const TextTamil = {
Home:
    {
    WelMsg: "லேர்ன் கீதா மனப்பாடப் பயிற்சி பக்கம்",
    chooseLang: "உங்கள் மொழியை தேர்வு செய்யவும்:",
    greetMsg: "கீதா குடும்பத்திற்கு வரவேற்கிறோம்",
    linkBasic1: "எளிய முறை",
    linkBasic2:"படி படியாக",
    startPractice: "பயிற்சி தொடங்க",
    linkAdv1:"மேம்பட்ட முறை",
    linkAdv2:"வசனம் வாரியாக",
    linkSerialWise: "படிப்படியாக கற்றுக்கொள்",
    alert: "இந்த மொழி தற்போது கிடைக்கவில்லை",
    },
Practice:
   {
    WelMsg: "ஸ்ரீமத் பகவத் கீதை மனப்பாடம் உதவி பக்கம்",
    selAdh: "ஒற்றை/பல அத்தியாயங்களைத் தேர்ந்தெடுக்கவும்:",
    or: "அல்லது",
    selectAll: "அனைத்து அத்தியாயங்களையும் தேர்ந்தெடுக்கவும்",
    selectdAdh: "தேர்ந்தெடுக்கப்பட்ட அத்தியாயங்கள்:",
    clkBtn: "சீரற்ற வசன எண்ணைப் பெற பொத்தானைக் கிளிக் செய்யவும்",
    alert: "குறைந்தது ஒரு அத்தியாயத்தையாவது தேர்ந்தெடுக்கவும்",
    meaningMsg: "இந்த ஸ்லோகத்துக்கான அர்த்தம் இப்போது தர இயலவில்லை. அடுத்த புதிய அறிவிப்பு வரை காத்திருங்கள்",
    shlokNo: "ஸ்லோக எண் ",
    shlokCharan: "ஸ்லோக அடி",
    showAnswer: "பதில் பெறுவதற்காக",
    showMeaning: "அர்த்தம் பெறுவதற்காக",
    adhyaya: "அத்தியாயம்",
    },
Nums:
    {
    num1: "1",
    num2: "2",
    num3: "3",
    num4: "4",
    num5: "5",
    num6: "6",
    num7: "7",
    num8: "8",
    num9: "9",
    num10: "10",
    num11: "11",
    num12: "12",
    num13: "13",
    num14: "14",
    num15: "15",
    num16: "16",
    num17: "17",
    num18: "18"
    },
Score:
    {
    reportCard: "ரிப்போர்ட் கார்டு",
    srNo: "வ.எண்.",
    adhyaya: "அத்தியாயம்",
    shlokNo: "ஸ்லோக வரிசை எண் ",
    time: "நேரம்",
    },
Serialwise:
    {
    WelMsg: "ஸ்ரீமத் பகவத் கீதை படிப்படியான கற்றல் பக்கம்",
    },
}
export default TextTamil