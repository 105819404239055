import Texts from "../Components/TextScript/TextIndex"
import Geetajis from "../Components/GeetajiAllLang/GeetajiIndex";
import Audio from "../Components/Audio"
import AudioMark from "../audios/AudioMark";
import { useState,useEffect } from "react";
import { useContext } from "react"
import { MyContext } from "../Components/Context"
import { Link } from "react-router-dom"
import Button from "../Components/Button";
import QRandMsg from "../Components/QRandMsg";

const Serialwise = () => {
  const [selAdh, setSelAdh] = useState("Adhyaya");
  const [selShlok, setSelShlok] = useState("Shlok No.");
  const [showShloka, setShowShloka] = useState('');
  const [showMeaning, setShowMeaning] = useState('');

  const lang = useContext(MyContext)

  const geetaji = Geetajis[lang][0]
  const meaning = Geetajis[lang][1]

  const textType = Texts[lang].Serialwise
  // const numType = Texts[lang].Nums

   let adhyaya = ['Adhyaya-1','Adhyaya-2','Adhyaya-3','Adhyaya-4','Adhyaya-5','Adhyaya-6','Adhyaya-7','Adhyaya-8','Adhyaya-9','Adhyaya-10','Adhyaya-11','Adhyaya-12','Adhyaya-13','Adhyaya-14','Adhyaya-15','Adhyaya-16','Adhyaya-17','Adhyaya-18']
  // console.log (adhyaya)
  let noOfShlok = [47,72,43,42,29,47,30,28,34,42,55,20,34,27,20,24,28,78]
  let geetaArr = geetaji[selAdh.toLowerCase()]
  let arthArr = meaning[selAdh.toLowerCase()]

  let handleAdhChange = (e) => {
    setSelAdh(e.target.value)
    setSelShlok('1')
  }
  // console.log (selAdh)
  // console.log (selShlok)

  let handleShlokChange = (e) => {
    setSelShlok(e.target.value)
  }

  useEffect(()=>{
    showShlok()
  })

  let shlokCount = noOfShlok[selAdh.slice(8)-1]
  let shlokNumArr = []
  for (let i=1; i<=shlokCount; i++){
    shlokNumArr.push(i)
  }
  shlokNumArr.push('Pushpika')
  // console.log (shlokNumArr)

//* FOR SHLOK & MEANING FETCH *//
  function showShlok(){


    let shloka = Number(selAdh.slice(8))>0 ?
    Number(selShlok)>0 ?  `${geetaArr[selShlok-1]}` : 
    selShlok === 'Pushpika' ?  `${geetaArr[geetaArr.length-1]}` : ''
    : ''
    let arth = Number(selAdh.slice(8))>0 ?
    Number(selShlok)>0 ?  `${arthArr[selShlok-1]}` : 
    selShlok === 'Pushpika' ?  `${arthArr[arthArr.length-1]}` : ''
    : ''
    // console.log(geetaArr)
    // console.log(arthArr)
    setShowShloka(shloka)
    setShowMeaning(arth)
  }

//* FOR SHLOK INC/DEC *//
  const decShlok = () => {
    if(Number(selShlok) === 1){
      setSelShlok('Pushpika')
    }else if(selShlok === 'Pushpika'){
      setSelShlok(shlokNumArr.length-1)
    }else(
      setSelShlok((prevSelShlok) => Number(prevSelShlok)-1)
    )
  }
  const incShlok = () => {
    if(Number(selShlok) === shlokNumArr.length-1){
      setSelShlok('Pushpika')
    }else if(selShlok === 'Pushpika'){
      setSelShlok(1)
    }else (
      setSelShlok((prevSelShlok) => Number(prevSelShlok)+1)
    )
  }

//* FOR SHLOK AUDIO *//
const audioToPlay = ((selShlok) !=='' && AudioMark[selAdh.toLowerCase()]) ?
`https://content.learngeeta.com/book/assets/audios/Ch${Number(selAdh.slice(8))}.mp3`
:''

// console.log(audioToPlay) 

const timeToStart = ((selShlok) !=='' && audioToPlay !== '')? 
selShlok === 'Pushpika' ? AudioMark[selAdh.toLowerCase()][geetaArr.length][0] :
AudioMark[selAdh.toLowerCase()][(selShlok)][0] : ''

const timeToStop = ((selShlok) !== '' && audioToPlay !== '') ? 
selShlok === 'Pushpika' ? AudioMark[selAdh.toLowerCase()][geetaArr.length][1] :
AudioMark[selAdh.toLowerCase()][(selShlok)][1] : ''

// console.log(timeToStart) 
// console.log(timeToStop) 

  return (
    <div>
      <Link className="link homeBtn" to = '/' >Home</Link>
      <div className="welcomeMsg">
        <h1>{textType.WelMsg}</h1>
      </div>
      <br/>
      <br/>
      <div className="adhShlokSelect">
        <select onChange={handleAdhChange}> 
          <option value={selAdh}>{selAdh}</option>
          {adhyaya.map((e) => <option key={e} value={e}>{e}</option>)}
        </select>
        <select value={selShlok} onChange={handleShlokChange}> 
          <option value={selShlok}>{selShlok}</option>
          {shlokNumArr.map((e) => <option key={e} value={e}>{e}</option>)}
        </select>
        <Button text = "Prev" onClick={()=>decShlok()}/>
        <Button text = "Next" onClick={()=>incShlok()}/>

      </div>
      <br/>
      <p className="shlok textViolet">{showShloka}</p>
      <br/>
      {showShloka !== '' && <span className="textSerialwise">Meaning</span>}
      <p className="shlok textViolet">{showMeaning}</p>
      <br/>
      <br/>
      {((selShlok) !=='' && audioToPlay !== '') && <Audio audioAdh={audioToPlay}  timeStart={timeToStart} timeStop={timeToStop}/>}
      <br/>
      <QRandMsg/>
    </div>
  )
}

export default Serialwise
