let Texts = {}

    Texts['hindi'] = require("./TextHindi").default;
    Texts['marathi'] = require("./TextMarathi").default;
    Texts['gujarati'] = require("./TextGujarati").default;
    Texts['english'] = require("./TextEnglish").default;
    Texts['tamil'] = require("./TextTamil").default;
    Texts['telugu'] = require("./TextTelugu").default;
    Texts['kannada'] = require("./TextKannada").default;
    Texts['bangla'] = require("./TextBangla").default;
    Texts['odia'] = require("./TextOdia").default;
    Texts['malayalam'] = require("./TextMalayalam").default;
    Texts['nepali'] = require("./TextNepali").default;
    // Texts['assamese'] = require("./TextAssamese").default;
    // Texts['sindhi'] = require("./TextSindhi").default;

export default Texts

// "hindi","marathi","gujarati","english","tamil","telugu","kannad","bangla","oria","malyalam"