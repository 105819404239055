const GeetajiTelugu = [
{
  "adhyaya-1" : [
    "ధృతరాష్ట్ర ఉవాచ\nధర్మక్షేత్రే కురుక్షేత్రే, సమవేతా యుయుత్సవః ৷\nమామకాః(ఫ్)  పాణ్డవాశ్చైవ, కిమకుర్వత సఞ్జ య॥1.1॥",

    "సఞ్జయ ఉవాచ\nదృష్ట్వా తు పాణ్డవానీకం(వ్ఁ) , వ్యూఢం(న్) దుర్యోధనస్తదా \nఆచార్యముపసఙ్గమ్య, రాజా వచనమబ్రవీ త్॥1.2॥",

    "పశ్యైతాం(మ్)  పాణ్డుపుత్రాణామ్, ఆచార్య మహతీం(ఞ్) చమూమ్ ৷\nవ్యూఢాం(న్)  ద్రుపదపుత్రేణ, తవ శిష్యేణ ధీమ తా॥1.3॥",

    "అత్ర శూరా మహేష్వాసా, భీమార్జునసమా యుధి ৷\nయుయుధానో విరాటశ్చ, ద్రుపదశ్చ మహార థః॥1.4॥",

    "ధృష్టకేతుశ్చేకితానః(ఖ్), కాశిరాజశ్చ వీర్యవాన్ ৷\nపురుజిత్కున్తిభోజశ్చ, శైబ్యశ్చ నరపుఙ్గ వః॥1.5॥",

    "యుధామన్యుశ్చ విక్రాన్త, ఉత్తమౌజాశ్చ వీర్యవాన్ ৷\nసౌభద్రో ద్రౌపదేయాశ్చ, సర్వ ఏవ మహార థాః॥1.6॥",

    "అస్మాకం(న్)  తు విశిష్టా యే, తాన్నిబోధ ద్విజోత్తమ ৷\nనాయకా మమ సైన్యస్య, సఞ్జ్ఞార్థం(న్)  తాన్బ్రవీమి  తే॥1.7॥",

    "భవాన్భీష్మశ్చ కర్ణశ్చ, కృపశ్చ సమితిఞ్జయః ৷\nఅశ్వత్థామా వికర్ణశ్చ, సౌమదత్తిస్తథైవ  చ॥1.8॥",

    "అన్యే చ బహవః(శ్)  శూరా, మదర్థే త్యక్తజీవితాః ৷\nనానాశస్త్రప్రహరణాః(స్), సర్వే యుద్ధవిశార దాః॥1.9॥",

    "అపర్యాప్తం(న్)  తదస్మాకం(మ్), బలం(మ్) భీష్మాభిరక్షితమ్ ৷\nపర్యాప్తం(న్)  త్విదమేతేషాం(మ్), బలం(మ్)  భీమాభిరక్షితమ్ ॥1.10॥",

    "అయనేషు చ సర్వేషు, యథాభాగమవస్థితాః ৷\nభీష్మమేవాభిరక్షన్తు, భవన్తః.(స్)  సర్వ ఏవ హి ॥1.11॥",

    "తస్య సఞ్జనయన్హర్షం(ఙ్) , కురువృద్ధః(ఫ్)  పితామహః \nసింహనాదం(వ్ఁ)  వినద్యోచ్చైః(శ్) , శఙ్ఖం(న్)  దధ్మౌ ప్రతాపవాన్ ॥1.12॥",

    "తతః(శ్)  శఙ్ఖాశ్చ భేర్యశ్చ, పణవానకగోముఖాః ৷\nసహసైవాభ్యహన్యన్త, స శబ్దస్తుములో౽భవత్ ॥1.13॥",

    "తతః(శ్)  శ్వేతైర్హయైర్యుక్తే, మహతి స్యన్దనే స్థితౌ ৷\nమాధవః(ఫ్)  పాణ్డవశ్చైవ, దివ్యౌ శఙ్ఖౌ ప్రదధ్మతుః ॥1.14॥",

    "పాఞ్చజన్యం(మ్)   హృషీకేశో, దేవదత్తం(న్)  ధనఞ్జయః ৷\nపౌణ్డ్రం(న్)  దధ్మౌ మహాశఙ్ఖం(మ్), భీమకర్మా వృకోదరః ॥1.15॥",

    "అనన్తవిజయం(మ్)  రాజా, కున్తీపుత్రో యుధిష్ఠిరః ৷\nనకులః(స్)  సహదేవశ్చ, సుఘోషమణిపుష్పకౌ ॥1.16॥",

    "కాశ్యశ్చ పరమేష్వాసః(శ్), శిఖణ్డీ చ మహారథః ৷\nధృష్టద్యుమ్నో విరాటశ్చ, సాత్యకిశ్చాపరాజితః ॥1.17॥",

    "ద్రుపదో ద్రౌపదేయాశ్చ, సర్వశః(ఫ్)  పృథివీపతే ৷\nసౌభద్రశ్చ మహాబాహుః(శ్), శఙ్ఖాన్దధ్ముః(ఫ్) పృథక్ పృథక్ ॥1.18॥",

    "స ఘోషో ధార్తరాష్ట్రాణాం(మ్), హృదయాని వ్యదారయత్ ৷\nనభశ్చ పృథివీం(ఞ్) చైవ, తుములో వ్యనునాదయన్ ॥1.19॥",

    "అథ వ్యవస్థితాన్దృష్ట్వా, ధార్తరాష్ట్రాన్కపిధ్వజః ৷\nప్రవృత్తే శస్త్రసమ్పాతే, ధనురుద్యమ్య పాణ్డవః ॥1.20॥",

    "హృషీకేశం(న్)  తదా వాక్యమ్,ఇదమాహ మహీపతే ৷\nఅర్జున ఉవాచ\nసేనయోరుభయోర్మధ్యే, రథం(మ్)  స్థాపయ మే౽చ్యుత ॥1.21॥",

    "యావదేతాన్నిరీక్షే౽హం(య్ఁ) , యోద్ధుకామానవస్థితాన్ \nకైర్మయా సహ యోద్ధవ్యమ్, అస్మిన్రణసముద్యమే ॥1.22॥",

    "యోత్స్యమానానవేక్షే౽హం(య్ఁ) , య ఏతే౽త్ర సమాగతాః \nధార్తరాష్ట్రస్య దుర్బుద్ధేః(ర్) , యుద్ధే ప్రియచికీర్షవః  ॥1.23॥",

    "సఞ్జయ ఉవాచ\n ఏవముక్తో హృషీకేశో, గుడాకేశేన భారత ৷\nసేనయోరుభయోర్మధ్యే, స్థాప యిత్వా రథోత్తమమ్ ॥1.24॥",

    "భీష్మద్రోణప్రముఖతః(స్), సర్వేషాం(ఞ్) చ మహీక్షితామ్ ৷\nఉవాచ పార్థ పశ్యైతాన్, సమవేతాన్కురూనితి ॥1.25॥",

    "తత్రాపశ్యత్స్థితాన్పార్థః(ఫ్), పితౄనథ పితామహాన్ ৷\nఆచార్యాన్మాతులాన్భ్రాతౄన్, పుత్రాన్పౌత్రాన్సఖీం(మ్) స్తథా ॥1.26॥",

    "శ్వశురాన్సుహృదశ్చైవ, సేనయోరుభయోరపి ৷\nతాన్సమీక్ష్య స కౌన్తేయః(స్),  సర్వాన్బన్ధూనవస్థితాన్ ॥1.27॥",

    "కృపయా పరయావిష్టో, విషీదన్నిదమబ్రవీత్ ৷\nఅర్జున ఉవాచ\nదృష్ట్వేమం(మ్)  స్వజనం(ఙ్) కృష్ణ, యుయుత్సుం(మ్)  సముపస్థితమ్ ॥1.28॥",

    "సీదన్తి మమ గాత్రాణి, ముఖం(ఞ్) చ పరిశుష్యతి ৷\nవేపథుశ్చ శరీరే మే,  రోమహర్షశ్చ జాయతే ॥1.29॥",

    "గాణ్డీవం(మ్) స్రంసతే హస్తాత్,త్వక్చైవ పరిదహ్యతే ৷\nన చ శక్నోమ్యవస్థాతుం(మ్)  భ్రమతీవ చ మే మనః ॥1.30॥",

    "నిమిత్తాని చ పశ్యామి, విపరీతాని కేశవ ৷\n న చ శ్రేయో౽నుపశ్యామి, హత్వా స్వజనమాహవే ॥1.31॥",

    "న కాఙ్క్షే విజయం(ఙ్) కృష్ణ, న చ రాజ్యం(మ్)  సుఖాని చ ৷\n కిం(న్)  నో రాజ్యేన గోవిన్ద, కిం(మ్)  భోగైర్జీవితేన వా ॥1.32॥",

    "యేషామర్థే  కాఙ్క్షితం(న్) నో, రాజ్యం(మ్)  భోగాః(స్)  సుఖాని చ ৷\n త ఇమే౽వస్థితా యుద్ధే, ప్రాణాంస్త్యక్త్వా ధనాని చ ॥1.33॥",

    "ఆచార్యాః(ఫ్)  పితరః(ఫ్)  పుత్రాః(స్), తథైవ చ పితామహాః ৷\n మాతులాః(శ్)  శ్వశురాః(ఫ్)  పౌత్రాః(శ్),  శ్యాలాః(స్)  సమ్బన్ధినస్తథా ॥1.34॥",

    "ఏతాన్న హన్తుమిచ్ఛామి, ఘ్నతో౽పి మధుసూదన ৷\n అపి త్రైలోక్యరాజ్యస్య, హేతోః(ఖ్)  కిం(న్)  ను మహీకృతే ॥1.35॥",

    "నిహత్య ధార్తరాష్ట్రాన్నః(ఖ్),  కా ప్రీతిః(స్)  స్యాజ్జనార్దన ৷\nపాపమేవాశ్రయేదస్మాన్,హత్వైతానాతతాయినః॥1.36॥",
    
    "తస్మాన్నార్హా వయం(మ్)  హన్తుం(న్), ధార్తరాష్ట్రాన్స్వబాన్ధవాన్ ৷\nస్వజనం(మ్)  హి కథం(మ్)  హత్వా, సుఖినః(స్)  స్యామ మాధవ॥1.37॥",

    "యద్యప్యేతే న పశ్యన్తి,  లోభోపహతచేతసః ৷\nకులక్షయకృతం(న్)  దోషం(మ్), మిత్రద్రోహే చ పాతకమ్॥1.38॥",

    "కథం(న్)  న జ్ఞేయమస్మాభిః(ఫ్), పాపాదస్మాన్నివర్తితుమ్ ৷\nకులక్షయకృతం(న్)  దోషం(మ్),  ప్రపశ్యద్భిర్జనార్దన॥1.39॥",

    "కులక్షయే ప్రణశ్యన్తి, కులధర్మాః(స్)  సనాతనాః ৷\nధర్మే నష్టే కులం(ఙ్) కృత్స్నమ్, అధర్మో౽భిభవత్యుత॥1.40॥",

    "అధర్మాభిభవాత్కృష్ణ, ప్రదుష్యన్తి కులస్త్రియః ৷\nస్త్రీషు దుష్టాసు వార్ష్ణేయ, జాయతే వర్ణసఙ్కరః॥1.41॥",

    "సఙ్కరో నరకాయైవ , కులఘ్నానాం(ఙ్) కులస్య చ\nపతన్తి పితరో హ్యేషాం(ల్ఁ)  , లుప్తపిణ్డోదకక్రియాః॥1.42॥",

    "దోషైరేతైః(ఖ్)  కులఘ్నానాం(వ్ఁ) , వర్ణసఙ్కరకారకైః \nఉత్సాద్యన్తే జాతిధర్మాః( ఖ్),  కులధర్మాశ్చ శాశ్వతాః॥1.43॥",

    "ఉత్సన్నకులధర్మాణాం(మ్), మనుష్యాణాం(ఞ్) జనార్దన \nనరకే౽నియతం(వ్ఁ)   వాసో  , భవతీత్యనుశుశ్రుమ॥1.44॥",

    "అహో బత మహత్పాపం(ఙ్), కర్తుం(వ్ఁ)   వ్యవసితా వయమ్ \nయద్రాజ్యసుఖలోభేన , హన్తుం( మ్)   స్వజనముద్యతాః॥1.45॥",

    "యది మామప్రతీకారమ్,అశస్త్రం(మ్)  శస్త్రపాణయః ৷\nధార్తరాష్ట్రా రణే హన్యుః(స్), తన్మే క్షేమతరం(మ్)  భవేత్॥1.46॥",

    "సఞ్జయ ఉవాచ\nఏవముక్త్వార్జునః(స్) సఙ్ఖ్యే, రథోపస్థ ఉపావిశత్ ৷\nవిసృజ్య సశరం(ఞ్) చాపం(మ్), శోకసంవిగ్నమానసః॥1.47॥",

    "ఓం తత్సదితి శ్రీమద్భగవద్గీతాసు  ఉపనిషత్సు బ్రహ్మవిద్యాయాం(య్ఁ)\nయోగశాస్త్రే శ్రీకృష్ణార్జునసంవాదే అర్జునవిషాదయోగో నామ ప్రథమో౽ధ్యాయః ॥1॥"
  ],
  "adhyaya-2" : [
    "సఞ్జయ ఉవాచ\nతం(న్) తథా కృపయావిష్టమ్,అశ్రుపూర్ణాకులేక్షణమ్৷\nవిషీదన్తమిదం(వ్ఁ) వాక్యమ్, ఉవాచ మధుసూదనః ॥2.1॥",

    "శ్రీ భగవానువాచ\nకుతస్త్వా కశ్మలమిదం(వ్ఁ) , విషమే సముపస్థితమ్৷\nఅనార్యజుష్టమస్వర్గ్యమ్, అకీర్తికరమర్జున ॥2.2॥",

    "క్లైబ్యం(మ్) మా స్మ గమః(ఫ్) పార్థ, నైతత్త్వయ్యుపపద్యతే৷\nక్షుద్రం(మ్) హృదయదౌర్బల్యం(న్),  త్యక్త్వోత్తిష్ఠ పరన్తప ৷৷2.3॥",

    "అర్జున ఉవాచ\nకథం(మ్) భీష్మమహం(మ్) సఙ్ఖ్యే, ద్రోణం(ఞ్) చ మధుసూదన|\nఇషుభిః(ఫ్) ప్రతియోత్స్యామి, పూజార్హావరిసూదన ৷৷2.4॥",

    "గురూనహత్వా హి మహానుభావాన్, \nశ్రేయో భోక్తుం(మ్) భైక్ష్యమపీహ లోకే|\nహత్వార్థకామాంస్తు గురూనిహైవ, \nభుఞ్జీయ భోగాన్రుధిరప్రదిగ్ధాన్ ৷৷2.5॥",

    "న చైతద్విద్మః(ఖ్) కతరన్నో గరీయో, \nయద్వా జయేమ యది వా నో జయేయుః৷\nయానేవ హత్వా న జిజీవిషామః(స్), \nతే౽వస్థితాః(ఫ్) ప్రముఖే ధార్తరాష్ట్రాః ৷৷2.6॥",

    "కార్పణ్యదోషోపహతస్వభావః(ఫ్), \nపృచ్ఛామి త్వాం(న్) ధర్మసమ్మూఢచేతాః৷\nయచ్ఛ్రేయః(స్) స్యాన్నిశ్చితం(మ్) బ్రూహి తన్మే, \nశిష్యస్తే౽హం(మ్) శాధి మాం(న్)త్వాం(మ్)ప్రపన్నమ్ ৷৷2.7॥",

    "న హి ప్రపశ్యామి మమాపనుద్యాద్, \nయచ్ఛోకముచ్ఛోషణమిన్ద్రియాణామ్৷\nఅవాప్య భూమావసపత్నమృద్ధమ్, \nరాజ్యం(మ్) సురాణామపి చాధిపత్యమ్ ৷৷2.8॥",

    "సఞ్జయ ఉవాచ\nఏవముక్త్వా హృషీకేశం(ఙ్), గుడాకేశః(ఫ్) పరన్తప৷\nన యోత్స్య ఇతి గోవిన్దమ్, ఉక్త్వా తూష్ణీం(మ్) బభూవ హ ৷৷2.9॥",

    "తమువాచ హృషీకేశః(ఫ్) ప్రహసన్నివ భారత ৷\nసేనయోరుభయోర్మధ్యే , విషీదన్తమిదం(వ్ఁ) వచః ॥2.10॥",

    "శ్రీ భగవానువాచ\nఅశోచ్యానన్వశోచస్త్వం(మ్), ప్రజ్ఞావాదాంశ్చ భాషసే৷\nగతాసూనగతాసూంశ్చ, నానుశోచన్తి పణ్డితాః ৷৷2.11॥",

    "న త్వేవాహం(ఞ్) జాతు నాసం(న్), న త్వం(న్) నేమే జనాధిపాః৷\nన చైవ న భవిష్యామః(స్), సర్వే వయమతః(ఫ్) పరమ్ ৷৷2.12॥",

    "దేహినో౽స్మిన్యథా దేహే ,కౌమారం(య్ఁ) యౌవనం(ఞ్) జరా৷\nతథా దేహాన్తరప్రాప్తిః(ర్) , ధీరస్తత్ర న ముహ్యతి ॥2.13॥",

    "మాత్రాస్పర్శాస్తు కౌన్తేయ, శీతోష్ణసుఖదుఃఖదాః৷\nఆగమాపాయినో౽నిత్యాః(స్), తాంస్తితిక్షస్వ భారత ৷৷2.14॥",

    "యం(మ్) హి న వ్యథయన్త్యేతే, పురుషం(మ్) పురుషర్షభ৷\nసమదుఃఖసుఖం(న్) ధీరం(మ్), సో౽మృతత్వాయ కల్పతే ৷৷2.15॥",

    "నాసతో విద్యతే భావో, నాభావో విద్యతే సతః৷\nఉభయోరపి దృష్టో౽న్తః(స్), త్వనయోస్తత్త్వదర్శిభిః ৷৷2.16॥",

    "అవినాశి తు తద్విద్ధి, యేన సర్వమిదం(న్) తతమ్৷\nవినాశమవ్యయస్యాస్య, న కశ్చిత్ కర్తుమర్హతి ৷৷2.17॥",

    "అన్తవన్త ఇమే దేహా, నిత్యస్యోక్తాః(శ్)శరీరిణః৷\nఅనాశినో౽ప్రమేయస్య, తస్మాద్యుధ్యస్వ భారత ৷৷2.18॥",

    "య ఏనం(వ్ఁ) వేత్తి హన్తారం(య్ఁ), యశ్చైనం(మ్) మన్యతే హతమ్৷\nఉభౌ తౌ న విజానీతో, నాయం(మ్) హన్తి న హన్యతే ॥2.19॥",

    "న జాయతే మ్రియతే వా కదాచిన్, \nనాయం(మ్) భూత్వా భవితా వా న భూయః৷\nఅజో నిత్యః(శ్) శాశ్వతో౽యం(మ్) పురాణో, \nన హన్యతే హన్యమానే శరీరే ৷৷2.20॥",

    "వేదావినాశినం(న్) నిత్యం(య్ఁ) , య ఏనమజమవ్యయమ్৷\nకథం(మ్) స పురుషః(ఫ్) పార్థ , కం(ఙ్) ఘాతయతి హన్తి కమ్ ॥2.21॥",

    "వాసాంసి జీర్ణాని యథా విహాయ, \nనవాని గృహ్ణాతి నరో౽పరాణి৷\nతథా శరీరాణి విహాయ జీర్ణా-\nన్యన్యాని సంయాతి నవాని దేహీ ৷৷2.22॥",

    "నైనం(ఞ్) ఛిన్దన్తి శస్త్రాణి, నైనం(న్) దహతి పావకః৷\nన చైనం(ఙ్) క్లేదయన్త్యాపో, న శోషయతి మారుతః ৷৷2.23॥",

    "అచ్ఛేద్యో౽యమదాహ్యో౽యమ్, అక్లేద్యో౽శోష్య ఏవ చ৷\nనిత్యః(స్) సర్వగతః(స్) స్థాణుః(ర్), అచలో౽యం(మ్) సనాతనః ৷৷2.24॥",

    "అవ్యక్తో౽యమచిన్త్యో౽యమ్,అవికార్యో౽యముచ్యతే৷\nతస్మాదేవం(వ్ఁ) విదిత్వైనం(న్), నానుశోచితుమర్హసి ॥2.25॥",

    "అథ చైనం(న్) నిత్యజాతం(న్), నిత్యం(వ్ఁ) వా మన్యసే మృతమ్৷\nతథాపి త్వం(మ్) మహాబాహో, నైవం(మ్) శోచితుమర్హసి ॥2.26॥",

    "జాతస్య హి ధ్రువో మృత్యుః(ర్), ధ్రువం(ఞ్) జన్మ మృతస్య చ৷\nతస్మాదపరిహార్యే౽ర్థే, న త్వం(మ్) శోచితుమర్హసి ৷৷2.27॥",
    
    "అవ్యక్తాదీని భూతాని, వ్యక్తమధ్యాని భారత৷\nఅవ్యక్తనిధనాన్యేవ, తత్ర కా పరిదేవనా ৷৷2.28॥",
    
    "ఆశ్చర్యవత్పశ్యతి కశ్చిదేనమ్,\nఆశ్చర్యవద్వదతి తథైవ చాన్యః৷\nఆశ్చర్యవచ్ఛైనమన్యః(శ్) శృణోతి, \nశ్రుత్వాప్యేనం(వ్ఁ) వేద న చైవ కశ్చిత్ ॥2.29॥",

    "దేహీ నిత్యమవధ్యో౽యం(న్), దేహే సర్వస్య భారత৷\nతస్మాత్సర్వాణి భూతాని, న త్వం(మ్) శోచితుమర్హసి ৷৷2.30॥",
    
    "స్వధర్మమపి చావేక్ష్య, న వికమ్పితుమర్హసి৷\nధర్మ్యాద్ధి యుద్ధాఛ్రేయో౽న్యత్,క్షత్రియస్య న విద్యతే ৷৷2.31॥",
    
    "యదృచ్ఛయా చోపపన్నం(మ్), స్వర్గద్వారమపావృతమ్৷\nసుఖినః క్షత్రియాః(ఫ్) పార్థ, లభన్తే యుద్ధమీదృశమ్ ৷৷2.32॥",
    
    "అథ చేత్త్వమిమం(న్) ధర్మ్యం(మ్), సఙ్గ్రామం(న్) న కరిష్యసి৷\nతతః(స్) స్వధర్మం(ఙ్) కీర్తిం(ఞ్) చ, హిత్వా పాపమవాప్స్యసి ৷৷2.33॥",
    
    "అకీర్తిం(ఞ్) చాపి భూతాని, కథయిష్యన్తి తే౽వ్యయామ్৷\nసమ్భావితస్య చాకీర్తిః(ర్), మరణాదతిరిచ్యతే ৷৷2.34॥",
    
    "భయాద్రణాదుపరతం(మ్), మంస్యన్తే త్వాం(మ్) మహారథాః৷\nయేషాం(ఞ్) చ త్వం(మ్) బహుమతో, భూత్వా యాస్యసి లాఘవమ్ ৷৷2.35॥",
    
    "అవాచ్యవాదాంశ్చ బహూన్, వదిష్యన్తి తవాహితాః৷\nనిన్దన్తస్తవ సామర్థ్యం(న్), తతో దుఃఖతరం(న్) ను కిమ్ ৷৷2.36॥",
    
    "హతో వా ప్రాప్స్యసి స్వర్గం(ఞ్), జిత్వా వా భోక్ష్యసే మహీమ్৷\nతస్మాదుత్తిష్ఠ కౌన్తేయ, యుద్ధాయ కృతనిశ్చయః ৷৷2.37॥",
    
    "సుఖదుఃఖే సమే కృత్వా, లాభాలాభౌ జయాజయౌ৷\nతతో యుద్ధాయ యుజ్యస్వ, నైవం(మ్) పాపమవాప్స్యసి ৷৷2.38॥",
    
    "ఏషా తే౽భిహితా సాఙ్ఖ్యే, బుద్ధిర్యోగే త్విమాం(మ్) శృణు৷\nబుద్ధ్యాయుక్తో యయా పార్థ, కర్మబన్ధం(మ్) ప్రహాస్యసి ৷৷2.39॥",
    
    "నేహాభిక్రమనాశో౽స్తి, ప్రత్యవాయో న విద్యతే৷\nస్వల్పమప్యస్య ధర్మస్య, త్రాయతే మహతో భయాత్ ৷৷2.40॥",
    
    "వ్యవసాయాత్మికా బుద్ధిః(ర్), ఏకేహ కురునన్దన৷\nబహుశాఖా హ్యనన్తాశ్చ, బుద్ధయో౽వ్యవసాయినామ్ ৷৷2.41॥",
    
    "యామిమాం(మ్) పుష్పితాం(వ్ఁ) వాచం(మ్) , ప్రవదన్త్యవిపశ్చితః৷\nవేదవాదరతాః(ఫ్) పార్థ , నాన్యదస్తీతి వాదినః ॥2.42॥",

    "కామాత్మానః(స్) స్వర్గపరా, జన్మకర్మఫలప్రదామ్৷\nక్రియావిశేషబహులాం(మ్), భోగైశ్వర్యగతిం(మ్) ప్రతి ৷৷2.43॥",

    "భోగైశ్వర్యప్రసక్తానాం(న్), తయాపహృతచేతసామ్৷\nవ్యవసాయాత్మికా బుద్ధిః(స్), సమాధౌ న విధీయతే ৷৷2.44॥",

    "త్రైగుణ్యవిషయా వేదా, నిస్త్రైగుణ్యో భవార్జున৷\nనిర్ద్వన్ద్వో నిత్యసత్త్వస్థో, నిర్యోగక్షేమ ఆత్మవాన్ ৷৷2.45॥",

    "యావానర్థ ఉదపానే, సర్వతః(స్) సమ్ప్లుతోదకే৷\nతావాన్సర్వేషు వేదేషు, బ్రాహ్మణస్య విజానతః ৷৷2.46॥",

    "కర్మణ్యేవాధికారస్తే, మా ఫలేషు కదాచన৷\nమా కర్మఫలహేతుర్భూః(ర్), మాతే సఙ్గో౽స్త్వకర్మణి ৷৷2.47॥",

    "యోగస్థః(ఖ్)  కురు కర్మాణి, సఙ్గ(న్) త్యక్త్వా ధనఞ్జయ৷\nసిద్ధ్యసిద్ధ్యోః(స్) సమో భూత్వా , సమత్వం(య్ఁ) యోగ ఉచ్యతే ॥2.48॥",

    "దూరేణ హ్యవరం(ఙ్) కర్మ, బుద్ధియోగాద్ధనఞ్జయ৷\nబుద్ధౌ శరణమన్విచ్ఛ,కృపణాః(ఫ్)ఫలహేతవః ৷৷2.49॥",

    "బుద్ధియుక్తో జహాతీహ, ఉభే సుకృతదుష్కృతే৷\nతస్మాద్యోగాయ యుజ్యస్వ, యోగః(ఖ్) కర్మసు కౌశలమ్ ৷৷2.50॥",

    "కర్మజం(మ్) బుద్ధియుక్తా హి, ఫలం(న్) త్యక్త్వా మనీషిణః৷\nజన్మబన్ధవినిర్ముక్తాః(ఫ్),పదం(ఙ్) గచ్ఛన్త్యనామయమ్ ৷৷2.51॥",

    "యదా తే మోహకలిలం(మ్), బుద్ధిర్వ్యతితరిష్యతి৷\nతదా గన్తాసి నిర్వేదం(మ్), శ్రోతవ్యస్య శ్రుతస్య చ ৷৷2.52॥",

    "శ్రుతివిప్రతిపన్నా తే, యదా స్థాస్యతి నిశ్చలా৷\nసమాధావచలా బుద్ధిః(స్),తదా యోగమవాప్స్యసి ৷৷2.53॥",

    "అర్జున ఉవాచ\nస్థితప్రజ్ఞస్య కా భాషా, సమాధిస్థస్య కేశవ৷\nస్థితధీః(ఖ్) కిం(మ్) ప్రభాషేత, కిమాసీత వ్రజేత కిమ్ ৷৷2.54॥",

    "శ్రీ భగవానువాచ\nప్రజహాతి యదా కామాన్, సర్వాన్పార్థ మనోగతాన్\nఆత్మన్యేవాత్మనా తుష్టః(స్), స్థితప్రజ్ఞస్తదోచ్యతే ৷৷2.55॥",

    "దుఃఖేష్వనుద్విగ్నమనాః(స్), సుఖేషు విగతస్పృహః৷\nవీతరాగభయక్రోధః(స్), స్థితధీర్మునిరుచ్యతే ৷৷2.56॥",

    "యః(స్) సర్వత్రానభిస్నేహః(స్),త త్తత్ప్రాప్య శుభాశుభమ్৷\nనాభినన్దతి న ద్వేష్టి, తస్య ప్రజ్ఞా ప్రతిష్ఠితా ৷৷2.57॥",

    "యదా సంహరతే చాయం(ఙ్), కూర్మో౽ఙ్గానీవ సర్వశః৷\nఇన్ద్రియాణీన్ద్రియార్థేభ్యః(స్),తస్య ప్రజ్ఞా ప్రతిష్ఠితా ৷৷2.58॥",

    "విషయా వినివర్తన్తే,నిరాహారస్య దేహినః৷\nరసవర్జం(మ్) రసో౽ప్యస్య,పరం(న్) దృష్ట్వా నివర్తతే ৷৷2.59॥",

    "యతతో హ్యపి కౌన్తేయ,పురుషస్య విపశ్చితః৷\nఇన్ద్రియాణి ప్రమాథీని,హరన్తి ప్రసభం(మ్) మనః ৷৷2.60॥",

    "తాని సర్వాణి సంయమ్య,యుక్త ఆసీత మత్పరః৷\nవశే హి యస్యేన్ద్రియాణి,తస్య ప్రజ్ఞా ప్రతిష్ఠితా ৷৷2.61॥",

    "ధ్యాయతో విషయాన్పుంసః(స్), సఙ్గస్తేషూపజాయతే৷\nసఙ్గాత్సఞ్జాయతే కామః(ఖ్), కామాత్క్రోధో౽భిజాయతే ৷৷2.62॥",

    "క్రోధాద్భవతి సమ్మోహః(స్), సమ్మోహాత్స్మృతివిభ్రమః৷\nస్మృతిభ్రంశాద్ బుద్ధినాశో, బుద్ధినాశాత్ప్రణశ్యతి ৷৷2.63॥",

    "రాగద్వేషవియుక్తైస్తు, విషయానిన్ద్రియైశ్చరన్৷\nఆత్మవశ్యైర్విధేయాత్మా, ప్రసాదమధిగచ్ఛతి ৷৷2.64॥",

    "ప్రసాదే సర్వదుఃఖానాం(మ్), హానిరస్యోపజాయతే৷\nప్రసన్నచేతసో హ్యాశు, బుద్ధిః(ఫ్) పర్యవతిష్ఠతే ৷৷2.65॥",

    "నాస్తి బుద్ధిరయుక్తస్య,న చాయుక్తస్య భావనా৷\nన చాభావయతః(శ్) శాన్తిః(ర్),అశాన్తస్య కుతః(స్) సుఖమ్ ৷৷2.66॥",

    "ఇన్ద్రియాణాం(మ్) హి చరతాం(య్ఁ), యన్మనో౽నువిధీయతే৷\nతదస్య హరతి ప్రజ్ఞాం(వ్ఁ), వాయుర్నావమివామ్భసి ॥2.67॥",

    "తస్మాద్యస్య మహాబాహో, నిగృహీతాని సర్వశః৷\nఇన్ద్రియాణీన్ద్రియార్థేభ్యః(స్),తస్య ప్రజ్ఞా ప్రతిష్ఠితా ৷৷2.68॥",

    "యా నిశా సర్వభూతానాం(న్), తస్యాం(ఞ్) జాగర్తి సంయమీ৷\nయస్యాం(ఞ్) జాగ్రతి భూతాని, సా నిశా పశ్యతో మునేః ৷৷2.69॥",

    "ఆపూర్యమాణమచలప్రతిష్ఠం(మ్), \nసముద్రమాపః(ఫ్) ప్రవిశన్తి యద్వత్৷\nతద్వత్కామా యం(మ్) ప్రవిశన్తి సర్వే, \nస శాన్తిమాప్నోతి న కామకామీ ৷৷2.70॥",

    "విహాయ కామాన్యః(స్) సర్వాన్, పుమాంశ్చరతి నిఃస్పృహః৷\nనిర్మమో నిరహఙ్కారః(స్), స శాన్తిమధిగచ్ఛతి ৷৷2.71॥",

    "ఏషా బ్రాహ్మీ స్థితిః(ఫ్) పార్థ, నైనాం(మ్) ప్రాప్య విముహ్యతి৷\nస్థిత్వాస్యామన్తకాలే౽పి, బ్రహ్మనిర్వాణమృచ్ఛతి ৷৷2.72॥",

    "ఓం తత్సదితి శ్రీమద్భగవద్గీతాసు ఉపనిషత్సు బ్రహ్మవిద్యాయాం(య్ఁ) యోగశా\nస్త్రే శ్రీకృష్ణార్జునసంవాదే సాఙ్ఖ్యయోగో నామ ద్వితీయో౽ధ్యాయః ॥2॥"
  ],
  "adhyaya-3" : [
    "అర్జున ఉవాచ\nజ్యాయసీ చేత్కర్మణస్తే,మతా బుద్ధిర్జనార్దన\nతత్కిం(ఙ్) కర్మణి ఘోరే మాం(న్), నియోజయసి కేశవ ॥3.1॥",

    "వ్యామిశ్రేణేవ వాక్యేన , బుద్ధిం(మ్) మోహయసీవ మే\nతదేకం(వ్ఁ) వద నిశ్చిత్య , యేన శ్రేయో౽హమాప్నుయామ్ ॥3.2॥",

    "శ్రీ భగవానువాచ\nలోకే౽స్మిన్ద్వివిధా నిష్ఠా, పురా ప్రోక్తా మయానఘ\nజ్ఞానయోగేన సాఙ్ఖ్యానాం(ఙ్), కర్మయోగేన యోగినామ్ ॥3.3॥",

    "న కర్మణామనారమ్భాన్, నైష్కర్మ్యం(మ్) పురుషో౽శ్నుతే\nన చ సన్న్యసనాదేవ, సిద్ధిం(మ్) సమధిగచ్ఛతి ॥3.4॥",

    "న హి కశ్చిత్క్షణమపి, జాతు తిష్ఠత్యకర్మకృత్\nకార్యతే హ్యవశః(ఖ్) కర్మ, సర్వః(ఫ్) ప్రకృతిజైర్గుణైః ॥3.5॥",

    "కర్మేన్ద్రియాణి సంయమ్య, య ఆస్తే మనసా స్మరన్\nఇన్ద్రియార్థాన్విమూఢాత్మా, మిథ్యాచారః(స్) స ఉచ్యతే॥3.6॥",

    "యస్త్విన్ద్రియాణి మనసా, నియమ్యారభతేర్జున\nకర్మేన్ద్రియైః(ఖ్) కర్మయోగమ్, అసక్తః(స్) స విశిష్యతే ॥3.7॥",

    "నియతం(ఙ్) కురు కర్మ త్వం(ఙ్), కర్మ జ్యాయో హ్యకర్మణః \nశరీరయాత్రాపి చ తే, న ప్రసిద్ధ్యేదకర్మణః ॥3.8॥",

    "యజ్ఞార్థాత్కర్మణో౽న్యత్ర, లోకో౽యం(ఙ్) కర్మబన్ధనః \nతదర్థం(ఙ్) కర్మ కౌన్తేయ, ముక్తసఙ్గః(స్) సమాచర ॥3.9॥",

    "సహయజ్ఞాః(ఫ్) ప్రజాః(స్) సృష్ట్వా, పురోవాచ ప్రజాపతిః \nఅనేన ప్రసవిష్యధ్వమ్, ఏష వో౽స్త్విష్టకామధుక్ ॥3.10॥",

    "దేవాన్భావయతానేన,తే దేవా భావయన్తు వః \nపరస్పరం(మ్) భావయన్తః(శ్), శ్రేయః(ఫ్) పరమవాప్స్యథ ॥3.11॥",

    "ఇష్టాన్భోగాన్హి వో దేవా, దాస్యన్తే యజ్ఞభావితాః \nతైర్దత్తానప్రదాయైభ్యో,యో భుఙ్క్తే స్తేన ఏవ సః ॥3.12॥",

    "యజ్ఞశిష్టాశినః(స్) సన్తో, ముచ్యన్తే సర్వకిల్బిషైః \nభుఞ్జతే తే త్వఘం(మ్) పాపా, యే పచన్త్యాత్మకారణాత్ ॥3.13॥",

    "అన్నాద్భవన్తి భూతాని, పర్జన్యాదన్నసమ్భవః \nయజ్ఞాద్భవతి పర్జన్యో, యజ్ఞః(ఖ్) కర్మసముద్భవః ॥3.14॥",

    "కర్మ బ్రహ్మోద్భవం(వ్ఁ) విద్ధి, బ్రహ్మాక్షరసముద్భవమ్ \nతస్మాత్సర్వగతం(మ్) బ్రహ్మ , నిత్యం(య్ఁ) యజ్ఞే ప్రతిష్ఠితమ్ ॥3.15॥",

    "ఏవం(మ్) ప్రవర్తితం(ఞ్) చక్రం(న్), నానువర్తయతీహ యః \nఅఘాయురిన్ద్రియారామో, మోఘం(మ్) పార్థ స జీవతి ॥3.16॥",

    "యస్త్వాత్మరతిరేవ స్యాద్, ఆత్మతృప్తశ్చ మానవః \nఆత్మన్యేవ చ సన్తుష్టః(స్), తస్య కార్యం(న్) న విద్యతే ॥3.17॥",

    "నైవ తస్య కృతేనార్థో, నాకృతేనేహ కశ్చన \nన చాస్య సర్వభూతేషు, కశ్చిదర్థవ్యపాశ్రయః ॥3.18॥",

    "తస్మాదసక్తః(స్) సతతం(ఙ్), కార్యం(ఙ్) కర్మ సమాచర \nఅసక్తో హ్యాచరన్కర్మ, పరమాప్నోతి పూరుషః ॥3.19॥",

    "కర్మణైవ హి సంసిద్ధిమ్, ఆస్థితా జనకాదయః \nలోకసంగ్రహమేవాపి, సమ్పశ్యన్కర్తుమర్హసి ॥3.20॥",

    "యద్యదాచరతి శ్రేష్ఠః(స్), తత్తదేవేతరో జనః \nస యత్ప్రమాణం(ఙ్) కురుతే, లోకస్తదనువర్తతే ॥3.21॥",

    "న మే పార్థాస్తి కర్తవ్యం(న్) , త్రిషు లోకేషు కిఞ్చన \nనానవాప్తమవాప్తవ్యం(వ్ఁ) , వర్త ఏవ చ కర్మణి॥3.22॥",

    "యది హ్యహం(న్) న వర్తేయం(ఞ్), జాతు కర్మణ్యతన్ద్రితః \nమమ వర్త్మానువర్తన్తే, మనుష్యాః(ఫ్) పార్థ సర్వశః ॥3.23॥",

    "ఉత్సీదేయురిమే లోకా, న కుర్యాం(ఙ్) కర్మ చేదహమ్ \nసఙ్కరస్య చ కర్తా స్యామ్, ఉపహన్యామిమాః(ఫ్) ప్రజాః ॥3.24॥",

    "సక్తాః(ఖ్) కర్మణ్యవిద్వాంసో, యథా కుర్వన్తి భారత\nకుర్యాద్విద్వాంస్తథాసక్తః(శ్), చికీర్షుర్లోకసఙ్గ్రహమ్ ॥3.25॥",

    "న బుద్ధిభేదం(ఞ్) జనయేద్, అజ్ఞానాం(ఙ్) కర్మసఙ్గినామ్ \nజోషయేత్సర్వకర్మాణి, విద్వాన్యుక్తః(స్) సమాచరన్ ॥3.26॥",

    "ప్రకృతేః(ఖ్) క్రియమాణాని, గుణైః(ఖ్) కర్మాణి సర్వశః \nఅహఙ్కారవిమూఢాత్మా, కర్తాహమితి మన్యతే ॥3.27॥",

    "తత్త్వవిత్తు మహాబాహో, గుణకర్మవిభాగయోః \nగుణా గుణేషు వర్తన్త, ఇతి మత్వా న సజ్జతే ॥3.28॥",

    "ప్రకృతేర్గుణసమ్మూఢాః(స్), సజ్జన్తే గుణకర్మసు\nతానకృత్స్నవిదో మన్దాన్, కృత్స్నవిన్న విచాలయేత్ ॥3.29॥",

    "మయి సర్వాణి కర్మాణి, సన్న్యస్యాధ్యాత్మచేతసా \nనిరాశీర్నిర్మమో భూత్వా, యుధ్యస్వ విగతజ్వరః ॥3.30॥",

    "యే మే మతమిదం(న్) నిత్యమ్, అనుతిష్ఠన్తి మానవాః \nశ్రద్ధావన్తో౽నసూయన్తో, ముచ్యన్తే తే౽పి కర్మభిః ॥3.31॥",

    "యే త్వేతదభ్యసూయన్తో, నానుతిష్ఠన్తి మే మతమ్ \nసర్వజ్ఞానవిమూఢాంస్తాన్, విద్ధి నష్టానచేతసః ॥3.32॥",

    "సదృశం(ఞ్) చేష్టతే స్వస్యాః(ఫ్), ప్రకృతేర్జ్ఞానవానపి \nప్రకృతిం(య్ఁ) యాన్తి భూతాని , నిగ్రహః(ఖ్) కిం(ఙ్) కరిష్యతి ॥3.33॥",

    "ఇన్ద్రియస్యేన్ద్రియస్యార్థే, రాగద్వేషౌ వ్యవస్థితౌ \nతయోర్న వశమాగచ్ఛేత్,తౌ హ్యస్య పరిపన్థినౌ ॥3.34॥",

    "శ్రేయాన్స్వధర్మో విగుణః(ఫ్), పరధర్మాత్స్వనుష్ఠితాత్ \nస్వధర్మే నిధనం(మ్) శ్రేయః(ఫ్), పరధర్మో భయావహః ॥3.35॥",

    "అర్జున ఉవాచ\nఅథ కేన ప్రయుక్తో౽యం(మ్), పాపం(ఞ్) చరతి పూరుషః \nఅనిచ్ఛన్నపి వార్ష్ణేయ, బలాదివ నియోజితః ॥3.36॥",

    "శ్రీ భగవానువాచ\nకామ ఏష క్రోధ ఏష, రజోగుణసముద్భవః \nమహాశనో మహాపాప్మా, విద్ధ్యేనమిహ వైరిణమ్ ॥3.37॥",

    "ధూమేనావ్రియతే వహ్నిః(ర్), యథాదర్శో మలేన చ \nయథోల్బేనావృతో గర్భః(స్), తథా తేనేదమావృతమ్ ॥3.38॥",

    "ఆవృతం(ఞ్) జ్ఞానమేతేన, జ్ఞానినో నిత్యవైరిణా\nకామరూపేణ కౌన్తేయ, దుష్పూరేణానలేన చ ॥3.39॥",

    "ఇన్ద్రియాణి మనో బుద్ధిః(ర్),అస్యాధిష్ఠానముచ్యతే \nఏతైర్విమోహయత్యేష, జ్ఞానమావృత్య  దేహినమ్ ॥3.40॥",

    "తస్మాత్త్వమిన్ద్రియాణ్యాదౌ, నియమ్య భరతర్షభ\nపాప్మానం(మ్) ప్రజహి హ్యేనం(ఞ్), జ్ఞానవిజ్ఞాననాశనమ్ ॥3.41॥",

    "ఇన్ద్రియాణి పరాణ్యాహుః(ర్),  ఇన్ద్రియేభ్యః(ఫ్) పరం(మ్)మనః \nమనసస్తు పరా బుద్ధిః(ర్), యో బుద్ధేః(ఫ్) పరతస్తు సః ॥3.42॥",

    "ఏవం(మ్) బుద్ధేః(ఫ్) పరం(మ్) బుద్ధ్వా, సంస్తభ్యాత్మానమాత్మనా \nజహి శత్రుం(మ్) మహాబాహో, కామరూపం(న్)దురాసదమ్ ॥3.43॥",

    "ఓం తత్సదితి శ్రీమద్భగవద్గీతాసు ఉపనిషత్సు బ్రహ్మవిద్యాయాం(య్ఁ)\nయోగశాస్త్రే శ్రీకృష్ణార్జునసంవాదే కర్మయోగో నామ తృతీయో౽ధ్యాయః ॥3॥"
  ],
  "adhyaya-4" : [
    "శ్రీ భగవానువాచ\nఇమం(మ్) వివస్వతే యోగం(మ్), ప్రోక్తవానహమవ్యయమ్ ৷\nవివస్వాన్మనవే ప్రాహ, మనురిక్ష్వాకవే౽బ్రవీత్ ৷৷4.1॥",

    "ఏవం(మ్) పరమ్పరాప్రాప్తమ్, ఇమం(మ్) రాజర్షయో విదుః ৷\nస కాలేనేహ మహతా, యోగో నష్టః(ఫ్) పరన్తప ৷৷4.2॥",

    "స ఏవాయం(మ్) మయా తే౽ద్య, యోగః(ఫ్) ప్రోక్తః(ఫ్) పురాతనః ৷\nభక్తో౽సి మే సఖా చేతి, రహస్యం(మ్) హ్యేతదుత్తమమ్ ৷৷4.3॥",

    "అర్జున ఉవాచ\nఅపరం(మ్) భవతో జన్మ, పరం(ఞ్) జన్మ వివస్వతః ৷\nకథమేతద్విజానీయాం(న్), త్వమాదౌ ప్రోక్తవానితి ৷৷4.4॥",

    "శ్రీ భగవానువాచ\nబహూని మే వ్యతీతాని, జన్మాని తవ చార్జున ৷\nతాన్యహం(మ్) వేద సర్వాణి, న త్వం(మ్) వేత్థ పరన్తప ৷৷4.5॥",

    "అజో౽పి సన్నవ్యయాత్మా, భూతానామీశ్వరో౽పి సన్ ৷\nప్రకృతిం(మ్) స్వామధిష్ఠాయ, సమ్భవామ్యాత్మమాయయా ৷৷4.6॥",

    "యదా యదా హి ధర్మస్య, గ్లానిర్భవతి భారత৷\nఅభ్యుత్థానమధర్మస్య, తదాత్మానం(మ్) సృజామ్యహమ్ ৷৷4.7॥",

    "పరిత్రాణాయ సాధూనాం(మ్), వినాశాయ చ దుష్కృతామ్ ৷\nధర్మసంస్థాపనార్థాయ, సమ్భవామి యుగే యుగే ৷৷4.8॥",

    "జన్మ కర్మ చ మే దివ్యమ్, ఏవం(మ్) యో వేత్తి తత్త్వతః ৷\nత్యక్త్వా దేహం(మ్) పునర్జన్మ, నైతి మామేతి సో౽ర్జున ৷৷4.9॥",

    "వీతరాగభయక్రోధా, మన్మయా మాముపాశ్రితాః ৷\nబహవో జ్ఞానతపసా, పూతా మద్భావమాగతాః ৷৷4.10॥",

    "యే యథా మాం(మ్) ప్రపద్యన్తే, తాంస్తథైవ భజామ్యహమ్ ৷\nమమ వర్త్మానువర్తన్తే, మనుష్యాః(ఫ్) పార్థ సర్వశః ৷৷4.11॥",

    "కాఙ్క్షన్తః(ఖ్) కర్మణాం(మ్) సిద్ధిం(మ్), యజన్త ఇహ దేవతాః ৷\nక్షిప్రం(మ్) హి మానుషే లోకే, సిద్ధిర్భవతి కర్మజా ৷৷4.12॥",

    "చాతుర్వర్ణ్యం(మ్) మయా సృష్టం(ఙ్), గుణకర్మవిభాగశః |\nతస్య కర్తారమపి మాం(మ్), విద్ధ్యకర్తారమవ్యయమ్ ৷৷4.13॥",

    "న మాం(ఙ్) కర్మాణి లిమ్పన్తి, న మే కర్మఫలే స్పృహా ৷\nఇతి మాం(మ్) యో౽భిజానాతి, కర్మభిర్న స బధ్యతే ৷৷4.14॥",

    "ఏవం (ఞ్)  జ్ఞాత్వా కృతం(ఙ్) కర్మ, పూర్వైరపి ముముక్షుభిః ৷\nకురు కర్మైవ తస్మాత్త్వం(మ్), పూర్వైః(ఫ్) పూర్వతరం(ఙ్) కృతమ్ ৷৷4.15॥",

    "కిం(ఙ్) కర్మ కిమకర్మేతి,కవయో౽ప్యత్ర మోహితాః ৷\nతత్తే కర్మ ప్రవక్ష్యామి, యజ్జ్ఞాత్వా మోక్ష్యసే౽శుభాత్ ৷৷4.16॥",

    "కర్మణో హ్యపి బోద్ధవ్యం(మ్), బోద్ధవ్యం(ఞ్) చ వికర్మణః ৷\nఅకర్మణశ్చ బోద్ధవ్యం(ఙ్), గహనా కర్మణో గతిః ৷৷4.17॥",

    "కర్మణ్యకర్మ యః పశ్యేద్,అకర్మణి చ కర్మ యః ৷\nస బుద్ధిమాన్మనుష్యేషు, స యుక్తః(ఖ్) కృత్స్నకర్మకృత్ ৷৷4.18॥",

    "యస్య సర్వే సమారమ్భాః(ఖ్), కామసఙ్కల్పవర్జితాః ৷\nజ్ఞానాగ్నిదగ్ధకర్మాణం(న్), తమాహుః(ఫ్) పణ్డితం(మ్) బుధాః ৷৷4.19॥",

    "త్యక్త్వా కర్మఫలాసఙ్గం(న్), నిత్యతృప్తో నిరాశ్రయః ৷\nకర్మణ్యభిప్రవృత్తో౽పి, నైవ కిఞ్చిత్కరోతి సః ৷৷4.20॥",

    "నిరాశీర్యతచిత్తాత్మా, త్యక్తసర్వపరిగ్రహః ৷\nశారీరం(ఙ్) కేవలం(ఙ్) కర్మ, కుర్వన్నాప్నోతి కిల్బిషమ్ ৷৷4.21॥",

    "యదృచ్ఛాలాభసన్తుష్టో, ద్వన్ద్వాతీతో విమత్సరః৷\nసమః(స్) సిద్ధావసిద్ధౌ చ, కృత్వాపి న నిబధ్యతే ৷৷4.22॥",

    "గతసఙ్గస్య ముక్తస్య, జ్ఞానావస్థితచేతసః ৷\nయజ్ఞాయాచరతః(ఖ్) కర్మ, సమగ్రం(మ్) ప్రవిలీయతే ৷৷4.23॥",

    "బ్రహ్మార్పణం(మ్) బ్రహ్మహవిః(ర్), బ్రహ్మాగ్నౌ బ్రహ్మణా హుతమ్ ৷\nబ్రహ్మైవ తేన గన్తవ్యం(మ్), బ్రహ్మకర్మసమాధినా ৷৷4.24॥",

    "దైవమేవాపరే యజ్ఞం(మ్), యోగినః(ఫ్) పర్యుపాసతే৷\nబ్రహ్మాగ్నావపరే యజ్ఞం(మ్), యజ్ఞేనైవోపజుహ్వతి ৷৷4.25॥",

    "శ్రోత్రాదీనీన్ద్రియాణ్యన్యే, సంయమాగ్నిషు జుహ్వతి ৷\nశబ్దాదీన్విషయానన్య, ఇన్ద్రియాగ్నిషు జుహ్వతి ৷৷4.26॥",

    "సర్వాణీన్ద్రియకర్మాణి, ప్రాణకర్మాణి చాపరే৷\nఆత్మసంయమయోగాగ్నౌ, జుహ్వతి జ్ఞానదీపితే ৷৷4.27॥",

    "ద్రవ్యయజ్ఞాస్తపోయజ్ఞా, యోగయజ్ఞాస్తథాపరే|\nస్వాధ్యాయజ్ఞానయజ్ఞాశ్చ, యతయః(స్) సంశితవ్రతాః ৷৷4.28॥",

    "అపానే జుహ్వతి ప్రాణం(మ్), ప్రాణే౽పానం(న్) తథాపరే৷\nప్రాణాపానగతీ రుద్ధ్వా, ప్రాణాయామపరాయణాః ৷৷4.29॥",

    "అపరే నియతాహారాః(ఫ్), ప్రాణాన్ప్రాణేషు జుహ్వతి ৷\nసర్వే౽ప్యేతే యజ్ఞవిదో, యజ్ఞక్షపితకల్మషాః ৷৷4.30॥",

    "యజ్ఞశిష్టామృతభుజో,  యాన్తి బ్రహ్మ సనాతనమ్ ৷\nనాయం(మ్) లోకో౽స్త్యయజ్ఞస్య, కుతో౽న్యః(ఖ్) కురుసత్తమ ৷৷4.31॥",

    "ఏవం(మ్) బహువిధా యజ్ఞా, వితతా బ్రహ్మణో ముఖే ৷\nకర్మజాన్విద్ధి తాన్సర్వాన్, ఏవం(ఞ్) జ్ఞాత్వా విమోక్ష్యసే ৷৷4.32॥",

    "శ్రేయాన్ద్రవ్యమయాద్యజ్ఞాజ్, జ్ఞానయజ్ఞః(ఫ్)  పరన్తప৷\nసర్వం(ఙ్) కర్మాఖిలమ్ పార్థ, జ్ఞానే పరిసమాప్యతే ৷৷4.33॥",

    "తద్విద్ధి ప్రణిపాతేన, పరిప్రశ్నేన సేవయా ৷\nఉపదేక్ష్యన్తి తే జ్ఞానం(ఞ్), జ్ఞానినస్తత్త్వదర్శినః ৷৷4.34॥",

    "యజ్జ్ఞాత్వా న పునర్మోహమ్, ఏవం(మ్) యాస్యసి పాణ్డవ ৷\nయేన భూతాన్యశేషేణ, ద్రక్ష్యస్యాత్మన్యథో మయి৷ ৷৷4.35॥",

    "అపి చేదసి పాపేభ్యః(స్), సర్వేభ్యః(ఫ్) పాపకృత్తమః ৷\nసర్వం(ఞ్) జ్ఞానప్లవేనైవ, వృజినం(మ్) సన్తరిష్యసి ৷৷4.36॥",

    "యథైధాంసి సమిద్ధో౽గ్నిః(ర్), భస్మసాత్కురుతే౽ర్జున৷\nజ్ఞానాగ్నిః(స్) సర్వకర్మాణి, భస్మసాత్కురుతే తథా ৷৷4.37॥",

    "న హి జ్ఞానేన సదృశం(మ్), పవిత్రమిహ విద్యతే ৷\nతత్స్వయం(మ్) యోగసంసిద్ధః(ఖ్), కాలేనాత్మని విన్దతి ৷৷4.38॥",

    "శ్రద్ధావాంల్లభతే జ్ఞానం(న్),తత్పరః(స్) సంయతేన్ద్రియః ৷\nజ్ఞానం(మ్) లబ్ధ్వా పరాం(మ్) శాన్తిమ్, అచిరేణాధిగచ్ఛతి ৷৷4.39॥",

    "అజ్ఞశ్చాశ్రద్దధానశ్చ,సంశయాత్మా వినశ్యతి ৷\nనాయం(మ్) లోకో౽స్తి న పరో, న సుఖం(మ్) సంశయాత్మనః ৷৷4.40॥",

    "యోగసన్న్యస్తకర్మాణం(ఞ్), జ్ఞానసఞ్ఛిన్నసంశయమ్৷\nఆత్మవన్తం(న్) న కర్మాణి,నిబధ్నన్తి ధనఞ్జయ ৷৷4.41॥",

    "తస్మాదజ్ఞానసమ్భూతం(మ్), హృత్స్థం(ఞ్) జ్ఞానాసినాత్మనః ৷\nఛిత్త్వైనం(మ్) సంశయం(మ్)  యోగమ్, ఆతిష్ఠోత్తిష్ఠ భారత ৷৷4.42॥",

    "ఓం తత్సదితి శ్రీమద్భగవద్గీతాసు ఉపనిషత్సు బ్రహ్మవిద్యాయాం(మ్)\nయోగశాస్త్రే శ్రీకృష్ణార్జునసంవాదే జ్ఞానకర్మసన్న్యాసయోగో నామ చతుర్థో౽ధ్యాయః ॥4॥"
  ],
  "adhyaya-5" : [
    "అర్జున ఉవాచ\nసన్న్యాసం(ఙ్) కర్మణాం(ఙ్) కృష్ణ, పునర్యోగం(ఞ్) చ శంససి ৷\nయచ్ఛ్రేయ ఏతయోరేకం(న్), తన్మే బ్రూహి సునిశ్చితమ్ ৷৷5.1৷৷",

    "శ్రీ భగవానువాచ\nసన్న్యాసః(ఖ్) కర్మయోగశ్చ, నిః(శ్)శ్రేయసకరావుభౌ ৷\nతయోస్తు కర్మసన్న్యాసాత్, కర్మయోగో విశిష్యతే ৷৷5.2৷৷",

    "జ్ఞేయః(స్) స నిత్యసన్న్యాసీ, యో న ద్వేష్టి న కాఙ్క్షతి ৷\nనిర్ద్వన్ద్వో హి మహాబాహో, సుఖం(మ్) బన్ధాత్ప్రముచ్యతే ৷৷5.3৷৷",

    "సాఙ్ఖ్యయోగౌ పృథగ్బాలాః(ఫ్), ప్రవదన్తి న పణ్డితాః৷\nఏకమప్యాస్థితః(స్) సమ్యగ్, ఉభయోర్విన్దతే ఫలమ్ ৷৷5.4৷৷",

    "యత్సాఙ్ఖ్యైః(ఫ్) ప్రాప్యతే స్థానం(న్), తద్యోగైరపి గమ్యతే৷\nఏకం(మ్) సాఙ్ఖ్యం(ఞ్) చ యోగం(ఞ్) చ, యః(ఫ్) పశ్యతి స పశ్యతి ৷৷5.5৷৷",

    "సన్న్యాసస్తు మహాబాహో, దుఃఖమాప్తుమయోగతః ৷\nయోగయుక్తో మునిర్బ్రహ్మ, నచిరేణాధిగచ్ఛతి ৷৷5.6৷৷",

    "యోగయుక్తో విశుద్ధాత్మా, విజితాత్మా జితేన్ద్రియః ৷\nసర్వభూతాత్మభూతాత్మా, కుర్వన్నపి న లిప్యతే ৷৷5.7৷৷",

    "నైవ కిఞ్చిత్కరోమీతి,యుక్తో మన్యేత తత్త్వవిత్৷\nపశ్యఞ్శృణ్వన్స్పృశఞ్జిఘ్రన్, నశ్నన్గచ్ఛన్స్వపఞ్శ్వసన్ ৷৷5.8৷৷",

    "ప్రలపన్విసృజన్గృహ్ణన్, నున్మిషన్నిమిషన్నపి ৷\nఇన్ద్రియాణీన్ద్రియార్థేషు, వర్తన్త ఇతి ధారయన్ ৷৷5.9৷৷",

    "బ్రహ్మణ్యాధాయ కర్మాణి, సఙ్గం(న్) త్యక్త్వా కరోతి యః ৷\nలిప్యతే న స పాపేన, పద్మపత్రమివామ్భసా ৷৷5.10৷৷",

    "కాయేన మనసా బుద్ధ్యా, కేవలైరిన్ద్రియైరపి ৷\nయోగినః(ఖ్) కర్మ కుర్వన్తి, సఙ్గం(న్) త్యక్త్వాత్మశుద్ధయే ৷৷5.11৷৷",

    "యుక్తః(ఖ్) కర్మఫలం(న్) త్యక్త్వా, శాన్తిమాప్నోతి నైష్ఠికీమ్ ৷\nఅయుక్తః(ఖ్) కామకారేణ, ఫలే సక్తో నిబధ్యతే ৷৷5.12৷৷",

    "సర్వకర్మాణి మనసా, సన్న్యస్యాస్తే సుఖం(మ్) వశీ ৷\nనవద్వారే పురే దేహీ, నైవ కుర్వన్న కారయన్ ৷৷5.13৷৷",

    "న కర్తృత్వం(న్) న కర్మాణి, లోకస్య సృజతి ప్రభుః ৷\nన కర్మఫలసంయోగం(మ్), స్వభావస్తు ప్రవర్తతే ৷৷5.14৷৷",

    "నాదత్తే కస్యచిత్పాపం(న్), న చైవ సుకృతం(మ్)విభుః ৷\nఅజ్ఞానేనావృతం(ఞ్) జ్ఞానం(న్), తేన ముహ్యన్తి జన్తవః ৷৷5.15৷৷",

    "జ్ఞానేన తు తదజ్ఞానం(మ్),యేషాం(న్) నాశితమాత్మనః৷\nతేషామాదిత్యవజ్జ్ఞానం(మ్), ప్రకాశయతి తత్పరమ్ ৷৷5.16৷৷",

    "తద్బుద్ధయస్తదాత్మానః(స్), తన్నిష్ఠాస్తత్పరాయణాః৷\nగచ్ఛన్త్యపునరావృత్తిం(ఞ్), జ్ఞాననిర్ధూతకల్మషాః ৷৷5.17৷৷",

    "విద్యావినయసమ్పన్నే, బ్రాహ్మణే గవి హస్తిని ৷\nశుని చైవ శ్వపాకే చ, పణ్డితాః(స్) సమదర్శినః ৷৷5.18৷৷",

    "ఇహైవ తైర్జితః(స్) సర్గో, యేషాం(మ్) సామ్యే స్థితం(మ్) మనః ৷\nనిర్దోషం(మ్) హి సమం(మ్) బ్రహ్మ, తస్మాద్బ్రహ్మణి తే స్థితాః  ৷৷5.19৷৷",

    "న ప్రహృష్యేత్ప్రియం(మ్) ప్రాప్య, నోద్విజేత్ప్రాప్య చాప్రియమ్ ৷\nస్థిరబుద్ధిరసమ్మూఢో, బ్రహ్మవిద్ బ్రహ్మణి స్థితః  ৷৷5.20৷৷",

    "బాహ్యస్పర్శేష్వసక్తాత్మా, విన్దత్యాత్మని యత్సుఖమ్ ৷\nస బ్రహ్మయోగయుక్తాత్మా, సుఖమక్షయమశ్నుతే ৷৷5.21৷৷",

    "యే హి సంస్పర్శజా భోగా, దుఃఖయోనయ ఏవ తే ৷\nఆద్యన్తవన్తః(ఖ్) కౌన్తేయ,  న తేషు రమతే బుధః ৷৷5.22৷৷",

    "శక్నోతీహైవ యః(స్) సోఢుం(మ్), ప్రాక్శరీరవిమోక్షణాత్ ৷\nకామక్రోధోద్భవం(మ్) వేగం(మ్), స యుక్తః(స్) స సుఖీ నరః ৷৷5.23৷৷",

    "యో౽న్తఃసుఖో౽న్తరారామః(స్), తథాన్తర్జ్యోతిరేవ యః ৷\nస యోగీ బ్రహ్మనిర్వాణం(మ్), బ్రహ్మభూతో౽ధిగచ్ఛతి ৷৷5.24৷৷",

    "లభన్తే బ్రహ్మనిర్వాణమ్, ఋషయః(ఖ్) క్షీణకల్మషాః ৷\nఛిన్నద్వైధా యతాత్మానః(స్), సర్వభూతహితే రతాః ৷৷5.25৷৷",

    "కామక్రోధవియుక్తానాం(మ్), యతీనాం(మ్) యతచేతసామ్ ৷\nఅభితో బ్రహ్మనిర్వాణం(మ్), వర్తతే విదితాత్మనామ్ ৷৷5.26৷৷",

    "స్పర్శాన్కృత్వా బహిర్బాహ్యాంశ్, చక్షుశ్చైవాన్తరే భ్రువోః ৷\nప్రాణాపానౌ సమౌ కృత్వా, నాసాభ్యన్తరచారిణౌ ৷৷5.27৷৷",

    "యతేన్ద్రియమనోబుద్ధిః(ర్), మునిర్మోక్షపరాయణః ৷\nవిగతేచ్ఛాభయక్రోధో,యః(స్)సదా ముక్త ఏవ సః ৷৷5.28৷৷",

    "భోక్తారం(మ్) యజ్ఞతపసాం(మ్), సర్వలోకమహేశ్వరమ్ ৷\nసుహృదం(మ్) సర్వభూతానాం(ఞ్), జ్ఞాత్వా మాం(మ్) శాన్తిమృచ్ఛతి ৷৷5.29৷৷",

    "ఓం తత్సదితి శ్రీమద్భగవద్గీతాసు ఉపనిషత్సు బ్రహ్మవిద్యాయాం(మ్)\nయోగశాస్త్రే శ్రీకృష్ణార్జునసంవాదే కర్మసన్యాసయోగో నామ పఞ్చమో౽ధ్యాయః ॥5॥"
  ],
  "adhyaya-6" : [
    "శ్రీ భగవానువాచ\nఅనాశ్రితః(ఖ్) కర్మఫలం(ఙ్), కార్యం(ఙ్) కర్మ కరోతి యః৷\nస సన్న్యాసీ చ యోగీ చ, న నిరగ్నిర్న చాక్రియః ৷৷6.1৷৷",

    "యం(మ్) సన్న్యాసమితి ప్రాహుః(ర్), యోగం(న్)తం(మ్)విద్ధి పాణ్డవ৷\nన హ్యసన్న్యస్తసఙ్కల్పో, యోగీ భవతి కశ్చన ৷৷6.2৷৷",

    "ఆరురుక్షోర్మునేర్యోగం(ఙ్), కర్మ కారణముచ్యతే৷\nయోగారూఢస్య తస్యైవ, శమః(ఖ్)కారణముచ్యతే ৷৷6.3৷৷",

    "యదా హి నేన్ద్రియార్థేషు, న కర్మస్వనుషజ్జతే৷\nసర్వసఙ్కల్పసన్న్యాసీ, యోగారూఢస్తదోచ్యతే ৷৷6.4৷৷",

    "ఉద్ధరేదాత్మనాత్మానం(న్), నాత్మానమవసాదయేత్৷\nఆత్మైవ హ్యాత్మనో బన్ధుః(ర్), ఆత్మైవ రిపురాత్మనః ৷৷6.5৷৷",

    "బన్ధురాత్మాత్మనస్తస్య, యేనాత్మైవాత్మనా జితః৷\nఅనాత్మనస్తు శత్రుత్వే, వర్తేతాత్మైవ శత్రువత్ ৷৷6.6৷৷",

    "జితాత్మనః(ఫ్) ప్రశాన్తస్య, పరమాత్మా సమాహితః৷\nశీతోష్ణసుఖదుఃఖేషు, తథా మానాపమానయోః ৷৷6.7৷৷",

    "జ్ఞానవిజ్ఞానతృప్తాత్మా, కూటస్థో విజితేన్ద్రియః৷\nయుక్త ఇత్యుచ్యతే యోగీ, సమలోష్టాశ్మకాఞ్చనః ৷৷6.8৷৷",

    "సుహృన్మిత్రార్యుదాసీన,మధ్యస్థద్వేష్యబన్ధుషు৷\nసాధుష్వపి చ పాపేషు, సమబుద్ధిర్విశిష్యతే ৷৷6.9৷৷",

    "యోగీ యుఞ్జీత సతతమ్, ఆత్మానం(మ్) రహసి స్థితః৷\nఏకాకీ యతచిత్తాత్మా, నిరాశీరపరిగ్రహః ৷৷6.10৷৷",

    "శుచౌ దేశే ప్రతిష్ఠాప్య, స్థిరమాసనమాత్మనః৷\nనాత్యుచ్ఛ్రితం(న్) నాతినీచం(ఞ్), చైలాజినకుశోత్తరమ్ ৷৷6.11৷৷",

    "తత్రైకాగ్రం(మ్) మనః(ఖ్) కృత్వా, యతచిత్తేన్ద్రియక్రియః৷\nఉపవిశ్యాసనే యుఞ్జ్యాద్, యోగమాత్మవిశుద్ధయే ৷৷6.12৷৷",

    "సమం(ఙ్)కాయశిరోగ్రీవం(న్), ధారయన్నచలం(మ్) స్థిరః৷\nసమ్ప్రేక్ష్య నాసికాగ్రం(మ్) స్వం(న్), దిశశ్చానవలోకయన్ ৷৷6.13৷৷",

    "ప్రశాన్తాత్మా విగతభీః(ర్), బ్రహ్మచారివ్రతే స్థితః৷\nమనః(స్) సంయమ్య మచ్చిత్తో, యుక్త ఆసీత మత్పరః ৷৷6.14৷৷",

    "యుఞ్జన్నేవం(మ్) సదాత్మానం(మ్), యోగీ నియతమానసః৷\nశాన్తిం(న్) నిర్వాణపరమాం(మ్), మత్సంస్థామధిగచ్ఛతి ৷৷6.15৷৷",

    "నాత్యశ్నతస్తు యోగో౽స్తి, న చైకాన్తమనశ్నతః৷\nన చాతిస్వప్నశీలస్య, జాగ్రతో నైవ చార్జున ৷৷6.16৷৷",

    "యుక్తాహారవిహారస్య, యుక్తచేష్టస్య కర్మసు৷\nయుక్తస్వప్నావబోధస్య, యోగో భవతి దుఃఖహా ৷৷6.17৷৷",

    "యదా వినియతం(ఞ్)చిత్తమ్, ఆత్మన్యేవావతిష్ఠతే৷\nనిః స్పృహః(స్) సర్వకామేభ్యో, యుక్త ఇత్యుచ్యతే తదా ৷৷6.18৷৷",

    "యథా దీపో నివాతస్థో, నేఙ్గతే సోపమా స్మృతా৷\nయోగినో యతచిత్తస్య, యుఞ్జతో యోగమాత్మనః ৷৷6.19৷৷",

    "యత్రోపరమతే చిత్తం(న్), నిరుద్ధం(మ్) యోగసేవయా৷\nయత్ర చైవాత్మనాత్మానం(మ్), పశ్యన్నాత్మని తుష్యతి ৷৷6.20৷৷",

    "సుఖమాత్యన్తికం(మ్) యత్తద్, బుద్ధిగ్రాహ్యమతీన్ద్రియమ్৷\nవేత్తి యత్ర న చైవాయం(మ్), స్థితశ్చలతి తత్త్వతః ৷৷6.21৷৷",

    "యం(మ్) లబ్ధ్వా చాపరం(మ్) లాభం(మ్), మన్యతే నాధికం(న్) తతః৷\nయస్మిన్స్థితో న దుఃఖేన, గురుణాపి విచాల్యతే ৷৷6.22৷৷",

    "తం(మ్) విద్యాద్ దుఃఖసంయోగ, వియోగం(మ్) యోగసఞ్జ్ఞితమ్৷\nస నిశ్చయేన యోక్తవ్యో, యోగో౽నిర్విణ్ణచేతసా ৷৷6.23৷৷",

    "సఙ్కల్పప్రభవాన్కామాంస్, త్యక్త్వా సర్వానశేషతః৷\nమనసైవేన్ద్రియగ్రామం(మ్), వినియమ్య సమన్తతః ৷৷6.24৷৷",

    "శనైః(శ్) శనైరుపరమేద్, బుద్ధ్యా ధృతిగృహీతయా৷\nఆత్మసంస్థం(మ్) మనః(ఖ్) కృత్వా, న కిఞ్చిదపి చిన్తయేత్ ৷৷6.25৷৷",

    "యతో యతో నిశ్చరతి, మనశ్చఞ్చలమస్థిరమ్৷\nతతస్తతో నియమ్యైతద్, ఆత్మన్యేవ వశం(న్) నయేత్ ৷৷6.26৷৷",

    "ప్రశాన్తమనసం(మ్) హ్యేనం(మ్), యోగినం(మ్) సుఖముత్తమమ్৷\nఉపైతి శాన్తరజసం(మ్), బ్రహ్మభూతమకల్మషమ్ ৷৷6.27৷৷",

    "యుఞ్జన్నేవం(మ్) సదాత్మానం(మ్), యోగీ విగతకల్మషః৷\nసుఖేన బ్రహ్మసంస్పర్శమ్, అత్యన్తం(మ్) సుఖమశ్నుతే ৷৷6.28৷৷",

    "సర్వభూతస్థమాత్మానం(మ్), సర్వభూతాని చాత్మని৷\nఈక్షతే యోగయుక్తాత్మా, సర్వత్ర సమదర్శనః ৷৷6.29৷৷",

    "యో మాం(మ్) పశ్యతి సర్వత్ర, సర్వం(ఞ్) చ మయి పశ్యతి৷\nతస్యాహం(న్) న ప్రణశ్యామి, స చ మే న ప్రణశ్యతి ৷৷6.30৷৷",

    "సర్వభూతస్థితం(మ్) యో మాం(మ్), భజత్యేకత్వమాస్థితః৷\nసర్వథా వర్తమానో౽పి, స యోగీ మయి వర్తతే ৷৷6.31৷৷",

    "ఆత్మౌపమ్యేన సర్వత్ర, సమం(మ్) పశ్యతి యో౽ర్జున৷\nసుఖం(మ్) వా యది వా దుఃఖం(మ్), స యోగీ పరమో మతః ৷৷6.32৷৷",

    "అర్జున ఉవాచ\nయో౽యం(మ్)యోగస్త్వయా ప్రోక్తః(స్), సామ్యేన మధుసూదన৷\nఏతస్యాహం(న్) న పశ్యామి, చఞ్చలత్వాత్స్థితిం(మ్) స్థిరామ్ ৷৷6.33৷৷",

    "చఞ్చలం(మ్) హి మనః(ఖ్) కృష్ణ, ప్రమాథి బలవద్దృఢమ్৷\nతస్యాహం(న్) నిగ్రహం(మ్) మన్యే, వాయోరివ సుదుష్కరమ్ ৷৷6.34৷৷",

    "శ్రీ భగవానువాచ\nఅసంశయం(మ్)మహాబాహో, మనో దుర్నిగ్రహం(ఞ్) చలమ్৷\nఅభ్యాసేన తు కౌన్తేయ, వైరాగ్యేణ చ గృహ్యతే ৷৷6.35৷৷",

    "అసంయతాత్మనా యోగో, దుష్ప్రాప ఇతి మే మతిః৷\nవశ్యాత్మనా తు యతతా, శక్యో౽వాప్తుముపాయతః ৷৷6.36৷৷",

    "అర్జున ఉవాచ\nఅయతిః(శ్) శ్రద్ధయోపేతో, యోగాచ్చలితమానసః৷\nఅప్రాప్య యోగసంసిద్ధిం(ఙ్), కాం(ఙ్)గతిం(ఙ్) కృష్ణ గచ్ఛతి ৷৷6.37৷৷",

    "కచ్చిన్నోభయవిభ్రష్టః(శ్), ఛిన్నాభ్రమివ నశ్యతి৷\nఅప్రతిష్ఠో మహాబాహో, విమూఢో బ్రహ్మణః(ఫ్)పథి ৷৷6.38৷৷",

    "ఏతన్మే సంశయం(ఙ్) కృష్ణ, ఛేత్తుమర్హస్యశేషతః৷\nత్వదన్యః(స్) సంశయస్యాస్య, ఛేత్తా న హ్యుపపద్యతే ৷৷6.39৷৷",

    "శ్రీ భగవానువాచ\nపార్థ నైవేహ నాముత్ర, వినాశస్తస్య విద్యతే৷\nనహి కల్యాణకృత్కశ్చిద్, దుర్గతిం(న్) తాత గచ్ఛతి ৷৷6.40৷৷",

    "ప్రాప్య పుణ్యకృతాం(మ్) లోకాన్, ఉషిత్వా శాశ్వతీః(స్) సమాః৷\nశుచీనాం(మ్) శ్రీమతాం(ఙ్) గేహే, యోగభ్రష్టో౽భిజాయతే్ ৷৷6.41৷৷",

    "అథవా యోగినామేవ, కులే భవతి ధీమతామ్৷\nఏతద్ధి దుర్లభతరం(మ్), లోకే జన్మ యదీదృశమ్ ৷৷6.42৷৷",

    "తత్ర తం(మ్) బుద్ధిసంయోగం(మ్), లభతే పౌర్వదేహికమ్৷\nయతతే చ తతో భూయః(స్), సంసిద్ధౌ కురునన్దన ৷৷6.43৷৷",

    "పూర్వాభ్యాసేన తేనైవ, హ్రియతే హ్యవశో౽పి సః৷\nజిజ్ఞాసురపి యోగస్య, శబ్దబ్రహ్మాతివర్తతే ৷৷6.44৷৷",

    "ప్రయత్నాద్యతమానస్తు, యోగీ సంశుద్ధకిల్బిషః৷\nఅనేకజన్మసంసిద్ధః(స్), తతో యాతి పరాం(ఙ్) గతిమ్ ৷৷6.45৷৷",

    "తపస్విభ్యో౽ధికో యోగీ, జ్ఞానిభ్యో౽పి మతో౽ధికః৷\nకర్మిభ్యశ్చాధికో యోగీ, తస్మాద్యోగీ భవార్జున ৷৷6.46৷৷",

    "యోగినామపి సర్వేషాం(మ్), మద్గతేనాన్తరాత్మనా৷\nశ్రద్ధావాన్భజతే యో మాం(మ్), స మే యుక్తతమో మతః ৷৷6.47৷৷",

    "ఓం తత్సదితి శ్రీమద్భగవద్గీతాసు ఉపనిషత్సు బ్రహ్మవిద్యాయాం(మ్)\nయోగశాస్త్రే శ్రీకృష్ణార్జునసంవాదే ఆత్మసంయమయోగో నామ షష్ఠో౽ధ్యాయః ॥6॥"
  ],
  "adhyaya-7" : [
    "శ్రీ భగవానువాచ\nమయ్యాసక్తమనాః(ఫ్) పార్థ, యోగం(మ్) యుఞ్జన్మదాశ్రయః৷\nఅసంశయం(మ్) సమగ్రం(మ్) మాం(మ్), యథా జ్ఞాస్యసి తచ్ఛృణు ৷৷7.1॥",

    "జ్ఞానం(న్) తే౽హం(మ్) సవిజ్ఞానమ్, ఇదం(మ్) వక్ష్యామ్యశేషతః৷\nయజ్జ్ఞాత్వా నేహ భూయో౽న్యజ్, జ్ఞాతవ్యమవశిష్యతే ৷৷7.2॥",

    "మనుష్యాణాం(మ్) సహస్రేషు, కశ్చిద్యతతి సిద్ధయే ৷\nయతతామపి సిద్ధానాం(ఙ్), కశ్చిన్మాం(మ్) వేత్తి తత్త్వతః ৷৷7.3॥",

    "భూమిరాపో౽నలో వాయుః(ఖ్), ఖం(మ్) మనో బుద్ధిరేవ చ৷\nఅహఙ్కార ఇతీయం(మ్) మే, భిన్నా ప్రకృతిరష్టధా ৷৷7.4॥",

    "అపరేయమితస్త్వన్యాం(మ్), ప్రకృతిం(మ్) విద్ధి మే పరామ్৷\nజీవభూతాం(మ్) మహాబాహో, యయేదం(న్) ధార్యతే జగత్ ৷৷7.5॥",

    "ఏతద్యోనీని భూతాని, సర్వాణీత్యుపధారయ ৷\nఅహం(ఙ్) కృత్స్నస్య జగతః(ఫ్), ప్రభవః(ఫ్) ప్రలయస్తథా ৷৷7.6॥",

    "మత్తః(ఫ్)పరతరం(న్) నాన్యత్, కిఞ్చిదస్తి ధనఞ్జయ ৷\nమయి సర్వమిదం(మ్) ప్రోతం(మ్), సూత్రే మణిగణా ఇవ ৷৷7.7॥",

    "రసో౽హమప్సు కౌన్తేయ, ప్రభాస్మి శశిసూర్యయోః ৷\nప్రణవః(స్) సర్వవేదేషు, శబ్దః(ఖ్) ఖే పౌరుషం(న్) నృషు ৷৷7.8॥",

    "పుణ్యో గన్ధః(ఫ్) పృథివ్యాం(ఞ్) చ, తేజశ్చాస్మి విభావసౌ৷\nజీవనం(మ్)  సర్వభూతేషు, తపశ్చాస్మి తపస్విషు ৷৷7.9॥",

    "బీజం(మ్) మాం(మ్) సర్వభూతానాం(మ్), విద్ధి పార్థ సనాతనమ్ ৷\nబుద్ధిర్బుద్ధిమతామస్మి, తేజస్తేజస్వినామహమ్ ৷৷7.10॥",

    "బలం(మ్) బలవతా(ఞ్) చాహం(ఙ్), కామరాగవివర్జితమ్৷\nధర్మావిరుద్ధో భూతేషు, కామో౽స్మి భరతర్షభ ৷৷7.11॥",

    "యే చైవ సాత్త్వికా భావా, రాజసాస్తామసాశ్చ యే৷\nమత్త ఏవేతి తాన్విద్ధి, న త్వహం(న్) తేషు తే మయి ৷৷7.12॥",

    "త్రిభిర్గుణమయైర్భావైః(ర్), ఏభిః(స్) సర్వమిదం(ఞ్) జగత్ ৷\nమోహితం(న్) నాభిజానాతి, మామేభ్యః(ఫ్) పరమవ్యయమ్ ৷৷7.13॥",

    "దైవీ హ్యేషా గుణమయీ, మమ మాయా దురత్యయా ৷\nమామేవ యే ప్రపద్యన్తే, మాయామేతాం(న్) తరన్తి తే ৷৷7.14॥",

    "న మాం(న్) దుష్కృతినో మూఢాః(ఫ్), ప్రపద్యన్తే నరాధమాః৷\nమాయయాపహృతజ్ఞానా, ఆసురం(మ్) భావమాశ్రితాః ৷৷7.15॥",

    "చతుర్విధా భజన్తే మాం(ఞ్), జనాః(స్) సుకృతినో౽ర్జున৷\nఆర్తో జిజ్ఞాసురర్థార్థీ, జ్ఞానీ చ భరతర్షభ ৷৷7.16॥",

    "తేషాం(ఞ్) జ్ఞానీ నిత్యయుక్త, ఏకభక్తిర్విశిష్యతే৷\nప్రియో హి జ్ఞానినో౽త్యర్థమ్, అహం(మ్) స చ మమ ప్రియః ৷৷7.17॥",

    "ఉదారాః(స్) సర్వ ఏవైతే, జ్ఞానీ త్వాత్మైవ మే మతమ్৷\nఆస్థితః(స్)స హి యుక్తాత్మా, మామేవానుత్తమాం(ఙ్) గతిమ్ ৷৷7.18॥",

    "బహూనాం(ఞ్) జన్మనామన్తే, జ్ఞానవాన్మాం(మ్) ప్రపద్యతే৷\nవాసుదేవః(స్) సర్వమితి, స మహాత్మా సుదుర్లభః ৷৷7.19॥",

    "కామైస్తైస్తైర్హృతజ్ఞానాః(ఫ్), ప్రపద్యన్తే౽న్యదేవతాః ৷\nతం(న్) తం(న్) నియమమాస్థాయ, ప్రకృత్యా నియతాః(స్) స్వయా ৷৷7.20॥",

    "యో యో యాం(మ్) యాం(న్) తనుం(మ్) భక్తః(శ్), శ్రద్ధయార్చితుమిచ్ఛతి|\nతస్య తస్యాచలాం(మ్) శ్రద్ధాం(న్), తామేవ విదధామ్యహమ్ ৷৷7.21॥",

    "స తయా శ్రద్ధయా యుక్తః(స్), తస్యారాధనమీహతే৷\nలభతే చ తతః(ఖ్) కామాన్, మయైవ విహితాన్హి తాన్ ৷৷7.22॥",

    "అన్తవత్తు ఫలం(న్) తేషాం(న్), తద్భవత్యల్పమేధసామ్৷\nదేవాన్దేవయజో యాన్తి, మద్భక్తా యాన్తి మామపి ৷৷7.23॥",

    "అవ్యక్తం(మ్) వ్యక్తిమాపన్నం(మ్), మన్యన్తే మామబుద్ధయః৷\nపరం(మ్) భావమజానన్తో, మమావ్యయమనుత్తమమ్ ৷৷7.24॥",

    "నాహం(మ్) ప్రకాశః(స్) సర్వస్య, యోగమాయాసమావృతః৷\nమూఢో౽యం(న్) నాభిజానాతి, లోకో మామజమవ్యయమ్ ৷৷7.25॥",

    "వేదాహం(మ్) సమతీతాని, వర్తమానాని చార్జున৷\nభవిష్యాణి చ భూతాని, మాం(న్) తు వేద న కశ్చన ৷৷7.26॥",

    "ఇచ్ఛాద్వేషసముత్థేన, ద్వన్ద్వమోహేన భారత৷\nసర్వభూతాని సమ్మోహం(మ్), సర్గే యాన్తి పరన్తప ৷৷7.27॥",

    "యేషాం(న్) త్వన్తగతం(మ్) పాపం(ఞ్), జనానాం(మ్) పుణ్యకర్మణామ్৷\nతే ద్వన్ద్వమోహనిర్ముక్తా, భజన్తే మాం(న్) దృఢవ్రతాః ৷৷7.28॥",

    "జరామరణమోక్షాయ, మామాశ్రిత్య యతన్తి యే৷\nతే బ్రహ్మ తద్విదుః(ఖ్) కృత్స్నమ్, అధ్యాత్మం(ఙ్) కర్మ చాఖిలమ్ ৷৷7.29॥",

    "సాధిభూతాధిదైవం(మ్) మాం(మ్), సాధియజ్ఞం(ఞ్)చ యే విదుః৷\nప్రయాణకాలే౽పి చ మాం(న్), తే విదుర్యుక్తచేతసః ৷৷7.30॥",

    "ఓం తత్సదితి శ్రీ మద్భగవద్గీతాసు ఉపనిషత్సు బ్రహ్మవిద్యాయామ్ యోగశాస్త్రే\nశ్రీ కృష్ణార్జునసంవాదే జ్ఞానవిజ్ఞానయోగోనామ సప్తమో౽ధ్యాయః ॥7॥"
  ],
  "adhyaya-8" : [
    "అర్జున ఉవాచ\nకిం(న్) తద్బ్రహ్మ కిమధ్యాత్మం(ఙ్), కిం(ఙ్) కర్మ పురుషోత్తమ৷\nఅధిభూతం(ఞ్) చ కిం(మ్) ప్రోక్తమ్,అధిదైవం(ఙ్) కిముచ్యతే ৷৷8.1॥",

    "అధియజ్ఞః(ఖ్) కథం(ఙ్) కో౽త్ర, దేహే౽స్మిన్మధుసూదన৷\nప్రయాణకాలే చ కథం(ఞ్),జ్ఞేయో౽సి నియతాత్మభిః ৷৷8.2॥",

    "శ్రీ భగవానువాచ\nఅక్షరం(మ్) బ్రహ్మ పరమం(మ్), స్వభావో౽ధ్యాత్మముచ్యతే৷\nభూతభావోద్భవకరో, విసర్గః(ఖ్) కర్మసఞ్జ్ఞితః ৷৷8.3॥",

    "అధిభూతం(ఙ్) క్షరో భావః(ఫ్), పురుషశ్చాధిదైవతమ్৷\nఅధియజ్ఞో౽హమేవాత్ర, దేహే దేహభృతాం(మ్) వర ৷৷8.4॥",

    "అన్తకాలే చ మామేవ, స్మరన్ముక్త్వా కలేవరమ్৷\nయః(ఫ్) ప్రయాతి స మద్భావం(మ్), యాతి నాస్త్యత్ర సంశయః ৷৷8.5॥",

    "యం(మ్) యం(మ్) వాపి స్మరన్భావం(న్), త్యజత్యన్తే కలేవరమ్৷\nతం(న్) తమేవైతి కౌన్తేయ, సదా తద్భావభావితః ৷৷8.6॥",

    "తస్మాత్సర్వేషు కాలేషు, మామనుస్మర యుధ్య చ৷\nమయ్యర్పితమనోబుద్ధిః(ర్),మామేవైష్యస్యసంశయమ్ ৷৷8.7॥",

    "అభ్యాసయోగయుక్తేన,చేతసా నాన్యగామినా৷\nపరమం(మ్) పురుషం(న్) దివ్యం(మ్), యాతి పార్థానుచిన్తయన్ ৷৷8.8॥",

    "కవిం(మ్) పురాణమనుశాసితారమ్, \nఅణోరణీయాంసమనుస్మరేద్యః৷\nసర్వస్య ధాతారమచిన్త్యరూపమ్,\nఆదిత్యవర్ణం(న్) తమసః(ఫ్) పరస్తాత్ ৷৷8.9॥",

    "ప్రయాణకాలే మనసాచలేన,\nభక్త్యా యుక్తో యోగబలేన చైవ৷\nభ్రువోర్మధ్యే ప్రాణమావేశ్య సమ్యక్, \nస తం(మ్) పరం(మ్) పురుషముపైతి దివ్యమ్ ৷৷8.10॥",

    "యదక్షరం(మ్) వేదవిదో వదన్తి,\nవిశన్తి యద్యతయో వీతరాగాః৷\nయదిచ్ఛన్తో బ్రహ్మచర్యం(ఞ్) చరన్తి, \nతత్తే పదం(మ్) సఙ్గ్రహేణ ప్రవక్ష్యే ৷৷8.11॥",

    "సర్వద్వారాణి సంయమ్య, మనో హృది నిరుధ్య చ৷\nమూర్ధ్న్యాధాయాత్మనః(ఫ్) ప్రాణమ్, ఆస్థితో యోగధారణామ్ ৷৷8.12॥",

    "ఓమిత్యేకాక్షరం(మ్) బ్రహ్మ, వ్యాహరన్మామనుస్మరన్৷\nయః(ఫ్) ప్రయాతి త్యజన్దేహం(మ్),స యాతి పరమాం(ఙ్) గతిమ్ ৷৷8.13॥",

    "అనన్యచేతాః(స్) సతతం(మ్), యో మాం(మ్) స్మరతి నిత్యశః৷\nతస్యాహం(మ్) సులభః(ఫ్) పార్థ, నిత్యయుక్తస్య యోగినః ৷৷8.14॥",

    "మాముపేత్య పునర్జన్మ, దుఃఖాలయమశాశ్వతమ్৷\nనాప్నువన్తి మహాత్మానః(స్), సంసిద్ధిం(మ్) పరమాం(ఙ్) గతాః ৷৷8.15॥",

    "ఆబ్రహ్మభువనాల్లోకాః(ఫ్), పునరావర్తినో౽ర్జున৷\nమాముపేత్య తు కౌన్తేయ, పునర్జన్మ న విద్యతే ৷৷8.16॥",

    "సహస్రయుగపర్యన్తమ్, అహర్యద్బ్రహ్మణో విదుః৷\nరాత్రిం(మ్) యుగసహస్రాన్తాం(న్), తే౽హోరాత్రవిదో జనాః ৷৷8.17॥",

    "అవ్యక్తాద్వ్యక్తయః(స్) సర్వాః(ఫ్), ప్రభవన్త్యహరాగమే৷\nరాత్వర్యాగమే ప్రలీయన్తే, తత్రైవావ్యక్తసఞ్జ్ఞకే ৷৷8.18॥",

    "భూతగ్రామః(స్) స ఏవాయం(మ్), భూత్వా భూత్వా ప్రలీయతే৷\nరాత్ర్యాగమే౽వశః(ఫ్) పార్థ, ప్రభవత్యహరాగమే ৷৷8.19॥",

    "పరస్తస్మాత్తు భావో౽న్యో-౽వ్యక్తో౽వ్యక్తాత్సనాతనః৷\nయః(స్) స సర్వేషు భూతేషు, నశ్యత్సు న వినశ్యతి ৷৷8.20॥",

    "అవ్యక్తో౽క్షర ఇత్యుక్తః(స్),తమాహుః(ఫ్) పరమాం(ఙ్) గతిమ్৷\nయం(మ్) ప్రాప్య న నివర్తన్తే, తద్ధామ పరమం(మ్) మమ ৷৷8.21॥",

    "పురుషః(స్) స పరః(ఫ్)పార్థ, భక్త్యా లభ్యస్త్వనన్యయా৷\nయస్యాన్తః(స్)స్థాని భూతాని, యేన సర్వమిదం(న్) తతమ్ ৷৷8.22॥",

    "యత్ర కాలే త్వనావృత్తిమ్, ఆవృత్తిం(ఞ్) చైవ యోగినః৷\nప్రయాతా యాన్తి తం(ఙ్) కాలం(మ్), వక్ష్యామి భరతర్షభ ৷৷8.23॥",

    "అగ్నిర్జ్యోతిరహః(శ్) శుక్లః(ష్), షణ్మాసా ఉత్తరాయణమ్৷\nతత్ర ప్రయాతా గచ్ఛన్తి, బ్రహ్మ బ్రహ్మవిదో జనాః ৷৷8.24॥",

    "ధూమో రాత్రిస్తథా కృష్ణః(ష్), షణ్మాసా దక్షిణాయనమ్৷\nతత్ర చాన్ద్రమసం(ఞ్) జ్యోతిః(ర్), యోగీ ప్రాప్య నివర్తతే ৷৷8.25॥",

    "శుక్లకృష్ణే గతీ హ్యేతే, జగతః(శ్)శాశ్వతే మతే৷\nఏకయా యాత్యనావృత్తిమ్,అన్యయావర్తతే పునః ৷৷8.26॥",

    "నైతే సృతీ పార్థ జానన్,యోగీ ముహ్యతి కశ్చన৷\nతస్మాత్సర్వేషు కాలేషు, యోగయుక్తో భవార్జున ৷৷8.27॥",

    "వేదేషు యజ్ఞేషు తపః(స్)సు చైవ,\nదానేషు యత్పుణ్యఫలం(మ్) ప్రదిష్టమ్৷\nఅత్యేతి తత్సర్వమిదం(మ్) విదిత్వా, \nయోగీ పరం(మ్) స్థానముపైతి చాద్యమ్ ৷৷8.28॥",

    "ఓం తత్సదితి శ్రీమద్భగవద్గీతాసు ఉపనిషత్సు బ్రహ్మవిద్యాయాం(య్ఁ)\nయోగశాస్త్ర శ్రీకృష్ణార్జునసంవాదే అక్షరబ్రహ్మయోగో నామ అష్టమో౽ధ్యాయః ॥8॥"
  ],
  "adhyaya-9" : [
    "శ్రీ భగవానువాచ\nఇదం(న్) తు తే గుహ్యతమం(మ్) , ప్రవక్ష్యామ్యనసూయవే\nజ్ఞానం(వ్ఁ) విజ్ఞానసహితం(య్ఁ) , యజ్జ్ఞాత్వా మోక్ష్యసే౽శుభాత్ ॥9.1॥",

    "రాజవిద్యా రాజగుహ్యం(మ్), పవిత్రమిదముత్తమమ్\nప్రత్యక్షావగమం(న్)ధర్మ్యం(మ్), సుసుఖం(ఙ్) కర్తుమవ్యయమ ॥9.2॥",

    "అశ్రద్దధానాః(ఫ్) పురుషా, ధర్మస్యాస్య పరన్తప\nఅప్రాప్య మాం(న్) నివర్తన్తే, మృత్యుసంసారవర్త్మని ॥9.3॥",

    "మయా తతమిదం(మ్) సర్వం(ఞ్), జగదవ్యక్తమూర్తినా\nమత్స్థాని సర్వభూతాని, న చాహం(న్) తేష్వవస్థితః ॥9.4॥",

    " న చ మత్స్థాని భూతాని, పశ్య మే యోగమైశ్వరమ్\nభూతభృన్న చ భూతస్థో, మమాత్మా భూతభావనః ॥9.5॥",

    "యథాకాశస్థితో నిత్యం(వ్ఁ), వాయుః(స్) సర్వత్రగో మహాన్\nతథా సర్వాణి భూతాని, మత్స్థానీత్యుపధారయ ॥9.6॥",

    "సర్వభూతాని కౌన్తేయ, ప్రకృతిం(య్ఁ) యాన్తి మామికామ్\nకల్పక్షయే పునస్తాని, కల్పాదౌ విసృజామ్యహమ్ ॥9.7॥",

    "ప్రకృతిం(మ్) స్వామవష్టభ్య, విసృజామి పునః(ఫ్) పునః \nభూతగ్రామమిమం(ఙ్) కృత్స్నమ్, అవశం(మ్) ప్రకృతేర్వశాత ॥9.8॥",

    "న చ మాం(న్)తాని కర్మాణి, నిబధ్నన్తి ధనఞ్జయ\nఉదాసీనవదాసీనమ్, అసక్తం(న్) తేషు కర్మసు ॥9.9॥",

    "మయాధ్యక్షేణ ప్రకృతిః(స్), సూయతే సచరాచరమ్\nహేతునానేన కౌన్తేయ, జగద్విపరివర్తతే ॥9.10॥",

    "అవజానన్తిమాం(మ్) మూఢా, మానుషీం(న్) తనుమాశ్రితమ్ \nపరం(మ్)  భావమజానన్తో, మమ భూతమహేశ్వరమ ॥9.11॥",

    "మోఘాశా మోఘకర్మాణో, మోఘజ్ఞానా విచేతసః \nరాక్షసీమాసురీం(ఞ్) చైవ, ప్రకృతిం(మ్)  మోహినీం(మ్) శ్రితాః ॥9.12॥",

    "మహాత్మానస్తు మాం(మ్)  పార్థ, దైవీం(మ్)  ప్రకృతిమాశ్రితాః \nభజన్త్యనన్యమనసో, జ్ఞాత్వా భూతాదిమవ్యయమ ॥9.13॥",

    "సతతం(ఙ్) కీర్తయన్తో మాం(య్ఁ) , యతన్తశ్చ దృఢవ్రతాః \nనమస్యన్తశ్చ మాం(మ్) భక్త్యా , నిత్యయుక్తా ఉపాసతే ॥9.14॥",

    "జ్ఞానయజ్ఞేన చాప్యన్యే,  యజన్తో మాముపాసతే \nఏకత్వేన పృథక్త్వేన,  బహుధా విశ్వతోముఖమ ॥9.15॥",

    "అహం(ఙ్) క్రతురహం(య్ఁ) యజ్ఞః(స్) ,  స్వధాహమహమౌషధమ్\nమంత్రో౽హమహమేవాజ్యమ్, అహమగ్నిరహం(మ్)  హుతమ్ ॥9.16॥",

    "పితాహమస్య జగతో, మాతా ధాతా పితామహః \nవేద్యం(మ్) పవిత్రమోంకార, ఋక్సామ యజురేవ చ ॥9.17॥",

    "గతిర్భర్తా ప్రభుః(స్ )  సాక్షీ, నివాసః(శ్ ) శరణం(మ్ ) సుహృత్\nప్రభవః (ఫ్)  ప్రలయః( స్)   స్థానం(న్),   నిధానం(మ్) బీజమవ్యయమ ॥9.18॥",

    "తపామ్యహమహం(వ్ఁ) వర్షం(న్) ,  నిగృహ్ణామ్యుత్సృజామి చ\nఅమృతం(ఞ్) చైవ మృత్యుశ్చ , సదసచ్చాహమర్జున ॥9.19॥",

    "త్రైవిద్యా మాం(మ్) సోమపాః(ఫ్) పూతపాపా,\nయజ్ఞైరిష్ట్వా స్వర్గతిం(మ్) ప్రార్థయన్తే\nతే   పుణ్యమాసాద్య సురేన్ద్రలోకమ్,\nఅశ్నన్తి దివ్యాన్దివి దేవభోగాన ॥9.20॥",

    "తే తం(మ్) భుక్త్వా స్వర్గలోకం(వ్ఁ) విశాలం(ఙ్),\nక్షీణే   పుణ్యే     మర్త్యలోకం(వ్ఁ)   విశన్తి \nఏవ(న్)    త్రయీధర్మమనుప్రపన్నా,\nగతాగతం(ఙ్)   కామకామా    లభన్తే ॥9.21॥",

    "అనన్యాశ్చిన్తయన్తో మాం(య్ఁ) , యే జనాః(ఫ్) పర్యుపాసతే \nతేషాం(న్) నిత్యాభియుక్తానాం(య్ఁ) , యోగక్షేమం(వ్ఁ) వహామ్యహమ్ ॥9.22॥",

    "యే ౽ప్యన్యదేవతా భక్తా,  యజన్తే శ్రద్ధయాన్వితాః \nతే౽పి మామేవ కౌన్తేయ, యజన్త్యవిధిపూర్వకమ ॥9.23॥",

    "అహం(మ్) హి సర్వయజ్ఞానాం(మ్),  భోక్తా చ ప్రభురేవ చ \nన తు మామభిజానన్తి,  తత్త్వేనాతశ్చ్యవన్తి తే ॥9.24॥",

    "యాన్తి దేవవ్రతా దేవాన్, పితౄన్యాన్తి పితృవ్రతాః \nభూతాని యాన్తి భూతేజ్యా, యాన్తి మద్యాజినో౽పి మామ ॥9.25॥",

    "పత్రం(మ్) పుష్పం(మ్) ఫలం(న్) తోయం(య్ఁ) ,  యో మే భక్త్యా ప్రయచ్ఛతి \nతదహం(మ్)    భక్త్యుపహృతమ్, అశ్నామి   ప్రయతాత్మనః ॥9.26॥",

    "యత్కరోషి యదశ్నాసి, యజ్జుహోషి దదాసి యత్ \nయత్తపస్యసి కౌన్తేయ, తత్కురుష్వ మదర్పణమ ॥9.27॥",

    "శుభాశుభఫలైరేవం(మ్), మోక్ష్యసే కర్మబన్ధనైః \nసన్న్యాసయోగయుక్తాత్మా, విముక్తో మాముపైష్యసి ॥9.28॥",

    "సమో౽హం(మ్)  సర్వభూతేషు, న మే ద్వేష్యో౽స్తి న ప్రియః \nయే భజన్తి తు మాం(మ్)  భక్త్యా,  మయి తే తేషు చాప్యహమ ॥9.29॥",

    "అపి చేత్సుదురాచారో, భజతే మామనన్యభాక్\nసాధురేవ స మన్తవ్యః (స్),   సమ్యగ్వ్యవసితో హి సః ॥9.30॥",

    "క్షిప్రం(మ్) భవతి ధర్మాత్మా,  శశ్వచ్ఛాన్తిం(న్) నిగచ్ఛతి\nకౌన్తేయ ప్రతిజానీహి, న మే భక్తః(ఫ్) ప్రణశ్యతి ॥9.31॥",

    "మాం(మ్) హి పార్థ వ్యపాశ్రిత్య, యే౽పి స్యుః(ఫ్) పాపయోనయః\nస్త్రియో వైశ్యాస్తథా శూద్రాః(స్), తే౽పి యాన్తి పరాం(ఙ్) గతిమ ॥9.32॥",

    "కిం(మ్) పునర్బ్రాహ్మణాః(ఫ్) పుణ్యా,  భక్తా రాజర్షయస్తథా\nఅనిత్యమసుఖం(మ్) లోకమ్, ఇమ(మ్)  ప్రాప్య భజస్వ మామ ॥9.33॥",

    "మన్మనా భవ మద్భక్తో, మద్యాజీ మాం(న్) నమస్కురు\nమామేవైష్యసి యుక్త్వైవమ్,  ఆత్మానం(మ్) మత్పరాయణః ॥9.34॥",

    "ఓం తత్సదితి శ్రీమద్భగవద్గీతాసు ఉపనిషత్సు బ్రహ్మవిద్యాయాం(య్ఁ)\nయోగశాస్త్రే శ్రీకృష్ణార్జునసంవాదే రాజవిద్యారాజగుహ్యయోగో నామ నవమో౽ధ్యాయః ॥9॥"
  ],
  "adhyaya-10" : [
    "శ్రీ భగవానువాచ\nభూయ ఏవ మహాబాహో, శ్రృణు మే పరమం(మ్) వచః৷\nయత్తే౽హం(మ్) ప్రీయమాణాయ, వక్ష్యామి హితకామ్యయా ৷৷10.1॥",

    "న మే విదుః(స్) సురగణాః(ఫ్), ప్రభవం(న్) న మహర్షయః৷\nఅహమాదిర్హి దేవానాం(మ్), మహర్షీణాం(ఞ్) చ సర్వశః ৷৷10.2॥",

    "యో మామజమనాదిం(ఞ్) చ , వేత్తి లోకమహేశ్వరమ్৷\nఅసమ్మూఢః(స్) స మర్త్యేషు, సర్వపాపైః(ఫ్) ప్రముచ్యతే ৷৷10.3॥",

    "బుద్ధిర్జ్ఞానమసమ్మోహః, క్షమా సత్యం(న్) దమః(శ్) శమః৷\nసుఖం(న్) దుఃఖం(మ్) భవో౽భావో, భయం(ఞ్) చాభయమేవ చ ৷৷10.4॥",

    "అహింసా సమతా తుష్టిః(స్) ,తపో దానం(మ్) యశో౽యశః৷\nభవన్తి భావా భూతానాం(మ్), మత్త ఏవ పృథగ్విధాః ৷৷10.5॥",

    "మహర్షయః(స్) సప్త పూర్వే, చత్వారో మనవస్తథా৷\nమద్భావా మానసా జాతా , యేషాం(మ్) లోక ఇమాః(ఫ్) ప్రజాః ৷৷10.6॥",

    "ఏతాం(మ్) విభూతిం(మ్) యోగం(ఞ్) చ , మమ యో వేత్తి తత్త్వతః৷\nసో౽వికమ్పేన యోగేన , యుజ్యతే నాత్ర సంశయః ৷৷10.7॥",

    "అహం(మ్) సర్వస్య ప్రభవో , మత్తః(స్) సర్వం(మ్) ప్రవర్తతే৷\nఇతి మత్వా భజన్తే మాం(మ్), బుధా భావసమన్వితాః ৷৷10.8॥",

    "మచ్చిత్తా మద్గతప్రాణా , బోధయన్తః(ఫ్) పరస్పరమ్৷\nకథయన్తశ్చ మాం(న్) నిత్యం(న్), తుష్యన్తి చ రమన్తి చ ৷৷10.9॥",

    "తేషాం(మ్) సతతయుక్తానాం(మ్), భజతాం(మ్) ప్రీతిపూర్వకమ్৷\nదదామి బుద్ధియోగం(న్) తం(మ్) , యేన మాముపయాన్తి తే ৷৷10.10॥",

    "తేషామేవానుకమ్పార్థమ్,అహమజ్ఞానజం(న్) తమః৷\nనాశయామ్యాత్మభావస్థో , జ్ఞానదీపేన భాస్వతా ৷৷10.11॥",

    "అర్జున ఉవాచ\nపరం(మ్) బ్రహ్మ పరం(న్) ధామ, పవిత్రం(మ్) పరమం(మ్) భవాన్৷\nపురుషం(మ్) శాశ్వతం(న్) దివ్యమ్, ఆదిదేవమజం(మ్) విభుమ్ ৷৷10.12॥",

    "ఆహుస్త్వామృషయః(స్) సర్వే, దేవర్షిర్నారదస్తథా৷\nఅసితో దేవలో వ్యాసః(స్), స్వయం(ఞ్) చైవ బ్రవీషి మే ৷৷10.13॥",

    "సర్వమేతదృతం(మ్) మన్యే, యన్మాం(మ్) వదసి కేశవ৷\nన హి తే భగవన్వ్యక్తిం(మ్),   విదుర్దేవా న దానవాః ৷৷10.14॥",

    "స్వయమేవాత్మనాత్మానం(మ్) , వేత్థ త్వం(మ్) పురుషోత్తమ৷\nభూతభావన భూతేశ , దేవదేవ జగత్పతే ৷৷10.15॥",

    "వక్తుమర్హస్యశేషేణ , దివ్యా హ్యాత్మవిభూతయః৷\nయాభిర్విభూతిభిర్లోకాన్ , ఇమాంస్త్వం(మ్) వ్యాప్య తిష్ఠసి ৷৷10.16॥",

    "కథం(మ్) విద్యామహం(మ్) యోగింస్ , త్వాం(మ్) సదా పరిచిన్తయన్৷\nకేషు కేషు చ భావేషు , చిన్త్యో౽సి భగవన్మయా ৷৷10.17॥",

    "విస్తరేణాత్మనో యోగం(మ్), విభూతిం(ఞ్) చ జనార్దన৷\nభూయః(ఖ్) కథయ తృప్తిర్హి, శ్రృణ్వతో నాస్తి మే౽మృతమ్ ৷৷10.18॥",

    "శ్రీ భగవానువాచ\nహన్త తే కథయిష్యామి , దివ్యా హ్యాత్మవిభూతయః৷\nప్రాధాన్యతః(ఖ్) కురుశ్రేష్ఠ , నాస్త్యన్తో విస్తరస్య మే ৷৷10.19॥",
    
    "అహమాత్మా గుడాకేశ, సర్వభూతాశయస్థితః৷\nఅహమాదిశ్చ మధ్యం(ఞ్) చ, భూతానామన్త ఏవ చ ৷৷10.20॥",
    
    "ఆదిత్యానామహం(మ్) విష్ణుః(ర్) ,జ్యోతిషాం(మ్)రవిరంశుమాన్৷\nమరీచిర్మరుతామస్మి, నక్షత్రాణామహం(మ్) శశీ ৷৷10.21॥",
    
    "వేదానాం(మ్) సామవేదో౽స్మి, దేవానామస్మి వాసవః৷\nఇన్ద్రియాణాం(మ్) మనశ్చాస్మి , భూతానామస్మి చేతనా ৷৷10.22॥",
    
    "రుద్రాణాం(మ్) శఙ్కరశ్చాస్మి , విత్తేశో యక్షరక్షసామ్৷\nవసూనాం(మ్) పావకశ్చాస్మి ,మేరుః(శ్)శిఖరిణామహమ్ ৷৷10.23॥",
    
    "పురోధసాం(ఞ్) చ ముఖ్యం(మ్) మాం(మ్) , విద్ధి పార్థ బృహస్పతిమ్৷\nసేనానీనామహం(మ్) స్కన్దః(స్) , సరసామస్మి సాగరః ৷৷10.24॥",
    
    "మహర్షీణాం(మ్) భృగురహం(ఙ్), గిరామస్మ్యేకమక్షరమ్৷\nయజ్ఞానాం(ఞ్) జపయజ్ఞో౽స్మి , స్థావరాణాం(మ్) హిమాలయః ৷৷10.25॥",
    
    "అశ్వత్థః(స్) సర్వవృక్షాణాం(న్), దేవర్షీణాం(ఞ్) చ నారదః৷\nగన్ధర్వాణాం(ఞ్) చిత్రరథః(స్), సిద్ధానాం(ఙ్) కపిలో మునిః ৷৷10.26॥",
    
    "ఉచ్చైఃశ్రవసమశ్వానాం(మ్) , విద్ధి మామమృతోద్భవమ్৷\nఐరావతం(ఙ్) గజేన్ద్రాణాం(న్), నరాణాం(ఞ్) చ నరాధిపమ్ ৷৷10.27॥",
    
    "ఆయుధానామహం(మ్) వజ్రం(న్), ధేనూనామస్మి కామధుక్৷\nప్రజనశ్చాస్మి కన్దర్పః(స్), సర్పాణామస్మి వాసుకిః ৷৷10.28॥",

    "అనన్తశ్చాస్మి నాగానాం(మ్) , వరుణో యాదసామహమ్৷\nపితౄణామర్యమా చాస్మి, యమః(స్) సంయమతామహమ్ ৷৷10.29॥",

    "ప్రహ్లాదశ్చాస్మి దైత్యానాం(ఙ్) ,కాలః(ఖ్)కలయతామహమ్৷\nమృగాణాం(ఞ్) చ మృగేన్ద్రో౽హం(మ్), వైనతేయశ్చ పక్షిణామ్ ৷৷10.30॥",

    "పవనః(ఫ్) పవతామస్మి, రామః(శ్) శస్త్రభృతామహమ్৷\nఝషాణాం(మ్) మకరశ్చాస్మి, స్రోతసామస్మి జాహ్నవీ ৷৷10.31॥",

    "సర్గాణామాదిరన్తశ్చ ,మధ్యం(ఞ్) చైవాహమర్జున৷\nఅధ్యాత్మవిద్యా విద్యానాం(మ్), వాదః(ఫ్) ప్రవదతామహమ్ ৷৷10.32॥",

    "అక్షరాణామకారో౽స్మి ,ద్వన్ద్వః(స్)సామాసికస్య చ৷\nఅహమేవాక్షయః(ఖ్) కాలో, ధాతాహం(మ్) విశ్వతోముఖః ৷৷10.33॥",

    "మృత్యుః(స్) సర్వహరశ్చాహమ్,ఉద్భవశ్చ భవిష్యతామ్৷\nకీర్తిః(శ్) శ్రీర్వాక్చ నారీణాం(మ్), స్మృతిర్మేధా ధృతిః క్షమా ৷৷10.34॥",

    "బృహత్సామ తథా సామ్నాం(ఙ్), గాయత్రీ ఛన్దసామహమ్৷\nమాసానాం(మ్) మార్గశీర్షో౽హమ్, ఋతూనాం(ఙ్) కుసుమాకరః ৷৷10.35॥",

    "ద్యూతం(ఞ్) ఛలయతామస్మి, తేజస్తేజస్వినామహమ్৷\nజయో౽స్మి వ్యవసాయో౽స్మి, సత్త్వం(మ్) సత్త్వవతామహమ్ ৷৷10.36॥",

    "వృష్ణీనాం(మ్) వాసుదేవో౽స్మి, పాణ్డవానాం(న్) ధనఞ్జయః৷\nమునీనామప్యహం(మ్) వ్యాసః(ఖ్), కవీనాముశనా కవిః ৷৷10.37॥",

    "దణ్డో దమయతామస్మి , నీతిరస్మి జిగీషతామ్৷\nమౌనం(ఞ్) చైవాస్మి గుహ్యానాం(ఞ్), జ్ఞానం(ఞ్) జ్ఞానవతామహమ్ ৷৷10.38॥",

    "యచ్చాపి సర్వభూతానాం(మ్), బీజం(న్) తదహమర్జున৷\nన తదస్తి వినా యత్స్యాన్, మయా భూతం(ఞ్) చరాచరమ్ ৷৷10.39॥",

    "నాన్తో౽స్తి మమ దివ్యానాం(మ్), విభూతీనాం(మ్) పరన్తప৷\nఏష తూద్దేశతః(ఫ్) ప్రోక్తో, విభూతేర్విస్తరో మయా ৷৷10.40॥",

    "యద్యద్విభూతిమత్సత్త్వం(మ్), శ్రీమదూర్జితమేవ వా৷\nతత్తదేవావగచ్ఛ త్వం(మ్),  మమ తేజోం౽శసమ్భవమ్ ৷৷10.41॥",

    "అథవా బహునైతేన, కిం(ఞ్) జ్ఞాతేన తవార్జున৷\nవిష్టభ్యాహమిదం(ఙ్) కృత్స్నమ్,ఏకాంశేన స్థితో జగత్ ॥10.42॥",

    "ఓం తత్సదితి శ్రీమద్భగవద్గీతాసు ఉపనిషత్సు బ్రహ్మవిద్యాయాం(మ్)\nయోగశాస్త్రే శ్రీకృష్ణార్జునసంవాదే విభూతియోగో నామ దశమో౽ధ్యాయః ॥10॥"
  ],
  "adhyaya-11" : [
    "అర్జున ఉవాచ\nమదనుగ్రహాయ పరమం(ఙ్), గుహ్యమధ్యాత్మసఞ్జ్ఞితమ్৷\nయత్త్వయోక్తం(మ్) వచస్తేన, మోహో౽యం(మ్) విగతో మమ ৷৷11.1৷৷",

    "భవాప్యయౌ హి భూతానాం(మ్), శ్రుతౌ విస్తరశో మయా৷\nత్వత్తః(ఖ్)కమలపత్రాక్ష, మాహాత్మ్యమపి చావ్యయమ్ ৷৷11.2৷৷",

    "ఏవమేతద్యథాత్థ త్వమ్, ఆత్మానం(మ్)పరమేశ్వర৷ \nద్రష్టుమిచ్ఛామి తే రూపమ్, ఐశ్వరం(మ్) పురుషోత్తమ ৷৷11.3৷৷",

    "మన్యసే యది తచ్ఛక్యం(మ్), మయా ద్రష్టుమితి ప్రభో৷\nయోగేశ్వర తతో మే త్వం(న్), దర్శయాత్మానమవ్యయమ్ ৷৷11.4৷৷",

    "శ్రీ భగవానువాచ\nపశ్య మే పార్థ రూపాణి, శతశోథ సహస్రశః৷\nనానావిధాని దివ్యాని, నానావర్ణాకృతీని చ ৷৷11.5৷৷",

    "పశ్యాదిత్యాన్వసూన్రుద్రాన్, అశ్వినౌ మరుతస్తథా৷\nబహూన్యదృష్టపూర్వాణి, పశ్యాశ్చర్యాణి భారత ৷৷11.6৷৷",

    "ఇహైకస్థం(ఞ్) జగత్కృత్స్నం(మ్), పశ్యాద్య సచరాచరమ్৷\nమమ దేహే గుడాకేశ, యచ్చాన్యద్ద్రష్టుమిచ్ఛసి ৷৷11.7৷৷",

    "న తు మాం(మ్) శక్యసే ద్రష్టుమ్, అనేనైవ స్వచక్షుషా |\nదివ్యం(న్) దదామి తే చక్షుః(ష్), పశ్యమే యోగమైశ్వరమ్ ৷৷11.8৷৷",

    "సఞ్జయ ఉవాచ\nఏవముక్త్వా తతో రాజన్, మహాయోగేశ్వరో హరిః৷\nదర్శయామాస పార్థాయ, పరమం(మ్) రూపమైశ్వరమ్ ৷৷11.9৷৷",

    "అనేకవక్త్రనయనమ్, అనేకాద్భుతదర్శనమ్৷\nఅనేకదివ్యాభరణం(మ్), దివ్యానేకోద్యతాయుధమ్ ৷৷11.10৷৷",

    "దివ్యమాల్యామ్బరధరం(న్), దివ్యగన్ధానులేపనమ్৷\nసర్వాశ్చర్యమయం(న్) దేవమ్, అనన్తం(మ్) విశ్వతోముఖమ్ ৷৷11.11৷৷",

    "దివి సూర్యసహస్రస్య, భవేద్యుగపదుత్థితా৷\nయది భాః(స్) సదృశీ సా స్యాద్, భాసస్తస్య మహాత్మనః ৷৷11.12৷৷",

    "తత్రైకస్థం(ఞ్) జగత్కృత్స్నం(మ్), ప్రవిభక్తమనేకధా৷\nఅపశ్యద్దేవదేవస్య, శరీరే పాణ్డవస్తదా ৷৷11.13৷৷",

    "తతః(స్) స విస్మయావిష్టో, హృష్టరోమా ధనఞ్జయః৷\nప్రణమ్య శిరసా దేవం(ఙ్), కృతాఞ్జలిరభాషత ৷৷11.14৷৷",

    "అర్జున ఉవాచ\nపశ్యామి దేవాంస్తవ దేవ దేహే, \nసర్వాంస్తథా భూతవిశేషసఙ్ఘాన్৷\nబ్రహ్మాణమీశం(ఙ్) కమలాసనస్థమ్, \nఋషీంశ్చ సర్వానురగాంశ్చ దివ్యాన్ ৷৷11.15৷৷",

    "అనేకబాహూదరవక్త్రనేత్రం(మ్), \nపశ్యామి త్వాం(మ్) సర్వతో౽నన్తరూపమ్৷\nనాన్తం(న్) న మధ్యం (న్) న పునస్తవాదిం(మ్), \nపశ్యామి విశ్వేశ్వర విశ్వరూప ৷৷11.16৷৷",

    "కిరీటినం(ఙ్) గదినం(ఞ్) చక్రిణం(ఞ్) చ, \nతేజోరాశిం(మ్) సర్వతో దీప్తిమన్తమ్|\nపశ్యామి త్వాం(న్) దుర్నిరీక్ష్యం(మ్) సమన్తాద్- \nదీప్తానలార్కద్యుతిమప్రమేయమ్ ৷৷11.17৷৷",

    "త్వమక్షరం(మ్) పరమం(మ్) వేదితవ్యం(న్), \nత్వమస్య విశ్వస్య పరం(న్) నిధానమ్৷\nత్వమవ్యయః(శ్) శాశ్వతధర్మగోప్తా, \nసనాతనస్త్వం(మ్) పురుషో మతో మే ৷৷11.18৷৷",

    "అనాదిమధ్యాన్తమనన్తవీర్యమ్,\nఅనన్తబాహుం(మ్) శశిసూర్యనేత్రమ్ ৷\nపశ్యామి త్వాం(న్) దీప్తహుతాశవక్త్రం(మ్),\nస్వతేజసా విశ్వమిదం(న్) తపన్తమ్ ৷৷11.19৷৷",

    "ద్యావాపృథివ్యోరిదమన్తరం(మ్) హి,\nవ్యాప్తం(న్) త్వయైకేన దిశశ్చ సర్వాః ৷\nదృష్ట్వాద్భుతం(మ్) రూపముగ్రం(న్) తవేదం(మ్),\nలోకత్రయం(మ్) ప్రవ్యథితం(మ్) మహాత్మన్ ৷৷11.20৷৷",

    "అమీ హి త్వాం(మ్) సురసఙ్ఘా విశన్తి,\nకేచిద్భీతాః(ఫ్) ప్రాఞ్జలయో గృణన్తి৷\nస్వస్తీత్యుక్త్వా మహర్షిసిద్ధసఙ్ఘాః(స్),\nస్తువన్తి త్వాం స్తుతిభిః(ఫ్) పుష్కలాభిః ৷৷11.21৷৷",

    "రుద్రాదిత్యా వసవో యే చ సాధ్యా,\nవిశ్వే౽శ్వినౌ మరుతశ్చోష్మపాశ్చ৷\nగన్ధర్వయక్షాసురసిద్ధసఙ్ఘా,\nవీక్షన్తే త్వాం(మ్) విస్మితాశ్చైవ సర్వే ৷৷11.22৷৷",

    "రూపం(మ్) మహత్తే బహువక్త్రనేత్రం(మ్),\nమహాబాహో బహుబాహూరుపాదమ్৷\nబహూదరం(మ్) బహుదంష్ట్రాకరాలం(న్),\nదృష్ట్వా లోకాః(ఫ్) ప్రవ్యథితాస్తథాహమ్ ৷৷11.23৷৷",

    "నభఃస్పృశం(న్) దీప్తమనేకవర్ణం(మ్),\nవ్యాత్తాననం(న్) దీప్తవిశాలనేత్రమ్৷\nదృష్ట్వా హి త్వాం(మ్) ప్రవ్యథితాన్తరాత్మా,\nధృతిం(న్) న విన్దామి శమం(ఞ్) చ విష్ణో ৷৷11.24৷৷",

    "దంష్ట్రాకరాలాని చ తే ముఖాని,\nదృష్ట్వైవ కాలానలసన్నిభాని ৷\nదిశో న జానే న లభే చ శర్మ,\nప్రసీద దేవేశ జగన్నివాస ৷৷11.25৷৷",

    "అమీ చ త్వాం(న్) ధృతరాష్ట్రస్య పుత్రాః(స్),\nసర్వే సహైవావనిపాలసఙ్ఘైః৷\nభీష్మో ద్రోణః(స్) సూతపుత్రస్తథాసౌ,\nసహాస్మదీయైరపి యోధముఖ్యైః ৷৷11.26৷৷",

    "వక్త్రాణి తే త్వరమాణా విశన్తి,\nదంష్ట్రాకరాలాని భయానకాని৷\nకేచిద్విలగ్నా దశనాన్తరేషు,\nసన్దృశ్యన్తే చూర్ణితైరుత్తమాఙ్గైః ৷৷11.27৷৷",

    "యథా నదీనాం(మ్) బహవో౽మ్బువేగాః(స్),\nసముద్రమేవాభిముఖా ద్రవన్తి৷\nతథా తవామీ నరలోకవీరా,\nవిశన్తి వక్త్రాణ్యభివిజ్వలన్తి ৷৷11.28৷৷",

    "యథా ప్రదీప్తం(ఞ్)జ్వలనం(మ్) పతఙ్గా,\nవిశన్తి నాశాయ సమృద్ధవేగాః ৷\nతథైవ నాశాయ విశన్తి లోకాః(స్),\nతవాపి వక్త్రాణి సమృద్ధవేగాః ৷৷11.29৷৷",

    "లేలిహ్యసే గ్రసమానః(స్) సమన్తాల్,  \nలోకాన్సమగ్రాన్వదనైర్జ్వలద్భిః৷\nతేజోభిరాపూర్య జగత్సమగ్రం(మ్),\nభాసస్తవోగ్రాః(ఫ్) ప్రతపన్తి విష్ణో ৷৷11.30৷৷",

    "ఆఖ్యాహి మే కో భవానుగ్రరూపో,\nనమో౽స్తుతే దేవవర ప్రసీద |\nవిజ్ఞాతుమిచ్ఛామి భవన్తమాద్యం(న్),\nన హి ప్రజానామి తవ ప్రవృత్తిమ్ ৷৷11.31৷৷",

    "శ్రీ భగవానువాచ\nకాలో౽స్మి లోకక్షయకృత్ప్రవృద్ధో,\nలోకాన్సమాహర్తుమిహ ప్రవృత్తః |\nఋతే౽పి త్వాం(న్)న భవిష్యన్తి సర్వే,\nయే౽వస్థితాః(ఫ్) ప్రత్యనీకేషు యోధాః ৷৷11.32৷৷",

    "తస్మాత్త్వముత్తిష్ఠ యశో లభస్వ,\nజిత్వా శత్రూన్భుఙ్క్ష్వ రాజ్యం(మ్) సమృద్ధమ్৷\nమయైవైతే నిహతాః(ఫ్) పూర్వమేవ,\nనిమిత్తమాత్రం(మ్) భవ సవ్యసాచిన్ ৷৷11.33৷৷",

    "ద్రోణం(ఞ్) చ భీష్మం(ఞ్) చ జయద్రథం(ఞ్) చ,\nకర్ణం(న్) తథాన్యానపి యోధవీరాన్৷\nమయా హతాంస్త్వం(ఞ్) జహి మా వ్యథిష్ఠా,\nయుధ్యస్వ జేతాసి రణే సపత్నాన్ ৷৷11.34৷৷",

    "సఞ్జయ ఉవాచ\nఏతచ్ఛ్రుత్వా వచనం(ఙ్) కేశవస్య,\nకృతాఞ్జలిర్వేపమానః(ఖ్) కిరీటీ৷\nనమస్కృత్వా భూయ ఏవాహ కృష్ణం(మ్),\nసగద్గదం(మ్) భీతభీతః(ఫ్) ప్రణమ్య ৷৷11.35৷৷",

    "అర్జున ఉవాచ\nస్థానే హృషీకేశ తవ ప్రకీర్త్యా,\nజగత్ ప్రహృష్యత్యనురజ్యతే చ৷\nరక్షాంసి భీతాని దిశో ద్రవన్తి,\nసర్వే నమస్యన్తి చ సిద్ధసఙ్ఘాః ৷৷11.36৷৷",

    "కస్మాచ్చ తే న నమేరన్మహాత్మన్,\nగరీయసే బ్రహ్మణోప్యాదికర్త్రే৷\nఅనన్త దేవేశ జగన్నివాస,\nత్వమక్షరం(మ్) సదసత్తత్పరం(మ్) యత్ ৷৷11.37৷৷",

    "త్వమాదిదేవః(ఫ్) పురుషః(ఫ్) పురాణః(స్),\nత్వమస్య విశ్వస్య పరం(న్) నిధానమ్৷\nవేత్తాసి వేద్యం(ఞ్) చ పరం(ఞ్) చ ధామ,\nత్వయా తతం(మ్) విశ్వమనన్తరూప ৷৷11.38৷৷",

    "వాయుర్యమో౽గ్నిర్వరుణః(శ్) శశాఙ్కః(ఫ్),\nప్రజాపతిస్త్వం(మ్) ప్రపితామహశ్చ৷\nనమో నమస్తే౽స్తు సహస్రకృత్వః(ఫ్),\nపునశ్చ భూయో౽పి నమో నమస్తే ৷৷11.39৷৷",

    "నమః(ఫ్) పురస్తాదథ పృష్ఠతస్తే,\nనమో౽స్తు తే సర్వత ఏవ సర్వ৷\nఅనన్తవీర్యామితవిక్రమస్త్వం(మ్),\nసర్వం(మ్) సమాప్నోషి తతో౽సి సర్వః ৷৷11.40৷৷",

    "సఖేతి మత్వా ప్రసభం(మ్) యదుక్తం(మ్),\nహే కృష్ణ హే యాదవ హే సఖేతి৷\nఅజానతా మహిమానం(న్) తవేదం(మ్),\nమయా ప్రమాదాత్ప్రణయేన వాపి ৷৷11.41৷৷",

    "యచ్చావహాసార్థమసత్కృతో౽సి,\nవిహారశయ్యాసనభోజనేషు৷\nఏకో౽థవాప్యచ్యుత తత్సమక్షం(న్),\nతత్క్షామయే త్వామహమప్రమేయమ్ ৷৷11.42৷৷",

    "పితాసి లోకస్య చరాచరస్య,\nత్వమస్య పూజ్యశ్చ గురుర్గరీయాన్|\nన త్వత్సమో౽స్త్యభ్యధికః(ఖ్) కుతో౽న్యో, \nలోకత్రయే౽ప్యప్రతిమప్రభావ ৷৷11.43৷৷",

    "తస్మాత్ప్రణమ్య ప్రణిధాయ కాయం(మ్),\nప్రసాదయే త్వామహమీశమీడ్యమ్৷\nపితేవ పుత్రస్య సఖేవ సఖ్యుః(ఫ్),\nప్రియః(ఫ్) ప్రియాయార్హసి దేవ సోఢుమ్ ৷৷11.44৷৷",

    "అదృష్టపూర్వం(మ్) హృషితో౽స్మి దృష్ట్వా,\nభయేన చ ప్రవ్యథితం(మ్) మనో మే৷\nతదేవ మే దర్శయ దేవరూపం(మ్),\nప్రసీద దేవేశ జగన్నివాస ৷৷11.45৷৷",

    "కిరీటినం(ఙ్) గదినం(ఞ్) చక్రహస్తమ్,\nఇచ్ఛామి త్వాం(న్) ద్రష్టుమహం(న్) తథైవ৷\nతేనైవ రూపేణ చతుర్భుజేన,\nసహస్రబాహో భవ విశ్వమూర్తే ৷৷11.46৷৷",

    "శ్రీ భగవానువాచ\nమయా ప్రసన్నేన తవార్జునేదం(మ్),\nరూపం(మ్) పరం(న్) దర్శితమాత్మయోగాత్৷\nతేజోమయం(మ్) విశ్వమనన్తమాద్యం(మ్),\nయన్మే త్వదన్యేన న దృష్టపూర్వమ్ ৷৷11.47৷৷",

    "న వేదయజ్ఞాధ్యయనైర్న దానైః(ర్),\nనచ క్రియాభిర్న తపోభిరుగ్రైః৷\nఏవంరూపః(శ్) శక్య అహం(న్) నృలోకే,\nద్రష్టుం(న్) త్వదన్యేన కురుప్రవీర ৷৷11.48৷৷",

    "మా తే వ్యథా మా చ విమూఢభావో,\nదృష్ట్వా రూపం(ఙ్) ఘోరమీదృఙ్మమేదమ్৷\nవ్యపేతభీః(ఫ్) ప్రీతమనాః(ఫ్) పునస్త్వం(న్),\nతదేవ మే రూపమిదం(మ్) ప్రపశ్య ৷৷11.49৷৷",

    "సఞ్జయ ఉవాచ\nఇత్యర్జునం(మ్) వాసుదేవస్తథోక్త్వా,\nస్వకం(మ్) రూపం(న్) దర్శయామాస భూయః৷\nఆశ్వాసయామాస చ భీతమేనం(మ్),\nభూత్వా పునః సౌమ్యవపుర్మహాత్మా ৷৷11.50৷৷",

    "అర్జున ఉవాచ\nదృష్ట్వేదం(మ్) మానుషం(మ్) రూపం(న్), తవసౌమ్యం(ఞ్) జనార్దన৷\nఇదానీమస్మి సంవృత్తః(స్), సచేతాః(ఫ్) ప్రకృతిం(ఙ్) గతః ৷৷11.51৷৷",

    "శ్రీ భగవానువాచసుదుర్దర్శమిదం(మ్) రూపం(న్), దృష్టవానసి యన్మమ৷\nదేవా అప్యస్య రూపస్య, నిత్యం(న్) దర్శనకాఙ్క్షిణః ৷৷11.52৷৷",

    "నాహం(మ్) వేదైర్న తపసా, న దానేన న చేజ్యయా৷ \nశక్య ఏవంవిధో ద్రష్టుం(న్), దృష్టవానసి మాం యథా ৷৷11.53৷৷",

    "భక్త్యా త్వనన్యయా శక్య, అహమేవం(మ్)విధో౽ర్జున৷\nజ్ఞాతుం(న్) ద్రష్టుం(ఞ్) చ తత్త్వేన, ప్రవేష్టుం(ఞ్) చ పరన్తప ৷৷11.54৷৷",

    "మత్కర్మకృన్మత్పరమో, మద్భక్తః(స్) సఙ్గవర్జితః৷\nనిర్వైరః(స్) సర్వభూతేషు, యః(స్) స మామేతి పాణ్డవ ৷৷11.55৷৷",

    "ఓం తత్సదితి శ్రీమద్భగవద్గీతాసు ఉపనిషత్సు బ్రహ్మవిద్యాయాం(మ్) \nయోగశాస్త్రే శ్రీకృష్ణార్జునసంవాదే విశ్వరూపదర్శనయోగో నామైకాదశో౽ధ్యాయః ॥11॥"
  ],
  "adhyaya-12" : [
    "అర్జున ఉవాచ\nఏవం(మ్) సతతయుక్తా యే,భక్తాస్త్వాం(మ్) పర్యుపాసతే৷\nయేచాప్యక్షరమవ్యక్తం(న్),తేషాం(ఙ్)కే యోగవిత్తమాః ৷৷12.1॥",

    "శ్రీ భగవానువాచ\nమయ్యావేశ్య మనో యే మాం(న్), నిత్యయుక్తా ఉపాసతే꠰\nశ్రద్ధయా పరయోపేతాః(స్), తే మే యుక్తతమా మతాః ৷৷12.2॥",

    "యే త్వక్షరమనిర్దేశ్యమ్,అవ్యక్తం(మ్) పర్యుపాసతే꠰\nసర్వత్రగమచిన్త్యం(ఞ్)చ, కూటస్థమచలం(న్) ధ్రువ మ్ ꠱12.3॥",

    "సన్నియమ్యేన్ద్రియగ్రామం(మ్), సర్వత్ర సమబుద్ధయః꠰\nతే ప్రాప్నువన్తి మామేవ, సర్వభూతహితే ర తాః ꠱12.4॥",

    "క్లేశో౽ధికతరస్తేషామ్,అవ్యక్తాసక్తచేతసామ్꠰\nఅవ్యక్తా హి గతిర్దుఃఖం(న్), దేహవద్భిరవాప్య తే ꠱12.5॥",

    "యే తు సర్వాణి కర్మాణి, మయి సన్న్యస్య మత్పరాః꠰\nఅనన్యేనైవ యోగేన, మాం(న్) ధ్యాయన్త ఉపాస తే ꠱12.6॥",

    "తేషామహం(మ్) సముద్ధర్తా, మృత్యుసంసారసాగరాత్꠰\nభవామి నచిరాత్పార్థ, మయ్యావేశితచేతసామ్ ꠱12.7॥",

    "మయ్యేవ మన ఆధత్స్వ, మయి బుద్ధిం(న్) నివేశయ꠰\nనివసిష్యసి మయ్యేవ, అత ఊర్ధ్వం(న్) న సంశయః ꠱12.8॥",

    "అథ చిత్తం(మ్) సమాధాతుం(న్),న శక్నోషి మయి స్థిరమ్।\nఅభ్యాసయోగేన తతో, మామిచ్ఛాప్తుం(న్) ధనఞ్జయ ॥12.9॥",

    "అభ్యాసే౽ప్యసమర్థో౽సి, మత్కర్మపరమో భవ꠰\nమదర్థమపి కర్మాణి,కుర్వన్సిద్ధిమవాప్స్యసి ৷৷12.10॥",

    "అథైతదప్యశక్తో౽సి, కర్తుం(మ్) మద్యోగమాశ్రితః।\nసర్వకర్మఫలత్యాగం(న్), తతః(ఖ్)కురు యతాత్మవా న్॥12.11॥",

    "శ్రేయో హి జ్ఞానమభ్యాసాజ్, జ్ఞానాద్ధ్యానం(వ్ఁ) విశిష్యతే।\nధ్యానాత్కర్మఫలత్యాగః(స్) , త్యాగాచ్ఛాన్తిరనన్తరమ్ ৷৷12.12॥",

    "అద్వేష్టా సర్వభూతానాం(మ్), మైత్రః(ఖ్)కరుణ ఏవ చ।\nనిర్మమో నిరహఙ్కారః(స్), సమదుఃఖసుఖః క్షమీ ৷৷12.13॥",

    "సన్తుష్టః( స్) సతతం(య్ఁ) యోగీ, యతాత్మా దృఢనిశ్చయః|\nమయ్యర్పితమనోబుద్ధిః(ర్) ,యో మద్భక్తః(స్) స మే ప్రియః ৷৷12.14॥",

    "యస్మాన్నోద్విజతే లోకో, లోకాన్నోద్విజతే చ యః|\nహర్షామర్షభయోద్వేగైః(ర్),ముక్తో యః(స్) స చ మే ప్రియః ৷৷12.15॥",

    "అనపేక్షః(శ్)శుచిర్దక్ష,ఉదాసీనో గతవ్యథః|\nసర్వారమ్భపరిత్యాగీ,యో మద్భక్తః(స్) స మే ప్రియః ৷৷12.16॥",

    "యో న హృష్యతి న ద్వేష్టి,న శోచతి న కాఙ్క్షతి।\nశుభాశుభపరిత్యాగీ,భక్తిమాన్యః(స్) స మే ప్రి యః॥12.17॥",

    "సమః(శ్)శత్రౌ చ మిత్రే చ,తథా మానాపమానయోః।\nశీతోష్ణసుఖదుఃఖేషు, సమః(స్) సఙ్గవివర్జి తః॥12.18॥",

    "తుల్యనిన్దాస్తుతిర్మౌనీ, సన్తుష్టో యేనకేనచిత్।\nఅనికేతః(స్) స్థిరమతిః(ర్), భక్తిమాన్మే ప్రియో న రః॥12.19॥",

    "యే తు ధర్మ్యామృతమిదం(య్ఁ), యథోక్తం(మ్) పర్యుపాసతే।\nశ్రద్దధానా మత్పరమా, భక్తాస్తే౽తీవ మే ప్రియాః ৷৷12.20॥",

    "ఓం తత్సదితి శ్రీమద్భగవద్గీతాసు ఉపనిషత్సు బ్రహ్మవిద్యాయాం(య్ఁ)\nయోగశాస్త్రే శ్రీకృష్ణార్జునసంవాదే భక్తియోగో నామ ద్వాదశో౽ధ్యాయః ॥12॥"
  ],
  "adhyaya-13" : [
    "శ్రీ భగవానువాచ\nఇదం(మ్) శరీరం(ఙ్) కౌన్తేయ, క్షేత్రమిత్యభిధీయతే\nఏతద్యో వేత్తి తం(మ్) ప్రాహుః, క్షేత్రజ్ఞ ఇతి తద్విదః ॥13.1॥",

    "క్షేత్రజ్ఞం(ఞ్) చాపి మాం(మ్) విద్ధి, సర్వక్షేత్రేషు భారత\nక్షేత్రక్షేత్రజ్ఞయోర్జ్ఞానం(మ్), యత్తజ్జ్ఞానం(మ్) మతం(మ్) మమ ॥13.2॥",

    "తత్క్షేత్రం(మ్) యచ్చ యాదృక్చ, యద్వికారి యతశ్చ యత్\nస చ యో యత్ప్రభావశ్చ, తత్సమాసేన మే శ్రృణు ॥13.3॥",

    "ఋషిభిర్బహుధా గీతం(ఞ్), ఛన్దోభిర్వివిధైః(ఫ్) పృథక్ \nబ్రహ్మసూత్రపదైశ్చైవ, హేతుమద్భిర్వినిశ్చితైః ॥13.4॥",

    "మహాభూతాన్యహఙ్కారో, బుద్ధిరవ్యక్తమేవ చ\nఇన్ద్రియాణి దశైకం(ఞ్) చ,పఞ్చ చేన్ద్రియగోచరాః ॥13.5॥",

    "ఇచ్ఛా ద్వేషః(స్) సుఖం(న్) దుఃఖం(మ్), సఙ్ఘాతశ్చేతనా ధృతిః\nఏతత్క్షేత్రం(మ్)  సమాసేన, సవికారముదాహృతమ్ ॥13.6॥",

    "అమానిత్వమదమ్భిత్వమ్, అహింసా క్షాన్తిరార్జవమ్\nఆచార్యోపాసనం(మ్) శౌచం(మ్), స్థైర్యమాత్మవినిగ్రహః ॥13.7॥",

    "ఇన్ద్రియార్థేషు వైరాగ్యమ్, అనహఙ్కార ఏవ చ\nజన్మమృత్యుజరావ్యాధి, దుఃఖదోషానుదర్శనమ్ ॥13.8॥",

    "అసక్తిరనభిష్వఙ్గః(ఫ్), పుత్రదారగృహాదిషు\nనిత్యం(ఞ్) చ సమచిత్తత్వమ్, ఇష్టానిష్టోపపత్తిషు ॥13.9॥",

    "మయి చానన్యయోగేన, భక్తిరవ్యభిచారిణీ\nవివిక్తదేశసేవిత్వమ్, అరతిర్జనసంసది ॥13.10॥",

    "అధ్యాత్మజ్ఞాననిత్యత్వం(న్), తత్త్వజ్ఞానార్థదర్శనమ్\nఏతజ్జ్ఞానమితి ప్రోక్తమ్, అజ్ఞానం(మ్) యదతో౽న్యథా ॥13.11॥",

    "జ్ఞేయం(మ్) యత్తత్ప్రవక్ష్యామి, యజ్జ్ఞాత్వామృతమశ్నుతే\nఅనాదిమత్పరం(మ్) బ్రహ్మ, న సత్తన్నాసదుచ్యతే ॥13.12॥",

    "సర్వతః(ఫ్) పాణిపాదం(న్) తత్, సర్వతో౽క్షిశిరోముఖమ్\nసర్వతః(శ్) శ్రుతిమల్లోకే, సర్వమావృత్య తిష్ఠతి ॥13.13॥",

    "సర్వేన్ద్రియగుణాభాసం(మ్), సర్వేన్ద్రియవివర్జితమ్\nఅసక్తం(మ్) సర్వభృచ్చైవ, నిర్గుణం(ఙ్) గుణభోక్తృ చ ॥13.14॥",

    "బహిరన్తశ్చ భూతానామ్, అచరం(ఞ్) చరమేవ చ\nసూక్ష్మత్వాత్తదవిజ్ఞేయం(న్), దూరస్థం(ఞ్) చాన్తికే చ తత్ ॥13.15॥",

    "అవిభక్తం(ఞ్) చ భూతేషు, విభక్తమివ చ స్థితమ్ \nభూతభర్తృ చ తజ్జ్ఞేయం(ఙ్), గ్రసిష్ణు ప్రభవిష్ణు చ ॥13.16॥",

    "జ్యోతిషామపి తజ్జ్యోతిః(స్), తమసః(ఫ్) పరముచ్యతే \nజ్ఞానం(ఞ్) జ్ఞేయం(ఞ్) జ్ఞానగమ్యం(మ్), హృది సర్వస్య విష్ఠితమ్ ॥13.17॥",

    "ఇతి క్షేత్రం(న్) తథా జ్ఞానం(ఞ్), జ్ఞేయం(ఞ్) చోక్తం(మ్) సమాసతః \nమద్భక్త ఏతద్విజ్ఞాయ, మద్భావాయోపపద్యతే ॥13.18॥",

    "ప్రకృతిం(మ్) పురుషం(ఞ్) చైవ, విద్ధ్యనాదీ ఉభావపి \nవికారాంశ్చ గుణాంశ్చైవ, విద్ధి ప్రకృతిసమ్భవాన్ ॥13.19॥",

    "కార్యకరణ కర్తృత్వే, హేతుః(ఫ్) ప్రకృతిరుచ్యతే \nపురుషః(స్) సుఖదుఃఖానాం(మ్), భోక్తృత్వే హేతురుచ్యతే ॥13.20॥",

    "పురుషః(ఫ్) ప్రకృతిస్థో హి, భుఙ్క్తే ప్రకృతిజాన్గుణాన్ \nకారణం(ఙ్) గుణసఙ్గో౽స్య, సదసద్యోనిజన్మసు ॥13.21॥",

    "ఉపద్రష్టానుమన్తా చ, భర్తా భోక్తా మహేశ్వరః \nపరమాత్మేతి చాప్యుక్తో, దేహే౽స్మిన్పురుషః(ఫ్) పరః ॥13.22॥",

    "య ఏవం(మ్) వేత్తి పురుషం(మ్), ప్రకృతిం(ఞ్) చ గుణైః(స్)సహ \nసర్వథా వర్తమానో౽పి, న స భూయో౽భిజాయతే ॥13.23॥",

    "ధ్యానేనాత్మని పశ్యన్తి, కేచిదాత్మానమాత్మనా \nఅన్యే సాఙ్ఖ్యేన యోగేన, కర్మయోగేన చాపరే ॥13.24॥",

    "అన్యే త్వేవమజానన్తః(శ్), శ్రుత్వాన్యేభ్య ఉపాసతే \nతే౽పి చాతితరన్త్యేవ, మృత్యుం(మ్) శ్రుతిపరాయణాః ॥13.25॥",

    "యావత్సఞ్జాయతే కిఞ్చిత్, సత్త్వం(మ్) స్థావరజఙ్గమమ్ \nక్షేత్రక్షేత్రజ్ఞసంయోగాత్, తద్విద్ధి భరతర్షభ ॥13.26॥",

    "సమం(మ్) సర్వేషు భూతేషు, తిష్ఠన్తం(మ్) పరమేశ్వరమ్\nవినశ్యత్స్వవినశ్యన్తం(మ్), యః(ఫ్) పశ్యతి స పశ్యతి ॥13.27॥",

    "సమం(మ్) పశ్యన్హి సర్వత్ర, సమవస్థితమీశ్వరమ్ \nన హినస్త్యాత్మనాత్మానం(న్), తతో యాతి పరాం(ఙ్) గతిమ్ ॥13.28॥",

    "ప్రకృత్యైవ చ కర్మాణి, క్రియమాణాని సర్వశః\nయః(ఫ్) పశ్యతి తథాత్మానమ్, అకర్తారం(మ్) స పశ్యతి ॥13.29॥",

    "యదా భూతపృథగ్భావమ్, ఏకస్థమనుపశ్యతి \nతత ఏవ చ విస్తారం(మ్), బ్రహ్మ సమ్పద్యతే తదా ॥13.30॥",

    "అనాదిత్వాన్నిర్గుణత్వాత్, పరమాత్మాయమవ్యయః\nశరీరస్థో౽పి కౌన్తేయ, న కరోతి న లిప్యతే ॥13.31॥",

    "యథా సర్వగతం(మ్) సౌక్ష్మ్యాద్,ఆకాశం(న్) నోపలిప్యతే \nసర్వత్రావస్థితో దేహే, తథాత్మా నోపలిప్యతే ॥13.32॥",

    "యథా ప్రకాశయత్యేకః(ఖ్), కృత్స్నం(మ్) లోకమిమం(మ్) రవిః \nక్షేత్రం(ఙ్) క్షేత్రీ తథా కృత్స్నం(మ్), ప్రకాశయతి భారత ॥13.33॥",

    "క్షేత్రక్షేత్రజ్ఞయోరేవమ్, అన్తరం(ఞ్) జ్ఞానచక్షుషా\nభూతప్రకృతిమోక్షం(ఞ్) చ, యే విదుర్యాన్తి తే పరమ్ ॥13.34॥",

    "ఓం తత్సదితి శ్రీమద్భగవద్గీతాసు ఉపనిషత్సు బ్రహ్మవిద్యాయాం(మ్) యోగశాస్త్రే \nశ్రీకృష్ణార్జునసంవాదే క్షేత్ర క్షేత్రజ్ఞవిభాగయోగో నామ  త్రయోదశో౽ధ్యాయః ॥13॥"
  ],
  "adhyaya-14" : [
    "శ్రీ భగవానువాచ\nపరం(మ్) భూయః(ఫ్)ప్రవక్ష్యామి, జ్ఞానానాం(ఞ్) జ్ఞానముత్తమమ్ ৷\nయజ్జ్ఞాత్వా మునయః(స్) సర్వే, పరాం(మ్) సిద్ధిమితో గతాః ॥14.1॥",

    "ఇదం(ఞ్) జ్ఞానముపాశ్రిత్య, మమ సాధర్మ్యమాగతాః ৷\nసర్గే౽పి నోపజాయన్తే,ప్రలయే న వ్యథన్తి చ ॥14.2॥",

    "మమ యోనిర్మహద్బ్రహ్మ, తస్మిన్గర్భం(న్) దధామ్యహమ్ ৷\nసమ్భవః(స్) సర్వభూతానాం(న్), తతో భవతి భారత ॥14.3॥",

    "సర్వయోనిషు కౌన్తేయ, మూర్తయః(స్) సమ్భవన్తి యాః ৷\nతాసాం(మ్) బ్రహ్మ మహద్యోనిః(ర్), అహం(మ్) బీజప్రదః(ఫ్)పితా ॥14.4॥",

    "సత్త్వం(మ్) రజస్తమ ఇతి,గుణాః(ఫ్) ప్రకృతిసమ్భవాః ৷\nనిబధ్నన్తి మహాబాహో,దేహే దేహినమవ్యయమ్ ॥14.5॥",

    "తత్ర సత్త్వం(న్) నిర్మలత్వాత్, ప్రకాశకమనామయమ్ ৷\nసుఖసఙ్గేన బధ్నాతి, జ్ఞానసఙ్గేన చానఘ ॥14.6॥",

    "రజో రాగాత్మకం(మ్) విద్ధి, తృష్ణాసఙ్గసముద్భవమ్ ৷\nతన్నిబధ్నాతి కౌన్తేయ,కర్మసఙ్గేన దేహినమ్ ॥14.7॥",

    "తమస్త్వజ్ఞానజం(మ్) విద్ధి, మోహనం(మ్) సర్వదేహినామ్ ৷\nప్రమాదాలస్యనిద్రాభిః(స్), తన్నిబధ్నాతి భారత ॥14.8॥",

    "సత్త్వం(మ్) సుఖే సఞ్జయతి,రజః(ఖ్)కర్మణి భారత ৷\nజ్ఞానమావృత్య తు తమః(ఫ్), ప్రమాదే సఞ్జయత్యుత ॥14.9॥",

    "రజస్తమశ్చాభిభూయ, సత్త్వం(మ్) భవతి భారత ৷\nరజః(స్) సత్త్వం(న్) తమశ్చైవ, తమః(స్) సత్త్వం(మ్) రజస్తథా ॥14.10॥",

    "సర్వద్వారేషు దేహే౽స్మిన్,ప్రకాశ ఉపజాయతే ৷\nజ్ఞానం(మ్) యదా తదా విద్యాద్,వివృద్ధం(మ్) సత్త్వమిత్యుత ॥14.11॥",

    "లోభః(ఫ్) ప్రవృత్తిరారమ్భః(ఖ్), కర్మణామశమః(స్) స్పృహా ৷\nరజస్యేతాని జాయన్తే, వివృద్ధే భరతర్షభ ॥14.12॥",

    "అప్రకాశో౽ప్రవృత్తిశ్చ, ప్రమాదో మోహ ఏవ చ ৷\nతమస్యేతాని జాయన్తే, వివృద్ధే కురునన్దన ॥14.13॥",

    "యదా సత్త్వే ప్రవృద్ధే తు, ప్రలయం(మ్) యాతి దేహభృత్ ৷\nతదోత్తమవిదాం(మ్) లోకాన్, అమలాన్ప్రతిపద్యతే ॥14.14॥",

    "రజసి ప్రలయం(ఙ్) గత్వా, కర్మసఙ్గిషు జాయతే ৷\nతథా ప్రలీనస్తమసి,మూఢయోనిషు జాయతే ॥14.15॥",

    "కర్మణః(స్) సుకృతస్యాహుః(స్), సాత్త్వికం(న్) నిర్మలం(మ్) ఫలమ్ ৷\nరజసస్తు ఫలం(న్) దుఃఖమ్,అజ్ఞానం(న్) తమసః(ఫ్) ఫలమ్ ॥14.16॥",

    "సత్త్వాత్సఞ్జాయతే జ్ఞానం(మ్), రజసో లోభ ఏవ చ ৷\nప్రమాదమోహౌ తమసో, భవతో౽జ్ఞానమేవ చ ॥14.17॥",

    "ఊర్ధ్వం(ఙ్) గచ్ఛన్తి సత్త్వస్థా, మధ్యే తిష్ఠన్తి రాజసాః ৷\nజఘన్యగుణవృత్తిస్థా, అధో గచ్ఛన్తి తామసాః ॥14.18॥",

    "నాన్యం(ఙ్) గుణేభ్యః(ఖ్)కర్తారం(మ్), యదా ద్రష్టానుపశ్యతి ৷\nగుణేభ్యశ్చ పరం(మ్) వేత్తి, మద్భావం(మ్)  సో౽ధిగచ్ఛతి ॥14.19॥",

    "గుణానేతానతీత్య త్రీన్,దేహీ దేహసముద్భవాన్ ৷\nజన్మమృత్యుజరాదుఃఖైః(ర్),విముక్తో౽మృతమశ్నుతే ॥14.20॥",

    "అర్జున ఉవాచ\nకైర్లింగైస్త్రీన్గుణానేతాన్, అతీతో భవతి ప్రభో ৷\nకిమాచారః(ఖ్) కథం(ఞ్)చైతాంస్, త్రీన్గుణానతివర్తతే ॥14.21॥",

    "శ్రీ భగవానువాచ\nప్రకాశం(ఞ్) చ ప్రవృత్తిం(ఞ్) చ, మోహమేవ చ పాణ్డవ ৷\nన ద్వేష్టి సమ్ప్రవృత్తాని, న నివృత్తాని కాఙ్క్షతి ॥14.22॥",

    "ఉదాసీనవదాసీనో,గుణైర్యో న విచాల్యతే ৷\nగుణా వర్తన్త ఇత్యేవ, యో౽వతిష్ఠతి నేఙ్గతే ॥14.23॥",

    "సమదుఃఖసుఖః(స్) స్వస్థః(స్), సమలోష్టాశ్మకాఞ్చనః ৷\nతుల్యప్రియాప్రియో ధీరః(స్), తుల్యనిన్దాత్మసంస్తుతిః ॥14.24॥",

    "మానాపమానయోస్తుల్యః(స్),తుల్యో మిత్రారిపక్షయోః ৷\nసర్వారమ్భపరిత్యాగీ, గుణాతీతః(స్) స ఉచ్యతే ॥14.25॥",

    "మాం(ఞ్) చ యో౽వ్యభిచారేణ, భక్తియోగేన సేవతే ৷\nస గుణాన్సమతీత్యైతాన్, బ్రహ్మభూయాయ కల్పతే ॥14.26॥",

    "బ్రహ్మణో హి ప్రతిష్ఠాహమ్,అమృతస్యావ్యయస్య చ ৷\nశాశ్వతస్య చ ధర్మస్య, సుఖస్యైకాన్తికస్య చ ॥14.27॥",

    "ఓం తత్సదితి శ్రీమద్భగవద్గీతాసు ఉపనిషత్సు బ్రహ్మవిద్యాయాం(మ్)\nయోగశాస్త్రే శ్రీకృష్ణార్జునసంవాదే గుణత్రయవిభాగయోగో నామ చతుర్దశో౽ధ్యాయః ॥14॥"
  ],
  "adhyaya-15" : [
    "శ్రీ భగవానువాచ\nఊర్ధ్వమూలమధః(శ్) శాఖమ్,అశ్వత్థం(మ్) ప్రాహురవ్యయమ్। \nఛన్దాంసి యస్య పర్ణాని,యస్తం(వ్ఁ) వేద స వేదవిత్ ॥15.1॥",

    "అధశ్చో౽ర్ధ్వం(మ్  ) ప్రసృతాస్తస్య శాఖా, \nగుణప్రవృద్ధా విషయప్రవాలాః |\nఅధశ్చ మూలాన్యనుసన్తతాని, \nకర్మానుబన్ధీని మనుష్యలోకే ॥15.2॥",

    "న రూపమస్యేహ తథోపలభ్యతే, \nనాన్తో న చాదిర్న చ సంప్రతిష్ఠా |\nఅశ్వత్థమేనం( మ్ ) సువిరూఢమూలమ్, \nఅసఙ్గశస్త్రేణ దృఢేన ఛిత్త్వా ॥15.3॥",

    "తతః(ఫ్)పదం (న్) తత్పరిమార్గితవ్యం(య్ఁ), \nయస్మిన్గతా న నివర్తన్తి భూయః।\nతమేవ చాద్యం(మ్) పురుషం(మ్) ప్రపద్యే, \nయతః(ఫ్ )ప్రవృత్తిః(ఫ్)ప్రసృతా పురాణీ ॥15.4॥",

    "నిర్మానమోహా  జితసఙ్గదోషా, \nఅధ్యాత్మనిత్యా  వినివృత్తకామాః |\nద్వన్ద్వైర్విముక్తాః(స్) సుఖదుఃఖస ఞ్జ్ఞైః(ర్), \nగచ్ఛన్త్యమూఢాః (ఫ్)పదమవ్యయం(న్) తత్  ॥15.5॥",

    "న తద్భాసయతే సూర్యో, న శశాఙ్కో న పావకః|  యద్గత్వా న నివర్తన్తే,తద్ధామ పరమం(మ్) మమ ॥15.6॥",

    "మమైవాంశో  జీవలోకే జీవభూతః(స్)  సనాతనః|  మనః(ష్) షష్ఠానీన్ద్రియాణి ప్రకృతిస్థాని కర్షతి৷ ॥15.7॥",

    "శరీరం(య్ఁ)  యదవాప్నోతి, యచ్చాప్యుత్క్రామతీశ్వరః। \nగృహీత్వైతాని సంయాతి వాయుర్గన్ధానివాశయాత్ ॥15.8॥",

    "శ్రోత్రం(ఞ్) చక్షుః(స్) స్పర్శనం(ఞ్) చ, రసనం( ఙ్) ఘ్రాణమేవ చ \nఅధిష్ఠాయ  మనశ్చాయం(వ్ఁ) విషయానుపసేవతే ॥15.9॥",

    "ఉత్క్రామన్తం(మ్) స్థితం(వ్ఁ) వాపి, భుఞ్జానం(వ్ఁ) వా గుణాన్వితమ్।\nవిమూఢా నానుపశ్యన్తి , పశ్యన్తి జ్ఞానచక్షుషః ॥15.10॥",

    "యతన్తో యోగినశ్చైనం(మ్),పశ్యన్త్యాత్మన్యవస్థితమ్ |\nయతన్తో౽ప్యకృతాత్మానో, నైనం(మ్)  పశ్యన్త్యచేతసః ॥15.11॥",

    "యదాదిత్యగతం(న్) తేజో, జగద్భాసయతే౽ఖిలమ్|\nయచ్చన్ద్రమసి  యచ్చాగ్నౌ, తత్తేజో విద్ధి మామకమ్ ॥15.12॥",

    "గామావిశ్య చ భూతాని, ధారయామ్యహమోజసా|\nపుష్ణామి చౌషధీః(స్) సర్వాః(స్), సోమో భూత్వా రసాత్మకః ॥15.13॥",

    "అహం(వ్ఁ) వైశ్వానరో భూత్వా, ప్రాణినాం(న్) దేహమాశ్రితః।\nప్రాణాపానసమాయుక్తః (ఫ్), పచామ్యన్నం(ఞ్) చతుర్విధమ్ ॥15.14॥",

    "సర్వస్య  చాహం(మ్)  హృది సన్నివిష్టో, \nమత్తః (స్)స్మృతిర్జ్ఞానమపోహనం(ఞ్) చ |\nవేదైశ్చ  సర్వైరహమేవ  వేద్యో, \nవేదాన్తకృద్వేదవిదేవ చాహమ్ ॥15.15॥",

    "ద్వావిమౌ పురుషౌ లోకే, క్షరశ్చాక్షర ఏవ చ|\nక్షరః(స్) సర్వాణి భూతాని కూటస్థోక్షర ఉచ్యతే ॥15.16॥",

    "ఉత్తమః పురుషస్త్వన్యః  పరమాత్మేత్యుదాహృతః । \nయో లోకత్రయమావిశ్య బిభర్త్యవ్యయ ఈశ్వరః ॥15.17॥",

    "యస్మాత్క్షరమతీతో౽హమ్,అక్షరాదపి చోత్తమః | \nఅతో౽స్మి లోకే వేదే చ,ప్రథితః(ఫ్) పురుషోత్తమః ॥15.18॥",

    "యో మామేవమసమ్మూఢో, జానాతి పురుషోత్తమమ్| \nస సర్వవిద్భజతి మాం(మ్) సర్వభావేన  భారత ॥15.19॥",

    "ఇతి గుహ్యతమం(మ్)  శాస్త్రమ్, ఇదముక్తం(మ్)  మయా౽నఘ|\nఏతద్బుద్ధ్వా బుద్ధిమాన్స్యాత్,కృతకృత్యశ్చ భారత ॥15.20॥",

    "ఓం తత్సదితి శ్రీమద్భగవద్గీతాసు ఉపనిషత్సు బ్రహ్మవిద్యాయాం(య్ఁ)\nయోగశాస్త్రే శ్రీకృష్ణార్జునసంవాదే పురుషోత్తమయోగో నామ పఞ్చదశో౽ధ్యాయః ॥15॥"
  ],
  "adhyaya-16" : [
    "అభయం సత్త్వసంశుద్ధిః(ర్),  జ్ఞానయోగవ్యవస్థితిః ৷\nదానం(న్)  దమశ్చ యజ్ఞశ్చ,  స్వాధ్యాయస్తప  ఆర్జవమ్ ৷৷16.1৷৷",

    "అహింసా  సత్యమక్రోధః(స్), త్యాగః(శ్)  శాన్తిరపైశునమ్ ৷\nదయా  భూతేష్వలోలుప్త్వం(మ్),  మార్దవం(మ్)  హ్రీరచాపలమ్ ৷৷16.2৷৷",

    "తేజః  క్షమా ధృతిః(శ్)  శౌచమ్, అద్రోహో నాతిమానితా ৷\nభవన్తి  సమ్పదం(న్)  దైవీమ్, అభిజాతస్య భారత ৷৷16.3৷৷",

    "దమ్భో దర్పో౽భిమానశ్చ, క్రోధః(ఫ్)  పారుష్యమేవ చ ৷\nఅజ్ఞానం(ఞ్)  చాభిజాతస్య, పార్థ సమ్పదమాసురీమ్ ৷৷16.4৷৷",

    "దైవీ  సమ్పద్విమోక్షాయ, నిబన్ధాయాసురీ మతా ৷\nమా శుచః(స్) సమ్పదం(న్)  దైవీమ్, అభిజాతో౽సి పాణ్డవ ৷৷16.5৷৷",

    "ద్వౌ భూతసర్గౌ లోకే౽స్మిన్,  దైవ ఆసుర ఏవ చ ৷\nదైవో విస్తరశః(ఫ్) ప్రోక్త, ఆసురం(మ్) పార్థ మే శృణు ৷৷16.6৷৷",

    "ప్రవృత్తిం(ఞ్)  చ నివృత్తిం(ఞ్) చ,  జనా న విదురాసురాః ৷\nన శౌచం(న్)  నాపి చాచారో, న సత్యం(న్)  తేషు విద్యతే  ৷৷16.7৷৷",

    "అసత్యమప్రతిష్ఠం(న్)  తే,  జగదాహురనీశ్వరమ్ ৷\nఅపరస్పరసమ్భూతం(ఙ్),  కిమన్యత్కామహైతుకమ్ ৷৷16.8৷৷",

    "ఏతాం(న్) దృష్టిమవష్టభ్య,  నష్టాత్మానో౽ల్పబుద్ధయః ৷\nప్రభవన్త్యుగ్రకర్మాణః,  క్షయాయ జగతో౽హితాః ৷৷16.9৷৷",

    "కామమాశ్రిత్య దుష్పూరం(న్), దమ్భమానమదాన్వితాః ৷\nమోహాద్గృహీత్వాసద్గ్రాహాన్, ప్రవర్తన్తే౽శుచివ్రతాః ৷৷16.10৷৷",

    "చిన్తామపరిమేయాం(ఞ్) చ,  ప్రలయాన్తాముపాశ్రితాః ৷\nకామోపభోగపరమా, ఏతావదితి  నిశ్చితాః ৷৷16.11৷৷",

    "ఆశాపాశశతైర్బద్ధాః(ఖ్), కామక్రోధపరాయణాః৷\nఈహన్తే  కామభోగార్థమ్, అన్యాయేనార్థసఞ్చయాన్ ৷৷16.12৷৷",

    "ఇదమద్య మయా లబ్ధమ్, ఇమం(మ్) ప్రాప్స్యే  మనోరథమ్ ৷\nఇదమస్తీదమపి  మే,  భవిష్యతి పునర్ధనమ్ ৷৷16.13৷৷",

    "అసౌ మయా హతః(శ్) శత్రుర్, హనిష్యే చాపరానపి ৷\nఈశ్వరో౽హమహం(మ్) భోగీ,  సిద్ధో౽హం(మ్) బలవాన్సుఖీ ৷৷16.14৷৷",

    "ఆఢ్యో౽భిజనవానస్మి, కో౽న్యో౽స్తి  సదృశో మయా ৷\nయక్ష్యే దాస్యామి మోదిష్య, ఇత్యజ్ఞానవిమోహితాః ৷৷16.15৷৷",

    "అనేకచిత్తవిభ్రాన్తా, మోహజాలసమావృతాః৷\nప్రసక్తాః(ఖ్) కామభోగేషు, పతన్తి నరకే౽శుచౌ ৷৷16.16৷৷",

    "ఆత్మసమ్భావితాః(స్) స్తబ్ధా, ధనమానమదాన్వితాః৷\nయజన్తే  నామయజ్ఞైస్తే, దమ్భేనావిధిపూర్వకమ్ ৷৷16.17৷৷",

    "అహఙ్కారం(మ్) బలం(న్) దర్పం(ఙ్), కామం(ఙ్) క్రోధం(ఞ్) చ సంశ్రితాః ৷\nమామాత్మపరదేహేషు, ప్రద్విషన్తో౽భ్యసూయకాః ৷৷16.18৷৷",

    "తానహం(న్) ద్విషతః(ఖ్) క్రూరాన్, సంసారేషు నరాధమాన్৷\nక్షిపామ్యజస్రమశుభాన్,  ఆసురీష్వేవ యోనిషు ৷৷16.19৷৷",

    "ఆసురీం(య్ఁ)  యోనిమాపన్నా, మూఢా జన్మని జన్మని ৷\nమామప్రాప్యైవ కౌన్తేయ, తతో యాన్త్యధమాం(ఙ్)  గతిమ్ ৷৷16.20৷৷",

    "త్రివిధం(న్) నరకస్యేదం(న్),  ద్వారం(న్) నాశనమాత్మనః ৷\nకామః(ఖ్) క్రోధస్తథా లోభః(స్), తస్మాదేతత్త్రయం(న్) త్యజేత్ ৷৷16.21৷৷",

    "ఏతైర్విముక్తః(ఖ్) కౌన్తేయ, తమోద్వారైస్త్రిభిర్నరః ৷\nఆచరత్యాత్మనః(శ్) శ్రేయః(స్), తతో యాతి పరాం(ఙ్) గతిమ్ ৷৷16.22৷৷",

    "యః(శ్) శాస్త్రవిధిముత్సృజ్య, వర్తతే కామకారతః ৷\nన స సిద్ధిమవాప్నోతి, న సుఖం(న్) న పరాం(ఙ్) గతిమ్ ৷৷16.23৷৷",

    "తస్మాచ్ఛాస్త్రం(మ్) ప్రమాణం(న్) తే, కార్యాకార్యవ్యవస్థితౌ ৷\nజ్ఞాత్వా శాస్త్రవిధానోక్తం(ఙ్), కర్మ  కర్తుమిహార్హసి  ৷৷16.24৷৷",

    "ఓం తత్సదితి శ్రీమద్భగవద్గీతాసు ఉపనిషత్సు బ్రహ్మవిద్యాయాం(య్ఁ)\nయోగశాస్త్రే శ్రీకృష్ణార్జునసంవాదే దైవాసురసమ్పద్విభాగయోగో నామ షోడశో౽ధ్యాయః ॥16॥"
  ],
  "adhyaya-17" : [
    "అర్జున ఉవాచ\nయే శాస్త్రవిధిముత్సృజ్య, యజన్తే శ్రద్ధయాన్వితాః\nతేషాం(న్) నిష్ఠా తు కా కృష్ణ, సత్త్వమాహో రజస్తమః ৷৷17.1৷৷",
    
    "శ్రీ భగవానువాచ\nత్రివిధా భవతి శ్రద్ధా, దేహినాం(మ్) సా స్వభావజా\nసాత్త్వికీ రాజసీ చైవ, తామసీ చేతి తాం(మ్) శృణు ৷৷17.2৷৷",
    
    "సత్త్వానురూపా సర్వస్య, శ్రద్ధా భవతి భారత\nశ్రద్ధామయో౽యం(మ్)  పురుషో, యో యచ్ఛ్రద్ధః(స్) స ఏవ సః ৷৷17.3৷৷",
    
    "యజన్తే సాత్త్వికా దేవాన్, యక్షరక్షాంసి రాజసాః\nప్రేతాన్భూతగణాంశ్చాన్యే, యజన్తే తామసా జనాః ৷৷17.4৷৷",
    
    "అశాస్త్రవిహితం(ఙ్) ఘోరం(న్), తప్యన్తే యే తపో జనాః\nదమ్భాహఙ్కారసంయుక్తాః (ఖ్), కామరాగబలాన్వితాః ৷৷17.5৷৷",
    
    "కర్షయన్తః(శ్) శరీరస్థం(మ్), భూతగ్రామమచేతసః\nమాం(ఞ్) చైవాన్తః(శ్)శరీరస్థం(న్), తాన్విద్ధ్యాసురనిశ్చయాన్ ৷৷17.6৷৷",
    
    "ఆహారస్త్వపి సర్వస్య, త్రివిధో భవతి ప్రియః\nయజ్ఞస్తపస్తథా దానం(న్), తేషాం(మ్) భేదమిమం(మ్) శ్రృణు ৷৷17.7৷৷",
    
    "ఆయుః(స్)సత్త్వబలారోగ్య, సుఖప్రీతివివర్ధనాః \nరస్యాః(స్) స్నిగ్ధాః(స్) స్థిరా హృద్యా, ఆహారాః(స్) సాత్త్వికప్రియాః ৷৷17.8৷৷",
    
    "కట్వమ్లలవణాత్యుష్ణ, తీక్ష్ణరూక్షవిదాహినః\nఆహారా రాజసస్యేష్టా, దుఃఖశోకామయప్రదాః ৷৷17.9৷৷",
    
    "యాతయామం(ఙ్) గతరసం(మ్), పూతి పర్యుషితం(ఞ్) చ యత్\nఉచ్ఛిష్టమపి చామేధ్యం(మ్), భోజనం(న్) తామసప్రియమ్  ৷৷17.10৷৷",
    
    "అఫలాకాఙ్క్షిభిర్యజ్ఞో, విధిదృష్టో య ఇజ్యతే \nయష్టవ్యమేవేతి మనః(స్), సమాధాయ స సాత్త్వికః ৷৷17.11৷৷",

    "అభిసంధాయ తు ఫలం(న్), దమ్భార్థమపి చైవ యత్\nఇజ్యతే భరతశ్రేష్ఠ, తం(మ్) యజ్ఞం(మ్) విద్ధి రాజసమ్ ৷৷17.12৷৷",

    "విధిహీనమసృష్టాన్నం(మ్), మన్త్రహీనమదక్షిణమ్\nశ్రద్ధావిరహితం(మ్) యజ్ఞం(న్), తామసం(మ్) పరిచక్షతే ৷৷17.13৷৷",

    "దేవద్విజగురుప్రాజ్ఞ, పూజనం(మ్) శౌచమార్జవమ్\nబ్రహ్మచర్యమహింసా చ, శారీరం(న్) తప ఉచ్యతే ৷৷17.14৷৷",

    "అనుద్వేగకరం(మ్) వాక్యం(మ్), సత్యం(మ్) ప్రియహితం(ఞ్) చ యత్\nస్వాధ్యాయాభ్యసనం(ఞ్) చైవ, వాఙ్మయం(న్)  తప ఉచ్యతే ৷৷17.15৷৷",

    "మనః(ఫ్)ప్రసాదః(స్) సౌమ్యత్వం(మ్), మౌనమాత్మవినిగ్రహః\nభావసంశుద్ధిరిత్యేతత్, తపో మానసముచ్యతే ৷৷17.16৷৷",

    "శ్రద్ధయా పరయా తప్తం(న్), తపస్తత్త్రివిధం(న్) నరైః\nఅఫలాకాఙ్క్షిభిర్యుక్తైః(స్), సాత్త్వికం(మ్) పరిచక్షతే ৷৷17.17৷৷",

    "సత్కారమానపూజార్థం(న్), తపో దమ్భేన చైవ యత్\nక్రియతే తదిహ ప్రోక్తం(మ్), రాజసం(ఞ్) చలమధ్రువమ్ ৷৷17.18৷৷",

    "మూఢగ్రాహేణాత్మనో యత్, పీడయా క్రియతే తపః\nపరస్యోత్సాదనార్థం(మ్) వా, తత్తామసముదాహృతమ్ ৷৷17.19৷৷",

    "దాతవ్యమితి యద్దానం(న్), దీయతే౽నుపకారిణే\nదేశే కాలే చ పాత్రే చ, తద్దానం(మ్) సాత్త్వికం(మ్) స్మృతమ్ ৷৷17.20৷৷",

    "యత్తు ప్రత్యుపకారార్థం(మ్), ఫలముద్దిశ్య వా పునః\nదీయతే చ పరిక్లిష్టం(న్), తద్దానం(మ్) రాజసం(మ్) స్మృతమ్ ৷৷17.21৷৷",

    "అదేశకాలే యద్దానమ్,అపాత్రేభ్యశ్చ దీయతే ৷\nఅసత్కృతమవజ్ఞాతం(న్), తత్తామసముదాహృతమ్ ৷৷17.22৷৷",

    "ఓం తత్సదితి నిర్దేశో, బ్రహ్మణస్త్రివిధః(స్) స్మృతః\nబ్రాహ్మణాస్తేన వేదాశ్చ, యజ్ఞాశ్చ విహితాః(ఫ్) పురా ৷৷17.23৷৷",

    "తస్మాదోమిత్యుదాహృత్య, యజ్ఞదానతపః(ఖ్)క్రియాః\nప్రవర్తన్తే విధానోక్తాః(స్), సతతం(మ్) బ్రహ్మవాదినామ్ ৷৷17.24৷৷",

    "తదిత్యనభిసన్ధాయ, ఫలం(మ్) యజ్ఞతపః(ఖ్)క్రియాః\nదానక్రియాశ్చ వివిధాః(ఖ్), క్రియన్తే మోక్షకాఙ్క్షిభిః ৷৷17.25৷৷",

    "సద్భావే సాధుభావే చ, సదిత్యేతత్ప్రయుజ్యతే\nప్రశస్తే కర్మణి తథా, సచ్ఛబ్దః(ఫ్) పార్థ యుజ్యతే ৷৷17.26৷৷",

    "యజ్ఞే తపసి దానే చ, స్థితిః(స్) సదితి చోచ్యతే\nకర్మ చైవ తదర్థీయం(మ్), సదిత్యేవాభిధీయతే ৷৷17.27৷৷",

    "అశ్రద్ధయా హుతం(న్) దత్తం(న్), తపస్తప్తం(ఙ్) కృతం(ఞ్) చ యత్ \nఅసదిత్యుచ్యతే పార్థ,న చ తత్ప్రేత్య నో ఇహ ৷৷17.28৷৷",

    "ఓం తత్సదితి శ్రీమద్భగవద్గీతాసు ఉపనిషత్సు బ్రహ్మవిద్యాయాం(మ్)\nయోగశాస్త్రే శ్రీకృష్ణార్జునసంవాదే శ్రద్ధాత్రయవిభాగయోగో నామ సప్తదశో౽ధ్యాయః ॥17॥"
  ],
  "adhyaya-18" : [
    "అర్జున ఉవాచ\nసన్న్యాసస్య మహాబాహో, తత్త్వమిచ్ఛామి వేదితుమ్ ।\nత్యాగస్య చ హృషీకేశ, పృథక్కేశినిషూదన ॥18.1॥",

    "శ్రీ భగవానువాచ\nకామ్యానాం(ఙ్) కర్మణాం(న్) న్యాసం(మ్), సన్న్యాసం(ఙ్) కవయో విదుః ।\nసర్వకర్మఫలత్యాగం(మ్), ప్రాహుస్త్యాగం(మ్) విచక్షణాః ॥18.2॥",

    "త్యాజ్యం(న్) దోషవదిత్యేకే, కర్మ ప్రాహుర్మనీషిణః ।\nయజ్ఞదానతపఃకర్మ, న త్యాజ్యమితి చాపరే ॥18.3॥",

    "నిశ్చయం(మ్) శ్రృణు మే తత్ర, త్యాగే భరతసత్తమ ।\nత్యాగో హి పురుషవ్యాఘ్ర, త్రివిధః(స్) సమ్ప్రకీర్తితః ॥18.4॥",

    "యజ్ఞదానతపఃకర్మ, న త్యాజ్యం(ఙ్) కార్యమేవ తత్ ।\nయజ్ఞో దానం(న్) తపశ్చైవ, పావనాని మనీషిణామ్ ॥18.5॥",

    "ఏతాన్యపి తు కర్మాణి, సఙ్గం(న్) త్యక్త్వా ఫలాని చ ।\nకర్తవ్యానీతి మే పార్థ, నిశ్చితం(మ్) మతముత్తమమ్॥18.6॥",

    "నియతస్య తు సన్న్యాసః(ఖ్), కర్మణో నోపపద్యతే ।\nమోహాత్తస్య పరిత్యాగః(స్),తామసః(ఫ్) పరికీర్తితః ॥18.7॥",

    "దుఃఖమిత్యేవ యత్కర్మ, కాయక్లేశభయాత్త్యజేత్ ।\nస కృత్వా రాజసం(న్) త్యాగం(న్), నైవ త్యాగఫలం(మ్) లభేత్ ॥18.8॥",

    "కార్యమిత్యేవ యత్కర్మ, నియతం(ఙ్) క్రియతే౽ర్జున ।\nసఙ్గం(న్) త్యక్త్వా ఫలం(ఞ్) చైవ, స త్యాగః(స్) సాత్త్వికో మతః ॥18.9॥",

    "న ద్వేష్ట్యకుశలం(ఙ్) కర్మ, కుశలే నానుషజ్జతే ।\nత్యాగీ సత్త్వసమావిష్టో, మేధావీ ఛిన్నసంశయః ॥18.10॥",

    "న హి దేహభృతా శక్యం(న్), త్యక్తుం(ఙ్) కర్మాణ్యశేషతః ।\nయస్తు కర్మఫలత్యాగీ, స త్యాగీత్యభిధీయతే ॥18.11॥",

    "అనిష్టమిష్టం(మ్) మిశ్రం(ఞ్) చ, త్రివిధం(ఙ్) కర్మణః(ఫ్) ఫలమ్ ।\nభవత్యత్యాగినాం(మ్) ప్రేత్య, న తు సన్న్యాసినాం(ఙ్) క్వచిత్ ॥18.12॥",

    "పఞ్చైతాని మహాబాహో, కారణాని నిబోధ మే ।\nసాఙ్ఖ్యే కృతాన్తే ప్రోక్తాని, సిద్ధయే సర్వకర్మణామ్ ॥18.13॥",

    "అధిష్ఠానం(న్) తథా కర్తా, కరణం(ఞ్) చ పృథగ్విధమ్ ।\nవివిధాశ్చ పృథక్చేష్టా, దైవం(ఞ్) చైవాత్ర పఞ్చమమ్ ॥18.14॥",

    "శరీరవాఙ్మనోభిర్యత్, కర్మ ప్రారభతే నరః ।\nన్యాయ్యం(మ్) వా విపరీతం(మ్) వా, పఞ్చైతే తస్య హేతవః ॥18.15॥",

    "తత్రైవం(మ్) సతి కర్తారమ్, ఆత్మానం(ఙ్) కేవలం(న్) తు యః  ।\nపశ్యత్యకృతబుద్ధిత్వాన్,న స పశ్యతి దుర్మతిః ॥18.16॥",

    "యస్య నాహఙ్కృతో భావో, బుద్ధిర్యస్య న లిప్యతే  ।\nహత్వాపి స ఇమాఁల్లోకాన్, న హన్తి న నిబధ్యతే ॥18.17॥",

    "జ్ఞానం(ఞ్) జ్ఞేయం(మ్) పరిజ్ఞాతా, త్రివిధా కర్మచోదనా ।\nకరణం(ఙ్) కర్మ కర్తేతి, త్రివిధః(ఖ్) కర్మసఙ్గ్రహః ॥18.18॥",

    "జ్ఞానం(ఙ్) కర్మ చ కర్తా చ, త్రిధైవ గుణభేదతః ।\nప్రోచ్యతే గుణసఙ్ఖ్యానే, యథావచ్ఛృణు తాన్యపి ॥18.19॥",

    "సర్వభూతేషు యేనైకం(మ్), భావమవ్యయమీక్షతే ।\nఅవిభక్తం(మ్) విభక్తేషు, తజ్జ్ఞానం(మ్) విద్ధి సాత్త్వికమ్ ॥18.20॥",

    "పృథక్త్వేన తు యజ్జ్ఞానం(న్), నానాభావాన్పృథగ్విధాన్ ।\nవేత్తి సర్వేషు భూతేషు, తజ్జ్ఞానం(మ్) విద్ధి రాజసమ్ ॥18.21॥",

    "యత్తు కృత్స్నవదేకస్మిన్, కార్యే సక్తమహైతుకమ్ ।\nఅతత్త్వార్థవదల్పం(ఞ్) చ, తత్తామసముదాహృతమ్ ॥18.22॥",

    "నియతం(మ్) సఙ్గరహితమ్,అరాగద్వేషతః(ఖ్) కృతమ్ ।\nఅఫలప్రేప్సునా కర్మ, యత్తత్సాత్త్వికముచ్యతే ॥18.23॥",

    "యత్తు కామేప్సునా కర్మ, సాహఙ్కారేణ వా పునః ।\nక్రియతే బహులాయాసం(న్), తద్రాజసముదాహృతమ్ ॥18.24॥",

    "అనుబన్ధం(ఙ్) క్షయం(మ్) హింసామ్,అనవేక్ష్య చ పౌరుషమ్ ।\nమోహాదారభ్యతే కర్మ, యత్తత్తామసముచ్యతే ॥18.25॥",

    "ముక్తసఙ్గో౽నహంవాదీ, ధృత్యుత్సాహసమన్వితః ।\nసిద్ధ్యసిద్ధ్యోర్నిర్వికారః(ఖ్), కర్తా సాత్త్విక ఉచ్యతే ॥18.26॥",

    "రాగీ కర్మఫలప్రేప్సుః(ర్), లుబ్ధో హింసాత్మకో౽శుచిః ।\nహర్షశోకాన్వితః(ఖ్) కర్తా,రాజసః(ఫ్) పరికీర్తితః ॥18.27॥",

    "అయుక్తః(ఫ్) ప్రాకృతః(స్), స్తబ్ధః(శ్), శఠో నైష్కృతికో౽లసః ।\nవిషాదీ దీర్ఘసూత్రీ చ, కర్తా తామస ఉచ్యతే ॥18.28॥",

    "బుద్ధేర్భేదం(న్) ధృతేశ్చైవ, గుణతస్త్రివిధం(మ్) శ్రృణు ।\nప్రోచ్యమానమశేషేణ, పృథక్త్వేన ధనఞ్జయ ॥18.29॥",

    "ప్రవృత్తిం(ఞ్) చ నివృత్తిం(ఞ్) చ, కార్యాకార్యే భయాభయే ।\nబన్ధం(మ్) మోక్షం(ఞ్) చ యా వేత్తి, బుద్ధిః(స్) సా పార్థ సాత్త్వికీ ॥18.30॥",

    "యయా ధర్మమధర్మం(ఞ్) చ, కార్యం(ఞ్) చాకార్యమేవ చ ।\nఅయథావత్ప్రజానాతి, బుద్ధిః(స్) సా పార్థ రాజసీ ॥18.31॥",

    "అధర్మం(న్) ధర్మమితి యా, మన్యతే తమసావృతా ।\nసర్వార్థాన్విపరీతాంశ్చ, బుద్ధిః(స్) సా పార్థ తామసీ ॥18.32॥",

    "ధృత్యా యయా ధారయతే, మనఃప్రాణేన్ద్రియక్రియాః ।\nయోగేనావ్యభిచారిణ్యా, ధృతిః(స్) సా పార్థ సాత్త్వికీ ॥18.33॥",

    "యయా తు ధర్మకామార్థాన్, ధృత్యా ధారయతే౽ర్జున ।\nప్రసఙ్గేన ఫలాకాఙ్క్షీ, ధృతిః(స్) సా పార్థ రాజసీ ॥18.34॥",

    "యయా స్వప్నం(మ్) భయం(మ్) శోకం(మ్), విషాదం(మ్) మదమేవ చ ।\nన విముఞ్చతి దుర్మేధా, ధృతిః(స్) సా పార్థ తామసీ ॥18.35॥",

    "సుఖం(న్) త్విదానీం(న్) త్రివిధం(మ్), శ్రృణు మే భరతర్షభ ।\nఅభ్యాసాద్రమతే యత్ర, దుఃఖాన్తం(ఞ్) చ నిగచ్ఛతి ॥18.36॥",

    "యత్తదగ్రే విషమివ, పరిణామే౽మృతోపమమ్ ।\nతత్సుఖం(మ్) సాత్త్వికం(మ్) ప్రోక్తమ్, ఆత్మబుద్ధిప్రసాదజమ్ ॥18.37॥",

    "విషయేన్ద్రియసంయోగాద్, యత్తదగ్రే౽మృతోపమమ్ ।\nపరిణామే విషమివ, తత్సుఖం(మ్) రాజసం(మ్) స్మృతమ్ ॥18.38॥",

    "యదగ్రే చానుబన్ధే చ, సుఖం(మ్) మోహనమాత్మనః ।\nనిద్రాలస్యప్రమాదోత్థం(న్), తత్తామసముదాహృతమ్ ॥18.39॥",

    "న తదస్తి పృథివ్యాం(మ్) వా, దివి దేవేషు వా పునః ।\nసత్త్వం(మ్) ప్రకృతిజైర్ముక్తం(మ్), యదేభిః(స్) స్యాత్త్రిభిర్గుణైః ॥18.40॥",

    "బ్రాహ్మణక్షత్రియవిశాం(మ్), శూద్రాణాం(ఞ్) చ పరన్తప ।\nకర్మాణి ప్రవిభక్తాని, స్వభావప్రభవైర్గుణైః ॥18.41॥",

    "శమో దమస్తపః(శ్) శౌచం(ఙ్), క్షాన్తిరార్జవమేవ చ ।\nజ్ఞానం(మ్) విజ్ఞానమాస్తిక్యం(మ్), బ్రహ్మకర్మ స్వభావజమ్ ॥18.42॥",

    "శౌర్యం(న్) తేజో ధృతిర్దాక్ష్యం(మ్), యుద్ధే చాప్యపలాయనమ్ ।\nదానమీశ్వరభావశ్చ, క్షాత్రం(ఙ్) కర్మ స్వభావజమ్ ॥18.43॥",

    "కృషిగౌరక్ష్యవాణిజ్యం(మ్), వైశ్యకర్మ స్వభావజమ్ ।\nపరిచర్యాత్మకం(ఙ్) కర్మ, శూద్రస్యాపి స్వభావజమ్ ॥18.44॥",

    "స్వే స్వే కర్మణ్యభిరతః(స్), సంసిద్ధిం(మ్) లభతే నరః ।\nస్వకర్మనిరతః(స్) సిద్ధిం(మ్), యథా విన్దతి తచ్ఛృణు ॥18.45॥",

    "యతః(ఫ్) ప్రవృత్తిర్భూతానాం(మ్), యేన సర్వమిదం(న్) తతమ్ ।\nస్వకర్మణా తమభ్యర్చ్య, సిద్ధిం(మ్) విన్దతి మానవః ॥18.46॥",

    "శ్రేయాన్స్వధర్మో విగుణః(ఫ్), పరధర్మాత్స్వనుష్ఠితాత్ ।\nస్వభావనియతం(ఙ్) కర్మ, కుర్వన్ నాప్నోతి కిల్బిషమ్ ॥18.47॥",

    "సహజం(ఙ్) కర్మ కౌన్తేయ, సదోషమపి న త్యజేత్ ।\nసర్వారమ్భా హి దోషేణ, ధూమేనాగ్నిరివావృతాః ॥18.48॥",

    "అసక్తబుద్ధిః(స్) సర్వత్ర, జితాత్మా విగతస్పృహః ।\nనైష్కర్మ్యసిద్ధిం(మ్) పరమాం(మ్) సన్న్యాసేనాధిగచ్ఛతి ॥18.49॥",

    "సిద్ధిం(మ్) ప్రాప్తో యథా బ్రహ్మ, తథాప్నోతి నిబోధ మే ।\nసమాసేనైవ కౌన్తేయ, నిష్ఠా జ్ఞానస్య యా పరా ॥18.50॥",

    "బుద్ధ్యా విశుద్ధయా యుక్తో, ధృత్యాత్మానం(న్) నియమ్య చ ।\nశబ్దాదీన్ విషయాంస్త్యక్త్వా, రాగద్వేషౌ వ్యుదస్య చ ॥18.51॥",

    "వివిక్తసేవీ లఘ్వాశీ, యతవాక్కాయమానసః ।\nధ్యానయోగపరో నిత్యం(మ్), వైరాగ్యం(మ్) సముపాశ్రితః ॥18.52॥",

    "అహఙ్కారం(మ్) బలం(న్) దర్పం(ఙ్), కామం(ఙ్) క్రోధం(మ్) పరిగ్రహమ్ ।\nవిముచ్య నిర్మమః(శ్) శాన్తో, బ్రహ్మభూయాయ కల్పతే ॥18.53॥",

    "బ్రహ్మభూతః(ఫ్) ప్రసన్నాత్మా, న శోచతి న కాఙ్క్షతి ।\nసమః(స్) సర్వేషు భూతేషు, మద్భక్తిం(మ్) లభతే పరామ్ ॥18.54॥",

    "భక్త్యా మామభిజానాతి, యావాన్యశ్చాస్మి తత్త్వతః ।\nతతో మాం(మ్) తత్త్వతో జ్ఞాత్వా, విశతే తదనన్తరమ్ ॥18.55॥",

    "సర్వకర్మాణ్యపి సదా, కుర్వాణో మద్వ్యపాశ్రయః ।\nమత్ప్రసాదాదవాప్నోతి, శాశ్వతం(మ్) పదమవ్యయమ్ ॥18.56॥",

    "చేతసా సర్వకర్మాణి, మయి సన్న్యస్య మత్పరః ।\nబుద్ధియోగముపాశ్రిత్య, మచ్చిత్తః(స్) సతతం(మ్) భవ ॥18.57॥",

    "మచ్చిత్తః(స్) సర్వదుర్గాణి, మత్ప్రసాదాత్తరిష్యసి ।\nఅథ చేత్త్వమహఙ్కారాన్, న శ్రోష్యసి వినఙ్క్ష్యసి ॥18.58॥",

    "యదహఙ్కారమాశ్రిత్య, న యోత్స్య ఇతి మన్యసే ।\nమిథ్యైష వ్యవసాయస్తే, ప్రకృతిస్త్వాం(న్) నియోక్ష్యతి ॥18.59॥",

    "స్వభావజేన కౌన్తేయ, నిబద్ధః(స్) స్వేన కర్మణా ।\nకర్తుం(న్) నేచ్ఛసి యన్మోహాత్, కరిష్యస్యవశో౽పి తత్ ॥18.60॥",

    "ఈశ్వరః(స్) సర్వభూతానాం(మ్), హృద్దేశే౽ర్జున తిష్ఠతి ।\nభ్రామయన్సర్వభూతాని, యన్త్రారూఢాని మాయయా ॥18.61॥",

    "తమేవ శరణం(ఙ్) గచ్ఛ, సర్వభావేన భారత ।\nతత్ప్రసాదాత్పరాం(మ్) శాన్తిం(మ్), స్థానం(మ్) ప్రాప్స్యసి శాశ్వతమ్ ॥18.62॥",

    "ఇతి తే జ్ఞానమాఖ్యాతం(ఙ్), గుహ్యాద్గుహ్యతరం(మ్) మయా ।\nవిమృశ్యైతదశేషేణ, యథేచ్ఛసి తథా కురు ॥18.63॥",

    "సర్వగుహ్యతమం(మ్) భూయః(శ్), శ్రృణు మే పరమం(మ్) వచః ।\nఇష్టో౽సి మే దృఢమితి, తతో వక్ష్యామి తే హితమ్ ॥18.64॥",

    "మన్మనా భవ మద్భక్తో, మద్యాజీ మాం(న్) నమస్కురు ।\nమామేవైష్యసి సత్యం(న్) తే, ప్రతిజానే ప్రియో౽సి మే ॥18.65॥",

    "సర్వధర్మాన్పరిత్యజ్య,మామేకం(మ్) శరణం వ్రజ ।\nఅహం(న్) త్వా సర్వపాపేభ్యో, మోక్షయిష్యామి మా శుచః ॥18.66॥",

    "ఇదం(న్) తే నాతపస్కాయ, నాభక్తాయ కదాచన ।\nన చాశుశ్రూషవే వాచ్యం(న్), న చ మాం(మ్) యో౽భ్యసూయతి ॥18.67॥",

    "య ఇమం(మ్) పరమం(ఙ్) గుహ్యం(మ్), మద్భక్తేష్వభిధాస్యతి ।\nభక్తిం(మ్) మయి పరాం(ఙ్) కృత్వా, మామేవైష్యత్యసంశయః ॥18.68॥",

    "న చ తస్మాన్మనుష్యేషు,కశ్చిన్మే ప్రియకృత్తమః ।\nభవితా న చ మే తస్మాద్, అన్యః(ఫ్) ప్రియతరో భువి ॥18.69॥",

    "అధ్యేష్యతే చ య ఇమం(న్), ధర్మ్యం(మ్) సంవాదమావయోః ।\nజ్ఞానయజ్ఞేన తేనాహమ్, ఇష్టః(స్) స్యామితి మే మతిః ॥18.70॥",

    "శ్రద్ధావాననసూయశ్చ, శ్రృణుయాదపి యో నరః ।\nసో౽పి ముక్తః(శ్) శుభాఁల్లోకాన్, ప్రాప్నుయాత్పుణ్యకర్మణామ్ ॥18.71॥",

    "కచ్చిదేతచ్ఛ్రుతం(మ్) పార్థ, త్వయైకాగ్రేణ చేతసా ।\nకచ్చిదజ్ఞానసమ్మోహః(ఫ్), ప్రనష్టస్తే ధనఞ్జయ ॥18.72॥",

    "అర్జున ఉవాచ\nనష్టో మోహః(స్) స్మృతిర్లబ్ధా, త్వత్ప్రసాదాన్మయాచ్యుత ।\nస్థితో౽స్మి గతసన్దేహః(ఖ్), కరిష్యే వచనం(న్) తవ ॥18.73॥",

    "సఞ్జయ ఉవాచ\nఇత్యహం(మ్) వాసుదేవస్య, పార్థస్య చ మహాత్మనః ।\nసంవాదమిమమశ్రౌషమ్, అద్భుతం(మ్) రోమహర్షణమ్ ॥18.74॥",

    "వ్యాసప్రసాదాచ్ఛ్రుతవాన్, ఏతద్గుహ్యమహం(మ్) పరమ్ ।\nయోగం(మ్) యోగేశ్వరాత్కృష్ణాత్, సాక్షాత్కథయతః(స్) స్వయమ్ ॥18.75॥",

    "రాజన్సంస్మృత్య సంస్మృత్య, సంవాదమిమమద్భుతమ్ ।\nకేశవార్జునయోః(ఫ్) పుణ్యం(మ్), హృష్యామి చ ముహుర్ముహుః ॥18.76॥",

    "తచ్చ సంస్మృత్య సంస్మృత్య, రూపమత్యద్భుతం(మ్) హరేః ।\nవిస్మయో మే మహాన్ రాజన్, హృష్యామి చ పునః(ఫ్) పునః ॥18.77॥",

    "యత్ర యోగేశ్వరః(ఖ్) కృష్ణో,యత్ర పార్థో ధనుర్ధరః ।\nతత్ర శ్రీర్విజయో భూతిః(ర్), ధ్రువా నీతిర్మతిర్మమ ॥18.78॥",

    "ఓం తత్సదితి శ్రీమద్భగవద్గీతాసు ఉపనిషత్సు బ్రహ్మవిద్యాయాం(మ్)\nయోగశాస్త్రే శ్రీకృష్ణార్జునసంవాదే మోక్షసన్న్యాసయోగో నామ  అష్టాదశో౽ధ్యాయః ॥18॥"  
  ]
},
{
  "adhyaya-1" : [
    "ధృతరాష్ట్రుడు పలికెను - ఓ సంజయా! యుద్ధసన్నద్ధులై ధర్మక్షేత్రమైన కురుక్షేత్రమునకు చేరియున్న నా కుమారులును, పాండు పుత్రులును ఏమి చేసిరి? 1.1",

    "సంజయుడు పల్కెను - ఆ సమయమున రాజైన దుర్యోధనుడు వ్యూహరచనతో సమరమునకు మోహరించియున్న పాండవసైన్యమును చూచి, ద్రోణాచార్యుని కడకేగి యిట్లు పలికెను. 1.2",

    "ఓ ఆచార్యా! బుద్ధిమంతుడైన మీ శిష్యుడును, ద్రుపదపుత్రుడును అయిన ధృష్టద్యుమ్నునిచే వ్యూహాత్మకముగా నిల్పబడిన పాండవుల ఈ మహాసైన్యమును చూడుడు. 1.3",

    "ఈ సేనలో ధనుర్ధారులైన గొప్పయోధులు కలరు. వారిలో సాత్యకి, విరాటుడు, మహారథియైన ద్రుపదమహారాజు భీమార్జునసమానులు. 1.4",

    "ధృష్టకేతువు, చేకితానుడు, వీరుడైన కాశీరాజు, పురుజిత్తు, కుంతిభోజుడు, నరశ్రేష్ఠుడైన శైబ్యుడు (భీమార్జునసమానులు). 1.5",

    "పరాక్రమవంతుడైన యుధామన్యుడు, వీరుడైన ఉత్తమౌజుడు, సుభద్రాపుత్రుడైన అభిమన్యుడు, ద్రౌపదియొక్క ఐదుగురు పుత్రులును కలరు. వీరందరును మహారథులు. (శౌర్యమున భీమార్జునసమానులు). 1.6",

    "ఓ బ్రాహ్మణోత్తమా! మన పక్షముననున్న ప్రధానయోధులను గూడ గమనింపుడు. మీ యెఱుకకై మన సేనానాయకులను గూర్చియు తెలుపుచున్నాను. 1.7",

    "మీరును, భీష్ముడు, కర్ణుడు, సంగ్రామవిజయుడగు కృపాచార్యుడు, అశ్వత్థామ, వికర్ణుడు, సోమదత్తుని కుమారుడైన భూరిశ్రవుడు ఇందు ముఖ్యులు. 1.8",

    "ఇంకను పెక్కుమంది శూరులును, వీరులును మన సైన్యమునందు కలరు. వీరందరును యుద్ధవిశారదులు. నానాశస్త్రాస్త్రధారులు. నాకొరకు తమ ప్రాణముల నొడ్డియైనను యుద్ధము చేయుటకు సిద్ధముగా నున్నవారు. 1.9",

    "భీష్మపితామహునిచే సురక్షితమై, అపరిమితముగానున్న మనసైన్యము అజేయమైనది. భీమునిచే రక్షింపబడుచు పరిమితముగానున్న ఈ పాండవ సైన్యమును జయించుట సులభము. 1.10",

    "కనుక మీరందరును మీమీ స్థానములలో సుస్థిరముగా నిలిచి, అన్నివైపులనుండి నిశ్చయముగా భీష్ముని రక్షించుచుండుడు. 1.11",

    "కురువృద్ధుడును, ప్రతాపశాలియును ఐన భీష్మ పితామహుడు (దుర్యోధనుని ఈ మాటలు విని) అతనిని సంతోషపఱచుటకై ఉచ్చస్వరముతో సింహనాదమొనర్చి, తన శంఖమును పూరించెను. 1.12",

    "మరుక్షణమునందే శంఖములు, నగారాలు, తప్పెటలు, మృదంగములు, గోముఖవాద్యములు మొదలగునవి ఒక్కసారిగా మ్రోగినవి. దిక్కులను పిక్కటిల్లజేయు ఆ వాద్యనాదములు భయంకరములై ఒప్పినవి. 1.13",

    "తదనంతరము శ్వేతాశ్వములను పూన్చిన మహారథముపై ఆసీనులైయున్న శ్రీకృష్ణార్జునులు తమ దివ్యశంఖములను పూరించిరి. 1.14",

    "శ్రీకృష్ణుడు ‘పాంచజన్యము’ ను, అర్జునుడు ‘దేవదత్త(శంఖ)ము’ ను పూరించిరి. అరివీరభయంకరుడైన భీముడు “పౌండ్రము” అను మహాశంఖమును పూరించెను. 1.15",

    "కుంతీపుత్రుడును, రాజును ఐన యుధిష్ఠిరుడు 'అనంతవిజయము' అను శంఖమును, నకులసహదేవులు 'సుఘోష' 'మణిపుష్పకము'లను శంఖములను పూరించిరి. 1.16",

    "ఓ రాజా! మహాధనుర్ధారియైన కాశీరాజు, మహారథుడైన “శిఖండి”యు, ధృష్టద్యుమ్నుడును, విరాటరాజు, అజేయుడైన 'సాత్యకి'యు, (తమ తమ శంఖములను వేర్వేరుగా పూరించిరి.) 1.17",

    "ద్రౌపదియొక్క ఐదుగురు పుత్రులును, భుజబలశాలియు సుభద్రాపుత్రుడును అగు అభిమన్యుడును తమ తమ శంఖములను వేర్వేరుగా పూరించిరి. 1.18",

    "పాండవపక్షమహాయోధుల శంఖనినాదములకు భూమ్యాకాశములు దద్దరిల్లినవి. ఆ శంఖారావములకు ధార్తరాష్ట్రుల హృదయములు కకావికలములయ్యెను. 1.19",

    "(ఓ ధృతరాష్ట్ర మహారాజా!) పిమ్మట యుద్ధమునకై నడుము బిగించి సమర సన్నద్ధులైయున్న ధార్తరాష్ట్రులను చూచి, కపిధ్వజుడైన అర్జునుడు ధనుస్సును పైకెత్తి (శ్రీకృష్ణునితో ఈ క్రిందివిధముగాననెను) 1.20",

    "ఓ ధృతరాష్ట్రా! అర్జునుడు శ్రీకృష్ణునితో“ఓ అచ్యుత! నా రథమును ఉభయసేనలమధ్య నిలుపుము.” అని పలికెను. 1.21",

    "రణరంగమునందు యుద్ధాభిలాషులై నిలిచియున్న ప్రతిపక్ష యోధులందరిని బాగుగా పరిశీలించునంత వరకును, వారిలో ఎవరితో నేను యుద్ధము చేయవలసియున్నదో గమనించునంత వరకును రథమును నిలిపియుంచుము. 1.22",

    "“దుర్బుద్ధియైన దుర్యోధనునకు ప్రియమును గూర్చుటకై యుద్ధమున పాల్గొనదలచి వచ్చియున్న రాజులను అందరిని ఒకపరి పరికించెదను.” 1.23",

    "సంజయుడు పలికెను - ఓ ధృతరాష్ట్రా! అర్జునుని కోరికమేరకు శ్రీకృష్ణుడు ఆ దివ్యరథమును ఉభయసేనల మధ్య నిలిపెను. 1.24",

    "భీష్మద్రోణులకును, ఆ పక్షమునందలి మహారాజులందరికిని ఎదురుగా (ఉభయసేనల మధ్య రథమును నిలిపి ) శ్రీకృష్ణుడు అర్జునునితో “పార్థా! ఇక్కడ సమావేశమైన ఈ కౌరవ వీరులందరిని పరికింపుము” అని నుడివెను. 1.25",

    "పిమ్మట పార్థుడు (అక్కడ ఉభయసేనలయందును) చేరియున్న పెదతండ్రులను, పినతండ్రులను, తాతముత్తాతలను, గురువులను, మేనమామలను, సోదరులను, పుత్రులను, పౌత్రులను, మిత్రులను (చూచెను). 1.26",

    "(తరువాత)పిల్లనిచ్చిన మామలను (మరియు) ఉభయసేనలయందును ఉన్న ఆత్మీయులను చూచెను. సమరభూమికి వచ్చియున్న బంధువులను అందరిని చూచి, కుంతీపుత్రుడైన అర్జునుడు... 1.27",

    "అత్యంత కరుణాసమంచితుడై శోకసంతప్తుడై (అర్జునుడు)ఇట్లు పలికెను. ఓ కృష్ణా! సమరోత్సాహముతో రణరంగమున నిలిచియున్న ఈ స్వజన సమూహమును జూచి... 1.28",

    "(అర్జునుడు ఇట్లు పలికెను) నా అవయవములు శిథిలములగుచున్నవి. నోరు ఎండిపోవుచున్నది శరీరమునందు వణుకు, గగుర్పాటు కలుగుచున్నవి. 1.29",

    "గాండీవము చేతినుండి జారిపోవుచున్నది. చర్మము తపించిపోవుచున్నది. మనస్సు భ్రమకు గురియైనట్లు అనిపించుచున్నది. కనుక ఇక్కడ నిలబడలేకపోవుచున్నాను. 1.30",

    "ఓ కేశవా! పెక్కు అపశకునములు కనబడుచున్నవి. యుద్ధమున స్వజనసమూహమును చంపుటచే శ్రేయస్సు కలుగునని అనిపించుటలేదు. 1.31",

    "ఓ కృష్ణా! నాకు విజయము గాని, రాజ్యము గాని, సుఖములు గాని అక్కరయే లేదు. గోవిందా! ఈ రాజ్యము వలనగాని, ఈ భోగములవలన గాని, ఈ జీవితమువలన గాని ప్రయోజనమేమి? 1.32",

    "మనము ఎవరికై ఈ రాజ్యమును, భోగములను సుఖములను కోరుకొనుచున్నామో, వారే ధనప్రాణములయెడ ఆశలు వదలుకొని యుద్ధమునకు సన్నద్ధులై వచ్చియున్నారు. 1.33",

    "గురువులు, తండ్రులు, తాతలు, కొడుకులు మనుమలు, మేనమామలు, మామలు, బావమఱదులు, ఇతర బంధువులు మొదలగువారు అందరును ఇచ్చటికి చేరియున్నారు. 1.34",

    "ఓ మధుసూదనా! ముల్లోకాధిపత్యముకొరకైనను నేను ఎవ్వరినీ చంపను. ఇక ఈ భూమండలవిషయమై చెప్పనేల? అట్లే వీరిలో ఎవ్వరైనను నన్ను చంపబూనిననూ నేను మాత్రము వారిని చంపనే చంపను. 1.35",

    "ఓ జనార్దనా! ఈ ధార్తరాష్ట్రులను చంపి, మనము బావుకొనునది ఏమి? (మనము మూట కట్టుకొనునది యేమి?) ఈ ఆతతాయులను చంపుటవలన మనకు పాపమే కలుగును. 1.36",

    "కనుక ఓ మాధవా! మనబంధువులైన ఈ ధార్తరాష్ట్రులను చంపుట మనకు తగదు. స్వజనులను చంపిన మనకు సుఖము ఎట్లు అబ్బును? 1.37",

    "లోభకారణముగ భ్రష్టచిత్తులైన వీరు కులక్షయము వలన కలుగు దోషములను, మిత్రద్రోహమువలన సంభవించు పాపములను (చూడలేక పోచుంటిరి) 1.38",

    "ఓ జనార్దనా! కులనాశనమువలన కలుగు నష్టములను ఎరింగిన మనము ఈ పాపములకు దూరముగా ఉండుటకు ఏల ఆలోచింపరాదు? 1.39",

    "కులక్షయమువలన సనాతనములైన కులధర్మము లన్నియును నశించును. ధర్మము అంతరించి పోయినప్పుడు కులమునందు అంతటను పాపమే వ్యాపించును. 1.40",

    "ఓ కృష్ణా! అధర్మము (పాపము) పెచ్చుపెరిగి పోయినప్పుడు కులస్త్రీలు మిక్కిలి దూషితలగుదురు. ఓ వార్ష్ణేయా! స్త్రీలు దూషితలు ఐనచో వర్ణసాంకర్యము ఏర్పడును. 1.41",

    "వర్ణసాంకర్యము కులఘాతకులను, కులమును నరకమునందు పడవేయును. పిండోదకములు (శ్రాద్ధతర్పణములు) లోపించినందువలన వారి పితరులును అధోగతి పాలయ్యెదరు. 1.42",

    "వర్ణసాంకర్యమునకు మూలములైన ఈ దోషములవలన కులఘాతకులయొక్క సనాతన కులధర్మములు, జాతిధర్మములు నష్టమగును. 1.43",

    "ఓ జనార్దనా! కులధర్మములు నశించినవారికి నిరవధికముగా (కలకాలము) నరకప్రాప్తి తప్పదని ప్రతీతి. 1.44",

    "అయ్యో! మనము బుద్ధిమంతులమై యుండియు రాజ్యసుఖ లోభముచే స్వజనులనే సంహరించుటకు ఉద్యుక్తులమై ఈ ఘోరపాపకృత్యములకు ఒడిగట్టుచున్నాము - ఇది యెంత దారుణము? 1.45",

    "శస్త్రరహితుడనై, ఎదిరింపని నన్ను శస్త్రములను చేబూని ధార్తరాష్ట్రులు యుద్ధమున వధించినను, అది నాకు మిక్కిలి క్షేమకరమే యగును. 1.46",

    "సంజయుడు పలికెను - అర్జునుడు ఈ విధముగా పలికి శోకసంవిగ్న మానసుడై, యుద్ధభూమియందు ధనుర్బాణములను త్యజించి, రథము వెనుకభాగమున చతికిలబడెను. 1.47",

    "ఈ విధముగా ఓం తత్ సత్ అను భగవన్నామ ఉచ్చారణ పూర్వకమైన బ్రహ్మ విద్య మరియు యోగ శాస్త్ర మయమైన, శ్రీకృష్ణార్జునుల సంభాషణ లేక సంవాద రూపములో ఉన్న శ్రీమద్భగవద్గీతలోని మొదటి అధ్యాయమైన అర్జునవిషాదయోగము సమాప్తమైనది"
  ],
  "adhyaya-2" : [
    "సంజయుడు పలికెను - ఈవిధముగా కరుణాపూరిత హృదయుడైన అర్జునుని కనులలో అశ్రువులు నిండియుండెను. అవి అతని వ్యాకులపాటును, శోకమును తెలుపుచుండెను. అట్టి అర్జునునితో శ్రీకృష్ణభగవానుడు ఇట్లనెను. 2.1",

    "శ్రీకృష్ణభగవానుడు ఇట్లనెను - ఓ అర్జునా ! తగని సమయములో ఈ వెూహము నీకు ఎట్లు దాపురించినది? ఇది శ్రేష్ఠులచే ఆచరింపబడునదియు కాదు, స్వర్గమును ఇచ్చునదియు కాదు, కీర్తిని కలిగించునదియు కాదు. 2.2",

    "కావున ఓ అర్జునా! పిరికితనమునకు లోనుకావద్దు. నీకిది ఉచితము కాదు. ఓ పరంతపా ! తుచ్ఛమైన ఈ హృదయదౌర్భల్యమును వీడి, యుద్ధమునకై నడుము బిగింపుము. 2.3",

    "అర్జునుడు పలికెను - ఓ మధుసూదనా!  పూజ్యులైన భీష్మపితామహుని, ద్రోణాచార్యులను యుద్ధమున ఎదిరించి బాణములతో ఎట్లు పోరాడగలను? ఏలనన ఓ అరిసూదనా ! ఈ ఇరువురును  నాకు పూజ్యులు. 2.4",

    "మహానుభావులైన ఈ గురుజనులను చంపకుండా బిచ్చమెత్తుకొనియైనను ఈ లోకమున జీవించుట నాకు శ్రేయస్కరమే. ఏలనన ఈ గురుజనులను చంపినను, రక్తసిక్తములైన రాజ్యసంపదలను, భోగములను మాత్రమే నేను అనుభవింపవలసి యుండును కదా! 2.5",

    "ఈ యుద్ధముచేయుట శ్రేష్ఠమా? లేక చేయకుండుట శ్రేష్ఠమా? అనునది ఎఱుగము. యుద్ధమున వారిని మనము   జయింతుమా? లేక మనలను వారు జయింతురా? అను విషయమునుగూడ ఎఱుగము. మనకు ఆత్మీయులైన ధార్తరాష్ట్రులే ఇచట మనలను ఎదిరించు(పోరాడుటకు) నిలిచి యున్నారు.వారిని చంపి, జీవించుటకును మనము ఇష్టపడము. 2.6",

    "కార్పణ్యదోషము(పిరికితనము)నకులోనై  నా స్వభావమును కోల్పోయి గిలగిలలాడుచున్నాను. ధర్మాధర్మములవిచక్షణకు దూరమై నా కర్తవ్యమును నిర్ణయించు కొనలేకున్నాను. నాకు నిజముగా శ్రేయస్కరమైన దానిని తెలుపుము. నేను నీకు శిష్యుడను. శరణాగతుడను, ఉపదేశింపుము. 2.7",

    "ఈ శోకము నా ఇంద్రియములను దహించివేయుచున్నది. సిరిసంపదలతో గూడిన తిరుగులేని రాజ్యాధికారము లభించినను, కడకు సురాధిపత్యము ప్రాప్తించినను ఈ శోకదాహము చల్లారునుపాయమును గాంచలేకున్నాను. 2.8",

    "సంజయుడు పలికెను - ఓ రాజా! ఈ విధముగా పలికిన పిమ్మట అంతర్యామియైన శ్రీకృష్ణనితో గుడాకేశుడైన అర్జునుడు, “ నేను యుద్ధము చేయనే చేయను” అని స్పష్టముగా నుడివి, మౌనము వహించెను. 2.9",

    "ఓ ధృతరాష్ట్రా ! ఉభయసేనల మధ్య శోకసంతప్తుడైన అర్జునుని జూచి, శ్రీకృష్టడు మందహాసముతో ఇట్లు పలికెను. 2.10",

    "శ్రీభగవానుడు పలికెను - ఓ అర్జునా! శోకింపదగని వారికొఱకై నీవు  శోకించుచున్నావు. పైగా పండితుని(జ్ఞాని) వలె మాట్లాడుచున్నావు. పండితులైనవారు ప్రాణములు పోయిన వారిని గూర్చిగాని, ప్రాణములుపోని వారిని గుఱించిగాని శోకింపరు. 2.11",

    "నీవుగాని, నేనుగాని, ఈ రాజులుగాని ఉండని కాలమే లేదు. ఇక ముందు కూడ మనము ఉండము అనుమాటయే లేదు. (అన్ని కాలములలోను మనము ఉన్నాము. ఆత్మ శాశ్వతము. అది అన్ని కాలములయందును ఉండును. శరీరపతనముతో అది నశించునది కాదు.) 2.12",

    "జీవాత్మకు ఈ దేహమునందు కౌమారము, యౌవనము, వార్ధక్యము ఉన్నట్లే మఱియెుక దేహప్రాప్తియు కలుగును. ధీరుడైనవాడు ఈ విషయమున వెూహితుడు కాడు. 2.13",

    "ఓ కౌంతేయా! విషయేంద్రియసంయోగము వలన శీతోష్ణములు సుఖదుఃఖములు కలుగుచున్నవి.అవి ఉత్పత్తి వినాశశీలములు. అనిత్యములు. కనుక భారతా! వాటిని సహింపుము.( పట్టించుకొనకుము) 2.14",

    "ఏలనన ఓ పురుషశ్రేష్ఠా! ధీరుడైనవాడు  సుఖదుఃఖములను సమానముగా చూచును. అట్టి పురుషుని విషయేంద్రియ సంయోగములు చలింపజేయజాలవు. అతడే వెూక్షమును పొందుటకు అర్హుడు. 2.15",

    "అసత్తు అనుదానికి ( అనిత్యమైనదానికి) ఉనికియే లేదు. సత్తు అనుదానికి లేమి లేదు. ఈ విధముగ ఈ రెండింటియెుక్క వాస్తవస్వరూపములను తత్త్వజ్ఞానియైనవాడే ఎఱుంగును. 2.16",

    "నాశరహితమైన ఆ సత్యము( పరమాత్మ తత్త్వము) జగత్తునందు అంతటను వ్యాపించియున్నదని యెరుంగుము. శాశ్వతమైన దానినెవ్వరును నశింపజేయజాలరు. 2.17",

    " ఈ శరీరములు అన్నియును నశించునవియే. కాని జీవాత్మ నాశరహితము, అప్రమేయము( అనిర్వచనీయుము).నిత్యము. కనుక  ( ఈ విషయమును ఎఱింగి) ఓ భరతవంశీ! అర్జునా! నీవు యుద్ధము చేయుము. 2.18",

    " ఆత్మ ఇతురులను చంపునని భావించువాడును, అది (ఆత్మ) ఇతరులచే చంపబడునని భావించువాడును, ఆ ఇద్దఱును అజ్ఞానులే. ఏలనన వాస్తవముగా ఆత్మ ఎవ్వరినీ చంపదు, ఎవ్వరిచేతను చంపబడదు. 2.19",

    "ఈ ఆత్మ ఏ కాలమునందును పుట్టదు, గిట్టదు. పుట్టి ఉండునది కాదు. ఇది భావ వికారములు లేనిది( ఉత్పత్తి, అస్తిత్వము,వృద్ధి, విపరిణామము, అపక్షయము, వినాశము అను ఆఱును భావవికారములు) ఇది జన్మ లేనిది. నిత్యము, శాశ్వతము, పురాతనము. శరీరము చంపబడినను ఇది చావదు. 2.20",

    "ఓ పార్థా ! ఈ ఆత్మ నాశరహితము, నిత్యము అనియు, జననమరణములు లేనిదనియు, మార్పులేనిదనియు తెలిసికొనిన పురుషుడు ఎవరిని ఎట్లు చంపించును? ఎవరిని ఎట్లు చంపును? 2.21",

    "మానవుడు  జీర్ణవస్త్రములను త్యజించి, నూతన వస్త్రములను ధరించినట్లు జీవాత్మ ప్రాతశరీరములను వీడి నూతనశరీరములను పొందును. 2.22",

    "ఈ ఆత్మను శస్త్రములు ఛేదింపజాలవు. అగ్ని దహింపజాలదు. నీరు తడపజాలదు. వాయువు ఆరిపోవునట్లు చేయజాలదు. 2.23",

    "ఈ ఆత్మ ఛేదించుటకును, దహించుటకును, తడుపుటకును, శోషింపజేయుటకును సాధ్యము కానిది. ఇది నిత్యము. సర్వవ్యాపి, చలింపనిది(అచలము) స్థాణువు( స్థిరమైనది) సనాతనము.  (శాశ్వతము) 2.24",

    "ఈ ఆత్మ అవ్యక్తమైనది. ( ఇంద్రియగోచరముగానిది) అచింత్యము (మనస్సునకును అందనిది.) వికారములు లేనిది. దీనిని గూర్చి ఇట్లు తెలిసికొనుము. కనుక ఓ అర్జునా! నీవు దేనికై శోకింపదగదు. 2.25",

    "ఓ అర్జునా! ఈ ఆత్మకు జననమరణములు కలవని ఒకవేళ నీవు భావించినప్పటికిని దీనికై నీవు శోకింపదగదు. 2.26",

    "ఏలనన పుట్టినవానికి మరణము తప్పదు, మరణించిన వానికి పునర్జన్మ తప్పదు. కనుక అపరిహార్యములైన ఈ విషయములయందు నీవు శోకింపదగదు. 2.27",

    "ఓ అర్జునా!   ప్రాణులన్నియును పుట్టుకకుముందు ఇంద్రియ గోచరములు గావు- ( అవ్యక్తములు) మరణానంతరము గూడా అవి అవ్యక్తములే- ఈ జననమరణముల మధ్యకాలము నందు మాత్రమే అవి ప్రకటితములు ( ఇంద్రియ  గోచరములు)  అగుచుండును. ఇట్టి  స్థితిలో వాటికై పరితపించుట నిష్ప్రయోజనము. 2.28",

    "ఎవరో ఒక మహాపురుషుడు మాత్రమే దీనిని (ఈ ఆత్మను ) ఆశ్చర్యకరమైన దానినిగా చూచును. మరియొక మహాత్ముడు దీని తత్త్వమును ఆశ్చర్యకరముగా వర్ణించును. వేఱొక పురుషుడు దీనిని ఆశ్చర్యకరమైన దానినిగా వినును. ఆ   విన్నవారిలో కూడ కొందరు దీనినిగూర్చి  ఏమియు ఎఱుగరు. 2.29",

    "ఓ అర్జునా! ప్రతిదేహమునందును ఉండెడి ఈ ఆత్మ వధించుటకు వీలుకానిది. కనుక  ఏ ప్రాణిని గూర్చియైనను నీవు శోకింపదగదు. 2.30",

    "అంతేగాక  స్వధర్మమునుబట్టియు నీవు భయపడనక్కరలేదు. ఏలనన క్షత్రియునుకు ధర్మయుద్ధమునకు మించినట్టి  శ్రేయస్కరమైన కర్తవ్యము మఱియొకటి  ఏదియును లేదు. 2.31",

    " ఓ పార్థా! యాదృచ్ఛికముగా అనగా అనుకోకుండా తటస్థించిన ఇట్టి యుద్ధము అదృష్టవంతులైన క్షత్రియులకే లభించును. ఇది స్వర్గమునకు తెఱచిన ద్వారము వంటిది. 2.32",

    "ఈ యుద్ధము నీకు ధర్మబద్ధము. ఒకవేళ నీవు దీనిని ఆచరింపకున్నచో  నీ స్వధర్మమునుండి  పాఱిపోయినవాడవు అగుదువు. దానివలన కీర్తిని  కోల్పోవుదువు. పైగా నీవు పాపము చేసినవాడవగుదువు. 2.33",

    "లోకులెల్లరును బహుకాలమువఱకును నీ అపకీర్తినిగూర్చి చిలువలు పలువలుగా చెప్పికొందురు. మాన్యుడైన పురుషునకు అపకీర్తి మరణముకంటెను బాధాకరమైనది. 2.34",

    "ఈ మహారథులదృష్టిలో  ఇప్పుడు నీవు మిక్కిలి మాన్యుడవు.  యుద్ధవిముఖుడవైనచో   వీరి దృష్టిలో  నీవు ఎంతో చులకన అయ్యెదవు. అంతేగాక  నీవు పిరికివాడవై యుద్ధమునుండి పారిపోయినట్లు వీరు భావింతురు. 2.35",

    "నీ శత్రువులు నీ సామర్థ్యమును నిందించుచు నిన్ను గూర్చి పెక్కు అనరాని మాటలను అందురు. అంతకంటె విచారకరమైన విషయమేముండును? 2.36",

    "ఓ  అర్జునా! రణరంగమున మరణించినచో నీకు వీర స్వర్గము ప్రాప్తించును   యుద్ధమున జయించినచో రాజ్యభోగములను అనుభవింపగలవు. కనుక కృతనిశ్చయుడవై యుద్ధమునకు లెమ్ము. 2.37",

    "జయాపజయములను, లాభనష్టములను, సుఖదుఃఖములను సమానముగా భావించి, యుద్ధసన్నద్దుడవు కమ్ము. అప్పుడు నీకు పాపములు అంటనే అంటవు. 2.38",

    "ఓ పార్థా! ఈ  (సమత్వ)  బుద్ధిని ఇంతవఱకును జ్ఞానయోగ దృష్టితో తెల్పితిని. ఇప్పుడు దానినే కర్మయోగదృక్పథముతో వివరించెదను, వినుము దానిని  ఆకళింపుచేసికుని, ఆచరించినచో కర్మబంధములనుండి నీవుముక్తుడవయ్యెదవు. 2.39",

    "ఈ (నిష్కామ) కర్మయోగమును ప్రారంభించినచో దీనికి ఎన్నటికిని బీజనాశము లేదు. దీనికి విపరీత ఫలితములే  యుండవు. పైగా ఈ (నిష్కామ) కర్మయోగమును ఏ కొంచెము సాధనచేసినను అది జన్మమృత్యురూప మహాభాయమునుండి కాపాడును. 2.40",

    "ఓ అర్జునా! ఈ (నిష్కామ) కర్మయోగమునందు  నిశ్చయాత్మక బుద్ధి ఒకటియే యుండును.  కాని భోగాసక్తులైన వివేకహీనుల బుద్ధులు చంచలములై, ఒకదారీ తెన్నూ లేక   కోరికలవెంట నలువైపులా పరుగులు తీయుచూ అనంతములుగానుండును. 2.41",

    "ఓ అర్జునా! వివేకహీనులైన జనులు ప్రాపంచిక భోగములయందే తలమున్కలై యుందురు. వారు కర్మఫలములను ప్రశంసించు వేద వాక్యములయొక్క బాహ్యార్థములయందే ప్రీతివహింతురు. వాటి అంతరార్థములజోలికేపోరు. 2.42",

    "స్వర్గమునకు మించిదినదేదియును లేదనియు, అదియే పరమప్రాప్యమనియు వారు (వివేకహీనులు) భావింతురు. క్షణికములైన ప్రాపంచిక భోగైశ్వర్యముల యందలి ఆసక్తితో వివిద సకామ కర్మలను ప్రోత్సహించుచు , ప్రీతిని గూర్చు ఇచ్చకపుపల్కులు పలికెదరు. 2.43",

    "ఆ ఇచ్చకపుమాటల ఉచ్చులలో బడిన భోగైశ్వర్యాసక్తులైన అజ్ఞానులబుద్ధులు భగవంతుడు లక్ష్యముగాగల సమాధియందు స్థిరముగా ఉండవు. 2.44",

    "ఓ అర్జునా! వేదములు సత్త్వరజస్తమోగుణములకార్యరూపములైన సమస్త భోగములను గూర్చియు, వాటిని పొందుటకై చేయవలసిన సాధనలను గూర్చియు ప్రతిపాదించును. నీవు ఆ భోగముల యెడలను వాటి సాధనలయందును ఆసక్తిని త్యజింపుము. హర్షశో కాదిద్వంద్వములకు  అతీతుడవు కమ్ము. నిత్యుడైన పరమాత్మ యందే స్థితుడవు కమ్ము.  నీ యోగక్షేమముల కొఱకై ఆ ఆరాటపదవద్దు. అంతఃకరణమును వశమునందుంచుకొనుము. 2.45",

    "అన్నివైపులా జలములతో నిండియున్న మహాజలాశయము అందుబాటులోనున్న వానికి చిన్న చిన్న జలాశయములవలన ఎంత ప్రయోజనమో, పరమాత్మప్రాప్తినంది పరమానందమును అనుభవించు బ్రహ్మజ్ఞానికి వేదములవలన అంతియే ప్రయోజనము. 2.46",

    "కర్తవ్యకర్మమునాచరించుటయందే నీకు అధికారముగలదు. ఎన్నటికినీ దాని ఫలములయందులేదు. కర్మఫలములకు నీవు హేతువు కారాదు. కర్మలను మానుటయందు నీవు ఆసక్తుడవు కావలదు. అనగా ఫలాపేక్షరహితుడవై కర్తవ్యబుద్ధితో కర్మలనాచరింపుము. 2.47",

    "ఓ ధనంజయా! యోగస్థితుడవై ఆసక్తిని వీడి, సిద్ది-అసిద్దుల యెడ సమత్వ భావమును కలిగియుండి, కర్తవ్యకర్మలను ఆచరింపుము. ఈ సమత్వభావమునే యోగమందురు. 2.48",

    "ఈ సమత్వబుద్ధియోగముకంటెను సకామకర్మ మిక్కిలి నిమ్నశ్రేణికి చెందినది. కావున ఓ ధనంజయా! నీవు సమత్వబుద్ధియోగమునే ఆశ్రయింపుము - ఏలనన ఫలాసక్తితో కర్మలు చేయువారు అత్యంతదీనులు, కృపణులు. 2.49",

    "సమత్వ బుద్ధియుక్తుడైనవాడు పుణ్య పాపములను రెండింటిని ఈ లోకమునందే త్యజించును. అనగా కర్మఫలములు వానికి అంటవు. కనుక నీవు సమత్వబుద్ధి రూపయోగమును ఆశ్రయింపుము. ఇదియే కర్మాచరణమునందు కౌశలము. అనగాకర్మబంధములనుండి ముక్తుడగుటకు ఇదియే మార్గము. 2.50",

    "ఏలనన సమబుద్ధియుక్తులైన జ్ఞానులు కర్మఫలములను త్యజించి, జనన మరణబంధములనుండి ముక్తులయ్యెదరు. అంతేగాక వారు నిర్వికారమైన పరమ పదమును పొందుదురు. 2.51",

    "మోహమనెడి ఊబినుండి పూర్తిగా  బయటబడినప్పుడే నీవు వినిన, వినబోవు ఇహపరలోక సంబంధమైన సమస్త భోగముల నుండి వైరాగ్యమును పొందగలవు. 2.52",

    "నానా  విధములైన మాటలను వినుట వలన విచలితమైన ( అయోమయమునకు గురియైన) నీ బుద్ధి పరమాత్మయందు నిశ్చలముగా స్థిరముగా ఉన్నప్పుడే నీవు ఈ యోగమును పొందగలవు. అనగా నీకు పరమాత్మతో నిత్య  ( శాశ్వత) సంయోగము ఏర్పడును. 2.53",

    "అర్జునుడు పలికెను - ఓ కేశవా! సమాధిస్థితుడై పరమాత్మ  ప్రాప్తి నందిన స్థితప్రజ్ఞుని యొక్క లక్షణములెవ్వి? అతడు ఎట్లు భాషించును? ఎట్లు కూర్చొనును? ఎట్లు నడుచును? 2.54",

    "శ్రీ భగవానుడు పలికెను - ఓ అర్జునా! మనస్సునందలి కోరికలన్నియును పూర్తిగా తొలగిపోయి, ఆత్మద్వారా ఆత్మ యందు సంతుష్టుడైనవానిని, అనగా పరమాత్మ సంయోగము వలన ఆత్మానందమును పొందినవానిని స్థితప్రజ్ఞుడని యందురు. 2.55",

    "దుఃఖములకు క్రుంగిపోనివాడును, సుఖములకు పొంగిపోనివాడును, ఆసక్తిని, భయక్రోధములను వీడినవాడును ఐనట్టి మనన శీలుడు(ముని) స్థితప్రజ్ఞుడన బడును. 2.56",

    "దేనియందును మమతాసక్తులు లేనివాడును, అనుకూల పరిస్థితు లయందు హర్షము, ప్రతికూల పరిస్థితులయందు ద్వేషము మొదలగు వికారములకు లోను గాని వాడును అగు పురుషుడు స్థితప్రజ్ఞుడు అనబడును. 2.57",

    "తాబేలు తనఅంగములను అన్నివైపులనుండి లోనికి ముడుచుకొనునట్లుగా, ఇంద్రియములను  ఇంద్రియార్థముల (విషయాదుల) నుండి అన్నివిధముల ఉపసంహరించుకొనిన పురుషుని యొక్క బుద్ధి స్థిరముగా ఉన్నట్లు భావింపవలెను. 2.58",

    "ఇంద్రియముల ద్వారా విషయములను గ్రహంపనివానినుండి ఇంద్రియార్థములు మాత్రము వైదొలగును. కాని వాటి పై ఆసక్తి మిగిలిముండును. స్థితప్రజ్ఞునకు పరమాత్మ సాక్షాత్కారమైనందు వలన వానినుండి   ఆ ఆసక్తిగూడ తొలగిపోవును. 2.59",

    "ఓ అర్జునా! ఇంద్రియములు ప్రమథనశీలములు. మనుష్యుడు వాటిని నిగ్రహించుటకు ఎంతగా ప్రయత్నించినను, ఆసక్తి తొలగి పోనంతవఱకును అవి అతని మనస్సును ఇంద్రియార్థములవైపు బలవంతముగా లాగికొనిపోవుచునే యుండును. 2.60",

    "కనుక సాధకుడు ఆ ఇంద్రియములను అన్నింటిని వశమునందుంచుకొని, సమాహిత చిత్తుడై ( చిత్తమును పరమాత్మయందు లగ్నము చేసిన వాడై) మత్పరాయణుడై, ధ్యానమునందు కూర్చొనవలెను. ఏలనన ఇంద్రియములను వశము నందుంచుకొనువాని బుద్ధి స్థిరముగానుండును. 2.61",

    "విషయచింతన చేయు పురుషునకు ఆ విషయములయందు ఆసక్తి ఏర్పడును. ఆసక్తివలన ఆవిషయములను పొందుటకై కోరికలు కలుగును. ఆ కోరికలు తీరనప్పుడు క్రోధము ఏర్పడును. 2.62",

    "అట్టి క్రోధమువలన వ్యామోహము కలుగును. దాని ప్రభావమున స్మృతి చిన్నాభిన్నమగును. స్మృతిభ్రష్టమైనందున బుద్ధి అనగా జ్ఞానశక్తి నశించును. బుద్ధినాశమువలన మనుష్యుడు తన స్థితినుండి పతనమగును. 2.63",

    "అంతఃకరణమును వశమునందుంచుకొనిన సాధకుడు రాగద్వేషరహితుడై,  ఇంద్రియముల ద్వారా విషయములను గ్రహించుచున్నను మనశ్శాంతిని పొందును. 2.64",

    "మనః ప్రసన్నతను పొందిన వెంటనే అతనిదుఃఖములన్నియును నశించును. ప్రసన్నచిత్తుడైన కర్మయోగియొక్క బుద్ధి అన్నివిషయములనుండి వైదొలగి, పరమాత్మయందు మాత్రమే పూర్తిగా స్థిరమగును. 2.65",

    "ఇంద్రియములు, మనస్సు  వశమునందు ఉండని వాని యందు నిశ్చియాత్మకబుద్ధి ఉండదు. అట్టి అయుక్తమనుష్యుని అంతఃకరణమునందు ఆస్తిక భావమే కలుగదు. తద్భావనాహీనుడైన వానికి శాంతి లభింపదు.  మనశ్మాంతి లేనివానికి సుఖము ఎట్లు లభించును? 2.66",

    "నీటిపై తేలుచున్న నావను గాలినెట్టివేయును. అట్లే ఇంద్రియార్థములయందు సంచరించు ఇంద్రయములలో,  మనస్సు ఏ ఒక్క ఇంద్రియముతో  కూడియున్నను, ఆ ఒక్క ఇంద్రియమే మనోనిగ్రహములేని మనుజునిబుద్ధిని అనగా విచక్షణా శక్తిని హరించి వేయును. 2.67",

    "కనుక ఓ అర్జునా! ఇంద్రియములను ఇంద్రియార్థముల నుండి అన్నివిధిములుగ పూర్తిగా నిగ్రహించిన పురుషునియొక్క బుద్ధి స్థిరముగానుండును. 2.68",

    "నిత్యజ్ఞానస్వరూప  పరమానందప్రాప్తియందు స్థితప్రజ్ఞుడైన యోగి మేల్కొనియుండును. అది ఇతర ప్రాణులన్నింటికిని రాత్రితో సమానము. నశ్వరమైన ప్రాపంచిక సుఖప్రాప్తికై ప్రాకులాడుచు ప్రాణులన్నియు మేల్కోని యుండును. అది పరమాత్మతత్త్వమునెఱిగిన మునికి ( మననశీలునకు) రాత్రితో సమానము. 2.69",

    "సమస్తదిశలనుండి పొంగి ప్రవహించుచు వచ్చిచేరిన నదులన్నియును పరిపూర్ణమై నిశ్చలముగానున్న సముద్రమును ఏమాత్రము చలింపజేయకుండగనే అందులో లీనమగును. అట్లే సమస్తభోగములును స్థితప్రజ్ఞునియందు ఎట్టి  వికారములను కల్గింపకయే వానిలో లీనమగును. అట్టి పురుషుడే పరమశాంతిని పొందును. భోగాసక్తుడు శాంతిని పొందజాలడు. 2.70",

    "కోరికలన్నింటిని త్యజించి, మమతా- అహంకార, స్పృహారహితుడై చరించునట్టి పురుషుడే శాంతిని పొందును. 2.71",

    "ఓ అర్జునా! బ్రాహ్మీస్థితి  యనగా ఇదియే. (ఇదియే బ్రహ్మప్రాప్తి  కలిగిన పురుషునిస్థితి.) ఈ బ్రాహ్మీస్థితిని పొందిన యోగి ఎన్నడును మోహితుడు కాడు. అంత్యకాలమునందును ఈ బ్రాహ్మీ స్థితియందు స్థిరముగానున్ననాడు బ్రహ్మానందమును  పొందును. 2.72",

    "ఈ విధముగా ఓం తత్ సత్ అను భగవన్నామ ఉచ్చారణ పూర్వకమైన బ్రహ్మ విద్య మరియు యోగ శాస్త్ర మయమైన, శ్రీకృష్ణార్జునుల సంభాషణ లేక సంవాద రూపములో ఉన్న శ్రీమద్భగవద్గీతలోని రెండవ అధ్యాయమైన సాంఖ్యయోగము సమాప్తమైనది"
  ],
  "adhyaya-3" : [
    "అర్జునుడు పలికెను - ఓ జనార్దనా! కేశవా! నీ అభిప్రాయమును బట్టి కర్మకంటెను జ్ఞానమే శ్రేష్ఠమైనచో, భయంకరమైన ఈ యుద్ధకార్యమునందు నన్నేల వినియోగించుచున్నావు? 3.1",

    "కలగాపులగమువంటి నీ మాటలతో నా బుద్ది భ్రమకు లోనగునట్లు చేయుచున్నావు. కనుక నాకు  శ్రేయస్కరమైన ఒక మార్గమును నిశ్చయముగా తెల్పుము. 3.2",

    "శ్రీభగవానుడు పలికెను- ఓ అనఘా! అర్జునా! ఈ లోకమున రెండు నిష్ఠలు గలవని ఇంతకుముందే చెప్పియుంటిని. వానిలో సాంఖ్యయోగులకు జ్ఞానయోగముద్వారా, యోగులకు కర్మయోగము ద్వారా నిష్ఠ కలుగును. 3.3",

    "మనుష్యుడు కర్మలను ఆచరింపకయే నైష్కర్మ్యము అనగా యోగనిష్ఠాసిద్ధి అతనికి లభింపదు. అట్లే కేవలము కర్మలను త్యజించినంత మాత్రమున సిద్ధిని అనగా సాంఖ్యనిష్ఠను అతడు పొందజాలడు. 3.4",

    "ఏ మనుష్యుడైనను ఏ కాలమునందైనను క్షణమాత్రము గూడ కర్మను ఆచరింపకుండ ఉండలేడు. ఇందు ఎట్టి సందేహమునకును తావులేదు. ఏలనన మనుష్యులందఱును ప్రకృతిజనితములైన గుణములకు లోబడి కర్మలను చేయుటకు బాధ్యులగుదురు. ప్రతి వ్యక్తియు కర్మను ఆచరింపవలసియే యుండును. 3.5",

    "బలవంతముగా, బాహ్యముగా ఇంద్రియ వ్యాపారములను నిగ్రహించి, మానసికముగా ఇంద్రియ విషయములను చింతించునట్టి మూఢుని మిథ్యాచారి అనగా దంభి అనియందురు. 3.6",

    "కాని, అర్జునా! మనస్సుతో ఇంద్రియములను వశపఱచుకొని, అనాసక్తుడై ఇంద్రియములద్వారా కర్మయోగాచరణమును కావించు పురుషుడు శ్రేష్ఠుడు. 3.7",

    "నీవు శాస్త్రవిహితకర్తవ్యకర్మలను ఆచరింపుము. ఏలనన, కర్మలను చేయకుండుటకంటెను చేయుటయే ఉత్తమము. కర్మలను ఆచరింపనిచో నీ శరీర నిర్వహణముగూడ సాధ్యము గాదు. 3.8",

    "ఓ అర్జునా! యజ్ఞార్థము చేయబడు కర్మలలో గాక ఇతర కర్మలయందు నిమగ్నులగుటవలన మనుష్యులు కర్మబంధములలో చిక్కుపడుదురు. కనుక నీవు ఆసక్తిరహితుడవై యజ్ఞార్థమే కర్తవ్యకర్మలను చక్కగా ఆచరింపుము. 3.9",

    "కల్పాదియందు బ్రహ్మదేవుడు యజ్ఞసహితముగ ప్రజలను సృష్టించి, \"మీరు ఈ యజ్ఞములద్వారా వృద్ధిచెందుడు. ఈ యజ్ఞములు మీకు కామధేనువు వలె కోరిన కోర్కెలనెల్ల తీర్చును\" అని పల్కెను. 3.10",

    "ఈ యజ్ఞముల ద్వారా మీరు దేవతలను తృప్తిపఱచుడు. మఱియు ఆ దేవతలు మిమ్ములను అనుగ్రహింతురు. నిస్స్వార్థభావముతో మీరు పరస్పరము సంతృప్తిపఱుచుకొనుచు పొందగలరు. అని పల్కెను. 3.11",

    "యజ్ఞములద్వారా సంతృప్తిని పొందిన దేవతలు మీకు (మానవులకు) అయాచితముగనే ఇష్టములైన భోగములను ప్రసాదించెదరు. ఈ విధముగ దేవతలచే అనుగ్రహింపబడిన ఈ భోగములను ఆదేవతలకు నివేదనచేయక తానే అనుభవించువాడు నిజముగా దొంగయే. 3.12",

    "యజ్ఞశిష్టాన్నమును తిను శ్రేష్ఠపురుషులు అన్ని పాపములనుండి ముక్తులయ్యెదరు. తమ శరీరపోషణ కొఱకే ఆహారమును సిద్ధపఱచు (వండు) కొను పాపులు పాపమునే భుజింతురు. 3.13",

    "ప్రాణులన్నియు అన్నము (ఆహారము) నుండి జన్మించును. అన్నోత్పత్తి వర్షములవలన ఏర్పడును. యజ్ఞములవలన వర్షములు కురియును. విహితకర్మలు యజ్ఞములకు మూలములు.  3.14",

    "వేదములు విహిత కర్మలకు మూలములు. వేదములు నిత్యుడైన పరమాత్మ నుండి ఉద్భవించినవని తెలిసికొనుము. అందువలన సర్వవ్యాపియు, అవ్యయుడును ఐన పరమాత్మ సర్వదా యజ్ఞములయందే ప్రతిష్ఠితుడై యున్నాడు. 3.15",

    "ఓ అర్జునా! ఇట్లు పరంపరాగతముగా కొనసాగు చున్న సృష్టిచక్రమునకు అనుకూలముగా ప్రవర్తింపనివాడు అనగా తన కర్తవ్యములను పాటింపక ఇంద్రియ సుఖలోలుడైన వాడు పాపి. అట్టివానియొక్క జీవితము వ్యర్థము. 3.16",

    "సచ్చిదానందఘనపరమాత్మప్రాప్తినందిన జ్ఞానియైన మహాత్ముడు నిత్యమైన ఆత్మయందే రమించును. అతడు పూర్ణకాముడు. కనుక ఆత్మయందే తృప్తినొందును. అతడు ఆత్మయందే నిత్యసంతుష్టుడు. అట్టివానికి ఎట్టి కర్తవ్యమును ఉండదు. 3.17",

    "అట్టి మహాత్ముడు ఈ జగత్తునందు కర్మలు చేయుట వలనను, చేయకుండుట వలనను అతనికి ఎట్టి ప్రయోజనమూ ఉండదు. అతనికి సర్వప్రాణులతోడను స్వార్థపరమైన సంబంధము ఏవిధముగను ఏమాత్రము ఉండదు. 3.18",

    "అందువలన నీవు నిరంతరము ఆసక్తిరహితుడవై కర్తవ్య కర్మలను చక్కగా ఆచరింపుము. ఏలనన, ఆసక్తిని వీడి కర్మలను సదా ఆచరించు మనుష్యునకు పరమాత్మ ప్రాప్తి కలుగును. 3.19",

    "జనకుడు మొదలగు జ్ఞానులుగూడ ఆసక్తి రహితముగా కర్మలను ఆచరించుట వలననే పరమసిద్ధిని పొందిరి. కావున నీవును లోకహితార్థమై కర్మలను ఆచరించుటయే సముచితము. 3.20",

    "శ్రేష్ఠుడైన పురుషుని ఆచరణమునే (ప్రవర్తననే) ఇతరులను అనుసరింతురు. అతడు నిల్పిన (ప్రతిష్ఠించిన) ప్రమాణములనే లోకులందఱును పాటించెదరు. 3.21",

    "ఓ అర్జునా! ఈ ముల్లోకములయందును నాకు కర్తవ్యము అనునదియే లేదు. అట్లే పొందదగిన వస్తువులలో ఏదియును నేను పొందనిదియును లేదు. ఐనను నేను కర్మలయందే ప్రవర్తిల్లుచున్నాను. 3.22",

    "(ఏలనన) ఓ పార్థా! ఎప్పుడైనను నేను సావధానుడనై కర్మలయందు ప్రవర్తింపకున్నచో లోకమునకు గొప్పహాని సంభవించును. ఎందుకనగా మనుష్యులందఱును అన్ని విధముల నా మార్గమునే అనుసరింతురు. 3.23",

    "నేను కర్మలను ఆచరించుట మానినచో ఈ లోకములన్నియును నశించును. అంతేగాదు లోకములందు అల్లకల్లోలములు (సాంకర్యములు) చెలరేగును. ప్రజానష్టము వాటిల్లును. అప్పుడు అందులకు నేనే కారకుడనయ్యెదను. 3.24",

    "ఓ భారతా! (అర్జునా!) అజ్ఞానులు కర్మలయందు ఆసక్తులై వాటిని ఆచరించినట్లుగా విద్వాంసుడు (జ్ఞాని) కూడా లోకహితార్థమై ఆసక్తిరహితుడై కర్మలను ఆచరింపవలెను. 3.25",

    "పరమాత్మస్వరూపమునందు నిశ్చలస్థితిని పొందిన జ్ఞాని శాస్త్రవిహిత కర్మలను ఆసక్తితో (ఫలాసక్తితో) ఆచరించు అజ్ఞానులబుద్ధులను భ్రమకు లోను చేయరాదు. అనగా కర్మలయందు వారికి అశ్రద్ధను కలిగింపరాదు. పైగా తానుకూడ శాస్త్రవిహితములైన సమస్తకర్మలను చక్కగా చేయుచు వారితోగూడ అట్లే చేయింపవలెను. 3.26",

    "వాస్తవముగా కర్మలన్నియును అన్నివిధముల ప్రకృతిగుణములద్వారానే చేయబడుచుండును అహంకార విమూఢాత్ముడు (అహంకారముచే మోహితమైన అంతఃకరణముగల అజ్ఞాని) 'ఈ కర్మలకు నేనే కర్తను' అని భావించును. 3.27",

    "కాని, ఓ మహాబాహూ! (అర్జునా!) గుణవిభాగ తత్త్వమును, కర్మవిభాగ తత్త్వమును తెలిసికొన్న జ్ఞానయోగి గుణములే గుణములయందు ప్రవర్తిల్లుచున్నవని భావించి, వాటియందు ఆసక్తుడు కాడు. 3.28",

    "ప్రకృతిగుణములచే పూర్తిగా మోహితులైన మనుష్యులు ఆ గుణముల యందును, కర్మలయందును మిక్కిలి ఆసక్తులగుదురు. అట్టి మిడిమిడిజ్ఞానముగల మందబుద్ధులైన అజ్ఞానులను పూర్తిగా తెలిసిన జ్ఞానియైనవాడు భ్రమకు (ఊగిసలాటకు) గురి చేయరాదు. 3.29",

    "అంతర్యామిని, పరమాత్మను ఐన నాయందే నీ చిత్తమును ఉంచి, కర్మలనన్నింటినీ నాకే అర్పించి, ఆశా మమతా సంతాపములను వీడి, యుద్ధము చేయుము. 3.30",

    "దోషదృష్టి లేకుండ శ్రద్ధాయుక్తులై నా ఈ మతమును అనుసరించు మానవులు గూడ సమస్త కర్మబంధముల నుండి ముక్తులయ్యెదరు. 3.31",

    "కాని నాయందు దోషారోపణ చేయుచు, నా ఈ ఉపదేశమును అనుసరింపని మూర్ఖులు సమస్తజ్ఞాన విషయముల యందును మోహితులై (విపరీత జ్ఞానోపహతులై) భ్రష్టులై, కష్టనష్టముల పాలయ్యెని ఎఱుంగుము. 3.32",

    "సమస్త ప్రాణులును తమ తమ ప్రకృతులను అనుసరించి (స్వభావములకు లోబడి) కర్మలు చేయుచుండును. జ్ఞానియు తన ప్రకృతిని (స్వభావమును) అనుసరించియే క్రియలను ఆచరించును. ఎవ్వరైనను పట్టుబట్టి కర్మలను ఎట్లు త్యజింపగలరు? 3.33",

    "ప్రతిఇంద్రియార్థమునందును (ప్రతిఇంద్రియ విషయమునందును) రాగద్వేషములు దాగియున్నవి. మనుష్యుడు ఈ రెండింటికిని వశము కాకూడదు. ఏలనన ఈ రెండే మానవుని శ్రేయస్సునకు విఘ్నకారకములు, మహాశత్రువులు. 3.34",

    "పరధర్మమునందు ఎన్నో సుగుణములు ఉన్నను స్వధర్మమునందు అంతగా సుగుణములు లేకున్నను చక్కగా అనుష్ఠింపబడు ఆ పరధర్మముకంటెను స్వధర్మాచరణమే ఉత్తమము. స్వధర్మాచరణమునందు మరణించుటయు శ్రేయస్కరమే. పరధర్మాచరణము భయావహము. 3.35",

    "అర్జునుడు పలికెను - ఓ కృష్ణా! మానవుడు తనకు ఇష్టము లేకున్నను ఇతరులు బలవంతము చేసినట్లుగా దేని ప్రభావముచే ప్రేరితుడై పాపములను చేయు చుండును? 3.36",

    "శ్రీభగవానుడు పలికెను - రజోగుణమునుండి ఉత్పన్నమగునదే కామము. ఇదియే క్రోధరూపమును దాల్చును. ఇది మహాశనము. భోగానుభవములతో ఇది చల్లారునది గాదు. పైగా అంతులేని పాపకర్మా చరణములకు ఇదియే ప్రేరకము. కనుక ఈ విషయమున దీనిని పరమశత్రువుగా ఎఱుంగుము. 3.37",

    "పొగచే అగ్నియు, ధూళిచే అద్దము, మావిచే గర్భము కప్పివేయబడునట్లు, జ్ఞానము కామముచే ఆవృతమై యుండును. 3.38",

    "ఓ అర్జునా! కామము అగ్నితో సమానమైనది. (అగ్నివంటిది) అది ఎన్నటికిని చల్లారదు. జ్ఞానులకు  నిత్యవైరి. అది మనుష్యుని జ్ఞానమును కప్పివేయుచుండును. 3.39",

    "ఇంద్రియములు, మనస్సు, బుద్ధి ఈ కామమునకు నివాసస్థానములు. ఇది (ఈకామము) మనోబుద్దీంద్రియముల ద్వారా జ్ఞానమును కప్పివేసి, జీవాత్మను మోహితునిగా చేయును. 3.40",

    "కావున ఓ అర్జునా! మొదట ఇంద్రియములను వశపఱచుకొనుము. వి పిదప జ్ఞానవిజ్ఞానములను నశింపజేయునట్టి మహాపాపియైన ఈ కామమును అవశ్యముగా సర్వశక్తులనొడ్డి రూపుమాపుము. 3.41",

    "స్థూలశరీరముకంటెను ఇంద్రియములు బలీయములు, సూక్ష్మములు, శ్రేష్ఠములు అని పేర్కొందురు. ఇంద్రియములకంటెను మనస్సు, దానికంటెను బుద్ధి శ్రేష్ఠమైనవి. ఆ బుద్ధికంటెను అత్యంతశ్రేష్ఠమైనది, సూక్ష్మమైనది ఆత్మ. 3.42",

    "ఈ విధముగా బుద్ధికంటెను ఆత్మ పరమైనదని అనగా సూక్ష్మము, బలీయము, మిక్కిలి శ్రేష్ఠము ఐనదని తెలిసికొని, ఓ మహాబాహూ! బుద్ధిద్వారా మనస్సును వశపఱచుకొని, దుర్జయశత్రువైన కామమును నిర్మూలింపుము. 3.43",

    "ఈ విధముగా ఓం తత్ సత్ అను భగవన్నామ ఉచ్చారణ పూర్వకమైన బ్రహ్మ విద్య మరియు యోగ శాస్త్ర మయమైన, శ్రీకృష్ణార్జునుల సంభాషణ లేక సంవాద రూపములో ఉన్న శ్రీమద్భగవద్గీతలోని మూడవ అధ్యాయమైన కర్మయోగము సమాప్తమైనది"
  ],
  "adhyaya-4" : [
    "శ్రీ భగవానుడు ఇట్లు పలికెను - నేను నిత్యసత్యమైన ఈ యోగమును సూర్యునికి తెలిపితిని. సూర్యుడు తన పుత్రుడైన వైవస్వత మనువునకు దీనిని బోధించెను. ఆ మనువు తన కుమారుడైన ఇక్ష్వాకునకు ఉపదేశించెను. 4.1",

    "ఓ పరంతపా ! (అర్జునా ! )ఈ విధముగా పరంపరా ప్రాప్తమైన ఈ యోగమును రాజర్షులు ఎఱింగిరి. కాని అనంతరము ఈ యోగము కాలక్రమమున భూలోకము నందు లుప్త ప్ర్రాయమయ్యెను. 4.2",

    "ఈ యోగము ఉత్తమమైనది. రహస్యంగా ఉంచదగినది. నీవు నాకు భక్తుడవు.  ప్రియసఖుడవు. కనుక, మిక్కిలి పురాతనమైన ఈ యోగమును నేడు నీకు తెలుపుచున్నాను. 4.3",

    "అర్జునుడు పలికెను - కృష్ణా !  నీ జన్మ ఇటీవలిదే. సూర్యుని జన్మ కల్పాదియందు జరిగినది. అనగా అతి ప్రాచీనమైనది. కనుక నీకు సూర్యునకు దీనిని ఉపదేశించుట ఎట్లు సాధ్య పడును ? దీనిని నేను విశ్వసించుట యెట్లు ? 4.4",

    "శ్రీ భగవానుడు తెలిపెను - ఓ పరంతపా ! ఓ అర్జునా ! నాకును నీకును  పెక్కు జన్మలు గతించినవి.  నేను వాటిని ఎఱుగుదును. కాని వాటిని అన్నింటిని నేను ఎఱుంగుదును. నీ వెఱుగవు. 4.5",

    "నేను జన్మ రహితుడను, నిత్యుడను, సమస్త ప్రాణులకు ఈశ్వరుడను.  అయిననూ, నా ప్రకృతిని అధీనములోనుంచుకుని, నా యోగమాయచే అవతరించుచుందును. 4.6",

    "ఓ భారతా! (అర్జునా!) ధర్మమునకు హాని కలిగినప్పుడును, అధర్మము  పెచ్చు పెరిగిపోవుచున్నప్పుడును,  నన్ను నేను సృజించుకొందును. అనగా, సాకార రూపముతో ఈ లోకమున అవతరింతును. 4.7",

    "సత్పురుషులను పరిరక్షించుటకును, దుష్టులను రూపుమాపుటకును,  ధర్మమును సుస్థిరమొనర్చుటకును , నేను ప్రతి యుగము నందును అవతరించుచుందును. 4.8",

    "ఓ అర్జునా !  నా జన్మ(అవతారము) లు , కర్మములు,   దివ్యములు. అనగా, నిర్మలములు. అలౌకికములు. ఈ తత్వ రహస్యమును తెలిసికొనినవాడు తనువును చాలించిన పిమ్మట మఱల  జన్మింపడు సరికదా ! నన్నే చేరును. 4.9",

    "ఇదివరలో గూడ సర్వథా రాగ, భయ, క్రోధ రహితులైనవారు, దృఢమైన భక్తి, తాత్పర్యములతో స్థిర బుద్ధి గలిగి, నన్ను ఆశ్రయించిన భక్తులు పెక్కుమంది జ్ఞాన తపస్సంపన్నులై,  పవిత్రులై నా స్వరూపమును పొందియుండిరి. 4.10",

    "పార్థా !  భక్తులు నన్ను సేవించిన రీతికి అనుగుణముగ నేను వారిని అనుగ్రహింతును.  మనుష్యులందరును వివిధ రీతులలో నా మార్గమునే అనుసరింతురు. 4.11",

    "ఈ లోకమున కర్మ ఫలములను ఆశించువారు ఇతర దేవతలను పూజింతురు.  ఏలనన అట్లు చేయుటచే  కర్మల వలన కలుగు సిద్ధి వారికి శీఘ్రముగా లభించును. 4.12",

    "బ్రాహ్మణ, క్షత్రియ, వైశ్య, శూద్ర వర్ణముల వారిని వారి వారి గుణకర్మల ననుసరించి వేర్వేరుగా సృష్టించితిని. ఈ సృష్టి కార్యక్రమమునకు నేనే కర్తనయినను,  శాశ్వతుడను, పరమేశ్వరుడైన నన్ను వాస్తవముగా అకర్తను గా తెలుసికొనుము. 4.13",

    "నాకు కర్మ ఫలాసక్తి లేదు. కావున కర్మలు నన్నంటవు. ఈ విధముగా నా తత్త్వవమును తెలిసినవారు  కర్మబద్ధులు  కారు. 4.14",

    "ఓ అర్జునా !  ప్రాచీనులైన ముముక్షువులు (మోక్షము పొందకోరిన వారు) ఈ విధముగా (నా తత్త్వ రహస్యమును ) దెలిసికొని కర్మలనాచరించిరి. కావున  నీవును ఆ పూర్వులవలెనే నిష్కామ భావముతో కర్మల నాచరింపుము. 4.15",

    "కర్మ అనగా ఏమి ? అకర్మ యనగానేమి ? ఈ విషయమును నిర్ణయించుటలో విద్వాంసులు సైతము భ్రాంతికి లోనగుచున్నారు.  (తికమక పడుతున్నారు) కావున, కర్మతత్వమును నీకు చక్కగా విశదపరచెదను. దానిని తెలుసుకొని నీవు అశుభముల నుండి అనగా కర్మబంధముల నుండి ముక్తుడవయ్యెదవు. 4.16",

    "'కర్మ' తత్వమును తెలిసికొనవలెను, అట్లే 'అకర్మ' స్వరూపమును గూడ ఎరుగవలెను, 'వికర్మ' లక్షణములను కూడా తెలిసికొనుట చాలా అవసరము. ఏలనన, కర్మ తత్త్వము అతి నిగూఢమైనది. 4.17",

    "కర్మయందు \"అకర్మ\" ను, అకర్మ యందు 'కర్మ' ను దర్శించు వాడు మానవులలో బుద్ధిశాలి. అతడు యోగి మరియు సమస్త కర్మలు చేయువాడు. 4.18",

    "ఎవని కర్మలన్నియును, శాస్త్ర సమ్మతములై, కామ సంకల్ప వర్జితములై జరుగునో, అట్లే ఎవని కర్మలన్నియును  జ్ఞానాగ్నిచే భస్మమగునో, అట్టి మహా పురుషుని జ్ఞానులు పండితుడని అందురు. 4.19",

    "సమస్త కర్మల యందును, వాటి ఫలితముల యందును సర్వథా ఆసక్తిని వీడి, సంసార - ఆశ్రయ రహితుడై, పరమాత్మ యందే నిత్యతృప్తుడైన  పురుషుడు, కర్మల యందు చక్కగా ప్రవృత్తుడైనప్పటికిని, వాస్తవముగా వాటికి (కర్మలకు) కర్త కాడు. 4.20",

    "అంతఃకరణమును,  శరీర ఇంద్రియములను జయించినవాడు, సమస్త భోగ సామాగ్రిని పరిత్యజించిన వాడు, ఆశారహితుడైన సాంఖ్యయోగి కేవలము శారీరిక కర్మలను ఆచరించుచున్ననూ, పాపములను పొందడు. 4.21",

    "తాను కోరకుండగనే లభించిన పదార్థములతో (అప్రయత్నయముగా అమరిన లాభాలతో) సంతుష్టుడైన వాడు, అసూయలేని వాడు, హర్షశోకాది ద్వంద్వములకు అతీతుడు అయినవాడు సిద్ధి యందును, అసిధ్ది యందును కూడా సమదృష్టి కలిగియుండును. అట్టి కర్మ యోగి కర్మలనాచరించుచున్నను వాటి బంధములలో చిక్కుపడడు. 4.22",

    "ఏలనగా ఆసక్తి, దేహాభిమానము, మమకారము ఏ మాత్రమూ లేని వాడును, పరమాత్మ జ్ఞానము నందే నిరంతరము మనస్సును లగ్నమొనర్చినవాడును, కేవలము యజ్ఞార్థమే కర్మలను ఆచరించు వాడును అగు మనుష్యుని యొక్క కర్మలన్నియును పూర్తిగా విలీనములగును అనగా మిగిలియుండవు. 4.23",

    "యజ్ఞ కార్యముల యందు ఉపయుక్తమగు స్రువాదిసాధనములు బ్రహ్మము. హోమము చేయబడు ద్రవ్యము బ్రహ్మము. అగ్నియు బ్రహ్మము. యజ్ఞము నాచరించు కర్తయు బ్రహ్మము. హవన క్రియయు బ్రహ్మము. ఈ విధముగా బ్రహ్మ కర్మ యందు స్థితుడై యుండు యోగి ద్వారా పొందదగిన యజ్ఞఫలము గూడ  బ్రహ్మమే. 4.24",

    "కొందఱు  యోగులు దైవపూజారూపయజ్ఞమును చక్కగా అనుష్ఠింతురు. మఱి కొందఱు యోగులు బ్రహ్మాగ్నియందు అనగా  పరబ్రహ్మపరమాత్మ రూపాగ్ని యందు అభేద దర్శన రూప యజ్ఞము ద్వారా (అనగా పరమ సత్యమనే అగ్నిలో తమ ఆత్మనే సమర్పించటం) ఆత్మరూప  యజ్ఞమును ఆచరింతురు. 4.25",

    "కొందరు యోగులు శ్రోత్రాది -  ఇంద్రియములను సంయమన రూపాగ్నుల యందు హోమము చేయుదురు. మరికొంతమంది యోగులు శబ్దాది సమస్త  విషయములను ఇంద్రియ రూపాగ్నుల యందు  హవనము చేయుదురు.  అనగా యోగులు మనోనిగ్రహము  ద్వారా ఇంద్రియములను అదుపు చేయుదురు. తత్ఫలితముగా శబ్దాది విషయములు ఎదురుగా లేకున్నను వాటి ప్రభావము వారి ఇంద్రియములపై ఏమూత్రము ఉండదు. 4.26",

    "మరికొందఱు యోగులు ఇంద్రియముల క్రియలను, ప్రాణముల క్రియలను, అన్నింటిని జ్ఞానముచే ప్రకాశితమైన ఆత్మ సంయమయోగరూపాగ్నిలో హవనము చేయుచుందురు. అట్టివారు పూర్తిగా పరమాత్మయందే స్థితులైయుందురు. అపుడు  ప్రాణ ఇంద్రియముల స్వభావము వారిపై ఏ మాత్రము ఉండదు. ఏలనన వారి బుద్ధియందు పరమాత్మ మాత్రమే నిలిచి యుండును. 4.27",

    "కొందరు ద్రవ్య సంబంధ యజ్ఞములను, మరికొందరు తపోరూప యజ్ఞములను, కొందరు యోగరూప యజ్ఞములను చేయుదురు. మఱికొందరు అహింసాది తీక్ష్ణ వ్రతములను చేపట్టి, యత్నశీలురై స్వాధ్యాయ రూప జ్ఞానయజ్ఞములను ఆచరింతురు. 4.28",

    "కొందఱు యోగులు అపాన వాయువు నందు ప్రాణ వాయువును, మరి కొందరు ప్రాణ వాయువు నందు అపాన వాయువును హవనము చేయుదురు. 4.29",

    "కొందరు యోగులు నియమిత ఆహార నిష్ఠితులై ప్రాణాయామ పరాయణులై  ప్రాణాపాన గమనములను నిలిపి ప్రాణములను ప్రాణముల యందే హవనము చేయుదురు. యజ్ఞవిదులైన ఈ సాధకులు అందరూ యజ్ఞముల ద్వారా పాపములను రూపుమాపుదురు. 4.30",

    "ఓ కురుసత్తమా! (అర్జునా) యజ్ఞపూతశేషమైన అమృతమును అనుభవించు యోగులకు సనాతుడును, పరబ్రహ్మమును అగు పరమాత్మ యొక్క లాభము కలుగును. యజ్ఞము చేయని వారికి ఈ మర్త్య లోకమే సుఖప్రదము కాదు. ఇంక, ఆ పరలోక విషయము చెప్పనేల? 4.31",

    "ఈ ప్రకారముగనే ఇంకను బహువిధములైన యజ్ఞములు వేదములలో విస్తృతంగా వివరింపబడినవి. ఈ యజ్ఞములనన్నింటిని త్రికరణ శుద్ధిగా (మనో వాక్కాయములచే) ఆచరించినప్పుడే అవి సుసంపన్నములగునని తెలిసికొనుము. ఇట్లు ఈ కర్మతత్త్వమును తెలిసికొని, అనుష్ఠించుట వలన నీవు ప్రాపంచిక (కర్మ) బంధముల నుండి సర్వథా విముక్తుడవయ్యెదవు. 4.32",

    "ఓ పరంతపా! అర్జునా!  ద్రవ్యమయ యజ్ఞము కంటెను జ్ఞాన యజ్ఞము మిక్కిలి శేష్ఠమైనది.  కర్మలన్నియును  జ్ఞానము నందే పరిసమాప్తమగును. 4.33",

    "నీవు తత్త్వమును దర్శించిన జ్ఞానుల కడకేగి,ఆ జ్ఞానమును గ్రహింపుము. వారికి దండ ప్రణామము లాచరించుట వలనను , సేవలొనర్చుటవలనను, కపటము లేకుండ భక్తి శ్రద్ధలతో సముచిత రీతిలో ప్రశ్నించుట వలనను, పరమాత్మ తత్త్వమును చక్కగానెఱింగిన జ్ఞానులు సంప్రీతులై, నీకు ఆ పరమాత్మ తత్త్వమును ఉపదేశించెదరు. 4.34",

    "ఓ అర్జునా ! ఈ తత్త్వ జ్ఞానమునెఱింగినచో  మఱల ఇట్టి వ్యామోహములో చిక్కుకొనవు. ఈ జ్ఞాన ప్రభావముతో సమస్త ప్రాణులను నీలో సంపూర్ణంగా చూడగలవు. పిమ్మట సచ్చిదానందఘన పరమాత్ముడనైన నాలో చూడగలవు. 4.35",

    "ఒకవేళ పాపాత్ములందఱి కంటెను నీవు ఒక మహాపాపివి అయినచో, జ్ఞాననౌక సహాయముతో పాప సముద్రము నుండి నిస్సందేహముగా, పూర్తిగా బయట పడగలవు. 4.36",

    "ఓ అర్జునా ! ప్రజ్వలించుచున్న అగ్ని సమిధలను భస్మము చేసినట్లు, జ్ఞానమను అగ్ని కర్మలనన్నింటిని భస్మమొనరించును. 4.37",

    "ప్రపంచమున జ్ఞానముతో సమానముగ పవిత్రమైనది మరియొకటి లేనే లేదు. శుద్ధాంతఃకరణముగల సాధకుడు బహుకాలము వరకు, కర్మయోగాచరణము చేసి, ఆత్మ యందు అదే జ్ఞానమును తనంతట తానే పొందగలడు. 4.38",

    "జితేంద్రియుడు, (ఇంద్రియములను నియంత్రణ చేసేవాడు), సాధనపరాయణుడు , శ్రద్ధాళువైన మనుజునకు ఈ భగవత్తత్త్వ జ్ఞానము లభించును. ఆ జ్ఞానము కలిగిన వెంటనే (ఏ మాత్రము విలంబము లేకుండ) అతడు భగవత్తత్త్వ రూపమైన పరమశాంతిని  పొందును. 4.39",

    "అవివేకియు, శ్రద్ధారహితుడును అయిన సంశయాత్ముడు పరమార్థ విషయమున  అవశ్యము భ్రష్టుడే యగును. అట్టి సంశయాత్మునకు  ఈ లోకము నందు గాని, పరలోకము నందు గాని, ఎట్టి సుఖమూ ఉండదు. 4.40",

    "ఓ ధనంజయా! (ఓ అర్జునా!) విధి పూర్వకముగ కర్మలను ఆచరించుచు, కర్మ ఫలములను అన్నింటిని భగవదర్పణము చేయుచు, వివేకముద్వారా  సంశయములనన్నింటిని తొలగించుకొనుచు, అంతఃకరణమును వశము నందుంచుకొనిన వానిని కర్మలు బంధింపజాలవు. 4.41",

    "కావున, ఓ భారతా! (ఓ అర్జునా!) నీ హృదయము నందు గల అజ్ఞానజనితమైన ఈ సంశయమును, వివేక జ్ఞానమను   ఖడ్గముతో రూపుమాపి, సమత్వ రూప కర్మ యోగము నందు స్తితుడవై యుద్ధమునకు సన్నద్ధుడవగుము. 4.42",

    "ఈ విధముగా ఓం తత్ సత్ అను భగవన్నామ ఉచ్చారణ పూర్వకమైన బ్రహ్మ విద్య మరియు యోగ శాస్త్ర మయమైన, శ్రీకృష్ణార్జునుల సంభాషణ లేక సంవాద రూపములో ఉన్న శ్రీమద్భగవద్గీతలోని నాలుగవ అధ్యాయమైన జ్ఞానకర్మసన్యాసయోగము సమాప్తమైనది"
  ],
  "adhyaya-5" : [
    "అర్జునుడు పలికెను - ఓ కృష్ణా! ఒక్కసారి కర్మ సన్న్యాసమును, మరియొక సారి కర్మ యోగమును ప్రశంసించుచున్నావు. నిశ్చయముగా ఈ రెండింటిలో నాకు ఏది శ్రేయస్కరమో చెప్పుము. 5.1",

    "శ్రీ కృష్ణ భగవానుడు పలికెను - కర్మసన్న్యాసము, కర్మయోగము అను ఈ రెండును పరమ కళ్యాణదాయకములే. కానీ ఈ రెండింటిలోను,  కర్మసన్న్యాసముకంటెను కర్మ యోగము సాధన యందు సుగమమగుటవలన శ్రేష్ఠమైనది. 5.2",

    "మహాబాహో! (అర్జునా!) ఎవ్వరినీ ద్వేషింపని, దేనినీ కాంక్షింపని కర్మయోగిని నిత్య సన్న్యాసిగా ఎఱుంగవలెను. ఏలనన రాగద్వేషాది ద్వందములను  అధిగమించిన వాడు అవలీలగా సంసార బంధముల నుండి ముక్తుడగును. 5.3",

    "సాంఖ్య, కర్మ యోగములు వేర్వేఱు ఫలములను ఇచ్చునని మూర్ఖులు పలికెదరు. పండితులట్లు పలుకరు. ఆ రెండింటిలో ఏ ఒక్కదానినైనను బాగుగా (విధివిధానముగా) ఆచరించినవాడు ఆ రెండింటి ఫలస్వరూపమైన పరమాత్మను పొందును. 5.4",

    "జ్ఞానయోగులు పొందు పరంధామమునే కర్మ  యోగులు కూడా పొందుదురు. జ్ఞాన యోగ ఫలమును, కర్మ యోగ ఫలమును ఒక్కటిగా చూచువాడే యదార్థమును గ్రహించును. 5.5",

    "కానీ, ఓ అర్జునా! కర్మ యోగమును అనుష్ఠింపక, సన్న్యాసము అనగా మనస్సు, ఇంద్రియములు, శరీరముల ద్వారా జరుగు కర్మలన్నింటియందును కర్తృత్వమును త్యజించుట కష్టము. భగవత్స్వరూపమును మననము చేయు కర్మ యోగి పరబ్రహ్మ పరమాత్మను శీఘ్రముగా పొందగలడు. 5.6",

    "మనస్సును వశము నందుంచుకొనినవాడు, జితేంద్రియుడు, అంతఃకరణ శుద్ధి కలవాడు, సర్వ ప్రాణులలో ఆత్మ స్వరూపుడైన పరమాత్మను తన ఆత్మగా కలవాడు అగు కర్మ యోగి,  కర్మలను ఆచరించుచున్నను, ఆ కర్మలు వానిని అంటవు. 5.7",

    "తత్వజ్ఞునుడైన సాంఖ్య యోగి చూచుచు, వినుచు, స్పృశించుచు, ఆఘ్రాణించుచు, భుజించుచు (తినుచు), నడచుచు, నిద్రించుచు, శ్వాస క్రియలను నడపుచు ఉన్ననూ, నేను (ఏ కొంచెమూ) ఏమియు చేయట లేదు అని భావించును. 5.8",

    "అంతేకాదు, భాషించుచు, త్యజించుచు, గ్రహించుచు, కనులను తెరచుచు, మూయుచు ఉన్ననూ ఇంద్రియములు తమ తమ విషయముల యందు ప్రవర్తించుచున్నవనియు (తానేమియు చేయుటలేదనియు)భావించును. 5.9",

    "కర్మలనన్నింటిని భగవదర్పణము గావించి, ఆసక్తి రహితముగ కర్మలనాచరించు వానిని,  పాపములు తామరాకు పై నీటి బిందువువలే అంటవు. 5.10",

    "కర్మ యోగులు మమతాసక్తి రహితులై కేవలము ఇంద్రియములు, మనస్సు, బుధ్ధి, శరీరముల ద్వారా అంతఃకరణ శుద్ధికై కర్మలను ఆచరింతురు. 5.11",

    "నిష్కామ కర్మ యోగి కర్మ ఫలములను త్యజించి, భగవత్ప్రాప్తి రూపమైన శాంతిని పొందును. కర్మ ఫలాసక్తుడైన వాడు, ఫలేచ్ఛతో కర్మలనాచరించి బద్ధుడగును. 5.12",

    "అంతఃకరణమును అదుపులోనుంచుకుని, సాంఖ్య యోగమును ఆచరించు పురుషుడు కర్మలను ఆచరింపకయే, ఆచరింపజేయకయే, నవద్వారములు గల శరీరమునందు సమస్త కర్మలను మానసికముగా త్యజించి, సచ్చిదానంద ఘనపరమాత్మ స్వరూపమున స్థితుడై, ఆనందమును అనుభవించును. 5.13",

    "పరమేశ్వరుడు మానవుల యొక్క కర్తృత్వమును కాని, వారి కర్మలను కాని, కర్మ ఫల సంయోగమును కాని సృజింపడు. ఈ యన్నింటిలో ప్రకృతియే వ్రవర్తిల్లును. 5.14",

    "సర్వవ్యాపి అయిన భగవంతుడు ప్రాణుల పుణ్యపాప  కర్మలలో దేనికిని భాగస్వామి కాడు. అజ్ఞానముచే జ్ఞానము కప్పబడియుండుట వలన ప్రాణులు మోహితులతగుచుందురు. 5.15",

    "కాని, వారి (ప్రాణుల) అజ్ఞానము ఆ పరమాత్మ తత్త్వ జ్ఞానప్రాప్తిద్వారా తొలగిపోవును. అప్పుడు, ఆ జ్ఞానము వారికి సచ్చిదానంద ఘన పరమాత్మను సూర్యుని వలె (సూర్య ప్రభవలె) దర్శింపజేయును. 5.16",

    "తద్రూపమును పొందిన మనోబుద్ధులు గలవారై , సచ్చిదానంద ఘన పరమాత్మ యందే నిరంతరము ఏకీభావంతో స్థితులై, తత్పరాయణులైన పురుషులు జ్ఞాన సాధనతో పాప రహితులై, పునరావృత్తి రహితమైన పరమగతిని పొందుదురు.\n(తద్రూపము అంటే - ఆ పరమాత్మయే ఆనందమయము అనీ, దానికంటే భిన్నమైనది మరొక వస్తువు లేదనీ ఎల్లప్పుడూ మననము చేస్తూ, ఆ పరమాత్మ యందే మనస్సు అభిన్న భావముతో నిశ్చలముగా ఉండటమే మనస్సు తద్రూపము పొందుట అగును) 5.17",

    "జ్ఞానులు విద్యావినయ సంపన్నుడైన బ్రాహ్మణుని యందును, గోవు, ఏనుగు, కుక్క మొదలగు వానియందును, చండాలుని యందును సమదృష్టినే కలిగి యుందురు. 5.18",

    "సర్వత్ర సమభావ స్థిత మనస్కులు ఈ జన్మ యందే సంపూర్ణ జగత్తును జయించిన వారగుదురు. అనగా ప్రాపంచిక విషయాతీత స్థితికి చేరుదురు. సచ్చిదానంద ఘన పరమాత్మ దోష రహితుడు, సముడు. సమభావ స్థిత మనస్కులయిన జ్ఞానులు ఆ పరమాత్మ యందు స్థితులు. కనుక, వారు త్రిగుణాతీతులు. జీవన్ముక్తులు. 5.19",

    "ప్రియలాభములకు పొంగిపోని వాడును, అప్రియములు ఎదురైనప్పుడు క్రుంగిపోని వాడును, స్థిరమైన బుధ్ధి గలవాడును, మోహవివశుడు కానివాడును అయిన బ్రహ్మ వేత్త సచ్చిదానంద ఘన పరబ్రహ్మ పరమాత్మ యందు సదా ఏకీభావ స్థితి యందుండును. 5.20",

    "ప్రాపంచిక విషయముల యందు అనాసక్తమైన అంతఃకరణము గల సాధకుడు ఆత్మ స్థిత ధ్యానజనితమైన సాతత్త్వికాత్మానందమును పొందును. పిదప అతడు సచ్చిదానంద ఘన పరబ్రహ్మ పరమాత్మ ధ్యానయోగము నందు అభిన్న భావ స్థితుడై అక్షయానందమును అనుభవించును. 5.21",

    "విషయ - ఇంద్రియ సుఖముల సంయోగము వలన ఉత్పన్నములగు భోగములన్నియును భోగ లాలసులకు సుఖములుగా భాసించినను, అవి నిస్సందేహముగా దుఃఖ హేతువులే. అట్టి భోగములు ఆది అంత్యములు గలవి. అనగా, అనిత్యములు. కావున, ఓ అర్జునా! వివేకి వాటి యందు ఆసక్తుడు కాడు 5.22",

    "ఈ శరీరమును విడువక ముందే అనగా, జీవించియుండగానే కామ క్రోధాదుల ఉద్వేగములను అదుపులోనుంచుకొనగల సాధకుడే నిజమైన సుఖి, యోగి. 5.23",

    "(సమిష్టి) అంతరాత్మ యందే సుఖించువాడును, ఆత్మ యందే రమించు వాడును,  ఆత్మజ్ఞానియైనవాడును అగు సాంఖ్యయోగి, సచ్చిదానంద ఘన పరబ్రహ్మ పరమాత్మ యందు ఏకీభావ స్థితుడై, బ్రహ్మనిర్వాణమును పొందును. 5.24",

    "పాపరహితులును, జ్ఞాన ప్రభావమున సమస్త సంశయముల నివృతిని సాధించిన వారును, సర్వప్రాణుల హితమును గోరువారును, నిశ్చల స్థితితో మనస్సును పరమాత్మ యందు లగ్నము చేసినవారును అగు బ్రహ్మ వేత్తలు బ్రహ్మనిర్వాణమును పొందుదురు. 5.25",

    "కామక్రోధ రహితులును, చిత్త వృత్తులనుజయించిన వారును అయి , పరబ్రహ్మ పరమాత్మ సాక్షాత్కారమును పొందిన జ్ఞానులకు అంతటను శాంత పరబ్రహ్మ పరమాత్మయే గోచరించును. 5.26",

    "బాహ్య విషయ భోగములను చింతన చేయక వాటిని పారద్రోలవలెను. దృష్టిని భ్రూమధ్యమునందు స్థిరముగా ఉంచవలెను. నాసిక యందు ప్రసరించుచున్న ప్రాణాపాన వాయువులను సమస్థితిలో నడుపవలెను. 5.27",

    "(27 వ శ్లోకములో చెప్పిన) ప్రక్రియల ప్రభావమున మనస్సు, బుధ్ధి, ఇంద్రియములు సాధకుని వశములోకి వచ్చును. ఇట్టి సాధన వలన మోక్ష పరాయణుడైన ముని ఇచ్ఛాభయ క్రోధ రహితుడై సదా ముక్తుడగును. 5.28",

    "భగవంతుడు యజ్ఞములకును, తపస్సులకును భోక్త. సమస్త లోకములకును, లోకేశ్వరులకును అధిపతి. సమస్త ప్రాణులకును ఆత్మీయుడు. అనగా అవ్యాజ దయాళువు. పరమ ప్రేమ స్వరూపుడు. ఈ భగవత్తత్త్వమును ఎఱిగిన భక్తునకు పరమ శాంతి లభించును. 5.29",

    "ఈ విధముగా ఓం తత్ సత్ అను భగవన్నామ ఉచ్చారణ పూర్వకమైన బ్రహ్మ విద్య మరియు యోగ శాస్త్ర మయమైన, శ్రీకృష్ణార్జునుల సంభాషణ లేక సంవాద రూపములో ఉన్న శ్రీమద్భగవద్గీతలోని  ఐదవ అధ్యాయమైన కర్మసన్న్యాసయోగము సమాప్తమైనది"
  ],
  "adhyaya-6" : [
    "శ్రీ భగవానుడు పలికెను - కర్మఫలమును ఆశ్రయింపక కర్తవ్య కర్మను ఆచరించువాడే నిజమైన సన్న్యాసి, నిజమైన యోగి. కాని కేవలము అగ్ని కార్యములను త్యజించినంత మాత్రమున  సన్న్యాసియుకాడు. అట్లే కేవలము క్రియలను త్యజించినంత మాత్రమున యోగియుకాడు. 6.1",

    "ఓ అర్జున !  సన్న్యాసము అని పిలువబడునదియే యోగము అని తెలిసికొనుము. ఏలనన సంకల్పత్యాగము చేయనివాడెవ్వడును యోగి కాలేడు. 6.2",

    "యోగారూఢ స్థితిని పొందగోరు  మననశీలుడైన పురుషునకు నిష్కామ కర్మాచరణము వలననే యోగప్రాప్తి కలుగును. యోగారూఢుడైన పురుషునకు సర్వసంకల్పరాహిత్యమే  మోక్షప్రాప్తికి మూలము. 6.3",

    "ఇంద్రియ భోగములయందును, కర్మలయందును ఆసక్తుడు గాక సర్వసంకల్పములను త్యజించిన పురుషుడు యోగారూఢుడని చెప్పబడును. 6.4",

    "మనుజులు ఈ సంసారసాగరమునుండి తమను తామే ఉద్ధరించుకొనవలెను. తమకు తామే అధోగతిపాలు కారాదు. ఏలనన లోకములో వాస్తవముగ తమకు తామే మిత్రులు. తమకు తామే శత్రువులు. 6.5",

    "మనస్సును ఇంద్రియములను, శరీరమును జయించిన జీవుడు తనకు తానే మిత్రుడు. అట్లు జయింపనివాడు తనకుతానే శత్రువు. అనగా జితేంద్రియునకు మనస్సు, ఇంద్రియములు, శరీరము భగవత్ప్రాప్తిసిద్ధికై మిత్రునివలె సహకరించును. అట్లుగాక జితేంద్రియుడు కానివానికి మనస్సు, ఇంద్రియములు, శరీరము శత్రువులవలె ప్రవర్తించి లక్ష్యసాధనకు అవరోధకములుగా నిలుచును. 6.6",

    "శీతోష్ణములు, సుఖదుఃఖములు, మానావమానములు మున్నగు ద్వంద్వములయందు అంతఃకరణవృత్తులు నిశ్చలముగా (చలింపక) ఉండి, స్వాధీనమైన ఆత్మగల పురుషుని విజ్ఞానమునందు సచ్చిదానందఘనపరమాత్మ   చక్కగా స్థితుడై యుండును. అనగా పరమాత్మ తప్ప అతని జ్ఞానమునందు అన్యమేదియును ఉండదు. 6.7",

    "పరమాత్మ  ప్రాప్తినందిన యోగియొక్క అంతఃకరణమునందు జ్ఞానవిజ్ఞానములు నిండియుండును. అతడు వికారరహితుడు, ఇంద్రియాదులను పూర్తిగా వశపఱచుకొనినవాడు. అతడు మట్టిని, రాతిని, బంగారమును సమానముగా చూచును. 6.8",

    "సుహృదులయందును, మిత్రులయందును, శత్రువులయందును, ఉదాసీనులయందును, మధ్యస్థులయందును, ద్వేషింపదగినవారియందును, బంధువులయందును, ధర్మాత్ములయందును, పాపులయందును సమబుద్ధి కలిగియుండువాడు మిక్కిలి శ్రేష్ఠుడు. 6.9",

    "శరీరేంద్రియమనస్సులను స్వాధీనపఱచుకొనిన వాడు, ఆశారహితుడు, భోగసామగ్రిని ప్రోగుచేయనివాడు అయిన యోగి ఒంటరిగా, నిర్జన (ఏకాంత) ప్రదేశమున కూర్చొని, ఆత్మను నిరంతరము పరమాత్మయందు లగ్నము చేయవలెను. 6.10",

    "పరిశుభ్రమైన ప్రదేశమున క్రమముగా ధర్భాసనమును, జింకచర్మమును, వస్త్రమును, ఒకదానిపై ఒకటి పఱచి, అంత ఎక్కువగాకాని, తక్కువగాకాని కాకుండ సమానమైన ఎత్తులో స్థిరమైన స్థానమును ఏర్పఱచుకొనవలెను. 6.11",

    "ఆ అసనము పై కూర్చొని, చిత్తేంద్రియ వ్యాపారములను వశము నందుంచుకొని, ఏకాగ్రత గల మనస్సుతో అంతఃకరణశుద్ధికై ధ్యానాయోగమును సాధన చేయవలెను. 6.12",

    "శరీరమును, మెడను, శిరస్సును  నిటారుగ నిశ్చలముగా స్థిరముగా నుంచి చూపులను ఏ దిక్కునకు పోనీయక తన నాసికాగ్ర భాగమునందే దృష్టిని నిలుపవలెను. 6.13",

    "ధ్యానయోగి ప్రశాంతాత్ముడై, భయరహితుడై, బ్రహ్మచర్యవ్రతమును పాటించుచు, మనోనిగ్రహముతో మత్పరాయణుడై నిశ్చలుడై యుండవలెను. 6.14",

    "మనోనిగ్రహశాలియైన యోగి నిరంతరము పరమేశ్వరుడనైన నా స్వరూపమునందే ఆత్మను ఈ విధముగా లగ్నమొనర్చి నా యందున్న పరమానందమునకు పరాకాష్ఠ రూపమైన శాంతిని పొందును. 6.15",

    "అర్జున ! అతిగా భుజించువానికిని, ఏ మాత్రము భుజింపనివానికిని, అతిగా నిద్రించువానికిని, ఎల్లప్పుడును (ఏ మాత్రము నిద్రింపక) మేల్కొనియుండువానికిని ఈ యోగసిద్ధి కలుగదు. 6.16",

    "ఆహార విహారాదులయందును, కర్మాచరణములయందును, జాగ్రత్స్వప్నాదులయందును యథాయోగ్యముగ  ప్రవర్తించు వానికి దుఃఖనాశకమగు ధ్యానయోగము సిద్ధించును. 6.17",

    "చిత్తమును పూర్తిగా  వశమునందుంచుకొని, దానిని పరమాత్మ యందే స్థిరముగా నిల్పినప్పుడు పురుషుడు సర్వభోగములయందును స్పృహారహితుడగును. అప్పుడతడు యోగయుక్తుడనబడును. 6.18",

    "వాయుప్రసారము లేనిచోట నిశ్చలముగానుండు దీపమువలె యోగికి వశమైయున్న చిత్తము పరమాత్మ ధ్యానమున నిమగ్నమై యున్నప్పుడు నిర్వికారముగా నిశ్చలముగా నుండును. 6.19",

    "ధ్యానయోగసాధనచే నిగ్రహింపబడిన చిత్తము ఉపరతిని పొంది, పరమాత్మను ధ్యానించుట ద్వారా పవిత్రమైన సూక్ష్మ బుద్ధితో, ఆ భగవానుని సాక్షాత్కరింపజేసికొని, యోగి ఆ సచ్చిదానందఘనపరమాత్మ యందే సంతుష్టుడగుచున్నాడు. 6.20",

    "బ్రహ్మానందానుభవము ఇంద్రియాతీతమైనది. పవిత్రమైన సూక్ష్మ బుద్ధి ద్వారా మాత్రమే. గ్రాహ్యమైనది. ఆ బ్రహ్మానందమును అనుభవించుచు దానియందే స్థితుడైయున్న యోగి పరమాత్మస్వరూపమునుండి ఏ మాత్రమూ విచలితుడు కానేకాడు. 6.21",

    "పరమాత్మప్రాప్తిరూపలాభమును పొందినవాడు (అనగా భగవత్సాక్షాత్కారమును పొందినవాడు) మఱే యితర లాభమును గూడ దానికంటె అధికమైన దానినిగా తలంపడు. బ్రహ్మానందానుభవస్థితిలోనున్న యోగిని ఎట్టి బలవద్దుఃఖములును చలింపజేయజాలవు. 6.22",

    "దుఃఖరూపసంసారబంధముల నుండి విముక్తిని కలిగించు ఈ స్థితిని (భగవత్సాక్షాత్కార రూపస్థితిని) యోగము అని తెలియవలెను. అట్టి యోగమును దృఢమైన, ఉత్సాహపూరితమైన అనిర్విణ్ణ (విసుగులేని) చిత్తముతో నిశ్చయముగా సాధన చేయవలెను. 6.23",

    "సంకల్పముల వలన కలిగిన కోరికలన్నింటిని నిశ్శేషముగా త్యజించి, ఇంద్రియ సముదాయములను  అన్ని విధములుగ మనస్సుతో పూర్తిగా నిగ్రహింపవలెను. 6.24",

    "క్రమక్రమముగా సాధనచేయుచు ఉపరతిని పొందవలెను. ధైర్యముతో బుద్ధిబలముతో మనస్సును పరమాత్మ యందు స్థిరమొనర్చి పరమాత్మను తప్ప మఱి ఏ ఇతర విషయమును ఏ మాత్రమూ చింతన చేయరాదు. 6.25",

    "సహజముగా నిలకడలేని చంచలమైన మనస్సు ప్రాపంచిక విషయములయందు విశృంఖలముగా పరిభ్రమించుచుండును. అట్టి మనస్సును ఆయా విషయముల నుండి మఱల్చి, దానిని పరమాత్మయందే స్థిరముగా నిల్పవలెను. 6.26",

    "ప్రశాంతమైనమనస్సు కలవాడును, పాపరహితుడును, రజోగుణము శాంతమైనవాడును, అనగా ప్రాపంచిక కార్యములయందు ఆసక్తి తొలగినవాడును, సచ్చిదానందఘనపరమాత్మ యందు ఏకీభావమును పొందినవాడును అగు యోగి బ్రహ్మానందమును పొందును. 6.27",

    "పాపరహితుడైన ఆ యోగి పుర్వోక్తరీతిగా నిరంతరము ఆత్మను పరమాత్మ యందే లగ్నమొనర్చుచు, పరబ్రహ్మపరమాత్మ ప్రాప్తిరూపమైన అపరిమితానందమును హాయిగా అనుభవించును. 6.28",

    "సర్వవ్యాప్తమైన అనంత చైతన్యమునందు ఏకీభావ స్థితిరూప యోగయుక్తమైన ఆత్మగలవాడును, అంతటను అన్నింటిని సమభావముతో చూచువాడును అగు యోగి తనయాత్మను సర్వప్రాణులయందు స్థితమై యున్నట్లుగను, ప్రాణులన్నింటిని ఆత్మయందు కల్పితములుగను భావించును (చూచును). 6.29",

    "సకల ప్రాణులయందును ఆత్మ రూపముననున్న నన్ను (వాసుదేవుని) చూచు పురుషునకు, అట్లే ప్రాణులన్నింటికి  నా యందు అంతర్గతములుగా నున్నట్లు చూచువానికి నేను అదృశ్యుడను కాను. అతడును నాకు అదృశ్యుడు కాడు. 6.30",

    "భగవంతునియందు ఏకీభావస్థితుడైన పురుషుడు సర్వభూతముల యందును ఆత్మ రూపముననున్న సచ్చిదానందఘన వాసుదేవుడనైయున్న నన్ను భజించును. అట్టి యోగి సర్వథా సర్వవ్యవహారములయందు ప్రవర్తించుచున్నను నాయందే ప్రవర్తించుచుండును. 6.31",

    "అర్జున ! సర్వప్రాణులను తనవలె (తనతో) సమానముగా చూచువాడును, సుఖమును గాని, దుఃఖమును గాని సమముగా (సమానముగ) చూచువాడును (ఇతరుల సుఖదుఃఖములు లను తన సుఖదుఃఖములుగా భావుంచువాడును) అయిన యోగి పరమ శ్రేష్ఠుడు. 6.32",

    "అర్జునుడు పలికెను - ఓ మధుసూదన ! సమభావమును గూర్చి నీవు చెప్పిన ఈ యోగము యొక్క స్థిరస్థితిని మనశ్చాంచల్య కారణమున తెలిసికొన లేకున్నాను. 6.33",

    "ఓ కృష్ణ ! ఈ మనస్సు మిక్కిలి చంచలమైనది. ప్రమథనశీలమైనది (బాగుగా మథించు స్వభావము గలది). దృఢమైనది. మిక్కిలి బలీయమైనది. కనుక దానిని నిగ్రహించుట గాలిని ఆపుటవలె మిక్కిలి దుష్కరమని భావింతును. 6.34",

    "శ్రీ భగవానుడు పలికెను - హే మహాబాహో! నిస్సందేహముగా మనస్సు చంచలమైనదే. దానిని వశపఱచుకొనుట మిక్కిలి కష్టము. కాని కౌంతేయ ! అభ్యాసవైరాగ్యములద్వారా దానిని వశపఱచుకొనుట సాధ్యమే. 6.35",

    "మనస్సును వశపఱచుకొనని పురుషునకు యోగసిద్ధి కలుగుట కష్టము. మనస్సు వశమునందున్న ప్రయత్నశీలుడైన పురుషుడు సాధన ద్వారా సహజముగా యోగసిద్ధిని పొందుట సాధ్యమే అని నా అభప్రాయము. 6.36",

    "అర్జునుడు పలికెను - హే కృష్ణ! యోగమునందు శ్రద్ధాదరములతో యోగసాధన చేయుచు మనస్సు వశమునందుండని కారణమున అవసాన దశలో మనస్సు చలించి, యోగసిద్ధిని  (భాగవత్సాక్షాత్కారమును) పొందకయే మరణించిన సాధకుని గతియేమగును ? 6.37",

    "హే మహాబాహో! అతడు (యోగభ్రష్టుడు) భగవత్ప్రాప్తి మార్గమునుండి జారినవాడై, ఆశ్రయరహితుడై (ఆలంబనము లేనివాడై), ఉభయభ్రష్టుడై, ఛిన్నాభిన్నమైన మేఘమువలె అధోగతి పాలు కాడు గదా! 6.38",

    "ఓ కృష్ణ! ఈ నా సందేహమును పూర్తిగా నివృత్తిచేయుట నీకే చెల్లును. ఏలనన ఈ సందేహమును తొలగించుట నీకు తప్ప మరఱెవ్వరికిని శక్యము కాదు. 6.39",

    "శ్రీ భగవానుడు పలికెను - ఓ పార్థ! అట్టి (ఆ) పురుషుడు ఈ లోకమున గాని, పరలోకమున గాని అధోగతిపాలుగాడు. ఏలనన, నాయనా! ఆత్మోద్ధరణమునకు అనగా భగవత్ప్రాప్తికై కర్తవ్యకర్మలను ఆచరించువాడెవ్వడును దుర్గతిపాలుగాడు. 6.40",

    "యోగభ్రష్టుడు పుణ్యాత్ములు పొందు లోకములను అనగా స్వర్గాది ఉత్తమ లోకములను పొంది, ఆయా లోకములలో పెక్కు సంవత్సరములు గడిపి, పిదప పవిత్రులైన సంపన్నుల గృహమున జన్మించును. 6.41",

    "అథవా విరాగియైన పురుషుడు ఆ పుణ్య (ఊర్ధ్వ) లోకములకు పోకుండగనే జ్ఞానులైన యోగుల కుటుంబములోనే జన్మించును. కాని లోకమునందు ఇట్టి జన్మ  ప్రాప్తించుట మిక్కిలి దుర్లభము. 6.42",

    "అచట (యోగి కుటుంబమున పుట్టిన పిదప) పూర్వ దేహమున సాధించిన బుధ్ధి సంయోగమును అనగా సమబుద్ధిరూపయోగ సంస్కారములను అతడు సులభముగనే పొందును. ఓ కురునందన! ఆ బుద్ధిసంయోగ ప్రభావమున అతడు మఱల పరమాత్మ ప్రాప్తి సిద్ధించుటకై మునుపటికంటెను అధికముగా సాధన చేయును. 6.43",

    "శ్రీమంతుల ఇంటిలో జన్మించిన యోగభ్రష్టుడు పరాధీనుడైనను పుర్వసాధన ప్రభావమున నిస్సందేహముగ భగవంతుని వైపు ఆకర్షితుడగును. మరియు సమబుద్ధిరూప యోగజిజ్ఞాసువు గూడ వేదములలో తెల్పబడిన సకామకర్మల ఫలమును అధిగమించును. 6.44",

    "కాని, ప్రయత్నపూర్వకముగ యోగసాధన చేయు యోగి అనేక జన్మల సంస్కార ప్రభావమున ఈ జన్మయందే సిద్ధిని పొంది, సంపూర్ణముగా పాపరహితుడై, తత్ క్షణమే పరమపదమును పొందును. 6.45",

    "యోగి తాపసుల కంటెను శ్రేష్ఠుడు. శాస్త్ర జ్ఞానుల కంటెను శ్రేష్ఠుడు. సకామకర్మలను ఆచరించువారి కంటెను శ్రేష్ఠుడని భావించబడును. కావున ఓ అర్జున! నీవు యోగివి కమ్ము. 6.46",

    "యోగులందఱిలోను శ్రద్ధాళువై, అంతరాత్మను నాయందే లగ్నమొనర్చి, అనగా భక్తి విశ్వాసములతో నిశ్చలమైన, దృఢమైన అనన్యభావముతో నా యందే స్థిరమైయున్న మనోబుద్ధిరూప - అంతఃకరణమును గలిగి, నిరంతరము నన్నే భజించువాడు నాకు పరమ శ్రేష్ఠుడు. 6.47",

    "ఈ విధముగా ఓం తత్ సత్ అను భగవన్నామ ఉచ్చారణ పూర్వకమైన బ్రహ్మ విద్య మరియు యోగ శాస్త్ర మయమైన, శ్రీకృష్ణార్జునుల సంభాషణ లేక సంవాద రూపములో ఉన్న శ్రీమద్భగవద్గీతలోని ఆరవ అధ్యాయమైన ఆత్మసంయమ యోగము సమాప్తమైనది"
  ],
  "adhyaya-7" : [
    "శ్రీ భగవానుడు పలికెను - ఓ పార్థా! అనన్యభక్తితో నాయందే ఆసక్తమైన మనస్సు కలిగినవాడవై యుండుము.  అనన్య భావముతో మత్పరాయణుడవై, యోగమునందు నిమగ్నుడవగుము. అట్టి నీవు సంపూర్ణవిభూతిబల - ఐశ్వర్యాదిగుణయుక్తుడను, సర్వప్రాణులకును ఆత్మస్వరూపుడను ఐన నన్ను సమగ్రముగా తెలిసికొను విధమును వినుము. 7.1",

    "నేను నీకు విజ్ఞానసహితముగా తత్త్వజ్ఞానమును సంపూర్ణముగా తెలిపెదను. దీనిని ఎఱిగిన పిమ్మట ఈ జగత్తులో తెలిసికొనవలసినది ఏదియు మిగులదు.\nజ్ఞానము - అనగా భగవంతుని నిర్గుణ నిరాకార ప్రభావ మహాత్మ్య రహస్యములతో గూడిన యథార్థతత్త్వజ్ఞానము. విజ్ఞానము - అనగా సగుణ నిరాకార దివ్య సాకార తత్త్వముయొక్క లీలారహస్యగుణమహత్త్వ ప్రభావసహితమైన యథార్థజ్ఞానము. 7.2",

    "వేలకొలది మనుష్యులలో ఎవడో ఒకడు మాత్రమే నన్నుగూర్చి తెలిసికొనుటకు ప్రయత్నించును. అట్లు ప్రయత్నించిన వారిలోగూడ ఒకానొకడు మాత్రమే మత్పరాయణుడై నా తత్త్వమును అనగా నా యథార్థస్వరూపమును ఎఱుంగును. 7.3",

    "ఓ మహాబాహూ! భూమి, నీరు, అగ్ని, వాయువు, ఆకాశము, మనస్సు, బుద్ధి, అహంకారము అని నా ప్రకృతి ఎనిమిది విధములుగా కలదు. 7.4",

    "ఎనిమిది భేదములు గల ఈ ప్రకృతిని 'అపరా' లేక 'జడ' ప్రకృతి అనియందురు. ఇదిగాక ఈ సంపూర్ణజగత్తును ధరించునట్టి మఱియొక 'ప్రకృతి' కలదు. అదియే నా జీవరూప 'పరాప్రకృతి' లేక చేతనప్రకృతి అని తెలిసికొనుము. 7.5",

    "ఓ అర్జునా! సమస్తప్రాణులును ఈ రెండువిధములైన ప్రకృతులనుండియే ఉత్పన్నములగుచున్నవి, ఈ జగత్తుయొక్క ప్రభావమూ (పుట్టుకయూ) ప్రలయమూ (లీనమగుటయూ) నావలననే జరుగుచున్నవి. అనగా ఈ సంపూర్ణ జగత్తునకు నేనే మూలకారణము. 7.6",

    "ఓ ధనంజయా! నాకంటెను పరమకారణమైనది ఏదియును లేదు. ఈ జగత్తునందలి వస్తువులన్నియును సూత్రమున సూత్రమణులవలె నాయందే కూర్చబడియున్నవి. 7.7",

    "ఓ అర్జునా! జలములో రసతన్మాత్రను నేనే. సూర్యచంద్రులలో కాంతిని నేనే. వేదములన్నింటిలోను ఓంకారమును, ఆకాశమునందు శబ్దమును, పురుషులయందు పౌరుషమును నేనే. 7.8",

    "పృథ్వియందు పవిత్రగంధతన్మాత్రను, అగ్నియందు తేజస్సును, సమస్త ప్రాణులలో జీవశక్తిని (చైతన్యమును) తాపసులలో తపస్సును నేనే. 7.9",

    "ఓ పార్థా! సమస్త భూతములకును నన్ను సనాతన (శాశ్వత) మైన బీజముగా ఎఱుంగుము. ప్రజ్ఞావంతులలో ప్రజ్ఞను, తేజోవంతులలో తేజస్సును నేనే. 7.10",

    "ఓ భరతశ్రేష్ఠా! బలవంతులలో కామరాగరహితమైన బలమును నేను, భూతములన్నింటియందును (సమస్తప్రాణులయందును) ధర్మమునకు విరుద్ధముగాని (శాస్త్రసమ్మతమైన) కామమును నేనే. 7.11",

    "సాత్త్విక, రాజస, తామస భావములన్నియును నా నుండియే కలుగుచున్నవని తెలిసికొనుము. కాని, యథార్థముగా వాటిలో నేను గాని, నాలో అవిగాని లేవు. అనగా నేను త్రిగుణాతీతుడను. 7.12",

    "ఈ జగత్తు అనగా ప్రాణిసముదాయము అంతయును గుణములయొక్క కార్యరూపములైన సాత్త్విక, రాజస, తామసములు అను త్రివిధ భావములచే మోహితమగుచున్నది. కనుక త్రిగుణములకు అతీతుడను, శాశ్వతుడను ఐన నన్ను ఆ ప్రాణులు తెలిసికొనలేకున్నవి. 7.13",

    "నా మాయ త్రిగుణాత్మకమైనది. అలౌకికమైనది. ఇది అధిగమించుటకు సాధ్యము కానిది. కాని కేవలము నిరంతరము నన్నే భజించువారు ఈ మాయను అధిగమించి, సంసారసముద్రము నుండి బయటపడగలరు. 7.14",

    "మాయలో చిక్కుపడుటవలన విపరీత జ్ఞానమునకు లోనైనవారును, ఆసుర ప్రవృత్తిగలవారును, నరాధములును, మూఢులును, దుష్కర్మలను ఆచరించువారును నన్ను భజింపరు. 7.15",

    "ఓ భరతశ్రేష్ఠా! అర్జునా! శుభకర్మలను ఆచరించుచు సుఖసంపదలను కోరుకొనువారు (అర్థార్థులు), శారీరిక మానసిక సంతాపములకు గురియైన ఆర్తులు, ఐహికవిషయములపై ఆసక్తిని వీడి పరమాత్మ తత్త్వజ్ఞానమును పొందుటకు ఇచ్ఛగలవారు (జిజ్ఞాసువులు), పరమాత్మప్రాప్తినందిన జ్ఞానులు అను చతుర్విధ భక్తులు నన్ను భజింతురు. 7.16",

    "ఈ చతుర్విధభక్తులలో నిరంతరము నాయందే ఏకీభావస్థితుడై, అనన్య భక్తియుతుడైన జ్ఞాని అత్యుత్తముడు. ఏలనన వాస్తవముగ నన్ను (నాతత్వమును) తెలిసికొనిన జ్ఞానికి నేను మిక్కిలి ఇష్టుడను. అతడును నాకు మిక్కిలి ఇష్టుడు. 7.17",

    "ఈ చతుర్విధభక్తులందఱును ఊదారులే. (అనగా భగవంతునియందు విశ్వాసమునుంచి, ఎల్లప్పుడును ఆతనినే సేవించుచు ఉండువారు.)  కాని జ్ఞానియైన వాడు నా స్వరూపమే. ఇది నా అభిప్రాయము. ఏలనన అట్టి భక్తుడు తనమనస్సును బుద్ధిని నాయందే స్థిరముగా ఉంచి నన్నే పరమప్రాప్యునిగా భావించును. ఈవిధముగా అతడు నాయందే స్థితుడు. 7.18",

    "అనేకజన్మలపిదప జ్ఞానియైనవాడు (భగవత్తత్త్వమును ఎఱిగినవాడు) సర్వమూ వాసుదేవమయమే యని భావించి, నన్ను శరణుపొందును. అట్టి మహాత్ముడు లభించుట అరుదు. 7.19",

    "నానావిధములైన భోగవాంఛలలో కూరుకొనిపోయినవారిజ్ఞానము హరింపబడును. వారు తమతమస్వభావములకు అనుగుణముగా వారివారి (ఆయా) నియమములను బట్టి ఇతర దేవతలను ఆరాధించుచుందురు. 7.20",

    "సకామభక్తుడు ఏయేదేవతాస్వరూపములను భక్తిశ్రద్ధలతో పూజింప నిశ్చయించుకొనునో, ఆ భక్తునకు ఆయాదేవతలయందే భక్తిశ్రద్ధలను స్థిరముగా కుదురుకొనునట్లు చేయుదును. 7.21",

    "అట్టి సకామభక్తుడు తగిన భక్తిశ్రద్ధలతో ఆ దేవతనే ఆరాధించును. తత్పలితముగ నా అనుగ్రహము వలననే ఆ దేవతద్వారా తను కోరుకొనిన భోగములను అతడు తప్పక పొందగలడు. 7.22",

    "కాని ఈ సకామభక్తులు (అల్పబుద్ధులు) పొందెడి ఫలములు గూడ నశ్వరములు. అన్యదేవతలను పూజించువారు ఆ దేవతలనే చేరుదురు. నా భక్తులు ఏ విధముగా ఆరాధించినను (వారి వాంఛలనేగాక) చివరకు నన్నే పొందుదురు. 7.23",

    "నేను శాశ్వతుడను. సర్వోత్తముడను, ఇంద్రియములకును, మనస్సునకును గోచరింపనివాడను. నా పరమభావమును బుద్ధిహీనులు గ్రహింపక, ఇట్టి సచ్చిదానందఘనపరమాత్ముడనైన నన్ను సాధారణ మనుష్యునిగా అనగా జననమరణచక్రములోబడి పరిభ్రమించు వానినిగా తలంచెదరు. 7.24",

    "నా యోగమాయచే ఆవరింపబడియున్నందున నేను అందరికిని గోచరింపను. కనుక అజ్ఞానులు నన్ను జన్మరహితునిగా, శాశ్వతునిగా, పరమేశ్వరునిగా తెలిసికొనలేరు. (అనగా నన్ను జనన మరణములకు లోనగువానినిగా తలంతురు). 7.25",

    "ఓ అర్జునా! గతకాలమునకు చెందినట్టివియు, వర్తమాన (ప్రస్తుత) కాలము నందున్నట్టివియు ఐన చరాచర ప్రాణులన్నింటిని నేను ఎఱుగుదును. అంతేకాదు రాబోవుకాలమున ప్రభవించు (జన్మించు) ప్రాణులనుగూర్చియు నేను ఎఱుగుదును. కాని నాయందు భక్తిశ్రద్ధలుగలవారుతప్ప వేరెవ్వరును నన్ను ఎఱుగజాలరు. 7.26",

    "భరతవంశీ! ఓ అర్జునా! జగత్తునందు ప్రాణులన్నియును రాగద్వేషములవలన కలిగిన సుఖ దుఃఖాదిద్వంద్వములప్రభావమున అంతులేని మోహములో పడిపోవుచున్నవి. 7.27",

    "కాని నిష్కామభావముతో పుణ్యకర్మలను ఆచరించు పురుషులపాపములు రూపుమాసిపోవును (నశించును). అట్టివారు రాగద్వేషజనితములైన సుఖదుఃఖాది రూపమోహములనుండి విముక్తులయ్యెదరు. దృఢనిశ్చయముగల్గిన అట్టి భక్తులే అన్నివిధముల నన్ను భజింతురు. 7.28",

    "నన్ను శరణుపొంది జరామరణవిముక్తికై ప్రయత్నించు పురుషులు ఆ పరబ్రహ్మను, సమస్త-అధ్యాత్మమును, సంపూర్ణ కర్మను తెలిసికొందురు. 7.29",

    "అధిభూత. అధిదైవ, అధియజ్ఞములతోపాటు అందరికినీ ఆత్మరూపుడనైన నన్ను అంత్యకాలమునందైనను తెలిసికొనువారు నిశ్చలబుద్ధితో నన్నే చేరుదురు. 7.30",

    "ఈ విధముగా ఓం తత్ సత్ అను భగవన్నామ ఉచ్చారణ పూర్వకమైన బ్రహ్మ విద్య మరియు యోగ శాస్త్ర మయమైన, శ్రీకృష్ణార్జునుల సంభాషణ లేక సంవాద రూపములో ఉన్న శ్రీమద్భగవద్గీతలోని ఏడవ అధ్యాయమైన జ్ఞానవిజ్ఞానయోగము సమాప్తమైనది"
  ],
  "adhyaya-8" : [
    "అర్జునుడు పలికెను - ఓ పురుషోత్తమా! బ్రహ్మ అనగానేమి? అధ్యాత్మము అనగానేమి? కర్మ అనగానేమి? అధిభూతము అని దేనికి పేరు? అధిదైవము అని దేనిని అందురు? 8.1",

    "ఓ మధుసూదనా! 'అధియజ్ఞము' అనగానేమి? ఆ అధియజ్ఞము ఈ శరీరమునందు ఎట్లుండును? అంత్యకాలమున మనస్సును పరమాత్మయందు లగ్నముచేసిన యోగులు నిన్నెట్లు తెలిసికొనగలరు? 8.2",

    "శ్రీ భగవానుడు పలికెను - బ్రహ్మమనగా సర్వశ్రేష్ఠుడు, శాశ్వతుడు. అధ్యాత్మము అనగా స్వస్వరూపము అనగా జీవాత్మ, పరమాత్మయందు లీనమైన సకల భూతములను బహిర్గతమొనర్చి, వాటి ఉత్పత్తి - అభ్యుదయములకు కారణమైన చేష్టను అనగా సృష్ట్యాదికర్మలను కర్మయందురు. 8.3",

    "ఉత్పత్తి వినాశశీలములైన పదార్థములు అన్నియును అధిభూతములు అనబడును. హిరణ్మయుడైన పురుషుడు అధిదైవము. దేహధారులలో శ్రేష్ఠుడవైన ఓ అర్జునా! ఈ 8.4",

    "అంత్యకాలమునందైనను నన్నే స్మరించుచు దేహత్యాగమును చేసినవాడు నన్నే (నాస్వరూపమునే) పొందును. ఇందేమాత్రము గూడ సందేహము లేదు. 8.5",

    "కౌంతేయా! మనుష్యుడు అవసానదశయందు ఏఏభావములను స్మరించుచు దేహత్యాగము చేయునో అతడు మరుజన్మలో ఆయా స్వరూపములనే పొందును. ఏలనన సర్వదా అతడు దానినే స్మరించుచుండెను. 8.6",

    "కావున ఓ అర్జునా! నీవు సర్వదా నన్నే స్మరించుచుండుము. యుద్ధమును (కర్తవ్యకర్మను) కూడా చేయుము. ఈ విధముగా నీ మనోబుద్ధులను నాయందే నిల్పియున్నచో నిజముగా (నిస్సందేహముగా) నన్నే పొందెదవు. 8.7",

    "మనస్సును ఎటూ పోనీయక (వేరే ధ్యాస ఏ మాత్రమూ లేకుండ) నిరంతరము పరమేశ్వరుని ధ్యానరూప యోగమునే సాధనచేయు మనుష్యుడు దివ్యపురుషుడైన పరమాత్మనే చేరును. 8.8",

    "సర్వజ్ఞుడును, సనాతనుడును, అందరిని శాసించువాడును, అణువుకంటెను సూక్ష్మమైనవాడును, అందరిని ధరించి పోషించువాడును, అచింత్యరూపుడును, సూర్యుని వలె నిత్యచేతన ప్రకాశరూపుడును, అజ్ఞానాంధకారమును పారద్రోలువాడును ఐన సచ్చిదానంద ఘనపరమేశ్వరుని స్మరించువాడగు...... 8.9",

    "అట్టి పరమభక్తుడు అంత్యకాలమునందు యోగబలము చేత భ్రుకుటీ మధ్యమున ప్రాణములను స్థిరముగా నిల్పి, నిశ్చలమైన మనస్సుతో స్మరించుచు దివ్యస్వరూపుడును, పరమపురుషుడును ఐన ప్రమాత్మనే చేరును. 8.10",

    "వేదవిదులైన విద్వాంసులు ఆ సచ్చిదానందఘనపరమాత్మను శాశ్వతుడు (అక్షరుడు) అని ప్రస్తుతింతురు. ఆసక్తిరహితులై యత్నశీలురైన సన్న్యాసులు ఆ పరమపదమునందే ప్రవేశింతురు. ఆ పరమపదమును కోరియే బ్రహ్మచారులు బ్రహ్మచర్య వ్రతమును ఆచరింతురు. అట్టి పరమపదమునుగూర్చి సంక్షిప్తముగా నేను నీకు వివరింతును. 8.11",

    "సర్వేంద్రియములను నిగ్రహించి, మనస్సును హృదయమునందే స్థిరముగా నిలిపి, అట్లు వశమైన మనస్సుద్వారా ప్రాణములను మూర్ధస్థానమునందు (సహస్రారమునందు) స్థిరమొనర్చి, పరమాత్మధ్యానమునందే నిమగ్నుడై (ఉన్నవాడు),...... 8.12",

    "..... అక్షర బ్రహ్మస్వరూపమైన ఓంకారమును ఉచ్చరించుచు, ఆ ఓంకారమునకు అర్థస్వరూపుడను, నిర్గుణబ్రహ్మను ఐన నన్ను చింతించుచు దేహత్యాగ మొనర్చువాడు పరమగతిని (మోక్షమును) పొందును. 8.13",

    "పార్థా! నిత్యము నిరంతరము అనన్యభావముతో చిత్తమును నాయందే నిలిపి, పురుషోత్తముడనైన నన్ను స్మరించుచు, సతతము మత్పరాయణుడైన యోగికి నేను సులభుడను. అనగా అతనికి సహజముగానే నేను లభింతును. 8.14",

    "పరమసిద్ధిని పొందిన మహాత్ములు నన్ను చేరిన పిదప దుఃఖములకు నిలయమైన, క్షణభంగురమైన పునర్జన్మను పొందరు. 8.15",

    "అర్జునా! బ్రహ్మలోకపర్యంతము ఉన్న సమస్తలోకములును పునరావృత్తములు. కౌంతేయా! (ఈలోకములన్నియును కాలమునకు పరిమితమైనవి. కాని నేను కాలతీతుడను) కనుక నన్ను చేరినవారికి పునర్జన్మ యుండదు. 8.16",

    "వేయి చతుర్యుగముల కాలము బ్రహ్మకు ఒక 'పగలు' అనియు, అంతేకాలము ఆ బ్రహ్మకు ఒక రాత్రియనియు తెలిసిన యోగులు కాలతత్వమును నిజముగా ఎఱిగినవారు. 8.17",

    "చరాచరప్రాణులన్నియును బ్రహ్మయొక్క పగటి కాలము ప్రారంభము కాగానే అవ్యక్తమునుండి అనగా బ్రహ్మయొక్క సూక్ష్మశరీరమునుండి ఉత్పన్నములగును. (ప్రకటితములగును). మఱల బ్రహ్మయొక్క రాత్రికాలము ప్రారంభసమయమున అవి అదే అవ్యక్తమునందు లీనమగును. 8.18",

    "పార్థా! ఈభూత(ప్రాణి) సముదాయమే ప్రకృతివశమున మాటిమాటికిని ఉత్పన్నమగుచుండును. రాత్రి ప్రారంభకాలమున లీనమగుచుండును. పగటి ప్రారంభ కాలమున ఉత్పన్నమగుచుండును. 8.19",

    "ఆ అవ్యక్తముకంటెను పరమైన విలక్షణమైన సనాతన (శాశ్వత) మైన అవ్యక్తభావమే ఆ పరమపదము. ప్రాణులన్నియు నశించినను ఆ పరమ పురుషుడు మాత్రము నశింపడు (నిత్యుడే). 8.20",

    "ఈ అవ్యక్తమునే అక్షరము అనియు అందురు. ఇదియే పరమగతి మరియు నా పరమధామము. ఈ సనాతన - అవ్యక్తమును అనగా నా పరంధామమును చేరినవారు మఱల తిరిగిరారు. 8.21",

    "ఓ పార్థా! సమస్త భూతములు (ప్రాణులు) ఆపరమాత్మయందే అంతర్గతములై యున్నవి. ఆ సచ్చిదానంద ఘనపరమాత్మచేతనే ఈ జగత్తంతయు వ్యాప్తమై (పూర్ణమై) ఉన్నది. అట్టి సనాతన అవ్యక్త పరమ పురుషుడు అనన్యభక్తి ద్వారా మాత్రమే లభ్యుడగును. 8.22",

    "ఓ భరతశ్రేష్ఠా! ఏ కాలమునందు దేహత్యాగమును చేసిన యోగులు తిరిగిరాని గతిని చేరుదురో, మఱియు ఏకాలమునందు దేహత్యాగము చేసినవారు తిరిగివచ్చు గతిని పొందుదురో అట్టి కాలములను, అనగా రెండు మార్గములను తెలిపెదను. 8.23",

    "బ్రహ్మవేత్తలైన యోగులు జ్యోతిర్మయమార్గముద్వారా బ్రహ్మపదప్రాప్తి నందుదురు. ఈ జ్యోతిర్మయ మార్గమునకు అధిదేవత అగ్ని. దేహత్యాగము చేసిన ఆ యోగులను క్రమముగా దిన(పగలు)శుక్లపక్ష ఉత్తరాయణ - అభిమాన దేవతలు కొనిపోయి, పరమపదమును చేర్చుదురు. 8.24",

    "అట్లే సకామకర్మయోగులు ధూమ్రమార్గముద్వారా స్వర్గాదిలోకములను చేరుదురు. దేహత్యాగము చేసిన ఈ సకామకర్మయోగులను క్రమముగా రాత్రి, కృష్ణపక్ష, దక్షిణాయన -అభిమానదేవతలు కొనిపోయి, స్వర్గాదిలోకములను చేర్చుదురు. వారు అచట చాంద్రమసజ్యోతిని పొంది, అనగా తమశుభకర్మ ఫలములను అనుభవించి, తిరిగి భూలోకమును చేరుదురు. 8.25",

    "ఈ రెండు మార్గములకును శుక్ల, కృష్ణమార్గములనియు లేక దేవయాన పితృయాన మార్గములనియు వ్యవహారప్రసిద్ధి గలదు. ఇవి సనాతనములు. దేవయాన మార్గమున వెళ్ళువారు పరమగతిని పొంది తిరిగిరారు. పితృయానమార్గమున వెళ్ళువారు తిరిగివచ్చి జననమరణచక్రములో పడుదురు. 8.26",

    "ఓ పార్థా! ఈ విధముగా ఈ రెండు మార్గములతత్త్వములను తెలిసికొన్నయోగి మోహితుడు కాడు. కావున అన్నికాలముల యందును సమత్వబుద్ధిరూపయోగ యుక్తుడవు కమ్ము. అనగా నిరంతరము నన్నే పొందుటకు ప్రయత్నింపుము. 8.27",

    "ఈ తత్త్వరహస్యమును ఎఱిగిన యోగి వేదపఠనమువలనను, యజ్ఞదాన తపశ్చర్యాదులవలనను కలుగు పుణ్యఫలమును త్రోసిరాజని నిస్సందేహముగా సనాతనపరమపదమును చేరును. 8.28",

    "ఈ విధముగా ఓం తత్ సత్ అను భగవన్నామ ఉచ్చారణ పూర్వకమైన బ్రహ్మ విద్య మరియు యోగ శాస్త్ర మయమైన, శ్రీకృష్ణార్జునుల సంభాషణ లేక సంవాద రూపములో ఉన్న శ్రీమద్భగవద్గీతలోని ఎనిమిదవ అధ్యాయమైన అక్షరబ్రహ్మయోగము సమాప్తమైనది"
  ],
  "adhyaya-9" : [
    "శ్రీ భగవానుడు పలికెను - ఓ అర్జునా! నీవు దోషదృష్టిలేని భక్తుడవు. కనుక నీకు పరమగోప్యమైన విజ్ఞాన సహిత జ్ఞానమును మఱల విశిదముగ చెప్పుచున్నాను. దీనిని తెలిసికొని నీవు ఈ దుఃఖరూపసంసారమునుండి ముక్తుడవు కాగలవు. 9.1",

    "ఈ విజ్ఞానసహితజ్ఞానము (నిర్గుణ సగుణ పరమాత్మతత్త్వజ్ఞానము) అన్ని విద్యలకును తలమానికము. సమస్త గోప్యవిషయములకును శిరోభూషణము, అతి పవిత్రము. ఉత్తమోత్తమము. ప్రత్యక్షఫలదాయకము. ధర్మయుక్తము, సాధన చేయుటకు మిక్కిలి సుగమము, శాశ్వతము. 9.2",

    "ఓ పరంతపా! ఈ ధర్మమార్గమునందు విశ్వాసములేని పురుషులు నన్ను పొందజాలరు. కనుక వారు మృత్యురూపసంసారచక్రమున పరిభ్రమించుచుందురు. 9.3",

    "నిరాకారపరబ్రహ్మనైన నాచేతనే ఈ జగత్తంతయును జలముతో మంచువలె పరిపూర్ణమైయున్నది. ప్రాణులన్నియును నా సంకల్పమును ఆధారముగా కలిగి నాయందే అంతర్గతములైయున్నవి. కాని వాస్తవముగా నేను వాటియందు స్థితుడనుకాను. 9.4",

    "ఈ ప్రాణులన్నియును నాలో స్థిరముగా లేవు. ఈశ్వరీయమైన నా యోగశక్తిని చూడుము. ఈ భూతములన్నింటిని స్రృష్టించునదియు, పోషించునదియు నేనేయైనను యథార్థముగా నా ఆత్మ వాటియందు స్థితమై యుండదు. 9.5",

    "ఆకాశమునుండి ఉత్పన్నమై సర్వత్ర సంచరించుచున్న విస్తృతమైన వాయువు సర్వదా ఆకాశమునందే స్థితమైయుండును. అట్లే నా సంకల్పము ద్వారా ఉత్పన్నమైన భూతములు (ప్రాణులు) అన్నియును నాయందే స్థితమై యుండును అని ఎఱుంగుము. 9.6",

    "ఓ కౌంతేయా! కల్పాంతమునందు భూతములన్నియును నా ప్రకృతినే చేరును. అనగా ప్రకృతిలో లీనమగును. కల్పాదియందు నేను మఱల వాటిని సృజించుచుందును. 9.7",

    "తమతమ స్వభావవశమున పరతంత్రమైయున్న భూతసముదాయమును నాప్రకృతినాశ్రయించి మాటిమాటికి వాటి కర్మానుసారము సృజించుచున్నాను. 9.8",

    "ఓ అర్జునా! ఆ సృష్ట్యాది కర్మలయందు ఆసక్తిరహితుడనై, ఊదాసీనునివలెనున్న నన్ను ఆ కర్మలు బంధింపవు. 9.9",

    "ఓ అర్జునా! అధిష్ఠాతనైన నా అధ్యక్షతన ప్రకృతి ఈ చరాచరజగత్తును సృష్టించుచున్నది. ఈ కారణమువలననే సంసారచక్రము పరిభ్రమించుచున్నది. 9.10",

    "నా పరమభావమును ఎరఱుగని మూఢులు సర్వప్రాణులకును మహేశ్వరుడను (ప్రభువును) ఐన నన్ను లోకకల్యాణమునకై అవతారములను ఎత్తిన కారణమున సామాన్యమానవునిగా భావించి, తక్కువగా ఊహింతురు. 9.11",

    "వ్యర్థములైన ఆశలచే, కర్మలచే, విపరీత జ్ఞానముచే నిక్షిప్తములైన మనస్సులుగల అజ్ఞానులు రాక్షసీ-ఆసురీ - మోహినీ స్వభావములను ఆశ్రయింతురు. 9.12",

    "కాని ఓ పార్థా! దైవీప్రకృతిని ఆశ్రయించిన మహాత్ములైతే నన్ను సకల ప్రాణులకు మూలకారణముగను, అవ్యయునిగను (అక్షరస్వరూపునిగను) తెలిసికొని, నిశ్చలమనస్కులై నిరంతరము నన్నే భజింతురు. 9.13",

    "ఆ దృఢవ్రతులైన భక్తులు నా నామగుణములను నిరంతరము వాద్యబృందముతో కీర్తింతురు. నన్ను చేరుటకు యత్నింతురు. పదేపదే నాకు ప్రణమిల్లుదురు. సర్వదా నా ధ్యానమునందే నిమగ్నులయ్యెదరు. అనన్య భక్తితో నన్ను ఉపాసింతురు. 9.14",

    "మరఱికొందరు జ్ఞానయోగులు నిర్గుణనిరాకారబ్రహ్మనైన నన్ను జ్ఞానయజ్ఞనముద్వారా అభేదభావముతో ఉపాసించుచుందురు. మఱికొందరు అనంతరూపములతో ఒప్పెడి నా విరాట్ స్వరూపమును పృథక్ భావముతో ఆరాధించుచుందురు. 9.15",

    "నేనే క్రతువును. నేనే యజ్ఞమును, స్వధయును నేనే, ఓషధులను నేనే, నేనే మంత్రమును, నేనే ఘృతమును, నేనే అగ్నిని, హోమరూపక్రియయును నేనే. 9.16",

    "ఈ సమస్త జగత్తునకు ధాతను అనగా ధరించువాడను, అట్లే కర్మలఫలములను ఇచ్చువాడను నేనే. తల్లియును, తండ్రియును, తాతయును నేనే. తెలిసికొనదగిన వాడను నేనే. పవిత్రుడను, ఓంకారమును నేనే. ఋక్సామయజుర్వేదములను నేనే. 9.17",

    "పరమగతియైన పరమధామమును, జగత్తును భరించి, పోషించువాడను నేనే. అందరికిని స్వామిని, అందరి శుభాశుభములను చూచువాడను నేనే, అందఱికిని నివాసస్థానమును, శరణుపొందదగినవాడను నేనే, ప్రత్యుపకారమును ఆశింపక హితమొనర్చువాడను, అందఱి ఉత్పత్తిప్రళయములకు హేతువును, వారి స్థితికి ఆధారమును, నిధానమును, శాశ్వతకారణమును నేనే. 9.18",

    "ఓ అర్జునా! సూర్యరూపమున నేనే తపించుచున్నాను. సముద్రములనుండి నీటిని గ్రహించి, వర్షరూపమున మఱల వదలెదను. అమృతమును, మృత్యువును గూడ నేనే. సత్తును (శాశ్వతమైన ఆత్మను) అసత్తును (నశ్వరమైన సమస్త వస్తుజాలమును) గూడ నేనే. 9.19",

    "ఆ విశాలస్వర్గమునందు భోగములను అనుభవించి, పుణ్యములు అయిపోగానే (క్షీణింపగానే) మఱల మర్త్యలోకమున ప్రవేశింతురు. ఈ విధముగా స్వర్గప్రాప్తి సాధనములైన వేదత్రయవిహిత సకామకర్మలను ఆశ్రయించువారు, భోగములను ఆసించుచూ స్వర్గమర్త్యలోకముల మధ్య రాకపోకలు సాగించుచుందురు. అనగా పుణ్యప్రభావముచే స్వర్గమునకు పోవుదురు. పుణ్యము క్షీణింపగనే మర్త్యలోకమునకు వచ్చెదరు. 9.20",

    "ఆ విశాలస్వర్గమునందు భోగములను అనుభవించి, పుణ్యములు అయిపోగానే (క్షీణింపగానే) మఱల మర్త్యలోకమున ప్రవేశింతురు. ఈ విధముగా స్వర్గప్రాప్తి సాధనములైన వేదత్రయవిహిత సకామకర్మలను ఆశ్రయించువారు, భోగములను ఆసించుచూ స్వర్గమర్త్యలోకముల మధ్య రాకపోకలు సాగించుచుందురు. అనగా పుణ్యప్రభావముచే స్వర్గమునకు పోవుదురు. పుణ్యము క్షీణింపగనే మర్త్యలోకమునకు వచ్చెదరు. 9.21",

    "పరమేశ్వరుడనైన నన్ను నిరంతరము అనన్య భక్తితో చింతనచేయుచు, నిష్కామ భావముతో సేవించువారి యోగక్షేమములను నేనే వహించుచుందును. (అప్రాప్త వస్తుప్రాప్తిని 'యోగము' అనియందురు. ప్రాప్తించిన వస్తురక్షణమును 'క్షేమము' అని యందురు.) 9.22",

    "ఓ అర్జునా! శ్రద్ధాన్వితులైన సకామ భక్తులు ఇతరదేవతలను పూజించినప్పటికిని వారు నన్ను పూజించినట్లే. కాని వారిపూజలు అవిధిపూర్వకములైనవి. అనగా అజ్ఞానముతో కూడినవి. 9.23",

    "ఏలనన సకల యజ్ఞములకును భోక్తను, స్వామిని గూడ నేనే. వారు నా పరమేశ్వర తత్త్వమును ఎఱుంగరు. కావున పతనమగుదురు. అనగా పునర్జన్మను పొందుదురు. 9.24",

    "దేవతలను పూజించువారు దేవలోకములను చేరుదురు. పితరులను సేవించువారు పితృలోకములకు వెళ్ళుదురు. భూతప్రేతములను అర్చించువారు భూతప్రేత రూపములను పొందుదురు. నన్ను ఆరాధించు భక్తులు నన్నే పొందుదురు. అట్టి నా భక్తులకు పునర్జన్మ ఉండదు. 9.25",

    "నిర్మలబుద్ధితో, నిష్కామభావముతో పరమభక్తునిచే సమర్పింపబడిన పత్రమునుగాని, పుష్పమునుగాని, ఫలమునుగాని, జలమునుగాని, నేను ప్రత్యక్షముగా (స్వయముగా) ప్రీతితో ఆరగింతును. 9.26",

    "ఓ కౌంతేయా! నీవు ఆచరించు కర్మను, భుజించెడి ఆహారమును, హోమముచేయు హవ్యమును, అర్పించు దానమును, ఆచరించు తపస్సును నాకే సమర్పింపుము. 9.27",

    "ఈ విధముగా సన్న్యాసయోగమునందు స్థిరచిత్తుడవై. అనగా సమస్తకర్మలను భగవంతుడనైన నాకే సమర్పించి, శుభాశుభఫలరూపకర్మబంధములనుండి ముక్తుడవయ్యెదవు. పిదప నన్నే చేరగలవు. 9.28",

    "నేను సకలభూతముల (ప్రాణుల) యందును సమభావముతో వ్యాపించియుందును. నాకు అప్రియుడు గాని ప్రియుడు గాని ఎవ్వడును లేడు. కాని నన్ను భక్తితో భజించువారు నాయందేయుందురు. నేనును వారియందు ప్రత్యక్షముగా ఉందును. 9.29",

    "మిక్కిలి దురాచారుడైనను అనన్యభక్తితో నన్ను భజించినచో ఆతనిని సత్పురుషునిగానే భావింపదగును. ఏలనన యథార్థముగా అతడు నిశ్చయబుద్ధి గలవాడు. అనగా పరమాత్ముని సేవించుటతో సమానమైనది మరఱియొకటి ఏదియును లేదని గట్టిగా నిశ్చయించుకొనినవాడు. 9.30",

    "కౌంతేయా! అతడు శీఘ్రముగా ధర్మాత్ముడగును. శాశ్వతమైన పరమశాంతిని పొందును. 'నా భక్తుడెన్నడును నష్టమునకు గురికాడు' అను విషయమును నిశ్చయముగా ఎఱుంగుము. 9.31",

    "ఓ అర్జునా! స్త్రీ, వైశ్య, శూద్రులును, అట్లే చండాలాదిపాపయోనిజులును నన్ను శరణుపొంది పరమగతినే పొందుదురు. 9.32",

    "ఇక పుణ్యాత్ములైన బ్రాహ్మణులును, రాజర్షులును, భక్తులును నన్ను శరణుపొందినచో వారు పరమపదమునుచేరుదురని చెప్పవలసిన పనియేలేదు. ఈ మానవశరీరము క్షణభంగురము. సుఖరహితము. ఐనను దుర్లభము. కనుక దీనిని పొంది - నిరంతరము నన్నే భజింపుము. 9.33",

    "నా యందే నీ మనస్సును లగ్నము చేయుము. నా భక్తుడవు కమ్ము. నన్నే పూజింపుము. నాకు నమస్కరింపుము. ఈ విధముగా ఆత్మను నాయందే నిలిపి, మత్పరాయణుడవైనచో నీవు నన్నే పొందగలవు. 9.34",

    "ఈ విధముగా ఓం తత్ సత్ అను భగవన్నామ ఉచ్చారణ పూర్వకమైన బ్రహ్మ విద్య మరియు యోగ శాస్త్ర మయమైన, శ్రీకృష్ణార్జునుల సంభాషణ లేక సంవాద రూపములో ఉన్న శ్రీమద్భగవద్గీతలోని తొమ్మిదవ అధ్యాయమైన రాజవిద్యారాజగుహ్యయోగము సమాప్తమైనది"
  ],
  "adhyaya-10" : [
    "శ్రీ భగవానుడు పలికెను - హే మహాబాహో! నీవు నా మీద ప్రేమ గలవాడవు. కావున నీ మేలుగోరి నేను మఱల చెప్పుచున్న వచనములను వినుము. అవి మిక్కిలి గోప్యములు. మహిమాన్వితములు. 10.1",

    "నా ఉత్పత్తిని అనగా నా లీలావతారవిశేషములను దేవతలుగాని, మహర్షులుగాని తెలిసికొనజాలరు. ఏలనన నేను అన్నివిధములుగా ఆ దేవతలకును, ఈ మహర్షులకును మూలకారణమైనవాడను. 10.2",

    "నన్ను యథార్థముగా జన్మరహితునిగను, అనాదియైన వానినిగను, సకలలోక మహేశ్వరునిగను తెలిసికొనువాడు మానవులలో జ్ఞాని. అట్టివాడు సర్వపాపములనుండియు విముక్తుడగును. 10.3",

    "నిశ్చయాత్మకశక్తి, యథార్థజ్ఞానము, మోహరాహిత్యము, క్షమ, సత్యము, దమము (ఇంద్రియనిగ్రహము), శమము (మనోనిగ్రహము), సుఖదుఃఖములు, ఉత్పత్తి ప్రళయములు, భయము, అభయము,........... 10.4",

    ".....అహింస, సమత, సంతోషము, తపస్సు, దానము, కీర్తి, అపకీర్తి, అనునవి ప్రాణులవివిధభావములు. ఇవి యన్నియును నావలననే కలుగును. 10.5",

    "సప్తమహర్షులను, వారికంటెను పూర్వులైన సనకాదిమహామునులు నలుగురును, స్వాయంభువాది చతుర్ధశ మనువులును మొదలగు వీరందఱును నా భక్తులే. అందరూ నాయెడ సద్భావముగలవారే. వీరు నా సంకల్పమువలననే జన్మించిరి. ఈ జగత్తునందలి సమస్త ప్రాణులును వీరిప్రజలే. 10.6",

    "ఈ నా పరమైశ్వర్యరూపవిభూతిని, యోగశక్తియొక్క తత్త్వమును తెలిసికొన్నవాడు నిశ్చలభక్తియుక్తుడగును. ఇందు ఏమాత్రమూ సందేహమే లేదు. 10.7",

    "ఈ సమస్తజగత్తుయొక్క ఉత్పత్తికి వాసుదేవుడనైన నేనే మూలకారణము. నావలననే ఈ జగత్తంతయు నడుచుచున్నది. ఈ విషయమును బాగుగా ఎఱింగిన జ్ఞానులైన భక్తులు భక్తిశ్రద్ధలతో నిరంతరము నన్నే సేవింతురు. 10.8",

    "నా భక్తులు నాయందే తమ మనస్సులను లగ్నమొనర్తురు. తమ ప్రాణములను, తమ కర్మలన్నింటిని, తమ సర్వస్వమును నాకే అంకితమొనర్తురు. వారు పరస్పరచర్చలద్వారా నా మహత్త్వమును గూర్చి ఒకరికొకరు తెలుపుకొనుచు, కథలు కథలుగా చెప్పికొనుచు, నిరంతరము సంతుష్టులగుచుందురు. మఱియు వారు సతతము నాయందే రమించుచుందురు. 10.9",

    "అట్లు నిరంతరము ధ్యానాదులద్వారా నాయందే లగ్నమనస్కులై భక్తిశ్రద్ధలతో నన్నే భజించువారికి నేను బుద్ధియోగమును అనగా తత్త్వజ్ఞానరూపయోగమును ప్రసాదించెదను. దానిద్వారా వారు నన్నే పొందుదురు. 10.10",

    "ఓ అర్జునా! వారి యంతఃకరణములయందు స్థితుడనైయున్న నేను వారిని అనుగ్రహించుటకై తేజోమయమైన తత్త్వజ్ఞానరూప దీపమును (జ్యోతిని) వెలిగించి, వారి అజ్ఞానాంధకారమును పారద్రోలెదను. 10.11",

    "అర్జునుడు పలికెను - నీవు పరబ్రహ్మవు. పరంధాముడవు. పరమపవిత్రుడవు. (మహర్షులు) నిన్ను సనాతనుడవనియు, దివ్యపురుషుడవనియు, దేవదేవుడవనియు, జన్మరహితుడవనియు, సర్వవ్యాపివనియు (ప్రస్తుతింతురు). 10.12",

    "దేవర్షి నారదుడు, అసితుడు, దేవలుడు, వ్యాసమహర్షియు, (నిన్నే) నుతింతురు. నీవును నాకు ఆ విధముగనే తెలుపుచున్నావు. 10.13",

    "ఓ కేశవా! నీవు చెప్పునది అంతయును సత్యమే. హే భగవాన్! నీ లీలామయస్వరూపమును దేవతలుగాని, దానవులుగాని తెలిసికొనజాలరు. 10.14",

    "ఓ జగదుత్పత్తికారకా! ఓ సర్వభూతేశా! ఓ దేవాదిదేవా! ఓ జగన్నాథా! ఓ పురుషోత్తమా! నీ తత్త్వమును నీవే స్వయముగా ఎఱుంగుదువు. 10.15",

    "సమస్తలోకములయందును నీ దివ్యవిభూతులద్వారా వ్యాపించి, స్థితుడవై యున్నావు. మహామహితములైన ఆ దివ్యవిభూతులను సంపూర్ణముగా తెల్పుటకు నీవే సమర్థుడవు. 10.16",

    "ఓ యోగీశ్వరా! నిరంతరము చింతనచేయుచూ నేను నిన్ను ఏవిధముగా తెలిసికొనగలను? హే భగవన్! ఏయే భావములతో నాద్వారా చింతన చేయదగినవాడవు? నిన్ను నేను ఎట్లు చింతన చేయవలెను? 10.17",

    "ఓ జనార్దనా! నీ యోగశక్తిని గూర్చియు, నీ విభూతి వైభవములను గఱించియు విస్తృతముగా ఇంకను తెలుపుము. ఏలనన నీ అమృతమయవచనములను ఎంతగా విన్నను తనివియే తీరదు. 10.18",

    "శ్రీ భగవానుడు పలికెను - ఓ అర్జునా! నా దివ్యవిభూతుల విస్తృతికి (వ్యాప్తికి) అంతమే లేదు. వాటిలో ప్రధానమైనవాటిని కొన్నింటిని మాత్రము నీకు తెలుపుచున్నాను. 10.19",

    "ఓ అర్జునా! సమస్తప్రాణులహృదయములయందున్న ఆత్మను నేనే. సకల భూతముల (ప్రాణుల) ఆదియు, మధ్యస్థితియు, అంతము నేనే. (ప్రాణులయొక్క సృష్టిస్థితిలయములకు కారణము నేనే). 10.20",

    "అదితియొక్క ద్వాదశపుత్రులైన ఆదిత్యులలో విష్ణువును నేను. జ్యోతిర్మయ స్వరూపులలో నేను సూర్యుడను. 49 మంది వాయుదేవతలలోని 'తేజము'ను నేను.నక్షత్రాధిపతియైన చంద్రుడను నేను. 10.21",

    "వేదములలో నేను సామవేదమును. దేవతలలో ఇంద్రుడను నేను. ఇంద్రియములలో నేను మనస్సును. ప్రాణులలో చైతన్యమును (ప్రాణశక్తిని) నేను. 10.22",

    "ఏకాదశ రుద్రులలో శంకరుడను నేను. యక్ష రాక్షసులలో ధనాధిపతియైన కుబేరుడనునేను. అష్ట వసువులలో అగ్నిని నేను. పర్వతములలో 'సుమేరు' పర్వతమునునేను. 10.23",

    "పార్థా! పురోహితులలో ముఖ్యుడైన బృహస్పతినే నేనే. సేనాపతులలో కుమారస్వామిని నేను. జలశయములలో నేను సాగరుడను. 10.24",

    "మహర్షులలో భృగువును నేను. శబ్దములలో ఏకాక్షరమును అనగా ఓంకారమును నేను. యజ్ఞములయందు జపయజ్ఞమును నేను. స్థావరములలో హిమాలయమును నేను. 10.25",

    "వృక్షములలో నేను అశ్వత్థమును (రావిచెట్టును). దేవర్షులలో నారదుడను నేను. గంధర్వులలో నేను చిత్రరథుడను. సిద్ధులలో నేను కపిలమునిని. 10.26",

    "అశ్వములలో పాలసముద్రమునుండి అమృతముతో పుట్టిన ఉచ్చైఃశ్రవమును నేను. భద్రగజములలో ఐరావతమును నేను. మనుష్యులలో ప్రభువును నేను. 10.27",

    "ఆయుధములలో వజ్రాయుధమును నేను. పాడిఆవులలో కామధేనువును నేను. శాస్త్రవిహితరీతిలో సంతానోత్పత్తికి కారణమైన మన్మథుడను నేను. సర్పములలో వాసుకిని నేను. 10.28",

    "నాగజాతివారిలో ఆదిశేషుడ(అనంతుడ)ను నేను. జలచరములకు అధిపతియైన వరుణుడను నేను. పితరులలో అర్యముడను (పితృగణప్రభువును) నేను. శాసకులలో యమధర్మరాజును నేను. 10.29",

    "దైత్యులలో నేను ప్రహ్లాదుడను. గణించువారిలో (గణకులలో) నేను కాలమును. మృగములలో మృగరాజు ఐన సింహమును నేను. పక్షులలో పక్షిరాజైన గరుత్మంతుడను నేనే. 10.30",

    "పవిత్రమొనర్చు వానిలో వాయువును నేను, శస్త్రధారులలో శ్రీరామచంద్రుడను నేను. మత్స్యములలో మొసలిని నేను. నదులలో గంగానదిని నేను. 10.31",

    "ఓ అర్జునా! సృష్టికి ఆదిమధ్యాంతములు నేను. (సృష్టి స్థితిలయ కారకుడను నేనే). విద్యలలో అధ్యాత్మవిద్యను అనగా బ్రహ్మవిద్యను నేను. పరస్పరవాదవివాదములలో తత్త్వనిర్ణయమునకై చేయు 'వాదము'ను నేను. 10.32",

    "అక్షరములలో 'అ' కారమును నేను. సమాసములలో 'ద్వంద్వ' సమాసమును నేను. అక్షయకాలము అనగా మహాకాలమును నేను. అట్లే విశ్వతోముఖుడైన విరాట్ పురుషుడను నేను. అందరిని ధరించువాడను, పోషించువాడను నేను. 10.33",

    "అన్నిప్రాణములను హరించు మృత్యువును నేనే. సమస్తప్రాణుల ఉత్పత్తి హేతువును గూడ నేనే. స్త్రీలలో కీర్తి, శ్రీ, వాక్కు, స్మృతి, మేధా, ధృతి, క్షమా అను వారందఱును నేనే. 10.34",

    "గానముచేయుటకు అనువైన శ్రుతులలో బృహత్సామమును నేను. ఛందస్సులలో 'గాయత్రీఛందస్సు'ను నేను. మాసములలో మార్గశీర్షమును, ఋతువులలో వసంతఋతువును నేనే. 10.35",

    "వంచకులలో జూదమును నేనే. ప్రభావశాలురలోని ప్రభావమును నేను. విజేతలలో విజయమును నేను. నిశ్చయాత్మకులలో నిశ్చయమును, సాత్త్విక పురుషులలో సాత్త్వికభావమును నేనే. 10.36",

    "వృష్ణివంశజులలో వాసుదేవుడను నేను. పాండవులలో ధనంజయుడను నేను. అనగా నీవే నేను. మునులలో వేదవ్యాసుడను నేను. కవులలో శుక్రాచార్యుడను నేనే. 10.37",

    "శిక్షించువారిలో దండమును అనగా దమనశక్తిని నేనే. జయేచ్ఛకలవారినీతిని నేనే. గోప్యవిషయరక్షణమున 'మౌనము'ను నేను. జ్ఞానులయొక్క తత్త్వజ్ఞానమును నేనే. 10.38",

    "ఓ అర్జునా! సర్వప్రాణులఉత్పత్తికి కారణమైన బీజమును నేనే. ఏలనన నేనులేని చరాచరప్రాణియేదియును లేదు. 10.39",

    "ఓ పరంతపా! నా దివ్యవిభూతులకు అంతమే లేదు. నా విభూతులవిస్తృతిని గూర్చి నేను నీకు చాలా సంక్షిప్తముగా వివరించితిని. 10.40",

    "విభూతియుక్తము అనగా ఐశ్వర్యయుక్తము, కాంతియుక్తము, శక్తియుక్తము ఐన వస్తువేదైనను నా తేజస్సుయొక్క అంశమునుండియే కలిగినదిగా తెలిసికొనుము. 10.41",

    "ఓ అర్జునా! ఇంతకంటెను విపులముగా తెలిసికొని ప్రయోజనమేమి? ఈ సంపూర్ణజగత్తును కేవలము నా యోగశక్తియొక్క ఒక్క అంశతోడనే ధరించు చున్నాను. 10.42",

    "ఈ విధముగా ఓం తత్ సత్ అను భగవన్నామ ఉచ్చారణ పూర్వకమైన బ్రహ్మ విద్య మరియు యోగ శాస్త్ర మయమైన, శ్రీకృష్ణార్జునుల సంభాషణ లేక సంవాద రూపములో ఉన్న శ్రీమద్భగవద్గీతలోని పదవ అధ్యాయమైన విభూతియోగము సమాప్తమైనది"
  ],
  "adhyaya-11" : [
    "అర్జునుడు పలికెను - ఓ కృష్ణా! నన్ను అనుగ్రహింపదలచి పరమగోప్యమైన ఆధ్యాత్మిక విషయములను ఉపదేశించితివి. దానివలన నా అజ్ఞానము (మోహము) తొలగిపోయినది. 11.1",

    "ఓ కమలాక్షా! ఓ కృష్ణా! సమస్త ప్రాణుల ఉత్పత్తి ప్రళయములనుగూర్చి సవిస్తరముగా వింటిని. అట్లే శాశ్వతమైన నీ మహిమలను గూర్చియు వింటిని. 11.2",

    "ఓ పరమేశ్వరా! నీవు చెప్పినదంతయు సత్యమే. అందు సందేహమునకు తావులేదు. కాని ఓ పురుషోత్తమా! జ్ఞాన, ఐశ్వర్య, శక్తి, బల, వీర్య, తేజోమహితమైన నీ షడ్గుణైశ్వర్య సంపన్న రూపమును ప్రత్యక్షముగా చూచుటకు కుతూహలపడుచున్నాను. 11.3",

    "ఓ యోగేశ్వరా! ఓ ప్రభూ! నీ దివ్య రూపమును చూచుటకు నన్ను అర్హునిగా నీవు భావించినచో శాశ్వతమైన నీ దివ్యస్వరూపమును నాకు చూపింపుము. 11.4",

    "శ్రీభగవానుడు పలికెను - ఓ అర్జునా! అసంఖ్యాకములైన బహువిధములైన, పెక్కువర్ణములు, ఆకృతులు గల నా అలౌకికరూపములను చూడము. 11.5",

    "ఓ అర్జునా! ద్వాదశాదిత్యులను, అష్టవసువులను, ఏకాదశరుద్రులను, అశ్వినీ కుమారులను, మరుద్గణములను నాయందు చూడము. అంతేగాక ఇంకను మునుపెన్నడును చూచి యెరుగని అపూర్వమైన ఆశ్చర్యకరములైన రూపములను చూడుము. 11.6",

    "ఓ అర్జునా! నా ఈ రూపమునందు ఒకేచోట స్థితమైయున్న సమస్తచరాచర జగత్తును చూడము. అంతేగాక ఇంకను నీవు చూడదలచుకొనిన వాటినన్నింటినీ చూడము. 11.7",

    "కాని ఈ ప్రాకృత(చర్మ) చక్షువులతో నా ఈ రూపమును నీవు నిజముగా (నిస్సందేహముగా) చూడజాలవు. కనుక నీకు దివ్య(అలౌకిక) దృష్టిని ప్రసాదించుచున్నాను. ఆ దివ్యదృష్టితో నా ఈశ్వరీయ(దివ్య) యోగశక్తిని చూడుము. 11.8",

    "సంజయుడు పలికెను - ఓరాజా! మహాయోగేశ్వరుడును, పాపములను హరించువాడును ఐన భగవానుడు ఈ విధముగా పలికి, అనంతరము షడ్గుణైశ్వర్య సంపన్నమైన తన పరమదివ్య స్వరూపమును అర్జునునకు చూపెను. 11.9",

    "అర్జునుడు చూచిన ఆ పరమేశ్వరుని విరాట్ (విశ్వ) రూపమునందలి ముఖములు అనంతములు-నేత్రములు అసంఖ్యాకములు. అందలి దృశ్యములు అద్భుతములైనవి. ఆ రూపము అనేకదివ్యాభరణశోభితము. ఆ పరమేశ్వరుడు పెక్కు దివ్యాస్త్రములను చెపట్టియుండెను. 11.10",

    "దివ్యములగు మాలలను, వస్త్రములను ధరించియుండెను. ఆ దివ్యశరీరమునుండి దివ్యచందనపరిమళములు దశదిశల గుబాళించుచుండెను. ఆ రూపము సర్వాశ్చర్యకరము. అనంతము, సర్వతోముఖము. 11.11",

    "ఆకాశమున వేలకొలది సూర్యులు ఒక్కుమ్మడిగా ఉదయించినచోవచ్చు కాంతి పుంజములును ఆ విరాట్ - రూపతేజస్సులకు సాటిరావు. 11.12",

    "ఆ సమయమున అర్జునుడు ఆ దేవాదిదేవుని శరీరమునందు అసంఖ్యాకములైన వివిధబ్రహ్మాండములు వేర్వేరుగా ఒకేచోట కేంద్రీకృతమైయున్నట్లు చూచెను. 11.13",

    "అద్భుతమైన ఆ పరమాత్ముని విశ్వరూపమును జూచి, అర్జునుడు ఆశ్చర్యచకితుడై పులకితగాత్రుడయ్యెను. తేజోమయమైన ఈ విశ్వ(విరాట్) రూపమునకు భక్తిశ్రద్ధలతో సాష్టాంగప్రణామములాచరించెను. తదనంతరము చేతులు జోడించి యిట్లు పలికెను. 11.14",

    "అర్జునుడు పలికెను - ఓ దేవాదిదేవా! నీ విరాట్-రూపమునందు సకల దేవతలను, నానావిధప్రాణికోటిని, కమలాసనుడైన బ్రహ్మను, మహాదేవుడైన శంకరుని, సమస్త ఋషులను దివ్యసర్పములను చూచుచున్నాను. 11.15",

    "ఓ విశ్వేశ్వరా! విశ్వరూపా! నీ బాహువులు, ఉదరములు, ముఖములు, నేత్రములు అసంఖ్యాకములు. నీ అనంతరూపము సర్వతోముఖముగ విలసిల్లుుచున్నది. నీవు ఆదిమధ్యాంతరహితుడవు. మహత్త్వపూర్ణమైన నీ దివ్యరూపమునకు ఆది మధ్యాంతములను తెలిసికొనలేకున్నాను. 11.16",

    "హే విష్ణో! కిరీటమును, గదను, చక్రమును ధరించి, అంతటను తేజఃపుంజములను విరజిమ్ముుచున్న నిన్ను దర్శించుచున్నాను. ప్రజ్వలితాగ్నివలెను, జ్యోతిర్మయుడైన సూర్యునివలెను వెలుగొందుచున్న నీ అప్రమేయరూపము దుర్నిరీక్ష్యమై యున్నది. 11.17",

    "పరమ - అక్షరస్వరూపుడవైన పరబ్రహ్మపరమాత్మవు నీవే, కనుక అందరికిని తెలిసికొనదగిన వాడవు. ఈ జగత్తునకు నీవే పరమాశ్రయుడవు. సనాతన ధర్మరక్షకుడవు. నీవు అవ్యయుడవు. సనాతనపురుషుడవు అని నావిశ్వాసము. 11.18",

    "నీవు ఆదిమధ్యాంతరహితుడవు. అపరిమితశక్తిశాలివి. అసంఖ్యాకములైన భుజములు గలవాడవు. సూర్యచంద్రులు నీ నేత్రములు. అగ్నివలె నీ ముఖము ప్రజ్వరిల్లుచున్నది. నీ తేజస్సుతో ఈ జగత్తును సంతప్తమొనర్చుచున్నావు. అట్టి నిన్ను నేను చూచుచున్నాను. 11.19",

    "ఓ మహాత్మా! దివినుండి భువివఱకుగల అంతరిక్షమునందంతటను అన్ని దిశలను నీవే పరిపూర్ణుడవై యున్నావు. అద్భుతమైన నీ భయంకరరూపమును చూచి, ముల్లోకములును గడగడలాడుచున్నవి. 11.20",

    "ఇదిగో, ఆ దేవతలెల్లరును నీలో ప్రవేశించుచున్నారు. కొందఱు భయపడినవారై అంజలి ఘటించి, నీ నామగుణములను కీర్తించుచున్నారు. మహర్షులును, సిద్ధులును స్వస్తివచనములతోడను, ఉత్తమోత్తమ స్తోత్రములతోడను నిన్ను ప్రస్తుతించుచున్నారు. 11.21",

    "ఏకాదశరుద్రులును, ద్వాదశాదిత్యులును, అష్టవసువులును, సాధ్యులును, విశ్వేదేవతలును, అశ్వినీకుమారులును, మరుద్గణములును, పితరులును అట్లే గంధర్వయక్షాసురసిద్ధ సముదాయములును సంభ్రమాశ్చర్యములతో నిన్నే దర్శించుచున్నారు. 11.22",

    "హే మహాబాహో! అసంఖ్యాకములైన వక్త్రములను, నేత్రములను, చేతులను, ఊరువు(తొడ)లను, పాదములను, ఉదరములను, కోరలను కలిగిన మిక్కిలి భయంకరమైన నీ రూపమును చూచి, అందఱును భయకంపితులగుచున్నారు.  నేను కూడ భయముతో వణకిపోవుచున్నాను. 11.23",

    "ఏలనన హే విష్ణో! నీ రూపము అంతరిక్షమును తాకుచున్నది.  అది అనేకవర్ణములతో దేదీప్యమానమై వెలుగుచున్నది. కాంతులను విరజిమ్ము విశాలనేత్రములతో, విస్తరించినముఖములతో అద్భుతముగా ఒప్పుచున్నది.  అట్టి నీ రూపమును చూచిన నా మనస్సు తత్తరపడుచున్నది.  అందువలన నా ధైర్యము సడలినది.  శాంతి దూరమైనది. 11.24",

    "ఓ జగన్నివాసా! కరాళదంష్ట్రలతో (భయంకరములైన కోరలతో) ఒప్పుచున్న నీ ముఖములు ప్రళయాగ్నిజ్వాలల వలె భీతిగొల్పుచున్నవి.  వాటిని చూచిన నాకు దిక్కుతోచకున్నది.  నెమ్మది(శాంతి) శూన్యమైనది.  ఓ దేవేశా! ప్రసన్నుడవు కమ్ము. 11.25",

    "ఇదిగో! (ఇచ్చట చేరియున్న) ఈ ధృతరాష్ట్రపుత్రులు (దుర్యోధనాదులు), అందఱును, ఇతర రాజన్యులతోసహా, భీష్మపితామహుడు, ద్రోణుడు, సూత పుత్రుడైన కర్ణుడు, అట్లే మన పక్షమునందలి ప్రధానయోధులు అందఱును నీలో (ప్రవేశించుచున్నారు). 11.26",

    "(అట్లే మన, ప్రతిపక్షమునందలి ప్రధానయోధులు అందఱును) భయంకరములైన కోరలతోగూడిన నీ ముఖములయందు అతివేగముగా పరుగులుదీయుచు ప్రవేశించుచున్నారు.  కొందఱి తలలు కోరల మధ్యబడి నుగ్గునుగ్గైపోవుచుండగా వారు దంతములలో చిక్కుకొని వ్రేలాడుచున్నారు. 11.27",

    "అనేకములైన నదీనదములప్రవాహములన్నియును సహజముగాసముద్రమునకు అభిముఖముగా ప్రవహించుచు అందు ప్రవేశించుచున్నట్లు, ఈ శ్రేష్ఠులైన సమరయోధులు (నరలోకవీరులు) కూడ జ్వలించుచున్న నీ ముఖములయందు ప్రవేశించుచున్నారు. 11.28",

    "మిడుతలన్నియు మోహవశమున బాగుగా మండుచున్న అగ్నివైపు అతివేగముగా పరుగెత్తి, తమ నాశనముకొఱకు అందు ప్రవేశించి, నశించునట్లు ఈ వీరులందఱును తమనాశమునకై అతివేగముగా పరుగెత్తి, నీ వక్త్రములయందు ప్రవేశించుచున్నారు. 11.29",

    "హే విష్ణో! ప్రజ్వలించుచున్న నీ ముఖములతో సమస్త లోకమును కబళించుచు అన్నివైపులనుండి మాటిమాటికిని ఆస్వాదించుచున్నావు.  నీ ఉగ్రతేజస్సులు అంతటను నిండి జగత్తును తపింపజేయుచున్నవి. 11.30",

    "ఓ పరమాత్మా! నీకు నా నమస్కారములు - ప్రసన్నుడవు కమ్ము.  ఉగ్రరూపుడవైన నీవు ఎవరో దయతో నాకు తెలుపుము. ఆదిపురుషుడవైన నిన్ను విశిదముగా తెలిసికొనగోరుచున్నాను.  ఏలనన నీ ప్రవృత్తిని ఎఱుంగలేకున్నాను. 11.31",

    "శ్రీ భగవానుడు పలికెను - నేను లోకములనన్నింటిని తుదముట్టించుటకై విజృంభించిన మహాకాలుడను.  ఇప్పుడు ఈ లోకములను రూపుమాపుటకై పూనుకొనియున్నాను.  కనుక నీవు యుద్ధముచేయకున్ననూ ప్రతిపక్షమననున్న ఈ వీరులెవ్వరును మిగులరు. (మిగిలియుండరు) 11.32",

    "కనుక ఓ సవ్యసాచీ! లెమ్ము, కీర్తి గాంచుము. శత్రువులను జయించి సర్వసంపదలతో తులతూగు రాజ్యమును అనుభవింపుము.  వీరందఱును నా చేత మునుపే హతులైనవారు.  నీవు నిమిత్తమాత్రుడవు కమ్ము. 11.33",

    "ఇదివరకే నాచే చంపబడిన భీష్మ, ద్రోణ, జయద్రథ(సైంధవ), కర్ణాది యుద్ధ వీరులందఱిని నీవు సంహరింపుము. భయపడకుము.  రణరంగమున శత్రువులను తప్పక జయింపగలవు.  కనుక యుద్ధము చేయుము. 11.34",

    "సంజయుడు పలికెను - ఓ రాజా! శ్రీ కృష్ణపరమాత్మయొక్క ఈ మాటలను విని, అర్జునుడు వణకుచు, చేతులు జోడించి నమస్కరించెను, మఱల మిక్కిలి భయముతో ప్రణమిల్లి, గద్గదస్వరముతో తడబడుచు శ్రీకృష్ణుని స్తుతింపసాగెను. 11.35",

    "అర్జునుడు పలికెను - ఓ అంతర్యామీ! కేశవా! నీనామగుణప్రభావములను కీర్తించుచు జగత్తు హర్షాతిరేకముతో, అనురాగముతో ఉప్పొంగిపోవుచున్నది.  ఇది సముచితము.  భయగ్రస్తులైన రాక్షసులు నలుదిక్కులకును పారిపోవుచున్నారు.  సిద్ధగణములవారెల్లరును ప్రణమిల్లుచున్నారు. 11.36",

    "ఓ మహాత్మా! నీవు సర్వశ్రేష్ఠుడవు. సృష్టికర్తయైన బ్రహ్మకే మూలకారకుడవు - కనుక వారు ( సిద్ధాదులందఱును) నీకు నమస్కరింపక ఎట్లుండగలరు?   ఓ అనంతా! ఓ దేవేశా! ఓ జగన్నివాసా! సత్ - అసత్ లు నీవే.  వాటికంటెను పరమైన అక్షరస్వరూపుడవు అనగా సచ్చిదానంద ఘనపరబ్రహ్మవు నీవే. 11.37",

    "ఓ అనంతరూపా! నీవు ఆదిదేవుడవు, సనాతనపురుషుడవు, ఈజగత్తునకు పరమాశ్రయుడవు.  సర్వజ్ఞుడవు, సర్వవేద్యుడవు, పరంధాముడవు.  ఈ జగత్తు అంతయును నీచే పరిపూర్ణమైయున్నది. 11.38",

    "నీవే వాయుదేవుడవు, యముడవు, అగ్నివి, వరుణుడవు, చంద్రుడవు, ప్రజాపతియైన బ్రహ్మవు. బ్రహ్మకును జనకుడవు.  నీకువేలకొలది నమస్కారములు.  మఱల మఱల నమస్కారములు.  ఇంకను నమస్కారములు. 11.39",

    "అనంతసామర్థ్యముగలవాడా! నీకు ఎదురుగా ఉండియు, వెనుకనుండియు నమస్కరించుచున్నాను.  ఓ పరమాత్మా! నీకు అన్నివైపులనుండియు నమస్కారములు.  ఏలనన అనంతపరాక్రమశాలివై నీవు జగత్తంతటను వ్యాపించియున్నవాడవు.  అన్ని రూపములును నీవియే. 11.40",

    "నీ మహిమను ఎఱుగక నిన్ను నా సఖునిగా భావించి, చనువుచేగాని, పొరపాటువలనగాని, ఓ కృష్ణా! ఓయాదవా! ఓ మిత్రా! అనుచు  తొందరపాటుతో ఆలోచింపక, నేను నిన్ను సంబోధించి ఉంటిని. 11.41",

    "ఓ అచ్యుతా! విహారశయ్యాసనభోజనాది సమయములయందు ఏకాంతమునగాని, అన్యసఖుల సమక్షమున గాని సరసమునకై పరిహాసములాడి, నేను నిన్ను కించపఱచి  యుండవచ్చును.  ఓ అప్రమేయస్వరూపా! నా అపరాధములనన్నింటిని క్షమింపుమని వేడుకొనుచున్నాను. 11.42",

    "ఓ అనుపమప్రభావా! ఈ సమస్త చరాచరజగత్తునకు నీవే తండ్రివి. నీవు పూజ్యుడవు. గురుడవు. సర్వశ్రేష్ఠుడవు.  ఈ ముల్లోకములయందును నీతో సమానుడెవ్వడును లేడు. ఇంక నీకంటె అధికుడెట్లుండును. 11.43",

    "కనుక ఓ ప్రభూ! నాశరీరమును నీపాదములకడనిడి, సాష్టాంగముగా ప్రణమిల్లుచున్నాను. స్తవనీయుడవు, సర్వేశ్వరుడవు ఐన నీవు నాయందు ప్రసన్నుడవగుటకై నిన్ను ప్రార్థించుచున్నాను.  దేవా! కుమారుని తండ్రి క్షమించినట్లును, నా అపరాధములను నీవు క్షమింపుము. 11.44",

    "మునుపు ఎన్నడునుచూడని ఆశ్చర్యకరమైన ఈ రూపమును గాంచి, మిక్కిలి సంతసించితిని.  కాని భయముచే నామనస్సు కలవరపాటు పొందినది. కనుక దేవేశా! చతుర్భుజయుక్తుడవై విష్ణురూపముతోడనే నాకు దర్శనమిమ్ము.  జగన్నివాసా! ప్రసన్నుడవు కమ్ము. 11.45",

    "కిరీటమును, గదను, శంఖచక్రములను ధరించిన నీ రూపమును చూడగోరుచున్నాను.  ఓ సహస్రబాహూ! విరాడ్రూపా! నీ చతుర్భుజరూపమును నాకు చూపుము. 11.46",

    "శ్రీ భగవానుడు పలికెను - ఓ అర్జునా! నీపైగల అనుగ్రహమున నా యోగశక్తి ప్రభావముతో నీకు నా విరాట్ – రూపమును ప్రదర్శించితిని.  అది మిక్కిలి తేజోమయమైనది. అనంతమైనది, ఆద్యమైనది.  దీనిని నీవు తప్ప ఇంతకుముందు మఱి యెవ్వరును చూచియుండలేదు. 11.47",

    "ఓ అర్జునా! వేదాధ్యయనములచేగాని, యజ్ఞాచరణములచేగాని, దానములచేగాని, తీవ్రతపశ్చర్యలచేగాని, తదితరపుణ్యకర్మలచేగాని ఈ మానవలోకమున నా ఈ విశ్వరూపము నీకుదప్ప మఱియెవ్వరికిని చూడశక్యము గాదు. 11.48",

    "ఈ విధమైన నా ఈ భయంకరరూపమును చూచి, నీవు ఎట్టి వ్యథకును, మోహమునకును గురికావలదు.  భయమును వీడి ప్రసన్నచిత్తుడవై శంఖచక్ర గదాపద్మములతో విలసిల్లుచున్న నా చతుర్భుజరూపమును మరల చూడుము. 11.49",

    "సంజయుడు పలికెను - వాసుదేవుడు ఈ విధముగా పలికి, అర్జునునకు తన చతుర్భుజరూపమున దర్శనమిచ్చెను.  అనంతరము శ్రీకృష్ణపరమాత్మ సౌమ్యమూర్తియైన తన కృష్ణరూపమును స్వీకరించి, భయపడుచున్న అర్జునునకు ధైర్యము చెప్పెను. 11.50",

    "అర్జునుడు పలికెను - ఓ జనార్దనా! మీ అతిసౌమ్యమైన మానవాకృతిని (శ్యామశుందర రూపమును) చూచి, ఇప్పుడు నా మనస్సు కుదుటబడినది.  నేను నా స్వాభావిక (సహజ) స్థితిని పొందితిని. 11.51",

    "శ్రీ భగవానుడు పలికెను - నీవు చూచిన నా ఈ చతుర్భుజరూపముయొక్క దర్శనభాగ్యము అన్యులకు అత్యంతదుర్లభము.  దేవతలుసైతము ఈ రూపమును దర్శించుటకై సదా ఉవ్విళ్ళూరుచుందురు. 11.52",

    "నీవు గాంచిన నా చతుర్భుజరూపమును దర్శించుటకు వేదపఠనముచేగాని, తపశ్చర్యలచేగాని, దానములచేగాని, యజ్ఞకర్మలచేగాని శక్యము కాదు. 11.53",

    "కాని ఓ పరంతపా! అర్జునా! ఇట్టి నా చతుర్భుజరూపమును  ప్రత్యక్షముగా చూచుటకును, తత్త్వజ్ఞానమును పొందుటకును, అందు ఏకీభావస్థితినందుటకును కేవలము అనన్యభక్తిద్వారా మాత్రమే సాధ్యమగును. 11.54",

    "అర్జునా! కర్తవ్యకర్మలను అన్నింటిని నాకే అర్పించువాడును, మత్పరాయణుడును, నాయందు భక్తిశ్రద్థలుగలవాడును, ప్రాపంచిక విషయములయందు ఆసక్తిలేనివాడును, ఏ ప్రాణియందును ఏమాత్రము వైరభావము లేనివాడును ఐన అనన్య (పరమ) భక్తుడు మాత్రమే నన్ను పొందగలడు. 11.55",

    "ఈ విధముగా ఓం తత్ సత్ అను భగవన్నామ ఉచ్చారణ పూర్వకమైన బ్రహ్మ విద్య మరియు యోగ శాస్త్ర మయమైన, శ్రీకృష్ణార్జునుల సంభాషణ లేక సంవాద రూపములో ఉన్న శ్రీమద్భగవద్గీతలోని పదకొండవ అధ్యాయమైన విశ్వరూపదర్శనయోగము సమాప్తమైనది"
  ],
  "adhyaya-12" : [
    "అర్జునుడు పలికెను: ఓ కృష్ణా! అనన్యభక్తితో పూర్వోక్తరీతిగా నిరంతరము నిన్నే భజించుచు, ధ్యానించుచు పరమేశ్వరుడవైన నీ సగుణరూపమును ఆరాధించువారును, కేవలము అక్షరుడవగు సచ్చిదానంద ఘననిరాకార పరబ్రహ్మవైన నిన్ను అత్యంత భక్తిభావముతో సేవించువారును కలరు. ఈ రెండువిధములైన ఉపాసకులలో అత్యుత్తమ యోగ విదులెవరు? 12.1",

    "శ్రీ భగవానుడు ఇట్లనెను - పరమేశ్వరుడనైన నాయందే ఏకాగ్రచిత్తులై, నిరంతరము నా భజన ధ్యానాదులయందే నిమగ్నులై, అత్యంత శ్రద్ధాభక్తులతో సగుణరూపమున నన్ను ఆరాధించు భక్తులే యోగులలో మిక్కిలి శ్రేష్ఠులు - అని నా అభిప్రాయము. 12.2",

    "కాని ఏ పురుషులు అనిర్వచనీయమైన స్వరూపముగలవాడును, నిరాకారుడును , సర్వవ్యాపియైనవాడును, మనస్సుకు బుద్ధికి గోచరము గానివాడును, సర్వదా ఒకే స్థితిలో ఉండువాడును, నిశ్చలుడును, నిత్యుడును, శాశ్వతుడైన సచ్చిదానందఘనబ్రహ్మను ఏకీభావముతో ఉపాసింతురో… 12.3",

    "(ఏ పురుషులు) ఇంద్రియ సముదాయమును పూర్తిగా వశపఱచుకొని, అన్నిటియందును సమభావముగలవారై, సకల ప్రాణులకును హితముచేయగోరువారుగా ఉంటారో, వారు (యోగులు) నన్నే పొందుదురు. 12.4",

    "కాని సచ్చిదానందఘన నిరాకార పరబ్రహ్మమునందు ఆసక్తచిత్తులైనవారు  తత్ప్రాప్తికై చేయు సాధన మిక్కిలి శ్రమతోగూడినది. ఏలనన దేహాభిమానులకు అవ్యక్తపరబ్రహ్మ ప్రాప్తి కష్టసాధ్యమే. 12.5",

    "కాని మత్పరాయణులైన ఏ భక్తులు కర్మలన్నింటిని నాయందే అర్పించి, సగుణపరమేశ్వరరూపమునే అనన్యభక్తియోగముతో సతతము చింతన చేయుచు భజించుచుందురో......... 12.6",

    "ఓ అర్జునా నాయందే లగ్నమనస్కులైన అట్టి పరమభక్తులను నేను శీఘ్రముగనే మృత్యురూపసంసారసాగరము నుండి ఉద్ధరించెదను. 12.7",

    "నాయందే మనస్సు నిలుపుము. నాయందే బుద్ధిని లగ్నముచేయుము. పిమ్మట నాయందే స్థిరముగా నుందువు. ఇందు ఏ మాత్రమూ సందేహమునకు తావులేదు. 12.8",

    "మనస్సును సుస్థిరముగా నాయందే నిల్పుటకు సమర్థుడవు కానిచో అర్జునా! అభ్యాసయోగము ద్వారా నన్ను పొందుటకు ప్రయత్నింపుము. 12.9",

    "అభ్యాసముచేయుటకును అశక్తుడవైనచో మత్పరాయణుడవై కర్మలను ఆచరింపుము. ఈ విధముగా నా నిమిత్తమై కర్మలను ఆచరించుటద్వారాకూడ నన్నే పొందెదవు. 12.10",

    "మత్ప్రాప్తికై వలయు యోగమునాశ్రయించి సాధన చేయుటకును నీవు అశక్తుడవైనచో మనోబుద్ధీంద్రియాదులను వశమునందు ఉంచుకొని, సకల కర్మఫలములను త్యజింపుము. 12.11",

    "తత్త్వము నెఱుంగకయే చేయు అభ్యాసముకంటెను జ్ఞానము శ్రేష్ఠము. కేవలము పరోక్ష జ్ఞానము కంటెను అనగా అనుభవరహితమైన జ్ఞానముకంటెను (శాస్త్ర పాండిత్యముకంటెను) పరమేశ్వరస్వరూపధ్యానము శ్రేష్ఠము. ధ్యానము కంటెను కర్మఫలత్యాగము మిక్కిలి శ్రేష్ఠమైనది. ఏలనన త్యాగమువలన వెంటనే పరమశాంతి లభించును. 12.12",

    "ఏ ప్రాణియందును ద్వేషభావము లేనివాడును, పైగా సర్వప్రాణులయందును అవ్యాజమైనప్రేమ, కరుణ కలవాడును, మమతాహంకారములు లేనివాడును, సుఖము ప్రాప్తించినను, దుఃఖము ప్రాప్తించినను, సమభావము కలిగియుండువాడును, క్షమాగుణము కలవాడును (అపరాధికిని అభయమును ఇచ్చువాడును)... 12.13",

    "...సర్వకాల సర్వావస్థలయందును సంతుష్టుడైయుండు యోగియు, శరీరేంద్రియ మనస్సులను వశమునందుంచుకొనినవాడును, నాయందు దృఢమైన నిశ్చయము గలవాడును, అయి నాయందే మనోబుద్దులను అర్పణ చేసిన నా భక్తుడు నాకు ప్రియుడు. 12.14",

    "లోకమున ఎవరికిని ఉద్వేగము (క్షోభ) కలిగింపనివాడును, ఎవరివలనను తాను ఉద్వేజితుడు కానివాడును (ఉద్వేగమునకు గురికానివాడును), హర్షము, ఈర్ష్య, భయము, ఉద్వేగము మున్నగు  వికారములు లేనివాడును అగు భక్తుడు  నాకు ప్రియుడు. 12.15",

    "ఏమాత్రమూ కాంక్షలేనివాడు, శరీరేంద్రియమనస్సులయందు శుచియై యున్నవాడును, దక్షుడును, పక్షపాతరహితుడును, ఎట్టి దుఃఖములకును చలింపనివాడును, సమస్తకర్మల యందును కర్తృత్వాభిమానములు లేనివాడును అగు భక్తుడు  నాకు ప్రియుడు. 12.16",

    "ఇష్టవస్తుప్రాప్తికి పొంగిపోనివాడును, దేనియందును ద్వేషభావము లేనివాడును, దేనికినీ శోకింపనివాడును, దేనినీ ఆసింపనివాడును, శుభాశుభకర్మలను త్యజించినవాడును, ఐన భక్తుడు  నాకు ప్రియుడు. 12.17",

    "శత్రువులయెడను, మిత్రులయెడను సమభావముతో మెలగువాడును, మానావమానములు, శీతోష్ణములు, సుఖదుఃఖములు మొదలగు ద్వంద్వములను సమానముగా స్వీకరించువాడును, ఆసక్తిరహితుడును.... 12.18",

    "నిందాస్తుతులకు చలింపనివాడును, మననశీలుడును, శరీరనిర్వహణకై లభించిన దానితోడనే తృప్తిపడువాడును, నివాసస్థానములయందు మమతాసక్తులు లేనివాడును, స్థితప్రజ్ఞుడును అగు భక్తుడు  నాకు ఇష్టుడు. 12.19",

    "ఉపర్యుక్తధర్మ్యామృతమును సేవించుచు (పైనపేర్కొనభడిన ఉత్తమ గుణములను కలిగియుండి), నిష్కామభక్తిశ్రద్దలతో మత్పరాయణులైన భక్తులు నాకత్యంత ప్రియులు. 12.20",

    "ఈ విధముగా ఓం తత్ సత్ అను భగవన్నామ ఉచ్చారణ పూర్వకమైన బ్రహ్మ విద్య మరియు యోగ శాస్త్ర మయమైన, శ్రీకృష్ణార్జునుల సంభాషణ లేక సంవాద రూపములో ఉన్న శ్రీమద్భగవద్గీతలోని పన్నిండవ అధ్యాయమైన భక్తియోగము సమాప్తమైనది"
  ],
  "adhyaya-13" : [
    "శ్రీ భగవానుడు పలికెను - ఓ కౌంతేయా! ఈ శరీరమును 'క్షేత్రము' అనిపేర్కొందురు. ఈ క్షేత్రమును గూర్చి ఎఱింగిన వానిని 'క్షేత్రజ్ఞుడు' అని తత్త్వము తెలిసిన జ్ఞానులు తెలుపుదురు. 13.1",

    "అర్జునా! అన్ని క్షేత్రములయందున్న క్షేత్రజ్ఞుడను అనగా జీవాత్మను నేనే అని తెలిసికొనుము. క్షేత్రక్షేత్రజ్ఞులకు సంబంధించినజ్ఞానము అనగా వికారసహిత (త్రిగుణాత్మకమైన) ప్రకృతి మరియు నిర్వికారపురుషుల తత్త్వములనుగూర్చి తెలిసి కొనుటయే ‘జ్ఞానము' అని నా అభిప్రాయము. 13.2",

    "క్షేత్రమనగానేమి? అది యెట్లుండును? దాని వికారములు (భావములు) ఏవి? ఆ వికారములు దేనినుండి ఏర్పడినవి? అట్లే క్షేత్రజ్ఞుడు అనగా ఎవరు? అతని ప్రభావమేమి? ఆ వివరములను అన్నింటిని సంక్షిప్తముగా తెలిపెదను వినుము. 13.3",

    "క్షేత్ర, క్షేత్రజ్ఞులతత్త్వములనుగూర్చి ఋషులెల్లరును బహువిధములుగా వివరించిరి. వివిధ వేదమంత్రములను వేర్వేరుగా తెల్పినవి. అట్లే బ్రహ్మసూత్ర పదములుగూడ నిశ్చయాత్మకముగ సహేతుకముగ తేటతెల్లము గావించినవి. 13.4",

    "పంచమహాభూతములు, అహంకారము, బుద్ధి, మూలప్రకృతియు, అట్లే దశేంద్రియములు, మనస్సు, పంచేంద్రియగ్రాహ్యవిషయములు (శబ్ద, స్పర్శ, రూప, రస గంధములు). 13.5",

    "ఇచ్ఛ, ద్వేషము, సుఖము, దుఃఖము, స్థూలశరీరము, చైతన్యము, ధృతి అను వికారములతో గూడిన 'క్షేత్రస్వరూపము' సంక్షిప్తముగా పేర్కొనబడినది. 13.6",

    "తానే శ్రేష్ఠుడనను భావము లేకుండుట, డాంభికము లేకుండుట, అహింస, క్షమించుగుణము, మనోవాక్కులయందు సరళత్వము, శ్రద్ధాభక్తులతో గురుజనులను సేవించుట, బాహ్యాభ్యంతరశుద్ధి, అంతః కరణస్థిరత్వము, మనశ్శరీరేంద్రియముల నిగ్రహము. 13.7",

    "ఇంద్రియార్థములయందు వైరాగ్యము (ఇహపరలోక భోగములన్నింటి యందును ఆసక్తి లేకుండుట) అహంకార రాహిత్యము, జన్మ, మృత్యు, జరా (ముసలితనము) రోగాదులయందు దుఃఖదోషములను పదేపదే దర్శించుట. 13.8",

    "భార్య, పుత్రులు, ఇల్లు, సంపదలు మున్నగువాని యందు మమతాసక్తులు లేకుండుట, ఇష్టానిష్ట వస్తుప్రాప్తివలన ఎట్టి మనోవికారములకును లోను గాకుండుట, ఎల్లప్పుడును సమభావముతో నుండుట. 13.9",

    "పరమేశ్వరుడనైన నాయందు అనన్యయోగము ద్వారా అవ్యభిచారిణీభక్తి కలిగియుండుట, ఏకాంతపవిత్రప్రదేశమున వసించు ప్రవృత్తి కలిగియుండుట, విషయాసక్తులైన జనులయెడ ఆసక్తిలేకుండుట. 13.10",

    "ఆధ్యాత్మికజ్ఞానమునందు నిత్యస్థితుడై యుండుట, తత్త్వజ్ఞానమునకు ప్రతిపాద్యుడు భగవంతుడేయని యెఱుంగుట - ఇవియన్నియును (అమానిత్వము మొదలుకొని తత్త్వజ్ఞానార్థదర్శనము వఱకుగల ఇవి యన్నియును) జ్ఞానప్రాప్తికి సాధనములు దీనికి విపరీతమైనది అజ్ఞానము. 13.11",

    "అనాదియైన (సనాతనుడైన) పరబ్రహ్మయే తెలిసికొన దగినవాడు. అతనిని తెలిసికొనుటవలన మానవుడు పరమానందమును పొందును. అతడు సదసత్తులకు (సత్ + అసత్ లకు) అతీతుడు. ఆ పరమాత్మనుగూర్చి సమగ్రముగా నీకు తెల్పుచున్నాను. 13.12",

    "పరమాత్ముని చేతులు, పాదములు, నేత్రములు, చెవులు, శిరస్సులు, ముఖములు సర్వత్ర స్థితములై యున్నవి. అతడు సర్వమును ఆవరించి యున్నాడు. 13.13",

    "అతడు ఇంద్రియగ్రాహ్యవిషయములను అన్నింటిని ఎఱిగినవాడు. కాని వాస్తవముగా ఇంద్రియాదులకు అతీతుడు. ఆసక్తిరహితుడైనను సమస్తజగత్తును భరించి పోషించువాడు. అతడు నిర్గుణుడయ్యును (గుణాతీతు డయ్యును) ప్రకృతి సంబంధమువలన గుణానుభవములను కలిగియుండువాడు. 13.14",

    "చరాచరభూతములన్నింటికిని బాహ్యాభ్యంతరముల యందు పరిపూర్ణముగా ఉండువాడును, చరాచర రూపుడును అతడే. అతిసూక్ష్మ (పరమాణు) రూపుడైనందున తెలిసికొనశక్యముకానివాడు, అతిదూరముగను, అతిసమీపముగను స్థితుడై ఉండువాడును అతడే. 13.15",

    "పరమాత్మ ఆకాశమువలె విభజించుటకు వీలులేని ఒకేరూపమున పరిపూర్ణుడైయున్నను, సమస్త చరాచరప్రాణుల రూపములలో వేర్వేరుగా గోచరించు చుండును. ఆ పరమాత్మయే సృష్టికర్తయైన బ్రహ్మగా, పోషకుడైన విష్ణువుగా, లయకారకుడైన రుద్రునిగా తెలిసికొనదగినవాడు. 13.16",

    "ఆ పరబ్రహ్మ అన్నిజ్యోతులకును జ్యోతి పరంజ్యోతి మాయాతీతుడును, జ్ఞానస్వరూపుడును, తెలిసికొనదగినవాడును తత్త్వజ్ఞానముద్వారా ప్రాప్యుడును అతడే. సర్వప్రాణుల హృదయముల యందు విశేషముగా ఉండువాడును అతడే. 13.17",

    "ఇంతవఱకును క్షేత్రమును గూర్చియు, జ్ఞానమును గూర్చియు, జ్ఞేయమును (తెలిసికొనదగిన పరమాత్మ స్వరూపమును) గుఱించియు సంక్షిప్తముగా వివరించితిని. ఈ తత్త్వమును సమగ్రముగా తెలిసికొనిన నా భక్తుడు నా స్వరూపమునే పొందును. 13.18",

    "ప్రకృతి, పురుషుడు - అను ఈ రెండును అనాదియైన (సనాతనమైన) వనియు, రాగద్వేషాది వికారములును, త్రిగుణాత్మకములైన పదార్థము లన్నియును ప్రకృతినుండియే ఉత్పన్నములైనవనియు ఎఱుంగుము. 13.19",

    "కార్యకరణములను ఉత్పన్నము చేయుటలో ప్రకృతియే హేతువనియు, సుఖ దుఃఖములను అనుభవించుటలో జీవాత్మయే హేతువనియు చెప్పబడుచున్నది. 13.20",

    "పురుషుడు ప్రకృతిస్థుడై ప్రకృతినుండి ఉత్పన్నములైన త్రిగుణాత్మక పదార్థములను అనుభవించును. ఈ గుణసాంగత్యమే జీవాత్మయొక్క ఉత్తమాధమ జన్మలకు కారణమగును. 13.21",

    "ఈ దేహమునందున్న ఆత్మ వాస్తవముగా  పరమాత్మయే. అతడు సాక్షిభూతుడగుటవలన ‘ఉపద్రష్ట’యనియు, యథార్థసమ్మతినిచ్చువాడగుట వలన ‘అనుమంత’ అనియు, అన్నింటినిధరించి, పోషించువాడు అగుటవలన ‘భర్త’అనియు జీవరూపములో ‘భోక్త’ అనియు, బ్రహ్మాదులకును స్వామి యగుటవలన ‘మహేశ్వరుడు’ అనియు శుద్ధసచ్చిదానందఘనస్వరూపుడగుట వలన ‘పరమాత్మ’ అనియు పిలువబడుచున్నాడు. 13.22",

    "ఈ విధముగా పురుషుని తత్త్వమును, గుణసహిత మైన ప్రకృతితత్త్వమును తెలిసికొనినవాడు అన్ని విధములగు కర్తవ్యకర్మలను ఆచరించుచున్నప్పటికిని పునర్జన్మను పొందడు. 13.23",

    "కొందరు ఈ పరమాత్మను శుద్ధమైన సూక్ష్మబుద్ధితో ధ్యానయోగముద్వారా తమ హృదయముల యందు చూతురు. మఱికొందరు (జ్ఞాన) యోగముద్వారాను, మణికొందఱు కర్మయోగము ద్వారాను ఆ పరమాత్మను దర్శింతురు. 13.24",

    "కాని, ఈ సాధనమార్గములనుగూర్చి ఎఱుగని మంద బుద్ధులు తత్త్వజ్ఞానముగల ఇతరులనుండి విని, తదనుసారముగ ఉపాసనలు చేయుదురు. ఆ శ్రవణ పరాయణులును మృత్యురూపసంసార సాగరమునుండి నిస్సందేహముగా బయటపడుదురు. 13.25",

    "ఓ అర్జునా! ఆ స్థావరజంగమప్రాణులన్నియును క్షేత్ర - క్షేత్రజ్ఞ సంయోగము వలననే ఉత్పన్నమగునని ఎఱుంగుము. 13.26",

    "నశ్వరములైన చరాచరభూతముల (ప్రాణుల) యందు సమముగా స్థితుడైయున్న పరమేశ్వరుడు నాశరహితుడు. అట్టి పరమేశ్వరుని చూచువాడే నిజమైన ద్రష్ట. 13.27",

    "సమస్తప్రాణులయందును సమభావముతోనుండు పరమేశ్వరుని సమత్వభావముతో చూచువాడు ఆత్మహంతకుడుకాడు - అనగా తనను తాను నాశము చేసికొనువాడు కాడు. అందువలన అతడు పరమగతిని పొందును. 13.28",

    "సకలకర్మలు అన్నివిధములుగ ప్రకృతిద్వారానే జరుగుచున్నవనియు, ఆత్మ అకర్త అనియు ఎఱింగినవాడు వాస్తవముగ చూచువాడు. 13.29",

    "వేర్వేరుగా గోచరించుచున్న ప్రాణులన్నియును ఒకే పరమాత్మయందు స్థితమై యున్నవనియు, అట్లే అవి యన్నియును ఆ పరమాత్మనుండియే విస్తరించు చున్నవనియు, ఎఱింగిన పురుషుడు ఆ క్షణముననే సచ్చిదానంద ఘనపరబ్రహ్మను చేరును. 13.30",

    "ఓ అర్జునా! నాశరహితుడైనపరమాత్ముడు ప్రాణుల శరీరములందున్నప్పటికిని అనాది (సనాతనుడు) అగుటవలనను, నిర్గుణుడు అగుటవలనను ఎట్టి కర్మలకును కర్తకాడు. కనుక కర్మలు అతనిని ఏమాత్రమును అంటవు. 13.31",

    "సర్వత్ర (ఇతర మహాభూతములయందు) వ్యాపించి యున్న ఆకాశము సూక్ష్మ మగుట వలన వాటి గుణదోషములు దానికి అంటవు. అట్లే సకల ప్రాణుల దేహముల యందు స్థితమైయున్నను ఆత్మ నిర్గుణమగుటవలన వాటి గుణదోషములును దానికంటవు. 13.32",

    "అర్జునా! ఒక్క సూర్యుడే ఈ సమస్త జగత్తును ప్రకాశితమొనర్చు చున్నట్లు, ఒకే ఆత్మ సర్వప్రాణుల యందును స్థితమై, వాటినన్నింటిని ప్రకాశింప (చైతన్య వంతములుగా) జేయుచున్నది. 13.33",

    "ఈ విధముగా క్షేత్ర - క్షేత్రజ్ఞులమధ్యగల అంతరమును, కార్యసహిత ప్రకృతినుండి విముక్తులగుటకు ఉపాయములను జ్ఞాననేత్రముల ద్వారా ఎఱింగిన మహాత్ములు పరమగతిని పొందుదురు. 13.34",

    "ఈ విధముగా ఓం తత్ సత్ అను భగవన్నామ ఉచ్చారణ పూర్వకమైన బ్రహ్మ విద్య మరియు యోగ శాస్త్ర మయమైన, శ్రీకృష్ణార్జునుల సంభాషణ లేక సంవాద రూపములో ఉన్న శ్రీమద్భగవద్గీతలోని పదమూడవ అధ్యాయమైన క్షేత్రక్షేత్రజ్ఞ్యవిభాగయోగము సమాప్తమైనది"
  ],
  "adhyaya-14" : [
    "శ్రీ భగవానుడు పలికెను - జ్ఞానములలో అత్యుత్తమమైన పరమజ్ఞానమును నీకు మరల తెల్పబోవుచున్నాను. ఆ పరమజ్ఞానమును తెలిసికొనిన మునులు సంసార బంధములనుండి విముక్తులై పరమ సిద్ధిని పొందిరి. 14.1",

    "ఈ జ్ఞానమును ఆశ్రయించినవారు నాస్వరూపమునే పొందుదురు. అట్టి పురుషులుసృష్ట్యాదియందు మఱల జన్మింపరు, ప్రళయకాలమున వ్యథనొందరు. 14.2",

    "ఓ అర్జునా! నామహద్బ్రహ్మరూపమైన మూలప్రకృతి సర్వప్రాణులకు జన్మస్థానము. దానియందు చేతనసముదాయరూపమైన బీజమును ఉంచెదను(స్థాపన చేసెదను). ఆ జడచేతనసంయోగమువలననే సర్వభూతముల ఉత్పత్తి జరుగును. 14.3",

    "ఓ అర్జునా! నానాయోనులయందు జన్మించు ప్రాణులను తనగర్భమున ధరించు మూలప్రకృతియే ఆ ప్రాణులకు తల్లి. బీజస్థాపనచేయు నేను వాటికి తండ్రిని.అనగా సర్వప్రాణులకును ప్రకృతియే తల్లి నేనే జనకుడను. 14.4",

    "ఓ అర్జునా! ప్రకృతినుండి ఉత్పన్నములైన సత్త్వరజస్తమోగుణములు నాశములేని జీవాత్మను శరీరమున బంధించును. 14.5",

    "ఓ పాపరహితుడా! ఈమూడింటిలోసత్త్వగుణము నిర్మలమైనది.కనుక ప్రకాశకమైనది. వికారరహితమైనది. కాని అది సుఖసాంగత్యమువలనజ్ఞానాభిమానము వలన మనుజుని బంధించును. 14.6",

    "అర్జునా! రజోగుణమురాగాత్మకము.అదికామము,ఆసక్తులనుండిఉత్పన్నమగునని యెఱుంగుము. అది జీవాత్మను కర్మలయెుక్కయు, కర్మఫలములయెుక్కయు సాంగత్యముతో బంధించును. 14.7",

    "అర్జునా! తమోగుణం సకలదేహాభిమానులను మోహితులనుగా చేయును. అజ్ఞానమువలన జనించును. అది జీవాత్మను ప్రమాదాలస్యనిద్రాదులతో బంధించును. 14.8",

    "ఓ అర్జునా! సత్త్వగుణము జీవుని సుఖములలో నిమగ్నము చేయును. రజోగుణము కర్మలయందు నిమగ్నునిగా చేయును. తమోగుణం జ్ఞానమును కప్పివేసి ప్రమాదాదులలో ముంచివేయును. 14.9",

    "ఓ అర్జునా! రజోస్తమోగుణములనణచి సత్త్వగుణము వృద్ధిచెందును. సత్త్వతమోగుణములనణచి రజోగుణము వృద్ధిచెందును. అట్లే సత్త్వరజోగుణములనణచి తమోగుణము వృద్ధియగును. 14.10",

    "శరీరేంద్రియములయందును, అంతఃకరణము నందును చైతన్యము, వివేకశక్తి ఉత్పన్నములైనప్పుడు అతనిలో సత్త్వగుణము వృద్ధిచెందినదని తలంపవలెను. 14.11",

    "ఓ అర్జునా! రజోగుణము వృద్ధియైనప్పుడు లోభము, ప్రవృత్తి, (ప్రాపంచిక విషయములయందు ఆసక్తి) స్వార్థబుద్ధితో సకామభావముతో కర్మాచరణము, అశాంతి, విషయభోగములయందు లాలస అనునవి ఉత్పన్నములగుచుండును. 14.12",

    "ఓ అర్జునా! తమోగుణము అధికమైనప్పుడు అంతఃకరణమునందును, ఇంద్రియములయందును వివేకశక్తి నష్టమగును. కర్తవ్యకర్మలయందు విముఖత, ప్రమాదము అనగా అంతఃకరణమును మోహింపజేయు వ్యర్థచేష్టలు, నిద్రాదివృత్తులు మెుదలగునవి ఉత్పన్నములగుచుండును. 14.13",

    "సత్వగుణము వృద్ధిచెందినపుడు మనుజుడు మరణించినచో, అతడు ఉత్తమ కర్మలను ఆచరించువారు చేరెడి నిర్మలములైన స్వర్గాది దివ్యలోకములను పొందును. 14.14",

    "రజోగుణము వృద్ధిచెందినపుడు మృత్యువు ప్రాప్తించినచో అతడు కర్మాసక్తులైన మానవులలో జన్మించును. అట్లే తమోగుణము వృద్ధిచెందినప్పుడు మృతిచెందిన మానవుడు పశుపక్షికీటకాది నీచయోనులలో జన్మించుచుండును. 14.15",

    "శ్రేష్ఠములైన కర్మలను ఆచరించుటవలన సాత్త్విక ఫలము అనగా సుఖము, జ్ఞానము, వైరాగ్యము మొదలగు నిర్మలఫలములు కలుగును. రాజసకర్మలకు ఫలము దుఃఖము. తామసకర్మలకు ఫలము అజ్ఞానము. 14.16",

    "సత్త్వగుణమువలన జ్ఞానమూ, రజోగుణమువలన లోభమూ, తమోగుణము వలన ప్రమాద మోహాదులూ, అజ్ఞానమూ, తప్పక ఉత్పన్నము లగుచుండును. 14.17",

    "సత్త్వగుణస్థితులు స్వర్గాది - ఊర్ధ్వలోకములకు పోవుదురు. రజోగుణస్థితులైన (రాజస)పురుషులు మధ్య (మానవ) లోకమునందే ఉందురు. తమోగుణ కార్యరూపములైన నిద్రాప్రమాదాలస్యాదులయందు స్థితులైనవారు అధోగతిని అనగా కీట, పశుపక్ష్యాది యోనులను, నరకములను పొందుదురు. 14.18",

    "ద్రష్టయైనవాడు గుణములే గుణములయందు వర్తించుచున్నవనియూ, త్రిగుణములు తప్ప వేఱుగా కర్తలు లేరనియూ తెలిసికొని, త్రిగుణములకు అతీతముగానున్న సచ్చిదానంద ఘనపరమాత్మ స్వరూపుడనైన నా తత్త్వమును తెలిసికొనును. అప్పుడతడు నా స్వరూపమునే పొందును. 14.19",

    "దేహోత్పత్తికి కారణరూపములైన ఈ మూడు గుణములను అధిగమించిన పురుషుడు జన్మ, మృత్యు, జరా(వార్ధక్య), దుఃఖములనుండి విముక్తుడై, పరమానందమును పొందును. 14.20",

    "అర్జునుడు పలికెను - ఓ ప్రభూ! ఈ మూడు గుణములకును అతీతుడైనవాని లక్షణములెవ్వి? అతని లోకవ్యవహారరీతి యెట్లుండును? మనుష్యుడు త్రిగుణాతీతుడగుటకు ఉపాయమేమి? 14.21",

    "శ్రీ భగవానుడు పలికెను - ఓ పాండవా! సత్త్వగుణ కార్యరూపమైన ప్రకాశము, రజోగుణకార్యరూపమైన ప్రవృత్తి, తమోగుణ కార్యరూపమైన మోహము, తమంతట తామే ఏర్పడినప్పుడు త్రిగుణాతీతుడు ద్వేషింపడు - అనగా వాటిని గూర్చివిచారపడడు. అట్లే అవి తొలగిపోయి నప్పుడు వాటికై కాంక్షపడడు అనగా ఎల్లప్పుడును ఒకే స్థితిలోనుండును. 14.22",

    "త్రిగుణములకును, వాటి కార్యరూపములైన శరీరేంద్రియాంతఃకరణ వ్యాపారములకును ఏమాత్రము చలింపక, త్రిగుణాతీతుడు, ఉదాసీనుని వలె(సాక్షివలె) ఉండును. గుణములే గుణములయందు ప్రవర్తించు చున్నవని తలంచును. అతడు సచ్చిదానందఘన పరమాత్మయందు ఏకీభావస్థితుడై యుండును. ఈ స్థితినుండి ఎన్నడును చలింపడు. 14.23",

    "త్రిగుణాతీతుడు స్వస్వరూపమునందే నిరంతరము స్థితుడై యుండును. సుఖదుఃఖములను సమానముగా భావించును. మట్టి, రాయి, బంగారములయందు సమభావమునే కలిగియుండును. అనగా ఆ మూడింటి యందును గ్రాహ్యత్యాజ్య భావములను కలిగియుండడు. అతడే ధీరుడైన జ్ఞాని. ప్రియాప్రియములకుగాని, నిందాస్తుతులకు గాని తొణకడు. అనగా రెండింటి యందును సమస్థితిలోనే యుండును. 14.24",

    "మానావమానములయందును, మిత్రులయందును, శత్రువులయందును సమభావముతో ప్రవర్తించు వాడును, విధ్యుక్తకర్మలను ఆచరించుచు కర్తృత్వాభిమానము లేనివాడును త్రిగుణాతీతు డనబడును. 14.25",

    "అనన్య (నిర్మలమైన) భక్తియోగముద్వారా నన్నే నిరంతరము భజించువాడును,ఈ మూడు గుణములకును పూర్తిగా అతీతుడైనవాడును అగు పురుషుడు సచ్చిదానందఘనపరబ్రహ్మ ప్రాప్తికి అర్హుడగును. 14.26",

    "ఏలనన అట్టి శాశ్వతపరబ్రహ్మకును, అమృతత్వ మునకును, సనాతన ధర్మమునకును, అఖండా నందమునకును నేనే ఆధారము. 14.27",

    "ఈ విధముగా ఓం తత్ సత్ అను భగవన్నామ ఉచ్చారణ పూర్వకమైన బ్రహ్మ విద్య మరియు యోగ శాస్త్ర మయమైన, శ్రీకృష్ణార్జునుల సంభాషణ లేక సంవాద రూపములో ఉన్న శ్రీమద్భగవద్గీతలోని పదునాలుగవ అధ్యాయమైన గుణత్రయవిభాగయోగము సమాప్తమైనది"
  ],
  "adhyaya-15" : [
    "శ్రీ భగవానుడు పలికెను - ఆదిపురుషుడైన పరమేశ్వరుడే మూలముగా, బ్రహ్మయే ముఖ్యశాఖగా(కాండముగా), వేదములే పర్ణములు(ఆకులు)గా గల ఈ సంసారరూప-అశ్వత్థవృక్షము నాశరహితమైనది(శాశ్వతమైనది). ఈ సంసార వృక్షతత్త్వమును మూలసహితముగా తెలిసినవాడు నిజముగా వేదార్థములను ఎఱిగిన వాడు. 15.1",

    "ఈ సంసారవృక్షమును త్రిగుణములనెడిజలములు తడుపుచుండును. ఆ జలములచే వృద్ధిపొందు శాఖలే దేవమనుష్యతిర్యగ్యోనులలో జన్మించుప్రాణులు, చిగుళ్లే విషయభోగములు. ఈ శాఖలు, చిగుళ్ళు సర్వత్ర వ్యాపించియున్నవి. మనుజులను కర్మాను సారముగా బంధించు అహంకార మమకార వాసనలనెడి వేర్లు, ఊడలు అన్నిలోకములలోను క్రింద, పైన వ్యాపించి ఉన్నవి. 15.2",

    "ఈ సంసారవృక్షమును బాగుగా పరిశీలించి చూచినచో ఇందు వర్ణంపబడిన రీతిగా లభ్యముగాదు. ఏలనన ఇది ఆదియు, అంతము, సరియైన స్థితియు లేనిది. కనుక అహంకారమమకారవాసనారూపములైన ధృఢమైన వేర్లు, ఊడలు గల ఈ సంసారరూప అశ్వత్థవృక్షమును నిశితమైన వైరాగ్యమనెడి శస్త్రముతో ఖండించి..... 15.3",

    "అనంతరము ఆ పరమపదరూపుడైన పరమేశ్వరుని సర్వతోముఖముగ అన్వేషింపవలెను. అ పరమపదమును చేరిన పురుషులు ఈ జగత్తునకు తిరిగిరారు. అట్టి పరమేశ్వరునినుండియే ఈపురాతన సంసారవృక్షపరంపర విస్తరించియున్నది. అట్టి ఆదిపురుషుడైననారాయణునే శరణుపొంది, దృఢనిశ్చయముతో ఆ పరమేశ్వరుని మనన, నిధిధ్యాసాదులు చేయవలెను. 15.4",

    "దురభిమానమును, మోహమును త్యజించినవారును, ఆసక్తియను దోషమును జయించినవారును, ప్రాపంచికవాంఛలనుండి పూర్తిగా మఱలినవారును, పరమాత్మ స్వరూపమునందు నిత్యస్థితులైనవారును, సుఖదుఃఖాదిద్వంద్వముల నుండి విముక్తులైన వారును అగు జ్ఞానులు శాశ్వతమైన ఆ పరమపదమును పొందుదురు. 15.5",

    "స్వయంప్రకాశస్వరూపమైన నాపరంధామమును సూర్యుడుగాని, చంద్రుడుగాని , అగ్నిగాని ప్రకాశింప జేయజాలరు. అట్టి పరంధామమును చేరిన పుణ్యాత్ములు మఱల ఈ జగత్తున ప్రవేశింపరు. అనగా జన్మింపరు. 15.6",

    "ప్రాణి లోకమున ఈ దేహమునందున్న సనాతనమైన జీవాత్మ నా అంశయే. అది ప్రకృతియందు స్థితములైన మనస్సు, ఇంద్రియములను, శబ్దాదివిషయములద్వారా తనవైపు ఆకర్షించును. 15.7",

    "వాయువు వాసనలను ఒకచోటినుండి మఱియొక చోటికి తీసికొని పోయినట్లుగా దేహాదులకు స్వామియైన జీవాత్మ ఒక శరీరమును త్యజించునపుడు, మనస్సు ఇంద్రియములను గ్రహించి, వాటితో గూడ మఱొక శరీరమును పొందును. అనగా దానిలో ప్రవేశించును. 15.8",

    "ఈ జీవాత్మ త్వక్ఛక్షుశ్ర్శోత్రజిహ్వాఘ్రాణములు అనెడి పంచేంద్రియములను, మనస్సును ఆశ్రయించి, శబ్దాదివిషయములను అనుభవించును. 15.9",

    "జీవాత్మ శరీరమును త్యజించునప్పుడును, శరీరమునందు స్థితుడై యున్నప్పుడును, విషయ భోగములను అనుభవించుచున్నప్పుడును, అటులే త్రిగుణములతో కూడియున్నప్పుడును అజ్ఞానులు తెలిసికొనలేరు. కేవలము వివేకశీలురైన జ్ఞానులే తమజ్ఞాననేత్రములవలన స్వస్వరూపమును తెలిసికొన గల్గుదురు. 15.10",

    "అంతఃకరణశుద్ధిగల యోగులు తమహృదయముల యందున్న ఈ ఆత్మతత్త్వమును ప్రయత్నశీలురై తెలిసికొనగలరు. కాని, అంతఃకరణశుద్ధిలేని అజ్ఞానులు ఎంతగా ప్రయత్నించియు, ఈ ఆత్మను తెలిసికొన జాలరు. 15.11",

    "సమస్తజగత్తును ప్రకాశింపజేయు సూర్యుని తేజస్సును, అటులనే చంద్రుని తేజస్సును, అగ్నితేజస్సును నాతేజస్సేయని యెఱుంగుము. 15.12",

    "పృథ్వియందు ప్రవేశించి, నేను నా శక్తిద్వారా సకల భూతములను ధరించి, పోషించుచున్నాను. రసస్వరూపుడనై - అనగా అమృతమయుడైన చంద్రుడనై ఓషధులకు అనగా వనస్పతులన్నింటికిని పుష్టిని చేకూర్చుచున్నాను. 15.13",

    "నేనే ప్రాణాపానసంయుక్తమైన వైశ్వానరాగ్ని రూపములో సర్వప్రాణుల శరీరముల యందుండి నాలుగువిధములైన ఆహారములను జీర్ణము చేయుచుందును. 15.14",

    "సమస్తప్రాణుల హృదయములలో అంతర్యామిగా ఉన్న వాడను నేనే. నానుండియే స్మృతి, జ్ఞానము, అపోహనము (సందేహమును తొలగించుట) కలుగుచున్నవి. వేదముల ద్వారా తెలిసికొనదగిన వాడను నేనే. వేదాంతకర్తను, వేదజ్ఞుడను గూడ నేనే. 15.15",

    "ఈ జగత్తునందు క్షరుడు (నశ్వరుడు), అక్షరుడు (వినాశరహితుడు) అని పురుషులు రెండు విధములుగా గలరు. సకలప్రాణులశరీరములు నశ్వరములు. జీవాత్మ నాశరహితుడు. 15.16",

    "పై ఇద్దరికంటెను ఉత్తముడైన పురుషుడు వేరైన వాడొకడు కలడు. అతడే నాశరహితుడైన పరమేశ్వరుడు, పరమాత్మ. అతడు ముల్లోకములయందును ప్రవేశించి అందఱిని భరించి పోషించుచున్నాడు. 15.17",

    "ఏలనన, నశ్వరమగు జడవర్గము (క్షేత్రము) కంటెను నేను సర్వథా అతీతుడను. నాశరహితమైన జీవాత్మ కంటెను ఉత్తముడను. కనుక ఈజగత్తునందును, వేదముల యందును పురుషోత్తముడనని ప్రసిద్ధి కెక్కితిని. 15.18",

    "ఓ అర్జునా! జ్ఞానియైనవాడు ఈ విధముగా నన్ను తత్త్వతః పురుషోత్తమునిగా ఎఱుంగును. సర్వజ్ఞుడైన అతడు వాసుదేవుడనైన నన్నే నిరంతరము పరమేశ్వరునిగా భజించును. 15.19",

    "ఓ పుణ్యపురుషా! ఓ అర్జునా! అత్యంతము గోప్యమైన శాస్త్రమును ఈ విధముగా నీకు తెలిపితిని. దీని తత్త్వమును తెలిసికొనిన మనుష్యుడు జ్ఞానియై కృతార్థుడు కాగలడు. 15.20",

    "ఈ విధముగా ఓం తత్ సత్ అను భగవన్నామ ఉచ్చారణ పూర్వకమైన బ్రహ్మ విద్య మరియు యోగ శాస్త్ర మయమైన, శ్రీకృష్ణార్జునుల సంభాషణ లేక సంవాద రూపములో ఉన్న శ్రీమద్భగవద్గీతలోని  పదిహేనవఅధ్యాయమైన పురుషోత్తమయోగము సమాప్తమైనది"
  ],
  "adhyaya-16" : [
    "శ్రీ భగవానుడు పలికెను - నిర్భయత్వము, అంతఃకరణశుద్ధి, తత్త్వజ్ఞానప్రాప్తికై ధ్యానయోగమునందు నిరంతర దృఢస్థితి, సాత్వికదానము, ఇంద్రియనిగ్రహము, భగవంతుని, దేవతలను, గురుజనులను పూజించుట, అట్లే అగ్నిహోత్రాది ఉత్తమ కర్మాచరణము, వేదశాస్త్రములపఠన, పాఠనములు మఱియు భగవంతుని నామగుణకీర్తనములు, స్వధర్మాచరణమునందలి కష్టములను ఓర్చుకొనుట, శరీరేంద్రియాంతఃకరణముల సరళత్వము (ఆర్జవము). 16.1",

    "అహింస (మనోవాక్కాయముల ద్వారా ఎవ్వరికిని ఏ విధముగను కష్టమును కల్గింపకుండుట), సత్యము (యథార్థమైన, ప్రియమైన భాషణము), అక్రోధము (తనకు అపకారము చేయువారిపైనకూడ కోపము లేకుండుట), త్యాగము (కర్మాచరణమునందు కర్తృత్వాభిమానమును త్యజించుట), శాంతి (చిత్తచాంచల్యము లేకుండుట), అపైశునము (ఎవరినీ నిందింపకుండుట), దయ (అన్ని ప్రాణులయెడ నిర్హేతుక కృప), అలోలుప్త్యము (ఇంద్రియవిషయసంయోగము ఉన్నను వాటిపై ఆసక్తి లేకుండుట), మార్దవము (కోమలత్వము), శాస్త్ర విరుద్ధ కార్యాచరణమునకు వెనుకాడుట (సిగ్గుపడుట), అచాపలము (వ్యర్ధచేష్టలు చేయకుండుట)... 16.2",

    "ఓ అర్జునా! తేజస్సు, క్షమ, ధైర్యము, శౌచము (బాహ్యశుద్ధి), అద్రోహము (ఎవ్వరిపైనను శత్రుభావము లేకుండుట), అమానిత్వము (తాను పూజ్యుడనను అభిమానము లేకుండుట) మొదలగునవి యన్నియును దైవీసంపదగలవాని లక్షణములు. 16.3",

    "ఓ పార్థా! దంభము (కపటము), దర్పము (మొండితనము), అభిమానము, క్రోధము, పౌరుష్యము, (మాటల యందును, చేష్టలయందును కఠినత్వము), అజ్ఞానము మొదలగునవి అసురీ స్వభావముగలవాని లక్షణములు. 16.4",

    "ఓ అర్జునా! దైవీసంపద ముక్తిదాయకము. ఆసురీసంపద బంధహేతువు. నీవు దైవీసంపదతో పుట్టినవాడవు. కనుక శోకింపకుము. 16.5",

    "ఓ అర్జునా! ఈ లోకముననున్న మానవులు రెండువిధములుగా ఉందురు. దైవలక్షణములు గలవారు కొందఱు. ఆసురలక్షణములు గలవారు మఱికొందఱు. దైవ లక్షణములు విస్తృతముగా తెల్పబడినవి. ఇప్పుడు ఆసురలక్షణములుగలవారిని గూర్చి వివరముగా తెల్పెదను వినుము. 16.6",

    "ఆసురస్వభావముగలవారు ప్రవృత్తినివృత్తులను (కర్తవ్యాకర్తవ్యములను) ఎఱుగరు. కనుక వారిలో బాహ్యాభ్యంతర శుచిత్వముగాని, శ్రేష్ఠమైన ప్రవర్తనగాని, సత్యభాషణముగాని ఉండనే ఉండవు. 16.7",

    "ఈ జగత్తునకు ఆధారమైనది ఏదియును లేదనియు, ఇది అసత్యమనియు, భగవంతుడనెడివాడు లేనేలేడనియు, కామప్రేరితులైన స్త్రీపురుషులసంయోగ కారణముగ జీవులు సహజముగనే పుట్టుచున్నారనియు, కావున సృష్టికి కామము తప్ప మరొక కారణమే లేదనియు ఆసురలక్షణములు గలవారు భావింతురు. 16.8",

    "అసంబద్ధమైన ఇట్టి మిథ్యావాదముచేయుభౌతికవాదులు ఆత్మనుగూర్చి తలంపరు. (ఆత్మయొక్క అస్తిత్వమును విశ్వసింపరు) వారు మందబుద్ధులు. వారు అందఱికిని అపకారముచేయుక్రూరులు వారి శక్తిసామర్థ్యములు ప్రపంచ వినాశమునకే వినియోగపడుచుండును. 16.9",

    "దంభము, దురభిమానము, మదములతోగూడిన ఈ ఆసురలక్షణములు గలవారు యుక్తాయుక్తములను మరచి, తమవాంఛలను ఏదో విధముగ తీర్చుకొనుటకు సిద్ధపడుదురు అజ్ఞానకారణముగ మిథ్యాసిద్ధాంతములను ఆశ్రయింతురు. శాస్త్ర విరుద్ధముగా భ్రష్టాచారులై ప్రవర్తింతురు. 16.10",

    "మరణించువఱకును వారు అంతులేనిచింతలలోనే మునిగిపోవుచుందురు. విషయభోగానుభవముల యందే తత్పరులై అదియే నిజమైన సుఖమని భావింతురు. 16.11",

    "వారు ఆశాపాశపరంపరలచే ఎల్లప్పుడును బంధింపబడుచుందురు. కామక్రోధపరాయణులై ప్రవర్తింతురు. విషయభోగముల నిమిత్తమై, అన్యాయ మార్గములద్వారా ధనార్జనకు పాల్పడుచుందురు. 16.12",

    "“నేను మిక్కిలి పురుషార్థిని గనుక ఈ అభీష్టవస్తువును పొందితిని. ఇంకను నా మనోరథము లన్నింటిని సాధించుకొనగలను. ఇప్పటికే నాకడ ఎంతో ధనము ఉన్నది. మున్ముందు ఇంకను ఎంతోధనమును సంపాదింపగలను” అని వారు తలంచుచుందురు. 16.13",

    "నేను ఈ శత్రువును వధించితిని. ఇతర శత్రువులనుగూడ వధింపగలను. నేనే సర్వాధిపతిని. సమస్త సుఖ భోగములను అనుభవింపగలవాడను నేనే. సిద్ధులన్నియు నా గుప్పిటనే యున్నవి. నేనే గొప్ప బలవంతుడను, సుఖిని. 16.14",

    "“నేనే గొప్ప ధనవంతుడను, మిక్కిలి పరివారము గలవాడను. నాతో సమానుడు మరియొకడులేడు. నేను యజ్ఞములను చేయగలను. దానములు ఇయ్యగలను. యథేచ్ఛముగా వినోదింపగలను.” - అనుచు అనేక విధములుగా అజ్ఞానమోహితులై... 16.15",

    "చిత్తభ్రమణమునకు లోనై మోహజాలమునందు చిక్కుకొని, ఆసురలక్షణములు గలవారు విషయభోగములయందే మిక్కిలి ఆసక్తులై ఘోరనరకముల యందు పడిపోవుచుందురు. 16.16",

    "వారు తమకు తామే గొప్పవారమని భావించుకొనుచు, గర్వోన్మత్తులై, ధనదురహంకారములతో కన్నుమిన్నుగానక ప్రమత్తులై, శాస్త్రవిరుద్ధముగా ఆడంబరప్రధానముగా పేరుకు మాత్రమే యజ్ఞముల నాచరించుదురు. 16.17",

    "అహంకారము, బలము, దర్పము, కామము, క్రోధములకు వశులై, ఇతరులను నిందించుచు తమ శరీరములయందును, ఇతరులశరీరములయందును, అంతర్యామిగానున్న నన్ను ద్వేషించుచుందురు. 16.18",

    "అట్లు ఇతరులను ద్వేషించు పాపాత్ములను, క్రూరులైన నరాధములను మాటిమాటికిని ఈ సంసారమునందు ఆసురీయోనులలోనే నేను పడవేయుచుందును. 16.19",

    "ఓ అర్జునా! ఈ ఆసురీప్రకృతిగల మూఢులు, నన్ను పొందకయే, ప్రతిజన్మయందును ఆసురీయోనులనే పొందుచు చివరకు అంతకంటెను హీనమైనగతిని పొందుదురు. అనగా ఘోరమైన నరకములయందు పడెదరు. 16.20",

    "కామక్రోధలోభములు అను ఈ మూడును నరకద్వారములు. అవి ఆత్మ నాశమునకు కారణములు అనగా మనుజుని అధోగతిపాలుచేయునవి. కనుక ఈ మూడింటిని త్యజింపవలెను. 16.21",

    "ఓ అర్జునా! ఈ మూడునరకద్వారములనుండి బయటపడినవాడు శుభకర్మలనే ఆచరించును అందువలన పరమగతిని పొందును. అనగా నన్నే పొందును. 16.22",

    "శాస్త్రవిధిని త్యజించి, యథేచ్ఛగా (విశృంఖలముగా) ప్రవర్తించువాడు సిద్ధిని పొందజాలడు. వానికి ఇహపరలోకసుఖములు లభింపవు. పరమగతియు ప్రాప్తింపదు. 16.23",

    "కావున కర్తవ్యాకర్తవ్యములను నిర్ణయించుటకు శాస్త్రమే ప్రమాణము. కనుక శాస్త్రోక్తకర్మలను గూర్చి బాగుగా తెలిసికొని, అట్టి కర్మలను ఆచరింపుము. 16.24",

    "ఈ విధముగా ఓం తత్ సత్ అను భగవన్నామ ఉచ్చారణ పూర్వకమైన బ్రహ్మ విద్య మరియు యోగ శాస్త్ర మయమైన, శ్రీకృష్ణార్జునుల సంభాషణ లేక సంవాద రూపములో ఉన్న శ్రీమద్భగవద్గీతలోని పదహారవ అధ్యాయమైన దైవాసురసంపద్విభాగయోగము సమాప్తమైనది"
  ],
  "adhyaya-17" : [
    "అర్జునుడు పలికెను -  ఓ కృష్ణా! శాస్త్రవిధిని త్యజించి, భక్తిశ్రద్ధలతో యజ్ఞములను గాని, దైవపూజలనుగాని కొందఱు చేయుచుందురు. వారినిష్ఠ (వారి విధానము) సాత్త్వికమా? రాజసమా? తామసమా? 17.1",

    "శ్రీ భగవానుడు పలికెను -  శాస్త్రోక్తముగా నుండక కేవలము స్వభావమును అనుసరించి ఏర్పడుచుండు మానవుల శ్రద్ధ సాత్త్వికము, రాజసము, తామసము అని మూడు విధములుగా ఉండును. వాటిని గూర్చి వివరించెదను. వినుము. 17.2",

    "ఓ అర్జునా! మనుష్యులందఱి శ్రద్ధయు వారి అంతః కరణరీతులకు తగినట్లు ఉండును. ప్రతివ్యక్తికిని ఏదో ఒక శ్రద్ధఉండనే ఉండును. అతని జీవన విధాన మంతయును అతని శ్రద్ధకు అనుగుణముగా కొనసాగు చుండును. దానినిబట్టి అతడెట్టి శ్రద్ధ కలిగియున్నదియు తెలిసికొనవచ్చును. 17.3",

    "వారిలో సాత్త్వికులు దేవతలను, రాజసులు యక్ష రాక్షసులను, తామసులు ప్రేతభూతగణములను పూజించెదరు. 17.4",

    "దంభము, అహంకారము గలవారు, కోరికలు ఆసక్తి కలిగియుండువారు, బలగర్వితులు ఐనవారు, శాస్త్రవిరుద్ధముగా మనఃకల్పితమైన ఘోరతపస్సులను ఆచరించుచు... 17.5",

    "శరీరములయందున్న జీవులను, మరియుఅంతర్యామిగా అనగా పరమాత్ముడనైన నన్ను కృశింప జేయువారు అజ్ఞానులైన ఆసురస్వభావము గలవారని యెఱుంగుము. 17.6",

    "మనుష్యుల స్వభావములను అనుసరించి, వారికి ఇష్టములైన ఆహారములు గూడ మూడు విధములుగా ఉండును. అట్లే ఆయామనుష్యులు ఆచరించు యజ్ఞములు, తపస్సులు, దానములుగూడా మూడేసి విధములుగానే ఉండును. వాటినిగూర్చి వేర్వేఱుగా విశదపరచెదను వినుము. 17.7",

    "ఆయువు, బుద్ధి, బలము, ఆరోగ్యము, సుఖము, ప్రీతి మున్నగువానిని అభివృద్ధిపఱ చునవియు, పాలు, చక్కెర మొదలగు రసపదార్థములును, వెన్న, నెయ్యి మొదలగు స్నిగ్ధపదార్థములును, ఓజస్సును అభివృద్ధి పఱచు స్థిరపదార్థములును, సాత్విక స్వభావమును పెంచు  హృద్య పదార్థములును సాత్త్వికులకు ఇష్టమైనవి. 17.8",

    "చేదు, పులుపు, ఉప్పు, కారము, రుచులకు సంబంధించినవి, మిక్కిలి వేడి వస్తువులు, మాడిన పదార్థములు, దాహము కల్గించునవి, అట్లే దుఃఖము, చింత, రోగములను ఉత్పన్నము చేయు ఆహార పదార్థములు రాజసస్వభావము గలవారికి ఇష్టములగును. 17.9",

    "అర్ధపక్వములైన పదార్థములు (సరిగా ఉడకనివి, పండనివి) రసహీనములు, దుర్గంధయుక్తములు (చెడువాసన గలవి) పాసిపోయినపదార్థములు, ఎంగిలివస్తువులు, అపవిత్రపదార్థములు మొదలగునవి తామసస్వభావము గలవారికి ఇష్టమైనవి. 17.10",

    "శాస్త్రోక్తమైన దియు, ఈ యజ్ఞము నాకు కర్తవ్యము అని మనస్సున దృఢముగా నిశ్చయించుకొన బడినదియు, ప్రతిఫలాపేక్ష లేకుండ చేయబడునదియు ఐన యజ్ఞము సాత్త్వికయజ్ఞము అనబడును. 17.11",

    "కాని, ఓ అర్జునా! సరియైన నిష్టలేకుండ ఆడంబరము కొఱకు ఆచరింపబడునదియు, ప్రతిఫలాపేక్షతో చేయబడునదియు అగు యజ్ఞము రాజసయజ్ఞము అని ఎఱుంగుము. 17.12",

    "శాస్త్రవిధి ననుసరింపనిదియు, అన్నదానరహిత మైనదియు, మంత్ర హీనమైనదియు, దక్షిణలు లేనిదియు, శ్రద్ధారహితమైనదియు అగు యజ్ఞము ‘తామసయజ్ఞము’ అనబడును. 17.13",

    "దేవతలను, బ్రాహ్మణులను, గురుజనులను, జ్ఞానులను సేవించుట, పవిత్రత(శౌచము) నిరాడం బరత్వము, బ్రహ్మచర్యము, అహింస అనునవి శారీరక తపస్సులు. 17.14",

    "ఉద్వేగమును కలిగింపనిదియు, ప్రియమైనదియు, హితమును గూర్చునదియు, యథార్థమైనదియు అగు భాషణము, అట్లే వేదశాస్త్రపఠనము, పరమేశ్వరుని నామజప సాధన మొదలగునవి యన్నియును వాక్కునకు సంబంధించిన తపస్సులు. 17.15",

    "మనఃప్రసన్నత, శాంతస్వభావము, భగవచ్చింతన, మనోనిగ్రహము, అంతఃకరణశుద్ధి మొదలగునవి యన్నియును మానసికతపస్సులు. 17.16",

    "పూర్వోక్తములైన (శారీరిక, వాచిక, మానసిక) తపస్సులను ఫలాకాంక్షలేని యోగులు మిక్కిలి శ్రద్ధతో ఆచరించినప్పుడు వాటిని ‘సాత్త్విక తపస్సులు’ అని యందురు. 17.17",

    "ఇతరులనుండి సత్కారములను, గౌరవములను, పూజలను అందుకొనుటకును, అట్లే తదితర స్వార్థ ప్రయోజనముల కొఱకును, స్వాభావికముగాగాని, కల్పితముగా గానీ చేయబడునవియు, అనిశ్చితఫలములను గాని, క్షణికఫలములనుగాని ఇచ్చునవియు ఐన  తపస్సులను ‘రాజసతపస్సులు’ అని యందురు. 17.18",

    "మొండిపట్టుదలతో మనోవాక్కాయములకు బాధకలిగించునవియు, ఇతరులకు కీడు కల్గించుటకై చేయబడునవియు, ఐన తపస్సులను ‘తామసతపస్సులు’ అనియందురు. 17.19",

    "‘దానము చేయుటయే కర్తవ్యము’ అనుభావముతో తగిన ప్రదేశముల యందును, దుర్భిక్షాదికాలముల యందును, ఆకలిదప్పులతో బాధపడువారు, అంగవైకల్యముగలవారు, రోగులు మొదలగువారికిని, బ్రాహ్మణులు, పండితులు, బ్రహ్మచారులు, వానప్రస్థులు మొదలగు పాత్రులైనవారికిని  ప్రత్యుపకారమును ఆశింపక నిస్వార్థభావముతో చేయబడు దానము ‘సాత్త్వికదానము’అనబడును. 17.20",

    "కాని ప్రత్యుపకారమును ఆశించిగాని, ప్ర తిఫలాపేక్షతో గాని వివిధములగు ఒత్తిడులకు లోనైగాని బాధపడుచు విధిలేక ఇచ్చు దానమును ‘రాజసదానము’ అనియందురు. 17.21",

    "సద్భావము లేకుండా, దానము పుచ్చుకొనువారి యెడల గౌరవాదరములను చూపక, తృణీకార భావములతో ఛీకొట్టుచు, అయోగ్యులకును, అపాత్రులకును చేయబడు దానమూ, దేశకాలో చితము కాని దానమూ, ‘తామసదానము’ అని యనబడును. 17.22",

    "ఓమ్, తత్, సత్ అని మూడు విధములగు పేర్లు సచ్చిదానందఘన పరబ్రహ్మకు నిర్దేశించబడినవి. ఆ పరమాత్మనుండియే సృష్ట్యాదియందు బ్రాహ్మణులు, వేదములు, యజ్ఞములు ఏర్పడుట జరిగినది. 17.23",

    "కనుక వేదమంత్రములను పఠించువారు శాస్త్ర విహితములైన యజ్ఞదాన తపశ్చర్యలను సర్వదా ‘ఓమ్’ అను పరమాత్మనామమును ఉచ్చరించుచునే ప్రారంభింతురు. 17.24",

    "మోక్షకాంక్షగలవారు స్వలాభాపేక్ష లేశమాత్రమైనను లేకు లోకహితార్థమై యజ్ఞదానతపశ్చర్యలను ‘ఇదియంతయును పరమాత్మదే’ అను భావముతో ‘తత్’ అను నామమును ఉచ్చరించుచు చేయుదురు. 17.25",

    "ఓ పార్థా! ‘సత్’ అను పరమాత్మనామము సత్యభావమునందును, శ్రేష్ఠభావమునందును అనగా పరమాత్ముడు నిత్యుడు, శ్రేష్ఠుడు అను భావము నందును ప్రయోగింపబడుచుండును. ఉత్తమకర్మాచరణము నందును ‘సత్’ అను శబ్దము ప్రయుక్తమగుచుండును. 17.26",

    "యజ్ఞదానతపఃక్రియలయందలి నిష్ఠ, ఆస్తిక, భావమును ‘సత్’ అనియందురు. పరమాత్మను ఉద్దేశించి, చేయబడు నిశ్చయాత్మక కర్మలనుగూడ ‘సత్’ అని యందురు. 17.27",

    "ఓ అర్జునా! శ్రద్ధ (విశ్వాసము) లేకుండ చేయబడు హోమము, ఇయ్యబడు దానము, ఆచరింపబడు తపస్సు, ఇంకను జరుపబడు ఇతర శుభకర్మ లన్నియును, ‘అసత్’ అని చెప్పబడును. దానివలన జీవించియుండగా గాని మరణించిన పిదపగాని ఎట్టి ప్రయోజనమూ కలుగదు. 17.28",

    "ఈ విధముగా ఓం తత్ సత్ అను భగవన్నామ ఉచ్చారణ పూర్వకమైన బ్రహ్మ విద్య మరియు యోగ శాస్త్ర మయమైన, శ్రీకృష్ణార్జునుల సంభాషణ లేక సంవాద రూపములో ఉన్న శ్రీమద్భగవద్గీతలోని పదిహేడవ అధ్యాయమైన శ్రద్ధాత్రయవిభాగయోగము సమాప్తమైనది"
  ],
  "adhyaya-18" : [
    "అర్జునుడు పలికెను -  ఓ మహాబాహూ!అంతర్యామీ! వాసుదేవా! సన్న్యాసతత్త్వమును,  త్యాగతత్త్వమును వేర్వేరుగా  తెలిసికొనగోరెదను. 18.1",

    "శ్రీ భగవానుడు పలికెను - కామ్యకర్మల త్యాగమునే సన్న్యాసమని కొందరు పండితులు తలంతురు. కాని విచక్షణాశీలురైన మరికొందరు మాత్రము సర్వకర్మ ఫలములను త్యజించుటను త్యాగమని పేర్కొందురు. 18.2",

    "కొందరు విద్వాంసులు కర్మలన్నియును దోషయుక్తములేగావున వాటిని త్యజింపవలెనని యందురు. కానీ యజ్ఞదాన తపశ్చర్యాది కర్మలు త్యాజ్యములు కావని మరికొందరందురు. 18.3",

    "ఓ పురుషశ్రేష్ఠా! అర్జునా! సన్యాసము, త్యాగము అను రెండు విషయములలో మొదట త్యాగమునుగూర్చి నా నిశ్చయమును వినుము. త్యాగము సాత్త్వికము, రాజసము, తామసము అని మూడువిధములుగా చెప్పబడినది. 18.4",

    "యజ్ఞదానతపశ్చర్యాది కర్మలు త్యజింపదగవు, అవి  అవశ్యము అనుష్ఠింపదగినవి. ఏలనన యజ్ఞదానతపస్సులు అను ఈ మూడు కర్మలే బుద్ధిమంతులైన వారిని పవిత్రమొనర్చును. 18.5",

    "కావున ఓ పార్థా! 'ఈ యజ్ఞదాన తపోరూపకర్మలను మరియు కర్తవ్యకర్మలను అన్నింటిని ఫలాసక్తులను త్యజించి, అవశ్యమాచరింపవలెను' అనునది ఉత్తమమైన నా నిశ్చితాభిప్రాయము. 18.6",

    "(నిషిద్ధ కర్మల, కామ్యకర్మలఆచరణను త్యాగముచేయుట సముచితమే.)  కాని శాస్త్ర విహిత కార్మాచరణమును త్యజించుట ఉచితముగాదు. కావున మోహవశమున దానిని త్యజించుట 'తామస త్యాగము' అనబడును. 18.7",

    "కర్మలన్నియును దుఃఖకారకములే యని భావించి, శారీరికక్లేశమునకు భయపడి, కర్తవ్యకర్మలను త్యజించుటను రాజసత్యాగము అని యందురు. అట్టి త్యాగమువలన ఎట్టి ఫలమూ లభింపదు. 18.8",

    "ఓ అర్జునా! శాస్త్రవిహిత  కర్మలను కర్తవ్యములుగా భావించి వాటియందలి ఫలాసక్తులను త్యజించి  చేయుటయే సాత్త్వికత్యాగము అని భావింపబడును. 18.9",

    "అకుశలకర్మలను,  నిషిద్ధ కర్మలను, కామ్యకర్మలను ద్వేషింపనివాడు, కుశలకర్మలయందు (నిత్య నైమిత్తిక పవిత్ర కర్మలయందు) ఆసక్తి కలిగియుండనివాడు, శుద్ధసత్త్వగుణయుక్తుడు, సంశయరహితుడు, బుద్ధిమంతుడు ఐనవాడు నిజమైన త్యాగి. 18.10",

    "ప్రతి శరీరధారికిని కర్మలను సంపూర్ణముగా త్యజించుట అశక్యము. కావున కర్మలను గాక  కర్మఫలములను త్యజించినవాడే నిజమైన త్యాగి అని గ్రహింపవలెను. 18.11",

    "కర్మ ఫల త్యాగము చేయని మనుష్యుల కర్మలకైతే మంచి, చెడు, మిశ్రము అని మూడు విధములగు ఫలములుండును. మరణానంతరము వారు వాటిని తప్పక అనుభవించియే తీరవలయును. కాని కర్మఫలత్యాగమొనర్చిన కర్మయోగులు తమ కర్మల ఫలములను ఏ కాలమునందైనను, ఏ విధముగను అనుభవింపవలసిన పనియుండదు. 18.12",

    "ఓ మహాబాహూ! సర్వ కర్మల సిద్ధికి ఐదు హేతువులు  గలవని కర్మలను  అంతముచేయు ఉపాయములను  తెలుపు సాంఖ్యశాస్త్రమునందు పేర్కొనుట జరిగినది. వాటిని  నానుండి  నీవు స్పష్టముగా తెలుసుకొనుము. 18.13",

    "కర్మల సిద్ధియందు అధిష్ఠానము, కర్త, వివిధములైన కరణములు( సాధనములు)  నానావిధ చేష్టలు, దైవము అను  ఐదును హేతువులు. 18.14",

    "మానవుడు శరీరము, వాక్కు, మనస్సులతో ఆచరించు  శాస్త్రానుకూలమైన లేక  విపరీతమైన యేకర్మలైనను ఈ యైదు హేతువులతో ఒప్పుచుండును. 18.15",

    "అట్లైనప్పటికిని  (సర్వ కర్మలకును  ఐదు హేతువులే మూలమైనప్పటికిని) విపరీతబుద్ధికారణమున ఏ మనుష్యుడు  కేవలుడు (నిరంజనుడు), శుద్ధస్వరూపుడైన   ఆత్మను సమస్తకర్మలకు కర్తగా భావించునో, అట్టి  మలినబుద్ధిగల అజ్ఞాని యథార్థమును గ్రహింపలేడు. 18.16",

    "అంతఃకరణమునందు కర్తృత్వభావము లేనివానిబుద్ధి ప్రాపంచికపదార్థములయందును, కర్మలయందును  అంటుకొనదు. అట్టి పురుషుడు ఈ లోకములను  అన్నింటిని  హతమార్చినను  వాస్తవముగా  చంపినవాడు కాడు. అతనిని ఎట్టి పాపములును అంటవు. 18.17",

    "జ్ఞాత, జ్ఞానము, జ్ఞేయము అనునవి మూడు విధములైన కర్మ ప్రేరణములు. కర్త, కరణము, క్రియ అని కర్మసంగ్రహములు మూడు విధములు. 18.18",

    "గుణముల సంఖ్యను వివరించు సాంఖ్య శాస్త్రమునందు జ్ఞానము, కర్మ, కర్త అనునవి గుణభేదములతో మూడేసి విధములుగా పేర్కొనబడినది. వానిని గూర్చి విశదపరచెదను, వినుము. 18.19",

    "వేర్వేరుగా కన్పించు సమస్తప్రాణులయందును శాశ్వతుడైన పరమాత్మయే  విభాగరహితుడుగా సమభవాముతో స్థితుడైయునట్లు జ్ఞానియైన వాడు చూచును. అట్టి  పురుషుని జ్ఞానమును సాత్త్విక జ్ఞానముగా తెలుసికొనుము. 18.20",

    "సమస్తప్రాణులయందును నానావిధములైన వివిధ భావములను వేర్వేరుగా భావించువారి జ్ఞానమును రాజసము అని యెరుంగుము. 18.21",

    "ప్రకృతి కార్యమైనా  శరీరమునే  (శరీరమునకు సంబంధించిన భౌతికవస్తువులనే)  సమస్తముగా భావించి, దానియందే ఆసక్తిని కల్గించునట్టిదియు, తాత్త్వికముగా అర్థరహితమైనదియు, హేతబద్ధము కానిదియు, తుచ్ఛమైనదియు  అగు విపరీత  జ్ఞానమును తామసము  అనియందురు. 18.22",

    "కర్తృత్వాభిమానము  గాని, ఫలాపేక్ష  గాని లేని పురుషుని  చేత  రాగద్వేషరహితముగా  చేయబడు  శాస్త్రవిహితమైన  కర్మను  సాత్వికకర్మయని  యందురు. 18.23",

    "భోగలాలసుడైన పురుషునిచేతను, అహంకారి చేతను చేయబడు మిక్కిలి శ్రమతో కూడిన కర్మను రాజసకర్మ అనియందురు. 18.24",

    "పరిణామము (మంచిచెడ్డలు) హాని, హింస, సామర్థ్యములను చూచుకొనక  కేవలము మూర్ఖత్వముచే (అజ్ఞానముచే )ఆరంభింపబడు కర్మలను తామసకర్మలు అనియందురు. 18.25",

    "ఆసక్తిని త్యజించినవాడు, అహంకారరహితముగా భాషించువాడు, ధైర్యోత్సాహములు గలవాడును సిద్ధి - అసిద్ధులయెడ హర్షశోకాదివికారములకు లోనుకానివాడును అగు పురుషుడు సాత్వికకర్త యనబడును. 18.26",

    "ఆసక్తియుతుడు, కర్మఫలములకై ఆరాటపడువాడు, లోభి, ఇతరులను కష్టపెట్టు స్వభావము గలవాడు, అపవిత్ర  ప్రవర్తన గలవాడు, హర్ష శోకములకు  లోనగువాడు రాజసకర్తగా భావింపబడును. 18.27",

    "జితేంద్రియుడు కానివాడు, సుశిక్షితుడు కానివాడు, మూర్ఖుడు (మొండివాడు), ధూర్తుడు, అకారణముగ ఇతరులవృత్తులకు  విఘాతము  కల్గించువాడు, సదా చింతాగ్రస్తుడు, సోమరి, కార్యాచరణమునందు ఉపేక్షతోకాలము గడుపుచుండువాడు (దీర్ఘ సూత్రి) - ఇట్టి లక్షణములు గలవానిని తామసకర్త అని యందురు. 18.28",

    "ఓ ధనంజయా! ఇప్పుడు నీవు బుద్ధి, ధృతులను గూడ గుణ భేదములననుసరించి మూడు విధములుగా, విభాగ పూర్వకముగా సంపూర్ణముగా నానుండి వినుము. 18.29",

    "ప్రవృత్తిమార్గమును, నివృత్తిమార్గమును, కర్తవ్యమును, అకర్తవ్యమును, భయమును, అభయమును, అట్లే బంధమును, మోక్షమును యథార్థముగా తెలిసికొనుబుద్ధిని సాత్వికమైన బుద్ధియందురు. 18.30",

    "ఓ పార్థా! ధర్మాధర్మములయొక్కయు, కార్యాకార్యములయొక్కయు (కర్తవ్యా కర్తవ్యముల యొక్కయు), యదార్థ తత్త్వములను తెలియజాలని బుద్ధిని 'రాజస బుద్ధి' అనియందురు. 18.31",

    "ఓ అర్జునా! తమోగుణావృతమైనందున అధర్మమును ధర్మ ముగును, అట్లే ఇతర పదార్థములను( విషయములను) తద్విపరీతముగను భావించు బుద్ధిని  ‘తామస బుద్ధి’ అనియందురు. 18.32",

    "ఓ పార్థా! ఇటునటు చలింపని ధారణాశక్తితో  మనుష్యుడు ధ్యానయోగముద్వారా మనఃప్రాణేంద్రియ క్రియలను ధారణచేయు శక్తిని  ‘సాత్త్వికధృతి’ యందురు. 18.33",

    "కాని, ఓ పార్థా! అర్జునా! కర్మఫలేచ్ఛగల మనుజుడు మిక్కిలి  ఆసక్తితో ధర్మార్థకామ విషయములను ధారణచేయు శక్తిని  ‘రాజసధృతి’ అనియందురు. 18.34",

    "పార్థా! నిద్ర, భయము, చింతాశోకములు, ఉన్మత్తతలను విడువక, దుర్మతియైన మనుష్యుడు వాటినే సతతము ధారణ చేయుచుండును. అట్టి ధృతిని,  ‘తామసధృతి’  అనియందురు. 18.35",

    "ఓ భరతశ్రేష్ఠా!  మూడు విధములగు సుఖములను గూర్చియు నేను చెప్పెదను వినుము. ఏ సుఖమునందు సాధకుడు భజనధ్యానసేవాదుల నొనర్చి ఆనందించునో, దుఃఖములను అతిక్రమించునో ..... 18.36",

    "...ప్రారంభమునందు విషతుల్యముగా (దుఃఖకరముగా) గోచరించినప్పటికిని పరిణామమున అమృతతుల్యమై పరమాత్మకు సంబంధించిన విషయములలో నిమగ్నమగునో, అట్టి సుఖమును ‘సాత్త్వికసుఖము’ అనియందురు. 18.37",

    "విషయేంద్రియ సంయోగము వలన కలుగు సుఖము మెుదట భోగానుభవ సమయమునందు అమృతతుల్యముగ అనిపించినను పరిణామమున  అది  విషతుల్యమగును. అట్టి సుఖమును  'రాజససుఖము’ అందురు. 18.38",

    "నిద్ర, సోమరితనము, ప్రమాదము( వెూహము)ల వలన ఉత్పన్నమగు సుఖమును తామససుఖము అనియందురు. ఇట్టి సుఖము భోగసమయమునందును, పరిణామమునందును ఆత్మను వెూహింపజేయుచుండును. 18.39",

    "పృథివియందు గాని, ఆకాశమునందుగాని, దేవతలయందుగాని, మఱేయితర లోకములయందుగాని,   ప్రకృతినుండి ఉత్పన్నములైన  ఈ  మూడు గుణములు లేకుండ ఏ ప్రాణియు ఉండదు. 18.40",

    "ఓ పరంతపా! బ్రాహ్మణ క్షత్రియ వైశ్యుల కర్మలనూ, అట్లే శూద్రుల కర్మలునూ వారివారి స్వాభావికములైన గుణములను బట్టి విభజింపబడియున్నవి. 18.41",

    "అంత:కరణనిగ్రహము( శమము ), ఇంద్రియములను వశమునందుంచుకొనుట (దమము), ధర్మ ములను పాటించుటలో ఎదురగు కష్టములను సహించుట, బాహ్యాభ్యంతరముల శుచిత్వము, ఇతరులఅపరాధములను క్షమించుట, ఋజుమార్గ జీవనము, ( మనశ్శరీరేంద్రియముల సరళత్వము) వేద శాస్త్రముల యందును ఈశ్వరునియందును పరలోకాదులయందును, విశ్వాసమును కలిగియుండుట, వేదశాస్త్రములఅధ్యయనము, అధ్యాపనము, పరతత్త్వాసుభవము - ఇవి యన్నియును బ్రాహ్మణులస్వాభావిక కర్మలు. 18.42",

    "శౌర్యము, తేజస్సు, ధైర్యము, దక్షత, యుద్ధమునందు వెన్ను చూపకుండుట, దానములనిచ్చుట, స్వామి భావముతో ప్రజలను ధర్మ పరాయణులనుగా  చేయుచు  పరిపాలించుట మొదలగునవి యన్నియును క్షత్రియులస్వాభావికకర్మలు. 18.43",

    "వ్యవసాయము, గోరక్షణము, క్రయవిక్రయరూప సత్యవ్యవహారము ఇవి యన్నియును వైశ్యులస్వాభావిక కర్మలు. అట్లే అన్ని వర్ణములవారిని సేవించుట శూద్రుల స్వాభావిక కర్మ. 18.44",

    "తమతమ స్వాభావికకర్మల యందు తత్పరులైనవారు భగవత్ప్రాప్తిరూప పరమసిద్ధిని నిస్సందేహమగా పొందుదురు. స్వకర్మ నిరతులైన మానవులు పరమసిద్ధిని పొందుటకు ఆచరింపవలసిన విధులను తెలుపుచున్నాను వినుము. 18.45",

    "సమస్త ప్రాణులప్రాదుర్భావము పరమేశ్వరునినుండియే జరిగినది. సమస్త జగత్తునందును అతడు వ్యాపించియున్నాడు. అట్టి పరమేశ్వరుని తన స్వాభావికకర్మాలద్వారా పూజించి, మానవుడు పరమసిద్ధిని పొందును. 18.46",

    "బాగుగా ఆచరింపబడిన పరధర్మముకంటెను గణరహితమైనను స్వధర్మాచరణమే శ్రేష్ఠమైనది. స్వభావమును అనుసరించి ( స్వధర్మ రూప వర్ణాశ్రమ ధర్మములను అనుసరించి ) కర్మలను ఆచరించు మనుష్యునకు ఎట్టి పాపములును ఏ మాత్రము అంటనే అంటవు. 18.47",

    "ఓ కౌంతేయా! (అర్జునా! ) దోషయుక్తమైనను సహజమైన కర్మను త్యజింపరాదు. ఏలనన! అగ్ని పొగచే ఆవరింపబడినట్లు కర్మలన్నియను ఏదేని ఒక దోషముతో కూడియే యుండును. 18.48",

    "ఓ అర్జునా! ప్రాపంచికవిషయములన్నింటియందు ఆసక్తి లేని వాడును, స్ప్రుహారహితుడును, అంతఃకరణమును జయించినవాడును అగు పురుషుడు సాంఖ్య యోగము ద్వారా పరమశ్రేష్ఠమైన నైష్కర్మ్యసిద్ధిని  పొందును. 18.49",

    "ఓ కౌంతేయా! జ్ఞానయోగము  యొక్క  పరానిష్ఠయైన నైష్కర్మ్య సిద్ధిని పొందు  విధమును,  తద్ద్వారా  మనుష్యుడు  బ్రహ్మప్రాప్తి  నందు రీతిని సంగ్రహాముగా తెల్పెదను వినుము. 18.50",

    "విశుద్ధమైన  బుద్ధిగలవాడై, శబ్దాదివిషయములను  త్యజించి,   సాత్త్వికధారణాశక్తిద్వారా   అంతఃకరణేంద్రియముల   సంయమము  కలిగి (యువ్నవాడు)... 18.51",

    "తేలికయైన  సాత్వికమైన  ఆహారమును మితముగా భుజించువాడు, (శబ్దాదివిషయములను  త్యజించి) పరిశుభ్రమైన  ప్రదేశమున  ఏకాంతముగా నివసించువాడు, మనోవాక్కాయములను అదుపులో ఉంచుకొనినవాడు, (రాగద్వేషములను సర్వథా త్యజించి )దృఢమైన వైరాగ్యమును సంపూర్ణముగా  ఆశ్రయించినవాడు ..... 18.52",

    "అహంకారమును, బలమును, దర్పమును, కామక్రోధములను, పరిగ్రహమును వదలిపెట్టి, నిరంతరము  ధ్యానయోగ పరాయణుడై  యుండువాడు, మమతా రహితుడు, శాంతియుతుడు ఐన పురుషుడు సచ్చిదానంద పరబ్రహ్మము నందు అభిన్నభావముతో స్థితుడగుటకు పాత్రుడగును. 18.53",

    "సచ్చిదానంద ఘనపరబ్రహ్మయందు   ఏకీభావస్థితుడై ప్రసన్నమస్కుడైన యోగి  దేనికిని  శోకింపడు. దేనినీ కాంక్షింపడు. సమస్తప్రాణులయందును  సమభావము గల అట్టి యోగి నా పరాభక్తిని పొందును. 18.54",

    "బ్రహ్మభూతుడైన యోగి ఈ పరాభక్తి ద్వారా  నేనెవరినో? ఎంతటివాడనో?   యథాతథముగ  నా తత్త్వమును  తెలిసికొనును.  అట్లు భక్తితో నా తత్త్వమును గ్రహించిన వెంటనే అతడు నాలో లీనమగును. 18.55",

    "సమస్తకర్మలయందును    కర్తృత్వ భావమును   వీడి,  ఆయాకర్మల   ఫలరూపమైన   సమస్తభోగములను త్యజించి, నన్నే ఆశ్రయించిన   కర్మయోగి   కర్మలను   అన్నింటిని  సర్వదాచేయుచును   నాయనుగ్రహముచే   సనాతనమైన శాశ్వతమైన పరమపదమును  పొందును. 18.56",

    "సర్వకర్మలను నిండుమనస్సుతో  నాకే అర్పించి,  సమబుద్ధి  రుపయోగమును  అవలంబించి, మత్పరాయణుడవై  సతతము చిత్తమును నాయందే నిల్పుము. 18.57",

    "పైన తెలుపబడిన విధముగా నాయందు చిత్తమును నిల్పినచో, నా  యనుగ్రహమువలన  సమస్తసంకటముల నుండియు అనాయాసముగా బయట పడగలవు. ఒకవేళ అహంకార కారణమున నా వచనములను పెడచెవిన బెట్టినచో నష్టముల పాలగుదువు. అనగా పరమార్థ పథమునుండి  భ్రష్టుడవగుదువు. 18.58",

    "అహంకారవశమున  'నేను ఈ యుద్ధమును  చేయను' అని నీవు నిశ్చయించు కొనుట వృథా. ఏలనన నీ స్వభావమే యుద్ధముచేయుటకు నిన్ను పురికొల్పును. 18.59",

    "ఓ కౌంతేయా! మోహప్రభావమున నీవు చేయుటకు ఇష్టపడని కర్మను గూడ నీ పురాకృత స్వాభావిక కర్మలచే (సంస్కారములచే) బంధింపబడి, త  తత్ప్రభావమున నీవు అవశుడవై చేయుదువు. 18.60",

    "అర్జునా! శరీరరూపయంత్రములను అధిరోహించిన సర్వప్రాణుల హృదయములయందు అంతర్యామిగానున్న పరమేశ్వరుడు తన మాయచేత వారి వారి కర్మలను అనుసరించి, వారిని భ్రమింపజేయుచున్నాడు. 18.61",

    "ఓ భారతా! (అర్జునా!) అన్ని విధములుగా ఆ పరమేశ్వరునే శరణుజొచ్చుము. అతని కృపచేతనే పరమశాంతిని, శాశ్వతమైన పరమపదమును పొందగలవు. 18.62",

    "ఈ విధముగా అత్యంతరహస్యమైన  జ్ఞానమును నేను నీకు అందించితిని. ఇప్పుడు నీవు ఈ  పరమగోప్యమైన జ్ఞానమును పూర్తిగా గ్రహించి, నీకిష్టమైన రీతిగా ఆచరింపుము. 18.63",

    "సమస్తగోప్యవిషయములయందును పరమగోప్యమైన నావచనములను మఱొక్కసారి వినుము. నీవు నాకు అత్యంతప్రియుడవు అగుటవలన నీకు మిక్కిలి హితమును గూర్చు వచనములను మఱల చెప్పుచున్నాను. 18.64",

    "ఓ అర్జునా! నీవు నాయందే మనస్సును నిలుపుము. నా భక్తుడవు కమ్ము. నన్నే సేవింపుము. నాకు ప్రణమిల్లుము. ఇట్టు చేయుటవలన నన్నే పొందగలవు. ఇది ప్రతిజ్ఞా పూర్వకముగా నేను చెప్పుచున్నమాట. ఏలనన నీవు నాకత్యంత ప్రియుడవు. 18.65",

    "సర్వ ధర్మములను అనగా సమస్తకర్తవ్యకర్మలను నాకు సమర్పింపుము. సర్వ శక్తిమంతుడను, సర్వధారుడను, పరమేశ్వరుడను ఐన  నన్నే శరణుజొచ్చుము. అన్ని పాపములనుండియు నిన్ను నేను విముక్తుని గావించెదను. నీవు శోకింపకుము. 18.66",

    "తపస్సంపన్నుడు కానివానికిని, భక్తిరహితునకును, వినవలెనను కుతూహలము లేనివానికిని నీవు ఈ గీతారూపపరహస్యోపదేశమును ఎన్నడును తెల్పరాదు. అట్టే నాయందు దోషదృష్టి గలవానికి ఎన్నడునూ ఈ ఉపదేశమును తెలపరాదు. 18.67",

    "నా యందు పరమభక్తి గలిగి, ఈ పరమగోప్యమైన గీతోపదేశమును అనగా గీతాశాస్త్రమును నా భక్తుల హృదయములలో పదిలపఱచువాడు నన్నే పొందును. ఇందేమాత్రమూ సందేహము లేదు. 18.68",

    "నాకు ప్రీతిని గూర్చునట్టి కర్మల నాచరించు మనుష్యులలో అతనిని మించిన భక్తుడెవ్వడును లేడు. అంతేగాక అతనివలె గాని, అతనిని మించిగాని నాకు ప్రియమైన వాడు భూమండలమున మరియెుకడెవ్వడును భవిష్యత్తులోను ఉండబోడు. 18.69",

    "ధర్మయుక్తమైన మనసంవాదరూపమైన ఈ గీతాశాస్త్రమును  పఠించువాడు జ్ఞానయజ్ఞముద్వారా నన్ను పూజించువాడని నా అభిప్రాయము. 18.70",

    "శ్రద్ధాదరములుగలవాడును, దోషదృష్టి (దోషములెన్ను స్వభావము) లేనివాడును ఐన మనుష్యుడు గీతాశాస్త్రమును వినుటవలనగూడ పాపవిముక్తుడై, పుణ్యకర్మలను ఆచరించువారుపొందు ఉత్తమలోకములను పొందును. 18.71",

    "ఓపార్థా! ఈ గీతాశాస్త్రమును నీవు ఏకాగ్రచిత్తముతో వింటివా? ఓ ధనంజయా! అజ్ఞానజనితమైన నీ వెూహము పూర్తిగా నశించినదా? 18.72",

    "అర్జునుడు పలికెను - ఓ అచ్యుతా! నీ కృపచే నా వెూహము పూర్తిగా తొలిగినది. స్మృతిని పొందితిని. ఇప్పుడు సంశయ రహితుడైతిని. కనుక నీ ఆజ్ఞను తలదాల్చెదను. 18.73",

    "సంజయుడు పలికెను - ఈ విధముగా శ్రీ వాసుదేవునకును మహాత్ముడైన అర్జునకును మధ్య జరిగిన సంవాదమును వింటివి. అది అద్భుతమైనది. తనువును పులకింపజేయునది. 18.74",

    "వేదవ్యాసునికృపవలన దివ్యదృష్టిని పొందినవాడనై, పరమగోప్యమైన ఈ యోగమును ( గీతను) యోగేశ్వరుడైన శ్రీ కృష్ణభగవానుడు( స్వయముగ)అర్జునునకు చెప్పుచుండగా నేను ప్రత్యక్షముగా వింటిని. 18.75",

    "ఓ రాజా! (ధృతరాష్ట్రా!) శ్రీ కృష్ణభగవానునకును అర్జునకును మధ్య  జరిగిన గోప్యమైన ఈ సంవాదము పుణ్యప్రదమైనది. అత్యద్భుతమైన ఈ సంవాదమును అనుక్షణము పదేపదే స్మరించుచు నేను నిరంతరము ఆనందమున ఓలలాడుచున్నాను. 18.76",

    "ఓ రాజా! అత్యంత విలక్షణము, పరమాద్భుతము, అపూర్వమైన  ఐన ఆ శ్రీహరిరూపమును పదేపదే స్మరించుచు, నేను పొందుచున్న సంభ్రమాశ్చర్యములకు అవధియే లేదు. తత్ప్రభావమున మఱలమఱల హర్షోల్లాసములతో పులకితగాత్రుడనగుచున్నాను. 18.77",

    "ఓ రాజా! యోగేశ్వరుడైన  శ్రీ కృష్ణభగవానుడును, గాండీవధనుర్ధారియైన అర్జునుడును ఉండుచోట సంపదలును, సర్వ విజయములును, సకలైశ్వర్యములును, సుస్థిరమైన నీతియు 18.78",

    "ఈ విధముగా ఓం తత్ సత్ అను భగవన్నామ ఉచ్చారణ పూర్వకమైన బ్రహ్మ విద్య మరియు యోగ శాస్త్ర మయమైన, శ్రీకృష్ణార్జునుల సంభాషణ లేక సంవాద రూపములో ఉన్న శ్రీమద్భగవద్గీతలోని పదినెనిమిదవ అధ్యాయమైన మోక్షసన్న్యాసయోగము సమాప్తమైనది"
  ]
  }
]

    export default GeetajiTelugu