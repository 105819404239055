const GeetajiNepali = [
{
  "adhyaya-1" : [
    "धृतराष्ट्र उवाच\nधर्मक्षेत्रे कुरुक्षेत्रे, समवेता युयुत्सवः।\nमामकाः(फ्) पाण्डवाश्चैव, किमकुर्वत सञ्जय॥1.1॥",
    
    "सञ्जय उवाच\nदृष्ट्वा तु पाण्डवानीकं(व्ँ), व्यूढं(न्) दुर्योधनस्तदा।\nआचार्यमुपसङ्गम्य, राजा वचनमब्रवीत्॥1.2॥",
    
    "पश्यैतां(म्) पाण्डुपुत्राणाम्, आचार्य महतीं(ञ्) चमूम्।\nव्यूढां(न्) द्रुपदपुत्रेण, तव शिष्येण धीमता॥1.3॥",
    
    "अत्र शूरा महेष्वासा, भीमार्जुनसमा युधि।\nयुयुधानो विराटश्च, द्रुपदश्च महारथः॥1.4॥",
    
    "धृष्टकेतुश्चेकितानः(ख्), काशिराजश्च वीर्यवान्।\nपुरुजित्कुन्तिभोजश्च, शैब्यश्च नरपुङ्गवः॥1.5॥",
    
    "युधामन्युश्च विक्रान्त, उत्तमौजाश्च वीर्यवान्।\nसौभद्रो द्रौपदेयाश्च, सर्व एव महारथाः॥1.6॥",
    
    "अस्माकं(न्) तु विशिष्टा ये, तान्निबोध द्विजोत्तम।\nनायका मम सैन्यस्य, सञ्ज्ञार्थं(न्) तान्ब्रवीमि ते॥1.7॥",
    
    "भवान्भीष्मश्च कर्णश्च, कृपश्च समितिञ्जयः।\nअश्वत्थामा विकर्णश्च, सौमदत्तिस्तथैव च॥1.8॥",
    
    "अन्ये च बहवः(श्) शूरा, मदर्थे त्यक्तजीविताः।\nनानाशस्त्रप्रहरणाः(स्), सर्वे युद्धविशारदाः॥1.9॥",
    
    "अपर्याप्तं(न्) तदस्माकं(म्), बलं(म्) भीष्माभिरक्षितम्।\nपर्याप्तं(न्) त्विदमेतेषां(म्), बलं(म्) भीमाभिरक्षितम्॥1.10॥",
    
    "अयनेषु च सर्वेषु, यथाभागमवस्थिताः।\nभीष्ममेवाभिरक्षन्तु, भवन्तः(स्) सर्व एव हि॥1.11॥",
    
    "तस्य सञ्जनयन्हर्षं(ङ्), कुरुवृद्धः(फ्) पितामहः।\nसिंहनादं(व्ँ) विनद्योच्चैः(श्), शङ्खं(न्) दध्मौ प्रतापवान्॥1.12॥",
    
    "ततः(श्) शङ्खाश्च भेर्यश्च, पणवानकगोमुखाः।\nसहसैवाभ्यहन्यन्त, स शब्दस्तुमुलोऽभवत्॥1.13॥",
    
    "ततः(श्) श्वेतैर्हयैर्युक्ते, महति स्यन्दने स्थितौ।\nमाधवः(फ्) पाण्डवश्चैव, दिव्यौ शङ्खौ प्रदध्मतुः॥1.14॥",
    
    "पाञ्चजन्यं(म्) हृषीकेशो, देवदत्तं(न्) धनञ्जयः।\nपौण्ड्रं(न्) दध्मौ महाशङ्खं(म्), भीमकर्मा वृकोदरः॥1.15॥",
    
    "अनन्तविजयं(म्) राजा, कुन्तीपुत्रो युधिष्ठिरः।\nनकुलः(स्) सहदेवश्च, सुघोषमणिपुष्पकौ॥1.16॥",
    
    "काश्यश्च परमेष्वासः(श्), शिखण्डी च महारथः।\nधृष्टद्युम्नो विराटश्च, सात्यकिश्चापराजितः॥1.17॥",
    
    "द्रुपदो द्रौपदेयाश्च, सर्वशः(फ्) पृथिवीपते।\nसौभद्रश्च महाबाहुः(श्), शङ्खान्दध्मुः(फ्) पृथक् पृथक्॥1.18॥",
    
    "स घोषो धार्तराष्ट्राणां(म्), हृदयानि व्यदारयत्।\nनभश्च पृथिवीं(ञ्) चैव, तुमुलो व्यनुनादयन्॥1.19॥",
    
    "अथ व्यवस्थितान्दृष्ट्वा, धार्तराष्ट्रान्कपिध्वजः।\nप्रवृत्ते शस्त्रसम्पाते, धनुरुद्यम्य पाण्डवः॥1.20॥",
    
    "हृषीकेशं(न्) तदा वाक्यम्, इदमाह महीपते।\nअर्जुन उवाच\nसेनयोरुभयोर्मध्ये, रथं(म्) स्थापय मेऽच्युत॥1.21॥",
    
    "यावदेतान्निरीक्षेऽहं(य्ँ), योद्धुकामानवस्थितान्।\nकैर्मया सह योद्धव्यम्,  अस्मिन्रणसमुद्यमे॥1.22॥",
    
    "योत्स्यमानानवेक्षेऽहं(य्ँ), य एतेऽत्र समागताः।\nधार्तराष्ट्रस्य दुर्बुद्धे:(र्), युद्धे प्रियचिकीर्षवः॥1.23॥",
    
    "सञ्जय उवाच\nएवमुक्तो हृषीकेशो, गुडाकेशेन भारत।\nसेनयोरुभयोर्मध्ये, स्थापयित्वा रथोत्तमम्॥1.24॥",
    
    "भीष्मद्रोणप्रमुखतः(स्), सर्वेषां(ञ्) च महीक्षिताम्।\nउवाच पार्थ पश्यैतान्, समवेतान्कुरूनिति॥1.25॥",
    
    "तत्रापश्यत्स्थितान्पार्थः(फ्), पितॄनथ पितामहान्।\nआचार्यान्मातुलान्भ्रातॄन्, पुत्रान्पौत्रान्सखींस्तथा॥1.26॥",
    
    "श्वशुरान्सुहृदश्चैव, सेनयोरुभयोरपि।\nतान्समीक्ष्य स कौन्तेयः(स्), सर्वान्बन्धूनवस्थितान्॥1.27॥",
    
    "कृपया परयाविष्टो, विषीदन्निदमब्रवीत्।\nअर्जुन उवाच\nदृष्ट्वेमं(म्) स्वजनं(ङ्) कृष्ण, युयुत्सुं(म्) समुपस्थितम्॥1.28॥",
    
    "सीदन्ति मम गात्राणि, मुखं(ञ्) च परिशुष्यति।\nवेपथुश्च शरीरे मे, रोमहर्षश्च जायते॥1.29॥",
    
    "गाण्डीवं(म्) स्रंसते हस्तात्, त्वक्चैव परिदह्यते।\nन च शक्नोम्यवस्थातुं(म्), भ्रमतीव च मे मनः॥1.30॥",
    
    "निमित्तानि च पश्यामि, विपरीतानि केशव।\nन च श्रेयोऽनुपश्यामि, हत्वा स्वजनमाहवे॥1.31॥",
    
    "न काङ्क्षे विजयं(ङ्) कृष्ण, न च राज्यं(म्) सुखानि च।\nकिं(न्) नो राज्येन गोविन्द, किं(म्) भोगैर्जीवितेन वा॥1.32॥",
    
    "येषामर्थे काङ्क्षितं(न्) नो, राज्यं(म्) भोगाः(स्) सुखानि च।\nत इमेऽवस्थिता युद्धे, प्राणांस्त्यक्त्वा धनानि च॥1.33॥",
    
    "आचार्याः(फ्) पितरः(फ्) पुत्रा:(स्), तथैव च पितामहाः।\nमातुलाः(श्) श्वशुराः(फ्) पौत्राः(श्), श्यालाः(स्) सम्बन्धिनस्तथा॥1.34॥",
    
    "एतान्न हन्तुमिच्छामि, घ्नतोऽपि मधुसूदन।\nअपि त्रैलोक्यराज्यस्य, हेतोः(ख्) किं(न्) नु महीकृते॥1.35॥",
    
    "निहत्य धार्तराष्ट्रान्नः(ख्), का प्रीतिः(स्) स्याज्जनार्दन।\nपापमेवाश्रयेदस्मान्, हत्वैतानाततायिनः॥1.36॥",
    
    "तस्मान्नार्हा वयं(म्) हन्तुं(न्), धार्तराष्ट्रान्स्वबान्धवान्।\nस्वजनं(म्) हि कथं(म्) हत्वा, सुखिनः(स्) स्याम माधव॥1.37॥",
    
    "यद्यप्येते न पश्यन्ति, लोभोपहतचेतसः।\nकुलक्षयकृतं(न्) दोषं(म्), मित्रद्रोहे च पातकम्॥1.38॥",
    
    "कथं(न्) न ज्ञेयमस्माभिः(फ्), पापादस्मान्निवर्तितुम्।\nकुलक्षयकृतं(न्) दोषं(म्), प्रपश्यद्भिर्जनार्दन॥1.39॥",
    
    "कुलक्षये प्रणश्यन्ति, कुलधर्माः(स्) सनातनाः।\nधर्मे नष्टे कुलं(ङ्) कृत्स्नम्, अधर्मोऽभिभवत्युत॥1.40॥",
    
    "अधर्माभिभवात्कृष्ण, प्रदुष्यन्ति कुलस्त्रियः।\nस्त्रीषु दुष्टासु वार्ष्णेय, जायते वर्णसङ्करः॥1.41॥",
    
    "सङ्करो नरकायैव, कुलघ्नानां(ङ्) कुलस्य च।\nपतन्ति पितरो ह्येषां(ल्ँ),  लुप्तपिण्डोदकक्रियाः॥1.42॥",
    
    "दोषैरेतैः(ख्) कुलघ्नानां(व्ँ), वर्णसङ्करकारकैः।\nउत्साद्यन्ते जातिधर्माः(ख्), कुलधर्माश्च शाश्वताः॥1.43॥",
    
    "उत्सन्नकुलधर्माणां(म्), मनुष्याणां(ञ्) जनार्दन।\nनरकेऽनियतं(व्ँ) वासो, भवतीत्यनुशुश्रुम॥1.44॥",
    
    "अहो बत महत्पापं(ङ्), कर्तुं(व्ँ) व्यवसिता वयम्।\nयद्राज्यसुखलोभेन, हन्तुं(म्) स्वजनमुद्यताः॥1.45॥",
    
    "यदि मामप्रतीकारम्, अशस्त्रं(म्) शस्त्रपाणयः।\nधार्तराष्ट्रा रणे हन्यु:(स्), तन्मे क्षेमतरं(म्) भवेत्॥1.46॥",
    
    "सञ्जय उवाच\nएवमुक्त्वार्जुनः(स्) सङ्ख्ये , रथोपस्थ उपाविशत्।\nविसृज्य सशरं(ञ्) चापं(म्), शोकसंविग्नमानसः॥1.47॥",
  
    "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु ब्रह्मविद्यायां(य्ँ) योगशास्त्रे\nश्रीकृष्णार्जुनसंवादे अर्जुनविषादयोगो नाम प्रथमोऽध्याय:।।"
  ],
  "adhyaya-2" : [
    "सञ्जय उवाच\nतं(न्) तथा कृपयाविष्टम्, अश्रुपूर्णाकुलेक्षणम्।\nविषीदन्तमिदं(व्ँ) वाक्यम्, उवाच मधुसूदनः॥2.1॥",
    
    "श्रीभगवानुवाच\nकुतस्त्वा कश्मलमिदं(व्ँ), विषमे समुपस्थितम्।\nअनार्यजुष्टमस्वर्ग्यम्,     अकीर्तिकरमर्जुन॥2.2॥",
    
    "क्लैब्यं(म्) मा स्म गमः(फ्) पार्थ, नैतत्त्वय्युपपद्यते ।\nक्षुद्रं(म्) हृदयदौर्बल्यं(न्), त्यक्त्वोत्तिष्ठ परन्तप॥2.3॥",
    
    "अर्जुन उवाच\nकथं(म्) भीष्ममहं(म्) सङ्ख्ये, द्रोणं(ञ्) च मधुसूदन।\nइषुभिः(फ्) प्रति योत्स्यामि, पूजार्हावरिसूदन॥2.4॥",
    
    "गुरूनहत्वा हि महानुभावान्,\nश्रेयो भोक्तुं(म्) भैक्ष्यमपीह लोके।\nहत्वार्थकामांस्तु गुरूनिहैव,\nभुञ्जीय  भोगान् रुधिरप्रदिग्धान्॥2.5॥",
    
    "न चैतद्विद्मः(ख्) कतरन्नो गरीयो,\nयद्वा जयेम यदि वा नो जयेयुः।\nयानेव हत्वा न जिजीविषाम:(स्),\nतेऽवस्थिताः(फ्) प्रमुखे धार्तराष्ट्राः॥2.6॥",
    
    "कार्पण्यदोषोपहतस्वभावः(फ्),\nपृच्छामि त्वां(न्)  धर्मसम्मूढचेताः।\nयच्छ्रेयः(स्) स्यान्निश्चितं(म्) ब्रूहि तन्मे,\nशिष्यस्तेऽहं(म्) शाधि मां(न्) त्वां(म्) प्रपन्नम्॥2.7॥",
    
    "न हि प्रपश्यामि ममापनुद्याद्,\nयच्छोकमुच्छोषणमिन्द्रियाणाम्।\nअवाप्य भूमावसपत्नमृद्धं(म्),\nराज्यं(म्) सुराणामपि चाधिपत्यम्॥2.8॥",
    
    "सञ्जय उवाच\nएवमुक्त्वा हृषीकेशं(ङ्), गुडाकेशः(फ्) परन्तप।\nन योत्स्य इति गोविन्दम्, उक्त्वा तूष्णीं(म्) बभूव ह॥2.9॥",
    
    "तमुवाच हृषीकेशः(फ्), प्रहसन्निव भारत।\nसेनयोरुभयोर्मध्ये, विषीदन्तमिदं(व्ँ) वचः॥2.10॥",
    
    "श्रीभगवानुवाच\nअशोच्यानन्वशोचस्त्वं(म्), प्रज्ञावादांश्च भाषसे।\nगतासूनगतासूंश्च,  नानुशोचन्ति  पण्डिता:॥2.11॥",
    
    "न त्वेवाहं(ञ्) जातु नासं(न्), न त्वं(न्) नेमे जनाधिपाः।\nन चैव न भविष्यामः(स्), सर्वे वयमतः(फ्) परम्॥2.12॥",
    
    "देहिनोऽस्मिन्यथा देहे, कौमारं(य्ँ) यौवनं(ञ्) जरा।\nतथा देहान्तरप्राप्ति:(र्),    धीरस्तत्र न मुह्यति॥2.13॥",
    
    "मात्रास्पर्शास्तु कौन्तेय, शीतोष्णसुखदुःखदाः।\nआगमापायिनोऽनित्या:(स्), तांस्तितिक्षस्व भारत॥2.14॥",
    
    "यं(म्) हि न व्यथयन्त्येते, पुरुषं(म्) पुरुषर्षभ।\nसमदुःखसुखं(न्) धीरं(म्), सोऽमृतत्वाय कल्पते॥2.15॥",
    
    "नासतो विद्यते भावो, नाभावो विद्यते सतः।\nउभयोरपि दृष्टोऽन्त:(स्), त्वनयोस्तत्त्वदर्शिभिः॥2.16॥",
    
    "अविनाशि तु तद्विद्धि, येन सर्वमिदं(न्) ततम्।\nविनाशमव्ययस्यास्य,      न कश्चित्कर्तुमर्हति॥2.17॥",
    
    "अन्तवन्त इमे देहा, नित्यस्योक्ताः(श्) शरीरिणः।\nअनाशिनोऽप्रमेयस्य,    तस्माद्युध्यस्व भारत॥2.18॥",
    
    "य एनं(व्ँ) वेत्ति हन्तारं(य्ँ), यश्चैनं(म्) मन्यते हतम्।\nउभौ तौ न विजानीतो, नायं(म्) हन्ति न हन्यते॥2.19॥",
    
    "न जायते म्रियते वा कदाचिन्,\nनायं (म्) भूत्वा भविता वा न भूयः।\nअजो नित्यः(श्) शाश्वतोऽयं(म्) पुराणो,\n न हन्यते हन्यमाने शरीरे॥2.20॥",
    
    "वेदाविनाशिनं(न्) नित्यं(य्ँ), य एनमजमव्ययम्।\nकथं(म्) स पुरुषः(फ्) पार्थ, कं(ङ्) घातयति हन्ति कम्॥2.21॥",
    
    "वासांसि जीर्णानि यथा विहाय,\nनवानि गृह्णाति नरोऽपराणि।\nतथा शरीराणि विहाय जीर्णा-\nन्यन्यानि संयाति नवानि देही॥2.22॥",
    
    "नैनं(ञ्) छिन्दन्ति शस्त्राणि, नैनं(न्) दहति पावकः।\nन चैनं(ङ्) क्लेदयन्त्यापो, न शोषयति मारुतः॥2.23॥",
    
    "अच्छेद्योऽयमदाह्योऽयम्, अक्लेद्योऽशोष्य एव च।\nनित्यः(स्) सर्वगतः(स्) स्थाणु:(र्), अचलोऽयं(म्) सनातनः॥2.24॥",
    
    "अव्यक्तोऽयमचिन्त्योऽयम्, अविकार्योऽयमुच्यते।\nतस्मादेवं(व्ँ) विदित्वैनं(न्), नानुशोचितुमर्हसि॥2.25॥",
    
    "अथ चैनं(न्) नित्यजातं(न्), नित्यं(व्ँ) वा मन्यसे मृतम्।\nतथापि त्वं(म्) महाबाहो, नैवं(म्) शोचितुमर्हसि॥2.26॥",
    
    "जातस्य हि ध्रुवो मृत्यु:(र्), ध्रुवं(ञ्) जन्म मृतस्य च।\nतस्मादपरिहार्येऽर्थे, न त्वं(म्) शोचितुमर्हसि॥2.27॥",
    
    "अव्यक्तादीनि भूतानि, व्यक्तमध्यानि भारत।\nअव्यक्तनिधनान्येव, तत्र का परिदेवना॥2.28॥",
    
    "आश्चर्यवत्पश्यति कश्चिदेनम्,\nआश्चर्यवद्वदति तथैव चान्यः।\nआश्चर्यवच्चैनमन्यः(श्) शृणोति,\nश्रुत्वाप्येनं(व्ँ) वेद न चैव कश्चित्॥2.29॥",
    
    "देही नित्यमवध्योऽयं(न्), देहे सर्वस्य भारत।\nतस्मात्सर्वाणि भूतानि, न त्वं(म्) शोचितुमर्हसि॥2.230॥",
    
    "स्वधर्ममपि चावेक्ष्य, न विकम्पितुमर्हसि।\nधर्म्याद्धि युद्धाच्छ्रेयोऽन्यत्, क्षत्रियस्य न विद्यते॥2.31॥",
    
    "यदृच्छया चोपपन्नं(म्), स्वर्गद्वारमपावृतम्।\nसुखिनः क्षत्रियाः(फ्) पार्थ, लभन्ते युद्धमीदृशम्॥2.32॥",
    
    "अथ चेत्त्वमिमं(न्) धर्म्यं(म्), सङ्ग्रामं(न्) न करिष्यसि।\nततः(स्) स्वधर्मं(ङ्) कीर्तिं(ञ्) च, हित्वा पापमवाप्स्यसि॥2.33॥",
    
    "अकीर्तिं(ञ्) चापि भूतानि, कथयिष्यन्ति तेऽव्ययाम्।\nसम्भावितस्य चाकीर्ति:(र्), मरणादतिरिच्यते॥2.34॥",
    
    "भयाद्रणादुपरतं(म्), मंस्यन्ते त्वां(म्) महारथाः।\nयेषां(ञ्) च त्वं(म्) बहुमतो, भूत्वा यास्यसि लाघवम्॥2.35॥",
    
    "अवाच्यवादांश्च बहून्, वदिष्यन्ति तवाहिताः।\nनिन्दन्तस्तव सामर्थ्यं(न्), ततो दुःखतरं(न्) नु किम्॥2.36॥",
    
    "हतो वा प्राप्स्यसि स्वर्गं(ञ्), जित्वा वा भोक्ष्यसे महीम्।\nतस्मादुत्तिष्ठ कौन्तेय, युद्धाय कृतनिश्चयः॥2.37॥",
    
    "सुखदुःखे समे कृत्वा, लाभालाभौ जयाजयौ।\nततो युद्धाय युज्यस्व, नैवं(म्) पापमवाप्स्यसि॥2.38॥",
    
    "एषा तेऽभिहिता साङ्ख्ये, बुद्धिर्योगे त्विमां(म्) शृणु।\nबुद्ध्या युक्तो यया पार्थ, कर्मबन्धं(म्) प्रहास्यसि॥2.39॥",
    
    "नेहाभिक्रमनाशोऽस्ति, प्रत्यवायो न विद्यते।\nस्वल्पमप्यस्य धर्मस्य, त्रायते महतो भयात्॥2.40॥",
    
    "व्यवसायात्मिका बुद्धि:(र्), एकेह कुरुनन्दन।\nबहुशाखा ह्यनन्ताश्च, बुद्धयोऽव्यवसायिनाम्॥2.41॥",
    
    "यामिमां(म्) पुष्पितां(व्ँ) वाचं(म्), प्रवदन्त्यविपश्चितः।\nवेदवादरताः(फ्) पार्थ, नान्यदस्तीति वादिनः॥2.42॥",
    
    "कामात्मानः(स्) स्वर्गपरा, जन्मकर्मफलप्रदाम्।\nक्रियाविशेषबहुलां(म्), भोगैश्वर्यगतिं(म्) प्रति॥2.43॥",
    
    "भोगैश्वर्यप्रसक्तानां(न्), तयापहृतचेतसाम्।\nव्यवसायात्मिका बुद्धिः(स्), समाधौ न विधीयते॥2.44॥",
    
    "त्रैगुण्यविषया वेदा, निस्त्रैगुण्यो भवार्जुन।\nनिर्द्वन्द्वो नित्यसत्त्वस्थो, निर्योगक्षेम आत्मवान्॥2.45॥",
    
    "यावानर्थ उदपाने, सर्वतः(स्) सम्प्लुतोदके।\nतावान्सर्वेषु वेदेषु, ब्राह्मणस्य विजानतः॥2.46॥",
    
    "कर्मण्येवाधिकारस्ते, मा फलेषु कदाचन।\nमा कर्मफलहेतुर्भू:(र्), मा ते सङ्गोऽस्त्वकर्मणि॥2.47॥",
    
    "योगस्थः(ख्) कुरु कर्माणि, सङ्गं(न्) त्यक्त्वा धनञ्जय।\nसिद्ध्यसिद्ध्योः(स्) समो भूत्वा, समत्वं(य्ँ) योग उच्यते॥2.48॥",
    
    "दूरेण ह्यवरं(ङ्) कर्म, बुद्धियोगाद्धनञ्जय।\nबुद्धौ शरणमन्विच्छ, कृपणाः(फ्) फलहेतवः॥2.49॥",
    
    "बुद्धियुक्तो जहातीह, उभे सुकृतदुष्कृते।\nतस्माद्योगाय युज्यस्व, योगः(ख्) कर्मसु कौशलम्॥2.50॥",
    
    "कर्मजं(म्) बुद्धियुक्ता हि, फलं(न्) त्यक्त्वा मनीषिणः।\nजन्मबन्धविनिर्मुक्ताः(फ्), पदं(ङ्) गच्छन्त्यनामयम्॥2.51॥",
    
    "यदा ते मोहकलिलं(म्), बुद्धिर्व्यतितरिष्यति।\nतदा गन्तासि निर्वेदं(म्), श्रोतव्यस्य श्रुतस्य च॥2.52॥",
    
    "श्रुतिविप्रतिपन्ना ते, यदा स्थास्यति निश्चला।\nसमाधावचला बुद्धि:(स्), तदा योगमवाप्स्यसि॥2.53॥",
    
    "अर्जुन उवाच\nस्थितप्रज्ञस्य का भाषा, समाधिस्थस्य केशव।\nस्थितधीः(ख्) किं(म्) प्रभाषेत, किमासीत व्रजेत किम्॥2.54॥",
    
    "श्रीभगवानुवाच\nप्रजहाति यदा कामान्, सर्वान्पार्थ मनोगतान्।\nआत्मन्येवात्मना तुष्टः(स्), स्थितप्रज्ञस्तदोच्यते॥2.55॥",
    
    "दुःखेष्वनुद्विग्नमनाः(स्), सुखेषु विगतस्पृहः।\nवीतरागभयक्रोधः(स्), स्थितधीर्मुनिरुच्यते॥2.56॥",
    
    "यः(स्) सर्वत्रानभिस्नेह:(स्), तत्तत्प्राप्य शुभाशुभम्।\nनाभिनन्दति न द्वेष्टि, तस्य प्रज्ञा प्रतिष्ठिता॥2.57॥",
    
    "यदा संहरते चायं(ङ्), कूर्मोऽङ्गानीव सर्वशः।\nइन्द्रियाणीन्द्रियार्थेभ्य:(स्), तस्य प्रज्ञा प्रतिष्ठिता॥2.58॥",
    
    "विषया विनिवर्तन्ते, निराहारस्य देहिनः।\nरसवर्जं(म्) रसोऽप्यस्य, परं(न्) दृष्ट्वा निवर्तते ॥2.59॥",
    
    "यततो ह्यपि कौन्तेय, पुरुषस्य विपश्चितः।\nइन्द्रियाणि प्रमाथीनि, हरन्ति प्रसभं(म्) मनः॥2.60॥",
    
    "तानि सर्वाणि संयम्य, युक्त आसीत मत्परः।\nवशे हि यस्येन्द्रियाणि, तस्य प्रज्ञा प्रतिष्ठिता॥2.61॥",
    
    "ध्यायतो विषयान्पुंस:(स्), सङ्गस्तेषूपजायते।\nसङ्गात्सञ्जायते कामः(ख्), कामात्क्रोधोऽभिजायते॥2.62॥",
    
    "क्रोधाद्भवति सम्मोह:(स्), सम्मोहात्स्मृतिविभ्रमः।\nस्मृतिभ्रंशाद् बुद्धिनाशो, बुद्धिनाशात्प्रणश्यति॥2.63॥",
    
    "रागद्वेषवियुक्तैस्तु, विषयानिन्द्रियैश्चरन्।\nआत्मवश्यैर्विधेयात्मा, प्रसादमधिगच्छति॥2.64॥",
    
    "प्रसादे सर्वदुःखानां(म्), हानिरस्योपजायते।\nप्रसन्नचेतसो ह्याशु, बुद्धिः(फ्) पर्यवतिष्ठते॥2.65॥",
    
    "नास्ति बुद्धिरयुक्तस्य, न चायुक्तस्य भावना।\nन चाभावयतः(श्) शान्ति:(र्), अशान्तस्य कुतः(स्) सुखम्॥2.66॥",
    
    "इन्द्रियाणां(म्) हि चरतां(य्ँ), यन्मनोऽनुविधीयते।\nतदस्य हरति प्रज्ञां(व्ँ), वायुर्नावमिवाम्भसि॥2.67॥",
    
    "तस्माद्यस्य महाबाहो, निगृहीतानि सर्वशः।\nइन्द्रियाणीन्द्रियार्थेभ्य:(स्), तस्य प्रज्ञा प्रतिष्ठिता॥2.68॥",
    
    "या निशा सर्वभूतानां(न्), तस्यां(ञ्) जागर्ति संयमी।\nयस्यां(ञ्) जाग्रति भूतानि, सा निशा पश्यतो मुनेः॥2.69॥",
    
    "आपूर्यमाणमचलप्रतिष्ठं(म्),\nसमुद्रमापः(फ्) प्रविशन्ति यद्वत्।\nतद्वत्कामा यं(म्) प्रविशन्ति सर्वे,\nस शान्तिमाप्नोति न कामकामी॥2.70॥",
    
    "विहाय कामान्यः(स्) सर्वान्, पुमांश्चरति निःस्पृहः।\nनिर्ममो निरहङ्कारः(स्), स शान्तिमधिगच्छति॥2.71॥",
    
    "एषा ब्राह्मी स्थितिः(फ्) पार्थ, नैनां(म्) प्राप्य विमुह्यति ।\nस्थित्वास्यामन्तकालेऽपि,  ब्रह्मनिर्वाणमृच्छति॥2.72॥",
  
    "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु ब्रह्मविद्यायां(य्ँ) योगशास्त्रे\nश्रीकृष्णार्जुनसंवादे साङ्ख्ययोगो नाम द्वितीयोऽध्यायः ॥२॥"
  ],
  "adhyaya-3" : [
    "अर्जुन उवाच\nज्यायसी चेत्कर्मणस्ते, मता बुद्धिर्जनार्दन।\nतत्किं(ङ्) कर्मणि घोरे मां(न्), नियोजयसि केशव॥3.1॥",
    
    "व्यामिश्रेणेव वाक्येन, बुद्धिं(म्) मोहयसीव मे।\nतदेकं(व्ँ) वद निश्चित्य, येन श्रेयोऽहमाप्नुयाम्॥3.2॥",
    
    "श्रीभगवानुवाच\nलोकेऽस्मिन्द्विविधा निष्ठा, पुरा प्रोक्ता मयानघ।\nज्ञानयोगेन साङ्ख्यानां(ङ्), कर्मयोगेन योगिनाम्॥3.3॥",
    
    " न कर्मणामनारम्भान्, नैष्कर्म्यं(म्) पुरुषोऽश्नुते।\nन च सन्न्यसनादेव, सिद्धिं(म्) समधिगच्छति॥3.4॥",
    
    "न हि कश्चित्क्षणमपि, जातु तिष्ठत्यकर्मकृत्।\nकार्यते ह्यवशः(ख्) कर्म, सर्वः(फ्) प्रकृतिजैर्गुणैः॥3.5॥",
    
    "कर्मेन्द्रियाणि संयम्य, य आस्ते मनसा स्मरन्।\nइन्द्रियार्थान्विमूढात्मा, मिथ्याचारः(स्) स उच्यते॥3.6॥",
    
    "यस्त्विन्द्रियाणि मनसा, नियम्यारभतेऽर्जुन।\nकर्मेन्द्रियैः(ख्) कर्मयोगम्, असक्तः(स्) स विशिष्यते॥3.7॥",
    
    "नियतं(ङ्) कुरु कर्म त्वं(ङ्), कर्म ज्यायो ह्यकर्मणः।\nशरीरयात्रापि च ते, न प्रसिद्ध्येदकर्मणः॥3.8॥",
    
    "यज्ञार्थात्कर्मणोऽन्यत्र, लोकोऽयं(ङ्) कर्मबन्धनः।\nतदर्थं(ङ्) कर्म कौन्तेय, मुक्तसङ्गः(स्) समाचर॥3.9॥",
    
    "सहयज्ञाः(फ्) प्रजाः(स्) सृष्ट्वा, पुरोवाच प्रजापतिः।\nअनेन प्रसविष्यध्वम्, एष वोऽस्त्विष्टकामधुक्॥3.10॥",
    
    "देवान्भावयतानेन, ते देवा भावयन्तु वः।\nपरस्परं(म्) भावयन्तः(श्), श्रेयः(फ्) परमवाप्स्यथ॥3.11॥",
    
    "इष्टान्भोगान्हि वो देवा, दास्यन्ते यज्ञभाविताः।\nतैर्दत्तानप्रदायैभ्यो, यो भुङ्क्ते स्तेन एव सः॥3.12॥",
    
    "यज्ञशिष्टाशिनः(स्) सन्तो, मुच्यन्ते सर्वकिल्बिषैः।\nभुञ्जते ते त्वघं(म्) पापा, ये पचन्त्यात्मकारणात्॥3.13॥",
    
    "अन्नाद्भवन्ति भूतानि, पर्जन्यादन्नसम्भवः।\nयज्ञाद्भवति पर्जन्यो, यज्ञः(ख्) कर्मसमुद्भवः॥3.14॥",
    
    "कर्म ब्रह्मोद्भवं(व्ँ) विद्धि, ब्रह्माक्षरसमुद्भवम्।\nतस्मात्सर्वगतं(म्) ब्रह्म, नित्यं(य्ँ) यज्ञे प्रतिष्ठितम्॥3.15॥",
    
    "एवं(म्) प्रवर्तितं(ञ्) चक्रं(न्), नानुवर्तयतीह यः।\nअघायुरिन्द्रियारामो, मोघं(म्) पार्थ स जीवति॥3.16॥",
    
    "यस्त्वात्मरतिरेव स्याद्, आत्मतृप्तश्च मानवः।\nआत्मन्येव च सन्तुष्ट:(स्), तस्य कार्यं(न्) न विद्यते॥3.17॥",
    
    "नैव तस्य कृतेनार्थो, नाकृतेनेह कश्चन।\nन चास्य सर्वभूतेषु, कश्चिदर्थव्यपाश्रयः॥3.18॥",
    
    "तस्मादसक्तः(स्) सततं(ङ्), कार्यं(ङ्) कर्म समाचर।\nअसक्तो ह्याचरन्कर्म, परमाप्नोति पूरुषः॥3.19॥",
    
    "कर्मणैव हि संसिद्धिम्, आस्थिता जनकादयः।\nलोकसङ्ग्रहमेवापि, सम्पश्यन्कर्तुमर्हसि॥3.20॥",
    
    "यद्यदाचरति श्रेष्ठ:(स्), तत्तदेवेतरो जनः।\nस यत्प्रमाणं(ङ्) कुरुते, लोकस्तदनुवर्तते॥3.21॥",
    
    "न मे पार्थास्ति कर्तव्यं(न्), त्रिषु लोकेषु किञ्चन।\nनानवाप्तमवाप्तव्यं(व्ँ), वर्त एव च कर्मणि॥3.22॥",
    
    "यदि ह्यहं(न्) न वर्तेयं(ञ्), जातु कर्मण्यतन्द्रितः।\nमम वर्त्मानुवर्तन्ते, मनुष्याः(फ्) पार्थ सर्वशः॥3.23॥",
    
    "उत्सीदेयुरिमे लोका, न कुर्यां(ङ्) कर्म चेदहम्।\nसङ्करस्य च कर्ता स्याम्, उपहन्यामिमाः(फ्) प्रजाः॥3.24॥",
    
    "सक्ताः(ख्) कर्मण्यविद्वांसो, यथा कुर्वन्ति भारत।\nकुर्याद्विद्वांस्तथासक्त:(श्), चिकीर्षुर्लोकसङ्ग्रहम्॥3.25॥",
    
    "न बुद्धिभेदं(ञ्) जनयेद्, अज्ञानां(ङ्) कर्मसङ्गिनाम्।\nजोषयेत्सर्वकर्माणि, विद्वान्युक्तः(स्) समाचरन्॥3.26॥",
    
    "प्रकृतेः(ख्) क्रियमाणानि, गुणैः(ख्) कर्माणि सर्वशः।\nअहङ्कारविमूढात्मा, कर्ताहमिति मन्यते॥3.27॥",
    
    "तत्त्ववित्तु महाबाहो, गुणकर्मविभागयोः।\nगुणा गुणेषु वर्तन्त, इति मत्वा न सज्जते॥3.28॥",
    
    "प्रकृतेर्गुणसम्मूढाः(स्), सज्जन्ते गुणकर्मसु।\nतानकृत्स्नविदो मन्दान्, कृत्स्नविन्न विचालयेत्॥3.29॥",
    
    "मयि सर्वाणि कर्माणि,  सन्न्यस्याध्यात्मचेतसा।\nनिराशीर्निर्ममो भूत्वा, युध्यस्व विगतज्वरः॥3.30॥",
    
    "ये मे मतमिदं(न्) नित्यम्, अनुतिष्ठन्ति मानवाः।\nश्रद्धावन्तोऽनसूयन्तो, मुच्यन्ते तेऽपि कर्मभिः॥3.31॥",
    
    "ये त्वेतदभ्यसूयन्तो, नानुतिष्ठन्ति मे मतम्।\nसर्वज्ञानविमूढांस्तान्, विद्धि नष्टानचेतसः॥3.32॥",
    
    "सदृशं(ञ्) चेष्टते स्वस्याः(फ्), प्रकृतेर्ज्ञानवानपि।\nप्रकृतिं(य्ँ) यान्ति भूतानि, निग्रहः(ख्) किं(ङ्) करिष्यति॥3.33॥",
    
    "इन्द्रियस्येन्द्रियस्यार्थे, रागद्वेषौ व्यवस्थितौ।\nतयोर्न वशमागच्छेत्, तौ ह्यस्य परिपन्थिनौ॥3.34॥",
    
    "श्रेयान्स्वधर्मो विगुणः(फ्),  परधर्मात्स्वनुष्ठितात्।\nस्वधर्मे निधनं(म्) श्रेयः(फ्), परधर्मो भयावहः॥3.35॥",
    
    "अर्जुन उवाच\nअथ केन प्रयुक्तोऽयं(म्), पापं(ञ्) चरति पूरुषः।\nअनिच्छन्नपि वार्ष्णेय, बलादिव नियोजितः॥3.36॥",
    
    "श्रीभगवानुवाच\nकाम एष क्रोध एष, रजोगुणसमुद्भवः।\nमहाशनो महापाप्मा, विद्ध्येनमिह वैरिणम्॥3.37॥",
    
    "धूमेनाव्रियते वह्नि:(र्), यथादर्शो मलेन च।\nयथोल्बेनावृतो गर्भ:(स्), तथा तेनेदमावृतम्॥3.38॥",
    
    "आवृतं(ञ्) ज्ञानमेतेन, ज्ञानिनो नित्यवैरिणा।\nकामरूपेण कौन्तेय, दुष्पूरेणानलेन च॥3.39॥",
    
    "इन्द्रियाणि मनो बुद्धि:(र्), अस्याधिष्ठानमुच्यते।\nएतैर्विमोहयत्येष, ज्ञानमावृत्य देहिनम्॥3.40॥",
    
    "तस्मात्त्वमिन्द्रियाण्यादौ, नियम्य भरतर्षभ।\nपाप्मानं(म्) प्रजहि ह्येनं(ञ्), ज्ञानविज्ञाननाशनम्॥3.41॥",
    
    "इन्द्रियाणि पराण्याहु:(र्), इन्द्रियेभ्यः(फ्) परं(म्) मनः।\nमनसस्तु परा बुद्धि:(र्), यो बुद्धेः(फ्) परतस्तु सः॥3.42॥",
    
    "एवं(म्) बुद्धेः(फ्) परं(म्) बुद्ध्वा, संस्तभ्यात्मानमात्मना।\nजहि शत्रुं(म्) महाबाहो, कामरूपं(न्) दुरासदम्॥3.43॥",
  
    "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु  ब्रह्मविद्यायां(य्ँ) योगशास्त्रे\nश्रीकृष्णार्जुनसंवादे कर्मयोगो नाम तृतीयोऽध्याय:॥"
  ],
  "adhyaya-4" : [
    "श्रीभगवानुवाच\nइमं(व्ँ) विवस्वते योगं(म्), प्रोक्तवानहमव्ययम्।\nविवस्वान्मनवे प्राह, मनुरिक्ष्वाकवेऽब्रवीत्॥4.1॥",
    
    "एवं(म्) परम्पराप्राप्तम्, इमं(म्) राजर्षयो विदुः।\nस कालेनेह महता, योगो नष्टः(फ्) परन्तप॥4.2 ॥",
    
    "स एवायं(म्) मया तेऽद्य, योगः(फ्) प्रोक्तः(फ्) पुरातनः।\nभक्तोऽसि मे सखा चेति, रहस्यं(म्) ह्येतदुत्तमम्॥4.3॥",  
    
    "अर्जुन उवाच\nअपरं(म्) भवतो जन्म, परं(ञ्) जन्म विवस्वतः।\nकथमेतद्विजानीयां(न्), त्वमादौ प्रोक्तवानिति॥4.4॥",
    
    "श्रीभगवानुवाच\nबहूनि मे व्यतीतानि, जन्मानि तव चार्जुन।\nतान्यहं(व्ँ) वेद सर्वाणि, न त्वं(व्ँ) वेत्थ परन्तप॥4.5॥",
    
    "अजोऽपि सन्नव्ययात्मा, भूतानामीश्वरोऽपि सन्।\nप्रकृतिं(म्) स्वामधिष्ठाय, सम्भवाम्यात्ममायया॥4.6॥",
    
    "यदा यदा हि धर्मस्य, ग्लानिर्भवति भारत।\nअभ्युत्थानमधर्मस्य, तदात्मानं(म्) सृजाम्यहम्॥4.7॥",
    
    "परित्राणाय साधूनां(व्ँ), विनाशाय च दुष्कृताम्।\nधर्मसंस्थापनार्थाय, सम्भवामि युगे युगे॥4.8॥",
    
    "जन्म कर्म च मे दिव्यम्, एवं(य्ँ) यो वेत्ति तत्त्वतः।\nत्यक्त्वा देहं(म्) पुनर्जन्म, नैति मामेति सोऽर्जुन॥4.9॥",
    
    "वीतरागभयक्रोधा, मन्मया मामुपाश्रिताः।\nबहवो ज्ञानतपसा, पूता मद्भावमागताः॥4.10॥",
    
    "ये यथा मां(म्) प्रपद्यन्ते, तांस्तथैव भजाम्यहम्।\nमम वर्त्मानुवर्तन्ते, मनुष्याः(फ्) पार्थ सर्वशः॥4.11॥",
    
    "काङ्क्षन्तः(ख्) कर्मणां(म्) सिद्धिं(य्ँ), यजन्त इह देवताः।\nक्षिप्रं(म्) हि मानुषे लोके, सिद्धिर्भवति कर्मजा॥4.12॥",
    
    "चातुर्वर्ण्यं(म्) मया सृष्टं(ङ्), गुणकर्मविभागशः।\nतस्य कर्तारमपि मां(व्ँ), विद्ध्यकर्तारमव्ययम्॥4.13॥",
    
    "न मां(ङ्) कर्माणि लिम्पन्ति, न मे कर्मफले स्पृहा।\nइति मां(य्ँ) योऽभिजानाति, कर्मभिर्न स बध्यते॥4.14॥",
    
    "एवं(ञ्) ज्ञात्वा कृतं(ङ्) कर्म, पूर्वैरपि मुमुक्षुभिः।\nकुरु कर्मैव तस्मात्त्वं(म्), पूर्वैः(फ्) पूर्वतरं(ङ्) कृतम्॥4.15॥",
    
    "किं(ङ्) कर्म किमकर्मेति, कवयोऽप्यत्र मोहिताः।\nतत्ते कर्म प्रवक्ष्यामि, यज्ज्ञात्वा मोक्ष्यसेऽशुभात्॥4.16॥",
    
    "कर्मणो ह्यपि बोद्धव्यं(म्), बोद्धव्यं(ञ्) च विकर्मणः।\nअकर्मणश्च बोद्धव्यं(ङ्), गहना कर्मणो गतिः॥4.17॥",
    
    "कर्मण्यकर्म यः(फ्) पश्येद्, अकर्मणि च कर्म यः।\nस बुद्धिमान्मनुष्येषु, स युक्तः(ख्) कृत्स्नकर्मकृत्॥4.18॥",
    
    "यस्य सर्वे समारम्भाः(ख्), कामसङ्कल्पवर्जिताः।\nज्ञानाग्निदग्धकर्माणं(न्), तमाहुः(फ्) पण्डितं(म्) बुधाः॥4.19॥",
    
    "त्यक्त्वा कर्मफलासङ्गं(न्), नित्यतृप्तो निराश्रयः।\nकर्मण्यभिप्रवृत्तोऽपि, नैव किञ्चित्करोति सः॥4.20॥",
    
    "निराशीर्यतचित्तात्मा, त्यक्तसर्वपरिग्रहः।\nशारीरं(ङ्) केवलं(ङ्) कर्म, कुर्वन्नाप्नोति किल्बिषम्॥4.21॥",
    
    "यदृच्छालाभसन्तुष्टो, द्वन्द्वातीतो विमत्सरः।\nसमः(स्) सिद्धावसिद्धौ च, कृत्वापि न निबध्यते॥4.22॥",
    
    "गतसङ्गस्य मुक्तस्य, ज्ञानावस्थितचेतसः।\nयज्ञायाचरतः(ख्) कर्म, समग्रं(म्) प्रविलीयते॥4.23॥",
    
    "ब्रह्मार्पणं(म्) ब्रह्म हवि:(र्), ब्रह्माग्नौ ब्रह्मणा हुतम्।\nब्रह्मैव तेन गन्तव्यं(म्), ब्रह्मकर्मसमाधिना॥4.24॥",
    
    "दैवमेवापरे यज्ञं(य्ँ), योगिनः(फ्) पर्युपासते।\nब्रह्माग्नावपरे यज्ञं(य्ँ), यज्ञेनैवोपजुह्वति॥4.25॥",
    
    "श्रोत्रादीनीन्द्रियाण्यन्ये, संयमाग्निषु जुह्वति।\nशब्दादीन्विषयानन्य, इन्द्रियाग्निषु जुह्वति॥4.26॥",
    
    "सर्वाणीन्द्रियकर्माणि, प्राणकर्माणि चापरे।\nआत्मसंयमयोगाग्नौ, जुह्वति ज्ञानदीपिते॥4.27॥",
    
    "द्रव्ययज्ञास्तपोयज्ञा, योगयज्ञास्तथापरे।\nस्वाध्यायज्ञानयज्ञाश्च, यतयः(स्) संशितव्रताः॥4.28॥",
      
    "अपाने जुह्वति प्राणं(म्), प्राणेऽपानं(न्) तथापरे।\nप्राणापानगती रुद्ध्वा, प्राणायामपरायणाः॥4.29॥",
      
    "अपरे नियताहाराः(फ्), प्राणान्प्राणेषु जुह्वति।\nसर्वेऽप्येते यज्ञविदो, यज्ञक्षपितकल्मषाः॥4.30॥",
      
    "यज्ञशिष्टामृतभुजो, यान्ति ब्रह्म सनातनम्।\nनायं(ल्ँ) लोकोऽस्त्ययज्ञस्य, कुतोऽन्यः(ख्) कुरुसत्तम॥4.31॥",
    
    "एवं(म्) बहुविधा यज्ञा, वितता ब्रह्मणो मुखे।\nकर्मजान्विद्धि तान्सर्वान्, एवं(ञ्) ज्ञात्वा विमोक्ष्यसे॥4.32॥",
    
    "श्रेयान्द्रव्यमयाद्यज्ञाज्, ज्ञानयज्ञः(फ्) परन्तप।\nसर्वं(ङ्) कर्माखिलं(म्) पार्थ, ज्ञाने परिसमाप्यते॥4.33॥",
    
    "तद्विद्धि प्रणिपातेन, परिप्रश्नेन सेवया।\nउपदेक्ष्यन्ति ते ज्ञानं(ञ्), ज्ञानिनस्तत्त्वदर्शिनः॥4.34॥",
    
    "यज्ज्ञात्वा न पुनर्मोहम्, एवं(य्ँ) यास्यसि पाण्डव।\nयेन भूतान्यशेषेण, द्रक्ष्यस्यात्मन्यथो मयि॥4.35॥",
    
    "अपि चेदसि पापेभ्यः(स्), सर्वेभ्यः(फ्) पापकृत्तमः।\nसर्वं(ञ्) ज्ञानप्लवेनैव, वृजिनं(म्) सन्तरिष्यसि॥4.36॥",
    
    "यथैधांसि समिद्धोऽग्नि:(र्),  भस्मसात्कुरुतेऽर्जुन।\nज्ञानाग्निः(स्) सर्वकर्माणि, भस्मसात्कुरुते तथा॥4.37॥",
    
    "न हि ज्ञानेन सदृशं(म्), पवित्रमिह विद्यते।\nतत्स्वयं(य्ँ) योगसंसिद्धः(ख्), कालेनात्मनि विन्दति॥4.38॥",
    
    "श्रद्धावाँल्लभते ज्ञानं(न्), तत्परः(स्) संयतेन्द्रियः।\nज्ञानं(ल्ँ) लब्ध्वा परां(म्) शान्तिम्, अचिरेणाधिगच्छति॥4.39॥",
    
    "अज्ञश्चाश्रद्दधानश्च, संशयात्मा विनश्यति।\nनायं(ल्ँ) लोकोऽस्ति न परो, न सुखं(म्) संशयात्मनः॥4.40॥",
    
    "योगसन्न्यस्तकर्माणं(ञ्), ज्ञानसञ्छिन्नसंशयम्।\nआत्मवन्तं(न्) न कर्माणि, निबध्नन्ति धनञ्जय॥4.41॥",
    
    "तस्मादज्ञानसम्भूतं(म्), हृत्स्थं(ञ्) ज्ञानासिनात्मन:।\nछित्त्वैनं(म्) संशयं(य्ँ) योगम्, आतिष्ठोत्तिष्ठ भारत॥4.42॥",
    
    "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु ब्रह्मविद्यायां(य्ँ) योगशास्त्रे\nश्रीकृष्णार्जुनसंवादे ज्ञानकर्मसन्न्यासयोगो नाम चतुर्थोऽध्याय:।।"
  ],
  "adhyaya-5" : [
    "अर्जुन उवाच\nसन्न्यासं(ङ्) कर्मणां(ङ्) कृष्ण, पुनर्योगं(ञ्) च शंससि।\nयच्छ्रेय एतयोरेकं(न्), तन्मे ब्रूहि सुनिश्चितम्॥5.1॥",
    
    "श्रीभगवानुवाच\nसन्न्यासः(ख्) कर्मयोगश्च, निःश्रेयसकरावुभौ।\nतयोस्तु कर्मसन्न्यासात्, कर्मयोगो विशिष्यते॥5.2॥",
    
    "ज्ञेयः(स्) स नित्यसन्न्यासी, यो न द्वेष्टि न काङ्क्षति।\nनिर्द्वन्द्वो हि महाबाहो, सुखं(म्) बन्धात्प्रमुच्यते॥5.3॥",
    
    "साङ्ख्ययोगौ पृथग्बालाः(फ्), प्रवदन्ति न पण्डिताः।\nएकमप्यास्थितः(स्) सम्यग्, उभयोर्विन्दते फलम्॥5.4॥",
    
    "यत्साङ्ख्यैः(फ्) प्राप्यते स्थानं(न्), तद्योगैरपि गम्यते।\nएकं(म्) साङ्ख्यं(ञ्) च योगं(ञ्) च, यः(फ्) पश्यति स पश्यति॥5.5॥",
    
    "सन्न्यासस्तु महाबाहो, दुःखमाप्तुमयोगतः।\nयोगयुक्तो मुनिर्ब्रह्म, नचिरेणाधिगच्छति॥5.6॥",
    
    "योगयुक्तो विशुद्धात्मा, विजितात्मा जितेन्द्रियः।\nसर्वभूतात्मभूतात्मा, कुर्वन्नपि न लिप्यते॥5.7॥",
    
    "नैव किञ्चित्करोमीति, युक्तो मन्येत तत्त्ववित्।\nपश्यञ्शृण्वन्स्पृशञ्जिघ्रन्, नश्नन्गच्छन्स्वपञ्श्वसन्॥5.8॥",
    
    "प्रलपन्विसृजन्गृह्णन्, नुन्मिषन्निमिषन्नपि।\nइन्द्रियाणीन्द्रियार्थेषु, वर्तन्त इति धारयन्॥5.9॥", 
    
    "ब्रह्मण्याधाय कर्माणि, सङ्गं(न्) त्यक्त्वा करोति यः।\nलिप्यते न स पापेन, पद्मपत्रमिवाम्भसा॥5.10॥", 
    
    "कायेन मनसा बुद्ध्या, केवलैरिन्द्रियैरपि।\nयोगिनः(ख्) कर्म कुर्वन्ति, सङ्गं(न्) त्यक्त्वात्मशुद्धये॥5.11॥",
      
    "युक्तः(ख्) कर्मफलं(न्) त्यक्त्वा, शान्तिमाप्नोति नैष्ठिकीम्।\nअयुक्तः(ख्) कामकारेण, फले सक्तो निबध्यते॥5.12॥",
    
    "सर्वकर्माणि मनसा, सन्न्यस्यास्ते सुखं(व्ँ) वशी।\nनवद्वारे पुरे देही, नैव कुर्वन्न कारयन्॥5.13॥",
    
    "न कर्तृत्वं(न्) न कर्माणि, लोकस्य सृजति प्रभुः।\nन कर्मफलसंयोगं(म्), स्वभावस्तु प्रवर्तते॥5.14॥",
    
    "नादत्ते कस्यचित्पापं(न्), न चैव सुकृतं(व्ँ) विभुः।\nअज्ञानेनावृतं(ञ्) ज्ञानं(न्), तेन मुह्यन्ति जन्तवः॥5.15॥",
    
    "ज्ञानेन तु तदज्ञानं(य्ँ), येषां(न्) नाशितमात्मनः।\nतेषामादित्यवज्ज्ञानं(म्), प्रकाशयति तत्परम्॥5.16॥",
    
    "तद्बुद्धयस्तदात्मान:(स्), तन्निष्ठास्तत्परायणाः।\nगच्छन्त्यपुनरावृत्तिं(ञ्), ज्ञाननिर्धूतकल्मषाः॥5.17॥",
    
    "विद्याविनयसम्पन्ने, ब्राह्मणे गवि हस्तिनि।\nशुनि चैव श्वपाके च, पण्डिताः(स्) समदर्शिनः॥5.18॥",
    
    "इहैव तैर्जितः(स्) सर्गो, येषां(म्) साम्ये स्थितं(म्) मनः।\nनिर्दोषं(म्) हि समं(म्) ब्रह्म, तस्माद् ब्रह्मणि ते स्थिताः॥5.19॥",
    
    "न प्रहृष्येत्प्रियं(म्) प्राप्य, नोद्विजेत्प्राप्य चाप्रियम्।\nस्थिरबुद्धिरसम्मूढो, ब्रह्मविद् ब्रह्मणि स्थितः॥5.20॥",
    
    "बाह्यस्पर्शेष्वसक्तात्मा, विन्दत्यात्मनि यत्सुखम्।\nस ब्रह्मयोगयुक्तात्मा, सुखमक्षयमश्नुते॥5.21॥",
    
    "ये हि संस्पर्शजा भोगा, दुःखयोनय एव ते।\nआद्यन्तवन्तः(ख्) कौन्तेय, न तेषु रमते बुधः॥5.22॥",
    
    "शक्नोतीहैव यः(स्) सोढुं(म्), प्राक्शरीरविमोक्षणात्।\nकामक्रोधोद्भवं(व्ँ) वेगं(म्), स युक्तः(स्) स सुखी नरः॥5.23॥",
    
    "योऽन्तःसुखोऽन्तराराम:(स्), तथान्तर्ज्योतिरेव यः।\nस योगी ब्रह्मनिर्वाणं(म्), ब्रह्मभूतोऽधिगच्छति॥5.24॥",
    
    "लभन्ते ब्रह्मनिर्वाणम्, ऋषयः क्षीणकल्मषाः।\nछिन्नद्वैधा यतात्मानः(स्), सर्वभूतहिते रताः॥5.25॥",
    
    "कामक्रोधवियुक्तानां(य्ँ), यतीनां(य्ँ) यतचेतसाम्।\nअभितो ब्रह्मनिर्वाणं(व्ँ), वर्तते विदितात्मनाम्॥5.26॥",
    
    "स्पर्शान्कृत्वा बहिर्बाह्यांश्, चक्षुश्चैवान्तरे भ्रुवोः।\nप्राणापानौ समौ कृत्वा, नासाभ्यन्तरचारिणौ॥5.27॥",
    
    "यतेन्द्रियमनोबुद्धि:(र्), मुनिर्मोक्षपरायणः।\nविगतेच्छाभयक्रोधो, यः(स्) सदा मुक्त एव सः॥5.28॥",
    
    "भोक्तारं(य्ँ) यज्ञतपसां(म्), सर्वलोकमहेश्वरम्।\nसुहृदं(म्) सर्वभूतानां(ञ्), ज्ञात्वा मां(म्) शान्तिमृच्छति॥5.29॥",
    
    "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु ब्रह्मविद्यायां(य्ँ) योगशास्त्रे\nश्रीकृष्णार्जुनसंवादे कर्मसन्न्यासयोगो नाम पञ्चमोऽध्याय:॥"
  ],
  "adhyaya-6" : [
    "श्रीभगवानुवाच\nअनाश्रितः(ख्) कर्मफलं(ङ्), कार्यं(ङ्) कर्म करोति यः।\nस सन्न्यासी च योगी च, न निरग्निर्न चाक्रियः॥6.1॥",
    
    "यं(म्) सन्न्यासमिति प्राहु:(र्), योगं(न्) तं(व्ँ) विद्धि पाण्डव।\nन ह्यसन्न्यस्तसङ्कल्पो, योगी भवति कश्चन॥6.2॥",
    
    "आरुरुक्षोर्मुनेर्योगं(ङ्), कर्म कारणमुच्यते।\nयोगारूढस्य तस्यैव, शमः(ख्) कारणमुच्यते॥6.3॥",
    
    "यदा हि नेन्द्रियार्थेषु, न कर्मस्वनुषज्जते।\nसर्वसङ्कल्पसन्न्यासी, योगारूढस्तदोच्यते॥6.4॥",
    
    "उद्धरेदात्मनात्मानं(न्), नात्मानमवसादयेत्।\nआत्मैव ह्यात्मनो बन्धु:(र्), आत्मैव रिपुरात्मनः ॥6.5॥",
    
    "बन्धुरात्मात्मनस्तस्य, येनात्मैवात्मना जितः।\nअनात्मनस्तु शत्रुत्वे, वर्तेतात्मैव शत्रुवत्॥6.6॥",
    
    "जितात्मनः(फ्) प्रशान्तस्य, परमात्मा समाहितः।\nशीतोष्णसुखदुःखेषु, तथा मानापमानयोः॥6.7॥",
      
    "ज्ञानविज्ञानतृप्तात्मा, कूटस्थो विजितेन्द्रियः।\nयुक्त इत्युच्यते योगी, समलोष्टाश्मकाञ्चनः॥6.8॥",
    
    "सुहृन्मित्रार्युदासीन, मध्यस्थद्वेष्यबन्धुषु।\nसाधुष्वपि च पापेषु, समबुद्धिर्विशिष्यते॥6.9॥",
    
    "योगी युञ्जीत सततम्, आत्मानं(म्) रहसि स्थितः।\nएकाकी यतचित्तात्मा, निराशीरपरिग्रहः॥6.10॥",
    
    "शुचौ देशे प्रतिष्ठाप्य, स्थिरमासनमात्मनः।\nनात्युच्छ्रितं(न्) नातिनीचं(ञ्), चैलाजिनकुशोत्तरम्॥6.11॥",
    
    "तत्रैकाग्रं(म्) मनः(ख्) कृत्वा, यतचित्तेन्द्रियक्रियः।\nउपविश्यासने युञ्ज्याद्, योगमात्मविशुद्धये॥6.12॥",
    
    "समं(ङ्) कायशिरोग्रीवं(न्), धारयन्नचलं(म्) स्थिरः।\nसम्प्रेक्ष्य नासिकाग्रं(म्) स्वं(न्), दिशश्चानवलोकयन्॥6.13॥",
    
    "प्रशान्तात्मा विगतभी:(र्), ब्रह्मचारिव्रते स्थितः।\nमनः(स्) संयम्य मच्चित्तो, युक्त आसीत मत्परः॥6.14॥",
    
    "युञ्जन्नेवं(म्) सदात्मानं(य्ँ), योगी नियतमानसः।\nशान्तिं(न्) निर्वाणपरमां(म्), मत्संस्थामधिगच्छति॥6.15॥",
    
    "नात्यश्नतस्तु योगोऽस्ति, न चैकान्तमनश्नतः।\nन चातिस्वप्नशीलस्य, जाग्रतो नैव चार्जुन॥6.16॥",
    
    "युक्ताहारविहारस्य,  युक्तचेष्टस्य कर्मसु।\nयुक्तस्वप्नावबोधस्य, योगो भवति दुःखहा॥6.17॥",
    
    "यदा विनियतं(ञ्) चित्तम्, आत्मन्येवावतिष्ठते।\nनिःस्पृहः(स्) सर्वकामेभ्यो, युक्त इत्युच्यते तदा॥6.18॥",
    
    "यथा दीपो निवातस्थो, नेङ्गते सोपमा स्मृता।\nयोगिनो यतचित्तस्य, युञ्जतो योगमात्मनः॥6.19॥",
    
    "यत्रोपरमते चित्तं(न्), निरुद्धं(य्ँ) योगसेवया।\nयत्र चैवात्मनात्मानं(म्), पश्यन्नात्मनि तुष्यति॥6.20॥",
    
    "सुखमात्यन्तिकं(य्ँ) यत्तद्, बुद्धिग्राह्यमतीन्द्रियम्।\nवेत्ति यत्र न चैवायं(म्), स्थितश्चलति तत्त्वतः॥6.21॥",
    
    "यं(ल्ँ) लब्ध्वा चापरं(ल्ँ) लाभं(म्), मन्यते नाधिकं(न्) ततः।\nयस्मिन्स्थितो न दुःखेन, गुरुणापि विचाल्यते॥6.22॥",
    
    "तं(व्ँ) विद्याद् दुःखसंयोग, वियोगं(य्ँ) योगसञ्ज्ञितम्।\nस निश्चयेन योक्तव्यो, योगोऽनिर्विण्णचेतसा॥6.23॥",
    
    "सङ्कल्पप्रभवान्कामांस्, त्यक्त्वा सर्वानशेषतः।\nमनसैवेन्द्रियग्रामं(व्ँ), विनियम्य समन्ततः॥6.24॥",
    
    "शनैः(श्) शनैरुपरमेद्, बुद्ध्या धृतिगृहीतया।\nआत्मसंस्थं(म्) मनः(ख्) कृत्वा, न किञ्चिदपि चिन्तयेत्॥6.25॥",
    
    "यतो यतो निश्चरति, मनश्चञ्चलमस्थिरम्।\nततस्ततो नियम्यैतद्, आत्मन्येव वशं(न्) नयेत् ॥6.26॥",
    
    "प्रशान्तमनसं(म्) ह्येनं(य्ँ), योगिनं(म्) सुखमुत्तमम्।\nउपैति शान्तरजसं(म्), ब्रह्मभूतमकल्मषम्॥6.27॥",
    
    "युञ्जन्नेवं(म्) सदात्मानं(य्ँ), योगी विगतकल्मषः।\nसुखेन ब्रह्मसंस्पर्शम्, अत्यन्तं(म्) सुखमश्नुते॥6.28॥",
    
    "सर्वभूतस्थमात्मानं(म्), सर्वभूतानि चात्मनि।\nईक्षते योगयुक्तात्मा, सर्वत्र समदर्शनः॥6.29॥",
    
    "यो मां(म्) पश्यति सर्वत्र, सर्वं(ञ्) च मयि पश्यति।\nतस्याहं(न्) न प्रणश्यामि, स च मे न प्रणश्यति॥6.30॥",
    
    "सर्वभूतस्थितं(य्ँ) यो मां(म्), भजत्येकत्वमास्थितः।\nसर्वथा वर्तमानोऽपि, स योगी मयि वर्तते॥6.31॥",
    
    "आत्मौपम्येन सर्वत्र, समं(म्) पश्यति योऽर्जुन।\nसुखं(व्ँ) वा यदि वा दुःखं(म्), स योगी परमो मतः॥6.32॥",
    
    "अर्जुन उवाच\n योऽयं(य्ँ) योगस्त्वया प्रोक्तः(स्), साम्येन मधुसूदन।\nएतस्याहं(न्) न पश्यामि, चञ्चलत्वात्स्थितिं(म्) स्थिराम्॥6.33॥",
    
    "चञ्चलं(म्) हि मनः(ख्) कृष्ण, प्रमाथि बलवद्दृढम् ।\nतस्याहं(न्) निग्रहं(म्) मन्ये, वायोरिव सुदुष्करम् ॥6.34॥",
    
    "श्रीभगवानुवाच\nअसंशयं(म्) महाबाहो, मनो दुर्निग्रहं(ञ्) चलम्।\nअभ्यासेन तु कौन्तेय, वैराग्येण च गृह्यते॥6.35॥",
    
    "असंयतात्मना योगो, दुष्प्राप इति मे मतिः।\nवश्यात्मना तु यतता, शक्योऽवाप्तुमुपायतः॥6.36॥",
    
    "अर्जुन उवाच\nअयतिः(श्) श्रद्धयोपेतो, योगाच्चलितमानसः।\nअप्राप्य योगसंसिद्धिं(ङ्), कां(ङ्) गतिं (ङ्) कृष्ण गच्छति॥6.37॥",
    
    "कच्चिन्नोभयविभ्रष्ट:(श्), छिन्नाभ्रमिव नश्यति।\nअप्रतिष्ठो महाबाहो, विमूढो ब्रह्मणः(फ्) पथि॥6.38॥",
    
    "एतन्मे संशयं(ङ्) कृष्ण, छेत्तुमर्हस्यशेषतः।\nत्वदन्यः(स्) संशयस्यास्य, छेत्ता न ह्युपपद्यते॥6.39॥",
    
    "श्रीभगवानुवाच\nपार्थ नैवेह नामुत्र, विनाशस्तस्य विद्यते।\nन हि कल्याणकृत्कश्चिद्, दुर्गतिं(न्) तात गच्छति॥6.40॥",
    
    "प्राप्य पुण्यकृतां(ल्ँ) लोकान्, उषित्वा शाश्वतीः(स्) समाः।\nशुचीनां(म्) श्रीमतां(ङ्) गेहे, योगभ्रष्टोऽभिजायते॥6.41॥",
    
    "अथवा योगिनामेव, कुले भवति धीमताम्।\nएतद्धि दुर्लभतरं(ल्ँ), लोके जन्म यदीदृशम्॥6.42॥",
      
    "तत्र तं(म्) बुद्धिसंयोगं(ल्ँ), लभते पौर्वदेहिकम्।\nयतते च ततो भूयः(स्), संसिद्धौ कुरुनन्दन॥6.43॥",
    
    "पूर्वाभ्यासेन तेनैव, ह्रियते ह्यवशोऽपि सः।\nजिज्ञासुरपि योगस्य, शब्दब्रह्मातिवर्तते॥6.44॥",
    
    "प्रयत्नाद्यतमानस्तु, योगी संशुद्धकिल्बिषः।\nअनेकजन्मसंसिद्ध:(स्), ततो याति परां(ङ्) गतिम्॥6.45॥",
    
    "तपस्विभ्योऽधिको योगी, ज्ञानिभ्योऽपि मतोऽधिकः।\nकर्मिभ्यश्चाधिको योगी, तस्माद्योगी भवार्जुन॥6.46॥",
    
    "योगिनामपि सर्वेषां(म्), मद्गतेनान्तरात्मना।\nश्रद्धावान्भजते यो मां(म्), स मे युक्ततमो मतः॥6.47॥",
    
    "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु ब्रह्मविद्यायां(य्ँ) योगशास्त्रे\nश्रीकृष्णार्जुनसंवादे आत्मसंयमयोगो नाम षष्ठोऽध्यायः॥"
  ],
  "adhyaya-7" : [
    "श्रीभगवानुवाच\nमय्यासक्तमनाः(फ्) पार्थ, योगं(य्ँ) युञ्जन्मदाश्रयः।\nअसंशयं(म्) समग्रं(म्) मां(य्ँ), यथा ज्ञास्यसि तच्छृणु॥7.1॥",
    
    "ज्ञानं(न्) तेऽहं(म्) सविज्ञानम्, इदं(व्ँ) वक्ष्याम्यशेषतः।\nयज्ज्ञात्वा नेह भूयोऽन्यज्, ज्ञातव्यमवशिष्यते॥7.2॥",
    
    "मनुष्याणां(म्) सहस्रेषु, कश्चिद्यतति सिद्धये।\nयततामपि सिद्धानां(ङ्), कश्चिन्मां(व्ँ) वेत्ति तत्त्वतः॥7.3॥",
    
    "भूमिरापोऽनलो वायुः(ख्), खं(म्) मनो बुद्धिरेव च।\nअहङ्कार इतीयं(म्) मे, भिन्ना प्रकृतिरष्टधा॥7.4॥",
    
    "अपरेयमितस्त्वन्यां(म्), प्रकृतिं(व्ँ) विद्धि मे पराम्।\nजीवभूतां(म्) महाबाहो, ययेदं(न्) धार्यते जगत्॥7.5॥",
    
    "एतद्योनीनि भूतानि, सर्वाणीत्युपधारय।\nअहं(ङ्) कृत्स्नस्य जगतः(फ्), प्रभवः(फ्) प्रलयस्तथा॥7.6॥",
    
    "मत्तः(फ्) परतरं(न्) नान्यत्, किञ्चिदस्ति धनञ्जय।\nमयि सर्वमिदं(म्) प्रोतं(म्), सूत्रे मणिगणा इव॥7.7॥",
    
    "रसोऽहमप्सु कौन्तेय, प्रभास्मि शशिसूर्ययोः।\nप्रणवः(स्) सर्ववेदेषु, शब्दः(ख्) खे पौरुषं(न्) नृषु॥7.8॥",
    
    "पुण्यो गन्धः(फ्) पृथिव्यां(ञ्) च, तेजश्चास्मि विभावसौ।\nजीवनं(म्) सर्वभूतेषु, तपश्चास्मि तपस्विषु॥7.9॥",
    
    "बीजं(म्) मां(म्) सर्वभूतानां(व्ँ), विद्धि पार्थ सनातनम्।\nबुद्धिर्बुद्धिमतामस्मि, तेजस्तेजस्विनामहम्॥7.10॥",
    
    "बलं(म्) बलवतां(ञ्) चाहं(ङ्), कामरागविवर्जितम्।\nधर्माविरुद्धो भूतेषु, कामोऽस्मि भरतर्षभ॥7.11॥",
    
    "ये चैव सात्त्विका भावा, राजसास्तामसाश्च ये।\nमत्त एवेति तान्विद्धि, न त्वहं(न्) तेषु ते मयि॥7.12॥",
    
    "त्रिभिर्गुणमयैर्भावै:(र्), एभिः(स्) सर्वमिदं(ञ्) जगत्।\nमोहितं(न्) नाभिजानाति, मामेभ्यः(फ्) परमव्ययम्॥7.13॥",
    
    "दैवी ह्येषा गुणमयी, मम माया दुरत्यया।\nमामेव ये प्रपद्यन्ते, मायामेतां(न्) तरन्ति ते॥7.14॥",
      
    "न मां(न्) दुष्कृतिनो मूढाः(फ्), प्रपद्यन्ते नराधमाः।\nमाययापहृतज्ञाना, आसुरं(म्) भावमाश्रिताः॥7.15॥",
    
    "चतुर्विधा भजन्ते मां(ञ्), जनाः(स्) सुकृतिनोऽर्जुन।\nआर्तो जिज्ञासुरर्थार्थी, ज्ञानी च भरतर्षभ॥7.16॥",
    
    "तेषां(ञ्) ज्ञानी नित्ययुक्त, एकभक्तिर्विशिष्यते।\nप्रियो हि ज्ञानिनोऽत्यर्थम्, अहं (म्) स च मम प्रियः॥7.17॥",
    
    "उदाराः(स्) सर्व एवैते, ज्ञानी त्वात्मैव मे मतम्।\nआस्थितः(स्) स हि युक्तात्मा, मामेवानुत्तमां(ङ्) गतिम्॥7.18॥",
    
    "बहूनां(ञ्) जन्मनामन्ते, ज्ञानवान्मां(म्) प्रपद्यते।\nवासुदेवः(स्) सर्वमिति, स महात्मा सुदुर्लभः॥7.19॥",
    
    "कामैस्तैस्तैर्हृतज्ञानाः(फ्), प्रपद्यन्तेऽन्यदेवताः।\nतं(न्) तं(न्) नियममास्थाय, प्रकृत्या नियताः(स्) स्वया॥7.20॥",
    
    "यो यो यां(य्ँ) यां(न्) तनुं(म्) भक्तः(श्), श्रद्धयार्चितुमिच्छति।\nतस्य तस्याचलां(म्) श्रद्धां(न्), तामेव विदधाम्यहम्॥7.21॥",
    
    "स तया श्रद्धया युक्त:(स्), तस्याराधनमीहते।\nलभते च ततः(ख्) कामान्, मयैव विहितान्हि तान्॥7.22॥",
    
    "अन्तवत्तु फलं(न्) तेषां(न्), तद्भवत्यल्पमेधसाम्।\nदेवान्देवयजो यान्ति, मद्भक्ता यान्ति मामपि॥7.23॥",
    
    "अव्यक्तं(व्ँ) व्यक्तिमापन्नं(म्), मन्यन्ते मामबुद्धयः।\nपरं(म्) भावमजानन्तो, ममाव्ययमनुत्तमम्॥7.24॥",
    
    "नाहं(म्) प्रकाशः(स्) सर्वस्य, योगमायासमावृतः।\nमूढोऽयं(न्) नाभिजानाति, लोको मामजमव्ययम्॥7.25॥",
    
    "वेदाहं(म्) समतीतानि, वर्तमानानि चार्जुन।\nभविष्याणि च भूतानि, मां(न्) तु वेद न कश्चन॥7.26॥",
    
    "इच्छाद्वेषसमुत्थेन, द्वन्द्वमोहेन भारत।\nसर्वभूतानि सम्मोहं(म्), सर्गे यान्ति परन्तप॥7.27॥",
    
    "येषां(न्) त्वन्तगतं(म्) पापं(ञ्), जनानां(म्) पुण्यकर्मणाम्।\nते द्वन्द्वमोहनिर्मुक्ता, भजन्ते मां(न्) दृढव्रताः॥7.28॥",
    
    "जरामरणमोक्षाय, मामाश्रित्य यतन्ति ये।\nते ब्रह्म तद्विदुः(ख्) कृत्स्नम्, अध्यात्मं(ङ्) कर्म चाखिलम्॥7.29॥",
    
    "साधिभूताधिदैवं(म्) मां(म्), साधियज्ञं(ञ्) च ये विदुः।\nप्रयाणकालेऽपि च मां(न्), ते विदुर्युक्तचेतसः॥7.30॥",
    
    "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु ब्रह्मविद्यायां (य्ँ) योगशास्त्रे\nश्रीकृष्णार्जुनसंवादे ज्ञानविज्ञानयोगो नाम सप्तमोऽध्यायः॥"
  ],
  "adhyaya-8" : [
    "अर्जुन उवाच\nकिं(न्) तद्ब्रह्म किमध्यात्मं(ङ्), किं(ङ्) कर्म पुरुषोत्तम।\nअधिभूतं(ञ्) च किं(म्) प्रोक्तम्, अधिदैवं(ङ्) किमुच्यते॥8.1॥",
    
    "अधियज्ञ:(ख्) कथं(ङ्) कोऽत्र, देहेऽस्मिन्मधुसूदन।\nप्रयाणकाले च कथं(ञ्), ज्ञेयोऽसि नियतात्मभिः॥8.2॥",
    
    "श्रीभगवानुवाच\n अक्षरं(म्) ब्रह्म परमं(म्), स्वभावोऽध्यात्ममुच्यते।\nभूतभावोद्भवकरो, विसर्गः(ख्) कर्मसञ्ज्ञितः॥8.3॥",
    
    "अधिभूतं(ङ्) क्षरो भावः(फ्), पुरुषश्चाधिदैवतम्।\nअधियज्ञोऽहमेवात्र, देहे देहभृतां(व्ँ) वर॥8.4॥",
    
    "अन्तकाले च मामेव, स्मरन्मुक्त्वा कलेवरम्।\nयः(फ्) प्रयाति स मद्भावं(य्ँ), याति नास्त्यत्र संशयः॥8.5॥",
    
    "यं(य्ँ) यं(व्ँ) वापि स्मरन्भावं(न्), त्यजत्यन्ते कलेवरम्।\nतं(न्) तमेवैति कौन्तेय, सदा तद्भावभावितः॥8.6॥",
    
    "तस्मात्सर्वेषु कालेषु, मामनुस्मर युध्य च।\nमय्यर्पितमनोबुद्धि:(र्), मामेवैष्यस्यसंशयम्॥8.7॥",
    
    "अभ्यासयोगयुक्तेन, चेतसा नान्यगामिना।\nपरमं(म्) पुरुषं(न्) दिव्यं(य्ँ), याति पार्थानुचिन्तयन्॥8.8॥",
    
    "कविं(म्) पुराणमनुशासितारम्,\nअणोरणीयांसमनुस्मरेद्यः ।\nसर्वस्य धातारमचिन्त्यरूपम्,\nआदित्यवर्णं(न्) तमसः(फ्) परस्तात्॥8.9॥",
    
    "प्रयाणकाले मनसाचलेन,\nभक्त्या युक्तो योगबलेन चैव।\nभ्रुवोर्मध्ये प्राणमावेश्य सम्यक्,\nस तं(म्) परं(म्) पुरुषमुपैति दिव्यम्॥8.10॥",
    
    "यदक्षरं(व्ँ) वेदविदो वदन्ति,\nविशन्ति यद्यतयो वीतरागाः।\nयदिच्छन्तो ब्रह्मचर्यं(ञ्) चरन्ति,\nतत्ते पदं(म्) सङ्ग्रहेण प्रवक्ष्ये॥8.11॥",
    
    "सर्वद्वाराणि संयम्य, मनो हृदि निरुध्य च।\nमूर्ध्न्याधायात्मनः(फ्) प्राणम्, आस्थितो योगधारणाम्॥8.12॥",
    
    "ओमित्येकाक्षरं(म्) ब्रह्म, व्याहरन्मामनुस्मरन्।\nय:(फ्) प्रयाति त्यजन्देहं(म्), स याति परमां(ङ्) गतिम्॥8.13॥",
    
    "अनन्यचेताः(स्) सततं(य्ँ), यो मां(म्) स्मरति नित्यशः।\nतस्याहं(म्) सुलभः(फ्) पार्थ, नित्ययुक्तस्य योगिनः॥8.14॥",
    
    "मामुपेत्य पुनर्जन्म, दुःखालयमशाश्वतम्।\nनाप्नुवन्ति महात्मानः(स्), संसिद्धिं(म्) परमां(ङ्) गताः॥8.15॥",
    
    "आब्रह्मभुवनाल्लोकाः(फ्), पुनरावर्तिनोऽर्जुन।\nमामुपेत्य तु कौन्तेय, पुनर्जन्म न विद्यते॥8.16॥",
    
    "सहस्रयुगपर्यन्तम्, अहर्यद्ब्रह्मणो विदुः।\nरात्रिं(य्ँ) युगसहस्रान्तां(न्), तेऽहोरात्रविदो जनाः॥8.17॥",
    
    "अव्यक्ताद्व्यक्तयः(स्) सर्वाः(फ्), प्रभवन्त्यहरागमे।\nरात्र्यागमे प्रलीयन्ते, तत्रैवाव्यक्तसञ्ज्ञके॥8.18॥",
    
    "भूतग्रामः(स्) स एवायं(म्), भूत्वा भूत्वा प्रलीयते।\nरात्र्यागमेऽवशः(फ्) पार्थ, प्रभवत्यहरागमे॥8.19॥",
    
    "परस्तस्मात्तु भावोऽन्यो-ऽव्यक्तोऽव्यक्तात्सनातनः।\nयः(स्) स सर्वेषु भूतेषु, नश्यत्सु न विनश्यति॥8.20॥",
    
    "अव्यक्तोऽक्षर इत्युक्त:(स्), तमाहुः(फ्) परमां(ङ्) गतिम्।\nयं(म्) प्राप्य न निवर्तन्ते, तद्धाम परमं(म्) मम॥8.21॥",
    
    "पुरुषः(स्) स परः(फ्) पार्थ, भक्त्या लभ्यस्त्वनन्यया।\nयस्यान्तःस्थानि भूतानि, येन सर्वमिदं(न्) ततम्॥8.22॥",
    
    "यत्र काले त्वनावृत्तिम्, आवृत्तिं(ञ्) चैव योगिनः।\nप्रयाता यान्ति तं(ङ्) कालं(व्ँ), वक्ष्यामि भरतर्षभ॥8.23॥",
    
    "अग्निर्ज्योतिरहः(श्) शुक्लः(ष्), षण्मासा उत्तरायणम्।\nतत्र प्रयाता गच्छन्ति, ब्रह्म ब्रह्मविदो जनाः॥8.24॥",
    
    "धूमो रात्रिस्तथा कृष्णः(ष्), षण्मासा दक्षिणायनम्।\nतत्र चान्द्रमसं(ञ्) ज्योति:(र्), योगी प्राप्य निवर्तते॥8.25॥",
    
    "शुक्लकृष्णे गती ह्येते, जगतः(श्) शाश्वते मते।\nएकया यात्यनावृत्तिम्, अन्ययावर्तते पुनः॥8.26॥",
    
    "नैते सृती पार्थ जानन्, योगी मुह्यति कश्चन।\nतस्मात्सर्वेषु कालेषु, योगयुक्तो भवार्जुन॥8.27॥",
      
    "वेदेषु यज्ञेषु तपः(स्) सु चैव,\nदानेषु यत्पुण्यफलं(म्) प्रदिष्टम्।\nअत्येति तत्सर्वमिदं(व्ँ) विदित्वा,\n योगी परं(म्) स्थानमुपैति चाद्यम्॥8.28॥",
    
    "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु ब्रह्मविद्यायां(य्ँ) योगशास्त्रे\nश्रीकृष्णार्जुनसंवादे अक्षरब्रह्मयोगो नाम अष्टमोऽध्यायः॥"
  ],
  "adhyaya-9" : [
    "श्रीभगवानुवाच\nइदं(न्) तु ते गुह्यतमं(म्), प्रवक्ष्याम्यनसूयवे।\nज्ञानं(व्ँ) विज्ञानसहितं(य्ँ), यज्ज्ञात्वा मोक्ष्यसेऽशुभात्॥9.1॥",
    
    "राजविद्या राजगुह्यं(म्), पवित्रमिदमुत्तमम्।\nप्रत्यक्षावगमं(न्) धर्म्यं(म्), सुसुखं(ङ्) कर्तुमव्ययम्॥9.2॥",
    
    "अश्रद्दधानाः(फ्) पुरुषा, धर्मस्यास्य परन्तप।\nअप्राप्य  मां(न्)  निवर्तन्ते,  मृत्युसंसारवर्त्मनि॥9.3॥",
    
    "मया ततमिदं(म्) सर्वं(ञ्), जगदव्यक्तमूर्तिना।\nमत्स्थानि  सर्वभूतानि,  न  चाहं(न्)  तेष्ववस्थितः॥9.4॥",
    
    "न च मत्स्थानि भूतानि, पश्य मे योगमैश्वरम्।\nभूतभृन्न   च   भूतस्थो,   ममात्मा    भूतभावनः॥9.5॥",
    
    "यथाकाशस्थितो नित्यं(व्ँ), वायुः(स्) सर्वत्रगो महान्।\nतथा   सर्वाणि    भूतानि,   मत्स्थानीत्युपधारय॥9.6॥",
    
    "सर्वभूतानि कौन्तेय, प्रकृतिं(य्ँ) यान्ति मामिकाम्।\nकल्पक्षये  पुनस्तानि,  कल्पादौ  विसृजाम्यहम्॥9.7॥",
    
    "प्रकृतिं(म्) स्वामवष्टभ्य, विसृजामि पुनः(फ्) पुनः।\nभूतग्राममिमं(ङ्) कृत्स्नम्, अवशं(म्) प्रकृतेर्वशात्॥9.8॥",
    
    "न च मां(न्) तानि कर्माणि, निबध्नन्ति धनञ्जय।\nउदासीनवदासीनम्,  असक्तं(न्)  तेषु  कर्मसु॥9.9॥",
    
    "मयाध्यक्षेण प्रकृतिः(स्), सूयते सचराचरम्।\nहेतुनानेन     कौन्तेय,     जगद्विपरिवर्तते॥9.10॥",
    
    "अवजानन्ति मां(म्) मूढा, मानुषीं(न्) तनुमाश्रितम्।\nपरं(म्)   भावमजानन्तो,   मम   भूतमहेश्वरम्॥9.11॥",
    
    "मोघाशा मोघकर्माणो, मोघज्ञाना विचेतसः।\nराक्षसीमासुरीं(ञ्) चैव, प्रकृतिं(म्) मोहिनीं(म्) श्रिताः॥9.12॥",
    
    "महात्मानस्तु मां(म्) पार्थ, दैवीं(म्) प्रकृतिमाश्रिताः।\nभजन्त्यनन्यमनसो,    ज्ञात्वा    भूतादिमव्ययम्॥9.13॥",
    
    "सततं(ङ्) कीर्तयन्तो मां(य्ँ), यतन्तश्च दृढव्रताः।\nनमस्यन्तश्च  मां(म्)  भक्त्या,  नित्ययुक्ता   उपासते॥9.14॥",
    
    "ज्ञानयज्ञेन चाप्यन्ये, यजन्तो मामुपासते।\nएकत्वेन     पृथक्त्वेन,    बहुधा    विश्वतोमुखम्॥9.15॥",
    
    "अहं(ङ्) क्रतुरहं(य्ँ) यज्ञः(स्), स्वधाहमहमौषधम्।\nमन्त्रोऽहमहमेवाज्यम्,    अहमग्निरहं(म्)   हुतम्॥9.16॥",
    
    "पिताहमस्य जगतो, माता धाता पितामहः।\nवेद्यं(म्)    पवित्रमोङ्कार, ऋक्साम   यजुरेव    च॥9.17॥",
    
    "गतिर्भर्ता प्रभुः(स्) साक्षी, निवासः(श्) शरणं(म्) सुहृत्।\nप्रभवः(फ्) प्रलयः(स्) स्थानं(न्), निधानं(म्) बीजमव्ययम्॥9.18॥",
    
    "तपाम्यहमहं(व्ँ) वर्षं(न्), निगृह्णाम्युत्सृजामि च।\nअमृतं(ञ्)    चैव    मृत्युश्च,   सदसच्चाहमर्जुन॥9.19॥",
    
    "त्रैविद्या मां(म्) सोमपाः(फ्) पूतपापा,\nयज्ञैरिष्ट्वा स्वर्गतिं(म्) प्रार्थयन्ते।\nते पुण्यमासाद्य सुरेन्द्रलोकम्\nअश्नन्ति    दिव्यान्दिवि    देवभोगान्॥9.20॥",
    
    "ते तं(म्) भुक्त्वा स्वर्गलोकं(व्ँ) विशालं(ङ्),\nक्षीणे पुण्ये मर्त्यलोकं(व्ँ)  विशन्ति।\nएवं(न्)  त्रयीधर्ममनुप्रपन्ना,\nगतागतं(ङ्)    कामकामा    लभन्ते॥9.21॥",
    
    "अनन्याश्चिन्तयन्तो मां(य्ँ), ये जनाः(फ्) पर्युपासते।\nतेषां(न्) नित्याभियुक्तानां(य्ँ), योगक्षेमं(व्ँ) वहाम्यहम्॥9.22॥",
    
    "येऽप्यन्यदेवता भक्ता, यजन्ते श्रद्धयान्विताः।\nतेऽपि  मामेव  कौन्तेय,  यजन्त्यविधिपूर्वकम्॥9.23॥",
    
    "अहं(म्) हि सर्वयज्ञानां(म्), भोक्ता च प्रभुरेव च।\nन  तु  मामभिजानन्ति,  तत्त्वेनातश्च्यवन्ति ते॥9.24॥",
    
    "यान्ति देवव्रता देवान्, पितॄन्यान्ति पितृव्रताः।\nभूतानि यान्ति भूतेज्या, यान्ति मद्याजिनोऽपि माम्॥9.25॥",
    
    "पत्रं(म्) पुष्पं(म्) फलं(न्) तोयं(य्ँ), यो मे भक्त्या प्रयच्छति।\nतदहं(म्)    भक्त्युपहृतम्,    अश्नामि    प्रयतात्मनः॥9.26॥",
    
    "यत्करोषि यदश्नासि, यज्जुहोषि ददासि यत्।\nयत्तपस्यसि  कौन्तेय,  तत्कुरुष्व  मदर्पणम्॥9.27॥",
    
    "शुभाशुभफलैरेवं(म्), मोक्ष्यसे कर्मबन्धनैः।\nसन्न्यासयोगयुक्तात्मा, विमुक्तो मामुपैष्यसि॥9.28॥",
    
    "समोऽहं(म्) सर्वभूतेषु, न मे द्वेष्योऽस्ति न प्रियः।\nये भजन्ति तु मां(म्) भक्त्या, मयि ते तेषु चाप्यहम्॥9.29॥",
      
    "अपि चेत्सुदुराचारो, भजते मामनन्यभाक्।\nसाधुरेव स मन्तव्यः(स्), सम्यग्व्यवसितो हि सः॥9.30॥",
    
    "क्षिप्रं(म्) भवति धर्मात्मा, शश्वच्छान्तिं(न्) निगच्छति।\nकौन्तेय  प्रतिजानीहि,  न  मे  भक्तः(फ्)  प्रणश्यति॥9.31॥",
    
    "मां(म्) हि पार्थ व्यपाश्रित्य, येऽपि स्युः(फ्) पापयोनयः।\nस्त्रियो वैश्यास्तथा शूद्रा:(स्), तेऽपि यान्ति परां(ङ्) गतिम्॥9.32॥",
    
    "किं(म्) पुनर्ब्राह्मणाः(फ्) पुण्या, भक्ता राजर्षयस्तथा।\nअनित्यमसुखं(ल्ँ) लोकम्, इमं(म्) प्राप्य भजस्व माम्॥9.33॥",
    
    "मन्मना भव मद्भक्तो, मद्याजी मां(न्) नमस्कुरु।\nमामेवैष्यसि युक्त्वैवम्, आत्मानं(म्) मत्परायणः॥9.34॥",
    
    "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु ब्रह्मविद्यायां(य्ँ) योगशास्त्रे\nश्रीकृष्णार्जुनसंवादे राजविद्याराजगुह्ययोगो नाम नवमोऽध्याय:।।"
  ],
  "adhyaya-10" : [
    "श्रीभगवानुवाच\nभूय एव महाबाहो,शृणु मे परमं(व्ँ) वचः।\nयत्तेऽहं(म्) प्रीयमाणाय, वक्ष्यामि हितकाम्यया॥10.1॥",
    
    "न मे विदुः(स्) सुरगणाः(फ्), प्रभवं(न्) न महर्षयः।\nअहमादिर्हि देवानां(म्), महर्षीणां(ञ्) च सर्वशः॥10.2॥",
    
    "यो मामजमनादिं(ञ्) च,वेत्ति लोकमहेश्वरम्।\nअसम्मूढः(स्) स मर्त्येषु, सर्वपापैः(फ्) प्रमुच्यते॥10.3॥",
    
    "बुद्धिर्ज्ञानमसम्मोहः, क्षमा सत्यं(न्) दमः(श्) शमः।\nसुखं(न्) दुःखं(म्) भवोऽभावो, भयं(ञ्) चाभयमेव च॥10.4॥",
    
    "अहिंसा समता तुष्टि:(स्), तपो दानं(य्ँ) यशोऽयशः।\nभवन्ति भावा भूतानां(म्),मत्त एव पृथग्विधाः॥10.5॥",
    
    "महर्षयः(स्) सप्त पूर्वे,चत्वारो मनवस्तथा।\nमद्भावा मानसा जाता, येषां(ल्ँ) लोक इमाः(फ्) प्रजाः॥10.6॥",
    
    "एतां(व्ँ) विभूतिं(य्ँ) योगं(ञ्) च, मम यो वेत्ति तत्त्वतः।\nसोऽविकम्पेन योगेन,युज्यते नात्र संशयः॥10.7॥",
    
    "अहं(म्) सर्वस्य प्रभवो, मत्तः(स्) सर्वं(म्) प्रवर्तते।\nइति मत्वा भजन्ते मां(म्), बुधा भावसमन्विताः॥10.8॥",
    
    "मच्चित्ता मद्गतप्राणा,बोधयन्तः(फ्) परस्परम्।\nकथयन्तश्च मां(न्) नित्यं(न्), तुष्यन्ति च रमन्ति च॥10.9॥",
    
    "तेषां(म्) सततयुक्तानां(म्), भजतां(म्) प्रीतिपूर्वकम्।\nददामि बुद्धियोगं(न्) तं(य्ँ), येन मामुपयान्ति ते॥10.10॥",
    
    "तेषामेवानुकम्पार्थम्,अहमज्ञानजं(न्) तमः।\nनाशयाम्यात्मभावस्थो, ज्ञानदीपेन भास्वता॥10.11॥",
    
    "अर्जुन उवाच\nपरं(म्) ब्रह्म परं(न्) धाम, पवित्रं(म्) परमं(म्) भवान्।\nपुरुषं(म्) शाश्वतं(न्) दिव्यम्, आदिदेवमजं(व्ँ) विभुम्॥10.12॥",
    
    "आहुस्त्वामृषयः(स्) सर्वे, देवर्षिर्नारदस्तथा।\nअसितो देवलो व्यासः(स्), स्वयं(ञ्) चैव ब्रवीषि मे॥10.13॥",
    
    "सर्वमेतदृतं(म्) मन्ये, यन्मां(व्ँ) वदसि केशव।\nन हि ते भगवन्व्यक्तिं(व्ँ), विदुर्देवा न दानवाः॥10.14॥",
    
    "स्वयमेवात्मनात्मानं(व्ँ), वेत्थ त्वं(म्) पुरुषोत्तम।\nभूतभावन भूतेश,देवदेव जगत्पते॥10.15॥",
    
    "वक्तुमर्हस्यशेषेण, दिव्या ह्यात्मविभूतयः।\nयाभिर्विभूतिभिर्लोकान्, इमांस्त्वं(व्ँ) व्याप्य तिष्ठसि॥10.16॥",
    
    "कथं(व्ँ) विद्यामहं(य्ँ) योगिंस्, त्वां(म्) सदा परिचिन्तयन्।\nकेषु केषु च भावेषु,चिन्त्योऽसि भगवन्मया॥10.17॥",
    
    "विस्तरेणात्मनो योगं(व्ँ), विभूतिं(ञ्) च जनार्दन।\nभूयः(ख्) कथय तृप्तिर्हि, शृण्वतो नास्ति मेऽमृतम्॥10.18॥",
    
    "श्रीभगवानुवाच\nहन्त ते कथयिष्यामि, दिव्या ह्यात्मविभूतयः।\nप्राधान्यतः(ख्) कुरुश्रेष्ठ, नास्त्यन्तो विस्तरस्य मे॥10.19॥",
    
    "अहमात्मा गुडाकेश, सर्वभूताशयस्थितः।\nअहमादिश्च मध्यं(ञ्) च, भूतानामन्त एव च॥10.20॥",
    
    "आदित्यानामहं(व्ँ) विष्णु:(र्), ज्योतिषां(म्) रविरंशुमान्।\nमरीचिर्मरुतामस्मि, नक्षत्राणामहं(म्) शशी॥10.21॥",
    
    "वेदानां(म्) सामवेदोऽस्मि, देवानामस्मि वासवः।\nइन्द्रियाणां(म्) मनश्चास्मि, भूतानामस्मि चेतना॥10.22॥",
    
    "रुद्राणां(म्) शङ्करश्चास्मि,वित्तेशो यक्षरक्षसाम्।\nवसूनां(म्) पावकश्चास्मि, मेरुः(श्) शिखरिणामहम्॥10.23॥",
    
    "पुरोधसां(ञ्) च मुख्यं(म्) मां(व्ँ), विद्धि पार्थ बृहस्पतिम्।\nसेनानीनामहं(म्) स्कन्दः(स्),सरसामस्मि सागरः॥10.24॥",
    
    "महर्षीणां(म्) भृगुरहं(ङ्),गिरामस्म्येकमक्षरम्।\nयज्ञानां(ञ्) जपयज्ञोऽस्मि, स्थावराणां(म्) हिमालयः॥10.25॥",
    
    "अश्वत्थः(स्) सर्ववृक्षाणां(न्),देवर्षीणां(ञ्) च नारदः।\nगन्धर्वाणां(ञ्) चित्ररथः(स्), सिद्धानां(ङ्) कपिलो मुनिः॥10.26॥",
    
    "उच्चैःश्रवसमश्वानां(व्ँ),विद्धि माममृतोद्भवम्।\nऐरावतं(ङ्) गजेन्द्राणां(न्), नराणां(ञ्) च नराधिपम्॥10.27॥",
    
    "आयुधानामहं(व्ँ) वज्रं(न्), धेनूनामस्मि कामधुक्।\nप्रजनश्चास्मि कन्दर्पः(स्), सर्पाणामस्मि वासुकिः॥10.28॥",
    
    "अनन्तश्चास्मि नागानां(व्ँ), वरुणो यादसामहम्।\nपितॄणामर्यमा चास्मि, यमः(स्) संयमतामहम्॥10.29॥",
    
    "प्रह्लादश्चास्मि दैत्यानां(ङ्), कालः(ख्) कलयतामहम्।\nमृगाणां(ञ्) च मृगेन्द्रोऽहं(व्ँ), वैनतेयश्च पक्षिणाम्॥10.30॥",
    
    "पवनः(फ्) पवतामस्मि, रामः(श्) शस्त्रभृतामहम्।\nझषाणां(म्) मकरश्चास्मि, स्रोतसामस्मि जाह्नवी॥10.31॥",
    
    "सर्गाणामादिरन्तश्च,मध्यं(ञ्) चैवाहमर्जुन।\nअध्यात्मविद्या विद्यानां(व्ँ), वादः(फ्) प्रवदतामहम्॥10.32॥",
    
    "अक्षराणामकारोऽस्मि, द्वन्द्व:(स्) सामासिकस्य च।\nअहमेवाक्षयः(ख्) कालो, धाताहं(व्ँ) विश्वतोमुखः॥10.33॥",
    
    "मृत्यु(स्) सर्वहरश्चाहम्, उद्भवश्च भविष्यताम्।\nकीर्तिः(श्) श्रीर्वाक्च नारीणां(म्), स्मृतिर्मेधा धृतिः क्षमा॥10.34॥",
    
    "बृहत्साम तथा साम्नां(ङ्),गायत्री छन्दसामहम्।\nमासानां(म्) मार्गशीर्षोऽहम्, ऋतूनां(ङ्) कुसुमाकरः॥10.35॥",
    
    "द्युतं(ञ्)    छलयतामस्मि,     तेजस्तेजस्विनामहम्।\nजयोऽस्मि व्यवसायोऽस्मि, सत्त्वं(म्) सत्त्ववतामहम्॥10.36॥",
    
    "वृष्णीनां(व्ँ) वासुदेवोऽस्मि, पाण्डवानां(न्) धनञ्जयः।\nमुनीनामप्यहं(व्ँ) व्यासः(ख्), कवीनामुशना कविः॥10.37॥",
    
    "दण्डो दमयतामस्मि, नीतिरस्मि जिगीषताम्।\nमौनं(ञ्) चैवास्मि गुह्यानां(ञ्), ज्ञानं(ञ्) ज्ञानवतामहम्॥10.38॥",
    
    "यच्चापि सर्वभूतानां(म्), बीजं(न्) तदहमर्जुन।\nन तदस्ति विना यत्स्यान्, मया भूतं(ञ्) चराचरम्॥10.39॥",
    
    "नान्तोऽस्ति मम दिव्यानां(व्ँ), विभूतीनां(म्) परन्तप।\nएष तूद्देशतः(फ्) प्रोक्तो,विभूतेर्विस्तरो मया॥10.40॥",
    
    "यद्यद्विभूतिमत्सत्त्वं(म्), श्रीमदूर्जितमेव वा।\nतत्तदेवावगच्छ त्वं(म्), मम तेजोंऽशसम्भवम्॥10.41॥",
    
    "अथवा बहुनैतेन, किं(ञ्) ज्ञातेन तवार्जुन।\nविष्टभ्याहमिदं(ङ्) कृत्स्नम्, एकांशेन स्थितो जगत्॥10.42॥",
    
    "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु ब्रह्मविद्यायां(य्ँ)\nयोगशास्त्रे श्रीकृष्णार्जुनसंवादे विभूतियोगो नाम दशमोऽध्यायः॥"
  ],
  "adhyaya-11" : [
    "अर्जुन उवाच\nमदनुग्रहाय परमं(ङ्), गुह्यमध्यात्मसञ्ज्ञितम्।\nयत्त्वयोक्तं(व्ँ) वचस्तेन, मोहोऽयं(व्ँ) विगतो मम॥11.1॥",
    
    "भवाप्ययौ हि भूतानां(म्), श्रुतौ विस्तरशो मया।\nत्वत्तः(ख्) कमलपत्राक्ष, माहात्म्यमपि चाव्ययम्॥11.2॥",
    
    "एवमेतद्यथात्थ त्वम्, आत्मानं(म्) परमेश्वर।\nद्रष्टुमिच्छामि ते रूपम्, ऐश्वरं(म्) पुरुषोत्तम॥11.3॥",
    
    "मन्यसे यदि तच्छक्यं(म्), मया द्रष्टुमिति प्रभो।\nयोगेश्वर ततो मे त्वं(न्), दर्शयात्मानमव्ययम्॥11.4॥",
    
    "श्रीभगवानुवाच\nपश्य मे पार्थ रूपाणि, शतशोऽथ सहस्रशः।\nनानाविधानि दिव्यानि, नानावर्णाकृतीनि च॥11.5॥",
    
    "पश्यादित्यान्वसून् रुद्रान्, अश्विनौ मरुतस्तथा।\nबहून्यदृष्टपूर्वाणि, पश्याश्चर्याणि भारत॥11.6॥",
    
    "इहैकस्थं(ञ्) जगत्कृत्स्नं(म्), पश्याद्य सचराचरम्।\nमम देहे गुडाकेश, यच्चान्यद्द्रष्टुमिच्छसि॥11.7॥", 
    
    "न तु मां(म्) शक्यसे द्रष्टुम्, अनेनैव स्वचक्षुषा।\nदिव्यं(न्) ददामि ते चक्षुः(फ्), पश्य मे योगमैश्वरम्॥11.8॥",
    
    "सञ्जय उवाच\nएवमुक्त्वा ततो राजन्, महायोगेश्वरो हरिः।\nदर्शयामास पार्थाय, परमं(म्) रूपमैश्वरम्॥11.9॥",
    
    "अनेकवक्त्रनयनम्, अनेकाद्भुतदर्शनम्।\nअनेकदिव्याभरणं(न्), दिव्यानेकोद्यतायुधम्॥11.10॥",
    
    "दिव्यमाल्याम्बरधरं(न्), दिव्यगन्धानुलेपनम्।\nसर्वाश्चर्यमयं(न्) देवम्, अनन्तं(व्ँ) विश्वतोमुखम्॥11.11॥",
    
    "दिविसूर्यसहस्रस्य, भवेद्युगपदुत्थिता।\nयदि भाः(स्) सदृशी सा स्याद्, भासस्तस्य महात्मनः॥11.12॥",
    
    "तत्रैकस्थं(ञ्) जगत्कृत्स्नं(म्), प्रविभक्तमनेकधा।\nअपश्यद्देवदेवस्य,      शरीरे पाण्डवस्तदा॥11.13॥",
    
    "ततः(स्) स विस्मयाविष्टो, हृष्टरोमा धनञ्जयः।\nप्रणम्य शिरसा देवं(ङ्), कृताञ्जलिरभाषत॥11.14॥",
    
    "अर्जुन उवाच\nपश्यामि देवांस्तव देव देहे,\n सर्वांस्तथा भूतविशेषसङ्घान्।\nब्रह्माणमीशं(ङ्) कमलासनस्थम्\n  ऋषींश्च सर्वानुरगांश्च दिव्यान्॥11.15॥",
    
    "अनेकबाहूदरवक्त्रनेत्रं(म्),\nपश्यामि त्वां(म्) सर्वतोऽनन्तरूपम्।\nनान्तं(न्) न मध्यं(न्) न पुनस्तवादिं(म्),\nपश्यामि विश्वेश्वर विश्वरूप॥11.16॥",
    
    "किरीटिनं(ङ्) गदिनं(ञ्) चक्रिणं(ञ्) च,\nतेजोराशिं(म्) सर्वतो दीप्तिमन्तम्।\nपश्यामि त्वां(न्) दुर्निरीक्ष्यं(म्) समन्ताद्-\n दीप्तानलार्कद्युतिमप्रमेयम् ॥11.17॥",
    
    "त्वमक्षरं(म्) परमं(व्ँ) वेदितव्यं(न्),\nत्वमस्य विश्वस्य परं(न्) निधानम्।\nत्वमव्ययः(श्) शाश्वतधर्मगोप्ता,\n सनातनस्त्वं(म्) पुरुषो मतो मे॥11.18॥",
    
    "अनादिमध्यान्तमनन्तवीर्यम्,\nअनन्तबाहुं(म्) शशिसूर्यनेत्रम्।\nपश्यामि त्वां(न्) दीप्तहुताशवक्त्रं(म्),\n स्वतेजसा विश्वमिदं(न्) तपन्तम्॥11.19॥",
    
    "द्यावापृथिव्योरिदमन्तरं(म्) हि,\n व्याप्तं(न्) त्वयैकेन दिशश्च सर्वाः।\nदृष्ट्वाद्भुतं(म्) रूपमुग्रं(न्) तवेदं(ल्ँ),\n लोकत्रयं(म्) प्रव्यथितं(म्) महात्मन्॥11.20॥",
    
    "अमी हि त्वां(म्) सुरसङ्घा विशन्ति,\nकेचिद्भीताः(फ्) प्राञ्जलयो गृणन्ति।\nस्वस्तीत्युक्त्वा महर्षिसिद्धसङ्घा:(स्),\n स्तुवन्ति त्वां(म्) स्तुतिभिः(फ्) पुष्कलाभिः॥11.21॥",
    
    "रुद्रादित्या वसवो ये च साध्या-\nविश्वेऽश्विनौ मरुतश्चोष्मपाश्च।\nगन्धर्वयक्षासुरसिद्धसङ्घा,\nवीक्षन्ते त्वां(व्ँ) विस्मिताश्चैव सर्वे॥11.22॥",
    
    "रूपं(म्) महत्ते बहुवक्त्रनेत्रं(म्),\nमहाबाहो बहुबाहूरुपादम्।\nबहूदरं(म्) बहुदंष्ट्राकरालं(न्),\n दृष्ट्वा लोकाः(फ्) प्रव्यथितास्तथाहम्॥11.23॥",
    
    "नभःस्पृशं(न्) दीप्तमनेकवर्णं(व्ँ),\n व्यात्ताननं(न्) दीप्तविशालनेत्रम्।\nदृष्ट्वा हि त्वां(म्) प्रव्यथितान्तरात्मा,\n धृतिं(न्) न विन्दामि शमं(ञ्) च विष्णो॥11.24॥",
    
    "दंष्ट्राकरालानि च ते मुखानि,\n दृष्ट्वैव कालानलसन्निभानि।\nदिशो न जाने न लभे च शर्म,\n प्रसीद देवेश जगन्निवास॥11.25॥",
    
    "अमी च त्वां(न्) धृतराष्ट्रस्य पुत्राः(स्),\nसर्वे सहैवावनिपालसङ्घैः।\nभीष्मो द्रोणः(स्) सूतपुत्रस्तथासौ,\n सहास्मदीयैरपि योधमुख्यैः॥11.26॥",
    
    "वक्त्राणि ते त्वरमाणा विशन्ति,\n दंष्ट्राकरालानि भयानकानि।\nकेचिद्विलग्ना दशनान्तरेषु,\n सन्दृश्यन्ते चूर्णितैरुत्तमाङ्गै:॥11.27॥",
      
    "यथा नदीनां(म्) बहवोऽम्बुवेगाः(स्),\nसमुद्रमेवाभिमुखा द्रवन्ति।\nतथा तवामी नरलोकवीरा,\nविशन्ति वक्त्राण्यभिविज्वलन्ति॥11.28॥",
    
    "यथा प्रदीप्तं(ञ्) ज्वलनं(म्) पतङ्गा,\n विशन्ति नाशाय समृद्धवेगाः।\nतथैव नाशाय विशन्ति लोका:(स्),\n तवापि वक्त्राणि समृद्धवेगाः॥11.29॥",
    
    "लेलिह्यसे ग्रसमानः(स्) समन्ताल्,\n लोकान्समग्रान्वदनैर्ज्वलद्भिः।\nतेजोभिरापूर्य जगत्समग्रं(म्),\n भासस्तवोग्राः(फ्) प्रतपन्ति विष्णो॥11.30॥",
    
    "आख्याहि मे को भवानुग्ररूपो,\nनमोऽस्तु ते देववर प्रसीद।\nविज्ञातुमिच्छामि भवन्तमाद्यं(न्),\n न हि प्रजानामि तव प्रवृत्तिम्॥11.31॥",
    
    "श्रीभगवानुवाच\nकालोऽस्मि लोकक्षयकृत्प्रवृद्धो,\n लोकान् समाहर्तुमिह प्रवृत्तः।\nऋतेऽपि त्वां(न्) न भविष्यन्ति सर्वे,\n येऽवस्थिताः(फ्) प्रत्यनीकेषु योधाः॥11.32॥",
    
    "तस्मात्त्वमुत्तिष्ठ यशो लभस्व,\n जित्वा शत्रून्भुङ्क्ष्व राज्यं(म्) समृद्धम्।\nमयैवैते निहताः(फ्) पूर्वमेव,\nनिमित्तमात्रं(म्) भव सव्यसाचिन्॥11.33॥",
    
    "द्रोणं(ञ्) च भीष्मं(ञ्) च जयद्रथं(ञ्) च,\n कर्णं(न्) तथान्यानपि योधवीरान्।\nमया हतांस्त्वं(ञ्) जहि मा व्यथिष्ठा,\nयुध्यस्व जेतासि रणे सपत्नान्॥11.34॥",
    
    "सञ्जय उवाच\nएतच्छ्रुत्वा वचनं(ङ्) केशवस्य,\n कृताञ्जलिर्वेपमानः(ख्) किरीटी ।\nनमस्कृत्वा भूय एवाह कृष्णं(म्),\n सगद्गदं(म्) भीतभीतः(फ्) प्रणम्य ॥11.35॥",
    
    "अर्जुन उवाच\nस्थाने हृषीकेश तव प्रकीर्त्या,\nजगत्प्रहृष्यत्यनुरज्यते च।\nरक्षांसि भीतानि दिशो द्रवन्ति,\nसर्वे नमस्यन्ति च सिद्धसङ्घा:॥11.36॥",
    
    "कस्माच्च ते न नमेरन्महात्मन्,\n गरीयसे ब्रह्मणोऽप्यादिकर्त्रे।\nअनन्त देवेश जगन्निवास,\nत्वमक्षरं(म्) सदसत्तत्परं(य्ँ) यत्॥11.37॥",
    
    "त्वमादिदेवः(फ्) पुरुषः(फ्) पुराण:(स्),\nत्वमस्य विश्वस्य परं(न्) निधानम्।\nवेत्तासि वेद्यं(ञ्) च परं(ञ्) च धाम,\n त्वया ततं(व्ँ) विश्वमनन्तरूप॥11.38॥",
    
    "वायुर्यमोऽग्निर्वरुणः(श्) शशाङ्क:(फ्),\nप्रजापतिस्त्वं(म्) प्रपितामहश्च।\nनमो नमस्तेऽस्तु सहस्रकृत्वः(फ्),\nपुनश्च भूयोऽपि नमो नमस्ते॥11.39॥",
    
    "नमः(फ्) पुरस्तादथ पृष्ठतस्ते,\nनमोऽस्तु ते सर्वत एव सर्व।\nअनन्तवीर्यामितविक्रमस्त्वं(म्),\nसर्वं(म्) समाप्नोषि ततोऽसि सर्वः॥11.40॥",
    
    "सखेति मत्वा प्रसभं(य्ँ) यदुक्तं(म्),\nहे कृष्ण हे यादव हे सखेति।\nअजानता महिमानं(न्) तवेदं(म्),\nमया प्रमादात्प्रणयेन वापि॥11.41॥",
    
    "यच्चावहासार्थमसत्कृतोऽसि,\nविहारशय्यासनभोजनेषु।\nएकोऽथवाप्यच्युत तत्समक्षं(न्),\n तत्क्षामये त्वामहमप्रमेयम्॥11.42॥",
    
    "पितासि लोकस्य चराचरस्य,\n त्वमस्य पूज्यश्च गुरुर्गरीयान्।\nन त्वत्समोऽस्त्यभ्यधिकः(ख्) कुतोऽन्यो,\n लोकत्रयेऽप्यप्रतिमप्रभाव॥11.43॥",
    
    "तस्मात्प्रणम्य प्रणिधाय कायं(म्),\n प्रसादये त्वामहमीशमीड्यम्।\nपितेव पुत्रस्य सखेव सख्युः(फ्),\nप्रियः(फ्) प्रियायार्हसि देव सोढुम्॥11.44॥",
    
    "अदृष्टपूर्वं(म्) हृषितोऽस्मि दृष्ट्वा,\n भयेन च प्रव्यथितं(म्) मनो मे।\nतदेव मे दर्शय देवरूपं(म्),\nप्रसीद देवेश जगन्निवास॥11.45॥",
    
    "किरीटिनं(ङ्) गदिनं(ञ्) चक्रहस्तम्,\nइच्छामि त्वां(न्) द्रष्टुमहं(न्) तथैव।\nतेनैव रूपेण चतुर्भुजेन,\n सहस्रबाहो भव विश्वमूर्ते॥11.46॥",
    
    "श्रीभगवानुवाच\nमया प्रसन्नेन तवार्जुनेदं(म्),\n रूपं(म्) परं(न्) दर्शितमात्मयोगात्।\nतेजोमयं(व्ँ) विश्वमनन्तमाद्यं(य्ँ),\nयन्मे त्वदन्येन न दृष्टपूर्वम्॥11.47॥",
    
    "न वेदयज्ञाध्ययनैर्न दानै: (र्),\n न च क्रियाभिर्न तपोभिरुग्रैः।\nएवं(म्)रूपः(श्) शक्य अहं(न्) नृलोके,\nद्रष्टुं(न्) त्वदन्येन कुरुप्रवीर॥11.48॥",
    
    "मा ते व्यथा मा च विमूढभावो,\nदृष्ट्वा रूपं(ङ्) घोरमीदृङ्ममेदम्।\nव्यपेतभीः(फ्) प्रीतमनाः(फ्) पुनस्त्वं(न्),\n तदेव मे रूपमिदं(म्) प्रपश्य॥11.49॥",
    
    "सञ्जय उवाच\nइत्यर्जुनं(म्) वासुदेवस्तथोक्त्वा,\nस्वकं (म्) रूपं(न्) दर्शयामास भूयः।\nआश्वासयामास च भीतमेनं(म्),\n भूत्वा पुनः(स्) सौम्यवपुर्महात्मा॥11.50॥",
    
    "अर्जुन उवाच\nदृष्ट्वेदं(म्) मानुषं(म्) रूपं(न्), तव सौम्यं(ञ्) जनार्दन।\nइदानीमस्मि संवृत्तः(स्), सचेताः(फ्) प्रकृतिं(ङ्) गतः॥11.51॥",
    
    "श्रीभगवानुवाच\nसुदुर्दर्शमिदं(म्) रूपं(न्), दृष्टवानसि यन्मम।\nदेवा अप्यस्य रूपस्य, नित्यं(न्) दर्शनकाङ्क्षिणः॥11.52॥",
    
    "नाहं(व्ँ) वेदैर्न तपसा, न दानेन न चेज्यया।\nशक्य एवंविधो द्रष्टुं(न्), दृष्टवानसि मां यथा॥11.53॥",
    
    "भक्त्या त्वनन्यया शक्य, अहमेवंविधोऽर्जुन।\nज्ञातुं(न्) द्रष्टुं(ञ्) च तत्त्वेन, प्रवेष्टुं(ञ्) च परन्तप ॥11.54॥",
    
    "मत्कर्मकृन्मत्परमो, मद्भक्तः (स्) सङ्गवर्जितः।\nनिर्वैरः (स्) सर्वभूतेषु, यः (स्)  स मामेति पाण्डव ॥11.55॥",
    
    "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु ब्रह्मविद्यायां (य्ँ) योगशास्त्रे\nश्रीकृष्णार्जुनसंवादे 'विश्वरूपदर्शनयोगो' नामैकादशोऽध्यायः ॥11॥"
  ],
  "adhyaya-12" : [
    "अर्जुन उवाच\nएवं(म्) सततयुक्ता ये, भक्तास्त्वां(म्) पर्युपासते।\n ये चाप्यक्षरमव्यक्तं (न्), तेषां(ङ्) के योगवित्तमाः॥12.1॥",
    
    "श्रीभगवानुवाच\nमय्यावेश्य मनो ये मां(न्), नित्ययुक्ता उपासते।\n श्रद्धया परयोपेता:(स्), ते मे युक्ततमा मताः॥12.2॥",
    
    "ये त्वक्षरमनिर्देश्यम्, अव्यक्तं(म्) पर्युपासते।\n सर्वत्रगमचिन्त्यं(ञ्) च, कूटस्थमचलं(न्) ध्रुवम्॥12.3॥",
    
    "सन्नियम्येन्द्रियग्रामं(म्), सर्वत्र समबुद्धयः।\n ते प्राप्नुवन्ति मामेव, सर्वभूतहिते रताः॥12.4॥",
    
    "क्लेशोऽधिकतरस्तेषाम्, अव्यक्तासक्तचेतसाम्।\n अव्यक्ता हि गतिर्दुःखं(न्), देहवद्भिरवाप्यते॥12.5॥",
    
    "ये तु सर्वाणि कर्माणि, मयि सन्न्यस्य मत्पराः।\n अनन्येनैव योगेन, मां(न्) ध्यायन्त उपासते॥12.6॥",
    
    "तेषामहं(म्) समुद्धर्ता, मृत्युसंसारसागरात्।\n भवामि नचिरात्पार्थ, मय्यावेशितचेतसाम्॥12.7॥",
    
    "मय्येव मन आधत्स्व, मयि बुद्धिं(न्) निवेशय।\n निवसिष्यसि मय्येव, अत ऊर्ध्वं(न्) न संशयः॥12.8॥",
    
    "अथ चित्तं(म्) समाधातुं(न्), न शक्नोषि मयि स्थिरम्।\n अभ्यासयोगेन ततो, मामिच्छाप्तुं(न्) धनञ्जय॥12.9॥",
    
    "अभ्यासेऽप्यसमर्थोऽसि, मत्कर्मपरमो भव।\n मदर्थमपि कर्माणि, कुर्वन्सिद्धिमवाप्स्यसि॥12.10॥",
    
    "अथैतदप्यशक्तोऽसि, कर्तुं(म्) मद्योगमाश्रितः।\n सर्वकर्मफलत्यागं(न्), ततः(ख्) कुरु यतात्मवान्॥12.11॥",
    
    "श्रेयो हि ज्ञानमभ्यासाज्, ज्ञानाद्ध्यानं(व्ँ) विशिष्यते।\n ध्यानात्कर्मफलत्याग:(स्),त्यागाच्छान्तिरनन्तरम्॥12.12॥",
    
    "अद्वेष्टा सर्वभूतानां(म्), मैत्रः(ख्) करुण एव च।\n निर्ममो निरहङ्कारः(स्), समदुःखसुखः क्षमी॥12.13॥",
    
    "सन्तुष्टः(स्) सततं(य्ँ) योगी, यतात्मा दृढनिश्चयः।\n मय्यर्पितमनोबुद्धि:(र्), यो मद्भक्तः(स्) स मे प्रियः॥12.14॥",
    
    "यस्मान्नोद्विजते लोको, लोकान्नोद्विजते च यः।\n हर्षामर्षभयोद्वेगै:(र्),  मुक्तो यः(स्) स च मे प्रियः॥12.15॥",
    
    "अनपेक्षः(श्) शुचिर्दक्ष, उदासीनो गतव्यथः।\n सर्वारम्भपरित्यागी, यो मद्भक्तः(स्) स मे प्रियः॥12.16॥",
    
    "यो न हृष्यति न द्वेष्टि, न शोचति न काङ्क्षति।\n शुभाशुभपरित्यागी, भक्तिमान्यः(स्) स मे प्रियः॥12.17॥",
    
    "समः(श्) शत्रौ च मित्रे च, तथा मानापमानयोः।\n शीतोष्णसुखदुःखेषु, समः(स्) सङ्गविवर्जितः॥12.18॥",
    
    "तुल्यनिन्दास्तुतिर्मौनी, सन्तुष्टो येन केनचित्।\n अनिकेतः(स्) स्थिरमति:(र्), भक्तिमान्मे प्रियो नरः॥12.19॥",
    
    "ये तु धर्म्यामृतमिदं(य्ँ), यथोक्तं(म्) पर्युपासते।\n श्रद्दधाना मत्परमा, भक्तास्तेऽतीव मे प्रियाः॥12.20॥",
    
    "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु ब्रह्मविद्यायां(य्ँ) योगशास्त्रे\nश्रीकृष्णार्जुनसंवादे भक्तियोगो नाम द्वादशोऽध्यायः॥"
  ],
  "adhyaya-13" : [
    "श्रीभगवानुवाच\nइदं(म्) शरीरं(ङ्) कौन्तेय, क्षेत्रमित्यभिधीयते।\nएतद्यो वेत्ति तं(म्) प्राहुः, क्षेत्रज्ञ इति तद्विदः॥13.1॥",
    
    "क्षेत्रज्ञं(ञ्) चापि मां(व्ँ) विद्धि, सर्वक्षेत्रेषु भारत।\nक्षेत्रक्षेत्रज्ञयोर्ज्ञानं(य्ँ), यत्तज्ज्ञानं(म्) मतं(म्) मम॥13.2॥",
    
    "तत्क्षेत्रं(य्ँ) यच्च यादृक्च, यद्विकारि यतश्च यत्।\nस च यो यत्प्रभावश्च, तत्समासेन मे शृणु॥13.3॥",
    
    "ऋषिभिर्बहुधा गीतं(ञ्), छन्दोभिर्विविधैः(फ्) पृथक्।\nब्रह्मसूत्रपदैश्चैव, हेतुमद्भिर्विनिश्चितैः॥13.4॥",
    
    "महाभूतान्यहङ्कारो, बुद्धिरव्यक्तमेव च।\nइन्द्रियाणि दशैकं(ञ्) च, पञ्च चेन्द्रियगोचराः॥13.5॥",
    
    "इच्छा द्वेषः(स्) सुखं(न्) दुःखं(म्), सङ्घातश्चेतना धृतिः।\nएतत्क्षेत्रं(म्) समासेन, सविकारमुदाहृतम्॥13.6॥",
    
    "अमानित्वमदम्भित्वम्, अहिंसा क्षान्तिरार्जवम्।\nआचार्योपासनं(म्) शौचं(म्), स्थैर्यमात्मविनिग्रहः॥13.7॥",
    
    "इन्द्रियार्थेषु वैराग्यम्, अनहङ्कार एव च।\nजन्ममृत्युजराव्याधि, दुःखदोषानुदर्शनम्॥13.8॥",
    
    "असक्तिरनभिष्वङ्ग:(फ्), पुत्रदारगृहादिषु।\nनित्यं(ञ्) च समचित्तत्वम्, इष्टानिष्टोपपत्तिषु॥13.9॥",
    
    "मयि चानन्ययोगेन, भक्तिरव्यभिचारिणी।\nविविक्तदेशसेवित्वम्, अरतिर्जनसंसदि॥13.10॥",
    
    "अध्यात्मज्ञाननित्यत्वं(न्), तत्त्वज्ञानार्थदर्शनम्।\nएतज्ज्ञानमिति प्रोक्तम्, अज्ञानं(य्ँ) यदतोऽन्यथा॥13.11॥",
    
    "ज्ञेयं(य्ँ) यत्तत्प्रवक्ष्यामि, यज्ज्ञात्वामृतमश्नुते।\nअनादिमत्परं(म्) ब्रह्म, न सत्तन्नासदुच्यते॥13.12॥",
    
    "सर्वतः(फ्) पाणिपादं(न्) तत्, सर्वतोऽक्षिशिरोमुखम्।\nसर्वतः(श्) श्रुतिमल्लोके, सर्वमावृत्य तिष्ठति॥13.13॥",
    
    "सर्वेन्द्रियगुणाभासं(म्), सर्वेन्द्रियविवर्जितम्।\nअसक्तं(म्) सर्वभृच्चैव, निर्गुणं(ङ्) गुणभोक्तृ च॥13.14॥",
    
    "बहिरन्तश्च भूतानाम्, अचरं(ञ्) चरमेव च।\nसूक्ष्मत्वात्तदविज्ञेयं(न्), दूरस्थं(ञ्) चान्तिके च तत्॥13.15॥",
    
    "अविभक्तं(ञ्) च भूतेषु, विभक्तमिव च स्थितम्।\nभूतभर्तृ च तज्ज्ञेयं(ङ्),  ग्रसिष्णु प्रभविष्णु च॥13.16॥",
    
    "ज्योतिषामपि तज्ज्योति:(स्), तमसः(फ्) परमुच्यते।\nज्ञानं(ञ्) ज्ञेयं(ञ्) ज्ञानगम्यं(म्), हृदि सर्वस्य विष्ठितम्॥13.17॥",
    
    "इति क्षेत्रं(न्) तथा ज्ञानं(ञ्), ज्ञेयं(ञ्) चोक्तं(म्) समासतः।\nमद्भक्त एतद्विज्ञाय, मद्भावायोपपद्यते॥13.18॥",
    
    "प्रकृतिं(म्) पुरुषं(ञ्) चैव, विद्ध्यनादी उभावपि।\nविकारांश्च गुणांश्चैव, विद्धि प्रकृतिसम्भवान्॥13.19॥",
    
    "कार्यकरणकर्तृत्वे, हेतुः(फ्) प्रकृतिरुच्यते।\nपुरुषः(स्) सुखदुःखानां(म्), भोक्तृत्वे हेतुरुच्यते॥13.20॥",
    
    "पुरुषः(फ्) प्रकृतिस्थो हि, भुङ्क्ते प्रकृतिजान्गुणान्।\nकारणं(ङ्) गुणसङ्गोऽस्य, सदसद्योनिजन्मसु॥13.21॥",
    
    "उपद्रष्टानुमन्ता च, भर्ता भोक्ता महेश्वरः।\nपरमात्मेति चाप्युक्तो, देहेऽस्मिन्पुरुषः(फ्) परः॥13.22॥",
    
    "य एवं(व्ँ) वेत्ति पुरुषं(म्), प्रकृतिं(ञ्) च गुणैः(स्) सह।\nसर्वथा वर्तमानोऽपि, न स भूयोऽभिजायते॥13.23॥",
    
    "ध्यानेनात्मनि पश्यन्ति, केचिदात्मानमात्मना।\nअन्ये साङ्ख्येन योगेन, कर्मयोगेन चापरे॥13.24॥",
    
    "अन्ये त्वेवमजानन्तः(श्), श्रुत्वान्येभ्य उपासते।\nतेऽपि चातितरन्त्येव, मृत्युं(म्) श्रुतिपरायणाः॥13.25॥",
    
    "यावत्सञ्जायते किञ्चित्, सत्त्वं(म्) स्थावरजङ्गमम्।\nक्षेत्रक्षेत्रज्ञसंयोगात्, तद्विद्धि भरतर्षभ॥13.26॥",
    
    "समं(म्) सर्वेषु भूतेषु, तिष्ठन्तं(म्) परमेश्वरम्।\nविनश्यत्स्वविनश्यन्तं(य्ँ), यः(फ्) पश्यति स पश्यति ॥13.27॥",
    
    "समं(म्) पश्यन्हि सर्वत्र, समवस्थितमीश्वरम्।\nन हिनस्त्यात्मनात्मानं(न्), ततो याति परां(ङ्) गतिम्॥13.28॥",
    
    "प्रकृत्यैव च कर्माणि, क्रियमाणानि सर्वशः।\nयः(फ्) पश्यति तथात्मानम्, अकर्तारं(म्) स पश्यति॥13.29॥",
    
    "यदा भूतपृथग्भावम्, एकस्थमनुपश्यति।\nतत एव च विस्तारं(म्), ब्रह्म सम्पद्यते तदा॥13.30॥",
    
    "अनादित्वान्निर्गुणत्वात्, परमात्मायमव्ययः।\nशरीरस्थोऽपि कौन्तेय, न करोति न लिप्यते॥13.31॥",
    
    "यथा सर्वगतं(म्) सौक्ष्म्याद्, आकाशं(न्) नोपलिप्यते।\nसर्वत्रावस्थितो देहे, तथात्मा नोपलिप्यते॥13.32॥",
    
    "यथा प्रकाशयत्येकः(ख्), कृत्स्नं(ल्ँ) लोकमिमं(म्) रविः।\nक्षेत्रं(ङ्) क्षेत्री तथा कृत्स्नं(म्), प्रकाशयति भारत॥13.33॥",
    
    "क्षेत्रक्षेत्रज्ञयोरेवम्, अन्तरं(ञ्) ज्ञानचक्षुषा।\nभूतप्रकृतिमोक्षं(ञ्) च, ये विदुर्यान्ति ते परम्॥13.34॥",
    
    "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु ब्रह्मविद्यायां(य्ँ) योगशास्त्रे\nश्रीकृष्णार्जुनसंवादे क्षेत्रक्षेत्रज्ञविभागयोगो नाम त्रयोदशोऽध्यायः॥"
  ],
  "adhyaya-14" : [
    "श्रीभगवानुवाच\nपरं(म्) भूयः(फ्) प्रवक्ष्यामि, ज्ञानानां(ञ्) ज्ञानमुत्तमम्।\nयज्ज्ञात्वा मुनयः(स्) सर्वे, परां(म्) सिद्धिमितो गताः॥14.1॥",
    
    "इदं(ञ्) ज्ञानमुपाश्रित्य, मम साधर्म्यमागताः।\nसर्गेऽपि नोपजायन्ते, प्रलये न व्यथन्ति च॥14.2॥",
    
    "मम योनिर्महद्ब्रह्म, तस्मिन्गर्भं(न्) दधाम्यहम्।\nसम्भवः(स्) सर्वभूतानां(न्), ततो भवति भारत॥14.3॥",
    
    "सर्वयोनिषु कौन्तेय, मूर्तयः(स्)  सम्भवन्ति याः।\nतासां(म्) ब्रह्म महद्योनि:(र्), अहं(म्) बीजप्रदः(फ्) पिता॥14.4॥",
    
    "सत्त्वं(म्) रजस्तम इति, गुणाः(फ्) प्रकृतिसम्भवाः।\nनिबध्नन्ति महाबाहो, देहे देहिनमव्ययम्॥14.5॥",
    
    "तत्र सत्त्वं(न्) निर्मलत्वात्, प्रकाशकमनामयम्।\nसुखसङ्गेन बध्नाति, ज्ञानसङ्गेन चानघ॥14.6॥",
    
    "रजो रागात्मकं(व्ँ) विद्धि, तृष्णासङ्गसमुद्भवम्।\nतन्निबध्नाति कौन्तेय, कर्मसङ्गेन देहिनम्॥14.7॥",
    
    "तमस्त्वज्ञानजं(व्ँ) विद्धि, मोहनं(म्) सर्वदेहिनाम्।\nप्रमादालस्यनिद्राभि:(स्), तन्निबध्नाति भारत॥14.8॥",
    
    "सत्त्वं(म्) सुखे सञ्जयति, रजः(ख्) कर्मणि भारत।\nज्ञानमावृत्य तु तमः(फ्), प्रमादे सञ्जयत्युत॥14.9॥",
    
    "रजस्तमश्चाभिभूय, सत्त्वं(म्) भवति भारत।\nरजः(स्) सत्त्वं(न्) तमश्चैव, तमः(स्) सत्त्वं(म्) रजस्तथा॥14.10॥",
    
    "सर्वद्वारेषु देहेऽस्मिन्, प्रकाश उपजायते।\nज्ञानं(य्ँ) यदा तदा विद्याद्, विवृद्धं(म्) सत्त्वमित्युत॥14.11॥",
    
    "लोभः(फ्) प्रवृत्तिरारम्भः(ख्), कर्मणामशमः(स्) स्पृहा।\nरजस्येतानि जायन्ते, विवृद्धे भरतर्षभ॥14.12॥",
    
    "अप्रकाशोऽप्रवृत्तिश्च, प्रमादो मोह एव च।\nतमस्येतानि जायन्ते, विवृद्धे कुरुनन्दन॥14.13॥",
    
    "यदा सत्त्वे प्रवृद्धे तु, प्रलयं(य्ँ) याति देहभृत्।\nतदोत्तमविदां(ल्ँ) लोकान्, अमलान्प्रतिपद्यते॥14.14॥",
    
    "रजसि प्रलयं(ङ्) गत्वा, कर्मसङ्गिषु जायते।\nतथा प्रलीनस्तमसि, मूढयोनिषु जायते॥14.15॥",
    
    "कर्मणः(स्) सुकृतस्याहुः(स्), सात्त्विकं(न्) निर्मलं(म्) फलम्।\nरजसस्तु फलं(न्) दुःखम्, अज्ञानं(न्) तमसः(फ्) फलम्॥14.16॥",
    
    "सत्त्वात्सञ्जायते ज्ञानं(म्), रजसो लोभ एव च।\nप्रमादमोहौ तमसो, भवतोऽज्ञानमेव च॥14.17॥",
    
    "ऊर्ध्वं(ङ्) गच्छन्ति सत्त्वस्था, मध्ये तिष्ठन्ति राजसाः।\nजघन्यगुणवृत्तिस्था, अधो गच्छन्ति तामसाः॥14.18॥",
    
    "नान्यं(ङ्) गुणेभ्यः(ख्) कर्तारं(य्ँ), यदा द्रष्टानुपश्यति।\nगुणेभ्यश्च परं(व्ँ) वेत्ति, मद्भावं(म्) सोऽधिगच्छति॥14.19॥",
    
    "गुणानेतानतीत्य त्रीन्, देही देहसमुद्भवान्।\nजन्ममृत्युजरादुःखै:(र्), विमुक्तोऽमृतमश्नुते॥14.20॥",
    
    "अर्जुन उवाच\nकैर्लिङ्गैस्त्रीन्गुणानेतान्, अतीतो भवति प्रभो।\nकिमाचारः(ख्) कथं(ञ्) चैतांस्, त्रीन्गुणानतिवर्तते॥14.21॥",
    
    "श्रीभगवानुवाच\nप्रकाशं(ञ्) च प्रवृत्तिं(ञ्) च, मोहमेव च पाण्डव।\nन द्वेष्टि सम्प्रवृत्तानि, न निवृत्तानि काङ्क्षति॥14.22॥",
    
    "उदासीनवदासीनो, गुणैर्यो न विचाल्यते।\nगुणा वर्तन्त इत्येव, योऽवतिष्ठति नेङ्गते॥14.23॥",
    
    "समदुःखसुखः(स्) स्वस्थः(स्), समलोष्टाश्मकाञ्चनः।\nतुल्यप्रियाप्रियो धीर:(स्), तुल्यनिन्दात्मसंस्तुतिः॥14.24॥",
    
    "मानापमानयोस्तुल्य:(स्), तुल्यो मित्रारिपक्षयोः।\nसर्वारम्भपरित्यागी,  गुणातीतः(स्) स उच्यते॥14.25॥",
    
    "मां(ञ्) च योऽव्यभिचारेण, भक्तियोगेन सेवते।\nस गुणान्समतीत्यैतान्, ब्रह्मभूयाय कल्पते॥14.26॥",
    
    "ब्रह्मणो हि प्रतिष्ठाहम्, अमृतस्याव्ययस्य च।\nशाश्वतस्य च धर्मस्य, सुखस्यैकान्तिकस्य च॥14.27॥",
    
    "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु ब्रह्मविद्यायां(य्ँ) योगशास्त्रे\nश्रीकृष्णार्जुनसंवादे गुणत्रयविभागयोगो नाम चतुर्दशोऽध्याय:॥"
  ],
  "adhyaya-15" : [
    "श्रीभगवानुवाच\nऊर्ध्वमूलमधः(श्) शाखम्, अश्वत्थं(म्) प्राहुरव्ययम्।\nछन्दांसि यस्य पर्णानि, यस्तं(व्ँ) वेद स वेदवित्॥15.1॥",
    
    "अधश्चोर्ध्वं(म्) प्रसृतास्तस्य शाखा, गुणप्रवृद्धा विषयप्रवालाः।\nअधश्च मूलान्यनुसन्ततानि, कर्मानुबन्धीनि मनुष्यलोके॥15.2॥",
    
    "न रूपमस्येह तथोपलभ्यते, नान्तो न चादिर्न च सम्प्रतिष्ठा ।\nअश्वत्थमेनं(म्) सुविरूढमूलम्, असङ्गशस्त्रेण दृढेन छित्त्वा॥15.3॥",
    
    "ततः(फ्) पदं(न्) तत्परिमार्गितव्यं(य्ँ), यस्मिन्गता न निवर्तन्ति भूयः।\nतमेव चाद्यं(म्) पुरुषं(म्) प्रपद्ये, यतः(फ्) प्रवृत्तिः(फ्) प्रसृता पुराणी॥15.4॥",
    
    "निर्मानमोहा जितसङ्गदोषा, अध्यात्मनित्या विनिवृत्तकामाः।\nद्वन्द्वैर्विमुक्ताः(स्) सुखदुःखसञ्ज्ञै:(र्), गच्छन्त्यमूढाः(फ्) पदमव्ययं(न्) तत्॥15.5॥",
    
    "न तद्भासयते सूर्यो, न शशाङ्को न पावकः।\nयद्गत्वा न निवर्तन्ते, तद्धाम परमं(म्) मम॥15.6॥",
    
    "ममैवांशो जीवलोके, जीवभूतः(स्) सनातनः।\nमनः(ष्) षष्ठानीन्द्रियाणि, प्रकृतिस्थानि कर्षति॥15.7॥",
    
    "शरीरं(य्ँ) यदवाप्नोति,  यच्चाप्युत्क्रामतीश्वरः।\nगृहीत्वैतानि संयाति,  वायुर्गन्धानिवाशयात्॥15.8॥",
    
    "श्रोत्रं(ञ्) चक्षुः(स्) स्पर्शनं(ञ्) च,  रसनं(ङ्) घ्राणमेव च।\nअधिष्ठाय मनश्चायं(व्ँ), विषयानुपसेवते॥15.9॥",
    
    "उत्क्रामन्तं(म्) स्थितं(व्ँ) वापि, भुञ्जानं(व्ँ) वा गुणान्वितम्।\nविमूढा नानुपश्यन्ति, पश्यन्ति ज्ञानचक्षुषः॥15.10॥",
    
    "यतन्तो योगिनश्चैनं(म्), पश्यन्त्यात्मन्यवस्थितम्।\nयतन्तोऽप्यकृतात्मानो,नैनं(म्) पश्यन्त्यचेतसः॥15.11॥",
    
    "यदादित्यगतं(न्) तेजो, जगद्भासयतेऽखिलम्।\nयच्चन्द्रमसि यच्चाग्नौ,तत्तेजो विद्धि मामकम्॥15.12॥",
    
    "गामाविश्य च भूतानि, धारयाम्यहमोजसा।\nपुष्णामि चौषधीः(स्) सर्वाः(स्),  सोमो भूत्वा रसात्मकः॥15.13॥",
    
    "अहं(व्ँ) वैश्वानरो भूत्वा, प्राणिनां(न्) देहमाश्रितः।\nप्राणापानसमायुक्तः(फ्), पचाम्यन्नं(ञ्) चतुर्विधम्॥15.14॥",
    
    "सर्वस्य चाहं(म्) हृदि सन्निविष्टो, मत्तः(स्) स्मृतिर्ज्ञानमपोहनं(ञ्) च।\nवेदैश्च सर्वैरहमेव वेद्यो, वेदान्तकृद्वेदविदेव चाहम्॥15.15॥",
    
    "द्वाविमौ पुरुषौ लोके, क्षरश्चाक्षर एव च।\nक्षरः(स्) सर्वाणि भूतानि,  कूटस्थोऽक्षर उच्यते॥15.16॥",
    
    "उत्तमः(फ्) पुरुषस्त्वन्यः(फ्),  परमात्मेत्युदाहृतः।\nयो लोकत्रयमाविश्य, बिभर्त्यव्यय ईश्वरः॥15.17॥",
    
    "यस्मात्क्षरमतीतोऽहम्, अक्षरादपि चोत्तमः।\nअतोऽस्मि लोके वेदे च,  प्रथितः(फ्) पुरुषोत्तमः॥15.18॥",
    
    "यो मामेवमसम्मूढो, जानाति पुरुषोत्तमम्।\nस सर्वविद्भजति मां(म्),सर्वभावेन भारत॥15.19॥",
    
    "इति गुह्यतमं(म्) शास्त्रम्, इदमुक्तं(म्) मयानघ।\nएतद्बुद्ध्वा बुद्धिमान्स्यात्, कृतकृत्यश्च भारत॥15.20॥",
    
    "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु ब्रह्मविद्यायां(य्ँ) योगशास्त्रे\nश्रीकृष्णार्जुनसंवादे पुरुषोत्तमयोगो नाम पञ्चदशोऽध्याय:॥"
  ],
  "adhyaya-16" : [
    "श्रीभगवानुवाच\nअभयं(म्) सत्त्वसंशुद्धिः(र्), ज्ञानयोगव्यवस्थितिः।\nदानं(न्) दमश्च यज्ञश्च, स्वाध्यायस्तप आर्जवम्।।16.1।।",
    
    "अहिंसा सत्यमक्रोध:(स्),  त्यागः(श्) शान्तिरपैशुनम् ।\nदया भूतेष्वलोलुप्त्वं(म्), मार्दवं(म्) ह्रीरचापलम्।।16.2।।",
    
    "तेजः क्षमा धृतिः(श्) शौचम्, अद्रोहो नातिमानिता।\nभवन्ति सम्पदं(न्) दैवीम्, अभिजातस्य भारत।।16.3।।",
    
    "दम्भो दर्पोऽभिमानश्च, क्रोधः(फ्) पारुष्यमेव च।\nअज्ञानं(ञ्) चाभिजातस्य, पार्थ सम्पदमासुरीम्।।16.4।।",
    
    "दैवी सम्पद्विमोक्षाय, निबन्धायासुरी मता।\nमा शुचः(स्) सम्पदं(न्) दैवीम्, अभिजातोऽसि पाण्डव।।16.5।।",
    
    "द्वौ भूतसर्गौ लोकेऽस्मिन्, दैव आसुर एव च।\nदैवो विस्तरशः(फ्) प्रोक्त , आसुरं(म्) पार्थ मे शृणु।।16.6।।",
    
    "प्रवृत्तिं(ञ्) च निवृत्तिं(ञ्) च, जना न विदुरासुराः।\nन शौचं(न्) नापि चाचारो, न सत्यं(न्) तेषु विद्यते।।16.7।।",
    
    "असत्यमप्रतिष्ठं(न्) ते, जगदाहुरनीश्वरम्।\nअपरस्परसम्भूतं(ङ्), किमन्यत्कामहैतुकम्।।16.8।।",
    
    "एतां(न्) दृष्टिमवष्टभ्य, नष्टात्मानोऽल्पबुद्धयः।\nप्रभवन्त्युग्रकर्माणः, क्षयाय जगतोऽहिताः।।16.9।।",
    
    "काममाश्रित्य दुष्पूरं(न्), दम्भमानमदान्विताः।\nमोहाद्गृहीत्वासद्ग्राहान्,   प्रवर्तन्तेऽशुचिव्रताः।।16.10।।",
    
    "चिन्तामपरिमेयां(ञ्) च, प्रलयान्तामुपाश्रिताः।\nकामोपभोगपरमा, एतावदिति निश्चिताः।।16.11।।",
    
    "आशापाशशतैर्बद्धाः(ख्), कामक्रोधपरायणाः।\nईहन्ते कामभोगार्थम्, अन्यायेनार्थसञ्चयान्।।16.12।।",
    
    "इदमद्य मया लब्धम्, इमं(म्) प्राप्स्ये मनोरथम्।\nइदमस्तीदमपि मे, भविष्यति पुनर्धनम्।।16.13।।",
    
    "असौ मया हतः(श्) शत्रु:(र्), हनिष्ये चापरानपि।\nईश्वरोऽहमहं(म्) भोगी, सिद्धोऽहं(म्) बलवान्सुखी।।16.14।।",
    
    "आढ्योऽभिजनवानस्मि, कोऽन्योऽस्ति सदृशो मया।\nयक्ष्ये दास्यामि मोदिष्य, इत्यज्ञानविमोहिताः।।16.15।।",
    
    "अनेकचित्तविभ्रान्ता, मोहजालसमावृताः।\nप्रसक्ताः(ख्) कामभोगेषु, पतन्ति नरकेऽशुचौ।।16.16।।",
    
    "आत्मसम्भाविताः(स्) स्तब्धा, धनमानमदान्विताः।\nयजन्ते नामयज्ञैस्ते, दम्भेनाविधिपूर्वकम्।।16.17।।",
    
    "अहङ्कारं(म्) बलं(न्) दर्पं(ङ्), कामं(ङ्) क्रोधं(ञ्) च संश्रिताः।\nमामात्मपरदेहेषु, प्रद्विषन्तोऽभ्यसूयकाः।।16.18।।",
    
    "तानहं(न्) द्विषतः(ख्) क्रूरान् , संसारेषु  नराधमान्।\nक्षिपाम्यजस्रमशुभान्, आसुरीष्वेव योनिषु।।16.19।।",
    
    "आसुरीं(य्ँ) योनिमापन्ना, मूढा जन्मनि जन्मनि।\nमामप्राप्यैव कौन्तेय, ततो यान्त्यधमां(ङ्) गतिम्।।16.20।।",
    
    "त्रिविधं(न्) नरकस्येदं(न्), द्वारं(न्) नाशनमात्मनः।\nकामः(ख्) क्रोधस्तथा लोभ:(स्), तस्मादेतत्त्रयं(न्) त्यजेत्।।16.21।।",
    
    "एतैर्विमुक्तः(ख्) कौन्तेय, तमोद्वारैस्त्रिभिर्नरः।\nआचरत्यात्मनः(श्) श्रेयस् , ततो याति परां(ङ्) गतिम्।।16.22।।",
    
    "यः(श्) शास्त्रविधिमुत्सृज्य, वर्तते कामकारतः।\nन स सिद्धिमवाप्नोति ,  न सुखं(न्)  न परां(ङ्) गतिम् ।।16.23।।",
    
    "तस्माच्छास्त्रं(म्) प्रमाणं(न्) ते, कार्याकार्यव्यवस्थितौ।\nज्ञात्वा शास्त्रविधानोक्तं(ङ्), कर्म कर्तुमिहार्हसि।।16.24।।",
    
    "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु ब्रह्मविद्यायां(य्ँ) योगशास्त्रे\nश्रीकृष्णार्जुनसंवादे दैवासुरसम्पद्विभागयोगो नाम षोडशोऽध्याय:।।"  
  ],
  "adhyaya-17" : [
    "अर्जुन उवाच\nये शास्त्रविधिमुत्सृज्य, यजन्ते श्रद्धयान्विताः।\nतेषां(न्) निष्ठा तु का कृष्ण, सत्त्वमाहो रजस्तमः ॥17.1॥",
    
    "श्रीभगवानुवाच\nत्रिविधा भवति श्रद्धा, देहिनां(म्) सा स्वभावजा।\nसात्त्विकी राजसी चैव, तामसी चेति तां(म्) शृणु॥17.2॥",
    
    "सत्त्वानुरूपा सर्वस्य, श्रद्धा भवति भारत।\nश्रद्धामयोऽयं(म्) पुरुषो, यो यच्छ्रद्धः(स्) स एव सः॥17.3॥",
    
    "यजन्ते सात्त्विका देवान्, यक्षरक्षांसि राजसाः।\nप्रेतान्भूतगणांश्चान्ये, यजन्ते तामसा जनाः॥17.4॥",
    
    "अशास्त्रविहितं(ङ्) घोरं(न्), तप्यन्ते ये तपो जनाः।\nदम्भाहङ्कारसंयुक्ताः(ख्), कामरागबलान्विताः॥17.5॥",
    
    "कर्शयन्तः(श्) शरीरस्थं(म्), भूतग्राममचेतसः।\nमां(ञ्) चैवान्तः(श्) शरीरस्थं(न्), तान्विद्ध्यासुरनिश्चयान्॥17.6॥",
    
    "आहारस्त्वपि सर्वस्य, त्रिविधो भवति प्रियः।\nयज्ञस्तपस्तथा दानं(न्), तेषां(म्) भेदमिमं(म्) शृणु॥17.7॥",
    
    "आयुः(स्) सत्त्वबलारोग्य, सुखप्रीतिविवर्धनाः।\nरस्याः(स्) स्निग्धाः(स्) स्थिरा हृद्या, आहाराः(स्) सात्त्विकप्रियाः॥17.8॥",
    
    "कट्वम्ललवणात्युष्ण, तीक्ष्णरूक्षविदाहिनः।\nआहारा राजसस्येष्टा, दुःखशोकामयप्रदाः॥17.9॥",
    
    "यातयामं(ङ्) गतरसं(म्), पूति पर्युषितं(ञ्) च यत्।\nउच्छिष्टमपि चामेध्यं(म्), भोजनं(न्) तामसप्रियम्॥17.10॥",
    
    "अफलाकाङ्क्षिभिर्यज्ञो, विधिदृष्टो य इज्यते।\nयष्टव्यमेवेति मनः(स्), समाधाय स सात्त्विकः॥17.11॥",
    
    "अभिसन्धाय तु फलं(न्), दम्भार्थमपि चैव यत्।\nइज्यते भरतश्रेष्ठ, तं(य्ँ) यज्ञं(व्ँ) विद्धि राजसम्॥17.12॥",
    
    "विधिहीनमसृष्टान्नं(म्), मन्त्रहीनमदक्षिणम्।\nश्रद्धाविरहितं(य्ँ) यज्ञं(न्), तामसं(म्) परिचक्षते॥17.13॥",
    
    "देवद्विजगुरुप्राज्ञ, पूजनं(म्) शौचमार्जवम्।\nब्रह्मचर्यमहिंसा च, शारीरं(न्) तप उच्यते॥17.14॥",
    
    "अनुद्वेगकरं(व्ँ) वाक्यं(म्), सत्यं(म्) प्रियहितं(ञ्) च यत्।\nस्वाध्यायाभ्यसनं(ञ्) चैव, वाङ्मयं(न्) तप उच्यते॥17.15॥",
    
    "मनः(फ्) प्रसादः(स्) सौम्यत्वं(म्), मौनमात्मविनिग्रहः।\nभावसंशुद्धिरित्येतत्, तपो मानसमुच्यते॥17.16॥",
    
    "श्रद्धया परया तप्तं(न्), तपस्तत्त्रिविधं(न्) नरैः।\nअफलाकाङ्क्षिभिर्युक्तैः(स्), सात्त्विकं(म्) परिचक्षते॥17.17॥",
    
    "सत्कारमानपूजार्थं(न्), तपो दम्भेन चैव यत्।\nक्रियते तदिह प्रोक्तं(म्), राजसं(ञ्) चलमध्रुवम्॥17.18॥",
    
    "मूढग्राहेणात्मनो यत्, पीडया क्रियते तपः।\nपरस्योत्सादनार्थं(व्ँ) वा, तत्तामसमुदाहृतम्॥17.19॥",
    
    "दातव्यमिति यद्दानं(न्), दीयतेऽनुपकारिणे।\nदेशे काले च पात्रे च, तद्दानं(म्) सात्त्विकं(म्) स्मृतम्॥17.20॥",
    
    "यत्तु प्रत्युपकारार्थं(म्), फलमुद्दिश्य वा पुनः।\nदीयते च परिक्लिष्टं(न्), तद्दानं(म्) राजसं(म्) स्मृतम्॥17.21॥",
    
    "अदेशकाले यद्दानम्, अपात्रेभ्यश्च दीयते।\nअसत्कृतमवज्ञातं(न्), तत्तामसमुदाहृतम्॥17.22॥",
    
    "ॐ तत्सदिति निर्देशो, ब्रह्मणस्त्रिविधः(स्) स्मृतः।\nब्राह्मणास्तेन वेदाश्च, यज्ञाश्च विहिताः(फ्) पुरा॥17.23॥",
    
    "तस्मादोमित्युदाहृत्य, यज्ञदानतपः(ख्) क्रियाः।\nप्रवर्तन्ते विधानोक्ताः(स्), सततं(म्) ब्रह्मवादिनाम्॥17.24॥",
    
    "तदित्यनभिसन्धाय, फलं(य्ँ) यज्ञतपः(ख्) क्रियाः।\nदानक्रियाश्च विविधाः(ख्), क्रियन्ते मोक्षकाङ्क्षिभि:॥17.25॥",
    
    "सद्भावे साधुभावे च, सदित्येतत्प्रयुज्यते।\nप्रशस्ते कर्मणि तथा, सच्छब्दः(फ्) पार्थ युज्यते॥17.26॥",
    
    "यज्ञे तपसि दाने च, स्थितिः(स्) सदिति चोच्यते।\nकर्म चैव तदर्थीयं(म्), सदित्येवाभिधीयते॥17.27॥",
    
    "अश्रद्धया हुतं(न्) दत्तं(न्), तपस्तप्तं(ङ्) कृतं(ञ्) च यत्।\nअसदित्युच्यते पार्थ, न च तत्प्रेत्य नो इह17.28॥",
    
    "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु ब्रह्मविद्यायां(य्ँ) योगशास्त्रे\nश्रीकृष्णार्जुनसंवादे श्रद्धात्रयविभागयोगो नाम सप्तदशोऽध्याय:।"
  ],
  "adhyaya-18" : [     
    "अर्जुन उवाच\n सन्न्यासस्य महाबाहो, तत्त्वमिच्छामि वेदितुम्।\nत्यागस्य च हृषीकेश, पृथक्केशिनिषूदन॥18.1॥",
    
    "श्रीभगवानुवाच\nकाम्यानां(ङ्) कर्मणां(न्) न्यासं(म्), सन्न्यासं(ङ्) कवयो विदुः।\nसर्वकर्मफलत्यागं(म्), प्राहुस्त्यागं(व्ँ) विचक्षणाः॥18.2॥",
    
    "त्याज्यं(न्) दोषवदित्येके, कर्म प्राहुर्मनीषिणः।\nयज्ञदानतपःकर्म, न त्याज्यमिति चापरे॥18.3॥",
    
    "निश्चयं(म्) शृणु मे तत्र, त्यागे भरतसत्तम।\nत्यागो हि पुरुषव्याघ्र, त्रिविधः(स्) सम्प्रकीर्तितः॥18.4॥",
    
    "यज्ञदानतपःकर्म, न त्याज्यं(ङ्) कार्यमेव तत्।\nयज्ञो दानं(न्) तपश्चैव, पावनानि मनीषिणाम्॥18.5॥",
    
    "एतान्यपि तु कर्माणि, सङ्गं(न्) त्यक्त्वा फलानि च।\nकर्तव्यानीति मे पार्थ, निश्चितं(म्) मतमुत्तमम्॥18.6॥",
    
    "नियतस्य तु सन्न्यासः(ख्), कर्मणो नोपपद्यते।\nमोहात्तस्य परित्याग:(स्), तामसः(फ्) परिकीर्तितः॥18.7॥",
    
    "दुःखमित्येव यत्कर्म, कायक्लेशभयात्त्यजेत्।\nस कृत्वा राजसं(न्) त्यागं(न्), नैव त्यागफलं(ल्ँ) लभेत् ॥18.8॥",
    
    "कार्यमित्येव यत्कर्म, नियतं(ङ्) क्रियतेऽर्जुन।\nसङ्गं(न्) त्यक्त्वा फलं(ञ्) चैव, स त्यागः(स्) सात्त्विको मतः॥18.9॥",
    
    "न द्वेष्ट्यकुशलं(ङ्) कर्म, कुशले नानुषज्जते।\nत्यागी सत्त्वसमाविष्टो, मेधावी छिन्नसंशयः॥18.10॥",
    
    "न हि देहभृता शक्यं(न्), त्यक्तुं(ङ्) कर्माण्यशेषतः।\nयस्तु कर्मफलत्यागी, स त्यागीत्यभिधीयते॥18.11॥",
    
    "अनिष्टमिष्टं(म्) मिश्रं(ञ्) च, त्रिविधं(ङ्) कर्मणः(फ्) फलम्।\nभवत्यत्यागिनां(म्) प्रेत्य, न तु सन्न्यासिनां(ङ्) क्वचित्॥18.12॥",
    
    "पञ्चैतानि महाबाहो, कारणानि निबोध मे।\nसाङ्ख्ये कृतान्ते प्रोक्तानि, सिद्धये सर्वकर्मणाम्॥18.13॥",
    
    "अधिष्ठानं(न्) तथा कर्ता, करणं(ञ्) च पृथग्विधम्।\nविविधाश्च पृथक्चेष्टा, दैवं(ञ्) चैवात्र पञ्चमम्॥18.14॥",
    
    "शरीरवाङ्मनोभिर्यत्, कर्म प्रारभते नरः।\nन्याय्यं(व्ँ) वा विपरीतं(व्ँ) वा, पञ्चैते तस्य हेतवः॥18.15॥",
    
    "तत्रैवं(म्) सति कर्तारम्, आत्मानं(ङ्) केवलं(न्) तु यः।\nपश्यत्यकृतबुद्धित्वान्, न स पश्यति दुर्मतिः॥18.16॥",
    
    "यस्य नाहङ्कृतो भावो, बुद्धिर्यस्य न लिप्यते।\nहत्वापि स इमाँल्लोकान्, न हन्ति न निबध्यते॥18.17॥",
    
    "ज्ञानं(ञ्) ज्ञेयं(म्) परिज्ञाता, त्रिविधा कर्मचोदना।\nकरणं(ङ्) कर्म कर्तेति, त्रिविधः(ख्) कर्मसङ्ग्रहः॥18.18॥",
    
    "ज्ञानं(ङ्) कर्म च कर्ता च, त्रिधैव गुणभेदतः।\nप्रोच्यते गुणसङ्ख्याने, यथावच्छृणु तान्यपि॥18.19॥",
    
    "सर्वभूतेषु येनैकं(म्), भावमव्ययमीक्षते।\nअविभक्तं(व्ँ) विभक्तेषु, तज्ज्ञानं(व्ँ) विद्धि सात्त्विकम्॥18.20॥",
    
    "पृथक्त्वेन तु यज्ज्ञानं(न्), नानाभावान्पृथग्विधान्।\nवेत्ति सर्वेषु भूतेषु,  तज्ज्ञानं(व्ँ)विद्धि राजसम्॥18.21॥",
    
    "यत्तु कृत्स्नवदेकस्मिन्, कार्ये सक्तमहैतुकम्।\nअतत्त्वार्थवदल्पं(ञ्) च, तत्तामसमुदाहृतम्॥18.22॥",
    
    "नियतं(म्) सङ्गरहितम्, अरागद्वेषतः(ख्) कृतम्।\nअफलप्रेप्सुना कर्म, यत्तत्सात्त्विकमुच्यते॥18.23॥",
    
    "यत्तु कामेप्सुना कर्म, साहङ्कारेण वा पुनः।\nक्रियते बहुलायासं(न्), तद्राजसमुदाहृतम्॥18.24॥",
    
    "अनुबन्धं(ङ्) क्षयं(म्) हिंसाम्, अनवेक्ष्य च पौरुषम्।\nमोहादारभ्यते कर्म, यत्तत्तामसमुच्यते॥18.25॥",
    
    "मुक्तसङ्गोऽनहंवादी, धृत्युत्साहसमन्वितः।\nसिद्ध्यसिद्ध्योर्निर्विकारः(ख्), कर्ता सात्त्विक उच्यते॥18.26॥",
    
    "रागी कर्मफलप्रेप्सु:(र्), लुब्धो हिंसात्मकोऽशुचिः।\nहर्षशोकान्वितः(ख्) कर्ता, राजसः(फ्) परिकीर्तितः॥18.27॥",
    
    "अयुक्तः(फ्) प्राकृतः(स्) स्तब्धः(श्), शठो नैष्कृतिकोऽलसः।\nविषादी दीर्घसूत्री च, कर्ता तामस उच्यते॥18.28॥",
    
    "बुद्धेर्भेदं(न्) धृतेश्चैव, गुणतस्त्रिविधं(म्) शृणु।\nप्रोच्यमानमशेषेण, पृथक्त्वेन धनञ्जय॥18.29॥",
    
    "प्रवृत्तिं(ञ्) च निवृत्तिं(ञ्) च, कार्याकार्ये भयाभये।\nबन्धं(म्) मोक्षं(ञ्) च या वेत्ति, बुद्धिः(स्) सा पार्थ सात्त्विकी॥18.30॥",
    
    "यया धर्ममधर्मं(ञ्) च, कार्यं(ञ्) चाकार्यमेव च।\nअयथावत्प्रजानाति, बुद्धिः(स्) सा पार्थ राजसी॥18.31॥",
    
    "अधर्मं(न्) धर्ममिति या, मन्यते तमसावृता।\nसर्वार्थान्विपरीतांश्च, बुद्धिः(स्) सा पार्थ तामसी॥18.32॥",
    
    "धृत्या यया धारयते, मनःप्राणेन्द्रियक्रियाः।\nयोगेनाव्यभिचारिण्या, धृतिः(स्) सा पार्थ सात्त्विकी॥18.33॥",
    
    "यया तु धर्मकामार्थान्, धृत्या धारयतेऽर्जुन।\nप्रसङ्गेन फलाकाङ्क्षी, धृतिः(स्) सा पार्थ राजसी॥18.34॥",
    
    "यया स्वप्नं(म्) भयं(म्) शोकं(व्ँ), विषादं(म्) मदमेव च।\nन विमुञ्चति दुर्मेधा, धृतिः(स्) सा पार्थ तामसी॥18.35॥",
    
    "सुखं(न्) त्विदानीं(न्) त्रिविधं(म्), शृणु मे भरतर्षभ।\nअभ्यासाद्रमते यत्र, दुःखान्तं(ञ्) च निगच्छति॥18.36॥",
    
    "यत्तदग्रे विषमिव, परिणामेऽमृतोपमम्।\nतत्सुखं(म्) सात्त्विकं(म्) प्रोक्तम्, आत्मबुद्धिप्रसादजम्॥18.37॥",
    
    "विषयेन्द्रियसंयोगाद्, यत्तदग्रेऽमृतोपमम्।\nपरिणामे विषमिव, तत्सुखं(म्) राजसं(म्) स्मृतम्॥18.38॥",
    
    "यदग्रे चानुबन्धे च, सुखं(म्) मोहनमात्मनः।\nनिद्रालस्यप्रमादोत्थं(न्), तत्तामसमुदाहृतम्॥18.39॥",
    
    "न तदस्ति पृथिव्यां(व्ँ) वा, दिवि देवेषु वा पुनः।\nसत्त्वं(म्) प्रकृतिजैर्मुक्तं(य्ँ), यदेभिः(स्) स्यात्त्रिभिर्गुणैः॥18.40॥",
    
    "ब्राह्मणक्षत्रियविशां(म्), शूद्राणां(ञ्) च परन्तप।\nकर्माणि प्रविभक्तानि, स्वभावप्रभवैर्गुणैः॥18.41॥",
    
    "शमो दमस्तपः(श्) शौचं(ङ्), क्षान्तिरार्जवमेव च।\nज्ञानं(व्ँ) विज्ञानमास्तिक्यं(म्), ब्रह्मकर्म स्वभावजम्॥18.42॥",
    
    "शौर्यं(न्) तेजो धृतिर्दाक्ष्यं(य्ँ), युद्धे चाप्यपलायनम्।\nदानमीश्वरभावश्च, क्षात्रं(ङ्) कर्म स्वभावजम्॥18.43॥",
    
    "कृषिगौरक्ष्यवाणिज्यं(व्ँ), वैश्यकर्म स्वभावजम्।\nपरिचर्यात्मकं(ङ्) कर्म, शूद्रस्यापि स्वभावजम्॥18.44॥",
    
    "स्वे स्वे कर्मण्यभिरतः(स्), संसिद्धिं(ल्ँ) लभते नरः।\nस्वकर्मनिरतः(स्) सिद्धिं(य्ँ), यथा विन्दति तच्छृणु॥18.45॥",
    
    "यतः(फ्) प्रवृत्तिर्भूतानां(य्ँ), येन सर्वमिदं(न्) ततम्।\nस्वकर्मणा तमभ्यर्च्य, सिद्धिं(व्ँ) विन्दति मानवः॥18.46॥",
    
    "श्रेयान्स्वधर्मो विगुणः(फ्), परधर्मात्स्वनुष्ठितात्।\nस्वभावनियतं(ङ्) कर्म, कुर्वन्नाप्नोति किल्बिषम्॥18.47॥",
    
    "सहजं(ङ्) कर्म कौन्तेय, सदोषमपि न त्यजेत्।\nसर्वारम्भा हि दोषेण, धूमेनाग्निरिवावृताः॥18.48॥",
    
    "असक्तबुद्धिः(स्) सर्वत्र, जितात्मा विगतस्पृहः।\nनैष्कर्म्यसिद्धिं(म्) परमां(म्), सन्न्यासेनाधिगच्छति॥18.49॥",
    
    "सिद्धिं(म्) प्राप्तो यथा ब्रह्म, तथाप्नोति निबोध मे।\nसमासेनैव कौन्तेय, निष्ठा ज्ञानस्य या परा॥18.50॥",
    
    "बुद्ध्या विशुद्धया युक्तो, धृत्यात्मानं(न्) नियम्य च।\nशब्दादीन्विषयांस्त्यक्त्वा, रागद्वेषौ व्युदस्य च॥18.51॥",
    
    "विविक्तसेवी लघ्वाशी, यतवाक्कायमानसः।\nध्यानयोगपरो नित्यं(व्ँ), वैराग्यं(म्) समुपाश्रितः॥18.52॥",
    
    "अहङ्कारं(म्) बलं(न्) दर्पं(ङ्), कामं(ङ्) क्रोधं(म्) परिग्रहम्।\nविमुच्य निर्ममः(श्) शान्तो, ब्रह्मभूयाय कल्पते॥18.53॥",
    
    "ब्रह्मभूतः(फ्) प्रसन्नात्मा, न शोचति न काङ्क्षति।\nसमः(स्) सर्वेषु भूतेषु, मद्भक्तिं(ल्ँ) लभते पराम्॥18.54॥",
    
    "भक्त्या मामभिजानाति, यावान्यश्चास्मि तत्त्वतः।\nततो मां(न्) तत्त्वतो ज्ञात्वा, विशते तदनन्तरम्॥18.55॥",
    
    "सर्वकर्माण्यपि सदा, कुर्वाणो मद् व्यपाश्रयः।\nमत्प्रसादादवाप्नोति, शाश्वतं(म्) पदमव्ययम्॥18.56॥",
    
    "चेतसा सर्वकर्माणि, मयि सन्न्यस्य मत्परः।\nबुद्धियोगमुपाश्रित्य, मच्चित्तः(स्) सततं(म्) भव॥18.57॥",
    
    "मच्चित्तः(स्) सर्वदुर्गाणि, मत्प्रसादात्तरिष्यसि।\nअथ चेत्त्वमहङ्कारान्, न श्रोष्यसि विनङ्क्ष्यसि॥18.58॥",
    
    "यदहङ्कारमाश्रित्य, न योत्स्य इति मन्यसे।\nमिथ्यैष व्यवसायस्ते, प्रकृतिस्त्वां(न्) नियोक्ष्यति॥18.59॥",
    
    "स्वभावजेन कौन्तेय, निबद्धः(स्) स्वेन कर्मणा।\nकर्तुं(न्) नेच्छसि यन्मोहात्, करिष्यस्यवशोऽपि तत्॥18.60॥",
    
    "ईश्वरः(स्) सर्वभूतानां(म्), हृद्देशेऽर्जुन तिष्ठति।\nभ्रामयन्सर्वभूतानि, यन्त्रारूढानि मायया॥18.61॥",
    
    "तमेव शरणं(ङ्) गच्छ, सर्वभावेन भारत।\nतत्प्रसादात्परां(म्) शान्तिं(म्), स्थानं(म्) प्राप्स्यसि शाश्वतम्॥18.62॥",
    
    "इति ते ज्ञानमाख्यातं(ङ्), गुह्याद्गुह्यतरं(म्) मया।\nविमृश्यैतदशेषेण, यथेच्छसि तथा कुरु॥18.63॥",
    
    "सर्वगुह्यतमं(म्) भूयः(श्), शृणु मे परमं(व्ँ) वचः।\nइष्टोऽसि मे दृढमिति, ततो वक्ष्यामि ते हितम्॥18.64॥",
    
    "मन्मना भव मद्भक्तो, मद्याजी मां(न्) नमस्कुरु।\nमामेवैष्यसि सत्यं(न्) ते, प्रतिजाने प्रियोऽसि मे॥18.65॥",
    
    "सर्वधर्मान्परित्यज्य, मामेकं(म्) शरणं(व्ँ) व्रज।\nअहं(न्) त्वा सर्वपापेभ्यो, मोक्षयिष्यामि मा शुचः॥18.66॥",
    
    "इदं(न्) ते नातपस्काय, नाभक्ताय कदाचन।\nन चाशुश्रूषवे वाच्यं(न्), न च मां(य्ँ) योऽभ्यसूयति॥18.67॥",
    
    "य इमं(म्) परमं(ङ्) गुह्यं(म्), मद्भक्तेष्वभिधास्यति।\nभक्तिं(म्) मयि परां(ङ्) कृत्वा, मामेवैष्यत्यसंशयः॥18.68॥",
    
    "न च तस्मान्मनुष्येषु, कश्चिन्मे प्रियकृत्तमः।\nभविता न च मे तस्माद्, अन्यः(फ्) प्रियतरो भुवि॥18.69॥",
    
    "अध्येष्यते च य इमं(न्), धर्म्यं(म्) संवादमावयोः।\nज्ञानयज्ञेन तेनाहम्, इष्टः(स्) स्यामिति मे मतिः॥18.70॥",
    
    "श्रद्धावाननसूयश्च, शृणुयादपि यो नरः।\nसोऽपि मुक्तः(श्) शुभाँल्लोकान्, प्राप्नुयात्पुण्यकर्मणाम्॥18.71॥",
    
    "कच्चिदेतच्छुतं(म्) पार्थ, त्वयैकाग्रेण चेतसा।\nकच्चिदज्ञानसम्मोहः(फ्), प्रनष्टस्ते धनञ्जय॥18.72॥",
    
    "अर्जुन उवाच\n नष्टो मोहः(स्) स्मृतिर्लब्धा, त्वत्प्रसादान्मयाच्युत।\nस्थितोऽस्मि गतसन्देहः(ख्), करिष्ये वचनं(न्) तव॥18.73॥",
    
    "सञ्जय उवाच\nइत्यहं(व्ँ) वासुदेवस्य, पार्थस्य च महात्मनः।\nसंवादमिममश्रौषम्, अद्भुतं(म्) रोमहर्षणम्॥18.74॥",
    
    "व्यासप्रसादाच्छ्रुतवान्, एतद्गुह्यमहं(म्) परम्।\nयोगं(य्ँ) योगेश्वरात्कृष्णात्, साक्षात्कथयतः(स्) स्वयम्॥18.75॥",
    
    "राजन्संस्मृत्य संस्मृत्य, संवादमिममद्भुतम्।\nकेशवार्जुनयोः(फ्) पुण्यं(म्), हृष्यामि च मुहुर्मुहुः॥18.76॥",
    
    "तच्च संस्मृत्य संस्मृत्य, रूपमत्यद्भुतं(म्) हरेः।\nविस्मयो मे महान् राजन्, हृष्यामि च पुनः(फ्) पुनः॥18.77॥",
    
    "यत्र योगेश्वरः(ख्) कृष्णो, यत्र पार्थो धनुर्धरः।\nतत्र श्रीर्विजयो भूति:(र्), ध्रुवा नीतिर्मतिर्मम॥18.78॥",
    
    "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु ब्रह्मविद्यायां(य्ँ) योगशास्त्रे\nश्रीकृष्णार्जुनसंवादे मोक्षसन्न्यासयोगो नाम अष्टादशोऽध्यायः ॥18॥"  
  ]
},
{
  "adhyaya-1" : [
    "धृतराष्ट्रजी आज्ञा गर्नुहुन्छ-   हे संजय!  धर्मस्थल (पूण्य भूमि) कुरुक्षेत्रमा युध्दको इच्छाले जम्मा भएका मेरा र पाण्डुका  पुत्रहरुले के गरे? 1.1",

    "संजयले बिन्ती गरे - त्यस बखतमा व्यूह रचना गरी उभिएका पाण्डवको सेनालाई देखेर राजा दुर्योधनले द्रोणाचार्य का नजिकै गएर यस्तो कुरा भन्न लागे। 1.2",

    "हे आचार्य (गुरु)! हजुरको बुध्दिमान शिष्य द्रुपदका छोरा धृष्टद्युम्न द्वारा रचना गरिएकाे व्यूहमा खड़ा गरिएका पाण्डवहरूको यो ठुलो संख्याको सेनालाई हेर्नुहोस् । 1.3",

    "यहाँ (पाण्डवहरूको सेनामा) ठूला-ठूला शूरवीर छन्, (जस्का) धेरै ठूला-ठूला धनुष छन् तथा (जुन) युध्दमा भीम र अर्जुनका समान छन् । (उनिहरुमा) युयुधान (सात्यकि), राजा विराट र महारथी द्रुपद (पनि छन्)।  1.4",

    "धृष्टकेतु र चेकितान तथा पराक्रमी काशिराज (पनि छन्)। पुरुजित् र कुन्तिभोज तथा मनुष्यहरुमा श्रेष्ठ शैब्य (पनि छन्)। 1.5",

    "पराक्रमी युधामन्यु र पराक्रमी उत्तमौजा (पनि छन्)। सुभद्राका छोरा अभिमन्यु र द्रौपदीका पाँच छोरा (पनि छन् )। (यी) सबै नै महारथी हुन् ।  1.6",

    "हे ब्राह्मण श्रेष्ठ (गुरु)! हाम्रो पक्षमा भएका जुन जुन मुख्य छन्, त्यसमा पनि हजुरले ध्यान दिनुहोस् । हजुरको जानकारीको निमित्त मेरा सेनाका जुन सेनापति छन्, तिनिहरुको बारेमा म बताउदै छु। 1.7",

    "पहिला खूद हजुर (द्रोणाचार्य), पितामह भीष्म, कर्ण र युध्दमा विजयी कृपाचार्य, त्यस्तै अश्वत्थामा, विकर्ण र सोमदत्तका छोरा भूरिश्रवा ।  1.8",

    "त्यस्तै यी बाहेक धेरै नैं अन्य शूरवीर छन्, जसले मेरो लागि आफ्नो प्राण दिन तयार छन् र उनिहरु अनेक प्रकारका शस्त्र र अस्त्रहरु चलाउन निपुण छन्  तथा उनिहरु सबै नै युध्दकलामा अत्यन्त सिपालु छन् । 1.9",

    "भीष्मपितामहले  रक्षा गरिएको यो हाम्रो असंख्य सेना जुन सवै प्रकारबाट अजेय छ र भीमले रक्षा गरिएको पाण्डवहरूको त्यो थोरै सेनालाई सजिलै जित्न सकिनेछ ।  1.10",

    "यस बखत तपाईहरु सबैले तोकिएका सवै मोर्चाहरुमा  आफ्नो-आफ्नो ठाउँमा दृढ़ता पूर्वक रहेर  पितामह भीष्म को चारै तर्फबाट रक्षा गर्नुहोस् ।  1.11",

    "दुर्योधनको हृदयमा हर्ष उत्पन्न गराउदैं कौरव हरुमा वृध्द एवं प्रभावशाली पितामह भीष्मले सिंह कै समान ठूलो गर्जना गरेर आफ्नो शंख बजाए । 1.12",

    "त्यस पछि शंख नगारा, ढोल, मादल, मृदंग, नर्सिंगा आदि लडाईका बाजाहरू एकै पटक  धमाधम बज्न लागे । (यिनिहरुको) त्यो शब्द बड़ो भयंकर भयो ।  1.13",

    "यस पछि सेता घोड़ाहरु जोतिएको ठूलो रथमा बसेका भगवान् श्रीकृष्ण र पाण्डुका छोरा अर्जुनले पनि दिव्य शंखहरु ठुलो स्वरमा बजाए । 1.14",

    "अन्तर्यामी भगवान् श्रीकृष्णले पाञ्चजन्य नामक (तथा) धनञ्जय अर्जुनले देवदत्त नामक (शंख बजाए र) भयंकर ठुलो कर्म गरने वाला वृकोदर भीमले पौण्ड्र नामको ठुलोशंख बजाए ।   1.15",

    "कुन्तीपुत्र राजा युधिष्ठिरले अनन्तविजय नामको (शंख बजाए तथा) नकुल र सहदेवले सुघोष र मणिपुष्पक नामको शंख बजाए । 1.16",

    "हे राजन्! श्रेष्ठ धनुर्धारी काशिराज र महारथी शिखण्डी तथा धृष्टद्युम्न एवं राजा विराट र अजेय सात्यकि 1.17",

    "राजा द्रुपद, द्रौपदीका पाँचै छोराहरु तथा महाबाहु (लामो हात) भएका सुभद्राका छोरा अभिमन्यु इत्यादि सवै वीरहरुले सवै तिरबाट भिन्न-भिन्न, (आफ्ना-आफ्ना) शंख बजाए । 1.18",

    "र पाण्डव-सेना का शंखहरुको त्यो भयंकर शब्दले आकाशमण्डल र पृथ्वीमण्डललाइ पनि थर्काउदै धार्तराष्ट्रका अर्थात् हजुरका छोराहरुको हृदयलाई पनि  फोर्न अर्थात चर्चरी चिर्न लाग्यो ।   1.19",

    "हे राजन् ! यसपछि कपिध्वज (हनुमान ध्वजामा रहेका) अर्जुनले शस्त्र चलाउन तैयारी गरिएका, धनुष उठाएर मोर्चा बाँधी डटेका, व्यवस्थित धृतराष्ट्रका सन्तान लगायतकालाई  देखेर , 1.20",

    "अर्जुनले  भगवान् श्रीकृष्ण संग यस्तो कुरा गर्न लागे - हे अच्युत! (कुनै कुरामा पनि नचुक्ने कृष्ण) दुवै सेनाहरुको बिचमा मेरो रथलाई तपाईंले लगेर खड़ा गरि दिनु हओस् । 1.21",

    "म हेर्न चाहन्छु कि यो युध्दक्षेत्रमा खड़ा भएका, युध्दको इच्छा भएकाहरु यहाँ को को आएका रहेछन्?  मैले यो युध्दरूपी उद्योगमा क-कस माथि शस्त्र उठाउनु पर्ने हो ।  1.22",

    "दुर्बुध्दि दुर्योधनको कल्याणका इच्छाले यस युध्दमा को को लडाकाहरु जम्मा भएका छन् , ती युध्द गरने सबलाई म हेर्छु । 1.23",

    "संजय भन्दछन् - हे भरतवंशी राजन्! निद्रा-आलस्य जित्ने अर्जुनबाट यस तरहको भनाई हुँदा अन्तर्यामी भगवान् श्रीकृष्णले दुवै सेनाहरुको बिच भागमा आफ्नो उत्तम रथलाई खड़ा गरि दिनु भयो । 1.24",

    "अनि पितामह भीष्म, आचार्य द्रोण तथा अन्य सम्पूर्ण राजाहरूको सामुन्नेमा श्री कृष्णबाट आज्ञा भयो 'हे अर्जुन! यहाँ जम्मा भएका यी  कुरुवंशीहरुलाई हेर । 1.25",

    "त्यस पछि  पृथानन्दन अर्जुनले यी दुबै नै सेनाहरुमा जम्मा भएका बाबु (काका), बाजे (जिजुबुवा), गुरु, मामा, भाइहरु, छोरा, नाति, मित्र   असंख्यात् धेरै र । 1.26",

    "त्यस्तै ससुरा, प्रेम गर्ने आफन्त र माननिय मानिसहरु मर्नमार्नाको निमित्त ठिक ठाक परी आफ्नो आफ्नो सेनामा जम्मा भएको देखे र । 1.27",

    "ती कुन्तीका छोरा अर्जुन अत्यन्त कायरताले युक्त भई खिन्न (दिग्दार) हुदै भन्न लागे - हे कृष्ण  युध्द गर्नाको अभिलाषा लिएका यी सारा स्वजन बन्धुबान्धव हरुलाइ देखेर- ।  1.28",

    "मेरो अंग प्रत्यंग सबै शिथिल भइ रहेको छ र मुख पनि सुकिरहेको छ, मेरो शरीर थर्थरी काँप्न लागेको छ एवं शरिरका रौहरु ठाडा (खडा) भई रहेछन । 1.29",

    "त्यस्तै हातबाट गाण्डीव धनु खस्दछ र त्वचा पनि पोल्न लागि रहेछ । मेरो मन बेहोस जस्तो भइ चक्कर खान लागि रहेछ र (म) खडा रहनमा पनि असमर्थ भइरहेको छ ।  1.30",

    "हे केशव! मैंले लक्षणहरू पनि विपरीत देखि रहेको छु र युध्दमा आफ्ना स्वजनहरुलाई  मारेर कल्याण (लाभ)  हुने पनि देखि रहेको छुईन ।  1.31",

    "हे कृष्ण! म न त विजय चाहन्छु, न त राज्य चाहन्छु, न त सुखहरु नै चाहन्छु । हे गोविन्द! मलाई अन्य मानिसहरु को राज्यबाट के लाभ हुन्छ? सुख भोगले के लाभ हुन्छ? अथवा मैले बाचेर पो के लाभ हुन्छ? 1.32",

    "किनभने, हामीलाइ जसको निमित्त राज्य, उपभोग र सुखभोगको इच्छा (चाहना) थियो, उनिहरु सबै आफ्नो ज्यान र धन सम्पत्तिको समेत आशा एवं ममता छोडेर युध्दमा खडा छन् । 1.33",

    "जो कि, आचार्य(गुरुजन), पिता, छोरा, यस्तै प्रकारले पितामह (बाज्ये), मामा, ससुरा, नाति, साला तथा (अन्य जतिपनि छन्) नातेदार, इष्टमित्र मात्र छन् ।  1.34",

    "भलै यिनिहरुले मलाई घात गरे पनि म यिनिहरु लाई मार्न चाहन्नँ र हे मधुसूदन! मलाई त्रैलोक्यको राज्य मिल्छ भने पनि म यिनिहरुलाई  मार्न  चाहन्नँ, फेरि यो तुच्छ पृथ्वीको लागि त म यिनिहरुलाई किन मारु?  1.35",

    "हे जनार्दन! (यी) धृतराष्ट्रका पुत्र दुर्योधनादिलाई मारेर हामीलाई के प्रसन्नता होला? यी आततायीलाई मारेर हामीलाई पाप मात्र लाग्नेछ ।   1.36",

    "यसकारण आफ्ना बन्धु बान्धव यी धृतराष्ट्रका पुत्र हरुलाई  मार्नको लागि हामी योग्य छैनौं; किनकि हे माधव! आफ्ना स्वजनहरुलाई मारेर हामी कसरी सुखी हौंला? 1.37",

    "यद्यपि लोभको कारणले बुध्दि  विवेक नष्ट भएका यिनिहरुलाई (कौरवादिलाई) कुलको नाश गरेर हुने दोषलाई  र मित्रहरुका साथ गरिने विरोध (द्वेष) बाट प्राप्त हुने पापलाई पनि यिनिहरुले देख्तैनन् । 1.38",

    "तथापि हे जनार्दन! कुल को नाश गर्दा सो बाट हुने वाला दोषलाई राम्रो संग देख्ने, जान्ने  हामीहरु यो पाप बाट हट्नुपर्छ भन्ने विचार किन न गर्ने?    1.39",

    "कुलको नास हुदाँ सनातन देखि चली आएको कुलको धर्म नष्ट हुन जान्छ र धर्मको नाश हुदाँ पनि (बचेको) सम्पूर्ण कुललाई पनि अधर्मले ढाक्दछ ।  1.40",

    "हे कृष्ण! अधर्म (पाप) धेरै बढेको खण्डमा कुलका स्त्रिहरु दूषित हुन्छन् (बिग्रन्छन्) र हे वार्ष्णेय! स्त्रिहरु  दूषित हुनाले/ बिग्रनाले वर्णसंकर सन्तान पैदा हुन्छन् । 1.41",

    "वर्णसंकर उत्पन्न भएमा त्यसले कुलघातिहरुलाई र सम्पूर्ण कुललाई निश्चय नै नरकमा लै जान्छ । पिण्डदान र तर्पण आदि कर्म नहुँदा (लुप्त हुँदा) यीनका (कुलघातिहरुका) पितृहरु पनि  (आफ्नो ठाउँबाट) नरकमा गिर्दछन् । 1.42",

    "कुलघाती यो वर्णसंकर कारक दोषहरुले सनातनबाट चली आएका कुलधर्म र जातियधर्म पनि नष्ट हुन जान्छन् अर्थात् नाम निशानै रहदैनन् ।   1.43",

    "हे जनार्दन! जसको कुलधर्म नष्ट हुन्छ, तिनिहरुको अनन्त काल सम्म नरकमा वास हुन्छ, यस्तो पनि हामीले सुन्दै आएका छौ ।  1.44",

    "अहो! ठूलो खेदको कुरा यो छ कि हामीहरु यो सबै जान्दाजान्दै एकदमै ठूलो पाप गर्नाका निमित्त योजना बनाएर बसेका छौँ । जुन राज्यको सुखभोगका लोभले आफ्ना स्वजनहरु, बन्धुबान्धवहरुलाई मार्नका निमित्त तैयार भएका छौ । 1.45",

    "यदि हातमा शस्त्र र अस्त्र लिएर धृतराष्ट्रका  पक्षपाती कौरवहरुले, युध्दभूमिमा सामना न गरने,  शस्त्र रहित (नि:शस्त्र) चुपचाप बसेको मलाई, शस्त्र लिएर लडाईमा मारुन ता पनि त्यो मेरा निमित्त अति नै कल्याणकारी हुनेछ । 1.46",

    "संजयले बिन्ती चढाए - महाराज!  युध्दभूमीमा श्रीकृष्ण संग यस्तो कुराहरु भनेर शोकले व्याकुल चित्त लिएका अर्जुनले हातमा रहेको बाण सहितको धनुषलाई फ्याँकि आफु रथको भित्र पट्टिको भागमा गएर बसे ।  1.47",

    "यस प्रकार ॐ तत् सत् - यी भगवन्नामहरुको उच्चारणपूर्वक ब्रह्मविद्या र योगशास्त्रमय श्रीमद्भगवद्गीताको उपनिषद् रूप श्रीकृष्ण र अर्जुनको संवाद स्वरुप ‘अर्जुनविषादयोग’ नाम भएको पहिलो अध्याय पूर्ण भयो ।"
  ],
  "adhyaya-2" : [
    "महाराज धृतराष्ट्रलाई सञ्जयले भने- भगवान श्रीकृष्णले यी र यस्तै शब्द अर्जुनलाई भनेका थिए, जो यस्तो कायरताले भरिएका, करुणाले युक्त, शोकाकुल र जसको दृष्टि आँसुले रोकिएको छ। 2.1",

    "श्री भगवानबाट आज्ञा भयो - हे अर्जुन ! यो दु:खको घडीमा तिमीले यो कायरता कहाँबाट पायौ, जसलाई सत्पुरुषहरूले लिँदैनन्, जसले न स्वर्ग पुर्याउँछ, न कीर्ति दिन्छ। 2.2",

    "हे पृथानन्दन अर्जुन ! यो नामर्दपना अर्थात् कातरपना छोड, किनभने यो तिम्रोलागि योग्य छैन् अर्थात् शोभा दिदैन । हे परन्तप ! हृदयको यो क्षुद्र कमजोरीलाई त्याग र युद्धको लागि खडा होऊ। 2.3",

    "अर्जुन विन्ती गर्दछन् - हे मधुसूदन ! भीष्म र द्रोणाचार्यसँग युद्धभूमिमा बाणले कसरी युध्द गरुँला? (किनकि) हे अरिसुदन ! (यी) दुवै पूजनीय हुन्। 2.4",

    "यस संसारमा महान गुरुहरूलाई मार्नुको सट्टा (म) भिक्षा मागेर पेट पाल्नु उत्तम ठान्छु । किनभने मेरा गुरुहरूलाई मारेर म रगतले मुँछिएको राज्य र सुख कसरी भोग्न सकुँला? 2.5",

    "(हामीलाई) यो पनि थाहा छैन (कुन) हाम्रो लागि के गर्नु श्रेष्ठ छ, (लड्नु वा नलड्नु) वा (हामी उनीहरूलाई जितौँला) वा (उनीहरूले) हामीलाई जित्नन्। धृतराष्ट्रका पुत्र (कौरवहरू) जसलाई मारेर पनि बाँच्न मन नलाग्ने, उनै युद्धका निमित्त हाम्रो सम्मुख खडा भैरहेका छन्। 2.6",

    "कायरताका दुर्गुणहरूद्वारा घृणित स्वभाव भएको (र) धर्मका विषयमा चित्त मोह भएको, (म) मेरो लागि त्यो कुरा सोद्धछु, जसले निश्चित श्रेय होस् । म हजुरको शरणागत शिष्य हुँ। हजुरको शरण लिएको मलाई यथार्थ कुराको उपदेश गर्नुहोस्। 2.7",

    "किनकि मैले पृथ्वीमा निष्कण्टक समृद्ध (र) शत्रुमुक्त राज्य र (स्वर्गमा) देवताहरूको आधिपत्य पाए पनि इन्द्रियहरूलाई सुकाउने मेरो जो शोक छ, त्यसलाई छुटाउन सक्ने कुनै त्यस्तो साधन देख्दिन। 2.8",

    "सञ्जयले बिन्ति चढाए - हे शत्रु तप्त धृतराष्ट्र ! यसो भन्दै निद्रामाथि विजय प्राप्त गरेका अर्जुन (परन्तप) ले भगवान गोविन्दलाई ‘म युद्ध गर्दिन’ भनी स्पष्ट रुपमा भनी चुप लागे। 2.9",

    "हे भारतवंशोद्भव धृतराष्ट्र ! भगवान ऋषिकेशले दुवै सेनाको बीचमा शोकयुक्त भएका अर्जुनलाई केहि हाँसे जस्तो गरेर (मुसुक्क हाँसेर) आज्ञा गर्न लाग्नु भयो। 2.10",

    "श्रीकृष्ण आज्ञा गर्नुहुन्छ– जसलाई शोक गर्नु हुँदैन, त्यसलाई तिमीले शोक गरेका छौ र विद्वत्ताको जस्तो वचन पनि बोलिरहेका छौ । (तर) ज्ञानी (पण्डित) मानिसहरूले ज्यान गुमाएका र बाँचेकाहरूको लागि पनि शोक गर्दैनन्। 2.11",

    "के म, के तिमी, के यी सबै राजाहरु पहिले पनि थिएनौं, पछि पनि म, तिमी, यी सबै राजाहरु रहनेछैनौं। 2.12",

    "जसरी यो मूर्त मानव शरीरमा बाल्यकाल, युवावस्था र बुढ्यौली हुन्छ, त्यसैगरी अर्को शरीर पनि प्राप्त हुन्छ। त्यस्तै देहधारी आत्माको एक शरिर छाडेर अर्को शरीरमा प्राप्त हुने पनि हुन्छ, जो धीर (विद्वान) पुरुष छ, त्यो यस्तो विषयमा मोह गर्दैन अर्थात् घडबडाउदैन्। 2.13",

    "हे कुन्तीनन्दन ! इन्द्रियहरूका वस्तुहरू (निर्जीव वस्तुहरू) तिनीहरूले चिसो (अनुकूलता) र गर्मी (प्रतिकूलता) द्वारा सुख र दुःख दिन्छन् (र) तिनीहरू आउँछन् र जान्छन् (र) अनित्य हुन्।हे भारत ! तिनीहरू (तिमीहरू) संग सहन गर । 2.14",

    "किनकी हे पुरुषश्रेष्ठ ! जो धैर्यवान व्यक्ति सुख-दु:खमा एकरूप रहन्छ र यी भौतिक तथा भौतिक वस्तुहरू (सुख र दुःख)बाट विचलित हुन सक्दैन, उही धीर पुरुष अमृतभावमा अर्थात अमृतब्रह्मको स्थिति प्राप्त गरी लिनमा समर्थ हुन्छ अर्थात् अमर बन्न सक्छ। 2.15",

    "अवास्तविकको सार (अस्तित्व) अवस्थित छैन र अस्तित्वको अनुपस्थिति अवस्थित छैन, असत् पदार्थ ता अनित्य छ, सत् जो आत्मा हो उसको नाश छैन, दर्शनका महापुरुषहरू (तत्वदर्शीहरु)ले ती दुवैको सार तत्व देखेका छन्। 2.16",

    "जसद्वारा यो सम्पूर्ण संसार व्याप्त छ, उहाँलाई अविनाशी जान। यस अविनाशीलाई कसैले नष्ट गर्न सक्दैन। 2.17",

    "जो यो शरीरको आत्मा छ, त्यो अविनाशी, अचिन्त्य (मनले पनि संझन नसकिने) अज्ञात र शाश्वत छ, त्यसले लिइने यी शरीर नासवान् छन् भनेर बताउदछन् । यसकारण हे भारत ! तिमी युद्ध नै गर अर्थात् - तिमीले बचाउदैमा बाँच्ने र मार्दैमा मर्ने पनि हुदैन। 2.18",

    "शरीरको स्वामी जीवात्मालाई नै जो मार्ने हो भन्ने सम्झन्छ अर्थात् जो यसलाई मर्ने (मरेको) भन्ने सम्झन्छ, ती दुवैको ज्ञान सत्य होइन, किनभने यो आत्मा न कसैलाई मार्दछ, न त अरुद्वारा आफू मारिन्छ। 2.19",

    "यो आत्मा न त कहिल्यै जन्मन्छ, न त कहिल्यै मर्दछ, न भएर फेरि हुनेवाला छ । यो जन्महीन, नित्य, शाश्वत र अनादि छ। शरीर मारियोस, तापनि त्यो कबै मारिदैन। 2.20",

    "हे पार्थ ! जो यस आत्मालाई अविनाशी, शाश्वत, नित्य, अजन्मा, अविकारी भनी जान्दछ, उसले कसलाई कसरी मराउला र कसलाई कसरी मार्ला। 2.21",

    "जसरी मानिसले पुरानो लुगा छाडेर नयाँ लुगा लगाउँछ, त्यसैगरी आत्माले पुरानो शरीर छोडेर नयाँ देह धारण गर्दछ। 2.22",

    "यस आत्मालाई हतियारले काट्न सक्दैन, आगोले यसलाई जलाउन सक्दैन, पानीले यसलाई भिजाउन वा गलाउन सक्दैन र हावाले सुकाउन पनि सक्दैन। 2.23",

    "यो आत्मा नकाटिने, नडढ्ने, नभिज्ने, नसुक्ने छ, यो नि:सन्देह नित्य, सर्वव्यापी, स्थिर, अचल र सनातन छ। 2.24",

    "यो आत्मालाई अव्यक्त अर्थात् इन्द्रियका विषयबाट पर रहेको, अचिन्त्य अर्थात् मनले पनि जान्न नसकिने, विकार-रहित कहिन्छ, तस्मात् आत्मालाई यस्तो सँझेर तिमी उसका उपर शोक र मोह नगर। 2.25",

    "हे महाबाहो ! तिमी यो आत्मा नित्य होइन, शरीरका साथसाथै सधैं जन्मने र मर्ने मान्छौ भने पनि त्यसको निमित्त शोक गर्नु योग्य छैन। 2.26",

    "कारण यो हो कि जन्मने अवश्य मर्दछ र मरेकाले अवश्य जन्म लिन्छ, त्यसैले जन्म-मृत्यु परिवर्तनको यो प्रवाह रोक्न सकिँदैन। तस्मात् तिमीले उपाय नभएका विषयमा शोक गर्नु उचित हुँदैन। 2.27",

    "हे भारत ! सबै प्राणी पहिले अव्यक्त (नजानिने), मध्यमा व्यक्त (जानिने) र मृत्यु भएमा पछि फेरि अव्यक्त (नजानिने) हुन्छन् । यस्तो सबैको स्थिति छ भने त्यहाँ शोक कुन कुराको गर्नु? 2.28",

    "कसैले यो आत्मालाई अचम्मको रूपमा देख्दछन्, (अनुभव) गर्दछन्, त्यसैगरी कसैले यसलाई आश्चर्यको रूपमा वर्णन गर्दछन् र कसैले यसलाई आश्चर्यको रूपमा सुन्दछन् तर यस प्रकार देखेर सुनेर वर्णन गरेर पनि यस आत्मालाई वास्तविक जान्दैनन्। 2.29",

    "हे भारत ! यो आत्मा हरेकको देहमा अवश्य छ। तसर्थ, तिमीले सबै जीवित प्राणीहरूको लागि, अर्थात् कुनै पनि प्राणीको विषयमा शोक गर्नु तिमि योग्य छैनौ। 2.30",

    "र आफ्नो क्षत्रिय धर्म देखेर पनि आफ्नो कर्तव्यबाट विचलित हुनुहुँदैन किनभने क्षत्रियका लागि धर्मयुद्धभन्दा हितकारी कर्म अरू कुनै छैन। 2.31",

    "अनि हे पार्थ ! यो आपसेआप खुलेको स्वर्गको द्वार नै हो । यस्तो युद्ध ता पुण्यात्मा क्षत्रियहरूलाई मात्र प्राप्त हुन्छ। 2.32",

    "अतएव, यदि तिमिले यो धर्मको लडाइँ गर्दैनौ भने स्वधर्म र कीर्ति नाश गरी ठूलो पापको भागी बन्नेछौ। 2.33",

    "र सबै प्राणीहरूले पनि तिम्रो सदा अपकीर्ति नै गाउनेछन्, अपकीर्ति प्रतिष्ठित (इज्जतदार) पुरुषकोलागि मृत्युभन्दा पनि बढी पीडादायी हुन्छ। 2.34",

    "सबै महारथीहरूले तिमीलाई डरले युद्धबाट पछि हटेको ठान्नेछन्। जसको बोधमा तिमी महान् प्राणी बनेका छौ, उनीहरुको नजरमा तिमी तुच्छ हुनेछौं। 2.35",

    "यस्तै तिम्रो शक्तिको निन्दा (आलोचना) गरेर तिम्रा शत्रुहरूले पनि धेरै अवाच्य शब्दहरू बोल्नेछन् । तब त्यो भन्दा दु:खद कुरा के हुन सक्छ? 2.36",

    "युद्धमा मारिएमा तिमीले स्वर्ग प्राप्त गर्ने छौ र यदि युद्धमा विजयी भयौं भने पृथ्वीको राज्यको उपभोग गर्नेछौं । त्यसैले हे कुन्तीनन्दन ! (तिमी) युद्धको लागि निश्चय गरेर उठ (तैयार भएर अगाडि सर)। 2.37",

    "जित-हार, लाभ-हानि र सुख-दुःख जे होस् यो सबैलाई बराबर ठानेर युद्धमा लाग। यसरी युद्ध गर्दा तिमीलाई पाप लाग्दैन। 2.38",

    "हे पार्थ ! यो समानता तिमीलाई पहिले सांख्ययोगमा भनिएको थियो र अहिले कर्मयोगमा सुन। जुन बुध्दिले युक्त भएका तिमीले कर्मको बन्धनलाई राम्ररी त्याग्ने छौ। 2.39",

    "यसमा प्रारम्भको अर्थात् बीजको नाश हुदैन, पाप पनि छैन, यो धर्म अलिकती मात्र गरियोस् तापनि यसले कर्तालाई जन्ममृत्युरुपी ठूलो भयदेखि रक्षा गर्दछ। 2.40",

    "हे कुरुनन्दन ! यस सम्बन्धमा दृढ निश्चय सहितको बुद्धि मात्र छ। एउटै निश्चय नभएका व्यक्तिहरूको बुद्धि अनन्त र बहुशाखाको हुन्छ। 2.41",

    "हे पार्थ ! जो कामनामा मग्न छन्, जो स्वर्गलाई श्रेष्ठ मान्छन्, जसलाई वेदमा उल्लेख भए अनुसार फलदायी कर्महरूमा प्रेम छ, सुख बाहेक अरू केही छैन भनी बोल्नेहरू तिनीहरू तर्कहीन व्यक्ति हुन्। 2.42",

    "स्वर्गलाई श्रेष्ठ मान्ने सकामी पुरुष भोग र ऐश्वर्य प्राप्तिकालागि अनेक प्रकारका क्रिया गर्दछन्। 2.43",

    "त्यसैले माथि बताईएका कुरा तर्फ नै तिनिहरुको मन तानिएको हुनाले भोग र ऐश्वर्यमा लठ्ठ रहने ती अविवेकी, मूढ पुरुषहरुको बुद्धि समाधिमा एकाग्रताको साथमा स्थिर रहन सक्दैन। 2.44",

    "वेदहरूले तीन गुणहरूको मात्र कार्यहरू वर्णन गर्दछ; हे अर्जुन ! तिमी तीनै गुणबाट मुक्त बन, आसक्ति, द्वेष आदि द्वन्द्वबाट मुक्त भई सर्वस्वरूप परमात्मा सबैमा स्थित भई, योगको कल्याणको कामना पनि नगर र ईश्वरमा समर्पित आत्मपरायण होऊ। 2.45",

    "जस्तै चारैतिरबाट पूर्ण, ठूलो जलाशयको अगाडि सानो खाडलमा भरिएको पानीको (मानवको लागि) कुनै उपयोग छैन त्यस्तै ब्रह्मलाई तत्वसाथ जान्ने ब्राह्मणको लागि सम्पूर्ण वेदहरूको त्यति नै प्रयोजन रहन्छ। 2.46",

    "तिम्रो अधिकार कर्तव्य पालनमा मात्र छ, त्यसको फलमा कदापि छैन। त्यसैले तिमी आफ्नो कर्मका फलको कारण नहोऊ र कर्म नगर्नुमा पनि तिम्रो आसक्ति नहोस्। 2.47",

    "हे धनंजय ! तिमी आसक्ति त्यागेर, सफलता र असफलताको बीचमा समान भएर योगमा स्थित कर्म गर; किनकि समानतालाई योग भनिन्छ। 2.48",

    "फलदायी कर्म बुद्धियोग (समानता) भन्दा धेरै निम्न श्रेणीको हुन्छ। अतः हे धनञ्जय ! तिमीले समबुद्धिमै रक्षाको उपाय खोज, बुद्धियोग कै शरण पर, किनभने फल प्राप्त गर्नेहरू अत्यन्त दीन हुन्छन्। 2.49",

    "समत्व बुद्धि भएको पुरुषले यही (जीवित हुँदा) लोकमा पुण्य र पाप दुवै त्याग्छ। त्यसैले तिमी समत्वरुपी योगमा लाग, किनकि यही योग नै कर्ममा कुशलता हो अर्थात् कर्म बन्धनबाट मुक्त हुने उपाय हो। 2.50",

    "कारण यो हो कि कर्मफलको परित्याग गरेर समतायुक्त बुद्धिवान साधक जन्मजातको बन्धनबाट मुक्त भएर निर्विकार परमपदलाई प्राप्त गर्दछ। 2.51",

    "जुन क्षण तिम्रो बुद्धिले मोहरुपी दलदललाई पार गर्दछ, त्यही क्षणमा नै तिमीले सुनेका र सुन्नमा आउने यस लोक र परलोक सम्बन्धी सबै भोगहरुबाट वैराग्यलाई प्राप्त गर्दछौ। 2.52",

    "जुन समय शास्त्रको भिन्नताले व्याकुल भएको तिम्रो बुद्धि जब समाधिबुद्धिमा स्थिर र अचल बन्नेछ, त्यसै समय यो साम्यबुद्धिरूप योग तिमिलाई प्राप्त हुनेछ। 2.53",

    "अर्जुनले भने- हे केशव! ईश्वरमा स्थित स्थिर मन भएको व्यक्तिको विशेषता के हो? त्यो स्थिर बुद्धिको मानिस कसरी बोल्छ, कसरी बस्छ र कसरी हिँड्दछ? 2.54",

    "श्रीभगवान् ले भन्नुहुन्छ - हे अर्जुन ! जुन अवधिमा यस्तो पुरुषले आफ्नो मनमा आएका सबै कामनाहरूलाई पूर्णतया त्याग्छ र आफूभित्र नै सन्तुष्ट रहन्छ, त्यति नै बेला ऊ स्थितप्रज्ञ हुन पुग्छ। 2.55",

    "दु:ख पाएर जसको मन उत्तेजित हुँदैन र सुख पाएर मनमा कामना हुँदैन (र) जो आसक्ति, भय र क्रोधबाट पूर्णतया मुक्त हुन्छ, त्यो चिन्तनशील व्यक्तिलाई स्थिरबुध्दि मानिन्छ। 2.56",

    "जो व्यक्ति सर्वत्र आसक्तिबाट मुक्त हुन्छ, राम्रो नराम्रो प्राप्त गरेर न सुखी हुन्छ, न घृणित हुन्छ, उसकै बुद्धि स्थिर हुन्छ। 2.57",

    "जसरी कछुवाले चारैतिरबाट आफ्ना अंगहरूलाई समेट्छ, त्यसै गरी कर्मयोगी पुरुषले इन्द्रियहरूबाट इन्द्रियहरूलाई (सबै कुरामा) हटाइदिन्छ, तब उसको बुद्धि स्थिर हुन्छ भन्ने ठान्नु पर्दछ। 2.58",

    "निरहारी पुरुषका विषयहरु छुट्न जाऊन् तथापि तिनको (विषयहरुको) रस अर्थात् चाहना छुटेको हुदैन, परन्तु परब्रह्मको अनुभव भएमा त्यो चाहना पनि छुट्दछ अर्थात् विषय र तिनको चाहनासमेत दुवै छुट्दछन्। 2.59",

    "हे कौन्तेय ! कारण यो छ कि केवल इन्द्रियहरुलाई दमन (दबाव) को निमित्त प्रयत्न गर्ने विद्वानको पनि मनलाई यी बलिया इन्द्रियहरुले जबरजस्ती आफैतिर तान्दछन्। 2.60",

    "यसर्थ साधकले ती सम्पूर्ण इन्द्रियहरूलाई वशमा राखेर समाहितचित्त हुदै मेरै पारायण भएर ध्यानमा बसोस्, किनकि जुन पुरुषका इन्द्रियहरु वशमा हुन्छन्, उसको बुद्धि स्थिर हुन्छ। 2.61",

    "विषयको बारेमा चिन्तन गर्ने व्यक्तिको ती विषयहरूमा आसक्ति हुन जान्छ, आसक्तिबाट कामना उत्पन्न हुन्छ र कामनामा विघ्न हुँदा क्रोध उत्पन्न हुन्छ। 2.62",

    "बाधाहरूको सामना गर्दा जब क्रोध हुन्छ, त्यहाँ मुर्खता हुन्छ, सम्मोहनका कारण स्मरणशक्ति बिग्रन्छ । जब स्मरणशक्ति भ्रष्ट हुन्छ, बुद्धि (विवेक) नष्ट हुन्छ। जब बुद्धि नष्ट हुन्छ, सो पुरुष आफ्नो स्थितिबाट तल झर्दछ अर्थात् सर्वनाश हुन्छ। 2.63",

    "तर अन्त:करणलाई आफ्नो अधिनमा राखेको साधकले भने आफ्नो वशमा गरिएको रागद्वेषले रहित इन्द्रियहरूद्वारा विषयमा विचरण गर्दै अन्त:करणको प्रसन्नतालाई प्राप्त गर्दछ। 2.64",

    "चित्त प्रसन्न रहनाले त्यसका सब दु:खहरुको नाश हुन्छ, किनभने जसको चित्त प्रसन्न छ, त्यसको बुद्धि पनि तत्कालमा नै ठिक ठाउँमा स्थिर (अटल रहेको) हुन्छ। 2.65",

    "जसको मन र इन्द्रियहरू नियन्त्रित छैनन् उसको व्यापारिक दिमाग हुँदैन र (व्यावसायिक दिमागको कमीले) त्यो अशिक्षित व्यक्तिमा निस्वार्थता वा कर्तव्यपरायणताको भावना हुँदैन। त्यसलाई शान्ति हुदैन र जसलाई शान्ति छैन त्यसलाई सुख (आनन्द) कहाँबाट मिल्न सक्ला? 2.66",

    "कारण यो हो कि जुन मनले सबै इन्द्रियहरूबाट निकालेर आ-आफ्नो विषयहरूमा भड्काउँछ, त्यही मनले आफ्नो बुद्धिलाई खैँचेर लैजान्छ, जसरि पानीमा वायुले डुङ्गालाई घुमाएझै घुमाएर लैजान्छ। 2.67",

    "त्यसकारण हे महाबाहो ! जसको इन्द्रियहरूलाई उनका विषयबाट हटाएको हुन्छ, त्यस व्यक्तिको बुद्धि स्थिर हुन्छ। 2.68",

    "सबै प्राणीहरूको (परमात्माबाट विमुख हुने) रात, जसमा एक योगी पुरुष जागा रहन्छ र जसमा सबै प्राणीहरू जागा रहन्छन् (भोग र भेलामा व्यस्त हुन्छन्), त्यो तत्व जान्ने मुनि(ऋषि) को दृष्टिमा रात हो। 2.69",

    "जसरी सबै नदीहरूको पानी चारैतिरबाट आएर पानीले भरिएको सागरमा मिल्छ, तर समुद्र आफ्नो सीमाभित्र स्थिर रहन्छ, त्यसैगरी सबै भोग जुन स्थितप्रज्ञ पुरुषमा कुनै पनि प्रकारको विकार उत्पन्न नगरी समाहित हुन्छन्, त्यही पुरुषले परमशान्तिलाई प्राप्त गर्दछ, भोगहरुलाई चाहनेले होइन। 2.70",

    "जुन पुरुषले सबै आसक्ति छोडेर, नि:स्पृह भई, ममता र अहंकारलाई छोडदछ तब उसले शान्ति प्राप्त गर्छ। 2.71",

    "हे पार्थ ! यो ब्राह्मी स्थिति हो। यो प्राप्त गरेपछि (कोही पनि) मोहित हुँदैन र (यदि) अन्त्यकालमा पनि यस अवस्थामा रह्यो भने (तब) ब्रह्मनिर्वाण (शान्त) पदमा अर्थात् परब्रह्ममा लीन हुन जाने मोक्ष त्यसले अवश्य नै प्राप्त गर्दछ। 2.72",

    "यस प्रकार ॐ तत् सत् - यी भगवन्नामहरुको उच्चारणपूर्वक ब्रह्मविद्या र योगशास्त्रमय श्रीमद्भगवद्गीताको उपनिषद् रूप श्रीकृष्ण र अर्जुनको संवाद स्वरुप ‘सांख्ययोग’ नाम भएको दोस्रो अध्याय पूर्ण भयो।"
  ],
  "adhyaya-3" : [
    "अर्जुन विन्ती गर्दछन्- हे जनार्दन! यदि तपाँई कर्म भन्दा बुध्दि (ज्ञान) लाई श्रेष्ठ मान्नु हुन्छ भने फेरि हे केशव! मलाई यो युध्दको घोर कर्ममा किन लगाउनुहुन्छ? 3.1",

    "तपाईको यो आफ्नो दोधारे कुराले मेरो बुध्दि मोहित झै भएको छ । अतः तपाईंले निश्चय गरेर त्यो एउटा कुरा मात्र भन्नु होस् जसबाट मलाई धेरै भन्दा धेरै कल्याण प्राप्त होस् । 3.2",

    "श्रीभगवान् आज्ञा गर्नु हुन्छ– हे निष्पाप अर्जुन! यस लोकमा दुई प्रकारबाट हुनेवाला निष्ठा मैंले पहिले नै बताई सकेको छु । त्यसमा ज्ञानीहरुको निष्ठा ज्ञानयोग हो र योगीहरुको निष्ठा कर्मयोग हो । 3.3",

    "कर्महरुको शुरु नगर्नाले पुरुष निष्कर्म हुन सक्तैन र सन्न्यास लिएर कर्महरुको प्रारम्भलाई त्याग गरेर पनि सिध्दि मिल्न सक्तैन । 3.4",

    "कुनै पनि पुरुष कुनै पनि अवस्थामा क्षणभरको लागि पनि कर्म नगरिकन रहन सक्तैन; किनकि प्रकृतिको अधिनमा  भएका सबै प्राणिहरुलाई प्रकृतिबाट उत्पन्न भएको प्रकृति जन्य गुणले एउटा न एउटा कर्म गराएरै  छोडद्छन् । 3.5",

    "जो कर्मेन्द्रियहरु (सम्पूर्ण इन्द्रियहरु) लाई जबरजस्ती (हठपूर्वक) रोकेर, मनले इन्द्रियहरुका विषयमा चिन्तना गरेर बस्दछ, त्यो मूर्ख (मूढ) बुध्दि भएको पुरुषलाई मिथ्याचारी (मिथ्या आचरण गर्नेवाला) भनेर कहिन्छ । 3.6",

    "परन्तु हे अर्जुन! जो (मनुष्य) मन से इन्द्रियों पर नियन्त्रण करके आसक्ति रहित होकर (निष्काम भाव से) कर्मेंद्रियों (समस्त इन्द्रियों) के द्वारा कर्मयोग का आचरण करता है, वही श्रेष्ठ है। 3.7",

    "तिमी शास्त्र विधिबाट (आफ्नो धर्म अनुसार) तोकिएको कर्म (नियत कर्म) गर, किनकि कर्म नगर्नु भन्दा कर्म गर्नु केहि न केहि श्रेष्ठ छ तथा कर्म गरेन भने तिम्रो शरीर-निर्वाह पनि चल्न सक्ने छैन । 3.8",

    "यज्ञ (कर्तव्य पालन) को लागि   गरिने कर्महरु बाहेकका कर्महरुमा लागेको यो पुरुष लोक कर्म बन्धनमा बाधिएको हुन्छ, यसकारण हे कुन्तीपुत्र! तिमी आसक्ति-रहित भएर त्यो यज्ञ को लागि (नै) कर्तव्य कर्म गर। 3.9",

    "प्रजापति ब्रह्माजीले सृष्टि को शुरूआतमा कर्तव्य कर्महरुको  विधान सहित प्रजालाई सृष्टि गरि भन्नु भयो कि तिमी प्राणीहरु (प्रमुखत:  मनुष्य) यो  कर्तव्य यज्ञ द्वारा सबैको वृध्दिमा प्राप्त होऊ र यो कर्तव्य कर्मरूप यज्ञ तिमीहरुको  कर्तव्य-पालन को आवश्यक सामग्री प्रदान गर्ने भएको अर्थात तिम्रो इच्छा माफिकको फल दिनेवाला कामधेनु होस् । 3.10",

    "तिमीहरु यस यज्ञद्वारा देवताहरुलाई सन्तुष्ट गर र ती देवताहरुले तिमीहरुलाई सन्तुष्ट गर्दै रहुन । यसप्रकार निःस्वार्थ भावबाट परस्परमा सन्तुष्ट गराउदै तिमीहरु दुवै थरीले परम कल्याण प्राप्त गर । 3.11",

    "यज्ञको माध्यमबाट सन्तुष्ट भएका देवताले तिमीहरुलाई नमागीकन इच्छा माफिकको फल निश्चय नै  दिनेछन्,  यस प्रकार यी देवताहरुद्वारा दिएको फलको उपभोग जो पुरुष उनीहरुमा केहि समर्पण नगरि आफुमात्र उपभोग गर्दछ, त्यो साच्चै नै चोर हो । 3.12",

    "यज्ञशेष (योग)लाई ग्रहण गर्ने सज्जन पुरुषहरु सबै पापहरुबाट मुक्त हुन्छन । तर जो यज्ञ नगरेर केवल आफ्नो निमित्त मात्र अन्न पकाउँछन् अर्थात् सबै कर्म गर्दछन्, ती पापीहरुले पाप नै खान्छन् । 3.13",

    "सम्पूर्ण प्राणी अन्न देखि उत्पन्न हुन्छन्,  अन्नको उत्पत्ति वर्षाबाट हुन्छ, वर्षा यज्ञबाट उत्पन्न हुन्छ र यज्ञको उत्पत्ति कर्मबाट हुन्छ । 3.14",

    "कर्मलाई तिमी वेदबाट उत्पन्न भएको जान र वेदलाई अक्षरब्रह्मबाट (परब्रह्मबाट) प्रकट भएको जान । यसकारण त्यो सर्वव्यापी ब्रह्म यज्ञ कर्ममा सदा सर्वदा (नित्य निरन्तर) रहेको छ। 3.15",

    "हे पार्थ! जुन पुरुष यो लोकमा यसप्रकार चलाएका (परम्पराबाट) प्रचलित सृष्टि-चक्रका अनुसार चल्दैन, त्यो इन्द्रियहरुका  सुख भोगमा रमाउने अघायु (पापमय जीवन बिताउने) पुरुषको जीवन यो संसारमा व्यर्थ छ । 3.16",

    "तर जो मनुष्य आत्मामा नै रमाउने, आत्मा देखि नै तृप्त तथा संतुष्ट पनि छ, उसको लागि कुनै पनि कर्तव्य-कर्म (गर्नु पर्ने काम) बाँकी रहेको हुदैन । 3.17",

    "त्यो कर्मयोगबाट सिध्द भएको महापुरुषको यो संसारमा न त कर्म गर्नलाई केहि प्रयोजन बाँकि रहन्छ र न कर्म नगर्नबाट नै कोहि प्रयोजन रहन्छ तथा सम्पूर्ण प्राणिहरूमा (कुनैपनि प्राणीका साथ) उसको किंचिद्मात्र पनि स्वार्थको सम्बन्ध रहदैन । 3.18",

    "यसकारण तिमी सदा आसक्ति रहित भएर कर्तव्य कर्म सधैं गर्ने गर, किनकि आसक्ति रहित भएर जस्ले कर्म गर्दछ त्यो मनुष्यलाई परमगति प्राप्त हुन्छ अर्थात् परब्रह्म प्राप्त हुन्छ । 3.19",

    "राजा जनक जस्तै धेरै महापुरुषहरुले पनि कर्मयोगका माध्यमबाट नै परमसिध्दिलाई प्राप्त गरेका थिए । यसकारण लोकसंग्रहलाई दृष्टि दिएर पनि तिमीले कर्म गर्नु नै उचित छ अर्थात् अवश्य गर्नु पर्दछ । 3.20",

    "श्रेष्ठ आत्मज्ञानी कर्मयोगी पुरुषले यो लोकमा जे जे कर्म गर्दछ अरु साधारण पुरुषहरुले पनि देखा सिखी गरेर त्यहिँ त्यहिँ कर्म गर्दछन् । उसले ( ठूलोले) जे जे कुरालाई प्रमाणको रुपमा मानेर स्विकार गर्दछ लोक पनि त्यसैको अनुसरण अर्थात् नक्कल गर्दछन् । 3.21",

    "हे पार्थ! मलाई त्रिभुवनमा न त केहि कर्म गर्न बाँकि छ र न केहि प्राप्त गर्ने योग्य वस्तु अप्राप्त छ, तर पनि म कर्म (लौकिक काम) मा लागि रहेको हुन्छु । 3.22",

    "हे पार्थ! यसरी मैले कुनै पनि समयमा आलस्य लिएर वा चनाखो भएर सावधानपूर्वक कर्म गरिन भने  ता ठूलो नोक्सान हुन जान्छ, किनकी यी सारा मनुष्य सबै प्रकारले मेरो नै मार्गलाई अनुसरण गरेर कर्म गर्न छोड्नेछन् । 3.23",

    "यदि म कर्म न गरौ ता यी सबै मनुष्य नष्ट-भ्रष्ट हुनाले म वर्णसंकरकर्ता हुनेछु तथा यी सम्पूर्ण प्रजाहरुको नष्ट गर्ने वाला (संहारी) पनि मै बन्ने छु । 3.24",

    "हे अर्जुन! कर्म मा आसक्त भएका अज्ञानी पुरुष जसप्रकारले कर्म गर्दछन्, आसक्ति रहित भएर तत्त्वज्ञ महापुरुषले पनि लोक संग्रह गर्नको इच्छा त्यस्तै प्रकारले लोक मर्यादाका निमित्त (कर्म) गर्नु पर्दछ। 3.25",

    "ज्ञानी, तत्त्वज्ञ महापुरुषले कर्महरुमा आसक्ति भएका अज्ञानी पुरुषको बुध्दिमा भ्रम उत्पन्न नगराओस्, सम्पुर्ण कर्महरु लाइ व्यवस्थित (राम्रो) प्रकार  बाट आफु योगयुक्त भएर सबै कर्म गरोस् र लोकहरुबाट पनि सोहि किसिमले खुशीसाथ कर्म गराओस्। 3.26",

    "सम्पूर्ण कर्म सबै प्रकारबाट प्रकृतिका गुणहरु (सत्व, रज, तम) बाट भइरहन्छन् तर अहङ्कारबाट मोहित अन्तःस्करण भएका अज्ञानी पुरुषले सो लाइ 'म नै कर्ता हूँ' भनी ठान्दछ । 3.27",

    "तर हे महाबाहु! गुण-विभाग र कर्म-विभाग लाई तत्त्वबाट जान्ने भएको महापुरुष 'सम्पूर्ण गुण- गुणहरुमा (एकआपसमा) छ' -- भन्ने ठानेर उनीहरुमा आसक्त हुदैन । 3.28",

    "प्रकृति जन्य गुणहरुबाट अत्यन्तै मोहित भएको अज्ञानी पुरुष, गुणहरु र कर्महरुमा  आसक्त रहन्छ । त्यो पूर्णतया न सम्झने भएका मन्द बुध्दि अज्ञानि(अल्पज्ञ)हरुलाई पूर्णतया जान्ने भएको ज्ञानी पुरुषले विचलित नगराइ देओस् । 3.29",

    "तिमी अध्यात्म बुध्दिद्वारा सम्पूर्ण कर्तव्य र कर्महरुलाइ म मा अर्पण गरेर फलको आशा एवं ममता लाई छोडी, निश्चिन्त र संताप रहित भएर युध्द रूपी कर्तव्य कर्मलाई नै गर । 3.30",

    "जुन श्रध्दावान् पुरुष दोष-दृष्टिबाट रहित भएर मेरो यो (पूर्व श्लोकहरुमा वर्णन गरिएको) मतलाई सदा सर्वदा अनुसरण गर्दछ, त्यो पनि  कर्महरुको बन्धनबाट मुक्त हुन्छ । 3.31",

    "तर जुन पुरुष मेरो यो मतमा दोष-दृष्टिले शंकाहरु गरेर (यस्को) अनुष्ठान गर्दैन, त्यो सम्पूर्ण ज्ञानहरुमा मोहित र अविवेकी पुरुष लाई नष्ट भएको नै सम्झ अर्थात् उस्को पतन नै भएको ठान । 3.32",

    "सम्पूर्ण प्राणी आफ्नो  प्रकृति अनुसार नै चल्दछन् भने ज्ञानी महापुरुष पनिआफ्नो प्रकृति को नै अनुसरण गर्दछ । फेरि यसमा कसैले जवरजस्ती लिएर के हुन्छ? 3.33",

    "इन्द्रिय-इन्द्रिय को अर्थमा (प्रत्येक इन्द्रियको आफ्नो विषयमा) पुरुष यी राग र द्वेषका व्यवस्थाबाट अनुकूलता र प्रतिकूलतालाई लिएर स्थित रहेको छ । पुरुष यी दुईका वसमा हुनु हुदैन ; किनकि यी दुई नै पारमार्थिक (मोक्ष) मार्गका विघ्नकर्ता (शत्रु) हुन् । 3.34",

    "राम्ररी आचरण गरिएको अरुको  धर्मभन्दा गुणहीन आफ्नो धर्म नै उत्तम (श्रेष्ठ) छ । आफ्नो धर्मको आचरण गर्दा गर्दै मरिन्छ भने पनि त्यो श्रेयस्कर हुन्छ । परन्तु परधर्म भयकारक हुन्छ । 3.35",

    "अर्जुन बिन्ती गर्दछन् - हे वार्ष्णेय ! फेरि  यो मनुष्य आफ्नो ईच्छा नभएता पनि जबर्दस्ती लगाए झै कसको प्रेरणाले पाप कर्म गर्दछ?  3.36",

    "श्रीभगवान् आज्ञा गर्नुहुन्छ – रजोगुणबाट उत्पन्न भएको यो काम अर्थात् कामना नै पापको कारण हो । यो काम नै क्रोधमा परिणत हुन्छ । जत्ति पाए पनि तृप्त नहुने,  महापापी  यो काम र क्रोध नै मुख्य शत्रु हुन भनी जान । 3.37",

    "जसरि धुवाबाट  आगो, मैलो (धुलो) बाट   ऐना ढाकिएको हुन्छ तथा जसरि जालोबाट गर्भ ढाकिएको हुन्छ, त्यस्तै नै कामनाद्वारा यो ज्ञान अर्थात् विवेक ढाकिएको छ । 3.38",

    "हे कुन्तीपुत्र अर्जुन ! यो आगोको समान कहिल्यै नअघाउने (तृप्त नहुने) कामरूपी नित्य-बैरीबाट  सारा लोकको ज्ञान ढाकिएको छ । 3.39",

    "सम्पूर्ण इन्द्रिय, मन र बुध्दि यो कामनाको  वासस्थान भनिएको छ । यो कामना (यी इन्द्रिय, मन र बुध्दि) को आश्रयले ज्ञानलाई ढाकेर देहाभिमानी मनुष्य (आत्मा) लाई मोहमा फसाउँछ ।  3.40",

    "यसकारण हे भरतकुलमा श्रेष्ठ अर्जुन ! तिमी सबै भन्दा पहिले इन्द्रियहरुलाई वशमा राखेर यो ज्ञान र विज्ञान लाई नाश गर्नेवाला   महापापी कामनालाई  अवश्य नै बलपूर्वक मारी हाल । 3.41",

    "इन्द्रियहरु स्थूल शरीरबाट पर श्रेष्ठ, सबल, प्रकाशक, व्यापक तथा सूक्ष्म छन् भनिन्छ । इन्द्रियहरु भन्दा पर मन छ, मन भन्दा पनि पर बुध्दि छ ऱ बुध्दि भन्दा पनि पर जो छ, त्यो आत्मा (ब्रह्म) सर्वश्रेष्ठ छ ।  3.42",

    "यस प्रकारले बुध्दि भन्दा परको आत्मा (ब्रह्म) लाई  जानेर आफै (बुध्दि) ले  मनलाई वशमा गरेर हे महाबाहु ! तिमी यो हटाई न सक्नु कामरूपी शत्रुलाई मारी हाल (नास गर) ।  3.43",

    "यस प्रकार ॐ तत्  सत् - यी भगवन्नामहरुको उच्चारणपूर्वक ब्रह्मविद्या र योगशास्त्रमय श्रीमद्भगवद्गीताको उपनिषद् रूप श्रीकृष्ण र अर्जुनको संवाद स्वरुप  ‘कर्मयोग’ नाम भएको तेस्रो अध्याय पूर्ण भयो।"
  ],
  "adhyaya-4" : [
    "श्रीभगवान् आज्ञा गर्नुहुन्छ- मैंले यो अविनाशी योग (कर्मयोग) सृष्टिको आदिमा पहिले सूर्यलाई सुनाएथें । (फेरि) सूर्यले आफ्ना छोरा (वैवस्वत) मनुलाई सुनाए र मनुले आफ्ना छोरा राजा इक्ष्वाकुलाई सुनाए। 4.1",

    "हे परंतप ! यसप्रकार परम्पराबाट प्राप्त यो कर्मयोगलाई राजर्षिहरुले जान्नु भयो तर धेरै समय ब्यतित भएको कारणले त्यो योग यो मनुष्य लोकमा नष्ट भयो अर्थात् लुप्तप्राय: भइ सक्यो। 4.2",

    "तिमी मेरा भक्त र  प्यारो सँगी (साथी) हौँ, यसकारण त्यहिँ प्राचीन यो योग आज मैंले तिमीलाइ बताए; किनकि यो ठूलो उत्तम रहस्य हो।  4.3",

    "अर्जुन बिन्ती गर्दछन्- तपाइको जन्म त अहिले भएको छ र सूर्यको जन्म धेरै पहिले भइसकेको छ; अतः यस्तो अवस्थामा तपाईंले (नै) सृष्टिको आदिमा सूर्यलाई यो योग भनिएको हो भन्ने यो कुरा म कसरी पत्याऊँ? (जानुँ?) 4.4",

    "श्रीभगवान् आज्ञा गर्नुहुन्छ-- हे परन्तप अर्जुन ! मेरो र तिम्रो अनेक जन्म भइसकेको छ। ति सबैलाइ म जान्दछु, (तर) तिमी जान्दैनौ। यत्ति तिम्रो र मेरो फरक छ। 4.5",

    "म जन्मरहित र अविनाशी स्वरूपको भएर पनि तथा सम्पूर्ण प्राणिहरूको ईश्वर भएर पनि आफ्नो प्रकृतिलाई अधीनमा राख्दै (अधिष्ठित हुदै) आफ्नै योगमायाले प्रकट हुन्छु (जन्म लिन्छु)। 4.6",

    "हे भारत! जहिले जहिले धर्मको हानि र अधर्म को वृध्दि हुन्छ, त्यही त्यही समयमा नै म आफै आफूलाई (साकार रूपबाट) प्रकट गराउँदछु अर्थात् अवतार लिने गर्दछु। 4.7",

    "साधुहरुको (भक्तहरुको) रक्षा गर्नका लागि, पाप कर्म गर्ने वाला हरुलाइ विनाश गर्नकोलागि र धर्मलाइ यथारुपमा (भली भाँति) स्थापना गर्नको लागि म युगयुगमा अर्थात् हरेक युगमा जन्म (अवतार) लिने गर्छु। 4.8",

    "हे अर्जुन ! मेरो जन्म र कर्म दिव्य (अलौकिक) छ । यस प्रकार मेरो जन्म र कर्मलाई जुन मनुष्यले तत्त्वबाट जान्दछ  अर्थात् दृढ़तापूर्वक मान्दछ, त्यसले शरीरलाई त्याग गरे पछि पुनर्जन्मलाई  प्राप्त गर्दैन अर्थात् फेरि जन्म लिदैन वस्तुत मसित नै आएर मिल्दछ।  4.9",

    "राग,भय र क्रोध बाट सधैं रहित भई, अनन्य भावले मेरो शरणमा आएका, ममा नै आश्रित (तथा) ज्ञान रूप तपस्या बाट पवित्र भएका धेरै भक्त मेरो स्वरूपमा आई मिलिसकेका छन्। 4.10",

    "हे पार्थ ! जुन भक्तले मलाई जस्ता प्रकारले मेरो भजन गर्दछ, म उसलाई त्यसै प्रकारको आश्रय दिन्छु; (किनकि) सबै मनुष्य सबै प्रकारबाट मेरो मार्गलाई अनुकरण गर्दछन्।  4.11",

    "कर्महरुको फल (सिध्दी) को इच्छा राख्ने मनुष्यले देवताहरुको पूजा (उपासना) गर्ने गर्दछन; किनभने यस मनुष्यलोकमा कर्महरुबाट उत्पन्न हुनेवाला फल (सिध्दि) बहुतै चाडो पाइन्छ।  4.12",

    "मबाट गुणहरु र कर्महरुको विभाग गरेर चारै वर्णहरुको रचना गरेको छु। त्यस (सृष्टि-रचना आदि) को कर्ता भएका कारणले पनि म अविनाशी ईश्वरलाई (तिमी) अकर्ता नै जान। 4.13",

    "(किनभने) कर्महरुको फलमा मेरो ईच्छा छैन्, (यसकारण) मलाई कर्मको बाधा-बन्धन हुदैन। यस प्रकार जो मलाई तत्त्वबाट जान्दछ, त्यो (पनि) कर्महरुबाट बाँधिदैन अर्थात् जन्म-मरणादि हुँदैन। 4.14",

    "पूर्वकालका मुमुक्षुहरुले पनि यसप्रकार जानेर कर्म गरेका थिए, त्यसैले तिमी (पनि) पूर्वजहरु द्वारा सधैँबाट गरि आएको अतिप्राचिन कर्महरुलाई नै (उनीहरु कै सरह) गर। 4.15",

    "कर्म के हो? (र) अकर्म के हो? यस प्रकार यस विषयमा ठूला ठूला विद्वान हरुलाई पनि भ्रम हुन जान्छ। (अतः)  त्यो कर्म तत्त्व (म) तिमीलाई सविस्तार (भली भाँति) भन्दछु, जसलाई जानेर तिमी पाप (संसार-बन्धन) बाट मुक्त हुनेछौ। 4.16",

    "कर्म (तत्त्व) लाई जान्नु पर्छ र अकर्म (कर्म नगर्नु) तत्त्वलाई पनि जान्नु पर्छ तथा विकर्म अर्थात् विपरीत कर्म (पाप कर्म) तत्त्वलाई पनि जान्नु पर्छ; किनकि कर्मको गति बडो गहन छ अर्थात् बुझ्नलाई झन कठिन छ। 4.17",

    "जुन मनुष्यले कर्ममा अकर्म देख्दछ र जसले अकर्ममा पनि कर्म देख्दछ, त्यो मनुष्यहरुमा बुध्दिमान् हो, त्यो योगी हो र सम्पूर्ण कर्महरुलाई गर्ने वाला पुरुष (कृतकृत्य) हो।  4.18",

    "जसको सम्पूर्ण कर्महरुको सुभारम्भ संकल्प र कामना फलको ईच्छाबाट रहित छन् तथा जसको सम्पूर्ण कर्म ज्ञानरुपी अग्निबाट भस्म भएको छन्, त्यस्तो पुरुष लाई नै ज्ञानीपुरुषहरु पण्डित (बुध्दिमान्) भनेर बताउँदछन्। 4.19",

    "जो कर्मका फलको इच्छा छोडेर, कर्ममा आसक्ति त्याग गरेर, आश्रयबाट रहित र सदा सन्तुष्ट रहि त्यो कर्महरुमा राम्रै प्रकारले प्रवृत्त रहोस् तापनि त्यो केहि गर्दैन। 4.20",

    "जसको शरिर र अन्तःस्करण राम्रो किसिमबाट  वशमा गरिएको छ, जसले सबै प्रकारका संग्रहलाई परित्याग गरेको छ, यस्तो इच्छा रहित कर्मयोगीले केवल शरीर-सम्बन्धी कर्म गरेता पनि पापलाई प्राप्त गर्दैन् अर्थात् पापको भागी हुँदैन। 4.21",

    "जो कर्मयोगी (पुरुष) फलको इच्छाका बिना, संयोगवशले जो कुछ आईपर्दछ, त्यसैमा सन्तुष्ट रहन्छ र जो ईर्ष्याबाट रहित, द्वन्द्वहरुबाट रहित तथा सिध्दि र असिध्दिमा बराबर (समान रहने) देख्दछ, त्यो पुरुष कर्म गरेर पनि तिनका बन्धनबाट (पापपुण्यबाट) बाँधिदैन। 4.22",

    "जसको आसक्ति सर्वथा मेटिन गएको छ, जो मुक्त भएको छ, जसको बुध्दि स्वरूपको ज्ञानमा स्थिरचित्त छ, यस्तो केवल यज्ञको लागि कर्म गर्नेवाला पुरुषका सम्पूर्ण कर्म (कर्मबन्धन) नष्ट हुन जान्छ अर्थात् बन्धन हूँदैन। 4.23",

    "जुन यज्ञमा अर्पण अर्थात् जुनबाट अर्पण गरिन्छ, ती हवन गर्ने क्रिया, होमिने द्रब्य आदि पात्र पनि ब्रह्म हो, हवन‌गर्ने पदार्थ (तिल, जौ, घीउ आदि) पनि ब्रह्म हो र ब्रह्मरूप कर्ताका द्वारा ब्रह्मरूप अग्निमा आहुति दिनु, रूप क्रिया पनि ब्रह्म हो, (यस्तो यज्ञलाई गर्नेवाला) जुन मनुष्यलाई ब्रह्ममा नै कर्म-समाधि प्राप्त भएको छ, त्यसका द्वारा प्राप्त गर्ने योग्य फल पनि ब्रह्म  नै हो। 4.24",

    "कोही योगी पुरुष देव (भगवद् द्रर्पण रूप) यज्ञ को नै अनुष्ठान गर्दछन् र कोही योगी पुरुष ब्रह्मरूपी अग्निमा (विचार रूप) यज्ञद्वारा नै (जीवात्मा रूप) हवन (पूजन) गर्दछन्।‌ 4.25",

    "कोही योगी पुरुष  श्रोत्रादि समस्त इन्द्रियहरुलाई संयमरूपी अग्निमा हवन गर्ने गर्दछन् र कोही योगी पुरुष शब्दादि विषयहरुलाई इन्द्रियरूपी अग्निहरुमा हवन  गर्ने गर्दछन् अर्थात् केहि कुराको पनि चाहना गर्दैनन्।  4.26",

    "कोही योगी पुरुष सम्पूर्ण इन्द्रियहरुका क्रियाहरुलाई र प्राणका क्रियाहरूलाई ज्ञान बाट प्रकाशित आत्मसंयमयोग रूपी (समाधियोग रुपी)  अग्निमा हवन गर्ने गर्दछन्। 4.27",

    "अर्को कठिन नियममा व्रत गर्नेवाला प्रयत्नशील योगीहरु कोही द्रव्यमययज्ञ गर्ने गर्दछन्, कोही तपोयज्ञ गर्ने, कोही योगयज्ञ गर्ने तथा कोही स्वाध्यायरूपी ज्ञानयज्ञ गर्ने गर्दछन्। 4.28",

    "कोही प्रयत्नशील, तीक्ष्णव्रतधारी, प्राणायामी योगी पुरुषहरु अपानमा प्राण लाई पूरक गरेर, प्राण र अपानको गतिलाई रोकेर (कुम्भक गरेर) फेरि प्राणमा अपानलाई हवन (रेचक) गरेर प्राणायाममा परायण हुन्छन्। 4.29",

    "तथा कोही नियमित आहार गर्नेवाला प्राणीलाई प्राणैमा हवन गर्ने गर्दछन्। यी सबै साधक यज्ञहरुद्वारा पापहरुलाई नाश  गर्ने र यज्ञहरुलाई जान्ने यज्ञवेत्ता ज्ञानी हुन।  4.30",

    "हे कुरू श्रेष्ठ अर्जुन! यज्ञबाट बचेको अमृतलाई सेवन गर्ने पुरुष सनातन परब्रह्म परमात्मालाई प्राप्त गर्दछन्। यज्ञ नगर्नेवाला पुरुषको लागि यो अल्पसुख भएको मृत्यु लोकमा सफलता प्राप्त हुदैन भने फेरि त्यसकालागि परलोकमा सुख कसरी मिल्न सक्ला? 4.31",

    "यसप्रकार धेरै प्रकारका यज्ञहरू वेदका वाणी (वचन) मा विस्तार पूर्वक वर्णन गरिएको छ । ती सबै यज्ञहरु कर्मबाट सिध्द हुन्छन् । यो जान्नाले तिमी कर्म बन्धनबाट मुक्त हुन जान्छौं। 4.32",

    "हे परन्तप अर्जुन ! द्रव्यमय यज्ञ भन्दा ज्ञानमय यज्ञ श्रेष्ठ छ। हे पार्थ ! सम्पूर्ण कर्म र पदार्थको समाप्ति ज्ञान (तत्त्वज्ञान) मा नै हुन जान्छ। 4.33",

    "तिमी त्यो तत्त्वज्ञानलाई तत्त्वदर्शी ज्ञानी महापुरुषहरुकानजिकमा गएर सिक (समझ)। उनलाई साष्टांग दण्डवत् प्रणाम गरे पछि, (उनलाई) सेवा गरे पछि र सरलतापूर्वक प्रश्न गरे पछि ती  तत्त्वदर्शी (अनुभवी) ज्ञानी (शास्त्रज्ञ) महापुरुष तिम्रा भक्तिले प्रसन्न भएर तिमीलाई तत्त्वज्ञानको उपदेश गर्नेछन्। 4.34",

    "हे पाण्डव ! यस तत्त्वज्ञानलाई अनुभव गरे पछि  तिमीमा फेरि यो प्रकारको मोह हुनेछैन र जुन तत्त्वज्ञानको योगले तिमीसम्पूर्ण प्राणिहरुलाई  निःशेषभावबाट पहिले आफुमा र त्यसपछि सबैलाई म सच्चिदानन्द घन परमात्मामा पनि देख्नेछौ। 4.35",

    "यदि तिमी सबै पापीहरु भन्दा पनि धेरै पापी छौ भने पनि, तिमी ज्ञानरूपी डुँगाको सहाराले नै निःसन्देह सम्पूर्ण पापको सागरबाट राम्रै प्रकारले तारिन्छौं (मुक्त हुनेछौ)। 4.36",

    "हे अर्जुन ! जस्तै बलेका अग्निले काठहरुलाई जलाएर भस्म गर्दछ, यस्तै नै ज्ञानरूपी अग्निले सम्पूर्ण कर्महरुलाई अर्थात् पापपुण्यरुपी बन्धनहरुलाई सम्पूर्ण रुपले भस्म गरिदिन्छ (जलाइदिन्छ)। 4.37",

    "यो संसारमा ज्ञानका समान पवित्र गर्नेवाला निःसन्देह दोस्रो कुनै साधन छैन । जसको योग भली-भाँति किसिमले सिध्द भएकोछ, त्यो कर्मयोगीले तत्त्वज्ञानलाई अवश्य नै स्वयं आपसे आप पाउँदछ।  4.38",

    "जो जितेन्द्रिय तथा साधन-परायण छ, यस्तो श्रध्दावान् पुरुषलाई ज्ञान प्राप्त हुन्छ र त्यसले ज्ञानलाई प्राप्त गरेपछी (त्यो) तत्काल परम शान्तिलाई पाउँछ अर्थात् मुक्ति पाउँछ।  4.39",

    "विवेकहीन र श्रध्दारहित संशयात्मा (शंकाधारी) पुरुष पतन हुन्छ । यस्तो सन्देह मान्ने (संशयात्मा) पुरुषको लागि न त यो लोक हितकारक छ, न त परलोक हितकारक छ । त्यसलाई सुख पनि मिल्दैन । 4.40",

    "हे धनञ्जय ! जसले  कर्मयोगको आश्रय गरेर ईश्वरमा कर्महरुलाई समर्पण गरेको छ र विवेक (ज्ञान) ले जसको सम्पूर्ण शंकाहरुको  नाश भएको छ, त्यस्तो आत्मज्ञानी पुरुषलाई कर्मका पाप-पुण्यरुपी बन्धनहरुले बाँध्न सक्दैनन्। 4.41",

    "यसकारण हे भारत ! हृदयमा रहेको यो अज्ञानबाट उत्पन्न भएको यो आफ्नो शंकालाई ज्ञानरूपी तलवारले काटेर कर्मयोगको आश्रय गरेर युध्दको निमित्त खडा होऊ अर्थात् तैयार होऊ। 4.42",

    "यस प्रकार ॐ तत् सत् - यी भगवन्नामहरुको उच्चारणपूर्वक ब्रह्मविद्या र योगशास्त्रमय श्रीमद्भगवद्गीताको उपनिषद् रूप श्रीकृष्ण र अर्जुनको संवाद स्वरुप ‘ज्ञानकर्मसन्न्यासयोग’ नाम भएको चौथो अध्याय पूर्ण भयो।"
  ],
  "adhyaya-5" : [
    "अर्जुन बिन्ती गर्दछन्- हे कृष्ण !  तपाई कर्महरुलाई स्वरूपबाट त्याग  गर्नकालागि र फेरि कर्मयोगको प्रशंसा गर्नुहुन्छ । अतः यी दुबै साधनहरुमा जुन एक निश्चित रूपबाट कल्याण कारक छ, त्यसलाई मेरोलागि बताइबक्सनुहवस् । 5.1",

    "श्रीभगवान् आज्ञा गर्नुहुन्छ - संन्यास (सांख्ययोग) र कर्मयोग दुवै मार्ग नै मोक्ष प्राप्त गराउने  हुन्; तर मोक्षतर्फको दृष्टिले दुबैको योग्यता समान भएतापनि कर्मसंन्यास (सांख्ययोग) भन्दा कर्मयोग श्रेष्ठ छ । 5.2",

    "हे महाबाहु ! जो मनुष्य न कसैलाई द्वेष गर्दछ र न कसैको अभिलाषा राख्दछ; त्यो कर्मयोगीलाई नित्यसंन्यासी सम्झ; किनकी द्वन्द्वहरुबाट रहित मनुष्य सुखपूर्वक कर्मका सबै बन्धनबाट मुक्त हुन जान्छ  5.3",

    "हे महाबाहु ! जो मनुष्य न कसैलाई द्वेष गर्दछ र न कसैको अभिलाषा राख्दछ; त्यो कर्मयोगीलाई नित्यसंन्यासी सम्झ; किनकी द्वन्द्वहरुबाट रहित मनुष्य सुखपूर्वक कर्मका सबै बन्धनबाट मुक्त हुन जान्छ । 5.4",

    "मुर्खहरु सांख्ययोग र कर्मयोगलाई अलग-अलग फल दिने भन्दछन्, परन्तु पण्डितहरु यस्तो मान्दैनन्; किनकी यी दुबै मध्ये एक साधनलाई पनि राम्रो किसिमबाट आचरण गर्नाले मनुष्यले दुवैको फल (परमात्मा) प्राप्त गर्दछन् । 5.5",

    "सांख्ययोगीहरु बाट जुन तत्त्व प्राप्त गर्ने काम हुन जान्छ, कर्मयोगीहरुबाट पनि त्यहीं प्राप्त गर्ने काम हुन जान्छ । अतः जुन मनुष्य सांख्ययोग र कर्मयोगलाई  (फलरूपमा) एकै देख्दछ, त्यसैले यथार्थ (वास्तविक तत्व) देख्दछ । 5.6",

    "तर हे महाबाहु ! कर्मयोगका बिना संन्यासलाई प्राप्त गर्न मुस्किल पर्दछ । निष्काम कर्म गर्ने मुनि शीघ्र नै ब्रह्ममा प्राप्त हुन्छन्  5.7",

    "जस्को इन्द्रियहरु आफ्ना वशमा छन्, जस्को अन्तःस्करण निर्मल छ, जस्को शरिर आफ्नो वशमा छ, र सम्पूर्ण प्राणिहरुको आत्मा नै जस्को आत्मा छ, यस्तो कर्मयोगी सब कर्म गरोस् तापनि कर्मका पुण्य पापबाट अलिप्त (फरक) रहन्छ । 5.8",

    "योगयुक्त तत्त्ववेत्ता पुरुषले हेरेको, सुनेको, छोएको, सूँघेको, हिडेको, खाएको, लिएको (ग्रहण गरेको), बोलेको , मल-मूत्रलाई विसर्जन गरेको, सुतेको, सास लिएको, आँखा खोलेको र बन्द गरेको पनि केवल इन्द्रियहरुले आ-आफ्नो  विषयहरूको काम गरिरहेका छन्, यस्तो सम्झिएर म स्वयं केहि पनि गर्दिन भन्ने मानोस् । 5.9",

    "जो भक्तियोगी सम्पूर्ण कर्महरुलाई परमात्मामा अर्पण गरेर र आसक्तिलाई त्याग गरेर कर्म गर्दछ, त्यो पानीबाट कमलका पत्तालाई नछोए सरि पापबाट लिप्त हुँदैन । 5.10",

    "यसकारण निष्काम कर्मयोगीहरु आत्मशुध्दिका निमित्त आशक्ति छाडी केवल शरिरले, मनले, बुध्दिले र इन्द्रियहरुले कर्म गरि रहन्छन् । 5.11",

    "कर्मयोगी कर्म फललाई त्याग गरेर भगवत् प्राप्तिरूप शान्तिलाई प्राप्त गर्दछन् । तर सकामी मनुष्य कामनाको कारण फलमा आसक्त भएर कर्म गर्दछ र पुण्य पापबाट बाँधिने हुन्छ । 5.12",

    "जस्को इन्द्रियहरु र मन वशमा छ, यस्तो देहधारी पुरुष नौ ढोका भएको यो शरीररुपी शहरमा सम्पूर्ण कर्महरुलाई विवेकपूर्वक मनबाट त्याग गरेर न केहि गर्दछ, न त केहि गराउँदछ र आफ्नो स्वरुपमा आनन्दपूर्वक बसिरहन्छ ।  5.13",

    "परमेश्वरले मनुष्यहरुका न कर्तृत्वलाई, न तिनका कर्महरुलाई, न प्राप्त हुने कर्मफलका साथ संयोगलाई निर्माण (रचना) गर्दछ; वास्तवमा (उस्को) स्वभावले नै अर्थात् प्रकृतिले नै सब कुछ गराउँदछिन् ।  5.14",

    "सर्वव्यापी परमात्माले न कसैको पाप वा पुण्य कर्मलाई र न शुभ कर्मलाई नै ग्रहण  गर्दछ; जो अज्ञानबाट ज्ञान ढाकिएको छ, त्यसैबाट सबै जीव मोहित भइ रहेकाछन् । 5.15",

    "तर जस्ले आफ्नो जुन ज्ञानकाद्वारा त्यो अज्ञानलाई नाश गरि दिएको छ, उस्को त्यो ज्ञान सूर्यका समान परमतत्त्व परमात्मालाई प्रकाशित गरि दिन्छ । 5.16",

    "त्यस परमार्थ तत्वमा जस्को बुद्धि रंगिएको छ र जस्को अन्तःस्करण (मन) रमाएको छ, यस्ता परमात्म परायण भएका साधकका पाप ज्ञानका माध्यमद्वारा सबै धोइन्छन् र तिनीहरु फेरि जन्म लिदैनन् ।  5.17",

    "ज्ञानी महापुरुषहरु विद्या र विनयले युक्त ब्राह्मण तथा गाई, हात्ती, कुकुर, चाण्डाललाई समान दृष्टिले देख्दछन् ।  5.18",

    "जस्को मनमा (अन्तःस्करणमा) समत्वभावना स्थित छ, त्यसले नै यो जीवित अवस्थामा नै सम्पूर्ण संसारलाई जीतेको छ, अर्थात् त्यो जीवन्मुक्त भएको छ, किनकि ब्रह्म दोषरहित र समान छ, यसकारण साम्यबुध्दि भएका पुरुष सधैं ब्रह्ममा रहेर यसै लोकमा ब्रह्मभूत (ब्रह्मरूप) हुन्छन् । 5.19",

    "जो प्रिय वा इष्ट बस्तु प्राप्त गर्दा हर्षित हुँदैन र अप्रियलाई प्राप्त हुदाँ खिन्न पनि हुदैन, त्यो स्थिर बुध्दिभएको, संशयरहित  ब्रह्मवेत्ता मनुष्य ब्रह्ममा नै स्थित रहन्छ  5.20",

    "बाह्य स्पर्श (प्राकृत वस्तुमात्रको सम्बन्ध) अर्थात् विषयोपभोगमा जसको मन आसक्त छैन, त्यसलाई नै आत्मसुख (सात्विक सुख) मिल्दछ र त्यो ब्रह्मयुक्त पुरुष अक्षय सुखको अनुभव गर्दछ । 5.21",

    "किनकी हे कौन्तेय ! जो इन्द्रिय र विषयका संयोगले उत्पन्न हुने सारासुख दु:खादि भोगको आदि र अन्त्य छ, अर्थात् आउनासाथ नासिन्छन् । यसैले तिनीहरू दुःखका नै कारण हुन् । अतः विवेकशील मनुष्य त्यसमा रमाउदैनन्‌  (त्यस्तोमा आसक्ति लिदैनन्) । 5.22",

    "जो पुरुष शरीर छुटनु भन्दा पहिला नै काम र क्रोधबाट उत्पन्न हुने वेगलाई सहन गर्नमा समर्थ हुन्छ, त्यही पुरुष यस लोकमा योगी हो, सुखी हो । 5.23",

    "जो पुरुष आत्मसुखले सुखी, अन्तरात्माको आनन्दले आनन्दित तथा  आत्मज्ञानले प्रकाशित छ, त्यो योगी ब्रह्मरूप भएर ब्रह्मको निर्वाण (ब्रह्ममा लीन हुने रूप, मोक्ष) पदमा प्राप्त हुन्छ । 5.24",

    "जसको शरिर मन, बुध्दि, इन्द्रियहरु सहित वशमा छ, जो सम्पूर्ण प्राणिहरुको भलो चिताउँछ, जसको सम्पूर्ण शंका मेटिएर गएको छ, जसको सम्पूर्ण पाप नष्ट भएको छ, त्यो ब्रह्मवेत्ता (ज्ञानी) साधकले ब्रह्मनिर्वाणरूप (मोक्ष) लाई प्राप्त गर्दछ।  5.25",

    "काम-क्रोधबाट सर्वथा रहित, मनलाई जित्ने आत्मसंयमी र आत्मज्ञानले युक्त भएका सांख्ययोगी (मुनी) कोलागि  सबै  तिरबाट (शरीर हुदै वा शरीर छूटे पछि पनि) ब्रह्म निर्वाणमा (मोक्षमा) पुगिने हुन्छ। 5.26",

    "बाहिरका पदार्थहरुलाई बाहिर नै त्याग गरेर र आखाँको दृष्टिलाई आँखी भौंका बीचमा जमाएर तथा नासिकामा विचरन गर्ने प्राण र अपान वायुलाई समान राखेर जसको इन्द्रिय, मन र बुध्दि आफ्नो वशमा छ तथा जसको इच्छा, भय र क्रोधबाट सर्वथा रहित छ, त्यो मोक्ष परायण मुनि सदासर्वदा मुक्त नै हो । 5.27-5.28",
    
    "बाहिरका पदार्थहरुलाई बाहिर नै त्याग गरेर र आखाँको दृष्टिलाई आँखी भौंका बीचमा जमाएर तथा नासिकामा विचरन गर्ने प्राण र अपान वायुलाई समान राखेर जसको इन्द्रिय, मन र बुध्दि आफ्नो वशमा छ तथा जसको इच्छा, भय र क्रोधबाट सर्वथा रहित छ, त्यो मोक्ष परायण मुनि सदासर्वदा मुक्त नै हो । 5.27-5.28",

    "जो पुरुषले मलाई सबै यज्ञ र तपहरुको भोग गर्ने, सम्पूर्ण लोकहरुको अधिपति (ईश्वर) तथा सम्पूर्ण प्राणिहरुको सुहृद् (स्वार्थ रहित, दयालु, प्रेमी, भलो चाहने मित्र) संम्झन्छ, त्यस पुरुष(भक्त)ले नै शान्ति पाउँछ । 5.29",

    "यस प्रकार ॐ तत् सत् - यी भगवन्नामहरुको उच्चारणपूर्वक ब्रह्मविद्या र योगशास्त्रमय श्रीमद्भगवद्गीताको उपनिषद् रूप श्रीकृष्ण र अर्जुनको संवाद स्वरुप ‘कर्मसन्न्यासयोग’ नाम भएको पाँचौ अध्याय पूर्ण भयो ।"
  ],
  "adhyaya-6" : [
    "श्रीभगवानले भन्नुभयो -- कर्मफलको आश्रय नलिएर अर्थात् फलाशालाई छोडेर जस्ले कर्तव्य कर्म गर्दछ, त्यही संन्यासी तथा योगी हो; र केवल अग्निलाई त्याग गर्ने (अग्निहोत्रादि) पुरुष संन्यासी हुदैन तथा केवल क्रियाहरुलाई त्याग गर्ने पुरुष पनि योगी हुदैन । 6.1",

    "हे अर्जुन ! (व्यक्तिले) जसलाई संन्यास भन्दछन्, उसैलाई तिमी  योगी सम्झ; किनकि संकल्प अर्थात् फलाशालाई ईश्वरार्पण नगरि (मनुष्य) कोहि पनि योगी हुन सक्दैन् । 6.2",

    "जो योगमा आरूढ़ हुन इच्छा गर्दछ, (यस्तो) मननशील योगीको लागि कर्मलाई शमको कारण अर्थात् साधन बताइएको छ र त्यसै योगारूढ़ पुरुषका निमित्त शम (शान्ति) (परमात्म प्राप्ति) चाहिँ कर्मको साधन हुन्छ भनेर बताइएको छ । 6.3",

    "किनभने जब त्यो पुरुष इन्द्रियहरुका भोगमा तथा कर्महरुमा आसक्त हुदैन तब सधैं संकल्प अर्थात् काम्यबुध्दिरूप फलाशा त्यागी पुरुष योगारूढ़ (योगमा चढिसकेको) कहलाउँछ । 6.4",

    "पुरुषले आफैद्वारा आफ्नो उध्दार गरोस्, आफैले आफैलाइ कहिल्यै अधोगति नगराओस्, किनभने हरेक मनुष्य आफै आफ्नो बन्धु अर्थात् मित्र हो र आफू नै आफ्नो शत्रु पनि हो । 6.5",

    "जसले खुद आफैले आफ्नो मन (अन्त:करण) लाई जित्छ (वशमा राख्दछ), उसको लागि आफू (आत्मा) नै आफ्नो बन्धु (मित्र) हो र जसले आफूले आफैँ (आत्मा) लाई अधिनमा राख्न सकेको छैन्, यस्तो अनात्माको आत्माले नै आफूसँग शत्रुसरह वैरभाव गर्दछ । 6.6",

    "जसले आफैले आफैलाइ विजय गरेकोछ अर्थात् अन्त:करणलाई अधिनमा राखेको छ, त्यसले शीत-उष्ण (अनुकूलता-प्रतिकूलता) सुख-दुःख तथा मान-अपमानमा समेत एकनास शान्ति लिएको छ । त्यस्तो निर्विकार योगीको समाधिमा परब्रह्म परमात्माको स्फूरण (झल्का) नित्य प्राप्त हुन्छ ।  6.7",

    "जसको आत्मा (अन्तःकरण) ज्ञान र विज्ञानबाट तृप्त भएको होस्, जसले आफ्ना इन्द्रियहरुलाई जितेको होस्, जसले माटोको डल्लो, ढुङ्गा तथा सूनमा भेदभाव नदेख्ने अर्थात् समान सम्झने होस्, यस्तो कर्म गर्ने कर्मयोगी पुरुषलाई योगारूढ़ अर्थात् सिध्दावस्थामा पुगेको (योगी) कहिन्छ । 6.8",

    "सुह्रद्, मित्र, शत्रु, उदासीन, मध्यस्थ, द्वेष्य र बन्धुवर्ग, साधु र पापीहरुको विषयमा पनि जसले समभाव (बराबर) राख्दछ, त्यो पुरुष अति श्रेष्ठ ठहर्दछ । 6.9",

    "भोग बुध्दिबाट संग्रह नगर्ने, कुनै पनि काम्यवासनाहरुलाई नराखेर, अन्तःकरण तथा शरीरलाई वश (संयम) मा राखी योगी एक्लै एकान्तमा बसेर आत्मालाई निरन्तर आफ्नो योगाभ्यासमा (परमात्मामा) लगावोस् । 6.10",

    "शुध्द भूमिमा जसमा क्रमशः कुश, मृगछाला र त्यसमाथि वस्त्र (तन्ना)  बिछ्याओस्, जो न त अत्यन्त ऊँचो होस्, न त अत्यन्त नीचो, (यस्तो) आफ्नो आसनलाई स्थिर रुपमा स्थापना गरेर । 6.11",

    "त्यही (आसनमा) बसेर चित्त र इन्द्रियहरुको क्रियाहरुलाई वशमा राख्दै अनि मनलाई पनि एकाग्र बनाएर अन्तःकरणको शुध्दिको निमित्त योगको अभ्यास गरोस् । 6.12",

    "शरीर, शिर र गर्दन (घाँटी) लाई सीधा र स्थिर राखी, यताउति नहेरी केवल आफ्नो नासिकाको अग्रभागमा हेरी स्थिर भएर (बसोस्) । 6.13",

    "जसको अन्तःकरण शान्त छ, जो भयरहित छ र जस्ले ब्रह्मचारिव्रतको पालन गरेको छ, (यस्तो) सावधानताले  मनलाई रोक्तै म मा नै चित्त लगाएर, मत्परायण (म वाहेक अरुको संझना नगरी) र लीन भई योग गरोस् । 6.14",

    "यसप्रकार सधैं मन वशमा राखेर योगाभ्यासमा रहने कर्मयोगीलाई मैमा रहने र अन्त्यमा मेरै स्वरुपमा अर्थात्  निर्वाणपदमा लीन गराउने (मुक्ति दिने) शान्ति (त्यसलाई) प्राप्त हुन जान्छ ।  6.15",

    "हे अर्जुन ! यो योग (कर्मयोग) अत्ति धेरै खानेलाई अथवा कत्ति पनि नखानेलाई र ज्यादा नै सुत्ने वा  बिलकुल नसुत्ने (सदा जागरणमा रहने) यस्ता पुरुषलाई कदापि सिध्द हुँदैन ।  6.16",

    "जसको नियमित आहार र विहार  छ, कर्महरुको आचरण पनि नियमित रुपमा ठीक ठीक छ, जसले यथायोग्य सुत्ने र जागा रहने कर्म गर्दछ यसैलाई दु:ख नास (हरण) गर्ने अर्थात् सुख दिने योग सिध्द हुन्छ  6.17",

    "जब वशमा गरिएको चित्त आफ्नो आत्मामा स्थिर हुन जान्छ तब (आफै) सम्पूर्ण कामना(पदार्थ) हरुबाट निःस्पृह (हुन जान्छ), भएको (त्यो) पुरुष योगयुक्त कहिन्छ ।‌ 6.18",

    "जस्तै बतास नलाग्ने ठाउँमा राखिएको बत्तीको ज्योती, निश्चल (नडग्ने) हुन्छ, त्यही उपमा चित्तलाई रोकेर योगाभ्यास गर्ने योगीलाई पनि दिइन्छ।  6.19",

    "जुन अवस्थामा योगानुष्ठानले रोकिएको चित्त रमाउँछ, (तथा) जब आत्माले परमात्मालाई देखेर परमात्मामा नै सन्तुष्ट हुन्छ । 6.20",

    "जहाँ अत्यन्त सुखको उसलाई अनुभव हुन्छ, इन्द्रियहरुको गति नपुग्ने हुन्छ, खालि बुध्दिले मात्र थाहा पाईने/जानिने हुन्छ र जहाँ त्यो ध्यानयोगी एकपटक स्थिर भयो भने, परमार्थ तत्त्वबाट (फेरि पनि) कहिल्यै विचलित हुँदैन/डग्दैन । 6.21",

    "जुन स्थितिलाई प्राप्त हुनाले त्यसको अपेक्षा त्यो भन्दा पनि धेरै ठूलो अर्को लाभ त्यसलाई जँच्दैन् र जसमा स्थिर रहनाले पनि (त्यो) ठूलो भारी दुःखले पनि त्यस योगीलाई उसबाट विचलित गराउन/डगाउन सक्दैन । 6.22",

    "दुःखका संयोग देखि रहित जसको नाम योग छ, त्यसलाई जानी त्यो योगको आचरण मनलाई हडबडाउन नदिई निश्चयपूर्वक गर्नु पर्दछ । 6.23",

    "संकल्पबाट उत्पन्न हुने सम्पूर्ण कामना अर्थात् विषय-वासनालाई नि:शेष (बाँकी नराखी) त्याग गरेर (र) मनबाट नै सारा इन्द्रियका समूदायलाई सबै तर्फबाट नै वशमा गरेर ।  6.24",

    "धैर्ययुक्त बुध्दिले (संसारबाट) विस्तारै-विस्तारै मनलाई परमात्मामा स्थिर गरी,  (मन (बुध्दि) लाई परमात्मस्वरूप मा शान्त प्रकार  बाट स्थापना गरेर) अरु केहि पनि चिन्तन नगरोस् अर्थात् कुनै विचार आउनै नदेओस् । 6.25",

    "यो अस्थिर र चंचल मन जहाँ-जहाँ विचरण गर्दछ (घुम्दछ), त्यहाँ-त्यहाँबाट रोकेर त्यसलाई परमात्मामा नै भली भाँति लगाओस् । 6.26",

    "जसको सबै पाप नष्ट भएको छ, जसको रजोगुण शान्त भएको छ (तथा) जसको मन सर्वथा शान्त (निर्मल) भएको छ, (यस्तो) यो ब्रह्मरूप भएको योगीलाई निश्चित नै उत्तम (सात्त्विक) सुख (अक्षय सुख) प्राप्त हुन्छ । 6.27",

    "यस प्रकारले सधैं आफूले  आफैँलाई परमात्मामा लगाउँदै निष्पाप योगी सुखपूर्वक  ब्रह्म प्राप्तिको महान सुखको अनुभव गर्दछ  ।  6.28",

    "सबैलाई समान दृष्टिले देख्ने, सबै ठाउँमा आफ्नै स्वरूपलाई देख्ने र ध्यानयोगबाट युक्त अन्तःकरण भएको सांख्ययोगी आफ्नो स्वरूपलाई सम्पूर्ण प्राणीहरूमा रहेको देख्दछ र सम्पूर्ण प्राणीहरु आफ्नो स्वरूपमा रहेको देख्दछ । 6.29",

    "जो (भक्त) सबै प्राणीमा मलाई देख्दछ र ममा सबै प्राणीलाई देख्दछ, उसकोलागि म अदृश्य रहन्नँ र त्यो मदेखि अदृश्य रहदैन । 6.30",

    "म मा एकैभाव बाट स्थित भएको जो भक्तियोगी सम्पूर्ण प्राणिहरूमा स्थित मेरो भजन गर्दछ, त्यो कर्मयोगी जे जस्तो बर्ताव गरेर पनि ममा नै रहेको हुन्छ अर्थात् त्यो नित्य निरन्तर ममा नै स्थित हुन्छ । 6.31",

    "हे अर्जुन ! जो सम्पूर्ण प्राणीहरूको  सुख वा दुःखलाई आफ्नै समान देख्दछ, त्यो कर्मयोगी सर्व श्रेष्ठ योगी भनेर मानिन्छ ।  6.32",

    "अर्जुनले बिन्ती गरे - हे मधुसूदन ! हजुरले जो ध्यानयोग समभावले आज्ञा गर्नुभयो, बुध्दिको चंचलताको कारण यो योगको स्थिति म स्थिर देख्दिन ।  6.33",

    "किनभने हे कृष्ण ! यो मन (धेरै नै) चंचल, हठी, दृढ़ (जिद्दी) र बलवान छ, त्यसलाई वशमा गर्नु अर्थात् रोक्नु  म (आकाशमा रहेको) वायुका समान अर्थात् हावाको कुम्लो बाँध्नु जस्तै अत्यन्त मुश्किल ठान्दछु । 6.34",

    "श्रीभगवान् ले आज्ञा गर्नुभयो- हे महाबाहो ! यो मन धेरै चंचल छ (र) यसलाई वश गर्नु (रोक्नु) पनि धेरै मुस्किल छ - यो तिम्रो भनाई एकदमै ठीक छ ।  परन्तु हे कौन्तेय ! अभ्यास र वैराग्यले त्यो वश  गर्न सकिन्छ ।  6.35",

    "जसको मन पूर्ण वशमा छैन, उसकाद्वारा योग प्राप्त हुनु कठिन छ । परन्तु उपायपूर्वक प्रयत्न गर्ने वाला तथा मन वशमा राख्ने साधकलाई यो योग प्राप्त हुन सक्दछ, यस्तो मेरो मत छ (म ठान्दछु) ।  6.36",

    "अर्जुनले बिन्ती गरे - हे कृष्ण ! जसको साधनमा श्रध्दा छ, परन्तु जसको मेहनत शिथिल छ, (त्यो अन्त समयमा पनि) कर्मयोगबाट चुक्न गयो, (त) त्यो पुरुष योगसिध्दिलाई प्राप्त न गरेर कुन गतिमा पुग्न जान्छ? (त्यसको के गति हुन्छ?)  6.37",

    "हे महाबाहु कृष्ण ! संसारको आश्रयबाट रहित (र) ब्रह्मप्राप्ति को मार्गमा मोहित अर्थात् विचलित (यस प्रकार) दुवै तर्फबाट भ्रष्ट भएको साधक छिन्न-भिन्न बादल कै सरह नष्ट ता हुन हुँदैन ?  6.38",

    "हे कृष्ण ! मेरो यो  सन्देहलाई पूर्णरीतिले निर्मूल गर्नकोलागि हजुर नै योग्य हुनुहुन्छ; किनकि यो संशय(शंका) लाई निवारण गर्ने हजुर वाहेक अर्को कोही पुरुष यो लोकमा पाइँदैन ।  6.39",

    "श्रीभगवान् आज्ञा गर्नुहुन्छ - हे पार्थ ! त्यसको न त यो लोकमा, न त परलोकमा नै विनाश हुन्छ; किनभने हे बाबु ! कल्याणकारी काम (शुभ कर्म) गर्ने कुनै पनि पुरुषलाई दुर्गति प्राप्त हुनै सक्दैन । 6.40",

    "(त्यो) योगभ्रष्ट पुरुष पुण्यात्माहरुको लोकलाई प्राप्त गरेर (त्यहाँ) धेरै वर्षसम्म वास वसेर (फेरि यहाँ) पवित्र (ममता रहित) श्रीमानहरुका (लक्ष्मीवान् र शुध्दाचरण गर्नेका) घरमा जन्म लिन जान्छ ।  6.41",

    "अथवा (वैराग्यवान् योगभ्रष्ट) ज्ञानवान् कर्मयोगीहरुको कुलमा नै जन्म लिन जान्छ ‌। यस किसिमको जन्म, यस संसारमा पाउनु निःसंदेह अति नै दुर्लभ छ ‌।  6.42",

    "हे कुरुनन्दन ! त्यस ठाउँमा उस्लाई त्यस पूर्व जन्मको अभ्यासले बुध्दिसंस्कारलाई प्राप्त गर्दछ र त्यसको प्रभावले उसभन्दा बढी योगसिध्दि प्राप्तिका निमित्त फेरि प्रयत्न (मेहनत) पनि गर्दछ ।  6.43",

    "त्यो (श्रीमानहरुको घरमा जन्म लिएको योगभ्रष्ट पुरुष) भोगहरुमा लिप्त भएतापनि त्यो पहिलेको मनुष्य जन्ममा गरिएको अभ्यास (साधन) को कारण नै पूर्ण सिध्द तर्फ नै खिंचिन्छ; किनभने योगको जिज्ञासु पनि वेदोक्त फलभन्दा अधिक फल पाई मुक्त हुन्छ ।  6.44",

    "यसरी कोशिससाथ उद्योग गर्दागर्दै पापबाट शुध्दभई, कर्मयोगी पुरुष धेरै जन्मपछि सिध्दि पाएर, आखिरमा उत्तम गति अर्थात् मोक्ष पाउँछ ।  6.45",

    "(सकाम भावभएको) तपस्विभन्दा पनि योगी नै श्रेष्ठ हो, ज्ञानिभन्दा पनि योगी नै श्रेष्ठ हो र कर्मकाण्डीहरु भन्दा पनि योगी नै श्रेष्ठ मानिएको छ । यस्तो मेरो मत छ । अतः हे अर्जुन ! तिमी योगी अर्थात् कर्मयोगी नै बन (होऊ) । 6.46",

    "सम्पूर्ण योगीहरुमा पनि जो श्रध्दावान् भक्त म मा नै तल्लीन भएको मनबाट मेरो भजन गर्दछ, त्यो मेरो मतमा सर्वश्रेष्ठ योगी हो । 6.47",

    "यस प्रकार ॐ तत् सत् - यी भगवन्नामहरुको उच्चारणपूर्वक ब्रह्मविद्या र योगशास्त्रमय श्रीमद्भगवद्गीताको उपनिषद् रूप श्रीकृष्ण र अर्जुनको संवाद स्वरुप ‘आत्मसंयमयोग’ नाम भएको छैठौं अध्याय पूर्ण भयो ।"   
  ],
  "adhyaya-7" : [
    "श्रीकृष्ण आज्ञा गर्नुहुन्छ - हे पार्थ ! म मा चित्त लगाएर, मेरै आश्रय लिई,  योगलाई अभ्यास गर्दै तिमी मेरो जुन समग्र रूपलाइ संशय रहित भएर जस प्रकारबाट जानौला, त्यो  (त्यसै प्रकारबाट) सुन। 7.1",

    "तिम्रोलागि म यो विज्ञान सहितको पूर्णज्ञान सम्पूर्ण रुपबाट बताउछु, जसलाई जाने पछि फेरि यो विषयमा जान्ने योग्य अरु केहि पनि शेष (बाँकि) रहदैन। 7.2",

    "हजारौं पुरुषहरुमा कुनै एकाधले सिद्धि (कल्याण) प्राप्तीको लागि  यत्न गर्दछ र ती यत्न गर्ने अनेक सिद्ध पुरुषहरु (मुक्त पुरुषहरु) मध्येमा कोही एकाधले मात्र मलाई यथार्थ (वास्तविक) रूपबाट जान्दछ। 7.3",

    "पृथ्वी, जल, तेज, वायु, आकाश - (यी पाँच महाभूत) र मन, बुद्धि तथा अहंकार - यी तीन समेत जम्मा आठ प्रकारका मेरा फरक प्रकृति छन्।  7.4",

    "हे महाबाहो ! यी ता जड प्रकृति हुन्, यस देखि भिन्न अर्को जगतलाई धारणगर्ने, जीवरूपिणी, परा अर्थात् उच्च श्रेणीको, मेरो अर्को प्रकृति पनि छ, त्यस्लाई जान। 7.5",

    "सम्पूर्ण प्राणीहरूको उत्पन्न अपरा र परा - यिनै दुई प्रकृतिहरुको संयोग कै कारणबाट हुन्छ - यस्तो तिमी सम्झ । म सारा जगतको प्रभाव अर्थात्  मूल उत्पति स्थान र प्रलय अर्थात् अन्त्य पनि मै हूँ। 7.6",

    "यसकारण हे धनञ्जय ! मेरो शिवाय यो जगतमा दोस्रो कोहि किंचित्मात्र पनि (कारण तथा कार्य) भिन्न केहि छैन । जसरि धागोमा सारा मणि उनिन्छन्, त्यस्तै नै यो सम्पूर्ण जगत म एउटामा नै गांथिएको अर्थात् उनिएको छ। 7.7",

    "हे कौन्तेय ! जलमा रस मैं हूँ, चन्द्रमा र सूर्यको प्रभा (प्रकाश) पनि मैं हूँ, सम्पूर्ण वेदहरुमा प्रणव (ओंकार), आकाशमा शब्द र सबै पुरुषको पुरुषत्व (पौरख) पनि मैं हूँ।  7.8",

    "पृथ्वीमा पवित्र गन्ध (सुगन्ध) मैं हूँ र अग्निमा तेज मैं हूँ तथा सम्पूर्ण प्राणीमा जीवनशक्ति र तपस्विहरुमा तप पनि मैं हूँ।‌ 7.9",

    "हे पार्थ ! सम्पूर्ण प्राणीको सनातन  बीज मलाई संझ, बुद्धिमानहरुको बुद्धि र तेजस्वीहरुको तेज पनि मलाई नै जान।  7.10",

    "हे भरतश्रेष्ठ अर्जुन ! बलवानहरुको कामवासना र राग अर्थात् यी दुइलाई छोडेर, बलवानहरुको बल पनि मैं हूँ र प्राणीहरुमा धर्मको विरुद्ध नजाने कामदेव पनि मैं हूँ। 7.11",

    "र अरु के भनौ - जति पनि सात्त्विक भाव छन् र जति पनि राजस तथा तामस भाव छन्, ती सबै म बाट नै भएका हुन् - यस्तो तिनलाई समझ।  परन्तु ती ममा छन् र म तिनमा छैन।  7.12",

    "सत्व, रज, तम, यी त्रिगुणी भावले मोहित भएर, सारा जगत (प्राणिमात्र) यी गुणहरु भन्दा पर  रहेको (निर्गुण) म अव्यय (अविनाशी) लाई  जान्न सक्दैन   7.13",

    "किनकि मेरो यो अलौकिक गुणमयी दैवी माया बडो दुरत्यय (अपार) छ अर्थात् यसबाट पार पाउन बडो मुस्किल छ। जो केवल मेरो नै शरणमा हुन्छन्, ती पुरुष यी मायाबाट पार गरेर (तरेर) जान्छन्। 7.14",

    "मायाले जसको ज्ञान नाश गरि दिएको छ, यस्ता मुढ र दुष्कर्मी नराधमहरुले आसुरी (राक्षसी) स्वभावलाई अवलम्बन गरेर मेरो शरणमा आउँदैनन्। 7.15",

    "हे भरतश्रेष्ठ अर्जुन ! (१) रोगले पीडित (आर्त), (२) ज्ञानप्राप्तिको इच्छा गर्ने (जिज्ञासु), (३) द्रव्य आदिको काम्यवासनालाई मनमा राख्ने (अर्थार्थी) र (४) परमेश्वरको ज्ञान पाएर पनि फेरि केही प्राप्त गर्नु पर्ने छैन तापनि निष्काम बुद्धिले भक्ति गर्ने ज्ञानी (प्रेमी) - यी चार प्रकारका पुरुषले मेरो भजन गर्दछन् अर्थात् मेरो शरणमा हुन्छन्। 7.16",

    "यी चार भक्तहरु मध्येमा नित्य निरन्तर म मा लागेको, अनन्यभावले मेरो भक्तिगर्ने ज्ञानी अर्थात् प्रेमीभक्त श्रेष्ठ छ किनभने ज्ञानी भक्तलाई म अत्यन्त प्यारो छु र ऊ पनि मलाई अत्यन्त प्यारो छ। 7.17",

    "पहिले भनिएका सबका सब (चारै) नै भक्त धेरै उदार (असल, श्रेष्ठ) भावका हुन् । तर यिनमा ज्ञानी (प्रेमी) चाहिँ मेरो नै स्वरूप (आत्मा) हो - यस्तो मेरो मत छ ।  किनभने ऊ म बाट अभिन्न र ऊ बाट श्रेष्ठ दोस्रो कुनै गतिस्वरुप छैन, (यस्तो) म मा नै  दृढ़ स्थित रहन्छ।  7.18",

    "अनेकौं जन्महरुका अन्तिम जन्ममा अर्थात् मनुष्य जन्ममा ज्ञानीले सबै चिज वासुदेव (परमात्मा) नै हो भनी मलाई भज्दछ  - यस प्रकार (जुन) ज्ञानवान मेरो शरणमा हुन्छ, त्यो महात्मा अत्यन्तै दुर्लभ छ।  7.19",

    "आफ्नो-आफ्नो प्रकृतिको नियमानुसार अर्थात स्वभावबाट नियन्त्रित भएका पुरुषहरु भिन्न-भिन्न स्वर्गादि फलका कामवासनाले पागल जस्ता भएर, अलग-अलग देवताको उपासनाका नियमहरूको पालन गर्दै, अरु अरु देवताको सेवा (पूजा) गर्दछन्। 7.20",

    "जो-जो भक्त जुन-जुन देवताको स्वरुपको श्रद्धापूर्वक पूजा गर्न चाहन्छन्, ती-ती देवतामा नै म त्यहीं श्रद्धालाई स्थिर गरि दिन्छु।  7.21",

    "त्यो (म बाट स्थिर गरिएको) श्रद्धाबाट युक्त त्यो पुरुष ति देवताको (सकाम भावपूर्वक) उपासना गर्दछ र उसको त्यो कामना नि:सन्देह प्राप्त पनि गर्दछ; तर त्यो कामनाको पूर्ति म बाट नै निर्माण गरिएको हुन्छ। 7.22",

    "परन्तु ती अल्पबुद्धि भएका मनुष्यहरुलाई ती देवताहरुको आराधनाको फल नाशवान् नै मिल्दछ । देवताहरुलाई पूजा गर्नेहरुले देवताहरुलाई (स्वर्गलोकलाई) प्राप्त गर्दछन् र मेरो भक्तले मलाई (मोक्षलाई) नै प्राप्त गर्दछन्। 7.23",

    "बुद्धिहीन अर्थात् मूर्ख मनुष्यहरु मेरो अविनाशी, सर्वश्रेष्ठ र परमभावलाई  नजानेर अव्यक्त (मन-इन्द्रियहरु भन्दा पर) म (सच्चिदानन्द परमात्मा) लाई नजानेर मनुष्य कै सरह शरीर धारण गर्ने जस्तै मान्दछन् अर्थात् अव्यक्तलाई व्यक्त भएको ठान्दछन्।  7.24",

    "यो जो मूढ़ (मूर्ख) मनुष्य समुदायले मलाई अज (नजन्मिने) र अविनाशी (अव्यय) अर्थात् कहिल्यै ननासिने हो भनी कोहि पनि जान्दैनन्, (मान्दैनन्), यी सबैका (अगाडि) योगमायाबाट राम्रै किसिमबाट आच्छादित भएको (ढाकिएको) म सबमा प्रकट हुन्न।  7.25",

    "हे अर्जुन ! जो प्राणी भूतकालमा भइसकेका छन् तथा जो वर्तमानमा छन् र जो भविष्यमा हुनेछन्, यी सबै प्राणिहरुलाई म जान्दछु, परन्तु मलाई (मेरो भक्तको शिवाय अरु) कोहि पनि जान्दैनन्। 7.26",

    "किनभने - हे भरतवंशमा उत्पन्न, शत्रुलाई ताप दिलाउने परंतप ! इच्छा (राग) र द्वेषबाट उत्पन्न हुनेवाला, द्वन्द्व-मोहबाट मोहित सम्पूर्ण प्राणीहरु, यस संसारमा (सृष्टिमा) अनादि कालबाट भ्रम (अज्ञान) लाई अर्थात् जन्म-मरणलाई प्राप्त गरि रहेकाछन्। 7.27",

    "परन्तु जुन पुण्यकर्मले मनुष्यहरुको पाप नष्ट भएको छ, तिनी सुख-दु:ख आदि द्वन्द्वका मोहबाट छुटेर दृढ़ भई मेरो भक्ति गर्दछन्। 7.28",

    "वृद्धावस्था (जरामरण) र मृत्युबाट मुक्ति (पुनर्जन्मको चक्करबाट छुट्न) पाउनको लागि जो मेरो आश्रय लिएर प्रयत्न गर्दछन् , तीनले त्यो ब्रह्मलाई, सम्पूर्ण अध्यात्मलाई र सम्पूर्ण कर्मलाई पनि जान्दछन्।  7.29",

    "जो मनुष्य 'अधिभूत', 'अधिदैवत' र 'अधियज्ञ' ले सहित मलाई नै जान्दछन्, ती मुक्तचित्त योगीले  मरणकाल  (अन्तकाल) मा पनि मलाई नै जान्दछन् अर्थात् प्राप्त गर्दछन्। 7.30",

    "यस प्रकार ॐ तत् सत् - यी भगवन्नामहरुको उच्चारणपूर्वक ब्रह्मविद्या र योगशास्त्रमय श्रीमद्भगवद्गीताको उपनिषद् रूप श्रीकृष्ण र अर्जुनको संवाद स्वरुप ‘ज्ञानविज्ञानयोग’ नाम भएको सातौँ अध्याय पूर्ण भयो ।"   
  ],
  "adhyaya-8" : [
    "अर्जुनले भने-हे पुरुषोत्तम ! त्यो ब्रह्म के हो? अध्यात्म भनेको के हो ? कर्म भनेको के हो? अधिभूत नामबाट के भन्न खोजिएको छ र कसलाई अधिदैव भनिन्छ? 8.1",

    "यहाँ अधियज्ञ कुन हो? र त्यो यस शरीरमा कसरी छ? हे मधुसूदन ! वशमा परेका अन्तस्करण भएका पुरुषहरूबाट अन्त समयमा हजुर कसरी जानिनुहुन्छ? 8.2",

    "श्रीभगवानले भन्नुहुन्छ- परम अक्षर ब्रह्म हो, आफ्नो स्वरुप अर्थात् जीवात्मा अध्यात्म नामले चिनिन्छ भने भूतहरूका भावलाई उत्पन्न गर्नेवाला जुन त्याग छ, त्यो कर्म नामले चिनिएको छ । 8.3",

    "हे देहधारिहरुमा श्रेष्ठ अर्जुन! क्षर भाव अर्थात् नाशवान् पदार्थ अधिभूत हुन्, हिरण्यमय पुरुष अधिदैव हो र यो शरीरमा म वासुदेव नै अन्तर्यामीरूपले अधियज्ञ हूँ। 8.4",

    "जो पुरुष अन्तकालमा पनि मलाई नै स्मरण गर्दै शरीर त्याग गर्दछ, उसले मेरो साक्षात स्वरूपलाई प्राप्त गर्दछ भन्ने कुरामा कुनै पनि संशय छैन । 8.5",

    "हे कुन्तीपुत्र अर्जुन! यो मनुष्य अन्तकालमा जे जस्तो पनि भावलाई स्मरण गर्दै शरीरलाई त्याग गर्दछ, त्यो त्यसै अन्तकालको भावबाट सदा भावित रहेको हुन्छ र त्यहि नै उसलाई प्राप्त हुन्छ अर्थात् त्यहि - त्यहि योनिमा नै जन्म लिन्छ 8.6",

    "यसकारण (तिमी) सबै समयमा मेरो स्मरण गर (र) युद्ध पनि गर। ममा मन र बुद्धि अर्पित गर्नेवाला (तिमी) निःसन्देह मलाई नै प्राप्त गर्ने छौ । 8.7",

    "हे पृथानन्दन! अभ्यासयोगबाट युक्त र अन्य केहि चिन्तन नगर्नेवाला चित्तबाट परम दिव्य पुरुष अर्थात् परमेश्वरलाई चिन्तन गरेर शरीर छोड़नेवाला मनुष्यले उसैलाई प्राप्त गर्दछ भन्ने नियम नै छ । 8.8",

    "जुन सर्वज्ञ, अनादि, सबैमा शासन गर्नेवाला, सूक्ष्मबाट अत्यन्त सूक्ष्म, सबलाई धारण-पोषणगर्नेवाला, अज्ञानबाट अत्यन्त टाढा, सूर्य कै समान प्रकाशस्वरूप अर्थात् ज्ञानस्वरूप यस्तो अचिन्त्य स्वरूप, शुद्ध सच्चिदानन्दघन परमेश्वरको स्मरण गर्दछ । 8.9",

    "यस्तो भक्तियुक्त मनुष्यले अन्त्य समयमा निश्चल मनबाट र योगबलद्वारा भृकुटीका बीचमा प्राणलाई सुन्दर तरिकाले स्थापित गरेर शरीर छोड्यो भने त्यसले परम दिव्य पुरुष परमात्मालाई नै प्राप्त गर्दछ । 8.10",

    "वेदलाई जान्ने विद्वानहरुले जुन सच्चिदानन्दघनरूपी परमपदलाई अविनाशी भन्दछन्, आसक्तिरहित यत्नशील संन्यासी महात्माजन जसमा प्रवेश गर्दछन् र जुन परमपदलाई चाहने ब्रह्मचारीहरुले ब्रह्मचर्यको आचरण गर्दछन्, त्यस परमपदलाई म तिम्रा निम्ति भन्दछु । 8.11",

    "सबै इन्द्रियहरुको ढोकाहरुलाई रोकेर, मनलाई ह्रदयप्रदेशमा स्थित गरेर, त्यस जितिएको मनद्वारा प्राणलाई मस्तकमा स्थित गरि, परमात्मा सम्बन्धी योगधारणामा स्थित भएर, 8.12",

    "जुन पुरुषले 'ॐ' एक अक्षररुपी यस ब्रह्मलाई उच्चारण गर्दै र त्यसको अर्थस्वरूप म निर्गुण ब्रह्मको चिन्तन गर्दै, शरीरलाई त्याग गरिदिन्छ, त्यो पुरुषले परमगतिलाई प्राप्त गर्दछ। 8.13",

    "हे अर्जुन! अनन्य चित्त भएर जुन पुरुषले मेरो नित्य निरन्तर स्मरण गर्दछ, त्यो नित्य निरन्तर ममा लागेको योगीको लागि म सुलभ छु अर्थात् उसकोलागि सजिलै प्राप्त हुन सक्छु । 8.14",

    "महात्मा पुरुषले मलाई प्राप्त गरेर दुःखालय अर्थात् दुःखहरुको घर एवं क्षणभङ्गुर पुनर्जन्मलाई प्राप्त गर्दैनन्; किनकि उस्ले परमसिद्धिलाई प्राप्त गरेको हुन्छ अर्थात् उसले परम प्रेमलाई प्राप्त गरि सकेको हुन्छ । 8.15",

    "हे अर्जुन! ब्रह्मलोक पर्यन्त सबै लोक पुनरावृत्तिवाला लोक हुन् अर्थात् त्यहाँ गएपछि पुनः फर्केर संसारमा आउनु पर्दछ । परन्तु हे कुन्तीपुत्र! मलाई प्राप्त गरेपछी पुनर्जन्म हुदैन, किनकि म कालातीत छु भने यी सबै ब्रह्मादिका लोक कालद्वारा सीमित हुनाले अनित्य छन्। 8.16",

    "जुन पुरुषले ब्रह्माको एक हजार चतुर्युगीसम्मको अवधिवाला एक दिनलाई र एक हजार चतुर्युगीसम्मको अवधिवाला एक रातलाई तत्वसाथ जान्दछन्, ती योगीजन कालको तत्वलाई जान्नेवाला पुरुष हुन्। 8.17",

    "सम्पूर्ण चराचर भूतगण ब्रह्माको दिनको प्रवेशकालमा अव्यक्तद्वारा अर्थात् ब्रह्माको सूक्ष्म शरीरबाट उत्पन्न हुन्छन् र ब्रह्माको रातको प्रवेशकालमा त्यस अव्यक्त नामक ब्रह्माको सूक्ष्म शरीरमा नै सम्पूर्ण शरीर लीन हुन्छन्। 8.18",

    "हे पार्थ! त्यही यो भूतसमुदाय उत्पन्न भइ-भइकन प्रकृतिको वशमा भएको ब्रह्माको दिनको प्रवेशकालमा पुन: उत्पन्न हुन्छन् भने ब्रह्माको रातको प्रवेशकालमा पुन: लीन हुन्छन्। 8.19",

    "परन्तु त्यो अव्यक्त भन्दा पनि अति पर अर्को अर्थात् विलक्षण जुन सनातन अव्यक्त श्रेष्ठ भाव रूप छ, त्यो परम दिव्य पुरुष सम्पूर्ण प्राणिहरु नष्ट भएपछि पनि नष्ट हुँदैन । 8.20",

    "त्यसैलाई अव्यक्त 'अक्षर' यस नामले भनिएको छ तथा त्यसलाई नै परमगति भनिन्छ, जसलाई प्राप्त भएपछी मनुष्य फेरि फर्किएर (संसारमा) आउदैनन्, त्यहि मेरो परमधाम हो । 8.21",

    "हे पृथानन्दन अर्जुन! संपूर्ण प्राणी जसको अन्तर्गत छ र जसबाट यो संपूर्ण संसार व्याप्त (परिपूर्ण) छ, त्यस परम पुरुष परमात्मा त अनन्य भक्तिबाट नै प्राप्त गर्न योग्य छ । 8.22",

    "परन्तु, हे भरतवंशिहरुमा श्रेष्ठ अर्जुन! जुन समयमा अर्थात् मार्गमा शरीर छोड़ेर गएका योगीजन अनावृत्तिलाई प्राप्त गरेका हुन्छन अर्थात् पछाडि फर्किएर आउदैनन् र जुन मार्गमा गएका आवृत्तिलाई प्राप्त गर्दछन् अर्थात् पछाडि फर्किएर आउदछन्, ती काललाई अर्थात् दुवै मार्गहरुलाई म बताउँछु । 8.23",

    "जुन मार्गमा प्रकाश स्वरूप अग्निको अधिपति देवता, दिनको अधिपति देवता, शुक्ल पक्षको अधिपति देवता र उत्तरायणका छ महिनाका अधिपति देवता छन्, त्यो मार्गबाट शरीर छोड़ेर गएका ब्रह्मवेत्ता योगीजन उपर्युक्त देवताहरूद्वारा क्रमश: लगिएर (पहिला ब्रह्मलोकलाई प्राप्त भएर पछाडि ब्रह्मका साथ) ब्रह्मलाई प्राप्त गर्दछन् । 8.24",

    "जुन मार्गमा धूमको अधिपति देवता, रात्रिको अधिपति देवता, कृष्णपक्षको अधिपति देवता, र दक्षिणायनका छ महिनाका अधिपति देवता छन्, शरीर छोड़ेर त्यस मार्गबाट गएका सकाम कर्म गर्ने योगी चन्द्रमाको ज्योतिलाई प्राप्त गरेर स्वर्गमा आफ्ना शुभकर्महरुका फल भोगेर फर्किएर आउदछन् अर्थात जन्म- मरणलाई प्राप्त गर्दछन् । 8.25",

    "किनकी शुक्ल र कृष्ण यी दुवै मार्गहरु सनातन कालबाट जगत (प्राणीमात्र) लाई साथ सम्बन्ध राख्ने वाला मानीएका छन् । यसमा एउटा मार्गमा जानेवाला फर्किएर आउनु पर्दैन, त्यस परमगतिलाई प्राप्त हुन्छ र अर्को मार्गबाट जानेवाला पुनः फर्किएर आउँछ अर्थात् जन्ममृत्युलाई प्राप्त हुन्छ । 8.26",

    "हे पार्थ! यसप्रकार यो दुवै मार्गहरुलाई तत्वसाथ जान्नेवाला कुनै पनि योगी मोहित हुदैन ।अतः हे अर्जुन! तिमी सबै समयमा समबुध्दिरूपी योगद्वारा युक्त होऊ, अर्थात् निरन्तर मेरो प्राप्तिका लागि साधना गर्नेवाला बन । 8.27",

    "योगी पुरुषले यो रहस्यलाई (यो अध्यायमा वर्णित विषयलाई) तत्वपूर्वक जानेर वेदहरुलाई पढनाले, यज्ञ, तप तथा दान गर्नाले जे-जे पुण्यफल वताईएको छ, यी सबै पुण्य फलहरूलाई नि:सन्देह अतिक्रमण गर्दछ र सनातन परमपदलाई प्राप्त गर्दछ । 8.28",

    "यस प्रकार ॐ तत् सत् - यी भगवन्नामहरुको उच्चारणपूर्वक ब्रह्मविद्या र योगशास्त्रमय श्रीमद्भगवद्गीताको उपनिषद् रूप श्रीकृष्ण र अर्जुनको संवाद स्वरुप ‘अक्षरब्रह्मयोग’ नाम भएको आठौं अध्याय पूर्ण भयो ।"
  ],
  "adhyaya-9" : [
    "श्रीभगवान् आज्ञा गर्नुहुन्छ -- यो अत्यन्त गोपनीय विज्ञान सहित ज्ञान दोष दृष्टि रहितका निमित्त तिमीलाई  म फेरि सविस्तार बताउँछु, जसलाई जानेर (तिमी) पापबाट अर्थात् जन्म-मरण रूपी संसारबाट मुक्त हुनेछौ । 9.1",

    "यो (विज्ञान सहितको ज्ञान अर्थात् सम्पूर्ण रूप) सम्पूर्ण विद्याहरुको राजा, सम्पूर्ण गोपनीयको राजा, पवित्र, अतिश्रेष्ठ छ र यो प्रत्यक्ष फल दिने, धर्मयुक्त, अविनाशी छ र आचरण गर्नमा यो सुखकारक छ अर्थात् यसलाई प्राप्त गर्न धेरै नै सुगम छ । 9.2",

    "हे परन्तप! यस धर्मको महिमा प्रति श्रध्दा न राख्ने पुरुषहरुले मलाई प्राप्त न गरेर मृत्युरूपी संसारको चक्र मा घुम्दै रहन्छन् ।  अर्थात् मोक्ष प्राप्त नगरि बारम्बार जन्मदै मर्दै गरि रहन्छन् । 9.3",

    "यो समग्र संसार मेरो निराकार स्वरूपले व्याप्त गरेको छु,  सम्पूर्ण प्राणी म मा स्थित छन् तर  म तिनिहरुमा स्थित छैन । 9.4",

    "सवै प्राणीहरु म मा स्थित छैनन् - मेरो यो ईश्वर-सम्बन्धी योग सामर्थ्य लाई हेर ! सम्पूर्ण प्राणिहरुलाई उत्पन्न गर्ने र प्राणिहरुलाई धारण, पालन, पोषण गर्ने मेरो स्वरूप तिनै प्राणिहरुमा स्थित छैन् । 9.5",

    "जस्तै सर्वत्र घुम्ने वाला यो महान् वायु सर्वदा  आकाशमा नै स्थित रहेको छ, त्यस्तै सम्पूर्ण भूत-प्राणी म मा नै  स्थित छन्  - यस्तो तिमीले जान । 9.6",

    "हे कुन्तीनन्दन ! कल्पको अन्त्यमा (प्रलयकालमा) सम्पूर्ण भूत-प्राणी मेरै प्रकृतिमा आएर लीन हुन्छन् वा मिल्दछन् र कल्पको आरम्भमा  (महासर्गको समयमा) म नै फेरि तिनलाई उत्पन्न गर्दछु । 9.7",

    "प्रकृतिको वशमा भएका कारण परतन्त्र भएका यी सम्पूर्ण प्राणी समुदायलाई, कल्पको शुरूआतमा म आफ्नो प्रकृतिलाई वश मा राखेर तिनिलाई बारम्बार निर्माण गर्दछु ।  9.8",

    "हे धनञ्जय ! ती (सृष्टि-रचनाको शुरुवात) कर्ममा आसक्ति-रहित र उदासीन जस्तो  भएको मलाई, ती कर्महरु मलाई बन्धनकारी हुदैनन् । 9.9",

    "प्रकृतिले मेरो अध्यक्षतामा सम्पूर्ण चराचर जगत उत्पन्न गराउछिन् ।  हे कुन्तीनन्दन ! यसैकारणले यो जगतमा विभिन्न प्रकारबाट परिवर्तन (बन्नु र बिग्रनु) बराबर नै भईरहन्छ । 9.10",

    "मूर्ख मनुष्यले मेरो सम्पूर्ण प्राणिहरुको महान् ईश्वररूप र परमभावलाई न जानेर मलाई मनुष्यको शरीर धारण गर्ने मानेर अर्थात् साधारण मनुष्य मानेर मलाई अपहेलन गर्दछन् । 9.11",

    "(जसले) आसुरी, राक्षसी र मोहिनी प्रकृतिको नै आश्रय लिन्छन्, यस्ता अविवेकी मनुष्यहरुको सबै आशाहरु व्यर्थ हुन्छन्, सबै शुभ-कर्म व्यर्थ हुन्छन् (र) सबै ज्ञान व्यर्थ हुन्छन् अर्थात् यिनिहरुको आशा व्यर्थ छ, कर्म फजुल छ र ज्ञान निरर्थक छ ‌।  9.12",

    "परन्तु हे पृथानन्दन ! दैवी प्रकृतिमा आश्रित अनन्य (एकत्वभाव) मन भएका महात्मा मनुष्य, मलाई सम्पूर्ण प्राणिहरूको आदिस्थान र  अविनाशी सम्झेर मेरो भजन गर्दछन् । 9.13",

    "नित्य- निरन्तर म मा लागेका कर्म योगीहरु दृढ़व्रत लिई योगयुक्त भएर सधैं प्रेम पूर्वक कीर्तन र नमस्कार गर्दै भक्तिपूर्वक मेरो उपासना गर्दछन् ।  9.14",

    "दोस्रो साधक ज्ञानयज्ञ सहित एकत्व भाव (अभेद-भाव) ले मेरो पूजा गरेर मेरो उपासना गर्दछन् र अरु पनि कति  साधक (आफुलाई) पृथक् मानेर सर्वतोमुख भएको मेरो विराट स्वरुपको अर्थात् संसारलाई नै मेरो विराट रुप मानेर सेवाको  भाव लिएर मेरो नै अनेक प्रकारले उपासना गर्दछन्। 9.15",

    "क्रतु (श्रौतयज्ञ) म हूँ, यज्ञ (स्मार्तयज्ञ) म हूँ, स्वधा अर्थात् श्राध्दमा पितृहरूलाई अर्पण गरिने अन्न म हूँ, औषधी अर्थात् वनस्पति बाट यज्ञको निमित्त उत्पन्न भएको अन्न म हूँ, मन्त्र म हूँ, घिउ म हूँ, अग्नि र सोमा चढाइएको आहूति पनि म हूँ । 9.16",

    "जो केहि पवित्र र जान्न योग्य छ, ॐकार, ऋग्वेद, सामवेद र यजुर्वेद पनि  म नै हूँ । यस सम्पूर्ण जगतको पिता, धाता (आधार) माता, पितामह (बाज्ये) पनि मै हूँ । 9.17",

    "सबैको गति, सबैको पोषक, प्रभु, साक्षी, वासस्थान, शरण, संगी, उत्पत्ति, प्रलय, स्थिति, निधान (भण्डार), कहिल्यै ननासिने, बीजस्वरुप पनि   मैं हूँ । 9.18",

    "हे अर्जुन ! (संसार को हित को लागि) म (नै)सूर्यको रूपबाट गर्मी दिन्छु, म (नै) पानीलाई ग्रहण गर्दछु र (फेरि त्यस पानीलाई) (म नै) वर्षाको  रूपमा बर्षाउछु । (र अरु के भनौ) अमृत र मृत्यु तथा सत् (ननासिने) र असत् (नासिने) पनि म नै हूँ । 9.19",

    "तीनै वेदहरुमा भनिएका सकाम अनुष्ठानलाई गर्ने वाला (र) सोमरसलाई  पिउने वाला (जुन) पापले रहित पुरुष, यज्ञहरुद्वारा मेरो पूजा गरेर स्वर्ग-प्राप्तिको निमित्त प्रार्थना गर्दछन्, तिनिहरुले (पुण्य को फलस्वरूप) पवित्र इन्द्रलोक लाई प्राप्त गर्दछन् र (त्यहाँ) स्वर्गमा पुगेर देवताहरुको अनेक दिव्य भोगहरु भोग गर्दछन् । 9.20",

    "तिनिहरू त्यो विशाल स्वर्गलोकलाई (भोगहरुलाई ) भोगेर पुण्य क्षीण भएपछि फेरि जन्म लिनलाई मृत्युलोकमा आउदछन् । यसप्रकार तीनै वेदहरुमा भनिएका सकाम धर्मको आश्रय लिएर भोगहरुको कामना गर्ने वाला पुरुषलाई स्वर्गमा जानु र यहाँ आउनु बराबर परिरहन्छ। 9.21",

    "जुन अनन्य भक्त मेरो चिन्तन गर्दै (मेरो) निष्काम भावले मेरो उपासना गर्दछन्, मेरो नित्य निरन्तर चिन्तन गर्ने ती भक्तहरुको योगक्षेम (सांसारिक नित्य निर्वाहको निमित्त, अप्राप्तलाई प्राप्ति र प्राप्त भएकाको रक्षा) पनि म नै गराई दिन्छु । 9.22",

    "हे कुन्तीनन्दन! जुन सुकै पनि भक्त (मनुष्य) श्रध्दापूर्वक अरु देवताहरुको पूजा गर्दछन्, तिनिले पनि मेरो नै पूजा गरेका हुन, अविधिपूर्वक अर्थात् देवताहरुलाई म बाट अलग मानेर गरिएको भएता पनि एक किसिमले मेरै पूजन गर्दछन् । 9.23",

    "किनकि सम्पूर्ण यज्ञहरुको भोक्ता र स्वामी पनि म नै हूँ, तर तिनिहरुले मलाई तत्त्वपूर्वक  जान्दैनन्, यसै उसले नै उनिहरुको पतन हुन्छ । 9.24",

    "(सकाम भावबाट) देवताहरुको पूजा गर्नेले (शरिर छोड़े पछि) देवताहरुको साथ (स्वर्गलोक), पितृहरुको पूजा  गर्नेले पितृको साथ (पितृलोक), भूत-प्रेतहरुको पूजा गर्नेले भूत-प्रेतहरुको साथ (भूतलोक)र  मेरो पूजा गर्नेले मेरै साथ (वैकुण्ठ) मा आउँदछन् । 9.25",

    "जुन भक्तले पत्र, पुष्प, फल, जल आदि (यथासक्य एवं अनायास प्राप्त वस्तु) लाई प्रेमपूर्वक म मा अर्पण गर्दछ, त्यो (म मा) तल्लीन भएको अन्तःकरण वाला भक्तबाट प्रेमपूर्वक दिएको  उपहार (भेंट) लाई म ग्रहण गर्दछु अर्थात् स्विकार गर्दछु । 9.26",

    "हे कुन्तीपुत्र ! तिमी जो भन्दछौ,  जो गर्दछौ, जो भोजनको रुपमा ग्रहण गर्दछौ, जो यज्ञ गर्दछौ, जो दान दिन्छौ र जो तप गर्दछौ, ती  सबै मलाई नै अर्पण गर्ने गर । 9.27",

    "यस प्रकारले सन्न्यासयोग युक्त भएको चित्तले मलाई अर्पण गरेपछि कर्म बन्धनबाट एवं शुभ र अशुभ सम्पूर्ण कर्महरुको फलहरुबाट तिमी मुक्त हुनेछौ । यस्तै आफु सहित सबै वस्तु मलाई अर्पण गर्ने तिमी कर्म बन्धनबाट मुक्त भई मलाई प्राप्त गर्ने छौ । 9.28",

    "म सम्पूर्ण प्राणिहरुको लागि समान छु । (ति प्राणिहरुको लागि) न त कोहि मेरो अप्रिय छ (र) न कोहि प्रिय छ । तर जस्ले प्रेमपूर्वक (भक्तिभावले) मेरो भजन गर्दछन्, ति म मा छन् र म पनि तिनैमा छु । 9.29",

    "यदि (कोहि) दुराचारीले पनि अनन्य भावले मलाई भजन गर्दछ भने त्यसलाई साधु (महात्मा) नै मान्नुपर्दछ । किनभने त्यो यथार्थ निश्चयी हो । 9.30",

    "त्यो तत्काल नै (त्यहि क्षण) धर्मात्मा हुन जान्छ र नित्य शान्तिलाई पनि  प्राप्त गर्दछ । हे कुन्तीनन्दन ! मेरो भक्तको पतन कहिल्यै हुदैन यो कुरा तिमीले मनमा दृढ गरेर राख ।न् । 9.31",

    "हे पृथानन्दन ! जो पनि पाप योनि (निचयोनि) वाला किन नहुन् तथा जो पनि स्त्रिहरु, वैश्य र शूद्र किन नहुन्, तिनले पनि नित्य मेरो आश्रय (शरण) लिएमा निःसन्देह परमगतिलाई प्राप्त गर्दछन् । 9.32",

    "(जुन) पवित्र आचरण भएका ब्राह्मण र ऋषिस्वरूप क्षत्रीयहरु जो भगवान् का भक्त छन्, (ती परम गतिलाई प्राप्त गरेका) यिनिहरुको ता कुरा के नै भन्नु पर्छ र? तिमी यो अनित्य र दु:खकारक यस लोकमा छौ,   यसकारण तिमीले मेरो भजन सदा सर्वदा गर । 9.33",

    "(तिमी) मेरा भक्त होउ, म मा नै मन लगाउने होउ, मेरो पूजा गर्नेवाला होउ र मलाई  नै नमस्कार गर । यस प्रकार आफैले - आफैलाई मेरो साथमा लगाएर, मेरो परायण हुदै योगको अभ्यास गर्नाले (तिमी) मलाई नै प्राप्त गर्नेंछौ (पाउने छौ) । 9.34",

    "यस प्रकार ॐ तत् सत् - यी भगवन्नामहरुको उच्चारणपूर्वक ब्रह्मविद्या र योगशास्त्रमय श्रीमद्भगवद्गीताको उपनिषद् रूप श्रीकृष्ण र अर्जुनको संवाद स्वरुप ‘राजविद्याराजगुह्ययोग’ नाम भएको नवौं अध्याय पूर्ण भयो ।"
  ],
  "adhyaya-10" : [
    "श्रीभगवान् ले भन्नुभयो-- हे महाबाहो अर्जुन! मेराे परमरहस्य र प्रभावयुक्त वचनलाई तिमी फेरि पनि सुन, जस्लाई म, तिमी अत्यन्त प्रेम गर्नेलाई हितेच्छापूर्वक बताउँछु ‌। 10.1",

    "मेरो उत्पत्तिलाई अर्थात् लीलापूर्वक प्रकट हुनुलाई न देवता जान्दछन्, र न महर्षिहरुले नै जान्दछन्, किनकि म सबै प्रकारबाट देवताहरु र महर्षिहरुको आदिकारण हुँ। 10.2",

    "जुन पुरुषले मलाई अजन्मा अर्थात जन्मरहित अनादि र सम्पूर्ण लोकको महान् ईश्वर तत्वपूर्वक जान्दछ अर्थात् दृढ़तापूर्वक स्विकार गर्दछ, त्यो पुरुषहरु मध्येमा ज्ञानवान् पुरुष हो र सम्पूर्ण पापहरू बाट मुक्त हुन्छ । 10.3",

    "बुध्दि, ज्ञान, असम्मूढता, क्षमा, सत्य, इन्द्रियहरूलाई वशमा गर्नु, मनको निग्रह तथा सुख-दुःख, उत्पत्ति-प्रलय, भय-अभय 10.4",

    "तथा अहिंसा, समता, संतोष, तप, दान, कीर्ति र अपकीर्ति- यस्ता प्राणिहरुका विभिन्न प्रकारका र अलग-अलग बीस भाव मबाटै हुन्छन् । 10.5",

    "सात महर्षिहरु र उनीहरू भन्दा पनि पहिलेका चार सनकादि तथा स्वायम्भुव आदि चौध मनु-- यी सबैका सबै मेरो मनबाट उत्पन्न भई ममा भाव (श्रद्धाभक्ति) राख्नेवाला छन्, जसको संसारमा यो सम्पूर्ण प्रजा छ । 10.6",

    "जुन मनुष्य मेरो यस परमैश्वर्यरुपी विभूतिलाई र योग (सामर्थ्य) शक्तिलाई तत्वपूर्वक जान्दछ अर्थात् दृढ़तापूर्वक मान्दछ, ऊ निश्चय नै भक्तियोगले युक्त हुन्छ, यसमा कुनै पनि संशय छैन । 10.7",

    "म नै सम्पूर्ण जगतमात्रको उत्पत्तिको मूल कारण हुँ र मद्वारा नै सारा जगतले चेष्टा गर्दछ - यस्तो सम्झिएर (मानेर) ममा नै श्रद्धा-प्रेम राख्दै बुद्धिमान् भक्तहरुले मेरो नै भजन गर्दछन् - सबै प्रकारबाट मेरो नै शरणमा हुन्छन् । 10.8",

    "निरन्तर ममा मन लगाउने, ममा नै प्राणलाई अर्पण गर्ने भक्तजनहरु आपसमा मेरो गुण, प्रभाव आदिलाई जान्दै र तिनलाई चर्चा गर्दै नित्य- निरन्तर सन्तुष्ट हुन्छन् र म वासुदेवलाई नै निरन्तर प्रेम (रमण) गर्दछन् । 10.9",

    "ती नित्य-निरन्तर मेरो ध्यानमा लागेका र प्रेमपूर्वक मेरो भजन गर्नेवाला भक्तहरूलाई म त्यो तत्वज्ञानरुपी बुध्दियोग दिन्छु, जसका कारण तिनले मलाई नै प्राप्त गर्दछन् । 10.10",

    "ती भक्तहरुमा कृपा गर्नको लागि नै उनिहरुको स्वरूपमा (अन्त:करणमा) स्थित भएको म उनिहरुको अज्ञानजनित अन्धकारलाई प्रकाशमय तत्वज्ञानरूपी दीपकद्वारा नष्ट गरिदिन्छु । 10.11",

    "अर्जुनले भने - परम ब्रह्म, परम धाम र परम पवित्र हजुर नै हुनुहुन्छ । हजुर शाश्वत, दिव्य पुरुष, आदिदेव, अजन्मा र सर्वव्यापी हुनुहुन्छ। 10.12",

    "(यस्तो) हजुरलाई सबैका- सबै ऋषि, देवर्षि नारद, असित र देवल ऋषि तथा महर्षि व्यासले पनि भन्नुहुन्छ र स्वयं हजुरले पनि मप्रति भन्नुहुन्छ 10.13",

    "हे केशव ! मेरालागि हजुरले जे-जति भन्नुहुन्छ, ती सबैलाई म सत्य मान्दछु । हे भगवन् ! हजुरको लीलामय स्वरुपलाई न त देवताले जान्दछन् (र) न त दानवले नै जान्दछन् । 10.14",

    "हे भूतहरुलाई उत्पन्न गर्नेवाला! हे भूतहरुका ईश्वर ! हे देवताहरुका देव ! हे जगतका स्वामी ! हे पुरुषोत्तम ! हजुर स्वयं नै आफैले आफैलाई जान्नु हुन्छ। 10.15",

    "यसकारण जुन विभूतिहरुबाट हजुर यो सम्पूर्ण चराचर जगतमा व्याप्त गरेर स्थित हुनुहुन्छ, ती सबै आफ्नो दिव्य विभूतिहरुलाई सम्पूर्णताका साथ वर्णन गर्नमा हजुर नै समर्थ हुनुहुन्छ। 10.16",

    "हे योगेश्वर ! म कसरी नित्य निरन्तर चिन्तन गर्दै हजुरलाई जानूँ? र हे भगवन् ! हजुर कुन-कुन भावहरूमा मद्वारा चिन्तन गर्न योग्य हुनुहुन्छ? अर्थात् कुन-कुन भावहरूमा म हजुरलाई चिन्तन गरौं? 10.17",

    "हे जनार्दन ! हजुर आफ्नो योगशक्ति र विभूतिहरुको बर्णनलाई विस्तारपूर्वक शुरुबाट नै फेरि पनि भन्नुस्, किनकि हजुरका अमृतमय वचनहरुलाई जति सुने पनि मलाई तृप्ति नै हुँदैन अर्थात् सुन्ने उत्कण्ठा भइरहन्छ। 10.18",

    "श्रीभगवान् ले भन्नुभयो-- हो, ठीक छ। म आफ्नो दिव्य विभूतिहरुलाई तिम्रो निमित्त प्राथमिकताका साथमा (संक्षेपबाट) भन्दछु, किनकि हे कुरुश्रेष्ठ ! मेरो विभूतिहरुको विस्तारको अन्त (सिमा) नै छैन । 10.19",

    "हे नीन्द्रालाई जीतनेवाला अर्जुन (गुडाकेश) ! सम्पूर्ण प्राणिहरुको आदि, मध्य तथा अन्त्य म नै हुँ र सम्पूर्ण प्राणिहरूको अन्तःकरण (ह्रदय) मा स्थित आत्मा पनि म नै हुँ। 10.20",

    "म अदितिका बाह्र छोराहरुमा विष्णु (वामन) र ज्योतिहरुमा किरणहरू भएको सूर्य हुँ। म उनन्पचास वायु देवताहरुको तेज एवं नक्षत्रहरुका अधिपति चन्द्रमा हुँ। 10.21",

    "म वेदहरुमा सामवेद हुँ, देवताहरुमा इन्द्र हुँ, इन्द्रियहरुमा मन हुँ र भूतप्राणिहरूको चेतना हुँ अर्थात् जीवनशक्ति हुँ । 10.22",

    "म एकादश रुद्रहरुमा शंकर हुँ र यक्ष तथा राक्षसहरुमा धनका स्वामी कुबेर म नै हुँ । म आठ वसुहरुमा पवित्र गर्नेवाला अग्नि हुँ भने शिखरयुक्त पर्वतहरूमा सुमेरु पर्वत मैं हुँ। 10.23",

    "हे पार्थ! पुरोहितहरुमा मुखिया बृहस्पतिलाई मेरो स्वरूप समझ। (यस्तै) सेनापतिहरुमा कार्तिकेय (स्कन्द) र जलाशयहरुमा समुद्र म हुँ। 10.24",

    "म महर्षिहरुमा भृगु र वाणिहरु (शब्दहरू)मा एक अक्षर अर्थात् ओमकार (प्रणव) हुँ। सबै प्रकारका यज्ञहरुमध्ये जप यज्ञ म नै हुँ भने स्थिर रहनेहरुमा हिमालय पहाड (पनि) म नै हुँ। 10.25",

    "सम्पूर्ण वृक्षहरुमा पीपल, देवर्षिहरुमा नारद मुनि, गन्धर्वहरुमा चित्ररथ र सिद्धयोगीहरुमा कपिल मुनि म नै हुँ। 10.26",

    "घोड़ाहरुमा अमृतका साथ समुद्रबाट प्रकट हुनेवाला उच्चैःश्रवा नामक घोड़ालाई, श्रेष्ठ हात्तीहरुमा ऐरावत नामको हात्तीलाई र मनुष्यहरुमा राजालाई मेरो विभूति मान। 10.27",

    "आयुध (हतियार)हरुमा वज्र र गाईहरुमा कामधेनु म हुँ। सन्तान-उत्पत्तिको लागि कामदेव म हुँ र सर्पहरुमा सर्पराज वासुकि म हुँ। 10.28",

    "म नागहरुमा अनन्त (शेषनाग) र जल-जन्तुहरुको अधिपति वरुण देवता हुँ तथा पितृहरुमा अर्यमा र शासन गर्नेहरुमा यमराज म हुँ। 10.29",

    "दैत्यहरुमा प्रह्लाद र गणना गर्ने वालाहरुमा (ज्योतिषिहरुमा) समय म हुँ तथा पशुहरूमा सिंह र पक्षिहरुमा गरुड म हुँ। 10.30",

    "म पवित्र तुल्याउनेहरुमा वायु र शस्त्रधारिहरुमा श्रीराम हुँ भने जल-जन्तु (माछा)हरुमा मगर गोही म हुँ। बग्नेवाला स्त्रोत (नदी)हरुमा श्रीभागीरथी गङ्गाजी म हुँ। 10.31",

    "हे अर्जुन ! सम्पूर्ण सृष्टिहरुको आदि, मध्य तथा अन्त्य म नै हुँ। विद्याहरुमा अध्यात्मविद्या (ब्रह्मविद्या) र परस्पर शास्त्रार्थ (विवाद) गर्नेवालाहरुमा तत्त्व निर्णयार्थ गरिने वाद म हुँ। 10.32",

    "अक्षरहरुमा अकार र समासहरुमा द्वन्द्व नामक समास म हुँ। अक्षयकाल अर्थात् कालको पनि महाकाल तथा सबैतिर मुख भएको विराट्स्वरुप, सबैलाई पालन-पोषण गर्नेवाला पनि म नै हुँ। 10.33",

    "सबैलाई नाश गर्ने मृत्यु र भविष्यमा उत्पन्न हुनेहरुको उत्पत्तिहेतु म हुँ भने स्त्री-जातिहरुमा कीर्ति, श्री, वाक् (वाणी), स्मृति, मेधा, धृति र क्षमा पनि म नै हुँ। 10.34",

    "गायन गर्नेवाला वा गीत गाउन मिल्ने श्रुतिहरुमा म बृहत्साम र सबै छन्दहरुमा गायत्री छन्द हुँ भने (बाह्र) महीनाहरूमा मार्गशीर्ष (मंसिर) र (छ वटा) ऋतुहरुमा वसन्त म हुँ। 10.35",

    "छल गर्नेवालाहरुमा जुवा र तेजस्विहरुमा तेज (प्रभावशाली पुरुषहरुको प्रभाव) म हुँ। जीतने वालाहरुको विजय, निश्चय गर्नेहरुको निश्चय र सात्त्विक पुरुषहरुको सात्त्विक भाव पनि म नै हुँ। 10.36",

    "वृष्णिवंशहरुमा (यादवहरुकै एउटा वंश) वसुदेव पुत्र श्रीकृष्ण अर्थात् म स्वयं तिम्रो साथी, पाण्डवहरूमा धनञ्जय (अर्जुन) अर्थात् तिमी, मुनिहरुमा वेदव्यास र कविहरुमा कवि शुक्राचार्य पनि म नै हुँ। 10.37",

    "म दमन गर्नेहरुको दण्डनीति अर्थात् दमन गर्ने शक्ति र विजय गर्न चाहनेवालाहरुको नीति हुँ। गोप्य राख्नयोग्य भावहरुको रक्षक मौन र ज्ञानवान् हरुको तत्वज्ञान पनि म नै हुँ। 10.38",

    "र हे अर्जुन! जो सम्पूर्ण प्राणि (भूत)हरुको उत्पत्तिको मूलकारण हो, त्यो बीज पनि म नै हुँ, किनकि यस्तो चर र अचर कुनै पनि प्राणी(भूत) छैन, जो म देखि रहित होस् अर्थात् चर र अचर सबै म नै हुँ । 10.39",

    "हे परन्तप अर्जुन ! मेरो दिव्य विभूतिहरुको अन्त्य छैन । मैंले तिम्रा अगाडि आफ्ना विभूतिहरुको बारेमा जुन विस्तारपूर्वक वर्णन गरे, यो त केवल संक्षेपमा (नाममात्रको) बताएको हुँ । 10.40",

    "जे-जति विभूतियुक्त अर्थात् ऐश्वर्ययुक्त, कान्तियुक्त र शक्तियुक्त प्राणी तथा पदार्थ छन्, तिनलाई तिमीले मेरै तेज (योग अर्थात् सामर्थ्य) को अंशबाटै उत्पन्न भएको ठान। 10.41",

    "अथवा हे अर्जुन ! तिमीलाई यस प्रकारका धेरै कुराहरु जान्नुसित के प्रयोजन छ? जबकि म (आफ्नो कुनै) एक अंशबाट यो सम्पूर्ण चराचर जगत् लाई व्याप्त गरेर रहन्छु (स्थित छु) अर्थात् अनन्त ब्रह्माण्डलाई आफ्नो योग शक्तिको एउटा अंश मात्रले धारण गरेर बसेको छु। 10.42",

    "यस प्रकार ॐ तत् सत् - यी भगवन्नामहरुको उच्चारणपूर्वक ब्रह्मविद्या र योगशास्त्रमय श्रीमद्भगवद्गीताको उपनिषद् रूप श्रीकृष्ण र अर्जुनको संवाद स्वरुप ‘विभूतियोग’ नाम भएको दशौं अध्याय पूर्ण भयो ।"
  ],
  "adhyaya-11" : [
    "अर्जुनले भने- केवल मेरा माथि कृपा गर्नकोलागी नै हजुरले जुन परम गोपनीय अध्यात्म-विषयक वचन अर्थात् उपदेश दिनुभयो, त्यसबाट मेरो यो मोह नष्ट भएको छ । 11.1",

    "किनकि हे कमलनेत्र! सम्पूर्ण प्राणिहरुको उत्पत्ति तथा विनाश मैंले विस्तारपूर्वक हजुरबाट नै सुनेको छु र हजुरको अविनाशी महिमा पनि सुनेको छु। 11.2",

    "हे पुरुषोत्तम! हजुरले आफुलाई-आफु जस्तो भन्नुहुन्छ, यो वास्तवमा ठ्याक्कै उस्तै छ। हे परमेश्वर! हजुरको ईश्वर-सम्बन्धी ऐश्वर्यरूपलाई म प्रत्यक्ष देख्न चाहन्छु। 11.3",

    "हे प्रभु! मैले हजुरको त्यो परम ऐश्वर्य स्वरूप देख्न सक्छु - यस्तो यदि हजुरलाई लाग्दछ भने हे योगेश्वर! हजुरले आफ्नो त्यस अविनाशी स्वरूप मलाई दर्शन गराई दिनुहोस्। 11.4",

    "श्री भगवान् ले भन्नुभयो - हे पार्थ! अब तिमीले मेरा नाना प्रकारका र नाना वर्णहरूका (रंगहरु) तथा आकृतिहरुलाई सैयौँ-हजारौँ अलौकिक रूपहरूलाई हेर। 11.5",

    "हे भरतवंशी अर्जुन! तिमी बाह्र आदित्यहरुलाई अर्थात् अदितिका बाह्र पूत्रहरुलाई, आठ वसुहरुलाई, एघार रुद्रहरुलाई र दुवै अश्विनीकुमारहरुलाई तथा उनन्पचास मरुद्गणहरुलाई हेर । यस्तै पहिले कहिल्यैँ नदेखेका धेरै नै आश्चर्यमय रूपहरुलाई पनि तिमीले हेर । 11.6",

    "हे निन्द्रालाई जित्नेवाला अर्जुन! (गुडाकेश!) मेरो यो शरीरको एकै ठाँउमा चराचर सहित सम्पूर्ण जगत् लाई हेर। यसका अतिरिक्त (तिमी) अरु पनि जे-जति देख्न चाहन्छौ, ती पनि हेर। 11.7",

    "तर मलाई तिमीले यो आफ्नो आँखा (चर्मचक्षु) बाट हेर्न नि:सन्देह सक्दैनौ, त्यसैले म तिमीलाई दिव्य अर्थात् अलौकिक चक्षु दिन्छु, जसबाट तिमी मेरो ईश्वरीय अलौकिक शक्ति (सामर्थ्य) लाई हेर। 11.8",

    "सञ्जयले भने - हे राजन्! यस्तो भनेर फेरि महायोगेश्वर र सबै पापलाई नाश गर्नेवाला भगवान् श्रीकृष्णले अर्जुनलाई परम ऐश्वर्यले युक्त विराट स्वरूप (दिव्य स्वरुप) देखाउनु भयो। 11.9",

    "जसका अनेक मुख र नेत्रहरुले युक्त, अनेक अद्भुत दर्शनवाला, प्रशस्त अलौकिक आभूषणहरुले युक्त र हातहरुमा प्रशस्त दिव्य शस्त्रहरु लिनुभएका, 11.10",

    "जस्को गलामा दिव्य मालाहरु छन्, जस्ले अलौकिक वस्त्रहरुलाई धारण गर्नु भएका एवं जसका निधार तथा शरिरमा दिव्य चन्दन, कुंकुम आदि लगाइएको छ यस्तो सम्पूर्ण आश्चर्यमय, अनन्त रूपहरुवाला तथा सबै तर्फ मुखहरु भएका परमदेव (आफ्नो दिव्य स्वरूप) लाई भगवान् ले देखाउनु भयो। 11.11",

    "आकाशमा एकै पटक हजारौं सूर्यहरु उदय हुँदा उत्पन्न हुने जुन प्रकाश हुन्छ, त्यो पनि त्यस विश्वरूप परमात्माको प्रकाशसमान सायदै होला। 11.12",

    "यस समय अर्जुनले देवताका पनि देवता भगवान् श्रीकृष्णको त्यस शरिरको एकै ठाउँमा अनेक प्रकारका विभागहरुमा विभक्त सम्पूर्ण जगत् लाई रहेको देखे । 11.13",

    "भगवान् को विश्वरूपलाई देखेर ती अर्जुन धेरै चकित र पुलकित भएर आश्चर्यचकित भएका कारण उनको शरिर रोमाञ्चित भयो अनि श्रद्धाभक्तिसहित विश्वरूप भगवान् लाई शिरले प्रणाम गरी हात जोडेर भने । 11.14",

    "अर्जुनले भने - हे देव! मैले हजुरको शरिरमा सम्पूर्ण देवताहरुको देवता तथा प्राणिहरूको विशेष-विशेष समुदायहरु र कमलासनमा विराजित ब्रह्माजीलाई, शङ्करजीलाई, सम्पूर्ण ऋषिहरूलाई र दिव्य सर्पहरुलाई देखेको छु । 11.15",

    "हे विश्वरूप! हे विश्वेश्वर! हजुरलाई मैले अनेकौं हातहरु, पेटहरु, मुखहरु र आखाँहरुले युक्त भएको तथा सबैतिरबाट अनन्तरूपवाला देख्दछु । मैले हजुरलाई न आदि, न मध्य र न अन्तमा नै देखि रहेको छु । 11.16",

    "मैले हजुरलाई किरीटयुक्त (मुकुटयुक्त), गदायुक्त, चक्रयुक्त (तथा शंख र पद्मयुक्त) धारण गरेको देख्दछु । हजुरलाई तेजका पुञ्ज, सबैतिर प्रकाशमान भएको, प्रज्ज्वलित अग्नि र सूर्यसमान ज्योतियुक्त, (आखाँहरु द्वारा) कठिनतासाथ हेर्न सकिने र सबैतिरबाट अप्रमेयस्वरूपलाई देख्दछु। 11.17",

    "हजुर नै जान्ने योग्य परम अक्षर (अक्षरब्रह्म) हुनुहुन्छ, हजुर नै यो सम्पूर्ण विश्वको परम आश्रय हुनुहुन्छ, हजुर नै अनादि (सनातन) धर्मका रक्षक हुनुहुन्छ र हजुर नै अविनाशी सनातन पुरुष हुनुहुन्छ - भन्ने यस्तो मेरो मत छ । 11.18",

    "हजुरलाई आदि, मध्य र अन्तले रहित, अनन्त प्रभावशाली, अनन्त भुजाहरु भएको, चन्द्र र सूर्यरूपी नेत्रहरु भएको, प्रज्वलित अग्निरूपी मुखहरु भएको र आफ्नो तेजले यस जगतलाई सन्तप्त गरिरहनु भएको देख्दछु। 11.19",

    "हे महात्मन्! यो स्वर्ग र पृथ्वीको बीचको सम्पूर्ण आकाश तथा सम्पूर्ण दिशाहरु एकआपसमा नै परिपूर्ण छन् भने हजुरको यो अलौकिक, अद्भुत र भयङ्कररूपलाई देखेर तीनै लोक अति व्यथाले व्यथित (व्याकुल) भईरहेको छ । 11.20",

    "तिनै देवताहरूको समुदाय हजुरमा प्रविष्ट भईरहेका छन् भने तिनिहरु मध्येबाट कति त भयभीत भएर हात जोड़दै हजुरका नामहरु र गुणहरूको कीर्तन गरिरहनु भएको छ । साथै महर्षिहरु र सिद्ध पुरुषहरुको समुदायले 'कल्याण होस्! मंगल होस्!' यस्तो भन्दै राम्रा-राम्रा (उत्तम-उत्तम) स्तोत्रहरुको माध्यमबाट हजुरको स्तुति गरिरहनु भएको छ । 11.21",

    "जो एघार रुद्र, बाह्र आदित्य, आठ वसु, बाह्र साध्यगण, दस विश्वदेव र दुई अश्विनीकुमार, उनन्पचास मरुद्गण र तातो-तातो खाना खानेवाला सात पितृगणको समुदाय तथा गन्धर्व, यक्ष, राक्षस र सिद्धहरुको समुदाय सबै चकित (विस्मित) भएर हजुरलाई हेर्दछन्। 11.22",

    "हे महाबाहो! हजुरका धेरै मुख र नेत्रवाला, धेरै हात, जाँघ र खुट्टावाला, धेरै पेटवाला र धेरै दारीका कारण विकराल महान् रूपलाई देखेर सबै प्राणी व्याकुल भइरहेका छन् र संगै म पनि ब्याकुल भईरहेको छु । 11.23",

    "किनकि हे विष्णो! हजुर देदीप्यमान, अनेक वर्णले युक्त, आकाशलाई स्पर्श गरिरहनु भएको छ अर्थात् सबैतिरबाट धेरै ठूलो छ, हजुरको मुख फैलिएको छ, हजुरका आखाँ प्रकाशमान र विशाल छन्। यस्तो हजुरलाई देखेर भयभीत अन्तःकरणसहितको मैले धैर्य र शान्ति महसुस गर्न सकेको छैन। 11.24",

    "भयङ्कर दाह्राका कारण विकराल प्रलयकालको अग्निजस्तै प्रज्वलित मुखलाई देखेर म दिशाहरु पनि चिन्दिन र सुख पनि पाउँदिनँ। यसकारण हे देवेश! हे जगन्निवास! हजुर प्रसन्न हुनुहोस्। 11.25",

    "हाम्रो पक्षका मुख्य-मुख्य योद्धाहरु सहित भीष्म, द्रोण र कर्ण पनि हजुरमा प्रवेश गरिरहेका छन्। राजाहरुको समुदाय सहित धृतराष्ट्रका ति सबैका सबै पुत्र हजुरको विकराल दाह्राका कारण विकराल भयङ्कर मुखमा बड़ो वेगले प्रवेश गरिरहेका छन् भने कति त चूर्ण भएका शिरहरुसहित हजुरका दाँतका बिचमा फँसेको देखिएका छन्। 11.26-11.27",
  
    "हाम्रो पक्षका मुख्य-मुख्य योद्धाहरु सहित भीष्म, द्रोण र कर्ण पनि हजुरमा प्रवेश गरिरहेका छन्। राजाहरुको समुदाय सहित धृतराष्ट्रका ति सबैका सबै पुत्र हजुरको विकराल दाह्राका कारण विकराल भयङ्कर मुखमा बड़ो वेगले प्रवेश गरिरहेका छन् भने कति त चूर्ण भएका शिरहरुसहित हजुरका दाँतका बिचमा फँसेको देखिएका छन्। 11.26-11.27",
    
    "जसरि नदिहरुका प्रशस्त जलका प्रवाहहरु स्वाभाविकरुपमा समुद्रकै मुखतिर दौड़न्छन् अर्थात् समुद्रमा प्रवेश गर्दछन्, त्यसैगरी ती नरलोकका वीर पनि हजुरका प्रज्ज्वलित मुखमा प्रवेश गर्दै छन् । 11.28",

    "जसरि पुतलीहरु मोहवश आफ्नो नाश गर्नका लागि अति वेगले दौडँदै प्रज्वलित अग्निमा प्रवेश गर्दछन्, त्यसैगरि ती सबैजना पनि (मोहवश) आफ्नो नाश गर्नकोलागी अतिवेगले दौडँदै हजुरका मुखमा प्रवेश गर्दै छन्। 11.29",

    "हजुरले आफ्नो प्रज्वलित मुखद्वारा सम्पूर्ण जगतलाई गाँस बनाएर (त्यसलाई) सबैतिरबाट बारम्बार चाटिरहनुभएको छ। हे विष्णु! हजुरको उग्र प्रकाशले सम्पूर्ण जगत् लाई तेजका माध्यमले परिपूर्ण तुल्याई सबैतिर सो तेज फैलिएको छ। 11.30",

    "मलाई यो बताउनोस् कि उग्ररूप हुनु भएको हजुर को हुनुहुन्छ? हे देवताहरूमा श्रेष्ठ! हजुरलाई नमस्कार छ। हजुर प्रसन्न हुनुहोस्। आदिपुरुष हजुरलाई मैले विशेषरुपले जान्न चाहन्छु, किनकि म हजुरको प्रवृत्तिलाई भली भाँति जान्दिनँ। 11.31",

    "श्रीभगवान् ले भन्नुभयो - म सम्पूर्ण लोकलाई नाश गर्नेवाला बढेको महाकाल हुँ र यसबेलामा म यी सबै लोकहरुलाई नष्ट गर्नकोलागी प्रवृत्त भएको छु। तिम्रो प्रतिपक्षहरुका सेनामा जो योध्दाहरु खडा छन्, ती सबै तिमी बिना पनि रहदैनन् अर्थात् तिमीले युध्द नगरे पनि यी सबैको नाश हुन्छ। 11.32",

    "यसकारण तिमी युध्दका निमित्त खडा होऊ र यश प्राप्त गरी शत्रुहरुलाई जीतेर धन-धान्यले सम्पन्न राज्यलाई भोग गर। यिनीहरू सबै शूरवीर मबाट पहिले नै मारिइसकेका छन् । हे सव्यसाचिन्! अर्थात् दुवै हातले बाण चलाउने भएका अर्जुन! तिमी यिनिहरूलाई मार्नको लागि निमित्त मात्र बन। 11.33",

    "द्रोण र भीष्म तथा जयद्रथ र कर्ण तथा अरु धेरै मबाट मारिएका शूरवीर योध्दाहरूलाई तिमीले मार। तिमी नडराऊ (र) युध्द गर। युध्दमा तिमीले निःसन्देह वैरिहरुलाई जीत्नेछौ। 11.34",

    "सञ्जयले भने - भगवान केशवका यी वचन सुनेपछि डरले काप्तै मुकुटधारी अर्जुन हात जोड़ेर काँप्दै नमस्कार गरी अत्यन्त भयभीत हुँदै प्रणाम गर्दै गद्गद् वाणीबाट भगवान् श्रीकृष्णलाई भन्नु भयो -- 11.35",

    "अर्जुनले भने- हे अन्तर्यामी भगवन! हजुरका नाम, गुण, लीलालाई कीर्तन गर्दा पनि यो सम्पूर्ण जगत् हर्षित भईरहेको छ भने अनुराग (प्रेम) पनि प्राप्त भईरहेको छ, हजुरको नाम, गुण आदिको कीर्तनबाट भयभीत भएर राक्षसहरु दसवटै दिशातिर भागिरहेका छन् र सम्पूर्ण सिध्दगणको समुदाय हजुरलाई नमस्कार गरिरहेकाछन् । यो सबै हुनु उचित नै हो। 11.36",

    "हे महात्मन्! गुरुहरुका पनि गुरु र ब्रह्माजीका पनि आदिकर्ता हजुरकोलागि ती सिध्दगणले नमस्कार किन नगरुन्? किनकि हे अनन्त! हे देवेश! हे जगन्निवास! हजुर अक्षरस्वरूप हुनुहुन्छ, हजुर सत् पनि हुनुहुन्छ, असत् पनि हुनुहुन्छ र तीभन्दा पनि (सत्-असत् बाट) पर अक्षर अर्थात् सच्चिदानन्दघन ब्रह्म छ, त्यो पनि हजुर नै हुनुहुन्छ। 11.37",

    "हजुर नै आदिदेव र पुराणपुरुष हुनुहुन्छ तथा हजुर नै यो जगतको परम आश्रय हुनुहुन्छ। हजुर नै सबैलाई जान्नेवाला, जान्नेयोग्य र परमधाम हुनुहुन्छ। हे अनन्तरूप! हजुरबाट नै सम्पूर्ण जगत व्याप्त अर्थात् परिपूर्ण छ । 11.38",

    "हजुर नै वायु, यमराज, अग्नि, वरुण, चन्द्रमा, दक्ष आदि प्रजाका स्वामी ब्रह्माजी (प्रजापति) र ब्रह्माजीका पनि पिता (प्रपितामह) हुनुहुन्छ। हजुरको लागि हजारौ पटक नमस्कार ! नमस्कार छ!! हजुरका लागि फेरि पनि बारम्बार नमस्कार ! नमस्कार छ!! 11.39",

    "हे सर्व स्वरूप! हजुरलाई अगाडिबाट पनि नमस्कार छ! पछाडिबाट पनि नमस्कार छ !! सबैतिरबाट (दशैँ दिशाहरुबाट) नै नमस्कार छ!!! हे अनन्तवीर्य! किनकि असीम पराक्रमशाली हजुरले समस्त संसारलाई व्याप्त गर्नु भएको छ, यसर्थ हजुर नै सर्वरूप हुनुहुन्छ। 11.40",

    "हजुरको यो महिमालाई नजानेर हजुर 'मेरो मित्र (साथी) हुनुहुन्छ' यस्तो मानेर मैंले प्रमादपूर्वक अथवा प्रेमबाट हठपूर्वक (बिना सोचेर-सम्झेर) 'हे कृष्ण! हे यादव! हे सखे!' यस प्रकार जे पनि विचार नै नगरि भनेको छु । हे अच्युत! हाँसी-मजाकमा, घुम्दै-फिरदै, सुतेर-जागेर, उठेर-बसेर, खादै-पीऊदैका समयमा एक्लै वा ती साथीहरु, नाता-कुटुम्बहरु आदिका अगाडि मद्वारा हजुरलाई जो तिरस्कार (अपमान) भएको छ, ती सबै अपराध अप्रमेयस्वरुप अर्थात् अचिन्त्य प्रभाववाला हजुरबाट क्षमा गराउन चाहन्छु अर्थात् म क्षमा माग्दछु । 11.41-11.42",
    
    "हजुरको यो महिमालाई नजानेर हजुर 'मेरो मित्र (साथी) हुनुहुन्छ' यस्तो मानेर मैंले प्रमादपूर्वक अथवा प्रेमबाट हठपूर्वक (बिना सोचेर-सम्झेर) 'हे कृष्ण! हे यादव! हे सखे!' यस प्रकार जे पनि विचार नै नगरि भनेको छु । हे अच्युत! हाँसी-मजाकमा, घुम्दै-फिरदै, सुतेर-जागेर, उठेर-बसेर, खादै-पीऊदैका समयमा एक्लै वा ती साथीहरु, नाता-कुटुम्बहरु आदिका अगाडि मद्वारा हजुरलाई जो तिरस्कार (अपमान) भएको छ, ती सबै अपराध अप्रमेयस्वरुप अर्थात् अचिन्त्य प्रभाववाला हजुरबाट क्षमा गराउन चाहन्छु अर्थात् म क्षमा माग्दछु । 11.41-11.42",
    
    "हजुर नै यो चराचर जगतको पिता हुनुहुन्छ, हजुर नै पूजनीय हुनुहुन्छ र हजुर नै गुरुहरुको पनि महान् गुरु हुनुहुन्छ। हे अनन्त प्रभावशाली भगवन्! यो त्रिलोकमा हजुरका समान पनि अर्को कुनै छैन भने हजुरभन्दा बढी त कसरि होला र? 11.43",

    "यसकारण स्तुति गर्न योग्य हजुर ईश्वरलाई मैले यहाँको शरीरलाई राम्रोसँग चरणमा निवेदन प्रणाम गरेर प्रसन्न हुनका लागि प्रार्थना गर्दछु। पिताले जसरि पुत्रको, मित्रले जसरि मित्रको र पति जसरि पत्नीको अपराधलाई क्षमा गर्दछन्, त्यसैगरि हे देव! हजुरले पनि मद्वारा गरिएका अपमानलाई अवश्य सहन गरि गरिदिनुहुने छ अर्थात क्षमा गरिदिनु होस्। 11.44",

    "जसलाई पहिले कहिल्यैँ देखिएको थिएन, यस्तो रूपलाई देखेर म हर्षित भई रहेकोछु। साथै मेरो मन भयले अत्यन्तै व्याकुल भईरहेको छ । यसर्थ हजुरले मलाई आफ्नो त्यो देवरूप (शान्त विष्णुरुप) लाई नै देखाई दिनुहवस्। हे देवेश! हे जगन्निवास! हजुर प्रसन्न हुनुहोस् । 11.45",

    "म हजुरलाई त्यसैगरि किरीट (मुकुट)धारी, गदाधारी र हातमा चक्र लिएको अर्थात चतुर्भुजरूपमा देख्न चाहन्छु । यसकारण हे सहस्रबाहु! हे विश्वरूप! हजुर त्यही चतुर्भुज स्वरूप (शंख, चक्र, गदा पद्म सहित) मा नै प्रकट हुनुहोस् । 11.46",

    "श्रीभगवान् ले भन्नुभयो- हे अर्जुन! अनुग्रहपूर्वक मैले आफ्नो योगशक्तिको सामर्थ्यबाट मेरो यो अत्यन्त श्रेष्ठ, तेजोमयस्वरूप, सबैलाई आदि र अनन्त (सीमारहित) विश्वरूप (विराट रूप) तिमीलाई देखाएको छु, जस्लाई तिमी वाहेक अर्को कसैले देखेको थिएन । 11.47",

    "हे कुरुश्रेष्ठ! मनुष्य लोकमा यस प्रकारको विश्वरूपवाला म न वेदहरूको पाठले, न यज्ञहरुको अनुष्ठानले, न शास्त्रहरुको अध्ययनले, न दानले, न उग्र तपहरुले, न क्रियाहरूले तिमीबाहेक अरुद्वारा देखिन सक्छु । 11.48",

    "यो मेरो यस प्रकारको उग्ररूपलाई देखेर तिमी व्याकुल हुनु पर्दैन र मूढ़भाव हुनु पनि हुदैन। अब तिमी निर्भय र प्रसन्न मनवाला भएर तिमी फेरि त्यहिँ मेरो त्यो चतुर्भुज स्वरूपलाई राम्रो किसिमले हेर । 11.49",

    "सञ्जय भने-- वासुदेव भगवान् ले अर्जुनलाई यस्तो भनेर फेरि त्यहि प्रकारबाट आफ्नो रूप (देवरूप) देखाउनुभयो भने फेरि महात्मा श्रीकृष्णले पुनः सौम्यमूर्ति (द्विभुज मनुष्य रूप) भएर ती भयभीत अर्जुनलाई धीर तुल्याउनुभयो । 11.50",

    "अर्जुनले भने - हे जनार्दन! हजुरको यो शान्त मनुष्यरुपलाई देखेर म यस समयमा स्थिरचित्त भएको छु र आफ्नो स्वाभाविक स्थितिलाई प्राप्त गरेको छु। 11.51",

    "श्रीभगवान् ले भन्नुभयो- मेरो यो जुन चतुर्भुज रूप तिमीले देेखेका छौ, यस्को दर्शन अत्यन्त्यै दुर्लभ छ। देवता पनि यो रूपको दर्शन गर्नलाई नित्य निरन्तर लालायित रहन्छन्। 11.52",

    "जुनप्रकार तिमीले मलाई देखेका छौ, यस्तो प्रकारको चतुर्भुजरूप भएको म, न त वेदहरूले, न तपले, न दानले र न यज्ञले नै देखिन सक्छु। 11.53",

    "परन्तु हे परन्तप अर्जुन! यसप्रकार चतुर्भुजरूप भएको म केवल अनन्य भक्तिबाट नै तत्त्वसाथ जान्नकालागि र साकार रूपबाट देख्नको लागि एवं प्रवेश (प्राप्त) गर्नको लागि अर्थात् एकीभावले प्राप्त हुनका लागि पनि सहज छु। 11.54",

    "हे पाण्डव! जुन पुरुष मेरो लागि नै कर्तव्यकर्म गर्ने खालको छ, मेरो नै परायण र मेरो नै प्रेमीभक्त छ तथा सर्वथा आसक्तिरहित छ र सम्पूर्ण भूतप्राणिहरुमा वैरभावले रहित छ, त्यस्तो अनन्यभक्तियुक्त पुरुषले मलाई नै प्राप्त गर्दछ।  11.55",

    "यस प्रकार ॐ तत् सत् - यी भगवन्नामहरुको उच्चारणपूर्वक ब्रह्मविद्या र योगशास्त्रमय श्रीमद्भगवद्गीताको उपनिषद् रूप श्रीकृष्ण र अर्जुनको संवाद स्वरुप ‘विश्वरूपदर्शनयोग’ नाम भएको एघारौं अध्याय पूर्ण भयो ।"    
  ],
  "adhyaya-12" : [
    "अर्जुन विन्ती गर्दछन् - जुन भक्त यस प्रकारले योग युक्त भएर तपाइँको साकार रुपको उपासना गर्दछन् तथा जुन भक्त अविनाशी निराकार रुपको उपासना गर्दछन्,  तिनमा उत्तम योगवेत्ता कुन हुन्? । 12.1",

    "श्री भगवान् आज्ञा गर्नुहुन्छ- म मा  मन लगाएर नित्य-निरन्तर ध्यान लगाएर जुन भक्त परम श्रध्दाले  युक्त भई मेरो उपासना गर्दछन्, ती मेरा मतमा अति  सर्वश्रेष्ठ योगी हुन् । 12.2",

    "जस्ले आफ्नो इन्द्रियहरुलाई वशमा गरी अचिन्त्य, सर्वव्यापी, अप्रत्यक्ष, कूटस्थ, अचल, नित्य, निराकार र अव्यक्तको उपासना गर्दछन्। 12.3",

    "ती सबै प्राणि मात्रको हितमा लागेका र सर्वत्र समबुध्दि राख्ने प्राणीले मलाई नै पाउँदछन्। 12.4",

    "अव्यक्तमा आसक्त चित्त भएका ती साधकहरुलाई (आफ्नो साधनमा) कष्ट धेरै हुन्छ; किनकि देहाभिमान हरुलाई अव्यक्त-विषयक  मार्ग कष्टले प्राप्त हुन जान्छ।    12.5",

    "परन्तु जसले मेरा कर्महरुलाई म मा अर्पण गरेर  र म मा परायण भएर अनन्य ध्यान योगले मेरो नै ध्यान गर्दै मेरो नै उपासना गर्दछन् । 12.6",

    "हे पार्थ ! म मा चित्त लगाउने ती  भक्तहरुलाई म मृत्युमय संसाररुपी महासागरबाट शीघ्र नै उध्दार गर्दछु ।  12.7",

    "तिमीले म मा नै मनलाई लगाऊ र म मा नै  बुध्दिलाई पनि लगाऊ । यसो गर्यौ भने तिमी अवश्य नै म मा वास गरौला -- यसमा कुनै शंका गर्नुपर्दैन। 12.8",

    "यदि तिमी मनलाई म मा अचल भावबाट स्थिर भइ अर्पण गर्न समर्थ छैनौ भने हे धनञ्जय ! अभ्यासयोगको सहायताले तिमी ले मलाई प्राप्त गर्ने इच्छा राख। 12.9",

    "यदि तिमी अभ्यास गर्नमा पनि असमर्थ छौ भने, मेरा लागि कर्म गर्नाले पनि सिध्दि पाउने छौ। 12.10",

    "यदि म मा  योगद्वारा आश्रित भएको तिमी यी (पूर्ववर्ति श्लोकमा भनिएका) साधनहरु लाइ पनि अपनाउन असमर्थ छौ भने, तिमीले मन र इन्द्रियहरुलाई वशमा राखेर सम्पूर्ण कर्महरुका  फललाई त्याग गर। 12.11",

    "अभ्यास भन्दा शास्त्रज्ञान श्रेष्ठ छ, शास्त्रज्ञान भन्दा ध्यान श्रेष्ठ   छ र ध्यान भन्दा पनि सबै कर्मको फल त्याग श्रेष्ठ छ । कर्मफल त्यागबाट तुरुन्त नै शान्ति प्राप्त हुन्छ। 12.12",

    "सबै प्राणिहरुमा द्वेष भावले नहेर्ने, सबैमा मित्र (प्रेमी) र दयालुभाव राख्ने, ममतारहित, अहंकाररहित, सुख र दुःखमा समान भाव राख्ने, क्षमाशील, निरन्तर सन्तुष्ट, योगी, शरीरलाई वशमा राख्ने, दृढ़ निश्चयी म मा अर्पित मन र बुध्दि भएको जुन मेरो भक्त हो, त्यो  मेरोलागि प्रिय छ ।  12.13",

    "सबै प्राणिहरूमा द्वेषभावबाट रहित, सबैलाइ मित्र (प्रेमी) र दयालुभावले हेर्ने, ममतारहित, अहंकाररहित, सुख र दुःखको प्राप्तिमा समभाव राख्ने, क्षमाशील, सधैं सन्तुष्ट, योगी, शरीरलाई वशमा राख्ने, दृढ़ निश्चयी, म मा नै अर्पित मन र बुध्दि भएको जुन मेरो भक्त हो, त्यो मलाई प्रिय छ । 12.14",

    "जसबाट कुनै प्राणीलाई क्लेश हुदैन र जसलाई  कुनै पनि प्राणीबाट क्लेश हुदैन अनि यस्तै जो हर्ष, ईर्ष्या, भय, दिग्दारी देखि मुक्त छ, त्यो मलाई प्यारो छ। 12.15",

    "जो आकाङ्क्षाबाट रहित, बाहिर र भित्रबाट पवित्र, दक्ष, उदासीन, दु:खहरुबाट छुटेको र सबै आरम्भहरु लाइ अर्थात् नयाँ नयाँ कर्महरुलाई सर्वथा त्यागेको छ, त्यो मेरो भक्त मलाई प्यारो छ ।  12.16",

    "जो न कहिल्यै हर्षित हुन्छ, न द्वेष गर्दछ, न शोक गर्दछ, न कामना गर्दछ र जो शुभ-अशुभ कर्महरुको फललाई त्याग्दछ, त्यो भक्तिमान् प्राणी (मनुष्य) मलाई प्यारो छ। 12.17",

    "जसले शत्रु र मित्रलाई तथा मान र अपमानलाई बराबर एवं सर्दि र गर्मी, (अनुकूलता र प्रतिकूलता) तथा सुख र दुःखलाई समान  रुपले हेर्दछ, जस्लाई कसैसंग  आसक्ति छैन्। 12.18",

    "र जुन मनुष्यले निन्दा र स्तुतिलाई  समान सम्झन्छ, मननशील, जुन सुकै प्रकारले पनि (शरीरलाई निर्वाह गर्न) संतुष्ट, बस्ने स्थान र शरीरलाई ममता र आसक्तिबाट मुक्त राख्ने एवं स्थिर बुध्दि भएको, त्यो भक्तिमान् पुरुष मलाई प्यारो छ। 12.19",

    "जस्ले म मा श्रध्दा राखेर र मै मा तन्मय भएर जुन भक्त पहिले बताइएको यो धर्ममय अमृतलाई निष्कामभावले सेवन गर्दछन्, ती मेरा अत्यन्त प्यारा छन् । 12.20",

    "यस प्रकार ॐ तत् सत् - यी भगवन्नामहरुको उच्चारणपूर्वक ब्रह्मविद्या र योगशास्त्रमय श्रीमद्भगवद्गीताको उपनिषद् रूप श्रीकृष्ण र अर्जुनको संवाद स्वरुप ‘भक्तियोग’ नाम भएको बाह्रौं अध्याय पूर्ण भयो ।"    
  ],
  "adhyaya-13" : [
    "श्रीभगवान् ले भन्नुभयो-- हे कुन्तीपुत्र अर्जुन ! 'यो' - रूपबाट भनिने शरीरलाई 'क्षेत्र' - नामबाट पुकारिन्छ र यस क्षेत्रलाई जस्ले जान्दछ, त्यसलाई ज्ञानीजन 'क्षेत्रज्ञ' भनेर पुकार्दछन्। 13.1",

    "हे भरतवंशोद्भव अर्जुन ! तिमी सम्पूर्ण क्षेत्रहरुमा क्षेत्रज्ञ अर्थात् जीवात्मा पनि मलाई नै जान र क्षेत्र-क्षेत्रज्ञलाई अर्थात् विकारसहित प्रकृति र पुरुषलाई जसले तत्वसाथ जान्दछ, त्यो ज्ञान हो भन्ने मेरो मत छ। 13.2",

    "त्यो क्षेत्र जुन र जे-जस्तो छ तथा जुन विकारहरुले युक्त भएको छ र जसबाट जे उत्पन्न भएको तथा त्यो क्षेत्रज्ञ पनि जे र जुन प्रभाववाला छ, त्यो सबै संक्षेपमा मबाट सुन। 13.3",

    "यो क्षेत्र-क्षेत्रज्ञ को तत्त्व- ऋषिहरुद्वारा धेरै विस्तारपूर्वकले भनिएको छ र विभिन्न वेदहरूका ऋचाहरुद्वारा धेरै प्रकारका विभागपूर्वक भनिएको छ भने अत्यन्त राम्ररी निश्चय गरिएको युक्तियुक्त ब्रह्मसूत्रका पदहरुद्वारा पनि भनिएको छ। 13.4",

    "मूल प्रकृति र समष्टि बुद्धि, समष्टि अहंकार, पाँच महाभूत र दस इन्द्रियहरू, एक मन तथा पाँचै इन्द्रियहरूको विषय अर्थात शब्द, स्पर्श, रुप, रस र गन्ध-- 13.5",

    "तथा इच्छा, द्वेष, सुख, दुःख, स्थूल देहको पिण्ड (शरीर), चेतना (प्राणशक्ति) र धृति - यसप्रकारका विकारहरुसहित यो क्षेत्र संक्षेपमा भनियो। 13.6",

    "आफैमा श्रेष्ठताको भाव नहुनु, देखावटीपन नहुनु, अहिंसा, क्षमा, सरलता, गुरुको सेवा, बाहिर-भित्रको शुध्दि, अन्त:करणको स्थिरता र मन-इन्द्रियसहित शरीरको निग्रह। 13.7",

    "यस लोक र परलोकका सम्पूर्ण भोगहरुमा आशक्तिको अभाव र अहंकारको पनि अभाव, जन्म, मृत्यु, जरा(वृध्दावस्था) तथा रोग (व्याधि) हरुमा दुःखरूपी दोषहरुलाई बारम्बार विचार गर्नु। 13.8",

    "आसक्ति रहित हुनु, पुत्र, स्त्री, घर र धन आदिमा एकात्मता (घनिष्ठ सम्बन्ध) नहुनु र अनुकूलता-प्रतिकूलताको तथा प्रिय र अप्रियको प्राप्तिमा सधैँ चित्त समान रहनु। 13.9",

    "म मा अनन्ययोगद्वारा अव्यभिचारिणी भक्ति (केवल सर्वशक्तिमान् परमेश्वरलाई नै आफ्नो स्वामी मान्दै स्वार्थ र अभिमानको त्याग गरी, श्रध्दा र भावरहित परमप्रेमले भगवानको निरन्तर चिन्तन गर्नु) तथा शुद्ध र एकान्त स्थानमा बस्ने स्वभाव र विषयासक्त मनुष्यहरुको समुदाय प्रति प्रेम नहुनु। 13.10",

    "अध्यात्मज्ञानमा (जसको ज्ञानद्वारा आत्मवस्तु र अनात्मवस्तु जान्न सकिन्छ) नित्य-निरन्तर र तत्त्वज्ञानको अर्थरूपी परमात्मालाई सबै ठाँउमा देख्नु - यी पूर्वोक्त साधन-समुदाय चाहिँ ज्ञान हुन् भने जो यसदेखि विपरीत हुन्छन् ती अज्ञान हुन् - भनेर भनिएको छ। 13.11",

    "जुन ज्ञेय (पूर्वोक्त ज्ञानबाट जान्न योग्य) छ, त्यस परमात्मातत्त्वलाई जानेर मनुष्यले परमानन्दको अनुभव गर्दछ त्यो कुरा स-विस्तारबाट म भन्दछु, जसलाई जानेर मनुष्यले परमानन्दको अनुभव गरि लिन्छन्। त्यो (ज्ञेय-तत्त्व) अनादिवाला परमब्रह्मलाई न सत् भनिन्छ न असत् नै (भन्न सकिन्छ)। 13.12",

    "त्यो (परमात्मा) सबैतिर हातगोडा भएको, सबैतिर आखाँ, शिर, मुख र कान भएको छ किनकि त्यो संसारमा सबैलाई व्याप्त गरेर बसेको छ। 13.13",

    "ऊ (परमात्मा) सम्पूर्ण इन्द्रियहरुबाट रहित छ र सम्पूर्ण इन्द्रियहरुको विषयहरुलाई जान्नेवाला छ र आसक्तिरहित भएर पनि सम्पूर्ण संसारलाई भरण-पोषण गर्ने र निर्गुण भएर पनि गुणहरुलाई भोग्ने खालको छ। 13.14",

    "ऊ (परमात्मा) सम्पूर्ण प्राणि (भूत)हरुको भित्र र बाहिर परिपूर्ण छ र चर-अचर (प्राणिहरुको रुपमा) पनि उही नै हो । साथै टाडा भन्दा टाढा र नजिक भन्दा नजिक पनि ऊ नै छ । ऊ अत्यन्त सूक्ष्म भएकोले अविज्ञेय छ। 13.15",

    "त्यो परमात्मा (स्वयं) विभागरहित भएर पनि सम्पूर्ण प्राणिहरूमा विभक्त जस्तै रहनुहुन्छ र वहाँ जान्नयोग्य त्यहि परमात्मा सम्पूर्ण प्राणिहरुलाई ब्रह्मरुपले उत्पन्न गर्नेवाला तथा उनिहरुलाई विष्णुरुपले धारन-पोषण गर्नेवाला र रुद्ररुपले संहार गर्नेवाला हुनुहुन्छ । 13.16",

    "त्यो परमात्मा सम्पूर्ण ज्योतिहरुको पनि ज्योति र मायाबाट अत्यन्त पर हुनुहुन्छ । त्यो ज्ञान स्वरूप, जान्नयोग्य, तत्वज्ञानले प्राप्त गर्नयोग्य छ भने सबैका हृदयमा विराजमान विशेषरुपले हुनुहुन्छ। 13.17",

    "यसप्रकार क्षेत्र तथा ज्ञान र जान्नयोग्य परमात्माको स्वरुपलाई संक्षेपमा भने। मेरो भक्तले यसलाई तत्वपूर्वक जानेर मेरै स्वरुपलाई प्राप्त गर्दछ। 13.18",

    "प्रकृति र पुरुष - दुवैलाई नै तिमीले अनादि समझ र राग-द्वेषादि विकारहरुलाई एवं त्रिगुणात्मक सम्पूर्ण पदार्थहरुलाई पनि प्रकृतिबाट नै उत्पन्न भएको समझ। 13.19",

    "कार्य र करणलाई उत्पन्न गर्नकालागि हेतु प्रकृति मानिन्छ भने जिवात्मालाई सुख-दुःखहरुको भोक्तापनमा अर्थात् भोग्नकालागि हेतु मानिन्छ। 13.20",

    "प्रकृतिमा रहेको (स्थित) पुरुषले (जीवले) नै प्रकृतिजन्य त्रिगुणात्मक पदार्थहरुलाई भोग्दछ भने यिनै गुणहरुको उपभोग (सङ्गत) को कारणबाट नै उसको माथिल्लो र तल्लो योनिहरुमा (सत्वगुणले देवयोनि, रजोगुणले मनुष्ययोनि र तमोगुणले पशु पन्छी आदि निच योनिहरुमा) जन्म लिने कारण बन्दछ । 13.21",

    "शरीरमा रहेको यो आत्मा वास्तवमा परमात्मा नै हो । यो पुरूष शरिरको साथमा सम्बन्ध राखेबाट 'उपद्रष्टा' र उसको साथ मिलेर यथार्थ सम्मति दिनेवाला भएकाले 'अनुमन्ता', सबैलाई त्यसको धारण - पोषण गर्नेवाला भएबाट 'भर्ता', उसको संगतबाट सुख दुःख भोगेबाट 'भोक्ता', ब्रम्हा आदिको पनि स्वामी हुनाले 'महेश्वर' र शुद्ध सच्चिदानन्दघन हुनाले परमात्मा भनिएको हो। 13.22",

    "यसप्रकार, पुरुष र गुणहरुका साथमा प्रकृतिलाई जुन मनुष्यले तत्वपूर्वक (अलग-अलग) जान्दछ, त्यसले सबै प्रकारले कर्तव्यकर्म गरिरहेर पनि फेरी जन्मँदैन। 13.23",

    "त्यस परमात्मालाई कति मनुष्यले ध्यानयोगका माध्यमले, कतिले ज्ञानयोगका माध्यमले र कतिले कर्मयोगका माध्यमले हृदयमा देख्दछन् र आफै-आफमा प्राप्त गर्दछन्। 13.24",

    "अरु पुरुषहरुले यस प्रकारको ध्यानयोग, सांख्ययोग, कर्मयोग आदि साधनहरुलाई जान्दैनन् तर अरु जीवनमुक्त महापुरुषहरुबाट सुनेर नै उपासना गर्दछन्, यस्तो यी सुनेर सोहि अनुसार आचरण गर्ने पुरुषहरु पनि मृत्युरुपी संसार-सागरबाट नि:सन्देह तर्दछन्। 13.25",

    "हे भरतवंशिहरुमा श्रेष्ठ अर्जुन! स्थावर र जंगम जति पनि प्राणी उत्पन्न हुन्छन, तिनिहरुलाई तिमी क्षेत्र र क्षेत्रज्ञको संयोगबाट उत्पन्न भएको समझ। 13.26",

    "जो नष्ट भएर पनि सम्पूर्ण प्राणिहरुमा परमेश्वरलाई नाशरहित र समभावपूर्वक स्थित देख्दछ, उसैले वास्तवमा यथार्थ देख्दछ। 13.27",

    "किनकि जुन पुरुषले सबैमा समभावले स्थित ईश्वरलाई समान देख्दै आफैद्वारा आफैलाई आफ्नो हिंसा गर्दैन, यसैबाट उसले परम गतिलाई प्राप्त गर्दछ। 13.28",

    "जुन पुरुष सम्पूर्ण कर्महरुलाई सबै प्रकारबाट प्रकृतिका माध्यमद्वारा नै गरिएको देख्दछ र आत्मालाई अकर्ता देख्दछ (अनुभव गर्दछ), उसैले नै यथार्थ देख्दछ। 13.29",

    "जुन समयमा पुरुषले प्राणिहरुको अलग-अलग भावहरुलाई एकै परमात्मामा नै रहेको (स्थित) देख्दछ र त्यो परमात्माबाट नै ती सबैको विस्तार भएको देख्दछ, त्यसै समयमा उसले सच्चिदानन्दघन ब्रह्मलाई प्राप्त गर्दछ। 13.30",

    "हे कुन्तीनन्दन ! ती (पुरुष स्वयं) अनादि र निर्गुण भएको कारणले, यो अविनाशी परमात्मा स्वरूप शरीरमा नै स्थित रहेर पनि, वास्तवमा न त केहि गर्दछ, न त लिप्त नै हुन्छ। 13.31",

    "जसरी सबैतिर व्याप्त आकाश अत्यन्त सूक्ष्म भएको कारणले (कहीं पनि) लिप्त हुदैन, त्यसैगरी सबैतिर परिपूर्ण आत्मा निर्गुण भएका कारण शरीरका गुणहरुले लिप्त हुँदैन। 13.32",

    "हे भरतवंशोद्भव अर्जुन ! जसरी एउटै सूर्यले सम्पूर्ण ब्रह्माण्डलाई प्रकाशित गर्दछ, त्यसैगरी क्षेत्रज्ञले (आत्माले) सम्पूर्ण क्षेत्रलाई प्रकाशित गर्दछ। 13.33",

    "यसप्रकार जसले ज्ञानरूपी चक्षुबाट तत्वसाथ क्षेत्र र क्षेत्रज्ञको भेदलाई तथा कार्य-करण सहित प्रकृतिबाट स्वयंलाई अलग (मुक्त भएको) जान्दछ, उसले परम ब्रह्म परमात्मालाई प्राप्त गर्दछ। 13.34",

    "यस प्रकार ॐ तत् सत् - यी भगवन्नामहरुको उच्चारणपूर्वक ब्रह्मविद्या र योगशास्त्रमय श्रीमद्भगवद्गीताको उपनिषद् रूप श्रीकृष्ण र अर्जुनको संवाद स्वरुप ‘क्षेत्रक्षेत्रज्ञविभागयोग’ नाम भएको तेह्रौं अध्याय पूर्ण भयो ।"   
  ],
  "adhyaya-14" : [
    "श्रीभगवान आज्ञा गर्नु हुन्छ - सम्पूर्ण ज्ञानहरुमा उत्तम र श्रेष्ठ ज्ञानका बारेमा म फेरि बताउँछु, जसलाई जानेर सबै मुनिहरु यो संसारबाट मुक्त भएर परम सिध्दि पाएर गइसकेका छन् । 14.1",

    "यस ज्ञानको आश्रय लिएर जुन मनुष्य मेरो स्वरुपमा मिलेका छन्,  तिनिहरू सृष्टिको उत्पत्ति कालमा पनि जन्मदैनन् अनि  प्रलय कालमा व्यथा पनि पाउदैनन् अर्थात् जन्म-मरणबाट पुरै छुटकारा पाउँदछन् ।  14.2",

    "हे भारत ! मेरो मूल प्रकृति गर्भ राख्ने (उत्पत्ति) स्थान हो र म त्यसमा (जीवरूप) गर्भधारण गर्दछु र त्यसबाट सम्पूर्ण चराचर जगत् (प्राणिहरु)  उत्पन्न हुन्छन् ।  14.3",

    "हे कौन्तेय! योनिहरुमा जति पनि मूर्तिहरू (मनुष्य, पशु, पंक्षी आदि) जन्मन्छन्, ती सबैको योनि (आत्मा) प्रकृति हो र म बीजदाता पिता हूँ । 14.4",

    "हे महाबाहु! प्रकृतिबाट उत्पन्न भएका सत्त्व, रज र तम – यी तीनै गुणहरुले अविनाशी जीवात्मालाई देहमा बाँधी राख्दछन् ।  14.5",

    "जुन परमपदलाइ प्राप्त गरेर फेरि फर्किएर आउनु पर्दैन, त्यो स्वयंप्रकाश परमपदलाई न सूर्य, न चन्द्रमा, न अग्निले प्रकाशित गर्न सक्दछन्, उहि नै मेरो परमधाम हो ।  14.6",

    "हे निष्पाप अर्जुन! यी गुणहरुमा सत्त्वगुण निर्मल (स्वच्छ) भएका कारणले प्रकाश गर्ने र निर्विकार छ । यसले सुख र ज्ञानको आशक्तिबाट प्राणीलाई बाँध्दछ ।   14.7",

    "हे कुन्तीपुत्र! तृष्णा र आसक्ति लाई उत्पन्न गर्ने भएको रजोगुण लाई तिमी रागस्वरूप समझ । यसले कर्महरुको आशक्तिबाट प्राणीलाई बन्धनमा (जन्ममरणका चक्करमा) पार्दछ ।  14.8",

    "हे भारत! सम्पूर्ण देहधारिलाई मोहित गर्ने भएको तमोगुण लाई तिमी अज्ञानबाट उत्पन्न भएको समझ । यसले प्रमाद, आलस्य र निद्राद्वारा प्राणीहरुलाई बाँध्दछ । 14.9",

    "हे भारत! सत्त्वगुणले सुखमा र रजोगुणले कर्ममा प्राणीलाई आशक्ति उत्पन्न गर्दछ भने तमोगुणले ज्ञानलाई ढाकेर एवं प्रमादमा लगाएर प्राणीलाई आशक्ति उत्पन्न गर्दछ । 14.10",

    "हे भारत! रजोगुण र तमोगुणलाई दबाएर सत्त्व गुण हुन्छ, सत्त्वगुण र तमोगुणलाई दबाएर रजोगुण हुन्छ । यस्तै सत्त्वगुण र रजोगुण लाई दबाएर तमोगुण हुन्छ । 14.11",

    "जब यस मनुष्य-शरीरका सबै द्वारहरु (इन्द्रियहरु र अन्तःकरण) मा प्रकाश (स्वच्छता) र ज्ञान उत्पन्न हुन्छ, तब यो संझनु पर्दछ कि सत्त्वगुण बढेको छ । 14.12",

    "हे कुरुनन्दन! तमोगुणको बृध्दि भएमा अन्धकार/अज्ञान (अप्रकाश), केहि नगर्नाको इच्छा, प्रमाद र मोह – यी सब नै उत्पन्न हुन्छन् । 14.13",

    "यदि देहधारीले सत्वगुण बढेको बखतमा मृत्युलाई प्राप्त गर्दछ भने उत्तम कर्म गर्नेहरुको दिव्य लोक (देवता आदिको निर्मल स्वर्ग आदि) त्यसलाई प्राप्त हुन्छ । 14.14",

    "रजोगुणको प्रबलता भएको समयमा मृत्यु हुनेले कर्महरुमा आशक्त योनिमा जन्म लिन्छ तथा तमोगुणको वृध्दिमा मृत्यु हुनेले पशु, पंक्षी आदिको मूढ़योनिमा  जन्म लिन्छ । 14.15",

    "पूण्यकर्मको फल  सात्त्विक र निर्मल फल हुन्छ तर राजस कर्मको फल दुःख र तामस कर्मको फल अज्ञान हुन्छ,  भनिएको छ ‌। 14.16",

    "सत्त्वगुणबाट ज्ञान र रजोगुणबाट लोभ आदि उत्पन्न हुन्छ; तमोगुणबाट प्रमाद, मोह एवं अज्ञान उत्पन्न हुन्छ । 14.17",

    "सात्त्विक गुण भएका पुरुषहरु माथिको अर्थात् स्वर्गादि लोकहरुमा जान्छन्, राजसिक गुण भएका पुरुषहरु मध्यलोकमा अर्थात् मनुष्यलोकमा रहन्छन् र निच गुण वृत्ति भएका तामसी पुरुषहरु अधोगतिमा अर्थात् झन् झन् निचो गतिमा जान्छन् ।  14.18",

    "जब द्रष्टा पुरुषले यी तीन गुण वाहेक दोस्रो अरु कुनै कर्तालाई देख्दैन र  यी गुणहरु भन्दा पर रहेको तत्व (ब्रह्म) लाई पनि जान्दछ, तब त्यो मेरो स्वरूपमा मिल्न जान्छ अर्थात् प्राप्त हुन्छ । 14.19",

    "देहधारीले (विवेकी पुरुषले) यी देह देखि उत्पन्न भएका तीन गुणहरुलाई अतिक्रमण गरेर जन्म, मृत्यु र वृध्दावस्थाका  दुःखहरुबाट छुटकारा पाएर ब्रह्मपद प्राप्त अर्थात् मोक्षको  अनुभव गर्दछ । 14.20",

    "अर्जुन विन्ती गर्दछन् – हे प्रभु! यी तीनै गुणहरु बाट अतीत भएको पुरुष कुन कुन लक्षणहरुबाट युक्त हुन्छ? उसका आचरण कस्ता हुन्छन्? र यी तीनै गुणहरु लाई अतिक्रमण कसरि गर्न सकिन्छ?  14.21",

    "श्री भगवान् आज्ञा गर्नु हुन्छ– हे पाण्डव! प्रकाश, प्रवृत्ति, मोह अर्थात् सत्त्व, रज, तम यी तीन गुणका कार्य वा फलहरु आएमा गुणातित पुरुषले तिनको द्वेष पनि गर्दैन र नआएमा तिनको आकांक्षा (चाहना) पनि गर्दैन । 14.22",

    "जो उदासीनका झै स्थित भई गुणहरुद्वारा विचलित गरिन्दैन तथा गुणहरु आफ्ना आफ्ना कार्यमा प्रवृत्त रहन्छन् भन्ने संझेर स्थिर रहन्छ, जो डग्दैन अर्थात् चलायमान हुँदैन, उही गुणातीत हुन् । 14.23",

    "सुख र दुःखलाई सम (बराबर, एकै) सम्झने, स्वस्थ्य, माटो, ढुंगा (पत्थर), सून सवैलाई  एकै समान मान्ने, प्रिय र अप्रियलाई बराबर मान्ने, आफ्नो निन्दा र स्तुति लाई पनि समान मान्ने । (यस्ता पुरुष गुणातीत हुन्)। 14.24",

    "मान र अपमानलाई समान सम्झने, मित्र र शत्रुका पक्षलाई समान मान्ने र त्यो सर्वारम्भ परित्यागी पुरुष गुणातीत कहिन्छ । 14.25",

    "र जो पुरुष अनन्य भक्तियोगले मलाई भज्दछ, मेरो सेवा गर्दछ, त्यो पुरुष यी गुणहरुलाई पार  (अतिक्रमण) गरेर ब्रह्म प्राप्तिको योग्य हुन जान्छ । 14.26",

    "किनकि अविनाशी ब्रह्मको, अमृतको, शाश्वत (सनातन) धर्मको र एकान्त सुखको म नै आश्रय हूँ ।  14.27",

    "यस प्रकार ॐ तत् सत् - यी भगवन्नामहरुको उच्चारणपूर्वक ब्रह्मविद्या र योगशास्त्रमय श्रीमद्भगवद्गीताको उपनिषद् रूप श्रीकृष्ण र अर्जुनको संवाद स्वरुप ‘गुणत्रयविभागयोग’ नाम भएको चौधौं अध्याय पूर्ण भयो ।"    
  ],
  "adhyaya-15" : [
    "श्रीभगवान् भन्नु हुन्छ - आदिपुरुष परमेश्वररूप मूलवाला र ब्रह्मरूप मुख्य शाखावाला यो संसाररूपी पीपलको वृक्षलाइ अविनाशी भन्दछन्, जसको  पातलाइ वेद भनिएको छ  ।यस्तो संसाररूपी वृक्षको तत्त्वलाई जो पुरुष जान्दछ , त्यो वेद को तात्पर्य लाइ जानने वाला वेदवेत्ता हो ।  15.1",

    "यो संसाररुपी वृक्षका तीन गुणहरूरूप जलले सेचनभइ बढ़ेको, विषय-भोगरूप कोपिला भएको, देव, मनुष्य र तिर्यक् आदि योनिरूप शाखाहरू तल र माथि सवैतिर फैलीएका छन्, मनुष्यलोक मा कर्महरुका अनुसार बाँध्ने वाला माया-ममता र वासनारूपी जराहरू तल र माथि संसारमा व्याप्त भएर फैलिइ रहेछन् । 15.2",

    "यस संसाररुपी वृक्षको स्वरूप यहाँ उपलब्ध हुदैन, किनकी यसको न त आदि, न त अन्त्य र न त यसको आधार स्थान नै छ । यसकारण यो अहंकार, माया र वासनारूपी अति दृढ़ मूल भएको संसाररूपी पीपलको वृक्षलाइ वैराग्यरूपी शस्त्रले काटेर — 15.3",

    "त्यस परम- पदरूपी परमेश्वरलाई भलीभाँति खोज्नु पर्दछ, जसमा गएका पुरुष फेरि फर्किएर संसारमा आउदैनन् र जुन परमेश्वरबाट यो पुरातन संसार-वृक्षको प्रवृत्ति उत्पन्न भइरहेछ, त्यही आदिपुरुष नारायणको  शरणमा म छु – भन्ने दृढ़ निश्चय गरेर त्यो परमेश्वरलाइ मनन र रुप ध्यान गर्नु पर्दछ ‌। 15.4",

    "जसको मान र मोह नष्ट भएको छ, जसले आशक्तिरूपी दोषलाइ जीतेको छ, उनिहरु परमात्माको स्वरूपमा नित्य स्थिर भएर रहेका हुन्छन् र जसको कामनाहरु पूर्णरूपबाट नष्ट  भइ सकेको छ – ती सुख-दुःख नामक द्वन्द्वहरू बाट टाढा भएका ज्ञानी पुरुषहरु त्यो अविनाशी परमपद स्थानमा पुग्दछन् । 15.5",

    "जुन परमपदलाइ प्राप्त गरेर फेरि फर्किएर आउनु पर्दैन, त्यो स्वयंप्रकाश परमपदलाई न सूर्य, न चन्द्रमा, न अग्निले प्रकाशित गर्न सक्दछन्, उहि नै मेरो परमधाम हो । 15.6",

    "यस देहमा यो सनातन जीवात्मा मेरो नै अंश हो र त्यही यो प्रकृतिमा रहेको मन र पाँच इन्द्रियलाइ आकर्षित गर्दछ (आफ्नो मान्दछ)। 15.7",

    "वायुले गन्धको स्थानबाट गन्धलाई जसरी  लिएर जान्छ, त्यसरी नै देहधारी जीवात्माले पनि जुन शरीरलाई त्याग गर्दछ, उस्ले यो मनसहितको इन्द्रियलाइ  ग्रहण गरेर फेरी जुन शरीर प्राप्त हुन्छ–त्यसमा  जान्छ । 15.8",

    "यो जीवात्मा कान, आँखा, छाला, जिब्रो, नाक र मनमा बसेर नै विषयहरूलाई भोग गर्दछ। 15.9",

    "शरीर  छोड़ेर जानेलाइ वा शरीर मा रहने लाई  वा विषयहरूको भोग गर्नेलाई वा यो तिनै गुणों ले  युक्त भएकालाई अज्ञानी मानिसहरु जान्दैनन्, केवल ज्ञानरूपी नजरले विचार गर्ने विवेकशील ज्ञानीले नै यो तत्त्वलाई जान्दछन् । 15.10",

    "यत्न गर्ने वाला योगी व्यक्तिले नै आफ्नो हृदयमा रहेको यो आत्मा  तत्त्वलाइ जान्दछन्; तर जसले आफ्नो अन्तःकरण लाई शुध्द बनाएको हुदैन, त्यस्तो अज्ञानी ब्यक्तिले जति नै यत्न गरे पनि यो आत्मालाई जान्दैनन् , देख्न सक्दैनन् । 15.11",

    "सूर्य मा रहेको जुन तेजले सारा जगतलाई प्रकाशित गर्दछ र जुन तेज चन्द्रमा एवं  अग्निमा पनि छ – त्यो सबै तेज मेरै हो भनेर संझ । 15.12",

    "यस्तै गरी म नै पृथ्वी मा प्रवेश गरेर आफ्नो शक्तिबाट सबै भूत (प्राणी) लाइ धारण गर्दछु र रसरूपी अर्थात् अमृतमय चन्द्रमा  भएर सम्पूर्ण औषधि अर्थात् धान्यादि वनस्पतिहरू लाई बढाउछु/ पुष्ट गर्दछु। 15.13",

    "म नै सबै प्राणिहरूको शरीरमा रहेर प्राण र अपान वायुले युक्त, \"वैश्वानर\" नामको अग्नि द्वारा चार प्रकारको  अन्नलाई  पचाउदछु।   15.14",

    "म नै सबै प्राणिहरूको हृदयमा अन्तर्यामी रूपमा रहन्छु । स्मरण र ज्ञान, यिनको नास पनि ममा नै रहन्छ, त्यस्तै सबै वेदद्वारा जान्ने योग्य पनि म नै हूँ । अनि वेदान्तकर्ता र सबै वेदलाई  जानने वाला पनि केवल म नै एक मात्र हूँ ।  15.15",

    "यो संसार मा नाशवान् र अविनाशी यी दुई प्रकार का पुरुष छन् । यसमा सम्पूर्ण भूतप्राणिहरुको  शरिर नाशवान र जीवात्मा अविनाशी कहिन्छ। 15.16",

    "यिनिहरु दुई भन्दा उत्तम पुरुष ता अन्य नै छ जुन तीनै लोकमा प्रवेश गरेर सबैको धारण, पोषण गर्दछ र अविनाशी ईश्वर परमात्मा भनी कहलिएको छ। 15.17",

    "किनकी म नाशवान जडवर्ग-क्षेत्रबाट त सर्वथा पर छु र अविनाशी जीवात्मा बाट पनि उत्तम पुरुष छु, यसकारण लोक र वेद मा  पुरुषोत्तम नामबाट प्रसिध्द छु।    15.18",

    "हे भारत! जुन ज्ञानी पुरुष मोहलाई त्यागेर मलाई यस्तो प्रकारले  पुरुषोत्तम भनेर जान्दछ, त्यो सर्वज्ञ भएर, सबै तरहले नित्य निरन्तर म वासुदेव परमेश्वरलाई नै भज्दछ ।   15.19",

    "हे पापरहित भारत ! यसरी यो अति रहस्ययुक्त गोप्य शास्त्र मैले बर्णन गरे । यसको तत्त्व जानेर मनुष्य बुध्दिवान् र कृतकृत्य पनि हुने छ। 15.20",

    "यस प्रकार ॐ तत् सत् - यी भगवन्नामहरुको उच्चारणपूर्वक ब्रह्मविद्या र योगशास्त्रमय श्रीमद्भगवद्गीताको उपनिषद् रूप श्रीकृष्ण र अर्जुनको संवाद स्वरुप ‘पुरूषोत्तमयोग’ नाम भएको पन्ध्रौं अध्याय पूर्ण भयो ।"    
  ],
  "adhyaya-16" : [
    "श्रीभगवानले आज्ञा गर्नुभयो - अभय (नडराउनु); अन्तःकरणको अत्यन्त शुध्दता; ज्ञानयोग र कर्मयोगको तारतम्य सहितको ब्यवस्थापन; दान दिनु; इन्द्रियहरुको दमन; यज्ञ; स्वाध्याय अर्थात् स्वधर्म अनुसार दैनिक पठन पाठन गर्नु; तपस्या गर्नु र शरिर-मन-वाणी (अन्त:करण) को सरलता। 16.1",

    "अहिंसा, सत्य बोल्नु, नरिसाउनु, कर्म फललाई त्याग गर्नु, शान्ति लिनु, अर्काको निन्दा नगर्नु, सबै प्राणीमा दयाको भाव राख्नु, तृष्णा नराख्नु, मृदुता, खराब काम गर्न लजाउनु,  अन्तःकरणको कोमलता, फजुल कर्महरुलाई छोड्नु। 16.2",

    "तेज (प्रभाव), क्षमा, धैर्य, शरीर को शुध्दता, द्रोह नगर्नु र घमण्डीपना नराख्नु, हे भारत ! यी सबै २६ गुणहरू दैवी सम्पत्तिमा जन्मेका पुरुषहरुका लक्षण हुन्। 16.3",

    "हे पृथानन्दन ! दम्भ (छल) गर्नु, घमण्ड गर्नु, अभिमान गर्नु, रिसाउनु, निष्ठुरी  र अज्ञान हुनु - यी ६ गुण  आसुरी सम्पत्तिमा जन्मेका पुरुषहरुका लक्षण हुन्। 16.4",

    "दैवी सम्पत्ति मुक्तिको लागि र आसुरी सम्पत्ति बन्धनको लागि  मानीएको छ । अतः हे पाण्डव! तिमिले दैवी सम्पत्ति प्राप्त गरेका छौ, यसकारण शोक (चिन्ता) नगर। 16.5",

    "यस संसारमा दुई  प्रकारका स्वभाव भएका प्राणिहरुको सृष्टि भएको छ -- दैवी र आसुरी ।   दैवी स्वभाव को बारेमा त मैले यस अघि नै वर्णन गरि सके । अब हे पार्थ! तिमीले म बाट आसुरी स्वभावको बारेमा विस्तारपूर्वक सुन। 16.6",

    "आसुरी स्वभाव भएका मानिसहरू के मा  प्रवृत्ति हुनु पर्छ र के मा निवृत्ति हुनु  पर्दछ, यसलाई   जान्दैनन् साथै यिनिहरुमा न त बाह्य शुध्दता हुन्छ, न त श्रेष्ठ आचरण एवं  सत्य-पालन नै  हुन्छ। 16.7",

    "यी भन्दछन् कि संसार असत्य, मर्यादा बिनाको, ईश्वर बिनाको, आफसे-आफ, केवल स्त्री-पुरुष को विषय वासनाको निमित्त, संयोगबाट उत्पन्न भएको हो । यसकारण विषय वासना नै  यसको कारण हो, यसको तृप्ति गर्नु शिवाय अरु के कारण छ? (अरु केहि कारण हुन नै सक्दैन।) 16.8",

    "यस (पूर्वोक्त) (नास्तिक) दृष्टिलाई अवलम्बन गरेर जुन मानिसहरू आफ्नो नित्य स्वरूपलाई जान्दैनन्, जसको अल्पबुध्दि छ, जसले क्रुर कर्म गर्दछन् र यी मानिसहरुको सामर्थ्यको उपयोग संसार लाई नाश गर्न को लागि नै उत्पन्न हुन्छ। 16.9",

    "कहिल्यै पूरा नहुने कामनाहरुको आश्रय लिएर दम्भ, अभिमान र मदले युक्त भएर मिथ्या सिध्दान्तलाई ग्रहण गरि अर्थात् मनमोजी कल्पना गर्दै खराब काम गर्न निमित्त आसुरी व्यक्तिहरु प्रवित्त हुन्छन्। 16.10",

    "यस्तै किसिमले आमरणान्त सुखभोगका लागि अगणित चिन्ताले  ग्रसित भएका, कामोपभोगमा डुबेका र निश्चय पूर्वक त्यहि विषय वासनाको तृप्ति लाई नै सर्वोपरि मानिरहेका- 16.11",

    "यस्ता यी आसुरी स्वभावका मानिसहरु सयकडौं आशापाशहरुले जकडिएका, काम-क्रोधमा तत्पर रहेका, विषय भोगको (मोज मजाको) निमित्त अन्याय पूर्वक धेरै धन लुटेर सञ्चय गर्ने  चेष्टा गर्दछन्। 16.12",

    "यिनिहरु यसप्रकारका मनोरथ गर्ने गर्दछन् कि - यति वस्तुहरू त मैले आज प्राप्त गरे  (र अब) यो मनोरथलाई सिध्द (पूरा) गरिहाल्छु । यति  धन त म संग छदै छ,  यति धन भविष्यमा  फेरि पनि मेरो हातमा प्राप्त भइ हाल्छ। 16.13",

    "यस शत्रुलाई त मैले मारे, यस्तै अरुलाई पनि मारुँला । मै ईश्वर (मालिक) हुँ । मै भोगी,  मै सिध्द, मै बलवान र मै सुखी पनि हुँ। 16.14",

    "म धनवान छु, धेरै मानिसहरु म सँग सम्पर्कमा छन्, मेरो तुल्य अरु दोश्रो को छ र? म धेरै यज्ञ गर्नेछु, दान दिने छु र मोजमजा गर्ने छु  - यस प्रकारसंग ती अज्ञानले मोहित भएर रहन्छन्।  16.15",

    "(कामनाहरुको कारण) अनेक प्रकारले चित्तमा भ्रम भएका, मोह-जालका फन्दामा नराम्ररी  फँसेका  र  पदार्थहरूको विषय भोगमा अत्यन्त आसक्त हुने यिनी आसुरी स्वभावका मानिस भयंकर नरक(दु:ख) मा पर्दछन् (धसिन्छन्। 16.16",

    "आफै आफूलाई प्रशंसा गर्ने, हठी, धन र मानका मदले युक्त भएका, यी आसुरी स्वभावका मानिस शास्त्रमा तोकेको विधिलाई छोडेर दम्भले खाली नाममात्रको यज्ञ  गर्दछन्। 16.17",

    "ती अहंकार, बल, हठ, घमण्ड, कामना र क्रोधका आश्रित मानिस, आफ्नो र अरुका शरीरमा स्थित   म अन्तर्यामी भगवान् लाई द्वेष (निन्दा) गर्दछन्  तथा (मेरो र अन्यको गुणहरूमा) दोष दृष्टि राख्दछन्। 16.18",

    "ती द्वेष गर्ने, पापाचारी, क्रूर स्वभाव भएका,  नराधमहरुलाई, म बारम्बार आसुरी योनिमा नै फ्याँकि दिन्छु/ जन्म दिन्छु ।  16.19",

    "हे कुन्तीनन्दन !  ती मूढहरु मलाई प्राप्त नगरेर जन्म-जन्मान्तर सम्म आसुरी योनिलाई नै प्राप्त गर्दछन्, फेरि त्यो भन्दा पनि धेरै अधोगतिका साथमा भयंकर नरकमा पुग्दछन्। 16.20",

    "काम, क्रोध र लोभ - यी तीन प्रकारका नरक का ढोका नै जीवात्माका नाशकारक हुन्,  यसकारण यी तीनैलाई त्याग गर्नु पर्दछ। 16.21",

    "हे कुन्तीनन्दन ! यी नरकका तीनै  द्वारबाट मुक्त भएर जुन मानिस आफ्नो कल्याणको आचरण गर्दछ, त्यसपछि उसलाई उत्तमगति प्राप्त हुन्छ वा उसले उत्तमगति लिन सक्दछ। 16.22",

    "जुन मानिस शास्त्र विधिलाई छोड़ेर आफ्नै इच्छाबाट मनमानी आचरण गर्दछ, त्यसले न सिध्दि (अन्तःकरण को शुध्दि)लाई, न सुख (शान्ति)लाई, न उत्तमगति (मुक्ति) लाई नै प्राप्त गर्दछ  अर्थात् त्यसले केही पनि पाउँदैन। 16.23",

    "अतः तिमीले कर्तव्य-अकर्तव्य को व्यवस्थाको लागि शास्त्रलाई नै प्रमाण मान्नु पर्दछ,  यस्तो जानेर तिमी यो संसारमा शास्त्रमा जे वताएको छ, त्यसलाई राम्ररी संझेर नियमित कर्तव्य-कर्म गर्न योग्य छौ अर्थात् तिमीले शास्त्रविधि लाई राम्ररी संझेर त्यसकै अनुसार यस संसारमा कर्तव्य-कर्म गर्नु उचित छ। 16.24",

    "यस प्रकार ॐ तत् सत् - यी भगवन्नामहरुको उच्चारणपूर्वक ब्रह्मविद्या र योगशास्त्रमय श्रीमद्भगवद्गीताको उपनिषद् रूप श्रीकृष्ण र अर्जुनको संवाद स्वरुप ‘दैवासुरसम्पदवोभागयोग’ नाम भएको सोह्रौं अध्याय पूर्ण भयो ।"     
  ],
  "adhyaya-17" : [
    "अर्जुन बिन्ती गर्दछन् - हे कृष्ण ! जो मानिस शास्त्रको विधिलाई छोडेर श्रध्दापूर्वक (देवता आदिलाई) यज्ञ (पूजा) गर्दछन्, तिनको निष्ठा फेरि के कस्तो हुन्छ? सात्त्विकी या राजसी या तामसी कस्तो हुन्छ।  17.1",

    "श्री भगवान आज्ञा गर्नुहुन्छ - मानिसहरुको यो स्वभावबाट उत्पन्न भएको श्रध्दा सात्त्विकी, राजसी र तामसी - यस्ता तिन प्रकारको नै हुन्छन् । यिनको बारेमा तिमी म बाट सुन। 17.2",

    "हे भारत ! सबै मानिसहरुको श्रध्दा अन्तःस्करणको स्वभाव अनुसार हुन्छ । यो मनुष्य श्रध्दामय छ, (यसकारण) जसको जस्तो श्रध्दा रहन्छ, उस्को स्वभाव त्यस्तै नै हुन्छ अर्थात् त्यही उसको स्वरुप (स्थिति) हुन्छ।  17.3",

    "सात्त्विक (सत्वगुणी) पुरुष देवताहरूको पूजा गर्दछन्, राजसी (रजोगुणी) पुरुष  यक्षहरु र राक्षसहरुलाई, यीनीवाहेकका अन्य जो तामसी (तमोगुणी) पुरुष छन्, उनिहरु प्रेत र भूतगणहरुको पूजा गर्दछन्।  17.4",

    "जो मनुष्य शास्त्रविधि रहित भएर घोर तप गर्दछन्; (जो) दम्भ र अहंकारबाट युक्त छ ; (जो) भोग- पदार्थ, आसक्ति र हठले युक्त छ। 17.5",

    "त्यस्तै जो मानिस शरीरमा भएका पञ्चमहाभूतहरुको समूहलाई अर्थात् पञ्च भौतिक शरीरलाई र अन्तःस्करणमा भएको मलाई पनि कष्ट दिन्छन् ती अज्ञानीहरुलाई (तिमी) आसुरी बुध्दिले युक्त असुर हुन् भनेर जान । 17.6",

    "आहार (भोजन) पनि सबैलाई तीन प्रकारको नै प्रिय (मन पर्ने) हुन्छ  र त्यस्तै यज्ञ, तप, दान पनि तीन प्रकार को हुन्छन् अर्थात् शास्त्रीय कर्महरुमा पनि गुणहरुलाई लिएर तीनै प्रकारका रुचि हुन्छन्, तिमी यिनको यस प्रकारको फरक (भेद) लाई सुन, म बताउँछु । 17.7",

    "आयु, सत्त्वगुण, बल, आरोग्य, सुख र प्रसन्नता बढ़ाउने, स्थिर अर्थात् शरीरमा भिजेर धेरै कालसम्म अडिने, मनलाई आनन्द दिने, रसिला, चिल्ला  यस्ता आहार अर्थात् भोजन गरिने पदार्थ सात्त्विक मनुष्यलाई मन पर्ने (प्रिय) हुन्छन् । 17.8",

    "ज्यादा तीतो, ज्यादै अमिलो, अति नून चर्को, ज्यादा तातो, तीख्खर, अति रूखो र अति दाहकारक आहार अर्थात् भोजन गर्ने पदार्थ राजसी मनुष्यले निको (प्रिय) मान्दछ, जुन आहार दुःख, शोक र रोगहरूलाई जब्जाउने प्रकृतिको (खाल्को) हुन्छ । 17.9",

    "जो भोजन राम्ररी नपाकेको, सड़ेको, रस नभएको, दुर्गन्धित, बासी र जूठो छ, तथा जो एकदमै अपवित्र (मासु आदि) छ, त्यो तामसी पुरुषलाई मनपर्ने (प्रिय) हुन्छ ।  17.10",

    "यज्ञ गर्नु नै आफ्नो कर्तव्य सम्झि मनलाई संतुष्ट बनाएर फलको ईच्छा नराखि मनुष्यहरुद्वारा शास्त्रविधिले  नियत गरेको जो यज्ञ गरिन्छ, त्यो सात्त्विक यज्ञ हो।  17.11",

    "परन्तु हे भरतश्रेष्ठ अर्जुन ! जो यज्ञ फलको इच्छा राखेर अथवा दम्भ (देखावटीपन) को लागि गरिन्छ, त्यो यज्ञलाई तिमी राजस यज्ञ भनेर सम्झ। 17.12",

    "शास्त्र विधिबाट हीन, अन्न-दान विहिन, बिना मन्त्रको, बिना दक्षिणाको र  श्रध्दारहित यज्ञलाई तामस यज्ञ भन्दछन्। 17.13",

    "देवता, ब्राह्मण, गुरु र विद्वान महापुरुषहरुको यथायोग्य पूजा गर्नु, शुध्द राख्नु, सरलता, ब्रह्मचर्यलाई पालन गर्नु र हिंसा नगर्नु  - यीनलाई  शरीरिक तामास तप भन्दछन्।  17.14",

    "जो मनलाई दिग्दार (उद्वेग) नगर्ने, सत्य, प्यारो (प्रिय) तथा हितकारक वचन छ, त्यो स्वाध्याय र अभ्यास (नाम, जप आदि) लाई वाचिक तप (बोलीले गरिने तपस्या) भन्दछन्। 17.15",

    "मन खुशी (प्रसन्नता) राख्नु, शान्तभाव, मौन धारन,  मनोनिग्रह र शुध्द भावना  लिनु, यस्ता यी मन-सम्बन्धी तपलाई मानसिक (मनले गरिने) तप कहिन्छन्।  17.16",

    "यीनै माथि बताईएका तीन किसिमका तपस्या पुरुषले परम श्रध्दाबाट, फलको ईच्छा नराखि, योगयुक्त बुध्दिले गर्दछ भने यी सबै तीन प्रकार (शरीर, वाणी र मन) का  तपलाई सात्त्विक तप भन्दछन्। 17.17",

    "जुन तप आफ्नो सत्कार, मान र पूजाको लागि तथा देखावटी भावबाट गरिन्छ, यसलोकमा अनिश्चित र नाशवान फल दिनेवाला त्यो तपलाई शास्त्रहरुमा राजस भनिएको छ। 17.18",

    "जुन तप मूढ़तापूर्वक हठ गरि आफैंलाई पीड़ा दिएर अथवा अरुलाई कष्ट (अनिष्ट) दिनको लागि (अर्कालाई सताउनको लागि) गरिन्छ, त्यो तपलाई तामस तप कहिएको छ।  17.19",

    "दान दिनु नै आफ्नो कर्तव्य हो - यस्तो भावबाट  जुन दान देश (स्थान), काल (समय) र आफुलाई प्रत्युपकार नगर्ने पात्रमा दिइन्छ अर्थात् निष्काम भावबाट दिएको हुन्छ, त्यो दानलाई सात्त्विक कहिएको छ।  17.20",

    "तर जुन दान क्लेशपूर्वक र प्रत्युपकारको लागि अथवा फल-प्राप्ति को उद्देश्य लिएर फेरि दिइन्छ, त्यो दानलाई राजस दान कहलिएको छ। 17.21",

    "जुन दान सत्कार नगरि अर्थात् अपहेलना (तिरस्कार) गरि अयोग्य स्थान (देश) र अयोग्य समय (काल) मा कुपात्र पुरुषलाई दिइन्छ, त्यो दान तामस कहलाउँछ। 17.22",

    "शास्त्रमा ऊँ, तत् र सत् - यस्तो तीन प्रकारका नामहरुबाट जुन परब्रह्मको संकेत गरिएको छ, उनै परब्रह्मको निर्देशबाट सृष्टिको शुरुवातमा ब्राह्मण, वेद र यज्ञ पनि उत्पत्ति भएका हुन्। 17.23",

    "यसकारण वैदिक सिध्दान्तहरुलाई मान्नेवाला ब्रह्मवादीहरुको शास्त्रोक्त यज्ञ, दान र तपका क्रियाहरु सधैं 'ॐ’ को उच्चारण गरेर प्रारम्भ हुन्छ। 17.24",

    "तत् सत्' शब्दको उच्चारणले फलको इच्छा नराखेर, मोक्षार्थी पुरुषहरु नाना प्रकारका यज्ञ, दान र तप रुपी क्रिया (कर्म) हरु गरिरहन्छन्। 17.25",

    "हे पार्थ ! सत्-यस्तो यो परब्रह्मको नाम असल कर्ममा र श्रेष्ठ भावमा तथा प्रशंसनीय कर्मका साथमा 'सत्' शब्दको प्रयोग गरिन्छ । 17.26",

    "यज्ञ, तप र दान को रूप क्रियामा जो स्थिर भावना (निष्ठा) राखिएको छ, त्यो पनि 'सत्' हो  - यस्तो कहिन्छ र त्यो परब्रह्मको निमित्त गरिने कर्म पनि 'सत्' हो भनी कहिन्छ । 17.27",

    "हे पार्थ ! अश्रध्दाले गरिएको हवन, दिएको दान र गरिएको तपस्या तथा अरु पनि जो कुछ कर्म गरिन्छ , त्यो सबैलाई 'असत्' भनिन्छ । यसको   फल न त यो लोकमा, न त मृत्यु पर्यन्त परलोकमा अर्थात् त्यसको फल कहिल्यै र कहिँ पनि लाभदायक (हितकारी) हुदैँन। 17.28",

    "यस प्रकार ॐ तत् सत् - यी भगवन्नामहरुको उच्चारणपूर्वक ब्रह्मविद्या र योगशास्त्रमय श्रीमद्भगवद्गीताको उपनिषद् रूप श्रीकृष्ण र अर्जुनको संवाद स्वरुप ‘श्रद्धात्रयविभागयोग’ नाम भएको सत्रौं अध्याय पूर्ण हुआ।"
  ],
  "adhyaya-18" : [
    "अर्जुनले भने - हे महाबाहो ! हे हृषीकेश ! हे केशिनिषूदन ! म संन्यास र त्यागको तत्त्वलाई छुट्टा-छुट्टै जान्न चाहन्छु। 18.1",

    "श्रीभगवान् ले भन्नुभयो-- कति विद्वानहरूले त काम्यकर्महरुको त्यागलाई संन्यास भन्ने सम्झन्छन् र कति विद्वानहरुले सम्पूर्ण कर्महरुका फलको त्यागलाई त्याग भनेर भन्दछन्। 18.2",

    "कति विद्वानले यस्तो भन्दछन् कि कर्ममात्र दोषयुक्त छन्, यसर्थ त्याग्नयोग्य छन्। अर्काथरी विद्वान भने यस्तो भन्दछन् कि यज्ञ, दान र तपरूपी कर्मलाई त्याग्नयोग्य छैन्। 18.3",

    "हे भरतवंशिहरुमा श्रेष्ठ अर्जुन ! तिमी संन्यास र त्याग - यी दुईमध्ये पहिला त्यागको विषयमा मेरो निश्चय सुन, किनकि हे पुरुषश्रेष्ठ! त्याग सात्विक, राजस र तामस तीन प्रकारका मानिएका छन्। 18.4",

    "यज्ञ, दान र तपरूपी कर्म त्याग्नयोग्य छैनन्, बरु तिनीहरू अनिवार्यरुपमा गर्नु पर्ने खालका छन्, किनकि यज्ञ, दान र तप - यी तीनै कर्महरु बुध्दिमान पुरुषहरुलाई पवित्र गर्नेवाला हुन्। 18.5",

    "यसर्थ हे पार्थ ! यी यज्ञ, दान र तपरूपी कर्महरुलाई अन्य सम्पूर्ण कर्तव्यकर्महरुप्रति आसक्ति र फलहरुको इच्छा त्यागेर अवश्य गर्नुपर्दछ - यो मैले निश्चय गरेको उत्तम मत हो। 18.6",

    "तर नियत कर्मलाई त त्याग गर्नु उचित हुदैन। त्यसलाई मोहपूर्वक त्याग गर्नु तामस भनेर भनिएको छ। 18.7",

    "जे-जति कर्म छ, त्यो सबै दुःखरूप नै छ - यस्तो सम्झिएर कोहि शारीरिक क्लेशको भयका कारण कर्तव्यकर्महरुको त्याग गर्दछ भने उसले यस्तो राजस त्याग गरेर पनि त्यागको फललाई कुनै पनि प्रकारले प्राप्त गर्दैन। 18.8",

    "हे अर्जुन ! 'केवल कर्तव्य मात्र गर्नु छ' -- यस्तो सम्झिएर जसले कर्ममा आसक्ति र फलको इच्छालाई त्याग गरेर गरिन्छ, त्यसैलाई सात्त्विक त्याग मानिएको छ। 18.9",

    "जसले अकुशल कर्मसित द्वेष गर्दैन र कुशल कर्ममा आसक्त हुदैन, त्यो शुध्द सर्वगुणले युक्त पुरुष त्यागी, बुध्दिमान्, संशयरहित र आफ्नो स्वरूपमा रहने सच्चा त्यागी हो। 18.10",

    "किनकि शरीरधारी मनुष्यद्वारा सम्पूर्ण कर्महरुको त्याग गर्न सम्भव छैन। यसर्थ जो कर्मफलको त्यागी छ, उसैलाई त्यागी भनेर भनिन्छ। 18.11",

    "कर्मफलको त्याग नगर्ने मनुष्यका कर्महरुका त राम्रा-नराम्रा र मिश्रित भएका गरी यस्तो तीन प्रकारको फल मरेपछि अवश्य हुन्छ, तर कर्मफलको त्यागगर्ने मानिसको कर्मको फल कुनै पनि कालमा हुदैन। 18.12",

    "हे महाबाहो ! कर्महरुको अन्त गर्न उपाय बताइने सांख्यशास्त्रमा सम्पूर्ण कर्महरुको सिद्धिका लागि यी पाँच कारण उल्लेख गरिएका छन्, यीनलाई तिमी मबाट राम्रोसित जान। 18.13",

    "यस विषयमा अर्थात् कर्महरुको सिद्धिमा अधिष्ठान र कर्ता, विभिन्न प्रकारका करण एवं अनेक प्रकारको भिन्न-भिन्न चेष्टाहरु र यस्तै नै पाँचौं कारण दैव (संस्कार) हो। 18.14",

    "मनुष्यले शरीर, वाणी र मनद्वारा शास्त्रानुकूल अथवा शास्त्रविरुद्ध जे-जति पनि कर्म प्रारम्भ गर्दछ, त्यसका यीनै (पूर्वोक्त) पाँच कारण हुन्। 18.15",

    "तर, यस्तो पाँच कारणहरु भए पनि जसले त्यस कर्महरुका विषयमा केवल शुद्धस्वरुप आत्मालाई कर्ता ठान्दछ, त्यो अशुद्ध बुद्धिवाला अज्ञानीले यथार्थ बुझ्दैन, किनकि उसको बुद्धि शुद्ध हुदैन अर्थात् उसले विवेकलाई महत्व दिएको हुँदैंन। 18.16",

    "जुन पुरुषको अन्त:करणमा म कर्ता हूँ - यस्तो भाव हुदैन र जसको बुद्धि सांसारिक पदार्थहरुमा र कर्महरुमा लिप्त हुदैन, त्यो पुरुषले (युद्धमा) यी सम्पूर्ण प्राणीहरूलाई मारेर पनि वास्तवमा न त मार्दछ, न पापले बाधिन्छ नै। 18.17",

    "ज्ञान, ज्ञेय र ज्ञाता - यी तीन प्रकारको कर्म-प्रेरणा हुन् भने करण, कर्म र कर्ता - यी तीन प्रकारका कर्म-संग्रह हुन्। 18.18",

    "गुणहरुको संख्या उल्लेख गर्ने शास्त्रमा गुणहरुको भेद ज्ञान र कर्म तथा कर्ता तीन-तीन प्रकारबाट नै भनिएको छ, तिनलाई पनि तिमी वास्तविक रूपबाट सुन। 18.19",

    "जुन ज्ञानद्वारा मनुष्यले सम्पूर्ण विभक्त प्राणीहरुमा अविनाशी परमात्मभावलाई विभागरहित समभावले स्थित देख्दछ, त्यो ज्ञानलाई तिमीले सात्त्विक समझ (जान)। 18.20",

    "तर जुन ज्ञानद्वारा मनुष्यले सम्पूर्ण प्राणीहरुमा अलग-अलग प्रकारका अनेक भावहरुलाई फरक-फरक रूपबाट जान्दछ, त्यस ज्ञानलाई तिमी राजस भनेर जान (समझ)। 18.21",

    "तर जुन ज्ञानकाद्वारा मनुष्य एक कार्यरूप शरिरमा नै सम्पूर्ण किसिमले आसक्त रहन्छ तथा जुन युक्तिरहित, वास्तविक अर्थले रहित र तुच्छ छ, त्यसलाई तामस भनेर भनिएको छ। 18.22",

    "जुन कर्म शास्त्रविधिबाट निर्धारण गरिएका छ र कर्तृत्वाभिमानबाट रहित छ तथा फलेच्छारहित मनुष्यद्वारा राग-द्वेषबिना गरिएको छ, त्यसलाई सात्त्विक कर्म भनिन्छ। 18.23",

    "तर जुन कर्म भोगको इच्छा वा अहंकारबाट र परिश्रमपूर्वक गरिन्छ, त्यसलाई राजस कर्म भनिन्छ। 18.24",

    "जुन कर्म परिणाम, हानि, हिंसा र सामर्थ्यलाई विचार नगरिकन केवल अज्ञानपूर्वक आरम्भ गरिन्छ, त्यसकर्मलाई तामस भनेर भनिन्छ। 18.25",

    "जुन कर्ता सङ्गरहित, अहङ्कारयुक्त वचन नबोल्ने, धैर्य र उत्साहले युक्त तथा कार्यको सिद्धि हुनु र नहुनुमा एवं हर्ष-शोकादिमा निर्विकार छ, त्यसलाई सात्त्विक भनिन्छ। 18.26",

    "जुन कर्ता आसक्तिले युक्त, कर्मफल चाहने, लोभी, अरुलाई कष्ट दिने, अशुद्ध र हर्ष-शोकबाट युक्त छ, त्यसलाई राजस भनिएको छ। 18.27",

    "जुन कर्ता असावधान, अशिक्षित, घमण्डी, धुर्त, अरुको जीविकालाई नाश गर्ने, शोक गर्ने, अल्छी र दीर्घसूत्री छ, उ तामस हो। 18.28",

    "हे धनञ्जय! अब तिमी गुणहरुका अनुसार बुद्धि र धृतिको पनि तीन प्रकारका भेद अलग-अलग रूपबाट, जस्लाई मद्वारा सम्पूर्णताकासाथ भन्न गईरहेको छु, सुन। 18.29",

    "हे पृथानन्दन ! जुन बुद्धिले प्रवृत्तिमार्ग र निवृत्तिमार्गलाई, कर्तव्य र अकर्तव्यलाई, भय र अभयलाई अनि बन्धन र मोक्षलाई यथार्थसित जान्दछ, त्यो बुद्धि सात्त्विकी बुद्धि हो। 18.30",

    "हे पार्थ! मनुष्यले जुन बुद्धिद्वारा धर्म र अधर्मलाई, कर्तव्य र अकर्तव्यलाई पनि ठीक (यथार्थ) तरिकाले जान्दैन, त्यो बुद्धि राजसी हो। 18.31",

    "हे पृथानन्दन ! जुन तमोगुणबाट घेरिएको बुद्धिले अधर्मलाई पनि \"यो धर्म\" हो भनेर मान्दछ र सम्पूर्ण पदार्थहरुलाई पनि विपरीत मान्दछ, त्यो बुद्धि तामसी हो। 18.32",

    "हे पार्थ! समभावबाट युक्त मनुष्य जुन अव्यभिचारिणी धारणाशक्तिद्वारा मनुष्यले मन, प्राण र इन्द्रियहरुको क्रियाहरुलाई धारण गर्दछ अर्थात् संयम राख्दछ, त्यो धृति सात्त्विकी धृति हो। 18.33",

    "तर हे पृथापुत्र अर्जुन! फलको ईच्छा भएको पुरुषले जुन धारणाशक्तिका माध्यमले अत्यन्त आसक्तिका साथ धर्म, अर्थ र कामहरुलाई गर्दछ, त्यो धारणा राजसी हो। 18.34",

    "हे पार्थ! दुष्ट बुद्धिभएको मनुष्य जुन धृतिकाद्वारा निद्रा, भय, चिन्ता, दुःख र घमण्ड (उन्मत्तता) लाई पनि छोड़दैन अर्थात् धारण गरिरहन्छ, त्यो धृति तामसी हो। 18.35",

    "हे भरतश्रेष्ठ अर्जुन! अब तीन प्रकारका सुखलाई पनि तिमीले मबाट सुन। जुन सुखमा साधक पुरुषले भजन, ध्यान र सेवादिको अभ्यासले रमण गर्दछ र जसबाट दुःखहरुको अन्त हुन जान्छ, यस्तो त्यो परमात्मा - विषयक बुद्धिलाई प्रसन्नताबाट पैदा हुनेवाला जुन सुख (सांसारिक आसक्तिको कारण) आरम्भमा विष जस्तै प्रतीत हुन्छ तर परिणाममा अमृतसमान हुन्छ, यसर्थ त्यो परमात्मा विषयक प्रसाद द्वारा उत्पन्न हुने सुखलाई सात्त्विक मानिएको छ। 18.36-18.37",
    
    "हे भरतश्रेष्ठ अर्जुन! अब तीन प्रकारका सुखलाई पनि तिमीले मबाट सुन। जुन सुखमा साधक पुरुषले भजन, ध्यान र सेवादिको अभ्यासले रमण गर्दछ र जसबाट दुःखहरुको अन्त हुन जान्छ, यस्तो त्यो परमात्मा - विषयक बुद्धिलाई प्रसन्नताबाट पैदा हुनेवाला जुन सुख (सांसारिक आसक्तिको कारण) आरम्भमा विष जस्तै प्रतीत हुन्छ तर परिणाममा अमृतसमान हुन्छ, यसर्थ त्यो परमात्मा विषयक प्रसाद द्वारा उत्पन्न हुने सुखलाई सात्त्विक मानिएको छ। 18.36-18.37",
    
    "जुन सुख विषय र इन्द्रियहरुको संयोगले उत्पन्न हुन्छ, त्यो सुरुवाततिर भोगका समयमा अमृततुल्य भए पनि परिणाममा विषको जस्तो (विषयतुल्य) प्रतीत हुन जान्छ, त्यो सुखलाई राजस भनिन्छ। 18.38",

    "निद्रा, आलस्य र प्रमादबाट उत्पन्न हुनेवाला जुन सुख आरम्भमा र परिणाममा आत्मालाई मोहित गर्ने खालको छ, त्यो सुखलाई तामस मानिएको छ। 18.39",

    "पृथ्वी, आकाश र देवताहरुमा तथा यीबाहेक अन्यत्र कतै पनि यस्तो कुनै पनि वस्तु (तत्व) छैन, जो प्रकृतिबाट उत्पन्न यी तीनै गुणहरुले रहित होस्। 18.40",

    "हे परन्तप! ब्राह्मण, क्षत्रिय, वैश्य र शूद्रहरुका कर्म स्वभावले उत्पन्न भएका तीनै गुणहरुद्वारा विभक्त गरिएको छ। 18.41",

    "अन्त:करणको निग्रह गर्नु, इन्द्रियहरुलाई वशमा राख्नु, धर्मपालनको लागि कष्ट सहनु, भित्रबाहिरबाट शुद्ध रहनु, अर्काका अपराधलाई क्षमा गर्नु, शरीर, मन र इन्द्रिय आदिमा सरलता राख्नु, वेद, शास्त्र आदिको ज्ञान हुनु, परमात्मा तत्वको अनुभव गर्नु, यज्ञ, वेद आदिमा आस्तिक भाव राख्नु - यी सबैका सब ब्राह्मणका स्वाभाविक कर्म हुन्। 18.42",

    "शूरवीरता, तेज, धैर्य, प्रजाको संचालन आदिका निमित्त विशेष चतुरता, युद्धबाट कहिले पनि नभाग्नु, दान दिनु र स्वामीभाव - यी सबैका सब क्षत्रियका स्वाभाविक कर्म हुन्। 18.43",

    "खेती गर्नु, गाईको रक्षा गर्नु र व्यापार गर्नु - यी सबैका सब वैश्यका स्वाभाविक कर्म हुन्। साथै चारै वर्णहरूको सेवा गर्नु पनि शूद्रको स्वाभाविक कर्म हो। 18.44",

    "आ-आफ्ना कर्महरुमा प्रीतिपूर्वक लागेको मनुष्यले परमसिद्धि (परमात्मा)लाई प्राप्त गर्दछ। आफ्नो स्वाभाविक कर्ममा लागेको मनुष्यले जुन किसिमले कर्म गरी परमसिद्धिलाई प्राप्त गर्दछ, त्यस विधिलाई तिमीले मबाट सुन। 18.45",

    "जुन परमात्माबाट सम्पूर्ण प्राणिहरूको प्रवृत्ति (उत्पत्ति) भएको छ र जसबाट यो सम्पूर्ण जगत् व्याप्त छ, त्यस परमात्माको आफ्नो स्वाभाविक कर्महरुद्वारा पूजा गरेर मनुष्यले परमसिद्धिलाई प्राप्त गर्दछ। 18.46",

    "राम्रो तरिकाले आचरण गरिएको अर्काको धर्म भन्दा गुणरहित भएपनि आफ्नै धर्म श्रेष्ठ छ, किनकि स्वभावले नै निश्चित गरेको स्वधर्मरूपी कर्मलाई गर्दा मनुष्यलाई पाप लाग्दैन। 18.47",

    "हे कुन्तीनन्दन ! दोषयुक्त भए पनि सहज कर्मलाई त्याग्नु हुदैंन, किनकि सम्पूर्ण कर्म धुवाँबाट ढाकिएको अग्नि कै जस्तै कुनै न कुनै दोषले युक्त छन्। 18.48",

    "जसको बुद्धि सर्वत्र आसक्तिरहित छ, जसले शरीरलाई वशमा राखेको छ, जो स्पृहारहित छ, त्यो पुरुषले सांख्ययोगका माध्यमद्वारा सर्वश्रेष्ठ नैष्कर्म्य-सिद्धिलाई प्राप्त गर्दछ। 18.49",

    "हे कुन्तीपुत्र ! त्यस नैष्कर्म्य सिध्दी (अन्तःकरणको शुध्दि)लाई प्राप्त भएको साधकले ब्रह्मलाई प्राप्त गर्दछ, जुन कि ज्ञानको पराकाष्ठा नै हो, जसप्रकारबाट यो प्राप्त हुन्छ, उसैप्रकारबाट संक्षेपमा तिमीले मबाट सुन। 18.50",

    "विशुद्ध बुद्धिले युक्त, हलुका, एकान्तको सेवन गर्ने, सात्विक र नियमित भोजन गर्ने साधक धैर्यपूर्वक इन्द्रियहरुलाई संयम गरेर, मन, वचन र शरीरलाई वशमा गर्ने, शब्दादि विषयहरुलाई त्याग गरेर र राग-द्वेषलाई छोड़ेर निरन्तर ध्यानयोगको परायणमा रहने, त्यो अहंकार, बल, घमण्ड, काम, क्रोध र परिग्रहलाई त्यागी एवं ममता रहित तथा शान्तियुक्त पुरुष सच्चिदानन्दघन ब्रह्ममा अभिन्न भावले स्थित हुने पात्र बन्दछ। 18.51-18.53",
    
    "विशुद्ध बुद्धिले युक्त, हलुका, एकान्तको सेवन गर्ने, सात्विक र नियमित भोजन गर्ने साधक धैर्यपूर्वक इन्द्रियहरुलाई संयम गरेर, मन, वचन र शरीरलाई वशमा गर्ने, शब्दादि विषयहरुलाई त्याग गरेर र राग-द्वेषलाई छोड़ेर निरन्तर ध्यानयोगको परायणमा रहने, त्यो अहंकार, बल, घमण्ड, काम, क्रोध र परिग्रहलाई त्यागी एवं ममता रहित तथा शान्तियुक्त पुरुष सच्चिदानन्दघन ब्रह्ममा अभिन्न भावले स्थित हुने पात्र बन्दछ। 18.51-18.53",
    
    "विशुद्ध बुद्धिले युक्त, हलुका, एकान्तको सेवन गर्ने, सात्विक र नियमित भोजन गर्ने साधक धैर्यपूर्वक इन्द्रियहरुलाई संयम गरेर, मन, वचन र शरीरलाई वशमा गर्ने, शब्दादि विषयहरुलाई त्याग गरेर र राग-द्वेषलाई छोड़ेर निरन्तर ध्यानयोगको परायणमा रहने, त्यो अहंकार, बल, घमण्ड, काम, क्रोध र परिग्रहलाई त्यागी एवं ममता रहित तथा शान्तियुक्त पुरुष सच्चिदानन्दघन ब्रह्ममा अभिन्न भावले स्थित हुने पात्र बन्दछ। 18.51-18.53",
    
    "त्यसपछि त्यो सच्चिदानन्दघन ब्रह्ममा एकभावले स्थित, प्रसन्न मन चित्तवाला योगी न त कसैको निमित्त शोक गर्दछ, न त केहिको इच्छा, आकाङ्क्षा नै गर्दछ। यस्तो सम्पूर्ण प्राणिहरूमा समभाव राख्ने योगीले मेरो पराभक्तिलाई प्राप्त गर्दछ। 18.54",

    "त्यस पराभक्तिबाट उसले मलाई, म जति छु, जे छु र जो छु, त्यसलाई जस्ताको त्यस्तै तत्वपूर्वक जान्दछ अनि फेरि मलाई सो भक्तिले तत्वसाथ जानेर तत्कालै ममा प्रविष्ट हुन्छ। 18.55",

    "मेरो परायण भएको कर्मयोगी त सदासर्वदा कर्महरुलाई सधैं गरेर पनि मेरो कृपाले सनातन अविनाशी परमपदलाई प्राप्त गर्दछ। 18.56",

    "मनचित्तले नै सम्पूर्ण कर्म मलाई अर्पण गरेर, मेरो परायण भएर तथा समबुध्दिरूपी योगलाई अवलम्बन गरि मेरो परायण र निरन्तर ममा चित्त लगाउने बन। 18.57",

    "उपर्युक्त प्रकारले ममा चित्त लगाउने हुँदा तिमी मेरो कृपाले गर्दा सम्पूर्ण विघ्नहरुलाई अनायासै पार गर्नेछौ (तरी जान्छौं) तर यदि तिमी अहंकारको कारणले मेरो वचनलाई सुनेनौ भने तिम्रो पतन हुने निश्चित छ अर्थात् परमार्थ देखि तिमी भ्रष्ट हुने छौ। 18.58",

    "तिमीले अहंकारको आश्रय लिएर यदि तिमी जुन यस्तो मानी रहेकाछौ कि \"मैले युद्ध गर्दिन\" भन्ने तिम्रो यो निर्णय मिथ्या हो, किनकि तिम्रो क्षत्रिय-प्रकृति (स्वभाव)ले तिमिलाई जबर्जस्ती युद्धमा लगाईदिन्छ ! 18.59",

    "हे कुन्तीपुत्र ! आफ्नो स्वभाव-जन्य कर्मबाट बाँधिएको तिमी मोहको कारणले जुन युद्धलाई गर्न चाहदैनौ, त्यसलाई पनि तिमीले आफ्नो पूर्वकृत स्वाभाविक कर्मले बाँधिएर परिवेश भइ गर्नेछौ। 18.60",

    "हे अर्जुन ! ईश्वर सम्पूर्ण प्राणिहरुको हृदयमा रहन्छ र आफ्नो मायाबाट शरीररूपी यन्त्रमा आरूढ़ भएर सम्पूर्ण प्राणिहरुलाई (तिनीहरुको स्वभावका अनुसार) भ्रमण गराउदैं रहनु हुन्छ। 18.61",

    "हे भारत ! तिमी सबै प्रकारले त्यही ईश्वरका नै शरणमा जाऊ। उही ईश्वरको कृपाले नै तिमीले परमशान्ति तथा अविनाशी परमपदलाई प्राप्त गर्ने छौ। 18.62",

    "यसरी यो गोपनियभन्दा पनि गोपनिय (शरणागति रूप) ज्ञान मैंले तिमीलाई भने। अब तिमीले यो ज्ञानलाई पूर्णतः राम्ररी विचार गरेर जस्तो चाहन्छौ, त्यस्तै गर। 18.63",

    "सम्पूर्ण गोपनीय भन्दा अति गोपनीय मेरा रहस्य युक्त सर्वोत्कृष्ट वचनलाई तिमीले फेरि मबाट सुन। तिमी मेरा अतिशय प्रिय छौं, यसकारण यो परमहितकारक वचन मैंले तिमीसित भन्दछु। 18.64",

    "(तिमी) मेरो भक्त होऊ, ममा मन लगाउने होऊ, मेरो पूजन गर्नेवाला बन र मलाई नमस्कार (प्रणाम) गर। यसो गरेबाट तिमीले मलाई नै प्राप्त गर्ने छौ, यो मैंले तिम्रो अगाडि सत्य प्रतिज्ञा गर्दछु किनकि तिमी मेरो अत्यन्त प्रिय छौ। 18.65",

    "सम्पूर्ण धर्म अर्थात् कर्तब्य कर्मलाई ममा त्यागेर तिमीले केवल एक म सर्वशक्तिमान् सर्वाधार परमेश्वरकै शरणमा आऊ। म तिमीलाई सम्पूर्ण पापहरूबाट मुक्त गरि दिन्छु, तिमीले शोक नगर। 18.66",

    "यी सबै गुह्यतम उपदेश कुनै पनि कालमा तिमीले अतपस्वी पुरुषहरुसित भन्ने छैनौं, अभक्तलाई पनि कहिल्यैँ भन्ने छैनौं तथा जसले सुन्ने चाहना राख्दैन त्यसलाई पनि भन्ने छैनौं र जसले ममा दोषदृष्टि राख्दछ, उसलाई त झन् कहिल्यै भन्ने छैनौ/भन्न हुदैन। 18.67",

    "जुन पुरुषले ममा परम प्रेम (भक्ति) गर्दै यस परम रहस्ययुक्त संवाद (गीता शास्त्र)लाई मेरा भक्तहरु बिच भन्दछ, उसले मलाई नै प्राप्त गर्दछ - यसमा कुनै सन्देह/संका छैन। 18.68",

    "त्यस भन्दा धेरै मेरो अत्यन्त प्रिय कार्य गर्ने मनुष्यहरुमा अर्को कोहि पनि हुदैन र यो भूमण्डलमा ऊभन्दा प्रिय अर्को भविष्यमा कोहि हुनेवाला पनि छैन। 18.69",

    "जुन मनुष्यले हामी दुईको यस धर्ममय संवादरुपी गीताशास्त्रलाई पढदछ, अध्ययन गर्दछ, उसद्वारा पनि म ज्ञानयज्ञबाट पूजित हुन्छु भन्ने मेरो मत छ। 18.70",

    "श्रद्धावान् र दोषदृष्टिबाट रहित जुन मनुष्यले यस (गीताशास्त्र) को श्रवणमात्र पनि गर्दछ भने उसले पनि शरीर छुटे पछि पुण्य कर्म गर्नेहरुको श्रेष्ठ लोकलाई नै प्राप्त गर्दछ। 18.71",

    "हे पार्थ! के तिमीले एकाग्रचित्तले यो गीताशास्त्र सुन्यौ त? (र) हे धनञ्जय! के तिम्रो अज्ञान बाट उत्पन्न मोह नष्ट भयो त? 18.72",

    "अर्जुनले भने - हे अच्युत ! हजुरको कृपाले मेरो मोह नष्ट भयो र मैंले स्मृति प्राप्त गरेको छु। अब म संशयरहित भएर बसेको छु। अतः अब म हजुरको आज्ञालाई पालन गर्ने छु। 18.73",

    "सञ्जयले भने- यसप्रकार, मैले भगवान् श्रीवासुदेव र महात्मा अर्जुनको यस रोमाञ्चित गर्नेवाला अद्भुत रहस्ययुक्त संवादलाई सुनेँ। 18.74",

    "श्रीव्यासजीको कृपाले दिव्यदृष्टि मैंले पाएर यो परम गोपनीय योग (गीताशास्त्र)लाई अर्जुनसित भन्दाभन्दै गरेको साक्षात् स्वयं योगेश्वर भगवान् श्रीकृष्णबाट प्रत्यक्ष (रुपमा) सुनेको छु। 18.75",

    "हे राजन् ! भगवान् श्रीकृष्ण र अर्जुनको यस रहस्ययुक्त, कल्याणकारक र अद्भुत संवादलाई पुन:स्मरण गरेर म बारम्बार हर्षित भईरहेको छु। 18.76",

    "हे राजन्! श्री हरिको त्यस अत्यन्त विलक्षण (अद्भुत) रुपलाई पनि बारम्बार सम्झँदा मेरो चित्तमा महान् आश्चर्य उत्पन्न हुन्छ र म बारम्बार हर्षित भईरहेको छु। 18.77",

    "हे राजन्! जहाँ योगेश्वर भगवान श्रीकृष्ण हुनुहुन्छ र जहाँ गाण्डीव धनुषधारी अर्जुन छन्, त्यहीँ नै श्री, विजय, विभूति र अटल नीति हुन्छ भन्ने (यस्तो) मेरो मत रहेको छ। 18.78",

    "यस प्रकार ॐ तत् सत् - यी भगवन्नामहरुको उच्चारणपूर्वक ब्रह्मविद्या र योगशास्त्रमय श्रीमद्भगवद्गीताको उपनिषद् रूप श्रीकृष्ण र अर्जुनको संवाद स्वरुप ‘मोक्षसन्यासयोग’ नाम भएको अठारौँ अध्याय पूर्ण भयो ।"     
  ]
  }
]

    export default GeetajiNepali