const GeetajiHindi = [
{
  "adhyaya-1" : [
    "धृतराष्ट्र उवाच\nधर्मक्षेत्रे कुरुक्षेत्रे, समवेता युयुत्सवः।\nमामकाः(फ्) पाण्डवाश्चैव, किमकुर्वत सञ्जय॥1.1॥",
    
    "सञ्जय उवाच\nदृष्ट्वा तु पाण्डवानीकं(व्ँ), व्यूढं(न्) दुर्योधनस्तदा।\nआचार्यमुपसङ्गम्य, राजा वचनमब्रवीत्॥1.2॥",
    
    "पश्यैतां(म्) पाण्डुपुत्राणाम्, आचार्य महतीं(ञ्) चमूम्।\nव्यूढां(न्) द्रुपदपुत्रेण, तव शिष्येण धीमता॥1.3॥",
    
    "अत्र शूरा महेष्वासा, भीमार्जुनसमा युधि।\nयुयुधानो विराटश्च, द्रुपदश्च महारथः॥1.4॥",
    
    "धृष्टकेतुश्चेकितानः(ख्), काशिराजश्च वीर्यवान्।\nपुरुजित्कुन्तिभोजश्च, शैब्यश्च नरपुङ्गवः॥1.5॥",
    
    "युधामन्युश्च विक्रान्त, उत्तमौजाश्च वीर्यवान्।\nसौभद्रो द्रौपदेयाश्च, सर्व एव महारथाः॥1.6॥",
    
    "अस्माकं(न्) तु विशिष्टा ये, तान्निबोध द्विजोत्तम।\nनायका मम सैन्यस्य, सञ्ज्ञार्थं(न्) तान्ब्रवीमि ते॥1.7॥",
    
    "भवान्भीष्मश्च कर्णश्च, कृपश्च समितिञ्जयः।\nअश्वत्थामा विकर्णश्च, सौमदत्तिस्तथैव च॥1.8॥",
    
    "अन्ये च बहवः(श्) शूरा, मदर्थे त्यक्तजीविताः।\nनानाशस्त्रप्रहरणाः(स्), सर्वे युद्धविशारदाः॥1.9॥",
    
    "अपर्याप्तं(न्) तदस्माकं(म्), बलं(म्) भीष्माभिरक्षितम्।\nपर्याप्तं(न्) त्विदमेतेषां(म्), बलं(म्) भीमाभिरक्षितम्॥1.10॥",
    
    "अयनेषु च सर्वेषु, यथाभागमवस्थिताः।\nभीष्ममेवाभिरक्षन्तु, भवन्तः(स्) सर्व एव हि॥1.11॥",
    
    "तस्य सञ्जनयन्हर्षं(ङ्), कुरुवृद्धः(फ्) पितामहः।\nसिंहनादं(व्ँ) विनद्योच्चैः(श्), शङ्खं(न्) दध्मौ प्रतापवान्॥1.12॥",
    
    "ततः(श्) शङ्खाश्च भेर्यश्च, पणवानकगोमुखाः।\nसहसैवाभ्यहन्यन्त, स शब्दस्तुमुलोऽभवत्॥1.13॥",
    
    "ततः(श्) श्वेतैर्हयैर्युक्ते, महति स्यन्दने स्थितौ।\nमाधवः(फ्) पाण्डवश्चैव, दिव्यौ शङ्खौ प्रदध्मतुः॥1.14॥",
    
    "पाञ्चजन्यं(म्) हृषीकेशो, देवदत्तं(न्) धनञ्जयः।\nपौण्ड्रं(न्) दध्मौ महाशङ्खं(म्), भीमकर्मा वृकोदरः॥1.15॥",
    
    "अनन्तविजयं(म्) राजा, कुन्तीपुत्रो युधिष्ठिरः।\nनकुलः(स्) सहदेवश्च, सुघोषमणिपुष्पकौ॥1.16॥",
    
    "काश्यश्च परमेष्वासः(श्), शिखण्डी च महारथः।\nधृष्टद्युम्नो विराटश्च, सात्यकिश्चापराजितः॥1.17॥",
    
    "द्रुपदो द्रौपदेयाश्च, सर्वशः(फ्) पृथिवीपते।\nसौभद्रश्च महाबाहुः(श्), शङ्खान्दध्मुः(फ्) पृथक् पृथक्॥1.18॥",
    
    "स घोषो धार्तराष्ट्राणां(म्), हृदयानि व्यदारयत्।\nनभश्च पृथिवीं(ञ्) चैव, तुमुलो व्यनुनादयन्॥1.19॥",
    
    "अथ व्यवस्थितान्दृष्ट्वा, धार्तराष्ट्रान्कपिध्वजः।\nप्रवृत्ते शस्त्रसम्पाते, धनुरुद्यम्य पाण्डवः॥1.20॥",
    
    "हृषीकेशं(न्) तदा वाक्यम्, इदमाह महीपते।\nअर्जुन उवाच\nसेनयोरुभयोर्मध्ये, रथं(म्) स्थापय मेऽच्युत॥1.21॥",
    
    "यावदेतान्निरीक्षेऽहं(य्ँ), योद्धुकामानवस्थितान्।\nकैर्मया सह योद्धव्यम्,  अस्मिन्रणसमुद्यमे॥1.22॥",
    
    "योत्स्यमानानवेक्षेऽहं(य्ँ), य एतेऽत्र समागताः।\nधार्तराष्ट्रस्य दुर्बुद्धे:(र्), युद्धे प्रियचिकीर्षवः॥1.23॥",
    
    "सञ्जय उवाच\nएवमुक्तो हृषीकेशो, गुडाकेशेन भारत।\nसेनयोरुभयोर्मध्ये, स्थापयित्वा रथोत्तमम्॥1.24॥",
    
    "भीष्मद्रोणप्रमुखतः(स्), सर्वेषां(ञ्) च महीक्षिताम्।\nउवाच पार्थ पश्यैतान्, समवेतान्कुरूनिति॥1.25॥",
    
    "तत्रापश्यत्स्थितान्पार्थः(फ्), पितॄनथ पितामहान्।\nआचार्यान्मातुलान्भ्रातॄन्, पुत्रान्पौत्रान्सखींस्तथा॥1.26॥",
    
    "श्वशुरान्सुहृदश्चैव, सेनयोरुभयोरपि।\nतान्समीक्ष्य स कौन्तेयः(स्), सर्वान्बन्धूनवस्थितान्॥1.27॥",
    
    "कृपया परयाविष्टो, विषीदन्निदमब्रवीत्।\nअर्जुन उवाच\nदृष्ट्वेमं(म्) स्वजनं(ङ्) कृष्ण, युयुत्सुं(म्) समुपस्थितम्॥1.28॥",
    
    "सीदन्ति मम गात्राणि, मुखं(ञ्) च परिशुष्यति।\nवेपथुश्च शरीरे मे, रोमहर्षश्च जायते॥1.29॥",
    
    "गाण्डीवं(म्) स्रंसते हस्तात्, त्वक्चैव परिदह्यते।\nन च शक्नोम्यवस्थातुं(म्), भ्रमतीव च मे मनः॥1.30॥",
    
    "निमित्तानि च पश्यामि, विपरीतानि केशव।\nन च श्रेयोऽनुपश्यामि, हत्वा स्वजनमाहवे॥1.31॥",
    
    "न काङ्क्षे विजयं(ङ्) कृष्ण, न च राज्यं(म्) सुखानि च।\nकिं(न्) नो राज्येन गोविन्द, किं(म्) भोगैर्जीवितेन वा॥1.32॥",
    
    "येषामर्थे काङ्क्षितं(न्) नो, राज्यं(म्) भोगाः(स्) सुखानि च।\nत इमेऽवस्थिता युद्धे, प्राणांस्त्यक्त्वा धनानि च॥1.33॥",
    
    "आचार्याः(फ्) पितरः(फ्) पुत्रा:(स्), तथैव च पितामहाः।\nमातुलाः(श्) श्वशुराः(फ्) पौत्राः(श्), श्यालाः(स्) सम्बन्धिनस्तथा॥1.34॥",
    
    "एतान्न हन्तुमिच्छामि, घ्नतोऽपि मधुसूदन।\nअपि त्रैलोक्यराज्यस्य, हेतोः(ख्) किं(न्) नु महीकृते॥1.35॥",
    
    "निहत्य धार्तराष्ट्रान्नः(ख्), का प्रीतिः(स्) स्याज्जनार्दन।\nपापमेवाश्रयेदस्मान्, हत्वैतानाततायिनः॥1.36॥",
    
    "तस्मान्नार्हा वयं(म्) हन्तुं(न्), धार्तराष्ट्रान्स्वबान्धवान्।\nस्वजनं(म्) हि कथं(म्) हत्वा, सुखिनः(स्) स्याम माधव॥1.37॥",
    
    "यद्यप्येते न पश्यन्ति, लोभोपहतचेतसः।\nकुलक्षयकृतं(न्) दोषं(म्), मित्रद्रोहे च पातकम्॥1.38॥",
    
    "कथं(न्) न ज्ञेयमस्माभिः(फ्), पापादस्मान्निवर्तितुम्।\nकुलक्षयकृतं(न्) दोषं(म्), प्रपश्यद्भिर्जनार्दन॥1.39॥",
    
    "कुलक्षये प्रणश्यन्ति, कुलधर्माः(स्) सनातनाः।\nधर्मे नष्टे कुलं(ङ्) कृत्स्नम्, अधर्मोऽभिभवत्युत॥1.40॥",
    
    "अधर्माभिभवात्कृष्ण, प्रदुष्यन्ति कुलस्त्रियः।\nस्त्रीषु दुष्टासु वार्ष्णेय, जायते वर्णसङ्करः॥1.41॥",
    
    "सङ्करो नरकायैव, कुलघ्नानां(ङ्) कुलस्य च।\nपतन्ति पितरो ह्येषां(ल्ँ),  लुप्तपिण्डोदकक्रियाः॥1.42॥",
    
    "दोषैरेतैः(ख्) कुलघ्नानां(व्ँ), वर्णसङ्करकारकैः।\nउत्साद्यन्ते जातिधर्माः(ख्), कुलधर्माश्च शाश्वताः॥1.43॥",
    
    "उत्सन्नकुलधर्माणां(म्), मनुष्याणां(ञ्) जनार्दन।\nनरकेऽनियतं(व्ँ) वासो, भवतीत्यनुशुश्रुम॥1.44॥",
    
    "अहो बत महत्पापं(ङ्), कर्तुं(व्ँ) व्यवसिता वयम्।\nयद्राज्यसुखलोभेन, हन्तुं(म्) स्वजनमुद्यताः॥1.45॥",
    
    "यदि मामप्रतीकारम्, अशस्त्रं(म्) शस्त्रपाणयः।\nधार्तराष्ट्रा रणे हन्यु:(स्), तन्मे क्षेमतरं(म्) भवेत्॥1.46॥",
    
    "सञ्जय उवाच\nएवमुक्त्वार्जुनः(स्) सङ्ख्ये , रथोपस्थ उपाविशत्।\nविसृज्य सशरं(ञ्) चापं(म्), शोकसंविग्नमानसः॥1.47॥",
  
    "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु ब्रह्मविद्यायां(य्ँ) योगशास्त्रे\nश्रीकृष्णार्जुनसंवादे अर्जुनविषादयोगो नाम प्रथमोऽध्याय:।।"
  ],
  "adhyaya-2" : [
    "सञ्जय उवाच\nतं(न्) तथा कृपयाविष्टम्, अश्रुपूर्णाकुलेक्षणम्।\nविषीदन्तमिदं(व्ँ) वाक्यम्, उवाच मधुसूदनः॥2.1॥",
    
    "श्रीभगवानुवाच\nकुतस्त्वा कश्मलमिदं(व्ँ), विषमे समुपस्थितम्।\nअनार्यजुष्टमस्वर्ग्यम्,           अकीर्तिकरमर्जुन॥2.2॥",
    
    "क्लैब्यं(म्) मा स्म गमः(फ्) पार्थ, नैतत्त्वय्युपपद्यते ।\nक्षुद्रं(म्) हृदयदौर्बल्यं(न्), त्यक्त्वोत्तिष्ठ परन्तप॥2.3॥",
    
    "अर्जुन उवाच\nकथं(म्) भीष्ममहं(म्) सङ्ख्ये, द्रोणं(ञ्) च मधुसूदन।\nइषुभिः(फ्) प्रति योत्स्यामि, पूजार्हावरिसूदन॥2.4॥",
    
    "गुरूनहत्वा हि महानुभावान्,\nश्रेयो भोक्तुं(म्) भैक्ष्यमपीह लोके।\nहत्वार्थकामांस्तु गुरूनिहैव,\nभुञ्जीय  भोगान् रुधिरप्रदिग्धान्॥2.5॥",
    
    "न चैतद्विद्मः(ख्) कतरन्नो गरीयो,\nयद्वा जयेम यदि वा नो जयेयुः।\nयानेव हत्वा न जिजीविषाम:(स्),\nतेऽवस्थिताः(फ्) प्रमुखे धार्तराष्ट्राः॥2.6॥",
    
    "कार्पण्यदोषोपहतस्वभावः(फ्),\nपृच्छामि त्वां(न्)  धर्मसम्मूढचेताः।\nयच्छ्रेयः(स्) स्यान्निश्चितं(म्) ब्रूहि तन्मे,\nशिष्यस्तेऽहं(म्) शाधि मां(न्) त्वां(म्) प्रपन्नम्॥2.7॥",
    
    "न हि प्रपश्यामि ममापनुद्याद्,\nयच्छोकमुच्छोषणमिन्द्रियाणाम्।\nअवाप्य भूमावसपत्नमृद्धं(म्),\nराज्यं(म्) सुराणामपि चाधिपत्यम्॥2.8॥",
    
    "सञ्जय उवाच\nएवमुक्त्वा हृषीकेशं(ङ्), गुडाकेशः(फ्) परन्तप।\nन योत्स्य इति गोविन्दम्, उक्त्वा तूष्णीं(म्) बभूव ह॥2.9॥",
    
    "तमुवाच हृषीकेशः(फ्), प्रहसन्निव भारत।\nसेनयोरुभयोर्मध्ये, विषीदन्तमिदं(व्ँ) वचः॥2.10॥",
    
    "श्रीभगवानुवाच\nअशोच्यानन्वशोचस्त्वं(म्), प्रज्ञावादांश्च भाषसे।\nगतासूनगतासूंश्च,        नानुशोचन्ति  पण्डिता:॥2.11॥",
    
    "न त्वेवाहं(ञ्) जातु नासं(न्), न त्वं(न्) नेमे जनाधिपाः।\nन चैव न भविष्यामः(स्), सर्वे वयमतः(फ्) परम्॥2.12॥",
    
    "देहिनोऽस्मिन्यथा देहे, कौमारं(य्ँ) यौवनं(ञ्) जरा।\nतथा देहान्तरप्राप्ति:(र्),          धीरस्तत्र न मुह्यति॥2.13॥",
    
    "मात्रास्पर्शास्तु कौन्तेय, शीतोष्णसुखदुःखदाः।\nआगमापायिनोऽनित्या:(स्), तांस्तितिक्षस्व भारत॥2.14॥",
    
    "यं(म्) हि न व्यथयन्त्येते, पुरुषं(म्) पुरुषर्षभ।\nसमदुःखसुखं(न्) धीरं(म्), सोऽमृतत्वाय कल्पते॥2.15॥",
    
    "नासतो विद्यते भावो, नाभावो विद्यते सतः।\nउभयोरपि दृष्टोऽन्त:(स्), त्वनयोस्तत्त्वदर्शिभिः॥2.16॥",
    
    "अविनाशि तु तद्विद्धि, येन सर्वमिदं(न्) ततम्।\nविनाशमव्ययस्यास्य,      न कश्चित्कर्तुमर्हति॥2.17॥",
    
    "अन्तवन्त इमे देहा, नित्यस्योक्ताः(श्) शरीरिणः।\nअनाशिनोऽप्रमेयस्य,    तस्माद्युध्यस्व भारत॥2.18॥",
    
    "य एनं(व्ँ) वेत्ति हन्तारं(य्ँ), यश्चैनं(म्) मन्यते हतम्।\nउभौ तौ न विजानीतो, नायं(म्) हन्ति न हन्यते॥2.19॥",
    
    "न जायते म्रियते वा कदाचिन्,\nनायं (म्) भूत्वा भविता वा न भूयः।\nअजो नित्यः(श्) शाश्वतोऽयं(म्) पुराणो,\n न हन्यते हन्यमाने शरीरे॥2.20॥",
    
    "वेदाविनाशिनं(न्) नित्यं(य्ँ), य एनमजमव्ययम्।\nकथं(म्) स पुरुषः(फ्) पार्थ, कं(ङ्) घातयति हन्ति कम्॥2.21॥",
    
    "वासांसि जीर्णानि यथा विहाय,\nनवानि गृह्णाति नरोऽपराणि।\nतथा शरीराणि विहाय जीर्णा-\nन्यन्यानि संयाति नवानि देही॥2.22॥",
    
    "नैनं(ञ्) छिन्दन्ति शस्त्राणि, नैनं(न्) दहति पावकः।\nन चैनं(ङ्) क्लेदयन्त्यापो, न शोषयति मारुतः॥2.23॥",
    
    "अच्छेद्योऽयमदाह्योऽयम्, अक्लेद्योऽशोष्य एव च।\nनित्यः(स्) सर्वगतः(स्) स्थाणु:(र्), अचलोऽयं(म्) सनातनः॥2.24॥",
    
    "अव्यक्तोऽयमचिन्त्योऽयम्, अविकार्योऽयमुच्यते।\nतस्मादेवं(व्ँ) विदित्वैनं(न्), नानुशोचितुमर्हसि॥2.25॥",
    
    "अथ चैनं(न्) नित्यजातं(न्), नित्यं(व्ँ) वा मन्यसे मृतम्।\nतथापि त्वं(म्) महाबाहो, नैवं(म्) शोचितुमर्हसि॥2.26॥",
    
    "जातस्य हि ध्रुवो मृत्यु:(र्), ध्रुवं(ञ्) जन्म मृतस्य च।\nतस्मादपरिहार्येऽर्थे, न त्वं(म्) शोचितुमर्हसि॥2.27॥",
    
    "अव्यक्तादीनि भूतानि, व्यक्तमध्यानि भारत।\nअव्यक्तनिधनान्येव, तत्र का परिदेवना॥2.28॥",
    
    "आश्चर्यवत्पश्यति कश्चिदेनम्,\nआश्चर्यवद्वदति तथैव चान्यः।\nआश्चर्यवच्चैनमन्यः(श्) शृणोति,\nश्रुत्वाप्येनं(व्ँ) वेद न चैव कश्चित्॥2.29॥",
    
    "देही नित्यमवध्योऽयं(न्), देहे सर्वस्य भारत।\nतस्मात्सर्वाणि भूतानि, न त्वं(म्) शोचितुमर्हसि॥2.230॥",
    
    "स्वधर्ममपि चावेक्ष्य, न विकम्पितुमर्हसि।\nधर्म्याद्धि युद्धाच्छ्रेयोऽन्यत्, क्षत्रियस्य न विद्यते॥2.31॥",
    
    "यदृच्छया चोपपन्नं(म्), स्वर्गद्वारमपावृतम्।\nसुखिनः क्षत्रियाः(फ्) पार्थ, लभन्ते युद्धमीदृशम्॥2.32॥",
    
    "अथ चेत्त्वमिमं(न्) धर्म्यं(म्), सङ्ग्रामं(न्) न करिष्यसि।\nततः(स्) स्वधर्मं(ङ्) कीर्तिं(ञ्) च, हित्वा पापमवाप्स्यसि॥2.33॥",
    
    "अकीर्तिं(ञ्) चापि भूतानि, कथयिष्यन्ति तेऽव्ययाम्।\nसम्भावितस्य चाकीर्ति:(र्), मरणादतिरिच्यते॥2.34॥",
    
    "भयाद्रणादुपरतं(म्), मंस्यन्ते त्वां(म्) महारथाः।\nयेषां(ञ्) च त्वं(म्) बहुमतो, भूत्वा यास्यसि लाघवम्॥2.35॥",
    
    "अवाच्यवादांश्च बहून्, वदिष्यन्ति तवाहिताः।\nनिन्दन्तस्तव सामर्थ्यं(न्), ततो दुःखतरं(न्) नु किम्॥2.36॥",
    
    "हतो वा प्राप्स्यसि स्वर्गं(ञ्), जित्वा वा भोक्ष्यसे महीम्।\nतस्मादुत्तिष्ठ कौन्तेय, युद्धाय कृतनिश्चयः॥2.37॥",
    
    "सुखदुःखे समे कृत्वा, लाभालाभौ जयाजयौ।\nततो युद्धाय युज्यस्व, नैवं(म्) पापमवाप्स्यसि॥2.38॥",
    
    "एषा तेऽभिहिता साङ्ख्ये, बुद्धिर्योगे त्विमां(म्) शृणु।\nबुद्ध्या युक्तो यया पार्थ, कर्मबन्धं(म्) प्रहास्यसि॥2.39॥",
    
    "नेहाभिक्रमनाशोऽस्ति, प्रत्यवायो न विद्यते।\nस्वल्पमप्यस्य धर्मस्य, त्रायते महतो भयात्॥2.40॥",
    
    "व्यवसायात्मिका बुद्धि:(र्), एकेह कुरुनन्दन।\nबहुशाखा ह्यनन्ताश्च, बुद्धयोऽव्यवसायिनाम्॥2.41॥",
    
    "यामिमां(म्) पुष्पितां(व्ँ) वाचं(म्), प्रवदन्त्यविपश्चितः।\nवेदवादरताः(फ्) पार्थ, नान्यदस्तीति वादिनः॥2.42॥",
    
    "कामात्मानः(स्) स्वर्गपरा, जन्मकर्मफलप्रदाम्।\nक्रियाविशेषबहुलां(म्), भोगैश्वर्यगतिं(म्) प्रति॥2.43॥",
    
    "भोगैश्वर्यप्रसक्तानां(न्), तयापहृतचेतसाम्।\nव्यवसायात्मिका बुद्धिः(स्), समाधौ न विधीयते॥2.44॥",
    
    "त्रैगुण्यविषया वेदा, निस्त्रैगुण्यो भवार्जुन।\nनिर्द्वन्द्वो नित्यसत्त्वस्थो, निर्योगक्षेम आत्मवान्॥2.45॥",
    
    "यावानर्थ उदपाने, सर्वतः(स्) सम्प्लुतोदके।\nतावान्सर्वेषु वेदेषु, ब्राह्मणस्य विजानतः॥2.46॥",
    
    "कर्मण्येवाधिकारस्ते, मा फलेषु कदाचन।\nमा कर्मफलहेतुर्भू:(र्), मा ते सङ्गोऽस्त्वकर्मणि॥2.47॥",
    
    "योगस्थः(ख्) कुरु कर्माणि, सङ्गं(न्) त्यक्त्वा धनञ्जय।\nसिद्ध्यसिद्ध्योः(स्) समो भूत्वा, समत्वं(य्ँ) योग उच्यते॥2.48॥",
    
    "दूरेण ह्यवरं(ङ्) कर्म, बुद्धियोगाद्धनञ्जय।\nबुद्धौ शरणमन्विच्छ, कृपणाः(फ्) फलहेतवः॥2.49॥",
    
    "बुद्धियुक्तो जहातीह, उभे सुकृतदुष्कृते।\nतस्माद्योगाय युज्यस्व, योगः(ख्) कर्मसु कौशलम्॥2.50॥",
    
    "कर्मजं(म्) बुद्धियुक्ता हि, फलं(न्) त्यक्त्वा मनीषिणः।\nजन्मबन्धविनिर्मुक्ताः(फ्), पदं(ङ्) गच्छन्त्यनामयम्॥2.51॥",
    
    "यदा ते मोहकलिलं(म्), बुद्धिर्व्यतितरिष्यति।\nतदा गन्तासि निर्वेदं(म्), श्रोतव्यस्य श्रुतस्य च॥2.52॥",
    
    "श्रुतिविप्रतिपन्ना ते, यदा स्थास्यति निश्चला।\nसमाधावचला बुद्धि:(स्), तदा योगमवाप्स्यसि॥2.53॥",
    
    "अर्जुन उवाच\nस्थितप्रज्ञस्य का भाषा, समाधिस्थस्य केशव।\nस्थितधीः(ख्) किं(म्) प्रभाषेत, किमासीत व्रजेत किम्॥2.54॥",
    
    "श्रीभगवानुवाच\nप्रजहाति यदा कामान्, सर्वान्पार्थ मनोगतान्।\nआत्मन्येवात्मना तुष्टः(स्), स्थितप्रज्ञस्तदोच्यते॥2.55॥",
    
    "दुःखेष्वनुद्विग्नमनाः(स्), सुखेषु विगतस्पृहः।\nवीतरागभयक्रोधः(स्), स्थितधीर्मुनिरुच्यते॥2.56॥",
    
    "यः(स्) सर्वत्रानभिस्नेह:(स्), तत्तत्प्राप्य शुभाशुभम्।\nनाभिनन्दति न द्वेष्टि, तस्य प्रज्ञा प्रतिष्ठिता॥2.57॥",
    
    "यदा संहरते चायं(ङ्), कूर्मोऽङ्गानीव सर्वशः।\nइन्द्रियाणीन्द्रियार्थेभ्य:(स्), तस्य प्रज्ञा प्रतिष्ठिता॥2.58॥",
    
    "विषया विनिवर्तन्ते, निराहारस्य देहिनः।\nरसवर्जं(म्) रसोऽप्यस्य, परं(न्) दृष्ट्वा निवर्तते ॥2.59॥",
    
    "यततो ह्यपि कौन्तेय, पुरुषस्य विपश्चितः।\nइन्द्रियाणि प्रमाथीनि, हरन्ति प्रसभं(म्) मनः॥2.60॥",
    
    "तानि सर्वाणि संयम्य, युक्त आसीत मत्परः।\nवशे हि यस्येन्द्रियाणि, तस्य प्रज्ञा प्रतिष्ठिता॥2.61॥",
    
    "ध्यायतो विषयान्पुंस:(स्), सङ्गस्तेषूपजायते।\nसङ्गात्सञ्जायते कामः(ख्), कामात्क्रोधोऽभिजायते॥2.62॥",
    
    "क्रोधाद्भवति सम्मोह:(स्), सम्मोहात्स्मृतिविभ्रमः।\nस्मृतिभ्रंशाद् बुद्धिनाशो, बुद्धिनाशात्प्रणश्यति॥2.63॥",
    
    "रागद्वेषवियुक्तैस्तु, विषयानिन्द्रियैश्चरन्।\nआत्मवश्यैर्विधेयात्मा, प्रसादमधिगच्छति॥2.64॥",
    
    "प्रसादे सर्वदुःखानां(म्), हानिरस्योपजायते।\nप्रसन्नचेतसो ह्याशु, बुद्धिः(फ्) पर्यवतिष्ठते॥2.65॥",
    
    "नास्ति बुद्धिरयुक्तस्य, न चायुक्तस्य भावना।\nन चाभावयतः(श्) शान्ति:(र्), अशान्तस्य कुतः(स्) सुखम्॥2.66॥",
    
    "इन्द्रियाणां(म्) हि चरतां(य्ँ), यन्मनोऽनुविधीयते।\nतदस्य हरति प्रज्ञां(व्ँ), वायुर्नावमिवाम्भसि॥2.67॥",
    
    "तस्माद्यस्य महाबाहो, निगृहीतानि सर्वशः।\nइन्द्रियाणीन्द्रियार्थेभ्य:(स्), तस्य प्रज्ञा प्रतिष्ठिता॥2.68॥",
    
    "या निशा सर्वभूतानां(न्), तस्यां(ञ्) जागर्ति संयमी।\nयस्यां(ञ्) जाग्रति भूतानि, सा निशा पश्यतो मुनेः॥2.69॥",
    
    "आपूर्यमाणमचलप्रतिष्ठं(म्),\nसमुद्रमापः(फ्) प्रविशन्ति यद्वत्।\nतद्वत्कामा यं(म्) प्रविशन्ति सर्वे,\nस शान्तिमाप्नोति न कामकामी॥2.70॥",
    
    "विहाय कामान्यः(स्) सर्वान्, पुमांश्चरति निःस्पृहः।\nनिर्ममो निरहङ्कारः(स्), स शान्तिमधिगच्छति॥2.71॥",
    
    "एषा ब्राह्मी स्थितिः(फ्) पार्थ, नैनां(म्) प्राप्य विमुह्यति ।\nस्थित्वास्यामन्तकालेऽपि,        ब्रह्मनिर्वाणमृच्छति॥2.72॥",
  
    "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु ब्रह्मविद्यायां(य्ँ) योगशास्त्रे\nश्रीकृष्णार्जुनसंवादे साङ्ख्ययोगो नाम द्वितीयोऽध्यायः ॥२॥"
  ],
  "adhyaya-3" : [
    "अर्जुन उवाच\nज्यायसी चेत्कर्मणस्ते, मता बुद्धिर्जनार्दन।\nतत्किं(ङ्) कर्मणि घोरे मां(न्), नियोजयसि केशव॥3.1॥",
    
    "व्यामिश्रेणेव वाक्येन, बुद्धिं(म्) मोहयसीव मे।\nतदेकं(व्ँ) वद निश्चित्य, येन श्रेयोऽहमाप्नुयाम्॥3.2॥",
    
    "श्रीभगवानुवाच\nलोकेऽस्मिन्द्विविधा निष्ठा, पुरा प्रोक्ता मयानघ।\nज्ञानयोगेन साङ्ख्यानां(ङ्), कर्मयोगेन योगिनाम्॥3.3॥",
    
    " न कर्मणामनारम्भान्, नैष्कर्म्यं(म्) पुरुषोऽश्नुते।\nन च सन्न्यसनादेव, सिद्धिं(म्) समधिगच्छति॥3.4॥",
    
    "न हि कश्चित्क्षणमपि, जातु तिष्ठत्यकर्मकृत्।\nकार्यते ह्यवशः(ख्) कर्म, सर्वः(फ्) प्रकृतिजैर्गुणैः॥3.5॥",
    
    "कर्मेन्द्रियाणि संयम्य, य आस्ते मनसा स्मरन्।\nइन्द्रियार्थान्विमूढात्मा, मिथ्याचारः(स्) स उच्यते॥3.6॥",
    
    "यस्त्विन्द्रियाणि मनसा, नियम्यारभतेऽर्जुन।\nकर्मेन्द्रियैः(ख्) कर्मयोगम्, असक्तः(स्) स विशिष्यते॥3.7॥",
    
    "नियतं(ङ्) कुरु कर्म त्वं(ङ्), कर्म ज्यायो ह्यकर्मणः।\nशरीरयात्रापि च ते, न प्रसिद्ध्येदकर्मणः॥3.8॥",
    
    "यज्ञार्थात्कर्मणोऽन्यत्र, लोकोऽयं(ङ्) कर्मबन्धनः।\nतदर्थं(ङ्) कर्म कौन्तेय, मुक्तसङ्गः(स्) समाचर॥3.9॥",
    
    "सहयज्ञाः(फ्) प्रजाः(स्) सृष्ट्वा, पुरोवाच प्रजापतिः।\nअनेन प्रसविष्यध्वम्, एष वोऽस्त्विष्टकामधुक्॥3.10॥",
    
    "देवान्भावयतानेन, ते देवा भावयन्तु वः।\nपरस्परं(म्) भावयन्तः(श्), श्रेयः(फ्) परमवाप्स्यथ॥3.11॥",
    
    "इष्टान्भोगान्हि वो देवा, दास्यन्ते यज्ञभाविताः।\nतैर्दत्तानप्रदायैभ्यो, यो भुङ्क्ते स्तेन एव सः॥3.12॥",
    
    "यज्ञशिष्टाशिनः(स्) सन्तो, मुच्यन्ते सर्वकिल्बिषैः।\nभुञ्जते ते त्वघं(म्) पापा, ये पचन्त्यात्मकारणात्॥3.13॥",
    
    "अन्नाद्भवन्ति भूतानि, पर्जन्यादन्नसम्भवः।\nयज्ञाद्भवति पर्जन्यो, यज्ञः(ख्) कर्मसमुद्भवः॥3.14॥",
    
    "कर्म ब्रह्मोद्भवं(व्ँ) विद्धि, ब्रह्माक्षरसमुद्भवम्।\nतस्मात्सर्वगतं(म्) ब्रह्म, नित्यं(य्ँ) यज्ञे प्रतिष्ठितम्॥3.15॥",
    
    "एवं(म्) प्रवर्तितं(ञ्) चक्रं(न्), नानुवर्तयतीह यः।\nअघायुरिन्द्रियारामो, मोघं(म्) पार्थ स जीवति॥3.16॥",
    
    "यस्त्वात्मरतिरेव स्याद्, आत्मतृप्तश्च मानवः।\nआत्मन्येव च सन्तुष्ट:(स्), तस्य कार्यं(न्) न विद्यते॥3.17॥",
    
    "नैव तस्य कृतेनार्थो, नाकृतेनेह कश्चन।\nन चास्य सर्वभूतेषु, कश्चिदर्थव्यपाश्रयः॥3.18॥",
    
    "तस्मादसक्तः(स्) सततं(ङ्), कार्यं(ङ्) कर्म समाचर।\nअसक्तो ह्याचरन्कर्म, परमाप्नोति पूरुषः॥3.19॥",
    
    "कर्मणैव हि संसिद्धिम्, आस्थिता जनकादयः।\nलोकसङ्ग्रहमेवापि, सम्पश्यन्कर्तुमर्हसि॥3.20॥",
    
    "यद्यदाचरति श्रेष्ठ:(स्), तत्तदेवेतरो जनः।\nस यत्प्रमाणं(ङ्) कुरुते, लोकस्तदनुवर्तते॥3.21॥",
    
    "न मे पार्थास्ति कर्तव्यं(न्), त्रिषु लोकेषु किञ्चन।\nनानवाप्तमवाप्तव्यं(व्ँ), वर्त एव च कर्मणि॥3.22॥",
    
    "यदि ह्यहं(न्) न वर्तेयं(ञ्), जातु कर्मण्यतन्द्रितः।\nमम वर्त्मानुवर्तन्ते, मनुष्याः(फ्) पार्थ सर्वशः॥3.23॥",
    
    "उत्सीदेयुरिमे लोका, न कुर्यां(ङ्) कर्म चेदहम्।\nसङ्करस्य च कर्ता स्याम्, उपहन्यामिमाः(फ्) प्रजाः॥3.24॥",
    
    "सक्ताः(ख्) कर्मण्यविद्वांसो, यथा कुर्वन्ति भारत।\nकुर्याद्विद्वांस्तथासक्त:(श्), चिकीर्षुर्लोकसङ्ग्रहम्॥3.25॥",
    
    "न बुद्धिभेदं(ञ्) जनयेद्, अज्ञानां(ङ्) कर्मसङ्गिनाम्।\nजोषयेत्सर्वकर्माणि, विद्वान्युक्तः(स्) समाचरन्॥3.26॥",
    
    "प्रकृतेः(ख्) क्रियमाणानि, गुणैः(ख्) कर्माणि सर्वशः।\nअहङ्कारविमूढात्मा, कर्ताहमिति मन्यते॥3.27॥",
    
    "तत्त्ववित्तु महाबाहो, गुणकर्मविभागयोः।\nगुणा गुणेषु वर्तन्त, इति मत्वा न सज्जते॥3.28॥",
    
    "प्रकृतेर्गुणसम्मूढाः(स्), सज्जन्ते गुणकर्मसु।\nतानकृत्स्नविदो मन्दान्, कृत्स्नविन्न विचालयेत्॥3.29॥",
    
    "मयि सर्वाणि कर्माणि,  सन्न्यस्याध्यात्मचेतसा।\nनिराशीर्निर्ममो भूत्वा, युध्यस्व विगतज्वरः॥3.30॥",
    
    "ये मे मतमिदं(न्) नित्यम्, अनुतिष्ठन्ति मानवाः।\nश्रद्धावन्तोऽनसूयन्तो, मुच्यन्ते तेऽपि कर्मभिः॥3.31॥",
    
    "ये त्वेतदभ्यसूयन्तो, नानुतिष्ठन्ति मे मतम्।\nसर्वज्ञानविमूढांस्तान्, विद्धि नष्टानचेतसः॥3.32॥",
    
    "सदृशं(ञ्) चेष्टते स्वस्याः(फ्), प्रकृतेर्ज्ञानवानपि।\nप्रकृतिं(य्ँ) यान्ति भूतानि, निग्रहः(ख्) किं(ङ्) करिष्यति॥3.33॥",
    
    "इन्द्रियस्येन्द्रियस्यार्थे, रागद्वेषौ व्यवस्थितौ।\nतयोर्न वशमागच्छेत्, तौ ह्यस्य परिपन्थिनौ॥3.34॥",
    
    "श्रेयान्स्वधर्मो विगुणः(फ्),  परधर्मात्स्वनुष्ठितात्।\nस्वधर्मे निधनं(म्) श्रेयः(फ्), परधर्मो भयावहः॥3.35॥",
    
    "अर्जुन उवाच\nअथ केन प्रयुक्तोऽयं(म्), पापं(ञ्) चरति पूरुषः।\nअनिच्छन्नपि वार्ष्णेय, बलादिव नियोजितः॥3.36॥",
    
    "श्रीभगवानुवाच\nकाम एष क्रोध एष, रजोगुणसमुद्भवः।\nमहाशनो महापाप्मा, विद्ध्येनमिह वैरिणम्॥3.37॥",
    
    "धूमेनाव्रियते वह्नि:(र्), यथादर्शो मलेन च।\nयथोल्बेनावृतो गर्भ:(स्), तथा तेनेदमावृतम्॥3.38॥",
    
    "आवृतं(ञ्) ज्ञानमेतेन, ज्ञानिनो नित्यवैरिणा।\nकामरूपेण कौन्तेय, दुष्पूरेणानलेन च॥3.39॥",
    
    "इन्द्रियाणि मनो बुद्धि:(र्), अस्याधिष्ठानमुच्यते।\nएतैर्विमोहयत्येष, ज्ञानमावृत्य देहिनम्॥3.40॥",
    
    "तस्मात्त्वमिन्द्रियाण्यादौ, नियम्य भरतर्षभ।\nपाप्मानं(म्) प्रजहि ह्येनं(ञ्), ज्ञानविज्ञाननाशनम्॥3.41॥",
    
    "इन्द्रियाणि पराण्याहु:(र्), इन्द्रियेभ्यः(फ्) परं(म्) मनः।\nमनसस्तु परा बुद्धि:(र्), यो बुद्धेः(फ्) परतस्तु सः॥3.42॥",
    
    "एवं(म्) बुद्धेः(फ्) परं(म्) बुद्ध्वा, संस्तभ्यात्मानमात्मना।\nजहि शत्रुं(म्) महाबाहो, कामरूपं(न्) दुरासदम्॥3.43॥",
  
    "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु  ब्रह्मविद्यायां(य्ँ) योगशास्त्रे\nश्रीकृष्णार्जुनसंवादे कर्मयोगो नाम तृतीयोऽध्याय:॥"
  ],
  "adhyaya-4" : [
    "श्रीभगवानुवाच\nइमं(व्ँ) विवस्वते योगं(म्), प्रोक्तवानहमव्ययम्।\nविवस्वान्मनवे प्राह, मनुरिक्ष्वाकवेऽब्रवीत्॥4.1॥",
    
    "एवं(म्) परम्पराप्राप्तम्, इमं(म्) राजर्षयो विदुः।\nस कालेनेह महता, योगो नष्टः(फ्) परन्तप॥4.2 ॥",
    
    "स एवायं(म्) मया तेऽद्य, योगः(फ्) प्रोक्तः(फ्) पुरातनः।\nभक्तोऽसि मे सखा चेति, रहस्यं(म्) ह्येतदुत्तमम्॥4.3॥",  
    
    "अर्जुन उवाच\nअपरं(म्) भवतो जन्म, परं(ञ्) जन्म विवस्वतः।\nकथमेतद्विजानीयां(न्), त्वमादौ प्रोक्तवानिति॥4.4॥",
    
    "श्रीभगवानुवाच\nबहूनि मे व्यतीतानि, जन्मानि तव चार्जुन।\nतान्यहं(व्ँ) वेद सर्वाणि, न त्वं(व्ँ) वेत्थ परन्तप॥4.5॥",
    
    "अजोऽपि सन्नव्ययात्मा, भूतानामीश्वरोऽपि सन्।\nप्रकृतिं(म्) स्वामधिष्ठाय, सम्भवाम्यात्ममायया॥4.6॥",
    
    "यदा यदा हि धर्मस्य, ग्लानिर्भवति भारत।\nअभ्युत्थानमधर्मस्य, तदात्मानं(म्) सृजाम्यहम्॥4.7॥",
    
    "परित्राणाय साधूनां(व्ँ), विनाशाय च दुष्कृताम्।\nधर्मसंस्थापनार्थाय, सम्भवामि युगे युगे॥4.8॥",
    
    "जन्म कर्म च मे दिव्यम्, एवं(य्ँ) यो वेत्ति तत्त्वतः।\nत्यक्त्वा देहं(म्) पुनर्जन्म, नैति मामेति सोऽर्जुन॥4.9॥",
    
    "वीतरागभयक्रोधा, मन्मया मामुपाश्रिताः।\nबहवो ज्ञानतपसा, पूता मद्भावमागताः॥4.10॥",
    
    "ये यथा मां(म्) प्रपद्यन्ते, तांस्तथैव भजाम्यहम्।\nमम वर्त्मानुवर्तन्ते, मनुष्याः(फ्) पार्थ सर्वशः॥4.11॥",
    
    "काङ्क्षन्तः(ख्) कर्मणां(म्) सिद्धिं(य्ँ), यजन्त इह देवताः।\nक्षिप्रं(म्) हि मानुषे लोके, सिद्धिर्भवति कर्मजा॥4.12॥",
    
    "चातुर्वर्ण्यं(म्) मया सृष्टं(ङ्), गुणकर्मविभागशः।\nतस्य कर्तारमपि मां(व्ँ), विद्ध्यकर्तारमव्ययम्॥4.13॥",
    
    "न मां(ङ्) कर्माणि लिम्पन्ति, न मे कर्मफले स्पृहा।\nइति मां(य्ँ) योऽभिजानाति, कर्मभिर्न स बध्यते॥4.14॥",
    
    "एवं(ञ्) ज्ञात्वा कृतं(ङ्) कर्म, पूर्वैरपि मुमुक्षुभिः।\nकुरु कर्मैव तस्मात्त्वं(म्), पूर्वैः(फ्) पूर्वतरं(ङ्) कृतम्॥4.15॥",
    
    "किं(ङ्) कर्म किमकर्मेति, कवयोऽप्यत्र मोहिताः।\nतत्ते कर्म प्रवक्ष्यामि, यज्ज्ञात्वा मोक्ष्यसेऽशुभात्॥4.16॥",
    
    "कर्मणो ह्यपि बोद्धव्यं(म्), बोद्धव्यं(ञ्) च विकर्मणः।\nअकर्मणश्च बोद्धव्यं(ङ्), गहना कर्मणो गतिः॥4.17॥",
    
    "कर्मण्यकर्म यः(फ्) पश्येद्, अकर्मणि च कर्म यः।\nस बुद्धिमान्मनुष्येषु, स युक्तः(ख्) कृत्स्नकर्मकृत्॥4.18॥",
    
    "यस्य सर्वे समारम्भाः(ख्), कामसङ्कल्पवर्जिताः।\nज्ञानाग्निदग्धकर्माणं(न्), तमाहुः(फ्) पण्डितं(म्) बुधाः॥4.19॥",
    
    "त्यक्त्वा कर्मफलासङ्गं(न्), नित्यतृप्तो निराश्रयः।\nकर्मण्यभिप्रवृत्तोऽपि, नैव किञ्चित्करोति सः॥4.20॥",
    
    "निराशीर्यतचित्तात्मा, त्यक्तसर्वपरिग्रहः।\nशारीरं(ङ्) केवलं(ङ्) कर्म, कुर्वन्नाप्नोति किल्बिषम्॥4.21॥",
    
    "यदृच्छालाभसन्तुष्टो, द्वन्द्वातीतो विमत्सरः।\nसमः(स्) सिद्धावसिद्धौ च, कृत्वापि न निबध्यते॥4.22॥",
    
    "गतसङ्गस्य मुक्तस्य, ज्ञानावस्थितचेतसः।\nयज्ञायाचरतः(ख्) कर्म, समग्रं(म्) प्रविलीयते॥4.23॥",
    
    "ब्रह्मार्पणं(म्) ब्रह्म हवि:(र्), ब्रह्माग्नौ ब्रह्मणा हुतम्।\nब्रह्मैव तेन गन्तव्यं(म्), ब्रह्मकर्मसमाधिना॥4.24॥",
    
    "दैवमेवापरे यज्ञं(य्ँ), योगिनः(फ्) पर्युपासते।\nब्रह्माग्नावपरे यज्ञं(य्ँ), यज्ञेनैवोपजुह्वति॥4.25॥",
    
    "श्रोत्रादीनीन्द्रियाण्यन्ये, संयमाग्निषु जुह्वति।\nशब्दादीन्विषयानन्य, इन्द्रियाग्निषु जुह्वति॥4.26॥",
    
    "सर्वाणीन्द्रियकर्माणि, प्राणकर्माणि चापरे।\nआत्मसंयमयोगाग्नौ, जुह्वति ज्ञानदीपिते॥4.27॥",
    
    "द्रव्ययज्ञास्तपोयज्ञा, योगयज्ञास्तथापरे।\nस्वाध्यायज्ञानयज्ञाश्च, यतयः(स्) संशितव्रताः॥4.28॥",
      
    "अपाने जुह्वति प्राणं(म्), प्राणेऽपानं(न्) तथापरे।\nप्राणापानगती रुद्ध्वा, प्राणायामपरायणाः॥4.29॥",
      
    "अपरे नियताहाराः(फ्), प्राणान्प्राणेषु जुह्वति।\nसर्वेऽप्येते यज्ञविदो, यज्ञक्षपितकल्मषाः॥4.30॥",
      
    "यज्ञशिष्टामृतभुजो, यान्ति ब्रह्म सनातनम्।\nनायं(ल्ँ) लोकोऽस्त्ययज्ञस्य, कुतोऽन्यः(ख्) कुरुसत्तम॥4.31॥",
    
    "एवं(म्) बहुविधा यज्ञा, वितता ब्रह्मणो मुखे।\nकर्मजान्विद्धि तान्सर्वान्, एवं(ञ्) ज्ञात्वा विमोक्ष्यसे॥4.32॥",
    
    "श्रेयान्द्रव्यमयाद्यज्ञाज्, ज्ञानयज्ञः(फ्) परन्तप।\nसर्वं(ङ्) कर्माखिलं(म्) पार्थ, ज्ञाने परिसमाप्यते॥4.33॥",
    
    "तद्विद्धि प्रणिपातेन, परिप्रश्नेन सेवया।\nउपदेक्ष्यन्ति ते ज्ञानं(ञ्), ज्ञानिनस्तत्त्वदर्शिनः॥4.34॥",
    
    "यज्ज्ञात्वा न पुनर्मोहम्, एवं(य्ँ) यास्यसि पाण्डव।\nयेन भूतान्यशेषेण, द्रक्ष्यस्यात्मन्यथो मयि॥4.35॥",
    
    "अपि चेदसि पापेभ्यः(स्), सर्वेभ्यः(फ्) पापकृत्तमः।\nसर्वं(ञ्) ज्ञानप्लवेनैव, वृजिनं(म्) सन्तरिष्यसि॥4.36॥",
    
    "यथैधांसि समिद्धोऽग्नि:(र्),  भस्मसात्कुरुतेऽर्जुन।\nज्ञानाग्निः(स्) सर्वकर्माणि, भस्मसात्कुरुते तथा॥4.37॥",
    
    "न हि ज्ञानेन सदृशं(म्), पवित्रमिह विद्यते।\nतत्स्वयं(य्ँ) योगसंसिद्धः(ख्), कालेनात्मनि विन्दति॥4.38॥",
    
    "श्रद्धावाँल्लभते ज्ञानं(न्), तत्परः(स्) संयतेन्द्रियः।\nज्ञानं(ल्ँ) लब्ध्वा परां(म्) शान्तिम्, अचिरेणाधिगच्छति॥4.39॥",
    
    "अज्ञश्चाश्रद्दधानश्च, संशयात्मा विनश्यति।\nनायं(ल्ँ) लोकोऽस्ति न परो, न सुखं(म्) संशयात्मनः॥4.40॥",
    
    "योगसन्न्यस्तकर्माणं(ञ्), ज्ञानसञ्छिन्नसंशयम्।\nआत्मवन्तं(न्) न कर्माणि, निबध्नन्ति धनञ्जय॥4.41॥",
    
    "तस्मादज्ञानसम्भूतं(म्), हृत्स्थं(ञ्) ज्ञानासिनात्मन:।\nछित्त्वैनं(म्) संशयं(य्ँ) योगम्, आतिष्ठोत्तिष्ठ भारत॥4.42॥",
    
    "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु ब्रह्मविद्यायां(य्ँ) योगशास्त्रे\nश्रीकृष्णार्जुनसंवादे ज्ञानकर्मसन्न्यासयोगो नाम चतुर्थोऽध्याय:।।"
  ],
  "adhyaya-5" : [
    "अर्जुन उवाच\nसन्न्यासं(ङ्) कर्मणां(ङ्) कृष्ण, पुनर्योगं(ञ्) च शंससि।\nयच्छ्रेय एतयोरेकं(न्), तन्मे ब्रूहि सुनिश्चितम्॥5.1॥",
    
    "श्रीभगवानुवाच\nसन्न्यासः(ख्) कर्मयोगश्च, निःश्रेयसकरावुभौ।\nतयोस्तु कर्मसन्न्यासात्, कर्मयोगो विशिष्यते॥5.2॥",
    
    "ज्ञेयः(स्) स नित्यसन्न्यासी, यो न द्वेष्टि न काङ्क्षति।\nनिर्द्वन्द्वो हि महाबाहो, सुखं(म्) बन्धात्प्रमुच्यते॥5.3॥",
    
    "साङ्ख्ययोगौ पृथग्बालाः(फ्), प्रवदन्ति न पण्डिताः।\nएकमप्यास्थितः(स्) सम्यग्, उभयोर्विन्दते फलम्॥5.4॥",
    
    "यत्साङ्ख्यैः(फ्) प्राप्यते स्थानं(न्), तद्योगैरपि गम्यते।\nएकं(म्) साङ्ख्यं(ञ्) च योगं(ञ्) च, यः(फ्) पश्यति स पश्यति॥5.5॥",
    
    "सन्न्यासस्तु महाबाहो, दुःखमाप्तुमयोगतः।\nयोगयुक्तो मुनिर्ब्रह्म, नचिरेणाधिगच्छति॥5.6॥",
    
    "योगयुक्तो विशुद्धात्मा, विजितात्मा जितेन्द्रियः।\nसर्वभूतात्मभूतात्मा, कुर्वन्नपि न लिप्यते॥5.7॥",
    
    "नैव किञ्चित्करोमीति, युक्तो मन्येत तत्त्ववित्।\nपश्यञ्शृण्वन्स्पृशञ्जिघ्रन्, नश्नन्गच्छन्स्वपञ्श्वसन्॥5.8॥",
    
    "प्रलपन्विसृजन्गृह्णन्, नुन्मिषन्निमिषन्नपि।\nइन्द्रियाणीन्द्रियार्थेषु, वर्तन्त इति धारयन्॥5.9॥", 
    
    "ब्रह्मण्याधाय कर्माणि, सङ्गं(न्) त्यक्त्वा करोति यः।\nलिप्यते न स पापेन, पद्मपत्रमिवाम्भसा॥5.10॥", 
    
    "कायेन मनसा बुद्ध्या, केवलैरिन्द्रियैरपि।\nयोगिनः(ख्) कर्म कुर्वन्ति, सङ्गं(न्) त्यक्त्वात्मशुद्धये॥5.11॥",
      
    "युक्तः(ख्) कर्मफलं(न्) त्यक्त्वा, शान्तिमाप्नोति नैष्ठिकीम्।\nअयुक्तः(ख्) कामकारेण, फले सक्तो निबध्यते॥5.12॥",
    
    "सर्वकर्माणि मनसा, सन्न्यस्यास्ते सुखं(व्ँ) वशी।\nनवद्वारे पुरे देही, नैव कुर्वन्न कारयन्॥5.13॥",
    
    "न कर्तृत्वं(न्) न कर्माणि, लोकस्य सृजति प्रभुः।\nन कर्मफलसंयोगं(म्), स्वभावस्तु प्रवर्तते॥5.14॥",
    
    "नादत्ते कस्यचित्पापं(न्), न चैव सुकृतं(व्ँ) विभुः।\nअज्ञानेनावृतं(ञ्) ज्ञानं(न्), तेन मुह्यन्ति जन्तवः॥5.15॥",
    
    "ज्ञानेन तु तदज्ञानं(य्ँ), येषां(न्) नाशितमात्मनः।\nतेषामादित्यवज्ज्ञानं(म्), प्रकाशयति तत्परम्॥5.16॥",
    
    "तद्बुद्धयस्तदात्मान:(स्), तन्निष्ठास्तत्परायणाः।\nगच्छन्त्यपुनरावृत्तिं(ञ्), ज्ञाननिर्धूतकल्मषाः॥5.17॥",
    
    "विद्याविनयसम्पन्ने, ब्राह्मणे गवि हस्तिनि।\nशुनि चैव श्वपाके च, पण्डिताः(स्) समदर्शिनः॥5.18॥",
    
    "इहैव तैर्जितः(स्) सर्गो, येषां(म्) साम्ये स्थितं(म्) मनः।\nनिर्दोषं(म्) हि समं(म्) ब्रह्म, तस्माद् ब्रह्मणि ते स्थिताः॥5.19॥",
    
    "न प्रहृष्येत्प्रियं(म्) प्राप्य, नोद्विजेत्प्राप्य चाप्रियम्।\nस्थिरबुद्धिरसम्मूढो, ब्रह्मविद् ब्रह्मणि स्थितः॥5.20॥",
    
    "बाह्यस्पर्शेष्वसक्तात्मा, विन्दत्यात्मनि यत्सुखम्।\nस ब्रह्मयोगयुक्तात्मा, सुखमक्षयमश्नुते॥5.21॥",
    
    "ये हि संस्पर्शजा भोगा, दुःखयोनय एव ते।\nआद्यन्तवन्तः(ख्) कौन्तेय, न तेषु रमते बुधः॥5.22॥",
    
    "शक्नोतीहैव यः(स्) सोढुं(म्), प्राक्शरीरविमोक्षणात्।\nकामक्रोधोद्भवं(व्ँ) वेगं(म्), स युक्तः(स्) स सुखी नरः॥5.23॥",
    
    "योऽन्तःसुखोऽन्तराराम:(स्), तथान्तर्ज्योतिरेव यः।\nस योगी ब्रह्मनिर्वाणं(म्), ब्रह्मभूतोऽधिगच्छति॥5.24॥",
    
    "लभन्ते ब्रह्मनिर्वाणम्, ऋषयः क्षीणकल्मषाः।\nछिन्नद्वैधा यतात्मानः(स्), सर्वभूतहिते रताः॥5.25॥",
    
    "कामक्रोधवियुक्तानां(य्ँ), यतीनां(य्ँ) यतचेतसाम्।\nअभितो ब्रह्मनिर्वाणं(व्ँ), वर्तते विदितात्मनाम्॥5.26॥",
    
    "स्पर्शान्कृत्वा बहिर्बाह्यांश्, चक्षुश्चैवान्तरे भ्रुवोः।\nप्राणापानौ समौ कृत्वा, नासाभ्यन्तरचारिणौ॥5.27॥",
    
    "यतेन्द्रियमनोबुद्धि:(र्), मुनिर्मोक्षपरायणः।\nविगतेच्छाभयक्रोधो, यः(स्) सदा मुक्त एव सः॥5.28॥",
    
    "भोक्तारं(य्ँ) यज्ञतपसां(म्), सर्वलोकमहेश्वरम्।\nसुहृदं(म्) सर्वभूतानां(ञ्), ज्ञात्वा मां(म्) शान्तिमृच्छति॥5.29॥",
    
    "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु ब्रह्मविद्यायां(य्ँ) योगशास्त्रे\nश्रीकृष्णार्जुनसंवादे कर्मसन्न्यासयोगो नाम पञ्चमोऽध्याय:॥"
  ],
  "adhyaya-6" : [
    "श्रीभगवानुवाच\nअनाश्रितः(ख्) कर्मफलं(ङ्), कार्यं(ङ्) कर्म करोति यः।\nस सन्न्यासी च योगी च, न निरग्निर्न चाक्रियः॥6.1॥",
    
    "यं(म्) सन्न्यासमिति प्राहु:(र्), योगं(न्) तं(व्ँ) विद्धि पाण्डव।\nन ह्यसन्न्यस्तसङ्कल्पो, योगी भवति कश्चन॥6.2॥",
    
    "आरुरुक्षोर्मुनेर्योगं(ङ्), कर्म कारणमुच्यते।\nयोगारूढस्य तस्यैव, शमः(ख्) कारणमुच्यते॥6.3॥",
    
    "यदा हि नेन्द्रियार्थेषु, न कर्मस्वनुषज्जते।\nसर्वसङ्कल्पसन्न्यासी, योगारूढस्तदोच्यते॥6.4॥",
    
    "उद्धरेदात्मनात्मानं(न्), नात्मानमवसादयेत्।\nआत्मैव ह्यात्मनो बन्धु:(र्), आत्मैव रिपुरात्मनः ॥6.5॥",
    
    "बन्धुरात्मात्मनस्तस्य, येनात्मैवात्मना जितः।\nअनात्मनस्तु शत्रुत्वे, वर्तेतात्मैव शत्रुवत्॥6.6॥",
    
    "जितात्मनः(फ्) प्रशान्तस्य, परमात्मा समाहितः।\nशीतोष्णसुखदुःखेषु, तथा मानापमानयोः॥6.7॥",
      
    "ज्ञानविज्ञानतृप्तात्मा, कूटस्थो विजितेन्द्रियः।\nयुक्त इत्युच्यते योगी, समलोष्टाश्मकाञ्चनः॥6.8॥",
    
    "सुहृन्मित्रार्युदासीन, मध्यस्थद्वेष्यबन्धुषु।\nसाधुष्वपि च पापेषु, समबुद्धिर्विशिष्यते॥6.9॥",
    
    "योगी युञ्जीत सततम्, आत्मानं(म्) रहसि स्थितः।\nएकाकी यतचित्तात्मा, निराशीरपरिग्रहः॥6.10॥",
    
    "शुचौ देशे प्रतिष्ठाप्य, स्थिरमासनमात्मनः।\nनात्युच्छ्रितं(न्) नातिनीचं(ञ्), चैलाजिनकुशोत्तरम्॥6.11॥",
    
    "तत्रैकाग्रं(म्) मनः(ख्) कृत्वा, यतचित्तेन्द्रियक्रियः।\nउपविश्यासने युञ्ज्याद्, योगमात्मविशुद्धये॥6.12॥",
    
    "समं(ङ्) कायशिरोग्रीवं(न्), धारयन्नचलं(म्) स्थिरः।\nसम्प्रेक्ष्य नासिकाग्रं(म्) स्वं(न्), दिशश्चानवलोकयन्॥6.13॥",
    
    "प्रशान्तात्मा विगतभी:(र्), ब्रह्मचारिव्रते स्थितः।\nमनः(स्) संयम्य मच्चित्तो, युक्त आसीत मत्परः॥6.14॥",
    
    "युञ्जन्नेवं(म्) सदात्मानं(य्ँ), योगी नियतमानसः।\nशान्तिं(न्) निर्वाणपरमां(म्), मत्संस्थामधिगच्छति॥6.15॥",
    
    "नात्यश्नतस्तु योगोऽस्ति, न चैकान्तमनश्नतः।\nन चातिस्वप्नशीलस्य, जाग्रतो नैव चार्जुन॥6.16॥",
    
    "युक्ताहारविहारस्य,  युक्तचेष्टस्य कर्मसु।\nयुक्तस्वप्नावबोधस्य, योगो भवति दुःखहा॥6.17॥",
    
    "यदा विनियतं(ञ्) चित्तम्, आत्मन्येवावतिष्ठते।\nनिःस्पृहः(स्) सर्वकामेभ्यो, युक्त इत्युच्यते तदा॥6.18॥",
    
    "यथा दीपो निवातस्थो, नेङ्गते सोपमा स्मृता।\nयोगिनो यतचित्तस्य, युञ्जतो योगमात्मनः॥6.19॥",
    
    "यत्रोपरमते चित्तं(न्), निरुद्धं(य्ँ) योगसेवया।\nयत्र चैवात्मनात्मानं(म्), पश्यन्नात्मनि तुष्यति॥6.20॥",
    
    "सुखमात्यन्तिकं(य्ँ) यत्तद्, बुद्धिग्राह्यमतीन्द्रियम्।\nवेत्ति यत्र न चैवायं(म्), स्थितश्चलति तत्त्वतः॥6.21॥",
    
    "यं(ल्ँ) लब्ध्वा चापरं(ल्ँ) लाभं(म्), मन्यते नाधिकं(न्) ततः।\nयस्मिन्स्थितो न दुःखेन, गुरुणापि विचाल्यते॥6.22॥",
    
    "तं(व्ँ) विद्याद् दुःखसंयोग, वियोगं(य्ँ) योगसञ्ज्ञितम्।\nस निश्चयेन योक्तव्यो, योगोऽनिर्विण्णचेतसा॥6.23॥",
    
    "सङ्कल्पप्रभवान्कामांस्, त्यक्त्वा सर्वानशेषतः।\nमनसैवेन्द्रियग्रामं(व्ँ), विनियम्य समन्ततः॥6.24॥",
    
    "शनैः(श्) शनैरुपरमेद्, बुद्ध्या धृतिगृहीतया।\nआत्मसंस्थं(म्) मनः(ख्) कृत्वा, न किञ्चिदपि चिन्तयेत्॥6.25॥",
    
    "यतो यतो निश्चरति, मनश्चञ्चलमस्थिरम्।\nततस्ततो नियम्यैतद्, आत्मन्येव वशं(न्) नयेत् ॥6.26॥",
    
    "प्रशान्तमनसं(म्) ह्येनं(य्ँ), योगिनं(म्) सुखमुत्तमम्।\nउपैति शान्तरजसं(म्), ब्रह्मभूतमकल्मषम्॥6.27॥",
    
    "युञ्जन्नेवं(म्) सदात्मानं(य्ँ), योगी विगतकल्मषः।\nसुखेन ब्रह्मसंस्पर्शम्, अत्यन्तं(म्) सुखमश्नुते॥6.28॥",
    
    "सर्वभूतस्थमात्मानं(म्), सर्वभूतानि चात्मनि।\nईक्षते योगयुक्तात्मा, सर्वत्र समदर्शनः॥6.29॥",
    
    "यो मां(म्) पश्यति सर्वत्र, सर्वं(ञ्) च मयि पश्यति।\nतस्याहं(न्) न प्रणश्यामि, स च मे न प्रणश्यति॥6.30॥",
    
    "सर्वभूतस्थितं(य्ँ) यो मां(म्), भजत्येकत्वमास्थितः।\nसर्वथा वर्तमानोऽपि, स योगी मयि वर्तते॥6.31॥",
    
    "आत्मौपम्येन सर्वत्र, समं(म्) पश्यति योऽर्जुन।\nसुखं(व्ँ) वा यदि वा दुःखं(म्), स योगी परमो मतः॥6.32॥",
    
    "अर्जुन उवाच\n योऽयं(य्ँ) योगस्त्वया प्रोक्तः(स्), साम्येन मधुसूदन।\nएतस्याहं(न्) न पश्यामि, चञ्चलत्वात्स्थितिं(म्) स्थिराम्॥6.33॥",
    
    "चञ्चलं(म्) हि मनः(ख्) कृष्ण, प्रमाथि बलवद्दृढम् ।\nतस्याहं(न्) निग्रहं(म्) मन्ये, वायोरिव सुदुष्करम् ॥6.34॥",
    
    "श्रीभगवानुवाच\nअसंशयं(म्) महाबाहो, मनो दुर्निग्रहं(ञ्) चलम्।\nअभ्यासेन तु कौन्तेय, वैराग्येण च गृह्यते॥6.35॥",
    
    "असंयतात्मना योगो, दुष्प्राप इति मे मतिः।\nवश्यात्मना तु यतता, शक्योऽवाप्तुमुपायतः॥6.36॥",
    
    "अर्जुन उवाच\nअयतिः(श्) श्रद्धयोपेतो, योगाच्चलितमानसः।\nअप्राप्य योगसंसिद्धिं(ङ्), कां(ङ्) गतिं (ङ्) कृष्ण गच्छति॥6.37॥",
    
    "कच्चिन्नोभयविभ्रष्ट:(श्), छिन्नाभ्रमिव नश्यति।\nअप्रतिष्ठो महाबाहो, विमूढो ब्रह्मणः(फ्) पथि॥6.38॥",
    
    "एतन्मे संशयं(ङ्) कृष्ण, छेत्तुमर्हस्यशेषतः।\nत्वदन्यः(स्) संशयस्यास्य, छेत्ता न ह्युपपद्यते॥6.39॥",
    
    "श्रीभगवानुवाच\nपार्थ नैवेह नामुत्र, विनाशस्तस्य विद्यते।\nन हि कल्याणकृत्कश्चिद्, दुर्गतिं(न्) तात गच्छति॥6.40॥",
    
    "प्राप्य पुण्यकृतां(ल्ँ) लोकान्, उषित्वा शाश्वतीः(स्) समाः।\nशुचीनां(म्) श्रीमतां(ङ्) गेहे, योगभ्रष्टोऽभिजायते॥6.41॥",
    
    "अथवा योगिनामेव, कुले भवति धीमताम्।\nएतद्धि दुर्लभतरं(ल्ँ), लोके जन्म यदीदृशम्॥6.42॥",
      
    "तत्र तं(म्) बुद्धिसंयोगं(ल्ँ), लभते पौर्वदेहिकम्।\nयतते च ततो भूयः(स्), संसिद्धौ कुरुनन्दन॥6.43॥",
    
    "पूर्वाभ्यासेन तेनैव, ह्रियते ह्यवशोऽपि सः।\nजिज्ञासुरपि योगस्य, शब्दब्रह्मातिवर्तते॥6.44॥",
    
    "प्रयत्नाद्यतमानस्तु, योगी संशुद्धकिल्बिषः।\nअनेकजन्मसंसिद्ध:(स्), ततो याति परां(ङ्) गतिम्॥6.45॥",
    
    "तपस्विभ्योऽधिको योगी, ज्ञानिभ्योऽपि मतोऽधिकः।\nकर्मिभ्यश्चाधिको योगी, तस्माद्योगी भवार्जुन॥6.46॥",
    
    "योगिनामपि सर्वेषां(म्), मद्गतेनान्तरात्मना।\nश्रद्धावान्भजते यो मां(म्), स मे युक्ततमो मतः॥6.47॥",
    
    "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु ब्रह्मविद्यायां(य्ँ) योगशास्त्रे\nश्रीकृष्णार्जुनसंवादे आत्मसंयमयोगो नाम षष्ठोऽध्यायः॥"
  ],
  "adhyaya-7" : [
    "श्रीभगवानुवाच\nमय्यासक्तमनाः(फ्) पार्थ, योगं(य्ँ) युञ्जन्मदाश्रयः।\nअसंशयं(म्) समग्रं(म्) मां(य्ँ), यथा ज्ञास्यसि तच्छृणु॥7.1॥",
    
    "ज्ञानं(न्) तेऽहं(म्) सविज्ञानम्, इदं(व्ँ) वक्ष्याम्यशेषतः।\nयज्ज्ञात्वा नेह भूयोऽन्यज्, ज्ञातव्यमवशिष्यते॥7.2॥",
    
    "मनुष्याणां(म्) सहस्रेषु, कश्चिद्यतति सिद्धये।\nयततामपि सिद्धानां(ङ्), कश्चिन्मां(व्ँ) वेत्ति तत्त्वतः॥7.3॥",
    
    "भूमिरापोऽनलो वायुः(ख्), खं(म्) मनो बुद्धिरेव च।\nअहङ्कार इतीयं(म्) मे, भिन्ना प्रकृतिरष्टधा॥7.4॥",
    
    "अपरेयमितस्त्वन्यां(म्), प्रकृतिं(व्ँ) विद्धि मे पराम्।\nजीवभूतां(म्) महाबाहो, ययेदं(न्) धार्यते जगत्॥7.5॥",
    
    "एतद्योनीनि भूतानि, सर्वाणीत्युपधारय।\nअहं(ङ्) कृत्स्नस्य जगतः(फ्), प्रभवः(फ्) प्रलयस्तथा॥7.6॥",
    
    "मत्तः(फ्) परतरं(न्) नान्यत्, किञ्चिदस्ति धनञ्जय।\nमयि सर्वमिदं(म्) प्रोतं(म्), सूत्रे मणिगणा इव॥7.7॥",
    
    "रसोऽहमप्सु कौन्तेय, प्रभास्मि शशिसूर्ययोः।\nप्रणवः(स्) सर्ववेदेषु, शब्दः(ख्) खे पौरुषं(न्) नृषु॥7.8॥",
    
    "पुण्यो गन्धः(फ्) पृथिव्यां(ञ्) च, तेजश्चास्मि विभावसौ।\nजीवनं(म्) सर्वभूतेषु, तपश्चास्मि तपस्विषु॥7.9॥",
    
    "बीजं(म्) मां(म्) सर्वभूतानां(व्ँ), विद्धि पार्थ सनातनम्।\nबुद्धिर्बुद्धिमतामस्मि, तेजस्तेजस्विनामहम्॥7.10॥",
    
    "बलं(म्) बलवतां(ञ्) चाहं(ङ्), कामरागविवर्जितम्।\nधर्माविरुद्धो भूतेषु, कामोऽस्मि भरतर्षभ॥7.11॥",
    
    "ये चैव सात्त्विका भावा, राजसास्तामसाश्च ये।\nमत्त एवेति तान्विद्धि, न त्वहं(न्) तेषु ते मयि॥7.12॥",
    
    "त्रिभिर्गुणमयैर्भावै:(र्), एभिः(स्) सर्वमिदं(ञ्) जगत्।\nमोहितं(न्) नाभिजानाति, मामेभ्यः(फ्) परमव्ययम्॥7.13॥",
    
    "दैवी ह्येषा गुणमयी, मम माया दुरत्यया।\nमामेव ये प्रपद्यन्ते, मायामेतां(न्) तरन्ति ते॥7.14॥",
      
    "न मां(न्) दुष्कृतिनो मूढाः(फ्), प्रपद्यन्ते नराधमाः।\nमाययापहृतज्ञाना, आसुरं(म्) भावमाश्रिताः॥7.15॥",
    
    "चतुर्विधा भजन्ते मां(ञ्), जनाः(स्) सुकृतिनोऽर्जुन।\nआर्तो जिज्ञासुरर्थार्थी, ज्ञानी च भरतर्षभ॥7.16॥",
    
    "तेषां(ञ्) ज्ञानी नित्ययुक्त, एकभक्तिर्विशिष्यते।\nप्रियो हि ज्ञानिनोऽत्यर्थम्, अहं (म्) स च मम प्रियः॥7.17॥",
    
    "उदाराः(स्) सर्व एवैते, ज्ञानी त्वात्मैव मे मतम्।\nआस्थितः(स्) स हि युक्तात्मा, मामेवानुत्तमां(ङ्) गतिम्॥7.18॥",
    
    "बहूनां(ञ्) जन्मनामन्ते, ज्ञानवान्मां(म्) प्रपद्यते।\nवासुदेवः(स्) सर्वमिति, स महात्मा सुदुर्लभः॥7.19॥",
    
    "कामैस्तैस्तैर्हृतज्ञानाः(फ्), प्रपद्यन्तेऽन्यदेवताः।\nतं(न्) तं(न्) नियममास्थाय, प्रकृत्या नियताः(स्) स्वया॥7.20॥",
    
    "यो यो यां(य्ँ) यां(न्) तनुं(म्) भक्तः(श्), श्रद्धयार्चितुमिच्छति।\nतस्य तस्याचलां(म्) श्रद्धां(न्), तामेव विदधाम्यहम्॥7.21॥",
    
    "स तया श्रद्धया युक्त:(स्), तस्याराधनमीहते।\nलभते च ततः(ख्) कामान्, मयैव विहितान्हि तान्॥7.22॥",
    
    "अन्तवत्तु फलं(न्) तेषां(न्), तद्भवत्यल्पमेधसाम्।\nदेवान्देवयजो यान्ति, मद्भक्ता यान्ति मामपि॥7.23॥",
    
    "अव्यक्तं(व्ँ) व्यक्तिमापन्नं(म्), मन्यन्ते मामबुद्धयः।\nपरं(म्) भावमजानन्तो, ममाव्ययमनुत्तमम्॥7.24॥",
    
    "नाहं(म्) प्रकाशः(स्) सर्वस्य, योगमायासमावृतः।\nमूढोऽयं(न्) नाभिजानाति, लोको मामजमव्ययम्॥7.25॥",
    
    "वेदाहं(म्) समतीतानि, वर्तमानानि चार्जुन।\nभविष्याणि च भूतानि, मां(न्) तु वेद न कश्चन॥7.26॥",
    
    "इच्छाद्वेषसमुत्थेन, द्वन्द्वमोहेन भारत।\nसर्वभूतानि सम्मोहं(म्), सर्गे यान्ति परन्तप॥7.27॥",
    
    "येषां(न्) त्वन्तगतं(म्) पापं(ञ्), जनानां(म्) पुण्यकर्मणाम्।\nते द्वन्द्वमोहनिर्मुक्ता, भजन्ते मां(न्) दृढव्रताः॥7.28॥",
    
    "जरामरणमोक्षाय, मामाश्रित्य यतन्ति ये।\nते ब्रह्म तद्विदुः(ख्) कृत्स्नम्, अध्यात्मं(ङ्) कर्म चाखिलम्॥7.29॥",
    
    "साधिभूताधिदैवं(म्) मां(म्), साधियज्ञं(ञ्) च ये विदुः।\nप्रयाणकालेऽपि च मां(न्), ते विदुर्युक्तचेतसः॥7.30॥",
    
    "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु ब्रह्मविद्यायां (य्ँ) योगशास्त्रे\nश्रीकृष्णार्जुनसंवादे ज्ञानविज्ञानयोगो नाम सप्तमोऽध्यायः॥"
  ],
  "adhyaya-8" : [
    "अर्जुन उवाच\nकिं(न्) तद्ब्रह्म किमध्यात्मं(ङ्), किं(ङ्) कर्म पुरुषोत्तम।\nअधिभूतं(ञ्) च किं(म्) प्रोक्तम्, अधिदैवं(ङ्) किमुच्यते॥8.1॥",
    
    "अधियज्ञ:(ख्) कथं(ङ्) कोऽत्र, देहेऽस्मिन्मधुसूदन।\nप्रयाणकाले च कथं(ञ्), ज्ञेयोऽसि नियतात्मभिः॥8.2॥",
    
    "श्रीभगवानुवाच\n अक्षरं(म्) ब्रह्म परमं(म्), स्वभावोऽध्यात्ममुच्यते।\nभूतभावोद्भवकरो, विसर्गः(ख्) कर्मसञ्ज्ञितः॥8.3॥",
    
    "अधिभूतं(ङ्) क्षरो भावः(फ्), पुरुषश्चाधिदैवतम्।\nअधियज्ञोऽहमेवात्र, देहे देहभृतां(व्ँ) वर॥8.4॥",
    
    "अन्तकाले च मामेव, स्मरन्मुक्त्वा कलेवरम्।\nयः(फ्) प्रयाति स मद्भावं(य्ँ), याति नास्त्यत्र संशयः॥8.5॥",
    
    "यं(य्ँ) यं(व्ँ) वापि स्मरन्भावं(न्), त्यजत्यन्ते कलेवरम्।\nतं(न्) तमेवैति कौन्तेय, सदा तद्भावभावितः॥8.6॥",
    
    "तस्मात्सर्वेषु कालेषु, मामनुस्मर युध्य च।\nमय्यर्पितमनोबुद्धि:(र्), मामेवैष्यस्यसंशयम्॥8.7॥",
    
    "अभ्यासयोगयुक्तेन, चेतसा नान्यगामिना।\nपरमं(म्) पुरुषं(न्) दिव्यं(य्ँ), याति पार्थानुचिन्तयन्॥8.8॥",
    
    "कविं(म्) पुराणमनुशासितारम्,\nअणोरणीयांसमनुस्मरेद्यः ।\nसर्वस्य धातारमचिन्त्यरूपम्,\nआदित्यवर्णं(न्) तमसः(फ्) परस्तात्॥8.9॥",
    
    "प्रयाणकाले मनसाचलेन,\nभक्त्या युक्तो योगबलेन चैव।\nभ्रुवोर्मध्ये प्राणमावेश्य सम्यक्,\nस तं(म्) परं(म्) पुरुषमुपैति दिव्यम्॥8.10॥",
    
    "यदक्षरं(व्ँ) वेदविदो वदन्ति,\nविशन्ति यद्यतयो वीतरागाः।\nयदिच्छन्तो ब्रह्मचर्यं(ञ्) चरन्ति,\nतत्ते पदं(म्) सङ्ग्रहेण प्रवक्ष्ये॥8.11॥",
    
    "सर्वद्वाराणि संयम्य, मनो हृदि निरुध्य च।\nमूर्ध्न्याधायात्मनः(फ्) प्राणम्, आस्थितो योगधारणाम्॥8.12॥",
    
    "ओमित्येकाक्षरं(म्) ब्रह्म, व्याहरन्मामनुस्मरन्।\nय:(फ्) प्रयाति त्यजन्देहं(म्), स याति परमां(ङ्) गतिम्॥8.13॥",
    
    "अनन्यचेताः(स्) सततं(य्ँ), यो मां(म्) स्मरति नित्यशः।\nतस्याहं(म्) सुलभः(फ्) पार्थ, नित्ययुक्तस्य योगिनः॥8.14॥",
    
    "मामुपेत्य पुनर्जन्म, दुःखालयमशाश्वतम्।\nनाप्नुवन्ति महात्मानः(स्), संसिद्धिं(म्) परमां(ङ्) गताः॥8.15॥",
    
    "आब्रह्मभुवनाल्लोकाः(फ्), पुनरावर्तिनोऽर्जुन।\nमामुपेत्य तु कौन्तेय, पुनर्जन्म न विद्यते॥8.16॥",
    
    "सहस्रयुगपर्यन्तम्, अहर्यद्ब्रह्मणो विदुः।\nरात्रिं(य्ँ) युगसहस्रान्तां(न्), तेऽहोरात्रविदो जनाः॥8.17॥",
    
    "अव्यक्ताद्व्यक्तयः(स्) सर्वाः(फ्), प्रभवन्त्यहरागमे।\nरात्र्यागमे प्रलीयन्ते, तत्रैवाव्यक्तसञ्ज्ञके॥8.18॥",
    
    "भूतग्रामः(स्) स एवायं(म्), भूत्वा भूत्वा प्रलीयते।\nरात्र्यागमेऽवशः(फ्) पार्थ, प्रभवत्यहरागमे॥8.19॥",
    
    "परस्तस्मात्तु भावोऽन्यो-ऽव्यक्तोऽव्यक्तात्सनातनः।\nयः(स्) स सर्वेषु भूतेषु, नश्यत्सु न विनश्यति॥8.20॥",
    
    "अव्यक्तोऽक्षर इत्युक्त:(स्), तमाहुः(फ्) परमां(ङ्) गतिम्।\nयं(म्) प्राप्य न निवर्तन्ते, तद्धाम परमं(म्) मम॥8.21॥",
    
    "पुरुषः(स्) स परः(फ्) पार्थ, भक्त्या लभ्यस्त्वनन्यया।\nयस्यान्तःस्थानि भूतानि, येन सर्वमिदं(न्) ततम्॥8.22॥",
    
    "यत्र काले त्वनावृत्तिम्, आवृत्तिं(ञ्) चैव योगिनः।\nप्रयाता यान्ति तं(ङ्) कालं(व्ँ), वक्ष्यामि भरतर्षभ॥8.23॥",
    
    "अग्निर्ज्योतिरहः(श्) शुक्लः(ष्), षण्मासा उत्तरायणम्।\nतत्र प्रयाता गच्छन्ति, ब्रह्म ब्रह्मविदो जनाः॥8.24॥",
    
    "धूमो रात्रिस्तथा कृष्णः(ष्), षण्मासा दक्षिणायनम्।\nतत्र चान्द्रमसं(ञ्) ज्योति:(र्), योगी प्राप्य निवर्तते॥8.25॥",
    
    "शुक्लकृष्णे गती ह्येते, जगतः(श्) शाश्वते मते।\nएकया यात्यनावृत्तिम्, अन्ययावर्तते पुनः॥8.26॥",
    
    "नैते सृती पार्थ जानन्, योगी मुह्यति कश्चन।\nतस्मात्सर्वेषु कालेषु, योगयुक्तो भवार्जुन॥8.27॥",
      
    "वेदेषु यज्ञेषु तपः(स्) सु चैव,\nदानेषु यत्पुण्यफलं(म्) प्रदिष्टम्।\nअत्येति तत्सर्वमिदं(व्ँ) विदित्वा,\n योगी परं(म्) स्थानमुपैति चाद्यम्॥8.28॥",
    
    "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु ब्रह्मविद्यायां(य्ँ) योगशास्त्रे\nश्रीकृष्णार्जुनसंवादे अक्षरब्रह्मयोगो नाम अष्टमोऽध्यायः॥"
  ],
  "adhyaya-9" : [
    "श्रीभगवानुवाच\nइदं(न्) तु ते गुह्यतमं(म्), प्रवक्ष्याम्यनसूयवे।\nज्ञानं(व्ँ) विज्ञानसहितं(य्ँ), यज्ज्ञात्वा मोक्ष्यसेऽशुभात्॥9.1॥",
    
    "राजविद्या राजगुह्यं(म्), पवित्रमिदमुत्तमम्।\nप्रत्यक्षावगमं(न्) धर्म्यं(म्), सुसुखं(ङ्) कर्तुमव्ययम्॥9.2॥",
    
    "अश्रद्दधानाः(फ्) पुरुषा, धर्मस्यास्य परन्तप।\nअप्राप्य  मां(न्)  निवर्तन्ते,  मृत्युसंसारवर्त्मनि॥9.3॥",
    
    "मया ततमिदं(म्) सर्वं(ञ्), जगदव्यक्तमूर्तिना।\nमत्स्थानि  सर्वभूतानि,  न  चाहं(न्)  तेष्ववस्थितः॥9.4॥",
    
    "न च मत्स्थानि भूतानि, पश्य मे योगमैश्वरम्।\nभूतभृन्न   च   भूतस्थो,   ममात्मा    भूतभावनः॥9.5॥",
    
    "यथाकाशस्थितो नित्यं(व्ँ), वायुः(स्) सर्वत्रगो महान्।\nतथा   सर्वाणि    भूतानि,   मत्स्थानीत्युपधारय॥9.6॥",
    
    "सर्वभूतानि कौन्तेय, प्रकृतिं(य्ँ) यान्ति मामिकाम्।\nकल्पक्षये  पुनस्तानि,  कल्पादौ  विसृजाम्यहम्॥9.7॥",
    
    "प्रकृतिं(म्) स्वामवष्टभ्य, विसृजामि पुनः(फ्) पुनः।\nभूतग्राममिमं(ङ्) कृत्स्नम्, अवशं(म्) प्रकृतेर्वशात्॥9.8॥",
    
    "न च मां(न्) तानि कर्माणि, निबध्नन्ति धनञ्जय।\nउदासीनवदासीनम्,  असक्तं(न्)  तेषु  कर्मसु॥9.9॥",
    
    "मयाध्यक्षेण प्रकृतिः(स्), सूयते सचराचरम्।\nहेतुनानेन     कौन्तेय,     जगद्विपरिवर्तते॥9.10॥",
    
    "अवजानन्ति मां(म्) मूढा, मानुषीं(न्) तनुमाश्रितम्।\nपरं(म्)   भावमजानन्तो,   मम   भूतमहेश्वरम्॥9.11॥",
    
    "मोघाशा मोघकर्माणो, मोघज्ञाना विचेतसः।\nराक्षसीमासुरीं(ञ्) चैव, प्रकृतिं(म्) मोहिनीं(म्) श्रिताः॥9.12॥",
    
    "महात्मानस्तु मां(म्) पार्थ, दैवीं(म्) प्रकृतिमाश्रिताः।\nभजन्त्यनन्यमनसो,    ज्ञात्वा    भूतादिमव्ययम्॥9.13॥",
    
    "सततं(ङ्) कीर्तयन्तो मां(य्ँ), यतन्तश्च दृढव्रताः।\nनमस्यन्तश्च  मां(म्)  भक्त्या,  नित्ययुक्ता   उपासते॥9.14॥",
    
    "ज्ञानयज्ञेन चाप्यन्ये, यजन्तो मामुपासते।\nएकत्वेन     पृथक्त्वेन,    बहुधा    विश्वतोमुखम्॥9.15॥",
    
    "अहं(ङ्) क्रतुरहं(य्ँ) यज्ञः(स्), स्वधाहमहमौषधम्।\nमन्त्रोऽहमहमेवाज्यम्,    अहमग्निरहं(म्)   हुतम्॥9.16॥",
    
    "पिताहमस्य जगतो, माता धाता पितामहः।\nवेद्यं(म्)    पवित्रमोङ्कार, ऋक्साम   यजुरेव    च॥9.17॥",
    
    "गतिर्भर्ता प्रभुः(स्) साक्षी, निवासः(श्) शरणं(म्) सुहृत्।\nप्रभवः(फ्) प्रलयः(स्) स्थानं(न्), निधानं(म्) बीजमव्ययम्॥9.18॥",
    
    "तपाम्यहमहं(व्ँ) वर्षं(न्), निगृह्णाम्युत्सृजामि च।\nअमृतं(ञ्)    चैव    मृत्युश्च,   सदसच्चाहमर्जुन॥9.19॥",
    
    "त्रैविद्या मां(म्) सोमपाः(फ्) पूतपापा,\nयज्ञैरिष्ट्वा स्वर्गतिं(म्) प्रार्थयन्ते।\nते पुण्यमासाद्य सुरेन्द्रलोकम्\nअश्नन्ति    दिव्यान्दिवि    देवभोगान्॥9.20॥",
    
    "ते तं(म्) भुक्त्वा स्वर्गलोकं(व्ँ) विशालं(ङ्),\nक्षीणे पुण्ये मर्त्यलोकं(व्ँ)  विशन्ति।\nएवं(न्)  त्रयीधर्ममनुप्रपन्ना,\nगतागतं(ङ्)    कामकामा    लभन्ते॥9.21॥",
    
    "अनन्याश्चिन्तयन्तो मां(य्ँ), ये जनाः(फ्) पर्युपासते।\nतेषां(न्) नित्याभियुक्तानां(य्ँ), योगक्षेमं(व्ँ) वहाम्यहम्॥9.22॥",
    
    "येऽप्यन्यदेवता भक्ता, यजन्ते श्रद्धयान्विताः।\nतेऽपि  मामेव  कौन्तेय,  यजन्त्यविधिपूर्वकम्॥9.23॥",
    
    "अहं(म्) हि सर्वयज्ञानां(म्), भोक्ता च प्रभुरेव च।\nन  तु  मामभिजानन्ति,  तत्त्वेनातश्च्यवन्ति ते॥9.24॥",
    
    "यान्ति देवव्रता देवान्, पितॄन्यान्ति पितृव्रताः।\nभूतानि यान्ति भूतेज्या, यान्ति मद्याजिनोऽपि माम्॥9.25॥",
    
    "पत्रं(म्) पुष्पं(म्) फलं(न्) तोयं(य्ँ), यो मे भक्त्या प्रयच्छति।\nतदहं(म्)    भक्त्युपहृतम्,    अश्नामि    प्रयतात्मनः॥9.26॥",
    
    "यत्करोषि यदश्नासि, यज्जुहोषि ददासि यत्।\nयत्तपस्यसि  कौन्तेय,  तत्कुरुष्व  मदर्पणम्॥9.27॥",
    
    "शुभाशुभफलैरेवं(म्), मोक्ष्यसे कर्मबन्धनैः।\nसन्न्यासयोगयुक्तात्मा, विमुक्तो मामुपैष्यसि॥9.28॥",
    
    "समोऽहं(म्) सर्वभूतेषु, न मे द्वेष्योऽस्ति न प्रियः।\nये भजन्ति तु मां(म्) भक्त्या, मयि ते तेषु चाप्यहम्॥9.29॥",
      
    "अपि चेत्सुदुराचारो, भजते मामनन्यभाक्।\nसाधुरेव स मन्तव्यः(स्), सम्यग्व्यवसितो हि सः॥9.30॥",
    
    "क्षिप्रं(म्) भवति धर्मात्मा, शश्वच्छान्तिं(न्) निगच्छति।\nकौन्तेय  प्रतिजानीहि,  न  मे  भक्तः(फ्)  प्रणश्यति॥9.31॥",
    
    "मां(म्) हि पार्थ व्यपाश्रित्य, येऽपि स्युः(फ्) पापयोनयः।\nस्त्रियो वैश्यास्तथा शूद्रा:(स्), तेऽपि यान्ति परां(ङ्) गतिम्॥9.32॥",
    
    "किं(म्) पुनर्ब्राह्मणाः(फ्) पुण्या, भक्ता राजर्षयस्तथा।\nअनित्यमसुखं(ल्ँ) लोकम्, इमं(म्) प्राप्य भजस्व माम्॥9.33॥",
    
    "मन्मना भव मद्भक्तो, मद्याजी मां(न्) नमस्कुरु।\nमामेवैष्यसि युक्त्वैवम्, आत्मानं(म्) मत्परायणः॥9.34॥",
    
    "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु ब्रह्मविद्यायां(य्ँ) योगशास्त्रे\nश्रीकृष्णार्जुनसंवादे राजविद्याराजगुह्ययोगो नाम नवमोऽध्याय:।।"
  ],
  "adhyaya-10" : [
    "श्रीभगवानुवाच\nभूय एव महाबाहो,शृणु मे परमं(व्ँ) वचः।\nयत्तेऽहं(म्) प्रीयमाणाय, वक्ष्यामि हितकाम्यया॥10.1॥",
    
    "न मे विदुः(स्) सुरगणाः(फ्), प्रभवं(न्) न महर्षयः।\nअहमादिर्हि देवानां(म्), महर्षीणां(ञ्) च सर्वशः॥10.2॥",
    
    "यो मामजमनादिं(ञ्) च,वेत्ति लोकमहेश्वरम्।\nअसम्मूढः(स्) स मर्त्येषु, सर्वपापैः(फ्) प्रमुच्यते॥10.3॥",
    
    "बुद्धिर्ज्ञानमसम्मोहः, क्षमा सत्यं(न्) दमः(श्) शमः।\nसुखं(न्) दुःखं(म्) भवोऽभावो, भयं(ञ्) चाभयमेव च॥10.4॥",
    
    "अहिंसा समता तुष्टि:(स्), तपो दानं(य्ँ) यशोऽयशः।\nभवन्ति भावा भूतानां(म्),मत्त एव पृथग्विधाः॥10.5॥",
    
    "महर्षयः(स्) सप्त पूर्वे,चत्वारो मनवस्तथा।\nमद्भावा मानसा जाता, येषां(ल्ँ) लोक इमाः(फ्) प्रजाः॥10.6॥",
    
    "एतां(व्ँ) विभूतिं(य्ँ) योगं(ञ्) च, मम यो वेत्ति तत्त्वतः।\nसोऽविकम्पेन योगेन,युज्यते नात्र संशयः॥10.7॥",
    
    "अहं(म्) सर्वस्य प्रभवो, मत्तः(स्) सर्वं(म्) प्रवर्तते।\nइति मत्वा भजन्ते मां(म्), बुधा भावसमन्विताः॥10.8॥",
    
    "मच्चित्ता मद्गतप्राणा,बोधयन्तः(फ्) परस्परम्।\nकथयन्तश्च मां(न्) नित्यं(न्), तुष्यन्ति च रमन्ति च॥10.9॥",
    
    "तेषां(म्) सततयुक्तानां(म्), भजतां(म्) प्रीतिपूर्वकम्।\nददामि बुद्धियोगं(न्) तं(य्ँ), येन मामुपयान्ति ते॥10.10॥",
    
    "तेषामेवानुकम्पार्थम्,अहमज्ञानजं(न्) तमः।\nनाशयाम्यात्मभावस्थो, ज्ञानदीपेन भास्वता॥10.11॥",
    
    "अर्जुन उवाच\nपरं(म्) ब्रह्म परं(न्) धाम, पवित्रं(म्) परमं(म्) भवान्।\nपुरुषं(म्) शाश्वतं(न्) दिव्यम्, आदिदेवमजं(व्ँ) विभुम्॥10.12॥",
    
    "आहुस्त्वामृषयः(स्) सर्वे, देवर्षिर्नारदस्तथा।\nअसितो देवलो व्यासः(स्), स्वयं(ञ्) चैव ब्रवीषि मे॥10.13॥",
    
    "सर्वमेतदृतं(म्) मन्ये, यन्मां(व्ँ) वदसि केशव।\nन हि ते भगवन्व्यक्तिं(व्ँ), विदुर्देवा न दानवाः॥10.14॥",
    
    "स्वयमेवात्मनात्मानं(व्ँ), वेत्थ त्वं(म्) पुरुषोत्तम।\nभूतभावन भूतेश,देवदेव जगत्पते॥10.15॥",
    
    "वक्तुमर्हस्यशेषेण, दिव्या ह्यात्मविभूतयः।\nयाभिर्विभूतिभिर्लोकान्, इमांस्त्वं(व्ँ) व्याप्य तिष्ठसि॥10.16॥",
    
    "कथं(व्ँ) विद्यामहं(य्ँ) योगिंस्, त्वां(म्) सदा परिचिन्तयन्।\nकेषु केषु च भावेषु,चिन्त्योऽसि भगवन्मया॥10.17॥",
    
    "विस्तरेणात्मनो योगं(व्ँ), विभूतिं(ञ्) च जनार्दन।\nभूयः(ख्) कथय तृप्तिर्हि, शृण्वतो नास्ति मेऽमृतम्॥10.18॥",
    
    "श्रीभगवानुवाच\nहन्त ते कथयिष्यामि, दिव्या ह्यात्मविभूतयः।\nप्राधान्यतः(ख्) कुरुश्रेष्ठ, नास्त्यन्तो विस्तरस्य मे॥10.19॥",
    
    "अहमात्मा गुडाकेश, सर्वभूताशयस्थितः।\nअहमादिश्च मध्यं(ञ्) च, भूतानामन्त एव च॥10.20॥",
    
    "आदित्यानामहं(व्ँ) विष्णु:(र्), ज्योतिषां(म्) रविरंशुमान्।\nमरीचिर्मरुतामस्मि, नक्षत्राणामहं(म्) शशी॥10.21॥",
    
    "वेदानां(म्) सामवेदोऽस्मि, देवानामस्मि वासवः।\nइन्द्रियाणां(म्) मनश्चास्मि, भूतानामस्मि चेतना॥10.22॥",
    
    "रुद्राणां(म्) शङ्करश्चास्मि,वित्तेशो यक्षरक्षसाम्।\nवसूनां(म्) पावकश्चास्मि, मेरुः(श्) शिखरिणामहम्॥10.23॥",
    
    "पुरोधसां(ञ्) च मुख्यं(म्) मां(व्ँ), विद्धि पार्थ बृहस्पतिम्।\nसेनानीनामहं(म्) स्कन्दः(स्),सरसामस्मि सागरः॥10.24॥",
    
    "महर्षीणां(म्) भृगुरहं(ङ्),गिरामस्म्येकमक्षरम्।\nयज्ञानां(ञ्) जपयज्ञोऽस्मि, स्थावराणां(म्) हिमालयः॥10.25॥",
    
    "अश्वत्थः(स्) सर्ववृक्षाणां(न्),देवर्षीणां(ञ्) च नारदः।\nगन्धर्वाणां(ञ्) चित्ररथः(स्), सिद्धानां(ङ्) कपिलो मुनिः॥10.26॥",
    
    "उच्चैःश्रवसमश्वानां(व्ँ),विद्धि माममृतोद्भवम्।\nऐरावतं(ङ्) गजेन्द्राणां(न्), नराणां(ञ्) च नराधिपम्॥10.27॥",
    
    "आयुधानामहं(व्ँ) वज्रं(न्), धेनूनामस्मि कामधुक्।\nप्रजनश्चास्मि कन्दर्पः(स्), सर्पाणामस्मि वासुकिः॥10.28॥",
    
    "अनन्तश्चास्मि नागानां(व्ँ), वरुणो यादसामहम्।\nपितॄणामर्यमा चास्मि, यमः(स्) संयमतामहम्॥10.29॥",
    
    "प्रह्लादश्चास्मि दैत्यानां(ङ्), कालः(ख्) कलयतामहम्।\nमृगाणां(ञ्) च मृगेन्द्रोऽहं(व्ँ), वैनतेयश्च पक्षिणाम्॥10.30॥",
    
    "पवनः(फ्) पवतामस्मि, रामः(श्) शस्त्रभृतामहम्।\nझषाणां(म्) मकरश्चास्मि, स्रोतसामस्मि जाह्नवी॥10.31॥",
    
    "सर्गाणामादिरन्तश्च,मध्यं(ञ्) चैवाहमर्जुन।\nअध्यात्मविद्या विद्यानां(व्ँ), वादः(फ्) प्रवदतामहम्॥10.32॥",
    
    "अक्षराणामकारोऽस्मि, द्वन्द्व:(स्) सामासिकस्य च।\nअहमेवाक्षयः(ख्) कालो, धाताहं(व्ँ) विश्वतोमुखः॥10.33॥",
    
    "मृत्यु(स्) सर्वहरश्चाहम्, उद्भवश्च भविष्यताम्।\nकीर्तिः(श्) श्रीर्वाक्च नारीणां(म्), स्मृतिर्मेधा धृतिः क्षमा॥10.34॥",
    
    "बृहत्साम तथा साम्नां(ङ्),गायत्री छन्दसामहम्।\nमासानां(म्) मार्गशीर्षोऽहम्, ऋतूनां(ङ्) कुसुमाकरः॥10.35॥",
    
    "द्युतं(ञ्)    छलयतामस्मि,     तेजस्तेजस्विनामहम्।\nजयोऽस्मि व्यवसायोऽस्मि, सत्त्वं(म्) सत्त्ववतामहम्॥10.36॥",
    
    "वृष्णीनां(व्ँ) वासुदेवोऽस्मि, पाण्डवानां(न्) धनञ्जयः।\nमुनीनामप्यहं(व्ँ) व्यासः(ख्), कवीनामुशना कविः॥10.37॥",
    
    "दण्डो दमयतामस्मि, नीतिरस्मि जिगीषताम्।\nमौनं(ञ्) चैवास्मि गुह्यानां(ञ्), ज्ञानं(ञ्) ज्ञानवतामहम्॥10.38॥",
    
    "यच्चापि सर्वभूतानां(म्), बीजं(न्) तदहमर्जुन।\nन तदस्ति विना यत्स्यान्, मया भूतं(ञ्) चराचरम्॥10.39॥",
    
    "नान्तोऽस्ति मम दिव्यानां(व्ँ), विभूतीनां(म्) परन्तप।\nएष तूद्देशतः(फ्) प्रोक्तो,विभूतेर्विस्तरो मया॥10.40॥",
    
    "यद्यद्विभूतिमत्सत्त्वं(म्), श्रीमदूर्जितमेव वा।\nतत्तदेवावगच्छ त्वं(म्), मम तेजोंऽशसम्भवम्॥10.41॥",
    
    "अथवा बहुनैतेन, किं(ञ्) ज्ञातेन तवार्जुन।\nविष्टभ्याहमिदं(ङ्) कृत्स्नम्, एकांशेन स्थितो जगत्॥10.42॥",
    
    "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु ब्रह्मविद्यायां(य्ँ)\nयोगशास्त्रे श्रीकृष्णार्जुनसंवादे विभूतियोगो नाम दशमोऽध्यायः॥"
  ],
  "adhyaya-11" : [
    "अर्जुन उवाच\nमदनुग्रहाय परमं(ङ्), गुह्यमध्यात्मसञ्ज्ञितम्।\nयत्त्वयोक्तं(व्ँ) वचस्तेन, मोहोऽयं(व्ँ) विगतो मम॥11.1॥",
    
    "भवाप्ययौ हि भूतानां(म्), श्रुतौ विस्तरशो मया।\nत्वत्तः(ख्) कमलपत्राक्ष, माहात्म्यमपि चाव्ययम्॥11.2॥",
    
    "एवमेतद्यथात्थ त्वम्, आत्मानं(म्) परमेश्वर।\nद्रष्टुमिच्छामि ते रूपम्, ऐश्वरं(म्) पुरुषोत्तम॥11.3॥",
    
    "मन्यसे यदि तच्छक्यं(म्), मया द्रष्टुमिति प्रभो।\nयोगेश्वर ततो मे त्वं(न्), दर्शयात्मानमव्ययम्॥11.4॥",
    
    "श्रीभगवानुवाच\nपश्य मे पार्थ रूपाणि, शतशोऽथ सहस्रशः।\nनानाविधानि दिव्यानि, नानावर्णाकृतीनि च॥11.5॥",
    
    "पश्यादित्यान्वसून् रुद्रान्, अश्विनौ मरुतस्तथा।\nबहून्यदृष्टपूर्वाणि, पश्याश्चर्याणि भारत॥11.6॥",
    
    "इहैकस्थं(ञ्) जगत्कृत्स्नं(म्), पश्याद्य सचराचरम्।\nमम देहे गुडाकेश, यच्चान्यद्द्रष्टुमिच्छसि॥11.7॥", 
    
    "न तु मां(म्) शक्यसे द्रष्टुम्, अनेनैव स्वचक्षुषा।\nदिव्यं(न्) ददामि ते चक्षुः(फ्), पश्य मे योगमैश्वरम्॥11.8॥",
    
    "सञ्जय उवाच\nएवमुक्त्वा ततो राजन्, महायोगेश्वरो हरिः।\nदर्शयामास पार्थाय, परमं(म्) रूपमैश्वरम्॥11.9॥",
    
    "अनेकवक्त्रनयनम्, अनेकाद्भुतदर्शनम्।\nअनेकदिव्याभरणं(न्), दिव्यानेकोद्यतायुधम्॥11.10॥",
    
    "दिव्यमाल्याम्बरधरं(न्), दिव्यगन्धानुलेपनम्।\nसर्वाश्चर्यमयं(न्) देवम्, अनन्तं(व्ँ) विश्वतोमुखम्॥11.11॥",
    
    "दिविसूर्यसहस्रस्य, भवेद्युगपदुत्थिता।\nयदि भाः(स्) सदृशी सा स्याद्, भासस्तस्य महात्मनः॥11.12॥",
    
    "तत्रैकस्थं(ञ्) जगत्कृत्स्नं(म्), प्रविभक्तमनेकधा।\nअपश्यद्देवदेवस्य,      शरीरे पाण्डवस्तदा॥11.13॥",
    
    "ततः(स्) स विस्मयाविष्टो, हृष्टरोमा धनञ्जयः।\nप्रणम्य शिरसा देवं(ङ्), कृताञ्जलिरभाषत॥11.14॥",
    
    "अर्जुन उवाच\nपश्यामि देवांस्तव देव देहे,\n सर्वांस्तथा भूतविशेषसङ्घान्।\nब्रह्माणमीशं(ङ्) कमलासनस्थम्\n  ऋषींश्च सर्वानुरगांश्च दिव्यान्॥11.15॥",
    
    "अनेकबाहूदरवक्त्रनेत्रं(म्),\nपश्यामि त्वां(म्) सर्वतोऽनन्तरूपम्।\nनान्तं(न्) न मध्यं(न्) न पुनस्तवादिं(म्),\nपश्यामि विश्वेश्वर विश्वरूप॥11.16॥",
    
    "किरीटिनं(ङ्) गदिनं(ञ्) चक्रिणं(ञ्) च,\nतेजोराशिं(म्) सर्वतो दीप्तिमन्तम्।\nपश्यामि त्वां(न्) दुर्निरीक्ष्यं(म्) समन्ताद्-\n दीप्तानलार्कद्युतिमप्रमेयम् ॥11.17॥",
    
    "त्वमक्षरं(म्) परमं(व्ँ) वेदितव्यं(न्),\nत्वमस्य विश्वस्य परं(न्) निधानम्।\nत्वमव्ययः(श्) शाश्वतधर्मगोप्ता,\n सनातनस्त्वं(म्) पुरुषो मतो मे॥11.18॥",
    
    "अनादिमध्यान्तमनन्तवीर्यम्,\nअनन्तबाहुं(म्) शशिसूर्यनेत्रम्।\nपश्यामि त्वां(न्) दीप्तहुताशवक्त्रं(म्),\n स्वतेजसा विश्वमिदं(न्) तपन्तम्॥11.19॥",
    
    "द्यावापृथिव्योरिदमन्तरं(म्) हि,\n व्याप्तं(न्) त्वयैकेन दिशश्च सर्वाः।\nदृष्ट्वाद्भुतं(म्) रूपमुग्रं(न्) तवेदं(ल्ँ),\n लोकत्रयं(म्) प्रव्यथितं(म्) महात्मन्॥11.20॥",
    
    "अमी हि त्वां(म्) सुरसङ्घा विशन्ति,\nकेचिद्भीताः(फ्) प्राञ्जलयो गृणन्ति।\nस्वस्तीत्युक्त्वा महर्षिसिद्धसङ्घा:(स्),\n स्तुवन्ति त्वां(म्) स्तुतिभिः(फ्) पुष्कलाभिः॥11.21॥",
    
    "रुद्रादित्या वसवो ये च साध्या-\nविश्वेऽश्विनौ मरुतश्चोष्मपाश्च।\nगन्धर्वयक्षासुरसिद्धसङ्घा,\nवीक्षन्ते त्वां(व्ँ) विस्मिताश्चैव सर्वे॥11.22॥",
    
    "रूपं(म्) महत्ते बहुवक्त्रनेत्रं(म्),\nमहाबाहो बहुबाहूरुपादम्।\nबहूदरं(म्) बहुदंष्ट्राकरालं(न्),\n दृष्ट्वा लोकाः(फ्) प्रव्यथितास्तथाहम्॥11.23॥",
    
    "नभःस्पृशं(न्) दीप्तमनेकवर्णं(व्ँ),\n व्यात्ताननं(न्) दीप्तविशालनेत्रम्।\nदृष्ट्वा हि त्वां(म्) प्रव्यथितान्तरात्मा,\n धृतिं(न्) न विन्दामि शमं(ञ्) च विष्णो॥11.24॥",
    
    "दंष्ट्राकरालानि च ते मुखानि,\n दृष्ट्वैव कालानलसन्निभानि।\nदिशो न जाने न लभे च शर्म,\n प्रसीद देवेश जगन्निवास॥11.25॥",
    
    "अमी च त्वां(न्) धृतराष्ट्रस्य पुत्राः(स्),\nसर्वे सहैवावनिपालसङ्घैः।\nभीष्मो द्रोणः(स्) सूतपुत्रस्तथासौ,\n सहास्मदीयैरपि योधमुख्यैः॥11.26॥",
    
    "वक्त्राणि ते त्वरमाणा विशन्ति,\n दंष्ट्राकरालानि भयानकानि।\nकेचिद्विलग्ना दशनान्तरेषु,\n सन्दृश्यन्ते चूर्णितैरुत्तमाङ्गै:॥11.27॥",
      
    "यथा नदीनां(म्) बहवोऽम्बुवेगाः(स्),\nसमुद्रमेवाभिमुखा द्रवन्ति।\nतथा तवामी नरलोकवीरा,\nविशन्ति वक्त्राण्यभिविज्वलन्ति॥11.28॥",
    
    "यथा प्रदीप्तं(ञ्) ज्वलनं(म्) पतङ्गा,\n विशन्ति नाशाय समृद्धवेगाः।\nतथैव नाशाय विशन्ति लोका:(स्),\n तवापि वक्त्राणि समृद्धवेगाः॥11.29॥",
    
    "लेलिह्यसे ग्रसमानः(स्) समन्ताल्,\n लोकान्समग्रान्वदनैर्ज्वलद्भिः।\nतेजोभिरापूर्य जगत्समग्रं(म्),\n भासस्तवोग्राः(फ्) प्रतपन्ति विष्णो॥11.30॥",
    
    "आख्याहि मे को भवानुग्ररूपो,\nनमोऽस्तु ते देववर प्रसीद।\nविज्ञातुमिच्छामि भवन्तमाद्यं(न्),\n न हि प्रजानामि तव प्रवृत्तिम्॥11.31॥",
    
    "श्रीभगवानुवाच\nकालोऽस्मि लोकक्षयकृत्प्रवृद्धो,\n लोकान् समाहर्तुमिह प्रवृत्तः।\nऋतेऽपि त्वां(न्) न भविष्यन्ति सर्वे,\n येऽवस्थिताः(फ्) प्रत्यनीकेषु योधाः॥11.32॥",
    
    "तस्मात्त्वमुत्तिष्ठ यशो लभस्व,\n जित्वा शत्रून्भुङ्क्ष्व राज्यं(म्) समृद्धम्।\nमयैवैते निहताः(फ्) पूर्वमेव,\nनिमित्तमात्रं(म्) भव सव्यसाचिन्॥11.33॥",
    
    "द्रोणं(ञ्) च भीष्मं(ञ्) च जयद्रथं(ञ्) च,\n कर्णं(न्) तथान्यानपि योधवीरान्।\nमया हतांस्त्वं(ञ्) जहि मा व्यथिष्ठा,\nयुध्यस्व जेतासि रणे सपत्नान्॥11.34॥",
    
    "सञ्जय उवाच\nएतच्छ्रुत्वा वचनं(ङ्) केशवस्य,\n कृताञ्जलिर्वेपमानः(ख्) किरीटी ।\nनमस्कृत्वा भूय एवाह कृष्णं(म्),\n सगद्गदं(म्) भीतभीतः(फ्) प्रणम्य ॥11.35॥",
    
    "अर्जुन उवाच\nस्थाने हृषीकेश तव प्रकीर्त्या,\nजगत्प्रहृष्यत्यनुरज्यते च।\nरक्षांसि भीतानि दिशो द्रवन्ति,\nसर्वे नमस्यन्ति च सिद्धसङ्घा:॥11.36॥",
    
    "कस्माच्च ते न नमेरन्महात्मन्,\n गरीयसे ब्रह्मणोऽप्यादिकर्त्रे।\nअनन्त देवेश जगन्निवास,\nत्वमक्षरं(म्) सदसत्तत्परं(य्ँ) यत्॥11.37॥",
    
    "त्वमादिदेवः(फ्) पुरुषः(फ्) पुराण:(स्),\nत्वमस्य विश्वस्य परं(न्) निधानम्।\nवेत्तासि वेद्यं(ञ्) च परं(ञ्) च धाम,\n त्वया ततं(व्ँ) विश्वमनन्तरूप॥11.38॥",
    
    "वायुर्यमोऽग्निर्वरुणः(श्) शशाङ्क:(फ्),\nप्रजापतिस्त्वं(म्) प्रपितामहश्च।\nनमो नमस्तेऽस्तु सहस्रकृत्वः(फ्),\nपुनश्च भूयोऽपि नमो नमस्ते॥11.39॥",
    
    "नमः(फ्) पुरस्तादथ पृष्ठतस्ते,\nनमोऽस्तु ते सर्वत एव सर्व।\nअनन्तवीर्यामितविक्रमस्त्वं(म्),\nसर्वं(म्) समाप्नोषि ततोऽसि सर्वः॥11.40॥",
    
    "सखेति मत्वा प्रसभं(य्ँ) यदुक्तं(म्),\nहे कृष्ण हे यादव हे सखेति।\nअजानता महिमानं(न्) तवेदं(म्),\nमया प्रमादात्प्रणयेन वापि॥11.41॥",
    
    "यच्चावहासार्थमसत्कृतोऽसि,\nविहारशय्यासनभोजनेषु।\nएकोऽथवाप्यच्युत तत्समक्षं(न्),\n तत्क्षामये त्वामहमप्रमेयम्॥11.42॥",
    
    "पितासि लोकस्य चराचरस्य,\n त्वमस्य पूज्यश्च गुरुर्गरीयान्।\nन त्वत्समोऽस्त्यभ्यधिकः(ख्) कुतोऽन्यो,\n लोकत्रयेऽप्यप्रतिमप्रभाव॥11.43॥",
    
    "तस्मात्प्रणम्य प्रणिधाय कायं(म्),\n प्रसादये त्वामहमीशमीड्यम्।\nपितेव पुत्रस्य सखेव सख्युः(फ्),\nप्रियः(फ्) प्रियायार्हसि देव सोढुम्॥11.44॥",
    
    "अदृष्टपूर्वं(म्) हृषितोऽस्मि दृष्ट्वा,\n भयेन च प्रव्यथितं(म्) मनो मे।\nतदेव मे दर्शय देवरूपं(म्),\nप्रसीद देवेश जगन्निवास॥11.45॥",
    
    "किरीटिनं(ङ्) गदिनं(ञ्) चक्रहस्तम्,\nइच्छामि त्वां(न्) द्रष्टुमहं(न्) तथैव।\nतेनैव रूपेण चतुर्भुजेन,\n सहस्रबाहो भव विश्वमूर्ते॥11.46॥",
    
    "श्रीभगवानुवाच\nमया प्रसन्नेन तवार्जुनेदं(म्),\n रूपं(म्) परं(न्) दर्शितमात्मयोगात्।\nतेजोमयं(व्ँ) विश्वमनन्तमाद्यं(य्ँ),\nयन्मे त्वदन्येन न दृष्टपूर्वम्॥11.47॥",
    
    "न वेदयज्ञाध्ययनैर्न दानै: (र्),\n न च क्रियाभिर्न तपोभिरुग्रैः।\nएवं(म्)रूपः(श्) शक्य अहं(न्) नृलोके,\nद्रष्टुं(न्) त्वदन्येन कुरुप्रवीर॥11.48॥",
    
    "मा ते व्यथा मा च विमूढभावो,\nदृष्ट्वा रूपं(ङ्) घोरमीदृङ्ममेदम्।\nव्यपेतभीः(फ्) प्रीतमनाः(फ्) पुनस्त्वं(न्),\n तदेव मे रूपमिदं(म्) प्रपश्य॥11.49॥",
    
    "सञ्जय उवाच\nइत्यर्जुनं(म्) वासुदेवस्तथोक्त्वा,\nस्वकं (म्) रूपं(न्) दर्शयामास भूयः।\nआश्वासयामास च भीतमेनं(म्),\n भूत्वा पुनः(स्) सौम्यवपुर्महात्मा॥11.50॥",
    
    "अर्जुन उवाच\nदृष्ट्वेदं(म्) मानुषं(म्) रूपं(न्), तव सौम्यं(ञ्) जनार्दन।\nइदानीमस्मि संवृत्तः(स्), सचेताः(फ्) प्रकृतिं(ङ्) गतः॥11.51॥",
    
    "श्रीभगवानुवाच\nसुदुर्दर्शमिदं(म्) रूपं(न्), दृष्टवानसि यन्मम।\nदेवा अप्यस्य रूपस्य, नित्यं(न्) दर्शनकाङ्क्षिणः॥11.52॥",
    
    "नाहं(व्ँ) वेदैर्न तपसा, न दानेन न चेज्यया।\nशक्य एवंविधो द्रष्टुं(न्), दृष्टवानसि मां यथा॥11.53॥",
    
    "भक्त्या त्वनन्यया शक्य, अहमेवंविधोऽर्जुन।\nज्ञातुं(न्) द्रष्टुं(ञ्) च तत्त्वेन, प्रवेष्टुं(ञ्) च परन्तप ॥11.54॥",
    
    "मत्कर्मकृन्मत्परमो, मद्भक्तः (स्) सङ्गवर्जितः।\nनिर्वैरः (स्) सर्वभूतेषु, यः (स्)  स मामेति पाण्डव ॥11.55॥",
    
    "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु ब्रह्मविद्यायां (य्ँ) योगशास्त्रे\nश्रीकृष्णार्जुनसंवादे 'विश्वरूपदर्शनयोगो' नामैकादशोऽध्यायः ॥11॥"
  ],
  "adhyaya-12" : [
    "अर्जुन उवाच\nएवं(म्) सततयुक्ता ये, भक्तास्त्वां(म्) पर्युपासते।\n ये चाप्यक्षरमव्यक्तं (न्), तेषां(ङ्) के योगवित्तमाः॥12.1॥",
    
    "श्रीभगवानुवाच\nमय्यावेश्य मनो ये मां(न्), नित्ययुक्ता उपासते।\n श्रद्धया परयोपेता:(स्), ते मे युक्ततमा मताः॥12.2॥",
    
    "ये त्वक्षरमनिर्देश्यम्, अव्यक्तं(म्) पर्युपासते।\n सर्वत्रगमचिन्त्यं(ञ्) च, कूटस्थमचलं(न्) ध्रुवम्॥12.3॥",
    
    "सन्नियम्येन्द्रियग्रामं(म्), सर्वत्र समबुद्धयः।\n ते प्राप्नुवन्ति मामेव, सर्वभूतहिते रताः॥12.4॥",
    
    "क्लेशोऽधिकतरस्तेषाम्, अव्यक्तासक्तचेतसाम्।\n अव्यक्ता हि गतिर्दुःखं(न्), देहवद्भिरवाप्यते॥12.5॥",
    
    "ये तु सर्वाणि कर्माणि, मयि सन्न्यस्य मत्पराः।\n अनन्येनैव योगेन, मां(न्) ध्यायन्त उपासते॥12.6॥",
    
    "तेषामहं(म्) समुद्धर्ता, मृत्युसंसारसागरात्।\n भवामि नचिरात्पार्थ, मय्यावेशितचेतसाम्॥12.7॥",
    
    "मय्येव मन आधत्स्व, मयि बुद्धिं(न्) निवेशय।\n निवसिष्यसि मय्येव, अत ऊर्ध्वं(न्) न संशयः॥12.8॥",
    
    "अथ चित्तं(म्) समाधातुं(न्), न शक्नोषि मयि स्थिरम्।\n अभ्यासयोगेन ततो, मामिच्छाप्तुं(न्) धनञ्जय॥12.9॥",
    
    "अभ्यासेऽप्यसमर्थोऽसि, मत्कर्मपरमो भव।\n मदर्थमपि कर्माणि, कुर्वन्सिद्धिमवाप्स्यसि॥12.10॥",
    
    "अथैतदप्यशक्तोऽसि, कर्तुं(म्) मद्योगमाश्रितः।\n सर्वकर्मफलत्यागं(न्), ततः(ख्) कुरु यतात्मवान्॥12.11॥",
    
    "श्रेयो हि ज्ञानमभ्यासाज्, ज्ञानाद्ध्यानं(व्ँ) विशिष्यते।\n ध्यानात्कर्मफलत्याग:(स्),त्यागाच्छान्तिरनन्तरम्॥12.12॥",
    
    "अद्वेष्टा सर्वभूतानां(म्), मैत्रः(ख्) करुण एव च।\n निर्ममो निरहङ्कारः(स्), समदुःखसुखः क्षमी॥12.13॥",
    
    "सन्तुष्टः(स्) सततं(य्ँ) योगी, यतात्मा दृढनिश्चयः।\n मय्यर्पितमनोबुद्धि:(र्), यो मद्भक्तः(स्) स मे प्रियः॥12.14॥",
    
    "यस्मान्नोद्विजते लोको, लोकान्नोद्विजते च यः।\n हर्षामर्षभयोद्वेगै:(र्),  मुक्तो यः(स्) स च मे प्रियः॥12.15॥",
    
    "अनपेक्षः(श्) शुचिर्दक्ष, उदासीनो गतव्यथः।\n सर्वारम्भपरित्यागी, यो मद्भक्तः(स्) स मे प्रियः॥12.16॥",
    
    "यो न हृष्यति न द्वेष्टि, न शोचति न काङ्क्षति।\n शुभाशुभपरित्यागी, भक्तिमान्यः(स्) स मे प्रियः॥12.17॥",
    
    "समः(श्) शत्रौ च मित्रे च, तथा मानापमानयोः।\n शीतोष्णसुखदुःखेषु, समः(स्) सङ्गविवर्जितः॥12.18॥",
    
    "तुल्यनिन्दास्तुतिर्मौनी, सन्तुष्टो येन केनचित्।\n अनिकेतः(स्) स्थिरमति:(र्), भक्तिमान्मे प्रियो नरः॥12.19॥",
    
    "ये तु धर्म्यामृतमिदं(य्ँ), यथोक्तं(म्) पर्युपासते।\n श्रद्दधाना मत्परमा, भक्तास्तेऽतीव मे प्रियाः॥12.20॥",
    
    "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु ब्रह्मविद्यायां(य्ँ) योगशास्त्रे\nश्रीकृष्णार्जुनसंवादे भक्तियोगो नाम द्वादशोऽध्यायः॥"
  ],
  "adhyaya-13" : [
    "श्रीभगवानुवाच\nइदं(म्) शरीरं(ङ्) कौन्तेय, क्षेत्रमित्यभिधीयते।\nएतद्यो वेत्ति तं(म्) प्राहुः, क्षेत्रज्ञ इति तद्विदः॥13.1॥",
    
    "क्षेत्रज्ञं(ञ्) चापि मां(व्ँ) विद्धि, सर्वक्षेत्रेषु भारत।\nक्षेत्रक्षेत्रज्ञयोर्ज्ञानं(य्ँ), यत्तज्ज्ञानं(म्) मतं(म्) मम॥13.2॥",
    
    "तत्क्षेत्रं(य्ँ) यच्च यादृक्च, यद्विकारि यतश्च यत्।\nस च यो यत्प्रभावश्च, तत्समासेन मे शृणु॥13.3॥",
    
    "ऋषिभिर्बहुधा गीतं(ञ्), छन्दोभिर्विविधैः(फ्) पृथक्।\nब्रह्मसूत्रपदैश्चैव, हेतुमद्भिर्विनिश्चितैः॥13.4॥",
    
    "महाभूतान्यहङ्कारो, बुद्धिरव्यक्तमेव च।\nइन्द्रियाणि दशैकं(ञ्) च, पञ्च चेन्द्रियगोचराः॥13.5॥",
    
    "इच्छा द्वेषः(स्) सुखं(न्) दुःखं(म्), सङ्घातश्चेतना धृतिः।\nएतत्क्षेत्रं(म्) समासेन, सविकारमुदाहृतम्॥13.6॥",
    
    "अमानित्वमदम्भित्वम्, अहिंसा क्षान्तिरार्जवम्।\nआचार्योपासनं(म्) शौचं(म्), स्थैर्यमात्मविनिग्रहः॥13.7॥",
    
    "इन्द्रियार्थेषु वैराग्यम्, अनहङ्कार एव च।\nजन्ममृत्युजराव्याधि, दुःखदोषानुदर्शनम्॥13.8॥",
    
    "असक्तिरनभिष्वङ्ग:(फ्), पुत्रदारगृहादिषु।\nनित्यं(ञ्) च समचित्तत्वम्, इष्टानिष्टोपपत्तिषु॥13.9॥",
    
    "मयि चानन्ययोगेन, भक्तिरव्यभिचारिणी।\nविविक्तदेशसेवित्वम्, अरतिर्जनसंसदि॥13.10॥",
    
    "अध्यात्मज्ञाननित्यत्वं(न्), तत्त्वज्ञानार्थदर्शनम्।\nएतज्ज्ञानमिति प्रोक्तम्, अज्ञानं(य्ँ) यदतोऽन्यथा॥13.11॥",
    
    "ज्ञेयं(य्ँ) यत्तत्प्रवक्ष्यामि, यज्ज्ञात्वामृतमश्नुते।\nअनादिमत्परं(म्) ब्रह्म, न सत्तन्नासदुच्यते॥13.12॥",
    
    "सर्वतः(फ्) पाणिपादं(न्) तत्, सर्वतोऽक्षिशिरोमुखम्।\nसर्वतः(श्) श्रुतिमल्लोके, सर्वमावृत्य तिष्ठति॥13.13॥",
    
    "सर्वेन्द्रियगुणाभासं(म्), सर्वेन्द्रियविवर्जितम्।\nअसक्तं(म्) सर्वभृच्चैव, निर्गुणं(ङ्) गुणभोक्तृ च॥13.14॥",
    
    "बहिरन्तश्च भूतानाम्, अचरं(ञ्) चरमेव च।\nसूक्ष्मत्वात्तदविज्ञेयं(न्), दूरस्थं(ञ्) चान्तिके च तत्॥13.15॥",
    
    "अविभक्तं(ञ्) च भूतेषु, विभक्तमिव च स्थितम्।\nभूतभर्तृ च तज्ज्ञेयं(ङ्),  ग्रसिष्णु प्रभविष्णु च॥13.16॥",
    
    "ज्योतिषामपि तज्ज्योति:(स्), तमसः(फ्) परमुच्यते।\nज्ञानं(ञ्) ज्ञेयं(ञ्) ज्ञानगम्यं(म्), हृदि सर्वस्य विष्ठितम्॥13.17॥",
    
    "इति क्षेत्रं(न्) तथा ज्ञानं(ञ्), ज्ञेयं(ञ्) चोक्तं(म्) समासतः।\nमद्भक्त एतद्विज्ञाय, मद्भावायोपपद्यते॥13.18॥",
    
    "प्रकृतिं(म्) पुरुषं(ञ्) चैव, विद्ध्यनादी उभावपि।\nविकारांश्च गुणांश्चैव, विद्धि प्रकृतिसम्भवान्॥13.19॥",
    
    "कार्यकरणकर्तृत्वे, हेतुः(फ्) प्रकृतिरुच्यते।\nपुरुषः(स्) सुखदुःखानां(म्), भोक्तृत्वे हेतुरुच्यते॥13.20॥",
    
    "पुरुषः(फ्) प्रकृतिस्थो हि, भुङ्क्ते प्रकृतिजान्गुणान्।\nकारणं(ङ्) गुणसङ्गोऽस्य, सदसद्योनिजन्मसु॥13.21॥",
    
    "उपद्रष्टानुमन्ता च, भर्ता भोक्ता महेश्वरः।\nपरमात्मेति चाप्युक्तो, देहेऽस्मिन्पुरुषः(फ्) परः॥13.22॥",
    
    "य एवं(व्ँ) वेत्ति पुरुषं(म्), प्रकृतिं(ञ्) च गुणैः(स्) सह।\nसर्वथा वर्तमानोऽपि, न स भूयोऽभिजायते॥13.23॥",
    
    "ध्यानेनात्मनि पश्यन्ति, केचिदात्मानमात्मना।\nअन्ये साङ्ख्येन योगेन, कर्मयोगेन चापरे॥13.24॥",
    
    "अन्ये त्वेवमजानन्तः(श्), श्रुत्वान्येभ्य उपासते।\nतेऽपि चातितरन्त्येव, मृत्युं(म्) श्रुतिपरायणाः॥13.25॥",
    
    "यावत्सञ्जायते किञ्चित्, सत्त्वं(म्) स्थावरजङ्गमम्।\nक्षेत्रक्षेत्रज्ञसंयोगात्, तद्विद्धि भरतर्षभ॥13.26॥",
    
    "समं(म्) सर्वेषु भूतेषु, तिष्ठन्तं(म्) परमेश्वरम्।\nविनश्यत्स्वविनश्यन्तं(य्ँ), यः(फ्) पश्यति स पश्यति ॥13.27॥",
    
    "समं(म्) पश्यन्हि सर्वत्र, समवस्थितमीश्वरम्।\nन हिनस्त्यात्मनात्मानं(न्), ततो याति परां(ङ्) गतिम्॥13.28॥",
    
    "प्रकृत्यैव च कर्माणि, क्रियमाणानि सर्वशः।\nयः(फ्) पश्यति तथात्मानम्, अकर्तारं(म्) स पश्यति॥13.29॥",
    
    "यदा भूतपृथग्भावम्, एकस्थमनुपश्यति।\nतत एव च विस्तारं(म्), ब्रह्म सम्पद्यते तदा॥13.30॥",
    
    "अनादित्वान्निर्गुणत्वात्, परमात्मायमव्ययः।\nशरीरस्थोऽपि कौन्तेय, न करोति न लिप्यते॥13.31॥",
    
    "यथा सर्वगतं(म्) सौक्ष्म्याद्, आकाशं(न्) नोपलिप्यते।\nसर्वत्रावस्थितो देहे, तथात्मा नोपलिप्यते॥13.32॥",
    
    "यथा प्रकाशयत्येकः(ख्), कृत्स्नं(ल्ँ) लोकमिमं(म्) रविः।\nक्षेत्रं(ङ्) क्षेत्री तथा कृत्स्नं(म्), प्रकाशयति भारत॥13.33॥",
    
    "क्षेत्रक्षेत्रज्ञयोरेवम्, अन्तरं(ञ्) ज्ञानचक्षुषा।\nभूतप्रकृतिमोक्षं(ञ्) च, ये विदुर्यान्ति ते परम्॥13.34॥",
    
    "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु ब्रह्मविद्यायां(य्ँ) योगशास्त्रे\nश्रीकृष्णार्जुनसंवादे क्षेत्रक्षेत्रज्ञविभागयोगो नाम त्रयोदशोऽध्यायः॥"
  ],
  "adhyaya-14" : [
    "श्रीभगवानुवाच\nपरं(म्) भूयः(फ्) प्रवक्ष्यामि, ज्ञानानां(ञ्) ज्ञानमुत्तमम्।\nयज्ज्ञात्वा मुनयः(स्) सर्वे, परां(म्) सिद्धिमितो गताः॥14.1॥",
    
    "इदं(ञ्) ज्ञानमुपाश्रित्य, मम साधर्म्यमागताः।\nसर्गेऽपि नोपजायन्ते, प्रलये न व्यथन्ति च॥14.2॥",
    
    "मम योनिर्महद्ब्रह्म, तस्मिन्गर्भं(न्) दधाम्यहम्।\nसम्भवः(स्) सर्वभूतानां(न्), ततो भवति भारत॥14.3॥",
    
    "सर्वयोनिषु कौन्तेय, मूर्तयः(स्)  सम्भवन्ति याः।\nतासां(म्) ब्रह्म महद्योनि:(र्), अहं(म्) बीजप्रदः(फ्) पिता॥14.4॥",
    
    "सत्त्वं(म्) रजस्तम इति, गुणाः(फ्) प्रकृतिसम्भवाः।\nनिबध्नन्ति महाबाहो, देहे देहिनमव्ययम्॥14.5॥",
    
    "तत्र सत्त्वं(न्) निर्मलत्वात्, प्रकाशकमनामयम्।\nसुखसङ्गेन बध्नाति, ज्ञानसङ्गेन चानघ॥14.6॥",
    
    "रजो रागात्मकं(व्ँ) विद्धि, तृष्णासङ्गसमुद्भवम्।\nतन्निबध्नाति कौन्तेय, कर्मसङ्गेन देहिनम्॥14.7॥",
    
    "तमस्त्वज्ञानजं(व्ँ) विद्धि, मोहनं(म्) सर्वदेहिनाम्।\nप्रमादालस्यनिद्राभि:(स्), तन्निबध्नाति भारत॥14.8॥",
    
    "सत्त्वं(म्) सुखे सञ्जयति, रजः(ख्) कर्मणि भारत।\nज्ञानमावृत्य तु तमः(फ्), प्रमादे सञ्जयत्युत॥14.9॥",
    
    "रजस्तमश्चाभिभूय, सत्त्वं(म्) भवति भारत।\nरजः(स्) सत्त्वं(न्) तमश्चैव, तमः(स्) सत्त्वं(म्) रजस्तथा॥14.10॥",
    
    "सर्वद्वारेषु देहेऽस्मिन्, प्रकाश उपजायते।\nज्ञानं(य्ँ) यदा तदा विद्याद्, विवृद्धं(म्) सत्त्वमित्युत॥14.11॥",
    
    "लोभः(फ्) प्रवृत्तिरारम्भः(ख्), कर्मणामशमः(स्) स्पृहा।\nरजस्येतानि जायन्ते, विवृद्धे भरतर्षभ॥14.12॥",
    
    "अप्रकाशोऽप्रवृत्तिश्च, प्रमादो मोह एव च।\nतमस्येतानि जायन्ते, विवृद्धे कुरुनन्दन॥14.13॥",
    
    "यदा सत्त्वे प्रवृद्धे तु, प्रलयं(य्ँ) याति देहभृत्।\nतदोत्तमविदां(ल्ँ) लोकान्, अमलान्प्रतिपद्यते॥14.14॥",
    
    "रजसि प्रलयं(ङ्) गत्वा, कर्मसङ्गिषु जायते।\nतथा प्रलीनस्तमसि, मूढयोनिषु जायते॥14.15॥",
    
    "कर्मणः(स्) सुकृतस्याहुः(स्), सात्त्विकं(न्) निर्मलं(म्) फलम्।\nरजसस्तु फलं(न्) दुःखम्, अज्ञानं(न्) तमसः(फ्) फलम्॥14.16॥",
    
    "सत्त्वात्सञ्जायते ज्ञानं(म्), रजसो लोभ एव च।\nप्रमादमोहौ तमसो, भवतोऽज्ञानमेव च॥14.17॥",
    
    "ऊर्ध्वं(ङ्) गच्छन्ति सत्त्वस्था, मध्ये तिष्ठन्ति राजसाः।\nजघन्यगुणवृत्तिस्था, अधो गच्छन्ति तामसाः॥14.18॥",
    
    "नान्यं(ङ्) गुणेभ्यः(ख्) कर्तारं(य्ँ), यदा द्रष्टानुपश्यति।\nगुणेभ्यश्च परं(व्ँ) वेत्ति, मद्भावं(म्) सोऽधिगच्छति॥14.19॥",
    
    "गुणानेतानतीत्य त्रीन्, देही देहसमुद्भवान्।\nजन्ममृत्युजरादुःखै:(र्), विमुक्तोऽमृतमश्नुते॥14.20॥",
    
    "अर्जुन उवाच\nकैर्लिङ्गैस्त्रीन्गुणानेतान्, अतीतो भवति प्रभो।\nकिमाचारः(ख्) कथं(ञ्) चैतांस्, त्रीन्गुणानतिवर्तते॥14.21॥",
    
    "श्रीभगवानुवाच\nप्रकाशं(ञ्) च प्रवृत्तिं(ञ्) च, मोहमेव च पाण्डव।\nन द्वेष्टि सम्प्रवृत्तानि, न निवृत्तानि काङ्क्षति॥14.22॥",
    
    "उदासीनवदासीनो, गुणैर्यो न विचाल्यते।\nगुणा वर्तन्त इत्येव, योऽवतिष्ठति नेङ्गते॥14.23॥",
    
    "समदुःखसुखः(स्) स्वस्थः(स्), समलोष्टाश्मकाञ्चनः।\nतुल्यप्रियाप्रियो धीर:(स्), तुल्यनिन्दात्मसंस्तुतिः॥14.24॥",
    
    "मानापमानयोस्तुल्य:(स्), तुल्यो मित्रारिपक्षयोः।\nसर्वारम्भपरित्यागी,  गुणातीतः(स्) स उच्यते॥14.25॥",
    
    "मां(ञ्) च योऽव्यभिचारेण, भक्तियोगेन सेवते।\nस गुणान्समतीत्यैतान्, ब्रह्मभूयाय कल्पते॥14.26॥",
    
    "ब्रह्मणो हि प्रतिष्ठाहम्, अमृतस्याव्ययस्य च।\nशाश्वतस्य च धर्मस्य, सुखस्यैकान्तिकस्य च॥14.27॥",
    
    "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु ब्रह्मविद्यायां(य्ँ) योगशास्त्रे\nश्रीकृष्णार्जुनसंवादे गुणत्रयविभागयोगो नाम चतुर्दशोऽध्याय:॥"
  ],
  "adhyaya-15" : [
    "श्रीभगवानुवाच\nऊर्ध्वमूलमधः(श्) शाखम्, अश्वत्थं(म्) प्राहुरव्ययम्।\nछन्दांसि यस्य पर्णानि, यस्तं(व्ँ) वेद स वेदवित्॥15.1॥",
    
    "अधश्चोर्ध्वं(म्) प्रसृतास्तस्य शाखा, गुणप्रवृद्धा विषयप्रवालाः।\nअधश्च मूलान्यनुसन्ततानि, कर्मानुबन्धीनि मनुष्यलोके॥15.2॥",
    
    "न रूपमस्येह तथोपलभ्यते, नान्तो न चादिर्न च सम्प्रतिष्ठा ।\nअश्वत्थमेनं(म्) सुविरूढमूलम्, असङ्गशस्त्रेण दृढेन छित्त्वा॥15.3॥",
    
    "ततः(फ्) पदं(न्) तत्परिमार्गितव्यं(य्ँ), यस्मिन्गता न निवर्तन्ति भूयः।\nतमेव चाद्यं(म्) पुरुषं(म्) प्रपद्ये, यतः(फ्) प्रवृत्तिः(फ्) प्रसृता पुराणी॥15.4॥",
    
    "निर्मानमोहा जितसङ्गदोषा, अध्यात्मनित्या विनिवृत्तकामाः।\nद्वन्द्वैर्विमुक्ताः(स्) सुखदुःखसञ्ज्ञै:(र्), गच्छन्त्यमूढाः(फ्) पदमव्ययं(न्) तत्॥15.5॥",
    
    "न तद्भासयते सूर्यो, न शशाङ्को न पावकः।\nयद्गत्वा न निवर्तन्ते, तद्धाम परमं(म्) मम॥15.6॥",
    
    "ममैवांशो जीवलोके, जीवभूतः(स्) सनातनः।\nमनः(ष्) षष्ठानीन्द्रियाणि, प्रकृतिस्थानि कर्षति॥15.7॥",
    
    "शरीरं(य्ँ) यदवाप्नोति,  यच्चाप्युत्क्रामतीश्वरः।\nगृहीत्वैतानि संयाति,  वायुर्गन्धानिवाशयात्॥15.8॥",
    
    "श्रोत्रं(ञ्) चक्षुः(स्) स्पर्शनं(ञ्) च,  रसनं(ङ्) घ्राणमेव च।\nअधिष्ठाय मनश्चायं(व्ँ), विषयानुपसेवते॥15.9॥",
    
    "उत्क्रामन्तं(म्) स्थितं(व्ँ) वापि, भुञ्जानं(व्ँ) वा गुणान्वितम्।\nविमूढा नानुपश्यन्ति, पश्यन्ति ज्ञानचक्षुषः॥15.10॥",
    
    "यतन्तो योगिनश्चैनं(म्), पश्यन्त्यात्मन्यवस्थितम्।\nयतन्तोऽप्यकृतात्मानो,नैनं(म्) पश्यन्त्यचेतसः॥15.11॥",
    
    "यदादित्यगतं(न्) तेजो, जगद्भासयतेऽखिलम्।\nयच्चन्द्रमसि यच्चाग्नौ,तत्तेजो विद्धि मामकम्॥15.12॥",
    
    "गामाविश्य च भूतानि, धारयाम्यहमोजसा।\nपुष्णामि चौषधीः(स्) सर्वाः(स्),  सोमो भूत्वा रसात्मकः॥15.13॥",
    
    "अहं(व्ँ) वैश्वानरो भूत्वा, प्राणिनां(न्) देहमाश्रितः।\nप्राणापानसमायुक्तः(फ्), पचाम्यन्नं(ञ्) चतुर्विधम्॥15.14॥",
    
    "सर्वस्य चाहं(म्) हृदि सन्निविष्टो, मत्तः(स्) स्मृतिर्ज्ञानमपोहनं(ञ्) च।\nवेदैश्च सर्वैरहमेव वेद्यो, वेदान्तकृद्वेदविदेव चाहम्॥15.15॥",
    
    "द्वाविमौ पुरुषौ लोके, क्षरश्चाक्षर एव च।\nक्षरः(स्) सर्वाणि भूतानि,  कूटस्थोऽक्षर उच्यते॥15.16॥",
    
    "उत्तमः(फ्) पुरुषस्त्वन्यः(फ्),  परमात्मेत्युदाहृतः।\nयो लोकत्रयमाविश्य, बिभर्त्यव्यय ईश्वरः॥15.17॥",
    
    "यस्मात्क्षरमतीतोऽहम्, अक्षरादपि चोत्तमः।\nअतोऽस्मि लोके वेदे च,  प्रथितः(फ्) पुरुषोत्तमः॥15.18॥",
    
    "यो मामेवमसम्मूढो, जानाति पुरुषोत्तमम्।\nस सर्वविद्भजति मां(म्),सर्वभावेन भारत॥15.19॥",
    
    "इति गुह्यतमं(म्) शास्त्रम्, इदमुक्तं(म्) मयानघ।\nएतद्बुद्ध्वा बुद्धिमान्स्यात्, कृतकृत्यश्च भारत॥15.20॥",
    
    "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु ब्रह्मविद्यायां(य्ँ) योगशास्त्रे\nश्रीकृष्णार्जुनसंवादे पुरुषोत्तमयोगो नाम पञ्चदशोऽध्याय:॥"
  ],
  "adhyaya-16" : [
    "श्रीभगवानुवाच\nअभयं(म्) सत्त्वसंशुद्धिः(र्), ज्ञानयोगव्यवस्थितिः।\nदानं(न्) दमश्च यज्ञश्च, स्वाध्यायस्तप आर्जवम्।।16.1।।",
    
    "अहिंसा सत्यमक्रोध:(स्),  त्यागः(श्) शान्तिरपैशुनम् ।\nदया भूतेष्वलोलुप्त्वं(म्), मार्दवं(म्) ह्रीरचापलम्।।16.2।।",
    
    "तेजः क्षमा धृतिः(श्) शौचम्, अद्रोहो नातिमानिता।\nभवन्ति सम्पदं(न्) दैवीम्, अभिजातस्य भारत।।16.3।।",
    
    "दम्भो दर्पोऽभिमानश्च, क्रोधः(फ्) पारुष्यमेव च।\nअज्ञानं(ञ्) चाभिजातस्य, पार्थ सम्पदमासुरीम्।।16.4।।",
    
    "दैवी सम्पद्विमोक्षाय, निबन्धायासुरी मता।\nमा शुचः(स्) सम्पदं(न्) दैवीम्, अभिजातोऽसि पाण्डव।।16.5।।",
    
    "द्वौ भूतसर्गौ लोकेऽस्मिन्, दैव आसुर एव च।\nदैवो विस्तरशः(फ्) प्रोक्त , आसुरं(म्) पार्थ मे शृणु।।16.6।।",
    
    "प्रवृत्तिं(ञ्) च निवृत्तिं(ञ्) च, जना न विदुरासुराः।\nन शौचं(न्) नापि चाचारो, न सत्यं(न्) तेषु विद्यते।।16.7।।",
    
    "असत्यमप्रतिष्ठं(न्) ते, जगदाहुरनीश्वरम्।\nअपरस्परसम्भूतं(ङ्), किमन्यत्कामहैतुकम्।।16.8।।",
    
    "एतां(न्) दृष्टिमवष्टभ्य, नष्टात्मानोऽल्पबुद्धयः।\nप्रभवन्त्युग्रकर्माणः, क्षयाय जगतोऽहिताः।।16.9।।",
    
    "काममाश्रित्य दुष्पूरं(न्), दम्भमानमदान्विताः।\nमोहाद्गृहीत्वासद्ग्राहान्,   प्रवर्तन्तेऽशुचिव्रताः।।16.10।।",
    
    "चिन्तामपरिमेयां(ञ्) च, प्रलयान्तामुपाश्रिताः।\nकामोपभोगपरमा, एतावदिति निश्चिताः।।16.11।।",
    
    "आशापाशशतैर्बद्धाः(ख्), कामक्रोधपरायणाः।\nईहन्ते कामभोगार्थम्, अन्यायेनार्थसञ्चयान्।।16.12।।",
    
    "इदमद्य मया लब्धम्, इमं(म्) प्राप्स्ये मनोरथम्।\nइदमस्तीदमपि मे, भविष्यति पुनर्धनम्।।16.13।।",
    
    "असौ मया हतः(श्) शत्रु:(र्), हनिष्ये चापरानपि।\nईश्वरोऽहमहं(म्) भोगी, सिद्धोऽहं(म्) बलवान्सुखी।।16.14।।",
    
    "आढ्योऽभिजनवानस्मि, कोऽन्योऽस्ति सदृशो मया।\nयक्ष्ये दास्यामि मोदिष्य, इत्यज्ञानविमोहिताः।।16.15।।",
    
    "अनेकचित्तविभ्रान्ता, मोहजालसमावृताः।\nप्रसक्ताः(ख्) कामभोगेषु, पतन्ति नरकेऽशुचौ।।16.16।।",
    
    "आत्मसम्भाविताः(स्) स्तब्धा, धनमानमदान्विताः।\nयजन्ते नामयज्ञैस्ते, दम्भेनाविधिपूर्वकम्।।16.17।।",
    
    "अहङ्कारं(म्) बलं(न्) दर्पं(ङ्), कामं(ङ्) क्रोधं(ञ्) च संश्रिताः।\nमामात्मपरदेहेषु, प्रद्विषन्तोऽभ्यसूयकाः।।16.18।।",
    
    "तानहं(न्) द्विषतः(ख्) क्रूरान् , संसारेषु  नराधमान्।\nक्षिपाम्यजस्रमशुभान्, आसुरीष्वेव योनिषु।।16.19।।",
    
    "आसुरीं(य्ँ) योनिमापन्ना, मूढा जन्मनि जन्मनि।\nमामप्राप्यैव कौन्तेय, ततो यान्त्यधमां(ङ्) गतिम्।।16.20।।",
    
    "त्रिविधं(न्) नरकस्येदं(न्), द्वारं(न्) नाशनमात्मनः।\nकामः(ख्) क्रोधस्तथा लोभ:(स्), तस्मादेतत्त्रयं(न्) त्यजेत्।।16.21।।",
    
    "एतैर्विमुक्तः(ख्) कौन्तेय, तमोद्वारैस्त्रिभिर्नरः।\nआचरत्यात्मनः(श्) श्रेयस् , ततो याति परां(ङ्) गतिम्।।16.22।।",
    
    "यः(श्) शास्त्रविधिमुत्सृज्य, वर्तते कामकारतः।\nन स सिद्धिमवाप्नोति ,  न सुखं(न्)  न परां(ङ्) गतिम् ।।16.23।।",
    
    "तस्माच्छास्त्रं(म्) प्रमाणं(न्) ते, कार्याकार्यव्यवस्थितौ।\nज्ञात्वा शास्त्रविधानोक्तं(ङ्), कर्म कर्तुमिहार्हसि।।16.24।।",
    
    "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु ब्रह्मविद्यायां(य्ँ) योगशास्त्रे\nश्रीकृष्णार्जुनसंवादे दैवासुरसम्पद्विभागयोगो नाम षोडशोऽध्याय:।।"  
  ],
  "adhyaya-17" : [
    "अर्जुन उवाच\nये शास्त्रविधिमुत्सृज्य, यजन्ते श्रद्धयान्विताः।\nतेषां(न्) निष्ठा तु का कृष्ण, सत्त्वमाहो रजस्तमः ॥17.1॥",
    
    "श्रीभगवानुवाच\nत्रिविधा भवति श्रद्धा, देहिनां(म्) सा स्वभावजा।\nसात्त्विकी राजसी चैव, तामसी चेति तां(म्) शृणु॥17.2॥",
    
    "सत्त्वानुरूपा सर्वस्य, श्रद्धा भवति भारत।\nश्रद्धामयोऽयं(म्) पुरुषो, यो यच्छ्रद्धः(स्) स एव सः॥17.3॥",
    
    "यजन्ते सात्त्विका देवान्, यक्षरक्षांसि राजसाः।\nप्रेतान्भूतगणांश्चान्ये, यजन्ते तामसा जनाः॥17.4॥",
    
    "अशास्त्रविहितं(ङ्) घोरं(न्), तप्यन्ते ये तपो जनाः।\nदम्भाहङ्कारसंयुक्ताः(ख्), कामरागबलान्विताः॥17.5॥",
    
    "कर्शयन्तः(श्) शरीरस्थं(म्), भूतग्राममचेतसः।\nमां(ञ्) चैवान्तः(श्) शरीरस्थं(न्), तान्विद्ध्यासुरनिश्चयान्॥17.6॥",
    
    "आहारस्त्वपि सर्वस्य, त्रिविधो भवति प्रियः।\nयज्ञस्तपस्तथा दानं(न्), तेषां(म्) भेदमिमं(म्) शृणु॥17.7॥",
    
    "आयुः(स्) सत्त्वबलारोग्य, सुखप्रीतिविवर्धनाः।\nरस्याः(स्) स्निग्धाः(स्) स्थिरा हृद्या, आहाराः(स्) सात्त्विकप्रियाः॥17.8॥",
    
    "कट्वम्ललवणात्युष्ण, तीक्ष्णरूक्षविदाहिनः।\nआहारा राजसस्येष्टा, दुःखशोकामयप्रदाः॥17.9॥",
    
    "यातयामं(ङ्) गतरसं(म्), पूति पर्युषितं(ञ्) च यत्।\nउच्छिष्टमपि चामेध्यं(म्), भोजनं(न्) तामसप्रियम्॥17.10॥",
    
    "अफलाकाङ्क्षिभिर्यज्ञो, विधिदृष्टो य इज्यते।\nयष्टव्यमेवेति मनः(स्), समाधाय स सात्त्विकः॥17.11॥",
    
    "अभिसन्धाय तु फलं(न्), दम्भार्थमपि चैव यत्।\nइज्यते भरतश्रेष्ठ, तं(य्ँ) यज्ञं(व्ँ) विद्धि राजसम्॥17.12॥",
    
    "विधिहीनमसृष्टान्नं(म्), मन्त्रहीनमदक्षिणम्।\nश्रद्धाविरहितं(य्ँ) यज्ञं(न्), तामसं(म्) परिचक्षते॥17.13॥",
    
    "देवद्विजगुरुप्राज्ञ, पूजनं(म्) शौचमार्जवम्।\nब्रह्मचर्यमहिंसा च, शारीरं(न्) तप उच्यते॥17.14॥",
    
    "अनुद्वेगकरं(व्ँ) वाक्यं(म्), सत्यं(म्) प्रियहितं(ञ्) च यत्।\nस्वाध्यायाभ्यसनं(ञ्) चैव, वाङ्मयं(न्) तप उच्यते॥17.15॥",
    
    "मनः(फ्) प्रसादः(स्) सौम्यत्वं(म्), मौनमात्मविनिग्रहः।\nभावसंशुद्धिरित्येतत्, तपो मानसमुच्यते॥17.16॥",
    
    "श्रद्धया परया तप्तं(न्), तपस्तत्त्रिविधं(न्) नरैः।\nअफलाकाङ्क्षिभिर्युक्तैः(स्), सात्त्विकं(म्) परिचक्षते॥17.17॥",
    
    "सत्कारमानपूजार्थं(न्), तपो दम्भेन चैव यत्।\nक्रियते तदिह प्रोक्तं(म्), राजसं(ञ्) चलमध्रुवम्॥17.18॥",
    
    "मूढग्राहेणात्मनो यत्, पीडया क्रियते तपः।\nपरस्योत्सादनार्थं(व्ँ) वा, तत्तामसमुदाहृतम्॥17.19॥",
    
    "दातव्यमिति यद्दानं(न्), दीयतेऽनुपकारिणे।\nदेशे काले च पात्रे च, तद्दानं(म्) सात्त्विकं(म्) स्मृतम्॥17.20॥",
    
    "यत्तु प्रत्युपकारार्थं(म्), फलमुद्दिश्य वा पुनः।\nदीयते च परिक्लिष्टं(न्), तद्दानं(म्) राजसं(म्) स्मृतम्॥17.21॥",
    
    "अदेशकाले यद्दानम्, अपात्रेभ्यश्च दीयते।\nअसत्कृतमवज्ञातं(न्), तत्तामसमुदाहृतम्॥17.22॥",
    
    "ॐ तत्सदिति निर्देशो, ब्रह्मणस्त्रिविधः(स्) स्मृतः।\nब्राह्मणास्तेन वेदाश्च, यज्ञाश्च विहिताः(फ्) पुरा॥17.23॥",
    
    "तस्मादोमित्युदाहृत्य, यज्ञदानतपः(ख्) क्रियाः।\nप्रवर्तन्ते विधानोक्ताः(स्), सततं(म्) ब्रह्मवादिनाम्॥17.24॥",
    
    "तदित्यनभिसन्धाय, फलं(य्ँ) यज्ञतपः(ख्) क्रियाः।\nदानक्रियाश्च विविधाः(ख्), क्रियन्ते मोक्षकाङ्क्षिभि:॥17.25॥",
    
    "सद्भावे साधुभावे च, सदित्येतत्प्रयुज्यते।\nप्रशस्ते कर्मणि तथा, सच्छब्दः(फ्) पार्थ युज्यते॥17.26॥",
    
    "यज्ञे तपसि दाने च, स्थितिः(स्) सदिति चोच्यते।\nकर्म चैव तदर्थीयं(म्), सदित्येवाभिधीयते॥17.27॥",
    
    "अश्रद्धया हुतं(न्) दत्तं(न्), तपस्तप्तं(ङ्) कृतं(ञ्) च यत्।\nअसदित्युच्यते पार्थ, न च तत्प्रेत्य नो इह17.28॥",
    
    "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु ब्रह्मविद्यायां(य्ँ) योगशास्त्रे\nश्रीकृष्णार्जुनसंवादे श्रद्धात्रयविभागयोगो नाम सप्तदशोऽध्याय:।"
  ],
  "adhyaya-18" : [     
    "अर्जुन उवाच\n सन्न्यासस्य महाबाहो, तत्त्वमिच्छामि वेदितुम्।\nत्यागस्य च हृषीकेश, पृथक्केशिनिषूदन॥18.1॥",
    
    "श्रीभगवानुवाच\nकाम्यानां(ङ्) कर्मणां(न्) न्यासं(म्), सन्न्यासं(ङ्) कवयो विदुः।\nसर्वकर्मफलत्यागं(म्), प्राहुस्त्यागं(व्ँ) विचक्षणाः॥18.2॥",
    
    "त्याज्यं(न्) दोषवदित्येके, कर्म प्राहुर्मनीषिणः।\nयज्ञदानतपःकर्म, न त्याज्यमिति चापरे॥18.3॥",
    
    "निश्चयं(म्) शृणु मे तत्र, त्यागे भरतसत्तम।\nत्यागो हि पुरुषव्याघ्र, त्रिविधः(स्) सम्प्रकीर्तितः॥18.4॥",
    
    "यज्ञदानतपःकर्म, न त्याज्यं(ङ्) कार्यमेव तत्।\nयज्ञो दानं(न्) तपश्चैव, पावनानि मनीषिणाम्॥18.5॥",
    
    "एतान्यपि तु कर्माणि, सङ्गं(न्) त्यक्त्वा फलानि च।\nकर्तव्यानीति मे पार्थ, निश्चितं(म्) मतमुत्तमम्॥18.6॥",
    
    "नियतस्य तु सन्न्यासः(ख्), कर्मणो नोपपद्यते।\nमोहात्तस्य परित्याग:(स्), तामसः(फ्) परिकीर्तितः॥18.7॥",
    
    "दुःखमित्येव यत्कर्म, कायक्लेशभयात्त्यजेत्।\nस कृत्वा राजसं(न्) त्यागं(न्), नैव त्यागफलं(ल्ँ) लभेत् ॥18.8॥",
    
    "कार्यमित्येव यत्कर्म, नियतं(ङ्) क्रियतेऽर्जुन।\nसङ्गं(न्) त्यक्त्वा फलं(ञ्) चैव, स त्यागः(स्) सात्त्विको मतः॥18.9॥",
    
    "न द्वेष्ट्यकुशलं(ङ्) कर्म, कुशले नानुषज्जते।\nत्यागी सत्त्वसमाविष्टो, मेधावी छिन्नसंशयः॥18.10॥",
    
    "न हि देहभृता शक्यं(न्), त्यक्तुं(ङ्) कर्माण्यशेषतः।\nयस्तु कर्मफलत्यागी, स त्यागीत्यभिधीयते॥18.11॥",
    
    "अनिष्टमिष्टं(म्) मिश्रं(ञ्) च, त्रिविधं(ङ्) कर्मणः(फ्) फलम्।\nभवत्यत्यागिनां(म्) प्रेत्य, न तु सन्न्यासिनां(ङ्) क्वचित्॥18.12॥",
    
    "पञ्चैतानि महाबाहो, कारणानि निबोध मे।\nसाङ्ख्ये कृतान्ते प्रोक्तानि, सिद्धये सर्वकर्मणाम्॥18.13॥",
    
    "अधिष्ठानं(न्) तथा कर्ता, करणं(ञ्) च पृथग्विधम्।\nविविधाश्च पृथक्चेष्टा, दैवं(ञ्) चैवात्र पञ्चमम्॥18.14॥",
    
    "शरीरवाङ्मनोभिर्यत्, कर्म प्रारभते नरः।\nन्याय्यं(व्ँ) वा विपरीतं(व्ँ) वा, पञ्चैते तस्य हेतवः॥18.15॥",
    
    "तत्रैवं(म्) सति कर्तारम्, आत्मानं(ङ्) केवलं(न्) तु यः।\nपश्यत्यकृतबुद्धित्वान्, न स पश्यति दुर्मतिः॥18.16॥",
    
    "यस्य नाहङ्कृतो भावो, बुद्धिर्यस्य न लिप्यते।\nहत्वापि स इमाँल्लोकान्, न हन्ति न निबध्यते॥18.17॥",
    
    "ज्ञानं(ञ्) ज्ञेयं(म्) परिज्ञाता, त्रिविधा कर्मचोदना।\nकरणं(ङ्) कर्म कर्तेति, त्रिविधः(ख्) कर्मसङ्ग्रहः॥18.18॥",
    
    "ज्ञानं(ङ्) कर्म च कर्ता च, त्रिधैव गुणभेदतः।\nप्रोच्यते गुणसङ्ख्याने, यथावच्छृणु तान्यपि॥18.19॥",
    
    "सर्वभूतेषु येनैकं(म्), भावमव्ययमीक्षते।\nअविभक्तं(व्ँ) विभक्तेषु, तज्ज्ञानं(व्ँ) विद्धि सात्त्विकम्॥18.20॥",
    
    "पृथक्त्वेन तु यज्ज्ञानं(न्), नानाभावान्पृथग्विधान्।\nवेत्ति सर्वेषु भूतेषु,  तज्ज्ञानं(व्ँ)विद्धि राजसम्॥18.21॥",
    
    "यत्तु कृत्स्नवदेकस्मिन्, कार्ये सक्तमहैतुकम्।\nअतत्त्वार्थवदल्पं(ञ्) च, तत्तामसमुदाहृतम्॥18.22॥",
    
    "नियतं(म्) सङ्गरहितम्, अरागद्वेषतः(ख्) कृतम्।\nअफलप्रेप्सुना कर्म, यत्तत्सात्त्विकमुच्यते॥18.23॥",
    
    "यत्तु कामेप्सुना कर्म, साहङ्कारेण वा पुनः।\nक्रियते बहुलायासं(न्), तद्राजसमुदाहृतम्॥18.24॥",
    
    "अनुबन्धं(ङ्) क्षयं(म्) हिंसाम्, अनवेक्ष्य च पौरुषम्।\nमोहादारभ्यते कर्म, यत्तत्तामसमुच्यते॥18.25॥",
    
    "मुक्तसङ्गोऽनहंवादी, धृत्युत्साहसमन्वितः।\nसिद्ध्यसिद्ध्योर्निर्विकारः(ख्), कर्ता सात्त्विक उच्यते॥18.26॥",
    
    "रागी कर्मफलप्रेप्सु:(र्), लुब्धो हिंसात्मकोऽशुचिः।\nहर्षशोकान्वितः(ख्) कर्ता, राजसः(फ्) परिकीर्तितः॥18.27॥",
    
    "अयुक्तः(फ्) प्राकृतः(स्) स्तब्धः(श्), शठो नैष्कृतिकोऽलसः।\nविषादी दीर्घसूत्री च, कर्ता तामस उच्यते॥18.28॥",
    
    "बुद्धेर्भेदं(न्) धृतेश्चैव, गुणतस्त्रिविधं(म्) शृणु।\nप्रोच्यमानमशेषेण, पृथक्त्वेन धनञ्जय॥18.29॥",
    
    "प्रवृत्तिं(ञ्) च निवृत्तिं(ञ्) च, कार्याकार्ये भयाभये।\nबन्धं(म्) मोक्षं(ञ्) च या वेत्ति, बुद्धिः(स्) सा पार्थ सात्त्विकी॥18.30॥",
    
    "यया धर्ममधर्मं(ञ्) च, कार्यं(ञ्) चाकार्यमेव च।\nअयथावत्प्रजानाति, बुद्धिः(स्) सा पार्थ राजसी॥18.31॥",
    
    "अधर्मं(न्) धर्ममिति या, मन्यते तमसावृता।\nसर्वार्थान्विपरीतांश्च, बुद्धिः(स्) सा पार्थ तामसी॥18.32॥",
    
    "धृत्या यया धारयते, मनःप्राणेन्द्रियक्रियाः।\nयोगेनाव्यभिचारिण्या, धृतिः(स्) सा पार्थ सात्त्विकी॥18.33॥",
    
    "यया तु धर्मकामार्थान्, धृत्या धारयतेऽर्जुन।\nप्रसङ्गेन फलाकाङ्क्षी, धृतिः(स्) सा पार्थ राजसी॥18.34॥",
    
    "यया स्वप्नं(म्) भयं(म्) शोकं(व्ँ), विषादं(म्) मदमेव च।\nन विमुञ्चति दुर्मेधा, धृतिः(स्) सा पार्थ तामसी॥18.35॥",
    
    "सुखं(न्) त्विदानीं(न्) त्रिविधं(म्), शृणु मे भरतर्षभ।\nअभ्यासाद्रमते यत्र, दुःखान्तं(ञ्) च निगच्छति॥18.36॥",
    
    "यत्तदग्रे विषमिव, परिणामेऽमृतोपमम्।\nतत्सुखं(म्) सात्त्विकं(म्) प्रोक्तम्, आत्मबुद्धिप्रसादजम्॥18.37॥",
    
    "विषयेन्द्रियसंयोगाद्, यत्तदग्रेऽमृतोपमम्।\nपरिणामे विषमिव, तत्सुखं(म्) राजसं(म्) स्मृतम्॥18.38॥",
    
    "यदग्रे चानुबन्धे च, सुखं(म्) मोहनमात्मनः।\nनिद्रालस्यप्रमादोत्थं(न्), तत्तामसमुदाहृतम्॥18.39॥",
    
    "न तदस्ति पृथिव्यां(व्ँ) वा, दिवि देवेषु वा पुनः।\nसत्त्वं(म्) प्रकृतिजैर्मुक्तं(य्ँ), यदेभिः(स्) स्यात्त्रिभिर्गुणैः॥18.40॥",
    
    "ब्राह्मणक्षत्रियविशां(म्), शूद्राणां(ञ्) च परन्तप।\nकर्माणि प्रविभक्तानि, स्वभावप्रभवैर्गुणैः॥18.41॥",
    
    "शमो दमस्तपः(श्) शौचं(ङ्), क्षान्तिरार्जवमेव च।\nज्ञानं(व्ँ) विज्ञानमास्तिक्यं(म्), ब्रह्मकर्म स्वभावजम्॥18.42॥",
    
    "शौर्यं(न्) तेजो धृतिर्दाक्ष्यं(य्ँ), युद्धे चाप्यपलायनम्।\nदानमीश्वरभावश्च, क्षात्रं(ङ्) कर्म स्वभावजम्॥18.43॥",
    
    "कृषिगौरक्ष्यवाणिज्यं(व्ँ), वैश्यकर्म स्वभावजम्।\nपरिचर्यात्मकं(ङ्) कर्म, शूद्रस्यापि स्वभावजम्॥18.44॥",
    
    "स्वे स्वे कर्मण्यभिरतः(स्), संसिद्धिं(ल्ँ) लभते नरः।\nस्वकर्मनिरतः(स्) सिद्धिं(य्ँ), यथा विन्दति तच्छृणु॥18.45॥",
    
    "यतः(फ्) प्रवृत्तिर्भूतानां(य्ँ), येन सर्वमिदं(न्) ततम्।\nस्वकर्मणा तमभ्यर्च्य, सिद्धिं(व्ँ) विन्दति मानवः॥18.46॥",
    
    "श्रेयान्स्वधर्मो विगुणः(फ्), परधर्मात्स्वनुष्ठितात्।\nस्वभावनियतं(ङ्) कर्म, कुर्वन्नाप्नोति किल्बिषम्॥18.47॥",
    
    "सहजं(ङ्) कर्म कौन्तेय, सदोषमपि न त्यजेत्।\nसर्वारम्भा हि दोषेण, धूमेनाग्निरिवावृताः॥18.48॥",
    
    "असक्तबुद्धिः(स्) सर्वत्र, जितात्मा विगतस्पृहः।\nनैष्कर्म्यसिद्धिं(म्) परमां(म्), सन्न्यासेनाधिगच्छति॥18.49॥",
    
    "सिद्धिं(म्) प्राप्तो यथा ब्रह्म, तथाप्नोति निबोध मे।\nसमासेनैव कौन्तेय, निष्ठा ज्ञानस्य या परा॥18.50॥",
    
    "बुद्ध्या विशुद्धया युक्तो, धृत्यात्मानं(न्) नियम्य च।\nशब्दादीन्विषयांस्त्यक्त्वा, रागद्वेषौ व्युदस्य च॥18.51॥",
    
    "विविक्तसेवी लघ्वाशी, यतवाक्कायमानसः।\nध्यानयोगपरो नित्यं(व्ँ), वैराग्यं(म्) समुपाश्रितः॥18.52॥",
    
    "अहङ्कारं(म्) बलं(न्) दर्पं(ङ्), कामं(ङ्) क्रोधं(म्) परिग्रहम्।\nविमुच्य निर्ममः(श्) शान्तो, ब्रह्मभूयाय कल्पते॥18.53॥",
    
    "ब्रह्मभूतः(फ्) प्रसन्नात्मा, न शोचति न काङ्क्षति।\nसमः(स्) सर्वेषु भूतेषु, मद्भक्तिं(ल्ँ) लभते पराम्॥18.54॥",
    
    "भक्त्या मामभिजानाति, यावान्यश्चास्मि तत्त्वतः।\nततो मां(न्) तत्त्वतो ज्ञात्वा, विशते तदनन्तरम्॥18.55॥",
    
    "सर्वकर्माण्यपि सदा, कुर्वाणो मद् व्यपाश्रयः।\nमत्प्रसादादवाप्नोति, शाश्वतं(म्) पदमव्ययम्॥18.56॥",
    
    "चेतसा सर्वकर्माणि, मयि सन्न्यस्य मत्परः।\nबुद्धियोगमुपाश्रित्य, मच्चित्तः(स्) सततं(म्) भव॥18.57॥",
    
    "मच्चित्तः(स्) सर्वदुर्गाणि, मत्प्रसादात्तरिष्यसि।\nअथ चेत्त्वमहङ्कारान्, न श्रोष्यसि विनङ्क्ष्यसि॥18.58॥",
    
    "यदहङ्कारमाश्रित्य, न योत्स्य इति मन्यसे।\nमिथ्यैष व्यवसायस्ते, प्रकृतिस्त्वां(न्) नियोक्ष्यति॥18.59॥",
    
    "स्वभावजेन कौन्तेय, निबद्धः(स्) स्वेन कर्मणा।\nकर्तुं(न्) नेच्छसि यन्मोहात्, करिष्यस्यवशोऽपि तत्॥18.60॥",
    
    "ईश्वरः(स्) सर्वभूतानां(म्), हृद्देशेऽर्जुन तिष्ठति।\nभ्रामयन्सर्वभूतानि, यन्त्रारूढानि मायया॥18.61॥",
    
    "तमेव शरणं(ङ्) गच्छ, सर्वभावेन भारत।\nतत्प्रसादात्परां(म्) शान्तिं(म्), स्थानं(म्) प्राप्स्यसि शाश्वतम्॥18.62॥",
    
    "इति ते ज्ञानमाख्यातं(ङ्), गुह्याद्गुह्यतरं(म्) मया।\nविमृश्यैतदशेषेण, यथेच्छसि तथा कुरु॥18.63॥",
    
    "सर्वगुह्यतमं(म्) भूयः(श्), शृणु मे परमं(व्ँ) वचः।\nइष्टोऽसि मे दृढमिति, ततो वक्ष्यामि ते हितम्॥18.64॥",
    
    "मन्मना भव मद्भक्तो, मद्याजी मां(न्) नमस्कुरु।\nमामेवैष्यसि सत्यं(न्) ते, प्रतिजाने प्रियोऽसि मे॥18.65॥",
    
    "सर्वधर्मान्परित्यज्य, मामेकं(म्) शरणं(व्ँ) व्रज।\nअहं(न्) त्वा सर्वपापेभ्यो, मोक्षयिष्यामि मा शुचः॥18.66॥",
    
    "इदं(न्) ते नातपस्काय, नाभक्ताय कदाचन।\nन चाशुश्रूषवे वाच्यं(न्), न च मां(य्ँ) योऽभ्यसूयति॥18.67॥",
    
    "य इमं(म्) परमं(ङ्) गुह्यं(म्), मद्भक्तेष्वभिधास्यति।\nभक्तिं(म्) मयि परां(ङ्) कृत्वा, मामेवैष्यत्यसंशयः॥18.68॥",
    
    "न च तस्मान्मनुष्येषु, कश्चिन्मे प्रियकृत्तमः।\nभविता न च मे तस्माद्, अन्यः(फ्) प्रियतरो भुवि॥18.69॥",
    
    "अध्येष्यते च य इमं(न्), धर्म्यं(म्) संवादमावयोः।\nज्ञानयज्ञेन तेनाहम्, इष्टः(स्) स्यामिति मे मतिः॥18.70॥",
    
    "श्रद्धावाननसूयश्च, शृणुयादपि यो नरः।\nसोऽपि मुक्तः(श्) शुभाँल्लोकान्, प्राप्नुयात्पुण्यकर्मणाम्॥18.71॥",
    
    "कच्चिदेतच्छुतं(म्) पार्थ, त्वयैकाग्रेण चेतसा।\nकच्चिदज्ञानसम्मोहः(फ्), प्रनष्टस्ते धनञ्जय॥18.72॥",
    
    "अर्जुन उवाच\n नष्टो मोहः(स्) स्मृतिर्लब्धा, त्वत्प्रसादान्मयाच्युत।\nस्थितोऽस्मि गतसन्देहः(ख्), करिष्ये वचनं(न्) तव॥18.73॥",
    
    "सञ्जय उवाच\nइत्यहं(व्ँ) वासुदेवस्य, पार्थस्य च महात्मनः।\nसंवादमिममश्रौषम्, अद्भुतं(म्) रोमहर्षणम्॥18.74॥",
    
    "व्यासप्रसादाच्छ्रुतवान्, एतद्गुह्यमहं(म्) परम्।\nयोगं(य्ँ) योगेश्वरात्कृष्णात्, साक्षात्कथयतः(स्) स्वयम्॥18.75॥",
    
    "राजन्संस्मृत्य संस्मृत्य, संवादमिममद्भुतम्।\nकेशवार्जुनयोः(फ्) पुण्यं(म्), हृष्यामि च मुहुर्मुहुः॥18.76॥",
    
    "तच्च संस्मृत्य संस्मृत्य, रूपमत्यद्भुतं(म्) हरेः।\nविस्मयो मे महान् राजन्, हृष्यामि च पुनः(फ्) पुनः॥18.77॥",
    
    "यत्र योगेश्वरः(ख्) कृष्णो, यत्र पार्थो धनुर्धरः।\nतत्र श्रीर्विजयो भूति:(र्), ध्रुवा नीतिर्मतिर्मम॥18.78॥",
    
    "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु ब्रह्मविद्यायां(य्ँ) योगशास्त्रे\nश्रीकृष्णार्जुनसंवादे मोक्षसन्न्यासयोगो नाम अष्टादशोऽध्यायः ॥18॥"  
  ]
},
{
  "adhyaya-1" : [
    "धृतराष्ट्र बोले  - हे संजय!  धर्मभूमि कुरुक्षेत्र में युद्ध की इच्छा से इकट्ठे हुए मेरे और पाण्डु के पुत्रों ने भी क्या किया? 1.1",

    "संजय बोले - उस समय वज्रव्यूह-से खड़ी हुई पाण्डव-सेना को देखकर राजा दुर्योधन द्रोणाचार्य के पास जाकर यह वचन बोला। 1.2",

    "हे आचार्य! आपके बुद्धिमान शिष्य द्रुपदपुत्र धृष्टद्युम्न के द्वारा व्यूह रचना से खड़ी की हुई पाण्डवों की इस बड़ी भारी सेना को देखिये। 1.3",

    "यहाँ (पाण्डवों की सेना में) बड़े-बड़े शूरवीर हैं, (जिनके) बहुत बड़े-बड़े धनुष हैं तथा (जो) युद्ध में भीम और अर्जुन के समान हैं। (उनमें) युयुधान (सात्यकि), राजा विराट और महारथी द्रुपद (भी हैं)। धृष्टकेतु और चेकितान तथा पराक्रमी काशिराज (भी हैं)। पुरुजित् और कुन्तिभोज – (ये दोनों भाई) तथा मनुष्यों में श्रेष्ठ शैब्य (भी हैं)। पराक्रमी युधामन्यु और पराक्रमी उत्तमौजा (भी हैं)। सुभद्रा पुत्र अभिमन्यु और द्रौपदी के पाँचों पुत्र (भी हैं)। (ये) सब के सब महारथी हैं। (1.4-1.6)",
    "यहाँ (पाण्डवों की सेना में) बड़े-बड़े शूरवीर हैं, (जिनके) बहुत बड़े-बड़े धनुष हैं तथा (जो) युद्ध में भीम और अर्जुन के समान हैं। (उनमें) युयुधान (सात्यकि), राजा विराट और महारथी द्रुपद (भी हैं)। धृष्टकेतु और चेकितान तथा पराक्रमी काशिराज (भी हैं)। पुरुजित् और कुन्तिभोज – (ये दोनों भाई) तथा मनुष्यों में श्रेष्ठ शैब्य (भी हैं)। पराक्रमी युधामन्यु और पराक्रमी उत्तमौजा (भी हैं)। सुभद्रा पुत्र अभिमन्यु और द्रौपदी के पाँचों पुत्र (भी हैं)। (ये) सब के सब महारथी हैं। (1.4-1.6)",
    "यहाँ (पाण्डवों की सेना में) बड़े-बड़े शूरवीर हैं, (जिनके) बहुत बड़े-बड़े धनुष हैं तथा (जो) युद्ध में भीम और अर्जुन के समान हैं। (उनमें) युयुधान (सात्यकि), राजा विराट और महारथी द्रुपद (भी हैं)। धृष्टकेतु और चेकितान तथा पराक्रमी काशिराज (भी हैं)। पुरुजित् और कुन्तिभोज – (ये दोनों भाई) तथा मनुष्यों में श्रेष्ठ शैब्य (भी हैं)। पराक्रमी युधामन्यु और पराक्रमी उत्तमौजा (भी हैं)। सुभद्रा पुत्र अभिमन्यु और द्रौपदी के पाँचों पुत्र (भी हैं)। (ये) सब के सब महारथी हैं। (1.4-1.6)",

    "हे द्विजोत्तम! हमारे पक्ष में भी जो मुख्य (हैं), उन पर भी (आप) ध्यान दीजिये। आपको याद दिलाने के लिये मेरी सेना के (जो) नायक हैं, उनको (मैं) कहता हूँ। 1.7",

    "आप (द्रोणाचार्य) और पितामह भीष्म तथा कर्ण और संग्राम विजयी कृपाचार्य तथा वैसे ही अश्वत्थामा, विकर्ण और सोमदत्त का पुत्र भूरिश्रवा। 1.8",

    "इनके अतिरिक्त बहुत से शूरवीर हैं, (जिन्होंने) मेरे लिये अपने जीने की इच्छा का भी त्याग कर दिया है और जो अनेक प्रकार के शस्त्र-अस्त्रों को चलाने वाले हैं (तथा जो) सब के सब युद्धकला में अत्यन्त चतुर हैं। 1.9",

    "द्रोणाचार्य को चुप देखकर दुर्योधन के मन में विचार हुआ कि वास्तव में - वह हमारी सेना पाण्डवों पर विजय करने में अपर्याप्त है, असमर्थ है; क्योंकि उसके संरक्षक (उभय पक्षपाती) भीष्म हैं। परन्तु इन पाण्डवों की यह सेना (हम पर विजय करने में) पर्याप्त है, समर्थ है; (क्योंकि) इसके संरक्षक (निज सेना पक्षपाती) भीमसेन हैं। 1.10",

    "दुर्योधन बाह्य दृष्टि से अपनी सेना के महारथियों से बोला - आप सब के सब लोग सभी मोर्चों पर अपनी-अपनी जगह दृढ़ता से स्थित रहते हुए ही निश्चित रूप से पितामह भीष्म की चारों ओर से रक्षा करें। 1.11",

    "उस (दुर्योधन) के (हृदय में) हर्ष उत्पन्न करते हुए कौरवों में वृद्ध प्रभावशाली पितामह भीष्म ने सिंह के समान गरज कर जोर से शंख बजाया। 1.12",

    "उसके बाद शंख और भेरी (नगाड़े) तथा ढोल, मृदंग और नरसिंघे बाजे एक साथ ही बज उठे। (उनका) वह शब्द बड़ा भयंकर हुआ। 1.13",

    "उसके बाद सफेद घोड़ों से युक्त महान रथ पर बैठे हुए लक्ष्मीपति भगवान् श्रीकृष्ण और पाण्डुपुत्र अर्जुन ने भी दिव्य शंखों को बड़े जोर से बजाया। 1.14",

    "अन्तर्यामी भगवान् श्रीकृष्ण ने पाञ्चजन्य नामक (तथा) धनञ्जय अर्जुन ने देवदत्त नामक (शंख बजाया और) भयानक कर्म करने वाले वृकोदर भीम ने पौण्ड्र नामक महाशंख बजाया। 1.15",

    "कुन्तीपुत्र राजा युधिष्ठिर ने अनन्तविजय नामक (शंख बजाया तथा) नकुल और सहदेव ने सुघोष और मणिपुष्पक नामक (शंख बजाये) 1.16",

    "हे राजन्! श्रेष्ठ धनुष वाले काशिराज और महारथी शिखण्डी तथा धृष्टद्युम्न एवं राजा विराट और अजेय सात्यकि, राजा द्रुपद और द्रौपदी के पाँचों पुत्र तथा लम्बी-लम्बी भुजाओं वाले सुभद्रापुत्र अभिमन्यु (इन सभी ने) सब ओर से अलग-अलग (अपने- अपने) शंख बजाये। (1.17-1.18)",
    "हे राजन्! श्रेष्ठ धनुष वाले काशिराज और महारथी शिखण्डी तथा धृष्टद्युम्न एवं राजा विराट और अजेय सात्यकि, राजा द्रुपद और द्रौपदी के पाँचों पुत्र तथा लम्बी-लम्बी भुजाओं वाले सुभद्रापुत्र अभिमन्यु (इन सभी ने) सब ओर से अलग-अलग (अपने- अपने) शंख बजाये। (1.17-1.18)",        
    
    "और (पाण्डव-सेना के शंखों के) उस भयंकर शब्द ने आकाश और पृथ्वी को भी गुँजाते हुए अन्यायपूर्वक राज्य हड़पने वाले दुर्योधन आदि के हृदय विदीर्ण कर दिये। 1.19",

    "हे महीपते धृतराष्ट्र! अब शस्त्रों के चलने की तैयारी हो ही रही थी कि उस समय अन्यायपूर्वक राज्य को धारण करने वाले राजाओं और उनके साथियों को व्यवस्थित रूप से सामने खड़े हुए देखकर कपिध्वज पाण्डुपुत्र अर्जुन ने (अपना) गाण्डीव धनुष उठा लिया (और) अन्तर्यामी भगवान् श्रीकृष्ण से यह वचन बोले। (1.20-1.21)",

    "अर्जुन बोले - हे अच्युत! दोनों सेनाओं के मध्य में मेरे रथ को (आप तब तक) खड़ा कीजिये, जब तक मैं (युद्धक्षेत्र में) खड़े हुए इन युद्ध की इच्छा वालों को देख न लूँ कि इस युद्धरूप उद्योग में मुझे किन-किन के साथ युद्ध करना योग्य है। (1.21-1.22}",
    "अर्जुन बोले - हे अच्युत! दोनों सेनाओं के मध्य में मेरे रथ को (आप तब तक) खड़ा कीजिये, जब तक मैं (युद्धक्षेत्र में) खड़े हुए इन युद्ध की इच्छा वालों को देख न लूँ कि इस युद्धरूप उद्योग में मुझे किन-किन के साथ युद्ध करना योग्य है। (1.21-1.22}",
    
    "दुष्टबुद्धि दुर्योधन का युद्ध में प्रिय करने की इच्छा वाले जो ये राजा लोग इस सेना में आये हुए हैं, युद्ध करने को उतावले हुए (इन सबको) मैं देख लूँ। 1.23",

    "संजय बोले - हे भरतवंशी राजन्! निद्रा विजयी अर्जुन के द्वारा इस तरह कहने पर अन्तर्यामी भगवान् श्रीकृष्ण ने दोनों सेनाओं के मध्य भाग में पितामह भीष्म और आचार्य द्रोण के सामने तथा सम्पूर्ण राजाओं के सामने श्रेष्ठ रथ को खड़ा करके इस तरह कहा कि 'हे पार्थ! इन इकट्ठे हुए कुरुवंशियों को देख'। (1.24-1.25)",
    "संजय बोले - हे भरतवंशी राजन्! निद्रा विजयी अर्जुन के द्वारा इस तरह कहने पर अन्तर्यामी भगवान् श्रीकृष्ण ने दोनों सेनाओं के मध्य भाग में पितामह भीष्म और आचार्य द्रोण के सामने तथा सम्पूर्ण राजाओं के सामने श्रेष्ठ रथ को खड़ा करके इस तरह कहा कि 'हे पार्थ! इन इकट्ठे हुए कुरुवंशियों को देख'। (1.24-1.25)",

    "उसके बाद पृथानन्दन अर्जुन ने उन दोनों ही सेनाओं में स्थित पिताओं को, पितामहों को, आचार्यों को, मामाओं को, भाइयों को, पुत्रों को, पौत्रों को तथा मित्रों को, ससुरों को और सुहृदों को भी देखा। (1.26-1.27)",
    "उसके बाद पृथानन्दन अर्जुन ने उन दोनों ही सेनाओं में स्थित पिताओं को, पितामहों को, आचार्यों को, मामाओं को, भाइयों को, पुत्रों को, पौत्रों को तथा मित्रों को, ससुरों को और सुहृदों को भी देखा। (1.26-1.27)",

    "अपनी अपनी जगह पर स्थित उन सम्पूर्ण बान्धवों को देखकर वे कुन्तीनन्दन अर्जुन अत्यन्त कायरता से युक्त होकर विषाद करते हुए ऐसा बोले। 1.28",

    "अर्जुन बोले - हे कृष्ण! युद्ध की इच्छा वाले इस कुटुम्ब-समुदाय को अपने सामने उपस्थित देखकर मेरे अंग शिथिल हो रहे हैं और मुख सूख रहा है तथा मेरे शरीर में कँपकँपी (आ रही है) एवं रोंगटे खड़े हो रहे हैं। हाथ से गाण्डीव धनुष गिर रहा है और त्वचा भी जल रही है। मेरा मन भ्रमित-सा हो रहा है और (मैं) खड़े रहने में भी असमर्थ हो रहा हूँ। (1.28-1.30)",
    "अर्जुन बोले - हे कृष्ण! युद्ध की इच्छा वाले इस कुटुम्ब-समुदाय को अपने सामने उपस्थित देखकर मेरे अंग शिथिल हो रहे हैं और मुख सूख रहा है तथा मेरे शरीर में कँपकँपी (आ रही है) एवं रोंगटे खड़े हो रहे हैं। हाथ से गाण्डीव धनुष गिर रहा है और त्वचा भी जल रही है। मेरा मन भ्रमित-सा हो रहा है और (मैं) खड़े रहने में भी असमर्थ हो रहा हूँ। (1.28-1.30)",
    
    "हे केशव! मैं लक्षणों (शकुनों) को भी विपरीत देख रहा हूँ (और) युद्ध में स्वजनों को मारकर श्रेय (लाभ) भी नहीं देख रहा हूँ। 1.31",

    "हे कृष्ण! (मैं) न तो विजय चाहता हूँ, न राज्य (चाहता हूँ) और न सुखों को (ही चाहता हूँ)। हे गोविन्द! हम लोगों को राज्य से क्या लाभ? भोगों से (क्या लाभ)? अथवा जीने से (भी) क्या लाभ? 1.32",

    "जिनके लिये हमारी राज्य, भोग और सुख की इच्छा है, वे (ही) ये सब (अपने) प्राणों की और धन की आशा का त्याग करके युद्ध में खड़े हैं। 1.33",

    "आचार्य, पिता, पुत्र और उसी प्रकार पितामह, मामा, ससुर, पौत्र, साले तथा (अन्य जितने भी) सम्बन्धी हैं, (मुझ पर) प्रहार करने पर भी (मैं) इनको मारना नहीं चाहता, (और) हे मधुसूदन! (मुझे) त्रिलोकी का राज्य मिलता हो, तो भी (मैं इनको मारना नहीं चाहता), फिर पृथ्वी के लिये तो (मैं इनको मारूँ ही) क्या? (1.34-1.35)",
    "आचार्य, पिता, पुत्र और उसी प्रकार पितामह, मामा, ससुर, पौत्र, साले तथा (अन्य जितने भी) सम्बन्धी हैं, (मुझ पर) प्रहार करने पर भी (मैं) इनको मारना नहीं चाहता, (और) हे मधुसूदन! (मुझे) त्रिलोकी का राज्य मिलता हो, तो भी (मैं इनको मारना नहीं चाहता), फिर पृथ्वी के लिये तो (मैं इनको मारूँ ही) क्या? (1.34-1.35)",
    
    "हे जनार्दन! (इन) धृतराष्ट्र-सम्बन्धियों को मारकर हम लोगों को क्या प्रसन्नता होगी? इन आततायियों को मारने से तो हमें पाप ही लगेगा। 1.36",

    "इसलिये अपने बान्धव (इन) धृतराष्ट्र-सम्बन्धियों को मारने के लिये हम योग्य नहीं हैं; क्योंकि हे माधव! अपने कुटुम्बियों को मारकर (हम) कैसे सुखी होंगे? 1.37",

    "यद्यपि लोभ के कारण जिनका विवेक-विचार लुप्त हो गया है, ऐसे ये (दुर्योधन आदि) कुल का नाश करने से होने वाले दोष को और मित्रों के साथ द्वेष करने से होने वाले पाप को नहीं देखते, (तो भी) हे जनार्दन! कुल का नाश करने से होने वाले दोष को ठीक-ठीक जानने वाले हम लोग इस पाप से निवृत्त होने का विचार क्यों न करें? (1.38-1.39)",
    "यद्यपि लोभ के कारण जिनका विवेक-विचार लुप्त हो गया है, ऐसे ये (दुर्योधन आदि) कुल का नाश करने से होने वाले दोष को और मित्रों के साथ द्वेष करने से होने वाले पाप को नहीं देखते, (तो भी) हे जनार्दन! कुल का नाश करने से होने वाले दोष को ठीक-ठीक जानने वाले हम लोग इस पाप से निवृत्त होने का विचार क्यों न करें? (1.38-1.39)",
    
    "कुल का क्षय होने पर सदा से चलते आये कुलधर्म नष्ट हो जाते हैं और धर्म का नाश होने पर (बचे हुए) सम्पूर्ण कुल को अधर्म दबा लेता है। 1.40",

    "हे कृष्ण! अधर्म के अधिक बढ़ जाने से कुल की स्त्रियाँ दूषित हो जाती हैं; (और) हे वार्ष्णेय! स्त्रियों के दूषित होने पर वर्णसंकर पैदा हो जाते हैं। 1.41",

    "वर्णसंकर कुलघातियों को और कुल को नरक में ले जाने वाला ही (होता है)। श्राद्ध और तर्पण न मिलने से इन (कुलघातियों) के पितर भी (अपने स्थान से) गिर जाते हैं। 1.42",

    "इन वर्णसंकर पैदा करने वाले दोषों से कुलघातियों के सदा से चलते आये कुलधर्म और जातिधर्म नष्ट हो जाते हैं। 1.43",

    "हे जनार्दन! जिनके कुलधर्म नष्ट हो जाते हैं, (उन) मनुष्यों का बहुत काल तक नरकों में वास होता है, ऐसा (हम) सुनते आये हैं। 1.44",

    "यह बड़े आश्चर्य (और) खेद की बात है कि हम लोग बड़ा भारी पाप करने का निश्चय कर बैठे हैं, जो कि राज्य और सुख के लोभ से अपने स्वजनों को मारने के लिये तैयार हो गये हैं। 1.45",

    "अगर (ये) हाथों में शस्त्र-अस्त्र लिये हुए धृतराष्ट्र के पक्षपाती लोग युद्धभूमि में सामना न करने वाले (तथा) शस्त्र रहित मुझ को मार भी दें (तो) वह मेरे लिये बड़ा ही हितकारक होगा। 1.46",

    "संजय बोले - ऐसा कहकर शोकाकुल मन वाले अर्जुन बाण सहित धनुष का त्याग करके युद्धभूमि में रथ के मध्य भाग में बैठ गये। 1.47",
    
    "इस प्रकार ॐ तत  सत - इन भगवन्नामों के उच्चारणपूर्वक ब्रह्मविद्या और योगशास्त्रमय श्रीमद्भगवद्गीतोपनिषदरूप श्रीकृष्णार्जुनसंवाद में ‘अर्जुनविषादयोगनामक’ पहला अध्याय पूर्ण हुआ।"
  ],
  "adhyaya-2" : [
    "संजय बोले - वैसी कायरता से व्याप्त हुए उन अर्जुन के प्रति, जो कि विषाद कर रहे हैं (और) आँसुओं के कारण जिनके नेत्रों की देखने की शक्ति अवरुद्ध हो रही है, भगवान् मधुसूदन यह (आगे कहे जाने वाले) वचन बोले। 2.1",

    "श्रीभगवान् बोले - हे अर्जुन! इस विषम अवसर पर तुम्हें यह कायरता कहाँ से प्राप्त हुई, (जिसका कि) श्रेष्ठ पुरुष सेवन नहीं करते, (जो) स्वर्ग को देनेवाली नहीं है और कीर्ति करने वाली भी नहीं है। 2.2",

    "हे पृथानन्दन अर्जुन ! इस नपुंसकता को मत प्राप्त हो; (क्योंकि) तुम्हारे में यह उचित नहीं है। हे परंतप ! हृदय की इस तुच्छ दुर्बलता का त्याग करके (युद्धके लिये) खड़े हो जाओ। 2.3",

    "अर्जुन बोले - हे मधुसूदन! मैं रणभूमि में भीष्म और द्रोण के साथ बाणों से युद्ध कैसे करूँ? (क्योंकि) हे अरिसूदन! (ये) दोनों ही पूजा के योग्य हैं। 2.4",

    "महानुभाव गुरुजनों को न मारकर इस लोक में (मैं) भिक्षा का अन्न खाना भी श्रेष्ठ (समझता हूँ);  क्योंकि गुरुजनों को मारकर यहाँ रक्त से सने हुए (तथा) धन की कामना की मुख्यता वाले भोगों को ही तो भोगूँगा! 2.5 ",

    "(हम) यह भी नहीं जानते (कि) हम लोगों के लिये (युद्ध करना और न करना, इन) दोनों में से कौन-सा अत्यन्त श्रेष्ठ है अथवा (हम उन्हें जीतेंगे) या (वे) हमें जीतेंगे। जिनको मारकर (हम) जीना भी नहीं चाहते, वे ही धृतराष्ट्रके सम्बन्धी (हमारे) सामने खड़े हैं। 2.6",

    "कायरता रूप दोष से तिरस्कृत स्वभाव वाला (और) धर्म के विषय में मोहित अन्तःकरण वाला (मैं) आपसे पूछता हूँ (कि) जो निश्चित कल्याण करने वाली हो, वह (बात) मेरे लिये कहिये। मैं आपका शिष्य हूँ। आपके शरण हुए मुझे शिक्षा दीजिये। 2.7",

    "कारण कि पृथ्वी पर धन-धान्य समृद्ध (और) शत्रुरहित राज्य तथा (स्वर्ग में) देवताओं का आधिपत्य मिल जाय तो भी इन्द्रियों को सुखाने वाला मेरा जो शोक है, (वह) दूर हो जाय (ऐसा मैं) नहीं देखता हूँ। 2.8",

    "संजय बोले - हे शत्रु तापन धृतराष्ट्र! ऐसा कहकर निद्रा को जीतने वाले अर्जुन अन्तर्यामी भगवान् गोविन्द से 'मैं युद्ध नहीं करूँगा' ऐसा साफ-साफ कहकर चुप हो गये। 2.9",

    "हे भरतवंशोद्भव धृतराष्ट्र! दोनों सेनाओं के मध्य भाग में विषाद करते हुए उस अर्जुन के प्रति हँसते हुए से भगवान् हृषीकेश यह (आगे कहे जाने वाले) वचन बोले। 2.10",

    "श्रीभगवान् बोले - तुमने शोक न करने योग्य का शोक किया है और विद्वत्ता (पण्डिताई) की बातें कह रहे हो; (परन्तु) जिनके प्राण चले गये हैं, उनके लिये और जिनके प्राण नहीं गये हैं, उनके लिये पण्डित लोग शोक नहीं करते। 2.11",

    "किसी काल में मैं नहीं था (और) तू नहीं (था) (तथा) ये राजा लोग नहीं (थे), यह बात भी नहीं है; और इसके बाद (भविष्य में) (मैं, तू और राजा लोग) हम सभी नहीं रहेंगे, (यह बात) भी नहीं है। 2.12",

    "देहधारी के इस मनुष्य शरीर में जैसे बालकपन, जवानी (और) वृद्धावस्था (होती है), ऐसे ही दूसरे शरीर की प्राप्ति होती है। उस विषय में धीर मनुष्य मोहित नहीं होता। 2.13",

    "हे कुन्तीनन्दन! इन्द्रियों के विषय (जड़ पदार्थ), तो शीत (अनुकूलता) और उष्ण (प्रतिकूलता) - के द्वारा सुख और दुःख देने वाले हैं (तथा) आने-जाने वाले (और) अनित्य हैं। हे भरतवंशोद्भव अर्जुन! उनको (तुम) सहन करो। 2.14",

    "कारण कि हे पुरुषों में श्रेष्ठ अर्जुन! सुख-दुःख में सम रहने वाले जिस धीर मनुष्य को ये मात्रास्पर्श (पदार्थ) व्यथित (सुखी-दुःखी) नहीं कर पाते, वह अमर होने में समर्थ हो जाता है अर्थात् वह अमर हो जाता है। 2.15",

    "असत् का तो भाव (सत्ता) विद्यमान नहीं है और सत् का अभाव विद्यमान नहीं है, तत्त्वदर्शी महापुरुषों ने इन दोनों का ही तत्त्व देखा अर्थात् अनुभव किया है। 2.16",

    "अविनाशी तो उसको जान, जिससे यह सम्पूर्ण (संसार) व्याप्त है। इस अविनाशी का विनाश कोई भी नहीं कर सकता। 2.17",

    "अविनाशी, जानने में न आने वाले (और) नित्य रहनेवाले इस शरीरी के ये देह अन्त वाले कहे गये हैं। इसलिये हे अर्जुन! (तुम) युद्ध करो। 2.18",

    "जो मनुष्य इस (अविनाशी शरीरी) को मारने वाला मानता है और जो मनुष्य इसको मरा मानता है, वे दोनों ही (इसको) नहीं जानते; (क्योंकि) यह न मारता है (और) न मारा जाता है। 2.19",

    "यह शरीरी न कभी जन्मता है और न मरता है (तथा) यह उत्पन्न होकर फिर होने वाला नहीं है। यह जन्मरहित, नित्य-निरन्तर रहने वाला, शाश्वत (और) अनादि है। शरीर के मारे जाने पर भी (यह) नहीं मारा जाता। 2.20",

    "हे पृथानन्दन! जो मनुष्य इस शरीरी को अविनाशी, नित्य, जन्मरहित (और) अव्यय जानता है, वह कैसे किसको मारे (और) (कैसे) किसको मरवाये? 2.21",

    "मनुष्य जैसे पुराने कपड़ों को छोड़कर दूसरे नये (कपड़े) धारण कर लेता है, ऐसे ही देही पुराने शरीरों को छोड़कर दूसरे नये  (शरीरों में) चला जाता है। 2.22",

    "शस्त्र इस (शरीरी) को काट नहीं सकते, अग्नि इसको जला नहीं सकती, जल इसको गीला नहीं कर सकता और वायु (इसको) सुखा नहीं सकती। 2.23",

    "यह शरीरी काटा नहीं जा सकता, यह जलाया नहीं जा सकता, (यह) गीला नहीं किया जा सकता और (यह) सुखाया भी नहीं जा सकता। (कारण कि) यह नित्य रहने वाला, सबमें परिपूर्ण, अचल, स्थिर स्वभाव वाला (और) अनादि है। 2.24",

    "यह देही प्रत्यक्ष नहीं दीखता, यह चिन्तन का विषय नहीं है (और) यह निर्विकार कहा जाता है। अतः इस देही को ऐसा जानकर शोक नहीं करना चाहिये। 2.25",

    "हे महाबाहो ! अगर (तुम) इस देही को नित्य पैदा होनेवाला अथवा नित्य मरने वाला भी मानो, तो भी तुम्हें इस प्रकार शोक नहीं करना चाहिये। 2.26",

    "कारण कि पैदा हुए की जरूर मृत्यु होगी और मरे हुए का जरूर जन्म होगा। अतः (इस जन्म-मरण रूप परिवर्तन के प्रवाह का) निवारण नहीं हो सकता। (अतः) इस विषय में तुम्हें शोक नहीं करना चाहिये। 2.27",

    "हे भारत ! सभी प्राणी जन्म से पहले अप्रकट थे (और) मरने के बाद अप्रकट हो जायँगे, केवल बीच में ही प्रकट दीखते हैं। (अतः) इसमें शोक करने की बात ही क्या है? 2.28",

    "कोई इस शरीरी को आश्चर्य की तरह देखता (अनुभव करता) है और वैसे ही दूसरा (कोई) (इसका) आश्चर्य की तरह वर्णन करता है तथा अन्य (कोई) इसको आश्चर्य की तरह सुनता है और इसको सुनकर भी कोई नहीं जानता।अर्थात यह दुर्विज्ञेय है।  2.29",

    "हे भरतवंशोद्भव अर्जुन! सबके देह में यह देही नित्य ही अवध्य है। इसलिये सम्पूर्ण प्राणियों के लिये अर्थात् किसी भी प्राणी के लिये तुम्हें शोक नहीं करना चाहिये। 2.30",

    "और अपने क्षात्रधर्म को देखकर भी (तुम्हें) विकम्पित अर्थात् कर्तव्य-कर्म से विचलित नहीं होना चाहिये क्योंकि धर्ममय युद्ध से बढ़कर क्षत्रिय के लिये दूसरा कोई कल्याणकारक कर्म नहीं है। 2.31",

    "अपने-आप प्राप्त हुआ (युद्ध) खुला हुआ स्वर्ग का दरवाजा भी है। हे पृथानन्दन ! (वे) क्षत्रिय बड़े सुखी (भाग्यशाली) हैं (जिनको) ऐसा युद्ध प्राप्त होता है। 2.32",

    "अब अगर तू यह धर्ममय युद्ध नहीं करेगा,  तो अपने धर्म और कीर्ति का त्याग करके पाप को प्राप्त होगा। 2.33",

    "और सब प्राणी भी तेरी सदा रहने वाली अपकीर्ति का कथन अर्थात निंदा करेंगे। (वह) अपकीर्ति सम्मानित मनुष्य के लिये मृत्यु से भी बढ़कर दुःखदायी होती है। 2.34",

    "तथा महारथी लोग तुझे भय के कारण युद्ध से हटा हुआ मानेंगे। जिनकी (धारणा में) तू बहुमान्य हो चुका है, (उनकी दृष्टि में) (तू) लघुता को प्राप्त हो जायगा। 2.35",

    "तेरे शत्रु लोग तेरी सामर्थ्य की निन्दा करते हुए बहुत से न कहने योग्य वचन भी कहेंगे। उससे बढ़कर और दुःख की बात क्या होगी? 2.36",

    "अगर (युद्ध में तू) मारा जायगा (तो) (तुझे) स्वर्ग की प्राप्ति होगी (और) अगर (युद्ध में तू) जीत जायगा (तो) पृथ्वी का राज्य भोगेगा। अतः हे कुन्तीनन्दन! (तू) युद्ध के लिये निश्चय करके खड़ा हो जा। 2.37",

    "जय-पराजय, लाभ-हानि (और) सुख-दुःख को समान करके फिर युद्ध में लग जा। इस प्रकार (युद्ध करने से) (तू) पाप को प्राप्त नहीं होगा। 2.38",

    "हे पार्थ! यह समबुद्धि तेरे लिए (पहले) सांख्ययोग में कही गयी और (अब तू) इसको कर्मयोग के विषय में सुन; जिस समबुद्धि से युक्त हुआ (तू) कर्मबन्धन का त्याग कर देगा। 2.39",

    "मनुष्यलोक में इस समबुद्धि रूप धर्म के आरम्भ का नाश नहीं होता (तथा इसके अनुष्ठान का) उलटा फल (भी) नहीं होता (और इसका) थोड़ा सा भी (अनुष्ठान) (जन्म-मरण रूप) महान भय से रक्षा कर लेता है। 2.40",

    "हे कुरुनन्दन! इस (समबुद्धि की प्राप्ति) के विषय में निश्चयवाली बुद्धि एक ही (होती है)। जिनका एक निश्चय नहीं है, ऐसे मनुष्यों की बुद्धियाँ अनन्त और बहुशाखाओं वाली ही (होती हैं)। 2.41",

    "हे पृथानन्दन ! जो कामनाओं में तन्मय हो रहे हैं, स्वर्ग को ही श्रेष्ठ मानने वाले हैं, वेदों में कहे हुए सकाम कर्मों में प्रीति रखने वाले हैं, (भोगों के सिवाय) और कुछ है ही नहीं - ऐसा कहने वाले हैं, (वे) अविवेकी मनुष्य इस प्रकार की जिस पुष्पित (दिखाऊ शोभायुक्त) वाणी को कहा करते हैं, (जो कि) जन्मरूपी कर्मफल को देने वाली है (तथा) भोग और ऐश्वर्य की प्राप्ति के लिये बहुत सी क्रियाओं का वर्णन करने वाली है। (2.42-2.43)",
    
    "हे पृथानन्दन ! जो कामनाओं में तन्मय हो रहे हैं, स्वर्ग को ही श्रेष्ठ मानने वाले हैं, वेदों में कहे हुए सकाम कर्मों में प्रीति रखने वाले हैं, (भोगों के सिवाय) और कुछ है ही नहीं - ऐसा कहने वाले हैं, (वे) अविवेकी मनुष्य इस प्रकार की जिस पुष्पित (दिखाऊ शोभायुक्त) वाणी को कहा करते हैं, (जो कि) जन्मरूपी कर्मफल को देने वाली है (तथा) भोग और ऐश्वर्य की प्राप्ति के लिये बहुत सी क्रियाओं का वर्णन करने वाली है। (2.42-2.43)",

    "उस पुष्पित वाणी से जिसका अन्तःकरण हर लिया गया है अर्थात् भोगों की तरफ खिंच गया है (और जो) भोग तथा ऐश्वर्य में अत्यन्त आसक्त हैं, (उन मनुष्यों की) परमात्मा में निश्चय वाली बुद्धि नहीं होती। 2.44",

    " वेद तीनों गुणों के कार्य का ही वर्णन करने वाले हैं; हे अर्जुन! (तू) तीनों गुणों से रहित हो जा, राग द्वेषादि द्वन्द्वों से रहित (हो जा), (निरन्तर) नित्य वस्तु परमात्मा में स्थित (हो जा), योगक्षेम की चाहना भी मत रख (और) परमात्मपरायण (हो जा)। 2.45",

    "सब तरफ से परिपूर्ण महान जलाशय के (प्राप्त होने पर) छोटे गड्ढों में भरे जल में (मनुष्य का) जितना प्रयोजन (रहता है) अर्थात् कुछ भी प्रयोजन नहीं रहता, (वेदों और शास्त्रों को) तत्त्व से जानने वाले ब्रह्मज्ञानी का सम्पूर्ण वेदों में उतना (ही प्रयोजन रहता है) अर्थात् कुछ भी प्रयोजन नहीं रहता। 2.46",

    "कर्तव्य-कर्म करने में ही तेरा अधिकार है, फलों में कभी नहीं। (अतः तू) कर्मफल का हेतु (भी) मत बन (और) तेरी कर्म न करने में (भी) आसक्ति न हो। 2.47",

    "हे धनञ्जय ! (तू) आसक्ति का त्याग करके सिद्धि-असिद्धि में सम होकर योग में स्थित हुआ कर्मों को कर; (क्योंकि) समत्व (ही) योग कहा जाता है। 2.48",

    "बुद्धियोग (समता) की अपेक्षा सकाम कर्म दूर से (अत्यन्त) ही निकृष्ट है। (अतः) हे धनञ्जय ! (तू) बुद्धि (समता) का आश्रय ले; क्योंकि फल के हेतु बनने वाले अत्यन्त दीन हैं। 2.49",

    "बुद्धि-(समता) से युक्त (मनुष्य) यहाँ (जीवित अवस्था में ही) पुण्य और पाप दोनों का त्याग कर देता है। अतः (तू) योग (समता) में लग जा (क्योंकि) कर्मों में योग (ही) कुशलता है। 2.50",

    "कारण कि समतायुक्त बुद्धिमान साधक कर्मजन्य फल का त्याग करके जन्मरूप बन्धन से मुक्त होकर निर्विकार पद को प्राप्त हो जाते हैं। 2.51",

    "जिस समय तेरी बुद्धि मोहरूपी दलदल को भली भांति तर जायगी, उसी समय (तू) सुने हुए और सुनने में आने वाले (भोगों से) वैराग्य को प्राप्त हो जायगा। 2.52",

    "जिस काल में शास्त्रीय मतभेदों से विचलित हुई तेरी बुद्धि निश्चल हो जायगी (और) परमात्मा में अचल (हो जायगी), उस काल में (तू) योग को प्राप्त हो जायगा। 2.53",

    "अर्जुन बोले - हे केशव ! परमात्मा में स्थित स्थिर बुद्धि वाले मनुष्य के क्या लक्षण होते हैं? वह स्थिर बुद्धि वाला मनुष्य कैसे बोलता है, कैसे बैठता है (और) कैसे चलता है अर्थात व्यवहार करता है? 2.54",

    "श्रीभगवान् बोले - हे पृथानन्दन ! जिस काल में (साधक) मन में आयी सम्पूर्ण कामनाओं का भली भांति त्याग कर देता है (और) अपने आपसे अपने आप में ही सन्तुष्ट रहता है, उस काल में (वह) स्थितप्रज्ञ कहा जाता है। 2.55",

    "दुःखों की प्राप्ति होने पर जिसके मन में उद्वेग नहीं होता (और) सुखों की प्राप्ति होने पर जिसके मन में स्पृहा नहीं होती (तथा) जो राग, भय और क्रोध से सर्वथा रहित हो गया है, (वह) मननशील मनुष्य स्थिरबुद्धि कहा जाता है। 2.56",

    "सब जगह आसक्ति रहित हुआ जो मनुष्य उस-उस शुभ-अशुभ को प्राप्त करके न तो प्रसन्न होता है (और) न द्वेष करता है, उसकी बुद्धि स्थिर है। 2.57",

    "जिस तरह कछुआ अपने अंगों को सब ओर से समेट लेता है, (ऐसे ही) जिस काल में यह (कर्मयोगी) इन्द्रियों के विषयों से इन्द्रियों को (सब प्रकार से हटा लेता) है, तब उसकी बुद्धि स्थिर हो जाती है। 2.58",

    "निराहारी (इन्द्रियों को विषयों से हटाने वाले) मनुष्य के (भी) विषय तो निवृत्त हो जाते हैं (पर) रस निवृत्त नहीं होता। (परन्तु) परमात्म तत्त्व का अनुभव होने से इस स्थितप्रज्ञ मनुष्य का तो रस भी निवृत्त हो जाता है अर्थात उसकी संसार में रसबुद्धि नहीं रहती। 2.59",

    "कारण कि हे कुन्तीनन्दन! (रसबुद्धि रहने से) यत्न करते हुए विद्वान् मनुष्य की भी प्रमथनशील इन्द्रियाँ (उसके) मन को बलपूर्वक हर लेती हैं। 2.60",

    "कर्मयोगी साधक उन सम्पूर्ण इन्द्रियों को वश में करके मेरे परायण होकर बैठे; क्योंकि जिसकी इन्द्रियाँ वश में हैं, उसकी बुद्धि स्थिर है। 2.61",

    "विषयों का चिन्तन करने वाले मनुष्य की उन विषयों में आसक्ति पैदा हो जाती है। आसक्ति से कामना पैदा होती है। कामना से (बाधा लगने पर) क्रोध पैदा होता है। क्रोध होने पर सम्मोह (मूढ़भाव) हो जाता है। सम्मोह से स्मृति भ्रष्ट हो जाती है। स्मृति भ्रष्ट होने पर बुद्धि (विवेक) का नाश हो जाता है। बुद्धि का नाश होने पर (मनुष्य का) पतन हो जाता है। (2.62-2.63)",

    "विषयों का चिन्तन करने वाले मनुष्य की उन विषयों में आसक्ति पैदा हो जाती है। आसक्ति से कामना पैदा होती है। कामना से (बाधा लगने पर) क्रोध पैदा होता है। क्रोध होने पर सम्मोह (मूढ़भाव) हो जाता है। सम्मोह से स्मृति भ्रष्ट हो जाती है। स्मृति भ्रष्ट होने पर बुद्धि (विवेक) का नाश हो जाता है। बुद्धि का नाश होने पर (मनुष्य का) पतन हो जाता है। (2.62-2.63)",

    "परन्तु वशीभूत अन्तःकरण वाला (कर्मयोगी साधक) राग द्वेष से रहित अपने वश में की हुई इन्द्रियों के द्वारा विषयों का सेवन करता हुआ (अन्तःकरण की) निर्मलता को प्राप्त होने पर साधक के सम्पूर्ण दुःखों का नाश हो जाता है (और ऐसे) शुद्ध चित्तवाले साधक की बुद्धि निःसन्देह बहुत जल्दी (परमात्मा में) स्थिर हो जाती है। (2.64-2.65)",

    "परन्तु वशीभूत अन्तःकरण वाला (कर्मयोगी साधक) राग द्वेष से रहित अपने वश में की हुई इन्द्रियों के द्वारा विषयों का सेवन करता हुआ (अन्तःकरण की) निर्मलता को प्राप्त होने पर साधक के सम्पूर्ण दुःखों का नाश हो जाता है (और ऐसे) शुद्ध चित्तवाले साधक की बुद्धि निःसन्देह बहुत जल्दी (परमात्मा में) स्थिर हो जाती है। (2.64-2.65)",

    "जिसके मन-इन्द्रियाँ संयमित नहीं हैं, ऐसे मनुष्य की (व्यवसायात्मिका) बुद्धि नहीं होती और (व्यवसायात्मिका बुद्धि न होने से) उस अयुक्त मनुष्य में निष्काम भाव अथवा कर्तव्यपरायणता का भाव नहीं होता। निष्काम भाव न होने से (उसको) शान्ति नहीं मिलती। फिर शान्ति रहित मनुष्य को सुख कैसे (मिल सकता है)?  2.66",

    "कारण कि (अपने-अपने विषयों में) विचरती हुई इन्द्रियों में से (एक ही इन्द्रिय) जिस मन को अपना अनुगामी बना लेती है, वह (अकेला मन) जल में नौका को वायु की तरह इसकी बुद्धि को हर लेता है। 2.67",

    "इसलिये हे महाबाहो ! जिस मनुष्य की इन्द्रियाँ इन्द्रियों के विषयों से सर्वथा वश में की हुई हैं, उसकी बुद्धि स्थिर है। 2.68",

    "सम्पूर्ण प्राणियों की जो रात (परमात्मा से विमुखता) है, उसमें संयमी मनुष्य जागता है (और) जिसमें सब प्राणी जागते हैं (भोग और संग्रह में लगे रहते हैं), वह (तत्त्व को जानने वाले) मुनि की दृष्टि में रात है। 2.69",

    "जैसे (सम्पूर्ण नदियों का) जल चारों ओर से जल द्वारा परिपूर्ण समुद्र में आकर मिलता है, (पर) (समुद्र अपनी मर्यादा में) अचल स्थित रहता है, ऐसे ही सम्पूर्ण भोग-पदार्थ जिस संयमी मनुष्य को (विकार उत्पन्न किये बिना ही) प्राप्त होते हैं, वही मनुष्य परमशान्ति को प्राप्त होता है, भोगों की कामना वाला नहीं। 2.70",

    "जो मनुष्य सम्पूर्ण कामनाओं का त्याग करके स्पृहा रहित, ममता रहित (और) अहंकार रहित होकर आचरण करता है, वह शान्ति को प्राप्त होता है। 2.71",

    "हे पृथानन्दन ! यह ब्राह्मी स्थिति है। इसको प्राप्त होकर (कभी कोई) मोहित नहीं होता। इस स्थिति में (यदि) अन्तकाल में भी स्थित हो जाय, (तो) निर्वाण (शान्त) ब्रह्म की प्राप्ति हो जाती है। 2.72",

    "इस प्रकार ॐ तत्  सत् - इन भगवन्नामों के उच्चारणपूर्वक ब्रह्मविद्या और योगशास्त्रमय श्रीमद्भगवद्गीतोपनिषदरूप श्रीकृष्णार्जुनसंवाद में ‘सांख्ययोग’ नामक दूसरा अध्याय पूर्ण हुआ।"  
  ],
  "adhyaya-3" : [
    "अर्जुन बोले -- हे जनार्दन! अगर आप कर्म से बुद्धि (ज्ञान) को श्रेष्ठ मानते हैं, तो फिर हे केशव! मुझे घोर कर्म में क्यों लगाते हैं ? (आप अपने) मिले हुए से वचनों से मेरी बुद्धि को मोहित-सी कर रहे हैं। (अतः आप) निश्चय करके उस एक बात को कहिये, जिससे मैं कल्याण को प्राप्त हो जाऊँ। (3.1-3.2)",

    "अर्जुन बोले -- हे जनार्दन! अगर आप कर्म से बुद्धि (ज्ञान) को श्रेष्ठ मानते हैं, तो फिर हे केशव! मुझे घोर कर्म में क्यों लगाते हैं ? (आप अपने) मिले हुए से वचनों से मेरी बुद्धि को मोहित-सी कर रहे हैं। (अतः आप) निश्चय करके उस एक बात को कहिये, जिससे मैं कल्याण को प्राप्त हो जाऊँ। (3.1-3.2)",

    "श्रीभगवान् बोले - हे निष्पाप अर्जुन! इस मनुष्यलोक में दो प्रकार से होने वाली निष्ठा मेरे द्वारा पहले कही गयी है। (उनमें) ज्ञानियों की (निष्ठा) ज्ञानयोग से और योगियों की (निष्ठा) कर्मयोग से (होती है)। 3.3",

    "मनुष्य न तो कर्मों का आरम्भ किये बिना निष्कर्मता का अनुभव करता  है और न (कर्मों के) त्याग मात्र से सिद्धि को ही प्राप्त होता है। 3.4",

    "कोई भी (मनुष्य) किसी भी अवस्था में क्षणमात्र भी कर्म किये बिना नहीं रह सकता; क्योंकि (प्रकृति के) परवश हुए सब प्राणियों से प्रकृति जन्य गुण कर्म करवा लेते हैं। 3.5",

    "जो कर्मेन्द्रियों (सम्पूर्ण इन्द्रियों) को (हठपूर्वक) रोककर मन से इन्द्रियों के विषयों का चिन्तन करते हुए बैठता है, वह मूढ़ बुद्धि वाला मनुष्य मिथ्याचारी (मिथ्या आचरण करने वाला) कहा जाता है।  3.6",

    "परन्तु हे अर्जुन! जो (मनुष्य) मन से इन्द्रियों पर नियन्त्रण करके आसक्ति रहित होकर (निष्काम भाव से) कर्मेंद्रियों (समस्त इन्द्रियों) के द्वारा कर्मयोग का आचरण करता है, वही श्रेष्ठ है। 3.7",

    "तू शास्त्र विधि से नियत किये हुए कर्तव्य कर्म कर; क्योंकि कर्म न करने की अपेक्षा कर्म करना श्रेष्ठ है तथा कर्म न करने से तेरा शरीर-निर्वाह भी सिद्ध नहीं होगा।  3.8",

    "यज्ञ (कर्तव्य पालन) के लिये किये जाने वाले कर्मों से अन्यत्र (अपने लिये किये जाने वाले) कर्मों में लगा हुआ यह मनुष्य समुदाय कर्मों से बँधता है, (इसलिये) हे कुन्तीनन्दन ! तू आसक्ति-रहित होकर उस यज्ञ के लिये (ही) कर्तव्य कर्म कर। 3.9",

    "प्रजापति ब्रह्माजी ने सृष्टि के आदिकाल में कर्तव्य कर्मों के विधान सहित प्रजा (मनुष्य आदि) की रचना करके (उनसे प्रधानतया मनुष्यों से) कहा कि (तुम लोग) इस कर्तव्य के द्वारा सबकी वृद्धि करो (और) यह (कर्तव्य कर्मरूप यज्ञ) तुम लोगों को कर्तव्य-पालन की आवश्यक सामग्री प्रदान करने वाला हो। इस (अपने कर्तव्य कर्म) के द्वारा (तुम लोग) देवताओं को उन्नत करो (और) वे देवता लोग (अपने कर्तव्य के द्वारा) तुम लोगों को उन्नत करें। (इस प्रकार) एक-दूसरे को उन्नत करते हुए (तुम लोग) परम कल्याण को प्राप्त हो जाओगे।  (3.10-3.11)",

    "प्रजापति ब्रह्माजी ने सृष्टि के आदिकाल में कर्तव्य कर्मों के विधान सहित प्रजा (मनुष्य आदि) की रचना करके (उनसे प्रधानतया मनुष्यों से) कहा कि (तुम लोग) इस कर्तव्य के द्वारा सबकी वृद्धि करो (और) यह (कर्तव्य कर्मरूप यज्ञ) तुम लोगों को कर्तव्य-पालन की आवश्यक सामग्री प्रदान करने वाला हो। इस (अपने कर्तव्य कर्म) के द्वारा (तुम लोग) देवताओं को उन्नत करो (और) वे देवता लोग (अपने कर्तव्य के द्वारा) तुम लोगों को उन्नत करें। (इस प्रकार) एक-दूसरे को उन्नत करते हुए (तुम लोग) परम कल्याण को प्राप्त हो जाओगे।  (3.10-3.11)",

    "यज्ञ के द्वारा बढ़ाये हुए देवता तुम लोगों को बिना माँगे ही इच्छित भोग निश्चय ही देते रहेंगे। इस प्रकार उन देवताओं के द्वारा दिए हुए भोगों को जो पुरुष उनको बिना दिए स्वयं भोगता है, वह चोर ही है। 3.12",

    "यज्ञशेष- (योग-) का अनुभव करनेवाले श्रेष्ठ मनुष्य सम्पूर्ण पापोंसे मुक्त हो जाते हैं। परन्तु जो केवल अपने लिये ही पकाते अर्थात् सब कर्म करते हैं, वे पापीलोग तो पापका ही भक्षण करते हैं। 3.13",

    "सम्पूर्ण प्राणी अन्न से उत्पन्न होते हैं। अन्न की उत्पत्ति वर्षा से होती है। वर्षा यज्ञ से होती है। यज्ञ कर्मों से सम्पन्न होता है। कर्मों को (तू) वेद से उत्पन्न जान (और) वेद को अक्षरब्रह्म से प्रकट हुआ (जान)। इसलिये (वह) सर्वव्यापी परमात्मा यज्ञ (कर्तव्य कर्म) में नित्य स्थित है। (3.14-3.15)",

    "सम्पूर्ण प्राणी अन्न से उत्पन्न होते हैं। अन्न की उत्पत्ति वर्षा से होती है। वर्षा यज्ञ से होती है। यज्ञ कर्मों से सम्पन्न होता है। कर्मों को (तू) वेद से उत्पन्न जान (और) वेद को अक्षरब्रह्म से प्रकट हुआ (जान)। इसलिये (वह) सर्वव्यापी परमात्मा यज्ञ (कर्तव्य कर्म) में नित्य स्थित है। (3.14-3.15)",

    "हे पार्थ! जो मनुष्य इस लोक में इस प्रकार (परम्परा से) प्रचलित सृष्टि-चक्र के अनुसार नहीं चलता, वह इन्द्रियों के द्वारा भोगों में रमण करने वाला अघायु (पापमय जीवन बिताने वाला) मनुष्य (संसार में) व्यर्थ ही जीता है। 3.16",

    "परन्तु जो मनुष्य अपने आप में ही रमण करने वाला और अपने आप में ही तृप्त तथा अपने-आप में ही संतुष्ट है, उसके लिये कोई कर्तव्य नहीं है। 3.17",

    "उस (कर्मयोग से सिद्ध हुए महापुरुष) का इस संसार में न तो कर्म करने से कोई प्रयोजन (रहता है और) न कर्म न करने से ही (कोई प्रयोजन रहता है) तथा सम्पूर्ण प्राणियों में (किसी भी प्राणी के साथ) इसका किंचिन्मात्र भी स्वार्थ का सम्बन्ध नहीं रहता। 3.18",

    "इसलिये (तू) निरन्तर आसक्ति रहित (होकर) कर्तव्य कर्म का भली भाँति आचरण कर; क्योंकि आसक्ति रहित (होकर) कर्म करता हुआ मनुष्य परमात्मा को प्राप्त हो जाता है। 3.19",

    "राजा जनक जैसे अनेक महापुरुष भी कर्म (कर्मयोग) के द्वारा ही परमसिद्धि को प्राप्त हुए थे। (इसलिये) लोक संग्रह को देखते हुए भी (तू) (निष्काम भाव से) कर्म करने ही के योग्य है अर्थात् अवश्य करना चाहिये। 3.20",

    "श्रेष्ठ मनुष्य जो-जो आचरण करता है, दूसरे मनुष्य वैसा-वैसा ही आचरण करते हैं। वह जो कुछ प्रमाण कर देता है, दूसरे मनुष्य उसी के अनुसार आचरण करते हैं। 3.21",

    "हे पार्थ! मुझे तीनों लोकों में न तो कुछ कर्तव्य है और न (कोई) प्राप्त करने योग्य (वस्तु) अप्राप्त है, (फिर भी मैं) कर्तव्य कर्म में ही लगा रहता हूँ। 3.22",

    "क्योंकि हे पार्थ ! अगर मैं किसी समय सावधान होकर कर्तव्य-कर्म न करूँ (तो बड़ी हानि हो जाय; क्योंकि) मनुष्य सब प्रकार से मेरे (ही) मार्ग का अनुसरण करते हैं। यदि मैं कर्म न करूँ, (तो) ये सब मनुष्य नष्ट-भ्रष्ट हो जायँ और (मैं) वर्णसंकरता को करने वाला होऊँ (तथा) इस समस्त प्रजा को नष्ट करने वाला बनूँ। (3.23-3.24)",

    "क्योंकि हे पार्थ ! अगर मैं किसी समय सावधान होकर कर्तव्य-कर्म न करूँ (तो बड़ी हानि हो जाय; क्योंकि) मनुष्य सब प्रकार से मेरे (ही) मार्ग का अनुसरण करते हैं। यदि मैं कर्म न करूँ, (तो) ये सब मनुष्य नष्ट-भ्रष्ट हो जायँ और (मैं) वर्णसंकरता को करने वाला होऊँ (तथा) इस समस्त प्रजा को नष्ट करने वाला बनूँ। (3.23-3.24)",

    "हे भरतवंशोद्भव अर्जुन! कर्म में आसक्त हुए अज्ञानी जन जिस प्रकार (कर्म) करते हैं, आसक्ति रहित तत्त्वज्ञ महापुरुष (भी) लोक संग्रह करना चाहता हुआ उसी प्रकार (कर्म) करे। सावधान तत्त्वज्ञ महापुरुष कर्मों में आसक्ति वाले अज्ञानी मनुष्यों की बुद्धि में भ्रम उत्पन्न न करे, (प्रत्युत स्वयं) समस्त कर्मों को अच्छी तरह से करता हुआ (उनसे भी वैसे ही) करवाये। (3.25-3.26)",

    "हे भरतवंशोद्भव अर्जुन! कर्म में आसक्त हुए अज्ञानी जन जिस प्रकार (कर्म) करते हैं, आसक्ति रहित तत्त्वज्ञ महापुरुष (भी) लोक संग्रह करना चाहता हुआ उसी प्रकार (कर्म) करे। सावधान तत्त्वज्ञ महापुरुष कर्मों में आसक्ति वाले अज्ञानी मनुष्यों की बुद्धि में भ्रम उत्पन्न न करे, (प्रत्युत स्वयं) समस्त कर्मों को अच्छी तरह से करता हुआ (उनसे भी वैसे ही) करवाये। (3.25-3.26)",

    "सम्पूर्ण कर्म सब प्रकार से प्रकृति के गुणों द्वारा किये जाते हैं; (परन्तु) अहंकार से मोहित अन्तःकरण वाला अज्ञानी मनुष्य 'मैं कर्ता हूँ' -- ऐसा मान लेता  है। 3.27",

    "परन्तु हे महाबाहो! गुण-विभाग और कर्म-विभाग को तत्त्व से जानने वाला महापुरुष 'सम्पूर्ण गुण (ही) गुणों में बरत रहे हैं' -- ऐसा मानकर (उनमें) आसक्त नहीं होता। 3.28",

    "प्रकृति जन्य गुणों से अत्यन्त मोहित हुए अज्ञानी मनुष्य गुणों और कर्मों में आसक्त रहते हैं। उन पूर्णतया न समझने वाले मन्द बुद्धि अज्ञानियों को पूर्णतया जानने वाला ज्ञानी मनुष्य विचलित न करे। 3.29",

    "(तू) विवेकवती बुद्धि के द्वारा सम्पूर्ण कर्तव्य-कर्मों को मेरे अर्पण करके कामना रहित, ममता रहित (और) संताप रहित होकर युद्ध रूप कर्तव्य कर्म को कर। 3.30",

    "जो मनुष्य दोष-दृष्टि से रहित होकर श्रद्धापूर्वक मेरे इस (पूर्व श्लोक में वर्णित) मत का सदा अनुसरण करते हैं, वे भी कर्मों के बन्धन से मुक्त हो जाते हैं। 3.31",

    "परन्तु जो मनुष्य मेरे इस मत में दोष-दृष्टि करते हुए (इसका) अनुष्ठान नहीं करते, उन सम्पूर्ण ज्ञानों में मोहित (और) अविवेकी मनुष्यों को नष्ट हुए (ही) समझो अर्थात् उनका पतन ही होता है। 3.32",

    "सम्पूर्ण प्राणी प्रकृति को प्राप्त होते हैं। ज्ञानी महापुरुष भी अपनी प्रकृति के अनुसार चेष्टा करता है। (फिर इसमें) किसी का हठ क्या करेगा? 3.33",

    "इन्द्रिय-इन्द्रिय के अर्थ में (प्रत्येक इन्द्रिय के प्रत्येक विषय में) (मनुष्य के) राग और द्वेष व्यवस्था से (अनुकूलता और प्रतिकूलता को लेकर) स्थित हैं। (मनुष्य को) उन दोनों के वश में नहीं होना चाहिये; क्योंकि वे दोनों ही इसके (पारमार्थिक मार्ग में) विघ्न डालने वाले शत्रु हैं। 3.34",

    "अच्छी तरह आचरण में लाये हुए दूसरे के धर्म से गुणों की कमी वाला अपना धर्म श्रेष्ठ है। अपने धर्म में (तो) मरना (भी) कल्याणकारक है (और) दूसरे का धर्म भय को देने वाला है। 3.35",

    "अर्जुन बोले - हे वार्ष्णेय ! फिर यह मनुष्य न चाहता हुआ भी जबर्दस्ती लगाये हुए की तरह किससे प्रेरित होकर पाप का आचरण करता है? 3.36",

    "श्रीभगवान् बोले – रजोगुण से उत्पन्न हुआ यह काम अर्थात् कामना (ही पाप का कारण  है)। यह (काम ही) क्रोध (में परिणत होता) है। (यह) बहुत खाने वाला (और) महापापी है। इस विषय में (तू) इसको (ही) वैरी जान। 3.37",

    "जैसे धुएँ से अग्नि और मैल से दर्पण ढका जाता है (तथा) जैसे जेर से गर्भ ढका रहता है, ऐसे ही उस कामना के द्वारा यह (ज्ञान अर्थात् विवेक) ढका हुआ है। 3.38",

    "हे कुन्तीनन्दन ! इस अग्नि के (समान) (कभी) तृप्त न होने वाले और विवेकियों के कामना रूप  नित्य वैरी के द्वारा (मनुष्य) का विवेक ढका हुआ है। 3.39",

    "इन्द्रियाँ, मन (और) बुद्धि इस कामना  के वास स्थान कहे गये हैं। यह कामना इन (इन्द्रियाँ, मन और बुद्धि-) के द्वारा ज्ञान को ढककर देहाभिमानी मनुष्य को मोहित करती है। 3.40",

    "इसलिये हे भरतवंशियों में श्रेष्ठ अर्जुन ! तू सबसे पहले इन्द्रियों को वश में करके इस ज्ञान और विज्ञान का नाश करने वाले महान पापी काम को अवश्य ही बलपूर्वक मार डाल। 3.41",

    "इन्द्रियों को (स्थूल शरीर से) पर (श्रेष्ठ, सबल, प्रकाशक, व्यापक तथा सूक्ष्म) कहते हैं। इन्द्रियों से पर मन है, मन से भी पर बुद्धि है (औऱ) जो बुद्धि से भी पर है, वह (काम) है। इस तरह बुद्धि से पर (काम) को जानकर अपने द्वारा अपने आपको को वश में करके हे महाबाहो ! (तू इस) कामरूप दुर्जय शत्रु को मार डाल। (3.42-3.43)",

    "इन्द्रियों को (स्थूल शरीर से) पर (श्रेष्ठ, सबल, प्रकाशक, व्यापक तथा सूक्ष्म) कहते हैं। इन्द्रियों से पर मन है, मन से भी पर बुद्धि है (औऱ) जो बुद्धि से भी पर है, वह (काम) है। इस तरह बुद्धि से पर (काम) को जानकर अपने द्वारा अपने आपको को वश में करके हे महाबाहो ! (तू इस) कामरूप दुर्जय शत्रु को मार डाल। (3.42-3.43)",

    "इस प्रकार ॐ तत्  सत् - इन भगवन्नामों के उच्चारणपूर्वक ब्रह्मविद्या और योगशास्त्रमय श्रीमद्भगवद्गीतोपनिषदरूप श्रीकृष्णार्जुनसंवाद में ‘कर्मयोग’ नामक तीसरा अध्याय पूर्ण हुआ।"
  ],
  "adhyaya-4" : [
    "श्रीभगवान् बोले - मैंने इस अविनाशी योग (कर्मयोग) को सूर्य से कहा था। (फिर) सूर्य ने (अपने पुत्र) (वैवस्वत) मनु से कहा (और) मनु ने (अपने पुत्र) राजा इक्ष्वाकु से कहा। 4.1",

    "हे परंतप ! इस तरह परम्परा से प्राप्त इस कर्मयोग को राजर्षियों ने जाना (परन्तु) बहुत समय बीत जाने के कारण वह योग इस मनुष्य लोक में लुप्तप्राय हो गया। 4.2",

    "(तू) मेरा भक्त और (प्रिय) सखा है, इसलिये वही यह पुरातन योग आज मैंने तुझसे कहा है; क्योंकि यह बड़ा उत्तम रहस्य है। 4.3",

    "अर्जुन बोले - आपका जन्म (तो) अभी का है (और) सूर्य का जन्म बहुत पुराना है; अतः आपने (ही) सृष्टि के आरम्भ में (सूर्य से) यह योग कहा था - यह बात (मैं) कैसे समझूँ? 4.4",

    "श्रीभगवान् बोले -- हे परन्तप अर्जुन ! मेरे और तेरे बहुत से जन्म हो चुके हैं। उन सबको मैं जानता हूँ, (पर) तू नहीं जानता। 4.5",

    "(मैं) अजन्मा (और) अविनाशी-स्वरूप होते हुए भी (तथा) सम्पूर्ण प्राणियों का ईश्वर होते हुए भी अपनी प्रकृति को अधीन करके अपनी योगमाया से प्रकट होता हूँ। 4.6",

    "हे भरतवंशी अर्जुन! जब-जब धर्म की हानि (और) अधर्म की वृद्धि होती है, तब-तब ही मैं अपने आपको (साकार रूप से) प्रकट करता हूँ।  4.7",

    "साधुओं (भक्तों) की रक्षा करने के लिये, पाप कर्म करने वालों का विनाश करने के लिये और धर्म की भली भाँति स्थापना करने के लिये (मैं) युग-युग में प्रकट हुआ करता हूँ। 4.8",

    "हे अर्जुन ! मेरे जन्म और कर्म दिव्य हैं। इस प्रकार (मेरे जन्म और कर्म को) जो मनुष्य तत्त्व से जान लेता अर्थात् दृढ़तापूर्वक मान लेता है, वह शरीर का त्याग करके पुनर्जन्म को प्राप्त नहीं होता, (प्रत्युत) मुझे प्राप्त होता है। 4.9",

    "राग, भय और क्रोध से सर्वथा रहित, मुझ में तल्लीन, मेरे (ही) आश्रित (तथा) ज्ञान रूप तप से पवित्र हुए बहुत से (भक्त) मेरे स्वरूप को प्राप्त हो चुके हैं।  4.10",

    "पृथानन्दन ! जो भक्त जिस प्रकार मेरी शरण लेते हैं, मैं उन्हें उसी प्रकार आश्रय देता हूँ; (क्योंकि) सभी मनुष्य सब प्रकार से मेरे मार्ग का अनुकरण करते हैं। 4.11",

    "कर्मों की सिद्धि (फल) चाहने वाले (मनुष्य) देवताओं की उपासना किया करते हैं; क्योंकि इस मनुष्यलोक में कर्मों से उत्पन्न होने वाली सिद्धि जल्दी मिल जाती है। 4.12",

    "मेरे द्वारा गुणों और कर्मों के विभाग पूर्वक चारों वर्णों की रचना की गयी है। उस (सृष्टि-रचना आदि) का कर्ता होने पर भी मुझ अविनाशी परमेश्वर को (तू) अकर्ता जान। (कारण कि) कर्मों के फल में मेरी स्पृहा नहीं है, (इसलिये) मुझे कर्म लिप्त नहीं करते। इस प्रकार जो मुझे तत्त्व से जान लेता है, वह (भी) कर्मों से नहीं बँधता। (4.13-4.14)",

    "मेरे द्वारा गुणों और कर्मों के विभाग पूर्वक चारों वर्णों की रचना की गयी है। उस (सृष्टि-रचना आदि) का कर्ता होने पर भी मुझ अविनाशी परमेश्वर को (तू) अकर्ता जान। (कारण कि) कर्मों के फल में मेरी स्पृहा नहीं है, (इसलिये) मुझे कर्म लिप्त नहीं करते। इस प्रकार जो मुझे तत्त्व से जान लेता है, वह (भी) कर्मों से नहीं बँधता। (4.13-4.14)",

    "पूर्वकाल के मुमुक्षुओं ने भी इस प्रकार जानकर कर्म किये हैं, इसलिये तू (भी) पूर्वजों के द्वारा सदा से किये जाने वाले कर्मों को ही (उन्हीं की तरह) कर। 4.15",

    "कर्म क्या है (और) अकर्म क्या है - इस प्रकार इस विषय में विद्वान् भी मोहित हो जाते हैं। (अतः) वह कर्म तत्त्व (मैं) तुम्हें भली भाँति कहूँगा, जिसको जानकर (तू) अशुभ (संसार-बन्धन) से मुक्त हो जायगा। 4.16",

    "कर्मों का (तत्त्व) भी जानना चाहिये और अकर्म का (तत्त्व भी) जानना चाहिये तथा विकर्म का (तत्त्व भी) जानना चाहिये; क्योंकि कर्म की गति गहन है अर्थात् समझने में बड़ी कठिन है। 4.17",

    "जो मनुष्य कर्म में अकर्म देखता है और जो अकर्म में कर्म (देखता है), वह मनुष्यों में बुद्धिमान् है, वह योगी है (और) सम्पूर्ण कर्मों को करने वाला (कृतकृत्य) है। 4.18",

    "जिसके सम्पूर्ण कर्मों के आरम्भ संकल्प और कामना से रहित हैं (तथा) जिसके सम्पूर्ण कर्म ज्ञान रूपी अग्नि से जल गये हैं, उसको ज्ञानीजन भी पण्डित (बुद्धिमान्) कहते हैं। 4.19",

    "जो कर्म और फल की आसक्ति का त्याग करके आश्रय से रहित और सदा तृप्त है, वह कर्मों में अच्छी तरह लगा हुआ भी (वास्तव में) कुछ भी नहीं करता। 4.20",

    "जिसका शरीर और अन्तःकरण अच्छी तरह से वश में किया हुआ है, जिसने सब प्रकार के संग्रह का परित्याग कर दिया है, (ऐसा) इच्छा रहित (कर्मयोगी) केवल शरीर-सम्बन्धी कर्म करता हुआ (भी) पाप को प्राप्त नहीं होता। 4.21",

    "जो कर्मयोगी फल की इच्छा के बिना, अपने-आप जो कुछ मिल जाय, उसमें सन्तुष्ट रहता है (और) (जो) ईर्ष्या से रहित, द्वन्द्वों से रहित (तथा) सिद्धि और असिद्धि में सम है, (वह) (कर्म) करते हुए भी (उससे) नहीं बँधता। 4.22",

    "जिसकी आसक्ति सर्वथा मिट गयी है, जो मुक्त हो गया है, जिसकी बुद्धि स्वरूप के ज्ञान में स्थित है, (ऐसे केवल) यज्ञ के लिये कर्म करने वाले मनुष्य के सम्पूर्ण कर्म नष्ट हो जाते हैं। 4.23",

    "जिस यज्ञ में अर्पण अर्थात् जिससे अर्पण किया जाय,वे स्रुक्, स्रुवा आदि पात्र (भी) ब्रह्म है, हव्य पदार्थ (तिल, जौ, घी आदि) (भी) ब्रह्म है (और) ब्रह्मरूप कर्ता के द्वारा ब्रह्मरूप अग्नि में आहुति देना रूप क्रिया (भी ब्रह्म है) (ऐसे यज्ञ को करने वाले) जिस मनुष्य की ब्रह्म में ही कर्म-समाधि हो गयी है, उसके द्वारा प्राप्त करने योग्य (फल भी) ब्रह्म ही है। 4.24",

    "अन्य योगी लोग दैव (भगवदर्पण रूप) यज्ञ का ही अनुष्ठान करते हैं (और) दूसरे (योगी लोग) ब्रह्म रूप अग्नि में (विचार रूप) यज्ञ के द्वारा ही (जीवात्मा रूप) यज्ञ का हवन करते हैं। 4.25",

    "अन्य (योगी लोग) श्रोत्रादि समस्त इन्द्रियों का संयम रूप अग्नियों में हवन किया करते हैं (और) दूसरे (योगी लोग) शब्दादि विषयों का इन्द्रिय रूप अग्नियों में हवन किया करते हैं। 4.26",

    "अन्य (योगी लोग) सम्पूर्ण इन्द्रियों की क्रियाओं को और प्राणों की क्रियाओं को ज्ञान से प्रकाशित आत्मसंयमयोग रूप (समाधियोग) रूप अग्नि में हवन किया करते हैं। 4.27",

    "दूसरे (कितने ही) तीक्ष्ण व्रत करने वाले प्रयत्नशील साधक द्रव्यमय यज्ञ करने वाले हैं (और दूसरे कितने ही) तपोयज्ञ करने वाले हैं और (दूसरे कितने ही) योग यज्ञ करने वाले हैं तथा (कितने ही) स्वाध्यायरूप ज्ञान यज्ञ करने वाले हैं। 4.28",

    "दूसरे (कितने ही) प्राणायाम के परायण हुए (योगी लोग) अपान में प्राण का (पूरक करके) प्राण और अपान की गति रोककर (कुम्भक करके) (फिर) प्राण में अपान का हवन (रेचक) करते हैं; तथा अन्य (कितने ही) नियमित आहार करने वाले प्राणों का प्राणों में हवन किया करते हैं। ये सभी (साधक) यज्ञों द्वारा पापों का नाश करने वाले (और) यज्ञों को जानने वाले हैं। (4.29-4.30)",

    "दूसरे (कितने ही) प्राणायाम के परायण हुए (योगी लोग) अपान में प्राण का (पूरक करके) प्राण और अपान की गति रोककर (कुम्भक करके) (फिर) प्राण में अपान का हवन (रेचक) करते हैं; तथा अन्य (कितने ही) नियमित आहार करने वाले प्राणों का प्राणों में हवन किया करते हैं। ये सभी (साधक) यज्ञों द्वारा पापों का नाश करने वाले (और) यज्ञों को जानने वाले हैं। (4.29-4.30)",

    "हे कुरुवंशियों में श्रेष्ठ अर्जुन ! यज्ञ से बचे हुए अमृत का अनुभव करने वाले सनातन परब्रह्म परमात्मा को प्राप्त होते हैं। यज्ञ न करने वाले मनुष्य के लिये यह मनुष्य लोक (भी) (सुखदायक) नहीं है, (फिर) परलोक कैसे (सुखदायक होगा)? 4.31",

    "इस प्रकार (और भी) बहुत तरह के यज्ञ वेद की वाणी में विस्तार से कहे गये हैं। उन सब यज्ञों को (तू) कर्मजन्य जान। इस प्रकार जानकर (यज्ञ करने से) (तू कर्म बन्धन से) मुक्त हो जायगा। 4.32",

    "हे परन्तप अर्जुन ! द्रव्यमय यज्ञ से ज्ञानयज्ञ श्रेष्ठ है। सम्पूर्ण कर्म (और) पदार्थ ज्ञान (तत्त्वज्ञान) में समाप्त (लीन) हो जाते हैं। 4.33",

    "उस (तत्त्वज्ञान) को (तत्त्वदर्शी ज्ञानी महापुरुषों के पास जाकर) समझ। (उनको) साष्टांग दण्डवत् प्रणाम करने से, (उनकी) सेवा करने से (और) सरलतापूर्वक प्रश्न करने से वे तत्त्वदर्शी (अनुभवी) ज्ञानी (शास्त्रज्ञ) महापुरुष (तुझे उस) तत्त्वज्ञान का उपदेश देंगे। 4.34",

    "जिस (तत्त्वज्ञान) का अनुभव करने के बाद (तू) फिर इस प्रकार मोह को नहीं प्राप्त होगा, (और) हे अर्जुन ! जिस (तत्त्वज्ञान) से (तू) सम्पूर्ण प्राणियों को निःशेषभाव से (पहले) अपने में (और) उसके बाद मुझ सच्चिदानन्द घन परमात्मा में देखेगा। 4.35",

    "अगर (तू) सब पापियों से भी अधिक पापी है, (तो भी तू) ज्ञानरूपी नौका के द्वारा निःसन्देह सम्पूर्ण पाप-समुद्र से अच्छी तरह तर जायगा। 4.36",

    "हे अर्जुन ! जैसे प्रज्वलित अग्नि ईंधनों को सर्वथा भस्म कर देती है, ऐसे ही ज्ञानरूपी अग्नि सम्पूर्ण कर्मों को सर्वथा भस्म कर देती है। 4.37",

    "इस मनुष्यलोक में ज्ञान के समान पवित्र करने वाला निःसन्देह (दूसरा कोई साधन) नहीं है। जिसका योग भली-भाँति सिद्ध हो गया है, (वह कर्मयोगी) उस तत्त्वज्ञान को अवश्य ही स्वयं अपने आप में पा लेता है। 4.38",

    "(जो) जितेन्द्रिय (तथा) साधन-परायण है, (ऐसा) श्रद्धावान् मनुष्य ज्ञान को प्राप्त होता है (और) ज्ञान को प्राप्त होकर (वह) तत्काल परम शान्ति को प्राप्त हो जाता है। 4.39",

    "विवेकहीन और श्रद्धा रहित संशयात्मा मनुष्य का पतन हो जाता है। (ऐसे) संशयात्मा मनुष्य के लिये न तो यह लोक (हितकारक) है न परलोक (हितकारक) है और न सुख (ही) है। 4.40",

    "हे धनञ्जय ! योग (समता) के द्वारा जिसका सम्पूर्ण कर्मों से सम्बन्ध-विच्छेद हो गया है (और) विवेक ज्ञान के द्वारा जिसके सम्पूर्ण संशयों का नाश हो गया है, (ऐसे) स्वरूप-परायण मनुष्य को कर्म नहीं बाँधते। 4.41",

    "इसलिये हे भरतवंशी अर्जुन ! हृदय में स्थित इस अज्ञान से उत्पन्न अपने संशय का ज्ञान रूप तलवार से छेदन करके योग (समता) में स्थित हो जा, (और युद्ध के लिये) खड़ा हो जा। 4.42",

    "इस प्रकार ॐ तत्  सत् - इन भगवन्नामों के उच्चारणपूर्वक ब्रह्मविद्या और योगशास्त्रमय श्रीमद्भगवद्गीतोपनिषदरूप श्रीकृष्णार्जुनसंवाद में ज्ञानकर्मसन्यासयोग’ नामक चौथा अध्याय पूर्ण हुआ।"
  ],
  "adhyaya-5" : [
    "अर्जुन बोले - हे कृष्ण ! (आप) कर्मों का स्वरूप से त्याग करने की और फिर कर्मयोग की प्रशंसा करते हैं। (अतः) इन दोनों साधनों में जो एक निश्चित रूपसे कल्याण कारक हो, उसको मेरे लिये कहिये। 5.1",

    "श्रीभगवान् बोले - संन्यास (सांख्ययोग) और कर्मयोग दोनों ही कल्याण करने वाले हैं। परन्तु उन दोनों में (भी) कर्मसंन्यास- (सांख्ययोग) से कर्मयोग श्रेष्ठ है। 5.2",

    "हे महाबाहो ! जो मनुष्य न (किसी से) द्वेष करता है (और) न (किसी की) आकांक्षा करता है; वह (कर्मयोगी) सदा संन्यासी समझने योग्य है; क्योंकि द्वन्द्वों से रहित (मनुष्य) सुखपूर्वक संसार-बन्धन से मुक्त हो जाता है। 5.3",

    "बेसमझ लोग सांख्ययोग और कर्मयोग को अलग-अलग (फल वाले) कहते हैं, न कि पण्डितजन; (क्योंकि) (इन दोनों में से) एक साधन में भी अच्छी तरह से (स्थित) मनुष्य दोनों के फलरूप (परमात्मा को) प्राप्त कर लेता है। 5.4",

    "सांख्ययोगियों के द्वारा जो तत्त्व प्राप्त किया जाता है, कर्मयोगियों के द्वारा भी वही प्राप्त किया जाता है। (अतः) जो मनुष्य सांख्ययोग और कर्मयोग को (फलरूप में) एक देखता है, वही (ठीक) देखता है। 5.5",

    "परन्तु हे महाबाहो ! कर्मयोग के बिना संन्यास सिद्ध होना कठिन है। मननशील कर्मयोगी शीघ्र ही ब्रह्म को प्राप्त हो जाता है। 5.6",

    "जिसकी इन्द्रियाँ अपने वश में हैं, जिसका अन्तःकरण निर्मल है, जिसका शरीर अपने वश में है (और) सम्पूर्ण प्राणियों की आत्मा ही जिसकी आत्मा है, (ऐसा) कर्मयोगी (कर्म) करते हुए भी लिप्त नहीं होता। 5.7",

    "तत्त्व को जानने वाला सांख्ययोगी देखता हुआ, सुनता हुआ, छूता हुआ, सूँघता हुआ, खाता हुआ, चलता हुआ, ग्रहण करता हुआ, बोलता हुआ, (मल-मूत्र का) त्याग करता हुआ, सोता हुआ, श्वास लेता हुआ (तथा) आँखें खोलता हुआ (और) मूँदता हुआ भी सम्पूर्ण इन्द्रियाँ इन्द्रियों के विषयों में बरत रही हैं' - ऐसा समझकर '(मैं स्वयं) कुछ भी नहीं करता हूँ' - ऐसा माने। (5.8-5.9)",

    "तत्त्व को जानने वाला सांख्ययोगी देखता हुआ, सुनता हुआ, छूता हुआ, सूँघता हुआ, खाता हुआ, चलता हुआ, ग्रहण करता हुआ, बोलता हुआ, (मल-मूत्र का) त्याग करता हुआ, सोता हुआ, श्वास लेता हुआ (तथा) आँखें खोलता हुआ (और) मूँदता हुआ भी सम्पूर्ण इन्द्रियाँ इन्द्रियों के विषयों में बरत रही हैं' - ऐसा समझकर '(मैं स्वयं) कुछ भी नहीं करता हूँ' - ऐसा माने। (5.8-5.9)",

    "जो (भक्तियोगी) सम्पूर्ण कर्मों को परमात्मा में अर्पण करके (और) आसक्ति का त्याग करके (कर्म) करता है, वह जल से कमल के पत्ते की तरह पाप से लिप्त नहीं होता। 5.10",

    "कर्मयोगी आसक्ति का त्याग करके केवल (ममतारहित) इन्द्रियाँ-शरीर-मन-बुद्धि के द्वारा केवल अन्तःकरण की शुद्धि के लिये ही कर्म करते हैं।। 5.11",

    "कर्मयोगी कर्म फल का त्याग करके नैष्ठिकी शान्ति को प्राप्त होता है। (परन्तु) सकाम मनुष्य कामना के कारण फल में आसक्त होकर बँध जाता है। 5.12",

    "जिसकी इन्द्रियाँ और मन वश में हैं, (ऐसा) देहधारी पुरुष नौ द्वारों वाले (शरीर रूपी) पुर में सम्पूर्ण कर्मों का (विवेक पूर्वक) मन से त्याग करके निःसन्देह न करता हुआ (और) न करवाता हुआ सुख पूर्वक (अपने स्वरूप में) स्थित रहता है। 5.13",

    "परमेश्वर मनुष्यों के न कर्तापन की, न कर्मों की (और) न कर्मफल के साथ संयोग की रचना करते हैं; किन्तु स्वभाव (ही) बरत रहा है। 5.14",

    "सर्वव्यापी परमात्मा न किसी के पाप कर्म को और न शुभ कर्म को ही ग्रहण करता है; (किन्तु) अज्ञान से ज्ञान ढका हुआ है, उसी से सब जीव मोहित हो रहे हैं। 5.15",

    "परन्तु जिन्होंने अपने जिस ज्ञान के द्वारा उस अज्ञान का नाश कर दिया है, उनका वह ज्ञान सूर्य की तरह परमतत्त्व परमात्मा को प्रकाशित कर देता है। 5.16",

    "जिनका मन तदाकार हो रहा है, जिनकी बुद्धि तदाकार हो रही है,  जिनकी स्थिति परमात्म तत्व में है, (ऐसे) परमात्म परायण साधक ज्ञान के द्वारा पाप रहित होकर अपुनरावृत्ति (परमगति) को प्राप्त होते हैं। 5.17",

    "ज्ञानी महापुरुष विद्या-विनय युक्त ब्राह्मण में और चाण्डाल में तथा गाय, हाथी (एवं) कुत्ते में भी समरूप परमात्मा को देखने वाले होते हैं। 5.18",

    "जिनका अन्तःकरण समता में स्थित है, उन्होंने इस जीवित-अवस्था में ही सम्पूर्ण संसार को जीत लिया है; अर्थात् वे जीवन्मुक्त हो गये हैं, क्योंकि ब्रह्म निर्दोष (और) सम है, इसलिये वे ब्रह्म में (ही) स्थित हैं। 5.19",

    "(जो) प्रिय को प्राप्त होकर हर्षित न हो और अप्रिय को प्राप्त होकर उद्विग्न न हो, (वह) स्थिर बुद्धि वाला, मूढ़ता रहित (ज्ञानी) (तथा) ब्रह्म को जानने वाला मनुष्य ब्रह्म में स्थित है। 5.20",

    "बाह्य स्पर्श (प्राकृत वस्तुमात्र के सम्बन्ध) में आसक्ति रहित अन्तःकरण वाला साधक अन्तःकरण में जो (सात्विक) सुख है, (उसको) प्राप्त होता है। (फिर) वह ब्रह्म में अभिन्न भाव से स्थित मनुष्य अक्षय सुख का अनुभव करता है। 5.21",

    "क्योंकि हे कुन्तीनन्दन ! जो इन्द्रियों और विषयों के संयोग से पैदा होने वाले भोग (सुख) हैं, वे आदि-अन्त वाले (और) दुःख के ही कारण हैं। (अतः) विवेकशील मनुष्य उनमें रमण नहीं करता। 5.22",

    "इस मनुष्य-शरीर में जो कोई (मनुष्य) शरीर छूटने से पहले ही काम-क्रोध से उत्पन्न होने वाले वेग को सहन करने में समर्थ होता है, वह नर योगी है (और) वही सुखी है। 5.23",

    "जो मनुष्य (केवल) परमात्मा में सुख वाला (और) (केवल) परमात्मा में रमण करने वाला है तथा जो केवल परमात्मा में ज्ञान वाला है, वह ब्रह्म में अपनी स्थिति का अनुभव करने वाला (ब्रह्मरूप बना हुआ सांख्ययोगी निर्वाण ब्रह्म को प्राप्त होता है। 5.24",

    "जिनका शरीर मन, बुद्धि, इन्द्रियों सहित वश में है, जो सम्पूर्ण प्राणियों के हित में रत हैं, जिनके सम्पूर्ण संशय मिट गये हैं, जिनके सम्पूर्ण दोष नष्ट हो गये हैं, वे विवेकी साधक निर्वाण ब्रह्म को प्राप्त होते हैं। 5.25",

    "काम-क्रोध से सर्वथा रहित, जीते हुए मन वाले (और) स्वरूप का साक्षात्कार किये हुए सांख्ययोगियों के लिये सब ओर से (शरीर के रहते हुए अथवा शरीर छूटने के बाद) निर्वाण ब्रह्म परिपूर्ण है। 5.26",

    "बाहर के पदार्थों को बाहर ही छोड़कर और नेत्रों की दृष्टि को भौंहों के बीच में (स्थित करके) (तथा) नासिका में विचरने वाले प्राण और अपान वायु को सम करके जिसकी इन्द्रियाँ, मन और बुद्धि अपने वश में हैं, जो (केवल) मोक्षपरायण है (तथा) जो इच्छा, भय और क्रोध से सर्वथा रहित है, वह मुनि सदा मुक्त ही है।  (5.27-5.28)",

    "बाहर के पदार्थों को बाहर ही छोड़कर और नेत्रों की दृष्टि को भौंहों के बीच में (स्थित करके) (तथा) नासिका में विचरने वाले प्राण और अपान वायु को सम करके जिसकी इन्द्रियाँ, मन और बुद्धि अपने वश में हैं, जो (केवल) मोक्षपरायण है (तथा) जो इच्छा, भय और क्रोध से सर्वथा रहित है, वह मुनि सदा मुक्त ही है।  (5.27-5.28)",

    "मुझे सब यज्ञों और तपों का भोक्ता, सम्पूर्ण लोकों का महान् ईश्वर (तथा) सम्पूर्ण प्राणियों का सुहृद् (स्वार्थ रहित, दयालु और प्रेमी) जानकर (भक्त) शान्ति को प्राप्त हो जाता है। 5.29",

    "इस प्रकार ॐ तत्  सत् - इन भगवन्नामों के उच्चारणपूर्वक ब्रह्मविद्या और योगशास्त्रमय श्रीमद्भगवद्गीतोपनिषदरूप श्रीकृष्णार्जुनसंवाद में ‘कर्मसन्यासयोग’ नामक पाँचवाँ अध्याय पूर्ण हुआ।"
  ],
  "adhyaya-6" : [
    "श्रीभगवान् बोले -- कर्मफल का आश्रय न लेकर जो कर्तव्य कर्म करता है, वही संन्यासी तथा योगी है; (और) केवल अग्नि का त्याग करने वाला (संन्यासी) नहीं होता तथा (केवल) क्रियाओं का त्याग करने वाला (योगी) नहीं होता। 6.1",

    "हे अर्जुन ! (लोग) जिसको संन्यास ऐसा कहते हैं, उसी को (तुम) योग समझो; क्योंकि संकल्पों का त्याग किये बिना (मनुष्य) कोई-सा (भी) योगी नहीं हो सकता। 6.2",

    "जो योग (समता) में आरूढ़ होना चाहता है, (ऐसे) मननशील योगी के लिये कर्तव्य कर्म करना कारण कहा गया है (और) उसी योगारूढ़ मनुष्य का शम (शान्ति) (परमात्म प्राप्ति) में कारण कहा गया है। 6.3",

    "कारण कि जिस समय न इन्द्रियों के भोगों में (तथा) न कर्मों में (ही) आसक्त होता है, उस समय (वह) सम्पूर्ण संकल्पों का त्यागी मनुष्य योगारूढ़ कहा जाता है। 6.4",

    "अपने द्वारा अपना उद्धार करे, अपना पतन न करे; क्योंकि आप ही अपना मित्र है (और) आप ही अपना शत्रु है। 6.5",

    "जिसने अपने आप से अपने आपको जीत लिया है, उसके लिये आप ही अपना बन्धु है और जिसने अपने आपको नहीं जीता है, ऐसे अनात्मा का आत्मा ही शत्रुता में शत्रु की तरह बर्ताव करता है। 6.6",

    "जिसने अपने-आप पर अपनी विजय कर ली है, उस शीत-उष्ण (अनुकूलता-प्रतिकूलता) सुख-दुःख तथा मान-अपमान में निर्विकार मनुष्य को परमात्मा नित्य प्राप्त हैं। 6.7",

    "जिसका अन्तःकरण ज्ञान-विज्ञान से तृप्त है, जो कूट की तरह निर्विकार है, जितेन्द्रिय है (और) मिट्टी के ढेले, पत्थर तथा स्वर्ण में समबुद्धि वाला है - ऐसा योगी युक्त (योगारूढ़) कहा जाता है। 6.8",

    "सुह्रद्, मित्र, वैरी, उदासीन, मध्यस्थ, द्वेष्य और सम्बन्धियों में तथा साधु आचरण करने वालों में (और) पाप आचरण करने वालों में भी समबुद्धि वाला मनुष्य श्रेष्ठ है।  6.9",

    "भोग बुद्धि से संग्रह न करने वाला, इच्छा रहित (और) अन्तःकरण तथा शरीर को वश में रखने वाला योगी अकेला एकान्त में स्थित होकर मन को निरन्तर (परमात्मा में) लगाये। 6.10",

    "शुद्ध भूमि पर, (जिस पर क्रमशः) कुश, मृगछाला और वस्त्र बिछे हैं, (जो) न अत्यन्त ऊँचा है (और) न अत्यन्त नीचा, (ऐसे) अपने आसन को स्थिर स्थापन करके। 6.11",

    "उस आसन पर बैठकर चित्त और इन्द्रियों की क्रियाओं को वश में रखते हुए मन को एकाग्र करके अन्तःकरण की शुद्धि के लिये योग का अभ्यास करे। 6.12",

    "काया, शिर और ग्रीवा को सीधे अचल धारण करके तथा दिशाओं को न देखकर (केवल) अपनी नासिका के अग्रभाग को देखते हुए स्थिर होकर (बैठे)। 6.13",

    "जिसका अन्तःकरण शान्त है, जो भयरहित है और जो ब्रह्मचारिव्रत में स्थित है, (ऐसा) सावधान ध्यान-योगी मन का संयम करके मेरे में चित्त लगाता हुआ मेरे परायण होकर बैठे। 6.14",

    "वश में किये हुए मन वाला योगी मन को इस तरह से सदा (परमात्मा में) लगाता हुआ मुझमें सम्यक् स्थिति वाली (जो) निर्वाण परमा शान्ति है, (उसको) प्राप्त हो जाता है। 6.15",

    "हे अर्जुन ! (यह) योग न तो अधिक खाने वाले का और न बिलकुल न खाने वाले का तथा न अधिक सोने वाले का और न (बिलकुल) न सोने वाले का ही सिद्ध होता है। 6.16",

    "दुःखों का नाश करने वाला योग (तो) यथायोग्य आहार और विहार करने वाले का, कर्मों में यथायोग्य चेष्टा करने वाले का (तथा) यथायोग्य सोने और जागने वाले का ही सिद्ध होता है। 6.17",

    "वश में किया हुआ चित्त जिस काल में अपने स्वरूप में ही स्थित हो जाता है (और) (स्वयं) सम्पूर्ण पदार्थों से निःस्पृह (हो जाता है), उस काल में (वह) योगी है - ऐसा कहा जाता है। 6.18",

    "जैसे स्पन्दन रहित वायु के स्थान में स्थित दीपक की लौ चेष्टा रहित हो जाती है, योग का अभ्यास करते हुए वश में किए हुए चित्त वाले योगी के चित्त की वैसी ही उपमा कही गयी है। 6.19",

    "योग का सेवन करने से जिस अवस्था में निरुद्ध चित्त उपराम हो जाता है तथा जिस अवस्था में (स्वयं) अपने आप से अपने आपको देखता हुआ अपने आप में ही सन्तुष्ट हो जाता है। 6.20",

    "जो सुख आत्यन्तिक, अतीन्द्रिय (और) बुद्धिग्राह्य है, उस सुखका जिस अवस्था में अनुभव करता है और (जिस सुख में) स्थित हुआ यह ध्यानयोगी तत्त्व से फिर (कभी) विचलित नहीं होता। 6.21",

    "जिस लाभ की प्राप्ति होने पर उससे अधिक कोई दूसरा (लाभ) (उसके) मानने में भी नहीं आता और जिसमें स्थित होने पर (वह) बड़े भारी दुःख से भी विचलित नहीं किया जा सकता। 6.22",

    "जिसमें दुःखों के संयोग का ही वियोग है, उसी को 'योग' नाम से जानना चाहिये। (वह योग जिस ध्यानयोग का लक्ष्य है,) उस ध्यानयोग का अभ्यास न उकताये हुए चित्त से निश्चयपूर्वक करना चाहिये। 6.23",

    "संकल्प से उत्पन्न होने वाली सम्पूर्ण कामनाओं का सर्वथा त्याग करके (और) मन से ही इन्द्रिय-समूह को सभी ओर से हटाकर। 6.24",

    "धैर्ययुक्त बुद्धि के द्वारा (संसार से) धीरे-धीरे उपराम हो जाय (और) मन (बुद्धि) को परमात्मस्वरूप में सम्यक् प्रकार से स्थापन करके (फिर) कुछ भी चिन्तन न करे। 6.25",

    "(यह) अस्थिर (और) चंचल मन जहाँ-जहाँ विचरण करता है, वहाँ-वहाँ से हटाकर इसको (एक) परमात्मा में ही भली भाँति लगाये। 6.26",

    "जिसके सब पाप नष्ट हो गये हैं, जिसका रजोगुण शान्त हो गया है (तथा) जिसका मन सर्वथा शान्त (निर्मल) हो गया है, (ऐसे) इस ब्रह्मरूप बने हुए योगी को निश्चित ही उत्तम (सात्त्विक) सुख प्राप्त होता है। 6.27",

    "इस प्रकार अपने आपको सदा (परमात्मा में) लगाता हुआ पाप रहित योगी सुखपूर्वक ब्रह्म प्राप्ति रूप अत्यन्त सुख का अनुभव कर लेता  है। 6.28",

    "सब जगह अपने स्वरूप को देखने वाला और ध्यानयोग से युक्त अन्तःकरण वाला (सांख्ययोगी) अपने स्वरूप को सम्पूर्ण प्राणियों में स्थित देखता है (और) सम्पूर्ण प्राणियों को अपने स्वरूप में (देखता है)। 6.29",

    "जो (भक्त) सब में मुझे देखता है और मुझमें सबको देखता है, उसके लिये मैं अदृश्य नहीं होता और वह मेरे लिये अदृश्य नहीं होता। 6.30",

    "(मुझमें) एकीभाव से स्थित हुआ जो भक्तियोगी सम्पूर्ण प्राणियों में स्थित मेरा भजन करता है, वह सब कुछ बर्ताव करता हुआ भी मुझ में (ही) बर्ताव कर रहा है अर्थात् वह नित्य निरन्तर मुझमें  ही स्थित है। 6.31",

    "हे अर्जुन ! जो (भक्त) अपने शरीर की उपमा से सब जगह (मुझे) समान देखता है और सुख अथवा दुःख को (भी समान देखता है), वह परम योगी माना गया है। 6.32",

    "अर्जुन बोले - हे मधुसूदन ! आपने समतापूर्वक जो यह योग कहा है, (मन की) चंचलता के  कारण मैं इस योग की स्थिर स्थिति नहीं देखता हूँ। 6.33",

    "कारण कि  हे कृष्ण ! मन (बड़ा ही) चंचल, प्रमथनशील, दृढ़ (जिद्दी) (और) बलवान है। उसको रोकना मैं (आकाश में स्थित) वायु की तरह अत्यन्त कठिन मानता हूँ।  6.34",

    "श्रीभगवान् बोले - हे महाबाहो ! यह मन बड़ा चंचल है (और) इसका निग्रह करना भी बड़ा कठिन है - यह तुम्हारा कहना बिलकुल ठीक है। परन्तु हे कुन्तीनन्दन! अभ्यास और वैराग्य के द्वारा (इसका) निग्रह किया जाता है। 6.35",

    "जिसका मन पूरा वश में नहीं है, उसके द्वारा योग प्राप्त होना कठिन है। परन्तु उपायपूर्वक यत्न करने वाले (तथा) वश में किये हुए मन वाले साधक को (योग) प्राप्त हो सकता है, ऐसा मेरा मत है। 6.36",

    "अर्जुन बोले - हे कृष्ण ! जिसकी साधन में श्रद्धा है, पर जिसका प्रयत्न शिथिल है, (वह अन्त समय में अगर) योग से विचलितमना हो जाय, (तो) (वह) योगसिद्धि को प्राप्त न करके किस गति को चला जाता है? 6.37",

    "हे महाबाहो ! संसार के आश्रय से रहित (और) परमात्मप्राप्ति के मार्ग में मोहित अर्थात् विचलित (इस तरह) दोनों ओर से भ्रष्ट हुआ साधक क्या छिन्न-भिन्न बादल की तरह नष्ट तो नहीं हो जाता ? 6.38",

    "हे कृष्ण! मेरे इस सन्देह का सर्वथा छेदन करने के लिये (आप ही) योग्य हैं; क्योंकि इस संशय का छेदन करने वाला आपके सिवाय दूसरा कोई हो नहीं सकता। 6.39",

    "श्रीभगवान् बोले - हे पृथानन्दन ! उसका न तो इस लोक में (और) न परलोक में ही विनाश होता है; क्योंकि हे प्यारे ! कल्याणकारी काम करने वाला कोई भी मनुष्य दुर्गति को प्राप्त नहीं जाता। 6.40",

    "(वह) योगभ्रष्ट पुण्य कर्म करने वालों के लोकों को प्राप्त होकर (और) (वहाँ) बहुत वर्षों तक रहकर (फिर यहाँ) शुद्ध (ममता रहित) श्रीमानों के घर में जन्म लेता है। 6.41",

    "अथवा (वैराग्यवान् योगभ्रष्ट) ज्ञानवान् योगियों के कुल में ही जन्म लेता है। इस प्रकार का जो यह जन्म है, (यह) संसार में निःसंदेह बहुत ही दुर्लभ है। 6.42",

    "हे कुरुनन्दन ! वहाँ पर उसको पहले मनुष्य जन्म की साधन-सम्पत्ति (अनायास ही) प्राप्त हो जाती है। फिर उससे (वह) साधन की सिद्धि के विषय में पुनः (विशेषता से) यत्न करता है। 6.43",

    "वह (श्रीमानों के घर में जन्म लेने वाला योगभ्रष्ट मनुष्य) (भोगों के) परवश होता हुआ भी उस पहले मनुष्य जन्म में किये हुए अभ्यास (साधन) के कारण ही (परमात्मा की तरफ) खिंच जाता है; क्योंकि योग (समता) का जिज्ञासु भी वेदों में कहे हुए सकाम कर्मों का अतिक्रमण कर जाता है। 6.44",

    "परन्तु जो योगी प्रयत्नपूर्वक यत्न करता है (और) जिसके पाप नष्ट हो गये हैं तथा जो अनेक जन्मों से सिद्ध हुआ है, वह योगी फिर परमगति को प्राप्त हो जाता है। 6.45",

    "(सकाम भाव वाले) तपस्वियों से (भी) योगी श्रेष्ठ है, ज्ञानियों से भी (योगी) श्रेष्ठ है और कर्मियों से भी योगी श्रेष्ठ है (ऐसा मेरा) मत है। अतः हे अर्जुन ! (तू) योगी हो जा। 6.46",

    "सम्पूर्ण योगियों में भी जो श्रद्धावान् भक्त मुझ में तल्लीन हुए मन से मेरा भजन करता है, वह मेरे मत में सर्वश्रेष्ठ योगी है। 6.47",

    "इस प्रकार ॐ तत्  सत् - इन भगवन्नामों के उच्चारणपूर्वक ब्रह्मविद्या और योगशास्त्रमय श्रीमद्भगवद्गीतोपनिषदरूप श्रीकृष्णार्जुनसंवाद में  आत्मसंयमयोग’ नामक छठा अध्याय पूर्ण हुआ।"   
  ],
  "adhyaya-7" : [
    "श्रीभगवान् बोले - हे पृथानन्दन ! मुझमें आसक्त मनवाला, मेरे आश्रित होकर योग का अभ्यास करता हुआ (तू) मेरे (जिस) समग्र रूप को निःसन्देह जिस प्रकार से जानेगा, उसको (उसी प्रकार से) सुन। 7.1",

    "तेरे लिये मैं यह विज्ञान सहित ज्ञान सम्पूर्णता से कहूँगा, जिसको जानने के बाद फिर इस विषय में जानने योग्य अन्य (कुछ भी) शेष नहीं रहेगा। 7.2",

    "हजारों मनुष्यों में कोई (एक) सिद्धि (कल्याण) के लिये यत्न करता है (और) (उन) यत्न करने वाले सिद्धों (मुक्त पुरुषों) में कोई (एक) ही मुझे यथार्थ रूप से जानता है। 7.3",

    "पृथ्वी, जल, तेज, वायु, आकाश - (ये पंच महाभूत) और मन, बुद्धि तथा अहंकार - इस प्रकार यह आठ प्रकार के भेदों वाली मेरी अपरा प्रकृति है। हे महाबाहो ! इस अपरा प्रकृति से भिन्न जीवरूप बनी हुई मेरी परा प्रकृति को जान, जिसके द्वारा यह जगत धारण किया जाता है। (7.4-7.5)",

    "पृथ्वी, जल, तेज, वायु, आकाश - (ये पंच महाभूत) और मन, बुद्धि तथा अहंकार - इस प्रकार यह आठ प्रकार के भेदों वाली मेरी अपरा प्रकृति है। हे महाबाहो ! इस अपरा प्रकृति से भिन्न जीवरूप बनी हुई मेरी परा प्रकृति को जान, जिसके द्वारा यह जगत धारण किया जाता है। (7.4-7.5)",

    "सम्पूर्ण प्राणियों के (उत्पन्न होने में) अपरा और परा - इन दोनों प्रकृतियों का संयोग ही कारण है - ऐसा तुम समझो। मैं सम्पूर्ण जगत का प्रभव तथा प्रलय हूँ। 7.6",

    "इसलिये हे धनञ्जय ! मेरे सिवाय (इस जगत का) दूसरा कोई किंचिन्मात्र भी (कारण तथा कार्य) नहीं है। (जैसे सूत की) मणियाँ सूत के धागे में (पिरोयी हुई होती हैं), ऐसे ही यह सम्पूर्ण जगत मेरे में (ही) ओत-प्रोत है। 7.7",

    "हे कुन्तीनन्दन! जलों में रस मैं हूँ, चन्द्रमा और सूर्य में प्रभा (प्रकाश) मैं हूँ, सम्पूर्ण वेदों में प्रणव (ओंकार), आकाश में शब्द (और) मनुष्यों में पुरुषार्थ (मैं हूँ)। 7.8",

    "पृथ्वी में पवित्र गन्ध (मैं हूँ), और अग्नि में तेज मैं हूँ तथा सम्पूर्ण प्राणियों में जीवनी शक्ति (मैं हूँ) और तपस्वियों में तपस्या मैं हूँ। 7.9",

    "हे पृथानन्दन ! सम्पूर्ण प्राणियों का अनादि बीज मुझे जान। बुद्धिमानों में बुद्धि (और) तेजस्वियों में तेज मैं हूँ। 7.10",

    "हे भरतवंशियों में श्रेष्ठ अर्जुन ! बलवालों में काम और राग से रहित बल मैं हूँ और प्राणियों में धर्म से अविरुद्ध (धर्मयुक्त) काम मैं हूँ। 7.11",

    "और तो क्या कहूँ - जितने भी सात्त्विक भाव हैं (और) जितने भी राजस तथा तामस (भाव हैं, वे सब) मुझ से ही होते हैं - ऐसा उनको समझो। परन्तु मैं उनमें (और) वे मुझमें नहीं हैं। 7.12",

    "किन्तु - इन तीनों गुण रूप भावों से मोहित यह सम्पूर्ण जगत (प्राणिमात्र) इन गुणों से अतीत अविनाशी मुझे नहीं जानता। 7.13",

    "क्योंकि मेरी यह गुणमयी दैवी माया दुरत्यय है अर्थात् इससे पार पाना बड़ा कठिन है। जो केवल मेरे ही शरण होते हैं, वे इस माया को तर जाते हैं। 7.14",

    "परन्तु - माया के द्वारा जिनका ज्ञान हरा गया है, (वे) आसुर भाव का आश्रय लेने वाले (और) मनुष्यों में महान नीच (तथा) पाप-कर्म करने वाले मूढ़ मनुष्य मेरे शरण नहीं होते। 7.15",

    "हे भरतवंशियों में श्रेष्ठ अर्जुन ! पवित्र कर्म करने वाले अर्थार्थी, आर्त, जिज्ञासु और ज्ञानी अर्थात् प्रेमी - (ये) चार प्रकार के मनुष्य मेरा भजन करते हैं अर्थात् मेरे शरण होते हैं। 7.16",

    "उन चार भक्तों में मुझ में निरन्तर लगा हुआ, अनन्य भक्ति वाला ज्ञानी अर्थात् प्रेमी भक्त श्रेष्ठ है; क्योंकि ज्ञानी भक्त को मैं अत्यन्त प्रिय हूँ और वह भी मुझे (अत्यन्त) प्रिय है। 7.17",

    "पहले कहे हुए सबके सब (चारों) ही भक्त बड़े उदार (श्रेष्ठ भाव वाले) हैं। परन्तु ज्ञानी (प्रेमी) तो मेरा स्वरूप ही है - (ऐसा मेरा) मत है। कारण कि वह मुझसे अभिन्न है (और) जिससे श्रेष्ठ दूसरी कोई गति नहीं है, (ऐसे) मुझ में ही दृढ़ स्थित है। 7.18",

    "बहुत जन्मों के अन्तिम जन्म में अर्थात् मनुष्य जन्म में सब कुछ परमात्मा ही है - इस प्रकार (जो) ज्ञानवान मेरे शरण होता है, वह महात्मा अत्यन्त दुर्लभ है। 7.19",

    "परन्तु - उन-उन कामनाओं से जिनका ज्ञान हरा गया है, (ऐसे मनुष्य) अपनी-अपनी प्रकृति अर्थात स्वभाव से नियन्त्रित होकर उस उस अर्थात देवताओं के उन-उन नियमों को धारण करते हुए उन-उन देवताओं के शरण हो जाते हैं। 7.20",

    "जो-जो भक्त जिस-जिस देवता का श्रद्धापूर्वक पूजन करना चाहता है, उस-उस देवता में ही मैं उसी श्रद्धा को दृढ़ कर देता हूँ। 7.21",

    "उस (मेरे द्वारा दृढ़ की हुई) श्रद्धा से युक्त होकर वह मनुष्य उस देवता की (सकाम भावपूर्वक) उपासना करता है और उसकी वह कामना पूरी भी होती है; परन्तु वह कामना-पूर्ति मेरे द्वारा ही विहित की हुई होती है। 7.22",

    "परन्तु उन तुच्छ बुद्धि वाले मनुष्यों को उन देवताओं की आराधना का फल अन्त वाला (नाशवान्) ही मिलता है। देवताओं का पूजन करने वाले देवताओं को प्राप्त होते हैं (और) मेरे भक्त मेरे ही प्राप्त होते हैं। 7.23",

    "बुद्धिहीन मनुष्य मेरे परम, अविनाशी (और) सर्वश्रेष्ठ भाव को न जानते हुए अव्यक्त (मन-इन्द्रियों से पर) मुझ (सच्चिदानन्दघन परमात्मा) को मनुष्य की तरह शरीर धारण करनेवाला मानते हैं। 7.24",

    "यह जो मूढ़ मनुष्य समुदाय मुझे अज (और) अविनाशी ठीक तरह से नहीं जानता (मानता), उन सबके (सामने) योगमाया से अच्छी तरह से ढका हुआ मैं प्रकट नहीं होता। 7.25",

    "हे अर्जुन ! जो प्राणी भूतकाल में हो चुके हैं, तथा जो वर्तमान में हैं और जो भविष्य में होंगे, (उन सब प्राणियों को) तो मैं जानता हूँ; परन्तु मुझे (भक्त के सिवाय) कोई भी नहीं जानता। 7.26",

    "कारण कि - हे भरतवंश में उत्पन्न शत्रु तापन परंतप ! इच्छा (राग) और द्वेष से उत्पन्न होने वाले द्वन्द्व-मोह से (मोहित) सम्पूर्ण प्राणी संसार में (अनादि काल से) मूढ़ता को अर्थात् जन्म-मरण को प्राप्त हो रहे हैं। 7.27",

    "परन्तु जिन पुण्यकर्मा मनुष्यों के पाप नष्ट गये हैं, वे द्वन्द्व मोह से रहित हुए मनुष्य दृढ़व्रती होकर मेरा भजन करते हैं। 7.28",

    "वृद्धावस्था और मृत्यु से मुक्ति पाने के लिये जो मेरा आश्रय लेकर प्रयत्न करते हैं, वे उस ब्रह्म को, सम्पूर्ण अध्यात्म को और सम्पूर्ण कर्म को भी जान जाते हैं। 7.29",

    "जो मनुष्य अधिभूत तथा अधिदैव के सहित और अधियज्ञ के सहित मुझे जानते हैं, वे मुझमें लगे हुए चित्त वाले मनुष्य अन्तकाल में भी मुझे ही जानते हैं अर्थात् प्राप्त होते हैं। 7.30",

    "इस प्रकार ॐ तत्  सत् - इन भगवन्नामों के उच्चारणपूर्वक ब्रह्मविद्या और योगशास्त्रमय श्रीमद्भगवद्गीतोपनिषदरूप श्रीकृष्णार्जुनसंवाद में ‘ज्ञानविज्ञानयोग’ नामक सातवाँ अध्याय पूर्ण हुआ।"   
  ],
  "adhyaya-8" : [
    "अर्जुन बोले -हे पुरुषोत्तम! वह ब्रह्म क्या है? आध्यात्म क्या है? कर्म क्या है ? अधिभूत किसको कहा गया है और अधिदैव किसको कहा जाता है? यहाँ अधियज्ञ कौन है और (वह) इस देह में कैसे है? हे मधुसूदन! वशीभूत अंतःकरण वाले मनुष्यों के द्वारा, अन्त काल में (आप) कैसे जानने में आते हैं? (8.1-8.2)",

    "अर्जुन बोले -हे पुरुषोत्तम! वह ब्रह्म क्या है? आध्यात्म क्या है? कर्म क्या है ? अधिभूत किसको कहा गया है और अधिदैव किसको कहा जाता है? यहाँ अधियज्ञ कौन है और (वह) इस देह में कैसे है? हे मधुसूदन! वशीभूत अंतःकरण वाले मनुष्यों के द्वारा, अन्त काल में (आप) कैसे जानने में आते हैं? (8.1-8.2)",

    "श्रीभगवान बोले-  परम अक्षर ब्रह्म है (और) परा प्रकृति (जीव) को अध्यात्म कहते हैं,  प्राणियों की सत्ता को प्रकट करने वाला त्याग कर्म कहा जाता है। 8.3",

    "हे देहधारियों में श्रेष्ठ अर्जुन! क्षर भाव अर्थात् नाशवान् पदार्थ अधिभूत हैं, पुरुष अर्थात हिरण्यगर्भ ब्रह्मा अधिदैव हैं और इस देह में (अंतर्यामी रूप से) मैं ही अधियज्ञ हूँ। 8.4",

    "जो मनुष्य अन्तकाल में भी मेरा स्मरण करते हुए शरीर छोड़ कर जाता है, वह मेरे स्वरूप को ही प्राप्त होता हैं, इसमें सन्देह नहीं है। 8.5",

    "हे कुन्तीपुत्र अर्जुन! (मनुष्य) अन्तकाल में जिस-जिस भी भाव का स्मरण करते हुए शरीर छोड़ता है, वह उस (अंतकाल के) भाव से सदा भावित होता हुआ उस- उस को ही प्राप्त होता है अर्थात् उस - उस योनि में ही चला जाता है। 8.6",

    "इसलिए (तू) सब समय में मेरा स्मरण कर (और) युद्ध भी कर। मुझमें मन और बुद्धि अर्पित करने वाला (तू) निःसन्देह मुझे ही प्राप्त होगा। 8.7",

    " हे पृथानन्दन! अभ्यासयोग से युक्त (और) अन्य का चिन्तन न करने वाले चित्त से परम दिव्य पुरुष का चिंतन करता हुआ (शरीर छोड़ने वाला मनुष्य) (उसी को) प्राप्त हो जाता है। 8.8",

    "जो सर्वज्ञ, अनादि, सब पर शासन करने वाला, सूक्ष्म से अत्यन्त सूक्ष्म, सबका धारण-पोषण करने वाला, अज्ञान से अत्यन्त परे, सूर्य की तरह प्रकाशस्वरूप अर्थात् ज्ञानस्वरूप  ऐसे अचिन्त्य स्वरूप का चिंतन करता है। 8.9",

    "वह भक्तियुक्त मनुष्य अन्त समय में अचल मन से और योग बल के द्वारा भृकुटी के मध्य में प्राणों को अच्छी तरह से प्रविष्ट करके (शरीर छोड़ने पर) उस परम दिव्य पुरुष को ही प्राप्त होता है। 8.10",

    "वेदवेत्ता लोग जिसको अक्षर कहते हैं, वीतराग यति जिसको प्राप्त करते हैं (और) (साधक) जिसकी (प्राप्ति की) इच्छा करते हुए, ब्रह्मचर्य का पालन करते हैं, वह पद (मैं) तेरे लिए संक्षेप में कहूँगा। 8.11",

    "(इंद्रियों के) संपूर्ण द्वारों को रोक कर, मन का ह्रदय में निरोध करके और अपने प्राणों को मस्तक में स्थापित करके, योगधारणा में सम्यक् प्रकार से स्थित हुआ जो साधक ‘ॐ' इस एक अक्षर ब्रह्म का (मानसिक) उच्चारण (और) मेरा स्मरण करता हुआ, शरीर को छोड़कर जाता है वह परम गति को प्राप्त होता है। (8.12-8.13)",

    "(इंद्रियों के) संपूर्ण द्वारों को रोक कर, मन का ह्रदय में निरोध करके और अपने प्राणों को मस्तक में स्थापित करके, योगधारणा में सम्यक् प्रकार से स्थित हुआ जो साधक ‘ॐ' इस एक अक्षर ब्रह्म का (मानसिक) उच्चारण (और) मेरा स्मरण करता हुआ, शरीर को छोड़कर जाता है वह परम गति को प्राप्त होता है। (8.12-8.13)",

    "हे पृथानन्दन! अनन्य चित्तवाला जो मनुष्य मेरा नित्य निरंतर स्मरण करता है, उस नित्य निरंतर मुझ में लगे हुए योगी के लिए मैं सुलभ हूँ अर्थात् उसको सुलभता से प्राप्त हो जाता हूँ। 8.14",

    "महात्मा लोग मुझे प्राप्त करके दुःखालय अर्थात् दुःखों के घर (और) अशाश्वत अर्थात् निरंतर बदलने वाले पुनर्जन्म को प्राप्त नहीं होते; (क्योंकि वे) परम सिद्धि को प्राप्त हो गए हैं अर्थात् उनको परम प्रेम की प्राप्ति हो गई है। 8.15",

    "हे अर्जुन! ब्रह्मलोक तक सभी लोक पुनरावृत्ति वाले हैं अर्थात् वहाँ जाने पर पुनः लौट कर संसार में आना पड़ता है; परंतु हे कौन्तेय! मुझे प्राप्त होने पर पुनर्जन्म नहीं होता 8.16",

    "जो मनुष्य ब्रह्मा के एक हजार चतुर्युगी वाले एक दिन को (और) एक हजार चतुर्युगी वाली एक रात्रि को जानते हैं, वे मनुष्य ब्रह्मा के दिन और रात को जानने वाले हैं। 8.17",

    "ब्रह्मा के दिन के आरम्भ काल में अव्यक्त (ब्रह्मा के सूक्ष्म शरीर) से संपूर्ण शरीर पैदा होते हैं (और) ब्रह्मा की रात के आरंभकाल में उस अव्यक्त नाम से नाम वाले (ब्रह्मा के सूक्ष्म शरीर) में ही (संपूर्ण शरीर) लीन हो जाते हैं। 8.18",

    "हे पार्थ! वही यह प्राणी समुदाय उत्पन्न हो- होकर प्रकृति के परवश हुआ ब्रह्मा के दिन के समय उत्पन्न होता है (और) ब्रह्मा की रात्रि के समय लीन होता है। 8.19",

    "परंतु उस अव्यक्त (ब्रह्मा के सूक्ष्म शरीर) से अन्य (विलक्षण) अनादि अत्यंत श्रेष्ठ भाव रूप जो अव्यक्त (ईश्वर) है, वह संपूर्ण प्राणियों के नष्ट होने पर भी नष्ट नहीं होता। 8.20",

    "उसी को अव्यक्त (और) अक्षर ऐसा कहा गया है (तथा उसी को) परम गति कहा गया है (और) जिसको प्राप्त होने पर (जीव) फिर लौटकर (संसार में) नहीं आते, वह मेरा परमधाम है। 8.21",

    "हे पृथानन्दन अर्जुन!  संपूर्ण प्राणी जिसके अंतर्गत हैं (और) जिससे यह संपूर्ण संसार व्याप्त है, वह परम पुरुष परमात्मा तो अनन्य भक्ति से प्राप्त होने योग्य हैं। 8.22",

    "परन्तु, हे भरतवंशियों में श्रेष्ठ अर्जुन! जिस काल अर्थात् मार्ग में शरीर छोड़कर गए हुए योगी अनावृत्ति को प्राप्त होते हैं अर्थात् पीछे लौट कर नहीं आते और (जिस मार्ग में गए हुए) आवृत्ति को प्राप्त होते हैं अर्थात् पीछे लौट कर आते हैं, उस काल को अर्थात् दोनों मार्गों को मैं कहूँगा। 8.23",

    "प्रकाश स्वरूप अग्नि का अधिपति देवता, दिन का अधिपति देवता, शुक्ल पक्ष का अधिपति देवता, और छः महीनों वाले उत्तरायण का अधिपति देवता है,उस मार्ग से (शरीर छोड़कर) गए हुए ब्रह्मवेत्ता पुरुष (पहले ब्रह्मलोक को प्राप्त होकर पीछे ब्रह्मा के साथ) ब्रह्म को प्राप्त हो जाते हैं। 8.24",

    "धूम का अधिपति देवता, रात्रि का अधिपति देवता, कृष्ण पक्ष का अधिपति देवता, और छह महीनों वाले दक्षिणायन का अधिपति देवता है, (शरीर छोड़कर) उस मार्ग से गया हुआ योगी (सकाम मनुष्य) चन्द्रमा की ज्योति को प्राप्त होकर लौट आता है अर्थात जन्म- मरण को प्राप्त होता है। 8.25",

    "क्योंकि शुक्ल और कृष्ण यह दोनों गतियाँ अनादि काल से जगत (प्राणीमात्र) के साथ सम्बन्ध रखने वाली मानी गई है। इनमें से एक गति में जाने वाले को लौटना नहीं पड़ता और दूसरी गति में जाने वाले को पुनः लौटना पड़ता है। 8.26",

    "हे पृथानन्दन! इन दोनों मार्गों को जानने वाला कोई भी योगी मोहित नहीं होता।अतः हे अर्जुन! तू() सब समय में योगयुक्त, (समता में) स्थित हो जा। 8.27",

    "योगी (भक्त) इसको (इस अध्याय में वर्णित विषय को) जानकर वेदों में, यज्ञों में, तपों में तथा दान में जो- जो पुण्यफल कहे गए हैं, उन सभी पुण्यफलों का अतिक्रमण कर जाता है और आदिस्थान परमात्मा को प्राप्त हो जाता है। 8.28",

    "इस प्रकार ॐ तत्  सत् - इन भगवन्नामों के उच्चारणपूर्वक ब्रह्मविद्या और योगशास्त्रमय श्रीमद्भगवद्गीतोपनिषदरूप श्रीकृष्णार्जुनसंवाद में ‘अक्षरब्रह्मयोग’ नामक आठवाँ अध्याय पूर्ण हुआ।"
  ],
  "adhyaya-9" : [
    "श्रीभगवान् बोले -- यह अत्यन्त गोपनीय विज्ञान सहित ज्ञान दोष दृष्टि रहित तेरे लिये तो (मैं फिर) अच्छी तरह से कहूँगा, जिसको जानकर (तू) अशुभ से अर्थात् जन्म-मरण रूप संसार से मुक्त हो जायगा। 9.1",

    "यह (विज्ञान सहित ज्ञान अर्थात् समग्र रूप) सम्पूर्ण विद्याओं का राजा (और) सम्पूर्ण गोपनीयों का राजा है। यह अति पवित्र (तथा) अतिश्रेष्ठ है (और) इसका फल भी प्रत्यक्ष है। यह धर्ममय है, अविनाशी है (और) करने में बहुत सुगम है अर्थात् इसको प्राप्त करना बहुत सुगम है। 9.2",

    "हे परंतप! इस धर्म की महिमा पर श्रद्धा न रखने वाले मनुष्य मुझे प्राप्त न होकर मृत्युरूप संसार के मार्ग में लौटते रहते हैं अर्थात् बार-बार जन्मते-मरते रहते हैं। 9.3",

    "यह सब संसार मेरे निराकार स्वरूप से व्याप्त है। सम्पूर्ण प्राणी मुझ में स्थित हैं; परन्तु मैं उनमें स्थित नहीं हूँ तथा (वे) प्राणी (भी) मुझ में स्थित नहीं हैं - मेरे इस ईश्वर-सम्बन्धी योग (सामर्थ्य) को देख ! सम्पूर्ण प्राणियों को उत्पन्न करने वाला और प्राणियों का धारण, भरण-पोषण करने वाला मेरा स्वरूप उन प्राणियों में स्थित नहीं है। (9.4-9.5)",

    "यह सब संसार मेरे निराकार स्वरूप से व्याप्त है। सम्पूर्ण प्राणी मुझ में स्थित हैं; परन्तु मैं उनमें स्थित नहीं हूँ तथा (वे) प्राणी (भी) मुझ में स्थित नहीं हैं - मेरे इस ईश्वर-सम्बन्धी योग (सामर्थ्य) को देख ! सम्पूर्ण प्राणियों को उत्पन्न करने वाला और प्राणियों का धारण, भरण-पोषण करने वाला मेरा स्वरूप उन प्राणियों में स्थित नहीं है। (9.4-9.5)",

    "जैसे सब जगह विचरने वाली महान् वायु नित्य ही आकाश में स्थित रहती है, ऐसे ही सम्पूर्ण प्राणी मुझमें ही स्थित रहते हैं - ऐसा तुम मान लो। 9.6",

    "हे कुन्तीनन्दन ! कल्पों का क्षय होने पर (महाप्रलय के समय) सम्पूर्ण प्राणी मेरी प्रकृति को प्राप्त होते हैं (और) कल्पों के आदि में (महासर्ग के समय) मैं फिर उनकी रचना करता हूँ। 9.7",

    "प्रकृति के वश में होने से परतन्त्र हुए इस सम्पूर्ण प्राणी समुदाय की (कल्पों के आदि में) मैं अपनी प्रकृति को वश में करके बार-बार रचना करता हूँ। 9.8",

    "हे धनञ्जय ! उन (सृष्टि-रचना आदि) कर्मों में अनासक्त और उदासीन की तरह रहते हुए मुझे वे कर्म नहीं बाँधते। 9.9",

    "प्रकृति मेरी अध्यक्षता में सम्पूर्ण चराचर जगत को रचती है। हे कुन्तीनन्दन ! इसी हेतु से जगत का (विविध प्रकार से) परिवर्तन होता है। 9.10",

    "मूर्ख लोग मेरे सम्पूर्ण प्राणियों के महान् ईश्वररूप श्रेष्ठ भाव को न जानते हुए मुझे मनुष्य शरीर के आश्रित मानकर अर्थात् साधारण मनुष्य मानकर (मेरी) अवज्ञा करते हैं। 9.11",

    "(जो) आसुरी, राक्षसी और मोहिनी प्रकृति का ही आश्रय लेते हैं, ऐसे अविवेकी मनुष्यों की सब आशाएँ व्यर्थ होती हैं, सब शुभ-कर्म व्यर्थ होते हैं (और) सब ज्ञान व्यर्थ होते हैं अर्थात् जिनकी आशाएँ, कर्म और ज्ञान (समझ) सत्-फल देने वाले नहीं होते। 9.12",

    "परन्तु हे पृथानन्दन ! दैवी प्रकृति के आश्रित अनन्य मन वाले महात्मा लोग मुझे सम्पूर्ण प्राणियों का आदि (और) अविनाशी समझकर मेरा भजन करते हैं। 9.13",

    "नित्य- निरन्तर (मुझ में) लगे हुए मनुष्य दृढ़व्रती होकर लगन पूर्वक साधन में लगे हुए और प्रेम पूर्वक कीर्तन करते हुए तथा मुझे नमस्कार करते हुये निरन्तर मेरी उपासना करते हैं। 9.14",

    "दूसरे साधक ज्ञान यज्ञ के द्वारा एकीभाव से (अभेद-भाव से) मेरा पूजन करते हुए मेरी उपासना करते हैं और दूसरे भी कई साधक (अपने को) पृथक् मानकर चारों तरफ मुखवाले मेरे विराट रुप की अर्थात् संसार को मेरा विराट रुप मानकर सेव्य-सेवक भाव से (मेरी) अनेक प्रकार से (उपासना करते हैं)। 9.15",

    "क्रतु मैं हूँ, यज्ञ मैं हूँ, स्वधा मैं हूँ, औषध मैं हूँ, मन्त्र मैं हूँ, घृत मैं हूँ, अग्नि मैं हूँ (और) हवन रूप क्रिया भी मैं हूँ। जानने योग्य पवित्र, ओंकार, ऋग्वेद, सामवेद और यजुर्वेद भी मैं ही हूँ। इस सम्पूर्ण जगत का पिता, धाता, माता, पितामह, गति, भर्ता, प्रभु, साक्षी, निवास, आश्रय, सुहृद्, उत्पत्ति, प्रलय, स्थान, निधान (भण्डार) (तथा) अविनाशी बीज (भी मैं ही हूँ)। (9.16-9.18)",

    "क्रतु मैं हूँ, यज्ञ मैं हूँ, स्वधा मैं हूँ, औषध मैं हूँ, मन्त्र मैं हूँ, घृत मैं हूँ, अग्नि मैं हूँ (और) हवन रूप क्रिया भी मैं हूँ। जानने योग्य पवित्र, ओंकार, ऋग्वेद, सामवेद और यजुर्वेद भी मैं ही हूँ। इस सम्पूर्ण जगत का पिता, धाता, माता, पितामह, गति, भर्ता, प्रभु, साक्षी, निवास, आश्रय, सुहृद्, उत्पत्ति, प्रलय, स्थान, निधान (भण्डार) (तथा) अविनाशी बीज (भी मैं ही हूँ)। (9.16-9.18)",

    "क्रतु मैं हूँ, यज्ञ मैं हूँ, स्वधा मैं हूँ, औषध मैं हूँ, मन्त्र मैं हूँ, घृत मैं हूँ, अग्नि मैं हूँ (और) हवन रूप क्रिया भी मैं हूँ। जानने योग्य पवित्र, ओंकार, ऋग्वेद, सामवेद और यजुर्वेद भी मैं ही हूँ। इस सम्पूर्ण जगत का पिता, धाता, माता, पितामह, गति, भर्ता, प्रभु, साक्षी, निवास, आश्रय, सुहृद्, उत्पत्ति, प्रलय, स्थान, निधान (भण्डार) (तथा) अविनाशी बीज (भी मैं ही हूँ)। (9.16-9.18)",

    "हे अर्जुन ! (संसार के हित के लिये) मैं (ही) सूर्य रूप से तपता हूँ, मैं (ही) जल को ग्रहण करता हूँ और (फिर उस जल को) (मैं ही) वर्षा रूप से बरसा देता हूँ (और तो क्या कहूँ) अमृत और मृत्यु तथा सत् और असत् (भी) मैं ही हूँ। 9.19",

    "तीन वेदों में कहे हुए सकाम अनुष्ठान को करने वाले (और) सोमरस को पीने वाले (जो) पाप रहित मनुष्य यज्ञों के द्वारा (इन्द्ररूप से) मेरा पूजन करके स्वर्ग-प्राप्ति की प्रार्थना करते हैं, वे (पुण्यों के फलस्वरूप) पवित्र इन्द्रलोक को प्राप्त करके (वहाँ) स्वर्ग में देवताओं के दिव्य भोगों को भोगते हैं। 9.20",

    "वे उस विशाल स्वर्गलोक के (भोगों को) भोगकर पुण्य क्षीण होने पर मृत्युलोक में आ जाते हैं। इस प्रकार तीनों वेदों में कहे हुए सकाम धर्म का आश्रय लिये हुए भोगों की कामना करने वाले मनुष्य आवागमन को प्राप्त होते हैं। 9.21",

    "जो अनन्य भक्त मेरा चिन्तन करते हुए (मेरी) भली भांति उपासना करते हैं, (मुझ में) निरन्तर लगे हुए उन भक्तों का योगक्षेम (अप्राप्त की प्राप्ति और प्राप्त की रक्षा) मैं वहन करता हूँ। 9.22",

    "हे कुन्तीनन्दन! जो भी भक्त (मनुष्य) श्रद्धापूर्वक अन्य देवताओं का पूजन करते हैं, वे भी मेरा ही पूजन करते हैं, (पर करते है) अविधिपूर्वक अर्थात् देवताओं को मुझसे अलग मानते हैं। 9.23",

    "क्योंकि सम्पूर्ण यज्ञों का भोक्ता और स्वामी भी मैं ही हूँ; परन्तु वे मुझे तत्त्व से नहीं जानते, इसी से उनका पतन होता है। 9.24",

    "(सकाम भाव से) देवताओं का पूजन करने वाले (शरीर छोड़ने पर) देवताओं को प्राप्त होते हैं। पितरों का पूजन करने वाले पितरों को प्राप्त होते हैं। भूत-प्रेतों का पूजन करने वाले भूत-प्रेतों को प्राप्त होते हैं। (परन्तु) मेरा पूजन करने वाले मुझे ही प्राप्त होते हैं। 9.25",

    "जो भक्त पत्र, पुष्प, फल, जल आदि (यथासाध्य एवं अनायास प्राप्त वस्तु) को प्रेमपूर्वक मेरे अर्पण करता है, उस (मुझमें) तल्लीन हुए अन्तःकरण वाले भक्त के द्वारा प्रेमपूर्वक दिये हुए उपहार (भेंट) को मैं खा लेता हूँ अर्थात् स्वीकार कर लेता हूँ। 9.26",

    "हे कुन्तीपुत्र ! (तू) जो कुछ करता है, जो कुछ भोजन करता है, जो कुछ यज्ञ करता है, जो कुछ दान देता है (और) जो कुछ तप करता है, वह (सब) मेरे अर्पण कर दे। 9.27",

    "इस प्रकार (मेरे अर्पण करने से) कर्म बन्धन से और शुभ (विहित) और अशुभ (निषिद्ध) सम्पूर्ण कर्मों के फलों से (तू) मुक्त हो जायगा। ऐसे अपने सहित सब कुछ मेरे अर्पण करने वाला (और) सबसे सर्वथा मुक्त हुआ (तू) मुझे प्राप्त हो जायगा। 9.28",

    "मैं सम्पूर्ण प्राणियों में समान हूँ। (उन प्राणियों में) न तो कोई मेरा द्वेषी है (और) न कोई प्रिय है। परन्तु जो प्रेमपूर्वक मेरा भजन करते हैं, वे मुझ में हैं और मैं भी उनमें हूँ। 9.29",

    "अगर (कोई) दुराचारी से दुराचारी भी अनन्य भक्त होकर मेरा भजन करता है (तो) उसको साधु ही मानना चाहिये। कारण कि उसने निश्चय बहुत अच्छी तरह कर लिया है। 9.30",

    "(वह) तत्काल (उसी क्षण) धर्मात्मा हो जाता है (और) निरन्तर रहने वाली शान्ति को प्राप्त हो जाता है। हे कुन्तीनन्दन ! मेरे भक्त का पतन नहीं होता (ऐसी तुम) प्रतिज्ञा करो। 9.31",

    "हे पृथानन्दन ! जो भी पाप योनि वाले हों (तथा जो भी) स्त्रियाँ, वैश्य और शूद्र (हों), वे भी सर्वथा मेरे शरण होकर निःसन्देह परमगति को प्राप्त हो जाते हैं। 9.32",

    "(जो) पवित्र आचरण वाले ब्राह्मण और ऋषिस्वरूप क्षत्रिय भगवान् के भक्त हों, (वे परम गति को प्राप्त हो जायँ) इसमें तो कहना ही क्या है। (इसलिये) इस अनित्य (और) सुखरहित शरीर को प्राप्त करके (तू) मेरा भजन कर। 9.33",

    "(तू) मेरा भक्त हो जा, मुझमें मन वाला हो जा, मेरा पूजन करने वाला हो जा (और) मुझे नमस्कार कर। इस प्रकार अपने-आपको (मेरे साथ) लगाकर, मेरे परायण हुआ (तू) मुझे ही प्राप्त होगा। 9.34",

    "इस प्रकार ॐ तत्  सत् - इन भगवन्नामों के उच्चारणपूर्वक ब्रह्मविद्या और योगशास्त्रमय श्रीमद्भगवद्गीतोपनिषदरूप श्रीकृष्णार्जुनसंवाद में ‘राजविद्याराजगुह्ययोग’ नामक नवाँ अध्याय पूर्ण हुआ।"
  ],
  "adhyaya-10" : [
    "श्रीभगवान् बोले -- हे महाबाहो अर्जुन ! मेरे परम वचन को (तुम) फिर भी सुनो, जिसे मैं मुझमें अत्यन्त प्रेम रखने तुम्हारे लिए हित की कामना से कहूँगा। 10.1",

    "मेरे प्रकट होने को न देवता जानते हैं (और) न महर्षि; क्योंकि मैं सब प्रकार से देवताओं और महर्षियों का आदि हूँ। 10.2",

    "जो (मनुष्य) मुझे अजन्मा, अनादि और सम्पूर्ण लोकों का महान् ईश्वर जानता है अर्थात् दृढ़ता से (संदेह रहित) स्वीकार कर लेता है, वह मनुष्यों में ज्ञानवान है (और) (वह) सम्पूर्ण पापों से मुक्त हो जाता है। 10.3",

    "बुद्धि, ज्ञान, असम्मोह, क्षमा, सत्य, दम, शम तथा  सुख, दुःख, उत्पत्ति, विनाश, भय, अभय और अहिंसा, समता, संतोष, तप, दान, यश और अपयश - प्राणियों के (ये) अनेक प्रकार के और अलग-अलग (बीस) भाव मुझसे ही होते हैं। (10.4-10.5)",

    "बुद्धि, ज्ञान, असम्मोह, क्षमा, सत्य, दम, शम तथा  सुख, दुःख, उत्पत्ति, विनाश, भय, अभय और अहिंसा, समता, संतोष, तप, दान, यश और अपयश - प्राणियों के (ये) अनेक प्रकार के और अलग-अलग (बीस) भाव मुझसे ही होते हैं। (10.4-10.5)",

    "सात महर्षि (और) उनसे भी पहले होने वाले चार सनकादि तथा चौदह मनु (ये सब-के-सब) (मेरे) मन से पैदा हुए हैं (और) मुझमें भाव (श्रद्धाभक्ति) रखने वाले हैं, जिनकी संसार में यह सम्पूर्ण प्रजा है। 10.6",

    "जो मनुष्य मेरी इस विभूति को और योग (सामर्थ्य) को तत्त्व से जानता है अर्थात् दृढ़ता पूर्वक मानता है, वह अविचल भक्तियोग से युक्त हो जाता है; इसमें (कुछ भी) संशय नहीं है। 10.7",

    "मैं संसारमात्र का प्रभव (मूल कारण) हूँ, (और) मुझसे ही सारा संसार प्रवृत्त हो रहा है अर्थात् चेष्टा कर रहा है - ऐसा मानकर मुझमें ही श्रद्धा-प्रेम रखते हुए बुद्धिमान् भक्त मेरा ही भजन करते हैं - सब प्रकार से मेरे ही शरण होते हैं। 10.8",

    "मुझमें चित्तवाले, मुझमें प्राणों को अर्पण करने वाले (भक्तजन) आपस में (मेरे गुण, प्रभाव आदि को) जानते हुए और उनका कथन करते हुए नित्य-निरन्तर सन्तुष्ट रहते हैं और मुझमें प्रेम करते हैं। 10.9",

    "उन नित्य-निरन्तर मुझमें लगे हुए (और) प्रेमपूर्वक (मेरा) भजन करने वाले भक्तों को (मैंः वह बुद्धियोग देता हूँ, जिससे उनको मेरी प्राप्ति हो जाती है। 10.10",

    "उन भक्तों पर कृपा करने के लिये ही उनके स्वरूप (होने पन) में रहने वाला मैं (उनके) अज्ञानजन्य अन्धकार को देदीप्यमान ज्ञानरूप दीपक के द्वारा नष्ट कर देता हूँ। 10.11",

    "अर्जुन बोले - परम ब्रह्म, परम धाम (और) महान् पवित्र आप ही हैं। (आप) शाश्वत, दिव्य पुरुष, आदिदेव, अजन्मा (और) सर्वव्यापक हैं - (ऐसा) आपको सबके सब ऋषि, देवर्षि नारद, असित, देवल तथा व्यास कहते हैं और स्वयं आप भी मेरे प्रति कहते हैं। (10.12-10.13)",

    "अर्जुन बोले - परम ब्रह्म, परम धाम (और) महान् पवित्र आप ही हैं। (आप) शाश्वत, दिव्य पुरुष, आदिदेव, अजन्मा (और) सर्वव्यापक हैं - (ऐसा) आपको सबके सब ऋषि, देवर्षि नारद, असित, देवल तथा व्यास कहते हैं और स्वयं आप भी मेरे प्रति कहते हैं। (10.12-10.13)",

    "हे केशव ! मुझसे (आप) जो कुछ कह रहे हैं, यह सब (मैं) सत्य मानता हूँ। हे भगवन् ! आपके प्रकट होने को न तो देवता जानते हैं (और) न दानव ही जानते हैं। 10.14",

    "हे भूतभावन ! हे भूतेश ! हे देवदेव ! हे जगत्पते ! हे पुरुषोत्तम ! आप स्वयं ही अपने-आपसे अपने-आपको जानते हैं। 10.15",

    "इसलिए जिन विभूतियों से आप इन सम्पूर्ण लोकों को व्याप्त करके स्थित हैं, (उन सभी) अपनी दिव्य विभूतियों का सम्पूर्णता से वर्णन करने में (आप ही) समर्थ हैं। 10.16",

    "हे योगिन् ! हरदम सांगोपांग चिन्तन करता हुआ मैं आपको कैसे जानूँ ? और हे भगवन् ! किन-किन भावों में (आप) मेरे द्वारा चिन्तन किये जा सकते हैं अर्थात् किन-किन भावों में मैं आपका चिन्तन करूँ? 10.17",

    "हे जनार्दन ! (आप) अपने योग (सामर्थ्य) को और विभूतियों को विस्तार से फिर कहिये; क्योंकि (आपके) अमृतमय वचन सुनते-सुनते मेरी तृप्ति नहीं हो रही है। 10.18",

    "श्रीभगवान् बोले -- हाँ, ठीक है। मैं अपनी दिव्य विभूतियों को तेरे लिये प्रधानता से (संक्षेप से) कहूँगा; क्योंकि हे कुरुश्रेष्ठ ! मेरी विभूतियों के विस्तार का अन्त नहीं है। 10.19",

    "हे नींद को जीतने वाले अर्जुन ! सम्पूर्ण प्राणियों के आदि, मध्य तथा अन्त में मैं ही हूँ और सम्पूर्ण प्राणियों के अन्तःकरण (ह्रदय) में स्थित आत्मा भी मैं  हूँ। 10.20",

    "मैं अदिति के पुत्रों में विष्णु (वामन) (और) प्रकाशमान वस्तुओं में किरणों वाला सूर्य हूँ। मैं मरुतों का तेज (और) नक्षत्रों का अधिपति चन्द्रमा हूँ। 10.21",

    "(मैं) वेदों में सामवेद हूँ, देवताओं में इन्द्र हूँ, इन्द्रियों में मन हूँ और प्राणियों की चेतना हूँ। 10.22",

    "रुद्रों में शंकर और यक्ष-राक्षसों में कुबेर मैं हूँ। वसुओं में पवित्र करने वाली अग्नि और शिखरवाले पर्वतों में सुमेरु मैं हूँ। 10.23",

    "हे पार्थ ! पुरोहितों में मुख्य बृहस्पति को मेरा स्वरूप समझो। सेनापतियों में कार्तिकेय और जलाशयों में समुद्र मैं हूँ। 10.24",

    "महर्षियों में भृगु और वाणियों (शब्दों) में एक अक्षर अर्थात् प्रणव मैं हूँ। सम्पूर्ण यज्ञों में जप यज्ञ (और) स्थिर रहने वालों में हिमालय मैं हूँ। 10.25",

    "सम्पूर्ण वृक्षों में पीपल, देवर्षियों में नारद, गन्धर्वों में चित्ररथ और सिद्धों में कपिल मुनि (मैं हूँ)। 10.26",

    "घोड़ों में अमृत के साथ समुद्र से प्रकट होने वाले उच्चैःश्रवा नामक घोड़े को, श्रेष्ठ हाथियों में ऐरावत नामक हाथी को और मनुष्यों में राजा को मेरी विभूति मानो। 10.27",

    "आयुधों में वज्र (और) धेनुओं में कामधेनु मैं हूँ। सन्तान-उत्पत्ति का हेतु कामदेव मैं हूँ और सर्पों में वासुकि मैं हूँ। 10.28",

    "नागों में अनन्त (शेषनाग) और जल-जन्तुओं का अधिपति वरुण मैं हूँ। पितरों में अर्यमा और शासन करने वालों में यमराज मैं हूँ। 10.29",

    "दैत्यों में प्रह्लाद और गणना करने वालों (ज्योतिषियों) में काल मैं हूँ  तथा पशुओं में सिंह और पक्षियों में गरुड मैं हूँ। 10.30",

    "पवित्र करने वालों में वायु (और) शास्त्रधारियों में राम मैं हूँ। जल-जन्तुओं में मगर मैं हूँ। बहने वाले स्त्रोतों में गंगाजी मैं हूँ। 10.31",

    "हे अर्जुन ! सम्पूर्ण सृष्टियो के आदि, मध्य तथा अन्त में मैं ही हूँ। विद्याओं में अध्यात्मविद्या (ब्रह्म विद्या) और परस्पर शास्त्रार्थ करने वालों का(तत्त्व-निर्णय के लिये किया जाने वाला) वाद मैं हूँ। 10.32",

    "अक्षरों में अकार और समासों में द्वन्द्व समास मैं हूँ। अक्षय काल अर्थात् काल का भी महाकाल (तथा) सब ओर मुख वाला धाता (सबका पालन-पोषण करने वाला भी) मैं ही हूँ। 10.33",

    "सबका हरण करने वाली मृत्यु और भविष्य में उत्पन्न होने वाला मैं हूँ तथा स्त्री-जाति में कीर्ति, श्री, वाक् (वाणी), स्मृति, मेधा, धृति और क्षमा (मैं हूँ)। 10.34",

    "गायी जाने वाली श्रुतियों में बृहत्साम और सब छन्दों में गायत्री छन्द मैं हूँ। बारह महीनों में मार्गशीर्ष (और) छः ऋतुओं में वसन्त मैं हूँ। 10.35",

    "छल करने वालों में जुआ (और) तेजस्वियों में तेज मैं हूँ। (जीतने वालों की) विजय, (निश्चय करने वालों का) निश्चय (और) सात्त्विक मनुष्यों का सात्त्विक भाव मैं हूँ। 10.36",

    "वृष्णि वंशियों में वसुदेव पुत्र श्रीकृष्ण (और) पाण्डवों में अर्जुन मैं हूँ। मुनियों में वेदव्यास (और) कवियों में कवि शुक्राचार्य भी मैं हूँ। 10.37",

    "दमन करनेवालोंमें दण्डनीति और विजय चाहनेवालोंमें नीति मैं हूँ। गोपनीय भावोंमें मौन और ज्ञानवानोंमें ज्ञान मैं हूँ। 10.38",

    "और हे अर्जुन! सम्पूर्ण प्राणियों का जो बीज (मूलकारण) है, वह बीज भी मैं ही हूँ; (क्योंकि) वह चर-अचर (कोई) प्राणी नहीं है जो मेरे बिना हो अर्थात् चर-अचर सब कुछ मैं ही हूँ। 10.39",

    "हे परंतप अर्जुन ! मेरी दिव्य विभूतियों का अन्त नहीं है। मैंने (तुम्हारे सामने अपनी) विभूतियों का जो विस्तार कहा है, यह तो (केवल) संक्षेप से नामनात्र कहा है। 10.40",

    "जो-जो ऐश्वर्ययुक्त, शोभायुक्त और बलयुक्त प्राणी तथा पदार्थ हैं, उस-उसको तुम मेरे ही तेज (योग अर्थात् सामर्थ्य) के अंश से उत्पन्न हुई समझो। 10.41",

    "अथवा हे अर्जुन ! तुम्हें इस प्रकार बहुत-सी बातें जानने की क्या आवश्यकता है? (जबकि) मैं (अपने किसी) एक अंश से इस सम्पूर्ण जगत् को व्याप्त करके स्थित हूँ अर्थात् अनन्त ब्रह्मांड मेरे एक अंश में है। 10.42",

    "इस प्रकार ॐ तत्  सत् - इन भगवन्नामों के उच्चारणपूर्वक ब्रह्मविद्या और योगशास्त्रमय श्रीमद्भगवद्गीतोपनिषदरूप श्रीकृष्णार्जुनसंवाद में ‘विभूतियोग’ नामक दसवाँ अध्याय पूर्ण हुआ।"    
  ],
  "adhyaya-11" : [
    "अर्जुन बोले - केवल मेरे पर कृपा करने के लिये ही आपने जो परम गोपनीय अध्यात्म - विषयक वचन कहे, उससे मेरा यह मोह नष्ट हो गया है। 11.1",

    "क्योंकि हे कमलनयन! सम्पूर्ण प्राणियों के उत्पत्ति तथा विनाश मैंने विस्तारपूर्वक आपसे ही सुने हैं और (आपका) अविनाशी माहात्म्य भी (सुना) है। 11.2",

    "हे पुरुषोत्तम! आप अपने-आपको जैसा कहते हैं, यह (वास्तव में) ऐसा ही है। हे परमेश्वर! आपके ईश्वर-सम्बन्धी रूप को (मैं) देखना चाहता हूँ 11.3",

    "हे प्रभो! मेरे द्वारा (आपका) वह परम ऐश्वर रूप देखा जा सकता है - ऐसा अगर (आप) मानते हैं, तो हे योगेश्वर! आप अपने (उस) अविनाशी स्वरूप को मुझे दिखा दीजिये। 11.4",

    "श्री भगवान् बोले - हे पृथानन्दन! अब मेरे अनेक तरह के, और अनेक वर्णों (रंगों) तथा आकृतियों वाले सैकड़ों-हजारों अलौकिक रूपों को (तू) देख। 11.5",

    "हे भरतवंशोद्भव अर्जुन! तू बारह आदित्यों को, आठ वसुओं को, ग्यारह रुद्रों को (और) दो अश्विनीकुमारों को तथा उनचास मरुद्गणों को देख। जिनको तूने पहले कभी देखा नहीं, (ऐसे) बहुत-से आश्चर्यजनक रूपों को (भी) (तू) देख। 11.6",

    "हे नींद को जीतने वाले अर्जुन! मेरे इस शरीर के एक देश में चराचर सहित सम्पूर्ण जगत् को अभी देख ले। इसके सिवाय (तू) और भी जो कुछ देखना चाहता है, (वह भी देख ले)। 11.7",

    "परन्तु (तू) इस अपनी आँख (चर्मचक्षु) से मुझे देख ही नहीं सकता, (इसलिये मैं) तुझे दिव्य चक्षु देता हूँ, (जिससे तू) मेरी ईश्वरीय सामर्थ्य को देख। 11.8",

    "सञ्जय बोले - हे राजन्! ऐसा कहकर फिर महायोगेश्वर भगवान् श्रीकृष्ण ने अर्जुन को परम ऐश्वर विराट रूप दिखाया। 11.9",

    "जिसके अनेक मुख और नेत्र हैं, अनेक तरह के अद्भुत दर्शन हैं, अनेक अलौकिक आभूषण हैं, हाथों में उठाये हुए अनेक दिव्य आयुध हैं (तथा) जिनके गले में दिव्य मालाएँ हैं, जो अलौकिक वस्त्र पहने हुए हैं, जिनके ललाट तथा शरीर पर दिव्य चन्दन, कुंकुम आदि लगा हुआ है, ऐसे सम्पूर्ण आश्चर्यमय, अनन्त रूपों वाले (तथा) सब तरफ मुखों वाले देव (अपने दिव्य स्वरूप) को भगवान् ने दिखाया। (11.10-11.11)",

    "जिसके अनेक मुख और नेत्र हैं, अनेक तरह के अद्भुत दर्शन हैं, अनेक अलौकिक आभूषण हैं, हाथों में उठाये हुए अनेक दिव्य आयुध हैं (तथा) जिनके गले में दिव्य मालाएँ हैं, जो अलौकिक वस्त्र पहने हुए हैं, जिनके ललाट तथा शरीर पर दिव्य चन्दन, कुंकुम आदि लगा हुआ है, ऐसे सम्पूर्ण आश्चर्यमय, अनन्त रूपों वाले (तथा) सब तरफ मुखों वाले देव (अपने दिव्य स्वरूप) को भगवान् ने दिखाया। (11.10-11.11)",

    "(अगर) आकाश में एक साथ हजारों सूर्यों का उदय हो जाय, (तो भी) उन सबका प्रकाश (मिलकर) उस महात्मा (विराट् रूप परमात्मा) के प्रकाश के समान शायद ही हो अर्थात् नहीं हो सकता। 11.12",

    "उस समय अर्जुन ने देवों के देव भगवान् के उस शरीर में एक जगह स्थित अनेक प्रकार के विभागों में विभक्त सम्पूर्ण जगत् को देखा। 11.13",

    "भगवान् के विश्वरूप को देखकर वे अर्जुन बहुत चकित हुए (और) आश्चर्य के कारण उनका शरीर रोमाञ्चित हो गया। (वे) हाथ जोड़कर विश्वरूप देव को मस्तक से प्रणाम कर के बोले। 11.14",

    "अर्जुन बोले - हे देव! (मैं) आपके शरीर में सम्पूर्ण देवताओं को तथा प्राणियों के विशेष-विशेष समुदायों को और कमलासन पर बैठे हुए ब्रह्माजी को, शङ्करजी को, सम्पूर्ण ऋषियों को और दिव्य सर्पों को देख रहा हूँ। 11.15",

    "हे विश्वरूप! हे विश्वेश्वर! आपको (मैं) अनेक हाथों, पेटों, मुखों और नेत्रों वाला (तथा) सब ओर से अनन्त रूपों वाला देख रहा हूँ। (मैं) आपके न आदि को, न मध्य को और न अन्त को ही देख रहा हूँ। 11.16",

    "(मैं) आपको किरीट (मुकुट), गदा, चक्र (तथा शंख और पद्म) धारण किये हुए हुए देख रहा हूँ। (आपको) तेज की राशि, सब ओर प्रकाश वाले, देदीप्यमान अग्नि तथा सूर्य के समान कान्तिवाले, नेत्रों के द्वारा कठिनता से देखे जाने योग्य और सब तरफ से अप्रमेय स्वरूप (देख रहा हूँ)। 11.17",

    "आप (ही) जानने योग्य परम अक्षर (अक्षरब्रह्म) हैं, आप (ही) इस सम्पूर्ण विश्व के परम आश्रय हैं, आप (ही) सनातन धर्म के रक्षक हैं (और) आप ही अविनाशी सनातन पुरुष हैं - (ऐसा) मैं मानता हूँ। 11.18",

    "आपको (मैं) आदि, मध्य और अन्त से रहित, अनन्त प्रभावशाली, अनन्त भुजाओं वाले, चन्द्र और सूर्य रूप नेत्रों वाले, प्रज्वलित अग्नि रूप मुखों वाले (और) अपने तेज से संसार को तपाते करते हुए देख रहा हूँ। 11.19",

    "हे महात्मन्! यह स्वर्ग और पृथ्वी के बीच का अन्तराल और सम्पूर्ण दिशाएँ एक आपसे ही परिपूर्ण हैं। आपके इस अद्भुत (और) उग्ररूप को देखकर तीनों लोक व्यथित (व्याकुल) हो रहे हैं। 11.20",

    "वे ही देवताओं के समुदाय आप में प्रविष्ट हो रहे हैं। (उनमें से) कई तो भयभीत होकर हाथ जोड़े हुए (आपके नामों और गुणों का) कीर्तन कर रहे हैं। महर्षियों और सिद्धों के समुदाय 'कल्याण हो! मंगल हो!' ऐसा कहकर उत्तम-उत्तम स्तोत्रों के द्वारा आपकी स्तुति कर रहे हैं। 11.21",

    "जो ग्यारह रुद्र, बारह आदित्य, आठ वसु, बारह साध्यगण, दस विश्वेदेव और दो अश्विनीकुमार, उनचास मरुद्गण और गरम गरम भोजन करने वाले (सात पितृगण) तथा गन्धर्व, यक्ष, असुर और सिद्धों के समुदाय हैं, (वे) सभी चकित होकर आपको देख रहे हैं। 11.22",

    "हे महाबाहो! आपके बहुत मुखों और नेत्रों वाले, बहुत भुजाओं, जंघाओं और चरणों वाले, बहुत उदरों वाले (और) बहुत विकराल दाढ़ों वाले महान् रूप को देखकर सब प्राणी व्यथित हो रहे हैं तथा मैं भी (व्यथित हो रहा हूँ)। 11.23",

    "क्योंकि हे विष्णो! (आपके) देदीप्यमान अनेक वर्ण हैं, आप आकाश को स्पर्श कर रहे हैं अर्थात् सब तरफ से बहुत बड़े हैं, आपका मुख फैला हुआ है, आपके नेत्र प्रदीप्त और विशाल हैं। (ऐसे) आपको देखकर भयभीत अन्तःकरणवाला मैं धैर्य और शान्ति को प्राप्त नहीं हो रहा हूँ। 11.24",

    "आपके प्रलय काल की अग्नि के समान प्रज्वलित और दाढ़ों के कारण विकराल (भयानक) मुखों को देखकर (मुझे) न तो दिशाओं का ज्ञान हो रहा है और न शान्ति ही मिल रही है। (इसलिये) हे देवेश! हे जगन्निवास! (आप) प्रसन्न होइये। 11.25",

    "हमारे पक्ष के मुख्य-मुख्य योद्धाओं के सहित भीष्म, द्रोण और वह कर्ण भी आप में (प्रविष्ट हो रहे हैं)। राजाओं के समुदायों के सहित धृतराष्ट्र के वे ही सब के सब पुत्र आपके विकराल दाढ़ों के कारण भयंकर मुखों में बड़ी तेजी से प्रविष्ट हो रहे हैं। (उनमें से) कई एक तो चूर्ण हुए सिरों सहित (आपके) दाँतों के बीच में फँसे हुए दीख रहे हैं। (11.26-11.27)",

    "हमारे पक्ष के मुख्य-मुख्य योद्धाओं के सहित भीष्म, द्रोण और वह कर्ण भी आप में (प्रविष्ट हो रहे हैं)। राजाओं के समुदायों के सहित धृतराष्ट्र के वे ही सब के सब पुत्र आपके विकराल दाढ़ों के कारण भयंकर मुखों में बड़ी तेजी से प्रविष्ट हो रहे हैं। (उनमें से) कई एक तो चूर्ण हुए सिरों सहित (आपके) दाँतों के बीच में फँसे हुए दीख रहे हैं। (11.26-11.27)",

    "जैसे नदियों के बहुत-से जल के प्रवाह (स्वाभाविक) ही समुद्र के सम्मुख दौड़ते हैं, ऐसे ही वे संसार के महान् शूरवीर आपके सब तरफ से देदीप्यमान मुखों में प्रवेश कर रहे हैं। 11.28",

    "जैसे पतंगे (मोहवश) (अपना) नाश करने के लिये बड़े वेग से दौड़ते हुए प्रज्वलित अग्नि में प्रविष्ट होते हैं, ऐसे ही ये सब लोग भी (मोहवश) (अपना) नाश करने के लिये बड़े वेग से दौड़ते हुए आपके मुखों में प्रविष्ट हो रहे हैं। 11.29",

    "(आप अपने) प्रज्वलित मुखों द्वारा सम्पूर्ण लोकों का ग्रसन करते हुए (उन्हें) सब ओर से बार-बार चाट रहे हैं (और) हे विष्णो! आपका उग्र प्रकाश अपने तेज से सम्पूर्ण जगत को परिपूर्ण करके (सबको) तपा रहा है। 11.30",

    "मुझे यह बताइये कि उग्र रूप वाले आप कौन हैं? हे देवताओं में श्रेष्ठ! आपको नमस्कार हो। (आप) प्रसन्न होइये। आदिरूप आपको (मैं) तत्त्व से जानना चाहता हूँ; क्योंकि मैं आपकी प्रवृत्ति को भली भांति नहीं जानता। 11.31",

    "श्रीभगवान् बोले - (मैं) सम्पूर्ण लोकों का नाश करने वाला बढ़ा हुआ काल हूँ (और) इस समय (मैं) (इन सब) लोगों का संहार करने के लिये (यहाँ) आया हूँ। (तुम्हारे) प्रतिपक्ष में जो योद्धा लोग खड़े हैं, (वे) सब तुम्हारे (युद्ध किये) बिना भी नहीं रहेंगे। 11.32",

    "इसलिये तुम (युद्ध के लिये) खड़े हो जाओ और यश को प्राप्त करो (तथा) शत्रुओं को जीतकर धन-धान्य से सम्पन्न राज्य को भोगो। ये सभी मेरे द्वारा पहले से ही मारे हुए हैं। हे सव्यसाचिन्! अर्थात् दोनों हाथों से बाण चलाने वाले अर्जुन! (तुम इनको मारने में) निमित्त मात्र बन जाओ। 11.33",

    "द्रोण और भीष्म तथा जयद्रथ और कर्ण तथा अन्य सभी मेरे द्वारा मारे हुए शूरवीरों को तुम मारो। तुम व्यथा मत करो (और) युद्ध करो। युद्ध में (तुम निःसन्देह) वैरियों को जीतोगे। 11.34",

    "सञ्जय बोले - भगवान केशव का यह वचन सुनकर (भय से) काँपते हुए किरीटधारी अर्जुन हाथ जोड़कर नमस्कार करके (और) भयभीत होते हुए भी फिर प्रणाम करके गद्गद् वाणी से भगवान् कृष्ण से बोले। 11.35",

    "अर्जुन बोले - हे अन्तर्यामी भगवन! आपके (नाम, गुण, लीला का) कीर्तन करने से यह सम्पूर्ण जगत् हर्षित हो रहा है और अनुराग (प्रेम) को प्राप्त हो रहा है। (आपके नाम, गुण आदि के कीर्तन से) भयभीत होकर राक्षस लोग दसों दिशाओं में भागते हुए जा रहे हैं और सम्पूर्ण सिद्धगण आपको नमस्कार कर रहे हैं। यह सब होना उचित ही है। 11.36",

    "हे महात्मन्! गुरुओं के भी गुरु और ब्रह्मा के भी आदिकर्ता आपके लिये (वे सिद्धगण) नमस्कार क्यों नहीं करें? (क्योंकि) हे अनन्त! हे देवेश! हे जगन्निवास! आप अक्षरस्वरूप हैं; (आप) सत् भी हैं, असत् भी हैं, (और) उनसे (सत्-असत् से) पर भी जो कुछ है (वह भी आप ही हैं)। 11.37",

    "आप (ही) आदिदेव और पुराण पुरुष हैं (तथा) आप (ही) इस संसार के परम आश्रय हैं। (आप ही) सबको जानने वाले, जानने योग्य और परमधाम हैं। हे अनन्तरूप! आपसे (ही) सम्पूर्ण संसार व्याप्त है। 11.38",

    "आप ही वायु, यमराज, अग्नि, वरुण, चन्द्रमा, दक्ष आदि प्रजापति और प्रपितामह (ब्रह्माजी के भी पिता) हैं। आपको हजारों बार नमस्कार हो! नमस्कार हो! और फिर भी आपको बार-बार नमस्कार हो! नमस्कार हो! 11.39",

    "हे सर्व स्वरूप! आपको आगे से (भी) नमस्कार हो!  पीछे से (भी) नमस्कार हो! सब ओर से (दशों दिशाओं से) ही नमस्कार हो! हे अनन्तवीर्य! असीम पराक्रम वाले आपने सबको (एक देश में) समेट रखा है; अतः सब कुछ (आप ही) हैं। 11.40",

    "आपकी इस महिमा को न जानते हुए 'मेरे सखा हैं' ऐसा मानकर मैंने प्रमाद से अथवा प्रेम से हठपूर्वक (बिना सोचे-समझे) 'हे कृष्ण! हे यादव! हे सखे!' इस प्रकार जो कुछ कहा है; और हे अच्युत! हँसी-दिल्लगी में, चलते-फिरते, सोते-जागते, उठते-बैठते, खाते-पीते समय अकेले अथवा उन (सखाओं, कुटुम्बियों आदि) के सामने (मेरे द्वारा आपका) जो कुछ तिरस्कार (अपमान) किया गया है;  हे अप्रमेयस्वरुप! वह सब आपसे मैं क्षमा करवाता हूँ अर्थात आपसे क्षमा माँगता हूँ। (11.41-11.42)",

    "आपकी इस महिमा को न जानते हुए 'मेरे सखा हैं' ऐसा मानकर मैंने प्रमाद से अथवा प्रेम से हठपूर्वक (बिना सोचे-समझे) 'हे कृष्ण! हे यादव! हे सखे!' इस प्रकार जो कुछ कहा है; और हे अच्युत! हँसी-दिल्लगी में, चलते-फिरते, सोते-जागते, उठते-बैठते, खाते-पीते समय अकेले अथवा उन (सखाओं, कुटुम्बियों आदि) के सामने (मेरे द्वारा आपका) जो कुछ तिरस्कार (अपमान) किया गया है;  हे अप्रमेयस्वरुप! वह सब आपसे मैं क्षमा करवाता हूँ अर्थात आपसे क्षमा माँगता हूँ। (11.41-11.42)",

    "आप (ही) इस चराचर संसार के पिता हैं, (आप ही) पूजनीय हैं और (आप ही) गुरुओं के महान् गुरु हैं। हे अनन्त प्रभावशाली भगवन्! इस त्रिलोकी में आपके समान भी दूसरा कोई नहीं है, (फिर आपसे) अधिक तो हो ही कैसे सकता है! 11.43",

    "इसलिये स्तुति करने योग्य आप ईश्वर को मैं शरीर से लम्बा पड़कर प्रणाम करके प्रसन्न करना चाहता हूँ। पिता जैसे पुत्र के, मित्र जैसे मित्र के (और) पति जैसे पत्नी के (अपमान) को सह लेता है, (ऐसे ही) हे देव ! (आप मेरे द्वारा किया गया अपमान) सहने में अर्थात क्षमा करने में समर्थ हैं। 11.44",

    "जिसको पहले कभी नहीं देखा, उस रूप को देखकर मैं हर्षित हो रहा हूँ और (साथ ही साथ) भय से मेरा मन अत्यन्त व्यथित हो रहा है। (अतः आप) मुझे (अपने) उसी देवरूप (शान्त विष्णुरूप को) दिखाइये। हे देवेश! हे जगन्निवास! (आप) प्रसन्न होइये। 11.45",

    "मैं आपको वैसे ही किरीट (मुकुट)धारी, गदाधारी (और) हाथ में चक्र लिये अर्थात चतुर्भुज रूप से देखना चाहता हूँ। (इसलिये) हे सहस्रबाहो! हे विश्वमूर्ते! (आप) उसी चतुर्भुज रूप (शंख, चक्र, गदा पद्म सहित) से हो जाइये। 11.46",

    "श्रीभगवान् बोले - हे अर्जुन! मैंने प्रसन्न होकर अपनी सामर्थ्य से मेरा यह अत्यन्त श्रेष्ठ, तेजस्मवरूप, सबका आदि (और) अनन्त विश्वरूप तुझे दिखाया है, जिसको तुम्हारे सिवाय पहले किसी ने नहीं देखा है। 11.47",

    "हे कुरुश्रेष्ठ! मनुष्य लोक में इस प्रकार के विश्वरूप वाला मैं न वेदों के पाठ से, न यज्ञों के अनुष्ठान से, न शास्त्रों के अध्ययन से, न दान से, न उग्र तपों से और न मात्र क्रियाओं से तेरे (कृपापात्र के) सिवाय और किसी के द्वारा देखा जा सकता हूँ। 11.48",

    "यह मेरा इस प्रकार का उग्र रूप देखकर तुझे व्यथा नहीं होनी चाहिये और विमूढ़ भाव (भी) नहीं होना चाहिये। (अब) निर्भय (और) प्रसन्न मनवाला होकर तू फिर उसी मेरे इस (चतुर्भुज) रूप को अच्छी तरह देख ले। 11.49",

    "सञ्जय बोले – वासुदेव भगवान् ने अर्जुन से ऐसा कहकर फिर उसी प्रकार से अपना रूप (देवरूप) दिखाया और महात्मा श्रीकृष्ण ने पुनः सौम्य रूप (द्विभुज मानुष रूप) होकर इस भयभीत अर्जुन को आश्वासन दिया। 11.50",

    "अर्जुन बोले - हे जनार्दन! आपके इस सौम्य मनुष्य रूप को देखकर (मैं) इस समय स्थिरचित्त हो गया हूँ (और) अपनी स्वाभाविक स्थिति को प्राप्त हो गया हूँ। 11.51",

    "श्रीभगवान् बोले - मेरा यह जो (चतुर्भुज) रूप (तुमने) देखा है, इसके दर्शन अत्यन्त ही दुर्लभ हैं। देवता भी इस रूप को देखने के लिये नित्य लालायित रहते हैं। 11.52",

    "जिस प्रकार (तुमने) मुझे देखा है, इस प्रकार का (चतुर्भुज रूप वाला) मैं न तो वेदों से, न तप से, न दान से और न यज्ञ से ही देखा जा सकता हूँ। 11.53",

    "परन्तु हे शत्रु तापन अर्जुन! इस प्रकार (चतुर्भुज रूप वाला) मैं (केवल) अनन्य भक्ति से ही तत्त्व से जानने में और (साकार रूप से) देखने में और प्रवेश (प्राप्त) करने में शक्य हूँ। 11.54",

    "हे पाण्डव! जो मेरे लिये ही कर्म करने वाला, मेरे ही परायण (और) मेरा ही प्रेमी भक्त है (तथा) सर्वथा आसक्ति रहित (और) प्राणिमात्र के साथ वैर भाव से रहित है, वह भक्त मुझे प्राप्त होता है। 11.55",

    "इस प्रकार ॐ तत्  सत् - इन भगवन्नामों के उच्चारणपूर्वक ब्रह्मविद्या और योगशास्त्रमय श्रीमद्भगवद्गीतोपनिषदरूप श्रीकृष्णार्जुनसंवाद में ‘विश्वरूपदर्शनयोग’ नामक ग्यारहवाँ अध्याय पूर्ण हुआ।"    
  ],
  "adhyaya-12" : [
    "अर्जुन बोले - जो भक्त इस प्रकार (ग्यारवें अध्याय के पचपनवें श्लोक के अनुसार) निरन्तर आप में लगे रहकर आप (सगुण साकार) की उपासना करते हैं और जो अविनाशी निर्गुण निराकार की ही (उपासना करते हैं), उन दोनों में से उत्तम योगवेत्ता कौन हैं? 12.1",

    "श्रीभगवान् बोले - मुझ में मन को लगाकर नित्य-निरन्तर मुझ में लगे हुए जो भक्त परम श्रद्धा से युक्त होकर मेरी (सगुण साकार की) उपासना करते हैं, वे मेरे मत में सर्वश्रेष्ठ योगी हैं। 12.2",

    "और जो (अपने) इन्द्रिय समूह को वश में करके चिन्तन में न आने वाले, सब जगह परिपूर्ण, देखने में न आने वाले, निर्विकार, अचल, ध्रुव, अक्षर और अव्यक्त की तत्परता से उपासना करते हैं, वे प्राणिमात्र के हित में प्रीति रखन् वाले (और) सब जगह समबुद्धि वाले मनुष्य मुझे ही प्राप्त होते हैं। 12.3",

    "जो अपनी इन्द्रियों को वश में करके अचिन्त्य, सब जगह परिपूर्ण, अनिर्देश्य, कूटस्थ, अचल, ध्रुव, अक्षर और अव्यक्त की उपासना करते हैं, वे प्राणिमात्र के हित में रत और सब जगह समबुद्धि वाले मनुष्य मुझे ही प्राप्त होते हैं। 12.4",

    "अव्यक्त में आसक्त चित्त वाले उन साधकों को (अपने साधन में) कष्ट अधिक होता है; क्योंकि देहाभिमानियों के द्वारा अव्यक्त-विषयक गति कठिनता से प्राप्त की जाती है। 12.5",

    "परन्तु जो कर्मों को मेरे अर्पण करके (और) मेरे परायण होकर अनन्य योग (सम्बन्ध) से मेरा ही ध्यान करते हुए (मेरी) उपासना करते हैं। 12.6",

    "हे पार्थ ! मुझ में आविष्ट चित्त वाले उन भक्तों का मैं मृत्युरूप संसार-समुद्र से शीघ्र ही उद्धार करने वाला बन जाता हूँ। 12.7",

    "(तू) मुझ में मन को स्थापन कर (और) मुझ में ही बुद्धि को प्रविष्ट कर; इसके बाद (तू) मुझ में ही निवास करेगा (इसमें) संशय नहीं है। 12.8",

    "अगर (तू) मन को मुझ में अचल भाव से स्थिर (अर्पण) करने में अपने को समर्थ नहीं मानता, तो हे धनञ्जय ! अभ्यास योग के द्वारा (तू) मेरी प्राप्ति की इच्छा कर। 12.9",

    "(अगर तू) अभ्यास (योग) में भी (अपने को) असमर्थ (पाता) है, (तो) मेरे लिये कर्म करने के परायण हो जा। मेरे लिये कर्मों को करता हुआ भी (तू) सिद्धि को प्राप्त हो जायगा। 12.10",

    "अगर मेरे योग (समता) के आश्रित हुआ (तू) इस (पूर्व श्लोक में कहे गये साधन) को भी करने में (अपने को) असमर्थ (पाता) है, तो मन इन्द्रियों को वश में करके सम्पूर्ण कर्मों के फल की इच्छा का त्याग कर। 12.11",

    "अभ्यास से शास्त्रज्ञान श्रेष्ठ है, शास्त्रज्ञान से ध्यान श्रेष्ठ है (और) ध्यान से (भी) सब कर्मों के फल की इच्छा का त्याग (श्रेष्ठ है)। क्योंकि त्याग से तत्काल ही परम शान्ति प्राप्त हो जाती है। 12.12",

    "सब प्राणियों में द्वेषभाव से रहित और मित्र भाव वाला (तथा) दयालु भी (और) ममता रहित, अहंकार रहित, सुख दुःख की प्राप्ति में सम, क्षमाशील, निरन्तर सन्तुष्ट, योगी, शरीर को वश में किये हुए, दृढ़ निश्चयवाला, मुझ में अर्पित मन बुद्धि वाला जो मेरा भक्त है, वह मुझे प्रिय है। (12.13-12.14)",

    "सब प्राणियों में द्वेषभाव से रहित और मित्र भाव वाला (तथा) दयालु भी (और) ममता रहित, अहंकार रहित, सुख दुःख की प्राप्ति में सम, क्षमाशील, निरन्तर सन्तुष्ट, योगी, शरीर को वश में किये हुए, दृढ़ निश्चयवाला, मुझ में अर्पित मन बुद्धि वाला जो मेरा भक्त है, वह मुझे प्रिय है। (12.13-12.14)",

    "जिससे कोई भी प्राणी उद्विग्न (क्षुब्ध) नहीं होता और जो स्वयं भी किसी प्राणी से उद्विग्न नहीं होता तथा जो हर्ष, अमर्ष (ईर्ष्या), भय और उद्वेग (हलचल) से रहित है, वह मुझे प्रिय है। 12.15",

    "जो अपेक्षा (आवश्यकता) से रहित, (बाहर-भीतर से) पवित्र, चतुर, उदासीन, व्यथा से रहित (औरः सभी आरम्भों का अर्थात् नये-नये कर्मों के आरम्भ का सर्वथा त्यागी है, वह मेरा भक्त मुझे प्रिय है। 12.16",

    "जो न (कभी) हर्षित होता है, न द्वेष करता है, न शोक करता है, न कामना करता है (और) जो शुभ-अशुभ कर्मों से ऊँचा उठा हुआ (राग-द्वेष रहित) है, वह भक्तिमान् मनुष्य मुझे प्रिय है। 12.17",

    "(जो) शत्रु और मित्र में तथा मान-अपमान में सम है (और) शीत-उष्ण (शरीर की अनुकूलता-प्रतिकूलता) तथा सुख-दुःख (मन बुद्धि की अनुकूलता-प्रतिकूलता) में सम है एवं आसक्ति रहित है (और) जो निन्दा स्तुति को समान समझने वाला, मननशील, जिस किसी प्रकार से भी (शरीर का निर्वाह होने न होने में) संतुष्ट, रहने के स्थान तथा शरीर में ममता आसक्ति से रहित (और) स्थिर बुद्धिवाला है, (वह) भक्तिमान् मनुष्य मुझे प्रिय है। (12.18-12.19)",

    "(जो) शत्रु और मित्र में तथा मान-अपमान में सम है (और) शीत-उष्ण (शरीर की अनुकूलता-प्रतिकूलता) तथा सुख-दुःख (मन बुद्धि की अनुकूलता-प्रतिकूलता) में सम है एवं आसक्ति रहित है (और) जो निन्दा स्तुति को समान समझने वाला, मननशील, जिस किसी प्रकार से भी (शरीर का निर्वाह होने न होने में) संतुष्ट, रहने के स्थान तथा शरीर में ममता आसक्ति से रहित (और) स्थिर बुद्धिवाला है, (वह) भक्तिमान् मनुष्य मुझे प्रिय है। (12.18-12.19)",

    "परन्तु जो (मुझ में) श्रद्धा रखने वाले (और) मेरे परायण हुए भक्त इस धर्ममय अमृत का जैसा कहा कहा है, (वैसा ही) भली भांति सेवन करते हैं, वे मुझे अत्यन्त प्रिय हैं। 12.20",

    "इस प्रकार ॐ तत्  सत् - इन भगवन्नामों के उच्चारणपूर्वक ब्रह्मविद्या और योगशास्त्रमय श्रीमद्भगवद्गीतोपनिषदरूप श्रीकृष्णार्जुनसंवाद में ‘भक्तियोग’ नामक बारहवाँ अध्याय पूर्ण हुआ।"    
  ],
  "adhyaya-13" : [
    "श्रीभगवान् बोले - हे कुन्तीपुत्र अर्जुन ! 'यह' - रूप से कहे जाने वाले शरीर को 'क्षेत्र' - इस नाम से कहते हैं (और) इस क्षेत्र को जो जानता है, उसको ज्ञानी लोग 'क्षेत्रज्ञ' इस नाम से कहते हैं। 13.1",

    "हे भरतवंशोद्भव अर्जुन ! (तू) सम्पूर्ण क्षेत्रों में क्षेत्रज्ञ मुझे ही समझ और क्षेत्र-क्षेत्रज्ञ का जो ज्ञान है, वही मेरे मत में ज्ञान है। 13.2",

    "वह क्षेत्र जो है और जैसा है तथा जिन विकारों वाला है और जिससे जो (पैदा हुआ है) तथा वह क्षेत्रज्ञ (भी) जो है और जिस प्रभाव वाला है, वह सब संक्षेप में मुझ से सुन।  13.3",

    "यह क्षेत्र-क्षेत्रज्ञ का तत्त्व- ऋषियों के द्वारा बहुत विस्तार से कहा गया है (तथा) वेदों की ऋचाओं द्वारा बहुत प्रकार से विभागपूर्वक (कहा गया है) और युक्ति युक्त (एवं) निश्चित किये हुए ब्रह्मसूत्र के पदों द्वारा भी (कहा गया है)। 13.4",

    "मूल प्रकृति और समष्टि बुद्धि (महत्तत्त्व), समष्टि अहंकार, पाँच महाभूत और दस इन्द्रियाँ, एक मन तथा पाँचों इन्द्रियों के पाँच विषय - (यह चौबीस तत्त्वों वाला क्षेत्र है)। 13.5",

    "इच्छा, द्वेष, सुख, दुःख, संघात (शरीर), चेतना (प्राणशक्ति) (और) धृति - इन विकारों सहित यह क्षेत्र संक्षेप से कहा गया है। 13.6",

    "अपने में श्रेष्ठता का भाव न होना, दिखावटीपन न होना, अहिंसा, क्षमा, सरलता, गुरु की सेवा, बाहर-भीतर की शुद्धि, स्थिरता (और) मन का वश में होना। 13.7",

    "इन्द्रियों के विषयों में वैराग्य का होना, अहंकार का भी न होना और जन्म, मृत्यु, वृद्धावस्था तथा व्याधियों में दुःखरूप दोषों को बार-बार देखना। 13.8",

    "आसक्ति रहित होना, पुत्र, स्त्री, घर आदि में एकात्मता (घनिष्ठ सम्बन्ध) न होना और अनुकूलता-प्रतिकूलता की प्राप्ति में चित्त का नित्य सम रहना। 13.9",

    "मुझमें अनन्ययोग के द्वारा अव्यभिचारिणी भक्ति का होना, एकान्त स्थान में रहने का स्वभाव होना और जन-समुदाय में प्रीति का न होना। 13.10",

    "अध्यात्मज्ञान में नित्य-निरन्तर रहना, तत्त्वज्ञान के अर्थरूप परमात्मा को सब जगह देखना - यह (पूर्वोक्त बीस साधन-समुदाय) तो ज्ञान है (और) जो इसके विपरीत है वह अज्ञान है - ऐसा कहा गया है। 13.11",

    "जो ज्ञेय (पूर्वोक्त ज्ञान से जानने योग्य) है, उस (परमात्मतत्त्व) को मैं अच्छी तरह से कहूँगा, जिसको जानकर मनुष्य (अमरता) का अनुभव कर लेता है। वह (ज्ञेय-तत्त्व) अनादिवाला (और) परम ब्रह्म है। उसको न सत् कहा जा सकता है (और) न असत् ही (कहा जा सकता है)। 13.12",

    "वे (परमात्मा) सब जगह हाथों और पैरों वाले, सब जगह नेत्रों, सिरों और मुखों वाले (तथा) सब जगह कानों वाले हैं। (वे) संसार में सबको व्याप्त करके स्थित हैं। 13.13",

    "वे (परमात्मा) सम्पूर्ण इन्द्रियों से रहित हैं (और) सम्पूर्ण इन्द्रियों के विषयों को प्रकाशित करने वाले हैं; आसक्ति रहित हैं और सम्पूर्ण संसार का भरण-पोषण करने वाले हैं; तथा गुणों से रहित हैं (और) सम्पूर्ण गुणों के भोक्ता हैं। 13.14",

    "वे (परमात्मा) सम्पूर्ण प्राणियों के बाहर-भीतर (परिपूर्ण हैं) और चर-अचर (प्राणियों के रूप में) भी (वे ही हैं) एवं दूर-से-दूर तथा नजदीक-से-नजदीक भी (वे ही हैं) वे अत्यन्त सूक्ष्म होने से जानने में नहीं आते । 13.15",

    "वे (परमात्मा) (स्वयं) विभागरहित होते हुए भी सम्पूर्ण प्राणियों में विभक्त की तरह स्थित हैं और (वे) जानने योग्य (परमात्मा ही) सम्पूर्ण प्राणियों को उत्पन्न करनेवाले तथा उनका भरण-पोषण करनेवाले और संहार करनेवाले हैं। 13.16",

    "वे (परमात्मा) सम्पूर्ण ज्योतियों के भी ज्योति (और) अज्ञान से अत्यन्त परे कहे गये हैं। (वे) ज्ञान स्वरूप, जानने योग्य, ज्ञान से प्राप्त करने योग्य (और) सबके हृदय में विराजमान है। 13.17",

    "इस प्रकार क्षेत्र तथा ज्ञान और ज्ञेय को संक्षेप से कहा गया है। मेरा भक्त इसको तत्त्व से जानकर मेरे भाव को प्राप्त हो जाता है।  13.18",

    "प्रकृति और पुरुष - दोनों को ही (तुम) अनादि समझो और विकारों को तथा गुणों को भी प्रकृति से ही उत्पन्न समझो। कार्य और करण के द्वारा होने वाली क्रियाओं को उत्पन्न करने में प्रकृति हेतु कही जाती है (और) सुख-दुःखों के भोक्तापन में पुरुष हेतु कहा जाता है। (13.19-13.20)",

    "प्रकृति और पुरुष - दोनों को ही (तुम) अनादि समझो और विकारों को तथा गुणों को भी प्रकृति से ही उत्पन्न समझो। कार्य और करण के द्वारा होने वाली क्रियाओं को उत्पन्न करने में प्रकृति हेतु कही जाती है (और) सुख-दुःखों के भोक्तापन में पुरुष हेतु कहा जाता है। (13.19-13.20)",

    "प्रकृति में स्थित पुरुष (जीव) ही प्रकृतिजन्य गुणों का भोक्ता बनता है (और) गुणों का संग (ही) उसके ऊँच-नीच योनियों में जन्म लेने का कारण बनता है। 13.21",

    "यह पुरूष (शरीर के साथ सम्बन्ध रखने से) 'उपद्रष्टा', (उसके साथ मिलकर सम्मति, अनुमति देने से) 'अनुमन्ता', (अपने को उसका भरण- पोषण करने वाला मानने से) 'भर्ता', (उसके संग से सुख दुःख भोगने से) 'भोक्ता', और (अपने को उसका स्वामी मानने से) 'महेश्वर' (बन जाता है)। परन्तु (स्वरूप से यह) पुरुष 'परमात्मा' कहा जाता है। (यह) इस (देह में) रहता हुआ भी (देह से) पर (सर्वथा सम्बन्ध-रहित) ही है। 13.22",

    "इस प्रकार पुरुष को और गुणों के सहित प्रकृति को जो मनुष्य (अलग-अलग) जानता है, वह सब तरह का बर्ताव करता हुआ भी फिर जन्म नहीं लेता। 13.23",

    "कई मनुष्य ध्यानयोग के द्वारा, कई सांख्य योग के द्वारा और कई कर्मयोग के द्वारा अपने-आप से अपने-आप में परमात्म तत्त्व का अनुभव करते हैं। 13.24",

    "दूसरे मनुष्य इस प्रकार (ध्यानयोग, सांख्ययोग, कर्मयोग, आदि साधनों को) नहीं जानते, पर दूसरों से (जीवन्मुक्त महापुरुषों से) सुनकर उपासना करते हैं, ऐसे वे सुनने के अनुसार आचरण करने वाले मनुष्य भी मृत्यु को तर जाते हैं। 13.25",

    "हे भरतवंशियों में श्रेष्ठ अर्जुन! स्थावर और जंगम जितने भी प्राणी पैदा होते हैं, उनको (तुम) क्षेत्र और क्षेत्रज्ञ के संयोग से (उत्पन्न हुए) समझो। 13.26",

    "जो नष्ट होते हुए सम्पूर्ण प्राणियों में परमेश्वर को नाश रहित (और) समरूप से स्थित देखता है, वही (वास्तव में सही) देखता है। 13.27",

    "क्योंकि सब जगह समरूप से स्थित ईश्वर को समरूप से देखने वाला मनुष्य अपने-आप से अपनी हिंसा नहीं करता, इसलिये (वह) परमगति को प्राप्त हो जाता है। 13.28",

    "जो सम्पूर्ण क्रियाओं को सब प्रकार से प्रकृति के द्वारा ही की जाती हुई देखता है और अपने आपको अकर्ता देखता (अनुभव करता) है, वही (यथार्थ) देखता है। 13.29",

    "जिस काल में (साधक) प्राणियों के अलग-अलग भावों को एक प्रकृति में ही स्थित देखता है और उस प्रकृति से ही (उन सबका) विस्तार (देखता है), उस काल में (वह) ब्रह्म को प्राप्त हो जाता है। 13.30",

    "हे कुन्तीनन्दन ! यह (पुरुष स्वयं) अनादि होने से (और) गुणों से रहित होने से अविनाशी परमात्म स्वरूप ही है। यह शरीर में रहता हुआ भी न करता है (और) न लिप्त होता है। 13.31",

    "जैसे सब जगह व्याप्त आकाश अत्यन्त सूक्ष्म होने से (कहीं भी) लिप्त नहीं होता, ऐसे ही सब जगह परिपूर्ण आत्मा (किसी भी) देह में लिप्त नहीं होता। 13.32",

    "हे भरतवंशोद्भव अर्जुन ! जैसे एक ही सूर्य सम्पूर्ण संसार को प्रकाशित करता है, ऐसे ही क्षेत्रज्ञ (आत्मा) सम्पूर्ण क्षेत्र को प्रकाशित करता है। 13.33",

    "इस प्रकार जो ज्ञानरूपी नेत्रों से क्षेत्र और क्षेत्रज्ञ के विभाग को तथा कार्य-कारण सहित प्रकृति से स्वयं को अलग जानते हैं, वे परमात्मा को प्राप्त हो जाते हैं। 13.34",

    "इस प्रकार ॐ तत्  सत् - इन भगवन्नामों के उच्चारणपूर्वक ब्रह्मविद्या और योगशास्त्रमय श्रीमद्भगवद्गीतोपनिषदरूप श्रीकृष्णार्जुनसंवाद में ‘क्षेत्रक्षेत्रज्ञविभागयोग’ नामक तेरहवाँ अध्याय पूर्ण हुआ।"    
  ],
  "adhyaya-14" : [
    "श्रीभगवान बोले – सम्पूर्ण ज्ञानों में उत्तम (और) श्रेष्ठ ज्ञान को मैं फिर कहूँगा, जिसको जानकर सब के सब मुनि लोग इस संसार से (मुक्त होकर) परमसिद्धि को प्राप्त हो गये हैं। 14.1",

    "इस ज्ञान का आश्रय लेकर (जो मनुष्य) मेरी सधर्मता को प्राप्त हो गये हैं, (वे) महासर्ग में भी पैदा नहीं होते और महाप्रलय में भी व्यथित नहीं होते। 14.2",

    "हे भरतवंशोद्भव अर्जुन! मेरी मूल प्रकृति तो उत्पत्ति स्थान है (और) मैं उसमें जीवरूप गर्भ का स्थापन करता हूँ। उससे सम्पूर्ण प्राणियों की उत्पत्ति होती है। 14.3",

    "हे कुन्तीनन्दन ! सम्पूर्ण योनियों में प्राणियों के जितने शरीर पैदा होते हैं, उन सबकी मूल प्रकृति तो माता है और मैं बीज-स्थापन करने वाला पिता हूँ। 14.4",

    "हे महाबाहो! प्रकृति से उत्पन्न होने वाले सत्त्व, रज (और) तम - ये (तीनों) गुण अविनाशी देही (जीवात्मा) को देह में बाँध देते हैं। 14.5",

    "हे पाप रहित अर्जुन! उन गुणों में सत्त्वगुण निर्मल (स्वच्छ) होने के कारण प्रकाशक (और) निर्विकार है। (वह) सुख की आसक्ति से और ज्ञान की आसक्ति से (देही को) बाँधता है। 14.6",

    "हे कुन्तीनन्दन! तृष्णा और आसक्ति को पैदा करने वाले रजोगुण को (तुम) रागस्वरूप समझो। वह कर्मों की आसक्ति से देही जीवात्मा को बाँधता है। 14.7",

    "हे भरतवंशी अर्जुन ! सम्पूर्ण देहधारियों को मोहित करने वाले तमोगुण को तुम अज्ञान से उत्पन्न होने वाला समझो। वह प्रमाद, आलस्य और निद्रा के द्वारा देहधारियों को बाँधता है। 14.8",

    "हे भरतवंशोद्भव अर्जुन! सत्त्वगुण सुख में (और) रजोगुण कर्म में लगाकर (मनुष्य पर) विजय करता है। परन्तु तमोगुण ज्ञान को ढककर एवं प्रमाद में लगाकर (मनुष्य पर) विजय करता है। 14.9",

    "हे भरतवंशोद्भव अर्जुन! रजोगुण और तमोगुण को दबाकर सत्त्व गुण बढ़ता है, सत्त्व गुण और तमोगुण को दबाकर रजोगुण (बढ़ता है) वैसे ही सत्त्वगुण (और) रजोगुण को दबाकर तमोगुण (बढ़ता है)। 14.10",

    "जब इस मनुष्य-शरीर में सब द्वारों (इन्द्रियों और अन्तःकरण) में प्रकाश (स्वच्छता) और विवेक प्रकट हो जाता है, तब यह जानना चाहिये (कि) सत्त्वगुण बढ़ा हुआ है। 14.11",

    "हे भरतवंशमें श्रेष्ठ अर्जुन ! रजोगुण के बढ़ने पर लोभ, प्रवृत्ति, कर्मोंका आरम्भ, अशान्ति और स्पृहा -- ये वृत्तियाँ पैदा होती हैं। 14.12",

    "हे कुरुनन्दन! तमोगुण के बढ़ने पर अप्रकाश, अप्रवृत्ति तथा प्रमाद और मोह – ये वृत्तियाँ भी पैदा होती हैं। 14.13",

    "जिस समय सत्त्वगुण बढ़ा हो, उस समय यदि देहधारी मनुष्य मर जाता है (तो वह) उत्तमवेत्ताओं के निर्मल लोकों में जाता है। 14.14",

    "रजोगुण के बढ़ने पर मरने वाला प्राणी कर्मसंगी मनुष्य योनि में जन्म लेता है तथा तमोगुण के बढ़ने पर मरने वाला मूढ़ योनियों में जन्म लेता है। 14.15",

    "विवेकी पुरुषों ने – शुभ कर्म का तो सात्त्विक निर्मल फल कहा है, राजस कर्म का फल दुःख (कहा है और) तामस कर्म का फल अज्ञान (मूढ़ता) कहा है। 14.16",

    "सत्त्वगुण से ज्ञान और रजोगुण से लोभ (आदि) ही उत्पन्न होते हैं; तमोगुण से प्रमाद, मोह एवं अज्ञान भी उत्पन्न होते हैं। 14.17",

    "सत्त्वगुण में स्थित मनुष्य ऊर्ध्वलोकों में जाते हैं, रजोगुण में स्थित मनुष्य मृत्युलोक में जन्म लेते हैं (और) निन्दनीय तमोगुण की वृत्ति में स्थित तामस मनुष्य अधोगति में जाते हैं। 14.18",

    "जब विवेकी (विचार कुशल) मनुष्य तीनों गुणों के (सिवाय) अन्य किसी को कर्ता नहीं देखता और (अपने को) गुणों से पर अनुभव करता है, (तब) वह मेरे सत्स्वरूप को प्राप्त हो जाता है। 14.19",

    "देहधारी (विवेकी मनुष्य) देह को उत्पन्न करने वाले इन तीनों गुणों का अतिक्रमण करके जन्म, मृत्यु और वृद्धावस्था रूप दुःखों से रहित हुआ अमरता का अनुभव करता है। 14.20",

    "अर्जुन बोले - हे प्रभो! इन तीनों गुणों से अतीत हुआ मनुष्य किन लक्षणों से (युक्त) होता है? उसके आचरण कैसे होते हैं? और इन तीनों गुणों का अतिक्रमण कैसे किया जा सकता है? 14.21",

    "श्री भगवान बोले - हे पाण्डव! प्रकाश और प्रवृति तथा मोह – (ये सभी) अच्छी तरह से प्रवृत्त हो जायँ तो भी (गुणातीत मनुष्य) इनसे द्वेष नहीं करता और (ये सभी) निवृत्त हो जायँ तो (इनकी) इच्छा नहीं करता। 14.22",

    "जो उदासीन की तरह स्थित है (और) (जो) गुणों के द्वारा विचलित नहीं किया जा सकता (तथा) गुण ही (गुणों में) बरत रहे हैं - इस भाव से जो (अपने स्वरूप में ही) स्थित रहता है (और स्वयं कोई भी) चेष्टा नहीं करता। 14.23",

    "जो धीर मनुष्य सुख-दुःख में सम (तथा) अपने स्वरूप में स्थित रहता है; जो मिट्टी के ढेले, पत्थर और सोने में सम रहता है, जो प्रिय-अप्रिय में सम रहता है। जो अपनी निन्दा-स्तुति में सम रहता है; जो मान-अपमान में सम रहता है; जो मित्र-शत्रु के पक्ष में सम रहता है (और) जो सम्पूर्ण कर्मों के आरम्भ का त्यागी है, वह मनुष्य गुणातीत कहा जाता है। (14.24-14.25)",

    "जो धीर मनुष्य सुख-दुःख में सम (तथा) अपने स्वरूप में स्थित रहता है; जो मिट्टी के ढेले, पत्थर और सोने में सम रहता है, जो प्रिय-अप्रिय में सम रहता है। जो अपनी निन्दा-स्तुति में सम रहता है; जो मान-अपमान में सम रहता है; जो मित्र-शत्रु के पक्ष में सम रहता है (और) जो सम्पूर्ण कर्मों के आरम्भ का त्यागी है, वह मनुष्य गुणातीत कहा जाता है। (14.24-14.25)",

    "और जो मनुष्य अव्यभिचारी भक्तियोग के द्वारा मेरा सेवन करता है, वह इन गुणों का अतिक्रमण करके ब्रह्म प्राप्ति का पात्र हो जाता है। 14.26",

    "क्योंकि ब्रह्म का और अविनाशी अमृत का तथा शाश्वत धर्म का और ऐकान्तिक सुख का आश्रय मैं (ही हूँ)। 14.27",

    "इस प्रकार ॐ तत्  सत् - इन भगवन्नामों के उच्चारणपूर्वक ब्रह्मविद्या और योगशास्त्रमय श्रीमद्भगवद्गीतोपनिषदरूप श्रीकृष्णार्जुनसंवाद में ‘गुणत्रयविभागयोग’ नामक चौदहवाँ अध्याय पूर्ण हुआ।"    
  ],
  "adhyaya-15" : [
    "श्रीभगवान् बोले – ऊपर की  ओर मूल वाले (तथा) नीचे की ओर शाखा वाले (जिस) संसार रूप अश्वत्थ वृक्ष को (प्रवाह रूप से) अव्यय कहते हैं (और) वेद जिसके पत्ते हैं, उस संसार-वृक्ष को जो जानता है, वह सम्पूर्ण वेदों को जानने वाला है। 15.1",

    "उस संसार वृक्ष की गुणों (सत्त्व, रज और तम) के द्वारा बढ़ी हुई (तथा) विषय रूप कोंपलों वाली शाखाएँ नीचे, (मध्य में) और ऊपर (सब जगह) फैली हुई हैं। मनुष्यलोक में कर्मों के अनुसार बाँधने वाले मूल (भी) नीचे और (ऊपर) (सभी लोकों में) व्याप्त हो रहे हैं। 15.2",

    "इस संसार वृक्ष का (जैसा) रूप (देखने में आता है), वैसा यहाँ (विचार करने पर) मिलता नहीं; (क्योंकि इसका) न तो आदि है, न अन्त है और न स्थिति ही है। इसलिये इस दृढ़ मूलों वाले संसार रूप अश्वत्थ वृक्ष को दृढ़ असङ्गता रूप शस्त्र के द्वारा काटकर -  15.3",

    "उसके बाद उस परमपद (परमात्मा) की खोज करनी चाहिये जिसको प्राप्त होने पर मनुष्य फिर लौटकर संसार में नहीं आते और जिससे अनादिकाल से चली आने वाली (यह) सृष्टि विस्तार को प्राप्त हुई है, उस आदिपुरुष परमात्मा के ही मैं शरण हूँ। 15.4",

    "जो मान और मोह से रहित हो गये हैं, जिन्होंने आसक्ति से होने वाले दोषों को जीत लिया है, जो नित्य-निरन्तर परमात्मा में ही लगे हुए हैं, जो (अपनी दृष्टि से) सम्पूर्ण कामनाओं से रहित हो गये हैं, जो सुख-दुःख नाम वाले द्वन्द्वों से मुक्त हो गये हैं, (ऐसे) (ऊँची स्थिति वाले) मोह रहित साधक भक्त उस अविनाशी परमपद (परमात्मा) को प्राप्त होते हैं। 15.5",

    "उस (परमपद) को न सूर्य, न चन्द्र (और) न अग्नि ही प्रकाशित कर सकती है (और) (जिसको) प्राप्त होकर जीव लौट कर (संसार में) नहीं आते, वही मेरा परम धाम है। 15.6",

    "इस संसार में जीव बना हुआ आत्मा (स्वयं) मेरा ही सनातन अंश है; (परन्तु वह) प्रकृति में स्थित मन और पाँचों इन्द्रियों को आकर्षित करता है (अपना मान लेता है)। 15.7",

    "जैसे वायु गन्ध के स्थान से गन्ध को (ग्रहण करके ले जाती है), ऐसे ही शरीरादि का स्वामी बना हुआ जीवात्मा भी जिस शरीर को छोड़ता है, (वहाँ से) इन (मन सहित इन्द्रियों) को ग्रहण करके फिर जिस (शरीर) को प्राप्त होता है, (उसमें) चला जाता है। 15.8",

    "यह (जीवात्मा) मन का आश्रय लेकर ही श्रोत्र और नेत्र तथा त्वचा, रसना और घ्राण –(इन पाँचों इन्द्रियों के द्वारा) विषयों का सेवन करता है। 15.9",

    "शरीर को छोड़कर जाते हुए या दूसरे शरीर में स्थित हुए अथवा विषयों को भोगते हुए भी गुणों से युक्त (जीवात्मा के स्वरूप) को मूढ़ मनुष्य नहीं जानते, ज्ञानरूपी नेत्रोंवाले (ज्ञानी मनुष्य ही) जानते हैं 15.10",

    "यत्न करने वाले योगी लोग अपने आप में स्थित इस परमात्म तत्त्व का अनुभव करते हैं। परन्तु जिन्होंने अपना अन्तःकरण शुद्ध नहीं किया है, (ऐसे) अविवेकी मनुष्य यत्न करने पर भी इस तत्त्व का अनुभव नहीं करते। 15.11",

    "सूर्य को प्राप्त हुआ जो तेज सम्पूर्ण जगत् को प्रकाशित करता है (और) जो तेज चन्द्रमा में है तथा जो तेज अग्नि में है, उस तेज को मेरा ही जान। 15.12",

    "मैं ही पृथ्वी में प्रविष्ट होकर अपनी शक्ति से समस्त प्राणियों को धारण करता हूँ और (मैं ही) रस स्वरूप चन्द्रमा होकर समस्त ओषधियों (वनस्पतियों) को पुष्ट करता हूँ। 15.13",

    "प्राणियों के शरीर में रहने वाला मैं प्राण-अपान से युक्त वैश्वानर (जठराग्नि) होकर चार प्रकार के अन्न को पचाता हूँ। 15.14",

    "मैं ही सम्पूर्ण प्राणियों के हृदय में स्थित हूँ तथा मुझसे (ही) स्मृति, ज्ञान और अपोहन (संशय आदि दोषों का नाश) होता है। सम्पूर्ण वेदों के द्वारा मैं ही जानने योग्य हूँ। वेदों के तत्त्व का निर्णय करने वाला और वेदों को जानने वाला भी मैं (ही हूँ)। 15.15",

    "इस संसार में क्षर (नाशवान्) और अक्षर (अविनाशी) - ये दो प्रकार के ही पुरुष हैं। सम्पूर्ण प्राणियों के शरीर क्षर और जीवात्मा अक्षर कहा जाता है। 15.16",

    "उत्तम पुरुष तो अन्य (विलक्षण) ही है, जो ‘परमात्मा’– इस नाम से कहा गया है। (वही) अविनाशी ईश्वर तीनों लोकों में प्रविष्ट होकर (सबका) भरण-पोषण करता है। 15.17",

    "कारण कि मैं क्षर से अतीत हूँ और अक्षर से भी उत्तम हूँ, इसलिये लोक में और वेद में ‘पुरुषोत्तम’ (नाम से) प्रसिद्ध हूँ। 15.18",

    "हे भरतवंशी अर्जुन ! इस प्रकार जो मोह रहित मनुष्य मुझे पुरुषोत्तम जानता है, वह सर्वज्ञ सब प्रकार से मेरा ही भजन करता है। 15.19",

    "हे निष्पाप अर्जुन ! इस प्रकार यह अत्यन्त गोपनीय शास्त्र मेरे द्वारा कहा गया है। हे भरतवंशी अर्जुन ! इसको जानकर (मनुष्य) ज्ञानवान् (ज्ञात-ज्ञातव्य) (तथा प्राप्त-प्राप्तव्य) और कृतकृत्य हो जाता है 15.20",

    "इस प्रकार ॐ तत्  सत् - इन भगवन्नामों के उच्चारणपूर्वक ब्रह्मविद्या और योगशास्त्रमय श्रीमद्भगवद्गीतोपनिषदरूप श्रीकृष्णार्जुनसंवाद में ‘पुरूषोत्तमयोग’ नामक पन्द्रहवाँ अध्याय पूर्ण हुआ।"    
  ],
  "adhyaya-16" : [
    "श्रीभगवान बोले – भय का सर्वथा अभाव; अन्तःकरण की अत्यंत शुद्धि; ज्ञान के लिये योग में दृढ़ स्थिति; सात्त्विक दान; इन्द्रियों का दमन; यज्ञ; स्वाध्याय; कर्तव्य-पालन के लिये कष्ट सहना और शरीर-मन-वाणी की सरलता। 16.1",

    "अहिंसा, सत्य भाषण, क्रोध न करना, संसार की कामना का त्याग, अन्तःकरण में राग-द्वेष जनित हलचल का न होना, चुगली न करना, प्राणियों पर दया करना, सांसारिक विषयों में न ललचाना, अन्तःकरण की कोमलता, अकर्तव्य करने में लज्जा, चपलता का अभाव। 16.2",

    "तेज (प्रभाव), क्षमा, धैर्य, शरीर की शुद्धि, वैर भाव का न होना (और) मान को न चाहना, हे भरतवंशी अर्जुन ! (ये सभी) दैवी सम्पदा को प्राप्त हुए मनुष्य के (लक्षण) हैं। 16.3",

    "हे पृथानन्दन ! दम्भ करना, घमण्ड करना और अभिमान करना, क्रोध करना तथा कठोरता रखना और अविवेक का होना भी - (ये सभी) आसुरी सम्पदा को प्राप्त हुए मनुष्य के (लक्षण) हैं। 16.4",

    "दैवी सम्पत्ति मुक्ति के लिये (और) आसुरी सम्पत्ति बन्धन के लिये मानी गयी है। हे पाण्डव! (तुम) दैवी सम्पत्ति को प्राप्त हुए हो, (इसलिये तुम) शोक (चिन्ता) मत करो। 16.5",

    "इस लोक में दो तरह के ही प्राणियों की सृष्टि है -- दैवी और आसुरी। दैवी को तो (मैंने) विस्तार से कह दिया, (अब) हे पार्थ! (तुम) मुझसे आसुरी को (विस्तार) से सुनो। 16.6",

    "आसुरी प्रकृति वाले मनुष्य किस में प्रवृत होना चाहिये और किससे निवृत्त होना चाहिये (इसको) नहीं जानते और उनमें न तो बाह्य शुद्धि, न श्रेष्ठ आचरण तथा न सत्य-पालन ही होता है। 16.7",

    "वे कहा करते हैं कि संसार असत्य, बिना मर्यादा के (और) बिना ईश्वर के अपने-आप केवल स्त्री-पुरुष के संयोग से पैदा हुआ है। (इसलिये) काम ही इसका कारण है, इसके सिवाय और क्या कारण है? (और कोई कारण हो ही नहीं सकता।) 16.8",

    "इस (पूर्वोक्त) (नास्तिक) दृष्टि का आश्रय लेने वाले जो मनुष्य अपने नित्य स्वरूप को नहीं मानते, जिनकी बुद्धि तुच्छ है, जो उग्र कर्म करने वाले (और) संसार के शत्रु हैं, उन मनुष्यों की सामर्थ्य का उपयोग जगत का नाश करने के लिये ही होता है। 16.9",

    "कभी पूरी न होने वाली कामनाओं का आश्रय लेकर दम्भ, अभिमान और मद में चूर रहने वाले (तथा) अपवित्र व्रत धारण करने वाले मनुष्य मोह के कारण दुराग्रहों को धारण करके (संसार में) विचरते रहते हैं। 16.10",

    "(वे) मृत्यु पर्यन्त रहने वाली अपार चिन्ताओं का आश्रय लेने वाले, पदार्थों का संग्रह और उनका भोग करने में ही लगे रहने वाले और 'जो कुछ है, वह इतना ही है' - ऐसा निश्चय करने वाले होते हैं। 16.11",

    "(वे) आशा की सैकड़ों फाँसियों से बँधे हुए मनुष्य काम-क्रोध के परायण होकर पदार्थों का भोग करने के लिये अन्याय पूर्वक धन-संचय करने की चेष्टा करते रहते हैं। 16.12",

    "वे इस प्रकार के मनोरथ किया करते हैं कि - इतनी वस्तुएँ तो हमने आज प्राप्त कर लीं (और अब) इस मनोरथ को प्राप्त (पूरा) कर लेंगे। इतना धन तो हमारे पास है ही, इतना (धन) फिर भी हो जायगा। 16.13",

    "वह शत्रु तो हमारे द्वारा मारा गया और (उन) दूसरे शत्रुओं को भी (हम) मार डालेंगे। हम ईश्वर (सर्व समर्थ) हैं। हम भोग भोगने वाले हैं।हम सिद्ध हैं, (हम) बड़े बलवान (और) सुखी हैं। 16.14",

    "हम धनवान हैं, बहुत से मनुष्य हमारे पास हैं, हमारे समान दूसरा कौन है? (हम) खूब यज्ञ करेंगे, दान देंगे (और) मौज करेंगे - इस तरह (वे) अज्ञान से मोहित रहते हैं।  16.15",

    "(कामनाओं के कारण) तरह-तरह से भ्रमित चित्त वाले, मोह-जाल में अच्छी तरह से फँसे हुए (तथा) पदार्थों और भोगों में अत्यन्त आसक्त रहने वाले मनुष्य भयंकर नरकों में गिरते हैं। 16.16",

    "अपने को सबसे अधिक पूज्य मानने वाले, अकड़ रखने वाले (तथा) धन और मान के मद में चूर रहने वाले वे मनुष्य दम्भ से अविधिपूर्वक नाममात्र के यज्ञों से यजन करते हैं। 16.17",

    "(वे) अहंकार, हठ, घमण्ड, कामना और क्रोध का आश्रय लेने वाले मनुष्य अपने और दूसरों के शरीर में (रहने वाले) मुझ अन्तर्यामी के साथ द्वेष करते हैं (तथा) (मेरे और दूसरों के गुणों में) दोष दृष्टि रखते हैं। 16.18",

    "उन द्वेष करने वाले, क्रूर स्वभाव वाले (और) संसार में महानीच, अपवित्र मनुष्यों को मैं बार-बार आसुरी योनियों में ही गिराता ही रहता हूँ। 16.19",

    "हे कुन्तीनन्दन ! (वे) मूढ मनुष्य मुझे प्राप्त न करके ही जन्म-जन्मान्तर में आसुरी योनि को प्राप्त होते हैं, (फिर) उससे भी अधिक अधम गति में अर्थात् भयंकर नरकों में चले जाते हैं। 16.20",

    "काम, क्रोध और लोभ - ये तीन प्रकार के नरक के दरवाजे जीवात्मा का पतन करने वाले हैं, इसलिये इन तीनों का त्याग कर देना चाहिये। 16.21",

    "हे कुन्तीनन्दन ! इन नरक के तीनों दरवाजों से रहित हुआ (जो) मनुष्य अपने कल्याण का आचरण करता है, (वह) उससे परम गति को प्राप्त हो जाता है। 16.22",

    "जो मनुष्य शास्त्रविधि को छोड़कर अपनी इच्छा से मनमाना आचरण करता है, वह न सिद्धि (अन्तःकरण की शुद्धि) को, न सुख (शान्ति) को (और) न परमगति को (ही) प्राप्त होता है। 16.23",

    "अतः तेरे लिये कर्तव्य-अकर्तव्य की व्यवस्था में शास्त्र (ही) प्रमाण है - (ऐसा) जानकर (तू) इस लोक में शास्त्रविधि से नियत कर्तव्य-कर्म करने योग्य है अर्थात् तुझे शास्त्रविधि के अनुसार कर्तव्य-कर्म करने चाहिये। 16.24",

    "इस प्रकार ॐ तत्  सत् - इन भगवन्नामों के उच्चारणपूर्वक ब्रह्मविद्या और योगशास्त्रमय श्रीमद्भगवद्गीतोपनिषदरूप श्रीकृष्णार्जुनसंवाद में ‘देवासुरसम्पदविभाग योग’ नामक सोलहवाँ अध्याय पूर्ण हुआ।"     
  ],
  "adhyaya-17" : [
    "अर्जुन बोले - हे कृष्ण ! जो मनुष्य शास्त्र-विधि का त्याग करके श्रद्धापूर्वक (देवता आदि का) पूजन करते हैं, उनकी निष्ठा फिर कौन-सी है? सात्त्विकी है अथवा राजसी-तामसी 17.1",

    "श्री भगवान बोले  - मनुष्यों की वह स्वभाव से उत्पन्न हुई श्रद्धा सात्त्विकी तथा राजसी और तामसी - ऐसे तीन तरह की ही होती है, उसको (तुम) मुझसे सुनो। 17.2",

    "हे भारत ! सभी मनुष्यों की श्रद्धा अन्तःकरण के अनुरूप होती है। यह मनुष्य श्रद्धामय है। (इसलिये) जो जैसी श्रद्धावाला है, वही उसका स्वरूप है अर्थात् वही उसकी निष्ठा (स्थिति) है। 17.3",

    "सात्त्विक मनुष्य देवताओं का पूजन करते हैं, राजस मनुष्य यक्षों और राक्षसों का और दूसरे (जो) तामस मनुष्य हैं, (वे) प्रेतों (और) भूतगणों का पूजन करते हैं। 17.4",

    "जो मनुष्य शास्त्रविधि से रहित घोर तप करते हैं; (जो) दम्भ और अहंकार से अच्छी तरह युक्त हैं; (जो) भोग- पदार्थ, आसक्ति और हठ से युक्त हैं; (जो) शरीर में स्थित पाँच भूतों को अर्थात् पांच भौतिक शरीर को तथा अन्तःकरण में स्थित मुझ परमात्मा को भी कृश करने वाले हैं उन अज्ञानियों को (तू) आसुर निष्ठा वाले (आसुरी सम्पदा वाले) समझ। ( 17.5-17.6)",

    "जो मनुष्य शास्त्रविधि से रहित घोर तप करते हैं; (जो) दम्भ और अहंकार से अच्छी तरह युक्त हैं; (जो) भोग- पदार्थ, आसक्ति और हठ से युक्त हैं; (जो) शरीर में स्थित पाँच भूतों को अर्थात् पांच भौतिक शरीर को तथा अन्तःकरण में स्थित मुझ परमात्मा को भी कृश करने वाले हैं उन अज्ञानियों को (तू) आसुर निष्ठा वाले (आसुरी सम्पदा वाले) समझ। ( 17.5-17.6)",

    "आहार भी सबको तीन प्रकार का प्रिय होता है और वैसे ही यज्ञ, तप (और) दान (भी तीन प्रकार के होते हैं अर्थात् शास्त्रीय कर्मों में भी गुणों को लेकर तीन प्रकार की रुचि होती है,) (तू) उनके इस भेद को सुन। 17.7",

    "आयु, सत्त्वगुण, बल, आरोग्य, सुख और प्रसन्नता बढ़ाने वाले, स्थिर रहने वाले, हृदय को शक्ति देने वाले, रसयुक्त (तथा) चिकने - (ऐसे) आहार अर्थात् भोजन करने के पदार्थ सात्त्विक मनुष्य को प्रिय होते हैं। 17.8",

    "अति कड़वे, अति खट्टे, अति नमकीन, अति गरम, अति तीखे, अति रूखे और अति दाह कारक आहार अर्थात् भोजन के पदार्थ राजस मनुष्य को प्रिय होते हैं, (जो कि) दुःख, शोक और रोगों को देने वाले हैं। 17.9",

    "जो भोजन सड़ा हुआ, रस रहित, दुर्गन्धित, बासी और जूठा है तथा (जो) महान अपवित्र (मांस आदि) भी है, (वह) तामस मनुष्य को प्रिय होता है। 17.10",

    "यज्ञ करना ही कर्तव्य है - इस तरह मन को समाधान (संतुष्ट) करके फलेच्छा रहित मनुष्यों द्वारा जो शास्त्रविधि से नियत यज्ञ किया जाता है, वह सात्त्विक है। 17.11",

    "परन्तु हे भरतश्रेष्ठ अर्जुन ! जो यज्ञ फल की इच्छा को लेकर अथवा दम्भ (दिखावटीपन) के लिये भी (किया जाता है), उस यज्ञ को (तुम) राजस समझो। 17.12",

    "शास्त्र विधि से हीन, अन्न-दान से रहित, बिना मन्त्रों के, बिना दक्षिणा के (और) बिना श्रद्धा के किये जाने वाले यज्ञ को तामस यज्ञ कहते हैं। 17.13",

    "देवता, ब्राह्मण, गुरुजन और जीवन्मुक्त महापुरुष का यथायोग्य पूजन करना, शुद्धि रखना, सरलता, ब्रह्मचर्य का पालन करना और हिंसा न करना - (यह) शरीर-सम्बन्धी तप कहा जाता है। 17.14",

    "जो किसी को भी उद्विग्न न करने वाला, सत्य और प्रिय तथा हितकारक भाषण है (वह) तथा स्वाध्याय और अभ्यास (नाम जप आदि) भी - यह वाणी-सम्बन्धी तप कहा जाता है। 17.15",

    "मन की प्रसन्नता, सौम्य भाव, मननशीलता, मन का निग्रह (और) भावों की भली भाँति शुद्धि - इस तरह यह मन-सम्बन्धी तप कहा जाता है। 17.16",

    "परम श्रद्धा से युक्त फलेच्छा रहित मनुष्यों के द्वारा (जो) तीन प्रकार (शरीर, वाणी और मन) - का तप किया जाता है, उसको सात्त्विक कहते हैं। 17.17",

    "जो तप सत्कार, मान और पूजा के लिये तथा दिखाने के भाव से किया जाता है, वह इस लोक में अनिश्चित (और) नाशवान फल देने वाला (तप) राजस कहा गया है। 17.18",

    "जो तप मूढ़तापूर्वक हठ से अपने को पीड़ा देकर अथवा दूसरों को कष्ट देने के लिये किया जाता है, वह (तप) तामस कहा गया है। 17.19",

    "दान देना कर्तव्य है - ऐसे भाव से जो दान देश, काल और पात्र के प्राप्त होने पर अनुपकारी को अर्थात् निष्काम भाव से दिया जाता है, वह दान सात्त्विक कहा गया है। 17.20",

    "किन्तु जो (दान) क्लेशपूर्वक और प्रत्युपकार के लिये अथवा फल-प्राप्ति का उद्देश्य बनाकर फिर दिया जाता है, वह दान राजस कहा जाता है। 17.21",

    "जो दान बिना सत्कार के तथा अवज्ञापूर्वक अयोग्य देश और काल में कुपात्र को दिया जाता है, वह (दान) तामस कहा गया है। 17.22",

    "ऊँ, तत् और सत् - इन तीन प्रकार के नामों से (जिस) परमात्मा का निर्देश (संकेत) किया गया है, उसी परमात्मा से सृष्टि के आदि में वेदों तथा ब्राह्मणों और यज्ञों की रचना हुई है। 17.23",

    "इसलिये वैदिक सिद्धान्तों को मानने वाले पुरुषों की शास्त्रविधि से नियत यज्ञ, दान और तप रूप क्रियाएँ सदा 'ॐ’ इस परमात्मा के नाम का उच्चारण करके (ही) आरम्भ होती हैं। 17.24",

    "तत्' नाम से कहे जाने वाले परमात्मा के लिये ही सब कुछ है - ऐसा मान कर मुक्ति चाहने वाले मनुष्यों द्वारा फल की इच्छा से रहित होकर अनेक प्रकार की यज्ञ और तप रूप क्रियाएँ तथा दान रूप क्रियाएँ की जाती हैं। 17.25",

    "हे पार्थ ! सत्- ऐसा यह परमात्मा का नाम सत्ता मात्र में और श्रेष्ठ भाव में प्रयोग किया जाता है तथा प्रशंसनीय कर्म के साथ 'सत्' शब्द जोड़ा जाता है। 17.26",

    "यज्ञ तथा तप और दान रूप क्रिया में (जो) स्थिति (निष्ठा) है, (वह) भी 'सत्' - ऐसे कही जाती है और उस परमात्मा के निमित्त किया जाने वाला कर्म भी 'सत्' - ऐसा ही कहा जाता है। 17.27",

    "हे पार्थ ! अश्रद्धा से किया हुआ हवन, दिया हुआ दान (और) तपा हुआ तप तथा (और भी) जो कुछ किया जाय, (वह सब) 'असत्' - ऐसा कहा जाता है। उसका (फल) न तो यहाँ होता है और न मरने के बाद ही होता है अर्थात् उसका कहीं भी सत् फल नहीं होता। 17.28",

    "इस प्रकार ॐ तत्  सत् - इन भगवन्नामों के उच्चारणपूर्वक ब्रह्मविद्या और योगशास्त्रमय श्रीमद्भगवद्गीतोपनिषदरूप श्रीकृष्णार्जुनसंवाद में ‘श्रद्धात्रयविभागयोग’ नामक सत्रहवाँ अध्याय पूर्ण हुआ।"
  ],
  "adhyaya-18" : [
    "अर्जुन बोले - हे महाबाहो ! हे हृषीकेश ! हे केशिनिषूदन ! (मैं) संन्यास और त्याग का तत्त्व अलग-अलग जानना चाहता हूँ। 18.1",

    "श्रीभगवान् बोले - (कई) विद्वान् काम्य कर्मों के त्याग को संन्यास समझते हैंं (और) (कई) विद्वान् सम्पूर्ण कर्मों के फल के त्याग को त्याग कहते हैं। कई विद्वान ऐसा कहते हैं कि कर्मों को दोष की तरह छोड़ देना चाहिये और कई विद्वान ऐसा (कहते हैं कि) यज्ञ, दान और तपरूप कर्मोंका त्याग नहीं करना चाहिये। (18.2-18.3)",

    "श्रीभगवान् बोले - (कई) विद्वान् काम्य कर्मों के त्याग को संन्यास समझते हैंं (और) (कई) विद्वान् सम्पूर्ण कर्मों के फल के त्याग को त्याग कहते हैं। कई विद्वान ऐसा कहते हैं कि कर्मों को दोष की तरह छोड़ देना चाहिये और कई विद्वान ऐसा (कहते हैं कि) यज्ञ, दान और तपरूप कर्मोंका त्याग नहीं करना चाहिये। (18.2-18.3)",

    "हे भरतवंशियों में श्रेष्ठ अर्जुन ! (तू) संन्यास और त्याग - इन दोनों में से पहले त्याग के विषय में मेरा निश्चय सुन; क्योंकि हे पुरुषश्रेष्ठ ! त्याग तीन प्रकार का कहा गया है। 18.4",

    "यज्ञ, दान और तपरूप कर्मों का त्याग नहीं करना चाहिये, (प्रत्युत) उनको तो करना ही चाहिये (क्योंकि) यज्ञ, दान और तप - ये तीनों ही (कर्म) मनीषियों को पवित्र करनेवाले हैं। 18.5",

    "हे पार्थ ! इन (यज्ञ, दान और तपरूप) कर्मों को तथा (दूसरे) भी (कर्मों को) आसक्ति और फलों की इच्छा का त्याग करके करना चाहिये - यह मेरा निश्चित किया हुआ उत्तम मत है। 18.6",

    "नियत कर्म का तो त्याग करना उचित नहीं है। उसका मोहपूर्वक त्याग करना तामस कहा गया है। 18.7",

    "जो कुछ कर्म है, वह दुःखरूप ही है - ऐसा (समझकर) कोई शारीरिक परिश्रम के भय से (उसका) त्याग कर दे, (तो) वह राजस त्याग करके भी त्याग के फल को नहीं पाता। 18.8",

    "हे अर्जुन ! 'केवल कर्तव्य मात्र करना है' -- ऐसा (समझकर) जो कर्म आसक्ति और फलेच्छा का त्याग करके किया जाता है, वही सात्त्विक त्याग माना गया है। 18.9",

    "(जो) अकुशल कर्म से द्वेष नहीं करता (और) कुशल कर्म में आसक्त नहीं होता, (वह) त्यागी, बुद्धिमान्, सन्देह रहित (और) अपने स्वरूप में स्थित है। 18.10",

    "कारण कि देहधारी मनुष्य के द्वारा सम्पूर्ण कर्मों का त्याग करना सम्भव नहीं है। इसलिये जो कर्मफल का त्यागी है, वही त्यागी है - ऐसा कहा जाता है। 18.11",

    "कर्मफल का त्याग न करनेवाले मनुष्यों को कर्मों का इष्ट, अनिष्ट और मिश्रित - (ऐसे) तीन प्रकार का फल मरने के बाद (भी) होता है; परन्तु कर्मफल का त्याग करने वालों को कहीं भी नहीं होता। 18.12",

    "हे महाबाहो ! कर्मों का अन्त करने वाले सांख्य-सिद्धान्त में सम्पूर्ण कर्मों की सिद्धि के लिये ये पाँच कारण बताये गये हैं, (इनको तू) मुझसे समझ। 18.13",

    "इसमें (कर्मों की सिद्धि में) अधिष्ठान तथा कर्ता और अनेक प्रकार के करण एवं विविध प्रकार की अलग-अलग चेष्टाएँ और वैसे ही पाँचवाँ कारण दैव (संस्कार) है। 18.14",

    "मनुष्य, शरीर, वाणी और मन के द्वारा शास्त्रविहित अथवा शास्त्रविरुद्ध जो कुछ भी कर्म आरम्भ करता है, उसके ये (पूर्वोक्त) पाँचों हेतु होते हैं। 18.15",

    "परन्तु ऐसे पाँच हेतुओं के होने पर भी जो उस (कर्मों के) विषय में केवल (शुद्ध) आत्मा को कर्ता देखता है, वह दुष्ट बुद्धिवाला ठीक नहीं देखता; (क्योंकि) उसकी बुद्धि शुद्ध नहीं है अर्थात् उसने विवेक को महत्व नहीं दिया है। 18.16",

    "जिसका अहंकृत भाव (मैं कर्ता हूँ - ऐसा भाव) नहीं है (और) जिसकी बुद्धि लिप्त नहीं होती, वह (युद्ध में) इन सम्पूर्ण प्राणियों को मारकर भी न मारता है (और) न बँधता है। 18.17",

    "ज्ञान, ज्ञेय (और) परिज्ञाता - इन तीनों से कर्मप्रेरणा होती है (तथा) करण, कर्म (और) कर्ता - इन तीनों से कर्मसंग्रह होता है। 18.18",

    "गुणों का विवेचन करने वाले शास्त्र में गुणों के भेद से  ज्ञान और कर्म तथा कर्ता तीन-तीन प्रकार से ही कहे जाते हैं, उनको भी (तुम) यथार्थ रूप से सुनो। 18.19",

    "जिस ज्ञान के द्वारा (साधक) सम्पूर्ण विभक्त प्राणियों में विभागरहित एक अविनाशी भाव (सत्ता) को देखता है, उस ज्ञान को (तुम) सात्त्विक समझो। 18.20",

    "परन्तु जो ज्ञान अर्थात् जिस ज्ञान के द्वारा मनुष्य सम्पूर्ण प्राणियों में अलग-अलग अनेक भावों को अलग-अलग रूप से जानता है, उस ज्ञान को (तुम) राजस समझो। 18.21",

    "किंतु जो (ज्ञान) अर्थात् जिस ज्ञान के द्वारा मनुष्य एक कार्यरूप शरीर में ही सम्पूर्ण की तरह आसक्त रहता है तथा (जो) युक्तिरहित, वास्तविक ज्ञान से रहित (और) तुच्छ है, वह तामस कहा गया है। 18.22",

    "जो कर्म शास्त्रविधि से नियत किया हुआ (और) कर्तृत्वाभिमान से रहित हो (तथा) फलेच्छारहित मनुष्य के द्वारा बिना राग-द्वेष के किया हुआ हो, वह सात्त्विक कहा जाता है। 18.23",

    "परन्तु जो कर्म भोगों  की इच्छा से अथवा अहंकार से और परिश्रमपूर्वक किया जाता है, वह राजस कहा गया है। 18.24",

    "जो कर्म परिणाम, हानि, हिंसा और सामर्थ्य को न देखकर मोहपूर्वक आरम्भ किया जाता है, वह तामस कहा जाता है। 18.25",

    "(जो) कर्ता राग रहित, कर्तृत्वाभिमान से रहित, धैर्य और उत्साहयुक्त (तथा) सिद्धि और असिद्धि में निर्विकार है, (वह) सात्त्विक कहा जाता है। 18.26",

    "जो कर्ता रागी, कर्मफल की इच्छावाला, लोभी, हिंसा के स्वभाव वाला, अशुद्ध (और) हर्ष-शोक से युक्त है, (वह) राजस कहा गया है। 18.27",

    "(जो) कर्ता असावधान, अशिक्षित, ऐंठ-अकड़वाला, जिद्दी, उपकारी का अपकार करनेवाला, आलसी, विषादी और दीर्घसूत्री है, (वह) तामस कहा जाता है। 18.28",

    "हे धनञ्जय! (अब तू) गुणों के अनुसार बुद्धि और धृति के भी तीन प्रकार के भेद अलग-अलग रूप से सुन, (जो कि मेरे द्वारा) पूर्णरूप से कहे जा रहे हैं। 18.29",

    "हे पृथानन्दन ! जो (बुद्धि) प्रवृत्ति और निवृत्ति को, कर्तव्य और अकर्तव्य को, भय और अभय को तथा बन्धन और मोक्ष को जानती है, वह बुद्धि सात्त्विकी है 18.30",

    "हे पार्थ! (मनुष्य) जिसके द्वारा धर्म और अधर्म को, कर्तव्य और अकर्तव्य को भी ठीक तरह से नहीं जानता, वह बुद्धि राजसी है। 18.31",

    "हे पृथानन्दन ! तमोगुण से घिरी हुई जो बुद्धि अधर्म को धर्म मान लेती है और सम्पूर्ण चीजों को उलटा (मान लेती है), वह तामसी है। 18.32",

    "हे पार्थ! समता से युक्त जिस अव्यभिचारिणी धृति के द्वारा (मनुष्य) मन, प्राण और इन्द्रियों की क्रियाओं को धारण करता है अर्थात् संयम रखता है, वह धृति सात्त्विकी है। 18.33",

    "परन्तु हे पृथानन्दन अर्जुन! फल की इच्छावाला मनुष्य जिस धृति के द्वारा धर्म, काम (भोग) और धन को अत्यन्त आसक्तिपूर्वक धारण करता है, वह धृति राजसी है। 18.34",

    "हे पार्थ! दुष्ट बुद्धिवाला मनुष्य जिस धृति के द्वारा निद्रा, भय, चिन्ता, दुःख और घमण्ड को भी नहीं छोड़ता अर्थात् धारण किये रहता है, वह धृति तामसी है। 18.35",

    "हे भरतवंशियों में श्रेष्ठ अर्जुन! अब तीन प्रकार के सुख को भी (तुम) मुझसे सुनो। जिसमें अभ्यास से रमण होता है और (जिससे) दुःखों का अन्त हो जाता है, ऐसा वह परमात्म-विषयक बुद्धि की प्रसन्नता से पैदा होने वाला जो सुख (सांसारिक आसक्ति के कारण) आरम्भ में विष की तरह (और) परिणाम में अमृत की तरह होता है, वह (सुख) सात्त्विक कहा गया है। (18.36-18.37)",

    "हे भरतवंशियों में श्रेष्ठ अर्जुन! अब तीन प्रकार के सुख को भी (तुम) मुझसे सुनो। जिसमें अभ्यास से रमण होता है और (जिससे) दुःखों का अन्त हो जाता है, ऐसा वह परमात्म-विषयक बुद्धि की प्रसन्नता से पैदा होने वाला जो सुख (सांसारिक आसक्ति के कारण) आरम्भ में विष की तरह (और) परिणाम में अमृत की तरह होता है, वह (सुख) सात्त्विक कहा गया है। (18.36-18.37)",

    "जो सुख इन्द्रियों और विषयों के संयोग से (होता है), वह आरम्भ में अमृत की तरह (और) परिणाम में विष की तरह प्रतीत होता है, वह (सुख) राजस कहा गया है। 18.38",

    "निद्रा, आलस्य और प्रमाद से उत्पन्न होने वाला जो सुख आरम्भ में और परिणाम में अपने को मोहित करने वाला है, वह (सुख) तामस कहा गया है। 18.39",

    "पृथ्वी में या स्वर्ग में अथवा देवताओं में तथा इनके सिवाय और कहीं भी वह (ऐसी कोई) वस्तु नहीं है, जो प्रकृति से उत्पन्न इन तीनों गुणों से रहित हो। 18.40",

    "हे परंतप! ब्राह्मण, क्षत्रिय, वैश्य और शूद्रों के कर्म स्वभाव से उत्पन्न हुए तीनों गुणों के द्वारा विभक्त किये गये हैं। 18.41",

    "मन का निग्रह करना, इन्द्रियों को वश में करना; धर्मपालन के लिये कष्ट सहना; बाहर-भीतर से शुद्ध रहना; दूसरों के अपराध को क्षमा करना; शरीर, मन आदि में सरलता रखना; वेद, शास्त्र आदि का ज्ञान होना; यज्ञविधि को अनुभव में लाना; और परमात्मा, वेद आदि में आस्तिक भाव रखना - (ये सबके सब ब्राह्मण के स्वाभाविक कर्म हैं। 18.42",

    "शूरवीरता, तेज, धैर्य, प्रजा के संचालन आदि की विशेष चतुरता, युद्ध में कभी पीठ न दिखाना, दान करना और शासन करने का भाव - (ये सबके सब) क्षत्रिय के स्वाभाविक कर्म है 18.43",

    "खेती करना, गायों की रक्षा करना और व्यापार करना - (ये सबके सब) वैश्य के स्वाभाविक कर्म हैं (तथा) चारों वर्णों की सेवा करना शूद्र का भी स्वाभाविक कर्म है। 18.44",

    "अपने-अपने कर्म में प्रीतिपूर्वक लगा हुआ मनुष्य सम्यक् सिद्धि (परमात्मा)को प्राप्त कर लेता है। अपने कर्म में लगा हुआ मनुष्य जिस प्रकार सिद्धि को प्राप्त होता है? उस प्रकार को (तू मुझसे) सुन। 18.45",

    "जिस परमात्मा से सम्पूर्ण प्राणियों की प्रवृत्ति (उत्पत्ति) होती है (और) जिससे यह सम्पूर्ण संसार व्याप्त है, उस परमात्मा का अपने कर्म के द्वारा पूजन करके मनुष्य सिद्धि को प्राप्त हो जाता है। 18.46",

    "अच्छी तरह अनुष्ठान किये हुए परधर्म से गुणरहित (भी) अपना धर्म श्रेष्ठ है। (कारण कि) स्वभाव से नियत किये हुए स्वधर्मरूप कर्म को करता हुआ (मनुष्य) पाप को प्राप्त नहीं होता। 18.47",

    "हे कुन्तीनन्दन ! दोषयुक्त होने पर भी सहज कर्म का त्याग नहीं करना चाहिये; क्योंकि सम्पूर्ण कर्म धुएँ से अग्नि की तरह (किसी न किसी) दोष से युक्त हैं। 18.48",

    "जिसकी बुद्धि सब जगह आसक्तिरहित है, जिसने शरीर को वश में कर रखा है, जो स्पृहारहित है (वह मनुष्य) सांख्ययोग के द्वारा सर्वश्रेष्ठ नैष्कर्म्य-सिद्धि को प्राप्त हो जाता है। 18.49",

    "हे कौन्तेय ! सिद्धि (अन्तःकरण की शुद्धि) को प्राप्त हुआ साधक ब्रह्म को, जो कि ज्ञान की परा निष्ठा है, जिस प्रकार से प्राप्त होता है, उस प्रकार को (तुम) मुझसे संक्षेप में ही समझो। 18.50",

    "(जो) विशुद्ध (सात्त्विकी) बुद्धि से युक्त, वैराग्य के आश्रित, एकान्त का सेवन करने वाला (और) नियमित भोजन करने वाला (साधक) धैर्यपूर्वक इन्द्रियों का नियमन करके, शरीर, वाणी, मन को वश में करके, शब्दादि विषयों का त्याग करके और राग-द्वेष को छोड़कर निरन्तर ध्यानयोग के परायण हो जाता है, (वह) अहंकार, बल, दर्प, काम, क्रोध और परिग्रह से रहित होकर (एवं) ममता रहित तथा शान्त होकर ब्रह्मप्राप्ति का पात्र हो जाता है। (18.51-18.53)",

    "(जो) विशुद्ध (सात्त्विकी) बुद्धि से युक्त, वैराग्य के आश्रित, एकान्त का सेवन करने वाला (और) नियमित भोजन करने वाला (साधक) धैर्यपूर्वक इन्द्रियों का नियमन करके, शरीर, वाणी, मन को वश में करके, शब्दादि विषयों का त्याग करके और राग-द्वेष को छोड़कर निरन्तर ध्यानयोग के परायण हो जाता है, (वह) अहंकार, बल, दर्प, काम, क्रोध और परिग्रह से रहित होकर (एवं) ममता रहित तथा शान्त होकर ब्रह्मप्राप्ति का पात्र हो जाता है। (18.51-18.53)",

    "(जो) विशुद्ध (सात्त्विकी) बुद्धि से युक्त, वैराग्य के आश्रित, एकान्त का सेवन करने वाला (और) नियमित भोजन करने वाला (साधक) धैर्यपूर्वक इन्द्रियों का नियमन करके, शरीर, वाणी, मन को वश में करके, शब्दादि विषयों का त्याग करके और राग-द्वेष को छोड़कर निरन्तर ध्यानयोग के परायण हो जाता है, (वह) अहंकार, बल, दर्प, काम, क्रोध और परिग्रह से रहित होकर (एवं) ममता रहित तथा शान्त होकर ब्रह्मप्राप्ति का पात्र हो जाता है। (18.51-18.53)",

    "(वह) ब्रह्मरूप बना हुआ प्रसन्न मन वाला साधक न तो (किसी के लिये) शोक करता है (और) न किसी की इच्छा करता है। (ऐसा) सम्पूर्ण प्राणियों में समभाव वाला साधक मेरी पराभक्ति को प्राप्त हो जाता है। 18.54",

    "(उस) पराभक्ति से मुझे, (मैं) जितना हूँ और जो हूँ (इसको) तत्त्व से जान लेता है फिर मुझे तत्त्व से जानकर तत्काल मुझमें प्रविष्ट हो जाता है। 18.55",

    "मेरा आश्रय लेने वाला भक्त सदा सब कर्म करता हुआ भी मेरी कृपा से शाश्वत अविनाशी पद को प्राप्त हो जाता है। 18.56",

    "चित्त से सम्पूर्ण कर्म मुझमें अर्पण करके, मेरे परायण होकर (तथा) समता का आश्रय लेकर निरन्तर मुझमें चित्त वाला हो जा। 18.57",

    "मुझमें चित्तवाला होकर (तू) मेरी कृपा से सम्पूर्ण विघ्नों को तर जायगा और यदि तू अहंकार के कारण (मेरी बात) नहीं सुनेगा (तो) तेरा पतन हो जायगा। 18.58",

    "अहंकार का आश्रय लेकर (तू) जो ऐसा मान रहा (है) कि मैं युद्ध नहीं करूँगा, तेरा यह निश्चय मिथ्या (झूठा) है; (क्योंकि) (तेरी) क्षात्र-प्रकृति तुझे युद्ध में लगा देगी। 18.59",

    "हे कुन्तीनन्दन ! अपने स्वभाव-जन्य कर्म से बँधा हुआ (तू) मोह के कारण जिस युद्ध को नहीं करना चाहता, उसको (तू) (क्षात्र-प्रकृति के) परवश होकर करेगा। 18.60",

    "हे अर्जुन ! ईश्वर सम्पूर्ण प्राणियों के हृदय में रहता है (और) अपनी माया से शरीररूपी यन्त्र पर आरूढ़ हुए सम्पूर्ण प्राणियों को (उनके स्वभाव के अनुसार) भ्रमण कराता रहता है। 18.61",

    "हे भरतवंशोद्भव अर्जुन ! (तू) सर्वभाव से उस ईश्वर की ही शरण में चला जा। उसकी कृपा से (तू) परमशान्ति (संसार से सर्वथा उपरति) को और अविनाशी परमपद को प्राप्त हो जायगा। 18.62",

    "यह गुह्य से भी गुह्यतर (शरणागति रूप) ज्ञान मैंने तुझे कह दिया। (अब तू) इस पर अच्छी तरह से विचार करके जैसा चाहता है, वैसा कर। 18.63",

    "सबसे अत्यन्त गोपनीय सर्वेत्कृष्ट वचन (तू) फिर मुझसे सुन। तू मेरा अत्यन्त प्रिय है, इसलिये यह (विशेष) हित की बात (मैं) तुझे कहूँगा। 18.64",

    "(तू) मेरा भक्त हो जा, मुझमें मनवाला (हो जा), मेरा पूजन करने वाला (हो जा और) मुझे नमस्कार कर। (ऐसा करने से तू) मुझे ही प्राप्त हो जायगा - (यह मैं) तेरे सामने सत्य प्रतिज्ञा करता हूँ; (क्योंकि तू) मेरा अत्यन्त प्रिय है। 18.65",

    "सम्पूर्ण धर्मों का आश्रय छोड़कर (तू) केवल मेरी शरण में आ जा। मैं तुझे सम्पूर्ण पापों से मुक्त कर दूँगा, चिन्ता मत कर। 18.66",

    "यह सर्वगुह्यतम वचन तुझे अतपस्वी को नहीं कहना चाहिए; अभक्त को कभी नहीं कहना चाहिए तथा जो सुनना नहीं चाहता (उसको) नहीं कहना चाहिए और जो मुझमें दोषदृष्टि करता है, उसको भी नहीं कहना चाहिए। 18.67",

    "मुझमें पराभक्ति करके जो इस परम गोपनीय संवाद (गीताग्रन्थ) को मेरे भक्तों में कहेगा, (वह) मुझे ही प्राप्त होगा - इसमें कोई सन्देह नहीं है। 18.68",

    "उसके समान मेरा अत्यन्त प्रिय कार्य करने वाला मनुष्यों में कोई भी नहीं है और इस भूमण्डल पर उसके समान मेरा दूसरा कोई प्रियतर होगा भी नहीं। 18.69",

    "जो मनुष्य हम दोनों के इस धर्ममय संवाद का अध्ययन करेगा, उसके द्वारा भी मैं ज्ञानयज्ञ से पूजित होऊँगा - ऐसा मेरा मत है। 18.70",

    "श्रद्धावान् और दोषदृष्टि से रहित जो मनुष्य इस (गीता-ग्रन्थ) को सुन भी लेगा, वह भी शरीर छूटने पर पुण्यकारियों के शुभ लोकों को प्राप्त हो जायगा। 18.71",

    "हे पृथानन्दन ! क्या तुमने एकाग्र-चित्त से इसको सुना? (और) हे धनञ्जय! क्या तुम्हारा अज्ञान से उत्पन्न मोह नष्ट हुआ ? 18.72",

    "अर्जुन बोले - हे अच्युत ! आपकी कृपा से (मेरा) मोह नष्ट हो गया है (और) मैंने स्मृति प्राप्त कर ली है। (मैं) सन्देह रहित होकर स्थित हूँ। (अब मैं) आपकी आज्ञा का पालन करूँगा। 18.73",

    "सञ्जय बोले - इस प्रकार मैंने भगवान् वासुदेव और महात्मा पृथानन्दन अर्जुन का यह रोमाञ्चित करने वाला अद्भुत संवाद सुना। 18.74",

    "व्यासजी की कृपा से मैंने स्वयं इस परम गोपनीय योग (गीता-ग्रन्थ) को कहते हुए साक्षात् योगेश्वर भगवान् श्रीकृष्ण से सुना है। 18.75",

    "हे राजन् ! भगवान् श्रीकृष्ण और अर्जुन के इस पवित्र और अद्भुत संवाद को याद कर-करके (मैं) बार-बार हर्षित हो रहा हूँ। 18.76",

    "हे राजन! श्रीहरि के उस परम अलौकिक रूप को भी याद कर मेरा हृदय बहुत अचंभित होता है और (मैं) बार-बार हर्षित हो रहा हूँ। 18.77",

    "जहाँ योगेश्वर भगवान श्रीकृष्ण हैं (और) जहाँ गाण्डीव धनुषधारी अर्जुन हैं, वहाँ ही श्री, विजय, विभूति (और) अचल नीति है - (ऐसा) मेरा मत है। 18.78",

    "इस प्रकार ॐ तत्  सत् - इन भगवन्नामों के उच्चारणपूर्वक ब्रह्मविद्या और योगशास्त्रमय श्रीमद्भगवद्गीतोपनिषदरूप श्रीकृष्णार्जुनसंवाद में ‘मोक्षसन्यासयोग’ नामक अठारहवाँ अध्याय पूर्ण हुआ।"     
  ]
  }
]

    export default GeetajiHindi