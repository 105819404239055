import Timer from "../Components/Timer"
import Texts from "../Components/TextScript/TextIndex"
import { useContext } from "react"
import { MyContext } from "../Components/Context"
import { useLocation, Link } from "react-router-dom"

const Score = () => {
    const location = useLocation()
    const state = location.state
    // console.log(state)

const lang = useContext(MyContext)
const headType = Texts[lang].Score

  return (
    <div className="scorePage" >
    <Link className="link homeBtn" to = '/' >Home</Link>
        <h1>{headType.reportCard}</h1>
        <table className="scoreTable">
            <thead>
                <tr>
                    <th>{headType.srNo}</th>
                    <th>{headType.adhyaya}</th>
                    <th>{headType.shlokNo}</th>
                    <th>{headType.time}</th>
                </tr>
            </thead>
            <tbody>
                {state.map ((item, index)=> (
                    <tr key={index}>
                        <td>{index+1}</td>
                        <td>{item.randNums[1].slice(8)}</td>
                        <td>{item.randNums[0]}</td>
                        <td>{<Timer startTime={item.startTime} now={item.now}/>}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
  )
}

export default Score
