const TextGujarati = {
Home:
   {
    WelMsg: "લર્નગીતા કંઠસ્થીકરણ અભ્યાસ પૃષ્ઠ",
    chooseLang: "પોતાની ભાષા પસંદ કરો:",
    greetMsg: "ગીતા પરિવારમાં આપનું સ્વાગત છે",
    linkBasic1: "સામાન્ય અભ્યાસ",
    linkBasic2:"ચરણાનુસાર",
    startPractice: "અભ્યાસ આરંભ કરો",
    linkAdv1:"ઉચ્ચ સ્તર અભ્યાસ",
    linkAdv2:"શ્લોકાંક",
    linkSerialWise: "ક્રમ અનુસાર શીખો",
    alert: "આ ભાષા વર્તમાનમાં ઉપલબ્ધ નથી",
  },
Practice:
  {
    WelMsg: "શ્રીમદ્ભગવદ્ગીતા કંઠસ્થીકરણ સહાયતા પૃષ્ઠ",
    selAdh: "એક અથવા વધુ અધ્યાય પસંદ કરો:",
    or: "અથવા",
    selectAll: "બધા અધ્યાય પસંદ કરો",
    selectdAdh: "પસંદ કરેલ અધ્યાય",
    clkBtn: "ક્રમરહિત શ્લોક સંખ્યા પ્રાપ્ત કરવા બટન પર ક્લિક કરો",
    alert: "ઓછામાં ઓછો એક અધ્યાય પસંદ કરવા વિનંતી",
    meaningMsg: "આ શ્લોકનો અર્થ અત્યારે ઉપલબ્ધ નથી, આગળ અપડેટની પ્રતીક્ષા કરવા વિનંતી",
    shlokNo: "શ્લોક ક્રમાંક",
    shlokCharan: "શ્લોક ચરણ",
    showAnswer:"ઉત્તર જુઓ",
    showMeaning: "અર્થ જુઓ",
    adhyaya: "અધ્યાય"
},
Nums:
  {
    num1: "૧", 
    num2: "૨",
    num3: "૩",
    num4: "૪",
    num5: "૫",
    num6: "૬",
    num7: "૭",
    num8: "૮",
    num9: "૯",
    num10: "૧૦",
    num11: "૧૧",
    num12: "૧૨",
    num13: "૧૩",
    num14: "૧૪",
    num15: "૧૫",
    num16: "૧૬",
    num17: "૧૭",
    num18: "૧૮"
  },
  Score:
  {
    reportCard: "રિપોર્ટ કાર્ડ",
    srNo: "ક્ર.સં.",
    adhyaya: "અધ્યાય",
    shlokNo: "શ્લોક ક્રમાંક",
    time: "સમય",
  },
  Serialwise:
  {
    WelMsg: "શ્રીમદ્ભગવદ્ગીતા ક્રમ અનુસાર શીખવા માટેનું પાનું"
  },
}
export default TextGujarati