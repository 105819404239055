import Router from "./Components/Router"
import Texts from "./Components/TextScript/TextIndex";
import { useState } from "react";
import { MyContext } from './Components/Context';

export default function App() {

  const [lang, setLang] = useState('english');

  const textType = Texts[lang].Home

  const chooseLang = (e) => {
  const newLang = (e.target.value)
  let langArr = []
  for (let key in Texts){
    langArr.push(key)
  }
  // console.log(langArr)
    if (langArr.includes(newLang)){
      setLang(newLang)
    } else {
      // setLang('english')
      alert(`This language is not availabe at present\n${newLang? lang !== 'english'? textType.alert : '' : ''}`)
    }
  }

  return (
   <MyContext.Provider value={lang}>
    <> 
      <Router
      handleLang={chooseLang}
      lang={lang}
      />
    </>
  </MyContext.Provider>
  )
}
