const Timer = ({startTime, now}) => {

    let secondsPassed = 0;
    let minutesPassed = 0;
    if (startTime != null && now != null) {
      secondsPassed = Math.floor((now - startTime) / 1000) % 60;
      minutesPassed = Math.floor((now - startTime) / 1000 / 60) % 60
    }

  return (
    <>
      <h4 className="timer">{minutesPassed} Min : {secondsPassed} Sec </h4>
    </>
  )
}

export default Timer
