const TextOdia = {
Home:
    {
    WelMsg: "ଲର୍ନଗୀତା କଣ୍ଠସ୍ଥୀକରଣ ଅଭ୍ୟାସ ପୃଷ୍ଠ",
    chooseLang: "ନିଜର ଭାଷା ଚୟନ କରନ୍ତୁ:",
    greetMsg: "ଗୀତା ପରିବାର ପକ୍ଷରୁ ଆପଣଙ୍କୁ ସ୍ଵାଗତ",
    linkBasic1: "ସାମାନ୍ୟ ଅଭ୍ୟାସ",
    linkBasic2:"ଚରଣାନୁସାର",
    startPractice: "ଅଭ୍ୟାସ ପ୍ରାରମ୍ଭ କରନ୍ତୁ",
    linkAdv1:"ଅଗ୍ର ସ୍ତର ଅଭ୍ୟାସ",
    linkAdv2:"ଶ୍ଳୋକାଙ୍କ",
    linkSerialWise: "କ୍ରମାନୁସାରେ ଶିଖନ୍ତୁ",
    alert: "ଉକ୍ତ ଭାଷା ବର୍ତ୍ତମାନ ଉପଲବ୍ଧ ନାହିଁ"
    },
Practice:
    {
    WelMsg: "ଶ୍ରୀମଦ୍ଭଗବଦ୍ଗୀତା କଣ୍ଠସ୍ଥୀକରଣ ସହାୟତା ପୃଷ୍ଠ",
    selAdh: "ଗୋଟିଏ କିମ୍ବା ଏକାଧିକ ଅଧ୍ୟାୟ:",
    or: "ଅଥବା",
    selectAll: "ସମସ୍ତ ଅଧ୍ୟାୟ ଚୟନ କରନ୍ତୁ",
    selectdAdh: "ଚୟନିତ ଅଧ୍ୟାୟ",
    clkBtn: "କ୍ରମରହିତ ଶ୍ଳୋକ ସଂଖ୍ୟା ପ୍ରାପ୍ତ କରିବା ନିମନ୍ତେ ବଟନ୍ ଉପରେ କ୍ଲିକ୍ କରନ୍ତୁ",
    alert: "କୃପା ପୂର୍ବକ ଅତିକମ୍ ରେ ଗୋଟିଏ ଅଧ୍ୟାୟ ଚୟନ କରନ୍ତୁ",
    meaningMsg: "ଉକ୍ତ ଶ୍ଳୋକ ର ଅର୍ଥ ବର୍ତ୍ତମାନ ଉପଲବ୍ଧ ନାହିଁ, ଏଣୁ କୃପା କରି ପରବର୍ତ୍ତୀ ଅପଡେଟ ନିମନ୍ତେ ପ୍ରତୀକ୍ଷା କରନ୍ତୁ",
    shlokNo: "ଶ୍ଳୋକ କ୍ରମାଙ୍କ ",
    shlokCharan: "ଶ୍ଳୋକ ଚରଣ",
    showAnswer:"ଉତ୍ତର ଦେଖନ୍ତୁ",
    showMeaning: "ଅର୍ଥ ଦେଖନ୍ତୁ",
    adhyaya: "ଅଧ୍ୟାୟ"
    },
Nums:
    {
    num1: "୧", 
    num2: "୨",
    num3: "୩",
    num4: "୪",
    num5: "୫",
    num6: "୬",
    num7: "୭",
    num8: "୮",
    num9: "୯",
    num10: "୧୦",
    num11: "୧୧",
    num12: "୧୨",
    num13: "୧୩",
    num14: "୧୪",
    num15: "୧୫",
    num16: "୧୬",
    num17: "୧୭",
    num18: "୧୮"
    },
Score:
    {
    reportCard: "ରିପୋର୍ଟ କାର୍ଡ",
    srNo: "କ୍ର.ସଂ.",
    adhyaya: "ଅଧ୍ୟାୟ",
    shlokNo: "ଶ୍ଳୋକ କ୍ରମାଙ୍କ",
    time: "ସମୟ",
    },
Serialwise:
    {
    WelMsg: "ଶ୍ରୀମଦ୍ଭଗବଦ୍ଗୀତା କ୍ରମାନୁସାରେ ଶିକ୍ଷାଲାଭ ର ପୃଷ୍ଠ",
    },
}
export default TextOdia