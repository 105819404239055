const GeetajiKannada = [
  {
    "adhyaya-1" : [
      "ಧೃತರಾಷ್ಟ್ರ ಉವಾಚ\nಧರ್ಮಕ್ಷೇತ್ರೇ ಕುರುಕ್ಷೇತ್ರೇ, ಸಮವೇತಾ ಯುಯುತ್ಸವಃ\nಮಾಮಕಾಃ(ಫ್) ಪಾಂಡವಾಶ್ಚೈವ, ಕಿಮಕುರ್ವತ ಸಂಜಯ ॥1.1॥",
  
      "ಸಂಜಯ ಉವಾಚ\nದೃಷ್ಟ್ವಾ ತು ಪಾಂಡವಾನೀಕಂ(ವ್), ವ್ಯೂಢಂ(ನ್) ದುರ್ಯೋಧನಸ್ತದಾ\nಆಚಾರ್ಯಮುಪಸಂಗಮ್ಯ, ರಾಜಾ ವಚನಮಬ್ರವೀತ್ ॥1.2॥",
  
      "ಪಶ್ಯೈತಾಂ(ಮ್) ಪಾಂಡುಪುತ್ರಾಣಾಮ್, ಆಚಾರ್ಯ ಮಹತೀಂ(ಞ್) ಚಮೂಮ್\nವ್ಯೂಢಾಂ(ನ್) ದ್ರುಪದಪುತ್ರೇಣ, ತವ ಶಿಷ್ಯೇಣ ಧೀಮತಾ ॥1.3॥",
  
      "ಅತ್ರ ಶೂರಾ ಮಹೇಷ್ವಾಸಾ, ಭೀಮಾರ್ಜುನಸಮಾ ಯುಧಿ\nಯುಯುಧಾನೋ ವಿರಾಟಶ್ಚ, ದ್ರುಪದಶ್ಚ ಮಹಾರಥಃ ॥1.4॥",
  
      "ಧೃಷ್ಟಕೇತುಶ್ಚೇಕಿತಾನಃ(ಖ್), ಕಾಶಿರಾಜಶ್ಚ ವೀರ್ಯವಾನ್\nಪುರುಜಿತ್ಕುಂತಿಭೋಜಶ್ಚ, ಶೈಬ್ಯಶ್ಚ ನರಪುಂಗವಃ ॥1.5॥",
  
      "ಯುಧಾಮನ್ಯುಶ್ಚ ವಿಕ್ರಾಂತ, ಉತ್ತಮೌಜಾಶ್ಚ ವೀರ್ಯವಾನ್\nಸೌಭದ್ರೋ ದ್ರೌಪದೇಯಾಶ್ಚ, ಸರ್ವ ಏವ ಮಹಾರಥಾಃ ॥1.6॥",
  
      "ಅಸ್ಮಾಕಂ(ನ್) ತು ವಿಶಿಷ್ಟಾ ಯೇ, ತಾನ್ನಿಬೋಧ ದ್ವಿಜೋತ್ತಮ\nನಾಯಕಾ ಮಮ ಸೈನ್ಯಸ್ಯ, ಸಂಜ್ಞಾರ್ಥಂ(ನ್) ತಾನ್ಬ್ರವೀಮಿ ತೇ ॥1.7॥",
  
      "ಭವಾನ್ಭೀಷ್ಮಶ್ಚ ಕರ್ಣಶ್ಚ, ಕೃಪಶ್ಚ ಸಮಿತಿಂಜಯಃ\nಅಶ್ವತ್ಥಾಮಾ ವಿಕರ್ಣಶ್ಚ, ಸೌಮದತ್ತಿಸ್ತಥೈವ ಚ ॥1.8॥",
  
      "ಅನ್ಯೇ ಚ ಬಹವಃ(ಶ್) ಶೂರಾ, ಮದರ್ಥೇ ತ್ಯಕ್ತಜೀವಿತಾಃ\nನಾನಾಶಸ್ತ್ರಪ್ರಹರಣಾಃ(ಸ್), ಸರ್ವೇ ಯುದ್ಧವಿಶಾರದಾಃ ॥1.9॥",
  
      "ಅಪರ್ಯಾಪ್ತಂ(ನ್) ತದಸ್ಮಾಕಂ(ಮ್), ಬಲಂ(ಮ್) ಭೀಷ್ಮಾಭಿರಕ್ಷಿತಮ್\nಪರ್ಯಾಪ್ತಂ(ನ್) ತ್ವಿದಮೇತೇಷಾಂ(ಮ್), ಬಲಂ(ಮ್) ಭೀಮಾಭಿರಕ್ಷಿತಮ್॥1.10॥",
  
      "ಅಯನೇಷು ಚ ಸರ್ವೇಷು, ಯಥಾಭಾಗಮವಸ್ಥಿತಾಃ\nಭೀಷ್ಮಮೇವಾಭಿರಕ್ಷಂತು, ಭವಂತಃ(ಸ್) ಸರ್ವ ಏವ ಹಿ ॥1.11॥",
  
      "ತಸ್ಯ ಸಂಜನಯನ್ಹರ್ಷಂ(ಙ್), ಕುರುವೃದ್ಧಃ(ಫ್) ಪಿತಾಮಹಃ\nಸಿಂಹನಾದಂ(ವ್) ವಿನದ್ಯೋಚ್ಚೈಃ(ಶ್), ಶಂಖಂ(ನ್) ದಧ್ಮೌ ಪ್ರತಾಪವಾನ್॥1.12॥",
  
      "ತತಃ(ಶ್) ಶಂಖಾಶ್ಚ ಭೇರ್ಯಶ್ಚ, ಪಣವಾನಕಗೋಮುಖಾಃ\nಸಹಸೈವಾಭ್ಯಹನ್ಯಂತ, ಸ ಶಬ್ದಸ್ತುಮುಲೋಽಭವತ್  ॥1.13॥",
  
      "ತತಃ(ಶ್) ಶ್ವೇತೈರ್ಹಯೈರ್ಯುಕ್ತೇ, ಮಹತಿ ಸ್ಯಂದನೇ ಸ್ಥಿತೌ\nಮಾಧವಃ(ಫ್) ಪಾಂಡವಶ್ಚೈವ, ದಿವ್ಯೌ ಶಂಖೌ ಪ್ರದಧ್ಮತುಃ ॥1.14॥",
  
      "ಪಾಂಚಜನ್ಯಂ(ಮ್) ಹೃಷೀಕೇಶೋ, ದೇವದತ್ತಂ(ನ್) ಧನಂಜಯಃ\nಪೌಂಡ್ರಂ(ನ್) ದಧ್ಮೌ ಮಹಾಶಂಖಂ(ಮ್), ಭೀಮಕರ್ಮಾ ವೃಕೋದರಃ ॥1.15॥",
  
      "ಅನಂತವಿಜಯಂ(ಮ್) ರಾಜಾ, ಕುಂತೀಪುತ್ರೋ ಯುಧಿಷ್ಠಿರಃ\nನಕುಲಃ(ಸ್) ಸಹದೇವಶ್ಚ, ಸುಘೋಷಮಣಿಪುಷ್ಪಕೌ ॥1.16॥",
  
      "ಕಾಶ್ಯಶ್ಚ ಪರಮೇಷ್ವಾಸಃ(ಶ್), ಶಿಖಂಡೀ ಚ ಮಹಾರಥಃ\nಧೃಷ್ಟದ್ಯುಮ್ನೋ ವಿರಾಟಶ್ಚ, ಸಾತ್ಯಕಿಶ್ಚಾಪರಾಜಿತಃ ॥1.17॥",
  
      "ದ್ರುಪದೋ ದ್ರೌಪದೇಯಾಶ್ಚ, ಸರ್ವಶಃ(ಫ್) ಪೃಥಿವೀಪತೇ\nಸೌಭದ್ರಶ್ಚ ಮಹಾಬಾಹುಃ(ಶ್), ಶಂಖಾಂದಧ್ಮುಃ(ಫ್) ಪೃಥಕ್ಪೃಥಕ್ ॥1.18॥",
  
      "ಸ ಘೋಷೋ ಧಾರ್ತರಾಷ್ಟ್ರಾಣಾಂ(ಮ್), ಹೃದಯಾನಿ ವ್ಯದಾರಯತ್\nನಭಶ್ಚ ಪೃಥಿವೀಂ(ಞ್) ಚೈವ, ತುಮುಲೋ ವ್ಯನುನಾದಯನ್ ॥1.19॥",
  
      "ಅಥ ವ್ಯವಸ್ಥಿತಾಂದೃಷ್ಟ್ವಾ, ಧಾರ್ತರಾಷ್ಟ್ರಾನ್ ಕಪಿಧ್ವಜಃ\nಪ್ರವೃತ್ತೇ ಶಸ್ತ್ರಸಂಪಾತೇ, ಧನುರುದ್ಯಮ್ಯ ಪಾಂಡವಃ ॥1.20॥",
  
      "ಹೃಷೀಕೇಶಂ(ನ್) ತದಾ ವಾಕ್ಯಮ್, ಇದಮಾಹ ಮಹೀಪತೇ\nಅರ್ಜುನ ಉವಾಚ\nಸೇನಯೋರುಭಯೋರ್ಮಧ್ಯೇ, ರಥಂ(ಮ್) ಸ್ಥಾಪಯ ಮೇಽಚ್ಯುತ ॥1.21॥",
  
      "ಯಾವದೇತಾನ್ನಿರೀಕ್ಷೇಽಹಂ(ಯ್), ಯೋದ್ಧುಕಾಮಾನವಸ್ಥಿತಾನ್\nಕೈರ್ಮಯಾ ಸಹ ಯೋದ್ಧವ್ಯಮ್, ಅಸ್ಮಿನ್ರಣಸಮುದ್ಯಮೇ  ॥1.22॥",
  
      "ಯೋತ್ಸ್ಯಮಾನಾನವೇಕ್ಷೇಽಹಂ(ಯ್), ಯ ಏತೇಽತ್ರ ಸಮಾಗತಾಃ\nಧಾರ್ತರಾಷ್ಟ್ರಸ್ಯ ದುರ್ಬುದ್ಧೇಃ(ರ್), ಯುದ್ಧೇ ಪ್ರಿಯಚಿಕೀರ್ಷವಃ ॥1.23॥",
  
      "ಸಂಜಯ ಉವಾಚ\nಏವಮುಕ್ತೋ ಹೃಷೀಕೇಶೋ, ಗುಡಾಕೇಶೇನ ಭಾರತ\nಸೇನಯೋರುಭಯೋರ್ಮಧ್ಯೇ, ಸ್ಥಾಪಯಿತ್ವಾ ರಥೋತ್ತಮಮ್ ॥1.24॥",
  
      "ಭೀಷ್ಮದ್ರೋಣಪ್ರಮುಖತಃ(ಸ್), ಸರ್ವೇಷಾಂ(ಞ್) ಚ ಮಹೀಕ್ಷಿತಾಮ್\nಉವಾಚ ಪಾರ್ಥ ಪಶ್ಯೈತಾನ್, ಸಮವೇತಾನ್ಕುರೂನಿತಿ ॥1.25॥",
  
      "ತತ್ರಾಪಶ್ಯತ್ಸ್ಥಿತಾನ್ಪಾರ್ಥಃ(ಫ್), ಪಿತೄನಥ ಪಿತಾಮಹಾನ್\nಆಚಾರ್ಯಾನ್ಮಾತುಲಾನ್ಭ್ರಾತೄನ್, ಪುತ್ರಾನ್ಪೌತ್ರಾನ್ಸಖೀಂಸ್ತಥಾ ॥1.26॥",
  
      "ಶ್ವಶುರಾನ್ಸುಹೃದಶ್ಚೈವ, ಸೇನಯೋರುಭಯೋರಪಿ\nತಾನ್ಸಮೀಕ್ಷ್ಯ ಸ ಕೌಂತೇಯಃ(ಸ್), ಸರ್ವಾನ್ಬಂಧೂನವಸ್ಥಿತಾನ್ ॥1.27॥",
  
      "ಕೃಪಯಾ ಪರಯಾವಿಷ್ಟೋ, ವಿಷೀದನ್ನಿದಮಬ್ರವೀತ್\nಅರ್ಜುನ ಉವಾಚ\nದೃಷ್ಟ್ವೇಮಂ(ಮ್) ಸ್ವಜನಂ(ಙ್) ಕೃಷ್ಣ, ಯುಯುತ್ಸುಂ(ಮ್) ಸಮುಪಸ್ಥಿತಮ್ ॥1.28॥",
  
      "ಸೀದಂತಿ ಮಮ ಗಾತ್ರಾಣಿ, ಮುಖಂ(ಞ್) ಚ ಪರಿಶುಷ್ಯತಿ\nವೇಪಥುಶ್ಚ ಶರೀರೇ ಮೇ, ರೋಮಹರ್ಷಶ್ಚ ಜಾಯತೇ  ॥1.29॥",
  
      "ಗಾಂಡೀವಂ(ಮ್) ಸ್ರಂಸತೇ ಹಸ್ತಾತ್, ತ್ವಕ್ಚೈವ ಪರಿದಹ್ಯತೇ\nನ ಚ ಶಕ್ನೋಮ್ಯವಸ್ಥಾತುಂ(ಮ್), ಭ್ರಮತೀವ ಚ ಮೇ ಮನಃ ॥1.30॥",
  
      "ನಿಮಿತ್ತಾನಿ ಚ ಪಶ್ಯಾಮಿ, ವಿಪರೀತಾನಿ ಕೇಶವ\nನ ಚ ಶ್ರೇಯೋಽನುಪಶ್ಯಾಮಿ, ಹತ್ವಾ ಸ್ವಜನಮಾಹವೇ ॥1.31॥",
  
      "ನ ಕಾಂಕ್ಷೇ ವಿಜಯಂ(ಙ್) ಕೃಷ್ಣ, ನ ಚ ರಾಜ್ಯಂ(ಮ್) ಸುಖಾನಿ ಚ\nಕಿಂ(ನ್) ನೋ ರಾಜ್ಯೇನ ಗೋವಿಂದ, ಕಿಂ(ಮ್) ಭೋಗೈರ್ಜೀವಿತೇನ ವಾ ॥1.32॥",
  
      "ಯೇಷಾಮರ್ಥೇ ಕಾಂಕ್ಷಿತಂ(ನ್) ನೋ, ರಾಜ್ಯಂ(ಮ್) ಭೋಗಾಃ(ಸ್) ಸುಖಾನಿ ಚ\nತ ಇಮೇಽವಸ್ಥಿತಾ ಯುದ್ಧೇ, ಪ್ರಾಣಾಂಸ್ತ್ಯಕ್ತ್ವಾ ಧನಾನಿ ಚ ॥1.33॥",
  
      "ಆಚಾರ್ಯಾಃ(ಫ್) ಪಿತರಃ(ಫ್) ಪುತ್ರಾಃ(ಸ್), ತಥೈವ ಚ ಪಿತಾಮಹಾಃ\nಮಾತುಲಾಃ(ಶ್) ಶ್ವಶುರಾಃ(ಫ್) ಪೌತ್ರಾಃ(ಶ್), ಶ್ಯಾಲಾಃ(ಸ್) ಸಂಬಂಧಿನಸ್ತಥಾ ॥1.34॥",
  
      "ಏತಾನ್ನ ಹಂತುಮಿಚ್ಛಾಮಿ, ಘ್ನತೋಽಪಿ ಮಧುಸೂದನ\nಅಪಿ ತ್ರೈಲೋಕ್ಯರಾಜ್ಯಸ್ಯ, ಹೇತೋಃ(ಖ್) ಕಿಂ(ನ್) ನು ಮಹೀಕೃತೇ ॥1.35॥",
  
      "ನಿಹತ್ಯ ಧಾರ್ತರಾಷ್ಟ್ರಾನ್ನಃ(ಖ್), ಕಾ ಪ್ರೀತಿಃ(ಸ್) ಸ್ಯಾಜ್ಜನಾರ್ದನ\nಪಾಪಮೇವಾಶ್ರಯೇದಸ್ಮಾನ್, ಹತ್ವೈತಾನಾತತಾಯಿನಃ ॥1.36॥",
  
      "ತಸ್ಮಾನ್ನಾರ್ಹಾ ವಯಂ(ಮ್) ಹಂತುಂ(ನ್), ಧಾರ್ತರಾಷ್ಟ್ರಾನ್ಸ್ವಬಾಂಧವಾನ್\nಸ್ವಜನಂ(ಮ್) ಹಿ ಕಥಂ(ಮ್) ಹತ್ವಾ,ಸುಖಿನಃ(ಸ್) ಸ್ಯಾಮ ಮಾಧವ ॥1.37॥",
  
      "ಯದ್ಯಪ್ಯೇತೇ ನ ಪಶ್ಯಂತಿ, ಲೋಭೋಪಹತಚೇತಸಃ\nಕುಲಕ್ಷಯಕೃತಂ(ನ್) ದೋಷಂ(ಮ್), ಮಿತ್ರದ್ರೋಹೇ ಚ ಪಾತಕಮ್ ॥1.38॥",
  
      "ಕಥಂ(ನ್) ನ ಜ್ಞೇಯಮಸ್ಮಾಭಿಃ(ಫ್), ಪಾಪಾದಸ್ಮಾನ್ನಿವರ್ತಿತುಮ್\nಕುಲಕ್ಷಯಕೃತಂ(ನ್) ದೋಷಂ(ಮ್), ಪ್ರಪಶ್ಯದ್ಭಿರ್ಜನಾರ್ದನ ॥1.39॥",
  
      "ಕುಲಕ್ಷಯೇ ಪ್ರಣಶ್ಯಂತಿ, ಕುಲಧರ್ಮಾಃ(ಸ್) ಸನಾತನಾಃ\nಧರ್ಮೇ ನಷ್ಟೇ ಕುಲಂ(ಙ್) ಕೃತ್ಸ್ನಮ್, ಅಧರ್ಮೋಽಭಿಭವತ್ಯುತ ॥1.40॥",
  
      "ಅಧರ್ಮಾಭಿಭವಾತ್ಕೃಷ್ಣ, ಪ್ರದುಷ್ಯಂತಿ ಕುಲಸ್ತ್ರಿಯಃ\nಸ್ತ್ರೀಷು ದುಷ್ಟಾಸು ವಾರ್ಷ್ಣೇಯ, ಜಾಯತೇ ವರ್ಣಸಂಕರಃ ॥1.41॥",
  
      "ಸಂಕರೋ ನರಕಾಯೈವ, ಕುಲಘ್ನಾನಾಂ(ಙ್) ಕುಲಸ್ಯ ಚ\nಪತಂತಿ ಪಿತರೋ ಹ್ಯೇಷಾಂ(ಲ್), ಲುಪ್ತಪಿಂಡೋದಕಕ್ರಿಯಾಃ ॥1.42॥",
  
      "ದೋಷೈರೇತೈಃ(ಖ್) ಕುಲಘ್ನಾನಾಂ(ವ್), ವರ್ಣಸಂಕರಕಾರಕೈಃ\nಉತ್ಸಾದ್ಯಂತೇ ಜಾತಿಧರ್ಮಾಃ(ಖ್), ಕುಲಧರ್ಮಾಶ್ಚ ಶಾಶ್ವತಾಃ ॥1.43॥",
  
      "ಉತ್ಸನ್ನಕುಲಧರ್ಮಾಣಾಂ(ಮ್), ಮನುಷ್ಯಾಣಾಂ(ಞ್) ಜನಾರ್ದನ\nನರಕೇಽ ನಿಯತಂ(ವ್) ವಾಸೋ, ಭವತೀತ್ಯನುಶುಶ್ರುಮ ॥1.44॥",
  
      "ಅಹೋ ಬತ ಮಹತ್ಪಾಪಂ(ಙ್), ಕರ್ತುಂ(ವ್) ವ್ಯವಸಿತಾ ವಯಮ್\nಯದ್ರಾಜ್ಯಸುಖಲೋಭೇನ, ಹಂತುಂ(ಮ್) ಸ್ವಜನಮುದ್ಯತಾಃ  ॥1.45॥",
  
      "ಯದಿ ಮಾಮಪ್ರತೀಕಾರಮ್, ಅಶಸ್ತ್ರಂ(ಮ್) ಶಸ್ತ್ರಪಾಣಯಃ\nಧಾರ್ತರಾಷ್ಟ್ರಾ ರಣೇ ಹನ್ಯುಃ(ಸ್), ತನ್ಮೇ ಕ್ಷೇಮತರಂ(ಮ್) ಭವೇತ್ ॥1.46॥",
  
      "ಸಂಜಯ ಉವಾಚ\nಏವಮುಕ್ತ್ವಾರ್ಜುನಃ(ಸ್) ಸಂಖ್ಯೇ, ರಥೋಪಸ್ಥ ಉಪಾವಿಶತ್\nವಿಸೃಜ್ಯ ಸಶರಂ(ಞ್) ಚಾಪಂ(ಮ್), ಶೋಕಸಂವಿಗ್ನಮಾನಸಃ ॥1.47॥",
  
      "ಓಂ ತತ್ಸದಿತಿ ಶ್ರೀಮದ್ಭಗವದ್ಗೀತಾಸು ಉಪನಿಷತ್ಸು ಬ್ರಹ್ಮವಿದ್ಯಾಯಾಂ(ಯ್)\nಯೋಗಶಾಸ್ತ್ರೇ ಶ್ರೀಕೃಷ್ಣಾರ್ಜುನಸಂವಾದೇ ಅರ್ಜುನವಿಷಾದಯೋಗೋ ನಾಮ ಪ್ರಥಮೋಽಧ್ಯಾಯಃ ॥1॥"
    ],
    "adhyaya-2" : [
      "ಸಂಜಯ ಉವಾಚ\nತಂ(ನ್) ತಥಾ ಕೃಪಯಾವಿಷ್ಟಮ್, ಅಶ್ರುಪೂರ್ಣಾಕುಲೇಕ್ಷಣಮ್\nವಿಷೀದಂತಮಿದಂ(ವ್) ವಾಕ್ಯಮ್, ಉವಾಚ ಮಧುಸೂದನಃ ॥2.1॥",
  
      "ಶ್ರೀಭಗವಾನುವಾಚ\nಕುತಸ್ತ್ವಾ ಕಶ್ಮಲಮಿದಂ(ವ್), ವಿಷಮೇ ಸಮುಪಸ್ಥಿತಮ್\nಅನಾರ್ಯಜುಷ್ಟಮಸ್ವರ್ಗ್ಯಮ್, ಅಕೀರ್ತಿಕರಮರ್ಜುನ॥2.2॥",
  
      "ಕ್ಲೈಬ್ಯಂ(ಮ್) ಮಾ ಸ್ಮ ಗಮಃ(ಫ್) ಪಾರ್ಥ, ನೈತತ್ತ್ವಯ್ಯುಪಪದ್ಯತೇ\nಕ್ಷುದ್ರಂ(ಮ್) ಹೃದಯದೌರ್ಬಲ್ಯಂ(ನ್), ತ್ಯಕ್ತ್ವೋತ್ತಿಷ್ಠ ಪರಂತಪ ॥2.3॥",
  
      "ಅರ್ಜುನ ಉವಾಚ\nಕಥಂ(ಮ್) ಭೀಷ್ಮಮಹಂ(ಮ್) ಸಂಖ್ಯೇ, ದ್ರೋಣಂ(ಞ್) ಚ ಮಧುಸೂದನ\nಇಷುಭಿಃ(ಫ್) ಪ್ರತಿಯೋತ್ಸ್ಯಾಮಿ, ಪೂಜಾರ್ಹಾವರಿಸೂದನ ॥2.4॥",
  
      "ಗುರೂನಹತ್ವಾ ಹಿ ಮಹಾನುಭಾವಾನ್,\nಶ್ರೇಯೋ ಭೋಕ್ತುಂ(ಮ್) ಭೈಕ್ಷ್ಯಮಪೀಹ ಲೋಕೇ\nಹತ್ವಾರ್ಥಕಾಮಾಂಸ್ತು ಗುರೂನಿಹೈವ,\nಭುಂಜೀಯ ಭೋಗಾನ್ ರುಧಿರಪ್ರದಿಗ್ಧಾನ್ ॥2.5॥",
  
      "ನ ಚೈತದ್ವಿದ್ಮಃ(ಖ್) ಕತರನ್ನೋ ಗರೀಯೋ,\nಯದ್ವಾ ಜಯೇಮ ಯದಿ ವಾ ನೋ ಜಯೇಯುಃ\nಯಾನೇವ ಹತ್ವಾ ನ ಜಿಜೀವಿಷಾಮಃ(ಸ್),\nತೇಽವಸ್ಥಿತಾಃ(ಫ್) ಪ್ರಮುಖೇ ಧಾರ್ತರಾಷ್ಟ್ರಾಃ ॥2.6॥",
  
      "ಕಾರ್ಪಣ್ಯದೋಷೋಪಹತಸ್ವಭಾವಃ(ಫ್),\nಪೃಚ್ಛಾಮಿ ತ್ವಾಂ(ನ್) ಧರ್ಮಸಮ್ಮೂಢಚೇತಾಃ\nಯಚ್ಛ್ರೇಯಃ(ಸ್) ಸ್ಯಾನ್ನಿಶ್ಚಿತಂ(ಮ್) ಬ್ರೂಹಿ ತನ್ಮೇ,\nಶಿಷ್ಯಸ್ತೇಽಹಂ(ಮ್) ಶಾಧಿ ಮಾಂ(ನ್) ತ್ವಾಂ(ಮ್) ಪ್ರಪನ್ನಮ್ ॥2.7॥",
  
      "ನ ಹಿ ಪ್ರಪಶ್ಯಾಮಿ ಮಮಾಪನುದ್ಯಾದ್,\nಯಚ್ಛೋಕಮುಚ್ಛೋಷಣಮಿಂದ್ರಿಯಾಣಾಮ್\nಅವಾಪ್ಯ ಭೂಮಾವಸಪತ್ನಮೃದ್ಧಂ(ಮ್),\nರಾಜ್ಯಂ(ಮ್) ಸುರಾಣಾಮಪಿ ಚಾಧಿಪತ್ಯಮ್ ॥2.8॥",
  
      "ಸಂಜಯ ಉವಾಚ\nಏವಮುಕ್ತ್ವಾ ಹೃಷೀಕೇಶಂ(ಙ್), ಗುಡಾಕೇಶಃ(ಫ್) ಪರಂತಪ\nನ ಯೋತ್ಸ್ಯ ಇತಿ ಗೋವಿಂದಮ್, ಉಕ್ತ್ವಾ ತೂಷ್ಣೀಂ(ಮ್) ಬಭೂವ ಹ ॥2.9॥",
  
      "ತಮುವಾಚ ಹೃಷೀಕೇಶಃ(ಫ್), ಪ್ರಹಸನ್ನಿವ ಭಾರತ\nಸೇನಯೋರುಭಯೋರ್ಮಧ್ಯೇ, ವಿಷೀದಂತಮಿದಂ(ವ್) ವಚಃ ॥2.10॥",
  
      "ಶ್ರೀಭಗವಾನುವಾಚ\nಅಶೋಚ್ಯಾನನ್ವಶೋಚಸ್ತ್ವಂ(ಮ್), ಪ್ರಜ್ಞಾವಾದಾಂಶ್ಚ ಭಾಷಸೇ\nಗತಾಸೂನಗತಾಸೂಂಶ್ಚ, ನಾನುಶೋಚಂತಿ ಪಂಡಿತಾಃ ॥2.11॥ ",
  
      "ನ ತ್ವೇವಾಹಂ(ಞ್) ಜಾತು ನಾಸಂ(ನ್), ನ ತ್ವಂ(ನ್) ನೇಮೇ ಜನಾಧಿಪಾಃ\nನ ಚೈವ ನ ಭವಿಷ್ಯಾಮಃ(ಸ್), ಸರ್ವೇ ವಯಮತಃ(ಫ್) ಪರಮ್ ॥2.12॥",
  
      "ದೇಹಿನೋಽಸ್ಮಿನ್ಯಥಾ ದೇಹೇ, ಕೌಮಾರಂ(ಯ್) ಯೌವನಂ(ಞ್) ಜರಾ\nತಥಾ ದೇಹಾಂತರಪ್ರಾಪ್ತಿಃ(ರ್), ಧೀರಸ್ತತ್ರ ನ ಮುಹ್ಯತಿ ॥2.13॥",
  
      "ಮಾತ್ರಾಸ್ಪರ್ಶಾಸ್ತು ಕೌಂತೇಯ, ಶೀತೋಷ್ಣಸುಖದುಃಖದಾಃ\nಆಗಮಾಪಾಯಿನೋಽನಿತ್ಯಾಃ(ಸ್), ತಾಂಸ್ತಿತಿಕ್ಷಸ್ವ ಭಾರತ ॥2.14॥",
  
      "ಯಂ(ಮ್) ಹಿ ನ ವ್ಯಥಯಂತ್ಯೇತೇ, ಪುರುಷಂ(ಮ್) ಪುರುಷರ್ಷಭ\nಸಮದುಃಖಸುಖಂ(ನ್) ಧೀರಂ(ಮ್), ಸೋಽಮೃತತ್ವಾಯ ಕಲ್ಪತೇ ॥2.15॥",
  
      "ನಾಸತೋ ವಿದ್ಯತೇ ಭಾವೋ, ನಾಭಾವೋ ವಿದ್ಯತೇ ಸತಃ\nಉಭಯೋರಪಿ ದೃಷ್ಟೋಽನ್ತಃ(ಸ್), ತ್ವನಯೋಸ್ತತ್ತ್ವದರ್ಶಿಭಿಃ ॥2.16॥",
  
      "ಅವಿನಾಶಿ ತು ತದ್ವಿದ್ಧಿ, ಯೇನ ಸರ್ವಮಿದಂ(ನ್) ತತಮ್\nವಿನಾಶಮವ್ಯಯಸ್ಯಾಸ್ಯ, ನ ಕಶ್ಚಿತ್ಕರ್ತುಮರ್ಹತಿ ॥2.17॥",
  
      "ಅಂತವಂತ ಇಮೇ ದೇಹಾ, ನಿತ್ಯಸ್ಯೋಕ್ತಾಃ(ಶ್) ಶರೀರಿಣಃ\nಅನಾಶಿನೋಽಪ್ರಮೇಯಸ್ಯ, ತಸ್ಮಾದ್ಯುಧ್ಯಸ್ವ ಭಾರತ ॥2.18॥",
  
      "ಯ ಏನಂ(ವ್) ವೇತ್ತಿ ಹಂತಾರಂ(ಯ್), ಯಶ್ಚೈನಂ(ಮ್) ಮನ್ಯತೇ ಹತಮ್\nಉಭೌ ತೌ ನ ವಿಜಾನೀತೋ, ನಾಯಂ(ಮ್) ಹಂತಿ ನ ಹನ್ಯತೇ ॥2.19॥",
  
      "ನ ಜಾಯತೇ ಮ್ರಿಯತೇ ವಾ ಕದಾಚಿನ್,\nನಾಯಂ(ಮ್) ಭೂತ್ವಾ ಭವಿತಾ ವಾ ನ ಭೂಯಃ ಅಜೋ ನಿತ್ಯಃ(ಶ್) ಶಾಶ್ವತೋಽಯಂ(ಮ್) ಪುರಾಣೋ,\nನ ಹನ್ಯತೇ ಹನ್ಯಮಾನೇ ಶರೀರೇ ॥2.20॥",
  
      "ವೇದಾವಿನಾಶಿನಂ(ನ್) ನಿತ್ಯಂ(ಮ್), ಯ ಏನಮಜಮವ್ಯಯಮ್\nಕಥಂ(ಮ್) ಸ ಪುರುಷಃ(ಫ್) ಪಾರ್ಥ, ಕಂ(ಙ್) ಘಾತಯತಿ ಹಂತಿ ಕಮ್ ॥2.21॥",
  
      "ವಾಸಾಂಸಿ ಜೀರ್ಣಾನಿ ಯಥಾ ವಿಹಾಯ,\nನವಾನಿ ಗೃಹ್ಣಾತಿ ನರೋಽಪರಾಣಿ\nತಥಾ ಶರೀರಾಣಿ ವಿಹಾಯ ಜೀರ್ಣಾ\nನ್ಯನ್ಯಾನಿ ಸಂಯಾತಿ ನವಾನಿ ದೇಹೀ ॥2.22॥",
  
      "ನೈನಂ(ಞ್) ಛಿಂದಂತಿ ಶಸ್ತ್ರಾಣಿ, ನೈನಂ(ನ್) ದಹತಿ ಪಾವಕಃ\nನ ಚೈನಂ(ಙ್) ಕ್ಲೇದಯಂತ್ಯಾಪೋ, ನ ಶೋಷಯತಿ ಮಾರುತಃ ॥2.23॥",
  
      "ಅಚ್ಛೇದ್ಯೋಽಯಮದಾಹ್ಯೋಽಯಮ್, ಅಕ್ಲೇದ್ಯೋಽಶೋಷ್ಯ ಏವ ಚ\nನಿತ್ಯಃ(ಸ್) ಸರ್ವಗತಃ(ಸ್) ಸ್ಥಾಣುಃ(ರ್), ಅಚಲೋಽಯಂ(ಮ್) ಸನಾತನಃ ॥2.24॥",
  
      "ಅವ್ಯಕ್ತೋಽಯಮಚಿಂತ್ಯೋಽಯಮ್, ಅವಿಕಾರ್ಯೋಽಯಮುಚ್ಯತೇ \nತಸ್ಮಾದೇವಂ(ವ್) ವಿದಿತ್ವೈನಂ(ನ್), ನಾನುಶೋಚಿತುಮರ್ಹಸಿ ॥2.25॥",
  
      "ಅಥ ಚೈನಂ(ನ್) ನಿತ್ಯಜಾತಂ(ನ್), ನಿತ್ಯಂ(ವ್) ವಾ ಮನ್ಯಸೇ ಮೃತಮ್\nತಥಾಪಿ ತ್ವಂ(ಮ್) ಮಹಾಬಾಹೋ, ನೈವಂ(ಮ್) ಶೋಚಿತುಮರ್ಹಸಿ ॥2.26॥",
  
      "ಜಾತಸ್ಯ ಹಿ ಧ್ರುವೋ ಮೃತ್ಯುಃ(ರ್), ಧ್ರುವಂ(ಞ್) ಜನ್ಮ ಮೃತಸ್ಯ ಚ\nತಸ್ಮಾದಪರಿಹಾರ್ಯೇಽರ್ಥೇ, ನ ತ್ವಂ(ಮ್) ಶೋಚಿತುಮರ್ಹಸಿ ॥2.27॥",
  
      "ಅವ್ಯಕ್ತಾದೀನಿ ಭೂತಾನಿ, ವ್ಯಕ್ತಮಧ್ಯಾನಿ ಭಾರತ\nಅವ್ಯಕ್ತನಿಧನಾನ್ಯೇವ, ತತ್ರ ಕಾ ಪರಿದೇವನಾ ॥2.28॥",
  
      "ಆಶ್ಚರ್ಯವತ್ಪಶ್ಯತಿ ಕಶ್ಚಿದೇನಮ್,\nಆಶ್ಚರ್ಯವದ್ವದತಿ ತಥೈವ ಚಾನ್ಯಃ\nಆಶ್ಚರ್ಯವಚ್ಚೈನಮನ್ಯಃ(ಶ್) ಶೃಣೋತಿ,\nಶ್ರುತ್ವಾಪ್ಯೇನಂ(ವ್) ವೇದ ನ ಚೈವ ಕಶ್ಚಿತ್ ॥2.29॥",
  
      "ದೇಹೀ ನಿತ್ಯಮವಧ್ಯೋಽಯಂ(ನ್), ದೇಹೇ ಸರ್ವಸ್ಯ ಭಾರತ\nತಸ್ಮಾತ್ಸರ್ವಾಣಿ ಭೂತಾನಿ, ನ ತ್ವಂ(ಮ್) ಶೋಚಿತುಮರ್ಹಸಿ ॥2.30॥",
  
      "ಸ್ವಧರ್ಮಮಪಿ ಚಾವೇಕ್ಷ್ಯ, ನ ವಿಕಂಪಿತುಮರ್ಹಸಿ\nಧರ್ಮ್ಯಾದ್ಧಿ ಯುದ್ಧಾಚ್ಛ್ರೇಯೋಽನ್ಯತ್, ಕ್ಷತ್ರಿಯಸ್ಯ ನ ವಿದ್ಯತೇ ॥2.31॥",
  
      "ಯದೃಚ್ಛಯಾ ಚೋಪಪನ್ನಂ(ಮ್), ಸ್ವರ್ಗದ್ವಾರಮಪಾವೃತಮ್\nಸುಖಿನಃ ಕ್ಷತ್ರಿಯಾಃ(ಫ್) ಪಾರ್ಥ, ಲಭಂತೇ ಯುದ್ಧಮೀದೃಶಮ್ ॥2.32॥",
  
      "ಅಥ ಚೇತ್ತ್ವಮಿಮಂ(ನ್) ಧರ್ಮ್ಯಂ(ಮ್), ಸಂಗ್ರಾಮಂ(ನ್) ನ ಕರಿಷ್ಯಸಿ\nತತಃ(ಸ್) ಸ್ವಧರ್ಮಂ(ಙ್) ಕೀರ್ತಿಂ(ಞ್) ಚ, ಹಿತ್ವಾ ಪಾಪಮವಾಪ್ಸ್ಯಸಿ ॥2.33॥",
  
      "ಅಕೀರ್ತಿಂ(ಞ್) ಚಾಪಿ ಭೂತಾನಿ, ಕಥಯಿಷ್ಯಂತಿ ತೇಽವ್ಯಯಾಮ್\nಸಂಭಾವಿತಸ್ಯ ಚಾಕೀರ್ತಿಃ(ರ್), ಮರಣಾದತಿರಿಚ್ಯತೇ ॥2.34॥",
  
      "ಭಯಾದ್ರಣಾದುಪರತಂ(ಮ್), ಮಂಸ್ಯಂತೇ ತ್ವಾಂ(ಮ್) ಮಹಾರಥಾಃ\nಯೇಷಾಂ(ಞ್) ಚ ತ್ವಂ(ಮ್) ಬಹುಮತೋ, ಭೂತ್ವಾ ಯಾಸ್ಯಸಿ ಲಾಘವಮ್ ॥2.35॥",
  
      "ಅವಾಚ್ಯವಾದಾಂಶ್ಚ ಬಹೂನ್, ವದಿಷ್ಯಂತಿ ತವಾಹಿತಾಃ\nನಿಂದಂತಸ್ತವ ಸಾಮರ್ಥ್ಯಂ(ನ್), ತತೋ ದುಃಖತರಂ(ನ್) ನು ಕಿಮ್ ॥2.36॥",
  
      "ಹತೋ ವಾ ಪ್ರಾಪ್ಸ್ಯಸಿ ಸ್ವರ್ಗಂ(ಞ್), ಜಿತ್ವಾ ವಾ ಭೋಕ್ಷ್ಯಸೇ ಮಹೀಮ್\nತಸ್ಮಾದುತ್ತಿಷ್ಠ ಕೌಂತೇಯ, ಯುದ್ಧಾಯ ಕೃತನಿಶ್ಚಯಃ ॥2.37॥",
  
      "ಸುಖದುಃಖೇ ಸಮೇ ಕೃತ್ವಾ, ಲಾಭಾಲಾಭೌ ಜಯಾಜಯೌ\nತತೋ ಯುದ್ಧಾಯ ಯುಜ್ಯಸ್ವ, ನೈವಂ(ಮ್) ಪಾಪಮವಾಪ್ಸ್ಯಸಿ ॥2.38॥",
  
      "ಏಷಾ ತೇಽಭಿಹಿತಾ ಸಾಂಖ್ಯೇ, ಬುದ್ಧಿರ್ಯೋಗೇ ತ್ವಿಮಾಂ(ಮ್) ಶೃಣು\nಬುದ್ಧ್ಯಾ ಯುಕ್ತೋ ಯಯಾ ಪಾರ್ಥ, ಕರ್ಮಬಂಧಂ(ಮ್) ಪ್ರಹಾಸ್ಯಸಿ ॥2.39॥",
  
      "ನೇಹಾಭಿಕ್ರಮನಾಶೋಽಸ್ತಿ, ಪ್ರತ್ಯವಾಯೋ ನ ವಿದ್ಯತೇ\nಸ್ವಲ್ಪಮಪ್ಯಸ್ಯ ಧರ್ಮಸ್ಯ, ತ್ರಾಯತೇ ಮಹತೋ ಭಯಾತ್ ॥2.40॥",
  
      "ವ್ಯವಸಾಯಾತ್ಮಿಕಾ ಬುದ್ಧಿಃ(ರ್), ಏಕೇಹ ಕುರುನಂದನ\nಬಹುಶಾಖಾ ಹ್ಯನಂತಾಶ್ಚ, ಬುದ್ಧಯೋಽವ್ಯವಸಾಯಿನಾಮ್ ॥2.41॥",
  
      "ಯಾಮಿಮಾಂ(ಮ್) ಪುಷ್ಪಿತಾಂ(ವ್) ವಾಚಂ(ಮ್), ಪ್ರವದಂತ್ಯವಿಪಶ್ಚಿತಃ\nವೇದವಾದರತಾಃ(ಫ್) ಪಾರ್ಥ, ನಾನ್ಯದಸ್ತೀತಿ ವಾದಿನಃ ॥2.42॥",
  
      "ಕಾಮಾತ್ಮಾನಃ(ಸ್) ಸ್ವರ್ಗಪರಾ, ಜನ್ಮಕರ್ಮಫಲಪ್ರದಾಮ್\nಕ್ರಿಯಾವಿಶೇಷಬಹುಲಾಂ(ಮ್), ಭೋಗೈಶ್ವರ್ಯಗತಿಂ(ಮ್) ಪ್ರತಿ ॥2.43॥",
  
      "ಭೋಗೈಶ್ವರ್ಯಪ್ರಸಕ್ತಾನಾಂ(ನ್), ತಯಾಪಹೃತಚೇತಸಾಮ್\nವ್ಯವಸಾಯಾತ್ಮಿಕಾ ಬುದ್ಧಿಃ(ಸ್), ಸಮಾಧೌ ನ ವಿಧೀಯತೇ ॥2.44॥",
  
      "ತ್ರೈಗುಣ್ಯವಿಷಯಾ ವೇದಾ, ನಿಸ್ತ್ರೈಗುಣ್ಯೋ ಭವಾರ್ಜುನ\nನಿರ್ದ್ವಂದ್ವೋ ನಿತ್ಯಸತ್ತ್ವಸ್ಥೋ, ನಿರ್ಯೋಗಕ್ಷೇಮ ಆತ್ಮವಾನ್ ॥2.45॥",
  
      "ಯಾವಾನರ್ಥ ಉದಪಾನೇ, ಸರ್ವತಃ(ಸ್) ಸಂಪ್ಲುತೋದಕೇ\nತಾವಾನ್ಸರ್ವೇಷು ವೇದೇಷು, ಬ್ರಾಹ್ಮಣಸ್ಯ ವಿಜಾನತಃ ॥2.46॥",
  
      "ಕರ್ಮಣ್ಯೇವಾಧಿಕಾರಸ್ತೇ, ಮಾ ಫಲೇಷು ಕದಾಚನ\nಮಾ ಕರ್ಮಫಲಹೇತುರ್ಭೂಃ(ರ್), ಮಾ ತೇ ಸಂಗೋಽಸ್ತ್ವಕರ್ಮಣಿ ॥2.47॥",
  
      "ಯೋಗಸ್ಥಃ(ಖ್) ಕುರು ಕರ್ಮಾಣಿ, ಸಂಗಂ(ನ್) ತ್ಯಕ್ತ್ವಾ ಧನಂಜಯ\nಸಿದ್ಧ್ಯಸಿದ್ಧ್ಯೋಃ(ಸ್) ಸಮೋ ಭೂತ್ವಾ, ಸಮತ್ವಂ(ಯ್) ಯೋಗ ಉಚ್ಯತೇ ॥2.48॥",
  
      "ದೂರೇಣ ಹ್ಯವರಂ(ಙ್) ಕರ್ಮ, ಬುದ್ಧಿಯೋಗಾದ್ಧನಂಜಯ\nಬುದ್ಧೌ ಶರಣಮನ್ವಿಚ್ಛ, ಕೃಪಣಾಃ(ಫ್) ಫಲಹೇತವಃ ॥2.49॥",
  
      "ಬುದ್ಧಿಯುಕ್ತೋ ಜಹಾತೀಹ, ಉಭೇ ಸುಕೃತದುಷ್ಕೃತೇ\nತಸ್ಮಾದ್ಯೋಗಾಯ ಯುಜ್ಯಸ್ವ, ಯೋಗಃ(ಖ್) ಕರ್ಮಸು ಕೌಶಲಮ್ ॥2.50॥",
  
      "ಕರ್ಮಜಂ(ಮ್) ಬುದ್ಧಿಯುಕ್ತಾ ಹಿ, ಫಲಂ(ನ್) ತ್ಯಕ್ತ್ವಾ ಮನೀಷಿಣಃ\nಜನ್ಮಬಂಧವಿನಿರ್ಮುಕ್ತಾಃ(ಫ್), ಪದಂ(ಙ್) ಗಚ್ಛಂತ್ಯನಾಮಯಮ್ ॥2.51॥",
  
      "ಯದಾ ತೇ ಮೋಹಕಲಿಲಂ(ಮ್), ಬುದ್ಧಿರ್ವ್ಯತಿತರಿಷ್ಯತಿ\nತದಾ ಗಂತಾಸಿ ನಿರ್ವೇದಂ(ಮ್), ಶ್ರೋತವ್ಯಸ್ಯ ಶ್ರುತಸ್ಯ ಚ ॥2.52॥",
  
      "ಶ್ರುತಿವಿಪ್ರತಿಪನ್ನಾ ತೇ, ಯದಾ ಸ್ಥಾಸ್ಯತಿ ನಿಶ್ಚಲಾ\nಸಮಾಧಾವಚಲಾ ಬುದ್ಧಿ(ಸ್), ಸ್ತದಾ ಯೋಗಮವಾಪ್ಸ್ಯಸಿ ॥2.53॥",
  
      "ಸ್ಥಿತಪ್ರಜ್ಞಸ್ಯ ಕಾ ಭಾಷಾ, ಸಮಾಧಿಸ್ಥಸ್ಯ ಕೇಶವ\nಸ್ಥಿತಧೀಃ(ಖ್) ಕಿಂ(ಮ್) ಪ್ರಭಾಷೇತ, ಕಿಮಾಸೀತ ವ್ರಜೇತ ಕಿಮ್ ॥2.54॥",
  
      "ಪ್ರಜಹಾತಿ ಯದಾ ಕಾಮಾನ್, ಸರ್ವಾನ್ಪಾರ್ಥ ಮನೋಗತಾನ್\nಆತ್ಮನ್ಯೇವಾತ್ಮನಾ ತುಷ್ಟಃ(ಸ್), ಸ್ಥಿತಪ್ರಜ್ಞಸ್ತದೋಚ್ಯತೇ ॥2.55॥",
  
      "ದುಃಖೇಷ್ವನುದ್ವಿಗ್ನಮನಾಃ(ಸ್), ಸುಖೇಷು ವಿಗತಸ್ಪೃಹಃ\nವೀತರಾಗಭಯಕ್ರೋಧಃ(ಸ್), ಸ್ಥಿತಧೀರ್ಮುನಿರುಚ್ಯತೇ ॥2.56॥",
  
      "ಯಃ(ಸ್) ಸರ್ವತ್ರಾನಭಿಸ್ನೇಹಃ(ಸ್), ತತ್ತತ್ಪ್ರಾಪ್ಯ ಶುಭಾಶುಭಮ್\nನಾಭಿನಂದತಿ ನ ದ್ವೇಷ್ಟಿ, ತಸ್ಯ ಪ್ರಜ್ಞಾ ಪ್ರತಿಷ್ಠಿತಾ ॥2.57॥",
  
      "ಯದಾ ಸಂಹರತೇ ಚಾಯಂ(ಙ್), ಕೂರ್ಮೋಽಙ್ಗಾನೀವ ಸರ್ವಶಃ\nಇಂದ್ರಿಯಾಣೀಂದ್ರಿಯಾರ್ಥೇಭ್ಯ(ಸ್), ತಸ್ಯ ಪ್ರಜ್ಞಾ ಪ್ರತಿಷ್ಠಿತಾ ॥2.58॥",
  
      "ವಿಷಯಾ ವಿನಿವರ್ತಂತೇ, ನಿರಾಹಾರಸ್ಯ ದೇಹಿನಃ\nರಸವರ್ಜಂ(ಮ್) ರಸೋಽಪ್ಯಸ್ಯ, ಪರಂ(ನ್) ದೃಷ್ಟ್ವಾ ನಿವರ್ತತೇ ॥2.59॥",
  
      "ಯತತೋ ಹ್ಯಪಿ ಕೌಂತೇಯ, ಪುರುಷಸ್ಯ ವಿಪಶ್ಚಿತಃ\nಇಂದ್ರಿಯಾಣಿ ಪ್ರಮಾಥೀನಿ, ಹರಂತಿ ಪ್ರಸಭ(ಮ್) ಮನಃ ॥2.60॥",
  
      "ತಾನಿ ಸರ್ವಾಣಿ ಸಂಯಮ್ಯ, ಯುಕ್ತ ಆಸೀತ ಮತ್ಪರಃ\nವಶೇ ಹಿ ಯಸ್ಯೇಂದ್ರಿಯಾಣಿ, ತಸ್ಯ ಪ್ರಜ್ಞಾ ಪ್ರತಿಷ್ಠಿತಾ ॥2.61॥",
  
      "ಧ್ಯಾಯತೋ ವಿಷಯಾನ್ಪುಂಸಃ(ಸ್), ಸಂಗಸ್ತೇಷೂಪಜಾಯತೇ\nಸಂಗಾತ್ಸಂಜಾಯತೇ ಕಾಮಃ(ಖ್), ಕಾಮಾತ್ಕ್ರೋಧೋಽಭಿಜಾಯತೇ ॥2.62॥",
  
      "ಕ್ರೋಧಾದ್ಭವತಿ ಸಮ್ಮೋಹಃ(ಸ್), ಸಮ್ಮೋಹಾತ್ಸ್ಮೃತಿವಿಭ್ರಮಃ\nಸ್ಮೃತಿಭ್ರಂಶಾದ್ ಬುದ್ಧಿನಾಶೋ, ಬುದ್ಧಿನಾಶಾತ್ಪ್ರಣಶ್ಯತಿ ॥2.63॥",
  
      "ರಾಗದ್ವೇಷವಿಮುಕ್ತೈಸ್ತು, ವಿಷಯಾನಿಂದ್ರಿಯೈಶ್ಚರನ್\nಆತ್ಮವಶ್ಯೈರ್ವಿಧೇಯಾತ್ಮಾ, ಪ್ರಸಾದಮಧಿಗಚ್ಛತಿ ॥2.64॥",
  
      "ಪ್ರಸಾದೇ ಸರ್ವದುಃಖಾನಾ(ಮ್), ಹಾನಿರಸ್ಯೋಪಜಾಯತೇ\nಪ್ರಸನ್ನಚೇತಸೋ ಹ್ಯಾಶು, ಬುದ್ಧಿಃ(ಫ್) ಪರ್ಯವತಿಷ್ಠತೇ ॥2.65॥",
  
      "ನಾಸ್ತಿ ಬುದ್ಧಿರಯುಕ್ತಸ್ಯ, ನ ಚಾಯುಕ್ತಸ್ಯ ಭಾವನಾ\nನ ಚಾಭಾವಯತಃ(ಶ್) ಶಾಂತಿಃ(ರ್), ಅಶಾಂತಸ್ಯ ಕುತಃ(ಸ್) ಸುಖಮ್ ॥2.66॥",
  
      "ಇಂದ್ರಿಯಾಣಾಂ(ಮ್) ಹಿ ಚರತಾಂ(ಯ್), ಯನ್ಮನೋಽನುವಿಧೀಯತೇ\nತದಸ್ಯ ಹರತಿ ಪ್ರಜ್ಞಾಂ(ವ್), ವಾಯುರ್ನಾವಮಿವಾಂಭಸಿ ॥2.67॥",
  
      "ತಸ್ಮಾದ್ಯಸ್ಯ ಮಹಾಬಾಹೋ, ನಿಗೃಹೀತಾನಿ ಸರ್ವಶಃ\nಇಂದ್ರಿಯಾಣೀಂದ್ರಿಯಾರ್ಥೇಭ್ಯಃ (ಸ್), ತಸ್ಯ ಪ್ರಜ್ಞಾ ಪ್ರತಿಷ್ಠಿತಾ ॥2.68॥",
  
      "ಯಾ ನಿಶಾ ಸರ್ವಭೂತಾನಾಂ(ನ್), ತಸ್ಯಾಂ(ಞ್) ಜಾಗರ್ತಿ ಸಂಯಮೀ\nಯಸ್ಯಾಂ(ಞ್) ಜಾಗ್ರತಿ ಭೂತಾನಿ, ಸಾ ನಿಶಾ ಪಶ್ಯತೋ ಮುನೇಃ ॥2.69॥",
  
      "ಆಪೂರ್ಯಮಾಣಮಚಲಪ್ರತಿಷ್ಠಂ(ಮ್),\nಸಮುದ್ರಮಾಪಃ(ಫ್) ಪ್ರವಿಶಂತಿ ಯದ್ವತ್\nತದ್ವತ್ಕಾಮಾ ಯಂ(ಮ್) ಪ್ರವಿಶಂತಿ ಸರ್ವೇ,\nಸ ಶಾಂತಿಮಾಪ್ನೋತಿ ನ ಕಾಮಕಾಮೀ ॥2.70॥",
  
      "ವಿಹಾಯ ಕಾಮಾನ್ಯಃ(ಸ್) ಸರ್ವಾನ್, ಪುಮಾಂಶ್ಚರತಿ ನಿಃಸ್ಪೃಹಃ\nನಿರ್ಮಮೋ ನಿರಹಂಕಾರಃ(ಸ್), ಸ ಶಾಂತಿಮಧಿಗಚ್ಛತಿ ॥2.71॥",
  
      "ಏಷಾ ಬ್ರಾಹ್ಮೀ ಸ್ಥಿತಿಃ(ಫ್) ಪಾರ್ಥ, ನೈನಾಂ(ಮ್) ಪ್ರಾಪ್ಯ ವಿಮುಹ್ಯತಿ\nಸ್ಥಿತ್ವಾಸ್ಯಾಮಂತಕಾಲೇಽಪಿ, ಬ್ರಹ್ಮನಿರ್ವಾಣಮೃಚ್ಛತಿ ॥2.72॥",
  
      "ಓಂ ತತ್ಸದಿತಿ ಶ್ರೀಮದ್ಭಗವದ್ಗೀತಾಸು ಉಪನಿಷತ್ಸು\nಬ್ರಹ್ಮವಿದ್ಯಾಯಾಂ(ಯ್ ) ಯೋಗಶಾಸ್ತ್ರೇ ಶ್ರೀಕೃಷ್ಣಾರ್ಜುನಸಂವಾದೇ\nಸಾಂಖ್ಯಯೋಗೋ ನಾಮ ದ್ವಿತೀಯೋಽಧ್ಯಾಯಃ ॥2॥"
    ],
    "adhyaya-3" : [
      "ಅರ್ಜುನ ಉವಾಚ\nಜ್ಯಾಯಸೀ ಚೇತ್ಕರ್ಮಣಸ್ತೇ, ಮತಾ ಬುದ್ಧಿರ್ಜನಾರ್ದನ |\nತತ್ಕಿಂ(ಙ್) ಕರ್ಮಣಿ ಘೋರೇ ಮಾಂ(ನ್), ನಿಯೋಜಯಸಿ ಕೇಶವ ॥3.1॥",
  
      "ವ್ಯಾಮಿಶ್ರೇಣೇವ ವಾಕ್ಯೇನ, ಬುದ್ಧಿಂ(ಮ್) ಮೋಹಯಸೀವ ಮೇ |\nತದೇಕಂ(ವ್) ವದ ನಿಶ್ಚಿತ್ಯ, ಯೇನ ಶ್ರೇಯೋಽಹಮಾಪ್ನುಯಾಮ್  ॥3.2॥",
  
      "ಶ್ರೀಭಗವಾನುವಾಚ\nಲೋಕೇಽಸ್ಮಿನ್ ದ್ವಿವಿಧಾ ನಿಷ್ಠಾ, ಪುರಾ ಪ್ರೋಕ್ತಾ ಮಯಾನಘ |\nಜ್ಞಾನಯೋಗೇನ ಸಾಂಖ್ಯಾನಾಂ(ಙ್), ಕರ್ಮಯೋಗೇನ ಯೋಗಿನಾಮ್ ॥3.3॥",
  
      "ನ ಕರ್ಮಣಾಮನಾರಂಭಾನ್, ನೈಷ್ಕರ್ಮ್ಯಂ(ಮ್) ಪುರುಷೋಽಶ್ನುತೇ |\nನ ಚ ಸನ್ನ್ಯಸನಾದೇವ, ಸಿದ್ಧಿಂ(ಮ್) ಸಮಧಿಗಚ್ಛತಿ ॥3.4॥",
  
      "ನ ಹಿ ಕಶ್ಚಿತ್ಕ್ಷಣಮಪಿ, ಜಾತು ತಿಷ್ಠತ್ಯಕರ್ಮಕೃತ್ |\nಕಾರ್ಯತೇ ಹ್ಯವಶಃ(ಖ್) ಕರ್ಮ, ಸರ್ವಃ(ಫ್) ಪ್ರಕೃತಿಜೈರ್ಗುಣೈಃ ॥3.5॥",
  
      "ಕರ್ಮೇಂದ್ರಿಯಾಣಿ ಸಂಯಮ್ಯ, ಯ ಆಸ್ತೇ ಮನಸಾ ಸ್ಮರನ್ |\nಇಂದ್ರಿಯಾರ್ಥಾನ್ವಿಮೂಢಾತ್ಮಾ, ಮಿಥ್ಯಾಚಾರಃ(ಸ್) ಸ ಉಚ್ಯತೇ ॥3.6॥",
  
      "ಯಸ್ತ್ವಿಂದ್ರಿಯಾಣಿ ಮನಸಾ, ನಿಯಮ್ಯಾರಭತೇಽರ್ಜುನ |\nಕರ್ಮೇಂದ್ರಿಯೈಃ(ಖ್) ಕರ್ಮಯೋಗಮ್, ಅಸಕ್ತಃ(ಸ್) ಸ ವಿಶಿಷ್ಯತೇ ॥3.7॥",
  
      "ನಿಯತಂ(ಙ್) ಕುರು ಕರ್ಮ ತ್ವಂ(ಙ್), ಕರ್ಮ ಜ್ಯಾಯೋ ಹ್ಯಕರ್ಮಣಃ |\nಶರೀರಯಾತ್ರಾಪಿ ಚ ತೇ, ನ ಪ್ರಸಿದ್ಧ್ಯೇದಕರ್ಮಣಃ ॥3.8॥",
  
      "ಯಜ್ಞಾರ್ಥಾತ್ಕರ್ಮಣೋಽನ್ಯತ್ರ, ಲೋಕೋಽಯಂ(ಙ್) ಕರ್ಮಬಂಧನಃ |\nತದರ್ಥಂ(ಙ್) ಕರ್ಮ ಕೌಂತೇಯ, ಮುಕ್ತಸಂಗಃ(ಸ್) ಸಮಾಚರ ॥3.9॥",
  
      "ಸಹಯಜ್ಞಾಃ(ಫ್) ಪ್ರಜಾಃ(ಸ್) ಸೃಷ್ಟ್ವಾ, ಪುರೋವಾಚ ಪ್ರಜಾಪತಿಃ |\nಅನೇನ ಪ್ರಸವಿಷ್ಯಧ್ವಮ್, ಏಷ ವೋಽಸ್ತ್ವಿಷ್ಟಕಾಮಧುಕ್ ॥3.10॥",
  
      "ದೇವಾನ್ಭಾವಯತಾನೇನ, ತೇ ದೇವಾ ಭಾವಯಂತು ವಃ |\nಪರಸ್ಪರಂ(ಮ್) ಭಾವಯಂತಃ(ಶ್), ಶ್ರೇಯಃ(ಫ್) ಪರಮವಾಪ್ಸ್ಯಥ ॥3.11॥",
  
      "ಇಷ್ಟಾನ್ಭೋಗಾನ್ಹಿ ವೋ ದೇವಾ, ದಾಸ್ಯಂತೇ ಯಜ್ಞಭಾವಿತಾಃ |\nತೈರ್ದತ್ತಾನಪ್ರದಾಯೈಭ್ಯೋ, ಯೋ ಭುಂಕ್ತೇ ಸ್ತೇನ ಏವ ಸಃ ॥3.12॥",
  
      "ಯಜ್ಞಶಿಷ್ಟಾಶಿನಃ(ಸ್) ಸಂತೋ, ಮುಚ್ಯಂತೇ ಸರ್ವಕಿಲ್ಬಿಷೈಃ |\nಭುಂಜತೇ ತೇ ತ್ವಘಂ(ಮ್) ಪಾಪಾ, ಯೇ ಪಚಂತ್ಯಾತ್ಮಕಾರಣಾತ್ ॥3.13॥",
  
      "ಅನ್ನಾದ್ಭವಂತಿ ಭೂತಾನಿ, ಪರ್ಜನ್ಯಾದನ್ನಸಂಭವಃ |\nಯಜ್ಞಾದ್ಭವತಿ ಪರ್ಜನ್ಯೋ, ಯಜ್ಞಃ(ಖ್) ಕರ್ಮಸಮುದ್ಭವಃ ॥3.14॥",
  
      "ಕರ್ಮ ಬ್ರಹ್ಮೋದ್ಭವಂ(ವ್) ವಿದ್ಧಿ, ಬ್ರಹ್ಮಾಕ್ಷರಸಮುದ್ಭವಮ್ |\nತಸ್ಮಾತ್ಸರ್ವಗತಂ(ಮ್) ಬ್ರಹ್ಮ, ನಿತ್ಯಂ(ಯ್) ಯಜ್ಞೇ ಪ್ರತಿಷ್ಠಿತಮ್ ॥3.15॥",
  
      "ಏವಂ(ಮ್) ಪ್ರವರ್ತಿತಂ(ಞ್) ಚಕ್ರಂ(ನ್), ನಾನುವರ್ತಯತೀಹ ಯಃ |\nಅಘಾಯುರಿಂದ್ರಿಯಾರಾಮೋ, ಮೋಘಂ\n(ಮ್) ಪಾರ್ಥ ಸ ಜೀವತಿ ॥3.16॥",
  
      "ಯಸ್ತ್ವಾತ್ಮರತಿರೇವ ಸ್ಯಾದ್, ಆತ್ಮತೃಪ್ತಶ್ಚ ಮಾನವಃ |\nಆತ್ಮನ್ಯೇವ ಚ \nಸಂತುಷ್ಟಃ(ಸ್), ತಸ್ಯ ಕಾರ್ಯಂ(ನ್) ನ ವಿದ್ಯತೇ ॥3.17॥",
  
      "ನೈವ ತಸ್ಯ ಕೃತೇನಾರ್ಥೋ, ನಾಕೃತೇನೇಹ ಕಶ್ಚನ |\nನ ಚಾಸ್ಯ ಸರ್ವಭೂತೇಷು, ಕಶ್ಚಿದರ್ಥವ್ಯಪಾಶ್ರಯಃ ॥3.18॥",
  
      "ತಸ್ಮಾದಸಕ್ತಃ(ಸ್) ಸತತಂ(ಙ್), ಕಾರ್ಯಂ(ಙ್) ಕರ್ಮ ಸಮಾಚರ |\nಅಸಕ್ತೋ ಹ್ಯಾಚರನ್ಕರ್ಮ, ಪರಮಾಪ್ನೋತಿ \nಪೂರುಷಃ ॥3.19॥",
  
      "ಕರ್ಮಣೈವ ಹಿ ಸಂಸಿದ್ಧಿಮ್, ಆಸ್ಥಿತಾ ಜನಕಾದಯಃ |\nಲೋಕಸಂಗ್ರಹಮೇವಾಪಿ, ಸಂಪಶ್ಯನ್ಕರ್ತುಮರ್ಹಸಿ ॥3.20॥",
  
      "ಯದ್ಯದಾಚರತಿ ಶ್ರೇಷ್ಠಃ(ಸ್), ತತ್ತದೇವೇತರೋ ಜನಃ|\nಸ ಯತ್ಪ್ರಮಾಣಂ(ಙ್) ಕುರುತೇ, ಲೋಕಸ್ತದನುವರ್ತತೇ॥3.21॥",
  
      "ನ ಮೇ ಪಾರ್ಥಾಸ್ತಿ ಕರ್ತವ್ಯಂ(ನ್), ತ್ರಿಷು ಲೋಕೇಷು ಕಿಂಚನ |\nನಾನವಾಪ್ತಮವಾಪ್ತವ್ಯಂ(ವ್), ವರ್ತ ಏವ ಚ ಕರ್ಮಣಿ ॥3.22॥",
  
      "ಯದಿ ಹ್ಯಹಂ(ನ್) ನ ವರ್ತೇಯಂ(ಞ್), ಜಾತು ಕರ್ಮಣ್ಯತಂದ್ರಿತಃ |\nಮಮ ವರ್ತ್ಮಾನುವರ್ತಂತೇ, ಮನುಷ್ಯಾಃ(ಫ್) ಪಾರ್ಥ ಸರ್ವಶಃ ॥3.23॥",
  
      "ಉತ್ಸೀದೇಯುರಿಮೇ ಲೋಕಾ, ನ ಕುರ್ಯಾಂ(ಙ್) ಕರ್ಮ ಚೇದಹಮ್ |\nಸಂಕರಸ್ಯ ಚ ಕರ್ತಾ ಸ್ಯಾಮ್, ಉಪಹನ್ಯಾಮಿಮಾಃ(ಫ್) ಪ್ರಜಾಃ ॥3.24॥",
  
      "ಸಕ್ತಾಃ(ಖ್) ಕರ್ಮಣ್ಯವಿದ್ವಾಂಸೋ, ಯಥಾ ಕುರ್ವಂತಿ ಭಾರತ |\nಕುರ್ಯಾದ್ವಿದ್ವಾಂಸ್ತಥಾಸಕ್ತಃ(ಶ್​), ಚಿಕೀರ್ಷುರ್ಲೋಕಸಂಗ್ರಹಮ್ ॥3.25॥",
  
      "ನ ಬುದ್ಧಿಭೇದಂ(ಞ್) ಜನಯೇದ್, ಅಜ್ಞಾನಾಂ(ಙ್) ಕರ್ಮಸಂಗಿನಾಮ್ |\nಜೋಷಯೇತ್ಸರ್ವಕರ್ಮಾಣಿ, ವಿದ್ವಾನ್ಯುಕ್ತಃ(ಸ್) ಸಮಾಚರನ್ ॥3.26॥",
  
      "ಪ್ರಕೃತೇಃ(ಖ್) ಕ್ರಿಯಮಾಣಾನಿ, ಗುಣೈಃ(ಖ್) ಕರ್ಮಾಣಿ ಸರ್ವಶಃ |\nಅಹಂಕಾರವಿಮೂಢಾತ್ಮಾ, ಕರ್ತಾಹಮಿತಿ ಮನ್ಯತೇ ॥3.27॥",
  
      "ತತ್ತ್ವವಿತ್ತು ಮಹಾಬಾಹೋ, ಗುಣಕರ್ಮವಿಭಾಗಯೋಃ |\nಗುಣಾ ಗುಣೇಷು ವರ್ತಂತ, ಇತಿ ಮತ್ವಾ ನ ಸಜ್ಜತೇ ॥3.28॥",
  
      "ಪ್ರಕೃತೇರ್ಗುಣಸಮ್ಮೂಢಾಃ(ಸ್), ಸಜ್ಜಂತೇ ಗುಣಕರ್ಮಸು |\nತಾನಕೃತ್ಸ್ನವಿದೋ ಮಂದಾನ್, ಕೃತ್ಸ್ನವಿನ್ನ ವಿಚಾಲಯೇತ್ ॥3.29॥",
  
      "ಮಯಿ ಸರ್ವಾಣಿ ಕರ್ಮಾಣಿ, ಸಂನ್ಯಸ್ಯಾಧ್ಯಾತ್ಮಚೇತಸಾ |\nನಿರಾಶೀರ್ನಿರ್ಮಮೋ ಭೂತ್ವಾ, ಯುಧ್ಯಸ್ವ ವಿಗತಜ್ವರಃ ॥3.30॥",
  
      "ಯೇ ಮೇ ಮತಮಿದಂ(ನ್) ನಿತ್ಯಮ್, ಅನುತಿಷ್ಠಂತಿ ಮಾನವಾಃ |\nಶ್ರದ್ಧಾವಂತೋಽನಸೂಯಂತೋ, ಮುಚ್ಯಂತೇ ತೇಽಪಿ ಕರ್ಮಭಿಃ ॥3.31॥",
  
      "ಯೇ ತ್ವೇತದಭ್ಯಸೂಯಂತೋ, ನಾನುತಿಷ್ಠಂತಿ ಮೇ ಮತಮ್ |\nಸರ್ವಜ್ಞಾನವಿಮೂಢಾಂಸ್ತಾನ್, ವಿದ್ಧಿ ನಷ್ಟಾನಚೇತಸಃ ॥3.32॥",
  
      "ಸದೃಶಂ(ಞ್) ಚೇಷ್ಟತೇ ಸ್ವಸ್ಯಾಃ(ಫ್), ಪ್ರಕೃತೇರ್ಜ್ಞಾನವಾನಪಿ |\nಪ್ರಕೃತಿಂ(ಯ್) ಯಾಂತಿ ಭೂತಾನಿ, ನಿಗ್ರಹಃ(ಖ್) ಕಿಂ(ಙ್) ಕರಿಷ್ಯತಿ ॥3.33॥",
  
      "ಇಂದ್ರಿಯಸ್ಯೇಂದ್ರಿಯಸ್ಯಾರ್ಥೇ, ರಾಗದ್ವೇಷೌ ವ್ಯವಸ್ಥಿತೌ |\nತಯೋರ್ನ ವಶಮಾಗಚ್ಛೇತ್, ತೌ ಹ್ಯಸ್ಯ ಪರಿಪಂಥಿನೌ ॥3.34॥",
  
      "ಶ್ರೇಯಾನ್ಸ್ವಧರ್ಮೋ ವಿಗುಣಃ(ಫ್), ಪರಧರ್ಮಾತ್ಸ್ವನುಷ್ಠಿತಾತ್ |\nಸ್ವಧರ್ಮೇ ನಿಧನಂ(ಮ್) ಶ್ರೇಯಃ(ಫ್), ಪರಧರ್ಮೋ ಭಯಾವಹಃ ॥3.35॥",
  
      "ಅರ್ಜುನ ಉವಾಚ\nಅಥ ಕೇನ ಪ್ರಯುಕ್ತೋಽಯಂ(ಮ್), ಪಾಪಂ(ಞ್) ಚರತಿ ಪೂರುಷಃ |\nಅನಿಚ್ಛನ್ನಪಿ ವಾರ್ಷ್ಣೇಯ, ಬಲಾದಿವ ನಿಯೋಜಿತಃ ॥3.36॥",
  
      "ಶ್ರೀಭಗವಾನುವಾಚ\nಕಾಮ ಏಷ ಕ್ರೋಧ ಏಷ, ರಜೋಗುಣಸಮುದ್ಭವಃ |\nಮಹಾಶನೋ ಮಹಾಪಾಪ್ಮಾ, ವಿದ್ಧ್ಯೇನಮಿಹ ವೈರಿಣಮ್ ॥3.37॥",
  
      "ಧೂಮೇನಾವ್ರಿಯತೇ ವಹ್ನಿಃ(ರ್), ಯಥಾದರ್ಶೋ ಮಲೇನ ಚ |\nಯಥೋಲ್ಬೇನಾವೃತೋ ಗರ್ಭಃ(ಸ್), ತಥಾ ತೇನೇದಮಾವೃತಮ್ ॥3.38॥",
  
      "ಆವೃತಂ(ಞ್) ಜ್ಞಾನಮೇತೇನ, ಜ್ಞಾನಿನೋ ನಿತ್ಯವೈರಿಣಾ |\nಕಾಮರೂಪೇಣ ಕೌಂತೇಯ, ದುಷ್ಪೂರೇಣಾನಲೇನ ಚ ॥3.39॥",
  
      "ಇಂದ್ರಿಯಾಣಿ ಮನೋ ಬುದ್ಧಿಃ(ರ್), ಅಸ್ಯಾಧಿಷ್ಠಾನಮುಚ್ಯತೇ |\nಏತೈರ್ವಿಮೋಹಯತ್ಯೇಷ, ಜ್ಞಾನಮಾವೃತ್ಯ ದೇಹಿನಮ್ ॥3.40॥",
  
      "ತಸ್ಮಾತ್ತ್ವಮಿಂದ್ರಿಯಾಣ್ಯಾದೌ, ನಿಯಮ್ಯ ಭರತರ್ಷಭ |\nಪಾಪ್ಮಾನಂ(ಮ್) ಪ್ರಜಹಿ ಹ್ಯೇನಂ(ಞ್), ಜ್ಞಾನವಿಜ್ಞಾನನಾಶನಮ್ ॥3.41॥",
  
      "ಇಂದ್ರಿಯಾಣಿ ಪರಾಣ್ಯಾಹುಃ(ರ್), ಇಂದ್ರಿಯೇಭ್ಯಃ(ಫ್) ಪರಂ(ಮ್) ಮನಃ |\nಮನಸಸ್ತು ಪರಾ ಬುದ್ಧಿಃ(ರ್), ಯೋ ಬುದ್ಧೇಃ(ಫ್) ಪರತಸ್ತು ಸಃ ॥3.42॥",
  
      "ಏವಂ(ಮ್) ಬುದ್ಧೇಃ(ಫ್) ಪರಂ(ಮ್) ಬುದ್ಧ್ವಾ, ಸಂಸ್ತಭ್ಯಾತ್ಮಾನಮಾತ್ಮನಾ |\nಜಹಿ ಶತ್ರುಂ(ಮ್) ಮಹಾಬಾಹೋ, ಕಾಮರೂಪಂ(ನ್) ದುರಾಸದಮ್ ॥3.43॥",
  
      "ಓಂ ತತ್ಸದಿತಿ ಶ್ರೀಮದ್ಭಗವದ್ಗೀತಾಸು ಉಪನಿಷತ್ಸು\nಬ್ರಹ್ಮವಿದ್ಯಾಯಾಂ(ಯ್) ಯೋಗಶಾಸ್ತ್ರೇ ಶ್ರೀಕೃಷ್ಣಾರ್ಜುನಸಂವಾದೇ\nಕರ್ಮಯೋಗೋ ನಾಮ ತೃತೀಯೋಽಧ್ಯಾಯಃ ॥3॥"
    ],
    "adhyaya-4" : [
      "ಶ್ರೀಭಗವಾನುವಾಚ\nಇಮಂ(ಮ್) ವಿವಸ್ವತೇ ಯೋಗಂ(ಮ್), ಪ್ರೋಕ್ತವಾನಹಮವ್ಯಯಮ್\nವಿವಸ್ವಾನ್ಮನವೇ ಪ್ರಾಹ, ಮನುರಿಕ್ಷ್ವಾಕವೇಽಬ್ರವೀತ್ ॥4.1॥",
  
      "ಏವಂ(ಮ್) ಪರಂಪರಾಪ್ರಾಪ್ತಮ್, ಇಮಂ(ಮ್) ರಾಜರ್ಷಯೋ ವಿದುಃ\nಸ ಕಾಲೇನೇಹ ಮಹತಾ, ಯೋಗೋ ನಷ್ಟಃ(ಫ್) ಪರಂತಪ ॥4.2॥",
  
      "ಸ ಏವಾಯಂ(ಮ್) ಮಯಾ ತೇಽದ್ಯ, ಯೋಗಃ(ಫ್) ಪ್ರೋಕ್ತಃ(ಫ್) ಪುರಾತನಃ\nಭಕ್ತೋಽಸಿ ಮೇ ಸಖಾ ಚೇತಿ, ರಹಸ್ಯಂ(ಮ್) ಹ್ಯೇತದುತ್ತಮಮ್ ॥4.3॥",
  
      "ಅರ್ಜುನ ಉವಾಚ\nಅಪರಂ(ಮ್) ಭವತೋ ಜನ್ಮ, ಪರಂ(ಞ್) ಜನ್ಮ ವಿವಸ್ವತಃ\nಕಥಮೇತದ್ವಿಜಾನೀಯಾಂ(ನ್), ತ್ವಮಾದೌ ಪ್ರೋಕ್ತವಾನಿತಿ ॥4.4॥",
  
      "ಶ್ರೀಭಗವಾನುವಾಚ\nಬಹೂನಿ ಮೇ ವ್ಯತೀತಾನಿ, ಜನ್ಮಾನಿ ತವ ಚಾರ್ಜುನ\nತಾನ್ಯಹಂ(ಮ್) ವೇದ ಸರ್ವಾಣಿ, ನ ತ್ವಂ(ಮ್) ವೇತ್ಥ ಪರಂತಪ ॥4.5॥",
  
      "ಅಜೋಽಪಿ ಸನ್ನವ್ಯಯಾತ್ಮಾ, ಭೂತಾನಾಮೀಶ್ವರೋಽಪಿ ಸನ್\nಪ್ರಕೃತಿಂ(ಮ್) ಸ್ವಾಮಧಿಷ್ಠಾಯ, ಸಂಭವಾಮ್ಯಾತ್ಮಮಾಯಯಾ ॥4.6॥",
  
      "ಯದಾ ಯದಾ ಹಿ ಧರ್ಮಸ್ಯ, ಗ್ಲಾನಿರ್ಭವತಿ ಭಾರತ\nಅಭ್ಯುತ್ಥಾನಮಧರ್ಮಸ್ಯ, ತದಾತ್ಮಾನಂ(ಮ್) ಸೃಜಾಮ್ಯಹಮ್ ॥4.7॥",
  
      "ಪರಿತ್ರಾಣಾಯ ಸಾಧೂನಾಂ(ಮ್), ವಿನಾಶಾಯ ಚ ದುಷ್ಕೃತಾಮ್\nಧರ್ಮಸಂಸ್ಥಾಪನಾರ್ಥಾಯ, ಸಂಭವಾಮಿ ಯುಗೇ ಯುಗೇ ॥4.8॥",
  
      "ಜನ್ಮ ಕರ್ಮ ಚ ಮೇ ದಿವ್ಯಮ್, ಏವಂ(ಮ್) ಯೋ ವೇತ್ತಿ ತತ್ತ್ವತಃ\nತ್ಯಕ್ತ್ವಾ ದೇಹಂ(ಮ್) ಪುನರ್ಜನ್ಮ, ನೈತಿ ಮಾಮೇತಿ ಸೋಽರ್ಜುನ ॥4.9॥",
  
      "ವೀತರಾಗಭಯಕ್ರೋಧಾ, ಮನ್ಮಯಾ ಮಾಮುಪಾಶ್ರಿತಾಃ\nಬಹವೋ ಜ್ಞಾನತಪಸಾ, ಪೂತಾ ಮದ್ಭಾವಮಾಗತಾಃ ॥4.10॥",
  
      "ಯೇ ಯಥಾ ಮಾಂ(ಮ್) ಪ್ರಪದ್ಯಂತೇ, ತಾಂಸ್ತಥೈವ ಭಜಾಮ್ಯಹಮ್\nಮಮ ವರ್ತ್ಮಾನುವರ್ತಂತೇ, ಮನುಷ್ಯಾಃ(ಫ್) ಪಾರ್ಥ ಸರ್ವಶಃ ॥4.11॥",
  
      "ಕಾಂಕ್ಷಂತಃ(ಖ್) ಕರ್ಮಣಾಂ(ಮ್) ಸಿದ್ಧಿಂ(ಮ್), ಯಜಂತ ಇಹ ದೇವತಾಃ\nಕ್ಷಿಪ್ರಂ(ಮ್) ಹಿ ಮಾನುಷೇ ಲೋಕೇ, ಸಿದ್ಧಿರ್ಭವತಿ ಕರ್ಮಜಾ ॥4.12॥",
  
      "ಚಾತುರ್ವರ್ಣ್ಯಂ(ಮ್) ಮಯಾ ಸೃಷ್ಟಂ(ಙ್), ಗುಣಕರ್ಮವಿಭಾಗಶಃ\nತಸ್ಯ ಕರ್ತಾರಮಪಿ ಮಾಂ(ಮ್), ವಿದ್ಧ್ಯಕರ್ತಾರಮವ್ಯಯಮ್ ॥4.13॥",
  
      "ನ ಮಾಂ(ಙ್) ಕರ್ಮಾಣಿ ಲಿಂಪಂತಿ, ನ ಮೇ ಕರ್ಮಫಲೇ ಸ್ಪೃಹಾ\nಇತಿ ಮಾಂ(ಮ್) ಯೋಽಭಿಜಾನಾತಿ, ಕರ್ಮಭಿರ್ನ ಸ ಬಧ್ಯತೇ ॥4.14॥",
  
      "ಏವಂ(ಞ್) ಜ್ಞಾತ್ವಾ ಕೃತಂ(ಙ್) ಕರ್ಮ, ಪೂರ್ವೈರಪಿ ಮುಮುಕ್ಷುಭಿಃ\nಕುರು ಕರ್ಮೈವ ತಸ್ಮಾತ್ತ್ವಂ(ಮ್), ಪೂರ್ವೈಃ(ಫ್) ಪೂರ್ವತರಂ(ಙ್) ಕೃತಮ್ ॥4.15॥",
  
      "ಕಿಂ(ಙ್) ಕರ್ಮ ಕಿಮಕರ್ಮೇತಿ, ಕವಯೋಽಪ್ಯತ್ರ ಮೋಹಿತಾಃ\nತತ್ತೇ ಕರ್ಮ ಪ್ರವಕ್ಷ್ಯಾಮಿ, ಯಜ್ಜ್ಞಾತ್ವಾ ಮೋಕ್ಷ್ಯಸೇಽಶುಭಾತ್ ॥4.16॥",
  
      "ಕರ್ಮಣೋ ಹ್ಯಪಿ ಬೋದ್ಧವ್ಯಂ(ಮ್), ಬೋದ್ಧವ್ಯಂ(ಞ್) ಚ ವಿಕರ್ಮಣಃ\nಅಕರ್ಮಣಶ್ಚ ಬೋದ್ಧವ್ಯಂ(ಙ್), ಗಹನಾ ಕರ್ಮಣೋ ಗತಿಃ ॥4.17॥",
  
      "ಕರ್ಮಣ್ಯಕರ್ಮ ಯಃ(ಫ್) ಪಶ್ಯೇದ್, ಅಕರ್ಮಣಿ ಚ ಕರ್ಮ ಯಃ\nಸ ಬುದ್ಧಿಮಾನ್ಮನುಷ್ಯೇಷು, ಸ ಯುಕ್ತಃ(ಖ್) ಕೃತ್ಸ್ನಕರ್ಮಕೃತ್ ॥4.18॥",
  
      "ಯಸ್ಯ ಸರ್ವೇ ಸಮಾರಂಭಾಃ(ಖ್), ಕಾಮಸಂಕಲ್ಪವರ್ಜಿತಾಃ\nಜ್ಞಾನಾಗ್ನಿದಗ್ಧಕರ್ಮಾಣಂ(ನ್), ತಮಾಹುಃ(ಫ್) ಪಂಡಿತಂ(ಮ್) ಬುಧಾಃ 4.॥19॥",
  
      "ತ್ಯಕ್ತ್ವಾ ಕರ್ಮಫಲಾಸಂಗಂ(ನ್), ನಿತ್ಯತೃಪ್ತೋ ನಿರಾಶ್ರಯಃ\nಕರ್ಮಣ್ಯಭಿಪ್ರವೃತ್ತೋಽಪಿ, ನೈವ ಕಿಂಚಿತ್ಕರೋತಿ ಸಃ ॥4.20॥",
  
      "ನಿರಾಶೀರ್ಯತಚಿತ್ತಾತ್ಮಾ, ತ್ಯಕ್ತಸರ್ವಪರಿಗ್ರಹಃ\nಶಾರೀರಂ(ಙ್) ಕೇವಲಂ(ಙ್) ಕರ್ಮ, ಕುರ್ವನ್ನಾಪ್ನೋತಿ ಕಿಲ್ಬಿಷಮ್ 4.॥21॥",
  
      "ಯದೃಚ್ಛಾಲಾಭಸಂತುಷ್ಟೋ, ದ್ವಂದ್ವಾತೀತೋ ವಿಮತ್ಸರಃ\nಸಮಃ(ಸ್) ಸಿದ್ಧಾವಸಿದ್ಧೌ ಚ, ಕೃತ್ವಾಪಿ ನ ನಿಬಧ್ಯತೇ ॥4.22॥",
  
      "ಗತಸಂಗಸ್ಯ ಮುಕ್ತಸ್ಯ, ಜ್ಞಾನಾವಸ್ಥಿತಚೇತಸಃ\nಯಜ್ಞಾಯಾಚರತಃ(ಖ್) ಕರ್ಮ, ಸಮಗ್ರಂ(ಮ್) ಪ್ರವಿಲೀಯತೇ ॥4.23॥",
  
      "ಬ್ರಹ್ಮಾರ್ಪಣಂ(ಮ್) ಬ್ರಹ್ಮ ಹವಿಃ(ರ್), ಬ್ರಹ್ಮಾಗ್ನೌ ಬ್ರಹ್ಮಣಾ ಹುತಮ್\nಬ್ರಹ್ಮೈವ ತೇನ ಗಂತವ್ಯಂ(ಮ್), ಬ್ರಹ್ಮಕರ್ಮಸಮಾಧಿನಾ 4.॥24॥",
  
      "ದೈವಮೇವಾಪರೇ ಯಜ್ಞಂ(ಮ್), ಯೋಗಿನಃ(ಫ್) ಪರ್ಯುಪಾಸತೇ\nಬ್ರಹ್ಮಾಗ್ನಾವಪರೇ ಯಜ್ಞಂ(ಮ್), ಯಜ್ಞೇನೈವೋಪಜುಹ್ವತಿ ॥4.25॥",
  
      "ಶ್ರೋತ್ರಾದೀನೀಂದ್ರಿಯಾಣ್ಯನ್ಯೇ, ಸಂಯಮಾಗ್ನಿಷು ಜುಹ್ವತಿ\nಶಬ್ದಾದೀನ್ವಿಷಯಾನನ್ಯ, ಇಂದ್ರಿಯಾಗ್ನಿಷು ಜುಹ್ವತಿ ॥4.26॥",
  
      "ಸರ್ವಾಣೀಂದ್ರಿಯಕರ್ಮಾಣಿ, ಪ್ರಾಣಕರ್ಮಾಣಿ ಚಾಪರೇ\nಆತ್ಮಸಂಯಮಯೋಗಾಗ್ನೌ, ಜುಹ್ವತಿ ಜ್ಞಾನದೀಪಿತೇ ॥4.27॥",
  
      "ದ್ರವ್ಯಯಜ್ಞಾಸ್ತಪೋಯಜ್ಞಾ, ಯೋಗಯಜ್ಞಾಸ್ತಥಾಪರೇ\nಸ್ವಾಧ್ಯಾಯಜ್ಞಾನಯಜ್ಞಾಶ್ಚ, ಯತಯಃ(ಸ್) ಸಂಶಿತವ್ರತಾಃ ॥4.28॥",
  
      "ಅಪಾನೇ ಜುಹ್ವತಿ ಪ್ರಾಣಂ(ಮ್), ಪ್ರಾಣೇಽಪಾನಂ(ನ್) ತಥಾಪರೇ\nಪ್ರಾಣಾಪಾನಗತೀ ರುದ್ಧ್ವಾ, ಪ್ರಾಣಾಯಾಮಪರಾಯಣಾಃ ॥4.29॥",
  
      "ಅಪರೇ ನಿಯತಾಹಾರಾಃ(ಫ್), ಪ್ರಾಣಾನ್ಪ್ರಾಣೇಷು ಜುಹ್ವತಿ\nಸರ್ವೇಽಪ್ಯೇತೇ ಯಜ್ಞವಿದೋ, ಯಜ್ಞಕ್ಷಪಿತಕಲ್ಮಷಾಃ ॥4.30॥",
  
      "ಯಜ್ಞಶಿಷ್ಟಾಮೃತಭುಜೋ, ಯಾಂತಿ ಬ್ರಹ್ಮ ಸನಾತನಮ್\nನಾಯಂ(ಮ್) ಲೋಕೋಽಸ್ತ್ಯಯಜ್ಞಸ್ಯ, ಕುತೋಽನ್ಯಃ(ಖ್) ಕುರುಸತ್ತಮ॥4.31॥",
  
      "ಏವಂ(ಮ್) ಬಹುವಿಧಾ ಯಜ್ಞಾ, ವಿತತಾ ಬ್ರಹ್ಮಣೋ ಮುಖೇ\nಕರ್ಮಜಾನ್ವಿದ್ಧಿ ತಾನ್ಸರ್ವಾನ್, ಏವಂ(ಞ್) ಜ್ಞಾತ್ವಾ ವಿಮೋಕ್ಷ್ಯಸೇ ॥4.32॥",
  
      "ಶ್ರೇಯಾಂದ್ರವ್ಯಮಯಾದ್ಯಜ್ಞಾಜ್, ಜ್ಞಾನಯಜ್ಞಃ(ಫ್) ಪರಂತಪ\nಸರ್ವಂ(ಙ್) ಕರ್ಮಾಖಿಲಂ(ಮ್) ಪಾರ್ಥ, ಜ್ಞಾನೇ ಪರಿಸಮಾಪ್ಯತೇ ॥4.33॥",
  
      "ತದ್ವಿದ್ಧಿ ಪ್ರಣಿಪಾತೇನ, ಪರಿಪ್ರಶ್ನೇನ ಸೇವಯಾ\nಉಪದೇಕ್ಷ್ಯಂತಿ ತೇ ಜ್ಞಾನಂ(ಞ್), ಜ್ಞಾನಿನಸ್ತತ್ತ್ವದರ್ಶಿನಃ ॥4.34॥",
  
      "ಯಜ್.ಜ್ಞಾತ್ವಾ ನ ಪುನರ್ಮೋಹಮ್, ಏವಂ(ಮ್) ಯಾಸ್ಯಸಿ ಪಾಂಡವ\nಯೇನ ಭೂತಾನ್ಯಶೇಷೇಣ, ದ್ರಕ್ಷ್ಯಸ್ಯಾತ್ಮನ್ಯಥೋ ಮಯಿ ॥4.35॥",
  
      "ಅಪಿ ಚೇದಸಿ ಪಾಪೇಭ್ಯಃ(ಸ್), ಸರ್ವೇಭ್ಯಃ(ಫ್) ಪಾಪಕೃತ್ತಮಃ\nಸರ್ವಂ(ಞ್) ಜ್ಞಾನಪ್ಲವೇನೈವ, ವೃಜಿನಂ(ಮ್) ಸಂತರಿಷ್ಯಸಿ ॥4.36॥",
  
      "ಯಥೈಧಾಂಸಿ ಸಮಿದ್ಧೋಽಗ್ನಿಃ(ರ್), ಭಸ್ಮಸಾತ್ಕುರುತೇಽರ್ಜುನ\nಜ್ಞಾನಾಗ್ನಿಃ(ಸ್) ಸರ್ವಕರ್ಮಾಣಿ, ಭಸ್ಮಸಾತ್ಕುರುತೇ ತಥಾ ॥4.37॥",
  
      "ನ ಹಿ ಜ್ಞಾನೇನ ಸದೃಶಂ(ಮ್), ಪವಿತ್ರಮಿಹ ವಿದ್ಯತೇ\nತತ್ಸ್ವಯಂ(ಮ್) ಯೋಗಸಂಸಿದ್ಧಃ(ಖ್), ಕಾಲೇನಾತ್ಮನಿ ವಿಂದತಿ ॥4.38॥",
  
      "ಶ್ರದ್ಧಾವಾಁಲ್ಲಭತೇ ಜ್ಞಾನಂ(ನ್), ತತ್ಪರಃ(ಸ್) ಸಂಯತೇಂದ್ರಿಯಃ\nಜ್ಞಾನಂ(ಮ್) ಲಬ್ಧ್ವಾ ಪರಾಂ(ಮ್) ಶಾಂತಿಮ್, ಅಚಿರೇಣಾಧಿಗಚ್ಛತಿ ॥4.39॥",
  
      "ಅಜ್ಞಶ್ಚಾಶ್ರದ್ದಧಾನಶ್ಚ, ಸಂಶಯಾತ್ಮಾ ವಿನಶ್ಯತಿ\nನಾಯಂ(ಮ್) ಲೋಕೋಽಸ್ತಿ ನ ಪರೋ, ನ ಸುಖಂ(ಮ್) ಸಂಶಯಾತ್ಮನಃ॥4.40॥",
  
      "ಯೋಗಸಂನ್ಯಸ್ತಕರ್ಮಾಣಂ(ಞ್), ಜ್ಞಾನಸಂಛಿನ್ನಸಂಶಯಮ್\nಆತ್ಮವಂತಂ(ನ್) ನ ಕರ್ಮಾಣಿ, ನಿಬಧ್ನಂತಿ ಧನಂಜಯ ॥4.41॥",
  
      "ತಸ್ಮಾದಜ್ಞಾನಸಂಭೂತಂ(ಮ್), ಹೃತ್ಸ್ಥಂ(ಞ್) ಜ್ಞಾನಾಸಿನಾತ್ಮನಃ\nಛಿತ್ತ್ವೈನಂ(ಮ್) ಸಂಶಯಂ(ಮ್) ಯೋಗಮ್, ಆತಿಷ್ಠೋತ್ತಿಷ್ಠ ಭಾರತ ॥4.42॥",
  
      "ಓಂ ತತ್ಸದಿತಿ ಶ್ರೀಮದ್ಭಗವದ್ಗೀತಾಸು ಉಪನಿಷತ್ಸು ಬ್ರಹ್ಮವಿದ್ಯಾಯಾಂ(ಮ್) ಯೋಗಶಾಸ್ತ್ರೇ\nಶ್ರೀಕೃಷ್ಣಾರ್ಜುನಸಂವಾದೇ ಜ್ಞಾನಕರ್ಮಸಂನ್ಯಾಸಯೋಗೋ ನಾಮ ಚತುರ್ಥೋಽಧ್ಯಾಯಃ ।।4।।"
    ],
    "adhyaya-5" : [
      "ಅರ್ಜುನ ಉವಾಚ\nಸಂನ್ಯಾಸಂ(ಙ್) ಕರ್ಮಣಾಂ(ಙ್) ಕೃಷ್ಣ, ಪುನರ್ಯೋಗಂ(ಞ್) ಚ ಶಂಸಸಿ |\nಯಚ್ಛ್ರೇಯ ಏತಯೋರೇಕಂ(ನ್), ತನ್ಮೇ ಬ್ರೂಹಿ ಸುನಿಶ್ಚಿತಮ್  ।।5.1।।",
  
      "ಶ್ರೀಭಗವಾನುವಾಚ\nಸಂನ್ಯಾಸಃ(ಖ್) ಕರ್ಮಯೋಗಶ್ಚ, ನಿಃಶ್ರೇಯಸಕರಾವುಭೌ |\nತಯೋಸ್ತು ಕರ್ಮಸಂನ್ಯಾಸಾತ್, ಕರ್ಮಯೋಗೋ ವಿಶಿಷ್ಯತೇ  ।।5.2।।",
  
      "ಜ್ಞೇಯಃ(ಸ್) ಸ ನಿತ್ಯಸಂನ್ಯಾಸೀ, ಯೋ ನ ದ್ವೇಷ್ಟಿ ನ ಕಾಂಕ್ಷತಿ |\nನಿರ್ದ್ವಂದ್ವೋ ಹಿ ಮಹಾಬಾಹೋ, ಸುಖಂ(ಮ್) ಬಂಧಾತ್ಪ್ರಮುಚ್ಯತೇ  ।।5.3।।",
  
      "ಸಾಂಖ್ಯಯೋಗೌ ಪೃಥಗ್ಬಾಲಾಃ(ಫ್), ಪ್ರವದಂತಿ ನ ಪಂಡಿತಾಃ |\nಏಕಮಪ್ಯಾಸ್ಥಿತಃ(ಸ್) ಸಮ್ಯಗ್, ಉಭಯೋರ್ವಿಂದತೇ ಫಲಮ್ ।।5.4।।",
  
      "ಯತ್ಸಾಂಖ್ಯೈಃ(ಫ್) ಪ್ರಾಪ್ಯತೇ ಸ್ಥಾನಂ(ನ್), ತದ್ಯೋಗೈರಪಿ ಗಮ್ಯತೇ |\nಏಕಂ(ಮ್) ಸಾಂಖ್ಯಂ(ಞ್) ಚ ಯೋಗಂ(ಞ್) ಚ, ಯಃ(ಫ್) ಪಶ್ಯತಿ ಸ ಪಶ್ಯತಿ  ।।5.5।।",
  
      "ಸಂನ್ಯಾಸಸ್ತು ಮಹಾಬಾಹೋ, ದುಃಖಮಾಪ್ತುಮಯೋಗತಃ |\nಯೋಗಯುಕ್ತೋ ಮುನಿರ್ಬ್ರಹ್ಮ, ನಚಿರೇಣಾಧಿಗಚ್ಛತಿ  ।।5.6।।",
  
      "ಯೋಗಯುಕ್ತೋ ವಿಶುದ್ಧಾತ್ಮಾ, ವಿಜಿತಾತ್ಮಾ ಜಿತೇಂದ್ರಿಯಃ |\nಸರ್ವಭೂತಾತ್ಮಭೂತಾತ್ಮಾ, ಕುರ್ವನ್ನಪಿ ನ ಲಿಪ್ಯತೇ  ।।5.7।।",
  
      "ನೈವ ಕಿಂಚಿತ್ಕರೋಮೀತಿ, ಯುಕ್ತೋ ಮನ್ಯೇತ ತತ್ತ್ವವಿತ್ |\nಪಶ್ಯಞ್ಶೃಣ್ವನ್ಸ್ಪೃಶಂಜಿಘ್ರನ್, ನಶ್ನನ್ಗಚ್ಛನ್ಸ್ವಪಞ್ಶ್ವಸನ್  ।।5.8।।",
  
      "ಪ್ರಲಪನ್ವಿಸೃಜನ್ಗೃಹ್ಣನ್, ನುನ್ಮಿಷನ್ನಿಮಿಷನ್ನಪಿ |\nಇಂದ್ರಿಯಾಣೀಂದ್ರಿಯಾರ್ಥೇಷು, ವರ್ತಂತ ಇತಿ ಧಾರಯನ್  ।।5.9।।",
  
      "ಬ್ರಹ್ಮಣ್ಯಾಧಾಯ ಕರ್ಮಾಣಿ, ಸಂಗಂ(ನ್) ತ್ಯಕ್ತ್ವಾ ಕರೋತಿ ಯಃ |\nಲಿಪ್ಯತೇ ನ ಸ ಪಾಪೇನ, ಪದ್ಮಪತ್ರಮಿವಾಂಭಸಾ ।।5.10।।",
  
      "ಕಾಯೇನ ಮನಸಾ ಬುದ್ಧ್ಯಾ, ಕೇವಲೈರಿಂದ್ರಿಯೈರಪಿ |\nಯೋಗಿನಃ(ಖ್) ಕರ್ಮ ಕುರ್ವಂತಿ, ಸಂಗಂ(ನ್) ತ್ಯಕ್ತ್ವಾತ್ಮಶುದ್ಧಯೇ  ।।5.11।।",
  
      "ಯುಕ್ತಃ(ಖ್) ಕರ್ಮಫಲಂ(ನ್) ತ್ಯಕ್ತ್ವಾ, ಶಾಂತಿಮಾಪ್ನೋತಿ ನೈಷ್ಠಿಕೀಮ್ |\nಅಯುಕ್ತಃ(ಖ್) ಕಾಮಕಾರೇಣ, ಫಲೇ ಸಕ್ತೋ ನಿಬಧ್ಯತೇ ।5.।12।।",
  
      "ಸರ್ವಕರ್ಮಾಣಿ ಮನಸಾ, ಸಂನ್ಯಸ್ಯಾಸ್ತೇ ಸುಖಂ(ಮ್) ವಶೀ |\nನವದ್ವಾರೇ ಪುರೇ ದೇಹೀ, ನೈವ ಕುರ್ವನ್ನ ಕಾರಯನ್  ।।5.13।।",
  
      "ನ ಕರ್ತೃತ್ವಂ(ನ್) ನ ಕರ್ಮಾಣಿ, ಲೋಕಸ್ಯ ಸೃಜತಿ ಪ್ರಭುಃ |\nನ ಕರ್ಮಫಲಸಂಯೋಗಂ(ಮ್), ಸ್ವಭಾವಸ್ತು ಪ್ರವರ್ತತೇ  ।।5.14।।",
  
      "ನಾದತ್ತೇ ಕಸ್ಯಚಿತ್ಪಾಪಂ(ನ್), ನ ಚೈವ ಸುಕೃತಂ(ಮ್) ವಿಭುಃ |\nಅಜ್ಞಾನೇನಾವೃತಂ(ಞ್) ಜ್ಞಾನಂ(ನ್), ತೇನ ಮುಹ್ಯಂತಿ ಜಂತವಃ  ।।5.15।।",
  
      "ಜ್ಞಾನೇನ ತು ತದಜ್ಞಾನಂ(ಮ್), ಯೇಷಾಂ(ನ್) ನಾಶಿತಮಾತ್ಮನಃ |\nತೇಷಾಮಾದಿತ್ಯವಜ್ಜ್ಞಾನಂ(ಮ್), ಪ್ರಕಾಶಯತಿ ತತ್ಪರಮ್  ||5.16||",
  
      "ತದ್ಬುದ್ಧಯಸ್ತದಾತ್ಮಾನಃ(ಸ್), ತನ್ನಿಷ್ಠಾಸ್ತತ್ಪರಾಯಣಾಃ |\nಗಚ್ಛಂತ್ಯಪುನರಾವೃತ್ತಿಂ(ಞ್), ಜ್ಞಾನನಿರ್ಧೂತಕಲ್ಮಷಾಃ ৷৷5.17৷৷",
  
      "ವಿದ್ಯಾವಿನಯಸಂಪನ್ನೇ, ಬ್ರಾಹ್ಮಣೇ ಗವಿ ಹಸ್ತಿನಿ |\nಶುನಿ ಚೈವ ಶ್ವಪಾಕೇ ಚ, ಪಂಡಿತಾಃ(ಸ್) ಸಮದರ್ಶಿನಃ ৷৷5.18৷৷",
  
      "ಇಹೈವ ತೈರ್ಜಿತಃ(ಸ್) ಸರ್ಗೋ, ಯೇಷಾಂ(ಮ್) ಸಾಮ್ಯೇ ಸ್ಥಿತಂ(ಮ್) ಮನಃ |\nನಿರ್ದೋಷಂ(ಮ್) ಹಿ ಸಮಂ(ಮ್) ಬ್ರಹ್ಮ, ತಸ್ಮಾದ್ ಬ್ರಹ್ಮಣಿ ತೇ ಸ್ಥಿತಾಃ ৷৷5.19৷৷",
  
      "ನ ಪ್ರಹೃಷ್ಯೇತ್ಪ್ರಿಯಂ(ಮ್) ಪ್ರಾಪ್ಯ, ನೋದ್ವಿಜೇತ್ಪ್ರಾಪ್ಯ ಚಾಪ್ರಿಯಮ್ |\nಸ್ಥಿರಬುದ್ಧಿರಸಮ್ಮೂಢೋ, ಬ್ರಹ್ಮವಿದ್ ಬ್ರಹ್ಮಣಿ ಸ್ಥಿತಃ  ৷৷5.20৷৷",
  
      "ಬಾಹ್ಯಸ್ಪರ್ಶೇಷ್ವಸಕ್ತಾತ್ಮಾ, ವಿಂದತ್ಯಾತ್ಮನಿ ಯತ್ಸುಖಮ್ |\nಸ ಬ್ರಹ್ಮಯೋಗಯುಕ್ತಾತ್ಮಾ, ಸುಖಮಕ್ಷಯಮಶ್ನುತೇ ৷৷5.21৷৷",
  
      "ಯೇ ಹಿ ಸಂಸ್ಪರ್ಶಜಾ ಭೋಗಾ, ದುಃಖಯೋನಯ ಏವ ತೇ |\nಆದ್ಯಂತವಂತಃ(ಖ್) ಕೌಂತೇಯ, ನ ತೇಷು ರಮತೇ ಬುಧಃ  ৷৷5.22৷৷",
  
      "ಶಕ್ನೋತೀಹೈವ ಯಃ(ಸ್) ಸೋಢುಂ(ಮ್), ಪ್ರಾಕ್ಶರೀರವಿಮೋಕ್ಷಣಾತ್ |\nಕಾಮಕ್ರೋಧೋದ್ಭವಂ(ಮ್) ವೇಗಂ(ಮ್), ಸ ಯುಕ್ತಃ(ಸ್) ಸ ಸುಖೀ ನರಃ ৷৷5.23৷৷",
  
      "ಯೋಽನ್ತಃಸುಖೋಽನ್ತರಾರಾಮಃ(ಸ್), ತಥಾಂತರ್ಜ್ಯೋತಿರೇವ ಯಃ |\nಸ ಯೋಗೀ ಬ್ರಹ್ಮನಿರ್ವಾಣಂ(ಮ್), ಬ್ರಹ್ಮಭೂತೋಽಧಿಗಚ್ಛತಿ  ৷5.৷24৷৷",
  
      "ಲಭಂತೇ ಬ್ರಹ್ಮನಿರ್ವಾಣಮ್, ಋಷಯಃ ಕ್ಷೀಣಕಲ್ಮಷಾಃ |\nಛಿನ್ನದ್ವೈಧಾ ಯತಾತ್ಮಾನಃ(ಸ್), ಸರ್ವಭೂತಹಿತೇ ರತಾಃ  ৷৷5.25৷৷",
  
      "ಕಾಮಕ್ರೋಧವಿಯುಕ್ತಾನಾಂ(ಮ್), ಯತೀನಾಂ(ಮ್) ಯತಚೇತಸಾಮ್ |\nಅಭಿತೋ ಬ್ರಹ್ಮನಿರ್ವಾಣಂ(ಮ್), ವರ್ತತೇ ವಿದಿತಾತ್ಮನಾಮ್ ৷৷5.26৷৷",
  
      "ಸ್ಪರ್ಶಾನ್ಕೃತ್ವಾ ಬಹಿರ್ಬಾಹ್ಯಾಂಶ್, ಚಕ್ಷುಶ್ಚೈವಾಂತರೇ ಭ್ರುವೋಃ |\nಪ್ರಾಣಾಪಾನೌ ಸಮೌ ಕೃತ್ವಾ, ನಾಸಾಭ್ಯಂತರಚಾರಿಣೌ ৷৷5.27৷৷",
  
      "ಯತೇಂದ್ರಿಯಮನೋಬುದ್ಧಿಃ(ರ್), ಮುನಿರ್ಮೋಕ್ಷಪರಾಯಣಃ |\nವಿಗತೇಚ್ಛಾಭಯಕ್ರೋಧೋ, ಯಃ(ಸ್) ಸದಾ ಮುಕ್ತ ಏವ ಸಃ ||5.28||",
  
      "ಭೋಕ್ತಾರಂ(ಮ್) ಯಜ್ಞತಪಸಾಂ(ಮ್), ಸರ್ವಲೋಕಮಹೇಶ್ವರಮ್ |\nಸುಹೃದಂ(ಮ್) ಸರ್ವಭೂತಾನಾಂ(ಞ್), ಜ್ಞಾತ್ವಾ ಮಾಂ(ಮ್) ಶಾಂತಿಮೃಚ್ಛತಿ ৷৷5.29৷৷",
  
      "ಓಂ ತತ್ಸದಿತಿ ಶ್ರೀಮದ್ಭಗವದ್ಗೀತಾಸು ಉಪನಿಷತ್ಸು \nಬ್ರಹ್ಮವಿದ್ಯಾಯಾಂ(ಮ್) ಯೋಗಶಾಸ್ತ್ರೇ ಶ್ರೀಕೃಷ್ಣಾರ್ಜುನಸಂವಾದೇ\nಕರ್ಮಸಂನ್ಯಾಸ ಯೋಗೋ ನಾಮ ಪಂಚಮೋಽಧ್ಯಾಯಃ ।।5।।"
    ],
    "adhyaya-6" : [
      "ಶ್ರೀಭಗವಾನುವಾಚ\nಅನಾಶ್ರಿತಃ(ಖ್) ಕರ್ಮಫಲಂ(ಙ್), ಕಾರ್ಯಂ(ಙ್) ಕರ್ಮ ಕರೋತಿ ಯಃ |\nಸ ಸಂನ್ಯಾಸೀ ಚ ಯೋಗೀ ಚ, ನ ನಿರಗ್ನಿರ್ನ ಚಾಕ್ರಿಯಃ ॥6.1॥",
  
      "ಯಂ ಸಂನ್ಯಾಸಮಿತಿ ಪ್ರಾಹುಃ(ರ್), ಯೋಗಂ(ನ್) ತಂ(ಮ್) ವಿದ್ಧಿ ಪಾಂಡವ |\nನ ಹ್ಯಸಂನ್ಯಸ್ತಸಂಕಲ್ಪೋ, ಯೋಗೀ ಭವತಿ ಕಶ್ಚನ ॥6.2॥",
  
      "ಆರುರುಕ್ಷೋರ್ಮುನೇರ್ಯೋಗಂ(ಙ್), ಕರ್ಮ ಕಾರಣಮುಚ್ಯತೇ |\nಯೋಗಾರೂಢಸ್ಯ ತಸ್ಯೈವ, ಶಮಃ(ಖ್) ಕಾರಣಮುಚ್ಯತೇ ॥6.3॥",
  
      "ಯದಾ ಹಿ ನೇಂದ್ರಿಯಾರ್ಥೇಷು, ನ ಕರ್ಮಸ್ವನುಷಜ್ಜತೇ |\nಸರ್ವಸಂಕಲ್ಪಸಂನ್ಯಾಸೀ, ಯೋಗಾರೂಢಸ್ತದೋಚ್ಯತೇ ॥6.4॥",
  
      "ಉದ್ಧರೇದಾತ್ಮನಾತ್ಮಾನಂ(ನ್), ನಾತ್ಮಾನಮವಸಾದಯೇತ್ |\nಆತ್ಮೈವ ಹ್ಯಾತ್ಮನೋ ಬಂಧುಃ(ರ್), ಆತ್ಮೈವ ರಿಪುರಾತ್ಮನಃ ॥6.5॥",
  
      "ಬಂಧುರಾತ್ಮಾತ್ಮನಸ್ತಸ್ಯ, ಯೇನಾತ್ಮೈವಾತ್ಮನಾ ಜಿತಃ |\nಅನಾತ್ಮನಸ್ತು ಶತ್ರುತ್ವೇ, ವರ್ತೇತಾತ್ಮೈವ ಶತ್ರುವತ್ ॥6.6॥",
  
      "ಜಿತಾತ್ಮನಃ(ಫ್) ಪ್ರಶಾಂತಸ್ಯ, ಪರಮಾತ್ಮಾ ಸಮಾಹಿತಃ |\nಶೀತೋಷ್ಣಸುಖದುಃಖೇಷು, ತಥಾ ಮಾನಾಪಮಾನಯೋಃ ॥6.7॥",
  
      "ಜ್ಞಾನವಿಜ್ಞಾನತೃಪ್ತಾತ್ಮಾ, ಕೂಟಸ್ಥೋ ವಿಜಿತೇಂದ್ರಿಯಃ |\nಯುಕ್ತ ಇತ್ಯುಚ್ಯತೇ ಯೋಗೀ, ಸಮಲೋಷ್ಟಾಶ್ಮಕಾಂಚನಃ ॥6.8॥",
  
      "ಸುಹೃನ್ಮಿತ್ರಾರ್ಯುದಾಸೀನ, ಮಧ್ಯಸ್ಥದ್ವೇಷ್ಯಬಂಧುಷು |\nಸಾಧುಷ್ವಪಿ ಚ ಪಾಪೇಷು, ಸಮಬುದ್ಧಿರ್ವಿಶಿಷ್ಯತೇ ॥6.9॥",
  
      "ಯೋಗೀ ಯುಂಜೀತ ಸತತಮ್, ಆತ್ಮಾನಂ(ಮ್) ರಹಸಿ ಸ್ಥಿತಃ |\nಏಕಾಕೀ ಯತಚಿತ್ತಾತ್ಮಾ, ನಿರಾಶೀರಪರಿಗ್ರಹಃ 6.॥10॥",
  
      "ಶುಚೌ ದೇಶೇ ಪ್ರತಿಷ್ಠಾಪ್ಯ, ಸ್ಥಿರಮಾಸನಮಾತ್ಮನಃ |\nನಾತ್ಯುಚ್ಛ್ರಿತಂ(ನ್) ನಾತಿನೀಚಂ(ಞ್), ಚೈಲಾಜಿನಕುಶೋತ್ತರಮ್ ॥6.11॥",
  
      "ತತ್ರೈಕಾಗ್ರಂ(ಮ್) ಮನಃ(ಖ್) ಕೃತ್ವಾ, ಯತಚಿತ್ತೇಂದ್ರಿಯಕ್ರಿಯಃ |\nಉಪವಿಶ್ಯಾಸನೇ ಯುಂಜ್ಯಾದ್, ಯೋಗಮಾತ್ಮವಿಶುದ್ಧಯೇ ॥6.12॥",
  
      "ಸಮಂ(ಙ್) ಕಾಯಶಿರೋಗ್ರೀವಂ(ನ್), ಧಾರಯನ್ನಚಲಂ(ಮ್) ಸ್ಥಿರಃ |\nಸಂಪ್ರೇಕ್ಷ್ಯ ನಾಸಿಕಾಗ್ರಂ(ಮ್) ಸ್ವಂ(ನ್), ದಿಶಶ್ಚಾನವಲೋಕಯನ್ ॥6.13॥",
  
      "ಪ್ರಶಾಂತಾತ್ಮಾ ವಿಗತಭೀಃ(ರ್), ಬ್ರ​ಹ್ಮಚಾರಿವ್ರತೇ ಸ್ಥಿತಃ |\nಮನಃ(ಸ್) ಸಂಯಮ್ಯ ಮಚ್ಚಿತ್ತೋ, ಯುಕ್ತ ಆಸೀತ ಮತ್ಪರಃ ॥6.14॥",
  
      "ಯುಂಜನ್ನೇವಂ(ಮ್) ಸದಾತ್ಮಾನಂ(ಮ್), ಯೋಗೀ ನಿಯತಮಾನಸಃ |\nಶಾಂತಿಂ(ನ್) ನಿರ್ವಾಣಪರಮಾಂ(ಮ್), ಮತ್ಸಂಸ್ಥಾಮಧಿಗಚ್ಛತಿ ॥6.15॥",
  
      "ನಾತ್ಯಶ್ನತಸ್ತು ಯೋಗೋಽಸ್ತಿ, ನ ಚೈಕಾಂತಮನಶ್ನತಃ |\nನ ಚಾತಿಸ್ವಪ್ನಶೀಲಸ್ಯ, ಜಾಗ್ರತೋ ನೈವ ಚಾರ್ಜುನ ॥6.16॥",
  
      "ಯುಕ್ತಾಹಾರವಿಹಾರಸ್ಯ, ಯುಕ್ತಚೇಷ್ಟಸ್ಯ ಕರ್ಮಸು |\nಯುಕ್ತಸ್ವಪ್ನಾವಬೋಧಸ್ಯ, ಯೋಗೋ ಭವತಿ ದುಃಖಹಾ ॥6.17॥",
  
      "ಯದಾ ವಿನಿಯತಂ(ಞ್) ಚಿತ್ತಮ್, ಆತ್ಮನ್ಯೇವಾವತಿಷ್ಠತೇ |\nನಿಃಸ್ಪೃಹಃ(ಸ್) ಸರ್ವಕಾಮೇಭ್ಯೋ, ಯುಕ್ತ ಇತ್ಯುಚ್ಯತೇ ತದಾ ॥6.18॥",
  
      "ಯಥಾ ದೀಪೋ ನಿವಾತಸ್ಥೋ, ನೇಂಗತೇ ಸೋಪಮಾ ಸ್ಮೃತಾ |\nಯೋಗಿನೋ ಯತಚಿತ್ತಸ್ಯ, ಯುಂಜತೋ ಯೋಗಮಾತ್ಮನಃ ॥6.19॥",
  
      "ಯತ್ರೋಪರಮತೇ ಚಿತ್ತಂ(ನ್), ನಿರುದ್ಧಂ(ಮ್) ಯೋಗಸೇವಯಾ |\nಯತ್ರ ಚೈವಾತ್ಮನಾತ್ಮಾನಂ(ಮ್), ಪಶ್ಯನ್ನಾತ್ಮನಿ ತುಷ್ಯತಿ ॥6.20॥",
  
      "ಸುಖಮಾತ್ಯಂತಿಕಂ(ಮ್) ಯತ್ತದ್, ಬುದ್ಧಿಗ್ರಾಹ್ಯಮತೀಂದ್ರಿಯಮ್ |\nವೇತ್ತಿ ಯತ್ರ ನ ಚೈವಾಯಂ(ಮ್), ಸ್ಥಿತಶ್ಚಲತಿ ತತ್ತ್ವತಃ ॥6.21॥",
  
      "ಯಂ(ಮ್) ಲಬ್ಧ್ವಾ ಚಾಪರಂ(ಮ್) ಲಾಭಂ(ಮ್), ಮನ್ಯತೇ ನಾಧಿಕಂ(ನ್) ತತಃ |\nಯಸ್ಮಿನ್ಸ್ಥಿತೋ ನ ದುಃಖೇನ, ಗುರುಣಾಪಿ ವಿಚಾಲ್ಯತೇ ॥6.22॥",
  
      "ತಂ(ಮ್) ವಿದ್ಯಾದ್ ದುಃಖಸಂಯೋಗ, ವಿಯೋಗಂ(ಮ್) ಯೋಗಸಂಜ್ಞಿತಮ್ |\nಸ ನಿಶ್ಚಯೇನ ಯೋಕ್ತವ್ಯೋ, ಯೋಗೋಽನಿರ್ವಿಣ್ಣಚೇತಸಾ ॥6.23॥",
  
      "ಸಂಕಲ್ಪಪ್ರಭವಾನ್ಕಾಮಾಂಸ್, ತ್ಯ​ಕ್ತ್ವಾ ಸರ್ವಾನಶೇಷತಃ |\nಮನಸೈವೇಂದ್ರಿಯಗ್ರಾಮಂ(ಮ್), ವಿನಿಯಮ್ಯ ಸಮಂತತಃ ॥6.24॥",
  
      "ಶನೈಃ(ಶ್) ಶನೈರುಪರಮೇದ್, ಬುದ್ಧ್ಯಾ ಧೃತಿಗೃಹೀತಯಾ |\nಆತ್ಮಸಂಸ್ಥಂ(ಮ್) ಮನಃ(ಖ್) ಕೃತ್ವಾ, ನ ಕಿಂಚಿದಪಿ ಚಿಂತಯೇತ್ ॥6.25॥",
  
      "ಯತೋ ಯತೋ ನಿಶ್ಚರತಿ, ಮನಶ್ಚಂಚಲಮಸ್ಥಿರಮ್ |\nತತಸ್ತತೋ ನಿಯಮ್ಯೈತದ್, ಆತ್ಮನ್ಯೇವ ವಶಂ(ನ್) ನಯೇತ್ ॥6.26॥",
  
      "ಪ್ರಶಾಂತಮನಸಂ(ಮ್) ಹ್ಯೇನಂ(ಮ್), ಯೋಗಿನಂ(ಮ್) ಸುಖಮುತ್ತಮಮ್ |\nಉಪೈತಿ ಶಾಂತರಜಸಂ(ಮ್), ಬ್ರಹ್ಮಭೂತಮಕಲ್ಮಷಮ್ ॥6.27॥",
  
      "ಯುಂಜನ್ನೇವಂ(ಮ್) ಸದಾತ್ಮಾನಂ(ಮ್), ಯೋಗೀ ವಿಗತಕಲ್ಮಷಃ |\nಸುಖೇನ ಬ್ರಹ್ಮಸಂಸ್ಪರ್ಶಮ್, ಅತ್ಯಂತಂ(ಮ್) ಸುಖಮಶ್ನುತೇ ॥6.28॥",
  
      "ಸರ್ವಭೂತಸ್ಥಮಾತ್ಮಾನಂ(ಮ್), ಸರ್ವಭೂತಾನಿ ಚಾತ್ಮನಿ |\nಈಕ್ಷತೇ ಯೋಗಯುಕ್ತಾತ್ಮಾ, ಸರ್ವತ್ರ ಸಮದರ್ಶನಃ ॥6.29॥",
  
      "ಯೋ ಮಾಂ(ಮ್) ಪಶ್ಯತಿ ಸರ್ವತ್ರ, ಸರ್ವಂ(ಞ್) ಚ ಮಯಿ ಪಶ್ಯತಿ |\nತಸ್ಯಾಹಂ(ನ್) ನ ಪ್ರಣಶ್ಯಾಮಿ, ಸ ಚ ಮೇ ನ ಪ್ರಣಶ್ಯತಿ 6.॥30॥",
  
      "ಸರ್ವಭೂತಸ್ಥಿತಂ(ಮ್) ಯೋ ಮಾಂ(ಮ್), ಭಜತ್ಯೇಕತ್ವಮಾಸ್ಥಿತಃ |\nಸರ್ವಥಾ ವರ್ತಮಾನೋಽಪಿ, ಸ ಯೋಗೀ ಮಯಿ ವರ್ತತೇ 6.॥31॥",
  
      "ಆತ್ಮೌಪಮ್ಯೇನ ಸರ್ವತ್ರ, ಸಮಂ(ಮ್) ಪಶ್ಯತಿ ಯೋಽರ್ಜುನ |\nಸುಖಂ(ಮ್) ವಾ ಯದಿ ವಾ ದುಃಖಂ(ಮ್), ಸ ಯೋಗೀ ಪರಮೋ ಮತಃ ॥6.32॥",
  
      "ಅರ್ಜುನ ಉವಾಚ\nಯೋಽಯಂ(ಮ್) ಯೋಗಸ್ತ್ವಯಾ ಪ್ರೋಕ್ತಃ(ಸ್), ಸಾಮ್ಯೇನ ಮಧುಸೂದನ |\nಏತಸ್ಯಾಹಂ(ನ್) ನ ಪಶ್ಯಾಮಿ, ಚಂಚಲತ್ವಾತ್ಸ್ಥಿತಿಂ(ಮ್) ಸ್ಥಿರಾಮ್ ॥6.33॥",
  
      "ಚಂಚಲಂ(ಮ್) ಹಿ ಮನಃ(ಖ್) ಕೃಷ್ಣ, ಪ್ರಮಾಥಿ ಬಲವದ್ ದೃಢಮ್ |\nತಸ್ಯಾಹಂ(ನ್) ನಿಗ್ರಹಂ(ಮ್) ಮನ್ಯೇ, ವಾಯೋರಿವ ಸುದುಷ್ಕರಮ್ ॥6.34॥",
  
      "ಶ್ರೀಭಗವಾನುವಾಚ\nಅಸಂಶಯಂ(ಮ್) ಮಹಾಬಾಹೋ, ಮನೋ ದುರ್ನಿಗ್ರಹಂ(ಞ್) ಚಲಮ್ |\nಅಭ್ಯಾಸೇನ ತು ಕೌಂತೇಯ, ವೈರಾಗ್ಯೇಣ ಚ ಗೃಹ್ಯತೇ ॥6.35॥",
  
      "ಅಸಂಯತಾತ್ಮನಾ ಯೋಗೋ, ದುಷ್ಪ್ರಾಪ ಇತಿ ಮೇ ಮತಿಃ |\nವಶ್ಯಾತ್ಮನಾ ತು ಯತತಾ, ಶಕ್ಯೋಽವಾಪ್ತುಮುಪಾಯತಃ 6.॥36॥",
  
      "ಅರ್ಜುನ ಉವಾಚ\nಅಯತಿಃ(ಶ್) ಶ್ರದ್ಧಯೋಪೇತೋ, ಯೋಗಾಚ್ಚಲಿತಮಾನಸಃ |\nಅಪ್ರಾಪ್ಯ ಯೋಗಸಂಸಿದ್ಧಿಂ(ಙ್), ಕಾಂ(ಙ್) ಗತಿಂ(ಙ್) ಕೃಷ್ಣ ಗಚ್ಛತಿ ॥6.37॥",
  
      "ಕಚ್ಚಿನ್ನೋಭಯವಿಭ್ರಷ್ಟಃ(ಶ್), ಛಿನ್ನಾಭ್ರಮಿವ ನಶ್ಯತಿ |\nಅಪ್ರತಿಷ್ಠೋ ಮಹಾಬಾಹೋ, ವಿಮೂಢೋ ಬ್ರಹ್ಮಣಃ(ಫ್) ಪಥಿ ॥6.38॥",
  
      "ಏತನ್ಮೇ ಸಂಶಯಂ(ಙ್) ಕೃಷ್ಣ, ಛೇತ್ತುಮರ್ಹಸ್ಯಶೇಷತಃ |\nತ್ವದನ್ಯಃ(ಸ್) ಸಂಶಯಸ್ಯಾಸ್ಯ, ಛೇತ್ತಾ ನ ಹ್ಯುಪಪದ್ಯತೇ ॥6.39॥",
  
      "ಶ್ರೀಭಗವಾನುವಾಚ\nಪಾರ್ಥ ನೈವೇಹ ನಾಮುತ್ರ, ವಿನಾಶಸ್ತಸ್ಯ ವಿದ್ಯತೇ |\nನ ಹಿ ಕಲ್ಯಾಣಕೃತ್ಕಶ್ಚಿದ್, ದುರ್ಗತಿಂ(ನ್) ತಾತ ಗಚ್ಛತಿ ॥6.40॥",
  
      "ಪ್ರಾಪ್ಯ ಪುಣ್ಯಕೃತಾಂ(ಮ್) ಲೋಕಾನ್, ಉಷಿತ್ವಾ ಶಾಶ್ವತೀಃ(ಸ್) ಸಮಾಃ |\nಶುಚೀನಾಂ(ಮ್) ಶ್ರೀಮತಾಂ(ಙ್) ಗೇಹೇ, ಯೋಗಭ್ರಷ್ಟೋಽಭಿಜಾಯತೇ ॥6.41॥",
  
      "ಅಥವಾ ಯೋಗಿನಾಮೇವ, ಕುಲೇ ಭವತಿ ಧೀಮತಾಮ್ |\nಏತದ್ಧಿ ದುರ್ಲಭತರಂ(ಮ್), ಲೋಕೇ ಜನ್ಮ ಯದೀದೃಶಮ್ ॥6.42॥",
  
      "ತತ್ರ ತಂ(ಮ್) ಬುದ್ಧಿಸಂಯೋಗಂ(ಮ್), ಲಭತೇ ಪೌರ್ವದೇಹಿಕಮ್ |\nಯತತೇ ಚ ತತೋ ಭೂಯಃ(ಸ್), ಸಂಸಿದ್ಧೌ ಕುರುನಂದನ ॥6.43॥",
  
      "ಪೂರ್ವಾಭ್ಯಾಸೇನ ತೇನೈವ, ಹ್ರಿಯತೇ ಹ್ಯವಶೋಽಪಿ ಸಃ |\nಜಿಜ್ಞಾಸುರಪಿ ಯೋಗಸ್ಯ, ಶಬ್ದಬ್ರಹ್ಮಾತಿವರ್ತತೇ ॥6.44॥",
  
      "ಪ್ರಯತ್ನಾದ್ಯತಮಾನಸ್ತು, ಯೋಗೀ ಸಂಶುದ್ಧಕಿಲ್ಬಿಷಃ |\nಅನೇಕಜನ್ಮಸಂಸಿದ್ಧಃ(ಸ್), ತ​ತೋ ಯಾತಿ ಪರಾಂ(ಙ್) ಗತಿಮ್ ॥6.45॥",
  
      "ತಪಸ್ವಿಭ್ಯೋಽಧಿಕೋ ಯೋಗೀ, ಜ್ಞಾನಿಭ್ಯೋಽಪಿ ಮತೋಽಧಿಕಃ |\nಕರ್ಮಿಭ್ಯಶ್ಚಾಧಿಕೋ ಯೋಗೀ, ತಸ್ಮಾದ್ಯೋಗೀ ಭವಾರ್ಜುನ ॥6.46॥",
  
      "ಯೋಗಿನಾಮಪಿ ಸರ್ವೇಷಾಂ(ಮ್), ಮದ್ಗತೇನಾಂತರಾತ್ಮನಾ |\nಶ್ರದ್ಧಾವಾನ್ಭಜತೇ ಯೋ ಮಾಂ(ಮ್), ಸ ಮೇ ಯುಕ್ತತಮೋ ಮತಃ ॥6.47॥",
  
      "ಓಂ ತತ್ಸದಿತಿ  ಶ್ರೀಮದ್ಭಗವದ್ಗೀತಾಸು ಉಪನಿಷತ್ಸು ಬ್ರಹ್ಮವಿದ್ಯಾಯಾಂ(ಮ್)\nಯೋಗಶಾಸ್ತ್\nಶ್ರೀಕೃಷ್ಣಾರ್ಜುನಸಂವಾದೇ ಆತ್ಮಸಂಯಮಯೋಗೋ ನಾಮ ಷಷ್ಠೋಽಧ್ಯಾಯಃ ॥6॥"
    ],
    "adhyaya-7" : [
      "ಶ್ರೀಭಗವಾನುವಾಚ\nಮಯ್ಯಾಸಕ್ತಮನಾಃ(ಫ್) ಪಾರ್ಥ, ಯೋಗಂ(ಮ್) ಯುಂಜನ್ಮದಾಶ್ರಯಃ |\nಅಸಂಶಯಂ(ಮ್) ಸಮಗ್ರಂ(ಮ್) ಮಾಂ(ಮ್), ಯಥಾ ಜ್ಞಾಸ್ಯಸಿ ತಚ್ಛೃಣು ॥7.1॥",
  
      "ಜ್ಞಾನಂ(ನ್) ತೇಽಹಂ(ಮ್) ಸವಿಜ್ಞಾನಮ್, ಇದಂ(ಮ್) ವಕ್ಷ್ಯಾಮ್ಯಶೇಷತಃ |\nಯಜ್.ಜ್ಞಾತ್ವಾ ನೇಹ ಭೂಯೋಽನ್ಯಜ್, ಜ್ಞಾತವ್ಯಮವಶಿಷ್ಯತೇ ॥7.2॥",
  
      "ಮನುಷ್ಯಾಣಾಂ(ಮ್) ಸಹಸ್ರೇಷು, ಕಶ್ಚಿದ್ಯತತಿ ಸಿದ್ಧಯೇ |\nಯತತಾಮಪಿ ಸಿದ್ಧಾನಾಂ(ಙ್), ಕಶ್ಚಿನ್ಮಾಂ(ಮ್) ವೇತ್ತಿ ತತ್ತ್ವತಃ ॥7.3॥",
  
      "ಭೂಮಿರಾಪೋಽನಲೋ ವಾಯುಃ(ಖ್), ಖಂ(ಮ್) ಮನೋ ಬುದ್ಧಿರೇವ ಚ |\nಅಹಂಕಾರ ಇತೀಯಂ(ಮ್) ಮೇ, ಭಿನ್ನಾ ಪ್ರಕೃತಿರಷ್ಟಧಾ ॥7.4॥",
  
      "ಅಪರೇಯಮಿತಸ್ತ್ವನ್ಯಾಂ(ಮ್), ಪ್ರಕೃತಿಂ(ಮ್) ವಿದ್ಧಿ ಮೇ ಪರಾಮ್ |\nಜೀವಭೂತಾಂ(ಮ್) ಮಹಾಬಾಹೋ, ಯಯೇದಂ(ನ್) ಧಾರ್ಯತೇ ಜಗತ್ ॥7.5॥",
  
      "ಏತದ್ಯೋನೀನಿ ಭೂತಾನಿ, ಸರ್ವಾಣೀತ್ಯುಪಧಾರಯ |\nಅಹಂ(ಙ್) ಕೃತ್ಸ್ನಸ್ಯ ಜಗತಃ(ಫ್), ಪ್ರಭವಃ(ಫ್) ಪ್ರಲಯಸ್ತಥಾ ॥7.6॥",
  
      "ಮತ್ತಃ(ಫ್) ಪರತರಂ(ನ್) ನಾನ್ಯತ್, ಕಿಂಚಿದಸ್ತಿ ಧನಂಜಯ |\nಮಯಿ ಸರ್ವಮಿದಂ(ಮ್) ಪ್ರೋತಂ(ಮ್), ಸೂತ್ರೇ ಮಣಿಗಣಾ ಇವ ॥7.7॥",
  
      "ರಸೋಽಹಮಪ್ಸು ಕೌಂತೇಯ, ಪ್ರಭಾಸ್ಮಿ ಶಶಿಸೂರ್ಯಯೋಃ |\nಪ್ರಣವಃ(ಸ್) ಸರ್ವವೇದೇಷು, ಶಬ್ದಃ(ಖ್) ಖೇ ಪೌರುಷಂ(ನ್) ನೃಷು ॥7.8॥",
  
      "ಪುಣ್ಯೋ ಗಂಧಃ(ಫ್) ಪೃಥಿವ್ಯಾಂ(ಞ್) ಚ, ತೇಜಶ್ಚಾಸ್ಮಿ ವಿಭಾವಸೌ|\nಜೀವನಂ(ಮ್) ಸರ್ವಭೂತೇಷು, ತಪಶ್ಚಾಸ್ಮಿ ತಪಸ್ವಿಷು ॥7.9॥",
  
      "ಬೀಜಂ(ಮ್) ಮಾಂ(ಮ್) ಸರ್ವಭೂತಾನಾಂ(ಮ್), ವಿದ್ಧಿ ಪಾರ್ಥ ಸನಾತನಮ್ |\nಬುದ್ಧಿರ್ಬುದ್ಧಿಮತಾಮಸ್ಮಿ, ತೇಜಸ್ತೇಜಸ್ವಿನಾಮಹಮ್ 7.॥10॥",
  
      "ಬಲಂ(ಮ್) ಬಲವತಾಂ(ಞ್) ಚಾಹಂ(ಙ್), ಕಾಮರಾಗವಿವರ್ಜಿತಮ್ |\nಧರ್ಮಾವಿರುದ್ಧೋ ಭೂತೇಷು, ಕಾಮೋಽಸ್ಮಿ ಭರತರ್ಷಭ ॥7.11॥",
  
      "ಯೇ ಚೈವ ಸಾತ್ತ್ವಿಕಾ ಭಾವಾ, ರಾಜಸಾಸ್ತಾಮಸಾಶ್ಚ ಯೇ |\nಮತ್ತ ಏವೇತಿ ತಾನ್ವಿದ್ಧಿ, ನ ತ್ವಹಂ(ನ್) ತೇಷು ತೇ ಮಯಿ ॥7.12॥",
  
      "ತ್ರಿಭಿರ್ಗುಣಮಯೈರ್ಭಾವೈಃ(ರ್), ಏಭಿಃ(ಸ್) ಸರ್ವಮಿದಂ(ಞ್) ಜಗತ್ |\nಮೋಹಿತಂ(ನ್) ನಾಭಿಜಾನಾತಿ, ಮಾಮೇಭ್ಯಃ(ಫ್) ಪರಮವ್ಯಯಮ್ ॥7.13॥",
  
      "ದೈವೀ ಹ್ಯೇಷಾ ಗುಣಮಯೀ, ಮಮ ಮಾಯಾ ದುರತ್ಯಯಾ |\nಮಾಮೇವ ಯೇ ಪ್ರಪದ್ಯಂತೇ, ಮಾಯಾಮೇತಾಂ(ನ್) ತರಂತಿ ತೇ ॥7.14॥",
  
      "ನ ಮಾಂ(ನ್) ದುಷ್ಕೃತಿನೋ ಮೂಢಾಃ(ಫ್), ಪ್ರಪದ್ಯಂತೇ ನರಾಧಮಾಃ |\nಮಾಯಯಾಪಹೃತಜ್ಞಾನಾ, ಆಸುರಂ(ಮ್) ಭಾವಮಾಶ್ರಿತಾಃ ॥7.15॥",
  
      "ಚತುರ್ವಿಧಾ ಭಜಂತೇ ಮಾಂ(ಞ್), ಜನಾಃ(ಸ್) ಸುಕೃತಿನೋಽರ್ಜುನ |\nಆರ್ತೋ ಜಿಜ್ಞಾಸುರರ್ಥಾರ್ಥೀ, ಜ್ಞಾನೀ ಚ ಭರತರ್ಷಭ ॥7.16॥",
  
      "ತೇಷಾಂ(ಞ್) ಜ್ಞಾನೀ ನಿತ್ಯಯುಕ್ತ, ಏಕಭಕ್ತಿರ್ವಿಶಿಷ್ಯತೇ |\nಪ್ರಿಯೋ ಹಿ ಜ್ಞಾನಿನೋಽತ್ಯರ್ಥಮ್, ಅಹಂ(ಮ್) ಸ ಚ ಮಮ ಪ್ರಿಯಃ ॥7.17॥",
  
      "ಉದಾರಾಃ(ಸ್) ಸರ್ವ ಏವೈತೇ, ಜ್ಞಾನೀ ತ್ವಾತ್ಮೈವ ಮೇ ಮತಮ್ |\nಆಸ್ಥಿತಃ(ಸ್) ಸ ಹಿ ಯುಕ್ತಾತ್ಮಾ, ಮಾಮೇವಾನುತ್ತಮಾಂ(ಙ್) ಗತಿಮ್ ॥7.18॥",
  
      "ಬಹೂನಾಂ(ಞ್) ಜನ್ಮನಾಮಂತೇ, ಜ್ಞಾನವಾನ್ಮಾಂ(ಮ್) ಪ್ರಪದ್ಯತೇ |\nವಾಸುದೇವಃ(ಸ್) ಸರ್ವಮಿತಿ, ಸ ಮಹಾತ್ಮಾ ಸುದುರ್ಲಭಃ ॥7.19॥",
  
      "ಕಾಮೈಸ್ತೈಸ್ತೈರ್ಹೃತಜ್ಞಾನಾಃ(ಫ್), ಪ್ರಪದ್ಯಂತೇಽನ್ಯದೇವತಾಃ |\nತಂ(ನ್) ತಂ(ನ್) ನಿಯಮಮಾಸ್ಥಾಯ, ಪ್ರಕೃತ್ಯಾ ನಿಯತಾಃ(ಸ್) ಸ್ವಯಾ ॥7.20॥",
  
      "ಯೋ ಯೋ ಯಾಂ(ಮ್) ಯಾಂ(ನ್) ತನುಂ(ಮ್) ಭಕ್ತಃ(ಶ್), ಶ್ರದ್ಧಯಾರ್ಚಿತುಮಿಚ್ಛತಿ |\nತಸ್ಯ ತಸ್ಯಾಚಲಾಂ(ಮ್) ಶ್ರದ್ಧಾಂ(ನ್), ತಾಮೇವ ವಿದಧಾಮ್ಯಹಮ್ ॥7.21॥",
  
      "ಸ ತಯಾ ಶ್ರದ್ಧಯಾ ಯುಕ್ತಃ(ಸ್), ತ​ಸ್ಯಾರಾಧನಮೀಹತೇ |\nಲಭತೇ ಚ ತತಃ(ಖ್) ಕಾಮಾನ್, ಮ​ಯೈವ ವಿಹಿತಾನ್ಹಿ ತಾನ್ ॥7.22॥",
  
      "ಅಂತವತ್ತು ಫಲಂ(ನ್) ತೇಷಾಂ(ನ್), ತದ್ಭವತ್ಯಲ್ಪಮೇಧಸಾಮ್ |\nದೇವಾಂದೇವಯಜೋ ಯಾಂತಿ, ಮದ್ಭಕ್ತಾ ಯಾಂತಿ ಮಾಮಪಿ ॥7.23॥",
  
      "ಅವ್ಯಕ್ತಂ(ಮ್) ವ್ಯಕ್ತಿಮಾಪನ್ನಂ(ಮ್), ಮನ್ಯಂತೇ ಮಾಮಬುದ್ಧಯಃ |\nಪರಂ(ಮ್) ಭಾವಮಜಾನಂತೋ, ಮಮಾವ್ಯಯಮನುತ್ತಮಮ್ ॥7.24॥",
  
      "ನಾಹಂ(ಮ್) ಪ್ರಕಾಶಃ(ಸ್) ಸರ್ವಸ್ಯ, ಯೋಗಮಾಯಾಸಮಾವೃತಃ |\nಮೂಢೋಽಯಂ(ನ್) ನಾಭಿಜಾನಾತಿ, ಲೋಕೋ ಮಾಮಜಮವ್ಯಯಮ್ ॥7.25॥",
  
      "ವೇದಾಹಂ(ಮ್) ಸಮತೀತಾನಿ, ವರ್ತಮಾನಾನಿ ಚಾರ್ಜುನ |\nಭವಿಷ್ಯಾಣಿ ಚ ಭೂತಾನಿ, ಮಾಂ(ನ್) ತು ವೇದ ನ ಕಶ್ಚನ ॥7.26॥",
  
      "ಇಚ್ಛಾದ್ವೇಷಸಮುತ್ಥೇನ, ದ್ವಂದ್ವಮೋಹೇನ ಭಾರತ |\nಸರ್ವಭೂತಾನಿ ಸಮ್ಮೋಹಂ(ಮ್), ಸರ್ಗೇ ಯಾಂತಿ ಪರಂತಪ ॥7.27॥",
  
      "ಯೇಷಾಂ(ನ್) ತ್ವಂತಗತಂ(ಮ್) ಪಾಪಂ(ಞ್), ಜನಾನಾಂ(ಮ್) ಪುಣ್ಯಕರ್ಮಣಾಮ್ |\nತೇ ದ್ವಂದ್ವಮೋಹನಿರ್ಮುಕ್ತಾ, ಭಜಂತೇ ಮಾಂ(ನ್) ದೃಢವ್ರತಾಃ ॥7.28॥",
  
      "ಜರಾಮರಣಮೋಕ್ಷಾಯ, ಮಾಮಾಶ್ರಿತ್ಯ ಯತಂತಿ ಯೇ |\nತೇ ಬ್ರಹ್ಮ ತದ್ವಿದುಃ(ಖ್) ಕೃತ್ಸ್ನಮ್, ಅಧ್ಯಾತ್ಮಂ(ಙ್) ಕರ್ಮ ಚಾಖಿಲಮ್ ॥7.29॥",
  
      "ಸಾಧಿಭೂತಾಧಿದೈವಂ(ಮ್) ಮಾಂ(ಮ್), ಸಾಧಿಯಜ್ಞಂ(ಞ್) ಚ ಯೇ ವಿದುಃ |\nಪ್ರಯಾಣಕಾಲೇಽಪಿ ಚ ಮಾಂ(ನ್), ತೇ ವಿದುರ್ಯುಕ್ತಚೇತಸಃ 7.॥30॥",
  
      "ಓಂ ತತ್ಸದಿತಿ  ಶ್ರೀಮದ್ಭಗವದ್ಗೀತಾಸು ಉಪನಿಷತ್ಸು ಬ್ರಹ್ಮವಿದ್ಯಾಯಾಂ(ಮ್)\nಯೋಗಶಾಸ್ತ್ರೇ ಶ್ರೀಕೃಷ್ಣಾರ್ಜುನಸಂವಾದೇ\nಜ್ಞಾನವಿಜ್ಞಾನಯೋಗೋ ನಾಮ ಸಪ್ತಮೋಽಧ್ಯಾಯಃ ॥7॥"
    ],
    "adhyaya-8" : [
      "ಅರ್ಜುನ ಉವಾಚ\nಕಿಂ(ನ್) ತದ್ಬ್ರಹ್ಮ ಕಿಮಧ್ಯಾತ್ಮಂ(ಙ್), ಕಿಂ(ಙ್) ಕರ್ಮ ಪುರುಷೋತ್ತಮ \nಅಧಿಭೂತಂ(ಞ್) ಚ ಕಿಂ(ಮ್) ಪ್ರೋಕ್ತಮ್, ಅಧಿದೈವಂ(ಙ್) ಕಿಮುಚ್ಯತೇ ॥8.1॥",
  
      "ಅಧಿಯಜ್ಞಃ(ಖ್) ಕಥಂ(ಙ್) ಕೋಽತ್ರ, ದೇಹೇಽಸ್ಮಿನ್ಮಧುಸೂದನ \nಪ್ರಯಾಣಕಾಲೇ ಚ ಕಥಂ(ಞ್), ಜ್ಞೇಯೋಽಸಿ ನಿಯತಾತ್ಮಭಿಃ ॥8.2॥",
  
      "ಶ್ರೀಭಗವಾನುವಾಚ\nಅಕ್ಷರಂ(ಮ್) ಬ್ರಹ್ಮ ಪರಮಂ(ಮ್), ಸ್ವಭಾವೋಽಧ್ಯಾತ್ಮಮುಚ್ಯತೇ \nಭೂತಭಾವೋದ್ಭವಕರೋ, ವಿಸರ್ಗಃ(ಖ್) ಕರ್ಮಸಂಜ್ಞಿತಃ ॥8.3॥",
  
      "ಅಧಿಭೂತಂ(ಙ್) ಕ್ಷರೋ ಭಾವಃ(ಫ್), ಪುರುಷಶ್ಚಾಧಿದೈವತಮ್ \nಅಧಿಯಜ್ಞೋಽಹಮೇವಾತ್ರ, ದೇಹೇ ದೇಹಭೃತಾಂ(ಮ್) ವರ ॥8.4॥",
  
      "ಅಂತಕಾಲೇ ಚ ಮಾಮೇವ, ಸ್ಮರನ್ಮುಕ್ತ್ವಾ ಕಲೇವರಮ್ \nಯಃ(ಫ್) ಪ್ರಯಾತಿ ಸ ಮದ್ಭಾವಂ(ಮ್), ಯಾತಿ ನಾಸ್ತ್ಯತ್ರ ಸಂಶಯಃ ॥8.5॥",
  
      "ಯಂ(ಮ್) ಯಂ(ಮ್) ವಾಪಿ ಸ್ಮರನ್ಭಾವಂ(ನ್), ತ್ಯಜತ್ಯಂತೇ ಕಲೇವರಮ್ \nತಂ(ನ್) ತಮೇವೈತಿ ಕೌಂತೇಯ, ಸದಾ ತದ್ಭಾವಭಾವಿತಃ ॥8.6॥",
  
      "ತಸ್ಮಾತ್ಸರ್ವೇಷು ಕಾಲೇಷು, ಮಾಮನುಸ್ಮರ ಯುಧ್ಯ ಚ\nಮಯ್ಯರ್ಪಿತಮನೋಬುದ್ಧಿಃ(ರ್), ಮಾಮೇವೈಷ್ಯಸ್ಯಸಂಶಯಮ್ ॥8.7॥",
  
      "ಅಭ್ಯಾಸಯೋಗಯುಕ್ತೇನ, ಚೇತಸಾ ನಾನ್ಯಗಾಮಿನಾ \nಪರಮಂ(ಮ್) ಪುರುಷಂ(ನ್) ದಿವ್ಯಂ(ಮ್), ಯಾತಿ ಪಾರ್ಥಾನುಚಿಂತಯನ್ ॥8.8॥",
  
      "ಕವಿಂ(ಮ್) ಪುರಾಣಮನುಶಾಸಿತಾರಮ್,\nಅಣೋರಣೀಯಂಸಮನುಸ್ಮರೇದ್ಯಃ\nಸರ್ವಸ್ಯ ಧಾತಾರಮಚಿಂತ್ಯರೂಪಮ್,\nಆದಿತ್ಯವರ್ಣಂ(ನ್) ತಮಸಃ(ಫ್) ಪರಸ್ತಾತ್ ॥8.9॥",
  
      "ಪ್ರಯಾಣಕಾಲೇ ಮನಸಾಽಚಲೇನ,\nಭಕ್ತ್ಯಾ ಯುಕ್ತೋ ಯೋಗಬಲೇನ ಚೈವ\nಭ್ರುವೋರ್ಮಧ್ಯೇ ಪ್ರಾಣಮಾವೇಶ್ಯ ಸಮ್ಯಕ್,\nಸ ತಂ(ಮ್) ಪರಂ(ಮ್) ಪುರುಷಮುಪೈತಿ ದಿವ್ಯಮ್ ॥8.10॥",
  
      "ಯದಕ್ಷರಂ(ಮ್) ವೇದವಿದೋ ವದಂತಿ,\nವಿಶಂತಿ ಯದ್ಯತಯೋ ವೀತರಾಗಾಃ\nಯದಿಚ್ಛಂತೋ ಬ್ರಹ್ಮಚರ್ಯಂ(ಞ್) ಚರಂತಿ,\nತತ್ತೇ ಪದಂ(ಮ್) ಸಂಗ್ರಹೇಣ ಪ್ರವಕ್ಷ್ಯೇ ॥8.11॥",
  
      "ಸರ್ವದ್ವಾರಾಣಿ ಸಂಯಮ್ಯ, ಮನೋ ಹೃದಿ ನಿರುಧ್ಯ ಚ \nಮೂರ್ಧ್ನ್ಯಾಧಾಯಾತ್ಮನಃ(ಫ್) ಪ್ರಾಣಮ್, ಆಸ್ಥಿತೋ ಯೋಗಧಾರಣಾಮ್ ॥8.12॥",
  
      "ಓಮಿತ್ಯೇಕಾಕ್ಷರಂ(ಮ್) ಬ್ರಹ್ಮ, ವ್ಯಾಹರನ್ಮಾಮನುಸ್ಮರನ್\nಯಃ(ಫ್) ಪ್ರಯಾತಿ ತ್ಯಜಂದೇಹಂ(ಮ್), ಸ ಯಾತಿ ಪರಮಾಂ(ಙ್) ಗತಿಮ್ ॥8.13॥",
  
      "ಅನನ್ಯಚೇತಾಃ(ಸ್) ಸತತಂ(ಮ್), ಯೋ ಮಾಂ(ಮ್) ಸ್ಮರತಿ ನಿತ್ಯಶಃ \nತಸ್ಯಾಹಂ(ಮ್) ಸುಲಭಃ(ಫ್) ಪಾರ್ಥ, ನಿತ್ಯಯುಕ್ತಸ್ಯ ಯೋಗಿನಃ ॥8.14॥",
  
      "ಮಾಮುಪೇತ್ಯ ಪುನರ್ಜನ್ಮ, ದುಃಖಾಲಯಮಶಾಶ್ವತಮ್ \nನಾಪ್ನುವಂತಿ ಮಹಾತ್ಮಾನಃ(ಸ್), ಸಂಸಿದ್ಧಿಂ(ಮ್) ಪರಮಾಂ(ಙ್) ಗತಾಃ ॥8.15॥",
  
      "ಆಬ್ರಹ್ಮಭುವನಾಲ್ಲೋಕಾಃ(ಫ್), ಪುನರಾವರ್ತಿನೋಽರ್ಜುನ \nಮಾಮುಪೇತ್ಯ ತು ಕೌಂತೇಯ, ಪುನರ್ಜನ್ಮ ನ ವಿದ್ಯತೇ ॥8.16॥",
  
      "ಸಹಸ್ರಯುಗಪರ್ಯಂತಮ್, ಅಹರ್ಯದ್ ಬ್ರಹ್ಮಣೋ ವಿದುಃ \nರಾತ್ರಿಂ(ಮ್) ಯುಗಸಹಸ್ರಾಂತಾಂ(ನ್), ತೇಽಹೋರಾತ್ರವಿದೋ ಜನಾಃ॥8.17॥",
  
      "ಅವ್ಯಕ್ತಾದ್ವ್ಯಕ್ತಯಃ(ಸ್) ಸರ್ವಾಃ(ಫ್), ಪ್ರಭವಂತ್ಯಹರಾಗಮೇ \nರಾತ್ರ್ಯಾಗಮೇ ಪ್ರಲೀಯಂತೇ, ತತ್ರೈವಾವ್ಯಕ್ತಸಂಜ್ಞಕೇ ॥8.18॥",
  
      "ಭೂತಗ್ರಾಮಃ(ಸ್) ಸ ಏವಾಯಂ(ಮ್), ಭೂತ್ವಾ ಭೂತ್ವಾ ಪ್ರಲೀಯತೇ \nರಾತ್ರ್ಯಾಗಮೇಽವಶಃ(ಫ್) ಪಾರ್ಥ, ಪ್ರಭವತ್ಯಹರಾಗಮೇ ॥8.19॥",
  
      "ಪರಸ್ತಸ್ಮಾತ್ತು ಭಾವೋಽನ್ಯೋಽ, ವ್ಯಕ್ತೋಽವ್ಯಕ್ತಾತ್ಸನಾತನಃ \nಯಃ(ಸ್) ಸ ಸರ್ವೇಷು ಭೂತೇಷು, ನಶ್ಯತ್ಸು ನ ವಿನಶ್ಯತಿ ॥8.20॥",
  
      "ಅವ್ಯಕ್ತೋಽಕ್ಷರ ಇತ್ಯುಕ್ತಃ(ಸ್), ತ​ಮಾಹುಃ(ಫ್) ಪರಮಾಂ(ಙ್) ಗತಿಮ್ \nಯಂ(ಮ್) ಪ್ರಾಪ್ಯ ನ ನಿವರ್ತಂತೇ, ತದ್ಧಾಮ ಪರಮಂ(ಮ್) ಮಮ ॥8.21॥",
  
      "ಪುರುಷಃ(ಸ್) ಸ ಪರಃ(ಫ್) ಪಾರ್ಥ, ಭಕ್ತ್ಯಾ ಲಭ್ಯಸ್ತ್ವನನ್ಯಯಾ \nಯಸ್ಯಾಂತಃಸ್ಥಾನಿ ಭೂತಾನಿ, ಯೇನ ಸರ್ವಮಿದಂ(ನ್) ತತಮ್ ॥8.22॥",
  
      "ಯತ್ರ ಕಾಲೇ ತ್ವನಾವೃತ್ತಿಮ್, ಆವೃತ್ತಿಂ(ಞ್) ಚೈವ ಯೋಗಿನಃ \nಪ್ರಯಾತಾ ಯಾಂತಿ ತಂ(ಙ್) ಕಾಲಂ(ಮ್), ವಕ್ಷ್ಯಾಮಿ ಭರತರ್ಷಭ ॥8.23॥",
  
      "ಅಗ್ನಿರ್ಜ್ಯೋತಿರಹಃ(ಶ್) ಶುಕ್ಲಃ(ಷ್), ಷಣ್ಮಾಸಾ ಉತ್ತರಾಯಣಮ್ \nತತ್ರ ಪ್ರಯಾತಾ ಗಚ್ಛಂತಿ, ಬ್ರಹ್ಮ ಬ್ರಹ್ಮವಿದೋ ಜನಾಃ ॥8.24॥",
  
      "ಧೂಮೋ ರಾತ್ರಿಸ್ತಥಾ ಕೃಷ್ಣಃ(ಷ್), ಷಣ್ಮಾಸಾ ದಕ್ಷಿಣಾಯನಮ್ \nತತ್ರ ಚಾಂದ್ರಮಸಂ(ಞ್) ಜ್ಯೋತಿಃ(ರ್), ಯೋಗೀ ಪ್ರಾಪ್ಯ ನಿವರ್ತತೇ ॥8.25॥",
  
      "ಶುಕ್ಲಕೃಷ್ಣೇ ಗತೀ ಹ್ಯೇತೇ, ಜಗತಃ(ಶ್) ಶಾಶ್ವತೇ ಮತೇ \nಏಕಯಾ ಯಾತ್ಯನಾವೃತ್ತಿಮ್, ಅನ್ಯಯಾವರ್ತತೇ ಪುನಃ ॥8.26॥",
  
      "ನೈತೇ ಸೃತೀ ಪಾರ್ಥ ಜಾನನ್, ಯೋಗೀ ಮುಹ್ಯತಿ ಕಶ್ಚನ \nತಸ್ಮಾತ್ಸರ್ವೇಷು ಕಾಲೇಷು, ಯೋಗಯುಕ್ತೋ ಭವಾರ್ಜುನ ॥8.27॥",
  
      "ವೇದೇಷು ಯಜ್ಞೇಷು ತಪಃ(ಸ್) ಸು ಚೈವ,\nದಾನೇಷು ಯತ್ಪುಣ್ಯಫಲಂ(ಮ್) ಪ್ರದಿಷ್ಟಮ್\nಅತ್ಯೇತಿ ತತ್ಸರ್ವಮಿದಂ(ಮ್) ವಿದಿತ್ವಾ,\nಯೋಗೀ ಪರಂ(ಮ್) ಸ್ಥಾನಮುಪೈತಿ ಚಾದ್ಯಮ್ ॥8.28॥",
  
      "ಓಂ ತತ್ಸದಿತಿ ಶ್ರೀಮದ್ಭಗವದ್ಗೀತಾಸು ಉಪನಿಷತ್ಸು\nಬ್ರಹ್ಮವಿದ್ಯಾಯಾಂ(ಮ್) ಯೋಗಶಾಸ್ತ್ರೇ ಶ್ರೀಕೃಷ್ಣಾರ್ಜುನಸಂವಾದೇ\nಅಕ್ಷರಬ್ರಹ್ಮಯೋಗೋ ನಾಮ ಅಷ್ಟಮೋಽಧ್ಯಾಯಃ ॥8॥"
    ],
    "adhyaya-9" : [
      "ಶ್ರೀಭಗವಾನುವಾಚ\nಇದಂ(ನ್) ತು ತೇ ಗುಹ್ಯತಮಂ(ಮ್), ಪ್ರವಕ್ಷ್ಯಾಮ್ಯನಸೂಯವೇ।\nಜ್ಞಾನಂ(ವ್) ವಿಜ್ಞಾನಸಹಿತಂ(ಯ್), ಯಜ್.ಜ್ಞಾತ್ವಾ ಮೋಕ್ಷ್ಯಸೇಽಶುಭಾತ್ ॥9.1॥",
  
      "ರಾಜವಿದ್ಯಾ ರಾಜಗುಹ್ಯಂ(ಮ್), ಪವಿತ್ರಮಿದಮುತ್ತಮಮ್ \nಪ್ರತ್ಯಕ್ಷಾವಗಮಂ(ನ್) ಧರ್ಮ್ಯಂ(ಮ್), ಸುಸುಖಂ(ಙ್) ಕರ್ತುಮವ್ಯಯಮ್ ।।9.2।।",
  
      "ಅಶ್ರದ್ದಧಾನಾಃ(ಫ್) ಪುರುಷಾ, ಧರ್ಮಸ್ಯಾಸ್ಯ ಪರಂತಪ \nಅಪ್ರಾಪ್ಯ ಮಾಂ(ನ್) ನಿವರ್ತಂತೇ, ಮೃತ್ಯುಸಂಸಾರವರ್ತ್ಮನಿ ।।9.3।।",
  
      "ಮಯಾ ತತಮಿದಂ(ಮ್) ಸರ್ವಂ(ಞ್), ಜಗದವ್ಯಕ್ತಮೂರ್ತಿನಾ \nಮತ್ಸ್ಥಾನಿ ಸರ್ವಭೂತಾನಿ, ನ ಚಾಹಂ(ನ್) ತೇಷ್ವವಸ್ಥಿತಃ ।।9.4।।",
  
      "ನ ಚ ಮತ್ಸ್ಥಾನಿ ಭೂತಾನಿ, ಪಶ್ಯ ಮೇ ಯೋಗಮೈಶ್ವರಮ್ \nಭೂತಭೃನ್ನ ಚ ಭೂತಸ್ಥೋ, ಮಮಾತ್ಮಾ ಭೂತಭಾವನಃ ।।9.5।।",
  
      "ಯಥಾಕಾಶಸ್ಥಿತೋ ನಿತ್ಯಂ(ವ್), ವಾಯುಃ(ಸ್) ಸರ್ವತ್ರಗೋ ಮಹಾನ್।\nತಥಾ ಸರ್ವಾಣಿ ಭೂತಾನಿ, ಮತ್ಸ್ಥಾನೀತ್ಯುಪಧಾರಯ ॥9.6॥",
  
      "ಸರ್ವಭೂತಾನಿ ಕೌಂತೇಯ, ಪ್ರಕೃತಿಂ(ಯ್) ಯಾಂತಿ ಮಾಮಿಕಾಮ್।\nಕಲ್ಪಕ್ಷಯೇ ಪುನಸ್ತಾನಿ, ಕಲ್ಪಾದೌ ವಿಸೃಜಾಮ್ಯಹಮ್ ॥9.7॥",
  
      "ಪ್ರಕೃತಿಂ(ಮ್) ಸ್ವಾಮವಷ್ಟಭ್ಯ, ವಿಸೃಜಾಮಿ ಪುನಃ(ಫ್) ಪುನಃ \nಭೂತಗ್ರಾಮಮಿಮಂ(ಙ್) ಕೃತ್ಸ್ನಮ್, ಅವಶಂ(ಮ್) ಪ್ರಕೃತೇರ್ವಶಾತ್ ।।9.8।।",
  
      "ನ ಚ ಮಾಂ(ನ್) ತಾನಿ ಕರ್ಮಾಣಿ, ನಿಬಧ್ನಂತಿ ಧನಂಜಯ \nಉದಾಸೀನವದಾಸೀನಮ್, ಅಸಕ್ತಂ(ನ್) ತೇಷು ಕರ್ಮಸು ।।9.9।।",
  
      "ಮಯಾಧ್ಯಕ್ಷೇಣ ಪ್ರಕೃತಿಃ(ಸ್), ಸೂಯತೇ ಸಚರಾಚರಮ್ \nಹೇತುನಾನೇನ ಕೌಂತೇಯ, ಜಗದ್ವಿಪರಿವರ್ತತೇ ।।9.10।।",
  
      "ಅವಜಾನಂತಿ ಮಾಂ(ಮ್) ಮೂಢಾ, ಮಾನುಷೀಂ(ನ್) ತನುಮಾಶ್ರಿತಮ್\nಪರಂ(ಮ್) ಭಾವಮಜಾನಂತೋ, ಮಮ ಭೂತಮಹೇಶ್ವರಮ್ ।।9.11।।",
  
      "ಮೋಘಾಶಾ ಮೋಘಕರ್ಮಾಣೋ,ಮೋಘಜ್ಞಾನಾ ವಿಚೇತಸಃ\nರಾಕ್ಷಸೀಮಾಸುರೀಂ(ಞ್) ಚೈವ,ಪ್ರಕೃತಿಂ(ಮ್) ಮೋಹಿನೀಂ(ಮ್) ಶ್ರಿತಾಃ ।।9.12।।",
  
      "ಮಹಾತ್ಮಾನಸ್ತು ಮಾಂ(ಮ್) ಪಾರ್ಥ, ದೈವೀಂ(ಮ್) ಪ್ರಕೃತಿಮಾಶ್ರಿತಾಃ\nಭಜಂತ್ಯನನ್ಯಮನಸೋ, ಜ್ಞಾತ್ವಾ ಭೂತಾದಿಮವ್ಯಯಮ್ ।।9.13।।",
  
      "ಸತತಂ(ಙ್) ಕೀರ್ತಯಂತೋ ಮಾಂ(ಯ್), ಯತಂತಶ್ಚ ದೃಢವ್ರತಾಃ।\nನಮಸ್ಯಂತಶ್ಚ ಮಾಂ(ಮ್) ಭಕ್ತ್ಯಾ, ನಿತ್ಯಯುಕ್ತಾ ಉಪಾ ಸತೇ9.॥14॥",
  
      "ಜ್ಞಾನಯಜ್ಞೇನ ಚಾಪ್ಯನ್ಯೇ, ಯಜಂತೋ ಮಾಮುಪಾಸತೇ\nಏಕತ್ವೇನ ಪೃಥಕ್ತ್ವೇನ, ಬಹುಧಾ ವಿಶ್ವತೋಮುಖಮ್ ।।9.15।।",
  
      "ಅಹಂ(ಙ್) ಕ್ರತುರಹಂ(ಯ್) ಯಜ್ಞಃ(ಸ್), ಸ್ವಧಾಹಮಹಮೌಷಧಮ್।\nಮಂತ್ರೋಽಹಮಹಮೇವಾಜ್ಯಮ್, ಅಹಮಗ್ನಿರಹಂ(ಮ್) ಹು ತಮ್9.॥16॥",
  
      "ಪಿತಾಹಮಸ್ಯ ಜಗತೋ, ಮಾತಾ ಧಾತಾ ಪಿತಾಮಹಃ\nವೇದ್ಯಂ(ಮ್) ಪವಿತ್ರಮೋಂಕಾರ, ಋಕ್ಸಾಮ ಯಜುರೇವ  ಚ।9.।17।",
  
      "ಗತಿರ್ಭರ್ತಾ ಪ್ರಭುಃ(ಸ್) ಸಾಕ್ಷೀ,ನಿವಾಸಃ(ಶ್) ಶರಣಂ(ಮ್) ಸುಹೃತ್\nಪ್ರಭವಃ(ಫ್) ಪ್ರಲಯಃ(ಸ್) ಸ್ಥಾನಂ(ನ್),ನಿಧಾನಂ(ಮ್) ಬೀಜಮವ್ಯಯಮ್ ।।9.18।।",
  
      "ತಪಾಮ್ಯಹಮಹಂ(ವ್) ವರ್ಷಂ(ನ್), ನಿಗೃಹ್ಣಾಮ್ಯುತ್ಸೃಜಾಮಿ ಚ।\nಅಮೃತಂ(ಞ್) ಚೈವ ಮೃತ್ಯುಶ್ಚ, ಸದಸಚ್ಚಾಹಮರ್ ಜುನ9.॥19॥",
  
      "ತ್ರೈವಿದ್ಯಾ ಮಾಂ(ಮ್) ಸೋಮಪಾಃ(ಫ್) ಪೂತಪಾಪಾ,\nಯಜ್ಞೈರಿಷ್ಟ್ವಾ ಸ್ವರ್ಗತಿಂ(ಮ್) ಪ್ರಾರ್ಥಯಂತೇ\nತೇ ಪುಣ್ಯಮಾಸಾದ್ಯ ಸುರೇಂದ್ರಲೋಕಮ್,\nಅಶ್ನಂತಿ ದಿವ್ಯಾಂದಿವಿ ದೇವಭೋಗಾನ್ ।।9.20।।",
  
      "ತೇ ತಂ(ಮ್) ಭುಕ್ತ್ವಾ ಸ್ವರ್ಗಲೋಕಂ(ವ್) ವಿಶಾಲಂ(ಙ್),\nಕ್ಷೀಣೇ ಪುಣ್ಯೇ ಮರ್ತ್ಯಲೋಕಂ(ವ್) ವಿಶಂತಿ।\nಏವಂ(ನ್) ತ್ರಯೀಧರ್ಮಮನುಪ್ರಪನ್ನಾ,\nಗತಾಗತಂ(ಙ್) ಕಾಮಕಾಮಾ ಲ ಭಂತೇ9.॥21॥",
  
      "ಅನನ್ಯಾಶ್ಚಿಂತಯಂತೋ ಮಾಂ(ಯ್),ಯೇ ಜನಾಃ(ಫ್) ಪರ್ಯುಪಾಸತೇ।\nತೇಷಾಂ(ನ್) ನಿತ್ಯಾಭಿಯುಕ್ತಾನಾಂ(ಯ್),ಯೋಗಕ್ಷೇಮಂ(ವ್) ವಹಾಮ್ಯ ಹಮ್ ॥9.22॥",
  
      "ಯೇಽಪ್ಯನ್ಯದೇವತಾ ಭಕ್ತಾ, ಯಜಂತೇ ಶ್ರದ್ಧಯಾನ್ವಿತಾಃ\nತೇಽಪಿ ಮಾಮೇವ ಕೌಂತೇಯ, ಯಜಂತ್ಯವಿಧಿಪೂರ್ವಕಮ್ ।।9.23।।",
  
      "ಅಹಂ(ಮ್) ಹಿ ಸರ್ವಯಜ್ಞಾನಾಂ(ಮ್), ಭೋಕ್ತಾ ಚ ಪ್ರಭುರೇವ ಚ\nನ ತು ಮಾಮಭಿಜಾನಂತಿ, ತತ್ತ್ವೇನಾತಶ್ಚ್ಯವಂತಿ  ತೇ।9.।24।।",
  
      "ಯಾಂತಿ ದೇವವ್ರತಾ ದೇವಾನ್, ಪಿತೄನ್ಯಾಂತಿ ಪಿತೃವ್ರತಾಃ\nಭೂತಾನಿ ಯಾಂತಿ ಭೂತೇಜ್ಯಾ, ಯಾಂತಿ ಮದ್ಯಾಜಿನೋಽಪಿ ಮಾಮ್ ।।9.25।।",
  
      "ಪತ್ರಂ(ಮ್) ಪುಷ್ಪಂ(ಮ್) ಫಲಂ(ನ್) ತೋಯಂ(ಯ್),ಯೋ ಮೇ ಭಕ್ತ್ಯಾ ಪ್ರಯಚ್ಛತಿ।\nತದಹಂ(ಮ್) ಭಕ್ತ್ಯುಪಹೃತಮ್,ಅಶ್ನಾಮಿ ಪ್ರಯತಾತ್ಮ ನಃ 9.॥26॥",
  
      "ಯತ್ಕರೋಷಿ ಯದಶ್ನಾಸಿ, ಯಜ್ಜುಹೋಷಿ ದದಾಸಿ ಯತ್\nಯತ್ತಪಸ್ಯಸಿ ಕೌಂತೇಯ, ತತ್ಕುರುಷ್ವ ಮದರ್ಪಣಮ್ ।।9.27।।",
  
      "ಶುಭಾಶುಭಫಲೈರೇವಂ(ಮ್), ಮೋಕ್ಷ್ಯಸೇ ಕರ್ಮಬಂಧನೈಃ\nಸಂನ್ಯಾಸಯೋಗಯುಕ್ತಾತ್ಮಾ, ವಿಮುಕ್ತೋ ಮಾಮುಪೈಷ್ಯಸಿ ।।9.28।।",
  
      "ಸಮೋಽಹಂ(ಮ್) ಸರ್ವಭೂತೇಷು,ನ ಮೇ ದ್ವೇಷ್ಯೋಽಸ್ತಿ ನ ಪ್ರಿಯಃ\nಯೇ ಭಜಂತಿ ತು ಮಾಂ(ಮ್) ಭಕ್ತ್ಯಾ,ಮಯಿ ತೇ ತೇಷು ಚಾಪ್ಯಹಮ್ ।।9.29।।",
  
      "ಅಪಿ ಚೇತ್ಸುದುರಾಚಾರೋ, ಭಜತೇ ಮಾಮನನ್ಯಭಾಕ್\nಸಾಧುರೇವ ಸ ಮಂತವ್ಯಃ(ಸ್), ಸಮ್ಯಗ್ವ್ಯವಸಿತೋ ಹಿ ಸಃ ।।9.30।।",
  
      "ಕ್ಷಿಪ್ರಂ(ಮ್) ಭವತಿ ಧರ್ಮಾತ್ಮಾ, ಶಶ್ವಚ್ಛಾಂತಿಂ(ನ್) ನಿಗಚ್ಛತಿ\nಕೌಂತೇಯ ಪ್ರತಿಜಾನೀಹಿ, ನ ಮೇ ಭಕ್ತಃ(ಫ್) ಪ್ರಣಶ್ಯತಿ ।।9.31।।",
  
      "ಮಾಂ(ಮ್) ಹಿ ಪಾರ್ಥ ವ್ಯಪಾಶ್ರಿತ್ಯ, ಯೇಽಪಿ ಸ್ಯುಃ(ಫ್) ಪಾಪಯೋನಯಃ\nಸ್ತ್ರಿಯೋ ವೈಶ್ಯಾಸ್ತಥಾ ಶೂದ್ರಾಃ(ಸ್),ತೇಽಪಿ ಯಾಂತಿ ಪರಾಂ(ಙ್) ಗತಿಮ್ ।।9.32।।",
  
      "ಕಿಂ(ಮ್) ಪುನರ್ಬ್ರಾಹ್ಮಣಾಃ(ಫ್) ಪುಣ್ಯಾ,ಭಕ್ತಾ ರಾಜರ್ಷಯಸ್ತಥಾ\nಅನಿತ್ಯಮಸುಖಂ(ಮ್) ಲೋಕಮ್,ಇಮಂ(ಮ್) ಪ್ರಾಪ್ಯ ಭಜಸ್ವ ಮಾಮ್ ।।9.33।।",
  
      "ಮನ್ಮನಾ ಭವ ಮದ್ಭಕ್ತೋ, ಮದ್ಯಾಜೀ ಮಾಂ(ನ್) ನಮಸ್ಕುರು\nಮಾಮೇವೈಷ್ಯಸಿ ಯುಕ್ತ್ವೈವಮ್, ಆತ್ಮಾನಂ(ಮ್) ಮತ್ಪರಾಯಣಃ ।।9.34।।",
  
      "ಓಂ ತತ್ಸದಿತಿ ಶ್ರೀಮದ್ಭಗವದ್ಗೀತಾಸು ಉಪನಿಷತ್ಸು\nಬ್ರಹ್ಮವಿದ್ಯಾಯಾಂ(ಯ್)  ಯೋಗಶಾಸ್ತ್ರೇ ಶ್ರೀಕೃಷ್ಣಾರ್ಜುನಸಂವಾದೇ\nರಾಜವಿದ್ಯಾರಾಜಗುಹ್ಯಯೋಗೋ ನಾಮ ನವಮೋಽಧ್ಯಾಯಃ ॥9॥"
    ],
    "adhyaya-10" : [
      "ಶ್ರೀಭಗವಾನುವಾಚ\nಭೂಯ ಏವ ಮಹಾಬಾಹೋ, ಶೃಣು ಮೇ ಪರಮಂ(ಮ್) ವಚಃ\nಯತ್ತೇಽಹಂ(ಮ್) ಪ್ರೀಯಮಾಣಾಯ, ವಕ್ಷ್ಯಾಮಿ ಹಿತಕಾಮ್ಯಯಾ ॥10.1॥",

      "ನ ಮೇ ವಿದುಃ(ಸ್) ಸುರಗಣಾಃ(ಫ್), ಪ್ರಭವಂ(ನ್) ನ ಮಹರ್ಷಯಃ\nಅಹಮಾದಿರ್ಹಿ ದೇವಾನಾಂ(ಮ್), ಮಹರ್ಷೀಣಾಂ(ಞ್) ಚ ಸರ್ವಶಃ ॥10.2॥",

      "ಯೋ ಮಾಮಜಮನಾದಿಂ(ಞ್) ಚ, ವೇತ್ತಿ ಲೋಕಮಹೇಶ್ವರಮ್\nಅಸಮ್ಮೂಢಃ(ಸ್) ಸ ಮರ್ತ್ಯೇಷು, ಸರ್ವಪಾಪೈಃ(ಫ್) ಪ್ರಮುಚ್ಯತೇ ॥10.3॥",

      "ಬುದ್ಧಿರ್ಜ್ಞಾನಮಸಮ್ಮೋಹಃ(ಖ್), ಕ್ಷಮಾ ಸತ್ಯಂ(ನ್) ದಮಃ(ಶ್) ಶಮಃ\nಸುಖಂ(ನ್) ದುಃಖಂ(ಮ್) ಭವೋಽಭಾವೋ, ಭಯಂ(ಞ್) ಚಾಭಯಮೇವ ॥10.4॥",

      "ಅಹಿಂಸಾ ಸಮತಾ ತುಷ್ಟಿಃ(ಸ್), ತಪೋ ದಾನಂ(ಮ್) ಯಶೋಽಯಶಃ\nಭವಂತಿ ಭಾವಾ ಭೂತಾನಾಂ(ಮ್), ಮತ್ತ ಏವ ಪೃಥಗ್ವಿಧಾಃ ॥10.5॥",

      "ಮಹರ್ಷಯಃ(ಸ್) ಸಪ್ತ ಪೂರ್ವೇ, ಚತ್ವಾರೋ ಮನವಸ್ತಥಾ\nಮದ್ಭಾವಾ ಮಾನಸಾ ಜಾತಾ, ಯೇಷಾಂ(ಮ್) ಲೋಕ ಇಮಾಃ(ಫ್) ಪ್ರಜಾಃ ॥10.6॥",

      "ಏತಾಂ(ಮ್) ವಿಭೂತಿಂ(ಮ್) ಯೋಗಂ(ಞ್) ಚ, ಮಮ ಯೋ ವೇತ್ತಿ ತತ್ತ್ವತಃ\nಸೋಽವಿಕಂಪೇನ ಯೋಗೇನ, ಯುಜ್ಯತೇ ನಾತ್ರ ಸಂಶಯಃ ॥10.7॥",

      "ಅಹಂ(ಮ್) ಸರ್ವಸ್ಯ ಪ್ರಭವೋ, ಮತ್ತಃ(ಸ್) ಸರ್ವಂ(ಮ್) ಪ್ರವರ್ತತೇ\nಇತಿ ಮತ್ವಾ ಭಜಂತೇ ಮಾಂ(ಮ್), ಬುಧಾ ಭಾವಸಮನ್ವಿತಾಃ ॥10.8॥",

      "ಮಚ್ಚಿತ್ತಾ ಮದ್ಗತಪ್ರಾಣಾ, ಬೋಧಯಂತಃ(ಫ್) ಪರಸ್ಪರಮ್\nಕಥಯಂತಶ್ಚ ಮಾಂ(ನ್) ನಿತ್ಯಂ(ನ್), ತುಷ್ಯಂತಿ ಚ ರಮಂತಿ ಚ ॥10.9॥",

      "ತೇಷಾಂ(ಮ್) ಸತತಯುಕ್ತಾನಾಂ(ಮ್), ಭಜತಾಂ(ಮ್) ಪ್ರೀತಿಪೂರ್ವಕಮ್\nದದಾಮಿ ಬುದ್ಧಿಯೋಗಂ(ನ್) ತಂ(ಮ್), ಯೇನ ಮಾಮುಪಯಾಂತಿ ತೇ ॥10.10॥",

      "ತೇಷಾಮೇವಾನುಕಂಪಾರ್ಥಮ್, ಅಹಮಜ್ಞಾನಜಂ(ನ್) ತಮಃ\nನಾಶಯಾಮ್ಯಾತ್ಮಭಾವಸ್ಥೋ, ಜ್ಞಾನದೀಪೇನ ಭಾಸ್ವತಾ ॥10.11॥",

      "ಅರ್ಜುನ ಉವಾಚ\nಪರಂ(ಮ್) ಬ್ರಹ್ಮ ಪರಂ(ನ್) ಧಾಮ, ಪವಿತ್ರಂ(ಮ್) ಪರಮಂ(ಮ್) ಭವಾನ್\nಪುರುಷಂ(ಮ್) ಶಾಶ್ವತಂ(ನ್) ದಿವ್ಯಮ್, ಆದಿದೇವಮಜಂ(ಮ್) ವಿಭುಮ್ ॥10.12॥",

      "ಆಹುಸ್ತ್ವಾಮೃಷಯಃ(ಸ್) ಸರ್ವೇ, ದೇವರ್ಷಿರ್ನಾರದಸ್ತಥಾ\nಅಸಿತೋ ದೇವಲೋ ವ್ಯಾಸಃ(ಸ್), ಸ್ವಯಂ(ಞ್) ಚೈವ ಬ್ರವೀಷಿ ಮೇ ॥10.13॥",

      "ಸರ್ವಮೇತದೃತಂ(ಮ್) ಮನ್ಯೇ, ಯನ್ಮಾಂ(ಮ್) ವದಸಿ ಕೇಶವ\nನ ಹಿ ತೇ ಭಗವನ್ವ್ಯಕ್ತಿಂ(ಮ್), ವಿದುರ್ದೇವಾ ನ ದಾನವಾಃ ॥10.14॥",

      "ಸ್ವಯಮೇವಾತ್ಮನಾತ್ಮಾನಂ(ಮ್), ವೇತ್ಥ ತ್ವಂ(ಮ್) ಪುರುಷೋತ್ತಮ\nಭೂತಭಾವನ ಭೂತೇಶ, ದೇವದೇವ ಜಗತ್ಪತೇ ॥10.15॥",

      "ವಕ್ತುಮರ್ಹಸ್ಯಶೇಷೇಣ, ದಿವ್ಯಾ ಹ್ಯಾತ್ಮವಿಭೂತಯಃ\nಯಾಭಿರ್ವಿಭೂತಿಭಿರ್ಲೋಕಾನ್, ಇಮಾಂಸ್ತ್ವಂ(ಮ್) ವ್ಯಾಪ್ಯ ತಿಷ್ಠಸಿ ॥10.16॥",

      "ಕಥಂ(ಮ್) ವಿದ್ಯಾಮಹಂ(ಮ್) ಯೋಗಿಂಸ್, ತ್ವಾಂ(ಮ್) ಸದಾ ಪರಿಚಿಂತಯನ್\nಕೇಷು ಕೇಷು ಚ ಭಾವೇಷು, ಚಿಂತ್ಯೋಽಸಿ ಭಗವನ್ಮಯಾ ॥10.17॥",
 
      "ವಿಸ್ತರೇಣಾತ್ಮನೋ ಯೋಗಂ(ಮ್), ವಿಭೂತಿಂ(ಞ್) ಚ ಜನಾರ್ದನ\nಭೂಯಃ ಕಥಯ ತೃಪ್ತಿರ್ಹಿ, ಶೃಣ್ವತೋ ನಾಸ್ತಿ ಮೇಽಮೃತಮ್ ॥10.18॥",

      "ಶ್ರೀಭಗವಾನುವಾಚ\nಹಂತ ತೇ ಕಥಯಿಷ್ಯಾಮಿ, ದಿವ್ಯಾ ಹ್ಯಾತ್ಮವಿಭೂತಯಃ\nಪ್ರಾಧಾನ್ಯತಃ(ಖ್) ಕುರುಶ್ರೇಷ್ಠ, ನಾಸ್ತ್ಯಂತೋ ವಿಸ್ತರಸ್ಯ ಮೇ ॥10.19॥",

      "ಅಹಮಾತ್ಮಾ ಗುಡಾಕೇಶ, ಸರ್ವಭೂತಾಶಯಸ್ಥಿತಃ\nಅಹಮಾದಿಶ್ಚ ಮಧ್ಯಂ(ಞ್) ಚ, ಭೂತಾನಾಮಂತ ಏವ ಚ ॥10.20॥",

      "ಆದಿತ್ಯಾನಾಮಹಂ(ಮ್) ವಿಷ್ಣುಃ(ರ್), ಜ್ಯೋತಿಷಾಂ(ಮ್) ರವಿರಂಶುಮಾನ್\nಮರೀಚಿರ್ಮರುತಾಮಸ್ಮಿ, ನಕ್ಷತ್ರಾಣಾಮಹಂ(ಮ್) ಶಶೀ ॥10.21॥",

      "ವೇದಾನಾಂ(ಮ್) ಸಾಮವೇದೋಽಸ್ಮಿ, ದೇವಾನಾಮಸ್ಮಿ ವಾಸವಃ\nಇಂದ್ರಿಯಾಣಾಂ(ಮ್) ಮನಶ್ಚಾಸ್ಮಿ, ಭೂತಾನಾಮಸ್ಮಿ ಚೇತನಾ ॥10.22॥ ",

      "ರುದ್ರಾಣಾಂ(ಮ್) ಶಂಕರಶ್ಚಾಸ್ಮಿ, ವಿತ್ತೇಶೋ ಯಕ್ಷರಕ್ಷಸಾಮ್\nವಸೂನಾಂ(ಮ್) ಪಾವಕಶ್ಚಾಸ್ಮಿ, ಮೇರುಃ(ಶ್) ಶಿಖರಿಣಾಮಹಮ್ ॥10.23॥",

      "ಪುರೋಧಸಾಂ(ಞ್) ಚ ಮುಖ್ಯಂ(ಮ್) ಮಾಂ(ಮ್), ವಿದ್ಧಿ ಪಾರ್ಥ ಬೃಹಸ್ಪತಿಮ್\nಸೇನಾನೀನಾಮಹಂ(ಮ್) ಸ್ಕಂದಃ(ಸ್), ಸರಸಾಮಸ್ಮಿ ಸಾಗರಃ ॥10.24॥",

      "ಮಹರ್ಷೀಣಾಂ(ಮ್) ಭೃಗುರಹಂ(ಙ್), ಗಿರಾಮಸ್ಮ್ಯೇಕಮಕ್ಷರಮ್\nಯಜ್ಞಾನಾಂ(ಞ್) ಜಪಯಜ್ಞೋಽಸ್ಮಿ, ಸ್ಥಾವರಾಣಾಂ(ಮ್) ಹಿಮಾಲಯಃ ॥10.25॥",

      "ಅಶ್ವತ್ಥಃ(ಸ್) ಸರ್ವವೃಕ್ಷಾಣಾಂ(ನ್), ದೇವರ್ಷೀಣಾಂ(ಞ್) ಚ ನಾರದಃ\nಗಂಧರ್ವಾಣಾಂ(ಞ್) ಚಿತ್ರರಥಃ(ಸ್), ಸಿದ್ಧಾನಾಂ(ಙ್) ಕಪಿಲೋ ಮುನಿಃ ॥10.26॥",

      "ಉಚ್ಚೈಃಶ್ರವಸಮಶ್ವಾನಾಂ(ಮ್), ವಿದ್ಧಿ ಮಾಮಮೃತೋದ್ಭವಮ್,\nಐರಾವತಂ(ಙ್) ಗಜೇಂದ್ರಾಣಾಂ(ನ್), ನರಾಣಾಂ(ಞ್) ಚ ನರಾಧಿಪಮ್ ॥10.27॥",

      "ಆಯುಧಾನಾಮಹಂ(ಮ್) ವಜ್ರಂ(ನ್), ಧೇನೂನಾಮಸ್ಮಿ ಕಾಮಧುಕ್\nಪ್ರಜನಶ್ಚಾಸ್ಮಿ ಕಂದರ್ಪಃ(ಸ್), ಸರ್ಪಾಣಾಮಸ್ಮಿ ವಾಸುಕಿಃ ॥10.28॥",

      "ಅನಂತಶ್ಚಾಸ್ಮಿ ನಾಗಾನಾಂ(ಮ್), ವರುಣೋ ಯಾದಸಾಮಹಮ್\nಪಿತೄಣಾಮರ್ಯಮಾ ಚಾಸ್ಮಿ, ಯಮಃ(ಸ್) ಸಂಯಮತಾಮಹಮ್ ॥10.29॥",

      "ಪ್ರಹ್ಲಾದಶ್ಚಾಸ್ಮಿ ದೈತ್ಯಾನಾಂ(ಙ್), ಕಾಲಃ(ಖ್) ಕಲಯತಾಮಹಮ್\nಮೃಗಾಣಾಂ(ಞ್) ಚ ಮೃಗೇಂದ್ರೋಽಹಂ(ಮ್), ವೈನತೇಯಶ್ಚ ಪಕ್ಷಿಣಾಮ್ ॥10.30॥",

      "ಪವನಃ(ಫ್) ಪವತಾಮಸ್ಮಿ, ರಾಮಃ(ಶ್) ಶಸ್ತ್ರಭೃತಾಮಹಮ್\nಝಷಾಣಾಂ(ಮ್) ಮಕರಶ್ಚಾಸ್ಮಿ, ಸ್ರೋತಸಾಮಸ್ಮಿ ಜಾಹ್ನವೀ ॥10.31॥",

      "ಸರ್ಗಾಣಾಮಾದಿರಂತಶ್ಚ, ಮಧ್ಯಂ(ಞ್) ಚೈವಾಹಮರ್ಜುನ\nಅಧ್ಯಾತ್ಮವಿದ್ಯಾ ವಿದ್ಯಾನಾಂ(ಮ್), ವಾದಃ(ಫ್) ಪ್ರವದತಾಮಹಮ್ ॥10.32॥",

      "ಅಕ್ಷರಾಣಾಮಕಾರೋಽಸ್ಮಿ, ದ್ವಂದ್ವಃ(ಸ್) ಸಾಮಾಸಿಕಸ್ಯ ಚ\nಅಹಮೇವಾಕ್ಷಯಃ(ಖ್) ಕಾಲೋ, ಧಾತಾಹಂ(ಮ್) ವಿಶ್ವತೋಮುಖಃ ॥10.33॥",

      "ಮೃತ್ಯುಃ(ಸ್) ಸರ್ವಹರಶ್ಚಾಹಮ್, ಉದ್ಭವಶ್ಚ ಭವಿಷ್ಯತಾಮ್\nಕೀರ್ತಿಃ(ಶ್) ಶ್ರೀರ್ವಾಕ್ಚ ನಾರೀಣಾಂ(ಮ್), ಸ್ಮೃತಿರ್ಮೇಧಾ ಧೃತಿಃ(ಖ್) ಕ್ಷಮಾ ॥10.34॥",

      "ಬೃಹತ್ಸಾಮ ತಥಾ ಸಾಮ್ನಾಂ(ಙ್), ಗಾಯತ್ರೀ ಛಂದಸಾಮಹಮ್\nಮಾಸಾನಾಂ(ಮ್) ಮಾರ್ಗಶೀರ್ಷೋಽಹಮ್, ಋತೂನಾಂ(ಙ್) ಕುಸುಮಾಕರಃ ॥10.35॥",

      "ದ್ಯೂತಂ(ಞ್) ಛಲಯತಾಮಸ್ಮಿ, ತೇಜಸ್ತೇಜಸ್ವಿನಾಮಹಮ್\nಜಯೋಽಸ್ಮಿ ವ್ಯವಸಾಯೋಽಸ್ಮಿ, ಸತ್ತ್ವಂ(ಮ್) ಸತ್ತ್ವವತಾಮಹಮ್ ॥10.36॥",

      "ವೃಷ್ಣೀನಾಂ(ಮ್) ವಾಸುದೇವೋಽಸ್ಮಿ, ಪಾಂಡವಾನಾಂ(ನ್) ಧನಂಜಯಃ\nಮುನೀನಾಮಪ್ಯಹಂ(ಮ್) ವ್ಯಾಸಃ(ಖ್), ಕವೀನಾಮುಶನಾ ಕವಿಃ ॥10.37॥",

      "ದಂಡೋ ದಮಯತಾಮಸ್ಮಿ, ನೀತಿರಸ್ಮಿ ಜಿಗೀಷತಾಮ್\nಮೌನಂ(ಞ್) ಚೈವಾಸ್ಮಿ ಗುಹ್ಯಾನಾಂ(ಞ್), ಜ್ಞಾನಂ(ಞ್) ಜ್ಞಾನವತಾಮಹಮ್ ॥10.38॥",

      "ಯಚ್ಚಾಪಿ ಸರ್ವಭೂತಾನಾಂ(ಮ್), ಬೀಜಂ(ನ್) ತದಹಮರ್ಜುನ\nನ ತದಸ್ತಿ ವಿನಾ ಯತ್ಸ್ಯಾನ್, ಮಯಾ ಭೂತಂ(ಞ್) ಚರಾಚರಮ್ ॥10.39॥",

      "ನಾಂತೋಽಸ್ತಿ ಮಮ ದಿವ್ಯಾನಾಂ(ಮ್), ವಿಭೂತೀನಾಂ(ಮ್) ಪರಂತಪ\nಏಷ ತೂದ್ದೇಶತಃ(ಫ್) ಪ್ರೋಕ್ತೋ, ವಿಭೂತೇರ್ವಿಸ್ತರೋ ಮಯಾ ॥10.40॥",

      "ಯದ್ಯದ್ವಿಭೂತಿಮತ್ಸತ್ತ್ವಂ(ಮ್), ಶ್ರೀಮದೂರ್ಜಿತಮೇವ ವಾ\nತತ್ತದೇವಾವಗಚ್ಛ ತ್ವಂ(ಮ್), ಮಮ ತೇಜೋಂಽಶಸಂಭವಮ್ ॥10.41॥",

      "ಅಥವಾ ಬಹುನೈತೇನ, ಕಿಂ(ಞ್) ಜ್ಞಾತೇನ ತವಾರ್ಜುನ\nವಿಷ್ಟಭ್ಯಾಹಮಿದಂ(ಙ್) ಕೃತ್ಸ್ನಮ್, ಏಕಾಂಶೇನ ಸ್ಥಿತೋ ಜಗತ್ ॥10.42॥",

      "ಓಂ ತತ್ಸದಿತಿ ಶ್ರೀಮದ್ಭಗವದ್ಗೀತಾಸು ಉಪನಿಷತ್ಸು\nಬ್ರಹ್ಮವಿದ್ಯಾಯಾಂ(ಮ್) ಯೋಗಶಾಸ್ತ್ರೇ ಶ್ರೀಕೃಷ್ಣಾರ್ಜುನಸಂವಾದೇ\nವಿಭೂತಿಯೋಗೋ ನಾಮ ದಶಮೋಽಧ್ಯಾಯಃ ॥10॥"
    ],
    "adhyaya-11" : [
      "ಅರ್ಜುನ ಉವಾಚ\nಮದನುಗ್ರಹಾಯ ಪರಮಂ(ಙ್), ಗುಹ್ಯಮಧ್ಯಾತ್ಮಸಂಜ್ಞಿತಮ್ \nಯತ್ತ್ವಯೋಕ್ತಂ(ಮ್) ವಚಸ್ತೇನ, ಮೋಹೋಽಯಂ(ಮ್) ವಿಗತೋ ಮ ಮ11.॥1॥",

      "ಭವಾಪ್ಯಯೌ ಹಿ ಭೂತಾನಾಂ(ಮ್), ಶ್ರುತೌ ವಿಸ್ತರಶೋ ಮಯಾ\nತ್ವತ್ತಃ(ಖ್) ಕಮಲಪತ್ರಾಕ್ಷ, ಮಾಹಾತ್ಮ್ಯಮಪಿ ಚಾವ್ಯಯ ಮ್11.॥2॥",

      "ಏವಮೇತದ್ಯಥಾತ್ಥ ತ್ವಮ್, ಆತ್ಮಾನಂ(ಮ್) ಪರಮೇಶ್ವರ\nದ್ರಷ್ಟುಮಿಚ್ಛಾಮಿ ತೇ ರೂಪಮ್, ಐಶ್ವರಂ(ಮ್) ಪುರುಷೋತ್ತ ಮ11.॥3॥",

      "ಮನ್ಯಸೇ ಯದಿ ತಚ್ಛಕ್ಯಂ(ಮ್), ಮಯಾ ದ್ರಷ್ಟುಮಿತಿ ಪ್ರಭೋ\nಯೋಗೇಶ್ವರ ತತೋ ಮೇ ತ್ವಂ(ನ್), ದರ್ಶಯಾತ್ಮಾನಮವ್ಯಯ ಮ್11.॥4॥",

      "ಶ್ರೀಭಗವಾನುವಾಚ\nಪಶ್ಯ ಮೇ ಪಾರ್ಥ ರೂಪಾಣಿ, ಶತಶೋಽಥ ಸಹಸ್ರಶಃ\nನಾನಾವಿಧಾನಿ ದಿವ್ಯಾನಿ, ನಾನಾವರ್ಣಾಕೃತೀನಿ  ಚ11.॥5॥",

      "ಪಶ್ಯಾದಿತ್ಯಾನ್ವಸೂನ್ರುದ್ರಾನ್, ಅಶ್ವಿನೌ ಮರುತಸ್ತಥಾ\nಬಹೂನ್ಯದೃಷ್ಟಪೂರ್ವಾಣಿ, ಪಶ್ಯಾಶ್ಚರ್ಯಾಣಿ ಭಾರ ತ11.॥6॥",

      "ಇಹೈಕಸ್ಥಂ(ಞ್) ಜಗತ್ಕೃತ್ಸ್ನಂ(ಮ್), ಪಶ್ಯಾದ್ಯ ಸಚರಾಚರಮ್\nಮಮ ದೇಹೇ ಗುಡಾಕೇಶ, ಯಚ್ಚಾನ್ಯದ್ ದ್ರಷ್ಟುಮಿಚ್ಛ ಸಿ11.॥7॥",

      "ನ ತು ಮಾಂ(ಮ್) ಶಕ್ಯಸೇ ದ್ರಷ್ಟುಮ್, ಅನೇನೈವ ಸ್ವಚಕ್ಷುಷಾ\nದಿವ್ಯಂ(ನ್) ದದಾಮಿ ತೇ ಚಕ್ಷುಃ(ಫ್), ಪಶ್ಯ ಮೇ ಯೋಗಮೈಶ್ವರ ಮ್11.॥8॥",

      "ಸಂಜಯ ಉವಾಚ\nಏವಮುಕ್ತ್ವಾ ತತೋ ರಾಜನ್, ಮಹಾಯೋಗೇಶ್ವರೋ ಹರಿಃ\nದರ್ಶಯಾಮಾಸ ಪಾರ್ಥಾಯ, ಪರಮಂ(ಮ್) ರೂಪಮೈಶ್ವರ ಮ್11.॥9॥",

      "ಅನೇಕವಕ್ತ್ರನಯನಮ್, ಅನೇಕಾದ್ಭುತದರ್ಶನಮ್\nಅನೇಕದಿವ್ಯಾಭರಣಂ(ನ್), ದಿವ್ಯಾನೇಕೋದ್ಯತಾಯುಧಮ್ ॥11.10॥",

      "ದಿವ್ಯಮಾಲ್ಯಾಂಬರಧರಂ(ನ್), ದಿವ್ಯಗಂಧಾನುಲೇಪನಮ್\nಸರ್ವಾಶ್ಚರ್ಯಮಯಂ(ನ್) ದೇವಮ್, ಅನಂತಂ(ಮ್) ವಿಶ್ವತೋಮುಖಮ್ ॥11.11॥",

      "ದಿವಿ ಸೂರ್ಯಸಹಸ್ರಸ್ಯ, ಭವೇದ್ಯುಗಪದುತ್ಥಿತಾ\nಯದಿ ಭಾಃ(ಸ್) ಸದೃಶೀ ಸಾ ಸ್ಯಾದ್, ಭಾಸಸ್ತಸ್ಯ ಮಹಾತ್ಮನಃ ॥11.12॥",

      "ತತ್ರೈಕಸ್ಥಂ(ಞ್) ಜಗತ್ಕೃತ್ಸ್ನಂ(ಮ್), ಪ್ರವಿಭಕ್ತಮನೇಕಧಾ\nಅಪಶ್ಯದ್ದೇವದೇವಸ್ಯ, ಶರೀರೇ ಪಾಂಡವಸ್ತದಾ ॥11.13॥",

      "ತತಃ(ಸ್) ಸ ವಿಸ್ಮಯಾವಿಷ್ಟೋ, ಹೃಷ್ಟರೋಮಾ ಧನಂಜಯಃ\nಪ್ರಣಮ್ಯ ಶಿರಸಾ ದೇವಂ(ಙ್), ಕೃತಾಂಜಲಿರಭಾಷತ ॥11.14॥",

      "ಅರ್ಜುನ ಉವಾಚ\nಪಶ್ಯಾಮಿ ದೇವಾಂಸ್ತವ ದೇವ ದೇಹೇ,\nಸರ್ವಾಂಸ್ತಥಾ ಭೂತವಿಶೇಷಸಂಘಾನ್ \nಬ್ರಹ್ಮಾಣಮೀಶಂ(ಙ್) ಕಮಲಾಸನಸ್ಥಮ್,\nಋಷೀಂಶ್ಚ ಸರ್ವಾನುರಗಾಂಶ್ಚ ದಿವ್ಯಾನ್ ॥11.15॥",

      "ಅನೇಕಬಾಹೂದರವಕ್ತ್ರನೇತ್ರಂ(ಮ್),\nಪಶ್ಯಾಮಿ ತ್ವಾಂ(ಮ್) ಸರ್ವತೋಽನಂತರೂಪಮ್ \nನಾಂತಂ(ನ್) ನ ಮಧ್ಯಂ(ನ್) ನ ಪುನಸ್ತವಾದಿಂ(ಮ್),\nಪಶ್ಯಾಮಿ ವಿಶ್ವೇಶ್ವರ ವಿಶ್ವರೂಪ ॥11.16॥",

      "ಕಿರೀಟಿನಂ(ಙ್) ಗದಿನಂ ಚಕ್ರಿಣಂ(ಞ್) ಚ,\nತೇಜೋರಾಶಿಂ(ಮ್) ಸರ್ವತೋ ದೀಪ್ತಿಮಂತಮ್ \nಪಶ್ಯಾಮಿ ತ್ವಾಂ(ನ್) ದುರ್ನಿರೀಕ್ಷ್ಯಂ(ಮ್) ಸಮಂತಾದ್,\nದೀಪ್ತಾನಲಾರ್ಕದ್ಯುತಿಮಪ್ರಮೇಯಮ್ ॥11.17॥",

      "ತ್ವಮಕ್ಷರಂ(ಮ್) ಪರಮಂ(ಮ್) ವೇದಿತವ್ಯಂ(ನ್), \nತ್ವಮಸ್ಯ ವಿಶ್ವಸ್ಯ ಪರಂ(ನ್) ನಿಧಾನಮ್ \nತ್ವಮವ್ಯಯಃ(ಶ್) ಶಾಶ್ವತಧರ್ಮಗೋಪ್ತಾ,\nಸನಾತನಸ್ತ್ವಂ(ಮ್) ಪುರುಷೋ ಮತೋ ಮೇ ॥11.18॥",

      "ಅನಾದಿಮಧ್ಯಾಂತಮನಂತವೀರ್ಯಮ್,\nಅನಂತಬಾಹುಂ(ಮ್) ಶಶಿಸೂರ್ಯನೇತ್ರಮ್ \nಪಶ್ಯಾಮಿ ತ್ವಾಂ(ನ್) ದೀಪ್ತಹುತಾಶವಕ್ತ್ರಂ(ಮ್),\nಸ್ವತೇಜಸಾ ವಿಶ್ವಮಿದಂ(ನ್) ತಪಂತಮ್ ॥11.19॥",

      "ದ್ಯಾವಾಪೃಥಿವ್ಯೋರಿದಮಂತರಂ(ಮ್) ಹಿ,\nವ್ಯಾಪ್ತಂ(ನ್) ತ್ವಯೈಕೇನ ದಿಶಶ್ಚ ಸರ್ವಾಃ \nದೃಷ್ಟ್ವಾದ್ಭುತಂ(ಮ್) ರೂಪಮುಗ್ರಂ(ನ್) ತವೇದಂ(ಮ್),\nಲೋಕತ್ರಯಂ(ಮ್) ಪ್ರವ್ಯಥಿತಂ(ಮ್) ಮಹಾತ್ಮನ್ ॥11.20॥",

      "ಅಮೀ ಹಿ ತ್ವಾಂ(ಮ್) ಸುರಸಂಘಾ ವಿಶಂತಿ,\nಕೇಚಿದ್ಭೀತಾಃ(ಫ್) ಪ್ರಾಂಜಲಯೋ ಗೃಣಂತಿ \nಸ್ವಸ್ತೀತ್ಯುಕ್ತ್ವಾ ಮಹರ್ಷಿಸಿದ್ಧಸಂಘಾಃ(ಸ್),\nಸ್ತುವಂತಿ ತ್ವಾಂ(ಮ್) ಸ್ತುತಿಭಿಃ(ಫ್) ಪುಷ್ಕಲಾಭಿಃ ॥11.21॥",

      "ರುದ್ರಾದಿತ್ಯಾ ವಸವೋ ಯೇ ಚ ಸಾಧ್ಯಾ,\nವಿಶ್ವೇಽಶ್ವಿನೌ ಮರುತಶ್ಚೋಷ್ಮಪಾಶ್ಚ \nಗಂಧರ್ವಯಕ್ಷಾಸುರಸಿದ್ಧಸಂಘಾ,\nವೀಕ್ಷಂತೇ ತ್ವಾಂ(ಮ್) ವಿಸ್ಮಿತಾಶ್ಚೈವ ಸರ್ವೇ ॥11.22॥",

      "ರೂಪಂ(ಮ್) ಮಹತ್ತೇ ಬಹುವಕ್ತ್ರನೇತ್ರಂ(ಮ್),\nಮಹಾಬಾಹೋ ಬಹುಬಾಹೂರುಪಾದಮ್ \nಬಹೂದರಂ(ಮ್) ಬಹುದಂಷ್ಟ್ರಾಕರಾಲಂ(ನ್),\nದೃಷ್ಟ್ವಾ ಲೋಕಾಃ(ಫ್) ಪ್ರವ್ಯಥಿತಾಸ್ತಥಾಹಮ್ ॥11.23॥",

      "ನಭಃ(ಸ್)ಸ್ಪೃಶಂ(ನ್) ದೀಪ್ತಮನೇಕವರ್ಣಂ(ಮ್),\nವ್ಯಾತ್ತಾನನಂ(ನ್) ದೀಪ್ತವಿಶಾಲನೇತ್ರಮ್ \nದೃಷ್ಟ್ವಾ ಹಿ ತ್ವಾಂ(ಮ್) ಪ್ರವ್ಯಥಿತಾಂತರಾತ್ಮಾ,\nಧೃತಿಂ(ನ್) ನ ವಿಂದಾಮಿ ಶಮಂ(ಞ್) ಚ ವಿಷ್ಣೋ ॥11.24॥",

      "ದಂಷ್ಟ್ರಾಕರಾಲಾನಿ ಚ ತೇ ಮುಖಾನಿ,\nದೃಷ್ಟ್ವೈವ ಕಾಲಾನಲಸನ್ನಿಭಾನಿ \nದಿಶೋ ನ ಜಾನೇ ನ ಲಭೇ ಚ ಶರ್ಮ,\nಪ್ರಸೀದ ದೇವೇಶ ಜಗನ್ನಿವಾಸ ॥11.25॥",

      "ಅಮೀ ಚ ತ್ವಾಂ(ನ್) ಧೃತರಾಷ್ಟ್ರಸ್ಯ ಪುತ್ರಾಃ(ಸ್),\nಸರ್ವೇ ಸಹೈವಾವನಿಪಾಲಸಂಘೈಃ \nಭೀಷ್ಮೋ ದ್ರೋಣಃ(ಸ್) ಸೂತಪುತ್ರಸ್ತಥಾಸೌ,\nಸಹಾಸ್ಮದೀಯೈರಪಿ ಯೋಧಮುಖ್ಯೈಃ ॥11.26॥",

      "ವಕ್ತ್ರಾಣಿ ತೇ ತ್ವರಮಾಣಾ ವಿಶಂತಿ,\nದಂಷ್ಟ್ರಾಕರಾಲಾನಿ ಭಯಾನಕಾನಿ \nಕೇಚಿದ್ವಿಲಗ್ನಾ ದಶನಾಂತರೇಷು,\nಸಂದೃಶ್ಯಂತೇ ಚೂರ್ಣಿತೈರುತ್ತಮಾಂಗೈಃ ॥11.27॥",

      "ಯಥಾ ನದೀನಾಂ(ಮ್) ಬಹವೋಽಮ್ಬುವೇಗಾಃ(ಸ್),\nಸಮುದ್ರಮೇವಾಭಿಮುಖಾ ದ್ರವಂತಿ \nತಥಾ ತವಾಮೀ ನರಲೋಕವೀರಾ,\nವಿಶಂತಿ ವಕ್ತ್ರಾಣ್ಯಭಿವಿಜ್ವಲಂತಿ ॥11.28॥",

      "ಯಥಾ ಪ್ರದೀಪ್ತಂ(ಞ್) ಜ್ವಲನಂ(ಮ್) ಪತಂಗಾ,\nವಿಶಂತಿ ನಾಶಾಯ ಸಮೃದ್ಧವೇಗಾಃ \nತಥೈವ ನಾಶಾಯ ವಿಶಂತಿ ಲೋಕಾಃ(ಸ್),\nತವಾಪಿ ವಕ್ತ್ರಾಣಿ ಸಮೃದ್ಧವೇಗಾಃ ॥11.29॥",

      "ಲೇಲಿಹ್ಯಸೇ ಗ್ರಸಮಾನಃ(ಸ್) ಸಮಂತಾಲ್,\nಲೋಕಾನ್ಸಮಗ್ರಾನ್ವದನೈರ್ಜ್ವಲದ್ಭಿಃ\nತೇಜೋಭಿರಾಪೂರ್ಯ ಜಗತ್ಸಮಗ್ರಂ(ಮ್),\nಭಾಸಸ್ತವೋಗ್ರಾಃ(ಫ್) ಪ್ರತಪಂತಿ ವಿಷ್ಣೋ ॥11.30॥",

      "ಆಖ್ಯಾಹಿ ಮೇ ಕೋ ಭವಾನುಗ್ರರೂಪೋ,\nನಮೋಽಸ್ತು ತೇ ದೇವವರ ಪ್ರಸೀದ \nವಿಜ್ಞಾತುಮಿಚ್ಛಾಮಿ ಭವಂತಮಾದ್ಯಂ(ನ್),\nನ ಹಿ ಪ್ರಜಾನಾಮಿ ತವ ಪ್ರವೃತ್ತಿಮ್ ॥11.31॥",

      "ಶ್ರೀಭಗವಾನುವಾಚ\nಕಾಲೋಽಸ್ಮಿ ಲೋಕಕ್ಷಯಕೃತ್ಪ್ರವೃದ್ಧೋ,\nಲೋಕಾನ್ಸಮಾಹರ್ತುಮಿಹ ಪ್ರವೃತ್ತಃ \nಋತೇಽಪಿ ತ್ವಾಂ(ನ್) ನ ಭವಿಷ್ಯಂತಿ ಸರ್ವೇ,\nಯೇಽವಸ್ಥಿತಾಃ(ಫ್) ಪ್ರತ್ಯನೀಕೇಷು ಯೋಧಾಃ ॥11.32॥",

      "ತಸ್ಮಾತ್ತ್ವಮುತ್ತಿಷ್ಠ ಯಶೋ ಲಭಸ್ವ,\nಜಿತ್ವಾ ಶತ್ರೂನ್ ಭುಂಕ್ಷ್ವ ರಾಜ್ಯಂ(ಮ್) ಸಮೃದ್ಧಮ್ \nಮಯೈವೈತೇ ನಿಹತಾಃ(ಫ್) ಪೂರ್ವಮೇವ,\nನಿಮಿತ್ತಮಾತ್ರಂ(ಮ್) ಭವ ಸವ್ಯಸಾಚಿನ್ ॥11.33॥",

      "ದ್ರೋಣಂ(ಞ್) ಚ ಭೀಷ್ಮಂ(ಞ್) ಚ ಜಯದ್ರಥಂ(ಞ್) ಚ,\nಕರ್ಣಂ(ನ್) ತಥಾನ್ಯಾನಪಿ ಯೋಧವೀರಾನ್ \nಮಯಾ ಹತಾಂಸ್ತ್ವಂ(ಞ್) ಜಹಿ ಮಾ ವ್ಯಥಿಷ್ಠಾ,\nಯುಧ್ಯಸ್ವ ಜೇತಾಸಿ ರಣೇ ಸಪತ್ನಾನ್ ॥11.34॥",

      "ಸಂಜಯ ಉವಾಚ\nಏತಚ್ಛ್ರುತ್ವಾ ವಚನಂ(ಙ್) ಕೇಶವಸ್ಯ,\nಕೃತಾಂಜಲಿರ್ವೇಪಮಾನಃ(ಖ್) ಕಿರೀಟೀ \nನಮಸ್ಕೃತ್ವಾ ಭೂಯ ಏವಾಹ ಕೃಷ್ಣಂ(ಮ್),\nಸಗದ್ಗದಂ(ಮ್) ಭೀತಭೀತಃ(ಫ್) ಪ್ರಣಮ್ಯ ॥11.35॥",

      "ಅರ್ಜುನ ಉವಾಚ\nಸ್ಥಾನೇ ಹೃಷೀಕೇಶ ತವ ಪ್ರಕೀರ್ತ್ಯಾ,\nಜಗತ್ಪ್ರಹೃಷ್ಯತ್ಯನುರಜ್ಯತೇ ಚ \nರಕ್ಷಾಂಸಿ ಭೀತಾನಿ ದಿಶೋ ದ್ರವಂತಿ,\nಸರ್ವೇ ನಮಸ್ಯಂತಿ ಚ ಸಿದ್ಧಸಂಘಾಃ ॥11.36॥",

      "ಕಸ್ಮಾಚ್ಚ ತೇ ನ ನಮೇರನ್ಮಹಾತ್ಮನ್,\nಗರೀಯಸೇ ಬ್ರಹ್ಮಣೋಽಪ್ಯಾದಿಕರ್ತ್ರೇ \nಅನಂತ ದೇವೇಶ ಜಗನ್ನಿವಾಸ,\nತ್ವಮಕ್ಷರಂ(ಮ್) ಸದಸತ್ತತ್ಪರಂ(ಮ್) ಯತ್ ॥11.37॥",

      "ತ್ವಮಾದಿದೇವಃ(ಫ್) ಪುರುಷಃ(ಫ್) ಪುರಾಣಃ(ಸ್),\nತ್ವ​ಮಸ್ಯ ವಿಶ್ವಸ್ಯ ಪರಂ(ನ್) ನಿಧಾನಮ್ \nವೇತ್ತಾಸಿ ವೇದ್ಯಂ(ಞ್) ಚ ಪರಂ(ಞ್) ಚ ಧಾಮ,\nತ್ವಯಾ ತತಂ(ಮ್) ವಿಶ್ವಮನಂತರೂಪ ॥11.38॥",

      "ವಾಯುರ್ಯಮೋಽಗ್ನಿರ್ವರುಣಃ(ಶ್) ಶಶಾಂಕಃ(ಫ್),\nಪ್ರಜಾಪತಿಸ್ತ್ವಂ(ಮ್) ಪ್ರಪಿತಾಮಹಶ್ಚ \nನಮೋ ನಮಸ್ತೇಽಸ್ತು ಸಹಸ್ರಕೃತ್ವಃ(ಫ್),\nಪುನಶ್ಚ ಭೂಯೋಽಪಿ ನಮೋ ನಮಸ್ತೇ ॥11.39॥",

      "ನಮಃ(ಫ್) ಪುರಸ್ತಾದಥ ಪೃಷ್ಠತಸ್ತೇ,\nನಮೋಽಸ್ತು ತೇ ಸರ್ವತ ಏವ ಸರ್ವ \nಅನಂತವೀರ್ಯಾಮಿತವಿಕ್ರಮಸ್ತ್ವಂ(ಮ್),\nಸರ್ವಂ(ಮ್) ಸಮಾಪ್ನೋಷಿ ತತೋಽಸಿ ಸರ್ವಃ ॥11.40॥",

      "ಸಖೇತಿ ಮತ್ವಾ ಪ್ರಸಭಂ(ಮ್) ಯದುಕ್ತಂ(ಮ್),\nಹೇ ಕೃಷ್ಣ ಹೇ ಯಾದವ ಹೇ ಸಖೇತಿ \nಅಜಾನತಾ ಮಹಿಮಾನಂ(ನ್) ತವೇದಂ(ಮ್),\nಮಯಾ ಪ್ರಮಾದಾತ್ಪ್ರಣಯೇನ ವಾಪಿ ॥11.41॥",

      "ಯಚ್ಚಾವಹಾಸಾರ್ಥಮಸತ್ಕೃತೋಽಸಿ,\nವಿಹಾರಶಯ್ಯಾಸನಭೋಜನೇಷು\nಏಕೋಽಥವಾಪ್ಯಚ್ಯುತ ತತ್ಸಮಕ್ಷಂ(ನ್),\nತತ್ಕಾಮಯೇ ತ್ವಾಮಹಮಪ್ರಮೇಯಮ್ ॥11.42॥",

      "ಪಿತಾಸಿ ಲೋಕಸ್ಯ ಚರಾಚರಸ್ಯ,\nತ್ವಮಸ್ಯ ಪೂಜ್ಯಶ್ಚ ಗುರುರ್ಗರೀಯಾನ್ \nನ ತ್ವತ್ಸಮೋಽಸ್ತ್ಯಭ್ಯಧಿಕಃ(ಖ್) ಕುತೋಽನ್ಯೋ,\nಲೋಕತ್ರಯೇಽಪ್ಯಪ್ರತಿಮಪ್ರಭಾವ ॥11.43॥",

      "ತಸ್ಮಾತ್ಪ್ರಣಮ್ಯ ಪ್ರಣಿಧಾಯ ಕಾಯಂ(ಮ್),\nಪ್ರಸಾದಯೇ ತ್ವಾಮಹಮೀಶಮೀಡ್ಯಮ್ \nಪಿತೇವ ಪುತ್ರಸ್ಯ ಸಖೇವ ಸಖ್ಯುಃ(ಫ್),\nಪ್ರಿಯಃ(ಫ್) ಪ್ರಿಯಾಯಾರ್ಹಸಿ ದೇವ ಸೋಢುಮ್ ॥11.44॥",

      "ಅದೃಷ್ಟಪೂರ್ವಂ(ಮ್) ಹೃಷಿತೋಽಸ್ಮಿ ದೃಷ್ಟ್ವಾ,\nಭಯೇನ ಚ ಪ್ರವ್ಯಥಿತಂ(ಮ್) ಮನೋ ಮೇ \nತದೇವ ಮೇ ದರ್ಶಯ ದೇವ ರೂಪಂ(ಮ್),\nಪ್ರಸೀದ ದೇವೇಶ ಜಗನ್ನಿವಾಸ ॥11.45॥",

      "ಕಿರೀಟಿನಂ(ಙ್) ಗದಿನಂ(ಞ್) ಚಕ್ರಹಸ್ತಮ್,\nಇಚ್ಛಾಮಿ ತ್ವಾಂ(ನ್) ದ್ರಷ್ಟುಮಹಂ(ನ್) ತಥೈವ  \nತೇನೈವ ರೂಪೇಣ ಚತುರ್ಭುಜೇನ,\nಸಹಸ್ರಬಾಹೋ ಭವ ವಿಶ್ವಮೂರ್ತೇ ॥11.46॥",

      "ಶ್ರೀಭಗವಾನುವಾಚ\nಮಯಾ ಪ್ರಸನ್ನೇನ ತವಾರ್ಜುನೇದಂ(ಮ್),\nರೂಪಂ(ಮ್) ಪರಂ(ನ್) ದರ್ಶಿತಮಾತ್ಮಯೋಗಾತ್ \nತೇಜೋಮಯಂ(ಮ್) ವಿಶ್ವಮನಂತಮಾದ್ಯಂ(ಮ್),\nಯನ್ಮೇ ತ್ವದನ್ಯೇನ ನ ದೃಷ್ಟಪೂರ್ವಮ್ ॥11.47॥",

      "ನ ವೇದಯಜ್ಞಾಧ್ಯಯನೈರ್ನ ದಾನೈಃ(ರ್),\nನ ಚ ಕ್ರಿಯಾಭಿರ್ನ ತಪೋಭಿರುಗ್ರೈಃ \nಏವಂರೂಪಃ(ಶ್) ಶಕ್ಯ ಅಹಂ(ನ್) ನೃಲೋಕೇ,\nದ್ರಷ್ಟುಂ(ನ್) ತ್ವದನ್ಯೇನ ಕುರುಪ್ರವೀರ ॥11.48॥",

      "ಮಾ ತೇ ವ್ಯಥಾ ಮಾ ಚ ವಿಮೂಢಭಾವೋ,\nದೃಷ್ಟ್ವಾ ರೂಪಂ(ಙ್) ಘೋರಮೀದೃಙ್ಮಮೇದಮ್ \nವ್ಯಪೇತಭೀಃ(ಫ್) ಪ್ರೀತಮನಾಃ(ಫ್) ಪುನಸ್ತ್ವಂ(ನ್),\nತದೇವ ಮೇ ರೂಪಮಿದಂ(ಮ್) ಪ್ರಪಶ್ಯ ॥11.49॥",

      "ಸಂಜಯ ಉವಾಚ\nಇತ್ಯರ್ಜುನಂ(ಮ್) ವಾಸುದೇವಸ್ತಥೋಕ್ತ್ವಾ,\nಸ್ವಕಂ(ಮ್) ರೂಪಂ(ನ್) ದರ್ಶಯಾಮಾಸ ಭೂಯಃ \nಆಶ್ವಾಸಯಾಮಾಸ ಚ ಭೀತಮೇನಂ(ಮ್),\nಭೂತ್ವಾ ಪುನಃ(ಸ್) ಸೌಮ್ಯವಪುರ್ಮಹಾತ್ಮಾ ॥11.50॥",

      "ಅರ್ಜುನ ಉವಾಚ\nದೃಷ್ಟ್ವೇದಂ(ಮ್) ಮಾನುಷಂ(ಮ್) ರೂಪಂ(ನ್), ತವ ಸೌಮ್ಯಂ(ಞ್) ಜನಾರ್ದನ \nಇದಾನೀಮಸ್ಮಿ ಸಂವೃತ್ತಃ(ಸ್), ಸಚೇತಾಃ(ಫ್) ಪ್ರಕೃತಿಂ(ಙ್) ಗತಃ ॥11.51॥",

      "ಶ್ರೀಭಗವಾನುವಾಚ\nಸುದುರ್ದರ್ಶಮಿದಂ(ಮ್) ರೂಪಂ(ನ್), ದೃಷ್ಟವಾನಸಿ ಯನ್ಮಮ \nದೇವಾ ಅಪ್ಯಸ್ಯ ರೂಪಸ್ಯ, ನಿತ್ಯಂ(ನ್) ದರ್ಶನಕಾಂಕ್ಷಿಣಃ ॥11.52॥",

      "ನಾಹಂ(ಮ್) ವೇದೈರ್ನ ತಪಸಾ, ನ ದಾನೇನ ನ ಚೇಜ್ಯಯಾ \nಶಕ್ಯ ಏವಂವಿಧೋ ದ್ರಷ್ಟುಂ(ನ್), ದೃಷ್ಟವಾನಸಿ ಮಾಂ(ಮ್) ಯಥಾ ॥11.53॥",

      "ಭಕ್ತ್ಯಾ ತ್ವನನ್ಯಯಾ ಶಕ್ಯ, ಅಹಮೇವಂವಿಧೋಽರ್ಜುನ\nಜ್ಞಾತುಂ(ನ್) ದ್ರಷ್ಟುಂ(ಞ್) ಚ ತತ್ತ್ವೇನ, ಪ್ರವೇಷ್ಟುಂ(ಞ್) ಚ ಪರಂತಪ ॥11.54॥",

      "ಮತ್ಕರ್ಮಕೃನ್ಮತ್ಪರಮೋ, ಮದ್ಭಕ್ತಃ(ಸ್) ಸಂಗವರ್ಜಿತಃ \nನಿರ್ವೈರಃ(ಸ್) ಸರ್ವಭೂತೇಷು, ಯಃ(ಸ್) ಸ ಮಾಮೇತಿ ಪಾಂಡವ ॥11.55॥",

      "ಓಂ ತತ್ಸದಿತಿ ಶ್ರೀಮದ್ಭಗವದ್ಗೀತಾಸು ಉಪನಿಷತ್ಸು\nಬ್ರಹ್ಮವಿದ್ಯಾಯಾಂ(ಮ್) ಯೋಗಶಾಸ್ತ್ರೇ ಶ್ರೀಕೃಷ್ಣಾರ್ಜುನಸಂವಾದೇ\nವಿಶ್ವರೂಪದರ್ಶನಯೋಗೋ ನಾಮೈಕಾದಶೋಽಧ್ಯಾಯಃ ॥11॥"
    ],
    "adhyaya-12" : [
      "ಅರ್ಜುನ ಉವಾಚ\nಏವಂ(ಮ್) ಸತತಯುಕ್ತಾ ಯೇ, ಭಕ್ತಾಸ್ತ್ವಾಂ(ಮ್) ಪರ್ಯುಪಾಸತೇ |\nಯೇ ಚಾಪ್ಯಕ್ಷರಮವ್ಯಕ್ತಂ(ನ್), ತೇಷಾಂ(ಙ್) ಕೇ ಯೋಗವಿತ್ತಮಾಃ ।12.।1।।",

      "ಶ್ರೀಭಗವಾನುವಾಚ\nಮಯ್ಯಾವೇಶ್ಯ ಮನೋ ಯೇ ಮಾಂ(ನ್), ನಿತ್ಯಯುಕ್ತಾ ಉಪಾಸತೇ |\nಶ್ರದ್ಧಯಾ ಪರಯೋಪೇತಾಃ(ಸ್), ತೇ ಮೇ ಯುಕ್ತತಮಾ ಮತಾಃ ।12.।2।।",

      "ಯೇ ತ್ವಕ್ಷರಮನಿರ್ದೇಶ್ಯಮ್, ಅವ್ಯಕ್ತಂ(ಮ್) ಪರ್ಯುಪಾಸತೇ |\nಸರ್ವತ್ರಗಮಚಿಂತ್ಯಂ(ಞ್) ಚ, ಕೂಟಸ್ಥಮಚಲಂ(ನ್) ಧ್ರುವಮ್ ।12.।3।।",

      "ಸನ್ನಿಯಮ್ಯೇಂದ್ರಿಯಗ್ರಾಮಂ(ಮ್), ಸರ್ವತ್ರ ಸಮಬುದ್ಧಯಃ |\nತೇ ಪ್ರಾಪ್ನುವಂತಿ ಮಾಮೇವ, ಸರ್ವಭೂತಹಿತೇ ರತಾಃ  ।12.।4।।",

      "ಕ್ಲೇಶೋಽಧಿಕತರಸ್ತೇಷಾಮ್, ಅವ್ಯಕ್ತಾಸಕ್ತಚೇತಸಾಮ್ |\nಅವ್ಯಕ್ತಾ ಹಿ ಗತಿರ್ದುಃಖಂ(ನ್), ದೇಹವದ್ಭಿರವಾಪ್ಯತೇ  ।12.।5।।",

      "ಯೇ ತು ಸರ್ವಾಣಿ ಕರ್ಮಾಣಿ, ಮಯಿ ಸಂನ್ಯಸ್ಯ ಮತ್ಪರಾಃ |\nಅನನ್ಯೇನೈವ ಯೋಗೇನ, ಮಾಂ(ನ್) ಧ್ಯಾಯಂತ ಉಪಾಸತೇ ।12.।6।।",

      "ತೇಷಾಮಹಂ(ಮ್) ಸಮುದ್ಧರ್ತಾ, ಮೃತ್ಯುಸಂಸಾರಸಾಗರಾತ್ |\nಭವಾಮಿ ನಚಿರಾತ್ಪಾರ್ಥ, ಮಯ್ಯಾವೇಶಿತಚೇತಸಾಮ್ ।12.।7।।",

      "ಮಯ್ಯೇವ ಮನ ಆಧತ್ಸ್ವ, ಮಯಿ ಬುದ್ಧಿಂ(ನ್) ನಿವೇಶಯ |\nನಿವಸಿಷ್ಯಸಿ ಮಯ್ಯೇವ, ಅತ ಊರ್ಧ್ವಂ(ನ್) ನ ಸಂಶಯಃ ।12.।8।।",

      "ಅಥ ಚಿತ್ತಂ(ಮ್) ಸಮಾಧಾತುಂ(ನ್), ನ ಶಕ್ನೋಷಿ ಮಯಿ ಸ್ಥಿರಮ್ |\nಅಭ್ಯಾಸಯೋಗೇನ ತತೋ, ಮಾಮಿಚ್ಛಾಪ್ತುಂ(ನ್) ಧನಂಜಯ ।12.।9।।",

      "ಅಭ್ಯಾಸೇಽಪ್ಯಸಮರ್ಥೋಽಸಿ, ಮತ್ಕರ್ಮಪರಮೋ ಭವ |\nಮದರ್ಥಮಪಿ ಕರ್ಮಾಣಿ ,ಕುರ್ವನ್ಸಿದ್ಧಿಮವಾಪ್ಸ್ಯಸಿ ।।12.10।।",

      "ಅಥೈತದಪ್ಯಶಕ್ತೋಽಸಿ, ಕರ್ತುಂ(ಮ್) ಮದ್ಯೋಗಮಾಶ್ರಿತಃ |\nಸರ್ವಕರ್ಮಫಲತ್ಯಾಗಂ(ನ್), ತತಃ(ಖ್) ಕುರು ಯತಾತ್ಮವಾನ್ ।।12.11।।",

      "ಶ್ರೇಯೋ ಹಿ ಜ್ಞಾನಮಭ್ಯಾಸಾಜ್, ಜ್ಞಾನಾದ್ಧ್ಯಾನಂ(ವ್) ವಿಶಿಷ್ಯತೇ |\nಧ್ಯಾನಾತ್ಕರ್ಮಫಲತ್ಯಾಗಃ(ಸ್), ತ್ಯಾಗಾಚ್ಛಾಂತಿರನಂತರಮ ।।12.12।।",

      "ಅದ್ವೇಷ್ಟಾ ಸರ್ವಭೂತಾನಾಂ(ಮ್), ಮೈತ್ರಃ(ಖ್) ಕರುಣ ಏವ ಚ |\nನಿರ್ಮಮೋ ನಿರಹಂಕಾರಃ(ಸ್), ಸಮದುಃಖಸುಖಃ(ಹ್) ಕ್ಷಮೀ  ।।12.13।।",

      "ಸಂತುಷ್ಟಃ(ಸ್) ಸತತಂ(ಯ್) ಯೋಗೀ, ಯತಾತ್ಮಾ ದೃಢನಿಶ್ಚಯಃ |\nಮಯ್ಯರ್ಪಿತಮನೋಬುದ್ಧಿಃ(ರ್), ಯೋ ಮದ್ಭಕ್ತಃ(ಸ್) ಸ ಮೇ ಪ್ರಿಯಃ ।।12.14।।",

      "ಯಸ್ಮಾನ್ನೋದ್ವಿಜತೇ ಲೋಕೋ, ಲೋಕಾನ್ನೋದ್ವಿಜತೇ ಚ ಯಃ |\nಹರ್ಷಾಮರ್ಷಭಯೋದ್ವೇಗೈಃ(ರ್), ಮುಕ್ತೋ ಯಃ(ಸ್) ಸ ಚ ಮೇ ಪ್ರಿಯಃ ।।12.15।।",

      "ಅನಪೇಕ್ಷಃ(ಶ್) ಶುಚಿರ್ದಕ್ಷ, ಉದಾಸೀನೋ ಗತವ್ಯಥಃ |\nಸರ್ವಾರಂಭಪರಿತ್ಯಾಗೀ, ಯೋ ಮದ್ಭಕ್ತಃ(ಸ್) ಸ ಮೇ ಪ್ರಿಯಃ ।।12.16।।",

      "ಯೋ ನ ಹೃಷ್ಯತಿ ನ ದ್ವೇಷ್ಟಿ, ನ ಶೋಚತಿ ನ ಕಾಂಕ್ಷತಿ |\nಶುಭಾಶುಭಪರಿತ್ಯಾಗೀ, ಭಕ್ತಿಮಾನ್ಯಃ(ಸ್) ಸ ಮೇ ಪ್ರಿಯಃ ।।12.17।।",

      "ಸಮಃ(ಶ್) ಶತ್ರೌ ಚ ಮಿತ್ರೇ ಚ, ತಥಾ ಮಾನಾಪಮಾನಯೋಃ |\nಶೀತೋಷ್ಣಸುಖದುಃಖೇಷು, ಸಮಃ(ಸ್) ಸಂಗವಿವರ್ಜಿತಃ ।।12.18।।",

      "ತುಲ್ಯನಿಂದಾಸ್ತುತಿರ್ಮೌನೀ, ಸಂತುಷ್ಟೋ ಯೇನ ಕೇನಚಿತ್ |\nಅನಿಕೇತಃ(ಸ್) ಸ್ಥಿರಮತಿಃ(ರ್), ಭಕ್ತಿಮಾನ್ಮೇ ಪ್ರಿಯೋ ನರಃ ।।12.19।।",

      "ಯೇ ತು ಧರ್ಮ್ಯಾಮೃತಮಿದಂ(ಯ್), ಯಥೋಕ್ತಂ(ಮ್) ಪರ್ಯುಪಾಸತೇ |\nಶ್ರದ್ದಧಾನಾ ಮತ್ಪರಮಾ, ಭಕ್ತಾಸ್ತೇಽತೀವ ಮೇ ಪ್ರಿಯಾಃ ।।12.20।।",

      "ಓಂ ತತ್ಸದಿತಿ ಶ್ರೀಮದ್ಭಗವದ್ಗೀತಾಸು ಉಪನಿಷತ್ಸು ಬ್ರಹ್ಮವಿದ್ಯಾಯಾಂ(ಯ್)ಯೋಗಶಾಸ್ತ್ರೇ\nಶ್ರೀಕೃಷ್ಣಾರ್ಜುನಸಂವಾದೇ ಭಕ್ತಿಯೋಗೋ ನಾಮ ದ್ವಾದಶೋಽಧ್ಯಾಯಃ ॥12॥"
    ],
    "adhyaya-13" : [
      "ಶ್ರೀಭಗವಾನುವಾಚ\nಇದಂ(ಮ್) ಶರೀರಂ(ಙ್) ಕೌಂತೇಯ, ಕ್ಷೇತ್ರಮಿತ್ಯಭಿಧೀಯತೇ \nಏತದ್ಯೋ ವೇತ್ತಿ ತಂ(ಮ್) ಪ್ರಾಹುಃ, ಕ್ಷೇತ್ರಜ್ಞ ಇತಿ ತದ್ವಿದಃ ॥13.1॥",

      "ಕ್ಷೇತ್ರಜ್ಞಂ(ಞ್) ಚಾಪಿ ಮಾಂ(ಮ್) ವಿದ್ಧಿ, ಸರ್ವಕ್ಷೇತ್ರೇಷು ಭಾರತ\nಕ್ಷೇತ್ರಕ್ಷೇತ್ರಜ್ಞಯೋರ್ಜ್ಞಾನಂ(ಮ್), ಯತ್ತಜ್ಜ್ಞಾನಂ(ಮ್) ಮತಂ(ಮ್) ಮಮ ॥13.2॥",
      
      "ತತ್ಕ್ಷೇತ್ರಂ(ಮ್) ಯಚ್ಚ ಯಾದೃಕ್ಚ, ಯದ್ವಿಕಾರಿ ಯತಶ್ಚ ಯತ್ \nಸ ಚ ಯೋ ಯತ್ಪ್ರಭಾವಶ್ಚ, ತತ್ಸಮಾಸೇನ ಮೇ ಶೃಣು ॥13.3॥",
      
      "ಋಷಿಭಿರ್ಬಹುಧಾ ಗೀತಂ(ಞ್), ಛಂದೋಭಿರ್ವಿವಿಧೈಃ(ಫ್) ಪೃಥಕ್ \nಬ್ರಹ್ಮಸೂತ್ರಪದೈಶ್ಚೈವ, ಹೇತುಮದ್ಭಿರ್ವಿನಿಶ್ಚಿತೈಃ ॥13.4॥",
      
      "ಮಹಾಭೂತಾನ್ಯಹಂಕಾರೋ, ಬುದ್ಧಿರವ್ಯಕ್ತಮೇವ ಚ \nಇಂದ್ರಿಯಾಣಿ ದಶೈಕಂ(ಞ್) ಚ, ಪಂಚ ಚೇಂದ್ರಿಯಗೋಚರಾಃ ॥13.5॥",
      
      "ಇಚ್ಛಾ ದ್ವೇಷಃ(ಸ್) ಸುಖಂ(ನ್) ದುಃಖಂ(ಮ್), ಸಂಘಾತಶ್ಚೇತನಾ ಧೃತಿಃ \nಏತತ್ಕ್ಷೇತ್ರಂ(ಮ್) ಸಮಾಸೇನ, ಸವಿಕಾರಮುದಾಹೃತಮ್ ॥13.6॥",
      
      "ಅಮಾನಿತ್ವಮದಂಭಿತ್ವಮ್, ಅಹಿಂಸಾ ಕ್ಷಾಂತಿರಾರ್ಜವಮ್ \nಆಚಾರ್ಯೋಪಾಸನಂ(ಮ್) ಶೌಚಂ(ಮ್), ಸ್ಥೈರ್ಯಮಾತ್ಮವಿನಿಗ್ರಹಃ ॥13.7॥",
      
      "ಇಂದ್ರಿಯಾರ್ಥೇಷು ವೈರಾಗ್ಯಮ್, ಅನಹಂಕಾರ ಏವ ಚ \nಜನ್ಮಮೃತ್ಯುಜರಾವ್ಯಾಧಿ, ದುಃಖದೋಷಾನುದರ್ಶನಮ್ ॥13.8॥",
      
      "ಅಸಕ್ತಿರನಭಿಷ್ವಂಗಃ(ಫ್), ಪುತ್ರದಾರಗೃಹಾದಿಷು \nನಿತ್ಯಂ(ಞ್) ಚ ಸಮಚಿತ್ತತ್ವಮ್, ಇಷ್ಟಾನಿಷ್ಟೋಪಪತ್ತಿಷು ॥13.9॥",
      
      "ಮಯಿ ಚಾನನ್ಯಯೋಗೇನ, ಭಕ್ತಿರವ್ಯಭಿಚಾರಿಣೀ \nವಿವಿಕ್ತದೇಶಸೇವಿತ್ವಮ್, ಅರತಿರ್ಜನಸಂಸದಿ ॥13.10॥",
      
      "ಅಧ್ಯಾತ್ಮಜ್ಞಾನನಿತ್ಯತ್ವಂ(ನ್), ತತ್ತ್ವಜ್ಞಾನಾರ್ಥದರ್ಶನಮ್\nಏತಜ್ಜ್ಞಾನಮಿತಿ ಪ್ರೋಕ್ತಮ್, ಅಜ್ಞಾನಂ(ಮ್) ಯದತೋಽನ್ಯಥಾ ॥13.11॥",
      
      "ಜ್ಞೇಯಂ(ಮ್) ಯತ್ತತ್ಪ್ರವಕ್ಷ್ಯಾಮಿ, ಯಜ್ಜ್ಞಾತ್ವಾಮೃತಮಶ್ನುತೇ\nಅನಾದಿಮತ್ಪರಂ(ಮ್) ಬ್ರಹ್ಮ, ನ ಸತ್ತನ್ನಾಸದುಚ್ಯತೇ ॥13.12॥",
      
      "ಸರ್ವತಃ(ಫ್) ಪಾಣಿಪಾದಂ(ನ್) ತತ್, ಸ​ರ್ವತೋಽಕ್ಷಿಶಿರೋಮುಖಮ್\nಸರ್ವತಃ(ಶ್) ಶ್ರುತಿಮಲ್ಲೋಕೇ, ಸರ್ವಮಾವೃತ್ಯ ತಿಷ್ಠತಿ ॥13.13॥",
      
      "ಸರ್ವೇಂದ್ರಿಯಗುಣಾಭಾಸಂ(ಮ್), ಸರ್ವೇಂದ್ರಿಯವಿವರ್ಜಿತಮ್\nಅಸಕ್ತಂ(ಮ್) ಸರ್ವಭೃಚ್ಚೈವ, ನಿರ್ಗುಣಂ(ಙ್) ಗುಣಭೋಕ್ತೃ ಚ ॥13.14॥",
      
      "ಬಹಿರಂತಶ್ಚ ಭೂತಾನಾಮ್, ಅಚರಂ(ಞ್) ಚರಮೇವ ಚ\nಸೂಕ್ಷ್ಮತ್ವಾತ್ತದವಿಜ್ಞೇಯಂ(ನ್), ದೂರಸ್ಥಂ(ಞ್) ಚಾಂತಿಕೇ ಚ ತತ್ ॥13.15॥",
      
      "ಅವಿಭಕ್ತಂ(ಞ್) ಚ ಭೂತೇಷು, ವಿಭಕ್ತಮಿವ ಚ ಸ್ಥಿತಮ್\nಭೂತಭರ್ತೃ ಚ ತಜ್ಜ್ಞೇಯಂ(ಙ್), ಗ್ರಸಿಷ್ಣು ಪ್ರಭವಿಷ್ಣು ಚ ॥13.16॥",
      
      "ಜ್ಯೋತಿಷಾಮಪಿ ತಜ್ಜ್ಯೋತಿಃ(ಸ್), ತಮಸಃ(ಫ್) ಪರಮುಚ್ಯತೇ\nಜ್ಞಾನಂ(ಞ್) ಜ್ಞೇಯಂ(ಞ್) ಜ್ಞಾನಗಮ್ಯಂ(ಮ್), ಹೃದಿ ಸರ್ವಸ್ಯ ವಿಷ್ಠಿತಮ್ ॥13.17॥",
      
      "ಇತಿ ಕ್ಷೇತ್ರಂ(ನ್) ತಥಾ ಜ್ಞಾನಂ(ಞ್), ಜ್ಞೇಯಂ(ಞ್) ಚೋಕ್ತಂ(ಮ್) ಸಮಾಸತಃ\nಮದ್ಭಕ್ತ ಏತದ್ವಿಜ್ಞಾಯ, ಮದ್ಭಾವಾಯೋಪಪದ್ಯತೇ ॥13.18॥",
      
      "ಪ್ರಕೃತಿಂ(ಮ್) ಪುರುಷಂ(ಞ್) ಚೈವ, ವಿದ್ಧ್ಯನಾದೀ ಉಭಾವಪಿ\nವಿಕಾರಾಂಶ್ಚ ಗುಣಾಂಶ್ಚೈವ, ವಿದ್ಧಿ ಪ್ರಕೃತಿಸಂಭವಾನ್ ॥13.19॥",
      
      "ಕಾರ್ಯಕಾರಣಕರ್ತೃತ್ವೇ, ಹೇತುಃ(ಫ್) ಪ್ರಕೃತಿರುಚ್ಯತೇ\nಪುರುಷಃ(ಸ್) ಸುಖದುಃಖಾನಾಂ(ಮ್), ಭೋಕ್ತೃತ್ವೇ ಹೇತುರುಚ್ಯತೇ ॥13.20॥",
      
      "ಪುರುಷಃ(ಫ್) ಪ್ರಕೃತಿಸ್ಥೋ ಹಿ, ಭುಂಕ್ತೇ ಪ್ರಕೃತಿಜಾನ್ಗುಣಾನ್\nಕಾರಣಂ(ಙ್) ಗುಣಸಂಗೋಽಸ್ಯ, ಸದಸದ್ಯೋನಿಜನ್ಮಸು ॥13.21॥",
      
      "ಉಪದ್ರಷ್ಟಾನುಮಂತಾ ಚ, ಭರ್ತಾ ಭೋಕ್ತಾ ಮಹೇಶ್ವರಃ\nಪರಮಾತ್ಮೇತಿ ಚಾಪ್ಯುಕ್ತೋ, ದೇಹೇಽಸ್ಮಿನ್ಪುರುಷಃ(ಫ್) ಪರಃ ॥13.22॥",
      
      "ಯ ಏವಂ(ಮ್) ವೇತ್ತಿ ಪುರುಷಂ(ಮ್), ಪ್ರಕೃತಿಂ(ಞ್) ಚ ಗುಣೈಃ(ಸ್) ಸಹ\nಸರ್ವಥಾ ವರ್ತಮಾನೋಽಪಿ, ನ ಸ ಭೂಯೋಽಭಿಜಾಯತೇ ॥13.23॥",
      
      "ಧ್ಯಾನೇನಾತ್ಮನಿ ಪಶ್ಯಂತಿ, ಕೇಚಿದಾತ್ಮಾನಮಾತ್ಮನಾ\nಅನ್ಯೇ ಸಾಂಖ್ಯೇನ ಯೋಗೇನ, ಕರ್ಮಯೋಗೇನ ಚಾಪರೇ ॥13.24॥",
      
      "ಅನ್ಯೇ ತ್ವೇವಮಜಾನಂತಃ(ಶ್), ಶ್ರುತ್ವಾನ್ಯೇಭ್ಯ ಉಪಾಸತೇ\nತೇಽಪಿ ಚಾತಿತರಂತ್ಯೇವ, ಮೃತ್ಯುಂ(ಮ್) ಶ್ರುತಿಪರಾಯಣಾಃ ॥13.25॥",
      
      "ಯಾವತ್ಸಂಜಾಯತೇ ಕಿಂಚಿತ್, ಸತ್ತ್ವಂ(ಮ್) ಸ್ಥಾವರಜಂಗಮಮ್\nಕ್ಷೇತ್ರಕ್ಷೇತ್ರಜ್ಞಸಂಯೋಗಾತ್, ತದ್ವಿದ್ಧಿ ಭರತರ್ಷಭ ॥13.26॥",
      
      "ಸಮಂ(ಮ್) ಸರ್ವೇಷು ಭೂತೇಷು, ತಿಷ್ಠಂತಂ(ಮ್) ಪರಮೇಶ್ವರಮ್\nವಿನಶ್ಯತ್ಸ್ವವಿನಶ್ಯಂತಂ(ಮ್), ಯಃ(ಫ್) ಪಶ್ಯತಿ ಸ ಪಶ್ಯತಿ ॥13.27॥",
      
      "ಸಮಂ(ಮ್) ಪಶ್ಯನ್ಹಿ ಸರ್ವತ್ರ, ಸಮವಸ್ಥಿತಮೀಶ್ವರಮ್\nನ ಹಿನಸ್ತ್ಯಾತ್ಮನಾತ್ಮಾನಂ(ನ್), ತತೋ ಯಾತಿ ಪರಾಂ(ಙ್) ಗತಿಮ್ ॥13.28॥",
      
      "ಪ್ರಕೃತ್ಯೈವ ಚ ಕರ್ಮಾಣಿ, ಕ್ರಿಯಮಾಣಾನಿ ಸರ್ವಶಃ\nಯಃ(ಫ್) ಪಶ್ಯತಿ ತಥಾತ್ಮಾನಮ್, ಅಕರ್ತಾರಂ(ಮ್) ಸ ಪಶ್ಯತಿ ॥13.29॥",
      
      "ಯದಾ ಭೂತಪೃಥಗ್ಭಾವಮ್, ಏಕಸ್ಥಮನುಪಶ್ಯತಿ\nತತ ಏವ ಚ ವಿಸ್ತಾರಂ(ಮ್), ಬ್ರಹ್ಮ ಸಂಪದ್ಯತೇ ತದಾ ॥13.30॥",
      
      "ಅನಾದಿತ್ವಾನ್ನಿರ್ಗುಣತ್ವಾತ್, ಪರಮಾತ್ಮಾಯಮವ್ಯಯಃ\nಶರೀರಸ್ಥೋಽಪಿ ಕೌಂತೇಯ, ನ ಕರೋತಿ ನ ಲಿಪ್ಯತೇ ॥13.31॥",
      
      "ಯಥಾ ಸರ್ವಗತಂ(ಮ್) ಸೌಕ್ಷ್ಮ್ಯಾದ್, ಆಕಾಶಂ(ನ್) ನೋಪಲಿಪ್ಯತೇ\nಸರ್ವತ್ರಾವಸ್ಥಿತೋ ದೇಹೇ, ತಥಾತ್ಮಾ ನೋಪಲಿಪ್ಯತೇ ॥13.32॥",
      
      "ಯಥಾ ಪ್ರಕಾಶಯತ್ಯೇಕಃ(ಖ್), ಕೃತ್ಸ್ನಂ(ಮ್) ಲೋಕಮಿಮಂ(ಮ್) ರವಿಃ\nಕ್ಷೇತ್ರಂ(ಙ್) ಕ್ಷೇತ್ರೀ ತಥಾ ಕೃತ್ಸ್ನಂ(ಮ್), ಪ್ರಕಾಶಯತಿ ಭಾರತ ॥13.33॥",
      
      "ಕ್ಷೇತ್ರಕ್ಷೇತ್ರಜ್ಞಯೋರೇವಮ್, ಅಂತರಂ(ಞ್) ಜ್ಞಾನಚಕ್ಷುಷಾ\nಭೂತಪ್ರಕೃತಿಮೋಕ್ಷಂ(ಞ್) ಚ, ಯೇ ವಿದುರ್ಯಾಂತಿ ತೇ ಪರಮ್ ॥13.34॥",
      
      "ಓಂ ತತ್ಸದಿತಿ ಶ್ರೀಮದ್ಭಗವದ್ಗೀತಾಸು ಉಪನಿಷತ್ಸು\nಬ್ರಹ್ಮವಿದ್ಯಾಯಾಂ(ಮ್)  ಯೋಗಶಾಸ್ತ್ರೇ ಶ್ರೀಕೃಷ್ಣಾರ್ಜುನಸಂವಾದೇ\nಕ್ಷೇತ್ರಕ್ಷೇತ್ರಜ್ಞವಿಭಾಗಯೋಗೋ ನಾಮ ತ್ರಯೋದಶೋಽಧ್ಯಾಯಃ ॥13॥"
    ],
    "adhyaya-14" : [
      "ಶ್ರೀಭಗವಾನುವಾಚ\nಪರಂ(ಮ್) ಭೂಯಃ(ಫ್) ಪ್ರವಕ್ಷ್ಯಾಮಿ, ಜ್ಞಾನಾನಾಂ(ಞ್) ಜ್ಞಾನಮುತ್ತಮಮ್\nಯಜ್.ಜ್ಞಾತ್ವಾ ಮುನಯಃ(ಸ್) ಸರ್ವೇ, ಪರಾಂ(ಮ್) ಸಿದ್ಧಿಮಿತೋ ಗತಾಃ ॥14.1॥",

      "ಇದಂ(ಞ್) ಜ್ಞಾನಮುಪಾಶ್ರಿತ್ಯ, ಮಮ ಸಾಧರ್ಮ್ಯಮಾಗತಾಃ\nಸರ್ಗೇಽಪಿ ನೋಪಜಾಯಂತೇ, ಪ್ರಲಯೇ ನ ವ್ಯಥಂತಿ ಚ ॥14.2॥",

      "ಮಮ ಯೋನಿರ್ಮಹದ್ ಬ್ರಹ್ಮ, ತಸ್ಮಿನ್ಗರ್ಭಂ(ನ್) ದಧಾಮ್ಯಹಮ್\nಸಂಭವಃ(ಸ್) ಸರ್ವಭೂತಾನಾಂ(ನ್), ತತೋ ಭವತಿ ಭಾರತ ॥14.3॥",

      "ಸರ್ವಯೋನಿಷು ಕೌಂತೇಯ, ಮೂರ್ತಯಃ(ಸ್) ಸಂಭವಂತಿ ಯಾಃ\nತಾಸಾಂ(ಮ್) ಬ್ರಹ್ಮ ಮಹದ್ಯೋನಿಃ(ರ್), ಅಹಂ(ಮ್) ಬೀಜಪ್ರದಃ(ಫ್) ಪಿತಾ ॥14.4॥",

      "ಸತ್ತ್ವಂ(ಮ್) ರಜಸ್ತಮ ಇತಿ, ಗುಣಾಃ(ಫ್) ಪ್ರಕೃತಿಸಂಭವಾಃ\nನಿಬಧ್ನಂತಿ ಮಹಾಬಾಹೋ, ದೇಹೇ ದೇಹಿನಮವ್ಯಯಮ್ ॥14.5॥",

      "ತತ್ರ ಸತ್ತ್ವಂ(ನ್) ನಿರ್ಮಲತ್ವಾತ್, ಪ್ರಕಾಶಕಮನಾಮಯಮ್\nಸುಖಸಂಗೇನ ಬಧ್ನಾತಿ, ಜ್ಞಾನಸಂಗೇನ ಚಾನಘ ॥14.6॥",

      "ರಜೋ ರಾಗಾತ್ಮಕಂ(ಮ್) ವಿದ್ಧಿ, ತೃಷ್ಣಾಸಂಗಸಮುದ್ಭವಮ್\nತನ್ನಿಬಧ್ನಾತಿ ಕೌಂತೇಯ, ಕರ್ಮಸಂಗೇನ ದೇಹಿನಮ್ ॥14.7॥",

      "ತಮಸ್ತ್ವಜ್ಞಾನಜಂ(ಮ್) ವಿದ್ಧಿ, ಮೋಹನಂ(ಮ್) ಸರ್ವದೇಹಿನಾಮ್\nಪ್ರಮಾದಾಲಸ್ಯನಿದ್ರಾಭಿಃ(ಸ್), ತನ್ನಿಬಧ್ನಾತಿ ಭಾರತ ॥14.8॥",

      "ಸತ್ತ್ವಂ(ಮ್) ಸುಖೇ ಸಂಜಯತಿ, ರಜಃ(ಖ್) ಕರ್ಮಣಿ ಭಾರತ\nಜ್ಞಾನಮಾವೃತ್ಯ ತು ತಮಃ(ಫ್), ಪ್ರಮಾದೇ ಸಂಜಯತ್ಯುತ ॥14.9॥",

      "ರಜಸ್ತಮಶ್ಚಾಭಿಭೂಯ, ಸತ್ತ್ವಂ(ಮ್) ಭವತಿ ಭಾರತ\nರಜಃ(ಸ್) ಸತ್ತ್ವಂ(ನ್) ತಮಶ್ಚೈವ, ತಮಃ(ಸ್) ಸತ್ತ್ವಂ(ಮ್) ರಜಸ್ತಥಾ ॥14.10॥",

      "ಸರ್ವದ್ವಾರೇಷು ದೇಹೇಽಸ್ಮಿನ್, ಪ್ರಕಾಶ ಉಪಜಾಯತೇ\nಜ್ಞಾನಂ(ಮ್) ಯದಾ ತದಾ ವಿದ್ಯಾದ್, ವಿವೃದ್ಧಂ(ಮ್) ಸತ್ತ್ವಮಿತ್ಯುತ ॥14.11॥",

      "ಲೋಭಃ(ಫ್) ಪ್ರವೃತ್ತಿರಾರಂಭಃ(ಖ್), ಕರ್ಮಣಾಮಶಮಃ(ಸ್) ಸ್ಪೃಹಾ\nರಜಸ್ಯೇತಾನಿ ಜಾಯಂತೇ, ವಿವೃದ್ಧೇ ಭರತರ್ಷಭ ॥14.12॥",

      "ಅಪ್ರಕಾಶೋಽಪ್ರವೃತ್ತಿಶ್ಚ, ಪ್ರಮಾದೋ ಮೋಹ ಏವ ಚ\nತಮಸ್ಯೇತಾನಿ ಜಾಯಂತೇ, ವಿವೃದ್ಧೇ ಕುರುನಂದನ ॥14.13॥",

      "ಯದಾ ಸತ್ತ್ವೇ ಪ್ರವೃದ್ಧೇ ತು, ಪ್ರಲಯಂ(ಮ್) ಯಾತಿ ದೇಹಭೃತ್\nತದೋತ್ತಮವಿದಾಂ(ಮ್) ಲೋಕಾನ್, ಅಮಲಾನ್ಪ್ರತಿಪದ್ಯತೇ ॥14.14॥",

      "ರಜಸಿ ಪ್ರಲಯಂ(ಙ್) ಗತ್ವಾ, ಕರ್ಮಸಂಗಿಷು ಜಾಯತೇ\nತಥಾ ಪ್ರಲೀನಸ್ತಮಸಿ, ಮೂಢಯೋನಿಷು ಜಾಯತೇ ॥14.15॥",

      "ಕರ್ಮಣಃ(ಸ್) ಸುಕೃತಸ್ಯಾಹುಃ(ಸ್),ಸಾತ್ತ್ವಿಕಂ(ನ್) ನಿರ್ಮಲಂ(ಮ್) ಫಲಮ್\nರಜಸಸ್ತು ಫಲಂ(ನ್) ದುಃಖಮ್,ಅಜ್ಞಾನಂ(ನ್) ತಮಸಃ(ಫ್) ಫಲಮ್ ॥14.16॥",

      "ಸತ್ತ್ವಾತ್ಸಂಜಾಯತೇ ಜ್ಞಾನಂ(ಮ್), ರಜಸೋ ಲೋಭ ಏವ ಚ\nಪ್ರಮಾದಮೋಹೌ ತಮಸೋ, ಭವತೋಽಜ್ಞಾನಮೇವ ಚ ॥14.17॥",

      "ಊರ್ಧ್ವಂ(ಙ್) ಗಚ್ಛಂತಿ ಸತ್ತ್ವಸ್ಥಾ, ಮಧ್ಯೇ ತಿಷ್ಠಂತಿ ರಾಜಸಾಃ\nಜಘನ್ಯಗುಣವೃತ್ತಿಸ್ಥಾ, ಅಧೋ ಗಚ್ಛಂತಿ ತಾಮಸಾಃ ॥14.18॥",

      "ನಾನ್ಯಂ(ಙ್) ಗುಣೇಭ್ಯಃ(ಖ್) ಕರ್ತಾರಂ(ಮ್), ಯದಾ ದ್ರಷ್ಟಾನುಪಶ್ಯತಿ\nಗುಣೇಭ್ಯಶ್ಚ ಪರಂ(ಮ್) ವೇತ್ತಿ, ಮದ್ಭಾವಂ(ಮ್) ಸೋಽಧಿಗಚ್ಛತಿ  ॥14.19॥",

      "ಗುಣಾನೇತಾನತೀತ್ಯ ತ್ರೀನ್, ದೇಹೀ ದೇಹಸಮುದ್ಭವಾನ್\nಜನ್ಮಮೃತ್ಯುಜರಾದುಃಖೈರ್, ವಿಮುಕ್ತೋಽಮೃತಮಶ್ನುತೇ ॥14.20॥",

      "ಅರ್ಜುನ ಉವಾಚ\nಕೈರ್ಲಿಂಗೈಸ್ತ್ರೀನ್ಗುಣಾನೇತಾನ್, ಅತೀತೋ ಭವತಿ ಪ್ರಭೋ\nಕಿಮಾಚಾರಃ(ಖ್) ಕಥಂ(ಞ್) ಚೈತಾಂಸ್, ತ್ರೀನ್ಗುಣಾನತಿವರ್ತತೇ ॥14.21॥",

      "ಶ್ರೀಭಗವಾನುವಾಚ\nಪ್ರಕಾಶಂ(ಞ್) ಚ ಪ್ರವೃತ್ತಿಂ(ಞ್) ಚ, ಮೋಹಮೇವ ಚ ಪಾಂಡವ\nನ ದ್ವೇಷ್ಟಿ ಸಂಪ್ರವೃತ್ತಾನಿ, ನ ನಿವೃತ್ತಾನಿ ಕಾಂಕ್ಷತಿ ॥14.22॥",

      "ಉದಾಸೀನವದಾಸೀನೋ, ಗುಣೈರ್ಯೋ ನ ವಿಚಾಲ್ಯತೇ\nಗುಣಾ ವರ್ತಂತ ಇತ್ಯೇವ, ಯೋಽವತಿಷ್ಠತಿ ನೇಂಗತೇ ॥14.23॥",

      "ಸಮದುಃಖಸುಖಃ(ಸ್) ಸ್ವಸ್ಥಃ(ಸ್), ಸಮಲೋಷ್ಟಾಶ್ಮಕಾಂಚನಃ\nತುಲ್ಯಪ್ರಿಯಾಪ್ರಿಯೋ ಧೀರಃ(ಸ್), ತುಲ್ಯನಿಂದಾತ್ಮಸಂಸ್ತುತಿಃ  ॥14.24॥",

      "ಮಾನಾಪಮಾನಯೋಸ್ತುಲ್ಯಃ(ಸ್), ತುಲ್ಯೋ ಮಿತ್ರಾರಿಪಕ್ಷಯೋಃ\nಸರ್ವಾರಂಭಪರಿತ್ಯಾಗೀ, ಗುಣಾತೀತಃ(ಸ್) ಸ ಉಚ್ಯತೇ ॥14.25॥",

      "ಮಾಂ(ಞ್) ಚ ಯೋಽವ್ಯಭಿಚಾರೇಣ, ಭಕ್ತಿಯೋಗೇನ ಸೇವತೇ\nಸ ಗುಣಾನ್ಸಮತೀತ್ಯೈತಾನ್, ಬ್ರಹ್ಮಭೂಯಾಯ ಕಲ್ಪತೇ ॥14.26॥",
      
      "ಬ್ರಹ್ಮಣೋ ಹಿ ಪ್ರತಿಷ್ಠಾಹಮ್, ಅಮೃತಸ್ಯಾವ್ಯಯಸ್ಯ ಚ\nಶಾಶ್ವತಸ್ಯ ಚ ಧರ್ಮಸ್ಯ, ಸುಖಸ್ಯೈಕಾಂತಿಕಸ್ಯ ಚ ॥14.27॥",

      "ಓಂ ತತ್ಸದಿತಿ ಶ್ರೀಮದ್ಭಗವದ್ಗೀತಾಸೂಪನಿಷತ್ಸು\nಬ್ರಹ್ಮವಿದ್ಯಾಯಾಂ(ಮ್) ಯೋಗಶಾಸ್ತ್ರೇ ಶ್ರೀಕೃಷ್ಣಾರ್ಜುನಸಂವಾದೇ\nಗುಣತ್ರಯವಿಭಾಗಯೋಗೋ ನಾಮ ಚತುರ್ದಶೋಽಧ್ಯಾಯಃ ॥14॥"
    ],
    "adhyaya-15" : [
      "ಶ್ರೀಭಗವಾನುವಾಚ\nಊರ್ಧ್ವಮೂಲಮಧಃ(ಶ್)ಶಾಖಮ್, ಅಶ್ವತ್ಥಂ(ಮ್) ಪ್ರಾಹುರವ್ಯಯಮ್।\nಛಂದಾಂಸಿ ಯಸ್ಯ ಪರ್ಣಾನಿ, ಯಸ್ತಂ(ವ್) ವೇದ ಸ ವೇದವಿತ್ ॥15.1॥",

      "ಅಧಶ್ಚೋರ್ಧ್ವಂ(ಮ್) ಪ್ರಸೃತಾಸ್ತಸ್ಯ ಶಾಖಾ,\nಗುಣಪ್ರವೃದ್ಧಾ ವಿಷಯಪ್ರವಾಲಾಃ |\nಅಧಶ್ಚ ಮೂಲಾನ್ಯನುಸಂತತಾನಿ,\nಕರ್ಮಾನುಬಂಧೀನಿ ಮನುಷ್ಯಲೋಕೇ  ।।15.2।।",

      "ನ ರೂಪಮಸ್ಯೇಹ ತಥೋಪಲಭ್ಯತೇ,\nನಾಂತೋ ನ ಚಾದಿರ್ನ ಚ ಸಂಪ್ರತಿಷ್ಠಾ |\nಅಶ್ವತ್ಥಮೇನಂ(ಮ್) ಸುವಿರೂಢಮೂಲಮ್,\nಅಸಂಗಶಸ್ತ್ರೇಣ ದೃಢೇನ ಛಿತ್ತ್ವಾ  ।।15.3।।",

      "ತತಃ(ಫ್) ಪದಂ(ನ್) ತತ್ಪರಿಮಾರ್ಗಿತವ್ಯಂ(ಯ್),\nಯಸ್ಮಿನ್ಗತಾ ನ ನಿವರ್ತಂತಿ ಭೂಯಃ ।\nತಮೇವ ಚಾದ್ಯಂ(ಮ್) ಪುರುಷಂ(ಮ್) ಪ್ರಪದ್ಯೇ,\nಯತಃ(ಫ್) ಪ್ರವೃತ್ತಿಃ(ಫ್) ಪ್ರಸೃತಾ ಪುರಾಣೀ ॥15.4॥",

      "ನಿರ್ಮಾನಮೋಹಾ ಜಿತಸಂಗದೋಷಾ,\nಅಧ್ಯಾತ್ಮನಿತ್ಯಾ ವಿನಿವೃತ್ತಕಾಮಾಃ |\nದ್ವಂದ್ವೈರ್ವಿಮುಕ್ತಾಃ(ಸ್) ಸುಖದುಃಖಸಂಜ್ಞೈ(ರ್),\nಗಚ್ಛಂತ್ಯಮೂಢಾಃ(ಫ್) ಪದಮವ್ಯಯಂ(ನ್) ತತ್ ।।15.5।।",

      "ನ ತದ್ಭಾಸಯತೇ ಸೂರ್ಯೋ, ನ ಶಶಾಂಕೋ ನ ಪಾವಕಃ |\nಯದ್ಗತ್ವಾ ನ ನಿವರ್ತಂತೇ, ತದ್ಧಾಮ ಪರಮಂ(ಮ್) ಮಮ  ।।15.6।।",

      "ಮಮೈವಾಂಶೋ ಜೀವಲೋಕೇ, ಜೀವಭೂತಃ(ಸ್) ಸನಾತನಃ |\nಮನಃ(ಷ್)ಷಷ್ಠಾನೀಂದ್ರಿಯಾಣಿ, ಪ್ರಕೃತಿಸ್ಥಾನಿ ಕರ್ಷತಿ ।।15.7।।",

      "ಶರೀರಂ(ಯ್) ಯದವಾಪ್ನೋತಿ, ಯಚ್ಚಾಪ್ಯುತ್ಕ್ರಾಮತೀಶ್ವರಃ।\nಗೃಹೀತ್ವೈತಾನಿ ಸಂಯಾತಿ, ವಾಯುರ್ಗಂಧಾನಿವಾಶಯಾತ್ ॥15.8॥",

      "ಶ್ರೋತ್ರಂ(ಞ್) ಚಕ್ಷುಃ(ಸ್) ಸ್ಪರ್ಶನಂ(ಞ್) ಚ,ರಸನಂ(ಙ್) ಘ್ರಾಣಮೇವ ಚ।\nಅಧಿಷ್ಠಾಯ ಮನಶ್ಚಾಯಂ(ವ್), ವಿಷಯಾನುಪಸೇವತೇ ॥15.9॥",

      "ಉತ್ಕ್ರಾಮಂತಂ(ಮ್) ಸ್ಥಿತಂ(ವ್) ವಾಪಿ, ಭುಂಜಾನಂ(ವ್) ವಾ ಗುಣಾನ್ವಿತಮ್।\nವಿಮೂಢಾ ನಾನುಪಶ್ಯಂತಿ, ಪಶ್ಯಂತಿ ಜ್ಞಾನಚಕ್ಷುಷಃ ॥15.10॥",

      "ಯತಂತೋ ಯೋಗಿನಶ್ಚೈನಂ(ಮ್), ಪಶ್ಯಂತ್ಯಾತ್ಮನ್ಯವಸ್ಥಿತಮ್ |\nಯತಂತೋಽಪ್ಯಕೃತಾತ್ಮಾನೋ, ನೈನಂ(ಮ್) ಪಶ್ಯಂತ್ಯಚೇತಸಃ ।।15.11।।",

      "ಯದಾದಿತ್ಯಗತಂ(ನ್) ತೇಜೋ, ಜಗದ್ಭಾಸಯತೇಽಖಿಲಮ್ |\nಯಚ್ಚಂದ್ರಮಸಿ ಯಚ್ಚಾಗ್ನೌ, ತತ್ತೇಜೋ ವಿದ್ಧಿ ಮಾಮಕಮ್ ।।15.12।।",

      "ಗಾಮಾವಿಶ್ಯ ಚ ಭೂತಾನಿ, ಧಾರಯಾಮ್ಯಹಮೋಜಸಾ |\nಪುಷ್ಣಾಮಿ ಚೌಷಧೀಃ(ಸ್) ಸರ್ವಾಃ(ಸ್), ಸೋಮೋ ಭೂತ್ವಾ ರಸಾತ್ಮಕಃ ।।15.13।।",

      "ಅಹಂ(ವ್) ವೈಶ್ವಾನರೋ ಭೂತ್ವಾ, ಪ್ರಾಣಿನಾಂ(ನ್) ದೇಹಮಾಶ್ರಿತಃ।\nಪ್ರಾಣಾಪಾನಸಮಾಯುಕ್ತಃ(ಫ್), ಪಚಾಮ್ಯನ್ನಂ(ಞ್) ಚತುರ್ವಿಧಮ್ ॥15.14॥",

      "ಸರ್ವಸ್ಯ ಚಾಹಂ(ಮ್) ಹೃದಿ ಸನ್ನಿವಿಷ್ಟೋ,\nಮತ್ತಃ(ಸ್) ಸ್ಮೃತಿರ್ಜ್ಞಾನಮಪೋಹನಂ(ಞ್)ಚ |\nವೇದೈಶ್ಚ ಸರ್ವೈರಹಮೇವ ವೇದ್ಯೋ,\nವೇದಾಂತಕೃದ್ವೇದವಿದೇವ ಚಾಹಮ್ ।।15.15।।",

      "ದ್ವಾವಿಮೌ ಪುರುಷೌ ಲೋಕೇ, ಕ್ಷರಶ್ಚಾಕ್ಷರ ಏವ ಚ |\nಕ್ಷರಃ(ಸ್) ಸರ್ವಾಣಿ ಭೂತಾನಿ, ಕೂಟಸ್ಥೋಽಕ್ಷರ ಉಚ್ಯತೇ ।।15.16।।",

      "ಉತ್ತಮಃ(ಫ್) ಪುರುಷಸ್ತ್ವನ್ಯಃ(ಫ್), ಪರಮಾತ್ಮೇತ್ಯುದಾಹೃತಃ |\nಯೋ ಲೋಕತ್ರಯಮಾವಿಶ್ಯ, ಬಿಭರ್ತ್ಯವ್ಯಯ ಈಶ್ವರಃ ।।15.17।।",

      "ಯಸ್ಮಾತ್ಕ್ಷರಮತೀತೋಽಹಮ್, ಅಕ್ಷರಾದಪಿ ಚೋತ್ತಮಃ |\nಅತೋಽಸ್ಮಿ ಲೋಕೇ ವೇದೇ ಚ, ಪ್ರಥಿತಃ(ಫ್) ಪುರುಷೋತ್ತಮಃ ।।15.18।।",

      "ಯೋ ಮಾಮೇವಮಸಮ್ಮೂಢೋ, ಜಾನಾತಿ ಪುರುಷೋತ್ತಮಮ್ |\nಸ ಸರ್ವವಿದ್ಭಜತಿ ಮಾಂ(ಮ್), ಸರ್ವಭಾವೇನ ಭಾರತ ।।15.19।।",

      "ಇತಿ ಗುಹ್ಯತಮಂ(ಮ್) ಶಾಸ್ತ್ರಮ್, ಇದಮುಕ್ತಂ(ಮ್) ಮಯಾನಘ |\nಏತದ್ಬುದ್ಧ್ವಾ ಬುದ್ಧಿಮಾನ್ಸ್ಯಾತ್, ಕೃತಕೃತ್ಯಶ್ಚ ಭಾರತ ।।15.20।।",

      "ಓಂ ತತ್ಸದಿತಿ ಶ್ರೀಮದ್ಭಗವದ್ಗೀತಾಸು ಉಪನಿಷತ್ಸು ಬ್ರಹ್ಮವಿದ್ಯಾಯಾಂ(ಯ್)\nಯೋಗಶಾಸ್ತ್ರೇ ಶ್ರೀಕೃಷ್ಣಾರ್ಜುನಸಂವಾದೇ ಪುರುಷೋತ್ತಮಯೋಗೋ ನಾಮ ಪಂಚದಶೋಽಧ್ಯಾಯಃ ॥15॥"
    ],
    "adhyaya-16" : [
      "ಶ್ರೀಭಗವಾನುವಾಚ\nಅಭಯಂ(ಮ್) ಸತ್ತ್ವಸಂಶುದ್ಧಿಃ(ರ್), ಜ್ಞಾನಯೋಗವ್ಯವಸ್ಥಿತಿಃ |\nದಾನಂ(ನ್) ದಮಶ್ಚ ಯಜ್ಞಶ್ಚ, ಸ್ವಾಧ್ಯಾಯಸ್ತಪ ಆರ್ಜವಮ್ ।।16.1।।",

      "ಅಹಿಂಸಾ ಸತ್ಯಮಕ್ರೋಧಃ(ಸ್), ತ್ಯಾಗಃ(ಶ್) ಶಾಂತಿರಪೈಶುನಮ್ |\nದಯಾ ಭೂತೇಷ್ವಲೋಲುಪ್ತ್ವಂ(ಮ್), ಮಾರ್ದವಂ(ಮ್) ಹ್ರೀರಚಾಪಲಮ್ ।।16.2।।",

      "ತೇಜಃ(ಹ್) ಕ್ಷಮಾ ಧೃತಿಃ(ಶ್) ಶೌಚಮ್, ಅದ್ರೋಹೋ ನಾತಿಮಾನಿತಾ |\nಭವಂತಿ ಸಂಪದಂ(ನ್) ದೈವೀಮ್, ಅಭಿಜಾತಸ್ಯ ಭಾರತ  ।।16.3।।",

      "ದಂಭೋ ದರ್ಪೋಽಭಿಮಾನಶ್ಚ, ಕ್ರೋಧಃ(ಫ್) ಪಾರುಷ್ಯಮೇವ ಚ |\nಅಜ್ಞಾನಂ(ಞ್) ಚಾಭಿಜಾತಸ್ಯ, ಪಾರ್ಥ ಸಂಪದಮಾಸುರೀಮ್ ।।16.4।।",

      "ದೈವೀ ಸಂಪದ್ವಿಮೋಕ್ಷಾಯ, ನಿಬಂಧಾಯಾಸುರೀ ಮತಾ |\nಮಾ ಶುಚಃ(ಸ್) ಸಂಪದಂ(ನ್) ದೈವೀಮ್, ಅಭಿಜಾತೋಽಸಿ ಪಾಂಡವ ।।16.5।।",

      "ದ್ವೌ ಭೂತಸರ್ಗೌ ಲೋಕೇಽಸ್ಮಿನ್, ದೈವ ಆಸುರ ಏವ ಚ |\nದೈವೋ ವಿಸ್ತರಶಃ(ಫ್) ಪ್ರೋಕ್ತ, ಆಸುರಂ(ಮ್) ಪಾರ್ಥ ಮೇ ಶೃಣು ।।16.6।।",

      "ಪ್ರವೃತ್ತಿಂ(ಞ್) ಚ ನಿವೃತ್ತಿಂ(ಞ್) ಚ, ಜನಾ ನ ವಿದುರಾಸುರಾಃ |\nನ ಶೌಚಂ(ನ್) ನಾಪಿ ಚಾಚಾರೋ, ನ ಸತ್ಯಂ(ನ್) ತೇಷು ವಿದ್ಯತೇ ।।16.7।।",

      "ಅಸತ್ಯಮಪ್ರತಿಷ್ಠಂ(ನ್) ತೇ, ಜಗದಾಹುರನೀಶ್ವರಮ್ |\nಅಪರಸ್ಪರಸಂಭೂತಂ(ಙ್), ಕಿಮನ್ಯತ್ಕಾಮಹೈತುಕಮ್ ।।16.8।।",

      "ಏತಾಂ(ನ್) ದೃಷ್ಟಿಮವಷ್ಟಭ್ಯ, ನಷ್ಟಾತ್ಮಾನೋಽಲ್ಪಬುದ್ಧಯಃ |\nಪ್ರಭವಂತ್ಯುಗ್ರಕರ್ಮಾಣಃ, ಕ್ಷಯಾಯ ಜಗತೋಽಹಿತಾಃ ।।16.9।।",

      "ಕಾಮಮಾಶ್ರಿತ್ಯ ದುಷ್ಪೂರಂ(ನ್), ದಂಭಮಾನಮದಾನ್ವಿತಾಃ |\nಮೋಹಾದ್ ಗೃಹೀತ್ವಾಸದ್ಗ್ರಾಹಾನ್,ಪ್ರವರ್ತಂತೇಽಶುಚಿವ್ರತಾಃ ।।16.10।।",

      "ಚಿಂತಾಮಪರಿಮೇಯಾಂ(ಞ್) ಚ, ಪ್ರಲಯಾಂತಾಮುಪಾಶ್ರಿತಾಃ|\nಕಾಮೋಪಭೋಗಪರಮಾ, ಏತಾವದಿತಿ ನಿಶ್ಚಿತಾಃ ।।16.11।।",

      "ಆಶಾಪಾಶಶತೈರ್ಬದ್ಧಾಃ(ಖ್), ಕಾಮಕ್ರೋಧಪರಾಯಣಾಃ |\nಈಹಂತೇ ಕಾಮಭೋಗಾರ್ಥಮ್, ಅನ್ಯಾಯೇನಾರ್ಥಸಂಚಯಾನ್ ।।16.12।।",

      "ಇದಮದ್ಯ ಮಯಾ ಲಬ್ಧಮ್, ಇಮಂ(ಮ್) ಪ್ರಾಪ್ಸ್ಯೇ ಮನೋರಥಮ್ |\nಇದಮಸ್ತೀದಮಪಿ ಮೇ, ಭವಿಷ್ಯತಿ ಪುನರ್ಧನಮ್  ।।16.13।।",

      "ಅಸೌ ಮಯಾ ಹತಃ(ಶ್) ಶತ್ರುಃ(ರ್), ಹನಿಷ್ಯೇ ಚಾಪರಾನಪಿ |\nಈಶ್ವರೋಽಹಮಹಂ(ಮ್) ಭೋಗೀ, ಸಿದ್ಧೋಽಹಂ ಬಲವಾನ್ಸುಖೀ ।।16.14।।",

      "ಆಢ್ಯೋಽಭಿಜನವಾನಸ್ಮಿ, ಕೋಽನ್ಯೋಽಸ್ತಿ ಸದೃಶೋ ಮಯಾ |\nಯಕ್ಷ್ಯೇ ದಾಸ್ಯಾಮಿ ಮೋದಿಷ್ಯ, ಇತ್ಯಜ್ಞಾನವಿಮೋಹಿತಾಃ ।।16.15।।",

      "ಅನೇಕಚಿತ್ತವಿಭ್ರಾಂತಾ, ಮೋಹಜಾಲಸಮಾವೃತಾಃ |\nಪ್ರಸಕ್ತಾಃ(ಖ್) ಕಾಮಭೋಗೇಷು, ಪತಂತಿ ನರಕೇಽಶುಚೌ ।।16.16।।",

      "ಆತ್ಮಸಂಭಾವಿತಾಃ(ಸ್) ಸ್ತಬ್ಧಾ, ಧನಮಾನಮದಾನ್ವಿತಾಃ |\nಯಜಂತೇ ನಾಮಯಜ್ಞೈಸ್ತೇ, ದಂಭೇನಾವಿಧಿಪೂರ್ವಕಮ್ ।।16.17।।",

      "ಅಹಂಕಾರಂ(ಮ್) ಬಲಂ(ನ್) ದರ್ಪಂ(ಙ್),ಕಾಮಂ(ಙ್) ಕ್ರೋಧಂ(ಞ್) ಚ ಸಂಶ್ರಿತಾಃ |\nಮಾಮಾತ್ಮಪರದೇಹೇಷು, ಪ್ರದ್ವಿಷಂತೋಽಭ್ಯಸೂಯಕಾಃ ।।16.18।।",

      "ತಾನಹಂ(ನ್)  ದ್ವಿಷತಃ(ಖ್) ಕ್ರೂರಾನ್, ಸಂಸಾರೇಷು ನರಾಧಮಾನ್ |\nಕ್ಷಿಪಾಮ್ಯಜಸ್ರಮಶುಭಾನ್, ಆಸುರೀಷ್ವೇವ ಯೋನಿಷು ।।16.19।।",

      "ಆಸುರೀಂ(ಯ್) ಯೋನಿಮಾಪನ್ನಾ, ಮೂಢಾ ಜನ್ಮನಿ ಜನ್ಮನಿ।\nಮಾಮಪ್ರಾಪ್ಯೈವ ಕೌಂತೇಯ, ತತೋ ಯಾಂತ್ಯಧಮಾಂ(ಙ್) ಗತಿಮ ।।16.20।।",

      "ತ್ರಿವಿಧಂ(ನ್) ನರಕಸ್ಯೇದಂ(ನ್), ದ್ವಾರಂ(ನ್) ನಾಶನಮಾತ್ಮನಃ |\nಕಾಮಃ(ಖ್) ಕ್ರೋಧಸ್ತಥಾ ಲೋಭಃ (ಸ್), ತಸ್ಮಾದೇತತ್ತ್ರಯಂ(ನ್) ತ್ಯಜೇತ್ ।।16.21।।",

      "ಏತೈರ್ವಿಮುಕ್ತಃ(ಖ್)  ಕೌಂತೇಯ, ತಮೋದ್ವಾರೈಸ್ತ್ರಿಭಿರ್ನರಃ |\nಆಚರತ್ಯಾತ್ಮನಃ(ಶ್) ಶ್ರೇಯಸ್, ತತೋ ಯಾತಿ ಪರಾಂ(ಙ್) ಗತಿಮ್ ।।16.22।।",

      "ಯಃ(ಶ್) ಶಾಸ್ತ್ರವಿಧಿಮುತ್ಸೃಜ್ಯ, ವರ್ತತೇ ಕಾಮಕಾರತಃ |\nನ ಸ ಸಿದ್ಧಿಮವಾಪ್ನೋತಿ, ನ ಸುಖಂ(ನ್) ನ ಪರಾಂ(ಙ್) ಗತಿಮ್ ।।16.23।।",

      "ತಸ್ಮಾಚ್ಛಾಸ್ತ್ರಂ(ಮ್) ಪ್ರಮಾಣಂ(ನ್)  ತೇ, ಕಾರ್ಯಾಕಾರ್ಯವ್ಯವಸ್ಥಿತೌ |\nಜ್ಞಾತ್ವಾ ಶಾಸ್ತ್ರವಿಧಾನೋಕ್ತಂ(ಙ್), ಕರ್ಮ ಕರ್ತುಮಿಹಾರ್ಹಸಿ ।।16.24।।",

      "ಓಂ ತತ್ಸದಿತಿ ಶ್ರೀಮದ್ಭಗವದ್ಗೀತಾಸು ಉಪನಿಷತ್ಸು ಬ್ರಹ್ಮವಿದ್ಯಾಯಾಂ(ಯ್)\nಯೋಗಶಾಸ್ತ್ರೇ ಶ್ರೀಕೃಷ್ಣಾರ್ಜುನಸಂವಾದೇ ದೈವಾಸುರಸಂಪದ್ವಿಭಾಗಯೋಗೋ\nನಾಮ ಷೋಡಶೋಽಧ್ಯಾಯಃ ॥16॥" 
    ],
    "adhyaya-17" : [
      "ಅರ್ಜುನ ಉವಾಚ\nಯೇ ಶಾಸ್ತ್ರವಿಧಿಮುತ್ಸೃಜ್ಯ, ಯಜಂತೇ ಶ್ರದ್ಧಯಾನ್ವಿತಾಃ |\nತೇಷಾಂ(ನ್) ನಿಷ್ಠಾ ತು ಕಾ ಕೃಷ್ಣ, ಸತ್ತ್ವಮಾಹೋ ರಜಸ್ತಮಃ ।।17.1।।",

      "ಶ್ರೀಭಗವಾನುವಾಚ\nತ್ರಿವಿಧಾ ಭವತಿ ಶ್ರದ್ಧಾ, ದೇಹಿನಾಂ(ಮ್) ಸಾ ಸ್ವಭಾವಜಾ |\nಸಾತ್ತ್ವಿಕೀ ರಾಜಸೀ ಚೈವ, ತಾಮಸೀ ಚೇತಿ ತಾಂ(ಮ್) ಶೃಣು ।।17.2।।",

      "ಸತ್ತ್ವಾನುರೂಪಾ ಸರ್ವಸ್ಯ, ಶ್ರದ್ಧಾ ಭವತಿ ಭಾರತ |\nಶ್ರದ್ಧಾಮಯೋಽಯಂ(ಮ್) ಪುರುಷೋ, ಯೋ ಯಚ್ಛ್ರದ್ಧಃ(ಸ್) ಸ ಏವ ಸಃ ।।17.3।।",

      "ಯಜಂತೇ ಸಾತ್ತ್ವಿಕಾ ದೇವಾನ್, ಯಕ್ಷರಕ್ಷಾಂಸಿ ರಾಜಸಾಃ |\nಪ್ರೇತಾನ್ಭೂತಗಣಾಂಶ್ಚಾನ್ಯೇ, ಯಜಂತೇ ತಾಮಸಾ ಜನಾಃ ।।17.4।।",

      "ಅಶಾಸ್ತ್ರವಿಹಿತಂ(ಙ್) ಘೋರಂ(ನ್), ತಪ್ಯಂತೇ ಯೇ ತಪೋ ಜನಾಃ |\nದಂಭಾಹಂಕಾರಸಂಯುಕ್ತಾಃ(ಖ್), ಕಾಮರಾಗಬಲಾನ್ವಿತಾಃ ।।17.5।।",

      "ಕರ್ಶಯಂತಃ(ಶ್) ಶರೀರಸ್ಥಂ(ಮ್), ಭೂತಗ್ರಾಮಮಚೇತಸಃ |\nಮಾಂ(ಞ್) ಚೈವಾಂತಃ(ಶ್) ಶರೀರಸ್ಥಂ(ನ್), ತಾನ್ವಿದ್ಧ್ಯಾಸುರನಿಶ್ಚಯಾನ್ ।।17.6।।",

      "ಆಹಾರಸ್ತ್ವಪಿ ಸರ್ವಸ್ಯ, ತ್ರಿವಿಧೋ ಭವತಿ ಪ್ರಿಯಃ |\nಯಜ್ಞಸ್ತಪಸ್ತಥಾ ದಾನಂ(ನ್), ತೇಷಾಂ(ಮ್) ಭೇದಮಿಮಂ(ಮ್) ಶೃಣು ।।17.7।।",

      "ಆಯುಃ(ಸ್)ಸತ್ತ್ವಬಲಾರೋಗ್ಯ,ಸುಖಪ್ರೀತಿವಿವರ್ಧನಾಃ |\nರಸ್ಯಾಃ(ಸ್) ಸ್ನಿಗ್ಧಾಃ(ಸ್) ಸ್ಥಿರಾ ಹೃದ್ಯಾ, ಆಹಾರಾಃ(ಸ್) ಸಾತ್ತ್ವಿಕಪ್ರಿಯಾಃ ।।17.8।।",

      "ಕಟ್ವಮ್ಲಲವಣಾತ್ಯುಷ್ಣ, ತೀಕ್ಷ್ಣರೂಕ್ಷವಿದಾಹಿನಃ |\nಆಹಾರಾ ರಾಜಸಸ್ಯೇಷ್ಟಾ, ದುಃಖಶೋಕಾಮಯಪ್ರದಾಃ ।।17.9।।",

      "ಯಾತಯಾಮಂ(ಙ್) ಗತರಸಂ(ಮ್),ಪೂತಿ ಪರ್ಯುಷಿತಂ(ಞ್) ಚ ಯತ್ |\nಉಚ್ಛಿಷ್ಟಮಪಿ ಚಾಮೇಧ್ಯಂ(ಮ್),ಭೋಜನಂ ತಾಮಸಪ್ರಿಯಮ್ ।।17.10।।",

      "ಅಫಲಾಕಾಂಕ್ಷಿಭಿರ್ಯಜ್ಞೋ, ವಿಧಿದೃಷ್ಟೋ ಯ ಇಜ್ಯತೇ |\nಯಷ್ಟವ್ಯಮೇವೇತಿ ಮನಃ(ಸ್), ಸಮಾಧಾಯ ಸ ಸಾತ್ತ್ವಿಕಃ ।।17.11।।",

      "ಅಭಿಸಂಧಾಯ ತು ಫಲಂ(ನ್), ದಂಭಾರ್ಥಮಪಿ ಚೈವ ಯತ್ |\nಇಜ್ಯತೇ ಭರತಶ್ರೇಷ್ಠ, ತಂ(ಮ್) ಯಜ್ಞಂ(ಮ್) ವಿದ್ಧಿ ರಾಜಸಮ್ ।।17.12।।",

      "ವಿಧಿಹೀನಮಸೃಷ್ಟಾನ್ನಂ(ಮ್), ಮಂತ್ರಹೀನಮದಕ್ಷಿಣಮ್ |\nಶ್ರದ್ಧಾವಿರಹಿತಂ(ಮ್) ಯಜ್ಞಂ, ತಾಮಸಂ(ಮ್) ಪರಿಚಕ್ಷತೇ ।।17.13।।",

      "ದೇವದ್ವಿಜಗುರುಪ್ರಾಜ್ಞ, ಪೂಜನಂ(ಮ್) ಶೌಚಮಾರ್ಜವಮ್ |\nಬ್ರಹ್ಮಚರ್ಯಮಹಿಂಸಾ ಚ, ಶಾರೀರಂ(ನ್) ತಪ ಉಚ್ಯತೇ ।।17.14।।",

      "ಅನುದ್ವೇಗಕರಂ(ಮ್) ವಾಕ್ಯಂ(ಮ್), ಸತ್ಯಂ(ಮ್) ಪ್ರಿಯಹಿತಂ(ಞ್) ಚ ಯತ್|\nಸ್ವಾಧ್ಯಾಯಾಭ್ಯಸನಂ(ಞ್) ಚೈವ, ವಾಙ್ಮಯಂ(ನ್) ತಪ ಉಚ್ಯತೇ ।।17.15।।",

      "ಮನಃ(ಫ್) ಪ್ರಸಾದಃ(ಸ್) ಸೌಮ್ಯತ್ವಂ(ಮ್), ಮೌನಮಾತ್ಮವಿನಿಗ್ರಹಃ |\nಭಾವಸಂಶುದ್ಧಿರಿತ್ಯೇತತ್, ತಪೋ ಮಾನಸಮುಚ್ಯತೇ ।।17.16।।",

      "ಶ್ರದ್ಧಯಾ ಪರಯಾ ತಪ್ತಂ(ನ್), ತಪಸ್ತತ್ತ್ರಿವಿಧಂ(ನ್) ನರೈಃ |\nಅಫಲಾಕಾಂಕ್ಷಿಭಿರ್ಯುಕ್ತೈಃ(ಸ್), ಸಾತ್ತ್ವಿಕಂ(ಮ್) ಪರಿಚಕ್ಷತೇ ।।17.17।।",

      "ಸತ್ಕಾರಮಾನಪೂಜಾರ್ಥಂ(ನ್), ತಪೋ ದಂಭೇನ ಚೈವ ಯತ್ |\nಕ್ರಿಯತೇ ತದಿಹ ಪ್ರೋಕ್ತಂ(ಮ್), ರಾಜಸಂ(ಞ್) ಚಲಮಧ್ರುವಮ್ ।।17.18।।",

      "ಮೂಢಗ್ರಾಹೇಣಾತ್ಮನೋ ಯತ್, ಪೀಡಯಾ ಕ್ರಿಯತೇ ತಪಃ |\nಪರಸ್ಯೋತ್ಸಾದನಾರ್ಥಂ(ಮ್) ವಾ, ತತ್ತಾಮಸಮುದಾಹೃತಮ್ ।।17.19।।",

      "ದಾತವ್ಯಮಿತಿ ಯದ್ದಾನಂ(ನ್), ದೀಯತೇಽನುಪಕಾರಿಣೇ |\nದೇಶೇ ಕಾಲೇ ಚ ಪಾತ್ರೇ ಚ, ತದ್ದಾನಂ(ಮ್) ಸಾತ್ತ್ವಿಕಂ(ಮ್) ಸ್ಮೃತಮ್ ।।17.20।।",

      "ಯತ್ತು ಪ್ರತ್ಯುಪಕಾರಾರ್ಥಂ(ಮ್), ಫಲಮುದ್ದಿಶ್ಯ ವಾ ಪುನಃ |\nದೀಯತೇ ಚ ಪರಿಕ್ಲಿಷ್ಟಂ(ನ್), ತದ್ದಾನಂ(ಮ್) ರಾಜಸಂ(ಮ್) ಸ್ಮೃತಮ್ ।।17.21।।",

      "ಅದೇಶಕಾಲೇ ಯದ್ದಾನಮ್, ಅಪಾತ್ರೇಭ್ಯಶ್ಚ ದೀಯತೇ |\nಅಸತ್ಕೃತಮವಜ್ಞಾತಂ(ನ್), ತತ್ತಾಮಸಮುದಾಹೃತಮ್ ।।17.22।।",

      "ಓಂ ತತ್ಸದಿತಿ ನಿರ್ದೇಶೋ, ಬ್ರಹ್ಮಣಸ್ತ್ರಿವಿಧಃ(ಸ್) ಸ್ಮೃತಃ |\nಬ್ರಾಹ್ಮಣಾಸ್ತೇನ ವೇದಾಶ್ಚ, ಯಜ್ಞಾಶ್ಚ ವಿಹಿತಾಃ(ಫ್) ಪುರಾ ।।17.23।।",

      "ತಸ್ಮಾದೋಮಿತ್ಯುದಾಹೃತ್ಯ, ಯಜ್ಞದಾನತಪಃಕ್ರಿಯಾಃ |\nಪ್ರವರ್ತಂತೇ ವಿಧಾನೋಕ್ತಾಃ(ಸ್), ಸತತಂ(ಮ್) ಬ್ರಹ್ಮವಾದಿನಾಮ್ ।।17.24।।",

      "ತದಿತ್ಯನಭಿಸಂಧಾಯ, ಫಲಂ(ಮ್) ಯಜ್ಞತಪಃಕ್ರಿಯಾಃ |\nದಾನಕ್ರಿಯಾಶ್ಚ ವಿವಿಧಾಃ(ಖ್), ಕ್ರಿಯಂತೇ ಮೋಕ್ಷಕಾಂಕ್ಷಿಭಿಃ ।।17.25।।",

      "ಸದ್ಭಾವೇ ಸಾಧುಭಾವೇ ಚ, ಸದಿತ್ಯೇತತ್ಪ್ರಯುಜ್ಯತೇ |\nಪ್ರಶಸ್ತೇ ಕರ್ಮಣಿ ತಥಾ, ಸಚ್ಛಬ್ದಃ(ಫ್) ಪಾರ್ಥ ಯುಜ್ಯತೇ ।।17.26।।",

      "ಯಜ್ಞೇ ತಪಸಿ ದಾನೇ ಚ, ಸ್ಥಿತಿಃ(ಸ್) ಸದಿತಿ ಚೋಚ್ಯತೇ |\nಕರ್ಮ ಚೈವ ತದರ್ಥೀಯಂ(ಮ್), ಸದಿತ್ಯೇವಾಭಿಧೀಯತೇ ।।17.27।।",

      "ಅಶ್ರದ್ಧಯಾ ಹುತಂ(ನ್) ದತ್ತಂ(ನ್), ತಪಸ್ತಪ್ತಂ(ಙ್) ಕೃತಂ(ಞ್) ಚ ಯತ್|\nಅಸದಿತ್ಯುಚ್ಯತೇ ಪಾರ್ಥ, ನ ಚ ತತ್ಪ್ರೇತ್ಯ ನೋ ಇಹ ।।17.28।।",

      "ಓಂ ತತ್ಸದಿತಿ ಶ್ರೀಮದ್ಭಗವದ್ಗೀತಾಸು ಉಪನಿಷತ್ಸು \nಬ್ರಹ್ಮವಿದ್ಯಾಯಾಂ(ಮ್) ಯೋಗಶಾಸ್ತ್ರೇ ಶ್ರೀಕೃಷ್ಣಾರ್ಜುನಸಂವಾದೇ ಶ್ರದ್ಧಾತ್ರಯವಿಭಾಗಯೋಗೋ \nಸಪ್ತದಶೋಽಧ್ಯಾಯಃ ॥17॥"
    ],
    "adhyaya-18" : [ 
      "ಅರ್ಜುನ ಉವಾಚ\nಸಂನ್ಯಾಸಸ್ಯ ಮಹಾಬಾಹೋ, ತತ್ತ್ವಮಿಚ್ಛಾಮಿ ವೇದಿತುಮ್\nತ್ಯಾಗಸ್ಯ ಚ ಹೃಷೀಕೇಶ, ಪೃಥಕ್ಕೇಶಿನಿಷೂದನ ॥18.1॥",

      "ಶ್ರೀಭಗವಾನುವಾಚ\nಕಾಮ್ಯಾನಾಂ(ಙ್) ಕರ್ಮಣಾಂ(ನ್) ನ್ಯಾಸಂ(ಮ್), ಸಂನ್ಯಾಸಂ(ಙ್) ಕವಯೋ ವಿದುಃ\nಸರ್ವಕರ್ಮಫಲತ್ಯಾಗಂ(ಮ್), ಪ್ರಾಹುಸ್ತ್ಯಾಗಂ(ಮ್) ವಿಚಕ್ಷಣಾಃ ॥18.2॥",

      "ತ್ಯಾಜ್ಯಂ(ನ್) ದೋಷವದಿತ್ಯೇಕೇ, ಕರ್ಮ ಪ್ರಾಹುರ್ಮನೀಷಿಣಃ\nಯಜ್ಞದಾನತಪಃಕರ್ಮ, ನ ತ್ಯಾಜ್ಯಮಿತಿ ಚಾಪರೇ ॥18.3॥",

      "ನಿಶ್ಚಯಂ(ಮ್) ಶೃಣು ಮೇ ತತ್ರ, ತ್ಯಾಗೇ ಭರತಸತ್ತಮ\nತ್ಯಾಗೋ ಹಿ ಪುರುಷವ್ಯಾಘ್ರ, ತ್ರಿವಿಧಃ(ಸ್) ಸಂಪ್ರಕೀರ್ತಿತಃ ॥18.4॥",

      "ಯಜ್ಞದಾನತಪಃಕರ್ಮ, ನ ತ್ಯಾಜ್ಯಂ(ಙ್) ಕಾರ್ಯಮೇವ ತತ್\nಯಜ್ಞೋ ದಾನಂ(ನ್) ತಪಶ್ಚೈವ, ಪಾವನಾನಿ ಮನೀಷಿಣಾಮ್ ॥18.5॥",

      "ಏತಾನ್ಯಪಿ ತು ಕರ್ಮಾಣಿ, ಸಂಗಂ(ನ್) ತ್ಯಕ್ತ್ವಾ ಫಲಾನಿ ಚ \nಕರ್ತವ್ಯಾನೀತಿ ಮೇ ಪಾರ್ಥ, ನಿಶ್ಚಿತಂ(ಮ್) ಮತಮುತ್ತಮಮ್ ॥18.6॥",

      "ನಿಯತಸ್ಯ ತು ಸಂನ್ಯಾಸಃ(ಖ್), ಕರ್ಮಣೋ ನೋಪಪದ್ಯತೇ\nಮೋಹಾತ್ತಸ್ಯ ಪರಿತ್ಯಾಗಃ(ಸ್), ತಾಮಸಃ(ಫ್) ಪರಿಕೀರ್ತಿತಃ ॥18.7॥",

      "ದುಃಖಮಿತ್ಯೇವ ಯತ್ಕರ್ಮ, ಕಾಯಕ್ಲೇಶಭಯಾತ್ತ್ಯಜೇತ್\nಸ ಕೃತ್ವಾ ರಾಜಸಂ(ನ್) ತ್ಯಾಗಂ(ನ್), ನೈವ ತ್ಯಾಗಫಲಂ(ಮ್) ಲಭೇತ್ ॥18.8॥",

      "ಕಾರ್ಯಮಿತ್ಯೇವ ಯತ್ಕರ್ಮ, ನಿಯತಂ(ಙ್) ಕ್ರಿಯತೇಽರ್ಜುನ\nಸಂಗಂ(ನ್) ತ್ಯಕ್ತ್ವಾ ಫಲಂ(ಞ್) ಚೈವ, ಸ ತ್ಯಾಗಃ(ಸ್) ಸಾತ್ತ್ವಿಕೋ ಮತಃ ॥18.9॥",

      "ನ ದ್ವೇಷ್ಟ್ಯಕುಶಲಂ(ಙ್) ಕರ್ಮ, ಕುಶಲೇ ನಾನುಷಜ್ಜತೇ\nತ್ಯಾಗೀ ಸತ್ತ್ವಸಮಾವಿಷ್ಟೋ, ಮೇಧಾವೀ ಛಿನ್ನಸಂಶಯಃ ॥18.10॥",

      "ನ ಹಿ ದೇಹಭೃತಾ ಶಕ್ಯಂ(ನ್), ತ್ಯಕ್ತುಂ(ಙ್) ಕರ್ಮಾಣ್ಯಶೇಷತಃ \nಯಸ್ತು ಕರ್ಮಫಲತ್ಯಾಗೀ, ಸ ತ್ಯಾಗೀತ್ಯಭಿಧೀಯತೇ ॥18.11॥",

      "ಅನಿಷ್ಟಮಿಷ್ಟಂ(ಮ್) ಮಿಶ್ರಂ(ಞ್) ಚ, ತ್ರಿವಿಧಂ(ಙ್) ಕರ್ಮಣಃ(ಫ್) ಫಲಮ್\nಭವತ್ಯತ್ಯಾಗಿನಾಂ(ಮ್) ಪ್ರೇತ್ಯ, ನ ತು ಸಂನ್ಯಾಸಿನಾಂ(ಙ್) ಕ್ವಚಿತ್ ॥18.12॥",

      "ಪಂಚೈತಾನಿ ಮಹಾಬಾಹೋ, ಕಾರಣಾನಿ ನಿಬೋಧ ಮೇ \nಸಾಂಖ್ಯೇ ಕೃತಾಂತೇ ಪ್ರೋಕ್ತಾನಿ, ಸಿದ್ಧಯೇ ಸರ್ವಕರ್ಮಣಾಮ್ ॥18.13॥",

      "ಅಧಿಷ್ಠಾನಂ(ನ್) ತಥಾ ಕರ್ತಾ, ಕರಣಂ(ಞ್) ಚ ಪೃಥಗ್ವಿಧಮ್ \nವಿವಿಧಾಶ್ಚ ಪೃಥಕ್ಚೇಷ್ಟಾ, ದೈವಂ(ಞ್) ಚೈವಾತ್ರ ಪಂಚಮಮ್ ॥18.14॥ ",

      "ಶರೀರವಾಙ್ಮನೋಭಿರ್ಯತ್, ಕರ್ಮ ಪ್ರಾರಭತೇ ನರಃ \nನ್ಯಾಯ್ಯಂ(ಮ್) ವಾ ವಿಪರೀತಂ(ಮ್) ವಾ, ಪಂಚೈತೇ ತಸ್ಯ ಹೇತವಃ ॥18.15॥",

      "ತತ್ರೈವಂ(ಮ್) ಸತಿ ಕರ್ತಾರಮ್, ಆತ್ಮಾನಂ(ಙ್) ಕೇವಲಂ(ನ್) ತು ಯಃ\nಪಶ್ಯತ್ಯಕೃತಬುದ್ಧಿತ್ವಾನ್, ನ ಸ ಪಶ್ಯತಿ ದುರ್ಮತಿಃ ॥18.16॥",

      "ಯಸ್ಯ ನಾಹಂಕೃತೋ ಭಾವೋ, ಬುದ್ಧಿರ್ಯಸ್ಯ ನ ಲಿಪ್ಯತೇ \nಹತ್ವಾಪಿ ಸ ಇಮಾಲ್ಲೋಕಾನ್, ನ ಹಂತಿ ನ ನಿಬಧ್ಯತೇ ॥18.17॥",

      "ಜ್ಞಾನಂ(ಞ್) ಜ್ಞೇಯಂ(ಮ್) ಪರಿಜ್ಞಾತಾ, ತ್ರಿವಿಧಾ ಕರ್ಮಚೋದನಾ\nಕರಣಂ(ಙ್) ಕರ್ಮ ಕರ್ತೇತಿ, ತ್ರಿವಿಧಃ(ಖ್) ಕರ್ಮಸಂಗ್ರಹಃ ॥18.18॥",

      "ಜ್ಞಾನಂ(ಙ್) ಕರ್ಮ ಚ ಕರ್ತಾ ಚ, ತ್ರಿಧೈವ ಗುಣಭೇದತಃ\nಪ್ರೋಚ್ಯತೇ ಗುಣಸಂಖ್ಯಾನೇ, ಯಥಾವಚ್ಛೃಣು ತಾನ್ಯಪಿ ॥18.19॥",

      "ಸರ್ವಭೂತೇಷು ಯೇನೈಕಂ(ಮ್), ಭಾವಮವ್ಯಯಮೀಕ್ಷತೇ \nಅವಿಭಕ್ತಂ(ಮ್) ವಿಭಕ್ತೇಷು, ತಜ್ ಜ್ಞಾನಂ(ಮ್) ವಿದ್ದಿ ಸಾತ್ವಿಕಮ್ ॥18.20॥",

      "ಪೃಥಕ್ತ್ವೇನ ತು ಯಜ್ ಜ್ಞಾನಂ(ನ್), ನಾನಾಭಾವಾನ್ಪೃಥಗ್ವಿಧಾನ್\nವೇತ್ತಿ ಸರ್ವೇಷು ಭೂತೇಷು, ತಜ್ ಜ್ಞಾನಂ(ಮ್) ವಿದ್ಧಿ ರಾಜಸಮ್ ॥18.21॥",

      "ಯತ್ತು ಕೃತ್ಸ್ನವದೇಕಸ್ಮಿನ್, ಕಾರ್ಯೇ ಸಕ್ತಮಹೈತುಕಮ್\nಅತತ್ತ್ವಾರ್ಥವದಲ್ಪಂ(ಞ್) ಚ, ತತ್ತಾಮಸಮುದಾಹೃತಮ್ ॥18.22॥",

      "ನಿಯತಂ(ಮ್) ಸಂಗರಹಿತಮ್, ಅರಾಗದ್ವೇಷತಃ(ಖ್) ಕೃತಮ್\nಅಫಲಪ್ರೇಪ್ಸುನಾ ಕರ್ಮ, ಯತ್ತತ್ಸಾತ್ತ್ವಿಕಮುಚ್ಯತೇ ॥18.23॥",

      "ಯತ್ತು ಕಾಮೇಪ್ಸುನಾ ಕರ್ಮ, ಸಾಹಂಕಾರೇಣ ವಾ ಪುನಃ\nಕ್ರಿಯತೇ ಬಹುಲಾಯಾಸಂ(ನ್), ತದ್ರಾಜಸಮುದಾಹೃತಮ್ ॥18.24॥",

      "ಅನುಬಂಧಂ(ಙ್) ಕ್ಷಯಂ(ಮ್) ಹಿಂಸಾಮ್, ಅನವೇಕ್ಷ್ಯ ಚ ಪೌರುಷಮ್\nಮೋಹಾದಾರಭ್ಯತೇ ಕರ್ಮ, ಯತ್ತತ್ತಾಮಸಮುಚ್ಯತೇ ॥18.25॥",

      "ಮುಕ್ತಸಂಗೋಽನಹಂವಾದೀ, ಧೃತ್ಯುತ್ಸಾಹಸಮನ್ವಿತಃ\nಸಿದ್ಧ್ಯಸಿದ್ಧ್ಯೋರ್ನಿರ್ವಿಕಾರಃ(ಖ್), ಕರ್ತಾ ಸಾತ್ತ್ವಿಕ ಉಚ್ಯತೇ ॥18.26॥",

      "ರಾಗೀ ಕರ್ಮಫಲಪ್ರೇಪ್ಸುಃ(ರ್), ಲುಬ್ಧೋ ಹಿಂಸಾತ್ಮಕೋಽಶುಚಿಃ\nಹರ್ಷಶೋಕಾನ್ವಿತಃ(ಖ್) ಕರ್ತಾ, ರಾಜಸಃ(ಫ್) ಪರಿಕೀರ್ತಿತಃ ॥18.27॥",

      "ಅಯುಕ್ತಃ(ಫ್) ಪ್ರಾಕೃತಃ(ಸ್) ಸ್ತಬ್ಧಃ(ಶ್), ಶಠೋ ನೈಷ್ಕೃತಿಕೋಽಲಸಃ\nವಿಷಾದೀ ದೀರ್ಘಸೂತ್ರೀ ಚ, ಕರ್ತಾ ತಾಮಸ ಉಚ್ಯತೇ ॥18.28॥",

      "ಬುದ್ಧೇರ್ಭೇದಂ(ನ್) ಧೃತೇಶ್ಚೈವ, ಗುಣತಸ್ತ್ರಿವಿಧಂ(ಮ್) ಶೃಣು\nಪ್ರೋಚ್ಯಮಾನಮಶೇಷೇಣ, ಪೃಥಕ್ತ್ವೇನ ಧನಂಜಯ ॥18.29॥",

      "ಪ್ರವೃತ್ತಿಂ(ಞ್) ಚ ನಿವೃತ್ತಿಂ(ಞ್) ಚ, ಕಾರ್ಯಾಕಾರ್ಯೇ ಭಯಾಭಯೇ\nಬಂಧಂ(ಮ್) ಮೋಕ್ಷಂ(ಞ್) ಚ ಯಾ ವೇತ್ತಿ, ಬುದ್ಧಿಃ(ಸ್) ಸಾ ಪಾರ್ಥ ಸಾತ್ತ್ವಿಕೀ ॥18.30॥",

      "ಯಯಾ ಧರ್ಮಮಧರ್ಮಂ(ಞ್) ಚ, ಕಾರ್ಯಂ(ಞ್) ಚಾಕಾರ್ಯಮೇವ ಚ\nಅಯಥಾವತ್ಪ್ರಜಾನಾತಿ, ಬುದ್ಧಿಃ(ಸ್) ಸಾ ಪಾರ್ಥ ರಾಜಸೀ ॥18.31॥",

      "ಅಧರ್ಮಂ(ನ್) ಧರ್ಮಮಿತಿ ಯಾ, ಮನ್ಯತೇ ತಮಸಾವೃತಾ\nಸರ್ವಾರ್ಥಾನ್ವಿಪರೀತಾಂಶ್ಚ, ಬುದ್ಧಿಃ(ಸ್) ಸಾ ಪಾರ್ಥ ತಾಮಸೀ ॥18.32॥ ",

      "ಧೃತ್ಯಾ ಯಯಾ ಧಾರಯತೇ, ಮನಃ(ಫ್)ಪ್ರಾಣೇಂದ್ರಿಯಕ್ರಿಯಾಃ\nಯೋಗೇನಾವ್ಯಭಿಚಾರಿಣ್ಯಾ, ಧೃತಿಃ(ಸ್) ಸಾ ಪಾರ್ಥ ಸಾತ್ತ್ವಿಕೀ ॥18.33॥",

      "ಯಯಾ ತು ಧರ್ಮಕಾಮಾರ್ಥಾನ್, ಧೃತ್ಯಾ ಧಾರಯತೇಽರ್ಜುನ\nಪ್ರಸಂಗೇನ ಫಲಾಕಾಂಕ್ಷೀ, ಧೃತಿಃ(ಸ್) ಸಾ ಪಾರ್ಥ ರಾಜಸೀ ॥18.34॥",

      "ಯಯಾ ಸ್ವಪ್ನಂ(ಮ್) ಭಯಂ(ಮ್) ಶೋಕಂ(ಮ್), ವಿಷಾದಂ(ಮ್) ಮದಮೇವ ಚ\nನ ವಿಮುಂಚತಿ ದುರ್ಮೇಧಾ, ಧೃತಿಃ(ಸ್) ಸಾ ಪಾರ್ಥ ತಾಮಸೀ ॥18.35॥",

      "ಸುಖಂ(ನ್) ತ್ವಿದಾನೀಂ(ನ್) ತ್ರಿವಿಧಂ(ಮ್), ಶೃಣು ಮೇ ಭರತರ್ಷಭ\nಅಭ್ಯಾಸಾದ್ರಮತೇ ಯತ್ರ, ದುಃಖಾಂತಂ(ಞ್) ಚ ನಿಗಚ್ಛತಿ ॥18.36॥",

      "ಯತ್ತದಗ್ರೇ ವಿಷಮಿವ, ಪರಿಣಾಮೇಽಮೃತೋಪಮಮ್\nತತ್ಸುಖಂ(ಮ್) ಸಾತ್ತ್ವಿಕಂ(ಮ್) ಪ್ರೋಕ್ತಮ್, ಆತ್ಮಬುದ್ಧಿಪ್ರಸಾದಜಮ್ ॥18.37॥",

      "ವಿಷಯೇಂದ್ರಿಯಸಂಯೋಗಾದ್, ಯತ್ತದಗ್ರೇಽಮೃತೋಪಮಮ್\nಪರಿಣಾಮೇ ವಿಷಮಿವ, ತತ್ಸುಖಂ(ಮ್) ರಾಜಸಂ(ಮ್) ಸ್ಮೃತಂ ॥18.38॥",

      "ಯದಗ್ರೇ ಚಾನುಬಂಧೇ ಚ, ಸುಖಂ(ಮ್) ಮೋಹನಮಾತ್ಮನಃ\nನಿದ್ರಾಲಸ್ಯಪ್ರಮಾದೋತ್ಥಂ(ನ್), ತತ್ತಾಮಸಮುದಾಹೃತಮ್ ॥18.39॥",

      "ನ ತದಸ್ತಿ ಪೃಥಿವ್ಯಾಂ(ಮ್) ವಾ, ದಿವಿ ದೇವೇಷು ವಾ ಪುನಃ \nಸತ್ತ್ವಂ(ಮ್) ಪ್ರಕೃತಿಜೈರ್ಮುಕ್ತಂ(ಮ್), ಯದೇಭಿಃ(ಸ್) ಸ್ಯಾತ್ತ್ರಿಭಿರ್ಗುಣೈಃ ॥18.40॥",

      "ಬ್ರಾಹ್ಮಣಕ್ಷತ್ರಿಯವಿಶಾಂ(ಮ್), ಶೂದ್ರಾಣಾಂ(ಞ್) ಚ ಪರಂತಪ\nಕರ್ಮಾಣಿ ಪ್ರವಿಭಕ್ತಾನಿ, ಸ್ವಭಾವಪ್ರಭವೈರ್ಗುಣೈಃ ॥18.41॥",

      "ಶಮೋ ದಮಸ್ತಪಃ(ಶ್) ಶೌಚಂ(ಙ್), ಕ್ಷಾಂತಿರಾರ್ಜವಮೇವ ಚ\nಜ್ಞಾನಂ(ಮ್) ವಿಜ್ಞಾನಮಾಸ್ತಿಕ್ಯಂ(ಮ್), ಬ್ರಹ್ಮಕರ್ಮ ಸ್ವಭಾವಜಮ್ ॥18.42॥",

      "ಶೌರ್ಯಂ(ನ್) ತೇಜೋ ಧೃತಿರ್ದಾಕ್ಷ್ಯಂ(ಮ್), ಯುದ್ಧೇ ಚಾಪ್ಯಪಲಾಯನಮ್\nದಾನಮೀಶ್ವರಭಾವಶ್ಚ, ಕ್ಷಾತ್ರಂ(ಙ್) ಕರ್ಮ ಸ್ವಭಾವಜಮ್ ॥18.43॥",

      "ಕೃಷಿಗೌರಕ್ಷ್ಯವಾಣಿಜ್ಯಂ(ಮ್), ವೈಶ್ಯಕರ್ಮ ಸ್ವಭಾವಜಮ್\nಪರಿಚರ್ಯಾತ್ಮಕಂ(ಙ್) ಕರ್ಮ, ಶೂದ್ರಸ್ಯಾಪಿ ಸ್ವಭಾವಜಮ್ ॥18.44॥",

      "ಸ್ವೇ ಸ್ವೇ ಕರ್ಮಣ್ಯಭಿರತಃ(ಸ್), ಸಂಸಿದ್ಧಿಂ(ಮ್) ಲಭತೇ ನರಃ\nಸ್ವಕರ್ಮನಿರತಃ(ಸ್) ಸಿದ್ಧಿಂ(ಮ್), ಯಥಾ ವಿಂದತಿ ತಚ್ಛೃಣು ॥18.45॥",

      "ಯತಃ(ಫ್) ಪ್ರವೃತ್ತಿರ್ಭೂತಾನಾಂ(ಮ್), ಯೇನ ಸರ್ವಮಿದಂ(ನ್) ತತಮ್\nಸ್ವಕರ್ಮಣಾ ತಮಭ್ಯರ್ಚ್ಯ, ಸಿದ್ಧಿಂ(ಮ್) ವಿಂದತಿ ಮಾನವಃ ॥18.46॥",

      "ಶ್ರೇಯಾನ್ಸ್ವಧರ್ಮೋ ವಿಗುಣಃ(ಫ್), ಪರಧರ್ಮಾತ್ಸ್ವನುಷ್ಠಿತಾತ್\nಸ್ವಭಾವನಿಯತಂ(ಙ್) ಕರ್ಮ, ಕುರ್ವನ್ನಾಪ್ನೋತಿ ಕಿಲ್ಬಿಷಮ್ ॥18.47॥",

      "ಸಹಜಂ(ಙ್) ಕರ್ಮ ಕೌಂತೇಯ, ಸದೋಷಮಪಿ ನ ತ್ಯಜೇತ್\nಸರ್ವಾರಂಭಾ ಹಿ ದೋಷೇಣ, ಧೂಮೇನಾಗ್ನಿರಿವಾವೃತಾಃ ॥18.48॥",

      "ಅಸಕ್ತಬುದ್ಧಿಃ(ಸ್) ಸರ್ವತ್ರ, ಜಿತಾತ್ಮಾ ವಿಗತಸ್ಪೃಹಃ\nನೈಷ್ಕರ್ಮ್ಯಸಿದ್ಧಿಂ(ಮ್) ಪರಮಾಂ(ಮ್), ಸಂನ್ಯಾಸೇನಾಧಿಗಚ್ಛತಿ ॥18.49॥",

      "ಸಿದ್ಧಿಂ(ಮ್) ಪ್ರಾಪ್ತೋ ಯಥಾ ಬ್ರಹ್ಮ, ತಥಾಪ್ನೋತಿ ನಿಬೋಧ ಮೇ\nಸಮಾಸೇನೈವ ಕೌಂತೇಯ, ನಿಷ್ಠಾ ಜ್ಞಾನಸ್ಯ ಯಾ ಪರಾ ॥18.50॥",

      "ಬುದ್ಧ್ಯಾ ವಿಶುದ್ಧಯಾ ಯುಕ್ತೋ, ಧೃತ್ಯಾತ್ಮಾನಂ(ನ್) ನಿಯಮ್ಯ ಚ\nಶಬ್ದಾದೀನ್ವಿಷಯಾಂಸ್ತ್ಯಕ್ತ್ವಾ, ರಾಗದ್ವೇಷೌ ವ್ಯುದಸ್ಯ ಚ ॥18.51॥",
      
      "ವಿವಿಕ್ತಸೇವೀ ಲಘ್ವಾಶೀ, ಯತವಾಕ್ಕಾಯಮಾನಸಃ\nಧ್ಯಾನಯೋಗಪರೋ ನಿತ್ಯಂ(ಮ್), ವೈರಾಗ್ಯಂ(ಮ್) ಸಮುಪಾಶ್ರಿತಃ ॥18.52॥",
      
      "ಅಹಂಕಾರಂ(ಮ್) ಬಲಂ(ನ್) ದರ್ಪಂ(ಙ್), ಕಾಮಂ(ಙ್) ಕ್ರೋಧಂ(ಮ್) ಪರಿಗ್ರಹಮ್\nವಿಮುಚ್ಯ ನಿರ್ಮಮಃ(ಶ್) ಶಾಂತೋ, ಬ್ರಹ್ಮಭೂಯಾಯ ಕಲ್ಪತೇ ॥18.53॥",
      
      "ಬ್ರಹ್ಮಭೂತಃ(ಫ್) ಪ್ರಸನ್ನಾತ್ಮಾ, ನ ಶೋಚತಿ ನ ಕಾಂಕ್ಷತಿ\nಸಮಃ(ಸ್) ಸರ್ವೇಷು ಭೂತೇಷು, ಮದ್ಭಕ್ತಿಂ(ಮ್) ಲಭತೇ ಪರಾಮ್ ॥18.54॥",
      
      "ಭಕ್ತ್ಯಾ ಮಾಮಭಿಜಾನಾತಿ, ಯಾವಾನ್ಯಶ್ಚಾಸ್ಮಿ ತತ್ತ್ವತಃ\nತತೋ ಮಾಂ(ನ್) ತತ್ತ್ವತೋ ಜ್ಞಾತ್ವಾ, ವಿಶತೇ ತದನಂತರಮ್ ॥18.55॥",
      
      "ಸರ್ವಕರ್ಮಾಣ್ಯಪಿ ಸದಾ, ಕುರ್ವಾಣೋ ಮದ್ವ್ಯಪಾಶ್ರಯಃ\nಮತ್ಪ್ರಸಾದಾದವಾಪ್ನೋತಿ, ಶಾಶ್ವತಂ(ಮ್) ಪದಮವ್ಯಯಮ್ ॥18.56॥",
      
      "ಚೇತಸಾ ಸರ್ವಕರ್ಮಾಣಿ, ಮಯಿ ಸಂನ್ಯಸ್ಯ ಮತ್ಪರಃ\nಬುದ್ಧಿಯೋಗಮುಪಾಶ್ರಿತ್ಯ, ಮಚ್ಚಿತ್ತಃ(ಸ್) ಸತತಂ(ಮ್) ಭವ ॥18.57॥",
      
      "ಮಚ್ಚಿತ್ತಃ(ಸ್) ಸರ್ವದುರ್ಗಾಣಿ, ಮತ್ಪ್ರಸಾದಾತ್ತರಿಷ್ಯಸಿ\nಅಥ ಚೇತ್ತ್ವಮಹಂಕಾರಾನ್, ನ ಶ್ರೋಷ್ಯಸಿ ವಿನಂಕ್ಷ್ಯಸಿ ॥18.58॥",
      
      "ಯದಹಂಕಾರಮಾಶ್ರಿತ್ಯ, ನ ಯೋತ್ಸ್ಯ ಇತಿ ಮನ್ಯಸೇ\nಮಿಥ್ಯೈಷ ವ್ಯವಸಾಯಸ್ತೇ, ಪ್ರಕೃತಿಸ್ತ್ವಾಂ(ನ್) ನಿಯೋಕ್ಷ್ಯತಿ ॥18.59॥",
      
      "ಸ್ವಭಾವಜೇನ ಕೌಂತೇಯ, ನಿಬದ್ಧಃ(ಸ್) ಸ್ವೇನ ಕರ್ಮಣಾ\nಕರ್ತುಂ(ನ್) ನೇಚ್ಛಸಿ ಯನ್ಮೋಹಾತ್, ಕರಿಷ್ಯಸ್ಯವಶೋಽಪಿ ತತ್ ॥18.60॥",

      "ಈಶ್ವರಃ(ಸ್) ಸರ್ವಭೂತಾನಾಂ(ಮ್), ಹೃದ್ದೇಶೇಽರ್ಜುನ ತಿಷ್ಠತಿ\nಭ್ರಾಮಯನ್ಸರ್ವಭೂತಾನಿ, ಯಂತ್ರಾರೂಢಾನಿ ಮಾಯಯಾ ॥18.61॥",

      "ತಮೇವ ಶರಣಂ(ಙ್) ಗಚ್ಛ, ಸರ್ವಭಾವೇನ ಭಾರತ\nತತ್ಪ್ರಸಾದಾತ್ಪರಾಂ(ಮ್) ಶಾಂತಿಂ(ಮ್), ಸ್ಥಾನಂ(ಮ್) ಪ್ರಾಪ್ಸ್ಯಸಿ ಶಾಶ್ವತಮ್ ॥18.62॥",

      "ಇತಿ ತೇ ಜ್ಞಾನಮಾಖ್ಯಾತಂ(ಙ್), ಗುಹ್ಯಾದ್ಗುಹ್ಯತರಂ(ಮ್) ಮಯಾ\nವಿಮೃಶ್ಯೈತದಶೇಷೇಣ, ಯಥೇಚ್ಛಸಿ ತಥಾ ಕುರು ॥18.63॥",

      "ಸರ್ವಗುಹ್ಯತಮಂ(ಮ್) ಭೂಯಃ(ಶ್), ಶೃಣು ಮೇ ಪರಮಂ(ಮ್) ವಚಃ\nಇಷ್ಟೋಽಸಿ ಮೇ ದೃಢಮಿತಿ, ತತೋ ವಕ್ಷ್ಯಾಮಿ ತೇ ಹಿತಮ್ ॥18.64॥",

      "ಮನ್ಮನಾ ಭವ ಮದ್ಭಕ್ತೋ, ಮದ್ಯಾಜೀ ಮಾಂ(ನ್) ನಮಸ್ಕುರು\nಮಾಮೇವೈಷ್ಯಸಿ ಸತ್ಯಂ(ನ್) ತೇ, ಪ್ರತಿಜಾನೇ ಪ್ರಿಯೋಽಸಿ ಮೇ ॥18.65॥",

      "ಸರ್ವಧರ್ಮಾನ್ಪರಿತ್ಯಜ್ಯ, ಮಾಮೇಕಂ(ಮ್) ಶರಣಂ(ಮ್) ವ್ರಜ\nಅಹಂ(ನ್) ತ್ವಾ ಸರ್ವಪಾಪೇಭ್ಯೋ, ಮೋಕ್ಷಯಿಷ್ಯಾಮಿ ಮಾ ಶುಚಃ ॥18.66॥",

      "ಇದಂ(ನ್) ತೇ ನಾತಪಸ್ಕಾಯ, ನಾಭಕ್ತಾಯ ಕದಾಚನ\nನ ಚಾಶುಶ್ರೂಷವೇ ವಾಚ್ಯಂ(ನ್), ನ ಚ ಮಾಂ(ಮ್) ಯೋಽಭ್ಯಸೂಯತಿ ॥18.67॥",

      "ಯ ಇಮಂ(ಮ್) ಪರಮಂ(ಙ್) ಗುಹ್ಯಂ(ಮ್), ಮದ್ಭಕ್ತೇಷ್ವಭಿಧಾಸ್ಯತಿ \nಭಕ್ತಿಂ(ಮ್) ಮಯಿ ಪರಾಂ(ಙ್) ಕೃತ್ವಾ, ಮಾಮೇವೈಷ್ಯತ್ಯಸಂಶಯಃ ॥18.68॥",
      
      "ನ ಚ ತಸ್ಮಾನ್ಮನುಷ್ಯೇಷು, ಕಶ್ಚಿನ್ಮೇ ಪ್ರಿಯಕೃತ್ತಮಃ \nಭವಿತಾ ನ ಚ ಮೇ ತಸ್ಮಾದ್, ಅನ್ಯಃ(ಫ್) ಪ್ರಿಯತರೋ ಭುವಿ ॥18.69॥",
      
      "ಅಧ್ಯೇಷ್ಯತೇ ಚ ಯ ಇಮಂ(ನ್), ಧರ್ಮ್ಯಂ(ಮ್) ಸಂವಾದಮಾವಯೋಃ\nಜ್ಞಾನಯಜ್ಞೇನ ತೇನಾಹಮ್, ಇಷ್ಟಃ(ಸ್) ಸ್ಯಾಮಿತಿ ಮೇ ಮತಿಃ ॥18.70॥",
      
      "ಶ್ರದ್ಧಾವಾನನಸೂಯಶ್ಚ, ಶೃಣುಯಾದಪಿ ಯೋ ನರಃ \nಸೋಽಪಿ ಮುಕ್ತಃ(ಶ್) ಶುಭಾಁಲ್ಲೋಕಾನ್, ಪ್ರಾಪ್ನುಯಾತ್ಪುಣ್ಯಕರ್ಮಣಾಮ್ ॥18.71॥",
      
      "ಕಚ್ಚಿದೇತಚ್ಛ್ರುತಂ(ಮ್) ಪಾರ್ಥ, ತ್ವಯೈಕಾಗ್ರೇಣ ಚೇತಸಾ \nಕಚ್ಚಿದಜ್ಞಾನಸಮ್ಮೋಹಃ(ಫ್), ಪ್ರನಷ್ಟಸ್ತೇ ಧನಂಜಯ ॥18.72॥",
       
      "ಅರ್ಜುನ ಉವಾಚ\nನಷ್ಟೋ ಮೋಹಃ(ಸ್) ಸ್ಮೃತಿರ್ಲಬ್ಧಾ, ತ್ವತ್ಪ್ರಸಾದಾನ್ಮಯಾಚ್ಯುತ \nಸ್ಥಿತೋಽಸ್ಮಿ ಗತಸಂದೇಹಃ(ಖ್), ಕರಿಷ್ಯೇ ವಚನಂ(ನ್) ತವ ॥18.73॥",
      
      "ಸಂಜಯ ಉವಾಚ\nಇತ್ಯಹಂ(ಮ್) ವಾಸುದೇವಸ್ಯ, ಪಾರ್ಥಸ್ಯ ಚ ಮಹಾತ್ಮನಃ \nಸಂವಾದಮಿಮಮಶ್ರೌಷಮ್, ಅದ್ಭುತಂ(ಮ್) ರೋಮಹರ್ಷಣಮ್ ॥18.74॥",
      
      "ವ್ಯಾಸಪ್ರಸಾದಾಚ್ಛ್ರುತವಾನ್, ಏತದ್ಗುಹ್ಯಮಹಂ(ಮ್) ಪರಮ್\nಯೋಗಂ(ಮ್) ಯೋಗೇಶ್ವರಾತ್ಕೃಷ್ಣಾತ್, ಸಾಕ್ಷಾತ್ಕಥಯತಃ(ಸ್) ಸ್ವಯಮ್॥18.75॥",
      
      "ರಾಜನ್ಸಂಸ್ಮೃತ್ಯ ಸಂಸ್ಮೃತ್ಯ, ಸಂವಾದಮಿಮಮದ್ಭುತಮ್ \nಕೇಶವಾರ್ಜುನಯೋಃ(ಫ್) ಪುಣ್ಯಂ(ಮ್), ಹೃಷ್ಯಾಮಿ ಚ ಮುಹುರ್ಮುಹುಃ ॥18.76॥",
      
      "ತಚ್ಚ ಸಂಸ್ಮೃತ್ಯ ಸಂಸ್ಮೃತ್ಯ, ರೂಪಮತ್ಯದ್ಭುತಂ(ಮ್) ಹರೇಃ\nವಿಸ್ಮಯೋ ಮೇ ಮಹಾನ್ ರಾಜನ್, ಹೃಷ್ಯಾಮಿ ಚ ಪುನಃ(ಫ್) ಪುನಃ ॥18.77॥",
      
      "ಯತ್ರ ಯೋಗೇಶ್ವರಃ(ಖ್) ಕೃಷ್ಣೋ, ಯತ್ರ ಪಾರ್ಥೋ ಧನುರ್ಧರಃ\nತತ್ರ ಶ್ರೀರ್ವಿಜಯೋ ಭೂತಿಃ(ರ್), ಧ್ರುವಾ ನೀತಿರ್ಮತಿರ್ಮಮ ॥78॥",

      "ಓಂ ತತ್ಸದಿತಿ ಶ್ರೀಮದ್ಭಗವದ್ಗೀತಾಸು ಉಪನಿಷತ್ಸ\nಬ್ರಹ್ಮವಿದ್ಯಾಯಾಂ(ಮ್) ಯೋಗಶಾಸ್ತ್ರೇ \nಶ್ರೀಕೃಷ್ಣಾರ್ಜುನಸಂವಾದೇ ಮೋಕ್ಷಸಂನ್ಯಾಸಯೋಗ ನಾಮ ಅಷ್ಟಾದಶೋಧ್ಯಾಃ ॥18॥"
    ]
  },
  {
    "adhyaya-1" : [
      "ಧೃತರಾಷ್ಟ್ರನು ಹೇಳಿದನು - ಹೇ ಸಂಜಯ ! ಧರ್ಮಭೂಮಿಯಾದ ಕುರುಕ್ಷೇತ್ರದಲ್ಲಿ ಸೇರಿದ ಯುದ್ಧದ ಇಚ್ಛೆಯುಳ್ಳ ನನ್ನವರು ಹಾಗೂ ಪಾಂಡುವಿನ ಪುತ್ರರು ಏನು ಮಾಡಿದರು ? 1.1",

      "ಸಂಜಯನು ಹೇಳಿದನು - ಯುದ್ಧಾರಂಭದ ಸಮಯದಲ್ಲಿ ರಾಜನಾದ ದುರ್ಯೋಧನನು ವ್ಯೂಹರಚನಾ ಯುಕ್ತವಾದ ಪಾಂಡವರ ಸೈನ್ಯವನ್ನು ನೋಡಿ ದ್ರೋಣಾಚಾರ್ಯರ ಬಳಿಗೆ ಹೋಗಿ ಈ ಮಾತನ್ನು ಹೇಳಿದನು. 1.2",

      "ಹೇ ಆಚಾರ್ಯರೇ ! ತಮ್ಮ ಬುದ್ಧಿವಂತನಾದ ಶಿಷ್ಯ ದ್ರುಪದ ಪುತ್ರ ದೃಷ್ಟದ್ಯುಮ್ನನ  ಮೂಲಕ ವ್ಯೂಹಾಕಾರವಾಗಿ ನಿಲ್ಲಿಸಲ್ಪಟ್ಟ ಪಾಂಡುಪುತ್ರರ ಈ ಬಹಳ ದೊಡ್ಡದಾದ ಸೈನ್ಯವನ್ನು ನೋಡಿರಿ. 1.3",

      "ಈ ಸೈನ್ಯದಲ್ಲಿ ದೊಡ್ಡ ದೊಡ್ಡ ಧನುಸ್ಸುಳ್ಳ ಹಾಗೂ ಯುದ್ಧದಲ್ಲಿ ಭೀಮಾರ್ಜುನರಿಗೆ ಸಮಾನರಾದ ಶೂರ - ವೀರ ಸಾತ್ಯಕಿಯೂ, ವಿರಾಟನೂ, ಮಹಾರಥಿಯಾದ ದ್ರುಪದ ರಾಜನೂ, 1.4",

      "ಧೃಷ್ಟಕೇತು ಮತ್ತು ಚೇಕಿತಾನನು ಹಾಗೂ ಬಲಶಾಲಿಯಾದ ಕಾಶಿರಾಜನೂ, ಪುರುಜಿತ್ತು, ಕುಂತೀಭೋಜನೂ, ಮನುಷ್ಯರಲ್ಲಿ ಶ್ರೇಷ್ಠನಾದ ಶೈಬ್ಯನೂ, 1.5",

      "ಪರಾಕ್ರಮಿಯಾದ ಯುಧಾಮನ್ಯುವು, ಬಲಶಾಲಿಯಾದ ಉತ್ತಮೌಜನು, ಸುಭದ್ರಾಪುತ್ರನಾದ ಅಭಿಮನ್ಯುವು ಹಾಗೂ ದ್ರೌಪದಿಯ ಐವರು ಪುತ್ರರೂ - ಈ ಎಲ್ಲ ಮಹಾರಥಿಗಳಿದ್ದಾರೆ. 1.6",

      "ಹೇ ಬ್ರಾಹ್ಮಣಶ್ರೇಷ್ಠರೇ ! ನಮ್ಮ ಪಕ್ಷದಲ್ಲಿರುವ ಪ್ರಧಾನ ಯೋಧರನ್ನು ಸಹ ನೀವು ತಿಳಿದುಕೊಳ್ಳಿರಿ. ತಮ್ಮ ತಿಳಿವಳಿಕೆಗಾಗಿ ನನ್ನ ಸೈನ್ಯದಲ್ಲಿರುವ ಸೇನಾಧಿಪತಿಗಳ ಕುರಿತು ತಿಳಿಸುತ್ತೇನೆ. 1.7",

      "ದ್ರೋಣಾಚಾರ್ಯರಾದ ನೀವು ಮತ್ತು ಪಿತಾಮಹರಾದ ಭೀಷ್ಮರು ಹಾಗೂ ಕರ್ಣ ಮತ್ತು ಸಂಗ್ರಾಮವಿಜಯಿಯಾದ ಕೃಪಾಚಾರ್ಯರು, ಅಂತೆಯೇ ಅಶ್ವತ್ಥಾಮ, ವಿಕರ್ಣ ಮತ್ತು ಸೋಮದತ್ತನ ಪುತ್ರರಾದ ಭೂರಿಶ್ರವ, 1.8",

      "ನನಗೋಸ್ಕರ ಜೀವನದ ಆಸೆಯನ್ನು ತ್ಯಾಗಮಾಡುವಂತಹ ಅನೇಕ ಶೂರವೀರರು ನಾನಾ ಪ್ರಕಾರದ ಶಸ್ತ್ರಾಸ್ತ್ರಗಳಿಂದ ಸುಸಜ್ಜಿತರಾಗಿದ್ದಾರೆ ಮತ್ತು ಅವರೆಲ್ಲರೂ ಯುದ್ಧದಲ್ಲಿ ನಿಪುಣರಾಗಿದ್ದಾರೆ. 1.9",

      "ಭೀಷ್ಮಪಿತಾಮಹರಿಂದ ರಕ್ಷಿತವಾದ ನಮ್ಮ ಆ ಸೈನ್ಯವು ಅಪರಿಮಿತವಾಗಿದೆ ಅರ್ಥಾತ್ ಎಲ್ಲ ಪ್ರಕಾರದಿಂದ ಅಜೇಯವಾಗಿದೆ ಮತ್ತು ಭೀಮನಿಂದ ರಕ್ಷಿತವಾಗಿರುವ ಇವರುಗಳ ಈ ಸೈನ್ಯವು ಪರಿಮಿತ ಅರ್ಥಾತ್ ಗೆಲ್ಲುವುದು ಸುಗಮವಾಗಿದೆ. 1.10",

      "ಆದ್ದರಿಂದ ಎಲ್ಲ ವ್ಯೂಹದ್ವಾರಗಳಲ್ಲಿ ನಿಮ್ಮ ನಿಮ್ಮ ಜಾಗಗಳಲ್ಲಿ ಸ್ಥಿರವಾಗಿರುತ್ತ ನೀವೆಲ್ಲರೂ ನಿಸ್ಸಂದೇಹರಾಗಿ ಭೀಷ್ಮಪಿತಾಮಹರನ್ನೇ ಎಲ್ಲ ಕಡೆಯಿಂದ ರಕ್ಷಿಸಿರಿ. 1.11",

      "ಕೌರವರಲ್ಲಿ ವೃದ್ಧರಾದ, ಬಹಳ ಪ್ರತಾಪಿಯಾದ ಪಿತಾಮಹ ಭೀಷ್ಮರು ಆ ದುರ್ಯೋಧನನ ಹೃದಯದಲ್ಲಿ ಹರ್ಷವನ್ನುಂಟುಮಾಡುತ್ತ ಉಚ್ಚ ಸ್ವರದಲ್ಲಿ ಸಿಂಹದ ಗರ್ಜನೆಗೆ ಸಮನಾಗಿ ಗರ್ಜಿಸಿ ಶಂಖವನ್ನು ಊದಿದರು. 1.12",

      "ಇದಾದ ನಂತರ ಶಂಖಗಳು, ನಗಾರಿಗಳು ಹಾಗೂ ಡೋಲು, ಮೃದಂಗ, ಕಹಳೆ ಮೊದಲಾದ ವಾದ್ಯಗಳು ಒಟ್ಟಿಗೆ ಮೊಳಗಿದವು. ಅವುಗಳ ಆ ಶಬ್ದವು ಬಹಳ ಭಯಂಕರವಾಯಿತು. 1.13",

      "ಇದಾದ ನಂತರ ಬಿಳಿಯ ಬಣ್ಣದ ಕುದುರೆಗಳಿಂದ ಯುಕ್ತವಾದ ಉತ್ತಮ ರಥದಲ್ಲಿ ಕುಳಿತಿದ್ದ ಭಗವಾನ್ ಶ್ರೀ ಕೃಷ್ಣನು ಮತ್ತು ಅರ್ಜುನನೂ ಕೂಡ ಅಲೌಕಿಕವಾದ ಶಂಖಗಳನ್ನು ಊದಿದರು. 1.14",

      "ಭಗವಾನ್ ಶ್ರ‍ೀಕೃಷ್ಣನು ಪಾಂಚಜನ್ಯವೆಂಬ ಹೆಸರಿನ ಶಂಖವನ್ನೂ, ಅರ್ಜುನನು ದೇವದತ್ತವೆಂಬ ಹೆಸರಿನ ಶಂಖವನ್ನೂ ಮತ್ತು ಭಯಾನಕ ಕರ್ಮಗಳನ್ನು ಮಾಡುವ ಭೀಮಸೇನನು ಪೌಂಡ್ರವೆಂಬ ಹೆಸರಿನ ಮಹಾಶಂಖವನ್ನು ಊದಿದರು. 1.15",

      "ಕುಂತಿಯ ಪುತ್ರನಾದ ರಾಜಾ ಯುಧಿಷ್ಠಿರನು ಅನಂತವಿಜಯವೆಂಬ ಹೆಸರಿನ ಮತ್ತು ನಕುಲ ಹಾಗೂ ಸಹದೇವರು ಸುಘೋಷ ಮತ್ತು ಮಣಿಪುಷ್ಪಕವೆಂಬ ಹೆಸರಿನ ಶಂಖಗಳನ್ನು ಊದಿದರು. 1.16",

      "ಶ್ರೇಷ್ಠವಾದ ಧನುಸ್ಸುಳ್ಳ ಕಾಶಿರಾಜನು ಮತ್ತು ಮಹಾರಥಿಯಾದ ಶಿಖಂಡೀ ಹಾಗೂ ಧೃಷ್ಟದ್ಯುಮ್ನ, ರಾಜಾ ವಿರಾಟನು ಮತ್ತು ಅಜೇಯನಾದ ಸಾತ್ಯಕಿಯು, ರಾಜನಾದ ದ್ರುಪದನು, ದ್ರೌಪದಿಯ ಐವರು ಪುತ್ರರು ಮತ್ತು ಮಹಾಬಾಹುವಾದ ಸುಭದ್ರಾಪುತ್ರ ಅಭಿಮನ್ಯುವು - ಇವರೆಲ್ಲರೂ ಹೇ! ರಾಜನೇ ! ಎಲ್ಲ ಕಡೆಗಳಿಂದ ಬೇರೆ-ಬೇರೆಯಾಗಿ ಶಂಖಗಳನ್ನು ಊದಿದರು. (1.17-1.18)",

      "ಶ್ರೇಷ್ಠವಾದ ಧನುಸ್ಸುಳ್ಳ ಕಾಶಿರಾಜನು ಮತ್ತು ಮಹಾರಥಿಯಾದ ಶಿಖಂಡೀ ಹಾಗೂ ಧೃಷ್ಟದ್ಯುಮ್ನ, ರಾಜಾ ವಿರಾಟನು ಮತ್ತು ಅಜೇಯನಾದ ಸಾತ್ಯಕಿಯು, ರಾಜನಾದ ದ್ರುಪದನು, ದ್ರೌಪದಿಯ ಐವರು ಪುತ್ರರು ಮತ್ತು ಮಹಾಬಾಹುವಾದ ಸುಭದ್ರಾಪುತ್ರ ಅಭಿಮನ್ಯುವು - ಇವರೆಲ್ಲರೂ ಹೇ! ರಾಜನೇ ! ಎಲ್ಲ ಕಡೆಗಳಿಂದ ಬೇರೆ-ಬೇರೆಯಾಗಿ ಶಂಖಗಳನ್ನು ಊದಿದರು. (1.17-1.18)",

      "ಮತ್ತು ಆ ಭಯಾನಕವಾದ ಶಬ್ದವು ಆಕಾಶ ಮತ್ತು ಪೃಥ್ವಿಯನ್ನು ಸಹ ಪ್ರತಿಧ್ವನಿಗೊಳಿಸುತ್ತ ದುರ್ಯೋಧನಾದಿಗಳ ಅಂದರೆ ನಿಮ್ಮ ಪಕ್ಷದವರ ಹೃದಯಗಳನ್ನು ಭೇದಿಸಿತು. 1.19",

      "ಹೇ ರಾಜಾ ! ಇದಾದನಂತರ ಕಪಿಧ್ವಜನಾದ ಅರ್ಜುನನು ಯುದ್ಧಕ್ಕೆ ಸನ್ನದ್ಧರಾಗಿ ನಿಂತಿರುವ ಧೃತರಾಷ್ಟ್ರನ ಸಂಬಂಧಿಗಳನ್ನು ನೋಡಿದವನಾಗಿ ಆ ಶಸ್ತ್ರ ಪ್ರಯೋಗ ಸಿದ್ಧತೆಯ ಸಮಯದಲ್ಲಿ ಧನುಸ್ಸನ್ನು ಎತ್ತಿಕೊಂಡು ಹೃಷೀಕೇಶನಾದ ಭಗವಾನ್ ಶ್ರ‍ೀ ಕೃಷ್ಣನಿಗೆ ಈ ವಚನವನ್ನು ಹೇಳಿದನು. ಹೇ ಅಚ್ಯುತನೇ ! ನನ್ನ ರಥವನ್ನು ಎರಡೂ ಸೈನ್ಯಗಳ ಮಧ್ಯದಲ್ಲಿ ನಿಲ್ಲಿಸಿಬಿಡು. (1.20-1.21)",

      "ಹೇ ರಾಜಾ ! ಇದಾದನಂತರ ಕಪಿಧ್ವಜನಾದ ಅರ್ಜುನನು ಯುದ್ಧಕ್ಕೆ ಸನ್ನದ್ಧರಾಗಿ ನಿಂತಿರುವ ಧೃತರಾಷ್ಟ್ರನ ಸಂಬಂಧಿಗಳನ್ನು ನೋಡಿದವನಾಗಿ ಆ ಶಸ್ತ್ರ ಪ್ರಯೋಗ ಸಿದ್ಧತೆಯ ಸಮಯದಲ್ಲಿ ಧನುಸ್ಸನ್ನು ಎತ್ತಿಕೊಂಡು ಹೃಷೀಕೇಶನಾದ ಭಗವಾನ್ ಶ್ರ‍ೀ ಕೃಷ್ಣನಿಗೆ ಈ ವಚನವನ್ನು ಹೇಳಿದನು. ಹೇ ಅಚ್ಯುತನೇ ! ನನ್ನ ರಥವನ್ನು ಎರಡೂ ಸೈನ್ಯಗಳ ಮಧ್ಯದಲ್ಲಿ ನಿಲ್ಲಿಸಿಬಿಡು. (1.20-1.21)",

      "ನಾನು ಯುದ್ಧಕ್ಷೇತ್ರದಲ್ಲಿ ಸನ್ನದ್ಧರಾಗಿರುವ ಯುದ್ಧದ ಅಭಿಲಾಷೆಯುಳ್ಳ ಈ ವಿಪಕ್ಷದ ಯೋಧರನ್ನು ಚೆನ್ನಾಗಿ ನೋಡಿಬಿಡುತ್ತೇನೆ. ಈ ಯುದ್ಧರೂಪವಾದ ವ್ಯಾಪಾರದಲ್ಲಿ ನಾನು ಯಾರು-ಯಾರೊಡನೆ ಯುದ್ಧಮಾಡುವುದು ಯೋಗ್ಯವೆಂಬುದನ್ನು ತಿಳಿದುಕೊಳ್ಳುವೆನು. ಅಲ್ಲಿಯವರೆಗೆ ರಥವನ್ನು ನಿಲ್ಲಿಸಿಕೊಂಡಿರು. 1.22",

      "ದುಷ್ಟಬುದ್ಧಿಯುಳ್ಳ ದುರ್ಯೋಧನನಿಗೆ ಯುದ್ಧದಲ್ಲಿ ಹಿತವನ್ನು ಬಯಸುವ ಯಾವ ಯಾವ ರಾಜರುಗಳು ಈ ಸೈನ್ಯದಲ್ಲಿ ಬಂದಿದ್ದಾರೋ ಅಂತಹ ಯುದ್ಧಮಾಡುವವರನ್ನು ನಾನು ನೋಡಿಕೊಳ್ಳುವೆನು. 1.23",

      "ಸಂಜಯನು ಹೇಳಿದನು - ಹೇ ಧೃತರಾಷ್ಟ್ರ ! ಅರ್ಜುನನು ಈ ಪ್ರಕಾರವಾಗಿ ಹೇಳಿದಾಗ ಭಗವಾನ್ ಶ್ರೀ ಕೃಷ್ಣನು ಎರಡೂ ಸೈನ್ಯಗಳ ಮಧ್ಯದಲ್ಲಿ ಭೀಷ್ಮ ಮತ್ತು ದ್ರೋಣಾಚಾರ್ಯರ ಎದುರಿಗೆ ಹಾಗೂ ಸಂಪೂರ್ಣ ರಾಜರುಗಳೆದುರಿನಲ್ಲಿ ಉತ್ತಮವಾದ ರಥವನ್ನು ನಿಲ್ಲಿಸಿ ಹೇ ಪಾರ್ಥನೇ ! ಯುದ್ಧಕ್ಕಾಗಿ ನೆರೆದಿರುವ ಈ ಕೌರವರನ್ನು ನೋಡು ಈ ಪ್ರಕಾರವಾಗಿ ಹೇಳಿದನು. (1.24-1.25)",

      "ಸಂಜಯನು ಹೇಳಿದನು - ಹೇ ಧೃತರಾಷ್ಟ್ರ ! ಅರ್ಜುನನು ಈ ಪ್ರಕಾರವಾಗಿ ಹೇಳಿದಾಗ ಭಗವಾನ್ ಶ್ರೀ ಕೃಷ್ಣನು ಎರಡೂ ಸೈನ್ಯಗಳ ಮಧ್ಯದಲ್ಲಿ ಭೀಷ್ಮ ಮತ್ತು ದ್ರೋಣಾಚಾರ್ಯರ ಎದುರಿಗೆ ಹಾಗೂ ಸಂಪೂರ್ಣ ರಾಜರುಗಳೆದುರಿನಲ್ಲಿ ಉತ್ತಮವಾದ ರಥವನ್ನು ನಿಲ್ಲಿಸಿ ಹೇ ಪಾರ್ಥನೇ ! ಯುದ್ಧಕ್ಕಾಗಿ ನೆರೆದಿರುವ ಈ ಕೌರವರನ್ನು ನೋಡು ಈ ಪ್ರಕಾರವಾಗಿ ಹೇಳಿದನು. (1.24-1.25)",

      "ಇದಾದ ನಂತರ ಪಾರ್ಥನು ಆ ಎರಡೂ ಸೈನ್ಯಗಳಲ್ಲಿ ಸೇರಿದ್ದ ದೊಡ್ಡಪ್ಪ - ಚಿಕ್ಕಪ್ಪಂದಿರನ್ನು, ಅಜ್ಜ - ಮುತ್ತಜ್ಜರನ್ನು, ಗುರುಗಳನ್ನು, ಸೋದರಮಾವಂದಿರನ್ನು, ಸಹೋದರರನ್ನು, ಮಕ್ಕಳು - ಮೊಮ್ಮಕ್ಕಳನ್ನು, ಹಾಗೂ ಮಿತ್ರರನ್ನು, ಮಾವಂದಿರನ್ನು ಮತ್ತು ಸಹೃದಯರನ್ನೂ ನೋಡಿದನು. 1.26",

      "ಅಲ್ಲಿ ಉಪಸ್ಥಿತರಿದ್ದ ಸಂಪೂರ್ಣ ಬಂಧುಗಳನ್ನು ನೋಡಿ ಆ ಕುಂತೀಪುತ್ರನಾದ ಅರ್ಜುನನು ಅತ್ಯಂತ ಕರುಣಾ ಪರವಶನಾಗಿ ಶೋಕಿಸುತ್ತ ಹೀಗೆ ಹೇಳಿದನು. 1.27",

      "ಅರ್ಜುನನು ಹೇಳಿದನು - ಹೇ ಕೃಷ್ಣ ! ಯುದ್ಧಕ್ಷೇತದಲ್ಲಿ ಸಿದ್ಧರಾಗಿ ನಿಂತಿರುವ ಯುದ್ಧಾಭಿಲಾಷಿಗಳಾದ ಈ ಸ್ವಜನ ಸಮುದಾಯವನ್ನು ನೋಡಿ ನನ್ನ ಅಂಗಾಂಗಗಳು ಶಿಥಿಲವಾಗಿ ಹೋಗುತ್ತಿವೆ. ಮುಖವು ಒಣಗಿ ಹೋಗುತ್ತಿದೆ ಹಾಗೂ ನನ್ನ ಶರೀರದಲ್ಲಿ ಕಂಪನ ಮತ್ತು ರೋಮಾಂಚನವಾಗುತ್ತಿದೆ. (1.28-1.29)",

      "ಅರ್ಜುನನು ಹೇಳಿದನು - ಹೇ ಕೃಷ್ಣ ! ಯುದ್ಧಕ್ಷೇತದಲ್ಲಿ ಸಿದ್ಧರಾಗಿ ನಿಂತಿರುವ ಯುದ್ಧಾಭಿಲಾಷಿಗಳಾದ ಈ ಸ್ವಜನ ಸಮುದಾಯವನ್ನು ನೋಡಿ ನನ್ನ ಅಂಗಾಂಗಗಳು ಶಿಥಿಲವಾಗಿ ಹೋಗುತ್ತಿವೆ. ಮುಖವು ಒಣಗಿ ಹೋಗುತ್ತಿದೆ ಹಾಗೂ ನನ್ನ ಶರೀರದಲ್ಲಿ ಕಂಪನ ಮತ್ತು ರೋಮಾಂಚನವಾಗುತ್ತಿದೆ. (1.28-1.29)",

      "ಕೈಯಿಂದ ಗಾಂಡೀವ ಧನಸ್ಸು ಜಾರಿ ಬೀಳುತ್ತಿದೆ ಮತ್ತು ಚರ್ಮವು ಸಹ ಬಹಳವಾಗಿ ಉರಿಯುತ್ತಿದೆ ಹಾಗೂ ನನ್ನ ಮನಸ್ಸು ಭ್ರಮಿಸಿದಂತೆ ತೋರುತ್ತಿದೆ. ಆದ್ದರಿಂದ ನಾನು ನಿಂತುಕೊಂಡಿರಲೂ ಸಹ ಸಮರ್ಥನಾಗಿಲ್ಲ. 1.30",

      "ಹೇ ಕೇಶವ ! ನಾನು ಲಕ್ಷಣಗಳನ್ನು ಸಹ ವಿಪರೀತವಾಗಿಯೇ ನೋಡುತ್ತಿದ್ದೇನೆ. ಹಾಗೂ ಯುದ್ಧದಲ್ಲಿ ಸ್ವಜನ ಸಮುದಾಯವನ್ನು ಕೊಂದು ಯಾವ ಶ್ರೇಯಸ್ಸನ್ನೂ ನೋಡುವುದಿಲ್ಲ. 1.31",

      "ಹೇ ಕೃಷ್ಣನೇ ! ನಾನಾದರೋ ವಿಜಯವನ್ನಾಗಲೀ, ರಾಜ್ಯವನ್ನಾಗಲೀ ಹಾಗೂ ಸುಖವನ್ನಾಗಲೀ ಇಚ್ಛಿಸುವುದಿಲ್ಲ. ಹೇ ಗೋವಿಂದನೇ ! ನಮಗೆ ಇಂತಹ ರಾಜ್ಯದಿಂದ ಯಾವ ಪ್ರಯೋಜನವಿದೆ ? ಅಥವಾ ಇಂತಹ ಭೋಗಗಳಿಂದಾಗಲೀ ಜೀವನದಿಂದಾಗಲೀ ಯಾವ ಲಾಭವಿದೆ ? 1.32",

      "ನಾವು ಯಾರಿಗಾಗಿ ರಾಜ್ಯವನ್ನು, ಭೋಗಗಳನ್ನು ಮತ್ತು ಸುಖಾದಿಗಳನ್ನು ಇಚ್ಛಿಸುತ್ತೇವೆಯೋ, ಅಂತಹವರೇ ಆದ ಇವರೆಲ್ಲರೂ ಧನ ಮತ್ತು ಜೀವನದ ಆಸೆಯನ್ನು ತ್ಯಜಿಸಿ ಯುದ್ಧಕ್ಕಾಗಿ ನಿಂತಿದ್ದಾರೆ. 1.33",

      "ಗುರುಗಳೂ, ದೊಡ್ಡಪ್ಪ-ಚಿಕ್ಕಪ್ಪಂದಿರೂ, ಪುತ್ರರು ಮತ್ತು ಅದೇ ಪ್ರಕಾರವಾಗಿ ಅಜ್ಜಂದಿರು, ಸೋದರ ಮಾವಂದಿರೂ, ಮಾವಂದಿರೂ ಪೌತ್ರರೂ, ಭಾವಮೈದುನರೂ ಹಾಗೆಯೇ ಇನ್ನು ಅನೇಕ ಸಂಬಂಧಿಗಳೂ ಇದ್ದಾರೆ. 1.34",

      "ಹೇ ಮಧುಸೂದನ ! ನನ್ನನ್ನು ಕೊಂದರೂ ಅಥವಾ ಮೂರು ಲೋಕಗಳ ರಾಜ್ಯ ದೊರಕಿದರೂ ಸಹ ನಾನು ಇವರೆಲ್ಲರನ್ನು ಕೊಲ್ಲಲು ಇಚ್ಛಿಸುವುದಿಲ್ಲ. ಮತ್ತೆ ಭೂಮಂಡಲದ ರಾಜ್ಯಕ್ಕಾಗಿಯಾದರೋ ಹೇಳುವುದೇನಿದೆ ? 1.35",

      "ಹೇ ಜನಾರ್ದನ ! ಧೃತರಾಷ್ಟ್ರನ ಪುತ್ರರನ್ನು ಕೊಲ್ಲುವುದರಿಂದ ನಮಗೆ ಯಾವ ಸಂತೋಷವುಂಟಾಗುವುದು ? ಈ ಆತತಾಯಿಗಳನ್ನು ಕೊಲ್ಲುವುದರಿಂದಲಾದರೋ ನಮಗೆ ಪಾಪವೇ ತಟ್ಟುವುದು. 1.36",

      "ಆದುದರಿಂದ ಹೇ ಮಾಧವನೇ ! ನಮ್ಮ ಬಾಂಧವರೇ ಆದ ಧೃತರಾಷ್ಟ್ರನ ಮಕ್ಕಳನ್ನು ಕೊಲ್ಲುವುದು ನಮಗೆ ಯೋಗ್ಯವಲ್ಲ; ಏಕೆಂದರೆ ನಮ್ಮದೇ ಆದ ಕುಟುಂಬವನ್ನು ಕೊಂದು ನಾವು ಹೇಗೆ ಸುಖಿಗಳಾಗುವೆವು ? 1.37",

      "ಒಂದು ವೇಳೆ ಲೋಭದಿಂದ ಭ್ರಷ್ಟಚಿತ್ತರಾದ ಈ ಜನರು ಕುಲಕ್ಷಯದಿಂದ ಉತ್ಪನ್ನವಾಗುವ ದೋಷವನ್ನು ಮತ್ತು ಮಿತ್ರದ್ರೋಹಮಾಡುವುದರಲ್ಲಿ ಪಾಪವನ್ನು ನೋಡುವುದಿಲ್ಲವಾದರೂ, ಹೇ ಜನಾರ್ದನನೇ ! ಕುಲದ ನಾಶದಿಂದ ಉತ್ಪನ್ನವಾಗುವ ದೋಷವನ್ನು ತಿಳಿದಿರುವ ನಾವುಗಳು ಈ ಪಾಪದಿಂದ ಹಿಂಜರಿಯಲು ಏಕೆ ವಿಚಾರಮಾಡಬಾರದು (1.38-1.39)",

      "ಒಂದು ವೇಳೆ ಲೋಭದಿಂದ ಭ್ರಷ್ಟಚಿತ್ತರಾದ ಈ ಜನರು ಕುಲಕ್ಷಯದಿಂದ ಉತ್ಪನ್ನವಾಗುವ ದೋಷವನ್ನು ಮತ್ತು ಮಿತ್ರದ್ರೋಹಮಾಡುವುದರಲ್ಲಿ ಪಾಪವನ್ನು ನೋಡುವುದಿಲ್ಲವಾದರೂ, ಹೇ ಜನಾರ್ದನನೇ ! ಕುಲದ ನಾಶದಿಂದ ಉತ್ಪನ್ನವಾಗುವ ದೋಷವನ್ನು ತಿಳಿದಿರುವ ನಾವುಗಳು ಈ ಪಾಪದಿಂದ ಹಿಂಜರಿಯಲು ಏಕೆ ವಿಚಾರಮಾಡಬಾರದು (1.38-1.39)",

      "ಕುಲದ ನಾಶದಿಂದ ಸನಾತನವಾದ ಕುಲಧರ್ಮಗಳು ನಷ್ಟವಾಗುತ್ತವೆ, ಧರ್ಮವು ನಾಶವಾದನಂತರ ಸಂಪೂರ್ಣ ಕುಲದಲ್ಲಿ ಪಾಪವು ಕೂಡ ಬಹಳವಾಗಿ ಆವರಿಸಿಕೊಳ್ಳುತ್ತದೆ. 1.40",

      "ಹೇ ಕೃಷ್ಣ ! ಪಾಪವು ಅಧಿಕವಾಗಿ ಬೆಳೆಯುವುದರಿಂದ ಕುಲದ ಸ್ತ್ರೀಯರು ಅತ್ಯಂತ ದೂಷಿತರಾಗಿ ಬಿಡುತ್ತಾರೆ ಮತ್ತು ಹೇ ವಾರ್ಷ್ಣೇಯನೇ ! ಸ್ತ್ರೀಯರು ದೂಷಿತರಾದ ಬಳಿಕ ವರ್ಣಸಾಂಕರ್ಯವು ಉಂಟಾಗುತ್ತದೆ. 1.41",

      "ವರ್ಣಸಾಂಕರ್ಯವು ಕುಲಘಾತುಕರನ್ನೂ ಮತ್ತು ಕುಲವನ್ನೂ ನರಕಕ್ಕೆ ಕೊಂಡೊಯ್ಯಲೆಂದೇ ಆಗುತ್ತದೆ. ಲುಪ್ತವಾದ ಪಿಂಡ ಮತ್ತು ತರ್ಪಣಾದಿಗಳಿಂದ ವಂಚಿತರಾದ ಇವರ ಪಿತೃಗಳು ಕೂಡ ಅಧೋಗತಿಯನ್ನು ಪಡೆಯುತ್ತಾರೆ. 1.42",

      "ಈ ವರ್ಣಸಂಕರಕಾರಕವಾದ ದೋಷಗಳಿಂದ ಕುಲಘಾತುಕರ ಸನಾತನವಾದ ಕುಲಧರ್ಮಗಳು ಮತ್ತು ಜಾತಿ ಧರ್ಮಗಳು ನಾಶವಾಗಿ ಬಿಡುತ್ತವೆ. 1.43",

      "ಹೇ ಜನಾರ್ದನನೇ ! ಯಾರ ಕುಲಧರ್ಮಗಳು ನಾಶವಾಗಿವೆಯೋ ಅಂತಹ ಮನುಷ್ಯರು ಅನಿಶ್ಚಿತಕಾಲದವರೆಗೆ ನರಕದಲ್ಲಿ ವಾಸಿಸುತ್ತಾರೆಂದು ನಾವು ಕೇಳುತ್ತ ಬಂದಿದ್ದೇವೆ. 1.44",

      "ಅಯ್ಯೋ ! ಕಷ್ಟವೇ ! ಬುದ್ಧಿವಂತರಾಗಿದ್ದೂ ಸಹ ನಾವುಗಳು ಮಹತ್ತಾದ ಪಾಪವನ್ನು ಮಾಡಲು ಸಿದ್ಧರಾಗಿದ್ದೇವಲ್ಲ! ಆದರೆ ರಾಜ್ಯ ಮತ್ತು ಸುಖದ ಲೋಭದಿಂದ ಸ್ವಜನರನ್ನು ಕೊಲ್ಲುವುದಕ್ಕೆ ತೊಡಗಿದ್ದೇವಲ್ಲ. 1.45",

      "ಒಂದು ವೇಳೆ ಶಸ್ತ್ರರಹಿತನೂ ಹಾಗೂ ಎದುರಿಸದೇ ಇರುವ ನನ್ನನ್ನು ಶಸ್ತ್ರಗಳನ್ನು ಕೈಯಲ್ಲಿ ಹಿಡಿದಿರುವ ಧೃತರಾಷ್ಟ್ರನ ಪುತ್ರರು ಯುದ್ಧದಲ್ಲಿ ಕೊಂದುಹಾಕಿದರೂ ಆ ಕೊಲ್ಲುವಿಕೆಯೂ ಸಹ ನನಗೆ ಹೆಚ್ಚು ಶ್ರೇಯಸ್ಕರವಾದೀತು. 1.46",

      "ಸಂಜಯನು ಹೇಳಿದನು -  ರಣಭೂಮಿಯಲ್ಲಿ ಶೋಕದಿಂದ ಉದ್ವಿಗ್ನ ಮನಸ್ಸುಳ್ಳವನಾದ ಅರ್ಜುನನು ಈ ಪ್ರಕಾರವಾಗಿ ಹೇಳಿ ಬಾಣಸಹಿತ ಧನುಸ್ಸನ್ನು ತ್ಯಾಗಮಾಡಿ ರಥದ ಹಿಂಭಾಗದಲ್ಲಿ ಕುಳಿತುಬಿಟ್ಟನು. 1.47",

      "ಇತಿ ಶ್ರೀಮದ್ಭಗವದ್ಗೀತಾರೂಪೀ ಉಪನಿಷತ್ ಮತ್ತು ಬ್ರಹ್ಮವಿದ್ಯಾ ಹಾಗು ಯಾಂ ಯೋಗಶಾಸ್ತ್ರ ವಿಷಯವಾಗಿ ಶ್ರೀ ಕೃಷ್ಣ ಮತ್ತು ಅರ್ಜುನ ಇವರ ಸಂವಾದದಲ್ಲಿ ಅರ್ಜುನವಿಷಾದಯೋಗ ಎಂಬ ಮೊದಲನೇ ಅಧ್ಯಾಯ ಸಂಪೂರ್ಣ"
    ],
    "adhyaya-2" : [
      "ಸಂಜಯನು ಹೇಳಿದನು - ಆ  ಪ್ರಕಾರವಾಗಿ ಕರುಣೆಯಿಂದ ವ್ಯಾಪ್ತನಾದ ಮತ್ತು ಕಂಬನಿ ತುಂಬಿದ ಹಾಗೂ ವ್ಯಾಕುಲ ಕಣ್ಣುಗಳುಳ್ಳವನಾದ, ಶೋಕಯುಕ್ತನಾದ ಆ ಅರ್ಜುನನನ್ನು ಕುರಿತು ಭಗವಾನ್ ಮಧುಸೂದನನು ಈ ಮಾತನ್ನು ಹೇಳಿದನು. 2.1",

      "ಭಗವಂತ ಹೇಳಿದನು - ಹೇ ಅರ್ಜುನ ! ನಿನಗೆ ಈ ಅಸಮಯದಲ್ಲಿ ಅರ್ಥಾತ್ - ಯುದ್ಧಾರಂಭದ ಸಮಯದಲ್ಲಿ ಈ ಪ್ರಕಾರದ ಮೋಹವು ಯಾವ ಕಾರಣದಿಂದ ಪ್ರಾಪ್ತವಾಯಿತು ? ಏಕೆಂದರೆ ಇದು ಶ್ರೇಷ್ಠ ಪುರುಷರಿಂದ ಆಚರಿಸಲ್ಪಡುವುದಲ್ಲ. ಸ್ವರ್ಗವನ್ನು ಕೊಡುವಂತಹುದೂ ಅಲ್ಲ ಮತ್ತು ಕೀರ್ತಿಯನ್ನುಂಟುಮಾಡುವುದೂ ಅಲ್ಲ. 2.2",

      "ಹೇ ಪಾರ್ಥ ! ನಪುಂಸಕತ್ವವನ್ನು ಹೊಂದಬೇಡ. ನಿನಗೆ ಇದು ಉಚಿತವಲ್ಲ ಹೇ ಪರಂತಪ ! ಹೃದಯದ ತುಚ್ಛವಾದ ಈ ದೌರ್ಬಲ್ಯವನ್ನು ತ್ಯಜಿಸಿ ಯುದ್ಧಕ್ಕಾಗಿ ಎದ್ದು ನಿಲ್ಲು. 2.3",

      "ಅರ್ಜುನನು ಹೇಳಿದನು - ಹೇ ಮಧುಸೂದನ ! ನಾನು ರಣಭೂಮಿಯಲ್ಲಿ ಬಾಣಗಳಿಂದ ಭೀಷ್ಮಪಿತಾಮಹರ ಮತ್ತು ದ್ರೋಣಾಚಾರ್ಯರ ವಿರುದ್ಧ ಯಾವ ಪ್ರಕಾರ ಯುದ್ಧ ಮಾಡಲಿ ? ಏಕೆಂದರೆ, ಹೇ ಅರಿಸೂದನನೇ ! ಅವರಿಬ್ಬರೂ ಪೂಜನೀಯರಾಗಿದ್ದಾರೆ. 2.4",

      "ಅದಕ್ಕಾಗಿ ಈ ಮಹಾನುಭಾವರಾದ ಗುರುಜನರನ್ನು ಕೊಲ್ಲದೆ, ನಾನು ಈ ಲೋಕದಲ್ಲಿ ಭಿಕ್ಷೆಯ ಅನ್ನವನ್ನು ತಿನ್ನುವುದು ಶ್ರೇಯಸ್ಕರವೆಂದು ಭಾವಿಸುತ್ತೇನೆ. ಏಕೆಂದರೆ ಗುರುಜನರನ್ನು ಕೊಲ್ಲುವುದರಿಂದಲಾದರೋ ಈ ಲೋಕದಲ್ಲಿ ರಕ್ತದಿಂದ ತೊಯ್ದಿರುವ ಅರ್ಥ ಮತ್ತು ಕಾಮರೂಪೀ ಭೋಗಗಳನ್ನೇ ಭೋಗಿಸುವೆನು. 2.5",

      "ನಮಗಾಗಿ ಯುದ್ಧಮಾಡುವುದು ಅಥವಾ ಮಾಡದಿರುವುದು, ಇವೆರಡರಲ್ಲಿ ಯಾವುದು ಶ್ರೇಷ್ಠವೆಂಬುದನ್ನು ನಾವು ಅರಿಯೆವು;  ಅಥವಾ ಅವರನ್ನು ನಾವು ಗೆಲ್ಲುವೆವೋ, ನಮ್ಮನ್ನು ಅವರು ಗೆಲ್ಲುವರೋ ಎಂಬುದನ್ನೂ ಅರಿಯೆವು. ಮತ್ತು ಯಾರನ್ನು ಕೊಂದು ನಾವು ಜೀವಿಸಿರಲು ಇಷ್ಟಪಡುವುದಿಲ್ಲವೋ ಅವರೇ ನಮ್ಮ ಆತ್ಮೀಯರಾದ ಧೃತರಾಷ್ಟ್ರನ ಪುತ್ರರು ನಮ್ಮ ಎದುರು ಪ್ರತಿಸ್ಪರ್ಧಿಗಳಾಗಿ ನಿಂತಿದ್ದಾರೆ. 2.6",

      "ಆದ್ದರಿಂದ ಹೇಡಿತನರೂಪೀ ದೋಷದಿಂದ ನಷ್ಟವಾಗಿರುವ ಸ್ವಭಾವವುಳ್ಳವನೂ ಹಾಗೂ ಧರ್ಮದ ವಿಷಯದಲ್ಲಿ ಮೋಹಿತ ಚಿತ್ತನಾಗಿರುವ ನಾನು ನಿನ್ನಲ್ಲಿ ಪ್ರಾರ್ಥಿಸುವುದೇನೆಂದರೆ - ಯಾವ ಸಾಧನವು ನಿಶ್ಚಯವಾಗಿ ಶ್ರೇಯಸ್ಕರವಾಗಿದೆಯೋ ಅದನ್ನು ನನಗಾಗಿ ಹೇಳು. ನಾನು ನಿನ್ನ ಶಿಷ್ಯನಾಗಿದ್ದೇನೆ. ಆದುದರಿಂದ, ನಿನ್ನಲ್ಲಿ ಶರಣಾಗಿರುವ ನನಗೆ ಉಪದೇಶವನ್ನು ಮಾಡು. 2.7",

      "ಏಕೆಂದರೆ ಭೂಮಿಯಲ್ಲಿ  ನಿಷ್ಕಂಟಕವಾದ, ಧನಧಾನ್ಯ ಸಮೃದ್ಧವಾದ ರಾಜ್ಯವನ್ನು ಮತ್ತು ದೇವತೆಗಳ ಒಡೆತನವನ್ನು ಪಡೆದರೂ ಸಹ ನನ್ನ ಇಂದ್ರಿಯಗಳನ್ನು ಸೊರಗಿಸುತ್ತಿರುವ ಶೋಕವನ್ನು ದೂರ ಮಾಡಬಲ್ಲ ಯಾವ ಉಪಾಯವು ನನಗೆ ಕಾಣುವುದಿಲ್ಲ. 2.8",

      "ಸಂಜಯನು ಹೇಳಿದನು - ಹೇ ರಾಜಾ ! ನಿದ್ರೆಯನ್ನು ಗೆದ್ದ ಅರ್ಜುನನು ಅಂತರ್ಯಾಮಿಯಾದ ಭಗವಾನ್ ಶ್ರೀಕೃಷ್ಣನಲ್ಲಿ ಈ ಪ್ರಕಾರವಾಗಿ ಹೇಳಿಕೊಂಡು ಭಗವಾಗ್ ಶ್ರೀ ಗೋವಿಂದನಲ್ಲಿ ಯುದ್ಧ ಮಾಡುವುದಿಲ್ಲ ಎಂದು ಸ್ಪಷ್ಟವಾಗಿ ಹೇಳಿ ಸುಮ್ಮನಾದನು. 2.9",

      "ಹೇ ಭರತವಂಶೀ ಧೃತರಾಷ್ಟ್ರ ! ಅಂತರ್ಯಾಮಿಯಾದ ಭಗವಾನ್ ಶ್ರೀಕೃಷ್ಣನು ಎರಡು ಸೈನ್ಯಗಳ ಮಧ್ಯದಲ್ಲಿ ಶೋಕಿಸುತ್ತಿರುವ ಆ ಅರ್ಜುನನಿಗೆ ನಗುತ್ತಿರುವವನಂತೆ ಈ ಮಾತುಗಳನ್ನು ಹೇಳಿದನು. 2.10",

      "ಶ್ರೀ ಭಗವಂತನು ಹೇಳಿದನು - ಹೇ ಅರ್ಜುನ ! ನೀನು ಯಾರಿಗಾಗಿ ಶೋಕಿದಬೇಕಾಗಿಲ್ಲವೋ ಅವರಿಗಾಗಿ ಶೋಕಿಸುತ್ತಿರುವೆ. ಹಾಗೂ ಪಂಡಿತರಂತೆ ಮಾತುಗಳನ್ನಾಡುತ್ತಿರುವೆ, ಆದರೆ ಯಾರ ಪ್ರಾಣಗಳು ಹೋಗಿರುವವೋ, ಅವರಿಗಾಗಿ ಮತ್ತು ಯಾರ ಪ್ರಾಣಗಳು ಹೋಗಿಲ್ಲವೋ ಅವರಿಗಾಗಿಯೂ ಪಂಡಿತರಾದವರು ಶೋಕಿಸುವುದಿಲ್ಲ. 2.11",

      "ನಾನು ಯಾವ ಕಾಲದಲ್ಲಿಯೂ ಇರಲಿಲ್ಲವೆಂಬುದಿಲ್ಲ ( ಅಂದರೆ ಯಾವಾಗಲೂ ಇದ್ದೆ ). ಹಾಗೆಯೇ ನೀನಾಗಲೀ, ಈ ರಾಜರುಗಳಾಗಲೀ ಇರಲಿಲ್ಲವೆಂಬುದಿಲ್ಲ ( ಅಂದರೆ ಇದ್ದರು ). ಹಾಗೆಯೆ ಇನ್ನು ಮುಂದೆಯೂ ನಾವೆಲ್ಲರೂ ಇರುವುದಿಲ್ಲವೆಂಬುದೇ ಇಲ್ಲ ( ಅಂದರೆ ಇರುತ್ತೇವೆ. ) 2.12",

      "ಜೀವಾತ್ಮನಿಗೆ ಈ ದೇಹದಲ್ಲಿ ಬಾಲ್ಯ, ಯೌವನ ಮತ್ತು ವೃದ್ಧಾವಸ್ಥೆಗಳುಂಟಾಗುತ್ತವೆಯೋ ಅಂತೆಯೇ ಬೇರೆ ಶರೀರವು ಪ್ರಾಪ್ತವಾಗುತ್ತದೆ. ಆ ವಿಷಯದಲ್ಲಿ ಧೀರ ಪುರುಷನು ಮೋಹಿತನಾಗುವುದಿಲ್ಲ. 2.13",

      "ಹೇ ಕುಂತೀಪುತ್ರನೇ ! ಶೀತೋಷ್ಣಗಳನ್ನೂ ಮತ್ತು ಸುಖ-ದುಃಖಗಳನ್ನೂ ಕೊಡುವಂತಹ ಇಂದ್ರಿಯ ಮತ್ತು ವಿಷಯಗಳ ಸಂಯೋಗವಾದರೋ ಉತ್ಪತ್ತಿ - ವಿನಾಶಶೀಲ ಮತ್ತು ಅನಿತ್ಯವಾಗಿದೆ; ಆದ್ದರಿಂದ ಎಲೈ ಅರ್ಜುನ ! ಅವುಗಳನ್ನು ನೀನು ಸಹಿಸಿಕೋ. 2.14",

      "ಏಕೆಂದರೆ, ಹೇ ಪುರುಷಶ್ರೇಷ್ಠನೇ ! ಸುಖದುಃಖಗಳನ್ನು ಸಮಾನವಾಗಿ ತಿಳಿಯುವ ಯಾವ ಧೀರ ಪುರುಷನನ್ನು ಈ ಇಂದ್ರಿಯ ಮತ್ತು ವಿಷಯಗಳ ಸಂಯೋಗವು ವ್ಯಾಕುಲಗೊಳಿಸುವುದಿಲ್ಲವೋ ಅವನು ಮೋಕ್ಷಕ್ಕೆ ಯೋಗ್ಯನಾಗುತ್ತಾನೆ. 2.15",

      "ಅಸದ್ವಸ್ತುವಿಗಾದರೋ ಇರುವಿಕೆಯು ಇರುವುದಿಲ್ಲ ಮತ್ತು ಸದ್ವಸ್ತುವಿಗೆ ಅಭಾವವೆಂಬುದಿಲ್ಲ ಈ ಪ್ರಕಾರವಾಗಿ ಇವೆರಡರ ತತ್ವವೂ ತತ್ವಜ್ಞಾನಿಗಳಾದ ಪುರುಷರ ಮೂಲಕ ನೋಡಲ್ಫಟ್ಟಿದೆ. 2.16",

      "ಯಾವುದರಿಂದ ಈ ಸಂಪೂರ್ಣ ದೃಶ್ಯ ಜಗತ್ತು ವ್ಯಾಪ್ತವಾಗಿದೆಯೋ ಅದನ್ನು ನೀನು ನಾಶರಹಿತವಾದುದೆಂದು ತಿಳಿ. ಈ ಅವಿನಾಶಿಯ ವಿನಾಶವನ್ನು ಮಾಡಲು ಯಾರೂ ಕೂಡ ಸಮರ್ಥರಲ್ಲ. 2.17",

      "ಈ ನಾಶರಹಿತನೂ, ಅಪ್ರಮೇಯನೂ, ನಿತ್ಯ ಸ್ವರೂಪನೂ ಆಗಿರುವ ಜೀವಾತ್ಮನ ಈ ಎಲ್ಲ ಶರೀರಗಳು ನಾಶವುಳ್ಳವುಗಳೆಂದು ಹೇಳಲಾಗಿದೆ. ಅದಕ್ಕಾಗಿ ಹೇ ಭರತವಂಶೀ ಅರ್ಜುನನೇ ! ನೀನು ಯುದ್ಧಮಾಡು 2.18",

      "ಯಾರು ಈ ಆತ್ಮವನ್ನು ಕೊಲ್ಲುವವನೆಂದು ತಿಳಿಯುತ್ತಾನೋ, ಹಾಗೂ ಯಾರು ಇವನು ಸತ್ತವನೆಂದು ತಿಳಿಯುತ್ತಾನೋ - ಅವರಿಬ್ಬರೂ ತಿಳಿದವರಲ್ಲ, ಏಕೆಂದರೆ ಈ ಆತ್ಮನು ವಾಸ್ತವವಾಗಿ ಯಾರನ್ನೂ ಕೊಲ್ಲುವುದೂ ಇಲ್ಲ ಮತ್ತು ಯಾರಿಂದಲೂ ಕೊಲ್ಲಲ್ಪಡುವುದೂ ಇಲ್ಲ. 2.19",

      "ಈ ಆತ್ಮನು ಯಾವುದೇ ಕಾಲದಲ್ಲಿಯೂ ಹುಟ್ಟುವುದಿಲ್ಲ, ಸಾಯುವುದೂ ಇಲ್ಲ ಹಾಗೂ ಉತ್ಪತ್ತಿಯಾಗಿ ಮತ್ತೆ ಇರುತ್ತಾನೆಂಬುದೂ ಇಲ್ಲ; ಏಕೆಂದರೆ ಇವನು ಜನ್ಮರಹಿತನೂ ನಿತ್ಯನೂ, ಸನಾತನನೂ ಮತ್ತು ಪುರಾತನನೂ ಆಗಿದ್ದಾನೆ, ಶರೀರವು ಕೊಲ್ಲಲ್ಪಟ್ಟರೂ ಕೂಡ ಇವನು ಕೊಲ್ಲಲ್ಪಡುವುದಿಲ್ಲ. 2.20",

      "ಹೇ ಪೃಥಾಪುತ್ರನಾದ ಅರ್ಜುನನೇ ! ಯಾವ ಪುರುಷನು ಈ ಆತ್ಮವನ್ನು ನಾಶರಹಿತನೂ, ನಿತ್ಯನೂ ಅಜನ್ಮನೂ ಮತ್ತು ಅವ್ಯಯನೆಂದೂ ತಿಳಿಯುತ್ತಾನೆಯೋ, ಆ ಪುರುಷನು ಯಾರನ್ನು ಹೇಗೆ ಕೊಲ್ಲಿಸುತ್ತಾನೆ ಮತ್ತು ಯಾರನ್ನು ಹೇಗೆ ಕೊಲ್ಲುತ್ತಾನೆ ? 2.21",

      "ಹೇಗೆ ಮನುಷ್ಯನು ಹಳೆಯದಾದ ವಸ್ತ್ರಗಳನ್ನು ತ್ಯಾಗ ಮಾಡಿ ಬೇರೆಯ, ಹೊಸದಾದ ವಸ್ತ್ರಗಳನ್ನು ಧರಿಸುತ್ತಾನೋ, ಹಾಗೆಯೇ ಜೀವಾತ್ಮನು ಹಳೆಯದಾದ ಶರೀರಗಳನ್ನು ತ್ಯಾಗಮಾಡಿ ಬೇರೆಯಾದ ಹೊಸ ಶರೀರಗಳನ್ನು ಪಡೆಯುತ್ತಾನೆ. 2.22",

      "ಈ ಆತ್ಮವನ್ನು ಶಸ್ತ್ರಗಳು ಕತ್ತರಿಸಲಾರವು, ಇದನ್ನು ಬೆಂಕಿಯು ಸುಡಲಾರದು, ಇದನ್ನು ಜಲವು ನೆನೆಯಿಸಲಾರದು ಮತ್ತು ವಾಯುವು ಒಣಗಿಸಲಾರದು. 2.23",

      "ಏಕೆಂದರೆ ಈ ಆತ್ಮವು ಅಚ್ಛೇದ್ಯವೂ, ಅದಾಹ್ಯವೂ, ಅಕ್ಲೇದ್ಯವೂ ಮತ್ತು ನಿಸ್ಸಂದೇಹವಾಗಿ ಅಶೋಷ್ಯವಾಗಿದೆ. ಹಾಗೂ ಈ ಆತ್ಮವು ನಿತ್ಯವೂ, ಸರ್ವವ್ಯಾಪಿಯೂ, ಅಚಲವೂ ಸ್ಥಿರವಾಗಿದ್ದು ಸನಾತನವಾಗಿದೆ. 2.24",

      "ಈ ಆತ್ಮವು ಅವ್ಯಕ್ತವೂ, ಅಚಿಂತ್ಯವೂ, ಮತ್ತು ವಿಕಾರ ರಹಿತವೂ ಎಂದು ಹೇಳಲಾಗುತ್ತದೆ. ಆದ್ದರಿಂದ ಹೇ ಅರ್ಜುನಾ ! ಈ ಆತ್ಮವನ್ನು ಮೇಲೆ ಹೇಳಿದ ಪ್ರಕಾರವಾಗಿ ತಿಳಿದುಕೊಂಡು ನೀನು ಶೋಕಿಸಲು ಯೋಗ್ಯನಲ್ಲ ಅರ್ಥಾತ್ ನಿನಗೆ ಶೋಕಿಸುವುದು ಉಚಿತವಾದುದಲ್ಲ. 2.25",

      "ಆದರೂ ಒಂದು ವೇಳೆ ನೀನು ಈ ಆತ್ಮವನ್ನು ಸದಾ ಹುಟ್ಟುವವನು ಹಾಗೂ ಸದಾ ಸಾಯುವವನೂ ಎಂದು ತಿಳಿದಿದ್ದರೂ ಕೂಡ ಹೇ ಮಹಾಬಾಹುವೇ ! ನೀನು ಈ ಪ್ರಕಾರವಾಗಿ ಶೋಕಿಸುವುದಕ್ಕೆ ಯೋಗ್ಯನಲ್ಲ. 2.26",

      "ಏಕೆಂದರೆ ಈ ಅಭಿಪ್ರಾಯಕ್ಕನುಸಾರವಾಗಿ ಹುಟ್ಟಿದವನ ಮರಣವು ನಿಶ್ಚಿತವಾಗಿದೆ ಮತ್ತು ಸತ್ತವನ ಹುಟ್ಟು ನಿಶ್ಚಿತವಾಗಿದೆ. ಆದ್ದರಿಂದಲೂ ಕೂಡ ಈ ಪರಿಹಾರೋಪಾಯವಿಲ್ಲದ ವಿಷಯದಲಿ ನೀನು ಶೋಕಪಡಲು ಯೋಗ್ಯನಲ್ಲ. 2.27",

      "ಹೇ ಅರ್ಜುನನೇ ! ಸಂಪೂರ್ಣ ಪ್ರಾಣಿಗಳು ಹುಟ್ಟುವ ಮೊದಲು ಅಪ್ರಕಟವಾಗಿದ್ದುವು ಮತ್ತು ಸತ್ತನಂತರವೂ ಅಪ್ರಕಟವಾಗುತ್ತವೆ, ಕೇವಲ ಮಧ್ಯದಲ್ಲಿ ಮಾತ್ರ ಪ್ರಕಟವಾಗಿವೆ, ಮತ್ತೆ ಇಂತಹ ಸ್ಥಿತಿಯಲ್ಲಿ ಏಕೆ ಶೋಕಿಸಬೇಕು ? 2.28",

      "ಯಾರೋ ಒಬ್ಬ ಮಹಾಪುರುಷನು ಈ ಆತ್ಮವನ್ನು ಆಶ್ಚರ್ಯದಂತೆ ನೋಡುತ್ತಾನೆ ಮತ್ತು ಹಾಗೆಯೇ ಬೇರೆ ಯಾರೋ ಮಹಾಪುರುಷನೇ ಇದರ ತತ್ವವನ್ನು ಆಶ್ಚರ್ಯದಂತೆ ವರ್ಣಿಸುತ್ತಾನೆ, ಬೇರೆ ಯಾರೋ ಅಧಿಕಾರಿ ಪುರುಷನು ಇದನ್ನು ಆಶ್ಚರ್ಯದಂತೆ ಕೇಳುತ್ತಾನೆ ಮತ್ತು ಕೆಲವರಾದರೋ ಕೇಳಿಯೂ ಕೂಡ ಇದನ್ನು ಅರಿಯಲಾರರು. 2.29",

      "ಹೇ ಅರ್ಜುನನೇ ! ಈ ಆತ್ಮವು ಎಲ್ಲರ ಶರೀರಗಳಲ್ಲಿ ಸದಾ ಅವಧ್ಯವಾಗಿದೆ. ಈ ಕಾರಣದಿಂದ ಸಂಪೂರ್ಣ ಪ್ರಾಣಿಗಳಿಗಾಗಿ ನೀನು ಶೋಕಿಸುವುದು ಸರಿಯಲ್ಲ. 2.30",

      "ಹಾಗೆಯೇ ಸ್ವಧರ್ಮವನ್ನು ನೋಡಿದರೂ ನೀನು ಭಯಪಡಲು ಯೋಗ್ಯನಲ್ಲ ಅಂದರೆ ನೀನು ಭಯಪಡಬಾರದು ಏಕೆಂದರೆ ಕ್ಷತ್ರಿಯನಾದವನಿಗೆ ಧರ್ಮಯುಕ್ತವಾದ ಯುದ್ಧದಿಂದ ಮಿಗಿಲಾದ ಶ್ರೇಯಸ್ಕರವಾದ ಕರ್ತ್ಯವ್ಯವು ಬೇರೆ ಯಾವುದೂ ಇಲ್ಲ 2.31",

      "ಹೇ ಪಾರ್ಥನೇ ! ತನ್ನಿಂದ ತಾನೇ ಪ್ರಾಪ್ತವಾದ ಮತ್ತು ತೆರೆದಿರುವ ಸ್ವರ್ಗದ ದ್ವಾರದಂತಿರುವ ಈ ಪ್ರಕಾರದ ಯುದ್ಧವನ್ನು ಭಾಗ್ಯಶಾಲಿಯಾದ ಕ್ಷತ್ರಿಯರೇ ಪಡೆಯುತ್ತಾರೆ. 2.32",

      "ಏಕೆಂದರೆ,  ಒಂದು ವೇಳೆ ನೀನು ಈ ಧರ್ಮ ಯುಕ್ತವಾದ ಯುದ್ಧವನ್ನು ಮಾಡದಿದ್ದರೆ ಸ್ವಧರ್ಮವನ್ನು ಮತ್ತು ಕೀರ್ತಿಯನ್ನು ಕಳೆದುಕೊಂಡು ಪಾಪವನ್ನು ಪಡೆಯುವೆ. 2.33",

      "ಹಾಗೂ ಬಹುಕಾಲದವರೆಗೆ ಇರುವ ನಿನ್ನ ಅಪಕೀರ್ತಿಯನ್ನು ಎಲ್ಲ ಜನರೂ ಕೂಡ ಆಡಿಕೊಳ್ಳುವರು ಮತ್ತು ಮರ್ಯಾದಸ್ಥನಿಗೆ ಈ ಅಪಕೀರ್ತಿಯು ಮರಣಕ್ಕಿಂತಲೂ ಹೆಚ್ಚಿನದಾಗಿದೆ. 2.34",

      "ಮತ್ತು ಯಾರ ದೃಷ್ಟಿಯಲ್ಲಿ ನೀನು ಮೊದಲು ಬಹಳ ಸಮ್ಮಾನಿತನಾಗಿದ್ದೆಯೋ ಅವರು ನಿನ್ನನ್ನು ತುಚ್ಛವಾಗಿ ಕಾಣುವರು; ಅಷ್ಟೇ ಅಲ್ಲ ಆ ಮಹಾರಥರು ನಿನ್ನನ್ನು ಭಯದ ಕಾರಣದಿಂದಾಗಿ ಯುದ್ಧದಿಂದ ಹಿಮ್ಮೆಟ್ಟಿದವನೆಂದು ತಿಳಿಯುವರು. 2.35",

      "ನಿನ್ನ ವೈರಿಗಳು ನಿನ್ನ ಸಾಮರ್ಥ್ಯವನ್ನು ನಿಂದಿಸುತ್ತಾ ನಿನ್ನ ಬಗ್ಗೆ ಹೇಳಬಾರದ ಮಾತುಗಳನ್ನು ಬಹಳವಾಗಿ ಹೇಳುವರು ; ಅದಕ್ಕಿಂತ ಹೆಚ್ಚಿನದಾದ ದುಃಖವು ಮತ್ತೆ ಯಾವುದಿರಬಹುದು ? 2.36",

      "ಒಂದೋ ನೀನು ಯುದ್ಧದಲ್ಲಿ ಕೊಲ್ಲಲ್ಪಟ್ಟು ಸ್ವರ್ಗವನ್ನು ಪಡೆಯುವೆ ಅಥವಾ ಯುದ್ಧದಲ್ಲಿ ಗೆದ್ದು ಪೃಥ್ವಿಯ ರಾಜ್ಯವನ್ನು ಭೋಗಿಸುವೆ ಈ ಕಾರಣದಿಂದ ಹೇ ಅರ್ಜುನನೇ ! ನೀನು ಯುದ್ಧಕ್ಕಾಗಿ ನಿಶ್ಚಯಮಾಡಿ ಎದ್ದು ನಿಲ್ಲು. 2.37",

      "ಜಯ- ಪರಾಜಯ, ಲಾಭ - ಹಾನಿ ಮತ್ತು ಸುಖ - ದುಃಖಗಳನ್ನು ಸಮಾನವಾಗಿ ತಿಳಿದುಕೊಂಡು, ಅನಂತರ ಯುದ್ಧಕ್ಕೆ ಸಿದ್ಧನಾಗು, ಈ ಪ್ರಕಾರ ಯುದ್ಧ ಮಾಡುವುದರಿಂದ ನೀನು ಪಾಪವನ್ನು ಹೊಂದುವುದಿಲ್ಲ. 2.38",

      "ಹೇ ಪಾರ್ಥನೇ ! ಈ ಬುದ್ಧಿಯು ನಿನಗೆ ಜ್ಞಾನಯೋಗದ ವಿಷಯದಲ್ಲಿ ಹೇಳಲ್ಪಟ್ಟಿತು, ಮತ್ತು ಈಗ ನೀನು ಅದನ್ನು ಕರ್ಮಯೋಗದ ವಿಷಯದಲ್ಲಿ ಕೇಳು. ಇಂತಹ ಸಮಬುದ್ಧಿಯಿಂದ ಯುಕ್ತನಾಗಿ ನೀನು ಕರ್ಮಗಳ ಬಂಧನವನ್ನು ಸಂಪೂರ್ಣವಾಗಿ ಕಳೆದುಕೊಳ್ಳುವೆ, ಅಂದರೆ ಕರ್ಮಬಂಧನದಿಂದ ಬಿಡುಗಡೆ ಹೊಂದುವೆ. 2.39",

      "ಈ ಕರ್ಮಯೋಗದಲ್ಲಿ ಉಪಕ್ರಮ ಅರ್ಥಾತ್ ಬೀಜದ ನಾಶವಾಗುವುದಿಲ್ಲ ಮತ್ತು ಇದರಲ್ಲಿ ವಿರುದ್ಧವಾದ ಫಲರೂಪೀ ದೋಷವೂ ಕೂಡ  ಇಲ್ಲ. ಅಲ್ಲದೆ ಈ ಕರ್ಮಯೋಗ ರೂಪೀ ಧರ್ಮದ ಸ್ವಲ್ಪವಾದ ಸಾಧನವೂ ಕೂಡ ಜನ್ಮ-ಮೃತ್ಯು ರೂಪವಾದ ಮಹಾನ್ ಭಯದಿಂದ ರಕ್ಷಿಸುತ್ತದೆ. 2.40",

      "ಹೇ ಅರ್ಜುನನೇ ! ಈ ಕರ್ಮಯೋಗದಲ್ಲಿ ನಿಶ್ಚಯಾತ್ಮಕವಾದ ಬುದ್ಧಿಯು ಒಂದೇ ಇರುತ್ತದೆ ; ಆದರೆ,  ಅಸ್ತ್ಥಿರವಾದ ವಿಚಾರವುಳ್ಳ, ವಿವೇಕಹೀನ, ಸಕಾಮರಾದ ಮನುಷ್ಯರ ಬುದ್ಧಿತಳು ನಿಶ್ಚಯವಾಗಿಯೂ ಬಹುಭೇದವುಳ್ಳವುಗಳಾಗಿ ಅನಂತವಾಗಿರುತ್ತವೆ. 2.41",

      "ಹೇ ಅರ್ಜುನನೇ ! ಯಾರು ಭೋಗಗಳಲ್ಲಿ ತನ್ಮಯರಾಗಿದ್ದಾರೋ, ಯಾರು ಕರ್ಮಫಲವನ್ನು ಪ್ರಶಂಸಿಸುವ ವೇದ ವಾಕ್ಯಗಳಲ್ಲಿ ಪ್ರೀತಿಯನ್ನಿಡುತ್ತಾರೋ, ಯಾರ ಬುದ್ಧಿಯಲ್ಲಿ ಸ್ವರ್ಗವೇ ಪರಮಪ್ರಾಪ್ಯವಸ್ತುವಾಗಿದೆಯೋ ಮತ್ತು ಸ್ವರ್ಗಕ್ಕಿಂತ ಮಿಗಿಲಾದ ಎರಡನೇ ವಸ್ತುವೇ ಇಲ್ಲ - ಹೀಗೆ ಹೇಳುವವರಿದ್ದಾರೋ ಆ ಅವಿವೇಕಿ ಜನರು ಈ ಪ್ರಕಾರದ ಯಾವ ಪುಷ್ಟಿತವಾದ ಅರ್ಥಾತ್ ತೋರಿಕೆಗೆ ಮಾತ್ರ ಶೋಭೆಯಿಂದ ಕೂಡಿದ ವಾಣಿಯನ್ನು ಹೇಳುತ್ತಿರುತ್ತಾರೋ, ಅದು ಜನ್ಮ ರೂಪೀ - ಕರ್ಮಫಲವನ್ನು ಕೊಡುವುದಾಗಿಯೂ ಮತ್ತು ಭೋಗ ಹಾಗೂ ಐಶ್ವರ್ಯದ ಪ್ರಾಪ್ತಿಗಾಗಿ ನಾನಾ ಪ್ರಕಾರದ, ಬಹಳಷ್ಟು ಕ್ರಿಯಗಳ ವರ್ಣನೆಯನ್ನು ಮಾಡುವುದಾಗಿದೆಯೋ ಆ ವಾಣಿಯ ಮೂಲಕ ಯಾರ ಚಿತ್ತವು ಅಪಹರಿಸಲ್ಪಟ್ಟಿದೆಯೋ, ಯಾರು ಭೋಗ ಮತ್ತು ಐಶವರ್ಯದಲ್ಲಿ ಅತ್ಯಂತ ಆಸಕ್ತರಾಗಿದ್ದಾರೋ - ಆ ಪುರುಷರಿಗೆ ಪರಮಾತ್ಮನಲ್ಲಿ ನಿಶ್ಚಯಾತ್ಮಿಕಾ ಬುದ್ಧಿಯು ಇರುವುದಿಲ್ಲ. (2.42-2.44)",

      "ಹೇ ಅರ್ಜುನನೇ ! ಯಾರು ಭೋಗಗಳಲ್ಲಿ ತನ್ಮಯರಾಗಿದ್ದಾರೋ, ಯಾರು ಕರ್ಮಫಲವನ್ನು ಪ್ರಶಂಸಿಸುವ ವೇದ ವಾಕ್ಯಗಳಲ್ಲಿ ಪ್ರೀತಿಯನ್ನಿಡುತ್ತಾರೋ, ಯಾರ ಬುದ್ಧಿಯಲ್ಲಿ ಸ್ವರ್ಗವೇ ಪರಮಪ್ರಾಪ್ಯವಸ್ತುವಾಗಿದೆಯೋ ಮತ್ತು ಸ್ವರ್ಗಕ್ಕಿಂತ ಮಿಗಿಲಾದ ಎರಡನೇ ವಸ್ತುವೇ ಇಲ್ಲ - ಹೀಗೆ ಹೇಳುವವರಿದ್ದಾರೋ ಆ ಅವಿವೇಕಿ ಜನರು ಈ ಪ್ರಕಾರದ ಯಾವ ಪುಷ್ಟಿತವಾದ ಅರ್ಥಾತ್ ತೋರಿಕೆಗೆ ಮಾತ್ರ ಶೋಭೆಯಿಂದ ಕೂಡಿದ ವಾಣಿಯನ್ನು ಹೇಳುತ್ತಿರುತ್ತಾರೋ, ಅದು ಜನ್ಮ ರೂಪೀ - ಕರ್ಮಫಲವನ್ನು ಕೊಡುವುದಾಗಿಯೂ ಮತ್ತು ಭೋಗ ಹಾಗೂ ಐಶ್ವರ್ಯದ ಪ್ರಾಪ್ತಿಗಾಗಿ ನಾನಾ ಪ್ರಕಾರದ, ಬಹಳಷ್ಟು ಕ್ರಿಯಗಳ ವರ್ಣನೆಯನ್ನು ಮಾಡುವುದಾಗಿದೆಯೋ ಆ ವಾಣಿಯ ಮೂಲಕ ಯಾರ ಚಿತ್ತವು ಅಪಹರಿಸಲ್ಪಟ್ಟಿದೆಯೋ, ಯಾರು ಭೋಗ ಮತ್ತು ಐಶವರ್ಯದಲ್ಲಿ ಅತ್ಯಂತ ಆಸಕ್ತರಾಗಿದ್ದಾರೋ - ಆ ಪುರುಷರಿಗೆ ಪರಮಾತ್ಮನಲ್ಲಿ ನಿಶ್ಚಯಾತ್ಮಿಕಾ ಬುದ್ಧಿಯು ಇರುವುದಿಲ್ಲ. (2.42-2.44)",

      "ಹೇ ಅರ್ಜುನನೇ ! ಯಾರು ಭೋಗಗಳಲ್ಲಿ ತನ್ಮಯರಾಗಿದ್ದಾರೋ, ಯಾರು ಕರ್ಮಫಲವನ್ನು ಪ್ರಶಂಸಿಸುವ ವೇದ ವಾಕ್ಯಗಳಲ್ಲಿ ಪ್ರೀತಿಯನ್ನಿಡುತ್ತಾರೋ, ಯಾರ ಬುದ್ಧಿಯಲ್ಲಿ ಸ್ವರ್ಗವೇ ಪರಮಪ್ರಾಪ್ಯವಸ್ತುವಾಗಿದೆಯೋ ಮತ್ತು ಸ್ವರ್ಗಕ್ಕಿಂತ ಮಿಗಿಲಾದ ಎರಡನೇ ವಸ್ತುವೇ ಇಲ್ಲ - ಹೀಗೆ ಹೇಳುವವರಿದ್ದಾರೋ ಆ ಅವಿವೇಕಿ ಜನರು ಈ ಪ್ರಕಾರದ ಯಾವ ಪುಷ್ಟಿತವಾದ ಅರ್ಥಾತ್ ತೋರಿಕೆಗೆ ಮಾತ್ರ ಶೋಭೆಯಿಂದ ಕೂಡಿದ ವಾಣಿಯನ್ನು ಹೇಳುತ್ತಿರುತ್ತಾರೋ, ಅದು ಜನ್ಮ ರೂಪೀ - ಕರ್ಮಫಲವನ್ನು ಕೊಡುವುದಾಗಿಯೂ ಮತ್ತು ಭೋಗ ಹಾಗೂ ಐಶ್ವರ್ಯದ ಪ್ರಾಪ್ತಿಗಾಗಿ ನಾನಾ ಪ್ರಕಾರದ, ಬಹಳಷ್ಟು ಕ್ರಿಯಗಳ ವರ್ಣನೆಯನ್ನು ಮಾಡುವುದಾಗಿದೆಯೋ ಆ ವಾಣಿಯ ಮೂಲಕ ಯಾರ ಚಿತ್ತವು ಅಪಹರಿಸಲ್ಪಟ್ಟಿದೆಯೋ, ಯಾರು ಭೋಗ ಮತ್ತು ಐಶವರ್ಯದಲ್ಲಿ ಅತ್ಯಂತ ಆಸಕ್ತರಾಗಿದ್ದಾರೋ - ಆ ಪುರುಷರಿಗೆ ಪರಮಾತ್ಮನಲ್ಲಿ ನಿಶ್ಚಯಾತ್ಮಿಕಾ ಬುದ್ಧಿಯು ಇರುವುದಿಲ್ಲ. (2.42-2.44)",

      "ಹೇ ಅರ್ಜುನನೇ ! ವೇದಗಳು ಮೇಲೆ ಹೇಳಿದ ಪ್ರಕಾರ ಮೂರು ಗುಣಗಳ ಕಾರ್ಯರೂಪವಾದ ಸಮಸ್ತಭೋಗಗಳನ್ನು ಮತ್ತು ಅವುಗಳ ಸಾಧನೆಗಳನ್ನು ಪ್ರತಿಪಾದಿಸುತ್ತವೆ; ಆದ್ದರಿಂದ ನೀನು ಆ ಭೋಗಗಳು ಮತ್ತು ಅವುಗಳ ಸಾಧನೆಗಳಲ್ಲಿ ಆಸಕ್ತಿಹೀನನಾಗಿ, ಹರ್ಷ - ಶೋಕಾದಿ ದ್ವಂದ್ವಗಳಿಂದ ರಹಿತನಾಗಿ, ನಿತ್ಯವಸ್ತುವಾದ ಪರಮಾತ್ಮನಲ್ಲಿ ಸ್ಥಿತನಾಗಿ ಯೋಗಕ್ಷೇಮವನ್ನು ಬಯಸದವನೂ ಮತ್ತು ಸ್ವಾಧೀನ ಅಂತಃಕರಣವುಳ್ಳವನೂ ಆಗು. 2.45",

      "ಎಲ್ಲ ಕಡೆಗಳಿಂದಲೂ ತುಂಬಿತುಳುಕುವ ಜಲಾಶಯವು ದೊರಕಿದಾಗ ಚಿಕ್ಕ ಜಲಾಶಯದಿಂದ ಮನುಷ್ಯನಿಗೆ ಎಷ್ಟು ಪ್ರಯೋಜನವಿರುತ್ತದೆಯೋ, ಬ್ರಹ್ಮವನ್ನು ತತ್ವಶಃ ತಿಳಿದಿರುವ ಬ್ರಾಹ್ಮಣನಿಗೆ ಸಮಸ್ತ ವೇದಗಳಲ್ಲಿ ಅಷ್ಟೇ ಪ್ರಯೋಜನವಿರುತ್ತದೆ. ( ಹೇಗೆಂದರೆ ಬ್ರಹ್ಮಾನಂದ ಪ್ರಾಪ್ತಿಯಾದ ಮೇಲೆ ಆನಂದಕ್ಕಾಗಿ ವೇದಗಳ ಅವಶ್ಯಕತೆ ಇರುವುದಿಲ್ಲ.) 2.46",

      "ನಿನಗೆ ಕರ್ಮ ಮಾಡುವುದರಲ್ಲಿಯೇ ಅಧಿಕಾರವಿದೆ, ಅದರ ಫಲಗಳಲ್ಲಿ ಎಂದಿಗೂ ಇಲ್ಲ. ಅದಕ್ಕಾಗೆ ನೀನು ಕರ್ಮಗಳ ಫಲಕ್ಕೆ ಕಾರಣನಾಗಬೇಡ. ಅರ್ಥಾತ್ ಫಲಾಪೇಕ್ಷೆ ಇಲ್ಲದೆ ಕರ್ತವ್ಯ ಬುದ್ಧಿಯಿಂದಾ ಕರ್ಮಗಳನ್ನು ಮಾಡು. ಹಾಗೂ, ನಿನಗೆ ಕರ್ಮ ಮಾಡದೇ ಇರುವುದರಲ್ಲಿ ಕೂಡ ಆಸಕ್ತಿಯಿಲ್ಲದಿರಲಿ. 2.47",

      "ಹೇ ಧನಂಜಯನೇ ! ನೀನು ಆಸಕ್ತಿಯನ್ನು ತ್ಯಜಿಸಿ ಹಾಗೂ ಸಿದ್ಧಿ - ಅಸಿದ್ಧಿಗಳಲ್ಲಿ ಸಮಬುದ್ಧಿಯುಳ್ಳವನಾಗಿ, ಯೋಗದಲ್ಲಿ ಸ್ಥಿತನಾಗಿದ್ದು ಕರ್ತವ್ಯ ಕರ್ಮಗಳನ್ನು ಮಾಡು, ಸಮತ್ವವೇ ಯೋಗವೆನಿಸಿಕೊಳ್ಳುತ್ತದೆ. 2.48",

      "ಈ ಸಮತ್ವರೂಪವಾದ ಬುದ್ಧಿಯೋಗಕ್ಕಿಂತ ಸಕಾಮ ಕರ್ಮವು ಅತ್ಯಂತ ಕೆಳಮಟ್ಟದ್ದಾಗಿದೆ. ಅದಕ್ಕಾಗಿ ಹೇ ಧನಂಜಯನೇ! ನೀನು ಸಮಬುದ್ಧಿಯಲ್ಲಿಯೇ ರಕ್ಷಣೆಯ ಉಪಾಯವನ್ನು ಹುಡುಕು ಅರ್ಥಾತ್ ಬುದ್ಧಿಯೋಗದ ಆಶ್ರಯವನ್ನೇ ಪಡೆ; ಏಕೆಂದರೆ, ಫಲಕ್ಕೆ ಕಾರಣರಾಗುವವರು ಅತ್ಯಂತ ದೀನರಾಗಿದ್ದಾರೆ. 2.49",

      "ಸಮಬುದ್ಧಿಯುಳ್ಳ ಪುರುಷನು ಪುಣ್ಯ ಮತ್ತು ಪಾಪಗಳೆರಡನ್ನೂ ಇದೇ ಲೋಕದಲ್ಲಿ ತ್ಯಜಿಸುತ್ತಾನೆ ಅರ್ಥಾತ್, ಅವುಗಳಿಂದ ಮುಕ್ತನಾಗುತ್ತಾನೆ. ಆದ್ದರಿಂದ ನೀನು ಸಮತ್ವರೂಪೀ ಯೋಗದಲ್ಲಿ ತೊಡಗು. ಈ ಸಮತ್ವರೂಪೀ ಯೋಗವೇ ಕರ್ಮಗಳಲ್ಲಿ ಕುಶಲತೆಯಾಗಿದೆ ಅರ್ಥಾತ್ ಕರ್ಮಬಂಧನದಿಂದ ಬಿಡುಗಡೆ ಹೊಂದುವ ಉಪಾಯವಾಗಿದೆ. 2.50",

      "ಏಕೆಂದರೆ,  ಸಮಬುದ್ಧಿಯಿಂದಯುಕ್ತರಾದ ಜ್ಞಾನಿಗಳು ಕರ್ಮಗಳಿಂದ ಉಂಟಾಗುವ ಫಲವನ್ನು ತ್ಯಾಗಮಾಡಿ ಜನ್ಮರೂಪೀ ಬಂಧನದಿಂದ ಮುಕ್ತರಾಗಿ ನಿರ್ವಿಕಾರ ಪರಮಪದವನ್ನು ಹೊಂದುತ್ತಾರೆ. 2.51",

      "ಯಾವ ಕಾಲದಲ್ಲಿ ನಿನ್ನ ಬುದ್ಧಿಯು ಮೋಹರೂಪೀ ಕೆಸರನ್ನು ಚೆನ್ನಾಗಿ ದಾಟಿಬಿಡುವುದೋ ಆ ಸಮಯದಲ್ಲಿ ನೀನು ಕೇಳಿರುವ, ಕೇಳಿಬರುವ ಈ ಲೋಕ ಮತ್ತು ಪರಲೋಕ ಸಂಬಂಧವಾದ ಎಲ್ಲ ಭೋಗಗಳಿಂದ ವೈರಾಗ್ಯವನ್ನು ಪಡೆಯುವೆ. 2.52",

      "ಬಗೆ-ಬಗೆಯ ವಚನಗಳನ್ನು ಕೇಳುವುದರಿಂದ ವಿಚಲಿತವಾಗಿರುವ ನಿನ್ನ ಬುದ್ಧಿಯು ಯಾವಾಗ ಪರಮಾತ್ಮನಲ್ಲಿ ಅಚಲ ಮತ್ತು ಸ್ಥಿರವಾಗಿ ನಿಲ್ಲುವುದೋ ಆಗ ನೀನು ಯೋಗವನ್ನು ಪಡೆಯುವೆ ಅರ್ಥಾತ್ ಪರಮಾತ್ಮನೊಡನೆ ನಿನ್ನ ನಿತ್ಯ ಸಂಯೋಗವು ಉಂಟಾಗುವುದು. 2.53",

      "ಅರ್ಜುನನು ಹೇಳಿದನು - ಹೇ ಕೇಶವಾ! ಸಮಾಧಿಸ್ಥನಾಗಿ ಪರಮಾತ್ಮನನ್ನು ಪಡೆದಿರುವ ಸ್ಥಿರಬುದ್ಧಿಯುಳ್ಳ ಪುರುಷನ ಲಕ್ಷಣಗಳೇನು ? ಆ ಸ್ಥಿರಬುದ್ಧಿಯ ಪುರುಷನು ಹೇಗೆ ಮಾತನಾಡುತ್ತಾನೆ, ಹೇಗೆ ಕುಳಿತಿರುತ್ತಾನೆ ಮತ್ತು ಹೇಗೆ ನಡೆಯುತ್ತಾನೆ ? 2.54",

      "ಭಗವಂತನು ಹೇಳಿದನು - ಹೇ ಅರ್ಜುನನೇ ! ಯಾವ ಕಾಲದಲ್ಲಿ ಈ ಪುರುಷನು ಮನಸ್ಸಿನಲ್ಲಿರುವ ಎಲ್ಲ ಕಾಮನೆಗಳನ್ನು ಸಂಪೂರ್ಣವಾಗಿ ತ್ಯಜಿಸುತ್ತಾನೋ ಮತ್ತು ಆತ್ಮನಿಂದ ಆತ್ಮನಲ್ಲಿಯೇ ಸಂತುಷ್ಟನಾಗಿರುತ್ತಾನೋ ಆ ಕಾಲದಲ್ಲಿ ಅವನು ಸ್ಥಿತಪ್ರಜ್ಞನೆಂದು ಹೇಳಲ್ಪಡುತ್ತಾನೆ. 2.55",

      "ದುಃಖಗಳು ಪ್ರಾಪ್ತಿಯಾದ ಮೇಲೆ ಯಾರ ಮನಸ್ಸಿನಲ್ಲಿ ಉದ್ವೇಗವು ಉಂಟಾಗುವುದಿಲ್ಲವೋ, ಸುಖಗಳ ಪ್ರಾಪ್ತಿಯಲ್ಲಿ ಯಾರು ಸರ್ವಥಾ ನಿಸ್ಫೃಹರಾಗಿರುತ್ತಾರೋ ಹಾಗೂ ಯಾರ ರಾಗ, ಭಯ ಮತ್ತು ಕ್ರೋಧಗಳು ನಷ್ಟವಾಗಿ ಹೋಗಿವೆಯೋ ಅಂತಹ ಮುನಿಯು ಸ್ಥಿರಬುದ್ಧಿಯವನೆಂದು ಹೇಳಲ್ಪಡುತ್ತಾನೆ. 2.56",

      "ಯಾವ ಪುರುಷನು ಸರ್ವತ್ರ ಸ್ನೇಹರಹಿತನಾಗಿದ್ದು ಆಯಾಯಾ ಶುಭಾಶುಭ ವಸ್ತುಗಳನ್ನು ಪಡೆದುಕೊಂಡು ಪ್ರಸನ್ನನಾಗುವುದಿಲ್ಲವೋ ಮತ್ತು ದ್ವೇಷಿಸುವುದಿಲ್ಲವೋ ಅವನ ಬುದ್ಧಿಯು ಸ್ಥಿರವಾಗಿದೆ. 2.57",

      "ಮತ್ತು ಆಮೆಯು ಎಲ್ಲ ಕಡೆಯಿಂದ ತನ್ನ ಅಂಗಗಳನ್ನು ಹೇಗೆ ಒಳಕ್ಕೆ ಸೆಳೆದುಕೊಳ್ಳುತ್ತದೆಯೋ ಹಾಗೆಯೇ ಯಾವಾಗ ಈ ಪುರುಷನು ಇಂದ್ರಿಯಗಳ ವಿಷಯಗಳಿಂದ ಇಂದ್ರಿಯಗಳನ್ನು ಎಲ್ಲ ಪ್ರಕಾರದಿಂದ ಹಿಂದಕ್ಕೆಳೆದುಕೊಳ್ಳುತ್ತಾನೆಯೋ ಆಗ ಅವನ ಬುದ್ಧಿಯು ಸ್ಥಿರವಾಗಿದೆಯೆಂದು ತಿಳಿಯಬೇಕು. 2.58",

      "ಹೇ ಅರ್ಜುನನೇ ! ಆಸಕ್ತಿಯು ನಾಶವಾಗದಿರುವ ಕಾರಣ ಈ ಪ್ರಮಥನ ಸ್ವಭಾವವುಳ್ಳ ಇಂದ್ರಿಯಗಳು ಪ್ರಯತ್ನಿಸುತ್ತಿರುವ ಬುದ್ಧಿವಂತನಾದ್ ಪುರುಷನ ಮನಸ್ಸನ್ನು ಕೂಡ ಬಲವಂತವಾಗಿ ಅಪಹರಿಸಿಕೊಳ್ಳುತ್ತವೆ. 2.59",

      "ಅದಕ್ಕಾಗಿ ಸಾಧಕನು ಆ ಸಂಪೂರ್ಣ ಇಂದ್ರಿಯಗಳನ್ನು ವಶಪಡಿಸಿಕೊಂಡು, ಸಮಾಹಿತಚಿತ್ತನಾಗಿ ನನ್ನ ಪರಾಯಣನಾಗಿ, ಧ್ಯಾನದಲ್ಲಿ ಕುಳಿತುಕೊಳ್ಳಲಿ. ಏಕೆಂದರೆ, ಯಾವ ಪುರುಷನ ಇಂದ್ರಿಯಗಳು ವಶದಲ್ಲಿ ಇರುತ್ತವೆಯೋ ಅವನ ಬುದ್ಧಿಯು ಸ್ಥಿರವಾಗಿರುತ್ತದೆ. 2.60",

      "ಅದಕ್ಕಾಗಿ ಸಾಧಕನು ಆ ಸಂಪೂರ್ಣ ಇಂದ್ರಿಯಗಳನ್ನು ವಶಪಡಿಸಿಕೊಂಡು, ಸಮಾಹಿತಚಿತ್ತನಾಗಿ ನನ್ನ ಪರಾಯಣನಾಗಿ, ಧ್ಯಾನದಲ್ಲಿ ಕುಳಿತುಕೊಳ್ಳಲಿ. ಏಕೆಂದರೆ, ಯಾವ ಪುರುಷನ ಇಂದ್ರಿಯಗಳು ವಶದಲ್ಲಿ ಇರುತ್ತವೆಯೋ ಅವನ ಬುದ್ಧಿಯು ಸ್ಥಿರವಾಗಿರುತ್ತದೆ. 2.61",

      "ವಿಷಯಗಳನ್ನು ಚಿಂತಿಸುವ ಪುರುಷನಿಗೆ ಆ ವಿಷಯಗಳಲ್ಲಿ ಆಸಕ್ತಿಯುಂಟಾಗುತ್ತದೆ. ಆಸಕ್ತಿಯಿಂದಾಗಿ ಆ ವಿಷಯಗಳ ಕಾಮನೆ ಉಂಟಾಗುತ್ತದೆ ಮತ್ತು ಕಾಮನೆಯಲ್ಲಿ ವಿಘ್ನವುಂಟಾಗುವುದರಿಂದ ಕ್ರೋಧವು ಉತ್ಪನ್ನವಾಗುತ್ತದೆ. 2.62",

      "ಕ್ರೋಧದಿಂದ ಅತ್ಯಂತ, ಮೂಢಭಾವವು ಉಂಟಾಗುತ್ತದೆ, ಮೂಢಭಾವದಿಂದ ಸ್ಮೃತಿಯಲ್ಲಿ ಭ್ರಮೆಯುಂಟಾಗುತ್ತದೆ, ಸ್ಮೃತಿಯಲ್ಲಿ ಭ್ರಮೆಯುಂಟಾಗುವುದರಿಂದ ಬುದ್ಧಿ ಅರ್ಥಾತ್ ಜ್ಞಾನಶಕ್ತಿಯು ನಾಶವಾಗಿ ಹೋಗುತ್ತದೆ ಮತ್ತು ಬುದ್ಧಿನಾಶವಾಗುವುದರಿಂದ ಈ ಪುರುಷನು ತನ್ನ ಸ್ಥಿತಿಯಿಂದ ಜಾರಿ ಬೀಳುತ್ತಾನೆ 2.63",

      "ಆದರೆ, ಅಂತಃಕರಣವನ್ನು ತನ್ನ ಅಧೀನವಾಗಿ ಮಾಡಿಕೊಂಡಿರುವ ಸಾಧಕನು ತನ್ನ ವಶದಲ್ಲಿ ಮಾಡಿಕೊಂಡಿರುವ, ರಾಗ - ದ್ವೇಷರಹಿತವಾದ ಇಂದ್ರಿಯಯಳ ಮೂಲಕ ವಿಷಯಗಳಲ್ಲಿ ವಿಚರಿಸುತ್ತ ಅಂತಃಕರಣದ ಪ್ರಸನ್ನತೆಯನ್ನು ಪಡೆಯುತ್ತಾನೆ. 2.64",

      "ಅಂತಃಕರಣದಲ್ಲಿ ಆಧ್ಯಾತ್ಮಿಕ ಪ್ರಸನ್ನತೆ ಉಂಟಾದ ಮೇಲೆ ಇವನ ಸಂಪೂರ್ಣ ದುಃಖಗಳ ಅಭಾವವು ಉಂಟಾಗುತ್ತಾದೆ ಮತ್ತು ಆ ಪ್ರಸನ್ನಚ್ಚಿತ್ತನಾದ ಕರ್ಮಯೋಗಿಯು ಬುದ್ಧಿಯು ಶೀಘ್ರವೇ ಎಲ್ಲ ಕಡೆಯಿಂದ ಹಿಮ್ಮೆಟ್ಟಿ ಒರ್ವ ಪರಮಾತ್ಮತನ್ನಿಯೇ ಚೆನ್ನಾಗಿ ಸ್ಥಿರವಾಗುತ್ತದೆ. 2.65",

      "ಮನಸ್ಸು ಮತ್ತು ಇಂದ್ರಿಯಗಳನ್ನು ಗೆಲ್ಲದಿರುವ ಪುರುಷನಲ್ಲಿ ನಿಶ್ಚಯಾತ್ಮಿಕಾ ಬುದ್ಧಿಯು ಇರುವುದಿಲ್ಲ ಮತ್ತು ಆ ಅಯುಕ್ತ ಮನುಷ್ಯನ ಅಂತಃಕರಣದಲ್ಲಿ ಭಾವನೆಯೂ ಇರುವುದಿಲ್ಲ. ಹಾಗೆಯೇ ಭಾವನಾಹೀನನಾದ ಮನುಷ್ಯನಿಗೆ ಶಾಂತಿಯು ಸಿಗುವುದಿಲ್ಲ ಮತ್ತು ಶಾಂತಿರಹಿತನಾದ ಮನುಷ್ಯನಿಗೆ ಸುಖವು ಹೇಗೆ ಸಿಗಬಲ್ಲದು ? 2.66",

      "ಏಕೆಂದರೆ - ಹೇಗೆ ನೀರಿನಲ್ಲಿ ಚಲಿಸುವ ನಾವೆಯನ್ನು ವಾಯುವು ಅಪಹರಿಸುತ್ತದೆಯೋ, ಹಾಗೆಯೇ - ವಿಷಯಗಳಲ್ಲಿ ಚರಿಸುತ್ತಿರುವ ಇಂದ್ರಿಯಗಳಲ್ಲಿ ಮನಸ್ಸು ಯಾವ ಇಂದ್ರಿಯದ ಜೊತೆಯಲ್ಲಿ ಇರುತ್ತದೆಯೋ ಆ ಒಂದೇ ಇಂದ್ರಿಯವು ಈ ಅಯುಕ್ತಪುರುಷನ ಬುದ್ಧಿಯನ್ನು ಅಪಹರಿಸುತ್ತದೆ. 2.67",

      "ಅದಕ್ಕಾಗಿ ಹೇ ಮಹಾಬಾಹುವೇ ! ಯಾವ ಪುರುಷನ ಇಂದ್ರಿಯಗಳು, ಇಂದ್ರಿಯಗಳ ವಿಷಯಗಳಿಂದ ಎಲ್ಲಾ ಪ್ರಕಾರವಾಗಿ ನಿಗ್ರಹಿಸಲ್ಪಟ್ಟಿವೆಯೋ, ಅವನ ಬುದ್ಧಿಯು ಸ್ಥಿರವಾಗಿದೆ. 2.68",

      "ಸಂಪೂರ್ಣ ಪ್ರಾಣಿಗಳಿಗಾಗಿ ಯಾವುದು ರಾತ್ರಿಗೆ ಸಮಾನವಾಗಿದೆಯೋ ಆ ನಿತ್ಯಜ್ಞಾನಸ್ವರೂಪವಾದ ಪರಮಾನಂದದ ಪ್ರಾಪ್ತಿಯಲ್ಲಿ ಸ್ಥಿತಪ್ರಜ್ಞನಾದ ಯೋಗಿಯು ಎಚ್ಚರಿರುತ್ತಾನೆ ಮತ್ತು ಯಾವ ನಾಶವಾಗುವ ಸಾಂಸಾರಿಕ ಸುಖದ ಪ್ರಾಪ್ತಿಯಲ್ಲಿ ಎಲ್ಲ ಪ್ರಾಣಿಗಳು ಎಚ್ಚರವಾಗಿರುತ್ತವೆಯೋ ಪರಮಾತ್ಮ ತತ್ವವನ್ನು ಅರಿತಿರುವ ಮುನಿಗೆ ಅದು ರಾತ್ರಿಗೆ ಸಮಾನವಾಗಿದೆ. 2.69",

      "ಯಾವ ರೀತಿಯಲ್ಲಿ ನಾನಾ ನದಿಗಳ ಜಲವು ಯಾವಾಗ ಎಲ್ಲಕಡೆಯಿಂದ ಪರಿಪೂರ್ಣವಾದ ಅಚಲ ಪ್ರತಿಷ್ಠೆಯುಳ್ಳ ಸಮುದ್ರದಲ್ಲಿ ಅದನ್ನು ವಿಚಲಿತವಾಗಿಸದೆಯೇ ಸೇರಿಹೋಗುತ್ತವೆಯೋ ಹಾಗೆಯೇ ಎಲ್ಲ ಭೋಗಗಳು ಸ್ಥಿತಪ್ರಜ್ಞಪುರುಷನಲ್ಲಿ ಯಾವ ಪ್ರಕಾರವಾದ ವಿಕಾರವನ್ನೂ ಉಂಟುಮಾಡದೆಯೇ ಸೇರಿಹೋಗುತ್ತವೆ, ಆ ಪುರುಷನು ಪರಮಶಾಂತಿಯನ್ನುಪಡೆಯುತ್ತಾನೆ - ಭೋಗಗಳನ್ನು ಬಯಸುವವನು ಪಡೆಯುವುದಿಲ್ಲ. 2.70",

      "ಯಾವ ಪುರುಷನು ಸಂಪೂರ್ಣವಾಗಿ ಎಲ್ಲಾ ಕಾಮನೆಗಳನ್ನು ತ್ಯಜಿಸಿ, ಮಮತಾರಹಿತನೂ, ಅಹಂಕಾರರಹಿತನೂ, ಸ್ಪೃಹಾರಹಿತನೂ ಆಗಿ ವಿಚರಿಸುತ್ತಾನೋ ಅವನೇ ಶಾಂತಿಯನ್ನು ಪಡೆದಿದ್ದಾನೆ. 2.71",

      "ಹೇ ಅರ್ಜುನನೇ! ಇದು ಬ್ರಹ್ಮವನ್ನು ಪಡೆದುಕೊಂಡ ಪುರುಷನ ಸ್ಥಿತಿಯಾಗಿದೆ. ಇದನ್ನು ಪಡೆದ ಯೋಗಿಯು ಎಂದೂ ಮೋಹಿತನಾಗುವುದಿಲ್ಲ, ಮತ್ತು ಅಂತ್ಯಕಾಲದಲ್ಲಿ ಕೂಡ ಈ ಬ್ರಾಹ್ಮೀ ಸ್ಥಿತಿಯಲ್ಲಿ ಸ್ಥಿತನಾಗಿದ್ದು ಬ್ರಹ್ಮಾನಂದವನ್ನು ಪಡೆಯುತ್ತಾನೆ. 2.72",

      "ಇತಿ ಶ್ರೀಮದ್ಭಗವದ್ಗೀತಾರೂಪೀ ಉಪನಿಷದ್, ಬ್ರಹ್ಮವಿದ್ಯಾ ಹಾಗೂ ಯೋಗಶಾಸ್ತ್ರ ವಿಷಯವಾಗಿ ಶ್ರೀಕೃಷ್ಣ ಮತ್ತು ಅರ್ಜುನರ ಸಂವಾದದಲ್ಲಿ ಸಾಂಖ್ಯಯೋಗ ಎಂಬ ಎರಡನೇ ಅಧ್ಯಾಯ ಸಂಪೂರ್ಣ" 
    ],
    "adhyaya-3" : [
      "ಅರ್ಜುನನು ಹೇಳಿದನು - ಹೇ ಜನಾರ್ದನನೇ ! ಒಂದುವೇಳೆ ನಿನಗೆ ಕರ್ಮಕ್ಕಿಂತ ಜ್ಞಾನವು ಶ್ರೇಷ್ಠವೆಂದು ಮಾನ್ಯವಾದರೆ ಹೇ ಕೇಶವನೇ ಮತ್ತೇಕೆ ನನ್ನನ್ನು ಭಯಂಕರವಾದ ಕರ್ಮದಲ್ಲಿ ತೊಡಗಿಸುತ್ತೀಯೆ ? 3.1",

      "ನೀನು ಗೊಂದಲದಂತಿರುವ ವಚನಗಳಿಂದ ನನ್ನ ಬುದ್ಧಿಯನ್ನು ಮೋಹಗೊಳಿಸುತ್ತಿರುವಂತೆ ಕಾಣುತ್ತದೆ. ಆದ್ದರಿಂದ ಯಾವುದರಿಂದ ನಾನು ಶ್ರೇಯಸ್ಸನ್ನು ಹೊಂದುವೆನೋ ಆ ಒಂದು ಮಾತನ್ನು ನಿಶ್ಚಯಿಸಿ ಹೇಳು 3.2",

      "ಭಗವಂತನು ಹೇಳಿದನು - ಹೇ ನಿಷ್ಪಾಪನೇ ! ಈ ಲೋಕದಲ್ಲಿ ಎರಡು ಪ್ರಕಾರದ ನಿಷ್ಠೆಗಳು ನನ್ನ ಮೂಲಕ ಮೊದಲು ಹೇಳಲಾಗಿವೆ. ಅವುಗಳಲ್ಲಿ ಸಾಂಖ್ಯಯೋಗಿಗಳ ನಿಷ್ಠೆಯು ಜ್ಞಾನಯೋಗದಿಂದ ಮತ್ತು ಯೋಗಿಗಳ ನಿಷ್ಠೆಯು ಕರ್ಮಯೋಗದಿಂದ ಇರುತ್ತದೆ. 3.3",

      "ಮನುಷ್ಯನು ಕರ್ಮಗಳನ್ನು ಆಚರಿಸದೆ ಅರ್ಥಾತ್ ಕರ್ಮಾಚರಣೆ ಮಾಡದೆ ನಿಷ್ಕರ್ಮತೆಯನ್ನು ಅಂದರೆ ಯೋಗನಿಷ್ಠೆಯನ್ನು ಪಡೆಯಲಾರನು,  ಮತ್ತು ಕೇವಲ ಕರ್ಮಗಳ ತ್ಯಾಗಮಾತ್ರದಿಂದ ಸಿದ್ಧಿ ಎಂದರೆ ಸಾಂಖ್ಯನಿಷ್ಠೆಯನ್ನು ಪಡೆಯಲಾರನು. 3.4",

      "ನಿಸ್ಸಂದೇಹವಾಗಿ ಯಾವ ಮನುಷ್ಯನಾದರೂ ಯಾವುದೇ ಕಾಲದಲ್ಲಿ, ಕ್ಷಣ ಮಾತ್ರವೂ ಸಹ ಕರ್ಮವನ್ನು ಮಾಡದೇ ಇರಲಾರನು; ಏಕೆಂದರೆ, ಎಲ್ಲ ಮನುಷ್ಯ ಸಮುದಾಯವು ಪ್ರಕೃತಿಜನ್ಯವಾದ ಗುಣಗಳ ಮೂಲಕ ಪರವಶವಾಗಿ ಕರ್ಮ ಮಾಡುವುದಕ್ಕೆ ಬಾಧ್ಯನನ್ನಾಗಿ ಮಾಡಲ್ಪಡುತ್ತದೆ. 3.5",

      "ಯಾವ ಮೂಢಬುದ್ಧಿಯುಳ್ಳ ಮನುಷ್ಯನು ಸಮಸ್ತ ಇಂದ್ರಿಯಗಳನ್ನು ಹಠಪೂರ್ವಕವಾಗಿ ( ಮೇಲ್ನೊಟಕ್ಕೆ ) ತಡೆದು ಮನಸ್ಸಿನಲ್ಲಿ ಇಂದ್ರಿಯಗಳ ವಿಷಯಗಳನ್ನು ಚಿಂತಿಸುತ್ತ ಇರುತ್ತಾನೋ ಅವನು ಮಿಥ್ಯಾಚಾರಿ ಅರ್ಥಾತ್ ಡಾಂಭಿಕನೆಂದು ಹೇಳಲ್ಪಡುತ್ತಾನೆ. 3.6",

      "ಆದರೆ, ಎಲೈ ಅರ್ಜುನ, ಯಾರು ಮನಃಪೂರ್ವಕವಾಗಿ ಇಂದ್ರಿಯಗಳನ್ನು ವಶಮಾಡಿಕೊಂಡು ಅನಾಸಕ್ತವಾದ ಕರ್ಮೇಂದ್ರಿಯಗಳಿಂದ ಕರ್ಮಯೋಗವನ್ನು ಆಚರಿಸುತ್ತಾರೋ ಅವರು ಶ್ರೇಷ್ಠರು. 3.7",

      "ನೀನು ಶಾಸ್ತ್ರವಿಧಿಯಿಂದ ನಿಶ್ಚಯಿಸಲ್ಪಟ್ಟ ಸ್ವಧರ್ಮರೂಪೀ ಕರ್ಮವನ್ನು ಮಾಡು. ಏಕೆಂದರೆ ಕರ್ಮ ಮಾಡದೇ ಇರುವುದಕ್ಕಿಂತಲೂ ಕರ್ಮ ಮಾಡುವುದು ಶ್ರೇಷ್ಠ ಹಾಗು ಕರ್ಮ ಮಾಡದೇ ಇರುವುದರಿಂದ ನಿನ್ನ ಶರೀರ ನಿರ್ವಹಣೆ, ಅರ್ಥಾತ್ ಜೀವನೋಪಾಯವೂ, ಸಾಧ್ಯವಾಗುವುದಿಲ್ಲ. 3.8",

      "ಯಜ್ಞ, ಅಂದರೆ ವಿಷ್ಣುವಿನ ನಿಮಿತ್ತವಾಗಿ ಮಾಡಲ್ಪಟ್ಟ ಕರ್ಮದ ವಿನಃ ಬೇರೆ ಕರ್ಮಗಳಲ್ಲಿ ತೊಡಗಿರುವ ಮನುಜನು ಕರ್ಮಗಳಿಂದ ಬಂಧಿತನಾಗುತ್ತಾನೆ. ಆದುದರಿಂದ, ಎಲೈ ಅರ್ಜುನನೇ, ಆಸಕ್ತಿಯಿಲ್ಲದವನಾಗಿ ಆ ಪರಮೇಶ್ವರನ ನಿಮಿತ್ತವಾಗಿ ಕರ್ಮವನ್ನು ಒಳ್ಳೆಯ ರೀತಿಯಲ್ಲಿ ಆಚರಣೆ ಮಾಡು. 3.9",

      "ಪ್ರಜಾಪತಿ ಬ್ರಹ್ಮನು ಕಲ್ಪದ ಆದಿಯಲ್ಲಿ ಯಜ್ಞದ ಸಹಿತ ಪ್ರಜೆಗಳನ್ನು ರಚಿಸಿ ನೀವು ಈ ಯಜ್ಞದ ಮೂಲಕ ವೃದ್ಧಿ ಪಡೆಯಿರಿ ಮತ್ತು ಈ ಯಜ್ಞ ನಿಮಗೆ ಇಚ್ಛಿಸಿದ ಫಲಗಳನ್ನು ನೀಡುವಂತಾಗಲಿ ಎಂದು ಹೇಳಿದನು. 3.10",

      "ಈ ಯಜ್ಞದ ಮೂಲಕ ನೀವು ದೇವತೆಗಳನ್ನು ತೃಪ್ತಿಪಡಿಸಿರಿ ಮತ್ತು ಆ ದೇವತೆಗಳು ನಿಮಗೆ ಶ್ರೇಯಸ್ಸನ್ನು ಉಂಟುಮಾಡುವರು. ಈ ಪ್ರಕಾರ ಪರಸ್ಪರ ಕರ್ತವ್ಯವಂದು ತಿಳಿದುಕೊಂಡು ಶ್ರೇಯಸ್ಸನ್ನು ಕೋರುತ್ತಾ ಪರಮ ಶ್ರೇಯಸ್ಸನ್ನು ಪಡೆಯಿರಿ. 3.11",

      "ಯಜ್ಞದಿಂದ ಸಂತುಷ್ಟರಾದ ದೇವತೆಗಳು ನಿಮಗೆ ಪ್ರಿಯವಾದ ಸುಖ ಭೋಗಗಳನ್ನು ನೀವು ಕೇಳಿಕೊಳ್ಳದಿದ್ದರೂ ಸಹ ಕೊಡುವರು. ಅವರಿಂದ ಕೊಡಲ್ಪಟ್ಟ ಭೋಗಗಳನ್ನು ಯಾರು ಅವರಿಗೆ ಏನನ್ನೂ ಅರ್ಪಿಸದೆ ಅನುಭವಿಸುತ್ತಾನೆಯೋ ಅವನು ಶುದ್ಧ ಕಳ್ಳ. 3.12",

      "ಯಜ್ಞ ಶೇಷದ ಪ್ರಸಾದವನ್ನು ಸೇವಿಸುವ ಶ್ರೇಷ್ಠ ಪುರುಷರು ಎಲ್ಲ ಪಾಪಗಳಿಂದಲೂ ಬಿಡುಗಡೆ ಪಡೆಯುತ್ತಾರೆ ಮತ್ತು ಯಾವ ಪಾಪಿಗಳು ತಮಗೋಸ್ಕರವಾಗಿಯೇ ಶರೀರ ಪೋಷಣೆಗೆ ಅಡುಗೆ ಮಾಡಿಕೊಳ್ಳುತ್ತಾರೋ ಅವರು ಪಾಪವನ್ನೇ ತಿನ್ನುತ್ತಾರೆ. 3.13",

      "ಜೀವಿಗಳೆಲ್ಲವೂ ಅನ್ನದಿಂದ ಉತ್ಪತ್ತಿಯಾಗುತ್ತವೆ. ಅನ್ನದ ಉತ್ಪತ್ತಿಯು ಮಳೆಯಿಂದಾಗುತ್ತದೆ. ಮಳೆಯು ಯಜ್ಞದಿಂದ ಉಂಟಾಗುತ್ತದೆ. ಆ ಯಜ್ಞವು ಕರ್ಮಗಳಿಂದ ಉತ್ಪತ್ತಿಯಾಗುತ್ತದೆ.ಆ ಕರ್ಮವನ್ನು ನೀನು ವೇದದಿಂದ ಉತ್ಪತ್ತಿಯಾಯಿತೆಂದು ತಿಳಿದುಕೊ. ವೇದವು ಅವಿನಾಶೀ ಪರಮಾತ್ಮನಿಂದ ಉತ್ಪತ್ತಿಯಾಗಿದೆ. ಆದುದರಿಂದ, ಸರ್ವವ್ಯಾಪೀ ಪರಮಾತ್ಮ ಯಾವಗಲೂ ಯಜ್ಞದಲ್ಲಿ ಪ್ರತಿಷ್ಠಿತನಾಗಿರುತ್ತಾನೆ. (3.14-3.15)",

      "ಜೀವಿಗಳೆಲ್ಲವೂ ಅನ್ನದಿಂದ ಉತ್ಪತ್ತಿಯಾಗುತ್ತವೆ. ಅನ್ನದ ಉತ್ಪತ್ತಿಯು ಮಳೆಯಿಂದಾಗುತ್ತದೆ. ಮಳೆಯು ಯಜ್ಞದಿಂದ ಉಂಟಾಗುತ್ತದೆ. ಆ ಯಜ್ಞವು ಕರ್ಮಗಳಿಂದ ಉತ್ಪತ್ತಿಯಾಗುತ್ತದೆ.ಆ ಕರ್ಮವನ್ನು ನೀನು ವೇದದಿಂದ ಉತ್ಪತ್ತಿಯಾಯಿತೆಂದು ತಿಳಿದುಕೊ. ವೇದವು ಅವಿನಾಶೀ ಪರಮಾತ್ಮನಿಂದ ಉತ್ಪತ್ತಿಯಾಗಿದೆ. ಆದುದರಿಂದ, ಸರ್ವವ್ಯಾಪೀ ಪರಮಾತ್ಮ ಯಾವಗಲೂ ಯಜ್ಞದಲ್ಲಿ ಪ್ರತಿಷ್ಠಿತನಾಗಿರುತ್ತಾನೆ. (3.14-3.15)",

      "ಹೇ ಪಾರ್ಥ, ಯಾರು ಈ ಲೋಕದಲ್ಲಿ ಈ ಪ್ರಕಾರ ಪ್ರವರ್ತಿಸಲ್ಪಟ್ಟ ಸೃಷ್ಟಿ ಚಕ್ರವನ್ನು ಅನುಸರಿಸಿ ನಡೆದುಕೊಳ್ಳವುದಿಲ್ಲವೋ, ಅಂದರೆ ತನ್ನ ಕರ್ತವ್ಯ ಕರ್ಮಗಳನ್ನು ಮಾಡುವುದಿಲ್ಲವೋ, ಆ ಇಂದ್ರಿಯ ಸುಖಗಳನ್ನು ಅನುಭವಿಸುವ ಪಾಪಾಯುವಾದ ಪುರುಷನು ವ್ಯರ್ಥವಾಗಿಯೇ ಜೀವಿಸುತ್ತಾನೆ. 3.16",

      "ಆದರೆ ಯಾವ ಮನುಷ್ಯನು ಆತ್ಮನಲ್ಲಿಯೇ ಪ್ರೀತಿಯುಳ್ಳವನಾಗಿ, ಆತ್ಮನಲ್ಲಿಯೇ ತೃಪ್ತನಾಗಿ ಹಾಗು ಆತ್ಮನಲ್ಲಿಯೇ ಸಂತುಷ್ಟನಾಗಿರುವನೋ ಅವನಿಗೆ ಯಾವ ಕರ್ತವ್ಯವೂ ಇರುವುದಿಲ್ಲ. 3.17",

      "ಈ ಪ್ರಪಂಚದಲ್ಲಿ ಆ ಮಹಾಪುರುಷರಿಗೆ ಕರ್ಮ ಮಾಡುವುದರಿಂದಾಗಲೀ, ಮಾಡದೇ ಇರುವುರಿಂದಾಗಲೀ, ಯಾವ ಪ್ರಯೋಜನವೂ ಇಲ್ಲ ಮತ್ತು ಅಂತಹವರಿಗೆ ಎಲ್ಲ ಜೀವಿಗಳೊಡನೆ ಸ್ವಲ್ಪವೂ ಸ್ವಾರ್ಥ ಸಂಬಂಧವಿರುವುದಿಲ್ಲ. ಆದರೂ ಸಹ ಅವರಿಂದ ಕೇವಲ ಲೋಕಹಿತಾರ್ಥವಾಗಿ ಕರ್ಮವು ಮಾಡಲ್ಪಡುತ್ತದೆ. 3.18",

      "ಆದುದರಿಂದ ನೀನು ಅನಾಸಕ್ತನಾಗಿ ಯಾವಾಗಲೂ ಕರ್ತವ್ಯ ಕರ್ಮಗಳನ್ನು ಚೆನ್ನಾಗಿ ಆಚರಿಸು. ಏಕೆಂದರೆ, ಅನಾಸಕ್ತನಾದ ಪುರುಷನು ಕರ್ಮವನ್ನು ಆಚರಿಸುತ್ತಾ ಪರಮಾತ್ಮನ ಸಾಕ್ಷಾತ್ಕಾರವನ್ನು ಪಡೆಯುತ್ತಾನೆ. 3.19",

      "ಜನಕಾದಿ ಜ್ಞಾನಿಗಳೂ ಸಹ ಆಸಕ್ತಿಯಿಲ್ಲದ ಕರ್ಮದ ಆಚರಣೆಯಿಂದ ಪರಮ ಸಿದ್ಧಿಯನ್ನು ಪಡೆದಿದ್ದಾರೆ. ಆದುದರಿಂದ ಲೋಕಹಿತದ ದೃಷ್ಟಿಯಿಂದಲಾದರೂ ಸಹ ನೀನು ಕರ್ಮವನ್ನು ಮಾಡಲು ಯೋಗ್ಯನಾಗಿರುವೆ. 3.20",

      "ಶ್ರೇಷ್ಠ ಪುರುಷರು ಯಾವ ಯಾವ ಆಚರಣೆಗಳನ್ನು ಮಾಡುತ್ತಾರೋ, ಇತರ ಪುರುಷರೂ ಕೂಡ ಹಾಗೆ ಹಾಗೆಯೇ ಆಚರಣೆಗಳನ್ನು ಮಾಡುತ್ತಾರೆ. ಅವರು ಯಾವುದನ್ನು ಪ್ರಮಾಣವೆಂದು ಮಾಡಿತೋರಿಸುತ್ತಾರೋ ಸಮಸ್ತ ಮನುಷ್ಯ ಸಮುದಾಯವು ಅದಕ್ಕನುಸಾರವಾಗಿಯೇ ವರ್ತಿಸಲು ತೊಡಗುತ್ತದೆ. 3.21",

      "ಹೇ ಅರ್ಜುನನೇ ! ನನಗೆ ಈ ಮೂರೂ ಲೋಕಗಳಲ್ಲಿ ಯಾವುದೇ ಕರ್ತವ್ಯವು ಇಲ್ಲ ಮತ್ತು ಪಡೆಯಲು ಯೋಗ್ಯವಾದ ಯಾವುದೇ ವಸ್ತುವು ಅಪ್ರಾಪ್ತವಾಗಿದೆ ಎಂಬುದಿಲ್ಲ. ಆದರೂ ಸಹ ನಾನು ಕರ್ಮದಲ್ಲಿಯೇ ವರ್ತಿಸುತ್ತಿರುತ್ತೇನೆ. 3.22",

      "ಏಕೆಂದರೇ ಹೇ ಪಾರ್ಥನೇ ! ಒಂದು ವೇಳೆ ಯವಾಗಲಾದರೂ ನಾನು  ಎಚ್ಚರವಾಗಿದ್ದುಕೊಂಡು ಕರ್ಮಗಳಲ್ಲಿ ವರ್ತಿಸದಿದ್ದರೆ ದೊಡ್ಡ ಹಾನಿಯುಂಟಾಗುವುದು; ಏಕೆಂದರೆ ಮನುಷ್ಯರು ಎಲ್ಲ ಪ್ರಕಾರದಿಂದ ನನ್ನ ಮಾರ್ಗವನ್ನೇ ಅನುಸರಿಸುತ್ತಾರೆ. 3.23",

      "ಅದಕ್ಕಾಗಿ ಒಂದು ವೇಳೆ ನಾನು ಕರ್ಮವನ್ನು ಮಾಡದಿದ್ದರೆ ಈ ಎಲ್ಲ ಮನುಷ್ಯರೂ ನಷ್ಟ- ಭ್ರಷ್ಟರಾಗಿ ಬಿಡುವರು, ಮತ್ತು ನಾನು ವರ್ಣಸಂಕರತೆಯನ್ನು ಉಂಟುಮಾಡುವವನಾಗುವೆನು’ ಹಾಗೂ ಸಮಸ್ತ ಪ್ರಜೆಗಳನ್ನು ನಾಶ ಮಾಡುವವನಾಗುವೆನು. 3.24",

      "ಹೇ ಭಾರತ ! ( ಅರ್ಜುನ) ಕರ್ಮದಲ್ಲಿ ಆಸಕ್ತರಾದ ಅಜ್ಞಾನೀ ಜನರು ಯಾವ ಪ್ರಕಾರ ಕರ್ಮವನ್ನು ಮಾಡುತ್ತರೋ, ಆಸಕ್ತಿರಹಿತನಾದ ವಿದ್ವಾಂಸನೂ ಕೂಡ ಲೋಕಸಂಗ್ರಹವನ್ನು ಮಾಡಲಿಚ್ಚಿಸುತ್ತಾ ಅದೇ ಪ್ರಕಾರ ಕರ್ಮವನ್ನು ಮಾಡಲಿ. 3.25",

      "ಪರಮಾತ್ಮನ ಸ್ವರೂಪದಲ್ಲಿ ಅಚಲವಾಗಿ ಸ್ಥಿತರಾದ ಜ್ಞಾನೀ ಪುರುಷರು - ಶಾಸ್ತ್ರವಿಹಿತವಾದ ಕರ್ಮಗಳಲ್ಲಿ ಆಸಕ್ತಿಉಉಳ್ಳ ಅಜ್ಞಾನಿಗಳ ಬುದ್ಧಿಯಲ್ಲಿ ಭ್ರಮೆಯನ್ನು, ಅರ್ಥಾತ್, ಕರ್ಮಗಳಲ್ಲಿ ಅಶ್ರದ್ಧೆಯನ್ನು ಉಂಟುಮಾಡಬಾರದು. ಆದರೆ ಸ್ವಯಂ ಶಾಸ್ತ್ರವಿಹಿತ ಸಮಸ್ತ ಕರ್ಮಗಳನ್ನು ಚೆನ್ನಾಗಿ ಮಾಡುತ್ತಾ ಅವರಿಂದಲೂ ಅದೇ ಪ್ರಕಾರವಾಗಿ ಮಾಡಿಸಬೇಕು. 3.26",

      "ವಾಸ್ತವವಾಗಿ ಸಮಸ್ತ ಕರ್ಮಗಳು ಎಲ್ಲ ಪ್ರಕಾರದಿಂದಲೂ ಪ್ರಕೃತಿಯ ಗುಣಗಳ ಮೂಲಕವೇ ಮಾಡಲ್ಪಡುತ್ತವೆ. ಆದರೂ ಕೂಡ ಯಾರ ಅಂತಃಕರಣವು ಅಹಂಕಾರದಿಂದ ಮೋಹಿತವಾಗಿದೆಯೋ ಅಂತಹ ಅಜ್ಞಾನಿಯು ನಾನು ಕರ್ತೃವಾಗಿದ್ದೇನೆ ಎಂದು ತಿಳಿಯುತ್ತಾನೆ. 3.27",

      "ಆದರೆ ಹೇ  ಮಹಾಬಾಹುವೇ ! ಗುಣವಿಭಾಗ ಮತ್ತು ಕರ್ಮವಿಭಾಗದ ತತ್ವವನ್ನು ಬಲ್ಲ ಜ್ಞಾನಯೋಗಿಯು ಸಂಪೂರ್ಣವಾಗಿ ಗುಣಗಳೇ ಗುಣಗಳಲ್ಲಿ ವರ್ತಿಸುತ್ತಿವೆಯೆಂದು ತಿಳಿದುಕೊಂಡು ಅವುಗಳಲ್ಲಿ ಆಸಕ್ತನಾಗುವುದಿಲ್ಲ. 3.28",

      "ಪ್ರಕೃತಿಯ ಗುಣಗಳಿಂದ ಅತ್ಯಂತ ಮೋಹಿತರಾದ ಮನುಷ್ಯರು ಗುಣಗಳಲ್ಲಿ ಮತ್ತು ಕರ್ಮಗಳಲ್ಲಿ ಆಸಕ್ತರಾಗಿರುತ್ತಾರೆ. ಪೂರ್ಣವಾಗಿ ತಿಳಿಯದಿರುವ ಮಂದಬುದ್ಧಿಯುಳ್ಳ ಆ ಅಜ್ಞಾನಿಗಳನ್ನು ಪೂರ್ಣವಾಗಿ ತಿಳಿದ ಜ್ಞಾನಿಯು ವಿಚಲಿತಗೊಳಿಸಬಾರದು. 3.29",

      "ಅಂತರ್ಯಾಮಿ ಪರಮಾತ್ಮನಾದ ನನ್ನಲ್ಲಿ ತೊಡಗಿಸಿರುವ ಚಿತ್ತದ ಮೂಲಕ ಸಂಪೂರ್ಣ ಕರ್ಮಗಳನ್ನು ನನ್ನಲ್ಲಿ ಅರ್ಪಿಸಿ, ಆಶಾರಹಿತನೂ, ಮಮತಾರಹಿತನೂ ಮತ್ತು ಸಂತಾಪರಹಿತನೂ ಆಗಿ ಯುದ್ಧಮಾಡು. 3.30",

      "ಯಾವ ಮನುಷ್ಯರು ದೋಷದೃಷ್ಟಿರಹಿತರಾಗಿ ಮತ್ತು ಶ್ರದ್ಧಾಯುಕ್ತರಾಗಿ ನನ್ನ ಈ ಸಿದ್ದಾಂತವನ್ನು ಸದಾ ಅನುಸರಣೆ ಮಾಡುತ್ತಾರೋ ಅವರೂ ಕೂಡ ಸಂಪೂರ್ಣ ಕರ್ಮಗಳಿಂದ ಬಿಡುಗಡೆಯನ್ನು ಹೊಂದುತ್ತಾರೆ. 3.31",

      "ಆದರೆ ಯಾವ ಮನುಷ್ಯರು ನನ್ನಲ್ಲಿ ದೋಷಾರೋಪಣೆ ಮಾಡುತ್ತಾ ನನ್ನ ಈ ಸಿದ್ಧಾಂತಕ್ಕನುಸಾರವಾಗಿ ನಡೆಯುವುದಿಲ್ಲವೋ ಆ ಮೂರ್ಖರನ್ನು ನೀನು ಸಮಸ್ತ ಜ್ಞಾನಗಳಲ್ಲಿ ಮೋಹಿತರು ಮತ್ತು ನಷ್ಟಹೊಂದಿದವರೆಂದು ತಿಳಿದುಕೋ. 3.32",

      "ಎಲ್ಲ ಪ್ರಾಣಿಗಳು ಪ್ರಕೃತಿಯನ್ನು ಪಡೆಯುತ್ತವೆ ಅರ್ಥಾತ್ ತಮ್ಮ ಸ್ವಭಾವಕ್ಕೆ ಪರವಶವಾಗಿ ಕರ್ಮಮಾಡುತ್ತವೆ, ಜ್ಞಾನಿಯೂ ಸಹ ತನ್ನ ಪ್ರಕೃತಿಗೆ ಅನುಸಾರವಾಗಿ ಕ್ರಿಯೆಗಳನ್ನು ಮಾಡುತ್ತಾನೆ. ಮತ್ತೆ ಇದರಲ್ಲಿ ಯಾರ ಹಠವು ಏನು ಮಾಡಬಲ್ಲದು ? 3.33",

      "ಇಂದ್ರಿಯ- ಇಂದ್ರಿಯದ ಅರ್ಥದಲ್ಲಿ ಅರ್ಥಾತ್ ಪ್ರತಿಯೊಂದು ಇಂದ್ರಿಯದ ವಿಷಯದಲ್ಲಿ ರಾಗ ಮತ್ತು ದ್ವೇಷಗಳು ಅಡಗಿದ್ದು ಸ್ಥಿತವಾಗಿರುತ್ತವೆ, ಮನುಷ್ಯನು ಅವೆರಡರ ವಶನಾಗಬಾರದು. ಏಕೆಂದರೆ ಅವೆರಡೇ ಅವನ ಶ್ರೇಯಸ್ಸಿನ ಮಾರ್ಗದಲ್ಲಿ ವಿಘ್ನವನ್ನುಂಟುಮಾಡುವ ಮಹಾ ಶತ್ರುಗಳಾಗಿವೆ 3.34",

      "ಒಳ್ಳೆಯ ಪ್ರಕಾರದಿಂದ ಆಚರಣೆಗೆ ತರಲಾಗಿರುವ ಇನ್ನೊಬ್ಬರ ಧರ್ಮಕ್ಕಿಂತ ಗುಣರಹಿತವಾದರೂ ತನ್ನ ಧರ್ಮವು ಅತಿ ಉತ್ತಮವಾಗಿದೆ. ತನ್ನ ಧರ್ಮದಲ್ಲಾದರೋ ಸಾಯುವುದೂ ಕೂಡ ಶ್ರೇಯಸ್ಕರವಾಗಿದೆ ಮತ್ತು ಬೇರೆಯವರ ಧರ್ಮವು ಭಯವನ್ನುಂಟುಮಾಡುವುದಾಗಿದೆ. 3.35",

      "ಅರ್ಜುನನು ಹೇಳಿದನು - ಹೇ ಕೃಷ್ಣಾ ! ಹಾಗಾದರೆ ಈ ಮನುಷ್ಯನು ಸ್ವತಃ ಇಚ್ಚಿಸದಿದ್ದರೂ ಕೂಡ ಬಲವಂತವಾಗಿ ತೊಡಗಿಸಲ್ಪಟ್ಟವನಂತೆ ಯಾವುದರಿಂದ ಪ್ರೇರಿತನಾಗಿ ಪಾಪದ ಆಚರಣೆಯನ್ನು ಮಾಡುತ್ತಾನೆ ? 3.36",

      "ಭಗವಂತನು ಹೇಳಿದನು - ರಜೋಗುಣದಿಂದ ಉತ್ಪನ್ನವಾದ ಈ ಕಾಮವೇ ಕ್ರೋಧವಾಗಿದೆ. ಇದು ಬಹಳ ತಿನ್ನುವವನು ಅರ್ಥಾತ್ ಭೋಗಗಳಿಂದ ಬೇಸರ ಪಡದಿರುವವನು ಮತ್ತು ಮಹಾಪಾಪಿಯಾಗಿದೆ. ಇದನ್ನೇ ನೀನು ಈ ವಿಷಯದಲ್ಲಿ ವೈರಿಯೆಂದು ತಿಳಿ. 3.37",

      "ಯಾವ ಪ್ರಕಾರವಾಗಿ ಹೊಗೆಯಿಂದ ಅಗ್ನಿಯು ಮತ್ತು ಕೊಳೆಯಿಂದ ಕನ್ನಡಿಯು ಮುಚ್ಚಲ್ಪಡುತ್ತವೆಯೋ ಹಾಗೂ ಯಾವ ಪ್ರಕಾರವಾಗಿ ಜರಾಯು ಎಂಬ ಪೊರೆಯಿಂದ ಗರ್ಭವು ಮುಚ್ಚಲ್ಪಡುತ್ತದೆಯೋ ಹಾಗೆಯೇ ಆ ಕಾಮದ ಮೂಲಕ ಈ ಜ್ಞಾನವು ಮುಚ್ಚಲ್ಫಟ್ಟಿರುತ್ತದೆ. 3.38",

      "ಮತ್ತು ಹೇ ಅರ್ಜುನನೇ ! ಇದು ಅಗ್ನಿಗೆ ಸಮಾನವಾಗಿ ಎಂದಿಗೂ ಪೂರ್ಣವಾಗದಿರುವ, ಕಾಮರೂಪೀ ಜ್ಞಾನಿಗಳ ನಿತ್ಯವೈರಿಯ ಮೂಲಕ ಮನುಷ್ಯನ ಜ್ಞಾನವು ಮುಚ್ಚಲ್ಪಟ್ಟಿದೆ. 3.39",

      "ಇಂದ್ರಿಯಗಳು, ಮನಸ್ಸು ಮತ್ತು ಬುದ್ಧಿ - ಇವೆಲ್ಲ ಇದರ ವಾಸಸ್ಥಾನವೆಂದು ಹೇಳಲ್ಪಡುತ್ತವೆ. ಈ ಕಾಮವು ಮನಸ್ಸು, ಬುದ್ದಿ ಮತ್ತು ಇಂದ್ರಿಯಗಳ ಮೂಲಕವೇ ಜ್ಞಾನವನ್ನು ಆವರಿಸಿ ಜೀವಾತ್ಮನನ್ನು ಮೋಹಗೊಳಿಸುತ್ತದೆ. 3.40",

      "ಆದಕಾರಣ ಹೇ ಅರ್ಜುನನೇ ! ನೀನು ಮೊದಲು ಇಂದ್ರಿಯಗಳನ್ನು ವಶಪಡಿಸಿಕೊಂಡು ಈ ಜ್ಞಾನ ಮತ್ತು ವಿಜ್ಞಾನವನ್ನು ನಾಶಪಡಿಸುವ ಮಹಾಪಾಪಿಯಾದ ಕಾಮವನ್ನು ಅವಶ್ಯವಾಗಿ, ಬಲಪೂರ್ವಕವಾಗಿ ಕೊಂದುಬಿಡು. 3.41",

      "ಇಂದ್ರಿಯಗಳು ಸ್ಥೂಲಶರೀರಕ್ಕಿಂತ, ಶ್ರೇಷ್ಠ ಬಲಶಾಲಿ ಮತ್ತು ಸೂಕ್ಷ್ಮವೆಂದು ಹೇಳುತ್ತಾರೆ; ಈ ಇಂದ್ರಿಯಗಳಿಗಿಂತ ಶ್ರೇಷ್ಠವದುದು ಮನಸ್ಸು, ಮನಸ್ಸಿಗಿಂತಲೂ ಶ್ರೇಷ್ಠವದುದು ಬುದ್ಧಿಯಾಗಿದೆ ಮತ್ತು ಯಾವುದು ಬುದ್ಧಿಗಿಂತಲೂ ಕೂಡ ಅತ್ಯಂತ  ಶ್ರೇಷ್ಠವಾಗಿದೆಯೋ ಅದೇ ಆತ್ಮವಾಗಿದೆ. 3.42",

      "ಈ ಪ್ರಕಾರವಾಗಿ ಬುದ್ಧಿಗಿಂತ ಪರ ಅರ್ಥಾತ್ ಸೂಕ್ಷ್ಮವೂ, ಬಲಶಾಲಿಯೂ ಮತ್ತು ಅತ್ಯಂತ ಶ್ರೇಷ್ಠವಾದ ಆತ್ಮವನ್ನು ಅರಿತುಕೊಂಡು ಮತ್ತು ಬುದ್ಧಿಯ ಮೂಲಕ ಮನಸ್ಸನ್ನು ವಶಪಡಿಸಿಕೊಂಡು ಹೇ ಮಹಾಬಾಹುವೇ ! ನೀನು ಈ ಕಾಮರೂಪೀ ದುರ್ಜಯವಾದ ಶತ್ರುವನ್ನು ಕೊಂದು ಹಾಕು. 3.43",

      "ಇತಿ ಶ್ರೀಮದ್ಭಗವದ್ಗೀತಾರೂಪೀ ಉಪನಿಷದ್ ಬ್ರಹ್ಮವಿದ್ಯಾ ಹಾಗೂ ಯೋಗಶಾಸ್ತ್ರ ವಿಷಯವೆಂಬ ಶ್ರೀಕೃಷ್ಣ ಮತ್ತು ಅರ್ಜುನರ ಸಂವಾದದಲ್ಲಿ ಕರ್ಮಯೋಗ ಎಂಬ ಮೂರನೇ ಅಧ್ಯಾಯ ಸಂಪೂರ್ಣ"
    ],
    "adhyaya-4" : [
      "ನಾನು ಈ ಅವಿನಾಶಿಯಾದ ಯೋಗವನ್ನು ಕಲ್ಪದ ಆದಿಯಲ್ಲಿ ಸೂರ್ಯನಿಗೆ ಹೇಳಿದೆನು ಮತ್ತು ಸೂರ್ಯನು ತನ್ನ ಮಗ ಮನುವಿಗೆ ಹೇಳಿದನು ಮತ್ತು ಮನು ತನ್ನ ಮಗ ರಾಜಾ ಇಕ್ಷ್ವಾಕುವಿಗೆ ಹೇಳಿದನು. 4.1",

      "ಈ ರೀತಿ ಪರಂಪರೆಯಿಂದ ಬಂದಿರುವ ಈ ಯೋಗವನ್ನು ರಾಜರ್ಷಿಗಳು ತಿಳಿದುಕೊಂಡಿದ್ದರು. ಆದರೆ ಎಲೈ ಅರ್ಜುನ ! ಆ ಯೋಗವು ಬಹಳಕಾಲದಿಂದ ಈ ಭೂಲೋಕದಲ್ಲಿ ಪ್ರಾಯಶಃ ನಶಿಸಿ ಹೋಗಿತ್ತು. 4.2",

      "ಅದೇ ಈ ಪುರಾತನವಾದ ಯೋಗವನ್ನು ಈಗ ನಾನು ನಿನಗೋಸ್ಕರವಾಗಿ ವರ್ಣಿಸಿದ್ದೇನೆ, ಏಕೆಂದರೆ ನೀನು ನನ್ನ ಭಕ್ತನೂ ಮತ್ತು ಪ್ರಿಯ ಸ್ನೇಹಿತನೂ ಆಗಿರುವುದರಿಂದ ಹಾಗೂ ಈ ಯೋಗ ಬಹಳ ಉತ್ತಮ ಮತ್ತು ರಹಸ್ಯ ಅರ್ಥಾತ್ ಮಾರ್ಮಿಕವಾದ ವಿಷಯವಾಗಿದೆ. 4.3",

      "ನಿನ್ನ ಜನ್ಮವಾದರೋ ಇತ್ತಿಚಿನದು ಮತ್ತು ಸೂರ್ಯನ ಜನ್ಮವು ಬಹಳ ಪುರಾತನವಾದದ್ದು ಅರ್ಥಾತ್ ಕಲ್ಪದ ಆದಿಯಲ್ಲಿ ಆದದ್ದು. ಆದುದರಿಂದ ಈ ಯೋಗವನ್ನು ಕಲ್ಪದ ಆದಿಯಲ್ಲಿ ಹೇಳಿದ್ದೆ ಎಂದು ನಾನು ಹೇಗೆ ತಿಳಿದುಕೊಳ್ಳಲಿ ? 4.4",

      "ಶ್ರೀಕೃಷ್ಣ ಹೇಳಿದನು - ಎಲೈ ಅರ್ಜುನ ! ನನ್ನ ಮತ್ತು ನಿನ್ನ ಅನೇಕ ಜನ್ಮಗಳು ಆಗಿ ಹೋಗಿವೆ. ಆದರೆ ಎಲೈ ಅರ್ಜುನ ! ಅವುಗಳನ್ನೆಲ್ಲಾ ನೀನು ತಿಳಿಯಲಾರೆ ಮತ್ತು ನಾನು ತಿಳಿದಿರುತ್ತೇನೆ. 4.5",

      "ನಾನು ಅವಿನಾಶೀ ಸ್ವರೂಪಿ, ಜನ್ಮರಹಿತ, ಆದಾಗ್ಯೂ ಸಹ ಹಾಗೂ ಸಮಸ್ತ ಪ್ರಾಣಿಗಳಿಗೂ ಒಡೆಯನಾದರೂ, ನನ್ನ ಪ್ರಕೃತಿಯನ್ನು ಅಧೀನಮಾಡಿಕೊಂಡು ನನ್ನ ಯೋಗಮಾಯೆಯಿಂದ ಅವತರಿಸುತ್ತೇನೆ. 4.6",

      "ಎಲೈ ಅರ್ಜುನ ! ಧರ್ಮಕ್ಕೆ ಚ್ಯುತಿ ಉಂಟಾದಾಗಲೆಲ್ಲಾ ಮತ್ತು ಅಧರ್ಮವು ಹೆಚ್ಚಿದಾಗಲೆಲ್ಲಾ ನಾನು ನನ್ನ ರೂಪವನ್ನು ರಚಿಸಿಕೊಳ್ಳುತ್ತೇನೆ ಅರ್ಥಾತ್ ಅವತಾರ ತಾಳುತ್ತೇನೆ. ಸಾಕಾರ ರೂಪದಿಂದ ಎಲ್ಲರಿಗೂ ಗೋಚರಿಸುತ್ತೇನೆ. 4.7",

      "ಸಾಧು ಸತ್ಫುರುಷರನ್ನು ಉದ್ಧಾರ ಮಾಡುವುದಕ್ಕಾಗಿಯೂ, ದುಷ್ಕರ್ಮಿಗಳನ್ನು ನಾಶ ಮಾಡುವುದಕ್ಕಾಗಿಯೂ ಮತ್ತು ಧರ್ಮವನ್ನು ಸಂಸ್ಥಾಪನೆ ಮಾಡುವುದಕ್ಕಾಗಿಯೂ ಯುಗ-ಯುಗಗಳಲ್ಲಿ ಅವತಾರ ತಾಳುತ್ತೇನೆ. 4.8",

      "ಎಲೈ ಅರ್ಜುನ ! ನನ್ನ ಈ ಜನ್ಮ ಮತ್ತು ಕರ್ಮದಿವ್ಯ ಅರ್ಥಾತ್ ಅಲೌಕಿಕವಾಗಿವೆ ಎಂಬುದಾಗಿ ಯಾವ ಪುರುಷನು ಯಥಾರ್ಥವಾಗಿ ತಿಳಿದುಕೊಳ್ಳುತ್ತಾನೆಯೋ ಅವನು ಶರೀರವನ್ನು ತ್ಯಜಿಸಿದ ಮೇಲೆ ಪುನಃ ಜನ್ಮವನ್ನು ಪಡೆಯುವುದಿಲ್ಲ ಆದರೆ ನನ್ನನ್ನೇ ಪಡೆಯುತ್ತಾನೆ. 4.9",

      "ಆಸಕ್ತಿ, ಭಯ ಮತ್ತು ಕ್ರೋಧಗಳಿಲ್ಲದೆ ಅನನ್ಯಭಾವದಿಂದ ನನ್ನಲ್ಲಿಯೇ ದೃಢವಾದ ಮನಸುಳ್ಳವರಾಗಿದ್ದು ನನಗೆ ಶರಣಾಗತರಾದ ಅನೇಕ ಪುರುಷರು ಜ್ಞಾನರೂಪೀ ತಪಸ್ಸಿನಿಂದ ಪವಿತ್ರರಾಗಿ ನನ್ನ ಸ್ವರೂಪವನ್ನು ಪಡೆದು ಕೊಂಡಿದ್ದಾರೆ. 4.10",

      "ಎಲೈ ಅರ್ಜುನ ! ಯಾರು ನನ್ನನ್ನು ಹೇಗೆ ಭಜಿಸುತ್ತಾರೆಯೋ, ನಾನೂ ಸಹ ಅವರನ್ನು ಅದೇ ರೀತಿ ಭಜಿಸುತ್ತೇನೆ. ಈ ರಹಸ್ಯವನ್ನು ತಿಳಿದುಕೊಂಡ ಬುದ್ಧಿವಂತರಾದ ಮಾನವ ಸಮೂಹವು ಎಲ್ಲಾ ರೀತಿಯಿಂದಲೂ ನನ್ನ ಮಾರ್ಗಕ್ಕೆ ಅನುಗುಣವಾಗಿ ನಡೆದುಕೊಳ್ಳುತ್ತಾರೆ. (4.11-4.12)",

      "ಎಲೈ ಅರ್ಜುನ ! ಯಾರು ನನ್ನನ್ನು ಹೇಗೆ ಭಜಿಸುತ್ತಾರೆಯೋ, ನಾನೂ ಸಹ ಅವರನ್ನು ಅದೇ ರೀತಿ ಭಜಿಸುತ್ತೇನೆ. ಈ ರಹಸ್ಯವನ್ನು ತಿಳಿದುಕೊಂಡ ಬುದ್ಧಿವಂತರಾದ ಮಾನವ ಸಮೂಹವು ಎಲ್ಲಾ ರೀತಿಯಿಂದಲೂ ನನ್ನ ಮಾರ್ಗಕ್ಕೆ ಅನುಗುಣವಾಗಿ ನಡೆದುಕೊಳ್ಳುತ್ತಾರೆ. (4.11-4.12)",

      "ಗುಣ ಮತ್ತು ಕರ್ಮಗಳ ವಿಭಾಗದಿಂದ ಬ್ರಾಹ್ಮಣ, ಕ್ಷತ್ರಿಯ, ವೈಶ್ಯ ಮತ್ತು ಶೂದ್ರ ಎಂದು ನನ್ನ ಮೂಲಕ ರಚಿಸಲ್ಫಟ್ಟಿವೆ. ಅವುಗಳ ಕರ್ತೃವಾದರೂ ಸಹ ಅವಿನಾಶೀ ಪರಮೇಶ್ವರನಾದ ನನ್ನನ್ನು ನೀನು ವಾಸ್ತವವಾಗಿಯೂ ಕರ್ತೃವಲ್ಲವೆಂದೇ ತಿಳಿದುಕೋ. 4.13",

      "ಕರ್ಮಗಳ ಫಲದಲ್ಲಿ ನನಗೆ ಇಚ್ಛೆಯಿರುವುದಿಲ್ಲ, ಆದುದರಿಂದ ನನ್ನನ್ನು ಕರ್ಮಗಳು ಅಂಟಿಕೊಳ್ಳುವುದಿಲ್ಲ ಎಂದು ಯಾರು ನನ್ನನ್ನು ಯಥಾರ್ಥವಾಗಿ ತಿಳಿದುಕೊಳ್ಳುತ್ತಾನೆಯೋ ಅವನೂ ಸಹ ಕರ್ಮಗಳಿಂದ ಬಂಧಿಸಲ್ಫಡುವುದಿಲ್ಲ. 4.14",

      "ಈ ರೀತಿ ತಿಳಿದುಕೊಂಡೇ ಪೂರ್ವಕಾಲದವರಾದ ಮುಮುಕ್ಷು ಪುರುಷರಿಂದಲೂ ಸಹ ಕರ್ಮ ಮಾಡಲ್ಪಟ್ಟಿದೆ. ಆದುದರಿಂದ ನೀನೂ ಸಹ ಪೂರ್ವಜರಿಂದ ನಿರಂತರ ಮಾಡಲ್ಪಟ್ಟ ಕರ್ಮವನ್ನೇ ಮಾಡು. 4.15",

      "ಕರ್ಮ ಯಾವುದು ? ಅಕರ್ಮ ಯಾವುದು ? ಹೇಗೆ ಈ ವಿಷಯಗಳಲ್ಲಿ ಕುಶಲಮತಿಗಳೂ ಸಹ ಭ್ರಮೆಗೊಳಗಾಗಿದ್ದಾರೆ. ಆದುದರಿಂದ ನಾನು ಆ ಕರ್ಮ ಅರ್ಥಾತ್ ಕರ್ಮಗಳ ತತ್ವವನ್ನು ನಿನಗೋಸ್ಕರ ಚೆನ್ನಾಗಿ ಹೇಳುವೆನು. ಅದನ್ನು ತಿಳಿದುಕೊಂಡು ನೀನು ಅಶುಭ ಅರ್ಥಾತ್ ಕರ್ಮಬಂಧನದಿಂದ ಬಿಡುಗಡೆ ಹೊಂದುವೆ. 4.16",

      "ಕರ್ಮದ ಸ್ವರೂಪವನ್ನೂ, ಅಕರ್ಮದ ಸ್ವರೂಪವನ್ನೂ ಮತ್ತು ವಿಕರ್ಮ ಅರ್ಥಾತ್ ನಿಷಿದ್ಧ ಕರ್ಮಗಳ ಸ್ವರೂಪವನ್ನೂ ಸಹ ತಿಳಿದುಕೊಳ್ಳಬೇಕು. ಏಕೆಂದರೆ ಕರ್ಮದ ಗತಿಯು ಗಹನವಾಗಿದೆ. 4.17",

      "ಯಾವ ಪುರುಷನು ಕರ್ಮದಲ್ಲಿ ಅರ್ಥಾತ್ ಅಹಂಕಾರವಿಲ್ಲದೆ ಮಾಡಲ್ಪಟ್ಟ ಎಲ್ಲಾ ಪ್ರಯತ್ನಗಳಲ್ಲಿ  ಮತ್ತು ಅಕರ್ಮ ಅರ್ಥಾತ್ ವಾಸ್ತವವಾಗಿ ಅವುಗಳ ಕರ್ತೃತ್ವದ ಅಭಾವವನ್ನು ನೋಡುತ್ತನೆಯೋ ಹಾಗೂ ಯಾವ ಪುರುಷನು ಅಕರ್ಮದಲ್ಲಿ ಅರ್ಥಾತ್ ಅಜ್ಞಾನೀ ಪುರುಷರಿಂದ ಮಾಡಲ್ಪಟ್ಟ ಎಲ್ಲಾ ಕ್ರಿಯೆಗಳ ತ್ಯಾಗದಲ್ಲಿಯೂ ಸಹ ಕರ್ಮವನ್ನು ಅರ್ಥಾತ್ ತ್ಯಾಗರೂಪೀ ಕ್ರಿಯೆಯನ್ನು ನೋಡುವನೋ ಆ ಪುರುಷನು ಮನುಷ್ಯರಲ್ಲಿ ಬುದ್ಧಿವಂತನು ಮತ್ತು ಅವನು ಯೋಗಿಯು ಎಲ್ಲಾ ಕರ್ಮಗಳನ್ನೂ ಮಾಡುವವನು. 4.18",

      "ಯಾರ ಕರ್ಮಗಳೆಲ್ಲಾ ಸಂಪೂರ್ಣವಾಗಿ ಶಾಸ್ತ್ರಸಮ್ಮತವಾಗಿದ್ದು ಆಸೆ ಆಕಾಂಕ್ಷೆ ಮತ್ತು ಸಂಕಲ್ಪಗಳಿಂದ ರಹಿತವಾಗಿರುತ್ತವೆಯೋ ಅಂತಹ ಆ ಜ್ಞಾನರೂಪೀ ಅಗ್ನಿಯಿಂದ ಅವನ ಕರ್ಮಗಳೆಲ್ಲಾ ಭಸ್ಮವಾಗಿರುವ ಪುರುಷನನ್ನು ಜ್ಞಾನಿಗಳೂ ಸಹ ಪಂಡಿತ ಎಂದು ಹೇಳುತ್ತಾರೆ. 4.19",

      "ಯಾವ ಪುರುಷನು ಸಂಸಾರದ ಸಂಬಂಧವಿಲ್ಲದೆ ಸದಾ ಪರಮಾನಂದ ಪರಮಾತ್ಮನಲ್ಲಿಯೇ ನಿತ್ಯತೃಪ್ತನಾಗಿರುವವನು ಕರ್ಮ ಮತ್ತು ಅದರ ಫಲದ ಆಸಕ್ತಿಯನ್ನು ತ್ಯಜಿಸಿ ಕರ್ಮದಲ್ಲಿ ಚೆನ್ನಾಗಿ ನಿರತನಾಗಿದ್ದರೂ ಸಹ ವಾಸ್ತವದಲ್ಲಿ ಏನನೂ ಮಾಡುವುದಿಲ್ಲ. 4.20",

      "ಅಂತಃಕರಣ ಮತ್ತು ಇಂದ್ರಿಯಗಳ ಸಹಿತ ಶರೀರವನ್ನು ಜಯಿಸಿರುವವನು ಹಾಗೂ ಎಲ್ಲಾ ಭೋಗವಸ್ತುಗಳನ್ನೂ ತ್ಯಜಿಸಿರುವವನೂ ಅಂತಹ ಆಸೆ ಆಕಾಂಕ್ಷೆಗಳನ್ನು ಬಿಟ್ಟವನು ಕೇವಲ ಶರೀರ ನಿರ್ವಹಣೆಗಾಗಿ ಕರ್ಮವನ್ನು ಮಾಡುತ್ತಾ ಇದ್ದರೂ ಸಹ ಪಾಪವನ್ನು ಪಡೆಯುವ ದೋಷಕ್ಕೆ ಗುರಿಯಾಗುವುದಿಲ್ಲ. 4.21",

      "ತಾನಾಗಿಯೇ ಏನು ದೊರೆಯುತ್ತದೆಯೋ ಅದರಲ್ಲಿಯೇ ಸಂತುಷ್ಟನಾಗಿರುವವನು ಮತ್ತು ಹರ್ಷಶೋಕಾದಿ ದ್ವಂದ್ವಗಳಿಂದ ದೂರಾದವನು ಹಾಗೂ ಮತ್ಸರ ಅರ್ಥಾತ್ ಅಸೂಯೆಯಿಲ್ಲದವನು ಮತು ಸಿದ್ಧಿ ಹಾಗೂ ಅಸಿದ್ಧಿಯಲ್ಲಿ ಸಮಾನ ಭಾವನೆಯುಳ್ಳ ಕರ್ಮಯೋಗಿಯು ಕರ್ಮಗಳನ್ನು ಮಾಡಿದರೂ ಸಹ ಬಂಧಿತನಾಗುವುದಿಲ್ಲ. 4.22",

      "ಆಸಕ್ತಿ ಇಲ್ಲದವನು, ದೇಹಾಭಿಮಾನ ಮತ್ತು ಮಮತೆ ಇಲ್ಲದವನು ನಿರಂತರ ಪರಮಾತ್ಮನ ಜ್ಞಾನದಲ್ಲಿ ಸ್ಥಿರವಾದ ಮನಸ್ಸುಳ್ಳವನು ಈ ರೀತಿ ಕೇವಲ ಯಜ್ಞಕ್ಕಾಗಿಯೇ ಆಚರಣೆ ಮಾಡುತ್ತಾ ಇರುವವನ ಎಲ್ಲಾ ಕರ್ಮಗಳೂ ಚೆನ್ನಾಗಿ ಲೀನಗೊಳ್ಳುತ್ತವೆ. 4.23",

      "ಅರ್ಪಣವು ಅರ್ಥಾತ್ ಸ್ರುವ ( ತುಪ್ಪದ ಪಾತ್ರೆ ಹಾಗೂ ಉದ್ಧರಣೆ ಇತ್ಯಾದಿಗಳು ) ಸಹ ಬ್ರಹ್ಮ ಮತ್ತು ಹವಿಸ್ಸು ಅರ್ಥಾತ್ ಹವನ ಮಾಡುವ ಯೋಗ್ಯ ದ್ರವ್ಯಗಲೂ ಸಹ ಬ್ರಹ್ಮ ಹಾಗೂ ಬ್ರಹ್ಮರೂಪೀ ಅಗ್ನಿಯಲ್ಲಿ ಬ್ರಹ್ಮರೂಪೀ ಕರ್ತೃವಿನಿಂದ ಯಾವುದು ಹೋಮ ಮಾಡಲಾಗಿದೆಯೋ ಅದೂ ಸಹ ಬ್ರಹ್ಮವೇ ಆದುದರಿಂದ ಬ್ರಹ್ಮರೂಪೀ ಕರ್ಮದಲ್ಲಿ ಸಮಾಧಿಸ್ಥನಾಗಿರುವ ಆ ಪುರುಷನಿಂದ ಯಾವುದು ಪಡೆಯಲು ಯೋಗ್ಯಫಲವಾಗಿದೆಯೋ ಅದೂ ಸಹ ಬ್ರಹ್ಮವೇ ಆಗಿದೆ. 4.24",

      "ಕೆಲವು ಯೋಗಿಗಳು ದೇವತೆಗಳ ಉಪಾಸನಾ ರೂಪೀ ಯಜ್ಞವನ್ನೇ ಚೆನ್ನಾಗಿ ಅನುಷ್ಠಾನ ಮಾಡುತ್ತಾರೆ ಮತ್ತೆ ಕೆಲವರು ಜ್ಞಾನಿಗಳಾದವರು ಪರಬ್ರಹ್ಮ ಪರಮಾತ್ಮ ರೂಪೀ ಅಗ್ನಿಯಲ್ಲಿ ಯಜ್ಞದ ಮೂಲಕವೇ ಯಜ್ಞವನ್ನು ಹೋಮ ಮಾಡುತ್ತಾರೆ. 4.25",

      "ಇನ್ನು ಬೇರೇ ಯೋಗಿಗಳು ಶ್ರೋತ್ರಾದಿಗಳಾದ ಇಂದ್ರಿಯಗಳನ್ನೆಲ್ಲಾ ಸಂಯಮ ಅರ್ಥಾತ್ ಸ್ವಾಧೀನತಾರೂಪೀ ಅಗ್ನಿಯಲ್ಲಿ ಹೋಮ ಮಾಡುತ್ತಾರೆ. ಅಂದರೆ ಇಂದ್ರಿಯಗಳನ್ನು ವಿಷಯಗಳಿಂದ ತಡೆದು ತಮ್ಮ ವಶಮಾಡಿಕೊಳ್ಳುತ್ತಾರೆ. ಮತ್ತೆ ಹಲವಾರು ಯೋಗಿಗಳು ಶಬ್ದಾದಿ ವಿಷಯಗಳನ್ನು ಇಂದ್ರಿಯರೂಪೀ ಅಗ್ನಿಯಲ್ಲಿ ಹೋಮ ಮಾಡುತ್ತಾರೆ ಅರ್ಥಾತ್ ರಾಗ -ದ್ವೇಷ ರಹಿತವಾದ ಇಂದ್ರಿಯಗಳ ಮೂಲಕ ವಿಷಯಗಳನ್ನು ಗ್ರಹಿಸುತ್ತಿದ್ದರೂ ಸಹ ಭಸ್ಮರೂಪ ಮಾಡುತ್ತಾರೆ. 4.26",

      "ಬೇರೇ ಯೋಗಿಗಳು ಸಂಪೂರ್ಣವಾಗಿ ಇಂದ್ರಿಯಗಳ ಯತ್ನಗಳನ್ನು ಹಾಗೂ ಪ್ರಾಣದ ಸಮಸ್ತ ಕ್ರಿಯೆಯಳನ್ನು ಜ್ಞಾನದಿಂದ ಪ್ರಕಾಶಗೊಂಡ ಆತ್ಮ ಸಂಯಮರೂಪೀ ಯೋಗಾಗ್ನಿಯಲ್ಲಿ ಹೋಮ ಮಾಡುತ್ತಾರೆ. 4.27",

      "ಮತ್ತೆ ಹಲವರು ಈಶ್ವರಾರ್ಪಣ ಬುದ್ಧಿಯಿಂದ ಲೋಕೋದ್ದಾರಕ್ಕಾಗಿ ದ್ರವ್ಯಗಳನ್ನು ತೊಡಗಿಸುತ್ತಾರೆ, ಅದರಂತೆಯೇ ಅನೇಕರು ಸ್ವಧರ್ಮಪಾಲನಾರೂಪೀ ತಪೋಯಜ್ಞವನ್ನು ಮಾಡುವುದುಂಟು, ಇನ್ನು ಕೆಲವರು ಅಹಿಂಸಾದಿ ಕಠಿಣ ವ್ರತಗಳಿಂದ ಕೂಡಿದ ಪ್ರಯತ್ನಶೀಲ ವ್ಯಕ್ತಿಗಳು ಭಗವಂತನ ನಾಮ-ಜಪ ಹಾಗೂ ಭಗವತ್ಸಾಕ್ಷಾತ್ಕಾರದ ವಿಷಯಗಳ ಶಾಸ್ತ್ರಾಧ್ಯಯನ ರೂಪೀ ಜ್ಞಾನಯಜ್ಞ ಮಾಡುವುದುಂಟು. 4.28",

      "ಬೇರೆ ಯೋಗಿಗಳು - ಅಪಾನವಾಯುವಿನಲ್ಲಿ ಪ್ರಾಣವಾಯುವನ್ನು ಹೋಮ ಮಾಡುತ್ತಾರೆ. ಅದರಂತೆಯೇ ಮತ್ತೆ ಬೇರೆ ಯೋಗಿಗಳು ಪ್ರಾಣವಾಯುವಿನಲ್ಲಿ ಅಪಾನವಾಯುವನ್ನು ಹೋಮ ಮಾಡುತ್ತಾರೆ ಹಾಗೂ ಬೇರೆ ಯೋಗಿಗಳು ನಿಯಮಿತ ಆಹಾರ ಸೇವನೆ ಮಾಡುವ ಪ್ರಾಣಾಯಾಮ ಪರಾಯಣ ಪುರುಷರು ಪ್ರಾಣ ಮತ್ತು ಅಪಾನಗಳ ಗತಿಯನ್ನು ತಡೆದು ಪ್ರಾಣವನ್ನು ಪ್ರಾಣದಲ್ಲಿಯೇ ಹೋಮ ಮಾಡುತ್ತಾರೆ. ಈ ಪ್ರಕಾರ ಯಜ್ಞಗಳ ಮೂಲಕ ಯಾರ ಪಾಪಗಳು ನಾಶವಾಗಿ ಹೋಗಿವೆಯೋ ಅಂತಹವರೆಲ್ಲರೂ ಸಹ ಯಜ್ಞಗಳನ್ನು ತಿಳಿದವರಾಗಿರುತ್ತಾರೆ. (4.29-4.30)",

      "ಬೇರೆ ಯೋಗಿಗಳು - ಅಪಾನವಾಯುವಿನಲ್ಲಿ ಪ್ರಾಣವಾಯುವನ್ನು ಹೋಮ ಮಾಡುತ್ತಾರೆ. ಅದರಂತೆಯೇ ಮತ್ತೆ ಬೇರೆ ಯೋಗಿಗಳು ಪ್ರಾಣವಾಯುವಿನಲ್ಲಿ ಅಪಾನವಾಯುವನ್ನು ಹೋಮ ಮಾಡುತ್ತಾರೆ ಹಾಗೂ ಬೇರೆ ಯೋಗಿಗಳು ನಿಯಮಿತ ಆಹಾರ ಸೇವನೆ ಮಾಡುವ ಪ್ರಾಣಾಯಾಮ ಪರಾಯಣ ಪುರುಷರು ಪ್ರಾಣ ಮತ್ತು ಅಪಾನಗಳ ಗತಿಯನ್ನು ತಡೆದು ಪ್ರಾಣವನ್ನು ಪ್ರಾಣದಲ್ಲಿಯೇ ಹೋಮ ಮಾಡುತ್ತಾರೆ. ಈ ಪ್ರಕಾರ ಯಜ್ಞಗಳ ಮೂಲಕ ಯಾರ ಪಾಪಗಳು ನಾಶವಾಗಿ ಹೋಗಿವೆಯೋ ಅಂತಹವರೆಲ್ಲರೂ ಸಹ ಯಜ್ಞಗಳನ್ನು ತಿಳಿದವರಾಗಿರುತ್ತಾರೆ. (4.29-4.30)",

      "ಎಲೈ ಕುರುಶ್ರೇಷ್ಠನಾದ ಅರ್ಜುನ ! ಯಜ್ಞಗಳ ಪರಿಣಾಮರೂಪೀ ಜ್ಞಾನಾಮೃತವನ್ನು ಸವಿಯುವ ಯೋಗಿಗಳು ಸನಾತನವಾದ ಪರಬ್ರಹ್ಮ ಪರಮಾತ್ಮನನ್ನು ಪಡೆಯುತ್ತಾರೆ ಮತ್ತು ಯಜ್ಞವನ್ನು ಮಾಡದವರಿಗೆ ಈ ಮಾನವ ಲೋಕವೂ ಸಹ ಸುಖದಾಯಕವಾಗುವುದಿಲ್ಲ ಅಂದ ಮೇಲೆ ಪರಲೋಕವು ಹೇಗೆ ತಾನೆ ಸುಖಕರವಾಗಬಲ್ಲದು. 4.31",

      "ಹೀಗೆ ಅನೇಕ ಪ್ರಕಾರದ ಯಜ್ಞಗಳು ವೇದದ ವಾಣಿಯಲ್ಲಿ ವಿಸ್ತಾರವಾಗಿ ವಿವರಿಸಲ್ಪಟ್ಟಿವೆ. ಅವುಗಳೆಲ್ಲವನ್ನೂ ಶರೀರ, ಮನಸ್ಸು ಮತ್ತು ಇಂದ್ರಿಯಗಳ ಕ್ರಿಯೆಯ ಮೂಲಕವೇ ಉತ್ಪತ್ತಿಯಾದವು ಎಂದು ತಿಳಿ. ಈ ರೀತಿ ಯಥಾರ್ಥವಾಗಿ ತಿಳಿದುಕೊಂಡು ನಿಷ್ಕಾಮಕರ್ಮಯೋಗದ ಮೂಲಕ ಸಂಸಾರ ಬಂಧನದಿಂದ ಮುಕ್ತನಾಗಿ ಹೋಗುವೆ. 4.32",

      "ಎಲೈ ಅರ್ಜುನ ! ಸಾಂಸಾರಿಕ ವಸ್ತುಗಳಿಂದ ಸಿದ್ಧಿಸುವಂತಹ ಯಜ್ಞಕ್ಕಿಂತ ಜ್ಞಾನರೂಪೀ ಯಜ್ಞವು ಎಲ್ಲಾ ರೀತಿಯಿಂದಲೂ ಶ್ರೇಷ್ಠ. ಏಕೆಂದರೆ ಎಲೈ ಪಾರ್ಥ ! ಎಲ್ಲಾ ಸರ್ವಾಂಗೀಣ ಕರ್ಮಗಳೂ ಜ್ಞಾನದಲ್ಲಿ ಪರಿಸಮಾಪ್ತಿಯನ್ನು ಹೊಂದುತ್ತವೆ ಅರ್ಥಾತ್ ಜ್ಞಾನವು ಅವುಗಳ ಪರಾಕಾಷ್ಠೆಯಾಗಿದೆ. 4.33",

      "ಸಾಷ್ಟಾಂಗ ನಮಸ್ಕಾರ ಮಾಡಿ ಹಾಗೂ ಸೇವೆ ಮಾಡಿ ಒಳ್ಳೆಯ ಭಾವನೆಯಿಂದ ಪ್ರಶ್ನೆ ಮಾಡಿ ಆ ಜ್ಞಾನವನ್ನು ತಿಳಿದುಕೊ. ಆ ಗೂಡಾರ್ಥವನ್ನು ತಿಳಿದುಕೊಂಡಿರುವ ಜ್ಞಾನಿಗಳು ನಿನಗೆ ಆ ಜ್ಞಾನದ ಉಪದೇಶ ಮಾಡುವರು. 4.34",

      "ಯಾವುದನ್ನು ತಿಳಿದುಕೊಂಡರೆ ನೀನು ಪುನಃ ಈ ರೀತಿ ಮೋಹಕ್ಕೆ ಒಳಗಾಗುವುದಿಲ್ಲವೋ ಎಲೈ ಅರ್ಜುನ ! ಆ ಜ್ಞಾನದ ಮೂಲಕ ಸರ್ವವ್ಯಾಪೀ ಅನಂತ ಚೇತನ ರೂಪವಾದ ಆತ್ಮನಲ್ಲಿ ಅರ್ಥಾತ್ ನಿನ್ನ ಅಂತರ್ಗತವಾದ ಸಮಷ್ಟಿ ಬುದ್ಧಿಯಿಂದ ನಿನ್ನಲ್ಲೇ ಎಲ್ಲಾ ಜೀವಿಗಳನ್ನೂ ಕಾಣುವೆ. ಮತ್ತು ಅನಂತರ ನನ್ನಲ್ಲಿ ಅರ್ಥಾತ್ ಸಚ್ಚಿದಾನಂದ ಸ್ವರೂಪದಲ್ಲಿ ಒಂದೇ ಭಾವದಿಂದ ಸಚ್ಚಿದಾನಂದಮಯವನ್ನೇ ನೋಡುವೆ. 4.35",

      "ಆದರೆ, ನೀನು ಎಲ್ಲಾ ಪಾಪಿಗಳಿಗಿಂತಲೂ ಸಹ ಹೆಚ್ಚು ಪಾಪ ಮಾಡಿದವನೇ ಆಗಿದ್ದರೂ ಸಹ ಜ್ಞಾನರೂಪೀ ದೋಣಿಯ ಮೂಲಕ ನಿಃಸಂದೇಹವಾಗಿ ಸಮಸ್ತ ಪಾಪರೂಪೀ ಸಮುದ್ರವನ್ನು ಚೆನ್ನಾಗಿ ದಾಟಿ ಪಾರಾಗುವೆ. 4.36",

      "ಎಲೈ ಅರ್ಜುನ ! ಯಾವ ರೀತಿ ಪ್ರಜ್ವಲಿಸುವ ಅಗ್ನಿಯು ಉರುವಲನ್ನು ( ಸೌದೆ ) ಸುಟ್ಟು ಬೂದಿಯನ್ನಾಗಿ ಮಾಡಿಬಿಡುವುದೋ, ಅದರಂತೆಯೇ ಜ್ಞಾನರೂಪೀ ಅಗ್ನಿಯೂ ಸಕಲ ಕರ್ಮಗಳನ್ನು ಭಸ್ಮಮಯವಾಗಿ ಮಾಡಿ ಬಿಡುವುದು. 4.37",

      "ಈ ಜಗತ್ತಿನಲ್ಲಿ ಜ್ಞಾನಕ್ಕೆ ಸರಿಸಮಾನ ಪವಿತ್ರವಾದ ವಸ್ತು ನಿಸ್ಸಂದೇಹವಾಗಿಯೂ ಬೇರೆ ಯಾವುದಉ ಸಹ ಇರುವುದಿಲ್ಲ. ಆ ಜ್ಞಾನವನ್ನು ಕಾಲಾಂತರದಲ್ಲಿ ಸಾಧನೆಯ ಪರಿಪಕ್ವಾವಸ್ಥೆಯಿಂದ ತಾನೇ ಸ್ವತಃ ಸಮತ್ವ ಬುದ್ಧಿರೂಪೀ ಕರ್ಮಯೋಗದಿಂದ ಒಳ್ಳೆಯ ಶುದ್ಧಾಂತಃಕರಣವುಳ್ಳ ಪುರುಷನು ಆತ್ಮನಲ್ಲಿ ಅನುಭಬ ಪಡೆಯುತ್ತಾನೆ. 4.38",

      "ಜಿತೇಂದ್ರಿಯನೂ, ಸಾಧನ ಪರಾಯಣನೂ ಮತ್ತು ಶ್ರದ್ಧಾವಂತನೂ ಆದವನು ಜ್ಞಾನವನ್ನು ಪಡೆಯುತ್ತಾನೆ. ಜ್ಞಾನವನ್ನು ಪಡೆದುಕೊಂಡ ಕೂಡಲೇ ಭಗವತ್ಸಾಕ್ಷಾತ್ಕಾರವೆಂಬ ಪರಮ ಶಾಂತಿಯನ್ನು ಪಡೆದುಕೊಳ್ಳುತ್ತಾನೆ. 4.39",

      "ಭಗವದ್ವಿಷಯಗಳನ್ನು ತಿಳಿಯದ ಅಜ್ಞಾನಿ ಹಾಗೂ ಶ್ರದ್ಧೆಯಿಲ್ಲದವನೂ ಹಾಗೂ ಸಂಶಯ ಸ್ವಭಾವದವನೂ ಪಾರಮಾರ್ಥಿಕವಾಗಿ ಭ್ರಷ್ಟನಾಗುತ್ತಾನೆ. ಅಂತಹ ಸಂಶಯ ಪಡುವ ಪುರುಷನಿಗಾದರೋ ಸುಖವು ಇಲ್ಲ, ಈ ಲೋಕವೂ ಇಲ್ಲ ಮತ್ತು ಪರಲೋಕವೂ ಇಲ್ಲ ಅರ್ಥಾತ್ ಇಹ-ಪರ ಎರಡೂ ಕಡೆಗಳಲ್ಲಿಯೂ ಭ್ರಷ್ಟನಾಗಿ ಹೋಗುತ್ತಾನೆ. 4.40",

      "ಎಲೈ ಧನಂಜಯ ! ಯಾರು ಕರ್ಮಗಳನ್ನೆಲ್ಲಾ ಸಮತ್ವ ಬುದ್ಧುರೂಪೀ ಯೋಗದಿಂದ ಭಗವದರ್ಪಣ ಮಾಡಿದ್ದಾರೆಯೋ ಮತ್ತು ಯಾರ ಸಂಶಯಗಳೆಲ್ಲಾ ಜ್ಞಾನದ ಮೂಲಕ ನಾಶವಗಿ ಹೋಗಿವೆಯೋ ಅಂತಹ ಜಿತೇಂದ್ರಿಯ ಪುರುಷನನ್ನು ಕರ್ಮಗಳು ಬಂಧಿಸಲಾರವು. 4.41",

      "ಆದುದರಿಂದ, ಎಲೈ ಭರತವಂಶಜನಾದ ಅರ್ಜುನ! ನೀನು ಸಮತ್ವ ಬುದ್ಧಿರೂಪೀ ಕರ್ಮಯೋಗದಲ್ಲಿ ಏಕಾಗ್ರತೆಯುಳ್ಳವನಾಗು ಮತ್ತು ಅಜ್ಞಾನದಿಂದ ಉಂಟಾಗಿರುವ ಹಾಗೂ ಮನಸ್ಸಿನಲ್ಲಿ ಅಡಗಿರುವ ಈ ನಿನ್ನ ಸಂಶಯವನ್ನು ಜ್ಞಾನರೂಪೀ ಕತ್ತಿಯಿಂದ ಕತ್ತರಿಸಿ ಯುದ್ಧಮಾಡಲು ಸಿದ್ಧನಾಗಿ ನಿಲ್ಲು. 4.42",

      "ಇತಿ ಶ್ರೀಮದ್ಭಗವದ್ಗೀತಾರೂಪೀ ಉಪನಿಷದ್ ಬ್ರಹ್ಮವಿದ್ಯಾ ಹಾಗೂ ಯೋಗಶಾಸ್ತ್ರ ವಿಷಯ ಕುರಿತು ಶ್ರೀ ಕೃಷ್ಣ ಮತ್ತು ಅರ್ಜುನರ ಸಂವಾದದಲ್ಲಿ ಜ್ಞಾನಕರ್ಮಸಂನ್ಯಾಸಯೋಗ ಎಂಬ ನಾಲ್ಕನೇ ಅಧ್ಯಾಯ ಸಂಪೂರ್ಣ"
    ],
    "adhyaya-5" : [
      "ಅರ್ಜುನನು ಹೇಳಿದನು - ಹೇ ಕೃಷ್ಣಾ ನೀನು ಕರ್ಮಗಳ ಸಂನ್ಯಾಸವನ್ನೂ ಮತ್ತೆ ಪುನಃ ಕರ್ಮಯೋಗವನ್ನೂ ಪ್ರಶಂಸೆ ಮಾಡುತ್ತಿರುವೆ. ಆದ್ದರಿಂದ ಇವೆರಡರಲ್ಲಿ ಯಾವುದಾದರೊಂದು ಚೆನ್ನಾದ, ನಿಶ್ಚಿತವಾದ ಶ್ರೇಯಸ್ಕರವಾದ ಸಾಧನವಾಗಿದೆಯೋ ಅದನ್ನು ನನಗೆ ಹೇಳು. 5.1",

      "ಶ್ರೀ ಭಗವಂತನು ಹೇಳಿದನು - ಕರ್ಮಸಂನ್ಯಾದ ಮತ್ತು ಕರ್ಮಯೋಗ - ಇವೆರಡೂ ಪರಮಕಲ್ಯಾಣಕರವಾಗಿವೆ, ಆದರೂ ಅವೆರಡರಲ್ಲಿಯೂ ಕೂಡ ಕರ್ಮಸಂನ್ಯಾಸಕ್ಕಿಂತ ಕರ್ಮಯೋಗವು, ಸಾಧನೆಯಲ್ಲಿ ಸುಗಮವಾಗಿರುವುದರಿಂದ ಶ್ರೇಷ್ಠವಾಗಿದೆ. 5.2",

      "ಹೇ ಅರ್ಜುನನೇ ! ಯಾವ ಪುರುಷನು ಯಾರನ್ನೂ ದ್ವೇಷಿಸುವುದಿಲ್ಲವೋ ಮತ್ತು ಯಾವುದನ್ನೂ ಬಯಸುವುದಿಲ್ಲವೋ, ಆ ಕರ್ಮಯೋಗಿಯು ಸದಾ ಸಂನ್ಯಾಸಿಯೆಂದೇ ತಿಳಿಯಲು ಯೋಗ್ಯನಾಗಿದ್ದಾನೆ, ಏಕೆಂದರೆ ರಾಗ-ದ್ವೇಷಾದೆ ದ್ವಂದ್ವಗಳಿದ ರಹಿತನಾದ ಪುರುಷನು ಸುಖಪೂರ್ವಕವಾಗಿ ಸಂಸಾರ ಬಂಧನದಿಂದ ಮುಕ್ತನಾಗಿ ಹೋಗುತ್ತಾನೆ. 5.3",

      "ಮೇಲೆ ಹೇಳಿದ ಸಂನ್ಯಾಸವನ್ನು ಮತ್ತು ಕರ್ಮಯೋಗವನ್ನು ಮೂರ್ಖಜನರು ಬೇರೆ-ಬೇರೆಯಾದ ಫಲಗಳನ್ನು ಕೊಡುವುವು ಎಂದು ಹೇಳುತ್ತಾರೆ, ಪಂಡಿತರಾದವರು ಹೇಳುವುದಿಲ್ಲ ; ಏಕೆಂದರೆ, ಎರಡರ ಪೈಕಿ ಒಂದರಲ್ಲಿಯಾದರೂ ಸಮ್ಯಕ್ ಪ್ರಕಾರದಿಂದ ಸ್ಥಿತನಾದ ಪುರುಷನು ಎರಡರ ಫಲರೂಪೀ ಪರಮಾತ್ಮನನ್ನು ಪಡೆಯುತ್ತಾನೆ. 5.4",

      "ಜ್ಞಾನಯೋಗಿಗಳ ಮೂಲಕ ಯಾವ ಪರಮಧಾಮವು ಪಡೆಯಲಾಗುತ್ತದೋ, ಕರ್ಮಯೋಗಿಗಳ ಮೂಲಕವೂ ಸಹ ಅದೇ ಪಡೆಯಲಾಗುತ್ತದೆ. ಅದಕ್ಕಾಗಿ ಯಾವ ಪುರುಷನು ಜ್ಞಾನಯೋಗ ಮತ್ತು ಕರ್ಮಯೋಗವನ್ನು, ಫಲರೂಪದಲ್ಲಿ ಒಂದೆಂದು ನೋಡುತ್ತಾನೋ ಅವನೇ ಯಥಾರ್ಥವಾಗಿ ನೋಡುತ್ತಾನೆ. 5.5",

      "ಆದರೆ ಹೇ ಅರ್ಜುನಾ ! ಕರ್ಮಯೋಗವಿಲ್ಲದೆ ಸಂನ್ಯಾಸ ಅರ್ಥಾತ್ - ಮನಸ್ಸು, ಇಂದ್ರಿಯಗಳು ಮತ್ತು ಶರೀರದ ಮೂಲಕ ನಡೆಯುವ ಸಂಪೂರ್ಣ ಕರ್ಮಗಳಲ್ಲಿ ಕರ್ತೃತ್ವದ ತ್ಯಾಗವು ಪ್ರಾಪ್ತವಾಗುವುದು ಕಠಿಣವಾಗಿದೆ ಮತ್ತು ಭಗವಂತನ ಸ್ವರೂಪವನ್ನು ಮನನ ಮಾಡುವ ಕರ್ಮಯೋಗಿಯು ಪರಬ್ರಹ್ಮ ಪರಮಾತ್ಮನನ್ನು ಶೀಘ್ರವಾಗಿಯೇ ಪಡೆಯುತ್ತಾನೆ. 5.6",

      "ಯಾರ ಮನಸ್ಸು ತನ್ನ ವಶದಲ್ಲಿದೆಯೋ ಯಾರು ಜಿತೇಂದ್ರಿಯನೋ ಹಾಗೂ ವಿಶುದ್ಧವಾದ ಅಂತಃಕರಣವುಳ್ಳವನೋ ಮತ್ತು ಸಮಸ್ತ ಪ್ರಾಣಿಗಳ ಆತ್ಮರೂಪೀ ಪರಮಾತ್ಮನೇ ಯಾರ ಆತ್ಮವಾಗಿದ್ದಾನೋ ಅಂತಹ ಕರ್ಮ ಯೋಗಿಯು ಕರ್ಮ ಮಾಡುತ್ತಿದ್ದರೂ ಕೂಡ ಲಿಪ್ತನಾಗುವುದಿಲ್ಲ. 5.7",

      "ತತ್ವವನ್ನು ಅರಿತಿರುವ ಸಾಂಖ್ಯಯೋಗಿಯಾದರೋ ನೋಡುವಾಗಲೂ, ಕೇಳುವಾಗಲೂ, ಮುಟ್ಟುವಾಗಲೂ, ಮೂಸುವಾಗಲೂ, ಭೋಜನ ಮಾಡುವಾಗಲೂ, ನಡೆಯುವಾಗಲೂ, ನಿದ್ರಿಸುವಾಗಲೂ, ಉಸಿರಾಡುವಾಗಲೂ, ಮಾತನಾಡುವಾಗಲೂ, ತ್ಯಾಗಮಾಡುವಾಗಲೂ, ತೆಗೆದುಕೊಳ್ಳುವಾಗಲೂ ಹಾಗೂ ಕಣ್ಣುಗಳನ್ನು ತೆರೆಯುವಾಗಲೂ ಮತ್ತು ಮುಚ್ಚಿಕೊಳ್ಳುವಾಗಲೂ ಕೂಡ ಎಲ್ಲ ಇಂದ್ರಿಯಗಳು ತಮ್ಮ-ತಮ್ಮ ಅರ್ಥದಲ್ಲಿ ವರ್ತಿಸುತ್ತಿರುತ್ತವೆ - ಈ ಪ್ರಕಾರವಾಗಿ ತಿಳಿದುಕೊಂಡು ನಿಸ್ಸಂದೇಹವಾಗಿ ನಾನು ಏನನ್ನೂಮಾಡುವುದಿಲ್ಲ ಎಂಬುದಾಗಿ ತಿಳಿಯಬೇಕು.  (5.8-5.9)",

      "ತತ್ವವನ್ನು ಅರಿತಿರುವ ಸಾಂಖ್ಯಯೋಗಿಯಾದರೋ ನೋಡುವಾಗಲೂ, ಕೇಳುವಾಗಲೂ, ಮುಟ್ಟುವಾಗಲೂ, ಮೂಸುವಾಗಲೂ, ಭೋಜನ ಮಾಡುವಾಗಲೂ, ನಡೆಯುವಾಗಲೂ, ನಿದ್ರಿಸುವಾಗಲೂ, ಉಸಿರಾಡುವಾಗಲೂ, ಮಾತನಾಡುವಾಗಲೂ, ತ್ಯಾಗಮಾಡುವಾಗಲೂ, ತೆಗೆದುಕೊಳ್ಳುವಾಗಲೂ ಹಾಗೂ ಕಣ್ಣುಗಳನ್ನು ತೆರೆಯುವಾಗಲೂ ಮತ್ತು ಮುಚ್ಚಿಕೊಳ್ಳುವಾಗಲೂ ಕೂಡ ಎಲ್ಲ ಇಂದ್ರಿಯಗಳು ತಮ್ಮ-ತಮ್ಮ ಅರ್ಥದಲ್ಲಿ ವರ್ತಿಸುತ್ತಿರುತ್ತವೆ - ಈ ಪ್ರಕಾರವಾಗಿ ತಿಳಿದುಕೊಂಡು ನಿಸ್ಸಂದೇಹವಾಗಿ ನಾನು ಏನನ್ನೂಮಾಡುವುದಿಲ್ಲ ಎಂಬುದಾಗಿ ತಿಳಿಯಬೇಕು.  (5.8-5.9)",

      "ಯಾವ ಪುರುಷನು ಎಲ್ಲ ಕರ್ಮಗಳನ್ನು ಪರಮಾತ್ಮನಲ್ಲಿ ಅರ್ಪಣೆ ಮಾಡಿ ಮತ್ತು ಆಸಕ್ತಿಯನ್ನು ತ್ಯಾಗಮಾಡಿ ಕರ್ಮವನ್ನು ಮಾಡುತ್ತಾನೋ ಆ ಪುರುಷನು ನೀರಿನಲ್ಲಿರುವ ಕಮಲದೆಲೆಯು ನೀರಿನಿಂದ ಲಿಪ್ತವಾಗುವುದಿಲ್ಲವೋ ಅದರಂತೆ ಪಾಪದಿಂದ ಲಿಪ್ತನಾಗುವುದಿಲ್ಲ. 5.10",

      "ಕರ್ಮಯೋಗಿಗಳು ಮಮತ್ವಬುದ್ಧಿರಹಿತರಾಗಿ ಕೇವಲ ಇಂದ್ರಿಯಗಳು, ಮನಸ್ಸು, ಬುದ್ಧಿ ಮತ್ತು ಶರೀರದ ಮೂಲಕ ಆಗುವ ಎಲ್ಲ ಕ್ರಿಯೆಗಳಲ್ಲಿ ಆಸಕ್ತಿಯನ್ನು ತ್ಯಾಗ ಮಾಡಿ ಅಂತಃಕರಣ ಶುದ್ಧಿಗಾಗಿ ಕರ್ಮವನ್ನು ಮಾಡುತ್ತಾರೆ. 5.11",

      "ಕರ್ಮಯೋಗಿಯು ಕರ್ಮಗಳ ಫಲಗಳನ್ನು ತ್ಯಾಗಮಾಡಿ ಭಗವತ್ಪ್ರಾಪ್ತಿ ರೂಪವಾದ ಶಾಂತಿಯನ್ನು ಪಡೆಯುತ್ತಾನೆ ಮತ್ತು ಸಕಾಮಪುರುಷನು ಕಾಮನೆಯ ಪ್ರೇರಣೆಯಿಂದ ಫಲದಲ್ಲಿ ಆಸಕ್ತನಾಗಿ ಬಂಧಿತನಾಗುತ್ತಾನೆ. 5.12",

      "ಅಂತಃಕರಣವು ಯಾರ ವಶದಲ್ಲಿದೆಯೋ ಅಂತಹ ಸಾಂಖ್ಯಯೋಗವನ್ನು ಆಚರಿಸುವ ಪುರುಷನು ಮಾಡದೇ ಮತ್ತು ಮಾಡಿಸದೇ ಇರುತ್ತಲೇ ನವದ್ವಾರಗಳುಳ್ಳ ಶರೀರರೂಪೀ ಪುರದಲ್ಲಿ ಎಲ್ಲ ಕರ್ಮಗಳನ್ನು ಮನಸ್ಸಿನಿಂದ ತ್ಯಜಿಸಿ, ಆನಂದಪೂರ್ವಕವಾಗಿ ಸಚ್ಚಿದಾನಂದಘನ ಪರಮಾತ್ಮನ ಸ್ವರೂಪದಲ್ಲಿ ಸ್ಥಿತನಾಗಿರುತ್ತಾನೆ. 5.13",

      "ಪರಮೇಶ್ವರನು ಮನುಷ್ಯರ ಕರ್ತೃತ್ವವಾಗಲೀ ಕರ್ಮಗಳಾಗಲೀ ಮತ್ತು ಕರ್ಮಫಲಸಂಯೋಗವಾಗಲೀ ರಚಿಸುವುದಿಲ್ಲ; ಆದರೆ ಪ್ರಕೃತಿಯೇ ವರ್ತಿಸುತ್ತಿದೆ ಅರ್ಥಾತ್ ಗುಣಗಳೇ  ಗುಣಗಳಲ್ಲಿ ವರ್ತಿಸುತ್ತಿವೆ. 5.14",

      "ಸರ್ವವ್ಯಾಪಿಯಾದ ಪರಮೇಶ್ವರನು ಯಾರ ಪಾಪಕರ್ಮವನ್ನಾಗಲೀ ಮತ್ತು ಶುಭಕರ್ಮವನ್ನಾಗಲೀ ಸ್ವೀಕರಿಸುವುದಿಲ್ಲ. ಆದರೆ ಅಜ್ಞಾನದ ಮೂಲಕ ಜ್ಞಾನವು ಮುಚ್ಚಲ್ಪಟ್ಟಿದೆ, ಅದರಿಂದಲೇ ಎಲ್ಲ ಅಜ್ಞಾನೀ ಮನುಷ್ಯರು ಮೋಹಿತರಾಗುತ್ತಿದ್ದಾರೆ. 5.15",

      "ಆದರೆ ಯಾರ ಆ ಅಜ್ಞಾನವು ಪರಮಾತ್ಮನ ತತ್ವಜ್ಞಾನದ ಮೂಲಕ ನಾಶಮಾಡಲ್ಪಟ್ಟಿದೆಯೋ ಅವನ ಆ ಜ್ಞಾನಬು ಸೂರ್ಯನಂತೆ ಆ ಸಚ್ಚಿದಾನಂದಘನನಾದ ಪರಮಾತ್ಮನನ್ನು ಪ್ರಕಾಶಗೊಳಿಸುತ್ತದೆ. 5.16",

      "ಯಾರ ಮನಸ್ಸು ಪರಮಾತ್ಮನಲ್ಲಿ ತದ್ರೂಪವಾಗಿದೆಯೋ, ಯಾರ ಬುದ್ದಿಯು ಪರಮಾತ್ಮನಲ್ಲಿ ತದ್ರೂಪವಾಗಿದೆಯೋ ಮತ್ತು ಸಚ್ಚಿದಾನಂದಘನ ಪರಮಾತ್ಮನಲ್ಲಿಯೇ ಯಾರಿಗೆ ನಿರಂತರವಾದ ಏಕೀಭಾವದ ಸ್ಥಿತಿಯಿದೆಯೋ ಅಂತಹ ಪರಮಾತ್ಮ ಪರಾಯಣರಾದ ಪುರುಷರು ಜ್ಞಾನದ ಮೂಲಕ ಪಾಪರಹಿತರಾಗಿ ಅಪುನರಾವೃತ್ತಿಯನ್ನು ಅರ್ಥಾತ್ ಪರಮ ಗತಿಯನ್ನು ಪಡೆಯುತ್ತಾರೆ. 5.17",

      "ಆ ಜ್ಞಾನಿಗಳಾದವರು ವಿದ್ಯಾ ಮತ್ತು ವಿನಯಯುಕ್ತರಾದ ಬ್ರಾಹ್ಮಣನಲ್ಲಿ ಹಾಗೂ ಹಸು, ಆನೆ, ನಾಯಿ ಮತ್ತು ಚಾಂಡಾಲನಲ್ಲಿಯೂ ಸಹ ಸಮದರ್ಶಿಗಳಾಗಿಯೇ ಇರುತ್ತಾರೆ. 5.18",

      "ಯಾರ ಮನಸ್ಸು ಸಮಭಾವದಲ್ಲಿ ಸ್ಥಿತವಾಗಿದೆಯೋ, ಅವರ ಮೂಲಕ ಈ ಜೀವಿತ ಅವಸ್ಥೆಯಲ್ಲಿಯೇ ಸಮಸ್ತ ಜಗತ್ತು ಗೆಲ್ಲಲ್ಫಟ್ಟಿರುತ್ತದೆ; ಏಕೆಂದರೆ ಸಚ್ಚಿದಾನಂದಘನ ಪರಮಾತ್ಮನು ನಿರ್ದೋಷನೂ ಮತ್ತು ಸಮನೂ ಆಗಿದ್ದಾನೆ. ಇದರಿಂದ ಅವರು ಸಚ್ಚಿದಾನಂದಘನನಾದ ಪರಾಮಾತ್ಮನಲ್ಲಿಯೇ ಸ್ಥಿತವಾಗಿರುತ್ತಾರೆ. 5.19",

      "ಯಾವ ಪುರುಷನು ಪ್ರಿಯವಾದುದನ್ನು ಪಡೆದರೂ ಹರ್ಷಿತನಾಗುವುದಿಲ್ಲವೋ ಮತ್ತು ಅಪ್ರಿಯವು ದೊರೆತಾಗಲೂ ಉದ್ವಿಗ್ನನಾಗುವುದಿಲ್ಲವೋ ಆ ಸ್ಥಿರಬುದ್ಧಿಯುಳ್ಳ, ಸಂಶಯರಹಿತನಾದ, ಬ್ರಹ್ಮವೇತ್ತನಾದ ಪುರುಷನು ಸಚ್ಚಿದಾನಂದಘನ ಪರಬ್ರಹ್ಮ ಪರಮಾತ್ಮನಲ್ಲಿ ಏಕೀಭಾವದಿಂದ ಸದಾ ಸ್ಥಿತನಾಗಿರುತ್ತಾನೆ. 5.20",

      "ಹೊರಗಿನ ವಿಷಯಗಳಲ್ಲಿ ಆಸಕ್ತಿರಹಿತವಾದ ಅಂತಃಕರಣವುಳ್ಳ ಸಾಧಕನು ಆತ್ಮನಲ್ಲಿ ಸ್ಥಿತವಾದ ಯಾವ ಧ್ಯಾನಜನಿತ ಸಾತ್ವಿಕ ಆನಂದವಿದೆಯೋ ಅದನ್ನು ಪಡೆಯುತ್ತಾನೆ. ಅನಂತರ ಆ ಸಚ್ಚಿದಾನಂದಘನನಾದ ಪರಬ್ರಹ್ಮ ಪರಮಾತ್ಮನ ಧ್ಯಾನರೂಪವಾದ ಯೋಗದಲ್ಲಿ ಅಭಿನ್ನಭಾವದಿಂದ ಸ್ಥಿತನಾದ ಪುರುಷನು ಅಕ್ಷಯವಾದ ಆನಂದವನ್ನು ಅನುಭವಿಸುತ್ತಾನೆ. 5.21",

      "ಈ ಇಂದ್ರಿಯ ಹಾಗೂ ವಿಷಯಗಳ ಸಂಯೋಗದಿಂದ ಉತ್ಪನ್ನವಾಗುವ ಯಾವ ಸಮಸ್ತ ಭೋಗಗಳಿವೆಯೋ ಅವು ಒಂದೊಮ್ಮೆ ವಿಷಯಿ - ಪುರುಷರಿಗೆ ಸುಖರೂಪವಾಗಿ ಭಾಸವಾಗುತ್ತವೆಯಾದರೂ ಸಹ ನಿಸ್ಸಂದೇಹವಾಗಿ ದುಃಖದ್ದೆ ಕಾರಣವಾಗಿವೆ ಮತ್ತು ಆದಿ - ಅಂತ್ಯವುಳ್ಳವುಗಳು ಅರ್ಥಾತ್ ಅನಿತ್ಯವಾಗಿವೆ. ಅದಕ್ಕಾಗಿ ಹೇ ಅರ್ಜುನಾ ! ಬುದ್ಧಿವಂತರಾದ, ವಿವೇಕಿ ಪುರುಷರು ಅವುಗಳಲ್ಲಿ ರಮಿಸುವುದಿಲ್ಲ. 5.22",

      "ಯಾವ ಸಾಧಕನು ಈ ಮನುಷ್ಯ ಶರೀರದಲ್ಲಿ ಶರೀರ ನಾಶವಾಗುವುದಕ್ಕೆ ಮೊದಲೇ ಕಾಮ - ಕ್ರೋಧಗಳಿಂದ ಉತ್ಪನ್ನವಾಗುವ ವೇಗವನ್ನು ಸಹಿಸುವಲ್ಲಿ ಸಮರ್ಥನಾಗುತ್ತಾನೆಯೋ ಆ ಪುರುಷನೇ ಯೋಗಿಯು, ಮತ್ತು ಅವನೇ ಸುಖಿಯಾಗಿದ್ದಾನೆ. 5.23",

      "ಯಾವ ಪುರುಷನು ಬ್ರಹ್ಮನಿಗಿಂತ ಬೇರೆಯಾವುದೂ ಇಲ್ಲವೇ ಇಲ್ಲವೆಂದು ನಿಶ್ಚಯಮಾಡಿ ಸಮಷ್ಟಿ ಅಂತರಾತ್ಮನಲ್ಲಿಯೇ ಸುಖವುಳ್ಳವನಾಗಿದ್ದಾನೋ, ಆತ್ಮನಲ್ಲಿಯೇ ಜ್ಞಾನವುಳ್ಳವನಾಗಿದ್ದಾನೋ ಅವನು ಸಚ್ಚಿದಾನಂದಘನನಾದ ಪರಬ್ರಹ್ಮ ಪರಮಾತ್ಮನ ಜೊತೆಯಲ್ಲಿ ಏಕೀಭಾವವನ್ನು ಪಡೆದ ಸಾಂಖ್ಯಯೋಗಿಯು ಶಾಂತಬ್ರಹ್ಮನನ್ನು ಪಡೆಯುತ್ತಾನೆ. 5.24",

      "ಯಾರ ಎಲ್ಲ ಪಾಪಗಳು ನಾಶವಾಗಿ ಹೋಗಿವೆಯೋ ಯಾರ ಸಂಶಯಗಳೆಲ್ಲ ಜ್ಞಾನದ ಮೂಲಕ ನಿವೃತ್ತಿ ಹೊಂದಿವೆಯೋ, ಯಾರು ಸಂಪೂರ್ಣ ಪ್ರಾಣಿಗಳ ಹಿತದಲ್ಲಿ ನಿರತರಾಗಿದ್ದಾರೋ ಮತ್ತು ಯಾರ ಮನಸ್ಸು ಗೆಲ್ಲಲ್ಪಟ್ಟು ನಿಶ್ಚಲಭಾವದಿಂದ ಪರಮಾತ್ಮನಲ್ಲಿ ಸ್ಥಿತವಾಗಿದೆಯೋ ಆ ಬ್ರಹ್ಮವೇತ್ತರಾದ ಪುರುಷರು ಶಾಂತಬ್ರಹ್ಮವನ್ನು ಪಡೆಯುತ್ತಾರೆ. 5.25",

      "ಕಾಮಕ್ರೋಧರಹಿತರೂ ಚಿತ್ತವನ್ನು ಗೆದ್ದಿರುವವರೂ, ಪರಬ್ರಹ್ಮಪರಮಾತ್ಮನ ಸಾಕ್ಷಾತ್ಕಾರ ಮಾಡಿಕೊಂಡವರೂ ಆದ ಜ್ಞಾನೀ ಪುರುಷರಿಗೆ ಎಲ್ಲ ಕಡೆಗಳಿಂದ ಶಾಂತ ಪರಬ್ರಹ್ಮ ಪರಮಾತ್ಮನೇ ಪರಿಪೂರ್ಣನಾಗಿದ್ದಾನೆ. 5.26",

      "ಹೊರಗಿನ ವಿಷಯ ಭೋಗಗಳನ್ನು ಚಿಂತಿಸದೆ ಇದ್ದು, ಹೊರಗೆಯೇ ತ್ಯಜಿಸಿ ಮತ್ತು ಕಣ್ಣುಗಳ ದೃಷ್ಟಿಯನ್ನು ಭ್ರೂ ಮಧ್ಯದಲ್ಲಿ ಸ್ಥಿರವಾಗಿಸಿ ಹಾಗೂ ಮೂಗಿನಲ್ಲಿ ಸಂಚರಿಸುವ ಪ್ರಾಣ ಮತ್ತು ಅಪಾನ ವಾಯುವನ್ನು ಸಮವಾಗಿಸಿ, ಯಾರ ಇಂದ್ರಿಯಗಳು, ಮನಸ್ಸು ಮತ್ತು ಬುದ್ಧಿ ಗೆಲ್ಲಲ್ಪಟ್ಟಿವೆಯೋ ಅಂತಹ ಯಾವ ಮೋಕ್ಷಪರಾಯಣನಾದ ಮುನಿಯು ಇಚ್ಛೆ, ಭಯ ಮತ್ತು ಕ್ರೋಧಗಳಿಂದ ರಹಿತನಾಗಿದ್ದಾನೆಯೋ ಅವನು ಸದಾ ಮುಕ್ತನೇ ಆಗಿದ್ದಾನೆ. (5.27-5.28)",

      "ಹೊರಗಿನ ವಿಷಯ ಭೋಗಗಳನ್ನು ಚಿಂತಿಸದೆ ಇದ್ದು, ಹೊರಗೆಯೇ ತ್ಯಜಿಸಿ ಮತ್ತು ಕಣ್ಣುಗಳ ದೃಷ್ಟಿಯನ್ನು ಭ್ರೂ ಮಧ್ಯದಲ್ಲಿ ಸ್ಥಿರವಾಗಿಸಿ ಹಾಗೂ ಮೂಗಿನಲ್ಲಿ ಸಂಚರಿಸುವ ಪ್ರಾಣ ಮತ್ತು ಅಪಾನ ವಾಯುವನ್ನು ಸಮವಾಗಿಸಿ, ಯಾರ ಇಂದ್ರಿಯಗಳು, ಮನಸ್ಸು ಮತ್ತು ಬುದ್ಧಿ ಗೆಲ್ಲಲ್ಪಟ್ಟಿವೆಯೋ ಅಂತಹ ಯಾವ ಮೋಕ್ಷಪರಾಯಣನಾದ ಮುನಿಯು ಇಚ್ಛೆ, ಭಯ ಮತ್ತು ಕ್ರೋಧಗಳಿಂದ ರಹಿತನಾಗಿದ್ದಾನೆಯೋ ಅವನು ಸದಾ ಮುಕ್ತನೇ ಆಗಿದ್ದಾನೆ. (5.27-5.28)",

      "ನನ್ನ ಭಕ್ತನು ನನ್ನನ್ನು ಎಲ್ಲ ಯಜ್ಞ ಮತ್ತು ತಪಸ್ಸುಗಳನ್ನು ಭೋಗಿಸುವವನೂ, ಸಂಪೂರ್ಣ ಲೋಕಗಳ ಒಡೆಯರಿಗೂ ಒಡೆಯನೂ ಹಾಗೂ ಸಂಪೂರ್ಣ ಪ್ರಾಣಿಗಳ ಸುಹೃದನೂ ಅರ್ಥಾತ್ ಸ್ವಾರ್ಥ ರಹಿತ ದಯಾಯುವೂ ಮತ್ತು ಪ್ರೇಮಿಯೂ ಎಂದು ತತ್ವದಿಂದ ತಿಳಿದುಕೊಂಡು ಶಾಂತಿಯನ್ನು ಪಡೆಯುತ್ತಾನೆ. 5.29",

      "ಇತಿ ಶ್ರೀಮದ್ಭಗವದ್ಗೀತಾರೂಪೀ ಉಪನಿಷದ್ ಬ್ರಹ್ಮವಿದ್ಯಾ ಹಾಗೂ ಯೋಗಶಾಸ್ತ್ರ ವಿಷಯಕ ಶ್ರೀ ಕೃಷ್ಣ ಮತ್ತು ಅರ್ಜುನರ ಸಂವಾದದಲ್ಲಿ ಕರ್ಮಸಂನ್ಯಾಸಯೋಗ ಎಂಬ ಐದನೇ ಅಧ್ಯಾಯ ಸಂಪೂರ್ಣ"
    ],
    "adhyaya-6" : [
      "ಶ್ರೀ ಭಗವಂತನು ಹೇಳಿದನು - ಯಾವ ಪುರುಷನು ಕರ್ಮದ ಫಲದ ಆಶ್ರಯವನ್ನು ಪಡೆಯದೆ ಕರ್ತ್ಯವ್ಯ ಕರ್ಮವನ್ನು ಮಾಡುತ್ತಾನೋ ಅವನು ಸಂನ್ಯಾಸಿ ಮತ್ತು ಯೋಗಿಯಾಗಿದ್ದಾನೆ; ಮತ್ತು ಕೇವಲ ಅಗ್ನಿಯನ್ನು ತ್ಯಜಿಸಿದವನು ಸಂನ್ಯಾಸಿಯಲ್ಲ ಹಾಗೂ ಕೇವಲ ಕ್ರಿಯೆಗಳನ್ನು ತ್ಯಜಿಸಿದವನೂ ಯೋಗಿಯಲ್ಲ. 6.1",

      "ಯೋಗದಲ್ಲಿ ಆರೂಢನಾಗಬೇಕೆಂಬ ಇಚ್ಛೆಯುಳ್ಳ ಮನನಶೀಲನಾದ ಪುರುಷನಿಗೆ ನಿಷ್ಕಾಮಭಾವದಿಂದ ಕರ್ಮ ಮಾಡುವುದೇ ಯೋಗದ ಪ್ರಾಪ್ತಿಯಲ್ಲಿ ಕಾರಣವೆಂದು ಹೇಳಲಾಗುತ್ತದೆ ಮತ್ತು ಯೋಗಾರೂಢನಾದ ನಂತರ ಆ ಯೋಗಾರೂಢ ಪುರುಷನಿಗೆ ಯಾವುದು ಸರ್ವಸಂಕಲ್ಪಗಳ ಅಭಾವವಿದೆಯೋ ಅದೇ ಅವನ ಶ್ರೇಯಸ್ಸಿಗೆ ಕಾರಣವೆಂದು ಹೇಳಲಾಗುತ್ತದೆ. 6.2",

      "ಯಾವ ಕಾಲದಲ್ಲಿ ಇಂದ್ರಿಯಗಳ ಭೋಗಗಳಲ್ಲಿ ಮತ್ತು ಕರ್ಮಗಳಲ್ಲಿಯೂ ಆಸಕ್ತನಾಗುವುದಿಲ್ಲವೋ ಆ ಕಾಲದಲ್ಲಿ ಸರ್ವ ಸಂಕಲ್ಪಗಳನ್ನು ತ್ಯಾಗಮಾಡಿದ ಪುರುಷನು ಯೋಗಾರೂಢನೆಂದು ಹೇಳಲ್ಪಡುತ್ತಾನೆ. 6.3",

      "ಯೋಗದಲ್ಲಿ ಆರೂಢನಾಗಬೇಕೆಂಬ ಇಚ್ಛೆಯುಳ್ಳ ಮನನಶೀಲನಾದ ಪುರುಷನಿಗೆ ನಿಷ್ಕಾಮಭಾವದಿಂದ ಕರ್ಮ ಮಾಡುವುದೇ ಯೋಗದ ಪ್ರಾಪ್ತಿಯಲ್ಲಿ ಕಾರಣವೆಂದು ಹೇಳಲಾಗುತ್ತದೆ ಮತ್ತು ಯೋಗಾರೂಢನಾದ ನಂತರ ಆ ಯೋಗಾರೂಢ ಪುರುಷನಿಗೆ ಯಾವುದು ಸರ್ವಸಂಕಲ್ಪಗಳ ಅಭಾವವಿದೆಯೋ ಅದೇ ಅವನ ಶ್ರೇಯಸ್ಸಿಗೆ ಕಾರಣವೆಂದು ಹೇಳಲಾಗುತ್ತದೆ. 6.4",

      "ತನ್ನ ಮೂಲಕ ತನ್ನನ್ನು ಸಂಸಾರಸಾಗರದಿಂದ ಉದ್ಧಾರಮಾಡಿಕೊಳ್ಳಲಿ ಮತ್ತು ತನ್ನನ್ನು ಅಧೋಗತಿಗೆ ಕೊಂಡೊಯ್ಯುದಿರಲಿ. ಏಕೆಂದರೆ ಈ ಮನುಷ್ಯನು ತನಗೆ ತಾನೇ ಮಿತ್ರನೂ, ಮತ್ತು ತನಗ್ರ್ ತಾನೇ ಶತ್ರುವೂ ಆಗಿದ್ದಾನೆ. 6.5",

      "ಯಾವ ಜೀವಾತ್ಮನ ಮೂಲಕ ಮನಸ್ಸು ಮತ್ತು ಇಂದ್ರಿಯಗಳ ಸಹಿತವಾದ ಶರೀರವು ಗೆಲ್ಲಲ್ಪಟ್ಟಿದೆಯೋ ಆ ಜೀವಾತ್ಮನಿಗಾದರೋ ಅವನು ತನಗೆ ತಾನೇ ಮಿತ್ರನಾಗಿದ್ದಾನೆ ಮಾತ್ತು ಯಾರ ಮೂಲಕ ಮನಸ್ಸು ಹಾಗೂ ಇಂದ್ರಿಯಗಳ  ಸಹಿತವಾದ ಶರೀರವು ಗೆಲ್ಲಲ್ಪಟ್ಟಿದೆಯೋ ಅವನಿಗೆ ಅವನು ಶತ್ರುವಿನಂತೆ ಶತೃತ್ವದಲ್ಲಿ ವರ್ತಿಸುತ್ತಾನೆ. 6.6",

      "ಶೀತ- ಉಷ್ಣ ಮತ್ತು ಸುಖ -ದುಃಖಾದಿಗಳಲ್ಲಿ ಹಾಗೂ ಮಾನ - ಅಪಮಾನಗಳಲ್ಲಿ ಯಾರ ಅಂತಃಕರಣದ ವೃತ್ತಿಗಳು ಚೆನ್ನಾಗಿ ಶಾಂತವಾಗಿವೆಯೋ ಅಂತಹ ಆತ್ಮವನ್ನು ಸ್ವಾಧೀನ ಪಡಿಸಿಕೊಂಡ ಪುರುಷರ ಜ್ಞಾನದಲ್ಲಿ ಸಚ್ಚಿದಾನಂದಘನ ಪರಮಾತ್ಮನಲ್ಲದೇ ಬೇರೇನೂ ಇಲ್ಲವೇ ಇಲ್ಲ. 6.7",

      "ಯಾರ ಅಂತಃಕರಣವು ಜ್ಞಾನ - ವಿಜ್ಞಾನದಿಂದ ತೃಪ್ತವಾಗಿದೆಯೋ, ಯಾರ ಸ್ಥಿತಿಯು ವಿಕಾರರಹಿತವಾಗಿದೆಯೋ ಯಾರ ಇಂದ್ರಿಯಗಳು ಪೂರ್ಣವಾಗಿ ಗೆಲ್ಲಲ್ಪಟ್ಟಿವೆಯೋ ಮತ್ತು ಯಾರಿಗೆ ಕಲ್ಲು, ಮಣ್ಣು ಮತ್ತು ಚಿನ್ನ ಸಮಾನವಾಗಿವೆಯೋ ಆ ಯೋಗಿಯು ಯುಕ್ತನು ಅರ್ಥಾತ್ ಭಗವತ್ಪ್ರಾಪ್ತನಾಗಿದ್ದಾನೆಂದು ಹೇಳಲಾಗುತ್ತದೆ. 6.8",

      "ಸಹೃದಯರು, ಮಿತ್ರರು, ವೈರಿಗಳು, ಉದಾಸೀನರು, ಮಧ್ಯಸ್ಥರು, ದ್ವೇಷ ಪಾತ್ರನಾಗುವನು ಮತ್ತು ಬಂಧುಗಣದಲ್ಲಿ, ಧರ್ಮಾತ್ಮರಲ್ಲಿ ಮತ್ತು ಪಾಪಿಗಳಲ್ಲಿ ಕೂಡ ಸಮಭಾವವನ್ನಿಡುವವನು ಅತ್ಯಂತ ಶ್ರೇಷ್ಠನಾಗಿದ್ದಾನೆ. 6.9",

      "ಮನಸ್ಸು ಮತ್ತು ಇಂದ್ರಿಯಗಳ ಸಹಿತವಾಗಿ ಶರೀರವನ್ನು ತನ್ನ ವಶದಲ್ಲಿ ಇಟ್ಟುಕೊಂಡವನೂ, ಆಶಾರಹಿತನೂ ಮತ್ತು ಸಂಗ್ರಹರಹಿತನೂ ಆದ ಯೋಗಿಯು ಒಬ್ಬಂಟಿಗನಾಗಿ, ಏಕಾಂತಸ್ಥಾನದಲ್ಲಿ ಸ್ಥಿತನಾಗಿ ಆತ್ಮವನ್ನು ನಿರಂತರವಾಗಿ ಪರಮಾತ್ಮನಲ್ಲಿ ತೊಡಗಿಸಬೇಕು. 6.10",

      "ಶುದ್ಧವಾದ ಭೂಮಿಯ ಮೇಲೆ ಕ್ರಮವಾಗಿ ಕುಶ, ಮೃಗಚರ್ಮ ಮತ್ತು ವಸ್ತ್ರಗಳನ್ನು ಹಾಸಿ, ಬಹಳ ಎತ್ತರವಿಲ್ಲದಂತೆ ಮತ್ತು ಬಹಳ ತಗ್ಗಿಲ್ಲದಂತೆ ತನ್ನ ಆಸನವನ್ನು ಸ್ಥಿರವಾಗಿ ಸ್ಥಾಪನೆ ಮಾಡಿ, 6.11",

      "ಆ ಆಸನದಲ್ಲಿ ಕುಳಿತುಕೊಂಡು, ಚಿತ್ತ ಮತ್ತು ಇಂದ್ರಿಯಗಳ ಕ್ರಿಯೆಗಳನ್ನು ವಶದಲ್ಲಿಟ್ಟುಕೊಂಡು, ಮನಸ್ಸನ್ನು ಏಕಾಗ್ರಗೊಳಿಸಿ, ಅಂತಃಕರಣ ಶುದ್ಧಿಗಾಗಿ ಯೋಗದ ಅಭ್ಯಾಸವನ್ನು ಮಾಡಬೇಕು. 6.12",

      "ಶರೀರ, ತಲೆ ಮತ್ತು ಕುತ್ತಿಗೆಯನ್ನು ನೇರವಾಗಿ ಮತ್ತು ಅಚಲವಾಗಿ ಇಟ್ಟುಕೊಂಡು ಮತ್ತು ಸ್ಥಿರವಾಗಿ ತನ್ನ ಮೂಗಿನತುದಿಯಲ್ಲಿ ದೃಷ್ಟಿಯನ್ನು ನಿಲ್ಲಿಸಿ ಬೇರೆ ದಿಕ್ಕುಗಳನ್ನು ನೋಡದೆ, 6.13",

      "ಬ್ರಹ್ಮಚಾರಿವ್ರತದಲ್ಲಿ ಸ್ಥಿತನಾಗಿದ್ದು, ಭಯರಹಿತನಾಗಿ, ಪೂರ್ಣವಾಗಿ, ಶಾಂತವಾದ ಅಂತಃಕರಣವುಳ್ಳ, ಎಚ್ಚರಿಕೆಯುಳ್ಳ ಯೋಗಿಯು ಮನಸ್ಸನ್ನು ತಡೆದುಕೊಂಡು ನನ್ನಲ್ಲಿಯೇ ಚಿತ್ತವುಳ್ಳವನಾಗಿ, ಮತ್ತು ನನ್ನಲ್ಲಿ ಪರಾಯಣನಾಗಿ ಕುಳಿತುಕೊಳ್ಳಬೇಕು. 6.14",

      "ಮನಸ್ಸನ್ನು ವಶಪಡಿಸಿಕೊಂಡಿರುವ ಯೋಗಿಯು ಈ ಪ್ರಕಾರವಾಗಿ ಆತ್ಮವನ್ನು ಪರಮೇಶ್ವರಸ್ವರೂಪನಾದ ನನ್ನಲ್ಲಿ ನಿರಂತರವಾಗಿ ತೊಡಗಿಸುತ್ತ ನನ್ನಲ್ಲಿರುವ ಪರಮಾನಂದದ ಪರಾಕಾಷ್ಠರೂಪಿಯಾದ ಶಾಂತಿಯನ್ನು ಪಡೆಯುತ್ತಾನೆ. 6.15",

      "ಹೇ ಅರ್ಜುನಾ! ಈ ಯೋಗವು ಅತಿಯಾಗಿ ತಿನ್ನುವವನಿಗೂ, ಸರ್ವಥಾ ತಿನ್ನದಿರುವವನಿಗೂ, ತುಂಬಾ ಮಲಗುವ ಸ್ವಭಾವವುಳ್ಳವನಿಗೂ, ಅಥವಾ ಸರ್ವಥಾ ಮಲಗದೇ ಇರುವವನಿಗೂ ಸಿದ್ಧಿಸುವುದಿಲ್ಲ. 6.16",

      "ದುಃಖಗಳನ್ನು ನಾಶಪಡಿಸುವ ಈ ಯೋಗವಾದರೋ ಯಥಾಯೋಗ್ಯವಾಗಿ ಆಹಾರ-ವಿಹಾರ ಮಾಡುವವನಿಗೆ ಕರ್ಮಗಳಲ್ಲಿ ಯಥಾಯೋಗ್ಯವಾದ ಚೇಷ್ಟೆಮಾಡುವವನಿಗೆ ಮತ್ತು ಯಥಾಯೋಗ್ಯವಾಗಿ ಮಲಗಿ ನಿದ್ರಿಸುವವನಿಗೆ ಹಾಗೂ ಎಚ್ಚರವಾಗಿರುವವನಿಗೇ ಸಿದ್ಧವಾಗುತ್ತದೆ. 6.17",

      "ಸಂಪೂರ್ಣವಾಗಿ ವಶಪಡಿಸಿಕೊಳ್ಳಲಾದ ಚಿತ್ತವು ಯಾವ ಕಾಲದಲ್ಲಿ ಪರಮಾತ್ಮನಲ್ಲಿಯೇ, ಪೂರ್ಣವಾಗಿ ಸ್ಥಿತವಾಗಿರುತ್ತದೋ, ಆ ಕಾಲದಲ್ಲಿ ಸಂಪೂರ್ಣ ಭೋಗಗಳಲ್ಲಿ ಸ್ಪೃಹಾರಹಿತನಾದ ಪುರುಷನು ಯೋಗಯುಕ್ತನಾಗಿದ್ದಾನೆಂದು ಹೇಳಲಾಗುತ್ತದೆ. 6.18",

      "ಯಾವ ಪ್ರಕಾರವಾಗಿ ವಾಯುರಹಿತ ಸ್ಥಾನದಲ್ಲಿ ಸ್ಥಿತವಾದ ದೀಪವು ಚಲಿಸುವುದಿಲ್ಲವೋ ಅದೇ ರೀತಿಯ ಉಪಮೆಯನ್ನು ಪರಮಾತ್ಮನ ಧ್ಯಾನದಲ್ಲಿ ತೊಡಗಿರುವ ಯೋಗಿಯ ಗೆದ್ದಿರುವ ಚಿತ್ತಕ್ಕೇ ಕೊಡಲಾಗಿದೆ. 6.19",

      "ಯೋಗಾಭ್ಯಾಸದಿಂದ ವಶಮಾಡಿಕೊಂಡಿರುವ ಚಿತ್ತವು ಯಾವ ಅವಸ್ಥೆಯಲ್ಲಿ ಉಪರತಿ ಉಂಟಾಗುತ್ತದೋ ಮತ್ತು ಯಾವ ಅವಸ್ಥೆಯಲ್ಲಿ ಪರಮಾತ್ಮನ ಧ್ಯಾನದಿಂದ ಶುದ್ಧವಾದ, ಸೂಕ್ಷ್ಮಬುದ್ಧಿಯ ಮೂಲಕ ಪರಮಾತ್ಮನನ್ನು ಸಾಕ್ಷಾತ್ಕರಿಸಿಕೊಳ್ಳುತ್ತಾ ಸಚ್ಚಿದಾನಂದಘನನಾದ ಪರಮಾತ್ಮನಲ್ಲಿಯೇ ಸಂತುಷ್ಟನಾಗಿರುತ್ತಾನೋ, 6.20",

      "ಹಾಗೂ ಇಂದ್ರಿಯಗಳಿಂದ ಅತೀತವಾದ ಕೇವಲ ಶುದ್ಧವಾದ ಸೂಕ್ಷ್ಮ ಬುದ್ಧಿಯ ಮೂಲಕ ಗ್ರಹಿಸಲು ಯೋಗ್ಯವಾದ ಯಾವ ಅನಂತವಾದ ಆನಂದವಿದೆಯೋ; ಅದನ್ನು ಯಾವ ಅವಸ್ಥೆಯಲ್ಲಿ ಅನುಭವಿಸುತ್ತಾನೋ ಮತ್ತು ಯಾವ ಅವಸ್ಥೆಯಲ್ಲಿ ಸ್ಥಿತನಾದ ಈ ಯೋಗಿಯು ಪರಮಾತ್ಮನ ಸ್ವರೂಪದಿಂದ ಎಂದಿಗೂ ವಿಚಲಿತನಾಗುವುದೇ ಇಲ್ಲವೋ, 6.21",

      "ಮತ್ತು ಪರಮಾತ್ಮನ ಪ್ರಾಪ್ತಿರೂಪೀ ಯಾವ ಲಾಭವನ್ನು ಪಡೆದುಕೊಂಡು ಅದಕ್ಕಿಂತ ಅಧಿಕವಾದ ಬೇರೆ ಯಾವುದೇ ಲಾಭವನ್ನು ಮನ್ನಿಸುವುದಿಲ್ಲವೋ ಮತ್ತು ಪರಮಾತ್ಮನ ಪ್ರಾಪ್ತಿರೂಪೀ ಯಾವ ಅವಸ್ಥೆಯಲ್ಲಿ ಸ್ಥಿತನಾದ ಯೋಗಿಯು ಭಾರೀ ದೊಡ್ಡ ದುಃಖದಿಂದಲೂ ಕೂಡ ವಿಚಲಿತನಾಗುವುದಿಲ್ಲವೋ, 6.22",

      "ಯಾವುದು ದುಃಖರೂಪೀ ಸಂಸಾರದ ಸಂಯೋಗದಿಂದ ರಹಿತವಾಗಿದೆಯೋ, ಹಾಗೂ ಯಾವುದರ ಹೆಸರು ಯೋಗವೆಂಬುದಾಗಿದೆಯೋ ಅದನ್ನು ತಿಳಿಯಬೇಕು. ಆ ಯೋಗವನ್ನು ಬೇಸರಿಸದೆ ಅರ್ಥಾತ್ ಧೈರ್ಯ ಮತ್ತು ಉತ್ಸಾಹದಿಂದ ಕೂಡಿದ ಚಿತ್ತದಿಂದ ನಿಶ್ಚಯಪೂರ್ವಕವಾಗಿ ಮಾಡುವುದು ಕರ್ತವ್ಯವಾಗಿದೆ. 6.23",

      "ಸಂಕಲ್ಪದಿಂದ ಉತ್ಪನ್ನವಾಗುವ ಸಂಪೂರ್ಣ ಕಾಮನೆಗಳನ್ನು ನಿಶ್ಯೇಷರೂಪದಿಂದ ತ್ಯಾಗಮಾಡಿ ಮತ್ತು ಮನಸ್ಸಿನ ಮೂಲಕ ಇಂದ್ರಿಯಗಳ ಸಮುದಾಯವನ್ನು ಎಲ್ಲ ಕಡೆಗಳಿಂದಲೂ ಪೂರ್ಣವಾಗಿ ತಡೆದುಕೊಂಡು, 6.24",

      "ಕ್ರಮ-ಕ್ರಮವಾಗಿ ಅಭ್ಯಾಸಮಾಡುತ್ತ ಉಪರತಿಯನ್ನು ಪಡೆದು ಹಾಗೂ ಧೈರ್ಯದಿಂದ ಕೂಡಿದ ಬುದ್ಧಿಯ ಮೂಲಕ ಮನವನ್ನು ಪರಮಾತ್ಮನಲ್ಲಿ ಸ್ಥಿತಗೊಳಿಸಿ, ಪರಮಾತ್ಮನಲ್ಲದೆ ಮತ್ತೇನನ್ನೂ ಕೂಡ ಚಿಂತಿಸಬಾರದು. 6.25",

      "ಈ ಅಸ್ಥಿರವಾಗಿರುವ ಮತ್ತು ಚಂಚಲವಾದ ಮನಸ್ಸು ಯಾವ - ಯಾವ ಶಬ್ದಾದಿ ವಿಷಯಗಳ ನಿಮಿತ್ತದಿಂದ ಜಗತ್ತಿನಲ್ಲಿ ವಿಚರಿಸುತ್ತದೆಯೋ, ಆಯಾಯಾ ವಿಷಯಗಳಿಂದ ತಡೆದು ಎಂದರೆ ಹಿಂದಕ್ಕೆಳೆದುಕೊಂಡು ಅದನ್ನು ಪದೇ ಪದೇ ಪರಮಾತ್ಮನಲ್ಲಿಯೇ ತೊಡಗಿಸಬೇಕು. 6.26",

      "ಏಕೆಂದರೆ ಯಾರ ಮನಸ್ಸು ಒಳ್ಳೆಯ ರೀತಿಯಲ್ಲಿ ಶಾಂತವಾಗಿದೆಯೋ, ಯಾರು ಪಾಪರಹಿತನಾಗಿದ್ದಾನೋ ಮತ್ತು ಯಾರ ರಜೋಗುಣವು ಶಾಂತವಾಗಿ ಹೋಗಿದೆಯೋ, ಈ ಸಚ್ಚಿದಾನಂದಘನನಾದ ಬ್ರಹ್ಮನೊಡನೆ ಏಕೀಭಾವವುಂಟಾಗಿರುವ ಅಂತಹ ಯೋಗಿಗೆ ಉತ್ತಮವಾದ್ ಆನಂದವು ಪ್ರಾಪ್ತವಾಗುತ್ತದೆ 6.27",

      "ಆ ಪಾಪರಹಿತನಾದ ಯೋಗಿಯು ಈ ಪ್ರಕಾರ ನಿರಂತರವಾಗಿ ಆತ್ಮವನ್ನು ಪರಮಾತ್ಮನಲ್ಲಿ ತೊಡಗಿಸುತ್ತ ಸುಖಪೂರ್ವಕವಾಗಿ ಪರಬ್ರಹ್ಮ ಪರಮಾತ್ಮನ ಪ್ರಾಪ್ತಿರೂಪವಾದ ಅನಂತ ಆನಂದವನ್ನು ಅನುಭವಿಸುತ್ತಾನೆ 6.28",

      "ಸರ್ವವ್ಯಾಪಿಯಾದ ಅನಂತ ಚೇತನದಲ್ಲಿ ಏಕೀಭಾವದಿಂದ ಸ್ಥಿತರೂಪವಾದ ಯೋಗದಿಂದ ಕೂಡಿದ ಆತ್ಮವುಳ್ಳವನು ಹಾಗೂ ಎಲ್ಲರನ್ನು ಸಮಭಾವದಿಂದ ನೋಡುವ ಯೋಗಿಯು ಆತ್ಮವನ್ನು ಸಂಪೂರ್ಣ ಪ್ರಾಣಿಗಳಲ್ಲಿ ಸ್ಥಿತನಾಗಿರುವ ಮತ್ತು ಸಮಸ್ತ ಪ್ರಾಣಿಗಳನ್ನು ಆತ್ಮನಲ್ಲಿ ಕಲ್ಪಿತವಾಗಿ ನೋಡುತ್ತಾನೆ. 6.29",

      "ಯಾವ ಪುರುಷನು ಸಮಸ್ತ ಜೀವಿಗಳಲ್ಲಿ ಎಲ್ಲರ ಆತ್ಮರೂಪೀ ವಾಸುದೇವನಾದ ನನ್ನನ್ನೇ ವ್ಯಾಪಕನಾಗಿ ನೋಡುತ್ತನೋ ಮತ್ತು ಸಂಪೂರ್ಣ ಜೀವಿಗಳನ್ನು ವಸುದೇವನಾದ ನನ್ನಲ್ಲಿ ಅಂತರ್ಗತವಾಗಿರುವಂತೆ ನೋಡುತ್ತಾನೋ ಅವನಿಗೆ ನಾನು ಅದೃಶ್ಯನಾಗುವುದಿಲ್ಲ ಮತ್ತು ಅವನು ನನಗೂ ಅದೃಶ್ಯನಾಗುವುದಿಲ್ಲ. 6.30",

      "ಯಾವ ಪುರುಷನು ಏಕೀಭಾವದಲ್ಲಿ ಸ್ಥಿತನಾಗಿ, ಸಂಪೂರ್ಣ ಭೂತಗಳಲ್ಲಿ ಆತ್ಮರೂಪದಿಂದ ಸ್ಥಿತನಾದ, ಸಚ್ಚಿದಾನಂದಘನ, ವಾಸುದೇವನಾದ ನನ್ನನ್ನು ಭಜಿಸುತ್ತಾನೋ ಆ ಯೋಗಿಯು ಎಲ್ಲ ಪ್ರಕಾರದಿಂದ ವರ್ತಿಸುತ್ತಿದ್ದರೂ ಸಹ ನನ್ನಲ್ಲಿಯೇ ವರ್ತಿಸುತ್ತಾನೆ ಅರ್ಥಾತ್ - ಅವನ ಎಲ್ಲ ಕ್ರಿಯೆಗಳು ಭಗವಂತನೊಂದಿಗೆ ನಡೆಯುತ್ತವೆ. 6.31",

      "ಹೇ ಅರ್ಜುನಾ! ಯಾವ ಯೋಗಿಯು ತನ್ನಂತೆಯೇ ಸಂಪೂರ್ಣ ಪ್ರಾಣಿಗಳನ್ನು ಸಮದೃಷ್ಟಿಯಿಂದ ನೋಡುತ್ತಾನೋ ಮತ್ತು ಸುಖ ಅಥವಾ ದುಃಖವನ್ನೂ ಕೂಡ ಎಲ್ಲರಲ್ಲಿ ಸಮವಾಗಿ ನೋಡುತ್ತಾನೋ ಆ ಯೋಗಿಯು ಪರಮಶ್ರೇಷ್ಠನೆಂದು ತಿಳಿಯಲ್ಪಟ್ಟಿದ್ದಾನೆ. 6.32",

      "ಅರ್ಜುನ ಹೇಳಿದನು - ಹೇ ಮಧುಸೂದನಾ ! ಯಾವ ಈ ಯೋಗವನ್ನು ನೀನು ಸಮಭಾವದಿಂದ ಹೇಳಿದ್ದೀಯೋ, ಮನಸ್ಸು ಚಂಚಲವಾದ್ದರಿಂದ ನಾನು ಅದರ ಸ್ಥಿರವಾದ ಸ್ಥಿತಿಯನ್ನು ನೋಡುವುದಿಲ್ಲ. 6.33",

      "ಏಕೆಂದರೆ ಹೇ ಕೃಷ್ಣಾ ! ಈ ಮನಸ್ಸು ತುಂಬಾ ಚಂಚಲವಾದುದು, ಮಥಿಸುವ ಸ್ವಭಾವವುಳ್ಳದ್ದು, ಬಹಳ ದೃಢವಾದುದೂ ಮತ್ತು ಬಲಶಾಲಿಯೂ ಆಗಿದೆ. ಆದಕಾರಣ ಇದನ್ನು ವಶದಲ್ಲಿಡುವುದು, ವಾಯುವನ್ನು ತಡೆಹಿಡಿದಂತೆ ಅತ್ಯಂತ ದುಷ್ಕರವಾದುದೆಂದು ನಾನು ತಿಳಿಯುತ್ತೇನೆ. 6.34",

      "ಶ್ರೀ ಭಗವಂತನು ಹೇಳಿದನು - ಹೇ ಮಹಾಬಾಹುವೇ ! ನಿಸ್ಸಂದೇಹವಾಗಿ ಮನಸ್ಸು ಚಂಚಲ ಮತ್ತು ಕಷ್ಟದಿಂದ ವಶವಾಗುವಂತಹುದಾಗಿದೆ; ಆದರೆ ಹೇ ಕುಂತೀಪುತ್ರನಾದ ಅರ್ಜುನನೇ ! ಇದು ಅಭ್ಯಾಸದಿಂದ ಮತ್ತು ವೈರಾಗ್ಯದಿಂದ ವಶವಾಗುತ್ತದೆ. 6.35",

      "ಯಾರ ಮನಸ್ಸನ್ನು ವಶಪಡಿಸಿಕೊಳ್ಳಲಿಲ್ಲವೋ ಅಂತಹ ಪುರುಷರ ಮೂಲಕ ಯೋಗವು ಪಡೆಯಲಶಕ್ಯವಾಗಿದೆ. ಆದರೆ ಮನಸ್ಸನ್ನು ವಶಪಡಿಸಿಕೊಂಡಿರುವ ಪ್ರಯತ್ನಶೀಲರಾದ ಪುರುಷರ ಮೂಲಕ ಸಾಧನೆಯಿಂದ ಅದು ಪ್ರಾಪ್ತವಾಗುವುದು ಸಹಜವಾಗಿದೆ. ಇದು ನನ್ನ ಮತವಾಗಿದೆ. 6.36",

      "ಅರ್ಜುನನು ಹೇಳಿದನು - ಹೇ ಶ್ರೀಕೃಷ್ಣ ! ಯಾರು ಯೋಗದಲ್ಲಿ ಶ್ರದ್ಧೆಯನ್ನಿಟ್ಟಿದ್ದಾರೋ, ಆದರೆ ಸಂಯಮಿಗಳಲ್ಲವೋ, ಈ ಕಾರಣದಿಂದ ಅಂತ್ಯಕಾಲದಲ್ಲಿ ಯಾರ ಮನಸ್ಸು ಯೋಗದಿಂದ ವಿಚಲಿತವಾಗಿ ಹೋಗಿದೆಯೋ ಅಂತಹ ಸಾಧಕನು ಯೋಗದ ಸಿದ್ಧಿಯನ್ನು ಅರ್ಥಾತ್ ಭಗವಂತನ ಸಾಕ್ಷಾತ್ಕಾರ್ವನ್ನು ಪಡೆಯದೆ ಯಾವ ಗತಿಯನ್ನು ಪಡೆಯುತ್ತಾನೆ ? 6.37",

      "ಹೇ ಮಹಾಬಾಹುವೇ ! ಆ ಭಗವತ್ಪ್ರಾಪ್ತಿಯ ಮಾರ್ಗದಲ್ಲಿ ಮೋಹಿತನಾದ ಮತ್ತು ಆಶ್ರಯರಹಿತನಾದ ಪುರುಷನು ಛಿನ್ನ- ಭಿನ್ನವಾದ ಮೋಡದಂತೆ ಎರಡೂ ಕಡೆಗಳಿಂದ ಭ್ರಷ್ಟನಾಗಿ ನಷ್ಟನಾಗುವುದಿಲ್ಲವೇನು ? 6.38",

      "ಹೇ ಶ್ರೀಕೃಷ್ಣಾ! ನನ್ನ ಈ ಸಂಶಯವನ್ನು ಸಂಪೂರ್ಣವಾಗಿ ಛೇದಿಸಲು ನೀನೇ ಸಮರ್ಥನಾಗಿದ್ದೀಯೆ; ಏಕೆಂದರೆ ನೀನಲ್ಲದೆ ಬೇರೆ ಯಾರೂ ಈ ಸಂಶಯವನ್ನು ಛೇದಿಸುವವರು ಸಿಕ್ಕುವುದು ಸಂಭವವಿಲ್ಲ. 6.39",

      "ಶ್ರೀ ಭಗವಂತನು ಹೇಳಿದನು - ಹೇ ಪಾರ್ಥನೇ ಆ ಪುರುಷನ ವಿನಾಶವು ಈ ಲೋಕದಲ್ಲಿಯಾತಲಿ ಮತ್ತು ಪರಲೋಕದಲ್ಲಿಯೇ ಆಗಲಿ ಆಗುವುದಿಲ್ಲ. ಏಕೆಂದರೆ, ಹೇ ಪ್ರೀತಿಪಾತ್ರನೇ! ಆತ್ಮೋದ್ಧಾರಕ್ಕಾಗಿ ಅರ್ಥಾತ್ ಭಗವತ್ಪ್ರಾಪ್ತಿಗಾಗಿ ಕರ್ಮಮಾಡುವ ಯಾವ್ ಮನುಷ್ಯನೂ ದುರ್ಗತಿಯನ್ನು ಹೊಂದುವುದಿಲ್ಲ. 6.40",

      "ಯೋಗಭ್ರಷ್ಟನಾದ ಪುರುಷನು ಪುಣ್ಯವಂತರುಗಳ ಲೋಕಗಳನ್ನು ಅರ್ಥಾತ್ ಸ್ವರ್ಗಾದಿ ಉತ್ತಮವಾದ್ ಲೋಕಗಳನ್ನು ಪ್ರಾಪ್ತಮಾಡಿಕೊಂಡು, ಅವುಗಳಲ್ಲಿ ಬಹಳ ವರ್ಷಗಳವರೆಗೆ ವಾಸಮಾಡಿದ ನಂತರ ಶುದ್ಧವಾದ ಆಚರಣೆ ಮಾಡುವ ಶ್ರೀಮಂತ ಪುರುಷರ ಮನೆಯಲ್ಲಿ ಜನ್ಮವೆತ್ತುತ್ತಾನೆ. 6.41",

      "ಅಥವಾ ವೈರಾಗ್ಯವಂತನಾದ ಯೋಗಿಗಳ ಕುಲದಲ್ಲಿಯೇ ಜನಿಸುತ್ತಾನೆ. ಆದರೆ ಈ ಪ್ರಕಾರದ ಯಾವ ಈ ಜನ್ಮವಿದೆಯೋ ಅದು ಜಗತ್ತಿನಲ್ಲಿ ನಿಸ್ಸಂದೇಹವಾಗಿ ಅತ್ಯಂತ ದುರ್ಲಭವಾಗಿದೆ. 6.42",

      "ಅಲ್ಲಿ ಆ ಹಿಂದಿನ ಶರೀರದಲ್ಲಿ ಸಂಗ್ರಹಿಸಿದ ಬುದ್ಧಿಸಂಯೋಗವನ್ನು ಅರ್ಥಾತ್ ಸಮಬುದ್ಧಿರೂಪವಾದ ಯೋಗದ ಸಂಸ್ಕಾರಗಳನ್ನು ಅನಾಯಾಸವಾಗಿಯೇ ಪಡೆಯುತ್ತಾನೆ ಮತ್ತು ಹೇ ಕುರುನಂದನ ! ಅದರ ಪ್ರಭಾವದಿಂದ ಅವನು ಪುನಃ ಪರಮಾತ್ಮನ ಪ್ರಾಪ್ತಿರೂಪವಾದ ಸಿದ್ಧಿಗಾಗಿ ಮೊದಲಿಗಿಂತಲೂ ಹೆಚ್ಚಿನ ಪ್ರಯತ್ನ ಮಾಡುತ್ತಾನೆ. 6.43",

      "ಆ ಶ್ರೀಮಂತರ ಮನೆಯಲ್ಲಿ ಜನಿಸುವ ಯೋಗಭ್ರಷ್ಟನು ಪರಾಧೀನನಾಗಿದ್ದರೂ ಸಹ ಆ ಮೊದಲಿನ ಅಭ್ಯಾಸದಿಂದಲೇ ನಿಸ್ಸಂದೇಹವಾಗಿ ಭಗವಂತನ ಕಡೆಗೆ ಆಕರ್ಷಿಸಲ್ಪಡುತ್ತಾನೆ ಹಾಗೂ ಸಮಬುದ್ಧಿರೂಪವಾದ ಯೋಗದ ಜಿಜ್ಞಾಸುವುಕೂಡ ವೇದದಲ್ಲಿ ಹೇಳಲಾಗಿರುವ ಸಕಾಮ ಕರ್ಮಗಳ ಫಲವನ್ನು ದಾಟಿ ಹೋಗುತ್ತಾನೆ. 6.44",

      "ಆದರೆ ಪ್ರಯತ್ನಪೂರ್ವಕವಾಗಿ ಅಭ್ಯಾಸಮಾಡುವ ಯೋಗಿಯಾದರೋ ಹಿಂದಿನ ಅನೇಕ ಜನ್ಮಗಳ ಸಂಸ್ಕಾರ ಬಲದಿಂದ ಇದೇ ಜನ್ಮದಲ್ಲಿ ಸಂಸಿದ್ಧನಾಗಿ, ಸಂಪೂರ್ಣ ಪಾಪಗಳಿಂದ ರಹಿತನಾಗಿ ನಂತರ ತತ್ಕಾಲದಲ್ಲಿಯೇ ಪರಮ ಗತಿಯನ್ನು ಪಡೆಯುತ್ತಾನೆ. 6.45",

      "ಯೋಗಿಯು ತಪಸ್ವಿಗಳಿಗಿಂತ ಶ್ರೇಷ್ಠನಾಗಿದ್ದಾನೆ ಶಾಸ್ತ್ರ ಜ್ಞಾನಿಗಳಿಗಿಂತಲೂ ಶ್ರೇಷ್ಠನೆಂದು ತಿಳಿಯಲಾಗಿದೆ ಮತ್ತು ಸಕಾಮ ಕರ್ಮ ಮಾಡುವವರಿಗಿಂತಲೂ ಸಹ ಯೋಗಿಯು ಶ್ರೇಷ್ಠನಾಗಿದ್ದಾನೆ. ಆದ್ದರಿಂದ ಹೇ ಅರ್ಜುನಾ! ನೀನೂ ಯೋಗಿಯಾಗು. 6.46",

      "ಸಂಪೂರ್ಣ ಯೋಗಿಗಳಲ್ಲಿ ಕೂಡ ಯಾವ ಶ್ರದ್ಧಾವಂತನಾದ ಯೋಗಿಯು ನನ್ನಲ್ಲಿ ತೊಡಗಿದ್ದ ಅಂತರಾತ್ಮನಿಂದ ನನ್ನನ್ನು ನಿರಂತರ ಭಜಿಸುತ್ತಾನೋ ಆ ಯೋಗಿಯು ನನಗೆ ಪರಮಶ್ರೇಷ್ಠನೆಂದು ಮಾನ್ಯನಾಗಿದ್ದಾನೆ. 6.47",

      "ಇತಿ ಶ್ರೀಮದ್ಭಗವದ್ಗೀತಾರೂಪೀ ಉಪನಿಷದ್ ಬ್ರಹ್ಮವಿದ್ಯಾ ಹಾಗೂ ಯೋಗಶಾಸ್ತ್ರ ವಿಷಯದಲ್ಲಿ ಶ್ರೀ ಕೃಷ್ಣ ಮತ್ತು ಅರ್ಜುನರ ಸಂವಾದದಲ್ಲಿ ಆತ್ಮಸಂಯಮಯೋಗ ಎಂಬ ಆರನೇ ಅಧ್ಯಾಯ ಸಂಪೂರ್ಣ"
    ],
    "adhyaya-7" : [
      "ಶ್ರೀಕೃಷ್ಣ ಹೇಳುತ್ತಾರೆ : ಎಲೈ ಪಾರ್ಥ, ನೀನು ನನ್ನಲ್ಲಿಯೇ ಅನನ್ಯ ಪ್ರೇಮ ಆಸಕ್ತಿಯುಳ್ಳ ಮನಸ್ಸಿನಿಂದ ಮತ್ತು ಅನನ್ಯ ಭಾವದಿಂದ ನನ್ನನ್ನೇ ಆಶ್ರಯಿಸಿ ಯೋಗದಲ್ಲಿ ನಿರತನಾಗಿ ಎಲ್ಲ ಅಲೌಕಿಕ ಬಲ ಐಶ್ವರ್ಯಾದಿ ಗುಣಗಳಿಂದ ಕೂಡಿದ ಹಾಗೂ ಎಲ್ಲರ ಆತ್ಮರೂಪಿಯಾದ ನನ್ನನ್ನು ಯಾವ ಪ್ರಕಾರ ಸಂಶಯವಿಲ್ಲದೆ ತಿಳಿಯಬೇಕೋ ಅದನ್ನು ಕೇಳು. 7.1",

      "ನಾನು ನಿನಗೋಸ್ಕರ ಈ ವಿಜ್ಞಾನಸಹಿತ ತತ್ತ್ವಜ್ಞಾನವನವನ್ನು ನಿಶ್ಯೇಷವಾಗಿ ಹೇಳುವೆನು. ಅದನ್ನು ತಿಳಿದುಕೊಂಡಮೇಲೆ ಈ ಪ್ರಪಂಚದಲ್ಲಿ ಪುನಃ ತಿಳಿಯಬೇಕಾದದ್ದು ಮತ್ತೇನೂ ಉಳಿದಿರುವುದಿಲ್ಲ. 7.2",

      "ಸಾವಿರಾರು ಮನುಜರಲ್ಲಿ ಯಾರೋ ಒಬ್ಬ ನನ್ನ ಸಾಕ್ಷಾತ್ಕಾರಕ್ಕಾಗಿ ಯತ್ನಿಸುತ್ತಾನೆ. ಹಾಗೆ ಯತ್ನಿಸುವ ಯೋಗಿಗಳಲ್ಲಿಯೂ ಯಾರೋ ಒಬ್ಬ ನನ್ನ ಪರಾಯಣನಾಗಿ ನನ್ನನ್ನು ಯಥಾರ್ಥವಾಗಿ ತಿಳಿದುಕೊಳ್ಳುತ್ತಾನೆ, ಅರ್ಥಾತ್ ರಹಸ್ಯ ಸಹಿತ ಅರಿತುಕೊಳ್ಳುತ್ತಾನೆ. 7.3",

      "ಭೂಮಿ, ನೀರು, ಅಗ್ನಿ, ಗಾಳಿ ಮತ್ತು ಆಕಾಶ, ಮನಸ್ಸು, ಬುದ್ಧಿ ಹಾಗು ಅಹಂಕಾರವೂ ಸೇರಿ ಈ ರೀತಿನನ್ನ ಪ್ರಕೃತಿಯು ಎಂಟು ವಿಧವಾಗಿ ವಿಭಾಗಿಸಲ್ಪಟ್ಟಿದೆ. 7.4",

      "ಈ ಎಂಟು ಪ್ರಕಾರವಾಗಿ ವಿಭಾಗಿಸಲ್ಪಟ್ಟ ಪ್ರಕೃತಿಯು ಅಪರಾ, ಅರ್ಥಾತ್ ನನ್ನ ಜಡಪ್ರಕೃತಿಯಾಗಿದೆ. ಎಲೈ ಮಹಾಬಾಹುವೇ, ಇದಕ್ಕಿಂತ ಬೇರೆಯಾದುದನ್ನು ನನ್ನ ಜೀವರೂಪೀ ಪರಾ ಅರ್ಥಾತ್ ಚೇತನ ಪ್ರಕೃತಿ ಎಂದು ತಿಳಿದುಕೊ. ಇದರಿಂದ ಈ ಜಗತ್ತೆಲ್ಲವೂ ಧರಿಸಲ್ಪಟ್ಟಿರುವುದು. 7.5",

      "ಹೇ ಅರ್ಜುನ, ಸಮಸ್ತ ಜೀವಿಗಳೂ ಈ ಎರಡೂ ಪ್ರಕೃತಿಗಳಿಂದಲೇ ಉತ್ಪತ್ತಿಯಾದವು ಮತ್ತು ನಾನು ಸಮಸ್ತ ಜಗತ್ತಿನ ಉತ್ಪತ್ತಿ ಮತ್ತು ಪ್ರಳಯರೂಪಿಯಾಗಿದ್ದೇನೆ, ಅಂದರೆ ಇಡೀ ಜಗತ್ತಿನ ಮೂಲಕಾರಣನಾಗಿದ್ದೇನೆ, ಎಂದು ತಿಳಿದುಕೊ. 7.6",

      "ಎಲೈ ಧನಂಜಯನೆ, ನನಗಿಂತಲೂ ಪರಮೋಚ್ಚ ಕಾರಣವಾದ ಬೇರೆ ವಸ್ತು ಲೇಶಾಂಶವೂ ಸಹ ಇರುವುದಿಲ್ಲ. ಈ ಸಮಸ್ತ ಜಗತ್ತು ದಾರದಲ್ಲಿ ದಾರದಿಂದಲೇ ರಚಿಸಿದ ಮಣಿಗಳಂತೆ ನನ್ನಲ್ಲಿ ಪೋಣಿಸಲ್ಪಟ್ಟಿದೆ 7.7",

      "ಎಲೈ ಅರ್ಜುನ, ನಾನು ನೀರಿನಲ್ಲಿ ರಸವೂ, ಚಂದ್ರ ಮತ್ತು ಸೂರ್ಯರಲ್ಲಿ ಪ್ರಕಾಶವೂ, ಸಕಲ ವೇದಗಳಲ್ಲಿ ‘ಓಂ’ ಕಾರವೂ, ಆಕಾಶದಲ್ಲಿ ಶಬ್ದವೂ ಮತ್ತು ಪುರುಷರಲ್ಲಿ ಪುರುಷತ್ವವೂ ಆಗಿದ್ದೇನೆ. 7.8",

      "ಪೃಥ್ವಿಯಲ್ಲಿ ಪವಿತ್ರವಾದ ಗಂಧವೂ, ಅಗ್ನಿಯಲ್ಲಿ ತೇಜಸ್ಸೂ, ಎಲ್ಲ ಜೀವಿಗಳಲ್ಲಿಯೂ ಅವುಗಳ ಜೀವನ, ಅರ್ಥಾತ್ ಅವು ಯಾವುದರಿಂದ ಜೀವಿಸುತ್ತವೆಯೋ, ಅದೂ ಸಹ ನಾನೇ ಅಗಿದ್ದೇನೆ, ಮತ್ತು ತಪಸ್ವಿಗಳಲ್ಲಿ ತಪಸ್ಸೂ ಆಗಿದ್ದೇನೆ. 7.9",

      "ಎಲೈ ಪಾರ್ಥ, ಸಕಲ ಜೀವಿಗಳ ಉತ್ಪತ್ತಿಗೆ ನನ್ನನ್ನೇ ಸನಾತನವಾದ ಮೂಲ ಕಾರಣ ಎಂದು ತಿಳಿದುಕೊ. ನಾನು ಬುದ್ಧಿವಂತರ ಬುದ್ಧಿಯೂ ಮತ್ತು ತೇಜಸ್ವಿಗಳ ತೇಜಸ್ಸು ಸಹ ಆಗಿದ್ದೇನೆ. 7.10",

      "ಹೇ ಭರತಶ್ರೇಷ್ಠನೇ ನಾನು ಬಲಶಾಲಿಗಳ, ಆಸಕ್ತಿ ಮತ್ತು ಕಾಮನೆಗಳಿಂದ ರಹಿತವಾದ ಬಲ ಅರ್ಥಾತ್ ಸಾಮರ್ಥ್ಯವಾಗಿದ್ದೇನೆ. ಮತ್ತು ಎಲ್ಲ ಪ್ರಾಣಿಗಳಲ್ಲಿ ಧರ್ಮಕ್ಕೆ ಅನುಕೂಲವಾದ  ಅರ್ಥಾತ್ ಶಾಸ್ತ್ರಕ್ಕೆ ಅನುಕೂಲವಾದ ಕಾಮವಾಗಿದ್ದೇನೆ. 7.11",

      "ಇನ್ನೂ ಕೂಡ ಯಾವ ಸತ್ವಗುಣದಿಂದ ಉತ್ಪನ್ನವಾಗುವ ಭಾವಗಳಿವೆಯೋ ಮತ್ತು ಯಾವ ರಜೋಗುಣದಿಂದ ಹಾಗೂ ತಮೋಗುಣದಿಂದ ಉಂಟಾಗುವ ಭಾವಗಳಿವೆಯೋ, ಅವೆಲ್ಲವುಗಳನ್ನು ನೀನು ನನ್ನಿಂದಲೇ ಉಂಟಾಗುವುವೆಂದು ತಿಳಿ. ಆದರೆ ವಾಸ್ತವವಾಗಿ ಅವುಗಳಲ್ಲಿ ನಾನು ಮತ್ತು ಅವುಗಳು ನನ್ನಲ್ಲಿ ಇಲ್ಲ. 7.12",

      "ಗುಣಗಳ ಕಾರ್ಯರೂಪವಾದ ಸಾತ್ವಿಕ, ರಾಜಸ ಮತ್ತು ತಾಮಸಗಳೆಂಬ ಈ ಮೂರೂ ಪ್ರಕಾರದ ಭಾವಗಳಿಂದ ಈ ಎಲ್ಲ ಜಗತ್ತಿನ ಪ್ರಾಣಿ ಸಮುದಾಯವು ಮೋಹಿತವಾಗುತ್ತಿದೆ. ಆದ ಕಾರಣ ಈ ಮೂರು ಗುಣಗಳಿಂದ ಅತೀತನಾದ, ಅವಿನಾಶಿಯಾದ ನನ್ನನ್ನು ತಿಳಿಯುವುದಿಲ್ಲ. 7.13",

      "ಏಕೆಂದರೆ, ಈ ಅಲೌಕಿಕವಾದ ಅರ್ಥಾತ್ ಅತಿ ಅದ್ಭುತವಾದ ತ್ರಿಗುಣಮಯಿಯಾದ ನನ್ನ ಮಾಯೆಯು ಬಹಳ ದುಸ್ತರವಾಗಿದೆ. ಆದರೆ ಯಾವ ಪುರುಷರು ಕೇವಲ ನನ್ನನ್ನೇ ನಿರಂತರವಾಗಿ ಭಜಿಸುತ್ತಾರೆಯೋ ಅವರು ಈ ಮಾಯೆಯನ್ನು ದಾಟಿಬಿಡುತ್ತಾರೆ. ಅರ್ಥಾತ್ ಜಗತ್ತಿನಿಂದ ಪಾರಾಗುತ್ತಾರೆ. 7.14",

      "ಮಾಯೆಯ ಮೂಲಕ ಯಾರ ಜ್ಞಾನವು ಅಪಹರಿಸಲ್ಪಟ್ಟಿದೆಯೋ ಅಂತಹ ಆಸುರೀಸ್ವಭಾವವನ್ನು ಆಶ್ರಯಿಸಿರುವ ಮನುಷ್ಯರಲ್ಲಿ ನೀಚ ಮತ್ತು ದೂಷಿತ ಕರ್ಮ ಮಾಡುವ ಮೂಢ ಜನರು ನನ್ನನ್ನು ಭಜಿಸುವುದಿಲ್ಲ. 7.15",

      "ಹೇ ಭರತವಂಶೀಯರಲ್ಲಿ ಶ್ರೇಷ್ಠನಾದ ಅರ್ಜುನ ! ಉತ್ತಮ ಕರ್ಮಗಳನ್ನು ಮಾಡುವ ಅರ್ಥಾರ್ಥಿ, ಆರ್ತ, ಜಿಜ್ಞಾಸು ಮತ್ತು ಜ್ಞಾನೀ - ಹೀಗೆ ನಾಲ್ಕು ಪ್ರಕಾರದ ಭಕ್ತ ಜನರು ನನ್ನನ್ನು ಭಜಿಸುತ್ತಾರೆ 7.16",

      "ಅವರಲ್ಲಿ ನಿತ್ಯವೂ ನನ್ನಲ್ಲಿ ಏಕೀಭಾವದಿಂದ ಸ್ಥಿತನಾಗಿರುವ ಅನನ್ಯ ಪ್ರೇಮ ಭಕ್ತಿಯುಳ್ಳ ಜ್ಞಾನೀ ಭಕ್ತನು ಅತಿ ಉತ್ತಮನಾಗಿದ್ದಾನೆ, ಏಕೆಂದರೆ ನನ್ನನ್ನು ತತ್ವದಿಂದ ತಿಳಿಯುವ ಜ್ಞಾನಿಗೆ ನಾನು ಅತ್ಯಂತ ಪ್ರಿಯನಾಗಿದ್ದೇನೆ ಮತ್ತು ಆ ಜ್ಞಾನಿಯು ನನಗೆ ಅತ್ಯಂತ ಪ್ರಿಯನಾಗಿದ್ದಾನೆ. 7.17",

      "ಇವರೆಲ್ಲರೂ ಉದಾರರಾಗಿದ್ದಾರೆ ಆದರೆ, ಜ್ಞಾನಿಯಾದರೋ ಸಾಕ್ಷಾತ್ ನನ್ನ ಸ್ವರೂಪವೇ ಆದಿದ್ದಾನೆ ಎಂಬುದು ನನ್ನ ಮತವಾಗಿದೆ, ಏಕೆಂದರೆ, ಮದ್ಗತ ಮನ - ಬುದ್ಧಿಯುಳ್ಳ  ಜ್ಞಾನಿ ಭಕ್ತನಾದ ಅವನು ಅತ್ಯುತ್ತಮ ಗತಿಸ್ವರೂಪೀ ನನ್ನಲ್ಲಿಯೇ ಒಳ್ಳೆಯ ಪ್ರಕಾರದಿಂದ ಸ್ಥಿತನಾಗಿದ್ದಾನೆ. 7.18",

      "ಅನೇಕ ಜನ್ಮಗಳ ಕೊನೆಯ ಜನ್ಮದಲ್ಲಿ ತತ್ವಜ್ಞಾನವನ್ನು ಪಡೆದ ಪುರುಷನು ಎಲ್ಲವೂ ’ವಾಸುದೇವನೇ ಆಗಿದ್ದಾನೆ’ ಈ ಪ್ರಕಾರವಾಗಿ ನನ್ನನ್ನುಭಜಿಸುತ್ತಾನೋ, ಅಂತಹ ಮಹಾತ್ಮನು ಅತ್ಯಂತ ದುರ್ಲಭವಾಗಿದ್ದಾನೆ. 7.19",

      "ಆಯಾಯಾ ಭೋಗಗಳ ಕಾಮನೆಯ ಮೂಲಕ ಯಾರ ಜ್ಞಾನವು ಅಪಹರಿಸಲ್ಪಟ್ಟಿದೆಯೋ, ಆ ಜನರು ತಮ್ಮ ಸ್ವಭಾವದಿಂದ ಪ್ರೇರಿತರಾಗಿ ಆಯಾಯಾ ನಿಯಮಗಳನ್ನು ಧಾರಣೆಮಾಡಿ ಬೇರೆ ದೇವತೆಗಳನ್ನು ಭಜಿಸುತ್ತಾರೆ ಅರ್ಥಾತ್ ಪೂಜಿಸುತ್ತಾರೆ. 7.20",

      "ಯಾವ ಯಾವ ಸಕಾಮ ಭಕ್ತರು ಯಾವ ಯಾವ ದೇವತೆಯ ಸ್ವರೂಪವನ್ನು ಶ್ರದ್ಧೆಯಿಂದ ಪೂಜಿಸಲು ಬಯಸುತ್ತಾರೋ ಆಯಾ ಭಕ್ತನ ಶ್ರದ್ಧೆಯನ್ನು ನಾನು ಅದೇ ದೇವತೆಯ ಕುರಿತು ಸ್ಥಿರಗೊಳಿಸುತ್ತೇನೆ. 7.21",

      "ಆ ಪುರುಷನು ಅಂತಹ ಶ್ರದ್ಧೆಯಿಂದ ಯುಕ್ತನಾಗಿ ಆ ದೇವತೆಯ ಪೂಜೆಯನ್ನು ಮಾಡುತ್ತಾನೆ ಮತ್ತು ಆ ದೇವತೆಯಿಂದ ನನ್ನಿಂದಲೇ ವಿಧಿಸಲ್ಪಟ್ಟ ಆ ಬಯಸಿದ ಭೋಗಗಳನ್ನು ನಿಸ್ಸಂದೇಹವಾಗಿ ಪಡೆಯುತ್ತಾನೆ. 7.22",

      "ಆದರೆ ಆ ಅಲ್ಪಬುದ್ಧಿಯುಳ್ಳವರ ಆ ಫಲವು ನಾಶಯುಕ್ತವಾಗಿದೆ, ಹಾಗೂ ಆ ದೇವತೆಗಳನ್ನು ಪೂಜಿಸುವವರು ದೇವತೆಗಳನ್ನು ಪಡೆಯುತ್ತಾರೆ. ಆದರೆ ನನ್ನ ಭಕ್ತರು ಹೇಗಾದರೂ ಭಜಿಸಲಿ, ಕಡೆಯಲ್ಲಿ ಅವರು ನನ್ನನ್ನೇ ಸೇರುತ್ತಾರೆ. 7.23",

      "ಬುದ್ಧಿಹೀನರಾದ ಪುರುಷರು ನನ್ನ ಉತ್ಕೃಷ್ಟವೂ, ಅವಿನಾಶಿಯೂ ಆದ ಪರಮ ಭಾವವನ್ನು ತಿಳಿಯದೆ ಮನಸ್ಸು- ಇಂದ್ರಿಯಗಳಿಗಿಂತ ಪರನಾದ ಸಚ್ಚಿದಾನಂದಘನ ಪರಮಾತ್ಮನಾದ ನನ್ನನ್ನು ಮನುಷ್ಯರಂತೆ ಹುಟ್ಟಿ ಬಂದು ವ್ಯಕ್ತಿಭಾವವನ್ನು ಪಡೆದಿರುವವನೆಂದು ತಿಳಿಯುತ್ತಾರೆ. 7.24",

      "ನನ್ನ ಯೋಗಮಾಯೆಯಿಂದ ಅಡಗಿರುವ ನಾನು ಎಲ್ಲರಿಗೂ ಪ್ರತ್ಯಕ್ಷನಾಗುವುದಿಲ್ಲ. ಆದ್ದರಿಂದ ಈ ಅಜ್ಞಾನಿ ಜನಸಮುದಾಯವು ಜನ್ಮರಹಿತವಾದ, ಅವಿನಾಶಿಯಾದ, ಪರಮೇಶ್ವರನಾದ ನನ್ನನು ತಿಳಿಯುವುದಿಲ್ಲ ಅರ್ಥಾತ್ ನನ್ನನ್ನು ಜನನ-ಮರಣವುಳ್ಳವನೆಂದು ತಿಳಿಯುತ್ತದೆ. 7.25",

      "ಹೇ ಅರ್ಜುನಾ !  ಹಿಂದೆ ಕಳೆದು ಹೋದ ಮತ್ತು ವರ್ತಮಾನದಲ್ಲಿ ಇರುವ ಹಾಗೂ ಮುಂದೆ ಹುಟ್ಟಲಿರುವ ಎಲ್ಲ ಪ್ರಾಣಿಗಳನ್ನು ನಾನು ಬಲ್ಲೆ. ಆದರೆ, ನನ್ನನ್ನು ಶ್ರದ್ಧಾ-ಭಕ್ತಿರಹಿತನಾದ ಯಾವ ಪುರುಷನೂ ತಿಳಿಯುವುದಿಲ್ಲ. 7.26",

      "ಹೇ ಭರತವಂಶೀಯನಾದ ಅರ್ಜುನನೇ ! ಜಗತ್ತಿನಲ್ಲಿ ಇಚ್ಛೆ  (ರಾಗ) ಮತ್ತು ದ್ವೇಷದಿಂದ ಉಂಟಾಗುವ ಸುಖ - ದುಃಖಾದಿ ದ್ವಂದ್ವ ರೂಪೀ ಮೋಹದಿಂದ ಸಂಪೂರ್ಣ ಪ್ರಾಣಿಗಳು ಅತ್ಯಂತ ಅಜ್ಞತೆಯನು ಹೊಂದುತ್ತಿದ್ದಾರೆ. 7.27",

      "ಆದರೆ ನಿಷ್ಕಾಮ ಭಾವದಿಂದ ಶ್ರೇಷ್ಠವಾದ ಕರ್ಮಗಳ ಆಚರಣೆ ಮಾಡುವ ಯಾವ ಪುರುಷರ ಪಾಪಗಳು ನಷ್ಟವಾಗಿ ಹೋಗಿವೆಯೋ, ರಾಗ-ದ್ವೇಷಜನಿತ ದ್ವಂದ್ವರೂಪೀ ಮೋಹದಿಂದ ಮುಕ್ತರಾದ ದೃಢನಿಶ್ಚಯವುಳ್ಳ ಆ ಭಕ್ತರು ನನ್ನನ್ನು ಎಲ್ಲ ಪ್ರಕಾರದಿಂದಲೂ ಭಜಿಸುತ್ತಾರೆ. 7.28",

      "ಯಾರು ನನ್ನಲ್ಲಿ ಶರಣಾಗಿ ಮುಪ್ಪು ಮತ್ತು ಸಾವಿನಿಂದ ಬಿಡುಗಡೆ ಹೊಂದಲು ಪ್ರಯತ್ನ ಮಾಡುತ್ತರೋ ಆ ಪುರುಷರು ಆ ಬ್ರಹ್ಮವನ್ನೂ,  ಅಧ್ಯಾತ್ಮವನ್ನೂ ಹಾಗೂ ಸಂಪೂರ್ಣ ಕರ್ಮವನ್ನು ತಿಳಿಯುತ್ತಾರೆ. 7.29",

      "ಯಾವ ಪುರುಷರು ಆದಿಭೂತ ಮತ್ತು ಆದಿದೈವದ ಸಹಿತ ಮತ್ತು ಆದಿಯಜ್ಞದ ಸಹಿತ ( ಎಲ್ಲರ ಆತ್ಮರೂಪಿಯಾದ) ನನ್ನನ್ನು ಅಂತ್ಯಕಾಲದಲ್ಲಿಯೂ ಸಹ ತಿಳಿಯುತ್ತಾರೆಯೋ ಆ ಯುಕ್ತಚಿತ್ತರಾದ ಪುರುಷರು ನನ್ನನ್ನೇ ಅರಿಯುತ್ತಾರೆ ಅರ್ಥಾತ್ ಪಡೆಯುತ್ತಾರೆ. 7.30",

      "ಇತಿ ಶ್ರೀಮದ್ಭಗವದ್ಗೀತಾರೂಪೀ ಉಪನಿಷದ್ ಬ್ರಹ್ಮವಿದ್ಯಾ ಹಾಗೂ ಯೋಗಶಾಸ್ತ್ರ ವಿಷಯಕ ಶ್ರೀ ಕೃಷ್ಣ ಮತ್ತು ಅರ್ಜುನರ ಸಂವಾದದಲ್ಲಿ ಜ್ಞಾನ-ವಿಜ್ಞಾನಯೋಗ ಎಂಬ ಏಳನೇ ಅಧ್ಯಾಯ ಸಂಪೂರ್ಣ"
    ],
    "adhyaya-8" : [
      "ಅರ್ಜುನನು ಹೇಳಿದನು - ಹೇ ಪುರುಷೋತ್ತಮ! ಆ ಬ್ರಹವು ಯಾವುದು ? ಅಧ್ಯಾತ್ಮವೆಂದರೆ ಏನು ಮತ್ತು ಕರ್ಮವೆಂದರೆ ಯಾವುದು ? ಅಧಿಭೂತವೆಂಬ ಹೆಸರಿನಿಂದ ಯಾವುದನ್ನು ಹೇಳಲಾಗಿದೆ ? ಮತ್ತು ಅಧಿದೈವವೆಂದು ಯಾವುದಕ್ಕೆ ಹೇಳುತ್ತಾರೆ ? 8.1",

      "ಹೇ ಮಧುಸೂದನ ! ಇಲ್ಲಿ ಅಧಿಯಜ್ಞ ಎಂದರೆ ಯಾರು? ಮತ್ತು ಅವನು ಈ ಶರೀರದಲ್ಲಿ ಹೇಗಿದ್ದಾನೆ ? ಹಾಗೂ ಯುಕ್ತಚಿತ್ತವುಳ್ಳ ಪುರುಷರ ಮೂಲಕ ಅಂತ್ಯಕಾಲದಲ್ಲಿ ನೀನು ಯಾವ ಪ್ರಕಾರದಿಂದ ತಿಳಿಯಲು ಬರುತ್ತೀಯೆ? 8.2",

      "ಶ್ರೀ ಭಗವಂತನು ಹೇಳಿದನು - ಪರಮ ಅಕ್ಷರವು ’ಬ್ರಹ್ಮ’ವಾಗಿದೆ ತನ್ನ ಸ್ವರೂಪ ಅರ್ಥಾತ್ ಜೇವಾತ್ಮನು ಅಧ್ಯಾತ್ಮವೆಂಬ ಹೆಸರಿನಿಂದ ಹೇಳಲ್ಪಡುತ್ತಾನೆ ಹಾಗೂ ಭೂತಗಳ ಭಾವವನ್ನು ಉಂಟುಮಾಡುವ ಯಾವ ತ್ಯಾಗ ಅರ್ಥಾತ್ ಸೃಷ್ಟಿರಚನಾರೂಪೀ ಕ್ರಿಯೆಯಿದೆಯೋ ಅದು ಕರ್ಮ ಎಂಬ ಹೆಸರಿನಿಂದ ಹೇಳಲ್ಪಟ್ಟಿದೆ. 8.3",

      "ಉತ್ಪತ್ತಿ-ವಿನಾಶ ಧರ್ಮವುಳ್ಳ ಎಲ್ಲ ಪದಾರ್ಥಗಳೂ ಅಧಿಭೂತವಾಗಿವೆ. ಹಿರಣ್ಯಮಯ ಪುರುಷನು ಅಧಿದೈವವಾಗಿದ್ದಾನೆ ಮತ್ತು ದೇಹಧಾರಿಗಳಲ್ಲಿ ಶ್ರೇಷ್ಠನಾದ ಅರ್ಜುನನೇ ಈ ಶರೀರದಲ್ಲಿ ವಾಸುದೇವನಾದ ನಾನೇ ಅಂತರ್ಯಾಮಿರೂಪದಿಂದ ಅಧಿಯಜ್ಞನಾಗಿದ್ದೇನೆ. 8.4",

      "ಯಾವ ಪುರುಷನು ಅಂತ್ಯಕಾಲದಲ್ಲಿಯೂ ಕೂಡ ನನ್ನನ್ನೇ ಸ್ಮರಿಸುತ್ತ ಶರೀರವನ್ನು ತ್ಯಾಗಮಾಡಿ ಬಿಡುತ್ತಾನೋ ಅವನು ನನ್ನ ಸಾಕ್ಷಾತ್ ಸ್ವರೂಪವನ್ನು ಪಡೆಯುತ್ತಾನೆ. ಇದರಲ್ಲಿ ಯಾವುದೇ ಸಂಶಯವೂ ಇರುವುದಿಲ್ಲ. 8.5",

      "ಹೇ ಕುಂತೀಪುತ್ರನಾದ ಅರ್ಜುನ! ಈ ಮನುಷ್ಯನು ಅಂತ್ಯಕಾಲದಲ್ಲಿ ಯಾವ ಯಾವ ಭಾವವನ್ನು ಸ್ಮರಣೆ ಮಾಡುತ್ತ ಶರೀರವನ್ನು ತ್ಯಜಿಸುತ್ತಾನೋ ಅದನ್ನೇ ಪಡೆಯುತ್ತಾನೆ; ಏಕೆಂದರೆ ಅವನು ಸದಾಕಾಲ ಅದೇ ಭಾವದಿಂದ ಭಾವಿತನಾಗಿದ್ದಾನೆ. 8.6",

      "ಆದ್ದರಿಂದ ಹೇ ಅರ್ಜುನನೇ ! ನೀನು ಎಲ್ಲ ಸಮಯದಲ್ಲಿ ನಿರಂತರವಾಗಿ ನನ್ನ ಸ್ಮರಣೆಮಾಡು ಮತ್ತು ಯುದ್ಧವನ್ನೂ ಮಾಡು. ಈ ಪ್ರಕಾರ ನನ್ನಲ್ಲಿ ಅರ್ಪಿತವಾದ ಮನಸ್ಸು-ಬುದ್ಧಿಯಿಂದ ಯುಕ್ತನಾಗಿ ನೀನು ನಿಃಸಂದೇಹವಾಗಿ ನನ್ನನ್ನೇ ಪಡೆಯುವೆ. 8.7",

      "ಹೇ ಪಾರ್ಥನೇ! ಪರಮೇಶ್ವರನ ಧ್ಯಾನದ ಅಭ್ಯಾಸ ರೂಪೀ ಯೋಗದಿಂದ ಯುಕ್ತನಾಗಿ ಬೇರೆಡೆಗೆ ಹೋಗದಿರುವ ಚಿತ್ತದಿಂದ ನಿರಂತರ ಚಿಂತನೆ ಮಾಡುತ್ತ ಮನುಷ್ಯನು ಪರಮ ಪ್ರಕಾಶ ಸ್ವರೂಪೀ ದಿವ್ಯ ಪುರುಷನನ್ನು ಅರ್ಥಾತ್ ಪರಮೇಶ್ವರನನ್ನೇ ಹೊಂದುತ್ತಾನೆ ಇದು ನಿಯಮವಾಗಿದೆ. 8.8",

      "ಹೇ ಪಾರ್ಥನೇ! ಪರಮೇಶ್ವರನ ಧ್ಯಾನದ ಅಭ್ಯಾಸ ರೂಪೀ ಯೋಗದಿಂದ ಯುಕ್ತನಾಗಿ ಬೇರೆಡೆಗೆ ಹೋಗದಿರುವ ಚಿತ್ತದಿಂದ ನಿರಂತರ ಚಿಂತನೆ ಮಾಡುತ್ತ ಮನುಷ್ಯನು ಪರಮ ಪ್ರಕಾಶ ಸ್ವರೂಪೀ ದಿವ್ಯ ಪುರುಷನನ್ನು ಅರ್ಥಾತ್ ಪರಮೇಶ್ವರನನ್ನೇ ಹೊಂದುತ್ತಾನೆ ಇದು ನಿಯಮವಾಗಿದೆ. 8.9",

      "ಆ ಭಕ್ತಿಯುತನಾದ ಪುರುಷನು ಅಂತ್ಯಕಾಲದಲ್ಲಿಯೂ ಯೋಗಬಲದಿಂದ ಭ್ರೂಮಧ್ಯದಲ್ಲಿ ಪ್ರಾಣವನ್ನು ಒಳ್ಳೆ ಪ್ರಕಾರದಿಂದ ಸ್ಥಾಪನೆ ಮಾಡಿ ಮತ್ತೆ ನಿಶ್ಚಲವಾದ ಮನಸ್ಸಿನಿಂದ ಸ್ಮರಣೆ ಮಾಡುತ್ತ ಆ ದಿವ್ಯ ಸ್ವರೂಪನಾದ ಪರಮ ಪುರುಷ ಪರಮಾತ್ಮನನ್ನೇ ಪಡೆಯುತ್ತಾನೆ. 8.10",

      "ವೇದವನ್ನು ತಿಳಿದ ವಿದ್ವಾಂಸರು ಯಾವ ಸಚ್ಚಿದಾನಂದ ಘನರೂಪೀ ಪರಮಪದವನ್ನು ಅವಿನಾಶೀ ಎಂದು ಹೇಳುತ್ತಾರೋ,  ಆಸಕ್ತಿರಹಿತರಾದ ಯತ್ನಶೀಲ ಸಂನ್ಯಾಸೀ ಮಹಾತ್ಮರು ಯಾವುದರಲ್ಲಿ ಪ್ರವೇಶ ಮಾಡುತ್ತಾರೋ ಮತ್ತು ಯಾವ ಪರಮಪದವನ್ನು ಬಯಸುವ ಬ್ರಹ್ಮಚಾರಿಗಳು ಬ್ರಹ್ಮಚರ್ಯದ ಆಚರಣೆಯನ್ನು ಮಾಡುತ್ತಾರೋ ಆ ಪರಮ ಪದವನ್ನು ನಾನು ನಿನಗಾಗಿ ಸಂಕ್ಷೇಪವಾಗಿ ಹೇಳುವೆನು. 8.11",

      "ಎಲ್ಲ ಇಂದ್ರಿಯಗಳ ದ್ವಾರಗಳನ್ನು ತಡೆದು ಹಾಗೂ ಮನಸ್ಸನ್ನು ಹೃದ್ದೇಶದಲ್ಲಿ ಸ್ಥಿರವಾಗಿಸಿ ಮತ್ತೆ ಆ ಗೆಲ್ಲಲ್ಪಟ್ಟ ಮನಸ್ಸಿನ ಮೂಲಕ ಪ್ರಾಣವನ್ನು ಮಸ್ತಕದಲ್ಲಿ ಸ್ಥಾಪಿಸಿಕೊಂಡು ಪರಮಾತ್ಮನ ಸಂಬಂಧವಾದ ಯೋಗಧಾರಣೆಯಲ್ಲಿ ಸ್ಥಿರನಾಗಿದ್ದು ಯಾವ ಪುರುಷನು ’ಓಂ’ ಎಂಬ ಈ ಏಕಾಕ್ಷರರೂಪೀ ಬ್ರಹ್ಮವನ್ನು ಉಚ್ಚರಿಸುತ್ತಾ ಮತ್ತು ಅದರ ಅರ್ಥಸ್ವರೂಪೀ ನಿರ್ಗುಣ ಬ್ರಹ್ಮನಾದ ನನ್ನನ್ನು ಚಿಂತನೆ ಮಾಡುತ್ತಾ ಶರೀರವನ್ನು ತ್ಯಜಿಸಿ ಹೋಗುತ್ತಾನೋ ಆ ಪುರುಷನು ಪರಮಗತಿಯನ್ನು ಹೊಂದುತ್ತಾನೆ. (8.12-8.13)",

      "ಎಲ್ಲ ಇಂದ್ರಿಯಗಳ ದ್ವಾರಗಳನ್ನು ತಡೆದು ಹಾಗೂ ಮನಸ್ಸನ್ನು ಹೃದ್ದೇಶದಲ್ಲಿ ಸ್ಥಿರವಾಗಿಸಿ ಮತ್ತೆ ಆ ಗೆಲ್ಲಲ್ಪಟ್ಟ ಮನಸ್ಸಿನ ಮೂಲಕ ಪ್ರಾಣವನ್ನು ಮಸ್ತಕದಲ್ಲಿ ಸ್ಥಾಪಿಸಿಕೊಂಡು ಪರಮಾತ್ಮನ ಸಂಬಂಧವಾದ ಯೋಗಧಾರಣೆಯಲ್ಲಿ ಸ್ಥಿರನಾಗಿದ್ದು ಯಾವ ಪುರುಷನು ’ಓಂ’ ಎಂಬ ಈ ಏಕಾಕ್ಷರರೂಪೀ ಬ್ರಹ್ಮವನ್ನು ಉಚ್ಚರಿಸುತ್ತಾ ಮತ್ತು ಅದರ ಅರ್ಥಸ್ವರೂಪೀ ನಿರ್ಗುಣ ಬ್ರಹ್ಮನಾದ ನನ್ನನ್ನು ಚಿಂತನೆ ಮಾಡುತ್ತಾ ಶರೀರವನ್ನು ತ್ಯಜಿಸಿ ಹೋಗುತ್ತಾನೋ ಆ ಪುರುಷನು ಪರಮಗತಿಯನ್ನು ಹೊಂದುತ್ತಾನೆ. (8.12-8.13)",

      "ಹೇ ಅರ್ಜುನನೇ! ಯಾವ ಪುರುಷನು ನನ್ನಲ್ಲಿ ಅನನ್ಯಚಿತ್ತನಾಗಿ ಸದಾಕಾಲ ನಿರಂತರ ಪುರುಷೋತ್ತಮನಾದ ನನ್ನನ್ನು ಸ್ಮರಿಸುತ್ತಾನೋ, ಆ ನಿತ್ಯ-ನಿರಂತರ ನನ್ನಲ್ಲಿ ಯುಕ್ತನಾದ ಯೋಗಿಗೆ ನಾನು ಸುಲಭನಾಗಿದ್ದೇನೆ ಅರ್ಥಾತ್ ಅವನಿಗೆ ಸಹಜವಾಗಿಯೇ ಪ್ರಾಪ್ತನಾಗುತ್ತೇನೆ. 8.14",

      "ಪರಮ ಸಿದ್ಧಿಯನ್ನು ಪಡೆದ ಮಹಾತ್ಮಾ ಜನರು ನನ್ನನ್ನು ಪಡೆದುಕೊಂಡು ದುಃಖದ ಆಲಯವೂ ಮತ್ತು ಕ್ಷಣಭಂಗುರವೂ ಆದ ಪುನರ್ಜನ್ಮವನ್ನು ಪಡೆಯುವುದಿಲ್ಲ. 8.15",

      "ಹೇ ಅರ್ಜುನನೇ! ಬ್ರಹ್ಮಲೋಕದವರೆಗೆ ಎಲ್ಲ ಲೋಕಗಳು ಪುನರಾವರ್ತಿಗಳಾಗಿವೆ, ಆದರೆ, ಹೇ ಕುಂತೀಪುತ್ರನೇ ! ನನ್ನನ್ನು ಪಡೆದ ನಂತರ ಪುನರ್ಜನವು ಇರುವುದಿಲ್ಲ; ಏಕೆಂದರೆ ನಾನು ಕಾಲಾತೀತನಾಗಿದ್ದೇನೆ ಮತ್ತು ಈ ಎಲ್ಲ ಬ್ರಹ್ಮಾದಿ ಲೋಕಗಳು ಕಾಲನ ಮೂಲಕ ಸೀಮಿತವಾಗಿರುವುದರಿಂದ ಅನಿತ್ಯವಾಗಿವೆ. 8.16",

      "ಬ್ರಹ್ಮದೇವರ ಒಂದು ಹಗಲನ್ನು ಒಂದು ಸಾವಿರ ಚತುರ್ಯುಗಗಳವರೆಗಿನ ಅವಧಿಯೆಂದೂ ಮತ್ತು ರಾತ್ರಿಯನ್ನು ಕೂಡ ಒಂದು ಸಾವಿರ ಚತುರ್ಯುಗಗಳ ವರೆಗಿನ ಅವಧಿಯೆಂದೂ ಯಾವ ಪುರುಷನು ತತ್ವದಿಂದ ತಿಳಿಯುತ್ತಾರೋ, ಆ ಯೋಗಿಜನರು ಕಾಲದ ತತ್ವವನ್ನು ತಿಳಿದವರಾಗಿದ್ದಾರೆ. 8.17",

      "ಸಂಪೂರ್ಣ ಚರಾಚರ ಪ್ರಾಣಿಗಳು ಬ್ರಹ್ಮನ ಹಗಲಿನ ಪ್ರವೇಶಕಾಲದಲ್ಲಿ ಅವ್ಯಕ್ತದಿಂದ ಅರ್ಥಾತ್ ಬ್ರಹ್ಮನ ಸೂಕ್ಷ್ಮ ಶರೀರದಿಂದ ಉತ್ಪನ್ನವಾಗುತ್ತವೆ ಮತ್ತು ಬ್ರಹ್ಮನ ರಾತ್ರಿಯ ಪ್ರವೇಶಕಾಲದಲ್ಲಿ ಆ ಅವ್ಯಕ್ತ ಹೆಸರಿನ ಬ್ರಹ್ಮನ ಸೂಕ್ಷ ಶರೀದದಲ್ಲಿಯೇ ಲೀನವಾಗುತ್ತವೆ. 8.18",

      "ಹೇ ಪಾರ್ಥನೇ ! ಅದೇ ಈ ಪ್ರಾಣಿಸಮುದಾಯವು ಉತ್ಪನ್ನವಾಗುತ್ತ್ ಪ್ರಕೃತಿಯ ವಶದಲ್ಲಿದ್ದು ರಾತ್ರಿಯ ಪ್ರವೇಶಕಾಲದಲ್ಲಿ ಲೀನವಾಗಿ ಹೋಗುತ್ತದೆ, ಮತ್ತು ಹಗಲಿನ ಪ್ರವೇಶಕಾಲದಲ್ಲಿ ಮತ್ತೆ ಉತ್ಪನ್ನವಾಗುತ್ತದೆ. 8.19",

      "ಆದರೆ ಆ ಅವ್ಯಕ್ತಕ್ಕಿಂತಲೂ ಕೂಡ ಅತಿಶ್ರೇಷ್ಠವಾದ ಇನ್ನೊಂದು ಅರ್ಥಾತ್ ವಿಲಕ್ಷಣವಾದ, ಯಾವ ಸನಾತನ ಅವ್ಯಕ್ತಭಾವವಾಗಿದೆಯೋ ಆ ಪರಮ ದಿವ್ಯಪುರುಷನು ಎಲ್ಲ ಭೂತಗಳು ನಷ್ಟವಾದರೂ ನಾಶವಾಗುವುದಿಲ್ಲ. 8.20",

      "ಯಾವ ಅವ್ಯಕ್ತವು ’ ಅಕ್ಷರ’ ವೆಂಬ ಹೆಸರಿನಿಂದ ಹೇಳಲಾಗಿದೆಯೋ ಅದೇ ಅಕ್ಷರವೆಂಬ ಹೆಸರಿನ ಅವ್ಯಕ್ತಭಾವಬನ್ನು ಪರಮಗತಿಯೆಂದು ಹೇಳುತ್ತಾರೆ ಹಾಗೂ ಯಾವ ಸನಾತನವಾದ ಅವ್ಯಕ್ತಭಾವವನ್ನು ಪಡೆದ ಮನುಷ್ಯನು ಪುನರ್ಜನ್ಮ ಪಡೆಯುವುದಿಲ್ಲವೋ ಅದೇ ನನ್ನ ಪರಮಧಾಮವಾಗಿದೆ. 8.21",

      "ಹೇ ಪಾರ್ಥನೇ! ಯಾವ ಪರಮಾತ್ಮನ ಅಂತರ್ಗತವಾಗಿ ಎಲ್ಲ ಜೇವಿಗಳಿವೆಯೋ ಮತ್ತು ಯಾವ ಸಚ್ಚಿದಾನಂದಘನ ಪರಮಾತ್ಮನಿಂದ ಈ ಸಮಸ್ತ ಜಗತ್ತು ಪರಿಪೂರ್ಣ ಪರಿಪೂರ್ಣವಾಗಿದೆಯೋ ಆ ಸನಾತನನೂ, ಅವ್ಯಕ್ತನೂ ಆದ ಪರಮ ಪುರುಷನಾದರೋ ಅನನ್ಯವಾದ ಭಕ್ತಿಯಿಂದಲೇ ಪ್ರಾಪ್ತನಾಗಲು ಯೋಗ್ಯನಾಗಿದ್ದಾನೆ. 8.22",

      "ಹೇ ಅರ್ಜುನನೇ! ಯಾವ ಕಾಲದಲ್ಲಿ ಶರೀರವನ್ನು ತ್ಯಾಗಮಾಡಿ ಹೊರಟುಹೋದ ಯೋಗಿಜನರಾದರೋ ಹಿಂದಿರುಗಿ ಬಾರದಿರುವ ಗತಿಯನ್ನು ಮತ್ತು ಯಾವ ಕಾಲದಲ್ಲಿ ಹೊರಟು ಹೋದವರು ಹಿಂದಿರುಗಿ ಬರುವ ಗತಿಯನ್ನೇ ಪಡೆಯುತ್ತಾರೆಯೋ ಆ ಕಾಲವನ್ನು ಅರ್ಥಾತ್ ಎರಡೂ ಮಾರ್ಗಗಳನ್ನು ಹೇಳುವೆನು. 8.23",

      "ಯಾವ ಮಾರ್ಗದಲ್ಲಿ ಜ್ಯೋತಿರ್ಮಯ ಅಗ್ನಿ ಅಭಿಮಾನಿ ದೇವತೆಯಾಗಿದ್ದಾನೋ, ಹಗಲಿನ ಅಭಿಮಾನ್ ದೇವತೆಯಾಗಿದ್ದಾನೋ, ಶುಕ್ಲಪಕ್ಷದ ಅಭಿಮಾಗಿ ದೇವತೆಯಿದ್ದಾನೋ ಮತ್ತು ಉತ್ತರಾಯಣದ ಆರು ತಿಂಗಳ ಅಭಿಮಾನೀ ದೇವತೆಯಾಗಿದ್ದಾನೋ ಆ ಮಾರ್ಗದಲ್ಲಿ ಸತ್ತಬಳಿಕ ಹೋದ ಬ್ರಹ್ಮವೇತ್ತರಾದ ಯೋಗಿಜನರು ಮೇಲೆ ಹೇಳಿದ ದೇವತೆಗಳ ಮೂಲಕ ಕ್ರಮವಾಗಿ ಕರೆದುಕೊಂಡು ಹೋದವರಾಗಿ ಬ್ರಹ್ಮವನ್ನು ಪಡೆಯುತ್ತಾರೆ. 8.24",

      "ಯಾವ ಮಾರ್ಗದಲ್ಲಿ ಧೂಮಾಭಿಮಾನೀ ದೇವತೆಯಾಗಿದೆಯೋ, ರಾತ್ರಿಯ ಅಭಿಮಾನೀ ದೇವತೆಯಾಗಿದೆಯೋ, ಹಾಗೂ ಕೃಷ್ಣಪಕ್ಷದ ಅಭಿಮಾನೀ ದೇವತೆಯಾಗಿದೆಯೋ, ದಕ್ಷಿಣಾಯನದ ಆರು ತಿಂಗಳ ಅಭಿಮಾನೀ ದೇವತೆಯಾಗಿದೆಯೋ, ಆ ಮಾರ್ಗದಲ್ಲಿ - ಸತ್ತು ಪ್ರಯಾಣ ಬೆಳೆಸಿದ ಸಕಾಮ ಕರ್ಮ ಮಾಡಿದ ಯೋಗಿಗು - ಮೇಲೆ ತಿಳಿಸಿದ ದೇವತೆಗಳ ಮೂಲಕ ಕ್ರಮವಾಗಿ ಕರೆದುಕೊಂಡು ಹೋದವರಾಗಿ ಚಂದ್ರನ ಜ್ಯೋತಿಯನ್ನು ಪಡೆದುಕೊಂಡು, ಸ್ವರ್ಗದಲ್ಲಿ ತಮ್ಮ ಶುಭ ಕರ್ಮಗಳ ಫಲವನ್ನು ಭೋಗಿಸಿ ಹಿಂದಿರುಗುತ್ತಾರೆ. 8.25",

      "ಏಕೆಂದರೆ ಜಗತ್ತಿನ ಈ ಎರಡು ಪ್ರಕಾರದ, ಶುಕ್ಲ ಮತ್ತು ಕೃಷ್ಣ - ಅರ್ಥಾತ್ ದೇವಯಾನ ಮತ್ತು ಪಿತೃಯಾನ ಮಾರ್ಗಗಳು ಸನಾತನವಾದುವು ಎಂದು ತಿಳಿಯಲಾಗಿದೆ. ಇವುಗಳಲ್ಲಿ ಒಂದರ ಮೂಲಕ ಪ್ರಯಾಣ ಬೆಳೆಸಿದವರು ಅರ್ಥಾತ್ ಅರ್ಚಿಮಾರ್ಗದಿಂದ ಹೋದವರು ಮರಳಿ ಬರಬೇಕಾಗಿರುವುದಿಲ್ಲವೋ ಅಂತಹ ಆ ಪರಮಗತಿಯನ್ನು ಪಡೆಯುತ್ತಾರೆ ಮತ್ತು ಎರಡನೇ ಮಾರ್ಗದ ಮೂಲಕ ಹೊರಟು ಹೋದವನು ಅರ್ಥಾತ್ ಧೂಮ ಮಾರ್ಗದಿಂದ ಹೋದ ಸಕಾಮಕರ್ಮಿಯು ಮತ್ತೆ ಹಿಂದಿರುಗಿ ಬರುತ್ತಾನೆ ಅರ್ಥಾತ್ ಜನ್ಮ-ಮೃತ್ಯುವನ್ನು ಪಡೆಯುತ್ತಾನೆ. 8.26",

      "ಹೇ ಪಾರ್ಥನೆ! ಈ ಪ್ರಕಾರದ ಎರಡೂ ಮಾರ್ಗಗಳನ್ನು ತತ್ವದಿಂದ ತಿಳಿದುಕೊಂಡ ಯಾವುದೇ ಯೋಗಿಯು ಮೋಹಿತನಾಗುವುದಿಲ್ಲ. ಈ ಕಾರಣದಿಂದ ಹೇ ಅರ್ಜುನನೇ! ಎಲ್ಲ ಕಾಲದಲ್ಲಿ ನೀನು ಸಮಬುದ್ಧಿರೂಪವಾದ ಯೋಗದಿಂದ ಯುಕ್ತನಾನು, ಅರ್ಥಾತ್ ನಿರಂತರ ನನ್ನ ಪ್ರಾಪ್ತಿಗಾಗಿ ಸಾಧನೆಮಾಡು. 8.27",

      "ಯೋಗೀ ಪುರುಷನು ಈ ರಹಸ್ಯವನ್ನು ತತ್ವದಿಂದ ತಿಳಿದುಕೊಂಡು ವೇದಗಳ ಅಧ್ಯಯನ ಹಾಗೂ ಯಜ್ಞ, ತಪಸ್ಸು ಮತ್ತು ದಾನಾದಿಗಳನ್ನು ಮಾಡುವುದರಲ್ಲಿ ಯಾವ ಪುಣ್ಯಫಲಗಳನ್ನು ಹೇಳಿದೆಯೋ ಅದೆಲ್ಲವನ್ನೂ ನಿಃಸಂದೇಹವಾಗಿ ದಾಟಿ ಹೋಗುತ್ತಾನೆ ಮತ್ತು ಸನಾತನವಾದ ಪರಮಪದವನ್ನು ಪಡೆಯುತ್ತಾನೆ. 8.28",

      "ಇತಿ ಶ್ರೀಮದ್ಭಗವದ್ಗೀತಾರೂಪೀ ಉಪನಿಷದ್ ಬ್ರಹ್ಮವಿದ್ಯಾ ಹಾಗೂ ಯೋಗಶಾಸ್ತ್ರ ವಿಷಯದಲ್ಲಿ ಶ್ರೀ ಕೃಷ್ಣ ಮತ್ತು ಅರ್ಜುನರ ಸಂವಾದದಲ್ಲಿ ಅಕ್ಷರಬ್ರಹ್ಮಯೋಗ ಎಂಬ ಎಂಟನೇ ಅಧ್ಯಾಯ ಸಂಪೂರ್ಣ"
    ],
    "adhyaya-9" : [
      "ಶ್ರೀಕೃಷ್ಣ ಭಗವಾನರು ಹೇಳುತ್ತಾರೆ, ದೋಷರಹಿತ ದೃಷ್ಟಿಕೋನವುಳ್ಳ ಭಕ್ತನಾದ ನಿನಗೆ ಅತ್ಯಂತ ಗೋಪ್ಯವಾದ ಈ ವಿಜ್ಞಾನ ಸಹಿತ ಜ್ಞಾನವನ್ನು ವಿವರವಾಗಿ ತಿಳಿಸುವೆನು. ಏಕೆಂದರೆ, ಅದನ್ನು ತಿಳಿದುಕೊಂಡು ನೀನು ದುಃಖಮಯ ಸಂಸಾರದಿಂದ ಮುಕ್ತನಾಗುವೆ 9.1",

      "ಈ ವಿಜ್ಞಾನ ಸಹಿತ ಜ್ಞಾನವು ಎಲ್ಲ ವಿದ್ಯೆಗಳ ಹಾಗು ಎಲ್ಲ ರಹಸ್ಯಗಳ ರಾಜ. ಪರಮ ಪವಿತ್ರ, ಅತ್ಯುತ್ತಮ, ಪ್ರತ್ಯಕ್ಷ ಫಲಕಾರಿ, ಮತ್ತು ಧರ್ಮಯುಕ್ತವಾದದ್ದು. ಸಾಧನೆ ಮಾಡಲು ಬಹಳ ಸುಲಭ ಹಾಗು ಅವಿನಾಶಿ 9.2",

      "ಎಲೈ ಪರಂತಪನೆ, ಈ ತತ್ತ್ವಜ್ಞಾನರೂಪಿಯಾದ ಧರ್ಮದಲ್ಲಿ ಶ್ರದ್ಧೆಯಿಲ್ಲದ ಪುರುಷರು ನನ್ನನ್ನು, ಅಂದರೆ ನನ್ನ ಸಾನಿಧ್ಯವನ್ನು ಪಡೆಯದೆ ಮೃತ್ಯುರೂಪಿ ಸಂಸಾರಚಕ್ರದಲ್ಲಿ ಸುತ್ತುತ್ತಿರುತ್ತಾರೆ 9.3",

      "ನಿರಾಕಾರ ಸಚ್ಚಿದಾನಂದ ಪರಮಾತ್ಮನಾದ ನನ್ನಿಂದ ಈ ಇಡೀ ಜಗತ್ತು ನೀರಿನಿಂದಾದ ಹಿಮದ ಗಡ್ಡೆಯಂತೆ ಪರಿಪೂರ್ಣವಾಗಿದೆ ಮತ್ತು ಚರಾಚರಗಳೆಲ್ಲವೂ ನನ್ನಲ್ಲಿ ಸಂಕಲ್ಪದ ಆಧಾರದಿಂದ ಅಂತರ್ಗತವಾಗಿವೆ. ಆದರೆ ವಾಸ್ತವವಾಗಿ ನಾನು ಅವುಗಳಲ್ಲಿ ಇರವುದಿಲ್ಲ 9.4",

      "ಆ ಪ್ರಾಣಿಗಳೆಲ್ಲವೂ ನನ್ನಲ್ಲಿ ಇರುವುದಿಲ್ಲ. ಆದರೆ ನನ್ನ ಈಶ್ವರೀಯ ಯೋಗಶಕ್ತಿಯನ್ನು ನೋಡು; ಜೀವಿಗಳ ಪಾಲನೆ ಪೋಷಣೆ ಮಾಡುವ ಮತ್ತು ಜೀವಿಗಳನ್ನು ಉತ್ಪತ್ತಿ ಮಾಡುವ ನನ್ನ ಆತ್ಮ ವಾಸ್ತವವಾಗಿಯೂ ಜೀವಿಗಳಲ್ಲಿ ಇಲ್ಲ 9.5",

      "ಯಾವ ರೀತಿಯಲ್ಲಿ ಆಕಾಶದಿಂದ ಉತ್ಪತ್ತಿಯಾಗಿ ಎಲ್ಲೆಡೆ ಸಂಚರಿಸುವ ಮಹಾನ್ ವಾಯುವು ಸದಾ ಆಕಾಶದಲ್ಲಿರುವುದೋ, ಹಾಗೆ ನನ್ನ ಸಂಕಲ್ಪದಿಂದ ಉತ್ಪತ್ತಿಯಾದುದರಿಂದ ಸಮಸ್ತ ಜೀವಿಗಳೂ ನನ್ನಲ್ಲಿಯೇ ಇರುತ್ತವೆ ಎಂದು ತಿಳಿದುಕೊ 9.6",

      "ಅರ್ಜುನನೇ, ಕಲ್ಪದ ಅಂತ್ಯದಲ್ಲಿ ಜೀವಿಗಳೆಲ್ಲವೂ ನನ್ನ ಪ್ರಕೃತಿಯನ್ನು ಪಡೆಯುತ್ತವೆ. ಅರ್ಥಾತ್ ಪ್ರಕೃತಿಯಲ್ಲಿ ಲಯವಾಗುತ್ತವೆ. ಕಲ್ಪದ ಆದಿಯಲ್ಲಿ ಅವುಗಳನ್ನು ನಾನು ಪುನಃ ರಚಿಸುತ್ತೇನೆ 9.7",

      "ನನ್ನ ತ್ರಿಗುಣಮಯೀ ಮಾಯೆಯನ್ನು ಅಂಗೀಕರಿಸಿ ಸ್ವಭಾವ ವಶದಿಂದ ಪರತಂತ್ರವಾಗಿರುವ ಈ ಸಮಸ್ತ ಪ್ರಾಣಿ ಸಮುದಾಯವನ್ನು ಮತ್ತೆ ಮತ್ತೆ ಅವುಗಳ ಕರ್ಮಗಳಿಗೆ ಅನುಸಾರವಾಗಿ ರಚಿಸುತ್ತೇನೆ 9.8",

      "ಎಲೈ ಅರ್ಜುನನೆ, ಆ ಕರ್ಮಗಳಲ್ಲಿ ಆಸಕ್ತಿಯಿಲ್ಲದ ಮತ್ತು ಉದಾಸೀನದೋಪಾದಿಯಲ್ಲಿರುವ ನನ್ನನ್ನು ಆ ಕರ್ಮಗಳು ಬಂಧಿಸುವುದಿಲ್ಲ 9.9",

      "ಹೇ ಅರ್ಜುನ, ನಿಯಾಮಕನಾದ ನನ್ನ ಅಧ್ಯಕ್ಷತೆಯ ಸ್ಪೂರ್ತಿಯಿಂದ ನನ್ನ ಈ ಪ್ರಕೃತಿ ಮಾಯೆಯು ಚರಾಚರ ಸಹಿತ ಈ ಇಡೀ ಜಗತ್ತನ್ನು ರಚಿಸುತ್ತದೆ. ಈ ಕಾರಣಗಳಿಂದ ಈ ಸಂಸಾರಚಕ್ರ ಸುತ್ತುತ್ತಾ ಇದೆ 9.10",

      "ಇಡೀ ಜೀವಕೋಟಿಗೆಲ್ಲಾ ಮಹೇಶ್ವರನಾದ ನನ್ನ ಪ್ರೇಮ ಭಾವವನ್ನು ತಿಳಿಯದ ಮೂಢರು ಮನುಷ್ಯ ರೂಪವನ್ನು ಅರ್ಥಾತ್ ಮಾನವ ಶರೀರವನ್ನು ಧರಿಸಿರುವ ಪರಮಾತ್ಮನಾದ ನನ್ನನ್ನು ಕೀಳಾಗಿ ಕಾಣುತ್ತಾರೆ. ಅಂದರೆ ತನ್ನ ಯೋಗಮಾಯೆಯಿಂದ ಜಗತ್ತಿನ ಉದ್ಧಾರಕ್ಕಾಗಿ ಮನುಷ್ಯರೂಪದಲ್ಲಿ ನಡೆದುಕೊಳ್ಳುವ ನನ್ನನ್ನು ಸಾಧಾರಣ ಮನುಷ್ಯನೆಂದು ಭಾವಿಸುತ್ತಾರೆ 9.11",

      "ವ್ಯರ್ಥವಾದ ಆಸೆ, ವ್ಯರ್ಥವಾದ ಕಾರ್ಯ ಮತ್ತು ವ್ಯರ್ಥವಾದ ಜ್ಞಾನವುಳ್ಳ ಅಜ್ಞಾನಿಗಳು ರಾಕ್ಷಸರ ಮತ್ತು ಅಸುರರಂತಹ ಮೋಹಕಾರೀ ತಾಮಸ ಸ್ವಭಾವವನ್ನೇ ಆಶ್ರಯಿಸುತ್ತಾರೆ 9.12",

      "ಆದರೆ ಎಲೈ ಕುಂತೀಪುತ್ರ ! ದೈವೀ ಪ್ರಕೃತಿಯ ಆಶ್ರಯ ಪಡೆದ ಮಹಾತ್ಮರು ನನ್ನನ್ನು ಎಲ್ಲಾ ಜೀವಿಗಳಿಗೂ ಸನಾತನ ಮೂಲಕಾರಣನೆಂದೂ, ನಾಶವಿಲ್ಲದ ಅಕ್ಷರ ಸ್ವರೂಪಿಯೆಂದೂ ತಿಳಿದುಕೊಂಡು ಅನನ್ಯ ಮನಸ್ಕರಾಗಿ ನಿರಂತರ ಭಜಿಸುತ್ತಾರೆ. 9.13",

      "ದೃಢನಿಶ್ಚಯವುಳ್ಳ ಭಕ್ತರು ನಿರಂತರ ನನ್ನ ನಾಮ ಮತ್ತು ಗುಣಗಳ ಕೀರ್ತನೆ ಮಾಡುತ್ತಾ ಮತ್ತು ನನ್ನ ಸಾಕ್ಷಾತ್ಕಾರಕ್ಕಾಗಿ ಪ್ರಯತ್ನಿಸುತ್ತಾ ಹಾಗೂ ನನಗೆ ಪದೇ-ಪದೇ ನಮಸ್ಕರಿಸುತ್ತಾ ಯಾವಾಗಲೂ ನನ್ನ ಧ್ಯಾನದಲ್ಲಿ ನಿರತರಾಗಿ ಅನನ್ಯ ಭಕ್ತಿಯಿಂದ ನನ್ನನ್ನು ಆರಾಧಿಸುತ್ತಾರೆ. 9.14",

      "ಬೇರೆ ಜ್ಞಾನಯೋಗಿಗಳು ನನ್ನನ್ನು ಅರ್ಥಾತ್ ನಿರ್ಗುಣ ನಿರಾಕಾರ ಬ್ರಹ್ಮನನ್ನು ಜ್ಞಾನದ ಮೂಲಕ ಅಭಿನ್ನ ಭಾವದಿಂದ ಆರಾಧಿಸುತ್ತಿದ್ದರೂ ಸಹ ನನ್ನ ಉಪಾಸನೆಯನ್ನೇ ಮಾಡುತ್ತಾರೆ. ಮತ್ತು ಬೇರೆಯವರು ಬಹಳ ಪ್ರಕಾರಗಳಿಂದ ವಿರಾಟ್ ಸ್ವರೂಪೀ ಪರಮೇಶ್ವರನಾದ ನನ್ನನ್ನು ಬೇರೆ ಭಾವದಿಂದ ಉಪಾಸನೆ ಮಾಡುತ್ತಾರೆ. 9.15",

      "ಕ್ರತು ಅರ್ಥಾತ್ ಶ್ರೌತಕರ್ಮ ನಾನು, ಯಜ್ಞ ಅರ್ಥಾತ್ ಪಂಚಮಹಾ ಯಜ್ಞಾದಿ ಸ್ಮಾರ್ತಕರ್ಮ ನಾನು, ಸ್ವಧಾ ಅರ್ಥಾತ್ ಪಿತೃಗಳ ನಿಮಿತ್ತ ಅರ್ಪಿಸುವ ತರ್ಪಣ ನಾನೇ, ಔಷಧಿ ಅರ್ಥಾತ್ ವನಸ್ಪತಿಗಳೆಲ್ಲಾ ನಾನೇ ಮತ್ತು ಮಂತ್ರವೂ ನಾನೇ, ತುಪ್ಪವೂ, ಅಗ್ನಿಯೂ ಮತ್ತು ಹವನರೂಪೀ ಕ್ರಿಯೆಯೂ ಸಹ ನಾನೇ ಆಗಿದ್ದೇನೆ. 9.16",

      "ಈ ಜಗತ್ತಿಗೆಲ್ಲಾ ಧಾತಾ ಅರ್ಥಾತ್ ಪಾಲನೆ-ಪೋಷಣೆ ಮಾಡುವವನು ಮತ್ತು ಕರ್ಮಗಳ ಫಲದಾಯಕ, ತಂದೆ, ತಾಯಿ, ಪಿತಾಮಹ ಮತ್ತು ತಿಳಿಯಲು ಯೋಗ್ಯನಾದವ, ಪವಿತ್ರ, ಓಂಕಾರ, ಋಗ್ವೇದ, ಸಾಮವೇದ ಮತ್ತು ಯಜುರ್ವೇದವೂ ಸಹ ನಾನೇ ಆಗಿದ್ದೇನೆ. 9.17",

      "ಪಡೆಯಲು ಯೋಗ್ಯವಾದ ಪವಿತ್ರಧಾಮ ಹಾಗೂ ಪಾಲನೆ-ಪೋಷಣೆ ಮಾಡುವವನೂ, ಎಲ್ಲರೊಡೆಯನೂ, ಶುಭಾಶುಭಗಳ ಪ್ರೇಕ್ಷಕನೂ, ಎಲ್ಲರ ವಾಸಸ್ಥಾನವೂ, ಶರಣಾಗಲು ಯೋಗ್ಯನೂ, ಪ್ರತ್ಯುಪಕಾರ ಬಯಸದ ಹಿತೈಷಿಯೂ, ಉತ್ಪತ್ತಿಯೂ, ಪ್ರಳಯರೂಪಿಯೂ, ಎಲ್ಲಕ್ಕೂ ಆಧಾರ, ನಿಧಾನ ಮತ್ತು ಅವಿನಾಶೀ ಕಾರಣವೂ ಸಹ ನಾನೇ ಆಗಿದ್ದೇನೆ 9.18",

      "ನಾನು ಸೂರ್ಯನ ರೂಪದಿಂದ ತಪಿಸುತ್ತೇನೆ ಅರ್ಥಾತ್ ಶಾಖ ಕೊಡುತ್ತೇನೆ, ಮಳೆಯನ್ನು ಆಕರ್ಷಿಸುತ್ತೇನೆ ಮತ್ತು ಸುರಿಸುತ್ತೇನೆ. ಎಲೈ ಅರ್ಜುನ ! ನಾನು ಅಮೃತ ಮತ್ತು ಮೃತ್ಯು ಹಾಗೂ ಸತ್ ಮತ್ತು ಅಸತ್ ಎಲ್ಲವೂ ಸಹ ನಾನೇ ಆಗಿದ್ದೇನೆ. 9.19",

      "ಮೂರು ವೇದಗಳಲ್ಲಿಯೂ ತಿಳಿಸಿರುವ ಸಕಾಮಕರ್ಮಗಳನ್ನು ಮಾಡುವ ಮತ್ತು ಸೋಮರಸವನ್ನು ಪಾನ ಮಾಡುವ ಪಾಪರಹಿತ ಪುರುಷರು ನನ್ನನ್ನು ಯಜ್ಞಗಳ ಮೂಲಕ ಆರಾಧಿಸಿ ಸ್ವರ್ಗವನ್ನು ಬಯಸುತ್ತಾರೆ. ಅವರು ತಮ್ಮ ಪುಣ್ಯ ಫಲದಿಂದ ಸ್ವರ್ಗಲೋಕವನ್ನು ಪಡೆದು ಸ್ವರ್ಗದಲ್ಲಿ ದಿವ್ಯವಾದ ದೇವತೆಗಳ ಸುಖವನ್ನು ಅನುಭವಿಸುತ್ತಾರೆ 9.20",

      "ಅವರು ಆ ವಿಶಾಲವಾದ ಸ್ವರ್ಗಸುಖವನ್ನು ಅನುಭವಿಸಿ ಪುಣ್ಯಫಲಗಳೆಲ್ಲ ಮುಗಿದ ನಂತರ ಮೃತ್ಯುಲೋಕವನ್ನು ಪುನಃ ಪಡೆಯುತ್ತಾರೆ. ಈ ರೀತಿ ಸ್ವರ್ಗಕ್ಕೆ ಸಾಧನ ರೂಪಿಯಾದ ಮೂರು ವೇದಗಳಲ್ಲಿಯೂ ಹೇಳಲಾಗಿರುವ ಸಕಾಮ ಕರ್ಮಗಳನ್ನೇ ಆಶ್ರಯಿಸಿರುವವರು ಮತ್ತು ಭೋಗಾಪೇಕ್ಷೆಯುಳ್ಳವರು ಪದೇ-ಪದೇ ಜನನ - ಮರಣಗಳನ್ನೂ ಪಡೆಯುತ್ತಾರೆ. ಅರ್ಥಾತ್ ಪುಣ್ಯದ ಪ್ರಭಾವದಿಂದ ಸ್ವರ್ಗವನ್ನು ಸೇರುತ್ತಾರೆ ಮತ್ತು ಪುಣ್ಯಫಲ ಮುಗಿದ ಮೇಲೆ ಮೃತ್ಯು ಲೋಕಕ್ಕೆ ಮರಳುತ್ತಾರೆ. 9.21",

      "ಯಾರು ಅನನ್ಯಭಾವದಿಂದ ನನ್ನಲ್ಲೇ ಸ್ಥಿರಗೊಂಡ ಭಕ್ತರು ಪರಮೇಶ್ವರನಾದ ನನ್ನ ಚಿಂತನೆಯನ್ನು ಸದಾ ಮಾಡುತ್ತಾ ನಿಷ್ಕಮಭಾವದಿಂದ ನನ್ನಲ್ಲಿಯೇ ಸ್ಥಿರಮನಸ್ಕರಾಗಿರುವವರೊ ಅವರ ಯೋಗಕ್ಷೇಮವನ್ನು ನಾನೇ ಸ್ವತಃ ವಹಿಸಿಕೊಳ್ಳುತ್ತೇನೆ. 9.22",

      "ಎಲೈ ಅರ್ಜುನ ! ಶ್ರದ್ಧಾವಂತರಾದ ಸಕಾಮಿ ಭಕ್ತರು ಬೇರೆ ಬೇರೆ ದೇವತೆಗಳನ್ನು ಆರಾಧಿಸಿದರೂ ಅವರೂ ಸಹ ನನ್ನನ್ನೇ ಆರಾಧಿಸುತ್ತಾರೆ. ಆದರೆ ಅವರ ಆರಾಧನೆಯು ಅವಿಧಿ ಪೂರ್ವಕವಾದದ್ದೂ ಅರ್ಥಾತ್ ಅಜ್ಞಾನಪೂರ್ವಕವಾದದ್ದು. 9.23",

      "ಏಕೆಂದರೆ, ಎಲ್ಲಾ ಯಜ್ಞಗಳನ್ನೂ ಸ್ವೀಕರಿಸುವವನೂ ಮತ್ತು ಒಡೆಯನೂ ಸಹ ನಾನೇ. ಆದರೆ ಅವರು ಅಂತರ್ಯಾಮಿ ಪರಮೇಶ್ವರನಾದ ನನ್ನನ್ನು ತತ್ವತಃ ಅರ್ಥತ್ ಯಥಾರ್ಥವಾಗಿ ತಿಳಿದುಕೊಳ್ಳುವುದಿಲ್ಲ, ಆದುದರಿಂದ ಅವನತಿ ಪಡೆಯುತ್ತಾರೆ ಅಂದರೆ ಪುನರ್ಜನ್ಮವುಂಟಾಗುತ್ತದೆ. 9.24",

      "ದೇವತೆಗಳ ಆರಾಧಕರು ದೇವತೆಗಳನ್ನು ಪಡೆಯುತ್ತಾರೆ, ಪಿತೃಗಳ ಆರಾಧಕರು ಪಿತೃಗಳನ್ನೂ ಹೊಂದುತ್ತಾರೆ, ಭೂತಗಳ ಆರಾಧಕರು ಭೂತಗಳನ್ನೂ ಪಡೆಯುತ್ತಾರೆ ಮತ್ತು ನನ್ನ ಭಕ್ತರು ನನ್ನನ್ನೇ ಪಡೆಯುತ್ತಾರೆ. ಆದುದರಿಂದ ನನ್ನ ಭಕ್ತರಿಗೆ ಪುನರ್ಜನ್ಮವಿಲ್ಲ. 9.25",

      "ತುಳಸೀ- ಬಿಲ್ವಾದಿಗಳನ್ನೂ, ಹೂವನ್ನೂ, ಹಣ್ಣುಗಳನ್ನೂ, ನೀರು ಇತ್ಯಾದಿಗಳನ್ನೂ ಯಾವ ಭಕ್ತನು ನನಗಾಗಿ ಭಕ್ತಿಯಿಂದ ಅರ್ಪಿಸುತ್ತಾನೆಯೋ ಆ ಶುದ್ಧಬುದ್ಧಿಯ ನಿಷ್ಕಾಮ ಪ್ರೇಮಿ ಭಕ್ತನು ಪ್ರೀತಿಯಿಂದ ಅರ್ಪಿಸಿದ ಆ ಪತ್ರ-ಪುಷ್ಪಾದಿಗಳನ್ನು ನಾನು ಸಗುಣರೂಪದಿಂದ ಪ್ರಕಟವಾಗಿ ಪ್ರೀತಿ ಸಹಿತ ಸೇವಿಸುತ್ತೇನೆ ಅರ್ಥಾತ್ ಸ್ವೀಕರಿಸುತ್ತೇನೆ. 9.26",

      "ಎಲೈ ಅರ್ಜುನ ! ನೀನು ಯಾವ ಕೆಲಸ ಮಾಡುತ್ತೀಯೋ, ಏನನ್ನು ತಿನ್ನುತ್ತೀಯೋ ಯಾವುದನ್ನು ಹೋಮ ಮಾಡುತ್ತೀಯೋ, ಯಾವುದನ್ನು ದಾನ ಕೊಡುತ್ತೀಯೋ ಯಾವ ಸ್ವಧರ್ಮಾಚರಣೆ ರೂಪೀ ತಪಸ್ಸು ಮಾಡುವಿಯೋ ಅದೆಲ್ಲವನ್ನೂ ನನಗೆ ಅರ್ಪಿಸು. 9.27",

      "ಹೀಗೆ ಸಮಸ್ತ ಕರ್ಮಗಳನ್ನೂ ಭಗವದರ್ಪಣೆ ಮಾಡುವಂತಹ ಸಂನ್ಯಾಸಯೋಗದಿಂದ ಕೂಡಿದ ಮನಸ್ಸುಳ್ಳ ನೀನು ಶುಭಾಶುಭ ಫಲಕಾರಿ ಕರ್ಮ ಬಂಧನದಿಂದ ಮುಕ್ತನಾಗಿ ಹೋಗುವೆ ಮತ್ತು ಅವುಗಳಿಂದ ಮುಕ್ತನಾಗಿ ನನ್ನನ್ನೇ ಸೇರುವೆ. 9.28",

      "ನಾನು ಸಮಸ್ತ ಪ್ರಾಣಿಗಳಲ್ಲಿಯೂ ಸಮಭಾವದಿಂದ ವ್ಯಾಪಿಸಿದ್ದೇನೆ. ನನಗೆ ಯಾರೂ ಅಪ್ರಿಯರಾದವರೂ ಇಲ್ಲ ಮತ್ತು ಪ್ರಿಯರಾದವರೂ ಇಲ್ಲ. ಆದರೆ ಯಾವ ಭಕ್ತರು ನನ್ನನ್ನು ಪ್ರೀತಿ-ಭಕ್ತಿಗಳಿಂದ ಭಜಿಸುತ್ತಾರೆಯೋ ಅವರು ನನ್ನಲ್ಲಿ ಮತ್ತು ನಾನೂ ಸಹ ಅವರಲ್ಲಿ ಪ್ರತ್ಯಕ್ಷರೂಪದಿಂದ ಇರುತ್ತೇನೆ 9.29",

      "ಒಂದು ವೇಳೆ ಯಾರಾದರೂ ಅತ್ಯಂತ ದುರಾಚರಿಯಾಗಿದ್ದರೂ ಸಹ ಅನನ್ಯಭಾವದಿಂದ ನನ್ನ ಭಕ್ತನಾಗಿ ನನ್ನನ್ನು ನಿರಂತರ ಭಜಿಸುತ್ತಾನೆಯೋ ಅವನು ಸಾಧುವೆಂದೇ ಪರಿಗಣಿಸಲು ಯೋಗ್ಯ. ಏಕೆಂದರೆ ಅವನು ಯಥಾರ್ಥವಾದ ನಿಶ್ಚಿತ ಬುದ್ಧಿಯುಳ್ಳವನಾಗಿರುತ್ತಾನೆ. ಅರ್ಥಾತ್ ಪರಮೇಶ್ವರನ ಭಜನೆಗೆ ಸಮಾನವಾದುದು ಬೇರೆ ಏನೂ ಇಲ್ಲ ಎಂದು ಅವನು ದೃಢನಿಶ್ಚಯ ಮಾಡಿಕೊಂಡಿರುತ್ತಾನೆ. 9.30",

      "ಅವನು ಬಹುಬೇಗ ಧರ್ಮಾತ್ಮನಾಗುತ್ತಾನೆ ಮತ್ತು ಶಾಶ್ವತವಾದ ಪರಮ ಶಾಂತಿಯನ್ನು ಪಡೆಯುತ್ತಾನೆ. ಎಲೈ ಅರ್ಜುನ ! ನನ್ನ ಭಕ್ತನು ನಾಶವಾಗುವುದಿಲ್ಲ ಎಂಬ ದೃಢ ಸತ್ಯವನ್ನು ತಿಳಿದುಕೋ 9.31",

      "ಏಕೆಂದರೆ, ಎಲೈ ಅರ್ಜುನ ! ಸ್ತ್ರೀಯರು, ವೈಶ್ಯರು, ಶೂದ್ರಾದಿಗಳು ಮತ್ತು ಪಾಪಯೋನಿಜರೂ ಸಹ ಯಾರೇ ಆಗಲಿ ಅವರೂ ಸಹ ನನಗೆ ಶರಣಾಗತರಾಗಿ ಪರಮ ಗತಿಯನ್ನು ಪಡೆಯುತ್ತಾರೆ. 9.32",

      "ಮತ್ತೆ ಏನು ಹೇಳುವುದು ? ಪುಣ್ಯಶಾಲಿಗಳಾದ ಬ್ರಾಹ್ಮಣರು ಹಾಗೂ ರಾಜರ್ಷಿಭಕ್ತರು ನನಗೆ ಶರಣಾಗತರಾಗಿ ಪರಮಗತಿಯನ್ನು ಪಡೆಯುತ್ತಾರೆ. ಆದುದರಿಂದ ನೀನು ಸುಖವಿಲ್ಲದ ಕ್ಷಣಭಂಗುರವಾದ ಈ ಮಾನವ ಶರೀರವನ್ನು ಪಡೆದಿರುವ ನೀನು ನಿರಂತರ ನನ್ನನ್ನೇ ಭಜಿಸು. ಅರ್ಥಾತ್ ಮನುಷ್ಯ ಶರೀರ ಬಹು ದುರ್ಲಭ, ಆದರೆ ನಾಶವಾಗುವಂತಹುದು ಮತ್ತು ಸುಖವೂ ಇಲ್ಲದ್ದು; ಆದುದರಿಂದ ಸಮಯವನ್ನು ನಂಬದೆ ಹಾಗೂ ಅಜ್ಞಾನದಿಂದ ಸುಖವೆಂಬಂತೆ ಭಾಸವಾಗುವ ವಿಷಯ - ಭೋಗಗಳಲ್ಲಿ ಸಿಕ್ಕಿಹಾಕಿಕೊಳ್ಳದೆ ಯಾವಾಗಲೂ ನನ್ನನ್ನು ಧ್ಯಾನಿಸು. 9.33",

      "ಕೇವಲ ಸಚ್ಚಿದಾನಂದಘನ ವಾಸುದೇವ ಪರಮಾತ್ಮನಾದ ನನ್ನಲ್ಲೇ ಅನನ್ಯ ಪ್ರೇಮದಿಂದ ನಿರಂತರ ದೃಢ ಮನಸ್ಸುಳ್ಳವನಾಗು, ನನ್ನನೇ ಶ್ರದ್ಧಾ-ಪ್ರೇಮಗಳಿಂದ, ನಿಷ್ಕಾಮ ಭಾವದಿಂದ, ನಾಮ, ಗುಣ, ಪ್ರಭಾವಗಳ ಶ್ರವಣ, ಕೀರ್ತನೆ, ಮನನ ಮತ್ತು ಅಧ್ಯಯನ- ಅಧ್ಯಾಪನದ ಮೂಲಕ ನಿರಂತರ ಭಜಿಸುತ್ತಿರು. ಕಾಯೇನ-ವಾಚಾ-ಮನಸಾ ಸರ್ವಸ್ವವನ್ನೂ ಅರ್ಪಿಸಿ ಅತ್ಯಂತ ಶ್ರದ್ಧಾ-ಭಕ್ತಿ-ಪ್ರೇಮದಿಂದ ವಿಹ್ವಲನಾಗಿ ನನ್ನ ಆರಾಧನೆ ಮಾಡು. ಎಲ್ಲರ ಆಶ್ರಯರೂಪೀ ವಾಸುದೇವನಾದ ನನಗೆ ನಮಸ್ಕಾರ ಮಾಡು. ಈ ಪ್ರಕಾರ ನನಗೆ ಶರಣಾಗಿ ನೀನು ಆತ್ಮನನ್ನು ನನ್ನಲ್ಲಿ ಒಂದೇ ಭಾವದಿಂದ ನಿಯುಕ್ತಗೊಳಿಸಿ ನನ್ನನ್ನೇ ಸೇರಿಕೊಳ್ಳುವೆ. 9.34",

      "ಇತಿ ಶ್ರೀಮದ್ಭಗವದ್ಗೀತಾರೂಪೀ ಉಪನಿಷದ್ ಬ್ರಹ್ಮವಿದ್ಯಾ ಹಾಗೂ ಯೋಗಶಾಸ್ತ್ರ ವಿಷಯದಲ್ಲಿ ಶ್ರೀಕೃಷ್ಣ ಮತ್ತು ಅರ್ಜುನರ ಸಂವಾದದಲ್ಲಿ ರಾಜವಿದ್ಯಾ-ರಾಜಗುಹ್ಯಯೋಗ ಎಂಬ ಒಂಬತ್ತನೇ ಅಧ್ಯಾಯ ಸಂಪೂರ್ಣ"
    ],
    "adhyaya-10" : [
      "ಶ್ರೀ ಭಗವಾನರು ಹೇಳುತ್ತಾರೆ. ಎಲ್ಲ ಮಹಾಬಾಹುವೇ! ಪರಮ ರಹಸ್ಯ ಮತ್ತು ಪ್ರಭಾವಯುಕ್ತ ನನ್ನೀ ವಚನಗಳನ್ನು ಕೇಳು‌‌. ಇದನ್ನು ನಾನು ಅತ್ಯಂತ ಪ್ರೀತಿಪಾತ್ರನಾದ ನಿನ್ನ ಹಿತವನ್ನು ಬಯಸಿಯೇ ಹೇಳುತ್ತಿರುವುದು. 10.1",

      "ನನ್ನ ಉತ್ಪತ್ತಿಯನ್ನು ದೇವತೆಗಳೂ, ಮಹರ್ಷಿಗಳೂ ಸಹ ತಿಳಿಯರು. ಏಕೆಂದರೆ ನಾನು ಎಲ್ಲ ರೀತಿಯಿಂದಲೂ ದೇವತೆಗಳಿಗೂ ಮತ್ತು ಮಹರ್ಷಿಗಳಿಗೂ ಸಹ ಮೂಲಕಾರಣನಾಗಿದ್ದೇನೆ. 10.2",

      "ಯಾರು ನನ್ನನ್ನು ಅಜನ್ಮ ಮತ್ತು ಅನಾದಿ ಹಾಗೂ ಲೋಕಗಳಿಗೆಲ್ಲ ಮಹಾನ್ ಈಶ್ವರ ಎಂಬ ಯಥಾರ್ಥವನ್ನು ತಿಳಿಯುತ್ತಾನೆಯೋ ಅವನು ಮಾನವರಲ್ಲಿ ಜ್ಞಾನಿಯಾದವನಾಗಿ ಎಲ್ಲ ಪಾಪಗಳಿಂದ ಮುಕ್ತನಾಗುತ್ತಾನೆ. 10.3",

      "ನಿಶ್ಚಯ ಮಾಡುವ ಬುದ್ಧಿಶಕ್ತಿ, ತತ್ತ್ವಜ್ಞಾನ, ಮೋಹಶೂನ್ಯತೆ, ಕ್ಷಮೆ, ಸತ್ಯ, ಇಂದ್ರಿಯಗಳ ನಿಗ್ರಹ, ಮನೋನಿಗ್ರಹ, ಸುಖ, ದುಃಖ, ಉತ್ಪತ್ತಿ ಮತ್ತು ಪ್ರಳಯ, ಭಯ ಮತ್ತು ಅಭಯ ಮತ್ತು 10.4",

      "ಅಹಿಂಸೆ, ಸಮಾನತೆ, ತೃಪ್ತಿ, ತಪಸ್ಸು, ದಾನ, ಕೀರ್ತಿ, ಅಪಕೀರ್ತಿ, ಹೀಗೆ ಪ್ರಾಣಿಗಳ ನಾನಾ ಪ್ರಕಾರಗಳ ಭಾವಗಳು ನನ್ನಿಂದಲೇ ಉಂಟಾಗುತ್ತವೆ. 10.5",

      "ಏಳು ಮಂದಿ ಮಹರ್ಷಿಗಳು, ಇವರಿಗಿಂತಲೂ ಮೊದಲಿನವರಾದ ನಾಲ್ವರು ಸನಕಾದಿಗಳು ಹಾಗೂ ಸ್ವಾಯಂಭುವ ಮುಂತಾದ ಹದಿನಾಲ್ಕು ಜನ ಮನುಗಳು, ನನ್ನ ಭಾವವುಳ್ಳವರಾಗಿ ಇವರೆಲ್ಲಾ ನನ್ನ ಸಂಕಲ್ಪದಿಂದಲೇ ಉತ್ಪನ್ನರಾಗಿದ್ದಾರೆ. ಜಗತ್ತಿನ ಸಂಪೂರ್ಣ ಪ್ರಜೆಗಳೆಲ್ಲ ಇವರಿಂದಲೇ ವೃದ್ಧಿ ಯಾದವರು. 10.6",

      "ಯಾರು ಈ ನನ್ನ ಪರಮೇಶ್ವರರೂಪೀ ವಿಭೂತಿಯನ್ನು ಮತ್ತು ಯೋಗ ಶಕ್ತಿಯನ್ನು ಯಥಾರ್ಥವಾಗಿ ತಿಳಿದುಕೊಳ್ಳುತ್ತಾನೆಯೋ ಅವನು ನಿಶ್ಚಲವಾದ ಧ್ಯಾನಯೋಗದಿಂದ ನನ್ನಲ್ಲೇ ಏಕೈಕ ಭಾವದಲ್ಲಿ ಮಗ್ನನಾಗುತ್ತಾನೆ. ಇದರಲ್ಲಿ ಸಂಶಯವಿಲ್ಲ 10.7",

      "ವಾಸುದೇವನಾದ ನಾನೇ ಸಮಸ್ತ ಜಗತ್ತಿನ ಉತ್ಪತ್ತಿಗೆ ಕಾರಣನು. ನನ್ನಿಂದಲೇ ಇಡೀ ಜಗತ್ತು ವ್ಯವಹರಿಸುತ್ತದೆ ಎಂಬ ಯಥಾರ್ಥವನ್ನು ತಿಳಿದುಕೊಂಡು ಶ್ರದ್ಧಾ-ಭಕ್ತಿಗಳಿಂದ ಕೂಡಿರುವ ಬುದ್ಧಿಶಾಲಿ ಭಕ್ತರು ಪರಮೇಶ್ವರನಾದ ನನನ್ನು ನಿರಂತರ ಭಜಿಸುತ್ತಾರೆ. 10.8",

      "ನಿರಂತರ ನನ್ನಲ್ಲಿಯೇ ಮನಸ್ಸನ್ನು ತೊಡಗಿಸಿರುವವರು, ನನ್ನಲ್ಲಿಯೇ ಪ್ರಾಣವುಳ್ಳವರು - ಇಂತಹ ಭಕ್ತರು ಯಾವಾಗಲೂ ತಮ್ಮ-ತಮ್ಮಲ್ಲಿ ನನ್ನ ಭಕ್ತಿಯ ಚರ್ಚೆಯ ಮೂಲಕ ನನ್ನ ಗುಣ-ಪ್ರಭಾವವನ್ನು ಹೇಳುತ್ತಾ-ಕೇಳುತ್ತಾ ಮತ್ತು ಗುಣ-ಪ್ರಭಾವಗಳ ಸಹಿತ ನನ್ನ ಪುರಾಣ ಪಠಣ ಮಾಡುವುದರಿಂದಲೇ ಸಂತುಷ್ಟರಾಗುತ್ತಾರೆ ಮತ್ತು ವಾಸುದೇವನಾದ ನನ್ನಲ್ಲಿಯೇ ತಲ್ಲೀನರಾಗಿ ಆನಂದಪಡುತ್ತಾರೆ 10.9",

      "ನಿರಂತರ ನನ್ನ ಧ್ಯಾನದಲ್ಲಿಯೇ ಮಗ್ನರಾಗಿರುವ ಮತ್ತು ಪ್ರೀತಿಪೂರ್ವಕವಾಗಿ ಭಜಿಸುವ ಭಕ್ತರಿಗೆ ನಾನು ತತ್ತ್ವಜ್ಞಾನ ರೂಪೀ ಯೋಗವನ್ನು ಕೊಡುತ್ತೇನೆ.  ಅದರಿಂದ ಅವರು ನನ್ನನ್ನು ಪಡೆಯುತ್ತಾರೆ. 10.10",

      "ಅವರನ್ನು ಅನುಗ್ರಹಿಸುವುದಕ್ಕಾಗಿ ನಾನೇ ಸ್ವತಃ ಅವರ ಅಂತಃಕರಣದಲ್ಲಿ ಆತ್ಮಭಾವದಿಂದ ಇದ್ದುಕೊಂಡು ಅಜ್ಞಾನದಿಂದ ಉಂಟಾದ ಅಂಧಕಾರವನ್ನು ಪ್ರಕಾಶಮಯ ತತ್ತ್ವಜ್ಞಾನರೂಪೀ ದೀಪದಿಂದ ನಾಶ ಮಾಡುತ್ತೇನೆ. 10.11",

      "ಅರ್ಜುನನ ಹೇಳುತ್ತಾನೆ:  ನೀನು ಪರಬ್ರಹ್ಮ, ಪರಮ ಧಾಮ, ಪರಮ ಪವಿತ್ರ.  ಏಕೆಂದರೆ, ಎಲ್ಲಾ ಋಷಿಗಳೂ ನಿನ್ನನ್ನು ಸನಾತನ ದಿವ್ಯ ಪುರುಷ, ದೇವತೆಗಳ ಆದಿದೇವ, ಜನ್ಮರಹಿತ, ಸರ್ವವ್ಯಾಪೀ ಎಂದು ಹೇಳುತ್ತಾರೆ. 10.12",

      "ಅಂತೆಯೇ ದೇವರ್ಷಿಗಳಾದ ನಾರದ, ಅಸಿತ, ದೇವಲ, ಮಹರ್ಷಿ ವ್ಯಾಸರು ಮತ್ತು ಸ್ವತಃ ನೀನೂ ಸಹ ನನಗೆ ಹೇಳುತ್ತಿರುವೆ. 10.13",

      "ಹೇ ಕೇಶವ!  ನೀನು ಹೇಳುತ್ತಿರುವುದೆಲ್ಲವನ್ನೂ ನಾನು ಸತ್ಯವೆಂದು ಭಾವಿಸುತ್ತೇನೆ. ಹೇ ಪರಮಾತ್ಮ!  ನಿನ್ನ ಲೀಲಾಮಯ ಸ್ವರೂಪವನ್ನು ದಾನವರು ಮತ್ತು ದೇವತೆಗಳೂ ಸಹ ಅರಿಯರು. 10.14",

      "ಹೇ ಜೀವಿಗಳ ಉತ್ಪತ್ತಿ ದಾತ, ಹೇ ಜೀವಿಗಳ ಈಶ್ವರ, ದೇವತೆಗಳ ದೇವ, ಜಗದೊಡೆಯ ಪುರುಷೋತ್ತಮನೇ, ನೀನೇ ಸ್ವತಃ ನಿನ್ನಿಂದ ನಿನ್ನನ್ನು ತಿಳಿದಿರುವೆ. 10.15",

      "ಆದುದರಿಂದ ನೀನೇ ದಿವ್ಯವಾದ ನಿನ್ನ ವಿಭೂತಿಗಳನ್ನು ನಿಶ್ಶೇಷವಾಗಿ ಹೇಳಲು ಅರ್ಹನು.  ಏಕೆಂದರೆ, ಆ ನಿನ್ನ ವಿಭೂತಿಗಳ ಮೂಲಕ ಎಲ್ಲಾ ಲೋಕಗಳನ್ನು ವ್ಯಾಪಿಸಿಕೊಂಡಿರುವೆ ಹಾಗೂ ಪರಿಪೂರ್ಣನಾಗಿರುವೆ. 10.16",

      "ಹೇ ಯೋಗೇಶ್ವರ!  ನಾನು ಯಾವ ಪ್ರಕಾರ ಯಾವಾಗಲೂ ಚಿಂತಿಸುತ್ತಾ ನಿನ್ನನ್ನು ತಿಳಿದುಕೊಳ್ಳಲಿ ಮತ್ತು  ಹೇ ಪರಮಾತ್ಮ, ಯಾವ ಯಾವ ಭಾವಸ್ವರೂಪಗಳಲ್ಲಿ ನನ್ನಿಂದ ಚಿಂತಿಸಲು ಸಾಧ್ಯನಾಗಿರುವೆ? 10.17",

      "ಹೇ ಜನಾರ್ದನ!  ನಿನ್ನ ಯೋಗಶಕ್ತಿಯನ್ನು ಮತ್ತು ಪರಮೈಶ್ವರ್ಯ ರೂಪೀ ವಿಭೂತಿಗಳನ್ನು ಸವಿಸ್ತಾರವಾಗಿ ಹೇಳು.  ಏಕೆಂದರೆ ನಿನ್ನ ಅಮೃತವಾಣಿಯನ್ನು ಕೇಳುತ್ತಾ ನನಗೆ ತೃಪ್ತಿಯೇ ಆಗುವುದಿಲ್ಲ.  ಕೇಳಬೇಕೆಂಬ ಉತ್ಕಟೇಚ್ಛೆ ಉಂಟಾಗುತ್ತಿದೆ. 10.18",

      "ಶ್ರೀಕೃಷ್ಣನು ಹೇಳಿದನು: ಎಲೈ  ಕುರುಶ್ರೇಷ್ಠನೇ, ಈಗ ನಾನು ನಿನಗೋಸ್ಕರ ಮುಖ್ಯವಾಗಿ ದಿವ್ಯವಾದ ನನ್ನ ವಿಭೂತಿಗಳನ್ನು ಹೇಳುವೆನು.  ಏಕೆಂದರೆ ನನ್ನ ವ್ಯಾಪ್ತಿಗೆ ಅಂತ್ಯವೆಂಬುದೇ ಇಲ್ಲ. 10.19",

      "ಹೇ ಅರ್ಜುನ!  ನಾನು ಎಲ್ಲಾ ಪ್ರಾಣಿಗಳ ಹೃದಯಾಂತರಾಳದಲ್ಲಿರುವ ಆತ್ಮನಾಗಿದ್ದೇನೆ ಹಾಗೂ ಸಮಸ್ತ ಜೀವಿಗಳ ಆದಿ, ಮಧ್ಯ ಮತ್ತು ಅಂತ್ಯವೂ ಸಹ ನಾನೇ ಆಗಿದ್ದೇನೆ. 10.20",

      "ನಾನು ಆದಿತಿಯ ಹನ್ನೆರಡು  ಮಂದಿ ಪುತ್ರರಲ್ಲಿ ವಿಷ್ಣುವಿನ ಅವತಾರ.  ಜ್ಯೋತಿಗಳಲ್ಲಿ ಕಿರಣಗಳುಳ್ಳ ಸೂರ್ಯನಾಗಿದ್ದೇನೆ.  ಹಾಗೂ ನಾನು ವಾಯುದೇವತೆಗಳಲ್ಲಿನ ತೇಜಸ್ಸು, ಮತ್ತು ನಕ್ಷತ್ರಗಳು, ಅವುಗಳ ಅಧಿಪತಿ ಚಂದ್ರ ನಾನಾಗಿದ್ದೇನೆ. 10.21",

      "ನಾನು ವೇದಗಳಲ್ಲಿ ಸಾಮವೇದ, ದೇವತೆಗಳಲ್ಲಿ ಇಂದ್ರ, ಮತ್ತು ಇಂದ್ರಿಯಗಳಲ್ಲಿ ಮನಸ್ಸು, ಪ್ರಾಣಿಗಳಲ್ಲಿ ಚೇತನ ಅಥವಾ ಜೀವಶಕ್ತಿ ಆಗಿದ್ದೇನೆ. 10.22",

      "ನಾನು ಏಕಾದಶ ರುದ್ರರಲ್ಲಿ ಶಂಕರ, ಯಕ್ಷ-ರಾಕ್ಷಸರಲ್ಲಿ ಧನಾಧಿಪತಿ ಕುಬೇರ, ಅಷ್ಟವಸುಗಳಲ್ಲಿ ಅಗ್ನಿ, ಶಿಖರಗಳುಳ್ಳ ಪರ್ವತಗಳಲ್ಲಿ ಮೇರು ಪರ್ವತ ಆಗಿದ್ದೇನೆ. 10.23",

      "ಪುರೋಹಿತರಲ್ಲಿ ಪ್ರಮುಖರಾದ, ಅಂದರೆ ದೇವತೆಗಳ ಪುರೋಹಿತರಾದ, ಬೃಹಸ್ಪತಿ ನಾನೆಂದು ತಿಳಿದುಕೋ.  ಎಲೈ ಪಾರ್ಥ, ನಾನು ಸೇನಾಧಿಪತಿಗಳಲ್ಲಿ ಸ್ವಾಮಿ ಕಾರ್ತಿಕ, ಮತ್ತು ಜಲಾಶಯಗಳಲ್ಲಿ ಸಮುದ್ರ ಆಗಿದ್ದೇನೆ. 10.24",

      "ಮಹರ್ಷಿಗಳಲ್ಲಿ ಭೃಗು, ಶಬ್ದಗಳಲ್ಲಿ ಏಕಾಕ್ಷರ, ಅಂದರೆ ಓಂಕಾರ, ನಾನಾಗಿದ್ದೇನೆ.  ಎಲ್ಲ ಪ್ರಕಾರದ ಯಜ್ಞಗಳಲ್ಲಿ ಜಪಯಜ್ಞ ಮತ್ತು  ಸ್ಥಿರವಾದವುಗಳಲ್ಲಿ ಹಿಮಾಲಯ ಪರ್ವತ ನಾನಾಗಿದ್ದೇನೆ. 10.25",

      "ಎಲ್ಲ ವೃಕ್ಷಗಳಲ್ಲಿ ಅಶ್ವತ್ಥ, ದೇವಋಷಿಗಳಲ್ಲಿ ನಾರದಮುನಿ, ಗಂಧರ್ವರಲ್ಲಿ ಚಿತ್ರರಥ, ಮತ್ತು ಸಿದ್ದರಲ್ಲಿ ಕಪಿಲಮುನಿ ನಾನಾಗಿದ್ದೇನೆ 10.26",

      "ಅಮೃತದೊಡನೆ ಉತ್ಪತ್ತಿಯಾದ ಉಚ್ಚೈಃಶ್ರವ ಎಂಬ ಕುದುರೆಯೂ, ಆನೆಗಳಲ್ಲಿ ಉತ್ತಮವಾದ ಐರಾವತವೂ, ಹಾಗೂ ಮನುಷ್ಯರಲ್ಲಿ ರಾಜನೆಂದೂ ನನ್ನನ್ನು ತಿಳಿದುಕೋ. 10.27",

      "ನಾನು ಆಯುಧಗಳಲ್ಲಿ ವಜ್ರಾಯುಧ, ಗೋವುಗಳಲ್ಲಿ ಕಾಮಧೇನು, ಶಾಸ್ತ್ರೋಕ್ತ ರೀತಿಯಿಂದ ಸಂತಾನೋತ್ಪತ್ತಿಯಲ್ಲಿ ಕಾರಣನಾದ ಕಾಮದೇವನೂ ಮತ್ತು ಸರ್ಪಗಳಲ್ಲಿ ಸರ್ಪರಾಜನಾದ ವಾಸುಕಿ ಆಗಿದ್ದೇನೆ. 10.28",

      "ನಾನು ನಾಗಗಳಲ್ಲಿ ಶೇಷನಾಗವೂ, ಜಲಚರಗಳಲ್ಲಿ ಅವುಗಳ ಅಧಿಪತಿಯಾದ ವರುಣದೇವನೂ, ಪಿತೃಗಳಲ್ಲಿ ಅರ್ಯಮಾ ಎಂಬ ಪಿತೃವೂ ಮತ್ತು ದಂಡಾಧಿಪತಿಗಳಲ್ಲಿ ಯಮರಾಜನೂ ಆಗಿದ್ದೇನೆ. 10.29",

      "ನಾನು ದೈತ್ಯರಲ್ಲಿ ಪ್ರಹ್ಲಾದ, ಗಣನೆ ಮಾಡುವುದರಲ್ಲಿ ಸಮಯ, ಮೃಗಗಳಲ್ಲಿ ಮೃಗರಾಜ ಸಿಂಹ, ಮತ್ತು ಪಕ್ಷಿಗಳಲ್ಲಿ ಗರುಡನಾಗಿದ್ದೇನೆ. 10.30",

      "ನಾನು ಪವಿತ್ರಗೊಳಿಸುವವರಲ್ಲಿ ವಾಯು, ಶಸ್ತ್ರಧಾರಿಗಳಲ್ಲಿ ರಾಮ, ಜಲಚರಗಳಲ್ಲಿ ಮೊಸಳೆ, ಮತ್ತು ನದಿಗಳಲ್ಲಿ ಶ್ರೀಭಾಗೀರತಿ ಗಂಗೆಯಾಗಿದ್ದೇನೆ. 10.31",

      "ಎಲೈ ಅರ್ಜುನ, ಸೃಷ್ಟಿಗಳ ಆದಿ, ಅಂತ್ಯ ಮತ್ತು ಮಧ್ಯವೂ ಸಹ ನಾನೇ; ವಿದ್ಯೆಗಳಲ್ಲಿ ಅಧ್ಯಾತ್ಮ ವಿದ್ಯೆ ಅಥವಾ ಬ್ರಹ್ಮವಿದ್ಯೆ, ಮತ್ತು ಪರಸ್ಪರ ಚರ್ಚಿಸುವುದರಲ್ಲಿ ತತ್ತ್ವನಿರ್ಣಯಕ್ಕಾಗಿ ಮಾಡುವ ವಾದವೂ ಸಹ ನಾನೇ ಅಗಿದ್ದೇನೆ. 10.32",

      "ನಾನು ಅಕ್ಷರಗಳಲ್ಲಿ ಅಕಾರ, ಸಮಾಸಗಳಲ್ಲಿ ದ್ವಂದ್ವವಸಮಾಸವೂ ಆಗಿದ್ದೇನ.  ಮಹಾಕಾಲನೂ, ವಿರಾಟ್ ಸ್ವರೂಪಿಯೂ, ಎಲ್ಲರ ಪಾಲನೆ-ಪೋಣೆ ಮಾಡುವವನೂ ನಾನೇ ಅಗಿದ್ದೇನೆ. 10.33",

      "ನಾನು ಎಲ್ಲರನ್ನೂ ನಾಶಮಾಡುವ ಮೃತ್ಯು, ಮುಂದಿನ ಉತ್ಪತ್ತಿಗೆ ಕಾರಣನು, ಹಾಗು ಸ್ತ್ರೀಯರಲ್ಲಿ ಕೀರ್ತಿ, ಶ್ರೀ, ವಾಕ್ಕು, ಸ್ಮೃತಿ, ಮೇಧಾ, ಧೃತಿ, ಮತ್ತು ಕ್ಷಮೆ ಆಗಿದ್ದೇನೆ. 10.34",

      "ಹಾಗು, ನಾನು ಗಾಯನಯೋಗ್ಯ ಶ್ರುತಿಗಳಲ್ಲಿ ಬೃಹತ್ಸಾಮ, ಛಂದಸ್ಸುಗಳಲ್ಲಿ ಗಾಯತ್ರಿ, ತಿಂಗಳುಗಳಲ್ಲಿ ಮಾರ್ಗಶೀರ್ಷ, ಮತ್ತು ಋತುಗಳಲ್ಲಿ ವಸಂತಋತು ಆಗಿದ್ದೇನೆ. 10.35",

      "ನಾನು ಮೋಸಗೊಳಿಸುವವರಲ್ಲಿ ದ್ಯೂತವೂ, ಪ್ರಭಾವಶಾಲಿ ತೇಜಸ್ವಿಗಳ ತೇಜಸ್ಸೂ, ಜಯಶಾಲಿಗಳ ವಿಜಯವೂ, ನಿಶ್ಚಯಿಸುವವರ ನಿಶ್ಚಯವೂ, ಮತ್ತು ಸಾತ್ವಿಕರಲ್ಲಿನ ಸಾತ್ವಿಕಭಾವವೂ ಆಗಿದ್ದೇನೆ. 10.36",

      "ಯಾದವ ವಂಶಜರಲ್ಲಿ ವಾಸುದೇವ ಸ್ವತಃ ನಾನೂ, ಪಾಂಡವರಲ್ಲಿ ಧನಂಜಯನಾದ ನೀನೂ, ಮುನಿಗಳಲ್ಲಿ ವೇದವ್ಯಾಸರೂ, ಮತ್ತು ಕವಿಗಳಲ್ಲಿ ಶುಕ್ರಾಚಾರ್ಯರೂ ಸಹ ನಾನೇ ಆಗಿದ್ದೇನೆ. 10.37",

      "ನಾನು ದಮನ ಮಾಡುವವರಲ್ಲಿನ ದಮನ ಶಕ್ತಿಯೂ, ಜಯಾಭಿಲಾಷಿಗಳ ನೀತಿಯೂ, ರಹಸ್ಯಯೋಗ್ಯ  ಭಾವಗಳಲ್ಲಿ ಮೌನವೂ, ಮತ್ತು ಜ್ಞಾನಿಗಳ ತತ್ತ್ವಜ್ಙಾನವೂ ಆಗಿರುವೆನು. 10.38",

      "ಎಲೈ ಅರ್ಜುನ, ಯಾವುದು ಸಮಸ್ತ ಜೀವಿಗಳ ಉತ್ತತ್ತಿಗೆ ಕಾರಣವೋ ಅದೂ ಸಹ ನಾನೇ ಆಗಿದ್ದೇನೆ.  ಏಕೆಂದರೆ, ನಾನಿಲ್ಲದೆ ಇರುವಂತಹ ಯಾವುದೇ ಚರಾಚರ ಜೀವಿಗಳೂ ಮತ್ತು ವಸ್ತುಗಳು ಇಲ್ಲವೇ ಇಲ್ಲ.  ಆದುದರಿಂದ ಎಲ್ಲವೂ ನನ್ನ ಸ್ವರೂಪವೇ ಆಗಿವೆ. 10.39",

      "ಎಲೈ ಅರ್ಜುನ, ನನ್ನ ದಿವ್ಯವಾದ ವಿಭೂತಗಳಿಗೆ ಅಂತ್ಯವೇ ಇಲ್ಲ,  ನನ್ನ ವಿಭೂತಿಗಳ ವಿಸ್ತಾರವನ್ನು ನಿನಗೋಸ್ಕರ ಸಂಕ್ಷಿಪ್ತವಾಗಿ ಹೇಳಿದ್ದೇನೆ. 10.40",

      "ಯಾವ-ಯಾವ ವಸ್ತುಗಳು ವಿಭೂತಿಯುಳ್ಳವೋ, ಅಂದರೆ ಸಂಪದ್ಯುಕ್ತವೋ, ಕಾಂತಿಯಿಂದ ಕೂಡಿರುವವೋ, ಮತ್ತು ಶಕ್ತಿಯುತವಾಗಿರುವವೋ ಅವುಗಳೆಲ್ಲವೂ ಸಹ ನನ್ನ ತೇಜಸ್ಸಿನ ಅಂಶದಿಂದಲೇ ಉಂಟಾದುವುಗಳು ಎಂದು ನೀನು ತಿಳಿದುಕೋ. 10.41",

      "ಅಥವಾ, ಎಲೈ ಅರ್ಜುನ, ಈ ವಿಭೂತಿಗಳನ್ನೆಲ್ಲಾ ವಿಶದವಾಗಿ ತಿಳಿದುಕೊಳ್ಳುವುದರಿಂದ ನಿನಗೆ ಪ್ರಯೋಜನವೇನು?  ನಾನು ಈ ಸಂಪೂರ್ಣ ಜಗತ್ತನ್ನು ನನ್ನ ಯೋಗಮಾಯೆಯ ಒಂದಂಶ ಮಾತ್ರದಿಂದ ಧರಿಸಿಕೊಂಡಿದ್ದೇನೆ.  ಆದುದರಿಂದ ನನ್ನನ್ನೇ ಯಥಾರ್ಥವಾಗಿ ತಿಳಿದುಕೊಳ್ಳಬೇಕು. 10.42",

      "ಇತಿ ಶ್ರೀಮದ್ಭಗವದ್ಗೀತಾರೂಪೀ ಉಪನಿಷತ್ ಮತ್ತು ಬ್ರಹ್ಮವಿದ್ಯೆ ಹಾಗೂ ಯೋಗಶಾಸ್ತ್ರ ವಿಷಯವಾಗಿ ಶ್ರೀಕೃಷ್ಣ ಮತ್ತು ಅರ್ಜುನರ ಸಂವಾದದಲ್ಲಿ 'ವಿಭೂತಿಯೋಗ' ಎಂಬ ಹತ್ತನೆಯ ಅಧ್ಯಾಯ ಸಂಪೂರ್ಣವು."
    ],
    "adhyaya-11" : [
      "ಅರ್ಜುನ ಹೇಳಿದನು: ನನ್ನನ್ನು ಅನುಗ್ರಹಿಸುವ ಸಲುವಾಗಿ ಪರಮ ಗೋಪ್ಯವಾದ ಆಧ್ಯಾತ್ಮ ವಿಚಾರಗಳ ಯಾವ ಉಪದೇಶವು ನಿನ್ನಿಂದ ಹೇಳಲ್ಪಟ್ಟಿದೆಯೋ ಅದರಿಂದ ನನ್ನ ಈ ಅಜ್ಞಾನ ನಾಶವಾಗಿ ಹೋಗಿದೆ. 11.1",

      "ಏಕೆಂದರೆ, ಹೇ ಕಮಲ ನೇತ್ರನೆ! ನಾನು ಜೀವಿಗಳ ಉತ್ಪತ್ತಿ ಮತ್ತು ಪ್ರಳಯಗಳನ್ನು ಹಾಗು ಅವಿನಾಶಿಯಾದ ಪ್ರಭಾವಗಳನ್ನು ಸಹ ನಿನ್ನಿಂದ ವಿಸ್ತಾರವಾಗಿ ಕೇಳಿದೆನು. 11.2",

      "ಹೇ ಪರಮೇಶ್ವರ! ನೀನು ನಿನ್ನ ವಿಚಾರಗಳನ್ನು ಯಾವ ರೀತಿಯಲ್ಲಿ ಹೇಳಿರುವಿಯೋ ಅದು ಹಾಗೆಯೇ ಇದೆ.  ಆದರೆ, ಹೇ ಪುರುಷೋತ್ತಮ! ನಿನ್ನ ಜ್ಞಾನ, ಐಶ್ವರ್ಯ, ಶಕ್ತಿ, ಬಲ, ವೀರ್ಯ ಮತ್ತು ತೇಜಸ್ಸಿನಿಂದ ಕೂಡಿದ ಈಶ್ವರಿಯ ರೂಪವನ್ನು ಪ್ರತ್ಯಕ್ಷವಾಗಿ ನೋಡಬೇಕೆಂದು ಇಚ್ಚಿಸುತ್ತೇನೆ. 11.3",

      "ಹೇ ಪ್ರಭು! ನನ್ನಿಂದ ಆ ನಿನ್ನ ರೂಪವನ್ನು ನೋಡಲು ಸಾಧ್ಯ ಎಂಬುದಾಗಿ ಒಪ್ಪುವುದಾದರೆ, ಹೇ ಯೋಗೇಶ್ವರ! ನೀನು ನಿನ್ನ ಅವಿನಾಶೀ ಸ್ವರೂಪವನ್ನು ನನಗೆ ದರ್ಶನ ಮಾಡಿಸು. 11.4",

      "ಶ್ರೀಕೃಷ್ಣನು ಹೇಳಿದನು - ಎಲೈ ಪಾರ್ಥ! ನನ್ನ ನೂರಾರು, ಸಾವಿರಾರು ನಾನಾ ಪ್ರಕಾರಗಳ, ನಾನಾವರ್ಣ ಹಾಗೂ ಆಕೃತಿಗಳುಳ್ಳ ಅಲೌಕಿಕವಾದ ರೂಪಗಳನ್ನು ನೀನೀಗ ನೋಡು. 11.5",

      "ಎಲೈ ಭರತವಂಶಿ ಅರ್ಜುನ!  ನನ್ನಲ್ಲಿ ಆದಿತ್ಯರನ್ನು - ಅಂದರೆ, ಅದಿತಿಯ ಹನ್ನೆರಡು ಮಂದಿ ಪುತ್ರರನ್ನು, ಎಂಟು ಮಂದಿ ವಸ್ತುಗಳನ್ನು, ಹನ್ನೊಂದು ಮಂದಿ ರುದ್ರರನ್ನು, ಇಬ್ಬರು ಅಶ್ವಿನೀ ಕುಮಾರರನ್ನು, ಮತ್ತು ನಲವತ್ತೊಂಬತ್ತು ಮರುದ್ಗಣಗಳನ್ನು ನೋಡು.  ಜೊತೆಗೆ, ಮೊದಲು ನೋಡಿಯೇ ಇರದಂತಹ ಇನ್ನೂ ಅನೇಕ ಆಶ್ಚರ್ಯಮಯ ರೂಪಗಳನ್ನು ನೋಡು. 11.6",

      "ಎಲೈ ಅರ್ಜುನ, ಈಗ ನನ್ನ ಈ ಶರೀರದಲ್ಲಿ ಒಂದೇ ಕಡೆಯಲ್ಲಿರುವ ಚರಾಚರ ಸಹಿತ ಸಕಲ ಜಗತ್ತನ್ನು ಮತ್ತು ಜೊತೆಗೆ ಇನ್ನೇನನ್ನು ನೋಡಬೇಕೆಂದು ಇಚ್ಚಿಸುವಿಯೋ ಅದೆಲ್ಲವನ್ನೂ ನೋಡು. 11.7",

      "ಆದರೆ ನೀನು ನನ್ನನ್ನು ಈ ನಿನ್ನ ನೈಸರ್ಗಿಕ ಕಣ್ಣುಗಳಿಂದ ಖಂಡಿತವಾಗಿ ನೋಡಲು ಸಮರ್ಥನಲ್ಲ.  ಆದ್ದರಿಂದ ನಿನಗೋಸ್ಕರ ಅಲೌಕಿಕವಾದ ದೃಷ್ಟಿಯನ್ನು ಕೊಡುತ್ತೇನೆ.  ಅವುಗಳಿಂದ ನೀನು ನನ್ನ ಈಶ್ವರಿಯ ಪ್ರಭಾವವನ್ನೂ, ಯೋಗ ಶಕ್ತಿಯನ್ನೂ ನೋಡು. 11.8",

      "ಸಂಜಯನು ಹೇಳಿದನು: ಎಲೈ ರಾಜನೆ, ಮಹಾಯೋಗೇಶ್ವರನಾದ ಪಾಪನಾಶಕ ಭಗವಂತನು ಈ ರೀತಿ ಹೇಳಿದ ನಂತರ ಅರ್ಜುನನಿಗೆ ಅಲೌಕಿಕವಾದ  ಐಶ್ವರ್ಯಯುಕ್ತ ಸ್ವರೂಪವನ್ನು ತೋರಿಸಿದನು. 11.9",

      "ಅನೇಕ ಮುಖಗಳು ಮತ್ತು ಕಣ್ಣುಗಳಿಂದ ಕೂಡಿರುವ, ಅನೇಕ ಅದ್ಭುತವಾದ ದರ್ಶನಗಳುಳ್ಳ, ಅನೇಕ ದಿವ್ಯಾಭರಣಗಳಿಂದ ಅಲಂಕೃತನಾದ, ಬಹಳ ದಿವ್ಯಾಯುಧಗಳನ್ನು ಕೈಯಲ್ಲಿ ಎತ್ತಿ ಹಿಡಿದು.. 11.10",

      "ದಿವ್ಯವಾದ ಹಾರ ಮತ್ತು ವಸ್ತುಗಳನ್ನು ಧರಿಸಿಕೊಂಡಿರುವ, ದಿವ್ಯ ಗಂಧವನ್ನು ಲೇಪಿಸಿಕೊಂಡಿರುವ, ಎಲ್ಲಾ ಪ್ರಕಾರದ ಆಶ್ಚರ್ಯಗಳಿಂದ ಕೂಡಿರುವ, ಅನಂತನೂ ಮತ್ತು ಸರ್ವತೋಮುಖವಾದ ವಿರಾಟ್ ಸ್ವರೂಪೀ ಪರಮದೇವ ಪರಮೇಶ್ವರನನ್ನು ಅರ್ಜುನನು ನೋಡಿದನು. 11.11",

      "ಸಾವಿರ ಸೂರ್ಯರು ಒಂದೇ ಬಾರಿ ಉದಯಿಸಿದರೆ ಯಾವ ಪ್ರಕಾಶವು ಉಂಟಾಗುವುದೋ ಅದು ಆ ವಿಶ್ವರೂಪೀ ಪರಮಾತ್ಮನ ಪ್ರಕಾಶಕ್ಕೆ ಸರಿ ಸಮಾನ ಬಹುಶಃ ಆದೀತು. 11.12",

      "ಆ ಸಮಯದಲ್ಲಿ ಅನೇಕ ಪ್ರಕಾರಗಳಿಂದ ವಿಭಕ್ತವಾಗಿರುವ, ಅಂದರೆ ಬೇರೆ ಬೇರೆಯಾಗಿರುವ ಸಮಸ್ತ ಜಗತ್ತನ್ನು ಆ ದೇವಾದಿದೇವನಾದ ಶ್ರೀಕೃಷ್ಣನ ಶರೀರದಲ್ಲಿ ಒಂದೇ ಕಡೆಯಲ್ಲಿರುವುದನ್ನು  ಪಾಂಡುಪುತ್ರ ಅರ್ಜುನನು ನೋಡಿದನು. 11.13",

      "ಅನಂತರ ಆಶ್ಚರ್ಯಭರಿತನಾಗಿ ಹರ್ಷದಿಂದ ರೋಮಾಂಚನಗೊಂಡ ಅರ್ಜುನನು ವಿಶ್ವರೂಪೀ ಪರಮಾತ್ಮನಿಗೆ ಶ್ರದ್ಧಾಭಕ್ತಿಸಹಿತ ಶಿರಸಾಷ್ಟಾಂಗ ನಮಸ್ಕಾರ ಮಾಡಿ, ಕೈಮುಗಿದು ಹೇಳಿದನು: 11.14",

      "ಹೇ ದೇವ!  ನಿನ್ನ ಶರೀರದಲ್ಲಿ ಎಲ್ಲಾ ದೇವತೆಗಳನ್ನೂ ಹಾಗು ಅನೇಕ ಜೀವ ಸಮುದಾಯಗಳನ್ನೂ, ಕಮಲಾಸನದಲ್ಲಿ ಕುಳಿತಿರುವ ಬ್ರಹ್ಮನನ್ನೂ, ಮಹಾದೇವನನ್ನೂ, ಮತ್ತು ಎಲ್ಲಾ ಋಷಿಗಳನ್ನೂ, ಹಾಗು ದಿವ್ಯವಾದ ಸರ್ಪಗಳನ್ನೂ ನೋಡುತ್ತಿದ್ದೇನೆ. 11.15",

      "ಹೇ ವಿಶ್ವೇಶ್ವರ!  ಅನೇಕ ಕೈಗಳೂ, ಉದರಗಳೂ, ಮುಖಗಳೂ ಮತ್ತು ಕಣ್ಣುಗಳೂ ಉಳ್ಳವನಾಗಿ ಎಲ್ಲ ಕಡೆಗಳಿಂದಲೂ ಅನಂತ ರೂಪಗಳುಳ್ಳವನಾದ ನಿನ್ನನ್ನು ನೋಡುತ್ತಿದ್ದೇನೆ.  ಹೇ ವಿಶ್ವರೂಪನೇ, ನಿನ್ನ ಅಂತ್ಯವೂ ಕಾಣಿಸುತ್ತಿಲ್ಲ, ಮಧ್ಯವೂ ಮತ್ತು ಆದಿಯೂ ಸಹ ಕಾಣಿಸುತ್ತಿಲ್ಲ. 11.16",

      "ಕಿರೀಟ, ಗದೆ, ಚಕ್ರ, ಮುಂತಾದುವುಗಳನ್ನು ಧರಿಸಿರುವ, ಎಲ್ಲೆಲ್ಲಿಯೂ ಪ್ರಕಾಶಮಯವಾದ ತೇಜಃಪುಂಜಗಳು ಪ್ರಜ್ವಲಿಸುವ, ಅಗ್ನಿ ಮತ್ತು ಸೂರ್ಯರಂತಹ ಜ್ಯೋತಿಗಳಿಂದ ಕೂಡಿರುವ, ನೋಡಲು ಅಸಾಧ್ಯವಾದ, ಅಪ್ರಮೇಯ ಸ್ವರೂಪಿಯಾದ ನಿನ್ನನ್ನು ಎಲ್ಲಾ ಕಡೆಗಳಿಂದಲೂ ನೋಡುತ್ತಿದ್ದೇನೆ. 11.17",

      "ತಿಳಿದುಕೊಳ್ಳಲೇ ಬೇಕಾದಂತಹ ಪರಬ್ರಹ್ಮ ಪರಮಾತ್ಮನು ನೀನೇ, ಈ ಜಗತ್ತಿಗೆ ಪರಮ ಆಶ್ರಯವೂ ನೀನೇ, ಅನಾದಿ ಧರ್ಮ ಸಂರಕ್ಷಕನೂ ನೀನೇ, ಮತ್ತು ಅವಿನಾಶೀ ಸನಾತನ ಪುರುಷನೂ ನೀನೇ ಎಂದು ನನ್ನ ಅಭಿಪ್ರಾಯ. 11.18",

      "ಆದಿ, ಮಧ್ಯ ಮತ್ತು ಅಂತ್ಯಗಳಿಲ್ಲದವನೂ, ಅನಂತ ಸಾಮರ್ಥ್ಯವುಳ್ಳವನೂ, ಅನಂತ ಕೈಗಳುಳ್ಳವನೂ, ಚಂದ್ರ-ಸೂರ್ಯರನ್ನೇ ಕಣ್ಣುಗಳಾಗಿ ಉಳ್ಳವನೂ, ಪ್ರಜ್ವಲಿಸುವ ಅಗ್ನಿರೂಪೀ ಮುಖವುಳ್ಳವನೂ, ಮತ್ತು ನಿನ್ನ ತೇಜಸ್ಸಿನಿಂದಲೇ ಈ ಜಗತ್ತಿಗೆ ತಾಪವನ್ನು ಕೊಡುತ್ತಿರುವ ನಿನ್ನನ್ನು ನೋಡುತ್ತಿದ್ದೇನೆ. 11.19",

      "ಓ ಮಹಾತ್ಮ!   ಈ ಸ್ವರ್ಗ ಮತ್ತು ಭೂಮಿಯ ಮಧ್ಯದ ಪೂರ್ಣ ಆಕಾಶ ಹಾಗು ಎಲ್ಲಾ ದಿಕ್ಕುಗಳೂ ನಿನ್ನೊಬ್ಬನಿಂದಲೇ ಪರಿಪೂರ್ಣವಾಗಿದೆ.  ನಿನ್ನ ಈ ಅಲೌಕಿಕ ಮತ್ತು ಭಯಾನಕ ರೂಪವನ್ನು ನೋಡಿ ಮೂರು ಲೋಕಗಳೂ ಭೀತಿಗೊಂಡಿವೆ. 11.20",

      "ಆ ದೇವತಾ ಸಮೂಹಗಳು ನಿನ್ನಲ್ಲಿ ಪ್ರವೇಶಿಸುತ್ತಿವೆ.  ಕೆಲವರು ಭಯಭೀತರಾಗಿ ಕೈ ಜೋಡಿಸಿಕೊಂಡು ನಿನ್ನ ನಾಮ ಮತ್ತು ಗುಣಗಳನ್ನು ಸ್ಮರಿಸುತ್ತಾರೆ.  ಮಹರ್ಷಿಗಳ ಮತ್ತು ಸಿದ್ಧರ ಸಮುದಾಯಗಳು 'ಮಂಗಳವಾಗಲಿ' ಎಂಬುದಾಗಿ ಹೇಳಿ ಉತ್ಕೃಷ್ಟವಾದ ಸ್ತೋತ್ರಗಳ ಮೂಲಕ ನಿನ್ನನ್ನು ಭಜಿಸುತ್ತಾರೆ. 11.21",

      "ಏಕಾದಶ ರುದ್ರರು, ದ್ವಾದಶ ಆದಿತ್ಯರು, ಅಷ್ಟವಸುಗಳು, ಸಾಧ್ಯರ ಗಣಗಳು, ವಿಶ್ವೇದೇವ, ಅಶ್ವಿನೀಕುಮಾರರು, ಮರುದ್ಗಣಗಳು, ಪಿತೃಸಮುದಾಯ, ಗಂಧರ್ವ, ಯಕ್ಷ, ರಾಕ್ಷಸ ಮತ್ತು ಸಿದ್ಧರ ಸಮುದಾಯ ಇವುಗಳೆಲ್ಲವೂ ಆಶ್ಚರ್ಯಭರಿತವಾಗಿ ನಿನ್ನನ್ನು ನೋಡುತ್ತಾರೆ. 11.22",

      "ಹೇ ಮಹಾನುಭಾಹುವೇ!  ಅನೇಕ ಮುಖಗಳೂ, ಕಣ್ಣುಗಳೂ, ಅನೇಕ ಕೈಗಳೂ, ತೊಡೆಗಳೂ ಮತ್ತು ಕಾಲುಗಳೂ, ಅನೇಕ ಉದರಗಳೂ, ಅನೇಕ ವಿಕಾರ ಕೋರೆದಾಡೆಗಳೂ ಉಳ್ಳ ನಿನ್ನ ಮಹತ್ತಾದ ರೂಪವನ್ನು ನೋಡಿ ಲೋಕಗಳೆಲ್ಲಾ ಭಯಭೀತಗೊಳ್ಳುತ್ತಿವೆ.   ನನಗೂ ಸಹ ಭಯವಾಗುತ್ತಿದೆ. 11.23",

      "ಏಕೆಂದರೆ, ಓ ವಿಷ್ಣುವೇ! ಆಕಾಶವನ್ನು ಸ್ಪರ್ಶಿಸಿರುವವನೂ, ತೇಜೋಮಯನೂ, ಅನೇಕ ವರ್ಣಗಳುಳ್ಳವನೂ, ವಿಶಾಲವಾದ ತೆರೆದ ಬಾಯಿಗಳುಳ್ಳವನೂ, ಪ್ರಕಾಶಮಾನವಾದ ವಿಶಾಲ ಕಣ್ಣುಗಳುಳ್ಳವನೂ ಆದ ನಿನ್ನನ್ನು ನೋಡಿ ಭಯಭೀತನಾಗಿ ನಾನು ಧೈರ್ಯ ಮತ್ತು ಶಾಂತಿ ಕಳೆದುಕೊಂಡಿದ್ದೇನೆ. 11.24",

      "ನಿನ್ನ ವಿಕಾರ ಕೋರೆದಾಡೆಗಳನ್ನು ಮತ್ತು ಪ್ರಳಯಾಗ್ನಿಯಂತೆ ಪ್ರಜ್ವಲಿಸುವ ಮುಖಗಳನ್ನು ನೋಡಿ ದಿಕ್ಕುಕಾಣದಾಗಿದ್ದೇನೆ ಮತ್ತು ನೆಮ್ಮದಿಯೂ ಸಿಗುತ್ತಿಲ್ಲ.  ಆದುದರಿಂದ, ಹೇ ದೇವೇಶ! ಓ ಜಗನ್ನಿವಾಸ! ಪ್ರಸನ್ನನಾಗು. 11.25",

      "ಧೃತರಾಷ್ಟ್ರನ ಆ ಸಮಸ್ತ ಪುತ್ರರು ರಾಜರುಗಳ ಸಮುದಾಯದ ಸಹಿತ ನಿನ್ನಲ್ಲಿ ಪ್ರವೇಶಿಸುತ್ತಿದ್ದಾರೆ ಮತ್ತು ಭೀಷ್ಮ ಪಿತಾಮಹರು, ದ್ರೋಣಾಚಾರ್ಯರು ಹಾಗೂ ಕರ್ಣನೂ ಮತ್ತು ಪ್ರಮುಖ ಯೋಧರ ಸಹಿತ ನಮ್ಮ ಪಕ್ಷದವ ಎಲ್ಲರೂ.. 11.26",

      "ನಿನ್ನ ವಿಕಾರ ಕೋರೆದಾಡೆಗಳ ಕಾರಣದಿಂದ ವಿಕರಾಳವಾದ ಭಯಂಕರ ಮುಖಗಳಲ್ಲಿ ಬಹು ವೇಗವಾಗಿ ಪ್ರವೇಶಿಸುತ್ತಿದ್ದಾರೆ.  ಕೆಲವರಂತೂ, ಚೂರು-ಚೂರಾದ ತಲೆಗಳ ಸಹಿತ ನಿನ್ನ ಹಲ್ಲುಗಳ ಸಂದುಗಳಲ್ಲಿ ಸಿಕ್ಕಿಹಾಕಿಕೊಂಡಿರುವಂತೆ ಕಾಣುತ್ತಿದ್ದಾರೆ. 11.27",

      "ಹೇಗೆ ನದಿಗಳ ಎಲ್ಲ ಜಲಪ್ರವಾಹಗಳು ಸಮುದ್ರಕ್ಕೆ ಅಭಿಮುಖವಾಗಿಯೇ ಓಡುತ್ತವೆಯೋ, ಅರ್ಥಾತ್ ಸಾಗರಕ್ಕೆ ಸೇರುತ್ತದೆಯೋ, ಹಾಗೆಯೇ ನರಲೋಕದ ಶ್ರೇಷ್ಠ ವೀರರು ಸಹ ಪ್ರಜ್ವಲಿಸುತ್ತಿರುವ ನಿನ್ನ ಮುಖಗಳಲ್ಲಿ ಪ್ರವೇಶಿಸುತ್ತಿದ್ದಾರೆ. 11.28",

      "ಹೇಗೆ ಪತಂಗಗಳು ಮೋಹವಶದಿಂದ ನಾಶಹೊಂದುವುದಕ್ಕಾಗಿಯೇ ಪ್ರಜ್ವಲಿಸುವ ಅಗ್ನಿಯಲ್ಲಿ ಅತ್ಯಂತ ವೇಗದಿಂದ ಪ್ರವೇಶಿಸುತ್ತವೆಯೋ ಹಾಗೆಯೇ ಈ ಜನರೆಲ್ಲರೂ ಸಹ ತಮ್ಮ ನಾಶಕ್ಕಾಗಿಯೇ ನಿನ್ನ ಮುಖಗಳಲ್ಲಿ ಅತಿ ವೇಗವಾಗಿ ಪ್ರವೇಶಿಸುತ್ತಿದ್ದಾರೆ. 11.29",

      "ನೀನು, ಸಮಸ್ತ ಲೋಕಗಳನ್ನೂ ಪ್ರಜ್ವಲಿಸುವ ನಿನ್ನ ಮುಖಗಳಿಂದ ನುಂಗುತ್ತಾ ಎಲ್ಲ ಕಡೆಗಳಿಂದಲೂ ಚಪ್ಪರಿಸುತ್ತಿರುವೆ.  ಹೇ ಪರಮಾತ್ಮನೆ!  ನಿನ್ನ ಉಗ್ರವಾದ ಪ್ರಕಾಶವು ತೇಜಸ್ಸಿನಿಂದ ತುಂಬಿಕೊಂಡು ಇಡೀ ಜಗತ್ತನ್ನು ದಹಿಸುತ್ತಿದೆ. 11.30",

      "ಉಗ್ರರೂಪಿಯಾದ ನೀನು ಯಾರೆಂದು ನನಗೆ ಹೇಳು, ಹೇ ದೇವಶ್ರೇಷ್ಠನೇ!  ನಿನಗೆ ಇದೋ ನಮಸ್ಕಾರಗಳು.  ಪ್ರಸನ್ನನಾಗು, ಆದಿ ಪುರುಷನಾದ ನಿನ್ನನ್ನು ಯಥಾರ್ಥವಾಗಿ ತಿಳಿದುಕೊಳ್ಳಲು ಇಚ್ಛಿಸುತ್ತೇನೆ.  ಏಕೆಂದರೆ ನಿನ್ನ ಪ್ರವೃತ್ತಿಯನ್ನು ನಾನು ತಿಳಿಯೆನು. 11.31",

      "ಅರ್ಜುುನ ಪ್ರಶ್ನೆಗೆ ಉತ್ತರಿಸುತ್ತಾ ಶ್ರೀಕೃಷ್ಣ ಹೇಳಿದ :  ಎಲೈ ಅರ್ಜುನ, ಲೋಕಗಳನ್ನು ನಾಶಮಾಡಲು ಮುನ್ನುಗ್ಗುವ ಮಾಹಾಕಾಲ ನಾನಾಗಿರುವೆನು.  ಈಗ ಈ ಲೋಕಗಳನ್ನು ನಾಶಮಾಡಲು ತೊಡಗಿದ್ದೇನೆ.  ಹಾಗಾಗಿ, ಎದರು ಪಕ್ಷದ ಸೈನ್ಯದ ಯೋಧರಾರೂ ಉಳಿಯುವುದಿಲ್ಲ.  ಅಂದರೆ, ನೀನು ಯುದ್ಧ ಮಾಡದಿದ್ದರೂ ಸಹ ಇವರಲೆಲ್ಲರೂ ನಾಶವಾಗಿಹೋಗುವರು. 11.32",

      "ಆದುದರಿಂದ ನೀನು ಸಿದ್ಧವಾಗಿ ನಿಲ್ಲು, ಯಶಸ್ಸನ್ನು ಗಳಿಸು, ಶತ್ರುಗಳನ್ನು ಜಯಿಸಿ ಧನ-ಧಾನ್ಯಗಳಿಂದ ಸಮೃದ್ಧವಾದ ರಾಜ್ಯಸುಖವನ್ನು ಅನುಭವಿಸು.  ಈ ಎಲ್ಲಾ ವೀರಯೋಧರೂ ಮೊದಲೇ ನನ್ನಿಂದ ಕೊಲ್ಲಲ್ಪಟ್ಟಿದ್ದಾರೆ.  ಎಲೈ ಸವ್ಯಸಾಚಿಯೇ, ನೀನು ಕೇವಲ ನಿಮಿತ್ತಮಾತ್ರನಾಗು. 11.33",

      "ನನ್ನಿಂದ ಕೊಲ್ಲಲ್ಪಟ್ಟ ದ್ರೋಣಾಚಾರ್ಯರು, ಭೀಷ್ಮ ಪಿತಾಮಹರು, ಜಯದ್ರಥ, ಕರ್ಣ, ಹಾಗು ಇನ್ನೂ ಅನೇಕ ವೀರ ಯೋಧರುಗಳನ್ನು ನೀನು ಸಂಹರಿಸು; ಭಯ ಪಡಬೇಡ, ಯುದ್ಧದಲ್ಲಿ ನೀನು ವೈರಗಳನ್ನು ನಿಸ್ಸಂದೇಹವಾಗಿ ಜಯಿಸುವೆ.  ಆದ್ದರಿಂದ ಯುದ್ಧ ಮಾಡು. 11.34",

      "ಸಂಜಯನು ಹೇಳಿದನು: ಎಲೈ ರಾಜನೆ, ಕೇಶವನ ಈ ಮಾತುಗಳನ್ನು ಕೇಳಿ, ಕಿರೀಟಧಾರಿ ಅರ್ಜುನನು ಕೈಜೋಡಿಸಿಕೊಂಡು ನಡುಗುತ್ತಾ ನಮಸ್ಕರಿಸಿ, ಮತ್ತೆ ಮತ್ತೆ ಭಯಭೀತಿಯಿಂದ ವಂದಿಸಿ, ಶ್ರೀಕೃಷ್ಣನನ್ನು ಕುರಿತು ಗದ್ಗದ ಸ್ವರದಲ್ಲಿ ಹೇಳಿದನು.. 11.35",

      "ಹೇ ಅಂತರ್ಯಾಮೀ !  ನಿನ್ನ ನಾಮ ಗುಣ ಮತ್ತು ಪ್ರಭಾವಗಳ ಕೀರ್ತನೆಯಿಂದ ಜಗತ್ತು ಅತ್ಯಂತ ಹರ್ಷಪಡುತ್ತಿದೆ ಮತ್ತು ಅನುರಾಗವನ್ನೂ ಸಹ ಪಡೆಯುತ್ತಿದೆ.  ರಾಕ್ಷಸರೆಲ್ಲಾ ಭಯಭೀತಿಯಿಂದ ದಿಕ್ಕಾಪಾಲಾಗಿ ಓಡುತ್ತಿದ್ದಾರೆ ಮತ್ತು ಸಿದ್ಧರ ಸಂಘ-ಸಮುದಾಯಗಳೆಲ್ಲಾ ನಮಸ್ಕರಿಸುತ್ತಿವೆ.  ಇದು ಸರ್ವೋಚಿತವೂ ಆಗಿದೆ. 11.36",

      "ಹೇ ಮಹಾತ್ಮ!  ಬ್ರಹ್ಮನಿಗೂ ಸಹ ಆದಿಕರ್ತಾ ಮತ್ತು ಸರ್ವೋತ್ಕೃಷ್ಟವಾದ ನಿನಿಗೆ ಹೇಗೆ ತಾನೇ ನಮಸ್ಕರಿಸದೆ ಇರುವರು? ಏಕೆಂದರೆ, ಹೇ ಅನಂತ! ಹೇ ದೇವೇಶ! ಓ ಜಗನ್ನಿವಾಸ! ಯಾರು ಸತ್ ಅಸತ್ ಮತ್ತು ಅವುಗಳಿಗಿಂತ ಆಚೆಗಿರುವ ಅಕ್ಷರ, ಅಂದರೆ ಸಚ್ಚಿದಾನಂದ ಘನ ಬ್ರಹ್ಮನಿದ್ದಾನೋ ಅವನು ನೀನೇ ಆಗಿರುವೆ. 11.37",

      "ನೀನು ಆದಿದೇವ, ಸನಾತನ ಪುರುಷ, ಸರ್ವಜ್ಞ, ಜ್ಞೇಯವಸ್ತು ಅಂದರೆ ತಿಳಿದುಕೊಳ್ಳಬೇಕಾದವ, ಮತ್ತು ಪರಮ ಧಾಮ ಆಗಿರುವೆ.  ಈ ಜಗತ್ತಿಗೆ ಪರಮಾಶ್ರಯ.  ಹೇ ಅನಂತರೂಪನೇ!  ಜಗತ್ತೆಲ್ಲಾ ನಿನ್ನಿಂದ  ವ್ಯಾಪಿಸಲ್ಪಟ್ಟಿದೆ. 11.38",

      "ನೀನು ವಾಯು, ಯಮರಾಜ, ಅಗ್ನಿ, ವರುಣ, ಚಂದ್ರ, ಪ್ರಜಾಪತಿ ಅರ್ಥಾತ್ ಬ್ರಹ್ಮ, ಮತ್ತು ಬ್ರಹ್ಮನಿಗೂ ಸಹ ತಂದೆ, ನಿನಗೆ ಸಾವಿರ ನಮಸ್ಕಾರ, ಮತ್ರೊಮ್ಮೆ ನಮಸ್ಕಾರ, ಪದೇ ಪದೇ ನಮಸ್ಕಾರ! ನಮಸ್ಕಾರ. 11.39",

      "ಹೇ ಅನಂತ ಸಾಮರ್ಥ್ಯವುಳ್ಳವನೇ!  ನಿನಗೆ ಮುಂಬಾಗ ಹಾಗು ಹಿಂಬಾಗದಿಂದ ನಮಸ್ಕಾರ.  ಓ ಸರ್ವಾತ್ಮನೇ!  ನಿನಗೆ ಎಲ್ಲ ಕಡೆಗಳಿಂದಲೂ ನಮಸ್ಕಾರವಿರಲಿ.  ಏಕೆಂದರೆ, ಅನಂತ ಪರಾಕ್ರಮಶಾಲಿಯಾದ ನೀನು ಇಡೀ ಜಗತ್ತನ್ನು ವ್ಯಾಪಿಸಿಕೊಂಡಿರುವ ಪ್ರಯುಕ್ತ ನೀನೇ ಸರ್ವಸ್ವರೂಪಿಯಾಗಿರುವೆ. 11.40",

      "ಮಿತ್ರನೆಂದು ಭಾವಿಸಿಕೊಂಡು ನಿನ್ನ ಪ್ರಭಾವವನ್ನು ತಿಳಿದುಕೊಳ್ಳದೆ ನನ್ನಿಂದ ಪ್ರೇಮವಶಾತ್ ಅಥವಾ ಪ್ರಮಾದದಿಂದಲಾದರೂ ಸಹ, ಹೇ ಕೃಷ್ಣ! ಓ ಯಾದವಾ! ಹೇ ಮಿತ್ರನೇ ಈ ರೀತಿ ಏನೇನನ್ನು ದಿಟ್ಟತನದಿಂದ ಒರಟಾಗಿ ಹೇಳಲ್ಪಟ್ಟಿರುವದೋ. 11.41",

      "ಮತ್ತು ಹೇ ಅಚ್ಚುತ! ಏನನ್ನು ವಿನೋದಕ್ಕಾಗಿ, ವಿಹಾರಕಾಲದ, ಮಲಗುವ, ಕುಳಿತುಕೊಳ್ಳುವ ಮತ್ತು ಭೋಜನಾದ ಸಮಯಗಳಲ್ಲಿ, ಒಬ್ಬನೇ ಇರುವಾಗ ಅಥವಾ ಆ ಸ್ನೇಹಿತರ ಎದುರಿಗಾದರೂ ಸಹ ಅಪಮಾನಿಸಲ್ಪಟ್ಟಿರುವೆ.  ಆ ಅಪರಾಧಗಳಿಗಾಗಿ, ಅಪ್ರಮೇಯ ಸ್ವರೂಪಇಯಾದ ನಿನ್ನಲ್ಲಿ ನಾನು ಕ್ಷಮೆ ಯಾಚಿಸುತ್ತೇನೆ. 11.42",

      "ನೀನು ಈ ಚರಾಚರತ್ಮಕವಾದ ಜಗತ್ತಿಗೆ ತಂದೆ ಮತ್ತು ಗುರುವಿಗಿಂತಲೂ ಮಿಗಿಲಾದ ಗುರು ಹಾಗು ಅತ್ಯಂತ ಪೂಜ್ಯನಾಗಿರುವೆ.  ಹೇ ಅತಿಶಯ ಪ್ರಭಾವಶಾಲಿಯೇ! ಮೂರೂ ಲೋಕಗಳಲ್ಲಿಯೂ ನಿನಗೆ ಸಮಾನರಾದವರು ಬೇರೆ ಯಾರೂ ಸಹ ಇರುವುದಿಲ್ಲ.  ಅಂದಮೇಲೆ ನಿನಗಿಂತಲೂ ದೊಡ್ಡವರು ಹೇಗುಂಟು? 11.43",

      "ಆದ್ದರಿಂದ, ಹೇ ಪ್ರಭೋ! ನಾನು ಶರೀರವನ್ನು ಸರಿಯಾಗಿ ನಿನ್ನ ಚರಣಗಳಲ್ಲಿಟ್ಟು ಸಾಷ್ಟಾಂಗ ನಮಸ್ಕಾರ ಮಾಡಿ, ಸ್ತುತಿಸಲು ಯೋಗ್ಯನಾದ ಈಶ್ವರನೇ ಪ್ರಸನ್ನನಾಗೆಂದು ನಿನ್ನನ್ನು ಪ್ರಾರ್ಥಿಸುತ್ತೇನೆ.  ಹೇ ದೇವಾ! ತಂದೆಯು ಮಗನ, ಮಿತ್ರನು ಮಿತ್ರನ ಮತ್ತು ಪತಿಯು ಪ್ರಿಯಪತ್ನಿಯ ಅಪರಾಧವನ್ನು ಸಹಿಸಿಕೊಳ್ಳುವಂತೆ ನೀನೂ ನನ್ನ ಅಪರಾಧವನ್ನು ಸಹಿಸಿಕೊಳ್ಳಲು ತಕ್ಕವನಾಗಿರುವೆ. 11.44",

      "ನಾನು ಈ ಮೊದಲು ನೋಡದೇ ಇದ್ದ ನಿನ್ನ ಈ ಆಶ್ಚರ್ಯಮಯ ರೂಪವನ್ನು ನೋಡಿ ಸಂತೋಷಪಡುತ್ತಿದ್ದೇನೆ ಹಾಗು ನನ್ನ ಮನಸ್ಸು ಭಯಗೊಂಡಿದೆ.  ಅತ್ಯಂತ ಗಾಬರಿ ಕೂಡ ಆಗುತ್ತಿದೆ.  ಆದ್ದರಿಂದ, ನೀನು ಆ ನಿನ್ನ ಚತುರ್ಭುಜ ರೂಪವನ್ನೇ ನನಗೆ ತೋರಿಸು.  ಹೇ ದೇವೇಶ! ಹೇ ಜಗನ್ನಿವಾಸ ನೀನು ಪ್ರಸನ್ನನಾಗು. 11.45",

      "ಕಿರೀಟಧಾರಿಯಾಗಿರುವ, ಗದೆ ಮತ್ತು ಚಕ್ರಗಳನ್ನು ಕರದಲ್ಲಿ ಹಿಡಿದಿರುವ ರೀತಿಯಲ್ಲಿಯೇ ನಿನ್ನನ್ನು ನಾನು ನೋಡಲು ಇಚ್ಛಿಸುತ್ತೇನೆ.  ಆದುದರಿಂದ, ಹೇ ವಿಶ್ವಸ್ವರೂಪಿಯೇ! ಹೇ ಸಹಸ್ರ ಬಾಹುವೇ! ಅದೇ ಚತುರ್ಭುಜ ರೂಪದಿಂದ ಪ್ರತ್ಯಕ್ಷನಾಗು. 11.46",

      "ಶ್ರೀಕೃಷ್ಣನು ಹೇಳಿದನು - ಎಲೈ  ಅರ್ಜುನ!  ಅನುಗ್ರಹಪೂರ್ವಕವಾಗಿ ನಾನು ಯೋಗಶಕ್ತಿಯ ಪ್ರಭಾವದಿಂದ ಈ ನನ್ನ ಪರಮ ತೇಜೋಮಯವಾದ, ಎಲ್ಲಕ್ಕೂ ಅದಿಯಾದ ಮತ್ತು ಅನಂತವಾದ ವಿರಾಟ್ ಸ್ವರೂಪವನ್ನು ನಿನಗೆ ತೋರಿಸಿದೆನು.  ಇದನ್ನು ನಿನ್ನ ಹೊರತು ಬೇರೆ ಯಾರೂ ಮೊದಲು ನೋಡಿರಲಿಲ್ಲ. 11.47",

      "ಎಲೈ ಅರ್ಜುುನ!  ಮಾನವ ಲೋಕದಲ್ಲಿ ಈ ಪ್ರಕಾರ ವಿಶ್ವರೂಪವುಳ್ಳ ನಾನು ವೇದ ಮತ್ತು ಯಜ್ಞಗಳ ಅಧ್ಯಯನದಿಂದಲೂ, ದಾನದಿಂದಲೂ, ಕರ್ಮಕ್ರಿಯೆಗಳಿಂದಲೂ ಮತ್ತು ಉಗ್ರವಾದ ತಪಸ್ಸಿನಿಂದಲೂ ಸಹ ಕಾಣಿಸಿಕೊಳ್ಳಲಾರೆ ಹಾಗು ನಿನ್ನ ಹೊರತು ಬೇರೆಯವರಿಂದ ನೋಡಲಾಗುವುದಿಲ್ಲ. 11.48",

      "ಈ ಪ್ರಕಾರದ ನನ್ನ ಈ ವಿಕರಾಳ ರೂಪವನ್ನು ನೋಡಿ ನಿನಗೆ ಭಯ, ಭ್ರಾಂತಿ ಮತ್ತು ಬುದ್ಧಿಶೂನ್ಯತೆ ಉಂಟಾಗಬಾರದು.  ಭಯವನ್ನು ಬಿಟ್ಟು ಪ್ರೀತಿಪೂರ್ಣ ಮನಸ್ಸಿನಿಂದ ನೀನು ಶಂಖ, ಚಕ್ರ, ಗದೆ ಮತ್ತು ಪದ್ಮಗಳುಳ್ಳ ನನ್ನ ಅದೇ ಚತುರ್ಭುಜ ರೂಪವನ್ನು ಪುನಃ ನೋಡು. 11.49",

      "ಸಂಜಯ ಹೇಳಿದ, ಎಲೈ ರಾಜನೇ, ಭಗವಾನ್ ವಾಸುದೇವನು ಅರ್ಜುನನಿಗೆ ಹೀಗೆ ಹೇಳಿ ಪುನಃ ಅದರಂತೆಯೇ ತನ್ನ ಚತುರ್ಭುಜ ರೂಪವನ್ನು ತೋರಿಸಿದನು ಮತ್ತು ಮಹಾತ್ಮ ಶ್ರೀಕೃಷ್ಣನು ಸೌಮ್ಯಮೂರ್ತಿಯಾಗಿ, ಭಯ-ಭೀತನಾಗಿದ್ದ ಅರ್ಜುನನಿಗೆ ಧೈರ್ಯ ತುಂಬಿದನು. 11.50",

      "ಅರ್ಜುನನು ಹೇಳಿದನು: ಹೇ ಜನಾರ್ದನ!  ನಿನ್ನ ಈ ಅತ್ಯಂತ ಶಾಂತವಾದ ಮನುಷ್ಯ ರೂಪವನ್ನು ನೋಡಿ ಈಗ ನಾನು ಶಾಂತಮನಸ್ಸುಳ್ಳವನಾಗಿ ನನ್ನ ಸ್ವಾಭಾವಿಕ ಸ್ಥಿತಿಯನ್ನು ಪಡೆದುಕೊಂಡಿದ್ದೇನೆ. 11.51",

      "ನನ್ನ ಯಾವ ಈ ಚತುರ್ಭುಜ ರೂಪವನ್ನು ನೀನು ನೋಡಿದೆಯೋ, ಅದು ನೋಡಲು ಅತ್ಯಂತ ದುರ್ಲಭ.  ಏಕೆಂದರೆ ದೇವತೆಗಳೂ ಸಹ ಯಾವಾಗಲೂ ಈ ರೂಪದ ದರ್ಶನ ಮಾಡಲು ಇಚ್ಛಿಸುತ್ತಿರುತ್ತಾರೆ. 11.52",

      "ನೀನು ನನ್ನನ್ನು ನೋಡಿರುವಂತೆ ಈ ಚತುರ್ಭುಜ ರೂಪದಲ್ಲಿರುವ ನಾನು ವೇದಾಧ್ಯಯನದಿಂದಲೂ, ತಪಸ್ಸಿನಿಂದಲೂ, ದಾನದಿಂದಲೂ ಮತ್ತು ಯಜ್ಞದಿಂದಲೂ ಸಹ ಕಾಣಿಸಿಕೊಳ್ಳಲು ಸಾಧ್ಯನಲ್ಲ. 11.53",

      "ಆದರೆ, ಎಲೈ ಪರಂತಪ, ಅನನ್ಯ ಭಕ್ತಿಯ ಮೂಲಕ ಈ ಪ್ರಕಾರದ ಚತುರ್ಭುಜ ರೂಪದ ನನ್ನನ್ನು ಪ್ರತ್ಯಕ್ಷವಾಗಿ ನೋಡಲು, ಯಥಾರ್ಥವಾಗಿ ತಿಳಿದುಕೊಳ್ಳಲು, ಹಾಗು ಏಕ ಭಾವದಿಂದ ಸಾಕ್ಷಾತ್ಕಾರ ಮಾಡಿಕೊಳ್ಳಲು ಸಹ ಸಾಧ್ಯ. 11.54",

      "ಎಲೈ ಅರ್ಜುನ!  ಯಾರು ಕೇವಲ ನನಗಾಗಿಯೇ ಯಜ್ಞ, ದಾನ ಮತ್ತು ತಪಸ್ಸು ಮುಂತಾದ ಎಲ್ಲ ಕರ್ತವ್ಯ-ಕರ್ಮಗಳನ್ನು ಮಾಡುತ್ತಾನೆಯೋ, ನನ್ನ ಪರಾಯಣನೋ ಅಂದರೆ ನನ್ನನ್ನೇ ಆಶ್ರಯಿಸಿ ಪರಮಗತಿಯೆಂದು ಭಾವಿಸಿ ತತ್ಪರನಾಗಿದ್ದಾನೆಯೋ, ನನ್ನ ಭಕ್ತನೋ, ಪ್ರಾಪಂಚಿಕ ವಸ್ತುಗಳಲ್ಲಿ ಆಸಕ್ತಿ ಮಮತೆ ಇಲ್ಲದವನೋ, ಸಮಸ್ತ ಜೀವಿಗಳಲ್ಲಿಯೂ ದ್ವೇಷಭಾವನೆ ಇಲ್ಲದವನೋ ಅಂತಹ ಅನನ್ಯ ಭಕ್ತನು ನನ್ನನ್ನೇ ಸೇರುತ್ತಾನೆ. 11.55",

      "ಇತಿ ಶ್ರೀಮದ್ಭಗವದ್ಗೀತಾರೂಪೀ ಉಪನಿಷತ್ ಮತ್ತು ಬ್ರಹ್ಮವಿದ್ಯೆ ಹಾಗೂ ಯೋಗಶಾಸ್ತ್ರ ವಿಷಯವಾಗಿ ಶ್ರೀಕೃಷ್ಣ ಮತ್ತು ಅರ್ಜುನರ ಸಂವಾದದಲ್ಲಿ 'ವಿಶ್ವರೂಪದರ್ಶನ ಯೋಗ' ಎಂಬ ಹನ್ನೊಂದನೆಯ ಅಧ್ಯಾಯ ಸಂಪೂರ್ಣವು."  
    ],
    "adhyaya-12" : [
      "ಅರ್ಜುನನು ಹೇಳಿದನು - ಈ ಹಿಂದೆ ತಿಳಿಸಿದ ಪ್ರಕಾರ ಯಾವ ಅನನ್ಯ ಪ್ರೇಮಿ ಭಕ್ತರು ನಿರಂತರ ನಿನ್ನ ಧ್ಯಾನದಲ್ಲಿಯೇ ನಿರತರಾಗಿದ್ದು ಸಗುಣರೂಪೀ ಪರಮೇಶ್ವರನಾದ ನಿನ್ನನ್ನು ಅತ್ಯಂತ  ಶ್ರ‍ೇಷ್ಠಭಾವನೆಯಿಂದ ಉಪಾಸನೆ ಮಾಡುತ್ತಾರೆಯೋ ಮತ್ತು ಯಾರು ಅವಿನಾಶೀ ಸಚ್ಚಿದಾನಂದ ಘನ ನಿರಾಕಾರನನ್ನೇ ಆರಾಧಿಸುತ್ತಾರೆಯೋ ಆ ಎರಡು ಪ್ರಕಾರದ ಭಕ್ತರಲ್ಲಿ ಅತ್ಯಂತ ಉತ್ತಮರಾದ ಯೋಗವೇತ್ತರು ಅರ್ಥಾತ್ ಜ್ಞಾನಿ ಯಾರು ? 12.1",

      "ಶ್ರೀ ಕೃಷ್ಣ ಪರಮಾತ್ಮನು ಹೇಳಿದನು - ನನ್ನಲ್ಲಿ ಮನಸ್ಸನ್ನು ಏಕಾಗ್ರಗೊಳಿಸಿ ನಿರಂತರ ನನ್ನ ಪ್ರಾರ್ಥನೆ - ಧ್ಯಾನದಲ್ಲಿ ನಿರತರಾದ ಯಾವ ಭಕ್ತರು ಅತಿಶಯ (ಶ್ರೇಷ್ಠ)ವಾದ ಶ್ರದ್ಧಾಯುಕ್ತರಾಗಿ ಸಗುಣರೂಪಿ ಪರಮೇಶ್ವರನಾದ ನನ್ನನ್ನು ಆರಾಧಿಸುತ್ತಾರೆಯೋ ಅವರು ನನಗೆ ಯೋಗಿಗಳಲ್ಲೆಲ್ಲಾ ಅತ್ಯುತ್ತಮ ಯೋಗಿ ಎಂದು ಒಪ್ಪಿಗೆ. 12.2",

      "ಆದರೆ ಯಾರು ಇಂದ್ರಿಯಗಳ ಸಮುದಾಯವನ್ನು ಚೆನ್ನಾಗಿ ವಶಮಾಡಿಕೊಂಡು ಮನಸ್ಸು-ಬುದ್ಧಿಗಳಿಗೂ ನಿಲುಕದ ಸರ್ವವ್ಯಾಪೀ, ಅಕಥನೀಯ ಸ್ವರೂಪೀ ಮತ್ತು ಯಾವಾಗಲೂ ಒಂದೇ ಭಾವದಲ್ಲಿರುವವನು, ಶಾಶ್ವತ,  ಅಚಲ, ನಿರಾಕಾರ, ಅವಿನಾಶೀ ಸಚ್ಚಿದಾನಂದ ಘನ ಬ್ರಹ್ಮನನ್ನು ನಿರಂತರ ಏಕೀಭಾವದಿಂದ ಧ್ಯಾನಿಸುತ್ತಾ ಆರಾಧಿಸುತ್ತಾರೆಯೋ ಆ ಸಮಸ್ತ ಜೀವಿಗಳ ಹಿತಕ್ಕಾಗಿ ಶ್ರಮಿಸುವ ಮತ್ತು ಎಲ್ಲರಲ್ಲಿಯೂ ಸಮಾನ ಭಾವನೆಯನ್ನಿತ್ತುಕೊಂಡಿರುವಂತಹ ಯೋಗಿಗಳು ನನ್ನನ್ನೇ ಪಡೆದುಕೊಳ್ಳುತ್ತಾರೆ. (12.3-12.4)",

      "ಆದರೆ ಯಾರು ಇಂದ್ರಿಯಗಳ ಸಮುದಾಯವನ್ನು ಚೆನ್ನಾಗಿ ವಶಮಾಡಿಕೊಂಡು ಮನಸ್ಸು-ಬುದ್ಧಿಗಳಿಗೂ ನಿಲುಕದ ಸರ್ವವ್ಯಾಪೀ, ಅಕಥನೀಯ ಸ್ವರೂಪೀ ಮತ್ತು ಯಾವಾಗಲೂ ಒಂದೇ ಭಾವದಲ್ಲಿರುವವನು, ಶಾಶ್ವತ,  ಅಚಲ, ನಿರಾಕಾರ, ಅವಿನಾಶೀ ಸಚ್ಚಿದಾನಂದ ಘನ ಬ್ರಹ್ಮನನ್ನು ನಿರಂತರ ಏಕೀಭಾವದಿಂದ ಧ್ಯಾನಿಸುತ್ತಾ ಆರಾಧಿಸುತ್ತಾರೆಯೋ ಆ ಸಮಸ್ತ ಜೀವಿಗಳ ಹಿತಕ್ಕಾಗಿ ಶ್ರಮಿಸುವ ಮತ್ತು ಎಲ್ಲರಲ್ಲಿಯೂ ಸಮಾನ ಭಾವನೆಯನ್ನಿತ್ತುಕೊಂಡಿರುವಂತಹ ಯೋಗಿಗಳು ನನ್ನನ್ನೇ ಪಡೆದುಕೊಳ್ಳುತ್ತಾರೆ. (12.3-12.4)",

      "ಆ ಸಚ್ಚಿದಾನಂದ ಘನ ನಿರಾಕಾರ ಬ್ರಹ್ಮನಲ್ಲಿ ಆಸಕ್ತ ಮನಸ್ಸುಳ್ಳವನ ಸಾಧನೆಯಲ್ಲಿ ಕ್ಲೇಶ ಅರ್ಥಾತ್ ಪರಿಶ್ರಮ ವಿಶೇಷವಾಗಿರುತ್ತದೆ. ಏಕೆಂದರೆ ದೇಹಾಭಿಮಾನಿಗಳಾದವರು ಅವ್ಯಕ್ತದ ಮಾರ್ಗವನ್ನು ಅರ್ಥಾತ್ ಜ್ಞಾನ ಮಾರ್ಗವನ್ನು ಬಹಳ ಕಷ್ಟದಿಂದ ಪಡೆದುಕೊಳ್ಳುತ್ತಾರೆ. ಅರ್ಥಾತ್ ದೇಹಾಭಿಮಾನವಿರುವವರೆಗೂ ಶುದ್ಧಸಚ್ಚಿದಾನಂದ ಘನ ನಿರಾಕಾರ ಬ್ರಹ್ಮನ ಸಾಕ್ಷಾತ್ಕಾರ ಉಂಟಾಗುವುದು ಕಠಿಣ. 12.5",

      "ಆದರೆ ಯಾರು ನನ್ನ ಪರಾಯಣರಾದ ಭಕ್ತರು ಎಲ್ಲಾ ಕರ್ಮಗಳನ್ನೂ ನನಗೆ ಸಮರ್ಪಿಸಿ ಸಗುಣರೂಪೀ ಪರಮೇಶ್ವರನಾದ ನನ್ನನ್ನೇ ಅನನ್ಯ ಭಾವದಿಂದ ಭಕ್ತಿಯೋಗದ ಮೂಲಕ ನಿರಂತರ ಚಿಂತನೆ ಮಾಡುತ್ತಾ ಸ್ಮರಿಸುತ್ತಾರೆಯೋ 12.6",

      "ಎಲೈ ಅರ್ಜುನ ! ನನ್ನಲ್ಲಿಯೇ ಮನಸ್ಸನ್ನು ಲೀನಗೊಳಿಸಿರುವ ಆ ಪ್ರೇಮಿ ಭಕ್ತರನ್ನು ನಾನು ಶೀಘ್ರವಾಗಿಯೇ ಮೃತ್ಯುರೂಪೀ ಸಂಸಾರ - ಸಮುದ್ರದಿಂದ ಪಾರುಮಾಡಿ ಉದ್ಧಾರಮಾಡುತ್ತೇನೆ 12.7",

      "ನನ್ನಲ್ಲಿಯೇ ಮನಸ್ಸನ್ನು ಕೇಂದ್ರೀಕರಿಸು ಮತ್ತು ಬುದ್ಧಿಯನ್ನು ತಲ್ಲೀನಗೊಳಿಸು, ಇದರ ನಂತರ ನನ್ನಲ್ಲಿಯೇ ನಿವಾಸಮಾಡುವೆ ಅರ್ಥಾತ್ ನನ್ನನ್ನೇ ಸೇರುವೆ. ಇದರಲ್ಲಿ ಸ್ವಲ್ಪವೂ ಸಹ ಸಂಶಯವೆಂಬುದೇ ಇಲ್ಲ. 12.8",

      "ಒಂದು ವೇಳೆ ನೀನು ಮನಸ್ಸನ್ನು ನನ್ನಲ್ಲಿ ಸ್ಥಿರವಾಗಿ ನೆಲೆಗೊಳಿಸಲು ಅಸಮರ್ಥನಾದರೆ, ಎಲೈ ಅರ್ಜುನಾ ! ಅಭ್ಯಾಸರೂಪೀ ಯೋಗದ ಮೂಲಕ ನನ್ನನ್ನು ಸೇರಲು ಇಚ್ಛಿಸು. 12.9",

      "ಮೇಲೆ ತಿಳಿಸಿದ ಅಭ್ಯಾಸದಲ್ಲಿಯೂ ಸಹ ಅಸಮರ್ಥನಾದರೆ ಕೇವಲ ನನಗಾಗಿಯೇ ನೀನು ಕರ್ಮಪರಾಯಣನಾಗು, ಹೀಗೆ ನನ್ನ ನಿಮಿತ್ತವಾಗಿ ಕರ್ಮಗಳನ್ನು ಮಾಡುತ್ತಾ ಇದ್ದರೂ ಸಹ ನನ್ನ ಸಾಕ್ಷಾತ್ಕಾರ ರೂಪೀ ಸಿದ್ಧಿಯನ್ನೇ ಪಡೆಯುವೆ. 12.10",

      "ಒಂದು ವೇಳೆ ನನ್ನ ಪ್ರಾಪ್ತಿ ರೂಪೀ ಯೋಗವನ್ನು ಆಶ್ರಯಿಸಿ ಮೇಲೆ ಹೇಳಿದಂತೆ ಸಾಧನೆಯನ್ನು ಮಾಡಲು ಸಹ ಅಸಮರ್ಥನೇ ಆದ ಪಕ್ಷದಲ್ಲಿ ಮನಸ್ಸು ಮತ್ತು ಬುದ್ಧಿ ಮೊದಲಾದವುಗಳನ್ನು ಜಯಿಸಿ ಎಲ್ಲಾ ಕರ್ಮಗಳ ಫಲಗಳನ್ನು ತ್ಯಾಗ ಮಾಡು. 12.11",

      "ಏಕೆಂದರೆ, ರಹಸ್ಯವನ್ನು ತಿಳಿಯದೆ ಮಾಡಲಾದ ಅಭ್ಯಾಸಕ್ಕಿಂತ ಪರೋಕ್ಷ ಜ್ಞಾನ ಶ್ರೇಷ್ಠ, ಪರೋಕ್ಷ ಜ್ಞಾನಕ್ಕಿಂತ ಪರಮಾತ್ಮನಾದ ನನ್ನ ಧ್ಯಾನ ಶ್ರ‍ೇಷ್ಠ ಮತ್ತು ಧ್ಯಾನಕ್ಕಿಂತಲೂ ಕರ್ಮಫಲಗಳನ್ನೇಲ್ಲಾ ನನಗಾಗಿ ತ್ಯಾಗ ಮಾಡುವುದು ಶ್ರ‍ೇಷ್ಠ. ತ್ಯಾಗದಿಂದ ಕೂಡಲೇ ಪರಮ ಶಾಂತಿ ಲಭಿಸುತ್ತದೆ. 12.12",

      "( ಈ ರೀತಿ ಶಾಂತಿಯನ್ನು ಪಡೆದವನು ) ಎಲ್ಲಾ ಪ್ರಾಣಿಗಳಲ್ಲಿಯೂ ದ್ವೇಷ ಭಾವವಿಲ್ಲದವನು, ಸ್ವಾರ್ಥವಿಲ್ಲದ ಎಲ್ಲರ ಪ್ರೇಮಿ ಮತ್ತು ನಿಃಸ್ವಾರ್ಥ ದಯಾಪರನೂ ಜೊತೆಗೆ ಮಮಕಾರವಿಲ್ಲದವನು, ಅಹಂಕಾರವಿಲ್ಲದವನು, ಸುಖ ದುಃಖಾದಿಗಳಲ್ಲಿ ಸಮಾನವಾಗಿರುವವನು ಮತ್ತು ಕ್ಷಮಾಶೀಲನೂ ಆಗಿದ್ದಾನೆಯೋ ಅರ್ಥಾತ್ ಅಪರಾಧ ಮಾಡುವವನಿಗೂ ಸಹ ಅಭಯ ಕೊಡುವವನು. 12.13",

      "ಹಾಗೂ ಯಾರು ಧ್ಯಾನಯೋಗದಲ್ಲಿ ನಿರತನೋ, ಯಾವಾಗಲೂ ಎಲ್ಲ ರೀತಿಯಿಂದಲೂ ಸಂತುಷ್ಟನೋ, ಮನಸ್ಸು ಮತ್ತು ಇಂದ್ರಿಯಗಳ ಸಹಿತ ಶರೀರವನ್ನು ವಶದಲ್ಲಿಟ್ಟುಕೊಂಡಿರುವನೋ, ನನ್ನಲ್ಲಿ ಮನಸ್ಸು-ಬುದ್ಧಿಗಳನ್ನು ಅರ್ಪಿಸಲ್ಪಟ್ಟ ಆ ನನ್ನ ಭಕ್ತನು ನನಗೆ ಬಹು ಪ್ರಿಯನು. 12.14",

      "ಯಾರಿಂದ ಯಾರೂ ಸಹ ಉದ್ವೇಗಗೊಳ್ಳುವುದಿಲ್ಲವೋ ಮತ್ತು ಯಾರು ಸ್ವತಃ ಬೇರೆ ಯಾರಿಂದಲೂ ಉದ್ವೇಗಗೊಳ್ಳುವುದಿಲ್ಲವೋ ಹಾಗೂ ಯಾರು ಹರ್ಷ, ಅಸೂಯೆ, ( ಅಸಹನೆ) ಭಯ, ಉದ್ವೇಗಾದಿಗಳಿಂದ ಮುಕ್ತನೋ ಆ ಭಕ್ತನು ನನಗೆ ಪ್ರಿಯನು. 12.15",

      "ಯಾರು ಆಕಾಂಕ್ಷಾರಹಿತನೋ ಅಂತರಂಗ- ಬಹಿರಂಗ ಶುದ್ಢನೋ, ಚತುರನೋ ಅರ್ಥಾತ್ ಯಾವ ಉದ್ದೇಶಕ್ಕಾಗಿ ಜನಿಸಿರುವನೋ ಆ ಉದ್ದೇಶ ಪೂರ್ತಿಗೊಳಿಸಿದವನೋ ಮತ್ತು ಪಕ್ಷಪಾತವಿಲ್ಲದವನೋ, ದುಃಖಾದಿಗಳಿಂದ ಪಾರಾದವನೋ ಮತ್ತು ಎಲ್ಲಾ ಕರ್ಮಗಳಲ್ಲಿ ಮಮತೆ, ಆಸಕ್ತಿ ಮತ್ತು ಫಲಾಪೇಕ್ಷಾದಿಗಳ ಪರಿತ್ಯಾಗಿಯೋ ಆ ನನ್ನ ಭಕ್ತನು ನನಗೆ ಪ್ರಿಯನಾದವನು. 12.16",

      "ಯಾರು ಎಂದೆಂದಿಗೂ ಸಂತೋಷದಿಂದ ಹಿಗ್ಗುವುದಿಲ್ಲವೋ, ದ್ವೇಷ ಭಾವ ರಹಿತನೋ, ಶೋಕಪಡುವುದಿಲ್ಲವೋ, ಏನನ್ನು ಬಯಸುವುದಿಲ್ಲವೋ ಮತ್ತು ಯಾರು ಎಲ್ಲಾ ಶುಭ ಮತ್ತು ಅಶುಭ ಕರ್ಮಗಳ ಫಲವನ್ನು ತ್ಯಾಗಮಾಡಿರುವನೋ ಆ ಭಕ್ತನು ನನಗೆ ಪ್ರಿಯನು 12.17",

      "ಯಾರು ಶತ್ರು- ಮಿತ್ರರಲ್ಲಿ ಮತ್ತು ಮಾನಾಪಮಾನಗಳಲ್ಲಿ ಸಮಾನಭಾವನೆಯಿಟ್ಟಿರುವನೋ ಹಾಗೂ ಶೀತೋಷ್ಣ ಸುಖ ದುಃಖಾದಿ ದ್ವಂದ್ವಗಳಲ್ಲಿ ಸಮಾನವಾಗಿರುವನೋ ಮತ್ತು ಸಾಂಸಾರಿಕ ವಿಷಯಗಳಲ್ಲಿ ಆಸಕ್ತಿಯಿಲ್ಲದವನೋ 12.18",

      "ಯಾರು ಹೊಗಳಿಕೆ- ತೆಗಳಿಕೆಗಳನ್ನು ಸಮವಾಗಿ ತಿಳಿದುಕೊಳ್ಳುವನೋ, ಮನನ ಶೀಲನೋ, ಯಾವುದೇ ರೀತಿಯ ಜೀವನೋಪಾಯವೇ ಆಗಿರಲಿ ಯಾವಾಗಲೂ ತೃಪ್ತನಾಗಿರುವನೋ, ನಿವಾಸಾದಿಗಳ ಬಗ್ಗೆ ಮಮತೆಯಿಲ್ಲದಿರುವವನೋ ಆ ಸ್ಥಿರಬುದ್ಧಿಯುಳ್ಳ ಭಕ್ತನಾದ ಮನುಷ್ಯನು ನನಗೆ ಪ್ರಿಯನು 12.19",

      "ಆದರೆ ಯಾರು ನನ್ನ ಪರಾಯಣರಾಗಿ ಶ್ರದ್ಧೆಯಿಂದ ಈ ಹಿಂದೆ ತಿಳಿಸಿರುವ ಧರ್ಮಮಯ ಉಪದೇಶಾಮೃತವನ್ನು ನಿಷ್ಕಾಮ ಭಾವನೆಯಿಂದ ಸೇವಿಸುವರೋ ಆ ಭಕ್ತರು ನನಗೆ ಅತಿಶಯ ಪ್ರಿಯರಾಗಿರುತ್ತಾರೆ. 12.20",

      "ಇತಿ ಶ್ರೀಮದ್ಭಗವದ್ಗೀತಾರೂಪೀ ಉಪನಿಷದ್, ಬ್ರಹ್ಮವಿದ್ಯೆ ಹಾಗೂ ಯೋಗಶಾಸ್ತ್ರ ವಿಷಯದಲ್ಲಿ ಶ್ರೀ ಕೃಷ್ಣ ಮತ್ತು ಅರ್ಜುನರ ಸಂವಾದದಲ್ಲಿ 'ಭಕ್ತಿಯೋಗ' ಎಂಬ ಎಂಟನೇ ಹನ್ನೆರಡನೆಯ ಅಧ್ಯಾಯ ಸಂಪೂರ್ಣ"
    ],
    "adhyaya-13" : [
      "ಶ್ರೀ ಕೃಷ್ಣ ಪರಮಾತ್ಮ ಹೇಳುತ್ತಾರೆ: ಎಲೈ ಅರ್ಜುನನೆ, ಈ ಶರೀರವು ಕ್ಷೇತ್ರ ಎಂದು ಕರೆಯಲ್ಪಡುತ್ತದೆ. ಇದನ್ನು ಯಾರು ತಿಳಿದುಕೊಂಡಿದ್ದಾರೋ ಅಂಥವರನ್ನು ಕ್ಷೇತ್ರಜ್ಞ ಎಂದು ಅವುಗಳ ಯಥಾರ್ಥವನ್ನು ತಿಳಿದುಕೊಂಡ ಜ್ಞಾನಿಗಳು ಹೇಳುತ್ತಾರೆ. 13.1",

      "ಎಲ್ಲಾ ಕ್ಷೇತ್ರಗಳಲ್ಲಿಯೂ ಕ್ಷೇತ್ರಜ್ಞ ಅರ್ಥಾತ್ ಜೀವಾತ್ಮನೂ ಸಹ ನಾನೇ ಎಂದು ತಿಳಿದುಕೊ. ಕ್ಷೇತ್ರ ಕ್ಷೇತ್ರಜ್ಞರ, ಅಂದರೆ ಪ್ರಕೃತಿಯ ಮತ್ತು ಪುರಷನ ಯಾವ ಯಥಾರ್ಥವಾದ ಅರಿವು ಇದೆಯೋ ಅದೇ ಜ್ಞಾನ ಎಂದು ನನ್ನ ಅಭಿಪ್ರಾಯ 13.2",

      "ಆ ಕ್ಷೇತ್ರ ಯಾವುದು? ಮತ್ತು ಹೇಗಿದೆ? ಹಾಗು ಯಾವ ವಿಕಾರಗಳುಳ್ಳದ್ದು? ಯಾವ ಕಾರಣದಿಂದ ಏನಾಗಿದೆ? ಆ ಕ್ಷೇತ್ರಜ್ಞ ಯಾರು? ಯಾವ ಪ್ರಭಾವವುಳ್ಳವನು ಅದೆಲ್ಲವನ್ನೂ ಸಂಕ್ಷಿಪ್ತವಾಗಿ ನನ್ನಿಂದ ಕೇಳು 13.3",

      "ಈ ಕ್ಷೇತ್ರ ಮತ್ತು ಕ್ಷೇತ್ರಜ್ಞರ ತತ್ತ್ವವು ಋಷಿಗಳಿಂದ ನಾನಾ ಪ್ರಕಾರವಾಗಿ ಹೇಳಲ್ಪಟ್ಟಿದೆ ಮತ್ತು ನಾನಾ ಪ್ರಕಾರದ ವೇದ ಮಂತ್ರಗಳಿಂದ ವಿಭಾಗಪೂರ್ವಕವಾಗಿ ತಿಳಿಸಲ್ಪಟ್ಟಿದೆ ಹಾಗು ಸರಿಯಾಗಿ ನಿಶ್ಚಯಿಸಲ್ಪಟ್ಟ ಯುಕ್ತಿಯುಕ್ತವಾದ ಬ್ರಹ್ಮಸೂತ್ರದ ಪದಗಳಿಂದಲೂ ಹಾಗೆಯೇ ಹೇಳಲ್ಪಟ್ಟಿದೆ 13.4",

      "ಪಂಚ ಸೂಕ್ಷ್ಮ ಮಹಾಭೂತಗಳು, ಅಹಂಕಾರ, ಬುದ್ಧಿ ಮತ್ತು ಮೂಲ ಪ್ರಕೃತಿಯೂ, ಅರ್ಥಾತ್ ತ್ರಿಗುಣಮಯೀ ಮಾಯೆಯೂ, ಹತ್ತು ಇಂದ್ರಿಯಾಣಿಗಳು, ಶಬ್ದ ಸ್ಪರ್ಶ ರೂಪ ರಸ ಗಂಧ ಈ ಐದು ಇಂದ್ರಿಯ ವಿಷಯಗಳು 13.5",

      "ಹಾಗೂ ಇಚ್ಛೆ, ದ್ವೇಷ, ಸುಖ, ದುಃಖ, ಸ್ಥೂಲ ದೇಹದ ಪಿಂಡ, ಚೇತನ ಮತ್ತು ಧೃತಿ ಹೀಗೆ ಈ ಕ್ಷೇತ್ರವು ವಿಕಾರಗಳ ಸಹಿತ ಸಂಕ್ಷೇಪವಾಗಿ ಹೇಳಲ್ಪಟ್ಟಿತು 13.6",

      "ಶ್ರೇಷ್ಠತ್ವದ ಅಭಿಮಾನದ ಅಭಾವ ಮತ್ತು ಡಂಬಾಚಾರವಿಲ್ಲದಿರುವುದು, ಯಾವ ಕಾರಣಕ್ಕೂ ಪ್ರಾಣಿಹಿಂಸೆ ಮಾಡದಿರುವುದು, ಕ್ಷಮಾಶೀಲತೆ, ಮಾತು ಮತ್ತು ಮನಸ್ಸಿನಲ್ಲಿ ಸರಳತೆ, ಶ್ರದ್ದಾಭಕ್ತಿ ಸಹಿತ ಗುರುಸೇವೆ ಅಂತರಂಗ-ಬಹಿರಂಗಗಳ ಶುದ್ಧಿ, ಅಂತಃಕರಣದ ಸ್ಥಿರತೆ ಮತ್ತು ಮನಸ್ಸು ಹಾಗು ಇಂದ್ರಿಯಗಳ ಸಹಿತ ಶರೀರ ನಿಗ್ರಹ 13.7",

      "ಇಹ-ಪರ ಲೋಕಗಳ ಎಲ್ಲ ಭೋಗಗಳಲ್ಲಿಯೂ ಆಸಕ್ತಿಯಿಲ್ಲದಿರುವುದು, ಅಹಂಕಾರವಿಲ್ಲದಿರುವುದು, ಜನನ-ಮರಣ, ಮುಪ್ಪು-ರೋಗ, ದುಃಖ ಮತ್ತು ದೋಷಗಳು ಇತ್ಯಾದಿ ಎಲ್ಲವುಗಳ ಬಗೆಗೆ ಮತ್ತೆ ಮತ್ತೆ ಯೋಚಿಸಿ ನೋಡುವುದು 13.8",

      "ಮಡದಿ, ಮಕ್ಕಳು, ಮನೆ ಮತ್ತು ಐಶ್ವರ್ಯಾದಿಗಳಲ್ಲಿ ಆಸಕ್ತಿಯಿಲ್ಲದಿರುವುದು, ಮಮತೆಯಿಲ್ಲದಿರುವುದು ಹಾಗು ಪ್ರಿಯವಾದದ್ದಾಗಲೀ, ಅಪ್ರಿಯವಾದದ್ದಾಗಲೀ ಒದಗಿಬಂದಾಗ ಸದಾ ಮನಸ್ಸು ಸಮಸ್ಥಿತಿಯಲ್ಲಿರುವುದು, ಅಂದರೆ, ಅನುಕೂಲ ಅಥವಾ ಪ್ರತಿಕೂಲ ಸ್ಥಿತಿ ಒದಗಿದಾಗ ಹರ್ಷ-ಶೋಕಾದಿ ವಿಕಾರಗಳು ಉಂಟಾಗದಿರುವುದು 13.9",

      "ಪರಮೇಶ್ವರನಾದ ನನ್ನಲ್ಲಿ ಅನನ್ಯಭಾವದಿಂದ ಸ್ಥಿರವಾದ ಧ್ಯಾನಯೋಗದ ಮೂಲಕ ಶುದ್ಧ ಭಕ್ತಿ ಮತ್ತು ಏಕಾಂತ ಹಾಗು ಶುದ್ಧ ಸ್ಥಳದಲ್ಲಿ ನೆಲೆಸುವ ಸ್ವಭಾವದ ಜೊತೆಗೆ ವಿಷಯಾಸಕ್ತರ ಸಂಗದಲ್ಲಿ ಪ್ರೀತಿಯಿಲ್ಲದಿರುವಿಕೆ 13.10",

      "ಅಧ್ಯಾತ್ಮಜ್ಞಾನದಲ್ಲಿ ಯಾವಾಗಲೂ ನಿಷ್ಠನಾಗಿರುವುದು ಮತ್ತು ತತ್ವ ಜ್ಞಾನದ ಅರ್ಥಸ್ವರೂಪೀ ಪರಮಾತ್ಮನನ್ನು ಎಲ್ಲೆಲ್ಲಿಯೂ ಕಾಣುವುದು; ಇದೆಲ್ಲವೂ ಜ್ಞಾನ. ಯಾವುದು ಇದಕ್ಕಿಂತ ಬೇರೆಯಾಗಿದೆಯೋ ಅದೇ ಅಜ್ಞಾನ ಎಂದು ಹೇಳಲಾಗಿದೆ. 13.11",

      "ಯಾವುದು ತಿಳಿದುಕೊಳ್ಳಲು ಯೋಗ್ಯವಾಗಿದೆಯೋ, ಯಾವುದನ್ನು ತಿಳಿದುಕೊಂಡ ನಂತರ ಮನುಷ್ಯನು ಪರಮಾನಂದವನ್ನು ಪಡೆಯುತ್ತಾನೆಯೋ ಅದನ್ನು ಚೆನ್ನಾಗಿ ಹೇಳುವೆನು. ಅದು ಅನಾದಿಯಾದ ಪರಮ - ಬ್ರಹ್ಮ ಅವರ್ಣನೀಯ, ಆದುದರಿಂದ ಸತ್ ಅಲ್ಲ, ಅಸತ್ ಅಲ್ಲ ಎಂದು ಹೇಳಲಾಗುತ್ತದೆ. 13.12",

      "ಅದು ಎಲ್ಲೆಲ್ಲಿಯೂ ಕೈ ಕಾಲುಗಳುಳ್ಳದ್ದು, ಎಲ್ಲೆಲ್ಲಿಯೂ ಕಣ್ಣು, ತಲೆ ಮತ್ತು ಮುಖಗಳುಳದ್ದು. ಅದು ಎಲ್ಲೆಲ್ಲಿಯೂ ಕಿವಿಗಳುಳ್ಳದ್ದು. ಏಕೆಂದರೆ ಅದು ಜಗತ್ತಿನಲ್ಲಿ ಎಲ್ಲವನ್ನೂ ವ್ಯಾಪಿಸಿಕೊಂಡು ಸ್ಥಿರಗೊಂಡಿದೆ. 13.13",

      "ಸಕಲ ಇಂದ್ರಿಯಗಳ ವಿಷಯಗಳನ್ನೂ ತಿಳಿದುಕೊಂಡಿರುವವನು, ಆದರೆ ವಾಸ್ತವವಾಗಿಯೂ ಎಲ್ಲಾ ಇಂದ್ರಿಯಗಳಿಂದ ಹೊರತಾದವನು ಅರ್ಥಾತ್ ಇಂದ್ರಿಯಾತೀತ ಮತ್ತು ಆಸಕ್ತಿಯಿಲ್ಲದವನಾದರೂ ಸಹ ತನ್ನ ಯೋಗ ಮಾಯೆಯಿಂದ ಎಲ್ಲರ ಪಾಲನೆ -ಪೋಷಣೆ ಮಾಡುವವನು ಮತ್ತು ಗುಣಾತೀತನಾದರೂ ಸಹ ಗುಣಗಳ ಫಲಾನುಭವಿಯಾಗಿದ್ದಾನೆ. 13.14",

      "ಆ ಪರಮಾತ್ಮನು ಎಲ್ಲಾ ಚರಾಚರ ಜೀವಿಗಳ ಬಾಹ್ಯ-ಅಂತರಂಗಗಳಲ್ಲಿ ಪರಿಪೂರ್ಣನಾಗಿದ್ದಾನೆ ಮತ್ತು ಚರ- ಅಚರ ರೂಪೀ ಕೂಡಾ ಅವನೇ. ಅವನು ಸೂಕ್ಷ್ಮವಾದುದರಿಂದ ಅರಿಯಲಸಾಧ್ಯನು. ಅತಿ ಸಮೀಪದಲ್ಲಿಯೂ ಮತ್ತು ದೂರದಲ್ಲಿಯೂ ಇರುವವನೂ ಸಹ ಆತನೇ. 13.15",

      "ಆ ಪರಮಾತ್ಮನು ವಿಭಾಗಗಳಿಲ್ಲದೆ ಏಕರೂಪದಿಂದ ಆಕಾಶದಂತೆ ಪರಿಪೂರ್ಣನಾದರೂ ಸಹ ಸಮಸ್ತ ಚರಾಚರ ಪ್ರಾಣಿಗಳಲ್ಲಿ ಬೇರೆಬೇರೆಯೋ ಎಂಬಂತೆ ಇರುವ ಹಾಗೆ ಕಂಡುಬರುತ್ತದೆ. ಆ ಅರಿಯಲು ಶ್ರೇಷ್ಠನಾದ ಪರಮಾತ್ಮನು ವಿಷ್ಣು ರೂಪದಿಂದ ಜೀವಿಗಳ ಧಾರಣೆ-ಪೋಷಣೆ ಮಾಡುವವನು, ರುದ್ರ ರೂಪದಿಂದ ಸಂಹಾರ ಮಾಡುವವನು ಮತ್ತು ಬ್ರಹ್ಮ ರೂಪದಿಂದ ಎಲ್ಲರನ್ನೂ ಉತ್ಪತ್ತಿ ಮಾಡುವವನೂ ಆಗಿದ್ದಾನೆ. 13.16",

      "ಆ ಪರಬ್ರಹ್ಮಜ್ಯೋತಿಗಳೆಲ್ಲಕ್ಕೂ ಸಹ ಜ್ಯೋತಿ, ಮಾಯೆಯಿಂದ ಅತಿದೂರದವನು ಎಂದು ಹೇಳಲಾಗುತ್ತದೆ. ಆ ಪರಮಾತ್ಮನು ಜ್ಞಾನ ಸ್ವರೂಪಿ,  ತಿಳಿದುಕೊಳ್ಳಲು ಯೋಗ್ಯನೂ, ತತ್ವಜ್ಞಾನದಿಂದ ದೊರೆಯಬಲ್ಲವನು ಮತ್ತು ಎಲ್ಲರ ಹೃದಯನಿವಾಸಿಯಾಗಿದ್ದಾನೆ. 13.17",

      "ಹೀಗೆ ಕ್ಷೇತ್ರ ಮತ್ತು ಜ್ಞಾನ ಹಾಗೂ ಅರಿತುಕೊಳ್ಳಲು ಯೋಗ್ಯವಾದ ಪರಮಾತ್ಮನ ಸ್ವರೂಪಗಳನ್ನು ಸಂಕ್ಷೇಪವಾಗಿ ಹೇಳಲಾಯಿತು. ಇದನ್ನು ಯಥಾರ್ಥವಾಗಿ ತಿಳಿದುಕೊಂಡ ನನ್ನ ಭಕ್ತನು ನನ್ನ ಸ್ವರೂಪವನ್ನು ಪಡೆಯುತ್ತಾನೆ. 13.18",

      "ಪ್ರಕೃತಿ ಅರ್ಥಾತ್ ತಿಗುಣಮಯೀ ನನ್ನ ಮಾಯೆ ಮತ್ತು ಜೀವಾತ್ಮಾ ಅರ್ಥಾತ್ ಕ್ಷೇತ್ರಜ್ಞ ಇವೆರಡನ್ನೂ ಸಹ ನೀನು ಅನಾದಿಗಳೆಂದು ತಿಳಿ. ರಾಗ-ದ್ವೇಷಾದಿ ವಿಕಾರಗಳನ್ನು ಹಾಗೂ ತ್ರಿಗುಣಾತ್ಮಕ ಪದಾರ್ಥಗಳೆಲ್ಲವೂ ಸಹ ಪ್ರಕೃತಿಯಿಂದಲೇ ಉತ್ಪತ್ತಿಯಾದವು ಎಂದು ತಿಳಿದುಕೊ. 13.19",

      "ಪ್ರಕೃತಿಯು ಕಾರ್ಯ ಮತ್ತು ಕರಣ ಅರ್ಥಾತ್ ಎಲ್ಲಾ ಇಂದ್ರಿಯಗಳ ಉತ್ಪತ್ತಿಗೆ ಮೂಲ ಕಾರಣವೆಂದು ಹೇಳಲಾಗುತ್ತದೆ ಮತ್ತು ಜೀವಾತ್ಮನು ಸುಖ - ದುಃಖಗಳನ್ನು ಅನುಭವಿಸುವುದಕ್ಕೆ ಕಾರಣನೆಂದು ಹೇಳಲಾಗುತ್ತದೆ. 13.20",

      "ಪ್ರಕೃತಿಯಲ್ಲಿದ್ದುಕೊಂಡೇ ಪುರುಷನು ಪ್ರಕೃತಿಯಿಂದ ಹುಟ್ಟಿದ ಎಲ್ಲಾ ತ್ರಿಗುಣಾತ್ಮಕ ವಸ್ತುಗಳನ್ನು ಅನುಭವಿಸುತ್ತಾನೆ ಮತ್ತು ಈ ಗುಣಗಳ ಸಂಗವೇ ಅರ್ಥಾತ್ ಅವುಗಳಲ್ಲಿನ ಆಸಕ್ತಿಯೇ ಈ ಜೀವಾತ್ಮನು ಒಳ್ಳೆಯ ಅಥವಾ ಕೆಟ್ಟ ಯೋನಿಗಳಲ್ಲಿ ಜನಿಸಲು ಕಾರಣವಾಗಿದೆ. 13.21",

      "ಪುರುಷನು ಅರ್ಥಾತ್ ಜೀವಾತ್ಮನು ಈ ಶರೀರದಲ್ಲಿ ಇದ್ದರೂ ಸಹ ಪರಮಾತ್ಮನಾಗಿದ್ದಾನೆ. ಕೇವಲ ಸಾಕ್ಷಿಯಾದುದರಿಂದ ಪ್ರೇಕ್ಷಕ ಮತ್ತು ಯಥಾರ್ಥವಾದ ಸಮ್ಮತಿಯನ್ನು ಕೊಡುವವನಾದ್ದರಿಂದ ಅನುಮೋದಕ. ಎಲ್ಲರ ಧಾರಣ- ಪಾಲನೆ ಮಾಡುವವನಾದ್ದರಿಂದ ಒಡೆಯ. ಜೀವರೂಪದಿಂದ ಫಲಾನುಭವಿ, ಬ್ರಹ್ಮಾದಿಗಳಿಗೂ ಸ್ವಾಮಿಯಾದ್ದರಿಂದ ಮಹೇಶ್ವರ ಮತ್ತು ಶುದ್ಧ ಸಚ್ಚಿದಾನಂದಘನನಾದ್ದರಿಂದ ಪರಮಾತ್ಮ ಎಂದು ಹೇಳಲಾಗಿದೆ. 13.22",

      "ಹೀಗೆ ಪುರುಷನನ್ನು ಮತ್ತು ಗುಣಗಳ ಸಹಿತ ಪ್ರಕೃತಿಯನ್ನು ಯಾರು ಯಥಾರ್ಥವಾಗಿ ತಿಳಿದುಕೊಳ್ಳುತ್ತಾನೆಯೋ, ಅವನು ಎಲ್ಲಾ ರೀತಿಯಿಂದ ನಡೆದುಕೊಳ್ಳುತ್ತಿದ್ದರೂ ಸಹ ಪುನಃ ಹುಟ್ಟುವುದಿಲ್ಲ ಅರ್ಥಾತ್ ಪುನರ್ಜನ್ಮ ಪಡೆಯುವುದಿಲ್ಲ. 13.23",

      "ಅದೆಷ್ಟೋ ಜನರು ಶುದ್ಧವಾದ ಸೂಕ್ಷ್ಮ ಬುದ್ಧಿಯಿಂದ ಧ್ಯಾನದ ಮೂಲಕ ಮನಸ್ಸಿನಲ್ಲಿಯೂ, ಬೇರೆ ಎಷ್ಟೋ ಜನರು ಜ್ಞಾನಯೋಗದ ಮೂಲಕವೂ ಮತ್ತು ಇನ್ನೆಷ್ಟೋ ಮಂದಿ ನಿಷ್ಕಾಮ ಕರ್ಮಯೋಗದ ಮೂಲಕವೂ ಆ ಪರಮ ಪುರುಷ ಪರಮಾತ್ಮನನ್ನು ನೋಡುತ್ತಾರೆ. 13.24",

      "ಆದರೆ ಇವರಿಗಿಂತ ಬೇರೆಯವರು ಅರ್ಥಾತ್ ಮಂದಬುದ್ಧಿಯುಳ್ಳವರು ಈ ರೀತಿ ತಿಳಿದುಕೊಳ್ಳದೆ ಇತರರಿಂದ ಅರ್ಥಾತ್ ತತ್ವಾರ್ಥವನ್ನು ತಿಳಿದವರಿಂದ ಕೇಳಿ ಅರಿತುಕೊಂಡು ಉಪಾಸನೆ ಮಾಡುತ್ತಾರೆ ಮತ್ತು ಆ ಶ್ರವಣ-ಪರಾಯಣರೂ ಸಹ ಮೃತ್ಯುರೂಪೀ ಸಂಸಾರ ಸಾಗರವನ್ನು ಸಂದೇಹವಿಲ್ಲದೆ ದಾಟಿ ಹೋಗುತ್ತಾರೆ. 13.25",

      "ಎಲೈ ಅರ್ಜುನ ! ಒಟ್ಟಾರೆ ಎಷ್ಟೊಂದು ಯಾವ-ಯಾವ ಅಚರ ಮತು ಚರ ವಸ್ತುಗಳು ಉತ್ಪತ್ತಿಯಾಗುತ್ತವೆಯೋ ಆ ಸಮಸ್ತವನ್ನೂ ಕ್ಷೇತ್ರ ಮತ್ತು ಕ್ಷೇತ್ರಜ್ಞರ ಸಂಯೋಗದಿಂದಲೇ ಉತ್ಪತ್ತಿಯಾದವುಗಳೆಂದು ತಿಳಿ. 13.26",

      "ಯಾರು ನಾಶಯುತವಾದ ಎಲ್ಲಾ ಚರಾಚರ ಪ್ರಾಣಿವಸ್ತುಗಳಲ್ಲಿಯೂ ಪರಮೇಶ್ವರನನ್ನು ನಾಶರಹಿತವಾದ ಮತ್ತು ಸಮಭಾವದಿಂದ ಇರುವಂತೆ ನೋಡುತ್ತಾನೆಯೋ ಅವನೇ ಯಥಾರ್ಥವಾಗಿ ತಿಳಿದು ನೋಡುತ್ತಾನೆ. 13.27",

      "ಏಕೆಂದರೆ, ಎಲ್ಲಾ ಕಡೆಗಳಲ್ಲಿಯೂ ಸಮಭಾವದಿಂದಿರುವ ಪರಮೇಶ್ವರನನ್ನು ಸಮಾನವಾಗಿ ನೋಡುತ್ತಾ ತನ್ನಿಂದಲೇ ತನ್ನನ್ನು ಹಾಳುಮಾಡಿಕೊಳ್ಳುವುದಿಲ್ಲ ಅದರಿಂದ ಅವನು ಪರಮ ಗತಿಯನ್ನು ಪಡೆಯುತ್ತಾನೆ. 13.28",

      "ಮತ್ತು ಯಾರು ಕರ್ಮಗಳೆಲ್ಲಾ ಎಲ್ಲಾ ಪ್ರಕಾರದಿಂದಲೂ ಪ್ರಕೃತಿಯಿಂದಲೇ ಮಾಡಲ್ಪಡುತ್ತವೆ ಎಂದು ನೋಡುತ್ತಾನೋ ಹಾಗೂ ಆತ್ಮನನ್ನು ಅರ್ಥಾತ್ ತನ್ನನ್ನು ಕರ್ತೃವಲ್ಲವೆಂದು ತಿಳಿದುಕೊಳ್ಳುತ್ತಾನೆಯೋ ಅವನೇ ಯಥಾರ್ಥವಾಗಿ ತಿಳಿದುಕೊಂಡವನು. 13.29",

      "ಜೀವಿಗಳ ಬೇರೆ ಬೇರೆ ಭಾವಗಳು ಏಕಮೇವ ಪರಮಾತ್ಮನ ಸಂಕಲ್ಪಗಳ ಆಧಾರದಲ್ಲಿರುವುದೆಂದು ಮತ್ತು ಆ ಪರಮಾತ್ಮನ ಸಂಕಲ್ಪದಿಂದಲೇ ಸಮಸ್ತ ಜೀವಿಗಳ ವಿಕಾಸವಾಗುವುದೆಂದು ಯಾವಾಗ ತಿಳಿದುಕೊಳ್ಳುವನೋ ಆಗ ಸಚ್ಚಿದಾನಂದಘನ ಬ್ರಹ್ಮವನ್ನು ಪಡೆಯುತ್ತಾನೆ. 13.30",

      "ಎಲೈ ಅರ್ಜುನ ! ಅನಾದಿಯಾಗಿರುವುದರಿಂದಲೂ, ಗುಣಾತೀತನಾಗಿರುವುದರಿಂದಲೂ ಈ ಅವಿನಾಶೀ ಪರಮಾತ್ಮನು ಶರೀರದಲ್ಲಿದ್ದರೂ ಸಹ ವಾಸ್ತವವಾಗಿ ಯಾವ ಕರ್ಮವನ್ನೂ ಮಾಡುವುದಿಲ್ಲ ಹಾಗೂ ಲಿಪ್ತನಾಗುವುದೂ ಇಲ್ಲ. 13.31",

      "ಯಾವ ರೀತಿ ಎಲ್ಲೆಲ್ಲಿಯೂ ವ್ಯಾಪಿಸಿದ್ದರೂ ಸಹ ಆಕಾಶವು ಸೂಕ್ಷವಾಗಿರುವ ಕಾರಣ ಲಿಪ್ತವಾಗುವುದಿಲ್ಲವೋ ಹಾಗೆಯೇ ಶರೀರದಲ್ಲಿ ಎಲ್ಲಾ ಕಡೆ ಇರುತ್ತಿದ್ದರೂ ಸಹ ಆತ್ಮನು ಗುಣಾತೀತನಾದ ಕಾರಣ ದೇಹದ ಗುಣಗಳಲ್ಲಿ ಲಿಪ್ತನಾಗುವುದಿಲ್ಲ. 13.32",

      "ಎಲೈ ಅರ್ಜುನ ! ಯಾವ ರೀತಿ ಒಬ್ಬನೇ ಆದ ಸೂರ್ಯನು ಈ ಇಡೀ ಬ್ರಹಾಂಡವನ್ನು ಪ್ರಕಾಶಗೊಳಿಸುತ್ತಾನೆಯೋ ಅದೇ ಪ್ರಕಾರ ಒಂದೇ ಆತ್ಮ(ಕ್ಷೇತ್ರಜ್ಞನು ) ಸಮಸ್ತ ಕ್ಷೇತ್ರ ( ಶರೀರ )ಗಳನ್ನು ಪ್ರಕಾಶಗೊಳಿಸುತ್ತಾನೆ. 13.33",

      "ಈ ರೀತಿ ಕ್ಷೇತ್ರ ಮತ್ತು ಕ್ಷೇತ್ರಜ್ಞರ ಭೇದವನ್ನು ಹಾಗೂ ಕಾರ್ಯಸಹಿತ ಪ್ರಕೃತಿಯಿಂದ ಮುಕ್ತರಾಗುವ ಉಪಾಯವನ್ನು ಯಾರು ಜ್ಞಾನದ ದೃಷ್ಟಿಯಿಂದ ಯಥಾರ್ಥವಾಗಿ ತಿಳಿದುಕೊಳ್ಳುತ್ತಾರೋ ಆ ಮಹಾತ್ಮರು ಪರಬ್ರಹ್ಮ ಪರಮಾತ್ಮನನ್ನು ಪಡೆಯುತ್ತಾರೆ. 13.34",

      "ಇತಿ ಶ್ರೀಮದ್ಭಗವದ್ಗೀತಾರೂಪೀ ಉಪನಿಷದ್,  ಬ್ರಹ್ಮವಿದ್ಯೆ ಹಾಗೂ ಯೋಗಶಾಸ್ತ್ರ ವಿಷಯವಾಗಿ ಶ್ರೀ ಕೃಷ್ಣ ಮತ್ತು ಅರ್ಜುನರ ಸಂವಾದದಲ್ಲಿ  ಕ್ಷೇತ್ರಕ್ಷೇತ್ರಜ್ಞವಿಭಾಗಯೋಗ ಎಂಬ ಹದಿಮೂರನೆಯ ಅಧ್ಯಾಯ ಸಂಪೂರ್ಣ."
    ],
    "adhyaya-14" : [
      "ಭಗವಂತನು ಹೇಳಿದನು - ಎಲೈ ಅರ್ಜುನ! ಯಾವುದನ್ನು ತಿಳಿದುಕೊಂಡ ಮುನಿಗಳೆಲ್ಲ ಈ ಸಂಸಾರದಿಂದ ಮುಕ್ತರಾಗಿ ಪರಮ ಶ್ರೇಷ್ಠ ಸಿದ್ಧಿಯನ್ನು ಪಡೆದುಕೊಂಡಿದ್ದಾರೆಯೋ ಅಂತಹ ಜ್ಞಾನಗಳಲ್ಲಿ ಅತ್ಯುತ್ತಮವಾದ ಪರಮಶ್ರೇಷ್ಠ ಜ್ಞಾನವನ್ನು ನಾನು ಪುನಃ ನಿನಗೋಸ್ಕರ ಹೇಳುವೆನು. 14.1",

      "ಈ ಜ್ಞಾನವನ್ನು ಆಶ್ರಯಿಸಿ ನನ್ನ ಸ್ವರೂಪವನ್ನು ಪಡೆದುಕೊಂಡವರು ಸೃಷ್ಟಿಯ ಆದಿಯಲ್ಲಿ ಪುನಃ ಹುಟ್ಟುವುದಿಲ್ಲ ಮತ್ತು ಪ್ರಳಯಕಾಲದಲ್ಲಿಯೂ ಸಹ ವ್ಯಥೆ ಪಡುವುದಿಲ್ಲ.  ಏಕೆಂದರೆ, ವಾಸುದೇವನಾದ ನನಗಿಂತ ಬೇರೆಯಾದ ಯಾವ ವಸ್ತುವೂ ಅವರ ದೃಷ್ಟಿಯಲ್ಲಿ ಇಲ್ಲವೇ ಇಲ್ಲ. 14.2",

      "ಹೇ ಅರ್ಜುನ, ನನ್ನ ಮಹತ್ ಬ್ರಹ್ಮರೂಪೀ ಪ್ರಕೃತಿ ಅರ್ಥಾತ್ ತ್ರಿಗುಣಮಯೀ ಮಾಯೆಯು ಎಲ್ಲ ಜೀವಿಗಳ ಯೋನಿಯಾಗಿದೆ.  ನಾನು ಆ ಯೋನಿಯಲ್ಲಿ ಚೇತನರೂಪೀ ಬೀಜವನ್ನು ಸ್ಥಾಪಿಸುತ್ತೇನೆ. ಆ ಜಡ ಮತ್ತು ಚೇತನಗಳ ಸಂಯೋಗದಿಂದ ಎಲ್ಲ ಜೀವಿಗಳ ಉತ್ಪತ್ತಿಯಾಗುತ್ತದೆ. 14.3",

      "ಅರ್ಜುನನೇ, ನಾನಾ ಪ್ರಕಾರದ ಯೋನಿಗಳಲ್ಲೆಲ್ಲಾ ಎಷ್ಟು ಶರೀರಗಳು ಉತ್ಪತ್ತಿಯಾಗುತ್ತವೆಯೋ ಅವುಗಳಿಗೆ ತ್ರಿಗುಣಮಯೀ ಮಾಯೆಯು ಗರ್ಭಧರಿಸುವ ತಾಯಿ‌.  ನಾನು ಬೀಜ ಸ್ಥಾಪಿಸುವ ತಂದೆ. 14.4",

      "ಎಲೈ ಅರ್ಜುನ!  ಸತ್ವಗುಣ, ರಜೋಗುಣ, ಮತ್ತು ತಮೋಗುಣ ಎಂಬ ಪ್ರಕೃತಿಯಿಂದ ಉತ್ಪನ್ನವಾದ ಮೂರು ಗುಣಗಳು ಅವಿನಾಶಿಯಾದ ಜೀವಾತ್ಮನನ್ನು ಶರೀರದಲ್ಲಿ ಬಂಧಿಸುತ್ತವೆ. 14.5",

      "ಎಲೈ  ಪಾಪರಹಿತನೇ!  ಅ ಮೂರು ಗುಣಗಳಲ್ಲಿ ಸತ್ವಗುಣವು ಪ್ರಕಾಶಪಡಿಸಬಲ್ಲ ನಿರ್ವಿಕಾರೀ ನಿರ್ಮಲವಾದ ಕಾರಣ ಸುಖದ ಆಸಕ್ತಿಯಿಂದ ಮತ್ತು ಜ್ಞಾನದ ಆಸಕ್ತಿಯಿಂದ ಅರ್ಥಾತ್ ಜ್ಞಾನದ ಅಭಿಮಾನದಿಂದ ಆತ್ಮನನ್ನು ಬಂಧಿಸುತ್ತದೆ. 14.6",

      "ಹೇ ಅರ್ಜುನ!  ರಾಗರೂಪೀ ರಜೋಗುಣವು ಆಸೆ-ಆಕಾಂಕ್ಷೆ ಮತ್ತು ಆಸಕ್ತಿಯಿಂದ ಉತ್ಪತ್ತಿಯಾಗುವುದು ಎಂದು ತಿಳಿ.  ಅದು ಜೀವಾತ್ಮನನ್ನು ಕರ್ಮಗಳ ಮತ್ತು ಅವುಗಳ ಫಲದ ಆಸಕ್ತಿಯಿಂದ ಬಂಧಿಸುತ್ತದೆ. 14.7",

      "ಎಲೈ ಅರ್ಜುನ!  ದೇಹಾಭಿಮಾನಿಗಳನ್ನೆಲ್ಲಾ ಮೋಹಗೊಳಿಸುವ ತಮೋಗುಣವು ಅಜ್ಞಾನದಿಂದ ಉತ್ಪತ್ತಿಯಾಗುವುದೆಂದು ತಿಳಿ.  ಅದು ಜೀವಾತ್ಮನನ್ನು ಪ್ರಮಾದ, ಆಲಸ್ಯ ಮತ್ತು ನಿದ್ರೆಗಳ ಮೂಲಕ ಬಂಧಿಸುತ್ತದೆ. 14.8",

      "ಹೇ ಅರ್ಜುನ, ಸತ್ತ್ವಗುಣವು ಸುಖದಲ್ಲಿ ಪ್ರೇರಣೆಯನ್ನುಂಟುಮಾಡುತ್ತದೆ.  ರಜೋಗುಣವು ಕರ್ಮದಲ್ಲಿಯೂ, ಹಾಗು ತಮೋಗುಣವು ಜ್ಞಾನವನ್ನು ಆವರಿಸಿಕೊಂಡು ಪ್ರಮಾದದಲ್ಲಿ ಪ್ರೇರಣೆಯನ್ನು ಉಂಟುಮಾಡುತ್ತವೆ. 14.9",

      "ಎಲೈ ಅರ್ಜುನ!  ರಜೋಗುಣವನ್ನು ಮತ್ತು ತಮೋಗುಣವನ್ನು ಬದಿಗೊತ್ತಿ ಸತ್ತ್ವಗುಣವೂ, ಹಾಗೂ ಸತ್ತ್ವಗುಣವನ್ನು ಮತ್ತು ತಮೋಗುಣವನ್ನು ಬದಿಗೊತ್ತಿ ರಜೋಗುಣವೂ, ಅದರಂತೆಯೇ ಸತ್ತ್ವಗುಣವನ್ನು ಮತ್ತು ರಜೋಗುಣವನ್ನು ಬದಿಗೊತ್ತಿ ತಮೋಗುಣವೂ ಉಂಟಾಗುತ್ತದೆ ಅರ್ಥಾತ್ ಪ್ರಬಲವಾಗುತ್ತದೆ. 14.10",

      "ಈ ಶರೀರದಲ್ಲಿ, ಅಂತಃಕರಣ ಮತ್ತು ಇಂದ್ರಿಯಗಳಲ್ಲಿ ಚೈತನ್ಯ ಮತ್ತು ವಿವೇಕ ಉತ್ಪನ್ನವಾದಾಗ ಸತ್ತ್ವಗುಣ ವೃದ್ಧಿಯಾಗಿದೆ ಎಂದು ತಿಳಿಯಬೇಕು. 14.11",

      "ಎಲೈ ಅರ್ಜುನ!  ರಜೋಗುಣ ವೃದ್ಧಿಯಾದಾಗ ಲೋಭ, ಸಾಂಸಾರಿಕ ಪ್ರಯತ್ನಗಳನ್ನು ಸ್ವಾರ್ಥಬುದ್ಧಿಯಿಂದ ಹಾಗೂ ಎಲ್ಲಾ ಬಗೆಯ ಕರ್ಮಗಳನ್ನು ಸಕಾಮಭಾವದಿಂದ ನಡೆಸುವುದು, ಮನಸ್ಸಿನ ಚಂಚಲತೆ ಮತ್ತು ವಿಷಯ ಭೋಗಗಳ ದುರಾಸೆ ಇತ್ಯಾದಿಗಳೆಲ್ಲಾ ಉತ್ಪತ್ತಿಯಾಗುತ್ತವೆ. 14.12",

      "ಹೇ ಅರ್ಜುನ!  ತಮೋಗುಣ ವೃದ್ಧಿಯಾದಾಗ ಅಂತಃಕರಣ ಮತ್ತು ಇಂದ್ರಿಯಗಳಲ್ಲಿ ಅಜ್ಞಾನ, ಕರ್ತವ್ಯ ಕರ್ಮಗಳಲ್ಲಿ ಸೋಮಾರಿತನ, ಮತ್ತು ವ್ಯರ್ಥ ಪ್ರಯತ್ನ, ಹಾಗು ನಿದ್ರಾದಿ ಅಂತಃಕರಣದ ಮೋಹಿನೀ ಸ್ವಭಾವ ಇತ್ಯಾದಿಗಳೆಲ್ಲಾ ಉತ್ಪತ್ತಿಯಾಗುತ್ತವೆ. 14.13",

      "ಈ ಜೀವನು ಸತ್ತ್ವಗುಣಗಳ ವೃದ್ಧಿಕಾಲದಲ್ಲಿ ಮರಣ ಹೊಂದಿದರೆ, ಆಗ ಉತ್ತಮ ಕರ್ಮಾಚರಣೆಯವರ ದಿವ್ಯ ಸ್ವರ್ಗಾದಿ ಲೋಕಗಳನ್ನು ಪಡೆಯುತ್ತಾನೆ. 14.14",

      "ರಜೋಗುಣದ ವೃದ್ಧಿಯ ಸಮಯದಲ್ಲಿ ಮೃತ್ಯುವನ್ನು ಹೊಂದಿದರೆ ಕರ್ಮಗಳಲ್ಲಿ ಆಸಕ್ತರಾದ ಮನುಷ್ಯರಲ್ಲಿ ಜನಿಸುತ್ತಾನೆ ಹಾಗೂ ತಮೋಗುಣ ವೃದ್ಧಿಯ ಸಮಯದಲ್ಲಿ ಮರಣ ಹೊಂದಿದವನು ಕೀಟ ಪಶುಗಳಾದಿ ಮೂಢ ಯೋನಿಗಳಲ್ಲಿ ಹುಟ್ಟುತ್ತಾನೆ. 14.15",

      "ಶ್ರೇಷ್ಠಕರ್ಮದ ಫಲ ಸಾತ್ವಿಕ ಅರ್ಥಾತ್ ಸುಖ, ಜ್ಞಾನ ಮತ್ತು ನರವ್ಯಾಧಿ ನಿರ್ಮಲ ಫಲವೆಂದೂ, ರಾಜಸ ಕರ್ಮದ ಫಲ ದುಃಖವೆಂದೂ ಮತ್ತು ತಾಮಸ ಕರ್ಮದ ಫಲ ಅಜ್ಞಾನವೆಂದೂ ಹೇಳಲ್ಪಟ್ಟಿದೆ. 14.16",

      "ಸತ್ತ್ವ ಗುಣದಿಂದ ಜ್ಞಾನ ಉಂಟಾಗುತ್ತದೆ‌.  ರಜೋಗುಣದಿಂದ ನಿಸ್ಸಂದೇಹವಾಗಿ ಲೋಭವೂ, ತಮೋಗುಣದಿಂದ ಪ್ರಮಾದ ಮತ್ತು ಮೋಹಗಳು ಹಾಗೂ ಅಜ್ಞಾನವೂ ಸಹ ಉತ್ಪತ್ತಿಯಾಗುತ್ತವೆ. 14.17",

      "ಸತ್ತ್ವಗುಣವುಳ್ಳ ಸತ್ಪುರುಷರು ಸ್ವರ್ಗಾದಿ ಉಚ್ಚಲೋಕಗಳಿಗೆ ಹೋಗುತ್ತಾರೆ.  ರಜೋಗುಣವುಳ್ಳ ರಾಜಸ ಪುರುಷರು ಮಧ್ಯದಲ್ಲಿ, ಅರ್ಥಾತ್ ಮಾನವ ಲೋಕದಲ್ಲಿಯೇ, ಇರುತ್ತಾರೆ.  ತಮೋಗುಣದ ಕಾರ್ಯರೂಪೀ ನಿದ್ರೆ, ಪ್ರಮಾದ ಮತ್ತು ಆಲಸ್ಯಾದಿ ಗುಣಗಳುಳ್ಳ ತಾಮಸ ಪುರುಷರು ಅಧೋಗತಿಯನ್ನು - ಅಂದರೆ ಕೀಟ ಪಶುಗಳಾದಿ ನೀಚ ಯೋನಿಗಳಗೆ ಮತ್ತು  ನರಕಕ್ಕೆ - ಹೋಗುತ್ತಾರೆ. 14.18",

      "ಯಾವಾಗ ದ್ರಷ್ಟಾ, ಅಂದರೆ ಪ್ರೇಕ್ಷಕನು, ಮೂರು ಗುಣಗಳ ಹೊರತು ಬೇರೆ ಯಾವುದನ್ನೂ ಕರ್ತವ್ಯ ಎಂಬುದಾಗಿ ನೋಡುವುದಿಲ್ಲವೋ, ಅಂದರೆ ಗುಣಗಳೇ  ಗುಣಗಳಲ್ಲಿ ಪ್ರವರ್ತಿಸುತ್ತವೆ ಎಂದು ನೋಡುತ್ತಾನೆಯೋ, ಮತ್ತು ಮೂರು ಗುಣಗಳಿಗಿಂತ ಶ್ರೇಷ್ಠವಾದ ಸಚ್ಚಿದಾನಂದ ಘನ ಸ್ವರೂಪೀ ಪರಮೇಶ್ವರನಸದ ನನನ್ನು ಯಥಾರ್ಥವಾಗಿ ತಿಳಿದುಕೊಳ್ಳುತ್ತಾನೆಯೋ ಆಗ ಅವನು ನನ್ನ ಸ್ವರೂಪವನ್ನು ಪಡೆಯುತ್ತಾನೆ. 14.19",

      "ಈ ಪುರುಷನು ಶರೀರದ ಉತ್ಪತ್ತಿಗೆ ಕಾರಣ ರೂಪಿಯಾದ ಈ ಮೂರು ಗುಣಗಳನ್ನು ದಾಟಿ ಜನನ-ಮರಣ, ಮುಪ್ಪು ಹಾಗೂ ಸಕಲ ದುಃಖಗಳಿಂದ ಮುಕ್ತನಾಗಿ ಪರಮಾನಂದಾಮೃತವನ್ನು ಪಡೆಯುತ್ತಾನೆ. 14.20",

      "ಅರ್ಜುನನು ಕೇಳುತ್ತಾನೆ: ಈ ಮೂರು ಗುಣಗಳಿಂದ ಪಾರಾದವನು ಯಾವ ಯಾವ ಲಕ್ಷಣಗಳಿಂದ ಕೂಡಿರುತ್ತಾನೆ ಮತ್ತು ಯಾವ ಬಗೆಯ ಆಚರಣೆಯುಳ್ಳವನಾಗಿರುತ್ತಾನೆ?  ಹೇ ಪ್ರಭುವೇ, ಮನುಷ್ಯನು ಯಾವ ಉಪಾಯದಿಂದ ಈ ಮೂರು ಗುಣಗಳಿಂದ ವಿರಕ್ತನಾಗುತ್ತಾನೆ? 14.21",

      "ಶ್ರೀಕೃಷ್ಣನು ಹೇಳಿದನು:  ಎಲೈ ಅರ್ಜುನ, ಸತ್ತ್ವಗುಣಗಳ ಕಾರ್ಯರೂಪೀ ಪ್ರಕಾಶವು ಮತ್ತು ರಜೋಗುಣಗಳ ಕಾರ್ಯರೂಪೀ ಪ್ರವೃತ್ತಿಯು ಹಾಗೂ ತಮೋಗುಣಗಳ ಕಾರ್ಯರೂಪೀ ಮೋಹವು ಒದಗಿಬಂದರೆ ಯಾರು ಕೆಟ್ಟದೆಂದು ತಿಳಿಯುವುದಿಲ್ಲವೋ ಮತ್ತು ನಿವೃತ್ತಿಯಾದರೆ ಅವುಗಳನ್ನು ಅಪೇಕ್ಷೆ ಪಡುವುದಿಲ್ಲವೋ. 14.22",

      "ಯಾರು ಸಾಕ್ಷಿ ಸದೃಶನಾಗಿರುತ್ತಾ ಗುಣಗಳಿಂದ ವಿಚಲಿತನಾಗಲಾರನೋ, ಗುಣವೇ ಗುಣಗಳಲ್ಲಿ ಪ್ರವರ್ತಿಸುತ್ತವೆ ಎಂಬುದಾಗಿ ತಿಳಿಯುತ್ತಾ ಯಾರು ಸಚ್ಚಿದಾನಂದ ಘನ ಪರಮಾತ್ಮನಲ್ಲಿ ಏಕ ಭಾವದಿಂದ ಇರುತ್ತಾನೆಯೋ, ಆ ಸ್ಥಿತಿಯಿಂದ ವಿಚಲಿತನಾಗುವುದಿಲ್ಲವೋ. 14.23",

      "ನಿರಂತರ ಆತ್ಮ ಭಾವದಲ್ಲಿರುತ್ತಾ ಸುಖ-ದುಃಖಗಳನ್ನು ಸಮಾನವಾಗಿ ತಿಳಿದುಕೊಳ್ಳುತ್ತಾನೆಯೋ, ಕಲ್ಲು ಮಣ್ಣು ಮತ್ತು ಬಂಗಾರಗಳಲ್ಲಿ ಸಮಾನವಾದ ಭಾವವುಳ್ಳವನೋ, ಜ್ಞಾನಿಯೋ, ಪ್ರಿಯ-ಅಪ್ರಿಯಗಳನ್ನು ಸರಿಸಮಾನವಾಗಿ ತಿಳಿದುಕೊಳ್ಳುತ್ತಾನೆಯೋ, ಮತ್ತು ತನ್ನ ಸ್ತುತಿ-ನಿಂದೆಗಳಲ್ಲಯೂ ಸಹ ಸಮಾನವಾದ ಭಾವ ಉಳ್ಳವನೋ. 14.24",

      "ಮಾನ ಮತ್ತು ಅಪಮಾನಗಳಲ್ಲಿ ಸಮನಾಗಿರುತ್ತಾನೆಯೋ, ಮಿತ್ರ ಮತ್ತು ಶತ್ರು ಪಕ್ಷಗಳಲ್ಲಿಯೂ ಸಮವಾಗಿರುತ್ತಾನೆಯೋ ಮತ್ತು ಸಮಸ್ತ ಕಾರ್ಯ-ಕಲಾಪಗಳಲ್ಲಿ ಕರ್ತೃವೆಂಬ ಅಭಿಮಾನವಿಲ್ಲದೆ ಇರುತ್ತಾನೆಯೋ ಅವನು ಗುಣಾತೀತನೆಂದು ಹೇಳಲ್ಪಡುತ್ತಾನೆ. 14.25",

      "ಯಾರು ಅನನ್ಯ ಪ್ರೇಮಭಾವ ಭಕ್ತಿರೂಪೀ ಯೋಗದ ಮೂಲಕ ನನ್ನನ್ನು ನಿರಂತರ ಧ್ಯಾನ ಮಾಡುತ್ತಾನೆಯೋ, ಅರ್ಥಾತ್ ಸೇವೆಮಾಡುತ್ತಾನೆಯೋ, ಅವನು ಈ ಮೂರು ಗುಣಗಳನ್ನು ಉತ್ತಮವಾಗಿದ್ದಾಗಿ ದಾಟಿ ಸಚ್ಚಿದಾನಂದ ಘನ ಬ್ರಹ್ಮನಲ್ಲಿ ಭಾವೈಕ್ಯನಾಗಲು ಯೋಗ್ಯನಾಗುತ್ತಾನೆ. 14.26",

      "ಏಕೆಂದರೆ ಅವಿನಾಶೀ ಪರಬ್ರಹ್ಮನಿಗೂ ಮತ್ತು ಅಮೃತಕ್ಕೂ, ಶಾಶ್ವತವಾದ ಧರ್ಮಕ್ಕೂ ಮತ್ತು ಅಖಂಡ ಏಕೈಕ ಭಾವದ ಆನಂದಕ್ಕೂ ನಾನೇ ಆಶ್ರಯನಾಗಿದ್ದೇನೆ 14.27",

      "ಇತಿ ಶ್ರೀಮದ್ಭಗವದ್ಗೀತಾರೂಪೀ ಉಪನಿಷದ್, ಬ್ರಹ್ಮವಿದ್ಯೆ, ಹಾಗೂ ಯೋಗಶಾಸ್ತ್ರ ವಿಷಯವಾಗಿ ಶ್ರೀ ಕೃಷ್ಣ ಮತ್ತು ಅರ್ಜುನರ ಸಂವಾದದಲ್ಲಿ ಗುಣತ್ರಯವಿಭಾಗಯೋಗ ಎಂಬ ಹದಿನಾಲ್ಕನೆಯ ಅಧ್ಯಾಯ ಸಂಪೂರ್ಣ."
    ],
    "adhyaya-15" : [
      "ಶ್ರೀ ಭಗವಂತನು ಹೇಳಿದನು-ಆದಿಪುರುಷ ಪರಮೇಶ್ವರನು ಬೇರು ಆಗಿರುವ,ಬ್ರಹ್ಮದೇವರೇಮುಖ್ಯಕಾಂಡವಾಗಿರುವಜಗದ್ರೂಪಿಅಶ್ವತ್ಥವೃಕ್ಷವನ್ನುಅವಿನಾಶೀ ಎಂದುಹೇಳುತ್ತಾರೆ. ಹಾಗೆಯೇ ವೇದಗಳುಅದರ ಎಲೆಗಳೆಂದು ಹೇಳಲಾಗಿದೆ.ಆ ಜಗದ್ರೂಪಿಅಶ್ವತ್ಥವೃಕ್ಷವನ್ನುತತ್ತ್ವತಃ ತಿಳಿದವನುವೇದಗಳ ತಾತ್ಪರ್ಯವನ್ನು ತಿಳಿದವನಾಗಿದ್ದಾನೆ. 15.1",

      "ಆ ಜಗತ್‌ ವೃಕ್ಷಕ್ಕೆ- ತ್ರಿಗುಣರೂಪೀ ನೀರಿನಿಂದ ಬೆಳೆದಿರುವವಿಷಯ ಭೋಗರೂಪೀ ಚಿಗುರುಗಳುಳ್ಳದೇವ,ಮನುಷ್ಯ ಮತ್ತು ತಿರ್ಯಕ್‌ ಮೊದಲಾದ ಯೋನಿರೂಪವಾದ ರೆಂಬೆಗಳುಕೆಳಗೆ ಮೇಲೆ ಎಲ್ಲೆಲ್ಲಿಯೂ ಹರಡಿಕೊಂಡಿವೆ ಹಾಗೂ ಮನುಷ್ಯ ಜನ್ಮದಲ್ಲಿ ಕರ್ಮಗಳಿಗನುಸಾರಬಂಧೀಸುವಂತಹ ಅಹಂತೆ,ಮಮತೆ,ಮತ್ತು ವಾಸನಾರೂಪೀ ಬಿಳಿಲುಗಳೂ ಮೇಲೆ, ಕೆಳಗೆ ಎಲ್ಲ ಲೋಕಗಳಲ್ಲಿ ಹರಡಿಕೊಂಡಿವೆ. 15.2",

      "ಈ ಪ್ರಪಂಚ ವೃಕ್ಷದ ಸ್ವರೂಪವು ಹೇಗೆ ಹೇಳಿದೆಯೋ ಹಾಬೆ ಇಲ್ಲಿ ವಿಚಾರ ಕಾಲದಲ್ಲಿ ಕಂಡುಬರುವುದಿಲ್ಲ. ಏಕೆಂದರೆ ಇದಕ್ಕೆ ಆದಿಯಿಲ್ಲ, ಅಂತ್ಯವೂ ಇಲ್ಲ ಮತ್ತು ಸ್ಥಿತಿಯೂ ಇಲ್ಲ. ಪ್ರಯುಕ್ತ ಈ ಅಹಂಕಾರ, ಮಮಕಾರ ಮತ್ತು ವಾಸನಾರೂಪೀ ಅತಿಬಲವಾದ ಬುಡಗಳುಳ್ಳ ಪ್ರಪಂಚರೂಪೀ ಅಶ್ವತ್ಥವೃಕ್ಷವನ್ನು ದೃಢವಾದ ವೈರಾಗ್ಯರೂಪೀ ಆಯುಧದಿಂದ ಕತ್ತರಿಸಿ 15.3",

      "ಅನಂತರ ಯಾವ ಸ್ಥಳವನ್ನು ಸೇರಿದವರು ಪುನಃ ಹಿಂದಿರುಗಿ ಈ ಪ್ರಪಂಚಕ್ಕೆ ಬರುವುದಿಲ್ಲವೋ ಮತ್ತು ಯಾವ ಪರಮೇಶ್ವರನಿಂದ ಈ ಪುರಾತನ ಪ್ರಪಂಚರೂಪೀ ವೃಕ್ಷದ ಪ್ರವೃತ್ತಿಯು ವಿಸ್ತಾರಗೊಂಡಿದೆಯೋ ಆ ಪರಮಪದರೂಪೀ ಪರಮೇಶ್ವರನನ್ನು ಚೆನ್ನಾಗಿ ಹುಡುಕಬೇಕು. ಅದೇ ಆದಿಪುರುಷ ನಾರಾಯಣನಿಗೆ ನಾನು ಶರಣಾಗತನಾದಿದ್ದೇನೆ - ಎಂದು ಈ ಪ್ರಕಾರ ದೃಢ ನಿಶ್ಚಯ ಮಾಡಿಕೊಂಡು ಆ ಪರಮಾತ್ಮನ ಚಿಂತನ ಮತ್ತು ಧ್ಯಾನ ಮಾಡಬೇಕು. 15.4",

      "ಅಹಂಕಾರ, ಮೋಹಾದಿಗಳಿಲ್ಲದವರೂ, ಆಸಕ್ತಿರೂಪೀ ದೋಷವನ್ನು ಜಯಿಸಿದವರೂ, ಪರಮಾತ್ಮನ ಸ್ವರೂಪದಲ್ಲಿ ನಿತ್ಯಲೀನರಾದವರೂ, ತನ್ನಿಚ್ಛೆಗಳನ್ನೆಲ್ಲಾ ಸಂಪೂರ್ಣವಾಗಿ ತ್ಯಜಿಸಿಬಿಟ್ಟಿರುವವರೂ ಹಾಗೂ ಸುಖ ದುಃಖಗಳೆಂಬ ದ್ವಂದ್ವಗಳಿಂದ ವಿಮುಕ್ತರಾದ ಅರ್ಥಾತ್ ಬಿಡುಗಡೆ ಪಡೆದ ಜ್ಞಾನಿಗಳು ಆ ಅವಿನಾಶೀ ಪರಮ ಪದವನ್ನು ಪಡೆದುಕೊಳ್ಳುತ್ತಾರೆ. 15.5",

      "ಆ ಸ್ವಯಂ ಪ್ರಕಾಶಮಯ ಪರಮ ಪದವನ್ನು ಸೂರ್ಯನಾಗಲೀ, ಚಂದ್ರನಾಗಲೀ ಮತ್ತು ಅಗ್ನಿಯಾಗಲೀ ಪ್ರಕಾಶಗೊಳಿಸಲಾರರು. ಅಂತಹ ಯಾವ ಧಾಮವನ್ನು ಪಡೆದುಕೊಂಡವನು ಹಿಂದಿರುಗಿ ಈ ಪ್ರಪಂಚಕೆ ಬರುವುದಿಲ್ಲವೋ ಅರ್ಥಾತ್ ಪುನರ್ಜನ್ಮ ಪಡೆಯುವುದಿಲ್ಲವೋ ಅದೇ ನನ್ನ ಪರಮಧಾಮ. 15.6",

      "ಈ ದೇಹದಲ್ಲಿನ ಈ ಜೀವಾತ್ಮನು ನನ್ನದೇ ಸನಾತನ ಅಂಶವಾಗಿದೆ ಮತ್ತು ಅದೇ ಈ ತ್ರಿಗುಣಮಯೀ ಮಾಯೆಯಲ್ಲಿರುವ ಮನಸ್ಸಹಿತವಾಗಿ ಐದು ಇಂದ್ರಿಯಗಳನ್ನು ಆಕರ್ಷಿಸುತ್ತದೆ. 15.7",

      "ಗಾಳಿಯು ಗಂಧವಿರುವ ಸ್ಥಾನದಿಂದ ಗಂಧವನ್ನು ಹೇಗೆ ಗ್ರಹಿಸಿಕೊಂಡು ಹೋಗುತ್ತದೆಯೋ ಹಾಗೆಯೇ ದೇಹಾದಿಗಳ ಸ್ವಾಮಿಯಾದ ಜೀವಾತ್ಮನೂ ಸಹ ಮೊದಲಿನ ಶರೀರವನ್ನು ತ್ಯಜಿಸುತ್ತಾನೆ;   ಅದರಿಂದ ಮನಸ್ಸಹಿತ ಈ ಇಂದ್ರಿಯಗಲನ್ನೂ ಗ್ರಹಿಸಿಕೊಂಡು ಪುನಃ ಯಾವ ಶರೀರವನ್ನು ಪಡೆಯುತ್ತಾನೋ ಅದರಲ್ಲಿ ಪ್ರವೇಶಿಸುತ್ತಾನೆ. 15.8",

      "ಈ ಜೀವಾತ್ಮನು ಶ್ರೋತ್ರೇಂದ್ರಿಯ ( ಕಿವಿ ) ಚಕ್ಷುರಿಂದ್ರಿಯ ( ಕಣ್ಣು ) ಮತ್ತು ತ್ವಗಿಂದ್ರಿಯ ( ಚರ್ಮ ) , ರಸನೇಂದ್ರಿಯ ( ನಾಲಿಗೆ ), ಘ್ರಾಣೇಂದ್ರಿಯ ( ಮೂಗು ) ಹಾಗೂ ಮನಸ್ಸನ್ನು ಆಶ್ರಯಿಸಿಕೊಂಡು ಅರ್ಥಾತ್ ಇವುಗಳ ಸಹಾಯದಿಂದ ವಿಷಯಗಳನ್ನು ಅನುಭವಿಸುತ್ತಾನೆ. 15.9",

      "ಶರೀರವನ್ನು ಬಿಟ್ಟುಹೋಗುವುದನ್ನು, ಶರೀರದಲ್ಲಿರುವುದನ್ನು, ವಿಷಯಗಳನ್ನು ಅನುಭವಿಸುವುದನ್ನು ಅಥವಾ ಮೂರು ಗುಣಗಳಿಂದ ಕೂಡಿರುವುದನ್ನೂ ಸಹ ಅಜ್ಞಾನಿಗಳು ಅರಿತುಕೊಳ್ಳಲಾರರು ; ಕೇವಲ ಜ್ಞಾನರೂಪೀ ಕಣ್ಣುಗಳುಳ್ಳವರು ಅಂದರೆ ಜ್ಞಾನಿಗಳು ಮಾತ್ರ ಯಥಾರ್ಥವಾಗಿ ತಿಳಿಯುತ್ತಾರೆ. 15.10",

      "ಯೋಗಿಗಳು ಸಹ ತಮ್ಮ ಹೃದಯದಲ್ಲಿರುವ ಈ ಆತ್ಮನನ್ನು ಪ್ರಯತ್ನಶೀಲರಾಗಿಯೇ ಯಥಾರ್ಥವಾಗಿ ತಿಳಿದುಕೊಳ್ಳುತ್ತಾರೆ. ಆದರೆ ತಮ್ಮ ಅಂತಃಕರಣವನ್ನು ಶುದ್ಧವಾಗಿಟ್ಟುಕೊಂಡಿಲ್ಲದ ಅಜ್ಞಾನಿಗಳು ಪ್ರಯತ್ನಶೀಲರಾಗಿದ್ದರೂ ಸಹ ಈ ಆತ್ಮವನ್ನು ತಿಳಿದುಕೊಳ್ಳಲಾರರು. 15.11",

      "ಸೂರ್ಯನಲ್ಲಿಯೂ, ಚಂದ್ರನಲ್ಲಿಯೂ ಮತ್ತು ಅಗ್ನಿಯಲ್ಲಿಯೂ ಇರುವ ಇಡೀ ಜಗತ್ತನ್ನೇ ಪ್ರಕಾಶಪಡಿಸುವಂತಹ ತೇಜಸ್ಸನ್ನು ನನ್ನದೇ ಆದ ತೇಜಸ್ಸೆಂದು ತಿಳಿ. 15.12",

      "ಮತ್ತು ನಾನೇ ಭೂಮಿಯಲ್ಲಿ ಪ್ರವೇಶಿಸಿ ನನ್ನ ಶಕ್ತಿಯಿಂದ ಎಲ್ಲಾ ಜೀವಿಗಳನ್ನೂ ಧರಿಸುತ್ತೇನೆ ಮತ್ತು ರಸ ಸ್ವರೂಪೀ ಅರ್ಥಾತ್ ಅಮೃತಮಯನಾದ ಚಂದ್ರನಾಗಿ ಸಕಲ ವನಸ್ಪತಿಗಳನ್ನೆಲ್ಲಾ ಪುಷ್ಟಿಗೊಳಿಸುತ್ತೇನೆ. 15.13",

      "ನಾನು ಎಲ್ಲಾ ಪ್ರಾಣಿಗಳ ಶರೀರದಲ್ಲಿ ಇದ್ದುಕೊಂಡು ವೈಶ್ವಾನರ ಅಗ್ನಿರೂಪಿಯಾಗಿದ್ದು ಪ್ರಾಣ ಮತ್ತು ಅಪಾನಗಳಿಂದ ಕೂಡಿದವನಾಗಿ ನಾಲ್ಕು ವಿಧದ ಅನ್ನವನ್ನು ಜೀರ್ಣವಾಗುವಂತೆ ಮಾಡುತ್ತೇನೆ. 15.14",

      "ನಾನು ಎಲ್ಲಾ ಜೀವಿಗಳ ಹೃದಯದಲ್ಲಿ ಅಂತರ್ಯಾಮೀರೂಪದಿಂದ ಇದ್ದೇನೆ. ನನ್ನಿಂದಲೇ ಸ್ಮೃತಿ, ಜ್ಞಾನ ಮತ್ತು ಅಪೋಹನ ( ಸಂಶಯ, ವಿಪರ್ಯಾಸಾದಿ ದೋಷಗಳ ನಿವಾರಣೆ ) ನನ್ನಿಂದ ಉಂಟಾಗುತ್ತವೆ. ವೇದೆಗಳ ಮೂಲಕ ತಿಳಿದುಕೊಳ್ಳಬೇಕಾದ ಶ್ರೇಷ್ಠನೂ ನಾನೇ ; ವೇದಾಂತದ ರಚನಾಕಾರ ಮತ್ತು ವೇದಗಳನ್ನು ತಿಳಿದವನೂ ಸಹ ನಾನೇ. 15.15",

      "ಈ ಜಗತ್ತಿನಲ್ಲಿ ವಿನಾಶೀ ಮತ್ತು ಅವಿನಾಶೀ ಎಂದು ಎರಡು ಪ್ರಕಾರದ ಪುರುಷರಿದ್ದಾರೆ. ಅವರಲ್ಲಿ ಸಮಸ್ತ ಜೀವಿಗಳ ಶರೀರವು ನಾಶವಾಗುವಂತಹದು ಮತ್ತು ಜೀವಾತ್ಮನು ಅವಿನಾಶೀ ಎಂದು ಹೇಳಲಾಗುತ್ತದೆ. 15.16",

      "ಇವರಿಬ್ಬರಿಗಿಂತಲೂ ಉತ್ತಮನಾದ ಪುರುಷನಾದರೋ ಬೇರೊಬ್ಬನುಂಟು. ಅವನು ಮೂರು ಲೋಕಗಳಲ್ಲಿ ಪ್ರವೇಶಿಸಿ ಎಲ್ಲರ ರಕ್ಷಣೆ - ಪಾಲನೆ ಮಾಡುತ್ತಾನೆ ; ಆ ಅವಿನಾಶೀ ಪರಮೇಶ್ವರನನ್ನು ಪರಮಾತ್ಮಾ ಎಂದು ಹೇಳಲಾಗಿದೆ. 15.17",

      "ಏಕೆಂದರೆ ನಾನು ನಾಶಯುತ ಜಡವರ್ಗವಾದ ಕ್ಷೇತ್ರದಿಂದ ಸಂಪೂರ್ಣವಾಗಿ ಅತೀತನಾಗಿದ್ದೇನೆ ಮತ್ತು ಅವಿನಾಶೀ ಜೀವಾತ್ಮನಿಗಿಂತಲೂ ಸಹ ಉತ್ತಮನಾಗಿದ್ದೇನೆ. ಆದುದರಿಂದ ಲೋಕದಲ್ಲಿ ಮತ್ತು ವೇದದಲ್ಲಿಯೂ ಸಹ ಪುರುಷೋತ್ತಮನೆಂದು ಪ್ರಸಿದ್ಧನಾಗಿದ್ದೇನೆ. 15.18",

      "ಎಲೈ ಅರ್ಜುನ ! ಈ ರೀತಿ ಯಥಾರ್ಥವಾಗಿ ಯಾವ ಜ್ಞಾನಿಯು ನನ್ನನ್ನು ಪುರುಷೋತ್ತಮನೆಂದು ತಿಳಿದುಕೊಳ್ಳುತ್ತಾನೆಯೋ, ಆ ಸರ್ವಜ್ಞನು ಎಲ್ಲಾ ಬಗೆಯಿಂದಲೂ ನಿರಂತರ ವಾಸುದೇವ ಪರಮಾತ್ಮನಾದ ನನ್ನನ್ನೇ ಭಜಿಸುತ್ತಾನೆ. 15.19",

      "ಎಲೈ ಪಾಪರಹಿತನಾದ ಅರ್ಜುನ ! ಈ ರೀತಿ ಅತ್ಯಂತ ರಹಸ್ಯಮಯವಾದ ಈ ಶಾಸ್ತ್ರವು ನನ್ನಿಂದ ಹೇಳಲ್ಪಟ್ಟಿತು. ಇದನ್ನು ಯಥಾರ್ಥವಾಗಿ ತಿಳಿದುಕೊಂಡವನು ಜ್ಞಾನಿಯೂ ಮತ್ತು ಕೃತಾರ್ಥನೂ ಆಗುತ್ತಾನೆ. ಅರ್ಥತ್ ಅವನು ಮಾಡಬೇಕಾದದ್ದು ಏನೂ ಸಹ ಉಳಿದುಕೊಂಡಿರುವುದಿಲ್ಲ. 15.20",

      "ಇತಿ ಶ್ರೀಮದ್ಭಗವದ್ಗೀತಾರೂಪೀ ಉಪನಿಷದ್ ಬ್ರಹ್ಮವಿದ್ಯಾ ಹಾಗೂ ಯೋಗಶಾಸ್ತ್ರ ವಿಷಯಕ ಶ್ರೀ ಕೃಷ್ಣ ಮತ್ತು ಅರ್ಜುನರ ಸಂವಾದದಲ್ಲಿ ಪುರುಷೋತ್ತಮಯೋಗ ಎಂಬ ಹದಿನೈದನೇ ಅಧ್ಯಾಯ ಸಂಪೂರ್ಣ"
    ],
    "adhyaya-16" : [
      "ಶ್ರೀ ಭಗವಂತನು ಹೇಳಿದನು - ಭಯದ ಸರ್ವಥಾ ಅಭಾವ, ಅಂತಃಕರಣದ ಪೂರ್ಣ ನಿರ್ಮಲತೆ, ತತ್ವಜ್ಞಾನಕ್ಕಾಗಿ ಧ್ಯಾನಯೋಗದಲ್ಲಿ ನಿರಂತರ ದೃಢವಾದ ಸ್ಥಿತಿ ಮತ್ತು ಸಾತ್ವಿಕದಾನ, ಇಂದ್ರಿಯಗಳ ದಮನ, ಭಗವಂತನ, ದೇವತೆಗಳ ಮತ್ತು ಗುರುಜನರ ಪೂಜೆ ಹಾಗೂ ಅಗ್ನಿಹೋತ್ರಾದಿ ಉತ್ತಮ ಕರ್ಮಗಳ ಆಚರಣೆ, ವೇದ - ಶಾಸ್ತ್ರಗಳ ಪಠನ - ಪಾಠನ, ಭಗವಂತನ ನಾಮ ಮತ್ತು ಗುಣಗಳ ಕೀರ್ತನೆ, ಸ್ವಧರ್ಮ ಪಾಲನೆಗಾಗಿ ಕಷ್ಟವನ್ನು ಸಹಿಸುವುದು ಮತ್ತು ಶರೀರ ಇಂದ್ರಿಯಗಳ ಸಹಿತವಾಗಿ ಅಂತಃಕರಣದ ಸರಳತೆ 16.1",

      "ಮನಸ್ಸು, ಮಾತು ಮತ್ತು ಶರೀರದಿಂದ ಯಾರಿಗೂ ಯಾವ ಪ್ರಕಾರವಾಗಿಯೂ ಕಷ್ಟಕೊಡದಿರುವುದು, ಯಥಾರ್ಥ ಮತ್ತು ಪ್ರಿಯವಾಗಿ ಮಾತನಾಡುವುದು, ತನಗೆ ಅಪಕಾರ ಮಾಡಿದವರ ಮೇಲೆಯೂ ಕೋಪಿಸದಿರುವುದು, ಕರ್ಮಗಳಲ್ಲಿ ಕರ್ತೃತ್ವದ ಅಭಿಮಾನವನ್ನು ತ್ಯಜಿಸುವುದು, ಅಂತಃಕರಣದ ಉಪರತಿ ಅರ್ಥಾತ್ ಚಿತ್ತಚಾಂಚಲ್ಯದ ಅಭಾವ, ಯಾರನ್ನೂ ನಿಂದಿಸದಿರುವುದು, ಎಲ್ಲ ಪ್ರಾಣಿಗಳಲ್ಲಿ ಕಾರಣರಹಿತವಾದ ದಯೆ, ಇಂದ್ರಿಯಗಳು ವಿಷಯಗಳೊಡನೆ ಸಂಯೋಗವಾದಾಗಲೂ ಅದರಲ್ಲಿ ಆಸಕ್ತರಾಗದಿರುವಿಕೆ, ಕೋಮಲತೆ, ಲೋಕ ಮತ್ತು ಶಾಸ್ತ್ರಕ್ಕೆ ವಿರುದ್ಧವಾಗ ಆಚರಣೆಯಲ್ಲಿ ಲಜ್ಜೆ ಮತ್ತು ವ್ಯರ್ಥವಾದ ಚೇಷ್ಟೆಯ ಅಭಾವ. 16.2",

      "ತೇಜಸ್ಸು, ಕ್ಷಮೆ, ಧೈರ್ಯ, ಬಾಹ್ಯಶುದ್ಧಿ, ಹಾಗೂ ಯಾರಲ್ಲಿಯೂ ಶತ್ರುಭಾವವಿಲ್ಲದಿರುವುದು ಮತ್ತು ತನ್ನಲ್ಲಿ ಪೂಜ್ಯತೆಯ ಅಭಿಮಾನದ ಅಭಾವವು - ಹೇ ಅರ್ಜುನಾ ! ಇವೆಲ್ಲಾ ದೈವೀ ಸಂಪತ್ತನ್ನು ಪಡೆದು ಜನಿಸಿರುವ ಪುರುಷರ ಲಕ್ಷಣವಾಗಿವೆ. 16.3",

      "ಹೇ ಅರ್ಜುನಾ ! ದಂಭ, ಅಹಂಕಾರ, ಅಭಿಮಾನ, ಕ್ರೋಧ, ಕಠೋರತೆ ಮತ್ತು ಅಜ್ಞಾನ ಇವೆಲ್ಲಾ ಆಸುರೀ ಸಂಪತ್ತನ್ನು ಪಡೆದು ಹುಟ್ಟಿರುವ ಪುರುಷರ ಲಕ್ಷಣಗಳಾಗಿವೆ. 16.4",

      "ದೈವೀ ಸಂಪತ್ತು ಮುಕ್ತಿಗಾಗಿ ಮತ್ತು ಆಸುರೀ ಸಂಪತ್ತು ಬಂಧಿಸುವುದಕ್ಕಾಗಿ ಎಂದು ತಿಳಿಯಲಾಗಿದೆ. ಆದ್ದರಿಂದ ಹೇ ಅರ್ಜುನಾ ! ನೀನು ಶೋಕಪಡಬೇಡ ; ಏಕೆಂದರೆ ನೀನು ದೈವೀ ಸಂಪತ್ತನ್ನು ಪಡೆದು ಜನಿಸಿರುವೆ. 16.5",

      "ಹೇ ಅರ್ಜುನಾ! ಈ ಲೋಕದಲ್ಲಿ ಜೀವಿಗಳ ಸೃಷ್ಟಿ ಅಂದರೆ ಮನುಷ್ಯ ಸಮುದಾಯವು ಎರಡೇ ಪ್ರಕಾರದ್ದಾಗಿದೆ. ಒಂದಾದರೋ ದೈವೀ ಪ್ರಕೃತಿಯುಳ್ಳದ್ದು ಮತ್ತು ಇನ್ನೊಂದು ಆಸುರೀಪ್ರಕೃತಿಯುಳ್ಳದ್ದು. ಅವುಗಳಲ್ಲಿ ದೈವೀ ಪ್ರಕೃತಿಯುಳ್ಳವರನ್ನು ವಿಸ್ತಾರಪೂರ್ವಕವಾಗಿ ಹೇಳಲಾಯಿತು. ಈಗ ನೀನು ಆಸುರೀಪ್ರಕೃತಿಯುಳ್ಳ ಮನುಷ್ಯ ಸಮುದಾಯವನ್ನೂ ಕೂಡ ವಿಸ್ತಾರಪೂರ್ವಕವಾಗಿ ನನ್ನಿಂದ ಕೇಳು. 16.6",

      "ಆಸುರೀ ಸ್ವಭಾವದ ಮನುಷ್ಯರು ಪ್ರವೃತ್ತಿ ಮತ್ತು ನಿವೃತ್ತಿ ಇವೆರಡನ್ನೂ ಕೂಡ ಅರಿಯರು. ಅದಕ್ಕಾಗೆ ಅವರಲ್ಲಿ ಬಾಹ್ಯ ಮತ್ತು ಆಂತರಿಕ ಶುದ್ಧಿಯಾಗಲೀ, ಶ್ರೇಷ್ಠವಾದ ಆಚರಣೆಯಾಗಲೀ ಮತ್ತು ಸತ್ಯಭಾಷಣವಾಗಲೀ ಇರುವುದಿಲ್ಲ. 16.7",

      "ಆ ಆಸುರೀ ಪ್ರಕೃತಿಯುಳ್ಳ ಮನುಷ್ಯರು - ಈ ಜಗತ್ತು ಆಶ್ರಯರಹಿತವಾದುದು, ಸರ್ವಥಾ ಅಸತ್ಯ ಮತ್ತು ದೇವರಿಲ್ಲದೇ ತನ್ನಿಂದ ತಾನೇ ಕೇವಲ ಸ್ತ್ರೀ - ಪುರುಷರ ಸಂಯೋಗದಿಂದ ಸೃಷ್ಟಿಯಾಗಿದೆ. ಆದುದರಿಂದ ಕಾಮವೇ ಇದರ ಕಾರಣವಾಗಿದೆ. ಇದಲ್ಲದೆ ಬೇರೇನಿದೆ ? ಎಂದು ಹೇಳುತ್ತಾರೆ. 16.8",

      "ಈ ಮಿಥ್ಯಾಜ್ಞಾನವನ್ನವಲಂಬಿಸಿ ಯಾರ ಸ್ವಭಾವವು ನಷ್ಟವಾಗಿ ಹೋಗಿದೆಯೋ ಹಾಗೂ ಯಾರ ಬುದ್ಧಿಯು ವಂದವಾಗಿದೆಯೋ ಅವರು ಎಲ್ಲರಿಗೂ ಅಪಕಾರಮಾಡುವ ಕ್ರೂರಕರ್ಮಿಗಳಾದ ಮನುಷ್ಯರು; ಕೇವಲ ಜಗತ್ತಿನ ನಾಶಕ್ಕಾಗಿಯೇ ಸಮರ್ಥರಾಗುತ್ತಾರೆ. 16.9",

      "ದಂಭ, ಮಾನ ಮತ್ತು ಮದದಿಂದ ಕೂಡಿದವರಾದ ಮನುಷ್ಯರು ಯಾವ ಪ್ರಕಾರದಿಂದಲೂ ಪೂರ್ಣವಾಗದ ಕಾಮನೆಗಲ ಆಶ್ರಯಪಡೆದು, ಅಜ್ಞಾನದಿಂದ ಮಿಥ್ಯಾ ಸಿದ್ಧಾಂತಗಳನ್ನು ಗ್ರಹಿಸಿಕೊಂಡು ಮತ್ತು ಭ್ರಷ್ಟ ಆಚರಣೆಯನ್ನು ತಮ್ಮದಾಗಿಸಿಕೊಂಡು ಜಗತ್ತಿನಲ್ಲಿ ವ್ಯವಹರಿಸುತ್ತಾರೆ. 16.10",

      "ಹಾಗೂ ಅವರು ಮರಣಪರ್ಯಂತವಾಗಿ ಇರುವ ಅಸಂಖ್ಯಾತ ಚಿಂತೆಗಳ ಆಶ್ರಯ ಪಡೆದವರು, ವಿಷಯ ಭೋಗಗಳನ್ನು ಭೋಗಿಸುವುದರಲ್ಲಿ ತತ್ಪರರಾದವರು ಮತ್ತು ’ಇಷ್ಟೇ ಸುಖ’ ಎಂದು ಈ ಪ್ರಕಾರವಾಗಿ ತಿಳಿದವರಾಗಿರುತ್ತಾರೆ. 16.11",

      "ನೂರಾರು ಆಸೆಗಳ ಪಾಶಗಳಿಂದ ಬಂಧಿತರಾದ ಆ ಪುರುಷರು ಕಾಮಕ್ರೋಧಗಳ ಪರಾಯಣರಾಗಿ, ವಿಷಯ ಭೋಗಗಳಿಗಾಗಿ, ಅನ್ಯಾಯಪೂರ್ವಕವಾಗಿ ಧನವೇ ಮುಂತಾದ ಪದಾರ್ಥಗಳನ್ನು ಸಂಗ್ರಹಿಸುವ ಪ್ರಯತ್ನವನ್ನು ಮಾಡುತ್ತಿರುತ್ತಾರೆ. 16.12",

      "ನಾನು ಈ ದಿನ ಇದನ್ನು ಪ್ರಾಪ್ತಿ ಮಾಡಿಕೊಂಡಿದ್ದೇನೆ ಮತ್ತು ಈಗ ಈ ಮನೋರಥವನ್ನು ಪಡೆಯುವೆನು. ನನ್ನ ಬಳಿ ಇಷ್ಟು ಧನವಿದೆ ಮತ್ತು ಇನ್ನೂ ಕೂಡ ಧನಸಂಗ್ರಹವಾಗುವುದೆಂದು ಅವರು ಯೋಚಿಸುತ್ತಾರೆ. 16.13",

      "ಆ ಶತ್ರುವು ನನ್ನಿಂದ ಕೊಲ್ಲಲ್ಪಟ್ಟನು ಮತ್ತು ಇತರ ಶತ್ರುಗಳನ್ನು ಕೂಡ ನಾನು ಕೊಂದು ಹಾಕುವೆನು. ನಾನು ಈಶ್ವರನಾಗಿದ್ದೇನೆ, ಐಶವರ್ಯವನ್ನು ಭೋಗಿಸುವವನಾಗಿದ್ದೇನೆ. ನಾನು ಎಲ್ಲ ಸಿದ್ಧಿಗಳಿಂದ ಕೂಡಿದವನಾಗಿದ್ದೇನೆ ಮತ್ತು ಬಲಶಾಲಿಯೂ ಹಾಗೂ ಸುಖಿಯೂ ಆಗಿದ್ದೇನೆ. 16.14",

      "ನಾನು ದೊಡ್ಡ ಶ್ರೀಮಂತ, ದೊಡ್ಡ ಕುಟುಂಬವುಳ್ಳವನಾಗಿದ್ದೇನೆ, ನನ್ನ ಸಮಾನವಾಗಿ ಬೇರೆಯವರು ಯಾರಿದ್ದಾರೆ? ನಾನು ಯಜ್ಞವನ್ನು ಮಾಡುವೆನು; ದಾನವನ್ನು ಕೊಡುವೆನು ಮತ್ತು ಆಮೋದ - ಪ್ರಮೋದ ಮಾಡುವೆನು. - ಈ ಪ್ರಕಾರವಾಗಿ ಅಜ್ಞಾನದಿಂದ ಮೋಹಿತರಾಗಿರುವ ಹಾಗೂ ಅನೇಕ ಪ್ರಕಾರದಿಂದ ಭ್ರಮಿತ ಚಿತ್ತವುಳ್ಳವರು, ಮೋಹರೂಪೀ ಜಾಲದಲ್ಲಿ ಸಿಕ್ಕಿಹಾಕಿಕೊಂಡು ಮತ್ತು ವಿಷಯಗಳ ಭೋಗಗಳಲ್ಲಿ ಅತ್ಯಂತ ಆಸಕ್ತರಾದ ಅಸುರರು, ಮಹಾನ್ ಅಪವಿತ್ರ ನರಕದಲ್ಲಿ ಬೀಳುತ್ತಾರೆ. (16.15-16.16)",

      "ನಾನು ದೊಡ್ಡ ಶ್ರೀಮಂತ, ದೊಡ್ಡ ಕುಟುಂಬವುಳ್ಳವನಾಗಿದ್ದೇನೆ, ನನ್ನ ಸಮಾನವಾಗಿ ಬೇರೆಯವರು ಯಾರಿದ್ದಾರೆ? ನಾನು ಯಜ್ಞವನ್ನು ಮಾಡುವೆನು; ದಾನವನ್ನು ಕೊಡುವೆನು ಮತ್ತು ಆಮೋದ - ಪ್ರಮೋದ ಮಾಡುವೆನು. - ಈ ಪ್ರಕಾರವಾಗಿ ಅಜ್ಞಾನದಿಂದ ಮೋಹಿತರಾಗಿರುವ ಹಾಗೂ ಅನೇಕ ಪ್ರಕಾರದಿಂದ ಭ್ರಮಿತ ಚಿತ್ತವುಳ್ಳವರು, ಮೋಹರೂಪೀ ಜಾಲದಲ್ಲಿ ಸಿಕ್ಕಿಹಾಕಿಕೊಂಡು ಮತ್ತು ವಿಷಯಗಳ ಭೋಗಗಳಲ್ಲಿ ಅತ್ಯಂತ ಆಸಕ್ತರಾದ ಅಸುರರು, ಮಹಾನ್ ಅಪವಿತ್ರ ನರಕದಲ್ಲಿ ಬೀಳುತ್ತಾರೆ. (16.15-16.16)",

      "ತಾವು - ತಮ್ಮನ್ನೇ ಶ್ರೇಷ್ಠರೆಂದು ತಿಳಿದ ಆ ಅಹಂಕಾರೀ ಪುರುಷರು ಧನ ಮತ್ತು ಮಾನದ ಮದದಿಂದ ಕೂಡಿದಾವರಾಗಿ ಕೇವಲ ಹೆಸರಿಗೆ ಮಾತ್ರ ಯಜ್ಞಗಳ ಮೂಲಕ ಪಾಖಂಡತೆಯಿಂದ ಶಾಸ್ತ್ರವಿಧಿರಹಿತವಾದ ಯಜ್ಞವನ್ನು ಮಾಡುತ್ತಾರೆ. 16.17",

      "ಅವರು ಅಹಂಕಾರ, ಬಲ, ದರ್ಪ, ಕಾಮನೆ ಮತ್ತು ಕ್ರೋಧವೇ ಮೊದಲಾದವುಗಳ ಪರಾಯಣರಾಗಿರುತ್ತಾರೆ. ಇಷ್ಟೇ ಅಲ್ಲ ಇನ್ನೂ ಅನಾಚಾರದಲ್ಲಿ ನಿರತರಾಗಿದ್ದು ಮತ್ತು ಇತರರನ್ನು ನಿಂದಿಸುತ್ತ ತಮ್ಮ ಮತ್ತು ಇತರರ ಶರೀರದಲ್ಲಿ ಸ್ಥಿತನಾಗಿರುವ ಅಂತರ್ಯಾಮಿಯಾಗಿರುವ ನನ್ನೊಡನೆ ದ್ವೇಷ ಮಾಡುತ್ತಾರೆ. 16.18",

      "ಆ ದ್ವೇಷಮಾಡುವ ಪಾಪಾಚಾರಿಗಳೂ ಮತ್ತು ಕ್ರೂರಕರ್ಮಿಗಳೂ ಆದ ನರಾಧಮರನ್ನು ನಾನು ಪ್ರಪಂಚದಲ್ಲಿ ಪದೇ ಪದೇ ಆಸುರೀ ಯೋನಿಗಳಲ್ಲಿಯೇ ಹಾಕುತ್ತೇನೆ. 16.19",

      "ಹೇ ಅರ್ಜುನಾ! ಆ ಮೂಢರು ನನ್ನನ್ನು ಪಡೆಯದೆ ಜನ್ಮ-ಜನ್ಮಗಳಲ್ಲಿ ಆಸುರೀ ಯೋನಿಯನ್ನು ಪಡೆಯುತ್ತಾರೆ. ಅನಂತರ ಅದಕ್ಕಿಂತಲೂ ಕೂಡ ಅತಿ ನೀಚ ಗತಿಯನ್ನೇ ಪಡೆಯುತ್ತಾರೆ ಅಂದರೆ ಘೋರವಾದ ನರಕದಲ್ಲಿ ಬೀಳುತ್ತಾರೆ. 16.20",

      "ಕಾಮ, ಕ್ರೋಧ ಹಾಗೂ ಲೋಭ ಇವು ಮೂರು ಪ್ರಕಾರವಾದ ನರಕದ ದ್ವಾರಗಳು. ಆತ್ಮವನ್ನು ನಾಶಮಾಡುವ ಅರ್ಥಾತ್ ಅವನನ್ನು ಅಧೋಗತಿಗೆ ಕೊಂಡೊಯ್ಯುವಂತಹವುಗಳಾಗಿವೆ. ಆದುದರಿಂದ ಈ ಮೂರನ್ನೂ ತ್ಯಜಿಸಿಬಿಡಬೇಕು. 16.21",

      "ಹೇ ಅರ್ಜುನಾ ! ಈ ಮೂರೂ ನರಕದ ದ್ವಾರಗಳಿಂದ ಮುಕ್ತನಾದ ಪುರುಷನು ತನ್ನ ಶ್ರೇಯಸ್ಸಿಗಾಗಿ ಆಚರಣೆಯನ್ನು ಮಾಡುತ್ತಾನೆ, ಅದರಿಂದ ಅವನು ಪರಮ ಗತಿತೆ ಹೋಗುತ್ತಾನೆ ಅರ್ಥಾತ್ ನನ್ನನ್ನು ಪಡೆಯುತ್ತಾನೆ. 16.22",

      "ಯಾವ ಪುರುಷನು ಶಾಸ್ತ್ರವಿಧಿಯನ್ನು ತ್ಯಜಿಸಿ ತನ್ನ ಇಚ್ಛೆಯಿಂದ ಮನಬಂದಂತೆ ಆಚರಣೆ ಮಾಡುತ್ತಾನೋ ಅವನು ಸಿದ್ಧಿಯನ್ನು ಪಡೆಯುವುದಿಲ್ಲ. ಪರಮಗತಿಯನ್ನೂ ಪಡೆಯುವುದಿಲ್ಲ ಮತ್ತು ಸುಖವನ್ನೂ ಹೊಂದುವುದಿಲ್ಲ. 16.23",

      "ಆದ್ದರಿಂದ ನಿನಗಾಗಿ ಈ ಕರ್ತವ್ಯ ಮತ್ತು ಅಕರ್ತವ್ಯದ ವ್ಯವಸ್ಥೆಯಲ್ಲಿ ಶಾಸ್ತ್ರವೇ ಪ್ರಮಾಣವಾಗಿದೆ ಎಂದು ತಿಳಿದುಕೊಂಡು ನೀನು ಶಾಸ್ತ್ರವಿಧಿಯಿಂದ ನಿಯತ ಕರ್ಮವನ್ನೇ ಮಾಡಲು ಯೋಗ್ಯನಾಗಿರುವೆ. 16.24",

      "ಇತಿ ಶ್ರೀಮದ್ಭಗವದ್ಗೀತಾರೂಪೀ ಉಪನಿಷದ್ ಬ್ರಹ್ಮವಿದ್ಯಾ ಹಾಗೂ ಯೋಗಶಾಸ್ತ್ರ ವಿಷಯಕ ಶ್ರೀ ಕೃಷ್ಣ ಮತ್ತು ಅರ್ಜುನರ ಸಂವಾದದಲ್ಲಿ ದೈವಾಸುರಸಂಪದ್ವಿಭಾಗಯೋಗ ಎಂಬ ಹದಿನಾರನೇ ಅಧ್ಯಾಯ ಸಂಪೂರ್ಣ"
    ],
    "adhyaya-17" : [
      "ಅರ್ಜುನ ಹೇಳಿದನು - ಹೇ ಶ್ರೀ ಕೃಷ್ಣ ! ಯಾರು ಶಾಸ್ತ್ರವಿಧಿಗಳನ್ನು ಬಿಟ್ಟು ಕೇವಲ ಶ್ರದ್ಧಾಯುಕ್ತರಾಗಿ ದೇವಾದಿಗಳನ್ನು ಆರಾಧಿಸುವರೋ, ಅವರ ಸ್ಥಿತಿಗತಿ ಯಾವ ರೀತಿಯದು ? ಸಾತ್ತ್ವಿಕವೋ ಅಥವಾ ರಾಜಸವೋ ಇಲ್ಲವೇ ತಾಮಸವೋ? 17.1",

      "ಶ್ರೀ ಕೃಷ್ಣ ಪರಮಾತ್ಮ ಹೇಳಿದನು - ಮಾನವರ ಸ್ವಭಾವದಿಂದಲೇ ಉಂಟಾದ ಶಾಸ್ತ್ರೀಯ ಸಂಸ್ಕಾರವಿಲ್ಲದ ಆ ಶ್ರದ್ಧೆಯು ಸಾತ್ತ್ವಿಕ, ರಾಜಸ ಮತ್ತು ತಾಮಸ ಎಂಬುದಾಗಿ ಮೂರು ವಿಧವಾಗಿವೆ. ಅದನ್ನು ಹೇಳುತ್ತೇನೆ ಕೇಳು 17.2",

      "ಎಲೈ ಅರ್ಜುನ ! ಎಲ್ಲರ ಶ್ರದ್ಧೆಯು ಅವರವರ ಅಂತಃಕರಣಕ್ಕೆ ಅನುಗುಣವಾಗಿ ಉಂಟಾಗುತ್ತದೆ. ಈ ಮನುಷ್ಯ ಶ್ರದ್ಧಾವಂತನು,  ಯಾರು ಎಂತಹ ಶ್ರದ್ಧೆಯುಳ್ಳವನಾಗಿರುತ್ತಾನೆಯೋ  ಅವನು ಸ್ವತಃ ಅದರಂತೆ ಇರುತ್ತಾನೆ. ಅರ್ಥಾತ್ ಯಾರ ಶ್ರದ್ಧೆ ಹೇಗಿರುತ್ತದೆಯೋ ಹಾಗೆಯೇ ಅವರ ಸ್ವರೂಪವೂ ಇರುತ್ತದೆ 17.3",

      "ಸಾತ್ತ್ವಿಕರು ದೇವತೆಗಳನ್ನೂ, ರಾಜಸ ಪುರುಷರು ಯಕ್ಷ-ರಾಕ್ಷಸರನ್ನೂ ಮತ್ತು ಇನ್ನುಳಿದ ತಾಮಸ ಮನುಷ್ಯರು ಪ್ರೇತ-ಭೂತ ಗಣಗಳನ್ನೂ ಪೂಜಿಸುತ್ತಾರೆ 17.4",

      "ಯಾವ ಮನುಷ್ಯರು, ಶಾಸ್ತ್ರೋಕ್ತ ವಿಧಿಗಳನ್ನು ಬಿಟ್ಟು ಮನಸ್ಸಿಗೆ ತೋಚಿದಂತೆ ಘೋರವಾದ ತಪಸ್ಸನ್ನು ಆಚರಿಸುತ್ತಾರೋ, ದಂಭ-ಅಹಂಕಾರ ಇತ್ಯಾದಿಗಳಿಂದ ಕೂಡಿದವರಾಗಿ ಕಾಮ, ಆಸಕ್ತಿ ಮತ್ತು ಬಲದ ಅಭಿಮಾನವುಳ್ಳವರೋ - 17.5",

      "ಶಾಸ್ತ್ರವಿರುದ್ಧವಾದ ಉಪವಾಸವಾದಿ ಘೋರ ಆಚರಣೆ ಮೂಲಕ ಶರೀರವನ್ನು ಒಣಗಿಸುವುದು ಮತ್ತು ಭಗವಂತನ ಅಂಶರೂಪಿಯಾದ ಜೀವಾತ್ಮನಿಗೆ ಕಷ್ಟ ಕೊಡುವುದು-ಜೀವ ಸಮುದಾಯವನ್ನೂ ಮತ್ತು ಅಂತರ್ಯಾಮಿ ಪರಮೇಶ್ವರನನ್ನು ಕೃಶಗೋಳಿಸುವುದು. ಶರೀರ ರೂಪದಿಂದ ಜೀವ ಸಮುದಾಯ ಮತ್ತು ಅಂತಃಕರಣದಲ್ಲಿರುವ ಅಂತರ್ಯಾಮಿಯಾದ ನನ್ನನ್ನೂ ಸಹ ದುರ್ಬಲಗೊಳಿಸುವರೋ ಆ ಅಜ್ಞಾನಿಗಳನ್ನು ಆಸುರೀ ಸ್ವಭಾವದವರೆಂದು ತಿಳಿ 17.6",

      "ಭೋಜನವೂ ಸಹ ಎಲ್ಲರಿಗೂ ಅವರವರ ಪ್ರಕೃತಿಗೆ ಅನುಗುಣವಾಗಿ ಮೂರು ವಿಧದಲ್ಲಿ ಪ್ರಿಯವಾಗುತ್ತದೆ. ಅದರಂತೆಯೇ ಯಜ್ಞ, ತಪಸ್ಸು ಮತ್ತು ದಾನವೂ ಸಹ ಮೂರು ವಿಧ. ಅವುಗಳ ಈ ಬೇರೆ ಬೇರೆ ಭೇದಗಳನ್ನು ಕೇಳು 17.7",

      "ಆಯುಸ್ಸು, ಬುದ್ಧಿ, ಬಲ, ಆರೋಗ್ಯ, ಸುಖ ಮತ್ತು ಪ್ರೀತಿಯನ್ನು ವೃದ್ಧಿಗೋಳಿಸುವಂತಹ ರಸಯುಕ್ತವಾಗಿ ತುಪ್ಪದಿಂದ ತಯಾರಾದ ಸ್ಥಿರವಾಗಿರುವಂತಹ ಮತ್ತು ಸ್ವಾಭಾವಿಕವಾಗಿಯೇ ಮನಸ್ಸಿಗೆ ಪ್ರಿಯವಾದ ಆಹಾರಗಳು ಅರ್ಥಾತ್ ಭೋಜನದ ಪದಾರ್ಥಗಳು ಸಾತ್ತ್ವಿಕ ಪುರುಷರಿಗೆ ಪ್ರಿಯವಾಗುತ್ತವೆ 17.8",

      "ಕಹಿ, ಹುಳಿ, ಉಪ್ಪುಳ್ಳ, ಅತಿ ಬಿಸಿಯಾದ, ತೀಕ್ಷ್ಣ ಅರ್ಥಾತ್ ಖಾರವಾದ, ಹುರಿದ, ದಾಹವನ್ನುಂಟುಮಾಡುವ ಮತ್ತು ದುಃಖ, ಚಿಂತೆ ಮತ್ತು ರೋಗಾದಿಗಳನ್ನು ಉಂಟುಮಾಡುವಂತಹ ಆಹಾರ ಪದಾರ್ಥ ಅರ್ಥಾತ್ ಭೋಜನದ ಪದಾರ್ಥಗಳು ರಾಜಸ ಪುರುಷರಿಗೆ ಪ್ರಿಯವಾಗುತ್ತವೆ 17.9",

      "ಯಾವ ಭೋಜನವು ಸರಿಯಾಗಿ ಪಕ್ವವಾಗಿರುವುದಿಲ್ಲವೋ, ರಸಹೀನವಾಗಿದೆಯೋ, ದುರ್ಗಂಧಮಯವಾಗಿರುವುದೋ, ಹಳಸಿಹೋದದ್ದೋ, ತಿಂದುಳಿದುದೋ ಹಾಗು ಅಪವಿತ್ರವಾದುದೋ. ಅವುಗಳೆಲ್ಲಾ ತಾಮಸ ಪುರುಷರಿಗೆ ಪ್ರಿಯವಾಗಿರುತ್ತದೆ 17.10",

      "ಶಾಸ್ತ್ರೋಕ್ತವಾಗಿ ನಿಯಮಿಸಲ್ಪಟ್ಟು ಮಾಡಲೇಬೇಕಾದ ಕರ್ತವ್ಯವಾಗಿದೆ - ಎಂಬುದಾಗಿ ಮನಸ್ಸನ್ನು ದೃಢಪಡಿಸಿಕೊಂಡು ಫಲಾಪೇಕ್ಷೆಯಿಲ್ಲದೆ ಇರುವಂತಹ ವ್ಯಕ್ತಿಯಿಂದ ಯಾವ ಯಜ್ಞ ಮಾಡಲ್ಪಡುವುದೋ ಅದು ಸಾತ್ತ್ವಿಕ (ಯಜ್ಞ) ವಾಗಿದೆ 17.11",

      "ಆದರೆ, ಎಲೈ ಅರ್ಜುನ ! ಕೇವಲ ದಂಭಾಚಾರಕ್ಕಾಗಿಯೋ ಅಥವಾ ಫಲವನ್ನು ಪಡೆಯುವ ಉದ್ದೇಶವಿಟ್ಟುಕೊಂಡು ಮಾಡಲಾಗುವ ಯಜ್ಞವನ್ನು ನೀನು ರಾಜಸವೆಂದು ತಿಳಿ 17.12",

      "ಶಾಸ್ತ್ರೋಕ್ತ ವಿಧಿ-ವಿಧಾನಗಳಿಲ್ಲದ, ಅನ್ನದಾನವಿಲ್ಲದಿರುವ, ಮಂತ್ರಗಳಿಲ್ಲದ, ದಕ್ಷಿಣೆ ನೀಡದಿರುವ ಮತ್ತು ಶ್ರದ್ಧೆಯಿಲ್ಲದೆ ಮಾಡಲ್ಪಡುವ ಯಜ್ಞವನ್ನು ತಾಮಸ ಎಂದು ಹೇಳುತ್ತಾರೆ 17.13",

      "ದೇವತೆಗಳನ್ನು, ಬ್ರಾಹ್ಮಣರನ್ನು, ಗುರುಗಳನ್ನು ಮತ್ತು ಜ್ಞಾನೀ ಮಹಾತ್ಮರನ್ನು ಪೂಜಿಸಿ ಗೌರವಿಸುವುದು ಹಾಗು ಪವಿತ್ರತೆ, ಸರಳತೆ, ಬ್ರಹ್ಮಚರ್ಯ ಮತ್ತು ಅಹಿಂಸೆ ಇತ್ಯಾದಿಗಳೆಲ್ಲಾ ಶರೀರ ಸಂಬಂಧವಾದ ತಪಸ್ಸು ಎಂದು ಹೇಳಲಾಗುತ್ತದೆ 17.14",

      "ಹಾಗೂ ಉದ್ವೇಗಗೊಳಿಸದ, ಪ್ರಿಯವಾದ, ಹಿತಕರವಾದ ಹಾಗೂ ಯಥಾರ್ಥವಾದ ಮಾತುಗಳಾಗಿವೆಯೋ ಮತ್ತು ವೇದ-ಶಾಸ್ತ್ರಗಳ ಅಧ್ಯಯನ ಹಾಗೂ ಪರಮೇಶ್ವರನ ನಾಮ ಜಪದ ಅಭ್ಯಾಸ ಇತ್ಯಾದಿಗಳನ್ನು ವಾಣಿ ಸಂಬಂಧವಾದ ತಪಸ್ಸು ಎಂದು ಹೇಳಲಾಗುತ್ತದೆ 17.15",

      "ಮನಸ್ಸಿನ ಪ್ರಸನ್ನತೆ, ಶಾಂತ ಸ್ವಭಾವ, ಭಗವತ್-ಚಿಂತನೆ ಮಾಡುವ ಅಭ್ಯಾಸ, ಮನೋನಿಗ್ರಹ ಮತ್ತು ಅಂತಃಕರಣದ ಭಾವನೆಗಳ ಪವಿತ್ರತೆ - ಹೀಗೆ ಇವು ಮಾನಸಿಕ ತಪಸ್ಸು ಎಂದು ಹೇಳಲ್ಪಡುತ್ತದೆ 17.16",

      "ಫಲವನ್ನು ಬಯಸದ ನಿಷ್ಕಾಮೀ ಯೋಗಿ ಪುರುಷರಿಂದ ಪರಮ ಶ್ರದ್ಧೆಯಿಂದ ಆಚರಿಸಲ್ಪಟ್ಟ ಹಿಂದೆ ತಿಳಿಸಿದ ಆ ಮೂರು ಬಗೆಯ ತಪಸ್ಸುಗಳನ್ನು ಸಾತ್ತ್ವಿಕವೆಂದು ಹೇಳುತ್ತಾರೆ 17.17",

      "ಯಾವ ತಪಸ್ಸು ಸತ್ಕಾರ, ಮಾನ ಮತ್ತು ಪೂಜೆಗಳಿಗಾಗಿಯೇ ಕೇವಲ ಗರ್ವದಿಂದ ಅಥವಾ ಸ್ವಾರ್ಥಕ್ಕಾಗಿ ಮಾಡಲಾಗುತ್ತದೆಯೋ ಅದು ಅನಿಶ್ಚಿತವಾದ ಹಾಗು ಕ್ಷಣಿಕ ಫಲದಾಯಕವಾದ ಪ್ರಯುಕ್ತ ಅದನ್ನು ಇಲ್ಲಿ ರಾಜಸವೆಂದು ಹೇಳಲಾಗಿದೆ 17.18",

      "ಯಾವ ತಪಸ್ಸು ಮೂಢಬುದ್ಧಿಯ ಹಠದಿಂದ ಕಾಯಾ, ವಾಚಾ, ಮನಸಾ ತನಗೇ ತೋಂದರೆ ಮಾಡಿಕೊಂಡು ಅಥವಾ ಇತರರಿಗೆ ಕೇಡುಮಾಡುವ ಸಲುವಾಗಿ ಮಾಡಲ್ಪಡುವುದೋ ಆ ತಪಸ್ಸು ತಾಮಸವೆಂದು ಹೇಳಲ್ಪಟ್ಟಿದೆ 17.19",

      "ದಾನ ಕೊಡುವುದೂ ಸಹ ಕರ್ತವ್ಯ ಎಂಬ ಉದ್ದೇಶದಿಂದ ಯಾವ ದಾನವು ದೇಶ, ಕಾಲ ಮತ್ತು ಪಾತ್ರನು ಒದಗಿ ಬಂದಾಗ ಪ್ರತ್ಯುಪಕಾರ ಮಾಡಲು ಸಮರ್ಥರಲ್ಲದವರಿಗೆ ಕೊಡಲಾಗುವುದೋ ಆ ದಾನವು ಸಾತ್ತ್ವಿಕವೆಂದು ಹೇಳಲ್ಪಟ್ಟಿದೆ 17.20",

      "ಯಾವ ದಾನವು ಸಂಕಟಪಡುತ್ತಾ, ಪುನಃ ಪ್ರತ್ಯುಪಕಾರದ ಪ್ರಯೋಜನವನ್ನು ಬಯಸಿ ಅಥವಾ ಫಲವನ್ನು ಉದ್ದೇಶದಲ್ಲಿಟ್ಟುಕೊಂಡು ಕೊಡಲಾಗುವುದೋ ಆ ದಾನವು ರಾಜಸವೆಂದು ಹೇಳಲ್ಪಟ್ಟಿದೆ 17.21",

      "ಯಾವ ದಾನವು ಸತ್ಕಾರ ಮಾಡದೇ ತಿರಸ್ಕಾರ ಪೂರ್ವಕ ಅಯೋಗ್ಯವಾದ ದೇಶ-ಕಾಲಗಳಲ್ಲಿ ಮತ್ತು ಪಾತ್ರರಲ್ಲದವರಿಗೆ ಕೊಡಲಾಗುವುದೋ ಅದು ತಾಮಸವೆಂದು ಹೇಳಲ್ಪಟ್ಟಿದೆ 17.22",

      "ಓಂ, ತತ್, ಸತ್ ಎಂಬುದಾಗಿ ಮೂರು ಬಗೆಯ ಹೆಸರುಗಳೂ ಸಚ್ಚಿದಾನಂದ ಘನ ಬ್ರಹ್ಮನಿಗೆ ಹೇಳಲ್ಪಟ್ಟಿವೆ. ಆ ಮೂಲಕ ಸೃಷ್ಟಿಯ ಆದಿಕಾಲದಲ್ಲಿ ಬ್ರಾಹ್ಮಣ, ವೇದ ಮತ್ತು ಯಜ್ಞಾದಿಗಳು ರಚಿಸಲ್ಪಟ್ಟಿವೆ 17.23",

      "ಆದುದರಿಂದ ವೇದಘೋಷ ಮಾಡುವ ಶ್ರೇಷ್ಠ ಪುರುಷರ ಶಾಸ್ತ್ರೋಕ್ತ ವಿಧಿಯಿಂದ ನಿಯಮಿತವಾದ ಯಜ್ಞ, ದಾನ ಮತ್ತು ತಪಸ್ಸಾದಿ ಕ್ರಿಯೆಗಳೆಲ್ಲಾ ಯಾವಾಗಲು ಓಂ ಎಂಬ ಪರಮಾತ್ಮನ ನಾಮವನ್ನು ಉಚ್ಚರಿಸಿಯೇ ಪ್ರಾರಂಭವಾಗುತ್ತವೆ 17.24",

      "ತತ್ ಅರ್ಥಾತ್ ತತ್ ಎಂಬ ನಾಮದಿಂದ ಹೇಳಲ್ಪಡುವ ಪರಮಾತ್ಮನದೇ ಈ ಸರ್ವಸ್ವವೂ ಎಂದು ಭಾವಿಸಿಕೊಂಡು, ಫಲವನ್ನು ಬಯಸದೇ ನಾನಾ ರೀತಿಯ ಯಜ್ಞ, ತಪಸ್ಸಾದಿ ಕ್ರಿಯೆಗಳು ಮತ್ತು ದಾನ ರೂಪೀ ಕ್ರಿಯೆಗಳು ಮೋಕ್ಷವನ್ನು ಬಯಸುವವರಿಂದ ಮಾಡಲ್ಪಡುತ್ತವೆ 17.25",

      "’ಸತ್’ ಎಂಬ ಈ ಪರಮಾತ್ಮನ ನಾಮವು ಸತ್ಯ ಎಂಬ ಭಾವದಲ್ಲಿಯೂ ಮತ್ತು ಶ್ರೇಷ್ಠ ಎಂಬ ಭಾವದಲ್ಲಿಯೂ ಪ್ರಯೋಗಿಸಲ್ಪಡುತ್ತದೆ ಹಾಗೂ ಎಲೈ ಅರ್ಜುನ ! ಉತ್ತಮವಾದ ಕರ್ಮದಲ್ಲಿಯೂ ಸಹ ಸತ್ ಎಂಬ ಶಬ್ದವು ಪ್ರಯೋಗಿಸಲ್ಪಡುತ್ತದೆ 17.26",

      "ಹಾಗೂ ಯಜ್ಞ, ತಪಸ್ಸು ಮತ್ತು ದಾನದಲ್ಲಿರುವ ಸ್ಥಿತಿಯೂ ಸಹ ಸತ್ ಎಂದು ಹೇಳಲ್ಪಡುತ್ತದೆ ಮತ್ತು ಆ ಪರಮಾತ್ಮನಿಗಾಗಿ ಮಾಡಲ್ಪಟ್ಟ ಕರ್ಮವು ನಿಶ್ಚಿತವಾಗಿಯೂ ಸತ್ ಎಂದೇ ಹೇಳಲ್ಪಡುತ್ತದೆ 17.27",

      "ಎಲೈ ಅರ್ಜುನ ! ಅಶ್ರದ್ಧೆಯಿಂದ ಮಾಡಿದ ಹವನ-ಹೋಮ, ಕೊಟ್ಟ ದಾನ, ಆಚರಿಸಿದ ತಪಸ್ಸು, ಮತ್ತು ಇನ್ನಿತರೆ ಕರ್ಮಗಳು ಅಸತ್ ಎಂದು ಹೇಳಲ್ಪಡುತ್ತವೆ. ಆದುದರಿಂದ ಅವು ಇಹ-ಪರಗಳಲ್ಲಿ ಎಲ್ಲಿಯೂ ಶ್ರೇಯಸ್ಕರವಲ್ಲ. (ಆದುದರಿಂದ ಸಚ್ಚಿದಾನಂದ ಘನ ಪರಮಾತ್ಮನ ನಾಮವನ್ನು ನಿರಂತರ ಸ್ಮರಿಸುತ್ತಾ ನಿಷ್ಕಾಮ ಭಾವದಿಂದ ಕೇವಲ ಪರಮಾತ್ಮನಿಗಾಗಿಯೇ ಶಾಸ್ತ್ರೋಕ್ತವಾಗಿ ನಿಯಮಿತವಾದ ಕರ್ಮಗಳನ್ನು ಪರಮ ಶ್ರದ್ಧೆ ಮತ್ತು ಉತ್ಸಾಹದಿಂದ ಆಚರಿಸಬೇಕು.) 17.28",

      "ಇತಿ ಶ್ರೀಮದ್ಭಗವದ್ಗೀತಾರೂಪಿ ಉಪನಿಷತ್ ಮತ್ತು ಬ್ರಹ್ಮ ವಿದ್ಯೆ ಹಾಗೂ ಯೋಗಶಾಸ್ತ್ರ ವಿಷಯವಾಗಿ ಶ್ರೀಕೃಷ್ಣ ಮತ್ತು ಅರ್ಜುನರ ಸಂವಾದದಲ್ಲಿ \"ಶ್ರದ್ಧಾತ್ರಯ ವಿಭಾಗ ಯೋಗ\" ಎಂಬ ಹದಿನೇಳನೇಯ ಅಧ್ಯಾಯ ಸಂಪೂರ್ಣ"
    ],
    "adhyaya-18" : [
      "ಅರ್ಜುನನು ಹೇಳಿದನು - ಹೇ ಮಹಾಬಾಹುವೇ ! ಹೇ ಅಂತರ್ಯಾಮಿಯೇ ! ಹೇ ವಾಸುದೇವನೇ ! ನಾನು ಸಂನ್ಯಾಸ ಮತ್ತು ತ್ಯಾಗದ ತತ್ವವನ್ನು ಬೇರೆ - ಬೇರೆಯಾಗಿ ತಿಳಿಯಲು ಬಯಸುತ್ತೇನೆ. 18.1",

      "ಶ್ರೀ ಭಗವಂತನು ಹೇಳಿದನು - ಎಷ್ಟೋ ಪಂಡಿತಜನರು ಕಾಮ್ಯಕರ್ಮಗಳ ತ್ಯಾಗವನ್ನು ಸಂನ್ಯಾಸವೆಂದು ತಿಳಿಯುತ್ತಾರೆ ಹಾಗೂ ಇತರ ವಿಚಾರಶೀಲರಾದ ಪುರುಷರು ಎಲ್ಲಾ ಕರ್ಮಗಳ ಫಲತ್ಯಾಗವನ್ನು ತ್ಯಾಗವೆಂದು ಹೇಳುತ್ತಾರೆ. 18.2",

      "ಕೆಲ ವಿದ್ವಾಂಸರು - ಎಲ್ಲಾ ಕರ್ಮಗಳು ದೋಷ ಯುಕ್ತವಾಗಿವೆ ಆದ್ದರಿಂದ ತ್ಯಜಿಸಲು ಯೋಗ್ಯವಾಗಿವೆ ಎಂದು ಹೇಳುತ್ತಾರೆ, ಮತ್ತು ಇತರ ವಿದ್ವಾಂಸರು - ಯಜ್ಞ, ದಾನ ಮತ್ತು ತಪರೂಪವಾದ ಕರ್ಮಗಳು ತ್ಯಜಿಸಲು ಯೋಗ್ಯವಾಗಿಲ್ಲ ಎಂದು ಹೇಳುತ್ತಾರೆ. 18.3",

      "ಹೇ ಪುರುಷಶ್ರೇಷ್ಠನಾದ ಅರ್ಜುನಾ! ಸಂನ್ಯಾಸ ಮತ್ತು ತ್ಯಾಗ - ಈ ಎರಡರಲ್ಲಿ ಮೊದಲು ತ್ಯಾಗದ ವಿಷಯದಲ್ಲಿ ನೀನು ನನ್ನ ನಿಶ್ಚಯವನ್ನು ಕೇಳು; ಏಕೆಂದರೆ ತ್ಯಾಗವು ಸಾತ್ವಿಕ, ರಾಜಸ ಮತ್ತು ತಾಮಸ ಎಂಬ ಭೇದದಿಂದ ಮೂರು ಪ್ರಕಾರದ್ದೆಂದು ಹೇಳಲಾಗಿದೆ. 18.4",

      "ಯಜ್ಞ, ದಾನ ಮತ್ತು ತಪರೂಪವಾದ ಕರ್ಮ ತ್ಯಜಿಸಲು ಯೋಗ್ಯವಾದುದಲ್ಲ. ಅಲ್ಲದೆ ಅದಾದರೋ ಅವಶ್ಯವಾದ ಕರ್ತವ್ಯವಾಗಿದೆ; ಏಕೆಂದರೆ ಯಜ್ಞ, ದಾನ ಮತ್ತು ತಪ - ಈ ಮೂರೂ ಕರ್ಮಗಳೇ ಬುದ್ಧಿವಂತರಾದ ಪುರುಷರನ್ನು ಪವಿತ್ರಗೊಳಿಸುವುದಾಗಿದೆ. 18.5",

      "ಅದಕ್ಕಾಗಿ ಹೇ ಪಾರ್ಥನೆ! ಈ ಯಜ್ಞ, ದಾನ ಮತ್ತು ತಪೋರೂಪೀ ಕರ್ಮಗಳನ್ನು ಹಾಗೂ ಬೇರೆಯೂ ಕೂಡ ಸಂಪೂರ್ಣ ಕರ್ತವ್ಯಕರ್ಮಗಳನ್ನು ಆಸಕ್ತಿ ಮತ್ತು ಫಲಗಳ ತ್ಯಾಗಗೈದು ಅವಶ್ಯವಾಗಿ ಮಾಡಬೇಕು; ಇದು ನನ್ನ ನಿಶ್ಚಯಿಸಲ್ಪಟ್ಟ ಉತ್ತಮವಾದ ಅಭಿಪ್ರಾಯವಾಗಿದೆ. 18.6",

      "(ನಿಷಿದ್ಧ ಮತ್ತು ಕಾಮ್ಯ ಕರ್ಮಗಳನ್ನಾದರೋ ಸ್ವರೂಪದಿಂದ ತ್ಯಾಗ ಮಾಡುವುದು ಉಚಿತವೇ ಆಗಿದೆ. ) ಆದರೆ ನಿಯತ ಕರ್ಮವನ್ನು ಸ್ವರೂಪದಿಂದ ತ್ಯಜಿಸುವುದು ಉಚಿತವಾದುದಲ್ಲ. ಅದಕ್ಕಾಗಿ ಮೋಹದ ಕಾರಣ ಅದನ್ನು ತ್ಯಾಗಮಾಡಿ ಬಿಡುವುದು ತಾಮಸ ತ್ಯಾಗವೆಂದು ಹೇಳಲಾಗಿದೆ. 18.7",

      "ಯಾವುದೆಲ್ಲ ಕರ್ಮವಿವೆಯೋ ಅವೆಲ್ಲವೂ ದುಃಖ ರೂಪವೇ ಆಗಿವೆ - ಎಂದು ತಿಳಿದುಕೊಂಡು, ಒಂದು ವೇಳೆ ಯಾವುದಾದರೂ ಶಾರೀರಿಕ ಕ್ಲೇಶದ ಭಯದಿಂದ ಕರ್ತವ್ಯ ಕರ್ಮಗಳನ್ನು ತ್ಯಾಗಮಾಡಿದ್ದೇ ಆದರೆ, ಅವನು ಇಂತಹ ರಾಜಸವಾದ ತ್ಯಾಗವನ್ನು ಮಾಡಿ, ತ್ಯಾಗದ ಫಲವನ್ನು ಯಾವ ಪ್ರಕಾರದಿಂದಲೂ ಪಡೆಯುವುದಿಲ್ಲ. 18.8",

      "ಹೇ ಅರ್ಜುನನೇ ! ಯಾವ ಶಾಸ್ತ್ರವಿಹಿತವಾದ ಕರ್ಮವು ಮಾಡಲೇಬೇಕು ಎಂಬ ಕರ್ತವ್ಯ ಭಾವದಿಂದ ಆಸಕ್ತಿ ಮತ್ತು ಫಲವನ್ನು ತ್ಯಾಗಗೈದು ಮಾಡಲಾಗುತ್ತದೆಯೋ - ಅದೇ ಸಾತ್ವಿದ ತ್ಯಾಗವೆಂದು ತಿಳಿಯಲಾಗಿದೆ. 18.9",

      "ಯಾವ ಮನುಷ್ಯನು ಅಕುಶಲ ಕರ್ಮವನ್ನು ದ್ವೇಷಿಸುವುದಿಲ್ಲವೋ ಮತ್ತು ಕುಶಲ ಕರ್ಮದಲ್ಲಿ ಆಸಕ್ತನಾಗುವುದಿಲ್ಲವೋ - ಅಂತಹ ಶುದ್ಧವಾದ ಸತ್ವಗುಣದಿಂದ ಯುಕ್ತನಾದ ಪುರುಷನು, ಸಂಶಯರಹಿತ, ಬುದ್ಧಿವಂತ ಮತ್ತು ನಿಜವಾದ ತ್ಯಾಗಿಯೂ ಆಗಿದ್ದಾನೆ. 18.10",

      "ಏಕೆಂದರೆ ಶರೀರಧಾರಿಯಾದ ಯಾವುದೇ ಮನುಷ್ಯನ ಮೂಲಕ ಸಂಪೂರ್ಣವಾಗಿ ಕರ್ಮಗಳೆಲ್ಲಾ ತ್ಯಾಗಮಾಡಲ್ಪಡುವುದು ಸಾಧ್ಯವಾಗುವುದಿಲ್ಲ, ಆದಕಾರಣ ಯಾರು ಕರ್ಮಫಲ ತ್ಯಾಗಿಯಾಗಿದ್ದಾನೋ ಅವನೇ ತ್ಯಾಗಿಯಾಗಿದ್ದಾನೆ ಎಂದು ಹೇಳಲಾಗುತ್ತದೆ. 18.11",

      "ಕರ್ಮಫಲವನ್ನು ತ್ಯಾಗಮಾಡದಿರುವ ಮನುಷ್ಯ್ರ ಕರ್ಮಗಳಾದರೋ ಒಳ್ಳೆಯ, ಕೆಟ್ಟ ಮತ್ತು ಮಿಶ್ರವಾದ ಹೀಗೆ ಮೂರೂ ವಿಧವಾದ ಫಲಗಳು ಮರಣಾನಂತರ ಅವಶ್ಯವಾಗಿ ಉಂಟಾಗುತ್ತವೆ. ಆದರೆ, ಕರ್ಮಫಲವನ್ನು ತ್ಯಾಗಮಾಡಿಬಿಡುವ ಮನುಷ್ಯರ ಕರ್ಮಗಳ ಫಲವು ಯಾವ ಕಾಲದಲ್ಲಿಯೂ ಉಂಟಾಗುವುದಿಲ್ಲ. 18.12",

      "ಹೇ ಮಹಾಬಾಹುವೇ ! ಸಂಪೂರ್ಣ ಕರ್ಮಗಳ ಸಿದ್ಧಿಗೆ ಈ ಐದು ಕಾರಣಗಳು ಕರ್ಮಗಳನ್ನು ಅಂತ್ಯಗೊಳಿಸುವುದಕ್ಕಾಗಿ ಉಪಾಯವನ್ನು ತಿಳಿಸುವ ಸಾಂಖ್ಯಶಾಸ್ತ್ರದಲ್ಲಿ ಹೇಳಲ್ಪಟ್ಟಿದೆ; ಅವುಗಳನ್ನು ನೀನು ನನ್ನಿಂದ ಚೆನ್ನಾಗಿ ತಿಳಿದುಕೋ. 18.13",

      "ಈ ವಿಷಯದಲ್ಲಿ ಅರ್ಥಾತ್ ಕರ್ಮಗಳ ಸಿದ್ಧಿಯಲ್ಲಿ ಅಧಿಷ್ಠಾನ ಮತ್ತು ಕರ್ತೃವು, ಭಿನ್ನ ಭಿನ್ನ ಪ್ರಕಾರಕ ಕರಣಗಳು ಹಾಗೂ ನಾನಾ ಪ್ರಕಾರದ, ಬೇರೆ ಬೇರೆಯಾದ ಕ್ರಿಯೆಗಳು ಮತ್ತು ಹಾಗೆಯೇ ಐದನೆಯ ಕಾರಣವು ದೈವವಾಗಿದೆ. 18.14",

      "ಮನುಷ್ಯನು ಮನಸ್ಸು, ವಾಣಿ ಮತ್ತು ಶರೀರದಿಂದ ಶಾಸ್ತ್ರಾನುಕೂಲವಾಗಲೀ ಅಥವಾ ವಿಪರೀತವಾಗಲೀ, ಯಾವುದೇ ಕರ್ಮವನ್ನು ಮಾಡುತ್ತಾನೋ ಅದಕ್ಕೆ ಈ ಐದೂ ಕಾರಣಗಳಾಗಿವೆ. 18.15",

      "ಆದರೆ, ಹೀಗಿದ್ದರೂ ಸಹ ಯಾವ ಮನುಷ್ಯನು ಅಶುದ್ಧಬುದ್ಧಿಯವನಾದ ಕಾರಣ ಆ ವಿಷಯದಲ್ಲಿ ಎಂದರೆ ಕರ್ಮಗಳಾಗುವುದರಲ್ಲಿ ನಿರ್ವಿಕಾರ ಶುದ್ಧ ಸ್ವರೂಪೀ ಆತ್ಮನನ್ನು ಕರ್ತಾ ಎಂದು ತಿಳಿಯುತ್ತಾನೋ ಆ ಮಲಿನ ಬುದ್ಧಿಯವನಾದ ಅಜ್ಞಾನಿಯು ಯಥಾರ್ಥವನ್ನು ಅರಿಯುವುದಿಲ್ಲ. 18.16",

      "ಯಾವ ಪುರುಷನ ಅಂತಃಕರಣದಲ್ಲಿ ’ ನಾನು ಕರ್ತಾ ಆಗಿದ್ದೇನೆ’ ಎನ್ನುವ ಭಾವ ಇರುವುದಿಲ್ಲವೋ, ಹಾಗೂ ಯಾರ ಬುದ್ಧಿಯು ಸಾಂಸಾರಿಕ ಪದಾರ್ಥಗಳಲ್ಲಿ ಮತ್ತು ಕರ್ಮಗಳಲ್ಲಿ ಅಂಟಿಕೊಳ್ಳುವುದಿಲ್ಲವೋ ಆ ಪುರುಷರು ಈ ಎಲ್ಲ ಜನರನ್ನು ಕೊಂದರೂ ಸಹ ವಾಸ್ತವವಾಗಿ ಕೊಲ್ಲುವುದೂ ಇಲ್ಲ ಮತ್ತು ಪಾಪದಿಂದ ಬಂಧಿತನಾಗುವುದೂ ಇಲ್ಲ. 18.17",

      "ಜ್ಞಾತಾ, ಜ್ಞಾನ ಮತ್ತು ಜ್ಞೇಯ ಇವು ಮೂರು ಪ್ರಕಾರದ ಕರ್ಮಪ್ರೇರಣೆಯಾಗಿದೆ ಮತ್ತು ಕರ್ತಾ, ಕರಣ ಹಾಗೂ ಕ್ರಿಯೆ ಇವು ಮೂರು ಪ್ರಕಾರದ ಕರ್ಮಸಂಗ್ರಹವಾಗಿದೆ. 18.18",

      "ಗುಣಗಳ ಸಂಖ್ಯೆಯನ್ನು ತಿಳಿಸುವಂತಹ ಶಾಸ್ತ್ರದಲ್ಲಿ ಜ್ಞಾನ, ಕರ್ಮ ಮತ್ತು ಕರ್ತಾ ಗುಣಗಳ ಭೇದದಿಂದ ಮೂರು ಮೂರು ಪ್ರಕಾರದಿಂದಲೇ ಹೇಳಲ್ಪಟ್ಟಿವೆ, ಅದನ್ನೂ ಸಹ ನೀನೂ ನನ್ನಿಂದ ಚೆನ್ನಾಗಿ ಕೇಳು. 18.19",

      "ಯಾವ ಜ್ಞಾನದಿಂದ ಮನುಷ್ಯನು ಬೇರೆ-ಬೇರೆಯಾಗಿ ಎಲ್ಲಾ ಪ್ರಾಣಿಗಳಲ್ಲಿ ಒಂದೇ ಅವಿನಾಶಿಯಾದ ಪರಮಾತ್ಮ ಭಾವವನ್ನು ವಿಭಾಗರಹಿತವಾಗಿ ಸಮಭಾವದಿಂದ ಸ್ಥಿತನಾಗಿರುವಂತೆ ನೋಡುತ್ತಾನೋ ಆ ಜ್ಞಾನವನ್ನು ನೀನು ಸಾತ್ವಿಕವೆಂದು ತಿಳಿ  18.20",

      "ಆದರೆ ಯಾವ ಜ್ಞಾನ ಅರ್ಥಾತ್ ಯಾವ ಜ್ಞಾನದ ಮೂಲಕ ಮನುಷ್ಯನು ಸಂಪೂರ್ಣ ಭೂತಗಳಲ್ಲಿ ಭಿನ್ನ-ಭಿನ್ನ ಪ್ರಕಾರದ, ನಾನಾ ಭಾವಗಳನ್ನು ಬೇರೆ ಬೇರೆಯಾಗಿ ತಿಳಿಯುತ್ತಾನೋ ಆ ಜ್ಞಾನವನ್ನು ನೀನು ರಾಜಸವೆಂದು ತಿಳಿದುಕೋ. 18.21",

      "ಆದರೆ ಯಾವ ಜ್ಞಾನವು ಒಂದು ಕಾರ್ಯರೂಪವಾದ ಶರೀರದಲ್ಲಿಯೇ ಸಂಪೂರ್ಣದಂತೆ ಆಸಕ್ತವಾಗಿದೆಯೋ ಹಾಗೂ ಯುಕ್ತಿಯಿಲ್ಲದೆ, ತಾತ್ವಿಕ ಅರ್ಥ ರಹಿತ ಮತ್ತು ತುಚ್ಛವಾಗಿದೆಯೋ - ಅದನ್ನು ತಾಮಸವೆಂದು ಹೇಳಲಾಗಿದೆ. 18.22",

      "ಯಾವ ಕರ್ಮವು ಶಾಸ್ತ್ರವಿಧಿಯಿಂದ ನಿಯಮಿಸಲ್ಪಟ್ಟಿದೆಯೋ, ಮತ್ತು ಕರ್ತೃತ್ವದ ಅಭಿಮಾನದಿಂದ ರಹಿತವಾಗಿದೆಯೋ ಹಾಗೂ ಫಲವನ್ನು ಬಯಸದೆ ಇರುವ ಪುರುಷನ ಮೂಲಕ ರಾಗ-ದ್ವೇಷ-ರಹಿತವಾಗಿ ಮಾಡಲಾಗಿದೆಯೋ ಅದು ಸಾತ್ವಿಕವೆಂದು ಹೇಳಲಾಗುತ್ತದೆ. 18.23",

      "ಆದರೆ ಯಾವ ಕರ್ಮವು ಬಹಳ ಪರಿಶ್ರಮದಿಂದ ಕೂಡಿರುತ್ತದೆಯೋ ಹಾಗೂ ಭೋಗಗಳನ್ನು ಬಯಸುವ ಪುರುಷನ ಮೂಲಕ ಅಥವಾ ಅಹಂಕಾರದಿಂದ ಕೂಡಿದ ಪುರುಷನ ಮೂಲಕ ಮಾಡಲಾಗುತ್ತದೆಯೋ ಆ ಕರ್ಮವು ರಾಜಸವೆಂದು ಹೇಳಲಾಗಿದೆ. 18.24",

      "ಯಾವ ಕರ್ಮವು ಪರಿಣಾಮ, ಹಾನಿ, ಹಿಂಸೆ ಮತ್ತು ಸಾಮರ್ಥ್ಯ ಮೊದಲಾದವುಗಳ ವಿಚಾರಮಾಡದೆ ಕೇವಲ್ ಅಜ್ಞಾನದಿಂದ ಆರಂಭಿಸಲ್ಪಡುತ್ತದೆಯೋ ಅದನ್ನು ತಾಮಸವೆಂದು ಹೇಳಲಾಗುತ್ತದೆ. 18.25",

      "ಯಾವ ಕರ್ತನು ಸಂಗರಹಿತನಾಗಿ, ಅಹಂಕಾರದಿಂದ ಕೂಡಿದ ವಚನವನ್ನು ಆಡದೆ, ಧೈರ್ಯ ಮತ್ತು ಉತ್ಸಾಹದಿಂದ ಕೂಡಿ ಹಾಗೂ ಕಾರ್ಯವು ಸಿದ್ಧವಾಗುವುದು ಮತ್ತು ಆಗದಿರುವುದರಲ್ಲಿ ಹರ್ಷ-ಶೋಕಾದಿ ವಿಕಾರಗಳಿಂದ ರಹಿತನಾಗಿದ್ದಾನೋ ಅವನು ಸಾತ್ವಿಕನೆಂದು ಹೇಳಲ್ಪಡುತ್ತಾನೆ. 18.26",

      "ಯಾವ ಕರ್ತೃವು ಆಸಕ್ತಿಯಿಂದ ಕೂಡಿದವಮಾಗಿ, ಕರ್ಮಗಳ ಫಲವನ್ನು ಬಯಸುವವನೂ ಮತ್ತು ಲೋಭಿಯಾಗಿದ್ದಾನೋ ಹಾಗೂ ಇತರರಿಗೆ ಕಷ್ಟಕೊಡುವ ಸ್ವಭಾವವುಳ್ಳವನಾಗಿ, ಅಶುದ್ಧಾಚಾರಿಯೂ ಮತ್ತು ಹರ್ಷ - ಶೋಕಗಳಿಂದ ಲಿಪ್ತನಾಗಿದ್ದಾನೋ ಅವನು ರಾಜಸನೆಂದು ಹೇಳಲಾಗಿದೆ. 18.27",

      "ಯಾವ ಕರ್ತೃವು ಅಯುಕ್ತನೂ, ಶಿಕ್ಷಣರಹಿತನೂ, ಅಹಂಕಾರಿಯೂ, ಧೂರ್ತನೂ ಮತ್ತು ಇತರರ ಜೀವನವನ್ನು ನಾಶಗೊಳಿಸುವವನೂ ಹಾಗೂ ಶೋಕಿಸುವವನೂ, ಸೋಮಾರಿಯೂ ಮತ್ತು ದೀರ್ಘಸೂತ್ರಿಯೂ ( ಕೆಲಸವನ್ನು ನಿಧಾನವಾಗಿ ಮಾಡುವವನು ) ಆಗಿದ್ದಾನೋ ಅವನನ್ನು ತಾಮಸನೆಂದು ಹೇಳಲಾಗುತ್ತದೆ. 18.28",

      "ಹೇ ಧನಂಜಯನೇ ! ಈಗ ನೀನು ಬುದ್ಧಿಯ ಮತ್ತು ಧೃತಿಯ ಮೂರು ಪ್ರಕಾರದ ಭೇದಗಳನ್ನು ಕೂಡ ಗುಣಗಳಿಗನುಸಾರವಾಗಿ ನನ್ನ ಮೂಲಕ ಸಂಪೂರ್ಣವಾಗಿ, ವಿಭಾಗಪೂರ್ವಕವಾಗಿ ಹೇಳಲ್ಪಡುವುದನ್ನು ಕೇಳು. 18.29",

      "ಹೇ ಪಾರ್ಥನೇ ! ಯಾವ ಬುದ್ಧಿಯು ಪ್ರವೃತ್ತಿಮಾರ್ಗ ಮತ್ತು ನಿವೃತ್ತಿ ಮಾರ್ಗವನ್ನು, ಕರ್ತವ್ಯ ಮತ್ತು ಅಕರ್ತವ್ಯವನ್ನು, ಭಯ ಮತ್ತು ಅಭಯವನ್ನು ಹಾಗೂ ಬಂಧನವನ್ನು ಮತ್ತು ಮೋಕ್ಷವನ್ನು ಯಥಾರ್ಥವಾಗಿ ತಿಳಿಯುತ್ತದೆಯೋ ಆ ಬುದ್ಧಿಯು ಸಾತ್ವಿಕವಾಗಿದೆ. 18.30",

      "ಹೇ ಪಾರ್ಥನೇ ! ಮನುಷ್ಯನು ಯಾವ ಬುದ್ಧಿಯ ಮೂಲಕ ಧರ್ಮವನ್ನೂ ಮತ್ತು ಅಧರ್ಮವನ್ನೂ ಹಾಗೂ ಕರ್ತವ್ಯ ಮತ್ತು ಅಕರ್ತವ್ಯವನ್ನು ಕೂಡ ಯಥಾರ್ಥವಾಗಿ ತಿಳಿಯುವುದಿಲ್ಲವೋ ಆ ಬುದ್ಧಿಯು ರಾಜಸವಾಗಿದೆ. 18.31",

      "ಹೇ ಅರ್ಜುನಾ ! ತಮೋಗುಣದಿಂದ ಆವರಿಸಲ್ಪಟ್ಟ ಯಾವ ಬುದ್ಧಿಯು ಅಧರ್ಮವನ್ನು ಕೂಡ ’ ಇದು ಧರ್ಮವಾಗಿದೆ’ ಎಂದು ತಿಳಿಯುತ್ತದೆಯೋ, ಹಾಗೂ ಇದೇ ಪ್ರಕಾರ ಅನ್ಯ ಸಂಪೂರ್ಣ ಪದಾರ್ಥಗಳನ್ನು ಸಹ ವಿಪರೀತವಾಗಿ ತಿಳಿಯುತ್ತದೆಯೋ ಆ ಬುದ್ಧಿಯು ತಾಮಸೀಯಾಗಿದೆ. 18.32",

      "ಹೇ ಪಾರ್ಥನೇ ! ಯಾವ ಅವ್ಯಭಿಚಾರಿಣಿಯಾದ ಧಾರಣ ಶಕ್ತಿಯಿಂದ ಮನುಷ್ಯನು ಧ್ಯಾನಯೋಗದ ಮೂಲಕ ಮನಸ್ಸು, ಪ್ರಾಣ ಮತ್ತು ಇಂದ್ರಿಯಗಳ ಕ್ರಿಯೆಗಳನ್ನು ಧಾರಣೆ ಮಾಡುತ್ತಾನೆಯೋ ಆ ಧೃತಿಯು ಸಾತ್ವಿಕವಾಗಿದೆ. 18.33",

      "ಆದರೆ ಹೇ ಪೃಥಾಪುತ್ರ ಅರ್ಜುನಾ ! ಫಲದ ಇಚ್ಛೆಯುಳ್ಳ ಮನುಷ್ಯನು ಯಾವ ಧಾರಣಶಕ್ತಿಯ ಮೂಲಕ ಅತ್ಯಂತ ಆಸಕ್ತಿಯಿಂದ ಧರ್ಮ, ಅರ್ಥ ಮತ್ತು ಕಾಮಗಳನ್ನು ಧಾರಣೆ ಮಾಡುತ್ತಾನೋ ಆ ಧಾರಣಶಕ್ತಿಯು ರಾಜಸಿಯಾಗಿದೆ. 18.34",

      "ಹೇ ಪಾರ್ಥನೇ ! ದುಷ್ಟಬುದ್ಧಿಯುಳ್ಳ ಮನುಷ್ಯನು ಯಾವ ಧಾರಣಶಕ್ತಿಯ ಮೂಲಕ ನಿದ್ರೆ, ಭಯ, ಚಿಂತೆ ಮತ್ತು ದುಃಖವನ್ನೂ ಹಾಗೂ ಉನ್ಮತ್ತತೆಯನ್ನೂ ಧಾರಣೆ ಮಾಡಿರುತ್ತಾನೆಯೋ ಆ ಧಾರಣಶಕ್ತಿಯು ತಾಮಸಿಯಾಗಿದೆ. 18.35",

      "ಹೇ ಭರತಶ್ರೇಷ್ಠನೇ ! ಈಗ ಮೂರು ಪ್ರಕಾರದ ಸುಚವನ್ನು ಕೂಡ ನನ್ನಿಂದ ಕೇಳು. ಯಾವ ಸುಖದಲ್ಲಿ ಸಾಧಕನಾದ ಮನುಷ್ಯನು ಭಜನೆ, ಧ್ಯಾನ ಮತ್ತು ಸೇವೆ ಮೊದಲಾದ ಅಭ್ಯಾಸದಿಂದ ರಮಿಸುತ್ತಾನೋ, ಮತ್ತು ಯಾವುದರಿಂದ ದುಃಖದ ಅಂತ್ಯವನ್ನು ಪಡೆಯುತ್ತಾನೆಯೋ, ಯಾವ ಇಂತಹ ಸುಖವಿದೆಯೋ ಅದು ಆರಂಭಕಾಲದಲ್ಲಿ ವಿಷಕ್ಕೆ ಸಮಾನವಾಗಿ ತೋರಿದರೂ ಪರಿಣಾಮದಲ್ಲಿ ಅಮೃತಕ್ಕೇ ಸಮಾನವಾಗಿದೆ. ಆದ್ದರಿಂದ ಆ ಪರಮಾತ್ಮ ವಿಷಯಕವಾದ ಬುದ್ಧಿಯ ಪ್ರಸಾದದಿಂದ ಉಂಟಾಗುವ ಸುಖವನ್ನು ಸಾತ್ವಿಕವೆಂದು ಹೇಳಲಾಗಿದೆ. 18.36",

      "ವಿಷಯ ಮತ್ತು ಇಂದ್ರಿಯಗಳ ಸಂಯೋಗದಿಂದ ಯಾವ ಸುಖವು ಉಂಟಾಗುವುದೋ ಅದು ಮೊದಲ ಅನುಭವ ಕಾಲದಲ್ಲಿ ಅಮೃತಕ್ಕೆ ಸಮಾನವಾಗಿ ಕಂಡರೂ ಸಹ ಅಂತ್ಯದ ಪರಿಣಾಮದಲ್ಲಿ ವಿಷಕ್ಕೆ ಸಮಾನವಾದುದರಿಂದ ಅದು ರಾಜಸವೆಂದು ಹೇಳಲ್ಪಟ್ಟಿದೆ. 18.37",

      "ಯಾವ ಸುಖವು ವಿಷಯ ಮತ್ತು ಇಂದ್ರಿಯಗಳ ಸಂಯೋಗದಿಂದ ಉಂಟಾಗುತ್ತದೆಯೋ ಅದು ಮೊದಲು ಭೋಗಕಾಲದಲ್ಲಿ ಅಮೃತಕ್ಕೆ ಸಮಾನವಾಗಿ ತೋರಿದರೂ ಸಹ ಪರಿಣಾಮದಲ್ಲಿ ವಿಷಕ್ಕೆ ಸಮಾನವಾಗಿದೆ ; ಆದ್ದರಿಂದ ಆ ಸುಖವು ರಾಜಸವೆಂದು ಹೇಳಲ್ಪಟ್ಟಿದೆ. 18.38",

      "ಯಾವ ಸುಖವು ಭೋಗಕಾಲದಲ್ಲಿ ಹಾಗೂ ಪರಿಣಾಮದಲ್ಲಿಯೂ ಕೂಡ ಆತ್ಮವನ್ನು ಮೋಹಗೊಳಿಸುತ್ತದೆಯೋ ಆ ನಿದ್ರಾ, ಆಲಸ್ಯ ಮತ್ತು ಪ್ರಾಮಾದದಿಂದ ಉತ್ಪನ್ನವಾದ ಸುಖವನ್ನು ತಾಮಸವೆಂದು ಹೇಳಲಾಗಿದೆ. 18.39",

      "ಪೃಥ್ವಿಯಲ್ಲಿ ಅಥವಾ ಆಕಾಶದಲ್ಲಿ ಅಥವಾ ದೇವತೆಗಳಲ್ಲಿ ಹಾಗೂ ಇವಲ್ಲದೆ ಮತ್ತೆಲ್ಲಿಯಾದರೂ ಯಾವ ಪ್ರಕೃತಿಯಿಂದ ಉತ್ಪನ್ನವಾದ ಈ ಮೂರು ಗುಣಗಳಿಂದ ರಹಿತವಾದಿರುವಂತಹ ಸತ್ವವು ಇಲ್ಲವಾಗಿದೆ. 18.40",

      "ಹೇ ಪರಂತಪನೆ ! ಬ್ರಾಹ್ಮಣ, ಕ್ಷತ್ರಿಯ ಮತ್ತು ವೈಶ್ಯರ ಹಾಗೂ ಶೂದ್ರರ ಕರ್ಮಗಳು ಸ್ವಭಾವದಿಂದ ಉತ್ಪನ್ನವಾದ ಗುಣಗಳ ಅನುಸಾರವಾಗಿ ವಿಭಾಗಿಸಲ್ಪಟ್ಟಿವೆ. 18.41",

      "ಅಂತಃಕರಣವನ್ನು ನಿಗ್ರಹಿಸುವುದು; ಇಂದ್ರಿಯಗಳ ದಮನಮಾಡುವುದು; ಧರ್ಮಪಾಲನೆಗಾಗಿ ಕಷ್ಟವನ್ನು ಸಹಿಸುವುದು; ಹೊರಗೊಳಗಿಂದ ಶುದ್ಧವಾಗಿರುವುದು; ಬೇರೆಯವರ ಅಪರಾಧಗಳನ್ನು ಕ್ಷಮಿಸುವುದು; ಮನಸ್ಸು ಇಂದ್ರಿಯಗಳು ಮತ್ತು ಶರೀರವನ್ನು ಸರಳವಾಗಿರಿಸುವುದು; ವೇದ, ಶಾಸ್ತ್ರ , ದೇವರು ಮತ್ತು ಪರಲೋಕಾದಿಗಳಲ್ಲಿ ಶ್ರದ್ಧೆಯನ್ನಿಡುವುದು; ವೇದಶಾಸ್ತ್ರಗಳ ಅಧ್ಯಯನ ಮತ್ತು ಅಧ್ಯಾಪನ ಮಾಡುವುದು ಮತ್ತು ಪರಮಾತ್ಮ ತತ್ವದ ಅನುಭವ ಪಡೆಯುವುದು - ಇವೆಲ್ಲವೂ ಬ್ರಾಹ್ಮಣನ ಸ್ವಾಭಾವಿಕವಾದ ಕರ್ಮಗಳಾಗಿವೆ. 18.42",

      "ಶೌರ್ಯ, ವೀರತೆ, ತೇಜ, ಧೈರ್ಯ, ದಕ್ಷತೆ ಮತ್ತು ಯುದ್ಧದಲ್ಲಿ ಹೆಚ್ಚಿನ ಸಂಕಟ ಉಂಟಾದರೂ ಓಡಿಹೋಗದಿರುವುದು, ದಾನವನ್ನು ಕೊಡುವುದು ಮತ್ತು ಸ್ವಾಮಿಭಾವ ಹೀಗೆ ಇವೆಲ್ಲವೂ ಕ್ಷತ್ರಿಯನ ಸ್ವಾಭಾವಿಕವಾದ ಕರ್ಮಗಳಾಗಿವೆ. 18.43",

      "ಕೃಷಿ, ಗೋಪಾಲನೆ ಮತ್ತು ಕ್ರಯ -ವಿಕ್ರಯರೂಪವಾದ ಸತ್ಯವ್ಯವಹಾರ - ಇವು ವೈಶ್ಯರ ಸ್ವಾಭಾವಿಕ ಕರ್ಮಗಳಾಗಿವೆ. ಹಾಗೂ ಶೂದ್ರನಿಗೂ ಕೂಡ ಎಲ್ಲ ವರ್ಣಗಳ ಸೇವೆ ಮಾಡುವುದೇ ಸ್ವಾಭಾವಿಕ ಕರ್ಮವಾಗಿದೆ. 18.44",

      "ತಮ್ಮ-ತಮ್ಮ ಸ್ವಾಭಾವಿಕವಾದ ಕರ್ಮಗಳಲ್ಲಿ ತತ್ಪರತೆಯಿಂದ ತೊಡಗಿರುವ ಮನುಷ್ಯನು ಭಗವತ್ಪ್ರಾಪ್ತಿರೂಪೀ ಪರಮ ಸಿದ್ಧಿಯನ್ನುಜ್ ಪಡೆಯುತ್ತಾನೆ. ತನ್ನ ಸ್ವಾಭಾವಿಕ ಕರ್ಮದಲ್ಲಿ ತೊಡಗಿರುವ ಮನುಷ್ಯನು ಯಾವ ಪ್ರಕಾರದಿಂದ ಕರ್ಮಗೈದು ಪರಮಸಿದ್ಧಿಯನ್ನು ಪಡೆಯುತ್ತಾನೆ, ಆ ವಿಧಿಯನ್ನು ನೀನು ಕೇಳು. 18.45",

      "ಯಾವ ಪರಮೇಶ್ವರನಿಂದ ಸಂಪೂರ್ಣ ಪ್ರಾಣಿಗಳ ಉತ್ಪತ್ತಿಯಾಗಿದೆಯೋ ಮತ್ತು ಯಾರಿಂದ ಈ ಸಮಸ್ತ ಜಗತ್ತು ವ್ಯಾಪ್ತವಾಗಿದೆಯೋ, ಆ ಪರಮೇಶ್ವರನನ್ನು ತನ್ನ ಸ್ವಾಭಾವಿಕ ಕರ್ಮಗಳ ಮೂಲಕ ಪೂಜಿಸಿ ಮನುಷ್ಯನು ಪರಮ ಸಿದ್ಧಿಯನ್ನು ಪಡೆಯುತ್ತಾನೆ. 18.46",

      "ಒಳ್ಳೆಯ ರೀತಿಯಲ್ಲಿ ಆಚರಿಸಲಾದ ಬೇರೆಯವರ ಧರ್ಮಕ್ಕಿಂತ ಗುಣರಹಿತವಾದರೂ ಕೂಡ ತನ್ನ ಧರ್ಮವು ಶ್ರೇಷ್ಠವಾಗಿದೆ. ಏಕೆಂದರೆ ಸ್ವಭಾವದಿಂದ ನಿಯಮಿಸಲ್ಪಟ್ಟಿರುವ, ಸ್ವಧರ್ಮರೂಪೀ ಕರ್ಮವನ್ನು ಮಾಡುತ್ತಾ ಇರುವ ಮನುಷ್ಯನು ಪಾಪವನ್ನು ಪಡೆಯುವುದಿಲ್ಲ. 18.47",

      "ಆದ್ದರಿಂದ, ಹೇ ಕುಂತೀಪುತ್ರನೇ ! ದೋಷಯುಕ್ತವಾದರೂ ಕೂಡ ಸಹಜವಾದ ಕರ್ಮವನ್ನು ತ್ಯಜಿಸಬಾರದು; ಏಕೆಂದರೆ ಅಗ್ನಿಯು ಹೊಗೆಯಿಂದ ಯುಕ್ತವಾಗಿರುವಂತೆ ಎಲ್ಲ ಕರ್ಮಗಳೂ ಒಂದಲ್ಲ ಒಂದು ದೋಷದಿಂದ ಕೂಡಿರುತ್ತವೆ. 18.48",

      "ಸರ್ವತ್ರ ಆಸಕ್ತಿರಹಿತವಾದ ಬುದ್ಧಿಯುಳ್ಳವನು, ಸ್ಪೃಹಾ ರಹಿತನೂ ಮತ್ತು ಗೆಲ್ಲಲ್ಪಟ್ಟ ಅಂತಃಕರಣವುಳ್ಳ ಪುರುಷನು ಸಾಂಖ್ಯಯೋಗದ ಮೂಲಕ ಆ ಪರಮ ನೈಷ್ಕರ್ಮ್ಯಸಿದ್ಧಿಯನ್ನು ಪಡೆಯುತ್ತಾನೆ. 18.49",

      "ಯಾವ ಜ್ಞಾನಯೋಗದ ಶ್ರೇಷ್ಠನಿಷ್ಠೆಯಾಗಿದೆಯೋ ಆ ನೈಷ್ಕರ್ಮ್ಯಸಿದ್ಧಿಯನ್ನು ಯಾವ ಪ್ರಕಾರವಾಗಿ ಪಡೆದುಕೊಂಡ ಮನುಷ್ಯನು ಬ್ರಹ್ಮವನ್ನೂ ಪಡೆಯುತ್ತಾನೋ ಆ ಪ್ರಕಾರವನ್ನು ಹೇ ಕುಂತೀಪುತ್ರನೇ ! ನೀನು ಸಂಕ್ಷೇಪವಾಗಿಯೇ ನನ್ನಿಂದ ತಿಳಿದುಕೊ. 18.50",

      "ವಿಶುದ್ಧವಾದ ಬುದ್ಧಿಯಿಂದ ಕೂಡಿದವನಾಗಿ ಹಾಗೂ ಹಗುರವಾದ, ಸಾತ್ವಿಕ ಮತ್ತು ನಿಯಮಿತ ಭೋಜನವನ್ನು ಮಾಡುವವನೂ, ಶಬ್ದವೇ ಮೊದಲಾದ ವಿಷಯಗಳನ್ನು ತ್ಯಾಗಗೈದು ಏಕಾಂತ ಮತ್ತು ಶುದ್ಧವಾದ ದೇಶದಲ್ಲಿ ವಾಸ ಮಾಡುವವನೂ, ಸಾತ್ವಿಕ ಧಾರಣಶಕ್ತಿಯ ಮೂಲಕ ಅಂತಃಕರಣ ಮತ್ತು ಇಂದ್ರಿಯಗಳನ್ನು ಸಂಯಮದಲ್ಲಿರಿಸಿಕೊಂಡು, ಮನಸ್ಸು, ವಾಣಿ ಮತ್ತು ಶರೀರವನ್ನು ವಶದಲ್ಲಿರಿಸಿಕೊಳ್ಳುವವನೂ, ರಾಗ- ದ್ವೇಷಗಳನ್ನು ಸರ್ವಥಾ ನಾಶಗೊಳಿಸಿ, ಚೆನ್ನಾದ ದೃಢವಾದ ವೈರಾಗ್ಯವನ್ನು ಆಶ್ರಯಿಸುವವನೂ ಹಾಗೂ ಅಹಂಕಾರ, ಬಲ, ದರ್ಪ, ಕಾಮ, ಕ್ರೋಧ ಮತ್ತು ಪರಿಗ್ರಹವನ್ನು ತ್ಯಾಗಮಾಡಿ, ನಿರಂತರ ಧ್ಯಾನಯೋಗ ಪರಾಯಣನಾಗಿರುವ ಮಮತಾರಹಿತ ಶಾಂತಿಯುಕ್ತ ಪುರುಷನು, ಸಚ್ಚಿದಾನಂದ ಬ್ರಹ್ಮನಲ್ಲಿ ಅಭಿನ್ನಭಾವದಿಂದ ಸ್ಥಿತನಾಗಿರಲು ಪಾತ್ರನಾಗುತ್ತಾನೆ. (18.51-18.53)",

      "ವಿಶುದ್ಧವಾದ ಬುದ್ಧಿಯಿಂದ ಕೂಡಿದವನಾಗಿ ಹಾಗೂ ಹಗುರವಾದ, ಸಾತ್ವಿಕ ಮತ್ತು ನಿಯಮಿತ ಭೋಜನವನ್ನು ಮಾಡುವವನೂ, ಶಬ್ದವೇ ಮೊದಲಾದ ವಿಷಯಗಳನ್ನು ತ್ಯಾಗಗೈದು ಏಕಾಂತ ಮತ್ತು ಶುದ್ಧವಾದ ದೇಶದಲ್ಲಿ ವಾಸ ಮಾಡುವವನೂ, ಸಾತ್ವಿಕ ಧಾರಣಶಕ್ತಿಯ ಮೂಲಕ ಅಂತಃಕರಣ ಮತ್ತು ಇಂದ್ರಿಯಗಳನ್ನು ಸಂಯಮದಲ್ಲಿರಿಸಿಕೊಂಡು, ಮನಸ್ಸು, ವಾಣಿ ಮತ್ತು ಶರೀರವನ್ನು ವಶದಲ್ಲಿರಿಸಿಕೊಳ್ಳುವವನೂ, ರಾಗ- ದ್ವೇಷಗಳನ್ನು ಸರ್ವಥಾ ನಾಶಗೊಳಿಸಿ, ಚೆನ್ನಾದ ದೃಢವಾದ ವೈರಾಗ್ಯವನ್ನು ಆಶ್ರಯಿಸುವವನೂ ಹಾಗೂ ಅಹಂಕಾರ, ಬಲ, ದರ್ಪ, ಕಾಮ, ಕ್ರೋಧ ಮತ್ತು ಪರಿಗ್ರಹವನ್ನು ತ್ಯಾಗಮಾಡಿ, ನಿರಂತರ ಧ್ಯಾನಯೋಗ ಪರಾಯಣನಾಗಿರುವ ಮಮತಾರಹಿತ ಶಾಂತಿಯುಕ್ತ ಪುರುಷನು, ಸಚ್ಚಿದಾನಂದ ಬ್ರಹ್ಮನಲ್ಲಿ ಅಭಿನ್ನಭಾವದಿಂದ ಸ್ಥಿತನಾಗಿರಲು ಪಾತ್ರನಾಗುತ್ತಾನೆ. (18.51-18.53)",

      "ವಿಶುದ್ಧವಾದ ಬುದ್ಧಿಯಿಂದ ಕೂಡಿದವನಾಗಿ ಹಾಗೂ ಹಗುರವಾದ, ಸಾತ್ವಿಕ ಮತ್ತು ನಿಯಮಿತ ಭೋಜನವನ್ನು ಮಾಡುವವನೂ, ಶಬ್ದವೇ ಮೊದಲಾದ ವಿಷಯಗಳನ್ನು ತ್ಯಾಗಗೈದು ಏಕಾಂತ ಮತ್ತು ಶುದ್ಧವಾದ ದೇಶದಲ್ಲಿ ವಾಸ ಮಾಡುವವನೂ, ಸಾತ್ವಿಕ ಧಾರಣಶಕ್ತಿಯ ಮೂಲಕ ಅಂತಃಕರಣ ಮತ್ತು ಇಂದ್ರಿಯಗಳನ್ನು ಸಂಯಮದಲ್ಲಿರಿಸಿಕೊಂಡು, ಮನಸ್ಸು, ವಾಣಿ ಮತ್ತು ಶರೀರವನ್ನು ವಶದಲ್ಲಿರಿಸಿಕೊಳ್ಳುವವನೂ, ರಾಗ- ದ್ವೇಷಗಳನ್ನು ಸರ್ವಥಾ ನಾಶಗೊಳಿಸಿ, ಚೆನ್ನಾದ ದೃಢವಾದ ವೈರಾಗ್ಯವನ್ನು ಆಶ್ರಯಿಸುವವನೂ ಹಾಗೂ ಅಹಂಕಾರ, ಬಲ, ದರ್ಪ, ಕಾಮ, ಕ್ರೋಧ ಮತ್ತು ಪರಿಗ್ರಹವನ್ನು ತ್ಯಾಗಮಾಡಿ, ನಿರಂತರ ಧ್ಯಾನಯೋಗ ಪರಾಯಣನಾಗಿರುವ ಮಮತಾರಹಿತ ಶಾಂತಿಯುಕ್ತ ಪುರುಷನು, ಸಚ್ಚಿದಾನಂದ ಬ್ರಹ್ಮನಲ್ಲಿ ಅಭಿನ್ನಭಾವದಿಂದ ಸ್ಥಿತನಾಗಿರಲು ಪಾತ್ರನಾಗುತ್ತಾನೆ. (18.51-18.53)",

      "ಮತ್ತೆ ಆ ಸಚ್ಚಿದಾನಂದಘನ ಬ್ರಹ್ಮನಲ್ಲಿ ಸ್ಥಿತವಾದ, ಪ್ರಸನ್ನ ಮನಸ್ಸುಳ್ಳ ಯೋಗಿಯು ಯಾರಿಗಾಗಿಯೂ ಶೋಕಿಸುವುದಿಲ್ಲ ಮತ್ತು ಯಾವುದನ್ನೂ ಬಹಸುವುದಿಲ್ಲ. ಇಂತಹ ಸಮಸ್ತ ಪ್ರಾಣಿಗಳಲ್ಲಿ ಸಮಭಾವವುಳ್ಳ ಯೋಗಿಯು ನನ್ನ ಪರಾಭಕ್ತಿಯನ್ನು ಪಡೆಯುತ್ತಾನೆ. 18.54",

      "ಆ ಪರಾಭಕ್ತಿಯ ಮೂಲಕ ಅವನು ಪರಮಾತ್ಮನಾದ ನನ್ನನ್ನು ನಾನು ಯಾರಾಗಿದ್ದೇನೋ ಮತ್ತು ಯಾವ ಪ್ರಭಾವದಿಂದ ಯುಕ್ತನಾಗಿದ್ದೇನೋ, ಸರಿಯಾಗಿ ಹೇಗಿರುವೆನೋ ಹಾಗೆ ತತ್ವದಿಂದ ತಿಳಿಯುತ್ತಾನೆ; ಹಾಗೂ ಆ ಭಕ್ತಿಯಿಂದ ನನ್ನನ್ನು ತತ್ವದಿಂದ ತಿಳಿಸುಕೊಂಡು ತತ್ಕಾಲದಲ್ಲಿಯೇ ನನ್ನಲ್ಲಿ ಪ್ರವೇಶ ಮಾಡಿಬಿಡುತ್ತಾನೆ. 18.55",

      "ನನ್ನ ಪರಾಯಣನಾದ ಕರ್ಮ ಯೋಗಿಯು ಸಂಪೂರ್ಣ ಕರ್ಮಗಳನ್ನು ಯಾವಾಗಲೂ ಮಾಡುತ್ತಿದ್ದರೂ ಕೂಡ ನನ್ನ ಕೃಪೆಯಿಂದ ಸನಾತನ, ಅವಿನಾಶಿಯಾದ ಪರಮಪದವನ್ನು ಪಡೆಯುತ್ತಾನೆ. 18.56",

      "ಎಲ್ಲ ಕರ್ಮಗಳನ್ನು ಮನ್ಃಪೂರ್ವಕವಾಗಿ ನನ್ನಲ್ಲಿ ಅರ್ಪಣೆ ಮಾಡಿ ಹಾಗೂ, ಸಮತ್ವ ಬುದ್ಧಿರೂಪವಾದ ಯೋಗವನ್ನು ಅವಲಂಬಿಸಿಕೊಂಡು ನನ್ನ ಪರಾಯಣನಾಗಿ ಮತ್ತು ನಿರಂತರ ನನ್ನಲ್ಲಿ ಚಿತ್ತವುಳ್ಳವನಾಗು. 18.57",

      "ಮೇಲೆ ಹೇಳಿದ ಪ್ರಕಾರದಿಂದ ನನ್ನಲ್ಲಿ ಚಿತ್ತವುಳ್ಳವನಾಗಿ ನೀನು ನನ್ನ ಕೃಪೆಯಿಂದ ಸಮಸ್ತ ಸಂಕಟಗಳನ್ನು ಅನಾಯಾಸವಾಗಿಯೇ ದಾಟಿಬಿಡುವೆ ಮತ್ತು ಒಂದು ವೇಳೆ ಅಹಂಕಾರದ ಕಾರಣದಿಂದ ನನ್ನ ವಚನಗಳನ್ನು ಕೇಳದಿದ್ದರೆ ನಾಶವಾಗುವೆ ಅರ್ಥಾತ್ ಪರಮಾರ್ಥದಿಂದ ಭ್ರಷ್ಟನಾಗಿ ಹೋಗುವೆ. 18.58",

      "ನೀನು ಯಾವ ಅಹಂಕಾರದ ಆಶ್ರಯ ಪಡೆದು ’ನಾನು ಯುದ್ಧ ಮಾಡುವುದಿಲ್ಲ’ ಎಂಬುದಾಗಿ ನಿಶ್ಚಯ ಮಾಡಿರುವೆಯೋ, ನಿನ್ನ ಈ ನಿಶ್ಚಯವು ಮಿಥ್ಯೆಯಾಗಿದೆ; ಏಕೆಂದರೆ ನಿನ್ನ ಸ್ವಭಾವವು ನಿನ್ನನ್ನು ಬಲವಂತವಾಗಿ ಯುದ್ಧದಲ್ಲಿ ತೊಡಗಿಸುವುದು. 18.59",

      "ಹೇ ಕುಂತೀಪುತ್ರನೇ ! ಯಾವ ಕರ್ಮವನ್ನು ನೀನು ಮೋಹದ ಕಾರಣದಿಂದಾಗಿ ಮಾಡಲು ಇಚ್ಛಿಸುವುದಿಲ್ಲವೋ ಅದನ್ನು ಸಹ ನಿನ್ನ ಪೂರ್ವಕೃತ ಸ್ವಾಭಾವಿಕ ಕರ್ಮದಿಂದ ಬಂಧಿಸಲ್ಪಟ್ಟು, ಪರವಶನಾಗಿ ಮಾಡುವೆ. 18.60",

      "ಹೇ ಅರ್ಜುನಾ ! ಶರೀರರೂಪೀ ಯಂತ್ರದಲ್ಲಿ ಆರೂಢನಾಗಿರುವ, ಸಂಪೂರ್ಣ ಪ್ರಾಣಿಗಳನ್ನು ಅಂತರ್ಯಾಮಿ ಪರಮೇಶ್ವರನು ತನ್ನ ಮಾಯೆಯಿಂದ ಅವರ ಕರ್ಮಗಳನುಸಾರವಾಗಿ ಅಲೆಸುತ್ತ ಎಲ್ಲ ಪ್ರಾಣಿಗಳ ಹೃದಯದಲ್ಲಿ ಸ್ಥಿತನಾಗಿದ್ದಾನೆ. 18.61",

      "ಹೇ ಅರ್ಜುನಾ! ನೀನು ಎಲ್ಲಾ ಪ್ರಕಾರದಿಂದಲೂ ಆ ಪರಮೇಶ್ವರನಲ್ಲಿಯೇ ಶರಣು ಹೋಗು. ಆ ಪರಮಾತ್ಮನ ಕೃಪೆಯಿಂದಲೇ ನೀನು ಪರಮ ಶಾಂತಿಯನ್ನು ಹಾಗೂ ಸನಾತನವಾದ ಪರಮಧಾಮವನ್ನು ಪಡೆಯುವೆ. 18.62",

      "ಈ ಪ್ರಕಾರವಾಗಿ ಈ ಗೋಪನೀಯಕ್ಕಿಂತಲೂ ಅತಿ ಗೋಪನೀಯವಾದ ಜ್ಞಾನವನ್ನು ನಾನು ನಿನಗೆ ಹೇಳಿಬಿಟ್ಟೆನು. ಈಗ ನೀನು ಈ ರಹಸ್ಯಯುಕ್ತವಾದ ಜ್ಞಾನವನ್ನು ಪೂರ್ಣವಾಗಿ ಚೆನ್ನಾಗಿ ವಿಚಾರ ಮಾಡಿ ಹೇಗೆ ಇಷ್ಟಪಡುವೆಯೋ ಹಾಗೆಯೇ ಮಾಡು. 18.63",

      "ಸಂಪೂರ್ಣವಾದ ಗೋಪನೀಯಕ್ಕಿಂತ ಅತಿ ಗೋಪನೀಯವಾದ ನನ್ನ ಪರಮ ರಹಸ್ಯಯುಕ್ತವಾದ ವಚನವನ್ನು ನೀನು ಇನ್ನೊಮ್ಮೆ ಕೇಳು. ನೀನು ನನಗೆ ಅತಿಶಯವಾಗಿ ಪ್ರಿಯನಾಗಿರುವೆ, ಆದುದರಿಂದ ಈ ಪರಮ ಹಿತಕಾರವಾದ ವಚನವನ್ನು ನಾನು ನಿನಗೆ ಹೇಳುವೆನು. 18.64",

      "ಹೇ ಅರ್ಜುನಾ ! ನೀನು ನನ್ನಲ್ಲಿ ಮನಸ್ಸುಳ್ಳವನಗು, ನನ್ನ ಭಕ್ತನಾಗು, ನನ್ನನ್ನು ಪುಜಿಸುವವನಾಗು ಮತ್ತು ನನಗೆ ಪ್ರಣಾಮ ಮಾಡು. ಹೀಗೆ ಮಾಡುವುದರಿಂದ ನೀನು ನನ್ನನ್ನೇ ಪಡೆಯುವೆ. ಇದನ್ನು ನಾನು ನಿನ್ನಲ್ಲಿ ಸತ್ಯವಾಗಿ ಪ್ರತಿಜ್ಞೆಮಾಡಿ ಹೇಳುತ್ತೇನೆ; ಏಕೆಂದರೆ ನೀನು ನನಗೆ ಅತ್ಯಂತ ಪ್ರಿಯನಾಗಿರುವೆ. 18.65",

      "ಸಂಪೂರ್ಣ ಧರ್ಮಗಳನ್ನು ಅರ್ಥಾತ್ ಸಮಸ್ತ ಕರ್ತವ್ಯ- ಕರ್ಮಗಳನ್ನು ನನ್ನಲ್ಲಿ ತ್ಯಾಗಮಾಡಿ ( ಸಮರ್ಪಿಸಿ ) ನೀನು ಕೇವಲ, ಸರ್ವಶಕ್ತಿವಂತ, ಸರ್ವಾಧಾರ ಪರಮೇಶ್ವರನಾದ ನನ್ನೋರ್ವನಿಗೇ ಶರಣು ಬಂದುಬಿಡು. ನಾನು ನಿನ್ನನ್ನು ಸಮಸ್ತ ಪಾಪಗಳಿಂದ ಮುಕ್ತನಾಗಿಸುವೆ. ನೀನು ಶೋಕ ಮಾಡಬೇಡ. 18.66",

      "ನೀನು ಈ ಗೀತಾರೂಪವಾದ ರಹಸ್ಯಮಯ ಉಪದೇಶವನ್ನು ಯಾವ ಕಾಲದಲ್ಲಿಯೂ ತಪರಹಿತನಾದ ಮನುಷ್ಯನಾಗಲೀ, ಭಕ್ತಿರಹಿತನಾದವನಿಗಾಗಲೀ ಮತ್ತು ಕೇಳಲು ಇಚ್ಛೆಯಿಲ್ಲದಿರುವವನಿಗಾಗಲೀ ಹೇಳಬಾರದು, ಹಾಗೂ ಯಾರು ನನ್ನಲ್ಲಿ ದೋಷದೃಷ್ಟಿಯನ್ನು ಇಡುತ್ತಾರೋ ಅವನಿಗಂತೂ ಎಂದಿಗೂ ಹೇಳಬಾರದು. 18.67",

      "ಯಾವ ಪುರುಷನು ನನ್ನಲ್ಲಿ ಪರಮ ಪ್ರೇಮವನ್ನಿಟ್ಟುಕೊಂಡು ಈ ಪರಮ, ರಹಸ್ಯಯುಕ್ತವಾದ, ಗೀತಾಶಾಸ್ತ್ರವನ್ನು ನನ್ನ ಭಕ್ತರಲ್ಲಿ ಹೇಳುವನೋ ಅವನು ನನ್ನನ್ನೇ ಪಡೆಯುತ್ತಾನೆ- ಇದರಲ್ಲಿ ಯಾವ ಸಂದೇಹವೂ ಇಲ್ಲ. 18.68",

      "ಅವನಿಗಿಂತ ಹೆಚ್ಚಾಗಿ ನನ್ನ ಪ್ರಿಯಕಾರ್ಯ ಮಾಡುವವನು ಮನುಷ್ಯರಲ್ಲಿ ಯಾರೂ ಇಲ್ಲ ಹಾಗೂ ಭೂಮಂಡಲದಲ್ಲಿ ಅವನಿಗಿಂತ ಮಿಗಿಲಾಗಿ ಪ್ರಿಯನಾದವನು ನನಗೆ ಬೇರೆ ಯಾರೂ ಭವಿಷ್ಯದಲ್ಲಿ ಆಗಲಾರರು. 18.69",

      "ಯಾವ ಪುರುಷನು ಈ ಧರ್ಮಮಯವಾದ ನಮ್ಮಿಬ್ಬರ ಸಂವಾದರೂಪವಾದ ಗೀತಾಶಾಸ್ತ್ರವನ್ನು ಓದುವನೋ ಅವನ ಮೂಲಕವೂ ಸಹ ನನೌ ಜ್ಞಾನಯಜ್ಞದಿಂದ ಪೂಜಿತನಾಗುವೆನು. 18.70",

      "ಯಾವ ಮನುಷ್ಯನು ಶ್ರದ್ಧಾಯುಕ್ತನಾಗಿ ಮತ್ತು ದೋಷದೃಷ್ಟಿಯಿಂದ ರಹಿತನಾಗಿ ಈ ಗೀತಾಶಾಸ್ತ್ರದ ಶ್ರವಣ ಮಾಡುವನೋ ಅವನೂ ಸಹ ಪಾಪಗಳಿಂದ ಮುಕ್ತನಾಗಿ ಉತ್ತಮ ಕರ್ಮಮಾಡುವವರ ಶ್ರೇಷ್ಠವಾದ ಲೋಕಗಳನ್ನು ಹೊಂದುವನು. 18.71",

      "ಹೇ ಪಾರ್ಥನೇ !  ಈ ಗೀತಾಶಾಸ್ತ್ರವನ್ನು ನೀನು ಏಕಾಗ್ರಚಿತ್ತನಾಗಿ ಶ್ರವಣ ಮಾಡಿದೆಯಾ ? ಮತ್ತು ಹೇ ಧನಂಜಯನೇ !  ಅಜ್ಞಾನದಿಂದ ಉಂಟಾದ ನಿನ್ನ ಮೋಹವು ನಾಶವಾಯಿತೇ ? 18.72",

      "ಅರ್ಜುನನು ಹೇಳಿದನು - ಹೇ ಅಚ್ಯುತಾ ! ನಿನ್ನ ಕೃಪೆಯಿಂದ ನನ್ನ ಮೋಹವು ನಾಶವಾಗಿ ಹೋಯಿತು ಮತ್ತು ನಾನು ಸ್ಮೃತಿಯನ್ನು ಪಡೆದುಕೊಂಡಿದ್ದೇನೆ. ಈಗ ನಾನು ಸಂಶಯರಹಿತನಾಗಿ ಸ್ಥಿತನಾಗಿದ್ದೇನೆ.  ಆದ್ದರಿಂದ ನಿನ್ನ ಆಜ್ಞೆಯನ್ನು ಪಾಲನೆ ಮಾಡುವೆನು. 18.73",

      "ಸಂಜಯನು ಹೇಳಿದನು - ಹೇ ರಾಜಾ ! ಈ ಪ್ರಕಾರವಾಗಿ ನಾನು ಶ್ರೀ ವಾಸುದೇವನ ಮತ್ತು ಮಹಾತ್ಮನಾದ ಅರ್ಜುನನ ಈ ಅಧ್ಬುತ ರಹಸ್ಯ ಯುಕ್ತವಾದ ರೋಮಾಂಚಕರವಾದ ಸಂವಾದವನ್ನು ಕೇಳಿದೆನು. 18.74",

      "ಶ್ರೀ ವ್ಯಾಸರ ಕೃಪೆಯಿಂದ ದಿವ್ಯದೃಷ್ಟಿಯನ್ನು ಪಡೆದುಕೊಂಡು ನಾನು ಈ ಪರಮ ಗೋಪನೀಯವಾದ ಯೋಗವನ್ನು ಸ್ವಯಂ ಯೋಗೇಶ್ವರ ಭಗವಾನ್ ಶ್ರೀಕೃಷ್ಣನಿಂದ ಅರ್ಜುನನಿಗೆ ಹೇಳುತ್ತಿರುವಂತೆ ಪ್ರತ್ಯಕ್ಷವಾಗಿ ಕೇಳಿದ್ದೇನೆ. 18.75",

      "ಹೇ ರಾಜಾ! ಭಗವಾನ್ ಶ್ರೀ ಕೃಷ್ಣ ಮತ್ತು ಅರ್ಜುನರ ಈ ರಹಸ್ಯಯುಕ್ತವಾದ, ಕಲ್ಯಾಣಕರವಾದ ಮತ್ತು ಅದ್ಭುತವಾದ ಸಂವಾದವನ್ನು ಪುನಃ ಪುನಃ ಸ್ಮರಿಸಿಕೊಂಡು ನಾನು ಪದೇ ಪದೇ ಹರ್ಷಿತನಾಗುತ್ತಿದ್ದೇನೆ. 18.76",

      "ಹೇ ರಾಜಾ ! ಶ್ರೀಹರಿಯ ಆ ಅತ್ಯಂತ, ವಿಲಕ್ಷಣವಾದ ರೂಪವನ್ನೂ ಕೂಡ ಪುನಃ ಪುನಃ ಸ್ಮರಿಸಿಕೊಂಡು ನನ್ನ ಚಿತ್ತದಲ್ಲಿ ಮಹಾನ್ ಆಶ್ಚರ್ಯವಾಗುತ್ತದ್ ಮತ್ತು ನಾನು ಬಾರಿ ಬಾರಿಗೂ ಹರ್ಷಿನನಾಗುತ್ತಿದ್ದೇನೆ. 18.77",

      "ಹೇ ರಾಜಾ ! ಎಲ್ಲಿ ಯೋಗೇಶ್ವರನಾದ ಭಗವಾನ್ ಶ್ರ‍ೀಕೃಷ್ಣನಿದ್ದಾನೋ ಮತ್ತು ಎಲ್ಲಿ ಗಾಂಡೀವ ಧನುರ್ಧಾರಿಯಾದ ಅರ್ಜುನನಿದ್ದಾನೋ ಅಲ್ಲಿಯೇ ಶ್ರೀ, ವಿಜಯ, ವಿಭೂತಿ ಮತ್ತು ಅಚಲವಾದ ನೀತಿಯು ಇದೆ - ಎಂಬುದೇ ನನ್ನ ಮತವಾಗಿದೆ. 18.78",

      "ಇತಿ ಶ್ರೀಮದ್ಭಗವದ್ಗೀತಾರೂಪೀ ಉಪನಿಷದ್ ಬ್ರಹ್ಮವಿದ್ಯಾ ಹಾಗೂ ಯೋಗಶಾಸ್ತ್ರ ವಿಷಯಕ ಶ್ರೀ ಕೃಷ್ಣ ಮತ್ತು ಅರ್ಜುನರ ಸಂವಾದದಲ್ಲಿ ಮೋಕ್ಷಸಂನ್ಯಾಸಯೋಗ ಎಂಬ ಹದಿನೆಂಟನೇ ಅಧ್ಯಾಯ ಸಂಪೂರ್ಣ"
    ]
    }
  ]
  
      export default GeetajiKannada