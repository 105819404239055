const TextMalayalam = {
Home:
    {
    WelMsg: "ലേൺ ഗീത കണ്ഠസ്ഥീകരണ പരിശീലന പേജ്",
    chooseLang: "നിങ്ങളുടെ ഭാഷ തിരഞ്ഞെടുക്കുക:",
    greetMsg: "ഗീത പരിവാറിലേക്ക് നിങ്ങൾക്ക് സ്വാഗതം",
    linkBasic1: "പൊതു പരിശീലനം",
    linkBasic2:"ചരണാനുസരണം",
    startPractice: "പരിശീലനം ആരംഭിക്കുക",
    linkAdv1:"ആദ്യ ലെവൽ പരിശീലനം",
    linkAdv2:"ശ്ലോക നമ്പർ",
    linkSerialWise: "സീരിയലായി പഠിക്കുക",
    alert: "ഈ ഭാഷ നിലവിൽ ലഭ്യമല്ല"
    },
Practice:
   {
    WelMsg: "ശ്രീമദ്ഭഗവദ്ഗീത മനഃപാഠ സഹായ പേജ്",
    selAdh: "ഒന്നോ അതിലധികമോ അധ്യായങ്ങൾ തിരഞ്ഞെടുക്കുക:",
    or: "അഥവാ",
    selectAll: "എല്ലാ അധ്യായയും തിരഞ്ഞെടുക്കുക",
    selectdAdh : "തിരഞ്ഞെടുത്ത അധ്യായം",
    clkBtn: "ക്രമരഹിതമായ ശ്ലോകനമ്പർ ലഭിക്കാൻ ബട്ടൺ ക്ലിക്ക് ചെയ്യുക",
    alert: "ദയവായി ഒരു അധ്യായമെങ്കിലും തിരഞ്ഞെടുക്കുക",
    meaningMsg: "ഈ ശ്ലോകത്തിന്റെ അർത്ഥം ഇപ്പോൾ ലഭ്യമല്ല, ദയവായി അടുത്ത അപ്ഡേറ്റിനായി കാത്തിരിക്കുക",
    shlokNo: "ശ്ലോക നമ്പർ",
    shlokCharan: "ശ്ലോക ചരണം",
    showAnswer:"ഉത്തരം കാണുക",
    showMeaning: "അർത്ഥം കാണുക",
    adhyaya : "അധ്യായം",
    },
Nums:
    {
    num1: "1",
    num2: "2",
    num3: "3",
    num4: "4",
    num5: "5",
    num6: "6",
    num7: "7",
    num8: "8",
    num9: "9",
    num10: "10",
    num11: "11",
    num12: "12",
    num13: "13",
    num14: "14",
    num15: "15",
    num16: "16",
    num17: "17",
    num18: "18"
    },
Score:
    {
    reportCard : "റിപ്പോർട്ട് കാർഡ്",
    srNo: "ക്ര.സം.",
    adhyaya : "അധ്യായം",
    shlokNo: "ശ്ലോക ക്രമസംഖ്യ",
    time: "സമയം",
    },
Serialwise:
    {
    WelMsg: "ശ്രീമദ്ഭഗവദ്ഗീത ഘട്ടം ഘട്ടമായുള്ള പഠന പേജ്"
    },
}
export default TextMalayalam