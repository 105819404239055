import gpLogo from '../imgs/Logo Gita Parivar-1-2020.png'
import lgLogo from '../imgs/Learngeeta Logo2.png'
// import circle from '../imgs/Circle.png';

function Header () {
    return (
        <header className="header">
            <section className="logo">
                <img className="logoGP" src={gpLogo} alt="Geeta Pariwar"/>
            </section>
            {/* <section className="container">
                <section className="outCircle"></section>
                <img src={circle} className="outer" alt="Outer-Circle" />
                <img src={gpLogo} className="logoGP" alt="GP-logo" />
            </section> */}

            <section className="startPrayer">
                {/* <h3>
                    ।।ॐ श्रीपरमात्मने नम:।।
                </h3> */}
                <p>
                    वसुदेवसुतं(न्) देवं(ङ्), कंसचाणूरमर्दनम्।,<br/>देवकीपरमानन्दं(ङ्), कृष्णं(व्ँ) वन्दे जगद्गुरुम्।।
                </p>
            </section>

            <section className="logo">
                <img className="logoLG" src={lgLogo} alt="Learn Geeta"/>
            </section>
        </header>
    )
}

export default Header