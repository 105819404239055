const TextEnglish = {
Home:
  {
  WelMsg: "Learngeeta Memorisation Practice Page",
  chooseLang: "Choose your language:",
  greetMsg: "Geeta Pariwar Welcomes You",
  linkBasic1: "Simple Practice",
  linkBasic2:"Charan-wise",
  startPractice: "Lets Start Practice",
  linkAdv1:"Advance Practice",
  linkAdv2:"Shlokank",
  linkSerialWise: "Serial-wise Learning",
  alert: "This language is not availabe at present"
  },
Practice:
  {
  WelMsg: "Shrimadbhagwadgeeta Memorisation Support Page",
  selAdh: "Select Single/Multiple Adhyaya:",
  or: "OR",
  selectAll: "Select All Adhyaya",
  selectdAdh: "Selected Adhyaya",
  clkBtn: "Click on button to get Random Shlok No.",
  alert: "Please select at least one Adhyaya",
  meaningMsg: "This shlok meaning is not avialbale presently, please wait for next update",
  shlokNo: "Shlok No.",
  shlokCharan: "Shlok-Charan",
  showAnswer: "Show Answer",
  showMeaning: "Show Meaning",
  adhyaya: "Adhyaya"
  },
Nums:
  {
  num1: "1",
  num2: "2",
  num3: "3",
  num4: "4",
  num5: "5",
  num6: "6",
  num7: "7",
  num8: "8",
  num9: "9",
  num10: "10",
  num11: "11",
  num12: "12",
  num13: "13",
  num14: "14",
  num15: "15",
  num16: "16",
  num17: "17",
  num18: "18"
  },
Score:
  {
  reportCard: "Report-Card",
  srNo: "S. No.",
  adhyaya: "Adhyaya",
  shlokNo: "Shlok No.",
  time: "Time",
  },
Serialwise:
  {
  WelMsg: "Shrimadbhagwadgeeta Serialwise Learning Page",
  },
}
export default TextEnglish