const TextMarathi = {
Home:
    {
    WelMsg: "लर्नगीता कंठस्थीकरण अभ्यास पृष्ठ",
    chooseLang: "आपली भाषा निवडा",
    greetMsg: "गीता परिवारात आपले स्वागत आहे",
    linkBasic1: "सामान्य अभ्यास",
    linkBasic2:"चरणानुसार",
    startPractice: "अभ्यास सुरु करा",
    linkAdv1:"अग्र स्तराचा अभ्यास",
    linkAdv2:"श्लोकांक",
    linkSerialWise: "क्रमानुसार शिका",
    alert: "ही भाषा सध्या उपलब्ध नाही"
    },
Practice:
    {
    WelMsg: "श्रीमद्भगवदगीता कंठस्थीकरण मदत पृष्ठ",
    selAdh: "एक किंवा अधिक अध्याय निवडा",
    or: "किंवा",
    selectAll: "सर्व अध्याय निवडा",
    selectdAdh: "निवडलेला अध्याय",
    clkBtn: "क्रमरहित श्लोक संख्या येण्यासाठी बटणावर क्लिक करा",
    alert: "कृपया कमीत कमी एक अध्याय निवडा",
    meaningMsg: "या श्लोकाचा अर्थ सध्या उपलब्ध नाही, कृपया पुढच्या अपडेटची वाट पहा",
    shlokNo: "श्लोक क्रमांक",
    shlokCharan: "श्लोकाचे चरण",
    showAnswer:"उत्तर बघा",
    showMeaning: "अर्थ बघा",
    adhyaya: "अध्याय"
    },
Nums:
    {
    num1: "१", 
    num2: "२",
    num3: "३",
    num4: "४",
    num5: "५",
    num6: "६",
    num7: "७",
    num8: "८",
    num9: "९",
    num10: "१०",
    num11: "११",
    num12: "१२",
    num13: "१३",
    num14: "१४",
    num15: "१५",
    num16: "१६",
    num17: "१७",
    num18: "१८"
    },
Score:
    {
    reportCard: "रिपोर्ट कार्ड",
    srNo: "क्र.सं.",
    adhyaya: "अध्याय",
    shlokNo: "श्लोक क्रमांक",
    time: "वेळ",
    },
Serialwise:
    {
    WelMsg: "श्रीमद्भगवदगीता क्रमानुसार शिकण्यासाठी पृष्ठ",
    },
}
export default TextMarathi