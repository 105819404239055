const TextHindi = {
Home:
    {
    WelMsg: "लर्नगीता कंठस्थीकरण अभ्यास पृष्ठ",
    chooseLang: "अपनी भाषा चुनें:",
    greetMsg: "गीता परिवार में आपका स्वागत है",
    linkBasic1: "सामान्य अभ्यास",
    linkBasic2:"चरणानुसार",
    startPractice: "अभ्यास आरम्भ करें",
    linkAdv1:"अग्र स्तर अभ्यास",
    linkAdv2:"श्लोकांक",
    linkSerialWise: "क्रमानुसार सीखें",
    alert: "यह भाषा वर्तमान में  उपलब्ध नहीं है",
    },
Practice:
    {
    WelMsg: "श्रीमद्भगवदगीता कंठस्थीकरण सहायता पृष्ठ",
    selAdh: "एक अथवा अधिक अध्याय चुनें:",
    or: "अथवा",
    selectAll: "सभी अध्याय चुने",
    selectdAdh: "चयनित अध्याय",
    clkBtn: "क्रमरहित श्लोक संख्या प्राप्त करने के लिए बटन पर क्लिक करें",
    alert: "कृपया कम से कम एक अध्याय चुनें",
    meaningMsg: "इस श्लोक का अर्थ अभी उपलब्ध नहीं है, कृपया अगले अपडेट की प्रतीक्षा करें",
    shlokNo: "श्लोक क्रमांक",
    shlokCharan: "श्लोक चरण",
    showAnswer:"उत्तर देखें",
    showMeaning: "अर्थ देखें",
    adhyaya: "अध्याय",
    },
Nums:
    {
    num1: "१", 
    num2: "२",
    num3: "३",
    num4: "४",
    num5: "५",
    num6: "६",
    num7: "७",
    num8: "८",
    num9: "९",
    num10: "१०",
    num11: "११",
    num12: "१२",
    num13: "१३",
    num14: "१४",
    num15: "१५",
    num16: "१६",
    num17: "१७",
    num18: "१८",
    },
Score:
    {
    reportCard: "रिपोर्ट कार्ड",
    srNo: "क्र.सं.",
    adhyaya: "अध्याय",
    shlokNo: "श्लोक क्रमांक",
    time: "समय",
    },
Serialwise:
    {
    WelMsg: "श्रीमद्भगवदगीता क्रमानुसार सीखने का पृष्ठ",
    },
}
export default TextHindi