import Texts from "./TextScript/TextIndex"
import { useContext } from "react"
import { MyContext } from "./Context"
import { FaArrowDown } from 'react-icons/fa';
import Button from "./Button";

function AdhyayaSelector({selectedAdhyaya, handleSelect, selectAll}) {

    const lang = useContext(MyContext)
    const textType = Texts[lang].Practice
    const numType = Texts[lang].Nums
    // console.log(textType)
    // console.log(selectedAdhyaya)
    let langSelAdh = selectedAdhyaya.map(e => {
        let item = 'num'+e
        return numType[item]
    })
    // console.log(langSelAdh)

    return (
    <div className="adhyayaSelector">
        <label>
            <h4 className="noPadding textViolet">{textType.selAdh}</h4>
            <h4 className="noPadding textViolet"><FaArrowDown/></h4>          
            <select
            name="adhyayaSelector"
            multiple={true}
            value ={selectedAdhyaya}
            size="4"
            onChange={handleSelect}
            >
                <option value="1">{textType.adhyaya} - {numType.num1}</option>
                <option value="2">{textType.adhyaya} - {numType.num2}</option>
                <option value="3">{textType.adhyaya} - {numType.num3}</option>
                <option value="4">{textType.adhyaya} - {numType.num4}</option>
                <option value="5">{textType.adhyaya} - {numType.num5}</option>
                <option value="6">{textType.adhyaya} - {numType.num6}</option>
                <option value="7">{textType.adhyaya} - {numType.num7}</option>
                <option value="8">{textType.adhyaya} - {numType.num8}</option>
                <option value="9">{textType.adhyaya} - {numType.num9}</option>
                <option value="10">{textType.adhyaya} - {numType.num10}</option>
                <option value="11">{textType.adhyaya} - {numType.num11}</option>
                <option value="12">{textType.adhyaya} - {numType.num12}</option>
                <option value="13">{textType.adhyaya} - {numType.num13}</option>
                <option value="14">{textType.adhyaya} - {numType.num14}</option>
                <option value="15">{textType.adhyaya} - {numType.num15}</option>
                <option value="16">{textType.adhyaya} - {numType.num16}</option>
                <option value="17">{textType.adhyaya} - {numType.num17}</option>
                <option value="18">{textType.adhyaya} - {numType.num18}</option>
                </select>
        </label>
        <h3 className="textViolet">{textType.or}</h3>
        <Button text = {textType.selectAll} onClick={selectAll}/>
        <h4 className="textViolet">{langSelAdh.length === 0 ? textType.selectdAdh : `${textType.selectdAdh} - ${langSelAdh.join(', ')}` }
        </h4>

    </div>
    )
}

export default AdhyayaSelector