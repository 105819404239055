import Header from './Header';
import Footer from './Footer';
import Home from '../pages/Home';
import Practice from './Practice'
import Score from '../pages/Score';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import Serialwise from '../pages/Serialwise';


const Router = ({handleLang, lang}) => {
    const Layout = () => {
        return (
          <>
            <Header />
            <Outlet />
            <Footer />
          </>
        )
      }
  return (
    <BrowserRouter>
        <Routes>
        <Route path = '/' element = {<Layout />}>
            <Route path = '/' element = {<Home handleChange={handleLang} lang={lang}/>} />
            <Route path = '/basicLearning' element = {<Practice method ="Basic" />} />
            <Route path = '/advanceLearning' element = {<Practice method ="Advance"/>} />
            <Route path = '/showScore' element = {<Score />} />
            <Route path = '/serialWise' element = {<Serialwise />} />
        </Route>
        </Routes>
    </BrowserRouter>
  )
}

export default Router
