const GeetajiOdia = [
{
  "adhyaya-1" : [
    "ଧୃତରାଷ୍ଟ୍ର ଉବାଚ\nଧର୍ମକ୍ଷେତ୍ରେ କୁରୁକ୍ଷେତ୍ରେ ସମବେତା ୟୁଯୁତ୍ସବଃ,\nମାମକାଃ(ଫ୍) ପାଣ୍ଡବାଶ୍ଚୈବ କିମକୁର୍ବତ ସଞ୍ଜୟ ॥1.1॥",

    "ଦୃଷ୍ଟ୍ୱା ତୁ ପାଣ୍ଡବାନୀକଂ(ୱ୍ଁ), ବ୍ୟୂଢ(ନ୍) ଦୁର୍ୟୋଧନସ୍ତଦା\nଆଚାର୍ୟମୁପସଙ୍ଗମ୍ୟ, ରାଜା ବଚନମବ୍ରବୀତ୍ ॥1.2॥",

    "ପଶ୍ୟୈତାଂ(ମ୍) ପାଣ୍ଡୁପୁତ୍ରାଣାମ୍, ଆଚାର୍ୟ ମହତୀଂ(ଞ) ଚମୂମ୍\nବ୍ୟୂଢାଂ(ନ୍) ଦ୍ରୁପଦପୁତ୍ରେଣ, ତବ ଶିଷ୍ୟେଣ ଧୀମତା ॥1.3॥",

    "ଅତ୍ର ଶୂରା ମହେଷ୍ୱାସା, ଭୀମାର୍ଜୁନସମା ୟୁଧି\nୟୁଯୁଧାନୋ ବିରାଟଶ୍ଚ, ଦ୍ରୁପଦଶ୍ଚ ମହାରଥଃ ॥1.4॥",

    "ଧୃଷ୍ଟକେତୁଶ୍ଚେକିତାନଃ(ଖ୍), କାଶିରାଜଶ୍ଚ ବୀର୍ୟବାନ୍\nପୁରୁଜିତ୍କୁନ୍ତିଭୋଜଶ୍ଚ, ଶୈବ୍ୟଶ୍ଚ ନରପୁଙ୍ଗବଃ ॥1.5॥",

    "ୟୁଧାମନ୍ୟୁଶ୍ଚ ବିକ୍ରାନ୍ତ, ଉତ୍ତମୌଜାଶ୍ଚ ବୀର୍ୟବାନ୍\nସୌଭଦ୍ରୋ ଦ୍ରୌପଦେୟାଶ୍ଚ, ସର୍ବ ଏବ ମହାରଥାଃ ॥1.6॥",

    "ଅସ୍ମାକଂ(ନ୍) ତୁ ବିଶିଷ୍ଟା ୟେ, ତାନ୍ନିବୋଧ ଦ୍ୱିଜୋତ୍ତମ\nନାୟକା ମମ ସୈନ୍ୟସ୍ୟ, ସଞ୍ଜ୍ଞାର୍ଥଂ(ନ୍) ତାନ୍ବ୍ରବୀମି ତେ ॥1.7॥",

    "ଭବାନ୍ଭୀଷ୍ମଶ୍ଚ କର୍ଣ୍ଣଶ୍ଚ, କୃପଶ୍ଚ ସମିତିଞ୍ଜୟଃ\nଅଶ୍ୱତ୍ଥାମା ବିକର୍ଣ୍ଣଶ୍ଚ, ସୌମଦତ୍ତିସ୍ତଥୈବ ଚ ॥1.8॥",

    "ଅନ୍ୟେ ଚ ବହବଃ(ଶ୍) ଶୂରା, ମଦର୍ଥେ ତ୍ୟକ୍ତଜୀବିତାଃ\nନାନାଶସ୍ତ୍ରପ୍ରହରଣାଃ(ସ୍), ସର୍ବେ ୟୁଦ୍ଧବିଶାରଦାଃ ॥1.9॥",

    "ଅପର୍ୟାପ୍ତଂ(ନ୍) ତଦସ୍ମାକଂ(ମ୍), ବଲଂ(ମ୍) ଭୀଷ୍ମାଭିରକ୍ଷିତମ୍\nପର୍ୟାପ୍ତଂ(ନ୍) ତ୍ୱିଦମେତେଷାଂ(ମ୍), ବଲଂ(ମ୍) ଭୀମାଭିରକ୍ଷିତମ୍ ॥1.10॥",

    "ଅୟନେଷୁ ଚ ସର୍ବେଷୁ, ୟଥାଭାଗମବସ୍ଥିତାଃ\nଭୀଷ୍ମମେବାଭିରକ୍ଷନ୍ତୁ, ଭବନ୍ତଃ(ସ୍) ସର୍ବ ଏବ ହି ॥1.11॥",

    "ତସ୍ୟ ସଞ୍ଜନୟନ୍ହର୍ଷଂ(ଙ୍), କୁରୁବୃଦ୍ଧଃ(ଫ୍) ପିତାମହଃ\nସିଂହନାଦଂ(ୱ୍ଁ) ବିନଦ୍ୟୋଚ୍ଚୈଃ(ଶ୍), ଶଙ୍ଖଂ(ନ୍) ଦଧ୍ମୌ ପ୍ରତାପବାନ୍ ॥1.12॥",

    "ତତଃ(ଶ୍) ଶଙ୍ଖାଶ୍ଚ ଭେର୍ୟଶ୍ଚ, ପଣବାନକଗୋମୁଖାଃ\nସହସୈବାଭ୍ୟହନ୍ୟନ୍ତ, ସ ଶବ୍ଦସ୍ତୁମୁଲୋଽଭବତ୍ ॥1.13॥",

    "ତତଃ(ଶ୍) ଶ୍ୱେତୈର୍ହୟୈର୍ୟୁକ୍ତେ, ମହତି ସ୍ୟନ୍ଦନେ ସ୍ଥିତୌ\nମାଧବଃ(ଫ୍) ପାଣ୍ଡବଶ୍ଚୈବ, ଦିବ୍ୟୌ ଶଙ୍ଖୌ ପ୍ରଦଧ୍ମତୁଃ ॥1.14॥",

    "ପାଞ୍ଚଜନ୍ୟଂ(ମ୍) ହୃଷୀକେଶୋ, ଦେବଦତ୍ତଂ(ନ୍) ଧନଞ୍ଜୟଃ\nପୌଣ୍ଡ୍ରଂ(ନ୍) ଦଧ୍ମୌ ମହାଶଙ୍ଖଂ(ମ୍), ଭୀମକର୍ମା ବୃକୋଦରଃ ॥1.15॥",

    "ଅନନ୍ତବିଜୟଂ(ମ୍) ରାଜା, କୁନ୍ତୀପୁତ୍ରୋ ୟୁଧିଷ୍ଠିରଃ\nନକୁଳଃ(ସ୍) ସହଦେବଶ୍ଚ, ସୁଘୋଷମଣିପୁଷ୍ପକୌ ॥1.16॥",

    "କାଶ୍ୟଶ୍ଚ ପରମେଷ୍ୱାସଃ(ଶ୍), ଶିଖଣ୍ଡୀ ଚ ମହାରଥଃ\nଧୃଷ୍ଟଦ୍ୟୁମ୍ନୋ ବିରାଟଶ୍ଚ, ସାତ୍ୟକିଶ୍ଚାପରାଜିତଃ ॥1.17॥",

    "ଦ୍ରୁପଦୋ ଦ୍ରୌପଦେୟାଶ୍ଚ, ସର୍ବଶଃ(ଫ୍) ପୃଥିବୀପତେ\nସୌଭଦ୍ରଶ୍ଚ ମହାବାହୁଃ(ଶ୍), ଶଙ୍ଖାନ୍ଦଧ୍ମୁଃ(ଫ୍) ପୃଥକ୍ ପୃଥକ୍ ॥1.18॥",

    "ସ ଘୋଷୋ ଧାର୍ତରାଷ୍ଟ୍ରାଣାଂ(ମ୍), ହୃଦୟାନି ବ୍ୟଦାରୟତ୍\nନଭଶ୍ଚ ପୃଥିବୀଂ(ଞ୍) ଚୈବ, ତୁମୁଲୋ ବ୍ୟନୁନାଦୟନ୍ ॥1.19॥",

    "ଅଥ ବ୍ୟବସ୍ଥିତାନ୍ଦୃଷ୍ଟ୍ୱା, ଧାର୍ତରାଷ୍ଟ୍ରାନ୍କପିଧ୍ୱଜଃ\nପ୍ରବୃତ୍ତେ ଶସ୍ତ୍ରସମ୍ପାତେ, ଧନୁରୁଦ୍ୟମ୍ୟ ପାଣ୍ଡବଃ ॥1.20॥",

    "ହୃଷୀକେଶଂ(ନ୍) ତଦା ବାକ୍ୟମ୍, ଇଦମାହ ମହୀପତେ\nଅର୍ଜୁନ ଉବାଚ\nସେନୟୋରୁଭୟୋର୍ମଧ୍ୟେ, ରଥଂ(ମ୍) ସ୍ଥାପୟ ମେଽଚ୍ୟୁତ ॥1.21॥",

    "ୟାବଦେତାନ୍ନିରୀକ୍ଷେଽହଂ(ୟ୍ଁ), ୟୋଦ୍ଧୁକାମାନବସ୍ଥିତାନ୍\nକୈର୍ମୟା ସହ ୟୋଦ୍ଧବ୍ୟମ୍, ଅସ୍ମିନ୍ରଣସମୁଦ୍ୟମେ ॥1.22॥",

    "ୟୋତ୍ସ୍ୟମାନାନବେକ୍ଷେଽହଂ(ୟ୍ଁ), ୟ ଏତେଽତ୍ର ସମାଗତାଃ\nଧାର୍ତରାଷ୍ଟ୍ରସ୍ୟ ଦୁର୍ବୁଦ୍ଧେଃ(ର୍), ୟୁଦ୍ଧେ ପ୍ରିୟଚିକୀର୍ଷବଃ ॥1.23॥",

    "ସଞ୍ଜୟ ଉବାଚ\nଏବମୁକ୍ତୋ ହୃଷୀକେଶୋ, ଗୁଡାକେଶେନ ଭାରତ\nସେନୟୋରୁଭୟୋର୍ମଧ୍ୟେ, ସ୍ଥାପୟିତ୍ୱା ରଥୋତ୍ତମମ୍ ॥1.24॥",

    "ଭୀଷ୍ମଦ୍ରୋଣପ୍ରମୁଖତଃ(ସ୍), ସର୍ବେଷାଂ(ଞ୍) ଚ ମହୀକ୍ଷିତାମ୍\nଉବାଚ ପାର୍ଥ ପଶ୍ୟୈତାନ୍, ସମବେତାନ୍କୁରୂନିତି ॥1.25॥",

    "ତତ୍ରାପଶ୍ୟତ୍ସ୍ଥିତାନ୍ପାର୍ଥଃ(ଫ୍), ପିତୃୃନଥ ପିତାମହାନ୍\nଆଚାର୍ୟାନ୍ମାତୁଳାନ୍ଭ୍ରାତୃୃନ୍, ପୁତ୍ରାନ୍ପୌତ୍ରାନ୍ସଖୀଂସ୍ତଥା ॥1.26॥",

    "ଶ୍ୱଶୁରାନ୍ସୁହୃଦଶ୍ଚୈବ, ସେନୟୋରୁଭୟୋରପି\nତାନ୍ସମୀକ୍ଷ୍ଯ ସ କୌନ୍ତେୟଃ(ସ୍), ସର୍ବାନ୍ବନ୍ଧୂନବସ୍ଥିତାନ୍ ॥1.27॥",

    "କୃପୟା ପରୟାବିଷ୍ଟୋ, ବିଷୀଦନ୍ନିଦମବ୍ରବୀତ୍\nଅର୍ଜୁନ ଉବାଚ\nଦୃଷ୍ଟ୍ୱେମଂ(ମ୍) ସ୍ୱଜନଂ(ଙ୍) କୃଷ୍ଣ, ୟୁଯୁତ୍ସୁଂ(ମ୍) ସମୁପସ୍ଥିତମ୍ ॥1.28॥",

    "ସୀଦନ୍ତି ମମ ଗାତ୍ରାଣି, ମୁଖଂ(ଞ୍) ଚ ପରିଶୁଷ୍ୟତି\nବେପଥୁଶ୍ଚ ଶରୀରେ ମେ, ରୋମହର୍ଷଶ୍ଚ ଜାୟତେ ॥1.29॥",

    "ଗାଣ୍ଡୀବଂ(ମ୍) ସ୍ରଂସତେ ହସ୍ତାତ୍, ତ୍ୱକ୍ଚୈବ ପରିଦହ୍ୟତେ\nନ ଚ ଶକ୍ନୋମ୍ୟବସ୍ଥାତୁଂ(ମ୍), ଭ୍ରମତୀବ ଚ ମେ ମନଃ ॥1.30॥",

    "ନିମିତ୍ତାନି ଚ ପଶ୍ୟାମି, ବିପରୀତାନି କେଶବ\nନ ଚ ଶ୍ରେୟୋଽନୁପଶ୍ୟାମି, ହତ୍ୱା ସ୍ୱଜନମାହବେ ॥1.31॥",

    "ନ କାଙ୍ଖେ ବିଜୟଂ(ଙ୍) କୃଷ୍ଣ, ନ ଚ ରାଜ୍ୟଂ(ମ୍) ସୁଖାନି ଚ\nକିଂ(ନ୍) ନୋ ରାଜ୍ୟେନ ଗୋବିନ୍ଦ, କିଂ(ମ୍) ଭୋଗୈର୍ଜୀବିତେନ ବା ॥1.32॥",

    "ୟେଷାମର୍ଥେ କାଙ୍କ୍ଷିତଂ(ନ୍) ନୋ, ରାଜ୍ୟଂ(ମ୍) ଭୋଗାଃ(ସ୍) ସୁଖାନି ଚ\nତ ଇମେଽବସ୍ଥିତା ୟୁଦ୍ଧେ, ପ୍ରାଣାଂସ୍ତ୍ୟକ୍ତ୍ୱା ଧନାନି ଚ ॥1.33॥",

    "ଆଚାର୍ୟାଃ(ଫ୍) ପିତରଃ(ଫ୍) ପୁତ୍ରାଃ(ସ୍), ତଥୈବ ତ ପିତାମହାଃ\nମାତୁଲାଃ(ଶ୍) ଶ୍ୱଶୁରାଃ(ଫ୍) ପୌତ୍ରାଃ(ଶ୍), ଶ୍ୟାଲାଃ(ସ୍) ସମ୍ବନ୍ଧିନସ୍ତଥା ॥1.34॥",

    "ଏତାନ୍ନ ହନ୍ତୁମିଚ୍ଛାମି, ଘ୍ନତୋଽପି ମଧୁସୂଦନ\nଅପି ତ୍ରୈଲୋକ୍ୟରାଜ୍ୟସ୍ୟ, ହେତୋଃ(ଖ୍) କିଂ(ନ୍) ନୁ ମହୀକୃତେ ॥1.35॥",

    "ନିହତ୍ୟ ଧାର୍ତରାଷ୍ଟ୍ରାନ୍ନଃ(ଖ୍), କା ପ୍ରୀତିଃ(ସ୍) ସ୍ୟାଜ୍ଜନାର୍ଦନ\nପାପମେବାଶ୍ରୟେଦସ୍ମାନ, ହତ୍ୱୈତାନାତତାୟିନଃ ॥1.36॥",

    "ତସ୍ମାନ୍ନାର୍ହା ବୟଂ(ମ୍) ହନ୍ତୁଂ(ନ୍), ଧାର୍ତରାଷ୍ଟ୍ରାନ୍ସ୍ୱବାନ୍ଧବାନ୍\nସ୍ୱଜନଂ(ମ୍) ହି କଥଂ(ମ୍) ହତ୍ୱା, ସୁଖିନଃ(ସ୍) ସ୍ୟାମ ମାଧବ ॥1.37॥",

    "ୟଦ୍ୟପ୍ୟେତେ ନ ପଶ୍ୟନ୍ତି, ଲୋଭୋପହତଚେତସଃ\nକୁଲକ୍ଷୟକୃତଂ(ନ୍) ଦୋଷଂ(ମ୍), ମିତ୍ରଦ୍ରୋହେ ଚ ପାତକମ୍ ॥1.38॥",

    "କଥଂ(ନ୍) ନ ଜ୍ଞେୟମସ୍ମାଭିଃ(ଫ୍), ପାପାଦସ୍ମାନ୍ନିବର୍ତିତୁମ୍\nକୁଲକ୍ଷୟକୃତଂ(ନ୍) ଦୋଷଂ(ମ୍), ପ୍ରପଶ୍ୟଦ୍ଭିର୍ଜନାର୍ଦନ ॥1.39॥",

    "କୁଲକ୍ଷୟେ ପ୍ରଣଶ୍ୟନ୍ତି, କୁଲଧର୍ମାଃ(ସ୍) ସନାତନାଃ\nଧର୍ମେ ନଷ୍ଟେ କୁଲଂ(ଙ୍) କୃତ୍ସ୍ନମ୍, ଅଧର୍ମୋଽଭିଭବତ୍ୟୁତ ॥1.40॥",

    "ଅଧର୍ମାଭିଭବାତ୍କୃଷ୍ଣ, ପ୍ରଦୁଷ୍ୟନ୍ତି କୁଲସ୍ତ୍ରିୟଃ\nସ୍ତ୍ରୀଷୁ ଦୁଷ୍ଟାସୁ ବାର୍ଷ୍ଣେୟ, ଜାୟତେ ବର୍ଣ୍ଣସଙ୍କରଃ ॥1.41॥",

    "ସଙ୍କରୋ ନରକାୟୈବ, କୁଲଘ୍ନାନାଂ(ଙ୍) କୁଲସ୍ୟ ଚ\nପତନ୍ତି ପିତରୋ ହ୍ୟେଷାଂ(ଲ୍ଁ), ଲୁପ୍ତପିଣ୍ଡୋଦକକ୍ରିୟାଃ ॥1.42॥",

    "ଦୋଷୈରେତୈଃ(ଖ୍) କୁଲଘ୍ନାନାଂ(ୱ୍ଁ), ବର୍ଣ୍ଣସଙ୍କରକାରକୈଃ\nଉତ୍ସାଦ୍ୟନ୍ତେ ଜାତିଧର୍ମାଃ(ଖ୍), କୁଲଧର୍ମାଶ୍ଚ ଶାଶ୍ୱତାଃ ॥1.43॥",

    "ଉତ୍ସନ୍ନକୁଲଧର୍ମାଣାଂ(ମ୍), ମନୁଷ୍ୟାଣାଂ(ଞ୍) ଜନାର୍ଦନ\nନରକେଽନିୟତଂ(ୱ୍ଁ) ବାସୋ, ଭବତୀତ୍ୟନୁଶୁଶ୍ରୁମ ॥1.44॥",

    "ଅହୋ ବତ ମହତ୍ପାପଂ(ଙ୍), କର୍ତୁଂ(ୱ୍ଁ) ବ୍ୟବସିତା ବୟମ୍\nୟଦ୍ରାଜ୍ୟସୁଖଲୋଭେନ, ହନ୍ତୁଂ(ମ୍) ସ୍ୱଜନମୁଦ୍ୟତାଃ ॥1.45॥",

    "ୟଦି ମାମପ୍ରତୀକାରମ୍, ଅଶସ୍ତ୍ରଂ(ମ୍) ଶସ୍ତ୍ରପାଣୟଃ\nଧାର୍ତରାଷ୍ଟ୍ରା ରଣେ ହନ୍ୟୁଃ(ସ୍), ତନ୍ମେ କ୍ଷେମତରଂ(ମ୍) ଭବେତ୍ ॥1.46॥",

    "ସଞ୍ଜୟ ଉବାଚ\nଏବମୁକ୍ତ୍ୱାର୍ଜୁନଃ(ସ୍) ସଙ୍ଖ୍ୟେ, ରଥୋପସ୍ଥ ଉପାବିଶତ୍\nବିସୃଜ୍ୟ ସଶରଂ(ଞ୍) ଚାପଂ(ମ୍), ଶୋକସଂବିଗ୍ନମାନସଃ ॥1.47॥",

    "ଓଁ ତତ୍ସଦିତି ଶ୍ରୀମଦ୍ଭଗବଦ୍ଗୀତାସୁ ଉପନିଷତ୍ସୁ ବ୍ରହ୍ମବିଦ୍ୟାୟାଂ(ୟ୍ଁ) ୟୋଗଶାସ୍ତ୍ରେ ଶ୍ରୀକୃଷ୍ଣାର୍ଜୁନସଂବାଦେ ଅର୍ଜୁନବିଷାଦୟୋଗୋ ନାମ ପ୍ରଥମୋଽଧ୍ୟାୟଃ ॥1॥"
  ],
  "adhyaya-2" : [
    "ସଞ୍ଜୟ ଉବାଚ\nତଂ(ନ୍) ତଥା କୃପୟାବିଷ୍ଟମ୍, ଅଶ୍ରୁପୂର୍ଣ୍ଣାକୁଲେକ୍ଷଣମ୍।\nବିଷୀଦନ୍ତମିଦଂ(ୱ୍ଁ) ବାକ୍ୟମ୍, ଉବାଚ ମଧୁସୂଦନଃ॥2.1॥",

    "ଶ୍ରୀ ଭଗବାନୁବାଚ\nକୁତସ୍ତ୍ୱା କଶ୍ମଲମିଦଂ(ୱ୍ଁ), ବିଷମେ ସମୁପସ୍ଥିତମ୍।\nଅନାର୍ୟଜୁଷ୍ଟମସ୍ୱର୍ଗ୍ୟମ୍, ଅକୀର୍ତିକରମର୍ଜୁନ॥2.2॥",

    "କ୍ଲେବ୍ୟଂ(ମ୍) ମା ସ୍ମ ଗମଃ(ଫ୍) ପାର୍ଥ, ନୈତତ୍ତ୍ୱୟ୍ୟୁପପଦ୍ୟତେ\nକ୍ଷୁଦ୍ରଂ(ମ୍) ହୃଦୟଦୌର୍ବଲ୍ୟଂ(ନ୍), ତ୍ୟକ୍ତ୍ୱୋତ୍ତିଷ୍ଠ ପରନ୍ତପ ॥2.3॥",

    "ଅର୍ଜୁନ ଉବାଚ\nକଥଂ(ମ୍) ଭୀଷ୍ମମହଂ(ମ୍) ସଙ୍ଖ୍ୟେ, ଦ୍ରୋଣଂ(ଞ୍) ଚ ମଧୁସୂଦନ\nଇଷୁଭିଃ(ଫ୍) ପ୍ରତି ୟୋତ୍ସ୍ୟାମି, ପୂଜାର୍ହାବରିସୂଦନ ॥2.4॥",

    "ଗୁରୂନହତ୍ୱା ହି ମହାନୁଭାବାନ୍, \nଶ୍ରେୟୋ ଭୋକ୍ତୁଂ(ମ୍) ଭୈକ୍ଷ୍ୟମପୀହ ଲୋକେ\nହତ୍ୱାର୍ଥକାମାଂସ୍ତୁ ଗୁରୂନିହୈବ,\nଭୁଞ୍ଜୀୟ ଭୋଗାନ୍ରୁଧିରପ୍ରଦିଗ୍ଧାନ୍ ॥2.5॥",

    "ନ ଚୈତଦ୍ୱିଦ୍ମଃ(ଖ୍) କତରନ୍ନୋ ଗରୀୟୋ,\nୟଦ୍ୱା ଜୟେମ ୟଦି ବା ନୋ ଜୟେୟୁଃ\nୟାନେବ ହତ୍ୱା ନ ଜିଜୀବିଷାମଃ(ସ୍)\nତେଽବସ୍ଥିତାଃ(ଫ୍) ପ୍ରମୁଖେ ଧାର୍ତରାଷ୍ଟ୍ରାଃ ॥2.6॥",

    "କାର୍ପଣ୍ୟଦୋଷୋପହତସ୍ୱଭାବଃ(ଫ୍),\nପୃଚ୍ଛାମି ତ୍ୱାଂ(ନ୍) ଧର୍ମସମ୍ମୂଢଚେତାଃ\nୟଚ୍ଛ୍ରେୟଃ(ସ୍) ସ୍ୟାନିଶ୍ଚିତଂ(ମ୍) ବ୍ରୂହି ତନ୍ମେ,\nଶିଷ୍ୟସ୍ତେଽହଂ(ମ୍) ଶାଧି ମାଂ(ନ୍) ତ୍ୱାଂ(ମ୍) ପ୍ରପନ୍ନମ୍ ॥2.7॥",

    "ନ ହି ପ୍ରପଶ୍ୟାମି ମମାପନୁଦ୍ୟାଦ୍,\nୟଚ୍ଛୋକମୁଚ୍ଛୋଷଣମିନ୍ଦ୍ରିୟାଣାମ୍\nଅବାପ୍ୟ ଭୂମାବସପତ୍ନମୃଦ୍ଧଂ(ମ୍),\nରାଜ୍ୟଂ(ମ୍) ସୁରାଣାମପି ଚାଧିପତ୍ୟମ୍ ॥2.8॥",

    "ସଞ୍ଜୟ ଉବାଚ\nଏବମୁକ୍ତ୍ୱା ହୃଷୀକେଶଂ(ଙ୍), ଗୁଡାକେଶଃ(ଫ୍) ପରନ୍ତପ\nନ ୟୋତ୍ସ୍ୟ ଇତି ଗୋବିନ୍ଦ ମ୍, ଉକ୍ତ୍ୱା ତୂଷ୍ଣୀଂ(ମ୍) ବଭୂବ ହ ॥2.9॥",

    "ତମୁବାଚ ହୃଷୀକେଶଃ(ଫ୍), ପ୍ରହସନ୍ନିବ ଭାରତ।\nସେନୟୋରୁଭୟୋର୍ମଧ୍ୟେ,ବିଷୀଦନ୍ତମିଦଂ(ୱ୍ଁ) ବଚଃ ॥2.10॥",
    
    "ଶ୍ରୀ ଭଗବାନୁବାଚ\nଅଶୋଚ୍ୟାନନ୍ୱଶୋଚସ୍ତ୍ୱଂ(ମ୍), ପ୍ରଜ୍ଞାବାଦାଂଶ୍ଚ ଭାଷସେ\nଗତାସୂନଗତାସୂଂଶ୍ଚ, ନାନୁଶୋଚନ୍ତି ପଣ୍ଡିତାଃ ॥2.11॥",
    
    "ନ ତ୍ୱେବାହଂ(ଞ୍) ଜାତୁ ନାସଂ(ନ୍), ନ ତ୍ୱଂ(ନ୍) ନେମେ ଜନାଧିପାଃ\nନ ଚୈବ ନ ଭବିଷ୍ୟାମଃ(ସ୍), ସର୍ବେ ବୟମତଃ(ଫ୍) ପରମ୍ ॥2.12॥",
    
    "ଦେହିନୋଽସ୍ମିନ୍ୟଥା ଦେହେ, କୌମାରଂ(ୟ୍ଁ) ୟୌବନଂ(ଞ୍) ଜରା।\nତଥା ଦେହାନ୍ତରପ୍ରାପ୍ତିଃ(ର୍), ଧୀରସ୍ତତ୍ର ନ ମୁହ୍ୟତି ॥2.13॥",
    
    "ମାତ୍ରାସ୍ପର୍ଶାସ୍ତୁ କୌନ୍ତେୟ, ଶୀତୋଷ୍ଣସୁଖଦୁଃଖଦାଃ\nଆଗମାପାୟିନୋଽନିତ୍ୟାଃ(ସ୍), ତାଂସ୍ତିତିକ୍ଷସ୍ୱ ଭାରତ ॥2.14॥",
    
    "ୟଂ(ମ୍) ହି ନ ବ୍ୟଥୟନ୍ତ୍ୟେତେ, ପୁରୁଷଂ(ମ୍) ପୁରୁଷର୍ଷଭ\nସମଦୁଃଖସୁଖଂ(ନ୍) ଧୀରଂ(ମ୍), ସୋଽମୃତତ୍ୱାୟ କଲ୍ପତେ ॥2.15॥",
    
    "ନାସତୋ ବିଦ୍ୟତେ ଭାବୋ, ନାଭାବୋ ବିଦ୍ୟତେ ସତଃ\nଉଭୟୋରପି ଦୃଷ୍ଟୋଽନ୍ତଃ(ସ୍), ତ୍ୱନୟୋସ୍ତତ୍ତ୍ୱଦର୍ଶିଭିଃ ॥2.16॥",
    
    "ଅବିନାଶି ତୁ ତଦ୍ୱିଦ୍ଧି, ୟେନ ସର୍ବମିଦଂ(ନ୍) ତତମ୍\nବିନାଶମବ୍ୟୟସ୍ୟାସ୍ୟ, ନ କଶ୍ଚିତ୍କର୍ତୁମର୍ହତି ॥2.17॥",
    
    "ଅନ୍ତବନ୍ତ ଇମେ ଦେହା, ନିତ୍ୟସ୍ୟୋକ୍ତାଃ(ଶ୍) ଶରୀରିଣଃ\nଅନାଶିନୋଽପ୍ରମେୟସ୍ୟ, ତସ୍ମାଦ୍ୟୁଧ୍ୟସ୍ୱ ଭାରତ ॥2.18॥",
    
    "ୟ ଏନଂ(ୱ୍ଁ) ବେତ୍ତି ହନ୍ତାରଂ(ୟ୍ଁ), ୟଶ୍ଚୈନଂ(ମ୍) ମନ୍ୟତେ ହତମ୍।\nଉଭୌ ତୌ ନ ବିଜାନୀତୋ, ନାୟଂ(ମ୍) ହନ୍ତି ନ ହନ୍ୟତେ ॥2.19॥",
    
    "ନ ଜାୟତେ ମ୍ରିୟତେ ବା କଦାଚିନ୍, \nନାୟଂ(ମ୍) ଭୂତ୍ୱା ଭବିତା ବା ନ ଭୂୟଃ\nଅଜୋ ନିତ୍ୟଃ(ଶ୍) ଶାଶ୍ୱତୋଽୟଂ(ମ୍) ପୁରାଣୋ,\nନ ହନ୍ୟତେ ହନ୍ୟମାନେ ଶରୀରେ ॥2.20॥",
    
    "ବେଦାବିନାଶିନଂ(ନ୍) ନିତ୍ୟଂ(ୟ୍ଁ), ୟ ଏନମଜମବ୍ୟୟମ୍।\nକଥଂ(ମ୍) ସ ପୁରୁଷଃ(ଫ୍) ପାର୍ଥ, କଂ(ଙ୍) ଘାତୟତି ହନ୍ତି କମ୍ ॥2.21॥",
    
    "ବାସାଂସି ଜୀର୍ଣାନି ୟଥା ବିହାୟ,\nନବାନି ଗୃହ୍ଣାତି ନରୋଽପରାଣି\nତଥା ଶରୀରାଣି ବିହାୟ ଜୀର୍ଣା-\nନ୍ୟନ୍ୟାନି ସଂୟାତି ନବାନି ଦେହୀ ॥2.22॥",
     
    "ନୈନଂ(ଞ୍) ଛିନ୍ଦନ୍ତି ଶସ୍ତ୍ରାଣି, ନୈନଂ(ନ୍) ଦହତି ପାବକଃ\nନ ଚୈନଂ(ଙ୍) କ୍ଲେଦୟନ୍ତ୍ୟାପୋ, ନ ଶୋଷୟତି ମାରୁତଃ ॥2.23॥",
    
    "ଅଚ୍ଛେଦ୍ୟୋଽୟମଦାହ୍ୟୋଽୟମ୍, ଅକ୍ଲେଦ୍ୟୋଽଶୋଷ୍ୟ ଏବ ଚ\nନିତ୍ୟଃ(ସ୍) ସର୍ବଗତଃ(ସ୍) ସ୍ଥାଣୁଃ(ର୍), ଅଚଲୋଽୟଂ(ମ୍) ସନାତନଃ ॥2.24॥",
    
    "ଅବ୍ୟକ୍ତୋଽୟମଚିନ୍ତ୍ୟୋଽୟମ୍, ଅବିକାର୍ୟୋଽୟମୁଚ୍ୟତେ।\nତସ୍ମାଦେବଂ(ୱ୍ଁ) ବିଦିତ୍ୱୈନଂ(ନ୍), ନାନୁଶୋଚିତୁମର୍ହସି ॥2.25॥",
    
    "ଅଥ ଚୈନଂ(ନ୍) ନିତ୍ୟଜାତଂ(ନ୍), ନିତ୍ୟଂ(ୱ୍ଁ) ବା ମନ୍ୟସେ ମୃତମ୍।\nତଥାପି ତ୍ୱଂ(ମ୍) ମହାବାହୋ, ନୈବଂ(ମ୍) ଶୋଚିତୁମର୍ହସି ॥2.26॥",
    
    "ଜାତସ୍ୟ ହି ଧ୍ରୁବୋ ମୃତ୍ୟୁଃ(ର୍), ଧ୍ରୁବଂ(ଞ୍) ଜନ୍ମ ମୃତସ୍ୟ ଚ\nତସ୍ମାଦପରିହାର୍ୟେଽର୍ଥେ, ନ ତ୍ୱଂ(ମ୍) ଶୋଚିତୁମର୍ହସି ॥2.27॥",
    
    "ଅବ୍ୟକ୍ତାଦୀନି ଭୂତାନି, ବ୍ୟକ୍ତମଧ୍ୟାନି ଭାରତ\nଅବ୍ୟକ୍ତନିଧନାନ୍ୟେବ, ତତ୍ର କା ପରିଦେବନା ॥2.28॥",
    
    " ଆଶ୍ଚର୍ୟବତ୍ପଶ୍ୟତି କଶ୍ଚିଦେନମ୍,\nଆଶ୍ଚର୍ୟବଦ୍ୱଦତି ତଥୈବ ଚାନ୍ୟଃ।\nଆଶ୍ଚର୍ୟବଚ୍ଚୈନମନ୍ୟଃ(ଶ୍) ଶୃଣୋତି,\nଶୃତ୍ୱାପ୍ୟେନଂ(ୱ୍ଁ) ବେଦ ନ ଚୈବ କଶ୍ଚିତ୍ ॥2.29॥",
    
    "ଦେହୀ ନିତ୍ୟମବଧ୍ୟୋଽୟଂ(ନ୍), ଦେହେ ସର୍ବସ୍ୟ ଭାରତ\nତସ୍ମାତ୍ସର୍ବାଣି ଭୂତାନି, ନ ତ୍ୱଂ(ମ୍) ଶୋଚିତୁମର୍ହସି ॥2.30॥",
    
    "ସ୍ୱଧର୍ମମପି ଚାବେକ୍ଷ୍ୟ , ନ ବିକମ୍ପିତୁମର୍ହସି\nଧର୍ମ୍ୟାଦ୍ଧି ୟୁଦ୍ଧାଚ୍ଛ୍ରେୟୋଽନ୍ୟତ୍, କ୍ଷତ୍ରିୟସ୍ୟ ନ ବିଦ୍ୟତେ ॥2.31॥",
    
    "ୟଦୃଚ୍ଛୟା ଚୋପପନ୍ନଂ(ମ୍), ସ୍ୱର୍ଗଦ୍ୱାରମପାବୃତମ୍\nସୁଖିନଃ କ୍ଷତ୍ରିୟାଃ(ଫ୍) ପାର୍ଥ, ଲଭନ୍ତେ ୟୁଦ୍ଧମୀଦୃଶମ୍ ॥2.32॥",
    
    "ଅଥ ଚେତ୍ତ୍ୱମିମଂ(ନ୍) ଧର୍ମ୍ୟଂ(ମ୍), ସଙ୍ଗ୍ରାମଂ(ନ୍) ନ କରିଷ୍ୟସି\nତତଃ(ସ୍) ସ୍ୱଧର୍ମଂ(ଙ୍) କୀର୍ତିଂ(ଞ୍) ଚ, ହିତ୍ୱା ପାପମବାପ୍ସ୍ୟସି ॥2.33॥",
    
    "ଅକୀର୍ତିଂ(ଞ୍) ଚାପି ଭୂତାନି, କଥୟିଷ୍ୟନ୍ତି ତେଽବ୍ୟୟାମ୍\nସମ୍ଭାବିତସ୍ୟ ଚାକୀର୍ତିଃ(ର୍), ମରଣାଦତିରିଚ୍ୟତେ ॥2.34॥",
    
    "ଭୟାଦ୍ରଣାଦୁପରତଂ(ମ୍), ମଂସ୍ୟନ୍ତେ ତ୍ୱାଂ(ମ୍) ମହାରଥାଃ\nୟେଷାଂ(ଞ୍) ଚ ତ୍ୱଂ(ମ୍) ବହୁମତୋ, ଭୂତ୍ୱା ୟାସ୍ୟସି ଲାଘବମ୍ ॥2.35॥",
    
    "ଅବାଚ୍ୟବାଦାଂଶ୍ଚ ବହୂନ୍, ବଦିଷ୍ୟନ୍ତି ତବାହିତାଃ\nନିନ୍ଦନ୍ତସ୍ତବ ସାମର୍ଥ୍ୟଂ(ନ୍), ତତୋ ଦୁଃଖତରଂ(ନ୍) ନୁ କିମ୍ ॥2.36॥",
    
    "ହତୋ ବା ପ୍ରାପ୍ସ୍ୟସି ସ୍ୱର୍ଗଂ(ଞ୍), ଜିତ୍ୱା ବା ଭୋକ୍ଷ୍ୟସେ ମହୀମ୍ \nତସ୍ମାଦୁତ୍ତିଷ୍ଠ କୌନ୍ତେୟ, ୟୁଦ୍ଧାୟ କୃତନିଶ୍ଚୟଃ ॥2.37॥",
    
    "ସୁଖଦୁଃଖେ ସମେ କୃତ୍ୱା, ଲାଭାଲାଭୌ ଜୟାଜୟୌ\nତତୋ ୟୁଦ୍ଧାୟ ୟୁଜ୍ୟସ୍ୱ, ନୈବଂ(ମ୍) ପାପମବାପ୍ସ୍ୟସି ॥2.38॥",
    
    "ଏଷା ତେଽଭିହିତା ସାଙ୍ଖ୍ୟେ, ବୁଦ୍ଧିର୍ୟୋଗେ ତ୍ୱିମାଂ(ମ୍) ଶୃଣୁ\nବୁଦ୍ଧ୍ୟା ୟୁକ୍ତୋ ୟଯା ପାର୍ଥ, କର୍ମବନ୍ଧଂ(ମ୍) ପ୍ରହାସ୍ୟସି ॥2.39॥",
    
    "ନେହାଭିକ୍ରମନାଶୋଽସ୍ତି, ପ୍ରତ୍ୟବାୟୋ ନ ବିଦ୍ୟତେ\nସ୍ୱଲ୍ପମପ୍ୟସ୍ୟ ଧର୍ମସ୍ୟ, ତ୍ରାୟତେ ମହତୋ ଭୟାତ୍ ॥2.40॥",
    
    "ବ୍ୟବସାୟାତ୍ମିକା ବୁଦ୍ଧିଃ(ର୍), ଏକେହ କୁରୁନନ୍ଦନ\nବହୁଶାଖା ହ୍ୟନନ୍ତାଶ୍ଚ, ବୁଦ୍ଧୟୋଽବ୍ୟବସାୟିନାମ୍ ॥2.41॥",
    
    "ୟାମିମାଂ(ମ୍) ପୁଷ୍ପିତାଂ(ୱ୍ଁ) ବାଚଂ(ମ୍), ପ୍ରବଦନ୍ତ୍ୟବିପଶ୍ଚିତଃ।\nବେଦବାଦରତାଃ(ଫ୍) ପାର୍ଥ, ନାନ୍ୟଦସ୍ତୀତି ବାଦିନଃ ॥2.42॥",
    
    "କାମାତ୍ମାନଃ(ସ୍) ସ୍ୱର୍ଗପରା, ଜନ୍ମକର୍ମଫଲପ୍ରଦାମ୍\nକ୍ରିୟାବିଶେଷବହୁଲାମ୍, ଭୋଗୈଶ୍ୱର୍ୟଗତିଂ(ମ୍) ପ୍ରତି ॥2.43॥",
    
    "ଭୋଗୈଶ୍ୱର୍ୟପ୍ରସକ୍ତାନାଂ(ନ୍), ତୟାପହୃତଚେତସାମ୍\nବ୍ୟବସାୟାତ୍ମିକା ବୁଦ୍ଧିଃ(ସ୍), ସମାଧୌ ନ ବିଧୀୟତେ ॥2.44॥",
    
    "ତ୍ରୈଗୁଣ୍ୟ ବିଷୟା ବେଦା, ନିସ୍ତ୍ରୈଗୁଣ୍ୟୋ ଭବାର୍ଜୁନ\nନିର୍ଦ୍ୱନ୍ଦୋ ନିତ୍ୟସତ୍ତ୍ୱସ୍ଥୋ, ନିର୍ୟୋଗକ୍ଷେମ ଆତ୍ମବାନ୍ ॥2.45॥",
    
    "ୟାବାନର୍ଥ ଉଦପାନେ, ସର୍ବତଃ(ସ୍) ସମ୍ପ୍ଲୁତୋଦକେ\nତାବାନ୍ସର୍ବେଷୁ ବେଦେଷୁ, ବ୍ରାହ୍ମଣସ୍ୟ ବିଜାନତଃ ॥2.46॥",
    
    "କର୍ମଣ୍ୟେବାଧିକାରସ୍ତେ, ମା ଫଲେଷୁ କଦାଚନ\nମା କର୍ମଫଲହେତୁର୍ଭୂଃ(ର୍), ମା ତେ ସଙ୍ଗୋଽସ୍ତ୍ୱକର୍ମଣି ॥2.47॥",
    
    "ୟୋଗସ୍ଥଃ(ଖ୍) କୁରୁ କର୍ମାଣି, ସଙ୍ଗଂ(ନ୍) ତ୍ୟକ୍ତ୍ୱା ଧନଞ୍ଜୟ।\nସିଦ୍ଧ୍ୟସିଦ୍ଧ୍ୟୋଃ(ସ୍) ସମୋ ଭୂତ୍ୱା, ସମତ୍ୱଂ(ୟ୍ଁ) ୟୋଗ ଉଚ୍ୟତେ ॥2.48॥",
    
    "ଦୂରେଣ ହ୍ୟବରଂ(ଙ୍) କର୍ମ, ବୁଦ୍ଧିୟୋଗାଦ୍ଧନଞ୍ଜୟ\nବୁଦ୍ଧୌ ଶରଣମନ୍ୱିଚ୍ଛ, କୃପଣାଃ(ଫ୍) ଫଲହେତବଃ ॥2.49॥",
    
    "ବୁଦ୍ଧିୟୁକ୍ତୋ ଜହାତୀହ, ଉଭେ ସୁକୃତଦୁଷ୍କୃତେ\nତସ୍ମାଦ୍ୟୋଗାୟ ୟୁଜ୍ୟସ୍ୱ, ୟୋଗଃ(ଖ୍) କର୍ମସୁ କୌଶଲମ୍ ॥2.50॥",
    
    "କର୍ମଜଂ(ମ୍) ବୁଦ୍ଧିୟୁକ୍ତା ହି, ଫଲଂ(ନ୍) ତ୍ୟକ୍ତ୍ୱା ମନୀଷିଣଃ\nଜନ୍ମବନ୍ଧବିନିର୍ମୁକ୍ତାଃ(ଫ୍), ପଦଂ(ଙ୍) ଗଚ୍ଛନ୍ତ୍ୟନାମୟମ୍ ॥2.51॥",
    
    "ୟଦା ତେ ମୋହ କଲିଲଂ(ମ୍), ବୁଦ୍ଧିର୍ବ୍ୟତିତରିଷ୍ୟତି\nତଦା ଗନ୍ତାସି ନିର୍ବେଦଂ(ମ୍), ଶ୍ରୋତବ୍ୟସ୍ୟ ଶ୍ରୁତସ୍ୟ ଚ ॥2.52॥",
    
    "ଶ୍ରୁତିବିପ୍ରତିପନ୍ନା ତେ, ୟଦା ସ୍ଥାସ୍ୟତି ନିଶ୍ଚଲା\nସମାଧାବଚଲା ବୁଦ୍ଧିଃ(ସ୍), ତଦା ୟୋଗମବାପ୍ସ୍ୟସି ॥2.53॥",
    
    "ଅର୍ଜୁନ ଉବାଚ\nସ୍ଥିତପ୍ରଜ୍ଞସ୍ୟ କା ଭାଷା, ସମାଧିସ୍ଥସ୍ୟ କେଶବ\nସ୍ଥିତଧୀଃ(ଖ୍) କିଂ(ମ୍) ପ୍ରଭାଷେତ, କିମାସୀତ ବ୍ରଜେତ କିମ୍ ॥2.54॥",
    
    "ଶ୍ରୀ ଭଗବାନୁବାଚ\nପ୍ରଜହାତି ୟଦା କାମାନ୍, ସର୍ବାନ୍ପାର୍ଥ ମନୋଗତାନ୍\nଆତ୍ମନ୍ୟେବାତ୍ମନା ତୁଷ୍ଟଃ(ସ୍), ସ୍ଥିତପ୍ରଜ୍ଞସ୍ତଦୋଚ୍ୟତେ ॥2.55॥",
    
    "ଦୁଃଖେଷ୍ୱନୁଦ୍ୱିଗ୍ନମନାଃ(ସ୍), ସୁଖେଷୁ ବିଗତସ୍ପୃହଃ\nବୀତରାଗଭୟକ୍ରୋଧଃ(ସ୍), ସ୍ଥିତଧୀର୍ମୁନିରୁଚ୍ୟତେ ॥2.56॥",
    
    "ୟଃ(ସ୍) ସର୍ବତ୍ରାନଭିସ୍ନେହଃ(ସ୍), ତତ୍ତତ୍ପ୍ରାପ୍ୟ ଶୁଭାଶୁଭମ୍\nନାଭିନନ୍ଦତି ନ ଦ୍ୱେଷ୍ଟି, ତସ୍ୟ ପ୍ରଜ୍ଞା ପ୍ରତିଷ୍ଠିତା ॥2.57॥",
    
    "ୟଦା ସଂହରତେ ଚାୟଂ(ଙ୍), କୂର୍ମୋଽଙ୍ଗାନୀବ ସର୍ବଶଃ\nଇନ୍ଦ୍ରିୟାଣୀନ୍ଦ୍ରିୟାର୍ଥେଭ୍ୟଃ(ସ୍), ତସ୍ୟ ପ୍ରଜ୍ଞା ପ୍ରତିଷ୍ଠିତା ॥2.58॥",
    
    "ବିଷୟା ବିନିବର୍ତନ୍ତେ, ନିରାହାରସ୍ୟ ଦେହିନଃ \nରସବର୍ଜଂ(ମ୍) ରସୋଽପ୍ୟସ୍ୟ, ପରଂ(ନ୍) ଦୃଷ୍ଟ୍ୱା ନିବର୍ତତେ ॥2.59॥",
    
    "ୟତତୋ ହ୍ୟପି କୌନ୍ତେୟ, ପୁରୁଷସ୍ୟ ବିପଶ୍ଚିତଃ\nଇନ୍ଦ୍ରିୟାଣି ପ୍ରମାଥୀନି, ହରନ୍ତି ପ୍ରସଭଂ(ମ୍) ମନଃ ॥2.60॥",
    
    "ତାନି ସର୍ବାଣି ସଂୟମ୍ୟ, ୟୁକ୍ତ ଆସୀତ ମତ୍ପରଃ \nବଶେ ହି ୟସ୍ୟେନ୍ଦ୍ରିୟାଣି, ତସ୍ୟ ପ୍ରଜ୍ଞା ପ୍ରତିଷ୍ଠିତା ॥2.61॥",
    
    "ଧ୍ୟାୟତୋ ବିଷୟାନ୍ପୁଂଂସଃ, ସଙ୍ଗସ୍ତେଷୂପଜାୟତେ\nସଙ୍ଗାତ୍ସଞ୍ଜାୟତେ କାମଃ(ଖ୍), କାମାତ୍କ୍ରୋଧୋଽଭିଜାୟତେ ॥2.62॥",
    
    "କ୍ରୋଧାଦ୍ଭବତି ସମ୍ମୋହଃ(ସ୍), ସମ୍ମୋହାତ୍ସ୍ମୃତିବିଭ୍ରମଃ\nସ୍ମୃତିଭ୍ରଂଶାଦ୍ ବୁଦ୍ଧିନାଶୋ, ବୁଦ୍ଧିନାଶାତ୍ପ୍ରଣଶ୍ୟତି ॥2.63॥",
    
    "ରାଗଦ୍ୱେଷବିୟୁକ୍ତୈସ୍ତୁ, ବିଷୟାନିନ୍ଦ୍ରିୟୈଶ୍ଚରନ୍\nଆତ୍ମବଶ୍ୟୈର୍ବିଧେୟାତ୍ମା, ପ୍ରସାଦମଧିଗଚ୍ଛତି ॥2.64॥",
    
    "ପ୍ରସାଦେ ସର୍ବଦୁଃଖାନାଂ(ମ୍), ହାନିରସ୍ୟୋପଜାୟତେ\nପ୍ରସନ୍ନଚେତସୋ ହ୍ୟାଶୁ, ବୁଦ୍ଧିଃ(ଫ୍) ପର୍ୟବତିଷ୍ଠତେ ॥2.65॥",
    
    "ନାସ୍ତି ବୁଦ୍ଧିରୟୁକ୍ତସ୍ୟ, ନ ଚାୟୁକ୍ତସ୍ୟ ଭାବନା\nନ ଚାଭାବୟତଃ(ଶ୍) ଶାନ୍ତିଃ(ର୍), ଅଶାନ୍ତସ୍ୟ କୁତଃ(ସ୍) ସୁଖମ୍ ॥2.66॥",
    
    "ଇନ୍ଦ୍ରିୟାଣାଂ(ମ୍) ହି ଚରତାଂ(ମ୍), ୟନ୍ମନୋଽନୁବିଧୀୟତେ।\nତଦସ୍ୟ ହରତି ପ୍ରଜ୍ଞାଂ(ୱ୍ଁ) ବାୟୁର୍ନାବମିବାମ୍ଭସି ॥2.67॥",
    
    "ତସ୍ମାଦ୍ୟସ୍ୟ ମହାବାହୋ, ନିଗୃହୀତାନି ସର୍ବଶଃ\nଇନ୍ଦ୍ରିୟାଣୀନ୍ଦ୍ରିୟାର୍ଥେଭ୍ୟଃ(ସ୍), ତସ୍ୟ ପ୍ରଜ୍ଞା ପ୍ରତିଷ୍ଠିତା ॥2.68॥",
    
    "ୟା ନିଶା ସର୍ବଭୂତାନାଂ(ନ୍), ତସ୍ୟାଂ(ଞ) ଜାଗର୍ତି ସଂୟମୀ\nୟସ୍ୟାଂ(ଞ) ଜାଗ୍ରତି ଭୂତାନି, ସା ନିଶା ପଶ୍ୟତୋ ମୁନେଃ ॥2.69॥",
    
    " ଆପୂର୍ୟମାଣମଚଲପ୍ରତିଷ୍ଠଂ(ମ୍),\nସମୁଦ୍ରମାପଃ(ଫ୍) ପ୍ରବିଶନ୍ତି ୟଦ୍ୱତ୍\nତଦ୍ୱତ୍କାମା ୟଂ(ମ୍) ପ୍ରବିଶନ୍ତି ସର୍ବେ,\nସ ଶାନ୍ତିମାପ୍ନୋତି ନ କାମକାମୀ ॥2.70॥",
    
    "ବିହାୟ କାମାନ୍ୟଃ(ସ୍) ସର୍ବାନ୍, ପୁମାଂଶ୍ଚରତି ନିଃସ୍ପୃହଃ\nନିର୍ମମୋ ନିରହଙ୍କାରଃ(ସ୍), ସ ଶାନ୍ତିମଧିଗଚ୍ଛତି ॥2.71॥",
    
    "ଏଷା ବ୍ରାହ୍ମୀ ସ୍ଥିତିଃ(ଫ୍) ପାର୍ଥ, ନୈନାଂ(ମ୍) ପ୍ରାପ୍ୟ ବିମୁହ୍ୟତି\nସ୍ଥିତ୍ୱାସ୍ୟାମନ୍ତକାଲେଽପି, ବ୍ରହ୍ମନିର୍ବାଣମୃଚ୍ଛତି ॥2.72॥",
    
    "ଓଁ ତତ୍ସଦିତି ଶ୍ରୀମଦ୍ଭଗବଦ୍ଗୀତାସୁ ଉପନିଷତ୍ସୁ ବ୍ରହ୍ମବିଦ୍ୟାୟାଂ(ୱ୍ଁ) ୟୋଗଶାସ୍ତ୍ରେ ଶ୍ରୀକୃଷ୍ଣାର୍ଜୁନସଂବାଦେ ସାଙ୍ଖ୍ୟୟୋଗୋ ନାମ ଦ୍ୱିତୀୟୋଽଧ୍ୟାୟଃ ॥2॥"
  ],
  "adhyaya-3" : [
    "ଅର୍ଜୁନ ଉବାଚ\nଜ୍ୟାୟସୀ ଚେତ୍କର୍ମଣସ୍ତେ, ମତା ବୁଦ୍ଧିର୍ଜନାଦନ\nତତ୍କିଂ(ଙ୍) କର୍ମଣି ଘୋରେ ମାଂ(ନ୍), ନିୟୋଜୟସି କେଶବ ॥3.1॥",

    "ବ୍ୟାମିଶ୍ରେଣେବ ବାକ୍ୟେନ, ବୁଦ୍ଧିଂ(ମ୍) ମୋହୟସୀବ ମେ\nତଦେକଂ(ୱ୍ଁ) ବଦ ନିଶ୍ଚିତ୍ୟ, ୟେନ ଶ୍ରେୟୋଽହମାପ୍ନୁୟାମ୍ ॥3.2॥",

    "ଶ୍ରୀଭଗବାନୁବଚ\nଲୋକେଽସ୍ମିନ୍ଦ୍ୱିବିଧା ନିଷ୍ଠା, ପୁରା ପ୍ରୋକ୍ତା ମୟାନଘ\nଜ୍ଞାନୟୋଗେନ ସାଙ୍ଖ୍ୟାନାଂ(ଙ୍), କର୍ମୟୋଗେନ ୟୋଗିନାମ୍ ॥3.3॥",

    "ନ କର୍ମଣାମନାରମ୍ଭାନ୍, ନୈଷ୍କର୍ମ୍ୟଂ(ମ୍) ପୁରୁଷୋଽଶ୍ନୁତେ\nନ ଚ ସନ୍ନ୍ୟସନାଦେବ, ସିଦ୍ଧିଂ(ମ୍) ସମଧିଗଚ୍ଛତି ॥3.4॥",

    "ନ ହି କଶ୍ଚିତ୍କ୍ଷଣମପି, ଜାତୁ ତିଷ୍ଠତ୍ୟକର୍ମକୃତ୍\nକାର୍ୟତେ ହ୍ୟବଶଃ(ଖ୍) କର୍ମ, ସର୍ବଃ(ଫ୍) ପ୍ରକୃତିଜୈର୍ଗୁଣୈଃ ॥3.5॥",

    "କର୍ମେନ୍ଦ୍ରିୟାଣି ସଂୟମ୍ୟ, ୟ ଆସ୍ତେ ମନସା ସ୍ମରନ୍\nଇନ୍ଦ୍ରିୟାର୍ଥାନ୍ୱିମୂଢାତ୍ମା, ମିଥ୍ୟାଚାରଃ(ସ୍) ସ ଉଚ୍ୟତେ ॥3.6॥",

    "ୟସ୍ତ୍ୱିନ୍ଦ୍ରିୟାଣି ମନସା, ନିୟମ୍ୟାରଭତେଽର୍ଜୁନ\nକର୍ମେନ୍ଦ୍ରିୟୈଃ(ଖ୍) କର୍ମୟୋଗମ୍, ଅସକ୍ତଃ(ସ୍) ସ ବିଶିଷ୍ୟତେ ॥3.7॥",

    "ନିୟତଂ(ଙ୍) କୁରୁ କର୍ମ ତ୍ୱଂ(ଙ୍), କର୍ମ ଜ୍ୟାୟୋ ହ୍ୟକର୍ମଣଃ\nଶରୀରୟାତ୍ରାପି ଚ ତେ, ନ ପ୍ରସିଦ୍ଧ୍ୟେଦକର୍ମଣଃ ॥3.8॥",

    "ୟଜ୍ଞାର୍ଥାତ୍କର୍ମଣୋଽନ୍ୟତ୍ର, ଲୋକୋଽୟଂ(ଙ୍) କର୍ମବନ୍ଧନଃ\nତଦର୍ଥଂ(ଙ୍) କର୍ମ କୌନ୍ତେୟ, ମୁକ୍ତସଙ୍ଗଃ(ସ୍) ସମାଚର ॥3.9॥",

    "ସହୟଜ୍ଞାଃ(ଫ୍) ପ୍ରଜାଃ(ସ୍) ସୃଷ୍ଟ୍ୱା, ପୁରୋବାଚ ପ୍ରଜାପତିଃ\nଅନେନ ପ୍ରସବିଷ୍ୟଧ୍ୱମ୍, ଏଷ ବୋଽସ୍ତ୍ୱିଷ୍ଟକାମଧୁକ୍ ॥3.10॥",

    "ଦେବାନ୍ଭାବୟତାନେନ, ତେ ଦେବା ଭାବୟନ୍ତୁ ବଃ\nପରସ୍ପରଂ(ମ୍) ଭାବୟନ୍ତଃ(ଶ୍), ଶ୍ରେୟଃ(ଫ୍) ପରମବାପ୍ସ୍ୟଥ ॥3.11॥",

    "ଇଷ୍ଟାନ୍ଭୋଗାନ୍ହି ବୋ ଦେବା, ଦାସ୍ୟନ୍ତେ ୟଜ୍ଞଭାବିତାଃ\nତୈର୍ଦତ୍ତାନପ୍ରଦାୟୈଭ୍ୟୋ, ୟୋ ଭୁଙ୍କ୍ତେ ସ୍ତେନ ଏବ ସଃ ॥3.12॥",

    "ୟଜ୍ଞଶିଷ୍ଟାଶିନଃ(ସ୍) ସନ୍ତୋ, ମୁଚ୍ୟନ୍ତେ ସର୍ବକିଲ୍ବିଷୈଃ\nଭୁଞ୍ଜତେ ତେ ତ୍ୱଘଂ(ମ୍) ପାପା, ୟେ ପଚନ୍ତ୍ୟାତ୍ମକାରଣାତ୍ ॥3.13॥",

    "ଅନ୍ନାଦ୍ଭବନ୍ତି ଭୂତାନି, ପର୍ଜନ୍ୟାଦନ୍ନସମ୍ଭବଃ\nୟଜ୍ଞାଦ୍ଭବତି ପର୍ଜନ୍ୟୋ, ୟଜ୍ଞଃ(ଖ୍) କର୍ମସମୁଦ୍ଭବଃ ॥3.14॥",

    "କର୍ମ ବ୍ରହ୍ମୋଦ୍ଭବଂ(ୱ୍ଁ) ବିଦ୍ଧି, ବ୍ରହ୍ମାକ୍ଷରସମୁଦ୍ଭବମ୍\nତସ୍ମାତ୍ସର୍ବଗତଂ(ମ୍) ବ୍ରହ୍ମ, ନିତ୍ୟଂ(ମ୍) ୟଜ୍ଞେ ପ୍ରତିଷ୍ଠିତମ୍ ॥3.15॥",

    "ଏବଂ(ମ୍) ପ୍ରବର୍ତିତଂ(ଞ୍) ଚକ୍ରଂ(ନ୍), ନାନୁବର୍ତୟତୀହ ୟଃ ।\nଅଧାୟୁରିନ୍ଦ୍ରିୟାରାମୋ, ମୋଘଂ(ମ୍) ପାର୍ଥ ସ ଜୀବତି ॥3.16॥",

    "ୟସ୍ତ୍ୱାତ୍ମରତିରେବ ସ୍ୟାଦ୍, ଆତ୍ମତୃପ୍ତଶ୍ଚ ମାନବଃ ।\nଆତ୍ମନ୍ୟେବ ଚ ସନ୍ତୁଷ୍ଟଃ(ସ୍), ତସ୍ୟ କାର୍ୟଂ(ନ୍) ନ ବିଦ୍ୟତେ ॥3.17॥",

    "ନୈବ ତସ୍ୟ କୃତେନାର୍ଥୋ, ନାକୃତେନେହ କଶ୍ଚନ\nନ ଚାସ୍ୟ ସର୍ବଭୂତେଷୁ, କଶ୍ଚିଦର୍ଥବ୍ୟପାଶ୍ରୟଃ ॥3.18॥",

    "ତସ୍ମାଦସକ୍ତଃ(ସ୍) ସତତଂ(ଙ୍), କାର୍ୟ(ଙ୍) କର୍ମ ସମାଚର ।\nଅସକ୍ତୋ ହ୍ୟାଚରନ୍କର୍ମ, ପରମାପ୍ନୋତି ପୂରୁଷଃ ॥3.19॥",

    "କର୍ମଣୈବ ହି ସଂସିଦ୍ଧିମ୍, ଆସ୍ଥିତା ଜନକାଦୟଃ ।\nଲୋକସଙ୍ଗ୍ରହମେବାପି, ସମ୍ପଶ୍ୟନ୍ କର୍ତ୍ତୁମର୍ହସି ॥3.20॥",

    "ୟଦ୍ୟଦାଚରତି ଶ୍ରେଷ୍ଠଃ(ସ୍), ତତ୍ତଦେବେତରୋ ଜନଃ ।\nସ ୟତ୍ପ୍ରମାଣଂ(ଙ୍) କୁରୁତେ, ଲୋକସ୍ତଦନୁବର୍ତତେ ॥3.21॥",

    "ନ ମେ ପାର୍ଥାସ୍ତି କର୍ତବ୍ୟଂ(ନ୍), ତ୍ରିଷୁ ଲୋକେଷୁ କିଞ୍ଚନ ।\nନାନବାପ୍ତମବାପ୍ତବ୍ୟଂ(ୱ୍ଁ), ବର୍ତ ଏବ ଚ କର୍ମଣି ॥3.22॥",

    "ୟଦି ହ୍ୟହଂ(ନ୍) ନ ବର୍ତେୟଂ(ଞ୍) ଜାତୁ କର୍ମଣ୍ୟତନ୍ଦ୍ରିତଃ ।\nମମ ବର୍ତ୍ମାନୁବର୍ତନ୍ତେ, ମନୁଷ୍ୟାଃ(ଫ୍) ପାର୍ଥ ସର୍ବଶଃ ॥3.23॥",

    "ଉତ୍ସୀଦେୟୁରିମେ ଲୋକା, ନ କୁର୍ୟାଂ(ଙ୍) କର୍ମ ଚେଦହମ୍ ।\nସଙ୍କରସ୍ୟ ଚ କର୍ତା ସ୍ୟାମ୍, ଉପହନ୍ୟାମିମାଃ(ଫ୍) ପ୍ରଜାଃ ॥3.24॥",

    "ସକ୍ତାଃ(ଖ୍) କର୍ମଣ୍ୟବିଦ୍ୱାଂସୋ, ୟଥା କୁର୍ବନ୍ତି ଭାରତ ।\nକୁର୍ୟାଦ୍ୱିଦ୍ୱାଂସ୍ତଥାସକ୍ତଃ(ଶ୍), ଚିକୀର୍ଷୁର୍ଲୋକସଙ୍ଗ୍ରହମ୍ ॥3.25॥",

    "ନ ବୁଦ୍ଧିଭେଦଂ(ଞ୍) ଜନୟେଦ୍, ଅଜ୍ଞାନାଂ(ଙ୍) କର୍ମସଙ୍ଗିନାମ୍ ।\nଜୋଷୟେତ୍ସର୍ବକର୍ମାଣି, ବିଦ୍ୱାନ୍ୟୁକ୍ତଃ(ସ୍) ସମାଚରନ୍ ॥3.26॥",

    "ପ୍ରକୃତେଃ(ଖ୍) କ୍ରିୟମାଣାନି, ଗୁଣୈଃ(ଖ୍) କର୍ମାଣି ସର୍ବଶଃ ।\nଅହଙ୍କାରବିମୂଢାତ୍ମା, କର୍ତାହମିତି ମନ୍ୟତେ ॥3.27॥",

    "ତତ୍ତ୍ୱବିତ୍ତୁ ମହାବାହୋ, ଗୁଣକର୍ମବିଭାଗୟୋଃ ।\nଗୁଣା ଗୁଣେଷୁ ବର୍ତନ୍ତ, ଇତି ମତ୍ୱା ନ ସଜ୍ଜତେ ॥3.28॥",

    "ପ୍ରକୃତେର୍ଗୁଣସମ୍ମୂଢାଃ(ସ୍), ସଜ୍ଜନ୍ତେ ଗୁଣକର୍ମସୁ ।\nତାନକୃତ୍ସ୍ନବିଦୋ ମନ୍ଦାନ୍, କୃତ୍ସ୍ନବିନ୍ନ ବିଚାଲୟେତ୍ ॥3.29॥",

    "ମୟି ସର୍ବାଣି କର୍ମାଣି, ସନ୍ନ୍ୟସ୍ୟାଧ୍ୟାତ୍ମଚେତସା\nନିରାଶୀର୍ନିର୍ମମୋ ଭୂତ୍ୱା, ୟୁଧ୍ୟସ୍ୱ ବିଗତଜ୍ୱରଃ ॥3.30॥",

    "ୟେ ମେ ମତମିଦଂ(ନ୍) ନିତ୍ୟମ୍, ଅନୁତିଷ୍ଠନ୍ତି ମାନବାଃ\nଶ୍ରଦ୍ଧାବନ୍ତୋଽନସୂୟନ୍ତୋ, ମୁଚ୍ୟନ୍ତେ ତେଽପି କର୍ମଭିଃ ॥3.31॥",

    "ୟେ ତ୍ୱେତଦଭ୍ୟସୂୟନ୍ତୋ, ନାନୁତିଷ୍ଠନ୍ତି ମେ ମତମ୍\nସର୍ବଜ୍ଞାନବିମୂଢାଂସ୍ତାନ୍, ବିଦ୍ଧି ନଷ୍ଟାନଚେତସଃ ॥3.32॥",

    "ସଦୃଶ(ଞ୍) ଚେଷ୍ଟତେ ସ୍ୱସ୍ୟାଃ(ଫ୍), ପ୍ରକୃତେର୍ଜ୍ଞାନବାନପି\nପ୍ରକୃତି(ୟ୍ଁ) ୟାନ୍ତି ଭୂତାନି, ନିଗ୍ରହଃ(ଖ୍) କିଂ(ଙ୍) କରିଷ୍ୟତି ॥3.33॥",

    "ଇନ୍ଦ୍ରିୟସ୍ୟେନ୍ଦ୍ରିୟସ୍ୟାର୍ଥେ, ରାଗଦ୍ୱେଷୌ ବ୍ୟବସ୍ଥିତୌ\nତୟୋର୍ନ ବଶମାଗଚ୍ଛେତ୍, ତୌ ହ୍ୟସ୍ୟ ପରିପନ୍ଥିନୌ ॥3.34॥",

    "ଶ୍ରେୟାନ୍ସ୍ୱଧର୍ମୋ ବିଗୁଣଃ(ଫ୍), ପରଧର୍ମାତ୍ସ୍ୱନୁଷ୍ଠିତାତ୍\nସ୍ୱଧର୍ମେ ନିଧନଂ(ମ୍) ଶ୍ରେୟଃ(ଫ୍), ପରଧର୍ମୋ ଭୟାବହଃ ॥3.35॥",

    "ଅର୍ଜୁନ ଉବାଚ\nଅଥ କେନ ପ୍ରୟୁକ୍ତୋଽୟଂ(ମ୍), ପାପଂ(ଞ୍) ଚରତି ପୂରୁଷଃ\nଅନିଚ୍ଛନ୍ନପି ବାର୍ଷ୍ଣେୟ, ବଲାଦିବ ନିୟୋଜିତଃ ॥3.36॥",

    "ଶ୍ରୀଭଗବାନୁବାଚ\nକାମ ଏଷ କ୍ରୋଧ ଏଷ, ରଜୋଗୁଣସମୁଦ୍ଭବଃ\nମହାଶନୋ ମହାପାପ୍ମା, ବିଦ୍ଧ୍ୟେନମିହ ବୈରିଣମ୍ ॥3.37॥",

    "ଧୂମେନାବ୍ରିୟତେ ବହ୍ନିଃ(ର୍), ୟଥାଦର୍ଶୋ ମଲେନ ଚ\nୟଥୋଲ୍ବେନାବୃତୋ ଗର୍ଭଃ(ସ୍), ତଥା ତେନେଦମାବୃତମ୍ ॥3.38॥",

    "ଆବୃତଂ(ଞ୍) ଜ୍ଞାନମେତେନ, ଜ୍ଞାନିନୋ ନିତ୍ୟବୈରିଣା\nକାମରୂପେଣ କୌନ୍ତେୟ, ଦୁଷ୍ପୂରେଣାନଲେନ ଚ ॥3.39॥",

    "ଇନ୍ଦ୍ରିୟାଣି ମନୋ ବୁଦ୍ଧିଃ(ର୍), ଅସ୍ୟାଧିଷ୍ଠାନମୁଚ୍ୟତେ\nଏତୈର୍ବିମୋହୟତ୍ୟେଷ, ଜ୍ଞାନମାବୃତ୍ୟ ଦେହିନମ୍ ॥3.40॥",

    "ତସ୍ମାତ୍ତ୍ୱମିନ୍ଦ୍ରିୟାଣ୍ୟାଦୌ, ନିୟମ୍ୟ ଭରତର୍ଷଭ\nପାପ୍ମାନଂ(ମ୍) ପ୍ରଜହି ହ୍ୟେନଂ(ଞ୍), ଜ୍ଞାନବିଜ୍ଞାନନାଶନମ୍ ॥3.41॥",

    "ଇନ୍ଦ୍ରିୟାଣି ପରାଣ୍ୟାହୁଃ(ର୍), ଇନ୍ଦ୍ରିୟେଭ୍ୟଃ(ଫ୍) ପରଂ(ମ୍) ମନଃ\nମନସସ୍ତୁ ପରା ବୁଦ୍ଧିଃ(ର୍), ୟୋ ବୁଦ୍ଧେଃ(ଫ୍) ପରତସ୍ତୁ ସଃ ॥3.42॥",

    "ଏବଂ(ମ୍) ବୁଦ୍ଧେଃ(ଫ୍)ପରଂ(ମ୍) ବୁଦ୍ଧ୍ୱା, ସଂସ୍ତଭ୍ୟାତ୍ମାନମାତ୍ମନା\nଜହି ଶତ୍ରୁଂ(ମ୍) ମହାବାହୋ, କାମରୂପ(ନ୍) ଦୁରାସଦମ୍ ॥3.43॥",

    "ଓଁ ତତ୍ସଦିତି ଶ୍ରୀମଦ୍ଭଗବଦ୍ଗୀତାସୁ ଉପନିଷତ୍ସୁ ବ୍ରହ୍ମବିଦ୍ୟାୟାଂ(ୟ୍ଁ) ୟୋଗଶାସ୍ତ୍ରେ ରୀକୃଷ୍ଣାର୍ଜୁନସଂବାଦେ କର୍ମୟୋଗୋ ନାମ ତୃତୀୟୋଽଧ୍ୟାୟଃ ॥3॥"
  ],
  "adhyaya-4" : [
    "ଶ୍ରୀଭଗବାନୁବାଚ\nଇମଂ(ମ୍) ବିବସ୍ୱତେ 'ୟୋଗଂ(ମ୍), ପ୍ରୋକ୍ତବାନହମବ୍ୟୟମ୍।\nବିବସ୍ୱାନ୍ମନବେ ପ୍ରାହ, ମନୁରୀକ୍ଷ୍ୱାକବେଽବ୍ରବୀତ୍ ॥4.1॥",

    "ଏବଂ(ମ୍) ପରମ୍ପରାପ୍ରାପ୍ତମ୍, ଇମଂ(ମ୍) ରାଜର୍ଷୟୋ ବିଦୁଃ।\nସ କାଲେନେହ ମହତା, ୟୋଗୋ ନଷ୍ଟଃ(ଫ୍) ପରନ୍ତପ ॥4.2॥",

    "ସ ଏବାୟଂ(ମ୍) ମୟା ତେଽଦ୍ୟ, ୟୋଗଃ(ଫ୍) ପ୍ରୋକ୍ତଃ(ଫ୍) ପୁରାତନଃ।\nଭକ୍ତୋଽସି ମେ ସଖା ଚେତି, ରହସ୍ୟଂ(ମ୍) ହ୍ୟେତଦୁତ୍ତମମ୍ ॥4.3॥",

    "ଅର୍ଜୁନ ଉବାଚ\nଅପରଂ(ମ୍) ଭବତୋ ଜନ୍ମ, ପରଂ(ଞ୍) ଜନ୍ମ  ବିବସ୍ୱତଃ।\nକଥମେତଦ୍ୱିଜାନୀୟାଂ(ନ୍), ତ୍ୱମାଦୌ ପ୍ରୋକ୍ତବାନିତି ॥4.4॥",

    "ଶ୍ରୀଭଗବାନୁବାଚ\nବହୂନି ମେ ବ୍ୟତୀତାନି, ଜନ୍ମାନି ତବ ଚାର୍ଜୁନ।\nତାନ୍ୟହଂ(ମ୍) ବେଦ ସର୍ବାଣି, ନ ତ୍ୱଂ(ମ୍) ବେତ୍ଥ ପରନ୍ତପ ॥4.5॥",

    "ଅଜୋଽପି ସନ୍ନବ୍ୟୟାତ୍ମା, ଭୂତାନାମୀଶ୍ୱରୋଽପି ସନ୍।\nପ୍ରକୃତିଂ(ମ୍) ସ୍ୱାମଧିଷ୍ଠାୟ, ସମ୍ଭବାମ୍ୟାତ୍ମମାୟୟା ॥4.6॥",

    "ୟଦା ୟଦା ହି ଧର୍ମସ୍ୟ, ଗ୍ଲାନିର୍ଭବତି ଭାରତ।\nଅଭ୍ୟୁତ୍ଥାନମଧର୍ମସ୍ୟ, ତଦାତ୍ମାନଂ(ମ୍) ସୃଜାମ୍ୟହମ୍ ॥4.7॥",

    "ପରିତ୍ରାଣାୟ ସାଧୂନାଂ(ମ୍), ବିନାଶାୟ ଚ ଦୁଷ୍କୃତାମ୍।\nଧର୍ମସଂସ୍ଥାପନାର୍ଥାୟ, ସମ୍ଭବାମି ୟୁଗେ ୟୁଗେ ॥4.8॥",

    "ଜନ୍ମ କର୍ମ ଚ ମେ ଦିବ୍ୟଂ(ମ୍), ଏବଂ(ମ୍) ୟୋ ବେତ୍ତି ତତ୍ତ୍ୱତଃ।\nତ୍ୟକ୍ତ୍ୱା ଦେହଂ(ମ୍) ପୁନର୍ଜନ୍ମ, ନୈତି ମାମେତି ସୋଽର୍ଜୁନ ॥4.9॥",

    "ବୀତରାଗଭୟକ୍ରୋଧା, ମନ୍ମୟା ମାମୁପାଶ୍ରିତାଃ।\nବହବୋ ଜ୍ଞାନତପସା, ପୂତା ମଦ୍ଭାବମାଗତାଃ ॥4.10॥",

    "ୟେ ୟଥା ମାଂ(ମ୍) ପ୍ରପଦ୍ୟନ୍ତେ, ତାଂସ୍ତଥୈବ ଭଜାମ୍ୟହମ୍।\nମମ ବର୍ତ୍ମାନୁବର୍ତନ୍ତେ, ମନୁଷ୍ୟାଃ(ଫ୍) ପାର୍ଥ ସର୍ବଶଃ ॥4.11॥",

    "କାଙ୍କ୍ଷନ୍ତଃ(ଖ୍) କର୍ମଣାଂ(ମ୍) ସିଦ୍ଧିଂ(ମ୍), ୟଜନ୍ତ ଇହ ଦେବତାଃ।\nକ୍ଷିପ୍ରଂ(ମ୍) ହି ମାନୁଷେ ଲୋକେ, ସିଦ୍ଧିର୍ଭବତି କର୍ମଜା ॥4.12॥",

    "ଚାତୁର୍ବର୍ଣ୍ୟଂ(ମ୍) ମୟା ସୃଷ୍ଟଂ(ଙ୍), ଗୁଣକର୍ମବିଭାଗଶଃ।\nତସ୍ୟ କର୍ତାରମପି ମାଂ(ମ୍), ବିଦ୍ଧ୍ୟକର୍ତାରମବ୍ୟୟମ୍ ॥4.13॥",

    "ନ ମାଂ(ଙ୍) କର୍ମାଣି ଲିମ୍ପନ୍ତି,  ନ ମେ କର୍ମଫଲେ ସ୍ପୃହା।\nଇତି ମାଂ(ମ୍) ୟୋଽଭିଜାନାତି, କର୍ମଭିର୍ନ ସ ବଧ୍ୟତେ ॥4.14॥",

    "ଏବଂ ଜ୍ଞାତ୍ବା କୃତଂ କର୍ମ ପୂର୍ବୈରପି ମୁମୁକ୍ଷୁଭିଃ ।\nକୁରୁ କର୍ମୈବ ତସ୍ମାତ୍ତ୍ବଂ ପୂର୍ବୈଃ ପୂର୍ବତରଂ କୃତମ୍ ॥4.15॥",

    "କିଂ(ଙ୍) କର୍ମ କିମକର୍ମେତି, କବୟୋଽପ୍ୟତ୍ର ମୋହିତାଃ।\nତତ୍ତେ କର୍ମ ପ୍ରବକ୍ଷ୍ୟାମି, ୟଜ୍ଜ୍ଞାତ୍ୱା  ମୋକ୍ଷସେଽଶୁଭାତ୍ ॥4.16॥",

    "କର୍ମଣୋ ହ୍ୟପି ବୋଦ୍ଧବ୍ୟଂ(ମ୍), ବୋଦ୍ଧବ୍ୟଂ(ଞ୍) ଚ ବିକର୍ମଣଃ।\nଅକର୍ମଣଶ୍ଚ ବୋଦ୍ଧବ୍ୟଂ(ଙ୍), ଗହନା କର୍ମଣୋ ଗତିଃ ॥4.17॥",

    "କର୍ମଣ୍ୟକର୍ମ ୟଃ(ଫ୍) ପଶ୍ୟେଦ୍, ଅକର୍ମଣି ଚ କର୍ମ ୟଃ।\nସ ବୁଦ୍ଧିମାନ୍ମନୁଷ୍ୟେଷୁ, ସ ୟୁକ୍ତଃ(ଖ୍) କୃତ୍ସ୍ନକର୍ମକୃତ୍ ॥4.18॥",

    "ୟସ୍ୟ ସର୍ବେ ସମାରମ୍ଭାଃ(ଖ୍), କାମସଙ୍କଲ୍ପବର୍ଜିତାଃ।\nଜ୍ଞାନାଗ୍ନିଦଗ୍ଧକର୍ମାଣଂ(ନ୍), ତମାହୁଃ(ଫ୍) ପଣ୍ଡିତଂ(ମ୍) ବୁଧାଃ ॥4.19॥",

    " ତ୍ୟକ୍ତ୍ୱା କର୍ମଫଲାସଙ୍ଗଂ(ନ୍), ନିତ୍ୟତୃପ୍ତୋ ନିରାଶ୍ରୟଃ।\nକର୍ମଣ୍ୟଭିପ୍ରବୃତ୍ତୋଽପି, ନୈବ କିଞ୍ଚିତ୍କରୋତି ସଃ ॥4.20॥",

    "ନିରାଶୀର୍ୟତଚିତ୍ତାତ୍ମା, ତ୍ୟକ୍ତସର୍ବପରିଗ୍ରହଃ।\nଶାରୀରଂ(ଙ୍) କେବଲଂ(ଙ୍) କର୍ମ, କୁର୍ବନ୍ନାପ୍ନୋତି କିଲ୍ବିଷମ୍ ॥4.21॥",

    "ୟଦୃଚ୍ଛାଲାଭସନ୍ତୁଷ୍ଟୋ, ଦ୍ୱନ୍ଦ୍ୱାତୀତୋ ବିମତ୍ସରଃ।\nସମଃ(ସ୍) ସିଦ୍ଧାବସିଦ୍ଧୌ ଚ, କୃତ୍ୱାପି ନ ନିବଧ୍ୟତେ ॥4.22॥",

    "ଗତସଙ୍ଗସ୍ୟ ମୁକ୍ତସ୍ୟ, ଜ୍ଞାନାବସ୍ଥିତଚେତସଃ।\nୟଜ୍ଞାୟାଚରତଃ(ଖ୍) କର୍ମ, ସମଗ୍ରଂ(ମ୍) ପ୍ରବିଲୀୟତେ ॥4.23॥",

    "ବ୍ରହ୍ମାର୍ପଣଂ(ମ୍) ବ୍ରହ୍ମ ହବିଃ(ର୍), ବ୍ରହ୍ମାଗ୍ନୌ ବ୍ରହ୍ମଣା ହୁତମ୍।\nବ୍ରହ୍ମୈବ ତେନ ଗନ୍ତବ୍ୟଂ(ମ୍), ବ୍ରହ୍ମକର୍ମସମାଧିନା ॥4.24॥",

    "ଦୈବମେବାପରେ ୟଜ୍ଞଂ(ମ୍), ୟୋଗିନଃ(ଫ୍) ପର୍ୟୁପାସତେ।\nବ୍ରହ୍ମାଗ୍ନାବପରେ ୟଜ୍ଞଂ(ମ୍), ୟଜ୍ଞେନୈବୋପଜୁହ୍ୱତି ॥4.25॥",

    "ଶ୍ରୋତ୍ରାଦୀନୀନ୍ଦ୍ରିୟାଣ୍ୟନ୍ୟେ, ସଂୟମାଗ୍ନିଷୁ ଜୁହ୍ୱତି।\nଶବ୍ଦାଦୀନ୍ବିଷୟାନନ୍ୟ, ଇନ୍ଦ୍ରିୟାଗ୍ନିଷୁ ଜୁହ୍ୱତି ॥4.26॥",

    "ସର୍ବାଣୀନ୍ଦ୍ରିୟକର୍ମାଣି, ପ୍ରାଣକର୍ମାଣି ଚାପରେ।\nଆତ୍ମସଂୟମୟୋଗାଗ୍ନୌ, ଜୁହ୍ୱତି ଜ୍ଞାନଦୀପିତେ ॥4.27॥",

    "ଦ୍ରବ୍ୟୟଜ୍ଞାସ୍ତପୋୟଜ୍ଞା, ୟୋଗୟଜ୍ଞାସ୍ତଥାପରେ।\nସ୍ୱାଧ୍ୟାୟଜ୍ଞାନୟଜ୍ଞାଶ୍ଚ, ୟତୟଃ(ସ୍) ସଂଶିତବ୍ରତାଃ ॥4.28॥",

    "ଅପାନେ ଜୁହ୍ୱତି ପ୍ରାଣଂ(ମ୍), ପ୍ରାଣେଽପାନଂ(ନ୍) ତଥାପରେ।\nପ୍ରାଣାପାନଗତୀ ରୁଦ୍ଧ୍ୱା, ପ୍ରାଣାୟାମପରାୟଣାଃ ॥4.29॥",

    "ଅପରେ ନିୟତାହାରାଃ(ଫ୍), ପ୍ରାଣାନ୍ପ୍ରାଣେଷୁ ଜୁହ୍ୱତି।\nସର୍ବେଽପ୍ୟେତେ ୟଜ୍ଞବିଦୋ, ୟଜ୍ଞକ୍ଷପିତକଲ୍ମଷାଃ ॥4.30॥",

    "ୟଜ୍ଞଶିଷ୍ଟାମୃତଭୁଜୋ, ୟାନ୍ତି ବ୍ରହ୍ମ ସନାତନମ୍।\nନାୟଂ(ମ୍) ଲୋକୋଽସ୍ତ୍ୟୟଜ୍ଞସ୍ୟ, କୁତୋଽନ୍ୟଃ(ଖ୍) କୁରୁସତ୍ତମ ॥4.31॥",

    "ଏବଂ(ମ୍) ବହୁବିଧା ୟଜ୍ଞା, ବିତତା ବ୍ରହ୍ମଣୋ ମୁଖେ।\nକର୍ମଜାନ୍ବିଦ୍ଧି ତାନ୍ସର୍ବାନ୍, ଏବଂ(ଞ୍) ଜ୍ଞାତ୍ୱା ବିମୋକ୍ଷ୍ୟସେ ॥4.32॥",

    "ଶ୍ରେୟାନ୍ଦ୍ରବ୍ୟମୟାଦ୍ୟଜ୍ଞାଜ୍, ଜ୍ଞାନୟଜ୍ଞଃ(ଫ୍) ପରନ୍ତପ।\nସର୍ବଂ(ଙ୍) କର୍ମାଖିଲଂ(ମ୍) ପାର୍ଥ, ଜ୍ଞାନେ ପରିସମାପ୍ୟତେ ॥4.33॥",

    "ତଦ୍ୱିଦ୍ଧି ପ୍ରଣିପାତେନ, ପରିପ୍ରଶ୍ନେନ ସେବୟା।\nଉପଦେକ୍ଷ୍ୟନ୍ତି ତେ ଜ୍ଞାନଂ(ଞ୍), ଜ୍ଞାନିନସ୍ତତ୍ତ୍ୱଦର୍ଶିନଃ ॥4.34॥",

    "ୟଜ୍ଜ୍ଞାତ୍ୱା ନ ପୁନର୍ମୋହମ୍, ଏବଂ(ମ୍) ୟାସ୍ୟସି ପାଣ୍ଡବ।\nୟେନ ଭୂତାନ୍ୟଶେଷେଣ, ଦ୍ରକ୍ଷ୍ୟସ୍ୟାତ୍ମନ୍ୟଥୋ ମୟି ॥4.35॥",

    "ଅପି ଚେଦସି ପାପେଭ୍ୟଃ(ସ୍), ସର୍ବେଭ୍ୟଃ(ଫ୍) ପାପକୃତ୍ତମଃ।\nସର୍ବଂ(ଞ୍) ଜ୍ଞାନପ୍ଲବେନୈବ, ବୃଜିନଂ(ମ୍) ସନ୍ତରିଷ୍ୟସି ॥4.36॥",

    "ୟଥୈଧାଂସି ସମିଦ୍ଧୋଽଗ୍ନିଃ(ର୍), ଭସ୍ମସାତ୍କୁରୁତେଽର୍ଜୁନ।\nଜ୍ଞାନାଗ୍ନିଃ(ସ୍) ସର୍ବକର୍ମାଣି, ଭସ୍ମସାତ୍କୁରତେ ତଥା ॥4.37॥",

    "ନ ହି ଜ୍ଞାନେନ ସଦୃଶଂ(ମ୍), ପବିତ୍ରମିହ ବିଦ୍ୟତେ।\nତତ୍ସ୍ୱୟଂ(ମ୍) ୟୋଗସଂସିଦ୍ଧଃ(ଖ୍), କାଲେନାତ୍ମନି ବିନ୍ଦତି ॥4.38॥",

    "ଶ୍ରଦ୍ଧାବାଁଲ୍ଲଭତେ ଜ୍ଞାନଂ(ନ୍), ତତ୍ପରଃ(ସ୍) ସଂୟତେନ୍ଦ୍ରିୟଃ।\nଜ୍ଞାନଂ(ମ୍) ଲବ୍ଧ୍ୱା ପରାଂ(ମ୍) ଶାନ୍ତିମ୍, ଅଚିରେଣାଧିଗଚ୍ଛତି ॥4.39॥",

    "ଅଜ୍ଞଶ୍ଚାଶ୍ରଦ୍ଦଧାନଶ୍ଚ, ସଂଶୟାତ୍ମା ବିନଶ୍ୟତି।\nନାୟଂ(ମ୍) ଲୋକୋଽସ୍ତି ନ ପରୋ, ନ ସୁଖଂ(ମ୍) ସଂଶୟାତ୍ମନଃ ॥4.40॥",

    "ୟୋଗସନ୍ନ୍ୟସ୍ତକର୍ମାଣଂ(ଞ୍), ଜ୍ଞାନସଞ୍ଛିନ୍ନସଂଶୟମ୍।\nଆତ୍ମବନ୍ତଂ(ନ୍) ନ କର୍ମାଣି, ନିବଧ୍ନନ୍ତି ଧନଞ୍ଜୟ ॥4.41॥",

    "ତସ୍ମାଦଜ୍ଞାନସମ୍ଭୂତଂ(ମ୍), ହୃତ୍ସ୍ଥଂ(ଞ୍) ଜ୍ଞାନାସିନାତ୍ମନଃ।\nଛିତ୍ତ୍ୱୈନଂ(ମ୍) ସଂଶୟଂ(ମ୍) ୟୋଗମ୍, ଆତିଷ୍ଠୋତ୍ତିଷ୍ଠ ଭାରତ ॥4.42॥",

    "ଓଁ ତତ୍ସଦିତି ଶ୍ରୀମଦ୍ଭଗବଦ୍ଗୀତାସୁ ଉପନିଷତ୍ସୁ ବ୍ରହ୍ମବିଦ୍ୟାୟାଂ(ୟ୍ଁ) ୟୋଗଶାସ୍ତ୍ରେ ଶ୍ରୀକୃଷ୍ଣାର୍ଜୁନସଂବାଦେ ଜ୍ଞାନକର୍ମସନ୍ନ୍ୟାସୟୋଗୋ ନାମ ଚତୁର୍ଥୋSଧ୍ୟାୟଃ ॥4॥"
  ],
  "adhyaya-5" : [
    "ଅର୍ଜୁନ ଉବାଚ\nସନ୍ୟାସଂ(ଙ୍) କର୍ମଣାଂ(ଙ୍) କୃଷ୍ଣ, ପୁନର୍ୟୋଗଂ(ଞ୍) ଚ ଶଂସସି \nୟଚ୍ଛ୍ରେୟ ଏତୟୋରେକଂ(ନ୍) ତନ୍ମେ ବ୍ରୂହି ସୁନିଶ୍ଚିତମ୍ ॥5.1॥",

    "ଶ୍ରୀଭଗବାନୁବାଚ\nସନ୍ନ୍ୟାସଃ(ଖ୍) କର୍ମୟୋଗଶ୍ଚ, ନିଃଶ୍ରେୟସକରାବୁଭୌ\nତୟୋସ୍ତୁ କର୍ମସନ୍ୟାସାତ୍, କର୍ମୟୋଗୋ ବିଶିଷ୍ୟତେ ॥5.2॥",

    "ଜ୍ଞେୟଃ(ସ୍) ସ ନିତ୍ୟସନ୍ନ୍ୟାସୀ, ୟୋ ନ ଦ୍ୱେଷ୍ଟି ନ କାଙ୍କ୍ଷତି\nନିର୍ଦ୍ୱନ୍ଦ୍ୱୋ ହି ମହାବାହୋ, ସୁଖଂ(ମ୍) ବନ୍ଧାତ୍ପ୍ରମୁଚ୍ୟତେ ॥5.3॥",

    "ସାଙ୍ଖ୍ୟୟୋଗୌ ପୃଥଗ୍ବାଲାଃ(ଫ୍), ପ୍ରବଦନ୍ତି ନ ପଣ୍ଡିତାଃ\nଏକମପ୍ୟାସ୍ଥିତଃ(ସ୍) ସମ୍ୟଗ୍, ଉଭୟୋର୍ବିନ୍ଦତେ ଫଲମ୍ ॥5.4॥",

    "ୟତ୍ସାଙ୍ଖ୍ୟୈଃ(ଫ୍) ପ୍ରାପ୍ୟତେ ସ୍ଥାନଂ(ନ୍), ତଦ୍ଦ୍ୟୋଗୈରପି ଗମ୍ୟତେ\nଏକଂ(ମ୍) ସାଙ୍ଖ୍ୟେଂ(ଞ୍) ଚ ୟୋଗଂ(ଞ୍) ଚ, ୟଃ(ଫ୍) ପଶ୍ୟତି ସ ପଶ୍ୟତି ॥5.5॥",

    "ସନ୍ୟାସସ୍ତୁ ମହାବାହୋ, ଦୁଃଖମାପ୍ତୁମୟୋଗତଃ\nୟୋଗୟୁକ୍ତୋ ମୁନିର୍ବ୍ରହ୍ମ, ନଚିରେଣାଧିଗଚ୍ଛତି ॥5.6॥",

    "ୟୋଗୟୁକ୍ତୋ ବିଶୁଦ୍ଧାତ୍ମା, ବିଜିତାତ୍ମା ଜିତେନ୍ଦ୍ରିୟଃ\nସର୍ବଭୂତାତ୍ମଭୂତାତ୍ମା, କୁର୍ବନ୍ନପି ନ ଲିପ୍ୟତେ ॥5.7॥",

    "ନୈବ କିଞ୍ଚିତ୍କରୋମୀତି, ୟୁକ୍ତୋ ମନ୍ୟେତ ତତ୍ତ୍ୱବିତ୍\nପଶ୍ୟଞ୍ଶୃଣ୍ୱନ୍ସ୍ପୃଶଞ୍ଜିଘ୍ରନ୍, ନଶ୍ନନ୍ଗଚ୍ଛନ୍ସ୍ୱପଞ୍ଶ୍ୱସନ୍ ॥5.8॥",

    "ପ୍ରଲପନ୍ୱିସୃଜନ୍ଗୃହ୍ଣନ୍, ନୁନ୍ମିଷନ୍ନିମିଷନ୍ନପି\nଇନ୍ଦ୍ରିୟାଣୀନ୍ଦ୍ରିୟାର୍ଥେଷୁ, ବର୍ତନ୍ତ ଇତି ଧାରୟନ୍ ॥5.9॥",

    "ବ୍ରହ୍ମଣ୍ୟାଧ୍ୟାୟ କର୍ମାଣି, ସଙ୍ଗଂ(ନ୍) ତ୍ୟକ୍ତ୍ୱା କରୋତି ୟଃ\nଲିପ୍ୟତେ ନ ସ ପାପେନ, ପଦ୍ମପତ୍ରମିବାମ୍ଭସା ॥5.10॥",

    "କାୟେନ ମନସା ବୁଦ୍ଧ୍ୟା, କେବଲୈରିନ୍ଦ୍ରିୟୈରପି\nୟୋଗିନଃ(ଖ୍) କର୍ମ କୁର୍ବନ୍ତି, ସଙ୍ଗଂ(ନ୍) ତ୍ୟକ୍ତ୍ୱାତ୍ମଶୁଦ୍ଧୟେ ॥5.11॥",

    "ୟୁକ୍ତଃ(ଖ୍) କର୍ମଫଲଂ(ନ୍) ତ୍ୟକ୍ତ୍ୱା, ଶାନ୍ତିମାପ୍ନୋତି ନୈଷ୍ଠିକୀମ୍\nଅୟୁକ୍ତଃ(ଖ୍) କାମକାରେଣ, ଫଲେ ସକ୍ତୋ ନିବଧ୍ୟତେ ॥5.12॥",

    "ସର୍ବକର୍ମାଣି ମନସା, ସନ୍ନ୍ୟସ୍ୟାସ୍ତେ ସୁଖଂ(ମ୍) ବଶୀ\nନବଦ୍ୱାରେ ପୁରେ ଦେହୀ, ନୈବ କୁର୍ବନ୍ନ କାରୟନ୍ ॥5.13॥",

    "ନ କର୍ତୃତ୍ୱଂ(ନ୍) ନ କର୍ମାଣି, ଲୋକସ୍ୟ ସୃଜତି ପ୍ରଭୁଃ\nନ କର୍ମଫଲସଂୟୋଗଂ(ମ୍), ସ୍ୱଭାବସ୍ତୁ ପ୍ରବର୍ତତେ ॥5.14॥",

    "ନାଦତ୍ତେ କସ୍ୟଚିତ୍ପାପଂ(ନ୍), ନ ଚୈବ ସୁକୃତଂ(ମ୍) ବିଭୁଃ\nଅଜ୍ଞାନେନାବୃତଂ(ଞ୍)  ଜ୍ଞାନଂ(ନ୍), ତେନ ମୁହ୍ୟନ୍ତି ଜନ୍ତବଃ ॥5.15॥",

    "ଜ୍ଞାନେନ ତୁ ତଦଜ୍ଞାନଂ(ମ୍), ୟେଷାଂ(ନ୍) ନାଶିତମାତ୍ମନଃ\nତେଷାମାଦିତ୍ୟବଜ୍ଜ୍ଞାନଂ(ମ୍), ପ୍ରକାଶୟତି ତତ୍ପରମ୍ ॥5.16॥",

    "ତଦ୍ବୁଦ୍ଧୟସ୍ତଦାତ୍ମାନଃ(ସ୍), ତନ୍ନିଷ୍ଠାସ୍ତତ୍ପରାୟଣାଃ\nଗଚ୍ଛନ୍ତ୍ୟପୁନରାବୃତ୍ତିଂ(ଞ୍), ଜ୍ଞାନନିର୍ଧୂତକଲ୍ମଷାଃ ॥5.17॥",

    "ବିଦ୍ୟାବିନୟସମ୍ପନ୍ନେ, ବ୍ରାହ୍ମଣେ ଗବି ହସ୍ତିନି\nଶୁନି ଚୈବ ଶ୍ୱପାକେ ଚ, ପଣ୍ଡିତାଃ(ସ୍) ସମଦର୍ଶିନଃ ॥5.18॥",

    "ଇହୈବ ତୈର୍ଜିତଃ(ସ୍) ସର୍ଗୋ, ୟେଷାଂ(ମ୍) ସାମ୍ୟେ ସ୍ଥିତଂ(ମ୍) ମନଃ।\nନିର୍ଦୋଷଂ(ମ୍) ହି ସମଂ(ମ୍) ବ୍ରହ୍ମ, ତସ୍ମାଦ୍ ବ୍ରହ୍ମଣି ତେ ସ୍ଥିତାଃ ॥5.19॥",

    "ନ ପ୍ରହୃଷ୍ୟେତ୍ପ୍ରିୟଂ(ମ୍) ପ୍ରାପ୍ୟ, ନୋଦ୍ୱିଜେତ୍ପ୍ରାପ୍ୟ ଚାପ୍ରିୟମ୍\nସ୍ଥିରବୁଦ୍ଧିରସମ୍ମୂଢୋ, ବ୍ରହ୍ମବିଦ୍ ବ୍ରହ୍ମଣି  ସ୍ଥିତଃ ॥5.20॥",

    "ବାହ୍ୟସ୍ପର୍ଶେଷ୍ୱସକ୍ତାତ୍ମା, ବିନ୍ଦତ୍ୟାତ୍ମନି ୟତ୍ସୁଖମ୍\nସ ବ୍ରହ୍ମୟୋଗୟୁକ୍ତାତ୍ମା, ସୁଖମକ୍ଷୟମଶ୍ନୁତେ ॥5.21॥",

    "ୟେ ହି ସଂସ୍ପର୍ଶଜା ଭୋଗା, ଦୁଃଖୟୋନୟ ଏବ ତେ\nଆଦ୍ୟନ୍ତବନ୍ତଃ(ଖ୍) କୌନ୍ତେୟ, ନ ତେଷୁ ରମତେ ବୁଧଃ ॥5.22॥",

    "ଶକ୍ନୋତୀହୈବ ୟଃ(ସ୍) ସୋଢୁଂ(ମ୍), ପ୍ରାକ୍ଶରୀରବିମୋକ୍ଷଣାତ୍\nକାମକ୍ରୋଧୋଦ୍ଭବଂ(ମ୍) ବେଗଂ(ମ୍), ସ ୟୁକ୍ତଃ(ସ୍) ସ ସୁଖୀ ନରଃ ॥5.23॥",

    "ୟୋଽନ୍ତଃସୁଖୋଽନ୍ତରାରାମଃ(ସ୍), ତଥାନ୍ତର୍ଜ୍ୟୋତିରେବ ୟଃ\nସ ୟୋଗୀ ବ୍ରହ୍ମନିର୍ବାଣଂ(ମ୍), ବ୍ରହ୍ମଭୂତୋଽଧିଗଚ୍ଛତି ॥5.24॥",

    "ଲଭନ୍ତେ ବ୍ରହ୍ମନିର୍ବାଣମ୍, ଋଷୟଃ କ୍ଷୀଣକଲ୍ମଷାଃ\nଛିନ୍ନଦ୍ୱୈଧା ୟତାତ୍ମାନଃ(ସ୍), ସର୍ବଭୂତହିତେ ରତାଃ ॥5.25॥",

    "କାମକ୍ରୋଧବିୟୁକ୍ତାନାଂ(ମ୍), ୟତୀନାଂ(ମ୍) ୟତଚେତସାମ୍\nଅଭିତୋ ବ୍ରହ୍ମନିର୍ବାଣଂ(ମ୍), ବର୍ତତେ ବିଦିତାତ୍ମନାମ୍ ॥5.26॥",

    "ସ୍ପର୍ଶାନ୍କୃତ୍ୱା ବହିର୍ବାହ୍ୟାଂଶ୍, ଚକ୍ଷୁଶ୍ଚୈବାନ୍ତରେ ଭ୍ରୁବୋଃ\nପ୍ରାଣାପାନୌ ସମୌ କୃତ୍ୱା, ନାସାଭ୍ୟନ୍ତରଚାରିଣୌ ॥5.27॥",

    "ୟତେନ୍ଦ୍ରିୟମନୋବୁଦ୍ଧିଃ(ର୍), ମୁନିର୍ମୋକ୍ଷପରାୟଣଃ\nବିଗତେଚ୍ଛାଭୟକ୍ରୋଧୋ, ୟଃ(ସ୍) ସଦା ମୁକ୍ତ ଏବ ସଃ ॥5.28॥",

    "ଭୋକ୍ତାରଂ(ୟ୍ଁ) ୟଜ୍ଞତପସାଂ(ମ୍), ସର୍ବଲୋକମହେଶ୍ୱରମ୍\nସୁହୃଦଂ(ମ୍) ସର୍ବଭୂତାନାଂ(ଞ୍), ଜ୍ଞାତ୍ୱା ମାଂ(ମ୍) ଶାନ୍ତିମୃଚ୍ଛତି ॥5.29॥",

    "ଓଁ ତତ୍ସଦିତି ଶ୍ରୀମଦ୍ଭଗବଦ୍ଗୀତାସୁ ଉପନିଷତ୍ସୁ ବ୍ରହ୍ମବିଦ୍ୟାୟାଂ(ୟ୍ଁ) ୟୋଗଶାସ୍ତ୍ରେ ଶ୍ରୀକୃଷ୍ଣାର୍ଜୁନସଂବାଦେ କର୍ମସନ୍ନ୍ୟାସୟୋଗୋ ନାମ ପଞ୍ଚମୋଽଧ୍ୟାୟଃ।।"
  ],
  "adhyaya-6" : [
    "ଶ୍ରୀଭଗବାନୁବାଚ\nଅନାଶ୍ରିତଃ(ଖ୍) କର୍ମଫଲଂ(ଙ୍), କାର୍ୟ୍ଯ(ଙ୍) କର୍ମ କରୋତି ୟଃ।\nସ ସନ୍ନ୍ୟାସୀ ଚ ୟୋଗୀ ଚ, ନ ନିରଗ୍ନିର୍ନ ଚାକ୍ରିୟଃ ॥6.1॥",

    "ୟଂ(ମ୍) ସନ୍ନ୍ୟାସମିତି ପ୍ରାହୁଃ(ର୍), 'ୟୋଗଂ(ନ୍) ତଂ(ମ୍) ବିଦ୍ଧି ପାଣ୍ଡବ।\nନ ହ୍ୟସନ୍ନ୍ୟସ୍ତସଙ୍କଲ୍ପୋ, ୟୋଗୀ ଭବତି କଶ୍ଚନ ॥6.2॥",

    "ଆରୁରୁକ୍ଷୋର୍ମୁନେର୍ୟୋଗଂ(ଙ୍), କର୍ମ କାରଣମୁଚ୍ୟତେ।\nୟୋଗାରୂଢସ୍ୟ ତସ୍ୟୈବ, ଶମଃ(ଖ୍) କାରଣମୁଚ୍ୟତେ ॥6.3॥",

    "ୟଦା ହି ନେନ୍ଦ୍ରିୟାର୍ଥେଷୁ, ନ କର୍ମସ୍ୱନୁଷଜ୍ଜତେ।\nସର୍ବସଙ୍କଲ୍ପସନ୍ନ୍ୟାସୀ, ୟୋଗାରୂଢସ୍ତଦୋଚ୍ୟତେ ॥6.4॥",

    "ଉଦ୍ଧରେଦାତ୍ମନାତ୍ମାନଂ(ନ୍), ନାତ୍ମାନମବସାଦୟେତ୍।\nଆତ୍ମୈବ ହ୍ୟାତ୍ମନୋ ବନ୍ଧୁଃ(ର୍), ଆତ୍ମୈବ ରିପୁରାତ୍ମନଃ ॥6.5॥",

    "ବନ୍ଧୁରାତ୍ମାତ୍ମନସ୍ତସ୍ୟ, ୟେନାତ୍ମୈବାତ୍ମନା ଜିତଃ।\nଅନାତ୍ମନସ୍ତୁ ଶତ୍ରୁତ୍ୱେ, ବର୍ତେତାତ୍ମୈବଶତ୍ରୁବତ ॥6.6॥",

    "ଜିତାତ୍ମନଃ(ଫ୍) ପ୍ରଶାନ୍ତସ୍ୟ, ପରମାତ୍ମା ସମାହିତଃ।\nଶୀତୋଷ୍ଣସୁଖଦୁଃଖେଷୁ, ତଥା ମାନାପମାନୟୋଃ ॥6.7॥",

    "ଜ୍ଞାନବିଜ୍ଞାନତୃପ୍ତାତ୍ମା, କୂଟସ୍ଥୋ ବିଜିତେନ୍ଦ୍ରିୟଃ।\nୟୁକ୍ତ ଇତ୍ୟୁଚ୍ୟତେ ୟୋଗୀ, ସମଲୋଷ୍ଟାଶ୍ମକାଞ୍ଚନଃ ॥6.8॥",

    "ସୁହୃନ୍ମିତ୍ରାର୍ୟୁଦାସୀନ, ମଧ୍ୟସ୍ଥଦ୍ୱେଷ୍ୟବନ୍ଧୁଷୁ।\nସାଧୁଷ୍ୱପି ଚ ପାପେଷୁ, ସମବୁଦ୍ଧିର୍ବିଶିଷ୍ୟତେ ॥6.9॥",

    "ୟୋଗୀ ୟୁଞ୍ଜୀତ ସତତମ୍, ଆତ୍ମାନଂ(ମ୍) ରହସି ସ୍ଥିତଃ।\nଏକାକୀ ୟତଚିତ୍ତାତ୍ମା, ନିରାଶୀରପରିଗ୍ରହଃ ॥6.10॥",

    "ଶୁଚୌ ଦେଶେ ପ୍ରତିଷ୍ଠାପ୍ୟ, ସ୍ଥିରମାସନମାତ୍ମନଃ।\nନାତ୍ୟୁଚ୍ଛ୍ରିତଂ(ନ୍) ନାତିନୀଚଂ(ଞ), ଚୈଲାଜିନକୁଶୋତ୍ତରମ ॥6.11॥",

    "ତତ୍ରୈକାଗ୍ରଂ(ମ୍) ମନଃ(ଖ୍) କୃତ୍ୱା, ୟତଚିତ୍ତେନ୍ଦ୍ରିୟକ୍ରିୟଃ।\nଉପବିଶ୍ୟାସନେ ୟୁଞ୍ଜ୍ୟାଦ୍, ୟୋଗମାତ୍ମବିଶୁଦ୍ଧୟେ ॥6.12॥",

    "ସମଂ(ଙ୍) କାୟଶିରୋଗ୍ରୀବଂ(ନ୍), ଧାରୟନ୍ନଚଲଂ(ମ୍) ସ୍ଥିରଃ।\nସମ୍ପ୍ରେକ୍ଷ୍ୟ ନାସିକାଗ୍ରଂ(ମ୍) ସ୍ୱଂ(ନ୍), ଦିଶଶ୍ଚାନବଲୋକୟନ ॥6.13॥",

    "ପ୍ରଶାନ୍ତାତ୍ମା ବିଗତଭୀଃ(ର୍), ବ୍ରହ୍ମଚାରିବ୍ରତେ ସ୍ଥିତଃ।\nମନଃ(ସ୍) ସଂୟମ୍ୟ ମଚ୍ଚିତ୍ତୋ, ୟୁକ୍ତ ଆସୀତ ମତ୍ପରଃ ॥6.14॥",

    "ୟୁଞ୍ଜନ୍ନେବଂ(ମ୍) ସଦାତ୍ମାନଂ(ମ୍), ୟୋଗୀ ନିୟତମାନସଃ।\nଶାନ୍ତିଂ(ନ୍) ନିର୍ବାଣପରମାଂ(ମ୍), ମତ୍ସଂସ୍ଥାମଧିଗଚ୍ଛତି ॥6.15॥",

    "ନାତ୍ୟଶ୍ନତସ୍ତୁ ୟୋଗୋଽସ୍ତି, ନ ଚୈକାନ୍ତମନଶ୍ନତଃ।\nନ ଚାତିସ୍ୱପ୍ନଶୀଲସ୍ୟ, ଜାଗ୍ରତୋ ନୈବ ଚାର୍ଜୁନ ॥6.16॥",

    "ୟୁକ୍ତାହାରବିହାରସ୍ୟ, ୟୁକ୍ତଚେଷ୍ଟସ୍ୟ କର୍ମସୁ।\nୟୁକ୍ତସ୍ୱପ୍ନାବବୋଧସ୍ୟ, ୟୋଗୋ ଭବତି ଦୁଃଖହା ॥6.17॥",

    "ୟଦା ବିନିୟତଂ(ଞ) ଚିତ୍ତମ୍, ଆତ୍ମନ୍ୟେବାବତିଷ୍ଠତେ।\nନିଃସ୍ପୃହଃ(ସ୍) ସର୍ବକାମେଭ୍ୟୋ, ୟୁକ୍ତ ଇତ୍ୟୁଚତେ ତଦା ॥6.18॥",

    "ୟଥା ଦୀପୋ ନିବାତସ୍ଥୋ, ନେଙ୍ଗତେ ସୋପମା ସ୍ମୃତା।\nୟୋଗିନୋ ୟତଚିତ୍ତସ୍ୟ, ୟୁଞ୍ଜତୋ ୟୋଗମାତ୍ମନଃ ॥6.19॥",

    "ୟତ୍ରୋପରମତେ ଚିତ୍ତଂ(ନ୍), ନିରୁଦ୍ଧଂ(ମ୍) ୟୋଗସେବୟା।\nୟତ୍ର ଚୈବାତ୍ମନାତ୍ମାନଂ(ମ୍), ପଶ୍ୟନ୍ନାତ୍ମନି ତୁଷ୍ୟତି ॥6.20॥",

    "ସୁଖମାତ୍ୟନ୍ତିକଂ(ମ୍) ୟତ୍ତଦ୍, ବୁଦ୍ଧିଗ୍ରାହ୍ୟମତୀନ୍ଦ୍ରିୟମ୍।\nବେତ୍ତି ୟତ୍ର ନ ଚୈବାୟଂ(ମ୍), ସ୍ଥିତଶ୍ଚଲତି ତତ୍ତ୍ୱତଃ ॥6.21॥",

    "ୟଂ(ମ୍) ଲବ୍ଧ୍ୱା ଚାପରଂ(ମ୍) ଲାଭଂ(ମ୍), ମନ୍ୟତେ ନାଧିକଂ(ନ୍) ତତଃ।\nୟସ୍ମିନ୍ସ୍ଥିତୋ ନ ଦୁଃଖେନ, ଗୁରୁଣାପି ବିଚାଲ୍ୟତେ ॥6.22॥",

    "ତଂ(ମ୍) ବିଦ୍ୟାଦ୍ ଦୁଃଖସଂୟୋଗ, ବିୟୋଗଂ(ମ୍) ୟୋଗସଞ୍ଜ୍ଞିତମ୍।\nସ ନିଶ୍ଚୟେନ ୟୋକ୍ତବ୍ୟୋ, ୟୋଗୋଽନିର୍ବିଣ୍ଣଚେତସା ॥6.23॥",

    "ସଙ୍କଳ୍ପପ୍ରଭବାନ୍କାମାଂସ୍, ତ୍ୟକ୍ତ୍ୱା ସର୍ବାନଶେଷତଃ।\nମନସୈବେନ୍ଦ୍ରିୟଗ୍ରାମଂ(ମ୍), ବିନିୟମ୍ୟ ସମନ୍ତତଃ ॥6.24॥",

    "ଶନୈଃ(ଶ୍) ଶନୈରୁପରମେଦ୍, ବୁଦ୍ଧ୍ୟା ଧୃତିଗୃହୀତୟା।\nଆତ୍ମସଂସ୍ଥଂ(ମ୍) ମନଃ(ଖ୍) କୃତ୍ୱା, ନ କିଞ୍ଚିଦପି ଚିନ୍ତୟେତ ॥6.25॥",

    "ୟତୋ ୟତୋ ନିଶ୍ଚରତି, ମନଶ୍ଚଞ୍ଚଲମସ୍ଥିରମ୍।\nତତସ୍ତତୋ ନିୟମ୍ୟୈତଦ୍, ଆତ୍ମନ୍ୟେବ ବଶଂ(ନ୍) ନୟେତ ॥6.26॥",

    "ପ୍ରଶାନ୍ତମନସଂ(ମ୍) ହ୍ୟେନଂ(ମ୍), ୟୋଗିନଂ(ମ୍) ସୁଖମୁତ୍ତମମ୍।\nଉପୈତି ଶାନ୍ତରଜସଂ(ମ୍), ବ୍ରହ୍ମଭୂତମକଲ୍ମଷମ ॥6.27॥",

    "ୟୁଞ୍ଜନ୍ନେବଂ(ମ୍) ସଦାତ୍ମାନଂ(ମ୍), ୟୋଗୀ ବିଗତକଲ୍ମଷଃ।\nସୁଖେନ ବ୍ରହ୍ମସଂସ୍ପର୍ଶମ୍, ଅତ୍ୟନ୍ତଂ(ମ୍) ସୁଖମଶ୍ନୁତେ ॥6.28॥",

    "ସର୍ବଭୂତସ୍ଥମାତ୍ମାନଂ(ମ୍), ସର୍ବଭୂତାନି ଚାତ୍ମନି।\nଈକ୍ଷତେ ୟୋଗୟୁକ୍ତାତ୍ମା, ସର୍ବତ୍ର ସମଦର୍ଶନଃ ॥6.29॥",

    "ୟୋ ମାଂ(ମ୍) ପଶ୍ୟତି ସର୍ବତ୍ର, ସର୍ବଂ(ଞ) ଚ ମୟି ପଶ୍ୟତି।\nତସ୍ୟାହଂ(ନ୍) ନ ପ୍ରଣଶ୍ୟାମି, ସ ଚ ମେ ନ ପ୍ରଣଶ୍ୟତି ॥6.30॥",

    "ସର୍ବଭୂତସ୍ଥିତଂ(ମ୍) ୟୋ ମାଂ(ମ୍), ଭଜତ୍ୟେକତ୍ୱମାସ୍ଥିତଃ।\nସର୍ବଥା ବର୍ତମାନୋଽପି, ସ ୟୋଗୀ ମୟି ବର୍ତତେ ॥6.31॥",

    "ଆତ୍ମୌପମ୍ୟେନ ସର୍ବତ୍ର, ସମଂ(ମ୍) ପଶ୍ୟତି ୟୋଽର୍ଜୁନ।\nସୁଖଂ(ମ୍) ବା ୟଦି ବା ଦୁଃଖଂ(ମ୍), ସ ୟୋଗୀ ପରମୋ ମତଃ ॥6.32॥",

    "ଅର୍ଜୁନ ଉବାଚ\nୟୋଽୟଂ(ମ୍) ୟୋଗସ୍ତ୍ୱୟା ପ୍ରୋକ୍ତଃ(ସ୍), ସାମ୍ୟେନ ମଧୁସୂଦନ।\nଏତସ୍ୟାହଂ(ନ୍) ନ ପଶ୍ୟାମି, ଚଞ୍ଚଲତ୍ୱାତ୍ସ୍ଥିତିଂ(ମ୍) ସ୍ଥିରାମ ॥6.33॥",

    "ଚଞ୍ଚଲଂ(ମ୍) ହି ମନଃ(ଖ୍) କୃଷ୍ଣ, ପ୍ରମାଥି ବଲବଦ୍ଦୃଢମ୍।\nତସ୍ୟାହଂ(ନ୍) ନିଗ୍ରହଂ(ମ୍) ମନ୍ୟେ, ବାୟୋରିବ ସୁଦୁଷ୍କରମ ॥6.34॥",

    "ଶ୍ରୀଭଗବାନୁବାଚ\nଅସଂଶୟଂ(ମ୍) ମହାବାହୋ, ମନୋ ଦୁର୍ନିଗ୍ରହଂ(ଞ) ଚଲମ୍।\nଅଭ୍ୟାସେନ ତୁ କୌନ୍ତେୟ, ବୈରାଗ୍ୟେଣେ ଚ ଗୃହ୍ୟତେ ॥6.35॥",

    "ଅସଂୟତାତ୍ମନା ୟୋଗୋ, ଦୁଷ୍ପ୍ରାପ ଇତି ମେ ମତିଃ।\nବଶ୍ୟାତ୍ମନା ତୁ ୟତତା, ଶକ୍ୟୋଽବାପ୍ତୁମୁପାୟତଃ ॥6.36॥",

    "ଅର୍ଜୁନ ଉବାଚhh\nଅୟତିଃ(ଶ୍) ଶ୍ରଦ୍ଧୟୋପେତୋ, ୟୋଗାଚ୍ଚଲିତମାନସଃ।\nଅପ୍ରାପ୍ୟ ୟୋଗସଂସିଦ୍ଧିଂ(ଙ୍), କାଂ(ଙ୍) ଗତିଂ(ଙ୍) କୃଷ୍ଣ ଗଚ୍ଛତି ॥6.37॥",

    "କଚ୍ଚିନ୍ନୋଭୟବିଭ୍ରଷ୍ଟଃ(ଶ୍), ଛିନ୍ନାଭ୍ରମିବ ନଶ୍ୟତି।\nଅପ୍ରତିଷ୍ଠୋ ମହାବାହୋ, ବିମୂଢୋ ବ୍ରହ୍ମଣଃ(ଫ୍) ପଥି ॥6.38॥",

    "ଏତନ୍ମେ ସଂଶୟଂ(ଙ୍) କୃଷ୍ଣ, ଛେତ୍ତୁମର୍ହସ୍ୟଶେଷତଃ।\nତ୍ୱଦନ୍ୟଃ(ସ୍) ସଂଶୟସ୍ୟାସ୍ୟ, ଛେତ୍ତା ନ ହ୍ୟୁପପଦ୍ୟତେ ॥6.39॥",

    "ଶ୍ରୀଭଗବାନୁବାଚ\nପାର୍ଥ ନୈବେହ ନାମୁତ୍ର, ବିନାଶସ୍ତସ୍ୟ ବିଦ୍ୟତେ।\nନ ହି କଲ୍ୟାଣକୃତ୍କଶ୍ଚିଦ୍, ଦୁର୍ଗତିଂ(ନ୍) ତାତ ଗଚ୍ଛତି ॥6.40॥",

    "ପ୍ରାପ୍ୟ ପୁଣ୍ୟକୃତାଂ(ମ୍) ଲୋକାନ୍, ଉଷିତ୍ୱା ଶାଶ୍ୱତୀଃ(ସ୍) ସମାଃ।\nଶୁଚୀନାଂ(ମ୍) ଶ୍ରୀମତାଂ(ଙ୍) ଗେହେ, ୟୋଗଭ୍ରଷ୍ଟୋଽଭିଜାୟତେ ॥6.41॥",

    "ଅଥବା ୟୋଗିନାମେବ, କୁଲେ ଭବତି ଧୀମତାମ୍।\nଏତଦ୍ଧି ଦୁର୍ଲଭତରଂ(ମ୍), ଲୋକେ ଜନ୍ମ ୟଦୀଦୃଶମ ॥6.42॥",

    "ତତ୍ର ତଂ(ମ୍) ବୁଦ୍ଧିସଂୟୋଗଂ(ମ୍), ଲଭତେ ପୌର୍ବଦେହିକମ୍।\nୟତତେ ଚ ତତୋ ଭୂୟଃ(ସ୍), ସଂସିଦ୍ଧୌ କୁରୁନନ୍ଦନ ॥6.43॥",

    "ପୂର୍ବାଭ୍ୟାସେନ ତେନୈବ, ହ୍ରିୟତେ ହ୍ୟବଶୋଽପି ସଃ।\nଜିଜ୍ଞାସୁରପି ୟୋଗସ୍ୟ, ଶବ୍ଦବ୍ରହ୍ମାତିବର୍ତତେ ॥6.44॥",

    "ପ୍ରୟତ୍ନାଦ୍ୟତମାନସ୍ତୁ, ୟୋଗୀ ସଂଶୁଦ୍ଧକିଲ୍ବିଷଃ।\nଅନେକଜନ୍ମସଂସିଦ୍ଧଃ(ସ୍), ତତୋ ୟାତି ପରାଂ(ଙ୍) ଗତିମ ॥6.45॥",

    "ତପସ୍ୱିଭ୍ୟୋଽଧିକୋ ୟୋଗୀ, ଜ୍ଞାନିଭ୍ୟୋଽପି ମତୋଽଧିକଃ।\nକର୍ମିଭ୍ୟଶ୍ଚାଧିକୋ ୟୋଗୀ, ତସ୍ମାଦ୍ୟୋଗୀ ଭବାର୍ଜୁନ ॥6.46॥",

    "ୟୋଗିନାମପି ସର୍ବେଷାଂ(ମ୍), ମଦ୍ଗତେନାନ୍ତରାତ୍ମନା।\nଶ୍ରଦ୍ଧାବାନ୍ଭଜତେ ୟୋ ମାଂ(ମ୍), ସ ମେ ୟୁକ୍ତତମୋ ମତଃ ॥6.47॥",

    "ଓଁ ତତ୍ସଦିତି ଶ୍ରୀମଦ୍ଭଗବଦ୍ଗୀତାସୁ ଉପନିଷତ୍ସୁ ବ୍ରହ୍ମବିଦ୍ୟାୟାଂ(ୟ୍ଁ) ୟୋଗଶାସ୍ତ୍ରେ ଶ୍ରୀକୃଷ୍ଣାର୍ଜୁନସଂବାଦେ ଆତ୍ମସଂୟମୟୋଗୋ ନାମ  ଷଷ୍ଠୋଽଧ୍ୟାୟଃ ॥6॥"
  ],
  "adhyaya-7" : [
    "ଶ୍ରୀ ଭଗବାନୁବାଚ\nମୟ୍ୟାସକ୍ତମନାଃ(ଫ୍) ପାର୍ଥ, 'ୟୋଗଂ(ମ୍) ୟୁଞ୍ଜନ୍ମଦାଶ୍ରୟଃ\nଅସଂଶୟଂ(ମ୍) ସମଗ୍ରଂ(ମ୍) ମାଂ(ମ୍), ୟଥା ଜ୍ଞାସ୍ୟସି ତଚ୍ଛୃଣୁ ॥7.1॥",

    "ଜ୍ଞାନଂ(ନ୍) ତେଽହଂ(ମ୍) ସବିଜ୍ଞାନମ୍, ଇଦଂ(ମ୍) ବକ୍ଷ୍ୟାମ୍ୟଶେଷତଃ\nୟଜ୍ଜ୍ଞାତ୍ୱା ନେହ ଭୂୟୋଽନ୍ୟଜ୍, ଜ୍ଞାତବ୍ୟମବଶିଷ୍ୟତେ ॥7.2॥",

    "ମନୁଷ୍ୟାଣାଂ(ମ୍) ସହସ୍ରେଷୁ, କଶ୍ଚିଦ୍ୟତତି ସିଦ୍ଧୟେ\nୟତତାମପି ସିଦ୍ଧାନାଂ(ଙ୍), କଶ୍ଚିନ୍ମାଂ(ମ୍) ବେତ୍ତି ତତ୍ତ୍ୱତଃ ॥7.3॥",

    "ଭୂମିରାପୋଽନଲୋ ବାୟୁଃ(ଖ୍), ଖଂ(ମ୍) ମନୋ ବୁଦ୍ଧିରେବ ଚ\nଅହଙ୍କାର ଇତୀୟଂ(ମ୍) ମେ, ଭିନ୍ନା ପ୍ରକୃତିରଷ୍ଟଧା ॥7.4॥",

    "ଅପରେୟମିତସ୍ତ୍ୱନ୍ୟାଂ(ମ୍), ପ୍ରକୃତିଂ(ମ୍) ବିଦ୍ଧି ମେ ପରାମ୍\nଜୀବଭୂତାଂ(ମ୍) ମହାବାହୋ, ୟୟେଦଂ(ନ୍) ଧାର୍ୟତେ ଜଗତ୍ ॥7.5॥",

    "ଏତଦ୍ୟୋନୀନି ଭୂତାନି, ସର୍ବାଣୀତ୍ୟୁପଧାରୟ\nଅହଂ(ଙ୍) କୃତ୍ସ୍ନସ୍ୟ ଜଗତଃ(ଫ୍), ପ୍ରଭବଃ(ଫ୍) ପ୍ରଲୟସ୍ତଥା ॥7.6॥",

    "ମତ୍ତଃ(ଫ୍) ପରତରଂ(ନ୍) ନାନ୍ୟତ୍, କିଞ୍ଚିଦସ୍ତି ଧନଞ୍ଜୟ\nମୟି ସର୍ବମିଦଂ(ମ୍) ପ୍ରୋତଂ(ମ୍), ସୂତ୍ରେ ମଣିଗଣା ଇବ ॥7.7॥",

    "ରସୋଽହମପ୍ସୁ କୌନ୍ତେୟ, ପ୍ରଭାସ୍ମି ଶଶିସୂର୍ୟୟୋଃ\nପ୍ରଣବଃ(ସ୍) ସର୍ବବେଦେଷୁ, ଶବ୍ଦଃ(ଖ୍) ଖେ ପୌରୁଷଂ(ନ୍) ନୃଷୁ ॥7.8॥",

    "ପୁଣ୍ୟୋ ଗନ୍ଧଃ(ଫ୍) ପୃଥୀବ୍ୟାଂ(ଞ୍) ଚ, ତେଜଶ୍ଚାସ୍ମି ବିଭାବସୌ\nଜୀବନଂ(ମ୍) ସର୍ବଭୂତେଷୁ, ତପଶ୍ଚାସ୍ମି ତପସ୍ୱୀଷୁ ॥7.9॥",

    "ବୀଜଂ(ମ୍) ମାଂ(ମ୍) ସର୍ବଭୂତାନାଂ(ମ୍), ବିଦ୍ଧି ପାର୍ଥ ସନାତନମ୍\nବୁଦ୍ଧିର୍ବୁଦ୍ଧିମତାମସ୍ମି, ତେଜସ୍ତେଜସ୍ୱିନାମହମ୍ ॥7.10॥",

    "ବଲଂ(ମ୍) ବଲବାତାଂ(ଞ୍) ଚାହଂ(ଙ୍), କାମରାଗବିବର୍ଜିତମ୍\nଧର୍ମାବିରୁଦ୍ଧୋ ଭୂତେଷୁ, କାମୋଽସ୍ମି ଭରତର୍ଷଭ ॥7.11॥",

    "ୟେ ଚୈବ ସାତ୍ତ୍ୱିକା ଭାବା, ରାଜସାସ୍ତାମସାଶ୍ଚ ୟେ\nମତ୍ତ ଏବେତି ତାନ୍ୱିଦ୍ଧି, ନ ତ୍ୱହଂ(ନ୍) ତେଷୁ ତେ ମୟି ॥7.12॥",

    "ତ୍ରିଭିର୍ଗୁଣମୟୈର୍ଭାବୈଃ(ର୍), ଏଭିଃ(ସ୍) ସର୍ବମିଦଂ(ଞ୍) ଜଗତ୍\nମୋହିତଂ(ନ୍) ନାଭିଜାନାତି, ମାମେଭ୍ୟଃ(ଫ୍) ପରମବ୍ୟୟମ୍ ॥7.13॥",

    "ଦୈବୀ ହ୍ୟେଷା ଗୁଣମୟୀ, ମମ ମାୟା ଦୁରତ୍ୟୟା\nମାମେବ ୟେ ପ୍ରପଦ୍ୟନ୍ତେ, ମାୟାମେତାଂ(ନ୍) ତରନ୍ତି ତେ ॥7.14॥",

    "ନ ମାଂ(ନ୍) ଦୁଷ୍କୃତିନୋ ମୂଢାଃ(ଫ୍), ପ୍ରପଦ୍ୟନ୍ତେ ନରାଧମାଃ\nମାୟୟାପହୃତଜ୍ଞାନା, ଆସୁରଂ(ମ୍) ଭାବମାଶ୍ରିତାଃ ॥7.15॥",

    "ଚତୁର୍ବିଧା ଭଜନ୍ତେ ମାଂ(ଞ୍), ଜନାଃ(ସ୍) ସୁକୃତିନୋଽର୍ଜୁନ\nଆର୍ତୋ ଜିଜ୍ଞାସୁରର୍ଥାର୍ଥୀ, ଜ୍ଞାନୀ ଚ ଭରତର୍ଷଭ ॥7.16॥",

    "ତେଷାଂ(ଞ୍) ଜ୍ଞାନୀ ନିତ୍ୟୟୁକ୍ତ, ଏକଭକ୍ତିର୍ବିଶିଷ୍ୟତେ\nପ୍ରିୟୋ ହି ଜ୍ଞାନୀନୋଽତ୍ୟର୍ଥମ୍, ଅହଂ(ମ୍) ସ ଚ ମମ ପ୍ରିୟଃ ॥7.17॥",

    "ଉଦାରାଃ(ସ୍) ସର୍ବ ଏବୈତେ, ଜ୍ଞାନୀ ତ୍ୱାତ୍ମୈବ ମେ ମତମ୍\nଆସ୍ଥିତଃ(ସ୍) ସ ହି ୟୁକ୍ତାତ୍ମା, ମାମେବାନୁତ୍ତମାଂ(ଙ୍) ଗତିମ୍ ॥7.18॥",

    "ବହୁନାଂ(ଞ୍) ଜନ୍ମନାମନ୍ତେ, ଜ୍ଞାନବାନ୍ମାଂ(ମ୍) ପ୍ରପଦ୍ୟତେ \nବାସୁଦେବଃ(ସ୍) ସର୍ବମିତି, ସ ମହାତ୍ମା ସୁଦୁର୍ଲଭଃ ॥7.19॥",

    "କାମୈସ୍ତୈସ୍ତୈର୍ହୃତଜ୍ଞାନାଃ(ଫ୍), ପ୍ରପଦ୍ୟନ୍ତେଽନ୍ୟଦେବତାଃ\nତଂ(ନ୍) ତଂ(ନ୍) ନିୟମମାସ୍ଥାୟ, ପ୍ରକୃତ୍ୟା ନିୟତାଃ(ସ୍) ସ୍ୱୟାା ॥7.20॥",

    "ୟୋ ୟୋ ୟାଂ(ମ୍) ୟାଂ(ନ୍) ତନୁଂ(ମ୍) ଭକ୍ତଃ(ଶ୍), ଶ୍ରଦ୍ଧୟାର୍ଚିତୁମିଚ୍ଛତି\nତସ୍ୟ ତସ୍ୟାଚଲାଂ(ମ୍) ଶ୍ରଦ୍ଧାଂ(ନ୍), ତାମେବ ବିଦଧାମ୍ୟହମ୍ ॥7.21॥",

    "ସ ତୟା ଶ୍ରଦ୍ଧୟା ୟୁକ୍ତଃ(ସ୍), ତସ୍ୟାରାଧନମୀହତେ\nଲଭତେ ଚ ତତଃ(ଖ୍) କାମାନ୍, ମୟୈବ ବିହିତାହ୍ନି ତାନ୍ ॥7.22॥",

    "ଅନ୍ତବତ୍ତୁ ଫଲଂ(ନ୍) ତେଷାଂ(ନ୍), ତଦ୍ଭବତ୍ୟଲ୍ପମେଧସାମ୍\nଦେବାନ୍ଦେବୟଜୋ ୟାନ୍ତି, ମଦ୍ଭକ୍ତା ୟାନ୍ତି ମାମପି ॥7.23॥",

    "ଅବ୍ୟକ୍ତଂ(ମ୍) ବ୍ୟକ୍ତିମାପନ୍ନଂ(ମ୍), ମନ୍ୟନ୍ତେ ମାମବୁଦ୍ଧୟଃ\nପରଂ(ମ୍) ଭାବମଜାନନ୍ତୋ, ମମାବ୍ୟୟମନୁତ୍ତମମ୍ ॥7.24॥",

    "ନାହଂ(ମ୍) ପ୍ରକାଶଃ(ସ୍) ସର୍ବସ୍ୟ, ୟୋଗମାୟାସମାବୃତଃ\nମୂଢୋଽୟଂ(ନ୍) ନାଭିଜାନାତି, ଲୋକୋ ମାମଜମବ୍ୟୟମ୍ ॥7.25॥",

    "ବେଦାହଂ(ମ୍) ସମତୀତାନି, ବର୍ତମାନାନି ଚାର୍ଜୁନ\nଭବିଷ୍ୟାଣି ଚ ଭୂତାନି, ମାଂ(ନ୍) ତୁ ବେଦ ନ କଶ୍ଚନ ॥7.26॥",

    "ଇଚ୍ଛାଦ୍ୱେଷସମୁତ୍ଥେନ, ଦ୍ୱନ୍ଦ୍ୱମୋହେନ ଭାରତ\nସର୍ବଭୂତାନି ସମ୍ମୋହଂ(ମ୍), ସର୍ଗେ ୟାନ୍ତି ପରନ୍ତପ ॥7.27॥",

    "ୟେଷାଂ(ନ୍) ତ୍ୱନ୍ତଗତଂ(ମ୍) ପାପଂ(ଞ), ଜନାନାଂ(ମ୍) ପୁଣ୍ୟକର୍ମଣାମ୍\nତେ ଦ୍ୱନ୍ଦ୍ୱମୋହନିର୍ମୁକ୍ତା, ଭଜନ୍ତେ ମାଂ(ନ୍) ଦୃଢବ୍ରତାଃ ॥7.28॥",

    "ଜରାମରଣମୋକ୍ଷାୟ, ମାମାଶ୍ରିତ୍ୟ ୟତନ୍ତି ୟେ\nତେ ବ୍ରହ୍ମ ତଦ୍ୱିଦୁଃ(ଖ୍) କୃତ୍ସ୍ନମ୍, ଅଧ୍ୟାତ୍ମଂ(ଙ୍) କର୍ମ ଚାଖିଲମ୍ ॥7.29॥",

    "ସାଧିଭୂତାଧିଦୈବଂ(ମ୍) ମାଂ(ମ୍), ସାଧିୟଜ୍ଞଂ(ଞ) ଚ ୟେ ବିଦୁଃ\nପ୍ରୟାଣକାଲେଽପି ଚ ମାଂ(ନ୍), ତେ ବିଦୁର୍ୟୁକ୍ତଚେତସଃ ॥7.30॥",

    "ଓଁ ତତ୍ସଦିତି ଶ୍ରୀମଦ୍ଭଗବଦ୍ଗୀତାସୁ ଉପନିଷତ୍ସୁ ବ୍ରହ୍ମବିଦ୍ୟାୟାଂ(ୟ୍ଁ) ୟୋଗଶାସ୍ତ୍ରେ ଶ୍ରୀକୃଷ୍ଣାର୍ଜୁନସଂବାଦେ ଜ୍ଞାନବିଜ୍ଞାନୟୋଗୋ ନାମ ସପ୍ତମୋଽଧ୍ୟାୟଃ।।"
  ],
  "adhyaya-8" : [
    "ଅର୍ଜୁନ ଉବାଚ\nକିଂ(ନ୍) ତଦ୍ବ୍ରହ୍ମ କିମଧ୍ୟାତ୍ମଂ(ଙ୍), କିଂ(ଙ୍) କର୍ମ ପୁରୁଷୋତ୍ତମ।\nଅଧିଭୂତଂ(ଞ୍) ଚ କିଂ(ମ୍) ପ୍ରୋକ୍ତମ୍, ଅଧିଦୈବଂ(ଙ୍) କିମୁଚ୍ୟତେ ॥8.1॥",

    "ଅଧିଯଜ୍ଞଃ(ଖ୍) କଥଂ(ଙ୍) କୋଽତ୍ର, ଦେହେଽସ୍ମିନ୍ମଧୁସୂଦନ।\nପ୍ରୟାଣକାଲେ ଚ କଥଂ(ଞ୍), ଜ୍ଞେୟୋଽସି ନିୟତାତ୍ମଭିଃ ॥8.2॥",

    "ଶ୍ରୀଭଗବାନୁବାଚ\nଅକ୍ଷରଂ(ମ୍) ବ୍ରହ୍ମ ପରମଂ(ମ୍), ସ୍ୱଭାବୋଽଧ୍ୟାତ୍ମମୁଚ୍ୟତେ।\nଭୂତଭାବୋଦ୍ଭବକରୋ, ବିସର୍ଗଃ(ଖ୍) କର୍ମସଂଞ୍ଜ୍ଞିତଃ ॥8.3॥",

    "ଅଧିଭୂତଂ(ଙ୍) କ୍ଷରୋ ଭାବଃ(ଫ୍), ପୁରୁଷଶ୍ଚାଧିଦୈବତମ୍।।\nଅଧିଯଜ୍ଞୋଽହମେବାତ୍ର, ଦେହେ ଦେହଭୃତାଂ(ମ୍) ବର ॥8.4॥",

    "ଅନ୍ତକାଲେ ଚ ମାମେବ, ସ୍ମରନ୍ମୁକ୍ତ୍ୱା କଲେବରମ୍।\nୟଃ(ଫ୍) ପ୍ରୟାତି ସ ମଦ୍ଭାବଂ(ମ୍), ୟାତି ନାସ୍ତ୍ୟତ୍ର ସଂଶୟଃ ॥8.5॥",

    "ୟଂ(ମ୍) ୟଂ(ମ୍) ବାପି ସ୍ମରନ୍ଭାବଂ(ନ୍), ତ୍ୟଜତ୍ୟନ୍ତେ କଲେବରମ୍।\nତଂ(ନ୍) ତମେବୈତି କୌନ୍ତେୟ, ସଦା ତଦ୍ଭାବଭାବିତଃ ॥8.6॥",

    "ତସ୍ମାତ୍ସର୍ବେଷୁ କାଲେଷୁ, ମାମନୁସ୍ମର ୟୁଧ୍ୟ ଚ।\nମୟ୍ୟର୍ପିତମନୋବୁଦ୍ଧିଃ(ର୍), ମାମେବୈଷ୍ୟସ୍ୟସଂଶୟମ୍ ॥8.7॥",

    "ଅଭ୍ୟାସୟୋଗୟୁକ୍ତେନ, ଚେତସା ନାନ୍ୟଗାମିନା।\nପରମଂ(ମ୍) ପୁରୁଷଂ(ନ୍) ଦିବ୍ୟଂ(ମ୍), ୟାତି ପାର୍ଥାନୁଚିନ୍ତୟନ୍ ॥8.8॥",

    "କବିଂ(ମ୍) ପୁରାଣମନୁଶାସିତାରମ୍,\nଅଣୋରଣୀୟାଂସମନୁସ୍ମରେଦ୍ୟଃ।\nସର୍ବସ୍ୟ ଧାତାରମଚିନ୍ତ୍ୟରୂପମ୍,\nଆଦିତ୍ୟ ବର୍ଣ୍ଣଂ(ନ୍) ତମସଃ(ଫ୍) ପରସ୍ତାତ ॥8.9॥",

    " ପ୍ରୟାଣକାଲେ ମନସାଽଚଲେନ,\nଭକ୍ତ୍ୟା ୟୁକ୍ତୋ ୟୋଗବଲେନ ଚୈବ।\nଭ୍ରୁବୋର୍ମଧ୍ୟେ ପ୍ରାଣମାବେଶ୍ୟ ସମ୍ୟକ୍,\nସ ତଂ(ମ୍) ପରଂ(ମ୍) ପୁରୁଷମୁପୈତି ଦିବ୍ୟମ୍ ॥8.10॥",

    "ୟଦକ୍ଷରଂ(ମ୍) ବେଦବିଦୋ ବଦନ୍ତି,\nବିଶନ୍ତି ୟଦ୍ୟତୟୋ ବୀତରାଗାଃ।\nୟଦିଚ୍ଛନ୍ତୋ ବ୍ରହ୍ମଚର୍ୟଂ(ଞ୍) ଚରନ୍ତି,\nତତ୍ତେ ପଦଂ(ମ୍) ସଙ୍ଗ୍ରହେଣ ପ୍ରବକ୍ଷ୍ୟ ॥8.11॥",

    "ସର୍ବଦ୍ୱାରାଣି ସଂୟମ୍ୟ, ମନୋ ହୃଦି ନିରୁଧ୍ୟ ଚ।\nମୂର୍ଧ୍ନ୍ୟାଧାୟାତ୍ମନଃ(ଫ୍) ପ୍ରାଣମ୍, ଆସ୍ଥିତୋ ୟୋଗଧାରଣାମ୍ ॥8.12॥",

    "ଓମିତ୍ୟେକାକ୍ଷରଂ(ମ୍) ବ୍ରହ୍ମ, ବ୍ୟାହରନ୍ମାମନୁସ୍ମରନ୍।\nୟଃ(ଫ୍) ପ୍ରୟାତି ତ୍ୟଜନ୍ଦେହଂ(ମ୍), ସ ୟାତି ପରମାଂ(ଙ୍) ଗତିମ୍ ॥8.13॥",

    "ଅନନ୍ୟଚେତାଃ(ସ୍) ସତତଂ(ମ୍), ୟୋ ମାଂ(ମ୍) ସ୍ମରତି ନିତ୍ୟଶଃ।\nତସ୍ୟାହଂ(ମ୍) ସୁଲଭଃ(ଫ୍) ପାର୍ଥ, ନିତ୍ୟୟୁକ୍ତସ୍ୟ ୟୋଗିନଃ ॥8.14॥",

    "ମାମୁପେତ୍ୟ ପୁନର୍ଜନ୍ମ, ଦୁଃଖାଲୟମଶାଶ୍ୱତମ୍।\nନାପ୍ନୁବନ୍ତି ମହାତ୍ମାନଃ(ସ୍), ସଂସିଦ୍ଧିଂ(ମ୍) ପରମାଂ(ଙ୍) ଗତାଃ ॥8.15॥",

    "ଆବ୍ରହ୍ମଭୂବନାଲ୍ଲୋକାଃ(ଫ୍), ପୁନରାବର୍ତିନୋଽର୍ଜୁନ।\nମାମୁପେତ୍ୟ ତୁ କୌନ୍ତେୟ, ପୁନର୍ଜନ୍ମ ନ ବିଦ୍ୟତେ ॥8.16॥",

    "ସହସ୍ରୟୁଗପର୍ୟନ୍ତମ୍, ଅହର୍ୟଦ୍ବ୍ରହ୍ମଣୋ ବିଦୁଃ।\nରାତ୍ରିଂ(ମ୍) ୟୁଗସହସ୍ରାନ୍ତାଂ(ନ୍), ତେଽହୋରାତ୍ରବିଦୋ ଜନାଃ ॥8.17॥",

    "ଅବ୍ୟକ୍ତାଦ୍ବ୍ୟକ୍ତୟଃ(ସ୍) ସର୍ବାଃ(ଫ୍), ପ୍ରଭବନ୍ତ୍ୟହରାଗମେ।\nରାତ୍ର୍ୟାଗମେ ପ୍ରଲୀୟନ୍ତେ, ତତ୍ରୈବାବ୍ୟକ୍ତସଞ୍ଜ୍ଞକେ ॥8.18॥",

    "ଭୂତଗ୍ରାମଃ(ସ୍) ସ ଏବାୟଂ(ମ୍), ଭୂତ୍ୱା ଭୂତ୍ୱା ପ୍ରଲୀୟତେ।\nରାତ୍ର୍ୟାଗମେଽବଶଃ(ଫ୍) ପାର୍ଥ, ପ୍ରଭବତ୍ୟହରାଗମେ ॥8.19॥",

    "ପରସ୍ତସ୍ମାତ୍ତୁ ଭାବୋଽନ୍ୟୋ- ଽବ୍ୟକ୍ତୋଽବ୍ୟକ୍ତାତ୍ସନାତନଃ।\nୟଃ(ସ୍)  ସ ସର୍ବେଷୁ ଭୂତେଷୁ, ନଶ୍ୟତ୍ସୁ ନ ବିନଶ୍ୟତି ॥8.20॥",

    "ଅବ୍ୟକ୍ତୋଽକ୍ଷର ଇତ୍ୟୁକ୍ତଃ(ସ୍), ତମାହୁଃ(ଫ୍) ପରମାଂ(ଙ୍) ଗତିମ୍।\nୟଂ(ମ୍) ପ୍ରାପ୍ୟ ନ ନିବର୍ତନ୍ତେ, ତଦ୍ଧାମ ପରମଂ(ମ୍) ମମ ॥8.21॥",

    "ପୁରୁଷଃ(ସ୍) ସ ପରଃ(ଫ୍) ପାର୍ଥ, ଭକ୍ତ୍ୟା ଲଭ୍ୟସ୍ତ୍ୱନନ୍ୟୟା।\nୟସ୍ୟାନ୍ତଃସ୍ଥାନି ଭୂତାନି, ୟେନ ସର୍ବମିଦଂ(ନ୍) ତତମ୍ ॥8.22॥",

    "ୟତ୍ର କାଲେ ତ୍ୱନାବୃତ୍ତିମ୍, ଆବୃତ୍ତିଂ(ଞ୍) ଚୈବ ୟୋଗିନଃ।\nପ୍ରୟାତା ୟାନ୍ତି ତଂ(ଙ୍) କାଲଂ(ମ୍), ବକ୍ଷ୍ୟାମି ଭରତର୍ଷଭ ॥8.23॥",

    "ଅଗ୍ନିର୍ଜ୍ୟୋତିରହଃ(ଶ୍) ଶୁକ୍ଲଃ(ଷ୍), ଷଣ୍ମାସା ଉତ୍ତରାୟଣମ୍।\nତତ୍ର ପ୍ରୟାତା ଗଚ୍ଛନ୍ତି, ବ୍ରହ୍ମ ବ୍ରହ୍ମବିଦୋ ଜନାଃ ॥8.24॥",

    "ଧୂମୋ ରାତ୍ରିସ୍ତଥା କୃଷ୍ଣଃ(ଷ୍), ଷଣ୍ମାସା ଦକ୍ଷିଣାୟନମ୍।\nତତ୍ର ଚାନ୍ଦ୍ରମସଂ(ଞ୍) ଜ୍ୟୋତିଃ(ର୍), ୟୋଗୀ ପ୍ରାପ୍ୟ ନିବର୍ତତେ ॥8.25॥",

    "ଶୁକ୍ଲକୃଷ୍ଣେ ଗତୀ ହ୍ୟେତେ, ଜଗତଃ(ଶ୍) ଶାଶ୍ୱତେ ମତେ।\nଏକୟା ୟାତ୍ୟନାବୃତ୍ତିମ୍, ଅନ୍ୟୟାବର୍ତତେ ପୁନଃ ॥8.26॥",

    "ନୈତେ ସୃତୀ ପାର୍ଥ ଜାନନ୍, ୟୋଗୀ ମୁହ୍ୟତି କଶ୍ଚନ।\nତସ୍ମାତ୍ସର୍ବେଷୁ କାଲେଷୁ, ୟୋଗୟୁକ୍ତୋ ଭବାର୍ଜୁନ ॥8.27॥",

    "ବେଦେଷୁ ୟଜ୍ଞେଷୁ ତପଃ(ସ୍) ସୁ ଚୈବ,\nଦାନେଷୁ ୟତ୍ପୁଣ୍ୟଫଲଂ(ମ୍) ପ୍ରଦିଷ୍ଟମ୍।\nଅତ୍ୟେତି ତତ୍ସର୍ବମିଦଂ(ମ୍) ବିଦିତ୍ୱା,\nୟୋଗୀ ପରଂ(ମ୍) ସ୍ଥାନମୁପୈତି ଚାଦ୍ୟମ୍ ॥8.1॥",

    "ଓଁ ତତ୍ସଦିତି ଶ୍ରୀମଦ୍ଭଗବଦ୍ଗୀତାସୁ ଉପନିଷତ୍ସୁ ବ୍ରହ୍ମବିଦ୍ୟାୟାଂ(ୟ୍ଁ) ୟୋଗଶାସ୍ତ୍ରେ ଶ୍ରୀକୃଷ୍ଣାର୍ଜୁନସଂବାଦେ ଅକ୍ଷରବ୍ରହ୍ମୟୋଗୋ ନାମ ଅଷ୍ଟମୋଽଧ୍ୟାୟଃ।।"
  ],
  "adhyaya-9" : [
    "ଶ୍ରୀଭଗବାନୁବାଚ\nଇଦଂ(ନ୍) ତୁ ତେ ଗୁହ୍ୟତମଂ(ମ୍), ପ୍ରବକ୍ଷ୍ୟାମ୍ୟନସୂୟବେ ।\nଜ୍ଞାନଂ(ୱ୍ଁ) ବିଜ୍ଞାନ ସହିତଂ(ୟ୍ଁ), ୟଜ୍ ଜ୍ଞାତ୍ୱା ମୋକ୍ଷ୍ୟସେଽଶୁଭାତ୍ ॥9.1॥",

    "ରାଜବିଦ୍ୟା ରାଜଗୁହ୍ୟଂ(ମ୍), ପବିତ୍ରମିଦମୁତ୍ତମମ୍ \nପ୍ରତ୍ୟକ୍ଷାବଗମଂ(ନ୍) ଧର୍ମ୍ୟଂ(ମ୍), ସୁସୁଖଂ(ଙ୍) କର୍ତ୍ତୁମବ୍ୟୟମ୍ ॥9.2॥",

    "ଅଶ୍ରଦ୍ଦଧାନାଃ(ଫ୍) ପୁରୁଷା, ଧର୍ମସ୍ୟାସ୍ୟ ପରନ୍ତପ \nଅପ୍ରାପ୍ୟ ମାଂ(ନ୍) ନିବର୍ତନ୍ତେ, ମୃତ୍ୟୁସଂସାରବର୍ତ୍ମନି ॥9.3॥",

    "ମୟା ତତମିଦଂ(ମ୍) ସର୍ବଂ(ଞ୍), ଜଗଦବ୍ୟକ୍ତମୂର୍ତିନ\nମତ୍ସ୍ଥାନି ସର୍ବଭୂତାନି, ନ ଚାହଂ(ନ୍) ତେଷ୍ୱବସ୍ଥିତ ॥9.4॥",

    "ନ ଚ ମତ୍ସ୍ଥାନି ଭୂତାନି, ପଶ୍ୟ ମେ ୟୋଗମୈଶ୍ୱରମ୍\nଭୂତଭ୍ରୁନ୍ନ ଚ ଭୂତସ୍ଥୋ, ମମାତ୍ମା ଭୂତଭାବନ ॥9.5॥",

    "ୟଥାକାଶସ୍ଥିତୋ ନିତ୍ୟଂ(ୱ୍ଁ), ବାୟୁଃ(ସ୍) ସର୍ବତ୍ରଗୋ ମହାନ୍ ।\nତଥା ସର୍ବାଣି ଭୂତାନି, ମତ୍ସ୍ଥାନୀତ୍ୟୁପଧାରୟ ॥9.6॥",

    "ସର୍ବଭୂତାନି କୌନ୍ତେୟ, ପ୍ରକୃତିଂ(ୟ୍ଁ) ୟାନ୍ତି ମାମିକାମ୍ ।\nକଲ୍ପକ୍ଷୟେ ପୁନସ୍ତାନି, କଲ୍ପାଦୌ ବିସୃଜାମ୍ୟହମ୍ ॥9.7॥",

    "ପ୍ରକୃତିଂ(ମ୍) ସ୍ୱାମବଷ୍ଟଭ୍ୟ, ବିସୃଜାମି ପୁନଃ(ଫ୍) ପୁନ\nଭୂତଗ୍ରାମମିମଂ(ଙ୍) କୃତ୍ସ୍ନମ୍, ଅବଶଂ(ମ୍) ପ୍ରକୃତେର୍ବଶାତ୍ ॥9.8॥",

    "ନ ଚ ମାଂ(ନ୍) ତାନି କର୍ମାଣି, ନିବଧ୍ନନ୍ତି ଧନଞ୍ଜୟ\nଉଦାସୀନବଦାସୀନମ୍, ଅସକ୍ତଂ(ନ୍) ତେଷୁ କର୍ମସୁ ॥9.9॥",

    "ମୟାଧ୍ୟକ୍ଷେଣ ପ୍ରକୃତିଃ(ସ୍), ସୂୟତେ ସଚରାଚରମ୍\nହେତୁନାନେନ କୌନ୍ତେୟ, ଜଗଦ୍ୱିପରିବର୍ତତେ ॥9.10॥",

    "ଅବଜାନନ୍ତି ମାଂ(ମ୍) ମୂଢା, ମାନୁଷୀଂ(ନ୍) ତନୁମାଶ୍ରିତମ୍ \nପରଂ(ମ୍) ଭାବମଜାନନ୍ତୋ, ମମ ଭୂତମହେଶ୍ୱରମ୍ ॥9.11॥",

    "ମୋଘାଶା ମୋଘକର୍ମାଣୋ, ମୋଘଜ୍ଞାନା ବିଚେତସଃ\nରାକ୍ଷସୀମାସୁରୀଂ(ଞ୍) ଚୈବ, ପ୍ରକୃତିଂ(ମ୍) ମୋହିନୀଂ(ମ୍) ଶ୍ରିତାଃ ॥9.12॥",

    "ମହାତ୍ମାନସ୍ତୁ ମାଂ(ମ୍) ପାର୍ଥ, ଦୈବୀଂ(ମ୍) ପ୍ରକୃତିମାଶ୍ରିତାଃ \nଭଜନ୍ତ୍ୟନନ୍ୟମନସୋ, ଜ୍ଞାତ୍ୱା ଭୂତାଦିମବ୍ୟୟମ୍ ॥9.13॥",

    "ସତତଂ(ଙ୍) କୀର୍ତୟନ୍ତୋ ମାଂ(ମ୍), ୟତନ୍ତଶ୍ଚ ଦୃଢବ୍ରତାଃ ।\nନମସ୍ୟନ୍ତଶ୍ଚ ମାଂ(ମ୍) ଭକ୍ତ୍ୟା, ନିତ୍ୟୟୁକ୍ତା ଉପାସତେ ॥9.14॥",

    "ଜ୍ଞାନୟଜ୍ଞେନ ଚାପ୍ୟନ୍ୟେ, ୟଜନ୍ତୋ ମାମୁପାସତେ\nଏକତ୍ୱେନ ପୃଥକ୍ତ୍ୱେନ, ବହୁଧା ବିଶ୍ୱତୋମୁଖମ୍ ॥9.15॥",

    "ଅହଂ(ଙ୍) କ୍ରତୁରହଂ(ୟ୍ଁ) ୟଜ୍ଞଃ(ସ୍), ସ୍ୱଧାହମହମୌଷଧମ୍।\nମନ୍ତ୍ରୋଽହମହମେବାଜ୍ୟମ୍, ଅହମଗ୍ନିରହଂ(ମ୍) ହୁତମ୍ ॥9.16॥",

    "ପିତାହମସ୍ୟ ଜଗତୋ, ମାତା ଧାତା ପିତାମହଃ\nବେଦ୍ୟଂ(ମ୍) ପବିତ୍ରମୋଙ୍କାର, ଋକ୍ସାମ ୟଜୁରେବ ଚ ॥9.17॥",

    "ଗତିର୍ଭର୍ତ୍ତା ପ୍ରଭୁଃ(ସ୍) ସାକ୍ଷୀ, ନିବାସଃ(ଶ୍) ଶରଣଂ(ମ୍) ସୁହୃତ୍ \nପ୍ରଭବଃ(ଫ୍) ପ୍ରଲୟଃ(ସ୍) ସ୍ଥାନଂ(ନ୍), ନିଧାନଂ(ନ୍) ବୀଜମବ୍ୟୟମ୍ ॥9.18॥",

    "ତପାମ୍ୟହମହଂ(ୱ୍ଁ) ବର୍ଷଂ(ନ୍), ନିଗୃ୍ହ୍ଣାମ୍ୟୁତ୍ସୃଜାମି ଚ ।\nଅମୃତଂ(ଞ୍) ଚୈବ ମୃତ୍ୟୁଶ୍ଚ, ସଦସଚ୍ଚାହମର୍ଜୁନ ॥9.19॥",

    "ତ୍ରୈବିଦ୍ୟା ମାଂ(ମ୍) ସୋମପାଃ(ଫ୍) ପୂତପାପା \nୟଜ୍ଞୈରିଷ୍ଟ୍ୱା ସ୍ୱର୍ଗତିଂ(ମ୍) ପ୍ରାର୍ଥୟନ୍ତେ \nତେ ପୁଣ୍ୟମାସାଦ୍ୟ ସୁରେନ୍ଦ୍ରଲୋକମ୍\nଅଶ୍ନନ୍ତି ଦିବ୍ୟାନ୍ଦିବି ଦେବଭୋଗାନ ॥9.20॥",

    " ତେ ତଂ(ମ୍) ଭୁକ୍ତ୍ୱା ସ୍ୱର୍ଗଲୋକଂ(ୱ୍ଁ) ବିଶାଲଂ(ଙ୍),\nକ୍ଷୀଣେ ପୁଣ୍ୟେ ମର୍ତ୍ୟଲୋକଂ(ୱ୍ଁ) ବିଶନ୍ତି ।\nଏବଂ(ନ୍) ତ୍ରୟୀଧର୍ମମନୁପ୍ରପନ୍ନା,\nଗତାଗତଂ(ଙ୍) କାମକାମା ଲଭନ୍ତେ ॥9.21॥",

    "ଅନନ୍ୟାଶ୍ଚିନ୍ତୟନ୍ତୋ ମାଂ(ୟ୍ଁ), ୟେ ଜନାଃ(ଫ୍) ପର୍ୟୁପାସତେ ।\nତେଷାଂ(ନ୍) ନିତ୍ୟାଭିୟୁକ୍ତାନାଂ(ୟ୍ଁ), ୟୋଗକ୍ଷେମଂ(ମ୍) ବହାମ୍ୟହମ୍ ॥9.22॥",

    "ୟେଽପ୍ୟନ୍ୟଦେବତା ଭକ୍ତା, ୟଜନ୍ତେ ଶ୍ରଦ୍ଧୟାନ୍ୱିତ\nତେଽପି ମାମେବ କୌନ୍ତେୟ, ୟଜନ୍ତ୍ୟବିଧିପୂର୍ବକମ୍ ॥9.23॥",

    "ଅହଂ(ମ୍) ହି ସର୍ବୟଜ୍ଞାନାଂ(ମ୍), ଭୋକ୍ତା ଚ ପ୍ରଭୁରେବ ଚ\nନ ତୁ ମାମଭିଜାନନ୍ତି, ତତ୍ତ୍ୱେନାତଶ୍ଚ୍ୟବନ୍ତି ତେ ॥9.24॥",

    "ୟାନ୍ତି ଦେବବ୍ରତା ଦେବାନ୍, ପିତୃ୍ୃନ୍ୟାନ୍ତି ପିତୃବ୍ରତ\nଭୂତାନି ୟାନ୍ତି ଭୂତେଜ୍ୟା, ୟାନ୍ତି ମଦ୍ୟାଜିନୋଽପି ମାମ୍ ॥9.25॥",

    "ପତ୍ରଂ(ମ୍) ପୁଷ୍ପଂ(ମ୍) ଫଲଂ(ନ୍) ତୋୟଂ(ୟ୍ଁ), ୟୋ ମେ ଭକ୍ତ୍ୟା ପ୍ରୟଚ୍ଛତି।\nତଦହଂ(ମ୍) ଭକ୍ତ୍ୟୁପହୃତମ୍, ଅଶ୍ନାମି ପ୍ରୟତାତ୍ମନଃ ॥9.26॥",

    "ୟତ୍କରୋଷି ୟଦଶ୍ନାସି, ୟଜ୍ଜୁହୋଷି ଦଦାସି ୟତ\nୟତ୍ତପସ୍ୟସି କୌନ୍ତେୟ, ତତ୍କୁରୁଷ୍ୱ ମଦର୍ପଣମ୍ ॥9.27॥",

    "ଶୁଭାଶୁଭଫଲୈରେବଂ(ମ୍), ମୋକ୍ଷସେ କର୍ମବନ୍ଧନୈଃ\nସନ୍ନ୍ୟାସୟୋଗୟୁକ୍ତାତ୍ମା, ବିମୁକ୍ତୋ ମାମୁପୈଷ୍ୟସି ॥9.28॥",

    "ସମୋଽହଂ(ମ୍) ସର୍ବଭୂତେଷୁ, ନ ମେ ଦ୍ୱେଷ୍ୟୋଽସ୍ତି ନ ପ୍ରିୟଃ \nୟେ ଭଜନ୍ତି ତୁ ମାଂ(ମ୍) ଭକ୍ତ୍ୟା, ମୟି ତେ ତେଷୁ ଚାପ୍ୟହମ୍ ॥9.29॥",

    "ଅପି ଚେତ୍ସୁଦୁରାଚାରୋ, ଭଜତେ ମାମନନ୍ୟଭାକ୍\nସାଧୁରେବ ସ ମନ୍ତବ୍ୟଃ(ସ୍), ସମ୍ୟଗ୍ବ୍ୟବସିତୋ ହି ସଃ ॥9.30॥",

    "କ୍ଷିପ୍ରଂ(ମ୍) ଭବତି ଧର୍ମାତ୍ମା, ଶଶ୍ୱଚ୍ଛାନ୍ତିଂ(ନ୍) ନିଗଚ୍ଛତି\nକୌନ୍ତେୟ ପ୍ରତିଜାନୀହି, ନ ମେ ଭକ୍ତଃ(ଫ୍) ପ୍ରଣଶ୍ୟତି ॥9.31॥",

    "ମାଂ(ମ୍) ହି ପାର୍ଥ ବ୍ୟପାଶ୍ରିତ୍ୟ, ୟେଽପି ସ୍ୟୁଃ(ଫ୍) ପାପୟୋନୟଃ\nସ୍ତ୍ରିୟୋ ବୈଶ୍ୟାସ୍ତଥା ଶୂଦ୍ରାଃ(ସ୍), ତେଽପି ୟାନ୍ତି ପରାଂ(ଙ୍) ଗତିମ୍ ॥9.32॥",

    "କିଂ(ମ୍) ପୁନର୍ବ୍ରହ୍ମଣାଃ(ଫ୍) ପୁଣ୍ୟା, ଭକ୍ତା ରାଜର୍ଷୟସ୍ତଥା\nଅନିତ୍ୟମସୁଖଂ(ମ୍) ଲୋକମ୍, ଇମଂ(ମ୍) ପ୍ରାପ୍ୟ ଭଜସ୍ୱ ମାମ୍ ॥9.33॥",

    "ମନ୍ମନା ଭବ ମଦ୍ଭକ୍ତୋ, ମଦ୍ୟାଜୀ ମାଂ(ନ୍) ନମସ୍କୁରୁ\nମାମେବୈଷ୍ୟସି ୟୁକ୍ତୈବମ୍, ଆତ୍ମାନଂ(ମ୍) ମତ୍ପରାୟଣଃ ॥9.34॥",

    "ଓଁ ତତ୍ସଦିତି ଶ୍ରୀମଦ୍ଭଗବଦ୍ଗୀତାସୁ ଉପନିଷତ୍ସୁ ବ୍ରହ୍ମବିଦ୍ୟାୟାଂ(ୟ୍ଁ) ୟୋଗଶାସ୍ତ୍ରେ ଶ୍ରୀକୃଷ୍ଣାର୍ଜୁନସଂବାଦେ ରାଜବିଦ୍ୟାରାଜଗୁହ୍ୟୟୋଗୋ ନାମ ନବମୋଽଧ୍ୟାୟଃ"
  ],
  "adhyaya-10" : [
    "ଶ୍ରୀଭଗବାନୁବାଚ\nଭୂୟ ଏବ ମହାବାହୋ, ଶୃଣୁ ମେ ପରମଂ(ମ୍) ବଚଃ।\nୟତ୍ତେଽହଂ(ମ୍) ପ୍ରୀୟମାଣାୟ, ବକ୍ଷ୍ୟାମି ହିତକାମ୍ୟୟା ॥10.1॥",

    "ନ ମେ ବିଦୁଃ(ସ୍) ସୁରଗଣାଃ(ଫ୍), ପ୍ରଭବଂ(ନ୍) ନ ମହର୍ଷୟଃ।\nଅହମାଦିର୍ହି ଦେବାନାଂ(ମ୍), ମହର୍ଷୀଣାଂ(ଞ୍) ଚ ସର୍ବଶଃ ॥10.2॥",

    "ୟୋ ମାମଜମନାଦିଂ(ଞ୍) ଚ, ବେତ୍ତି ଲୋକମହେଶ୍ୱରମ୍।\nଅସମ୍ମୂଢଃ(ସ୍) ସ ମର୍ତ୍ୟେଷୁ, ସର୍ବପାପୈଃ(ଫ୍) ପ୍ରମୁଚ୍ୟତେ ॥10.3॥",

    "ବୁଦ୍ଧିର୍ଜ୍ଞାନମସମ୍ମୋହଃ, କ୍ଷମା ସତ୍ୟଂ(ନ୍) ଦମଃ(ଶ୍) ଶମଃ।\nସୁଖଂ(ନ୍) ଦୁଃଖଂ(ମ୍) ଭବୋଽଭାବୋ, ଭୟଂ(ଞ୍) ଚାଭୟମେବ ଚ ॥10.4॥",

    "ଅହିଂସା ସମତା ତୁଷ୍ଟିଃ(ସ୍), ତପୋ ଦାନଂ(ମ୍) ୟଶୋଽୟଶଃ। \nଭବନ୍ତି ଭାବା ଭୂତାନାଂ(ମ୍), ମତ୍ତ ଏବ ପୃଥଗ୍ବିଧାଃ ॥10.5॥",

    "ମହର୍ଷୟଃ(ସ୍) ସପ୍ତ ପୂର୍ବେ ଚତ୍ୱାରୋ ମନବସ୍ତଥା।\nମଦ୍ଭାବା ମାନସା ଜାତା, ୟେଷାଂ(ମ୍) ଲୋକ ଇମାଃ(ଫ୍) ପ୍ରଜାଃ ॥10.6॥",

    "ଏତାଂ(ମ୍) ବିଭୂତିଂ(ମ୍) ୟୋଗଂ(ଞ୍) ଚ, ମମ ୟୋ ବେତ୍ତି ତତ୍ତ୍ୱତଃ।\nସୋଽବିକମ୍ପେନ ୟୋଗେନ, ୟୁଜ୍ୟତେ ନାତ୍ର ସଂଶୟଃ ॥10.7॥",

    "ଅହଂ(ମ୍) ସର୍ବସ୍ୟ ପ୍ରଭବୋ, ମତ୍ତଃ(ସ୍) ସର୍ବଂ(ମ୍) ପ୍ରବର୍ତତେ।\nଇତି ମତ୍ୱା ଭଜନ୍ତେ ମାଂ(ମ୍), ବୁଧା ଭାବସମନ୍ୱିତାଃ ॥10.8॥",

    "ମଚ୍ଚିତ୍ତା ମଦ୍ଗତପ୍ରାଣା, ବୋଧୟନ୍ତଃ(ଫ୍) ପରସ୍ପରମ୍।\nକଥୟନ୍ତଶ୍ଚ ମାଂ(ନ୍) ନିତ୍ୟ(ନ୍), ତୁଷ୍ୟନ୍ତି ଚ ରମନ୍ତି ଚ ॥10.9॥",

    "ତେଷାଂ(ମ୍) ସତତୟୁକ୍ତାନାଂ(ମ୍), ଭଜତାଂ(ମ୍) ପ୍ରୀତିପୂର୍ବକମ୍।\nଦଦାମି ବୁଦ୍ଧିୟୋଗଂ(ନ୍) ତଂ(ମ୍), ତେନ ମାମୁପୟାନ୍ତି ତେ ॥10.10॥",

    "ତେଷାମେବାନୁକମ୍ପାର୍ଥମ୍, ଅହମଜ୍ଞାନଜଂ(ନ୍) ତମଃ।\nନାଶୟାମ୍ୟାତ୍ମଭାବସ୍ଥୋ, ଜ୍ଞାନଦୀପେନ ଭାସ୍ୱତା ॥10.11॥",

    "ଅର୍ଜୁନ ଉବାଚ\nପରଂ(ମ୍) ବ୍ରହ୍ମ ପରଂ(ନ୍) ଧାମ, ପବିତ୍ରଂ(ମ୍) ପରମଂ(ମ୍) ଭବାନ୍।\nପୁରୁଷଂ(ମ୍) ଶାଶ୍ୱତଂ(ନ୍) ଦିବ୍ୟମ୍, ଆଦିଦେବମଜଂ(ମ୍) ବିଭୁମ୍ ॥10.12॥",

    "ଆହୁସ୍ତ୍ୱାମୃଷୟଃ(ସ୍) ସର୍ବେ, ଦେବର୍ଷିର୍ନାରଦସ୍ତଥା।\nଅସିତୋ ଦେବଲୋ ବ୍ୟାସଃ(ସ୍), ସ୍ୱୟଂ(ଞ୍) ଚୈବ ବ୍ରବୀଷି ମେ ॥10.13॥",

    "ସର୍ବମେତଦୃତଂ(ମ୍) ମନ୍ୟେ, ୟନ୍ମାଂ(ମ୍) ବଦସି କେଶବ।\nନ ହି ତେ ଭଗବନ୍ବ୍ୟକ୍ତିଂ(ମ୍), ବିଦୁର୍ଦେବା ନ ଦାନବାଃ ॥10.14॥",

    "ସ୍ୱୟମେବାତ୍ମନାତ୍ମାନଂ(ମ୍), ବେତ୍ଥ ତ୍ୱଂ(ମ୍) ପୁରୁଷୋତ୍ତମ।\nଭୂତଭାବନ ଭୂତେଶ, ଦେବଦେବ ଜଗତ୍ପତେ ॥10.15॥",

    "ବକ୍ତୁମର୍ହସ୍ୟଶେଷେଣ, ଦିବ୍ୟା ହ୍ୟାତ୍ମବିଭୂତୟଃ।\nୟାଭିର୍ବିଭୂତିଭିର୍ଲୋକାନ୍, ଇମାଂସ୍ତ୍ୱଂ(ମ୍) ବ୍ୟାପ୍ୟ ତିଷ୍ଠସି ॥10.16॥",

    "କଥଂ(ମ୍) ବିଦ୍ୟାମହଂ(ମ୍) ୟୋଗିଂସ୍, ତ୍ୱାଂ(ମ୍) ସଦା ପରିଚିନ୍ତୟନ୍।\nକେଷୁ କେଷୁ ଚ ଭାବେଷୁ, ଚିନ୍ତ୍ୟୋଽସି ଭଗବନ୍ମୟା ॥10.17॥",

    "ବିସ୍ତରେଣାତ୍ମନୋ 'ୟୋଗଂ(ମ୍), ବିଭୂତିଂ(ଞ୍) ଚ ଜନାର୍ଦନ।\nଭୂୟଃ(ଖ୍) କଥୟ ତୃପ୍ତିର୍ହି, ଶୃଣ୍ୱତୋ ନାସ୍ତି ମେଽମୃତମ୍ ॥10.18॥",

    "ଶ୍ରୀଭଗବାନୁବାଚ\nହନ୍ତ ତେ କଥୟିଷ୍ୟାମି, ଦିବ୍ୟା ହ୍ୟାତ୍ମବିଭୂତୟଃ।\nପ୍ରାଧାନ୍ୟତଃ(ଖ୍) କୁରୁଶ୍ରେଷ୍ଠ, ନାସ୍ତ୍ୟନ୍ତୋ ବିସ୍ତରସ୍ୟ ମେ ॥10.19॥",

    "ଅହମାତ୍ମା ଗୁଡାକେଶ, ସର୍ବାଭୂତାଶୟସ୍ଥିତଃ।\nଅହମାଦିଶ୍ଚ ମଧ୍ୟଂ(ଞ୍) ଚ, ଭୂତାନାମନ୍ତ ଏବ ଚ ॥10.20॥",

    "ଆଦିତ୍ୟାନାମହଂ(ମ୍) ବିଷ୍ଣୁଃ(ର୍), ଜ୍ୟୋତିଷାଂ(ମ୍) ରବିରଂଶୁମାନ୍।\nମରୀଚିର୍ମରୁତାମସ୍ମି, ନକ୍ଷତ୍ରାଣାମହଂ(ମ୍) ଶଶୀ ॥10.21॥",

    "ବେଦାନାଂ(ମ୍) ସାମବେଦୋଽସ୍ମି, ଦେବାନାମସ୍ମି ବାସବଃ।\n'ଇନ୍ଦ୍ରିୟାଣାଂ(ମ୍) ମନଶ୍ଚାସ୍ମି, ଭୂତାନାମସ୍ମି ଚେତନା ॥10.22॥",

    "ରୁଦ୍ରାଣାଂ(ମ୍) ଶଙ୍କରଶ୍ଚାସ୍ମି, ବିତ୍ତେଶୋ ୟକ୍ଷରକ୍ଷସାମ୍।\nବସୂନାଂ(ମ୍) ପାବକଶ୍ଚାସ୍ମି, ମେରୁଃ(ଶ୍) ଶିଖରିଣାମହମ୍ ॥10.23॥",

    "ପୁରୋଧସାଂ(ଞ୍) ଚ ମୁଖ୍ୟଂ(ମ୍) ମାଂ(ମ୍), ବିଦ୍ଧି ପାର୍ଥ ବୃହସ୍ପତିମ୍।\nସେନାନୀନାମହଂ(ମ୍) ସ୍କନ୍ଦଃ(ସ୍), ସରସାମସ୍ମି ସାଗରଃ ॥10.24॥",

    "ମହର୍ଷୀଣାଂ(ମ୍) ଭୃଗୁରହଂ(ଙ୍), ଗିରାମସ୍ମ୍ୟୈକମକ୍ଷରମ୍।\nୟଜ୍ଞାନାଂ(ଞ୍) ଜପୟଜ୍ଞୋଽସ୍ମି, ସ୍ଥାବରାଣାଂ(ମ୍) ହିମାଲୟଃ ॥10.25॥",

    "ଅଶ୍ୱତ୍ଥଃ(ସ୍) ସର୍ବବୃକ୍ଷାଣାଂ(ନ୍), ଦେବର୍ଷିଣାଂ(ଞ୍) ଚ ନାରଦଃ।\nଗନ୍ଧର୍ବାଣାଂ(ଞ୍) ଚିତ୍ରରଥଃ(ସ୍), ସିଦ୍ଧାନାଂ(ଙ୍) କପିଲୋ ମୁନିଃ ॥10.26॥",

    "ଉଚ୍ଚୈଃଶ୍ରବସମଶ୍ୱାନାଂ(ମ୍), ବିଦ୍ଧି ମାମମୃତୋଦ୍ଭବମ୍।\nଐରାବତଂ(ଙ୍) ଗଜେନ୍ଦ୍ରାଣାଂ(ନ୍), ନରାଣାଂ(ଞ୍) ଚ ନରାଧିପମ୍ ॥10.27॥",

    "ଆୟୁଧାନାମହଂ(ମ୍) ବଜ୍ରଂ(ନ୍), ଧେନୂନାମସ୍ମି କାମଧୁକ୍।\nପ୍ରଜନଶ୍ଚାସ୍ମି କନ୍ଦର୍ପଃ(ସ୍), ସର୍ପାଣାମସ୍ମି ବାସୁକିଃ ॥10.28॥",

    "ଅନନ୍ତଶ୍ଚାସ୍ମି ନାଗାନାଂ(ମ୍), ବରୁଣୋ ୟାଦସାମହମ୍।\nପିତୃ୍ୃଣାମର୍ୟମା ଚାସ୍ମି, ୟମଃ(ସ୍) ସଂୟମତାମହମ୍ ॥10.29॥",

    "ପ୍ରହ୍ଲାଦଶ୍ଚାସ୍ମି ଦୈତ୍ୟାନାଂ(ଙ୍), କାଲଃ(ଖ୍) କଲୟତାମହମ୍।\nମୃଗାଣାଂ(ଞ୍) ଚ ମୃଗେନ୍ଦ୍ରୋଽହଂ(ମ୍), ବୈନତେୟଶ୍ଚ ପକ୍ଷିଣାମ୍ ॥10.30॥",

    "ପବନଃ(ଫ୍) ପବତାମସ୍ମି, ରାମଃ(ଶ୍) ଶସ୍ତ୍ରଭୃତାମହମ୍।\nଝଷାଣାଂ(ମ୍) ମକରଶ୍ଚାସ୍ମି, ସ୍ରୋତସାମସ୍ମି ଜାହ୍ନବୀ ॥10.31॥",

    "ସର୍ଗାଣାମାଦିରନ୍ତଶ୍ଚ, ମଧ୍ୟଂ(ଞ୍) ଚୈବାହମର୍ଜୁନ।\nଅଧ୍ୟାତ୍ମବିଦ୍ୟା ବିଦ୍ୟାନାଂ(ମ୍), ବାଦଃ(ଫ୍) ପ୍ରବଦତାମହମ୍ ॥10.32॥",

    "ଅକ୍ଷରାଣାମକାରୋଽସ୍ମି, ଦ୍ୱନ୍ଦ୍ୱଃ(ସ୍) ସାମାସିକସ୍ୟ ଚ।\nଅହମେବାକ୍ଷୟଃ(ଖ୍) କାଲୋ, ଧାତାହଂ(ମ୍) ବିଶ୍ୱତୋମୁଖଃ ॥10.33॥",

    "ମୃତ୍ୟୁଃ(ସ୍) ସର୍ବହରଶ୍ଚାହମ୍, ଉଦ୍ଭବଶ୍ଚ ଭବିଷ୍ୟତାମ୍।\nକୀର୍ତ୍ତିଃ(ଶ୍) ଶ୍ରୀର୍ବାକ୍ଚ ନାରୀଣାଂ(ମ୍), ସ୍ମୃତିର୍ମେଧା ଧୃତିଃ କ୍ଷମା ॥10.34॥",

    "ବୃହତ୍ସାମ ତଥା ସାମ୍ନାଂ(ଙ୍), ଗାୟତ୍ରୀ ଛନ୍ଦସାମହମ୍।\nମାସାନାଂ(ମ୍) ମାର୍ଗଶୀର୍ଷୋଽହମ୍, ଋତୂନାଂ(ଙ୍) କୁସୁମାକରଃ ॥10.35॥",

    "ଦ୍ୟୁତଂ(ଞ୍) ଛଲୟତାମସ୍ମି, ତେଜସ୍ତେଜସ୍ୱିନାମହମ୍।\nଜୟୋଽସ୍ମି ବ୍ୟବସାୟୋଽସ୍ମି, ସତ୍ତ୍ୱ(ମ୍) ସତ୍ତ୍ୱବତାମହମ୍ ॥10.36॥",

    "ବୃଷ୍ଣୀନାଂ(ମ୍) ବାସୁଦେବୋଽସ୍ମି, ପାଣ୍ଡବାନାଂ(ନ୍) ଧନଞ୍ଜୟଃ।\nମୁନୀନାମପ୍ୟହଂ(ମ୍) ବ୍ୟାସଃ(ଖ୍), କବୀନାମୁଶନା କବିଃ ॥10.37॥",

    "ଦଣ୍ଡୋ ଦମୟତାମସ୍ମି, ନୀତିରସ୍ମି ଜିଗୀଷତାମ୍।\nମୌନଂ(ଞ୍) ଚୈବାସ୍ମି ଗୁହ୍ୟାନାଂ(ଞ୍), ଜ୍ଞାନଂ(ଞ୍) ଜ୍ଞାନବତାମହମ୍ ॥10.38॥",

    "ୟଚ୍ଚାପି ସର୍ବଭୂତାନାଂ(ମ୍), ବୀଜଂ(ନ୍) ତଦହମର୍ଜୁନ।\nନ ତଦସ୍ତି ବିନା ୟତ୍ସ୍ୟାନ୍, ମୟା ଭୂତଂ(ଞ୍) ଚରାଚରମ୍ ॥10.39॥",

    "ନାନ୍ତୋଽସ୍ତି ମମ ଦିବ୍ୟାନାଂ(ମ୍), ବିଭୂତୀନାଂ(ମ୍) ପରନ୍ତପ।\nଏଷ ତୁଦ୍ଦେଶତଃ(ଫ୍) ପ୍ରୋକ୍ତୋ, ବିଭୂତେର୍ବିସ୍ତରୋ ମୟା ॥10.40॥",

    "ୟଦ୍ୟଦ୍ୱିଭୂତିମତ୍ସତ୍ତ୍ୱଂ(ମ୍), ଶ୍ରୀମଦୂର୍ଜିତମେବ ବା।\nତତ୍ତଦେବାବଗଚ୍ଛ ତ୍ୱଂ(ମ୍), ମମ ତେଜୋଂଽଶସମ୍ଭବମ୍ ॥10.41॥",

    "ଅଥବା ବହୁନୈତେନ, କିଂ(ଞ୍) ଜ୍ଞାତେନ ତବାର୍ଜୁନ।\nବିଷ୍ଟଭ୍ୟାହମିଦଂ(ଙ୍) କୃତ୍ସ୍ନମ୍, ଏକାଂଶେନ ସ୍ଥିତୋ ଜଗତ୍ ॥10.42॥",

    "ଓଁ ତତ୍ସଦିତି ଶ୍ରୀମଦ୍ଭଗବଦ୍ଗୀତାସୁ ଉପନିଷତ୍ସୁ ବ୍ରହ୍ମବିଦ୍ୟାୟାଂ(ୟ୍ଁ) ୟୋଗଶାସ୍ତ୍ରେ ଶ୍ରୀକୃଷ୍ଣାର୍ଜୁନସଂବାଦେ ବିଭୂତିୟୋଗୋ ନାମ ଦଶମୋଽଧ୍ୟାୟଃ।।"
  ],
  "adhyaya-11" : [
    "ଅର୍ଜୁନ ଉବାଚ\nମଦନୁଗ୍ରହାୟ ପରମଂ(ଙ୍), ଗୁହ୍ୟମଧ୍ୟାତ୍ମସଞ୍ଜ୍ଞିତମ୍।\nୟତ୍ତ୍ୱୟୋକ୍ତଂ(ମ୍) ବଚସ୍ତେନ, ମୋହୋଽୟଂ(ମ୍) ବିଗତୋ ମମ। ॥11.1॥",

    "ଭବାପ୍ୟୟୌ ହି ଭୂତାନାଂ(ମ୍), ଶ୍ରୁତୌ ବିସ୍ତରଶୋ ମୟା।\nତ୍ୱତ୍ତଃ(ଖ୍) କମଲପତ୍ରାକ୍ଷ, ମାହାତ୍ମ୍ୟମପି ଚାବ୍ୟୟମ୍। ॥11.2॥",

    "ଏବମେତଦ୍ୟଥାତ୍ଥ ତ୍ୱମ୍, ଆତ୍ମାନଂ(ମ୍) ପରମେଶ୍ୱର।\nଦ୍ରଷ୍ଟୁମିଚ୍ଛାମି ତେ ରୂପମ୍, ଐଶ୍ୱରଂ(ମ୍) ପୁରୁଷୋତ୍ତମ। ॥11.3॥",

    "ମନ୍ୟସେ ୟଦି ତଚ୍ଛକ୍ୟଂ(ମ୍), ମୟା ଦ୍ରଷ୍ଟୁମିତି ପ୍ରଭୋ।\nୟୋଗେଶ୍ୱର ତତୋ ମେ ତ୍ୱଂ(ନ୍), ଦର୍ଶୟାତ୍ମାନମବ୍ୟୟମ୍। ॥11.4॥",

    "ଶ୍ରୀଭଗବାନୁବାଚ\nପଶ୍ୟ ମେ ପାର୍ଥ ରୂପାଣି, ଶତଶୋଽଥ ସହସ୍ରଶଃ।\nନାନାବିଧାନି ଦିବ୍ୟାନି, ନାନାବର୍ଣ୍ଣାକୃତୀନି ଚ। ॥11.5॥",

    "ପଶ୍ୟାଦିତ୍ୟାନ୍ ବସୂନ୍ ରୁଦ୍ରାନଶ୍ୱିନୌ ମରୁତସ୍ତଥା।\nବହୂନ୍ୟଦୃଷ୍ଟପୂର୍ବାଣି, ପଶ୍ୟାଶ୍ଚର୍ୟାଣି ଭାରତ। ॥11.6॥",

    "ଇହୈକସ୍ଥଂ(ଞ୍) ଜଗତ୍କୃତ୍ସ୍ନଂ(ମ୍), ପଶ୍ୟାଦ୍ୟ ସଚରାଚରମ୍।\nମମ ଦେହେ ଗୁଡାକେଶ, ୟଚ୍ଚାନ୍ୟଦ୍ଦ୍ରଷ୍ଟୁମିଚ୍ଛସି। ॥11.7॥",

    "ନ ତୁ ମାଂ(ମ୍) ଶକ୍ୟସେ ଦ୍ରଷ୍ଟୁମନେନୈବ ସ୍ୱଚକ୍ଷୁଷା।\nଦିବ୍ୟଂ(ନ୍) ଦଦାମି ତେ ଚକ୍ଷୁଃ(ଫ୍), ପଶ୍ୟ ମେ ୟୋଗମୈଶ୍ୱରମ୍। ॥11.8॥",

    "ସଞ୍ଜୟ ଉବାଚ\nଏବମୁକ୍ତ୍ୱା ତତୋ ରାଜନ୍, ମହାୟୋଗେଶ୍ୱରୋ ହରିଃ।\nଦର୍ଶୟାମାସ ପାର୍ଥାୟ, ପରମଂ(ମ୍) ରୂପମୈଶ୍ୱରମ୍। ॥11.9॥",

    "ଅନେକବକ୍ତ୍ରନୟନମ୍, ଅନେକାଦ୍ଭୁତଦର୍ଶନମ୍।\nଅନେକଦିବ୍ୟାଭରଣଂ(ନ୍), ଦିବ୍ୟାନେକୋଦ୍ୟତାୟୁଧମ୍। ॥11.10॥",

    "ଦିବ୍ୟମାଲ୍ୟାମ୍ବରଧରଂ(ନ୍), ଦିବ୍ୟଗନ୍ଧାନୁଲେପନମ୍।\nସର୍ବାଶ୍ଚର୍ୟମୟଂ(ନ୍) ଦେବମ୍, ଅନନ୍ତଂ(ମ୍) ବିଶ୍ୱତୋମୁଖମ୍। ॥11.11॥",

    "ଦିବି ସୂର୍ୟସହସ୍ରସ୍ୟ, ଭବେଦ୍ୟୁଗପଦୁତ୍ଥିତା।\nୟଦି ଭାଃ(ସ୍) ସଦୃଶୀ ସା ସ୍ୟାଦ୍, ଭାସସ୍ତସ୍ୟ ମହାତ୍ମନଃ। ॥11.12॥",

    "ତତ୍ରୈକସ୍ଥଂ(ଞ୍) ଜଗତ୍କୃତ୍ସ୍ନଂ(ମ୍), ପ୍ରବିଭକ୍ତମନେକଧା।\nଅପଶ୍ୟଦ୍ଦେବଦେବସ୍ୟ, ଶରୀରେ ପାଣ୍ଡବସ୍ତଦା। ॥11.13॥",

    "ତତଃ(ସ୍) ସ ବିସ୍ମୟାବିଷ୍ଟୋ, ହୃଷ୍ଟରୋମା ଧନଞ୍ଜୟଃ।\nପ୍ରଣମ୍ୟ ଶିରସା ଦେବଂ(ଙ୍), କୃତାଞ୍ଜଲିରଭାଷତ। ॥11.14॥",

    "ଅର୍ଜୁନ ଉବାଚ\nପଶ୍ୟାମି ଦେବାଂସ୍ତବ ଦେବ ଦେହେ,\nସର୍ବାଂସ୍ତଥା ଭୂତବିଶେଷସଙ୍ଘାନ୍।\nବ୍ରହ୍ମାଣମୀଶଂ(ଙ୍) କମଲାସନସ୍ଥମ୍,\nଋଷୀଂଶ୍ଚ ସର୍ବାନୁରଗାଂଶ୍ଚ ଦିବ୍ୟାନ୍ । ॥11.15॥",

    "ଅନେକବାହୂଦରବକ୍ତ୍ରନେତ୍ରଂ(ମ୍), \nପଶ୍ୟାମି ତ୍ୱାଂ(ମ୍) ସର୍ବତୋଽନନ୍ତରୂପମ୍।\nନାନ୍ତଂ(ନ୍) ନ ମଧ୍ୟଂ(ନ୍) ନ ପୁନସ୍ତବାଦିଂ(ମ୍),\nପଶ୍ୟାମି ବିଶ୍ୱେଶ୍ୱର ବିଶ୍ୱରୂପ। ॥11.16॥",

    "କିରୀଟିନଂ(ଙ୍) ଗଦିନଂ(ଞ୍) ଚକ୍ରିଣଂ(ଞ୍) ଚ, \nତେଜୋରାଶିଂ(ମ୍) ସର୍ବତୋ ଦୀପ୍ତିମନ୍ତମ୍।\nପଶ୍ୟାମି ତ୍ୱାଂ(ନ୍) ଦୁର୍ନିରୀକ୍ଷ୍ୟଂ(ମ୍) ସମନ୍ତାଦ୍-,\nଦୀପ୍ତାନଲାର୍କଦ୍ୟୁତିମପ୍ରମେୟମ୍। ॥11.17॥",

    "ତ୍ୱମକ୍ଷରଂ(ମ୍) ପରମଂ(ମ୍) ବେଦିତବ୍ୟଂ(ନ୍),\nତ୍ୱମସ୍ୟ ବିଶ୍ୱସ୍ୟ ପରଂ(ନ୍) ନିଧାନମ୍।\nତ୍ୱମବ୍ୟୟଃ(ଶ୍) ଶାଶ୍ୱତଧର୍ମଗୋପ୍ତା,\nସନାତନସ୍ତ୍ୱଂ(ମ୍) ପୁରୁଷୋ ମତୋ ମେ। ॥11.18॥",

    "ଅନାଦିମଧ୍ୟାନ୍ତମନନ୍ତବୀର୍ୟମ୍,\nଅନନ୍ତବାହୁଂ(ମ୍) ଶଶିସୂର୍ୟନେତ୍ରମ୍।\nପଶ୍ୟାମି ତ୍ୱାଂ(ନ୍) ଦୀପ୍ତହୁତାଶବକ୍ତ୍ରଂ(ମ୍),\nସ୍ୱତେଜସା ବିଶ୍ୱମିଦଂ(ନ୍) ତପନ୍ତମ୍। ॥11.19॥",

    "ଦ୍ୟାବାପୃଥିବ୍ୟୋରିଦମନ୍ତରଂ(ମ୍) ହି,\nବ୍ୟାପ୍ତଂ(ନ୍) ତ୍ୱୟୈକେନ ଦିଶଶ୍ଚ ସର୍ବାଃ।\nଦୃଷ୍ଟ୍ୱାଦ୍ଭୁତଂ(ମ୍) ରୂପମୁଗ୍ରଂ(ନ୍) ତବେଦଂ(ମ୍),\nଲୋକତ୍ରୟଂ(ମ୍) ପ୍ରବ୍ୟଥିତଂ(ମ୍) ମହାତ୍ମନ୍। ॥11.20॥",

    "ଅମୀ ହି ତ୍ୱାଂ(ମ୍) ସୁରସଙ୍ଘା ବିଶନ୍ତି,\nକେଚିଦ୍ଭୀତାଃ(ଫ୍) ପ୍ରାଞ୍ଜଲୟୋ ଗୃଣନ୍ତି।\nସ୍ୱସ୍ତୀତ୍ୟୁକ୍ତ୍ୱା ମହର୍ଷିସିଦ୍ଧସଙ୍ଘାଃ(ସ୍),\nସ୍ତୁୱନ୍ତି ତ୍ୱାଂ(ମ୍) ସ୍ତୁତିଭିଃ(ଫ୍) ପୁଷ୍କଲାଭିଃ। ॥11.21॥",

    "ରୁଦ୍ରାଦିତ୍ୟା ବସବୋ ୟେ ଚ ସାଧ୍ୟା-\nବିଶ୍ୱେଽଶ୍ୱିନୌ ମରୁତଶ୍ଚୋଷ୍ମପାଶ୍ଚ।\nଗନ୍ଧର୍ବୟକ୍ଷାସୁରସିଦ୍ଧସଙ୍ଘା,\nବୀକ୍ଷନ୍ତେ ତ୍ୱାଂ(ମ୍) ବିସ୍ମିତାଶ୍ଚୈବ ସର୍ବେ। ॥11.22॥",

    "ରୂପଂ(ମ୍) ମହତ୍ତେ ବହୁବକ୍ତ୍ରନେତ୍ରଂ(ମ୍),\nମହାବାହୋ ବହୁବାହୂରୁପାଦମ୍।\nବହୂଦରଂ(ମ୍) ବହୁଦଂଷ୍ଟ୍ରାକରାଲଂ(ନ୍),\nଦୃଷ୍ଟ୍ୱା ଲୋକାଃ(ଫ୍) ପ୍ରବ୍ୟଥିତାସ୍ତଥାହମ୍। ॥11.23॥",

    "ନଭଃସ୍ପୃଶଂ(ନ୍) ଦୀପ୍ତମନେକବର୍ଣ୍ଣଂ(ମ୍)\nବ୍ୟାତ୍ତାନନଂ(ନ୍) ଦୀପ୍ତବିଶାଲନେତ୍ରମ୍।\nଦୃଷ୍ଟ୍ୱା ହି ତ୍ୱାଂ(ମ୍) ପ୍ରବ୍ୟଥିତାନ୍ତରାତ୍ମା, \nଧୃତିଂ(ନ୍) ନ ବିନ୍ଦାମି ଶମଂ(ଞ୍) ଚ ବିଷ୍ଣୋ। ॥11.24॥",

    "ଦଂଷ୍ଟ୍ରାକରାଲାନି ଚ ତେ ମୁଖାନି,\nଦୃଷ୍ଟ୍ୱୈବ କାଲାନଲସନ୍ନିଭାନି।\nଦିଶୋ ନ ଜାନେ ନ ଲଭେ ଚ ଶର୍ମ, \nପ୍ରସୀଦ ଦେବେଶ ଜଗନ୍ନିବାସ। ॥11.25॥",

    "ଅମୀ ଚ ତ୍ୱାଂ(ନ୍) ଧ୍ରୁତରାଷ୍ଟ୍ରସ୍ୟ ପୁତ୍ରାଃ(ସ୍),\nସର୍ବେ ସହୈବାବନିପାଲସଙ୍ଘୈଃ।\nଭୀଷ୍ମୋ ଦ୍ରୋଣଃ(ସ୍) ସୂତପୁତ୍ରସ୍ତଥାସୌ,\nମହାସ୍ମଦୀୟୈରପି ୟୋଧମୁଖ୍ୟୈଃ। ॥11.26॥",

    "ବକ୍ତ୍ରାଣି ତେ ତ୍ୱରମାଣା ବିଶନ୍ତି,\nଦଂଷ୍ଟ୍ରାକରାଲାନି ଭୟାନକାନି।\nକେଚିଦ୍ୱିଲଗ୍ନା ଦଶନାନ୍ତରେଷୁ,\nସନ୍ଦୃଶ୍ୟନ୍ତେ ଚୂର୍ଣ୍ଣିତୈରୁତ୍ତମାଙ୍ଗୈଃ। ॥11.27॥",

    "ୟଥା ନଦୀନାଂ(ମ୍) ବହବୋଽମ୍ବୁବେଗାଃ(ସ୍),\nସମୁଦ୍ରମେବାଭିମୁଖା ଦ୍ରବନ୍ତି।\nତଥା ତବାମି ନରଲୋକବୀରା,\nବିଶନ୍ତି ବକ୍ତ୍ରାଣ୍ୟଭିବିଜ୍ୱଲନ୍ତି। ॥11.28॥",

    "ୟଥା ପ୍ରଦୀପ୍ତଂ(ଞ୍) ଜ୍ୱଲନଂ(ମ୍) ପତଙ୍ଗା,\nବିଶନ୍ତି ନାଶାୟ ସମୃଦ୍ଧବେଗାଃ।\nତଥୈବ ନାଶାୟ ବିଶନ୍ତି ଲୋକାଃ(ସ୍), \nତବାପି ବକ୍ତ୍ରାଣି ସମୃଦ୍ଧବେଗାଃ। ॥11.29॥",

    "ଲେଲିହ୍ୟସେ ଗ୍ରସମାନଃ(ସ୍) ସମନ୍ତାଲ୍,\nଲୋକାନ୍ସମଗ୍ରାନ୍ ବଦନୈର୍ଜ୍ୱଲଦ୍ଭିଃ।\nତେଜୋଭିରାପୂର୍ୟ ଜଗତ୍ସମଗ୍ରଂ(ମ୍),\nଭାସସ୍ତବୋଗ୍ରାଃ(ଫ୍) ପ୍ରତପନ୍ତି ବିଷ୍ଣୋ। ॥11.30॥",

    "ଆଖ୍ୟାହି ମେ କୋ ଭବାନୁଗ୍ରରୂପୋ,\nନମୋଽସ୍ତୁ ତେ ଦେବବର ପ୍ରସୀଦ।\nବିଜ୍ଞାତୁମିଚ୍ଛାମି ଭବନ୍ତମାଦ୍ୟଂ(ନ୍),\nନ ହି ପ୍ରଜାନାମି ତବ ପ୍ରବୃତ୍ତିମ୍। ॥11.31॥",

    "ଶ୍ରୀଭଗବାନୁବାଚ\nକାଲୋଽସ୍ମି ଲୋକକ୍ଷୟକୃତ୍ପ୍ରବୃଦ୍ଧୋ, \nଲୋକାନ୍ ସମାହର୍ତୁମିହ ପ୍ରବୃତ୍ତଃ।\nଋତେଽପି ତ୍ୱାଂ(ନ୍) ନ ଭବିଷ୍ୟନ୍ତି ସର୍ବେ,\nୟେଽବସ୍ଥିତାଃ(ଫ୍) ପ୍ରତ୍ୟନୀକେଷୁ ୟୋଦ୍ଧାଃ। ॥11.32॥",

    "ତସ୍ମାତ୍ତ୍ୱମୁତ୍ତିଷ୍ଠ ୟଶୋ ଲଭସ୍ୱ,\nଜିତ୍ୱା ଶତ୍ରୂନ୍ ଭୁଙ୍କ୍ଷ୍ୱ ରାଜ୍ୟଂ(ମ୍) ସମୃଦ୍ଧମ୍।\nମୟୈବୈତେ ନିହତାଃ(ଫ୍) ପୂର୍ବମେବ, \nନିମିତ୍ତମାତ୍ରଂ(ମ୍) ଭବ ସବ୍ୟସାଚିନ୍। ॥11.33॥",

    "ଦ୍ରୋଣଂ(ଞ୍) ଚ ଭୀଷ୍ମଂ(ଞ୍) ଚ ଜୟଦ୍ରଥଂ(ଞ୍) ଚ, \nକର୍ଣ୍ଣଂ(ନ୍) ତଥାନ୍ୟାନପି ୟୋଧବୀରାନ୍।\nମୟା ହତାଂସ୍ତ୍ୱଂ(ଞ୍) ଜହି ମା ବ୍ୟଥିଷ୍ଠା,\nୟୁଧ୍ୟସ୍ୱ ଜେତାସି ରଣେ ସପତ୍ନାନ୍। ॥11.34॥",

    "ସଞ୍ଜୟ ଉବାଚ\nଏତଚ୍ଛ୍ରୁତ୍ୱା ବଚନଂ(ଙ୍) କେଶବସ୍ୟ,\nକୃତାଞ୍ଜଲିର୍ବେପମାନଃ(ଖ୍) କିରୀଟୀ।\nନମସ୍କୃତ୍ୱା ଭୂୟ ଏବାହ କୃଷ୍ଣଂ(ମ୍),\nସମଦ୍ଗଦଂ(ମ୍) ଭୀତଭୀତଃ(ଫ୍) ପ୍ରଣମ୍ୟ। ॥11.35॥",

    "ଅର୍ଜୁନ ଉବାଚ\nସ୍ଥାନେ ହୃଷୀକେଶ ତବ ପ୍ରକୀର୍ତ୍ୟା,\nଜଗତ୍ପ୍ରହୃଷ୍ୟତ୍ୟନୁରଜ୍ୟତେ ଚ।\nରକ୍ଷାଂସି ଭୀତାନି ଦିଶୋ ଦ୍ରବନ୍ତି,\nସର୍ବେ ନମସ୍ୟନ୍ତି ଚ ସିଦ୍ଧସଙ୍ଘାଃ। ॥11.36॥",

    "କସ୍ମାଚ୍ଚ ତେ ନ ନମେରନ୍ମହାତ୍ମନ୍,\nଗରୀୟସେ ବ୍ରହ୍ମଣୋଽପ୍ୟାଦିକର୍ତ୍ରେ।\nଅନନ୍ତ ଦେବେଶ ଜଗନ୍ନିବାସ,\nତ୍ୱମକ୍ଷରଂ(ମ୍) ସଦସତ୍ତତ୍ପରଂ(ମ୍) ୟତ୍। ॥11.37॥",

    "ତ୍ୱମାଦିଦେବଃ(ଫ୍) ପୁରୁଷଃ(ଫ୍) ପୁରାଣଃ(ସ୍),\nତ୍ୱମସ୍ୟ ବିଶ୍ୱସ୍ୟ ପରଂ(ନ୍) ନିଧାନମ୍।\nବେତ୍ତାସି ବେଦ୍ୟଂ(ଞ୍) ଚ ପରଂ(ଞ୍) ଚ ଧାମ,\nତ୍ୱୟା ତତଂ(ମ୍) ବିଶ୍ୱମନନ୍ତରୂପ। ॥11.38॥",

    "ବାୟୁର୍ୟମୋଽଗ୍ନିର୍ବରୁଣଃ(ଶ୍) ଶଶାଙ୍କଃ(ଫ୍),\nପ୍ରଜାପତିସ୍ତ୍ୱଂ(ମ୍) ପ୍ରପିତାମହଶ୍ଚ।\nନମୋ ନମସ୍ତେଽସ୍ତୁ ସହସ୍ରକୃତ୍ୱଃ(ଫ୍),\nପୁନଶ୍ଚ ଭୂୟୋଽପି ନମୋ ନମସ୍ତେ। ॥11.39॥",

    "ନମଃ(ଫ୍) ପୁରସ୍ତାଦଥ ପୃଷ୍ଠତସ୍ତେ,\nନମୋଽସ୍ତୁ ତେ ସର୍ବତ ଏବ ସର୍ବ।\nଅନନ୍ତବୀର୍ୟାମିତବିକ୍ରମସ୍ତ୍ୱଂ(ମ୍),\nସର୍ବଂ(ମ୍) ସମାପ୍ନୋଷି ତତୋଽସି ସର୍ବଃ। ॥11.40॥",

    "ସଖେତି ମତ୍ୱା ପ୍ରସଭଂ(ମ୍) ୟଦୁକ୍ତଂ(ମ୍),\nହେ କୃଷ୍ଣ ହେ ୟାଦବ ହେ ସଖେତି।\nଅଜାନତା ମହିମାନଂ(ନ୍) ତବେଦଂ(ମ୍),\nମୟା ପ୍ରମାଦାତ୍ପ୍ରଣୟେନ ବାପି। ॥11.41॥",

    "ୟଚ୍ଚାବହାସାର୍ଥମସତ୍କୃତୋଽସି,\nବିହାରଶୟ୍ୟାସନଭୋଜନେଷୁ।\nଏକୋଽଥବାପ୍ୟଚ୍ୟୁତ ତତ୍ସମକ୍ଷଂ(ନ୍),\nତତ୍କ୍ଷାମୟେ ତ୍ୱାମହମପ୍ରମେୟମ୍। ॥11.42॥",

    "ପିତାସି ଲୋକସ୍ୟ ଚରାଚରସ୍ୟ,\nତ୍ୱମସ୍ୟ ପୂଜ୍ୟଶ୍ଚ ଗୁରୁର୍ଗରୀୟାନ୍।\nନ ତ୍ୱତ୍ସମୋଽସ୍ତ୍ୟଭ୍ୟଧିକଃ(ଖ୍) କୁତୋଽନ୍ୟୋ,\nଲୋକତ୍ରୟେଽପ୍ୟପ୍ରତିମପ୍ରଭାବ। ॥11.43॥",

    "ତସ୍ମାତ୍ପ୍ରଣମ୍ୟ ପ୍ରଣିଧାୟ କାୟଂ(ମ୍),\nପ୍ରସାଦୟେ ତ୍ୱାମହମୀଶମୀଡ୍ୟମ୍।\nପିତେବ ପୁତ୍ରସ୍ୟ ସଖେବ ସଖ୍ୟୁଃ(ଫ୍),\nପ୍ରିୟଃ(ଫ୍) ପ୍ରିୟାୟାର୍ହସି ଦେବ ସୋଢୁମ୍। ॥11.44॥",

    "ଅଦୃଷ୍ଟପୂର୍ବଂ(ମ୍) ହୃଷିତୋଽସ୍ମି ଦୃଷ୍ଟ୍ୱା,\nଭୟେନ ଚ ପ୍ରବ୍ୟଥିତଂ(ମ୍) ମନୋ ମେ।\nତଦେବ ମେ ଦର୍ଶୟ ଦେବରୂପଂ(ମ୍),\nପ୍ରସୀଦ ଦେବେଶ ଜଗନ୍ନିବାସ । ॥11.45॥",

    "କିରୀଟିନଂ(ଙ୍) ଗଦିନଂ(ଞ୍) ଚକ୍ରହସ୍ତମ୍,\nଇଚ୍ଛାମି ତ୍ୱାଂ(ନ୍) ଦ୍ରଷ୍ଟୁମହଂ(ନ୍) ତଥୈବ।\nତେନୈବ ରୂପେଣ ଚତୁର୍ଭୁଜେନ,\nସହସ୍ରବାହୋ ଭବ ବିଶ୍ୱମୂର୍ତେ। ॥11.46॥",

    "ଶ୍ରୀଭଗବାନୁବାଚ\nମୟା ପ୍ରସନ୍ନେନ ତବାର୍ଜୁନେଦଂ(ମ୍),\nରୂପଂ(ମ୍) ପରଂ(ନ୍) ଦର୍ଶିତମାତ୍ମୟୋଗାତ୍।\nତେଜୋମୟଂ(ମ୍) ବିଶ୍ୱମନନ୍ତମାଦ୍ୟଂ(ମ୍),\nୟନ୍ମେ ତ୍ୱଦନ୍ୟେନ ନ ଦୃଷ୍ଟପୂର୍ବମ୍। ॥11.47॥",

    "ନ ବେଦୟଜ୍ଞାଧ୍ୟୟନୈର୍ନ ଦାନୈଃ(ର୍),\nନ ଚ କ୍ରିୟାଭିର୍ନ ତପୋଭିରୁଗ୍ରୈଃ ।\nଏବଂରୂପଃ(ଶ୍) ଶକ୍ୟ ଅହଂ(ନ୍) ନୃଲୋକେ,\nଦ୍ରଷ୍ଟୁଂ(ନ୍) ତ୍ୱଦନ୍ୟେନ କୁରୁପ୍ରବୀରଃ। ॥11.48॥",

    "ମା ତେ ବ୍ୟଥା ମା ଚ ବିମୂଢଭାବୋ,\nଦୃଷ୍ଟ୍ୱା ରୂପଂ(ଙ୍) ଘୋରମୀଦୃଙ୍ମମେଦମ୍।\nବ୍ୟପେତଭିଃ(ଫ୍) ପ୍ରୀତମନାଃ(ଫ୍) ପୁନସ୍ତ୍ୱଂ(ନ୍),\nତଦେବ ମେ ରୂପମିଦଂ(ମ୍) ପ୍ରପଶ୍ୟ। ॥11.49॥",

    "ସଞ୍ଜୟ ଉବାଚ\nଇତ୍ୟର୍ଜୁନଂ(ମ୍) ବାସୁଦେବସ୍ତଥୋକ୍ତ୍ୱା,\nସ୍ୱକଂ(ମ୍) ରୂପଂ(ନ୍) ଦର୍ଶୟାମାସ ଭୂୟଃ।\nଆଶ୍ୱାସୟାମାସ ଚ ଭୀତମେନଂ(ମ୍),\nଭୂତ୍ୱା ପୁନଃ(ସ୍) ସୌମ୍ୟବପୁର୍ମହାତ୍ମା। ॥11.50॥",

    "ଅର୍ଜୁନ ଉବାଚ\nଦୃଷ୍ୱେଦଂ(ମ୍) ମାନୁଷଂ(ମ୍) ରୂପଂ(ନ୍), ତବ ସୌମ୍ୟଂ(ଞ୍) ଜନାର୍ଦନ।\nଇଦାନୀମସ୍ମି ସଂବୃତ୍ତଃ(ସ୍), ସଚେତାଃ(ଫ୍) ପ୍ରକୃତି(ଙ୍) ଗତଃ। ॥11.51॥",

    "ଶ୍ରୀଭଗବାନୁବାଚ\nସୁଦୁର୍ଦର୍ଶମିଦଂ(ମ୍) ରୂପଂ(ନ୍), ଦୃଷ୍ଟବାନସି ୟନ୍ମମ।\nଦେବା ଅପ୍ୟସ୍ୟ ରୂପସ୍ୟ, ନିତ୍ୟଂ(ନ୍) ଦର୍ଶନକାଙ୍କ୍ଷିଣଃ। ॥11.52॥",

    "ନାହଂ(ମ୍) ବେଦୈର୍ନ ତପସା, ନ ଦାନେନ ନ ଚେଜ୍ୟୟା।\nଶକ୍ୟ ଏବଂବିଧୋ ଦ୍ରଷ୍ଟୁଂ(ନ୍), ଦୃଷ୍ଟବାନସି ମାଂ ୟଥା। ॥11.53॥",

    "ଏବମେତଦ୍ୟଥାତ୍ଥ ତ୍ୱମ୍, ଆତ୍ମାନଂ(ମ୍) ପରମେଶ୍ୱର।\nଦ୍ରଷ୍ଟୁମିଚ୍ଛାମି ତେ ରୂପମ୍, ଐଶ୍ୱରଂ(ମ୍) ପୁରୁଷୋତ୍ତମ। ॥11.54॥",

    "ମତ୍କର୍ମକୃନ୍ମତ୍ପରମୋ, ମଦ୍ଭକ୍ତଃ(ସ୍) ସଙ୍ଗବର୍ଜିତଃ।\nନିର୍ବୈରଃ(ସ୍) ସର୍ବଭୂତେଷୁ, ୟଃ(ସ୍) ସ ମାମେତି ପାଣ୍ଡବ। ॥11.55॥",

    "ଓଁ ତତ୍ସଦିତି ଶ୍ରୀମଦ୍ଭଗବଦ୍ଗୀତାସୁ ଉପନିଷତ୍ସୁ ବ୍ରହ୍ମବିଦ୍ୟାୟାଂ(ୟ୍ଁ) ୟୋଗଶାସ୍ତ୍ରେ ଶ୍ରୀକୃଷ୍ଣାର୍ଜୁନସଂବାଦେ ବିଶ୍ୱରୂପଦର୍ଶନୟୋଗୋ ନାମ ଏକାଦଶୋଽଧ୍ୟାୟଃ ॥11॥"
  ],
  "adhyaya-12" : [
    "ଅର୍ଜୁନ ଉବାଚ\nଏବଂ(ମ୍) ଶତତୟୁକ୍ତା ୟେ, ଭକ୍ତାସ୍ତ୍ୱାଂ(ମ୍) ପର୍ୟୁପାସତେ।\nୟେ ଚାପ୍ୟକ୍ଷର ମବ୍ୟକ୍ତଂ(ନ୍), ତେଷାଂ(ଙ୍) କେ ୟୋଗବିତ୍ତମାଃ। ॥12.1॥",

    "ଶ୍ରୀଭଗବାନୁବାଚ\nମୟାବେଶ୍ୟ ମନୋ ୟେ ମାଂ(ନ୍), ନିତ୍ୟୟୁକ୍ତା ଉପାସତେ।\nଶ୍ରଦ୍ଧୟା ପରୟୋପେତାଃ(ସ୍), ତେ ମେ ୟୁକ୍ତତମା ମତାଃ। ॥12.2॥",

    "ୟେ ତ୍ୱକ୍ଷର ମନିର୍ଦେଶ୍ୟମ୍, ଅବ୍ୟକ୍ତଂ(ମ୍) ପର୍ୟୁପାସତେ।\nସର୍ବତ୍ର ଗମଚିନ୍ତ୍ୟଂ(ଞ୍) ଚ କୂଟସ୍ଥମଚଲଂ(ନ୍) ଧ୍ରୁବମ୍। ॥12.3॥",

    "ସନ୍ନିୟମ୍ୟେନ୍ଦ୍ରିୟ ଗ୍ରାମଂ(ମ୍), ସର୍ବତ୍ର ସମବୁଦ୍ଧୟଃ।\nତେ ପ୍ରାପ୍ନୁବନ୍ତି ମାମେବ, ସର୍ବଭୂତହିତେ ରତାଃ। ॥12.4॥",

    "କ୍ଲେଶୋଽଧିକତରସ୍ତେଷାମ୍, ଅବ୍ୟକ୍ତାସକ୍ତଚେତସାମ୍।\nଅବ୍ୟକ୍ତା ହି ଗତିର୍ଦୁଃଖଂ(ନ୍), ଦେହବଦ୍ଭିରବାପ୍ୟତେ। ॥12.5॥",

    "ୟେ ତୁ ସର୍ବାଣି କର୍ମାଣି, ମୟି ସନ୍ନ୍ୟସ୍ୟ ମତ୍ପରାଃ।\nଅନନ୍ୟେନୈବ ୟୋଗେନ, ମାଂ(ନ୍) ଧ୍ୟାୟନ୍ତ ଉପାସତେ। ॥12.6॥",

    "ତେଷାମହଂ(ମ୍) ସମୁଦ୍ଧର୍ତା, ମୃତ୍ୟୁସଂସାରସାଗରାତ୍।\nଭବାମି ନଚିରାତ୍ପାର୍ଥ, ମୟ୍ୟାବେଶିତଚେତସାମ୍। ॥12.7॥",

    "ମୟ୍ୟେବ ମନ ଆଧତ୍ସ୍ୱ, ମୟି ବୁଦ୍ଧିଂ(ନ୍) ନିବେଶୟ।\nନିବସିଷ୍ୟସି ମୟ୍ୟେବ, ଅତ ଊର୍ଦ୍ଧ୍ୱଂ(ନ୍) ନ ସଂଶୟଃ। ॥12.8॥",

    "ଅଥ ଚିତ୍ତଂ(ମ୍) ସମାଧାତୁଂ(ନ୍), ନ ଶକ୍ନୋଷି ମୟି ସ୍ଥିରମ୍।\nଅଭ୍ୟାସୟୋଗେନ ତତୋ, ମାମିଚ୍ଛାପ୍ତୁଂ(ନ୍) ଧନଞ୍ଜୟ। ॥12.9॥",

    "ଅଭ୍ୟାସେଽପ୍ୟସମର୍ଥୋଽସି, ମତ୍କର୍ମପରମୋ ଭବ।\nମଦର୍ଥମପି କର୍ମାଣି, କୁର୍ବନ୍ସିଦ୍ଧିମବାପ୍ସ୍ୟସି। ॥12.10॥",

    "ଅଥୈତଦପ୍ୟସକ୍ତୋଽସି, କର୍ତୁଂ(ମ୍) ମଦ୍ୟୋଗମାଶ୍ରିତଃ।\nସର୍ବକର୍ମଫଲତ୍ୟାଗଂ(ନ୍), ତତଃ(ଖ୍) କୁରୁ ୟତାତ୍ମବାନ୍। ॥12.11॥",

    "ଶ୍ରେୟୋ ହି ଜ୍ଞାନମଭ୍ୟାସାଜ୍, ଜ୍ଞାନାଦ୍ଧ୍ୟାନଂ(ୱ୍ଁ) ବିଶିଷ୍ୟତେ।\nଧ୍ୟାନାତ୍କର୍ମଫଲତ୍ୟାଗଃ(ସ୍), ତ୍ୟାଗାଚ୍ଛାନ୍ତିରନନ୍ତରମ୍। ॥12.12॥",

    "ଅଦ୍ୱେଷ୍ଟା ସର୍ବଭୂତାନାଂ(ମ୍), ମୈତ୍ରଃ(ଖ୍) କରୁଣ ଏବ ଚ।\nନିର୍ମମୋ ନିରହଙ୍କାରଃ(ସ୍), ସମଦୁଃଖସୁଖଃ କ୍ଷମୀ। ॥12.13॥",

    "ସନ୍ତୁଷ୍ଟଃ(ସ୍) ସତତଂ(ୟ୍ଁ) ୟୋଗୀ, ୟତାତ୍ମା ଦୃଢନିଶ୍ଚୟଃ।\nମୟ୍ୟର୍ପିତମନୋବୁଦ୍ଧିଃ(ର୍), ୟୋ ମଦ୍ଭକ୍ତଃ(ସ୍) ସ ମେ ପ୍ରିୟଃ। ॥12.14॥",

    "ୟସ୍ମାନ୍ନୋଦ୍ୱିଜତେ ଲୋକୋ, ଲୋକାନ୍ନୋଦ୍ୱିଜତେ ଚ ୟଃ।\nହର୍ଷାମର୍ଷଭୟୋଦ୍ୱେଗୈଃ(ର୍), ମୁକ୍ତୋ ୟଃ(ସ୍) ସ ଚ ମେ ପ୍ରିୟଃ। ॥12.15॥",

    "ଅନପେକ୍ଷଃ(ଶ୍) ଶୁଚିର୍ଦକ୍ଷ, ଉଦାସୀନୋ ଗତବ୍ୟଥଃ।\nସର୍ବାରମ୍ଭପରିତ୍ୟାଗୀ, ୟୋ ମଦ୍ଭକ୍ତଃ(ସ୍) ସ ମେ ପ୍ରିୟଃ। ॥12.16॥",

    "ୟୋ ନ ହୃଷ୍ୟତି ନ ଦ୍ୱେଷ୍ଟି, ନ ଶୋଚତି ନ କାଙ୍କ୍ଷତି।\nଶୁଭାଶୁଭପରିତ୍ୟାଗୀ, ଭକ୍ତିମାନ୍ୟଃ(ସ୍) ସ ମେ ପ୍ରିୟଃ। ॥12.17॥",

    "ସମଃ(ଶ୍) ଶତ୍ରୌ ତ ମିତ୍ରେ ଚ, ତଥା ମାନାପମାନୟୋଃ।\nଶୀତୋଷ୍ଣସୁଖଦୁଃଖେଷୁ, ସମଃ(ସ୍) ସଙ୍ଗବିବର୍ଜିତଃ। ॥12.18॥",

    "ତୁଲ୍ୟନିନ୍ଦାସ୍ତୁତିର୍ମୌନୀ, ସନ୍ତୁଷ୍ଟୋ ୟେନ କେନଚିତ୍।\nଅନିକେତଃ(ସ୍) ସ୍ଥିରମତିଃ(ର୍), ଭକ୍ତିମାନ୍ମେ ପ୍ରିୟୋ ନରଃ। ॥12.19॥",

    "ୟେ ତୁ ଧର୍ମ୍ୟାମୃତମିଦଂ(ୟ୍ଁ), ୟଥୋକ୍ତଂ(ମ୍) ପର୍ୟୁପାସତେ।\nଶ୍ରଦ୍ଦଧାନା ମତ୍ପରମା, ଭକ୍ତାସ୍ତେଽତୀବ ମେ ପ୍ରିୟାଃ। ॥12.20॥",

    "ଓଁ ତତ୍ସଦିତି ଶ୍ରୀମଦ୍ଭଗବଦ୍ଗୀତାସୁ ଉପନିଷତ୍ସୁ ବ୍ରହ୍ମବିଦ୍ୟାୟାଂ(ୟ୍ଁ) ୟୋଗଶାସ୍ତ୍ରେ ଶ୍ରୀକୃଷ୍ଣାର୍ଜୁନସଂବାଦେ ଭକ୍ତିୟୋଗୋ ନାମ ଦ୍ୱାଦଶୋଽଧ୍ୟାୟଃ। ॥12॥",
  ],
  "adhyaya-13" : [
    "ଶ୍ରୀଭଗବାନୁବାଚ\nଇଦଂ(ମ୍) ଶରୀରଂ(ଙ୍) କୌନ୍ତେୟ, କ୍ଷେତ୍ରମିତ୍ୟଭିଧୀୟତେ।\nଏତଦ୍ୟୋ ବେତ୍ତି ତଂ(ମ୍) ପ୍ରାହୁଃ, କ୍ଷେତ୍ରଜ୍ଞ ଇତି ତଦ୍ୱିଦଃ। ॥13.1॥",

    "କ୍ଷେତ୍ରଜ୍ଞଂ(ଞ୍) ଚାପି ମାଂ(ମ୍) ବିଦ୍ଧି, ସର୍ବକ୍ଷେତ୍ରେଷୁ ଭାରତ।\nକ୍ଷେତ୍ରକ୍ଷେତ୍ରଜ୍ଞୟୋର୍ଜ୍ଞାନଂ(ୟ୍ଁ), ୟତ୍ତଜ୍ଜ୍ଞାନଂ(ମ୍) ମତଂ(ମ୍) ମମ। ॥13.2॥",

    "ତତ୍କ୍ଷେତ୍ରଂ(ମ୍) ୟଚ୍ଚ ୟାଦୃକ୍ଚ, ୟଦ୍ୱିକାରି ୟତଶ୍ଚ ୟତ୍।\nସ ଚ ୟୋ ୟତ୍ପ୍ରଭାବଶ୍ଚ, ତତ୍ସମାସେନ ମେ ଶୃଣୁ। ॥13.3॥",

    "ଋଷିଭିର୍ବହୁଧା ଗୀତଂ(ଞ୍), ଛନ୍ଦୋଭିର୍ବିବିଧୈଃ(ଫ୍) ପୃଥକ୍।\nବ୍ରହ୍ମସୂତ୍ରପଦୈଶ୍ଚୈବ, ହେତୁମଦ୍ଭିର୍ବିନିଶ୍ଚିତୈଃ। ॥13.4॥",

    "ମହାଭୂତାନ୍ୟହଙ୍କାରୋ, ବୁଦ୍ଧିରବ୍ୟକ୍ତମେବ ଚ।\nଇନ୍ଦ୍ରିୟାଣି ଦଶୈକଂ(ଞ୍) ଚ, ପଞ୍ଚ ଚେନ୍ଦ୍ରିୟଗୋଚରାଃ। ॥13.5॥",

    "ଇଚ୍ଛା ଦ୍ୱେଷଃ(ସ୍) ସୁଖଂ(ନ୍) ଦୁଃଖଂ(ମ୍), ସଙ୍ଘାତଶ୍ଚେତନା ଧୃତିଃ।\nଏତତ୍କ୍ଷେତ୍ରଂ(ମ୍) ସମାସେନ, ସବିକାରମୁଦାହୃତମ୍। ॥13.6॥",

    "ଅମାନିତ୍ୱମଦମ୍ଭିତ୍ୱମ୍, ଅହିଂସା କ୍ଷାନ୍ତିରାର୍ଜବମ୍।\nଆଚାର୍ୟୋପାସନଂ(ମ୍) ଶୌଚଂ(ମ୍), ସ୍ଥୈର୍ୟମାତ୍ମବିନିଗ୍ରହଃ। ॥13.7॥",

    "ଇନ୍ଦ୍ରିୟାର୍ଥେଷୁ ବୈରାଗ୍ୟମ୍, ଅନହଙ୍କାର ଏବ ଚ।\nଜନ୍ମମୃତ୍ୟୁଜରାବ୍ୟାଧି, ଦୁଃଖଦୋଷାନୁଦର୍ଶନମ୍। ॥13.8॥",

    "ଅସକ୍ତିରନଭିଷ୍ୱଙ୍ଗଃ(ଫ୍), ପୁତ୍ରଦାରଗୃହାଦିଷୁ।\nନିତ୍ୟଂ(ଞ୍) ଚ ସମଚିତ୍ତତ୍ୱମ୍, ଇଷ୍ଟାନିଷ୍ଟୋପପତ୍ତିଷୁ। ॥13.9॥",

    "ମୟି ଚାନନ୍ୟୟୋଗେନ, ଭକ୍ତିରବ୍ୟଭିଚାରିଣୀ।\nବିବିକ୍ତଦେଶସେବିତ୍ୱମ୍, ଅରତିର୍ଜନସଂସଦି। ॥13.10॥",

    "ଅଧ୍ୟାତ୍ମଜ୍ଞାନନିତ୍ୟତ୍ୱଂ(ନ୍), ତତ୍ତ୍ୱଜ୍ଞାନାର୍ଥଦର୍ଶନମ୍।\nଏତଜ୍ଜ୍ଞାନମିତି  ପ୍ରୋକ୍ତମ୍, ଅଜ୍ଞାନଂ(ମ୍) ୟଦତୋଽନ୍ୟଥା। ॥13.11॥",

    "ଜ୍ଞେୟଂ(ମ୍) ୟତ୍ତତ୍ପ୍ରବକ୍ଷ୍ୟାମି, ୟଜ୍ଜ୍ଞାତ୍ୱାମୃତମଶ୍ନୁତେ।\nଅନାଦିମତ୍ପରଂ(ମ୍) ବ୍ରହ୍ମ, ନ ସତ୍ତନ୍ନାସଦୁଚ୍ୟତେ। ॥13.12॥",

    "ସର୍ବତଃ(ଫ୍) ପାଣିପାଦଂ(ନ୍) ତତ୍, ସର୍ବତୋଽକ୍ଷିଶିରୋମୁଖମ୍।\nସର୍ବତଃ(ଶ୍) ଶ୍ରୁତିମଲ୍ଲୋକେ, ସର୍ବମାବୃତ୍ୟ  ତିଷ୍ଠତି। ॥13.13॥",

    "ସର୍ବେନ୍ଦ୍ରିୟଗୁଣାଭାସଂ(ମ୍), ସର୍ବେନ୍ଦ୍ରିୟବିବର୍ଜିତମ୍।\nଅସକ୍ତଂ(ମ୍) ସର୍ବଭୃଚ୍ଚୈବ, ନିର୍ଗୁଣଂ(ଙ୍) ଗୁଣଭୋକ୍ତୃ ଚ। ॥13.14॥",

    "ବହିରନ୍ତଶ୍ଚ ଭୂତାନାମ୍, ଅଚରଂ(ଞ୍) ଚରମେବ ଚ।\nସୂକ୍ଷ୍ମତ୍ୱାତ୍ତଦବିଜ୍ଞେୟଂ(ନ୍), ଦୂରସ୍ଥଂ(ଞ୍) ଚାନ୍ତିକେ ଚ ତତ୍। ॥13.15॥",

    "ଅବିଭକ୍ତଂ(ଞ୍) ଚ ଭୂତେଷୁ, ବିଭକ୍ତମିବ ଚ ସ୍ଥିତମ୍। \nଭୂତଭର୍ତୃ ଚ ତଜ୍ଜ୍ଞେୟଂ(ଙ୍), ଗ୍ରସିଷ୍ଣୁ ପ୍ରଭବିଷ୍ଣୁ ଚ। ॥13.16॥",

    "ଜ୍ୟୋତିଷାମପି ତଜ୍ଜ୍ୟୋତିଃ(ସ୍), ତମସଃ(ଫ୍) ପରମୁଚ୍ୟତେ।\nଜ୍ଞାନଂ(ଞ୍) ଜ୍ଞେୟଂ(ଞ୍) ଜ୍ଞାନଗମ୍ୟଂ(ମ୍), ହୃଦି ସର୍ବସ୍ୟ ବିଷ୍ଠିତମ୍। ॥13.17॥",

    "ଇତି କ୍ଷେତ୍ରଂ(ନ୍) ତଥା ଜ୍ଞାନଂ(ଞ୍), ଜ୍ଞେୟଂ(ଞ୍) ଚୋକ୍ତଂ(ମ୍) ସମାସତଃ।\nମଦ୍ଭକ୍ତ ଏତଦ୍ୱିଜ୍ଞାୟ, ମଦ୍ଭାବାୟୋପପଦ୍ୟତେ। ॥13.18॥",

    "ପ୍ରକୃତି(ମ୍) ପୁରୁଷଂ(ଞ୍) ଚୈବ, ବିଦ୍ଧ୍ୟନାଦୀ ଉଭାବପି।\nବିକାରାଂଶ୍ଚ ଗୁଣାଂଶ୍ଚୈବ, ବିଦ୍ଧି ପ୍ରକୃତିସମ୍ଭବାନ୍। ॥13.19॥",

    "କାର୍ୟକରଣକର୍ତୃତ୍ୱେ, ହେତୁଃ(ଫ୍) ପ୍ରକୃତିରୁଚ୍ୟତେ।\nପୁରୁଷଃ(ସ୍) ସୁଖଦୁଃଖାନାଂ(ମ୍), ଭୋକ୍ତୃତ୍ୱେ ହେତୁରୁଚ୍ୟତେ। ॥13.20॥",

    "ପୁରୁଷଃ(ଫ୍) ପ୍ରକୃତିସ୍ଥୋ ହି, ଭୁଙ୍କ୍ତେ ପ୍ରକୃତିଜାନ୍ଗୁଣାନ୍।\nକାରଣଂ(ଙ୍) ଗୁଣସଙ୍ଗୋଽସ୍ୟ, ସଦସଦ୍ୟୋନିଜନ୍ମସୁ। ॥13.21॥",

    "ଉପଦ୍ରଷ୍ଟାନୁମନ୍ତା ଚ, ଭର୍ତା ଭୋକ୍ତା ମହେଶ୍ୱରଃ।\nପରମାତ୍ମେତି ଚାପ୍ୟୁକ୍ତୋ, ଦେହେଽସ୍ମିନ୍ପୁରୁଷଃ(ଫ୍) ପରଃ। ॥13.22॥",

    "ୟ ଏବଂ(ମ୍) ବେତ୍ତି ପୁରୁଷଂ(ମ୍), ପ୍ରକୃତିଂ(ଞ୍) ଚ ଗୁଣୈଃ(ସ୍) ସହ। \nସର୍ବଥା ବର୍ତମାନୋଽପି, ନ ସ ଭୂୟୋଽଭିଜାୟତେ। ॥13.23॥",

    "ଧ୍ୟାନେନାତ୍ମନି ପଶ୍ୟନ୍ତି, କେଚିଦାତ୍ମାନମାତ୍ମନା।\nଅନ୍ୟେ ସାଙ୍ଖ୍ୟେନ ୟୋଗେନ, କର୍ମୟୋଗେନ ଚାପରେ। ॥13.24॥",

    "ଅନ୍ୟେ ତ୍ୱେବମଜାନନ୍ତଃ(ଶ୍), ଶ୍ରୁତ୍ୱାନ୍ୟେଭ୍ୟ ଉପାସତେ।\nତେଽପି ଚାତିତରନ୍ତ୍ୟେବ, ମୃତ୍ୟୁ(ମ୍) ଶ୍ରୁତିପରାୟଣାଃ। ॥13.25॥",

    "ୟାବତ୍ସଞ୍ଜାୟତେ କିଞ୍ଚିତ୍, ସତ୍ତ୍ୱଂ(ମ୍) ସ୍ଥାବରଜଙ୍ଗମମ୍।\nକ୍ଷେତ୍ରକ୍ଷେତ୍ରଜ୍ଞସଂୟୋଗାତ୍, ତଦ୍ୱିଦ୍ଧି ଭରତର୍ଷଭ। ॥13.26॥",

    "ସମଂ(ମ୍) ସର୍ବେଷୁ ଭୂତେଷୁ, ତିଷ୍ଠନ୍ତଂ(ମ୍) ପରମେଶ୍ୱରମ୍।\nବିନଶ୍ୟତ୍ସ୍ୱବିନଶ୍ୟନ୍ତଂ(ମ୍), ୟଃ(ଫ୍) ପଶ୍ୟତି ସ ପଶ୍ୟତି। ॥13.27॥",

    "ସମଂ(ମ୍) ପଶ୍ୟନ୍ହି ସର୍ବତ୍ର, ସମବସ୍ଥିତମୀଶ୍ୱରମ୍।\nନ ହିନସ୍ତ୍ୟାତ୍ମନାତ୍ମାନଂ(ନ୍), ତତୋ ୟାତି ପରାଂ(ଙ୍) ଗତିମ୍। ॥13.28॥",

    "ପ୍ରକୃତ୍ୟୈବ ଚ କର୍ମାଣି, କ୍ରିୟମାଣାନି ସର୍ବଶଃ।\nୟଃ(ଫ୍) ପଶ୍ୟତି ତଥାତ୍ମାନମ୍, ଅକର୍ତାରଂ(ମ୍) ସ ପଶ୍ୟତି। ॥13.29॥",

    "ୟଦା ଭୂତପୃଥଗ୍ଭାବମ୍, ଏକସ୍ଥମନୁପଶ୍ୟତି।\nତତ ଏବ ଚ ବିସ୍ତାରଂ(ମ୍), ବ୍ରହ୍ମ ସମ୍ପଦ୍ୟତେ ତଦା। ॥13.30॥",

    "ଅନାଦିତ୍ୱାନ୍ନିର୍ଗୁଣତ୍ୱାତ୍, ପରମାତ୍ମାୟମବ୍ୟୟଃ।\nଶରୀରସ୍ଥୋଽପି କୌନ୍ତେୟ, ନ କରୋତି ନ ଲିପ୍ୟତେ। ॥13.31॥",

    "ୟଥା ସର୍ବଗତଂ(ମ୍) ସୌକ୍ଷ୍ମ୍ୟାଦ୍, ଆକାଶଂ(ନ୍) ନୋପଲିପ୍ୟତେ।\nସର୍ବତ୍ରାବସ୍ଥିତୋ ଦେହେ, ତଥାତ୍ମା ନୋପଲିପ୍ୟତେ। ॥13.32॥",

    "ୟଥା ପ୍ରକାଶୟତ୍ୟେକଃ(ଖ୍), କୃତ୍ସ୍ନଂ(ମ୍) ଲୋକମିମଂ(ମ୍) ରବିଃ। \nକ୍ଷେତ୍ରଂ(ଙ୍) କ୍ଷେତ୍ରୀ ତଥା କୃତ୍ସ୍ନଂ(ମ୍), ପ୍ରକାଶୟତି ଭାରତ। ॥13.33॥",

    "କ୍ଷେତ୍ରକ୍ଷେତ୍ରଜ୍ଞୟୋରେବମ୍, ଅନ୍ତରଂ(ଞ୍) ଜ୍ଞାନଚକ୍ଷୁଷା।\nଭୂତପ୍ରକୃତିମୋକ୍ଷଂ(ଞ୍) ଚ, ୟେ ବିଦୁର୍ୟାନ୍ତି ତେ ପରମ୍। ॥13.34॥",

    "ଓଁ ତତ୍ସଦିତି ଶ୍ରୀମଦ୍ଭଗବଦ୍ଗୀତାସୁ ଉପନିଷତ୍ସୁ ବ୍ରହ୍ମବିଦ୍ୟାୟାଂ(ୟ୍ଁ) ୟୋଗଶାସ୍ତ୍ରେ \nଶ୍ରୀକୃଷ୍ଣାର୍ଜୁନସଂବାଦେ କ୍ଷେତ୍ରକ୍ଷେତ୍ରଜ୍ଞବିଭାଗୟୋଗୋ ନାମ ତ୍ରୟୋଦଶୋଽଧ୍ୟାୟଃ। ॥13॥"
  ],
  "adhyaya-14" : [
    "ଶ୍ରୀ ଭଗବାନୁବାଚ\nପରଂ(ମ୍) ଭୂୟଃ(ଫ୍) ପ୍ରବକ୍ଷାମି, ଜ୍ଞାନାନାଂ(ଞ୍) ଜ୍ଞାନମୁତ୍ତମମ୍।\nୟଜ୍ଜ୍ଞାତ୍ୱା ମୁନୟଃ(ସ୍) ସର୍ବେ, ପରାଂ(ମ୍) ସିଦ୍ଧିମିତୋ ଗତାଃ ॥14.1॥",

    "ଇଦଂ(ଞ୍) ଜ୍ଞାନମୁପାଶ୍ରିତ୍ୟ, ମମ ସାଧର୍ମ୍ୟମାଗତାଃ।\nସର୍ଗେଽପି ତୋପଜାୟନ୍ତେ, ପ୍ରଲୟେ ନ ବ୍ୟଥନ୍ତି ଚ ॥14.2॥",

    "ମମ ୟୋନିର୍ମହଦ୍ବ୍ରହ୍ମ, ତସ୍ମିନ୍ଗର୍ଭଂ(ନ୍) ଦଧାମ୍ୟହମ୍।\nସମ୍ଭବଃ(ସ୍) ସର୍ବଭୂତାନାଂ(ନ୍), ତତୋ ଭବତି ଭାରତ ॥14.3॥",

    "ସର୍ବୟୋନିଷୁ କୌନ୍ତେୟ, ମୂର୍ତ୍ତୟଃ(ସ୍) ସମ୍ଭବନ୍ତି ୟାଃ।\nତାସାଂ(ମ୍) ବ୍ରହ୍ମ ମହଦ୍ଦ୍ୟୋନିଃ(ର୍), ଅହଂ(ମ୍) ବୀଜପ୍ରଦଃ(ଫ୍) ପିତା ॥14.4॥",

    "ସତ୍ତ୍ୱଂ(ମ୍) ରଜସ୍ତମ ଇତି, ଗୁଣାଃ(ଫ୍) ପ୍ରକୃତିସମ୍ଭବାଃ।\nନିବଧ୍ନନ୍ତି ମହାବାହୋ, ଦେହେ ଦେହିନମବ୍ୟୟମ୍ ॥14.5॥",

    "ତତ୍ର ସତ୍ତ୍ୱଂ(ନ୍) ନିର୍ମଲତ୍ୱାନ୍, ପ୍ରକାଶକମନାମୟମ୍।\nସୁଖସଙ୍ଗେନ ବଧ୍ନାତି, ଜ୍ଞାନସଙ୍ଗେନ ଚାନଘ ॥14.6॥",

    "ରଜୋ ରଗାତ୍ମକଂ(ମ୍) ବିଦ୍ଧି, ତୃଷ୍ଣାସଙ୍ଗସମୁଦ୍ଭବମ୍।\nତନ୍ନିବଧ୍ନାତି କୌନ୍ତେୟ, କର୍ମସଙ୍ଗେନ ଦେହିନମ୍ ॥14.7॥",

    "ତମସ୍ତ୍ୱଜ୍ଞାନଜଂ(ମ୍) ବିଦ୍ଧି, ମୋହନଂ(ମ୍) ସର୍ବଦେହିନାମ୍।\nପ୍ରମାଦାଲସ୍ୟନିଦ୍ରାଭିଃ(ସ୍), ତନ୍ନିବଧ୍ନାତି ଭାରତ ॥14.8॥",

    "ସତ୍ତ୍ୱଂ(ମ୍) ସୁଖେ ସଞ୍ଜୟତି, ରଜଃ(ଖ୍) କର୍ମଣି ଭାରତ।\nଜ୍ଞାନମାବୃତ୍ୟ ତୁ ତମଃ(ଫ୍), ପ୍ରମାଦେ ସଞ୍ଜୟତ୍ୟୁତ ॥14.9॥",

    "ରଜସ୍ତମଶ୍ଚାଭିଭୂୟ, ସତ୍ତ୍ୱଂ(ମ୍) ଭବତି ଭାରତ।\nରଜଃ(ସ୍) ସତ୍ତ୍ୱଂ(ନ୍) ତମଶ୍ଚୈବ, ତମଃ(ସ୍) ସତ୍ତ୍ୱଂ(ମ୍) ରଜସ୍ତଥା ॥14.10॥",

    "ସର୍ବଦ୍ୱାରେଷୁ ଦେହେଽସ୍ମିନ୍, ପ୍ରକାଶ ଉପଯାୟତେ।\nଜ୍ଞାନଂ(ମ୍) ୟଦା ତଦା ବିଦ୍ୟାଦ୍, ବିବୃଦ୍ଧଂ(ମ୍) ସତ୍ତ୍ୱମିତ୍ୟୁତ ॥14.11॥",

    "ଲୋଭଃ(ଫ୍) ପ୍ରବୃତ୍ତିରାରମ୍ଭଃ(ଖ୍),  କର୍ମଣାମଶମଃ(ସ୍) ସ୍ପୃହା। \nରଜସ୍ୟେତାନି ଜାୟନ୍ତେ, ବିବୃଦ୍ଧେ ଭରତର୍ଷଭ ॥14.12॥",

    "ଅପ୍ରକାଶୋଽପ୍ରବୃତ୍ତିଶ୍ଚ, ପ୍ରମାଦୋ ମୋହ ଏବ ଚ।\nତମସ୍ୟେତାନି ଜାୟନ୍ତେ, ବିବୃଦ୍ଧେ କରୁନନ୍ଦନ ॥14.13॥",

    "ୟଦା ସତ୍ତ୍ୱେ ପ୍ରବୃଦ୍ଧେ ତୁ, ପ୍ରଲୟଂ(ମ୍) ୟାତି ଦେହଭୃତ୍।\nତଦୋତ୍ତମବିଦାଂ(ମ୍) ଲୋକାନ୍, ଅମଲାନ୍ପ୍ରତିପଦ୍ୟତେ ॥14.14॥",

    "ରଜସି ପ୍ରଲୟଂ(ଙ୍) ଗତ୍ୱା, କର୍ମସଙ୍ଗିଷୁ ଜାୟତେ।\nତଥା ପ୍ରଲୀନସ୍ତମସି, ମୂଢୟୋନିଷୁ ଜାୟତେ ॥14.15॥",

    "କର୍ମଣଃ(ସ୍) ସୁକୃତସ୍ୟାହୁଃ(ସ୍), ସାତ୍ତ୍ୱିକଂ(ନ୍) ନିର୍ମଲଂ(ମ୍) ଫଲମ୍।\nରଜସସ୍ତୁ ଫଲଂ(ନ୍) ଦୁଃଖମ୍, ଅଜ୍ଞାନଂ(ନ୍) ତମସଃ(ଫ୍) ଫଲମ୍ ॥14.16॥",

    "ସତ୍ତ୍ୱାତ୍ସଞ୍ଜାୟତେ ଜ୍ଞାନଂ(ମ୍), ରଜସୋ ଲୋଭ ଏବ ଚ।\nପ୍ରମାଦମୋହୌ ତମସୋ, ଭବତୋଽଜ୍ଞାନମେବ ଚ ॥14.17॥",

    "ଊର୍ଧ୍ୱଂ(ଙ୍) ଗଚ୍ଛନ୍ତି ସତ୍ତ୍ୱସ୍ଥା, ମଧ୍ୟେ ତିଷ୍ଠନ୍ତି ରାଜସାଃ।\nଜଘନ୍ୟଗୁଣବୃତ୍ତିସ୍ଥା, ଅଧୋ ଗଚ୍ଛନ୍ତି ତାମସାଃ ॥14.18॥",

    "ନାନ୍ୟଂ(ଙ୍) ଗୁଣେଭ୍ୟଃ(ଖ୍) କର୍ତାରଂ(ମ୍), ୟଦା ଦ୍ରଷ୍ଟାନୁପଶ୍ୟତି।\nଗୁଣେଭ୍ୟଶ୍ଚ ପରଂ(ମ୍) ବେତ୍ତି,  ମଦ୍ଭାବଂ(ମ୍) ସୋଽଧିଗଚ୍ଛତି ॥14.19॥",

    "ଗୁଣାନେତାନତୀତ୍ୟ ତ୍ରୀନ୍, ଦେହୀ ଦେହସମୁଦ୍ଭବାନ୍ ।\nଜନ୍ମମୃତ୍ୟୁଜରାଦୁଃଖୈଃ(ର୍), ବିମୁକ୍ତୋଽମୃତମଶ୍ନୁତେ ॥14.20॥",

    "ଅର୍ଜୁନ ଉବାଚ\nକୈର୍ଲିଙ୍ଗୈସ୍ତ୍ରୀନ୍ଗୁଣାନେତାନ୍, ଅତୀତୋ ଭବତି ପ୍ରଭୋ ।\nକିମାଚାରଃ(ଖ୍) କଥଂ(ଞ୍) ଚୈତାଂସ୍, ତ୍ରୀନ୍ଗୁଣାନତିବର୍ତତେ ॥14.21॥",

    "ଶ୍ରୀଭଗବାନୁବାଚ\nପ୍ରକାଶଂ(ଞ୍) ଚ ପ୍ରବୃତ୍ତିଂ(ଞ୍) ଚ, ମୋହମେବ ଚ ପାଣ୍ଡବ।\nନ ଦ୍ୱେଷ୍ଟି ସମ୍ପ୍ରବୃତ୍ତାନି, ନ ନିବୃତ୍ତାନି କାଙ୍କ୍ଷତି ॥14.22॥",

    "ଉଦାସୀନବଦାସୀନୋ, ଗୁଣୈର୍ୟୋ ନ ବିଚାଲ୍ୟତେ।\nଗୁଣା ବର୍ତ୍ତନ୍ତ ଇତ୍ୟେବ, ୟୋଽବତିଷ୍ଠତି ନେଙ୍ଗତେ ॥14.23॥",

    "ସମଦୁଃଖସୁଖଃ(ସ୍)  ସ୍ୱସ୍ଥଃ(ସ୍),  ସମଲୋଷ୍ଟାଶ୍ମକାଞ୍ଚନଃ।\nତୁଲ୍ୟପ୍ରିୟାପ୍ରିୟୋ ଧୀରଃ(ସ୍),  ତୁଲ୍ୟନିନ୍ଦାତ୍ମସଂସ୍ତୁତିଃ ॥14.24॥",

    "ମାନାପମାନୟୋସ୍ତୁଲ୍ୟଃ(ସ୍) ତୁଲ୍ୟୋ ମିତ୍ରାରିପକ୍ଷୟୋଃ।\nସର୍ବାରମ୍ଭପରିତ୍ୟାଗୀ, ଗୁଣାତୀତଃ(ସ୍) ସ ଉଚ୍ୟତେ ॥14.25॥",

    "ମାଂ(ଞ୍) ଚ ୟୋଽବ୍ୟଭିଚାରେଣ, ଭକ୍ତିୟୋଗେନ ସେବତେ।\nସ ଗୁଣାନ୍ସମତୀତ୍ୟୈତାନ୍, ବ୍ରହ୍ମଭୂୟାୟ କଲ୍ପତେ ॥14.26॥",

    "ବ୍ରହ୍ମଣୋ ହି ପ୍ରତିଷ୍ଠାହମ୍, ଅମୃତସ୍ୟାବ୍ୟୟସ୍ୟ ଚ।\nଶାଶ୍ୱତସ୍ୟ ଚ ଧର୍ମସ୍ୟ, ସୁଖସ୍ୟୈକାନ୍ତିକସ୍ୟ ଚ ॥14.27॥",

    "ଓଁ ତତ୍ସଦିତି ଶ୍ରୀମଦ୍ଭଗବଦ୍ଗୀତାସୁ ଉପନିଷତ୍ସୁ ବ୍ରହ୍ମବିଦ୍ୟାୟାଂ(ୟ୍ଁ) ୟୋଗଶାସ୍ତ୍ରେ \nଶ୍ରୀକୃଷ୍ଣାର୍ଜୁନସଂବାଦେ ଗୁଣତ୍ରୟବିଭାଗୟୋଗୋ ନାମ ଚତୁର୍ଦ୍ଦଶୋଽଧ୍ୟାୟଃ ॥14॥"
  ],
  "adhyaya-15" : [
    "ଶ୍ରୀଭଗବାନୁବାଚ \nଊର୍ଦ୍ଧ୍ୱମୂଲମଧଃ(ଶ୍) ଶାଖମ୍, ଅଶ୍ୱତ୍ଥଂ(ମ୍) ପ୍ରାହୁରବ୍ୟୟମ୍।\nଛନ୍ଦାଂସି ୟସ୍ୟ ପର୍ଣ୍ଣାନି, ୟସ୍ତଂ(ୱ୍ଁ) ବେଦ ସ ବେଦବିତ୍ ॥15.1॥",
    
    "ଅଧଶ୍ଚୋର୍ଦ୍ଧ୍ୱଂ(ମ୍) ପ୍ରସୃତାସ୍ତସ୍ୟ ଶାଖା,\nଗୁଣପ୍ରବୃଦ୍ଧା ବିଷୟପ୍ରବାଲାଃ।\nଅଧଶ୍ଚ ମୂଲାନ୍ୟନୁସନ୍ତତାନି,\nକର୍ମାନୁବନ୍ଧୀନି ମନୁଷ୍ୟଲୋକେ ॥15.2॥",
    
    "ନ ରୂପମସ୍ୟେହ ତଥୋପଲଭ୍ୟତେ,\nନାନ୍ତୋ ନ ଚାଦିର୍ନ  ଚ ସମ୍ପ୍ରତିଷ୍ଠା।\nଅଶ୍ୱତ୍ଥମେନଂ(ମ୍) ସୁବିରୂଢମୂଲମ୍,\nଅସଙ୍ଗଶସ୍ତ୍ରେଣ ଦୃଢେନ ଛିତ୍ତ୍ୱା ॥15.3॥",
     
    "ତତଃ(ଫ୍) ପଦଂ(ନ୍) ତତ୍ପରିମାର୍ଗିତବ୍ୟଂ(ୟ୍ଁ),\nୟସ୍ମିନ୍ଗତା ନ ନିବର୍ତନ୍ତି ଭୂୟଃ।\nତମେବ ଚାଦ୍ୟଂ(ମ୍) ପୁରୁଷଂ(ମ୍) ପ୍ରପଦ୍ୟେ,\nୟତଃ(ଫ୍) ପ୍ରବୃତ୍ତିଃ(ଫ୍) ପ୍ରସୃତା ପୁରାଣୀ ॥15.4॥",
    
    "ନିର୍ମାନମୋହା ଜିତସଙ୍ଗଦୋଷା,\nଅଧ୍ୟାତ୍ମନିତ୍ୟା ବିନିବୃତ୍ତକାମାଃ।\nଦ୍ୱନ୍ଦ୍ୱୈର୍ବିମୁକ୍ତାଃ(ସ୍) ସୁଖଦୁଃଖସଞ୍ଜ୍ଞୈଃ(ର୍),\nଗଚ୍ଛନ୍ତ୍ୟମୂଢାଃ(ଫ୍) ପଦମବ୍ୟୟଂ(ନ୍) ତତ୍ ॥15.5॥",
    
    "ନ ତଦ୍ଭାସୟତେ ସୂର୍ୟୋ, ନ ଶଶାଙ୍କୋ ନ ପାବକଃ।\nୟଦ୍ଗତ୍ୱା ନ ନିବର୍ତନ୍ତେ, ତଦ୍ଧାମ ପରମଂ(ମ୍) ମମ ॥15.6॥",
     
    "ମମୈବାଂଶୋ ଜୀବଲୋକେ, ଜୀବଭୂତଃ(ସ୍) ସନାତନଃ।\nମନଃ(ଷ୍) ଷଷ୍ଠାନୀନ୍ଦ୍ରିୟାଣି, ପ୍ରକୃତିସ୍ଥାନି କର୍ଷତି ॥15.7॥",
    
    "ଶରୀରଂ(ୟ୍ଁ) ୟଦବାପ୍ନୋତି, ୟଚ୍ଚାପ୍ୟୁତ୍କ୍ରାମତୀଶ୍ୱରଃ।\nଗୃହୀତ୍ୱୈତାନି ସଂୟାତି, ବାୟୁର୍ଗନ୍ଧାନିବାଶୟାତ୍ ॥15.8॥",
    
    "ଶ୍ରୋତ୍ରଂ(ଞ୍) ଚକ୍ଷୁଃ(ସ୍) ସ୍ପର୍ଶନଂ(ଞ୍) ଚ, ରସନଂ(ଙ୍) ଘ୍ରାଣମେବ ଚ।\nଅଧିଷ୍ଠାୟ ମନଶ୍ଚାୟଂ(ୱ୍ଁ), ବିଷୟାନୁପସେବତେ ॥15.9॥",
    
    "ଉତ୍କ୍ରାମନ୍ତଂ(ମ୍) ସ୍ଥିତଂ(ୱ୍ଁ) ବାପି, ଭୁଞ୍ଜାନଂ(ୱ୍ଁ) ବା ଗୁଣାନ୍ୱିତମ୍।\nବିମୂଢା ନାନୁପଶ୍ୟନ୍ତି, ପଶ୍ୟନ୍ତି ଜ୍ଞାନଚକ୍ଷୁଷଃ ॥15.10॥",
    
    "ୟତନ୍ତୋ ୟୋଗିନଶ୍ଚୈନଂ(ମ୍), ପଶ୍ୟନ୍ତ୍ୟାତ୍ମନ୍ୟବସ୍ଥିତମ୍।\nୟତନ୍ତୋଽପ୍ୟକୃତାତ୍ମାନୋ, ନୈନଂ(ମ୍) ପଶ୍ୟନ୍ତ୍ୟଚେତସଃ ॥15.11॥",
    
    "ୟଦାଦିତ୍ୟଗତଂ(ନ୍) ତେଜୋ, ଜଗଦ୍ଭାସୟତେଽଖିଲମ୍।\nୟଚ୍ଚନ୍ଦ୍ରମସି ୟଚ୍ଚାଗ୍ନୌ, ତତ୍ତେଜୋ ବିଦ୍ଧି ମାମେକମ୍ ॥15.12॥",
    
    "ଗାମାବିଶ୍ୟ ଚ ଭୂତାନି, ଧାରୟାମ୍ୟହମୋଜସା।\nପୁଷ୍ଣାମି ଚୌଷଧୀଃ(ସ୍) ସର୍ବାଃ(ସ୍), ସୋମୋ ଭୂତ୍ୱା ରସାତ୍ମକଃ ॥15.13॥",
    
    "ଅହଂ(ୱ୍ଁ) ବୈଶ୍ୱାନରୋ ଭୂତ୍ୱା, ପ୍ରାଣିନାଂ(ନ୍) ଦେହମାଶ୍ରିତଃ।\nପ୍ରାଣାପାନସମାୟୁକ୍ତଃ(ଫ୍), ପଚାମ୍ୟନ୍ନଂ(ଞ୍) ଚତୁର୍ବିଧମ୍ ॥15.14॥",
    
    "ସର୍ବସ୍ୟ ଚାହଂ(ମ୍) ହୃଦି ସନ୍ନିବିଷ୍ଟୋ,\nମତ୍ତଃ(ସ୍) ସ୍ମୃତିର୍ଜ୍ଞାନମପୋହନଂ(ଞ୍) ଚ।\nବେଦୈଶ୍ଚ ସର୍ବୈରହମେବ ବେଦ୍ୟୋ,\nବେଦାନ୍ତକୃଦ୍ବେଦବିଦେବ ଚାହମ୍ ॥15.15॥",
    
    "ଦ୍ୱାବିମୌ ପୁରୁଷୌ ଲୋକେ, କ୍ଷରଶ୍ଚାକ୍ଷର ଏବ ଚ।\nକ୍ଷରଃ(ସ୍) ସର୍ବାଣି ଭୂତାନି, କୂଟସ୍ଥୋଽକ୍ଷର ଉଚ୍ୟତେ ॥15.16॥",
    
    "ଉତ୍ତମଃ(ଫ୍) ପୁରୁଷସ୍ତ୍ୱନ୍ୟଃ(ଫ୍), ପରମାତ୍ମେତ୍ୟୁଦାହୃତଃ।\nୟୋ ଲୋକତ୍ରୟମାବିଶ୍ୟ, ବିଭର୍ତ୍ୟବ୍ୟୟ ଈଶ୍ୱରଃ ॥15.17॥",
     
    "ୟସ୍ମାତ୍କ୍ଷରମତୀତୋଽହମ୍, ଅକ୍ଷରାଦପି ଚୋତ୍ତମଃ।\nଅତୋଽସ୍ମି ଲୋକେ ବେଦେ ଚ, ପ୍ରଥିତଃ(ଫ୍) ପୁରୁଷୋତ୍ତମଃ ॥15.18॥",
    
    "ୟୋ ମାମେବମସମ୍ମୂଢୋ, ଜାନାତି ପୁରୁଷୋତ୍ତମମ୍।\nସ ସର୍ବବିଦ୍ଭଜତି ମାଂ(ମ୍), ସର୍ବଭାବେନ ଭାରତ ॥15.19॥",
    
    "ଇତି ଗୁହ୍ୟତମଂ(ମ୍) ଶାସ୍ତ୍ରଂ(ମ୍), ଇଦମୁକ୍ତଂ(ମ୍) ମୟାନଘ।\nଏତଦ୍ବୁଦ୍ଧ୍ୱା ବୁଦ୍ଧିମାନ୍ସ୍ୟାତ୍, କୃତକୃତ୍ୟଶ୍ଚ ଭାରତ ॥15.20॥",
    
    "ଓଁ ତତ୍ସଦିତି ଶ୍ରୀମଦ୍ଭଗବଦ୍ଗୀତାସୁ ଉପନିଷତ୍ସୁ ବ୍ରହ୍ମବିଦ୍ୟାୟାଂ(ୟ୍ଁ) ୟୋଗଶାସ୍ତ୍ରେ \nଶ୍ରୀକୃଷ୍ଣାର୍ଜୁନସଂବାଦେ ପୁରୁଷୋତ୍ତମୟୋଗୋ ନାମ ପଞ୍ଚଦଶୋଽଧ୍ୟାୟଃ ॥15॥"
  ],
  "adhyaya-16" : [
    "ଶ୍ରୀଭଗବାନୁବାଚ\nଅଭୟଂ(ମ୍) ସତ୍ତ୍ୱସଂଶୁଦ୍ଧିଃ(ର୍), ଜ୍ଞାନୟୋଗବ୍ୟବସ୍ଥିତିଃ \nଦାନଂ(ନ୍) ଦମଶ୍ଚ ୟଜ୍ଞଶ୍ଚ, ସ୍ୱାଧ୍ୟାୟସ୍ତପ ଆର୍ଜବମ୍ ॥16.1॥",

    "ଅହିଂସା ସତ୍ୟମକ୍ରୋଧଃ(ସ୍), ତ୍ୟାଗଃ(ଶ୍) ଶାନ୍ତିରପୈଶୁନମ୍\nଦୟା ଭୂତେଷ୍ୱଲୋଲୁପ୍ତ୍ୱଂ(ମ୍), ମାର୍ଦବଂ(ମ୍) ହ୍ରୀରଚାପଲମ୍ ॥16.2॥",

    "ତେଜଃ କ୍ଷମା ଧୃତିଃ(ଶ୍) ଶୌଚମ୍, ଅଦ୍ରୋହୋନାତିମାନିତା\nଭବନ୍ତି ସମ୍ପଦଂ(ନ୍) ଦୈବୀମ୍, ଅଭିଜାତସ୍ୟ ଭାରତ ॥16.3॥",

    "ଦମ୍ଭୋ ଦର୍ପୋଽଭିମାନଶ୍ଚ, କ୍ରୋଧଃ(ଫ୍) ପାରୁଷ୍ୟମେବଚ\nଅଜ୍ଞାନଂ(ଞ୍) ଚାଭିଜାତସ୍ୟ, ପାର୍ଥ ସମ୍ପଦମାସୁରୀମ୍ ॥16.4॥",

    "ଦୈବୀ ସମ୍ପଦ୍ବିମୋକ୍ଷାୟ, ନିବନ୍ଧାୟାସୁରୀ ମତା\nମା ଶୁଚଃ(ସ୍) ସମ୍ପଦଂ(ନ୍) ଦୈବୀମ୍, ଅଭିଜାତୋଽସି ପାଣ୍ଡବ ॥16.5॥",

    "ଦ୍ୱୌ ଭୂତସର୍ଗୌ ଲୋକେଽସ୍ମିନ୍, ଦୈବ ଆସୁର ଏବ ଚ\nଦୈବୋ ବିସ୍ତରଶଃ(ଫ୍) ପ୍ରୋକ୍ତ, ଆସୁରଂ(ମ୍) ପାର୍ଥ ମେ ଶ୍ରୁଣୁ ॥16.6॥",

    "ପ୍ରବୃତ୍ତିଂ(ଞ୍) ଚ ନିବୃତ୍ତିଂ(ଞ୍) ଚ, ଜନା ନ ବିଦୁରାସୁରାଃ\nନ ଶୌଚଂ(ନ୍) ନାପି ଚାଚାରୋ, ନ ସତ୍ୟଂ(ନ୍) ତେଷୁ  ବିଦ୍ୟତେ ॥16.7॥",

    "ଅସତ୍ୟମପ୍ରତିଷ୍ଠଂ(ନ୍) ତେ, ଜଗଦାହୁରନୀଶ୍ୱରମ୍\nଅପରସ୍ପରସମ୍ଭୁତଂ(ଙ୍), କିମନ୍ୟତ୍କାମହୈତୁକମ୍ ॥16.8॥",

    "ଏତାଂ(ନ୍) ଦୃଷ୍ଟିମବଷ୍ଟଭ୍ୟ, ନଷ୍ଟାତ୍ମାନୋଽଲ୍ପବୁଦ୍ଧୟଃ\nପ୍ରଭବନ୍ତ୍ୟୁଗ୍ରକର୍ମାଣଃ, କ୍ଷୟାୟ ଜଗତୋଽହିତାଃ ॥16.9॥",

    "କାମମାଶ୍ରିତ୍ୟ ଦୁଷ୍ପୁରଂ(ନ୍), ଦମ୍ଭମାନମଦାନ୍ୱିତାଃ\nମୋହାଦ୍ଗୃହିତ୍ୱାସଦ୍ଗ୍ରାହାନ୍, ପ୍ରବର୍ତ୍ତନ୍ତେଽଶୁଚୀବ୍ରତାଃ ॥16.10॥",

    "ଚିନ୍ତାମପରିମେୟାଂ(ଞ) ଚ, ପ୍ରଲୟାନ୍ତାମୁପାଶ୍ରିତାଃ\nକାମୋପଭୋଗପରମା, ଏତାବଦିତି ନିଶ୍ଚିତାଃ ॥16.11॥",

    "ଆଶାପାଶଶତୈର୍ବଦ୍ଧାଃ(ଖ୍), କାମକ୍ରୋଧପରାୟଣାଃ\nଈହନ୍ତେ କାମଭୋଗାର୍ଥମ୍, ଅନ୍ୟାୟେନାର୍ଥସଞ୍ଚୟାନ୍ ॥16.12॥",

    "ଇଦମଦ୍ୟ ମୟା ଲବ୍ଧମ୍, ଇମ୍(ମ୍) ପ୍ରାପ୍ସ୍ୟେ ମନୋରଥମ୍\nଇଦମସ୍ତୀଦମପି ମେ, ଭବିଷ୍ୟତି ପୁନର୍ଧନମ୍ ॥16.13॥",

    "ଅସୌ ମୟା ହତଃ(ଶ୍) ଶତୃଃ(ର୍), ହନିଷ୍ୟେ ଚାପରାନପି\nଈଶ୍ୱରୋଽହମହଂ(ମ୍) ଭୋଗୀ, ସିଦ୍ଧୋଽହଂ(ମ୍) ବଲବାନ୍ସୁଖୀ ॥16.14॥",

    "ଆଢ୍ୟୋଽଭିଜନବାନସ୍ମି, କୋଽନ୍ୟୋଽସ୍ତି ସଦୃଶୋ ମୟା\nୟକ୍ଷ୍ୟେ ଦାସ୍ୟାମି ମୋଦିଷ୍ୟ, ଇତ୍ୟଜ୍ଞାନବିମୋହିତାଃ ॥16.15॥",

    "ଅନେକଚିତ୍ତବିଭ୍ରାନ୍ତା, ମୋହଜାଲସମାବୃତାଃ\nପ୍ରସକ୍ତାଃ(ଖ୍) କାମଭୋଗେଷୁ, ପତନ୍ତି ନରକେଽଶୁଚୌ ॥16.16॥",

    "ଆତ୍ମସମ୍ଭାବିତାଃ(ସ୍) ସ୍ତବ୍ଧା, ଧନମାନମଦାନ୍ୱିତାଃ\nୟଜନ୍ତେ ନାମ ୟଜ୍ଞୈସ୍ତେ, ଦମ୍ଭେନାବିଧିପୂର୍ବକମ୍ ॥16.17॥",

    "ଅହଙ୍କାରଂ(ମ୍) ବଲଂ(ନ୍) ଦର୍ପ(ଙ୍), କାମ(ଙ୍) କ୍ରୋଧଂ(ଞ୍ଚ) ଚ ସଂଶ୍ରିତାଃ\nମାମାତ୍ମପରଦେହେଷୁ, ପ୍ରଦ୍ୱିଷନ୍ତୋଽଭ୍ୟସୂୟକାଃ ॥16.18॥",

    "ତାନହଂ(ନ୍) ଦ୍ୱିଶତଃ(ଖ୍) କ୍ରୂରାନ୍, ସଂସାରେଷୁ ନରାଧମାନ୍\nକ୍ଷିପାମ୍ୟଜସ୍ରମଶୁଭାନ୍, ଆସୁରୀଷ୍ୱେବ ୟୋନିଷୁ ॥16.19॥",

    "ଆସୁରୀ(ୟ୍ଁ) ୟୋନିମାପନ୍ନା, ମୂଢା ଜନ୍ମନି ଜନ୍ମନି। \nମାମପ୍ରାପ୍ୟୈବ କୌନ୍ତେୟ, ତତୋ ୟାନ୍ତ୍ୟଧମାଂ(ଙ୍) ଗତିମ୍ ॥16.20॥",

    "ତ୍ରିବିଧଂ(ନ୍) ନରକସ୍ୟେଦଂ(ନ୍), ଦ୍ୱାରଂ(ନ୍) ନାଶନମାତ୍ମନଃ\nକାମଃ(ଖ୍) କ୍ରୋଧସ୍ତଥା ଲୋଭ(ସ୍), ତସ୍ମାଦେତତ୍ତ୍ରୟଂ(ନ୍) ତ୍ୟଜେତ୍ ॥16.21॥",

    "ଏତୈର୍ବିମୁକ୍ତଃ(ଖ୍) କୌନ୍ତେୟ, ତମୋଦ୍ୱାରୈସ୍ତ୍ରୀଭିର୍ନରଃ\nଆଚରତ୍ୟାତ୍ମନଃ(ଶ୍) ଶ୍ରେୟଃ(ସ୍), ତତୋ ୟାତି ପରାଂ ଗତିମ୍ ॥16.22॥",

    "ୟଃ(ଶ୍) ଶାସ୍ତ୍ରବିଧିମୁତ୍ସୃଜ୍ୟ, ବର୍ତତେ କାମକାରତଃ\nନ ସ ସିଦ୍ଧିମବାପ୍ନୋତି, ନ ସୁଖଂ(ନ୍) ନ ପରାଂ(ଙ୍) ଗତିମ୍ ॥16.23॥",

    "ତସ୍ମାଚ୍ଛାସ୍ତ୍ରଂ(ମ୍) ପ୍ରମାଣଂ(ନ୍) ତେ, କାର୍ୟାକାର୍ୟବ୍ୟବସ୍ଥିତୌ\nଜ୍ଞାତ୍ୱା ଶାସ୍ତ୍ରବିଧାନୋକ୍ତଂ(ଙ୍) କର୍ମ କର୍ତୁମିହାର୍ହସି ॥16.24॥",

    "ଓଁ ତତ୍ସଦିତି ଶ୍ରୀମଦ୍ଭଗବଦ୍ଗୀତାସୁ ଉପନିଷତ୍ସୁ ବ୍ରହ୍ମବିଦ୍ୟାୟାଂ(ୟ୍ଁ) ୟୋଗଶାସ୍ତ୍ରେ \nଶ୍ରୀକୃଷ୍ଣାର୍ଜୁନସଂବାଦେ ଦୈବାସୁରସମ୍ପଦ୍ବିଭାଗୟୋଗୋ ନାମ ଷୋଡଶୋଽଧ୍ୟାୟଃ ॥16॥"
  ],
  "adhyaya-17" : [
    "ଅର୍ଜୁନ ଉବାଚ\nୟେ ଶାସ୍ତ୍ରବିଧିମୁତ୍ସୃଜ୍ୟ , ୟଜନ୍ତେ ଶ୍ରଦ୍ଧୟାନ୍ୱିତାଃ ।\nତେଷାଂ(ନ୍) ନିଷ୍ଠା ତୁ କା କୃଷ୍ଣ, ସତ୍ତ୍ୱମାହୋ ରଜସ୍ତମଃ ॥17.1॥",

    "ଶ୍ରୀ ଭଗବାନ ଉଵାଚ ତ୍ରିବିଧା ଭବତି ଶ୍ରଦ୍ଧା, ଦେହିନାଂ(ମ୍) ସା ସ୍ଵଭାଵଜା |           \nସାତ୍ତ୍ୱିକୀ ରାଜସୀ ଚୈବ, ତାମସୀ ଚେତି ତାଂ(ମ୍) ଶୃଣୁ ॥17.2॥",

    "ସତ୍ତ୍ୱାନୁରୂପା ସର୍ବସ୍ୟ, ଶ୍ରଦ୍ଧା ଭବତି ଭାରତ ।\nଶ୍ରଦ୍ଧାମୟୋଽୟଂ(ମ୍) ପୁରୁଷୋ, ୟୋ ୟଚ୍ଛ୍ରଦ୍ଧଃ(ସ୍) ସ ଏବ ସଃ ॥17.3॥",

    "ୟଜନ୍ତେ ସାତ୍ତ୍ୱିକା ଦେବାନ୍, ୟକ୍ଷରକ୍ଷାଂସି ରାଜସାଃ ।\nପ୍ରେତାନ୍ଭୂତଗଣାଂଶ୍ଚାନ୍ୟେ, ୟଜନ୍ତେ ତାମସା ଜନାଃ ॥17.4॥",

    "ଅଶାସ୍ତ୍ରବିହିତଂ(ଙ୍) ଘୋରଂ(ନ୍), ତପ୍ୟନ୍ତେ ୟେ ତପୋ ଜନାଃ ।\nଦମ୍ଭାହଙ୍କାରସଂୟୁକ୍ତାଃ(ଖ୍), କାମରାଗବଲାନ୍ୱିତାଃ ॥17.5॥",

    "କର୍ଶୟନ୍ତଃ(ଶ୍) ଶରୀରସ୍ଥଂ(ମ୍), ଭୂତଗ୍ରାମମଚେତସଃ ।\nମାଂ(ଞ୍) ଚୈବାନ୍ତଃ(ଶ୍) ଶରୀରସ୍ଥଂ(ନ୍), ତାନ୍ୱିଦ୍ଧ୍ୟାସୁରନିଶ୍ଚୟାନ୍ ॥17.6॥",

    "ଆହାରସ୍ତ୍ୱପି ସର୍ବସ୍ୟ, ତ୍ରିବିଧୋ ଭବତି ପ୍ରିୟଃ ।\nୟଜ୍ଞସ୍ତପସ୍ତଥା ଦାନଂ(ନ୍), ତେଷାଂ(ମ୍) ଭେଦମିମଂ(ମ୍) ଶୃଣୁ ॥17.7॥",

    "ଆୟୁଃ(ସ୍) ସତ୍ତ୍ୱବଲାରୋଗ୍ୟ, ସୁଖପ୍ରୀତିବିବର୍ଧନାଃ ।\nରସ୍ୟାଃ(ସ୍) ସ୍ନିଗ୍ଧାଃ(ସ୍) ସ୍ଥିରା ହୃଦ୍ୟା, ଆହାରାଃ(ସ୍) ସାତ୍ତ୍ୱିକପ୍ରିୟାଃ ॥17.8॥",

    "କଟ୍ୱମ୍ଳଲବଣାତ୍ୟୁଷ୍ଣ, ତୀକ୍ଷ୍ଣଋକ୍ଷବିଦାହୀନଃ ।\nଆହାରା ରାଜସସ୍ୟେଷ୍ଟା, ଦୁଃଖଶୋକାମୟପ୍ରଦାଃ ॥17.9॥",

    "ୟାତୟାମଂ(ଙ୍) ଗତରସଂ(ମ୍), ପୂତି ପର୍ୟୁସିତଂ(ଞ୍) ଚ ୟତ୍ ।\nଉଚ୍ଛିଷ୍ଟମପି ଚାମେଧ୍ୟଂ(ମ୍), ଭୋଜନଂ(ନ୍) ତାମସପ୍ରିୟମ୍ ॥17.10॥",

    "ଅଫଲାକାଙ୍କ୍ଷିଭିର୍ୟଜ୍ଞୋ ବିଧିଦୃଷ୍ଟୋ ୟ ଇଜ୍ୟତେ ।\nୟଷ୍ଟବ୍ୟମେବେତି ମନଃ(ସ୍), ସମାଧାୟ ସ ସାତ୍ତ୍ୱିକଃ ॥17.11॥",

    "ଅଭିସନ୍ଧାୟ ତୁ ଫଲଂ(ନ୍), ଦମ୍ଭାର୍ଥମପି ଚୈବ ୟତ୍ ।\nଇଜ୍ୟତେ ଭରତଶ୍ରେଷ୍ଠ, ତଂ(ମ୍) ୟଜ୍ଞଂ(ମ୍) ବିଦ୍ଧି ରାଜସମ୍ ॥17.12॥",

    "ବିଧିହୀନମସୃଷ୍ଟାନ୍ନଂ(ମ୍), ମନ୍ତ୍ରହୀନମଦକ୍ଷିଣମ୍ ।\nଶ୍ରଦ୍ଧାବିରହିତଂ(ମ୍) ୟଜ୍ଞଂ(ନ୍), ତାମସଂ(ମ୍) ପରିଚକ୍ଷତେ ॥17.13॥",

    "ଦେବଦ୍ୱିଜଗୁରୁପ୍ରାଜ୍ଞ, ପୂଜନଂ(ମ୍) ଶୌଚମାର୍ଜବମ୍ ।\nବ୍ରହ୍ମଚର୍ୟମହିଂସା ଚ, ଶାରୀରଂ(ନ୍) ତପ ଉଚ୍ୟତେ ॥17.14॥",

    "ଅନୁଦ୍ୱେଗକରଂ(ମ୍) ବାକ୍ୟଂ(ମ୍), ସତ୍ୟଂ(ମ୍) ପ୍ରିୟହିତଂ(ଞ୍) ଚ ୟତ୍ ।\nସ୍ୱାଧ୍ୟାୟାଭ୍ୟସନଂ(ଞ୍) ଚୈବ, ବାଙ୍ମୟଂ(ନ୍) ତପ ଉଚ୍ୟତେ ॥17.15॥",

    "ମନଃ(ଫ୍) ପ୍ରସାଦଃ(ସ୍) ସୌମ୍ୟତ୍ୱଂ(ମ୍), ମୌନମାତ୍ମବିନିଗ୍ରହଃ ।\nଭାବସଂଶୁଦ୍ଧିରିତ୍ୟେତତ୍, ତପୋ ମାନସମୁଚ୍ୟତେ ॥17.16॥",

    "ଶ୍ରଦ୍ଧୟା ପରୟା ତପ୍ତଂ(ନ୍), ତପସ୍ତତ୍ତ୍ରିବିଧଂ(ନ୍) ନରୈଃ ।\nଅଫଲାକାଙ୍କ୍ଷିଭିର୍ୟୁକ୍ତୈଃ(ସ୍), ସାତ୍ତ୍ୱିକ(ମ୍) ପରିଚକ୍ଷତେ ॥17.17॥",

    "ସତ୍କାରମାନପୂଜାର୍ଥଂ(ନ୍), ତପୋ ଦମ୍ଭେନ ଚୈବ ୟତ୍ ।\nକ୍ରିୟତେ ତଦିହ ପ୍ରୋକ୍ତ(ମ୍), ରାଜସଂ(ଞ୍) ଚଲମଧ୍ରୁବମ୍ ॥17.18॥",

    "ମୁଢଗ୍ରାହେଣାତ୍ମନୋ ୟତ୍, ପୀଡୟା କ୍ରିୟତେ ତପଃ ।\nପରସ୍ୟୋତ୍ସାଦନାର୍ଥଂ(ମ୍) ବା, ତତ୍ତାମସମୁଦାହୃତମ୍ ॥17.19॥",

    "ଦାତବ୍ୟମିତି ୟଦ୍ଦାନଂ(ନ୍), ଦୀୟତେଽନୁପକାରିଣେ ।\nଦେଶେ କାଲେ ଚ ପାତ୍ରେ ଚ, ତଦ୍ଦାନଂ(ମ୍) ସାତ୍ତ୍ୱିକଂ(ମ୍) ସ୍ମୃତମ୍ ॥17.20॥",

    "ୟତ୍ତୁ ପ୍ରତ୍ୟୁପକାରାର୍ଥଂ(ମ୍), ଫଲମୁଦ୍ଦିଶ୍ୟ ବା ପୁନଃ ।\nଦୀୟତେ ଚ ପରିକ୍ଲିଷ୍ଟଂ(ନ୍), ତଦ୍ଦାନଂ(ମ୍) ରାଜସଂ(ମ୍) ସ୍ମୃତମ୍ ॥17.21॥",

    "ଅଦେଶକାଲେ ୟଦ୍ଦାନମ୍, ଅପାତ୍ରେଭ୍ୟଶ୍ଚ ଦୀୟତେ ।\nଅସତ୍କୃତମବଜ୍ଞାତଂ(ନ୍), ତତ୍ତାମସମୁଦାହୃତମ୍ ॥17.22॥",

    "ଓଁ ତତ୍ସଦିତି ନିର୍ଦ୍ଦେଶୋ, ବ୍ରହ୍ମଣସ୍ତ୍ରିବିଧଃ(ସ୍) ସ୍ମୃତଃ ।\nବ୍ରାହ୍ମଣାସ୍ତେନ ବେଦାଶ୍ଚ, ୟଜ୍ଞାଶ୍ଚ ବିହିତାଃ(ଫ୍) ପୁରା ॥17.23॥",

    "ତସ୍ମାଦୋମିତ୍ୟୁଦାହୃତ୍ୟ, ୟଜ୍ଞଦାନତପଃ(ଖ୍) କ୍ରିୟାଃ ।\nପ୍ରବର୍ତ୍ତନ୍ତେ ବିଧାନୋକ୍ତାଃ(ସ୍), ସତତଂ(ମ୍) ବ୍ରହ୍ମବାଦିନାମ୍ ॥17.24॥",

    "ତଦିତ୍ୟନଭିସନ୍ଧାୟ, ଫଲଂ(ୟ୍ଁ) ୟଜ୍ଞତପଃ(ଖ୍) କ୍ରିୟାଃ ।\nଦାନକ୍ରିୟାଶ୍ଚ ବିବିଧାଃ(ଖ୍), କ୍ରିୟନ୍ତେ ମୋକ୍ଷକାଙ୍କ୍ଷିଭିଃ ॥17.25॥",

    "ସଦ୍ଭାବେ ସାଧୁଭାବେ ଚ, ସଦିତ୍ୟେତତ୍ପ୍ରୟୁଜ୍ୟତେ ।\nପ୍ରଶସ୍ତେ କର୍ମଣି ତଥା, ସଚ୍ଛବ୍ଦଃ(ଫ୍) ପାର୍ଥ ୟୁଜ୍ୟତେ ॥17.26॥",

    "ୟଜ୍ଞେ ତପସି ଦାନେ ଚ, ସ୍ଥିତିଃ(ସ୍) ସଦିତି ଚୋଚ୍ୟତେ ।\nକର୍ମ ଚୈବ ତଦର୍ଥୀୟଂ(ମ୍), ସଦିତ୍ୟେବାଭିଧୀୟତେ ॥17.27॥",

    "ଅଶ୍ରଦ୍ଧୟା ହୁତଂ(ନ୍) ଦତ୍ତଂ(ନ୍), ତପସ୍ତପ୍ତଂ(ଙ୍) କୃତଂ(ଞ୍) ଚ ୟତ୍ ।\nଅସଦିତ୍ୟୁଚ୍ୟତେ ପାର୍ଥ, ନ ଚ ତତ୍ପ୍ରେତ୍ୟ ନୋ ଇହ ॥17.28॥",

    "ଓଁ ତତ୍ସଦିତି ଶ୍ରୀମଦ୍ଭଗବଦ୍ଗୀତାସୁ ଉପନିଷତ୍ସୁ ବ୍ରହ୍ମବିଦ୍ୟାୟାଂ(ୟ୍ଁ) ୟୋଗଶାସ୍ତ୍ରେ \nଶ୍ରୀକୃଷ୍ଣାର୍ଜୁନସଂବାଦେ ଶ୍ରଦ୍ଧାତ୍ରୟବିଭାଗୟୋଗୋ ନାମ ସପ୍ତଦଶୋଽଧ୍ୟାୟଃ ॥17॥"
  ],
  "adhyaya-18" : [
    "ଅର୍ଜୁନ ଉବାଚ\nସନ୍ୟାସସ୍ୟ ମହାବାହୋ, ତତ୍ତ୍ୱମିଚ୍ଛାମି ବେଦିତୁମ୍\nତ୍ୟାଗସ୍ୟ ଚ ହୃଷୀକେଶ, ପୃଥକ୍କେଶିନିଷୂଦନ ॥18.1॥",

    "ଶ୍ରୀ ଭଗବାନୁବାଚ\nକାମ୍ୟାନାଂ(ଙ୍) କର୍ମଣାଂ(ନ୍), ନ୍ୟାସଂ(ମ୍), ସନ୍ୟାସଂ(ଙ୍) କବୟୋ  ବିଦୁଃ\nସର୍ବକର୍ମଫଲତ୍ୟାଗଂ(ମ୍), ପ୍ରାହୁସ୍ତ୍ୟାଗଂ(ମ୍) ବିଚକ୍ଷଣାଃ ॥18.2॥",

    "ତ୍ୟାଜ୍ୟଂ(ନ୍) ଦୋଷବଦିତ୍ୟେକେ, କର୍ମ  ପ୍ରାହୁର୍ମନୀଷିଣଃ\nୟଜ୍ଞଦାନତପଃକର୍ମ, ନ ତ୍ୟାଜ୍ୟମିତି ଚାପରେ ॥18.3॥",

    "ନିଶ୍ଚୟଂ(ମ୍) ଶ୍ରୁଣୁ ମେ ତତ୍ର, ତ୍ୟାଗେ ଭରତସତ୍ତମ\nତ୍ୟାଗୋ ହି ପୁରୁଷବ୍ୟାଘ୍ର, ତ୍ରିବିଧଃ(ସ୍) ସମ୍ପ୍ରକୀର୍ତିତଃ ॥18.4॥",

    "ୟଜ୍ଞଦାନତପଃକର୍ମ, ନ ତ୍ୟାଜ୍ୟଂ(ଙ୍) କାର୍ୟମେବ ତତ୍\nୟଜ୍ଞୋ ଦାନଂ(ନ୍) ତପଶ୍ଚୈବ, ପାବନାନି ମନୀଷିଣାମ୍ ॥18.5॥",

    "ଏତାନ୍ୟପି ତୁ କର୍ମାଣି, ସଙ୍ଗଂ(ନ୍) ତ୍ୟକ୍ତ୍ୱା ଫଲାନି ଚ \nକର୍ତ୍ତବ୍ୟାନୀତି ମେ ପାର୍ଥ, ନିଶ୍ଚିତଂ(ମ୍) ମତମୁତ୍ତମମ୍ ॥18.6॥",

    "ନିୟତସ୍ୟ ତୁ ସନ୍ୟାସଃ(ଖ୍), କର୍ମଣୋ ନୋପପଦ୍ୟତେ\nମୋହାତ୍ତସ୍ୟ ପରିତ୍ୟାଗଃ(ସ୍), ତାମସଃ(ଫ୍) ପରିକୀର୍ତିତଃ ॥18.7॥",

    "ଦୁଃଖମିତ୍ୟେବ ୟତ୍କର୍ମ, କାୟକ୍ଲେଶଭୟାତ୍ତ୍ୟଜେତ୍\nସ କୃତ୍ୱା ରାଜସଂ(ନ୍) ତ୍ୟାଗଂ(ନ୍), ନୈବ ତ୍ୟାଗଫଲମ୍ ଲଭେତ୍ ॥18.8॥",

    "କାର୍ୟମିତ୍ୟେବ ୟତ୍କର୍ମ, ନିୟତଂ(ଙ୍) କ୍ରିୟତେଽର୍ଜୁନ\nସଙ୍ଗଂ(ନ୍) ତ୍ୟକ୍ତ୍ୱା ଫଲଂ(ଞ) ଚୈବ, ସ ତ୍ୟାଗଃ(ସ୍) ସାତ୍ତ୍ୱିକୋ ମତଃ ॥18.9॥",

    "ନ ଦ୍ୱେଷ୍ଟ୍ୟକୁଶଲଂ(ଙ୍) କର୍ମ, କୁଶଲେ ନାନୁଷଜ୍ଜତେ\nତ୍ୟାଗୀ ସତ୍ତ୍ୱସମାବିଷ୍ଟୋ, ମେଧାବୀ ଛିନ୍ନସଂଶୟଃ ॥18.10॥",

    "ନ ହି ଦେହଭୃତା ଶକ୍ୟଂ(ନ୍), ତ୍ୟକ୍ତୁଂ(ଙ୍) କର୍ମାଣ୍ୟଶେଷତଃ\nୟସ୍ତୁ କର୍ମଫଲତ୍ୟାଗୀ, ସ ତ୍ୟାଗୀତ୍ୟଭିଧୀୟତେ ॥18.11॥",

    "ଅନିଷ୍ଟମିଷ୍ଟଂ(ମ୍) ମିଶ୍ରଂ(ଞ) ଚ, ତ୍ରିବିଧଂ(ଙ୍) କର୍ମଣଃ(ଫ) ଫଲମ୍\nଭବତ୍ୟତ୍ୟାଗିନାଂ(ମ୍) ପ୍ରେତ୍ୟ, ନ ତୁ ସନ୍ୟାସୀନାଂ(ଙ୍) କ୍ୱଚିତ୍ ॥18.12॥",

    "ପଞ୍ଚୈତାନି ମହାବାହୋ, କାରଣାନି ନିବୋଧ ମେ\nସାଙ୍ଖ୍ୟେ କୃତାନ୍ତେ ପ୍ରୋକ୍ତାନି, ସିଦ୍ଧୟେ ସର୍ବକର୍ମଣାମ୍ ॥18.13॥",

    "ଅଧିଷ୍ଠାନଂ(ନ୍) ତଥା କର୍ତା, କରଣଂ(ଞ) ଚ ପୃଥଗ୍ବିଧମ୍\nବିବିଧାଶ୍ଚ ପୃଥକ୍ଚେଷ୍ଟା , ଦୈବଂ(ଞ) ଚୈବାତ୍ର ପଞ୍ଚମମ୍ ॥18.14॥",

    "ଶରୀରବାଙ୍ମନୋଭିର୍ୟତ୍, କର୍ମ ପ୍ରାରଭତେ ନରଃ\nନ୍ୟାୟ୍ୟଂ(ମ୍) ବା ବିପରୀତଂ(ମ୍) ବା, ପଞ୍ଚୈତେ ତସ୍ୟ ହେତବଃ ॥18.15॥",

    "ତତ୍ରୈବଂ(ମ୍) ସତୀ କର୍ତାରମ୍, ଆତ୍ମାନଂ(ଙ୍) କେବଲଂ(ନ୍) ତୁ ୟଃ \nପଶ୍ୟତ୍ୟକୃତବୁଦ୍ଧିତ୍ୱାନ୍, ନ ସ ପଶ୍ୟତି ଦୁର୍ମତିଃ ॥18.16॥",

    "ୟସ୍ୟ ନାହଙ୍କୃତୋ ଭାବୋ, ବୁଦ୍ଧିର୍ୟସ୍ୟ ନ ଲିପ୍ୟତେ \nହତ୍ୱାପି ସ ଇମାଁଲ୍ଲୋକାନ୍, ଇ ହନ୍ତି ନ ନିବଧ୍ୟତେ ॥18.17॥",

    "ଜ୍ଞାନଂ(ଞ) ଜ୍ଞେୟଂ(ମ୍) ପରିଜ୍ଞାତା, ତ୍ରିବିଧା କର୍ମଚୋଦନା\nକାରଣଂ(ଙ୍) କର୍ମ କର୍ତେତି, ତ୍ରିବିଧଃ(ଖ୍) କର୍ମସଙ୍ଗ୍ରହଃ ॥18.18॥",

    "ଜ୍ଞାନଂ(ଙ୍) କର୍ମ ଚ କର୍ତା ଚ, ତ୍ରିଧୈବ ଗୁଣଭେଦତଃ \nପ୍ରୋଚ୍ୟତେ ଗୁଣସଙ୍ଖ୍ୟାନେ, ୟଥାବଚ୍ଛୃଣୁ ତାନ୍ୟପି ॥18.19॥",

    "ସର୍ବଭୂତେଷୁ ୟେନୈକଂ(ମ୍), ଭାବମବ୍ୟୟମୀକ୍ଷତେ \nଅବିଭକ୍ତଂ(ମ୍) ବିଭକ୍ତେଷୁ, ତଜ୍ଜ୍ଞାନଂ(ମ୍) ବିଦ୍ଧି ସାତ୍ତ୍ୱିକମ୍ ॥18.20॥",

    "ପୃଥକ୍ତ୍ୱେନ ତୁ ୟଜ୍ଜ୍ଞାନଂ(ନ୍), ନାନାଭାବାନ୍ପୃଥଗ୍ବିଧାନ୍\nବେତ୍ତି ସର୍ବେଷୁ ଭୂତେଷୁ, ତଜ୍ଜ୍ଞାନଂ(ମ୍) ବିଦ୍ଧି ରାଜସମ୍ ॥18.21॥",

    "ୟତ୍ତୁ କୃତ୍ସ୍ନବଦେକସ୍ମିନ୍, କାର୍ୟେ ସକ୍ତମହୈତୁକମ୍\nଅତତ୍ତ୍ୱାର୍ଥବଦଲ୍ପଂ(ଞ) ଚ, ତତ୍ତାମସମୁଦାହୃତମ୍ ॥18.22॥",

    "ନିୟତଂ(ମ୍) ସଙ୍ଗରହିତମ୍, ଅରାଗଦ୍ୱେଷତଃ(ଖ୍) କୃତମ୍\nଅଫଲପ୍ରେପ୍ସୁନା କର୍ମ, ୟତ୍ତତ୍ସାତ୍ତ୍ୱିକମୁଚ୍ୟତେ ॥18.23॥",

    "ୟତ୍ତୁ କାମେପ୍ସୁନା କର୍ମ, ସାହଙ୍କାରେଣ ବା ପୁନଃ\nକ୍ରିୟତେ ବହୁଲାୟାସଂ(ନ୍), ତଦ୍ରାଜସମୁଦାହୃତମ୍ ॥18.24॥",

    "ଅନୁବନ୍ଧଂ(ଙ୍) କ୍ଷୟଂ(ମ୍) ହିଂସାମ୍, ଅନବେକ୍ଷ୍ୟ ଚ ପୌରୁଷମ୍\nମୋହାଦାରଭ୍ୟତେ କର୍ମ, ୟତ୍ତତ୍ତାମସମୁଚ୍ୟତେ ॥18.25॥",

    "ମୁକ୍ତସଙ୍ଗୋଽନହଂବାଦୀ, ଧୃତ୍ୟୁତ୍ସାହସମନ୍ୱିତଃ\nସିଦ୍ଧ୍ୟସିଦ୍ଧ୍ୟୋର୍ନିର୍ବିକାରଃ(ଖ୍), କର୍ତା ସାତ୍ତ୍ୱିକ ଉଚ୍ୟତେ ॥18.26॥",

    "ରାଗୀ କର୍ମଫଲପ୍ରେପ୍ସୁଃ(ର୍), ଲୁବ୍ଧୋ ହିଂସାତ୍ମକୋଽଶୁଚିଃ \nହର୍ଷଶୋକାନ୍ୱିତଃ(ଖ୍) କର୍ତା, ରାଜସଃ(ଫ୍) ପରିକୀର୍ତିତଃ ॥18.27॥",

    "ଅୟୁକ୍ତଃ(ଫ୍) ପ୍ରାକୃତଃ(ସ୍) ସ୍ତବ୍ଧଃ(ଶ୍), ଶଠୋ ନୈଷ୍କୃତିକୋଽଲସଃ\nବିଷାଦୀ ଦୀର୍ଘସୂତ୍ରୀ ଚ, କର୍ତା ତାମସ ଉଚ୍ୟତେ ॥18.28॥",

    "ବୁଦ୍ଧେର୍ଭେଦଂ(ନ୍) ଧୃତେଶ୍ଚୈବ, ଗୁଣତସ୍ତ୍ରିବିଧଂ(ମ୍) ଶୃଣୁ \nପ୍ରୋଚ୍ୟମାନମଶେଷେଣ, ପୃଥକ୍ତ୍ୱେନ ଧନଞ୍ଜୟ ॥18.29॥",

    "ପ୍ରବୃତ୍ତିଂ(ଞ) ଚ ନିବୃତ୍ତିଂ(ଞ) ଚ, କାର୍ୟାକାର୍ୟେ ଭୟାଭୟେ \nବନ୍ଧଂ(ମ୍) ମୋକ୍ଷଂ(ଞ) ଚ ୟା ବେତ୍ତି, ବୁଦ୍ଧିଃ(ସ୍) ସା ପାର୍ଥ ସାତ୍ତ୍ୱିକୀ ॥18.30॥",

    "ୟଯା ଧର୍ମମଧର୍ମଂ(ଞ) ଚ, କାର୍ୟଂ(ଞ) ଚାକାର୍ୟମେବ ଚ\nଅୟଥାବତ୍ପ୍ରଜାନାତି, ବୁଦ୍ଧିଃ(ସ୍) ସା ପାର୍ଥ ରାଜସୀ ॥18.31॥",

    "ଅଧର୍ମଂ(ନ୍) ଧର୍ମମିତି ୟା, ମନ୍ୟତେ ତମସାବୃତା\nସର୍ବାର୍ଥାନ୍ୱିପରୀତାଂଶ୍ଚ, ବୁଦ୍ଧିଃ(ସ୍) ସା ପାର୍ଥ ତାମସୀ ॥18.32॥",

    "ଘୃତ୍ୟା ୟଯା ଧାରୟତେ, ମନଃପ୍ରାଣେନ୍ଦ୍ରିୟକ୍ରିୟାଃ\nୟୋଗେନାବ୍ୟଭିଚାରିଣ୍ୟା, ଧୃତିଃ(ସ୍) ସା ପାର୍ଥ ସାତ୍ତ୍ୱିକୀ ॥18.33॥",

    "ୟଯା ତୁ ଧର୍ମକାମାର୍ଥାନ୍, ଧୃତ୍ୟା ଧାରୟତେଽର୍ଜୁନ\nପ୍ରସଙ୍ଗେନ ଫଲାକାଙ୍କ୍ଷୀ, ଧୃତିଃ(ସ୍) ସା ପାର୍ଥ ରାଜସୀ ॥18.34॥",

    "ୟଯା ସ୍ୱପ୍ନଂ(ମ୍) ଭୟଂ(ମ୍) ଶୋକଂ(ମ୍), ବିଷାଦଂ(ମ୍) ମଦମେବ ଚ\nନ ବିମୁଞ୍ଚତି ଦୁର୍ମେଧା, ଧୃତିଃ(ସ୍) ସା ପାର୍ଥ ତାମସୀ ॥18.35॥",

    "ସୁଖଂ(ନ୍) ତ୍ୱିଦାନୀଂ(ନ୍) ତ୍ରିବିଧଂ(ମ୍), ଶୃଣୁ ମେ ଭରତର୍ଷଭ \nଅଭ୍ୟାସାଦ୍ରମତେ ୟତ୍ର, ଦୁଃଖାନ୍ତଂ(ଞ) ଚ ନିଗଚ୍ଛତି ॥18.36॥",

    "ୟତ୍ତଦଗ୍ରେ ବିଷମିବ, ପରିଣାମେଽମୃତୋପମମ୍\nତତ୍ସୁଖଂ(ମ୍) ସାତ୍ତ୍ୱିକଂ(ମ୍) ପ୍ରୋକ୍ତମ୍, ଆତ୍ମବୁଦ୍ଧିପ୍ରସାଦଜମ୍ ॥18.37॥",

    "ବିଷୟେନ୍ଦ୍ରିୟସଂୟୋଗାଦ୍, ୟତ୍ତଦଗ୍ରେଽମୃତୋପମମ୍\nପରିଣାମେ ବିଷମିବ, ତତ୍ସୁଖଂ(ମ୍) ରାଜସଂ(ମ୍) ସ୍ମୃତମ୍ ॥18.38॥",

    "ୟଦଗ୍ରେ ଚାନୁବନ୍ଧେ ଚ, ସୁଖଂ(ମ୍) ମୋହନମାତ୍ମନଃ \nନିଦ୍ରାଲସ୍ୟପ୍ରମାଦୋତ୍ଥଂ(ନ୍), ତତ୍ତାମସମୁଦାହୃତମ୍ ॥18.39॥",

    "ନ ତଦସ୍ତି ପୃଥିବ୍ୟାଂ(ମ୍) ବା, ଦିବି ଦେବେଷୁ ବା ପୁନଃ\nସତ୍ତ୍ୱଂ(ମ୍) ପ୍ରକୃତିଜୈର୍ମୁକ୍ତଂ(ମ୍) ୟଦେଭିଃ(ସ୍) ସ୍ୟାତ୍ତ୍ରିଭିର୍ଗୁଣୈଃ ॥18.40॥",

    "ବ୍ରାହ୍ମଣକ୍ଷତ୍ରୀୟବିଶାଂ(ମ୍), ଶୂଦ୍ରାଣାଂ(ଞ) ଚ ପରନ୍ତପ\nକର୍ମାଣି ପ୍ରବିଭକ୍ତାନି, ସ୍ୱଭାବପ୍ରଭବୈର୍ଗୁଣୈଃ ॥18.41॥",

    "ଶମୋ ଦମସ୍ତପଃ(ସ୍) ଶୌଚଂ(ଙ୍), କ୍ଷାନ୍ତିରାର୍ଜବମେବ ଚ \nଜ୍ଞାନଂ(ମ୍) ବିଜ୍ଞାନମାସ୍ତିକ୍ୟଂ(ମ୍), ବ୍ରହ୍ମକର୍ମ ସ୍ୱଭାବଜମ୍ ॥18.42॥",

    "ଶୌର୍ୟଂ(ନ୍) ତେଜୋ ଧୃତିର୍ଦାକ୍ଷ୍ୟଂ(ମ୍), ୟୁଦ୍ଧେ ଚାପ୍ୟପଲାୟନମ୍\nଦାନମୀଶ୍ୱରଭାବଶ୍ଚ , କ୍ଷାତ୍ରଂ(ଙ୍) କର୍ମ ସ୍ୱଭାବଜମ୍ ॥18.43॥",

    "କୃଷିଗୌରକ୍ଷ୍ୟବାଣିଜ୍ୟଂ(ମ୍), ବୈଶ୍ୟକର୍ମ ସ୍ୱଭାବଜମ୍ \nପରିଚର୍ୟାତ୍ମକଂ(ଙ୍) କର୍ମ,ଶୂଦ୍ରସ୍ୟାପି ସ୍ୱଭାବଜମ୍ ॥18.44॥",

    "ସ୍ୱେ ସ୍ୱେ କର୍ମଣ୍ୟଭିରତଃ(ସ୍), ସଂସିଦ୍ଧିଂ(ମ୍) ଲଭତେ ନରଃ\nସ୍ୱକର୍ମନିରତଃ(ସ୍) ସିଦ୍ଧିଂ(ମ୍), ୟଥା ବିନ୍ଦତି ତଚ୍ଛୃଣୁ ॥18.45॥",

    "ୟତଃ(ଫ୍) ପ୍ରବୃତ୍ତିର୍ଭୂତାନାଂ(ମ୍), ୟେନ ସର୍ବମିଦଂ(ନ୍) ତତମ୍\nସ୍ୱକର୍ମଣା ତମଭ୍ୟର୍ଚ୍ୟ, ସିଦ୍ଧିଂ(ମ୍) ବିନ୍ଦତି ମାନବଃ ॥18.46॥",

    "ଶ୍ରେୟାନ୍ସ୍ୱଧର୍ମୋ ବିଗୁଣଃ(ଫ୍), ପରଧର୍ମାତ୍ସ୍ୱନୁଷ୍ଠିତାତ୍\nସ୍ୱଭାବନିୟତଂ(ଙ୍) କର୍ମ, କୁର୍ବନ୍ ନାପ୍ନୋତି କିଲ୍ବିଷମ୍ ॥18.47॥",

    "ସହଜଂ(ଙ୍) କର୍ମ କୌନ୍ତେୟ, ସଦୋଷମପି ନ ତ୍ୟଜେତ୍\nସର୍ବାରମ୍ଭା ହି ଦୋଷେଣ, ଘୂମେନାଗ୍ନିରିବାବୃତାଃ ॥18.48॥",

    "ଅସକ୍ତବୁଦ୍ଧିଃ(ସ୍) ସର୍ବତ୍ର, ଜିତାତ୍ମା ବିଗତସ୍ପୃହଃ\nନୈଷ୍କର୍ମ୍ୟସିଦ୍ଧିଂ(ମ୍) ପରମାଂ(ମ୍), ସନ୍ୟାସେନାଧିଗଚ୍ଛତି ॥18.49॥",

    "ସିଦ୍ଧିଂ(ମ୍) ପ୍ରାପ୍ତୋ ୟଥା ବ୍ରହ୍ମ, ତଥାପ୍ନୋତି ନିବୋଧ ମେ\nସମାସେନୈବ କୌନ୍ତେୟ, ନିଷ୍ଠା ଜ୍ଞାନସ୍ୟ ୟା ପରା ॥18.50॥",

    "ବୁଦ୍ଧ୍ୟା ବିଶୁଦ୍ଧୟା ୟୁକ୍ତୋ, ଧୃତ୍ୟାତ୍ମାନଂ(ନ୍) ନିୟମ୍ୟ ଚ \nଶବ୍ଦାଦୀନ୍ୱିଷୟାଂସ୍ତ୍ୟକ୍ତ୍ୱା, ରାଗଦ୍ୱେଷୌ ବ୍ୟୁଦସ୍ୟ ଚ ॥18.51॥",

    "ବିବିକ୍ତସେବୀ ଲଧ୍ୱାଶୀ, ୟତବାକ୍କାୟମାନସଃ\nଧ୍ୟାନୟୋଗପରୋ ନିତ୍ୟଂ(ମ୍), ବୈରାଗ୍ୟଂ(ମ୍) ସମୁପାଶ୍ରିତଃ ॥18.52॥",

    "ଅହଙ୍କାରଂ(ମ୍) ବଲଂ(ନ୍) ଦର୍ପଂ(ଙ୍), କାମଂ(ଙ୍) କ୍ରୋଧଂ(ମ୍) ପରିଗ୍ରହମ୍\nବିମୁଚ୍ୟ ନିର୍ମମଃ(ଶ୍) ଶାନ୍ତୋ, ବ୍ରହ୍ମଭୂୟାୟ କଲ୍ପତେ ॥18.53॥",

    "ବ୍ରହ୍ମଭୂତଃ(ଫ୍) ପ୍ରସନ୍ନାତ୍ମା, ନ ଶୋଚତି ନ କାଙ୍କ୍ଷତି \nସମଃ(ସ୍) ସର୍ବେଷୁ ଭୂତେଷୁ, ମଦ୍ଭକ୍ତିଂ(ମ୍) ଲଭତେ ପରାମ୍ ॥18.54॥",

    "ଭକ୍ତ୍ୟା ମାମଭିଜାନାତି, ୟାବାନ୍ୟଶ୍ଚାସ୍ମି ତତ୍ତ୍ୱତଃ\nତତୋ ମାଂ(ନ୍) ତତ୍ତ୍ୱତୋ ଜ୍ଞାତ୍ୱା, ବିଶତେ ତଦନନ୍ତରମ୍ ॥18.55॥",

    "ସର୍ବକର୍ମାଣ୍ୟପି ସଦା, କୁର୍ବାଣୋ ମଦ୍ବ୍ୟପାଶ୍ରୟଃ\nମତ୍ପ୍ରସାଦାଦବାପ୍ନୋତି, ଶାଶ୍ୱତଂ(ମ୍) ପଦମବ୍ୟୟମ୍ ॥18.56॥",

    "ଚେତସା ସର୍ବକର୍ମାଣି, ମୟି ସନ୍ନ୍ୟସ୍ୟ ମତ୍ପରଃ \nବୁଦ୍ଧିୟୋଗମୁପାଶ୍ରିତ୍ୟ, ମଚ୍ଚିତ୍ତଃ(ସ୍) ସତତଂ(ମ୍) ଭବ ॥18.57॥",

    "ମଚ୍ଚିତ୍ତଃ(ସ୍) ସର୍ବଦୁର୍ଗାଣି, ମତ୍ପ୍ରସାଦାତ୍ତରିଷ୍ୟସି\nଅଥ ଚେତ୍ତ୍ୱମହଙ୍କାରାନ୍, ନ ଶ୍ରୋଷ୍ୟସି ବିନଙ୍କ୍ଷ୍ୟସି ॥18.58॥",

    "ୟଦହଙ୍କାରମାଶ୍ରିତ୍ୟ, ନ ୟୋତ୍ସ୍ୟ ଇତି ମନ୍ୟସେ\nମିଥ୍ୟୈଷ ବ୍ୟବସାୟସ୍ତେ, ପ୍ରକୃତିସ୍ତ୍ୱାଂ(ନ୍) ନିୟୋକ୍ଷ୍ୟତି ॥18.59॥",

    "ସ୍ୱଭାବଜେନ କୌନ୍ତେୟ, ନିବଦ୍ଧଃ(ସ୍) ସ୍ୱେନ କର୍ମଣା\nକର୍ତୁଂ(ନ୍) ନେଚ୍ଛସି ୟନ୍ମୋହାତ୍, କରିଷ୍ୟସ୍ୟବଶୋଽପି ତତ୍  ॥18.60॥",

    "ଈଶ୍ୱରଃ(ସ୍) ସର୍ବଭୂତାନାଂ(ମ୍), ହୃଦ୍ଦେଶେଽର୍ଜୁନ ତିଷ୍ଠତି\nଭ୍ରାମୟନ୍ସର୍ବଭୂତାନି, ୟନ୍ତ୍ରାରୁଢାନି ମାୟୟା ॥18.61॥",

    "ତମେବ ଶରଣଂ(ଙ୍) ଗଚ୍ଛ, ସର୍ବଭାବେନ ଭାରତ \nତତ୍ପ୍ରସାଦାତ୍ପରାଂ(ମ୍) ଶାନ୍ତିଂ(ମ୍), ସ୍ଥାନଂ(ମ୍) ପ୍ରାପ୍ସ୍ୟସି ଶାଶ୍ୱତମ୍ ॥18.62॥",

    "ଇତି ତେ ଜ୍ଞାନମାଖ୍ୟାତଂ(ଙ୍), ଗୁହ୍ୟାଦ୍ଗୁହ୍ୟତରଂ(ମ୍) ମୟା\nବିମୃଶ୍ୟୈତଦଶେଷେଣ, ୟଥେଚ୍ଛସି ତଥା କୁରୁ ॥18.63॥",

    "ସର୍ବଗୁହ୍ୟତମଂ(ମ୍) ଭୂୟଃ(ସ୍), ଶୃଣୁ ମେ ପରମଂ(ମ୍) ବଚଃ \nଇଷ୍ଟୋଽସି ମେ ଦୃଢମିତି, ତତୋ ବକ୍ଷ୍ୟାମି ତେ ହିତମ୍ ॥18.64॥",

    "ମନ୍ମନା ଭବ ମଦ୍ଭକ୍ତୋ, ମଦ୍ୟାଜୀ ମାଂ(ନ୍) ନମସ୍କୁରୁ\nମାମେବୈଷ୍ୟସି ସତ୍ୟଂ(ନ୍) ତେ, ପ୍ରତିଜାନେ ପ୍ରିୟୋଽସି ମେ ॥18.65॥",

    "ସର୍ବଧର୍ମାନ୍ପରିତ୍ୟଜ୍ୟ, ମାମେକଂ(ମ୍) ଶରଣଂ(ମ୍) ବ୍ରଜ\nଅହଂ(ନ୍) ତ୍ୱା ସର୍ବପାପେଭ୍ୟୋ, ମୋକ୍ଷୟିଷ୍ୟାମି ମା ଶୁଚଃ ॥18.66॥",

    "ଇଦଂ(ନ୍) ତେ ନାତପସ୍କାୟ, ନାଭକ୍ତାୟ କଦାଚନ \nନା ଚାଶୁଶ୍ରୂଷବେ ବାଚ୍ୟଂ(ନ୍), ନ ଚ ମାଂ(ମ୍) ୟୋଽଭ୍ୟସୂୟତି ॥18.67॥",

    "ୟ ଇମଂ(ମ୍) ପରମଂ(ଙ୍) ଗୁହ୍ୟଂ(ମ୍), ମଦ୍ଭକ୍ତେଷ୍ୱଭିଧାସ୍ୟତି\nଭକ୍ତିଂ(ମ୍) ମୟି ପରାଂ(ଙ୍) କୃତ୍ୱା, ମାମେବୈଷତ୍ୟସଂଶୟଃ ॥18.68॥",

    "ନ ଚ ତସ୍ମାନ୍ମନୁଷ୍ୟେଷୁ, କଶ୍ଚିନ୍ମେ ପ୍ରିୟକୃତ୍ତମଃ\nଭବିତା ନ ଚ ମେ ତସ୍ମାଦ୍, ଅନ୍ୟଃ(ଫ୍) ପ୍ରିୟତରୋ ଭୁବି ॥18.69॥",

    "ଅଧ୍ୟେଷ୍ୟତେ ଚ ୟ ଇମଂ(ନ୍), ଧର୍ମ୍ୟଂ(ମ୍) ସଂବାଦମାବୟୋଃ \nଜ୍ଞାନୟଜ୍ଞେନ ତେନାହମ୍, ଇଷ୍ଟଃ(ସ୍) ସ୍ୟାମିତି ମେ ମତିଃ ॥18.70॥",

    "ଶ୍ରଦ୍ଧାବାନନସୂୟଶ୍ଚ, ଶୃଣୁୟାଦପି ୟୋ ନରଃ \nସୋଽପି ମୁକ୍ତଃ(ଶ୍) ଶୁଭାଁଲ୍ଲୋକାନ୍, ପ୍ରାପ୍ନୁୟାତ୍ପ୍ୟୁଣ୍ୟକର୍ମଣାମ୍ ॥18.71॥",

    "କଚ୍ଚିଦେତଚ୍ଛ୍ରୁତଂ(ମ୍) ପାର୍ଥ, ତ୍ୱୟୈକାଗ୍ରେଣ ଚେତସା\nକଚ୍ଚିଦଜ୍ଞାନସମ୍ମୋହଃ(ଫ୍), ପ୍ରନଷ୍ଟସ୍ତେ ଧନଞ୍ଜୟ ॥18.72॥",

    "ଅର୍ଜୁନ ଉବାଚ\nନଷ୍ଟୋ ମୋହଃ(ସ୍) ସ୍ମୃତିର୍ଲବ୍ଧା, ତ୍ୱତ୍ପ୍ରସାଦାନ୍ମୟାଚ୍ୟୁତ \nସ୍ଥିତୋଽସ୍ମି ଗତସନ୍ଦେହଃ(ଖ୍), କରିଷ୍ୟେ ବଚନଂ(ମ୍) ତବ ॥18.73॥",

    "ସଞ୍ଜୟ ଉବାଚ\nଇତ୍ୟହଂ(ମ୍) ବାସ୍ତୁଦେବସ୍ୟ, ପାର୍ଥସ୍ୟ ଚ ମହାତ୍ମନଃ \nସଂବାଦମିମମଶ୍ରୌଷମ୍, ଅଦ୍ଭୁତଂ(ମ୍) ରୋମହର୍ଷଣମ୍ ॥18.74॥",

    "ବ୍ୟାସପ୍ରସାଦାଚ୍ଛ୍ରୁତବାନ୍, ଏତଦ୍ଗୁହ୍ୟମହଂ(ମ୍) ପରମ୍\nୟୋଗଂ(ମ୍) ୟୋଗେଶ୍ୱରାତ୍କୃଷ୍ଣାତ୍, ସାକ୍ଷାତ୍କଥୟତଃ(ସ୍) ସ୍ୱୟମ୍ ॥18.75॥",

    "ରାଜନ୍ସଂସ୍ମୃତ୍ୟ ସଂସ୍ମୃତ୍ୟ, ସଂବାଦମିମମଦ୍ଭୁତମ୍ \nକେଶବାର୍ଜୁନୟୋଃ(ଫ୍) ପୁଣ୍ୟଂ(ମ୍), ହୃଷ୍ୟାମି ଚ ମୁହୁର୍ମୁହୁଃ ॥18.76॥",

    "ତଚ୍ଚ ସଂସ୍ମୃତ୍ୟ ସଂସ୍ମୃତ୍ୟ, ରୂପମତ୍ୟଦ୍ଭୁତଂ(ମ୍) ହରେଃ\nବିସ୍ମୟୋ ମେ ମହାନ୍ ରାଜନ୍, ହୃଷ୍ୟାମି ଚ ପୁନଃ(ଫ୍) ପୁନଃ ॥18.77॥",

    "ୟତ୍ର ୟୋଗେଶ୍ୱରଃ(ଖ୍) କୃଷ୍ଣୋ, ୟତ୍ର ପାର୍ଥୋ ଧନୁର୍ଧରଃ \nତତ୍ରୋ ଶ୍ରୀର୍ବିଜୟୋ ଭୂତିଃ(ର୍), ଧ୍ରୁବା ନୀତିର୍ମତିର୍ମମ ॥18.78॥",

    "ଓଁ ତତ୍ସଦିତି ଶ୍ରୀମଦ୍ଭଗବଦ୍ଗୀତାସୁ ଉପନିଷତ୍ସୁ ବ୍ରହ୍ମବିଦ୍ୟାୟାଂ(ୟ୍ଁ) ୟୋଗଶାସ୍ତ୍ରେ \nଶ୍ରୀକୃଷ୍ଣାର୍ଜୁନସଂବାଦେ ମୋକ୍ଷସନ୍ନ୍ୟାସୟୋଗୋ ନାମ ଅଷ୍ଟାଦଶୋଽଧ୍ୟାୟଃ ॥18॥"
  ]
},
{
  "adhyaya-1" : [
    "ଧୃତରାଷ୍ଟ୍ର ପଚାରିଲେ- ହେ ସଞ୍ଜୟ ! ଧର୍ମଭୂମି କୁରୁକ୍ଷେତ୍ରରେ ଯୁଦ୍ଧ କରିବା ଇଚ୍ଛାରେ ଏକତ୍ରିତ ହୋଇଥିବା ମୋର ଓ ପାଣ୍ଡୁଙ୍କର ପୁତ୍ର ମାନେ କ'ଣ କଲେ ? 1.1",

    "ସଞ୍ଜୟ କହିଲେ- ଯୁଦ୍ଧାରମ୍ଭ ସମୟରେ ରାଜା ଦୁର୍ଯ୍ୟୋଧନ ବ୍ୟୂହରଚନାଯୁକ୍ତ ପାଣ୍ଡବ ସେନାକୁ ଦେଖି, ଦ୍ରୋଣାଚାର୍ଯ୍ୟଙ୍କ ପାଖକୁ ଯାଇ କହିଲେ, 1.2",

    "ହେ ଆଚାର୍ଯ୍ୟ! ଆପଣଙ୍କ ବୁଦ୍ଧିମାନ ଶିଷ୍ୟ ଦ୍ରୁପଦପୁତ୍ର ଧ୍ରୁଷ୍ଟଦ୍ୟୁମ୍ନ ଦ୍ୱାରା ବ୍ୟୂହାକାରରେ ସଜ୍ଜିତ ପାଣ୍ଡୁ ପୁତ୍ର ମାନଙ୍କର ଏହି ବିରାଟ ସେନା କୁ ଦେଖନ୍ତୁ । 1.3",

    "ଏଠାରେ ସେନାରେ ମହା ମହା ଧନୁର୍ଦ୍ଧର ତଥା ଯୁଦ୍ଧରେ ଭୀମ ଓ ଅର୍ଜୁନ ଙ୍କ ସମାନ ଶୂରବୀର ସାତ୍ୟକି, ବିରାଟ ତଥା ମହାରଥୀ ରାଜା ଦ୍ରୁପଦ, ଧ୍ରୁଷ୍ଟକେତୁ ଓ ଚେକିତାନ ତଥା ବଳବାନ୍ କାଶୀରାଜ, ପୁରୁଜିତ୍ କୁନ୍ତୀଭୋଜ ଓ ମନୁଷ୍ୟ ମାନଙ୍କ ମଧ୍ୟରେ ଶ୍ରେଷ୍ଠ ଶୈବ୍ୟ, ପରାକ୍ରମୀ ଯୁଧାମନ୍ୟୁ ତଥା ବଳବାନ୍ ଉତ୍ତମୌଜା, ସୁଭଦ୍ରାପୁତ୍ର ଅଭିମନ୍ୟୁ ଏବଂ ଦ୍ରୌପଦୀଙ୍କ ପାଞ୍ଚପୁତ୍ର ଅଛନ୍ତି;ଏମାନେ ସମସ୍ତେ ମହାରଥୀ । 1.4",

    " ଏଠାରେ ସେନାରେ ମହା ମହା ଧନୁର୍ଦ୍ଧର ତଥା ଯୁଦ୍ଧରେ ଭୀମ ଓ ଅର୍ଜୁନ ଙ୍କ ସମାନ ଶୂରବୀର ସାତ୍ୟକି, ବିରାଟ ତଥା ମହାରଥୀ ରାଜା ଦ୍ରୁପଦ, ଧ୍ରୁଷ୍ଟକେତୁ ଓ ଚେକିତାନ ତଥା ବଳବାନ୍ କାଶୀରାଜ, ପୁରୁଜିତ୍ କୁନ୍ତୀଭୋଜ ଓ ମନୁଷ୍ୟ ମାନଙ୍କ ମଧ୍ୟରେ ଶ୍ରେଷ୍ଠ ଶୈବ୍ୟ, ପରାକ୍ରମୀ ଯୁଧାମନ୍ୟୁ ତଥା ବଳବାନ୍ ଉତ୍ତମୌଜା, ସୁଭଦ୍ରାପୁତ୍ର ଅଭିମନ୍ୟୁ ଏବଂ ଦ୍ରୌପଦୀଙ୍କ ପାଞ୍ଚପୁତ୍ର ଅଛନ୍ତି;ଏମାନେ ସମସ୍ତେ ମହାରଥୀ । 1.5",

    "ଏଠାରେ ସେନାରେ ମହା ମହା ଧନୁର୍ଦ୍ଧର ତଥା ଯୁଦ୍ଧରେ ଭୀମ ଓ ଅର୍ଜୁନ ଙ୍କ ସମାନ ଶୂରବୀର ସାତ୍ୟକି, ବିରାଟ ତଥା ମହାରଥୀ ରାଜା ଦ୍ରୁପଦ, ଧ୍ରୁଷ୍ଟକେତୁ ଓ ଚେକିତାନ ତଥା ବଳବାନ୍ କାଶୀରାଜ, ପୁରୁଜିତ୍ କୁନ୍ତୀଭୋଜ ଓ ମନୁଷ୍ୟ ମାନଙ୍କ ମଧ୍ୟରେ ଶ୍ରେଷ୍ଠ ଶୈବ୍ୟ, ପରାକ୍ରମୀ ଯୁଧାମନ୍ୟୁ ତଥା ବଳବାନ୍ ଉତ୍ତମୌଜା, ସୁଭଦ୍ରାପୁତ୍ର ଅଭିମନ୍ୟୁ ଏବଂ ଦ୍ରୌପଦୀଙ୍କ ପାଞ୍ଚପୁତ୍ର ଅଛନ୍ତି;ଏମାନେ ସମସ୍ତେ ମହାରଥୀ । 1.6",

    "ହେ ବ୍ରାହ୍ମଣଶ୍ରେଷ୍ଠ ! ଆମ ପକ୍ଷରେ ବି ଯେଉଁ ପ୍ରଧାନ ଯୋଦ୍ଧା ମାନେ ଅଛନ୍ତି, ସେମାନଙ୍କୁ ଆପଣ ଜାଣିନିଅନ୍ତୁ । ମୋ ସେନାରେ ଯେଉଁ ସେନାପତିମାନେ ଅଛନ୍ତି, ଆପଣଙ୍କ ଜାଣିବା ନିମନ୍ତେ, ସେମାନଙ୍କ ନାମ କହୁଛି । 1.7",

    "ଆପଣ (ଦ୍ରୋଣାଚାର୍ଯ୍ୟ), ପିତାମହ ଭୀଷ୍ମ, କର୍ଣ୍ଣ ଏବଂ ସଂଗ୍ରାମବିଜୟୀ କୃପାଚାର୍ଯ୍ୟ ତଥା ସେହିପରି ଅଶ୍ଵତ୍ଥାମା, ବିକର୍ଣ୍ଣ ଓ ସୋମଦତ୍ତଙ୍କ ପୁତ୍ର ଭୂରିଶ୍ରବା ମଧ୍ୟ ଅଛନ୍ତି । 1.8",

    "ଏମାନଙ୍କ ବ୍ୟତୀତ ଆହୁରି ମଧ୍ୟ ଅନେକ ବୀର ଯୋଦ୍ଧା ଅଛନ୍ତି, ଯେଉଁମାନେ ମୋ ପାଇଁ ନିଜ ଜୀବନର ଆଶା ମଧ୍ୟ ଛାଡି ଦେଇଛନ୍ତି , ଏବଂ ସେମାନେ ସମସ୍ତେ ଅନେକ ପ୍ରକାର ଅସ୍ତ୍ରଶସ୍ତ୍ର ଚାଳନାରେ ତଥା ଯୁଦ୍ଧ କଳାରେ ଅତ୍ୟନ୍ତ ଚତୁର । 1.9",

    "ଭୀଷ୍ମ ଙ୍କ ଦ୍ଵାରା ରକ୍ଷିତ ଆମ୍ଭମାନଙ୍କର ସେନା ଅପର୍ଯ୍ୟାପ୍ତ ବା ଅସମର୍ଥ ଅର୍ଥାତ୍ ସବୁ ଦୃଷ୍ଟିରୁ ଅଜେୟ ଏବଂ ଭୀମଙ୍କ ଦ୍ୱାରା ରକ୍ଷିତ ଏମାନଙ୍କର ଏହି ସେନା ପର୍ଯ୍ୟାପ୍ତ ବା ସମର୍ଥ  ଅର୍ଥାତ୍ ଏହାକୁ ଜୟ କରିବା ଅତି ସହଜ। 1.10",

    "ଦୁର୍ଯ୍ୟୋଧନ ବାହ୍ୟ ମନରେ ନିଜ ସେନାର ମହାରଥିମାନଙ୍କୁ କହିଲା- ଆପଣମାନେ ସମସ୍ତେ ବ୍ୟୂହର ସମସ୍ତ ପ୍ରବେଶ ଦ୍ବାରରେ ନିଜ ନିଜ ସ୍ଥାନରେ ଅବିଚଳିତରୂପେ ରହି ପିତାମହ ଭୀଷ୍ମଙ୍କୁ ଚତୁର୍ଦିଗରୁ ରକ୍ଷା କରନ୍ତୁ । 1.11",

    "କୁରୁବୃଦ୍ଧ ମହାପ୍ରତାପୀ ପିତାମହ ଭୀଷ୍ମ ଦୁର୍ଯ୍ୟୋଧନ ହୃଦୟରେ ହର୍ଷ ଉତ୍ପନ୍ନ କରି ଉଚ୍ଚସ୍ୱରରେ ସିଂହନାଦ ପରି ଗର୍ଜନ କରି ଶଙ୍ଖ ବଜାଇଲେ । 1.12",

    "ତା'ପରେ ପରେ ବହୁ ଶଙ୍ଖ,ଭେରୀ (ନାଗରା), ଢୋଲ, ମୃଦଙ୍ଗ ଓ ରଣଶିଙ୍ଗା ଆଦି ବାଦ୍ୟ ଏକସଙ୍ଗେ ବାଜିଉଠିଲା । ସେସବୁ ର ଶବ୍ଦ ଅତି ଭୟଙ୍କର ହେଲା । 1.13",

    "ତା'ପରେ ଧଳା ଘୋଡ଼ାଯୁକ୍ତ ମହାନ୍ ରଥରେ ଉପରେ ବିରାଜମାନ ଲକ୍ଷ୍ମୀପତି ଭଗବାନ ଶ୍ରୀକୃଷ୍ଣ ଓ ପାଣ୍ଡୁପୁତ୍ର ଅର୍ଜୁନ ମଧ୍ୟ ଉଚ୍ଚସ୍ବରରେ ଦିବ୍ୟ ଶଙ୍ଖ ବାଦନ କଲେ । 1.14",

    "ଅନ୍ତର୍ଯ୍ୟାମୀ ଶ୍ରୀକୃଷ୍ଣ ପାଞ୍ଚଜନ୍ୟ ନାମକ ଶଙ୍ଖ ତଥା ଧନଞ୍ଜୟ ଅର୍ଜୁନ ଦେବଦତ୍ତ ନାମକ ଶଙ୍ଖ ବଜାଇଲେ ଏବଂ ଭୟାନକ କର୍ମସାଧନକାରୀ ବୃକୋଦର ଭୀମ ପୌଣ୍ଡ୍ର ନାମକ ମହାଶଙ୍ଖ ବଜାଇଲେ । 1.15",

    "କୁନ୍ତୀପୁତ୍ର ରାଜା ଯୁଧିଷ୍ଠିର ଅନନ୍ତବିଜୟ ନାମକ ଏବଂ ନକୁଳ ଓ ସହଦେବ ଯଥାକ୍ରମେ ସୁଘୋଷ ଓ ମଣିପୁଷ୍ପକ ନାମକ ଶଙ୍ଖ ବଜାଇଲେ । 1.16",

    "ଶ୍ରେଷ୍ଠ ଧନୁର୍ଦ୍ଧର କାଶୀରାଜ, ମହାରଥୀ ଶିଖଣ୍ଡୀ, ଧୃଷ୍ଟଦ୍ୟୁମ୍ନ, ରାଜା ବିରାଟ, ଅଜେୟ ସାତ୍ୟକି, ରାଜା ଦ୍ରୁପଦ, ଦ୍ରୌପଦୀଙ୍କ ପାଞ୍ଚପୁତ୍ର ଏବଂ ଦୀର୍ଘ ବାହୁବିଶିଷ୍ଟ ସୁଭଦ୍ରାପୁତ୍ର  ଅଭିମନ୍ୟୁ- ଏ ସମସ୍ତେ ସବୁ ଦିଗରୁ ଭିନ୍ନ ଭିନ୍ନ (ନିଜ ନିଜର) ଶଙ୍ଖ ବଜାଇଲେ । 1.17",

    "ଶ୍ରେଷ୍ଠ ଧନୁର୍ଦ୍ଧର କାଶୀରାଜ, ମହାରଥୀ ଶିଖଣ୍ଡୀ, ଧୃଷ୍ଟଦ୍ୟୁମ୍ନ, ରାଜା ବିରାଟ, ଅଜେୟ ସାତ୍ୟକି, ରାଜା ଦ୍ରୁପଦ, ଦ୍ରୌପଦୀଙ୍କ ପାଞ୍ଚପୁତ୍ର ଏବଂ ଦୀର୍ଘ ବାହୁବିଶିଷ୍ଟ ସୁଭଦ୍ରାପୁତ୍ର  ଅଭିମନ୍ୟୁ- ଏ ସମସ୍ତେ ସବୁ ଦିଗରୁ ଭିନ୍ନ ଭିନ୍ନ (ନିଜ ନିଜର) ଶଙ୍ଖ ବଜାଇଲେ ।  1.18",

    "ପାଣ୍ଡବସେନାର ସେହି ଭୟଙ୍କର ଶଙ୍ଖଧ୍ବନି ଆକାଶ ଓ ପୃଥିବୀକୁ କମ୍ପମାନ କରି ଅନ୍ୟାୟପୂର୍ବକ ରାଜ୍ୟ ହରଣକାରୀ ଦୁର୍ଯ୍ୟୋଧନାଦି କୌରବମାନଙ୍କର ହୃଦୟକୁ ବିଦୀର୍ଣ୍ଣ କରିଦେଲା । 1.19",

    "ହେ ମହୀପତି ଧୃତରାଷ୍ଟ୍ର ! ଯେତେବେଳେ ଶସ୍ତ୍ର ଚାଳନା ନିମନ୍ତେ ପ୍ରସ୍ତୁତି ଚାଲିଥିଲା, ସେତେବେଳେ ଅନ୍ୟାୟପୂର୍ବକ ରାଜ୍ୟ ଅଧିକାର କରିଥିବା ରାଜାମାନଙ୍କୁ ଓ ସେମାନଙ୍କର ସାଥୀମାନଙ୍କୁ ସମ୍ମୁଖରେ ବ୍ୟବସ୍ଥିତରୂପେ ଦଣ୍ଡାୟମାନ ଦେଖି କପଧ୍ବଜ ରଥରେ ବସିଥିବା ପାଣ୍ଡୁପୁତ୍ର ଅର୍ଜୁନ ଗାଣ୍ଡୀବ ଧନୁ ଉତ୍ତୋଳନକରି ଅନ୍ତର୍ଯ୍ୟାମୀ ଶ୍ରୀକୃଷ୍ଣଙ୍କୁ ଏହି ବଚନ କହିଲେ । 1.20",

    "ଅର୍ଜୁନ କହିଲେ- ହେ ଅଚ୍ୟୁତ ! ଏହି ଯୁଦ୍ଧରୂପକ ଉଦ୍ଯୋଗରେ ଯୁଦ୍ଧ କରିବାକୁ ପ୍ରସ୍ତୁତ ହୋଇ ରହିଥିବା ଯୁଦ୍ଧାଭିଳାଷୀମାନଙ୍କ ମଧ୍ୟରୁ କେଉଁମାନଙ୍କ ସହିତ ମୋତେ ଯୁଦ୍ଧ କରିବାକୁ ହେବ, ଯେପର୍ଯ୍ୟନ୍ତ ତାହା ମୁଁ ଦେଖି ନ ସାରିଛି, ସେପର୍ଯ୍ୟନ୍ତ ଆପଣ ଦୁଇସେନାଙ୍କ ମଧ୍ୟଭାଗରେ ମୋର ରଥ ରଖନ୍ତୁ ।” 1.21 -1.22",

    "ଅର୍ଜୁନ କହିଲେ- ହେ ଅଚ୍ୟୁତ ! ଏହି ଯୁଦ୍ଧରୂପକ ଉଦ୍ଯୋଗରେ ଯୁଦ୍ଧ କରିବାକୁ ପ୍ରସ୍ତୁତ ହୋଇ ରହିଥିବା ଯୁଦ୍ଧାଭିଳାଷୀମାନଙ୍କ ମଧ୍ୟରୁ କେଉଁମାନଙ୍କ ସହିତ ମୋତେ ଯୁଦ୍ଧ କରିବାକୁ ହେବ, ଯେପର୍ଯ୍ୟନ୍ତ ତାହା ମୁଁ ଦେଖି ନ ସାରିଛି, ସେପର୍ଯ୍ୟନ୍ତ ଆପଣ ଦୁଇସେନାଙ୍କ ମଧ୍ୟଭାଗରେ ମୋର ରଥ ରଖନ୍ତୁ ।” 1.21 -1.22",

    "ଦୁଷ୍ଟବୁଦ୍ଧି ଦୁର୍ଯ୍ୟୋଧନର ଯୁଦ୍ଧରେ ହିତାକାଂକ୍ଷୀ ହୋଇ ଯେଉଁ ରାଜାମାନେ ଏହି ସେନାରେ ଆସିଛନ୍ତି, ସେହି ଯୋଦ୍ଧା ମାନଙ୍କୁ ମୁଁ ଟିକିଏ ଭଲ କରି ଦେଖିବି । 1.23",

    "ସଞ୍ଜୟ କହିଲେ- ହେ ଧୃତରାଷ୍ଟ୍ର ! ନିଦ୍ରାଜୟୀ ଅର୍ଜୁନ ଏପରି କହିବାରୁ, ଅନ୍ତର୍ଯ୍ୟାମୀ ଶ୍ରୀକୃଷ୍ଣ ଦୁଇସେନାଙ୍କ ମଧ୍ୟଭାଗରେ ପିତାମହ ଭୀଷ୍ମ ଓ ଦ୍ରୋଣାଚାର୍ଯ୍ୟଙ୍କ ସମ୍ମୁଖରେ ତଥା ସମସ୍ତ ରାଜାଙ୍କ ସମ୍ମୁଖରେ ଶ୍ରେଷ୍ଠ ରଥକୁ ଠିଆ କରିଦେଇ କହିଲେ- 'ହେ ପାର୍ଥ, ଯୁଦ୍ଧ ପାଇଁ ଏକତ୍ରିତ ହୋଇଥିବା ଏହି କୌରବମାନଙ୍କୁ ଦେଖ ।’ 1.24 - 1.25",

    "ସଞ୍ଜୟ କହିଲେ- ହେ ଧୃତରାଷ୍ଟ୍ର ! ନିଦ୍ରାଜୟୀ ଅର୍ଜୁନ ଏପରି କହିବାରୁ, ଅନ୍ତର୍ଯ୍ୟାମୀ ଶ୍ରୀକୃଷ୍ଣ ଦୁଇସେନାଙ୍କ ମଧ୍ୟଭାଗରେ ପିତାମହ ଭୀଷ୍ମ ଓ ଦ୍ରୋଣାଚାର୍ଯ୍ୟଙ୍କ ସମ୍ମୁଖରେ ତଥା ସମସ୍ତ ରାଜାଙ୍କ ସମ୍ମୁଖରେ ଶ୍ରେଷ୍ଠ ରଥକୁ ଠିଆ କରିଦେଇ କହିଲେ- 'ହେ ପାର୍ଥ, ଯୁଦ୍ଧ ପାଇଁ ଏକତ୍ରିତ ହୋଇଥିବା ଏହି କୌରବମାନଙ୍କୁ ଦେଖ ।’ 1.24 - 1.25",

    "ତା'ପରେ ପୃଥାପୁତ୍ର ଅର୍ଜୁନ ଉଭୟ ସେନାରେ ଉପସ୍ଥିତ ପିତୃ-ପିତାମହ ମାନଙ୍କୁ ତଥା ଗୁରୁ, ମାମୁଁ, ଭାଇ, ପୁତ୍ର, ପୌତ୍ର, ବନ୍ଧୁ, ଶ୍ୱଶୁର ଓ ସୁହୃଦମାନଙ୍କୁ ମଧ୍ୟ ଦେଖିବାକୁ ପାଇଲେ । 1.26",

    "ନିଜ ନିଜ ସ୍ଥାନରେ ଉପସ୍ଥିତ ସମସ୍ତ ବାନ୍ଧବଂକୁ ଦେଖି କୁନ୍ତୀପୁତ୍ର ଅର୍ଜୁନ ଅତ୍ୟନ୍ତ କାତରଭାବରେ ବିଷାଦମଗ୍ନ ହୋଇ ଏହି ବଚନ କହିଲେ । 1.27",

    "ଅର୍ଜୁନ କହିଲେ- ହେ କୃଷ୍ଣ ! ଏହି ଯୁଦ୍ଧେଚ୍ଛୁ କୁଟୁମ୍ବମାନଙ୍କୁ ସମ୍ମୁଖରେ ଉପସ୍ଥିତ ଦେଖି ମୋର ସର୍ବାଙ୍ଗ ଶୀଥିଳ ହୋଇଯାଉଛି, ପାଟି ଶୁଖିଯାଉଛି, ଶରୀରରେ କମ୍ପ ଆସୁଛି ଓ ରୋମ ଟାଙ୍କୁରି ଉଠୁଛି । ହାତରୁ ଗାଣ୍ଡୀବ ଧନୁ ଖସି ପଡୁଛି ଏବଂ ଚର୍ମ ମଧ୍ୟ ଦହ୍ୟମାନ ହେଉଛି । ମୋ ମନ ଭ୍ରମିତ ହେଲାପରି ଲାଗୁଛି, ଏବଂ ଏବେ ମୁଁ ଠିଆ ହୋଇ ରହିବାକୁ ମଧ୍ୟ ଅସମର୍ଥ ହୋଇ ପଡୁଛି । 1.28 -1.29",

    "ଅର୍ଜୁନ କହିଲେ- ହେ କୃଷ୍ଣ ! ଏହି ଯୁଦ୍ଧେଚ୍ଛୁ କୁଟୁମ୍ବମାନଙ୍କୁ ସମ୍ମୁଖରେ ଉପସ୍ଥିତ ଦେଖି ମୋର ସର୍ବାଙ୍ଗ ଶୀଥିଳ ହୋଇଯାଉଛି, ପାଟି ଶୁଖିଯାଉଛି, ଶରୀରରେ କମ୍ପ ଆସୁଛି ଓ ରୋମ ଟାଙ୍କୁରି ଉଠୁଛି । ହାତରୁ ଗାଣ୍ଡୀବ ଧନୁ ଖସି ପଡୁଛି ଏବଂ ଚର୍ମ ମଧ୍ୟ ଦହ୍ୟମାନ ହେଉଛି । ମୋ ମନ ଭ୍ରମିତ ହେଲାପରି ଲାଗୁଛି, ଏବଂ ଏବେ ମୁଁ ଠିଆ ହୋଇ ରହିବାକୁ ମଧ୍ୟ ଅସମର୍ଥ ହୋଇ ପଡୁଛି । 1.28 -1.29",

    "ଅର୍ଜୁନ କହିଲେ- ହେ କୃଷ୍ଣ ! ଏହି ଯୁଦ୍ଧେଚ୍ଛୁ କୁଟୁମ୍ବମାନଙ୍କୁ ସମ୍ମୁଖରେ ଉପସ୍ଥିତ ଦେଖି ମୋର ସର୍ବାଙ୍ଗ ଶୀଥିଳ ହୋଇଯାଉଛି, ପାଟି ଶୁଖିଯାଉଛି, ଶରୀରରେ କମ୍ପ ଆସୁଛି ଓ ରୋମ ଟାଙ୍କୁରି ଉଠୁଛି । ହାତରୁ ଗାଣ୍ଡୀବ ଧନୁ ଖସି ପଡୁଛି ଏବଂ ଚର୍ମ ମଧ୍ୟ ଦହ୍ୟମାନ ହେଉଛି । ମୋ ମନ ଭ୍ରମିତ ହେଲାପରି ଲାଗୁଛି, ଏବଂ ଏବେ ମୁଁ ଠିଆ ହୋଇ ରହିବାକୁ ମଧ୍ୟ ଅସମର୍ଥ ହୋଇ ପଡୁଛି । 1.30",

    "ହେ କେଶବ ! ମୁଁ ସବୁ ବିପରୀତ ଲକ୍ଷଣମାନ ହିଁ ଦେଖିବାକୁ ପାଉଛି। ଯୁଦ୍ଧରେ ସ୍ୱଜନ ସମୁଦାୟଙ୍କୁ ମାରି ଯେ କିଛି ଲାଭ ହେବ, ଏହା ମଧ୍ୟ ମୁଁ ଦେଖିପାରୁ ନାହିଁ । 1.31",

    "ହେ କୃଷ୍ଣ ! ମୁଁ ବିଜୟ ଚାହୁଁ ନାହିଁ, କି ରାଜ୍ୟ ତଥା ସୁଖ ମଧ୍ୟ ଚାହୁଁନାହିଁ । ହେ ଗୋବିନ୍ଦ ! ଆମର ଏପରି ରାଜ୍ୟରେ କି ପ୍ରୟୋଜନ ? ଅଥବା ଏପରି ଭୋଗରେ ବଞ୍ଚିବାରେ କି ଲାଭ ? 1.32",

    "ଆମେ ଯେଉଁମାନଙ୍କ ପାଇଁ ରାଜ୍ୟ, ଭୋଗ ଓ ସୁଖାଦି ଅଭିଳାଷ କରୁଛୁ, ଏହି ସେହିମାନେ ଧନ ଓ ଜୀବନର ଆଶା ପରିତ୍ୟାଗ କରି ଯୁଦ୍ଧ କରିବାକୁ ଠିଆ ହୋଇଛନ୍ତି । 1.33",

    "ଗୁରୁମାନେ, ପିତୃ ମାନେ, ପୁତ୍ର ମାନେ ଏବଂ ସେହିପରି ପିତାମହ ମାନେ, ମାମୁଁ ମାନେ, ଶ୍ୱଶୁର ମାନେ, ନାତି ମାନେ, ଶାସକମାନେ ତଥା ଅନ୍ୟ ସମ୍ବନ୍ଧୀୟ ମାନେ ବି ଅଛନ୍ତି । 1.34",

    "ହେ ମଧୁସୂଦନ ! ଏମାନେ ମୋତେ ମାରିଦେଲେ ବି ମୁଁ ଏମାନଙ୍କୁ ମାରିବାକୁ ଚାହେଁ ନାହିଁ । ପୃଥିବୀ ଲାଭ କରିବା କଥା କିଏ ପଚାରେ, ତିନି ଲୋକର ରାଜ୍ୟାଧିକାର ମିଳିବାର ଥିଲେ ମଧ୍ୟ, ମୁଁ ଏମାନଙ୍କୁ ମାରିବାକୁ ଚାହେଁ ନାହିଁ । 1.35",

    "ହେ ଜନାର୍ଦ୍ଦନ ! ଧୃତରାଷ୍ଟ୍ରଙ୍କ ପୁତ୍ର ମାନଙ୍କୁ ମାରିଦେଲେ ଆମକୁ କି ସୁଖ ମିଳିବ ? ଏହି ଆତତାୟୀ ମାନଙ୍କୁ ମାରିଲେ ତ ଆମକୁ ପାପ ହିଁ ଲାଗିବ । 1.36",

    "ତେଣୁ ହେ ମାଧବ ! ନିଜର ବାନ୍ଧବ ଦୁର୍ଯ୍ୟୋଧନାଦିଙ୍କୁ ମାରିବା ଆମ ପକ୍ଷରେ ଉଚିତ୍ ହେବ ନାହିଁ; କାରଣ ନିଜ କୁଟୁମ୍ବ ଲୋକଙ୍କୁ ମାରି ଆମେ କିପରି ସୁଖୀ ହୋଇପାରିବୁ ? 1.37",

    "ଯଦ୍ୟପି ଲୋଭବଶତଃ ଭ୍ରଷ୍ଟ ଚିତ୍ତ ହୋଇଯାଇଥିବା ଏହି ଲୋକେ କୁଳକ୍ଷୟଜନିତ ଦୋଷ ଓ ମିତ୍ରଦ୍ରୋହଜନିତ ପାପ ଜାଣିପାରୁ ନାହାନ୍ତି, ତଥାପି ହେ ଜନାର୍ଦ୍ଦନ ! କୁଳନାଶ ରୁ ଉତ୍ପନ୍ନ ଦୋଷକୁ ଜାଣିପାରୁଥିବା ଆମ୍ଭେମାନେ ଏପରି ପାପରୁ ନିବୃତ୍ତ ହେବାର ବିଚାର କାହିଁକି ନ କରିବୁ ? 1.38-1.39",

    "ଯଦ୍ୟପି ଲୋଭବଶତଃ ଭ୍ରଷ୍ଟ ଚିତ୍ତ ହୋଇଯାଇଥିବା ଏହି ଲୋକେ କୁଳକ୍ଷୟଜନିତ ଦୋଷ ଓ ମିତ୍ରଦ୍ରୋହଜନିତ ପାପ ଜାଣିପାରୁ ନାହାନ୍ତି, ତଥାପି ହେ ଜନାର୍ଦ୍ଦନ ! କୁଳନାଶ ରୁ ଉତ୍ପନ୍ନ ଦୋଷକୁ ଜାଣିପାରୁଥିବା ଆମ୍ଭେମାନେ ଏପରି ପାପରୁ ନିବୃତ୍ତ ହେବାର ବିଚାର କାହିଁକି ନ କରିବୁ ? 1.38-1.39",

    "ଯଦ୍ୟପି ଲୋଭବଶତଃ ଭ୍ରଷ୍ଟ ଚିତ୍ତ ହୋଇଯାଇଥିବା ଏହି ଲୋକେ କୁଳକ୍ଷୟଜନିତ ଦୋଷ ଓ ମିତ୍ରଦ୍ରୋହଜନିତ ପାପ ଜାଣିପାରୁ ନାହାନ୍ତି, ତଥାପି ହେ ଜନାର୍ଦ୍ଦନ ! କୁଳନାଶ ରୁ ଉତ୍ପନ୍ନ ଦୋଷକୁ ଜାଣିପାରୁଥିବା ଆମ୍ଭେମାନେ ଏପରି ପାପରୁ ନିବୃତ୍ତ ହେବାର ବିଚାର କାହିଁକି ନ କରିବୁ ? 1.40",

    "କୁଳକ୍ଷୟ ହେଲେ ପରମ୍ପରାକ୍ରମେ ଚଳି ଆସୁଥିବା କୁଳଧର୍ମ ନଷ୍ଟ ହୋଇଯାଏ ଏବଂ ଧର୍ମ ନଷ୍ଟ ହୋଇଗଲେ, ଅବଶିଷ୍ଟ ସମ୍ପୂର୍ଣ୍ଣ କୁଳକୁ ଅଧର୍ମ ମାଡି ବସେ । 1.41",

    "ବର୍ଣ୍ଣସଙ୍କର କୂଳଘାତୀମାନଙ୍କୁ ଓ କୁଳକୁ ନରକଗାମୀ କରିବାରେ କାରଣ ହୁଏ । ଶ୍ରାଦ୍ଧ ଓ ତର୍ପଣ ନ ମିଳିଲେ ଏମାନଙ୍କର(କୁଳଘାତୀମାନଙ୍କର) ପିତୃପୁରୁଷମାନେ ମଧ୍ୟ ସ୍ବସ୍ଥାନରୁ ପତିତ ହୁଅନ୍ତି । 1.42",

    "ଏହି ବର୍ଣ୍ଣସଙ୍କର ଉତ୍ପତ୍ତିକାରକ ଦୋଷ ଗୁଡିକ ଯୋଗୁଁ କୁଳଘାତୀମାନଙ୍କର ସଦାକାଳରୁ ଚଳି ଆସୁଥିବା କୁଳଧର୍ମ ଓ ଜାତିଧର୍ମ ନଷ୍ଟ ହୋଇଯାଏ । 1.43",

    "ହେ ଜନାର୍ଦ୍ଦନ ! ଯେଉଁ ମନୁଷ୍ୟ ମାନଙ୍କର କୁଳଧର୍ମ ନଷ୍ଟ ହୋଇଯାଏ, ସେମାନଙ୍କୁ ବହୁକାଳ ପର୍ଯ୍ୟନ୍ତ ନରକବାସ ମିଳେ ବୋଲି ଆମେ ଶୁଣି ଆସିଛୁ । 1.44",

    "ବଡ ଆଶ୍ଚର୍ଯ୍ୟ ଓ ଦୁଃଖର କଥା ଯେ ଆମ୍ଭେମାନେ ବୁଦ୍ଧିମାନ ହୋଇ ମଧ୍ୟ ମହାପାପ କରିବାକୁ ନିର୍ଣ୍ଣୟ ନେଇଛୁ; ସାମାନ୍ୟ ରାଜ୍ୟ ଓ ସୁଖ ପ୍ରାପ୍ତି ଲୋଭରେ ଆମେ ନିଜର ଆତ୍ମୀୟ ସ୍ୱଜନ ମାନଙ୍କୁ ମାରିବାପାଇଁ ପ୍ରସ୍ତୁତ ହୋଇଯାଇଛୁ ! 1.45",

    "ଯଦି ମୋ ପରି ନିଃଶସ୍ତ୍ର ଓ ପ୍ରତିକାରରହିତ ଲୋକକୁ ଧୃତରାଷ୍ଟ୍ରଙ୍କ ଶସ୍ତ୍ରଧାରୀ ପୁତ୍ରମାନେ ଯୁଦ୍ଧ ରେ ମାରିଦିଅନ୍ତି, ତେବେ ବି ତାହା ମୋ ପକ୍ଷରେ ଅଧିକ ମଙ୍ଗଳକାରୀ ହେବ । 1.46",

    "ସଞ୍ଜୟ କହିଲେ- ରଣଭୂମିରେ ଶୋକରେ ଉଦ୍ବିଗ୍ନମନା ଅର୍ଜୁନ ଏପରି କହି, ବାଣ ସହିତ ଧନୁ ତ୍ୟାଗ କରିଦେଇ ରଥ ଉପରେ ବସିପଡିଲେ । 1.47",

    "ଏହି ପ୍ରକାର ଓଁ ତତ୍ ସତ୍ -- ଏହି ଭଗବତ୍ ନାମର ଉଚ୍ଚାରଣପୂର୍ବକ ବ୍ରହ୍ମବିଦ୍ୟା ଓ ଯୋଗଶାସ୍ତ୍ରମୟ ଶ୍ରୀମଦ୍ଭଗବଦ୍ଗୀତା ଉପନିଷଦରୂପେ ଶ୍ରୀକୃଷ୍ଣ ଏବଂ ଅର୍ଜୁନଙ୍କ ସମ୍ବାଦରେ 'ଅର୍ଜୁନବିଷାଦଯୋଗନାମକ' ପ୍ରଥମ ଅଧ୍ୟାୟ ପୂର୍ଣ୍ଣ ହେଲା ।"
  ],
  "adhyaya-2" : [
    "ସଞ୍ଜୟ କହିଲେ- ସେପରି କରୁଣାବିଷ୍ଟ ଏବଂ ଅଶ୍ରୁପୂର୍ଣ୍ଣ ବ୍ୟାକୁଳ ନେତ୍ର ଶୋକାକୁଳ ଅର୍ଜୁନଙ୍କୁ ଭଗବାନ ମଧୁସୂଦନ ଏହି କଥା କହିଲେ,-  2.1",

    "ଶ୍ରୀ ଭଗବାନ କହିଲେ- ହେ ଅର୍ଜୁନ ! ତୁମକୁ ଏହି ଅସମୟରେ ଏହି ମୋହ କେଉଁଠୁ ଆସିଗଲା ? କାରଣ ଏହା ଶ୍ରେଷ୍ଠ ପୁରୁଷମାନଙ୍କ ଦ୍ୱାରା ଆଚରିତ ନୁହେଁ, କିମ୍ବା ସ୍ୱର୍ଗପ୍ରଦାୟକ କି କୀର୍ତ୍ତିପ୍ରଦାୟକ  ମଧ୍ୟ ନୁହେଁ । 2.2",

    "ତେଣୁ ହେ ଅର୍ଜୁନ ! ନପୁଂସକତା ପ୍ରାପ୍ତ ହୁଅ ନାହିଁ । ତୁମ ପକ୍ଷରେ ଏହା ଉଚିତ୍ ନୁହେଁ । ହେ ଶତୃତାପନ ! ହୃଦୟର ତୁଚ୍ଛ ଦୁର୍ବଳତା ତ୍ୟାଗକରି ଯୁଦ୍ଧ କରିବାକୁ ଠିଆ ହୋଇଯାଅ । 2.3",

    "ଅର୍ଜୁନ କହିଲେ- ହେ ମଧୁସୂଦନ ! ରଣଭୂମି ରେ ଭୀଷ୍ମ ପିତାମହ ଓ ଦ୍ରୋଣାଚାର୍ଯ୍ୟଙ୍କ ବିରୁଦ୍ଧରେ ମୁଁ କିପରି ବାଣ ମାରି ପଢିବି ? କାରଣ, ହେ ଅରିସୂଦନ ! ସେ ଦୁହେଁ ମୋର ପୂଜନୀୟ । 2.4",

    "ତେଣୁ ଏହି ମହାନୁଭବ ଗୁରୁଜନମାନଙ୍କୁ ମାରିବା ଅପେକ୍ଷା ମୁଁ ଏହି ଲୋକରେ ଭିକ୍ଷାନ୍ନ ଦ୍ୱାରା ଜୀବିକା ନିର୍ବାହ କରିବା କଲ୍ୟାଣକର ବୋଲି ମନେ କରୁଛି; କାରଣ ଗୁରୁଜନମାନଙ୍କୁ ମାରିଲେ ଏହି ଲୋକରେ ସେମାନେ ରକ୍ତସିକ୍ତ ଅର୍ଥ ଓ କାମରୂପକ ଭୋଗ ସାମଗ୍ରୀ ହିଁ ତ ଭୋଗ କରିବି । 2.5",

    "ଆମର ଯୁଦ୍ଧ କରିବା ଓ ନ କରିବା- ଏ ଦୁଇଟି ମଧ୍ୟରୁ କେଉଁଟି ଶ୍ରେଷ୍ଠ, ଆମେ ତାହା ଜାଣିନାହୁଁ; ଆମେ ସେମାନଙ୍କୁ ଜିତିବୁ, କି ସେମାନେ ଆମକୁ ଜିତିଲେ, ତାହା ମଧ୍ୟ ଜାଣିନାହୁଁ; ଯେଉଁ ମାନଙ୍କୁ ମାରି ଆମେ ବଞ୍ଚିବାକୁ ଇଚ୍ଛା ମଧ୍ୟ କରୁନାହୁଁ, ଆମର ସେହି ଆତ୍ମୀୟ ଧୃତରାଷ୍ଟ୍ରଙ୍କ ପୁତ୍ର ମାନେ ହିଁ ଆମ ସମ୍ମୁଖରେ ଯୁଦ୍ଧ କରିବାକୁ ଠିଆ ହୋଇଛନ୍ତି । 2.6",

    "ତେଣୁ କାପୁରୁଷତାରୂପକ ଦୋଷହେତୁ ନଷ୍ଟ ସ୍ୱଭାବଯୁକ୍ତ ତଥା ଧର୍ମ ବିଷୟରେ ମୋହିତ ଚିତ୍ତ ହୋଇଥିବା ମୁଁ ଆପଣଙ୍କୁ ପଚାରୁଛି, ମୋ ପକ୍ଷରେ କ'ଣ କରିବା ନିଶ୍ଚିତ କଲ୍ୟାଣକର , ତାହା ମୋତେ କହନ୍ତୁ; କାରଣ ମୁଁ ଆପଣଙ୍କର ଶିଷ୍ୟ । ମୋ ପରି ଶରଣାଗତକୁ  ଆପଣ ଉପଦେଶ ଦିଅନ୍ତୁ । 2.7",

    "କାରଣ ଇହଲୋକ ରେ ନିଷ୍କଣ୍ଟକ, ଧନଧାନ୍ୟ ସମ୍ପନ୍ନ ରାଜ୍ୟ ଏବଂ ଦେବତାମାନଙ୍କର ଆଧିପତ୍ୟ ପାଇଗଲେ ମଧ୍ୟ ମୁଁ ମୋର ଇନ୍ଦ୍ରିୟମାନଙ୍କୁ ଶୁଷ୍କ କରିଦେଉଥିବା ଶୋକକୁ ଦୂର କରିପାରିବା ପରି କୌଣସି ଉପାୟ ଦେଖିପାରୁ ନାହିଁ । 2.8",

    "ସଞ୍ଜୟ  ଧୃତରାଷ୍ଟ୍ରଙ୍କୁ କହିଲେ- ହେ ରାଜନ୍ ! ନିଦ୍ରାଜୟୀ ଅର୍ଜୁନ ଅନ୍ତର୍ଯ୍ୟାମୀ ଭଗବାନ୍ ଶ୍ରୀକୃଷ୍ଣଙ୍କୁ ଏପରି କହିଲେ ଏବଂ ଶ୍ରୀ ଗୋବିନ୍ଦ ଭଗବାନ ଙ୍କୁ 'ମୁଁ  ଯୁଦ୍ଧ କରିବି ନାହିଁ' ବୋଲି ସ୍ପଷ୍ଟ ଭାବରେ କହିଦେଇ ଚୁପ୍ ହୋଇଗଲେ । 2.9",

    "ହେ ଭରତବଂଶୀ ଧୃତରାଷ୍ଟ୍ର ! ଅନ୍ତର୍ଯ୍ୟାମୀ ଶ୍ରୀକୃଷ୍ଣ ପରମାତ୍ମା ଦୁଇ ସେନାଙ୍କ ମଧ୍ୟରେ ଶୋକ କରୁଥିବା ସେହି ଅର୍ଜୁନଙ୍କୁ ସ୍ମିତହାସ୍ କରି କହିଲେ,-  2.10",

    "ଶ୍ରୀ ଭଗବାନ୍ କହିଲେ- ହେ ଅର୍ଜୁନ ! ଯାହାଙ୍କ ପାଇଁ ଶୋକ କରିବା ଉଚିତ୍ ନୁହେଁ, ସେମାନଙ୍କ ପାଇଁ ଶୋକ କରୁଛ ଏବଂ ପଣ୍ଡିତଙ୍କ ପରି କଥା କହୁଛ ! କିନ୍ତୁ, ଯାହାଙ୍କର ପ୍ରାଣ ଚାଲିଯାଇଛି, ସେମାନଙ୍କ ପାଇଁ ଏବଂ ଯାହାଙ୍କର ପ୍ରାଣ ଯାଇ ନାହିଁ, ସେମାନଙ୍କ ପାଇଁ ମଧ୍ୟ ପଣ୍ଡିତମାନେ ଶୋକ କରନ୍ତି ନାହିଁ । 2.11",

    "ଏପରି ନୁହେଁ ଯେ, କୌଣସି କାଳରେ ମୁଁ ନ ଥିଲି, କି ତୁମେ ନ ଥିଲ ଅଥବା ଏହି ରାଜାମାନେ ନ ଥିଲେ । ପୁଣି ଏପରି ମଧ୍ୟ ଘଟିବ ନାହିଁ ଯେ, ଭବିଷ୍ୟତରେ ଆମେ ସମସ୍ତେ ରହିବା ନାହିଁ । 2.12",

    "ଯେପରି ଏହି ଦେହରେ ଜୀବାତ୍ମା ର ବାଲ୍ୟାବସ୍ଥା, ଯୌବନାବସ୍ଥା ଓ ବୃଦ୍ଧାବସ୍ଥା ହୋଇଥାଏ, ସେହିପରି ଅନ୍ୟ ଶରୀର ମଧ୍ୟ ପ୍ରାପ୍ତ ହୁଏ; ସେହି ବିଷୟରେ ଧୀର ପୁରୁଷ ମୋହଗ୍ରସ୍ତ ହୁଅନ୍ତି ନାହିଁ । 2.13",

    "କାରଣ, ହେ କୁନ୍ତୀପୁତ୍ର ! ଶୀତ-ଉଷ୍ଣ ଓ ସୁଖ-ଦୁଃଖ ଦେଉଥିବା ଇନ୍ଦ୍ରିୟ ଓ ବିଷୟମାନଙ୍କର ସଂଯୋଗ ତ ଉତ୍ପତ୍ତି-ବିନାଶଶୀଳ ଓ ଅନିତ୍ୟ; ତେଣୁ ହେ ଭାରତ ! ତୁମେ ସେଗୁଡିକୁ ସହିଯାଅ । 2.14",

    "କାରଣ, ହେ ପୁରୁଷଶ୍ରେଷ୍ଠ ! ସୁଖ-ଦୁଃଖକୁ ସମାନ ଦୃଷ୍ଟିରେ ଦେଖୁଥିବା ଯେଉଁ ଧୀରପୁରୁଷଙ୍କୁ ଏହି ଇନ୍ଦ୍ରିୟ ଓ ବିଷୟର ସଂଯୋଗ ବ୍ୟାକୁଳ କରିପାରେ ନାହିଁ, ସେ ହିଁ ମୋକ୍ଷ ପାଇଁ ଯୋଗ୍ୟ ହୁଅନ୍ତି । 2.15",

    "ଅସତ୍ ବସ୍ତୁର ଅସ୍ତିତ୍ବ ନାହିଁ ଏବଂ ସତ୍ ବସ୍ତୁ ର ଅଭାବ ନାହିଁ । ଏପରି ଭାବରେ ଉକ୍ତ ଦୁଇଟି ଯାକ ର ତତ୍ତ୍ୱ ତତ୍ତ୍ୱଜ୍ଞାନୀ ପୁରୁଷମାନଙ୍କ ଦ୍ୱାରା ଦେଖାଯାଇଛି । 2.16",

    "ଏହି ନ୍ୟାୟ ରେ ଯାହାଙ୍କ ଦ୍ୱାରା ଏହି ସମ୍ପୂର୍ଣ୍ଣ ଜଗତ୍ ବ୍ୟାପ୍ତ ହୋଇଛି, ତାହାଙ୍କୁ ଅବିନାଶୀ ବୋଲି ଜାଣ । ସେହି ଅବିନାଶୀଙ୍କୁ ବିନାଶ କରିବାକୁ  କେହି ହେଲେ ସମର୍ଥ ନୁହେଁ । 2.17",

    "ନାଶରହିତ, ଅପ୍ରମେୟ ଓ ନିତ୍ୟସ୍ୱରୂପ  ଜୀବାତ୍ମା ର  ଏହି ଶରୀର ଗୁଡିକ ନାଶବାନ୍ ବୋଲି କୁହାଯାଇଛି । ତେଣୁ ହେ ଭରତବଂଶୀ ଅର୍ଜୁନ ! ତୁମେ ଯୁଦ୍ଧ କର । 2.18",

    "ଯେ ଏହି ଆତ୍ମା ହତ୍ୟା କରେ ବୋଲି ବୁଝନ୍ତି ତଥା ଯେ ଏହା ନିହତ ହୁଏ ବୋଲି ସ୍ୱୀକାର କରନ୍ତି, ସେ ଉଭୟେ ଆତ୍ମା ବିଷୟରେ କିଛି ଜାଣନ୍ତି ନାହିଁ; କାରଣ ଏହି ଆତ୍ମା ବାସ୍ତବରେ କାହାରିକୁ ହତ୍ୟା କରେ ନାହିଁ, କି କାହାରି ଦ୍ୱାରା ନିହତ ହୁଏ ନାହିଁ । 2.19",

    "ଏହି ଆତ୍ମା କୌଣସି କାଳରେ ବି ଜନ୍ମ ହୁଏ ନାହିଁ, କି ମରେ ନାହିଁ, ତଥା ଏହା ଉତ୍ପନ୍ନ ହେବା ପରେ ଯେ ହୁଏ, ସେପରି ନୁହେଁ; କାରଣ ଏହା ହେଉଛି ଅଜନ୍ମା, ନିତ୍ୟ, ସନାତନ ଏବଂ ପୁରାତନ । ଶରୀରକୁ ବଧ କରିଦେଲେ ମଧ୍ୟ ଏହା ନିହତ ହୁଏ ନାହିଁ। 2.20",

    "ହେ ପୃଥାପୁତ୍ର ଅର୍ଜୁନ ! ଯେଉଁ ପୁରୁଷ ଏହି ଆତ୍ମା କୁ ନାଶରହିତ, ନିତ୍ୟ, ଅଜନ୍ମା ଓ ଅବ୍ୟୟ ବୋଲି ଜାଣନ୍ତି, ସେହି ପୁରୁଷ କିପରି ବଧ କରିବେ ବା ବଧ କରାଇବେ? 2.21",

    "ମନୁଷ୍ୟ ଯେପରି ପୁରୁଣା ବସ୍ତ୍ରମାନ ତ୍ୟାଗ କରି ଅନ୍ୟ ନୂତନ ବସ୍ତ୍ର ଗ୍ରହଣ କରେ, ଜୀବାତ୍ମା ସେହିପରି ପୁରାତନ ଶ୍ରୀମାନ ତ୍ୟାଗ କରି ଅନ୍ୟ ନୂତନ ଶରୀର ମାନ ପ୍ରାପ୍ତ ହୋଇଥାଏ । 2.22",

    "ଏହି ଆତ୍ମା କୁ  କୌଣସି ଶସ୍ତ୍ର ବି କାଟି ପାରେ ନାହିଁ; ଅଗ୍ନି ଏହାକୁ ଜଳାଇ ପାରେ ନାହିଁ; ଦଳ ଏହାକୁ ଭିଜାଇ ପାରେ ନାହିଁ ଏବଂ ବିୟୁ ଏହାକୁ ଶୁଖାଇ ପାରେ ନାହିଁ । 2.23",

    "କାରଣ ଏହି ଆତ୍ମା ଅଚ୍ଛେଦ୍ୟ, ଅଦାହ୍ୟ, ଅକ୍ଳେଦ୍ୟ ଓ ନିଃସନ୍ଦେହ ଅଶୋଷ୍ୟ, ତଥା ଏହା ହେଉଛି ନିତ୍ୟ, ସର୍ବଗତ, ସ୍ଥିର, ଅଚଳ ଓ ସନାତନ । 2.24",

    "ଏହି ଆତ୍ମା ଅବ୍ୟକ୍ତ; ଏହା ଅଚିନ୍ତ୍ୟ ଏବଂ ଏହାକୁ ବିକାରରହିତ କୁହାଯାଏ । ଏଣୁ ସେ ଅର୍ଜୁନ ! ଏହି ଆତ୍ମା ଏପରି ବୋଲି ଜାଣି ତୁମେ ଶୋକ କରିବା ଉଚିତ୍ ନୁହେଁ । 2.25",

    "ପକ୍ଷାନ୍ତରେ, ଯଦି ତୁମେ ଏହି ଆତ୍ମା କୁ ନିତ୍ୟ ଜନ୍ମ ହେଉଛି ଏବଂ ନିତ୍ୟ ମରୁଛି ବୋଲି ସ୍ୱୀକାର କରୁଥାଅ, ତଥାପି ସେ ମହାବାହୁ ! ତୁମେ ଏପରି ଶୋକ କରିବା ଉଚିତ୍ ନୁହେଁ । 2.26",

    "କାରଣ ଏହି ମାନ୍ୟତା ଅନୁସାରେ ଜନ୍ମ ହୋଇଥିବା ଲୋକର ମୃତ୍ୟୁ ନିଶ୍ଚିତ ଏବଂ ମରିଥିବା ଲୋକର ଜନ୍ମ ନିଶ୍ଚିତ । ଏହି ହେତୁ ମଧ୍ୟ ଯାହା ଅନିବାର୍ଯ୍ୟ, ସେହି ବିଷୟରେ ତୁମେ ଶୋକ କରିବା ଉଚିତ୍ ନୁହେଁ । 2.27",

    "ହେ ଅର୍ଜୁନ ! ସମସ୍ତ ପ୍ରାଣୀ ଜନ୍ମ ହେବା ପୂର୍ବରୁ ଅପ୍ରକଟ ହୋଇ ରହିଥାନ୍ତି ଏବଂ ମୃତ୍ୟୁ ପରେ ମଧ୍ୟ ଅପ୍ରକଟ ହୋଇଯାଆନ୍ତି । ସେମାନେ କେବଳ ଏହି ମଧ୍ୟବର୍ତ୍ତୀ କାଳରେ ହିଁ ପ୍ରକଟ ହୁଅନ୍ତି । ଏପରି ସ୍ଥିତିରେ ଶୋକ କରିବା କ'ଣ ? 2.28",

    "କୌଣସି ଜଣେ ମହାପୁରୁଷ ହିଁ ଏହି ଆତ୍ମାକୁ ଆଶ୍ଚର୍ଯ୍ୟ ପରି ଦେଖନ୍ତି ଏବଂ ସେହିପରି ଅନ୍ୟ କେହି ମହାପୁରୁଷ ଏହାର ତତ୍ତ୍ୱକୁ ଆଶ୍ଚର୍ଯ୍ୟ ପରି ବର୍ଣ୍ଣନା କରନ୍ତି ତଥା ଅନ୍ୟ କେହି ଅଧିକାରୀ ପୁରୁଷ ଏହାକୁ ଆଶ୍ଚର୍ଯ୍ୟ ପରି ଶୁଣନ୍ତି ଏବଂ କେହି କେହି ଶୁଣି ମଧ୍ୟ ଏହାକୁ ଜାଣିପାରନ୍ତି ନାହିଁ । 2.29",

    "ହେ ଅର୍ଜୁନ ! ଏହି ଆତ୍ମା ସମସ୍ତଙ୍କ ଶରୀରରେ ସର୍ବଦା ଅବଧ୍ୟ । ତେଣୁ ସକଳ ପ୍ରାଣୀ ଙ୍କ ପାଇଁ ଅର୍ଥାତ୍ କୌଣସି ବି ପ୍ରାଣୀ ପାଇଁ ତୁମେ ଶୋକ କରିବା ଉଚିତ୍ ନୁହେଁ । 2.30",

    "ପୁଣି ନିଜର ଧର୍ମ ଦୃଷ୍ଟିରୁ ବି ତୁମେ ଭୟ କରିବା ଉଚିତ୍ ନୁହେଁ; କାରଣ କ୍ଷତ୍ରିୟ ନିମନ୍ତେ ଧର୍ମ ଯୁଦ୍ଧ ଠାରୁ ବଳି କଲ୍ୟାଣକର କର୍ତ୍ତବ୍ୟ  ଆଉ କିଛି ନାହିଁ । 2.31",

    "ହେ ପାର୍ଥ ! ସ୍ୱତଃ ପ୍ରାପ୍ତ ହୋଇଥିବା ଏବଂ ଉନ୍ମୁକ୍ତ ସ୍ୱର୍ଗଦ୍ୱାରରୂପକ ଏହିପରି ଯୁଦ୍ଧ ଭାଗ୍ୟବାନ କ୍ଷତ୍ରିୟ ମାନେ ହିଁ ଲାଭ କରନ୍ତି । 2.32",

    "କିନ୍ତୁ ଯଦି ତୁମେ ଏହି ଧର୍ମମୟ ଯୁଦ୍ଧ ନ କରିବ, ତେବେ ସ୍ୱଧର୍ମ ଓ ସୁଯଶ ହରାଇ ପାପଭାଗୀ ହେବ । 2.33",

    "ତଥା ସମସ୍ତେ ଦୀର୍ଘକାଳ ସ୍ଥାୟୀ ତୁମର ଅପକୀର୍ତ୍ତୀ ବି ଆଲୋଚନା କରିବେ ଏବଂ ସମ୍ମାନନୀୟ ପୁରୁଷଙ୍କ ନିମନ୍ତେ ଅପକୀର୍ତ୍ତି ହେଉଛି ମରଣରୁ ବଳି ଦୁ଼ଃଖଦାୟକ । 2.34",

    "ଏବଂ ଯେଉଁମାନଙ୍କ ଦୃଷ୍ଟିରେ ତୁମେ ବିଶେଷରୂପେ ସମ୍ମାନିତ ହୋଇସାରିଛ, ସେମାନଙ୍କ ଦୃଷ୍ଟିରେ ବର୍ତ୍ତମାନ ହୀନ ହୋଇଯିବ; ସେହି ମହାରଥୀମାନେ ମନେ କରିବେ ଯେ, ତୁମେ ଭୟଭୀତ ହୋଇ ଯୁଦ୍ଧ ରୁ ଓହରି ଗଲ । 2.35",

    "ତୁମର ଶତ୍ରୁ ମାନେ ତୁମ ସାମର୍ଥ୍ୟକୁ ନିନ୍ଦା କରି କରି ତୁମକୁ ବହୁ ଅକଥ୍ୟ ବଚନ ମଧ୍ୟ କହିବେ । ତା' ଠାରୁ ବଳି ଦୁଃଖର ବିଷୟ ଆଉ କ'ଣ ହୋଇପାରେ ! 2.36",

    "ତୁମେ ଯୁଦ୍ଧ ରେ ନିହତ ହେଲେ ସ୍ୱର୍ଗ ପାଇବ; ନଚେତ୍ ଯୁଦ୍ଧ ରେ ଜୟୀ ହେଲେ ଭୂମଣ୍ଡଳ ର ରାଜ୍ୟ ଭୋଗ କରିବ । ତେଣୁ ହେ ଅର୍ଜୁନ ! ତୁମେ ଯୁଦ୍ଧ କରିବାକୁ ସଂକଳ୍ପବଦ୍ଧ  ହୋଇ ଦଣ୍ଡାୟମାନ ହୋଇଯାଅ । 2.37",

    "ଜୟ-ପରାଜୟ, ଲାଭ-କ୍ଷତି ଓ ସୁଖ-ଦୁଃଖକୁ ସମାନ ମନେ କରି, ତା' ପରେ ତୁମେ ଯୁଦ୍ଧ କରିବାକୁ ପ୍ରସ୍ତୁତ ହୋଇଯାଅ । ଏହିପରି ଯୁଦ୍ଧ କଲେ  ତୁମେ ପାପଭାଗୀ ହେବ ନାହିଁ । 2.38",

    "ହେ ପାର୍ଥ ! ଏହି ବୁଦ୍ଧି ତୁମକୁ ଜ୍ଞାନ ଯୋଗ ଦୃଷ୍ଟିରୁ କୁହାଗଲା । ବର୍ତ୍ତମାନ ତୁମେ ଏହା କର୍ମଯୋଗ ଦୃଷ୍ଟିରୁ ଶୁଣ; ଏହି ବୁଦ୍ଧି ରେ ଯୁକ୍ତ ହେଲେ ତୁମେ କର୍ମବନ୍ଧନକୁ  ଭଲ ରୂପେ ତ୍ୟାଗ କରିଦେଇପାରିବ ଅର୍ଥାତ୍ କର୍ମବନ୍ଧନରୁ ସର୍ବଥା ମୁକ୍ତ ହୋଇଯିବ ।  2.39",

    "ଏହି କର୍ମଯୋଗରେ ଆରମ୍ଭ ର ଅର୍ଥାତ୍ ବୀଜର ନାଶ ହୁଏ ନାହିଁ ଏବଂ ବିପରୀତ ଫଳରୂପକ ଦୋଷ ମଧ୍ୟ ଏଥିରେ ହୁଏ ନାହିଁ । ବରଂ ଏହି କର୍ମଯୋଗରୂପକ ଧର୍ମର ସାମାନ୍ୟ ମାତ୍ର ସାଧନା ବି ଜନ୍ମ- ମୃତ୍ୟୁ ରୂପକ ବିରାଟ ଭୟରୁ ରକ୍ଷା କରିଦିଏ । 2.40",

    "ହେ ଅର୍ଜୁନ ! ଏହି କର୍ମଯୋଗ ରେ ନିଶ୍ଚୟାତ୍ମିକା ବୁଦ୍ଧି ଗୋଟିଏ ମାତ୍ର ହୁଏ; କିନ୍ତୁ ଅସ୍ଥିର ବିଚାର ଯୁକ୍ତ ବିବେକହୀନ  ସକାମ ମନୁଷ୍ୟ ମାନଙ୍କର ବୁଦ୍ଧି ନିଶ୍ଚିତ ଭାବରେ ବହୁପ୍ରକାର ଓ ଅସଂଖ୍ୟ ହୁଏ । 2.41",

    "ହେ ଅର୍ଜୁନ ! ଯେଉଁମାନେ ଭୋଗରେ ବୁଡି ରହିଛନ୍ତି, ଯେଉଁମାନେ କର୍ମଫଳର ପ୍ରଶଂସା କରୁଥିବା ବେଦବାକ୍ୟରେ ପ୍ରୀତି ରଖନ୍ତି, ଯେଉଁ ମାନଙ୍କ ବୁଦ୍ଧି ରେ ସ୍ୱର୍ଗ ହିଁ ପରମ ପ୍ରାପ୍ୟ ବସ୍ତୁ ଏବଂ ଯେଉଁ ମାନେ ସ୍ୱର୍ଗରୁ ବଳି ଅନ୍ୟ କୌଣସି ବସ୍ତୁ ନାହିଁ ବୋଲି କହନ୍ତି, ସେହି ଅବିବେକୀମାନେ ଭୋଗ-ଐଶ୍ୱର୍ଯ୍ୟ ପ୍ରାପ୍ତି ନିମନ୍ତେ ଜନ୍ମ ରୂପକ କର୍ମ ଫଳ ଦେଉଥିବା କ୍ରିୟାବହୁଳ, ଶ୍ରୁତି ମଧୁର, ସୁନ୍ଦର ପୁଷ୍ପିତ ବାଣୀ କହନ୍ତି । ସେପରି ବାଣୀ ଦ୍ୱାରା ଯେଉଁ ମାନଙ୍କର ଚିତ୍ତ ହରଣ ହୋଇଯାଇଛି, ଯେଉଁ ମାନେ ଭୋଗ ଓ ଐଶ୍ୱର୍ଯ୍ୟରେ ଅତ୍ୟନ୍ତ ଆସକ୍ତ, ସେହି ପୁରୁଷ ମାନଙ୍କର ପରମାତ୍ମାଙ୍କ ଠାରେ ନିଶ୍ଚୟାତ୍ମିକା ବୁଦ୍ଧି ହୁଏ ନାହିଁ ।  2.42-2.44",

    "ହେ ଅର୍ଜୁନ ! ଯେଉଁମାନେ ଭୋଗରେ ବୁଡି ରହିଛନ୍ତି, ଯେଉଁମାନେ କର୍ମଫଳର ପ୍ରଶଂସା କରୁଥିବା ବେଦବାକ୍ୟରେ ପ୍ରୀତି ରଖନ୍ତି, ଯେଉଁ ମାନଙ୍କ ବୁଦ୍ଧି ରେ ସ୍ୱର୍ଗ ହିଁ ପରମ ପ୍ରାପ୍ୟ ବସ୍ତୁ ଏବଂ ଯେଉଁ ମାନେ ସ୍ୱର୍ଗରୁ ବଳି ଅନ୍ୟ କୌଣସି ବସ୍ତୁ ନାହିଁ ବୋଲି କହନ୍ତି, ସେହି ଅବିବେକୀମାନେ ଭୋଗ-ଐଶ୍ୱର୍ଯ୍ୟ ପ୍ରାପ୍ତି ନିମନ୍ତେ ଜନ୍ମ ରୂପକ କର୍ମ ଫଳ ଦେଉଥିବା କ୍ରିୟାବହୁଳ, ଶ୍ରୁତି ମଧୁର, ସୁନ୍ଦର ପୁଷ୍ପିତ ବାଣୀ କହନ୍ତି । ସେପରି ବାଣୀ ଦ୍ୱାରା ଯେଉଁ ମାନଙ୍କର ଚିତ୍ତ ହରଣ ହୋଇଯାଇଛି, ଯେଉଁ ମାନେ ଭୋଗ ଓ ଐଶ୍ୱର୍ଯ୍ୟରେ ଅତ୍ୟନ୍ତ ଆସକ୍ତ, ସେହି ପୁରୁଷ ମାନଙ୍କର ପରମାତ୍ମାଙ୍କ ଠାରେ ନିଶ୍ଚୟାତ୍ମିକା ବୁଦ୍ଧି ହୁଏ ନାହିଁ ।  2.42-2.44",

    "ହେ ଅର୍ଜୁନ ! ଯେଉଁମାନେ ଭୋଗରେ ବୁଡି ରହିଛନ୍ତି, ଯେଉଁମାନେ କର୍ମଫଳର ପ୍ରଶଂସା କରୁଥିବା ବେଦବାକ୍ୟରେ ପ୍ରୀତି ରଖନ୍ତି, ଯେଉଁ ମାନଙ୍କ ବୁଦ୍ଧି ରେ ସ୍ୱର୍ଗ ହିଁ ପରମ ପ୍ରାପ୍ୟ ବସ୍ତୁ ଏବଂ ଯେଉଁ ମାନେ ସ୍ୱର୍ଗରୁ ବଳି ଅନ୍ୟ କୌଣସି ବସ୍ତୁ ନାହିଁ ବୋଲି କହନ୍ତି, ସେହି ଅବିବେକୀମାନେ ଭୋଗ-ଐଶ୍ୱର୍ଯ୍ୟ ପ୍ରାପ୍ତି ନିମନ୍ତେ ଜନ୍ମ ରୂପକ କର୍ମ ଫଳ ଦେଉଥିବା କ୍ରିୟାବହୁଳ, ଶ୍ରୁତି ମଧୁର, ସୁନ୍ଦର ପୁଷ୍ପିତ ବାଣୀ କହନ୍ତି । ସେପରି ବାଣୀ ଦ୍ୱାରା ଯେଉଁ ମାନଙ୍କର ଚିତ୍ତ ହରଣ ହୋଇଯାଇଛି, ଯେଉଁ ମାନେ ଭୋଗ ଓ ଐଶ୍ୱର୍ଯ୍ୟରେ ଅତ୍ୟନ୍ତ ଆସକ୍ତ, ସେହି ପୁରୁଷ ମାନଙ୍କର ପରମାତ୍ମାଙ୍କ ଠାରେ ନିଶ୍ଚୟାତ୍ମିକା ବୁଦ୍ଧି ହୁଏ ନାହିଁ ।  2.42-2.44",

    "ହେ ଅର୍ଜୁନ ! ବେଦମାନେ ଉପରୋକ୍ତ ପ୍ରକାରେ ତିନିଗୁଣା କାର୍ଯ୍ୟ ରୂପ ସମସ୍ତ ଭୋଗ ଏବଂ ସେସବୁର ସାଧନମାନଙ୍କର ପ୍ରତିପାଦନ କରନ୍ତି । ତୁମେ ସେସବୁ ଭୋଗ ଏବଂ ସେସବୁ ସାଧନରେ  ଆସକ୍ତି ତ୍ୟାଗ କର; ହର୍ଷ-ଶୋକାଦି ଦ୍ୱନ୍ଦ୍ୱରୁ ରହିତ ହୋଇଯାଅ; ନିତ୍ୟବସ୍ତୁ ପରମାତ୍ମା ରେ ସ୍ଥିତ ହୋଇ, ଯୋଗକ୍ଷେମ ନ ଚାହିଁ ସ୍ଵାଧୀନ ଅନ୍ତଃକରଣଯୁକ୍ତ ହୋଇଯାଅ । 2.45",

    "ସବୁଦିଗରୁ ପରିପୂର୍ଣ୍ଣ ଜଳାଶୟଟିଏ ପାଇଗଲେ ଛୋଟ ଜଳାଶୟରେ ମନୁଷ୍ୟ ର ଯେତିକି ପ୍ରୟୋଜନ ରହେ, ବ୍ରହ୍ମଜ୍ଞାନ ଲାଭ କରିଥିବା ବ୍ରାହ୍ମଣଙ୍କର ସମସ୍ତ ବେଦରେ ସେତିକି ପ୍ରୟୋଜନ ରହିଯାଏ । 2.46",

    "କର୍ମ କରିବାରେ ହିଁ ତୁମର ଅଧିକାର ରହିଛି; କର୍ମ ର ଫଳ ଉପରେ କଦାପି ଅଧିକାର ନାହିଁ । ତେଣୁ ତୁମେ କର୍ମଫଳର କାରଣ ହୁଅ ନାହିଁ ତଥା ତୁମର କର୍ମ ନ କରିବାରେ ବି ଆସକ୍ତି ନ ହେଉ । 2.47",

    "ହେ ଧନଞ୍ଜୟ ! ତୁମେ ଆସକ୍ତି ତ୍ୟାଗ କରି ତଥା ସିଦ୍ଧି-ଅସିଦ୍ଧି ରେ ସମବୁଦ୍ଧି ରଖି ଯୋଗସ୍ଥ ହୋଇ କର୍ତ୍ତବ୍ଯ କର୍ମ କର; ସମତ୍ଵକୁ ହିଁ ଯୋଗ କୁହାଯାଏ । 2.48",

    "ଏହି ସମତ୍ୱରୂପକ ବୁଦ୍ଧିଯୋଗ ଅପେକ୍ଷା ସକାମ କର୍ମ ଅତ୍ୟନ୍ତ ନିମ୍ନଶ୍ରେଣୀର ଅଟେ । ତେଣୁ ହେ ଧନଞ୍ଜୟ ! ତୁମେ ସମବୁଦ୍ଧି ରେ ହିଁ ରକ୍ଷା ର ଉପାୟ ଖୋଜ ଅର୍ଥାତ୍ ବୁଦ୍ଧିଯୋଗର  ହିଁ ଆଶ୍ରୟ ଗ୍ରହଣ କର; କାରଣ, ଫଳର କାରଣ ହେଉଥିବା ଲୋକେ ଅତ୍ୟନ୍ତ ଦୀନ । 2.49",

    "ସମବୁଦ୍ଧିଯୁକ୍ତ ପୁରୁଷ ପୁଣ୍ୟ ଓ ପାପ ଉଭୟକୁ ଇହଲୋକରେ ତ୍ୟାଗ କରିଦିଅନ୍ତି ଅର୍ଥାତ୍ ସେଥିରୁ ମୁକ୍ତ ହୋଇଯାଆନ୍ତି । ତେଣୁ ତୁମେ ସମତ୍ୱରୂପକ ଯୋଗରେ ଲାଗିଯାଅ । ଏହି ସମତ୍ୱରୂପକ ଯୋଗ ହିଁ କର୍ମରେ କୁଶଳତା ଅର୍ଥାତ୍ କର୍ମବନ୍ଧନରୁ ମୁକ୍ତି ପାଇବାର ଉପାୟ । 2.50",

    "କାରଣ ସମବୁଦ୍ଧି ରେ ଯୁକ୍ତ ଜ୍ଞାନୀଜନମାନେ କର୍ମ ଦ୍ବାରା ଉତ୍ପନ୍ନ ହେଉଥିବା ଫଳ ତ୍ଯାଗ କରି, ଜନ୍ମ ରୂପକ  ବନ୍ଧନରୁ ମୁକ୍ତ ହୋଇ ନିର୍ବିକାର ପରମପଦ ପ୍ରାପ୍ତ ହୋଇ ଯାଆନ୍ତି।  2.51",

    "ଯେତେବେଳେ ତୁମର ବୁଦ୍ଧି ମୋହରୂପକ ପଙ୍କକୁ ସମ୍ପୂର୍ଣ୍ଣ ରୂପେ ଅତିକ୍ରମ କରିଯିବ, ସେତେବେଳେ ତୁମର ଶୁଣିଥିବା ଏବଂ ଶୁଣିବାକୁ ଥିବା ଇହଲୋକ ଏବଂ ପରଲୋକ ସମ୍ବନ୍ଧୀୟ ସମସ୍ତ ପ୍ରକାର ଭୋଗ ପ୍ରତି ବୈରାଗ୍ୟ ଆସିଯିବ।  2.52",

    "ବିଭିନ୍ନ ପ୍ରକାର କଥା ଶୁଣି ଶୁଣି ବିଚଳିତ ହୋଇଯାଇଥିବା ତୁମର ବୁଦ୍ଧି ଯେତେବେଳେ ପରମାତ୍ମାଙ୍କ ଠାରେ ଅଚଳ ଓ ସ୍ଥିର ହୋଇଯିବ, ସେତେବେଳେ ତୁମେ ଯୋଗପ୍ରାପ୍ତ ହୋଇଯିବ, ଅର୍ଥାତ୍ ପରମାତ୍ମାଙ୍କ ସହିତ ତୁମର ନିତ୍ଯ ସଂଯୋଗ ହୋଇଯିବ।  2.53",

    "ଅର୍ଜୁନ ପଚାରିଲେ- ହେ କେଶବ! ସମାଧିରେ ପରମାତ୍ମପ୍ରାପ୍ତ ସ୍ଥିରବୁଦ୍ଧି ପୁରୁଷଙ୍କ ଲକ୍ଷଣ କ'ଣ? ସେହି ସ୍ଥିର ବୁଦ୍ଧି ପୁରୁଷ କିପରି କହନ୍ତି, କିପରି ବସନ୍ତି ଏବଂ କିପରି ଚଳନ୍ତି?  2.54",

    "ଶ୍ରୀ ଭଗବାନ କହିଲେ--  ହେ ପୃଥା ନନ୍ଦନ ! ଯେତେବେଳେ ଏହି ପୁରୁଷ ମନ ମଧ୍ୟରେ ଥିବା ସମସ୍ତ କାମନା  ପୂର୍ଣ୍ଣ ରୂପେ ତ୍ୟାଗ କରିଦିଅନ୍ତି ଏବଂ ଆତ୍ମା ଦ୍ୱାରା ଆତ୍ମା ରେ ହିଁ ସନ୍ତୁଷ୍ଟ ହୋଇ ରହନ୍ତି, ସେତେବେଳେ ତାଙ୍କୁ ସ୍ଥିତପ୍ରଜ୍ଞ କୁହାଯାଏ । 2.55",

    "ଦୁଃଖ ଆସିଗଲେ ବି ଯାହାଙ୍କ ମନରେ ଉଦ୍ବେଗ ଜାତ ହୁଏ ନାହିଁ, ସୁଖ ମିଳିଗଲେ ବି ଯେ ସର୍ବଥା ନିଃସ୍ପୃହ ରହନ୍ତି, ଯାହାଙ୍କର  ରାଗ( ଆସକ୍ତି), ଭୟ ଓ କ୍ରୋଧ ନଷ୍ଟ ହୋଇଯାଇଛି, ସେହିପରି ମୁନିଙ୍କୁ ସ୍ଥିରବୁଦ୍ଧି କୁହାଯାଏ । 2.56",

    "ଯେଉଁ ପୁରୁଷ ସର୍ବତ୍ର ସ୍ନେହ ରହିତ ହୋଇ ଶୁଭ ବସ୍ତୁ ପାଇଲେ ପ୍ରସନ୍ନ ହୁଅନ୍ତି ନାହିଁ, କି ଅଶୁଭ ବସ୍ତୁ ପାଇଲେ ଦ୍ଵେଷ କରନ୍ତି ନାହିଁ, ତାଙ୍କର ବୁଦ୍ଧି ସ୍ଥିର ଅଟେ । 2.57",

    "ଏବଂ କଇଁଛ ଯେପରି ନିଜର ଅଙ୍ଗପ୍ରତ୍ୟଙ୍ଗ ଗୁଡ଼ିକୁ ସବୁ ଦିଗରୁ ସଙ୍କୁଚିତ କରି ଆଣେ, ଏହି ପୁରୁଷ ଯେତେବେଳେ ଠିକ୍ ସେହିପରି ନିଜର ଇନ୍ଦ୍ରିୟ ମାନଙ୍କୁ ଇନ୍ଦ୍ରିୟ ଗ୍ରାହ୍ୟ ବିଷୟମାନଙ୍କରୁ ସକଳ ପ୍ରକାରେ ହଟାଇ ଆଣନ୍ତି, ସେତେବେଳେ ତାଙ୍କର ବୁଦ୍ଧି ସ୍ଥିର ଅଟେ ବୋଲି ଜାଣିବା ଉଚିତ୍ । 2.58",

    "ଇନ୍ଦ୍ରିୟ ମାନଙ୍କ ଦ୍ଵାରା ବିଷୟମାନଙ୍କୁ ଗ୍ରହଣ କରୁ ନ ଥିବା ପୁରୁଷ ର କେବଳ ବିଷୟମାନେ ନିବୃତ୍ତ ହୋଇଯାଆନ୍ତି; ବିଷୟ ଭୋଗ ପ୍ରତି ରହିଥିବା ଆସକ୍ତି ନିବୃତ୍ତ ହୁଏ ନାହିଁ; କିନ୍ତୁ ପରମାତ୍ମାଙ୍କୁ ସାକ୍ଷାତକାର କରିଥବାରୁ ଏହି ସ୍ଥିତ ପ୍ରଜ୍ଞ ପୁରୁଷଙ୍କର ଆସକ୍ତି ବି ନିବୃତ୍ତ ହୋଇଯାଇଥାଏ । 2.59",

    "ହେ ଅର୍ଜୁନ ! ଆସକ୍ତି ନାଶ ହୋଇ ନ ଥିବାରୁ ଏହି ପ୍ରମଥନ ସ୍ୱଭାବବିଶିଷ୍ଟ  ଇନ୍ଦ୍ରିୟ ମାନେ ଯତ୍ନଶୀଳ ଶୀତଳ ବୁଦ୍ଧିମାନ ପୁରୁଷ ଙ୍କର ମନକୁ ମଧ୍ୟ ବଳପୂର୍ବକ ହରଣ କରିନିଅନ୍ତି । 2.60",

    "ତେଣୁ ସାଧକଙ୍କ ପକ୍ଷରେ ଏହା ଆବଶ୍ୟକ ଯେ, ତାଙ୍କୁ ସକଳ ଇନ୍ଦ୍ରିୟ ବଶ କରି, ସମାହିତ ଚିତ୍ତ ହୋଇ, ମୋର ପରାୟଣ ହୋଇ ଧ୍ୟାନ ରେ ବସିବାକୁ ପଡ଼ିବ; କାରଣ ଯେଉଁ ପୁରୁଷଙ୍କ ଇନ୍ଦ୍ରିୟମାନେ ବଶୀଭୂତ ହୋଇଥା'ନ୍ତି, ତାଙ୍କର ବୁଦ୍ଧି ସ୍ଥିର ହୋଇଥାଏ । 2.61",

    "ବିଷୟଚିନ୍ତନ କରୁଥିବା ପୁରୁଷ ର ସେହି ବିଷୟମାନଙ୍କରେ ଆସକ୍ତି ହୋଇଯାଏ; ଆସକ୍ତିରୁ ସେହି ବିଷୟମାନଙ୍କ ପ୍ରତି କାମନା ଉତ୍ପନ୍ନ ହୋଇଯାଏ ଏବଂ କାମନାରେ ବାଧା ଉପୁଜିଲେ କ୍ରୋଧ ଜାତ ହୋଇଯାଏ । 2.62",

    "କ୍ରୋଧରୁ ଅତ୍ୟନ୍ତ ମୂଢଭାବ ଉତ୍ପନ୍ନ ହୋଇଯାଏ; ମୂଢଭାବରୁ ସ୍ମୃତିଭ୍ରମ ହୋଇଯାଏ; ସ୍ମୃତି ବିଭ୍ରମ ହେବାରୁ ବୁଦ୍ଧି ଅର୍ଥାତ୍ ଜ୍ଞାନଶକ୍ତି ନାଶ ହୋଇଯାଏ ଏବଂ ବୁଦ୍ଧିନାଶ  ହେବାରୁ ସେହି ନିଜର ସ୍ଥିତିରୁ ଖସିପଡେ । 2.63",

    "କିନ୍ତୁ ଅନ୍ତଃକରଣ ନିଜର ଅଧୀନ କରିଥିବା ସାଧକ ନିଜ ବଶରେ ରଖିଥିବା ରାଗଦ୍ଵେଷରହିତ ଇନ୍ଦ୍ରିୟ ମାନଙ୍କ ଦ୍ଵାରା ବିଷୟମାନଙ୍କରେ ବିଚରଣ କରୁଥାଇ ମଧ୍ୟ ଅନ୍ତଃକରଣର ପ୍ରସନ୍ନତା  ଲାଭ କରନ୍ତି । 2.64",

    "ଅନ୍ତଃକରଣର ପ୍ରସନ୍ନତା ଆସିବା ଫଳରେ ତାଙ୍କର ସମସ୍ତ ଦୁଃଖ ବିନାଶ ହୋଇଯାଏ ଏବଂ ସେହି ପ୍ରସନ୍ନଚିତ୍ତ କର୍ମଯୋଗୀଙ୍କର ବୁଦ୍ଧି ଶୀଘ୍ର ହିଁ ସକଳ ଦିଗରୁ ଅପସରି ଆସି ଏକ ପରମାତ୍ମାଙ୍କ ଠାରେ ଭଲଭାବରେ ସ୍ଥିର ହୋଇଯାଏ । 2.65",

    "ମନ ଓ ଇନ୍ଦ୍ରିୟମାନଙ୍କୁ ଜୟ କରି ନ ଥିବା ମନୁଷ୍ୟ ର ନିଶ୍ଚୟାତ୍ମିକା ବୁଦ୍ଧି ହୁଏ ନାହିଁ ଏବଂ ସେପରି ଅଯୁକ୍ତ ମନୁଷ୍ୟର ଅନ୍ତଃକରଣରେ ଆସ୍ତିକଭାବନା ବି ଆସେ ନାହିଁ; ଆସ୍ତିକ-ଭାବନାହୀନ ମନୁଷ୍ୟକୁ   ଶାନ୍ତି ମିଳେ ନାହିଁ, ଶାନ୍ତିରହିତ ମନୁଷ୍ୟକୁ ସୁଖ ବା କେଉଁଠୁ ମିଳିବ ?  2.66",

    "କାରଣ ବାୟୁ ଯେପରି ଜଳରେ ଚାଲୁଥିବା ନୌକାକୁ ହରଣ କରିନିଏ, ବିଷୟରେ ବିଚରଣ କରୁଥିବା ଇନ୍ଦ୍ରିୟ ମାନଙ୍କ ମଧ୍ୟରୁ ଯେଉଁ ଇନ୍ଦ୍ରିୟ ଟି ସହିତ ମନ ରହିଥାଏ, ସେହି ଗୋଟିକ ଇନ୍ଦ୍ରିୟ ବି ସେହିପରି ଉକ୍ତ ଅଯୁକ୍ତ ମନୁଷ୍ୟର ବୁଦ୍ଧି କୁ ହରଣ କରିନିଏ । 2.67",

    "ତେଣୁ ହେ ମହାବାହୁ ! ଯେଉଁ ପୁରୁଷଙ୍କ ଇନ୍ଦ୍ରିୟମାନେ ଇନ୍ଦ୍ରିୟମାନଙ୍କର  ବିଷୟମାନଙ୍କ ଠାରୁ ସକଳପ୍ରକାରେ  ନିଗୃହୀତ ହୋଇଯାଇଥା'ନ୍ତି, ତାହାଙ୍କର ବୁଦ୍ଧି ସ୍ଥିର ଅଟେ । 2.68",

    "ଯେଉଁ ପରମାନନ୍ଦପ୍ରାପ୍ତି ସକଳ ପ୍ରାଣୀଙ୍କ ନିମନ୍ତେ ରାତ୍ରି-ସମାନ, ସେହି ନିତ୍ୟଜ୍ଞାନସ୍ୱରୂପ ପରମାନନ୍ଦ ପ୍ରାପ୍ତି ରେ ସ୍ଥିତ ପ୍ରଜ୍ଞ ଯୋଗୀ ଜାଗ୍ରତ ରହନ୍ତି ଏବଂ ଯେଉଁ ନାଶବାନ୍ ସାଂସାରିକ ସୁଖଲାଭରେ ସକଳ ପ୍ରାଣୀ ଜାଗ୍ରତ ରହନ୍ତି, ପରମାତ୍ମତତ୍ତ୍ୱ ଜାଣିଥିବା ମୁନିଙ୍କ ନିମନ୍ତେ ତାହା ରାତ୍ରି ସମାନ । 2.69",

    "ନାନାନଦୀର ଜଳ ଯେପରି ସକଳ ଦିଗରୁ ପରିପୂର୍ଣ୍ଣ ଅଚଳ-ପ୍ରତିଷ୍ଠାଯୁକ୍ତ ସମୁଦ୍ରକୁ ବିଚଳିତ ନ କରି ତା' ଭିତରେ ପ୍ରବେଶ କରେ, ସକଳ ଭୋଗ ସେହିପରି ଯେଉଁ ସ୍ଥିତପ୍ରଜ୍ଞ ପୁରୁଷଙ୍କ ଠାରେ କୌଣସି ପ୍ରକାର ବିକାର ଉତ୍ପନ୍ନ ନ କରି ପ୍ରବେଶ  କରନ୍ତି, ସେହି ପୁରୁଷ ପରମ ଶାନ୍ତି ପ୍ରାପ୍ତ ହୁଅନ୍ତି । ଭୋଗ ଚାହୁଁଥିବା ଲୋକ ତାହା ପାଏ ନାହିଁ ।  2.70",

    "ଯେଉଁ ପୁରୁଷ ସମସ୍ତ କାମନା ତ୍ୟାଗ କରି ମମତ୍ୱରହିତ, ଅହଙ୍କାର ରହିତ ଏବଂ ସ୍ପୃହାରହିତ ହୋଇ ବିଚରଣ କରନ୍ତି, ସେ ହିଁ ଶାନ୍ତି ପ୍ରାପ୍ତ ହୁଅନ୍ତି ଅର୍ଥାତ୍ ଶାନ୍ତି ଲାଭ କରନ୍ତି । 2.71",

    "ହେ ଅର୍ଜୁନ ! ଏହା ହେଉଛି ବ୍ରହ୍ମକୁ ପ୍ରାପ୍ତ ହୋଇଥିବା ପୁରୁଷଙ୍କ ସ୍ଥିତି । ଏହାକୁ ପ୍ରାପ୍ତ ହୋଇ ଯୋଗୀ କେବେହେଲେ ମୋହିତ ହୁଅନ୍ତି ନାହିଁ ଏବଂ ଅନ୍ତକାଳରେ ମଧ୍ୟ ଏହି ବ୍ରାହ୍ମୀ ସ୍ଥିତିରେ ସ୍ଥିତ ହୋଇ ସେ ବ୍ରହ୍ମାନନ୍ଦ ପ୍ରାପ୍ତ ହୁଅନ୍ତି । 2.72",

    "ଏହି ପ୍ରକାର ଓଁ ତତ୍ ସତ୍ -- ଏହି ଭଗବତ୍ ନାମର ଉଚ୍ଚାରଣପୂର୍ବକ ବ୍ରହ୍ମବିଦ୍ୟା ଓ ଯୋଗଶାସ୍ତ୍ରମୟ ଶ୍ରୀମଦ୍ଭଗବଦ୍ଗୀତା ଉପନିଷଦରୂପେ ଶ୍ରୀକୃଷ୍ଣ ଏବଂ ଅର୍ଜୁନଙ୍କ ସମ୍ବାଦରେ 'ସାଂଖ୍ଯଯୋଗନାମକ' ଦ୍ବିତୀୟ ଅଧ୍ୟାୟ ପୂର୍ଣ୍ଣ ହେଲା"
  ],
  "adhyaya-3" : [
    "ଅର୍ଜୁନ ପଚାରିଲେ- ହେ ଜନାର୍ଦ୍ଦନ ! ଯଦି ଆପଣଙ୍କ ମତରେ କର୍ମ ଅପେକ୍ଷା ଜ୍ଞାନ ଶ୍ରେଷ୍ଠ, ତେବେ ହେ କେଶବ ! ମୋତେ କାହିଁକି ଭୟଙ୍କର କର୍ମରେ ଲଗାଉଛନ୍ତି ? 3.1",

    "ଆପଣ ଗୋଳିଆମିଶା ପରି ଲାଗୁଥିବା କଥାଦ୍ୱାରା ମୋର ବୁଦ୍ଧିକୁ, ମୋହିତ ପ୍ରାୟ କରିଦେଉଛନ୍ତି; ତେଣୁ ନିଶ୍ଚୟ କରି ଗୋଟିଏ କଥା କହନ୍ତୁ, ଯାହାଦ୍ଵାରା କି ମୁଁ କଲ୍ୟାଣ ପ୍ରାପ୍ତ ହେବି। 3.2",

    "ଶ୍ରୀ ଭଗବାନ୍ କହିଲେ- ହେ ନିଷ୍ପାପ ଅର୍ଜୁନ! ଏହି ଲୋକରେ ଦୁଇ ପ୍ରକାର ନିଷ୍ଠା ହୁଏ ବୋଲି ମୁଁ ଆଗରୁ କହିସାରିଛି। ତନ୍ମଧ୍ୟରୁ ସାଂଖ୍ୟ ଯୋଗୀମାନଙ୍କର ନିଷ୍ଠା ଜ୍ଞାନଯୋଗ ଦ୍ୱାରା ଏବଂ  ଯୋଗୀମାନଙ୍କର ନିଷ୍ଠା କର୍ମଯୋଗ ଦ୍ୱାରା ହୋଇଥାଏ। 3.3",

    "ମନୁଷ୍ୟ କର୍ମାଚରଣ ନ କରି ନିଷ୍କର୍ମତା ବା ଯୋଗନିଷ୍ଠା ପାଇପାରେ ନାହିଁ, କି କେବଳ କର୍ମ ତ୍ୟାଗ କରିଦେଇ ସିଦ୍ଧି ଅର୍ଥାତ୍ ସାଂଖ୍ୟନିଷ୍ଠା ପାଇପାରେ ନାହିଁ । 3.4",

    "ଏହା ନିଃସନ୍ଦେହ ଯେ, କୌଣସି ମନୁଷ୍ୟ କୌଣସି ସମୟରେ କ୍ଷଣେ ସୁଦ୍ଧା କର୍ମ ନ କରି ରହିପାରିବ ନାହିଁ। କାରଣ ସମସ୍ତ ପ୍ରାଣୀ ପ୍ରକୃତିରୁ ଉତ୍ପନ୍ନ ଗୁଣମାନଙ୍କ ଦ୍ୱାରା ପରବଶ ହୋଇ କର୍ମ କରିବାକୁ ବାଧ୍ୟ ହୁଅନ୍ତି। 3.5",

    "ଯେଉଁ ମୂଢବୁଦ୍ଧି ମନୁଷ୍ୟ ସମସ୍ତ ଇନ୍ଦ୍ରିୟଙ୍କୁ ବଳପୂର୍ବକ ରୁଦ୍ଧ କରିଦେଇ ମନ ଦ୍ଵାରା ସେହି ଇନ୍ଦ୍ରିୟମାନଙ୍କର ବିଷୟକୁ ଚିନ୍ତନ କରୁଥାଏ, ସେ ମିଥ୍ୟାଚାରୀ ଅର୍ଥାତ୍ ଦମ୍ଭୀ ବୋଲି କଥିତ ହୁଏ। 3.6",

    "କିନ୍ତୁ ହେ ଅର୍ଜୁନ! ଯେଉଁ ପୁରୁଷ ମନ ଦ୍ୱାରା ଇନ୍ଦ୍ରିୟମାନଙ୍କୁ ବଶ କରି ଅନାସକ୍ତ ହୋଇ ସମସ୍ତ ଇନ୍ଦ୍ରିୟ ଦ୍ୱାରା କର୍ମଯୋଗ ଆଚରଣ କରନ୍ତି, ସେ ଶ୍ରେଷ୍ଠ ଅଟନ୍ତି। 3.7",

    "ତୁମେ ଶାସ୍ତ୍ରବିହିତ କର୍ତ୍ତବ୍ଯ-କର୍ମ କର; କାରଣ କର୍ମ ନ କରିବା ଠାରୁ କର୍ମ କରିବା ଶ୍ରେଷ୍ଠ; ତଥା କର୍ମ ନ କଲେ ତୁମର ଶରୀର ଶରୀରନିର୍ବାହ ମଧ୍ୟ ସିଦ୍ଧ ହୋଇପାରିବ ନାହିଁ। 3.8",

    "ଯଜ୍ଞ ପାଇଁ କରାଯାଉଥିବା କର୍ମ ଛଡ଼ା ଅନ୍ୟ କର୍ମ ମାନଙ୍କରେ ଲାଗି ରହିବା ଫଳରେ ହିଁ ଏହି ମନୁଷ୍ୟ ସମୁଦାୟ କର୍ମ ଦ୍ୱାରା ବାନ୍ଧି ହୁଅନ୍ତି। ତେଣୁ ହେ ଅର୍ଜୁନ ! ତୁମେ ଆସକ୍ତିରହିତ ହୋଇ କେବଳ ଯଜ୍ଞ ନିମିତ୍ତ ହିଁ ଭଲଭାବରେ କର୍ତ୍ତବ୍ଯ କର୍ମ ଆଚରଣ କର।  3.9",

    "ପ୍ରଜାପତି ବ୍ରହ୍ମା କଳ୍ପର ପ୍ରାରମ୍ଭରେ ଯଜ୍ଞ ସହିତ ପ୍ରଜାମାନଙ୍କୁ ରଚନା କରି ସେମାନଙ୍କୁ କହିଥିଲେ, 'ତୁମ୍ଭେମାନେ ଏହି ଯଜ୍ଞ ଦ୍ବାରା ବୃଦ୍ଧି ପ୍ରାପ୍ତ ହୁଅ ଏବଂ ଏହି ଯଜ୍ଞ ତୁମ୍ଭମାନଙ୍କର ବାଞ୍ଛିତଭୋଗପ୍ରଦାୟକ ହେଉ'। 3.10",

    "ତୁମ୍ଭେମାନେ ଏହି ଯଜ୍ଞ ଦ୍ବାରା ଦେବତାମାନଙ୍କୁ ଉନ୍ନତ କର ଏବଂ ସେହି ଦେବତାମାନେ ତୁମକୁ ଉନ୍ନତ କରନ୍ତୁ । ଏହିଭଳି ନିଃସ୍ୱାର୍ଥଭାବରେ ଏକ ଅନ୍ୟକୁ ଉନ୍ନତ କରିବା ଦ୍ୱାରା ତୁମ୍ଭେମାନେ ପରମକଲ୍ୟାଣ ପ୍ରାପ୍ତ ହୋଇଯିବ। 3.11",

    "ଯଜ୍ଞ ଦ୍ବାରା ସଂବର୍ଦ୍ଧିତ ଦେବତାମାନେ ତୁମକୁ ବିନା ପ୍ରାର୍ଥନା ରେ  ବାଞ୍ଛିତ ଭୋଗ ନିଶ୍ଚୟ ପ୍ରଦାନ କରି ଚାଲିବେ।' ସେହି ଦେବତାମାନଙ୍କ ଦ୍ଵାରା ଏହିପରି ପ୍ରଦତ୍ତ ଭୋଗ ପଦାର୍ଥ ଗୁଡ଼ିକୁ ଯେଉଁ ମନୁଷ୍ୟ ସେମାନଙ୍କୁ ସମର୍ପଣ ନ କରି ସ୍ୱୟଂ ଭୋଗ କରେ, ସେ ଚୋର ହିଁ ଅଟେ। 3.12",

    "ଯଜ୍ଞ ରୁ ବଳି ଥିବା ଅନ୍ନ ଭୋଜନ କରୁଥିବା ଶ୍ରେଷ୍ଠ ପୁରୁଷ ମାନେ ସମସ୍ତ ପାପ ରୁ ମୁକ୍ତ ହୋଇଯା'ନ୍ତି ଏବଂ ଯେଉଁ ପାପୀ ଲୋକମାନେ ନିଜର ଶରୀର ଭରଣପୋଷଣ ପାଇଁ ଅନ୍ନ ରନ୍ଧନ କରନ୍ତି, ସେମାନେ ପାପ ହିଁ ଭୋଜନ କରନ୍ତି । 3.13",

    "ସମସ୍ତ ପ୍ରାଣୀ ଅନ୍ନରୁ ଉତ୍ପନ୍ନ ହୁଅନ୍ତି; ଅନ୍ନ ର ଉତ୍ପତ୍ତି ବୃଷ୍ଟିରୁ ; ବୃଷ୍ଟି ଯଜ୍ଞରୁ ହୁଏ ଏବଂ ଯଜ୍ଞ ବିହିତ କର୍ମ ରୁ ଉତ୍ପନ୍ନ ହୁଏ । କର୍ମ ସମୁଦାୟ ବେଦରୁ ଉତ୍ପନ୍ନ ହୁଏ ଏବଂ ବେଦ ଅବିନାଶୀ ପରମାତ୍ମାଙ୍କ ଠାରୁ ଉତ୍ପନ୍ନ ହୁଏ ବୋଲି ଜାଣ । ତେଣୁ ସିଦ୍ଧ ହେଲା ଯେ, ସର୍ବବ୍ୟାପୀ ପରମ ଅକ୍ଷର ପରମାତ୍ମା ସଦାସର୍ବଦା ଯଜ୍ଞରେ ପ୍ରତିଷ୍ଠିତ। 3.14-3.15",

    "ସମସ୍ତ ପ୍ରାଣୀ ଅନ୍ନରୁ ଉତ୍ପନ୍ନ ହୁଅନ୍ତି; ଅନ୍ନ ର ଉତ୍ପତ୍ତି ବୃଷ୍ଟିରୁ ; ବୃଷ୍ଟି ଯଜ୍ଞରୁ ହୁଏ ଏବଂ ଯଜ୍ଞ ବିହିତ କର୍ମ ରୁ ଉତ୍ପନ୍ନ ହୁଏ । କର୍ମ ସମୁଦାୟ ବେଦରୁ ଉତ୍ପନ୍ନ ହୁଏ ଏବଂ ବେଦ ଅବିନାଶୀ ପରମାତ୍ମାଙ୍କ ଠାରୁ ଉତ୍ପନ୍ନ ହୁଏ ବୋଲି ଜାଣ । ତେଣୁ ସିଦ୍ଧ ହେଲା ଯେ, ସର୍ବବ୍ୟାପୀ ପରମ ଅକ୍ଷର ପରମାତ୍ମା ସଦାସର୍ବଦା ଯଜ୍ଞରେ ପ୍ରତିଷ୍ଠିତ। 3.14-3.15",

    "ହେ ପାର୍ଥ! ଯେଉଁ ମନୁଷ୍ୟ ଇହଲୋକରେ ପରମ୍ପରାକ୍ରମେ ପ୍ରଚଳିତ ଏହି ସୃଷ୍ଟିଚକ୍ର ଅନୁସାରେ ଆଚରଣ କରେ ନାହିଁ , ଇନ୍ଦ୍ରିୟମାନଙ୍କ ଦ୍ବାରା ଭୋଗ ଉପଭୋଗଗ କରୁଥିବା ସେହି ଅଘାୟୁ (ପାପୀ) ମନୁଷ୍ୟ ସଂସାରରେ ଅକାରଣେ ବଞ୍ଚିରହେ। 3.16",

    "କିନ୍ତୁ ଯେଉଁ ମନୁଷ୍ୟ ନିଜଠାରେ ହିଁ ରମଣ କରେ ଏବଂ ନିଜ ମଧ୍ୟରେ ହିଁ ତୃପ୍ତ ଓ ସନ୍ତୁଷ୍ଟ ରହେ , ତା ' ପାଇଁ କୌଣସି କର୍ତ୍ତବ୍ୟ ନାହିଁ। 3.17",

    "ସେହି(କର୍ମଯୋଗ ଦ୍ବାରା ସିଦ୍ଧିପ୍ରାପ୍ତ) ମହାପୁରୁଷଙ୍କର ଏ ସଂସାରରେ କୌଣସି କର୍ମ କରିବାରେ ପ୍ରୟୋଜନ ନ ଥାଏ କି କୌଣସି କର୍ମ ନ କରିବାରେ ମଧ୍ୟ ପ୍ରୟୋଜନ ନ ଥାଏ, ଏବଂ ସମସ୍ତ ପ୍ରାଣୀଙ୍କ ସହିତ( କୌଣସି ପ୍ରାଣୀ ସଙ୍ଗେ ) ତାଙ୍କର କିଞ୍ଚିନ୍ମାତ୍ର ବି ସ୍ବାର୍ଥଗତ ସମ୍ବନ୍ଧ ନ ଥାଏ। 3.18",

    "ତେଣୁ ତୁମେ ନିରନ୍ତର ଅନାସକ୍ତ ହୋଇ ସୁଚାରୁରୂପେ କର୍ତ୍ତବ୍ୟ-କର୍ମ ଆଚରଣ କର, କାରଣ ଅନାସକ୍ତିପୂର୍ବକ କର୍ମ କରି ମନୁଷ୍ୟ ପରମାତ୍ମାଙ୍କୁ ପ୍ରାପ୍ତ ହୁଏ। 3.19",

    "ରାଜା ଜନକଙ୍କ ପରି ଅନେକ ମହାପୁରୁଷ ମଧ୍ୟ କର୍ମ (କର୍ମଯୋଗ) ଦ୍ବାରା ହିଁ ପରମ ସିଦ୍ଧି ଲାଭ କରିଥିଲେ। ତେଣୁ ଲୋକସଂଗ୍ରହ ଦୃଷ୍ଟିରୁ ମଧ୍ୟ ତୁମେ ନିଷ୍କାମ ଭାବରେ କର୍ମ ଅବଶ୍ୟ କରିବା ଉଚିତ। 3.20",

    "ଶ୍ରେଷ୍ଠ ମନୁଷ୍ୟ ଯେଉଁ ଯେଉଁ ଆଚରଣ କରନ୍ତି, ଅନ୍ୟ ଲୋକେ ସେହି ସେହି ଆଚରଣ କରନ୍ତି। ସେ ଯାହା କିଛି ପ୍ରମାଣ କରିଦିଅନ୍ତି, ଅନ୍ୟମାନେ ତାହାର ଅନୁବର୍ତ୍ତନ କରନ୍ତି। 3.21",

    "ହେ ପାର୍ଥ! ତ୍ରିଲୋକରେ ମୋର କୌଣସି କର୍ତ୍ତବ୍ୟ ନାହିଁ କିଂବା କୌଣସି ପ୍ରାପ୍ତବ୍ୟ ବସ୍ତୁ ମୋତେ ଅପ୍ରାପ୍ତ ନାହିଁ , ତଥାପି ମୁଁ କର୍ତ୍ତବ୍ୟ-କର୍ମରେ ନିୟୋଜିତ ରହୁଛି।  3.22",

    "ହେ ପାର୍ଥ! ଯଦି ମୁଁ କଦାଚିତ୍ ସାବଧାନ ହୋଇ କର୍ତ୍ତବ୍ୟ-କର୍ମ ନ କରେ [ତେବେ ବଡ କ୍ଷତି ହେବ, କାରଣ ] ମନୁଷ୍ୟମାନେ ସର୍ବଭାବରେ ମୋର ମାର୍ଗ ଅନୁସରଣ କରନ୍ତି।  3.23",

    "ତେଣୁ ଯଦି ମୁଁ କର୍ମ ନ କରିବି, ତେବେ ଲୋକେ ନଷ୍ଟଭ୍ରଷ୍ଟ ହୋଇଯିବେ ଏବଂ ମୁଁ ବର୍ଣ୍ଣସଙ୍କରତା ଉତ୍ପନ୍ନକାରୀ ତଥା ସମସ୍ତ ପ୍ରଜାଙ୍କ ବିନାଶକାରୀ ହୋଇଯିବି। 3.24",

    "ହେ ଭରତବଂଶଜ ଅର୍ଜୁନ! କର୍ମାସକ୍ତ ଅଜ୍ଞାନୀ ମନୁଷ୍ୟ ଯେଉଁପରି କର୍ମ କରନ୍ତି, ଅନାସକ୍ତ ତତ୍ତ୍ବଜ୍ଞ ମହାପୁରୁଷ ମଧ୍ୟ ଲୋକସଂଗ୍ରହାର୍ଥେ ସେହିପରି କର୍ମ କରନ୍ତି।  3.25",

    "ତତ୍ତ୍ବଜ୍ଞ ମହାପୁରୁଷ କର୍ମାସକ୍ତ ଅଜ୍ଞାନୀ ମନୁଷ୍ୟଙ୍କ ବୁଦ୍ଧିରେ ଭ୍ରମ ଉତ୍ପନ୍ନ ନ କରନ୍ତୁ, ବରଂ ନିଜେ ସମସ୍ତ କର୍ମ ଠିକ୍ ଭାବରେ କରିବା ସଙ୍ଗେ ସଙ୍ଗେ ଅନ୍ୟମାନଙ୍କ ଦ୍ବାରା ମଧ୍ୟ ସେହିପରି କରାନ୍ତୁ। 3.26",

    "ସମସ୍ତ କର୍ମ ସର୍ବତୋଭାବେ ପ୍ରକୃତିର ଗୁଣମାନଙ୍କ ଦ୍ବାରା କରାଯାଉଛି, କିନ୍ତୁ ଅହଂକାରରେ ମୋହିତ ଅଜ୍ଞାନୀ ମନୁଷ୍ୟ ନିଜକୁ 'ମୁଁ କର୍ତ୍ତା' ବୋଲି ମନେକରେ। 3.27",

    "କିନ୍ତୁ ହେ ମହାବାହୁ! ଗୁଣ-ବିଭାଗ ଓ କର୍ମ-ବିଭାଗକୁ ତତ୍ତ୍ବତଃ ଜାଣୁଥିବା ମହାପୁରୁଷ ଗୁଣଗୁଡିକ ହିଁ ଗୁଣମଧ୍ୟରେ ସବୁ କାର୍ଯ୍ୟ କରୁଛନ୍ତି ବୋଲି ମନେକରି ତହିଁରେ ଆସକ୍ତ ହୁଅନ୍ତି ନାହିଁ। 3.28",

    "ପ୍ରକୃତିଜନିତ ଗୁଣରେ ଅତ୍ୟନ୍ତ ମୋହିତ ଅଜ୍ଞାନୀ ମନୁଷ୍ୟ ଗୁଣ ଓ କର୍ମରେ ଆସକ୍ତ ରହନ୍ତି। ସେହି ଅଳ୍ପଜ୍ଞ ତଥା ମନ୍ଦବୁଦ୍ଧି ଅଜ୍ଞାନୀମାନଙ୍କୁ ଜ୍ଞାନୀ ମନୁଷ୍ୟ ବିଚଳିତ ନ କରିବା ଉଚିତ। 3.29",

    "ତୁମେ ବିବେକବତୀ ବୁଦ୍ଧି ଦ୍ବାରା ସମସ୍ତ କର୍ତ୍ତବ୍ୟ-କର୍ମ ମୋତେ ଅର୍ପଣ କରି କାମନା, ମମତା ଓ ସନ୍ତାପରହିତ ହୋଇ ଯୁଦ୍ଧରୂପୀ କର୍ତ୍ତବ୍ୟ-କର୍ମ କର। 3.30",

    "ଯେଉଁମାନେ ଦୋଷଦୃଷ୍ଟିରହିତ ହୋଇ ଶ୍ରଦ୍ଧାପୂର୍ବକ ସର୍ବଦା ମୋର ଏହି( ପୂର୍ବ ଶ୍ଳୋକରେ ବର୍ଣ୍ଣିତ) ମତ ଅନୁସରଣ କରନ୍ତି, ସେମାନେ ମଧ୍ଯ ସମସ୍ତ କର୍ମବନ୍ଧନରୁ ମୁକ୍ତ ହୋଇଯାଆନ୍ତି। 3.31",

    "କିନ୍ତୁ ଯେଉଁ ମନୁଷ୍ଯ ମୋର ଏହି ମତ ପ୍ରତି ଦୋଷଦୃଷ୍ଟି କରି ଏହାକୁ ପାଳନ କରନ୍ତି ନାହିଁ, ସେହି ସର୍ବଜ୍ଞାନବିମୂଢ ଅବିବେକିମାନେ ନଷ୍ଟ ହୋଇଯାଇଛନ୍ତି ବୋଲି ଜାଣ ଅର୍ଥାତ୍ ସେମାନଙ୍କର ପତନ ଅବଶ୍ୟମ୍ଭାବୀ। 3.32",

    "ସମସ୍ତ ପ୍ରାଣୀ ପ୍ରକୃତିକୁ ପ୍ରାପ୍ତ ହୁଅନ୍ତି ଅର୍ଥାତ୍ ନିଜ ନିଜ ପ୍ରକୃତି ଅନୁସାରେ କାର୍ଯ୍ୟ କରନ୍ତି। ଏପରି କି ଜ୍ଞ।ନୀ ମହାପୁରୁଷ ମଧ୍ଯ ନିଜ ପ୍ରକୃତି ଅନୁସାରେ କାର୍ଯ୍ୟ କରନ୍ତି। ତେବେ ଏଥିରେ କାହାର ନିଗ୍ରହ(ହଠ) କଣ କାମ କରିବ ? 3.33",

    "ଇନ୍ଦ୍ରିୟର ଇନ୍ଦ୍ରିୟ ଅର୍ଥରେ (ପ୍ରତ୍ୟେକ ଇନ୍ଦ୍ରିୟର ନିଜ ନିଜ ବିଷୟରେ) ମନୁଷ୍ୟର ରାଗ ଓ ଦ୍ବେଷ ବ୍ୟବସ୍ଥାନୁସାରେ (ଅନୁକୂଳତା ଓ ପ୍ରତିକୂଳତାକୁ ନେଇ) ଅବସ୍ଥିତ। ମନୁଷ୍ୟ ଏ ଦୁଇଟିର ବଶୀଭୂତ ହେବା ଅନୁଚିତ। କାରଣ ଏ ଦୁଇଟି ତାହାର ପରମାର୍ଥିକ ମାର୍ଗରେ ବିଘ୍ନକାରୀ ଶତ୍ରୁ ଅଟନ୍ତି। 3.34",

    "ଉତ୍ତମରୂପେ ଆଚରିତ ପରଧର୍ମ ଅପେକ୍ଷା ସ୍ବଳ୍ପଗୁଣଯୁକ୍ତ ସ୍ବଧର୍ମ ଶ୍ରେଷ୍ଠ। ସ୍ବଧର୍ମରେ ନିଧନ ବରଂ କଲ୍ୟାଣକାରକ, କିନ୍ତୁ ପରଧର୍ମ ଭୟାବହ। 3.35",

    "ଅର୍ଜୁନ କହିଲେ - ହେ ବାର୍ଷ୍ଣେୟ ! ଅନିଚ୍ଛା ସତ୍ତ୍ବେ କେହି ବଳପୂର୍ବକ ନିୟୋଜିତ କଲାପରି କାହା ଦ୍ବାରା ପ୍ରେରିତ ହୋଇ ମନୁଷ୍ୟ ପାପାଚରଣ କରେ? 3.36",

    "ଶ୍ରୀଭଗବାନ କହିଲେ - ରଜୋଗୁଣରୁ ଉଦ୍ଭବ ଏହି କାମ ଅର୍ଥାତ୍ କାମନା ହିଁ ପାପର କାରଣ। ଏହି କାମନା କ୍ରୋଧରେ ପରିଣତ ହୋଇଯାଏ। ଏହା ବହୁଗ୍ରାସୀ ଓ ମହାପାପୀ । ଏ ବିଷୟରେ ତୁମେ ଏହାକୁ ଶତ୍ରୁ ବୋଲି ଜାଣ। 3.37",

    "ଯେପରି ଧୂଆଁ ଦ୍ବାରା ଅଗ୍ନି ଓ ମଳି ଦ୍ବାରା ଦର୍ପଣ ଆବୃତ ହୋଇଯାଏ ଏବଂ ଯେପରି ଜରାୟୁ ଦ୍ବାରା ଗର୍ଭ ଆବୃତ ହୋଇଥାଏ, ଠିକ୍ ସେହିପରି କାମନା ଦ୍ବାରା ଜ୍ଞାନ ( ବିବେକ ) ଆବୃତ ହୋଇଛି । 3.38",

    "ହେ କୁନ୍ତୀନନ୍ଦନ! ଅଗ୍ନି ସଦୃଶ ଦୁଷ୍ପୂରଣୀୟ ତଥା ବିବେକୀମାନଙ୍କର ନିତ୍ୟ ବୈରୀ ଏହି କାମନା ଦ୍ବାରା ମନୁଷ୍ୟର ଜ୍ଞାନ ଅର୍ଥାତ୍ ବିବେକ ଆଛାଦିତ ହୋଇ ରହିଛି। 3.39",

    "ଇନ୍ଦ୍ରିୟଗଣ, ମନ ଓ ବୁଦ୍ଧି କାମନାର ବାସସ୍ଥାନ ବୋଲି କୁହାଯାଇଛି। କାମନା ହିଁ ଏମାନଙ୍କ ଦ୍ବାରା (ଇନ୍ଦ୍ରିୟ, ମନ ଓ ବୁଦ୍ଧି ଦ୍ବାରା) ଜ୍ଞାନକୁ ଆଛାଦିତ କରି ଦେହାଭିମାନୀ ମନୁଷ୍ୟକୁ ମୋହିତ କରିଦିଏ। 3.40",

    "ତେଣୁ ହେ ଭରତଶ୍ରେଷ୍ଠ ଅର୍ଜୁନ! ତୁମେ ସର୍ବପ୍ରଥମେ ଇନ୍ଦ୍ରିୟଗଣଙ୍କୁ ନିୟମନ କରି ଜ୍ଞାନ ଓ ବିଜ୍ଞାନର ବିନାଶକାରୀ ମହାପାପୀ କାମକୁ ବଳପୂର୍ବକ ବିନାଶ କର।  3.41",

    "ଇନ୍ଦ୍ରିୟମାନଙ୍କୁ ( ସ୍ଥୂଳଶରୀର ଅପେକ୍ଷା ) ପର ( ଶ୍ରେଷ୍ଠ , ସବଳ, ପ୍ରକାଶକ, ବ୍ୟାପକ ତଥା ସୂକ୍ଷ୍ମ ) କୁହାଯାଏ । ଇନ୍ଦ୍ରିୟମାନଙ୍କ ଅପେକ୍ଷା ମନ ହେଉଛି ପର, ମନ ଅପେକ୍ଷା ବୁଦ୍ଧି ପର ଏବଂ ବୁଦ୍ଧି ଅପେକ୍ଷା  ସେ(କାମ) ହେଉଛି ପର। 3.42",

    "ଏହି ପ୍ରକାରେ ବୁଦ୍ଧିରୁ ପର (କାମ)କୁ ଜାଣି, ନିଜେ ନିଜକୁ ବଶୀଭୂତ କରି ହେ ମହାବାହୁ ! ତୁମେ କାମରୂପକ ଏହି ଦୂର୍ଜୟ ଶତ୍ରୁକୁ ମାରିଦିଅ।  3.43",

    "ଏହି ପ୍ରକାର ଓଁ ତତ୍ ସତ୍ -- ଏହି ଭଗବତ୍ ନାମର ଉଚ୍ଚାରଣପୂର୍ବକ ବ୍ରହ୍ମବିଦ୍ୟା ଓ ଯୋଗଶାସ୍ତ୍ରମୟ ଶ୍ରୀମଦ୍ଭଗବଦ୍ଗୀତା ଉପନିଷଦରୂପେ ଶ୍ରୀକୃଷ୍ଣ ଏବଂ ଅର୍ଜୁନଙ୍କ ସମ୍ବାଦରେ 'କର୍ମଯୋଗନାମକ' ତୃତୀୟ ଅଧ୍ୟାୟ ପୂର୍ଣ୍ଣ ହେଲା"
  ],
  "adhyaya-4" : [
    "ଶ୍ରୀଭଗବାନ କହିଲେ - ମୁଁ ଏହି ଅବିନାଶୀ ଯୋଗ( କର୍ମଯୋଗ ) ସୂର୍ଯ୍ୟଙ୍କୁ କହିଥିଲି । ସୂର୍ଯ୍ୟ ତାଙ୍କ ପୁତ୍ର ବିବସ୍ବତ ମନୁଙ୍କୁ ଏବଂ ମନୁ ତାଙ୍କ ପୁତ୍ର ରାଜା ଇକ୍ଷ୍ବାକୁଙ୍କୁ କହିଲେ। 4.1",

    "ହେ ପରନ୍ତପ! ଏହିପରି ପରମ୍ପରାକ୍ରମେ ପ୍ରାପ୍ତ ହୋଇଥିବା ଏହି କର୍ମଯୋଗକୁ ରାଜର୍ଷିମାନେ ଜାଣିଲେ। କିନ୍ତୁ ଦୀର୍ଘସମୟ ଅତିବାହିତ ହୋଇଯିବାରୁ ସେହି ଯୋଗ ଇହଲୋକରେ ଲୁପ୍ତପ୍ରାୟ ହୋଇଗଲା। 4.2",

    "ତୁମେ ମୋର ଭକ୍ତ ଏବଂ ପ୍ରିୟ ସଖା, ତେଣୁ ସେହି ପୁରାତନ ଯୋଗ ଆଜି ମୁଁ ତୁମକୁ କହିଦେଲି, କାରଣ ଏହା ଅତି ଉତ୍ତମ ରହସ୍ୟ। 4.3",

    "ଅର୍ଜୁନ କହିଲେ---ଆପଣଙ୍କ ଜନ୍ମ ତ ଏବେ ହୋଇଛି ଏବଂ ସୂର୍ଯ୍ୟଙ୍କ ଜନ୍ମ ତ ବହୁ ପୂର୍ବରୁ ହୋଇଛି, ତେଣୁ ଆପଣ ଯେ ସୃଷ୍ଟି ଆରମ୍ଭରେ ସୂର୍ଯ୍ୟଙ୍କୁ ଏହି ଯୋଗ ବିଷୟରେ କହିଥିଲେ, ଏହା ମୁଁ କିପରି ଜାଣିବି ( ବିଶ୍ବାସ କରିବି )? 4.4",

    "ଶ୍ରୀଭଗବାନ କହିଲେ---ହେ ପରନ୍ତପ ଅର୍ଜୁନ ! ତୁମର ଓ ମୋର ଅନେକ ଜନ୍ମ ବିତିଗଲାଣି। ସେ ସବୁକୁ ମୁଁ ଜାଣେ, କିନ୍ତୁ ତୁମେ ଜାଣ ନାହିଁ। 4.5",

    "ମୁଁ ଅଜ ଓ ଅବିନାଶୀସ୍ବରୂପ ହୋଇଥିଲେ ମଧ୍ୟ ତଥା ପ୍ରାଣୀମାନଙ୍କର ଈଶ୍ବର ହୋଇଥିଲେ ମଧ୍ୟ ନିଜ ପ୍ରକୃତିକୁ ଅଧୀନ କରି ଆପଣା ଯୋଗମାୟା ବଳରେ ପ୍ରକଟ ହୁଏ। 4.6",

    "ହେ ଭରତବଂଶଜ ଅର୍ଜୁନ ! ଯେଉଁ ଯେଉଁ ସମୟରେ ଧର୍ମର ହାନି ହୁଏ ଓ ଅଧର୍ମ ବୃଦ୍ଧି ପାଏ, ସେତେବେଳେ ହିଁ ମୁଁ ନିଜକୁ ସାକାରରୂପରେ ପ୍ରକଟ କରେ।  4.7",

    "ସାଧୁ (ଭକ୍ତ) ଜନଙ୍କୁ ରକ୍ଷା କରିବା, ପାପାଚାରୀମାନଙ୍କୁ ବିନାଶ କରିବା ଏବଂ ଉତ୍ତମରୂପେ ଧର୍ମସ୍ଥାପନା କରିବା ନିମିତ୍ତ ମୁଁ ଯୁଗେ ଯୁଗେ ପ୍ରକଟ ହୋଇଥାଏ। 4.8",

    "ହେ ଅର୍ଜୁନ ! ମୋର ଜନ୍ମ ଓ କର୍ମ ଦିବ୍ୟ। ଯେଉଁ ମନୁଷ୍ୟ ତତ୍ତ୍ବତଃ ଏ କଥା (ମୋର ଜନ୍ମ ଓ କର୍ମକୁ) ଜାଣିଯାଏ ଅର୍ଥାକ୍ ଦୃଢଭାବେ ସ୍ବୀକାର କରେ, ସେ ଦେହତ୍ୟାଗ କରି ଆଉ ପୂନର୍ଜନ୍ମ ପାଏ ନାହିଁ, ବରଂ ମୋତେ ପାଏ। 4.9",

    "ରାଗ (ଆସକ୍ତି), ଭୟ ଓ କ୍ରୋଧ ବିବର୍ଜିତ, କେବଳ ମୋ ଠାରେ ତଲ୍ଲୀନଚିତ୍ତ, ମୋର ଆଶ୍ରିତ ତଥା ଜ୍ଞାନରୂପକ ତପରେ ପବିତ୍ର ହୋଇଥିବା ଅନେକ ଭକ୍ତ ମୋର ଭାବ ଅର୍ଥାତ୍ ସ୍ବରୂପକୁ ପ୍ରାପ୍ତ ହୋଇଛନ୍ତି। 4.10",

    "ହେ ପୃଥାନନ୍ଦନ ! ଯେଉଁ ଭକ୍ତମାନେ ମୋଠାରେ ଯେପରି ଶରଣାପନ୍ନ ହୁଅନ୍ତି, ମୁଁ ସେମାନଙ୍କୁ ସେହିପରି ଆଶ୍ରୟ ଦିଏ, କାରଣ ସମସ୍ତ ମନୁଷ୍ୟ ସର୍ବଭାବରେ ମୋର ମାର୍ଗ ଅନୁସରଣ କରନ୍ତି। 4.11",

    "କର୍ମଜନିତ ସିଦ୍ଧି (ଫଳ) ଇଛା କରୁଥିବା ମନୁଷ୍ୟ ଦେବଗଣଙ୍କର ଉପାସନା କରନ୍ତି, କାରଣ ମନୁଷ୍ୟଲୋକରେ କର୍ମଜନିତ ସିଦ୍ଧି ଶୀଘ୍ର ମିଳିଥାଏ। 4.12",

    "ଗୁଣ ଓ କର୍ମର ବିଭାଗପୂର୍ବକ ମୋ ଦ୍ବାରା ଚାରିବର୍ଣ୍ଣ ସୃଷ୍ଟ। ତାହାର (ସୃଷ୍ଟିରଚନା ଆଦିର) କର୍ତ୍ତା ହୋଇଥିଲେ ମଧ୍ୟ ମୋତେ ଅର୍ଥାତ୍ ଅବ୍ୟୟ ପରମେଶ୍ବରଙ୍କୁ ତୁମେ ଅକର୍ତ୍ତା ବୋଲି ଜାଣ ।  4.13",

    "କର୍ମଫଳରେ ମୋର ସ୍ପୃହା ନ ଥିବାରୁ ମୋତେ କର୍ମ ଲିପ୍ତ କରିପାରେ ନାହିଁ। ଯିଏ ମୋତେ ଏହିରୂପେ ତତ୍ତ୍ବତଃ ଜାଣିଯାଏ, ସେ ମଧ୍ୟ କର୍ମବନ୍ଧନରେ ପଡେ ନାହିଁ।  4.14",

    "ପୂର୍ବକାଳର ମୁମୁକ୍ଷୁମାନେ ମଧ୍ୟ ଏପରି ଜାଣି କର୍ମ କରି ଯାଇଛନ୍ତି, ଏଣୁ ତୁମେ ମଧ୍ୟ ପୂର୍ବଜଙ୍କ ଦ୍ବାରା ବହୁ ପୂର୍ବରୁ କରାଯାଇଥିବା କର୍ମଗୁଡିକୁ କର ଅର୍ଥାତ୍ ପୂର୍ବଜମାନେ ଯେପରି କରିଯାଇଛନ୍ତି, ସେହିପରି କର। 4.15",

    "କର୍ମ କ'ଣ ଏବଂ ଅକର୍ମ କ'ଣ -- ଏ ବିଷୟରେ ବିଦ୍ବାନ୍ ମଧ୍ୟ ମୋହିତ ହୋଇଯାଆନ୍ତି। ଏଣୁ ମୁଁ ତୁମକୁ କର୍ମର ତତ୍ତ୍ବ ସମ୍ୟକ୍ ଭାବେ କହିବି, ଯାହା ଜାଣିଲେ ତୁମେ ଅଶୁଭ( ସଂସାରବନ୍ଧନ)ରୁ ମୁକ୍ତ ହୋଇଯିବ। 4.16",

    "କର୍ମର ତତ୍ତ୍ବ ମଧ୍ୟ ଜାଣିବା ଉଚିତ, ଅକର୍ମର ତତ୍ତ୍ବ ମଧ୍ୟ ଜାଣିବା ଉଚିତ ଏବଂ ବିକର୍ମର ତତ୍ତ୍ବ ମଧ୍ୟ ଜାଣିବା ଉଚିତ, କାରଣ କର୍ମର ଗତି( ତତ୍ତ୍ବ) ଅତି ଗହନ ଅର୍ଥାତ୍ ବୁଝିବା ଦୁଷ୍କର। 4.17",

    "ଯେଉଁ ମନୁଷ୍ୟ କର୍ମରେ ଅକର୍ମ ଦେଖେ ଏବଂ ଅକର୍ମରେ କର୍ମ ଦେଖେ, ମନୁଷ୍ୟଙ୍କ ମଧ୍ୟରେ ସେ ହି ବୁଦ୍ଧିମାନ୍ ଏବଂ ସେ ହିଁ ଯୋଗୀ ଓ ସର୍ବକର୍ମକାରୀ (କୃତକୃତ୍ୟ)। 4.18",

    "ଯାହାର ସମସ୍ତ କର୍ମର ଆରମ୍ଭ ସଂକଳ୍ପ ଓ କାମନାରୁ ମୁକ୍ତ ତଥା ଯାହାର ସମସ୍ତ କର୍ମ ଜ୍ଞାନରୂପୀ ଅଗ୍ନିରେ ଭସ୍ମ ହୋଇସାରିଛି, ତାକୁ ଜ୍ଞାନୀଜନ ମଧ୍ୟ ପଣ୍ଡିତ (ବୁଦ୍ଧିମାନ୍) କହିଥାନ୍ତି। 4.19",

    "ଯେ କର୍ମର ଓ ଫଳର ଆସକ୍ତି ପରିତ୍ୟାଗକରି(ସଂସାର ଉପରେ) ଅନାଶ୍ରିତ ଓ ସଦାତୃପ୍ତ , ସେ ଉତ୍ତମରୂପେ କର୍ମରେ ପ୍ରବୃତ୍ତ ହୋଇଥିଲେ ମଧ୍ୟ ବସ୍ତୁତଃ କିଛି କରେ ନାହିଁ। 4.20",

    "ଯାହାାଙ୍କର ଶରୀର ଓ ଅନ୍ତଃକରଣ ଉତ୍ତମରୂପେ ବଶୀଭୂତ, ଯେ ସମସ୍ତ ସଂଗ୍ରହ ପରିତ୍ୟାଗ କରିଦେଇଛନ୍ତି, ସେପରି ଇଛାଶୂନ୍ୟ କର୍ମଯୋଗୀ ଯଦି କେବଳ ଶରୀର ସମ୍ବନ୍ଧୀୟ କିଛି କର୍ମ କରୁଥାନ୍ତି, ତେବେ ସେ ସେଥିପାଇଁ ପାପଭାଗୀ ହୁଅନ୍ତି ନାହିଁ। 4.21",

    "ଫଳେଚ୍ଛା ବିନା ଆପେ ଆପେ ଯାହାକିଛି ମିଳିଯାଏ ସେଥିରେ ଯେଉଁ କର୍ମଯୋଗୀ ସନ୍ତୁଷ୍ଟ ରହେ ଏବଂ ଯେ ଇର୍ଷ୍ୟାରହିତ, ଦ୍ବନ୍ଦ୍ବାତୀତ ତଥା ସିଦ୍ଧି-ଅସିଦ୍ଧିରେ ସମଭାବସମ୍ପନ୍ନ, ସେ କର୍ମ କରୁଥିଲେ ମଧ୍ୟ ତହିଁରେ ବାନ୍ଧିହୁଏ ନାହିଁ। 4.22",

    "ଯାହାଙ୍କର ଆସକ୍ତି ସର୍ବଥା ନଷ୍ଟ ହୋଇଯାଇଛି, ଯେ ମୁକ୍ତ ହୋଇସାରିଛନ୍ତି, ଯାହାଙ୍କର ବୁଦ୍ଧି ସ୍ବରୂପଭୂତ ଜ୍ଞାନରେ ପ୍ରତିଷ୍ଠିତ, କେବଳ ଯଜ୍ଞ ଉଦ୍ଦେଶ୍ୟରେ କର୍ମରତ, ସେପରି ମନୁଷ୍ୟଙ୍କର ସମସ୍ତ କର୍ମ ବିଲୀନ ହୋଇଯାଏ। 4.23",

    "ଯେଉଁ ଯଜ୍ଞରେ ଅର୍ପଣ ( ଅର୍ଥାତ୍ ସ୍ରୁକ୍ , ସୁବାଦି ଅର୍ପଣପାତ୍ର )ମଧ୍ୟ ବ୍ରହ୍ମ, ହବ୍ୟ ପଦାର୍ଥ ( ଅର୍ଥାତ୍ ତିଳ, ଯବ,ଘିଅ ଆଦି) ମଧ୍ୟ ବ୍ରହ୍ମ ଏବଂ ବ୍ରହ୍ମସ୍ବରୂପ କର୍ତ୍ତା ଦ୍ବାରା ବ୍ରହ୍ମରୂପକ ଅଗ୍ନିରେ ଆହୁତି ପ୍ରଦାନରୂପକ କ୍ରିୟା ମଧ୍ୟ ବ୍ରହ୍ମ, (ଏପରି ଯଜ୍ଞାନୁଷ୍ଠାନରତ) ଯେଉଁ ମନୁଷ୍ୟଙ୍କର ବ୍ରହ୍ମରେ ହିଁ କର୍ମ-ସମାଧି ହୋଇଛି, ତାଙ୍କ ଦ୍ବାରା ପ୍ରାପ୍ତବ୍ୟ ଫଳ ମଧ୍ୟ ବ୍ରହ୍ମ। 4.24",

    "ଅନ୍ୟ କେତେକ ଯୋଗୀଜନ ଦୈବ( ଭଗବଦର୍ପଣରୂପକ) ଯଜ୍ଞର ଅନୁଷ୍ଠାନ କରନ୍ତି ଏବଂ ଆଉ କେତେକ ଯୋଗୀଜନ ବ୍ରହ୍ମରୂପକ ଅଗ୍ନିରେ ବିଚାରରୂପକ ଯଜ୍ଞଦ୍ବାରା ହିଁ ଜୀବାତ୍ମାରୂପକ ଯଜ୍ଞର ଆହୁତି ଦିଅନ୍ତି (ଅର୍ଥାତ୍ ବିଚାର ଦ୍ବାରା ଜୀବାତ୍ମାକୁ ବ୍ରହ୍ମରେ ଲୀନ କରିଦିଅନ୍ତି)। 4.25",

    "ଅନ୍ୟ କେତେକ ଯୋଗୀ ଶ୍ରୋତ୍ରାଦି ସମସ୍ତ ଇନ୍ଦ୍ରିୟଙ୍କୁ ସଂଯମରୂପକ ଅଗ୍ନିରେ ଆହୁତି ଦିଅନ୍ତି ଏବଂ ଆଉ କେତେକ ଯୋଗୀ ଶବ୍ଦାଦି ବିଷୟଗୁଡିକୁ ଇନ୍ଦ୍ରିୟରୂପକ ଅଗ୍ନିରେ ଆହୁତି ଦିଅନ୍ତି। 4.26",

    "ଅନ୍ୟ କେତେକ ଯୋଗୀ ଇନ୍ଦ୍ରିୟମାନଙ୍କର ଓ ପ୍ରାଣମାନଙ୍କର କ୍ରିୟାଗୁଡିକୁ ଜ୍ଞାନରୁ ପ୍ରକାଶିତ ଆତ୍ମସଂଯମଯୋଗ (ସମାଧିଯୋଗ) ରୂପକ ଅଗ୍ନିରେ ଆହୁତି ପ୍ରଦାନ କରନ୍ତି। 4.27",

    "ତୀକ୍ଷ୍ଣ ବ୍ରତ ଆଚରଣ କରୁଥିବା କେତେକ ପ୍ରଯତ୍ନଶୀଳ ସାଧକ ଦ୍ରବ୍ୟମୟ ଯଜ୍ଞ କରନ୍ତି, କେତେକ ତପୋଯଜ୍ଞ କରନ୍ତି ଏବଂ କେତେକ ଯୋଗଯଜ୍ଞ କରନ୍ତି ତଥା ଅନ୍ୟ କେତେକ ସ୍ବାଧ୍ୟାୟରୂପକ ଜ୍ଞାନଯଜ୍ଞ କରନ୍ତି। 4.28",

    "ଅନ୍ୟ କେତେକ ପ୍ରାଣାୟାମ ପରାୟଣ ଯୋଗୀ ଅପାନରେ ପ୍ରାଣବାୟୁକୁ ପୂରକ କରି, ପ୍ରାଣ ଓ ଅପାନର ଗତିକୁ ରୁଦ୍ଧ (କୁମ୍ଭକ) କରି ପ୍ରାଣରେ ଅପାନରେ ଆହୁତି ଦିଅନ୍ତି ବା ରେଚକ କରନ୍ତି, ତଥା ଅନ୍ୟ କେତେକ ମିତାହାରୀ ଯୋଗୀ ପ୍ରାଣମାନଙ୍କୁ ପ୍ରାଣମାନଙ୍କରେ ଆହୁତି ଦିଅନ୍ତି। ଏ ସମସ୍ତ ସାଧକ ଯଜ୍ଞ ଦ୍ବାରା ପାପ ନାଶ କରନ୍ତି ଏବଂ ଯଜ୍ଞକୁ ଜାଣନ୍ତି। 4.29-4.30",

    "ଅନ୍ୟ କେତେକ ପ୍ରାଣାୟାମ ପରାୟଣ ଯୋଗୀ ଅପାନରେ ପ୍ରାଣବାୟୁକୁ ପୂରକ କରି, ପ୍ରାଣ ଓ ଅପାନର ଗତିକୁ ରୁଦ୍ଧ (କୁମ୍ଭକ) କରି ପ୍ରାଣରେ ଅପାନରେ ଆହୁତି ଦିଅନ୍ତି ବା ରେଚକ କରନ୍ତି, ତଥା ଅନ୍ୟ କେତେକ ମିତାହାରୀ ଯୋଗୀ ପ୍ରାଣମାନଙ୍କୁ ପ୍ରାଣମାନଙ୍କରେ ଆହୁତି ଦିଅନ୍ତି। ଏ ସମସ୍ତ ସାଧକ ଯଜ୍ଞ ଦ୍ବାରା ପାପ ନାଶ କରନ୍ତି ଏବଂ ଯଜ୍ଞକୁ ଜାଣନ୍ତି। 4.29-4.30",

    "ହେ କୁରୁଶ୍ରେଷ୍ଠ ଅର୍ଜୁନ ! ଯଜ୍ଞାବଶେଷ ଅମୃତ ଅନୁଭବ କରୁଥିବା ମନୁଷ୍ୟ ସନାତନ ପରବ୍ରହ୍ମ ପରମାତ୍ମାଙ୍କୁ ପ୍ରାପ୍ତ ହୁଅନ୍ତି । ଯଜ୍ଞ ନ କରୁଥିବା ମନୁଷ୍ୟଙ୍କ ପାଇଁ ଇହଲୋକ ମଧ୍ୟ ସୁଖଦାୟକ ନୁହେଁ , ପୁଣି ପରଲୋକ ସୁଖଦାୟକ ହେବ କିପରି ? 4.31",

    "ଏହିଭଳି ଆହୁରି ଅନେକ ପ୍ରକାର ଯଜ୍ଞ ବିସ୍ତାରଭାବେ ବେଦବାଣୀରେ ବର୍ଣ୍ଣିତ ହୋଇଛି। ସେ ସମସ୍ତ ଯଜ୍ଞ କର୍ମଜନିତ ବୋଲି ଜାଣିରଖ। ଏଭଳି ଜାଣି ଯଜ୍ଞ କଲେ ତୁମେ ( କର୍ମବନ୍ଧନରୁ ) ମୁକ୍ତ ହୋଇଯିବ। 4.32",

    "ହେ ପରନ୍ତପ ଅର୍ଜୁନ ! ଦ୍ରବ୍ୟମୟ ଯଜ୍ଞଠାରୁ ଜ୍ଞାନଯଜ୍ଞ ଶ୍ରେଷ୍ଠ। ସମସ୍ତ କର୍ମ ଓ ପଦାର୍ଥ ଜ୍ଞାନ ( ତତ୍ତ୍ବଜ୍ଞାନ ) ରେ ସମାପ୍ତ ବା ଲୀନ ହୋଇଯାଏ। 4.33",

    "ତାହାକୁ (ତତ୍ତ୍ବଜ୍ଞାନକୁ) ତୁମେ ତତ୍ତ୍ବଦର୍ଶୀ ଜ୍ଞାନୀ ମହାପୁରୁଷଙ୍କ ନିକଟକୁ ଯାଇ ବୁଝ। ତାଙ୍କୁ ସାଷ୍ଟାଙ୍ଗ ପ୍ରଣାମ କଲେ, ତାଙ୍କର ସେବା କଲେ ଏବଂ ତାଙ୍କୁ ସରଳତାପୂର୍ବକ ପ୍ରଶ୍ନ କଲେ ସେହି ତତ୍ତ୍ବଦର୍ଶୀ (ଅନୁଭବୀ) ଜ୍ଞାନୀ (ଶାସ୍ତ୍ରଜ୍ଞ) ମହାପୁରୁଷ ତୁମକୁ ତତ୍ତ୍ବଜ୍ଞାନ ଉପଦେଶ ଦେବେ। 4.34",

    "ସେହି ତତ୍ତ୍ବଜ୍ଞାନ ଅନୁଭବ କଲାପରେ ତୁମେ ଆଉ ଏଭଳି ମୋହଗ୍ରସ୍ତ ହେବ ନାହିଁ, ଏବଂ ହେ ଅର୍ଜୁନ ! ତାହା (ତତ୍ତ୍ବଜ୍ଞାନ) ସାହାଯ୍ୟରେ ତୁମେ ସମସ୍ତ ପ୍ରାଣୀଙ୍କୁ ସର୍ବଭାବରେ ପ୍ରଥମେ ନିଜଠାରେ ଏବଂ ପରେ ମୋ' ଠାରେ ଅର୍ଥାତ୍ ସଚ୍ଚିଦାନନ୍ଦଘନ ପରମାତ୍ମାଙ୍କ ଠାରେ ଦେଖିବ। 4.35",

    "ଯଦି ତୁମେ ସମସ୍ତ ପାପୀଙ୍କ ଠାରୁ ବି ଅଧିକ ପାପୀ ହୋଇଥାଅ, ତଥାପି ଜ୍ଞାନରୂପୀ ନୌକା ଦ୍ବାରା ତୁମେ ନିଃସନ୍ଦେହରେ ସମସ୍ତ ପାପସମୁଦ୍ରକୁ ଉତ୍ତମରୂପେ ପାର ହୋଇଯିବ। 4.36",

    "ହେ ଅର୍ଜୁନ ! ପ୍ରଜ୍ବଳିତ ଅଗ୍ନି ଯେପରି ଇନ୍ଧନଗୁଡିକୁ ଭସ୍ମିଭୂତ କରିଦିଏ, ସେହିପରି ଜ୍ଞାନରୂପୀ ଅଗ୍ନି ସମସ୍ତ କର୍ମକୁ ସର୍ବଥା ଭସ୍ମିଭୂତ କରିଦିଏ। 4.37",

    "ଏହି ମନୁଷ୍ୟଲୋକରେ ଜ୍ଞାନଭଳି ପବିତ୍ରକାରକ ଅନ୍ୟ କୌଣସି ସାଧନା ନାହିଁ। ଯାହାଙ୍କର ଯୋଗ ପୂର୍ଣ୍ଣାଙ୍ଗ ହୋଇସାରିଛି, ସେ (କର୍ମଯୋଗୀ) ନିଶ୍ଚିତରୂପେ ସେହି ତତ୍ତ୍ବଜ୍ଞାନକୁ ନିଜେ ନିଜ ଭିତରେ ଅନୁଭବ କରନ୍ତି। 4.38",

    "ଜିତେନ୍ଦ୍ରିୟ ଓ ସାଧନପରାୟଣ ଶ୍ରଦ୍ଧାବାନ୍ ମନୁଷ୍ୟ ଜ୍ଞାନ ଲାଭ କରେ ଏବଂ ଜ୍ଞାନ ଲାଭକରି ସେ ତତ୍କାଳ ପରମ ଶାନ୍ତି ପ୍ରାପ୍ତ ହୁଏ। 4.39",

    "ବିବେକହୀନ ଓ ଶ୍ରଦ୍ଧାବିହୀନ ସଂଶୟାତ୍ମା ମନୁଷ୍ୟର ପତନ ହୁଏ। ଏପରି ସଂଶୟାତ୍ମା ମନୁଷ୍ୟର ଇହଲୋକ ଓ ପରଲୋକରେ ହିତ ହୁଏ ନାହିଁ ଏବଂ ତାକୁ ସୁଖ ମଧ୍ୟ ମିଳେ ନାହିଁ। 4.40",

    "ହେ ଧନଞ୍ଜୟ ! ଯୋଗ (ସମତା) ଦ୍ବାରା ଯାହାର ସମସ୍ତ କର୍ମରୁ ସମ୍ପର୍କ ବିଛିନ୍ନ ହୋଇଯାଇଛି ଏବଂ ବିବେକଜ୍ଞାନ ଦ୍ବାରା ସମସ୍ତ ସଂଶୟ ମୋଚନ ହୋଇଯାଇଛି, ସେପରି ସ୍ବରୂପପରାୟଣ ମନୁଷ୍ୟକୁ କର୍ମଗୁଡିକ ବାନ୍ଧି ପାରନ୍ତି ନାହିଁ। 4.41",

    "ତେଣୁ ହେ ଭରତବଂଶୀ ଅର୍ଜୁନ ! ହୃଦୟସ୍ଥ ଏହି ଅଜ୍ଞାନଜନିତ ସଂଶୟକୁ ଜ୍ଞାନରୂପକ ଖଡ୍ଗ ଦ୍ବାରା ଛେଦନ କରି ଯୋଗ (ସମତା) ରେ ସ୍ଥିତ ହୁଅ, (ଏବଂ ଯୁଦ୍ଧ ପାଇଁ) ଠିଆ ହୋଇଯାଅ। 4.42",

    "ଏହି ପ୍ରକାର ଓଁ ତତ୍ ସତ୍ - ଏହି ଭଗବତ୍ ନାମର ଉଚ୍ଚାରଣପୂର୍ବକ ବ୍ରହ୍ମବିଦ୍ୟା ଓ ଯୋଗଶାସ୍ତ୍ରମୟ ଶ୍ରୀମଦ୍ଭଗବଦ୍ଗୀତା ଉପନିଷଦରୂପେ ଶ୍ରୀକୃଷ୍ଣ ଏବଂ ଅର୍ଜୁନଙ୍କ ସମ୍ବାଦରେ 'ଜ୍ଞାନକର୍ମସନ୍ନ୍ୟାସଯୋଗନାମକ' ଚତୁର୍ଥ ଅଧ୍ୟାୟ ପୂର୍ଣ୍ଣ ହେଲା"
  ],
  "adhyaya-5" : [
    "ଅର୍ଜୁନ କହିଲେ - ଆପଣ କର୍ମତ୍ୟାଗକୁ ପ୍ରଶଂସା କରୁଛନ୍ତି, ପୁନଶ୍ଚ କର୍ମଯୋଗର ମଧ୍ୟ ପ୍ରଶଂସା କରୁଛନ୍ତି। ଅତଏବ ଏ ଦୁଇଟି ସାଧନା ମଧ୍ୟରୁ ଯେଉଁଟି ନିଶ୍ଚିତରୂପେ କଲ୍ୟାଣକାରକ, ତାହା ମୋତେ କୁହନ୍ତୁ। 5.1",

    "ଶ୍ରୀ ଭଗବାନ କହିଲେ-- ସନ୍ନ୍ୟାସ (ସାଙ୍ଖ୍ୟଯୋଗ) ଓ କର୍ମଯୋଗ ଉଭୟ କଲ୍ୟାଣକାରକ ।ତଥାପି ଉଭୟଙ୍କ ମଧ୍ୟରେ କର୍ମସନ୍ନ୍ୟାସ( ସାଂଖ୍ୟଯୋଗ ) ଅପେକ୍ଷା କର୍ମଯୋଗ ଶ୍ରେଷ୍ଠ । 5.2",

    "ହେ ମହାବାହୁ ! ଯେଉଁ ମନୁଷ୍ୟ କାହାପ୍ରତି ଦ୍ବେଷ କରେ ନାହିଁ କି କିଛି ଆକାଂକ୍ଷା କରେ ନାହିଁ , ତାକୁ (କର୍ମଯୋଗୀକୁ) ନିତ୍ୟସନ୍ନ୍ୟାସୀ ବୁଝିବା ଉଚ୍ଚିତ, କାରଣ ଦ୍ବନ୍ଦ୍ବାତୀତ ମନୁଷ୍ୟ ସୁଖପୂର୍ବକ ସଂସାର ବନ୍ଧନରୁ ମୁକ୍ତ ହୋଇଯାଏ। 5.3",

    "ଅଜ୍ଞଲୋକେ ହିଁ ସାଂଖ୍ୟଯୋଗ ଓ କର୍ମଯୋଗକୁ ଭିନ୍ନ ଭିନ୍ନ ଫଳଦାୟକ ବୋଲି କହନ୍ତି, ପଣ୍ଡିତଲୋକ କହନ୍ତି ନାହିଁ, କାରଣ ଏ ଦୁଇଟି ମଧ୍ୟରୁ କୌଣସି ଗୋଟିଏ ସାଧନାରେ ବି ଉତ୍ତମରୂପେ ସ୍ଥିତ ହୋଇଥିବା ମନୁଷ୍ୟ ଉଭୟର ଫଳ ସ୍ବରୂପ ପରମାତ୍ମାଙ୍କୁ ପ୍ରାପ୍ତ ହୁଏ। 5.4",

    "ସାଙ୍ଖ୍ୟଯୋଗୀମାନେ ଯେଉଁ ତତ୍ତ୍ବ ପ୍ରାପ୍ତ ହୁଅନ୍ତି, କର୍ମଯୋଗୀମାନେ ମଧ୍ୟ ସେହି ତତ୍ତ୍ବ ପ୍ରାପ୍ତ ହୁଅନ୍ତି। ଅତଃ ଯେଉଁ ମନୁଷ୍ୟ ଫଳ ଦୃଷ୍ଟିରୁ ସାଙ୍ଖ୍ୟଯୋଗ ଓ କର୍ମଯୋଗକୁ ସମାନ ଦେଖେ, ସେ ହିଁ ଯଥାର୍ଥ ଦେଖେ। 5.5",

    "କିନ୍ତୁ ହେ ମହାବାହୁ ! କର୍ମଯୋଗ ବିନା ସାଙ୍ଖ୍ୟଯୋଗରେ ସିଦ୍ଧି ଲାଭ କରିବା କଷ୍ଟକର। ମନନଶୀଳ କର୍ମଯୋଗୀ ଅଚିରେ ବ୍ରହ୍ମଙ୍କୁ ପ୍ରାପ୍ତ ହୁଏ। 5.6",

    "ଯାହାର ଇନ୍ଦ୍ରିୟଗଣ ବଶିଭୂତ,ଅନ୍ତଃକରଣ ନିର୍ମଳ, ଶରୀର ନିଜର ଆୟତ୍ତାଧୀନ ଏବଂ ସକଳ ପ୍ରାଣୀଙ୍କ ଆତ୍ମା ହିଁ ଯାହାର ଆତ୍ମା, ସେପରି କର୍ମଯୋଗୀ କର୍ମ କରି ମଧ୍ୟ ତହିଁରେ ଲିପ୍ତ ହୁଏ ନାହିଁ। 5.7",

    "ତତ୍ତ୍ବବେତ୍ତା ସାଙ୍ଖ୍ୟଯୋଗୀ ଦେଖିବା, ଶୁଣିବା, ଛୁଇଁବା, ଶୁଂଘିବା, ଖାଇବା, ଚାଲିବା, ଗ୍ରହଣ କରିବା, କହିବା, ମଳ ମୂତ୍ର ତ୍ୟାଗ କରିବା, ଶୋଇବା, ଆଖି ଖୋଲିବା ଓ ବନ୍ଦ କରିବା ଇତ୍ୟାଦି ସମସ୍ତ କ୍ରିୟା କରି ମଧ୍ୟ ଇନ୍ଦ୍ରିୟଗଣ ଇନ୍ଦ୍ରିୟଗଣଙ୍କ ବିଷୟରେ ସବୁକିଛି କରୁଛନ୍ତି ବୋଲି ବୁଝି ,ମୁଁ (ସ୍ବୟଂ ) କିଛିହେଲେ କରୁ ନାହିଁ - ଏପରି ମାନିବା ଉଚିତ। 5.8-5.9",

    "ତତ୍ତ୍ବବେତ୍ତା ସାଙ୍ଖ୍ୟଯୋଗୀ ଦେଖିବା, ଶୁଣିବା, ଛୁଇଁବା, ଶୁଂଘିବା, ଖାଇବା, ଚାଲିବା, ଗ୍ରହଣ କରିବା, କହିବା, ମଳ ମୂତ୍ର ତ୍ୟାଗ କରିବା, ଶୋଇବା, ଆଖି ଖୋଲିବା ଓ ବନ୍ଦ କରିବା ଇତ୍ୟାଦି ସମସ୍ତ କ୍ରିୟା କରି ମଧ୍ୟ ଇନ୍ଦ୍ରିୟଗଣ ଇନ୍ଦ୍ରିୟଗଣଙ୍କ ବିଷୟରେ ସବୁକିଛି କରୁଛନ୍ତି ବୋଲି ବୁଝି ,ମୁଁ (ସ୍ବୟଂ ) କିଛିହେଲେ କରୁ ନାହିଁ - ଏପରି ମାନିବା ଉଚିତ। 5.8-5.9",

    "ଯେଉଁ ଭକ୍ତିଯୋଗୀ ସମସ୍ତ କର୍ମ ପରମାତ୍ମାଙ୍କ ଠାରେ ଅର୍ପଣ କରି ଓ ଆସକ୍ତି ତ୍ୟାଗକରି କର୍ମ କରେ, ଜଳରେ ପଦ୍ମପତ୍ର ପରି ସେ ପାପରେ ଲିପ୍ତ ହୁଏ ନାହିଁ। 5.10",

    "କର୍ମଯୋଗୀମାନେ ଆସକ୍ତି ତ୍ୟାଗ କରି, କେବଳ (ମମତାରହିତ)ଇନ୍ଦ୍ରିୟ, ଶରୀର, ମନ ଓ ବୁଦ୍ଧି ଦ୍ବାରା ଅନ୍ତଃକରଣ ଶୁଦ୍ଧି ନିମନ୍ତେହିଁ କର୍ମ କରନ୍ତି। 5.11",

    "କର୍ମ୍ମଯୋଗୀ କର୍ମଫଳ ତ୍ୟାଗ କରି ନୈଷ୍ଠିକୀ ଶାନ୍ତି ଲାଭ କରେ। କିନ୍ତୁ ସକାମ ମନୁଷ୍ୟ  କାମନା ହେତୁ ଫଳରେ ଆସକ୍ତ ହୋଇ ବାନ୍ଧି ହୋଇଯାଏ। 5.12",

    "ଇନ୍ଦ୍ରିୟ ଓ ମନକୁ ବଶୀଭୂତ କରିଥିବା ଦେହଧାରୀ ଜିତେନ୍ଦ୍ରିୟ ପୁରୁଷ ନବଦ୍ବାରବିଶିଷ୍ଟ ଶରୀରରୂପୀ ପୁରରେ ମାନସିକଭାବେ ସମସ୍ତ କର୍ମକୁ ବିବେକପୂର୍ବକ ତ୍ୟାଗକରି, ନିଃସନ୍ଦେହରେ କିଛି ନ କରି ଓ ନ କରାଇ ସୁଖପୂର୍ବକ ନିଜ ସ୍ବରୂପରେ ଅବସ୍ଥାନ କରେ। 5.13",

    "ପରମେଶ୍ବର ମନୁଷ୍ୟମାନଙ୍କର କର୍ତ୍ତୃତ୍ବ,କର୍ମ ଓ କର୍ମଫଳ ସହିତ ସଂଯୋଗ ରଚି ନ ଥାନ୍ତି, କିନ୍ତୁ ସ୍ବଭାବ ହିଁ ପ୍ରବୃତ୍ତ ହୋଇଥାଏ। 5.14",

    "ସର୍ବବ୍ୟାପି ପରମାତ୍ମା କାହାରି ପାପକର୍ମ ଓ ଶୁଭକର୍ମକୁ ଗ୍ରହଣ କରନ୍ତି ନାହିଁ, କିନ୍ତୁ ଅଜ୍ଞାନ ଦ୍ବାରା ଜ୍ଞାନ ଆବୃତ ହୋଇଥିବାରୁ ଜୀବମାନେ ମୋହିତ ହେଉଛନ୍ତି। 5.15",

    "କିନ୍ତୁ ଯେଉଁମାନେ ନିଜର ଜ୍ଞାନ ବଳରେ ସେହି ଅଜ୍ଞାନକୁ ନାଶ କରି ଦେଇଛନ୍ତି, ସେମାନଙ୍କର ସେହି ଜ୍ଞାନ ସୂର୍ଯ୍ୟ ସଦୃଶ ପରମତତ୍ତ୍ବ ପରମାତ୍ମାଙ୍କୁ ପ୍ରକାଶିତ କରେ। 5.16",

    "ପରମାତ୍ମ ତତ୍ତ୍ବରେ ଯେଉଁମାନଙ୍କର ମନ ଓ ବୁଦ୍ଧି ତଦାକାର ହୋଇଯାଇଛି ଏବଂ ଯେଉଁମାନେ ପରମାତ୍ମତତ୍ତ୍ବରେ ପ୍ରତିଷ୍ଠିତ, ସେପରି ପରମାତ୍ମପରାୟଣ ସାଧକ ଜ୍ଞାନ ଦ୍ବାରା ପାପରହିତ ହୋଇ ଅପୁନରାବୃତ୍ତି ବା ପରମଗତି ପ୍ରାପ୍ତ ହୁଅନ୍ତି। 5.17",

    "ଜ୍ଞାନୀ ମହାପୁରୁଷ ବିଦ୍ୟାବାନ ଓ ବିନୟୀ ବ୍ରାହ୍ମଣଠାରେ ଏବଂ ଚଣ୍ଡାଳଠାରେ ତଥା ଗାଈ, ହାତୀ ଓ କୁକୁରଠାରେ ମଧ୍ୟ ସମସ୍ବରୂପ ପରମାତ୍ମାଙ୍କୁ ଦେଖନ୍ତି। 5.18",

    "ଯେଉଁମାନଙ୍କର ଅନ୍ତଃକରଣ ସମତାରେ ସ୍ଥିତ, ସେମାନେ ଜୀବିତାବସ୍ଥାରେ ହିଁ ସାରା ସଂସାରକୁ ଜିଣି ଯାଇଛନ୍ତି ଅର୍ଥାତ୍ ଜୀବନ୍ମୁକ୍ତ ହୋଇଯାଇଛନ୍ତି, କାରଣ ବ୍ରହ୍ମ ନିର୍ଦୋଷ ଓ ସମ, ତେଣୁ ସେମାନେ ବ୍ରହ୍ମରେ ହିଁ ସ୍ଥିତ। 5.19",

    "ଯିଏ ପ୍ରିୟକୁ ପାଇ ହର୍ଷିତ ହୁଏ ନାହିଁ ଏବଂ ଅପ୍ରିୟକୁ ପାଇ ଉଦ୍ବିଗ୍ନ ହୁଏନାହିଁ , ସେଭଳି ସ୍ଥିରବୁଦ୍ଧିଯୁକ୍ତ ଓ ମୂଢତାରହିତ (ଜ୍ଞାନୀ) ବ୍ରହ୍ମଜ୍ଞ ମନୁଷ୍ୟ ବ୍ରହ୍ମରେ ସ୍ଥିତ ଅଟେ। 5.20",

    "ବାହ୍ୟସ୍ପର୍ଶ (ବିଷୟ ସୁଖ) ରେ ଅନାସକ୍ତ ସାଧକ ଅନ୍ତଃକରଣର ସାତ୍ତ୍ବିକ ସୁଖ ଲାଭ କରେ। ତା' ପରେ ବ୍ରହ୍ମରେ ଅଭିନ୍ନଭାବେ ସ୍ଥିତ ସେହି ମନୁଷ୍ୟ ଅକ୍ଷୟ ସୁଖ ଅନୁଭବ କରେ। 5.21",

    "କାରଣ ହେ କୁନ୍ତୀନନ୍ଦନ ! ଇନ୍ଦ୍ରିୟ ଓ ବିଷୟର ପରସ୍ପର ସଂଯୋଗରୁ ଯେଉଁ ଭୋଗ ବା ସୁଖ ଉତ୍ପନ୍ନ ହୁଏ, ସେସବୁ ଆଦି-ଅନ୍ତଯୁକ୍ତ ଓ ଦୁଃଖର ହିଁ କାରଣ। ତେଣୁ ବିବେକଶୀଳ ମନୁଷ୍ୟ ସେଥିରେ ରମଣ କରେ ନାହିଁ। 5.22",

    "ଏହି ମନୁଷ୍ୟଜନ୍ମରେ ଶରୀରାବସାନ ପୂର୍ବରୁ ଯିଏ କାମ ଓ କ୍ରୋଧଜନିତ ବେଗକୁ ସହ୍ୟ କରିବାରେ ସମର୍ଥ ହୁଏ, ସେହି ନର ଯୋଗୀ ଓ ସୁଖୀ ଅଟେ। 5.23",

    "ଯେଉଁ ମନୁଷ୍ୟ କେବଳ ପରମାତ୍ମାଙ୍କଠାରେ ସୁଖ ପାଏ, କେବଳ ପରମାତ୍ମାଙ୍କଠାରେ ରମଣ କରେ ତଥା ଯେ କେବଳ ପରମାତ୍ମଜ୍ଞାନଯୁକ୍ତ, ବ୍ରହ୍ମରେ ନିଜ ସ୍ଥିତି ଅନୁଭବ କରୁଥିବା (ବ୍ରହ୍ମସ୍ବରୂପ ହୋଇଯାଇଥିବା) ସେହି ସାଙ୍ଖ୍ୟଯୋଗୀ ନିର୍ବାଣ ବ୍ରହ୍ମ ପ୍ରାପ୍ତ ହୁଏ। 5.24",

    "ଯେଉଁମାାନଙ୍କର ଇନ୍ଦ୍ରିୟ ସମେତ ଶରୀର, ମନ ଓ ବୁଦ୍ଧି ବଶିଭୂତ, ଯେଉଁମାନେ ସମସ୍ତ ପ୍ରାଣୀଙ୍କ ହିତରେ ରତ, ଯେଉଁମାନଙ୍କର ସମସ୍ତ ସଂଶୟ ମୋଚନ ହୋଇଯାଇଛି ଓ ସମସ୍ତ କଳ୍ମଷ (ଦୋଷ) ନାଶ ହୋଇଯାଇଛି, ସେହି ବିବେକୀ ସାଧକମାନେ ନିର୍ବାଣବ୍ରହ୍ମ ପ୍ରାପ୍ତ ହୁଅନ୍ତି। 5.25",

    "କାମକ୍ରୋଧରହିତ, ବିଜିତମନା ଏବଂ ଆତ୍ମଦର୍ଶୀ ସାଙ୍ଖ୍ୟଯୋଗୀମାନଙ୍କ ପାଇଁ ସବୁ ଦିଗରୁ (ଜୀବିତ ଅବସ୍ଥାରେ ବା ମୃତ୍ୟୁ ପରେ) ନିର୍ବାଣବ୍ରହ୍ମ ପରିପୂର୍ଣ୍ଣ। 5.26",

    "ବାାହ୍ୟ ପଦାର୍ଥଗୁଡିକୁ ବାହାରେ ପରିତ୍ୟାଗ କରି ଏବଂ ନେତ୍ରଦ୍ବୟର ଦୃଷ୍ଟିକୁ ଦୁଇ ଭ୍ରୂଲତା ମଧ୍ୟରେ ସ୍ଥିର କରି ତଥା ନାସିକାରେ ବିଚରଣଶୀଳ ପ୍ରାଣ ଓ ଅପାନ ବାୟୁକୁ ସମ କରି ଯିଏ ଇନ୍ଦ୍ରିୟ, ମନ ଓ ବୁଦ୍ଧିକୁ ବଶ  କରି ପାରିଛି, ଯିଏ ମୋକ୍ଷ ପରାୟଣ ତଥା ଇଚ୍ଛା, ଭୟ ଓ କ୍ରୋଧରୁ ସର୍ବଥା ରହିତ, ସେହି ମୁନି ସର୍ବଦା ମୁକ୍ତ ଅଟେ।  5.27 - 5.28",

    "ବାାହ୍ୟ ପଦାର୍ଥଗୁଡିକୁ ବାହାରେ ପରିତ୍ୟାଗ କରି ଏବଂ ନେତ୍ରଦ୍ବୟର ଦୃଷ୍ଟିକୁ ଦୁଇ ଭ୍ରୂଲତା ମଧ୍ୟରେ ସ୍ଥିର କରି ତଥା ନାସିକାରେ ବିଚରଣଶୀଳ ପ୍ରାଣ ଓ ଅପାନ ବାୟୁକୁ ସମ କରି ଯିଏ ଇନ୍ଦ୍ରିୟ, ମନ ଓ ବୁଦ୍ଧିକୁ ବଶ  କରି ପାରିଛି, ଯିଏ ମୋକ୍ଷ ପରାୟଣ ତଥା ଇଚ୍ଛା, ଭୟ ଓ କ୍ରୋଧରୁ ସର୍ବଥା ରହିତ, ସେହି ମୁନି ସର୍ବଦା ମୁକ୍ତ ଅଟେ।  5.27 - 5.28",

    "ଭକ୍ତ ମୋତେ ସମସ୍ତ ଯଜ୍ଞ ଓ ତପର ଭୋକ୍ତା, ସମସ୍ତ ଲୋକର ମହାନ୍ ଈଶ୍ବର ତଥା ସକଳ ପ୍ରାଣୀଙ୍କର ସୁହୃଦ୍ (ସ୍ବାର୍ଥରହିତ ଦୟାଳୁ ଓ ପ୍ରେମୀ) ବୋଲି ଜାଣି ଶାନ୍ତି ଲାଭ କରେ। 5.29",

    "ଏହି ପ୍ରକାର ଓଁ ତତ୍ ସତ୍ -- ଏହି ଭଗବତ୍ ନାମର ଉଚ୍ଚାରଣପୂର୍ବକ ବ୍ରହ୍ମବିଦ୍ୟା ଓ ଯୋଗଶାସ୍ତ୍ରମୟ ଶ୍ରୀମଦ୍ଭଗବଦ୍ଗୀତା ଉପନିଷଦରୂପେ ଶ୍ରୀକୃଷ୍ଣ ଏବଂ ଅର୍ଜୁନଙ୍କ ସମ୍ବାଦରେ 'କର୍ମସନ୍ନ୍ୟାସଯୋଗନାମକ' ପଞ୍ଚମ ଅଧ୍ୟାୟ ପୂର୍ଣ୍ଣ ହେଲା"
  ],
  "adhyaya-6" : [
    "ଶ୍ରୀଭଗବାନ କହିଲେ - ଯେଉଁ ପୁରୁଷ କର୍ମଫଳ କୁ ଆଶ୍ରୟ ନ କରି ,  କର୍ତ୍ତବ୍ୟ କର୍ମ କରନ୍ତି, ସେ ସନ୍ନ୍ୟାସୀ ତଥା ଯୋଗୀ ଅଟନ୍ତି।  କେବଳ ଅଗ୍ନି ତ୍ୟାଗ କରିଥିବା ମନୁଷ୍ୟ ସନ୍ନ୍ୟାସୀ ନୁହଁନ୍ତି, କି କେବଳ କ୍ରିୟା ତ୍ୟାଗ କରିଥିବା ମନୁଷ୍ୟ ଯୋଗୀ ନୁହଁନ୍ତି। 6.1",

    "ହେ ଅର୍ଜୁନ! ଯାହାକୁ ସନ୍ନ୍ୟାସ ବୋଲି କହନ୍ତି, ତାହାକୁ ହିଁ ତୁମ୍ଭେ ଯୋଗ ବୋଲି ଜାଣ, କାରଣ ସଂକଳ୍ପ ତ୍ୟାଗ କରି ନ ଥିବା କୌଣସି ଵି ପୁରୁଷ ଯୋଗୀ ହୁଏ ନାହିଁ। 6.2",

    "ଯୋଗାରୁଢ ହେବାକୁ ଇଚ୍ଛୁକ ମନନଶୀଳ ମନୁଷ୍ୟଙ୍କ ନିମନ୍ତେ ନିଷ୍କାମ ଭାବରେ କର୍ମକରିଵା ହିଁ ଯୋଗପ୍ରାପ୍ତିର ହେତୁ ବୋଲି କୁହାଯାଏ, ଏବଂ ଯୋଗାରୁଢ ହୋଇଯିବା ପରେ ସେହି ଯୋଗାରୁଢ ପୁରୁଷଙ୍କର ସର୍ଵସଂକଳ୍ପଶୂନ୍ୟତା ହିଁ ତାଙ୍କ କଲ୍ୟାଣର କାରଣ ବୋଲି କୁହାଯାଏ। 6.3",

    "ପୁରୁଷ ଯେତେବେଳେ ଇନ୍ଦ୍ରିୟମାନଙ୍କର ଭୋଗରେ ଓ କର୍ମମାନଙ୍କରେ ମଧ୍ୟ ଆସକ୍ତ ହୁଅନ୍ତି ନାହିଁ, ସେତେବେଳେ ସେହି ସର୍ଵସଂକଳ୍ପତ୍ୟାଗୀ ଯୋଗାରୁଢ ବୋଲି କଥିତ ହୁଅନ୍ତି। 6.4",

    "ନିଜକୁ ନିଜଦ୍ୱାରା ସଂସାରସାଗରରୁ ଉଦ୍ଧାର କରିବା ଉଚିତ; ନିଜକୁ ଅଧୋଗତିରେ ପକାଇଵା ଉଚିତ ନୁହେଁ; କାରଣ ମନୁଷ୍ୟ ନିଜେ ହିଁ ନିଜର ମିତ୍ର ଓ ନିଜେ ହିଁ ନିଜର ଶତ୍ରୁ। 6.5",

    "ଯେଉଁ ଜୀବାତ୍ମାଦ୍ୱାରା ମନ ଓ ଇନ୍ଦ୍ରିୟସହିତ ଶରୀରକୁ ଜୟ କରାଯାଇଛି, ସେହି ଜୀବାତ୍ମା ନିଜେହିଁ ନିଜର ମିତ୍ର ଏବଂ ଯାହାଦ୍ଵାରା ମନ, ଇନ୍ଦ୍ରିୟ ଓ ଶରୀରକୁ ଜୟ କରାଯାଇ ନାହିଁ ସେ ନିଜେ ହିଁ ନିଜର ଶତ୍ରୁ ପରି ଆଚରଣ କରେ। 6.6",

    "ଶୀତ - ଉଷ୍ଣ ଓ ସୁଖ - ଦୁଃଖ ତଥା ମାନ - ଅପମାନରେ ଯାହାଙ୍କ ଅନ୍ତଃକରଣର ବୃତ୍ତିଗୁଡିକ ଭଲରୂପରେ ଶାନ୍ତ ରହନ୍ତି, ସେପରି ସ୍ୱାଧୀନ - ଆତ୍ମାବିଶିଷ୍ଟ ପୁରୁଷଙ୍କ ଜ୍ଞାନରେ ସଚ୍ଚିଦାନନ୍ଦଘନ ପରମାତ୍ମା ସମ୍ୟକପ୍ରକାରେ ସ୍ଥିତ ଅଟନ୍ତି ଅର୍ଥାତ୍ ତାଙ୍କ ଜ୍ଞାନରେ ପରମାତ୍ମାଙ୍କ ବ୍ୟତୀତ ଅନ୍ୟ କିଛି ହିଁ ନାହିଁ। 6.7",

    "ଯାହାଙ୍କ ଅନ୍ତଃକରଣ ଜ୍ଞାନ ବିଜ୍ଞାନରେ ତୃପ୍ତ, ଯାହାଙ୍କର ସ୍ଥିତି ଵିକାର ଶୂନ୍ୟ, ଯାହାଙ୍କର ଇନ୍ଦ୍ରିୟମାନେ ଭଲଭାବରେ ଵଶୀଭୁତ ହୋଇଛନ୍ତି ଏବଂ ଯାହାଙ୍କ ପାଇଁ ମାଟି, ପଥର ଓ ସୁନା ସମାନ, ସେହି ଯୋଗୀ ଯୁକ୍ତ ଅର୍ଥାତ୍ ଭଗବଦ୍ ପ୍ରାପ୍ତ ବୋଲି କୁହାଯାନ୍ତି। 6.8",

    "ସୁହୃଦ୍ , ମିତ୍ର, ଵୈରୀ, ଉଦାସୀନ, ମଧ୍ୟସ୍ଥ, ଦ୍ଵେଷ୍ୟ ଓ ବନ୍ଧୁଗଣଙ୍କ ଠାରେ, ଧର୍ମାତ୍ମାମାନଙ୍କ ଠାରେ ଓ ପାପୀ ମାନଙ୍କ ଠାରେ ମଧ୍ୟ ସମାନ ଭାବ ରଖୁଥିବା ମନୁଷ୍ୟ ଶ୍ରେଷ୍ଠ ଅଟନ୍ତି। 6.9",

    "ମନ ଓ ଇନ୍ଦ୍ରିୟମାନଙ୍କ ସହିତ ଶରୀରକୁ ବଶ କରି ରଖୁଥିବା, ଆଶାରହିତ ଓ ସଂଗ୍ରହ- ଇଚ୍ଛାଶୂନ୍ୟ ଯୋଗୀ ଏକାକୀ ହିଁ ଏକାନ୍ତ ସ୍ଥାନରେ ଅବସ୍ଥିତ ହୋଇ ଆତ୍ମାକୁ ନିରନ୍ତର ପରମାତ୍ମାରେ ଲଗାଇଵା ଉଚିତ। 6.10",

    "ଶୁଦ୍ଧଭୂମି ଉପରେ ତଳଆଡୁ କ୍ରମଶଃ କୁଶ, ତା' ଉପରେ ମୃଗଚର୍ମ ଓ ତା' ଉପରେ ବସ୍ତ୍ର ବିଛାଇ ଅତି ଉଚ୍ଚା କିମ୍ଵା ଅତି ନୀଚା ନ କରି ନିଜର ଆସନ ସ୍ଥିରଭାବେ ସ୍ଥାପନ କରି, 6.11",

    "ସେହି ଆସନରେ ବସି ଚିତ୍ତ ଓ ଇନ୍ଦ୍ରିୟମାନଙ୍କ କ୍ରିୟାମାନଙ୍କୁ ଵଶରେ ରଖି ମନକୁ ଏକାଗ୍ର କରି ଅନ୍ତଃକରଣର ଶୁଦ୍ଧି ପାଇଁ ଯୋଗାଭ୍ୟାସ କରିବା ଉଚିତ୍। 6.12",

    "ଶରୀର, ଶିର ଏବଂ ଗ୍ରୀବା ସଳଖ ଓ ଅଚଳ ରଖି ଏବଂ ସ୍ଥିର ହୋଇ, ଅନ୍ୟ ଆଡେ ଦୃଷ୍ଟି ନିକ୍ଷେପ ନକରି, ନିଜ ନାସିକାର ଅଗ୍ରଭାଗରେ ଦୃଷ୍ଟି ନିବଦ୍ଧ କରି ରଖ। 6.13",

    "ବ୍ରହ୍ମଚାରି ବ୍ରତରେ ସ୍ଥିତ ଭୟଶୂନ୍ୟ ପ୍ରଶାନ୍ତ ଅନ୍ତଃକରଣ ବିଶିଷ୍ଟ ତଥା ସାବଧାନ ଯୋଗୀ ମନକୁ ସଂଯତ କରି, ମୋ ଠାରେ ଯୁକ୍ତଚିତ୍ତ ହୋଇ ଏବଂ ମୋର ପରାୟଣ ହୋଇ ବସି ରହିବା ଉଚିତ୍। 6.14",

    "ମନକୁ ବଶ କରିଥିବା ଯୋଗୀ ଏପରି ଭାବରେ ଆତ୍ମାକୁ ନିରନ୍ତର ମୋର ଅର୍ଥାତ୍ ପରମେଶ୍ଵରଙ୍କ ସ୍ୱରୂପରେ ଲଗାଇ, ମୋ ଠାରେ ଥିବା ପରମାନନ୍ଦର ପରାକାଷ୍ଠା ରୂପକ ଶାନ୍ତି ପ୍ରାପ୍ତ ହୁଅନ୍ତି, ଅର୍ଥାତ୍ ମୋତେ ପ୍ରାପ୍ତ ହୋଇଯାଆନ୍ତି। 6.15",

    "ହେ ଅର୍ଜୁନ! ଯେ ବହୁତ ଭୋଜନ କରେ କିମ୍ବା ଯେ ଆଦୌ ଭୋଜନ କରନ୍ତି ନାହିଁ; ଯେ ବହୁତ ଶୁଏ କିମ୍ବା ଯେ ଆଦୌ ଶୁଏ ନାହିଁ, ସେମାନଙ୍କ ଦ୍ୱାରା ଏହି ଯୋଗ ସିଦ୍ଧ ହୁଏ ନାହିଁ। 6.16",

    "ଏହି ଦୁଃଖନାଶକାରୀ ଯୋଗ ଯଥାଯୋଗ୍ୟ ଆହାରବିହାର କରୁଥିବା, କର୍ମ କରିବାକୁ ଯଥାଯୋଗ୍ୟ ଚେଷ୍ଟା କରୁଥିବା ତଥା ଯଥାଯୋଗ୍ୟ ଶୋଉଥିବା ଚେଉଁଥିବା ବ୍ୟକ୍ତିଙ୍କ ଦ୍ୱାରା ହିଁ ସିଦ୍ଧ ହୋଇଥାଏ। 6.17",

    "ଅତ୍ୟନ୍ତ ବଶୀଭୂତ ହୋଇଥିବା ଚିତ୍ତ ଯେତେବେଳେ ପରମାତ୍ମାଙ୍କ ଠାରେ ହିଁ ଭଲଭାବରେ ଅବସ୍ଥାନ କରେ ସେତେବେଳେ ସକଳ ଭୋଗରୁ ନସ୍ପୃହ ପୁରୁଷ ଯୋଗଯୁକ୍ତ ହୁଅନ୍ତି ବୋଲି କୁହାଯାଏ। 6.18",

    "ପବନ ନଥିବା ସ୍ଥାନରେ ରହିଥିବା ଦୀପର‌ ଶିଖା ଯେପରି ଚଞ୍ଚଳ ହୁଏ ନାହିଁ, ପରମାତ୍ମାଙ୍କ ଧ୍ୟାନରେ ଲାଗିରହିଥିବା ଯୋଗୀଙ୍କର ବଶୀଭୂତ ଚିତ୍ତ ସେହିପରି ସ୍ଥିର ରହେ ବୋଲି କୁହାଯାଏ। 6.19",

    "ଯୋଗାଭ୍ୟାସ ଦ୍ୱାରା ନିରୁଦ୍ଧ ହୋଇଥିବା ଚିତ୍ତ ଯେଉଁ ଅବସ୍ଥାରେ ଉପରତି ପ୍ରାପ୍ତ ହୋଇଥାଏ ଓ ଯେଉଁ ପରମାତ୍ମାଙ୍କ ଧ୍ୟାନରେ ଶୁଦ୍ଧ ହୋଇଥିବା ସୂକ୍ଷ୍ମବୁଦ୍ଧି ଦ୍ୱାରା ପରମାତ୍ମାଙ୍କୁ ସାକ୍ଷାତ କରି ସଚ୍ଚିଦାନନ୍ଦଘନ ପରମାତ୍ମାରେ ହିଁ ସନ୍ତୁଷ୍ଟ ହୋଇରହେ। 6.20",

    "କେବଳ ବିଶୁଦ୍ଧ ଓ ସୂକ୍ଷ୍ମ ବୁଦ୍ଧି ଦ୍ୱାରା ଗ୍ରହଣ ଯୋଗ୍ୟ ଯେଉଁ ଇନ୍ଦ୍ରିୟାତୀତ ଅନନ୍ତ ଆନନ୍ଦ ରହିଛି, ଯୋଗୀ ତାହାକୁ ଯେଉଁ ଅବସ୍ଥାରେ ଅନୁଭବ କରନ୍ତି ଓ ଯେଉଁ ଅବସ୍ଥାରେ ସ୍ଥିତ ହେଲେ ଏହି ଯୋଗୀ ପରମାତ୍ମାଙ୍କ ସ୍ୱରୂପରୁ କଦାପି ବିଚଳିତ ହୁଅନ୍ତି ‌ନାହିଁ। 6.21",

    "ପରମାତ୍ମା ପ୍ରାପ୍ତି ରୂପକ ଯେଉଁ ଲାଭ ପ୍ରାପ୍ତ ହେଲେ, ତା’ଠାରୁ ଅଧିକ ଅନ୍ୟ କିଛି ଲାଭ ଅଛି ବୋଲି ଯୋଗୀ ମାନନ୍ତି ନାହିଁ ଓ ପରମାତ୍ମା ପ୍ରାପ୍ତି ରୂପକ ଯେଉଁ ଅବସ୍ଥାରେ ସ୍ଥିତ ହେଲେ ଯୋଗୀ ଅତିଶୟ ଦୁଃଖରେ ମଧ୍ୟ ବିଚଳିତ ହୁଅନ୍ତି ନାହିଁ। 6.22",

    "ଯାହା ଦୁଃଖ ରୂପକ ସଂସାରର ସଂଯୋଗରୁ ରହିତ ତଥା ଯାହାର ନାମ ଯୋଗ, ତାହା ଜାଣିବା ଆବଶ୍ୟକ। କ୍ଷୂର୍ଣ୍ଣ ନହୋଇ, ଅର୍ଥାତ୍ ଧୈର୍ଯ୍ୟ ଓ ଉତ୍ସାହଯୁକ୍ତ ଚିତ୍ତରେ ନିର୍ଣ୍ଣୟ ପୂର୍ବକ ସେହି ଯୋଗ ସାଧନା କରିବା କର୍ତ୍ତବ୍ୟ। 6.23",

    "ସଂକଳ୍ପରୁ ଉତ୍ପନ୍ନ ହେଉଥିବା ସକଳ କାମନା ନିଃଶେଷ ରୂପେ ତ୍ୟାଗ କରି, ସମସ୍ତ ଇନ୍ଦ୍ରିୟଙ୍କୁ ସବୁ ଦିଗରୁ ଭଲଭାବେ ମନ ଦ୍ୱାରା ନିରୋଧ କରି ରଖିବା ଉଚିତ୍। 6.24",

    "କ୍ରମଶଃ ଅଭ୍ୟାସ କରି କରି ଉପରତି ପ୍ରାପ୍ତ ହେବା ଉଚିତ୍ ତଥା ଧୈର୍ଯ୍ୟ ଯୁକ୍ତ ବୁଦ୍ଧି ଦ୍ଵାରା ମନକୁ ପରମାତ୍ମାରେ ଲଗାଇ ପରମାତ୍ମାଙ୍କ ବ୍ୟତୀତ ଅନ୍ୟ କିଛି ବି ଚିନ୍ତନ କରିବା ଉଚିତ୍ ନୁହେଁ। 6.25",

    "ଏହି ଅସ୍ଥିର ଚଞ୍ଚଳ ମନ ଯେଉଁ ଯେଉଁ ଶଦ୍ଦାଦି ବିଷୟ ନିମନ୍ତେ ସଂସାରରେ ବିଚରଣ କରେ, ତାହାକୁ ସେହି ସେହି ବିଷୟରୁ ନିବୃତ୍ତ କରି ଅର୍ଥାତ୍ ହଟାଇ ଆଣି ବାରମ୍ବାର ପରମାତ୍ମାଙ୍କ ଠାରେ ନିରୁଦ୍ଧ କରିବା ଉଚିତ। 6.26",

    "ଯାହାଙ୍କର ମନ ଭଲ ଭାବରେ ଶାନ୍ତ, ଯେ ପାପରୁ ରହିତ ଓ ଯାହାଙ୍କର ରଜୋଗୁଣ ଶାନ୍ତ ହୋଇଗଲାଣି, ସଚ୍ଚିଦାନନ୍ଦଘନ ବ୍ରହ୍ମଙ୍କ ସ‌ହିତ ଏକତ୍ବଭାବ ପ୍ରାପ୍ତ ହୋଇଥିବା ସେହି ଯୋଗୀଙ୍କୁ ଉତ୍ତମ ଆନନ୍ଦ ପ୍ରାପ୍ତ ହୁଏ। 6.27",

    "ସେହି ନିଷ୍ପାପ ଯୋଗୀ ଏହିପରି ଭାବରେ ନିରନ୍ତର ଆତ୍ମାକୁ ପରମାତ୍ମା ରେ ଲଗାଇରଖି ସୁଖପୂର୍ବକ ପରଂବ୍ରହ୍ମ ପରମାତ୍ମପ୍ରାପ୍ତି ରୂପକ ଅନନ୍ତ ଆନନ୍ଦ ଅନୁଭବ କରନ୍ତି। 6.28",

    "ସର୍ବବ୍ୟାପୀ ଅନନ୍ତ ଚେତନାରେ ଅର୍ଥାତ୍ ପରଂବ୍ରହ୍ମ ପରମାତ୍ମାରେ ଏକୀଭାବରେ ସ୍ଥିତି ରୂପକ ଯୋଗଦ୍ୱାରା ଯୁକ୍ତ ଆତ୍ମବାନ ତଥା ସର୍ବତ୍ର ସମଭାବ ଦର୍ଶନ କରୁଥିବା ଯୋଗୀ ଆତ୍ମାକୁ ସକଳ ଭୂତରେ ଅବସ୍ଥିତ ଓ ସକଳ ଭୂତଙ୍କୁ ଆତ୍ମାରେ କଳ୍ପିତ ହୋଇଥିବା ଦେଖନ୍ତି। 6.29",

    "ଯେଉଁ ପୁରୁଷ ସକଳ ପ୍ରାଣୀଙ୍କ ଠାରେ ସକଳଙ୍କର ଆତ୍ମା ରୂପେ ମୋତେ ଅର୍ଥାତ୍ ବାସୁଦେବଙ୍କୁ ହିଁ ବ୍ୟାପି ରହିଥିବା ଦେଖନ୍ତି ଓ ସକଳ ପ୍ରାଣୀଙ୍କୁ ମୋର ଅର୍ଥାତ୍ ବାସୁଦେବଙ୍କର ଅନ୍ତର୍ଗତ ଥିବା ଦେଖନ୍ତି ତାଙ୍କ ପାଇଁ ମୁଁ ଅଦୃଶ୍ୟ ହୁଏ ନାହିଁ, କି ସେ ମୋ ପାଇଁ ଅଦୃଶ୍ୟ ହୁଅନ୍ତି ନାହିଁ। 6.30",

    "ଯେଉଁ ପୁରୁଷ ଏୈକ୍ୟ ଭାବରେ ସ୍ଥିତ ହୋଇ ସମସ୍ତ ପ୍ରାଣୀଙ୍କ ଠାରେ ଆତ୍ମରୂପରେ ସ୍ଥିତ ମୋତେ (ସଚ୍ଚିଦାନନ୍ଦଘନ ବାସୁଦେବଙ୍କୁ) ଭଜନ୍ତି, ସେହି ଯୋଗୀ ସକଳପ୍ରକାର କ୍ରିୟାକଳାପ କରୁଥିଲେ ମଧ୍ୟ ମୋ ପାଇଁ ହିଁ ସକଳ କ୍ରିୟା କରୁଥାନ୍ତି। 6.31",

    "ହେ ଅର୍ଜୁନ ! ସକଳଭୂତରେ ଆତ୍ମଭାବ ହୋଇଯିବାରୁ ଯେଉଁ ଯୋଗୀ ସମସ୍ତଙ୍କୁ ନିଜପରି ସମଭାବରେ ଦେଖନ୍ତି ଏବଂ ସମସ୍ତଙ୍କର ସୁଖଦୁଃଖକୁ ବି ନିଜର ସୁଖଦୁଃଖ ସମାନ ଦେଖନ୍ତି, ତାଙ୍କୁ ପରମ ଶ୍ରେଷ୍ଠ ଯୋଗୀ ବୋଲି ସ୍ୱୀକାର କରାଯାଏ । 6.32",

    "ଅର୍ଜୁନ କହିଲେ– ହେ ମଧୁସୂଦନ! ଆପଣଙ୍କ ଦ୍ଵାରା ଏହି ଯେଉଁ ସମତ୍ୱ ରୂପକ ଯୋଗ କୁହାଗଲା , ମନର ଚଞ୍ଚଳତା ହେତୁ ମୁଁ ତାହାର ନିତ୍ୟ ସ୍ଥିତି ଦେଖିପାରୁ ନାହିଁ। 6.33",

    "କାରଣ ହେ ଶ୍ରୀକୃଷ୍ଣ! ଏହି ମନ ବଡ଼ ଚଞ୍ଚଳ, ଇନ୍ଦ୍ରିୟ ବିକ୍ଷୋଭକାରୀ, ବଡ଼ ଦୃଢ଼ ଓ ବଳବାନ୍। ତେଣୁ ମୁଁ ଭାବୁଛି, ତାହାକୁ ବଶ କରିବା ବାୟୂକୁ ବଶ କରିବା ପରି ଅତ୍ୟନ୍ତ ଦୁଷ୍କର। 6.34",

    "ଶ୍ରୀ ଭଗବାନ କହିଲେ – ହେ ମହାବାହୁ! ମନ ଯେ ଚଞ୍ଚଳ ଓ ବଡ଼ କଷ୍ଟରେ ବଶୀଭୂତ ହୁଏ, ଏହା ନିଃସନ୍ଦେହ। କିନ୍ତୁ ହେ କୁନ୍ତୀ ପୁତ୍ର ଅର୍ଜୁନ! ଏହା ଅଭ୍ୟାସ ଓ ବୈରାଗ୍ୟ ଦ୍ଵାରା ବଶୀଭୂତ ହୋଇଥାଏ। 6.35",

    "ଯାହାର ମନ ସଂଯତ ହୋଇନାହିଁ, ତା’ଦ୍ଵାରା ଯୋଗ ଦୁଷ୍ପ୍ରାପ୍ୟ ଓ ମନକୁ ବଶ କରିଥିବା ପ୍ରଯତ୍ନଶୀଳ ପୁରୁଷଙ୍କ ଦ୍ଵାରା ସାଧନା ବଳରେ ତାହା ପ୍ରାପ୍ତ ହେବା ସହଜ – ଏହା ମୋର ମତ। 6.36",

    "ଅର୍ଜୁନ କହିଲେ-ହେ ଶ୍ରୀକୃଷ୍ଣ ! ଯେ ଯୋଗରେ ଶ୍ରଦ୍ଧାଶୀଳ ଅଥଚ ସଂଯମୀ ନୁହନ୍ତି ଏବଂ ସେହି କାରଣରୁ ଯାହାଙ୍କ ମନ ଅନ୍ତକାଳରେ ଯୋଗରୁ ବିଚଳିତ ହୋଇଯାଇଛି, ସେପରି ସାଧକ ଯୋଗସିଦ୍ଧି ଅର୍ଥାତ୍‌ ଭଗବତ୍‌ ସାକ୍ଷାତକାର ପ୍ରାପ୍ତି କରି ନ ପାରି କେଉଁ ଗତି ପ୍ରାପ୍ତ ହୁଅନ୍ତି ? 6.37",

    "ହେ ମହାବାହୁ ! କ’ଣ ସେହି ଭଗବତପ୍ରାପ୍ତି ମାର୍ଗରେ ମୋହିତ ଓ ଆଶ୍ରୟରହିତ ହୋଇଯାଇଥିବା ପୁରୁଷ ଛିନ୍ନଭିନ୍ନ ମେଘପରି ଉଭୟ ଦିଗରୁ ଭ୍ରଷ୍ଟ ହୋଇ ନଷ୍ଟ ହୋଇଯା’ନ୍ତି ନାହିଁ ? 6.38",

    "ହେ ଶ୍ରୀକୃଷ୍ଣ ! ମୋର ଏହି ସଂଶୟ ସମ୍ପୂର୍ଣ୍ଣ ରୂପେ ଛେଦନ କରିବାକୁ ଆପଣ ହିଁ ଯୋଗ୍ୟ,କାରଣ ଆପଣଙ୍କ ବ୍ୟତିତ ଅନ୍ୟ କେହି ଏହି ସଂଶୟର ଛେଦନକାରୀ ମିଳିବା ସମ୍ଭବନୁହେଁ। 6.39",

    "ଶ୍ରୀ ଭଗବାନ କହିଲେ - ହେ ପାର୍ଥ ! ସେହି ପୁରୁଷଙ୍କର ଇହଲୋକରେ ବିନାଶ ହୁଏନାହିଁ କି, ପରଲୋକରେ ବିନାଶ ହୁଏନାହିଁ; କାରଣ ହେ ପ୍ରିୟ ! ଆତ୍ମୋଦ୍ଧାର ପାଇଁ ଅର୍ଥାତ୍‌ ଭଗବତପ୍ରାପ୍ତି ପାଇଁ କର୍ମ କରୁଥିବା କୌଣସି ବି ପୁରୁଷ ଦୁର୍ଗତି ପ୍ରାପ୍ତ ହୁଅନ୍ତି ନାହିଁ। 6.40",

    "ଯୋଗଭ୍ରଷ୍ଟ ପୁରୁଷ ପୁଣ୍ୟବାନ ମାନଙ୍କର ଲୋକ ଅର୍ଥାତ ସ୍ୱର୍ଗାଦି ଉତ୍ତମ ଲୋକ ପ୍ରାପ୍ତ ହୁଅନ୍ତି ଓ ବହୁ ବର୍ଷ ଯାଏଁ ସେଠାରେ ନିବାସ କରି ଶୁଦ୍ଧ ଆଚରଣ ବିଶିଷ୍ଟ ଶ୍ରୀମନ୍ତ(ଲକ୍ଷ୍ମୀମନ୍ତ) ପୁରୁଷମାନଙ୍କ ଘରେ ଜନ୍ମ ହୁଅନ୍ତି 6.41",

    "ଅଥବା ବୈରାଗ୍ୟବାନ୍ ପୁରୁଷ ସେସବୁ ଲୋକକୁ ନଯାଇ ଜ୍ଞାନବାନ ଯୋଗୀ ମାନଙ୍କ କୁଳରେ ହିଁ ଜନ୍ମ ନିଅନ୍ତି। କିନ୍ତୁ ଏପରି ଜନ୍ମ ସଂସାରରେ ନିଃସନ୍ଦେହ ଅତ୍ୟନ୍ତ ଦୁର୍ଲଭ। 6.42",

    "ସେଠାରେ ସେହି ପୁର୍ବଜନ୍ମର ଶରୀରରେ ସଂଗ୍ରହ କରିଥିବା ବୁଦ୍ଧିର ସଂଯୋଗ ଅର୍ଥାତ ସମବୁଦ୍ଧି ରୂପକ ଯୋଗ ସଂସ୍କାର ଗୁଡିକୁ ସେ ସହଜରେ ପାଇଯାନ୍ତି ଏବଂ ହେ କୁରୁନନ୍ଦନ ! ତାହାର ପ୍ରଭାବରେ ସେ ପୁଣି ଥରେ ପରମାତ୍ମପ୍ରାପ୍ତି ରୂପକ ସିଦ୍ଧିପାଇଁ ପୂର୍ବ ଅପେକ୍ଷା ଅଧିକ ପ୍ରଯତ୍ନ କରନ୍ତି। 6.43",

    "ଲକ୍ଷ୍ମୀବାନ୍ ଲୋକଙ୍କଘରେ ଜନ୍ମ ନେଇଥିବା ସେହି ଯୋଗଭ୍ରଷ୍ଟ ପୁରୁଷ ପରାଧିନ ହୋଇ ମଧ୍ୟ ପୂର୍ବାଭ୍ୟାସ ପ୍ରଭାବରେ ହିଁ ନିଃସନ୍ଦେହ ଭଗବାନଙ୍କ ପ୍ରତି ଆକୃଷ୍ଟ ହୁଅନ୍ତି ତଥା ସମବୁଦ୍ଧି ରୂପକ ଯୋଗର ଜିଜ୍ଞାସୁ ମଧ୍ୟ ବେଦରେ କଥିତ ସକାମ କର୍ମର ଫଳକୁ ଉଲ୍ଲଙ୍ଘନ କରିଯା’ନ୍ତି। 6.44",

    "ପରନ୍ତୁ ପ୍ରଯତ୍ନପୂର୍ବକ ଅଭ୍ୟାସ କରିଥିବା ଯୋଗୀ ତ ପୂର୍ବର ଅନେକ ଜନ୍ମର ସଂସ୍କାର ବଳରେ ଇହ ଜନ୍ମରେ ସଂସିଦ୍ଧ ହୋଇ, ସମସ୍ତ ପାପରୁ ମୁକ୍ତ ହୋଇ, ତତ୍‌କାଳ ହିଁ ପରମଗତି ପାଇଯାଆନ୍ତି। 6.45",

    "ଯୋଗୀ ତପସ୍ୱୀମାନଙ୍କ ଠାରୁ ଶ୍ରେଷ୍ଠ ; ଶାସ୍ତ୍ରଜ୍ଞାନୀ ମାନଙ୍କଠାରୁ ମଧ୍ୟ ଶ୍ରେଷ୍ଠ ବୋଲି ଗଣ୍ୟ ହୁଅନ୍ତି ଏବଂ ସକାମକର୍ମ କରୁଥିବା ଲୋକଙ୍କଠାରୁ ମଧ୍ୟ ଯୋଗୀ ଶ୍ରେଷ୍ଠ ; ତେଣୁ ହେ ଅର୍ଜୁନ ! ତୁମେ ଯୋଗୀ ହୋଇଯାଅ। 6.46",

    "ଯୋଗୀମାନଙ୍କ ମଧ୍ୟରେ ବି ଯେଉଁ ଶ୍ରଦ୍ଧାବାନ୍ ଯୋଗୀ ମୋ ଠାରେ ଲାଗିରହିଥିବା ଅନ୍ତରାତ୍ମାଦ୍ୱାରା ମୋତେ ନିରନ୍ତର ଭଜୁଥାନ୍ତି, ସେହି ଯୋଗୀ ମୋ ମତରେ ପରମ ଶ୍ରେଷ୍ଠ ଯୋଗୀ ଅଟନ୍ତି। 6.47",

    "ଏହି ପ୍ରକାର ଓଁ ତତ୍ ସତ୍ -- ଏହି ଭଗବତ୍ ନାମର ଉଚ୍ଚାରଣପୂର୍ବକ ବ୍ରହ୍ମବିଦ୍ୟା ଓ ଯୋଗଶାସ୍ତ୍ରମୟ ଶ୍ରୀମଦ୍ଭଗବଦ୍ଗୀତା ଉପନିଷଦରୂପେ ଶ୍ରୀକୃଷ୍ଣ ଏବଂ ଅର୍ଜୁନଙ୍କ ସମ୍ବାଦରେ 'ଆତ୍ମସଂଯମଯୋଗନାମକ' ଷଷ୍ଠ ଅଧ୍ୟାୟ ପୂର୍ଣ୍ଣ ହେଲା"
  ],
  "adhyaya-7" : [
    "ଶ୍ରୀ ଭଗବାନ କହିଲେ - ହେ ପୃଥାନନ୍ଦନ ! ମୋ' ଠାରେ ଆସକ୍ତମନା (ଅନୁରକ୍ତ ) ହୋଇ ଓ ମୋର ଆଶ୍ରିତ ହୋଇ ଯୋଗାଭ୍ୟାସ କରିବା ଦ୍ବାରା ତୁମେ ମୋର ସମଗ୍ରରୂପକୁ ଯେପରି ନିଃସନ୍ଦେହରେ ଜାଣିବ, ତାହା ଶୁଣ। 7.1",

    "ମୁଁ ତୁମକୁ ଏହି ବିଜ୍ଞାନ ସମନ୍ବିତ ଜ୍ଞାନ ପୂର୍ଣ୍ଣରୂପେ କହିବି, ଯାହା ଜାଣିବାପରେ ଏ ବିଷୟରେ ଆଉ କିଛି ଜାଣିବାକୁ ବାକି ରହିବ ନାହିଁ। 7.2",

    "ହଜାର ହଜାର ମନୁଷ୍ୟଙ୍କ ମଧ୍ୟରୁ କେହି ଜଣେ ସିଦ୍ଧି ବା କଲ୍ୟାଣ ନିମନ୍ତେ ପ୍ରଯତ୍ନ କରେ ଏବଂ ସେହି ପ୍ରଯତ୍ନଶୀଳ ସିଦ୍ଧ ( ମୁକ୍ତ ପୁରୁଷ )ମାନଙ୍କ ମଧ୍ୟରୁ କେହି ଜଣେ ମୋତେ ଯଥାର୍ଥରୂପେ ଜାଣେ। 7.3",

    "ପୃଥିବୀ, ଜଳ, ତେଜ, ବାୟୁ ଓ ଆକାଶ ନାମକ ପଞ୍ଚମହାଭୂତ  ଏବଂ ମନ, ବୁଦ୍ଧି ତଥା ଅହଂକାର -- 'ମୋର ଅପରା ପ୍ରକୃତି' ଏପରି ଆଠ ପ୍ରକାରର। ହେ ମହାବାହୁ ! ଯାହାଦ୍ବାରା ଏ ଜଗତ ଧାରଣ କରାଯାଇଛି (ଅର୍ଥାତ୍ ଯେ ଜଗତକୁ ଅସ୍ତିତ୍ବ ପ୍ରଦାନ କରିଛି) , ମୋର ସେହି ଜୀବରୂପା ପରା ପ୍ରକୃତି 'ଅପରା ପ୍ରକୃତି' ଠାରୁ ଭିନ୍ନ ବୋଲି ଜାଣ। 7.4-7.5",
  
    "ପୃଥିବୀ, ଜଳ, ତେଜ, ବାୟୁ ଓ ଆକାଶ ନାମକ ପଞ୍ଚମହାଭୂତ  ଏବଂ ମନ, ବୁଦ୍ଧି ତଥା ଅହଂକାର -- 'ମୋର ଅପରା ପ୍ରକୃତି' ଏପରି ଆଠ ପ୍ରକାରର। ହେ ମହାବାହୁ ! ଯାହାଦ୍ବାରା ଏ ଜଗତ ଧାରଣ କରାଯାଇଛି (ଅର୍ଥାତ୍ ଯେ ଜଗତକୁ ଅସ୍ତିତ୍ବ ପ୍ରଦାନ କରିଛି) , ମୋର ସେହି ଜୀବରୂପା ପରା ପ୍ରକୃତି 'ଅପରା ପ୍ରକୃତି' ଠାରୁ ଭିନ୍ନ ବୋଲି ଜାଣ। 7.4-7.5",

    "ହେ ଅର୍ଜୁ୍ନ ! ତୁମେ ଜାଣ ଯେ, ସକଳ ଭୂତ ଏହି ଦୁଇ ପ୍ରକୃତିରୁ ହିଁ ଜାତ ହୋଇଛନ୍ତି ଏବଂ ମୁଁ ସମଗ୍ର ଜଗତର ଉତ୍ପତ୍ତି ତଥା ପ୍ରଳୟର ହେତୁ, ଅର୍ଥାତ୍ ମୁଁ ସମ୍ପୂର୍ଣ୍ଣ ଜଗତର ମୂଳ କାରଣ । 7.6",

    "ହେ ଧନଞ୍ଜୟ ! ମୋ ବ୍ୟତୀତ ଏହି ଜଗତର ଅନ୍ୟ କୌଣସି କିଞ୍ଚିନ୍ମାତ୍ର କାରଣ ଓ କାର୍ଯ୍ୟ ନାହିଁ। ସୂତାରେ ତିଆରି ମାଳି ଦାନାଗୁଡିକ ସୂତାରେ ଗୁନ୍ଥା ହେଲାପରି ଏ ସମଗ୍ର ଜଗତରେ ମୁଁ ଓତପ୍ରୋତ ହୋଇ ରହିଛି। 7.7",

    "ହେ କୁନ୍ତୀପୁତ୍ର ! ମୁଁ ଜଳରେ ରସ, ଚନ୍ଦ୍ର ଓ ସୂର୍ଯ୍ୟଙ୍କ ଠାରେ ପ୍ରଭା, ସମସ୍ତ ବେଦରେ ପ୍ରଣବ (ଓଁ କାର), ଆକାଶେ ଶବ୍ଦ ଏବଂ ମନୁଷ୍ୟମାନଙ୍କ ଠାରେ ପୁରୁଷତ୍ବ ଅଟେ। 7.8",

    "ମୁଁ ପୃଥିବୀରେ ପବିତ୍ର ଗନ୍ଧ, ଅଗ୍ନିରେ ତେଜ, ସମସ୍ତ ପ୍ରାଣୀଙ୍କ ଠାରେ ଜୀବନୀଶକ୍ତି ଏବଂ ତପସ୍ବୀମାନଙ୍କର ତପସ୍ୟା ଅଟେ। 7.9",

    "ହେପୃଥାନନ୍ଦନ ! ମୋତେ ସମସ୍ତ ପ୍ରାଣୀଙ୍କର ଅନାଦି ବୀଜ ବୋଲି ଜାଣ। ମୁଁ ବୁଦ୍ଧିମାନ୍ ମାନଙ୍କର ବୁଦ୍ଧି ଓ ତେଜସ୍ବୀମାନଙ୍କର ତେଜ ଅଟେ। 7.10",

    "ହେ ଭରତଶ୍ରେଷ୍ଠ ଅର୍ଜୁନ ! ମୁଁ ବଳବାନ୍ ଙ୍କର କାମନା-ଆସକ୍ତି ବିବର୍ଜିତ ବଳ ଏବଂ ପ୍ରାଣୀମାନଙ୍କ ଠାରେ ଧର୍ମର ଅବିରୁଦ୍ଧ (ଧର୍ମଯୁକ୍ତ) କାମ ଅଟେ। 7.11",

    "(ଅଧିକ କ' ଣ ବା କହିବି) ଯେତେ ବି ସାତ୍ତ୍ବିକ, ରାଜସ ଓ ତାମସ ଭାବ ଅଛି, ସେ ସବୁ ମୋ' ଠାରୁ ଉତ୍ପନ୍ନ ବୋଲି ବୁଝ। କିନ୍ତୁ ମୁଁ ସେ ସବୁରେ ନାହିଁ କି ସେସବୁ ମୋ ଠାରେ ନାହାନ୍ତି। 7.12",

    "ଏହି ତିନି ଗୁଣରୂପକ ଭାବ ଦ୍ବାରା ମୋହିତ ଏ ସମଗ୍ର ଜଗତ (ପ୍ରାଣୀସମୂହ) ମୋତେ ତ୍ରିଗୁଣାତୀତ ଓ ଅବିନାଶୀରୂପେ ଜାଣେ ନାହିଁ। 7.13",

    "କାରଣ ମୋର ଏହି ଗୁଣମୟୀ ଦୈବୀ ମାୟା ବଡ ଦୁସ୍ତର ଅର୍ଥାତ୍ ଏଥିରୁ ପାରିହେବା ବଡ କଠିନ। ଯେଉଁମାନେ କେବଳ ମୋର ଶରଣାପନ୍ନ ହୁଅନ୍ତି, ସେମାନେ ଏହି ଦୈବୀ ମାୟାକୁ ତରିଯାଆନ୍ତି। 7.14",

    "କିନ୍ତୁ ଯେଉଁମାନଙ୍କ ଜ୍ଞାନକୁ ମାୟା ହରି ନେଇଛି, ଯେଉଁମାନେ ଆସୁର ସ୍ବଭାବର ଆଶ୍ରିତ ଏବଂ ନରାଧମ ତଥା ପାପାଚାରୀ, ସେଭଳି ମୂଢ ମନୁଷ୍ୟ ମୋର ଶରଣାପନ୍ନ ହୁଅନ୍ତି ନାହିଁ। 7.15",

    "ହେ ଭରତଶ୍ରେଷ୍ଠ ଅର୍ଜୁନ ! ପବିତ୍ର କର୍ମ କରୁଥିବା ଅର୍ଥାର୍ଥୀ, ଆର୍ତ୍ତ, ଜିଜ୍ଞାସୁ ଓ ଜ୍ଞାନୀ (ପ୍ରେମୀ ) --- ଏହି ଚାରିପ୍ରକାର ମନୁଷ୍ୟ ମୋତେ ଭଜିଥାନ୍ତି ଅର୍ଥାତ୍ ମୋର ଶରଣାପନ୍ନ ହୁଅନ୍ତି। 7.16",

    "ସେହି ଚାରିପ୍ରକାର ଭକ୍ତଙ୍କ ମଧ୍ୟରୁ ମୋ' ଠାରେ ନିତ୍ୟଯୁକ୍ତ ଅନନ୍ୟ ଭକ୍ତିସମ୍ପନ୍ନ ଜ୍ଞାନୀ ଅର୍ଥାତ୍ ପ୍ରେମୀଭକ୍ତ ଶ୍ରେଷ୍ଠ, କାରଣ ଜ୍ଞାନୀଭକ୍ତର ମୁଁ ଅତି ପ୍ରିୟ ଏବଂ ସେ ମଧ୍ୟ ମୋର ଅତି ପ୍ରିୟ। 7.17",

    "ପୂର୍ବୋକ୍ତ ସମସ୍ତ ଭକ୍ତ ବଡ ଉଦାର (ମହାନ୍)। କିନ୍ତୁ ମୋ ମତରେ ଜ୍ଞାନୀ (ପ୍ରେମୀ) ମୋର ସ୍ବରୂପ, କାରଣ ସେ ମୋ ସହ ଅଭିନ୍ନ ଏବଂ ମୋଠାରୁ ବଳି ଅନ୍ୟ କୌଣସି ଶ୍ରେଷ୍ଠ ଗତି ନାହିଁ ବୋଲି ଜାଣି ମୋ' ଠାରେ ସେ ଦୃଢଭାବରେ ପ୍ରତିଷ୍ଠିତ ଅଟେ। 7.18",

    "ଅନେକ ଜନ୍ମର ଅନ୍ତିମ ଜନ୍ମରେ ଅର୍ଥାତ୍ ମନୁଷ୍ୟ ଜନ୍ମରେ 'ସବୁକିଛି ପରମାତ୍ମା' --- ଏହି ଜ୍ଞାନସମ୍ପନ୍ନ ଯେଉଁ ବ୍ୟକ୍ତି ମୋର ଶରଣାପନ୍ନ ହୁଏ, ସେ ମହାତ୍ମା ଅତ୍ୟନ୍ତ ଦୁର୍ଲଭ । 7.19",

    "ସେହି ସେହି କାମନା ଦ୍ବାରା ଯେଉଁମାନଙ୍କର ଜ୍ଞାନ ଅପହୃତ ହୋଇଯାଇଛି, ସେମାନେ ନିଜ ନିଜ ପ୍ରକୃତି ବା ସ୍ବଭାବ ଦ୍ବାରା ନିୟନ୍ତ୍ରିତ ହୋଇ ଅନ୍ୟ ଦେବତାମାନଙ୍କର ବିବିଧ ନିୟମ ଧାରଣ କରି ସେହି ଦେବତାଗଣଙ୍କ ଠାରେ ଶରଣ ପଶନ୍ତି। 7.20",

    "ଯେଉଁ ଭକ୍ତମାନେ ଯେଉଁ ଯେଉଁ ଦେବତାଙ୍କୁ ଶ୍ରଦ୍ଧା ସହକାରେ ପୂଜା କରିବାକୁ ଇଛା କରନ୍ତି, ସେହି ସେହି ଦେବତାଙ୍କ ପ୍ରତି ମୁଁ ସେମାନଙ୍କ ଶ୍ରଦ୍ଧାକୁ ଦୃଢକରିଦିଏ। 7.21",

    "ସେହି (ମୋ ଦ୍ବାରା ଦୃଢ କରାଯାଇଥିବା) ଶ୍ରଦ୍ଧାରେ ଯୁକ୍ତ ହୋଇ ସେହି ମନୁଷ୍ୟ ସକାମଭାବରେ ନିଜ ଉପାସ୍ୟ ଦେବତାଙ୍କୁ ଉପାସନା କରେ ଏବଂ ତାର କାମନା ମଧ୍ୟ ପୂରଣ ହୁଏ। କିନ୍ତୁ ମୋ ଦ୍ବାରା ହିଁ ସେହି କାମନାପୂର୍ତ୍ତିର ବିଧାନ କରାଯାଇଥାଏ।  7.22",

    "କିନ୍ତୁ ସେଭଳି ତୁଚ୍ଛ ବୁଦ୍ଧିଯୁକ୍ତ ମନୁଷ୍ୟଙ୍କୁ ଦେବତାମାନଙ୍କ ଆରାଧନାରୁ ନାଶବାନ୍ ଫଳ ମିଳେ। ଦେବଗଣଙ୍କ ଉପାସକ ଦେବତାମାନଙ୍କୁ ପ୍ରାପ୍ତ ହୁଅନ୍ତି ଏବଂ ମୋର ଭକ୍ତମାନେ ମୋତେ ହିଁ ପ୍ରାପ୍ତ ହୁଅନ୍ତି। 7.23",

    "ମୁଁ ଅବ୍ୟକ୍ତ (ମନ ଓ ଇନ୍ଦ୍ରିୟମାନଙ୍କ ଠାରୁ ଅତୀତ) ଅଟେ । କିନ୍ତୁ ବୁଦ୍ଧିହୀନ ମନୁଷ୍ୟ ମୋର ପରମ, ଅବିନାଶୀ ଓ ସର୍ବଶ୍ରେଷ୍ଠ ଭାବକୁ ନ ଜାଣି ମୋତେ ଅର୍ଥାତ୍ ସଚ୍ଚିଦାନନ୍ଦ ପରମାତ୍ମାଙ୍କୁ ମନୁଷ୍ୟପରି ଶରୀରଧାରୀ ମନେ କରନ୍ତି। 7.24",

    "ଏ ଯେଉଁ ମୂଢ ଜନସମୁଦାୟ ମୋତେ ଅଜ ଓ ଅବିନାଶୀ ବୋଲି ଠିକ୍ ଭାବେ ଜାଣେ ନାହିଁ (ମାନେ ନାହିଁ), ଯୋଗମାୟାରେ ସମାବୃତ ହୋଇଥିବାରୁ ମୁଁ ସେ ସମସ୍ତଙ୍କ ସମ୍ମୁଖରେ ପ୍ରକାଶିତ ହୁଏ ନାହିଁ ।  7.25",

    "ହେ ଅର୍ଜୁନ ! ଯେଉଁ ପ୍ରାଣୀମାନେ ପୂର୍ବରୁ ଥିଲେ, ବର୍ତ୍ତମାନ ଅଛନ୍ତି ଏବଂ ଭବିଷ୍ୟତରେ ଜାତ ହେବେ, ସେ ସମସ୍ତଙ୍କୁ ମୁଁ ଜାଣେ। କିନ୍ତୁ ମୋତେ (ଭକ୍ତ ବ୍ୟତୀତ) କେହି ଜାଣେ ନାହିଁ। 7.26",

    "କାରଣ ହେ ଭରତବଂଶଜ ଅର୍ଜୁନ ! ଇଛା-ଦ୍ବେଷଜନିତ ଦ୍ବନ୍ଦ୍ବ ଓ ମୋହରେ ମୋହିତ ହୋଇ ସମସ୍ତ ପ୍ରାଣୀ ସଂସାରରେ ଅନାଦିକାଳରୁ ମୂଢତା ବା ଜନ୍ମମୃତ୍ୟୁ ପ୍ରାପ୍ତ ହେଉଛନ୍ତି। 7.27",

    "କିନ୍ତୁ୍ ଯେଉଁ ପୂଣ୍ୟକର୍ମାମାନଙ୍କର ପାପ ନଷ୍ଟ ହୋଇଯାଇଛି, ସେଭଳି ଦ୍ବନ୍ଦ୍ବମୋହରହିତ ମନୁଷ୍ୟ ଦୃଢବ୍ରତୀ ହୋଇ ମୋତେ ଭଜିଥାନ୍ତି। 7.28",

    "ଜରାବସ୍ଥା ଓ ମୃତ୍ୟୁରୁ ମୂକ୍ତି ପାଇବା ଉଦ୍ଦେଶ୍ୟରେ ଯେଉଁମାନେ ମୋର ଆଶ୍ରିତ ହୋଇ ପ୍ରଯତ୍ନ କରନ୍ତି, ସେମାନେ ସେହି ବ୍ରହ୍ମଙ୍କୁ,  ସମସ୍ତ ଅଧ୍ୟାତ୍ମକୁ ଓ ସମସ୍ତ କର୍ମକୁ ଜାଣି ଯାଆନ୍ତି। 7.29",

    "ଯେଉଁମାନେ ଅଧିଭୂତ, ଅଧିଦୈବ ଓ ଅଧିଯଜ୍ଞ ସହ ମୋତେ ଜାଣନ୍ତି, ସେଭଳି ଯୁକ୍ତଚିତ୍ତ ମନୁଷ୍ୟ ଅନ୍ତକାଳରେ ମଧ୍ୟ ମୋତେ ହିଁ ଜାଣନ୍ତି ଅର୍ଥାତ୍ ମୋତେ ପ୍ରାପ୍ତ ହୁଅନ୍ତି। 7.30",

    "ଏହି ପ୍ରକାର ଓଁ ତତ୍ ସତ୍ -- ଏହି ଭଗବତ୍ ନାମର ଉଚ୍ଚାରଣପୂର୍ବକ ବ୍ରହ୍ମବିଦ୍ୟା ଓ ଯୋଗଶାସ୍ତ୍ରମୟ ଶ୍ରୀମଦ୍ଭଗବଦ୍ଗୀତା ଉପନିଷଦରୂପେ ଶ୍ରୀକୃଷ୍ଣ ଏବଂ ଅର୍ଜୁନଙ୍କ ସମ୍ବାଦରେ 'ଜ୍ଞାନବିଜ୍ଞାନଯୋଗନାମକ' ସପ୍ତମ ଅଧ୍ୟାୟ ପୂର୍ଣ୍ଣ ହେଲା"
  ],
  "adhyaya-8" : [
    "ଅର୍ଜୁନ କହିଲେ -- ହେ ପୁରୁଷୋତ୍ତମ ! ସେହି ବ୍ରହ୍ମ କଣ ? ଅଧ୍ୟାତ୍ମ କଣ ? କର୍ମ କଣ ? ଅଧିଭୂତ କାହାକୁ କୁହାଯାଇଛି ଏବଂ ଅଧିଦୈବ କାହାକୁ କହନ୍ତି ? 8.1",

    "ହେ ମଧୁସୂଦନ ! ଏଠାରେ ଅଧିଯଜ୍ଞ କିଏ ଓ ସେ ଏହି ଶରୀରରେ କିପରି ଅଛନ୍ତି ତଥା ଯୁକ୍ତଚିତ୍ତ ପୁରୁଷମାନଙ୍କ ଦ୍ବାରା ମରଣକାଳରେ ଆପଣ କିପରି ଜାଣିହୁଅନ୍ତି ? 8.2",

    "ଶ୍ରୀ ଭଗବାନ କହିଲେ --- ପରମ ଅକ୍ଷର ବ୍ରହ୍ମ ଅଟେ । ନିଜ ସ୍ବରୂପ ଅର୍ଥାତ୍ ଜୀବାତ୍ମା ଅଧ୍ୟାତ୍ମ ନାମରେ କଥିତ ହୁଏ, ତଥା ପ୍ରାଣୀମାନଙ୍କର ଉତ୍ପତ୍ତି ଓ ଅଭ୍ୟୁଦୟ କରୁଥିବା ବିସର୍ଗ ଅର୍ଥାତ୍ ତ୍ୟାଗକୁ କର୍ମ କୁହାଯାଏ । 8.3",

    "ଉତ୍ପତ୍ତି ବିନାଶଶୀଳ ସମସ୍ତ ପଦାର୍ଥ ହେଉଛି ଅଧିଭୂତ । ହିରଣ୍ୟମୟ ପୁରୁଷ ହିଁ ଅଧିଦୈବ । ହେ ଦେହଧାରୀମାନଙ୍କ ମଧ୍ୟରେ ଶ୍ରେଷ୍ଠ ଅର୍ଜୁନ ! ଏହି ଶରୀରରେ ମୁଁ ବାସୁଦେବ ହିଁ ଅନ୍ତର୍ଯ୍ୟାମୀ ରୂପରେ ଅଧିଯଜ୍ଞ ଅଟେ । 8.4",

    "ଯେଉଁ ପୁରୁଷ ଅନ୍ତକାଳରେ ହେଲେ ବି ମୋତେ ହିଁ ସ୍ମରଣ କରି କରି ଦେହ ତ୍ୟାଗ କରନ୍ତି, ସେ ମୋର ସାକ୍ଷାତ୍ ସ୍ବରୂପ ପ୍ରାପ୍ତ ହୁଅନ୍ତି, ଏଥିରେ ତିଳେମାତ୍ର ସନ୍ଦେହ ନାହିଁ । 8.5",

    "ହେେ କୁନ୍ତୀପୁତ୍ର ଅର୍ଜୁନ ! ଏହି ମନୁଷ୍ୟ ଅନ୍ତକାଳରେ ଯେଉଁ ଯେଉଁ ଭାବ ସ୍ମରଣ କରି କରି ଶରୀର ତ୍ୟାଗ କରେ, ସେ ତାହା ହିଁ ପ୍ରାପ୍ତ ହୁଏ, କାରଣ ସେ ସବୁବେଳେ ସେହି ଭାବରେ ଭାବିତ ହୋଇ ରହିଥାଏ । 8.6",

    "ଅତଏବ ହେ ଅର୍ଜୁନ ! ତୁମେ ସବୁ ସମୟରେ ନିରନ୍ତର ମୋତେ ସ୍ମରଣ କର ଓ ଯୁଦ୍ଧ ମଧ୍ୟ କର । ଏହିପ୍ରକାରେ ମୋଠାରେ ଅର୍ପଣ କରାଯାଇଥିବା ମନ-ବୁଦ୍ଧିରେ ଯୁକ୍ତ ହୋଇ ତୁମେ ନିଃସନ୍ଦେହ ମୋତେ ହିଁ ପ୍ରାପ୍ତ ହେବ । 8.7",

    "ହେ ପାର୍ଥ ! ପରମେଶ୍ବରଙ୍କ ଧ୍ୟାନର ଅଭ୍ୟାସରୂପକ ଯୋଗରେ ଯୁକ୍ତ ହୋଇ ଅନ୍ୟ ଆଡେ ଯାଉ ନ ଥିବା ଚିତ୍ତଦ୍ବାରା ନିରନ୍ତର ଚିନ୍ତନ କରୁଥିବା ମନୁଷ୍ୟ ପରମପ୍ରକାଶରୂପ ଦିବ୍ୟ ପୁରୁଷଙ୍କୁ ଅର୍ଥାତ୍ ପରମେଶ୍ବରଙ୍କୁ ହିଁ ପ୍ରାପ୍ତ ହୁଅନ୍ତି। 8.8",

    "ଯେେଉଁ ପୁରୁଷ ସର୍ବଜ୍ଞ, ଅନାଦି, ସର୍ବନିୟନ୍ତା, ସୂକ୍ଷ୍ମାତିସୂକ୍ଷ୍ମ, ସମସ୍ତଙ୍କର ଧାରଣ-ପୋଷଣକାରୀ, ଅଚିନ୍ତ୍ୟସ୍ବରୂପ, ସୂର୍ଯ୍ୟ ସଦୃଶ ନିତ୍ୟ, ଚେତନ, ପ୍ରକାଶରୂପ ଓ ଅବିଦ୍ୟାରୁ ସମ୍ପୂର୍ଣ୍ଣ ଅତୀତ  ଶୁଦ୍ଧ ସଚ୍ଚିଦାନନ୍ଦଘନ ପରମେଶ୍ବରଙ୍କୁ ସ୍ମରଣ କରନ୍ତି । 8.9",

    "ସେହି ଭକ୍ତିଯୁକ୍ତ ପୁରୁଷ ଅନ୍ତକାଳରେ ମଧ୍ୟ ଯୋଗ ବଳରେ ଦୁଇ ଭ୍ରୂଲତାର ମଧ୍ୟବର୍ତ୍ତି ସ୍ଥାନରେ ପ୍ରାଣକୁ ଭଲରୂପେ ସ୍ଥାପନ କରି ଏବଂ ନିଶ୍ଚଳ ମନ ଦ୍ବାରା ସ୍ମରଣ କରି କରି ସେହି ଦିବ୍ୟରୂପ ପରମ ପୁରୁଷ ପରମାତ୍ମାଙ୍କୁ ହିଁ ପ୍ରାପ୍ତ ହୁଅନ୍ତି । 8.10",

    "ଏବଂ ହେ ଅର୍ଜୁନ ! ବେଦଜ୍ଞ ବିଦ୍ୱାନ୍ ମାନେ ଯେଉଁ ସଚ୍ଚିଦାନନ୍ଦଘନରୂପକ ପରମପଦକୁ ଅବିନାଶୀ ବୋଲି କହନ୍ତି, ଆସକ୍ତିଶୂନ୍ୟ ଯତ୍ନଶୀଳ ସନ୍ନ୍ୟାସୀ ମହାତ୍ମାମାନେ ଯାହାଙ୍କଠାରେ ପ୍ରବେଶ କରନ୍ତି ଏବଂ ଯେଉଁ ପରମପଦ ଚାହୁଁଥିବା ବ୍ରହ୍ମଚାରୀମାନେ ବ୍ରହ୍ମଚର୍ଯ୍ୟ ଆଚରଣ କରନ୍ତି, ସେହି ପରମପଦ ମୁଁ ତୁମକୁ ସଂକ୍ଷେପରେ କହିଦେବି । 8.11",

    "ସକଳ ଇନ୍ଦ୍ରିୟର ଦ୍ବାର ରୁଦ୍ଧ କରି ତଥା ମନକୁ ହୃଦୟରେ ସ୍ଥିର କରି, ପୁଣି ସେହି ବିଜିତ ମନଦ୍ୱାରା ପ୍ରାଣକୁ ମସ୍ତକରେ ସ୍ଥାପନ କରି, ପରମାତ୍ମସମ୍ବନ୍ଧୀ ଯୋଗଧାରଣାରେ ସ୍ଥିତ ହୋଇ, ଯେଉଁ ପୁରୁଷ ଓଁ - ଏହି ଏକାକ୍ଷରରୂପକ ବ୍ରହ୍ମକୁ ଉଚ୍ଚାରଣ କରି କରି ଏବଂ ତାହାର ଅର୍ଥସ୍ୱରୂପ ମୋତେ ଅର୍ଥାତ୍ ନିର୍ଗୁଣ ବ୍ରହ୍ମକୁ ଚିନ୍ତନ କରି କରି ଶରୀର ଛାଡି ପ୍ରୟାଣ କରନ୍ତି, ସେହି ପୁରୁଷ ପରମଗତି ପ୍ରାପ୍ତ ହୁଅନ୍ତି । 8.12-8.13",

    "ସକଳ ଇନ୍ଦ୍ରିୟର ଦ୍ବାର ରୁଦ୍ଧ କରି ତଥା ମନକୁ ହୃଦୟରେ ସ୍ଥିର କରି, ପୁଣି ସେହି ବିଜିତ ମନଦ୍ୱାରା ପ୍ରାଣକୁ ମସ୍ତକରେ ସ୍ଥାପନ କରି, ପରମାତ୍ମସମ୍ବନ୍ଧୀ ଯୋଗଧାରଣାରେ ସ୍ଥିତ ହୋଇ, ଯେଉଁ ପୁରୁଷ ଓଁ - ଏହି ଏକାକ୍ଷରରୂପକ ବ୍ରହ୍ମକୁ ଉଚ୍ଚାରଣ କରି କରି ଏବଂ ତାହାର ଅର୍ଥସ୍ୱରୂପ ମୋତେ ଅର୍ଥାତ୍ ନିର୍ଗୁଣ ବ୍ରହ୍ମକୁ ଚିନ୍ତନ କରି କରି ଶରୀର ଛାଡି ପ୍ରୟାଣ କରନ୍ତି, ସେହି ପୁରୁଷ ପରମଗତି ପ୍ରାପ୍ତ ହୁଅନ୍ତି । 8.12-8.13",

    "ହେ ଅର୍ଜୁନ ! -- ଯେଉଁ ପୁରୁଷ ମୋଠାରେ ଅନନ୍ୟଚିତ୍ତ ହୋଇ ସଦାସର୍ବଦା ନିରନ୍ତର ମୋତେ ଅର୍ଥାତ୍ ପୁରୁଷୋତ୍ତମଙ୍କୁ ସ୍ମରଣ କରନ୍ତି, ମୋଠାରେ ନିତ୍ୟନିରନ୍ତର ଯୁକ୍ତ ହୋଇଥିବା ସେହି ଯୋଗୀଙ୍କ ପାଇଁ ମୁଁ ସୁଲଭ ଅର୍ଥାତ୍ ମୁଁ ତାଙ୍କୁ ସହଜରେ ପ୍ରାପ୍ତ ହୋଇଯାଏ । 8.14",

    "ପରମସିଦ୍ଧିପ୍ରାପ୍ତ ମହାତ୍ମାମାନେ ମୋତେ ପ୍ରାପ୍ତ ହୋଇ ଦୁଃଖର ଗୃହରୂପକ କ୍ଷଣଭଙ୍ଗୁର ପୁନର୍ଜନ୍ମ ପାଆନ୍ତି ନାହିଁ । 8.15",

    "ହେେ ଅର୍ଜୁନ ! ବ୍ରହ୍ମଲୋକପର୍ଯ୍ୟନ୍ତ ସମସ୍ତ ଲୋକ ପୁନରାବର୍ତ୍ତୀ, କିନ୍ତୁ ହେ କୁନ୍ତୀପୁତ୍ର ! ମୋତେ ପ୍ରାପ୍ତ ହେଲେ ପୁନର୍ଜନ୍ମ ହୁଏ ନାହିଁ, କାରଣ ମୁଁ କାଳାତୀତ ଏବଂ ସେସବୁ ବ୍ରହ୍ମାଦିଲୋକ କାଳଦ୍ବାରା ସୀମିତ ହୋଇଥିବାରୁ ଅନିତ୍ୟ । 8.16",

    "ବ୍ରହ୍ମାଙ୍କର ଗୋଟିଏ ଦିନ ଏକସହସ୍ର ଚତୁର୍ଯୁଗୀ ଅବଧିବିଶିଷ୍ଟ ବୋଲି ଯେଉଁ ପୁରୁଷମାନେ ତତ୍ତ୍ୱତଃ ଜାଣନ୍ତି, ସେହି ଯୋଗୀଜନମାନେ କାଳର ତତ୍ତ୍ୱ ଜାଣନ୍ତି । 8.17",

    "ସକଳ ଚରାଚର ଜୀବମାନେ ବ୍ରହ୍ମାଙ୍କ ଦିନର ଆରମ୍ଭରେ ଅବ୍ୟକ୍ତରୁ ଅର୍ଥାତ୍ ବ୍ରହ୍ମାଙ୍କ ସୁକ୍ଷ୍ମଶରୀରରୁ ଉତ୍ପନ୍ନ ହୁଅନ୍ତି, ଏବଂ ବ୍ରହ୍ମାଙ୍କ ରାତ୍ରୀର ଆରମ୍ଭରେ ବ୍ରହ୍ମାଙ୍କର ଅବ୍ୟକ୍ତ ନାମକ ସେହି ସୁକ୍ଷ୍ମଶରୀରରେ ହିଁ ଲୀନ ହୋଇଯାଆନ୍ତି । 8.18",

    "ହେ ପାର୍ଥ ! ସେହି ଭୂତସମୁଦାୟ ବାରମ୍ବାର ଉତ୍ପନ୍ନ ହୋଇ ପ୍ରକୃତିର ପରବଶ ହେବାରୁ ରାତ୍ରୀର ପ୍ରବେଶକାଳରେ ଲୀନ ହୋଇଯାଏ ଏବଂ ଦିନର ପ୍ରବେଶକାଳରେ ପୁନର୍ବାର ଉତ୍ପନ୍ନ ହୁଏ । 8.19",

    "ଉକ୍ତ ଅବ୍ୟକ୍ତଠାରୁ ମଧ୍ୟ ଯେଉଁ ଅତିଶ୍ରେଷ୍ଠ ଅର୍ଥାତ୍ ଯେଉଁ ବିଲକ୍ଷଣ ସନାତନ ଅବ୍ୟକ୍ତ ଭାବ ରହିଛି, ସେହି ପରମ ଦିବ୍ୟ ପୁରୁଷ, ସକଳ ଚରାଚର ପ୍ରାଣୀ ନଷ୍ଟ ହୋଇଗଲେ ମଧ୍ୟ ବିନାଶପ୍ରାପ୍ତ ହୁଅନ୍ତି ନାହିଁ । 8.20",

    "ଯେଉଁ ଅବ୍ୟକ୍ତକୁ ଅକ୍ଷର ବୋଲି କୁହାଯାଇଛି, ସେହି ଅକ୍ଷର ନାମକ ଅବ୍ୟକ୍ତଭାବକୁ ପରମଗତି କହନ୍ତି ତଥା ଯେଉଁ ସନାତନ ଅବ୍ୟକ୍ତଭାବ ପ୍ରାପ୍ତ ହେଲେ ମନୁଷ୍ୟମାନେ ଆଉ ଫେରି ଆସନ୍ତି ନାହିଁ, ତାହା ମୋର ପରମଧାମ ଅଟେ । 8.21",

    "ହେ ପାାର୍ଥ ! ଯେଉଁ ପରମାତ୍ମାଙ୍କ ଅନ୍ତର୍ଗତ ହୋଇ ସକଳ ଭୂତ ଅଛନ୍ତି ଏବଂ ଯେଉଁ ସଚ୍ଚିଦାନନ୍ଦଘନ ପରମାତ୍ମାଙ୍କ ଦ୍ବାରା ଏହି ସମଗ୍ର ଜଗତ ପରିପୂର୍ଣ୍ଣ ହୋଇଛି, ସେହି ସନାତନ ଅବ୍ୟକ୍ତ ପରମ ପୁରୁଷ ଅନନ୍ୟ ଭକ୍ତି ଦ୍ବାରା ହିଁ ପ୍ରାପ୍ତ ହେବା ଯୋଗ୍ୟ । 8.22",

    "ହେ ଅର୍ଜୁ୍ନ ! ଯେଉଁ କାଳରେ ଶରୀର ଛାଡି ଯାଉଥିବା ଯୋଗୀମାନେ ପୁନର୍ଜନ୍ମ ହେଉ ନ ଥିବା ଗତି ପ୍ରାପ୍ତ ହୁଅନ୍ତି ଏବଂ ଯେଉଁ କାଳରେ ଶରୀର ଛାଡି ଯାଉଥିବା ଯୋଗୀମାନେ ପୁନର୍ଜନ୍ମ ହେଉଥିବା ଗତି ହିଁ ପ୍ରାପ୍ତ ହୁଅନ୍ତି, ମୁଁ ସେହି ଦୁଇ କାଳର ଅର୍ଥାତ୍ ଦୁଇ ମାର୍ଗର କଥା କହିବି । 8.23",

    "ଯେଉଁ  ମାର୍ଗରେ ଜ୍ୟୋତିର୍ମୟ ଅଗ୍ନି - ଅଭିମାନୀ ଦେବତା, ଦିନର ଅଭିମାନୀ ଦେବତା, ଶୁକ୍ଳପକ୍ଷର ଅଭିମାନୀ ଦେବତା ଓ ଉତ୍ତରାୟଣର ଛଅମାସର ଅଭିମାନୀ ଦେବତା ଅଛନ୍ତି, ମୃତ୍ୟୁ ପରେ ସେହି ମାର୍ଗରେ ଯାଇଥିବା ବ୍ରହ୍ମବେତ୍ତା ଯୋଗୀମାନେ ସେହି ଦେବତାମାନଙ୍କ ଦ୍ବାରା କ୍ରମାନ୍ୱୟରେ ନିଆଯାଇ ବ୍ରହ୍ମଙ୍କୁ ପ୍ରାପ୍ତ ହୁଅନ୍ତି । 8.24",

    "ଯେଉଁ ମାର୍ଗରେ ଧୂମାଭିମାନୀ ଦେବତା, ରାତ୍ରୀ-ଅଭିମାନୀ ଦେବତା, କୃଷ୍ଣପକ୍ଷର ଅଭିମାନୀ ଦେବତା ଏବଂ ଦକ୍ଷିଣାୟନ ଛଅମାସର ଅଭିମାନୀ ଦେବତା ଅଛନ୍ତି, ମୃତ୍ୟୁ ପରେ ସେହି ମାର୍ଗରେ ଯାଇଥିବା ସକାମ କର୍ମ କରିଥିବା ଯୋଗୀ ଉପରୋକ୍ତ ଦେବତାମାନଙ୍କ ଦ୍ବାରା କ୍ରମଶଃ ଊର୍ଦ୍ଧ୍ୱଲୋକକୁ ନିଆଯାଇ, ଚନ୍ଦ୍ରମାଙ୍କ ଜ୍ୟୋତୀ ପ୍ରାପ୍ତ ହୋଇ , ସ୍ୱର୍ଗରେ ନିଜ ଶୁଭକର୍ମର ଫଳ ଭୋଗ କରିସାରି ଫେରି ଆସେ । 8.25",

    "କାରଣ, ଜଗତରେ ଶୁକ୍ଳ ଓ କୃଷ୍ଣ - ଏହି ଦୁଇ ମାର୍ଗକୁ ଅର୍ଥାତ୍ ଦେବଯାନ ଓ ପିତୃଯାନ ମାର୍ଗକୁ ସନାତନ ବୋଲି ଗ୍ରହଣ କରାଯାଏ । ଏ ଦୁଇଟି ମଧ୍ୟରୁ ପ୍ରଥମଟି ଧରି ଗମନ କଲେ ଆଉ ପ୍ରତ୍ୟାବର୍ତ୍ତନ କରିବାକୁ ପଡେ ନାହିଁ, ପରମଗତି ମିଳେ ଏବଂ ଅନ୍ୟଟି ଧରି ଗମନ କଲେ ପୁଣି ଫେରି ଆସିବାକୁ ହୁଏ ଅର୍ଥାତ୍ ଜନ୍ମ-ମୃତ୍ୟୁ ପ୍ରାପ୍ତ ହୁଏ । 8.26",

    "ହେ ପାର୍ଥ ! ଏପରିଭାବରେ ଏହି ମାର୍ଗଦୁଇଟିକୁ ତତ୍ତ୍ୱତଃ ଜାଣିଗଲେ କୌଣସି ଯୋଗୀ ଆଉ ମୋହଗ୍ରସ୍ତ ହୁଅନ୍ତି ନାହିଁ । ତେଣୁ ହେ ଅର୍ଜୁନ ! ତୁମେ ସର୍ବଦା ସମବୁଦ୍ଧିରୂପକ ଯୋଗଦ୍ବାରା ଯୁକ୍ତ ହୁଅ ଅର୍ଥାତ୍ ନିରନ୍ତର ମୋର ପ୍ରାପ୍ତିପାଇଁ ସାଧନାରତ ହୁଅ ।  8.27",

    "ଯୋଗୀ ପୁରୁଷ ଏହି ରହସ୍ୟ ତତ୍ତ୍ୱତଃ ଜାଣିଯାଇ ବେଦ ପଢିବାରେ ତଥା ଯଜ୍ଞ, ତପ ଓ ଦାନାଦି କର୍ମମାନଙ୍କରେ ଯେଉଁ ପୁଣ୍ୟଫଳ ମିଳେ ବୋଲି କୁହାଯାଇଛି, ସେସବୁକୁ ନିଃସନ୍ଦେହ ଉଲଂଘନ କରିଯାନ୍ତି ଏବଂ ସନାତନ ପରମପଦ ପ୍ରାପ୍ତ ହୁଅନ୍ତି । 8.28",

    "ଏହି ପ୍ରକାର ଓଁ ତତ୍ ସତ୍ - ଏହି ଭଗବତ୍ ନାମର ଉଚ୍ଚାରଣପୂର୍ବକ ବ୍ରହ୍ମବିଦ୍ୟା ଓ ଯୋଗଶାସ୍ତ୍ରମୟ ଶ୍ରୀମଦ୍ଭଗବଦ୍ଗୀତା ଉପନିଷଦରୂପେ ଶ୍ରୀକୃଷ୍ଣ ଏବଂ ଅର୍ଜୁନଙ୍କ ସମ୍ବାଦରେ 'ଅକ୍ଷରବ୍ରହ୍ମଯୋଗନାମକ' ଅଷ୍ଟମ ଅଧ୍ୟାୟ ପୂର୍ଣ୍ଣ ହେଲା"
  ],
  "adhyaya-9" : [
    "ଶ୍ରୀଭଗବାନ କହିଲେ--- ତୁମେ ଦୋଷଦୃଷ୍ଟିରହିତ ହୋଇଥିବାରୁ ଏହି ଅତ୍ୟନ୍ତ ଗୋପନୀୟ ବିଜ୍ଞାନ ସମନ୍ବିତ ଜ୍ଞାନ ମୁଁ ପୁଣି ଥରେ ତୁମକୁ ଭଲଭାବେ କହିବି, ଯାହା ଜାଣିଲେ ତୁମେ ଅଶୁଭରୁ ଅର୍ଥାତ୍ ଜନ୍ମମରଣରୂପୀ ସଂସାରରୁ ମୁକ୍ତ ହୋଇଯିବ। 9.1",

    "ଏହି ବିଜ୍ଞାନ ସମନ୍ବିତ ଜ୍ଞାନ ଜୀବନରେ ଆଚରଣ କରିବା ଅତ୍ୟନ୍ତ ସହଜ। କାରଣ ଏଥିପାଇଁ କେବଳ 'ସବୁକିଛି ପରମାତ୍ମା 'ବୋଲି ସ୍ବୀକାର କରିବା ଯଥେଷ୍ଟ। ଏଥିରେ କୌଣସି ପ୍ରକାର ପରିଶ୍ରମ ଅଥବା ଅଭ୍ୟାସ ଲୋଡା ନାହିଁ। 9.2",

    "ହେ ପରନ୍ତପ ! ଏହି ଧର୍ମର ମହିମା ପ୍ରତି ଶ୍ରଦ୍ଧା ନ କରୁଥିବା ମନୁଷ୍ୟ ମୋତେ ନ ପାଇ ମୃତ୍ୟୁରୂପୀ ସଂସାର ମାର୍ଗରେ ପ୍ରତ୍ୟାବର୍ତ୍ତନ କରୁଥାନ୍ତି ଅର୍ଥାତ୍ ବାରମ୍ବାର ଜନ୍ମ ନେଉଥାନ୍ତି ଓ ମରୁଥାନ୍ତି। 9.3",

    "ଏ ସମଗ୍ର ସଂସାର ମୋର ନିରାକାର ସ୍ବରୂପରେ ବ୍ୟାପ୍ତ। ସବୁ ପ୍ରାଣୀ ମୋ ' ଠାରେ ରହିଛନ୍ତି; କିନ୍ତୁ ମୁଁ ସେମାନଙ୍କ ଠାରେ ରହି ନାହିଁ ତଥା ସେହି ପ୍ରାଣୀମାନେ ମଧ୍ୟ ମୋ' ଠାରେ ରହି ନାହାନ୍ତି। ମୋର ଏହି ଈଶ୍ବର ସମ୍ବନ୍ଧୀୟ ଯୋଗ (ସାମର୍ଥ୍ୟ)କୁ ଦେଖ! ସମସ୍ତ ପ୍ରାଣୀଙ୍କୁ ଉତ୍ପନ୍ନ କରୁଥିବା ଓ ପ୍ରାଣୀମାନଙ୍କର ଭରଣପୋଷଣ କରୁଥିବା ମୋର ସ୍ବରୂପ ସେହି ପ୍ରାଣୀମାନଙ୍କ ଠାରେ ନାହିଁ। 9.4",

    "ଏ ସମଗ୍ର ସଂସାର ମୋର ନିରାକାର ସ୍ବରୂପରେ ବ୍ୟାପ୍ତ। ସବୁ ପ୍ରାଣୀ ମୋ ' ଠାରେ ରହିଛନ୍ତି; କିନ୍ତୁ ମୁଁ ସେମାନଙ୍କ ଠାରେ ରହି ନାହିଁ ତଥା ସେହି ପ୍ରାଣୀମାନେ ମଧ୍ୟ ମୋ' ଠାରେ ରହି ନାହାନ୍ତି। ମୋର ଏହି ଈଶ୍ବର ସମ୍ବନ୍ଧୀୟ ଯୋଗ (ସାମର୍ଥ୍ୟ)କୁ ଦେଖ ! ସମସ୍ତ ପ୍ରାଣୀଙ୍କୁ ଉତ୍ପନ୍ନ କରୁଥିବା ଓ ପ୍ରାଣୀମାନଙ୍କର ଭରଣପୋଷଣ କରୁଥିବା ମୋର ସ୍ବରୂପ ସେହି ପ୍ରାଣୀମାନଙ୍କ ଠାରେ ନାହିଁ। 9.5",

    "ସର୍ବତ୍ର ବିଚରଣଶୀଳ ମହାନ୍ ବାୟୁ ଯେପରି ନିରନ୍ତର ଆକାଶରେ ସ୍ଥିତ , ସେହିପରି ସବୁ ପ୍ରାଣୀ ମୋ ' ଠାରେ ହିଁ ସ୍ଥିତ ବୋଲି ତୁମେ ମାନିନିଅ। 9.6",

    "ହେ କୁନ୍ତୀନନ୍ଦନ! କଳ୍ପମାନଙ୍କ ଶେଷରେ (ମହାପ୍ରଳୟ ସମୟରେ) ସବୁ ପ୍ରାଣୀ ମୋର ପ୍ରକୃତିକୁ ପ୍ରାପ୍ତ ହୁଅନ୍ତି ଅର୍ଥାତ୍ ତହିଁରେ ଲୀନ ହୁଅନ୍ତି ଏବଂ କଳ୍ପମାନଙ୍କର ଆରମ୍ଭରେ (ମହାସର୍ଗ ସମୟରେ) ମୁଁ ପୁଣି ସେମାନଙ୍କୁ ସର୍ଜନା କରେ। 9.7",

    "ପ୍ରକୃତି ବଶରେ ରହି ପରତନ୍ତ୍ର ହୋଇଥିବା ଏହି ସମସ୍ତ ପ୍ରାଣୀବର୍ଗଙ୍କୁ ମୁଁ [କଳ୍ପମାନଙ୍କର ଆରମ୍ଭରେ] ମୋର ପ୍ରକୃତିକୁ ଅଧୀନରେ ରଖି ବାରମ୍ବାର ସର୍ଜନା କରେ। 9.8",

    "ହେ ଧନଞ୍ଜୟ! ସେହି ସବୁ (ସୃଷ୍ଟି ରଚନାଦି) କର୍ମରେ ଅନାସକ୍ତ ଓ ଉଦାସୀନ ଭଳି ରହୁଥିବାରୁ କର୍ମଗୁଡିକ ମୋତେ ଆବଦ୍ଧ କରନ୍ତି ନାହି। 9.9",

    "ପ୍ରକୃତି ମୋ ଅଧ୍ୟକ୍ଷତାରେ ସମଗ୍ର ସଚରାଚର ଜଗତକୁ ସର୍ଜନା କରେ। ହେ କୁନ୍ତୀନନ୍ଦନ! ଏହି କାରଣରୁ ଜଗତରେ ବିବିଧ ପ୍ରକାର ପରିବର୍ତ୍ତନ ହେଉଛି। 9.10",

    "ମୂର୍ଖଲୋ କେ ସକଳ ପ୍ରାଣୀଙ୍କର ମହାନ୍ ଈଶ୍ବରରୂପକ ମୋର ପରମଭାବକୁ ନ ଜାଣି, ମୋତେ ମନୁଷ୍ୟଶରୀରର ଆଶ୍ରିତ ମନେକରି ଅର୍ଥାତ୍ ସାଧାରଣ ମନୁଷ୍ୟ ମନେକରି ଅବଜ୍ଞା କରନ୍ତି।  9.11",

    "ଯେଉଁମାନେ ଆସୁରୀ, ରାକ୍ଷସୀ ଓ ମୋହିନୀ ପ୍ରକୃତିର ଆଶ୍ରୟ ନିଅନ୍ତି, ସେପରି ଅବିବେକୀ ମନୁଷ୍ୟଙ୍କର ସମସ୍ତ ଆଶା, ସମସ୍ତ ଶୁଭକର୍ମ ଓ ସମସ୍ତ ଜ୍ଞାନ ବୃଥା ଅର୍ଥାତ୍ ସେମାନଙ୍କର ଆଶା, କର୍ମ ଓ ଜ୍ଞାନ (ବୁଦ୍ଧି)  ସତ୍ ଫଳ ପ୍ରଦାୟକ ହୁଏ ନାହିଁ। 9.12",

    "କିନ୍ତୁ ହେ ପୃଥାନନ୍ଦନ! ଦୈବୀ ପ୍ରକୃତିର ଆଶ୍ରିତ ଅନନ୍ୟମନା ମହାତ୍ମାଗଣ ମୋତେ ସମସ୍ତ ପ୍ରାଣୀଙ୍କର ଆଦି ଓ ଅବିନାଶୀ ବୋଲି ଜାଣି ମୋର ଆରାଧନା କରନ୍ତି। 9.13",

    "ମୋ' ଠାରେ ନିତ୍ୟ-ନିରନ୍ତର ସଂଯୁକ୍ତ ମନୁଷ୍ୟ ଦୃଢବ୍ରତୀ ହୋଇ ଯତ୍ନସହକାରେ ସାଧନା କରନ୍ତି ଏବଂ ପ୍ରେମପୂର୍ବକ କୀର୍ତ୍ତନ କରି ତଥା ନମସ୍କାର କରି ନିରନ୍ତର ମୋର ଉପାସନା କରନ୍ତି। 9.14",

    "କେତେକ ସାଧକ ଜ୍ଞାନଯଜ୍ଞ ଦ୍ବାରା ଅଭିନ୍ନଭାବରେ ମୋତେ ପୂଜା କରି ଉପାସନା କରନ୍ତି ଏବଂ ଅନ୍ୟ କେତେକ  ସାଧକ ନିଜକୁ ପୃଥକ୍ ମନେକରି ଚାରିଆଡକୁ ମୁଖଥିବା ମୋର ବିରାଟରୂପକୁ ଅର୍ଥାତ୍ ସଂସାରକୁ ମୋର ବିରାଟରୂପ ଭାବି (ସେବ୍ୟ-ସେବକ ଭାବରେ) ମୋତେ ଅନେକ ବିଧିରେ ଉପାସନା କରନ୍ତି। 9.15",

    "ମୁଁ କ୍ରତୁ, ମୁଁ ଯଜ୍ଞ, ମୁଁ ସ୍ବଧା, ମୁଁ ଔଷଧ, ମୁଁ ମନ୍ତ୍ର, ମୁଁ ଘୃତ, ମୁଁ ଅଗ୍ନି ଏବଂ ହବନ ( ଆହୁତୀ ) ରୂପକ କ୍ରିୟା ମଧ୍ୟ ମୁଁ। ଜ୍ଞେୟ ପବିତ୍ର ଓଁକାର , ଋକ୍ ବେଦ, ସାମବେଦ ଓ ଯଜୁର୍ବେଦ ମଧ୍ୟ ମୁଁ। ଏ ସମଗ୍ର ଜଗତର ପିତା, ଧାତା, ମାତା, ପିତାମହ, ଗତି, ଭର୍ତ୍ତା, ପ୍ରଭୂ, ସାକ୍ଷୀ, ନିବାସ, ଆଶ୍ରୟ, ସୁହୃଦ୍, ଉତ୍ପତ୍ତି, ପ୍ରଳୟ, ସ୍ଥାନ, ନିଧାନ ( ଭଣ୍ଡାର ) ତଥ ଅବିନାଶୀ ବୀଜ ମଧ୍ୟ ମୁଁ। 9.16",

    "ମୁଁ କ୍ରତୁ, ମୁଁ ଯଜ୍ଞ, ମୁଁ ସ୍ବଧା, ମୁଁ ଔଷଧ, ମୁଁ ମନ୍ତ୍ର, ମୁଁ ଘୃତ, ମୁଁ ଅଗ୍ନି ଏବଂ ହବନ (ଆହୁତୀ) ରୂପକ କ୍ରିୟା ମଧ୍ୟ ମୁଁ। ଜ୍ଞେୟ ପବିତ୍ର ଓଁକାର , ଋକ୍ ବେଦ, ସାମବେଦ ଓ ଯଜୁର୍ବେଦ ମଧ୍ୟ ମୁଁ । ଏ ସମଗ୍ର ଜଗତର ପିତା, ଧାତା, ମାତା, ପିତାମହ, ଗତି, ଭର୍ତ୍ତା, ପ୍ରଭୂ, ସାକ୍ଷୀ, ନିବାସ, ଆଶ୍ରୟ, ସୁହୃଦ୍, ଉତ୍ପତ୍ତି, ପ୍ରଳୟ, ସ୍ଥାନ, ନିଧାନ ( ଭଣ୍ଡାର ) ତଥ ଅବିନାଶୀ ବୀଜ ମଧ୍ୟ ମୁଁ। 9.17",

    "ମୁଁ କ୍ରତୁ, ମୁଁ ଯଜ୍ଞ, ମୁଁ ସ୍ବଧା, ମୁଁ ଔଷଧ, ମୁଁ ମନ୍ତ୍ର, ମୁଁ ଘୃତ, ମୁଁ ଅଗ୍ନି ଏବଂ ହବନ (ଆହୁତୀ) ରୂପକ କ୍ରିୟା ମଧ୍ୟ ମୁଁ। ଜ୍ଞେୟ ପବିତ୍ର ଓଁକାର , ଋକ୍ ବେଦ, ସାମବେଦ ଓ ଯଜୁର୍ବେଦ ମଧ୍ୟ ମୁଁ। ଏ ସମଗ୍ର ଜଗତର ପିତା, ଧାତା, ମାତା, ପିତାମହ, ଗତି, ଭର୍ତ୍ତା, ପ୍ରଭୂ, ସାକ୍ଷୀ, ନିବାସ, ଆଶ୍ରୟ, ସୁହୃଦ୍, ଉତ୍ପତ୍ତି, ପ୍ରଳୟ, ସ୍ଥାନ, ନିଧାନ (ଭଣ୍ଡାର) ତଥ ଅବିନାଶୀ ବୀଜ ମଧ୍ୟ ମୁଁ। 9.18",

    "ହେ ଅର୍ଜୁନ ! [ ସଂସାରର ହିତ ପାଇଁ ] ମୁଁ ହିଁ ସୂର୍ଯ୍ୟ-ରୂପରେ ଉତ୍ତପ୍ତ ହୁଏ, ଜଳକୁ ଗ୍ରହଣ କରେ ଏବଂ ପୁନଶ୍ଚ ସେହି ଜଳକୁ ବର୍ଷାରୂପେ ବୃଷ୍ଟି କରେ । [ ଆଉ ଅଧିକ କ' ଣ କହିବି ] ଅମୃତ ଓ ମୃତ୍ୟୁ ତଥା ସତ୍ ଓ ଅସତ୍ ମଧ୍ୟ ମୁଁ। 9.19",

    "ଯେଉଁମାନେ ତିନିବେଦରେ ବର୍ଣ୍ଣିତ ଯଜ୍ଞାଦି ଅନୁଷ୍ଠାନ ସକାମଭାବରେ କରନ୍ତି ଏବଂ ସୋମରସ ପାନପୂର୍ବକ ପାପରହିତ ହୋଇ ଯଜ୍ଞ ଦ୍ବାରା ଇନ୍ଦ୍ରରୂପରେ ମୋତେ ପୂଜା କରି ସ୍ବର୍ଗ ପ୍ରାପ୍ତିନିମନ୍ତେ ପ୍ରାର୍ଥନା କରନ୍ତି, ସେମାନେ ପୂଣ୍ୟଫଳସ୍ବରୂପ ପବିତ୍ର ଇନ୍ଦ୍ରଲୋକ ପ୍ରାପ୍ତ ହୋଇ ସେଠାରେ ସ୍ବର୍ଗର ଦେବଗଣଙ୍କ ଯୋଗ୍ୟ ଦିବ୍ୟ ଭୋଗ ଉପଭୋଗ କରନ୍ତି। 9.20",

    "ସେମାନେ ସେହି ବିଶାଳ ସ୍ବର୍ଗଲୋକର ଭୋଗ ଉପଭୋଗ କରିସାରି ପୁଣ୍ୟ କ୍ଷୟ ହେଲେ ମୃତ୍ୟୁଲୋକକୁ ଫେରିଆସନ୍ତି। ଏହିପରି ତିନି ବେଦରେ ବର୍ଣ୍ଣିତ ସକାମ ଧର୍ମର ଆଶ୍ରିତ ଭୋଗକାମୀ ମନୁଷ୍ୟ ଜନ୍ମମରଣ ଲାଭ କରନ୍ତି।  9.21",

    "ଯେଉଁ ଅନନ୍ୟ ଭକ୍ତଗଣ ମୋତେ ଚିନ୍ତନ କରି ସମ୍ୟକ୍ ଭାବେ ଉପାସନା କରନ୍ତି, ନିରନ୍ତର ମୋ ଠାରେ ନିମଗ୍ନ ରହୁଥିବା ସେହି ଭକ୍ତମାନଙ୍କର ଯୋଗକ୍ଷେମ (ଅପ୍ରାପ୍ତର ପ୍ରାପ୍ତି ଓ ପ୍ରାପ୍ତର ରକ୍ଷା ଦାୟୀତ୍ବ) ମୁଁ ବହନ କରେ।  9.22",

    "ହେ କୁନ୍ତୀନନ୍ଦନ! ଯେଉଁ ଭକ୍ତ (ମନୁଷ୍ୟ) ଶ୍ରଦ୍ଧାପୂର୍ବକ ଅନ୍ୟ ଦେବତାମାନଙ୍କୁ ପୂଜା କରନ୍ତି, ସେମାନେ ବି ମୋତେ ହିଁ ପୂଜା କରନ୍ତି, କିନ୍ତୁ ଅବିଧିପୂର୍ବକ କରନ୍ତି, ଅର୍ଥାତ୍ ସେମାନେ ଦେବତାମାନଙ୍କୁ ମୋ ଠାରୁ ଭିନ୍ନ ମନେ କରନ୍ତି।  9.23",

    "କାରଣ ମୁଁ ହିଁ ସମସ୍ତ ଯଜ୍ଞର ଭୋକ୍ତା ଓ ସ୍ବାମୀ, କିନ୍ତୁ ସେମାନେ ମୋତେ ତତ୍ତ୍ବତଃ ଜାଣନ୍ତି ନାହିଁ, ତେଣୁ ସେମାନଙ୍କର ପତନ ହୁଏ।  9.24",

    "( ସକାମଭାବରେ ) ଦେବଗଣଙ୍କୁ ପୂଜା କରୁଥିବା ଲୋକେ ( ଦେହତ୍ୟାଗ କରିବାପରେ ) ଦେବତାମାନଙ୍କୁ ପ୍ରାପ୍ତ ହୁଅନ୍ତି । ପିତୃପୁରୁଷଙ୍କର ଉପାସକ ପିତୃପୁରୁଷଙ୍କୁ ପ୍ରାପ୍ତ ହୁଅନ୍ତି । ଭୂତ-ପ୍ରେତଙ୍କର ଉପାସକ ଭୂତ-ପ୍ରେତଙ୍କୁ ପ୍ରାପ୍ତ ହୁଅନ୍ତି । କିନ୍ତୁ ମୋର ଉପାସକ ମୋତେ ହିଁ ପ୍ରାପ୍ତ ହୁଅନ୍ତି । 9.25",

    "ଯେଉଁ ଭକ୍ତ ପତ୍ର,ପୁଷ୍ପ, ଫଳ, ଜଳ ଆଦିକୁ (ଯଥାସାଧ୍ୟ ଓ ଅନାୟାସ ଲବ୍ଧ ବସ୍ତୁକୁ) ପ୍ରେମର ସହିତ ମୋତେ ପ୍ରଦାନ କରେ, ମୋଠାରେ ତଲ୍ଲୀନମନା ଭକ୍ତର ସେହି ପ୍ରେମ ସହକାରେ ପ୍ରଦତ୍ତ ଉପହାରକୁ ମୁଁ ଖାଇଦିଏ ଅର୍ଥାତ୍ ଗ୍ରହଣ କରେ।  9.26",

    "ହେ କୁନ୍ତୀନନ୍ଦନ! ତୁମେ ଯାହା କିଛି କରୁଛ, ଯାହା କିଛି ଖାଉଛ, ଯେଉଁ ସବୁ ଯଜ୍ଞ କରୁଛ, ଯାହା କିଛି ଦାନ ଦେଉଛ ଓ ଯେଉଁ ସବୁ ତପ କରୁଛ, ସେ ସମସ୍ତ ମୋତେ ଅର୍ପଣ କରିଦିଅ।  9.27",

    "ଏହି ପ୍ରକାରେ ମୋତେ ଅର୍ପଣ କଲେ କର୍ମବନ୍ଧନରୁ ଏବଂ ସମସ୍ତ ଶୁଭ ( ବିହିତ) ଓ ଅଶୁଭ (ନିଷିଦ୍ଧ) କର୍ମଫଳରୁ ତୁମେ ମୁକ୍ତ ହୋଇଯିବ। ତୁମେ ନିଜସମେତ ସବୁକିଛି ମୋତେ ଅର୍ପଣ କରି ଏବଂ ସବୁଥିରୁ ସମ୍ପୂର୍ଣ୍ଣ ମୁକ୍ତ ହୋଇ ମୋତେ ପ୍ରାପ୍ତ ହେବ।  9.28",

    "ସବୁ ପ୍ରାଣୀଙ୍କ ଠାରେ ମୁଁ ସମାନ। ସେହି ପ୍ରାଣୀମାନଙ୍କ ମଧ୍ୟରେ ମୋର କେହି ଦ୍ବେଷ୍ୟ ନୁହନ୍ତି କିମ୍ବା କେହି ପ୍ରିୟ ନୁହନ୍ତି। କିନ୍ତୁ ଯେଉଁମାନେ ମୋତେ ପ୍ରେମ ସହକାରେ ଭଜନ୍ତି, ସେମାନେ ମୋ ଠାରେ ଥାଆନ୍ତି ଓ ମୁଁ ସେମାନଙ୍କ ଠାରେ ଥାଏ।  9.29",

    "ଯଦି କୌଣସି ଅତ୍ୟନ୍ତ ଦୁରାଚାରୀ ମଧ୍ୟ ଅନନ୍ୟଭାବରେ ମୋତେ ଭଜେ, ତେବେ ତାକୁ ନିଶ୍ଚିତରୂପେ ସାଧୁ ବୋଲି ମାନିବା ଉଚ୍ଚିତ। କାରଣ ସେ ଉତ୍ତମରୂପେ ଦୃଢ ନିର୍ଣ୍ଣୟ କରି ନେଇଛି।  9.30",

    "ସେ ତତ୍ କ୍ଷଣାତ୍ ଧର୍ମାତ୍ମା ହୋଇଯାଏ ଏବଂ ଚିରସ୍ଥାୟୀ ଶାନ୍ତି ଲାଭ କରେ। ହେ  କୁନ୍ତୀନନ୍ଦନ ! ତୁମେ ପ୍ରତିଜ୍ଞା କର ଯେ, ମୋ ଭକ୍ତର ପତନ ହୁଏ ନାହିଁ।  9.31",

    "ହେ ପୃଥାନନ୍ଦନ! ଯେଉଁମାନେ ପାପଯୋନିସମ୍ଭୂତ ତଥା ଯେଉଁମାନେ ନାରୀ, ବୈଶ୍ୟ ଓ ଶୁଦ୍ର, ସେମାନେ ମଧ୍ୟ ସର୍ବୋତଭାବେ ମୋର ଶରଣାପନ୍ନ ହୋଇ ନିଃସନ୍ଦେହରେ ପରମଗତି ଲାଭ କରନ୍ତି।   9.32",

    "ପୁଣି ପବିତ୍ର ଆଚରଣସମ୍ପନ୍ନ ବ୍ରାହ୍ମଣ ଏବଂ ଋଷିସ୍ବରୂପ କ୍ଷତ୍ରୀୟ ଭଗବାନଙ୍କର ଭକ୍ତ ହେଲେ ସେମାନେ ଯେ ପରମଗତି ଲାଭ କରିବେ, ଏଥିରେ କହିବାର କଣ ଅଛି ! ତେଣୁ ଏହି ଅନିତ୍ୟ ଓ ସୁଖରହିତ ଶରୀର ପାଇ ତୁମେ ମୋତେ ଭଜ।  9.33",

    "ମୋ ଠାରେ ମନ ଲଗାଅ, ମୋର ଭକ୍ତ ହୁଅ, ମୋର ପୂଜନ କର, ମୋତେ ପ୍ରଣାମ କର । ଏପରିଭାବରେ ଆତ୍ମାକୁ ମୋଠାରେ ଲଗାଇ ମୋର ପରାୟଣ ହେଲେ ତୁମେ ମୋତେ ନିଶ୍ଚୟ ପ୍ରାପ୍ତ ହେବ । 9.34",

    "ଏହି ପ୍ରକାର ଓଁ ତତ୍ ସତ୍ -- ଏହି ଭଗବତ୍ ନାମର ଉଚ୍ଚାରଣପୂର୍ବକ ବ୍ରହ୍ମବିଦ୍ୟା ଓ ଯୋଗଶାସ୍ତ୍ରମୟ ଶ୍ରୀମଦ୍ଭଗବଦ୍ଗୀତା ଉପନିଷଦରୂପେ ଶ୍ରୀକୃଷ୍ଣ ଏବଂ ଅର୍ଜୁନଙ୍କ ସମ୍ବାଦରେ 'ରାଜବିଦ୍ୟାରାଜଗୁହ୍ୟଯୋଗନାମକ' ନବମ ଅଧ୍ୟାୟ ପୂର୍ଣ୍ଣ ହେଲା"
  ],
  "adhyaya-10" : [
    "ଶ୍ରୀ ଭଗବାନ କହିଲେ -- ହେ ମହାବାହୁ ଅର୍ଜୁନ ! ପୁନଶ୍ଚ ତୁମେ ମୋର ପରମ ବଚନ ଶୁଣ, ଯାହାକୁ ମୁଁ ତୁମର ହିତ ଉଦ୍ଦେଶ୍ୟରେ କହିବି, କାରଣ ତୁମେ ମୋ ପ୍ରତି ଅତ୍ୟନ୍ତ ପ୍ରେମଭାବ ରଖୁଛ । 10.1",

    "ମୋର ପ୍ରାକଟ୍ୟକୁ ଦେବତା ଓ ମହର୍ଷି କେହି ହେଲେ ଜାଣନ୍ତି ନାହିଁ , କାରଣ ମୁଁ ସବୁ ଦୃଷ୍ଟିରୁ ଦେବତା ଓ ମହର୍ଷିମାନଙ୍କର ଆଦି କାରଣ । 10.2",

    "ଯେଉଁ ମନୁଷ୍ୟ ମୋତେ ଅଜ, ଅନାଦି ଓ ସମସ୍ତ ଲୋକର ମହାନ୍ ଈଶ୍ୱର ବୋଲି ଜାଣେ ଅର୍ଥାତ୍ ଦୃଢଭାବେ ମାନେ, ସେ ମନୁଷ୍ୟମାନଙ୍କ ମଧ୍ୟରେ ଜ୍ଞାନବାନ୍ ଏବଂ ସେ ସମସ୍ତ ପାପରୁ ମୁକ୍ତ ହୋଇଯାଏ । 10.3",

    "ବୁଦ୍ଧି, ଜ୍ଞାନ, ଅସମ୍ମୋହ, କ୍ଷମା, ସତ୍ୟ, ଦମ, ଶମ, ସୁଖ, ଦୁଃଖ, ଉତ୍ପତ୍ତି, ବିନାଶ, ଭୟ, ଅଭୟ, ଅହିଂସା, ସମତା, ସନ୍ତୋଷ, ତପ, ଦାନ, ଯଶ ଓ ଅପଯଶ --- ପ୍ରାଣୀମାନଙ୍କର ଏହି ନାନାବିଧ ପୃଥକ୍ ପୃଥକ୍ ( କୋଡିଏଟି ) ଭାବ ମୋ ' ଠାରୁ ହିଁ ଉତ୍ପନ୍ନ ହୁଅନ୍ତି । 10.4-10.5",

    "ବୁଦ୍ଧି, ଜ୍ଞାନ, ଅସମ୍ମୋହ, କ୍ଷମା, ସତ୍ୟ, ଦମ, ଶମ, ସୁଖ, ଦୁଃଖ, ଉତ୍ପତ୍ତି, ବିନାଶ, ଭୟ, ଅଭୟ, ଅହିଂସା, ସମତା, ସନ୍ତୋଷ, ତପ, ଦାନ, ଯଶ ଓ ଅପଯଶ --- ପ୍ରାଣୀମାନଙ୍କର ଏହି ନାନାବିଧ ପୃଥକ୍ ପୃଥକ୍ ( କୋଡିଏଟି ) ଭାବ ମୋ ' ଠାରୁ ହିଁ ଉତ୍ପନ୍ନ ହୁଅନ୍ତି । 10.4-10.5",
    
    "ସାତ ମହର୍ଷି, ସେମାନଙ୍କ ପୂର୍ବବର୍ତ୍ତୀ ଚାରି ସନକାଦି ଓ ଚଉଦ ମନୁ --- ଏ ସମସ୍ତେ ମୋ ମନରୁ ଜାତ ହୋଇଛନ୍ତି ଏବଂ ମୋ ପ୍ରତି ଭାବ ( ଶ୍ରଦ୍ଧା-ଭକ୍ତି ) ରଖିଛନ୍ତି । ଏ ସଂସାରରେ ସମସ୍ତେ ଏମାନଙ୍କର ପ୍ରଜା ଅଟନ୍ତି । 10.6",

    "ଯେଉଁ ମନୁଷ୍ୟ ମୋର ଏହି ବିଭୂତି ଓ ଯୋଗ(ସାମର୍ଥ୍ୟ)କୁ ତତ୍ତ୍ୱତଃ ଜାଣେ ଅର୍ଥାତ୍ ଦୃଢଭାବେ ନିଃସନ୍ଦେହରେ ମାନେ , ସେ ଅବିଚଳ ଭକ୍ତିଯୋଗରେ ଯେ ସଂଶ୍ଳିଷ୍ଟ ହୋଇଯାଏ, ଏଥିରେ ତିଳେମାତ୍ର ସନ୍ଦେହ ନାହିଁ । 10.7",

    "ମୁଁ ସାରା ସଂସାରର ପ୍ରଭବ( ମୂଳ କାରଣ ) ଏବଂ ମୋ ଠାରୁ ସାରା ସଂସାର ପ୍ରବୃତ୍ତ ହେଉଅଛି ବୋଲି ବିଶ୍ୱାସ କରି ମୋ ଠାରେ ଶ୍ରଦ୍ଧା-ପ୍ରେମ ରଖୁଥିବା ବୁଦ୍ଧିମାନ୍ ଭକ୍ତ ମୋତେ ଭଜିଥାନ୍ତି ଅର୍ଥାତ୍ ସର୍ବଭାବରେ ମୋର ଶରଣାପନ୍ନ ହୁଅନ୍ତି । 10.8",

    "ମଦ୍ଗତଚିତ୍ତ ତଥା ମଦ୍ଗତପ୍ରାଣ ଭକ୍ତଜନ ମୋର ଗୁଣ, ପ୍ରଭାବ ଇତ୍ୟାଦି ପରସ୍ପରକୁ ଜଣାଇ ଓ ତତ୍ ସମ୍ବନ୍ଧୀୟ ଆଲୋଚନା କରି ନିରନ୍ତର ସନ୍ତୁଷ୍ଟ ରହନ୍ତି ଏବଂ ମୋତେ ପ୍ରେମ କରନ୍ତି । 10.9",

    "ମୋ ଠାରେ ସର୍ବଦା ଯୁକ୍ତ ହୋଇ ପ୍ରେମପୂର୍ବକ ମୋତେ ଭଜୁଥିବାସେହି( ପୂର୍ବଶ୍ଳୋକରେ ବର୍ଣ୍ଣିତ )ଭକ୍ତମାନଙ୍କୁ ମୁଁ ଏଭଳି ବୁଦ୍ଧିଯୋଗ ପ୍ରଦାନ କରେ, ଯଦ୍ଦ୍ୱାରା ସେମାନେ ମୋତେ ପ୍ରାପ୍ତ ହୁଅନ୍ତି । 10.10",

    "ସେହି ଭକ୍ତମାନଙ୍କ ପ୍ରତି କୃପା କରିବା ପାଇଁ ମୁଁ ସେମାନଙ୍କ ସ୍ୱରୂପରେ ଥାଇ ସେମାନଙ୍କ ଅଜ୍ଞାନଜନିତ ଅନ୍ଧକାରକୁ ଦେଦୀପ୍ୟମାନ ଜ୍ଞାନଦୀପ ଦ୍ୱାରା ନଷ୍ଟ କରିଦିଏ । 10.11",

    "ଅର୍ଜୁନ କହିଲେ -- ଆପଣ ହେଉଛନ୍ତି ପରମ ବ୍ରହ୍ମ, ପରମ ଧାମ ଓ ପରମ ପବିତ୍ର । ଆପଣ ଶାଶ୍ୱତ, ଦିବ୍ୟ ପୁରୁଷ, ଆଦିଦେବ, ଅଜନ୍ମା ଓ ସର୍ବବ୍ୟାପକ ବୋଲି ସମସ୍ତ ଋଷି, ଦେବର୍ଷି ନାରଦ, ଅସିତ, ଦେବଳ ତଥା ବ୍ୟାସ କହିଛନ୍ତି ଏବଂ ଆପଣ ନିଜେ ମଧ୍ୟ ମୋତେ କହୁଛନ୍ତି । 10.12-10.13",

    "ଅର୍ଜୁନ କହିଲେ -- ଆପଣ ହେଉଛନ୍ତି ପରମ ବ୍ରହ୍ମ, ପରମ ଧାମ ଓ ପରମ ପବିତ୍ର । ଆପଣ ଶାଶ୍ୱତ, ଦିବ୍ୟ ପୁରୁଷ, ଆଦିଦେବ, ଅଜନ୍ମା ଓ ସର୍ବବ୍ୟାପକ ବୋଲି ସମସ୍ତ ଋଷି, ଦେବର୍ଷି ନାରଦ, ଅସିତ, ଦେବଳ ତଥା ବ୍ୟାସ କହିଛନ୍ତି ଏବଂ ଆପଣ ନିଜେ ମଧ୍ୟ ମୋତେ କହୁଛନ୍ତି । 10.12-10.13",

    "ହେ କେଶବ ! ମୋତେ ଆପଣ ଯାହା କିଛି କହୁଛନ୍ତି, ସେ ସବୁକୁ ମୁଁ ସତ୍ୟ ମନେ କରୁଛି । ହେ ଭଗବନ୍ ! ଆପଣଙ୍କ ପ୍ରକଟ ହେବା ବିଷୟ ଦେବତା ବା ଦାନବ କେହିହେଲେ ଜାଣନ୍ତି ନାହିଁ । 10.14",

    "ହେ ଭୂତଭାବନ ! ହେ ଭୂତେଶ ! ହେ ଦେବଦେବ ! ହେ ଜଗତପତି ! ହେ ପୁରୁଷୋତ୍ତମ ! ଆପଣ ନିଜେ ହିଁ ନିଜଦ୍ୱାରା ନିଜକୁ ଜାଣନ୍ତି। 10.15",

    "ତେଣୁ ଯେଉଁ ବିଭୂତିମାନଙ୍କ ଦ୍ୱାରା ଆପଣ ଏହି ସମଗ୍ର ଲୋକକୁ ବ୍ୟାପ୍ତ କରି ବିରାଜମାନ ହୋଇଛନ୍ତି, ନିଜର ସେହି ଦିବ୍ୟ ବିଭୂତିଗୁଡିକୁ ପୂର୍ଣ୍ଣରୂପେ ବର୍ଣ୍ଣନା କରିବାରେ ଆପଣ ହିଁ ସମର୍ଥ । 10.16",

    "ହେ ଯୋଗୀ ! ନିରନ୍ତର ସମ୍ୟକ୍ ଭାବେ ଚିନ୍ତନ କରି ମୁଁ ଆପଣଙ୍କୁ କିପରି ଜାଣିବି ? ଏବଂ ହେ ଭଗବନ୍ ! କେଉଁ କେଉଁ ଭାବରେ ଆପଣ ମୋ ଦ୍ୱାରା ଚିନ୍ତନୀୟ ହେବେ ଅର୍ଥାତ୍ କେଉଁ କେଉଁ ଭାବରେ ମୁଁ ଆପଣଙ୍କୁ ଚିନ୍ତନ କରିବି ? 10.17",

    "ହେ ଜନାର୍ଦନ ! ଆପଣଙ୍କର ଯୋଗ ( ସାମର୍ଥ୍ୟ ) ଓ ବିଭୂତିମାନଙ୍କୁ ପୁନଶ୍ଚ ବିସ୍ତାର କରି କହନ୍ତୁ , କାରଣ ଆପଣଙ୍କର ଅମୃତମୟ ବଚନ ଯେତେ ଶୁଣିଲେ ବି ମୋତେ ତୃପ୍ତି ମିଳୁ ନାହିଁ । 10.18",

    "ଶ୍ରୀ ଭଗବାନ କହିଲେ -- ଆଚ୍ଛା, ଠିକ୍ ଅଛି । ମୁଁ ମୋର ମୁଖ୍ୟ ମୁଖ୍ୟ ବିଭୂତିମାନ ତୁମକୁ ସଂକ୍ଷେପରେ କହିବି, କାରଣ ହେ କୁରୁଶ୍ରେଷ୍ଠ ! ମୋ ବିଭୂତିମାନଙ୍କ ବିସ୍ତାରର ଅନ୍ତ ନାହିଁ । 10.19",

    "ହେ ନିଦ୍ରାଜୟୀ ଅର୍ଜୁନ ! ସମସ୍ତ ପ୍ରାଣୀଙ୍କ ଆଦି, ମଧ୍ୟ ତଥା ଅନ୍ତରେ ମୁଁ ହିଁ ବିରାଜମାନ ଏବଂ ପ୍ରାଣୀମାନଙ୍କର ଅନ୍ତଃକରଣ(ହୃଦୟ)ରେ ସ୍ଥିତ ଆତ୍ମା ମଧ୍ୟ ମୁଁ । 10.20",

    "ମୁଁ ଅଦିତିପୁତ୍ରମାନଙ୍କ ମଧ୍ୟରେ ବିଷ୍ଣୁ (ବାମନ) ଏବଂ ଉଜ୍ଜ୍ୱଳ ବସ୍ତୁମାନଙ୍କ ମଧ୍ୟରେ କିରଣମୟ ସୂର୍ଯ୍ୟ । ମରୁତମାନଙ୍କର ତେଜ ଓ ନକ୍ଷତ୍ରମାନଙ୍କର ଅଧିପତି ଚନ୍ଦ୍ର ହେଉଛି ମୁଁ । 10.21",

    "ମୁଁ ବେଦମାନଙ୍କ ମଧ୍ୟରେ ସାମବେଦ, ଦେବତାମାନଙ୍କ ମଧ୍ୟରେ ଇନ୍ଦ୍ର, ଇନ୍ଦ୍ରିୟମାନଙ୍କ ମଧ୍ୟରେ ମନ ଏବଂ ପ୍ରାଣୀମାନଙ୍କର ଚେତନା ଅଟେ । 10.22",

    "ରୁଦ୍ରମାନଙ୍କ ମଧ୍ୟରେ ଶଙ୍କର ଓ ଯକ୍ଷ-ରାକ୍ଷସଙ୍କ ମଧ୍ୟରେ କୁବେର ହେଉଛି ମୁଁ । ବସୁମାନଙ୍କ ମଧ୍ୟରେ ପବିତ୍ରକାରୀ ଅଗ୍ନି ଓ ଶିଖରବିଶିଷ୍ଟ ପର୍ବତମାନଙ୍କ ମଧ୍ୟରେ ସୁମେରୁ ପର୍ବତ ମୁଁ ଅଟେ। 10.23",

    "ହେ ପାର୍ଥ ! ପୁରୋହିତମାନଙ୍କ ମଧ୍ୟରେ ମୁଖ୍ୟ ବୃହସ୍ପତିଙ୍କୁ ମୋର ସ୍ୱରୂପ ବୋଲି ଜାଣ ।ମୁଁ ସେନାପତିମାନଙ୍କ ମଧ୍ୟରେ ସ୍କନ୍ଦ (କାର୍ତ୍ତିକେୟ ) ଓ ଜଳାଶୟମାନଙ୍କ ମଧ୍ୟରେ ସମୁଦ୍ର । 10.24",

    "ମୁଁ ମହର୍ଷିମାନଙ୍କ ମଧ୍ୟରେ ଭୃଗୁ, ବାଣୀ ( ଶବ୍ଦ ) ମାନଙ୍କ ମଧ୍ୟରେ ଏକାକ୍ଷର ଅର୍ଥାତ୍ ପ୍ରଣବ । ସମସ୍ତ ଯଜ୍ଞ ମଧ୍ୟରେ ଜପଯଜ୍ଞ ଓ ସ୍ଥାବରମାନଙ୍କ ମଧ୍ୟରେ ହିମାଳୟ ହେଉଛି ମୁଁ । 10.25",

    "ମୁଁ ବୃକ୍ଷମାନଙ୍କ ମଧ୍ୟରେ ଅଶ୍ୱତ୍ଥ , ଦେବର୍ଷିମାନଙ୍କ ମଧ୍ୟରେ ନାରଦ, ଗନ୍ଧର୍ବମାନଙ୍କ ମଧ୍ୟରେ ଚିତ୍ରରଥ ଓ ସିଦ୍ଧମାନଙ୍କ ମଧ୍ୟରେ କପିଳ ମୁନି । 10.26",

    "ଅଶ୍ୱମାନଙ୍କ ମଧ୍ୟରେ ଅମୃତ ସହିତ ସମୁଦ୍ରରୁ ଉଦ୍ଭୂତ ଉଚ୍ଚୈଃଶ୍ରବା ନାମକ ଅଶ୍ୱକୁ , ଶ୍ରେଷ୍ଠ ହସ୍ତୀମାନଙ୍କ ମଧ୍ୟରେ ଐରାବତ ନାମକ ହସ୍ତୀକୁ ଏବଂ ମନୁଷ୍ୟମାନଙ୍କ ମଧ୍ୟରେ ରାଜାଙ୍କୁ ମୋର ବିଭୂତି ବୋଲି ଜାଣ । 10.27",

    "ମୁଁ ଆୟୁଧମାନଙ୍କ ମଧ୍ୟରେ ବଜ୍ର ଓ ଧେନୁମାନଙ୍କ ମଧ୍ୟରେ କାମଧେନୁ । ସନ୍ତାନ ଉତ୍ପତ୍ତିର କାରଣ କାମଦେବ ଓ ସର୍ପମାନଙ୍କ ମଧ୍ୟରେ ବାସୁକି ହେଉଛି ମୁଁ । 10.28",

    "ମୁଁ ନାଗମାନଙ୍କ ମଧ୍ୟରେ ଅନନ୍ତ ( ଶେଷନାଗ ) ଓ ଜଳଜନ୍ତୁଙ୍କର ଅଧିପତି ବରୁଣ । ମୁଁ ପିତୃଗଣଙ୍କ ମଧ୍ୟରେ ଅର୍ଯ୍ୟମା ଓ ଶାସନକାରୀଙ୍କ ମଧ୍ୟରେ ଯମରାଜ । 10.29",

    "ମୁଁ ଦୈତ୍ୟମାନଙ୍କ ମଧ୍ୟରେ ପ୍ରହ୍ଲାଦ ଏବଂ ଗଣନାକାରୀମାନଙ୍କ ମଧ୍ୟରେ କାଳ ତଥା ମୁଁ ପଶୁମାନଙ୍କ ମଧ୍ୟରେ ସିଂହ ଓ ପକ୍ଷୀମାନଙ୍କ ମଧ୍ୟରେ ଗରୁଡ । 10.30",

    "ମୁଁ ପବିତ୍ରକାରୀମାନଙ୍କ ମଧ୍ୟରେ ବାୟୁ ଓ ଶସ୍ତ୍ରଧାରୀମାନଙ୍କ ମଧ୍ୟରେ ରାମ । ଜଳଜନ୍ତୁଙ୍କ ମଧ୍ୟରେ ମୁଁ ମଗର ଏବଂ ନଦୀମାନଙ୍କ ମଧ୍ୟରେ ମୁଁ ହେଉଛି ଗଙ୍ଗା । 10.31",

    "ହେ ଅର୍ଜୁନ ! ସମସ୍ତ ସର୍ଗର ଆଦି, ମଧ୍ୟ ଓ ଅନ୍ତରେ ମୁଁ ହିଁ ବିରାଜମାନ । ବିଦ୍ୟାମାନଙ୍କ ମଧ୍ୟରେ ଅଧ୍ୟାତ୍ମବିଦ୍ୟା (ବ୍ରହ୍ମବିଦ୍ୟା) ଏବଂ ପରସ୍ପର ଶାସ୍ତ୍ରାର୍ଥକାରୀମାନଙ୍କର (ତତ୍ତ୍ୱ ନିର୍ଣ୍ଣାୟକ) ବାଦ ହେଉଛି ମୁଁ । 10.32",

    "ମୁଁ ଅକ୍ଷରମାନଙ୍କ ମଧ୍ୟରେ ଅକାର ଏବଂ ସମାସମାନଙ୍କ ମଧ୍ୟରେ ଦ୍ୱନ୍ଦ୍ୱ ସମାସ । ଅକ୍ଷୟକାଳ ଅର୍ଥାତ୍ କାଳର ବି ମହାକାଳ ତଥା ସର୍ବଦିଗେ ମୁଖବିଶିଷ୍ଟ ଧାତା ( ସମସ୍ତଙ୍କର ପାଳନ-ପୋଷଣକାରୀ) ମଧ୍ୟ ମୁଁ । 10.33",

    "ସମସ୍ତଙ୍କର ହରଣକାରୀ ମୃତ୍ୟୁ ଓ ଭବିଷ୍ୟତର ଉଦ୍ଭବ ମଧ୍ୟ ମୁଁ ତଥା ନାରୀ ଜାତିରେ କୀର୍ତ୍ତି, ଶ୍ରୀ, ବାକ୍, ସ୍ମୃତି, ମେଧା, ଧୃତି ଓ କ୍ଷମା ମଧ୍ୟ ମୁଁ । 10.34",

    "ମୁଁ ଗାନଯୋଗ୍ୟ ଶ୍ରୁତିମାନଙ୍କ ମଧ୍ୟରେ ବୃହତ୍ ସାମ ଏବଂ ଛନ୍ଦମାନଙ୍କ ମଧ୍ୟରେ ଗାୟତ୍ରୀ ଛନ୍ଦ । ମୁଁ ବାରମାସ ମଧ୍ୟରେ ମାର୍ଗଶୀର ଓ ଛ ' ଋତୁ ମଧ୍ୟରେ ବସନ୍ତ । 10.35",

    "ଛଳନା ମଧ୍ୟରେ ଜୁଆ ଏବଂ ତେଜସ୍ୱୀମାନଙ୍କର ତେଜ ମୁଁ । ବିଜୟୀମାନଙ୍କର ବିଜୟ, ନିର୍ଣ୍ଣୟକାରୀଙ୍କର ନିର୍ଣ୍ଣୟ ଓ ସାତ୍ତ୍ୱିକ ମନୁଷ୍ୟଙ୍କର ସାତ୍ତ୍ୱିକ ଭାବ ହେଉଛି ମୁଁ । 10.36",

    "ମୁଁ ବୃଷ୍ଣିବଂଶୀମାନଙ୍କ ମଧ୍ୟରେ ବସୁଦେବସୁତ ଶ୍ରୀକୃଷ୍ଣ ଓ ପାଣ୍ଡବଙ୍କ ମଧ୍ୟରେ ଧନଞ୍ଜୟ । ମୁନିମାନଙ୍କ ମଧ୍ୟରେ ବେଦବ୍ୟାସ ଓ କବିମାନଙ୍କ ମଧ୍ୟରେ କବି ଶୁକ୍ରାଚାର୍ଯ୍ୟ ମଧ୍ୟ ମୁଁ । 10.37",

    "ମୁଁ ଦମନକାରୀମାନଙ୍କର ଦଣ୍ଡନୀତି ଏବଂ ବିଜୟାକାଂକ୍ଷୀମାନଙ୍କର ନୀତି । ଗୋପନୀୟ ଭାବଗୁଡିକ ମଧ୍ୟରେ ମୌନ ଏବଂ ଜ୍ଞାନବାନ୍ ମାନଙ୍କର ଜ୍ଞାନ ମଧ୍ୟ ମୁଁ । 10.38",

    "ହେ ଅର୍ଜୁ୍ନ ! ମୁଁ ସମସ୍ତ ପ୍ରାଣୀଙ୍କର ବୀଜ , କାରଣ ମୋ ବିନା କୌଣସି ଚରାଚର ପ୍ରାଣୀ ନାହିଁ ଅର୍ଥାତ୍ ଚର ଓ ଅଚର ସବୁକିଛି ମୁଁ । 10.39",

    "ହେ ପରନ୍ତପ ଅର୍ଜୁନ ! ମୋ ଦିବ୍ୟ ବିଭୂତିମାନଙ୍କର ଅନ୍ତ ନାହିଁ । ମୁଁ ତୁମ ଆଗରେ ମୋର ଏ ଯେଉଁ ବିଭୂତିମାନ ବର୍ଣ୍ଣନା କଲି, ଏହା କେବଳ ସଂକ୍ଷେପମାତ୍ର । 10.40",

    "ଯେତେ ସବୁ ଐଶ୍ୱର୍ଯ୍ୟଯୁକ୍ତ, ଶୋଭାଯୁକ୍ତ ଓ ବଳଯୁକ୍ତ ପ୍ରାଣୀ ଓ ପଦାର୍ଥ ଅଛନ୍ତି, ସେ ସବୁକୁ ତୁମେ ମୋର ତେଜ ( ଯୋଗ ଅର୍ଥାତ୍ ସାମର୍ଥ୍ୟ)ର ଅଂଶରୁ ଉତ୍ପନ୍ନ ହୋଇଛନ୍ତି ବୋଲି ବୁଝ । 10.41",

    "ଅଥବା ହେ ଅର୍ଜୁନ ! ଏପରି ବହୁତ କଥା ଜାଣିବାରେ ତୁମର କି ପ୍ରୟୋଜନ ? ମୁଁ ସ୍ୱୟଂ ମୋର କୌଣସି ଏକ ଅଂଶରେ ସମଗ୍ର ଜଗତକୁ ବ୍ୟାପ୍ତକରି ବିରାଜମାନ ହୋଇଛି ଅର୍ଥାତ୍ ଅନନ୍ତ ବ୍ରହ୍ମାଣ୍ଡ ମୋର କୌଣସି ଏକ ଅଂଶରେ ସ୍ଥିତ । 10.42",

    "ଏହି ପ୍ରକାର ଓଁ ତତ୍ ସତ୍ -- ଏହି ଭଗବତ୍ ନାମର ଉଚ୍ଚାରଣପୂର୍ବକ ବ୍ରହ୍ମବିଦ୍ୟା ଓ ଯୋଗଶାସ୍ତ୍ରମୟ ଶ୍ରୀମଦ୍ଭଗବଦ୍ଗୀତା ଉପନିଷଦରୂପେ ଶ୍ରୀକୃଷ୍ଣ ଏବଂ ଅର୍ଜୁନଙ୍କ ସମ୍ବାଦରେ 'ବିଭୂତିଯୋଗନାମକ' ଦଶମ ଅଧ୍ୟାୟ ପୂର୍ଣ୍ଣ ହେଲା ।"
  ],
  "adhyaya-11" : [
    "ଅର୍ଜୁନ କହିଲେ -- କେବଳ ମୋ ଉପରେ କୃପା କରିବା ପାଇଁ ଆପଣ ଅଧ୍ୟାତ୍ମବିଷୟକ ଯେଉଁ ପରମ ଗୋପନୀୟ ବଚନ କହିଲେ, ତାହାଦ୍ବାରା ମୋର ମୋହ ନଷ୍ଟ ହୋଇଗଲା । 11.1",

    "କାରଣ, ହେ କମଳଲୋଚନ ! ସମସ୍ତ ପ୍ରାଣୀଙ୍କର ଉତ୍ପତ୍ତି ଓ ବିନାଶ ବିଷୟରେ ମୁଁ ଆପଣଙ୍କ ଠାରୁ ବିସ୍ତାରପୂର୍ବକ ଶୁଣିଲି ଏବଂ ଆପଣଙ୍କର ଅବିନାଶୀ ମାହାତ୍ମ୍ୟ ମଧ୍ୟ ଶୁଣିଲି। 11.2",

    "ହେ ପୁରୁଷୋତ୍ତମ ! ଆପଣ ନିଜକୁ ଯେପରି କହୁଛନ୍ତି, ପ୍ରକୃତରେ ଆପଣ ଠିକ୍ ସେହିପରି। ହେ ପରମେଶ୍ବର ! ମୁଁ ଆପଣଙ୍କର ଐଶ୍ବରିକ ରୂପ ଦେଖିବାକୁ ଇଚ୍ଛା କରୁଛି। 11.3",

    "ହେ ପ୍ରଭୂ ! ମୋ ପକ୍ଷେ ଆପଣଙ୍କର ସେହି ଐଶ୍ବରିକ ରୂପ ଦେଖାଯିବା ସମ୍ଭବ ବୋଲି ଯଦି ଭାବୁଛନ୍ତି, ତେବେ ହେ ଯୋଗେଶ୍ବର ! ଆପଣଙ୍କର ସେହି ଅବିନାଶୀ ସ୍ବରୂପକୁ ମୋତେ ଦେଖାନ୍ତୁ। 11.4",

    "ଶ୍ରୀଭଗବାନ କହିଲେ --- ହେ ପୃଥାନନ୍ଦନ ! ଏବେ ତୁମେ ଅନେକ ପ୍ରକାରର, ଅନେକ ବର୍ଣ୍ଣ ଓ ଅନେକ ଆକୃତିବିଶିଷ୍ଟ ମୋର ଶହ ଶହ, ହଜାର ହଜାର ଅଲୌକିକ ରୂପ ଦେଖ।  11.5",

    "ହେ ଭରତବଂଶଜ ଅର୍ଜୁନ ! ତୁମେ ଦ୍ବାଦଶ ଆଦିତ୍ୟ, ଅଷ୍ଟ ବସୁ, ଏକାଦଶ ରୁଦ୍ର ଓ ଅଶ୍ବିନୀକୁମାର ଦ୍ବୟଙ୍କୁ ତଥା ଅଣଚାଶ ମରୁତ୍ ଗଣଙ୍କୁ ଦେଖ। ଯେଉଁ ସବୁ ଆଶ୍ଚର୍ଯ୍ୟଜନକ ରୂପ ତୁମେ ପୂର୍ବରୁ କେବେ ଦେଖି ନ ଥିଲ, ସେ ସବୁକୁ ମଧ୍ୟ ଦେଖ। 11.6",

    "ହେ ନିଦ୍ରାଜୟୀ ଅର୍ଜୁନ ! ମୋର ଏହି ଶରୀରର କୌଣସି ଏକ ଅଂଶରେ ତୁମେ ଏବେ ସଚରାଚର ସମଗ୍ର ଜଗତକୁ ଦେଖ। ଏହା ବ୍ୟତୀତ ତୁମେ ଆଉ ଯାହା କିଛି ଦେଖିବାକୁ ଇଚ୍ଛା କରୁଛ, ତାହା ମଧ୍ୟ ଦେଖ। 11.7",

    "କିନ୍ତୁ ତୁମେ ତୁମର ଏହି ଚର୍ମ ଚକ୍ଷୁରେ ମୋତେ ଦେଖି ପାରିବ ନାହିଁ। ତେଣୁ ମୁଁ ତୁମକୁ ଦିବ୍ୟଚକ୍ଷୁ ପ୍ରଦାନ କରୁଛି, ତାହାଦ୍ବାରା ତୁମେ ମୋର ଐଶ୍ବରିକ ସାମର୍ଥ୍ୟକୁ ଦେଖ। 11.8",

    "ସଞ୍ଜୟ କହିଲେ -- ହେ ରାଜନ୍ ! ଏପରି କହି ମହାଯୋଗେଶ୍ବର ଭଗବାନ ଶ୍ରୀକୃଷ୍ଣ ଅର୍ଜୁନଙ୍କୁ ପରମ ଐଶ୍ବରିକରୂପ ଦେଖାଇଲେ। 11.9",

    "ସେହି ଦିବ୍ୟ ଐଶ୍ବରିକ ରୂପରେ ଅନେକ ମୁଖ, ନେତ୍ର ଓ ନାନାବିଧ ଅଦ୍ଭୁତ ରୁପ ଦୃଶ୍ୟମାନ ହେଉଥିଲା ଏବଂ ତାଙ୍କ ଅଙ୍ଗରେ ଦିବ୍ୟ ଆଭୂଷଣ, ହସ୍ତରେ ଦିବ୍ୟ ଆୟୁଧ ଓ ଗଳାରେ ଦିବ୍ୟ ମାଳା ଶୋଭା ପାଉଥିଲାା। ସେ ଦିବ୍ୟ ବସ୍ତ୍ର ପରିଧାନ କରିଥିଲେ ତଥା ତାହାଙ୍କ ଲଲାଟ ଓ ଶ୍ରୀଅଙ୍ଗ ଚନ୍ଦନ ଚର୍ଚ୍ଚିତ ହୋଇଥିଲା। ଏଭଳି ଅତି ଆଶ୍ଚର୍ଯ୍ୟମୟ , ଅନନ୍ତରୂପଶାଳୀ ତଥା ସବୁଦିଗକୁ ମୁଖ ଥିବା ନିଜର ଦିବ୍ୟ ସ୍ବରୂପକୁ ଭଗବାନ ଦେଖାଇଲେ। 11.10-11.11",

    "ସେହି ଦିବ୍ୟ ଐଶ୍ବରିକ ରୂପରେ ଅନେକ ମୁଖ, ନେତ୍ର ଓ ନାନାବିଧ ଅଦ୍ଭୁତ ରୁପ ଦୃଶ୍ୟମାନ ହେଉଥିଲା ଏବଂ ତାଙ୍କ ଅଙ୍ଗରେ ଦିବ୍ୟ ଆଭୂଷଣ, ହସ୍ତରେ ଦିବ୍ୟ ଆୟୁଧ ଓ ଗଳାରେ ଦିବ୍ୟ ମାଳା ଶୋଭା ପାଉଥିଲାା। ସେ ଦିବ୍ୟ ବସ୍ତ୍ର ପରିଧାନ କରିଥିଲେ ତଥା ତାହାଙ୍କ ଲଲାଟ ଓ ଶ୍ରୀଅଙ୍ଗ ଚନ୍ଦନ ଚର୍ଚ୍ଚିତ ହୋଇଥିଲା। ଏଭଳି ଅତି ଆଶ୍ଚର୍ଯ୍ୟମୟ , ଅନନ୍ତରୂପଶାଳୀ ତଥା ସବୁଦିଗକୁ ମୁଖ ଥିବା ନିଜର ଦିବ୍ୟ ସ୍ବରୂପକୁ ଭଗବାନ ଦେଖାଇଲେ। 11.10-11.11",

    "ଯଦି ଆକାଶରେ ଏକାବେଳେ ସହସ୍ର ସୂର୍ଯ୍ୟ ଉଦିତ ହୁଅନ୍ତି, ତଥାପି ସେମାନଙ୍କର ଜ୍ୟୋତୀ ମିଶି ସେହି ମହାତ୍ମାଙ୍କ (ବିରାଟରୂପ ପରମାତ୍ମାଙ୍କ) ଜ୍ୟୋତୀ ସହିତ ସମାନ ହେବ କି ନାହିଁ ସନ୍ଦେହ ଅର୍ଥାତ୍ ହୋଇ ପାରିବ ନାହିଁ। 11.12",

    "ସେତେବେଳେ ଅର୍ଜୁନ ଦେବାଧିଦେବ ଭଗବାନଙ୍କ ଶ୍ରୀଅଙ୍ଗର କୌଣସି ଏକ ସ୍ଥାନରେ ଅନେକ ପ୍ରକାର ବିଭାଗରେ ବିଭକ୍ତ ହୋଇଥିବା ସମଗ୍ର ଜଗତକୁ ଦେଖିଲେ। 11.13",

    "ଭଗବାନଙ୍କର ବିଶ୍ବରୂପ ଦର୍ଶନ କରି ଅର୍ଜୁନ ଅତ୍ୟନ୍ତ ଚକିତ ହୋଇଗଲେ ଏବଂ ଆଶ୍ଚର୍ଯ୍ୟ ଯୋଗୁଁ ତାଙ୍କର ସର୍ବାଙ୍ଗ ରୋମାଞ୍ଚିତ ହୋଇ ଉଠିଲା । ସେ ଯୋଡହସ୍ତପୂର୍ବକ ବିଶ୍ବରୂପରେ ପ୍ରକଟିତ ଦେବଙ୍କୁ ନତମସ୍ତକ ହୋଇ ପ୍ରଣାମ କରି କହିଲେ  11.14",

    "ଅର୍ଜୁନ କହିଲେ - ହେ ଦେବ ! ମୁଁ ଆପଣଙ୍କ ଶରୀରରେ ସମସ୍ତ ଦେବଗଣଙ୍କୁ, ପ୍ରଧାନ ପ୍ରଧାନ ପ୍ରାଣୀବର୍ଗଙ୍କୁ, କମଳାସନସ୍ଥ ବ୍ରହ୍ମାଙ୍କୁ, ମହାଦେବଙ୍କୁ, ସମସ୍ତ ଋଷିଗଣଙ୍କୁ ଓ ସମସ୍ତ ଦିବ୍ୟ ସର୍ପମାନଙ୍କୁ ଦେଖୁଅଛି । 11.15",

    "ହେ ବିଶ୍ବରୂପ ! ହେ ବିଶ୍ବେଶ୍ବର ! ମୁଁ ଆପଣଙ୍କର ଅନେକ ବାହୁ, ଉଦର, ମୁଖ ଓ ନେତ୍ରବିଶିଷ୍ଟ ରୂପକୁ ତଥା ସବୁ ଦୃଷ୍ଟିରୁ ଅନନ୍ତରୂପଶାଳୀ ଅଦ୍ଭୁତ ସ୍ବରୂପକୁ ଦେଖି ପାରୁଛି । ମୁଁ ଆପଣଙ୍କର ଆଦି, ମଧ୍ୟ ବା ଅନ୍ତ କିଛିହେଲେ ଦେଖିପାରୁ ନାହିଁ । 11.16",

    "ମୁଁ ଆପଣଙ୍କୁ ମୁକୁଟ, ଗଦା, ଚକ୍ର (ତଥା ଶଙ୍ଖ ଓ ପଦ୍ମ) ଧାରଣ କରିଥିବାର ଦେଖୁଛି। ମୁଁ ଆପଣଙ୍କୁ ତେଜରାଶିଯୁକ୍ତ, ସର୍ବଦିଗେ ଦିପ୍ତିମନ୍ତ, ଦେଦୀପ୍ୟମାନ ଅଗ୍ନି ଓ ସୂର୍ଯ୍ୟଙ୍କ ସଦୃଶ ଉଜ୍ଜ୍ବଳ କାନ୍ତିସମ୍ପନ୍ନ, ଦୁର୍ନିରୀକ୍ଷ୍ୟ ଏବଂ ସବୁ ଦିଗରୁ ଅପ୍ରମେୟସ୍ବରୂପ ଦେଖୁଛି ।  11.17",

    "ମୋ ମତରେ ଆପଣ ହିଁ ଜ୍ଞାତବ୍ୟ ପରମ ଅକ୍ଷର ( ଅକ୍ଷର ବ୍ରହ୍ମ ), ଆପଣ ହିଁ ସମଗ୍ର ବିଶ୍ବର ପରମ ଆଶ୍ରୟ ଓ ସନାତନ ଧର୍ମର ରକ୍ଷକ ଏବଂ ଆପଣ ହିଁ ଅବିନାଶୀ ସନାତନ ପୁରୁଷ । 11.18",

    "ମୁଁ ଦେଖୁଛି ଯେ ଆପଣଙ୍କର ଆଦି, ମଧ୍ୟ ଓ ଅନ୍ତ ନାହିଁ ।ଆପଣଙ୍କର ପ୍ରଭାବ ଅନନ୍ତ, ଭୁଜ ଅନନ୍ତ। ଚନ୍ଦ୍ର ଓ ସୂର୍ଯ୍ୟ ଆପଣଙ୍କର ନେତ୍ର, ପ୍ରଜ୍ବଳିତ ଅଗ୍ନି ଆପଣଙ୍କ ମୁଖ। ଆପଣ ନିଜର ତେଜ ପ୍ରଭାବରେ ସମଗ୍ର ଜଗତକୁ ତାପିତ କରୁଛନ୍ତି। 11.19",

    "ହେ ମହାତ୍ମନ୍ ! ଆପଣ ନିଜର ବିରାଟରୂପରେ ସ୍ବର୍ଗ ଓ ପୃଥିବୀର ମଧ୍ୟସ୍ଥଳକୁ ଏବଂ ସମସ୍ତ ଦିଗ ବିଦିଗକୁ ଆଚ୍ଛାଦିତ କରିଛନ୍ତି । ଆପଣଙ୍କର ଏହି ଅଦ୍ଭୁତ ଓ ଉଗ୍ର ରୂପ ଦେଖି ତ୍ରୈଲୋକ୍ୟ ବ୍ୟଥିତ (ବ୍ୟାକୁଳ ) ହେଉଛି। 11.20",

    "ସେହି ଦେବଗଣ ଆପଣଙ୍କ ଠାରେ ପ୍ରବିଷ୍ଟ ହେଉଛନ୍ତି । ସେମାନଙ୍କ ମଧ୍ୟରୁ କେତେକ ଭୟଭୀତ ହୋଇ ଜୋଡହସ୍ତପୂର୍ବକ ଆପଣଙ୍କ ନାମ ଓ ଗୁଣ କୀର୍ତ୍ତନ କରୁଛନ୍ତି । ମହର୍ଷି ଓ ସିଦ୍ଧଗଣ 'କଲ୍ୟାଣ ହେଉ ! ମଙ୍ଗଳ ହେଉ !' ଏପରି ସ୍ବସ୍ତି ବାକ୍ୟ ଉଚ୍ଚାରଣ ପୂର୍ବକ ସୁନ୍ଦର ସୁନ୍ଦର ସ୍ତୋତ୍ର ଦ୍ବାରା ଆପଣଙ୍କୁ ସ୍ତୁତି କରୁଛନ୍ତି । 11.21",

    "ଏକାଦଶ ରୁଦ୍ର, ଦ୍ବାଦଶ ଆଦିତ୍ୟ, ଅଷ୍ଟ ବସୁ, ଦ୍ବାଦଶ ସାଧ୍ୟଗଣ, ଦଶ ବିଶ୍ବେଦେବ, ଅଶ୍ବିନୀକୁମାର ଦ୍ବୟ, ଅଣଚାଶ ମରୁଦ୍ ଗଣ, ଉଷ୍ଣ ଖାଦ୍ୟ ଗ୍ରହଣକାରୀ ସପ୍ତ ପିତୃଗଣ ତଥା ଗନ୍ଧର୍ବ, ଯକ୍ଷ, ଅସୁର ଓ ସିଦ୍ଧଗଣ ସମସ୍ତେ ଚକିତ ହୋଇ ଆପଣଙ୍କୁ ଦେଖୁଛନ୍ତି । 11.22",

    "ହେ ମହାବାହୁ ! ଆପଣଙ୍କର ବହୁ ମୁଖ, ନେତ୍ର, ଭୁଜ, ଉରୁ ଓ ପାଦବିଶିଷ୍ଟ ରୂପକୁ ତଥା ବହୁ ଉଦର ଓ ବିକଟାଳ ଦନ୍ତ ପଂକ୍ତିବିଶିଷ୍ଟ ମହାନ୍ ରୂପକୁ ଦେଖି ସମସ୍ତ ପ୍ରାଣୀ ବ୍ୟଥିତ ହେଉଛନ୍ତି ଏବଂ ମୁଁ ମଧ୍ୟ ବ୍ୟଥିତ ହୋଇପଡୁଛି। 11.23",

    "କାରଣ, ହେ ବିଷ୍ଣୁ ! ଆପଣଙ୍କର ବିବିଧ ବର୍ଣ୍ଣ ଅତି ଦେଦୀପ୍ୟମାନ, ଆପଣ ଆକାଶକୁ ସ୍ପର୍ଶ କରିଛନ୍ତି, ଆପଣଙ୍କର ମୁଖ ବିସ୍ତାରିତ, ନେତ୍ର ପ୍ରଦୀପ୍ତ ଓ ବିଶାଳ। ଆପଣଙ୍କୁ ଏପରି ଦେଖି ମୋ ଅନ୍ତଃକରଣରେ ଭୟ ସଞ୍ଚାର ହେବାରୁ ମୁଁ ଧୈର୍ଯ୍ୟ ଓ ଶାନ୍ତି ପାଇ ପାରୁ ନାହିଁ। 11.24",

    "ଆପଣଙ୍କର ପ୍ରଳୟକାଳୀନ ଅଗ୍ନିସମ ପ୍ରଜ୍ବଳିତ ଓ ବିକଟାଳ (ଭୟାନକ) ଦନ୍ତପଂନ୍ତିବିଶିଷ୍ଟ ମୁଖ ଦେଖି ମୋତେ ଦିଗ-ବିଦିଗର ଜ୍ଞାନ ହେଉ ନାହିଁ କି ଶାନ୍ତି ମଧ୍ୟ ମିଳୁ ନାହିଁ। ତେଣୁ ହେ ଦେବେଶ ! ହେ ଜଗନ୍ନିବାସ ! ଆପଣ ସୁପ୍ରସନ୍ନ ହୋଇଯାଆନ୍ତୁ। 11.25",

    "ଆମ ପକ୍ଷର ମୁଖ୍ୟ ମୁଖ୍ୟ ଯୋଦ୍ଧାମାନଙ୍କ ସହିତ ଭୀଷ୍ମ, ଦ୍ରୋଣ ଓ କର୍ଣ୍ଣ ମଧ୍ୟ ଆପଣଙ୍କଠାରେ ପ୍ରବିଷ୍ଟ ହେଉଛନ୍ତି । ରାଜନ୍ୟବର୍ଗଙ୍କ ସହିତ ଧୃତରାଷ୍ଟ୍ରଙ୍କ ସମସ୍ତ ପୁତ୍ର ଆପଣଙ୍କର ବିକଟାଳ ଦନ୍ତପଂକ୍ତିବିଶିଷ୍ଟ ଭୟାନକ ମୁଖରେ ଦ୍ରୁତଗତିରେ ପ୍ରବିଷ୍ଟ ହେଉଛନ୍ତି । ସେମାନଙ୍କ ମଧ୍ୟରୁ କେତେକ ଚୂର୍ଣ୍ଣ ବିଚୂର୍ଣ୍ଣ ମସ୍ତକ ସହିତ ଆପଣଙ୍କ ଦାନ୍ତ ସନ୍ଧିରେ ଲାଗିଥିବାର ଦେଖାଯାଉଛନ୍ତି । 11.26-11.27",

    "ଆମ ପକ୍ଷର ମୁଖ୍ୟ ମୁଖ୍ୟ ଯୋଦ୍ଧାମାନଙ୍କ ସହିତ ଭୀଷ୍ମ, ଦ୍ରୋଣ ଓ କର୍ଣ୍ଣ ମଧ୍ୟ ଆପଣଙ୍କଠାରେ ପ୍ରବିଷ୍ଟ ହେଉଛନ୍ତି । ରାଜନ୍ୟବର୍ଗଙ୍କ ସହିତ ଧୃତରାଷ୍ଟ୍ରଙ୍କ ସମସ୍ତ ପୁତ୍ର ଆପଣଙ୍କର ବିକଟାଳ ଦନ୍ତପଂକ୍ତିବିଶିଷ୍ଟ ଭୟାନକ ମୁଖରେ ଦ୍ରୁତଗତିରେ ପ୍ରବିଷ୍ଟ ହେଉଛନ୍ତି । ସେମାନଙ୍କ ମଧ୍ୟରୁ କେତେକ ଚୂର୍ଣ୍ଣ ବିଚୂର୍ଣ୍ଣ ମସ୍ତକ ସହିତ ଆପଣଙ୍କ ଦାନ୍ତ ସନ୍ଧିରେ ଲାଗିଥିବାର ଦେଖାଯାଉଛନ୍ତି । 11.26-11.27",

    "ନଦୀଗୁଡିକର ଅନେକ ଜଳସ୍ରୋତ ଯେପରି ସ୍ବାଭାବିକଭାବେ ସମୁଦ୍ର ଅଭିମୁଖେ ଧାବମାନ ହୁଅନ୍ତି, ସେହିପରି ପୃଥିବୀର ମହା ପରାକ୍ରମୀ ବୀର ଯୋଦ୍ଧାମାନେ ଆପଣଙ୍କର ଦେଦିପ୍ୟମାନ ମୁଖରେ ପ୍ରବେଶ କରୁଛନ୍ତି । 11.28",

    "ପତଙ୍ଗମାନେ ମୋହବଶତଃ ନିଜକୁ ବିନାଶ କରିବା ପାଇଁ ଯେପରି ଦ୍ରୂତଗତିରେ ଧାବିତ ହୋଇ ପ୍ରଜ୍ବଳିତ ଅଗ୍ନିରେ ପ୍ରବିଷ୍ଟ ହୁଅନ୍ତି, ସେହିପରି ଏହି ଲୋକମାନେ ମଧ୍ୟ ନିଜକୁ ବିନାଶ କରିବା ପାଇଁ ଦ୍ରୂତଗତିରେ ଧାବିତ ହୋଇ ଆପଣଙ୍କ ମୁଖ ଗହ୍ବରରେ ପ୍ରବେଶ କରୁଛନ୍ତି । 11.29",

    "ଆପଣ ନିଜର ପ୍ରଜ୍ବଳିତ ମୁଖଗୁଡିକ ଦ୍ବାରା ସମସ୍ତ ଭୁବନକୁ ଗ୍ରାସ କରି ସେସବୁକୁ ଚତୁଷ୍ପାର୍ଶ୍ବରୁ ବାରମ୍ବାର ଚାଟୁଛନ୍ତି, ଏବଂ ହେ ବିଷ୍ଣୁ ! ଆପଣଙ୍କର ଉଗ୍ର ଜ୍ୟୋତିର ତେଜ ସମଗ୍ର ଜଗତରେ ପରିପୂର୍ଣ୍ଣ ହୋଇ ସମସ୍ତଙ୍କୁ ସନ୍ତପ୍ତ କରୁଛି । 11.30",

    "ମୋତେ ଦୟାକରି କହନ୍ତୁ ଉଗ୍ରରୂପଧାରୀ ଆପଣ କିଏ ? ହେ ଦେବଶ୍ରେଷ୍ଠ ! ଆପଣଙ୍କୁ ମୋର ନମସ୍କାର । ଆପଣ ପ୍ରସନ୍ନ ହୁଅନ୍ତୁ ।ଆପଣ ଆଦିରୂପ ଅଟନ୍ତି । ଆପଣଙ୍କୁ ମୁଁ ତତ୍ତ୍ବ ସହକାରେ ଜାଣିବାକୁ ଚାହୁଁଛି, କାରଣ ମୁଁ ଆପଣଙ୍କର ପ୍ରବୃତ୍ତିକୁ ଭଲଭାବେ ଜାଣେ ନାହିଁ । 11.31",

    "ଶ୍ରୀଭଗବାନ କହିଲେ -- ମୁଁ ସମଗ୍ର ଭୁବନର ବିନାଶକାରୀ ବର୍ଦ୍ଧିତ କାଳ ଏବଂ ବର୍ତ୍ତମାନ ମୁଁ ଏ ସମସ୍ତଙ୍କୁ ସଂହାର କରିବା ପାଇଁ ଏଠାକୁ ଆସିଛି । ତୁମ ବିପକ୍ଷରେ ଯେଉଁ ଯୋଦ୍ଧାମାନେ ଛିଡା ହୋଇଛନ୍ତି, ତୁମେ ଯୁଦ୍ଧ ନ କଲେ ମଧ୍ୟ ସେ ସମସ୍ତେ ବର୍ତ୍ତିବେ ନାହିଁ । 11.32",

    "ତେଣୁ ତୁମେ ଯୁଦ୍ଧ ପାଇଁ ଠିଆ ହୋଇଯାଅ ଏବଂ ଯଶ ଲାଭ କର ତଥା ଶତ୍ରୁମାନଙ୍କୁ ଜୟ କରି ଧନଧାନ୍ୟ- ସମୃଦ୍ଧ ରାଜ୍ୟ ଭୋଗ କର । ଏ ସମସ୍ତେ ପୂର୍ବରୁ ମୋ ଦ୍ବାରା ନିହତ ହୋଇ ସାରିଛନ୍ତି ।ହେ ସବ୍ୟସାଚୀ ! ତୁମେ ଏମାନଙ୍କୁ ମାରିବାରେ ନିମିତ୍ତ ମାତ୍ର ହୁଅ । 11.33",

    "ଦ୍ରୋଣ, ଭୀଷ୍ମ, ଜୟଦ୍ରଥ ଓ କର୍ଣ୍ଣଙ୍କୁ ତଥା ମୋ ଦ୍ବାରା ନିହତ ଅନ୍ୟ ବୀର ଯୋଦ୍ଧାଙ୍କୁ ତୁମେ ମାର । ତୁମେ ବ୍ୟଥିତ ହୁଅ ନାହିଁ, ଯୁଦ୍ଧ କର । ଯୁଦ୍ଧରେ ତୁମେ ନିଶ୍ଚୟ ଶତ୍ରୁମାନଙ୍କୁ ଜିଣିବ । 11.34",

    "ସଞ୍ଜୟ କହିଲେ -- କେଶବଙ୍କର ଏହି ବଚନ ଶୁଣି ଭୟରେ ଥରହର କିରଟଧାରୀ ଅର୍ଜୁନ ଯୋଡହସ୍ତପୂର୍ବକ ନମସ୍କାର କଲେ ଏବଂ ଭୟଭୀତ ହୋଇଥିଲେହେଁ ପୁନଃ ପ୍ରଣାମ କରି ସଗଦ୍ଗଦ ବାଣୀରେ ଶ୍ରୀକୃଷ୍ଣଙ୍କୁ କହିଲେ । 11.35",

    "ଅର୍ଜୁନ କହିଲେ -- ହେ ଅନ୍ତର୍ଯ୍ୟାମୀ ଭଗବାନ ! ଆପଣଙ୍କର ନାମ, ଗୁଣ ଓ ଲୀଳା କୀର୍ତ୍ତନ କରିସମଗ୍ର ଜଗତ ହର୍ଷିତ ହେଉଛି ଏବଂ ଆପଣଙ୍କ ପ୍ରତି ଅନୁରକ୍ତ ହେଉଛି ।ଆପଣଙ୍କର ନାମ, ଗୁଣାଦି କୀର୍ତ୍ତନ ଦ୍ବାରା ରାକ୍ଷସମାନେ ଭୟଭୀତ ହୋଇ ଦଶଦିଗକୁ ପଳାୟନ କରୁଛନ୍ତି ଏବଂ ସମସ୍ତ ସିଦ୍ଧଗଣ ଆପଣଙ୍କୁ ନମସ୍କାର କରୁଛନ୍ତି । ଏସବୁ ହେବା ଯଥାର୍ଥ ମଧ୍ୟ । 11.36",

    "ହେ ମହାତ୍ମନ୍ ! ଆପଣ ଗୁରୁମାନଙ୍କର ଗୁରୁ ଓ ବ୍ରହ୍ମାଙ୍କର ମଧ୍ୟ ଆଦିକର୍ତ୍ତା, ଆପଣଙ୍କୁ ( ସେହି ସିଦ୍ଧଗଣ ) କାହିଁକି ବା ନମସ୍କାର ନ କରିବେ ? କାରଣ ହେ ଅନନ୍ତ ! ହେ ଦେବେଶ ! ହେ ଜଗନ୍ନିବାସ ! ଆପଣ ଅକ୍ଷରସ୍ବରୂପ, ଆପଣ ହିଁ ସତ୍ ଓ ଅସତ୍ ଏବଂ ସତ୍-ଅସତ୍ ରୁ ଅତିରିକ୍ତ ଯାହା କିଛି ଅଛି, ତାହା ମଧ୍ୟ ଆପଣ । 11.37",

    "ଆପଣ ହିଁ ଆଦିଦେବ ଓ ପୁରାଣପୁରୁଷ ତଥା ଏ ସଂସାରର ପରମ ଆଶ୍ରୟ । ଆପଣ ହିଁ ସମସ୍ତଙ୍କର ଜ୍ଞାତା, ଜ୍ଞେୟ ଓ ପରମଧାମ । ହେ ଅନନ୍ତସ୍ବରୂପ ! ଆପଣଙ୍କ ଦ୍ବାରା ହିଁ ସମଗ୍ର ସଂସାର ପରିବ୍ୟାପ୍ତ । 11.38",

    "ଆପଣ ହିଁ ବାୟୁ, ଯମରାଜ, ଅଗ୍ନି, ବରୁଣ, ଚନ୍ଦ୍ର, ଦକ୍ଷାଦି ପ୍ରଜାପତି ଓ ପ୍ରପିତାମହ ( ବ୍ରହ୍ମାଙ୍କର ମଧ୍ୟ ପିତା ) । ଆପଣଙ୍କୁ ହଜାରବାର ନମସ୍କାର ! ନମସ୍କାର !! ଏବଂ ଆହୁରି ମଧ୍ୟ ଆପଣଙ୍କୁ ବାରମ୍ବାର ନମସ୍କାର ! ନମସ୍କାର !!  11.39",

    " ହେ ସର୍ବସ୍ବରୂପ ! ଆପଣଙ୍କୁ ସମ୍ମୁଖଭାଗରୁ ନମସ୍କାର ! ପଶ୍ଚାତ୍ ଭାଗରୁ ନମସ୍କାର ! ସର୍ବଦିଗରୁ ନମସ୍କାର ! ହେ ଅନନ୍ତବୀର୍ଯ୍ୟ ! ଆପଣଙ୍କ ପରାକ୍ରମ ଅପରିମିତ ଏବଂ ଆପଣ ସମସ୍ତଙ୍କୁ ଏକତ୍ର ସମାବିଷ୍ଟ କରି ରଖିଛନ୍ତି, ତେଣୁ ଆପଣ ହିଁ ସବୁକିଛି । 11.40",

    "ଆପଣଙ୍କର ମହିମା ନ ଜାଣି, ଆପଣଙ୍କୁ ସଖା ମନେକରି ମୁଁ ପ୍ରମାଦବଶତଃ ଅଥବା ପ୍ରେମାବେଶରେ ହଠପୂର୍ବକ ନିର୍ବିଚାରରେ ' ହେ କୃଷ୍ଣ ! ହେ ଯାଦବ ! ହେ ସଖା ! ' ଏହିଭଳି  ଯାହାକିଛି କହିଛି, ଏବଂ ହେ ଅଚ୍ୟୁତ ! ଥଟ୍ଟା ମଜାରେ ଓ ଚାଲିବା-ବୁଲିବା, ଶୋଇବା-ଜାଗିବା, ବସିବା-ଉଠିବା, ଖାଇବା-ପିଇବା ସମୟରେ, ଏକୁଟିଆ କିମ୍ବା ସଖା, କୁଟୁମ୍ବ ଆଦିଙ୍କ ସମ୍ମୁଖରେ ମୁଁ ଆପଣଙ୍କୁ ଯେତେ ତିରସ୍କାର କରିଛି, ହେ ଅପ୍ରମେୟସ୍ବରୂପ ! ସେସବୁ ପାଇଁ ଆପଣଙ୍କ ନିକଟରେ ମୁଁ କ୍ଷମା ଭୀକ୍ଷା କରୁଛି । 11.41-11.42",

    "ଆପଣଙ୍କର ମହିମା ନ ଜାଣି, ଆପଣଙ୍କୁ ସଖା ମନେକରି ମୁଁ ପ୍ରମାଦବଶତଃ ଅଥବା ପ୍ରେମାବେଶରେ ହଠପୂର୍ବକ ନିର୍ବିଚାରରେ ' ହେ କୃଷ୍ଣ ! ହେ ଯାଦବ ! ହେ ସଖା ! ' ଏହିଭଳି  ଯାହାକିଛି କହିଛି, ଏବଂ ହେ ଅଚ୍ୟୁତ ! ଥଟ୍ଟା ମଜାରେ ଓ ଚାଲିବା-ବୁଲିବା, ଶୋଇବା-ଜାଗିବା, ବସିବା-ଉଠିବା, ଖାଇବା-ପିଇବା ସମୟରେ, ଏକୁଟିଆ କିମ୍ବା ସଖା, କୁଟୁମ୍ବ ଆଦିଙ୍କ ସମ୍ମୁଖରେ ମୁଁ ଆପଣଙ୍କୁ ଯେତେ ତିରସ୍କାର କରିଛି, ହେ ଅପ୍ରମେୟସ୍ବରୂପ ! ସେସବୁ ପାଇଁ ଆପଣଙ୍କ ନିକଟରେ ମୁଁ କ୍ଷମା ଭୀକ୍ଷା କରୁଛି । 11.41-11.42",

    "ଆପଣ ହିଁ ଚରାଚର ଜଗତର ପିତା, ଆପଣ ହିଁ ପୂଜନୀୟ ଏବଂ ଆପଣ ହିଁ ଗୁରୁମାନଙ୍କର ମହାନ୍ ଗୁରୁ । ହେ ଅନନ୍ତ ପ୍ରଭାବଶାଳୀ ଭଗବନ୍ ! ତ୍ରିଭୁବନରେ ଆପଣଙ୍କ ସମାନ ମଧ୍ୟ ଆଉ କେହି ନାହିଁ, ପୁଣି ଆପଣଙ୍କଠାରୁ ଅଧିକ ଶ୍ରେଷ୍ଠ କିଏ ବା ହୋଇପାରିବ ! 11.43",

    "ତେଣୁ ହେ ଈଶ୍ବର ! ଆପଣ ସାଷ୍ଟାଙ୍ଗ ପ୍ରଣିପାତ ଦ୍ବାରା ସ୍ତବନୀୟ । ଆପଣଙ୍କୁ ମୁଁ ପ୍ରଣାମ କରି ପ୍ରସନ୍ନ କରିବାକୁ ଚାହୁଁଛି । ଯେପରି ପିତା ପୁତ୍ରର, ମିତ୍ର ମିତ୍ରର ଓ ପତି ପତ୍ନୀର ଅପମାନକୁ ସହ୍ୟ କରନ୍ତି, ସେହିପରି ହେ ଦେବ ! ଆପଣ ମୋ ଦ୍ବାରା କରାଯାଇଥିବା ଅପମାନକୁ ସହ୍ୟ କରିବାରେ ଅର୍ଥାତ୍ କ୍ଷମା କରିବାରେ ସମର୍ଥ। 11.44",

    "ମୁଁ ପୂର୍ବରୁ ଏପରି ରୂପ କେବେ ଦେଖି ନ ଥିଲି । ଏହି ରୂପ ଦର୍ଶନ କରି ମୁଁ ହର୍ଷିତ ହେଉଛି । ତତ୍ ସହିତ ଭୟରେ ମୋର ମନ ଅତ୍ୟନ୍ତ ବ୍ୟଥିତ ମଧ୍ୟ ହେଉଛି ।ତେଣୁ ମୋତେ ଆପଣଙ୍କର ସେହି ଦେବରୂପ ( ସୌମ୍ୟ ବିଷ୍ଣୁରୂପ ) ଦର୍ଶନ କରାନ୍ତୁ । ହେ ଦେବେଶ ! ହେ ଜଗନ୍ନିବାସ ! ଆପଣ ସୁପ୍ରସନ୍ନ ହୋଇଯାଆନ୍ତୁ । 11.45",

    "ମୁଁ ଆପଣଙ୍କୁ ପୁନଃ ସେହି କିରୀଟଧାରୀ, ଗଦାଧାରୀ ଓ ଚକ୍ରଧାରୀ ରୂପରେ ଅର୍ଥାତ୍ ଚତୁର୍ଭୁଜ ରୂପରେ ଦେଖିବାକୁ ଚାହୁଁଛି । ତେଣୁ ହେ ସହସ୍ରବାହୁ ! ହେ ବିଶ୍ବମୂର୍ତ୍ତି ! ଆପଣ ପୂର୍ବଭଳି ଚତୁର୍ଭୁଜରୂପ ଧାରଣ କରନ୍ତୁ । 11.46",

    "ଶ୍ରୀ ଭଗବାନ କହିଲେ -- ହେ ଅର୍ଜୁନ ! ମୁଁ ପ୍ରସନ୍ନ ହୋଇ ନିଜର ସାମର୍ଥ୍ୟ ଦ୍ବାରା ମୋର ଏହି ଅତ୍ୟନ୍ତ ଶ୍ରେଷ୍ଠ, ତେଜୋମୟ, ସମସ୍ତଙ୍କର ଆଦି ଓ ଅନନ୍ତ ବିଶ୍ବରୂପ ତୁମକୁ ଦେଖାଇଲି । ତୁମ ବ୍ୟତୀତ ଏହାକୁ ପୂର୍ବରୁ କେହି ଦେଖି ନ ଥିଲେ । 11.47",

    "ହେ କୁରୁଶ୍ରେଷ୍ଠ ! କେବଳ ତୁମ ଛଡା ( କୃପାପାତ୍ର ବ୍ୟତୀତ ) ଅନ୍ୟ କେହି ବେଦାଧ୍ୟୟନ, ଯଜ୍ଞାନୁଷ୍ଠାନ, ଶାସ୍ତ୍ରାଧ୍ୟୟନ, ଦାନ, କଠୋର ତପସ୍ୟା ଏବଂ କୌଣସି କ୍ରିୟା ଦ୍ବାରା ମର୍ତ୍ତ୍ୟଭୁବନରେ ମୋତେ ଏହି ବିଶ୍ବରୂପରେ ଦେଖିବା ସମ୍ଭବ ନୁହେଁ । 11.48",

    "ମୋର ଏହି ଉଗ୍ରରୂପ ଦେଖି ତୁମେ ବ୍ୟଥିତ ହୁଅ ନାହିଁ ଏବଂ ହୃଦୟରେ ମୂଢଭାବକୁ ସ୍ଥାନ ଦିଅ ନାହିଁ । ଏବେ ନିର୍ଭୟ ଓ ପ୍ରସନ୍ନ ମନରେ ତୁମେ ପୁନଶ୍ଚ ମୋର ଏହି ଚତୁର୍ଭୁଜ ରୂପ ଭଲଭାବେ ଦର୍ଶନ କର । 11.49",

    "ସଞ୍ଜୟ କହିଲେ -- ଭଗବାନ ବାସୁଦେବ ଅର୍ଜୁନଙ୍କୁ ଏପରି କହି ପୁନଶ୍ଚ ନିଜର ସେହି ରୂପ ( ଦେବରୂପ ) ଦେଖାଇଲେ ଏବଂ ମହାତ୍ମା ଶ୍ରୀକୃଷ୍ଣ ପୁନର୍ବାର ସୌମ୍ୟବପୁ (ଦ୍ବିଭୁଜରୂପ) ଧାରଣ କରି ଭୟଭୀତ ଅର୍ଜୁନଙ୍କୁ ସାନ୍ତ୍ବନା ଦେଲେ। 11.50",

    "ଅର୍ଜୁନ କହିଲେ - ହେ ଜନାର୍ଦନ ! ଆପଣଙ୍କର ଏହି ସୌମ୍ୟ ମାନବରୂପ ଦର୍ଶନ କରି ମୁଁ ଏବେ ସ୍ଥିରଚିତ୍ତ ହେଲି ଏବଂ ନିଜର ସ୍ବାଭାବିକ ସ୍ଥିତି ଫେରି ପାଇଲି। 11.51",

    "ଶ୍ରୀଭଗବାନ କହିଲେ - ତୁମେ ମୋର ଏ ଯେଉଁ ଚତୁର୍ଭୁଜ ରୂପ ଦର୍ଶନ କଲ, ଏହା ଦର୍ଶନ କରିବା ଅତ୍ୟନ୍ତ ଦୁର୍ଲଭ । ଏହି ରୂପ ଦର୍ଶନ ପାଇଁ ଦେବତାମାନେ ମଧ୍ୟ ସର୍ବଦା ଲାଳାୟିତ । 11.52",

    "ତୁମେ ମୋତେ ଯେଉଁପରି ଦେଖିଲ , ସେହିପରି ମୋତେ (ଚତୁର୍ଭୁଜରୂପରେ) ବେଦ ଦ୍ବାରା, ତପ ଦ୍ବାରା, ଦାନ ଦ୍ବାରା ବା ଯଜ୍ଞ ଦ୍ବାରା ଦେଖିବା ସମ୍ଭବ ନୁହେଁ । 11.53",

    "କିନ୍ତୁ ହେ ଶତ୍ରୁତାପନ ଅର୍ଜୁନ ! ଅନନ୍ୟଭକ୍ତି ଦ୍ବାରା ହିଁ ଏହିପରି (ଚତୁର୍ଭୁଜରୂପରେ) ମୋତେ ତତ୍ତ୍ବତଃ ଜାଣି ହୁଏ ,ସଗୁଣରୂପରେ ଦେଖି ହୁଏ ଏବଂ ପ୍ରାପ୍ତ କରି ହୁଏ । 11.54",

    "ହେ ପାଣ୍ଡବ ! ଯେ କେବଳ ମୋ ପାଇଁ କର୍ମ କରେ ଏବଂ ଯେ ମତ୍ପରାୟଣ , ମୋର ପ୍ରେମୀଭକ୍ତ , ସର୍ବଥା ଆସକ୍ତିରହିତ ଓ ପ୍ରତ୍ୟେକ ପ୍ରାଣୀଙ୍କ ପ୍ରତି ଶତ୍ରୁତାଶୂନ୍ୟ, ସେହି ଭକ୍ତ ମୋତେ ପ୍ରାପ୍ତ ହୁଏ । 11.55",

    "ଏହି ପ୍ରକାର ଓଁ ତତ୍ ସତ୍ -- ଏହି ଭଗବତ୍ ନାମର ଉଚ୍ଚାରଣପୂର୍ବକ ବ୍ରହ୍ମବିଦ୍ୟା ଓ ଯୋଗଶାସ୍ତ୍ରମୟ ଶ୍ରୀମଦ୍ଭଗବଦ୍ଗୀତା ଉପନିଷଦରୂପେ ଶ୍ରୀକୃଷ୍ଣ ଏବଂ ଅର୍ଜୁନଙ୍କ ସମ୍ବାଦରେ 'ବିଶ୍ବରୂପଦର୍ଶନଯୋଗନାମକ' ଏକାଦଶ ଅଧ୍ୟାୟ ପୂର୍ଣ୍ଣ ହେଲା"
  ],
  "adhyaya-12" : [
    "ଅର୍ଜୁନ କହିଲେ - ଯେଉଁ ଭକ୍ତମାନେ ଏହିପରି( ଏକାଦଶ ଅଧ୍ଯାୟର ୫୫ଶ ଶ୍ଳୋକ ଅନୁସାରେ ) ନିରନ୍ତର ଆପଣଂକଠାରେ ସଂଲଗ୍ନ ହୋଇ ଆପଣଂକର ( ସଗୁଣ-ସାକାରଂକର) ଉପାସନା କରନ୍ତି ଏବଂ ଯେଉଁମାନେ ଅବିନାଶୀ ନିର୍ଗୁଣ-ନିରାକାରଂକର ଉପାସନା କରନ୍ତି, ସେମାନଂକ ମଧ୍ଯରେ ଉତ୍ତମ ଯୋଗବେତ୍ତା କେଉଁମାନେ ? 12.1",

    "ଶ୍ରୀ ଭଗବାନ କହିଲେ - ମୋ’ ଠାରେ ମନ ନିବେଶପୂର୍ବକ ନିରନ୍ତର ମୋ ସହିତ ସଂଲଗ୍ନ  ହୋଇଥିବା ଯେଉଁ ଭକ୍ତମାନେ ପରମ ଶ୍ରଦ୍ଧାଯୁକ୍ତ ହୋଇ ମୋର ଉପାସନା କରନ୍ତି, ମୋ ମତରେ ସେମାନେ ସର୍ବଶ୍ରେଷ୍ଠ ଯୋଗୀ। 12.2",

    "ଯେଉଁମାନେ ଅଚିନ୍ତ୍ଯ, ସର୍ବତ୍ର ପରିପୂର୍ଣ୍ଣ , ଅଦୃଶ୍ଯ, ନିର୍ବିକାର, ଅଚଳ, ଧ୍ରୁବ, ଅକ୍ଷର ଓ ଅବ୍ଯକ୍ତଂକୁ ତତ୍ପରତାପୂର୍ବକ ଉପାସନା କରନ୍ତି, ସେହି ମନୁଷ୍ଯ ମୋତେ ହିଁ ପ୍ରପ୍ତ ହୁଅନ୍ତି । 12.3",

    "ଏବଂ ଯେଉଁମାନେ ନିଜର ଇନ୍ଦ୍ରିୟଗଣଙ୍କୁ ଉତ୍ତମରୂପେ ବଶୀଭୂତ କରି ଅଚିନ୍ତ୍ୟ, ସର୍ବତ୍ର ପରିପୂର୍ଣ୍ଣ, ଅଦୃଶ୍ୟ, ନିର୍ବିକାର, ଅଚଳ, ଧ୍ରୁବ, ଅକ୍ଷର ଓ ଅବ୍ୟକ୍ତଙ୍କୁ ତତ୍ପରତାପୂର୍ବକ ଉପାସନା କରନ୍ତି, ପ୍ରାଣୀମାନଙ୍କ ହିତରେ ରତ ଓ ସର୍ବତ୍ର ସମବୁଦ୍ଧିସମ୍ପନ୍ନ ସେହି ମନୁଷ୍ୟ ମୋତେ ହିଁ ପ୍ରାପ୍ତ ହୁଅନ୍ତି। 12.4",

    "ଯେଉଁ ସାଧକମାନଙ୍କର ଚିତ୍ତ ଅବ୍ୟକ୍ତ ପ୍ରତି ଆସକ୍ତ, ସେମାନଙ୍କୁ ସାଧନାରେ ଅଧିକ କ୍ଳେଶ ଅନୁଭବ ହୁଏ, କାରଣ ଦେହାଭିମାନୀ ପୁରୁଷ ବହୁ କଷ୍ଟରେ ଅବ୍ୟକ୍ତ ବିଷୟକ ଗତି ଲାଭ କରିଥାନ୍ତି। 12.5",

    "କିନ୍ତୁ ଯେଉଁମାନେ ସମସ୍ତ କର୍ମ ମୋ ନିକଟରେ ଅର୍ପଣ କରି, ମତ୍ପରାୟଣ ହୋଇ ଓ ଅନନ୍ୟଯୋଗ (ଐକାନ୍ତିକ ସମ୍ବନ୍ଧ)ରେ ମୋତେ ଧ୍ୟାନ କରି ଉପାସନା କରନ୍ତି। 12.6",

    "ହେ ପାର୍ଥ ! ମୋ’ ଠାରେ ଚିତ୍ତ ନିବେଶ କରିଥିବା ସେହି ଭକ୍ତମାନଂକୁ ମୁଁ ମୃତ୍ଯୁରୂପୀ ସଂସାର-ସାଗରରୁ ଶୀଘ୍ର ଉଦ୍ଧାର କରେ । 12.7",

    "ତୁମେ ମୋ ଠାରେ ମନ ସ୍ଥିର କର ଏବଂ ମୋ ଠାରେ ହିଁ ବୁଦ୍ଧି ନିବେଶ କର। ଏହାପରେ ତୁମେ ମୋ ଠାରେ ଯେ ନିବାସ କରିବ, ଏଥିରେ ସଂଶୟ ନାହିଁ। 12.8",

    "ଯଦି ମନକୁ ମୋ ଠାରେ ଅବିଚଳଭାବେ ସ୍ଥିର(ଅର୍ପଣ) କରିବାରେ ତୁମେ ନିଜକୁ ଅସମର୍ଥ ଭାବୁଛ, ତେବେ ହେ ଧନଞ୍ଜୟ ! ଅଭ୍ୟାସଯୋଗ ଦ୍ବାରା ମୋତେ ପାଇବାକୁ ଇଛାକର।। 12.9",

    "ଯଦି ଅଭ୍ୟାସଯୋଗରେ ମଧ୍ୟ ନିଜକୁ ଅସମର୍ଥ ମନେ କରୁଛ, ତା ହେଲେ ମୋ ନିମନ୍ତେ କର୍ମପରାୟଣ ହୋଇଯାଅ । ମୋ ନିମନ୍ତେ କର୍ମ କରି ମଧ୍ୟ ତୁମେ ସିଦ୍ଧି ଲାଭ କରିବ। 12.10",

    "ମୋ ଯୋଗ(ସମତା)ର ଆଶ୍ରିତ ହୋଇ ଯଦି ତୁମେ ଏହା (ପୂର୍ବଶ୍ଳୋକରେ କଥିତ ସାଧନା) କରିବାରେ ମଧ୍ୟ ନିଜକୁ ଅସମର୍ଥ ମନେ କରୁଛ, ତେବେ ମନ ଓ ଇନ୍ଦ୍ରିୟଗଣଙ୍କୁ ସଂଯତ କରି ସମସ୍ତ କର୍ମର ଫଳେଚ୍ଛା ତ୍ୟାଗ କର ।  12.11",

    "ଅଭ୍ୟାସଠାରୁ ଶାସ୍ତ୍ରଜ୍ଞାନ ଶ୍ରେଷ୍ଠ , ଶାସ୍ତ୍ରଜ୍ଞାନଠାରୁ ଧ୍ୟାନ ଶ୍ରେଷ୍ଠ ଏବଂ ଧ୍ୟାନଠାରୁ ମଧ୍ୟ ସମସ୍ତ କର୍ମର ଫଳେଚ୍ଛା ତ୍ୟାଗ କରିବା ଶ୍ରେଷ୍ଠ। କାରଣ ତ୍ୟାଗରୁ ତତ୍ କ୍ଷଣାତ୍ ପରମ ଶାନ୍ତି।  12.12",

    "ଯେ ସମସ୍ତ ପ୍ରାଣୀଙ୍କ ପ୍ରତି ଦ୍ବେଷଭାବଶୂନ୍ୟ, ମିତ୍ରଭାବାପନ୍ନ ଓ ଦୟାଳୁ, ମମତାରହିତ, ଅହଂକାରରହିତ, ସୁଖଦୁଃଖ ପ୍ରାପ୍ତିରେ ସମଭାବାପନ୍ନ, କ୍ଷମାଶୀଳ, ନିରନ୍ତର ସନ୍ତୁଷ୍ଟ, ଯୋଗୀ, ଶରୀର ସଂଯମୀ, ଦୃଢନିଶ୍ଚୟୀ ଏବଂ ଯେ ମୋ ଠାରେ ମନବୁଦ୍ଧି ଅର୍ପଣ କରିଛି, ମୋର ସେହି ଭକ୍ତ ମୋତେ ପ୍ରିୟ ଲାଗେ। 12.13-12.14",

    "ଯେ ସମସ୍ତ ପ୍ରାଣୀଙ୍କ ପ୍ରତି ଦ୍ବେଷଭାବଶୂନ୍ୟ, ମିତ୍ରଭାବାପନ୍ନ ଓ ଦୟାଳୁ, ମମତାରହିତ, ଅହଂକାରରହିତ, ସୁଖଦୁଃଖ ପ୍ରାପ୍ତିରେ ସମଭାବାପନ୍ନ, କ୍ଷମାଶୀଳ, ନିରନ୍ତର ସନ୍ତୁଷ୍ଟ, ଯୋଗୀ, ଶରୀର ସଂଯମୀ, ଦୃଢନିଶ୍ଚୟୀ ଏବଂ ଯେ ମୋ ଠାରେ ମନବୁଦ୍ଧି ଅର୍ପଣ କରିଛି, ମୋର ସେହି ଭକ୍ତ ମୋତେ ପ୍ରିୟ ଲାଗେ। 12.13-12.14",

    "ଯେଉଁ ବ୍ୟକ୍ତି କୌଣସି ପ୍ରାଣୀ ପାଇଁ ଉଦ୍ବେଗର କାରଣ ହୁଏ ନାହିଁ ଏବଂ ଯେ ନିଜେ ମଧ୍ୟ କୌଣସି ପ୍ରାଣୀ ଦ୍ବାରା ଉଦ୍ବେଗ ପ୍ରାପ୍ତ ହୁଏ ନାହିଁ ତଥା ଯେ ହର୍ଷ, ଅମର୍ଷ(ଈର୍ଷା), ଭୟ ଓ ଉଦ୍ବେଗଶୂନ୍ୟ, ସେ ମୋର ପ୍ରିୟ। 12.15",

    "ଯେ ଅପେକ୍ଷାଶୂନ୍ୟ (ଆବଶ୍ୟକତା ପ୍ରତି ନିଃସ୍ପୃହ) , ବାହ୍ୟ ଓ ଅଭ୍ୟନ୍ତର ଦୃଷ୍ଟିରୁ ପବିତ୍ର, ଚତୁର, ଉଦାସୀନ, ବ୍ୟଥାରହିତ ଓ ସର୍ବାରମ୍ଭ ପରିତ୍ୟାଗୀ ଅର୍ଥାତ୍ ନୂତନ ନୂତନ କର୍ମାରମ୍ଭ ପରିତ୍ୟାଗ କରିଛି , ମୋର ସେହି ଭକ୍ତ ମୋତେ ପ୍ରିୟ ଲାଗେ। 12.16",

    "ଯେ କେବେ ହର୍ଷିତ ହୁଏ ନାହିଁ, ଦ୍ବେଷ କରେ ନାହିଁ, ଶୋକ କରେ ନାହିଁ କି କାମନା କରେ ନାହିଁ ଏବଂ ଯେ ଶୁଭାଶୁଭ କର୍ମର ଉର୍ଦ୍ଧ୍ବରେ (ଆସକ୍ତି-ବିରକ୍ତିଶୂନ୍ୟ) , ସେହି ଭକ୍ତିମାନ୍ ମନୁଷ୍ୟ ମୋର ପ୍ରିୟ ଅଟେ । 12.17",

    "ଯେ ଶତ୍ରୁ-ମିତ୍ରରେ ତଥା ମାନ-ଅପମାନରେ ସମ, ଶୀତ-ଉଷ୍ଣ ( ଶାରୀରିକ ଅନୁକୁଳତା-ପ୍ରତିକୁଳତା) ତଥା ସୁଖ-ଦୁଃଖରେ ( ମନ ଓ ବୁଦ୍ଧିର ଅନୁକୁଳତା-ପ୍ରତିକୁଳତାରେ ) ସମଭାବାପନ୍ନ ଓ ଆସକ୍ତିରହିତ ରହେ, ସେହି ଭକ୍ତିମାନ୍ ମନୁଷ୍ୟ ମୋର ପ୍ରିୟ ଅଟେ । 12.18",

    "ଯେ ନିନ୍ଦା ଓ ସ୍ତୁତିରେ ସମଭାବାପନ୍ନ ମନନଶୀଳ, ଯେନକେନ ପ୍ରକାରେ ( ଶରୀର ନିର୍ବାହ ସମ୍ବନ୍ଧରେ )ସନ୍ତୁଷ୍ଟ, ଶରୀର ଓ ବାସସ୍ଥାନ ପ୍ରତି ମମତା-ଆସକ୍ତିରହିତ ଏବଂ ସ୍ଥିରବୁଦ୍ଧିଯୁକ୍ତ ରହନ୍ତି , ସେହି ଭକ୍ତିମାନ୍ ମନୁଷ୍ୟ ମୋର ପ୍ରିୟ ଅଟନ୍ତି। 12.19",

    "କିନ୍ତୁ ଯେଉଁ ଶ୍ରଦ୍ଧାଶୀଳ ଓ ମତ୍ପରାୟଣ ଭକ୍ତମାନେ ଏହି ଧର୍ମମୟ ଅମୃତକୁ ପୂର୍ବ କଥନ ଅନୁସାରେ ଉତ୍ତମରୂପେ ସେବନ କରନ୍ତି, ସେମାନେ ମୋର ଅତ୍ୟନ୍ତ ପ୍ରିୟ । 12.20",

    "ଏହି ପ୍ରକାର ଓଁ ତତ୍ ସତ୍ -- ଏହି ଭଗବତ୍ ନାମର ଉଚ୍ଚାରଣପୂର୍ବକ ବ୍ରହ୍ମବିଦ୍ୟା ଓ ଯୋଗଶାସ୍ତ୍ରମୟ ଶ୍ରୀମଦ୍ଭଗବଦ୍ଗୀତା ଉପନିଷଦରୂପେ ଶ୍ରୀକୃଷ୍ଣ ଏବଂ ଅର୍ଜୁନଙ୍କ ସମ୍ବାଦରେ 'ଭକ୍ତିଯୋଗନାମକ' ଦ୍ବାଦଶ ଅଧ୍ୟାୟ ପୂର୍ଣ୍ଣ ହେଲା"
  ],
  "adhyaya-13" : [
    "ଶ୍ରୀ ଭଗବାନ କହିଲେ -- ହେ କୁନ୍ତୀପୁତ୍ର ଅର୍ଜୁନ ! ଏହି ରୂପରେ ସୂଚିତ କରାଯାଉଥିବା ଶରୀରକୁ 'କ୍ଷେତ୍ର' କୁହାଯାଏ ଏବଂ ଏହି କ୍ଷେତ୍ରକୁ ଯିଏ ଜାଣେ, ତାହାକୁ 'କ୍ଷେତ୍ରଜ୍ଞ' କହନ୍ତି । 13.1",

    "ହେ ଭରତବଂଶଜ ଅର୍ଜୁନ ! ସମସ୍ତ କ୍ଷେତ୍ରରେ ତୁମେ ମୋତେ ହିଁ କ୍ଷେତ୍ରଜ୍ଞ ବୋଲି ଜାଣ, ଏବଂ କ୍ଷେତ୍ର-କ୍ଷେତ୍ରଜ୍ଞ ବିଷୟକ ଜ୍ଞାନ ହିଁ ମୋ ମତରେ ଜ୍ଞାନ ଅଟେ । 13.2",

    "ସେହି କ୍ଷେତ୍ର ଯାହା, ଯେପରି, ଯେଉଁ ବିକାରଯୁକ୍ତ ଓ ଯାହାଠାରୁ ସୃଷ୍ଟି ହୋଇଛି ତଥା ସେହି କ୍ଷେତ୍ରଜ୍ଞ ମଧ୍ୟ ଯିଏ ଏବଂ ଯେଉଁ ପ୍ରଭାବବିଶିଷ୍ଟ, ସେସବୁ ମୋ' ଠାରୁ ସଂକ୍ଷେପରେ ଶୁଣ । 13.3",

    "ଏହି କ୍ଷେତ୍ର-କ୍ଷେତ୍ରଜ୍ଞର ତତ୍ତ୍ବ ଋଷିମାନଙ୍କଦ୍ବାରା ବିସ୍ତୃତଭାବେ କଥିତ ହୋଇଛି, ବେଦର ଋଚାମାନଙ୍କ ଦ୍ବାରା ବିବିଧ ପ୍ରକାରରେ ପୃଥକ୍ ପୃଥକ୍ ବର୍ଣ୍ଣିତ ହୋଇଛି ଏବଂ ଯୁକ୍ତିଯୁକ୍ତ ତଥା ନିର୍ଣ୍ଣୀତ ବ୍ରହ୍ମସୂତ୍ରର ପଦମାନଙ୍କ ଦ୍ବାରା ମଧ୍ୟ ବର୍ଣ୍ଣନା କରାଯାଇଛି । 13.4",

    "ମୂଳ ପ୍ରକୃତି, ସମଷ୍ଟି ବୁଦ୍ଧି (ମହତ୍ତତ୍ତ୍ବ), ସମଷ୍ଟି ଅହଂକାର, ପଞ୍ଚମହାଭୂତ, ଦଶ ଇନ୍ଦ୍ରିୟ, ମନ ତଥା ପାଞ୍ଚ ଇନ୍ଦ୍ରିୟର ପାଞ୍ଚଟି ବିଷୟ - ଏହି ଚବିଶ ତତ୍ତ୍ବରେ କ୍ଷେତ୍ର ଗଠିତ । 13.5",

    "ଇଚ୍ଛା, ଦ୍ବେଷ, ସୁଖ, ଦୁଃଖ, ସଂଘାତ (ଶରୀର), ଚେତନା (ପ୍ରାଣଶକ୍ତି) ଓ ଧୃତି -- ଏସବୁ ବିକାରବିଶିଷ୍ଟ ଉକ୍ତ କ୍ଷେତ୍ର ବିଷୟରେ ସଂକ୍ଷେପରେ କୁହାଗଲା । 13.6",

    "ମାନଶୂନ୍ୟତା, ଦମ୍ଭଶୂନ୍ୟତା, ଅହିଂସା, କ୍ଷମା, ସରଳତା, ଗୁରୁସେବା, ବାହ୍ୟ ଏବଂ ଅଭ୍ୟନ୍ତର ଶୁଦ୍ଧି, ସ୍ଥିରତା ଓ ମନୋନିଗ୍ରହ - 13.7",

    "ଇନ୍ଦ୍ରିୟମାନଙ୍କ ବିଷୟ ପ୍ରତି ବୈରାଗ୍ୟ, ଅହଂକାରଶୂନ୍ୟତା ଏବଂ ଜନ୍ମ, ମୃତ୍ୟୁ, ବୃଦ୍ଧାବସ୍ଥା ଓ ବ୍ୟାଧିରେ ବାରମ୍ବାର ଦୁଃଖରୂପୀ ଦୋଷ ଦର୍ଶନ 13.8",

    "ଅନାସକ୍ତି, ପୁତ୍ର, ସ୍ତ୍ରୀ ଓ ଗୃହ ଆଦି ପ୍ରତି ଏକାତ୍ମଭାବ (ଘନିଷ୍ଠ ସମ୍ବନ୍ଧ)ର ଅଭାବ ଏବଂ ଅନୁକୂଳତା ପ୍ରାପ୍ତିରେ ଚିତ୍ତରେ ନିତ୍ୟ ସମଭାବ ରହିବା 13.9",

    "ଅନନ୍ୟ ଯୋଗ ଦ୍ବାରା ମୋ ପ୍ରତି ଅବ୍ୟଭିଚାରିଣୀ ଭକ୍ତି ଜାତ ହେବା, ଏକାନ୍ତବାସ କରିବାର ସ୍ବଭାବ ହେବା ଏବଂ ଜନସମୁଦାୟରେ ପ୍ରୀତି ନ ହେବା 13.10",

    "ଅଧ୍ୟାତ୍ମଜ୍ଞାନରେ ନିରନ୍ତର ସ୍ଥିତ ହେବା, ତତ୍ତ୍ବଜ୍ଞାନର ଅର୍ଥସ୍ବରୂପ ପରମାତ୍ମାଙ୍କୁ ସର୍ବତ୍ର ଦର୍ଶନ କରିବା - ଏହାକୁ (ପୂର୍ବର କୋଡିଏଟି ସାଧନାର ସମାହାରକୁ) ଜ୍ଞାନ କୁହାଯାଇଛି ଏବଂ ଏହାର ବିପରିତକୁ ଅଜ୍ଞାନ କୁହାଯାଇଛି । 13.11",

    "ଯାହା ଜ୍ଞେୟ (ପୂର୍ବୋକ୍ତ ଜ୍ଞାନ ଦ୍ବାରା ଜ୍ଞାତବ୍ୟ) ଏବଂ ଯାହାକୁ ଜାଣିଗଲେ ମନୁଷ୍ୟ ଅମରତ୍ବ ଅନୁଭବ କରେ, ସେହି ପରମାତ୍ମତତ୍ତ୍ବ ସମ୍ପର୍କରେ ମୁଁ ଭଲଭାବରେ କହିବି । ସେହି ଜ୍ଞେୟ ତତ୍ତ୍ବ ଅନାଦି ଓ ପରମ ବ୍ରହ୍ମ ।ତାହାକୁ ସତ୍ କୁହାଯାଇପାରେ ନାହିଁ କି ଅସତ୍ ମଧ୍ୟ କୁହାଯାଇପାରେ ନାହିଁ । 13.12",

    "ତାଙ୍କର(ପରମାତ୍ମାଙ୍କର ) ସବୁ ସ୍ଥାନରେ ହସ୍ତ, ପଦ, ନେତ୍ର, ଶିର, ମୁଖ ଓ କର୍ଣ୍ଣ ରହିଛି । ସେ ସଂସାରରେ ସମସ୍ତଙ୍କୁ ବ୍ୟାପି ରହିଛନ୍ତି । 13.13",

    "ସେ( ପରମାତ୍ମା ) ସମସ୍ତ ଇନ୍ଦ୍ରିୟବର୍ଜିତ ଏବଂ ସମସ୍ତ ଇନ୍ଦ୍ରିୟବିଷୟର ପ୍ରକାଶକ, ସେ ଆସକ୍ତିରହିତ, ସମଗ୍ର ସଂସାରର ଭରଣପୋଷଣକାରୀ, ଗୁଣରହିତ ଓ ସମସ୍ତ ଗୁଣର ଭୋକ୍ତା ଅଟନ୍ତି । 13.14",

    "ସେହି ପରମାତ୍ମା ସମସ୍ତ ପ୍ରାଣୀଙ୍କର ବାହାରେ ଓ ଭିତରେ ପରିପୂର୍ଣ୍ଣ ଏବଂ ଚରାଚର ପ୍ରାଣୀଙ୍କ ରୂପରେ ମଧ୍ୟ ସେ ହିଁ ବିରାଜମାନ, ସେ ଅତ୍ୟନ୍ତ ଦୂରବର୍ତ୍ତୀ ଓ ଅତି ନିକଟବର୍ତ୍ତୀ ମଧ୍ୟ । ଅତ୍ୟନ୍ତ ସୁକ୍ଷ୍ମ ହୋଇଥିବାରୁ ସେ ଜାଣି ହେବାର ବିଷୟ ନୁହନ୍ତି । 13.15",

    "ସେହି ପରମାାତ୍ମା ସ୍ବୟଂ ଅବିଭକ୍ତ ହୋଇଥିଲେ ମଧ୍ୟ ସବୁ ପ୍ରାଣୀଙ୍କ ଠାରେ ବିଭକ୍ତପରି ସ୍ଥିତ ଏବଂ ସେହି ଜ୍ଞେୟ ପରମାତ୍ମା ହିଁ ସବୁ ପ୍ରାଣୀଙ୍କର ଉତ୍ପାଦକ, ପାଳକ ଓ ସଂହାରକ । 13.16",

    "ସେହି ପରମାତ୍ମା୍ଙ୍କୁ ସମସ୍ତ ଜ୍ୟୋତିର ଜ୍ୟୋତି ଏବଂ ଅଜ୍ଞାନରୁ ଅତ୍ୟନ୍ତ ପର କୁହାଯାଇଛି । ସେ ଜ୍ଞାନସ୍ବରୂପ, ଜାଣିବାର ଯୋଗ୍ୟ, ଜ୍ଞାନ ଦ୍ବାରା ପ୍ରାପ୍ତବ୍ୟ ଓ ସମସ୍ତଙ୍କ ହୃଦୟରେ ବିରାଜିତ । 13.17",

    "ଏହିରୂପେ କ୍ଷେତ୍ର, ଜ୍ଞାନ ଓ ଜ୍ଞେୟ ବିଷୟରେ ସଂକ୍ଷେପରେ କୁହାଗଲା । ମୋର ଭକ୍ତ ଏହାକୁ ତତ୍ତ୍ବସହକାରେ ଜାଣି ମୋର ଭାବକୁ ଅର୍ଥାତ୍ ମୋତେ ହିଁ ପ୍ରାପ୍ତ ହୁଏ । 13.18",

    "ଉଭୟ ପ୍ରକୃତି ଓ ପୁରୁଷକୁ ତୁମେ ଅନାଦି ବୋଲି ଜାଣ ଏବଂ ସମସ୍ତ ବିକାର ଓ ଗୁଣ ମଧ୍ୟ ପ୍ରକୃତିରୁ ଉତ୍ପନ୍ନ ହୁଏ ବୋଲି ଜାଣ । କାର୍ଯ୍ୟ ଓ କରଣ ଦ୍ବାରା ସମ୍ପାଦିତ କ୍ରିୟା ଗୁଡିକୁ ଉତ୍ପନ୍ନ କରିବାରେ ପ୍ରକୃତିକୁ ହେତୁ କୁହାଯାଏ ଏବଂ ସୁଖ-ଦୁଃଖ ଭୋଗିବାରେ ପୁରୁଷକୁ ହେତୁ କୁହାଯାଏ । 13.19-13.20",

    "ଉଭୟ ପ୍ରକୃତି ଓ ପୁରୁଷକୁ ତୁମେ ଅନାଦି ବୋଲି ଜାଣ ଏବଂ ସମସ୍ତ ବିକାର ଓ ଗୁଣ ମଧ୍ୟ ପ୍ରକୃତିରୁ ଉତ୍ପନ୍ନ ହୁଏ ବୋଲି ଜାଣ । କାର୍ଯ୍ୟ ଓ କରଣ ଦ୍ବାରା ସମ୍ପାଦିତ କ୍ରିୟା ଗୁଡିକୁ ଉତ୍ପନ୍ନ କରିବାରେ ପ୍ରକୃତିକୁ ହେତୁ କୁହାଯାଏ ଏବଂ ସୁଖ-ଦୁଃଖ ଭୋଗିବାରେ ପୁରୁଷକୁ ହେତୁ କୁହାଯାଏ । 13.19-13.20",

    "ପ୍ରକୃତିରେ ସ୍ଥିତ ପୁରୁଷ ହିଁ ପ୍ରକୃତି ରୁ ଉତ୍ପନ୍ନ ତ୍ରିଗୁଣାତ୍ମକ ପଦାର୍ଥ ଗୁଡ଼ିକୁ ଭୋଗ କରେ ଏବଂ ଏହିସବୁ ଗୁଣମାନଙ୍କର ସଙ୍ଗ ହିଁ ଏହି ଜୀବାତ୍ମା ର ଭଲ-ମନ୍ଦ ଯୋନିମାନଙ୍କରେ ଜନ୍ମ ନେବାରେ କାରଣ ଅଟେ । 13.21",

    "ଏହି ଦେହରେ ଅବସ୍ଥିତ ହୋଇ ମଧ୍ୟ ଏହି ଆତ୍ମା ପ୍ରକୃତରେ ପରମାତ୍ମା ହିଁ ଅଟେ ।ସେ ହିଁ ସାକ୍ଷୀ ହୋଇଥିବାରୁ ଉପଦ୍ରଷ୍ଟା, ଯଥାର୍ଥ ସମ୍ମତି ଦାତା ହୋଇଥିବାରୁ ଅନୁମନ୍ତା, ସମସ୍ତଙ୍କର ଧାରଣ-ପୋଷଣକାରୀ ହୋଇଥିବାରୁ ଭର୍ତ୍ତା, ଜୀବରୂପୀ ହୋଇଥିବାରୁ ଭୋକ୍ତା, ବ୍ରହ୍ମା ପ୍ରଭୃତିଙ୍କର ସ୍ଵାମୀ ହୋଇଥିବାରୁ ମହେଶ୍ୱର ଏବଂ ଶୁଦ୍ଧ ସଚ୍ଚିଦାନନ୍ଦଘନ ହୋଇଥିବାରୁ ପରମାତ୍ମା ବୋଲି କଥିତ ହୁଏ । 13.22",

    "ଏପରି ଭାବରେ ଯେଉଁ ମନୁଷ୍ୟ ପୁରୁଷକୁ (ସ୍ୱ-ସ୍ୱରୂପକୁ) ଓ ଗୁଣମାନଙ୍କ ସହିତ ପ୍ରକୃତିକୁ ତତ୍ତ୍ୱତଃ ଜାଣନ୍ତି, ସେ ସକଳପ୍ରକାରେ କର୍ତ୍ତବ୍ୟ  କର୍ମ କରୁଥିଲେ ବି ପୁନର୍ଜନ୍ମ ପ୍ରାପ୍ତ ହୁଅନ୍ତି ନାହିଁ । 13.23",

    "କେତେକ ମନୁଷ୍ୟ ସେହି ପରମପୁରୁଷ ପରମାତ୍ମାଙ୍କୁ ଶୁଦ୍ଧ ହୋଇଥିବା ସୂକ୍ଷ୍ମ ବୁଦ୍ଧି ବଳରେ ଧ୍ୟାନ ଦ୍ୱାରା ହୃଦୟ ରେ ଦର୍ଶନ କରନ୍ତି; ଅନ୍ୟ କେତେକ ଜ୍ଞାନଯୋଗ(ସାଙ୍ଖ୍ୟଯୋଗ) ଦ୍ୱାରା ଓ ଆହୁରି କେତେକ କର୍ମଯୋଗ ଦ୍ୱାରା ତାଙ୍କୁ ଦର୍ଶନ କରନ୍ତି ଅର୍ଥାତ୍ ପ୍ରାପ୍ତ ହୁଅନ୍ତି । 13.24",

    "ପରନ୍ତୁ ଅନ୍ୟ ଅଳ୍ପବୁଦ୍ଧି ପୁରୁଷମାନେ ଏପରି ତତ୍ତ୍ୱ ଜାଣୁ ନ ଥିବାରୁ ଅନ୍ୟ ମାନଙ୍କ ଠାରୁ ଅର୍ଥାତ୍ ତତ୍ତ୍ୱଜ୍ଞ ପୁରୁଷମାନଙ୍କ ଠାରୁ ଶୁଣି ହିଁ ତଦନୁସାରେ ଉପାସନା କରନ୍ତି ଏବଂ ସେହି ଶ୍ରବଣ ପରାୟଣ ପୁରୁଷମାନେ ବି ମୃତ୍ୟୁ ରୂପକ ସଂସାର ସାଗରକୁ ନିଃସନ୍ଦେହ ତରିଯା'ନ୍ତି । 13.25",

    "ହେ ଅର୍ଜୁନ ! ଯେତେସବୁ ସ୍ଥାବରଜଙ୍ଗମ ପ୍ରାଣୀ ଉତ୍ପନ୍ନ ହୁଅନ୍ତି, ସେମାନଙ୍କୁ ତୁମେ କ୍ଷେତ୍ର ଓ କ୍ଷେତ୍ରଜ୍ଞର ସଂଯୋଗ ରୁ ହିଁ ଉତ୍ପନ୍ନ ହୁଅନ୍ତି ବୋଲି ଜାଣ । 13.26",

    "ଯେଉଁ ପୁରୁଷ ବିନଷ୍ଟ ହେଉଥିବା ସକଳ ଚରାଚର ଭୂତମାନଙ୍କଠାରେ ପରମେଶ୍ୱରଙ୍କୁ ନାଶ ରହିତ ଏବଂ ସମଭାବରେ ଅବସ୍ଥିତ ଥିବା ଦେଖନ୍ତି, ସେ ହିଁ ଯଥାର୍ଥ ଦେଖନ୍ତି । 13.27",

    "କାରଣ ଯେଉଁ ପୁରୁଷ ସର୍ବତ୍ର ସମଭାବରେ ଅବସ୍ଥିତ, ପରମେଶ୍ୱରଙ୍କୁ ସମାନ ରୂପରେ ଦେଖି ନିଜ ଦ୍ଵାରା ନିଜକୁ ନଷ୍ଟ କରନ୍ତି ନାହିଁ, ସେ ତାହାର ଫଳସ୍ୱରୂପ ପରମଗତି ପ୍ରାପ୍ତ ହୁଅନ୍ତି । 13.28",

    "ଏବଂ ଯେଉଁ ପୁରୁଷ କର୍ମମାନଙ୍କୁ ସବୁପ୍ରକାରେ ପ୍ରକୃତି ଦ୍ୱାରା ହିଁ କରାଯାଉଛି ବୋଲି ଦେଖନ୍ତି ଓ ଆତ୍ମା କୁ ଅକର୍ତ୍ତା ବୋଲି ଦେଖନ୍ତି, ସେ ହିଁ ଯଥାର୍ଥ ଦେଖନ୍ତି । 13.29",

    "ଏହି ପୁରୁଷ ଯେଉଁକ୍ଷଣି ଭୂତମାନଙ୍କର ପୃଥକ୍ ପୃଥକ୍ ଭାବକୁ ଏକ ପରମାତ୍ମାଙ୍କ ଠାରେ ଅବସ୍ଥିତ ଥିବା ଦେଖନ୍ତି ଏବଂ ସେହି ଏକ ପରମାତ୍ମାଙ୍କ ଠାରୁ ହିଁ ସମସ୍ତ ଭୂତଙ୍କର ବିସ୍ତାର ହୋଇଛି ବୋଲି ଦେଖନ୍ତି, ସେ ସେହିକ୍ଷଣି ସଚ୍ଚିଦାନନ୍ଦଘନ ବ୍ରହ୍ମ ଙ୍କୁ ପ୍ରାପ୍ତ ହୋଇଯା'ନ୍ତି । 13.30",

    "ହେ ଅର୍ଜୁନ ! ଅନାଦି ହୋଇଥିବାରୁ ଓ ନିର୍ଗୁଣ ହୋଇଥିବାରୁ ଏହି ଅବିନାଶୀ ପରମାତ୍ମା ଶରୀରରେ ରହି ମଧ୍ୟ ବାସ୍ତବରେ କିଛି କରନ୍ତି ନାହିଁ, କି କୌଣସିଥିରେ ଲିପ୍ତ ହୁଅନ୍ତି ନାହିଁ ।  13.31",

    "ଯେପରି ସର୍ବତ୍ର ବ୍ୟାପ୍ତ ଆକାଶ ସୂକ୍ଷ୍ମ ହୋଇଥିବାରୁ କାହା ଦ୍ଵାରା ଲିପ୍ତ ହୁଏ ନାହିଁ, ସେହିପରି ଦେହରେ ସର୍ବତ୍ର ଅବସ୍ଥିତ ଆତ୍ମା ନିର୍ଗୁଣ ହୋଇଥିବାରୁ ଦେହର ଗୁଣମାନଙ୍କ ଦ୍ୱାରା ଲିପ୍ତ ହୁଏ ନାହିଁ । 13.32",

    "ହେ ଅର୍ଜୁନ ! ଯେପରି ଏକ ହିଁ ସୂର୍ଯ୍ୟ ସମସ୍ତ ବ୍ରହ୍ମାଣ୍ଡ କୁ ଆଲୋକିତ କରିଥାଏ, ସେହିପରି ଏକ ହିଁ ଆତ୍ମା ସମସ୍ତ କ୍ଷେତ୍ରକୁ ଆଲୋକିତ କରେ ।  13.33",

    "ଯେଉଁ ପୁରୁଷମାନେ ଏପରି ଭାବରେ କ୍ଷେତ୍ର ଓ କ୍ଷେତ୍ରଜ୍ଞର ପ୍ରଭେଦ ତଥା କାର୍ଯ୍ୟ ସମେତ ପ୍ରକୃତିରୁ ମୁକ୍ତ ହେବା ପ୍ରଣାଳୀ ଜ୍ଞାନ ନେତ୍ର ଦ୍ୱାରା ତତ୍ତ୍ୱ ତଃ ଜାଣିପାରନ୍ତି, ସେହି ମହାତ୍ମାମାନେ ପରଂବ୍ରହ୍ମ ପରମାତ୍ମାଙ୍କୁ ପ୍ରାପ୍ତ ହୁଅନ୍ତି ।  13.34",

    "ଏହି ପ୍ରକାର ଓଁ ତତ୍ ସତ୍ - ଏହି ଭଗବତ୍ ନାମର ଉଚ୍ଚାରଣପୂର୍ବକ ବ୍ରହ୍ମବିଦ୍ୟା ଓ ଯୋଗଶାସ୍ତ୍ରମୟ ଶ୍ରୀମଦ୍ଭଗବଦ୍ଗୀତା ଉପନିଷଦରୂପେ ଶ୍ରୀକୃଷ୍ଣ ଏବଂ ଅର୍ଜୁନଙ୍କ ସମ୍ବାଦରେ 'କ୍ଷେତ୍ରକ୍ଷେତ୍ରଜ୍ଞବିଭାଗଯୋଗନାମକ' ତ୍ରୟୋଦଶ ଅଧ୍ୟାୟ ପୂର୍ଣ୍ଣ ହେଲା"
  ],
  "adhyaya-14" : [
    "ଶ୍ରୀଭଗବାନ କହିଲେ - ସମସ୍ତ ଜ୍ଞାନ ମଧ୍ୟରେ ଉତ୍ତମ ଓ ଶ୍ରେଷ୍ଠ ଜ୍ଞାନ ପୁନଶ୍ଚ ମୁଁ ତୁମକୁ କହିବି, ଯାହାକୁ ଜାଣି ସମସ୍ତ ମୁନିଗଣ ଏ ସଂସାରରୁ ମୁକ୍ତ ହୋଇ ପରମସିଦ୍ଧି ପ୍ରାପ୍ତ ହୋଇଛନ୍ତି । 14.1",

    "ଏହି ଜ୍ଞାନର ଆଶ୍ରୟ ନେଇ ଯେଉଁ ମନୁଷ୍ୟ ମୋର ସାଧର୍ମ୍ୟ ଲାଭ କରିଛନ୍ତି, ସେମାନେ ମହାସର୍ଗରେ ମଧ୍ୟ ସୃଷ୍ଟି ହୁଅନ୍ତି ନାହିଁ ଏବଂ ମହାପ୍ରଳୟରେ ମଧ୍ୟ ବ୍ୟଥିତ ହୁଅନ୍ତି ନାହିଁ। 14.2",

    "ହେ ଭରତବଂଶଜ ଅର୍ଜୁନ ! ମୋର ମୂଳ ପ୍ରକୃତି ହେଉଛି ଉତ୍ପତ୍ତି ସ୍ଥାନ ଏବଂ ମୁଁ ସେଥିରେ ଜୀବରୂପକ ଗର୍ଭ ସ୍ଥାପନ କରେ। ସେହିଥିରୁ ହିଁ ସମସ୍ତ ପ୍ରାଣୀଙ୍କର ଉତ୍ପତ୍ତି ହୁଏ। 14.3",

    "ହେ କୁନ୍ତୀନନ୍ଦନ ! ସମସ୍ତ ଯୋନିରେ ପ୍ରାଣୀମାନଙ୍କର ଯେତେ ସବୁ ଶରୀର ସୃଷ୍ଟି ହୁଅନ୍ତି, ମୂଳ ପ୍ରକୃତି ହେଉଛି ସେ ସବୁର ମାତା ଏବଂ ମୁଁ ବୀଜ ସ୍ଥାପନକାରୀ ପିତା। 14.4",

    "ହେ ମହାବାହୁ ! ପ୍ରକୃତିରୁ ଉତ୍ପନ୍ନ ହେଉଥିବା ସତ୍ତ୍ବ, ରଜ ଓ ତମ ଗୁଣମାନେ ଅବିନାଶୀ ଦେହୀ (ଜୀବାତ୍ମା) କୁ ଦେହରେ ଆବଦ୍ଧ କରନ୍ତି। 14.5",

    "ହେ ନିଷ୍ପାପ ଅର୍ଜୁନ ! ସେହି ଗୁଣଗୁଡିକ ମଧ୍ୟରୁ ସତ୍ତ୍ବଗୁଣ ନିର୍ମଳ (ସ୍ବଚ୍ଛ) ହୋଇଥିବାରୁ ପ୍ରକାଶକ ଓ ନିର୍ବିକାର ଅଟେ । ତାହା ସୁଖାସକ୍ତି ଓ ଜ୍ଞାନାସକ୍ତି ଦ୍ବାରା ଦେହୀକୁ ଆବଦ୍ଧ କରେ।  14.6",

    "ହେ କୁନ୍ତୀନନ୍ଦନ ! ତୃଷ୍ଣା ଓ ଆସକ୍ତି ଜାତକରୁଥିବା ରଜୋଗୁଣକୁ ତୁମେ ରାଗ (ଆସକ୍ତି) ସ୍ବରୂପ ବୋଲି ଜାଣ । ତାହା କର୍ମାସକ୍ତି ଦ୍ବାରା ଦେହୀ ବା ଜୀବାତ୍ମାକୁ ବନ୍ଧନରେ ପକାଏ।  14.7",

    "ହେ ଭରତବଂଂଶୀ ଅର୍ଜୁନ ! ସମସ୍ତ ଦେହଧାରୀଙ୍କୁ ମୋହିତ କରୁଥିବା ତମୋଗୁଣ ଅଜ୍ଞାନରୁ ଜାତ ହୁଏ ବୋଲି ଜାଣ । ତାହା ପ୍ରମାଦ, ଆଳସ୍ୟ ଓ ନିଦ୍ରା ଦ୍ବାରା (ଦେହ ସହିତ ସମ୍ବନ୍ଧ ମାନୁଥିବା ମନୁଷ୍ୟଙ୍କୁ) ବନ୍ଧନରେ ପକାଏ ।  14.8",

    "ହେ ଭରତବଂଶୋଦ୍ଭବ ଅର୍ଜୁନ ! ସତ୍ତ୍ବଗୁଣ ସୁଖରେ ଓ ରଜୋଗୁଣ କର୍ମରେ ଲିପ୍ତ କରି ମନୁଷ୍ୟ ଉପରେ ବିଜୟୀ ହୁଏ । ମାତ୍ର ତମୋଗୁଣ ଜ୍ଞାନକୁ ଆଚ୍ଛାଦିତ କରି ଓ ପ୍ରମାଦରେ ଲିପ୍ତ କରାଇ ମନୁଷ୍ୟ ଉପରେ ବିଜୟୀ ହୁଏ।  14.9",

    "ହେ ଭରତବଂଶଜ ଅର୍ଜୁନ ! ରଜୋଗୁଣ ଓ ତମୋଗୁଣକୁ ପରାସ୍ତ କରି ସତ୍ତ୍ବଗୁଣ ବୃଦ୍ଧି ହୁଏ, ସତ୍ତ୍ବଗୁଣ ଓ ତମୋଗୁଣକୁ ପରାସ୍ତ କରି ରଜୋଗୁଣ ବୃଦ୍ଧି ହୁଏ ଏବଂ ସତ୍ତ୍ବଗୁଣ ଓ ରଜୋଗୁଣକୁ ପରାସ୍ତ କରି ତମୋଗୁଣ ବୃଦ୍ଧି ହୁଏ ।  14.10",

    "ଏହି ମାନବଦେହର ସମସ୍ତ ଦ୍ବାରରେ (ଇନ୍ଦ୍ରିୟ ଓ ଅନ୍ତଃକରଣରେ) ଯେତେବେଳେ ପ୍ରକାଶ (ସ୍ବଚ୍ଛତା) ଓ ବିବେକ ଉଦୟ ହୁଏ , ସେତେବେଳେ ସତ୍ତ୍ବଗୁଣ ବୃଦ୍ଧିପ୍ରାପ୍ତ ହୋଇଛି ବୋଲି ଜାଣିବା ଉଚିତ।  14.11",

    "ହେ ଭରତବଂଶଜ ଅର୍ଜୁନ ! ରଜୋଗୁଣ ବୃଦ୍ଧି ହେଲେ ଲୋଭ, ପ୍ରବୃତ୍ତି, କର୍ମାରମ୍ଭ, ଅଶାନ୍ତି, ସ୍ପୃହା ପ୍ରଭୃତି ବୃତ୍ତି ଜାତ ହୁଅନ୍ତି।  14.12",

    "ହେ କୁରୁନନ୍ଦନ ! ତମୋଗୁଣ ବଢିଗଲେ ଅପ୍ରକାଶ, ଅପ୍ରବୃତ୍ତି, ପ୍ରମାଦ ଓ ମୋହ -- ଏହି ବୃତ୍ତିଗୁଡିକ ଜାତ ହୁଅନ୍ତି।  14.13",

    "ସତ୍ତ୍ବଗୁଣ ବୃଦ୍ଧିପ୍ରାପ୍ତ ହୋଇଥିବା ସମୟରେ ଯଦି ଦେହଧାରୀ ମନୁଷ୍ୟ ମରିଯାଏ, ତେବେ ସେ ଉତ୍ତମବେତ୍ତାମାନଙ୍କର ପ୍ରାପ୍ତବ୍ୟ ସ୍ବର୍ଗାଦି ନିର୍ମଳ ଲୋକଗୁଡିକୁ ପ୍ରାପ୍ତ ହୁଏ।  14.14",

    "ରଜୋଗୁଣ ବୃଦ୍ଧି ପାଇଥିବା ବେଳେ ମରୁଥିବା ପ୍ରାଣୀ କର୍ମସଙ୍ଗା ମନୁଷ୍ୟ ଯୋନିରେ ଜନ୍ମ ନିଏ ଏବଂ ତମୋଗୁଣ ବୃଦ୍ଧି ପାଇଥିବା ବେଳେ ମରୁଥିବା ପ୍ରାଣୀ ମୂଢଯୋନିମାନଙ୍କରେ ଜନ୍ମ ନିଏ।  14.15",

    "ଶୁଭକର୍ମର ଫଳ ସାତ୍ତ୍ବିକ ଓ ନିର୍ମଳ ତଥା ରାଜସ କର୍ମର ଫଳ ଦୁଃଖ ଏବଂ ତାମସ କର୍ମର ଫଳ ଅଜ୍ଞାନ (ମୂଢତା) ବୋଲି ବିବେକୀ ପୁରୁଷମାନେ କହିଛନ୍ତି। 14.16",

    "ସତ୍ତ୍ବଗୁଣରୁ ଜ୍ଞାନ ଓ ରଜୋଗୁଣରୁ ଲୋଭ (ଇତ୍ୟାଦି) ଉତ୍ପନ୍ନ ହୁଏ। ତମୋଗୁଣରୁ ପ୍ରମାଦ, ମୋହ ଓ ଅଜ୍ଞାନ ଉତ୍ପନ୍ନ ହୁଏ। 14.17",

    "ସତ୍ତ୍ବଗୁଣରେ ସ୍ଥିତ ମନୁଷ୍ୟ ଉର୍ଦ୍ଧ୍ବଲୋକକୁ ଯାଆନ୍ତି, ରଜୋଗୁଣରେ ସ୍ଥିତ ମନୁଷ୍ୟ ମର୍ତ୍ତ୍ୟଲୋକରେ ଜନ୍ମ ନିଅନ୍ତି ଏବଂ ନିନ୍ଦନୀୟ ତମୋଗୁଣଜନିତ ବୃତ୍ତିରେ ସ୍ଥିତ ତାମସ ମନୁଷ୍ୟ ଅଧୋଗତି ପ୍ରାପ୍ତ ହୁଅନ୍ତି। 14.18",

    "ବିବେକୀ (ଜ୍ଞାନବାନ୍) ମନୁଷ୍ୟ ଯେତେବେଳେ ତିନିଗୁଣ ବ୍ୟତୀତ ଅନ୍ୟ କାହାକୁ କର୍ତ୍ତା ମନେ କରେ ନାହିଁ ଏବଂ ନିଜକୁ ଗୁଣଗୁଡିକରୁ ଅତୀତ ଅନୁଭବ କରେ, ସେତେବେଳେ ସେ ମୋର ସତ୍ ସ୍ବରୂପକୁ ପ୍ରାପ୍ତ ହୁଏ। 14.19",

    "ଦେହଧାରୀ (ବିବେକୀ ମନୁଷ୍ୟ) ଦେହର ଉତ୍ପନ୍ନକାରୀ ତିନିଗୁଣକୁ ଅତିକ୍ରମ କରି ଜନ୍ମ, ମୃତ୍ୟୁ ଓ ଜରାରୂପକ ଦୁଃଖରୁ ମୁକ୍ତ ହୋଇ ଅମରତ୍ବ ଅନୁଭବ କରେ। 14.20",

    "ଅର୍ଜୁନ କହିଲେ - ହେ ପ୍ରଭୂ ! ଏହି ତିନିଗୁଣରୁ ଅତୀତ ମନୁଷ୍ୟଙ୍କର ଲକ୍ଷଣ କ'ଣ? ତାଙ୍କର ଆଚରଣ କିପରି? ଏବଂ ଏହି ତିନିଗୁଣକୁ କିପରି ଅତିକ୍ରମ କରାଯାଇ ପାରେ ? 14.21",

    "ଶ୍ରୀୀଭଗବାନ କହିଲେ - ହେ ପାଣ୍ଡବ! ପ୍ରକାଶ, ପ୍ରବୃତ୍ତି ଓ ମୋହ ପୂର୍ଣ୍ଣରୂପେ ପ୍ରବୃତ୍ତ ହେଲେ ମଧ୍ୟ ଗୁଣାତୀତ ମନୁଷ୍ୟ ସେଗୁଡିକ ପ୍ରତି ଦ୍ବେଷ କରେ ନାହିଁ ଏବଂ ନିବୃତ୍ତ ହୋଇଗଲେ ମଧ୍ୟ ସେଗୁଡିକୁ ଇଚ୍ଛା କରେ ନାହିଁ। 14.22",

    "ଯେ ଉଦାସୀନଭଳି ସ୍ଥିତ ଏବଂ ଯାହାକୁ ଗୁଣ ଦ୍ବାରା ବିଚଳିତ କରାଯାଇ ପାରେନାହିଁ ତଥା ଗୁଣଗୁଡିକ ହିଁ ଗୁଣରେ ପ୍ରବୃତ୍ତ ହେଉଛନ୍ତି ବୋଲି ଭାବି ଯେ ନିଜ ସ୍ବରୂପରେ ଅବସ୍ଥାନ କରେ ଏବଂ ନିଜେ କୌଣସି ଚେଷ୍ଟା କରେ ନାହିଁ  14.23",

    "ଯେଉଁ ଧୀର ମନୁଷ୍ୟ ସୁଖ-ଦୁଃଖରେ ସମଭାବାପନ୍ନ ତଥା ନିଜ ସ୍ବରୂପରେ ଅବସ୍ଥିତ, ଯେ ଟେକା, ପଥର ଓ ସୁବର୍ଣ୍ଣ ପ୍ରତି ସମଭାବ ରଖେ, ପ୍ରିୟ-ଅପ୍ରିୟ ଓ ନିଜର ନିନ୍ଦା-ସ୍ତୁତିକୁ ଯେ ସମ ଜ୍ଞାନ କରେ, ଯେ ମାନ-ଅପମାନ ତଥା ମିତ୍ର ଓ ଶତ୍ରୁଙ୍କ ପକ୍ଷରେ ସମ ରହେ ଏବଂ ଯେ ସମସ୍ତ କର୍ମାରମ୍ଭ ପରିତ୍ୟାଗୀ ଅଟେ, ସେହି ମନୁଷ୍ୟକୁ ଗୁଣାତୀତ କୁହାଯାଏ। 14.24-14.25",

    "ଯେଉଁ ଧୀର ମନୁଷ୍ୟ ସୁଖ-ଦୁଃଖରେ ସମଭାବାପନ୍ନ ତଥା ନିଜ ସ୍ବରୂପରେ ଅବସ୍ଥିତ, ଯେ ଟେକା, ପଥର ଓ ସୁବର୍ଣ୍ଣ ପ୍ରତି ସମଭାବ ରଖେ, ପ୍ରିୟ-ଅପ୍ରିୟ ଓ ନିଜର ନିନ୍ଦା-ସ୍ତୁତିକୁ ଯେ ସମ ଜ୍ଞାନ କରେ, ଯେ ମାନ-ଅପମାନ ତଥା ମିତ୍ର ଓ ଶତ୍ରୁଙ୍କ ପକ୍ଷରେ ସମ ରହେ ଏବଂ ଯେ ସମସ୍ତ କର୍ମାରମ୍ଭ ପରିତ୍ୟାଗୀ ଅଟେ, ସେହି ମନୁଷ୍ୟକୁ ଗୁଣାତୀତ କୁହାଯାଏ। 14.24-14.25",

    "ଯେଉଁ ମନୁଷ୍ୟ ଅବ୍ୟଭିଚାରୀ ଭକ୍ତିଯୋଗ ଦ୍ବାରା ମୋର ସେବା କରେ, ସେ ଏହି ଗୁଣଗୁଡିକୁ ଅତିକ୍ରମ କରି ବ୍ରହ୍ମପ୍ରାପ୍ତିର ପାତ୍ର ହୋଇଯାଏ। 14.26",

    "କାରଣ ମୁଁ ହିଁ ବ୍ରହ୍ମର, ଅବିନାଶୀ ଅମୃତର ତଥା ଶାଶ୍ବତ ଧର୍ମ ଓ ଐକାନ୍ତିକ ସୁଖର ଆଶ୍ରୟ। 14.27",

    "ଏହି ପ୍ରକାର ଓଁ ତତ୍ ସତ୍ - ଏହି ଭଗବତ୍ ନାମର ଉଚ୍ଚାରଣପୂର୍ବକ ବ୍ରହ୍ମବିଦ୍ୟା ଓ ଯୋଗଶାସ୍ତ୍ରମୟ ଶ୍ରୀମଦ୍ଭଗବଦ୍ଗୀତା ଉପନିଷଦରୂପେ ଶ୍ରୀକୃଷ୍ଣ ଏବଂ ଅର୍ଜୁନଙ୍କ ସମ୍ବାଦରେ 'ଗୁଣତ୍ରୟବିଭାଗଯୋଗନାମକ' ଚତୁର୍ଦ୍ଦଶ ଅଧ୍ୟାୟ ପୂର୍ଣ୍ଣ ହେଲା"
  ],
  "adhyaya-15" : [
    "ଶ୍ରୀ ଭଗବାନ କହିଲେ - ଉପରକୁ ମୂଳ ଓ ତଳକୁ ଶାଖା ବ୍ୟାପିଥିବା ଯେଉଁ ସଂସାରରୂପକ ଅଶ୍ବତ୍ଥ ବୃକ୍ଷକୁ (ପ୍ରବାହରୂପେ) ଅବ୍ୟୟ କହନ୍ତି ଓ ବେଦମାନେ ଯାହାର ପତ୍ର, ସେହି ସଂସାର-ବୃକ୍ଷକୁ ଯେ ଜାଣେ, ସେ ସମସ୍ତ ବେଦର ଜ୍ଞାତା ।  15.1",

    "ସେହି ସଂସାର-ବୃକ୍ଷର ନିମ୍ନଭାଗ, ମଧ୍ୟଭାଗ ଓ ଉପରିଭାଗ ସର୍ବତ୍ର, ତ୍ରିଗୁଣ ଦ୍ବାରା ବୃଦ୍ଧିପ୍ରାପ୍ତ ତଥା ବିଷୟରୂପୀ କୋମଳ ପତ୍ରଯୁକ୍ତ ଶାଖାମାନ ବ୍ୟାପି ରହିଛି । ମର୍ତ୍ତ୍ୟଲୋକରେ କର୍ମାନୁସାରେ ବନ୍ଧନରେ ପକାଉଥିବା ମୂଳଗୁଡିକ ମଧ୍ୟ ଅଧଃଲୋକ ଓ ଉର୍ଦ୍ଧ୍ବଲୋକ ସର୍ବତ୍ର ପରିବ୍ୟାପ୍ତ ହୋଇଛି । 15.2",

    "ଏହି ସଂସାର-ବୃକ୍ଷର ରୂପ ଯେପରି ଦୃଶ୍ୟ ହୁଏ, ବିଚାର କଲେ ସେପରି ଉପଲବ୍ଧ ହୁଏ ନାହିଁ, କାରଣ ଏହାର ଆଦି ନାହିଁ , ଅନ୍ତ ନାହିଁ କି ସ୍ଥିତି ମଧ୍ୟ ନାହିଁ । ଏଣୁ ସୁଦୃଢ ମୂଳବିଶିଷ୍ଟ ଏହି ସଂସାରରୂପୀ ଅଶ୍ବତ୍ଥବୃକ୍ଷକୁ ଦୃଢ ଅସଙ୍ଗତାରୂପୀ ଶସ୍ତ୍ର ଦ୍ବାରା ଛେଦନ କରି। 15.3",

    "ତା ପରେ ସେହି ପରମପଦ ପରମାତ୍ମାଙ୍କୁ ଅନ୍ବେଷଣ କରିବା ଉଚିତ । ଯାହାଙ୍କୁ ପାଇବା ପରେ ମନୁଷ୍ୟମାନେ ପୁନର୍ବାର ସଂସାରକୁ ଫେରି ଆସନ୍ତି ନାହିଁ ଏବଂ ଯାହାଙ୍କଠାରୁ ଅନାଦିକାଳରୁ ପ୍ରଚଳିତ ସୃଷ୍ଟି ବିସ୍ତାର ଲାଭ କରିଛି, ସେହି ଆଦିପୁରୁଷ ପରମାତ୍ମାଙ୍କ ଠାରେ ହିଁ ମୁଁ ଶରଣ ପଶୁଛି ।  15.4",

    "ଯେଉଁମାନେ (ନିଜ ଦୃଷ୍ଟିରୁ ) ଅଭିମାନଶୂନ୍ୟ ଓ ମୋହରହିତ, ଆସକ୍ତିଜନିତ ଦୋଷ ଉପରେ ବିଜୟୀ, ପରମାତ୍ମାଙ୍କ ସହିତ ନିତ୍ୟନିରନ୍ତର ସଂଯୁକ୍ତ, ଯେଉଁମାନଙ୍କ ଠାରେ କୌଣସି କାମନା ନାହିଁ, ଯେଉଁମାନେ ସୁଖ ଦୁଃଖରୂପକ ଦ୍ବନ୍ଦ୍ବରୁ ମୁକ୍ତ, ସେପରି (ଉଚ୍ଚ ସ୍ତରର) ମୋହରହିତ ସାଧକଭକ୍ତ ସେହି ଅବିନାଶୀ ପରମପଦ ( ପରମାତ୍ମାଙ୍କୁ ) ପ୍ରାପ୍ତ ହୁଅନ୍ତି ।  15.5",

    "ସେହି ପରମପଦକୁ ସୂର୍ଯ୍ୟ, ଚନ୍ଦ୍ର, ଅଗ୍ନି କେହିହେଲେ ପ୍ରକାଶିତ କରି ପାରନ୍ତି ନାହିଁ ଏବଂ ଯାହାକୁ ପ୍ରାପ୍ତ ହୋଇ ଜୀବମାନେ ପୁନର୍ବାର ସଂସାରକୁ ଲେଉଟି ଆସନ୍ତି ନାହିଁ, ତାହା ମୋର ପରମଧାମ ।  15.6",

    "ଏହି ସଂସାରରେ ଜୀବରୂପ ଧାରଣ କରିଥିବା ଆତ୍ମା କେବଳ ମୋର ହିଁ ସନାତନ ଅଂଶ, ମାତ୍ର ସେ ପ୍ରକୃତିସ୍ଥ ମନ ଓ ପଞ୍ଚେନ୍ଦ୍ରିୟଙ୍କୁ ଆକର୍ଷିତ କରୁଛି (ନିଜର ମନେ କରୁଛି ) ।  15.7",

    "ବାୟୁ କୌଣସି ଗନ୍ଧ ସ୍ଥାନରୁ ଗନ୍ଧ ବହନ କରି ନେଇଯିବା ପରି ଶରୀରାଦିର ସ୍ବାମୀ ବୋଲାଉଥିବା ଜୀବାତ୍ମା ମଧ୍ୟ ଯେଉଁ ଶରୀର ତ୍ୟାଗ କରେ, ସେଠାରୁ ମନସମେତ ଇନ୍ଦ୍ରିୟମାନଙ୍କୁ ସାଥିରେ ଧରି ଯେଉଁ ଶରୀର ପ୍ରାପ୍ତ ହୁଏ ସେଠାକୁ ଚାଲିଯାଏ । 15.8",

    "ଏହି ଜୀବାତ୍ମା ମନର ସହାୟତାରେ ହିଁ କର୍ଣ୍ଣ, ଚକ୍ଷୁ, ତ୍ବକ୍, ଜିହ୍ବା ଓ ନାସିକା ଦ୍ବାରା ବିଷୟଗୁଡିକୁସେବନ କରେ । 15.9",

    "ଗୁଣଯୁକ୍ତ ଜୀବାତ୍ମା ଶରୀର ଛାଡି ଯାଉଥିଲେ ମଧ୍ୟ, ଅନ୍ୟ ଶରୀରରେ ସ୍ଥିତ ହେଉଥିଲେ ମଧ୍ୟ ଅଥବା ବିଷୟ ଉପଭୋଗ କରୁଥିଲେ ମଧ୍ୟ ତାହାର ବାସ୍ତବ ସ୍ବରୂପକୁ ମୂଢ ଲୋକେ ଜାଣନ୍ତି ନାହିଁ, ଜ୍ଞାନଚକ୍ଷୁସମ୍ପନ୍ନ ଜ୍ଞାନୀ ମନୁଷ୍ୟ ହିଁ ଜାଣନ୍ତି । 15.10",

    "ପ୍ରଯତ୍ନଶୀଳ ଯୋଗୀମାନେ ନିଜ ମଧ୍ୟରେ ଅବସ୍ଥିତ ଏହି ପରମାତ୍ମତତ୍ତ୍ବକୁ ଅନୁଭବ କରନ୍ତି । କିନ୍ତୁ ଅଶୁଦ୍ଧ ଅନ୍ତଃକରଣଯୁକ୍ତ ଅବିବେକୀ ( ଅଜ୍ଞାନୀ ) ମନୁଷ୍ୟ ଯେତେ ଯତ୍ନ କଲେ ମଧ୍ୟ ଏହି ତତ୍ତ୍ବକୁ ଅନୁଭବ କରି ପାରନ୍ତି ନାହିଁ । 15.11",

    "ସୂର୍ଯ୍ୟଙ୍କୁ ପ୍ରାପ୍ତ ଯେଉଁ ତେଜ ସମଗ୍ର ଜଗତକୁ ଆଲୋକିତ କରୁଛି ଏବଂ ଚନ୍ଦ୍ର ତଥା ଅଗ୍ନିରେ ଯେଉଁ ତେଜ ରହିଛି, ତାହା ମୋର ତେଜ ବୋଲି ଜାଣ । 15.12",

    "ମୁଁ ନିଜେ ହିଁ ପୃଥିବୀରେ ପ୍ରବିଷ୍ଟ ହୋଇ ମୋର ଶକ୍ତି ଦ୍ବାରା ସମସ୍ତ ପ୍ରାଣୀଙ୍କୁ ଧାରଣ କରୁଛି ଏବଂ ମୁଁ ହିଁ ରସମୟ ଚନ୍ଦ୍ରରୂପରେ ସମସ୍ତ ଔଷଧିକୁ (ବନସ୍ପତିକୁ) ପରିପୁଷ୍ଟ କରୁଛି । 15.13",

    "ସକଳ ପ୍ରାଣୀଙ୍କ ଶରୀରରେ ରହୁଥିବା ମୁଁ ପ୍ରାଣ ଓ ଅପାନ ଦ୍ବାରା ସଂଯୁକ୍ତ ବୈଶ୍ବାନର ଅଗ୍ନିରୂପ ହୋଇ ଚାରିପ୍ରକାର ଅନ୍ନ ଜୀର୍ଣ୍ଣ କରୁଛି । 15.14",

    "ମୁଁ ପ୍ରତ୍ୟେକ ପ୍ରାଣୀଙ୍କର ହୃଦୟରେ ବିରାଜମାନ । ମୋ ଠାରୁ ହିଁ ସ୍ଥିତି, ଜ୍ଞାନ ଓ ଅପୋହନ (ସଂଶୟାଦି ଦୋଷ ନାଶ କାର୍ଯ୍ୟ) ହୁଏ । ମୁଁ ସମସ୍ତ ବେଦର ଜ୍ଞେୟ ତତ୍ତ୍ବ । ସମସ୍ତ ବେଦର ତତ୍ତ୍ବ ନିର୍ଣ୍ଣାୟକ ଏବଂ ବେଦର ଜ୍ଞାତା ମଧ୍ୟ ମୁଁ ।  15.15",

    "ସଂସାରରେ ଦୁଇ ପ୍ରକାର ପୁରୁଷ ଅଛନ୍ତି - କ୍ଷର ( ବିନାଶୀ ) ଏବଂ ଅକ୍ଷର( ଅବିନାଶୀ ) । ସମସ୍ତ ପ୍ରାଣୀମାନଙ୍କ ଶରୀର କ୍ଷର ଏବଂ ଜୀବାତ୍ମା ଅକ୍ଷର ବୋଲାଏ। 15.16",

    "ଉତ୍ତମ ପୁରୁଷ କିନ୍ତୁ ଅନ୍ୟ ଜଣେ (ବିଲକ୍ଷଣ ପୁରୁଷ), ଯାହାକୁ ପରମାତ୍ମା କୁହାଯାଇଛି । ସେହି ଅବିନାଶୀ ଈଶ୍ବର ତ୍ରିଲୋକରେ ପ୍ରବିଷ୍ଟ ହୋଇ ସମସ୍ତଙ୍କୁ ଭରଣପୋଷଣ କରନ୍ତି । 15.17",

    "ଯେହେତୁ ମୁଁ କ୍ଷରରୁ ଅତୀତ ଓ ଅକ୍ଷରରୁ ମଧ୍ଯ ଉତ୍ତମ , ତେଣୁ ଜଗତରେ ଓ ବେଦରେ ମୁଁ ପୁରୁଷୋତ୍ତମ ନାମରେ ପ୍ରସିଦ୍ଧ । 15.18",

    "ହେ ଭରତବଂଶଜ ଅର୍ଜୁନ ! ଏପରି ଭାବରେ ଯେଉଁ ମୋହରହିତ ମନୁଷ୍ଯ ମୋତେ ପୁରୁଷୋତ୍ତମ ବୋଲି ଜାଣେ , ସେହି ସର୍ବଜ୍ଞ ସର୍ବଭାବରେ ମୋତେ ଭଜନ କରେ । 15.19",

    "ହେ ନିଷ୍ପାପ ଅର୍ଜୁନ ! ଏହିରୂପେ ମୁଁ ତୁମକୁ ଏହି ଅତ୍ୟନ୍ତ ଗୋପନୀୟ ଶାସ୍ତ୍ର କହିଲି । ହେ ଭରତବଂଶଜ ଅର୍ଜୁନ ! ଏହା ଜାଣିଗଲେ ମନୁଷ୍ୟ ଜ୍ଞାନବାନ୍ (ଜ୍ଞାତଜ୍ଞାତବ୍ୟ) ତଥା କୃତକୃତ୍ୟ ହୋଇଯାଏ ।   15.20",

    "ଏହି ପ୍ରକାର ଓଁ ତତ୍ ସତ୍ -- ଏହି ଭଗବତ୍ ନାମର ଉଚ୍ଚାରଣପୂର୍ବକ ବ୍ରହ୍ମବିଦ୍ୟା ଓ ଯୋଗଶାସ୍ତ୍ରମୟ ଶ୍ରୀମଦ୍ଭଗବଦ୍ଗୀତା ଉପନିଷଦରୂପେ ଶ୍ରୀକୃଷ୍ଣ ଏବଂ ଅର୍ଜୁନଙ୍କ ସମ୍ବାଦରେ 'ପୁରୁଷୋତ୍ତମଯୋଗନାମକ' ପଞ୍ଚଦଶ ଅଧ୍ୟାୟ ପୂର୍ଣ୍ଣ ହେଲା"
  ],
  "adhyaya-16" : [
    "ଶ୍ରୀ ଭଗବାନ କହିଲେ - ଅଭୟ , ଅନ୍ତଃକରଣର ସମ୍ୟକ୍ ଶୁଦ୍ଧି , ଜ୍ଞାନ ନିମନ୍ତେ ଯୋଗରେ ଦୃଢ ସ୍ଥିତି , ସାତ୍ତ୍ୱିକ ଦାନ, ଇନ୍ଦ୍ରିୟ ଦମନ , ଯଜ୍ଞ , ସ୍ବାଧ୍ୟାୟ, କର୍ତ୍ତବ୍ୟ ପାଳନ ନିମନ୍ତେ କଷ୍ଟସହିଷ୍ଣୁତା, କାୟମନୋବାକ୍ୟରେ ସରଳତା 16.1",

    "ଅହିଂସା, ସତ୍ୟଭାଷଣ, କ୍ରୋଧଶୂନ୍ୟତା, ସାଂସାରିକ କାମନା ତ୍ୟାଗ, ଅନ୍ତଃକରଣରେ ରାଗ-ଦ୍ବେଷଜନିତ ଅସ୍ଥିରତା ନ ହେବା, ଚୁଗୁଲି ନ କରିବା, ପ୍ରାଣିମାନଙ୍କ ପ୍ରତି ଦୟା କରିବା, ସାଂସାରିକ ବିଷୟରେ ଆସକ୍ତ ନ ହେବା, ଅନ୍ତଃକରଣର କୋମଳତା, ଅକର୍ତ୍ତବ୍ୟ କରିବାରେ ଲଜ୍ଜାବୋଧ, ଅଚପଳତା  16.2",

    "ତେଜ(ପ୍ରଭାବ), କ୍ଷମା, ଧୈର୍ଯ୍ୟ, ଶାରୀରିକ ଶୁଦ୍ଧି, ନିର୍ବୈରଭାବ ଏବଂ ସମ୍ମାନ ନ ଚାହିଁବା, ହେ ଭରତବଂଶଜ ଅର୍ଜୁନ ! ଏସବୁ ଦୈବୀସମ୍ପଦପ୍ରାପ୍ତ ମନୁଷ୍ୟଙ୍କର ଲକ୍ଷଣ ଅଟେ। 16.3",

    "ହେ ପୃଥାନନ୍ଦନ ! ଦମ୍ଭ, ଦର୍ପ, ଅଭିମାନ, କ୍ରୋଧ, କଠୋରତା, ଅବିବେକତା - ଏସବୁ ଆସୁରୀସମ୍ପଦପ୍ରାପ୍ତ ମନୁଷ୍ୟଙ୍କର ଲକ୍ଷଣ। 16.4",

    "ଦୈବୀସମ୍ପତ୍ତି ମୁକ୍ତି ନିମନ୍ତେ ଏବଂ ଆସୁରୀସମ୍ପତ୍ତି ବନ୍ଧନ ନିମନ୍ତେ ଉଦ୍ଦିଷ୍ଟ ବୋଲି କୁହାଯାଇଛି । ହେ ପାଣ୍ଡବ ! ତୁମେ ଦୈବୀସମ୍ପତ୍ତି ପ୍ରାପ୍ତ ହୋଇଛ, ତେଣୁ ଶୋକ( ଚିନ୍ତା ) କର ନାହିଁ। 16.5",

    "ଇହଲୋକରେ ଦୁଇ ପ୍ରକାର ପ୍ରାଣୀ ସୃଷ୍ଟି ଅଛି - ଦୈବୀ ଏବଂ ଆସୁରୀ । ଦୈବୀ ସୃଷ୍ଟିର ପ୍ରାଣୀଙ୍କ ବିଷୟରେ ମୁଁ ସବିସ୍ତାର ବର୍ଣ୍ଣନା କରିସାରିଛି, ହେ ପାର୍ଥ ! ଏବେ ତୁମେ ମୋ ଠାରୁ ଆସୁରୀ ସୃଷ୍ଟିର ପ୍ରାଣୀଙ୍କ ବିଷୟରେ ବିସ୍ତାର ପୂର୍ବକ ଶୁଣ। 16.6",

    "ଆସୁରୀ ପ୍ରକୃତିର ମନୁଷ୍ୟ ପ୍ରବୃତ୍ତି ଓ ନିବୃତ୍ତିକୁ ଜାଣନ୍ତି ନାହିଁ ଏବଂ ସେମାନଙ୍କଠାରେ ବାହ୍ୟ ଶୁଦ୍ଧି ନ ଥାଏ , ଶ୍ରେଷ୍ଠ ଆଚରଣ ନ ଥାଏ ଓ ସତ୍ୟ ପାଳନ ମଧ୍ୟନ ଥାଏ। 16.7",

    "ସେମାନେ କହନ୍ତି ଯେ ସଂସାର ଅସତ୍ୟ ତଥା ବିନା ମର୍ଯ୍ୟଦା ଓ ବିନା ଈଶ୍ବରରେ ଆପେ ଆପେ କେବଳ ସ୍ତ୍ରୀ-ପୁରୁଷଙ୍କ ସଂଯୋଗରୁ ସୃଷ୍ଟି ହୋଇଛି । ଏଣୁ କାମ ହିଁ ଏହାର ଏକମାତ୍ର କାରଣ, ଏହା ବ୍ୟତୀତ ଆଉ କି କାରଣ ଅଛି ? ଅର୍ଥାତ୍ ଅନ୍ୟ କିଛି ଏହାର କାରଣ ହୋଇ ନ ପାରେ। 16.8",

    "ଏହି(ପୂର୍ବୋକ୍ତ) ନାସ୍ତିକ ଭାବର ଆଶ୍ରୟ ନେଇ ଯେଉଁ ମନୁଷ୍ୟ ନିଜର ନିତ୍ୟ ସ୍ବରୂପକୁ ମାନନ୍ତି ନାହିଁ, ଯେଉଁମାନଙ୍କର ବୁଦ୍ଧି ତୁଚ୍ଛ, ଯେଉଁମାନେ ଉଗ୍ରକର୍ମରତ ଓ ସଂସାରର ଶତ୍ରୁ, ସେମାନଙ୍କର ସାମର୍ଥ୍ୟ କେବଳ ଜଗତର ବିନାଶ ପାଇଁ ଉପଯୋଗ ହୋଇଥାଏ। 16.9",

    "ଦୁଷ୍ପୂରଣୀୟ କାମନାଗୁଡିକର ଆଶ୍ରୟ ନେଇ ଦମ୍ଭ, ଅଭିମାନ ଓ ମଦରେ ପ୍ରମତ୍ତ ଏବଂ ଅପବିତ୍ର ନିୟମଧାରୀ ମନୁଷ୍ୟ ମୋହ ହେତୁ ଅନେକ ଦୁରାଗ୍ରହ ଧରି ରଖି ସଂସାରରେ ବିଚରଣ କରୁଥାନ୍ତି। 16.10",

    "ସେମାନେ ମୃତ୍ୟୁ ପର୍ଯ୍ୟନ୍ତ ଅପରିମେୟ ଚିନ୍ତାକୁ ଆଶ୍ରୟ କରିଥାନ୍ତି, ପଦାର୍ଥ ସଂଗ୍ରହ ଓ ଉପଭୋଗରେ ବ୍ୟସ୍ତ ଥାଆନ୍ତି ଏବଂ ' ଏହି କାମୋପଭୋଗ ହିଁ ସବୁକିଛି' ---- ଏପରି ଅଟଳ ନିର୍ଣ୍ଣୟ କରିଥାନ୍ତି। 16.11",

    "ଶତ ଶତ ଆଶାଫାଶରେ ଆବଦ୍ଧ ସେହି ମନୁଷ୍ୟ କାମ-କ୍ରୋଧପରାୟଣ ହୋଇ ବିଷୟ ଉପଭୋଗ କରିବା ଉଦ୍ଦେଶ୍ୟରେ ଅନ୍ୟାୟ ଉପାୟରେ ଧନ ସଞ୍ଚୟ କରିବାକୁ ଉଦ୍ୟମ କରୁଥାନ୍ତି। 16.12",

    "ସେମାନେ ମନୋରଥ କରୁଥାନ୍ତି ଯେ -- ଆଜି ଏତିକି ବସ୍ତୁ ମୁଁ ପାଇଗଲି, ଏଥର ମୁଁ ଅମୁକ ମନବାଞ୍ଛା ପୂର୍ଣ୍ଣ କରିବି । ଏତିକି ଧନ ତ ମୋ ପାଖରେ ଅଛି, ଆହୁରି ବି ଏତିକି ଧନ ହୋଇଯିବ। 16.13",

    "ଅମୁକ ଶତ୍ରୁକୁ ତ ମୁଁ ନିପାତ କରିଦେଲି, ଏଣିକି ଅନ୍ୟ ଶତ୍ରୁ ମାନଙ୍କୁ ବି ମାରିଦେବି । ମୁଁ ଈଶ୍ବର( ସର୍ବସମର୍ଥ) । ମୁଁ ଭୋଗ ବିଳାସୀ । ମୁଁ ହି ସିଦ୍ଧ ପୁରୁଷ । ମୁଁ ଖୁବ୍ ବଳବାନ୍ ଓ ସୁଖୀ। 16.14",

    "ମୁଁ ଧନବାନ୍ । ମୋ ହାତରେ ବହୁତ ଲୋକ ଅଛନ୍ତି । ମୋଭଳି ଆଉ କିଏ ଅଛି ? ମୁଁ ବହୁତ ଯଜ୍ଞ କରିବି, ଦାନ ଦେବି ଓ ଆମୋଦ ପ୍ରମୋଦ କରିବି --- ସେମାନେ ଏହିପରି ଅଜ୍ଞାନରେ ବିମୋହିତ ହୋଇଥାନ୍ତି। 16.15",

    "ନାନାପ୍ରକାର କାମନାରେ ଯେଉଁମାନଙ୍କର ଚିତ୍ତ ଭ୍ରମିତ, ଯେଉଁମାନେ ମୋହଜାଲରେ ଦୃଢଭାବରେ ଆବଦ୍ଧ ତଥା ପଦାର୍ଥ ଓ ଭୋଗ ପ୍ରତି ଅତ୍ୟନ୍ତ ଆସକ୍ତ, ସେମାନେ ଘୋର ନରକରେ ପଡନ୍ତି। 16.16",

    "ନିଜକୁ ସବୁଠୁ ଅଧିକ ପୂଜ୍ୟ ମନେ କରୁଥିବା, ଅବିନୟୀ, ଧନ ଓ ମାନରେ ପ୍ରମତ୍ତ ମନୁଷ୍ୟମାନେ ଦମ୍ଭ ସହକାରେ ଅବିଧିପୂର୍ବକ କେବଳ ନାମର ପ୍ରସିଦ୍ଧି ପାଇଁ ଯଜ୍ଞ କରନ୍ତି । 16.17",

    "ଅହଂକାର, ବଳ, ଗର୍ବ, କମ ଓ କ୍ରୋଧପରାୟଣ ହୋଇ ଅନ୍ୟମାନଙ୍କୁ ନିନ୍ଦା କରୁଥିବା ସେହି ପୁରୁଷମାନେ ନିଜର ଓ ଅନ୍ୟମାନଙ୍କର ଶରୀରରେ ଅଧିଷ୍ଠିତ ଥିବା ଅନ୍ତର୍ଯ୍ୟାମୀରୂପି ମୋ ସହିତ ହିଁ ଦ୍ୱେଷ କରନ୍ତି । 16.18",

    "ସେହି ଦ୍ବେଷପରାୟଣ, କ୍ରୂର ସ୍ବଭାବଯୁକ୍ତ ଏବଂ ସଂସାରରେ ଅତି ନୀଚ ଓ ଅପବିତ୍ର ମନୁଷ୍ୟଙ୍କୁ ମୁଁ ବାରମ୍ବାର ଆସୁରୀ ଯୋନିମାନଙ୍କରେ ପକାଉଥାଏ। 16.19",

    "ହେ କୁନ୍ତୀନନ୍ଦନ ! ସେହି ମୂଢ ମନୁଷ୍ୟ ମୋତେ ନ ପାଇ ଜନ୍ମ ଜନ୍ମାନ୍ତରରେ ଆସୁରୀ ଯୋନି ପ୍ରାପ୍ତ ହୁଅନ୍ତି ଏବଂ ତା ' ଠାରୁ ମଧ୍ୟ ଅଧିକ ଅଧମ ଗତିକୁ ଅର୍ଥାତ୍ ଭୟଙ୍କର ନରକକୁ ଚାଲିଯାଆନ୍ତି। 16.20",

    "କାମ, କ୍ରୋଧ ଓ ଲୋଭ --- ଏ ତିନୋଟି ନରକର ଦ୍ବାର ଅଟେ । ଜୀବାତ୍ମାର ଅଧଃପତନର ହେତୁ ସ୍ବରୂପ ଏ ତିନୋଟିକୁ ତ୍ୟାଗ କରିବା ଉଚିତ। 16.21",

    "ହେ କୁନ୍ତୀପୁତ୍ର ! ଏହି ତିନୋଟି ନରକଦ୍ବାରରୁ ମୁକ୍ତ ହୋଇ ଯେଉଁ ମନୁଷ୍ୟ ଆତ୍ମୋଦ୍ଧାର ନିମନ୍ତେ ବ୍ରତୀ ହୁଏ, ସେ ପରମଗତି ଲାଭ କରେ। 16.22",

    "ଯେ ଶାସ୍ତ୍ରବିଧିକୁ ଉପେକ୍ଷା କରି ନିଜ ଇଛାରେ ମନମୁଖି ଆଚରଣ କରେ, ସେ ସିଦ୍ଧି ( ଅନ୍ତଃକରଣର ଶୁଦ୍ଧି ), ସୁଖ କିମ୍ବା ପରମଗତି ପାଇପାରେ ନାହିଁ । 16.23",

    "ତେଣୁ ତୁମପାଇଁ କର୍ତ୍ତବ୍ୟ-ଅକର୍ତ୍ତବ୍ୟ ନିରୂପଣରେ ଶାସ୍ତ୍ର ହିଁ ପ୍ରମାଣ--- ଏହା ଜାଣି ତୁମେ ଇହଲୋକରେ ଶାସ୍ତ୍ରବିଧି ସମ୍ମତ କର୍ତ୍ତବ୍ୟ-କର୍ମ କରିବା ଉଚିତ। 16.24",

    "ଏହି ପ୍ରକାର ଓଁ ତତ୍ ସତ୍ - ଏହି ଭଗବତ୍ ନାମର ଉଚ୍ଚାରଣପୂର୍ବକ ବ୍ରହ୍ମବିଦ୍ୟା ଓ ଯୋଗଶାସ୍ତ୍ରମୟ ଶ୍ରୀମଦ୍ଭଗବଦ୍ଗୀତା ଉପନିଷଦରୂପେ ଶ୍ରୀକୃଷ୍ଣ ଏବଂ ଅର୍ଜୁନଙ୍କ ସମ୍ବାଦରେ 'ଦୈବାସୁରସମ୍ପଦ୍ବିଭାଗଯୋଗନାମକ' ଷୋଡଶ ଅଧ୍ୟାୟ ପୂର୍ଣ୍ଣ ହେଲା"
  ],
  "adhyaya-17" : [
    "ଅର୍ଜୁନ କହିଲେ - ହେ କୃଷ୍ଣ ! ଯେଉଁ ମନୁଷ୍ୟ ମାନେ ଶାସ୍ତ୍ରବିଧି ତ୍ୟାଗ କରି ଶ୍ରଦ୍ଧା ଯୁକ୍ତ ହୋଇ ଦେବାଦୀଙ୍କୁ ପୂଜା କରନ୍ତି , ସେମାନଙ୍କର ସ୍ଥିତି କଅଣ ହୁଏ ? ସାତ୍ତ୍ୱିକୀ , ରାଜସୀ, ନା ତାମସୀ ? 17.1",

    "ଶ୍ରୀ ଭଗବାନ କହିଲେ - ମନୁଷ୍ୟ ମାନଙ୍କର ସେହି ଶାସ୍ତ୍ରୀୟ ସଂସ୍କାର ରୁ ରହିତ କେବଳ ସ୍ୱଭାବରୁ ଉତ୍ପନ୍ନ ଶ୍ରଦ୍ଧା ସାତ୍ତ୍ୱିକୀ , ରାଜସୀ, ତଥା ତାମସୀ - ଏପରି ତିନି ପ୍ରକାରର ହୋଇଥାଏ। ତାହା ତୁମେ ମୋଠାରୁ ଶୁଣ। 17.2",

    "ହେ ଭାରତ ! ସବୁ ମନୁଷ୍ୟଙ୍କ ଶ୍ରଦ୍ଧା ସେମାନଙ୍କ ଅନ୍ତଃକରଣର ଅନୁରୂପ ହୁଏ। ଏହି ପୁରୁଷ ଶ୍ରଦ୍ଧାମୟ ଅଟେ। ତେଣୁ ଯେଉଁ ପୁରୁଷ ଯେପରି ଶ୍ରଦ୍ଧାବାନ୍‌ ଅଟନ୍ତି, ସେ ସ୍ୱୟଂ ମଧ୍ୟ ସେପରି ହିଁ ଅଟନ୍ତି। 17.3",

    "ସାତ୍ତ୍ଵିକ ପୁରୁଷ ମାନେ ଦେବତା ମାନଙ୍କୁ , ରାଜସ ପୁରୁଷ ମାନେ ଯକ୍ଷ୍ୟ ଓ ରାକ୍ଷସ ମାନଙ୍କୁ ଏବଂ ତାମସ ପୁରୁଷ ମାନେ ପ୍ରେତ ଓ ଭୂତ ମାନଙ୍କୁ ପୂଜା କରନ୍ତି। 17.4",

    "ଯେଉଁମାନେ ଶାସ୍ତ୍ରବିଧି ରହିତ କେବଳ ମନୋକଳ୍ପିତ ଘୋର ତପ ଆଚରଣ କରନ୍ତି ତଥା ଦମ୍ଭ ଓ ଅହଂକାରରେ ଯୁକ୍ତ ତଥା କାମନା ଆସକ୍ତି ଓ ବଳର ଅଭିମାନରେ ମଧ୍ୟ ଯୁକ୍ତ - 17.5",

    "ଶରୀର ରୂପରେ ଅବସ୍ଥିତ ଭୂତ ସମୁଦାୟକୁ ଏବଂ ଅନ୍ତଃ କରଣରେ ଅବସ୍ଥିତ ମୋତେ (ପରମାତ୍ମାଙ୍କୁ) ମଧ୍ୟ କୃଶ କରାଉଥିବା ସେହି ଅଜ୍ଞାନୀ ମାନଙ୍କୁ ଜିଦ୍‌ ପୂର୍ବକ ଆସୁର ସ୍ଵଭାବ ଧରିଥିବା ଲୋକ ବୋଲି ଜାଣ।  17.6",

    "ଆହାର ମଧ୍ୟ ଲୋକଙ୍କ ପ୍ରକୃତି ଭେଦରେ ତିନି ପ୍ରକାର ପ୍ରିୟ ହୁଏ ଏବଂ ଯଜ୍ଞ , ତପ ଓ ଦାନ ମଧ୍ୟ ସେହିପରି ତିନି ପ୍ରକାରର ଅଟେ। 17.7",

    "ଯେଉଁ ଆହାର ଆୟୁ , ବୁଦ୍ଧି , ବଳ , ଆରୋଗ୍ୟ , ସୁଖ ଏବଂ ପ୍ରୀତି ବର୍ଦ୍ଧନକାରୀ, ରସ ଯୁକ୍ତ , ସ୍ନିଗ୍ଧ , ଏବଂ ସ୍ଥିର ତଥା ସ୍ଵଭାବତଃ ମନକୁ ପ୍ରିୟ , ତାହା ସାତ୍ତ୍ଵିକ ପୁରୁଷ ମାନଙ୍କର ପ୍ରିୟ। 17.8",

    "ପିତା, ଖଟା , ଲୁଣିଆ , ଅତି ଉଷ୍ଣ , ରାଗ , ରୁକ୍ଷ , ଦାହ କାରକ ଏବଂ ଦୁଃଖ, ଚିନ୍ତା ତଥା ରୋଗ ଉତ୍ପନ୍ନ କରୁଥିବା ଆହାର ରାଜସିକ ପୁରୁଷର ପ୍ରିୟ। 17.9",

    "ଯେଉଁ ଖାଦ୍ୟ ଅର୍ଧସିଦ୍ଧ , ରସରହିତ , ଦୁର୍ଗନ୍ଧ ଯୁକ୍ତ , ବାସି ଓ ଅଇଁଠା ତଥା ଅପବିତ୍ର , ସେହି ଖାଦ୍ୟ ତାମସିକ ପୁରୁଷର ପ୍ରିୟ। 17.10",

    "ଯେଉଁ ଯଜ୍ଞ ଶାସ୍ତ୍ର ବିଧିରେ ନିୟତ, ଯାହା କରିବା ହିଁ ଉଚିତ ବୋଲି ମନକୁ ସମାହିତ କରି ଫଳ ଇଚ୍ଛା କରୁନଥିବା ପୁରୁଷଙ୍କ ଦ୍ୱାରା କରାଯାଏ , ତାହା ସାତ୍ତ୍ଵିକ।  17.11",

    "କିନ୍ତୁ ହେ ଅର୍ଜୁନ! କେବଳ ଦମ୍ଭାଚରଣ ପାଇଁ ଅଥବା ଫଳପ୍ରାପ୍ତି ଉଦ୍ଦେଶ୍ୟ ରଖି ଯେଉଁ ଯଜ୍ଞ କରାଯାଏ, ସେହି ଯଜ୍ଞକୁ ତୁମେ ରାଜସ ବୋଲି ଜାଣ। 17.12",

    "ବିନା ମନ୍ତ୍ରରେ, ବିନା ଦକ୍ଷିଣାରେ, ଏବଂ ବିନା ଶ୍ରଦ୍ଧାରେ କରାଯାଉଥିବା ଶାସ୍ତ୍ର ବିଧି ରହିତ ତଥା ଅବଦାନ ରହିତ ଯଜ୍ଞକୁ ତାମସ ଯଜ୍ଞ ବୋଲି କୁହାଯାଏ। 17.13",

    "ଦେବତା , ବ୍ରାହ୍ମଣ, ଗୁରୁ ଏବଂ ଜ୍ଞାନୀ ଜନଙ୍କର ପୂଜନ, ପବିତ୍ରତା, ସରଳତା, ବ୍ରହ୍ମଚର୍ଯ୍ୟ ଏବଂ ଅହିଂସା - ଏହାକୁ ଶରୀର ସମ୍ବନ୍ଧୀୟ ତପ କୁହାଯାଏ। 17.14",

    "ଯାହା ଉଦ୍ ବେଗ ସୃଷ୍ଟି କରେ ନାହିଁ , ଯାହା ପ୍ରିୟ ହିତକର ଏବଂ ଯଥାର୍ଥ ଭାଷଣ ତଥା ଯାହା ବେଦ-ଶାସ୍ତ୍ର ପଠନର ଏବଂ ପରମେଶ୍ବରଙ୍କ ନାମ ଜପ କରିବାର ଅଭ୍ୟାସ , ତାହାକୁ ହିଁ ବାଣୀ ସମ୍ବନ୍ଧୀୟ ତପ କୁହାଯାଏ। 17.15",

    "ମନର ପ୍ରସନ୍ନତା , ଶାନ୍ତଭାବ , ଭଗବତ ଚିନ୍ତନ କରିବାର ସ୍ଵଭାଵ , ମନର ନିଗ୍ରହ ଏବଂ ଅନ୍ତଃକରଣ ସ୍ଥିତ ଭାବ ମାନଙ୍କର ସମ୍ୟକ ପବିତ୍ରତା - ଏହି ସବୁକୁ ମନ ସମ୍ବନ୍ଧୀୟ ତପ କୁହାଯାଏ। 17.16",

    "ଫଳ ଇଛା କରୁ ନଥିବା ଯୋଗୀ ପୁରୁଷ ମାନଙ୍କ ଦ୍ୱାରା ପରମ ଶ୍ରଦ୍ଧାର ସହିତ କରାଯାଉଥିବା ସେହି ପୂର୍ବୋକ୍ତ କାୟିକ, ବାଚିକ, ଓ ମାନସିକ - ତିନିପ୍ରକାର ତପକୁ ସାତ୍ତ୍ଵିକ ତପ କହନ୍ତି। 17.17",

    "ଯେଉଁ ତପ ସତ୍କାର, ମାନ ଓ ପୂଜା ପାଇଁ ତଥା ଅନ୍ୟ କୌଣସି ସ୍ୱାର୍ଥ ସିଦ୍ଧି ପାଇଁ ଦମ୍ଭ ପୂର୍ବକ କରାଯାଏ, ସେହି ଅନିଶ୍ଚିତ ଏବଂ କ୍ଷଣିକ ଫଳ ଯୁକ୍ତ ତପକୁ ରାଜସ ତପ କୁହାଯାଏ। 17.18",

    "ଯେଉଁ ତପ ମୂଢତା ପୂର୍ବକ ଜିଦ୍‌ ଧରି ମନ , ବାଣୀ ଓ ଶରୀରର ପୀଡା ସହିତ ଅଥବା ଅନ୍ୟର ଅନିଷ୍ଟ କରିବା ନିମନ୍ତେ କରାଯାଏ , ସେହି ତପକୁ ତାମସ ତପ କୁହାଯାଏ।  17.19",

    "ଦାନ ଦେବା ହିଁ କର୍ତବ୍ୟ - ଏପରି ଭାବ ରଖି ଯେଉଁ ଦାନ ଦେଶ, କାଳ ଓ ପାତ୍ର ପ୍ରାପ୍ତିର ପ୍ରତ୍ୟୁପକାର ଆଶା ନ ରଖି ଅନୁପକାରୀକୁ ନିଃସ୍ୱାର୍ଥ ଭାବରେ ଦିଆ ଯାଏ, ସେହି ଦାନକୁ ସାତ୍ତ୍ଵିକ ଦାନ କହନ୍ତି। 17.20",

    "କିନ୍ତୁ ଯେଉଁ ଦାନ କ୍ଳେଶ ପୂର୍ବକ ତଥା ପ୍ରତ୍ୟୁପକାର ପାଇବା ନିମନ୍ତେ ଅଥବା ଫଳପ୍ରାପ୍ତି ଉଦ୍ଦେଶ୍ୟରେ ଦିଆ ଯାଏ , ସେହି ଦାନକୁ ରାଜସ ବୋଲି କୁହାଯାଏ।  17.21",

    "କିନ୍ତୁ ଯେଉଁ ଦାନ ବିନା ସତ୍କାରରେ ଅଥବା ତିରସ୍କାର ପୂର୍ବକ ଅଯୋଗ୍ୟ ଦେଶ - କାଳରେ ଏବଂ ଅପାତ୍ରରେ ଦିଆ ଯାଏ , ସେହି ଦାନକୁ ତାମସ କୁହାଯାଏ। 17.22",

    "ଓଁ , ତତ୍, ସତ୍‌ - ସଚ୍ଚିଦାନନ୍ଦଘନ ବ୍ରହ୍ମଙ୍କର ଏପରି ତିନି ପ୍ରକାର ନାମ କୁହ ଯାଇଛି | ସୃଷ୍ଟିର ଆଦି କାଳରେ ବ୍ରାହ୍ମଣମାନେ ଏବଂ ବେଦ ମାନେ ତଥା ଯଜ୍ଞ ମାନେ ସେଥିରୁ ରଚିତ ହୋଇଛନ୍ତି। 17.23",

    "ତେଣୁ ବେଦ ମନ୍ତ୍ର ଉଚ୍ଚାରଣ କରୁଥିବା ଶ୍ରେଷ୍ଠ ପୁରୁଷ ମାନଙ୍କର ଶାସ୍ତ୍ର ବିହିତ ଯଜ୍ଞ , ଦାନ ଏବଂ ତପ ପୂର୍ବକ କ୍ରିୟା ଗୁଡିକ ସର୍ବଦା ପରମାତ୍ମାଙ୍କର ଏହି ଓଁ ନାମ ଉଚ୍ଚାରଣ କରି ହିଁ ଆରମ୍ଭ ହୁଏ। 17.24",

    "ଏ ସବୁକିଛି ତତ୍ ଅର୍ଥାତ ତତ୍ ନାମରେ କୁହାଯାଉଥିବା ପରମାତ୍ମାଙ୍କର ହିଁ ଅଟେ ବୋଲି ଭାବ ରଖି କଲ୍ୟାଣକାମୀ ପୁରୁଷମାନେ ଫଳ ଆକାଙ୍କ୍ଷା ନ କରି ନାନା ପ୍ରକାର ଯଜ୍ଞ , ତପ ଓ ଦାନ ରୂପୀ କ୍ରିୟା କରନ୍ତି।  17.25",

    "ସତ୍‌ - ପରମାତ୍ମାଙ୍କର ଏହି ନାମ ସତ୍ୟଭାବରେ ଏବଂ ଶ୍ରେଷ୍ଠଭାବରେ ପ୍ରୟୋଗ କରାଯାଏ ତଥା , ହେ ପାର୍ଥ ! ଉତ୍ତମ କର୍ମରେ ସତ୍‌ ଶବ୍ଦ ପ୍ରୟୋଗ କରାଯାଏ। 17.26",

    "ଯଜ୍ଞ, ତପ ଓ ଦାନରେ ଯେଉଁ ସ୍ଥିତି ଥାଏ , ତାହାକୁ ମଧ୍ୟ ସତ୍‌ ବୋଲି କୁହାଯାଏ ଏବଂ ସେହି ପରମାତ୍ମାଙ୍କ ପାଇଁ କରାଯାଉଥିବା କର୍ମ ନିଶ୍ଚିତ ରୂପେ ସତ୍‌ ବୋଲି କୁହାଯାଏ। 17.27",

    "ହେ ପୃଥାପୁତ୍ର ! ବିନା ଶ୍ରଦ୍ଧାରେ କରାଯାଉଥିବା ହୋମ , ଦିଆଯାଉଥିବା ଦାନ ଏବଂ କରାଯାଉଥିବା ତପ ତଥା ଅନ୍ୟାନ୍ୟ ଶୁଭ କର୍ମକୁ ‘ଅସତ୍‌’ ବୋଲି କୁହାଯାଏ ; ତେଣୁ ସେସବୁ ଇହ ଲୋକରେ ଲାଭଦାୟକ ନୁହେଁ , କି ମରିବା ପରେ ମଧ୍ୟ ଲାଭଦାୟକ ନୁହେଁ । 17.28",

    "ଏହି ପ୍ରକାର ଓଁ ତତ୍ ସତ୍ - ଏହି ଭଗବତ୍ ନାମର ଉଚ୍ଚାରଣପୂର୍ବକ ବ୍ରହ୍ମବିଦ୍ୟା ଓ ଯୋଗଶାସ୍ତ୍ରମୟ ଶ୍ରୀମଦ୍ଭଗବଦ୍ଗୀତା ଉପନିଷଦରୂପେ ଶ୍ରୀକୃଷ୍ଣ ଏବଂ ଅର୍ଜୁନଙ୍କ ସମ୍ବାଦରେ 'ଶ୍ରଦ୍ଧାତ୍ରୟବିଭାଗଯୋଗନାମକ' ସପ୍ତଦଶ ଅଧ୍ୟାୟ ପୂର୍ଣ୍ଣ ହେଲା"
  ],
  "adhyaya-18" : [
    "ଅର୍ଜୁନ କହିଲେ- ହେ ମହାବାହୁ ! ହେ ଅନ୍ତର୍ଯ୍ୟାମୀ ! ହେ ବାସୁଦେବ ! ମୁଁ ସନ୍ନ୍ୟାସ ଏବଂ ତ୍ୟାଗ ର ତତ୍ତ୍ୱ ପୃଥକ୍ ପୃଥକ୍ ଭାବରେ ଜାଣିବାକୁ ଇଚ୍ଛା କରୁଛି । 18.1",

    "ଶ୍ରୀ ଭଗବାନ୍ କହିଲେ- କେତେକ ପଣ୍ଡିତ କାମ୍ୟ କର୍ମମାନଙ୍କର ତ୍ୟାଗ କୁ ସନ୍ନ୍ୟାସ ବୋଲି ବୁଝନ୍ତି ଏବଂ ଅନ୍ୟ ବିଚାରକୁଶଳ ପୁରୁଷମାନେ ସକଳ କର୍ମ ର ଫଳତ୍ୟାଗକୁ ତ୍ୟାଗ ବୋଲି କହନ୍ତି । 18.2",

    "କେହି କେହି ବିଦ୍ୱାନ କହନ୍ତି ଯେ, କର୍ମମାତ୍ରକେ ଦୋଷଯୁକ୍ତ; ତେଣୁ ତାହା ତ୍ୟାଗ କରିବା ଉଚିତ୍ ଏବଂ ଅନ୍ୟ ବିଦ୍ୱାନମାନେ  କହନ୍ତି ଯେ, ଯଜ୍ଞ, ଦାନ ଓ ତପରୂପକ କର୍ମ ତ୍ୟାଗ କରିବା ଉଚିତ୍ ନୁହେଁ । 18.3",

    "ହେ ପୁରୁଷଶ୍ରେଷ୍ଠ ଅର୍ଜୁନ ! ସନ୍ନ୍ୟାସ ଓ ତ୍ୟାଗ- ଏହି ଦୁଇଟି ମଧ୍ୟରୁ ପ୍ରଥମେ ତ୍ୟାଗ ବିଷୟରେ ତୁମେ ମୋର ନିଶ୍ଚିତ ମତ ଶୁଣ । ତ୍ୟାଗ ସାତ୍ତ୍ୱିକ, ରାଜସ ଓ ତାମସା ଭେଦରେ ତିନି ପ୍ରକାର ବୋଲି କୁହାଯାଇଛି । 18.4",

    "ଯଜ୍ଞ, ଦାନ ଓ ତପରୂପକ କର୍ମ ତ୍ୟାଗ କରିବା ଉଚିତ୍ ନୁହେଁ; ବରଂ ତାହା ଅବଶ୍ୟ କରଣୀୟ; କାରଣ ଯଜ୍ଞ, ଦାନ ଓ ତପ- ଏହି ତିନୋଟି କର୍ମ ହିଁ ବୁଦ୍ଧିମାନ୍ ପୁରୁଷମାନଙ୍କୁ ପବିତ୍ର କରେ । 18.5",

    "ତେଣୁ ହେ ପାର୍ଥ ! ଆସକ୍ତି ଓ ଫଳତ୍ୟାଗ କରି ଏହି ଯଜ୍ଞ, ଦାନ ଏବଂ ତପରୂପକ କର୍ମ ତଥା ଅନ୍ୟ ସକଳ କର୍ତ୍ତବ୍ୟ କର୍ମ ଅବଶ୍ୟ କରିବା ଉଚିତ୍ । ଏହା ହେଉଛି ମୋର ନିଶ୍ଚୟ କରାଯାଇଥିବା ଉତ୍ତମ ମତ । 18.6",

    "(ନିଷିଦ୍ଧ ଓ କାମ୍ୟକର୍ମ ତ ସ୍ୱରୂପତଃ ତ୍ୟାଗ କରିବା ଉଚିତ୍), ପରନ୍ତୁ ନିୟତକର୍ମ ସ୍ୱରୂପତଃ ତ୍ୟାଗ କରିବା ଉଚିତ୍ ନୁହେଁ । ତେଣୁ ମୋହବଶତଃ ତାହା ତ୍ୟାଗ କରିଦେବାକୁ ତାମସ ତ୍ୟାଗ କୁହାଯାଏ । 18.7",

    "ଯାହା କିଛି କର୍ମ ଅଛି, ସେସବୁ ଦୁଃଖ ରୂପ ହିଁ ଅଟେ- ଏପରି ବୁଝି ଯଦି କେହି ଶାରୀରିକ କ୍ଳେଶ ଭୟରେ କର୍ତ୍ତବ୍ୟକର୍ମ ତ୍ୟାଗ କରିଦିଏ, ତେବେ ସେ ଏପରି ରାଜସ ତ୍ୟାଗ କରି କୌଣସି ପ୍ରକାରେ ତ୍ୟାଗ ର ଫଳ ପାଏ ନାହିଁ । 18.8",

    "ହେ ଅର୍ଜୁନ ! ଶାସ୍ତ୍ରବିହିତ କର୍ମ କରିବା ହିଁ କର୍ତ୍ତବ୍ଯ ବୋଲି ଭାବି ଆସକ୍ତି ଓ ଫଳତ୍ୟାଗପୂର୍ବକ ଯେଉଁ କର୍ମ କରାଯାଏ, ତାହା ହିଁ ସାତ୍ତ୍ୱିକ ତ୍ୟାଗ ବୋଲି ଗୃହୀତ ହୁଏ । 18.9",

    "ଯେଉଁ ମନୁଷ୍ୟ ଅକୁଶଳ କର୍ମରେ ଦ୍ବେଷ କରନ୍ତି ନାହିଁ, କି କୁଶଳ କର୍ମରେ ଆସକ୍ତ ହୁଅନ୍ତି ନାହିଁ, ସେହି ଶୁଦ୍ଧସତ୍ତ୍ୱଗୁଣଯୁକ୍ତ ପୁରୁଷ ସଂଶୟରହିତ, ବୁଦ୍ଧିମାନ୍ ଏବଂ ପ୍ରକୃତ ତ୍ୟାଗୀ । 18.10",

    "କାରଣ ଶରୀରଧାରୀ କୌଣସି ବି ମନୁଷ୍ୟ ସମ୍ପୂର୍ଣ୍ଣ ରୂପେ ସକଳ କର୍ମ ତ୍ୟାଗ କରିଦେବାକୁ ସକ୍ଷମ ନୁହେଁ; ତେଣୁ ଯେ କର୍ମଫଳତ୍ୟାଗୀ, ତାଙ୍କୁ ହିଁ ତ୍ୟାଗୀ ବୋଲି କୁହାଯାଏ । 18.11",

    "କର୍ମଫଳ ତ୍ୟାଗ କରି ନ ଥିବା ମନୁଷ୍ୟମାନଙ୍କ କର୍ମ ର ଭଲ, ମନ୍ଦ ଓ ମିଶ୍ରିତ - ଏପରି ତିନି ପ୍ରକାର ଫଳ ମୃତ୍ୟୁ ପରେ ଅବଶ୍ୟ ହୁଏ; କିନ୍ତୁ କର୍ମଫଳ ତ୍ୟାଗ କରିଦେଇଥିବା ମନୁଷ୍ୟମାନଙ୍କ କର୍ମ ର ଫଳ କୌଣସି କାଳରେ ମଧ୍ୟ ହୁଏ ନାହିଁ । 18.12",

    "ହେ ମହାବାହୁ ! କର୍ମମାନଙ୍କୁ ଅନ୍ତ କରିବାର ଉପାୟ କହୁଥିବା ସାଙ୍ଖ୍ୟଶାସ୍ତ୍ରରେ ସକଳ କର୍ମ ର ସିଦ୍ଧି ପାଇଁ ପାଞ୍ଚଟି କାରଣ କୁହାଯାଇଛି, ସେଗୁଡିକ ତୁମେ ମୋ ଠାରୁ ଭଲଭାବରେ ଜାଣିନିଅ । 18.13",

    "ଏହି ବିଷୟରେ ଅର୍ଥାତ୍ କର୍ମମାନଙ୍କ ସିଦ୍ଧି ରେ ଅଧିଷ୍ଠାନ,କର୍ତ୍ତା, ବିଭିନ୍ନ ପ୍ରକାର କରଣ, ନାନା ପ୍ରକାର ଚେଷ୍ଟା ଏବଂ ସେହିପରି ପଞ୍ଚମ ହେତୁ ଦୈବ ଅଟେ । 18.14",

    "ମନୁଷ୍ୟ ମନ, ବାଣୀ ଓ ଶରୀର ଦ୍ୱାରା ଶାସ୍ତ୍ରାନୁକୂଳ ଅଥବା ବିପରୀତ, ଯାହା କିଛି ବି କର୍ମ କରେ, ଏହି ପାଞ୍ଚଟି ହିଁ ତାହାର କାରଣ । 18.15",

    "ପରନ୍ତୁ  ଏପରି ପାଞ୍ଚଟି ହେତୁ ଥିଲେ ମଧ୍ୟ ଯିଏ ସେହି (କର୍ମ) ବିଷୟ ରେ କେବଳ (ଶୁଦ୍ଧ) ଆତ୍ମା କୁ କର୍ତ୍ତା ଦେଖେ, ସେହି ଦୁର୍ମତି ଠିକ୍ ଦେଖେ ନାହିଁ; କାରଣ ତା'ର ବୁଦ୍ଧି ଶୁଦ୍ଧ ନୁହେଁ ଅର୍ଥାତ୍ ସେ ନିଜ ବିବେକକୁ ମହତ୍ତ୍ୱ ଦେଇ ନାହିଁ । 18.16",

    "ଯାହାର ଅହଂକୃତ ଭାବ ନାହିଁ ଏବଂ ଯାହାର ବୁଦ୍ଧି ଲିପ୍ତ ନୁହେଁ, ସେ ଯୁଦ୍ଧରେ ଏ ସମସ୍ତ ପ୍ରାଣୀ ଙ୍କୁ ମାରି ମଧ୍ୟ ମାରେ ନାହିଁ କି ବନ୍ଧନ ରେ ପଡେ ନାହିଁ । 18.17",

    "ଜ୍ଞାନ, ଜ୍ଞେୟ ଓ ପରିଜ୍ଞାତା - ଏ ତିନୋଟି ଦ୍ୱାରା କର୍ମପ୍ରେରଣା ହୁଏ ଏବଂ କରଣ, କର୍ମ ଓ କର୍ତ୍ତ।- ଏ ତିନୋଟି ଦ୍ୱାରା କର୍ମସଂଗ୍ରହ ହୁଏ । 18.18",

    "ଗୁଣମାନଙ୍କର ବିବେଚନା କରାଯାଇଥିବା ଶାସ୍ତ୍ରରେ ଗୁଣ ଭେଦରେ ଜ୍ଞାନ, କର୍ମ ଓ କର୍ତ୍ତ। ପ୍ରତ୍ୟେକକୁ ତିନି ପ୍ରକାର ରେ କୁହାଯାଇଛି, ସେଗୁଡିକୁ ମଧ୍ୟ ତୁମେ ଯଥାର୍ଥ ରୂପେ ଶୁଣ । 18.19",

    "ଯେଉଁ ଜ୍ଞାନ ଦ୍ୱାରା ସାଧକ ସମସ୍ତ ବିଭକ୍ତ ପ୍ରାଣୀଙ୍କ ଠାରେ ବିଭାଗ ରହିତ ଏକ ଅବିନାଶୀ ଭାବ ବା (ସତ୍ତା) କୁ ଦେଖେ, ସେହି ଜ୍ଞାନ କୁ ତୁମେ ସାତ୍ତ୍ଵିକ ବୋଲି ଜାଣ । 18.20",

    "କିନ୍ତୁ ଯେଉଁ ଜ୍ଞାନ ଦ୍ୱାରା ମନୁଷ୍ୟ ସମସ୍ତ ପ୍ରାଣୀଙ୍କର ଭିନ୍ନ ଭିନ୍ନ ଅନେକ ଭାବକୁ ପୃଥକ୍ ପୃଥକ୍ ବୋଲି ଜାଣେ, ସେହି ଜ୍ଞାନ କୁ ତୁମେ ରାଜସ ବୋଲି ଜାଣ । 18.21",

    "ଯେଉଁ ଜ୍ଞାନ ଦ୍ୱାରା ମନୁଷ୍ୟ ଏକମାତ୍ର କାର୍ଯ୍ୟରୂପକ ଶରୀରରେ ହିଁ ସମ୍ପୂର୍ଣ୍ଣ ରୂପେ ଆସକ୍ତ ହୋଇଯାଏ ଏବଂ ଯାହା ଅଯୌକ୍ତିକ, ତାତ୍ତ୍ୱିକ ଅର୍ଥରୁ ରହିତ ତଥା ତୁଚ୍ଛ, ତାହାକୁ ତାମସ  ବୋଲି କୁହାଯାଏ । 18.22",

    "ଯେଉଁ କର୍ମ ଶାସ୍ତ୍ର ବିଧି ଦ୍ୱାରା ନିୟତ ଏବଂ କର୍ତ୍ତାପଣର ଅଭିମାନରୁ ରହିତ ‌ହୋଇଫଳ ଇଚ୍ଛା କରୁ ନ ଥିବା ପୁରୁଷ ଙ୍କ ଦ୍ଵାରା ବିନା ରାଗ-ଦ୍ୱେଷ ରେ  କରାଯାଏ, ତାହାକୁ ସାତ୍ତ୍ଵିକ କର୍ମ କୁହାଯାଏ । 18.23",

    "କିନ୍ତୁ ଯେଉଁ କର୍ମ ଭୋଗେଚ୍ଛା ରେ ଅଥବା ଅହଙ୍କାର ସହିତ ଓ ପରିଶ୍ରମପୂର୍ବକ କରାଯାଏ, ତାହାକୁ ରାଜସ କର୍ମ କୁହାଯାଏ ।  18.24",

    "ଯେଉଁ କର୍ମ ପରିଣାମ, କ୍ଷତି, ହିଂସା ଓ ସାମର୍ଥ୍ୟ କୁ ନ ଦେଖି ମୋହପୂର୍ବକ ଆରମ୍ଭ କରାଯାଏ, ତାହାକୁ ତାମସ କୁହାଯାଏ । 18.25",

    "ଯେଉଁ କର୍ତ୍ତା ରାଗରହିତ, କର୍ତ୍ତୃତ୍ବାଭିମାନରହିତ, ଧୈର୍ଯ୍ୟ ଓ ଉତ୍ସାହଯୁକ୍ତ ତଥା ସିଦ୍ଧି-ଅସିଦ୍ଧିରେ ନିର୍ବିକାର, ତାହାକୁ ସାତ୍ତ୍ଵିକ କର୍ତ୍ତା କୁହାଯାଏ । 18.26",

    "ଯେଉଁ କର୍ତ୍ତା ରାଗୀ(ଆସକ୍ତ), କର୍ମଫଳାକାଙ୍କ୍ଷୀ, ଲୋଭୀ, ହିଂସାପରାୟଣ, ଅଶୁଦ୍ଧ ଓ ହର୍ଷଶୋକଯୁକ୍ତ, ତାହାକୁ ରାଜସ କର୍ତ୍ତା କୁହାଯାଏ । 18.27",

    "ଯେଉଁ କର୍ତ୍ତା ଅସାବଧାନ, ଅଶିକ୍ଷିତ, ଅବିନୟୀ, ଜିଦଖୋର, ଅକୃତଜ୍ଞ,ଅଳସୁଆ, ବିଷାଦୀ ଓ ଦୀର୍ଘସୂତ୍ରୀ, ତାହାକୁ ତାମସ କର୍ତ୍ତା କୁହାଯାଏ । 18.28",

    "ହେ ଧନଞ୍ଜୟ ! ଏବେ ମୁଁ ଗୁଣ ଅନୁସାରେ ବୁଦ୍ଧି ଓ ଧୃତି ର ତିନୋଟି ପ୍ରକାରଭେଦ ବିଷୟରେ ପୃଥକ୍ ପୃଥକ୍ ଭାବରେ କହୁଛି, ଶୁଣ । 18.29",

    "ହେ ପୃଥାନନ୍ଦନ ! ଯେଉଁ ବୁଦ୍ଧି ପ୍ରବୃତ୍ତି ଓ ନିବୃତ୍ତି କୁ, କର୍ତ୍ତବ୍ୟ ଓ ଅକର୍ତ୍ତବ୍ୟକୁ, ଭୟ ଓ ଅଭୟକୁ ତଥା ବନ୍ଧନ ଓ ମୋକ୍ଷକୁ ଜାଣେ, ସେହି ବୁଦ୍ଧି ସାତ୍ତ୍ୱିକୀ ଅଟେ । 18.30",

    "ହେ ପାର୍ଥ ! ମନୁଷ୍ୟ ଯେଉଁ ବୁଦ୍ଧି ଦ୍ୱାରା ଧର୍ମ ଓ ଅଧର୍ମ କୁ ତଥା କର୍ତ୍ତବ୍ଯ ଓ ଅକର୍ତ୍ତବ୍ୟକୁ ମଧ୍ୟ ଠିକ୍ ଭାବେ ଜାଣିପାରେ ନାହିଁ, ସେହି ବୁଦ୍ଧି ରାଜସୀ ଅଟେ । 18.31",

    "ହେ ପୃଥାନନ୍ଦନ ! ତମୋଗୁଣ ଦ୍ୱାରା ଆଚ୍ଛାଦିତ ଯେଉଁ ବୁଦ୍ଧି ଅଧର୍ମ କୁ ଧର୍ମ ଏବଂ ସବୁ ବିଷୟକୁ ବିପରୀତ ମନେକରେ, ତାହା ତାମସୀ ବୁଦ୍ଧି ଅଟେ । 18.32",

    "ହେ ପାର୍ଥ ! ଯେଉଁ ସମତାଯୁକ୍ତ ଅବ୍ୟଭିଚାରିଣୀ ଧୃତି ଦ୍ୱାରା ମନୁଷ୍ୟ ମନ, ପ୍ରାଣ ଓ ଇନ୍ଦ୍ରିୟମାନଙ୍କର ସମସ୍ତ କ୍ରିୟା କୁ ଧାରଣ ଅର୍ଥାତ୍ ସଂଯମ କରେ, ସେହି ଧୃତି ସାତ୍ତ୍ଵିକୀ ଅଟେ । 18.33",

    "କିନ୍ତୁ ହେ ପୃଥାନନ୍ଦନ ଅର୍ଜୁନ ! ଫଳାକାଙ୍କ୍ଷୀ ମନୁଷ୍ୟ ଯେଉଁ ଧୃତି ଦ୍ୱାରା ଧର୍ମ, କାମ(ଭୋଗ) ଓ ଅର୍ଥ କୁ ଅତ୍ୟନ୍ତ ଆସକ୍ତିପୂର୍ବକ ଧାରଣ କରେ, ସେହି ଧୃତି ରାଜସୀ ଅଟେ । 18.34",

    "ହେ ପାର୍ଥ ! ଦୁଷ୍ଟ ବୁଦ୍ଧି ମନୁଷ୍ୟ ଯେଉଁ ଧୃତି ଦ୍ୱାରା ନିଦ୍ରା, ଭୟ, ଚିନ୍ତା, ଦୁଃଖ ଓ ଅହମିକା ମଧ୍ୟ ଛାଡେ ନାହିଁ ଅର୍ଥାତ୍ ଧାରଣ କରିଥାଏ, ସେହି ଧୃତି ତାମସୀ ଅଟେ । 18.35",

   "ହେ ଭରତଶ୍ରେଷ୍ଠ ଅର୍ଜୁନ ! ଏବେ ତୁମେ ମୋ ଠାରୁ ତିନି ପ୍ରକାର ସୁଖ ବିଷୟରେ ମଧ୍ୟ ଶୁଣ । ଅଭ୍ୟାସ ଦ୍ୱାରା ଯେଉଁଥିରେ ରମଣ ହୁଏ ଏବଂ ଯେଉଁଥିରେ ସମସ୍ତ ଦୁଃଖ ଅନ୍ତ ହୋଇଯାଏ, ଏତାଦୃଶ ପରମାତ୍ମବିଷୟକ ବୁଦ୍ଧି ର ପ୍ରସନ୍ନତା ରୁ ଉତ୍ପନ୍ନ ଯେଉଁ ସୁଖ (ସାଂସାରିକ ଆସକ୍ତି ହେତୁ) ଆରମ୍ଭ ରେ ବିଷତୁଲ୍ୟ ଓ ପରିଣାମ ରେ ଅମୃତତୁଲ୍ୟ, ତାହାକୁ ସାତ୍ତ୍ଵିକ ସୁଖ କୁହାଯାଏ । 18.36-18.37",

   "ହେ ଭରତଶ୍ରେଷ୍ଠ ଅର୍ଜୁନ ! ଏବେ ତୁମେ ମୋ ଠାରୁ ତିନି ପ୍ରକାର ସୁଖ ବିଷୟରେ ମଧ୍ୟ ଶୁଣ । ଅଭ୍ୟାସ ଦ୍ୱାରା ଯେଉଁଥିରେ ରମଣ ହୁଏ ଏବଂ ଯେଉଁଥିରେ ସମସ୍ତ ଦୁଃଖ ଅନ୍ତ ହୋଇଯାଏ, ଏତାଦୃଶ ପରମାତ୍ମବିଷୟକ ବୁଦ୍ଧି ର ପ୍ରସନ୍ନତା ରୁ ଉତ୍ପନ୍ନ ଯେଉଁ ସୁଖ (ସାଂସାରିକ ଆସକ୍ତି ହେତୁ) ଆରମ୍ଭ ରେ ବିଷତୁଲ୍ୟ ଓ ପରିଣାମ ରେ ଅମୃତତୁଲ୍ୟ, ତାହାକୁ ସାତ୍ତ୍ଵିକ ସୁଖ କୁହାଯାଏ । 18.36-18.37",

    "ଯେଉଁ ସୁଖ ଇନ୍ଦ୍ରିୟ ଓ ବିଷୟ ସଂଯୋଗ ରୁ ଜାତ, ତାହା ଆରମ୍ଭରେ ଅମୃତତୁଲ୍ୟ ଓ ପରିଣାମରେ ବିଷତୁଲ୍ୟ ପ୍ରତୀତ ହୁଏ, ତେଣୁ ତାହାକୁ ରାଜସ ସୁଖ କୁହାଯାଏ । 18.38",

    "ନିଦ୍ରା, ଆଳସ୍ୟ ଓ ପ୍ରସାଦଜନିତ ଯେଉଁ ସୁଖ ଆରମ୍ଭରେ ଓ ପରିଣାମରେ ନିଜକୁ ମୋହିତ କରେ, ତାହାକୁ ତାମସ ସୁଖ କୁହାଯାଏ । 18.39",

    "ପୃଥିବୀରେ, ସ୍ୱର୍ଗରେ ଅଥବା ଦେବତାମାନଙ୍କ ଠାରେ ତଥା ଏମାନଙ୍କ ଅତିରିକ୍ତ ଅନ୍ୟ କେଉଁଠି ବି ଏପରି କୌଣସି ବସ୍ତୁ ନାହିଁ, ଯାହା ପ୍ରକୃତିଜନିତ ଏହି ତିନି ଗୁଣରୁ ମୁକ୍ତ । 18.40",

    "ହେ ପରନ୍ତପ ! ବ୍ରାହ୍ମଣ, କ୍ଷତ୍ରିୟ, ବୈଶ୍ୟ ଓ ଶୂଦ୍ରଙ୍କର କର୍ମ ସ୍ୱଭାବଜାତ ତିନି ଗୁଣ ଦ୍ଵାରା ବିଭକ୍ତ କରାଯାଇଛି । 18.41",

    "ମନୋନିଗ୍ରହ କରିବା; ଇନ୍ଦ୍ରିୟଗଣଙ୍କୁ ସଂଯତ କରିବା; ଧର୍ମ ପାଳନ ନିମନ୍ତେ କଷ୍ଟ ସହିବା; ବାହ୍ୟ ଓ ଆଭ୍ୟନ୍ତରରେ ଶୁଦ୍ଧ ରହିବା;ଅନ୍ୟମାନଙ୍କର ଅପରାଧ କ୍ଷମା କରିବା; ଶରୀର, ମନ ଆଦିରେ ସରଳତା ରକ୍ଷା କରିବା;ବେଦ,ଶାସ୍ତ୍ର ଆଦିର ଜ୍ଞାନ ହେବା; ଯଜ୍ଞବିଧିକୁ ଅନୁଭବ କରିବା ଏବଂ ପରମାତ୍ମା, ବେଦ ଆଦିରେ ଆସ୍ତିକଭାବ ରଖିବା- ଏସବୁ ବ୍ରାହ୍ମଣଙ୍କର ସ୍ଵାଭାବିକ କର୍ମ ଅଟେ । 18.42",

    "ଶୂରବୀରତା, ତେଜ, ଧୈର୍ଯ୍ୟ, ପ୍ରଜାଙ୍କ ପରିଚାଳନା ଆଦିରେ ବିଶେଷ ଚତୁରତା, ଯୁଦ୍ଧ ରେ କଦାପି ପଛଘୁଞ୍ଚା ନ ଦେବା, ଦାନ କରିବା ଓ ଶାସନ କରିବାର ଭାବ ରହିବା କ୍ଷତ୍ରିୟଙ୍କର ସ୍ଵାଭାବିକ କର୍ମ । 18.43",

    "କୃଷି କରିବା, ଗୋରକ୍ଷା କରିବା ଓ ବାଣିଜ୍ୟ କରିବା ବୈଶ୍ୟଙ୍କର ସ୍ଵାଭାବିକ କର୍ମ ତଥା ଚାରିବର୍ଣ୍ଣଙ୍କର ସେବା କରିବା ଶୂଦ୍ରଙ୍କର ମଧ୍ୟ ସ୍ଵାଭାବିକ କର୍ମ । 18.44",

    "ନିଜ ନିଜର କର୍ମ ରେ ପ୍ରୀତିପୂର୍ବକ ରଥ ମନୁଷ୍ୟ ସମ୍ୟକ୍ ସିଦ୍ଧି(ପରମାତ୍ମାଙ୍କୁ) ଲାଭ କରେ । ନିଜ କର୍ମ ରେ ଲାଗି ରହି ମନୁଷ୍ୟ ଯେପରି ସିଦ୍ଧି ପ୍ରାପ୍ତ ହୁଏ, ତାହା ତୁମେ ମୋ ଠାରୁ ଶୁଣ । 18.45",

    "ଯେଉଁ ପରମାତ୍ମାଙ୍କ ଠାରୁ ସମସ୍ତ ପ୍ରାଣୀଙ୍କର ଉତ୍ପତ୍ତି ହୁଏ ଏବଂ ଯାହାଙ୍କ  ଦ୍ୱାରା ସମଗ୍ର ସଂସାର ବ୍ୟାପ୍ତ, ମନୁଷ୍ୟ ନିଜ କର୍ମ ଦ୍ଵାରା ସେହି ପରମାତ୍ମାଙ୍କର  ପୂଜନ କରି ସିଦ୍ଧି ପ୍ରାପ୍ତ ହୁଏ । 18.46",

    "ଉତ୍ତମରୂପେ ଅନୁଷ୍ଠିତ ପରଧର୍ମ ଅପେକ୍ଷା ଗୁଣରହିତ ସ୍ୱଧର୍ମ ଶ୍ରେଷ୍ଠ । କାରଣ ସ୍ୱଭାବନିୟତ ସ୍ୱଧର୍ମ ରୂପକ କର୍ମ କରି ମନୁଷ୍ୟ ପାପଭାଗୀ ହୁଏ ନାହିଁ । 18.47",

    "ହେ କୁନ୍ତୀନନ୍ଦନ ! ଦୋଷଯୁକ୍ତ ହୋଇଥିଲେ ମଧ୍ୟ ସହଜକର୍ମ ତ୍ୟାଗ କରିବା ଉଚିତ୍ ନୁହେଁ; କାରଣ ଧୂଆଁରେ ଅଗ୍ନି ଆବୃତ ହୋଇଥିବା ପରି ସମସ୍ତ କର୍ମ କୌଣସି ନା କୌଣସି ଦୋଷରେ ଯୁକ୍ତ ଅଟେ । 18.48",

    "ଯାହାର ବୁଦ୍ଧି ସର୍ବତ୍ର ଆସକ୍ତିରହିତ, ଯେ ଶରୀରକୁ ବଶୀଭୂତ କରି ରଖିଛି, ଯେ ସ୍ପୃହାରହିତ, ସେହି ମନୁଷ୍ୟ ସାଂଖ୍ୟଯୋଗ ଦ୍ୱାରା ସର୍ବଶ୍ରେଷ୍ଠ ନୈଷ୍କର୍ମ୍ୟସିଦ୍ଧି ପ୍ରାପ୍ତ ହୁଏ । 18.49",

    "ହେ କୌନ୍ତେୟ ! ସିଦ୍ଧି(ଅନ୍ତଃକରଣ ଶୁଦ୍ଧି) ପ୍ରାପ୍ତ ସାଧକ ଜ୍ଞାନର  ପରାନିଷ୍ଠା ବ୍ରହ୍ମକୁ  ଯେପରି ପ୍ରାପ୍ତ ହୁଏ, ତାହା ତୁମେ ମୋ' ଠାରୁ ସଂକ୍ଷେପରେ ବୁଝ । 18.50",

    "ବିଶୁଦ୍ଧ (ସାତ୍ତ୍ୱିକୀ) ବୁଦ୍ଧିଯୁକ୍ତ, ବୈରାଗ୍ୟ ର ଆଶ୍ରିତ, ଏକାନ୍ତ ସେବୀ ଓ ନିୟମିତ ଭୋଜନ କରୁଥିବା ଯେଉଁ ସାଧକ ଧୈର୍ଯ୍ୟପୂର୍ବକ  ଇନ୍ଦ୍ରିୟଗଣଙ୍କୁ ନିୟମନ କରି, କାୟମନୋବାକ୍ୟକୁ ବଶୀଭୂତ କରି, ଶବ୍ଦାଦି ବିଷୟ ତ୍ୟାଗ କରି ଏବଂ ରାଗଦ୍ଵେଷ ପରିତ୍ୟାଗ କରି ନିରନ୍ତର ଧ୍ୟାନଯୋଗପରାୟଣ ହୋଇଯାଏ, ସେ ଅହଂକାର, ବଳ, ଦର୍ପ, କାମ, କ୍ରୋଧ ଓ  ପରିଗ୍ରହ  ତ୍ୟାଗପୂର୍ବକ ନିର୍ମମ ତଥା ଶାନ୍ତ ହୋଇ ବ୍ରହ୍ମପ୍ରାପ୍ତି ର ପାତ୍ର ହୋଇଯାଏ ।  18.51-18.53",

    "ବିଶୁଦ୍ଧ (ସାତ୍ତ୍ୱିକୀ) ବୁଦ୍ଧିଯୁକ୍ତ, ବୈରାଗ୍ୟ ର ଆଶ୍ରିତ, ଏକାନ୍ତ ସେବୀ ଓ ନିୟମିତ ଭୋଜନ କରୁଥିବା ଯେଉଁ ସାଧକ ଧୈର୍ଯ୍ୟପୂର୍ବକ  ଇନ୍ଦ୍ରିୟଗଣଙ୍କୁ ନିୟମନ କରି, କାୟମନୋବାକ୍ୟକୁ ବଶୀଭୂତ କରି, ଶବ୍ଦାଦି ବିଷୟ ତ୍ୟାଗ କରି ଏବଂ ରାଗଦ୍ଵେଷ ପରିତ୍ୟାଗ କରି ନିରନ୍ତର ଧ୍ୟାନଯୋଗପରାୟଣ ହୋଇଯାଏ, ସେ ଅହଂକାର, ବଳ, ଦର୍ପ, କାମ, କ୍ରୋଧ ଓ  ପରିଗ୍ରହ  ତ୍ୟାଗପୂର୍ବକ ନିର୍ମମ ତଥା ଶାନ୍ତ ହୋଇ ବ୍ରହ୍ମପ୍ରାପ୍ତି ର ପାତ୍ର ହୋଇଯାଏ ।  18.51-18.53",

    "ବିଶୁଦ୍ଧ (ସାତ୍ତ୍ୱିକୀ) ବୁଦ୍ଧିଯୁକ୍ତ, ବୈରାଗ୍ୟ ର ଆଶ୍ରିତ, ଏକାନ୍ତ ସେବୀ ଓ ନିୟମିତ ଭୋଜନ କରୁଥିବା ଯେଉଁ ସାଧକ ଧୈର୍ଯ୍ୟପୂର୍ବକ  ଇନ୍ଦ୍ରିୟଗଣଙ୍କୁ ନିୟମନ କରି, କାୟମନୋବାକ୍ୟକୁ ବଶୀଭୂତ କରି, ଶବ୍ଦାଦି ବିଷୟ ତ୍ୟାଗ କରି ଏବଂ ରାଗଦ୍ଵେଷ ପରିତ୍ୟାଗ କରି ନିରନ୍ତର ଧ୍ୟାନଯୋଗପରାୟଣ ହୋଇଯାଏ, ସେ ଅହଂକାର, ବଳ, ଦର୍ପ, କାମ, କ୍ରୋଧ ଓ  ପରିଗ୍ରହ  ତ୍ୟାଗପୂର୍ବକ ନିର୍ମମ ତଥା ଶାନ୍ତ ହୋଇ ବ୍ରହ୍ମପ୍ରାପ୍ତି ର ପାତ୍ର ହୋଇଯାଏ ।  18.51-18.53",

    "ବ୍ରହ୍ମରୂପ ହୋଇଥିବା ସେହି ପ୍ରସନ୍ନମନା ସାଧକ କାହାରି ପାଇଁ ଶୋକ କରେ ନାହିଁ କି କୌଣସି ଇଚ୍ଛା କରେ ନାହିଁ । ଏତାଦୃଶ ସମସ୍ତ ପ୍ରାଣୀଙ୍କ ଠାରେ ସମଭାବସମ୍ପନ୍ନ ସାଧକ ମୋର ପରାଭକ୍ତି ଲାଭ କରେ । 18.54",

    "ସେହି ପରାଭକ୍ତି ଦ୍ୱାରା ମୋତେ, 'ମୁଁ ଯେତେ ଓ ଯିଏ'- ଏ କଥା କୁ ସେ ତତ୍ତ୍ଵତଃ  ଜାଣିଯାଏ । ତା'ପରେ ମୋତେ ତତ୍ତ୍ଵତଃ ଜାଣି ତତ୍କାଳ ମୋ'ଠାରେ ପ୍ରବିଷ୍ଟ ହୁଏ । 18.55",

    "ମୋର ଆଶ୍ରିତ ଭକ୍ତ ସର୍ବଦା ସବୁ କର୍ମ କରି ମଧ୍ୟ ମୋ କୃପାରୁ ଶାଶ୍ଵତ ଅବିନାଶୀ ପଦ ପ୍ରାପ୍ତ ହୁଏ । 18.56",

    "ଚିତ୍ତ ରେ ସମସ୍ତ କର୍ମ ମୋତେ ଅର୍ପଣ କରି, ମତ୍ପରାୟଣ ହୋଇ ତଥା ସମତା ର ଆଶ୍ରୟ ନେଇ ନିରନ୍ତର ମଦ୍ଗତଚିତ୍ତ ହୋଇଯାଅ । 18.57",

    "ମଦ୍ଗତଚିତ୍ତ ହୋଇ ତୁମେ ମୋ କୃପାରୁ ସମସ୍ତ ବିଘ୍ନକୁ ଅତିକ୍ରମ କରାଯିବ ଏବଂ ଯଦି ତୁମେ ଅହଂକାରବଶତଃ ମୋ କଥା ନ ଶୁଣିବ, ତୁମର ପତନ ହେବ । 18.58",

    "ଅହଙ୍କାର ର ଆଶ୍ରୟ ନେଇ ତୁମେ ଯୁଦ୍ଧ କରିବ‌ ନାହିଁ ବୋଲି ଯାହା ମନସ୍ଥ କରିଛ, ତୁମର ଏହି ନିର୍ଣ୍ଣୟ ସମ୍ପୂର୍ଣ୍ଣ ମିଥ୍ୟା; କାରଣ ତୁମର କ୍ଷାତ୍ର ପ୍ରକୃତି ତୁମକୁ ଯୁଦ୍ଧ ରେ ନିୟୋଜିତ କରିଦେବ । 18.59",

    "ହେ କୁନ୍ତୀନନ୍ଦନ ! ନିଜ ସ୍ୱଭାବଜନିତ କର୍ମରେ ଆବଦ୍ଧ ହୋଇ ମୋହବଶତଃ ତୁମେ ଯେଉଁ ଯୁଦ୍ଧ କରିବାକୁ ଚାହୁଁନାହଁ, ତାହା ମଧ୍ୟ ତୁମେ (କ୍ଷାତ୍ରପ୍ରକୃତିର) ପରବଶ ହୋଇ କରିବ । 18.60",

    "ହେ ଅର୍ଜୁନ ! ଈଶ୍ୱର ସମସ୍ତ ପ୍ରାଣୀଙ୍କ ହୃଦୟରେ ବିରାଜମାନ ଏବଂ ସେ ନିଜ ମାୟା ଦ୍ୱାରା ଶରୀରରୂପୀ ଯନ୍ତ୍ରରେ ଆରୂଢ ପ୍ରାଣୀମାନଙ୍କୁ ( ସେମାନଙ୍କ ସ୍ୱଭାବ ଅନୁସାରେ) ଭ୍ରମଣ କରାଉଥା'ନ୍ତି । 18.61",

    "ହେ ଭାରତ ! ତୁମେ ସର୍ବୋତଭାବେ ସେହି ପରମେଶ୍ବରଙ୍କୁ ହିଁ ଶରଣ ଯାଅ ।ସେହି ପରମାତ୍ମାଙ୍କ କୃପାରୁ ତୁମେ ପରମ ଶାନ୍ତି ସନାତନ ପରମଧାମ ପ୍ରାପ୍ତ ହେବ । 18.62",

    "ଏପରିଭାବରେ ଗୋପନୀୟଠାରୁ ମଧ୍ୟ ଅତି ଗୋପନୀୟ ଏହି ଜ୍ଞାନ ମୁଁ ତୁମକୁ କହିଦେଲି । ବର୍ତ୍ତମାନ ତୁମେ ଏହି ରହସ୍ୟଯୁକ୍ତ ଜ୍ଞାନକୁ ପୂର୍ଣ୍ଣରୂପେ ଭଲଭାବରେ ବିଚାର କରି, ଯେପରି ଇଚ୍ଛା କରୁଛ, ସେପରି କର । 18.63",

    "ସକଳ ଗୋପନୀୟଠାରୁ ଅଧିକ ଗୋପନୀୟ ମୋର ପରମ ରହସ୍ୟଯୁକ୍ତ ବଚନ ତୁମେ ପୁଣି ଥରେ ଶୁଣ । ତୁମେ ମୋର ଅତିଶୟ ପ୍ରିୟ, ତେଣୁ ମୁଁ ତୁମକୁ ଏହି ପରମ ହିତକର ବଚନ ପୁନର୍ବାର କହିବି । 18.64",

    "ହେ ଅର୍ଜୁ୍ନ ! ତୁମେ ମୋଠାରେ ମନ ଲଗାଅ, ମୋର ଭକ୍ତ ହୋଇଯାଅ, ମୋର ପୂଜକ ହୋଇଯାଅ, ମୋତେ ପ୍ରଣାମ କର । ଏପରି କଲେ ତୁମେ ମୋତେ ହିଁ ପ୍ରାପ୍ତ ହେବ, ଏହା ମୁଁ ତୁମକୁ ସତ୍ୟ ପ୍ରତିଜ୍ଞା କରି କହୁଛି, କାରଣ ତୁମେ ମୋର ଅତ୍ୟନ୍ତ ପ୍ରିୟ । 18.65",

    "ସମସ୍ତ ଧର୍ମ ଅର୍ଥାତ୍ ସମସ୍ତ କର୍ତ୍ତବ୍ୟକର୍ମ ମୋଠାରେ ତ୍ୟାଗ କରିଦେଇ ତୁମେ ଏକମାତ୍ର ମୋର ଅର୍ଥାତ୍ ସର୍ବଶକ୍ତିମାନ୍ ସର୍ବାଧାର ପରମେଶ୍ୱରଙ୍କ ଶରଣକୁ ଆସିଯାଅ ।ମୁଁ ତୁମକୁ ସମସ୍ତ ପାପରୁ ମୁକ୍ତ କରିଦେବି, ତୁମେ ଶୋକ କର ନାହିଁ । 18.66",

    "ଏହି ଗୀତାରୂପକ ରହସ୍ୟମୟ ଉପଦେଶ କୌଣସି କାଳରେ ବି ତପସ୍ୟାବିହୀନ ମନୁଷ୍ୟକୁ କହିବା ଉଚ୍ଚିତ ନୁହେଁ, ଭକ୍ତିରହିତ ମନୁଷ୍ୟକୁ ଏବଂ ଶୁଣିବାକୁ ଅନିଚ୍ଛୁକ ମନୁଷ୍ୟକୁ ମଧ୍ୟ କହିବା ଉଚିତ ନୁହେଁ । ଯେ ମୋ ଠାରେ ଦୋଷଦୃଷ୍ଟି ରଖେ, ତାକୁ ତ କଦାପି କହିବା ଉଚିତ ନୁହେଁ । 18.67",

    "ଯେଉଁ ପୁରୁଷ ମୋ ଠାରେ ପରମପ୍ରେମ କରି ଏହି ପରମ ରହସ୍ୟଯୁକ୍ତ ଗୀତୋପଦେଶ ମୋର ଭକ୍ତମାନଙ୍କ ମଧ୍ୟରେ କହିବେ, ସେ ମୋତେ ହିଁ ପ୍ରାପ୍ତ ହେବେ, ଏଥିରେ କୌଣସି ସନ୍ଦେହ ନାହିଁ । 18.68",

    "ତାଙ୍କ ଠାରୁ ବଡ ହୋଇ ମୋର ପ୍ରିୟକାର୍ଯ୍ୟ କରୁଥିବା ବ୍ୟକ୍ତି ମନୁଷ୍ୟମାନଙ୍କ ମଧ୍ୟରେ ଆଉ କେହି ନାହିଁ , ତଥା ସମଗ୍ର ପୃଥିବୀରେ ତାଙ୍କ ଠାରୁ ବଳି ମୋର ଅନ୍ୟ କେହି ପ୍ରିୟ ବ୍ୟକ୍ତି ଭବିଷ୍ୟତରେ ମଧ୍ୟ ହେବ ନାହିଁ । 18.69",

    "ଯେଉଁ ପୁରୁଷ ଆମ ଦୁହିଙ୍କର ଏହି ଧର୍ମମୟ ସଂବାଦରୂପକ ଗୀତାଶାସ୍ତ୍ର ଅଧ୍ୟୟନ କରିବେ, ତାଙ୍କ ଦ୍ବାରା ମଧ୍ୟ ମୁଁ ଜ୍ଞାନଯଜ୍ଞରେ ପୂଜିତ ହେବି, ଏହା ମୋର ମତ । 18.70",

    "ଯେଉଁ ମନୁଷ୍ୟ ଶ୍ରଦ୍ଧାଯୁକ୍ତ ଏବଂ ଦୋଷଦୃଷ୍ଟିରହିତ ହୋଇ ଏହି ଗୀତାଶାସ୍ତ୍ର ଶ୍ରବଣମାତ୍ର କରିବେ, ସେ ମଧ୍ୟ ପାପମୁକ୍ତ ହୋଇ ଉତ୍ତମ କର୍ମକାରୀମାନଙ୍କର ଶ୍ରେଷ୍ଠ ଲୋକମାନଙ୍କୁ ପ୍ରାପ୍ତ ହେବେ । 18.71",

    "ହେ ପାର୍ଥ ! କଣ ଏହି ଗୀତାଶାସ୍ତ୍ରକୁ ତୁମେ ଏକାଗ୍ରଚିତ୍ତରେ ଶ୍ରବଣ କରିଛ ? ଏବଂ ହେ ଧନଞ୍ଜୟ ! କଣ ତୁମର ଅଜ୍ଞାନଜନିତ ମୋହ ନଷ୍ଟ ହୋଇଗଲା ? 18.72",

    "ଅର୍ଜୁନ କହିଲେ -- ହେ ଅଚ୍ୟୁତ ! ଆପଣଙ୍କ କୃପାରୁ ମୋର ମୋହ ନଷ୍ଟ ହୋଇଗଲା ଏବଂ ମୁଁ ସ୍ମୃତି ପାଇଗଲି, ମୁଁ ବର୍ତ୍ତମାନ ସମ୍ପୂର୍ଣ୍ଣ ନିଃସଂଶୟ ହୋଇଗଲି, ତେଣୁ ମୁଁ ଆପଣଙ୍କ ଆଜ୍ଞା ପାଳନ କରିବି । 18.73",

    "ସଞ୍ଜୟ କହିଲେ-- ଏହିପରି ମୁଁ ଶ୍ରୀବାସୁଦେବଙ୍କର ଏବଂ ମହାତ୍ମା ଅର୍ଜୁନଙ୍କର ଏହି ଅଦ୍ଭୁତ ରହସ୍ୟଯୁକ୍ତ, ରୋମାଞ୍ଚକର ସଂବାଦ ଶୁଣିଲି । 18.74",

    "ଶ୍ରୀବ୍ୟାସଦେବଙ୍କ କୃପାରୁ ଦିବ୍ୟଦୃଷ୍ଟି ପାଇ ମୁଁ ଏହି ପରମ ଗୋପନୀୟ ଯୋଗ ଅର୍ଜୁନଙ୍କୁ କହୁଥିବା ସ୍ୱୟଂ ଯୋଗେଶ୍ୱର ଭଗବାନ୍ ଶ୍ରୀକୃଷ୍ଣଙ୍କ ମୁଖରୁ ପ୍ରତ୍ୟକ୍ଷ ଶୁଣିଲି । 18.75",

    "ହେ ରାଜନ୍ ! ଭଗବାନ୍ ଶ୍ରୀକୃଷ୍ଣ ଏବଂ ଅର୍ଜୁନଙ୍କର ଏହି ରହସ୍ୟମୟ, କଲ୍ୟାଣକର ଏବଂ ଅଦ୍ଭୁତ ସଂବାଦ ସ୍ମରଣ କରି କରି ମୁଁ ବାରମ୍ବାର ହର୍ଷିତ ହେଉଛି । 18.76",

    "ହେ ରାାଜନ୍ ! ଶ୍ରୀହରିଙ୍କର ସେହି ଅତ୍ୟନ୍ତ ଅଦ୍ଭୁତ ରୂପ ସ୍ମରଣ କରି କରି ମୋର ଚିତ୍ତରେ ବଡ ବିସ୍ମୟ ଜାତ ହେଉଛି ଏବଂ ମୁଁ ବାରମ୍ବାର ହର୍ଷିତ ହେଉଛି । 18.77",

    "ହେ ରାଜନ୍ ! ଯେଉଁଠାରେ ଯୋଗେଶ୍ବର ଭଗବାନ ଶ୍ରୀକୃଷ୍ଣ ଅଛନ୍ତି ଏବଂ ଯେଉଁଠାରେ ଗାଣ୍ଡୀବଧନୁ ଧାରଣ କରିଥିବା ଅର୍ଜୁନ ଅଛନ୍ତି, ସେଠାରେ ଶ୍ରୀ, ବିଜୟ, ବିଭୂତି ଏବଂ ଅଟଳ ନୀତି ରହିଛି, ଏହା ମୋର ମତ । 18.78",

    "ଏହି ପ୍ରକାର ଓଁ ତତ୍ ସତ୍ -- ଏହି ଭଗବତ୍ ନାମର ଉଚ୍ଚାରଣପୂର୍ବକ ବ୍ରହ୍ମବିଦ୍ୟା ଓ ଯୋଗଶାସ୍ତ୍ରମୟ ଶ୍ରୀମଦ୍ଭଗବଦ୍ଗୀତା ଉପନିଷଦରୂପେ ଶ୍ରୀକୃଷ୍ଣ ଏବଂ ଅର୍ଜୁନଙ୍କ ସମ୍ବାଦରେ 'ମୋକ୍ଷସନ୍ନ୍ୟାସଯୋଗନାମକ' ଅଷ୍ଟାଦଶ ଅଧ୍ୟାୟ ପୂର୍ଣ୍ଣ ହେଲା"
  ]
  }
]

    export default GeetajiOdia