import { useState, useRef } from "react";
import ReactPlayer from "react-player";
import Button from "./Button";

const Audio = ({audioAdh, timeStart, timeStop}) => {
    const [playing, setPlaying] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const playerRef = useRef();

    const handleProgress = (secs) => {
      if (playerRef.current.player.isPlaying && (secs > timeStop)) {
        setPlaying(false)
        playerRef.current.seekTo(timeStart, "seconds");
      }
      if (playerRef.current.player.isPlaying && (secs < timeStart)) {
        playerRef.current.seekTo(timeStart, "seconds");
      }
      // console.log(timeToStop, secs, playerRef.current.player.isPlaying)
    };

    const onReady = () => {
        if (!isReady) {
          playerRef.current.seekTo(timeStart, "seconds");
          setIsReady(true);
        }
      };

    const handlePlay = () => {
      setPlaying(!playing)
    } 

  return (
    <div className="audioEle">
        {/* <h3>Play Audio</h3> */}
        <Button text = {playing ? 'Pause Audio' : 'Play Audio'} onClick={handlePlay} />
        <ReactPlayer
            ref={playerRef}
            url= {audioAdh}
            width="400px"
            height="30px"
            playing={playing}
            controls={true}
            muted={false}
            onReady={onReady}
            onProgress={(e) => handleProgress(e.playedSeconds)}
            onPlay={() => setPlaying(true)}
            onPause={() => setPlaying(false)}
        />
    </div>
  )
}

export default Audio
