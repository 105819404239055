import AdhyayaSelector from './AdhyayaSelector';
import WelcomeMsg from './WelcomeMsg';
import QRandMsg from './QRandMsg';
import SelectShlok from './SelectShlok';
import { useState } from "react";

const Practice = ({method}) => {

    const [selectedAdhyaya, setSelectedAdhyaya] = useState([]);
  
    function adhyaSelect(e){
        const options = [...e.target.selectedOptions];
        const values = options.map(option => option.value);
        setSelectedAdhyaya(values);
    }

    function select4Vrati(){
      setSelectedAdhyaya ([1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18])
   }

  return (
    <>
        <WelcomeMsg />
        <AdhyayaSelector
        selectedAdhyaya = {selectedAdhyaya}
        handleSelect ={adhyaSelect}
        selectAll={select4Vrati}
        />
        <SelectShlok
        selected = {selectedAdhyaya}
        process = {method}
        />
        <QRandMsg/>
    </>
  )
}

export default Practice

