let Geetajis = {}

    Geetajis['hindi'] = require("./GeetajiHindi").default;
    Geetajis['marathi'] = require("./GeetajiMarathi").default;
    Geetajis['gujarati'] = require("./GeetajiGujarati").default;
    Geetajis['english'] = require("./GeetajiEnglish").default;
    Geetajis['tamil'] = require("./GeetajiTamil").default;
    Geetajis['telugu'] = require("./GeetajiTelugu").default;
    Geetajis['kannada'] = require("./GeetajiKannada").default;
    Geetajis['bangla'] = require("./GeetajiBangla").default;
    Geetajis['odia'] = require("./GeetajiOdia").default;
    Geetajis['malayalam'] = require("./GeetajiMalayalam").default;
    Geetajis['nepali'] = require("./GeetajiNepali").default;

export default Geetajis
