
const AudioMark = {
    "adhyaya-1" : { '1' : [0, 31.5], '2' : [31.5, 48.5], '3' : [48.5, 63], '4' : [63,76.5], '5' : [76.5, 90.5], '6' : [90.5, 106], '7' : [106, 121], '8' : [121, 135], '9' : [135, 149.5], '10' : [149.5, 164], '11' : [164, 177], '12' : [177, 191.5], '13' : [191.5, 205.5], '14' : [205.5, 220.5], '15' : [220.5, 235], '16' : [235, 247], '17' : [247, 261], '18' : [261, 274], '19' : [274.5, 287], '20' : [287, 301.5], '21' : [301.5, 318], '22' : [318, 332], '23' : [332, 347], '24' : [347, 363], '25' : [363, 377], '26' : [377, 392.5], '27' : [392.5, 406], '28' : [406, 421.5], '29' : [421.5, 434], '30' : [434, 448], '31' : [448, 462], '32' : [462, 476], '33' : [476, 491.5], '34' : [491.5, 506.5], '35' : [506.5, 521], '36' : [521, 536], '37' : [536, 550.5], '38' : [550.5, 564], '39' : [564, 578], '40' : [578, 592.5], '41' : [592.5, 607], '42' : [607, 621.5], '43' : [621.5, 637], '44' : [637, 650], '45' : [650, 664], '46' : [664, 678], '47' : [678, 695], '48' : [695, 724]
    },
    "adhyaya-2" : { '1' : [0, 35], '2' : [35, 53], '3' : [53, 69], '4' : [69,86.5], '5' : [86.5, 107], '6' : [107, 128], '7' : [128, 149.5], '8' : [149.5, 169], '9' : [169, 186], '10' : [186, 199], '11' : [199, 218], '12' : [218, 232.5], '13' : [232.5, 246.5], '14' : [246.5, 262], '15' : [262, 275], '16' : [275, 289], '17' : [289, 301.5], '18' : [301.5, 316], '19' : [316, 330.5], '20' : [330.5, 350], '21' : [350, 363],  '22' : [363, 383], '23' : [383, 397.5], '24' : [397.5, 413], '25' : [413, 427.5], '26' : [427.5, 442.5], '27' : [442.5, 456.5], '28' : [456.5, 471], '29' : [471, 490], '30' : [490, 505], '31' : [505, 518.5], '32' : [518.5, 533.5], '33' : [533.5, 547.5], '34' : [547.5, 561], '35' : [561, 576.5], '36' : [576.5, 591], '37' : [591, 606], '38' : [606, 621], '39' : [621, 637], '40' : [637, 651], '41' : [651, 665], '42' : [665, 680], '43' : [680, 693.5], '44' : [693.5, 708], '45' : [708, 723.5], '46' : [723.5, 737.5], '47' : [737.5, 751.5], '48' : [751.5, 767], '49' : [767, 781], '50' : [781, 795.5], '51' : [795.5, 809.5], '52' : [809.5, 823.5], '53' : [823.5, 837.5], '54' : [837.5, 855.5], '55' : [855.5, 874.5], '56' : [874.5, 888.5], '57' : [888.5, 904], '58' : [904, 920], '59' : [920, 934.5], '60' : [934.5, 949], '61' : [949, 964], '62' : [964, 980.5], '63' : [980.5, 996], '64' : [996, 1010], '65' : [1010, 1024], '66' : [1024, 1039.5], '67' : [1039.5, 1052.5], '68' : [1052.5, 1069], '69' : [1069, 1085], '70' : [1085, 1105.5], '71' : [1105.5, 1119], '72' : [1119, 1134.5], '73' : [1134.5, 1165]
    },
    "adhyaya-3" : { '1' : [6, 38.5], '2' : [38.5, 53.5], '3' : [53.5, 73], '4' : [73,86.5], '5' : [86.5, 99.7], '6' : [99.7, 115.2], '7' : [115.2, 129], '8' : [129, 143], '9' : [143, 158.6], '10' : [158.6, 173.5], '11' : [173.5, 188.5], '12' : [188.5, 207], '13' : [207, 222.5], '14' : [222.5, 237], '15' : [237, 252], '16' : [252, 265.8], '17' : [265.8, 280.8], '18' : [280.8, 295.5], '19' : [295.5, 310], '20' : [310, 323.4], '21' : [323.4, 337], '22' : [337, 350.8], '23' : [350.8, 365.8], '24' : [365.8, 381.2], '25' : [381.2, 398], '26' : [398, 413.3], '27' : [413.3, 427.8], '28' : [427.8, 441.9], '29' : [441.9, 455.7], '30' : [455.7, 471.9], '31' : [471.9, 487.2], '32' : [487.2, 502.5], '33' : [502.5, 517.4], '34' : [517.4, 532.5], '35' : [532.5, 546.3], '36' : [546.3, 563.6], '37' : [563.6, 581.5], '38' : [581.5, 597], '39' : [597, 612.6], '40' : [612.6, 627.3], '41' : [627.3, 641.7], '42' : [641.7, 656.4], '43' : [656.4, 672.7], '44' : [672.7, 710]
    },
    "adhyaya-4" : { '1' : [0, 34.5], '2' : [34.5, 49.3], '3' : [49.3, 65.5], '4' : [65.5,82.8], '5' : [82.8, 99.8], '6' : [99.8, 115.3], '7' : [115.3, 129.6], '8' : [129.6, 144.8], '9' : [144.8, 160.3], '10' : [160.3, 176.4], '11' : [176.4, 191.7], '12' : [191.7, 206.7], '13' : [206.7, 220], '14' : [220, 234], '15' : [234, 249.2], '16' : [249.2, 265.5], '17' : [265.5, 280.2], '18' : [280.2, 293.5], '19' : [293.5, 310.3], '20' : [310.3, 325.7], '21' : [325.7, 341.2], '22' : [341.2, 356.5], '23' : [356.5, 371], '24' : [371, 386.2], '25' : [386.2, 400.6], '26' : [400.6, 415.8], '27' : [415.8, 430.7], '28' : [430.7, 447.9], '29' : [447.9, 464], '30' : [464, 479.3], '31' : [479.3, 494.5], '32' : [494.5, 510.5], '33' : [510.5, 526.4], '34' : [526.4, 541.8], '35' : [541.8, 557.7], '36' : [557.7, 572.3], '37' : [572.3, 587.4], '38' : [587.4, 600.6], '39' : [600.6, 616], '40' : [616, 631.5], '41' : [631.5, 645.8], '42' : [645.8, 662.8], '43' : [662.8, 695]
    },
    "adhyaya-5" : { '1' : [0, 37.8], '2' : [37.8, 58.5], '3' : [58.5, 75], '4' : [75,91.8], '5' : [91.8, 109.2], '6' : [109.2, 124.5], '7' : [124.5, 140.7], '8' : [140.7, 156.5], '9' : [156.5, 171.2], '10' : [171.2, 187.5], '11' : [187.5, 202.6], '12' : [202.6, 219], '13' : [219, 235.5], '14' : [235.5, 250.8], '15' : [250.8, 267.4], '16' : [267.4, 284], '17' : [284, 301.8], '18' : [301.8, 316.7], '19' : [316.7, 335], '20' : [335, 352.4], '21' : [352.4, 369.5], '22' : [369.5, 386.4], '23' : [386.4, 403.4], '24' : [403.4, 420.9], '25' : [420.9, 438], '26' : [438, 453.6], '27' : [453.6, 471.8], '28' : [471.8, 487.5], '29' : [487.5, 503.5], '30' : [503.5, 535]
    },
    "adhyaya-6" : { '1' : [0, 37.5], '2' : [37.5, 53.5], '3' : [53.5, 68.7], '4' : [68.7,84.2], '5' : [84.2, 100.5], '6' : [100.5, 117], '7' : [117, 133.5], '8' : [133.5, 150.5], '9' : [150.5, 166.5], '10' : [166.5, 182.6], '11' : [182.6, 198.5], '12' : [198.5, 214.8], '13' : [214.8, 231], '14' : [231, 248], '15' : [248, 264.4], '16' : [264.4, 281], '17' : [281, 297.5], '18' : [297.5, 313.6], '19' : [313.6, 330.3], '20' : [330.3, 345.6], '21' : [345.6, 361], '22' : [361, 377.8], '23' : [377.8, 394.9], '24' : [394.9, 411.2], '25' : [411.2, 426.4], '26' : [426.4, 440.3], '27' : [440.3, 454.9], '28' : [454.9, 471], '29' : [471, 486.7], '30' : [486.7, 501.7], '31' : [501.7, 517.4], '32' : [517.4, 532.9], '33' : [532.9, 552], '34' : [552, 566.3], '35' : [566.3, 585.9], '36' : [585.9, 602.1], '37' : [602.1, 620.3], '38' : [620.3, 635.3], '39' : [635.3, 652.3], '40' : [652.3, 671], '41' : [671, 689], '42' : [689, 704], '43' : [704, 718.4], '44' : [718.4, 734.8], '45' : [734.8, 750], '46' : [750, 767.6], '47' : [767.6, 784.6], '48' : [784.6, 816]
    },
    "adhyaya-7" : { '1' : [0, 38], '2' : [38, 56.5], '3' : [56.5, 72.3], '4' : [72.3,88], '5' : [88, 103.6], '6' : [103.6, 118.5], '7' : [118.5, 132.8], '8' : [132.8, 148.7], '9' : [148.7, 165.2], '10' : [165.2, 181.8], '11' : [181.8, 197.3], '12' : [197.3, 213.7], '13' : [213.7, 229], '14' : [229, 245.7], '15' : [245.7, 263.5], '16' : [263.5, 280], '17' : [280, 296.8], '18' : [296.8, 314.5], '19' : [314.5, 330.8], '20' : [330.8, 349.5], '21' : [349.5, 366.2], '22' : [366.2, 382], '23' : [382, 398.3], '24' : [398.3, 414.7], '25' : [414.7, 432], '26' : [432, 447.8], '27' : [447.8, 464], '28' : [464, 481.7], '29' : [481.7, 498.1], '30' : [498.1, 514.3], '31' : [514.3, 545]
    },
    "adhyaya-8" : { '1' : [0, 34.1], '2' : [34.1, 49.5], '3' : [49.5, 68.9], '4' : [68.9,84.1], '5' : [84.1, 99.8], '6' : [99.8, 115.7], '7' : [115.7, 131.1], '8' : [131.1, 146.6], '9' : [146.6, 167.7], '10' : [167.7, 189.1], '11' : [189.1, 210.9], '12' : [210.9, 227.3], '13' : [227.3, 243], '14' : [243, 259], '15' : [259, 275.2], '16' : [275.2, 290.7], '17' : [290.7, 307], '18' : [307, 324], '19' : [324, 340.6], '20' : [340.6, 357.2], '21' : [357.2, 372.7], '22' : [372.7, 388.4], '23' : [388.4, 404.8], '24' : [404.8, 420.7], '25' : [420.7, 437.4], '26' : [437.4, 453.7], '27' : [453.7, 469.9], '28' : [469.9, 492], '29' : [492, 524]
    },
    "adhyaya-9" : { '1' : [0, 34.8], '2' : [34.8, 48.6], '3' : [48.6, 63.8], '4' : [63.8,78], '5' : [78, 92.6], '6' : [92.6, 107.7], '7' : [107.7, 122.2], '8' : [122.2, 135.9], '9' : [135.9, 149.2], '10' : [149.2, 162.3], '11' : [162.3, 176.8], '12' : [176.8, 192.8], '13' : [192.8, 208.3], '14' : [208.3, 223.5], '15' : [223.5, 238.9], '16' : [238.9, 252], '17' : [252, 266.4], '18' : [266.4, 280], '19' : [280, 293.9], '20' : [293.9, 317], '21' : [317, 339], '22' : [339, 354.5], '23' : [354.5, 369.2], '24' : [369.2, 382.4], '25' : [382.4, 398], '26' : [398, 411.3], '27' : [411.3, 424], '28' : [424, 437.8], '29' : [437.8, 452.6], '30' : [452.6, 466], '31' : [466, 479.8], '32' : [479.8, 495.6], '33' : [495.6, 510], '34' : [510, 524.6], '35' : [524.6, 555]
    },
    "adhyaya-10" : { '1' : [0, 33.6], '2' : [33.6, 48], '3' : [48, 62.7], '4' : [62.7,77.3], '5' : [77.3, 93], '6' : [93, 108.9], '7' : [108.9, 124.5], '8' : [124.5, 138.8], '9' : [138.8, 152.8], '10' : [152.8, 166.6], '11' : [166.6, 182], '12' : [182, 198.6], '13' : [198.6, 213], '14' : [213, 227.4], '15' : [227.4, 241.2], '16' : [241.2, 256.6], '17' : [256.6, 271], '18' : [271, 285], '19' : [285, 303.4], '20' : [303.4, 317.7], '21' : [317.7, 331.5], '22' : [331.5, 346.5], '23' : [346.5, 361.6], '24' : [361.6, 375.3], '25' : [375.3, 389.8], '26' : [389.8, 405.3], '27' : [405.3, 419.8], '28' : [419.8, 434.2], '29' : [434.2, 448.3], '30' : [448.3, 463.4], '31' : [463.4, 477.4], '32' : [477.4, 493], '33' : [493, 508.5], '34' : [508.5, 523.7], '35' : [523.7, 539.3], '36' : [539.3, 553.4], '37' : [553.4, 568.4], '38' : [568.4, 582.8], '39' : [582.8, 596.7], '40' : [596.7, 612.3], '41' : [612.3, 626.5], '42' : [626.5, 641.2], '43' : [641.2, 670]
    },
    "adhyaya-11" : { '1' : [0, 32.3], '2' : [32.3, 46.5], '3' : [46.5, 61.5], '4' : [61.5,75.5], '5' : [75.5, 92.4], '6' : [92.4, 106.7], '7' : [106.7, 120.3], '8' : [120.3, 135], '9' : [135, 152.5], '10' : [152.5, 165.4], '11' : [165.4, 178.7], '12' : [178.7, 192.7], '13' : [192.7, 206.5], '14' : [206.5, 219.7], '15' : [219.7, 242.5], '16' : [242.5, 262.4], '17' : [262.4, 282.8], '18' : [282.8, 301.2], '19' : [301.2, 320.2], '20' : [320.2, 341], '21' : [341, 361.5], '22' : [361.5, 382.6], '23' : [382.6, 403], '24' : [403, 423.2], '25' : [423.2, 442.3], '26' : [442.3, 463.6], '27' : [463.6, 484], '28' : [484, 503], '29' : [503, 523.6], '30' : [523.6, 543.8], '31' : [543.8, 563.9], '32' : [563.9, 588.4], '33' : [588.4, 609], '34' : [609, 629.6], '35' : [629.6, 652.6], '36' : [652.6, 676.1], '37' : [676.1, 695.3], '38' : [695.3, 714.2], '39' : [714.2, 733.3], '40' : [733.3, 753], '41' : [753, 772.7], '42' : [772.7, 793.6], '43' : [793.6, 814], '44' : [814, 834], '45' : [834, 853.6], '46' : [853.6, 873.9], '47' : [873.9, 897.3], '48' : [897.3, 918.3], '49' : [918.3, 939.6], '50' : [939.6, 964.2], '51' : [964.2, 982.8], '52' : [982.8, 1001.1], '53' : [1001.1, 1016.4], '54' : [1016.4, 1031.4], '55' : [1031.4, 1046.8], '56' : [1046.8, 1080]
    },
    "adhyaya-12" : { '1' : [0, 35.8], '2' : [35.8, 55.1], '3' : [55.1, 69.7], '4' : [69.7,85.5], '5' : [85.5, 100.6], '6' : [100.6, 116], '7' : [116, 131.6], '8' : [131.6, 145.2], '9' : [145.2, 159.7], '10' : [159.7, 173.4], '11' : [173.4, 187.3], '12' : [187.3, 203], '13' : [203, 216.5], '14' : [216.5, 231.4], '15' : [231.4, 248.4], '16' : [248.4, 263.6], '17' : [263.6, 277.5], '18' : [277.5, 292], '19' : [292, 306.3], '20' : [306.3, 321.8], '21' : [321.8, 352]
    },
    "adhyaya-13" : { '1' : [0, 36.2], '2' : [36.2, 53], '3' : [53, 68], '4' : [68,83], '5' : [83, 98.8], '6' : [98.8, 115.5], '7' : [115.5, 130.9], '8' : [130.9, 145.5], '9' : [145.5, 158.8], '10' : [158.8, 172], '11' : [172, 188], '12' : [188, 203], '13' : [203, 216.5], '14' : [216.5, 231], '15' : [231, 245.8], '16' : [245.8, 259], '17' : [259, 273.2], '18' : [273.2, 290], '19' : [290, 304.1], '20' : [304.1, 318.3], '21' : [318.3, 331.8], '22' : [331.8, 347.3], '23' : [347.3, 361.1], '24' : [361.1, 376.1], '25' : [376.1, 391.1], '26' : [391.1, 406], '27' : [406, 419.5], '28' : [419.5, 433.2], '29' : [433.2, 446.5], '30' : [446.5, 459.6], '31' : [459.6, 474.4], '32' : [474.4, 489.7], '33' : [489.7, 504.2], '34' : [504.2, 519.7], '35' : [519.7, 550]
    },
    "adhyaya-14" : { '1' : [0, 37.8], '2' : [37.8, 53.3], '3' : [53.3, 67.2], '4' : [67.2,82.7], '5' : [82.7, 96], '6' : [96, 109.3], '7' : [109.3, 123.2], '8' : [123.2, 136.3], '9' : [136.3, 149.7], '10' : [149.7, 163.4], '11' : [163.4, 177.6], '12' : [177.6, 191.6], '13' : [191.6, 206], '14' : [206, 219.2], '15' : [219.2, 231.7], '16' : [231.7, 245], '17' : [245, 260.4], '18' : [260.4, 276.1], '19' : [276.1, 290.7], '20' : [290.7, 304.8], '21' : [304.8, 322.1], '22' : [322.1, 339.5], '23' : [339.5, 353.7], '24' : [353.7, 367.9], '25' : [367.9, 382.9], '26' : [382.9, 397.6], '27' : [397.6, 412.5], '28' : [412.5, 442]
    },
    "adhyaya-15" : { '1' : [0, 33.5], '2' : [33.5, 53.5], '3' : [53.5, 73.5], '4' : [73.5,92.6], '5' : [92.6, 113.7], '6' : [113.7, 128.4], '7' : [128.4, 143], '8' : [143, 158.2], '9' : [158.2, 172.1], '10' : [172.1, 187.8], '11' : [187.8, 203.6], '12' : [203.6, 217.7], '13' : [217.7, 232.7], '14' : [232.7, 247.6], '15' : [247.6, 267.8], '16' : [267.8, 282.4], '17' : [282.4, 296.9], '18' : [296.9, 311.7], '19' : [311.7, 325.7], '20' : [325.7, 339.8], '21' : [339.8, 370]
    },
    "adhyaya-16" : { '1' : [0, 32.8], '2' : [32.8, 48.2], '3' : [48.2, 63], '4' : [63,78], '5' : [78, 93], '6' : [93, 107.9], '7' : [107.9, 122.5], '8' : [122.5, 135.4], '9' : [135.4, 150.2], '10' : [150.2, 166.4], '11' : [166.4, 181.4], '12' : [181.4, 197.3], '13' : [197.3, 209.7], '14' : [209.7, 223.9], '15' : [223.9, 239.5], '16' : [239.5, 253.8], '17' : [253.8, 268.9], '18' : [268.9, 284.4], '19' : [284.4, 298.1], '20' : [298.1, 312.8], '21' : [312.8, 327], '22' : [327, 341.8], '23' : [341.8, 355.7], '24' : [355.7, 371], '25' : [371, 401]
    },
    "adhyaya-17" : { '1' : [0, 33.4], '2' : [33.4, 50.9], '3' : [50.9, 65.3], '4' : [65.3,82], '5' : [82, 97.5], '6' : [97.5, 112.3], '7' : [112.3, 125.8], '8' : [125.8, 142.5], '9' : [142.5, 157.5], '10' : [157.5, 170.2], '11' : [170.2, 183.6], '12' : [183.6, 196.7], '13' : [196.7, 210], '14' : [210, 223.9], '15' : [223.9, 238], '16' : [238, 252], '17' : [252, 265.4], '18' : [265.4, 279.2], '19' : [279.2, 293.8], '20' : [293.8, 308.1], '21' : [308.1, 321.9], '22' : [321.9, 335.5], '23' : [335.5, 350.2], '24' : [350.2, 365.6], '25' : [365.6, 380], '26' : [380, 395.2], '27' : [395.2, 408.7], '28' : [408.7, 422.5], '29' : [422.5, 451]
    },
    "adhyaya-18" : { '1' : [0, 35.5], '2' : [35.5, 55.6], '3' : [55.6, 71], '4' : [71,85.7], '5' : [85.7, 100.7], '6' : [100.7, 115.6], '7' : [115.6, 129.5], '8' : [129.5, 143.7], '9' : [143.7, 159.4], '10' : [159.4, 174.6], '11' : [174.6, 189.3], '12' : [189.3, 203.4], '13' : [203.4, 218.3], '14' : [218.3, 232.5], '15' : [232.5, 247.5], '16' : [247.5, 261.1], '17' : [261.1, 274.9], '18' : [274.9, 289.2], '19' : [289.2, 303.4], '20' : [303.4, 318.3], '21' : [318.3, 333],  '22' : [333, 346.4], '23' : [346.4, 360], '24' : [360, 374.1], '25' : [374.1, 388.7], '26' : [388.7, 403], '27' : [403, 418.1], '28' : [418.1, 432.7], '29' : [432.7, 447.2], '30' : [447.2, 462.2], '31' : [462.2, 476.6], '32' : [476.6, 491.1], '33' : [491.1, 506.3], '34' : [506.3, 520.8], '35' : [520.8, 534.5], '36' : [534.5, 548.1], '37' : [548.1, 562.1], '38' : [562.1, 575.2], '39' : [575.2, 589.5], '40' : [589.5, 603.4], '41' : [603.4, 617.5], '42' : [617.5, 632], '43' : [632, 647], '44' : [647, 661.6], '45' : [661.6, 674.3], '46' : [674.3, 688.5], '47' : [688.5, 702.6], '48' : [702.6, 717.3], '49' : [717.3, 730.6], '50' : [730.6, 746], '51' : [746, 761.7], '52' : [761.7, 776.2], '53' : [776.2, 789.7], '54' : [789.7, 803.7], '55' : [803.7, 818.5], '56' : [818.5, 832.4], '57' : [832.4, 846.2], '58' : [846.2, 859.5], '59' : [859.5, 873.2], '60' : [873.2, 886.8], '61' : [886.8, 901.1], '62' : [901.1, 915.4], '63' : [915.4, 928.6], '64' : [928.6, 941.3], '65' : [941.3, 955.7], '66' : [955.7, 970.9], '67' : [970.9, 985.1], '68' : [985.1, 999.5], '69' : [999.5, 1013], '70' : [1013, 1027.4], '71' : [1027.4, 1042], '72' : [1042, 1056.2],  '73' : [1056.2, 1073], '74' : [1073, 1089.6], '75' : [1089.6, 1104.2], '76' : [1104.2, 1118.2], '77' : [1118.2, 1133.1], '78' : [1133.1, 1147.5],'79' : [1162, 1190]
    },
}

export default AudioMark
