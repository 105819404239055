const TextTelugu = {
Home:
    {
    WelMsg: "లర్న్ గీతా కంఠస్థీకరణ సాధనా పేజి",
    chooseLang: "మీ బాషను ఎంచుకోండి:",
    greetMsg: "గీతా పరివార్ మీకు స్వాగతం పలుకుతుంది ",
    linkBasic1: " సాధారణ అభ్యాసం",
    linkBasic2:" చరణం వారీగా",
    startPractice: " మనం అభ్యాసం మొదలుపెడదాం",
    linkAdv1:" ముందస్తు సాధన",
    linkAdv2:"శ్లోకాంక",
    linkSerialWise: "క్రమానుసారంగా నేర్చుకోండి",
    alert: "ప్రస్తుతం ఈ బాష అందుబాటులో లేదు "
    },
Practice:
    {
    WelMsg: " శ్రీ మద్భగవద్గీతా కంఠస్థీకరణ సహాయ పేజి",
    selAdh: " ఒకటి/అనేక అధ్యాయాలు ఎంచుకోండి:",
    or: "లేదా",
    selectAll: "అన్ని అధ్యాయాలను ఎంచుకోండి",
    selectdAdh: "ఎంచుకున్న అధ్యాయం",
    clkBtn: " యాదృచ్ఛిక శ్లోక సంఖ్య ఎంపికకు బటన్ క్లిక్ చేయండి.",
    alert: " కనీసం ఒక అధ్యాయం ఎంపిక చేయండి",
    meaningMsg: " ఈ శ్లోకం యొక్క అర్థం ఇప్పుడు అందుబాటులో లేదు, తిరిగి సమకూర్చేవరకు ప్రతీక్షించండి",
    shlokNo: " శ్లోక సంఖ్య",
    shlokCharan: "శ్లోక -చరణం",
    showAnswer: " జవాబు తెలుపండి ",
    showMeaning: " అర్థం తెలుపండి",
    adhyaya: "అధ్యాయం"
    },
Nums:
    {
    num1: "1",
    num2: "2",
    num3: "3",
    num4: "4",
    num5: "5",
    num6: "6",
    num7: "7",
    num8: "8",
    num9: "9",
    num10: "10",
    num11: "11",
    num12: "12",
    num13: "13",
    num14: "14",
    num15: "15",
    num16: "16",
    num17: "17",
    num18: "18"
    },
Score:
    {
    reportCard: "రిపోర్ట్ -కార్డ్",
    srNo: "క్ర. సంఖ్య.",
    adhyaya: " అధ్యాయం",
    shlokNo: " శ్లోక సంఖ్య.",
    time: "సమయం",
    },
Serialwise:
    {
    WelMsg: "శ్రీమద్భగవద్గీతా క్రమానుసారం నేర్చుకునే పేజి",
    },
}
export default TextTelugu