const GeetajiHindi = [
{
  "adhyaya-1" : [
    "ধৃতরাষ্ট্র উওয়াচ\nধর্মক্ষেত্রে কুরুক্ষেত্রে, সমবেতা য়ুয়ুৎসবঃ\nমামকা: (ফ্) পাণ্ডবাশ্চৈব, কিমকুর্বত সঞ্জয় ॥1.1॥",

    "সঞ্জয় উওয়াচ\nদৃষ্ট্বা তু পান্ডাবানীকং(ৱ্ঁ), ব্য়ূঢং (ন্) দুর্য়োধনস্তদা।\nআচার্য়মুপসঙ্গম্য, রাজা বচনমব্রবীৎ ॥1.2॥",

    "পশ্যৈতাং (ম্) পাণ্ডুপুত্রাণাম্, আচার্য় মহতীং (ঞ্) চমূম্\nব্য়ূঢাং(ন্) দ্রুপদপুত্রেণ, তব শিষ্য়েণ ধীমতা ॥1.3॥",

    "অত্র শূরা মহেষ্বাসা, ভীমার্জুনসমা য়ুধি\nয়ুয়ুধানো বিরাটশ্চ, দ্রুপদশ্চ মহারথ: ॥1.4॥",

    "ধ্রুষ্টকেতুশ্চেকিতানঃ(খ্), কাশিরাজশ্চ বীর্য়বান্\nপুরুজিত্কুন্তিভোজশ্চ, শৈব্যশ্চ নরপুঙ্গব: ॥1.5॥",

    "য়ুধামন্যুশ্চ বিক্রান্ত, উত্তমৌজাশ্চ বীর্য়বান্\nসৌভদ্রো দ্রৌপদেয়াশ্চ, সর্ব এব মহারথাঃ ॥1.6॥",

    "অস্মাকং(ন্) তু বিশিষ্টা য়ে, তান্নিবোধ দ্বিজোত্তম\nনায়কা মম সৈন্যস্য, সঞ্জ্ঞার্থং(ন্) তান্ব্রবীমি তে ॥1.7॥",

    "ভবান্ভীষ্মশ্চ কর্ণশ্চ, কৃপশ্চ সমিতিঞ্জয়ঃ\nঅশ্বত্থামা বিকর্ণশ্চ, সৌমদত্তিস্তথৈব চ ॥1.8॥",

    "অন্যে চ বহবঃ(শ্), শূরা মদর্থে ত্যক্তজীবিতাঃ\nনানাশস্ত্রপ্রহরণা:(স্), সর্বে য়ুদ্ধবিশারদাঃ ॥1.9॥",

    "অপর্য়াপ্তং(ন্) তদস্মাকং(ম্), বলং(ম্)  ভীষ্মাভিরক্ষিতম্\nপর্য়াপ্তং(ন্) ত্বিদমেতেষাং (ম্), বলং (ম্) ভীমাভিরক্ষিতম্ ॥1.10॥",

    "অয়নেষু চ সর্বেষু, যথাভাগমবস্থিতাঃ\nভীষ্মমেবাভিরক্ষন্তু, ভবন্তঃ(স্) সর্ব এব হি ॥1.11॥",

    "তস্য সঞ্জনয়ন্হর্ষং (ঙ্), কুরুবৃব্ধঃ:(ফ্) পিতামহঃ। \nসিংহনাদং(ৱ্ঁ) বিনদ্যোচ্চৈ:(শ্), শঙ্খং(ন্) দধ্মৌ প্রতাপবান্ ॥1.12॥",

    "ততঃ(শ্) শঙ্খাশ্চ ভের্য়শ্চ, পণবানকগোমুখাঃ\nসহসৈবাভ্যহন্য়ন্ত,স শব্দস্তুমুলোsভবত্ ॥1.13॥",

    "ততঃ(শ্) শ্বেতৈর্হয়ৈর্য়ুক্তে, মহতি স্পন্দনে স্থিতৌ\nমাধব:(ফ্) পাণ্ডবশ্চৈব, দিব্যৌ শংখৌ প্রদধ্মতু: ॥1.14॥",

    "পাঞ্চজন্যং (ম্) হৃষীকেশো, দেবদত্তং(ন্) ধনঞ্জয়ঃ\nপৌণ্ড্রং(ন্) দধ্মৌ মহাশঙ্খং(ম্), ভীমকর্মা বৃকোদর: ॥1.15॥",

    "অনন্তবিজয়ং (ম্) রাজা, কুন্তীপুত্রো যুধিষ্ঠিরঃ\nনকুল: (স্) সহদেবশ্চ, সুঘোষমণিপুষ্পকৌ ॥1.16॥",

    "কাশ্যশ্চ পরমেষ্বাসঃ (শ্), শিখণ্ডী চ মহারথঃ\nধৃষ্টদ্যুম্নো বিরাটশ্চ, সাত্যকিশ্চাপরাজিতঃ ॥1.17॥",

    "দ্রুপদো দ্রৌপদেয়াশ্চ, সর্বশঃ(ফ্) পৃথিবীপতে\nসৌভদ্রশ্চ মহাবাহু:(শ্), শঙ্খান্দধ্মুঃ(ফ্) পৃথক্ পৃথক্ ॥1.18॥",

    "স ঘোষো ধার্তরাষ্ট্রাণাং (ম্), হৃদয়ানি ব্যদারয়ত্\nনভশ্চ পৃথিবীং(ঞ) চৈব, তুমুলো ব্যনুনাদায়ন্  ॥1.19॥",

    "অথ ব্যবস্থিতান্ দৃষ্ট্বা, ধার্তরাষ্ট্রান্কপিধ্বজ:\nপ্রবৃত্তে শস্ত্রসম্পাতে, ধনুরুদ্যম্য পাণ্ডবঃ ॥1.20॥",

    "হৃষীকেশং(ন্) তদা বাক্যম্, ইদমাহ মহীপতে\nঅর্জুন উবাচ\nসেনয়োরুভয়োর্মধ্যে, রথং (ম্) স্থাপয় মেsচ্যুত  ॥1.21॥",

    "য়াবদেতান্নিরীক্ষেsহং (য়্ঁ), য়োদ্ধুকামানবস্থিতান্। \nকৈর্ময়া সহ য়োদ্ধব্যম্, অস্মিন্  রণসমুদ্যমে ॥1.22॥",

    "য়োত্স্যমানানবেক্ষেsহং (ম্), য় এতেsত্র সমাগতাঃ\nধার্তরাষ্ট্রস্য দুর্বুদ্ধে:(র্), য়ুদ্ধে প্রিয়চিকীর্ষবঃ ॥1.23॥",

    "সঞ্জয় উবাচ\nএবমুক্তো হৃষীকেশো, গুডাকেশেন ভারত\nসেনয়োরুভয়োর্মধ্যে, স্থাপয়িত্বা রথোত্তমম্ ॥1.24॥",

    "ভীষ্মদ্রোণপ্রমুখতঃ(স্), সর্বেষাং(ঞ্) চ মহীক্ষিতাম্\nউবাচ পার্থ পশ্যৈতান্, সমবেতান্কুরূনিতি ॥1.25॥",

    "তত্রাপশ্যত্স্থিতান্পার্থঃ(ফ্), পিতৃনথ পিতামহান্\nআচার্য়ান্মাতুলান্ভ্রাতৃন্, পুত্রান্পৌত্রান্সখীংস্তথা ॥1.26॥",

    "শ্বশুরান্সুহৃদশ্চৈব, সেনয়োরুভয়োরপি\nতান্সমীক্ষ্য স কৌন্তেয়:(স্), সর্বান্বন্ধূনবস্থিতান্ ॥1.27॥",

    "কৃপয়া পরয়াবিষ্টো, বিষীদন্নিদমব্রবীত্\nঅর্জুন উওয়াচ\nদৃষ্ট্বেমং স্বজনং(ঙ্) কৃষ্ণ, য়ুয়ুত্সুং (ম্) সমুপস্থিতম্  ॥1.28॥",

    "সীদন্তি মম গাত্রাণী, মুখং (ঞ্) চ পরিশুষ্যতি\nবেপথুশ্চ শরীরে মে, রোমহর্ষশ্চ জায়তে  ॥1.29॥",

    "গাণ্ডীবং(ম্) স্রংসতে হস্তাত্, ত্বক্চৈব পরিদহ্যতে\nন চ শক্নোম্যবস্থাতুং(ম্), ভ্রমতীব চ মে মনঃ ॥1.30॥",

    "নিমিত্তানি চ পশ্যামি, বিপরীতানি কেশব\nন চে শ্রেয়োsনুপশ্যামি, হত্বা স্বজনমাহবে  ॥1.31॥",

    "ন কাঙ্ক্ষে বিজয়ং(ঙ্) কৃষ্ণ, ন চ রাজ্যং(ম্) সুখানি চ\nকিং(ন্) নো রাজ্যেন গোবিন্দ, কিং(ম্) ভোগৈর্জীবিতেন বা ॥1.32॥",

    "য়েষামর্থে কাঙ্ক্ষিতং(ন্) নো, রাজ্যং(ম্) ভোগাঃ(স্) সুখানি চ\nত ইমেsবস্থিতা য়ুদ্ধে, প্রাণাংস্ত্যক্ত্বা ধনানি চ  ॥1.33॥",

    "আচার্য়া:(ফ্) পিতরঃ(ফ্) পুত্রাঃ(স্), তথৈব চ পিতামহাঃ\nমাতুলা:(শ্) শ্বশুরাঃ(ফ্) পৌত্রা:(শ্), শ্যালাঃ (স্) সম্বন্ধিনস্তথা ॥1.34॥",

    "এতান্ন হন্তুমিচ্ছামি, ঘ্নতোsপি  মধুসূদন\nঅপি ত্রৈলোক্যরাজ্যস্য, হেতোঃ(খ্) কিং(ন্) নু মহীকৃতে ॥1.35॥",

    "নিহত্য ধার্তরাষ্ট্রান্ন:(খ্), কা প্রীতি:(স্) স্য়াজ্জনার্দন\nপাপমেবাশ্রয়েদস্মান্, হত্বৈতানাততায়িন: ॥1.36॥",

    "তস্মান্নার্হা বয়ং(ম্) হন্তুং(ন্), ধার্তরাষ্টান্ স্ববান্ধবান্\nস্বজনং( ম্) হি কথং(ম্) হত্বা, সূখিন: (স্) স্য়াম মাধব ॥1.37॥",

    "যদ্যপ্যেতে ন পশ্যন্তি,লোভোপহতচেতসঃ\nকুলক্ষয়কৃতং(ন্) দোষং(ম্),মিত্রদ্রোহে চ পাতকম্ ॥1.38॥",

    "কথং(ন্) ন জ্ঞেয়মস্মাভি:(ফ্),পাপাদস্মান্নিবর্তিতুম\nকুলক্ষয়কৃতং(ন্) দোষং(ম্),প্রপশ্য়দ্ভির্জনার্দন ॥1.39॥",

    "কুলক্ষয়ে প্রণশ্য়ন্তি,কুলধর্মা:(স্)সনাতনা:\nধর্মে নষ্টে কুল্ং(ঙ্) কৃত্স্নম্, অধর্মোsভিভবত্য়ুত ॥1.40॥",

    "অধর্মাভিভবাত্কৃষ্ণ,প্রদুষ্য়ন্তি কুলস্ত্রিয়ঃ\nস্ত্রীষু দুষ্টাসু বার্ষ্ণেয়,জায়তে বর্ণসংকর: ॥1.41॥",

    "সংকরো নরকায়ৈব, কুলঘ্নানাং(ঙ্) কুলস‍্য চ।  \nপতন্তি পিতরো হ্য়েষাং(ম্),লুপ্তপিন্ডোদকক্রিয়াঃ ॥1.42॥",

    "দোষৈরেতৈ:(খ্) কুলঘ্নানাং(ৱ্ঁ),বর্ণসঙ্করকারকৈঃ। \nউত্সাদ্য়ন্তে জাতিধর্মা:(খ্),কুলধর্মাশ্চ শাশ্বতা: ॥1.43॥",

    "উত্সন্নকুলধর্মাণাং(ম্), মনুষ্য়ানাং(ঞ্) জনার্দন। \nনরকেsনিয়তং(ৱ্ঁ) বাসো,ভবতীত্য়নুশুশ্রুম ॥1.44॥",

    "অহো বত মহত্পাপং(ঙ্), কর্তুং(ৱ্ঁ) ব্য়বসিতা বয়ম্। \nয়দ্রাজ্য়সুখলোভেন, হন্তুং(ম্) স্বজনমুদ্য়তাঃ ॥1.45॥",

    "য়দি মামপ্রতীকারম্, অশস্ত্রং(ম্) শস্ত্রপাণয়: \nধার্তরাষ্ট্রা হন্য়ুস্, তন্মে ক্ষেমতরং(ম্) ভবেত্ ॥1.46॥",

    "সঞ্জয় উও্য়াচ\nএবমুক্ত্বার্জুনঃ(স্) সংখ্য়ে, রথোপস্থ উপাবিশত্ \nবিসৃজ্য় সশরং(ঞ্) চাপং(ম্), শোকসংবিগ্নমানস:॥1.47॥",

    "ওম্ তত্সদিতি শ্রীমদ্ভগবদ্গীতাসু উপনিষত্সু ব্রহ্মবিদ্য়ায়াং(ম্)\nয়োগশাস্ত্রে শ্রীকৃষ্ণার্জুনসংবাদে অর্জুনবিষাদয়োগো নাম প্রথমোsধ্য়ায়: ॥1॥"
  ],
  "adhyaya-2" : [
    "সঞ্জয় উবাচ\nতং(ন্) তথা কৃপয়াবিষ্টম্, অশ্রুপূর্ণাকুলেক্ষণম্।\nবিষীদন্তমিদং(ব্ঁ) বাক্য়ম্,উবাচ মধুসূদনঃ ॥2.1॥",

    "শ্রীভগবানুবাচ\nকুতস্ত্বা কশ্মলমিদং(ব্ঁ), বিষমে সমুপস্থিতম্।\nঅনার্য়জুষ্টমস্বর্গ্য়ম্ অকীর্তিকরমর্জুন ॥2.2॥",

    "ক্লৈব্য়ং(ম্) মা স্ম গমঃ(ফ্) পার্থ, নৈতত্ত্বয়্য়ুপপদ্য়তে \nক্ষুদ্রং(ম্) হৃদয়দৌর্বল্য়ং(ন্),ত্য়কেত্বাত্তিষ্ঠ  পরন্তপ ॥2.3॥",

    "অর্জুন উবাচ\nকথং(ম্) ভীষ্মমহং(ম্) সঙ্খ্য়ে, দ্রোণং(ঞ্) চ মধুসূদন\nইষুভিঃ( ফ্) প্রতিয়োত্স্য়ামি  পূজার্হাবরিসূদন ॥2.4॥",

    "গুরূনহত্বা হি মহানুভাবান্, \nশ্রেয়ো ভোক্তুং(ম্) ভৈক্ষ্য়মপীহ লোকে \nহত্বার্থকামাংস্তু গুরূনিহৈব, \nভুঞ্জীয় ভোগান্রুধিরপ্রদিগ্ধান্ ॥2.5॥",

    "ন চৈতদ্বিদ্মঃ(খ্) কতরন্নো গরীয়ো,\nয়দ্বা জয়েম য়দি বা নো জয়েয়ুঃ\nয়ানেব হত্বা ন জিজীবিষাম:(স্),\nতেsবস্থিতাঃ(ফ্) প্রমুখে ধার্তরাষ্ট্রাঃ ॥2.6॥",

    "কার্পণ্য়দোষোপহতস্বভাবঃ(ফ্),\nপৃচ্ছামি ত্বাং(ন্) ধর্মসম্মূঢ়চেতাঃ \nয়চ্ছ্রেয়(স্) স্য়ান্নিশ্চিতং(ম্)  ব্রুহি তন্মে, \nশিষ্য়স্তেsহং(ম্) শাধি মাং(ন্) ত্বাং(ম্) প্রপন্নম্ ॥2.7॥",

    "ন হি প্রপশ্য়ামি  মমাপনুদ্য়াদ্ \nয়চ্ছোকমুচ্ছোষণমিন্দ্রিয়াণাম্\nঅবাপ্য় ভূমাবসপত্নমৃদ্ধং(ম্)\nরাজ্য়ং(ম্) সুরাণামপি চাধিপত্য়ম্ ॥2.8॥",

    "সঞ্জয় উবাচ\nএবমুক্ত্বা হৃষীকেশং(ঙ্), গুড়াকেশঃ(ফ্) পরন্তপ \nন য়োত্স্য় ইতি গোবিন্দম্, উক্ত্বা তূষ্নীং(ম্) বভূব হ ॥2.9॥",

    "তমুবাচ হৃষীকেশঃ(ফ্), প্রহসন্নিব ভারত।\nসেনয়োরুভয়োর্মধ্য়ে  বিষীদন্তমিদং(ব্ঁ) বচঃ॥2.10॥",

    "শ্রীভগবানুবাচ\nঅশোচ্য়ানন্বশোচস্ত্বং(ম্), প্রজ্ঞাবাদাংশ্চ ভাষসে \nগতাসূনগতাসূংশ্চ, নানুশোচন্তি পণ্ডিতাঃ ॥2.11॥",

    "ন ত্বেবাহং(ঞ্)জাতু নাসং(ন্),ন ত্বং(ন্)নেমে জনাধিপাঃ \nন চৈব ন ভবিষ্য়ামঃ(স্), সর্বে বয়মতঃ(ফ্) পরম্  ॥2.12॥",

    "দেহিনোऽস্মিন্য়থা দেহে, কৌমারং(য়্ঁ) যৌবনং(ঞ্) জরা। \nতথা দেহান্তরপ্রাপ্তি:(র্), ধীরস্তত্র ন মুহ্য়তি ॥2.13॥",

    "মাত্রাস্পর্শাস্তু কৌন্তেয়,শীতোষ্ণসুখদুঃখদাঃ \nআগমাপায়িনো‌ऽনিত্য়া:(স্), তাংস্তিতিক্ষস্ব ভারত ॥2.14॥",

    "য়ং(ম্) হি ন ব্য়থয়ন্ত্য়েতে, পুরুষং(ম্) পুরুষর্ষভ \nসমদুঃখসুখং(ন্) ধীরং(ম্), সো‌ऽমৃতত্বায় কল্পতে ॥2.15॥",

    "নাসতো বিদ্য়তে ভাবো, নাভাবো বিদ্য়তে সতঃ \nউভয়োরপি দৃষ্টো‌ऽন্ত:(স্),ত্বনয়োস্তত্ত্বদর্শিভিঃ ॥2.16॥",

    "অবিনাশি তু তদ্বিদ্ধি, য়েন সর্বমিদং(ন্) ততম্ \nবিনাশমব্য়য়স্য়াস্য়, ন কশ্চিত্কর্তুমর্হতি ॥2.17॥",

    "অন্তবন্ত ইমে দেহা, নিত্য়স্য়োক্তাঃ(শ্) শরীরিণঃ \nঅনাশিনো‌ऽপ্রমেয়স্য়, তস্মাদ্য়ুধ্য়স্ব ভারত ॥2.18॥",

    "য় এনং(ব্ঁ) বেত্তি হন্তারং(য়্ঁ), য়শ্চৈনং(ম্) মন্য়তে হতম্ ।\nউভৌ তৌ ন বিজানীতো, নায়ং(ম্) হন্তি ন হন্য়তে ॥2.19॥",

    "ন জায়তে ম্রিয়তে বা কদাচিন্, \nনায়ং(ম্) ভূত্বা ভবিতা বা ন ভূয়ঃ \nঅজো নিত্য়ঃ(শ্) শাশ্বতো‌ऽয়ং(ম্) পুরাণো,\nন হন্য়তে হন্য়মানে শরীরে ॥2.20॥",

    "বেদাবিনাশিনং(ন্) নিত্য়ং(য়্ঁ), য় এনমজমব্য়য়ম্। \nকথং(ম্) স পুরুষঃ(ফ্)  পার্থ, কং(ঙ্) ঘাতয়তি হন্তি কম্ ॥2.21॥",

    "বাসাংসি জীর্ণানি য়থা বিহায়,\nনবানি গৃহ্ণাতি নরো‌ऽপরাণি \nতথা শরীরাণি বিহায় জীর্ণা-\nন্য়ন্য়ানি সংয়াতি নবানি দেহী ॥2.22॥",

    "নৈনং(ঞ্)ছিন্দন্তি শস্ত্রাণি,নৈনং(ন্) দহতি পাবকঃ \nন চৈনং(ঙ্) ক্লেদয়ন্ত্য়াপো, ন শোষয়তি মারুতঃ ॥2.23॥",

    "অচ্ছেদ্য়ো‌ऽয়মদাহ্য়ো‌ऽয়ম্, অক্লেদ্য়ো‌ऽশোষ্য় এব চ \nনিত্য়ঃ(স্) সর্বগতঃ(স্) স্থাণু(র্),অচলো‌ऽয়ং(ম্) সনাতনঃ ॥2.24॥",

    "অব্য়ক্তো‌ऽয়মচিন্ত্য়োऽয়ম্,‌‌অবিকার্য়োऽয়মুচ্য়তে।\nতস্মাদেবং(ব্ঁ) বিদিত্বৈনং(ন্), নানুশোচিতুমর্হসি ॥2.25॥",

    "অথ চৈনং(ন্) নিত্য়জাতং(ন্), নিত্য়ং(ব্ঁ) বা মন্য়সে মৃতম্।\nতথাপি ত্বং(ম্) মহাবাহো, নৈবং(ম্) শোচিতুমর্হসি ॥2.26॥",

    "জাতস্য় হি ধ্রুবো মৃত্য়ু:(র্),  ধ্রুবং(ঞ্) জন্ম মৃতস্য় চ\nতস্মাদপরিহার্য়ে‌ऽথে, ন ত্বং(ম্) শোচিতুমর্হসি ॥2.27॥",
    
    "অব্য়ক্তাদীনি ভূতানি, ব্য়ক্তমধ্য়ানি ভারত \nঅব্য়ক্তনিধনান্য়েব, তত্র কা পরিদেবনা ॥2.28॥",

    "আশ্চর্য়বত্পশ্য়তি কশ্চিদেনম্,\nআশ্চর্য়বদ্বদতি তথৈব চান্য়ঃ। \nআশ্চর্য়বচ্চৈনমন্য়ঃ(শ্) শৃণোতি,\nশ্রুত্বাপ্য়েনং(ব্ঁ) বেদ ন চৈব কশ্চিত্ ॥2.29॥",

    "দেহী নিত্য়মবধ্য়ো‌ऽয়ং(ন্), দেহে সর্বস্য় ভারত \nতস্মাত্সর্বাণি ভূতানি, ন ত্বং(ম্) শোচিতুমর্হসি ॥2.30॥",

    "স্বধর্মমপি চাবেক্ষ্য়, ন বিকম্পিতুমর্হসি \nধর্ম্য়াদ্ধি যুদ্ধাচ্ছ্রেয়োsন্য়ত্, ক্ষত্রিয়স্য় ন বিদ্য়তে ॥2.31॥",

    "য়দৃচ্ছয়া চোপপন্নং(ম্),স্বর্গদ্বারমপাবৃতম্ \nসুখিনঃ ক্ষত্রিয়াঃ(ফ্) পার্থ, লভন্তে যুদ্ধমীদৃশম্ ॥2.32॥",

    "অথ চেত্ত্বমিমং(ন্) ধর্ম্য়ং(ম্), সঙ্গ্রামং(ন্) ন করিষ্য়সি \nততঃ(স্) স্বধর্মং(ঙ্) কীর্তিং(ঞ্) চ,হিত্বা পাপমবাপ্স্য়সি ॥2.33॥",

    "অকীর্তিং(ঞ্) চাপি ভূতানি, কথয়িষ্য়ন্তি তে‌ऽব্য়য়াম্ \nসম্ভাবিতস্য়  চাকীর্তি:(র্), মরণাদতিরিচ্য়তে ॥2.34॥",

    "ভয়াদ্রণাদুপরতং(ম্),মংস্য়ন্তে ত্বাং(ম্) মহারথাঃ \nয়েষাং(ঞ্) চ ত্বং(ম্) বহুমতো, ভূত্বা য়াস্য়সি লাঘবম্ ॥2.35॥",

    "অবাচ্য়বাদাংশ্চ বহূন্, বদিষ্য়ন্তি তবাহিতাঃ \nনিন্দন্তস্তব সামর্থ্য়ং(ন্),ততো দুঃখতরং (ন্) নু কিম্ ॥2.36॥",

    "হতো বা প্রাপ্স্য়সি স্বর্গং(ঞ্),জিত্বা বা ভোক্ষ্য়সে মহীম্  \nতস্মাদুত্তিষ্ঠ কৌন্তেয়,যুদ্ধায় কৃতনিশ্চয়ঃ ॥2.37॥",

    "সুখদুঃখে সমে কৃত্বা,লাভালাভৌ জয়াজয়ৌ \nততো যুদ্ধায় য়ুজ্য়স্ব, নৈবং(ম্) পাপমবাপ্স্য়সি ॥2.38॥",

    "এষা তে‌ऽভিহিতা সাঙ্খ্য়ে, বুদ্ধির্য়োগে ত্বিমাং(ম্) শৃণু \nবুদ্ধ্য়া যুক্তো য়য়া পার্থ, কর্মবন্ধং(ম্) প্রহাস্য়সি ॥2.39॥",

    "নেহাভিক্রমনাশো‌ऽস্তি, প্রত্য়বায়ো ন বিদ্য়তে \nস্বল্পমপ্য়স্য় ধর্মস্য়, ত্রায়তে মহতো ভয়াত্ ॥2.40॥",

    "ব্য়বসায়াত্মিকা বুদ্ধি:(র্), একেহ কুরুনন্দন \nবহুশাখা হ্য়নন্তাশ্চ, বুদ্ধয়ো‌ऽব্য়বসায়িনাম্ ॥2.41॥",

    "য়ামিমাং(ম্) পুষ্পিতাং(ব্ঁ) বাচং(ম্), প্রবদন্ত্য়বিপশ্চিতঃ। \nবেদবাদরতাঃ(ফ্) পার্থ, নান্য়দস্তীতি বাদিনঃ ॥2.42॥",

    "কামাত্মানঃ(স্) স্বর্গপরা, জন্মকর্মফলপ্রদাম্ \nক্রিয়াবিশেষবহুলাং(ম্), ভোগৈশ্বর্য়গতিং(ম্) প্রতি ॥2.43॥",

    "ভোগৈশ্বর্য়প্রসক্তানাং(ন্),তয়াপহৃতচেতসাম্ \nব্য়বসায়াত্মিকা বুদ্ধিঃ(স্), সমাধৌ ন বিধীয়তে ॥2.44॥",

    "ত্রৈগুণ্য়বিষয়া বেদা, নিস্ত্রৈগুণ্য়ো ভবার্জুন \nনির্দ্বন্দ্বো নিত্য়সত্ত্বস্থো, নির্য়োগক্ষেম আত্মবান্ ॥2.45॥",

    "য়াবানর্থ উদপানে, সর্বতঃ(স্) সম্প্লুতোদকে \nতাবান্সর্বেষু বেদেষু, ব্রাহ্মণস্য় বিজানতঃ ॥2.46॥",

    "কর্মণ্য়েবাধিকারস্তে, মা ফলেষু কদাচন \nমা কর্মফলহেতুর্ভূ:(র্), মা তে সঙ্গো‌ऽস্ত্বকর্মণি ॥2.47॥",

    "য়োগস্থঃ(খ্) কুরু কর্মাণি, সঙ্গং(ন্) ত্যক্ত্বা ধনঞ্জয়। \nসিদ্ধ্য়সিদ্ধ্য়োঃ(স্) সমো ভূত্বা, সমত্বং(য়্ঁ) য়োগ উচ্য়তে ॥2.48॥",

    "দূরেণ হ্য়বরং(ঙ্) কর্ম, বুদ্ধিয়োগাদ্ধনঞ্জয় \nবুদ্ধৌ শরণমন্বিচ্ছ, কৃপণাঃ(ফ্) ফলহেতবঃ ॥2.49॥",

    "বুদ্ধিয়ুক্তো জহাতীহ, উভে সুকৃতদুষ্কৃতে \nতস্মাদ্য়োগায় য়ুজ্য়স্ব, যোগঃ(খ্) কর্মসু কৌশলম্ ॥2.50॥",

    "কর্মজং(ম্) বুদ্ধিয়ুক্তা হি, ফলং(ন্) ত্য়ক্ত্বা মনীষিণঃ \nজন্মবন্ধবিনির্মুক্তাঃ(ফ্), পদং(ঙ্) গচ্ছন্ত্য়নাময়ম্ ॥2.51॥",

    "য়দা তে মোহকলিলং(ম্), বুদ্ধির্ব্য়তিতরিষ্য়তি \nতদা গন্তাসি নির্বেদং(ম্), শ্রোতব্য়স্য় শ্রুতস্য় চ ॥2.52॥",

    "শ্রুতিবিপ্রতিপন্না তে, য়দা স্থাস্য়তি নিশ্চলা \nসমাধাবচলা বুদ্ধি:(স্),তদা যোগমবাপ্স্য়সি ॥2.53॥",

    "অর্জুন উবাচ\nস্থিতপ্রজ্ঞস্য় কা ভাষা, সমাধিস্থস্য় কেশব \nস্থিতধীঃ(খ্) কিং(ম্) প্রভাষেত,কিমাসীত ব্রজেত কিম্ ॥2.54॥",

    "শ্রীভগবানুবাচ \nপ্রজহাতি য়দা কামান্, সর্বান্পার্থ মনোগতান্ \nআত্মন্য়েবাত্মনা তুষ্টঃ(স্), স্থিতপ্রজ্ঞস্তদোচ্য়তে ॥2.55॥",

    "দুঃখেষ্বনুদ্বিগ্নমনাঃ(স্), সুখেষু বিগতস্পৃহঃ \nবীতরাগভয়ক্রোধঃ(স্), স্থিতধীর্মুনিরুচ্য়তে ॥2.56॥",

    "য়ঃ(স্) সর্বত্রানভিস্নেহ:(স্),তত্তত্প্রাপ্য় শুভাশুভম্ \nনাভিনন্দতি ন দ্বেষ্টি, তস্য় প্রজ্ঞা প্রতিষ্ঠিতা ॥2.57॥",

    "য়ঃ(স্) সর্বত্রানভিস্নেহ:(স্),তত্তত্প্রাপ্য় শুভাশুভম্ \nনাভিনন্দতি ন দ্বেষ্টি, তস্য় প্রজ্ঞা প্রতিষ্ঠিতা ॥2.58॥",

    "বিষয়া বিনিবর্তন্তে, নিরাহারস্য় দেহিনঃ \nরসবর্জং(ম্) রসো‌ऽপ্য়স্য়, পরং(ন্) দৃষ্ট্বা নিবর্ততে ॥2.59॥",

    "য়ততো হ্য়পি কৌন্তেয়, পুরুষস্য় বিপশ্চিতঃ \nইন্দ্রিয়াণি প্রমাথীনি, হরন্তি প্রসভং(ম্) মনঃ ॥2.60॥",

    "তানি সর্বাণি সংয়ম্য়, যুক্ত আসীত মত্পরঃ \nবশে হি য়স্য়েন্দ্রিয়াণি, তস্য় প্রজ্ঞা প্রতিষ্ঠিতা ॥2.61॥",

    "ধ্য়ায়তো বিষয়ান্পুংসঃ(স্), সঙ্গস্তেষূপজায়তে \nসঙ্গাত্সঞ্জায়তে  কামঃ(খ্), কামাত্ক্রোধো‌ऽভিজায়তে ॥2.62॥",

    "ক্রোধাদ্ভবতি সম্মোহঃ(স্), সম্মোহাত্স্মৃতিবিভ্রমঃ \nস্মৃতিভ্রংশাদ্ বুদ্ধিনাশো, বুদ্ধিনাশাত্প্রণশ্য়তি ॥2.63॥",

    "রাগদ্বেষাবয়ুক্তৈস্তু, বিষয়ানিন্দ্রিয়ৈশ্চরন্\nআত্মবশ্য়ৈর্বিধেয়াত্মা,প্রসাদমধিগচ্ছতি ॥2.64॥",

    "প্রসাদে সর্বদুঃখানাং(ম্ ),হানিরস্য়োপজায়তে \nপ্রসন্নচেতসো হ্য়াশু, বুদ্ধিঃ(ফ্)পর্য়বতিষ্ঠতে ॥2.65॥",

    "নাস্তি বুদ্ধিরয়ুক্তস্য়, ন চায়ুক্তস্য় ভাবনা \nন চাভাবয়তঃ(শ্) শান্তি:(র্), অশান্তস্য় কুতঃ(স্) সুখম্ ॥2.66॥",

    "ইন্দ্রিয়াণাং(ম্) হি চরতাং(য়্ঁ), য়ন্মনো‌ऽনুবিধীয়তে। \nতদস্য় হরতি প্রজ্ঞাং(ম্), বায়ুর্নাবমিবাম্ভসি ॥2.67॥",

    "তস্মাদ্য়স্য় মহাবাহো, নিগৃহীতানি সর্বশঃ \nইন্দ্রিয়াণীন্দ্রিয়ার্থেভ্য়:(স্), তস্য় প্রজ্ঞা প্রতিষ্ঠিতা ॥2.68॥",

    "য়া নিশা সর্বভূতানাং(ন্),তস্য়াং( ঞ্)জাগর্তি সংয়মী \nয়স্য়াং(ঞ্) জাগ্রতি ভূতানি, সা নিশা পশ্য়তো মুনেঃ ॥2.69॥",

    "আপূর্য়মাণমচলপ্রতিষ্ঠং(ম্),\nসমুদ্রমাপঃ(ফ্) প্রবিশন্তি য়দ্বত্  \nতদ্বত্কামা য়ং(ম্) প্রবিশন্তি সর্বে,\nস শান্তিমাপ্নোতি ন কামকামী ॥2.70॥",

    "বিহায় কামান্য়ঃ(স্) সর্বান্, পুমাংশ্চরতি নিঃস্পৃহঃ \nনির্মমো নিরহঙ্কারঃ(স্), স শান্তিমধিগচ্ছতি ॥2.71॥",

    "এষা ব্রাহ্মী স্থিতিঃ(ফ্)পার্থ, নৈনাং(ম্) প্রাপ্য় বিমুহ্য়তি \nস্থিত্বাস্য়ামন্তকালে‌ऽপি, ব্রহ্মনির্বাণমৃচ্ছতি ॥2.72॥",

    "ॐ তত্সদিতি শ্রীমদ্ভগবদ্গীতাসু উপনিষত্সু ব্রহ্মবিদ্য়ায়াং(য়্ঁ)\nয়োগশাস্ত্রে শ্রীকৃষ্ণার্জুনসংবাদেসাঙ্খ্য়য়োগো নাম দ্বিতীয়ো‌ऽধ্য়ায়ঃ ॥2॥"
  ],
  "adhyaya-3" : [
    "অর্জুন উবাচ\nজ্যায়সী চেৎ কর্মণস্তে, মতা বুদ্ধির্জনার্দন।\nতৎকিং(ঙ) কর্মণি ঘোরে মাং(ন্), নিয়োজয়সি কেশব ॥3.1॥",

   "ব্যামিশ্রেণেব বাক্যেন, বুদ্ধিং(ম্) মোহয়সীব মে।\nতদেকং(ব্ঁ) বদ নিশ্চিত্য,  য়েন শ্রেয়োऽহমাপ্নুয়াম্ ॥3.2॥",

   "শ্রীভগবানুবাচ\nলোকেsস্মিন্ দ্বিবিধা নিষ্ঠা, পুরা প্রোক্তা ময়ানঘ।\nজ্ঞানযোগেন সাংখ্যানাং(ঙ), কর্মযোগেন যোগিনাম্ ॥3.3॥",

   "ন কর্মণামনারম্ভান্, নৈষ্কর্ম্যং(ম্) পুরুষোsশ্নুতে।\nন চ সন্ন্যসনাদেব, সিদ্ধিং(ম্) সমাধিগচ্ছতি ॥3.4॥",

   "ন হি কশ্চিৎক্ষণমপি, জাতু তিষ্ঠত্যকর্মকৃৎ।\nকার্যতে হ্যবশঃ(খ্) কর্ম, সর্বঃ(ফ্) প্রকৃতিজৈর্গুণৈঃ ॥3.5॥",

   "কর্মেন্দ্রিয়াণি সংযম্য, য় আস্তে মনসা স্মরন্ ।\nইন্দ্রিয়ার্থান্বিমূঢ়াত্মা, মিথ্যাচারঃ (স্) স উচ্যতে ॥3.6॥",

   "যস্ত্বিন্দ্রিয়াণি মনসা, নিয়ম্যারভতেsর্জুন।\nকর্মেন্দ্রিয়ৈঃ(খ্) কর্মযোগম্, অসক্ত:(স্) স বিশিষ্য়তে ॥3.7॥",

   "নিয়তং(ঙ)কুরু কর্ম ত্বং(ঙ), কর্ম জ্যায়ো হ্যকর্মণঃ।\nশরীরযাত্রাপি চ তে, ন প্রসিদ্ধ্যেদকর্মণ: ॥3.8॥",

   "যজ্ঞার্থাৎকর্মণোsন্য়ত্র, লোকোsয়ং(ঙ)কর্মবন্ধনঃ।\nতদর্থং(ঙ) কর্ম কৌন্তেয়, মুক্তসঙ্গঃ (স্) সমাচর ॥3.9॥",

   "সহযজ্ঞাঃ(ফ্) প্রজাঃ(স্)  সৃষ্ট্বা, পুরোবাচ প্রজাপতিঃ।\nঅনেন প্রসবিষ্যধ্বম্, এষ বোsস্ত্বিষ্টকামধুক্ ॥3.10॥",

   "দেবান্ভাবয়তানেন, তে দেবা ভাবয়স্তু বঃ।\nপরস্পরং(ম্) ভাবয়ন্তঃ(শ্),  শ্রেয়ঃ(ফ্) পরমবাপ্স্য়থ ॥3.11॥",

   "ইষ্টান্ভোগান্হি বো দেবা, দাস্যন্তে যজ্ঞভাবিতাঃ।\nতৈর্দত্তানপ্রদায়ৈভ্যো, যো ভুঙক্তে স্তেন এব সঃ ॥3.12॥",

   "যজ্ঞশিষ্টাশিনঃ(স্) সন্তো, মুচ্যন্তে সর্বকিল্বিষৈঃ।\nভুঞ্জতে তে ত্বঘং(ম্) পাপা, যে পচন্ত্যাত্মকারণাৎ ॥3.13॥",

   "অন্নাদ্ভবন্তি ভূতানি, পর্জন্যাদন্নসম্ভবঃ।\nযজ্ঞাদ্ ভবতি পর্জন্যো, য়জ্ঞ:(খ্)  কর্মসমুদ্ভবঃ ॥3.14॥",

   "কর্ম ব্রহ্মোদ্ভবং(ব্ঁ) বিদ্ধি, ব্রহ্মাক্ষরসমুদ্ভবম্।\nতস্মাৎসর্বগতং(ম্) ব্রহ্ম, নিত্যং(য়্ঁ) য়জ্ঞে প্রতিষ্ঠিতম্॥3.15॥",

   "এবং প্রবর্তিতং চক্রং(ন), নানুবর্তয়তীহ যঃ।\nঅঘায়ুরিন্দ্রিয়ারামো, মোঘং(ম্) পার্থ স জীবতি ॥3.16॥",

   "যস্ত্বাত্মরতিরেব স্যাদ্, আত্মতৃপ্তশ্চ মানবঃ।\nআত্মন্যেব চ সন্তুষ্ষ্ট:(স্), তস্য কার্যং(ন্) ন বিদ্যতে ॥3.17॥",

   "নৈব তস্য কৃতেনার্থো, নাকৃতেনেহ কশ্চন।\nন চাস্য সর্বভূতেষু, কশ্চিদর্থব্যপাশ্রয়ঃ ॥3.18॥",

   "তস্মাদসক্তঃ(স্) সততং(ঙ), কার্যং(ঙ) কর্ম সমাচর।\nঅসক্তো হ্যাচরন্কর্ম, পরমাপ্নোতি পুরুষঃ ॥3.19॥",

   "কর্মণৈব হি সংসিদ্ধিম্, আস্থিতা জনকাদয়ঃ।\nলোকসংগ্রহমেবাপি,সংপশ্যন্কর্তুমর্হসি ॥3.20॥",

   "য়দ্যয়দাচরতি শ্রেষ্ঠ:(স্), তত্তদেবেতরো জন:।\nস য়ত্প্রমাণং(ঙ) কূরূতে, লোকস্তদনূবর্ততে ॥3.21॥",

   "ন মে পার্থাস্তি কর্তব্য়(ন্),ত্রিষু লোকেষু কিঞ্চন।\nনানবাপ্তমবাপ্তব্য়ং(ব্ঁ),বর্ত এব চ কর্মণি ॥3.22॥",

   "যদি হ্যহং(ন) ন বর্তেয়ং(ঞ্), জাতু কর্মণ্যতন্দ্রিতঃ। \nমম বর্ত্মানুবর্তন্তে, মনুষ্যাঃ(ফ্) পার্থ সর্বশঃ ॥3.23॥",

   "উৎসীদেয়ুরিমে লোকা, ন কুর্যাং (ঙ)কর্ম চেদহম্।\nসঙ্করস্য চ কর্তা স্য়াম্,  উপহন্যামিমাঃ (ফ্) প্রজাঃ ॥3.24॥",

   "সক্তাঃ(খ্)কর্মণ্যবিদ্বাংসো, যথা কুর্বন্তি ভারত।\nকুর্যাদ্বিদ্বাংস্তথাসক্ত:(শ্), চিকীর্ষূর্লোকসংগ্রহম্ ॥3.25॥",

   "ন বুদ্ধিভেদং (ঞ্) জনয়েদ্, অজ্ঞানাং কর্মসঙ্গিনাম্ ।\nজোষয়েত্সর্বকর্মাণি, বিদ্বান্ যুক্তঃ (স) সমাচরন্ ॥3.26॥",

   "প্রকৃতেঃ(খ্) ক্রিয়মাণনি, গুণৈঃ( খ্) কর্মাণি সর্বশঃ।\nঅহঙ্কারবিমুঢ়াত্মা, কর্তাহমিতি মন্যতে ॥3.27॥",

   "তত্ত্ববিত্তু মহাবাহো, গুণকর্মবিভাগয়োঃ।\nগুণা গুণেষু বর্তন্ত, ইতি মত্বা ন সজ্জতে ॥3.28॥",

   "প্রকৃতের্গুণসংমূঢ়াঃ(স্), সজ্জন্তে গুণকর্মসু।\nতানকৃৎস্নবিদো মন্দান্, কৃৎস্নবিন্ন বিচালয়েৎ ॥3.29॥",

   "ময়ি সর্বাণি কর্মাণি, সংন্যস্যাধ্যাত্মচেতসা।\nনিরাশীর্নির্মমো ভূত্বা, যুধ্যস্ব বিগতজ্বরঃ ॥3.30॥",

   "যে মে মতমিদং(ন্) নিত্যম্, অনুতিষ্ঠন্তি  মানবাঃ।\nশ্রদ্ধাবন্তোsনসূয়ন্তো, মুচ্যন্তে তেsপি কর্মভিঃ ॥3.31॥",

   "যে ত্বেতদভ্যসূয়ন্তো, নানুতিষ্ঠন্তি মে মতম্।\nসর্বজ্ঞানবিমূঢ়াংস্তান্, বিদ্ধি নষ্টানচেতসঃ ॥3.32॥",

   "সদৃশ(ঞ্) চেষ্টতে স্বস্যাঃ(ফ্), প্রকৃতের্জ্ঞানবানপি। \nপ্রকৃতিং(য়্ঁ) য়ান্তি ভূতানি, নিগ্রহঃ(খ্) কিং(ঙ্) করিষ্যতি ॥3.33॥",

   "ইন্দ্রিয়স্যেন্দ্রিয়স্যার্থে, রাগদ্বেষৌ ব্যবস্থিতৌ।\nতয়োর্ন বশমাগচ্ছেৎ, তৌ হ্যস্য পরিপন্থিনৌ ॥3.34॥",

   "শ্রেয়ান্স্বধর্মো বিগুণঃ(ফ), পরধর্মাৎ স্বনুষ্ঠিতাৎ।\nস্বধর্মে নিধনং(ম্)  শ্রেয়ঃ(ফ্), পরধর্মো ভয়াবহঃ ॥3.35॥",

   "অর্জুন উবাচ\nঅথ কেন প্রযুক্তোsয়ং(ম্), পাপং(ঞ্) চরতি পুরুষঃ।\nঅনিচ্ছন্নপি বার্ষ্ণেয়, বলাদিব নিয়োজিতঃ ॥3.36॥",

   "শ্রীভগবানুবাচ\nকাম এষ ক্রোধ এষ, রজোগুণসমুদ্ভবঃ।\nমহাশনো মহাপাপ্মা, বিদ্ধ্যেনমিহ বৈরিণম্ ॥3.37॥",

   "ধূমেনাব্রিয়তে বহ্নি:(র্), য়থাদর্শো মলেন চ।\nযথোল্বেনাবৃতো গর্ভ(স্), তথা তেনেদমাবৃতম্ ॥3.38॥",

   "আবৃতং(ঞ্) জ্ঞানমেতেন, জ্ঞানিনো নিত্যবৈরিণা।\nকামরূপেণ কৌন্তেয়, দুষ্পূরেণানলেন চ ॥3.39॥",

   "ইন্দ্রিয়াণি মনো বুদ্ধি(র্), অস্যাধিষ্ঠানমুচ্য়তে ।\nএতৈর্বিমোহয়ত্যেষ, জ্ঞানমাবৃত্য দেহিনম্ ॥3.40॥",

   "তস্মাত্ত্বমিন্দ্রিয়াণ্যাদৌ, নিয়ম্য ভরতর্ষভ।\nপাপ্মানং(ম্) প্রজহি হ্যেনং (ঞ্), জ্ঞানবিজ্ঞাননাশনম্ ॥3.41॥",

   "ইন্দ্রিয়াণি পরাণ্যাহু:(র্), ইন্দ্রিয়েভ্য়:(ফ্)  পরং( ম্) মনঃ।\nমনস্তু পরা বুদ্ধি:(র্),য়ো বুদ্ধেঃ( ফ্) পরতস্তু সঃ ॥3.42॥",

   "এবং(ম্) বুদ্ধেঃ(ফ্) পরং(ম্) বুদ্ ধ্বা, সংস্তভ্যাত্মানমাত্মনা।\nজহি শত্রুং(ম্) মহাবাহো,কামরূপং (ন্) দুরাসদম্ ॥3.43॥",

   "ॐ তৎসদিতি শ্রীমদ্ভগবদ্গীতাসু উপনিষৎসু ব্রহ্মবিদ্যায়াং(য়্ঁ) য়োগশাস্ত্রে\nশ্রীকৃষ্ণার্জুনসংবাদে কর্ময়োগো নাম তৃতীয়োऽধ্য়ায়: ॥3॥"
  ],
  "adhyaya-4" : [
    "শ্রীভগবানুবাচ\nইমং(ম্) বিবস্বতে যোগং(ম্), প্রোক্তবানহমব্যয়ম্ ।\nবিবস্বান্ মনবে  প্রাহ, মনুরিক্ষ্বাকবেऽব্রবীৎ ꠱4.1꠱",

    "এবং(ম্) পরম্পরাপ্রাপ্তম্, ইমং(ম্) রাজর্ষয়ো বিদুঃ।\nস কালেনেহ মহতা, যোগো নষ্টঃ ( ফ্) পরন্তপ ꠱4.2꠱",

    "স এবায়ং(ম্) ময়া তেऽদ্য, যোগঃ(ফ্)  প্রোক্তঃ(ফ্) পুরাতনঃ।\nভক্তোsসি মে সখা চেতি, রহস্যং (ম্) হ্যেতদুত্তমম্ ꠱4.3꠱",

    "অর্জুন উবাচ\nঅপরং(ম্) ভবতো জন্ম, পরং(ঞ্) জন্ম বিবস্বতঃ।\nকথমেতদ্বিজানীয়াং(ন্), ত্বমাদৌ প্রোক্তবানিতি ꠱4.4꠱",

    "শ্রীভগবানুবাচ\nবহূনি মে ব্যতীতানি, জন্মানি তব চার্জুন।\nতান্যহং(ম্) বেদ সর্বাণি, ন ত্বং(ম্) বেত্থ পরন্তপ ꠱4.5꠱",

    "অজোsপি সন্নব্যয়াত্মা, ভূতানামীশ্বরোs পি  সন্।\nপ্রকৃতিং(ম্) স্বামধিষ্ঠায়,সম্ভবাম্যাত্মমায়য়া ꠱4.6꠱",

    "যদা যদা হি ধর্মস্য, গ্লানির্ভবতি ভারত।\nঅভ্যুত্থানমধর্মস্য, তদাত্মানং(ম্) সৃজাম্য়হম্ ꠱4.7꠱",

    "পরিত্রাণায় সাধূনাং(ম্), বিনাশায় চ দুষ্কৃতাম্।\nধর্মসংস্থাপনার্থায়, সম্ভবামি যুগে যুগে ꠱4.8꠱",

    "জন্ম কর্ম চ মে দিব্যম্, এবং(ম্) য়ো বেত্তি তত্ত্বতঃ।\nত্যক্ত্বা দেহং(ম্) পুনর্জন্ম,  নৈতি মামেতি সোsর্জুন ꠱4.9꠱",

    "বীতরাগভয়ক্রোধা, মন্ময়া মামুপাশ্রিতাঃ।\nবহবো জ্ঞানতপসা, পূতা মদ্ভাবমাগতাঃ ꠱4.10꠱",

    "য়ে যথা মাং(ম্)প্রপদ্যন্তে, তাংস্তথৈব ভজাম্যহম্।\nমম বর্ত্মানুবর্তন্তে,মনুষ্যাঃ(ফ্) পার্থ সর্বশঃ ꠱4.11꠱",

    "কাঙ্ক্ষন্তঃ(খ্) কর্মণাং(ম্) সিদ্ধিং(ম্), য়জন্ত ইহ দেবতাঃ।\nক্ষিপ্রং(ম্) হি মানুষে লোকে, সিদ্ধির্ভবতি কর্মজা ꠱4.12꠱",

    "চাতুর্বর্ণ্যং(ম্) ময়া সৃষ্টং(ঙ্), গুণকর্মবিভাগশঃ।\nতস্য কর্তারমপি মাং(ম্) বিদ্ধ্যকর্তারমব্যয়ম্ ꠱4.13꠱",

    "ন মাং(ঙ্) কর্মাণি লিম্পন্তি,ন মে কর্মফলে স্পৃহা।\nইতি মাং(ম্) য়োsভিজানাতি, কর্মভির্ন স বধ্যতে ꠱4.14꠱",

    "এবং(ঞ্) জ্ঞাত্বা কৃতং(ঙ্) কর্ম, পূর্বৈরপি মুমুক্ষুভিঃ।\nকুরু কর্মৈব তস্মাত্ত্বং(ম্) পূর্বৈঃ(ফ্) পূর্বতরং(ঙ্) কৃতম্ ꠱4.15꠱",

    "কিং(ঙ্) কর্ম কিমকর্মেতি, কবয়োsপ্য়ত্র মোহিতা : \nতত্তে কর্ম প্রবক্ষ্যামি, য়জ্জ্ঞাত্বা মোক্ষ্যসেsশুভাৎ ꠱4.16꠱",

    "কর্মণো হ্যপি বোদ্ধব্যং(ম্), বোদ্ধব্যং(ঞ্) চ বিকর্মণঃ।\nঅকর্মণশ্চ বোদ্ধব্যং(ঙ্), গহনা কর্মণো গতিঃ ꠱4.17꠱",

    "কর্মণ্যকর্ম য়য়ঃ(ফ্) পশ্যেদ্, অকর্মণি চ কর্ম য়ঃ।\nস বুদ্ধিমান্মনুষ্যেষু, স য়ুক্তঃ(খ্) কৃৎস্নকর্মকৃৎ ꠱4.18꠱",

    "য়স্য সর্বে সমারম্ভাঃ(খ্), কামসংকল্পবর্জিতাঃ।\nজ্ঞানাগ্নিদগ্ধকর্মাণং(ন্), তমাহুঃ(ফ্) পন্ডিতং(ম্) বুধাঃ ꠱4.19꠱",

    "ত্যক্ত্বা কর্মফলাসঙ্গং(ন্),নিত্যতৃপ্তো নিরাশ্রয়ঃ।\nকর্মণ্যভিপ্রবৃত্তোsপি, নৈব কিঞ্চিৎ করোতি সঃ ꠱4.20꠱",

    "নিরাশীর্য়তচিত্তাত্মা, ত্যক্তসর্বপরিগ্রহঃ।\nশারীরং(ঙ্) কেবলং(ঙ্) কর্ম, কুর্বন্নাপ্নোতি কিল্বিষম্ ꠱4.21꠱",

    "যদৃচ্ছালাভসন্তুষ্টো, দ্বন্দ্বাতীতো বিমৎসর: ।\nসমঃ(স্) সিদ্ধাবসিদ্ধৌ চ, কৃত্বাপি ন নিবধ্যতে ꠱4.22꠱",

    "গতসঙ্গস্য মুক্তস্য,জ্ঞানাবস্থিতচেতসঃ।\nয়জ্ঞায়াচরতঃ(খ্)কর্ম,সমগ্রং(ম্) প্রবিলীয়তে ꠱4.23꠱",

    " ব্রহ্মার্পণং(ম্) ব্রহ্ম হবি:(র্),ব্রহ্মাগ্নৌ ব্রহ্মণা হুতম্।\nব্রহ্মৈব তেন গন্তব্যং(ম্), ব্রহ্মকর্মসমাধিনা ꠱4.24꠱",

    "দৈবমেবাপরে  য়জ্ঞং(ম্),যোগিনঃ(ফ্) পর্য়ুপাসতে।\nব্রহ্মাগ্নাবপরে য়জ্ঞং (ম্), য়জ্ঞেনৈবোপজুহ্বতি ꠱4.25꠱",

    "শ্রোত্রাদীনীন্দ্রিয়াণ্যন্যে, সংয়মাগ্নিষু জুহ্বতি।\nশব্দাদীন্বিষয়ানন্য, ইন্দ্রিয়াগ্নিষু জুহ্বতি ꠱4.26꠱",

    "সর্বাণীন্দ্রিয়কর্মাণি, প্রাণকর্মাণি চাপরে।\nআত্মসংয়মযোগাগ্নৌ, জুহ্বতি জ্ঞানদীপিতে ꠱4.27꠱",

    "দ্রব্যয়জ্ঞাস্তপোয়জ্ঞা, যোগয়জ্ঞাস্তথাপরে।\nস্বাধ্যায়জ্ঞানয়জ্ঞাশ্চ, য়তয়ঃ(স্) সংশিতব্রতাঃ ꠱4.28꠱",

    "অপানে জুহ্বতি প্রাণং(ম্), প্রাণেsপানং(ন্) তথাপরে।\nপ্রাণাপানগতী রুদ্ধ্বা, প্রাণায়ামপরায়ণাঃ ꠱4.29꠱",

    "অপরে নিয়তাহারাঃ(ফ্),প্রাণান্প্রাণেষু জুহ্বতি।।\nসর্বেsপ্য়েতে য়জ্ঞবিদো, য়জ্ঞক্ষপিতকল্মষা: ꠱4.30꠱",

    "য়জ্ঞশিষ্টামৃতভুজো, য়ান্তি ব্রহ্ম সনাতনম্।\nনায়ং(ম্) লোকোsস্ত্য়য়জ্ঞস্য়, কুতোsন্য়:(খ্) কুরুসত্তম ꠱4.31꠱",

    "এবং(ম্) বহুবিধা য়জ্ঞা, বিততা ব্রহ্মণো মুখে। \nকর্মজান্বিদ্ধি তান্সর্বান্, এবং(ঞ্)  জ্ঞাত্বা বিমোক্ষ্যসে ꠱4.32꠱",

    "শ্রেয়ান্দ্রব্যময়দ্যজ্ঞাজ্, জ্ঞানয়জ্ঞঃ(ফ্) পরন্তপ। \nসর্বং(ঙ্) কর্মাখিলং(ম্)পার্থ, জ্ঞানে পরিসমাপ্যতে ꠱4.33꠱",

    "তদ্বিদ্ধি প্রণিপাতেন, পরিপ্রশ্নেন সেবয়া।\nউপদেক্ষ্যন্তি তে জ্ঞানং (ঞ্), জ্ঞানিনস্তত্ত্বদর্শিনঃ ꠱4.34꠱",

    "য়জ্জ্ঞাত্বা ন পুনর্মোহম্,এবং(ম্) য়াস্যসি পান্ডব। \nয়েন ভূতান্যশেষেণ,দ্রক্ষ্যস্যাত্মন্যথো ময়ি ꠱4.35꠱",

    "অপি চেদসি পাপেভ্যঃ(স্), সর্বেভ্যঃ(ফ্) পাপকৃত্তমঃ।\nসর্বং(ঞ্)জ্ঞানপ্লবেনৈব,বৃজিনং(ম্) সন্তরিষ্যসি ꠱4.36꠱",

    "যথৈধাংসি সমিদ্বোऽগ্নিঃ(র্), ভস্মসাৎকুরুতেsর্জুন ।\nজ্ঞানাগ্নিঃ(স্) সর্বকর্মাণি,ভস্মসাৎকুরুতে তথা ꠱4.37꠱",

    "ন হি জ্ঞানেন সদৃশং(ম্),পবিত্রমিহ বিদ্যতে। \nতৎস্বয়ং(ম্) যোগসংসিদ্ধঃ(খ্) কালেনাত্মনি বিন্দতি ꠱4.38꠱",

    "শ্রদ্ধাবাঁল্লভতে জ্ঞানং(ন্), তৎপরঃ(স্) সংয়তেন্দ্রিয়ঃ। \nজ্ঞানং(ম্) লব্ধ্বা পরাং(ম)শান্তিম্, অচিরেণাধিগচ্ছতি ꠱4.39꠱",

    "অজ্ঞশ্চাশ্রদ্দধানশ্চ, সংশয়াত্মা বিনশ্যতি। \nনায়ং(ম্) লোকোsস্তি  ন পরো,ন সুখং(ম্) সংশয়াত্মনঃ ꠱4.40꠱",

    "যোগসন্ন্যস্তকর্মাণং(ঞ্),জ্ঞানসঞ্ছিন্নসংশয়ম্ ।\nআত্মবন্ত (ন্) ন কর্মাণি, নিবধ্নন্তি ধনঞ্জয় ꠱4.41꠱",

    "তস্মাদজ্ঞানসম্ভূতং(ম্), হৃৎস্থং(ঞ্)  জ্ঞানাসিনাত্মনঃ। \nছিত্ত্বৈনং(ম্) সংশয়ং(ম্)যোগম্, আতিষ্ঠোত্তিষ্ঠ ভারত ꠱4.42꠱",
    
    "ॐ তৎসদিতি  শ্রীমদ্ভগবদ্গীতাসু উপনিষত্সু ব্রহ্মবিদ্য়ায়াং(য়্ঁ) য়োগশাস্ত্রে \nশ্রীকৃষ্ণার্জুনসংবাদে জ্ঞানকর্মসন্ন্যাসয়োগো নাম চতুর্থোsধ্য়ায়: ॥4॥"
  ],
  "adhyaya-5" : [
    "অর্জুন উবাচ\nসন্ন্য়াসং(ঙ্) কর্মণাং(ঙ্) কৃষ্ণ, পুনর্য়োগং(ঞ্) চ শংসসি \nয়চ্ছ্রেয় এতয়োরেকং(ন্), তন্মে ব্রূহি সুনিশ্চিতম্ ꠱5.1꠱",
    
    "শ্রীভগবানুবাচ\nসন্ন্য়াসঃ(খ্) কর্ময়োগশ্চ , নিঃশ্রেয়সকরাবুভৌ \nতয়োস্তু কর্মসন্ন্য়াসাত্, কর্ময়োগো বিশিষ্যতে ꠱5.2꠱",

    "জ্ঞেয়ঃ(স্) নিত্য়সন্ন্য়াসী,  য়ো ন দ্বেষ্টি ন কাঙ্ক্ষতি\nনির্দ্বন্দ্বো  হি মহাবাহো, সুখং(ম্) বন্ধার্ত্প্রমুচ্য়তে ꠱5.3꠱",

    "সাঙ্খ্য়য়োগৌ পৃথগ্বালাঃ(ফ্), প্রবদন্তি না  পন্ডিতাঃ \nএকমপ্যাস্থিতঃ(স্ )  সম্যগ্, উভয়োর্বিন্দতে ফলম্ ꠱5.4꠱",

    "য়ত্সাঙ্খ্য়ৈঃ(ফ্) প্রাপ্যতে স্থানং(ন্),তদ্যোগৈরপি গম্যতে \nএকম্(ঞ্) যোগং(ঞ্) চ যোগং(ঞ)  চ, য়ঃ(ফ্)পশ্যতি স পশ্যতি ꠱5.5꠱",
    
    "সন্ন্য়াসস্তু মহাবাহো, দুঃখমাপ্তুময়োগতঃ\nযোগযুক্তো মুনির্ব্রহ্ম, নচিরেণাধিগচ্ছতি ꠱5.6꠱",

    "যোগযুক্তো বিশুদ্ধাত্মা, বিজিতাত্মা জিতেন্দ্রিয়ঃ \nসর্বভূতাত্মভূতাত্মা, কুর্বন্নপি ন লিপ্যতে ꠱5.7꠱",

    "নৈব কিঞ্চিত্করোমীতি, যুক্তো মন্যেত তত্ত্ববিত্ \nপশ্য়ঞ্শৃণ্বন্স্পৃশঞ্জিঘ্রন্, নশ্নন্গচ্ছন্স্বপঞ্শ্বসন্꠱5.8꠱",

    "প্রলপন্বিসৃজন্গৃহ্ণন্ ,নুন্মিষন্নিমিষন্নপি \nইন্দ্রিয়াণীন্দ্রিয়ার্থেষু , বর্তন্ত ইতি ধারয়ন্ ꠱5.9꠱",

    "ব্রহ্মণ্য়াধায় কর্মাণি, সঙ্গং(ন্) ত্য়ক্ত্বা করোতি য়ঃ\nলিপ্যতে ন স পাপেন, পদ্মপত্রমিবাম্ভসা ꠱5.10꠱",

    "কাযেন মনসা বুদ্ধ্যা, কেবলৈরিন্দিয়ৈরপি \nযোগিনঃ (খ্) কর্ম কুরবন্তি, সংগং (ন্)ত্যক্ত্বাত্মশুদ্ধযে ꠱5.11꠱",

    "যুক্তঃ(খ্) কর্মফলং(ন্) ত্যক্ত্বা ,.শান্তিমাপ্নোতি নৈষ্ঠিকীম্ \nঅযুক্তঃ(খ্) কামকারেণ, ফলে সক্তো নিবধ্যতে ꠱5.12꠱",

    "সর্বকর্মাণি মনসা, সন্ন্যস্যাস্তে সুখং(ম্) বশী \nনবদ্বারে পুরে দেহী নৈব, কুর্বন্ন কারযন্ ꠱5.13꠱",

    "ন কর্তৃত্বং(ন্) ন কর্মাণি, লোকস্য সৃজতি প্রভুঃ \nন কর্মফলসংযোগং(ম্) ,স্বভাবস্তু প্রবর্ততে ꠱5.14꠱",

    "নাদত্তে কস্যচিত্পাপং(ন্), ন চৈব সুকৃতং(ম্) বিভুঃ\nঅজ্ঞানেনাবৃতং(ঞ)জ্ঞানং(ন্), তেন মুহ্যন্তি জন্তবঃ ꠱5.15꠱",

    "জ্ঞানেন তু তদজ্ঞানং(ম্), য়েষাং(ন্) নাশিতমাত্মনঃ\nতেষামাদিত্যবজ্ঞানং(ম্), প্রকাশয়তি তত্পরম্ ꠱5.16꠱",

    "তদ্বুদ্ধয়স্তদাত্মন:(স্), তন্নিষ্ঠাস্তত্পরায়ণাঃ\nগচ্ছন্ত্য়পুনরাবৃত্তিং (ঞ), জ্ঞাননির্ধূতকল্মষা: ꠱5.17꠱",

    "বিদ্যাবিনয়সম্পন্নে, ব্রাহ্মণে গবি হস্তিনি।\nশুনি চৈব শ্বপাকে চ, পণ্ডিতা:(স্)সমদর্শিন: ꠱5.18꠱",

    "ইহৈব তৈর্জিতঃ(স্)সর্গো, য়েষাং(ম্)সাম্য়ে স্থিতং(ম্)মনঃ।\nনির্দোষং(ম্)হি সমং(ম্)ব্রহ্ম, তাসমাদ্ ব্রহ্মণি তে স্থিতাঃ ꠱5.19꠱",

    "ন প্রহৃষ্যেত্প্রিয়মং(ম্)প্রাপ্য, নোদ্বিজেত্রাপ্য চাপ্রিয়ম্।\nস্হিরবুদ্ধিরসম্মূঢো, ব্রহ্মবিদ্ ব্রহ্মণি স্থিতঃ ꠱5.20꠱",

    "বাহ্যস্পর্শেষ্বসক্তাত্মা, বিন্দত্যাত্মনি য়ত্সুখম্।\nস ব্রহ্মযোগয়ুক্তাত্মা, সুখমক্ষয়মশ্নুতে ꠱5.21꠱",

    "য়ে হি সংস্পর্শজা ভোগা, দুঃখয়োনয় এব তে।\nআদ্যন্তবন্তঃ(খ্)কৌন্তেয়, ন তেষু রমতে বুধ: ꠱5.22꠱",

    "শক্নোতীহৈব য়ঃ(স্)সোঢুং(ম্), প্রাক্শরীরবিমক্ষোণাত্।\nকামক্রোধোদ্ভং(ম্)বৈগং(ম্), স য়ুক্ত:(স্)স সুখী নর: ꠱5.23꠱",

    "যোsন্তঃসুখোऽন্তরারাম:(স্), তথান্তর্জ্যোতিরেব য়ঃ।\nস যোগী ব্রহ্মনির্বাণং(ম্), ব্রহ্মভূতোধিগচ্ছতি ꠱5.24꠱",

    "লভন্তে ব্রহ্মনির্বাণম্, ঋষয়ঃ ক্ষীণকল্মষাঃ।\nছিন্নদ্বৈধা য়াতাত্মনঃ(স্), সর্বভূতহিতে রতাঃ ꠱5.25꠱",

    "কামক্রোধবিয়ুক্তানাম্ (ম্), য়তিনাং(ম্) য়তচেতসাম্।\nঅভিতো ব্রহ্মনির্বাণং (ম্), বর্ততে বিদিতাত্মনাম্ ꠱5.26꠱",

    "স্পর্শান্কৃত্বা বহির্বাহ্য়াংশ্,  চক্ষুশ্চৈবান্তরে ভ্রূবোঃ।\nপ্রাণাপানৌ সমৌ কৃত্বা, নাসাভ্যন্তরচারিণৌ ꠱5.27꠱",

    " য়তেন্দ্রিয়মনোবুদ্ধি:(র্), মুনির্মোক্ষপরায়ণঃ।\nবিগতেচ্ছাভয়ক্রোধো,য়ঃ(স্)সদা মুক্ত এব সঃ ꠱5.28꠱",

    "ভোক্তারং(ম্) য়জ্ঞতপসাং(ম্),সর্বলোকমহেশ্বরম্ ।\nসুহৃদং (ম্) সর্বভূতানাং(ঞ), জ্ঞাত্বা মাং(ম্)শান্তিমৃচ্ছতি ꠱5.29꠱",

    "ॐ তৎসদিতি  শ্রীমদ্ভগবদ্গীতাসু উপনিষৎসু ব্রহ্মবিদ্যায়াং(য়্ঁ)য়োগশাস্ত্রে \nশ্রীকৃষ্ণার্জুন সংবাদে কর্মসন্ন্যাসয়োগো নাম পঞ্চমোऽধ্যায়ঃ ॥5॥"
  ],
  "adhyaya-6" : [
    "শ্রীভগবানুবাচ\nঅনাশ্রিতঃ(খ্) কর্মফলং(ঙ্), কার্য়ং(ঙ্) কর্ম করোতি য়: ।\nস সন্ন্যাসী চ য়োগী চ , ন নিরগ্নির্ন চাক্রিয়: ॥6.1॥",

    "য়ং(ম্) সন্ন্যাসমিতি প্রাহু:(র্), য়োগং(ন্) তং(ম্) বিদ্ধি পাণ্ডব ।\nন হ্যসন্ন্যস্তসঙ্কল্পো, য়োগী ভবতি কশ্চন ॥6.2॥",

    "আরুরুক্ষোর্মুনের্য়োগং(ঙ্), কর্ম কারণমুচ্যতে ।\nয়োগারূঢস্য  তস্যৈব ,শমঃ কারণমুচ্যতে ॥6.3॥",

    "য়দা হি নেন্দ্রিয়ার্থেষু,  ন কর্মস্বনুষজ্জতে।\nসর্বসঙ্কল্পসন্ন্যাসী,  য়োগারূঢ়স্তদোচ্যতে॥6.4॥",

    "উদ্ধরেদাত্মনাত্মানং(ন্), নাত্মানমবসাদয়েত্ ।\nআত্মৈব হ্যাত্মনো বন্ধুঃ(র্), আত্মৈব রিপুরাত্মনঃ ॥6.5॥",

    "বন্ধুরাত্মাত্মনস্তস্য, য়েনাত্মৈবাত্মনা জিতঃ ।\nঅনাত্মনস্তু শত্রুত্বে , বর্তেতাত্মৈব শত্রুবত্ ॥6.6॥",

    "জিতাত্মনঃ(ফ্) প্রশান্তস্য , পরমাত্মা সমাহিতঃ ।\nশীতোষ্ণসুখদুঃখেষু , তথা মানাপমানয়ো: ॥6.7॥",

    "জ্ঞানবিজ্ঞানতৃপ্তাত্মা , কূটস্থো বিজিতেন্দ্রিয়: ।\nয়ুক্ত ইত্যুচ্যতে য়োগী, সমলোষ্টাশ্মকাঞ্চন: ॥6.8॥",

    "সুহৃন্মিত্রার্য়ুদাসীন , মধ্য়স্থদ্বেষ্য়বন্ধুষু  ।\nসাধুষ্বপি চ পাপেষু , সমবুদ্ধির্বিশিষ্যতে ॥6.9॥",

    "য়োগী য়ুঞ্জীত  সততম্, আত্মানং(ম্) রহসি স্থিতঃ ।\nএকাকী য়তচিত্তাত্মা , নিরাশীরপরিগ্রহঃ ॥6.10॥",

    "শুচৌ দেশে প্রতিষ্ঠাপ্য, স্থিরমাসনমাত্মনঃ ।\nনাত্যুচ্ছ্রিতং(ন্) নাতিনীচং( ঞ্), চৈলাজিনকুশোত্তরম্ ॥6.11॥",

    "তত্রৈকাগ্রং(ম্) মনঃ(খ্) কৃত্বা, য়তচিত্তেন্দ্রিয়ক্রিয়: ।\nউপবিশ্যাসনে য়ুঞ্জ্যাদ্,   য়োগমাত্মবিশুদ্ধয়ে ॥6.12॥",

    "সমং(ঙ্)কায়শিরোগ্রীবং(ন্), ধারয়ন্নচলং(ম্)  স্থিরঃ ।\nসম্প্রেক্ষ্য নাসিকাগ্রং(ম্) স্বং(ন্), দিশশ্চানবলোকয়ন্॥6.13॥",

    "প্রশান্তাত্মা বিগতভী:(র্), ব্রহ্মচারিব্রতে স্থিতঃ ।\nমনঃ(স্) সংয়ম্য মচ্চিত্তো, য়ুক্ত আসীত মত্পরঃ ॥6.14॥",

    "য়ুঞ্জন্নেবং(ম্) সদাত্মানং(ম্), য়োগী নিয়তমানসঃ ।\nশান্তিং(ন্) নির্বাণপরমাং(ম্), মত্সংস্থামধিগচ্ছতি ॥6.15॥",

    "নাত্যশ্নতস্তু য়োগোsস্তি , ন চৈকান্তমনশ্নত:  ।\nন চাতিস্বপ্নশীলস্য, জাগ্রতো নৈব চার্জুন ॥6.16॥",

    "য়ুক্তাহারবিহারস্য , য়ুক্তচেষ্টস্য কর্মসু ।\nয়ুক্তস্বপ্নাববোধস্য, য়োগো ভবতি দুঃখহা ॥6.17॥",

    "য়দা বিনিয়তং(ঞ্) চিত্তম্, আত্মন্য়েবাবতিষ্ঠতে ।\nনিঃস্পৃহঃ(স্) সর্বকামেভ্যো, য়ুক্ত ইত্যুচ্যতে তদা ॥6.18॥",

    "য়থা দীপো নিবাতস্থো, নেঙ্গতে সোপমা স্মৃতা ।\nয়োগিনো য়তচিত্তস্য, য়ুঞ্জতো য়োগমাত্মনঃ ॥6.19॥",

    "য়ত্রোপরমতে চিত্তং(ন্) , নিরুদ্ধং(ম্) য়োগসেবয়া ।\nয়ত্র চৈবাত্মনাত্মানং(ম্) , পশ্যন্নাত্মনি তুষ্যতি ॥6.20॥",

    "সুখমাত্যন্তিকং( ম্) য়ত্তদ্, বুদ্ধিগ্রাহ্যমতীন্দ্রিয়ম্ ।\nবেত্তি য়ত্র ন চৈবায়ং(ম্),  স্থিতশ্চলতি তত্ত্বতঃ ॥6.21॥",

    "য়ং(ম্) লব্ধ্বা চাপরং(ম্) লাভং(ম্), মন্যতে নাধিকং(ন্) ততঃ ।\nয়স্মিন্ স্হিতো ন দুঃখেন, গুরুণাপি বিচাল্যতে ॥6.22॥",

    "তং(ম্) বিদ্যাদ্ দু:খসংয়োগ, বিয়োগং(ম্) য়োগসজ্ঞিতম্ ।\nস নিশ্চয়েন য়োক্তব্যো, য়োগऽনির্বিণ্নচেতসা ॥6.23॥",

    "সঙ্কল্পপ্রভবান্কামাংস্ , ত্যক্ত্বা সর্বানশেষতঃ ।\nমনসৈবেন্দ্রিয়গ্রামং(ম্), বিনিয়ম্য সমন্তত: ॥6.24॥",

    "শনৈঃ(শ্) শনৈরুপরমেদ্, বুদ্ধ্য়া ধৃতিগৃহীতয়া ।\nআত্মসংস্থং(ম্) মনঃ(খ্) কৃত্বা , ন কিঞ্চিদপি চিন্তয়েত্ ॥6.25॥",

    "য়তো য়তো নিশ্চরতি, মনশ্চঞ্চলমস্থিরম্ ।\nততস্ততো নিয়ম্যৈতদ্, আত্মন্যেব বশং(ন্) নয়েৎ ॥6.26॥",

    "প্রশান্তমনসং( ম্)  হ্যেনং(ম্) ,য়োগিনং(ম্) সুখমুত্তমম্ ।\nউপৈতি শান্তরজসং(ম্), ব্রহ্মভূতমকল্মষম্ ॥6.27॥",

    "য়ুঞ্জন্নেবং(ম্) সদাত্মানং(ম্), য়োগী বিগতকল্মষঃ ।\nসুখেন ব্রহ্মসংস্পর্শম্ , অত্যন্তং(ম্) সুখমশ্নুতে ॥6.28॥",

    "সর্বভূতস্থমাত্মানং(ম্) , সর্বভূতানি চাত্মনি ।\nঈক্ষতে য়োগয়ুক্তাত্মা ,সর্বত্র সমদর্শনঃ ॥6.29॥",

    "য়ো মাং(ম্) পশ্যতি সর্বত্র , সর্বং(ঞ্) চ ময়ি পশ্যতি ।\nতস্যাহং(ন্) ন প্রণশ্যামি , স চ মে ন প্রণশ্যতি ॥6.30॥",

    "সর্বভূতস্থিতং(ম্) য়ো মাং(ম্), ভজত্যেকত্বমাস্থিতঃ ।\nসর্বথা বর্তমানোsপি , স য়োগী ময়ি বর্ততে ॥6.31॥",

    "আত্মৌপম্যেন সর্বত্র ,সমং(ম্) পশ্যতি য়োऽর্জুন ।\nসুখং(ম্) বা য়দি বা দুঃখং(ম্), স য়োগী পরমো মতঃ ॥6.32॥",

    "অর্জুন উবাচ\nয়োsয়ং(ম্) য়োগস্ত্বয়া প্রোক্তঃ(স্) সাম্যেন মধুসূদন ।\nএতস্যাহং(ন্) ন পশ্যামি,চঞ্চলত্বাৎস্থিতিং(ম্) স্থিরাম্ ॥6.33॥",

    "চঞ্চলং(ম্)  হি মনঃ(খ্) কৃষ্ণ, প্রমাথি বলবদ্দৃঢম্ ।\nতস্যাহং(ন্) নিগ্রহং(ম্) মন্যে , বায়োরিব সুদুষ্করম্ ॥6.34॥",

    "শ্রীভগবানুবাচ\nঅসংশয়ং(ম্) মহাবাহো, মনো দুর্নিগ্রহং (ঞ্) চলম্ ।\nঅভ্যাসেন তু কৌন্তেয় ,  বৈরাগ্যেণ চ গৃহ্যতে ॥6.35॥",

    "অসংয়তাত্মনা য়োগো , দুষ্প্রাপ ইতি মে মতিঃ ।\nবশ্যাত্মনা তু য়ততা , শক্যোsবাপ্তুমুপায়ত:  ॥6.36॥",

    "অর্জুন উবাচ\nঅয়তিঃ(শ্) শ্রদ্ধয়োপেতো, য়োগাচ্চলিতমানসঃ ।\nঅপ্রাপ্য য়োগসংসিদ্ধিং(ঙ্) , কাং(ঙ্) গতিং(ঙ্) কৃষ্ণ গচ্ছতি ॥6.37॥",

    "কচ্চিন্নোভয়বিভ্রষ্টঃ(শ্), ছিন্নাভ্রমিব নশ্যতি ।\nঅপ্রতিষ্ঠো মহাবাহো , বিমূঢো ব্রহ্মণঃ(ফ্) পথি ॥6.38॥",

    "এতন্মে সংশয়ং(ঙ্) কৃষ্ণ , ছেত্তুমর্হস্যশেষতঃ ।\nত্বদন্যঃ(স্) সংশয়স্য়াস্য় ,ছেত্তা ন হ্যুপপদ্যতে ॥6.39॥",

    "শ্রীভগবানুবাচ\nপার্থ নৈবেহ নামুত্র , বিনাশস্তস্য বিদ্যতে ।\nন হি কল্যাণকৃত্কশ্চিদ্, দুর্গতিং(ন্) তাত গচ্ছতি ॥6.40॥",

    "প্রাপ্য পুণ্যকৃতাং(ম্) লোকান্ ,উষিত্বা শাশ্বতীঃ(স্) সমাঃ ।\nশুচীনাং(ম্) শ্রীমতাং(ঙ্) গেহে, যোগভ্রষ্টোsভিজায়তে  ॥6.41॥",

    "অথবা য়োগিনামেব ,কুলে ভবতি ধীমতাম্ ।\nএতদ্ধি দুর্লভতরং(ম্) ,লোকে জন্ম য়দীদৃশম্  ॥6.42॥",

    "তত্র তং(ম্) বুদ্ধিসংয়োগং(ম্) ,লভতে পৌর্বদেহিকম্ ।\nয়ততে  চ ততো ভূয়:(স্) ,সংসিদ্ধৌ কুরুনন্দন ॥6.43॥",

    "পূর্বাভ্যাসেন তেনৈব , হ্রিয়তে হ্যবশোsপি  সঃ ।\nজিজ্ঞাসুরপি য়োগস্য ,শব্দব্রহ্মাতিবর্ততে ॥6.44॥",

    "প্রয়ত্নাদ্য়তমানস্তু ,য়োগী সংশুদ্ধকিল্বিষঃ ।\nঅনেকজন্মসংসিদ্ধ:(স্) ,ততো য়াতি পরাং(ঙ্) গতিম্ ॥6.45॥",

    "তপস্বিভ্যোऽধিকো য়োগী ,জ্ঞানিভ্যোsপি মতোsধিক: ।\nকর্মিভ্যশ্চাধিকো য়োগী, তস্মাদ্যোগী ভবার্জুন ॥6.46॥",

    "য়োগিনামপি সর্বেষাং(ম্), মদ্গতেনান্তরাত্মনা ।\nশ্রদ্ধাবান্ভজতে য়ো মাং(ম্), স মে য়ুক্ততমো মতঃ ॥6.47॥",
    
    "ॐ তৎসদিতি শ্রীমদ্ভগবদ্গীতাসু উপনিষৎসু  ব্রহ্মবিদ্যায়াং(য়্ঁ) য়োগশাস্ত্রে \nশ্রীকৃষ্ণার্জুনসংবাদে আত্মসংয়ময়োগো নাম ষষ্ঠোऽধ্যায়ঃ ॥6॥"
  ],
  "adhyaya-7" : [
    "শ্রীভগবানুবাচ\nময়্যাসক্তমনাঃ(ফ্) পার্থ, য়োগং(ম্) য়ুঞ্জন্মদাশ্রয়ঃ, \nঅসংশয়ং(ম্) সমগ্রং(ম্) মাং(ম্), য়থা  জ্ঞাস্যসি তচ্ছৃণু ॥7.1॥",

    "জ্ঞানং(ন্) তেsহং(ম্) সবিজ্ঞানম্, ইদং(ম্) বক্ষ্যাম্যশেষতঃ,\nয়জ্জ্ঞাত্বা নেহ ভূয়োsন্যজ্, জ্ঞাতব্যমবশিষ্যতে ॥7.2॥",

    "মনুষ্যাণাং(ম্) সহস্রেষু , কশ্চিদ্য়ততি সিদ্ধয়ে,\nয়ততামপি সিদ্ধানাং(ঙ্) , কশ্চিন্মাং(ম্) বেত্তি তত্ত্বতঃ ॥7.3॥",

    "ভূমিরাপোsনলো  বায়ুঃ(খ্),  খং(ম্) মনো বুদ্ধিরেব চ, \nঅহঙ্কার ইতীয়ং(ম্) মে , ভিন্না প্রকৃতিরষ্টধা ॥7.4॥",

    "অপরেয়মিতস্ত্বন্যাং(ম্), প্রকৃতিং(ম্) বিদ্ধি মে পরাম্, \nজীবভূতাং(ম্) মহাবাহো, য়য়েদং(ন্) ধার্য়তে  জগৎ ॥7.5॥",

    "এতদ্য়োনীনি ভূতানি, সর্বাণীত্যুপধারয়,\nঅহং(ঙ্) কৃৎস্নস্য জগতঃ(ফ্), প্রভবঃ(ফ্) প্রলয়স্তথা ॥7.6॥",

    "মত্তঃ(ফ্) পরতরং(ন্) নান্যৎ, কিঞ্চিদস্তি ধনঞ্জয়,\nময়ি সর্বমিদং(ম্) প্রোতং(ম্), সূত্রে মণিগণা ইব ॥7.7॥",

    "রসোsহমপ্সু কৌন্তেয়, প্রভাস্মি শশিসূর্যয়োঃ,\nপ্রণবঃ(স্) সর্ববেদেষু , শব্দঃ(খ্) খে পৌরুষং(ন্) নৃষু ॥7.8॥",

    "পুণ্যো গন্ধঃ(ফ্) পৃথিব্যাং(ঞ্) চ, তেজশ্চাস্মি বিভাবসৌ, \nজীবনং(ম্) সর্বভূতেষু ,তপশ্চাস্মি তপস্বিষু ॥7.9॥",

    "বীজং(ম্) মাং(ম্) সর্বভূতানাং(ম্), বিদ্ধি পার্থ সনাতনম্,\nবুদ্ধির্বুদ্ধিমতামস্মি, তেজস্তেজস্বিনামহম্ ॥7.10॥",

    "বলং(ম্) বলবতাং(ঞ্) চাহং(ঙ্),কামরাগবিবর্জিতম্,\nধর্মাবিরুদ্ধো ভূতেষু, কামোsস্মি ভরতর্ষভ ॥7.11॥",

    "য়ে চৈব সাত্ত্বিকা ভাবা , রাজসাস্তামসাশ্চ য়ে, \nমত্ত এবেতি তান্ বিদ্ধি ,ন ত্বহং(ন্) তেষু তে ময়ি ॥7.12॥",

    "ত্রিভির্গুণময়ৈর্ভাবৈ:(র্), এভিঃ(স্) সর্বমিদং(ঞ্) জগৎ,\nমোহিতং(ন্) নাভিজানাতি, মামেভ্যঃ(ফ্) পরমব্যয়ম্ ॥7.13॥",

    "দৈবী হ্যেষা গুণময়ী, মম মায়া দুরত্যয়া,\nমামেব যে প্রপদ্যন্তে, মায়ামেতাং(ন্) তরন্তি তে ॥7.14॥",

    "ন মাং(ন্) দুষ্কৃতিনো মূঢ়াঃ(ফ্), প্রপদ্যন্তে নরাধমাঃ,\nমায়য়াপহৃতজ্ঞানা , আসুরং(ম্) ভাবমাশ্রিতাঃ ॥7.15॥",

    "চতুর্বিধা ভজন্তে মাং(ঞ্),  জনাঃ(স্) সুকৃতিনোsর্জুন,\nআর্তো জিজ্ঞাসুরর্থার্থী , জ্ঞানী চ ভরতর্ষভ ॥7.16॥",

    "তেষাং(ঞ্) জ্ঞানী নিত্যয়ুক্ত , একভক্তির্বিশিষ্যতে,\nপ্রিয়ো হি জ্ঞানিনোsত্যর্থম্ ,অহং(ম্) স চ মম প্রিয়ঃ ॥7.17॥",

    "উদারাঃ(স্) সর্ব এবৈতে , জ্ঞানী ত্বাত্মৈব মে মতম্, \nঅস্থিতঃ(স্) স হি যুক্তাত্মা, মামেবানুত্তমাং(ঙ্) গতিম্ ॥7.18॥",

    "বহুনাং(ঞ্)জন্মনামন্তে, জ্ঞানবান্মাং(ম্) প্রপদ্যতে, \nবাসুদেবঃ(স্) সর্বমিতি,স মহাত্মা সুদুর্লভঃ ॥7.19॥",

    "কামৈস্তৈস্তৈর্হৃতজ্ঞানাঃ(ফ্), প্রপদ্যন্তেsন্যদেবতাঃ,\nতং(ন্) তং(ন্) নিয়মমাস্থায়, প্রকৃত্যা নিয়তাঃ(স্) স্বয়া ॥7.20॥",

    "য়ো য়ো য়াং(ম্) য়াং(ন্) তনুং(ম্) ভক্তঃ(শ্), শ্রদ্ধয়ার্চিতুমিচ্ছতি,\nতস্য তস্যাচলাং(ম্) শ্রদ্ধাং(ন্),তামেব বিদধাম্যহম্ ॥7.21॥",

    "স তয়া শ্রদ্ধয়া য়ুক্ত:(স্), তস্যয়ারাধনমীহতে ,\nলভতে চ ততঃ(খ্)কামান্, ময়ৈব বিহিতান্হি তান্ ॥7.22॥",

    "অন্তবত্তু ফলং(ন্)তেষাং(ন্), তদ্ভবত্যল্পমেধসাম্,\nদেবান্ দেবয়জো য়ান্তি ,মদ্ভক্তা য়ান্তি  মামপি ॥7.23॥",

    "অব্যক্তং(ম্) ব্যক্তিমাপন্নং(ম্), মন্যন্তে মামবুদ্ধয়ঃ, \nপরং(ম্) ভাবমজানন্তো, মমাব্যয়মনুত্তমম্ ॥7.24॥",

    "নাহং(ম্)প্রকাশঃ(স্) সর্বস্য, যোগমায়াসমাবৃতঃ,\nমূঢ়োsয়ং(ন্) নাভিজানাতি, লোকো মামজমব্যয়ম্ ॥7.25॥",

    "বেদাহং(ম্) সমতীতানি , বর্তামানানি চার্জুন,\nভবিষ্যাণি চ ভূতানি , মাং(ম্) তু বেদ ন কশ্চন ॥7.26॥",

    "ইচ্ছাদ্বেষসমুত্থেন , দ্বন্দ্বমোহেন ভারত,\nসর্বভূতানি সম্মোহং (ম্), সর্গে য়ান্তি পরন্তপ ॥7.27॥",

    "য়েষাং(ম্)  ত্বন্তগতং(ম্) পাপং(ঞ্), জনানাং(ম্) পুণ্যকর্মণাম্,\nতে দ্বন্দ্বমোহনির্মুক্তা, ভজন্তে মাং(ম্) দৃঢ়ব্রতাঃ ॥7.28॥",

    "জরামরণমোক্ষায় , মামাশ্রিত্য য়তন্তি য়ে ,\nতে ব্রহ্ম তদ্বিদুঃ(খ্) কৃৎস্নং(ম্), অধ্যাত্ম(ঙ্) কর্ম চাখিলম্ ॥7.29॥",

    "সাধিভূতাধিদৈবং(ম্) মাং(ম্), সাধিয়জ্ঞং(ঞ্) চ য়ে বিদুঃ,\nপ্রয়াণকালেsপি  চ মাং(ন্), তে বিদুর্যুক্তচেতসঃ ॥7.30॥",
    
    "ॐ তৎসদিতি শ্রীমদ্ভগবদ্গীতাসু উপনিষৎসু ব্রহ্মবিদ্য়ায়াং(য়্ঁ) য়োগশাস্ত্রে \nশ্রীকৃষ্ণার্জুন সংবাদে জ্ঞানবিজ্ঞানয়োগো নাম সপ্তমোऽধ্যায়ঃ ॥7॥"
  ],
  "adhyaya-8" : [
    "অর্জুন উবাচ\nকিং(ন্) তদ্ব্রহ্ম কিমধ্য়াত্মং(ঙ্),কিং(ঙ্) কর্ম পুরুষোত্তম\nঅধিভূতং(ঞ্) চ কিং(ম্) প্রোক্তং(ম্), অধিদৈবং(ঙ্) কিমুচ্য়তে ॥8.1॥",

    "অধিয়জ্ঞঃ(খ্)কথং(ঙ্)কোsত্র, দেহেs স্মিন্মধুসূদন \nপ্রয়াণকালে চ কথং( ঞ্),জ্ঞেয়োsসি নিয়তাত্মভিঃ ॥8.2॥",

    "শ্রীভগবানুবাচ\nঅক্ষরং(ম্) ব্রহ্ম পরমং(ম্), স্বভাবোsধ্য়াত্মমুচ্য়তে \nভূতভাবোদ্ভবকরো, বিসর্গঃ(খ্) কর্মসংজ্ঞিতঃ ॥8.3॥",

    "অধিভূতং(ঙ্) ক্ষরো ভাবঃ(ফ্), পুরুষশ্চাধিদৈবতম্\nঅধিয়জ্ঞোsহমেবাত্র, দেহে দেহভৃতাং(ম্) বর ॥8.4॥",

    "অন্তকালে চ মামেব, স্মরন্মুক্ত্বা কলেবরম্\nয়ঃ(ফ্) প্রয়াতি স মদ্ভাবং(ম্), য়াতি নাস্ত্য়ত্র সংশয়ঃ ॥8.5॥",

    "য়ং(ম্) য়ং(ম্) বাপি স্মরন্ভাবং(ন্), ত্য়জত্য়ন্তে কলেবরম্\nতং(নি) তমেবৈতি কৌন্তেয়, সদা তদ্ভাবভাবিতঃ ॥8.6॥",

    "তস্মাত্সর্বেষু কালেষু , মামনুস্মর য়ুধ্য় চ |\nময়্য়র্পিতমনোবুদ্ধি:(র্), মামেবৈষ্য়স্য়সংশয়ম্ ॥8.7॥",

    "অভ্য়াসয়োগয়ুক্তেন , চেতসা নান্য়গামিনা \nপরমং(ম্) পুরুষং(ন্) দিব্য়ং(ম্), য়াতি পার্থানুচিচয়ন্ ॥8.8॥",

    "কবিং(ম্) পুরাণমনুশাসিতারম্ ,\nঅণোরণীয়াংসমনুস্মরেদ্য়:\nসর্বস্য় ধাতারমচিন্ত্য়রূপম্ \nআদিত্য়বর্ণং(ন্) তমসঃ(ফ্) পরাত্ ॥8.9॥",

    "প্রয়াণকালে মনসাsচলেন , \nভক্ত্য়া য়ুক্তো য়োগবলেন চৈব \nভ্রুবোর্মধ্য়ে প্রাণমাবেশ্য় সম্য়ক্ , \nস তং(ম্) পরং(ম্) পুরুষমুপৈতি  দিব্যম্ ॥8.10॥",

    "য়দক্ষরং(ম্) বেদবিদো বদন্তি, \nবিশন্তি য়দ্য়তয়ো বীতরাগাঃ \nয়দিচ্ছন্তো ব্রহ্মচর্য়ং (ঞ্)চরন্তি , \nতত্তে পদং(ম্) সংগ্রহেণ প্রবক্ষ্য়ে ॥8.11॥",

    "সর্বদ্বারাণি সংয়ম্য় , মনো হৃদি নিরুধ্য় চ \nমূর্ধ্ন্য়াধায়াত্মনঃ(ফ্), প্রাণম্ ,আস্থিতো য়োগধারণাম্ ॥8.12॥",

    "ওমিত্যেকাক্ষরং(ম্) ব্রহ্ম,  ব্য়াহরন্মামনুস্মরন \nয়ঃ(ফ্) প্রয়াতি ত্য়জন্দেহং(ম্), স য়াতি পরমাং(ঙ্) গতিম্ ॥8.13॥",

    "অনন্য়চেতাঃ(স্) সততং(ম্), য়ো মাং(ম্) স্মরতি নিত্য়শঃ \nতস্য়াহং(ম্) সুলভঃ(ফ্) পার্থ, নিত্য়য়ুক্তস্য় য়োগিনঃ ॥8.14॥",

    "মামুপেত্য় পুনর্জন্ম , দুঃখালয়মশাশ্বতম্\nনাপ্নুবন্তি মহাত্মানঃ(স্) ,সংসিদ্ধিং(ম্) পরমাং(ঙ্) গতাঃ ॥8.15॥",

    "আব্রহ্মভুবনাল্লোকাঃ(ফ্), পুনরাবর্তিনোsর্জুন \nমামুপেত্য় তু কৌন্তেয়, পুনর্জন্ম ন বিদ্য়তে ॥8.16॥",

    "সহস্রয়ুগপর্য়ন্তম্, অহর্য়দ্ব্রহ্মণো বিদু: \nরাত্রিং(ম্) য়ুগসহস্রান্তাং(ন্),তেsহোরাত্রবিদো জনাঃ ॥8.17॥",

    "অব্যক্তাদ্ব্যক্তয়ঃ(স্) সর্বাঃ(ফ্), প্রভবন্ত্যরাগমে \nরাত্র্যাগমে প্রলীয়ন্তে, তত্রৈবাব্যক্তসংজ্ঞকে ॥8.18॥",

    "ভূতগ্রামঃ(স্) স এবায়ং(ম্), ভূত্বা ভূত্বা প্রলীয়তে \nরাত্র্য়াগমেsবশঃ(ফ্) পার্থ,  প্রভবত্যহরাগমে ॥8.19॥",

    "পরস্তস্মাত্তু ভাবোsন্যোsব্যক্তোs ব্যক্তাত্সনাতনঃ \nয়ঃ(স্) স সর্বেষু ভূতেষু, নশ্যত্সু ন বিনশ্যতি ॥8.20॥",

    "অব্যক্তোsক্ষর ইত্যুক্ত:(স্),তমাহুঃ(ফ্) পরমাং(ঙ্) গতিম্\nয়ং(ম্) প্রাপ্য ন নিবর্তন্তে, তদ্ধাম পরমং(ম্) মম ॥8.21॥",

    "পুরুষঃ(স্) স পরঃ(ফ্) পার্থ , ভক্ত্যালভ্যস্ত্বনন্যয়া \nয়স্যান্তঃস্থানি ভূতানি , য়েন সর্বমিদং(ন্) ততম্ ॥8.22॥",

    "য়ত্র কালে ত্বনাবৃত্তিম্ , আবৃত্তিং(ঞ্) চৈব য়োগিনঃ \nপ্রয়াতা য়ান্তি তং(ঙ্) কালং(ম্), বক্ষ্যামি ভরতর্ষভ ॥8.23॥",

    "অগ্নিজ্যোতিরহঃ(শ্) শুক্লঃ(ষ্) , ষণ্মাসা উত্তরায়নম্\nতত্র প্রয়াতা গচ্ছন্তি , ব্রহ্ম ব্রহ্মবিদো জনাঃ ॥8.24॥",

    "ধূমো রাত্রিস্তথা কৃষ্ণঃ(ষ্), ষণ্মাসা দক্ষিণায়নম্\nতত্র চান্দ্রমসং(ঞ্)  জ্যোতি:(র্) ,  য়োগী প্রাপ্য নিবর্ততে ॥8.25॥",

    "শুক্লকৃষ্ণে গতী হ্যেতে , জগতঃ(শ্) শাশ্বতে মতে \nএকয়া য়াত্যনাবৃত্তিম্, অন্যয়াবর্ততে পুনঃ ॥8.26॥",

    "নৈতে সৃতী পার্থ জানন্, য়োগী  মুহ্যতি কশ্চন \nতস্মাত্সর্বেষু কালেষু ,য়োগয়ুক্তো ভবার্জুন ॥8.27॥",

    "বেদেষু য়জ্ঞেষু তপঃ(স্)সু চৈব , \nদানেষু য়ত্পুণ্যফলং(ম্) প্রদিষ্টম্\nঅত্যেতি তত্সর্বমিদং(ম্) বিদিত্বা, \nয়োগী পরং(ম্) স্থানমুপৈতি চাদ্যম্ ॥8.28॥",
    
    "ॐ তৎসদিতি শ্রীমদ্ভগবদ্গীতাসু উপনিষৎসু ব্রহ্মবিদ্যায়াং(য়্ঁ) য়োগশাস্ত্রে \nশ্রীকৃষ্ণার্জুনসংবাদে অক্ষরব্রহ্ময়োগো নাম অষ্টমোऽধ্যায়ঃ ॥8॥"
  ],
  "adhyaya-9" : [
    "শ্রীভগবানুবাচ\nইদং(ন্) তু তে গুহ্যতমং(ম্), প্রবক্ষ্যাম্যনসূয়বে।\nজ্ঞানং(ব্ঁ) বিজ্ঞানসহিতং(য়্ঁ), যজ্জ্ঞাত্বা মোক্ষ্যসেऽশুভাৎ ॥9.1॥",

    "রাজবিদ্যা রাজগুহ্যং(ম্), পবিত্রমিদমুত্তমম্\nপ্রত্যক্ষাবগমং(ন্) ধর্ম্যং(ম্), সুসুখং(ঙ) কর্তুমব্যয়ম ॥9.2॥",

    "অশ্রদ্দধানাঃ(ফ্) পুরুষা, ধর্মস্যাস্য পরন্তপ\nঅপ্রাপ্য মাং(ন্) নিবর্তন্তে, মৃত্যুসংসারবর্ত্মনি ॥9.3॥",

    "ময়া ততমিদং(ম্) সর্বং(ঞ), জগদব্যক্তমূর্তিনা\nমৎস্থানি সর্বভূতানি, ন চাহং(ন্) তেষ্ববস্থিতঃ ॥9.4॥",

    "ন চ মৎস্থানি ভূতানি, পশ্য মে যোগমৈশ্বরম্\nভূতভৃন্ন চ ভূতস্থো, মমাত্মা ভূতভাবনঃ ॥9.5॥",

    "যথাকাশস্থিতো নিত্যং(ব্ঁ), বায়ুঃ(স্) সর্বত্রগো মহান্।\nতথা সর্বাণি ভূতানি, মৎস্থানীত্যুপধারয় ॥9.6॥",

    "সর্বভূতানি কৌন্তেয়, প্রকৃতিং(য়্ঁ) য়ান্তি মামিকাম্।\nকল্পক্ষয়ে পুনস্তানি, কল্পাদৌ বিসৃজাম্যহম্ ॥9.7॥",

    "প্রকৃতিং(ম্) স্বামবষ্টভ্য, বিসৃজামি পুনঃ(ফ্) পুনঃ\nভূতগ্রামমিমং(ঙ) কৃৎস্নম্, অবশং(ম্) প্রকৃতের্বশাৎ ॥9.8॥",

    "ন চ মাং(ন্) তানি কর্মাণি, নিবধ্নন্তি ধনঞ্জয়\nউদাসীনবদাসীনম্,  অসক্তং(ন্) তেষু কর্মসু ॥9.9॥",

    "ময়াধ্যক্ষেণ প্রকৃতিঃ(স্), সূয়তে সচরাচরম্\nহেতুনানেন কৌন্তেয়, জগদ্বিপরিবর্ততে ॥9.10॥",

    "অবজানন্তি মাং(ম্) মূঢা, মানুষীং(ন্) তনুমাশ্রিতম্\nপরং(ম্) ভাবমজানন্তো, মম ভূতমহেশ্বরম ॥9.11॥",

    "মোঘাশা মোঘকর্মাণো, মোঘজ্ঞানা বিচেতসঃ\nরাক্ষসীমাসুরীং(ঞ) চৈব, প্রকৃতিং(ম্) মোহিনীং(ম্) শ্রিতাঃ ॥9.12॥",

    "মহাত্মানস্তু মাং(ম্) পার্থ, দৈবীং(ম্) প্রকৃতিমাশ্রিতাঃ\nভজন্ত্যনন্যমনসো, জ্ঞাত্বা ভূতাদিমব্যয়ম্ ॥9.13॥",

    "সততং(ঙ্) কীর্তয়ন্তো মাং(য়্ঁ), য়তন্তশ্চ দৃঢব্রতাঃ।\nনমস্যন্তশ্চ মাং(ম্) ভক্ত্যা, নিত্যয়ুক্তা উপাসতে ॥9.14॥",

    "জ্ঞানযজ্ঞেন চাপ্যন্যে, যজন্তো মামুপাসতে\nএকত্বেন পৃথক্ত্বেন, বহুধা বিশ্বতোমুখম্ ॥9.15॥",

    "অহং(ঙ্) ক্রতুরহং(য়্ঁ) য়জ্ঞঃ(স্), স্বধাহমহমৌষধম্।\nমন্ত্রোऽহমহমেবাজ্যম্, অহমগ্নিরহং(ম্) হুতম্ ॥9.16॥",

    "পিতাহমস্য জগতো, মাতা ধাতা পিতামহঃ\nবেদ্যং(ম্) পবিত্রমোঙ্কার, ঋক্ সাম যজুরেব চ ॥9.17॥",

    "গতির্ভর্তা প্রভুঃ(স্) সাক্ষী, নিবাসঃ(শ্) শরণং(ম্) সুহৃৎ\nপ্রভবঃ(ফ্) প্রলয়ঃ(স্) স্থানং(ন্), নিধানং(ম্) বীজমব্যয়ম্ ॥9.18॥",

    "তপাম্যহমহং(ব্ঁ) বর্ষং(ন্), নিগৃহ্ণাম্যুৎসৃজামি চ।\nঅমৃতং(ঞ্) চৈব মৃত্যুশ্চ, সদসচ্চাহমর্জুন ॥9.19॥",

    "ত্রৈবিদ্যা মাং(ম্) সোমপাঃ(ফ্) পূতপাপা,\nযজ্ঞৈরিষ্ট্বা স্বর্গতিং(ম্) প্রার্থয়ন্তে\nতে পুণ্যমাসাদ্য সুরেন্দ্রলোকম্,\nঅশ্নন্তি দিব্যান্দিবি দেবভোগান্ ॥9.20॥",

    "তে তং(ম্) ভুক্ ত্বা স্বর্গলোকং(ব্ঁ)  বিশালং(ঙ্),\nক্ষীণে পুণ্যে মর্ত্যলোকং(ব্ঁ) বিশন্তি।\nএবং(ন্) ত্রয়ীধর্মমনুপ্রপন্না,\nগতাগতং(ঙ্) কামকামা লভন্তে ॥9.21॥",

    "অনন্যাশ্চিন্তয়ন্তো মাং(য়্ঁ), য়ে জনাঃ(ফ্) পর্যুপাসতে।\nতেষাং(ন্) নিত্যাভিয়ুক্তানাং(য়্ঁ), য়োগক্ষেমং(ব্ঁ) বহাম্যহম্ ॥9.22॥",

    "যেপ্যন্যদেবতা ভক্তা, যজন্তে শ্রদ্ধয়ান্বিতাঃ\nতেপি মামেব কৌন্তেয়, যজন্ত্যবিধিপূর্বকম্ ॥9.23॥",

    "অহং(ম্) হি সর্বযজ্ঞানাং(ম্), ভোক্তা চ প্রভুরেব চ\nন তু মামভিজানন্তি, তত্ত্বেনাতশ্চ্যবন্তি তে ॥9.24॥",

    "যান্তি দেবব্রতা দেবান্, পিতৃ়ন্যান্তি পিতৃব্রতাঃ\nভূতানি যান্তি ভূতেজ্যা, যান্তি মদ্যাজিনোপি মাম্ ॥9.25॥",

    "পত্রং(ম্) পুষ্পং(ম্) ফলং(ন্) তোয়ং(য়্ঁ), য়ো মে ভক্ত্যা প্রয়চ্ছতি।\nতদহং(ম্) ভক্ত্যুপহৃতম্ , অশ্নামি প্রয়তাত্মনঃ ॥9.26॥",

    "যৎকরোষি যদশ্নাসি, যজ্জুহোষি দদাসি যৎ\nযত্তপস্যসি কৌন্তেয়, তৎকুরুষ্ব মদর্পণম্ ॥9.27॥",

    "শুভাশুভফলৈরেবং(ম্), মোক্ষ্যসে কর্মবন্ধনৈঃ\nসন্ন্যাসযোগযুক্তাত্মা, বিমুক্তো মামুপৈষ্যসি ॥9.28॥",

    "সমোহং(ম্) সর্বভূতেষু , ন মে দ্বেষ্যোস্তি ন প্রিয়ঃ\nযে ভজন্তি তু মাং(ম্) ভক্ত্যা , ময়ি তে তেষু চাপ্যহম্ ॥9.29॥",

    "অপি চেৎসুদুরাচারো , ভজতে মামনন্যভাক্\nসাধুরেব স মন্তব্যঃ(স্), সম্যগ্ব্যবসিতো হি সঃ ॥9.30॥",

    "ক্ষিপ্রং(ম্) ভবতি ধর্মাত্মা , শশ্বচ্ছান্তিং(ন্) নিগচ্ছতি\nকৌন্তেয় প্রতিজানীহি , ন মে ভক্তঃ(ফ্) প্রণশ্যতি ॥9.31॥",

    "মাং(ম্) হি পার্থ ব্যপাশ্রিত্য , যেপি স্যুঃ(ফ্) পাপযোনয়ঃ\nস্ত্রিয়ো বৈশ্যাস্তথা শূদ্রাঃ(স্) , তেপি যান্তি পরাং(ঙ) গতিম্ ॥9.32॥",

    "কিং(ম্) পুনর্ব্রাহ্মণাঃ(ফ্) পুণ্যা , ভক্তা রাজর্ষয়স্তথা\nঅনিত্যমসুখং(ম্) লোকম্, ইমং(ম্) প্রাপ্য ভজস্ব মাম্ ॥9.33॥",

    "মন্মনা ভব মদ্ভক্তো , মদ্যাজী মাং(ন্) নমস্কুরু\nমামেবৈষ্যসি যুক্ত্বৈবম্ , আত্মানং(ম্) মৎপরায়ণঃ ॥9.34॥",

    "ॐ তৎসদিতি শ্রীমদ্ভগবদ্গীতাসু উপনিষৎসু ব্রহ্মবিদ্যায়াং(য়্ঁ)\nয়োগশাস্ত্রে শ্রীকৃষ্ণার্জুনসংবাদে রাজবিদ্যারাজগুহ্যয়োগো নাম নবমোऽধ্য়ায়: ॥9॥"
  ],
  "adhyaya-10" : [
    "শ্রীভগবানুবাচ\nভূয় এব মহাবাহো ,শৃণু মে পরমং(ম্) বচঃ\nযত্তেऽহং(ম্) প্রীয়মাণায় ,বক্ষ্যামি হিতকাম্যয়া ॥10.1॥",

    "ন মে বিদুঃ(স্) সুরগণাঃ(ফ্), প্রভবং(ন্) ন মহর্ষয়ঃ\nঅহমাদির্হি দেবানাং(ম্), মহর্ষীণাং    (ঞ্) চ সর্বশঃ ॥10.2॥",

    "য়ো মামজমনাদিং(ঞ্)চ , বেত্তি লোকমহেশ্বরম্\nঅসংমূঢ়ঃ(স্) স মর্ত্যেষু ,সর্বপাপৈঃ(ফ্) প্রমুচ্যতে ॥10.3॥",

    "বুদ্ধির্জ্ঞানমসংমোহঃ , ক্ষমা সত্যং(ন্) দমঃ(শ্) শমঃ\nসুখং(ন্) দুঃখং(ম্)ভবোऽভাবো ,ভয়ং(ঞ্) চাভয়মেব চ ॥10.4॥",

    "অহিংসা সমতা তুষ্টি:(স্) ,তপো দানং(ম্) য়শোऽয়শ: \nভবন্তি ভাবা ভূতানাং(ম্), মত্ত এব পৃথগ্বিধা: ॥10.5॥",

    "মহর্ষয়ঃ(স্) সপ্ত পূর্বে , চত্বারো মনবস্তথা\nমদ্ভাবা মানসা জাতা , যেষাং(ম্)লোক ইমাঃ(ফ্) প্রজাঃ ॥10.6॥",

    "এতাং(ম্) বিভূতিং(ম্) য়োগং(ঞ্) চ, মম য়ো বেত্তি তত্ত্বতঃ\nসোऽবিকম্পেন য়োগেন , য়ুজ্যতে নাত্র সংশয়ঃ ॥10.7॥",

    "অহং(ম্) সর্বস্য প্রভবো , মত্তঃ(স্) সর্বং(ম্) প্রবর্ততে\nইতি মত্বা ভজন্তে মাং(ম্), বুধা ভাবসমন্বিতাঃ ॥10.8॥",

    "মচ্চিত্তা মদ্গতপ্রাণা , বোধয়ন্তঃ(ফ্) পরস্পরম্\nকথয়ন্তশ্চ মাং(ন্) নিত্যং(ন্), তুষ্যন্তি চ রমন্তি চ ॥10.9॥",

    "তেষাং(ম্) সততযুক্তানাং(ম্) ,ভজতাং(ম্) প্রীতিপূর্বকম্\nদদামি বুদ্ধিযোগং(ন্) তং(ম্), য়েন  মামুপযান্তি তে ॥10.10॥",

    "তেষামেবানুকম্পার্থম্, অহমজ্ঞানজং(ন্) তমঃ\nনাশয়াম্যাত্মভাবস্থো, জ্ঞানদীপেন ভাস্বতা ॥10.11॥",

    "অর্জুন উবাচ\nপরং(ম্)  ব্রহ্ম পরং(ন্) ধাম ,পবিত্রং(ম্) পরমং(ম্) ভবান্\nপুরুষং(ম্) শাশ্বতং(ম্) দিব্যম্, আদিদেবমজং(ম্) বিভুম্ ॥10.12॥",

    "আহুস্ত্বামৃষয়ঃ(স্) সর্বে, দেবর্ষিনারদস্তথা\nঅসিতো দেবলো ব্যাসঃ(স্), স্বয়ং(ঞ্) চৈব ব্রবীষি মে ॥10.13॥",

    "সর্বমেতদ্ঋতং(ম্) মন্যে ,যন্মাং(ম্) বদসি কেশব\nন হি তে ভগবন্ব্যক্তিং(ম্) , বিদুর্দেবা ন দানবাঃ ॥10.14॥",

    "স্বয়মেবাত্মনাত্মনং(ম্) ,বেত্থ ত্বং(ম্) পুরুষোত্তম\nভূতভাবন ভূতেশ ,দেবদেব জগৎপতে ॥10.15॥",

    "বক্তুমর্হস্যশেষেণ, দিব্যা হ্যাত্মবিভূতয়ঃ\nযাভির্বিভূতিভির্লোকান্ ,ইমাংস্ত্বং(ম্) ব্যাপ্য তিষ্ঠসি ॥10.16॥",

    "কথং(ম্) বিদ্যামহং(ম্) যোগিংস্ ,ত্বাং((ম্) সদা পরিচিন্তয়ন্\nকেষু কেষু চ ভাবেষু ,চিন্ত্যোऽসি ভগবন্ময়া ॥10.17॥",

    "বিস্তরেণাত্মনো যোগং(ম্) , বিভূতিং( ঞ্) চ জনার্দন\nভূয়ঃ(খ্) কথয় তৃপ্তির্হি , শৃণ্বতো নাস্তি মেऽমৃতম্ ॥10.18॥",

    "শ্রীভগবানুবাচ\nহস্ত তে কথয়িষ্যামি , দিব্যা হ্যাত্মবিভূতয়ঃ\nপ্রাধান্যতঃ(খ্) কুরুশ্রেষ্ঠ ,নাস্ত্যস্তো বিস্তরস্য মে ॥10.19॥",

    "অহমাত্মা গুড়াকেশ ,সর্বভূতাশয়স্থিতঃ\nঅহমাদিশ্চ মধ্যং(ঞ্)চ , ভূতানামন্ত এব চ ॥10.20॥",

    "আদত্যানামহং(ম্)  বিষ্ণু:(র্) জ্যোতিষাং(ম্) রবিরংশুমান্\nমরীচির্মরুতামস্মি , নক্ষত্রাণামহং(ম্) শশী ॥10.21॥",

    "বেদানাং সামবেদোऽস্মি ,দেবনামস্মি বাসবঃ\nইন্দ্রিয়াণাং(ম্) মনশ্চাস্মি ,ভূতানামস্মি চেতনা ॥10.22॥",

    "রুদ্রাণাং(ম্) শঙ্করশ্চাস্মি ,বিত্তেশো যক্ষরক্ষসাম্\nবসূনাং(ম্) পাবকশ্চাস্মি ,মেরুঃ(শ্) শিখরিণামহম্ ॥10.23॥",

    "পুরোধসাং(ঞ্) চ মুখ্যং(ম্) মাং(ম্), বিদ্ধি পার্থ বৃহস্পতিম্\nসেনানীনামহং(ম্)  স্কন্দঃ(স্), সরসামস্মি সাগরঃ ॥10.24॥",

    "মহর্ষীণাং(ম্) ভৃগুরহং(ঙ্), গিরামস্ম্যেকমক্ষরম্\nযজ্ঞানাং(ঞ্)জপযজ্ঞোऽস্মি , স্থাবরাণাং(ম্) হিমালয়ঃ ॥10.25॥",

    "অশ্বত্থঃ(স্) সর্ববৃক্ষাণাং(ন্), দেবষীণাং(ঞ্) চ নারদ: \nগন্ধর্বাণাং(ঞ্)চিত্ররথঃ(স্),সিদ্ধানাং(ঙ্) কপিলো মুনিঃ ॥10.26॥",

    "উচ্ছৈঃশ্রবসমশ্বানাং(ম্), বিদ্ধি মামমৃতোদ্ভবম্\nঐরাবতং(ঙ্)গজেন্দ্রাণাং(ন্),নরাণাং(ঞ্) চ নরাধিপম্ ॥10.27॥",

    "আয়ুধানামহং(ম্) বজ্রং(ন্), ধেনূনামস্মি কামধুক্\nপ্রজন্শ্চাস্মি কন্দর্পঃ(স্), সর্পাণামস্মি বাসুকিঃ ॥10.28॥",

    "অনন্তশ্চাস্মি নাগানাং(ম্),বরুণো যাদসামহম্\nপিতৃৃণামর্য়মা  চাস্মি ,যমঃ(স্) সংযমতামহম্ ॥10.29॥",

    "প্রহ্লাদশ্চাস্মি দৈত্যানাং(ঙ্), কালঃ(খ্) কলয়তামহম্\nমৃগাণাং(ঞ্) চ মৃগেন্দ্রোऽহম্ং(ম্) বৈনতেয়শ্চ পক্ষিণাম্ ॥10.30॥",

    "পবনঃ(ফ্) পবতামস্মি ,রামঃ(শ্) শস্ত্রভৃতামহম্\nঝষাণাং(ম্) মকরশ্চাস্মি, স্রোতসামস্মি জাহ্নবী ॥10.31॥",

    "সর্গাণামাদিরন্তশ্চ ,মধ্যং(ঞ্)  চৈবাহমর্জুন\nঅধ্যাত্মবিদ্যা বিদ্যানাং(ম্),বাদঃ(ফ্) প্রবদতামহম্ ॥10.32॥",

    "অক্ষরাণামকারোऽস্মি , দ্বন্দ্বঃ(স্) সামাসিকস্য চ \nঅহমেবাক্ষয়ঃ(খ্) কালো ,ধাতাহং(ম্) বিশ্বতোমুখঃ ॥10.33॥",

    "মৃত্যুঃ(স্) সর্বহরশ্চাহম্,উদ্ভবশ্চ ভবিষ্যতাম্\nকীর্তিঃ(শ্) শ্রীর্বাক্চ নারীণাং(ম্),স্মৃতির্মেধা ধৃতিঃ ক্ষমা ॥10.34॥",

    "বৃহৎসাম তথা সাম্নাং(ঙ্), গায়ত্রী ছন্দসামহম্\nমাসানাং(ম্) মার্গশীর্ষোऽহম্ ,ঋতূনাং(ঙ) কুসুমাকরঃ ॥10.35॥",

    "দ্যূতং (ঞ্) ছলয়তামস্মি , তেজস্তেজস্বিনামহম্ \nজয়োऽস্মি ব্যবসায়োऽস্মি ,সত্ত্বং(ম্) সত্ত্ববতামহম্ ॥10.36॥",

    "বৃষ্ণীনাং(ম্) বাসুদেবোऽস্মি ,পান্ডবানাং(ন্) ধনঞ্জয়ঃ \nমুনীনামপ্যহং(ম্) ব্যাসঃ(খ্), কবীনামুশনা কবিঃ ॥10.37॥",

    "দন্ডো দময়তামস্মি , নীতিরস্মি জিগীষতাম্ \nমৌনং(ঞ্) চৈবাস্মি গুহ্যানাং(ঞ্),জ্ঞানং(ঞ্) জ্ঞানবতামহম্ ॥10.38॥",

    "যচ্চাপি সর্বভূতানাং(ম্) বীজং(ন্) তদহমর্জুন\nন তদস্তি বিনা যৎস্যান্, ময়া ভূতং(ঞ্) চরাচরম্ ॥10.39॥",

    "নান্তোsস্তি   মম দিব্যানাং(ম্), বিভূতে (ম্) পরন্তপ\nএষ তূদ্দেশতঃ(ফ্)  প্রোক্তো,বিভুতের্বিস্তরো ময়া ॥10.40॥",

    "যদ্যদ্বিভূতিমৎসত্ত্বং(ম্), শ্রীমদূর্জিতমেব বা\nতত্তদেবাবগচ্ছ ত্বং(ম্),মম তেজোংऽশসম্ভবম্ ॥10.41॥",

    "অথবা বহুনৈতেন ,কিং(ঞ্) জ্ঞাতেন তবার্জুন\nবিষ্টভ্যাহমিদং(ঙ্) কৃৎস্নম্, একাংশেন স্থিতো জগৎ ॥10.42॥",
    
    "ॐ তৎসদিতি শ্রীমদ্ভগবদ্গীতাসু উপনিষৎসু ব্রহ্মবিদ্যায়াং(য়্ঁ) য়োগশাস্ত্রে \nশ্রীকৃষ্ণার্জুনসংবাদে বিভূতিয়োগো নাম দশমোऽধ্যায়ঃ ॥10॥"
  ],
  "adhyaya-11" : [
    "অর্জুন উবাচ\nমদনুগ্রহায় পরমং(ঙ্), গুহ্য়মধ্য়াত্মসংজ্ঞিতম্ \nয়ত্ত্বয়োক্তং(ম্) বচস্তেন, মোহোऽয়ং(ম্) বিগতো মম ॥11.1॥",

    "ভবাপ্য়য়ৌ হি ভূতানাং(ম্),শ্রুতৌ বিস্তরশো ময়া \nত্বত্তঃ(খ্) কমলপত্রাক্ষ , মাহাত্ম্য়মপি চাব্যয়ম্ ॥11.2॥",

    "এবমেতদ্য়থাত্থ ত্বম্, আত্মানং(ম্) পরমেশ্বর \nদ্রষ্টুমিচ্ছামি তে রূপং(ম্), ঐশ্বরং(ম্) পুরুষোত্তম ॥11.3॥",

    "মন্য়সে য়দি তচ্ছক্য়ং(ম্), ময়া দ্রষ্টুমিতি প্রভো\nয়োগেশ্বর ততো মে ত্বং(ন্),দর্শয়াত্মানমব্যয়ম্ ॥11.4॥",

    "শ্রীভগবানুবাচ\nপশ্য় মে পার্থ রূপাণি ,শতশোऽথ সহস্রশঃ \nনানাবিধানি দিব্য়ানি, নানাবর্ণাকৃতীনি চ ॥11.5॥",

    "পশ্য়াদিত্য়ান্বসূন্রুদান্ , অশ্বিনৌ মরুতস্তথা\nবহূন্য়দৃষ্টপূর্বাণি ,পশ্য়াশ্চর্য়াণি ভারত ॥11.6॥",

    "ইহৈকস্থং(ঞ্) জগত্কৃত্স্নং(ম্),পশ্য়াদ্য় সচরাচরম্।\nমম দেহে গুডাকেশ , য়চ্চান্য়দ্দ্রষ্টুমিচ্ছসি ॥11.7॥",

    "ন তু মাং(ম্) শক্য়সে দ্রষ্টুম্, অনেনৈব স্বচক্ষুষা \nদিব্য়ং(ন্) দদামি তে চক্ষুঃ(ফ্), পশ্য় মে য়োগমৈশ্বরম ॥11.8॥",

    "সংজয় উবাচ\nএবমুক্ত্বা ততো রাজন্ ,মহায়োগেশ্বরো হরিঃ\nদর্শয়ামাস পার্থায় ,পরমং(ম্) রূপমৈশ্বরম্ ॥11.9॥",

    "অনেকবক্ত্রনয়নম্ ,অনেকাদ্ভুতদর্শনম্\nঅনেকদিব্য়াভরণং(ন্), দিব্য়ানেকোদ্য়তায়ুধম্ ॥11.10॥",

    "দিব্য়মাল্য়াম্বরধরং(ন্),দিব্য়গন্ধানুলেপনম্ \nসর্বাশ্চর্য়ময়ং(ন্) দেবম্, অনন্তং(ম্) বিশ্বতোমুখম ॥11.11॥",

    "দিবি সূর্য়সহস্রস্য় ,ভবেদ্য়ুগপদুত্থিতা\nয়দি ভাঃ(স্) সদৃশী সা স্য়াদ্, ভাসস্তস্য় মহাত্মনঃ ॥11.12॥",

    "তত্রৈকস্থং(ঞ্)জগত্কৃত্স্নং(ম্), প্রবিভক্তমনেকধা \nঅপশ্য়দ্দেবদেবস্য় ,শরীরে পান্ডবস্তদা ॥11.13॥",

    "ততঃ(স্) স বিস্ময়াবিষ্টো, হৃষ্টরোমা ধনংজয়ঃ\nপ্রণম্য় শিরসা দেবং(ঙ্), কৃতাঞ্জলিরভাষত ॥11.14॥",

    "অর্জুন উবাচ\nপশ্য়ামি দেবাংস্তব দেব দেহে,\nসর্বাংস্তথা ভূতবিশেষসংঘান্\nব্রহ্মাণমীশং(ঙ্) কমলাসনস্থম্,\nঋষীংশ্চ সর্বানুরগাংশ্চ দিব্যান্ ॥11.15॥",

    "অনেকবাহূদরবক্ত্রনেত্রং(ম্), \nপশ্য়ামি ত্বাং(ম্) সর্বতোऽনন্তরূপম্\nনান্তং(ন্) ন মধ্য়ং(ন্) ন পুনস্তবাদিং(ম্), \nপশ্য়ামি বিশ্বেশ্বর বিশ্বরূপ ॥11.16॥",

    "কিরীটিনং(ঙ্)গদিনং(ঞ্)চক্রিণং(ঞ্)চ,\nতেজোরাশিং(ম্) সর্বতো দীপ্তিমন্তম্\nপশ্য়ামি ত্বাং(ন্) দুর্নিরীক্ষ্য়ং(ম্) সমন্তাদ্- \nদীপ্তানলার্কদ্য়ুতিমপ্রমেয়ম্ ॥11.17॥",

    "ত্বমক্ষরং(ম্) পরমং(ম্) বেদিতব্য়ং(ন্), \nত্বমস্য় বিশ্বস্য় পরং(ন্) নিধানম্\nত্বমব্য়য়ঃ(শ্) শাশ্বতধর্মগোপ্তা,\nসনাতনস্ত্বং(ম্) পুরুষো মতো মে ॥11.18॥",

    "অনাদিমধ্য়ান্তমনন্তবীর্য়ম্, \nঅনন্তবাহুং(ম্) শশিসূর্য়নেত্রম্ |\nপশ্য়ামি ত্বাং(ন্) দীপ্তহুতাশবক্ত্রং(ম্), \nস্বতেজসা বিশ্বমিদং(ন্) তপন্তম্ ॥11.19॥",

    "দ্য়াবাপৃথিব্য়োরিদমন্তরং(ম্) হি ,\nব্য়াপ্তং(ন্) ত্বয়ৈকেন দিশশ্চ সর্বাঃ \nদৃষ্ট্বাদ্ভুতং(ম্) রূপমুগ্রং(ন্) তবেদং(ম্),\nলোকত্রয়ং(ম্) প্রব্য়থিতং(ম্) মহাত্মন্ ॥11.20॥",

    "অমী হি ত্বাং(ম্) সুরসঙ্ঘা বিশন্তি,\nকেচিদ্ভীতাঃ(ফ্) প্রাঞ্জলয়ো গৃণন্তি \nস্বস্তীত্যুক্ত্বা হবে  মহর্ষিসিদ্ধসঙ্ঘা(স্), \nস্তুবন্তি ত্বাং(ম্) স্তুতিভিঃ(ফ্) পুষ্কলাভিঃ ॥11.21॥",

    "রুদ্রাদিত্য়া বসবো য়ে চ সাধ্য়া- \nবিশ্বেSশ্বিনৌ মরুতশ্চোষ্মপাশ্চ\nগন্ধর্বয়ক্ষাসুরসিদ্ধসঙ্ঘা ,\nবীক্ষন্তে ত্বাং(ম্) বিস্মিতাশ্চৈব সর্বে ॥11.22॥",

    "রূপং(ম্) মহত্তে বহুবক্ত্রনেত্রং(ম্),\nমহাবাহো বহুবাহূরুপাদম্ \nবহূদরং(ম্) বহুদংষ্ট্রাকরালং(ন্),\nদৃষ্ট্বা লোকাঃ(ফ্) প্রব্য়থিতাস্তথাহম্ ॥11.23॥",

    "নভঃস্পৃশং(ন্) দীপ্তমনেকবর্ণং(ম্)\nব্য়াত্তাননং(ন্)দীপ্তবিশালনেত্রম্ \nদৃষ্ট্বা হি ত্বাং(ম্)প্রব্য়থিতান্তরাত্মা ,\nধৃতিং(ন্)  ন বিন্দামি শমং(ঞ্) চ বিষ্ণো ॥11.24॥",

    "দংষ্ট্রাকরালানি চ তে মুখানি,\nদৃষ্ট্বৈব কালানলসন্নিভানি হবে\nদিশো ন জানে ন লভে চ শর্ম,\nপ্রসীদ দেবেশ জগন্নিবাস ॥11.25॥",

    "অমী চ ত্বাং(ন্) ধৃতরাষ্ট্রস্য় পুত্রাঃ(স্),\nসর্বে সহৈবাবনিপালসঙ্ঘৈঃ\nভীষ্মো দ্রোণঃ(স্) সূতপুত্রস্তথাসৌ \nসহাস্মদীয়ৈরপি  য়োধমুখৈঃ ॥11.26॥",

    "বক্ত্রাণি তে ত্বরমাণা বিশন্তি,\nদংষ্ট্রাকরালানি ভয়ানকানি হবে \nকেচিদ্বিলগ্না দশনান্তরেষু,\nসন্দৃশ্যন্তে হবে  চূর্ণিতৈরুত্তমাঙ্গৈঃ ॥11.27॥",

    "য়থা নদীনাং(ম্) বহবোऽম্বুবেগাঃ(স্),\nসমুদ্রমেবাভিমুখা  দ্রবন্তি \nতথা তবামী নরলোকবীরা,\nবিশন্তি বক্ত্রাণ্য়ভিবিজ্বলন্তি ॥11.28॥",

    "য়থা প্রদীপ্তং(ঞ্) জ্বলনং(ম্) পতঙ্গা হবে ,\nবিশন্তি নাশায়  সমৃদ্ধবেগাঃ \nতথৈব নাশায় বিশন্তি লোকা:(স্),\nতবাপি  বক্ত্রাণি সমৃদ্ধবেগাঃ ॥11.29॥",

    "লেলিহ্য়সে গ্রসমানঃ(স্) সমন্তাল্,\nলোকান্সমগ্রান্বদনৈর্জ্বলদ্ভিঃ \nতেজোভিরাপূর্য় জগত্সমগ্রং(ম্),\nভাসস্তবোগ্রাঃ(ফ্) প্রতপন্তি বিষ্ণো ॥11.30॥",

    "আখ্যাহিমে কো ভবানুগ্ররূপো,\nনমোऽস্তু তে দেববর প্রসীদ \nবিজ্ঞাতুমিচ্ছামি   ভবন্তমাদ্যং (ন্),\nন হি প্রজানামি তব প্রবৃত্তিম্ ॥11.31॥",

    "শ্রীভগবানুবাচ\nকালো‌ऽস্মি লোকক্ষয়কৃত্প্রবৃদ্ধো,\nলোকান্সমাহর্তুমিহ প্রবৃত্তঃ \nঋতে‌ऽপি ত্বাং(ন্) ন  ভবিষ্যন্তি সর্বে \nয়ে‌ऽবস্থিতাঃ(ফ্) প্রত্যয়নীকেষু য়োধাঃ ॥11.32॥",

    "তস্মাত্ত্বমুত্তিষ্ঠ য়শো লভস্ব,\nজিত্বা শত্রূন্ ভুঙ্ক্ষ্ব  রাজ্যং (ম্ ) সমৃদ্ধম্ \nময়ৈবৈতে নিহতাঃ(ফ্) পূর্বমেব,\nনিমিত্তমাত্রং(ম্) ভব  সব্যসাচিন্ ॥11.33॥",

    "দ্রোণং(ঞ্) চ ভীষ্মং(ঞ্) চ জয়দ্রথং(ঞ্) চ\nকর্ণং(ন্)  তথান্যানপি য়োধবীরান্ \nময়া হতাংস্ত্বং(ঞ্) জহি মা ব্যথিষ্ঠা\nয়ুধ্যস্ব জেতাসি রণে সপত্নান্ ॥11.34॥",

    "সংজয় উবাচ\nএতচ্ছ্রুত্বা বচনং(ঙ্)  কেশবস্য,\nকৃতাঞ্জলির্বেপমানঃ(খ্) কিরীটী \nনমস্কৃত্বা ভূয় এবাহ কৃষ্ণং(ম্),\nসগদ্গদং(ম্) ভীতভীতঃ(ফ্) প্রণম্য ॥11.35॥",

    "অর্জুন উবাচ\nস্থানে হৃষীকেশ তব  প্রকীর্ত্যা,\nজগত্প্রহৃষ্যত্যনুরজ্যতে চ \nরক্ষাংসি ভীতানি দিশো দ্রবন্তি,\nসর্বে   নমস্যন্তি চ  সিদ্ধসঙ্ঘা ॥11.36॥",

    "কস্মাচ্চ তে ন নমেরন্মহাত্মন্, \nগরীয়সে ব্রহ্মণোSপ্যাদিকর্ত্রে\nঅনন্ত দেবেশ জগন্নিবাস,\nত্বমক্ষরং(ম্) সদসত্তত্পরং(ম্) য়ৎ ॥11.37॥",

    "ত্বমাদিদেবঃ(ফ্) পুরুষঃ(ফ্) পুরাণ:(স্),\nত্বমস্য বিশ্বস্য পরং(ন্) নিধানম্ \nবেত্তাসি বেদ্যং(ঞ্) চ পরং(ঞ্) চ ধাম,\nত্বয়া ততং(ম্) বিশ্বমনন্তরূপ ॥11.38॥",

    "বায়ুর্য়মো‌ऽগনির্বরুণঃ(শ্) শশাঙ্কঃ(ফ্)\nপ্রজাপতিস্ত্বং(ম্) প্রপিতামহশ্চ \nনমো নমস্তে‌ऽস্তু সহস্রকৃত্বঃ(ফ্),\nপুনশ্চ ভূয়ো‌ऽপি নমো নমস্তে ॥11.39॥",

    "নমঃ(ফ্) পুরস্তাদথ পৃষ্ঠতস্তে,\nনমো‌ऽস্তু তে সর্বত এব সর্ব \nঅনন্তবীর্য়ামিতবিক্রমস্ত্বং(ম্),\nসর্বং(ম্) সমাপ্নোষি ততো‌ऽসি সর্বঃ ॥11.40॥",

    "সখেতি মত্বা প্রসভং(ম্) য়দুক্তং(ম্),\nহে কৃষ্ণ হে য়াদব হে সখেতি \nঅজানতা মহিমানং(ন্) তবেদং(ম্),\nময়া প্রমাদাত্প্রণয়েন বাপি ॥11.41॥",

    "য়চ্চাবহাসার্থমসত্কৃতো‌ऽসি,\nবিহারশয়্যাসনভোজনেষু \nএকোSথবাপ্যচ্যুত তত্সমক্ষং(ন্),\nতত্ক্ষাময়ে ত্বামহমপ্রমেয়ম্ ॥11.42॥",

    "পিতাসি লোকস্য চরাচরস্য,\nত্বমস্য় পূজ্যশ্চ গুরুর্গরীয়ান্ \nন ত্বৎসমোSস্ত্যভ্যধিক:(খ্) কুতোSন্যো,\nলোকত্রয়েSপ্যপ্রতিমপ্রভাব ॥11.43॥",

    "তস্মাত্প্রণম্য প্রণিধায় কায়ং(ম্),\nপ্রসাদয়ে ত্বামহমীশমীড্যম্\nপিতেব পুত্রস্য সখেব  সখ্যু:(ফ্ ), \nপ্রিয়ঃ(ফ্)  প্রিয়ায়ার্হসি দেব  সোঢ়ুম্ ॥11.44॥",

    "অদৃষ্টপূর্বং(ম্) হৃষিতো‌ऽস্মি দৃষ্ট্বা,\nভয়েন চ প্রব্যথিতং(ম্ ) মনো মে \nতদেব মে দর্শয় দেবরূপং(ম্),\nপ্রসীদ দেবেশ জগন্নিবাস ॥11.45॥",

    "কিরীটিনং(ঙ্) গদিনং(ঞ্) চক্রহস্তম্,\nইচ্ছামি ত্বাং(ন্) দ্রষ্টুমহং(ন্) তথৈব \nতেনৈব রূপেণ চতুর্ভুজেন,\nসহস্রবাহো ভব বিশ্বমূর্তে ॥11.46॥",

    "শ্রীভগবানুবাচ\nময়া প্রসন্নেন তবার্জুনেদং(ম্),\nরূপং(ম্) পরং(ন্) দর্শিতমাত্ময়োগাত্ \nতেজোময়ং(ম্) বিশ্বমনন্তমাদ্যং(ম্),\nয়ন্মে ত্বদন্যেন ন দৃষ্টপূর্বম্ ॥11.47॥",

    "ন  বৈদয়জ্ঞাধ্যয়নৈর্ন দানৈ:(র্),\nন চ ক্রিয়াভির্ন তপোভিরুগ্রৈঃ \nএবংরূপঃ(শ্)  শক্য অহং(ন্) নৃলোকে,\nদ্রষ্টুং(ন্)  ত্বদন্যেন কুরুপ্রবীর ॥11.48॥",

    "মা তে  ব্যথা মা চ বিমূঢভাবো,\nদৃষ্ট্বা রূপং(ঙ্) ঘোরমীদৃঙ্মমেদম্ \nব্যপেতভী:(ফ্) প্রীতমনাঃ(ফ্)পুনস্ত্বং(ন্),\nতদেব মে রূপমিদং(ম্)  প্রপশ্য ॥11.49॥",

    "সংজয় উবাচ\nইত্যর্জুনং(ম্) বাসুদেবস্তথোক্ত্বা,\nস্বকং(ম্) রূপং(ন্) দর্শয়ামাস ভূয়ঃ \nআশ্বাসয়ামাস চ ভীতমেনং(ম্),\nভূত্বা পুনঃ(স্) সৌম্যবপুর্মহাত্মা ॥11.50॥",

    "অর্জুন উবাচ\nদৃষ্ট্বেদং(ম্) মানুষং(ম্) রূপং(ন্), তব সৌম্যং(ঞ্) জনার্দন \nইদানীমস্মি সংবৃত্তঃ(স্),সচেতাঃ(ফ্) প্রকৃতিং(ঙ্) গতঃ ॥11.51॥",

    "শ্রীভগবানুবাচ\nসুদুর্দর্শমিদং(ম্) রূপং(ন্),দৃষ্টবানসি য়ন্মম \nদেবা অপ্য়স্য়  রূপস্য,নিত্য়ং(ন্) দর্শনকাঙ্ক্ষিণঃ ॥11.52॥",

    "নাহং(ম্) বেদৈর্ন তপসা,ন দানেন ন  চেজ্যয়া\nশক্য এবংবিধো দ্রষ্টুং(ন্),দৃষ্টবানসি মাং(ম্) য়থা ॥11.53॥",

    "ভক্ত্যা ত্বনন্যয়া  শক্য, অহমেবংবিধো‌ऽর্জুন \nজ্ঞাতুং(ন্) দ্রষ্টুং(ঞ্) চ তত্ত্বেন,প্রবেষ্টুং(ঞ্) চ পরন্তপ ॥11.54॥",

    "মত্কর্মকৃন্মত্পরমো, মদ্ভক্তঃ(স্) সঙ্গবর্জিতঃ \nনির্বৈরঃ(স্) সর্বভূতেষু, য়ঃ(স্) স মামেতি পাংডব ॥11.55॥",
    
    "ॐ তৎসদিতি শ্রীমদ্ভগবদ্গীতাসু উপনিষৎসু ব্রহ্মবিদ্যায়াং(য়্ঁ) য়োগশাস্ত্রে\nশ্রীকৃষ্ণার্জুনসংবাদে বিশ্বরূপদর্শনয়োগো নামৈকাদশোऽধ্যায়ঃ ॥11॥"
  ],
  "adhyaya-12" : [
    "অর্জুন উবাচ\nএবং(ম্) সততয়ুক্তা য়ে, ভক্তাস্ত্বাং(ম্) পর্য়ুপাসতে  য়ে চাপ্যক্ষরমব্যক্তং(ন্), তেষাং(ঙ্) কে য়োগবিত্তমাঃ ॥12.1॥",

    "শ্রী ভগবানুবাচ\nময়‍্যাবেশ্য মনো য়ে মাং(ন্), নিত্যয়ুক্তা উপাসতে\nশ্রদ্ধয়া পরয়োপেতাঃ(স্), তে মে য়ুক্ততমা মতাঃ ॥12.2॥",

    "য়ে ত্বক্ষরমনির্দেশ্যম্, অব্যক্তং(ম্) পর্য়ুপাসতে\nসর্বত্রগমচিন্ত্যং(ঞ্) চ, কূটস্থমচলং(ন্) ধ্রুবম্ ॥12.3॥",

    "সন্নিয়ম্যেন্দ্রিয়গ্রামং(ম্), সর্বত্র সমবুদ্ধয়ঃ\nতে প্রাপ্নুবন্তি মামেব, সর্বভূতহিতে রতাঃ ॥12.4॥",

    "ক্লেশোধিকতরস্তেষাম্, অব্যক্তাসক্তচেতসাম্\nঅব্যক্তা হি গতির্দুঃখং(ন্), দেহবদ্ভিরবাপ্যতে ॥12.5॥",

    "য়ে তু সর্বাণি কর্মাণি, ময়ি সন্ন্যস্য মত্পরাঃ\nঅনন্যেনৈব য়োগেন, মাং(ন্) ধ্যায়ন্ত উপাসতে ॥12.6॥",

    "তেষামহং(ম্) সমুদ্ধর্তা, মৃত্যুসংসারসাগরাত্\nভবামি নচিরাত্পার্থ, ময়‍্যাবেশিতচেতসাম ॥12.7॥",

    "ময়‍্যেব মন আধত্স্ব, ময়ি বুদ্ধিং(ন্) নিবেশয়\nনিবসিষ্যসি ময়‍্যেব, অত ঊর্ধ্বং(ন্) ন সংশয়ঃ ॥12.8॥",

    "অথ চিত্তং(ম্) সমাধাতুং(ন্), ন শক্নোষি ময়ি স্থিরম্\nঅভ্যাসয়োগেন ততো, মামিচ্ছাপ্তুং(ন্) ধনঞ্জয় ॥12.9॥",

    "অভ্যাসেপ্যসমর্থোসি, মত্কর্মপরমো ভব\nমদর্থমপি কর্মাণি, কুর্বন্ সিদ্ধিমবাপ্স্যসি ॥12.10॥",

    "অথৈতদপ্যশক্তোসি, কর্তুং(ম্) মদ্যোগমাশ্রিতঃ\nসর্বকর্মফলত্যাগং(ন্), ততঃ(খ) কুরু য়তাত্মবান্ ॥12.11॥",

    "শ্রেয়ো হি জ্ঞানমভ্যাসাজ্, জ্ঞানাদ্ধ্যানং(ব্ঁ) বিশিষ্যতে।\nধ্যানাত্কর্মফলত্যাগঃ(স্),ত্যাগাচ্ছান্তিরনন্তরম্ ॥12.12॥",

    "অদ্বেষ্টা সর্বভূতানাং(ম্), মৈত্রঃ(খ্) করুণ এব চ\nনির্মমো নিরহঙ্কারঃ(স্) , সমদুঃখসুখঃ ক্ষমী ॥12.13॥",

    "সন্তুষ্টঃ(স্) সততং(য়্ঁ) য়োগী, য়তাত্মা দৃঢনিশ্চয়ঃ।\nময়্যর্পিতমনোবুদ্ধি(র্), য়ো মদ্ভক্তঃ(স্) স মে প্রিয়ঃ ॥12.14॥",

    "য়স্মান্নোদ্বিজতে লোকো, লোকান্নোদ্বিজতে চ য়ঃ\nহর্ষামর্ষভয়োদ্বেগৈঃ(র্),মুক্তো য়ঃ(স্) স চ মে প্রিয়ঃ ॥12.15॥",

    "অনপেক্ষঃ(শ্) শুচির্দক্ষ, উদাসীনো গতব্যথঃ\nসর্বারম্ভপরিত্যাগী, য়ো মদ্ভক্তঃ(স্) স মে প্রিয়ঃ ॥12.16॥",

    "য়ো ন হৃষ্যতি ন দ্বেষ্টি, ন শোচতি ন কাঙ্ক্ষতি\nশুভাশুভপরিত্যাগী, ভক্তিমান্যঃ(স্) স মে প্রিয়ঃ ॥12.17॥",

    "সমঃ(শ্) শত্রৌ চ মিত্রে চ, তথা মানাপমানয়োঃ\nশীতোষ্ণসুখদুঃখেষু, সমঃ(স্) সঙ্গবিবর্জিতঃ ॥12.18॥",

    "তুল্যনিন্দাস্তুতির্মৌনী, সন্তুষ্টো য়েনকেনচিত্\nঅনিকেতঃ(স্) স্থিরমতিঃ(র্), ভক্তিমান্মে প্রিয়ো নরঃ ॥12.19॥",

    "য়ে তু ধর্ম্যামৃতমিদং(য়্ঁ), য়থোক্তং(ম্) পর্য়ুপাসতে।\nশ্রদ্দধানা মত্পরমা, ভক্তাস্তেSতীব মে প্রিয়াঃ ॥12.20॥",

    "ॐ তত্সদিতি শ্রীমদ্ভগবদ্গীতাসু উপনিষত্সু ব্রহ্মবিদ্যায়াং(য়্ঁ) য়োগশাস্ত্রে\nশ্রীকৃষ্ণার্জুনসংবাদে ভক্তিয়োগো নাম দ্বাদশোধ্যায়ঃ ॥12॥",
  ],
  "adhyaya-13" : [
    "শ্রীভগবানুবাচ\nইদং(ম্) শরীরং(ঙ্) কৌন্তেয়,ক্ষেত্রমিত্য়ভিধীয়তে \nএতদ্য়ো বেত্তি তং(ম্)  প্রাহুঃ, ক্ষেত্রজ্ঞ ইতি তদ্বিদঃ ॥13.1॥",

    "ক্ষেত্রজ্ঞং(ঞ্) চাপি মাং(ম্) বিদ্ধি,সর্বক্ষেত্রেষু ভারত \nক্ষেত্রক্ষেত্রজ্ঞয়োর্জ্ঞানং(ম্),য়ত্তজ্জ্ঞানং(ম্) মতং(ম্) মম ॥13.2॥",

    "তত্ক্ষেত্রং(ম্) য়চ্চ য়াদৃক্চ,য়দ্বিকারি য়তশ্চ য়ত্\nস চ য়ো য়ত্প্রভাবশ্চ,তত্সমাসেন মে শৃণু ॥13.3॥",

    "ঋষিভির্বহুধা গীতং(ঞ্),ছন্দোভির্বিবিধৈঃ(ফ্) পৃথক্ \nব্রহ্মসূত্রপদৈশ্চৈব, হেতুমদ্ভির্বিনিশ্চিতৈঃ ॥13.4॥",

    "মহাভূতান্য়হংকারো, বুদ্ধিরব্য়ক্তমেব চ \nইন্দ্রিয়াণি দশৈকং(ঞ্) চ,পঞ্চ চেন্দ্রিয়গোচরাঃ ॥13.5॥",

    "ইচ্ছা দ্বেষঃ(স্)  সুখং(ন্) দুঃখং(ম্),সংঘাতশ্চেতনা ধৃতিঃ \nএতত্ক্ষেত্রং(ম্) সমাসেন,সবিকারমুদাহৃতম্ ॥13.6॥",

    "অমানিত্বমদম্ভিত্বম্, অহিংসা ক্ষান্তিরার্জবম্ \nআচার্য়োপাসনং(ম্) শৌচং(ম্) স্থৈর্য়মাত্মবিনিগ্রহঃ ॥13.7॥",

    "ইন্দ্রিয়ার্থেষু বৈরাগ্য়ম্, অনহংকার এব চ \nজন্মমৃত্য়ুজরাব্য়াধি,দুঃখদোষানুদর্শম্ ॥13.8॥",

    "অসক্তিরনভিষ্বঙ্গঃ(ফ্), পুত্রদারগৃহাদিষু \nনিত্য়ং(ঞ্) চ সমচিত্তত্বম্, ইষ্টানিষ্টোপপত্তিষু ॥13.9॥",

    "ময়ি চানন্য়য়োগেন,ভক্তিরব্য়ভিচারিণী \nবিবিক্তদেশসেবিত্বম্, অরতির্জনসংসদি॥10॥",

    "অধ্য়াত্মজ্ঞাননিত্য়ত্বং(ন্), তত্ত্বজ্ঞানার্থদর্শনম্ \nএতজ্জ্ঞানমিতি প্রোক্তম্, অজ্ঞানং (ম্) য়দতো‌ऽন্য়থা ॥13.11॥",

    "জ্ঞেয়ং(ম্) য়ত্তত্প্রবক্ষ্য়ামি,য়জ্জ্ঞাত্বামৃতমশ্নুতে \nঅনাদিমত্পরং(ম্) ব্রহ্ম,ন সত্তন্নাসদুচ্য়তে ॥13.12॥",

    "সর্বতঃ(ফ্) পাণিপাদং(ন্) তত্,সর্বতো‌ऽক্ষিশিরোমুখম্\nসর্বতঃ(শ্) শ্রুতিমল্লোকে, সর্বমাবৃত্য় তিষ্ঠতি ॥13.13॥",

    "সর্বেন্দ্রিয়গুণাভাসং(ম্),সর্বেন্দ্রিয়বিবর্জিতম্ \nঅসক্তং(ম্) সর্বভৃচ্চৈব, নির্গুণং(ঙ্) গুণভোক্তৃ চ ॥13.14॥",

    "বহিরন্তশ্চ ভূতানাম্, অচরং(ঞ্) চরমেব চ \nসূক্ষ্মত্বাত্তদবিজ্ঞেয়ং(ন্), দূরস্থং(ঞ্) চান্তিকে চ তত্ ॥13.15॥",

    "অবিভক্তং(ঞ্) চ ভূতেষু, বিভক্তমিব চ স্থিতম্ \nভূতভর্তৃ চ  তজ্ জ্ঞেয়ং(ঙ্), গ্রসিষ্ণু প্রভবিষ্ণু চ ॥13.16॥",

    "জ্য়োতিষামপি তজ্জ্য়োতি:(স্),তমসঃ(ফ্)  পরমুচ্য়তে \nজ্ঞানং(ঞ্) জ্ঞেয়ং(ঞ্)জ্ঞানগম্য়ং(ম্),হৃদি সর্বস্য় বিষ্ঠিতম্ ॥13.17॥",

    "ইতি ক্ষেত্রং(ন্) তথা জ্ঞানং(ঞ্), জ্ঞেয়ং(ঞ্) চোক্তং(ম্)  সমাসতঃ \nমদ্ভক্ত এতদ্বিজ্ঞায়, মদ্ভাবায়োপপদ্য়তে ॥13.18॥",

    "প্রকৃতিং(ম্) পুরুষং(ঞ্) চৈব,  বিদ্ধ্যনাদী উভাবপি \nবিকারাংশ্চ গুণাংশ্চৈব,বিদ্ধি প্রকৃতিসম্ভবান্ ॥13.19॥",

    "কার্য়করণকর্তৃত্বে, হেতুঃ(ফ্) প্রকৃতিরুচ্য়তে \nপুরুষঃ(স্) সুখদুঃখানাং(ম্),ভোক্তৃত্বে হেতুরুচ্য়তে ॥13.20॥",

    "পুরুষঃ(ফ্) প্রকৃতিস্থো হি,  ভুঙ্ ক্তে প্রকৃতিজান্গুণান্  \nকারণং(ঙ্) গুণসঙ্গো‌ऽস্য়, সদসদ্য়োনিজন্মসু ॥13.21॥",

    "উপদ্রষ্টানুমন্তা চ,ভর্তা ভোক্তা মহেশ্বরঃ \nপরমাত্মেতি চাপ্য়ুক্তো, দেহে‌ऽস্মিন্পুরুষঃ(ফ্) পরঃ ॥13.22॥",

    "য় এবং(ম্)বেত্তি পুরুষং(ম্),প্রকৃতিং(ঞ্) চ গুণৈঃ(স্) সহ \nসর্বথা বর্তমানো‌ऽপি, ন স ভূয়ো‌ऽভিজায়তে ॥13.23॥",

    "ধ্য়ানেনাত্মনি পশ্য়ন্তি,কেচিদাত্মানমাত্মনা \nঅন্য়ে সাঙ্খ্য়েন য়োগেন, কর্ময়োগেন চাপরে ॥13.24॥",

    "অন্য়ে ত্বেবমজানন্তঃ(শ্), শ্রুত্বান্য়েভ্য় উপাসতে \nতে‌ऽপি চাতিতরন্ত্য়েব, মৃত্যুং(ম্) শ্রুতিপরায়ণাঃ ॥13.25॥",

    "য়াবত্সঞ্জায়তে কিঞ্চিত্, সত্ত্বং(ম্) স্থাবরজঙ্গমম্ \nক্ষেত্রক্ষেত্রজ্ঞসংয়োগাৎ,তদ্বিদ্ধি ভরতর্ষভ ॥13.26॥",

    "সমং(ম্) সর্বেষু ভূতেষু, তিষ্ঠন্তং(ম্) পরমেশ্বরম্\nবিনশ্য়ত্স্ববিনশ্য়ন্তং(ম্), য়ঃ(ফ্) পশ্য়তি স পশ্য়তি ॥13.27॥",

    "সমং পশ্য়ন্হি সর্বত্র,  সমবস্থিতমীশ্বরম্ \nন হিনস্ত্য়াত্মনাত্মানং(ন্), ততো য়াতি পরাং(ঙ্) গতিম্ ॥13.28॥",

    "প্রকৃত্য়ৈব চ কর্মাণি, ক্রিয়মাণানি সর্বশঃ \nয়ঃ(ফ্) পশ্য়তি তথাত্মানম্,অকর্তারং(ম্) স পশ্য়তি ॥13.29॥",

    "য়দা ভূতপৃথগ্ভাবম্,  একস্থমনুপশ্য়তি \nতত এব চ বিস্তারং(ম্), ব্রহ্ম সম্পদ্য়তে তদা ॥13.30॥",

    "অনাদিত্বান্নির্গুণত্বাত্ ,পরমাত্মায়মব্যয়ঃ \nশরীরস্থো‌ऽপি  কৌন্তেয়, ন করোতি ন লিপ্য়তে ॥13.31॥",

    "য়থা সর্বগতং(ম্) সৌক্ষ্ম্য়াদ্,আকাশং(ন্) নোপলিপ্য়তে \nসর্বত্রাবস্থিতো দেহে, তথাত্মা নোপলিপ্য়তে ॥13.32॥",

    "য়থা প্রকাশয়ত্য়েকঃ(খ্),কৃত্স্নং(ম্),লোকমিমং(ম্) রবিঃ \nক্ষেত্রং(ঙ্)  ক্ষেত্রী তথা কৃত্স্নং(ম্),প্রকাশয়তি ভারত ॥13.33॥",

    "ক্ষেত্রক্ষেত্রজ্ঞয়োরেবম্, অন্তরং(ঞ্) জ্ঞানচক্ষুষা \nভূতপ্রকৃতিমোক্ষং(ঞ্) চ, য়ে বিদুর্য়ান্তি তে পরম্ ॥13.34॥",
    
    "ॐ তত্সদিতি শ্রীমদ্ভগবদ্গীতাসু উপনিষত্সু ব্রহ্মবিদ্য়ায়াং(য়্ঁ)য়োগশাস্ত্রে \nশ্রীকৃষ্ণার্জুনসংবাদেক্ষেত্রক্ষেত্রজ্ঞবিভাগয়োগো নাম ত্রয়োদশো‌ऽধ্য়ায়ঃ ॥13॥"
  ],
  "adhyaya-14" : [
    "শ্রীভগবানুবাচ\nপরং(ম্) ভূয়ঃ(ফ্) প্রবক্ষ্যামি, জ্ঞানানাং(ঞ) জ্ঞানমুত্তমম্\nযজ্জ্ঞাত্বা মুনয়ঃ(স্) সর্বে, পরাং(ম্) সিদ্ধিমিতো গতাঃ ॥14.1॥",

    "ইদং(ঞ) জ্ঞানমুপাশ্রিত্য, মম সাধর্ম্যমাগতাঃ\nসর্গেপি নোপজায়ন্তে, প্রলয়ে ন ব্যথন্তি চ ॥14.2॥",

    "মম যোনির্মহদ্ব্রহ্ম , তস্মিন্ গর্ভং(ন্) দধাম্যহম্\nসম্ভবঃ(স্) সর্বভূতানাং(ন্), ততো ভবতি ভারত ॥14.3॥",

    "সর্বযোনিষু কৌন্তেয় , মূর্তয়ঃ(স্) সম্ভবন্তি যাঃ\nতাসাং(ম্) ব্রহ্ম মহদ্যোনিঃ(র্), অহং(ম্) বীজপ্রদঃ(ফ্) পিতা ॥14.4॥",

    "সত্ত্বং(ম্) রজস্তম ইতি, গুণাঃ(ফ্) প্রকৃতিসম্ভবাঃ\nনিবধ্নন্তি মহাবাহো, দেহে দেহিনমব্যয়ম্ ॥14.5॥",

    "তত্র সত্ত্বং(ন্) নির্মলত্বাৎ , প্রকাশকমনাময়ম্\nসুখসঙ্গেন বধ্নাতি, জ্ঞানসঙ্গেন চানঘ ॥14.6॥",

    "রজো রাগাত্মকং(ম্) বিদ্ধি , তৃষ্ণাসঙ্গসমুদ্ভবম্\nতন্নিবধ্নাতি কৌন্তেয় , কর্মসঙ্গেন দেহিনম্ ॥14.7॥",

    "তমস্ত্বজ্ঞানজং(ম্) বিদ্ধি, মোহনং(ম্) সর্বদেহিনাম্\nপ্রমাদালস্যনিদ্রাভিঃ(স্ ), তন্নিবধ্নাতি ভারত ॥14.8॥",

    "সত্ত্বং(ম্) সুখে সঞ্জয়তি, রজঃ(খ্) কর্মণি ভারত\nজ্ঞানমাবৃত্য তু তমঃ(ফ্), প্রমাদে সঞ্জয়ত্যুত ॥14.9॥",

    "রজস্তমশ্চাভিভূয়, সত্ত্বং(ম্) ভবতি ভারত\nরজঃ(স্) সত্ত্বং(ন্) তমশ্চৈব, তমঃ(স্) সত্ত্বং(ম্) রজস্তথা ॥14.10॥",

    "সর্বদ্বারেষু দেহেস্মিন্ , প্রকাশ উপজায়তে\nজ্ঞানং(ম্) যদা তদা বিদ্যাদ্ , বিবৃদ্ধং(ম্) সত্ত্বমিত্যুত ॥14.11॥",

    "লোভঃ(ফ্) প্রবৃত্তিরারম্ভঃ(খ্), কর্মণামশমঃ(স্) স্পৃহা\nরজস্যেতানি জায়ন্তে , বিবৃদ্ধে ভরতর্ষভ ॥14.12॥",

    "অপ্রকাশোপ্রবৃত্তিশ্চ , প্রমাদো মোহ এব চ\nতমস্যেতানি জায়ন্তে , বিবৃদ্ধে কুরুনন্দন ॥14.13॥",

    "যদা সত্ত্বে প্রবৃদ্ধে তু, প্রলয়ং(ম্)যাতি দেহভৃৎ\nতদোত্তমবিদাং(ম্)লোকান্, অমলান্প্রতিপদ্যতে ॥14.14॥",

    "রজসি প্রলয়ং(ঙ) গত্বা , কর্মসঙ্গিষু জায়তে\nতথা প্রলীনস্তমসি , মূঢযোনিষু জায়তে ॥14.15॥",

    "কর্মণঃ(স্) সুকৃতস্যাহুঃ(স্), সাত্ত্বিকং(ন্) নির্মলং(ম্) ফলম্\nরজসস্তু ফলং(ন্) দুঃখম্ , অজ্ঞানং(ন্) তমসঃ(ফ্) ফলম্ ॥14.16॥",

    "সত্ত্বাৎসঞ্জায়তে জ্ঞানং(ম্), রজসো লোভ এব চ\nপ্রমাদমোহৌ তমসো, ভবতোজ্ঞানমেব চ ॥14.17॥",

    "ঊর্ধ্বং(ঙ) গচ্ছন্তি সত্ত্বস্থা , মধ্যে তিষ্ঠন্তি রাজসাঃ\nজঘন্যগুণবৃত্তিস্থা , অধো গচ্ছন্তি তামসাঃ ॥14.18॥",

    "নান্যং(ঙ) গুণেভ্যঃ(খ্) কর্তারং(ম্), যদা দ্রষ্টানুপশ্যতি\nগুণেভ্যশ্চ পরং(ম্) বেত্তি, মদ্ভাবং(ম্) সোধিগচ্ছতি ॥14.19॥",

    "গুণানেতানতীত্য ত্রীন্ , দেহী দেহসমুদ্ভবান্\nজন্মমৃত্যুজরাদুঃখৈঃ(র্) , বিমুক্তোমৃতমশ্নুতে ॥14.20॥",

    "অর্জুন উবাচ\nকৈর্লিংগৈস্ত্রীন্গুণানেতান্, অতীতো ভবতি প্রভো\nকিমাচারঃ(খ্) কথং(ঞ) চৈতাংস্, ত্রীন্গুণানতিবর্ততে ॥14.21॥",

    "শ্রীভগবানুবাচ\nপ্রকাশং(ঞ) চ প্রবৃত্তিং(ঞ) চ, মোহমেব চ পাণ্ডব\nন দ্বেষ্টি সম্প্রবৃত্তানি, ন নিবৃত্তানি কাঙ্ক্ষতি ॥14.22॥",

    "উদাসীনবদাসীনো , গুণৈর্যো ন বিচাল্যতে\nগুণা বর্তন্ত ইত্যেব, যোবতিষ্ঠতি নেঙ্গতে ॥14.23॥",

    "সমদুঃখসুখঃ(স্) স্বস্থঃ(স্), সমলোষ্টাশ্মকাঞ্চনঃ\nতুল্যপ্রিয়াপ্রিয়ো ধীরঃ(স), তুল্যনিন্দাত্মসংস্তুতিঃ ॥14.24॥",

    "মানাপমানয়োস্তুল্যঃ(স্), তুল্যো মিত্রারিপক্ষয়োঃ\nসর্বারম্ভপরিত্যাগী , গুণাতীতঃ(স্) স উচ্যতে ॥14.25॥",

    "মাং(ঞ) চ যোব্যভিচারেণ, ভক্তিযোগেন সেবতে\nস গুণান্সমতীত্যৈতান্, ব্রহ্মভূয়ায় কল্পতে ॥14.26॥",

    "ব্রহ্মণো হি প্রতিষ্ঠাহম , অমৃতস্যাব্যয়স্য চ\nশাশ্বতস্য চ ধর্মস্য , সুখস্যৈকান্তিকস্য চ ॥14.27॥",
    
    "ॐ তৎসদিতি শ্রীমদ্ভগবদ্গীতাসু উপনিষৎসু ব্রহ্মবিদ‍্যায়াং(য়্ঁ) য়োগশাস্ত্রে \nশ্রীকৃষ্ণার্জুনসংবাদে গুণত্রয়বিভাগয়োগো নাম চতুর্দশোऽধ‍্যায়ঃ ॥14॥"
  ],
  "adhyaya-15" : [
    "শ্রীভগবানুবাচ\nঊর্ধ্বমূলমধঃ(শ্) শাখম্, অশ্বত্থং(ম্) প্রাহুরব্যয়ম্।\nছন্দাংসি  য়স্য  পর্ণানি, য়স্তং(ব্ঁ) বেদ স বেদবিৎ ॥15.1॥",

    "অধশ্চোর্ধ্বং(ম্) প্রসৃতাস্তস্য শাখা,\nগুণপ্রবৃদ্ধা বিষয়প্রবালাঃ।\nঅধশ্চ মূলান্যনুসন্ততানি,\nকর্মানুবন্ধীনি মনুষ্যলোকে ॥15.2॥",

    "ন রূপমস্যেহ তথোপলভ্যতে,\nনান্তো ন চাদির্ন চ সংপ্রতিষ্ঠা।\nঅশ্বত্থমেনং(ম্) সুবিরূঢমূলম্,\nঅসঙ্গশস্ত্রেণ দৃঢেন ছিত্ত্বা ॥15.3॥",

    "ততঃ(ফ্) পদং(ন্)তৎপরিমর্গিতব্যং(য়্ঁ),\nয়স্মিন্গতা ন নিবর্তন্তি ভূয়ঃ।\nতমেব চাদ্যং(ম্) পুরুষং(ম্) প্রপদ্যে,\nয়তঃ(ফ্) প্রবৃত্তিঃ(ফ্) প্রসৃতা পুরাণী ॥15.4॥",

    "নির্মানমোহা জিতসঙ্গদোষা,\nঅধ্যাত্মনিত্যা বিনিবৃত্তকামাঃ।\nদ্বন্দ্বৈর্বিমুক্তাঃ(স্) সুখদুঃখসংজ্ঞৈ,\nর্গচ্ছন্ত্যমূঢাঃ(ফ্) পদমব্যয়ং(ন্) তত্ ॥15.5॥",

    "ন তদ্ভাসয়তে সূর্যো, ন শশাঙ্কো ন পাবকঃ\nয়দ্গত্বা ন নিবর্তন্তে, তদ্ধাম পরমং(ম্) মম ॥15.6॥",

    "মমৈবাংশো জীবলোকে, জীবভূতঃ(স্) সনাতনঃ\nমনঃ(ষ্) ষষ্ঠানীন্দ্রিয়াণি, প্রকৃতিস্থানি কর্ষতি ॥15.7॥",

    "শরীরং(য়্ঁ)  য়দবাপ্নোতি, য়চ্চাপ্যুৎক্রামতীশ্বরঃ।\nগৃহীত্বৈতানি সংয়াতি, বায়ুর্গন্ধানিবাশয়াৎ ॥15.8॥",

    "শ্রোত্রং(ঞ্) চক্ষুঃ(স্) স্পর্শনং(ঞ্) চ, রসনং(ঙ্) ঘ্রাণমেব চ।\nঅধিষ্ঠায় মনশ্চায়ং(ব্ঁ), বিষয়ানুপসেবতে ॥15.9॥",

    "উত্ক্রামন্তং(ম্) স্থিতং(ব্ঁ) বাপি, ভুঞ্জানং(ব্ঁ) বা গুণান্বিতম্।\nবিমূঢা নানুপশ্যন্তি, পশ্যন্তি জ্ঞানচক্ষুষঃ ॥15.10॥",

    "য়তন্তো য়োগিনশ্চৈনং(ম্), পশ্যন্ত্যাত্মন্যবস্থিতম্\nয়তন্তোপ্যকৃতাত্মানো, নৈনং(ম্) পশ্যন্ত্যচেতসঃ ॥15.11॥",

    "য়দাদিত্যগতং(ন্) তেজো, জগদ্ভাসয়তেখিলম্\nয়চ্চন্দ্রমসি য়চ্চাগ্নৌ, তত্তেজো বিদ্ধি মামকম্ ॥15.12॥",

    "গামাবিশ্য চ ভূতানি, ধারয়াম্যহমোজসা\nপুষ্ণামি চৌষধীঃ(স্) সর্বাঃ(স্), সোমো ভূত্বা রসাত্মকঃ ॥15.13॥",

    "অহং(ব্ঁ) বৈশ্বানরো ভূত্বা, প্রাণিনাং(ন্) দেহমাশ্রিতঃ।\nপ্রাণাপানসমায়ুক্তঃ(ফ্), পচাম্যন্নং(ঞ্) চতুর্বিধম্ ॥15.14॥",

    "সর্বস্য চাহং(ম্) হৃদি সন্নিবিষ্টো,\nমত্তঃ(স্) স্মৃতির্জ্ঞানমপোহনং(ঞ্) চ\nবেদৈশ্চ সর্বৈরহমেব বেদ্যো,\nবেদান্তকৃদ্বেদবিদেব চাহম্ ॥15.15॥",

    "দ্বাবিমৌ পুরুষৌ লোকে, ক্ষরশ্চাক্ষর এব চ\nক্ষরঃ(স্) সর্বাণি ভূতানি, কূটস্থোক্ষর উচ্যতে ॥15.16॥",

    "উত্তমঃ(ফ্) পুরুষস্ত্বন্যঃ(ফ্), পরমাত্মেত্যুদাহৃতঃ\nয়ো লোকত্রয়মাবিশ্য, বিভর্ত্যব্যয়ঈশ্বরঃ ॥15.17॥",

    "য়স্মাত্ক্ষরমতীতোহম্, অক্ষরাদপি চোত্তমঃ\nঅতোস্মি লোকে বেদে চ, প্রথিতঃ(ফ্) পুরুষোত্তমঃ ॥15.18॥",

    "য়ো মামেবমসম্মূঢো, জানাতি পুরুষোত্তমম্\nস সর্ববিদ্ভজতি মাং(ম্), সর্বভাবেন ভারত ॥15.19॥",

    "ইতি গুহ্যতমং(ম্) শাস্ত্রম্, ইদমুক্তং(ম্) ময়ানঘ\nএতদ্বুদ্ধ্বা বুদ্ধিমান্স্যাত্, কৃতকৃত্যশ্চ ভারত ॥15.20॥",

    "ॐ তৎসদিতি শ্রীমদ্ভগবদ্গীতাসু উপনিষৎসু ব্রহ্মবিদ্যায়াং(য়্ঁ) য়োগশাস্ত্রে\nশ্রীকৃষ্ণার্জুনসংবাদে পুরুষোত্তময়োগো নাম পঞ্চদশোऽধ্যায়ঃ ॥15॥"
  ],
  "adhyaya-16" : [
    "শ্রীভগবানুবাচ\nঅভয়ং (ম্) সত্ত্বসংশুদ্ধিঃ (র্),  জ্ঞানযোগব্যবস্থিতিঃ৷\nদানং(ন্) দমশ্চ যজ্ঞশ্চ, স্বাধ্যায়স্তপ আর্জবম্ ৷৷16.1৷৷",

    "অহিংসা সত্যমক্রোধঃ(স্), ত্যাগঃ(শ্) শান্তিরপৈশুনম্৷\nদয়া ভূতেষ্বলোলুপ্ত্বং(ম্), মার্দবং(ম্) হ্রীরচাপলম্ ৷৷16.2৷৷",

    "তেজঃ ক্ষমা ধৃতিঃ(শ্) শৌচম্, অদ্রোহো নাতিমানিতা৷\nভবন্তি সম্পদং(ন্) দৈবীম্, অভিজাতস্য ভারত ৷৷16.3৷৷",

    "দম্ভো দর্পোহভিমানশ্চ, ক্রোধঃ(ফ্) পারুষ্যমেব চ৷\nঅজ্ঞানং(ঞ) চাভিজাতস্য, পার্থ সম্পদমাসুরীম্ ৷৷16.4৷৷",

    "দৈবী সম্পদ্বিমোক্ষায়, নিবন্ধায়াসুরী মতা৷\nমা শুচঃ(স্) সম্পদং(ন্) দৈবীম, অভিজাতোসি পাণ্ডব ৷৷16.5৷৷",

    "দ্বৌ ভূতসর্গৌ লোকেস্মিন্, দৈব আসুর এব চ৷\nদৈবো বিস্তরশঃ(ফ্) প্রোক্ত, আসুরং(ম্) পার্থ মে শৃণু ৷৷16.6৷৷",

    "প্রবৃত্তিং(ঞ) চ নিবৃত্তিং(ঞ) চ, জনা ন বিদুরাসুরাঃ৷\nন শৌচং(ন্) নাপি চাচারো, ন সত্যং(ন্) তেষু বিদ্যতে ৷৷16.7৷৷",

    "অসত্যমপ্রতিষ্ঠং(ন্) তে, জগদাহুরনীশ্বরম্৷\nঅপরস্পরসম্ভূতং(ঙ), কিমন্যৎ কামহৈতুকম্ ৷৷16.8৷৷",

    "এতাং(ন্) দৃষ্টিমবষ্টভ্য, নষ্টাত্মানোল্পবুদ্ধয়ঃ৷\nপ্রভবন্ত্যুগ্রকর্মাণঃ, ক্ষয়ায় জগতোহিতাঃ ৷৷16.9৷৷",

    "কামমাশ্রিত্য দুষ্পূরং(ন্), দম্ভমানমদান্বিতাঃ৷\nমোহাদ্গৃহীত্বাসদ্গ্ৰাহান্ প্রবর্তন্তেশুচিব্রতাঃ ৷৷16.10৷৷",

    "চিন্তামপরিমেয়াং(ঞ) চ, প্রলয়ান্তামুপাশ্রিতাঃ৷\nকামোপভোগপরমা, এতাবদিতি নিশ্চিতাঃ ৷৷16.11৷৷",

    "আশাপাশশতৈর্বদ্ধাঃ(খ্), কামক্রোধপরায়ণাঃ৷\nঈহন্তে কামভোগার্থম্ , অন্যায়েনার্থসঞ্চয়ান্ ৷৷16.12৷৷",

    "ইদমদ্য ময়া লব্ধম্ , ইমং(ম্) প্রাপ্স্যে মনোরথম্৷\nইদমস্তীদমপি মে, ভবিষ্যতি পুনর্ধনম্ ৷৷16.13৷৷",

    "অসৌ ময়া হতঃ(শ্) শত্রুঃ(র্) ,  হর্নিষ্যে চাপরানপি৷\nঈশ্বরোহমহং(ম্) ভোগী, সিদ্ধোহং(ম্) বলবান্ সুখী ৷৷16.14৷৷",

    "আঢ্যোভিজনবানস্মি, কোন্যোস্তি সদৃশো ময়া ৷\nযক্ষ্যে দাস্যামি মোদিষ্য, ইত্যজ্ঞানবিমোহিতাঃ ৷৷16.15৷৷",

    "অনেকচিত্তবিভ্রান্তা, মোহজালসমাবৃতাঃ৷\nপ্রসক্তাঃ(খ্) কামভোগেষু, পতন্তি নরকেশুচৌ ৷৷16.16৷৷",

    "আত্মসম্ভাবিতাঃ(স্) স্তব্ধা, ধনমানমদান্বিতাঃ৷\nযজন্তে নামযজ্ঞৈস্তে, দম্ভেনাবিধিপূর্বকম্ ৷৷16.17৷৷",

    "অহঙ্কারং(ম্) বলং(ন্) দর্পং(ঙ), কামং(ঙ) ক্রোধং(ঞ) চ সংশ্রিতাঃ৷\nমামাত্মপরদেহেষু, প্রদ্বিষন্তোভ্যসূয়কাঃ ৷৷16.18৷৷",

    "তানহং(ন্) দ্বিষতঃ(খ্) ক্রূরান্, সংসারেষু নরাধমান্৷\nক্ষিপাম্যজস্রমশুভান্ , আসুরীষ্বেব যোনিষু ৷৷16.19৷৷",

    "আসুরীং(য়্ঁ) য়োনিমাপন্না, মূঢ়া জন্মনি জন্মনি৷\nমামপ্রাপ্যৈব কৌন্তেয়, ততো য়ান্ত্যধমাং(ঙ্) গতিম্ ৷৷16.20৷৷",

    "ত্রিবিধং(ন্) নরকস্যেদং(ন্), দ্বারং(ন্) নাশনমাত্মনঃ৷\nকামঃ(খ্) ক্রোধস্তথা লোভঃ(স্), তস্মাদেতত্ত্রয়ং(ন্) ত্যজেৎ ৷৷16.21৷৷",

    "এতৈর্বিমুক্তঃ(খ্) কৌন্তেয়, তমোদ্বারৈস্ত্রিভির্নরঃ৷\nআচরত্যাত্মনঃ(শ্) শ্রেয়ঃ(স্), ততো যাতি পরাং(ঙ) গতিম্ ৷৷16.22৷৷",

    "যঃ(শ্) শাস্ত্রবিধিমুৎসৃজ্য, বর্ততে কামকারতঃ৷\nন স সিদ্ধিমবাপ্নোতি, ন সুখং(ন্) ন পরাং(ঙ) গতিম্ ৷৷16.23৷৷",

    "তস্মাচ্ছাস্ত্রং(ম্) প্রমাণং(ন্) তে, কার্যাকার্যব্যবস্থিতৌ৷\nজ্ঞাত্বা শাস্ত্রবিধানোক্তং(ঙ), কর্ম কর্তুমিহার্হসি ৷৷16.24৷৷",

    "ॐ তৎসদিতি শ্রীমদ্ভগবদ্গীতাসু উপনিষৎসু ব্রহ্মবিদ্যায়াং(য়্ঁ)\nযোগশাস্ত্রে শ্রীকৃষ্ণার্জুনসংবাদে দৈবাসুরসম্পদ্বিভাগযোগো নাম ষোঢ়শোऽধ্যায়ঃ ॥16॥"
  ],
  "adhyaya-17" : [
    "অর্জুন উবাচ\nয়ে শাস্ত্রবিধিমুত্সৃজ্য়, য়জন্তে শ্রদ্ধয়ান্বিতাঃ\nতেষাং(ন্) নিষ্ঠা তু কা কৃষ্ণ,সত্ত্বমাহো রজস্তমঃ ৷৷17.1৷৷",

    "শ্রীভগবানুবাচ\nত্রিবিধা ভবতি শ্রদ্ধা, দেহিনাং(ম্) সা স্বভাবজা \nসাত্ত্বিকী রাজসী চৈব ,তামসী চেতি তাং (ম্) শৃণু ৷৷17.2৷৷",

    "সত্ত্বানুরূপা সর্বস্য় , শ্রদ্ধা ভবতি ভারত\nশ্রদ্ধাময়ো‌உয়ং(ম্) পুরুষো ,য়ো য়চ্ছ্রদ্ধঃ(স্), স এব সঃ ৷৷17.3৷৷",

    "য়জন্তে সাত্ত্বিকা দেবান্,য়ক্ষরক্ষাংসি রাজসাঃ\nপ্রেতান্ভূতগণাংশ্চান্য়ে,য়জন্তে তামসা জনাঃ ৷৷17.4৷৷",

    "অশাস্ত্রবিহিতং(ঙ) ঘোরং(ন্), তপ্য়ন্তে য়ে তপো জনাঃ\nদম্ভাহংকারসংয়ুক্তাঃ(খ্) ,কামরাগবলান্বিতাঃ ৷৷17.5৷৷",

    "কর্ষয়ন্তঃ(শ্)  শরীরস্থং(ম্), ভূতগ্রামমচেতসঃ\nমাং (ঞ) চৈবান্তঃ(শ্)শরীরস্থং(ন্),তান্বিদ্ধ্য়াসুরনিশ্চয়ান ৷৷17.6৷৷",

    "আহারস্ত্বপি সর্বস্য় ,ত্রিবিধো ভবতি প্রিয়ঃ\nয়জ্ঞস্তপস্তথা দানং (ন্),তেষাং (ম্) ভেদমিমং(ম্)শৃণু ৷৷17.7৷৷",

    "আয়ুঃ(স্)সত্ত্ববলারোগ্য়,সুখপ্রীতিবিবর্ধনাঃ\nরস্য়াঃ(স্)স্নিগ্ধাঃ (স্)স্থিরা হৃদ্য়া , আহারাঃ(স্)সাত্ত্বিকপ্রিয়াঃ ৷৷17.8৷৷",

    "কট্বম্ললবণাত্য়ুষ্ণ,তীক্ষ্ণরূক্ষবিদাহিনঃ\nআহারা রাজসস্য়েষ্টা, দুঃখশোকাময়প্রদাঃ ৷৷17.9৷৷",

    "য়াতয়ামং (ঙ) গতরসং (ম্),পূতি পর্য়ুষিতং (ঞ) চ য়ত\nউচ্ছিষ্টমপি চামেধ্য়ং (ম্),ভোজনং(ন্),তামসপ্রিয়ম্ ৷৷17.10৷৷",

    "অফলাকাঙ্ক্ষিভির্য়জ্ঞো, বিধিদৃষ্টো য় ইজ্য়তে \nয়ষ্টব্য়মেবেতি মনঃ(স্),সমাধায় স সাত্ত্বিকঃ ৷৷17.11৷৷",

    "অভিসংধায় তু ফলং (ন্),দম্ভার্থমপি চৈব য়ত \nইজ্য়তে ভরতশ্রেষ্ঠ, তং(ম্) য়জ্ঞং (ম্ )বিদ্ধি রাজসম্ ৷৷17.12৷৷",

    "বিধিহীনমসৃষ্টান্নং(ম্),মন্ত্রহীনমদক্ষিণম্ \nশ্রদ্ধাবিরহিতং (ম্ )য়জ্ঞং(ন্),তামসং (ম্ )পরিচক্ষতে ৷৷17.13৷৷",

    "দেবদ্বিজগুরুপ্রাজ্ঞপূজনং শৌচমার্জবম্।\nব্রহ্মচর্যমহিংসা চ শারীরং তপ উচ্যতে৷৷ ৷৷17.14৷৷",

    "অনুদ্বেগকরং(ম্) বাক্য়ং(ম্), সত্য়ং(ম্) প্রিয়হিতং (ঞ) চ য়ত \nস্বাধ্য়ায়াভ্য়সনং (ঞ)চৈব, বাঙ্ময়ং(ন্)তপ উচ্য়তে ৷৷17.15৷৷",

    "মনঃ(ফ্) প্রসাদঃ(স্) সৌম্য়ত্বং(ম্),মৌনমাত্মবিনিগ্রহঃ \nভাবসংশুদ্ধিরিত্য়েতত্ত,তপো মানসমুচ্য়তে ৷৷17.16৷৷",

    "শ্রদ্ধয়া পরয়া তপ্তং(ন্),তপস্তত্ত্রিবিধং(ন্)নরৈঃ\nঅফলাকাঙ্ক্ষিভির্য়ুক্তৈঃ(স্),সাত্ত্বিকং(ম্)পরিচক্ষতে ৷৷17.17৷৷",

    "সত্কারমানপূজার্থং (ন্ ), তপো দম্ভেন চৈব য়ত \nক্রিয়তে তদিহ প্রোক্তং(ম্), রাজসং (ঞ) চলমধ্রুবম্ ৷৷17.18৷৷",

    "মূঢগ্রাহেণাত্মনো য়ত্,পীডয়া ক্রিয়তে তপঃ\nপরস্য়োত্সাদনার্থং(ম্ )বা ,তত্তামসমুদাহৃতম্ ৷৷17.19৷৷",

    "দাতব্য়মিতি য়দ্দানং(ন্),দীয়তে‌உনুপকারিণে \nদেশে কালে চ পাত্রে চ তদ্দানং(ম্),সাত্ত্বিকং(ম্),স্মৃতম ৷৷17.20৷৷",

    "য়ত্তু প্রত্ত্য়ুপকারার্থং(ম্),ফলমুদ্দিশ্য় বা পুনঃ\nদীয়তে চ পরিক্লিষ্টং(ন্),তদ্দানং(ম্)রাজসং(ম্)স্মৃতম ৷৷17.21৷৷",

    "অদেশকালে য়দ্দানম, অপাত্রেভ্য়শ্চ দীয়তে \nঅসত্কৃতমবজ্ঞাতং(ন্), তত্তামসমুদাহৃতম ৷৷17.22৷৷",

    "ওং তত্সদিতি নির্দেশো, ব্রহ্মণস্ত্রিবিধঃ(স্)স্মৃতঃ \nব্রাহ্মণাস্তেন বেদাশ্চ ,য়জ্ঞাশ্চ বিহিতাঃ (ফ্)পুরা ৷৷17.23৷৷",

    "তস্মাদোমিত্য়ুদাহৃত্য় ,য়জ্ঞদানতপঃ(খ্)ক্রিয়াঃ \nপ্রবর্তন্তে বিধানোক্তাঃ (স্ ), সততং(ম্)ব্রহ্মবাদিনাম ৷৷17.24৷৷",

    "তদিত্য়নভিসংধায়, ফলং(ম্)য়জ্ঞতপঃ(খ্)ক্রিয়াঃ \nদানক্রিয়াশ্চ বিবিধাঃ(খ্),ক্রিয়ন্তে মোক্ষকাঙ্ক্ষিভিঃ ৷৷17.25৷৷",

    "সদ্ভাবে সাধুভাবে চ, সদিত্য়েতত্প্রয়ুজ্য়তে \nপ্রশস্তে কর্মণি তথা, সচ্ছব্দঃ(ফ্)পার্থ য়ুজ্য়তে ৷৷17.26৷৷",

    "য়জ্ঞে তপসি দানে চ, স্থিতিঃ(স্)সদিতি চোচ্য়তে \nকর্ম চৈব তদর্থীয়ং(ম্), সদিত্য়েবাভিধীয়তে ৷৷17.27৷৷",

    "অশ্রদ্ধয়া হুতং(ন্)দত্তং (ন্ ),তপস্তপ্তং (ঙ)কৃতং(ঞ)চ য়ত \nঅসদিত্য়ুচ্য়তে পার্থ, ন চ তত্প্রেপ্য় নো ইহ  ৷৷17.28৷৷",
    
    "ॐ তৎসদিতি শ্রীমদ্ভগবদ্গীতাসু উপনিষৎসু ব্রহ্মবিদ‍্যায়াং(য়্ঁ) যোগশাস্ত্রে \nশ্রীকৃষ্ণার্জুনসংবাদে শ্রদ্ধাত্রয়বিভাগয়োগো নাম সপ্তদশোऽধ্যায়ঃ ॥17॥"
  ],
  "adhyaya-18" : [   
    "অর্জুন উবাচ\nসন্ন্যাসস্য মহাবাহো, তত্ত্বমিচ্ছামি বেদিতুম্ \nত্যাগস্য চ হৃষীকেশ, পৃথক্কেশিনিষূদন ॥18.1॥",

    "শ্রীভগবানুবাচ \nকাম্যানাং(ঙ্) কর্মণাং(ন্) ন্যাসং(ম্), সন্ন্যাসং(ঙ্) কবয়ো  বিদুঃ \nসর্বকর্মফলত্যাগং(ম্) প্রাহুস্ত্যাগং(ম্) বিচক্ষণাঃ ॥18.2॥",

    "ত্যাজ্যং(ন্) দোষবদিত্যেকে, কর্ম প্রাহুর্মনীষিণঃ \nয়জ্ঞদানতপঃকর্ম, ন ত্যাজ্যমিতি চাপরে ॥18.3॥",

    "নিশ্চয়ং(ম্) শৃণু মে তত্র, ত্যাগে ভরতসত্তম \nত্যাগো হি পুরুষব্যাঘ্র,  ত্রিবিধঃ(স্) সংপ্রকীর্তিতঃ ॥18.4॥",

    "য়জ্ঞদানতপঃকর্ম, ন ত্যাজ্যং(ঙ্) কার্য়মেব তত্ \nয়জ্ঞো  দানং(ন্) তপশ্চৈব, পাবনানি মনীষিণাম্ ॥18.5॥",

    "এতান্যপি তু কর্মাণি,  সঙ্গং(ন্) ত্যক্ত্বা ফলানি চ \nকর্তব্যানীতি মে পার্থ, নিশ্চিতং(ম্) মতমুত্তমম্ ॥18.6॥",

    "নিয়তস্য তু সন্ন্যাসঃ(খ্), কর্মণো নোপপদ্যতে \nমোহাত্তস্য পরিত্যাগস্ , তামসঃ(ফ্) পরিকীর্তিতঃ ॥18.7॥",

    "দুঃখমিত্যেব য়ত্কর্ম, কায়ক্লেশভয়াত্ত্যজেত্\nস কৃত্বা রাজসং(ন্) ত্যাগং(ন্), নৈব ত্যাগফলং(ম্) লভেত্ ॥18.8॥",

    "কার্য়মিত্যেব য়ত্কর্ম, নিয়তং(ঙ্) ক্রিয়তেऽর্জুন \nসঙ্গং(ন্) ত্যক্ত্বা ফলং(ঞ্) চৈব, স ত্যাগঃ(স্) সাত্ত্বিকো মতঃ ॥18.9॥",

    "ন দ্বেষ্ট্যকুশলং(ঙ্) কর্ম, কুশলে নানুষজ্জতে \nত্যাগী সত্ত্বসমাবিষ্টো, মেধাবী ছিন্নসংশয়: ॥18.10॥",

    "ন হি দেহভৃতা শক্যং(ন্),ত্যক্তুং(ঙ্) কর্মাণ্যশেষতঃ \nয়স্তু কর্মফলত্যাগী,স ত্যাগীত্যভিধীয়তে ॥18.11॥",

    "অনিষ্টমিষ্টং(ম্) মিশ্রং( ঞ্) চ, ত্রিবিধং(ঙ্) কর্মণঃ(ফ্) ফলম্ \nভবত্যত্যাগিনাং(ম্)  প্রেত্য, ন তু সন্ন্যাসিনাং(ঙ্) ক্বচিত্ ॥18.12॥",

    "পঞ্চৈতানি মহাবাহো, কারণানি নিবোধ মে \nসাংখ্যে কৃতান্তে প্রোক্তানি, সিদ্ধয়ে সর্বকর্মণাম্ ॥18.13॥",

    "অধিষ্ঠানং(ন্) তথা কর্তা, করণং(ঞ্)  চ পৃথগ্বিধম্ \nবিবিধাশ্চ পৃথক্চেষ্টা, দৈবং(ঞ্) চৈবাত্র পঞ্চমম্ ॥18.14॥",

    "শরীরবাঙ্মনোভির্য়ৎ,  কর্ম প্রারভতে নরঃ \nন্যায়্যং(ম্) বা বিপরীতং(ম্) বা,  পঞ্চৈতে তস্য হেতবঃ ॥18.15॥",

    "তত্রৈবং(ম্) সতি কর্তারম্, আত্মানং(ঙ্) কেবলং(ন্) তু য়:  \nপশ্যত্যকৃতবুদ্ধিত্বান্, ন স পশ্যতি দুর্মতিঃ ॥18.16॥",

    "য়স্য নাহংকৃতো ভাবো, বুদ্ধির্য়স্য ন লিপ্যতে \nহত্বাऽপি স ইমাঁল্লোকান্,ন  হন্তি ন নিবধ্যতে ॥18.17॥",

    "জ্ঞানং(ঞ্) জ্ঞেয়ং(ম্) পরিজ্ঞাতা, ত্রিবিধা কর্মচোদনা \nকরণং(ঙ্)কর্ম কর্তেতি, ত্রিবিধঃ(খ্) কর্মসঙ্গ্রহঃ ॥18.18॥",

    "জ্ঞানং(ঙ্)কর্ম চ কর্তা চ, ত্রিধৈব গুণভেদতঃ \nপ্রোচ্যতে গুণসংখ্যানে, যথাবচ্ছৃণু তান্যপি ॥18.19॥",

    "সর্বভূতেষু য়েনৈকং(ম্),  ভাবমব্যয়মীক্ষতে \nঅবিভক্তং(ম্) বিভক্তেষু,তজ্জ্ঞানং(ম্) বিদ্ধি সাত্ত্বিকম্ ॥18.20॥",

    "পৃথক্ত্বেন তু য়জ্জ্ঞানং(ন্),নানাভাবান্পৃথগ্বিধান্ \nবেত্তি সর্বেষু ভূতেষু, তজ্জ্ঞানং(ম্) বিদ্ধি রাজসম্ ॥18.21॥",

    "য়ত্তু কৃত্স্নবদেকস্মিন্ , কার্য়ে সক্তমহৈতুকম্ \nঅতত্ত্বার্থবদল্পং(ঞ্)চ,তত্তামসমুদাহৃতম্  ॥18.22॥",

    "নিয়তং(ম্) সঙ্গরহিতম্ ,অরাগদ্বেষতঃ(খ্) কৃতম্ \nঅফলপ্রেপ্সুনা কর্ম, য়ত্তত্সাত্ত্বিকমুচ্যতে ॥18.23॥",

    "য়ত্তু কামেপ্সুনা কর্ম, সাহঙ্কারেণ বা পুনঃ \nক্রিয়তে বহুলায়াসং(ন), তদ্রাজসমুদাহৃতম্  ॥18.24॥",

    "অনুবন্ধং(ঙ্) ক্ষয়ং(ম্) হিংসাম্, অনবেক্ষ্য চ পৌরুষম্ \nমোহাদারভ্যতে কর্ম, য়ত্তত্তামসমুচ্যতে ॥18.25॥",

    "মুক্তসঙ্গোऽনহংবাদী,ধৃত্যুত্সাহসমন্বিতঃ \nসিদ্ধ্যসিদ্ধ্যোর্নির্বিকারঃ(খ্),কর্তা সাত্ত্বিক উচ্যতে ॥18.26॥",

    "রাগী কর্মফলপ্রেপ্সু:(র্), লুব্ধো হিংসাত্মকোऽশুচিঃ \nহর্ষশোকান্বিতঃ(খ্)কর্তা,রাজসঃ(ফ্) পরিকীর্তিতঃ ॥18.27॥",

    "অয়ুক্তঃ(ফ্) প্রাকৃতঃ(স্) স্তব্ধঃ(শ্), শঠো নৈষ্কৃতিকোऽলসঃ \nবিষাদী দীর্ঘসূত্রী চ, কর্তা তামস উচ্যতে ॥18.28॥",

    "বুদ্ধের্ভেদং(ন্) ধৃতেশ্চৈব, গুণতস্ত্রিবিধং(ম্) শৃণু \nপ্রোচ্যমানমশেষেণ, পৃথক্ত্বেন ধনঞ্জয় ॥18.29॥",

    "প্রবৃত্তিং(ঞ্) চ নিবৃত্তিং(ঞ্) চ, কার্য়াকার্য়ে ভয়াভয়ে \nবন্ধং(ম্) মোক্ষং(ঞ্) চ য়া বেত্তি, বুদ্ধিঃ(স্) সা পার্থ সাত্ত্বিকী ॥18.30॥",

    "য়য়া ধর্মমধর্মং(ঞ্) চ, কার্য়ং(ঞ্) চাকার্য়মেব চ \nঅয়থাবত্প্রজানাতি, বুদ্ধিঃ(স্) সা পার্থ রাজসী ॥18.31॥",

    "অধর্মং(ন্) ধর্মমিতি য়া, মন্যতে তমসাবৃতা \nসর্বার্থান্বিপরীতাংশ্চ,  বুদ্ধিঃ(স্)  সা পার্থ তামসী ॥18.32॥",

    "ধৃত্যা য়য়াধারয়তে, মনঃপ্রাণেন্দ্রিয়ক্রিয়া:\nয়োগেনাব্যভিচারিণ্যা, ধৃতিঃ(স্) সা পার্থ সাত্ত্বিকী ॥18.33॥",

    "য়য়া তু ধর্মকামার্থান্,ধৃত্যা ধারয়তেऽর্জুন \nপ্রসঙ্গেন ফলাকাঙ্ক্ষী, ধৃতিঃ(স্) সা পার্থ রাজসী ॥18.34॥",

    "য়য়া  স্বপ্নং(ম্) ভয়ং(ম্) শোকং(ম্), বিষাদং(ম্) মদমেব চ \nন বিমুঞ্চতি দুর্মেধা, ধৃতিঃ(স্)  সা পার্থ তামসী ॥18.35॥",

    "সুখং(ন্) ত্বিদানীং(ন্) ত্রিবিধং(ম্),শৃণু মে ভরতর্ষভ \nঅভ্যাসাদ্রমতে য়ত্র, দুঃখান্তং(ঞ্) চ নিগচ্ছতি ॥18.36॥",

    "য়ত্তদগ্রে বিষমিব, পরিণামেऽমৃতোপমম্ \nতত্সুখং(ম্) সাত্ত্বিকং(ম্) প্রোক্তম্, আত্মবুদ্ধিপ্রসাদজম্  ॥18.37॥",

    " বিষয়েন্দ্রিয়সংয়োগাদ্ ,য়ত্তদগ্রেऽমৃতোপমম্\nপরিণামে বিষমিব, তত্সুখং(ম্) রাজসং(ম্) স্মৃতম্ ॥18.38॥",

    "য়দগ্রে  চানুবন্ধে চ, সুখং(ম্)  মোহনমাত্মনঃ \nনিদ্রালস্যপ্রমাদোত্থং(ন্) তত্তামসমুদাহৃতম্ ॥18.39॥",

    "ন তদস্তি পৃথিব্যাং(ম্) বা, দিবি দেবেষু বা পুনঃ \nসত্ত্বং(ম্) প্রকৃতিজৈর্মুক্তং(ম্), য়দেভিঃ(স্) স্যাৎ ত্রিভির্গুণৈঃ ॥18.40॥",

    "ব্রাহ্মণক্ষত্রিয়বিশাং(ম্), শূদ্রাণাং(ঞ্) চ পরন্তপ \nকর্মাণি প্রবিভক্তানি, স্বভাবপ্রভবৈর্গুণৈঃ ॥18.41॥",

    "শমো দমস্তপঃ(শ্) শৌচং(ঙ্), ক্ষান্তিরার্জবমেব চ \nজ্ঞানং(ম্) বিজ্ঞানমাস্তিক্যং(ম্), ব্রহ্মকর্ম স্বভাবজম্ ॥18.42॥",

    "শৌর্যং(ন্) তেজো ধৃতির্দাক্ষ্যং(ম্), য়ুদ্ধে চাপ্যপলায়নম্ \nদানমীশ্বরভাবশ্চ, ক্ষাত্রং(ঙ্) কর্ম স্বভাবজম্ ॥18.43॥",

    "কৃষিগৌরক্ষ্যবাণিজ্যং(ম্), বৈশ্যকর্ম স্বভাবজম্ \nপরিচর্য়াত্মকং(ঙ্) কর্ম, শূদ্রস্যাপি স্বভাবজম্ ॥18.44॥",

    "স্বে স্বে কর্মণ্যভিরতঃ(স্), সংসিদ্ধিং(ম্) লভতে নরঃ \nস্বকর্মনিরতঃ(স্) সিদ্ধিং(ম্), য়থা বিন্দতি তচ্ছৃণু ॥18.45॥",

    "য়তঃ(ফ্) প্রবৃত্তির্ভূতানাং(ম্),য়েন সর্বমিদং(ন্) ততম্ \nস্বকর্মণা তমভ্যর্চ্য, সিদ্ধিং(ম্) বিন্দতি মানবঃ  ॥18.46॥",

    " শ্রেয়ান্স্বধর্মো বিগুণঃ(ফ্), পরধর্মাৎস্বনুষ্ঠিতাত্ \nস্বভাবনিয়তং(ঙ্) কর্ম, কুর্বন্ নাপ্নোতি  কিল্বিষম্  ॥18.47॥",

    "সহজং(ঙ্) কর্ম কৌন্তেয়, সদোষমপি ন ত্যজেত্ \nসর্বারম্ভা হি দোষেণ, ধূমেনাগ্নিরিবাবৃতাঃ ॥18.48॥",

    "অসক্তবুদ্ধিঃ(স্) সর্বত্র, জিতাত্মা বিগতস্পৃহঃ \nনৈষ্কর্ম্যসিদ্ধিং(ম্) পরমাং(ম্),সন্ন্যাসেনাধিগচ্ছতি ॥18.49॥",

    "সিদ্ধিং(ম্) প্রাপ্তো য়থা ব্রহ্ম, তথাপ্নোতি নিবোধ মে \nসমাসেনৈব কৌন্তেয়, নিষ্ঠা জ্ঞানস্য য়া পরা  ॥18.50॥",

    "বুদ্ধ্যা বিশুদ্ধয়া য়ুক্তো, ধৃত্যাত্মানং(ন্) নিয়ম্য  চ \nশতাব্দীন্বিষয়াংস্ত্যক্ত্বা, রাগদ্বেষৌ ব্যুদস্য চ ॥18.51॥",

    "বিবিক্তসেবী লঘ্বাশী, য়তবাক্কায়মানসঃ \nধ্যানয়োগপরো নিত্যং(ম্), বৈরাগ্যং(ম্) সমুপাশ্রিতঃ ॥18.52॥",

    "অহংকারং(ম্) বলং(ন্) দর্পং(ঙ্), কামং(ঙ্)  ক্রোধং(ম্) পরিগ্রহম্ \nবিমুচ্য নির্মমঃ(শ্) শান্তো, ব্রহ্মভূয়ায় কল্পতে ॥18.53॥",

    "ব্রহ্মভূতঃ(ফ্) প্রসন্নাত্মা, ন শোচতি ন কাঙ্ক্ষতি \nসমঃ(স্) সর্বেষু ভূতেষু, মদ্ভক্তিং(ম্) লভতে পরাম্ ॥18.54॥",

    "ভক্ত্যা মামভিজানাতি, য়াবান্যশ্চাস্মি তত্ত্বতঃ \nততো মাং(ন্)তত্ত্বতো জ্ঞাত্বা, বিশতে তদনন্তরম্  ॥18.55॥",

    "সর্বকর্মাণ্যপি সদা, কুর্বাণো মদ্ ব্যপাশ্রয়: \nমত্প্রসাদাদবাপ্নোতি, শাশ্বতং(ম্) পদমব্যয়ম্ ॥18.56॥",

    "চেতসা সর্বকর্মাণি, ময়ি সন্ন্যস্য মত্পরঃ \nবুদ্ধিয়োগমুপাশ্রিত্য, মচ্চিত্তঃ(স্) সততং(ম্) ভব ॥18.57॥",

    "মচ্চিত্তঃ(স্) সর্বদুর্গাণি, মত্প্রসাদাত্তরিষ্যসি \nঅথ চেত্ত্বমহঙ্কারান্ ,ন শ্রোষ্যসি বিনঙ্ ক্ষ্যসি ॥18.58॥",

    "যদহঙ্কারমাশ্রিত্য, ন য়োৎস্য ইতি মন্যসে \nমিথ্যৈষ ব্যবসায়স্তে, প্রকৃতিস্ত্বাং(ন্) নিয়োক্ষ্যতি ॥18.59॥",

    "স্বভাবজেন কৌন্তেয়, নিবদ্ধঃ(স্)  স্বেন কর্মণা \nকর্তুং(ন্) নেচ্ছসি য়ন্মোহাত্ ,করিষ্যস্যবশোऽপি তত্  ॥18.60॥",

    "ঈশ্বরঃ(স্) সর্বভূতানাং(ম্),হৃদ্দেশেऽর্জুন তিষ্ঠতি \nভ্রাময়ন্সর্বভূতানি, য়ন্ত্রারূঢানি মায়য়া ॥18.61॥",

    "তমেব শরণং(ঙ্) গচ্ছ,  সর্বভাবেন ভারত \nতত্প্রসাদাত্পরাং(ম্) শান্তিং(ম্),স্থানং(ম্)  প্রাপ্স্যসি শাশ্বতম্ ॥18.62॥",

    "ইতি তে জ্ঞানমাখ্যাতং(ঙ্), গুহ্যাদ্ গুহ্যতরং (ম্) ময়া \nবিমৃশ্যৈতদশেষেণ, য়থেচ্ছসি তথা কুরু ॥18.63॥",

    "সর্বগুহ্যতমং(ম্) ভূয়ঃ(শ্), শৃণু মে পরমং(ম্) বচঃ \nইষ্টোऽসি মে দৃঢমিতি, ততো বক্ষ্যামি তে হিতম্ ॥18.64॥",

    "মন্মনা ভব মদ্ভক্তো, মদ্যাজী মাং(ন্) নমস্কুরু \nমামেবৈষ্যসি সত্যং(ন্) তে, প্রতিজানে প্রিয়োऽসি মে ॥18.65॥",

    "সর্বধর্মান্পরিত্যজ্য, মামেকং(ম্) শরণং(ম্) ব্রজ \nঅহং(ন্) ত্বাং(ম্) সর্বপাপেভ্যো, মোক্ষয়িষ্যামি মা শুচঃ ॥18.66॥",

    "ইদং(ন্) তে নাতপস্কায়,নাভক্তায় কদাচন \nন চাশুশ্রূষবে বাচ্যং(ন্), ন চ মাং(ম্) য়োऽভ্যসূয়তি ॥18.67॥",

    "য় ইমং(ম্) পরমং(ঙ্) গুহ্যং(ম্), মদ্ভক্তেষ্বভিধাস্যতি \nভক্তিং(ম্) ময়ি  পরাং(ঙ্) কৃত্বা, মামেবৈষ্যত্যসংশয়: ॥18.68॥",

    "ন চ তস্মান্মনুষ্যেষু, কশ্চিন্মে প্রিয়কৃত্তমঃ \nভবিতা ন চ মে তস্মাদ্ ,অন্যঃ(ফ্)  প্রিয়তরো ভুবি ॥18.69॥",

    "অধ্যেষ্যতে চ য় ইমং(ন্),ধর্ম্যং(ম্) সংবাদমাবয়ো: \nজ্ঞানয়জ্ঞেন তেনাহম্, ইষ্ট:(স্) স্যামিতি মে মতিঃ ॥18.70॥",

    "শ্রদ্ধাবাননসূয়শ্চ, শৃণুয়াদপি য়ো নরঃ \nসোऽপি মুক্তঃ(শ্) শুভাঁল্লোকান্ প্রাপ্নুয়াত্পুণ্যকর্মণাম্ ॥18.71॥",

    "কচ্চিদেতচ্ছ্রুতং (ম্) পার্থ, ত্বয়ৈকাগ্রেণ চেতসা \nকচ্চিদজ্ঞানসম্মোহঃ(ফ্),  প্রনষ্টস্তে ধনঞ্জয় ॥18.72॥",

    "অর্জুন উবাচ\nনষ্টো মোহঃ(স্) স্মৃতির্লব্ধা, ত্বত্প্রসাদান্ময়াচ্যুত \nস্থিতোऽস্মি গতসন্দেহঃ(খ্),করিষ্যে বচনং(ন্) তব ॥18.73॥",

    "সঞ্জয় উবাচ \nইত্যহং(ম্) বাসুদেবস্য, পার্থস্য চ মহাত্মনঃ \nসংবাদমিমমশ্রৌষম্ ,অদ্ভুতং(ম্) রোমহর্ষণম্ ॥18.74॥",

    "ব্যাসপ্রসাদাচ্ছ্রুতবান্,  এতদ্ গুহ্যমহং(ম্) পরম্ \nয়োগং(ম্) য়োগেশ্বরাত্কৃষ্ণাত্, সাক্ষাত্কথয়তঃ(স্) স্বয়ম্ ॥18.75॥",

    "রাজন্সংস্মৃত্য সংস্মৃত্য, সংবাদমিমমদ্ভুতম্ \nকেশবার্জুনয়ো:(ফ্) পুণ্যং(ম্), হৃষ্যামি চ মুহুর্মুহুঃ ॥18.76॥",

    "তচ্চ সংস্মৃত্য সংস্মৃত্য,রূপমত্যদ্ভুতং(ম্) হরেঃ \nবিস্ময়ো মে মহান্ রাজন্ , হৃষ্যামি চ পুনঃ(ফ্) পুনঃ ॥18.77॥",

    "য়ত্র য়োগেশ্বরঃ(খ্) কৃষ্ণো, য়ত্র পার্থো ধনুর্ধরঃ \nতত্র শ্রীর্বিজয়ো ভূতি:(র্), ধ্রুবানীতির্মতির্মম ॥18.78॥",

    "ॐ তত্সদিতি শ্রীমদ্ভগবদ্গীতাসু উপনিষত্সু ব্রহ্মবিদ্যায়াং(ম্) য়োগশাস্ত্রে\nরীকৃষ্ণার্জুনসংবাদেমোক্ষসন্ন্যাসয়োগো নাম অষ্টাদশোऽধ্যায়: ॥18॥"
  ]
},
{
  "adhyaya-1" : [
    "ধৃতরাষ্ট্র বললেন- হে সঞ্জয়! ধর্মক্ষেত্রে  কুরুক্ষেত্রে যুদ্ধের ইচ্ছায় সমবেত  আমার এবং পান্ডুর পুত্রগণ কি করল? 1.1",

    "সঞ্জয় বললেন তখন বজ্রব্যূহের দন্ডায়মান পান্ডব সৈন্যগণ কে দেখে রাজা দুর্যোধন দ্রোণাচার্যের নিকটে এই কথা বললেন 1.2",

    "হে আচার্যদেব আপনার ধীমান শিশু দ্রুপদপুত্র ধৃষ্টদ্যুম্ন কর্তৃক রচিত ব্যুহে অবাঞ্ছিত এই বিশাল সৈন্য সমূহকে লক্ষ্য করুন 1.3",

    "এই স্থানে (পান্ডব সৈন্যদলে ) বহু বড় বড় বীরযোদ্ধা আছেন, যাদের অনেক বিশাল  ধনুক আছে এবং যারা ভীম ও অর্জুনের সমকক্ষ যোদ্ধাl এদের মধ্যে রয়েছেন যুযুধান( সাত্যকি),বিরাট রাজা এবং মহারথী দ্রুপদ রয়েছেন।  1.4",

    "রয়েছেন ধৃষ্টকেতু, চেকিতান এবং মহা পরাক্রমী কাশীরাজ, আরো আছেন পুরোজিৎ এবং কুন্তিভোজ দুই ভাই এবং নর শ্রেষ্ঠ শৈব্য আছেন ।  1.5",

    "আছেন  পরাক্রমী যুধামন্যুএবং উত্তমৌজা, আছেন সুভদ্রার পুত্র অভিমুন্য এবং দ্রোপদীর পঞ্চপুত্র-  এরা সকলেই মহাপরাক্রমশালী মহারথী। 1.6",

    "হে দ্বিজোত্তম! আমাদের পক্ষে ও যারা সেনাপ্রধান , তাদের আপনি অবগত হন ।  আপনাকে স্মরণ করাবার জন্য তাদের কথা বলছি। 1.7",

    "আপনি (দ্রোণাচার্য ) এবং পিতামহ ভীষ্ম এবং কর্ণ ও সংগ্রাম বিজয়ী কৃপাচার্য , তেমনি অশ্বথামা, বিকর্ণ ও সোমদত্তের পুত্র ভূরিশ্রবা 1.8",

    "এরা ছাড়া আরও যোদ্ধা আছেন, যারা আমার জন্য প্রাণত্যাগে প্রস্তুত হয়েছেন এবং যারা নানাপ্রকার অস্ত্রবিদ্যা জানেন এবং সর্বপ্রকার যুদ্ধবিদ্যায় পারদর্শী 1.9",

    "আমাদের সৈন্যগণ পান্ডবদের জয় করার পক্ষে পর্যাপ্ত নয় ও অসমর্থ কারণ তাদের সংরক্ষক হিসাবে আছেন (উভয়েরই পক্ষপাতী) ভীষ্ম। কিন্তু পান্ডব সৈন্যগণ আমাদের জয় করার পক্ষে পর্যাপ্ত এবং সমর্থ;  কারণ তাদের সংরক্ষক হিসেবে যিনি আছেন তিনি হলেন (নিজসেনাদলের পক্ষপাতী) ভীম। 1.10",

    "আপনারা সবাই সকল ব্যূহে নিজ নিজ স্থানে দৃঢ়তাপূর্বক অবস্থান করে পিতামহ ভীষ্মকে চতুর্দিক থেকে রক্ষা করুন ৷ 1.11",

    "দুর্যোধনের হৃদয়ে আনন্দ আনয়নের জন্য কুরুবংশীয় বৃদ্ধ প্রভাবশালী পিতামহ ভীষ্ম সিংহের মতো গর্জন করে উচ্চরবে শঙ্খ বাজালেন ৷ 1.12",

    "তারপর শঙ্খ, ভেরী (নাকাড়া), ঢোল, মৃদঙ্গ এবং নরশিঙ্গা আদি সমস্ত বাদ্য একসঙ্গে বেজে উঠল। এই সম্মিলিত শব্দ ভয়ংকর শোনাল ৷  1.13",

    "তারপর শ্বেত অশ্ববাহিত সুন্দর রথে উপবিষ্ট লক্ষ্মীপতি ভগবান শ্রীকৃষ্ণ এবং পাণ্ডুপুত্র অর্জুন তাঁদের দিব্য শঙ্খগুলি উচ্চৈঃস্বরে বাজালেন। 1.14",

    "অন্তর্যামী ভগবান শ্রীকৃষ্ণ পাঞ্চজন্য এবং ধনঞ্জয় অর্জুন দেবদত্ত নামক শঙ্খ বাজালেন এবং ভীতি উৎপাদক কর্মকারী বৃকোদর ভীম পৌণ্ড্র নামক মহাশঙ্খ বাজালেন৷ 1.15",

    "কুম্ভীপুত্র রাজা যুধিষ্ঠির অনন্তবিজয় নামক শঙ্খ বাজালেন এবং নকুল ও সহদেব সুঘোষ এবং মণিপুষ্পক নামক শঙ্খ বাজালেন ৷  1.16",

    "হে রাজন্ ! মহাধনুর্ধর কাশীরাজ, মহারথী শিখণ্ডী, ধৃষ্টদ্যুম্ন, রাজা বিরাট, অজেয় সাত্যকি, রাজা দ্ৰুপদ, দ্রৌপদীর পঞ্চপুত্র এবং মহাবীর অভিমন্যু—এঁরা সকলেই পৃথক পৃথক ভাবে নিজ নিজ শঙ্খবাদন করলেন। 1.17-1.18",

    "হে রাজন্ ! মহাধনুর্ধর কাশীরাজ, মহারথী শিখণ্ডী, ধৃষ্টদ্যুম্ন, রাজা বিরাট, অজেয় সাত্যকি, রাজা দ্ৰুপদ, দ্রৌপদীর পঞ্চপুত্র এবং মহাবীর অভিমন্যু—এঁরা সকলেই পৃথক পৃথক ভাবে নিজ নিজ শঙ্খবাদন করলেন। 1.17-1.18",

    "সেই তুমুল শব্দ আকাশ ও পৃথিবীকে কম্পায়মান করে অর্থাৎ আপনার পক্ষে যুদ্ধে যোগদানকারীদের হৃদয় বিদীর্ণ করল। 1.19",

    "হে রাজন ! এরপর অস্ত্র নিক্ষেপের সময় অর্থাৎ যুদ্ধারম্ভের প্রাক্কালে কপিধ্বজ রথারূঢ় অর্জুন যুদ্ধোদ্যত ধৃতরাষ্ট্র-পরিজনদের দেখে ধনুক উত্তোলন করে হৃষীকেশ শ্রীকৃষ্ণকে এই কথা বললেন-হে অচ্যুত ! আমার রথটিকে উভয় সেনার মধ্যে স্থাপন করুন। 1.20-1.21",

    "হে রাজন ! এরপর অস্ত্র নিক্ষেপের সময় অর্থাৎ যুদ্ধারম্ভের প্রাক্কালে কপিধ্বজ রথারূঢ় অর্জুন যুদ্ধোদ্যত ধৃতরাষ্ট্র-পরিজনদের দেখে ধনুক উত্তোলন করে হৃষীকেশ শ্রীকৃষ্ণকে এই কথা বললেন-হে অচ্যুত ! আমার রথটিকে উভয় সেনার মধ্যে স্থাপন করুন। 1.20-1.21",

    "যতক্ষণ না যুদ্ধক্ষেত্রে অবস্থিত এই যুদ্ধাভিলাষী বিপক্ষীয় যোদ্ধাদের ভালো করে দেখি যে, এই মহারণে আমাকে কাদের সঙ্গে যুদ্ধ করতে হবে, ততক্ষণ রথটিকে ঐভাবে রাখুন।  1.22",

    "দুর্বুদ্ধি দুর্যোধনের হিতাকাক্ষী যে সকল রাজন্যবর্গ এখানে সমবেত হয়েছেন, সেই সকল যুদ্ধার্থীদের আমি দেখতে চাই। 1.23",

    "সঞ্জয় বললেন—হে ধৃতরাষ্ট্র ! অর্জুন এইরূপ বলায় শ্রীকৃষ্ণ দুই পক্ষের সেনার মধ্যে ভীষ্ম, দ্রোণ এবং অন্যান্য রাজন্যবর্গের সামনে উত্তম রথটি স্থাপন করে বললেন, হে পার্থ ! যুদ্ধে সমবেত এই কৌরবদের দেখ। 1.24-1.25",

    "সঞ্জয় বললেন—হে ধৃতরাষ্ট্র ! অর্জুন এইরূপ বলায় শ্রীকৃষ্ণ দুই পক্ষের সেনার মধ্যে ভীষ্ম, দ্রোণ এবং অন্যান্য রাজন্যবর্গের সামনে উত্তম রথটি স্থাপন করে বললেন, হে পার্থ ! যুদ্ধে সমবেত এই কৌরবদের দেখ। 1.24-1.25",

    "তখন পৃথাপুত্র অর্জুন উভয় সেনাবাহিনীতে অবস্থানকারী পিতৃব্যগণ, পিতামহগণ, আচার্যগণ, মাতুলগণ, ভ্রাতৃগণ, পুত্রগণ, পৌত্রগণ, মিত্রগণ, শ্বশুরগণ এবং সুহৃদণকে লক্ষ্য করলেন। 1.26-1.27",

    "তখন পৃথাপুত্র অর্জুন উভয় সেনাবাহিনীতে অবস্থানকারী পিতৃব্যগণ, পিতামহগণ, আচার্যগণ, মাতুলগণ, ভ্রাতৃগণ, পুত্রগণ, পৌত্রগণ, মিত্রগণ, শ্বশুরগণ এবং সুহৃদণকে লক্ষ্য করলেন। 1.26-1.27",

    "অর্জুন খুবই করুণার্দ্র হয়ে বিষণ্ণ চিত্তে এই কথা বললেন।\nঅর্জুন বললেন—হে কৃষ্ণ ! যুদ্ধক্ষেত্রে সমবেত এই যুদ্ধাভিলাষী স্বজনদের দেখে আমার অঙ্গপ্রত্যঙ্গাদি অবসন্ন হচ্ছে, মুখ শুকিয়ে যাচ্ছে, শরীরে কম্পন ও রোমাঞ্চ হচ্ছে। 1.28-1.29",

    "অর্জুন খুবই করুণার্দ্র হয়ে বিষণ্ণ চিত্তে এই কথা বললেন।\nঅর্জুন বললেন—হে কৃষ্ণ ! যুদ্ধক্ষেত্রে সমবেত এই যুদ্ধাভিলাষী স্বজনদের দেখে আমার অঙ্গপ্রত্যঙ্গাদি অবসন্ন হচ্ছে, মুখ শুকিয়ে যাচ্ছে, শরীরে কম্পন ও রোমাঞ্চ হচ্ছে। 1.28-1.29",

    "গাণ্ডীব ধনুক হাত থেকে পড়ে যাচ্ছে, ত্বকে খুবই জ্বালাবোধ হচ্ছে, মন যেন ঘুরছে, তাই আমার দাঁড়িয়ে থাকার সামর্থ্যও থাকছে না। 1.30",

    "হে কেশব ! আমি অশুভ লক্ষণ সকল দেখছি এবং যুদ্ধে স্বজনদের হত্যা করে আমি কোনও মঙ্গল দেখছি না। 1.31",

    "হে কৃষ্ণ ! আমি জয় চাই না, রাজ্য ও সুখভোগও চাই না। হে গোবিন্দ ! আমাদের রাজ্যে কী প্রয়োজন আর সুখভোগে ও জীবনধারণেই বা কী প্রয়োজন? 1.32",

    "আমরা যাঁদের জন্য রাজ্য, ভোগ, সুখাদি কামনা করি তাঁরাই অর্থ এবং প্রাণের আশা ত্যাগ করে যুদ্ধের জন্য উপস্থিত হয়েছে। 1.33",

    "আচার্যগণ, পিতৃব্যগণ, পুত্রগণ, পিতামহগণ, মাতুলগণ, শ্বশুরগণ, পৌত্রগণ, শ্যালকগণ এবং অন্যান্য আত্মীয়গণও উপস্থিত রয়েছেন। 1.34",

    "হে মধুসূদন ! আমাকে বধ করলে অথবা ঐলোকের রাজ্যের জন্যও আমি কোনো মতেই এঁদের বধ করতে চাই না, অতএব পৃথিবীর রাজত্বের তো কথাই নেই। 1.35",

    "হে জনার্দন ! ধৃতরাষ্ট্রের পুত্রদের বধ করে আমাদের কী সুখ হবে ? এইসকল আততায়ীদের বধ করলে তো আমাদের পাপই হবে। 1.36",

    "অতএব হে মাধব ! দুর্যোধনাদি ও তাদের স্বজন পরিজনদের বধ করা আমাদের উচিত নয় ; কেননা নিজ কুটুম্বদের হত্যা করে আমরা কীরূপে সুখী হব ? 1.37",
    
    "যদিও লোভে ভ্রষ্টচিত্ত হয়ে এঁরা কুলনাশ হতে উৎপন্ন ক্ষয়ক্ষতি এবং স্বজনদের সঙ্গে বিরোধে কোনো পাপ দেখছেন না, কিন্তু হে জনার্দন ! কুলনাশজনিত কুপরিণাম জেনেও আমরা কেন এই পাপ হতে নিবৃত্ত হব না ? 1.38-1.39",
    
    "যদিও লোভে ভ্রষ্টচিত্ত হয়ে এঁরা কুলনাশ হতে উৎপন্ন ক্ষয়ক্ষতি এবং স্বজনদের সঙ্গে বিরোধে কোনো পাপ দেখছেন না, কিন্তু হে জনার্দন ! কুলনাশজনিত কুপরিণাম জেনেও আমরা কেন এই পাপ হতে নিবৃত্ত হব না ? 1.38-1.39",
    
    "কুলক্ষয়ে সনাতন কুলধর্ম নষ্ট হয়, ধর্ম নষ্ট হলে সমস্ত কুলে পাপ ছড়িয়ে পড়ে। 1.40",
    
    "হে কৃষ্ণ ! পাপ অত্যন্ত বৃদ্ধি পেলে কুলস্ত্রীগণ দুষ্টা (অসচ্চরিত্রা) হয়। হে বায়ে ! কুলস্ত্রীগণ দুষ্টা হলে বর্ণসঙ্কর উৎপন্ন হয়। 1.41",
    
    "বর্ণসঙ্কর উৎপন্ন হলে তা কুলঘাতকদের এবং কুলকে নরকগামী করে এবং শ্রাদ্ধ-তর্পণ হতে বঞ্চিত হওয়ায় পিতৃপুরুষগণও অধোগতি প্রাপ্ত হন। 1.42",
    
    "এই সকল বর্ণসঙ্করকারক দোষে কুলনাশকারীদের সনাতন কুলধর্ম ও জাতিধর্ম নষ্ট হয়ে যায়। 1.43",
    
    "হে জনার্দন ! আমরা শুনেছি যাদের কুলধর্ম নষ্ট হয়, তাদের অনিশ্চিত কাল পর্যন্ত নরকে বাস করতে হয়। 1.44",
    
    "হায় ! দুর্ভাগ্য ! আমরা বুদ্ধিমান হয়েও কী মহাপাপ করতে প্রবৃত্ত হয়েছি, রাজ্য ও সুখভোগের আশায় স্বজন বধ করতে উদ্যত হয়েছি ! 1.45",
    
    "যদি আমাকে শস্ত্ররহিত ও প্রতিকারহীন অবস্থায় দেখে শস্ত্রধারী ধৃতরাষ্ট্রের পুত্রেরা বধও করে, তবে সেই মৃত্যুও আমার পক্ষে কল্যাণজনক হবে। 1.46",
    
    "সঞ্জয় বললেন—রণভূমিতে শোকে উদ্বিগ্ন-চিত্ত অর্জুন এই কথা বলে ধনুর্বাণ ত্যাগ করে রথের মধ্যে উপবেশন করলেন। 1.47",
    
    "এইরূপ ওঁ তৎ সৎ - এই ভগবানের নামগুলি উচ্চারণপূর্বক ব্রহ্মবিদ্যা এবং য়োগশাস্ত্রময় শ্রীমদ্ভবদ্গীতোপনিষদরূপ শ্রীকৃষ্ণার্জুনসংবাদে 'অর্জুনবিষাদয়োগ' নামক প্রথম অধ্যায় সমাপ্ত হলো।"
  ],
  "adhyaya-2" : [
    "সঞ্জয় বললেন, কাপুরুষতায় আবিষ্ট , বিষাদমগ্ন এবং অশ্রুপূর্ণ অবরুদ্ধ-নেত্র অর্জুনকে ভগবান এই বাক্যগুলি (পরের শ্লোকে) বললেন ৷ 2.1",

    "শ্রী ভগবান বললেন, হে অর্জুন! এই বিষম সময়ে তোমার এই কাপুরুষতা কোথা থেকে এল, যা কোন শ্রেষ্ঠ পুরুষের হয়না , যাতে স্বর্গলাভ হয়না , যা কীতিমান করে না ৷ 2.2",

    "হে পৃথা নন্দন অর্জুন! এই নপুংসকতা আশ্রয় কোরো না , এ তোমার উচিত নয় ৷ হে পরন্তপ! এই তুচ্ছ হৃদয় -দৌর্বল্য ত্যাগ করে যুদ্ধের জন্য প্রস্তুত হও ৷ 2.3",

    "অর্জুন বললেন- হে মধুসুদন! রণভূমিতে ভীষ্ম এবং দ্রোনের সংগে বাণ দ্বারা আমি কি করে যুদ্ধ করব? কারণ হে অরিসূদন,  এরা দুজনেই আমার পূজানীয় হবে৷ 2.4",

    "মহানুভব গুরুজন দিগকে বধ করা অপেক্ষা ইহলোকে ভিক্ষা গ্রহন ই আমি শ্রেষ্ঠ বলে মনে করি ৷ কারণ গুরুজনদের বধ করে এই শোনিত সিক্ত ধনসম্পতিই ও রাজ্যইতো ভোগ করতে হবে! 2.5",

    "আমরা সঠিকভাবে জানি না যে যুদ্ধ করা বা না করা কোনটা আমাদের পক্ষে শ্রেয়় ৷ আমরা এও জানি না লে আমরা জয়লাভ করব না ওরা জয় লাভ করবে ৷ যে ধৃতরাষ্ট্রের আত্মীয় স্বজনদের বধ করে আমরা শুধু জয়লাভ কেন, বাঁচতেও চাইনা , আজ তারাই আমাদের সামনে উপস্থিত ৷ 2.6",

    "আমি আপনাকে জিজ্ঞাসা করছি যে, কাপুরুষতা দোষে অভিভূত স্বভাব এবং ধর্ম বিষয়ে বিমূঢ় চিত্ত কোনটি আমার পক্ষে শ্রেয় তাহা নিশ্চিত রূপে আমায় বলুন৷ আমি আপনার শিষ্য , শরণাগত আমায় আপনি শিক্ষা দান করুন৷ 2.7",

    "পৃথিবীতে ধনধান্যসমৃদ্ধ নিষ্কন্টক রাজ্য এবং স্বর্গে দেবতাগণের আধিপত্য যদি পাওয়া যায়, তাহলেও আমার ইন্দ্রিয়সমূহের সন্তাপক শোক দূরীভূত হবে বলে আমার মনে হয় না ৷ 2.8",

    "সঞ্জয় বললেন –হে শত্রুতাপন ধৃতরাষ্ট্র ! এই কথা বলে নিদ্রাজয়ী অর্জুন ভগবান গোবিন্দকে 'আমি যুদ্ধ করব না' স্পষ্টভাবে জানিয়ে চুপ করে গেলেন ৷  2.9",

    "হে ভরতবংশোদ্ভব ধৃতরাষ্ট্র ! দুই পক্ষের সেনার মধ্যহলে বিষাদমগ্ন সেই অর্জুনকে ভগবান হৃষীকেশ স্মিতহাস্যে এই (পরবর্তী শ্লোকে বলা) কথা বললেন ৷ 2.10",

    "শ্রীভগবান বললেন যাদের জন্য শোক করা উচিত নয় তাদের জন্য তুমি শোক করছ, আবার পণ্ডিতদের মতো কথা বলছ ; কিন্তু পণ্ডিতগণ মৃত বা জীবিত কারো জন্য শোক করেন না । 2.11",

    "কোনো সময়ে আমি ছিলাম না বা তুমি ছিলে না অথবা এই রাজন্যবর্গ ছিল না, একথা ঠিক নয়। আর এর পরে আমি, তুমি এবং এই নৃপতিবৃন্দ—এরা সকলে থাকবে না তাও ঠিক নয় ৷ 2.12",

    "প্রত্যেক দেহধারীরই এই মনুষ্যদেহে যেমন বালা, যৌবন এবং বার্ধক্য উপস্থিত হয়, প্রাপ্তিও হয়। ধীর ব্যক্তিগণ তাতে মোহগ্রস্ত হন না ৷ 2.13",

    "হে কুন্তীনন্দন ! ইন্দ্রিয়গুলির দ্বারা যার জ্ঞান হয় সেইসকল জড়বস্তু শীত (অনুকূল) এবং উষ্ণ (প্রতিকূল) ভাবনানুযায়ী সুখ ও দুঃখ প্রদান করে। সেগুলি উৎপত্তি ও বিনাশশীল, সুতরাং তা অনিত্য। তাই হে ভরতবংশোদ্ভব অর্জুন, তুমি এগুলিকে সহ্য করো । 2.14",

    "হে পুরুষশ্রেষ্ঠ অর্জুন ! সুখ-দুঃখে সমভাবে হিত যে ধীর ব্যক্তিকে এই বিষয়স্পর্শজনিত সুখ-দুঃখ বিচলিত করে না, তিনি অমৃতত্ব লাভে সমর্থ হন অর্থাৎ তিনি অমর হন । 2.15",

    "অসৎ বস্তুর ভাব (অস্তিত্ব) নেই এবং সৎ বস্তুর অনস্তিত্ব নেই। তত্ত্বদর্শী মহাপুরুষগণ এই দুটিরই পরিণতি অর্থাৎ এ দুটিকে তত্ত্বত দেখেছেন অর্থাৎ অনুভব করেছেন ৷ 2.16",

    "অবিনাশী বলে তাঁকেই জানবে, যিনি এই সমস্ত জগৎ পরিব্যাপ্ত করে আছেন। এই অবিনাশীর বিনাশ কেউই করতে পারে না ৷ 2.17",

    "অবিনাশী, অপ্রমেয় এবং নিত্যহিত শরীরীর (জীবাত্মার) আশ্রিত এই দেহকে নশ্বর বলা হয়েছে। অতএব হে অর্জুন ! তুমি যুদ্ধ করো (স্বধর্মের পালন করো) ৷ 2.18",

    "যিনি এই আত্মাকে হত্যাকারী মনে করেন অথবা যিনি এঁকে নিহত বলে মনে করেন তাঁরা উভয়েই আত্মার প্রকৃত স্বরূপ জানেন না; কারণ এই আত্মা প্রকৃতপক্ষে কাউকে হত্যা করেন না এবং কারো দ্বারা হতও হন না। 2.19",

    "এই আত্মা কখনও জন্মান না বা মরেনও না এবং আত্মার অস্তিত্ব উৎপত্তিসাপেক্ষ নয়, কারণ আত্মা জন্মরহিত, নিত্য, সনাতন এবং পুরাতন ; শরীর বিনষ্ট হলেও আত্মা বিনষ্ট হন না। 2.20",

    "হে পার্থ ! যিনি এই আত্মাকে অবিনাশী, নিত্য, জন্মরহিত এবং অব্যয় বলে জানেন, তিনি কীভাবে কাকেও হত্যা করবেন বা করাবেন ? 2.21",

    "যেমন মানুষ পুরানো বস্ত্র পরিত্যাগ করে অন্য নূতন বস্ত্র গ্রহণ করে, তেমনই জীবাত্মা পুরণো শরীরগুলিকে ত্যাগ করে অন্য নূতন নূতন শরীর গ্রহণ করে। 2.22",

    "শস্ত্র এই আত্মাকে কাটতে পারে না, অগ্নি দগ্ধ করতে পারে না, জল সিক্ত করতে পারে না এবং বায়ু এঁকে শুষ্ক করতে পারে না।  2.23",

    "কারণ এই আত্মা অচ্ছেদ্য, অদাহ্য, অক্লেদ্য ও অশোষ্য এবং নিত্য, সর্বব্যাপী, অচল, স্থির ও সনাতন। 2.24",

    "এই আত্মাকে অব্যক্ত, অচিন্ত্য এবং বিকাররহিত বলা হয়। তাই হে অর্জুন ! এই আত্মাকে উক্তপ্রকার জেনে তোমার শোক করা উচিত নয়। 2.25",

    "আর যদি তুমি এই আত্মাকে নিত্য জন্মশীল এবং নিত্য মরণশীল বলে মনে করো, তবুও হে মহাবাহো ! তোমার শোক করা উচিত নয়। 2.26",

    "কারণ এইরূপ মনে করলেও যে জন্মায় তার মৃত্যু নিশ্চিত এবং মৃতের জন্মও নিশ্চিত। সুতরাং এই অপরিহার্য বিষয়ে তোমার শোক করা উচিত নয়। 2.27",

    "হে ভারত ! সমস্ত প্রাণী জন্মের পূর্বে অপ্রকট ছিল, মৃত্যুর পরও অপ্রকট হয়ে যায়, কেবল মধ্যবর্তী সময়েই প্রকটিত থাকে। এই পরিস্থিতিতে বিলাপ কিসের ? 2.28",

    "কেউ এই আত্মাকে আশ্চর্যবৎ দেখেন, অন্য কেউ এঁকে আশ্চর্যবৎ বর্ণনা করেন এবং অপর কেউ এই আত্মাকে আশ্চর্যান্বিত হয়ে শ্রবণ করেন আর কেউ কেউ তো শুনেও এঁর সম্বন্ধে জানে না কারণ, আত্মা দুর্বিজ্ঞেয়। 2.29",

    "হে অর্জুন ! এই আত্মা সকলের দেহে সর্বদাই অবধ্য। এই কারণে কোনো প্রাণীর জন্য তোমার শোক করা উচিত নয়। 2.30",

    "এবং নিজ ধর্মের দিকে দৃষ্টি রেখেও তোমার ভীত হওয়া উচিত নয়, কারণ ক্ষত্রিয়ের পক্ষে ধর্মযুদ্ধ অপেক্ষা বড় আর কোনো কল্যাণকর কর্তব্য নেই। 2.31",

    "হে পার্থ ! স্বতঃপ্রাপ্ত, উন্মুক্ত স্বর্গদ্বার সদৃশ এই প্রকার ধর্মযুদ্ধ ভাগ্যবান ক্ষত্রিয়গণই প্রাপ্ত হন। 2.32",

    "কিন্তু যদি তুমি এই ধর্মযুদ্ধ না করো তা হলে স্বধর্ম ও কীর্তি হতে চ্যুত হয়ে পাপভাগী হবে। 2.33",

    "এবং সকলেই তোমার এই দীর্ঘকালস্থায়ী অকীর্তি (অখ্যাতি) নিয়ে আলোচনা করবে। মাননীয় ব্যক্তির পক্ষে এই অকীর্তি মৃত্যু অপেক্ষাও বেশি যন্ত্রণাদায়ক। 2.34",

    "আর যাঁদের দৃষ্টিতে তুমি খুবই সম্মানিত ছিলে তাঁদের চোখে হেয় হয়ে যাবে। এই মহারথিগণ মনে করবেন তুমি ভয়বশতঃ যুদ্ধে বিরত হয়েছ। 2.35",

    "তোমার শত্রুরা তোমার সামর্থ্যের নিন্দা করে অনেক অকথ্য কথাও বলবে, এর থেকে বেশি দুঃখজনক আর কী হতে পারে ? 2.36",

    "যদি তুমি যুদ্ধে নিহত হও, তা হলে স্বর্গ লাভ করবে আর যদি জয়লাভ করো তাহলে পৃথিবীর রাজত্ব ভোগ করবে। তাই হে অর্জুন ! তুমি যুদ্ধের জন্য দৃঢ়নিশ্চয় হয়ে উত্থিত হও। 2.37",

    "জয়-পরাজয়, লাভ-ক্ষতি, সুখ-দুঃখকে সমান মনে করে যুদ্ধের জন্য প্রস্তুত হও ; এইভাবে যুদ্ধ করলে তুমি পাপগ্রস্ত হবে না। 2.38",

    "হে পার্থ ! তোমার জন্য এই তত্ত্ব (সমত্ব বুদ্ধি) জ্ঞানযোগের বিষয়ে বলা হল, এখন তুমি কর্মযোগের কথা শোন—এই বুদ্ধি দ্বারা যুক্ত হলে তুমি অনায়াসে কর্মবন্ধন হতে মুক্ত হবে। 2.39",

    "নিষ্কাম কর্মযোগে আরম্ভের বিফলতা হয় না এবং বিপরীত ফলরূপ দোষও হয় না, উপরন্তু এই নিষ্কাম কর্মযোগরূপ ধর্মের স্বল্প অনুষ্ঠানও জন্ম-মৃত্যুরূপ মহাভয় হতে রক্ষা করে। 2.40",

    "হে কুরুনন্দন ! এই নিষ্কাম কর্মযোগে নিশ্চয়াত্মিকা বুদ্ধি একনিষ্ঠ হয়। কিন্তু অস্থির চিত্ত সকাম ব্যক্তিদের বুদ্ধি বহু শাখাবিশিষ্ট ও বহুমুখী হয়ে থাকে। 2.41",

    "হে পার্থ ! যারা ভোগে আসক্তচিত্ত, কর্মফল প্রশংসাকারী বেদবাক্যেই যাদের চিত্ত আকৃষ্ট, যাদের বুদ্ধিতে স্বর্গই পরমপ্রাপ্য বস্তু এবং যারা বলে থাকেন যে, স্বর্গ হতে বড় আর কিছুই নেই–এইরূপ অবিবেকিগণ যে পুষ্পিত অর্থাৎ আপাত-মনোহর বাক্য বলেন, যা জন্মরূপ কর্মফল প্রদান করে এবং ভোগ তথা ঐশ্বর্য প্রাপ্তির জন্য নানা ক্রিয়ার বর্ণনা করে, সেইসকল বাক্য দ্বারা যাদের চিত্ত অপহৃত হয়ে ভোগ ও ঐশ্বর্যের প্রতি আসক্ত হয়, তাদের পরমাত্মাতে নিশ্চয়াত্মিকা শুদ্ধা বুদ্ধি হতে পারে না। 2.42-2.44",

    "হে পার্থ ! যারা ভোগে আসক্তচিত্ত, কর্মফল প্রশংসাকারী বেদবাক্যেই যাদের চিত্ত আকৃষ্ট, যাদের বুদ্ধিতে স্বর্গই পরমপ্রাপ্য বস্তু এবং যারা বলে থাকেন যে, স্বর্গ হতে বড় আর কিছুই নেই–এইরূপ অবিবেকিগণ যে পুষ্পিত অর্থাৎ আপাত-মনোহর বাক্য বলেন, যা জন্মরূপ কর্মফল প্রদান করে এবং ভোগ তথা ঐশ্বর্য প্রাপ্তির জন্য নানা ক্রিয়ার বর্ণনা করে, সেইসকল বাক্য দ্বারা যাদের চিত্ত অপহৃত হয়ে ভোগ ও ঐশ্বর্যের প্রতি আসক্ত হয়, তাদের পরমাত্মাতে নিশ্চয়াত্মিকা শুদ্ধা বুদ্ধি হতে পারে না। 2.42-2.44",

    "হে পার্থ ! যারা ভোগে আসক্তচিত্ত, কর্মফল প্রশংসাকারী বেদবাক্যেই যাদের চিত্ত আকৃষ্ট, যাদের বুদ্ধিতে স্বর্গই পরমপ্রাপ্য বস্তু এবং যারা বলে থাকেন যে, স্বর্গ হতে বড় আর কিছুই নেই–এইরূপ অবিবেকিগণ যে পুষ্পিত অর্থাৎ আপাত-মনোহর বাক্য বলেন, যা জন্মরূপ কর্মফল প্রদান করে এবং ভোগ তথা ঐশ্বর্য প্রাপ্তির জন্য নানা ক্রিয়ার বর্ণনা করে, সেইসকল বাক্য দ্বারা যাদের চিত্ত অপহৃত হয়ে ভোগ ও ঐশ্বর্যের প্রতি আসক্ত হয়, তাদের পরমাত্মাতে নিশ্চয়াত্মিকা শুদ্ধা বুদ্ধি হতে পারে না। 2.42-2.44",

    "হে অর্জুন! বেদ পূর্বোক্ত ভাবে ত্রিগুণের কার্যরূপ সমস্ত ভোগ এবং তারই সাধনের প্রতিপাদক : সুতরাং তুমি ঐসব ভোগ এবং তার সাধনে আসক্তি বর্জিত হও, হর্ষ শোকাদি দ্বন্দ্ববহিত ও নিত্যবস্তুতে (পরমাত্মাতে) স্থিত হও এবং যোগ(১)-ক্ষেমের(২) আকাঙ্ক্ষাহীন তথা আত্মপরায়ন হও। 2.45",

    "সর্বত্র পরিপূর্ণ জলাশয় প্রাপ্ত হলে ক্ষুদ্র জলাশয়ে মানুষের যেটুকু প্রয়োজন থাকে, ব্রহ্মতত্ত্বজ্ঞানী ব্রাহ্মণের বেদে ততটাই প্রয়োজন থাকে 2.46",

    "কর্মেই তোমার অধিকার, ফলে নয়। অতএব তুমি কর্ম-ফলের হেতু হয়ো না আবার কর্মত্যাগেও যেন তোমার প্রবৃত্তি না হয়। 2.47",

    "হে ধনঞ্জয় ! তুমি আসক্তি ত্যাগ করে এবং সিদ্ধি অসিদ্ধিতে সমভাবাপন্ন থেকে যোগস্থ হয়ে সকল কর্ম করো। এই সমত্ব(১)-কেই যোগ বলা হয়। 2.48",

    "এই সমত্বরূপ বুদ্ধিযোগ অপেক্ষা সকাম-কর্ম নিতান্তই নিকৃষ্ট। তাই, হে ধনঞ্জয় ! তুমি সমত্ববুদ্ধি যোগের আশ্রয় নাও, কারণ যারা ফলের হেতু হয় অর্থাৎ ফলাকাঙ্ক্ষাবশতঃ কর্ম করে, তারা অত্যন্ত দীন। 2.49",

    "সমত্ববুদ্ধিযুক্ত পুরুষ ইহলোকেই পাপ এবং পুণ্য দুই-ই পরিত্যাগ করেন অর্থাৎ এগুলি হতে মুক্তিলাভ করেন। তাই তুমি সমত্বরূপ যোগের আশ্রয় নাও ; এই সমত্বরূপ যোগই হল কর্মের কৌশল অর্থাৎ কর্ম বন্ধন হতে মুক্ত হবার উপায়। 2.50",

    "কারণ সমবুদ্ধিসম্পন্ন জ্ঞানীগণ কর্মজনিত ফল ত্যাগ করে জন্মরূপ বন্ধন হতে মুক্ত হয়ে নির্বিকার পরমপদ লাভ করেন। 2.51",

    "যখন তোমার বুদ্ধি মোহরূপ কর্দম সম্পূর্ণরূপে অতিক্রম করবে, তখন তুমি শ্রুত এবং শ্রোতব্য ইহলোক এবং পরলোক সম্পর্কীয় সমস্ত বিষয় ভোগে বৈরাগ্য প্রাপ্ত হবে। 2.52",

    "নানা কথার দ্বারা বিক্ষিপ্ত তোমার বুদ্ধি যখন পরমাত্মায় অটল ও স্থির হবে , তখন তুমি যোগপ্রাপ্ত হবে অর্থাৎ পরমাত্মার সঙ্গে তোমার নিত্য সংযোগ স্থাপিত হবে। 2.53",

    "অর্জুন বললেন, হে কেশব ! সমাধিতে স্থিত পরমাত্মাকে প্রাপ্ত স্থিরবুদ্ধি ব্যক্তির লক্ষণ কী ? স্থিতধী ব্যক্তি কীভাবে কথা বলেন ? কীরূপে অবস্থান করেন ? কীভাবে চলেন ? 2.54",

    "ভগবান শ্রীকৃষ্ণ বললেন-হে অর্জুন ! যখন এই ব্যক্তি মন থেকে সমস্ত কামনা সম্পূর্ণভাবে পরিত্যাগ করেন এবং আত্মা দ্বারা আত্মাতেই সন্তুষ্ট থাকেন, তখন তাঁকে স্থিতপ্রজ্ঞ বলা হয়। 2.55",

    "দুঃখে অনুদ্বিগ্ন চিত্ত, সুখে স্পৃহাহীন এবং আসক্তি, ভয় এবং ক্রোধ রহিত মুনিকেই স্থিতপ্রজ্ঞ বলা হয়। 2.56",

    "যিনি সকল বস্তু ও ব্যক্তিতে আসক্তিরহিত এবং শুভ ও অশুভ বস্তুর প্রাপ্তিতে প্রসন্ন হন না বা দ্বেষ করেন না, তিনিই স্থিতপ্রজ্ঞ। 2.57",

    "কচ্ছপ যেমন আপন অঙ্গসমূহ সংহরণ করে নেয়, সেইরূপ যিনি ইন্দ্রিয়াদির বিষয় হতে ইন্দ্রিয়দের সর্বপ্রকারে সংহরণ করেন, তাঁকেই স্থিতপ্রজ্ঞ বলে জানবে। 2.58",

    "ইন্দ্রিয়াদির দ্বারা বিষয় উপভোগে অপ্রবৃত্ত ব্যক্তির বিষয়ভোগ নিবৃত্ত হলেও ইন্দ্রিয়াদির বিষয়াসক্তি নিবৃত্ত হয় না। কিন্তু স্থিতপ্রজ্ঞ ব্যক্তির আসক্তি পরমাত্মার সাক্ষাৎ লাভে সর্বতোভাবে দূর হয়। 2.59",

    "হে অর্জুন! আসক্তি সর্বতোভাবে দূর না হলে চিত্ত আলোড়নকারী ইন্দ্রিয়সকল যত্নশীল বুদ্ধিমান ব্যক্তির মনকেও বলপূর্বক হরণ করে। 2.60",

    "অতএব যোগী ইন্দ্রিয়গুলিকে সংযত করে সমাহিত চিত্তে মৎপরায়ণ হয়ে অবস্থান করবেন, কারণ যাঁর ইন্দ্রিয় বশীভূত, তাঁরই বুদ্ধি স্থির হয়। 2.61",

    "বিষয়চিস্তা করতে করতে মানুষের ঐ বিষয়ে আসক্তি জন্মায়, আসক্তি হতে কামনা উৎপন্ন হয় এবং কামনায় বাধা পড়লে ক্রোধের জন্ম হয়। 2.62",

    "ক্রোধ হতে মুঢ়ভাব উৎপন্ন হয়, মূঢ়ভাব হতে স্মৃতিভ্রংশ হয়, স্মৃতিভ্রংশে বুদ্ধি নাশ হয় এবং বুদ্ধি নাশ হলে পতন। 2.63",

    "কিন্তু যিনি অন্তঃকরণকে বশীভূত করেছেন, তিনি অনুরাগ ও বিদ্বেষবর্জিত বশীভূত ইন্দ্রিয়াদি সহযোগে বিষয়সমূহে বিচরণ করেও অন্তঃকরণের প্রসন্নতা লাভ করেন। 2.64",

    "অন্তঃকরণের প্রসন্নতার ফলে তাঁর সমস্ত দুঃখ নাশ হয় এবং সেই প্রসন্নচিত্ত কর্মযোগীর বুদ্ধি অচিরে সর্বদিক হতে নিবৃত্ত হয়ে পরমাত্মাতে স্থির হয়। 2.65",

    "যার মন এবং ইন্দ্রিয় নিজের বশে নেই, তার নিশ্চয়াত্মিকা বুদ্ধি হয় না এবং সেই অযুক্ত ব্যক্তির অন্তঃকরণে ভগবৎ চিন্তা জাগে না। আত্মচিন্তা বর্জিত মানুষের শান্তি অসম্ভব আর শান্তিরহিত মানুষের সুখ কোথায় ? 2.66",

    "কারণ জলের মধ্যে বিচরণশীল নৌকাকে যেমন বায়ু বিচলিত করে, তেমনি বিষয়ভোগে বিচরণকারী ইন্দ্রিয় সমূহের মধ্যে মন যেটিতে আকৃষ্ট হয় সেই ইন্দ্রিয়টিই অযুক্ত পুরুষের বুদ্ধি হরণ করে। 2.67",

    "সেইজন্য হে মহাবাহো ! যাঁর ইন্দ্রিয়গুলি ইন্দ্রিয়াদির বিষয় হতে সর্বপ্রকারে নিবৃত্ত হয়েছে, তাঁরই প্রজ্ঞা স্থির হয়েছে বলে জানবে। 2.68",

    "সমস্ত প্রাণীর পক্ষে যা রাত্রির সমান, নিত্য জ্ঞানস্বরূপ পরমানন্দে স্থিতপ্রজ্ঞ যোগী তাতে জাগ্রত থাকেন এবং বিনাশশীল জাগতিক সুখ প্রাপ্তির আশায় সমস্ত প্রাণী যাতে জাগরিত থাকে, পরমাত্ম-তত্ত্বজ্ঞানী মুনির কাছে তা রাত্রির সমান। 2.69",

    "যেমন বিভিন্ন নদীর জল পরিপূর্ণ অচল, স্থির সমুদ্রে এসে তাকে বিচলিত না করেই বিলীন হয়ে যায় তেমনই সমস্ত বিষয়ভোগও যাঁর মধ্যে কোনো বিকার উৎপন্ন না করে বিলীন হয়, তিনিই পরমশান্তি লাভ করেন, কিন্তু যিনি ভোগ্যপদার্থ কামনা করেন, তাঁর পক্ষে শান্তিলাভ অসম্ভব। 2.70",

    "যিনি সমস্ত কামনা পরিত্যাগ করে মমত্বশূন্য ও অহং বর্জিত এবং নিস্পৃহ হয়ে বিচরণ করেন, তিনিই পরম শান্তি লাভ করেন অর্থাৎ তাঁর ঈশ্বর লাভ হয়েছে। 2.71",

    "হে অর্জুন! এ হল ব্রহ্মপ্রাপ্ত পুরুষের স্থিতি, এই স্থিতি লাভের পর তিনি আর কখনও মোহগ্রস্ত হন না। অন্তিম সময়েও যিনি এই ব্রাহ্মীকৃিতি লাভ করেন তিনি ব্রহ্মানন্দ  লাভ করেন। 2.72",

    "এইরূপ ওঁ তৎ সৎ - এই ভগবানের নামগুলি উচ্চারণপূর্বক ব্রহ্মবিদ্যা এবং য়োগশাস্ত্রময় শ্রীমদ্ভবদ্গীতোপনিষদরূপ শ্রীকৃষ্ণার্জুনসংবাদে 'সাংখ্যয়োগ' নামক দ্বিতীয় অধ্যায় সমাপ্ত হলো।"
  ],
  "adhyaya-3" : [
    "অর্জুন বললেন, হে জনার্দন : যদি আপনি কর্ম থেকে বুদ্ধিকে (জ্ঞান) শ্রেষ্ঠ বলে মনে করেন, তাহলে হ কেশব! আমাকে এই ঘোর কর্মে কেন নিযুক্ত করছেন ?  3.1",
    
    "আপনি আপনার এই বিমিশ্র বচন দ্বারা কেন আমার বুদ্ধিকে মোহিত করছেন ? সুতরাং আপনি নিশ্চিত করে আমায় এমন কথা বলুন যার দ্বারা আমি কল্যাণ লাভ করতে সক্ষম হই 3.2",

    "শ্রীভগবান বললেন–হে অনঘ অর্জুন! এই মনুষ্যলোকে দুই প্রকারের নিষ্ঠা আছে, একথা আমি আগেই বলেছি। সেগুলি হল জ্ঞানীদের নিষ্ঠা জ্ঞানযোগের দ্বারা এবং যোগীদের নিষ্ঠা কর্মযোগের দ্বারা ঘটে 3.3",

    "মানুষ কর্মচেষ্টা না করলেই যে নৈষ্কর্মপ্রাপ্ত হয় তা নয়, আর কর্মত্যাগ করলেই যে সিদ্ধি লাভ হয় তাও নয় 3.4",

    "কোনো ব্যক্তিই কোনো অবস্থায় ক্ষণকালও কর্ম না করে থাকতে পারে না, কেননা প্রকৃতিজাত গুণে বর্ণীভূত প্রাণী কর্ম করতে বাধ্য হয় 3.5",

    "যে কর্মেন্দ্রিয়গুলিকে (সব ইন্দ্রিয়গুলিকে) হঠতাপূর্বক রুদ্ধ করে মন দ্বারা ইন্দ্রিয়গুলির বিষয় চিন্তা করতে থাকে, সেই মূঢ়মতি ব্যক্তিকে মিথ্যাচারী (মিথ্যা আচরণকারী) বলা হয়৷ 3.6",

    "হে অর্জুন! যে ব্যক্তি মনের দ্বারা ইন্দ্রিয়গুলি সংযত করে অনাসক্ত হয়ে (নিষ্কামভাবে) কর্মেন্দ্রিয়ের সাহায্যে কর্মযোগ অনুষ্ঠান করেন, তিনিই শ্ৰেষ্ঠ 3.7",

    "তুমি শাস্ত্রবিধিসম্মত (নির্দিষ্ট) কর্ম করো, কারণ কর্ম না করার থেকে কর্ম করা শ্রেষ্ঠ, কর্ম না করলে তোমার শরীর নির্বাহও হতে পারে না 3.8",

    "যজ্ঞের নিমিত্ত ভিন্ন অন্য কর্ম বন্ধনের কারণ হয়। অতএব হে কৌন্তেয় ! তুমি আসক্তিশূন্য হয়ে শুধুমাত্র যজ্ঞার্থে সকল কর্ম কর। 3.9",

    "প্রজাপতি ব্রহ্মা সৃষ্টির আরম্ভে কর্তব্যকর্মের বিধানসভা প্রজা (মানুষ প্রমুখ ) সৃষ্টি করে তাদের (প্রধানত মানুষকে ) বলেছিলেন যে, তোমার এই কর্তব্য ( যজ্ঞ) দ্বারা  সকলের সমৃদ্ধ করো এবং এই কর্তব্য রূপ যজ্ঞ তোমাদের কর্তব্য পালনের অভিষ্ঠ সামগ্রী প্রদানকারী হোক । 3.10",

    "এই কর্তব্যকর্ম (যজ্ঞ) দ্বারা তোমরা  দেবতাগনের সংবর্ধন করো এবং দেবতা গণ তাদের কর্তব্য দ্বারা তোমাদের  মানোন্নয়ন (সংবর্ধনা) করুন। এইভাবে পরস্পরের সংবর্ধনার দ্বারা তোমরা পরম কল্যাণপ্রাপ্ত হবে। 3.11",

    "যজ্ঞ দ্বারা পুষ্ট (সংবর্ধিত) দেবগন তোমাদের (বিনা প্রার্থনাতেই) কর্তব্যকর্মের জন্য আবশ্যক সামগ্রী প্রদান করে যাবেন। দেবতা প্রদত্ত এই সামগ্রী অন্যের সেবায় ব্যয় না করে যে ব্যক্তি  স্বয়ং ভোগ করে, সে অবশ্যই চোর। 3.12",

    "যজ্ঞাবশেষ (যোগ) অনুভবকারী শ্রেষ্ঠ ব্যক্তিগণ সর্বপাপ থেকে মুক্ত হন। কিন্তু যারা সমস্ত কর্ম কেবল নিজের জন্যই করে সেইসকল পাপী ব্যক্তি শুধু পাপরাশিই ভক্ষণ করে থাকে ৷ 3.13",

    "সমস্ত প্রাণী অন্ন থেকে উৎপন্ন হয়, অন্ন উৎপন্ন হয় মেঘ (জল) থেকে, মেঘ জন্মায় যজ্ঞ থেকে, যজ্ঞ নিষ্পন্ন হয় কর্ম থেকে।  3.14",

    "বেদ থেকে কর্ম উৎপন্ন হয় এবং বেদ পরব্রহ্ম থেকে প্রকটিত বলে জানবে। সেইহেতু এই সর্বব্যাপী পরমাত্মা যজ্ঞে (কর্তব্যকর্মে) নিত্য প্রতিষ্ঠিত ৷ 3.15",

    "হে পার্থ : যে ব্যক্তি ইহলোকে এইপ্রকার পরম্পরা দ্বারা প্রচলিত সৃষ্টিচক্র অনুযায়ী চলে না, ইন্দ্রিয়সুখাসক্ত পাপাচারী সেই ব্যক্তি বৃথাই এই জগতে জীবনধারণ করে থাকে ৷ 3.16",

    "কিন্তু যে ব্যক্তি নিজেতেই প্রীত, নিজেতেই তৃপ্ত এবং নিজেতেই সন্তুষ্ট, তাঁর নিজের জন্য কোনো কর্তব্য থাকে না। 3.17",

    "সেই (কর্মযোগে সিদ্ধ) মহাপুরুষের এই জগতে কর্মানুষ্ঠানের কোনো প্রয়োজন নেই বা কর্ম থেকে বিরত থাকারও কোনো প্রয়োজন নেই এবং প্রাণীগণের সঙ্গেও তাঁর কোনো প্রকার স্বার্থের সম্পর্ক থাকে না ৷ 3.18",

    "অতএব তুমি সর্বদা আসক্তিশূন্য হয়ে যথাযথভাবে কর্তব্যকর্ম পালন করো। কারণ অনাসক্ত হয়ে কর্ম করলে মানুষ পরমাত্মাকে (মোক্ষ) লাভ করে ৷ 3.19",

    "রাজা জনকের মতো মহাত্মাগণ কর্ম দ্বারাই পরম সিদ্ধি লাভ করেছেন। তাই লোকসংগ্রহের দিকে দৃষ্টি রেখে তোমারও নিষ্কামভাবে কর্ম করা উচিত ৷ 3.20",

    "শ্রেষ্ঠ ব্যক্তি যা যা আচরণ করেন, অন্যান্য সাধারণ ব্যক্তিগণও তাই করে থাকে। তিনি যা কিছু প্রামাণ্য বলে ধরেন, সাধারণ মানুষেরা সেই অনুযায়ী আচরণ করে থাকে৷ 3.21",

    "হে পার্থ ! ত্রিলোকে আমার কোনো কর্তব্য নেই এবং প্রাপ্ত করার যোগ্য কোনো বস্তুই অপ্রাপ্ত নেই, তবুও আমি কর্তব্যকর্মেই ব্যাপৃত আছি ৷ 3.22",

    "হে পার্থ ! যদি আমি সাবধানতাপূর্বক কর্তব্যকর্ম না করি (তাহলে অত্যন্ত ক্ষতি হবে ; কারণ) সাধারণ মানুষ সর্বপ্রকারে আমার পথই অনুসরণ করবে। 3.23",

    "যদি আমি কর্ম না করি, তাহলে এইসকল লোক পথভ্রষ্ট হবে এবং আমি বর্ণসংকরাদির হেতু এবং এই সমস্ত প্রজাদের বিনাশের কারণ হব ।২৪ ।। 3.24",

    "হে ভরতবংশোদ্ভূর অর্জুন ! কর্মে আসক্ত অজ্ঞ ব্যক্তিরা যেরূপ কর্ম করেন, আসক্তি বর্জিত জ্ঞানী ব্যক্তিগণেরও লোকসংগ্রহার্থে সেরূপ কর্ম করা উচিত। 3.25",

    "তত্ত্বজ্ঞ মহাপুরুষগণ সতর্ক থেকে কর্মে আসক্ত অজ্ঞ ব্যক্তিদের বুদ্ধিতে ভ্রম উৎপন্ন করবেন না, বরং তাঁরা নিজেরা সমস্ত কর্ম ভালোভাবে অবহিত হয়ে অজ্ঞ ব্যক্তিদেরও সেইরূপে কর্মে নিযুক্ত করে রাখবেন ৷ 3.26",

    "সকল কর্ম সর্বতোভাবে প্রকৃতির গুণগুলির দ্বারা সম্পন্ন হয়; কিন্তু অহংকারে মোহান্ধ অজ্ঞ ব্যক্তি মনে করে ‘আমিই কর্তা' 3.27",

    "হে মহাবাহো ! যে মহাপুরুষ গুণবিভাগ এবং কর্মবিভাগকে তত্ত্বত জেনেছেন, তিনি ‘সমস্ত গুণই গুণগুলিতে আবর্তিত হয়—এইরূপ মেনে নিয়ে সেগুলিতে আসক্ত হন না ৷ 3.28",

    "প্রকৃতিজনিত গুণে মোহিত অজ্ঞ ব্যক্তিগণ গুণ এবং কর্মে আসক্ত থাকে। এই অল্পবুদ্ধি অজ্ঞ ব্যক্তিগণকে যথার্থ জ্ঞানী মহাপুরুষের কর্ম থেকে বিচালিত করা উচিত নয় ৷ 3.29",

    "তুমি বিবেক-বুদ্ধি সহকারে সমস্ত কর্তব্যকর্ম আমাতে অর্পণ করে কামনা, মমতা এবং সন্তাপ পরিত্যাগ করে যুদ্ধরূপ কর্তব্যকর্ম করো ৷ 3.30",

    "যে সকল মানুষ দোষদৃষ্টিরহিত হয়ে শ্রদ্ধা সহকারে আমার এই মতের (পূর্বশ্লোকে বর্ণিত) সর্বদা অনুসরণ করেন, তাঁরাও সমস্ত কর্মবন্ধন হতে মুক্ত হয়ে যান  3.31",

    "যে ব্যক্তি দোষদৃষ্টিবশত আমার এই মত পালন না করে, সেই সর্বজ্ঞানবিমূঢ়, বিবেকহীন ব্যক্তিকে বিনষ্ট বলেই জেনো অর্থাৎ তাদের পতন হয় ৷ 3.32",

    "সমস্ত প্রাণী প্রকৃতিকে অনুসরণ করে, জ্ঞানী ব্যক্তিও নিজ প্রকৃতি অনুযায়ী কর্ম সম্পাদনের জন্য চেষ্টা করেন। তাহলে নিগ্রহের জন্য জেদ করে কী হবে?  3.33",

    "ইন্দ্রিয়-ইন্দ্রিয়ে’ অর্থাৎ প্রত্যেক ইন্দ্রিয়েরই নিজ নিজ বিষয়ে মানুষের রাগ এবং দ্বেষ (অনুকূল ও প্রতিকূল) ব্যাপারে স্থিতি হয়। মানুষের ওইগুলির বশীভূত হওয়া উচিত নয় ; কারণ এই দুটিই জীবের (পারমার্থিক পথে বিঘ্ন প্রদানকারী) শত্রু  3.34",

    "উত্তমরূপে অনুষ্ঠিত পরধর্ম অপেক্ষা স্বল্পগুণবিশিষ্ট নিজ ধর্ম শ্রেষ্ঠ। স্বধর্মে মৃত্যুও কল্যাণকারী, কিন্তু পর ধর্ম ভয়প্রদানকারী, বিপজ্জনক  3.35",

    "অর্জুন বললেন—হে বার্ষ্ণেয় ! তাহলে মানুষ অনিচ্ছা সত্ত্বেও কার দ্বারা বলপূর্বক পাপাচরণ করার জন্য প্রেরিত হয়ে থাকে ? 3.36",

    "শ্রীভগবান বললেন — রজোগুণ হতে উৎপন্ন এই কাম অর্থাৎ কামনাই হল পাপের কারণ। এই কামনাই ক্রোধে পরিণত হয়। এটি দুষ্পুরণীয় অর্থাৎ কিছুতেই তৃপ্ত হয় না এবং মহাপাপী। এই বিষয়ে তুমি একেই শত্রু বলে জানবে । 3.37",

    "যেমন ধূম দ্বারা বহ্নি, ময়লা দ্বারা দর্পণ এবং জরায়ু দ্বারা গর্ভ আবৃত থাকে, সেইরূপ কামের দ্বারা জ্ঞান অর্থাৎ বিবেক আবৃত থাকে ৷ 3.38",

    "হে কৌন্তেয় ! বিবেকশীল পুরুষের চিরশত্রু অগ্নির ন্যায় দুষ্পুরণীয় এই কাম অর্থাৎ কামনার দ্বারা মানুষের বিবেক-বুদ্ধি (জ্ঞান) আচ্ছন্ন থাকে ৷ 3.39",

    "ইন্দ্রিয়াদি, মন ও বুদ্ধি—এগুলিকে কামনার আশ্রয় স্থান বলা হয়েছে। কামনা এগুলিকে (ইন্দ্রিয়াদি, মন, বুদ্ধি) অবলম্বনপূর্বক জ্ঞানকে আবৃত করে দেহাভিমানী মানুষকে মোহগ্রস্ত করে ৷ 3.40",

    "সেইহেতু, হে ভরতশ্রেষ্ঠ অর্জুন! তুমি সর্বাগ্রে ইন্দ্রিয়গুলিকে বশীভূত করে, জ্ঞান-বিজ্ঞানবিনাশী ঘোর পাপস্বরূপ কামকে (কামনাকে) সবলে বিনাশ করো ৷ 3.41",

    "ইন্দ্রিয়গুলিকে ( স্থূল শরীর অপেক্ষা) শ্রেষ্ঠ (সবল, প্রকাশক, ব্যাপক এবং সূক্ষ্ম) বলা হয় ; ইন্দ্রিয়গুলি অপেক্ষা শ্রেয়তর হল মন, মনের থেকে শ্রেয়তর বুদ্ধি, বুদ্ধির থেকেও যা প্রবল তা হল কাম।  3.42",

    "এইভাবে বুদ্ধির চেয়েও পর অর্থাৎ কামকে জেনে নিজের দ্বারা নিজেকে (আত্মশক্তির দ্বারা) বশীভূত করে, হে মহাবাহো ! তুমি এই কামরূপ দুর্জয় শত্রুকে নাশ করো ৷৷৪৩। 3.43",

    "এইরূপ ওঁ তৎ সৎ - এই ভগবানের নামগুলি উচ্চারণপূর্বক ব্রহ্মবিদ্যা এবং য়োগশাস্ত্রময় শ্রীমদ্ভবদ্গীতোপনিষদরূপ শ্রীকৃষ্ণার্জুনসংবাদে 'কর্ময়োগ' নামক তৃতীয় অধ্যায় সমাপ্ত হলো।"
  ],
  "adhyaya-4" : [
    "ভগবান শ্রীকৃষ্ণ বললেন—এই অবিনাশী যোগ আমি সূর্যকে বলেছিলাম ; সূর্য তাঁর পুত্র বৈবস্বত মনুকে, মনু তাঁর পুত্র রাজা ইক্ষ্বাকুকে এটি বলেছিলেন। 4.1",

    "হে পরন্তপ অর্জুন ! এইভাবে পরম্পরাক্রমে প্রাপ্ত এই যোগ রাজর্ষিগণ জেনেছিলেন ; কিন্তু তার পরে এই যোগ দীর্ঘকালের ব্যবধানে পৃথ্বীলোক হতে প্রায় বিনষ্ট হয়েছে। 4.2",

    "তুমি আমার ভক্ত ও প্রিয় সখা, সেইজন্য এই পুরাতন যোগ আজ আমি তোমাকে বললাম ; কারণ এটি অতি উত্তম রহস্য অর্থাৎ গোপনীয় বিষয়। 4.3",

    "অর্জুন বললেন-আপনার জন্ম তো এখন অর্থাৎ এই যুগে হয়েছে আর সূর্যের জন্ম তো বহু পূর্বে অর্থাৎ কল্পের আদিতে হয়েছে। তবে আমি কী করে বুঝব যে আপনিই কল্পের আদিতে এই যোগের কথা সূর্যকে বলেছিলেন ? 4.4",

    "ভগবান শ্রীকৃষ্ণ বললেন –হে পরন্তপ অর্জুন ! আমার এবং তোমার বহু জন্ম অতীত হয়েছে; সে সব তুমি জানো না, কিন্তু আমি জানি। 4.5",

    "আমি জন্মরহিত, অবিনাশীস্বরূপ এবং সর্বভূতের ঈশ্বর হওয়া সত্ত্বেও নিজ প্রকৃতিকে অধীন করে স্বীয় যোগমায়া দ্বারা প্রকটিত হই। 4.6",

    "হে ভারত ! যখনই ধর্মের হানি এবং অধর্মের বৃদ্ধি হয়, তখনই আমি নিজেকে সৃষ্টি করি অর্থাৎ সাকাররূপে জনসমক্ষে প্রকট হই। 4.7",

    "সাধুদের রক্ষার জন্য, পাপীদের বিনাশের জন্য এবং ধর্ম সংস্থাপনের জন্য আমি যুগে যুগে অবতীর্ণ হই। 4.8",

    "হে অর্জুন ! আমার জন্ম ও কর্ম দিব্য অর্থাৎ নির্মল ও অলৌকিক—এইভাবে যে ব্যক্তি আমাকে তত্ত্বতঃ জানেন, তিনি দেহত্যাগ করে পুনরায় জন্মগ্রহণ করেন না অর্থাৎ তিনি আমাকেই লাভ করেন। 4.9",

    "যাঁদের আসক্তি, ভয় ও ক্রোধ সর্বতোভাবে নষ্ট হয়েছে, যাঁরা আমার প্রেমে একাগ্রচিত্ত এবং আমার শরণাপন্ন—এরূপ বহু ভক্ত জ্ঞানরূপ তপস্যা দ্বারা পবিত্র হয়ে আমার স্বরূপে স্থিতি লাভ করেছেন। 4.10",

    "হে অর্জুন ! যে ভক্ত আমাকে যেভাবে ভজনা করেন, আমিও তাঁকে সেইভাবেই ভজনা করি ; কারণ সকল মানুষই সর্বতোভাবে আমার পথেরই অনুসরণ করেন । 4.11",

    "এই মনুষ্যলোকে কর্মফলাকাঙ্ক্ষাযুক্ত মানুষ দেবতাগণের পূজা করেন, কারণ কর্মজনিত সিদ্ধি তাঁরা লাভ করেন। 4.12",

    "ব্রাহ্মণ, ক্ষত্রিয়, বৈশ্য এবং শূদ্র—এই বর্ণচতুষ্টয় গুণ এবং কর্মের বিভাগ অনুসারে আমি সৃষ্টি করেছি। সৃষ্টি-কর্মের কর্তা হলেও অবিনাশী, পরমেশ্বররূপ আমাকে তুমি প্রকৃতপক্ষে অকর্তা বলেই জানবে। 4.13",

    "কর্মফলে আমার স্পৃহা নেই, তাই কোনো কর্ম আমাকে আবদ্ধ করতে পারে না—এইরূপে যিনি আমাকে তত্ত্বতঃ জানেন, তিনিও কর্মের দ্বারা আবদ্ধ হন না। 4.14",

    "এইরূপ জেনেই পূর্বতন মুমুক্ষুগণও নিষ্কাম কর্ম করেছেন। এইজন্য তুমিও পূর্বসূরিগণের দ্বারা নিত্য আচরিত কর্মই পালন করো। 4.15",

    "কর্ম কী, অকর্ম কী ?-এর যাথার্থ্য নির্ণয় করতে বুদ্ধিমান ব্যক্তিরাও বিভ্রান্ত হন। সেইজন্য এই কর্মতত্ত্ব আমি তোমাকে ভালোভাবে বুঝিয়ে বলছি যাতে তুমি অশুভ হতে অর্থাৎ কর্মবন্ধন হতে মুক্ত হতে পারো । 4.16",

    "কর্ম, অকর্ম ও বিকর্মের স্বরূপ (তত্ত্ব) জানা উচিত, কারণ কর্মের গতি অত্যন্ত দুজ্ঞেয়। 4.17",

    "যে ব্যক্তি কর্মে অকর্ম এবং অকর্মে কর্ম দেখেন, মানুষের মধ্যে তিনিই জ্ঞানী ও যোগযুক্ত এবং সর্বকর্মকারী। 4.18",

    "যাঁর সমস্ত কর্মপ্রচেষ্টা, কামনা ও সংঙ্কল্পরহিত এবং যাঁর সমস্ত কর্ম জ্ঞানরূপ অগ্নিতে ভস্মীভূত হয়েছে, জ্ঞানিগণও তাঁকে পণ্ডিত বলে থাকেন। 4.19",

    "যিনি সকল কর্ম ও তার ফলে আসক্তি সর্বতোভাবে বৰ্জনপূর্বক সংসারের আশ্রয় ত্যাগ করেছেন এবং পরমাত্মাতে নিত্য তৃপ্তি লাভ করেছেন, তিনি কর্মসমূহে বিশেষভাবে প্রবৃত্ত থাকলেও প্রকৃতপক্ষে কিছুই করেন না। 4.20",

    "যাঁর অন্তঃকরণ এবং ইন্দ্রিয়াদিসহ শরীর স্ববশে আছে এবং যিনি সকল প্রকার ভোগ্যসামগ্রী ত্যাগ করেছেন, সেইরূপ আশারহিত ব্যক্তি শুধুমাত্র শরীর ধারণের উপযোগী কর্ম করলেও কোনোরূপ পাপের ভাগী হন না। 4.21",

    "যিনি কোনো ইচ্ছা না রেখে যা পান তাতেই তুষ্ট, ঈর্ষাশূন্য, হর্ষ-শোকাদি দ্বন্দ্ব হতে মুক্ত এবং সিদ্ধি ও অসিদ্ধিতে সমজ্ঞানসম্পন্ন,—তিনি শরীর ধারণের উপযোগী কর্ম করলেও তাতে বদ্ধ হন না। 4.22",

    "যিনি সর্বতোভাবে আসক্তি বর্জন করেছেন, দেহাভিমান (অহংবোধ) ও মমত্বরহিত হয়েছে এবং যাঁর চিত্ত নিরন্তর পরমাত্মতত্ত্বে স্থিত, কেবলমাত্র যজ্ঞ সম্পাদনের উদ্দেশ্যে যিনি কর্ম করেন তাঁর সমস্ত কর্ম বিলীন হয়ে যায় অর্থাৎ তা ফল প্রসব করে না। 4.23",

    "যে যজ্ঞে অর্পণ অর্থাৎ স্ক্রবাদিও (যার দ্বারা হবি অগ্নিতে প্রক্ষিপ্ত হয়) ব্রহ্ম এবং আহুত দ্রব্যাদিও ব্রহ্ম তথা ব্রহ্মরূপ যজ্ঞকর্তার দ্বারা ব্রহ্মরূপ অগ্নিতে আহুতি প্রদানরূপ ক্রিয়াও ব্রহ্ম—সেই ব্রহ্মকর্মে নিবিষ্টচিত্ত যোগীর প্রাপ্ত ফলও হল ব্রহ্ম। 4.24",

    "অন্যান্য যোগিগণ দেবপূজারূপ যজ্ঞের যথাযথ অনুষ্ঠান করেন এবং অন্য কোনো যোগী পরব্রহ্ম পরমাত্মারূপ অগ্নিতে অভেদদর্শনরূপ যজ্ঞের দ্বারা আত্মরূপ যজ্ঞের আহুতি প্রদান করেন। 4.25",

    "অন্য যোগিগণ শ্রোত্রাদি সমস্ত ইন্দ্রিয়কে সংযমরূপ অগ্নিতে আহুতি দেন এবং অপর যোগিগণ শব্দাদি সমস্ত বিষয়কে ইন্দ্রিয়রূপ অগ্নিতে আহুতি দেন । 4.26",

    "অন্য যোগিগণ সমস্ত ইন্দ্রিয়ের কর্ম এবং প্রাণের সকল কর্ম জ্ঞানের দ্বারা প্রকাশিত আত্মসংযমযোগরূপ অগ্নিতে আহুতি প্রদান করেন । 4.27",

    "কেউ কেউ দ্রব্যদানরূপ যজ্ঞ করেন, কেউ আবার তপস্যারূপ যজ্ঞ করেন, কেউ যোগরূপ যজ্ঞ করেন, আবার অহিংসাদি দৃঢ়ব্রতধারী যত্নশীল অনেক ব্যক্তি স্বাধ্যায়রূপ জ্ঞানযজ্ঞ করেন। 4.28",

    "আবার অন্যান্য যোগিগণ অপানবায়ুতে প্রাণবায়ু আহুতি দেন, সেইরূপ কেউ আবার প্রাণবায়ুতে অপানবায়ুর আহুতি দেন। আবার কোনো নিয়মিত আহারী(১) প্রাণায়ামপরায়ণ পুরুষ প্রাণ ও অপানের গতিরুদ্ধ করে প্রাণকে প্রাণে আহুতি দেন। এই সকল সাধকই হলেন যজ্ঞের দ্বারা পাপসমূহের বিনাশকারী এবং যজ্ঞসমূহের জ্ঞাতা। 4.29 - 4.30",

    "আবার অন্যান্য যোগিগণ অপানবায়ুতে প্রাণবায়ু আহুতি দেন, সেইরূপ কেউ আবার প্রাণবায়ুতে অপানবায়ুর আহুতি দেন। আবার কোনো নিয়মিত আহারী(১) প্রাণায়ামপরায়ণ পুরুষ প্রাণ ও অপানের গতিরুদ্ধ করে প্রাণকে প্রাণে আহুতি দেন। এই সকল সাধকই হলেন যজ্ঞের দ্বারা পাপসমূহের বিনাশকারী এবং যজ্ঞসমূহের জ্ঞাতা। 4.29 - 4.30",

    "হে কুরুশ্রেষ্ঠ অর্জুন ! যজ্ঞাবশেষ অমৃত অনুভবকারী যোগিগণ সনাতন পরব্রহ্ম পরমাত্মাকে লাভ করেন। আর যাঁরা যজ্ঞ করেন না তাঁদের ইহলোকও সুখদায়ক হয় না, পরলোক তো দূরের কথা ! 4.31",

    "এইরূপ আরও বহুপ্রকার যজ্ঞের কথা বেদে বিস্তারিতভাবে বলা হয়েছে। এ সবই মন, ইন্দ্রিয়াদি ও কায়িক ক্রিয়ার দ্বারা সম্পন্ন হয় বলে জানবে, এইরূপ তত্ত্বতঃ জেনে এগুলির অনুষ্ঠান করলে তুমি কর্মবন্ধন হতে মুক্ত হবে। 4.32",

    "হে পরন্তপ অর্জুন ! দ্রব্যময় যজ্ঞ হতে জ্ঞানযজ্ঞ শ্রেষ্ঠ, কারণ সমস্ত কর্ম জ্ঞানেই সমাপ্ত হয়। 4.33",

    "সেই জ্ঞান তুমি তত্ত্বদর্শী জ্ঞানীদের কাছে গিয়ে জেনে নাও ; তাঁদের বিনয়পূর্বক প্রণাম ও সেবা তথা কপটতা ত্যাগ করে সরলভাবে প্রশ্ন করলে সেই তত্ত্বদর্শী জ্ঞানিগণ তোমাকে তত্ত্বজ্ঞান সম্বন্ধে উপদেশ দেবেন। 4.34",

    "যা জানলে তুমি আর এইরূপ মোহগ্রস্ত হবে না এবং হে অর্জুন ! যে জ্ঞানের দ্বারা তুমি সমস্ত ভূতাদি নিরবশেষ প্রথমে নিজের মধ্যে এবং পরে সচ্চিদানন্দঘন পরমাত্মারূপী আমাতে দেখতে সক্ষম হবে। 4.35",

    "যদি তুমি সমস্ত পাপীর থেকেও অধিক পাপী হও তা হলেও তুমি জ্ঞানরূপ নৌকার সাহায্যে নিঃসন্দেহে সমগ্র পাপসাগর উত্তীর্ণ হয়ে যাবে। 4.36",

    "কারণ হে অর্জুন ! প্রজ্বলিত অগ্নি যেমন ইন্ধনকে ভস্মীভূত করে, জ্ঞানরূপ অগ্নিও তেমনই সমস্ত কর্মকে ভস্মীভূত করে। 4.37",

    "নিঃসন্দেহে এই জগতে জ্ঞানের মতো পবিত্রকারী আর কিছুই নেই। দীর্ঘকাল প্রযত্ন দ্বারা কর্মযোগে চিত্ত শুদ্ধ সাধক স্বয়ংই নিজের মধ্যে সেই ব্রহ্মজ্ঞান লাভ করেন। 4.38",

    "জিতেন্দ্রিয়, সাধনপরায়ণ, শ্রদ্ধাবান ব্যক্তি জ্ঞান লাভ করেন। জ্ঞান লাভ করে তিনি অচিরেই ভগবৎ প্রাপ্তিরূপ পরমশান্তি লাভ করেন। 4.39",

    "বিবেকরহিত ও শ্রদ্ধাহীন সংশয়াকুল ব্যক্তি পারমার্থিক পথ হতে অবশ্যই ভ্রষ্ট হয়। এইরূপ সংশয়াত্মার ইহলোকও নেই, পরলোকও নেই এবং সুখও নেই। 4.40",

    "হে ধনঞ্জয় ! যিনি কর্মযোগের দ্বারা সমস্ত কর্ম পরমাত্মায় অর্পণ করেছেন এবং বিবেকের দ্বারা সমস্ত সংশয় নাশ করেছেন এইরকম বশীভূত-চিত্ত ব্যক্তিকে কর্ম কখনও বদ্ধ করতে পারে না। 4.41",

    "অতএব হে ভরতবংশীয় অর্জুন ! তুমি হৃদয়স্থিত এই অজ্ঞানজাত সংশয়কে বিবেকজ্ঞানরূপ তরবারির সাহায্যে ছেদন করে সমত্বরূপ যোগে স্থিত হও এবং যুদ্ধের জন্য উত্থিত হও। 4.42",

    "এইরূপ ওঁ তৎ সৎ - এই ভগবানের নামগুলি উচ্চারণপূর্বক ব্রহ্মবিদ্যা এবং য়োগশাস্ত্রময় শ্রীমদ্ভবদ্গীতোপনিষদরূপ শ্রীকৃষ্ণার্জুনসংবাদে 'জ্ঞানকর্মসন্ন্যাসয়োগ' নামক চতুর্থ অধ্যায় সমাপ্ত হলো।"
  ],
  "adhyaya-5" : [
    "অর্জুন বললেন—হে কৃষ্ণ ! আপনি কর্মসন্ন্যাস এবং কর্মযোগ—উভয়েরই প্রশংসা করছেন। অতএব এই দুটির মধ্যে যেটি আমার পক্ষে নিশ্চিতরূপে কল্যাণকর, সেটি বলুন। 5.1",

    "ভগবান শ্রীকৃষ্ণ বললেন-কর্মসন্ন্যাস এবং কর্মযোগ উভয়ই কল্যাণকর ; কিন্তু কর্মসন্ন্যাস অপেক্ষা কর্মযোগ সুগম হওয়ায় শ্রেষ্ঠ। 5.2",

    "হে অর্জুন ! যিনি কারও প্রতি দ্বেষ করেন না এবং কোনও কিছু আকাঙ্ক্ষা করেন না, সেই নিষ্কাম কর্মযোগীকে নিত্য-সন্ন্যাসী বলে জানবে। কারণ রাগ (আসক্তি)-দ্বেষরূপ দ্বন্দ্ব-রহিত পুরুষ অনায়াসে সংসারবন্ধন হতে মুক্ত হন। 5.3",

    "ফল প্রদানকারী বলে থাকে, পণ্ডিতেরা তা বলেন না ; কারণ দুটির মধ্যে একটিতে সম্যকভাবে স্থিত পুরুষ উভয়েরই ফলস্বরূপ একই পরমাত্মতত্ত্ব প্রাপ্ত হন। 5.4",

    "জ্ঞানযোগী যে পরমধাম লাভ করেন, কর্মযোগীও সেই ধাম প্রাপ্ত হন। তাই যিনি জ্ঞানযোগ ও কর্মযোগকে ফলরূপে অভিন্ন দেখেন, তিনিই যথার্থদর্শী। 5.5",

    "কিন্তু হে অর্জুন ! নিষ্কাম কর্মযোগ ব্যতিরেকে সন্ন্যাস অর্থাৎ মন, ইন্দ্রিয় এবং শরীরের দ্বারা করা সমস্ত কর্মে কর্তৃত্বভাব ত্যাগ করা কঠিন কিন্তু ভগবৎ স্বরূপ মননকারী নিষ্কাম কর্মযোগী পরব্রহ্ম পরমাত্মাকে শীঘ্রই লাভ করেন। 5.6",

    "যাঁর মন বশীভূত, যিনি জিতেন্দ্রিয়, বিশুদ্ধচিত্ত সর্বপ্রাণীর আত্মারূপ পরমাত্মাই যাঁর আত্মস্বরূপ,এইরূপ নিষ্কাম কর্মযোগী কর্ম করলেও তাতে লিপ্ত হন না। 5.7",

    "তত্ত্বদর্শী সাংখ্যযোগী দর্শন, শ্রবণ, স্পর্শন, ঘ্রাণ, ভোজন, গমন, নিদ্রা, নিঃশ্বাসগ্রহণ, কথোপকথন, মলমূত্রাদি ত্যাগ, গ্রহণ, চক্ষুর উন্মেষ এবং নিমেষ ইত্যাদি কার্যে 'ইন্দ্রিয়গণ স্ব স্ব বিষয়সমূহে প্রবর্তিত এইরূপ বুঝে নিঃসন্দেহে মনে করেন যে আমি কিছুই করি না। 5.8 - 5.9",

    "তত্ত্বদর্শী সাংখ্যযোগী দর্শন, শ্রবণ, স্পর্শন, ঘ্রাণ, ভোজন, গমন, নিদ্রা, নিঃশ্বাসগ্রহণ, কথোপকথন, মলমূত্রাদি ত্যাগ, গ্রহণ, চক্ষুর উন্মেষ এবং নিমেষ ইত্যাদি কার্যে 'ইন্দ্রিয়গণ স্ব স্ব বিষয়সমূহে প্রবর্তিত এইরূপ বুঝে নিঃসন্দেহে মনে করেন যে আমি কিছুই করি না। 5.8 - 5.9",

    "যিনি সমস্ত কর্ম পরমাত্মায় অর্পণ করেন এবং আসক্তি ত্যাগ করে কর্ম করেন, তিনি জলে পদ্মপত্রের ন্যায় পাপে লিপ্ত হন না। 5.10",

    "নিষ্কাম কর্মযোগী ইন্দ্রিয়, মন, বুদ্ধি এবং শরীরের প্রতি মমত্ববুদ্ধি রহিত হয়ে আসক্তি ত্যাগ করে চিত্তশুদ্ধির নিমিত্ত কর্ম করেন। 5.11",

    "নিষ্কাম কর্মযোগী কর্মফল ত্যাগ করে অবিচল শান্তি (ভগবৎ প্রাপ্তি) লাভ করেন আর সকাম ব্যক্তি কামনাবশতঃ ফলে আসক্ত হয়ে আবদ্ধ হন। 5.12",

    "বশীভূত অন্তঃকরণযুক্ত সাংখ্যযোগের আচরণকারী পুরুষ, কর্ম না করে বা না করিয়ে নবদ্বারযুক্ত দেহে সমস্ত কর্ম মনে মনে ত্যাগ করে আনন্দে সচ্চিদানন্দঘন পরমাত্মার স্বরূপে স্থিত থাকেন। 5.13",

    "কারণ পরমেশ্বর মানুষের কর্তৃত্বভাব, কর্ম বা কর্মফলের সংযোগ রচনা করেন না, কিন্তু স্বভাবই (প্রকৃতি) প্রবর্তিত হয় । 5.14",

    "সর্বব্যাপী পরমাত্মা কারও পাপ বা পুণ্য গ্রহণ করেন না, কিন্তু অজ্ঞানের দ্বারা জ্ঞান আবৃত থাকায় মানুষ মোহগ্রস্ত হয় । 5.15",

    "কিন্তু আত্মজ্ঞান দ্বারা অন্তঃকরণের অজ্ঞান যাঁদের বিনষ্ট হয়েছে তাঁদের সেই জ্ঞান সূর্যের ন্যায় সচ্চিদানন্দঘন পরমাত্মাকে প্রকাশিত করে । 5.16",

    "গচ্ছন্ত্যপুনরাবৃত্তিং যাঁদের মন তাঁতে নিবিষ্ট, যাঁদের বুদ্ধিও তাঁতে স্থিত এবং যাঁরা সেই সচ্চিদানন্দঘন পরমাত্মায় নিরন্তর একীভাবে অবস্থান করেন, সেই তৎপরায়ণ ব্যক্তিগণ জ্ঞানের দ্বারা পাপরহিত হয়ে অপুনরাবৃত্তি অর্থাৎ পরমগতি প্রাপ্ত হন । 5.17",

    "ব্রহ্মনিগণ বিদ্যা ও বিনয়যুক্ত ব্রাহ্মণে, গো, হস্তী, কুকুর তথা চণ্ডালেও সমদর্শী (১) হন । 5.18",

    "যাঁদের মন সমভাবে স্থিত, তাঁরা জীবিত অবস্থাতেই সংসার জয় করেছেন; কারণ সচ্চিদানন্দঘন পরমাত্মা নির্দোষ এবং সম, তাই তাঁরা সেই পরমাত্মাতেই অবস্থান করেন। 5.19",

    "যাঁরা প্রিয়বস্তু লাভে হৃষ্ট হন না এবং অপ্রিয়বস্তু প্রাপ্ত হলে উদ্বিগ্ন হন না ; স্থিরবুদ্ধি, সংশয়রহিত— এইরূপ ব্রহ্মবেত্তা পুরুষ সচ্চিদানন্দঘন পরব্রহ্ম পরমাত্মাতে নিত্যই স্থিত। 5.20",

    "বাহ্য বিষয়ে অনাসক্ত পুরুষ আত্মায় যে শাশ্বত আনন্দ আছে তা লাভ করেন, অতঃপর সচ্চিদানন্দঘন পরব্রহ্ম পরমাত্মার ধ্যানরূপ যোগে অভিন্নভাবে স্থিত হয়ে তিনি অক্ষয় আনন্দ অনুভব করেন। 5.21",

    "ভোগ যদিও বিষয়ীলোকের কাছে সুখরূপে ভাসিত হয়, কিন্তু আসলে তা দুঃখেরই হেতু। এর আদি-অন্ত আছে অর্থাৎ এ অনিত্য। সেইজন্য হে অর্জুন ! বুদ্ধিমান বিবেকশীল পুরুষ তাতে রত হন না। 5.22",

    "যিনি দেহত্যাগ করবার পূর্বে কাম-ক্রোধ হতে উৎপন্ন বেগ সহ্য করতে সক্ষম হন, তিনিই যোগী এবং সুখী। 5.23",

    "যিনি অন্তরাত্মাতেই সুখযুক্ত, আত্মারাম এবং আত্মাতেই জ্ঞানযুক্ত ; সেই সচ্চিদানন্দঘন পরব্রহ্ম পরমাত্মার সঙ্গে একীভূত সাংখ্যযোগী ব্রহ্মনির্বাণ প্রাপ্ত হন। 5.24",

    "ছিন্নদ্বৈধা যতাত্মানঃ সর্বভূতহিতে রতাঃ৷৷ যাঁর সমস্ত পাপ দূর হয়েছে, সমস্ত সংশয় জ্ঞান দ্বারা ছিন্ন হয়েছে, যিনি সর্বভূতহিতে রত, সংযত চিত্ত ও নিশ্চলভাবে পরমাত্মাতে স্থিত—সেই ব্রহ্মবেত্তা পুরুষ শান্তস্বরূপ ব্রহ্মকে প্রাপ্ত হন। 5.25",

    "কাম-ক্রোধ হতে মুক্ত সংযতচিত্ত, ব্রহ্মদর্শী জ্ঞানী পুরুষের সর্বদিক পরিপূর্ণ করে শান্ত পরব্রহ্মই স্থিত আছেন। 5.26",

    "বাহ্য বিষয়ভোগের চিন্তা না করে তাকে বাইরেই ত্যাগ করে, চোখের দৃষ্টি ভ্রমধ্যে স্থাপন করে, নাসিকার মধ্যে বিচরনশীল প্রাণ ও অপানবায়ুকে সম করে এবং ইন্দ্রিয়, মন ও বুদ্ধিকে সংযত করে ইচ্ছা, ভয় ও ক্রোধশূন্য হয়ে যে মোক্ষপরায়ণ মুনি(১) সর্বদা বিরাজ করেন, তিনি মুক্তই থাকেন। 5.27 - 5.28",

    "বাহ্য বিষয়ভোগের চিন্তা না করে তাকে বাইরেই ত্যাগ করে, চোখের দৃষ্টি ভ্রমধ্যে স্থাপন করে, নাসিকার মধ্যে বিচরনশীল প্রাণ ও অপানবায়ুকে সম করে এবং ইন্দ্রিয়, মন ও বুদ্ধিকে সংযত করে ইচ্ছা, ভয় ও ক্রোধশূন্য হয়ে যে মোক্ষপরায়ণ মুনি(১) সর্বদা বিরাজ করেন, তিনি মুক্তই থাকেন। 5.27 - 5.28",

    "আমার ভক্ত আমাকে সমস্ত যজ্ঞ ও তপস্যার ভোক্তা, সর্বলোকের মহেশ্বর (ঈশ্বরেরও ঈশ্বর) এবং সকল প্রাণীর সুহৃদ অর্থাৎ স্বার্থরহিত দয়ালু ও প্রেমিক, এইরূপ তত্ত্বতঃ জেনে শান্তি লাভ করেন । 5.29",

    "এইরূপ ওঁ তৎ সৎ - এই ভগবানের নামগুলি উচ্চারণপূর্বক ব্রহ্মবিদ্যা এবং য়োগশাস্ত্রময় শ্রীমদ্ভবদ্গীতোপনিষদরূপ শ্রীকৃষ্ণার্জুনসংবাদে 'কর্মসন্ন্যাসয়োগ' নামক পঞ্চম অধ্যায় সমাপ্ত হলো।"
  ],
  "adhyaya-6" : [
    "ভগবান শ্রীকৃষ্ণ বললেন—যিনি কর্মফলের আশ্রয় না নিয়ে কর্তব্য-কর্ম করেন তিনিই সন্ন্যাসী এবং যোগী। যিনি কেবল যাগযজ্ঞাদিসম্পাদক বৈদিক অগ্নি ও লোকহিতকর ক্রিয়াদি ত্যাগ করেছেন তিনি যোগী বা সন্ন্যাসী নন। 6.1",

    "হে অর্জুন ! যাকে সন্ন্যাস(১) বলা হয়, তাকেই তুমি যোগ(২) বলে জানবে কারণ সংকল্প ত্যাগ না করলে কেউই যোগী হতে পারে না। 6.2",

    "যোগে আরোহণে ইচ্ছুক মননশীল ব্যক্তির যোগে স্থিতিলাভের জন্য নিষ্কাম কর্ম করাকেই কারণ বলা হয়েছে এবং যোগারূঢ় হলে যোগারূঢ় পুরুষের যে সর্বসংকল্পের অভাব হয়, সেটিকে তাঁর পরম কল্যাণের কারণ বলা হয়েছে। 6.3",

    "যখন সাধক ইন্দ্রিয়সমূহের ভোগে আসক্ত হন না এবং কর্মেও আসক্ত হন না, তখন সেই সর্বসংকল্পত্যাগী পুরুষকে যোগারূঢ় বলা হয়। 6.4",

    "নিজের দ্বারাই নিজেকে সংসার হতে উদ্ধার করবে এবং নিজেকে কখনও অধোগতির পথে যেতে দেবে না; কারণ মানুষ নিজেই নিজের বন্ধু আবার নিজেই নিজের শত্রু। 6.5",

    "যে জীবাত্মার দ্বারা মন এবং ইন্দ্রিয়াদিসহ শরীর বশীভূত হয়েছে, সেই জীবাত্মা নিজেই নিজের বন্ধু এবং যে জীবাত্মার দ্বারা মন এবং ইন্দ্রিয়াদিসহ শরীর বশীভূত হয়নি, সে নিজেই নিজের শত্রু। 6.6",

    "শীত-উষ্ণ, সুখ-দুঃখ এবং মান-অপমানে যাঁর চিত্ত পূর্ণরূপে শান্ত এইরূপ স্বাধীন-চিত্ত ব্যক্তি সচ্চিদানন্দঘন পরমাত্মায় সম্যকভাবে অবস্থান করেন অর্থাৎ তাঁর জ্ঞানে পরমাত্মা ভিন্ন অন্য কিছুই থাকে না। 6.7",

    "যুক্ত ইত্যুচ্যতে যোগী সমলোষ্ট, মকাঞ্চনঃ ৷৷ যাঁর অন্তঃকরণ জ্ঞান-বিজ্ঞানে পরিতৃপ্ত, যিনি বিকার রহিত এবং জিতেন্দ্রিয় ; যাঁর দৃষ্টিতে মৃত্তিকা, প্রস্তর এবং স্বর্ণ সমতুল্য, তিনিই যোগযুক্ত অর্থাৎ তাঁর ভগবৎ প্রাপ্তি হয়েছে বুঝতে হবে। 6.8",

    "সুহৃদ, মিত্র, শত্রু, উদাসীন, মধ্যস্থা, দেষ্য, বন্ধু, ধর্মাত্মা এবং পাপীদের প্রতি যিনি সমভাব রাখেন, তিনিই প্রকৃতপক্ষে শ্রেষ্ঠ। 6.9",

    "মন ও ইন্দ্রিয়সহ যিনি সংযতদেহ, আকাঙ্ক্ষা এবং সঞ্চয়বৃত্তিশূন্য ; তিনি একাকী, নির্জন স্থানে থেকে নিরন্তর চিত্তকে পরমাত্মার ধ্যানে নিযুক্ত করবেন। 6.10",

    "পবিত্র এবং অতি উচ্চে বা অতি নিম্নে নয় এমনস্থানে, ক্রমশঃ কুশ, মৃগচর্ম এবং বস্ত্রাদি পেতে নিজের স্থির আসন স্থাপন করবেন। 6.11",

    "সেই আসনে বসে চিত্ত ও ইন্দ্রিয়ের ক্রিয়া সংযত করে মনকে একাগ্র করে, অন্তঃকরণের শুদ্ধির জন্য যোগ অভ্যাস করবেন। 6.12",

    "মেরুদণ্ড, মস্তক, গ্রীবাকে সমান ও নিশ্চলভাবে স্থির করে নিজ নাসিকার অগ্রভাগে দৃষ্টিস্থাপন করে, অন্য কোনো দিকে না তাকিয়ে- 6.13",

    "ব্রহ্মচর্য ব্রতে স্থিত, ভয় রহিত ও প্রশান্তচিত্ত যোগী সতর্কতার সঙ্গে মনকে সংযত করে মদ্‌গতচিত্ত এবং মৎপরায়ণ হয়ে অবস্থান করবেন। 6.14",

    "সংযতচিত্ত যোগী এইভাবে আত্মাকে নিরন্তর পরমেশ্বররূপ আমাতে সমাহিত করে আমাতে স্থিত পরমানন্দের পরাকাষ্ঠারূপ শান্তি লাভ করেন। 6.15",

    "হে অর্জুন ! এই যোগ, যাঁরা অত্যধিক আহার করেন অথবা যাঁরা একান্ত অনাহারী, যাঁরা অতিশয় নিদ্রালু অথবা অত্যন্ত জাগরণশীল তাঁদের সিদ্ধ হয় না। 6.16",

    "দুঃখনাশক এই যোগ নিয়মিত আহার-বিহারকারী, কর্মে যথাযথ চেষ্টাকারী এবং নিয়মিত নিদ্রা ও জাগরণশীলেরই সিদ্ধ হয় । 6.17",

    "চিত্ত যখন একান্তভাবে বশীভূত হয়ে পরমাত্মাতেই অবস্থান করে তখন ভোগে সম্পূর্ণভাবে আকাঙ্ক্ষাশূন্য সেই পুরুষকে যোগযুক্ত বলা হয়। 6.18",

    "বায়ুবিহীন স্থানে দীপশিখা যেমন চঞ্চল হয় না, পরমাত্মার ধ্যানে সংযতচিত্ত একাগ্রীভূত চিত্তেরও সেই অবস্থা জানবে। 6.19",

    "যোগ অভ্যাসের দ্বারা নিরুদ্ধ চিত্ত যে অবস্থায় নিবৃত্ত হয় এবং যে অবস্থায় পরমাত্মার ধ্যানে শুদ্ধ বুদ্ধির সাহায্যে পরমাত্মাকে সাক্ষাৎ করে যোগী পরমাত্মাতেই পরিতুষ্ট হন। 6.20",

    "ইন্দ্রিয়াদির অতীত, কেবল পরিশুদ্ধ বুদ্ধিদ্বারা গ্রাহ্য যে অনন্ত আনন্দ আছে, যোগী এই অবস্থায় সেটি অনুভব করেন এবং সেই অবস্থায় স্থিত যোগী কোনোভাবেই পরমাত্মাস্বরূপ হতে আর বিচলিত হন না। 6.21",

    "পরমাত্মাকে লাভ করে অন্য কিছুকে যোগী তা অপেক্ষা অধিক লাভজনক মনে করেন না এবং পরমাত্মাপ্রাপ্তিরূপ সেই অবস্থায় স্থিত হয়ে মহাদুঃখেও তা থেকে বিচলিত হন না। 6.22",

    "তাকেই বলা হয় যোগ, এটি জানা চাই। এই যোগ অধৈর্য না হয়ে অর্থাৎ ধৈর্য ও উৎসাহযুক্ত চিত্তে নিশ্চয়পূর্বক অভ্যাস করা কর্তব্য। 6.23",

    "সংকল্পজাত সমস্ত কামনা নিঃশেষে ত্যাগ করে মনের দ্বারা ইন্দ্রিয়সমূহকে সমস্ত বিষয় হতে নিবৃত্ত করে। 6.24",

    "ধীরে ধীরে অভ্যাসের দ্বারা উপরতিতে স্থিত হবেন এবং ধৈর্যযুক্ত বুদ্ধির দ্বারা মনকে পরমাত্মায় স্থাপন করে পরমাত্মা ভিন্ন অন্য কিছুই চিন্তা করবে না। 6.25",

    "এই অস্থির চঞ্চল মন যে যে বিষয়ের প্রতি ধাবিত সেই সেই বিষয় হতে তাকে প্রত্যাহার করে বারবার পরমাত্মাতেই স্থিত করবে। 6.26",

    "কেননা যিনি প্রশান্তচিত্ত, পাপরহিত এবং রজোগুণ বৃত্তিশূন্য—এরূপ যোগী সচ্চিদানন্দঘন ব্রহ্মের সঙ্গে একাত্ম হয়ে পরম আনন্দ লাভ করেন। 6.27",

    "সেই নিষ্পাপ যোগী এইভাবে নিরন্তর আত্মাকে পরমাত্মায় সমাহিত করে অনায়াসে পরব্রহ্ম পরমাত্মাপ্রাপ্তিরূপ অনন্ত আনন্দ অনুভব করেন। 6.28",

    "সর্বব্যাপী অনন্ত চেতনে একত্বভাবে যুক্ত তথা সর্বত্র সমদর্শনকারী যোগী স্বীয় আত্মাকে সর্বভূতে এবং সর্বভূতকে স্বীয় আত্মায় অনুভব করেন। 6.29",

    "যিনি সর্বভূতে আত্মারূপে আমাকে (বাসুদেবকে) অবলোকন করেন এবং সর্বভূতকে আমাতে দেখেন তাঁর কাছে আমি অদৃশ্য হই না এবং তিনিও আমার কাছে অদৃশ্য হন না। 6.30",

    "যে-ব্যক্তি অভেদভাবে স্থিত হয়ে সর্বভূতে আত্মারূপে আমাকে (সচ্চিদানন্দঘন বাসুদেবকে ভজনা করেন, সেই যোগী সর্বপ্রকার ব্যবহার করলেও আমাতেই অবস্থান করেন। 6.31",

    "আত্মোপম্যেন সর্বত্র সমং পশ্যতি যোহর্জুন। সুখং বা যদি বা দুঃখং স যোগী পরমো মতঃ।। হে অর্জুন ! যিনি সর্বত্র সমদর্শী হয়ে সকল ভূতের সুখ ও দুঃখকে নিজের সুখ ও দুঃখ বলে অনুভব করেন, আমার মতে তিনি সর্বশ্রেষ্ঠ যোগী। 6.32",

    "অর্জুন বললেন-হে মধুসূদন ! আপনি যে সমতারূপ যোগের কথা বললেন, মন চঞ্চল হওয়ায় আমি তার নিতা স্থিতি দেখি না। 6.33",

    "কারণ হে কৃষ্ণ! মন অত্যন্ত চঞ্চল, বিক্ষোভকারী, দৃঢ় ও শক্তিশালী। তাই একে বশে রাখা আমি বায়ুকে নিরুদ্ধ করার মতো দুষ্কর বলে মনে করি। 6.34",

    "ভগবান শ্রীকৃষ্ণ বললেন –হে মহাবাহো ! মন নিঃসন্দেহে চঞ্চল এবং একে বশে রাখা দুস্কর। কিন্তু হে কুন্তীপুত্র অর্জুন ! অভ্যাস ও বৈরাগ্য দ্বারা একে বশ করা যায়। 6.35",

    "যারা সংযতচিত্ত নয় তাদের দ্বারা এই যোগ দুষ্প্রাপ্য, কিন্তু যত্নশীল বশীভূতচিত্ত ব্যক্তি সাধনার দ্বারা এই যোগ সহজেই প্রাপ্ত হতে পারেন—এই হল আমার মত। 6.36",

    "অর্জুন বললেন—হে কৃষ্ণ ! যিনি যোগে শ্রদ্ধা রাখেন কিন্তু সংযতচিত্ত নন, সেইজন্য অন্তিম সময়ে (মৃত্যুকালে) যাঁর মন যোগ হতে বিচলিত হয়ে যায়, এইরূপ যোগী যোগসিদ্ধ না হয়ে অর্থাৎ পরমাত্মার সাক্ষাৎকার না করে কীরূপ গতি প্রাপ্ত হন ? 6.37",

    "হে মহাবাহো ! তিনি কি ভগবৎপ্রাপ্তির পথে বিভ্রান্ত এবং নিরাশ্রয় হয়ে ছিন্ন-ভিন্ন মেঘখণ্ডের ন্যায় উভয় পথ হতে ভ্রষ্ট হয়ে বিনাশ প্রাপ্ত হন ? 6.38",

    "হে কৃষ্ণ ! আমার এই সংশয় নিঃশেষে আপনিই দূর করতে সমর্থ ; কারণ আপনি ছাড়া আর কেউ এই সংশয় দূর করতে পারবে না। 6.39",

    "ভগবান শ্রীকৃষ্ণ বললেন –হে পার্থ ! সেই ব্যক্তির ইহলোকে বা পরলোকে কোথাও বিনাশ নেই। কারণ হে বৎস ! কল্যাণকামী ব্যক্তির কখনও অধোগতি হয় না। 6.40",

    "যোগভ্রষ্ট ব্যক্তি পুণ্যাত্মাগণের প্রাপ্যলোক অর্থাৎ স্বর্গাদি উচ্চলোকে বহুকাল বাস করে পুনঃ সদাচার সম্পন্ন ধনীর গৃহে জন্মগ্রহণ করেন। 6.41",

    "অথবা যোগভ্রষ্ট ব্যক্তি সেইসকল লোকাদিতে না গিয়ে জ্ঞানবান যোগীর কুলে জন্মগ্রহণ করেন। এইরূপ জন্ম জগতে অত্যন্ত দুর্লভ। 6.42",

    "সেই দেহে পূর্বজন্মের সুকৃতির ফলে তিনি মোক্ষপর বুদ্ধি লাভ করেন। এবং হে কুরুনন্দন ! তার প্রভাবে পুনরায় পরমাত্মাকে লাভের জন্য তিনি পূর্বাপেক্ষা তীব্রভাবে চেষ্টা করেন। 6.43",

    "তিনি (শ্রীসম্পন্ন সদাচারী ব্যক্তির গৃহে জন্মগ্রহণকারী) যোগভ্রষ্ট হয়েও পূর্ব জন্মের অভ্যাসবশে নিজের অবশেই ভগবানে আকৃষ্ট হন, তথা সমবুদ্ধিরূপ যোগের জিজ্ঞাসু ব্যক্তিও বেদে বর্ণিত সকাম কর্মের ফলকে অতিক্রম করেন। 6.44",

    "কিন্তু যত্নপূর্বক অভ্যাসকারী যোগী বিগত বহু জন্মের সাধনসঞ্চিত সংস্কারের প্রভাবে এই জন্মেই পাপরহিত হয়ে যান এবং তৎকালেই পরমগতি লাভ করেন। 6.45",

    "যোগী তপস্বিগণ অপেক্ষা শ্রেষ্ঠ, শাস্ত্রজ্ঞ পণ্ডিত গণের হতেও শ্রেষ্ঠ এবং সকাম কর্মানুষ্ঠানকারিগণ অপেক্ষাও শ্রেষ্ঠ। অতএব হে অর্জুন! তুমি যোগী হও। 6.46",

    "সকল যোগীর মধ্যে যিনি শ্রদ্ধার সঙ্গে মদ্‌গতচিত্তে নিরন্তর আমাকে ভজনা করেন, তিনিই সর্বশ্রেষ্ঠ যোগী—এই আমার মত। 6.47",

    "এইরূপ ওঁ তৎ সৎ - এই ভগবানের নামগুলি উচ্চারণপূর্বক ব্রহ্মবিদ্যা এবং য়োগশাস্ত্রময় শ্রীমদ্ভবদ্গীতোপনিষদরূপ শ্রীকৃষ্ণার্জুনসংবাদে 'আত্মসংয়ময়োগ' নামক ষষ্ঠ অধ্যায় সমাপ্ত হলো।"
  ],
  "adhyaya-7" : [
    "শ্রীভগবান বললেন –হে পার্থ ! আমাতে আসক্তচিত্ত, আমার শরণাগত হয়ে যোগাভ্যাস দ্বারা তুমি আমার সমগ্র রূপ যে-প্রকারে নিঃসন্দিগ্ধভাবে জানতে পারবে—তা শোন ॥ 7.1",

    "তোমার জন্য আমি বিজ্ঞানসহ জ্ঞানের কথা সম্পূর্ণরূপে বলছি, যেটি জানলে এ বিষয়ে কিছুই আর জানবার অবশিষ্ট থাকবে না ৷৷ 7.2",

    "সহস্র মানুষের মধ্যে কোনো একজন প্রকৃত সিদ্ধিলাভের জন্য চেষ্টা করেন এবং ওই যত্নশীল সিদ্ধ ব্যক্তিগণের মধ্যে কোনো একজন আমাকে যথার্থভাবে জানতে পারেন । 7.3",

    "ক্ষিতি (পৃথিবী), জল, অগ্নি, বায়ু, আকাশ—এই পঞ্চমহাভূত এবং মন, বুদ্ধি, অহংকার- -এই আটপ্রকার ভেদসম্পন্ন হল 7.4",

    "আমার ‘অপরা’ প্রকৃতি। হে মহাবাহো ! এই অপরা প্রকৃতি থেকে ভিন্ন আমার জীবরূপা ‘পরা’ প্রকৃতি, যার দ্বারা এই জগৎ ধৃত হয়ে আছে জানবে ॥ 7.5",

    "অপরা এবং পরা–এই দুই প্রকৃতির সংযোগেই সমস্ত প্রাণী উৎপন্ন হয় বলে জেনো। আমি সমস্ত জগতের উৎপত্তি ও প্রলয় অর্থাৎ সৃষ্টির মূল কারণ ॥ 7.6",

    "হে ধনঞ্জয় ! আমি ভিন্ন এই জগতের অন্য কোনো কারণ বা কার্য নেই। যেমন সুতোর দ্বারা তৈরি গুটিকাগুলি (মণিসমূহ) সুতোতে গাঁথা থাকে, তেমনি সমস্ত জগৎ আমাতেই অনুস্যূত (ওতঃপ্রোত) হয়ে আছে 7.7",

    "হে কৌন্তেয় ! জলে আমি রস, চন্দ্র ও সূর্যে আমি প্রভা, বেদে আমি প্রণব (ওঁকার), আকাশে আমি শব্দ এবং মানুষের মধ্যে আমি পুরুষার্থরূপে বিরাজমান ॥7.8",

    "আমিই পৃথিবীতে পবিত্র গন্ধ, অগ্নিতে তেজ, সমস্ত প্রাণীর জীবনীশক্তি এবং তপস্বীদের তপস্যা ॥ 7.9",

    "হে পার্থ ! আমাকে সর্ব প্রাণীর অনাদি বীজ বলে জানবে। আমি বুদ্ধিমানদিগের বুদ্ধি এবং তেজস্বিগণের তেজস্বরূপ ৷৷ 7.10",

    "হে ভরতশ্রেষ্ঠ অর্জুন ! বলবানদিগের কাম ও রাগবর্জিত বল আমি। মানুষের মধ্যে ধর্মের অবিরুদ্ধ (ধর্মযুক্ত) কামও আমি ॥ 7.11",

    "যতপ্রকার সাত্ত্বিক, রাজসিক এবং তামসিক ভাব আছে, সে-সব আমা হতে উৎপন্ন বলে জানবে। কিন্তু আমি সেই সবে নেই এবং সেগুলিও আমাতে নেই ৷ 7.12",

    "এই তিনটি গুণরূপ ভাবের দ্বারা মোহিত সমগ্র জগৎ এইসব গুণের অতীত অবিনাশী ঈশ্বররূপে আমাকে জানতে পারে না । 7.13",

    "কারণ আমার এই গুণময়ী দৈবী মায়া অত্যন্ত দুরত্যয় অর্থাৎ পার হওয়া কঠিন। যাঁরা শুধুমাত্র আমার শরণাগত হন, তাঁরা এই মায়া অতিক্রম করতে পারেন ৷ 7.14",

    "মায়া দ্বারা হতজ্ঞানী, আসুরীভাবের আশ্রয়গ্রহণকারী, মনুষ্যকুলে অতিশয় নীচ এবং পাপাচরণকারী মৃঢ় ব্যক্তি আমার শরণ নেয় না ৷৷ 7.15",

    "হে ভরতশ্রেষ্ঠ অর্জুন! অর্থার্থী, আর্ত, জিজ্ঞাসু এবং জ্ঞানী অর্থাৎ প্রেমিক–এই চার-প্রকারের সুকৃতিশালী মানুষ আমার ভজনা করে অর্থাৎ আমার শরণাগত হয় ॥ 7.16",

    "ওই চারপ্রকার ভক্তের মধ্যে সতত আমাতে সমাহিতচিত্ত অনন্য ভক্তিসম্পন্ন জ্ঞানী অর্থাৎ প্রেমিক ভক্ত অতিশয় শ্রেষ্ঠ। কারণ জ্ঞানী ভক্তের নিকট আমি অতি প্রিয় এবং সেও আমার অতিশয় প্রিয় । 7.17",

    "পূর্বে কথিত সকল ভক্তই অত্যন্ত মহান (শ্রেষ্ঠ ভাবসম্পন্ন)। কিন্তু জ্ঞানী (প্রেমিক) তো আমার আত্মস্বরূপ —এই হল আমার মত। কারণ সে মদ্‌গতচিত্ত এবং যার থেকে শ্রেষ্ঠ আর কোনো গতি নেই,সেই আমাতেই সে দৃঢ় আস্থাবান ৷৷ 7.18",

    "অনেক জন্মের পরে অন্তিম জন্মে অর্থাৎ মনুষ্যজন্মে ‘পরমাত্মাই সবকিছু, এইভাবে যে জ্ঞানী ব্যক্তি আমার শরণাগত হন, সেইরূপ মহাত্মা অত্যন্ত দুর্লভ ৷৷ 7.19",

    "ওইসব কামনা দ্বারা যাদের বিবেক অপহৃত হয়েছে তারা নিজ নিজ প্রকৃতির (স্বভাবের) বশীভূত হয়ে অন্য দেবতাদের শরণাগত হয়ে তাদের (আরাধনার) নিয়মগুলি পালন করে থাকে । 7.20",

    "যে যে ভক্ত যে যে দেবতার পূজা করতে ইচ্ছুক, আমি সেই সেই দেবতার প্রতি তার ভক্তি অচলা করে দিই ৷৷ 7.21",

    "ওই (আমা দ্বারা দৃঢ়ীকৃত) শ্রদ্ধাযুক্ত হয়ে ওই ব্যক্তি (সকামভাবে) ওই দেবতার উপাসনা করে এবং তার কামনার পূরণও হয়। কিন্তু সেই কামনা-পূর্তি আমা কর্তৃকই বিহিত হয়ে থাকে৷  ৷ 7.22",

    "কিন্তু সেই অল্পবুদ্ধি মানুষদের ওইসকল দেবতাদের আরাধনার ফল বিনাশশীল হয়ে থাকে। দেবতাদের পূজা করেন যাঁরা, তাঁরা দেবতাদের লাভ করেন আর আমার ভক্তগণ আমাকেই লাভ করেন ৷৷ 7.23",

    "অল্পবুদ্ধি ব্যক্তিগণ আমার সর্বোৎকৃষ্ট পরমভাব না জেনে অব্যক্ত (মন-ইন্দ্রিয়াদির অতীত) আমাকে, সচ্চিদানন্দময় পরমাত্মাকে, মানুষের ন্যায় শরীর ধারণকারী বলে মনে করে থাকে ॥ 7.24",

    "যেসব মূঢ় ব্যক্তি আমাকে অজ এবং অবিনাশীরূপে যথার্থভাবে জানে না (মানে না), যোগমায়া সমাবৃত থেকে আমি তাদের সামনে প্রকাশিত হই না ৷৷ 7.25",

    "হে অর্জুন! যারা অতীতে হয়েছে, বর্তমানে হচ্ছে এবং ভবিষ্যতে হবে, সেই সকল প্রাণীকে আমি জানি। কিন্তু আমাকে (ভক্ত ব্যতীত) কেউই জানে না ৷৷ 7.26",

    "হে ভরতবংশোদ্ভব পরন্তপ ! ইচ্ছা (আকাঙ্ক্ষা) এবং দ্বেষ হতে উৎপন্ন দ্বন্দ্ব-মোহ দ্বারা মোহিত সমস্ত প্রাণী অনাদিকাল থেকে জগতে হতজ্ঞান অর্থাৎ জন্ম-মৃত্যু প্রাপ্ত হয়ে থাকে ॥ 7.27",

    "কিন্তু যে পুণ্যকর্মা ব্যক্তিদের পাপ বিনষ্ট হয়েছে, সেই দ্বন্দ্ব- মোহরহিত দৃঢ় ব্রত ব্যক্তিগণ আমার ভজনা করেন ৷৷ 7.28",

    "বৃদ্ধাবস্থা এবং মরণ থেকে মুক্তি পাবার জন্য যাঁরা আমার শরণাগত হয়ে প্রযত্ন করেন, তাঁরা সেই সনাতন ব্রহ্ম, সমগ্র অধ্যায় বিষয় এবং সম্পূর্ণ কর্মতত্ত্ব অবগত হন ৷৷ 7.29",

    "যেসব মানুষ অধিভূত, অধিদৈব এবং অধিযজ্ঞসহ আমাকে জানেন, তাঁরা যুক্তচিত্ত হওয়ায় মৃত্যুকালেও আমাকে জানতে পারেন অর্থাৎ আমাকেই প্রাপ্ত হন ॥ 7.30",

    "এইরূপ ওঁ তৎ সৎ - এই ভগবানের নামগুলি উচ্চারণপূর্বক ব্রহ্মবিদ্যা এবং য়োগশাস্ত্রময় শ্রীমদ্ভবদ্গীতোপনিষদরূপ শ্রীকৃষ্ণার্জুনসংবাদে 'জ্ঞানবিজ্ঞানয়োগ' নামক সপ্তম অধ্যায় সমাপ্ত হলো।"
  ],
  "adhyaya-8" : [
    "অর্জুন বললেন—হে পুরুষোত্তম ! সেই ব্রহ্ম কী ? অধ্যাত্ম কী ? কর্ম কী ? অধিভূত কাকে বলে আর অধিদৈবই বা কাকে বলা হয় ? 8.1",

    "এখানে অধিযজ্ঞ কী এবং এই দেহে কীভাবে অবস্থিত ? হে মধুসূদন !  সংযতচিত্ত ব্যক্তি মৃত্যুকালে আপনাকে কী করে জানতে পারেন ? ॥ 8.2",

    "শ্রীভগবান বললেন- —পরম অক্ষরই ব্রহ্ম আর পরা প্রকৃতিকে (জীব) অধ্যায় বলা হয়। প্রাণীদের সত্তা প্রকটকারী যে ত্যাগ, তাকেই কর্ম বলা হয় ৷৷ 8.3",

    "হে দেহধারীদের মধ্যে শ্রেষ্ঠ অর্জুন! ক্ষর ভাব অর্থাৎ নশ্বর পদার্থকেই অধিভূত বলা হয়, পুরুষ অর্থাৎ হিরণ্যগর্ভ ব্রহ্মাই অধিদৈব এবং এই দেহে অন্তর্যামীরূপে আমিই অধিযজ্ঞ ॥ 8.4",

    "যে ব্যক্তি অন্তকালেও আমাকে স্মরণ করতে করতে দেহ পরিত্যাগ করেন, তিনি আমাকেই প্রাপ্ত হন–এতে কোনো সন্দেহ নেই।' ॥ 8.5",

    "হে কুত্তীপুত্র অর্জুন! মানুষ মৃত্যুকালে যে যে ভাব স্মরণ করতঃ দেহত্যাগ করে, সে ওই (অন্তিম) ভাবে সদা ভাবিত হওয়ায় ওইরূপ গতি প্রাপ্ত হয় অর্থাৎ সেই সেই যোনিতে জন্ম নেয় ৷ 8.6",

    "অতএব তুমি সকল সময় আমাকে স্মরণ কর আর যুদ্ধও কর। আমাতে মন ও বুদ্ধি সমর্পণ করলে তুমি নিঃসন্দেহে আমাকেই লাভ করবে ॥ 8.7",

    "হে পার্থ ! অভ্যাসযোগ দ্বারা যুক্ত এবং অনন্য চিত্তে পরমপুরুষের চিন্তন করতে করতে (শরীর ত্যাগকারী ব্যক্তি) তাঁকেই প্রাপ্ত হন ৷৷ 8.8",

    "যিনি সর্বজ্ঞ, অনাদি, সকলের শাসনকর্তা, সূক্ষ্ম থেকে সূক্ষ্ম, সর্বপ্রাণীর পালন-পোষণকারী, সর্বতোভাবে অজ্ঞানের অতীত, সূর্যের ন্যায় স্ব-প্রকাশ অর্থাৎ জ্ঞানস্বরূপ—এইরূপ অচিন্ত্য-স্বরূপের চিন্তন করেন ৷৷ 8.9",

    "সেই ভক্তিযুক্ত মানুষ মৃত্যুকালে একাগ্র মনে এবং যোগবলের দ্বারা ভ্রূযুগলের মধ্যে প্রাণকে সম্যকভাবে ধারণ করে (শরীর ত্যাগ করলে), সেই পরম দিব্য পুরুষকে প্রাপ্ত হন ৷৷ 8.10",

    "বেদবিদ্‌গণ যাকে অক্ষর বলেন, বীতরাগ যোগিগণ যাকে প্রাপ্ত করেন এবং যাকে প্রাপ্ত করার আকাঙ্ক্ষা করে ব্রহ্মচর্য পালন করেন, সেই পদ-প্রাপ্তির কথা আমি তোমাকে সংক্ষেপে জানাচ্ছি ৷৷ 8.11",

    "সমস্ত ইন্দ্রিয়ম্বার রুদ্ধ (সংযত) করে, মনকে হৃদয়ে নিরোধ করে এবং নিজের প্রাণকে মস্তকে স্থাপনা করে যোগধারণে সম্যকরূপে স্থিত হয়ে  8.12",

    "যিনি ওঁ এই এক-অক্ষরব্রহ্ম মনে মনে উচ্চারণপূর্বক আমাকে স্মরণ করতে করতে শরীর পরিত্যাগ করেন তিনি পরমগতি প্রাপ্ত হন । 8.13",

    "হে পার্থ ! অনন্যচিত্ত যে ব্যক্তি নিত্য-নিরন্তর আমাকে স্মরণ করেন, সেই নিত্যযুক্ত যোগীর কাছে আমি সহজলভ্য অর্থাৎ তিনি আমাকে সহজেই প্রাপ্ত হন । 8.14",

    "মহাত্মাগণ আমাকে প্রাপ্ত হয়ে দুঃখের আলয় এবং অশাশ্বত অর্থাৎ নিত্য পরিবর্তনশীল পুনর্জন্ম প্রাপ্ত হন না : কারণ তাঁরা পরমসিদ্ধি প্রাপ্ত হয়েছেন অর্থাৎ তাঁদের পরমপ্রেম-প্রাপ্তি হয়েছে ৷ 8.15",

    "হে অর্জুন ! ব্রহ্মলোক পর্যন্ত সমস্ত লোকই আবর্তনশীল (অর্থাৎ সেখান থেকে ফিরে আসতে হয়), কিন্তু হে কৌন্তেয় ! আমাকে প্রাপ্ত হলে আর পুনর্জন্ম হয় না ॥ 8.16",

    "যাঁরা ব্রহ্মার চতুর্যুগসহস্র ব্যাপী একটি দিন এবং চতুর্যুগসহস্র ব্যাপী একটি রাত্রিকে জানেন, তাঁরাই ব্রহ্মার দিন ও রাতের প্রকৃত তত্ত্ব জানেন ৷৷ 8.17",

    "ব্রহ্মার দিবসের প্রারম্ভে অব্যক্ত (ব্রহ্মার সূক্ষ্মশরীর) থেকে সকল প্রাণী উদ্ভূত হয় এবং ব্রহ্মার রাত্রের প্রারম্ভে সেই অব্যক্তেই অর্থাৎ ব্রহ্মার সূক্ষ্মশরীরে সমস্ত প্রাণী লীন হয়ে যায় । 8.18",

    "হে পার্থ ! এই সেই প্রাণীসকলই প্রকৃতির বশীভূত থেকে পুনঃ পুনঃ ব্রহ্মার দিবসের প্রারম্ভে উৎপন্ন হয় এবং রাত্রির প্রারম্ভে লীন হয়ে যায় । 8.19",

    "কিন্তু সেই অবাক্তের (ব্রহ্মার সূক্ষ্মশরীরের) অতীত, অনাদি, সর্বশ্রেষ্ঠ ভাবরূপ যে অব্যক্ত (ঈশ্বর) আছেন, সমস্ত প্রাণীর নাশ হলেও তাঁর বিনাশ নেই ৷৷ 8.20",

    "তাঁকেই অব্যক্ত অক্ষর বলা হয়েছে, তাঁকেই পরমগতি বলা হয়েছে যাঁকে প্রাপ্ত হলে আর জগতে ফিরে আসতে হয় না, সেটিই হল আমার পরম ধাম ৷৷ 8.21",

    "হে পৃথানন্দন ! সমস্ত প্রাণী যাঁর অন্তর্গত এবং যাঁর দ্বারা এই সমস্ত জগৎ পরিব্যাপ্ত হয়ে আছে, পরমপুরুষ পরমাত্মাকে কেবল অনন্যা ভক্তি দ্বারা লাভ করা যায় । 8.22",

    "হে ভরতশ্রেষ্ঠ অর্জুন! যে কালে অর্থাৎ পথে শরীর ত্যাগ করার পর যোগিগণ অনাবৃত্তি প্রাপ্ত হন অর্থাৎ পুনর্জন্ম প্রাপ্ত হন না এবং যে পথে গমন করে আবৃত্তি প্রাপ্ত হন অর্থাৎ পুনর্জন্ম প্রাপ্ত হন, সেই কাল অর্থাৎ উভয় পথের কথাই আমি বলছি ॥ 8.23",

    "যে মার্গে জ্যোতির্ময় অগ্নির অধিপতি দেবতা, দিনের অধিপতি দেবতা, শুক্লপক্ষের অধিপতি দেবতা এবং ছয় মাসব্যাপী উত্তরায়ণের অধিপতি দেবতা থাকেন, ব্রহ্মবেত্তা পুরুষগণ দেহত্যাগ করে সেই মার্গে গমন করে (প্রথমে ব্রহ্মলোক প্রাপ্ত হয়ে পরে ব্রহ্মার সঙ্গে) ব্রহ্ম প্রাপ্ত হন ॥ 8.24",

    "যে মার্গে ধূমের অধিপতি দেবতা, রাত্রির অধিপতি দেবতা, কৃষ্ণপক্ষের অধিপতি দেবতা এবং দক্ষিণায়নের ছয় মাসের অধিপতি দেবতা থাকেন, দেহত্যাগ করে যে-সব যোগী (সকাম ব্যক্তি) সেই মার্গে গমন করেন, তাঁরা চন্দ্রলোকের জ্যোতিপ্রাপ্ত হয়ে ফিরে আসেন অর্থাৎ তাঁরা জন্ম-মৃত্যু প্রাপ্ত হন ॥ 8.25",

    "কারণ শুরু এবং কৃষ্ণ—এই দুটি গতিই অনাদিকাল হতে জগতের (প্রাণীকুলের) সঙ্গে সম্পর্কিত। এর মধ্যে একটির দ্বারা মোক্ষলাভ হয় (অর্থাৎ পুনর্জন্ম হয় না) আর অপরটিতে পুনর্জন্ম হয় (ফিরে আসতে হয়) ॥ 8.26",

    "হে পার্থ ! এই উভয় মার্গ সম্পর্কে অবগত কোনো যোগীই মোহগ্রস্ত হন না। অতএব হে অর্জুন ! তুমি সর্বদা যোগযুক্ত (সমত্বে হিত) হও ৷ 8.27",

    "যোগী (ভক্ত) এই অধ্যায়ে বর্ণিত তত্ত্ব অনুধাবন করে বেদ, যজ্ঞ, তপ ও দানে যেসব গুণাফলের কথা বলা হয়েছে, সেসব পুণ্যফল অতিক্রম করেন এবং আদ্যস্থান পরমাত্মাকে লাভ করেন । 8.28",

    "এইরূপ ওঁ তৎ সৎ - এই ভগবানের নামগুলি উচ্চারণপূর্বক ব্রহ্মবিদ্যা এবং য়োগশাস্ত্রময় শ্রীমদ্ভবদ্গীতোপনিষদরূপ শ্রীকৃষ্ণার্জুনসংবাদে 'অক্ষরব্রহ্ময়োগ' নামক অষ্টম অধ্যায় সমাপ্ত হলো।"
  ],
  "adhyaya-9" : [
    "শ্রীভগবান বললেন—এই অতি গুহ্য জ্ঞান-বিজ্ঞান দোষদৃষ্টিবর্জিত তোমাকে আমি পুনরায় জানাচ্ছি, যা জ্ঞাত হলে তুমি অশুভ থেকে অর্থাৎ জন্ম-মরণরূপ সংসার থেকে মুক্তিলাভ করবে ৷৷ 9.1",

    "এই জ্ঞান-বিজ্ঞান অর্থাৎ সমগ্ররূপ সমস্ত বিদ্যা এবং সমস্ত গোপনীয়তার রাজা অর্থাৎ শ্রেষ্ঠ। এটি অত্যন্ত পবিত্র ও সর্বোৎকৃষ্ট এবং এর ফলও প্রত্যক্ষ। এটি ধর্মময়, অবিনাশী এবং সুকর অর্থাৎ এটি লাভ করা অত্যন্ত সহজ ॥ 9.2",

    "হে পরন্তপ ! এই ধর্মের মহিমার প্রতি শ্রদ্ধাহীন ব্যক্তিরা আমাকে প্রাপ্ত না হয়ে মৃত্যুরূপ সংসারপথে চলতে থাকে অর্থাৎ বারংবার জন্মায় ও মৃত্যুবরণ করে । 9.3",

    "সমস্ত জগতে আমি অব্যক্তস্বরূপে পরিব্যাপ্ত হয়ে আছি। সমস্ত প্রাণী আমাতে অবস্থিত। কিন্তু আমি সে সবে অবহিত নই এবং ওই প্রাণীরাও আমাতে অবস্থান করে না- 9.4",

    "আমার এই ঐশ্বরিক যোগ (সামর্থ্য) দর্শন কর। সকল প্রাণীর উৎপাদক এবং তাদের ধারক ও পোষক হলেও আমার স্বরূপ ওইসব প্রাণীতে অবস্থিত নয় ৷৷ 9.5",

    "সর্বত্র বিচরণশীল মহাবায়ু যেমন নিত্য আকাশেই অবস্থিত, তেময়ই সমস্ত প্রাণী আমাতে অবস্থিত – তুমি এটি মেনে নাও ॥ 9.6",

    "হে কৌন্তেয় ! কল্পগুলি সমাপ্ত হলে মহাপ্রলয়ের সময় সমস্ত প্রাণী আমার প্রকৃতিতে এসে লয় হয়ে যায় এবং কল্পগুলির প্রারম্ভে মহাসর্গের সময় আমি আবার তাদের সৃষ্টি করি ॥৭॥ 9.7",

    "প্রকৃতির পরবশ এই সমগ্র প্রাণীগণকে আমি (কল্পগুলির আদিতে) নিজ প্রকৃতিকে বশীভূত করে বারংবার সৃষ্টি করি ॥ 9.8",

    "হে ধনঞ্জয় ! এই সৃষ্টি রচনাদি কর্মে আমি অনাসক্ত এবং উদাসীনের মতো থাকায় এই কর্মসকল আমাকে আবদ্ধ করে না ॥ 9.9",

    "প্রকৃতি আমার অধ্যক্ষতায় সমস্ত চরাচর জগৎ সৃষ্টি করে। হে কৌন্তেয় ! সেইজন্যই জগৎ বিবিধ প্রকারে পরিবর্তিত হয় ৷৷ 9.10",

    "অবিবেকী (মূৰ্খ) ব্যক্তিগণ সর্বপ্রাণীর মহেশ্বর-স্বরূপ আমার পরমভাব না জেনে আমাকে মনুষ্যদেহধারী (সাধারণ মানুষ মনে করে আমাকে অবজ্ঞা করে থাকে ৷৷ 9.11",

    "যেসকল বিবেকহীন ব্যক্তি আসুরী, রাক্ষসী (হিংসা) এবং মোহিনী (বুদ্ধিভ্রংশকারী) প্রকৃতির আশ্রয় গ্রহণ করে তাদের সব আশা ব্যর্থ, সমস্ত শুভকর্ম এবং সমস্ত জ্ঞান ব্যর্থ অর্থাৎ তাদের আশা, কর্ম এবং জ্ঞান শুভ-ফল প্রদান করে না। 9.12",

    "কিন্তু হে পাৰ্থ ! দৈবী প্রকৃতির আশ্রিত অনন্যচিত্ত মহাত্মাগণ আমাকে সর্বভূতের আদি ও অবিনাশী জেনে আমার ভজনা করে থাকেন ॥ 9.13",

    "নিত্য-(আমাতে) যুক্ত ব্যক্তি দৃঢ়ত্রত হয়ে যত্নপূর্বক সাধন-ভজন এবং ভক্তিপূর্বক কীর্তন করেন এবং আমাকে নমস্কার করতঃ নিরন্তর আমার উপাসনা করেন । 9.14",

    "কোনো কোনো সাধক জ্ঞানযজ্ঞের সাহায্যে একইভাবে (অভেদ-ভাবে) আমার পূজা দ্বারা আমাকে উপাসনা করেন, আবার অন্য কোনো সাধক নিজেকে পৃথক বলে মনে করে চারদিকে মুখবিশিষ্ট আমার বিরাট রূপের অর্থাৎ সংসারকে আমার বিরাট রূপ মনে করে (সেবা সেবক ভাবের দ্বারা) নানাপ্রকারে - আমার উপাসনা করেন ৷৷ 9.15",

    "আমি দ্রুতু, আমি যজ্ঞ, আমি স্বধা, আমি ঔষধ, মন্ত্র আমি, আমিই ঘৃত, আমি অগ্নি এবং যজ্ঞরূপ ক্রিয়াও আমি। 9.16",

    "যা কিছু জ্ঞেয়, পবিত্র, ওঁকার, ঋক-সাম-যজুঃ বেদও আমি। এই সমগ্র জগতের পিতা, ধাতা, মাতা, পিতামহ, 9.17",

    " গতি, ভর্তা, প্রভু, সাক্ষী, নিবাস, আশ্রয়, সুহৃদ, উৎপত্তি, প্রলয়, স্থান, নিধান এবং অবিনাশী বীজও আমি ॥ 9.18",

    "হে অর্জুন! (জগতের হিতার্থে) আমি সূর্যরূপে তাপিত করি, জলকে আকর্ষণ করি এবং পুনরায় সেই জলকে বৃষ্টিরূপে বর্ষণ করি। (অধিক আর কী বলব) আমিই অমৃত ও মৃত্যু এবং সৎ এবং অসৎও আমি ৷৷ 9.19",

    "তিন বেদে কথিত সকাম অনুষ্ঠানকারী এবং সোমরসপানকারী যে-সব নিষ্পাপ ব্যক্তি যজ্ঞ দ্বারা (ইন্দ্রের রূপে) আমার পূজা করে স্বর্গপ্রাপ্তি কামনা করেন, তাঁরা পুণ্যফলস্বরূপ ইন্দ্রলোক লাভ করে সেখানে দেবতাদের দিব্যভোগসমূহ উপভোগ করে থাকেন ৷৷ 9.20",

    "তাঁরা সেই বিশাল স্বর্গসুখ ভোগ করে পুণ্যক্ষয় হলে মৃত্যুলোকে ফিরে আসেন। এইভাবে তিন বেদে কথিত সকাম ধর্মের আশ্রয় গ্রহণকারী কামনা পরবশ ব্যক্তিরা জন্ম-মৃত্যু প্রাপ্ত হন ৷ 9.21",

    "যেসব অনন্যচিত্ত ভক্ত আমার চিন্তা করতঃ উপাসনা করে, আমাতে নিত্যযুক্ত সেই সব ভক্তের যোগক্ষেম (অপ্রাপ্ত বস্তুর প্রাপ্তি এবং প্রাপ্ত বস্তুর রক্ষা) আমি বহন করি ॥ 9.22",

    "হে কুণ্ডীনন্দন ! যে কোনো ভক্ত (মানুষ) শ্রদ্ধাযুক্ত হয়ে অন্য দেবতাদের পূজা করলেও তিনি প্রকৃতপক্ষে আমারই পূজা করেন কিন্তু তা করেন অবিধিপূর্বক অর্থাৎ দেবতাগণকে আমার থেকে পৃথক মনে করেন ৷৷ 9.23",

    "কারণ আমিই সমস্ত যজ্ঞের ভোক্তা এবং প্রভু, কিন্তু এরা তত্ত্বগতভাবে আমাকে জানে না, তাতেই তাদের পতন হয় ৷৷ 9.24",

    "যারা সকামভাবে দেবতাদের পূজা করে (শরীর ত্যাগ করার পর) তারা দেবতাদের (দেবলোক) প্রাপ্ত হয়। পিতৃগণের পূজকেরা পিতৃলোক প্রাপ্ত হয়। ভূত-প্রেতাদির পূজকেরা ভূত-প্রেতলোক প্রাপ্ত হয়। কিন্তু যাঁরা আমার পূজা করেন, তাঁরা আমাকেই প্ৰাপ্ত হন ৷৷ 9.25",

    "যে ভক্ত পত্র, পুষ্প, ফল, জল ইত্যাদি (সাধ্যমত বস্তু) ভক্তিপূর্বক আমাকে সমর্পণ করে, আমাতে তল্লীন চিত্ত, সেই ভক্তের ভক্তিপূর্বক প্রদত্ত উপহার আমি খেয়ে নিই (গ্রহণ করি) ॥ 9.26",

    "হে কৌন্তেয় ! তুমি যা কিছু কর, যা কিছু খাও, যা হোম কর, যা দান কর আর যে তপস্যা কর, তা সমস্তই আমাকে সমর্পণ করে দাও ॥ 9.27",

    "এইভাবে আমাকে সর্বকর্ম সমর্পণ করলে, কর্মবন্ধন থেকে এবং শুভ (বিহিত) ও অশুভ (নিষিদ্ধ) সমস্ত কর্মের ফল থেকে তুমি মুক্ত হবে। এইভাবে নিজেকে-সহ সবকিছু আমাকে অর্পণ করে, সমস্ত কিছু থেকে মুক্ত হয়ে তুমি আমাকেই প্রাপ্ত হবে ॥ 9.28",

    "সকল প্রাণীতেই আমি সমান। তাদের মধ্যে কেউ আমার অপ্রিয়ও নয়, আবার কেউ আমার প্রিয়ও নয়। কিন্তু যাঁরা ভক্তিপূর্বক আমার ভজনা করেন, তাঁরা আমাতে এবং আমি তাঁদের মধ্যে অবস্থান করি ৷ 9.29",

    "যদি অত্যন্ত দুরাচারী কোনো ব্যক্তিও অনন্য ভক্ত হয়ে আমার ভজনা করে, তাহলে তাকে সাধু বলেই মনে করবে। কারণ সে খুব ভালোভাবেই তার সিদ্ধান্ত গ্রহণ করেছে। 9.30",

    "সেই ব্যক্তি তৎক্ষণাৎ ধর্মাত্মা হয়ে যান এবং চির-শান্তি লাভ করেন। হে কৌন্তেয় ! তুমি শপথ নাও যে, আমার ভক্তের কখনই বিনাশ (পতন) হয় না ৷৷ 9.31",

    "হে পৃথানন্দন ! যারা পাপযোনিসম্ভূত অথবা স্ত্রীজাতি, বৈশ্য ও শূদ্র, তারাও সর্বতোভাবে আমার শরণ গ্রহণ করে নিঃসন্দেহে পরমগতি প্রাপ্ত হয় ৷৷ 9.32",

    "পবিত্র আচরণকারী ব্রাহ্মণ এবং ঋষিকল্প ক্ষত্রিয় ভগবানের ভক্ত হলে পরমগতি প্রাপ্ত হবেন, তাতে আর বলার কী আছে ? অতএব তুমি এই অনিত্য ও সুখশূন্য দেহ লাভ করে আমাকে ভজনা কর ৷৷ 9.33",

    "তুমি আমার ভক্ত হও, মদ্‌গতচিত্ত হও, আমার পূজনকারী হও এবং আমাকে নমস্কার কর। এইভাবে আমার সঙ্গে নিজেকে যুক্ত করলে, মৎপরায়ণ হলে তুমি আমাকেই লাভ করবে ॥ 9.34",

    "এইরূপ ওঁ তৎ সৎ - এই ভগবানের নামগুলি উচ্চারণপূর্বক ব্রহ্মবিদ্যা এবং য়োগশাস্ত্রময় শ্রীমদ্ভবদ্গীতোপনিষদরূপ শ্রীকৃষ্ণার্জুনসংবাদে 'রাজবিদ্যারাজগুহ্যয়োগ' নামক নবম অধ্যায় সমাপ্ত হলো।"
  ],
  "adhyaya-10" : [
    "শ্রীভগবান বললেন – হে মহাবাহো অর্জুন! আমার এই শ্রেষ্ঠ বাক্যগুলি তুমি পুনরায় শোনো, আমি তোমার হিতার্থে এগুলি জানাচ্ছি; কারণ তুমি আমার প্রতি অতিশয় শ্রদ্ধা-প্রেমসম্পন্ন ॥ 10.1",

    "আমার উৎপত্তির বিষয়টি দেবগণ বা মহর্ষিগণ কেউই জানেন না, কারণ সর্বপ্রকারেই আমি দেবতা ও মহর্ষিদের আদি কারণ ॥ 10.2",

    "যাঁরা আমাকে অজ, অনাদি এবং সর্বলোকের মহেশ্বর বলে জানেন অর্থাৎ দৃঢ়তার সঙ্গে (নিঃসন্দেহে) মেনে নেন, মানুষের মধ্যে তাঁরাই জ্ঞানী এবং সমস্ত পাপ হতে তাঁরা মুক্ত হন । 10.3",

    "বুদ্ধি, জ্ঞান, অসম্মোহ, ক্ষমা, সত্য, দম, শম, সুখ, দুঃখ, ভব (উৎপত্তি), অভাব (লয়), ভয়, অভয় 10.4",

    "অহিংসা, সমতা, তুষ্টি, তপ, দান, যশ এবং অপযশ—প্রাণীদের এইপ্রকার ভিন্ন ভিন্ন (কুড়ি প্রকারের) ভাব আমার থেকেই উৎপন্ন হয় । 10.5",

    "সপ্তমহর্ষি এবং তাঁদের পূর্ববর্তী চার সনকাদি এবং চতুর্দশ মনু এঁরা সকলেই আমার মন হতে উৎপন্ন এবং আমার প্রতি ভাব (শ্রদ্ধা-ভক্তি) সম্পন্ন, যাঁদের থেকে জগতের এই সমস্ত প্রজা উৎপন্ন হয়েছে৷৷ 10.6",

    "যে ব্যক্তি আমার এই বিভূতি এবং যোগৈশ্বর্য তত্ত্বত জানেন অর্থাৎ দৃঢ়ভাবে মেনে নেন, তিনি অবিচলিত ভক্তিযোগে যুক্ত হন; এতে বিন্দুমাত্র সংশয় নেই ॥ 10.7",

    "আমি জগতমাত্রেরই প্রভব (মূলকারণ), আমা হতেই এই জগৎ-সংসার প্রবৃত্ত হচ্ছে অর্থাৎ প্রবর্তিত হচ্ছে– বুদ্ধিমান ভক্তগণ আমাকে এইরূপ জেনে শ্রদ্ধা-ভক্তি সহকারে আমাকেই ভজনা করে থাকে সর্বভাবে আমারই শরণ গ্রহণ করে॥ 10.8",

    "মদ্‌গতচিত্ত, মদ্‌গতপ্রাণ ভক্তগণ নিজেদের মধ্যে আমার গুণ, প্রভাব আলোচনা করে এতেই সর্বদা সন্তুষ্ট থাকেন এবং আমার সঙ্গে প্রেমবন্ধনে যুক্ত হয়ে থাকেন৷৷ 10.9",

    "আমাতে সতত আসক্ত, প্রেমপূর্বক আমার ভজনাকারী ভক্তদের আমি সেই বুদ্ধিযোগ প্রদান করি, যার দ্বারা তাঁরা আমাকে প্রাপ্ত হন। 10.10",

    "সেই ভক্তগণের প্রতি কৃপা করার জন্যই তাদের স্বরূপতার (স্ব-ভাবের) মধ্যে অবস্থিত আমি, তাদের অজ্ঞানজনিত অন্ধকার দেদীপ্যমান জ্ঞানরূপ প্রদীপের দ্বারা সর্বতোভাবে নাশ করে থাকি৷৷ 10.11",

    "অর্জুন বললেন–পরমব্রহ্ম, পরমধাম এবং মহাপবিত্র আপনিই। আপনি শাশ্বত, দিব্যপুরুষ, আদিদেব, জন্মরহিত এবং সর্বব্যাপী বিভু– 10.12",

    "এইরূপে সকল ঋষি, দেবর্ষি নারদ, অসিত, দেবল এবং ব্যাসদেব বলে থাকেন এবং আপনি নিজেও আমাকে বলেছেন ৷৷ 10.13",

    "হে কেশব ! আপনি আমাকে যা বলেছেন তা সব আমি সত্য বলে মানি। হে ভগবান! দেবতা বা দানব কেউই আপনার প্রকট হওয়ার তাৎপর্য জানে না। 10.14",

    "হে ভূতভাবন ! হে ভূতেশ! হে দেবদেব! হে জগৎপতে! হে পুরুষোত্তম্ ! আপনি স্বয়ংই নিজের দ্বারা নিজেকে জানেন। 10.15",

    "অতএব যেসব বিভূতি দ্বারা আপনি সর্বলোকে ব্যাপ্তিস্বরূপ হয়ে অবস্থান করছেন, সেইসকল দিব্য বিভৃতি সম্পূর্ণভাবে আপনিই বর্ণনা করতে সক্ষম৷৷ 10.16",

    "হে যোগী ! সর্বদা সর্বতোভাবে চিন্তারত আমি আপনাকে কেমন করে জানব ? এবং হে ভগবান ! আপনি কোন্ কোন্ ভাবের মাধ্যমে আমার দ্বারা চিন্তনীয় হতে পারেন ? অর্থাৎ সর্বাঙ্গীণ কোন্ কোন্ সাহায্যে আপনাকে আমি চিন্তা করব ? 10.17",

    "হে জনার্দন ! আপনি আপনার যোগ (সামর্থ্য) এবং বিভূতিগুলি বিস্তারিতভাবে পুনরায় বলুন ; কারণ আপনার এই অমৃতময় বচন শুনে আমার তৃপ্তি হচ্ছে না৷ 10.18",

    "শ্রীভগবান বললেন—আচ্ছা, ঠিক আছে। আমি আমার প্রধান দিব্য বিভূতিগুলি তোমার জন্য সংক্ষেপে বলছি। কারণ হে কুরুশ্রেষ্ঠ ! আমার বিস্তারিত বিভৃতির কোনো অন্ত নেই৷৷ 10.19",

    "হে নিদ্ৰাজয়ী অর্জুন ! সকল প্রাণীর আদি, মধ্য এবং অন্তে আমিই অবস্থিত এবং তাদের হৃদয়ে (অন্তঃকরণে ) আত্মারূপেও আমিই অবস্থান করছি। 10.20",

    "আমি অদিতির পুত্রদের মধ্যে বিষ্ণু (বামন), জ্যোতিষ্মান্ বস্তুর মধ্যে আমি কিরণমালী সূর্য। আমিই  মরুৎদের মধ্যে তেজ এবং নক্ষত্রদের মধ্যে চন্দ্ৰ ৷৷ 10.21",

    "বেদাদির মধ্যে আমি সামবেদ, দেবতাদের মধ্যে আমি ইন্দ্র, ইন্দ্রিয়গুলির মধ্যে আমি মন এবং প্রাণীদের মধ্যে আমি চেতনা ৷ 10.22",

    "একাদশ রুদ্রের মধ্যে আমি শঙ্কর ও যক্ষ-রাক্ষসদের মধ্যে কুবের, অষ্ট বসুর মধ্যে আমি পাবক বা অগ্নি এবং চূড়াযুক্ত পর্বতের মধ্যে আমি সুমেরু পৰ্বত ৷৷ 10.23",

    "হে পার্থ ! পুরোহিতগণের মধ্যে আমাকে প্রধান বৃহস্পতি বলে জেনো, সেনানায়কদের মধ্যে আমি স্কন্দ (কার্তিক) এবং জলাশয়সমূহের মধ্যে আমি সাগৱ৷৷ 10.24",

    "মহর্ষিদের মধ্যে আমি ভৃগু, বাণীর (শব্দের) মধ্যে আমি একাক্ষর ওঁকার অর্থাৎ প্রণব। সমস্ত যজ্ঞের মধ্যে আমি জপযজ্ঞ এবং স্থাবর পদার্থের মধ্যে আমি হিমালয় ৷৷ 10.25",

    "সমস্ত বৃক্ষের মধ্যে আমি অশ্বত্থ, দেবর্ষিগণের মধ্যে নারদ, গন্ধবর্গণের মধ্যে চিত্ররথ এবং সিদ্ধ ব্যক্তিগণের মধ্যে আমি কপিলমুনি ৷৷ 10.26",

    "অশ্বগণের মধ্যে অমৃতমন্থনকালে প্রকটিত হওয়া উচ্চৈঃশ্রবা নামক অশ্ব, শ্রেষ্ঠ হাতিদের মধ্যে ঐরাবত নামক হাতি এবং মানুষদের মধ্যে রাজাকে আমারই বিভৃতি বলে জানবে। 10.27",

    "অস্ত্রসমূহের মধ্যে আমি বজ্র এবং ধেনুগণের মধ্যে আমিই কামধেনু। আমি সন্তান উৎপত্তির হেতু কন্দর্প এবং সর্পগুণের মধ্যে বাসুকি ॥ 10.28",

    "নাগগণের মধ্যে অনন্ত (শেষনাগ) এবং জলচর প্রাণীদের মধ্যে আমি জলাধিপতি বরুণ। পিতৃগণের মধ্যে আমি অর্যমা এবং শাসনকারীদের মধ্যে যমরাজ। 10.29",

    "দৈত্যগণের মধ্যে আমি প্রহ্লাদ এবং গণনাকারীদের মধ্যে কাল। পশুগণের মধ্যে আমি সিংহ ও পক্ষীগণের মধ্যে গরুড় ৷৷ 10.30",

    "পবিত্রকারীদের মধ্যে আমি বায়ু এবং শস্ত্রধারীগণের মধ্যে আমি রাম, জলচর জীবের মধ্যে আমি মকর এবং স্রোতস্বতী নদীসমূহের মধ্যে আমি গঙ্গা ৷৷ 10.31",

    "হে অর্জুন! সমস্ত সর্গের আদি, মধ্য এবং অন্তে আমিই বিরাজমান। বিদ্যাসমূহের মধ্যে আমি অধ্যায় (ব্রহ্ম) বিদ্যা এবং পরস্পর শাস্ত্রার্থকারীর (তার্কিকগণের) মধ্যে আমি হলাম বাদ৷৷ 10.32",

    "অক্ষর সমূহের মধ্যে আমি অকার এবং সমাস সমূহের মধ্যে দ্বন্দ্ব সমাস আমি। আমিই অক্ষয় কাল অর্থাৎ কালের মহাকাল এবং সর্বদিকে মুখবিশিষ্ট ধাতা (পালন-পোষণকারী) ও আমি৷৷ 10.33",

    "সর্বসংহারকারী মৃত্যু এবং উৎপন্ন হওয়া প্রাণীদের উদ্ভবম্বরূপ আমিই ; নারীজাতির মধ্যে আমিই কীর্তি, শ্রী, বাক্, স্মৃতি, মেধা, ধৃতি ও ক্ষমা ৷৷ 10.34",

    "সুরধর্মী শ্রুতিগুলির মধ্যে বৃহৎসাম এবং বৈদিক ছন্দগুলির মধ্যে গায়ত্রী ছন্দ আমিই। বৎসরের দ্বাদশ মাসের মধ্যে মার্গশীর্ষ (অগ্রহায়ণ) এবং ছটি ঋতুর মধ্যে বসন্ত ঋতুও আমি ৷৷ 10.35",

    "ছলনাকারীগণের মধ্যে জুয়া এবং তেজস্বীগণের মধ্যে তেজ আমি, বিজয়ী পুরুষদের জয়, উদ্যোগকারীদের উদ্যম এবং সাত্ত্বিক ভাবও আমি৷৷ 10.36",

    "বৃষ্ণি বংশীয়দের মধ্যে আমি বাসুদেব, পাণ্ডবগণের মধ্যে আমি ধনঞ্জয়, মুনিগণের মধ্যে বেদব্যাস এবং কবিদের মধ্যে শুক্রাচার্যও আমি ॥ 10.37",

    "দমনকারীদের মধ্যে দগুনীতি এবং জয়েচ্ছু ব্যক্তিগণের মধ্যে নীতি আমি। আমি গোপনীয় অর্থাৎ গুপ্ত রাখার যোগ্য ভাবসকলের মধ্যে মৌন এবং জ্ঞানীদিগের জ্ঞান ৷৷ 10.38",

    "হে অর্জুন! সর্বপ্রাণীর যা বীজ, সেই বীজ আমিই; কারণ আমা ব্যতীত চরাচরে কোনো প্রাণী নেই অর্থাৎ চরাচরে সবই আমি ৷৷ 10.39",

    "হে পরন্তপ ! আমার দিব্য বিভূতিসমূহের কোনো অন্ত নেই। আমি তোমাকে আমার যা কিছু বিভূতি বিস্তার বর্ণনা করেছি, তা তো শুধু বিভূতিগুলির সংক্ষেপ ॥ 10.40",

    "যে যে বস্তু (প্রাণী, পদার্থ প্রভৃতি) ঐশ্বর্যযুক্ত, শোভাযুক্ত এবং বল-সম্পন্ন, সেসবই তুমি আমারই তেজ (যোগ অর্থাৎ সামর্থ্যোর) অংশ হতে উৎপন্ন বলে জানবে৷ 10.41",

    "অথবা হে অর্জুন! তোমার এত বহুবিধ কথা জানবার প্রয়োজন কী ? আমি নিজের একাংশ মাত্র দিয়ে জগৎকে পরিব্যাপ্ত করে অবস্থিত আছি অর্থাৎ আমার মাত্র একাংশই অনন্ত ॥ 10.42",

    "এইরূপ ওঁ তৎ সৎ - এই ভগবানের নামগুলি উচ্চারণপূর্বক ব্রহ্মবিদ্যা এবং য়োগশাস্ত্রময় শ্রীমদ্ভবদ্গীতোপনিষদরূপ শ্রীকৃষ্ণার্জুনসংবাদে 'বিভূতিয়োগ' নামক দশম অধ্যায় সমাপ্ত হলো।"
  ],
  "adhyaya-11" : [
    "অর্জুন বললেন—হে ভগবান ! আমার প্রতি অনুগ্রহ করে আপনি যে পরম গুহ্য অধ্যাত্মতত্ত্ব বললেন, তাতে আমার মোহ দূর হয়েছে। 11.1",

    "কারণ হে কমললোচন ! আমি আপনার কাছে ভূতগণের উৎপত্তি ও বিনাশ সম্বন্ধে বিস্তারিতভাবে শুনেছি এবং আপনার অক্ষয় মাহাত্ম্যও জেনেছি। 11.2",

    "হে পরমেশ্বর ! আপনি যে আত্মতত্ত্ব বলছেন, তা যথার্থ ; কিন্তু হে পুরুষোত্তম ! আমি আপনার জ্ঞান, ঐশ্বর্য, শক্তি, বল, বীর্য এবং তেজঃসমন্বিত ঈশ্বরীয় বিশ্বরূপ দেখতে ইচ্ছা করি। 11.3",

    "হে প্রভু ! আমাকে যদি আপনার সেই বিশ্বরূপ দেখার যোগ্য বলে মনে করেন, তা হলে হে যোগেশ্বর ! আমাকে আপনার সেই অবিনাশী স্বরূপ দেখান। 11.4",

    "ভগবান শ্রীকৃষ্ণ বললেন—হে পার্থ ! তুমি আমার বহুবিধ এবং নানা বর্ণ ও নানা আকৃতিবিশিষ্ট শত শত এবং সহস্র সহস্র দিব্যরূপ দর্শন করো। 11.5",

    "হে ভরতবংশীয় অর্জুন ! তুমি আমার মধ্যে দ্বাদশ আদিত্য (অদিতির পুত্রদের), অষ্ট বসু, একাদশ রুদ্র, অশ্বিনীকুমারদ্বয় ও ঊনপঞ্চাশ মরুদ্গণ (বায়ু)কে দর্শন করো এবং পূর্বে যা কখনও দেখোনি এরূপ বহু আশ্চর্যময় রূপ দর্শন করো। 11.6",

    "হে অর্জুন ! আমার এই বিরাট শরীরে একস্থানে অবস্থিত চরাচরসহ সমগ্র জগৎ অবলোকন করো এবং আরও যা কিছু তোমার দেখবার ইচ্ছা তা-ও দেখো। 11.7",

    "কিন্তু তুমি নিজ চর্ম চক্ষুর দ্বারা আমার এই বিশ্বরূপ দেখতে সমর্থ হবে না ; সেইজন্য আমি তোমাকে দিব্য চক্ষু প্রদান করছি, সেই চক্ষু দ্বারা তুমি আমার ঈশ্বরীয় যোগশক্তি দর্শন করো। 11.8",

    "সঞ্জয় বললেন—হে রাজন্ ! মহাযোগেশ্বর এবং সর্বপাপনাশকারী ভগবান শ্রীকৃষ্ণ এই কথা বলে অর্জুনকে নিজের পরম ঐশ্বর্যযুক্ত দিব্যরূপ দেখালেন। 11.9",

    "সেই বিশ্বরূপ অনেক মুখ ও অনেক নেত্রযুক্ত, অসংখ্য অদ্ভুত আকৃতি বিশিষ্ট, বহু দিব্যভূষণাদি পরিহিত এবং বহু দিব্য আয়ুধে সজ্জিত, দিব্য মাল্য এবং দিব্য বস্ত্রে ভূষিত, দিব্যগন্ধ অনুলিপ্ত, সর্বাশ্চর্যযুক্ত, অনন্ত ও সর্বতোমুখ—সেই বিশ্বরূপ পরমদেব পরমেশ্বরকে অর্জুন দর্শন করলেন। 10.10 - 11.11",

    "সেই বিশ্বরূপ অনেক মুখ ও অনেক নেত্রযুক্ত, অসংখ্য অদ্ভুত আকৃতি বিশিষ্ট, বহু দিব্যভূষণাদি পরিহিত এবং বহু দিব্য আয়ুধে সজ্জিত, দিব্য মাল্য এবং দিব্য বস্ত্রে ভূষিত, দিব্যগন্ধ অনুলিপ্ত, সর্বাশ্চর্যযুক্ত, অনন্ত ও সর্বতোমুখ—সেই বিশ্বরূপ পরমদেব পরমেশ্বরকে অর্জুন দর্শন করলেন। 10.10 - 11.11",

    "সহস্র সূর্য একসঙ্গে আকাশে উদিত হলে যে প্রকাশ উৎপন্ন হয়, সেই প্রকাশও বিশ্বরূপ পরমাত্মার প্রকাশের কিঞ্চিৎ তুল্য হতে পারে। 11.12",

    "পাণ্ডুপুত্র অর্জুন সেই নানা ভাগে বিভক্ত বিশ্বব্রহ্মাণ্ডকে দেবাদিদেব ভগবান একস্থানে অবস্থিত দেখলেন। 11.13",

    "এরপর বিস্ময়াবিষ্ট রোমাঞ্চিত অর্জুন বিশ্বরূপধারী ভগবানকে শ্রীকৃষ্ণের শরীরে শ্রদ্ধা-ভক্তিসহ নতমস্তকে প্রণাম করে করজোড়ে বললেন। 11.14",

    "অর্জুন বললেন—হে দেব ! আপনার শরীরে আমি সমস্ত দেবতা এবং বহুবিধ ভূত সমুদয়, কমলাসনে অধিষ্ঠিত সৃষ্টিকর্তা ব্রহ্মাকে, মহাদেবকে এবং সমস্ত ঋষি ও দিব্য সর্পগণকে দেখতে পাচ্ছি। 11.15",

    "হে বিশ্বপতি ! আপনার বহু বাহু, বহু উদর, বহু মুখ এবং বহু নেত্র বিশিষ্ট এবং সব দিকেই অনন্তরূপযুক্ত বিরাট মূর্তি দেখছি। হে বিশ্বরূপ ! আমি আপনার অন্ত, মধ্য এবং আদি দেখতে পাচ্ছি না। 11.16",

    "আপনাকে আমি কিরীটি, গদা ও চক্রধারী, সর্বত্র দীপ্তিমান, তেজঃপুঞ্জরূপ, প্রজ্বলিত অগ্নি ও সূর্যের ন্যায় জ্যোতিসম্পন্ন, দুর্নিরীক্ষ্য এবং সর্বত্র অপ্রমেয়স্বরূপ দেখছি। 11.17",

    "আপনি পরম ব্রহ্ম ও একমাত্র জ্ঞাতব্য। আপনি জগতের পরম আশ্রয় ও সনাতন ধর্মের রক্ষক, আপনিই অবিনাশী সনাতন পুরুষ, এই আমার মত। 11.18",

    "আপনাকে আমি আদি, মধ্য ও অন্তহীনরূপে দেখছি, আপনি অনন্ত শক্তিসম্পন্ন ও অসংখ্য বাহুবিশিষ্ট, চন্দ্র ও সূর্য আপনার নেত্র, মুখ প্রজ্বলিত অগ্নির ন্যায় এবং স্বীয় তেজে এই বিশ্বকে আপনি সন্তপ্ত করছেন। 11.19",

    "হে মহাত্মন্ ! স্বর্গ ও মর্ত্যের মধ্যবর্তী অন্তরীক্ষ এবং সর্বদিক আপনি পরিব্যাপ্ত করে আছেন। আপনার এই অলৌকিক ও উগ্র রূপ দেখে ত্রিলোক অত্যন্ত ভীত হচ্ছে। 11.20",

    "ওই দেবগণ আপনাতেই প্রবিষ্ট হচ্ছেন । কেউ কেউ ভীত হয়ে করজোড়ে আপনার গুণগান করছেন এবং মহর্ষি ও সিদ্ধগণ ‘জগতের কল্যাণ হোক' বলে বহু স্তুতিবাক্য দ্বারা আপনার স্তব করছেন। 11.21",

    "একাদশ রুদ্র ও দ্বাদশ আদিত্য, অষ্ট বসু, সাধ্যগণ, বিশ্বদেবগণ, অশ্বিনীকুমারদ্বয়, মরুদ্গণ, পিতৃগণ এবং গন্ধর্ব, যক্ষ, রাক্ষস ও সিদ্ধগণ সকলেই বিস্মিত হয়ে আপনাকে দেখছেন। 11.22",

    "হে মহাবাহো ! আপনার বহু মুখ, বহু চক্ষু, বহু বাহু, বহু উরু, বহু চরণ, বহু উদর এবং ভয়ানক দন্তযুক্ত বিকট রূপ দেখে সমস্ত লোক অত্যন্ত ভীত হচ্ছে এবং আমিও অতিশয় ভীত হচ্ছি। 11.23",

    "কারণ হে বিষ্ণো ! আকাশস্পর্শকারী, তেজোময়, নানাবর্ণবিশিষ্ট, বিস্ফারিত মুখমণ্ডল তথা জাজ্বল্যমান বিশাল চক্ষুবিশিষ্ট আপনাকে দেখে আমি ভীত হচ্ছি এবং ধৈর্য ও শান্তি পাচ্ছি না। 11.24",

    "বিকট দন্তদ্বারা বিকৃত এবং প্রলয়াগ্নিসম প্রজ্বলিত আপনার মুখ দেখে আমি দিশাহারা হচ্ছি, শান্তি পাচ্ছি। না। হে দেবেশ ! হে জগন্নিবাস ! আপনি আমার প্রতি প্রসন্ন হোন। 11.25",

    "রাজন্যবর্গসহ ঐসব ধৃতরাষ্ট্রের পুত্রগণ এবং পিতামহ ভীষ্ম, দ্রোণাচার্য, কর্ণ এবং আমাদের পক্ষের প্রধান যোদ্ধাগণসহ সকলেই আপনার দংষ্ট্রাকরাল ভীষণ মুখগহ্বরে সবেগে প্রবেশ করছেন। কারও চূর্ণিত মস্তক খণ্ড আপনার দাঁতের ফাঁকে ফাঁকে লেগে রয়েছে দেখছি। 11.26 - 11.27",

    "রাজন্যবর্গসহ ঐসব ধৃতরাষ্ট্রের পুত্রগণ এবং পিতামহ ভীষ্ম, দ্রোণাচার্য, কর্ণ এবং আমাদের পক্ষের প্রধান যোদ্ধাগণসহ সকলেই আপনার দংষ্ট্রাকরাল ভীষণ মুখগহ্বরে সবেগে প্রবেশ করছেন। কারও চূর্ণিত মস্তক খণ্ড আপনার দাঁতের ফাঁকে ফাঁকে লেগে রয়েছে দেখছি। 11.26 - 11.27",

    "যেমন নদীসমূহের বহু জলপ্রবাহ সমুদ্রাভিমুখে যায় অর্থাৎ দ্রুতবেগে সমুদ্রে প্রবেশ করে, তেমনই এই বীরপুরুষগণও আপনার প্রজ্বলিত মুখবিবরে প্রবেশ করছেন। 11.28",

    "যেমন পতঙ্গগণ অতিবেগে ধাবিত হয়ে মরণের জন্য জ্বলন্ত অগ্নিতে প্রবেশ করে, তেমনি এইসব লোকও মৃত্যুর জন্যই অতিবেগে ধাবমান হয়ে আপনার মুখগহ্বরে প্রবেশ করছেন। 11.29",

    "আপনি সকল লোককে জ্বলন্ত মুখবিবরে গ্রাস করে সব দিকে জিহ্বা দ্বারা লেহন করছেন। হে বিষ্ণো ! আপনার তীব্র প্রভা সমস্ত জগৎকে তেজোরাশি দ্বারা পূর্ণ করে সন্তপ্ত করছে। 11.30",

    "আপনি আমাকে বলুন, এই উগ্ররূপে আপনি কে ? হে দেবশ্রেষ্ঠ ! আপনাকে আমি প্রণাম করি। আপনি প্রসন্ন হোন। আদিপুরুষ আপনাকে আমি বিশেষভাবে জানতে ইচ্ছা করি, কারণ আপনার কী প্রবৃত্তি তা আমি জানি না। 11.31",

    "ভগবান শ্রীকৃষ্ণ বললেন—আমি লোকনাশকারক প্রবৃদ্ধ কাল। এখন এই লোক সংহার করতে প্রবৃত্ত হয়েছি। তুমি যদি যুদ্ধ না-ও করো, প্রতিপক্ষের যোদ্ধাগণ কেউই জীবিত থাকবে না অর্থাৎ এঁদের বিনাশ হবেই। 11.32",

    "অতএব তুমি যুদ্ধার্থে উত্থিত হও ও যশ লাভ করো এবং শত্রু জয় করে ধন-ধান্যসম্পন্ন রাজ্য ভোগ করো। এই যোদ্ধাদের আমি পূর্বেই বধ করেছি। হে সব্যসাচী ! তুমি কেবল নিমিত্তমাত্র হও। 11.33",

    "ভীষ্ম, দ্রোণ, কর্ণ, জয়দ্রথ এবং অন্যান্য বীর যোদ্ধাদের আমি পূর্বেই নিহত করেছি, সেই মৃতদেরই তুমি বধ করো। ভয় করো না। তুমি নিশ্চয়ই যুদ্ধে শত্রুদের জয় করবে। অতএব যুদ্ধ করো । 11.34",

    "সঞ্জয় বললেন—কেশবের এই কথা শুনে মুকুটধারী অর্জুন কম্পিত দেহে হাত জোড় করে শ্রীকৃষ্ণকে প্রণাম করলেন এবং অত্যন্ত ভীত হয়ে পুনরায় প্রণাম করে গদ্‌গদ স্বরে বললেন। 11.35",

    "অর্জুন বললেন—হে হৃষীকেশ ! আপনার মাহাত্ম্য কীর্তনে সমস্ত জগৎ আনন্দিত ও আপনার প্রতি অনুরক্ত হচ্ছে। ভীতসন্ত্রস্ত হয়ে রাক্ষসেরা নানাদিকে ধাবিত হচ্ছে ও সিদ্ধগণ আপনাকে নমস্কার জানাচ্ছেন। এই সমস্ত খুবই যুক্তিযুক্ত। 11.36",

    "আপনাকে কেনই বা সকলে প্রণাম করবে না ? হে অনন্ত ! হে দেবেশ ! হে জগন্নিবাস ! যা সৎ, যা অ-সৎ তাও আপনি এবং এই উভয়ের অতীত যে সচ্চিদানন্দঘন ব্রহ্ম, তাও আপনি। 11.37",

    "আপনি আদিদেব ও অনাদি পুরুষ, আপনি এই জগতের পরম আশ্রয় এবং জ্ঞাতা ও জ্ঞাতব্য, আপনি পরমধাম। হে অনন্তরূপ ! আপনিই জগৎকে পরিব্যাপ্ত করে আছেন। 11.38",

    "আপনি বায়ু, যম, অগ্নি, বরুণ, চন্দ্র ; আপনি প্রজাপতি ব্রহ্মা এবং ব্রহ্মারও পিতা। আপনাকে সহস্রবার প্রণাম করি। আপনাকে পুনরায় প্রণাম করি। আপনাকে বারবার প্রণাম করি। 11.39",

    "হে অনন্ত সামর্থ্যসম্পন্ন ! আপনাকে সম্মুখে প্রণাম, পশ্চাতে প্রণাম ! সর্বদিক হতে প্রণাম। হে সর্বাত্মনু ! অসীম পরাক্রমশালী আপনি সমগ্র জগৎ পরিব্যাপ্ত করে আছেন, অতএব আপনিই সর্বস্বরূপ। 11.40",

    "আপনার এই মাহাত্ম্য না জেনে আপনাকে সখা মনে করে প্রণয়বশতঃ বা প্রমাদবশতঃ আমি ‘হে কৃষ্ণ !” “হে যাদব !” “হে সখে !’—এই বলে অবুঝের মতো আপনাকে সম্বোধন করেছি। হে অচ্যুত, উপহাসচ্ছলে আহার, বিহার, আসন এবং শয়নের সময় একাকী বা অন্য সখাদের সমক্ষে আপনাকে যে অমর্যাদা করেছি, হে অপ্রমেয় ! সেইসব অপরাধের জন্য আমি আপনার কাছে ক্ষমা ভিক্ষা করছি। 11.41 - 11.42",

    "আপনার এই মাহাত্ম্য না জেনে আপনাকে সখা মনে করে প্রণয়বশতঃ বা প্রমাদবশতঃ আমি ‘হে কৃষ্ণ !” “হে যাদব !” “হে সখে !’—এই বলে অবুঝের মতো আপনাকে সম্বোধন করেছি। হে অচ্যুত, উপহাসচ্ছলে আহার, বিহার, আসন এবং শয়নের সময় একাকী বা অন্য সখাদের সমক্ষে আপনাকে যে অমর্যাদা করেছি, হে অপ্রমেয় ! সেইসব অপরাধের জন্য আমি আপনার কাছে ক্ষমা ভিক্ষা করছি। 11.41 - 11.42",

    "আপনি এই জগৎ চরাচরের পিতা, পূজ্য, গুরুরও গুরু। হে অনুপম প্রভাবশালী ! ত্রিলোকে আপনার সমকক্ষ আর কেউ নেই, তাহলে আপনার হতে শ্রেষ্ঠ আর কে হতে পারে ? 11.43",

    "হে প্রভো ! সেইজন্য আপনাকে দণ্ডবৎ প্রণাম করে, সর্বতোভাবে পূজনীয় ঈশ্বরস্বরূপ আপনার প্রসন্নতা আমি প্রার্থনা করছি। হে দেব ! পিতা যেমন পুত্রের, সখা যেমন সখার এবং পতি যেমন তাঁর প্রিয়তমা পত্নীর অপরাধ ক্ষমা করেন—তেমনই আপনিও আমার অপরাধ ক্ষমা করবেন। 11.44",

    "যা পূর্বে কখনও আমি দর্শন করিনি আপনার সেই বিশ্বরূপ দেখে আমি হর্ষান্বিত হচ্ছি, আবার মন ভয়ে ব্যথিত হচ্ছে। অতএব আপনি আপনার সেই চতুর্ভুজ বিষ্ণুরূপই আমাকে দেখান। হে দেবেশ ! হে জগন্নিবাস ! আপনি প্রসন্ন হোন। 11.45",

    "আমি পূর্বের মতো আপনার মুকুটধারী এবং গদা চক্রধারী রূপ দর্শন করতে চাই। হে বিশ্বমূর্তি ! হে সহস্রবাহো ! এখন আপনি সেই চতুর্ভুজরূপ ধারণ করুন। 11.46",

    "ভগবান শ্রীকৃষ্ণ বললেন—হে অর্জুন ! তোমার প্রতি প্রসন্ন হয়ে স্বীয় ঈশ্বরীয় যোগ প্রভাবে আমার তেজোময়, সকলের আদি-অন্তহীন ও উত্তম বিশ্বরূপ তোমাকে দেখিয়েছি, যা তুমি ছাড়া আগে আর কেউ দেখেনি । 11.47",

    "হে অর্জুন ! ইহজগতে আমার এই বিশ্বরূপ বেদ পাঠ বা যজ্ঞের দ্বারা, দান বা ক্রিয়াদির দ্বারা অথবা কঠোর তপস্যার দ্বারাও কেউ দেখতে সক্ষম নয়। একমাত্র তুমিই তা দর্শন করলে। 11.48",

    "আমার এই ভয়ঙ্কর বিশ্বরূপ দেখে তুমি ভীত ও ব্যথিত হয়ো না, তোমার মূঢ়ভাবও যেন না হয় ; তুমি ভয় ত্যাগ করে প্রসন্নচিত্তে আমার সেই শঙ্খ চক্র-গদা-পদ্ম সমন্বিত চতুর্ভুজরূপ পুনরায় দর্শন করো। 11.49",

    "সঞ্জয় বললেন—ভগবান বাসুদেব অর্জুনকে এই কথা বলে পুনরায় নিজ সেই চতুর্ভুজ রূপ দেখালেন এবং এর পর শ্রীকৃষ্ণ সৌম্যমূর্তি ধারণ করে ভীত অর্জুনকে আশ্বস্ত করলেন। 11.50",

    "অর্জুন বললেন—হে জনার্দন ! আপনার এই সৌম্য মনুষ্যরূপ দেখে এখন আমি প্রসন্নচিত্ত ও প্রকৃতিস্থ হলাম এবং নিজ স্বাভাবিক স্থিতি ফিরে পেলাম। 11.51",

    "ভগবান শ্রীকৃষ্ণ বললেন—তুমি আমার যে চতুর্ভুজ রূপ দর্শন করেছ তার দর্শন পাওয়া বড়ই দুর্লভ। দেবতাগণও সর্বদা এই রূপের দর্শনাকাঙ্ক্ষী। 11.52",

    "আমার যে বিশ্বরূপের দর্শন তুমি করেছ তা বেদাধ্যয়ন, তপস্যা, দান বা যজ্ঞের দ্বারাও সম্ভব নয়। 11.53",

    "হে পরন্তপ অর্জুন ! একনিষ্ঠ ভক্তি দ্বারাই এই প্রকার আমাকে জানতে ও স্বরূপতঃ প্রত্যক্ষ করতে এবং আমাতে প্রবিষ্ট হতে অর্থাৎ একাত্বরূপে লাভ করতে ভক্তগণ সমর্থ হয়, অন্য উপায়ে নয়। 11.54",

    "হে অর্জুন ! যে-ব্যক্তি আমারই জন্য সমস্ত কর্ম করেন, আমার পরায়ণ হন, আমার ভক্ত হন, আসক্তিশূন্য হন এবং সমস্ত প্রাণীতে বৈরভাব শূন্য হন,—সেই অনন্য ভক্তিযুক্ত পুরুষ আমাকেই প্রাপ্ত হন। 11.55",

    "11 - এইরূপ ওঁ তৎ সৎ - এই ভগবানের নামগুলি উচ্চারণপূর্বক ব্রহ্মবিদ্যা এবং য়োগশাস্ত্রময় শ্রীমদ্ভবদ্গীতোপনিষদরূপ শ্রীকৃষ্ণার্জুনসংবাদে 'বিশ্বরূপদর্শনয়োগ' নামক একাদশ অধ্যায় সমাপ্ত হলো।"
  ],
  "adhyaya-12" : [
    "যেসকল ভক্ত (একাদশ অধ্যায়ের পঞ্চান্নতম শ্লোক অনুসারে) নিবিষ্ট চিত্তে নিরন্তর আপনার (সগুণ ভগবানের) উপাসনা করেন এবং যাঁরা অবিনাশী নিরাকারের উপাসনা করেন, তাঁদের মধ্যে শ্রেষ্ঠ যোগবেত্তা কে? ॥ 12.1",

    "শ্রীভগবান বললেন—আমাতে মন নিবিষ্ট করে নিত্য-নিরন্তর আমাতে যুক্ত হয়ে যেসব ভক্ত পরম শ্রদ্ধা সহকারে আমার (সগুণ-সাকারের) উপাসনা করেন আমার মতে তাঁরাই সর্বশ্রেষ্ঠ যোগী ॥ 12.2",

    "যাঁরা সম্পূর্ণরূপে নিজ ইন্দ্রিয় বশীভূত করে অচিন্ত্য, সর্বত্র পূর্ণভাবে অবস্থিত, অনির্দেশ্য, কূটস্থ, অচল, ধ্রুব, 12.3",

    "অক্ষর এবং অব্যক্তের একাগ্রের সঙ্গে উপাসনা করেন, সেই প্রাণীমাত্রেরই হিতপরায়ণ এবং সর্বত্র 9 সমবুদ্ধিসম্পন্ন ব্যক্তিগণ আমাকেই প্রাপ্ত হন । 12.4",

    "অব্যক্তে (নির্গুণ ব্রহ্মে) আসক্তচিত্ত সেই সাধকদের (নিজ নিজ সাধনে) অধিক ক্লেশ হয়ে থাকে, কারণ দেহধারী ব্যক্তিদের অব্যক্তের প্রাপ্তি কষ্টে লাভ হয় ৷৷ 12.5",

    "কিন্তু যাঁরা সমস্ত কর্ম আমাতে অর্পণ করে, মৎ-পরায়ণ হয়ে অনন্যভাবে আমারই ধ্যান করতে করে উপাসনা করেন ॥ 12.6",

    "হে পার্থ ! আমাতে সমর্পিত চিত্ত সেই ভক্তদের আমি মৃত্যুরূপ সংসার-সমুদ্র থেকে শীঘ্রই উদ্ধার করে থাকিl 12.7",

    "তুমি আমাতে মন নিবিষ্ট কর এবং আমাতেই বুদ্ধি নিয়োগ কর ; তাহলে তুমি আমাতেই বাস করবে (স্থিতিলাভ করবে) এতে কোনো সন্দেহ নেই ॥ 12.8",

    "যদি তুমি আমাতে চিত্ত অচলভাবে স্থির (অর্পণ) করতে সক্ষম না হও, তাহলে হে ধনঞ্জয় ! অভ্যাসযোগের সাহায্যে তুমি আমাকে পাবার চেষ্টা কর৷ ৷ 12.9",

    "যদি তুমি অভ্যাসযোগেও অসমর্থ হও তবে আমার জন্য কর্মপরায়ণ হও। আমার জন্য কর্ম করতে থাকলেও তুমি সিদ্ধিলাভ করবে৷৷ 12.10",

    "যদি তুমি আমার যোগের (সমত্বের) আশ্রিত থেকে এইগুলিও (পূর্বশ্লোকে কথিত সাধনগুলি) করতে অসমর্থ হও তাহলে মন ও ইন্দ্রিয়কে সংযত করে সমস্ত কর্মফলের ইচ্ছা ত্যাগ কর ৷৷ 12.11",

    "অভ্যাসের থেকে শাস্ত্রজ্ঞান শ্রেষ্ঠ, শাস্ত্রজ্ঞান থেকে ধ্যান শ্রেষ্ঠ, ধ্যানের থেকে সমস্ত কর্মফল ত্যাগ শ্রেষ্ঠ। কারণ কর্মফল ত্যাগের সাহায্যে অচিরাৎ পরম শান্তি লাভ হয় ৷৷ 12.12",

    "সমস্ত প্রাণীতে দ্বেষভাব বর্জিত, সকলের মিত্র (প্রেমী) ও দয়ালু, মমতারহিত, অহংকারবর্জিত, সুখ দুঃখে সমভাবাপন্ন, 12.13",

    "ক্ষমাশীল, সদা সন্তুষ্ট যোগী, সংযতদেহ, দৃঢ়চিত্ত ও আমাতে মনবুদ্ধি অর্পিত এরূপ যাঁরা আমার ভক্ত, তাঁরা আমার প্রিয় ॥ 12.14",

    "যাঁর দ্বারা কোনো প্রাণী বিচলিত হয় না এবং যিনি নিজে কোনো প্রাণীর দ্বারা বিচলিত হন না এবং যিনি আনন্দ, অমর্ষ (হিংসা), ভয় ও উদ্বেগ থেকে মুক্ত, তিনিই আমার প্রিয়। 12.15",

    "যিনি প্রত্যাশা বর্জিত (প্রয়োজন), শুদ্ধ (বাহির থেকে এবং ভিতরে), বুদ্ধিমান, উদাসীন, ব্যথামুক্ত এবং যিনি সমস্ত সূচনা থেকে সম্পূর্ণরূপে পরিত্যাগ করেন, অর্থাৎ নতুন কর্মের সূচনা করেন, তিনিই আমার ভক্ত প্রিয়। 12.16",

    "যিনি (কখনও) আনন্দ করেন না, ঘৃণা করেন না, শোক করেন না বা কামনা করেন না (এবং) ভাল ও অশুভ কর্ম থেকে উন্নীত (আসক্তি ও ঘৃণা ব্যতীত), সেই ভক্তিশীল ব্যক্তি আমার প্রিয়। 12.17",

    "(যিনি) শত্রু ও বন্ধু এবং সম্মান ও অসম্মানে সমান (এবং) যিনি শীতল-উষ্ণ (শরীরের অনুকূলতা-অপ্রতিকূলতা) এবং সুখ ও দুঃখে (মন ও বুদ্ধির অনুকূলতা) সমান  12.18",

    "যিনি আসক্তি থেকে মুক্ত (এবং) যিনি নিন্দিত একজন যিনি প্রশংসাকে সমান, মননশীল, যেভাবেই মনে করেন (দেহের ভরণপোষণের অনুপস্থিতিতে) এবং দেহে প্রেমময়, আসক্তি ছাড়া (এবং) স্থির বুদ্ধিসম্পন্ন, (যে) ভক্তিশীল মানুষটি আমার কাছে প্রিয়। 12.19",

    "কিন্তু যে ভক্তরা (আমাকে) বিশ্বাস করে এবং যারা আমার ভক্ত হয়ে উঠেছে, তারা এই ধার্মিক অমৃতকে যেমন বলা হয়েছে খুব ভালভাবে সেবন করে, তারা আমার খুব প্রিয়। 12.20",

    "এইরূপ ওঁ তৎ সৎ - এই ভগবানের নামগুলি উচ্চারণপূর্বক ব্রহ্মবিদ্যা এবং য়োগশাস্ত্রময় শ্রীমদ্ভবদ্গীতোপনিষদরূপ শ্রীকৃষ্ণার্জুনসংবাদে 'ভক্তিয়োগ' নামক দ্বাদশ অধ্যায় সমাপ্ত হলো।"
  ],
  "adhyaya-13" : [
    "শ্রীভগবান বললেন- হে কুন্তীর পুত্র অর্জুন! 'এই'-রূপকে 'ক্ষেত্র' বলে - এই নামে (এবং) যিনি এই ক্ষেত্র জানেন তাকে জ্ঞানীরা এই নামে 'ক্ষেত্রগ্য বলে ডাকেন। 13.1",

    "হে ভারতবংশোদ্ভব অর্জুন! (আপনি) আমাকে সব ক্ষেত্রেই  ক্ষেত্র  বোঝ। এবং ক্ষেত্র ক্ষেত্রে যে জ্ঞান তাও একই এটাই আমার মত। 13.2",

    "সেই ক্ষেত্রটি কী এবং এটি কী, এবং যেগুলি থেকে এটি (জন্ম) এবং ক্ষেত্রজ্ঞ কে এবং এটি কার প্রভাব।আমার কাছ থেকে সংক্ষিপ্তভাবে শুনুন 13.3",

    "ক্ষেত্র-ক্ষেত্রজ্ঞান- এই নীতিটি ঋষিদের দ্বারা বিস্তৃতভাবে বলা হয়েছে (এবং) বেদের স্তোত্র এবং ব্রহ্মসূত্রের শ্লোক দ্বারাও বিভিন্নভাবে বলা হয়েছে যা নির্ধারিত হয়েছে। 13.4",

    "মৌলিক প্রকৃতি এবং মোট বুদ্ধিমত্তা (মহাতত্ত্ব), সর্বজনীন অহং, পাঁচটি মহান উপাদান এবং দশটি ইন্দ্রিয়, একটি মন এবং পাঁচটি ইন্দ্রিয়ের পাঁচটি বস্তু - (এটি চব্বিশটি উপাদানের ক্ষেত্র)। 13.5",

    "কামনা, কুৎসা, আনন্দ, বেদনা, সংঘট (দেহ), চেতনা (প্রাণশক্তি) (এবং) ধৃতি - এই ব্যাধিগুলির সাথে এই অঞ্চলটি সংক্ষেপে বর্ণিত হয়েছে। 13.6",

    "শ্রেষ্ঠত্বের বোধ না থাকা, কোন দাম্ভিকতা, অহিংসা, ক্ষমা, সরলতা, গুরুর সেবা, অভ্যন্তরীণ ও বাহ্যিক পরিশুদ্ধি, স্থিরতা (এবং) মনের নিয়ন্ত্রণ। 13.7",

    "ইন্দ্রিয়ের বস্তুর প্রতি অনাগ্রহ থাকা, এমনকি অহংকার না থাকা এবং জন্ম, মৃত্যু, বার্ধক্য ও রোগের দুঃখের ত্রুটি বারবার দেখা।8 13.8",

    "আসক্তিহীন থাকা, পুত্র, নারী, গৃহ ইত্যাদিতে একতা (ঘনিষ্ঠ সম্পর্ক) না থাকা এবং সামঞ্জস্য ও প্রতিকূলতা অর্জনে অবিরাম ভারসাম্য বজায় রাখা। 13.9",

    "আমার একান্ত যোগের মাধ্যমে ব্যভিচারী ভক্তি থাকতে হবে, নির্জন স্থানে বাস করার স্বভাব থাকতে হবে এবং জনসাধারণের মধ্যে প্রেম নেই। 13.10",

    "আধ্যাত্মিক জ্ঞানে নিরন্তর থাকা, তত্ত্বজ্ঞানের অর্থরূপে সর্বত্র ভগবানকে দেখা, ইহা (উল্লেখিত বিশটি সম্পদ-সম্প্রদায়) জ্ঞান (এবং) যা বিপরীত তা হল অজ্ঞানতা- বলা হয়েছে- তাই। 13.11",

    "যা জ্ঞাত (পূর্বোক্ত জ্ঞান থেকে জ্ঞাত), সেই (পরমাত্ত্বতত্ত্ব) আমি ভালই বলব, কোনটি অনুভব করে (অমরত্ব)। সেই (জ্ঞানী-তত্ত্ব) শাশ্বত (এবং) পরম ব্রহ্ম। এটাকে (এবং) সত্য বা মিথ্যা বলা যাবে না। 13.12",

    "তিনি (ভগবান) সর্বত্রই হাত-পা, সর্বত্র চক্ষু, মস্তক ও মুখ এবং (কান সহ) সর্বত্র বিরাজমান। (তিনি) জগতের সর্বত্র ব্যাপ্ত হয়ে অবস্থান করছেন। 13.13",

    "তিনি (পরমাত্মা) সকল ইন্দ্রিয় বর্জিত (এবং) সকল ইন্দ্রিয়ের বস্তুর আলোকদানকারী; তিনি আসক্তি বর্জিত এবং সমগ্র জগতের ধারক; এবং গুণাবলী বর্জিত (এবং) সমস্ত গুণাবলীর ভোগকারী।14 13.14",

    "তিনি (পরমাত্মা) বাহিরে-সকল জীবের মধ্যে (নিখুঁত) এবং পরিবর্তনশীল-অস্থির (সত্তার আকারে)ও (তিনি) এবং দূরতম এবং নিকটে (তিনি)। সূক্ষ্ম হয়ে আপনি জানতে পারবেন না। . 13.15",

    "তিনি (পরমাত্মা) (আত্ম) বিভক্তি ব্যতীত, সমস্ত প্রাণীর মধ্যে বিভাজক হিসাবে অবস্থিত এবং (তিনি) জ্ঞাত (পরমাত্মা) স্রষ্টা এবং সমস্ত প্রাণীর ধারক ও ধ্বংসকারী।16 13.16",

    "তাঁকে (পরমাত্মা) বলা হয় সমস্ত আলোর জ্যোতি (এবং) অজ্ঞানতার ঊর্ধ্বে। (তিনি) জ্ঞানের মূর্ত প্রতীক, জ্ঞাত, জ্ঞান দ্বারা অর্জনযোগ্য (এবং) সকলের হৃদয়ে বিরাজমান। 13.17",

    "এভাবে ক্ষেত্র ও জ্ঞান ও পরিচিতকে সংক্ষেপে বলা হয়েছে। সারমর্মে এই জেনে আমার ভক্ত মান অর্জিত হয়। 13.18",

    "প্রকৃতি ও পুরুষ (তোমাকে) উভয়কেই অনাদি মনে কর এবং অপকর্ম ও গুণগুলিকে প্রকৃতি থেকেই উদ্ভূত বলে মনে কর-  13.19",

    "এটা বলা হয় প্রকৃতির জন্যই বলা হয় যে, কারণ (এবং) আনন্দ ও দুঃখের ভোগে ক্রিয়া ও কর্ম উৎপন্ন করে। পুরুষদের জন্য. (20) 13.20",

    "প্রকৃতিতে অবস্থিত পুরুষ (জীব)ই প্রকৃতির গুণাবলীর ভোগকারী হয়ে ওঠেন (এবং) গুণের সংসর্গ (এটি) তাকে উচ্চ ও নিম্ন জন্মে জন্ম দেয়। 13.21",

    "এই পুরুষ (দেহের সঙ্গে সম্পর্ক করে) 'উপদ্রাষ্ট', (অনুমতি দিয়ে) 'অনুমন্ত', (নিজেকে নিজের ভরণ-পোষণ মনে করে) 'ভারত', (তাঁর সঙ্গে আনন্দ-বেদনা অনুভব করেন। থেকে) 'ভোক্ত', এবং (নিজেকে তার গুরু মনে করে) 'মহেশ্বর' (হয়ে যায়)। কিন্তু (এরূপে) পুরুষকে বলা হয় 'পরমাত্মা'। (এটি) জীবিত থাকার সময় এটি (শরীরে) এখনও (সম্পূর্ণ সম্পর্কহীন) (শরীরের সাথে) রয়েছে। 13.22",

    "এইভাবে যে পুরুষ (পৃথকভাবে) গুণসহ পুরুষ ও প্রকৃতিকে জানে, সে সর্বপ্রকারে।  এমন আচরণ করার পরও সে আর জন্ম নেয় না। 13.23",

    "অনেক মানুষ ধ্যান যোগের মাধ্যমে নিজের মধ্যে পরম আত্মাকে অনুভব করে, কেউ সাংখ্য যোগের মাধ্যমে এবং কেউ কর্ম যোগের মাধ্যমে। 13.24",

    "অন্যান্য মানুষ এইভাবে (ধ্যান যোগ, সাংখ্য যোগ, কর্মযোগ ইত্যাদি) জানে না, কিন্তু অন্যদের (জীবনমুক্ত মহাপুরুষদের) কথা শোনে এবং তাদের উপাসনা করে, একইভাবে যারা এই শ্রবণ অনুসারে আচরণ করে তারাও মৃত্যুতে পতিত হয়। 13.25",

    "হে অর্জুন, ভরতের বংশধরদের মধ্যে শ্রেষ্ঠ! স্থাবর-অস্থাবর সকল জীবই তাদের (তোমাদের) ক্ষেত্র এবং ভূমিতে জন্মগ্রহণ করে ক্ষেত্রজ্ঞ  কাকতালীয় থেকে (উত্থিত) চিন্তা করুন। 13.26",

    "যিনি পরমেশ্বরকে ধ্বংসশীল (এবং) সমস্ত প্রাণীর মধ্যে অভিন্নরূপে দেখেন, তিনি একই 13.27",

    "কারণ যে মানুষ একই ভগবানকে সর্বত্র বিরাজমান দেখেন, তিনি নিজের প্রতি অত্যাচার করেন না, তাই (তিনি) পরম অবস্থা লাভ করেন।করেন।28 13.28",

    "যিনি প্রকৃতির দ্বারা সমস্ত ক্রিয়াকলাপকে সর্বক্ষেত্রে এবং নিজেকে দেখেন। কর্তা দেখেন (বোধ করেন), তিনি দেখেন (বাস্তব)। 13.29",

    "যে সময়ে (সাধক) এক প্রকৃতিতে অবস্থিত জীবের বিভিন্ন ভাব দেখেন এবং সেই প্রকৃতি থেকেই (সকলের) বিস্তৃতি দেখেন, সেই সময়েই (সে) ব্রহ্ম লাভ করে। 13.30",

    "হে কুন্তীনন্দন! এই (পুরুষ নিজেই) চিরন্তন (এবং) গুণ বর্জিত হয়ে অবিনশ্বর পরম আত্মা। দেহে বাস করার সময় তা করে না (এবং)ও করে না।30 13.31",

    "যেমন সর্বত্র বিরাজমান আকাশ অতি সূক্ষ্ম হয়ে (কোথাও) যুক্ত হয় না, তেমনি সর্বত্র পূর্ণ আত্মাও (কোন) দেহে যুক্ত হয় না। 13.32",

    "হে ভারতবংশোদ্ভব অর্জুন! একটি সূর্য যেমন সমগ্র বিশ্বকে আলোকিত করে, তেমনি ক্ষেত্রজ্ঞান (আত্মা) সমগ্র ক্ষেত্রকে আলোকিত করে। 13.33",

    "এইভাবে যাঁরা জ্ঞানচক্ষু দ্বারা ক্ষেত্র ও ক্ষেত্রবিভাগকে জানেন এবং কারণ ও প্রভাবে প্রকৃতি থেকে বিচ্ছিন্ন হন, তাঁরা পরমাত্মাকে লাভ করেন। 13.34",

    "13 - এইরূপ ওঁ তৎ সৎ - এই ভগবানের নামগুলি উচ্চারণপূর্বক ব্রহ্মবিদ্যা এবং য়োগশাস্ত্রময় শ্রীমদ্ভবদ্গীতোপনিষদরূপ শ্রীকৃষ্ণার্জুনসংবাদে 'ক্ষেত্রক্ষেত্রজ্ঞবিভাগয়োগ' নামক ত্রয়োদশ অধ্যায় সমাপ্ত হলো।"
  ],
  "adhyaya-14" : [
    "শ্রীভগবান বললেন - আমি আবারও বলবো সকল জ্ঞানের মধ্যে শ্রেষ্ঠ (এবং) সর্বোত্তম জ্ঞান, যা জেনে সমস্ত ঋষিরা এই জগৎ থেকে পরম সিদ্ধি লাভ করেছেন (মুক্ত)। 14.1",

    "এই জ্ঞানের আশ্রয় নিয়ে যারা আমার ধার্মিকতা লাভ করেছে, (তারা) মহাকাহিনীতেও জন্ম নেয় না এবং মহাপ্রলয়েও দুঃখ পায় না। 14.2",

    "হে ভারতবংশোদ্ভব অর্জুন! আমার আদি প্রকৃতিই উৎপত্তিস্থল (এবং) আমি এতে আত্মার গর্ভ স্থাপন করি। তা থেকে সমস্ত প্রাণীর জন্ম হয়। 14.3",

    "হে কুন্তীনন্দন! সমস্ত প্রাণীর মধ্যে যে সমস্ত দেহের জন্ম হয় তার আদি প্রকৃতি হল মাতা (এবং) আমিই পিতা যিনি বীজ স্থাপন করেন। 14.4",

    "ওহ মহান! সত্ত্ব, রজ (এবং) তম প্রকৃতি থেকে উদ্ভূত - এই (তিন) গুণগুলি অবিনশ্বর আত্মা।(জীবাত্মা) দেহে। 14.5",

    "হে পাপহীন অর্জুন! এই গুণগুলির মধ্যে সত্ত্ব উপাদানটি নির্মল (পরিচ্ছন্ন) হওয়ার কারণে, প্রকাশক (এবং) অপরিবর্তনীয়। (i) (শরীরকে) সুখের আসক্তি এবং জ্ঞানের সাথে সংযুক্ত করে। 14.6",

    "হে কুন্তীনন্দন! রজোগুণকে (আপনি) বিবেচনা করুন যা তৃষ্ণা ও আসক্তি সৃষ্টি করে। তিনি মূর্ত আত্মাকে কর্মের সংযুক্তি দ্বারা আবদ্ধ করেন। 14.7",

    "আর হে ভরত অর্জুন! তমগুণ (আপনি) বিবেচনা করুন যা অজ্ঞতা থেকে উদ্ভূত সমস্ত অবতারকে প্রলুব্ধ করে। তিনি পরমানন্দ, অলসতা এবং ঘুমের দ্বারা (যারা দেহের সাথে তার সম্পর্কে বিশ্বাস করেন) আবদ্ধ করেন। 14.8",

    "হে ভারতবংশোদ্ভব অর্জুন! সত্ত্বগুণ জয় করে (মানুষের উপর) সুখে (এবং) রজোগুণকে কর্মে রেখে। কিন্তু তমোগুণ (মানুষকে) জয় করে জ্ঞান ঢেকে এবং পরমানন্দে রেখে। 14.9",

    "হে ভারতবংশোদ্ভব অর্জুন! রজোগুণ ও তমোগুণকে দমন করলে সত্ত্বগুণ বৃদ্ধি পায়, সত্ত্বগুণ ও তমোগুণকে দমন করলে রজোগুণ (বাড়ে) একইভাবে সত্ত্বগুণ (এবং) তমোগুণকে দমন করে । 14.10",

    "এই মানবদেহে যখন সমস্ত দ্বারে (ইন্দ্রিয় ও বিবেক) ​​আলো (পরিচ্ছন্নতা) ও বিচক্ষণতা প্রকাশ পায়, তখন জানতে হবে (যে) সত্ত্ব উপাদান বৃদ্ধি পেয়েছে। 14.11",

    "হে অর্জুন, ভরত বংশের শ্রেষ্ঠ! লোভ, প্রবৃত্তি, কর্মের সূচনা, অশান্তি এবং (স্পৃহ) - এই প্রবৃত্তিগুলি রজোগুণের বৃদ্ধির ফলে উদ্ভূত হয়। 14.12",

    "হে কুরুনন্দন! তমসের গুণের বৃদ্ধির সাথে সাথে এই প্রবণতাগুলিও দেখা দেয় - অ-আলো, উদাসীনতা এবং ভ্রান্তি এবং ভ্রম। 14.13",

    "যে সময়ে সত্ত্ব গুণ বেড়েছে, সেই সময়ে যদি অবতারের মৃত্যু হয়, সে)সে বিশুদ্ধ জগতে যায়। 14.14",

    "যে ব্যক্তি রজোগুণ বৃদ্ধির কারণে মৃত্যুবরণ করে, সে কর্মময় পুরুষের যোনিতে জন্মগ্রহণ করে এবং যে ব্যক্তি তমোগুণ বৃদ্ধির কারণে মরণশীল যোনিতে মৃত্যুবরণ করে। 14.15",

    "জ্ঞানীরা বলেছেন, শুভ কর্মের ফল শুদ্ধ সাত্ত্বিক, রজস কর্মের ফলকে বলা হয় দুঃখ (এবং) তমস কর্মের ফলকে বলা হয় অজ্ঞানতা (মূর্খতা)। 14.16",

    "সত্ত্ব গুণ থেকে জ্ঞান ও রজো গুণ লোভের দিকে নিয়ে যায় (ইত্যাদি); তমোগুণ থেকে অহংকার, ভ্রম ও অজ্ঞতা এছাড়াও উত্পন্ন হয়। 14.17",

    "সত্ত্ব গুণ থেকে জ্ঞান ও রজো গুণ লোভের দিকে নিয়ে যায় (ইত্যাদি); তমোগুণ থেকে অহংকার, ভ্রম ও অজ্ঞতা এছাড়াও উৎপন্ন হয়। 14.18",

    "যখন বিচারশীল মানুষ তিনটি গুণ ব্যতিরেকে অন্য কাউকে কর্তা বলে না দেখেন এবং নিজেকে ত্রিগুণের অতীত বলে অনুভব করেন, তখন তিনি আমার স্বরূপ (ব্রহ্মভাব) প্ৰাপ্ত হন৷ 14.19",

    "মানুষ (বিবেকশীল মনুষ্য) দেহ উৎপত্তির কারণরূপ এই তিনটি গুণ অতিক্রম করে জন্ম, মৃত্যু, জরারূপ দুঃখ থেকে মুক্ত হয়ে অমরত্ব অনুভব করেন ॥ 14.20",

    "অর্জুন বললেন- —হে প্রভু ! এই তিনটি গুণের অতীত মানুষকে কোন্ কোন্ লক্ষণ দ্বারা জানা যায় ? তাঁর আচরণ কেমন হয় ? আর এই তিনটি গুণকে কীভাবে অতিক্রম করা যেতে পারে ? ॥ 14.21",

    "শ্রীভগবান বললেন হে পাণ্ডব ! প্রকাশ, প্রবৃত্তি এবং মোহ—এই গুণসমূহতে সামগ্রিকরূপে প্রবৃত্তি হলেও গুণাতীত ব্যক্তি এগুলিতে দ্বেষ করেন না এবং এগুলি যদি নিবৃত্ত হয় তবে সেগুলির ইচ্ছা রাখেন না ॥ 14.22",

    "যিনি উদাসীনের মতো অবস্থান করেন এবং গুণাদির দ্বারা যিনি বিচালিত হন না, গুণই (গুণাদিতে) কার্যান্বিত হতে থাকে - এই ভাব মনে রেখে যিনি নিজের স্বরূপে অবস্থিত থাকেন এবং স্বয়ং কোনো চেষ্টা করেন না৷ 14.23",

    "যে ধৈর্যশীল ব্যক্তি সুখদুঃখে সমভাবাপন্ন ও নিজস্বরূপে স্থিত, যিনি মাটির ডেলা, পাথর এবং সোনায় সমভাবাপন্ন; যিনি প্রিয়-অপ্রিয় বিষয়ে তথা নিজের নিন্দা-স্তুতিতে সমভাব বজায় রাখেন; যিনি মান অপমানে ও মিত্র-শত্রু পক্ষের সঙ্গে সমভাব রাখেন, যিনি সর্ব কর্মারম্ভ পরিত্যাগ করেন, এইরূপ ব্যক্তিকেই গুণাতীত বলা হয় ।। 14.24 - 14.25",

    "যে ধৈর্যশীল ব্যক্তি সুখদুঃখে সমভাবাপন্ন ও নিজস্বরূপে স্থিত, যিনি মাটির ডেলা, পাথর এবং সোনায় সমভাবাপন্ন; যিনি প্রিয়-অপ্রিয় বিষয়ে তথা নিজের নিন্দা-স্তুতিতে সমভাব বজায় রাখেন; যিনি মান অপমানে ও মিত্র-শত্রু পক্ষের সঙ্গে সমভাব রাখেন, যিনি সর্ব কর্মারম্ভ পরিত্যাগ করেন, এইরূপ ব্যক্তিকেই গুণাতীত বলা হয় ।। 14.24 - 14.25",

    "যে ব্যক্তি ঐকান্তিক ভক্তিযোগের সাহায্যে আমার উপাসনা করেন, তিনি এইসকল গুণ অতিক্রম করে ব্রহ্মপ্রাপ্তির যোগ্য হয়ে ওঠেন ৷৷ 14.26",

    "যেহেতু ব্ৰহ্ম, অবিনাশী অমৃত, সনাতন ধর্ম এবং ঐকান্তিক সুখের আশ্রয়, সবই আমিই॥ 14.27",

    "এইরূপ ওঁ তৎ সৎ - এই ভগবানের নামগুলি উচ্চারণপূর্বক ব্রহ্মবিদ্যা এবং য়োগশাস্ত্রময় শ্রীমদ্ভবদ্গীতোপনিষদরূপ শ্রীকৃষ্ণার্জুনসংবাদে 'গুণত্রয়বিভাগয়োগ' নামক চতুর্দশ অধ্যায় সমাপ্ত হলো।"
  ],
  "adhyaya-15" : [
    "শ্রীভগবান বললেন –ঊর্ধ্বে মূলযুক্ত এবং নিম্ন শাখাবিশিষ্ট যে জগৎরূপ অশ্বত্থবৃক্ষকে (প্রবাহরূপে) অব্যয় বলা হয় এবং বেদ যার পত্রসমূহ, সেই জগৎবৃক্ষকে যিনি জানেন, তিনিই বেদবিৎ ॥ 15.1",

    "ওই জগৎ-বৃক্ষের গুণাদির (সত্ত্ব, রজঃ ও তমের) সাহায্যে বৃদ্ধিপ্রাপ্ত ও বিষয়রূপ পল্লববিশিষ্ট শাখাগুলি নীচে, মধ্যভাগে ও উর্ধ্বে সর্বত্র বিস্তৃত। মনুষ্যলোকে কর্মানুসারে বন্ধনকারী মূল নিম্নে ও উর্ধ্বভাগে সর্বলোকে পরিব্যাপ্ত হয়ে চলেছে৷৷ ॥ 15.2",

    "এই সংসার-বৃক্ষের যেমন রূপ দেখা যায়, বিচার করলে তেমন উপলব্ধ হয় না; কারণ এর আদি-অন্ত বা স্থিতি কিছুই নেই। তাই এই সদৃঢ়মূল জগৎ-সংসাররূপ অশ্বত্থবৃক্ষকে দৃঢ় অনাসক্তিরূপ শস্ত্রের দ্বারা ছেদন করে॥ ॥ 15.3",

    "তারপর সেই পরমপদ পরমাত্মা, যাঁকে প্রাপ্ত হলে মানুষ আর ইহজগতে ফিরে আসে না এবং যাঁর হতে অনাদিকাল থেকে এই সৃষ্টি বিস্তারলাভ করেছে—‘আমি সেই আদি পুরুষ পরমাত্মার শরণ গ্রহণ করি' এই বলে তাঁর অন্বেষণ করতে হয়। ॥ 15.4",

    "যাঁরা অভিমান ও মোহবর্জিত হয়েছেন, যাঁরা সাংসারিক আসক্তিজনিত দোষগুলি জয় করেছেন, যাঁরা নিত্য পরমাত্মতত্ত্বে প্রতিষ্ঠিত, যাঁরা (স্বদৃষ্টিতে) সমস্ত কামনারহিত হয়েছেন, যাঁরা সুখ-দুঃখরূপ দ্বন্দ্ব হতে মুক্ত, এরূপ (উচ্চ স্থিতিসম্পন্ন) মোহরহিত সাধক ভক্তগণ সেই অবিনাশী পরমপদ পরমাত্মাকে প্রাপ্ত হন ॥ 15.5",

    "সেই পরমপদকে সূর্য, চন্দ্র বা অগ্নি প্রকাশিত করতে পারে না এবং যাকে প্রাপ্ত হলে জীব আর সংসারে ফিরে আসে না, সেইটিই আমার পরম ধাম ৷৷ 15.6",

    "এই জগতে আমারই সনাতন অংশ জীবরূপে অবস্থিত ; কিন্তু সে প্রকৃতিতে অবস্থিত হয়ে মন ও পঞ্চ ইন্দ্রিয়কে আকর্ষিত করে (নিজের বলে মেনে নেয়) ॥ 15.7",

    "বায়ু যেমন গন্ধের স্থান থেকে গন্ধ গ্রহণ করে নিয়ে যায়, তেমনই শরীরের অধিপতি জীবাত্মাও একটি দেহ পরিত্যাগ করার কালে মন সহ ইন্দ্রিয়াদিকে সঙ্গে নিয়ে অন্য দেহকে আশ্রয় করে। 15.8",

    "এই জীবাত্মা মনকে আশ্রয় করে কর্ণ, চক্ষু, ত্বক, জিহ্বা ও নাসিকা- এই পঞ্চেন্দ্রিয়ের সাহায্যে বিষয় উপভোগ করে থাকে৷ 15.9",

    "শরীর কীভাবে পরিত্যাগ করা হয় এবং অন্য শরীরে কীভাবে অবস্থিতি হয়, গুণ সংযুক্ত হয়ে জীবাত্মা কীরূপে বিষয়াদি ভোগ করেন অজ্ঞ ব্যক্তিগণ তা জানতে পারে না, জ্ঞানরূপ নেত্রের সাহায্যে জ্ঞানীগণই তা জানতে পারেন। 15.10",

    "যত্নশীল যোগিগণ আপনাতে অবস্থিত এই পরমাত্মতত্ত্বকে অনুভব করে থাকেন, কিন্তু যারা নিজেদের চিত্ত (অন্তঃকরণ) শুদ্ধ করেনি, এরূপ অবিবেকী মানুষ যত্ন করলেও এই তত্ত্ব অনুভব করতে পারে না৷৷  15.11",

    "সূর্যকে আশ্রয় করে যে তেজ সমস্ত জগৎ উদ্ভাসিত করে এবং যে তেজ চন্দ্র ও অগ্নিতে আছে, সেই তেজ আমারই বলে জানবে ৷ 15.12",

    "আমিই পৃথিবীতে অনুপ্রবেশ করে নিজ শক্তির সাহায্যে সমস্ত প্রাণীকে ধারণ করি এবং আমিই রসযুক্ত চন্দ্রের রূপে ওষধি (বনস্পতি) সমূহকে পরিপুষ্ট করি৷ 15.13",

    "প্রাণীগণের শরীরে অবস্থিত আমি প্রাণ ও অপান বায়ুর সহিত মিলিত হয়ে বৈশ্বানর (জঠরাগ্নি) রূপে চতুবিধ প্রকারের অম্ল পরিপাক করি৷ 15.14",

    "আমিই সকল প্রাণীর হৃদয়ে অধিষ্ঠিত। আমা হতেই সকলের স্মৃতি, জ্ঞান ও অপোহন (সংশয়াদি দোষের নাশ) হয়ে থাকে। বেদসমূহের জ্ঞাতব্য বিষয় আমিই। বেদের তত্ত্ব নির্ণয়কারী এবং বেদার্থের জ্ঞাতাও আমি-ই॥ 15.15",

    "এ জগতে ক্ষর (বিনাশশীল) এবং অক্ষর (অবিনাশী) –এই দু’প্রকারের পুরুষ অবস্থিত। তার মধ্যে সমস্ত প্রাণীর দেহ বিনাশশীল এবং কূটই (জীবাত্মা) হল অবিনাশী ৷৷ 15.16",

    "কিন্তু উত্তম পুরুষ হলেন অন্য বিশিষ্ট একজন, যাঁকে পরমাত্মা নামে অভিহিত করা হয়। সেই অবিনাশী ঈশ্বর ত্রিলোকে প্রবিষ্ট থেকে সকলের পালন-পোষণ করেন ॥ 15.17",

    "আমি ক্ষরের অতীত এবং অক্ষরের থেকে উত্তম, তাই জগতে এবং বেদে পুরুষোত্তম নামে আমি প্ৰসিদ্ধ ৷৷ 15.18",

    "হে ভরতবংশোদ্ভূত অর্জুন! এইরূপ মোহবর্জিত হয়ে যে ব্যক্তি আমাকে পুরুষোত্তম বলে জানতে পারেন, তিনিই সর্বজ্ঞ হন এবং তিনি সর্বতোভাবে আমারই ভজনা করে থাকেন৷  15.19",

    "হে নিষ্পাপ অর্জুন! আমি এরূপে এই অত্যন্ত গোপনীয় রহস্য তোমাকে জানালাম। হে ভারত ! এটি জেনে মানুষ জ্ঞানী (স্নাত-জ্ঞাতব্য তথা প্রাপ্ত প্রাপ্তব্য) এবং কৃত-কৃতাৰ্থ হয়৷৷ 15.20",

    "এইরূপ ওঁ তৎ সৎ - এই ভগবানের নামগুলি উচ্চারণপূর্বক ব্রহ্মবিদ্যা এবং য়োগশাস্ত্রময় শ্রীমদ্ভবদ্গীতোপনিষদরূপ শ্রীকৃষ্ণার্জুনসংবাদে 'পুরুষোত্তময়োগ' নামক পঞ্চদশ অধ্যায় সমাপ্ত হলো।"
  ],
  "adhyaya-16" : [
    "শ্রী ভগবান বললেন- ভয়ের পরম অনুপস্থিতি; বিবেকের পরম পরিশোধন; জ্ঞানের জন্য যোগব্যায়ামে দৃঢ় অবস্থান; সাত্ত্বিক দান; ইন্দ্রিয় দমন; যজ্ঞ; নিজ পাঠ; দায়িত্ব পালনের জন্য ভোগান্তি পোহাতে হয় শরীর-মন-কথার সরলতা। 16.1",

    "কায়মনোবাক্যে কাউকেও কোনওভাবে কষ্ট না দেওয়া, যথার্থ ও প্রিয় ভাষণ(১), নিজের প্রতি অপরাধকারীর প্রতিও ক্রোধ না করা, সকল কর্মে কর্তৃত্বাভিমান ত্যাগ করা, চিত্ত-চাঞ্চল্যের অভাব, - পরনিন্দাবর্জন, সর্বভূতে অহেতুক দয়া, বিষয়সমূহের সঙ্গে ইন্দ্রিয়াদির সংযোগ হলেও আসক্ত না হওয়া, কোমলতা, লোক ও শাস্ত্রবিরুদ্ধ আচরণে লজ্জা এবং ব্যর্থ চেষ্টার অভাব; 16.2",

    "তেজ, ক্ষমা,  ধৈর্য,বাহ্যাভ্যন্তর দিতি, শত্রুভাবশূণ্যতা এবং নিজের মধ্যে পূজ্যভাবের অনভিমান—হে ভারত! এই সমস্ত হল দৈবী সম্পদযুক্ত পুরুষদের লক্ষণ। 16.3",

    "হে পার্থ ! দন্ত, দৰ্প, অভিমান, ক্রোধ, নিষ্ঠুরতা ও অজ্ঞান—এই সকল হল আসুরী সম্পদসহ জাত পুরুষদের লক্ষণ। 16.4",

    "দৈবী সম্পদ সংসারবন্ধন হতে মুক্তির হেতু এবং আসুরী সম্পদ সংসার বন্ধনের কারণ। হে পাণ্ডব !তুমি শোক করো না, কারণ তুমি দৈবী সম্পদ নিয়ে জন্মেছ। 16.5",

    "হে পার্থ ! ইহলোকে দুই প্রকারের মানুষ সৃষ্টি  হয়েছে, এক দৈবী প্রকৃতিসম্পন্ন এবং অপরটি আসুরী প্রকৃতিসম্পন্ন। এদের মধ্যে দৈবী প্রকৃতিসম্পন্ন মানুষদের কথা বিস্তারিতভাবে বলেছি, এইবার আসুরী প্রকৃতিসম্পন্ন মানুষদের কথা বিস্তারিতভাবে আমার নিকট শোনো। 16.6",

    "আসুরী স্বভাবসম্পন্ন ব্যক্তিগণ প্রবৃত্তি এবং নিবৃত্তি –এই দুটিকেই জানে না। তাই তাদের মধ্যে বাহ্যাভ্যন্তর শুদ্ধি নেই, সদাচার নেই এবং সত্যভাষণও নেই। 16.7",

    "এই আসুরী প্রকৃতির মানুষেরা বলে এই জগৎ ধর্মাধর্মের ব্যবস্থাহীন, সত্যশূন্য এবং কর্মফলদাতা ঈশ্বর বলে কেউ নেই। শুধু কামবশতঃ স্ত্রী-পুরুষের সংযোগেই এ উৎপন্ন, এছাড়া আর কিছুই নেই। 16.8",

    "এইরূপ মিথ্যা জ্ঞান অবলম্বন করে বিকৃত স্বভাব এবং মন্দবুদ্ধিসম্পন্ন, অহিতকারী ক্রূরকর্মা ব্যক্তিগণ জগতের বিনাশের জন্য জন্মগ্রহণ করে। 16.9",

    "এইসব দুম্পূরণীয় বাসনায় পূর্ণ, দন্ত, অভিমান ও মদযুক্ত মানুষেরা অজ্ঞানবশতঃ অশুচি সিদ্ধান্ত গ্রহণ করে ভ্রষ্টাচারে প্রবৃত্ত হয়ে সংসারে বিচরণ করে। 16.10",

    "মৃত্যুকাল পর্যন্ত এরা অসংখ্য চিন্তার আশ্রয় নিয়ে বিষয়ভোগে রত থাকে ও ‘এই-ই সুখ’ এইরূপ মনে করে থাকে। 16.11",

    "তারা অসংখ্য আশাপাশে অর্থাৎ কামনার জালে আবদ্ধ থেকে এবং কাম ও ক্রোধের অধীন হয়ে বিষয়ভোগের জন্য অসদুপায়ে অর্থ সংগ্রহে রত থাকে। 16.12",

    "তারা ভাবতে থাকে যে আজ আমার এই ধন লাভ হল, ভবিষ্যতে আমার এই আশা পূরণ হবে। আমার এত ধন আছে, পরে আরও ধন লাভ হবে। 16.13",

    "সেই দুর্জয় শত্রুকে আমি নাশ করছি, এইবার অন্যান্যদেরও নাশ করব। আমি ঈশ্বর, আমি ভোগী। আমি পুরুষার্থসম্পন্ন, বলবান এবং সুখী। 16.14",

    "আমি অত্যন্ত ধনী এবং অনেক আত্মীয়-স্বজন পরিবেষ্টিত, আমার মতো আর কে আছে ? আমি যজ্ঞ করব, দান করব, আমোদ-প্রমোদ করব। এইপ্রকার অজ্ঞ, মোহগ্রস্ত এবং নানাভাবে বিভ্রান্তচিত্ত মোহজাল সমাবৃত এবং বিষয়ভোগে অত্যধিক আসক্ত আসুরী প্রকৃতির ব্যক্তিগণ ভয়ানক অপবিত্র নরকে পতিত হয়। 16.15 - 16.16",

    "আমি অত্যন্ত ধনী এবং অনেক আত্মীয়-স্বজন পরিবেষ্টিত, আমার মতো আর কে আছে ? আমি যজ্ঞ করব, দান করব, আমোদ-প্রমোদ করব। এইপ্রকার অজ্ঞ, মোহগ্রস্ত এবং নানাভাবে বিভ্রান্তচিত্ত মোহজাল সমাবৃত এবং বিষয়ভোগে অত্যধিক আসক্ত আসুরী প্রকৃতির ব্যক্তিগণ ভয়ানক অপবিত্র নরকে পতিত হয়। 16.15 - 16.16",

    "নিজেই নিজেকে শ্রেষ্ঠ মনে করে সেইসকল অহঙ্কারী ব্যক্তি ধন, মান ও গর্বের সঙ্গে অবিধিপূর্বক নামমাত্র যজ্ঞের অনুষ্ঠান করে। 16.17",

    "অহঙ্কার, বল, দর্প, কামনা ও ক্রোধের বশবর্তী এবং অপরের নিন্দাকারী এইরূপ ব্যক্তি নিজের অপরের দেহে অন্তর্যামীরূপে অবস্থিত আমার প্রতি দ্বেষভাব পোষণ করে। 16.18",

    "সেই দ্বেষপরায়ণ, পাপাচারী, ক্রূর, নরাধমদের আমি এই সংসারে বারংবার আসুরী যোনিতে নিক্ষেপ করি। 16.19",

    "হে অর্জুন ! সেই মূঢ় ব্যক্তিগণ আমাকে প্রাপ্ত না হয়ে জন্মে জন্মে আসুরী-যোনি প্রাপ্ত হয় এবং ক্রমে তা থেকেও অত্যন্ত নিম্নগতি প্রাপ্ত হয় অর্থাৎ ঘোর নরকে পতিত হয়। 16.20",

    "কাম, ক্রোধ এবং লোভ—এই তিনটি নরকের  দ্বার স্বরূপ এবং আত্মার হননকারী অর্থাৎ আত্মাকে অধোগামী করে। অতএব এই তিনটি বিষবৎ ত্যাগ করা উচিত। 16.21",

    "হে অর্জুন ! এই তিন নরকের দ্বার হতে মুক্ত ব্যক্তি নিজ কল্যাণ সাধনে সমর্থ হন । সেইজন্য তিনি পরমগতি প্রাপ্ত হন অর্থাৎ আমাকে লাভ করেন। 16.22",

    "যে ব্যক্তি শাস্ত্রবিধি ত্যাগ করে স্বেচ্ছাচারী হয়ে খুশিমতো আচরণ করে, সে সিদ্ধি লাভ করে না,মোক্ষলাভ করে না এবং সুখও প্রাপ্ত হয় না। 16.23",

    "কর্তব্য ও অকর্তব্য নির্ধারণে তোমার নিকট শাস্ত্রই প্রমাণ। অতএব কর্তব্য-অকর্তব্য নির্ধারণে শাস্ত্রবিধি জেনে তোমার কর্ম করা উচিত। 16.24",

    "এইরূপ ওঁ তৎ সৎ - এই ভগবানের নামগুলি উচ্চারণপূর্বক ব্রহ্মবিদ্যা এবং য়োগশাস্ত্রময় শ্রীমদ্ভবদ্গীতোপনিষদরূপ শ্রীকৃষ্ণার্জুনসংবাদে 'দৈবাসুরসম্পদবিভাগয়োগ' নামক ষোড়শ অধ্যায় সমাপ্ত হলো।"
  ],
  "adhyaya-17" : [
    "অর্জুন বললেন- হে কৃষ্ণ ! যেসব ব্যক্তি শাস্ত্রবিধি পরিত্যাগ করে শ্রদ্ধা সহকারে দেবতাদির পূজা করেন, তাঁদের নিষ্ঠা কিরূপ ? তা সাত্ত্বিকী, না রাজসী, না তামসী ? ॥ 17.1",

    "শ্রীভগবান বললেন—মানুষের স্বভাবজাত শ্রদ্ধা তিন প্রকারের হয়ে থাকে সাত্ত্বিকী, রাজসী এবং তামসী। সেগুলি বিস্তারিতভাবে আমার কাছে শোন ॥ 17.2",

    "হে ভারত! সকল ব্যক্তির শ্রদ্ধাই তার অন্তঃকরণ অনুযায়ী হয়ে থাকে। মানুষ শ্রদ্ধাময়, তাই যে যেমন শ্রদ্ধাযুক্ত, সেটিই তার স্বরূপ অর্থাৎ সেটিই তার নিষ্ঠা—স্থিতি ॥ 17.3",

    "সাত্ত্বিক ব্যক্তিগণ দেবতাদের পূজা করেন, রাজসিক ব্যক্তিগণ যক্ষ ও রাক্ষসাদির পূজা করেন আর তামসিক প্রকৃতির ব্যক্তিরা ভূত ও প্রেতের পূজা করে থাকেন ॥ 17.4",

    "দম্ভ, অহংকার, ভোগ ও আসক্তিযুক্ত এবং বলগর্বিত যেসকল ব্যক্তি শরীরই পঞ্চভূত অর্থাৎ পাঞ্চভৌতিক   শরীর এবং অন্তর্যামিরূপে হিত আমাকে কৃশ করে, কঠোর তপস্যা করে; সেই মৃঢ় ব্যক্তিদের তুমি আসুরী-সম্পদবিশিষ্ট বলে জানবে ॥ 17.5 - 17.6",

    "দম্ভ, অহংকার, ভোগ ও আসক্তিযুক্ত এবং বলগর্বিত যেসকল ব্যক্তি শরীরই পঞ্চভূত অর্থাৎ পাঞ্চভৌতিক   শরীর এবং অন্তর্যামিরূপে হিত আমাকে কৃশ করে, কঠোর তপস্যা করে; সেই মৃঢ় ব্যক্তিদের তুমি আসুরী-সম্পদবিশিষ্ট বলে জানবে ॥ 17.5 - 17.6",

    "সকলের প্রিয় আহারও তিন প্রকারের হয়; তেমনিই যজ্ঞ, দান ও তপস্যাও তিন প্রকারের হয় অর্থাৎ শাস্ত্রীয় কর্মাদি ও গুণাদি ভেদে তিন প্রকারের হয়, তুমি তার প্রভেদগুলি শোন ॥ 17.7",

    "আয়ু, সত্ত্বগুণ, বল, আরোগা, সুখ, চিত্তপ্রসন্নতা বৃদ্ধিকারী এবং সারবান, হৃদয়ে শক্তিবর্ধনকারী, সরস, স্নিগ্ধ এরূপ আহার্য সাত্ত্বিক ব্যক্তিগণের প্রিয় হয়। 17.8",

    "অত্যন্ত কটু, অতি টক, অতি লবণাক্ত, অতি উষ্ণ, অতি তীক্ষ্ণ, অতি শুষ্ক এবং অতি প্রদাহকারক আহার বা ভোজ্যপদার্থ রাজসিক ব্যক্তিগণের প্রিয়, যা দুঃখ-শোক এবং রোগ উৎপন্নকারী॥ 17.9",

    "যে খাদ্য অর্ধপক্ক, রসবর্জিত, দুর্গন্ধময়, বাসি, উচ্ছিষ্ট এবং অত্যস্ত অপবিত্র (মাংসাদি বস্তু) তথাপি তামস ব্যক্তিগণের সেরূপ খাদাই প্রিয় হয়৷৷ 17.10",

    "‘যজ্ঞে করা উচিত' –এইরূপ কর্তব্য মনে করেই ফলেচ্ছা ত্যাগকারী ব্যক্তিগণের দ্বারা শাস্ত্রবিধি অনুযায়ী যে যজ্ঞ করা হয়, তাকে বলা হয় সাত্ত্বিক যজ্ঞ৷ ৷ 17.11",

    "কিন্তু হে ভরতশ্রেষ্ঠ অর্জুন ! (যে যজ্ঞ) ফলের আশা নিয়ে অথবা দম্ভ সহকারে (লোক দেখাবার জন্য) করা হয়, তাকে তুমি রাজসিক বলে জানবে ॥ 17.12",

    "শাস্ত্রবিধিরহিত, অন্নদানবিহীন, মন্ত্রহীন, দক্ষিণাহীন, শ্রদ্ধাবর্জিত যেসব যজ্ঞ হয়, তাকে বলে তামসিক যজ্ঞ ৷৷ 17.13",

    "দেবতা, ব্রাহ্মণ, গুরুজন এবং জীবন্মুক্ত মহাপুরুষদের পূজা করা, শুদ্ধভাবে থাকা, সরলতা, বহ্মচর্য পালন এবং অহিংসা-এগুলিকে বলা হয় শারীরিক (কায়িক) তপসা ॥ 17.14",

    "অনুদ্বেগকারী, সত্য, প্রিয় ও হিতকার বাক্য এবং স্বাধ্যায় ও নামজপাদি এইগুলিকে বলা হয় বাচিক তপস্যা৷৷ 17.15",

    "চিত্তের প্রসন্নতা, অক্রুরতা, মননশীলতা (মৌনতা), মনঃসংযম এবং ভাবশুদ্ধি বা ব্যবহারে অকপট ভাব— এইগুলিকে বলা হয় মানসিক তপস্যা৷৷ 17.16",

    "পরম শ্রদ্ধা সহকারে, ফলাকাঙ্ক্ষাবর্জিত ব্যক্তির দ্বারা যে ত্রিবিধ (কায়মনোবাক্যে) তপস্যা করা হয়। তাকে বলা হয় সাত্ত্বিক তপস্যা ৷৷ 17.17",

    "যে তপস্যা সৎকার, মান ও পূজা পাবার জন্য এবং কপট ভাব নিয়ে করা হয় তথ্য ইহলোকে অনিশ্চিত এবং বিনাশশীল ফলপ্রদায়ী সেই তপকে রাজস তপ বলা হয় ॥ 17.18",

    "যে তপ মূঢ়বুদ্ধিবশত নিজেকে পীড়া প্রদান করে, অথবা অন্যকে কষ্ট প্রদান করার জন্য অনুষ্ঠিত হয়, তাকে বলা হয় তামস তপ ৷৷ 17.19",

    "দান করা কর্তব্য- -এই মনোভাব নিয়ে যে দান দেশ, কাল এবং পাত্র অনুসারে অনুপকারী ব্যক্তিকেও অর্থাৎ নিষ্কামভাবে করা হয়, সেই দানকে সাত্ত্বিক দান বলা হয়৷৷ 17.20",

    "পরন্তু যে দান ক্লেশ সহকারে এবং প্রত্যুপকারের আশায় অথবা ফলপ্রাপ্তির উদ্দেশ্যে করা হয়, তাকে বলা হয় রাজস দান ॥ 17.21",

    "যে দান সংস্কারবর্জিত, অবজ্ঞা সহকারে, অযোগ্য দেশ ও কালে, অনুপযুক্ত পাত্রে করা হয়, তাকে বলা হয় তামস দান ॥ 17.22",

    "ওঁ, তৎ‍, সং' -এই তিন নামে যে পরমাত্মাকে নির্দেশ করা হয়েছে, সেই পরমাত্মার দ্বারা পুরাকালে বেদ, ব্রাহ্মণ এবং যজ্ঞাদি সৃষ্ট হয়েছে৷ ৷৷ 17.23",

    "সেইজন্য বৈদিক সিদ্ধান্তগুলির মান্যকারী পুরুষদের শাস্ত্রবিধি নিয়ত যজ্ঞ, দান এবং তপরূপ ক্রিয়াগুলি সর্বদা 'ওঁ', পরমাত্মার এই নাম উচ্চারণের দ্বারাই আরম্ভ হয়ে থাকে৷৷  17.24",

    "তৎ' –এই নামের দ্বারা যাঁকে নির্দেশ করা হয়েছে সেই পরমাত্মার জন্যই সমস্ত কিছু করা, এরূপ মেনে নিয়ে মুমুক্ষু ব্যক্তিগণ ফলেচ্ছা বর্জন করে নানা প্রকারের যজ্ঞ এবং তপস্যা ও দানাদি ক্রিয়া অনুষ্ঠিত করেন৷৷  17.25",

    "হে পার্থ। পরমাত্মার 'সৎ'—এই নামটি অস্তিত্ব মাত্রে এবং শ্রেষ্ঠভাবে প্রয়োগ করা হয়, মঙ্গলজনক কাজের সঙ্গেও 'সৎ' শব্দটি ব্যবহৃত হয়৷৷ 17.26",

    "যজ্ঞ, তপস্যা এবং দানরূপ ক্রিয়ায় যে স্থিতি বা নিষ্ঠা—তাকেও 'সৎ' বলা হয় এবং পরমাত্মাকে উদ্দেশ্য করে যে কর্ম করা হয় তাকেও 'সৎ' বলে অভিহিত করা হয় ৷ 17.27",

    "হে পার্থ ! হোম, দান, তপস্যা অথবা অন্য যা কিছু অশ্রদ্ধা সহকারে করা হয়, সেগুলিকে বলা হয় 'অসৎ'। তার ফল ইহজন্মেও পাওয়া যায় না, পরজন্মেও না অর্থাৎ এগুলি কখনো সৎ ফল প্রদানকারী হয় না ॥ 17.28",

    "এইরূপ ওঁ তৎ সৎ - এই ভগবানের নামগুলি উচ্চারণপূর্বক ব্রহ্মবিদ্যা এবং য়োগশাস্ত্রময় শ্রীমদ্ভবদ্গীতোপনিষদরূপ শ্রীকৃষ্ণার্জুনসংবাদে 'শ্রদ্ধাত্রয়বিভাগয়োগ' নামক সপ্তদশ অধ্যায় সমাপ্ত হলো।"
  ],
  "adhyaya-18" : [
    "অর্জুন বললেন—হে মহাবাহো ! হে অন্তর্যামী ! হে বাসুদেব ! আমি সন্ন্যাস এবং ত্যাগের তত্ত্ব পৃথকভাবে জানতে ইচ্ছা করি। 18.1",

    "ভগবান শ্রীকৃষ্ণ বললেন—পণ্ডিতগণের কেউ কেউ কাম্য কর্মের) ত্যাগকেই সন্ন্যাস বলে জানেন, আবার অন্য বিচারশীল ব্যক্তি সর্ববিধ কেউ কর্মের ফল ত্যাগকেই ত্যাগ বলে থাকেন। 18.2",

    "কোনো কোনো বিদ্বান ব্যক্তি বলেন যে সমস্ত কর্মই দোষযুক্ত, অতএব সমস্ত কর্ম ত্যাগ করা উচিত ; আবার অন্য কোনো কোনো বিজ্ঞ ব্যক্তি বলেন যে যজ্ঞ, দান ও তপস্যারূপ কর্ম ত্যাগ করা উচিত নয়। 18.3",

    "হে পুরুষশ্রেষ্ঠ অর্জুন! সন্ন্যাস এবং ত্যাগ, এই দুটির মধ্যে প্রথমে তুমি ত্যাগের বিষয়ে আমার মত শোনো। ত্যাগ তিন প্রকারের বলা হয়েছে সাত্ত্বিক, রাজসিক ও তামসিক।  18.4",

    "যজ্ঞ, দান এবং তপস্যারূপ কর্ম ত্যাগ করা উচিত নয়, বরং এই সকল কর্ম করাই উচিত। কারণ যজ্ঞ, দান ও তপস্যা -এই তিনটিই বুদ্ধিমান পুরুষদের পবিত্র করে। 18.5",

    "অতএব, হে পার্থ ! যজ্ঞ, দান ও তপস্যারূপ কর্ম এবং অন্যান্য সকল কর্তব্য-কর্ম, আসক্তি ও ফল কামনা ত্যাগ করে অবশ্যই করা উচিত ; এই হল আমার নিশ্চিত ও উত্তম মত। 18.6",

    "(নিষিদ্ধ এবং কাম্যকর্ম ত্যাগ করাই উচিত) কিন্তু অবশ্যকর্তব্য নিত্য কর্ম ত্যাগ করা উচিত নয়। কারণ, নিত্যকর্ম চিত্ত শুদ্ধিকর। মোহবশতঃ নিত্যকর্ম ত্যাগ করাকে তামস ত্যাগ বলা হয়। 18.7",

    "কর্ম দুঃখকর, এই মনে করে যিনি দৈহিক ক্লেশের ভয়ে কর্ম ত্যাগ করেন, তিনি এইরূপ রাজস ত্যাগ করে ত্যাগের ফল মোক্ষ লাভ করতে পারেন না। 18.8",

    "হে অর্জুন ! শাস্ত্রবিহিত কর্ম করা কর্তব্য—এই ভাব নিয়ে আসক্তি ও ফলাকাঙ্ক্ষা ত্যাগ করে কর্ম করাকে সাত্ত্বিক ত্যাগ বলে। 18.9",

    "যে-ব্যক্তি অশুভ কর্মে দ্বেষ করেন না এবং শুভ কর্মে আসক্ত হন না—সেই সত্ত্বগুণযুক্ত ব্যক্তিই সংশয়রহিত, বুদ্ধিমান এবং প্রকৃত ত্যাগী। 18.10",

    "কারণ দেহাভিমানী মানুষের পক্ষে সম্পূর্ণভাবে সমস্ত কর্ম ত্যাগ করা সম্ভব নয় ; তাই যিনি কর্মফল ত্যাগ করেন, তাঁকেই ত্যাগী বলা হয়। 18.11",

    "যারা ফলাকাঙ্ক্ষা ত্যাগ করে না; তাদের ভালো, মন্দ এবং ভালো-মন্দ মিশ্রিত—এইরূপ তিন প্রকারের কর্মফল মৃত্যুর পরেও ভোগ করতে হয় । কিন্তু যাঁরা কর্মফল ত্যাগ করেছেন তাঁদের কোনো কালেই কর্মফল ভোগ করতে হয় না। 18.12",

    "হে মহাবাহো ! সমস্ত কর্মের সিদ্ধির এই পাঁচটি হেতু, কর্মবন্ধন হতে মুক্তির উপায় নির্দেশক সাংখ্যশাস্ত্রে যেভাবে কথিত হয়েছে, সেইগুলি তুমি আমার নিকট ভালোভাবে শোনো। 18.13",

    "এই বিষয়ে অর্থাৎ কর্মের সিদ্ধিতে অধিষ্ঠান (১), কর্তা, বিবিধ প্রকারের করণ, (২) নানাবিধ চেষ্টা এবং পঞ্চম কারণ হল দৈব (৩)। 18.14",

    "শরীর, মন ও বাক্যের দ্বারা মানুষ শাস্ত্রীয় বা অশাস্ত্রীয় যা-কিছু কর্ম করে এই পাঁচটি হল তার কারণ। 18.15",

    "এতৎসত্ত্বেও যে-ব্যক্তি অশুদ্ধবুদ্ধি হেতু ঐ কর্ম সম্পাদনে শুদ্ধস্বরূপ আত্মাকেই কর্তা বলে মনে করে, সেই মলিন বুদ্ধিসম্পন্ন অজ্ঞানী ব্যক্তি ঠিক ঠিক বোঝে না। 18.16",

    "যে-ব্যক্তির অন্তঃকরণে ‘আমি কর্তা' এই ভাব নেই এবং যাঁর বুদ্ধি সাংসারিক পদার্থ এবং কর্মে লিপ্ত হয় না, তিনি জগতের সকলকে হত্যা করলেও প্রকৃতপক্ষে হত্যা করেন না এবং পাপেও লিপ্ত হন না (১)। 18.17",

    "জ্ঞাতা(২), জ্ঞান(৩), জ্ঞেয়(৪)—এই তিনটি হল সকল কর্ম প্রবৃত্তির হেতু এবং কর্তা(৬), করণ(৬), ক্রিয়া(?) এই তিনটি হল কর্মসংগ্রহ। 18.18",

    "সাংখ্যশাস্ত্রে জ্ঞান, কর্ম ও কর্তাকে সত্ত্ব, রজঃ ও তমঃ—এই ত্রিগুণের ভেদ অনুসারে তিন প্রকারের বলে উল্লেখ করা হয়েছে, সেইগুলিও তুমি যথাযথভাবে আমার নিকট শোনো। 18.19",

    "যে-জ্ঞানের দ্বারা মানুষ বহুধা বিভক্ত সর্বভূতে অবস্থিত এক অবিনাশী পরমাত্মতত্ত্বকে অবিভক্তরূপে দেখে, সেই জ্ঞানকে তুমি সাত্ত্বিক জ্ঞান বলে জানবে। 18.20",

    "কিন্তু যে-জ্ঞানের দ্বারা মানুষ বহুধা-বিভক্ত সমস্ত ভূতে অবস্থিত নানা ভাবকে পৃথক পৃথক রূপে দেখে, সেই জ্ঞানকে রাজস জ্ঞান বলে জানবে। 18.21",

    "যে-জ্ঞানের দ্বারা কোনো একটি কার্যরূপ দেহেই সম্পূর্ণের মতো আসক্তি জন্মায়, সেই যুক্তিবিহীন অযথার্থ এবং তুচ্ছ জ্ঞানকে তামস জ্ঞান বলে জানবে। 18.22",

    "যে-কর্ম শাস্ত্রবিধির দ্বারা নির্দিষ্ট এবং কর্তৃত্বরহিত ব্যক্তির দ্বারা ফলাকাঙ্ক্ষাশূন্য তথা রাগ-দ্বেষ- -বর্জিত হয়ে করা হয় তাকে সাত্ত্বিক কর্ম বলা হয়। 18.23",

    "কিন্তু বহু কষ্টসাধ্য, ফলকামনাযুক্ত বা অহঙ্কারযুক্ত পুরুষের দ্বারা যে কর্মের অনুষ্ঠান করা হয় তাকে রাজস কর্ম বলা হয়। 18.24",

    "ভাবী শুভাশুভ ফল, ধনক্ষয়, শক্তিক্ষয়, পরপীড়া ও সামর্থ্যের বিচার না করে কেবল অবিবেকবশতঃ যে কর্ম করা হয়, তাকে তামস কর্ম বলা হয়। 18.25",

    "ফলে অনাসক্ত, কর্তৃত্বাভিমানরহিত, ধৈর্যশীল, উদ্যমযুক্ত এবং ক্রিয়মান কর্মের সিদ্ধিতে হর্ষহীন ও অসিদ্ধিতে বিষাদরহিত কর্তাকে সাত্ত্বিক কর্তা বলা হয়। 18.26",

    "বাসনাকুলচিত্ত, কর্মফলাকাঙ্ক্ষী, পরদ্রব্যে লোভী, পরপীড়ক, বাহ্যান্তর শৌচহীন, ইষ্টপ্রাপ্তিতে হর্ষযুক্ত এবং অনিষ্ট প্রাপ্তিতে বিষাদযুক্ত—এইরূপ কর্তাকে রাজস কর্তা বলা হয়। 18.27",

    "বিক্ষিপ্তচিত্ত, অত্যন্ত অসংস্কৃত বুদ্ধি, অনম্র, ধূর্ত, পরবৃত্তিনাশক, সদা বিষণ্ন, অলস ও দীর্ঘসূত্রী কর্তাকে তামস কর্তা বলা হয়। 18.28",

    "হে ধনঞ্জয় ! সত্ত্ব, রজঃ ও তমোগুণানুসারে বুদ্ধি ও ধৃতির তিন প্রকারের ভেদ পৃথক পৃথক ভাবে বলছি শোনো। 18.29",

    "হে পার্থ ! যে-বুদ্ধির দ্বারা প্রবৃত্তি(২) ও নিবৃত্তি, (৩) কর্তব্য ও অকর্তব্য, ভয় ও অভয়, বন্ধন ও মোক্ষ ঠিকমতো বুঝতে পারা যায়, তা হল সাত্ত্বিকী বুদ্ধি। 18.30",

    "হে পৃথানন্দন ! যে-বুদ্ধি ধর্ম-অধর্ম এবং কর্তব্য অকর্তব্য সম্বন্ধে যথাযথ জানে না তা হল রাজসী বুদ্ধি। 18.31",

    "হে পার্থ! যে-বুদ্ধি তমোগুণে সমাবৃত হয়ে অধর্মকে ‘ধর্ম” মনে করে এবং সমস্ত বিষয়েই বিপরীত অর্থ করে, তা হল তামসী বুদ্ধি। 18.32",

    "হে পার্থ ! যে অব্যভিচারিণী ধারণাশক্তিতে মানুষ ধ্যানযোগের দ্বারা মন-প্রাণ-ইন্দ্রিয়াদির ক্রিয়া ধারণ করে, তাকে সাত্ত্বিকী ধৃতি বলে। 18.33",

    "কিন্তু, হে পৃথাপুত্র অর্জুন ! ফলাসক্ত মানুষ যে ধারণাশক্তির দ্বারা অত্যন্ত আসক্তিপূর্বক ধর্ম, অর্থ ও কামনাকে ধারণ করে, তাকে রাজসী ধৃতি বলে। 18.34",

    "হে পার্থ ! দুর্বুদ্ধিসম্পন্ন মানুষ যে ধারণাশক্তির দ্বারা নিদ্রা-ভয়-শোক-অবসাদ ও মদ ত্যাগ করে না অর্থাৎ এইগুলিকে ধরে রাখে তাকে তামসী ধৃতি বলে। 18.35",

    "হে ভরতশ্রেষ্ঠ ! তিনপ্রকার সুখের বিষয় এইবার তুমি আমার নিকট শোনো। যে সুখে মানুষ ভজন, ধ্যান এবং সেবাদির অভ্যাসের দ্বারা প্রীত ও পরিতৃপ্ত হয় এবিং পরিণামে দুঃখ হতে সমারূপে মুক্ত হয়— এইরূপ সুখ, যা আরম্ভে বিষতুল্য মনে হলেও পরিণামে অমৃতের ন্যায় হয়ে থাকে; সেই পরমাত্ম বিষয়ক বুদ্ধির নির্মলতা হতে উৎপন্ন সুখকে সাত্ত্বিক সুখ বলা হয়। 18.36 - 18.37",

    "হে ভরতশ্রেষ্ঠ ! তিনপ্রকার সুখের বিষয় এইবার তুমি আমার নিকট শোনো। যে সুখে মানুষ ভজন, ধ্যান এবং সেবাদির অভ্যাসের দ্বারা প্রীত ও পরিতৃপ্ত হয় এবিং পরিণামে দুঃখ হতে সমারূপে মুক্ত হয়— এইরূপ সুখ, যা আরম্ভে বিষতুল্য মনে হলেও পরিণামে অমৃতের ন্যায় হয়ে থাকে; সেই পরমাত্ম বিষয়ক বুদ্ধির নির্মলতা হতে উৎপন্ন সুখকে সাত্ত্বিক সুখ বলা হয়। 18.36 - 18.37",

    "যে-সুখ বিষয় ও ইন্দ্রিয়াদির সংযোগে হয়, যা ভোগকালের প্রারম্ভে অমৃতবৎ মনে হলেও পরিণামে বিষতুল্য(১) হয়—সেই সুখকে রাজস সুখ বলা হয়। 18.38",

    "যে-সুখ ভোগকালে এবং পরিণামে আত্মাকে মোহগ্রস্ত করে—নিদ্রা, আলস্য এবং প্রমাদ হতে জাত সেই সুখকে তামস সুখ বলা হয়। 18.39",

    "পৃথিবীতে বা স্বর্গে (মনুষ্য বা দেবতা) এমন কোনো প্রাণী বা বস্তু নেই, যা প্রকৃতি হতে উৎপন্ন এই তিন গুণ রহিত। 18.40",

    "হে পরন্তপ ! ব্রাহ্মণ, ক্ষত্রিয়, বৈশ্য তথা শূদ্রদের কর্ম স্বভাবজাত গুণ-অনুযায়ী ভাগ করা হয়েছে। 18.41",

    "অন্তঃকরণের সংযম, ইন্দ্রিয়াদি দমন, ধর্মপালনের জন্য কষ্টস্বীকার করা, অন্তরে ও বাইরে শুচিতা(১) রক্ষা করা, অপরের অপরাধ ক্ষমা করা, কায়মনোবাক্যে সরল থাকা, বেদ, শাস্ত্র, ঈশ্বর এবং পরলোকাদিতে শ্রদ্ধা রাখা, বেদাদি গ্রন্থের অধ্যয়ন-অধ্যাপন করা এবং পরমাত্মতত্ত্ব অনুভব করা—এ সবই হল ব্রাহ্মণের স্বভাবজাত কর্ম। 18.42",

    "শৌর্য, তেজ, ধৈর্য, দক্ষতা, যুদ্ধ হতে পলায়ন না করা, দান করা এবং শাসনক্ষমতা—এই সবই হল ক্ষত্রিয়দের স্বভাবজাত কর্ম। 18.43",

    "কৃষি, গোপালন, ক্রয়-বিক্রয়রূপ সত্য ব্যবহার (১) —এইগুলি বৈশ্যদের স্বভাবজাত কর্ম এবং সর্ব বর্ণের সেবা করা হল শূদ্রদের স্বাভাবিক কর্ম। 18.44",

    "নিজ নিজ স্বভাবজাত কর্মে তৎপর ব্যক্তি ভগবৎ প্রাপ্তিরূপ পরমসিদ্ধি লাভ করেন। নিজ স্বাভাবিক কর্মে তৎপর ব্যক্তি কীরূপে কর্মের দ্বারা পরম সিদ্ধি লাভ করেন, আমার নিকট সেই বিধি শোনো। 18.45",

    "যে-পরমেশ্বর হতে সমস্ত প্রাণীর উৎপত্তি হয়েছে এবং যিনি সমস্ত জগতে পরিব্যাপ্ত(১) হয়ে আছেন, সেই পরমেশ্বরকে নিজের স্বাভাবিক কর্মের দ্বারা অর্চনা করে(২) মানুষ পরম সিদ্ধি লাভ করে। 18.46",

    "উত্তমরূপে অনুষ্ঠিত অন্যের ধর্ম হতে গুণরহিত স্বধর্ম শ্রেষ্ঠ ; কারণ স্বভাবনির্দিষ্ট স্বধর্মরূপ কর্মে মানুষের পাপ হয় না। 18.47",

    "অতএব, হে কুন্তীপুত্র ! দোষযুক্ত হলেও সহজকর্ম(১) ত্যাগ করা উচিত নয়, কারণ ধূমাবৃত অগ্নির ন্যায় সমস্ত কর্মই কোনো না কোনোভাবে দোষযুক্ত। 18.48",

    "নৈষ্কর্ম্যসিদ্ধিং পরমাং সন্ন্যাসেনাধিগচ্ছতি। সর্বত্র অনাসক্ত বুদ্ধিসম্পন্ন, নিস্পৃহ, জিতেন্দ্রিয় ব্যক্তি সাংখ্যযোগের দ্বারা সেই পরম নৈষ্কর্ম সিদ্ধিলাভ করেন। 18.49",

    "যা জ্ঞানযোগের পরনিষ্ঠা, সেই নৈষ্কর্ম্যসিদ্ধি লাভ করে মানুষ যেভাবে ব্রহ্ম প্রাপ্ত হয়, হে কৌন্তেয় ! তুমি সংক্ষেপে তা আমার নিকট শোনো। 18.50",

    "বিশুদ্ধ বুদ্ধিযুক্ত, সাত্ত্বিক, মিতভোজী, শব্দাদি বিষয়সমূহ ত্যাগ করে একান্ত শুদ্ধস্থানে বসবাসকারী, সাত্ত্বিক ধৃতির(১) দ্বারা অন্তঃকরণ ও ইন্দ্রিয় সংযম করে কায়মনোবাক্যে সংযমী, রাগ-দ্বেষ সর্বতোভাবে বর্জনপূর্বক দৃঢ় বৈরাগ্য-অবলম্বন করে তথা অহঙ্কার-বল-দর্প-কাম-ক্রোধ এবং পরিগ্রহ ত্যাগ, করে নিরন্তর ধ্যানযোগে নিরত, মমত্বশূন্য, প্রশান্তচিত্ত পুরুষ সচ্চিদানন্দঘন ব্রহ্মে অভিন্নরূপে অবস্থান করতে সমর্থ হন। 18.51 - 18.53",

    "বিশুদ্ধ বুদ্ধিযুক্ত, সাত্ত্বিক, মিতভোজী, শব্দাদি বিষয়সমূহ ত্যাগ করে একান্ত শুদ্ধস্থানে বসবাসকারী, সাত্ত্বিক ধৃতির(১) দ্বারা অন্তঃকরণ ও ইন্দ্রিয় সংযম করে কায়মনোবাক্যে সংযমী, রাগ-দ্বেষ সর্বতোভাবে বর্জনপূর্বক দৃঢ় বৈরাগ্য-অবলম্বন করে তথা অহঙ্কার-বল-দর্প-কাম-ক্রোধ এবং পরিগ্রহ ত্যাগ, করে নিরন্তর ধ্যানযোগে নিরত, মমত্বশূন্য, প্রশান্তচিত্ত পুরুষ সচ্চিদানন্দঘন ব্রহ্মে অভিন্নরূপে অবস্থান করতে সমর্থ হন। 18.51 - 18.53",

    "বিশুদ্ধ বুদ্ধিযুক্ত, সাত্ত্বিক, মিতভোজী, শব্দাদি বিষয়সমূহ ত্যাগ করে একান্ত শুদ্ধস্থানে বসবাসকারী, সাত্ত্বিক ধৃতির(১) দ্বারা অন্তঃকরণ ও ইন্দ্রিয় সংযম করে কায়মনোবাক্যে সংযমী, রাগ-দ্বেষ সর্বতোভাবে বর্জনপূর্বক দৃঢ় বৈরাগ্য-অবলম্বন করে তথা অহঙ্কার-বল-দর্প-কাম-ক্রোধ এবং পরিগ্রহ ত্যাগ, করে নিরন্তর ধ্যানযোগে নিরত, মমত্বশূন্য, প্রশান্তচিত্ত পুরুষ সচ্চিদানন্দঘন ব্রহ্মে অভিন্নরূপে অবস্থান করতে সমর্থ হন। 18.51 - 18.53",

    "স্থিত প্রসন্নচিত্ত যোগী কোনো কিছুর জন্য শোক করেন না বা কোনো কিছুর আকাঙ্ক্ষাও করেন না ; এইরূপ সর্বভূতে সমভাবযুক্ত(১) যোগী আমার পরাভক্তি লাভ করেন । 18.54",

    "সেই পরাভক্তির দ্বারা তিনি পরমাত্মরূপী আমাকে, আমি কে এবং কতটা, তা ঠিক ঠিক তত্ত্বতঃ জানতে পারেন এবং সেই ভক্তির দ্বারা আমাকে তত্ত্বতঃ জেনে অচিরাৎ আমার মধ্যে প্রবেশ করেন। 18.55",

    "মৎপরায়ণ কর্মযোগী সকল কর্ম সর্বদা করতে থেকেও আমার কৃপায় সনাতন অবিনাশী পরমপদ প্রাপ্ত হন। 18.56",

    "সমস্ত কর্ম মনে মনে আমাকে সমর্পণ করে সমবুদ্ধিরূপ যোগ অবলম্বন করে, মৎপরায়ণ হয়ে নিরন্তর আমাতে চিত্ত রাখো। 18.57",

    "উপরিউক্ত প্রকারে মদ্‌গত চিত্ত হয়ে তুমি আমার কৃপায় সমস্ত সঙ্কট অনায়াসে অতিক্রম করবে, আর যদি অহংকারবশতঃ আমার কথা না শোনো, তবে বিনষ্ট হবে অর্থাৎ পরমার্থ হতে ভ্রষ্ট হবে। 18.58",

    "তুমি অহঙ্কারবশতঃ মনে করছো যে, যুদ্ধ করবে না কিন্তু তোমার এই সিদ্ধান্ত মিথ্যা ; কারণ তোমার স্বভাবই জোর করে তোমাকে যুদ্ধে প্রবৃত্ত করাবে। 18.59",

    "হে কুন্তীপুত্র ! যে-কৰ্ম তুমি মোহবশতঃ করতে চাইছ না, সেই কর্মই পূর্বকৃত স্বাভাবিক কর্মে আবদ্ধ হওয়ায় বাধ্য হয়ে করবে। 18.60",

    "হে অর্জুন ! শরীররূপ যন্ত্রে আরূঢ় সকল প্রাণীকে অন্তর্যামী পরমেশ্বর তাদের কর্ম-অনুসারে নিজ মায়ার দ্বারা চালিত করে সকল প্রাণীর হৃদয়ে স্থিত রয়েছেন। 18.61",

    "হে ভারত ! তুমি সর্বতোভাবে সেই পরমেশ্বরেরই শরণাগত(?) হও। তাঁর কৃপাতেই তুমি পরমশান্তি এবং সনাতন পরমধাম প্রাপ্ত হবে। 18.62",

    "এইভাবে গুহা হতে অতি গুহ্য জ্ঞান আমি তোমাকে বললাম। এখন তুমি এই রহস্যময় জ্ঞানকে ভালোভাবে বিচার করে যেমন চাও, তেমন করো। 18.63",

    "সর্বাপেক্ষা গোপনীয় হতেও অতিশয় গোপনীয় আমার পরম রহস্যময় কথা আবার শোনো। তুমি আামার অত্যন্ত প্রিয়, তাই এই পরম হিতকারক বাক্য তোমাকে পুনরায় বলব। 18.64",

    "হে অর্জুন ! তুমি আমার প্রতি মনযুক্ত হও, আমার ভক্ত হও, আমার পূজা করো এবং আমাকে নমস্কার “করো। আমি সত্য প্রতিজ্ঞা করছি যে এরূপ করলে তুমি আমাকেই প্রাপ্ত হবে ; কারণ তুমি আমার অত্যন্ত প্রিয়। 18.65",

    "করে তুমি সর্বশক্তিমান, সর্বাধার পরমেশ্বররূপ একমাত্র আমার শরণ(১) গ্রহণ করো। আমি তোমাকে সমস্ত পাপ হতে মুক্ত করব, তুমি শোক করো না। 18.66",

    "এই গীতারূপ রহস্যময় উপদেশ কখনও তপস্যাহীন, ভক্তিহীন, এবং শুনতে অনিচ্ছুক ব্যক্তিদের বলবে না, আর যারা আমার প্রতি দোষদৃষ্টি রাখে তাদের তো কখনও বলবে না। 18.67",

    "যিনি আমার প্রতি পরম ভক্তিপূর্বক এই পরম গুহা গীতাশাস্ত্র আমার ভক্তগণের নিকট বলবেন, তিনি আমাকেই প্রাপ্ত হবেন—এতে কোনো সন্দেহ নেই। 18.68",

    "মানুষের মধ্যে তাঁর অপেক্ষা প্রিয় কর্মকারী আমার আর কেউ নেই এবং পৃথিবীতে তাঁর অপেক্ষা আমার প্রিয় ভবিষ্যতে কেউ হবেও না। 18.69",

    "যে-ব্যক্তি আমাদের উভয়ের এই ধর্মময় সংবাদরূপ গীতাশাস্ত্র পাঠ করবেন, তাঁর সেই জ্ঞানযজ্ঞের দ্বারা আমি পূজিত হব—এই আমার মত। 18.70",

    "যিনি শ্রদ্ধাসহকারে এবং দোষদৃষ্টিরহিত হয়ে এই গীতাশাস্ত্র শ্রবণ করেন, তিনিও পাপমুক্ত হয়ে উত্তম . কর্মকারীদের শ্রেষ্ঠ লোক প্রাপ্ত হন। 18.71",

    "হে পার্থ ! তুমি কি এই গীতা শাস্ত্র একাগ্র মনে শুনেছ ? হে ধনঞ্জয় ! তোমার অজ্ঞানজনিত মোহ কি নষ্ট হয়েছে ? 18.72",

    "অর্জুন বললেন—হে অচ্যুত ! আপনার কৃপায় আমার মোহ দূর হয়েছে এবং আমি স্মৃতি ফিরে পেয়েছি, এখন আমি নিঃসংশয় হয়েছি, অতএব আমি আপনার আদেশ পালন করব। 18.73",

    "সঞ্জয় বললেন—এইরূপে আমি ভগবান শ্রীবাসুদেব এবং অর্জুনের মধ্যে এই অদ্ভুত, রহস্যময় ও রোমাঞ্চকর কথোপকথন শুনেছি। 18.74",

    "শ্রীবেদব্যাসের কৃপায় দিব্যদৃষ্টি লাভ করে এই পরম গুহ্য যোগের কথা স্বয়ং যোগেশ্বর ভগবান শ্রীকৃষ্ণ যখন অর্জুনকে বলছিলেন তখন আমি তা প্রত্যক্ষভাবে শুনেছি। 18.75",

    "হে রাজন্ ! ভগবান শ্রীকৃষ্ণ এবং অর্জুনের এই রহস্যময়, কল্যাণকারী, অদ্ভুত কথোপকথন পুনঃ পুনঃ স্মরণ করে আমি বারংবার হর্ষান্বিত হচ্ছি। 18.76",

    "হে রাজন্ ! শ্রীহরির সেই অত্যন্ত অদ্ভুত রূপও পুনঃ পুনঃ স্মরণ করে আমার চিত্তে মহাবিস্ময় হচ্ছে এবং আমি বারংবার হর্ষান্বিত হচ্ছি। 18.77",

    "হে রাজন্ ! যেখানে যোগেশ্বর ভগবান শ্রীকৃষ্ণ এবং গাণ্ডীব ধনুর্ধারী অর্জুন রয়েছেন সেইখানেই শ্ৰী, বিজয়, বিভূতি ও অচল নীতি বিদ্যমান—এই আমার মত। 18.78",

    "এইরূপ ওঁ তৎ সৎ - এই ভগবানের নামগুলি উচ্চারণপূর্বক ব্রহ্মবিদ্যা এবং য়োগশাস্ত্রময় শ্রীমদ্ভবদ্গীতোপনিষদরূপ শ্রীকৃষ্ণার্জুনসংবাদে 'মোক্ষসন্ন্যাসয়োগ' নামক অষ্টাদশ অধ্যায় সমাপ্ত হলো।"
  ]
  }
]

    export default GeetajiHindi