const TextBangla = {
Home:
    {
    WelMsg: "লার্নগীতা মুখস্থ করার অনুশীলন পৃষ্ঠা",
    chooseLang: "আপনার ভাষা নির্বাচন করুন:",
    greetMsg: "গীতা পরিবারে আপনাকে স্বাগতম",
    linkBasic1: "সাধারণ অনুশীলন",
    linkBasic2: "চরণানুসারে",
    startPractice: "অনুশীলন শুরু করুন",
    linkAdv1: "উন্নত স্তরের অনুশীলন",
    linkAdv2: "শ্লোকাঙ্ক",
    linkSerialWise: "ক্রমানুসারে শিখুন",
    alert: "এই ভাষাটি বর্তমানে উপলব্ধ নয়",
    },
Practice:
    {
    WelMsg: "শ্রীমদ্ভগবদ্গীতা মুখস্থ করার সহায়তা পৃষ্ঠা",
    selAdh: "এক বা একাধিক অধ্যায় নির্বাচন করুন:",
    or: "বা",
    selectAll: "সব অধ্যায় নির্বাচন করুন",
    selectdAdh: "নির্বাচিত অধ্যায়",
    clkBtn: "ক্রম রহিত  শ্লোক সংখ্যা পেতে বোতামটি ক্লিক করুন",
    alert: "অনুগ্রহ করে কমপক্ষে একটি অধ্যায় নির্বাচন করুন",
    meaningMsg: "এই শ্লোকের অর্থ এখন উপলব্ধ নয়, অনুগ্রহ করে পরবর্তী আপডেটের জন্য অপেক্ষা করুন",
    shlokNo: "শ্লোক ক্রমাঙ্ক",
    shlokCharan: "শ্লোক চরণ",
    showAnswer: "উত্তর দেখুন",
    showMeaning: "অর্থ দেখুন",
    adhyaya: "অধ্যায়",
    },
Nums:
    {
    num1: "১",
    num2: "২",
    num3: "৩",
    num4: "৪",
    num5: "৫",
    num6: "৬",
    num7: "৭",
    num8: "৮",
    num9: "৯",
    num10: "১০",
    num11: "১১",
    num12: "১২",
    num13: "১৩",
    num14: "১৪",
    num15: "১৫",
    num16: "১৬",
    num17: "১৭",
    num18: "১৮",
    },
    Score:
    {
    reportCard: "রিপোর্ট কার্ড",
    srNo: "ক্রমিক সংখ্যা",
    adhyaya: "অধ্যায়",
    shlokNo: "শ্লোক ক্রমাঙ্ক",
    time: "সময়",
    },
Serialwise:
    {
    WelMsg: "শ্রীমদ্ভগবদ্গীতা ক্রমানুসারে শেখার জন্য পৃষ্ঠা",
    },
}
export default TextBangla