import { useContext } from "react"
import { MyContext } from "./Context"
import { Link } from "react-router-dom"
import Texts from "./TextScript/TextIndex"

function WelcomeMsg() {

  const lang = useContext(MyContext)
  const textType = Texts[lang].Practice
  // console.log(textType)

    return (
    <>
      <Link className="link homeBtn" to = '/' >Home</Link>
      <div className="welcomeMsg">
        <h1>{textType.WelMsg}</h1>
      </div>
    </>
    )
}

export default WelcomeMsg