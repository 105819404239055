const TextKannada = {
Home:
    {
    WelMsg: "ಲರ್ನ್ ಗೀತಾ ಕಂಠಪಾಠ ಅಭ್ಯಾಸ ಪುಟ",
    ChooseLang: "ನಿಮ್ಮ ಭಾಷೆಯನ್ನು ಆರಿಸಿ", 
    greetMsg: "ಗೀತಾ ಪರಿವಾರಕ್ಕೆ ಸುಸ್ವಾಗತ" ,
    linkBasic1: "ಸಾಮಾನ್ಯ ಅಭ್ಯಾಸ",
    linkBasic2: "ಚರಣಾನುಸಾರ",
    startPractice: "ಅಭ್ಯಾಸ ಪ್ರಾರಂಭಿಸಿ",
    linkAdv1: "ಮೊದಲ ಸ್ತರ ಅಭ್ಯಾಸ",
    linkAdv2: "ಶ್ಲೋಕಾಂಕ",
    linkSerialWise: "ಕ್ರಮಾನುಸಾರ ಕಲಿಯಿರಿ",
    alert: "ಈ ಭಾಷೆ ಪ್ರಸ್ತುತ ಲಭ್ಯವಿಲ್ಲ",
    },
Practice:
    {
    WelMsg: "ಶ್ರೀಮದ್ಭಗವದ್ಗೀತೆ ಕಂಠಪಾಠ ಸಹಾಯ ಪುಟ",
    selAdh: "ಒಂದು ಅಥವಾ ಹೆಚ್ಚಿನ ಅಧ್ಯಾಯಗಳನ್ನು ಆಯ್ಕೆಮಾಡಿ:",
    or: "ಅಥವಾ",
    selectAll: "ಎಲ್ಲಾ ಅಧ್ಯಾಯ ಆಯ್ಕೆಮಾಡಿ",
    selectdAdh: "ಆಯ್ದ ಅಧ್ಯಾಯ",
    clkBtn: "ಕ್ರಮರಹಿತ ಶ್ಲೋಕ ಸಂಖ್ಯೆಯನ್ನು ಪಡೆಯಲು ಬಟನ್ ಅನ್ನು ಕ್ಲಿಕ್ ಮಾಡಿ",
    alert: "ದಯವಿಟ್ಟು ಕನಿಷ್ಠ ಒಂದು ಅಧ್ಯಾಯವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
    meaningMsg: "ಈ ಶ್ಲೋಕದ ಅರ್ಥವು ಇದೀಗ ಲಭ್ಯವಿಲ್ಲ, ದಯವಿಟ್ಟು ಮುಂದಿನ ಅಪ್ಡೇಟ್ ಗಾಗಿ ನಿರೀಕ್ಷಿಸಿ",
    shlokNo: "ಶ್ಲೋಕ ಸಂಖ್ಯೆ",
    shlokCharan: "ಶ್ಲೋಕ ಚರಣ",
    showAnswer:"ಉತ್ತರವನ್ನು ನೋಡಿ",
    showMeaning: "ಅರ್ಥವನ್ನು ನೋಡಿ",
    adhyaya: "ಅಧ್ಯಾಯ",
    },
Nums:
    {
    num1: "೧", 
    num2: "೨",
    num3: "೩",
    num4: "೪",
    num5: "೫",
    num6: "೬",
    num7: "೭",
    num8: "೮",
    num9: "೯",
    num10: "೧0",
    num11: "೧೧",
    num12: "೧೨",
    num13: "೧೩",
    num14: "೧೪",
    num15: "೧೫",
    num16: "೧೬",
    num17: "೧೭",
    num18: "೧೮",
    },
    Score:
    {
    reportCard: "ರಿಪೋರ್ಟ್ ಕಾರ್ಡ್",
    srNo: "ಕ್ರಮ ಸಂಖ್ಯೆ",
    adhyaya: "ಅಧ್ಯಾಯ",
    shlokNo: "ಶ್ಲೋಕ ಸಂಖ್ಯೆ",
    time: "ಸಮಯ",
    },
Serialwise:
    {
    WelMsg: "ಶ್ರೀಮದ್ಭಗವದ್ಗೀತೆ ಕ್ರಮಾನುಸಾರ ಕಲಿಕೆಯ ಪುಟ",
    },
}
export default TextKannada