// import Guruji from '../imgs/Guruji.png';
import Texts from '../Components/TextScript/TextIndex';
import { Link } from 'react-router-dom';
// import countapi from 'countapi-js';

const Home = ({handleChange,lang}) => {

  const textType = Texts[lang].Home
  // console.log(textType)

  // countapi.visits().then((result) => {
  //   console.log(result.value);
  // });

  return (
    <div className="home">
      {/* <img className="Guruji" src={Guruji} alt="Guruji"/> */}
      <h1>{textType.WelMsg}</h1>
      <br/>
      <br/>
      <label>
        <h3 className='textViolet'>{textType.chooseLang}</h3>
        <select
          value={lang}
          onChange={handleChange}
        >
          <option value="hindi">Hindi / हिंदी</option>
          <option value ="marathi">Marathi / मराठी</option>
          <option value ="gujarati">Gujarati / ગુજરાતી</option>
          <option value ="english">English</option>
          <option value ="tamil">Tamil / தமிழ்</option>
          <option value ="telugu">Telugu / తెలుగు</option>
          <option value ="kannada">Kannada / ಕನ್ನಡ</option>
          <option value ="bangla">Bangla / বাংলা</option>
          <option value ="odia">Odia / ଓଡିଆ</option>
          <option value ="malayalam">Malayalam / മലയാളം</option>
          <option value ="nepali">Nepali / नेपाली</option>
          {/* <option value ="sindhi">Sindhi / सिंधी</option> */}
          {/* <option value ="assamese">Assamese / অসমীয়া</option> */}
        </select>
      </label>

      <h1>{textType.greetMsg}</h1>
      <br/>
      {/* <h2>{textType.WelMsg}</h2>
      <br/> */}
      <h3 className='textViolet'>{textType.startPractice}</h3>
      <br/>
      <section className="practiceLink">
        <Link className="link" to='/basicLearning'>{textType.linkBasic1}<br />{(textType.linkBasic2)}</Link>
        <Link className="link" to='/advanceLearning'>{textType.linkAdv1}<br />{(textType.linkAdv2)}</Link>
      </section>
      <section className="practiceLink">
        <Link className="link" to='/serialWise'>{textType.linkSerialWise}</Link>
      </section>
      {/* <div id="visits">...</div> */}
    </div>
  )
}

export default Home
