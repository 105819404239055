const GeetajiTamil = [
{
  "adhyaya-1" : [
    "த்₄ருதராஷ்ட்ர உவாச\nத₄ர்மக்ஷேத்ரே குருக்ஷேத்ரே, ஸமவேதா யுயுத்ஸவஹ ।\nமாமகாப்₂ பாண்ட₃வாஶ்சைவ, கிமகுர்வத ஸஞ்ஜய ॥1.1॥",

    "ஸஞ்ஜய உவாச\nத்₃ருஷ்ட்வா து பாண்ட₃வாநீகம்(வ்), வ்யூட₄ம்(ந்) து₃ர்யோத₄நஸ்ததா₃ \nஆசார்யமுப ஸங்க₃ம்ய, ராஜா வசநமப்₃ரவீத் ॥1.2॥",

    "பஶ்யைதாம் பாண்டு₃புத்ராணாம், ஆசார்ய மஹதீம்(ஞ்) சமூம் ।\nவ்யூடா₄ந் த்₃ருபத₃ புத்ரேண, தவ ஶிஶ்யேண தீ₄மதா ॥1.3॥",

    "அத்ர ஶூரா மஹேஷ்வாஸா, பீ₄மார்ஜுந ஸமா யுதி₄ ।\nயுயுதா₄நோ விராடஶ்ச, த்₃ருபத₃ஶ்ச மஹாரத₂ஹ ॥1.4॥",

    "த்₄ருஷ்டகேதுஶ்சேகிதாநக்₂, காஶிராஜஶ்ச வீர்யவாந் ।\nபுருஜித் குந்திபோ₄ஜஶ்ச, ஶைப்₃யஶ்ச நரபுங்க₃வஹ ॥1.5॥",

    "யுதா₄மந்யுஶ்ச விக்ராந்த, உத்தமௌஜாஶ்ச வீர்யவாந் |\nஸௌப₄த்₃ரோ த்₃ரௌபதே₃யாஶ்ச, ஸர்வ ஏவ மஹாரதா₂ஹா ॥1.6॥",

    "அஸ்மாகந் து விஶிஷ்டா யே, தாந்நிபோ₃த₄ த்₃விஜோத்தம ।\nநாயகா மம ஸைந்யஸ்ய, ஸஞ்ஜ்யார்த₂ந் தாந்ப்₃ரவீமி தே ॥1.7॥",

    "ப₄வாந் பீ₄ஷ்மஶ்ச கர்ணஶ்ச, க்ருபஶ்ச ஸமிதிஞ்ஜயஹ |\nஅஶ்வத்தா₂மா விகர்ணஶ்ச, ஸௌமத₃த்திஸ்ததை₂வ ச ॥1.8॥",

    "அந்யே ச ப₃ஹவஶ் ஶூரா, மத₃ர்தே₂ த்யக்த ஜீவிதாஹா ।\nநாநாஶஸ்த்ர ப்ரஹரணாஸ், ஸர்வே யுத்₃த₄ விஷாரதா₃ஹா ॥1.9॥",

    "அபர்யாப்தந் தத₃ஸ்மாகம், ப₃லம் பீ₄ஷ்மாபி₄ரக்ஷிதம் ।\nபர்யாப்தந் த்வித₃மேதேஷாம், ப₃லம் பீ₄மாபி₄ரக்ஷிதம் ॥1.10॥",

    "அயநேஷு ச ஸர்வேஷு, யதா₂ பா₄க₃மவஸ்தி₂தாஹா । \nபீ₄ஷ்மமேவாபி₄ரக்ஷந்து, ப₄வந்தஸ் ஸர்வ ஏவ ஹி ॥1.11॥",

    "தஸ்ய ஸஞ்ஜநயந்ஹர்ஷம்(ங்), குருவ்ருத்₃த₄:(ப்₂) பிதாமஹ:(ஹ) \nஸிம்ஹநாத₃ம்(வ்) விநத்₃யோச்சை:(ஶ்), ஶங்க₂ம்(ந்) த₃த்₄மௌ ப்ரதாபவாந் ॥1.12॥",

    "ததஶ் ஶங்கா₂ஶ்ச பே₄ர்யஶ்ச, பணவாநக கோ₃முகா₂ஹா ।\nஸஹஸைவாப்₄யஹந்யந்த, ஸஶப்₃த₃ஸ் துமுலோSப₄வத் ॥1.13॥",

    "ததஶ் ஶ்வேதைர்ஹயைர்யுக்தே, மஹதி ஸ்யந்த₃நே ஸ்தி₂தௌ ।\nமாத₄வப்₂ பாண்ட₃வஶ்சைவ, தி₃வ்யௌ ஶங்கௌ₂ ப்ரத₃த்₄மதுஹு ॥1.14॥",

    "பாஞ்சஜந்யம் ஹ்ருஷீகேஶோ, தே₃வத₃த்தந் த₄நஞ்ஜயஹ ।\nபௌண்ட்₃ரந் த₃த்₄மௌ மஹாஶங்க₂ம், பீ₄மகர்மா வ்ருகோத₃ரஹ ॥1.15॥",

    "அநந்தவிஜயம் ராஜா, குந்திபுத்ரோ யுதி₄ஷ்டி₂ரஹ ।\nநகுலஸ் ஸஹதே₃வஶ்ச, ஸுகோ₄ஷ மணிபுஷ்பகௌ ॥1.16॥",

    "காஶ்யஶ்ச பரமேஷ்வாஸஶ், ஶிக₂ண்டீ₃ ச மஹாரத₂ஹ ।\nத்₄ருஷ்டத்₃யும்நோ விராடஶ்ச, ஸாத்யகிஶ்சாபராஜிதஹ ॥1.17॥",

    "த்₃ருபதோ₃ த்₃ரௌபதே₃யாஶ்ச, ஸர்வஶப்₂ ப்ருதி₂வீபதே ।\nஸௌப₄த்₃ரஶ்ச மஹாபா₃ஹுஶ், ஶங்கா₂ந் த₃த்₄முப்₂ ப்ருத₂க் ப்ருத₂க் ॥1.18॥",

    "ஸ கோ₄ஷோ தா₄ர்தராஷ்ட்ராணாம், ஹ்ருத₃யாநி வ்யதா₃ரயத் ।\nநப₄ஶ்ச ப்ருதி₂வீஞ்சைவ, துமுலோ வ்யநுநாத₃யந் ॥1.19॥",

    "அத₂ வ்யவஸ்தி₂தாந் த்₃ருஷ்ட்வா, தா₄ர்தராஷ்ட்ராந்கபித்₄வஜஹ ।\nப்ரவ்ருத்தே ஶஸ்த்ர ஸம்பாதே, த₄நுருத்₃யம்ய பாண்ட₃வஹ ॥1.20॥",

    "ஹ்ருஷீகேஶந் ததா₃ வாக்யம், இத₃மாஹ மஹீபதே ।\nஅர்ஜுந உவாச\nஸேநயோருப₄யோர் மத்₄யே, ரத₂ம் ஸ்தா₂பயமேSச்யுத ॥1.21॥",

    "யாவதே₃தாந்நிரீக்ஷேSஹ(ய்), யோத்₃து₄ காமாநவஸ்தி₂தாந்\nகைர்மயா ஸஹ யோத்₃த₄வ்யம், அஸ்மிந் ரண ஸமுத்₃யமே ॥1.22॥",

    "யோத்ஸ்யமாநாநவேக்ஷேSஹம்(ய்), ய ஏதேSத்ர ஸமாக₃தா:(ஹா)\nதா₄ர்தராஷ்ட்ரஸ்ய து₃ர்பு₃த்₃தே₄:(ர்), யுத்₃தே₄ ப்ரியசிகீர்ஷவ:(ஹ) ॥1.23॥",

    "ஸஞ்ஜய உவாச\nஏவமுக்தோ ஹ்ருஷீகேஶோ, கு₃டா₃கேஶேந பா₄ரத ।\nஸேநயோருப₄யோர் மத்₄யே, ஸ்தா₂பயித்வா ரதோ₂த்தமம் ॥1.24॥",

    "பீ₄ஷ்ம த்₃ரோண ப்ரமுக₂தஸ், ஸர்வேஷாஞ் ச மஹீக்ஷிதாம் ।\nஉவாச பார்த₂ பஶ்யைதாந், ஸமவேதாந் குரூநிதி ॥1.25॥",

    "தத்ராபஶ்யத்ஸ்தி₂தாந்பார்த₂ப்₂, பித்ரூநத₂ பிதாமஹான்।\nஆசார்யாந்மாதுலாந் ப்₄ராத்ரூந், புத்ராந் பௌத்ராந்ஸகீ₂ம்ஸ்ததா₂ ॥1.26॥",

    "ஶ்வஶுராந் ஸுஹ்ருத₃ஶ்சைவ, ஸேநயோருப₄யோரபி ।\nதாந் ஸமீக்ஷ்ய ஸ கௌந்தேயஸ், ஸர்வாந் ப₃ந்தூ₄நவஸ்தி₂தாந் ॥1.27॥",

    "க்ருபயா பரயா விஷ்டோ, விஷீத₃ந்நித₃மப்₃ரவீத் ।\nஅர்ஜுந உவாச\nத்₃ருஷ்ட்வேமம் ஸ்வஜநங் க்ருஷ்ண, யுயுத்ஸும் ஸமுபஸ்தி₂தம் ॥1.28॥",

    "ஸீத₃ந்தி மம கா₃த்ராணி, முக₂ஞ் ச பரிஶுஷ்யதி ।\nவேபது₂ஶ்ச ஶரீரே மே, ரோமஹர்ஷஶ்ச ஜாயதே ॥1.29॥",

    "கா₃ண்டீ₃வம் ஸ்ரம்ஸதே ஹஸ்தாத், த்வக்சைவ பரித₃ஹ்யதே ।\nந ச ஶக்நோம்யவஸ்தா₂தும், ப்₄ரமதீவ ச மே மநஹ ॥1.30॥",

    "நிமித்தாநி ச பஶ்யாமி, விபரீதாநி கேஶவ ।\nந ச ஶ்ரேயோSநுபஶ்யாமி, ஹத்வா ஸ்வஜநமாஹவே ॥1.31॥",

    "ந காங்க்ஷே விஜயங் க்ருஷ்ண, ந ச ராஜ்யம் ஸுகா₂நி ச ।\nகிந் நோ ராஜ்யேந கோ₃விந்த₃, கிம் போ₄கை₃ர் ஜீவிதேந வா ॥1.32॥",

    "யேஷாமர்தே₂ காங்க்ஷிதந் நோ, ராஜ்யம் போ₄கா₃ஸ் ஸுகா₂நி ச ।\nத இமேSவஸ்தி₂தா யுத்₃தே₄,ப்ராணாம்ஸ்த்யக்த்வா த₄நாநி ச ॥1.33॥",

    "ஆசார்யாப்₂ பிதரப்₂ புத்ராஸ், ததை₂வச பிதாமஹாஹா ।\nமாதுலாஶ் ஶ்வஶுராப்₂ பௌத்ராஶ், ஶ்யாலாஸ் ஸம்ப₃ந்தி₄நஸ்ததா₂ ॥1.34॥",

    "ஏதாந்ந ஹந்துமிச்சா₂மி, க்₄நதோSபி மது₄ஸூத₃ந ।\nஅபித்ரைலோக்ய ராஜ்யஸ்ய, ஹேதோக்₂  கிந் நு மஹீக்ருதே ॥1.35॥",

    "நிஹத்ய தா₄ர்தராஷ்ட்ராந்நக்₂, கா ப்ரீதிஸ் ஸ்யாஜ் ஜநார்த₃ந । \nபாபமேவாஶ்ரயேத₃ஸ்மாந், ஹத்வைதாநாததாயிநஹ ॥1.36॥",

    "தஸ்மாந்நார்ஹா வயம் ஹந்துந், தா₄ர்தராஷ்ட்ராந் ஸ்வபா₃ந்த₄வாந் ।\nஸ்வஜநம் ஹி கத₂ம் ஹத்வா, ஸுகி₂நஸ்ஸ்யாம மாத₄வ ॥1.37॥",

    "யத்₃யப்யேதே ந பஶ்யந்தி, லோபோ₄பஹத சேதஸஹ ।\nகுலக்ஷயக்ருதந் தோ₃ஷம், மித்ரத்₃ரோஹே ச பாதகம் ॥1.38॥",

    "கத₂ந் ந ஜ்ஞேயமஸ்மாபி₄ப்₂, பாபாத₃ஸ்மாந்நிவர்திதும் ।\nகுலக்ஷய க்ருதந்தோ₃ஷம் ப்ரபஶ்யத்₃பி₄ர் ஜநார்த₃ந ॥1.39॥",

    "குலக்ஷயே ப்ரணஶ்யந்தி, குலத₄ர்மாஸ் ஸநாதநாஹா ।\nத₄ர்மே நஷ்டே குலங் க்ருத்ஸ்நம் அத₄ர்மோSபி₄ப₄வத்யுத ॥1.40॥",

    "அத₄ர்மாபி₄ ப₄வாத் க்ருஷ்ண, ப்ரது₃ஷ்யந்தி குலஸ்த்ரியஹ ।\nஸ்த்ரீஷு து₃ஷ்டாஸு வார்ஷ்ணேய, ஜாயதே வர்ணஸங்கரஹ ॥1.41॥",

    "ஸங்கரோ நரகாயைவ, குலக்₄நாநாம்(ங்) குலஸ்ய ச\nபதந்தி பிதரோ ஹ்யேஷாம்(ல்), லுப்த பிண்டோ₃த₃க க்ரியா:(ஹா) ॥1.42॥",

    "தோ₃ஷைரேதை:(க்₂) குலக்₄நாநாம்(வ்), வர்ணஸங்கர காரகை:(ஹி) \nஉத்ஸாத்₃யந்தே ஜாதி த₄ர்மா:(க்₂), குலத₄ர்மாஶ்ச ஶாஶ்வதா:(ஹா) ॥1.43॥",

    "உத்ஸந்ந குலத₄ர்மாணாம்(ம்), மநுஷ்யாணாம்(ஞ்) ஜநார்த₃ந\nநரகேSநியதம்(வ்) வாஸோ, ப₄வதீத்யநுஶுஶ்ரும ॥1.44॥",

    "அஹோ ப₃த மஹத்பாபம்(ங்), கர்தும்(வ்) வ்யவஸிதா வயம்\nயத்₃ராஐ்யஸுக₂லோபே₄ந, ஹந்தும்(ம்) ஸ்வஜநமுத்₃யதா:(ஹா) ॥1.45॥",

    "யதி₃ மாமப்ரதீகாரம், அஶஸ்த்ரம் ஶஸ்த்ரபாணயஹ ।\nதா₄ர்தராஷ்ட்ரா ரணே ஹந்யுஸ், தந்மே க்ஷேமதரம் ப₄வேத் ॥1.46॥",

    "ஸஞ்ஜய உவாச\nஏவமுக்த்வார்ஜுநஸ் ஸங்க்₂யே, ரதோ₂பஸ்த₂ உபாவிஶத் ।\nவிஸ்ருஜ்ய ஸஶரஞ் சாபம், ஶோக ஸம்விக்₃ன மானஸஹ ॥1.47॥",

    "ஓம் தத்ஸதி₃தி ஶ்ரீமத்₃ப₄க₃வத்₃கீ₃தாஸு உபநிஷத்ஸு ப்₃ரஹ்ம வித்₃யாயாம்(ய்) யோக₃ஶாஸ்த்ரே ஶ்ரீக்ருஷ்ணார்ஜுந ஸம்வாதே₃ அர்ஜுநவிஷாத₃யோகோ₃நாம ப்ரத₂மோSத்₄யாய : (ஹ)"
  ],
  "adhyaya-2" : [
    "ஸஞ்ஜய உவாச\nதம்(ந்) ததா₂ க்ருபயாவிஷ்டம், அஶ்ரு பூர்ணா குலேக்ஷணம் \nவிஷீத₃ந்தமித₃ம்(வ்) வாக்யம், உவாச மது₄ஸூத₃ந:(ஹ) ॥2.1॥",

    "ஶ்ரீ ப₄க₃வாநுவாச\nகுதஸ்த்வா கஶ்மலமித₃ம்(வ்), விஷமே ஸமுபஸ்தி₂தம் \nஅநார்யஜுஷ்டமஸ்வர்க்₃யம், அகீர்திகரமர்ஜுந ॥2.2॥",

    "க்லைப்₃யம் மா ஸ்ம க₃மஃப்₂ பார்த₂, நைதத்த்வய்யுபபத்₃யதே।\nக்ஷுத்₃ரம் ஹ்ருத₃ய தௌ₃ர்ப₃ல்யந், த்யக்த்வோத்திஷ்ட₂ பரந்தப ॥2.3॥",

    "அர்ஜுந உவாச\nகத₂ம் பீ₄ஷ்மமஹம் ஸங்க்₂யே, த்ரோணஞ் ச மது₄ஸூத₃ன। \nஇஷுபி₄ஃப் ப்ரதி யோத்ஸ்யாமி, பூஜார்ஹாவரிஸூத₃ந ॥2.4॥",

    "குரூநஹத்வா ஹி மஹாநுபா₄வாந்,\nஶ்ரேயோ போ₄க்தும் பை₄க்ஷ்யமபீஹ லோகே।\nஹத்வார்த₂ காமாம்ஸ்து கு₃ரூநிஹைவ, \nபு₄ஞ்ஜீய போ₄கா₃ந் ருதி₄ர ப்ரதி₃க்₃தா₄ந் ॥2.5॥",

    "ந சைதத்₃வித்₃மக்₂ கதரந்நோ க₃ரீயோ,\nயத்₃வா ஜயேம யதி வா நோ ஜயேயுஹு।\nயாநேவ ஹத்வாந ஜிஜீவிஷாமஸ், \nதேsவஸ்தி₂தாஃப் ப்ரமுகே₂ தா₄ர்தராஷ்ட்ராஹா ॥2.6॥",

    "கார்பண்ய தோ₃ஷோபஹத ஸ்வபா₄வஃப்,\nப்ருச்சா₂மி த்வாந் த₄ர்ம ஸம்மூட₄ சேதாஹா।\nயச்ச்₂ரேயஸ்ஸ்யாந் நிஶ்சிதம் ப்ரூஹி தந்மே, \nஶிஷ்யஸ்தேsஹம் ஶாதி₄ மாந் த்வாம் ப்ரபந்நம் ॥2.7॥",

    "ந ஹி ப்ரபஶ்யாமி மமாபநுத்₃யாத்₃,\nயச்சோ₂க முச்சோ₂ஷணமிந்த்₃ரியாணாம்। \nஅவாப்ய பூ₄மாவஸபத்நம்ருத்₃த₄ம்,\nராஜ்யம் ஸுராணாமபி சாதி₄பத்யம் ॥2.8॥",

    "ஸஞ்ஜய உவாச\nஏவமுக்த்வா ஹ்ருஷீகேஶங், கு₃டா₃கேஶஃப் பரந்தப।\nந யோத்ஸ்ய இதி கோ₃விந்த₃ம், உக்த்வா தூஷ்ணீம் ப₃பூ₄வ ஹ ॥2.9॥",

    "தமுவாச ஹ்ருஷீகேஶ:(ஃப் ₂), ப்ரஹஸந்நிவ பா₄ரத\nஸேநயோருப₄யோர் மத்₄யே, விஷீத₃ந்தமித₃ம்(வ்) வச(ஹ) ॥2.10॥",
    
    "ஶ்ரீ ப₄க₃வாநுவாச\nஅஶோச்யாநந்வஶோசஸ்த்வம்,ப்ரஜ்ஞா வாதா₃ம்ஶ்ச பா₄ஷஸே। \nக₃தாஸூநக₃தாஸூம்ஶ்ச,நாநுஶோசந்தி பண்டி₃தாஹா ॥2.11॥",
    
    "ந த்வேவாஹஞ் ஜாதுநாஸந்,ந த்வந் நேமே ஜநாதி₄பாஹா।\nந சைவ ந ப₄விஷ்யாமஸ்,ஸர்வே வயமதஃப்₂ பரம் ॥2.12॥",
    
    "தே₃ஹிநோSஸ்மிந் யதா₂ தே₃ஹே,கௌமாரம்(ய்) யௌவநம்(ஞ்) ஜரா\nததா₂ தே₃ஹாந்தர ப்ராப்தி:(ர்), தீ₄ரஸ் தத்ர ந முஹ்யதி ॥2.13॥",
    
    "மாத்ராஸ்பர்ஶாஸ்து கௌந்தேய,ஶீதோஷ்ண ஸுக₂ து₃க்₂க₂தா₃ஹா।\nஆக₃மா பாயிநோSநித்யாஸ்தாம்ஸ்திதிக்ஷஸ்வ பா₄ரத ॥2.14॥",
    
    "யம் ஹி ந வ்யத₂யந்த்யேதே,புருஷம் புருஷர்ஷப₄।\nஸம து₃க்₂க₂ ஸுக₂ந் தீ₄ரம்,ஸோSம்ருதத்த்வாய கல்பதே ॥2.15॥",
    
    "நாஸதோ வித்₃யதே பா₄வோ, நாபா₄வோ வித்₃யதே ஸதஹ। \nஉப₄யோரபி த்₃ருஷ்டோSந்தஸ், த்வநயோஸ்தத்த்வத₃ர்ஶிபி₄ஹி ॥2.16॥",
    
    "அவிநாஶி து தத்₃வித்₃தி₄, யேந ஸர்வமிதந் ததம்।\nவிநாஶமவ்யஸ்யாஸ்ய, ந கஶ்சித்கர்துமர்ஹதி ॥2.17॥",
    
    "அந்தவந்த இமேதே₃ஹா, நித்யஸ்யோக்தாஶ் ஶரீரிணஹ। \nஅநாஶிநோSப்ரமேயஸ்ய, தஸ்மாத்₃யுத்₄யஸ்வ பா₄ரத ॥2.18॥",
    
    "ய ஏநம்(வ்) வேத்தி ஹந்தாரம்(ய்), யஶ்சைந(ம்) மந்யதே ஹதம்\nஉபௌ₄ தௌ ந விஜாநீதோ, நாயம்(ம்) ஹந்தி ந ஹந்யதே ॥2.19॥",
    
    "ந ஜாயதே ம்ரியதே வா கதா₃சிந்,\nநாயம் பூ₄த்வா  ப₄விதா வா ந பூ₄யஹ।\nஅஜோ நித்யஶ் ஶாஶ்வதோSயம் புராணோ,\nந ஹந்யதே ஹந்யமாநே ஶரீரே ॥2.20॥",
     
    "வேதா₃விநாஶிநம்(ந்) நித்யம் (ய்), ய ஏநமஜமவ்யயம்\nகத₂ம்(ம்) ஸ புருஷ:(ஃப்₂) பார்த₂, கம்(ங்) கா₄தயதி ஹந்தி கம் ॥2.21॥",
    
    "வாஸாம்ஸி ஜீர்ணானி யதா₂ விஹாய,\nநவாநி க்₃ருஹ்ணாதி நரோSபராணி।\nததா₂ ஶரீராணி விஹாய ஜீர்ணான்,\nயந்யானி ஸம்யாதி நவாநி தேஹீ ॥2.22॥",
    
    "நைநஞ் சி₂ந்த₃ந்தி ஶஸ்த்ராணி, நைநந் த₃ஹதி பாவகஹ।\nந சைநங்க்லேத₃யந்த்யாபோ, ந ஶோஷயதி மாருதஹ ॥2.23॥",
    
    "அச்சே₂த்₃யோSயம்அதா₃ஹ்யோSயம்,அக்லேத்₃யோSஶோஷ்ய ஏவ ச।\nநித்யஸ் ஸர்வக₃தஸ் ஸ்தா₂ணுர் அசலோSயம் ஸநாதநஹ ॥2.24॥",
    
    "அவ்யக்தோSயமசிந்த்யோSயம்,அவிகார்யோSயமுச்யதே\nதஸ்மாதே₃வம்(வ்) விதி₃த்வைநம்(ந்), நாநுஶோசிதுமர்ஹஸி ॥2.25॥",
    
    "அத₂ சைநம்(ந்) நித்யஜாதம்(ந்), நித்யம்(வ்) வா மந்யஸே ம்ருதம்\nததா₂பி த்வம்(ம்) மஹாபா₃ஹோ, நைவம்(ம்) ஶோசிதுமர்ஹஸி ॥2.26॥",
    
    "ஜாதஸ்ய ஹி த்₄ருவோ ம்ருத்யுர், த்₄ருவஞ் ஜந்ம ம்ருதஸ்ய ச।\nதஸ்மாத₃பரிஹார்யேSர்தே₂, ந த்வம் ஶோசிதுமர்ஹஸி ॥2.27॥",
    
    "அவ்யக்தாதீ₃நி பூ₄தாநி,  வ்யக்த மத்₄யாநி பா₄ரத।\nஅவ்யக்த நித₄நாந்யேவ, தத்ர கா பரிதே₃வநா ॥2.28॥",
    
    "ஆஶ்சர்யவத்பஶ்யதி கஶ்சிதே₃நம்,\nஆஶ்சர்யவத்₃வத₃தி ததை₂வ சாந்ய:(ஹ) \nஆஶ்சர்யவச்சைநமந்ய:(ஶ்) ஶ்ருணோதி, \nஶ்ருத்வாப்யேநம்(வ்) வேத₃ ந சைவ கஶ்சித் ॥2.29॥",
    
    "தே₃ஹீ நித்யமவத்₄யோsயந், தே₃ஹே ஸர்வஸ்ய பா₄ரத।\nதஸ்மாத்ஸர்வாணி பூ₄தாநி, ந த்வம் ஶோசிதுமர்ஹஸி ॥2.30॥",
    
    "ஸ்வத₄ர்மமபி சாவேக்ஷ்ய, ந விகம்பிதுமர்ஹஸி। \nத₄ர்ம்யாத்₃தி₄ யுத்₃தா₄ச்ச்₂ரேயோSந்யத், க்ஷத்ரியஸ்ய ந வித்₃யதே ॥2.31॥",
    
    "யத்₃ருச்ச₂யா சோபபந்நம், ஸ்வர்க₃த்₃வாரமபாவ்ருதம்।\nஸுகி₂ந: க்ஷத்ரியாஃப்₂ பார்த₂, லப₄ந்தே யுத்₃த₄ மீத்₃ருஶம் ॥2.32॥",
    
    "அத₂ சேத்வமிமந் த₄ர்ம்யம், ஸங்க்₃ராமந் ந கரிஷ்யஸி।\nததஸ் ஸ்வத₄ர்மங் கீர்திஞ், ச ஹித்வா பாபமவாப்ஸ்யஸி ॥2.33॥",
    
    "அகீர்திஞ் சாபி பூ₄தாநி, கத₂யிஷ்யந்தி தேSவ்யயாம்। \nஸம்பா₄விதஸ்ய சாகீர்திர், மரணாத₃திரிச்யதே ॥2.34॥",
    
    "ப₄யாத்₃ரணாது₃பரதம், மம்ஸ்யந்தே த்வாம் மஹாரதா₂ஹா। \nயேஷாஞ் ச த்வம் ப₃ஹுமதோ, பூ₄த்வா யாஸ்யஸி லாக₄வம் ॥2.35॥",
    
    "அவாச்ய வாதா₃ம்ஶ்ச ப₃ஹூந், வதி₃ஷ்யந்தி தவாஹிதாஹா।\nநிந்த₃ந்தஸ்தவ ஸாமர்த்₂யந், ததோ து₃க்க₂தரந் நு கிம் ॥2.36॥",
    
    "ஹதோ வா ப்ராப்ஸ்யஸி ஸ்வர்க₃ஞ் ஜித்வா வா போ₄க்ஷ்யஸே மஹீம்। \nதஸ்மாது₃த்திஷ்ட₂ கௌந்தேய, யுத்₃தா₄ய க்ருத நிஶ்சயஹ ॥2.37॥",
    
    "ஸுக₂ து₃க்கே₂ ஸமே க்ருத்வா, லாபா₄லாபௌ₄ ஜயாஜயௌ। \nததோ யுத்₃தா₄ய யுஜ்யஸ்வ, நைவம் பாபமவாப்ஸ்யஸி ॥2.38॥",
    
    "ஏஷா தேSபி₄ஹிதா ஸாங்க்₂யே, பு₃த்₃தி₄ர் யோகே₃ த்விமாம் ஶ்ருணு।\nபு₃த்₃த்₄யா யுக்தோ யயா பார்த₂, கர்மப₃ந்த₄ம் ப்ரஹாஸ்யஸி ॥2.39॥",
    
    "நேஹாபி₄க்ரமநாஶோSஸ்தி, ப்ரத்யவாயோ ந வித்₃யதே। \nஸ்வல்பமப்யஸ்ய த₄ர்மஸ்ய, த்ராயதே மஹதோ ப₄யாத் ॥2.40॥",
    
    "வ்யவஸாயாத்மிகா பு₃த்₃தி₄ரேகேஹ குருநந்த₃ன।\nப₃ஹுஶாகா₂ ஹ்யநந்தாஶ்ச, பு₃த்₃த₄யோSவ்யவஸாயிநாம் ॥2.41॥",
    
    "யாமிமாம்(ம்) புஷ்பிதாம்(வ்) வாசம்(ம்), ப்ரவத₃ந்த்ய விபஶ்சித:(ஹ)\nவேத₃வாத₃ரதா:(ஃப்₂) பார்த₂, நாந்யத₃ஸ்தீதி வாதி₃ந:(ஹ) ॥2.42॥",
    
    "காமாத்மாநஸ் ஸ்வர்க₃பரா, ஜந்ம கர்மஃப₂லப்ரதா₃ம்। \nக்ரியா விஶேஷ ப₃ஹுலாம், போ₄கை₃ஶ்வர்ய க₃திம்ப்ரதி ॥2.43॥",
    
    "போ₄கை₃ஶ்வர்ய ப்ரஸக்தாநாந், தயாபஹ்ருத சேதஸாம்।\nவ்யவஸாயாத்மிகா பு₃த்₃தி₄ஸ், ஸமாதௌ₄ ந விதீ₄யதே ॥2.44॥",
     
    "த்ரைகு₃ண்ய விஷயா வேதா₃, நிஸ்த்ரைகு₃ண்யோ ப₄வார்ஜுந।\nநிர்த்₃வந்த்₃வோ நித்ய ஸத்த்வஸ்தோ₂, நிர்யோக₃க்ஷேம ஆத்மவாந் ॥2.45॥",
    
    "யாவாநர்த₂ உத₃பாநே, ஸர்வதஸ் ஸம்ப்லுதோத₃கே।\nதாவாந் ஸர்வேஷு வேதே₃ஷு, ப்₃ராஹ்மணஸ்ய விஜாநதஹ ॥2.46॥",
    
    "கர்மண்யேவாதி₄காரஸ்தே, மா ஃப₂லேஷு கதா₃சந। \nமா கர்ம ஃப₂ல ஹேதுர்பூ₄ர், மா தே ஸங்கோ₃Sஸ்த்வகர்மணி ॥2.47॥",
    
    "யோக₃ஸ்த₂:(க்₂) குரு கர்மாணி, ஸங்க₃ம்(ந்) த்யக்த்வா த₄நஞ்ஜய\nஸித்₃த்₄யஸித்₃த்₄யோ:(ஸ்) ஸமோ பூ₄த்வா, ஸமத்வம்(ய்) யோக₃ உச்யதே ॥2.48॥",
    
    "தூ₃ரேண ஹ்யவரங்கர்ம, பு₃த்₃தி₄ யோகா₃த்₃த₄நஞ்ஜய। \nபு₃த்₃தௌ₄ ஶரணமந்விச்ச₂, க்ருபணாஃப்₂ ஃப₂லஹேதவஹ ॥2.49॥",
    
    "பு₃த்₃தி₄யுக்தோ ஜஹாதீஹ, உபே₄ ஸுக்ருத துஷ்க்ருதே। \nதஸ்மாத்₃யோகா₃ய யுஜ்யஸ்வ, யோக₃க்₂ கர்மஸு கௌஶலம் ॥2.50॥",
    
    "கர்மஜம் பு₃த்₃தி₄யுக்தா ஹி, ஃப₂லந் த்யக்த்வா மநீஷிணஹ।\nஜந்மப₃ந்த₄ விநிர்முக்தாஃப்₂, பத₃ங் க₃ச்ச₂ந்த்யநாமயம் ॥2.51॥",
    
    "யதா₃ தே மோஹ கலிலம், பு₃த்₃தி₄ர் வ்யதிதரிஷ்யதி।\nததா₃ க₃ந்தாஸி நிர்வேத₃ம், ஶ்ரோதவ்யஸ்ய ஶ்ருதஸ்ய ச ॥2.52॥",
    
    "ஶ்ருதி விப்ரதிபந்நா தே, யதா₃ ஸ்தா₂ஸ்யதி நிஶ்சலா।\nஸமாதா₄வசலா பு₃த்₃தி₄ஸ், ததா₃ யோக₃மவாப்ஸ்யஸி ॥2.53॥",
    
    "அர்ஜுந உவாச\nஸ்தி₂த ப்ரஜ்ஞஸ்ய கா பா₄ஷா, ஸமாதி₄ஸ்த₂ஸ்ய கேஶவ।\nஸ்தி₂ததீ₄க்₂ கிம் ப்ரபா₄ஷேத, கிமாஸீத வ்ரஜேத கிம் ॥2.54॥",
    
    "ஶ்ரீ ப₄க₃வாநுவாச\nப்ரஜஹாதி யதா₃ காமாந், ஸர்வாந் பார்த₂ மநோக₃தாந்। \nஆத்மந்யேவாத்மநா துஷ்டஸ் ஸ்தி₂தப்ரஜ்ஞஸ் ததோ₃ச்யதே ॥2.55॥",
    
    "து₃க்கே₂ஷ்வநுத்₃விக்₃ந மநாஸ், ஸுகே₂ஷு விக₃த ஸ்ப்ருஹஹ। \nவீதராக₃ ப₄ய க்ரோத₄ஸ் ஸ்தி₂ததீ₄ர் முநிருச்யதே ॥2.56॥",
    
    "யஸ் ஸர்வத்ராநபி₄ஸ்நேஹஸ், தத்தத் ப்ராப்ய ஶுபா₄ஶுப₄ம்।\nநாபி₄நந்த₃தி ந த்₃வேஷ்டி, தஸ்ய ப்ரஜ்ஞா ப்ரதிஷ்டி₂தா ॥2.57॥",
    
    "யதா₃ ஸம்ஹரதே சாயங், கூர்மோSங்கா₃நீவ ஸர்வஶஹ।\nஇந்த்₃ரியாணீந்த்₃ரியார்தே₂ப்₄யஸ், தஸ்ய ப்ரஜ்ஞா ப்ரதிஷ்டி₂தா ॥2.58॥",
    
    "விஷயா விநிவர்தந்தே, நிராஹாரஸ்ய தே₃ஹிநஹ।\nரஸவர்ஜம் ரஸோSப்யஸ்ய, பரந்த்₃ருஷ்ட்வா நிவர்ததே ॥2.59॥",
    
    "யததோஹ்யபி கௌந்தேய, புருஷஸ்ய விபஶ்சிதஹ।\nஇந்த்₃ரியாணி ப்ரமாதீ₂நி, ஹரந்தி ப்ரஸப₄ம் மனஹ ॥2.60॥",
    
    "தாநி ஸர்வாணி ஸம்யம்ய, யுக்த ஆஸீத மத்பரஹ।\nவஶே ஹி யஸ்யேந்த்₃ரியாணி, தஸ்ய ப்ரஜ்ஞா ப்ரதிஷ்டி₂தா ॥2.61॥",
    
    "த்₄யாயதோ விஷயாந் பும்ஸஸ், ஸங்க₃ஸ்தேஷூபஜாயதே।\nஸங்கா₃த் ஸஞ்ஜாயதே காமக்₂, காமாத் க்ரோதோ₄Sபி₄ஜாயதே ॥2.62॥",
    
    "க்ரோதா₄த்₃ப₄வதி ஸம்மோஹஸ், ஸம்மோஹாத் ஸ்ம்ருதி விப்₄ரமஹ।\nஸ்ம்ருதி ப்₄ரம்ஶாத்₃ பு₃த்₃தி₄நாஶோ, பு₃த்₃தி₄ நாஶாத் ப்ரணஶ்யதி ॥2.63॥",
    
    "ராக₃ த்₃வேஷ வியுக்தைஸ்து, விஷயாநிந்த்ரியைஶ்சரந்। \nஆத்மவஶ்யைர் விதே₄யாத்மா, ப்ரஸாத₃மதி₄க₃ச்ச₂தி ॥2.64॥",
    
    "ப்ரஸாதே₃ ஸர்வ து₃க்கா₂நாம், ஹாநிரஸ்யோபஜாயதே।\nப்ரஸந்ந சேதஸோ ஹ்யாஷு, பு₃த்₃தி₄ஃப்₂ பர்யவதிஷ்ட₂தே ॥2.65॥",
    
    "நாஸ்தி பு₃த்₃தி₄ரயுக்தஸ்ய, ந சாயுக்தஸ்ய பா₄வநா।\nந சாபா₄வயதஶ் ஶாந்திரஶாந்தஸ்ய குதஸ் ஸுக₂ம் ॥2.66॥",
    
    "இந்த்₃ரியாணாம்(ம்) ஹி சரதாம்(ய்), யந்மநோSநுவிதீ₄யதே\nதத₃ஸ்ய ஹரதி ப்ரஜ்ஞாம்(வ்), வாயுர்நாவமிவாம்ப₄ஸி ॥2.67॥",
    
    "தஸ்மாத்₃யஸ்ய மஹாபா₃ஹோ, நிக்₃ருஹீதாநி ஸர்வஶஹ। \nஇந்த்₃ரியாணீந்த்₃ரியார்தே₂ப்₄யஸ், தஸ்ய ப்ரஜ்ஞா ப்ரதிஷ்டி₂தா ॥2.68॥",
    
    "யா நிஶா ஸர்வபூ₄தாநாந், தஸ்யாஞ் ஜாக₃ர்தி ஸம்யமீ।\nயஸ்யாஞ் ஜாக்₃ரதி பூ₄தாநி, ஸா நிஶா பஶ்யதோ முநேஹே ॥2.69॥",
    
    "ஆபூர்யமாணமசலப்ரதிஷ்ட₂ம்,\nஸமுத்₃ரமாபஃப்₂ ப்ரவிஶந்தி யத்₃வத்।\nதத்₃வத் காமாயம் ப்ரவிஶந்தி ஸர்வே,\nஸ ஶாந்திமாப்நோதி ந காமகாமீ ॥2.70॥",
     
    "விஹாய காமாந்யஸ் ஸர்வாந், புமாம்ஶ்சரதி நி:(ஹ்) ஸ்ப்ருஹஹ।\nநிர்மமோ நிரஹங்காரஸ், ஸ ஶாந்திமதி₄க₃ச்ச₂தி ॥2.71॥",
    
    "ஏஷா ப்₃ராஹ்மீ ஸ்தி₂திஃப் பார்த₂, நைநாம் ப்ராப்ய விமுஹ்யதி। \nஸ்தி₂த்வாஸ்யாமந்தகாலேSபி, ப்₃ரஹ்மநிர்வாணம்ருச்ச₂தி ॥2.72॥",
    
    "ௐ தத்ஸதி₃தி ஶ்ரீமத்₃ப₄க₃வத்₃கீ₃தாஸு உபநிஷத்ஸு ப்₃ரஹ்மவித்₃யாயாம்(ய்)யோக₃ஶாஸ்த்ரே ஶ்ரீ க்ருஷ்ணார்ஜுந ஸம்வாதே₃ஸாங்க்₂ய யோகோ₃ நாம த்₃விதீயோSத்₄யாயஹ"
  ],
  "adhyaya-3" : [
    "அர்ஜுந உவாச\nஜ்யாயஸீ  சேத்கர்மணஸ்தே, மதா  பு₃த்₃தி₄ர்  ஜநார்த₃ந। \nதத்கிங்  கர்மணி  கோ₄ரே  மாந், நியோஜயஸி  கேஶவ ॥3.1॥",

    "வ்யாமிஶ்ரேணேவ  வாக்யேந, பு₃த்₃தி₄ம்(ம்)  மோஹயஸீவ மே\nததே₃கம்(வ்) வத₃  நிஶ்சித்ய, யேந  ஶ்ரேயோSஹமாப்நுயாம் ॥3.2॥",

    "ஸ்ரீ ப₄க₃வாநுவாச\nலோகேSஸ்மிந்  த்₃விவிதா₄  நிஷ்டா₂, புரா  ப்ரோக்தா  மயாநக₄। \nஜ்ஞானயோகே₃ந  ஸாங்க்₂யாநாங், கர்மயோகே₃ந யோகி₃நாம் ॥3.3॥",

    "ந  கர்மணாமநாரம்பா₄ந், நைஷ்கர்ம்யம்  புருஷோSஶ்நுதே।\nந  ச  ஸந்ந்யஸநாதே₃வ, ஸித்₃தி₄ம்  ஸமதி₄  க₃ச்ச₂தி ॥3.4॥",

    "ந  ஹி  கஶ்சித்க்ஷணமபி, ஜாது  திஷ்ட₂த்ய  கர்மக்ருத்। \nகார்யதே  ஹ்யவஶக்₂  கர்ம, ஸர்வப்₂  ப்ரக்ருதிஜைர்கு₃ணைஹி ॥3.5॥",

    "கர்மேந்த்₃ரியாணி  ஸம்யம்ய, ய  ஆஸ்தே  மநஸா  ஸ்மரந்। \nஇந்த்₃ரியார்தா₂ந்  விமூடா₄த்மா, மித்₂யாசாரஸ்  ஸ  உச்யதே ॥3.6॥",

    "யஸ்த்விந்த்₃ரியாணி  மநஸா, நியம்யாரப₄தேSர்ஜுந। \nகர்மேந்த்₃ரியைக்₂  கர்மயோகம், அஸக்தஸ்  ஸ  விஶிஷ்யதே ॥3.7॥",

    "நியதங்  குரு  கர்மத்வங், கர்ம  ஜ்யாயோ  ஹ்யகர்மணஹ। \nஶரீரயாத்ராபி  ச  தே, ந  ப்ரஸித்₃த்₄யேத₃கர்மணஹ ॥3.8॥",

    "யஜ்ஞார்தா₂த்கர்மணோSந்யத்ர, லோகோSயங் கர்மப₃ந்த₄நஹ। \nதத₃ர்த₂ங்  கர்ம  கௌந்தேய, முக்தஸங்க₃ஸ்  ஸமாசர ॥3.9॥",

    "ஸஹயஜ்ஞாப்₂  ப்ரஜாஸ்  ஸ்ருஷ்ட்வா, புரோவாச  ப்ரஜாபதிஹி। \nஅநேந  ப்ரஸவிஷ்யத்₄வம், ஏஷ  வோSஸ்த்விஷ்ட  காமது₄க் ॥3.10॥",

    "தே₃வாந்பா₄வயதாநேந, தே  தே₃வா  பா₄வயந்து  வஹ। \nபரஸ்பரம்  பா₄வயந்தஶ், ஶ்ரேயப்₂  பரமவாப்ஸ்யத₂ ॥3.11॥",

    "இஷ்டாந்போ₄கா₃ந்ஹி வோ தே₃வா, தா₃ஸ்யந்தே  யஜ்ஞபா₄விதாஹா। \nதைர்த₃த்தாநப்ரதா₃யைப்₄யோ, யோ  பு₄ங்க்தே  ஸ்தேந  ஏவ  ஸஹ ॥3.12॥",

    "யஜ்ஞஶிஷ்டாஶிநஸ்  ஸந்தோ, முச்யந்தே  ஸர்வகில்பி₃ஷைஹி। \nபு₄ஞ்ஜதே  தே  த்வக₄ம்  பாபா, யே  பசந்த்யாத்மகாரணாத் ॥3.13॥",

    "அந்நாத்₃ப₄வந்தி  பூ₄தாநி, பர்ஜந்யாத₃ந்ந  ஸம்ப₄வஹ। \nயஜ்ஞாத்₃ப₄வதி  பர்ஜந்யோ, யஜ்ஞக்₂  கர்ம  ஸமுத்₃ப₄வஹ ॥3.14॥",

    "கர்ம  ப்₃ரஹ்மோத்₃ப₄வம்(வ்)  வித்₃தி₄, ப்₃ரஹ்மாக்ஷர  ஸமுத்₃ப₄வம்\nதஸ்மாத்₃  ஸர்வக₃தம்(ம்)  ப்₃ரஹ்ம, நித்யம்(ய்)  யஜ்ஞே  ப்ரதிஷ்டி₂தம் ॥3.15॥",

    "ஏவம்  ப்ரவர்திதஞ்  சக்ரந், நாநுவர்தயதீஹ  யஹ। \nஅகா₄யுரிந்த்₃ரியாராமோ, மோக₄ம்  பார்த₂  ஸ  ஜீவதி ॥3.16॥",

    "யஸ்த்வாத்மரதிரேவ  ஸ்யாத்₃, ஆத்மத்ருப்தஶ்ச  மாநவஹ। \nஆத்மந்யேவ  ச  ஸந்துஷ்டஸ், தஸ்ய  கார்யந்  ந  வித்₃யதே ॥3.17॥",

    "நைவ  தஸ்ய  க்ருதேநார்தோ₂, நாக்ருதேநேஹ  கஶ்சந।\nந  சாஸ்ய  ஸர்வபூ₄தேஷு, கஶ்சித₃ர்த₂வ்யபாஶ்ரயஹ ॥3.18॥",

    "தஸ்மாத₃ஸக்தஸ்  ஸததங், கார்யங்  கர்ம  ஸமாசர। \nஅஸக்தோஹ்யாசரந்கர்ம, பரமாப்நோதி  பூருஷஹ ॥3.19॥",

    "கர்மணைவஹி  ஸம்ஸித்₃தி₄ம், ஆஸ்தி₂தா  ஜநகாத₃யஹ। \nலோகஸங்க்₃ரஹமேவாபி, ஸம்பஶ்யந் கர்துமர்ஹஸி ॥3.20॥",

    "யத்₃யதா₃சரதி  ஶ்ரேஷ்ட₂ஸ், தத்ததே₃வேதரோ  ஜநஹ। \nஸ  யத்ப்ரமாணங்குருதே, லோகஸ்தத₃நுவர்ததே ॥3.21॥",

    "ந  மே  பார்தா₂ஸ்தி  கர்தவ்யம்(ந்), த்ரிஷு  லோகேஷு  கிஞ்சந\nநாநவாப்தமவாப்தவ்யம்(வ்), வர்த  ஏவ  ச  கர்மணி ॥3.22॥",

    "யதி₃  ஹ்யஹந்  ந  வர்தேயஞ், ஜாது  கர்மண்யதந்த்₃ரிதஹ। \nமம  வர்த்மாநுவர்தந்தே, மநுஷ்யாப்₂  பார்த₂  ஸர்வஶஹ ॥3.23॥",

    "உத்ஸீதே₃யுரிமே  லோகா, ந  குர்யாங்  கர்ம  சேத₃ஹம்। \nஸங்கரஸ்ய  ச  கர்தா  ஸ்யாம், உபஹந்யாமிமாப்₂  ப்ரஜாஹா ॥3.24॥",

    "ஸக்தாக்₂  கர்மண்ய  வித்₃வாம்ஸோ, யதா₂  குர்வந்தி  பா₄ரத। \nகுர்யாத்₃வித்₃வாம்ஸ்ததா₂ஸக்தஶ், சிகீர்ஷுர்லோகஸங்க்₃ரஹம் ॥3.25॥",

    "ந  பு₃த்₃தி₄ பே₄த₃ஞ்  ஜநயேத், அஜ்ஞானாங்  கர்ம  ஸங்கி₃நாம்। \nஜோஷயேத் ஸர்வகர்மாணி, வித்₃வாந் யுக்தஸ்  ஸமாசரந் ॥3.26॥",

    "ப்ரக்ருதேக்₂  க்ரியமாணாநி, கு₃ணைக்₂  கர்மாணி  ஸர்வஶஹ।\nஅஹங்கார விமூடா₄த்மா, கர்தாஹமிதி  மந்யதே ॥3.27॥",

    "தத்த்வவித்து  மஹாபா₃ஹோ, கு₃ணகர்ம  விபா₄க₃யோஹோ।\nகு₃ணா  கு₃ணேஷு  வர்தந்த, இதி  மத்வா  ந  ஸஜ்ஜதே ॥3.28॥",

    "ப்ரக்ருதேர்கு₃ணஸம்மூடா₄ஸ், ஸஜ்ஜந்தே  கு₃ணகர்மஸு।\nதாநக்ருத்ஸ்நவிதோ₃  மந்தா₃ந், க்ருத்ஸ்நவிந்ந  விசாலயேத் ॥3.29॥",

    "மயி  ஸர்வாணி  கர்மாணி, ஸந்ந்யஸ்யாத்₄யாத்மசேதஸா। \nநிராஶீர்  நிர்மமோ  பூ₄த்வா, யுத்₄யஸ்வ  விக₃தஜ்வரஹ ॥3.30॥",

    "யே  மே  மதமித₃ந்  நித்யம், அநுதிஷ்ட₂ந்தி  மாநவாஹா। \nஶ்ரத்₃தா₄வந்தோSநஸூயந்தோ, முச்யந்தே  தேSபி  கர்மபி₄ஹி ॥3.31॥",

    "யே  த்வேதத₃ப்₄யஸூயந்தோ  நாநுதிஷ்ட₂ந்தி  மே  மதம்।\nஸர்வஜ்ஞான விமூடா₄ம்ஸ்தாந், வித்₃தி₄  நஷ்டாநசேதஸஹ ॥3.32॥",

    "ஸத்₃ருஶம்(ஞ்)  சேஷ்டதே ஸ்வஸ்யா:(ஃப்₂), ப்ரக்ருதேர்ஜ்ஞானவானபி\nப்ரக்ருதிம்(ய்) யாந்தி  பூ₄தாந, நிக்₃ரஹ:(க்₂)  கிம்(ங்)  கரிஷ்யதி ॥3.33॥",

    "இந்த்₃ரியஸ்யேந்த்₃ரியஸ்யார்தே₂,ராக₃த்₃வேஷௌ  வ்யவஸ்தி₂தௌ। \nதயோர்ந  வஶமாக₃ச்சே₂த், தௌ ஹ்யஸ்ய  பரிபந்தி₂  நௌ ॥3.34॥",

    "ஶ்ரேயாந் ஸ்வத₄ர்மோ விகு₃ணப்₂, பரத₄ர்மாத்ஸ்வநுஷ்டி₂தாத்।\nஸ்வத₄ர்மே  நித₄னம்  ஶ்ரேயப்₂, பரத₄ர்மோ  ப₄யாவஹஹ ॥3.35॥",

    "அர்ஜுந உவாச \nஅத₂  கேந  ப்ரயுக்தோSயம், பாபஞ்  சரதி  பூருஷஹ। \nஅநிச்ச₂ந்நபி  வார்ஷ்ணேய, ப₃லாதி₃வ  நியோஜிதஹ ॥3.36॥",

    "ஸ்ரீ ப₄க₃வாநுவாச\nகாம ஏஷ  க்ரோத₄  ஏஷ, ரஜோகு₃ண  ஸமுத்₃ப₄வஹ ।\nமஹாஶநோ  மஹாபாப்மா, வித்₃த்₄யேநமிஹ  வைரிணம் ॥3.37॥",

    "தூ₄மேநாவ்ரியதே  வஹ்நிர், யதா₂த₃ர்ஶோ  மலேந ச।\nயதோ₂ல்பே₃நாவ்ருதோ  க₃ர்ப₄ஸ், ததா₂  தேநேத₃மாவ்ருதம் ॥3.38॥",

    "ஆவ்ருதஞ்  ஜ்ஞானமேதேந, ஜ்ஞானிநோ  நித்யவைரிணா।\nகாமரூபேண கௌந்தேய, து₃ஷ்பூரேணாநலேந  ச ॥3.39॥",

    "இந்த்₃ரியாணி  மநோ  பு₃த்₃தி₄ர், அஸ்யாதி₄ஷ்டா₂நமுச்யதே।\nஏதைர் விமோஹயத்யேஷ, ஜ்ஞாநமாவ்ருத்ய தே₃ஹிநம் ॥3.40॥",

    "ஸ்மாத்த்வமிந்த்₃ரியாண்யாதௌ₃, நியம்ய ப₄ரதர்ஷப₄। \nபாப்மாநம்  ப்ரஜஹிஹ்யேநஞ்,  ஜ்ஞான விஜ்ஞான நாஶனம் ॥3.41॥",

    "இந்த்₃ரியாணி பராண்யாஹுர், இந்த்₃ரியேப்₄யப்₂  பரம் மனஹ।\nமனஸஸ்து  பரா  புத்₃தி₄ர், யோ பு₃த்₃தேப்₂  பரதஸ்து  ஸஹ ॥3.42॥",

    "ஏவம் பு₃த்₃தே₄ப்₂  பரம்  பு₃த்₃த்₄வா, ஸம்தப்₄யாத்மாநமாத்மநா।\nஜஹி  ஶத்ரும் மஹாபா₃ஹோ,  காமரூபந்  து₃ராஸத₃ம் ॥3.43॥",

    "ஓம் தத்ஸதி₃தி  ஸ்ரீமத்₃ப₄க₃வத்₃கீ₃தாஸு  உபநிஶத்ஸு ப்₃ரஹ்ம வித்₃யாயாம்(ய்)  யோக₃ஶாஸ்த்ரே  ஸ்ரீ க்ருஷ்ணார்ஜுந ஸம்வ்வாதே கர்மயோகோ₃ நாம த்ருதீயோSத்₄யாய:(ஹ)"
  ],
  "adhyaya-4" : [
    "ஶ்ரீ ப₄க₃வான் உவாச\nஇமம் விவஸ்வதே யோக₃ம், ப்ரோக்தவானஹமவ்யயம்। \nவிவஸ்வான் மனவே ப்ராஹ, மனுரிக்ஷ்வாகவேSப்₃ரவீத் ॥4.1॥",

    "ஏவம் பரம்பரா ப்ராப்தம், இமம் ராஜர்ஷயோ விது₃ஹு।\nஸ காலேநேஹ மஹதா, யோகோ₃ நஷ்ட(ப்₂) பரந்தப ॥152॥",

    "ஸ ஏவாயம் மயா தேSத்₃ய,  யோக₃(ப்₂) ப்ரோக்த(ப்₂) புராதனஹ ।\nப₄க்தோSஸி மே ஸகா₂ சேதி,  ரஹஸ்யம் ஹ்யேதது₃த்தமம் ॥4.3॥",

    "அர்ஜுன உவாச\nஅபரம் ப₄வதோ ஜன்ம, பரஞ் ஜன்ம விவஸ்வதஹ ।\nகத₂மேதத்₃விஜானீயாந், த்வமாதௌ₃ ப்ரோக்தவானிதி ॥4.4॥",

    "ஶ்ரீ ப₄க₃வான் உவாச\nப₃ஹூநி மே வ்யதீதாநி, ஜன்மாநி தவ சார்ஜுன ।\nதாந்யஹம் வேத₃ ஸர்வாணி,  ந த்வம் வேத்த₂ பரந்தப ॥4.5॥",

    "அஜோSபி ஸந்நவ்யயாத்மா, பூ₄தாநாமீஶ்வரோSபி ஸன் । \nப்ரக்ருதிம் ஸ்வாமதி₄ஷ்டா₂ய,  ஸம்ப₄வாம்யாத்மமாயயா ॥4.6॥",

    "யதா₃ யதா₃ ஹி த₄ர்மஸ்ய,  க்₃லாநிர்ப₄வதி பா₄ரத।\nஅப்₄யுத்தா₂நமத₄ர்மஸ்ய, ததா₃த்மானம்  ஸ்ருஜாம்யஹம் ॥4.7॥",

    "பரித்ராணாய  ஸாதூ₄நாம், விநாஶாய ச து₃ஷ்க்ருதாம்।\nத₄ர்ம ஸம்ஸ்தா₂பநார்தா₂ய, ஸம்ப₄வாமி யுகே₃ யுகே₃ ॥4.8॥",

    "ஜன்ம கர்ம ச மே தி₃வ்யம், ஏவம் யோ வேத்தி தத்த்வதஹ ।\nத்யக்த்வா தே₃ஹம் புநர்ஜன்ம, நைதி மாமேதி ஸோSர்ஜுன ॥4.9॥",

    "வீதராக₃ப₄யக்ரோதா₄, மந்மயா மாமுபாஶ்ரிதாஹா।\nப₃ஹவோ ஜ்ஞானதபஸா, பூதா மத்₃பா₄வமாக₃தா ஹா॥4.10॥",

    "யே யதா₂ மாம் ப்ரபத்₃யந்தே, தாம்ஸ்ததை₂வ ப₄ஜாம்யஹம் ।\nமம வர்த்மாநுவர்தந்தே, மநுஷ்யா(ப்₂) பார்த₂ ஸர்வஶ ஹ॥4.11॥",

    "காங்க்ஷந்த(க்₂) கர்மணாம் ஸித்₃தி₄ம், யஜந்த இஹ தே₃வதாஹா ।\nக்ஷிப்ரம் ஹி மாநுஷே லோகே,  ஸித்₃தி₄ர்ப₄வதி கர்ம ஜா॥4.12॥",

    "சாதுர்வர்ண்யம் மயா ஸ்ருஷ்டங், கு₃ணகர்மவிபா₄க₃ஶஹ ।\nதஸ்ய கர்தாரமபி மாம், வித்₃த்₄யகர்தாரமவ்யய ம்॥4.13॥",

    "ந மாங் கர்மாணி லிம்பந்தி, ந மே கர்மப₂லே ஸ்ப்ருஹா ।\nஇதி மாம் யோSபி₄ஜாநாதி, கர்மபி₄ர்ந ஸ பத்₄ய தே॥4.14॥",

    "ஏவஞ் ஜ்ஞாத்வா க்ருதங்கர்ம, பூர்வைரபி முமுக்ஷுபி₄ஹி ।\nகுரு கர்மைவ தஸ்மாத்த்வம், பூர்வை(ப்₂) பூர்வதரங் க்ருத ம்॥4.15॥",

    "கிங் கர்ம கிமகர்மேதி, கவயோSப்யத்ர மோஹிதாஹா ।\nதத்தே கர்ம ப்ரவக்ஷ்யாமி, யஜ்ஞாத்வா மோக்ஷ்யஸேSஶுபா₄ த்॥4.16॥",

    "கர்மணோ ஹ்யபி போ₃த்₃த₄வ்யம், போ₃த்₃த₄வ்யஞ் ச விகர்மணஹ । \nஅகர்மணஶ்ச போ₃த்₃த₄வ்யங், க₃ஹநா கர்மணோ க₃தி ஹி॥4.17॥",

    "கர்மண்யகர்ம ய(ப்₂) பஶ்யேத்₃, அகர்மணி ச கர்ம யஹ।\nஸ பு₃த்₃தி₄மாந் மநுஷ்யேஷு, ஸ யுக்த(க்₂) க்ருத்ஸ்நகர்மக்ரு த்॥4.18॥",

    "யஸ்ய ஸர்வே ஸமாரம்பா₄(க்₂), காம ஸங்கல்பவர்ஜிதாஹா ।\nஜ்ஞானாக்₃னித₃க்₃த₄கர்மாணந், தமாஹு(ப்₂)  பண்டிதம் பு₃தா₄ ஹா॥4.19॥",

    "த்யக்த்வா கர்மப₂லாஸங்க₃ந், நித்ய த்ருப்தோ நிராஶ்ரயஹ।\nகர்மண்யபி₄ப்ரவ்ருத்தோSபி, நைவ கிஞ்சித் கரோதி ஸ ஹ॥4.20॥",

    "நிராஶீர்யதசித்தாத்மா, த்யக்தஸர்வபரிக்₃ரஹஹ।\nஶாரீரங் கேவலங் கர்ம, குர்வந்நாப்நோதி கில்பி₃ஷ ம்॥4.21॥",

    "யத்₃ருச்சா₂லாப₄ஸந்துஷ்டோ, த்₃வந்த்₃வாதீதோ விமத்ஸரஹ।\nஸமஸ் ஸித்₃தா₄வஸித்₃தௌ₄ ச, க்ருத்வாபி ந நிப₃த்₄ய தே॥4.22॥",

    "க₃தஸங்க₃ஸ்ய முக்தஸ்ய, ஜ்ஞானாவஸ்தி₂த சேதஸஹ।\nயஜ்ஞாயாசரத(க்₂) கர்ம, ஸமக்₃ரம் ப்ரவிலீய தே॥4.23॥",

    "ப்₃ரஹ்மார்பணம் ப்₃ரஹ்மஹவிர், ப்₃ரஹ்மாக்₃நௌ ப்₃ரஹ்மணாஹுதம்।\nப்₃ரஹ்மைவதேந க₃ந்தவ்யம், ப்₃ரஹ்மகர்மஸமாதி₄ நா॥4.24॥",

    "தை₃வமேவாபரே யஜ்ஞம், யோகி₃ன(ப்₂) பர்யுபாஸதே ।\nப்₃ரஹ்மாக்₃நாவபரே யஜ்ஞம், யஜ்ஞே நைவோபஜுஹ்வ தி॥4.25॥",

    "ஶ்ரோத்ராதீ₃நீந்த்₃ரியாண்யந்யே, ஸம்யமாக்₃நிஷு ஜுஹ்வதி।\nஶப்₃தா₃தீ₃ன் விஷயாநந்ய, இந்த்₃ரியாக்₃னிஷுஜுஹ்வ தி॥4.26॥",

    "ஸர்வாணீந்த்₃ரிய கர்மாணி,  ப்ராணகர்மாணி சாபரே।\nஆத்மஸம்யம யோகா₃க்₃நௌ, ஜுஹ்வதி ஜ்ஞானதீ₃பி தே॥4.27॥",

    "த்₃ரவ்யயஜ்ஞாஸ்தபோயஜ்ஞா, யோக₃யஜ்ஞாஸ்ததா₂பரே।\nஸ்வாத்₄யாயஜ்ஞானயஜ்ஞாஶ்ச, யதயஸ் ஸம்ஶிதவ்ரதா ஹா॥4.28॥",

    "அபானே ஜுஹ்வதி ப்ராணம், ப்ராணேSபானந் ததா₂பரே ।\nப்ராணாபானக₃தீ ருத்₃த்₄வா, ப்ராணாயாம பராயணா ஹா॥4.29॥",

    "அபரே நியதாஹாரா(ப்₂), ப்ராணாந்ப்ராணேஷு ஜுஹ்வதி।\nஸர்வேப்யேதே யஜ்ஞவிதோ₃, யஜ்ஞக்ஷபிதகல்மஷா ஹா॥4.30॥",

    "யஜ்ஞஶிஷ்டாம்ருதபு₄ஜோ, யாந்தி ப்₃ரஹ்ம ஸநாதனம் ।\nநாயம் லோகோSஸ்த்யயஜ்ஞஸ்ய, குதோந்ய(க்₂) குருஸத்த ம॥4.31॥",

    "ஏவஞ் ப₃ஹுவிதா₄ யஜ்ஞா, விததா ப்₃ரஹ்மணோ முகே₂ ।\nகர்மஜாந்வித்₃தி₄ தாந்ஸர்வாந், ஏவந் ஜ்ஞாத்வா விமோக்ஷ்ய ஸே॥4.32॥",

    "ஶ்ரேயாந்த்₃ரவ்யமயாத்₃யஜ்ஞாஜ், ஜ்ஞானயஜ்ஞ(ப்₂) பரந்தப । \nஸர்வங் கர்மாகி₂லம் பார்த₂, ஜ்ஞானே பரிஸமாப்ய தே॥4.33॥",

    "தத்₃வித்₃தி₄ ப்ரணிபாதேன, பரிப்ரஶ்னேன ஸேவயா ।\nஉபதே₃க்ஷ்யந்தி தே ஜ்ஞானஞ், ஜ்ஞானினஸ்தத்த்வ த₃ர்ஶின ஹ॥4.34॥",

    "யஜ்ஞாத்வா ந புநர்மோஹம், ஏவம் யாஸ்யஸி பாண்ட₃வ।\nயேன பூ₄தாந்யஶேஷேண, த்₃ரக்ஷ்யஸ்யாத்மந்யதோ₂ ம யி॥4.35॥",

    "அபி சேத₃ஸி பாபேப்₄யஸ், ஸர்வேப்₄ய(ப்₂) பாபக்ருத்தமஹ । \nஸர்வஞ் ஜ்ஞானப்லவேனைவ, வ்ருஜிநம் ஸந்தரிஷ்ய ஸி॥4.36॥",

    "யதை₂தா₄ம்ஸி ஸமித்₃தோ₄க்₃னிர், ப₄ஸ்மஸாத்குருதேSர்ஜுன।\nஜ்ஞானாக்₃னிஸ் ஸர்வகர்மாணி, ப₄ஸ்மஸாத்குருதே ததா 2॥4.37॥",

    "ந ஹி ஜ்ஞானேன ஸத்₃ருஶம், பவித்ரமிஹ வித்₃யதே।\nதத்ஸ்வயம் யோக₃ஸம்ஸித்₃த₄(க்₂), காலேனாத்மனி விந்த₃ தி॥4.38॥",

    "ஶ்ரத்₃தா₄வா(ந்)ல்லப₄தே ஜ்ஞானந், தத்பரஸ் ஸம்யதேந்த்₃ரியஹ।\nஜ்ஞானம் லப்₃த்₄வா பராம் ஶாந்திம், அசிரேணாதி₄க₃ச்ச₂ தி॥4.39॥",

    "அஜ்ஞஶ்சாஶ்ரத்₃தா₄நஶ்ச, ஸம்ஶயாத்மா விநஶ்யதி।\nநாயம் லோகோSஸ்தி ந பரோ, ந ஸுக₂ம் ஸம்ஶயாத்மன ஹ॥4.40॥",

    "யோக₃ ஸந்ந்யஸ்த கர்மாணஞ், ஜ்ஞானஸச்சி₂ந்நஸம்ஶயம்।\nஆத்மவந்தந் ந கர்மாணி, நிப₃த்₄நந்தி த₄னஞ்ஜ ய॥4.41॥",

    "தஸ்மாத₃ஜ்ஞானஸம்பூ₄தம், ஹ்ருத்ஸ்த₂ஞ் ஜ்ஞானாஸிநாத்மனஹ।\nசி₂த்த்வைநம் ஸம்ஶயம் யோக₃ம், ஆதிஷ்டோ₂த்திஷ்ட₂ பா₄ரத ॥4.42॥",

    "ஓம் தத்ஸதி₃தி ஶ்ரீமத்₃ப₄க₃வத்₃கீ₃தாஸு உபநிஷத்ஸு ப்₃ரஹ்மவித்₃யாயாம் யோக₃ஶாஸ்த்ரே ஶ்ரீ க்ருஷ்ணார்ஜுனஸம்வாதே₃ ஜ்ஞானகர்மஸந்ந்யாஸயோகோ  நாம சதுர்தோ₂Sத்₄யாயஹ॥4॥"
  ],
  "adhyaya-5" : [
    "அர்ஜுன உவாச \nஸந்ந்யாஸங் கர்மணாங் க்ருஷ்ண, புநர்யோக₃ஞ் ச ஶம்வ்ஸஸி।\nயச்ச்₂ரேய ஏதயோரேகந், தந்மே ப்₃ரூஹி ஸுநிஶ்சிதம் ॥5.1॥",

    "ஸ்ரீ ப₄க₃வாநுவாச\nஸந்ந்யாஸக்₂ கர்மயோக₃, நிஹ்ஶ்ரேயஸகராவுபௌ₄।\nதயோஸ்து கர்மஸந்ந்யாஸாத், கர்மயோகோ₃ விஶிஷ்யதே ॥5.2॥",

    "ஜ்ஞேயஸ் ஸ நித்ய ஸந்ந்யாஸீ, யோ ந த்₃வேஷ்டி.ந காங்க்ஷதி।\nநிர்த்₃வந்த்₃வோ ஹி மஹாபா₃ஹோ, ஸுக₂ம் ப₃ந்தா₄த்ப்ரமுச்யதே ॥5.3॥",

    "ஸாங்க்₂யயோகௌ₃ ப்ருத₂க்₃பா₃லாப்₂, ப்ரவத₃ந்தி ந பண்டி₃தாஹா।\nஏகமப்யாஸ்தி₂தஸ் ஸம்யக்₃, உப₄யோர்விந்த₃தே ப₂லம் ॥5.4॥",

    "யத்ஸாங்க்₂யைப்₂ ப்ராப்யதே ஸ்தா₂நந், தத்₃யோகை₃ரபி க₃ம்யதே। \nஏகம் ஸாங்க்₂யஞ் ச யோக₃ஞ் ச, யப்₂ பஶ்யதி ஸ பஶ்யதி ॥5.5॥",

    "ஸந்ந்யாஸஸ்து மஹாபா₃ஹோ, துஹ்க₂மாப்துமயோக₃தஹ।\nயோக₃யுக்தோ முநிர்ப்₃ரஹ்ம, நசிரேணாதி₄க₃ச்ச₂தி ॥5.6॥",

    "யோக₃யுக்தோ விஶுத்₃தா₄த்மா, விஜிதாத்மா ஜிதேந்த்₃ரியஹ।\nஸர்வபூ₄தாத்ம பூ₄தாத்மா, குர்வந்நபி ந லிப்யதே ॥5.7॥",

    "நைவ கிஞ்சித்கரோமீதி, யுக்தோ மந்யேத தத்த்வவித்।\nபஶ்யஞ்ஶ்ருண்வந்ஸ்ப்ருஶஞ்ஜிக்₄ரந், நஶ்நந்க₃ச்ச₂ந்ஸ்வபஞ்ஶ்வஸந் ॥5.8॥",

    "ப்ரலபந்விஸ்ருஜந்க்₃ருஹ்ணந், நுந்மிஷந்நிமிஷந்நபி।\nஇந்த்₃ரியாணீந்த்₃ரியார்தே₂ஷு, வர்தந்த இதி தா₄ரயந் ॥5.9॥",

    "ப்₃ரஹ்மண்யாதா₄ய கர்மாணி, ஸங்க₃ந் த்யக்த்வா கரோதி யஹ।\nலிப்யதே ந ஸ பாபேந, பத்₃மபத்ரமிவாம்ப₄ஸா ॥5.10॥",

    "காயேந மநஸா பு₃த்₃த்₄யா, கேவலைரிந்த்₃ரியைரபி।\nயோகிநக்₂ கர்ம குர்வந்தி, ஸங்க₃ந் த்யக்த்வாத்மஶுத்₃த₄யே ॥5.11॥",

    "யுக்தக்₂ கர்மப₂லந் த்யக்த்வா,ஶாந்திமாப்நோதி நைஷ்டி₂கீம்।\nஅயுக்தக்₂ காமகாரேண, ப₂லேஸக்தோ நிப₃த்₄யதே ॥5.12॥",

    "ஸர்வகர்மாணி மநஸா, ஸந்ந்யஸ்யாஸ்தே ஸுக₂ம் வஶீ। \nநவத்₃வாரே புரே தே₃ஹீ, நைவ குர்வந்ந காரயந் ॥5.13॥",

    "ந கர்த்ருத்வந் ந கர்மாணி, லோகஸ்ய ஸ்ருஜதி ப்ரபு₄ஹு।\nந கர்மப₂லஸம்யோக₃ம், ஸ்வபா₄வஸ்து ப்ரவர்ததே ॥5.14॥",

    "நாத₃த்தே கஸ்யசித்பாபந், ந சைவ ஸுக்ருதம் விபு₄ஹு।\nஅஜ்ஞாநேநாவ்ருதஞ் ஜ்ஞாநந், தேந முஹ்யந்தி ஜந்தவஹ ॥5.15॥",

    "ஜ்ஞாநேந து தத₃ஜ்ஞாநம், யேஷாந் நாஶிதமாத்மநஹ। \nதேஷாமாதி₃த்யவஜ்ஞாநம், ப்ரகாஶயதி தத்பரம் ॥5.16॥",

    "தத்₃பு₃த்₃த₄யஸ்ததா₃த்மாநஸ், தந்நிஷ்டா₂ஸ்தத்பராயணாஹா।\nக₃ச்ச₂ந்த்ய புநராவ்ருத்திஞ், ஜ்ஞாநநிர்தூ₄தகல்மஷாஹா ॥5.17॥",

    "வித்₃யா விநய ஸம்பந்நே, ப்₃ராஹ்மணே க₃வி ஹஸ்திநி।\nஶுநி சைவ ஶ்வபாகே ச, பண்டி₃தாஸ் ஸம த₃ர்ஶிநஹ ॥5.18॥",

    "இஹைவ தைர்ஜிதஸ் ஸர்கோ₃, யேஷாம் ஸாம்யே ஸ்தி₂தம் மநஹ।\nநிர்தோ₃ஷம் ஹி ஸமம் ப்₃ரஹ்ம, தஸ்மாத்₃ ப்₃ரஹ்மணி தே ஸ்தி₂தாஹா ॥5.19॥",

    "ந ப்ரஹ்ருஷ்யேத்ப்ரியம் ப்ராப்ய, நோத்₃விஜேத்ப்ராப்ய சாப்ரியம்।\nஸ்தி₂ரபு₃த்₃தி₄ரஸம்மூடோ₄, ப்₃ரஹ்மவித்₃ ப்₃ரஹ்மணி ஸ்தி₂தஹ ॥5.20॥",

    "பா₃ஹ்யஸ்பர்ஶேஷ்வஸக்தாத்மா, விந்த₃த்யாத்மநி யத்ஸுக₂ம்।\nஸ ப்₃ரஹ்மயோக₃ யுக்தாத்மா, ஸுக₂மக்ஷயமஶ்நுதே ॥5.21॥",

    "யே ஹி ஸம்ஸ்பர்ஶஜா போ₄கா₃, து₃ஹ்க₂யோநய ஏவ தே।\nஆத்₃யந்தவந்தக்₂ கௌந்தேய, ந தேஷு ரமதே பு₃த₄ஹ ॥5.22॥",

    "ஶக்நோதீஹைவ யஸ் ஸோடு₄ம், ப்ராக்ஶரீரவிமோக்ஷணாத்।\nகாமக்ரோதோ₄த்₃ப₄வம் வேக₃ம், ஸ யுக்தஸ் ஸ ஸுகீ₂ நரஹ ॥5.23॥",

    "யோSந்தஹ்ஸுகோ₂ந்தராராமஸ், ததா₂ந்தர்ஜ்யோதிரேவ யஹ।\nஸ யோகீ ப்₃ரஹ்மநிர்வாணம், ப்₃ரஹ்மபூ₄தோSதி₄க₃ச்ச₂தி ॥5.24॥",

    "லப₄ந்தே ப்₃ரஹ்மநிர்வாணம், ர்ஷயஹ் க்ஷீணகல்மஷாஹா। \nசி₂ந்ந்த்₃வைதா₄ யதாத்மாநஸ், ஸர்வபூ₄தஹிதே ரதாஹா ॥5.25॥",

    "காமக்ரோத₄ வியுக்தாநாம், யதீநாம் யதசேதஸாம்।\nஅபி₄தோ ப்₃ரஹ்மநிர்வாணம், வர்ததே விதி₃தாத்மநாம் ॥5.26॥",

    "ஸ்பர்ஶாந்க்ருத்வா ப₃ஹிர்பா₃ஹ்யாம்ஶ், சக்ஷுஶ்சைவாந்தரேப்₄ருவோஹோ।\nப்ராணாபாநௌ ஸமௌ க்ருத்வா, நாஸாப்₄யந்தரசாரிணௌ ॥5.27॥",

    "யதேந்த்₃ரிய மநோபு₃த்₃தி₄ர், முநிர்மோக்ஷபராயணஹ। \nவிக₃தேச்சா₂ப₄யக்ரோதோ₄, யஸ் ஸதா₃ முக்த ஏவ ஸஹ ॥5.28॥",

    "போ₄க்தாரம் யஜ்ஞதபஸாம், ஸர்வலோக மஹேஶ்வரம்। \nஸுஹ்ருத₃ம் ஸர்வபூ₄தாநாஞ், ஜ்ஞாத்வா மாம் ஶாந்திம்ருச்ச₂தி ॥5.29॥",

    "ஓம் தத்ஸதி₃தி ஸ்ரீமத்₃ப₄க₃வத்₃கீ₃தாஸு உபநிஷத்ஸு ப்₃ரஹ்மவித்₃யாயாம் யோக₃ ஶாஸ்த்ரே ஸ்ரீக்ருஷ்ணார்ஜுந ஸம்வாதே₃ கர்மஸந்ந்யாஸயோகோ₃ நாம பஞ்சமோSத்₄யாயஹ ॥5॥"
  ],
  "adhyaya-6" : [
    "ஶ்ரீ ப₄க₃வாநுவாச\nஅநாஶ்ரித;(க்₂) கர்ம  ஃப₂லங், கார்யங்கர்ம கரோதி யஹ।\nஸ ஸந்ந்யாஸீ ச யோகீ₃ ச, ந நிரக்₃னிர் ந சாக்ரியஹ ॥6.1॥",

    "யம்  ஸந்ந்யாஸமிதி ப்ராஹுர், யோக₃ந் தம் வித்₃தி₄ பாண்ட₃வ।\nந ஹ்ய ஸந்ந்யஸ்த ஸங்கல்போ, யோகீ₃ ப₄வதி கஶ்சன ॥6.2॥",

    "ஆருருக்ஷோர்முநேர்யோக₃ங்,  கர்ம காரணமுச்யதே।\nயோகா₃ரூட₄ஸ்ய தஸ்யைவ, ஶமக்₂ காரணமுச்யதே ॥6.3॥",

    "யதா₃ ஹி நேந்த்₃ரியார்தே₂ஷு, ந கர்ம ஸ்வநுஷஜ்ஜதே।\nஸர்வ ஸங்கல்ப ஸந்ந்யாஸீ, யோகா₃ரூட₄ஸ்ததோ₃ச்யதே ॥6.4॥",

    "உத்₃த₄ரேதா₃த்மநாத்மாநந், நாத்மாநமவஸாத₃யேத்।\nஆத்மைவ ஹ்யாத்மநோ ப₃ந்து₄ர், ஆத்மைவ ரிபுராத்மநஹ ॥6.5॥",

    "ப₃ந்து₄ராத்மாத்மனஸ்தஸ்ய, யேநாத்மைவாத்மநா ஜிதஹ।\nஅநாத்மநஸ்து ஶத்ருத்வே, வர்தேதாத்மைவ ஶத்ருவத் ॥6.6॥",

    "ஜிதாத்மநப்₂ ப்ரஶாந்தஸ்ய, பரமாத்மா ஸமாஹிதஹ।\nஶீதோஷ்ண ஸுக₂ து₃கே₂ஷு, ததா₂ மாநாபமாநயோஹோ ॥6.7॥",

    "ஜ்ஞான விஜ்ஞான த்ருப்தாத்மா, கூடஸ்தோ₂ விஜிதேந்த்₃ரியஹ।\nயுக்த இத்யுச்யதே யோகீ₃,  ஸம லோஷ்டாஶ்ம காஞ்சனஹ ॥6.8॥",

    "ஸுஹ்ருண் மித்ரார்யுதா₃ஸீன, மத்₄யஸ்த₂ த்₃வேஷ்யப₃ந்து₄ஷு। \nஸாது₄ஷ்வபி ச பாபேஷு, ஸமபு₃த்₃தி₄ர்விஶிஷ்யதே ॥6.9॥",

    "யோகீ யுஞ்ஜீத ஸததம், ஆத்மானம் ரஹஸி ஸ்தி₂தஹ।\nஏகாகீ யத சித்தாத்மா, நிராஶீரபரிக்ரஹஹ ॥6.10॥",

    "ஶுசௌ தே₃ஶே ப்ரதிஷ்டா₂ப்ய, ஸ்தி₂ரமாஸனமாத்மனஹ।\nநாத்யுச்ச்₂ரிதந் நாதிநீசஞ், சைலாஜின குஶோத்தரம் ॥6.11॥",

    "தத்ரைகாக்₃ரம் மனக்₂ க்ருத்வா, யதசித்தேந்த்ரியக்ரியஹ।\nஉபவிஶ்யாஸனே யுஞ்ஜ்யாத்₃, யோக₃மாத்மவிஶுத்₃த₄யே ॥6.12॥",

    "ஸமங் காயஶிரோக்₃ரீவந், தா₄ரயந்நசலம் ஸ்தி₂ரஹ।\nஸம்ப்ரேக்ஷ்ய நாஸிகாக்₃ரம் ஸ்வந், திஶஶ்சாநவலோகயந் ॥6.13॥",

    "ப்ரஶாந்தாத்மா விக₃தபீ₄ர், ப்₃ரஹ்மசாரிவ்ரதே ஸ்தி₂தஹ।\nமனஸ் ஸம்யம்ய மச்சித்தோ, யுக்த ஆஸீத மத்பரஹ ॥6.14॥",

    "யுஞ்ஜந்நேவம் ஸதா₃த்மானம், யோகீ₃ நியத மாநஸஹ।\nஶாந்திந் நிர்வாணபரமாம், மத்ஸம்ஸ்தா₂மதி₄க₃ச்ச₂தி ॥6.15॥",

    "நாத்யஶ்னதஸ்து யோகோ₃Sஸ்தி, ந சைகாந்தமநஶ்நதஹ।\nந சாதி ஸ்வப்னஶீலஸ்ய, ஜாக்₃ரதோ நைவ சார்ஜுன ॥6.16॥",

    "யுக்தாஹார விஹாரஸ்ய, யுக்தசேஷ்டஸ்ய கர்மஸு।\nயுக்த ஸ்வப்நாவபோ₃த₄ஸ்ய, யோகோ₃ ப₄வதி துக₂ஹா ॥6.17॥",

    "யதா₃ விநியதஞ் சித்தம், ஆத்மந்யேவாவதிஷ்ட₂தே।\nநிஸ்ப்ருஹஸ் ஸர்வகாமேப்₄யோ, யுக்த இத்யுச்யதே ததா₃ ॥6.18॥",

    "யதா₂ தீ₃போ நிவாதஸ்தோ₂, நேங்க₃தே ஸோபமா ஸ்ம்ருதா।\nயோகி₃நோ யத சித்தஸ்ய, யுஞ்ஜதோ யோக₃மாத்மனஹ ॥6.19॥",

    "யத்ரோபரமதே சித்தந், நிருத்₃த₄ம் யோக₃ ஸேவயா।\nயத்ர சைவாத்மநாத்மானம், பஶ்யந்நாத்மனி துஷ்யதி ॥6.20॥",

    "ஸுக₂மாத்யந்திகம் யத்தத்₃, பு₃த்₃தி₄ க்₃ராஹ்யமதீந்த்₃ரியம்।\nவேத்தி யத்ர ந சைவாயம், ஸ்தி₂தஶ்சலதி தத்த்வதஹ ॥6.21॥",

    "யம் லப்₃த்₄வா சாபரம் லாப₄ம், மந்யதே நாதி₄கம் ததஹ।\nயஸ்மிந்ஸ்தி₂தோ ந து₃கே₂ன, கு₃ருணாபி விசால்யதே ॥6.22॥",

    "தம் வித்₃யாத்₃ து₃க₂ ஸம்யோக₃, வியோக₃ம் யோக₃ ஸஞ்ஜ்ஞிதம்।\nஸ நிஶ்சயேன யோக்தவ்யோ, யோகோ₃Sநிர்விண்ணசேதஸா ॥6.23॥",

    "ஸங்கல்ப ப்ரப₄வான்காமாம்ஸ், த்யக்த்வா ஸர்வாநஶேஷதஹ।\nமனஸைவேந்த்₃ரியக்₃ராமம், விநியம்ய ஸமந்த தஹ ॥6.24॥",

    "ஶநைஶ் ஶநைருபரமேத்₃, புத்₃த்₄யா த்₄ருதி க்₃ருஹீதயா।\nஆத்ம ஸம்ஸ்த₂ம் மனக்₂ க்ருத்வா, ந கிஞ்சித₃பி சிந்தயேத் ॥6.25॥",

    "யதோ யதோ நிஶ்சரதி, மனஶ்சஞ்சலமஸ்தி₂ரம்।\nததஸ்ததோ நியம்யைதத்₃, ஆத்மந்யேவ வஶந் நயேத் ॥6.26॥",

    "ப்ரஶாந்த மனஸம் ஹ்யேநம், யோகி₃னம் ஸுக₂முத்தமம்।\nஉபைதி ஶாந்தரஜஸம், ப்₃ரஹ்ம்பூ₄தமகல்மஷம் ॥6.27॥",

    "யுஞ்ஜந்நேவம் ஸதா₃த்மானம், யோகீ₃ விக₃தகல்மஷஹ।\nஸுகே₂ன ப்₃ரஹ்ம ஸம்ஸ்பர்ஶம், அத்யந்தம் ஸுக₂மஶ்நுதே ॥6.28॥",

    "ஸர்வ பூ₄தஸ்த₂மாத்மானம், ஸர்வ பூ₄தானி சாத்மனி।\nஈக்ஷதே யோக₃யுக்தாத்மா, ஸர்வத்ர ஸம த₃ர்ஶனஹ ॥6.29॥",

    "யோ மாம் பஶ்யதி ஸர்வத்ர, ஸர்வஞ் ச மயி பஶ்யதி।\nதஸ்யாஹந் ந  ப்ரணஶ்யாமி, ஸ ச மே ந ப்ரணஶ்யதி ॥6.30॥",

    "ஸர்வபூ₄தஸ்தி₂தம் யோ மாம்,ப₄ஜத்யேகத்வமாஸ்தி₂தஹ।\nஸர்வதா₂ வர்தமாநோSபி, ஸ யோகீ₃ மயி வர்ததே ॥6.31॥",

    "ஆத்மௌபம்யேன ஸர்வத்ர, ஸமம் பஶ்யதி யோSர்ஜுன।\nஸுக₂ம் வா யதி₃ வா து₃க₂ம், ஸ யோகி₃ பரமோ மதஹ ॥6.32॥",

    "அர்ஜுன உவாச\nயோSயம் யோக₃ஸ்த்வயா ப்ரோக்தஸ், ஸாம்யேன மது₄ஸூத₃ன। \nஏதஸ்யாஹந் ந பஶ்யாமி, சஞ்சலத்வாத்ஸ்தி₂திம் ஸ்தி₂ராம் ॥6.33॥",

    "சஞ்சலம் ஹி மனக்₂ க்ருஷ்ண, ப்ரமாதி₂ ப₃லவத்₃த்₃ருட₄ம்।\nதஸ்யாஹந் நிக்₃ரஹம் மந்யே, வாயோரிவ ஸுது₃ஷ்கரம் ॥6.34॥",

    "ஶ்ரீ ப₄க₃வாநுவாச\nஅஸம்ஶயம் மஹாபா₃ஹோ, மனோ து₃ர்நிக்₃ரஹஞ் சலம்।\nஅப்₄யாஸேன து கௌந்தேய, வைராக்₃யேண ச க்₃ருஹ்யதே ॥6.35॥",

    "அஸம்யதாத்மனா யோகோ₃, துஷ்ப்ராப இதி மே மதிஹி।\nவஶ்யாத்மனா து யததா, ஶக்யோSவாப்துமுபாயதஹ ॥6.36॥",

    "அர்ஜுன உவாச\nஅயதிஶ் ஶ்ரத்₃த₄யோபேதோ, யோகா₃ச்சலிதமாநஸஹ।\nஅப்ராப்ய யோக₃ ஸம்ஸித்₃தி₄ங், காங் க₃திங் க்ருஷ்ண க₃ச்ச₂தி ॥6.37॥",

    "கச்சிந்நோப₄யவிப்₄ரஷ்டஶ் சி₂ந்நாப்₄ரமிவ நஶ்யதி।\nஅப்ரதிஷ்டோ₂ மஹாபா₃ஹோ, விமூடோ₄ ப்₃ரஹ்மணப்₂ பதி₂ ॥6.38॥",

    "ஏதன்மே ஸம்ஶயங் க்ருஷ்ண, சே₂த்துமர்ஹஸ்யஶேஷதஹ।\nத்வத₃ந்யஸ் ஸம்ஶயஸ்யாஸ்ய, சே₂த்தா ந ஹ்யுபபத்₃யதே ॥6.39॥",

    "ஶ்ரீ ப₄க₃வாநுவாச\nபார்த₂ நைவேஹ நாமுத்ர, விநாஶஸ்தஸ்ய வித்₃யதே।\nந ஹி கல்யாணக்ருத்கஶ்சித்₃, து₃ர்க₃திந் தாத க₃ச்ச₂தி ॥6.40॥",

    "ப்ராப்ய புண்யக்ருதாம் லோகாந், உஷித்வா ஶாஶ்வதீஸ் ஸமாஹா।\nஶுசீநாம் ஶ்ரீமதாங்கே₃ஹே, யோக₃ப்₄ரஷ்டோSபி₄ஜாயதே ॥6.41॥",

    "அத₂வா யோகி₃நாமேவ, குலே ப₄வதி தீ₄மதாம்।\nஏதத்₃தி₄ து₃ர்லப₄தரம், லோகே ஜன்ம யதீ₃த்₃ருஶம் ॥6.42॥",

    "தத்ர தம் பு₃த்₃தி₄ ஸம்யோக₃ம், லப₄தே பௌர்வதே₃ஹிகம்।\nயததே ச ததோ பூ₄யஸ், ஸம்ஸித்₃தௌ₄ குருநந்த₃ன ॥6.43॥",

    "பூர்வாப்₄யாஸேந தேநைவ, ஹ்ரியதே ஹ்யவஶோSபி ஸஹ। \nஜிஜ்ஞாஸுரபி யோக₃ஸ்ய, ஶப்₃த₃ ப்₃ரஹ்மாதிவர்ததே ॥6.44॥",

    "ப்ரயத்நாத்₃ய தமானஸ்து, யோகி₃ ஸம்ஶுத்₃த₄ கில்பி₃ஷஹ।\nஅநேகஜன்மஸம்ஸித்₃த₄ஸ், ததோ யாதி பராங் க₃திம் ॥6.45॥",

    "தபஸ்விப்₄யோSதி₄கோ யோகீ₃, ஜ்ஞானிப்₄யோSபி மதோSதி₄கஹ। \nகர்மிப்₄யஶ்சாதி₄கோ யோகீ₃, தஸ்மாத்₃ யோகீ₃ ப₄வார்ஜுன ॥6.46॥",

    "யோகி₃நாமபி ஸர்வேஷாம், மத்₃க₃தேநாந்தராத்மநா ।\nஶ்ரத்₃தா₄வாந்ப₄ஜதே யோ மாம், ஸ மே யுக்த தமோ மதஹ ॥6.47॥",

    "ॐ தத்ஸதி₃தி ஶ்ரீமத்₃ப₄க₃வத்₃கீ₃தாஸு உபநிஷத்ஸு ப்ரஹ்மவித்₃யாயாம் யோக₃ஶாஸ்த்ரே ஶ்ரீ க்ருஷணார்ஜுன ஸம்வாதே₃ ஆத்மஸம்யம யோகோ₃ நாம ஷஷ்டோSத்₄யாயஹ ॥6॥"
  ],
  "adhyaya-7" : [
    "ஶ்ரீ ப₄க₃வான் உவாச\nமய்யாஸக்த மனாஃப்₂ பார்த₂, யோக₃ம் யுஞ்ஜந்மதா₃ஶ்ரயஹ।\nஅஸம்ஶயம் ஸமக்₃ரம் மாம், யதா₂ ஜ்ஞாஸ்யஸி தச்ச்₂ருணு ॥7.1॥",

    "ஜ்ஞானந் தேSஹம் ஸ விஜ்ஞானம், இத₃ம் வக்ஷ்யாம்யஶேஷதஹ। \nயஜ்ஞாத்வா நேஹ பூ₄யோSந்யஜ், ஜ்ஞாதவ்யம் அவஶிஷ்யதே ॥7.2॥",

    "மநுஷ்யாணாம் ஸஹஸ்ரேஷு, கஶ்சித்₃யததி ஸித்₃த₄யே।\nயததாமபி ஸித்₃தா₄நாங், கஶ்சிந்மாம் வேத்தி தத்த்வதஹ ॥7.3॥",

    "பூ₄மிராபோsநலோ வாயுக்₂, க₂ம் மனோ பு₃த்₃தி₄ரேவ ச।\nஅஹங்கார இதீயம் மே, பி₄ந்நா ப்ரக்ருதிரஷ்டதா₄ ॥7.4॥",

    "அபரேயமிதஸ்த்வந்யாம், ப்ரக்ருதிம் வித்₃தி₄ மே பராம்।\nஜீவபூ₄தாம் மஹாபா₃ஹோ, யயேத₃ந் தா₄ர்யதே ஜக₃த் ॥7.5॥",

    "ஏதத்₃யோனீனி பூ₄தாநி, ஸர்வாணீத்யுபதா₄ரய।\nஅஹங் க்ருத்ஸ்நஸ்ய ஜக₃தஃப்₂, ப்ரப₄வஃப்₂ ப்ரலயஸ்ததா₂ ॥7.6॥",

    "மத்தஃப₂ பரதரந்நாந்யத், கிஞ்சித₃ஸ்தி த₄னஞ்ஜய।\nமயி ஸர்வமித₃ம் ப்ரோதம், ஸூத்ரே மணிக₃ணா இவ ॥7.7॥",

    "ரஸோSஹமப்ஸு கௌந்தேய, ப்ரபா₄ஸ்மி ஶஶிஸூர்யயோஹோ।\nப்ரணவஸ் ஸர்வவேதே₃ஷு, ஶப்த₃க்₂ கே₂ பௌருஷந் ந்ருஷு ॥7.8॥",

    "புண்யோக₃ந்த₄ஃப்₂ ப்ருதி₂வ்யாஞ்ச, தேஜஶ்சாஸ்மி விபா₄வஸௌ।\nஜீவனம் ஸர்வ பூ₄தேஷு, தபஶ்சாஸ்மி தபஸ்விஷு ॥7.9॥",

    "பீ₃ஜம் மாம் ஸர்வபூ₄தாநாம், வித்₃தி₄ பார்த₂ ஸநாதனம் ।\nபு₃த்₃தி₄ர் பு₃த்₃தி₄மதாமஸ்மி, தேஜஸ்தேஜஸ்விநாமஹம் ॥7.10॥",

    "ப₃லம் ப₃லவதாஞ்சாஹங், காமராக₃விவர்ஜிதம்।\nத₄ர்மாவிருத்₃தோ₄ பூ₄தேஷு, காமோSஸ்மி ப₄ரதர்ஷப₄ ॥7.11॥",

    "யே சைவ ஸாத்த்விகா பா₄வா, ராஜஸாஸ்தாமஸாஶ்ச யே।\nமத்த ஏவேதி தாந்வித்₃தி₄, ந த்வஹந் தேஷு தே மயி ॥7.12॥",

    "த்ரிபி₄ர்கு₃ணமயைர்பா₄வை, ரேபி₄ஸ் ஸர்வமித₃ஞ்ஜக₃த்।\nமோஹிதந் நாபி₄ஜாநாதி, மாமேப்₄யஃப்₂ பரமவ்யயம் ॥7.13॥",

    "தை₃வீ ஹ்யேஷா கு₃ணமயீ, மம மாயா து₃ரத்யயா।\nமாமேவ யே ப்ரபத்₃யந்தே, மாயாமேதாந் தரந்தி தே ॥7.14॥",

    "ந மாந் து₃ஷ்க்ருதினோ மூடா₄ஃப், ப்ரபத்₃யந்தே நராத₄மாஹா।\nமாயயாபஹ்ருதஜ்ஞானா, ஆஸுரம் பா₄வமாஶ்ரிதாஹா ॥7.15॥",

    "சதுர்விதா₄ ப₄ஜந்தே மாஞ், ஜநாஸ் ஸுக்ருதினோsர்ஜுன।\nஆர்தோ ஜிஜ்ஞாஸுரர்தா₂ர்தீ₂, ஜ்ஞானீ ச ப₄ரதர்ஷப₄ ॥7.16॥",

    "தேஷாஞ் ஜ்ஞானீ நித்ய யுக்த, ஏகப₄க்திர் விஶிஷ்யதே ।\nப்ரியோஹி ஜ்ஞானினோsத்யர்த₂ம், அஹம் ஸ ச மம ப்ரியஹ ॥7.17॥",

    "உதா₃ராஸ் ஸர்வ ஏவைதே, ஜ்ஞானீ த்வாத்மைவ மே மதம்।\nஆஸ்தி₂தஸ் ஸ ஹி யுக்தாத்மா, மாமேவாநுத்தமாங் க₃திம் ॥7.18॥",

    "ப₃ஹூநாஞ் ஜந்மனாமந்தே, ஜ்ஞானவான் மாம் ப்ரபத்₃யதே।\nவாஸுதே₃வஸ் ஸர்வமிதி, ஸ மஹாத்மா ஸுது₃ர்லப₄ஹ ॥7.19॥",

    "காமைஸ்தைஸ்தைர்ஹ்ருதஜ்ஞானாஃப்₂, ப்ரபத்₃யந்தேsந்ய தே₃வதாஹா।\nதந் தந் நியமமாஸ்தா₂ய, ப்ரக்ருத்யா நியதாஸ் ஸ்வயா ॥7.20॥",

    "யோ யோ யாம் யாந் தநும் ப₄க்தஶ், ஶ்ரத்₃த₄யார்சிதுமிச்ச₂தி।\nதஸ்ய தஸ்யாசலாம் ஶ்ரத்₃தா₄ந், தாமேவ வித₃தா₄ம்யஹம் ॥7.21॥",

    "ஸதயா ஶ்ரத்₃த₄யா யுக்தஸ், தஸ்யாராத₄னமீஹதே।\nலப₄தே ச ததக்₂ காமாந், மயைவ விஹிதாந் ஹி தான் ॥7.22॥",

    "அந்தவத்து ஃப₂லந் தேஷாந், தத்₃ப₄வத்யல்ப மேத₄ஸாம்।\nதே₃வாந்தே₃வயஜோ யாந்தி, மத்₃ப₄க்தா யாந்தி மாமபி ॥7.23॥",

    "அவ்யக்தம் வ்யக்திமாபந்நம், மந்யந்தே மாமபு₃த்₃த₄யஹ।\nபரம் பா₄வமஜானந்தோ, மமாவ்யயமநுத்தமம் ॥7.24॥",

    "நாஹம் ப்ரகாஶஸ் ஸர்வஸ்ய, யோக₃மாயா ஸமாவ்ருதஹ।\nமூடோ₄sயம் நாபி₄ஜாநாதி, லோகோ மாமஜமவ்யயம் ॥7.25॥",

    "வேதா₃ஹம் ஸமதீதானி, வர்தமானானி சார்ஜுன।\nப₄விஷ்யாணி ச பூ₄தானி, மாந் து வேத₃ ந கஶ்சன ॥7.26॥",

    "இச்சா₂ த்₃வேஷ ஸமுத்தே₂ந, த்₃வந்த்₃வ மோஹேந பா₄ரத।\nஸர்வ பூ₄தானி ஸம்மோஹம், ஸர்கே₃ யாந்தி பரந்தப ॥7.27॥",

    "ஏஷாந் த்வந்த க₃தம் பாபஞ், ஜநாநாம் புண்யகர்மணாம்।\nதே த்₃வந்த்₃வ மோஹ நிர்முக்தா, ப₄ஜந்தே மாந் த்₃ருட₄ வ்ரதாஹா ॥7.28॥",

    "ஜரா மரண மோக்ஷாய, மாமாஶ்ரித்ய யதந்தி யே।\nதே ப்₃ரஹ்ம தத்₃விது₃க்₂ க்ருத்ஸ்நம், அத்₄யாத்மங் கர்ம சாகி₂லம் ॥7.29॥",

    "ஸாதி₄பூ₄தாதி₄ தை₃வம் மாம், ஸாதி₄யஜ்ஞஞ் ச யே விது₃ஹு।\nப்ரயாண காலேsபிச மாந், தே விது₃ர் யுக்த சேதஸஹ ॥7.30॥",

    "ॐ தத்ஸதி₃தி ஶ்ரீமத்₃ப₄க₃வத்₃கீ₃தாஸு உபநிஷத்ஸு ப்₃ரஹ்மவித்₃யாயாம் யோக₃ஶாஸ்த்ரே ஶ்ரீ க்ருஷ்ணார்ஜுன ஸம்வாதே₃ ஜ்ஞான விஜ்ஞான யோகோ₃ நாம ஸப்தமோsத்₄யாயஹ ॥7॥"
  ],
  "adhyaya-8" : [
    "அர்ஜுன உவாச\nகிம்(ந்) தத்₃ ப்₃ரஹ்ம கிமத்₄யாத்மம்(ங்), கிம்(ங்) கர்ம புருஷோத்தம।\nஅதிபூ₄தம்(ஞ்) ச கிம் ப்ரோக்தம், அதி₄தை₃வம்(ங்) கிமுச்யதே ॥8.1॥",

    "அதி₄யஜ்ஞக்₂ கத₂ங் கோSத்ர, தே₃ஹேSஸ்மின் மது₄ஸூத₃ன। \nப்ரயாண காலே ச கத₂ஞ் ஜ்ஞேயோSஸி நியதாத்மபி₄ஹி ॥8.2॥",

    "ஶ்ரீ ப₄க₃வாநுவாச \nஅக்ஷரம் ப்₃ரஹ்ம பரமம்,ஸ்வபா₄வோSத்₄யாத்மமுச்யதே ।\nபூ₄தபா₄வோத்₃ப₄வகரோ, விஸர்க₃க்₂ கர்மஸஞ்ஜ்ஞிதஹ ॥8.3॥",

    "அதி₄பூ₄தங் க்ஷரோபா₄வஃப், புருஷஶ்சாதி₄தை₃வதம் ।\nஅதி₄யஜ்ஞோSஹமேவாத்ர,தே₃ஹே தே₃ஹப்₄ருதாம் வர ॥8.4॥",

    "அந்தகாலேச மாமேவ ஸ்மரன்முக்த்வா கலேவரம் \nயஃப் ப்ரயாதி ஸ மத்₃பா₄வம் யாதி நாஸ்த்யத்ர ஸம்ஶயஹ ॥8.5॥",

    "யம்  யம் வாபி ஸ்மரன்பா₄வந், த்யஜத்யந்தே கலேவரம்।\nதந் தமேவைதி கௌந்தேய, ஸதா₃ தத்₃பா₄வபா₄விதஹ ॥8.6॥",

    "தஸ்மாத் ஸர்வேஷு காலேஷு,மாமநுஸ்மர யுத்₄ய ச।\nமய்யர்பிதமனோபுத்₃தி₄ர்,மாமேவைஷ்யஸ்யஸம்ஶயம் ॥8.7॥",

    "அப்₄யாஸயோக₃யுக்தேன, சேதஸா நாந்யகா₃மினா। \nபரமம் புருஷந் தி₃வ்யம், யாதி பார்தா₂நுசிந்தயன் ॥8.8॥",

    "கவிம் புராணமநுஶாஸிதாரம்,\nஅணோரணீயாம் ஸமநுஸ்மரேத்₃யஹ।\nஸர்வஸ்ய தா₄தாரமசிந்த்யரூபம், \nஆதி₃த்யவர்ணந் தமஸஃப் பரஸ்தாத் ॥8.9॥",

    "ப்ரயாணகாலே மனஸாsசலேன,\nப₄க்த்யா யுக்தோ யோக₃ப₃லேன சைவ ।\nப்₄ருவோர்மத்₄யே ப்ராணமாவேஶ்ய ஸம்யக், \nஸ தம் பரம் புருஷமுபைதி தி₃வ்யம் ॥8.10॥",

    "யத₃க்ஷரம் வேத₃விதோ₃ வத₃ந்தி,\nவிஶந்தி யத்₃யதயோ வீதராகா₃ஹா ।\nயதி₃ச்ச₂ந்தோ ப்ரஹ்மசர்யஞ்சரந்தி,\nதத்தே பதம் ஸங்க்₃ரஹேண ப்ரவக்ஷ்யே ॥8.11॥",

    "ஸர்வத்₃வாராணி ஸம்யம்ய, மனோ ஹ்ருதி₃ நிருத்₄ய ச \nமூர்த்₄ந்யாதா₄யாத்மனஃப் ப்ராணம்,ஆஸ்தி₂தோ யோக₃தா₄ரணாம் ॥8.12॥",

    "ஓமித்யேகாக்ஷரம் ப்₃ரஹ்ம, வ்யாஹரன் மாமநுஸ்மரன்। \nயஃப் ப்ரயாதி த்யஜந்தே₃ஹம், ஸ யாதி பரமாங் க₃திம் ॥8.13॥",

    "அநந்யசேதாஸ் ஸததம், யோ மாம் ஸ்மரதி நித்யஶஹ ।\nதஸ்யாஹம் ஸுலப₄ஃப் பார்த₂, நித்யயுக்தஸ்ய யோகி₃னஹ ॥8.14॥",

    "மாமுபேத்ய புநர்ஜன்ம,துக்₂கா₂லயமஶாஶ்வதம் ।\nநாப்நுவந்தி மஹாத்மானஸ்,ஸம்ஸித்₃தி₄ம் பரமாங்க₃தாஹா ॥8.15॥",

    "ஆப்₃ரஹ்ம பு₄வநால்லோகாஃப், புநராவர்திநோSர்ஜுன। \nமாமூபேத்ய து கௌந்தேய, புநர்ஜன்ம ந வித்₃யதே ॥8.16॥",

    "ஸஹஸ்ர யுக₃ பர்யந்தம், அஹர்யத்₃ப்₃ரஹ்மணோ விது₃ஹு। \nராத்ரிம் யுக₃ ஸஹஸ்ராந்தாந், தேSஹோராத்ர விதோ₃ ஜநாஹா ॥8.17॥",

    "அவ்யக்தாத்₃வ்யக்த யஸ் ஸர்வாஃப், ப்ரப₄வந்த்யஹராக₃மே।\nராத்ர்யாக₃மே ப்ரலீயந்தே, தத்ரைவாவ்யக்த ஸஞ்ஜ்ஞகே ॥8.18॥",

    "பூ₄தக்₃ராமஸ் ஸ ஏவாயம், பூ₄த்வா பூ₄த்வா ப்ரலீயதே ।\nராத்ர்யாக₃மேSவஶஃப் பார்த₂, ப்ரப₄வத்யஹராக₃மே ॥8.19॥",

    "பரஸ்தஸ்மாத்து பா₄வோSந்யோSவ்யக்தோSவ்யக்தாத் ஸநாதநஹ। \nயஸ் ஸ ஸர்வேஷு பூ₄தேஷு, நஶ்யத்ஸு ந விநஶ்யதி ॥8.20॥",

    "அவ்யக்தோSக்ஷர இத்யுக்தஸ், தமாஹுஃப் பரமாங் க₃திம்। \nயம் ப்ராப்ய ந நிவர்தந்தே, தத்₃தா₄ம பரமம் மம ॥8.21॥",

    "புருஷஸ் ஸ பரஃப் பார்த₂, ப₄க்த்யா லப்₄யஸ்த்வநந்யயா। \nயஸ்யாந்தஸ் ஸ்தா₂நி பூ₄தாநி, யேந ஸர்வமித₃ம் ததம் ॥8.22॥",

    "யத்ர காலே த்வநாவ்ருத்திம், ஆவ்ருத்திஞ் சைவ யோகி₃நஹ। \nப்ரயாதா யாந்தி தங் காலம், வக்ஷ்யாமி ப₄ரதர்ஷப₄ ॥8.23॥",

    "அக்₃நிர்ஜ்யோதிரஹஶ் ஶுக்லஶ், ஷண்மாஸா உத்தராயணம்। \nதத்ர ப்ரயாதா க₃ச்ச₂ந்தி, ப்₃ரஹ்ம ப்₃ரஹ்மவிதோ₃ ஜநாஹா ॥8.24॥",

    "தூ₄மோ ராத்ரிஸ்ததா₂ க்ருஷ்ணஶ், ஷண்மாஸா த₃க்ஷிணாயநம்। \nதத்ர சாந்த₃ரமஸம் ஜ்யோதிர், யோகீ₃ ப்ராப்ய நிவர்ததே ॥8.25॥",

    "ஶுக்லக்ருஷ்ணே க₃தீஹ்யேதே, ஜக₃தஶ் ஶாஶ்வதே மதே। \nஏகயா யாத்யநாவ்ருத்திம், அந்யயாவர்ததே புநஹ ॥8.26॥",

    "நைதே ஸ்ருதீ பார்த₂ ஜாநந், யோகீ₃ முஹ்யதி கஶ்சந ।\nதஸ்மாத்ஸர்வேஷு காலேஷு, யோக₃யுக்தோ ப₄வார்ஜுன ॥8.27॥",

    "வேதே₃ஷு யஜ்ஞேஷு தபஸ்ஸு சைவ, \nதா₃னேஷு யத்புண்யஃபலம் ப்ரதி₃ஷ்டம்। \nஅத்யேதி தத்ஸர்வமித₃ம் விதி₃த்வா, \nயோகீ ₃ பரம் ஸ்தா₂நமுபைதி சாத்₃யம் ॥8.28॥",

    "ஓம் தத்ஸதி₃தி ஶ்ரீமத்₃ப₄க₃வத்₃கீ₃தாஸு உபநிஷத்ஸு ப்₃ரஹ்ம வித்₃யாயாம் யோக₃ஶாஸ்த்ரே ஶ்ரீக்ருஷ்ணார்ஜுந ஸம்வாதே₃ அக்ஷரப்₃ரஹ்மயோகோ₃ நாம அஷ்டமோத்₄யாயஹ ॥8॥"
  ],
  "adhyaya-9" : [
    "ஶ்ரீ ப₄க₃வாநுவாச\nஇதம்(ந்) து தே கு₃ஹ்யதமம்(ம்), ப்ரவக்ஷ்யாம்யநஸூயவே।\nஜ்ஞானம்(வ்) விஜ்ஞானஸஹிதம்(ய்), யஜ்ஜ்ஞாத்வா மோக்ஷ்யஸேSஶுபா₄த் ॥9.1॥",

    "ராஜவித்₃யா ராஜகு₃ஹ்யம், பவித்ரமித₃முத்தமம்।\nப்ரத்யக்ஷாவக₃மந் த₄ர்ம்யம், ஸுஸுக₂ங் கர்துமவ்யயம ॥9.2॥",

    "அஶ்ரத்₃த₃தா₄னா:(ஃப்) புருஷா, த₄ர்மஸ்யாஸ்ய பரந்தப।\nஅப்ராப்ய  மாந் நிவர்தந்தே, ம்ருத்யு ஸம்ஸார வர்த்மனி। ।9.3॥",

    "மயா ததமித₃ம் ஸர்வஞ், ஜக₃த₃வ்யக்தமூர்தினா।\nமத்ஸ்தா₂னி ஸர்வபூ₄தானி, ந சாஹந் தேஷ்வவஸ்தி₂தஹ। ।9.4॥",

    "ந ச மத்ஸ்தா₂னி பூ₄தானி, பஶ்ய மே யோக₃மைஶ்வரம்।\nபூ₄தப்₄ருன்ன ச பூ₄தஸ்தோ₂, மமாத்மா பூ₄தபா₄வனஹ। ।9.5॥",

    "யதா₂காஶஸ்தி₂தோ நித்யம்(வ்), வாயு:(ஸ்) ஸர்வத்ரகோ₃ மஹான்।\nததா₂ ஸர்வாணி பூ₄தானி, மத்ஸ்தா₂னீத்யுபதா₄ரய ॥9.6॥",

    "ஸர்வபூ₄தானி கௌந்தேய, ப்ரக்ருதிம்(ய்) யாந்தி மாமிகாம்।\nகல்பக்ஷயே புநஸ்தானி, கல்பாதௌ₃ விஸ்ருஜாம்யஹம் ॥9.7॥",

    "ப்ரக்ருதிம் ஸ்வாமவஷ்டப்₄ய, விஸ்ருஜாமி புந:(ஃப்) புந: ஹா।\nபூ₄தக்₃ராமமிமங் க்ருத்ஸ்னம், அவஶம் ப்ரக்ருதேர்வஶாத ॥9.8॥",

    "ந ச  மாந் தானி கர்மாணி, நிப₃த்₄னந்தி த₄னஞ்ஜய।\nஉதா₃ஸீனவதா₃ஸீனம்,  அஸக்தந் தேஷு கர்மஸு ॥9.9॥",

    "மயாத்₄யக்ஷேண ப்ரக்ருதி:(ஸ்), ஸூயதே ஸசராசரம்।\nஹேதுநாநேன கௌந்தேய, ஜக₃த்₃விபரிவர்ததே ॥9.10॥",
    
    "அவஜானந்தி மாம் மூடா₄,  மாநுஷீந் தநுமாஶ்ரிதம்।\nபரம் பா₄வமஜானந்தோ, மம பூ₄த மஹேஶ்வரம ॥9.11॥",
    
    "மோகா₄ஶா மோக₄ கர்மாணோ, மோக₄ஜ்ஞானா விசேதஸ:ஹ।\nராக்ஷஸீமாஸுரீஞ் சைவ, ப்ரக்ருதிம் மோஹினீம் ஶ்ரிதா:ஹா ॥9.12॥",
    
    "மஹாத்மாநஸ்து மாம் பார்த₂, தை₃வீம் ப்ரக்ருதிமாஶ்ரிதா:ஹா।\nப₄ஜந்த்யநந்யமனஸோ, ஜ்ஞாத்வா பூ₄தாதி₃மவ்யயம ॥9.13॥",
    
    "ஸததம்(ங்) கீர்தயந்தோ மாம்(ய்), யதந்தஶ்ச த்₃ருட₄வ்ரதா:(ஹா)।\nநமஸ்யந்தஶ்ச மாம்(ம்) ப₄க்த்யா, நித்யயுக்தா உபாஸதே ॥9.14॥",
    
    "ஜ்ஞானயஜ்ஞேன சாப்யந்யே, யஜந்தோ மாமுபாஸதே।\nஏகத்வேன ப்ருத₂க்த்வேன, ப₃ஹுதா₄ விஶ்வதோமுக₂ம ॥9.15॥",
    
    "அஹம்(ங்) க்ரதுரஹம்(ய்) யஜ்ஞ:(ஸ்),ஸ்வதா₄ஹ மஹமௌஷத₄ம்।\nமந்த்ரோSஹமஹமேவாஜ்யம், அஹமக்₃னிரஹம்(ம்) ஹுதம் ॥9.16॥",
    
    "பிதாஹமஸ்ய ஜக₃தோ, மாதா தா₄தா பிதாமஹ:ஹ।\nவேத்₃யம் பவித்ரமோங்கார, ருக்ஸாம யஜுரேவ ச ॥9.17॥",
    
    "க₃திர்ப₄ர்தா ப்ரபு₄:(ஸ்) ஸாக்ஷீ, நிவாஸ:(ஶ்) ஶரணம் ஸுஹ்ருத்।\nப்ரப₄வ:(ஃப்) ப்ரலய:(ஸ்) ஸ்தா₂னந், நிதா₄னம் பீ₃ஜமவ்யயம ॥9.18॥",
    
    "தபாம்யஹமஹம்(வ்) வர்ஷம்(ந்), நிக்₃ருஹ்ணாம்யுத்ஸ்ருஜாமி ச।\nஅம்ருதம்(ஞ்) சைவ ம்ருத்யுஶ்ச, ஸத₃ஸச்சாஹமர்ஜுன ॥9.19॥",
    
    "த்ரைவித்₃யா மாம் ஸோமபா:(ஃப்) பூதபாபா,\nயஜ்ஞைரிஷ்ட்வா ஸ்வர்க₃திம் ப்ரார்த₂யந்தே।\nதே புண்யமாஸாத்₃ய ஸுரேந்த்₃ரலோகம்,\nஅஶ்நந்தி தி₃வ்யாந்தி₃வி தே₃வபோ₄கா₃ந ॥9.20॥",
    
    "தே தம்(ம்) பு₄க்த்வா ஸ்வர்க₃லோகம்(வ்) விஶாலம்(ங்),\nக்ஷீணே புண்யே மர்த்ய லோகம்(வ்) விஶந்தி।\nஏவம்(ந்) த்ரயீத₄ர்மமநுப்ரபந்நா,\nக₃தாக₃தம்(ங்) காமகாமா லப₄ந்தே ॥9.21॥",
    
    "அநந்யாஶ்சிந்தயந்தோ மாம்(ய்), யே ஜனா:(ஃப்) பர்யுபாஸதே।\nதேஷாம்(ந்) நித்யாபி₄யுக்தானாம்(ய்), யோக₃க்ஷேமம்(வ்) வஹாம்யஹம் ॥9.22॥",
    
    "யேSப்யந்யதே₃வதா ப₄க்தா, யஜந்தே ஶ்ரத்₃த₄யாந்விதா:ஹா।\nதேSபி மாமேவ கௌந்தேய, யஜந்த்யவிதி₄பூர்வகம ॥9.23॥",
    
    "அஹம் ஹி ஸர்வ யஜ்ஞானாம், போ₄க்தா ச ப்ரபு₄ரேவ ச।\nந து மாமபி₄ஜானந்தி, தத்த்வேநாதஶ்ச்யவந்தி தே ॥9.24॥",
    
    "யாந்தி தே₃வவ்ரதா தேவாந், பித்ரூன்யாந்தி பித்ருவ்ரதாஹா।\nபூ₄தானி யாந்தி பூ₄தேஜ்யா, யாந்தி மத்₃யாஜிநோSபி மாம ॥9.25॥",
    
    "பத்ரம்(ம்) புஷ்பம்(ம்)  ஃபலம்(ந்) தோயம்(ய்), யோ மே ப₄க்த்யா ப்ரயச்ச₂தி।\nதத₃ஹம்(ம்) ப₄க்த்யுபஹ்ருதம், அஶ்நாமி ப்ரயதாத்மன:(ஹ) ॥9.26॥",
    
    "யத்கரோஷி யத₃ஶ்நாஸி, யஜ்ஜுஹோஷி த₃தா₃ஸி யத்।\nயத்தபஸ்யஸி கௌந்தேய, தத்குருஷ்வ மத₃ர்பணம ॥9.27॥",
    
    "ஶுபா₄ஶுப₄ஃபலைரேவம், மோக்ஷ்யஸே கர்மப₃ந்த₄நை:ஹி।\nஸந்ந்யாஸயோக₃யுக்தாத்மா, விமுக்தோ மாமுபைஷ்யஸி ॥9.28॥",
    
    "ஸமோSஹம் ஸர்வபூ₄தேஷு, ந மே த்₃வேஷ்யோSஸ்தி ந ப்ரிய:ஹ।\nயே ப₄ஜந்தி து மாம் ப₄க்த்யா, மயி தே தேஷு சாப்யஹம ॥9.29॥",
    
    "அபி சேத்ஸுது₃ராசாரோ, ப₄ஜதே மாமனந்யபா₄க்।\nஸாது₄ரேவ ஸ மந்தவ்ய:(ஸ்), ஸம்யக்₃ வ்யவஸிதோ ஹி ஸ:ஹ ॥9.30॥",
    
    "க்ஷிப்ரம் ப₄வதி த₄ர்மாத்மா,ஶஶ்வச்சா₂ந்திந் நிக₃ச்ச₂தி।\nகௌந்தேய ப்ரதிஜானீஹி, ந மே ப₄க்த:(ஃப்) ப்ரணஶ்யதி ॥9.31॥",
    
    "மாம் ஹி பார்த₂ வ்யபாஶ்ரித்ய, யேSபி ஸ்யு:(ஃப்) பாபயோனய:ஹ।\nஸ்த்ரியோ வைஶ்யாஸ்ததா₂ ஶூத்₃ரா:(ஸ்),தேSபி யாந்தி பராந் க₃திம ॥9.32॥",
    
    "கிம் புநர்ப்₃ராஹ்மணா:(ஃப்) புண்யா, ப₄க்தா ராஜர்ஷயஸ்ததா₂।\nஅநித்யமஸுக₂ம் லோகம், இமம் ப்ராப்ய ப₄ஜஸ்வ மாம ॥9.33॥",
    
    "மன்மநா ப₄வ மத்₃ப₄க்தோ, மத்₃யாஜீ மாந் நமஸ்குரு।\nமாமேவைஷ்யஸி யுக்த்வைவம், ஆத்மானம் மத்பராயண:ஹ ॥9.34॥",
    
    "ஓம் தத்ஸதி₃தி ஶ்ரீமத்₃ப₄க₃வத்₃கீ₃தாஸு உபநிஷத்ஸு ப்₃ரஹ்மவித்₃யாயாம்(ய்) யோக₃ஶாஸ்த்ரே ஶ்ரீக்ருஷ்ணார்ஜுனஸம்வாதே₃ ராஜவித்₃யா ராஜகு₃ஹ்யயோகோ₃ நாம நவமோSத்₄யாய:ஹ ॥9॥"
  ],
  "adhyaya-10" : [
    "ஶ்ரீ ப₄க₃வாநுவாச\nபூ₄ய ஏவ மஹாபா₃ஹோ, ஶ்ருணு மே பரமம் வச ஹ।\nயத்தேSஹம் ப்ரீயமாணாய, வக்ஷ்யாமி ஹிதகாம்யயா ॥10.1॥",

    "ந மே விது₃(ஸ்) ஸுரக₃ணா(ஃப்), ப்ரப₄வந் ந மஹர்ஷயஹ।\nஅஹமாதி₃ர்ஹி தே₃வாநாம், மஹர்ஷீணாஞ் ச ஸர்வஶ ஹ ॥10.2॥",

    "யோ மாமஜமநாதி₃ஞ் ச, வேத்தி லோக மஹேஶ்வரம்।\nஅஸம்மூட₄(ஸ்) ஸ மர்த்யேஷு,  ஸர்வபாபை(ஃப்) ப்ரமுச்யதே ॥10.3॥",

    "பு₃த்₃தி₄ர்ஜ்ஞானமஸம்மோஹ:,க்ஷமா ஸத்யந் த₃மஶ் ஶமஹ।\nஸுக₂ந் து₃க்₂க₂ம் ப₄வோபா₄வோ, ப₄யஞ் சாப₄யமேவ ச ॥10.4॥",

    "அஹிம்ஸா ஸமதா துஷ்டிஸ், தபோ தா₃னம் யஶோSயஶஹ।\nப₄வந்தி பா₄வா பூ₄தாநாம், மத்த ஏவ ப்ருத₂க்₃விதா₄ஹா ॥10.5॥",

    "மஹர்ஷயஸ் ஸப்த பூர்வே, சத்வாரோ மநவஸ்ததா₂।\nமத்₃பா₄வா மாநஸா ஜாதா, யேஷாம் லோக இமா(ஃப்) ப்ரஜாஹா ॥10.6॥",

    "யேதாம் விபூ₄திம் யோக₃ஞ் ச, மம யோ வேத்தி தத்த்வதஹ।\nஸோSவிகம்பேந யோகே₃ந, யுஜ்யதே நாத்ர ஸம்ஶயஹ ॥10.7॥",

    "அஹம் ஸர்வஸ்ய ப்ரப₄வோ, மத்தஸ் ஸர்வம் ப்ரவர்ததே।\nஇதி மத்வா ப₄ஜந்தே மாம்,  பு₃தா₄ பா₄வ ஸமந்விதாஹ ॥10.8॥",

    "மச்சித்தா மத்₃க₃தப்ராணா, போ₃த₄யந்த(ஃப்) பரஸ்பரம்।\nகத₂யந்தஶ்ச மாந் நித்யந், துஷ்யந்தி ச ரமந்தி ச ॥10.9॥",

    "தேஷாம் ஸதத யுக்தாநாம்,  ப₄ஜதாம் ப்ரீதி பூர்வகம்।\nத₃தா₃மி பு₃த்₃தி₄யோக₃ந் தம், யேந மாமுபயாந்தி தே ॥10.10॥",

    "தேஷாமேவாநுகம்பார்த₂ம், அஹமஜ்ஞானஜந்  தமஹ।\nநாஶயாம்யாத்ம பா₄வஸ்தோ₂, ஜ்ஞானதீ₃பேந பா₄ஸ்வதா ॥10.11॥",

    "அர்ஜுன உவாச\nபரம் ப்₃ரஹ்ம பரந் தா₄ம, பவித்ரம் பரமம் ப₄வான்।\nபுருஷம் ஶாஶ்வதந் தி₃வ்யம், ஆதி₃தே₃வமஜம் விபு₄ம் ॥10.12॥",

    "ஆஹுஸ்த்வாம்ருஷயஸ் ஸர்வே,  தே₃வர்ஷிர்நாரத₃ஸ்ததா₂।\nஅஸிதோ தே₃வலோ வ்யாஸஸ், ஸ்வயஞ் சைவ ப்₃ரவீஷி மே ॥10.13॥",

    "ஸர்வமேதத்₃ருதம் மந்யே, யந்மாம் வத₃ஸி கேஶவ।\nந ஹி தே ப₄க₃வந்வ்யக்திம், விது₃ர்தே₃வா ந தா₃நவாஹா ॥10.14॥",

    "ஸ்வயமேவாத்மநாத்மாநம்,வேத்த₂ த்வம் புருஷோத்தம।\nபூ₄தபா₄வந பூ₄தேஶ, தே₃வதே₃வ ஜக₃த்பதே ॥10.15॥",

    "வக்துமர்ஹஸ்ய ஶேஷேண, தி₃வ்யா ஹ்யாத்ம விபூ₄தயஹ।\nயாபி₄ர்விபூ₄திபி₄ர்லோகாந், இமாம் ஸ்த்வம் வ்யாப்ய திஷ்ட₂ஸி ॥10.16॥",

    "கத₂ம் வித்₃யாமஹம் யோகி₃ம்ஸ்,  த்வாம் ஸதா₃ பரிசிந்தயந்। \nகேஷு கேஷு ச பா₄வேஷு, சிந்த்யோSஸி ப₄க₃வந்மயா ॥10.17॥",

    "விஸ்தரேணாத்மநோ யோக₃ம், விபூ₄திஞ் ச ஜநார்த₃ன।\nபூ₄ய: (க்₂) கத₂ய த்ருப்திர்ஹி,ஶ்ருண்வதோ நாஸ்தி மேSம்ருதம் ॥10.18॥",

    "ஶ்ரீ ப₄க₃வாநுவாச\nஹந்த தே கத₂யிஷ்யாமி, தி₃வ்யா ஹ்யாத்ம விபூ₄தயஹ।\nப்ராதா₄ந்யத(க்₂) குருஶ்ரேஷ்ட₂, நாஸ்த்யந்தோ விஸ்தரஸ்ய மே ॥10.19॥",

    "அஹமாத்மா கு₃டா₃கேஶ, ஸர்வபூ₄தாஶயஸ்தி₂தஹ। \nஅஹமாதி₃ஶ்ச மத்₄யஞ் ச,  பூ₄தாநாமந்த ஏவ ச ॥10.20॥",

    "ஆதி₃த்யாநாமஹம் விஷ்ணு(ர்), ஜ்யோதிஷாம் ரவிரம்ஶுமாந்।\nமரீசிர் மருதாமஸ்மி, நக்ஷத்ராணாமஹம்  ஶஶீ॥₂1॥",

    "வேதா₃நாம் ஸாமவேதோ₃Sஸ்மி, தே₃வாநாமஸ்மி வாஸவஹ।\nஇந்த்₃ரியாணாம் மனஶ்சாஸ்மி,  பூ₄தாநாமஸ்மி சேதநா ॥10.22॥",

    "ருத்₃ராணாம் ஶங்கரஶ்சாஸ்மி, வித்தேஶோ யக்ஷரக்ஷஸாம்।\nவஸூநாம் பாவகஶ்சாஸ்மி, மேரு:(ஶ்) ஶிக₂ரிணாமஹம் ॥10.23॥",

    "புரோத₄ஸாஞ் ச முக்₂யம் மாம், வித்₃தி₄ பார்த₂ ப்₃ருஹஸ்பதிம்।\nஸேநாநீநாமஹம் ஸ்கந்த₃:(ஸ்), ஸரஸாமஸ்மி ஸாக₃ரஹ ॥10.24॥",

    "மஹர்ஷீணாம் ப்₄ருகு₃ரஹங், கி₃ராமஸ்ம்யேகமக்ஷரம்।\nயஜ்ஞாநாஞ் ஜபயஜ்ஞோSஸ்மி, ஸ்தா₂வராணாம் ஹிமாலயஹ ॥10.25॥",

    "அஶ்வத்த₂:(ஸ்) ஸர்வ வ்ருக்ஷாணாந், தே₃வர்ஷீணாஞ் ச நாரத₃ஹ ।\nக₃ந்த₄ர்வாணாஞ் சித்ரரத₂(ஸ்), ஸித்₃தா₄ணாங் கபிலோ முநிஹி ॥10.26॥",

    "உச்சை:(ஶ்) ஶ்ரவஸமஶ்வாநாம்,வித்₃தி₄ மாமம்ருதோத்₃ப₄வம்।\nஐராவதங் க₃ஜேந்த்₃ராணாந், நராணாஞ் ச நராதி₄பம் ॥10.27॥",

    "ஆயுதா₄நாமஹம் வஜ்ரந், தே₄நூநாமஸ்மி காமது₄க்।\nப்ரஜனஶ்சாஸ்மி கந்த₃ர்ப:(ஸ்), ஸர்பாணாமஸ்மி வாஸுகிஹி ॥10.28॥",

    "அநந்தஶ்சாஸ்மி நாகா₃நாம், வருணோ யாத₃ஸாமஹம்।\nபித்ரூணாமர்யமா சாஸ்மி, யம: (ஸ்) ஸம்யமதாமஹம் ॥10.29॥",

    "ப்ரஹ்லாத₃ஶ்சாஸ்மி தை₃த்யாநாங், கால(க்₂) கலயதாமஹம்।\nம்ருகா₃ணாஞ் ச ம்ருகே₃ந்த்₃ரோSஹம், வைநதேயஶ்ச பக்ஷிணாம் ॥10.30॥",

    "பவந :(ஃப்) பவதாமஸ்மி, ராம(ஶ்) ஶஸ்த்ரப்₄ருதாமஹம்।\nஜ₂ஷாணாம் மகரஶ்சாஸ்மி, ஸ்ரோதஸாமஸ்மி ஜாஹ்நவீ ॥10.31॥",

    "ஸர்கா₃ணாமாதி₃ரந்தஶ்ச, மத்₄யஞ் சைவாஹமர்ஜுன।\nஅத்₄யாத்ம வித்₃யா வித்₃யாநாம், வாத₃: (ஃப்) ப்ரவத₃தாமஹம் ॥10.32॥",

    "அக்ஷராணாமகாரோSஸ்மி, த்₃வந்த்₃வ :(ஸ்) ஸாமாஸிகஸ்ய ச।\nஅஹமேவாக்ஷய(க்₂) காலோ, தா₄தாஹம் விஶ்வதோமுக₂ஹ ॥10.33॥",

    "ம்ருத்யு: (ஸ்) ஸர்வஹரஶ்சாஹம், உத்₃ப₄வஶ்ச ப₄விஷ்யதாம்।\nகீர்தி(ஶ்) ஸ்ரீர்வாக் ச நாரீணாம், ஸ்ம்ருதிர்மேதா₄ த்₄ருதி : க்ஷமா ॥10.34॥",

    "ப்₃ருஹத்ஸாம ததா₂ ஸாம்நாங், கா₃யத்ரீ ச₂ந்த₃ஸாமஹம்।\nமாஸாநாம் மார்க₃ஶீர்ஷோSஹம், ம்ருதூநாங் குஸுமாகரஹ ॥10.35॥",

    "த்₃யூதஞ் ச₂லயதாமஸ்மி, தேஜஸ்தேஜஸ்விநாம் அஹம்।\nஜயோSஸ்மி வ்யவஸாயோSஸ்மி, ஸத்த்வம் ஸத்த்வ வதாமஹம் ॥10.36॥",

    "வ்ருஷ்ணீநாம் வாஸுதே₃வோSஸ்மி,பாண்ட₃வாநாந் த₄னஞ்ஜயஹ।\nமுநீநாமப்யஹம் வ்யாஸ(க்₂), கவீநாமுஶநா கவிஹி ॥10.37॥",

    "த₃ண்டோ₃ த₃மயதாமஸ்மி, நீதிரஸ்மி ஜிகீ₃ஷதாம்।  \nமௌனஞ் சைவாஸ்மி கு₃ஹ்யாநாஞ், ஜ்ஞானஞ் ஞானவதாமஹம் ॥10.38॥",

    "யச்சாபி ஸர்வபூ₄தாநாம், பீ₃ஜந் தத₃ஹமர்ஜுன।\nந தத₃ஸ்தி விநா யத்ஸ்யாந்,  மயா பூ₄தஞ் சராசரம் ॥10.39॥",

    "நாந்தோஸ்Sதி மம தி₃வ்யாநாம்,விபூ₄தீநாம் பரந்தப।\nஏஷ தூத்₃தே₃ஶத:(ஃப்) ப்ரோக்தோ, விபூ₄தேர்விஸ்தரோ மயா ॥10.40॥",

    "யத்₃யத்₃விபூ₄தி மத் ஸத்த்வம், ஸ்ரீமதூ₃ர்ஜிதமேவ வா।\nதத்ததே₃வாவக₃ச்ச₂ த்வம், மம தேஜோSம்ஶ ஸம்பவம் ॥10.41॥",

    "அத₂வா ப₃ஹுநைதேந, கிஞ்  ஜ்ஞாதேந தவார்ஜுன।\nவிஷ்டப்₄யாஹமித₃ங் க்ருத்ஸ்நம், ஏகாம்ஶேன ஸ்தி₂தோ ஜக₃த் ॥10.42॥",

    "ஓம் தத்ஸதி₃தி ஶ்ரீமத்₃ப₄க₃வத்₃கீ₃தாஸு உபநிஷத்ஸு ப்₃ரஹ்மவித்₃யாயாம் யோக₃ஶாஸ்த்ரே ஸ்ரீ க்ருஷ்ணார்ஜுநஸம்வாதே விபூ₄தியோகோ₃ நாம த₃ஶமோSத்₄யாயஹ ॥10॥"
  ],
  "adhyaya-11" : [
    "அர்ஜுன உவாச \nமத₃நுக்₃ரஹாய பரமங், கு₃ஹ்யமத்₄யாத்ம ஸஞ்ஜ்ஞிதம்।\nயத்த்வயோக்தம் வசஸ்தேந, மோஹோSயம் விக₃தோ மம ॥11.1॥",

    "ப₄வாப்யயௌ ஹி பூ₄தாநாம், ஶ்ருதௌ விஸ்தரஶோ மயா।\nத்வத்த:(க்₂) கமலபத்ராக்ஷ, மாஹாத்ம்யமபி சாவ்யயம் ॥11.2॥",

    "ஏவமேதத்₃யதா₂த்த₂ த்வம், ஆத்மாநம் பரமேஶ்வர ।\nத்₃ரஷ்டுமிச்சா₂மி தே ரூபம், ஐஶ்வரம் புருஷோத்தம ॥11.3॥",

    "மந்யஸே யதி₃ தச்ச₂க்யம், மயா த்₃ரஷ்டுமிதி ப்ரபோ₄।\nயோகே₃ஶ்வர ததோ மே த்வந், த₃ர்ஶயாத்மாநமவ்யயம் ॥11.4॥",

    "ஸ்ரீ ப₄க₃வானுவாச \nபஶ்ய மே பார்த₂ ரூபாணி, ஶதஶோSத₂ ஸஹஸ்ரஶஹ।\nநாநாவிதா₄நி தி₃வ்யாநி, நாநாவர்ணாக்ருதீனி ச ॥11.5॥",

    "பஶ்யாதி₃த்யாந் வஸூந்ருத்₃ராந், அஶ்விநௌ மருதஸ்ததா₂।\nப₃ஹூந்ய த்₃ருஷ்டபூர்வாணி, பஶ்யாஶ்சர்யாணி பா₄ரத ॥11.6॥",

    "இஹைகஸ்த₂ஞ் ஜக₃த்க்ருத்ஸ்நம், பஶ்யாத்₃ய ஸசராசரம்।\nமம தே₃ஹே கு₃டா₃கேஶ, யச்சாந்யத்₃ரஷ்டுமிச்ச₂ஸி ॥11.7॥",

    "ந து மாம் ஶக்யஸே த்₃ரஷ்டும், அநேநைவ ஸ்வசக்ஷுஷா ।\nதி₃வ்யந் த₃தா₃மி தே சக்ஷு;ஃப், பஶ்ய மே யோக₃மைஶ்வரம் ॥11.8॥",

    "ஸஞ்ஜய உவாச \nஏவமுக்த்வா ததோ ராஜன், மஹாயோகே₃ஶ்வரோ ஹரிஹி।\nத₃ர்ஶயாமாஸ பார்தா₂ய, பரமம் ரூபமைஶ்வரம் ॥11.9॥",

    "அநேகவக்த்ரநயநம், அநேகாத்₃பு₄தத₃ர்ஶனம்।\nஅநேகதி₃வ்யாப₄ரணம், தி₃வ்யாநேகோத்₃யதாயுத₄ம் ॥11.10॥",

    "தி₃வ்யமால்யாம்ப₃ரத₄ரந், தி₃வ்யக₃ந்தா₄நுலேபனம் ।\nஸர்வாஶ்சர்யமயந் தே₃வம், அநந்தம் விஶ்வதோமுக₂ம் ॥11.11॥",

    "தி₃வி ஸூர்யஸஹஸ்ரஸ்ய, ப₄வேத்₃யுக₃பது₃த்தி₂தா ।\nயதி₃ பா₄ஸ் ஸத்₃ருஶீ ஸா ஸ்யாத்₃, பா₄ஸஸ்தஸ்ய மஹாத்மநஹ ॥11.12॥",

    "தத்ரைகஸ்த₂ஞ் ஜக₃த்க்ருத்ஸ்நம்,  ப்ரவிப₄க்தமநேகதா₄। \nஅபஶ்யத்₃தே₃வதே₃வஸ்ய, ஶரீரே பாண்ட₃வஸ்ததா₃ ॥11.13॥",

    "ததஸ் ஸ விஸ்மயாவிஷ்டோ, ஹ்ருஷ்டரோமா த₄னஞ்ஜயஹ।\nப்ரணம்ய ஶிரஸா தே₃வங்,  க்ருதாஞ்ஜலிரபா₄ஷத ॥11.14॥",

    "அர்ஜுன உவாச \nபஶ்யாமி தே₃வாம்ஸ்தவ தே₃வ தே₃ஹே,\nஸர்வாம்ஸ்ததா₂ பூ₄த விஶேஷ ஸங்கா₄ந்।\nப்₃ரஹ்மாணமீஶங் கமலாஸனஸ்த₂ம்,\nருஷீம்ஶ்ச ஸர்வாநுரகா₃ம்ஶ்ச தி₃வ்யாந் ॥11.15॥",

    "அநேகபா₃ஹூத₃ரவக்த்ர நேத்ரம்,\nபஶ்யாமி த்வாம் ஸர்வதோSநந்த ரூபம்।\nநாந்தந் ந மத்யந் ந புநஸ்தவாதிம்,\nபஶ்யாமி விஶ்வேஶ்வர விஶ்வரூப ॥11.16॥",

    "கிரீடிநங் க₃தி₃நஞ் சக்ரிணஞ் ச,\nதேஜோராஶிம் ஸர்வதோ தீ₃ப்திமந்தம் ।\nபஶ்யாமி த்வாந் து₃ர்நிரீக்ஷ்யம் ஸமந்தாத்₃,\nதீ₃ப்தாநலார்கத்₃யுதிமப்ரமேயம் ॥11.17॥",

    "த்வமக்ஷரம் பரமம் வேதி₃தவ்யன்,\nத்வமஸ்ய விஶ்வஸ்ய பரந் நிதா₄னம்।\nத்வமவ்யயஶ் ஶாஶ்வத த₄ர்மகோ₃ப்தா,\nஸநாதநஸ்த்வம் புருஷோ மதோ மே ॥11.18॥",

    "அநாதி₃மத்₄யாந்தமநந்தவீர்யம்,\nஅநந்தபா₃ஹும் ஶஶிஸூர்யநேத்ரம்।\nபஶ்யாமி த்வாந் தீ₃ப்தஹுதாஶவக்த்ரம்,\nஸ்வதேஜஸா விஶ்வமித₃ந் தபந்தம் ॥11.19॥",

    "‘த்₃யாவாப்ருதி₂வ்யோரித₃மந்தரம் ஹி,\nவ்யாப்தம் த்வயைகேந தி₃ஶஶ்ச ஸர்வாஹா।\nத்₃ருஷ்ட்வாத்₃பு₄தம் ரூபமுக்₃ரந் தவேத₃ம்,\nலோகத்ரயம் ப்ரவ்யதி₂தம் மஹாத்மந் ॥11.20॥",

    "அமீ ஹி த்வாம் ஸுரஸங்கா₄ விஶந்தி,\nகேசித்₃பீ₄தாய்ஃப் ப்ரஞ்ஜலயோ க்₃ருணந்தி ।\nஸ்வஸ்தீத்யுக்த்வா மஹர்ஷிஸித்தஸங்கா₄ஸ்,\nஸ்துவந்தி த்வாம் ஸ்துதிபி₄ஃப் புஷ்கலாபி₄ஹி ॥11.21॥",

    "ருத்₃ராதி₃த்யா வஸவோ யே ச ஸாத்₄யா,\nவிஶ்வேSஶ்விநௌ மருதஶ்சோஷ்மபாஶ்ச।\nக₃ந்த₄ர்வ யக்ஷாஸுர ஸித்₃த₄ஸங்கா₄,\nவீக்ஷந்தே த்வாம் விஸ்மிதாஶ்சைவ ஸர்வே ॥11.22॥",

    "ரூபம் மஹத்தே ப₃ஹுவக்த்ரநேத்ரம்,\nமஹாபா₄ஹோ ப₃ஹுபா₃ஹூருபாத₃ம்।\nப₃ஹூத₃ரம் ப₃ஹுத₃ம்ஷ்ட்ராகராலந்,\nத்₃ருஷ்ட்வா லோகாஃப் ப்ரவ்யதி₂தாஸ்ததா₂ஹம் ॥11.23॥",

    "நப₄ஸ் ஸ்ப்ருஶந் தீ₃ப்தமநேகவர்ணம்,\nவ்யாத்தாநநந் தீ₃ப்த விஶாலநேத்ரம்।\nத்₃ருஷ்ட்வா ஹி த்வாம் ப்ரவ்யதி₂தாந்தராத்மா,\nத்₄ருதிந் ந விந்தா₃மி ஶமஞ் ச விஷ்ணோ ॥11.24॥",

    "த₃ம்ஷ்ட்ராகராலாநி ச தே முகா₂நி,\nத்₃ருஷ்ட்வைவ காலாநலஸந்நிபா₄நி।\nதி₃ஶோ ந ஜாநே ந லபே₄ ச ஶர்ம,\nப்ரஸீத₃ தே₃வேஶ ஜக₃ந்நிவாஸ ॥11.25॥",

    "அமீ ச த்வாந் த்₄ருதராஷ்ட்ரஸ்ய புத்ராஸ்,\nஸர்வே ஸஹைவாவநிபாலஸங்கை₄ஹி।\nபீ₄ஷ்மோ த்₃ரோணஸ் ஸூதபுத்ரஸ்ததா₂ஸௌ,\nஸஹாஸ்மதீ₃யைரபி யோத₄முக்₂யைஹி ॥11.26॥",

    "வக்த்ராணி தே த்வரமாணா விஶந்தி,\nத₃ம்ஷ்ட்ராகராலாநி ப₄யாநகாநி।\nகேசித்₃விலக்₃நா த₃ஶநாந்தரேஷு,\nஸந்த்₃ருஶ்யந்தே சூர்ணிதைருத்தமாங்கை₃ஹி ॥11.27॥",

    "யதா₂ நதீ₃நாம் ப₃ஹவோம்பு₃வேகா₃ஸ்,\nஸமுத்₃ரமேவாபி₄முகா₂ த்₃ரவந்தி।\nததா₂ தவாமி நரலோகவீரா,\nவிஶந்தி வக்த்ராண்யபி₄விஜ்வலந்தி ॥11.28॥",

    "யதா₂ ப்ரதீ₃ப்தஞ் ஜ்வலநம் பதங்கா₃,\nவிஶந்தி நாஶாய ஸம்ருத்₃த₄ வேகா₃ஹா।\nததை₂வ நாஶாய விஶந்தி லோகாஸ்,\nதவாபி வக்த்ராணி ஸம்ருத்₃த₄வேகா₃ஹா ॥11.29॥",

    "லேலிஹ்யஸே க்₃ரஸமாநஸ் ஸமந்தால்,\nலோகாந்ஸமக்₃ராந்வத₃னைர்ஜ்வலத்₃பி₄ஹி\nதேஜோபி₄ராபூர்ய ஜக₃த்ஸமக்₃ரம்\nபா₄ஸஸ்தவோக்₃ராஃப் ப்ரதபந்தி விஷ்ணோ ॥11.30॥",

    "ஆக்₂யாஹிமேகோ ப₃வாநுக்₃ர ரூபோ,\nநமோSஸ்து தே தே₃வவர ப்ரஸீத₃।\nவிஜ்ஞாதுமிச்சா₂மி ப₄வந்தமாத்₃யந்,\nந ஹி ப்ரஜாநாமி தவ ப்ரவ்ருத்திம் ॥11.31॥",

    "ஸ்ரீ ப₄க₃வானுவாச \nகாலோSஸ்மி லோகக்ஷயக்ருத்ப்ரவ்ருத்₃தோ₄, \nலோகாந் ஸமாஹர்துமிஹ ப்ரவ்ருத்தஹ।\nரிதேSபி த்வாந் ந ப₄விஷ்யந்தி ஸர்வே\nயேSவஸ்தி₂தாஃப் ப்ரத்யநீகேஷு யோதா₄ஹா ॥11.32॥",

    "தஸ்மாத்த்வமுத்திஷ்ட₂ யஶோ லப₄ஸ்வ,\nஜித்வா ஶத்ரூந் பு₄ங்க்ஷ்வ ராஜ்யம் ஸம்ருத்₃த₄ம்।\nமயைவைதே நிஹதாஃப் பூர்வமேவ,\nநிமித்தமாத்ரம் ப₄வ ஸவ்யஸாசிந் ॥11.33॥",

    "த்₃ரோணஞ் ச பீ₄ஷ்மஞ் ச ஜயத்₃ரத₂ஞ் ச,\nகர்ணந் ததா₂ந்யாநபி யோத₄வீராந்।\nமயா ஹதாம்ஸ்த்வஞ் ஜஹி மா வ்யதி₂ஷ்டா₂,\nயுத்₄யஸ்வ ஜேதாஸி ரணே ஸபத்நாந் ॥11.34॥",

    "ஸஞ்ஜய உவாச \nஏதச்ச்₂ருத்வா வசநங் கேஶவஸ்ய,\nக்ருதாஞ்ஜலிர்வேபமாநக்₂ கிரீடி।\nநமஸ்க்ருத்வா பூ₄ய ஏவாஹ க்ருஷ்ணம்,\nஸக₃த்₃க₃த₃ம் பீ₄த பீ₄தஃப் ப்ரணம்ய ॥11.35॥",

    "அர்ஜுன உவாச \nஸ்தா₂நே ஹ்ருஷீகேஶ தவ ப்ரகீர்த்யா,\nஜக₃த்ப்ரஹ்ருஷ்யத்யநுரஜ்யதே ச।\nரக்ஷாம்ஸி பீ₄தாநி தி₃ஶோ த்₃ரவந்தி,\nஸர்வே நமஸ்யந்தி ச ஸித்₃த₄ஸங்கா₄ஹா ॥11.36॥",

    "கஸ்மாச்ச தே ந நமேரந் மஹாத்மந்,\nக₃ரீயஸே ப்₃ரஹ்மணோSப்யாதி₃கர்த்ரே।\nஅநந்த தே₃வேஶ ஜக₃ந்நிவாஸ,\nத்வமக்ஷரம் ஸத₃ஸத்தத்பரம் யத் ॥11.37॥",

    "த்வமாதி₃தே₃வஃப் புருஷஃப் புராணஸ்,\nத்வமஸ்ய விஶ்வஸ்ய பரந் நிதா₄நம்\nவேத்தாஸி வேத்₃யஞ் ச பரஞ் ச தா₄ம,\nத்வயா ததம் விஶ்வமநந்தரூப ॥15.38॥",

    "வாயுர்யமோSக்₃நிர் வருணஶ் ஶஶாங்கஃப்,\nப்ரஜாபதிஸ்த்வம் ப்ரபிதாமஹஶ்ச।\nநமோ நமஸதேSஸ்து ஸஹஸ்ரக்ருத்வஃப்,\nபுநஶ்ச பூ₄யோSபி நமோ நமஸ்தே ॥11.39॥",

    "நமஃப் புரஸ்தாத₃த₂ ப்ருஷ்ட₂தஸ்தே,\nநமோSஸ்து தே ஸர்வத ஏவ ஸர்வ।\nஅநந்த வீர்யாமிதவிக்ரமஸ்த்வம்,\nஸர்வம் ஸமாப்நோஷி ததோSஸி ஸர்வஹ ॥11.40॥",

    "ஸகே₂தி மத்வா ப்ரஸப₄ம் யது₃க்தம்,\nஹே க்ருஷ்ண ஹே யாத₃வ ஹே ஸகே₂தி।\nஅஜாநதா மஹிமாநந் தவேத₃ம்,\nமயா ப்ரமாதா₃த் ப்ரணயேன வாபி ॥11.41॥",

    "யச்சாவஹாஸார்த₂மஸத்க்ருதோSஸி,\nவிஹாரஶய்யாஸன போ₄ஜநேஷு।\nஏகோSத₂வாப்யச்யுத தத் ஸமக்ஷந்,\nதத்க்ஷாமயே த்வாமஹமப்ரமேயம் ॥11.42॥",

    "பிதாஸி லோகஸ்ய சராசரஸ்ய,\nத்வமஸ்ய பூஜ்யஶ்ச கு₃ருர்க₃ரீயாந்।\nந த்வத்ஸமோSஸ்த்யப்₄யதி₄கக்₂ குதோSந்யோ,\nலோகத்ரயேSப்யப்ரதிமப்ரபா₄வ ॥11.43॥",

    "தஸ்மாத்ப்ரணம்ய ப்ரணிதா₄ய காயம்,\nப்ரஸாத₃யே த்வாமஹமீஶமீட்₃யம்।\nபிதேவ புத்ரஸ்ய ஸகே₂வ ஸக்₂யுஃப்,\nப்ரியஃப் ப்ரியாயார்ஹஸி தே₃வ ஸோடு₄ம் ॥11.44॥",

    "அத்₃ருஷ்ட பூர்வம் ஹ்ருஷிதோSஸ்மி த்₃ருஷ்ட்வா,\nப₄யேந ச ப்ரவ்யதி₂தம் மநோ மே।\nததே₃வ மே த₃ர்ஶய தே₃வரூபம்,\nப்ரஸீத₃ தே₃வேஶ ஜக₃ந்நிவாஸ ॥11.45॥",

    "கிரீடிநங் க₃தி₃நஞ் சக்ரஹஸ்தம்,\nஇச்சா₂மி த்வாந் த்₃ரஷ்டுமஹந் ததை₂வ।\nதேநைவ ரூபேண சதுர்பு₄ஜேந,\nஸஹஸ்ரபா₃ஹோ ப₄வ விஶ்வமூர்தே ॥15.46॥",

    "ஸ்ரீ ப₄க₃வானுவாச \nமயா ப்ரஸந்நேந தவார்ஜுநேத₃ம்,\nரூபம் பரந் த₃ர்ஶிதமாத்ம யோகா₃த்\nதேஜோமயம் விஶ்வமநந்தமாத்₃யம்,\nயந்மே த்வத₃ந்யேந ந த்₃ருஷ்டபூர்வம் ॥11.47॥",

    "ந வேத₃யஜ்ஞாத்₄யயநைர்ந தா₃நைஹி,\nந ச க்ரியாபி₄ர்ந தபோபி₄ருக்₃ரைஹி।\nஏவம்ரூபஶ் ஶக்ய அஹந் ந்ருலோகே,\nத்₃ரஷ்டுந் த்வத₃ந்யேந குருப்ரவீர ॥11.48॥",

    "மா தே வ்யதா₂ மா ச விமூட₄பா₄வோ,\nத்₃ருஷ்ட்வா ரூபங் கோ₄ரமீத்₃ருங்மமேத₃ம்।\nவ்யபேதபீ₄ஃப் ப்ரீதமனாஃப் புநஸ்த்வந்,\nததே₃வ மே ரூபமித₃ம் ப்ரபஶ்ய ॥11.49॥",

    "ஸஞ்ஜய உவாச \nஇத்யர்ஜுனம் வாஸுதே₃வஸ்ததோ₂க்த்வா,\nஸ்வகம் ரூபந் த₃ர்ஶயாமாஸ பூ₄ய।\nஆஶ்வாஸயாமாஸ ச பீ₄தமேனம்,\nபூ₄த்வா புநஸ் ஸௌம்யவபுர்மஹாத்மா ॥11.50॥",

    "அர்ஜுன உவாச \nத்₃ருஷ்ட்வேத்₃ம் மாநுஷம் ரூபம், தவ ஸௌம்யம் ஜநார்த₄ன।\nஇதா₃நீமஸ்மி ஸம்வ்ருத்தஸ்,  ஸசேதாஃப் ப்ரக்ருதிங் க₃தஹ ॥11.51॥",

    "ஸ்ரீ ப₄க₃வானுவாச \nஸுது₃ர்த₃ர்ஶமித₃ம் ரூபந், ருஷ்டவாநஸி யந்மம।\nதே₃வா அப்யஸ்ய ரூபஸ்ய, நித்யந் த₃ர்ஶனகாங்க்ஷிணஹ ॥11.52॥",

    "நாஹம் வேதை₃ர்ந தபஸா, ந தா₃நேந ந சேஜ்யயா।\nஶக்ய ஏவம்விதோ₄ த்₃ரஷ்டுந், த்₃ருஷ்டவாநஸி மாம் யதா₂ ॥11.53॥",

    "ப₄க்த்யா த்வநந்யயா ஶக்ய, அஹமேவம்விதோ₄Sர்ஹுன।\nஜ்ஞாதுந் த்₃ரஷ்டுஞ் ச தத்த்வேந, ப்ரவேஷ்டுஞ் ச பரந்தப ॥11.54॥",

    "மத்கர்மக்ருந் மத்பரமோ, மத்₃ப₄க்தஸ் ஸங்க₃வர்ஜிதஹ।\nநிர்வைரஸ் ஸர்வபூ₄தேஷு, யஸ் ஸ மாமேதி பாண்ட₃வ ॥11.55॥",

    "ஒம் தத்ஸதி₃தி ஸ்ரீமத்₃ப₄க₃வத்₃கீ₃தாஸு உபநிஷத்ஸு ப்₃ரஹ்மவித்₃யாயாம் யோக₃ஶாஸ்த்ரே ஸ்ரீ க்ருஷ்ணார்ஜுந ஸம்வாதே₃ விஶ்வரூபத₃ர்ஶநயோகோ₃ நாம ஏகாத₃ஶோத்₄யாயஹ ॥11॥"
  ],
  "adhyaya-12" : [
    "அர்ஜுன உவாச \nஏவம் ஸததயுக்தா யே, ப₄க்தாஸ்த்வாம் பர்யுபாஸதே। \nயே சாப்யக்ஷரமவ்யக்தம்(ந்), தேஷாம்(ங்) கே யோக₃வித்தமா:(ஹா) ॥12.1॥",

    "ஸ்ரீ ப₄க₃வானுவாச \nமய்யாவேஶ்ய மனோ யே மாம்(ந்), நித்யயுக்தா உபாஸதே।  \nஶ்ரத்₃த₄யா பரயோபேதா:(ஸ்), தே மே யுக்ததமா மதா:(ஹா) ॥12.2॥",

    "யே த்வக்ஷரமநிர்தே₃ஶ்யம், அவ்யக்தம் பர்யுபாஸதே।\nஸர்வத்ரக₃மசிந்த்யம்(ஞ்) ச, கூடஸ்த₂மசலந் த்₄ருவம்। ।12.3॥",

    "ஸந்நியம்யேந்த்₃ரியக்₃ராமம், ஸர்வத்ர ஸமபு₃த்₃த₄ய:(ஹ)।\nதே ப்ராப்னுவந்தி மாமேவ, ஸர்வபூ₄தஹிதே  ரதா:(ஹா) ॥12.4॥",

    "க்லேஶோsதி₄கதரஸ்தேஷாம், அவ்யக்தாஸக்தசேதஸாம்।\nஅவ்யக்தா ஹி க₃திர்து₃:க₂ம்(ந்), தே₃ஹவத்₃பி₄ரவாப்யதே ॥12.5॥",

    "யே து ஸர்வாணி கர்மாணி, மயி ஸந்ந்யஸ்ய மத்பரா:(ஹா)।\nஅநந்யேனைவ யோகே₃ன, மாம்(ந்) த்₄யாயந்த உபாஸதே ॥12.6॥",

    "தேஷாமஹம் ஸமுத்₃த₄ர்தா, ம்ருத்யுஸம்ஸார ஸாக₃ராத்।\nப₄வாமி நசிராத்பார்த₂, மய்யாவேஶித சேதஸாம் ॥12.7॥",

    "மய்யேவ மந ஆத₄த்ஸ்வ, மயி பு₃த்₃தி₄ம்(ந்) நிவேஶய।\nநிவஸிஷ்யஸி மய்யேவ, அத ஊர்த்₄வம்(ந்) ந ஸம்ஶய:(ஹ) ॥12.8॥",

    "அத₂ சித்தம் ஸமாதா₄தும்(ந்), ந ஶக்நோஷி மயி ஸ்தி₂ரம்।\nஅப்₄யாஸயோகே₃ன ததோ, மாமிச்சா₂ப்தும்(ந்) த₄னஞ்ஜய ॥12.9॥",

    "அப்₄யாஸேsப்யஸமர்தோ₂sஸி, மத்கர்மபரமோ ப₄வ। \nமத₃ர்த₂மபி கர்மாணி, குர்வந்ஸித்₃தி₄மவாப்ஸ்யஸி ॥12.10॥",

    "அதை₂தத₃ப்யஶக்தோsஸி, கர்தும் மத்₃யோக₃மாஶ்ரித:(ஹ)। \nஸர்வகர்மஃபலத்யாக₃ன், தத:(க்₂) குரு யதாத்மவான் ॥12.11॥",

    "ஶ்ரேயோஹி ஜ்ஞானமப்₄யாஸா(ஜ்), ஜ்ஞானாத்₃த்₄யானம்(வ்) விஶிஷ்யதே।\nத்₄யானாத்கர்மஃபலத்யாக₃:(ஸ்), த்யாகா₃ச்சா₂ந்திரனந்தரம் ॥12.12॥",

    "அத்₃வேஷ்டா ஸர்வபூ₄தானாம், மைத்ர:(க்₂) கருண ஏவ ச।\nநிர்மமோ நிரஹங்கார:(ஸ்), ஸமது₃:க₂ஸுக₂: க்ஷமீ ॥12.13॥",

    "ஸந்துஷ்ட:(ஸ்) ஸததம்(ய்)  யோகீ₃, யதாத்மா த்₃ருட₄நிஶ்சய:(ஹ)। \nமய்யர்பிதமனோபு₃த்₃தி₄:(ர்), யோ மத்₃ப₄க்த:(ஸ்) ஸ மே ப்ரிய:(ஹ) ॥12.14॥",

    "யஸ்மாந்நோத்₃விஜதே லோகோ, லோகாந்நோத்₃விஜதே ச ய:(ஹ)।\nஹர்ஷாமர்ஷப₄யோத்₃வேகை₃:(ர்), முக்தோ ய:(ஸ்) ஸ ச மே ப்ரிய:(ஹ) ॥12.15॥",

    "அநபேக்ஷ:(ஶ்) ஶுசி:(ர்) த₃க்ஷ, உதா₃ஸீனோ க₃தவ்யத₂:(ஹ)। \nஸர்வாரம்ப₄பரித்யாகீ₃, யோ மத்₃ப₄க்த:(ஸ்) ஸ மே ப்ரிய:(ஹ) ॥12.16॥",

    "யோ ந ஹ்ருஷ்யதி ந த்₃வேஷ்டி, ந ஶோசதி ந காங்க்ஷதி।\nஶுபா₄ஶுப₄பரித்யாகீ₃, ப₄க்திமான்ய:(ஸ்) ஸ மே ப்ரிய:(ஹ) ॥12.17॥",

    "ஸம:(ஶ்) ஶத்ரௌ ச மித்ரே ச, ததா₂ மாநாபமானயோ:(ஹோ)।\nஶீதோஷ்ணஸுக₂து₃:கே₂ஷு, ஸம:(ஸ்) ஸங்க₃விவர்ஜிதஹ ॥12.18॥",

    "துல்யநிந்தா₃ஸ்துதிர்மௌனீ, ஸந்துஷ்டோ யேந கேநசித்। \nஅநிகேத:(ஸ்) ஸ்தி₂ரமதி:(ர்), ப₄க்திமான்மே ப்ரியோ நர:(ஹ) ॥12.19॥",

    "யே து த₄ர்ம்யாம்ருதமித₃ம்(ய்), யதோ₂க்தம்(ம்) பர்யுபாஸதே।\nஶ்ரத்₃த₃தா₄நா மத்பரமா, ப₄க்தாஸ்தேsதீவ மே ப்ரியா:(ஹா) ॥12.20॥",

    "ஓம் தத்ஸதி₃தி ஶ்ரீமத்₃ப₄க₃வத்₃கீ₃தாஸு உபநிஷத்ஸு ப்₃ரஹ்மவித்₃யாயாம்(ய்)  யோக₃ஶாஸ்த்ரே ஶ்ரீக்ருஷ்ணார்ஜுநஸம்வாதே₃ ப₄க்தியோகோ₃நாம த்வாத₃ஶோத்₄யாய:(ஹ)॥12॥"
  ],
  "adhyaya-13" : [
    "இத₃ம் ஶரீரங் கௌந்தேய, க்ஷேத்ரமித்யபி₄தீ₄யதே। \nஏதத்₃யோ வேத்தி தம் ப்ராஹுஹு,க்ஷேத்ரஜ்ஞ இதி தத்₃வித₃ஹ ॥13.1॥",

    "க்ஷேத்ரஜ்ஞஞ் சாபி மாம் வித்₃தி₄, ஸர்வக்ஷேத்ரேஷு பா₄ரத।\nக்ஷேத்ரக்ஷேத்ரஜ்ஞயோர்ஜ்ஞானம், யத்தஜ்ஜ்ஞானம் மதம் மம ॥13.2॥",

    "தத்க்ஷேத்ரம் யச்ச யாத்₃ருக்ச, யத்₃விகாரி யதஶ்ச யத்। \nஸ ச யோ யத்ப்ரபா₄வஶ்ச, தத்ஸமாஸேன மே ஶ்ருணு ॥13.3॥",

    "ரிஷிபி₄ர் ப₃ஹுதா₄ கீ₃தஞ், ச₂ந்தோ₃பி₄ர்விவிதை₄ஃப் ப்ருத₂க்।\nப்₃ரஹ்மஸூத்ரபதை₃ஶ்சைவ, ஹேதுமத்₃பி₄ர்விநிஶ்சிதைஹி ॥13.4॥",

    "மஹாபூ₄தாந்யஹங்காரோ, பு₃த்₃தி₄ரவ்யக்தமேவ ச।\nஇந்த்₃ரியாணி த₃ஶைகஞ் ச, பஞ்ச சேந்த்₃ரிய கோ₃சராஹா ॥13.5॥",

    "இச்சா₂ த்₃வேஷஸ் ஸுக₂ந் து₃க்₂க₂ம்,ஸங்கா₄தஶ்சேதனா த்₄ருதிஹி। \nஏதத்க்ஷேத்ரம் ஸமாஸேந,ஸவிகாரமுதா₃ஹ்ருதம் ॥13.6॥",

    "அமாநித்வத₃ம்பி₄த்வம், அஹிம்ஸா க்ஷாந்திரார்ஜவம் ।\nஆசார்யோபாஸநம் ஶௌசம், ஸ்தை₂ர்யமாத்மவிநிக்₃ரஹஹ ॥13.7॥",

    "இந்த்₃ரியார்தே₂ஷு வைராக்₃யம், அநஹங்கார ஏவ ச।\nஜன்மம்ருத்யு ஜராவ்யாதி₄, துக்₂க₂ தோ₃ஷாநுத₃ர்ஶனம் ॥13.8॥",

    "அஸக்திரநபி₄ஷ்வங்க₃ஃப், புத்ரதா₃ர க்₃ருஹாதி₃ஷு। \nநித்யஞ் ச ஸமசித்தத்வம், இஷ்டாநிஷ்டோபபத்திஷு ॥13.9॥",

    "மயி சாநந்ய யோகே₃ன, ப₄க்திரவ்யபி₄சாரிணீ ।\nவிவிக்த தே₃ஶஸேவித்வம், அரதிர்ஜனஸம்ஸதி₃ ॥13.10॥",

    "அத்₄யாத்ம ஜ்ஞான நித்யத்வந், தத்த்வஜ்ஞானார்த₂ த₃ர்ஶனம்।\nஏதஜ்ஜ்ஞானமிதி ப்ரோக்தம், அஜ்ஞானம் யத₃தோSந்யதா₂ ॥13.11॥",

    "ஜ்ஞேயம் யத்தத் ப்ரவக்ஷ்யாமி, யஜ்ஜ்ஞாத்வாம்ருதமஶ்நுதே ।\nஅநாதி₃மத்பரம் ப்₃ரஹ்ம, ந ஸத்தந்நாஸது₃ச்யதே ॥13.12॥",

    "ஸர்வதஃப் பாணிபாத₃ந் தத், ஸர்வதோsக்ஷி ஶிரோமுக₂ம்।\nஸர்வதஶ் ஶ்ருதிமல்லோகே, ஸர்வமாவ்ருத்ய திஷ்ட₂தி ॥13.13॥",

    "ஸர்வேந்த்₃ரிய கு₃ணாபா₄ஸம், ஸர்வேந்த்₃ரிய விவர்ஜிதம்।\nஅஸக்தம் ஸர்வப்₄ருச்சைவ, நிர்கு₃ணங் கு₃ணபோ₄க்த்ரு ச ॥13.14॥",

    "ப₃ஹிரந்தஶ்ச பூ₄தாநாம், அசரஞ் சரமேவ ச।\nஸூக்ஷ்மத்வாத்தத₃விஜ்ஞேயந், தூ₃ரஸ்த₂ஞ் சாந்திகே ச தத் ॥13.15॥",

    "அவிப₄க்தஞ் ச பூ₄தேஷு, விப₄க்தமிவ ச ஸ்தி₂தம்।\nபூ₄தப₄ர்த்ரு ச தஜ்ஜ்ஞேயங், க்₃ரஸிஷ்ணு ப்ரப₄விஷ்ணு ச ॥13.16॥",

    "ஜ்யோதிஷாமபி தஜ்ஜ்யோதிஸ், தமஸஃப் பரமுச்யதே।\nஜ்ஞானஞ் ஜ்ஞேயஞ் ஜ்ஞானக₃ம்யம், ஹ்ருதி₃ ஸர்வஸ்ய விஷ்டி₂தம் ॥13.17॥",

    "இதி கக்ஷஏத்ரந் ததா₂ ஜ்ஞானஞ், ஜ்ஞேயஞ் சோக்தம் ஸமாஸதஹ। \nமத்₃ப₄க்த ஏதத்₃ விஜ்ஞாய, மத்₃பா₄வாயோபபத்₃யதே ॥13.18॥",

    "ப்ரக்ருதிம் புருஷஞ் சைவ, வித்₃த்₄யநாதீ₃ உபா₄வபி। \nவிகாராம்ஶ்ச கு₃ணாம்ஶ்சைவ, வித்₃தி₄ ப்ரக்ருதி ஸம்ப₄வான் ॥13.19॥",

    "கார்யகரண கர்த்ருத்வே, ஹேதுஃப் ப்ரக்ருதிருச்யதே। \nபுருஷஸ் ஸுக₂ து₃க்₂கா₂நாம், போ₄க்த்ருத்வே ஹேதுருச்யதே ॥13.20॥",

    "புருஷஃப் ப்ரக்ருதிஸ்தோ₂ ஹி, பு₄ங்க்தே ப்ரக்ருதிஜாந்கு₃ணாந்। \nகாரணங் கு₃ணஸங்கோ₃Sஸ்ய, ஸத₃ஸத்₃ யோனி ஜன்மஸு ॥13.21॥",

    "உபத்₃ரஷ்டாநுமந்தா ச, ப₄ர்தா போ₄க்தா மஹேஶ்வரஹ।\nபரமாத்மேதி சாப்யுக்தோ, தே₃ஹேSஸ்மின் புருஷஃப் பரஹ ॥13.22॥",

    "ய ஏவம் வேத்தி புருஷம், ப்ரக்ருதிஞ் ச கு₃ணைஸ் ஸஹ। \nஸர்வதா₂ வர்தமாநோSபி, ந ஸ பூ₄யோSபி₄ஜாயதே ॥13.23॥",

    "த்₄யாநேநாத்மனி பஶ்யந்தி, கேசிதா₃த்மானமாத்மனா। \nஅந்யே ஸாங்க்₂யேந யோகே₃ன, கர்மயோகே₃ந சாபரே ॥13.24॥",

    "அந்யே த்வேவமஜானந்தஶ், ஶ்ருத்வாந்யேப்₄ய உபாஸதே। \nதேSபி சாதி தரந்த்யேவ, ம்ருத்யும் ஶ்ருதி பராயணாஹா ॥13.25॥",

    "யாவத்ஸஞ்ஜாயதே கிஞ்சித், ஸத்த்வம் ஸ்தா₂வர ஜங்க₃மம்। \nக்ஷேத்ர க்ஷேத்ரஜ்ஞ ஸம்யோகா₃த், தத்₃ வித்₃தி₄ ப₄ரதர்ஷப₄ ॥13.26॥",

    "ஸமம் ஸர்வேஷு பூ₄தேஷு, திஷ்ட₂ந்தம் பரமேஶ்வரம்। \nவிநஶ்யத்ஸ்வ விநஶ்யந்தம், யஃப் பஶ்யதி ஸ பஶ்யதி ॥13.27॥",

    "ஸமம் பஶ்யந்ஹி ஸர்வத்ர, ஸமவஸ்தி₂தமீஶ்வரம் \nந ஹினஸ்த்யாத்மனாத்மானந், ததோ யாதி பராங்க ₃திம்॥₂8॥",

    "ப்ரக்ருத்யைவ ச கர்மாணி, க்ரியமாணானி ஸர்வஶஹ। \nயஃப் பஶ்யதி ததா₂த்மானம், அகர்தாரம் ஸ பஶ்யதி ॥13.29॥",

    "யதா₃ பூ₄தப்ருத₂க்₃பா₄வம், ஏகஸ்த₂மநுபஶ்யதி।\nதத ஏவ ச விஸ்தாரம், ப்₃ரஹ்ம ஸம்பத்₃யதே ததா₃ ॥13.30॥",

    "அநாதி₃த்வாந் நிர்கு₃ணத்வாத், பரமாத்மாயமவ்யயஹ। \nஶரீரஸ்தோ₂Sபி கௌந்தேய, ந கரோதி ந லிப்யதே ॥13.31॥",

    "யதா₂ ஸர்வக₃தம் ஸௌக்ஷ்ம்யாத்₃, ஆகாஶந் நோபலிப்யதே। \nஸர்வத்ராவஸ்தி₂தோ தே₃ஹே, ததா₂த்மா நோபலிப்யதே ॥13.32॥",

    "யதா₂ ப்ரகாஶயத்யேக(க்₂), க்ருத்ஸ்னம் லோகமிமம் ரவிஹி। \nக்ஷேத்ரங் க்ஷேத்ரீ ததா₂ க்ருத்ஸ்னம், ப்ரகாஶயதி பா₄ரத ॥13.33॥",

    "க்ஷேத்ரக்ஷேத்ரஜ்ஞயோரேவம், அந்தரஞ் ஜ்ஞானசக்ஷுஷா। \nபூ₄தப்ரக்ருதி மோக்ஷஞ் ச, யே விது₃ர் யாந்தி தே பரம் ॥13.34॥",

    "ஓம் தத்ஸதி₃தி ஶ்ரீமத்₃ ப₄க₃வத்₃ கீ₃தாஸு உபநிஷத்ஸு ப்₃ரஹ்மவித்₃யாயாம் யோக₃ஶாஸ்த்ரே ஶ்ரீ க்ருஷ்ணார்ஜுன ஸம்வாதே₃ க்ஷேத்ர க்ஷேத்ரஜ்ஞ விபா₄க₃ யோகோ₃ நாம த்ரயோத₃ஶோSத்₄யாயஹ ॥13॥"
  ],
  "adhyaya-14" : [
    "ஶ்ரீப₄க₃வானுவாச. \nபரம் பூ₄ய:(ஃப்) ப்ரவக்ஷ்யாமி, ஜ்ஞானானாஞ் ஜ்ஞானமுத்தமம்।\nயஜ்ஜ்ஞாத்வா முனய:(ஸ்) ஸர்வே, பராம் ஸித்₃தி₄மிதோ க₃தா:(ஹா) ॥14.1॥",

    "இத₃ஞ் ஜ்ஞானமுபாஶ்ரித்ய, மம ஸாத₄ர்ம்யமாக₃தா: (ஹா)।\nஸர்கே₃‌ऽபி நோபஜாயன்தே,  ப்ரலயே ந வ்யத₂ன்தி ச1 ॥14.2॥",

    "மம யோனிர்மஹத்₃ப்₃ரஹ்ம, தஸ்மின்  க₃ர்ப₄ந் த₃தா₄ம்யஹம்।\nஸம்ப₄வ: (ஸ்) ஸர்வபூ₄தானான், ததோ ப₄வதி பா₄ரத ॥14.3॥",

    "ஸர்வயோனிஷு கௌன்தேய, மூர்தய:(ஸ்) ஸம்ப₄வன்தி யா:(ஹா)। \nதாஸாம் ப்₃ரஹ்ம மஹத்₃யோனிர், அஹம் பீ₃ஜப்ரத₃:(ஃப்) பிதா ॥14.4॥",

    "ஸத்த்வம் ரஜஸ்தம இதி, கு₃ணா:(ஃப்) ப்ரக்ருதிஸம்ப₄வா: (ஹா)। \nநிப₃த்₄னந்தி மஹாபா₃ஹோ, தே₃ஹே தே₃ஹினமவ்யயம் ॥14.5॥",

    "தத்ர  ஸத்த்வன் நிர்மலத்வாத், ப்ரகாஶகமனாமயம்। \nஸுக₂ ஸங்கே₃ன ப₃த்₄னாதி, ஜ்ஞானஸங்கே₃ன சானக₄ ॥14.6॥",

    "ரஜோ ராகா₃த்மகம் வித்₃தி₄, த்ருஷ்ணாஸங்க₃ஸமுத்₃ப₄வம்।\nதன்னிப₃த்₄னாதி கௌன்தேய, கர்மஸங்கே₃ன தே₃ஹினம் ॥14.7॥",

    "தமஸ்த்வஜ்ஞானஜம் வித்₃தி₄, மோஹனம் ஸர்வதே₃ஹினாம்। \nப்ரமாதா₃லஸ்யனித்₃ராபி₄:(ஸ்), தன்னிப₃த்₄னாதி பா₄ரத ॥14.8॥",

    "ஸத்த்வம் ஸுகே₂ ஸஞ்ஜயதி, ரஜ:(க்₂) கர்மணி பா₄ரத। \nஜ்ஞானமாவ்ருத்ய து தம:(ஃப்), ப்ரமாதே₃ ஸஞ்ஜயத்யுத ॥14.9॥",

    "ரஜ1ஸ்தமஶ்சாபி₄பூ₄ய, ஸத்த்வம் ப₄வதி பா₄ரத ।\nரஜ:(ஸ்) ஸத்த்வன் தமஶ்சைவ, தம:(ஸ்) ஸத்த்வம் ரஜஸ்ததா₂ ॥14.10॥",

    "ஸர்வத்₃வாரேஷு தே₃ஹே‌ऽஸ்மின், ப்ரகாஶ உபஜாயதே।\nஜ்ஞானம் யதா₃ ததா₃ வித்₃யாத்₃, விவ்ருத்₃த₄ம் ஸத்த்வமித்யுத ॥14.11॥",

    "லோப₄:(ஃப்) ப்ரவ்ருத்திராரம்ப₄(க்₂), கர்மணாமஶம:(ஸ்) ஸ்ப்ருஹா।\nரஜஸ்யேதானி ஜாயன்தே, விவ்ருத்₃தே₄ ப₄ரதர்ஷப₄ ॥14.12॥",

    "அப்ரகாஶோ‌ऽப்ரவ்ருத்திஶ்ச1, ப்ரமாதோ₃ மோஹ ஏவ ச1। \nதமஸ்யேதானி ஜாயன்தே, விவ்ருத்₃தே₄ குருனன்த₃ன ॥14.13॥",

    "யதா₃ ஸத்த்வே ப்ரவ்ருத்₃தே₄ து, ப்ரலயம் யாதி தே₃ஹப்₄ருத்। \nததோ₃த்தமவிதா₃ம் லோகான், அமலான்ப்ரதிபத்₃யதே ॥14.14॥",

    "ரஜஸி ப்ரலயங் க₃த்வா, கர்மஸங்கி₃ஷு ஜாயதே। \nததா₂ ப்ரலீனஸ்தமஸி, மூட₄யோனிஷு ஜாயதே ॥14.15॥",

    "கர்மண:(ஸ்) ஸுக்ருதஸ்யாஹு:(ஸ்), ஸாத்த்விகன் நிர்மலம் ஃபலம்। \nரஜஸஸ்து ப₂லன் து₃:க₂ம், அஜ்ஞானன் தமஸ:(ஃப்) ஃபலம் ॥14.16॥",

    "ஸத்த்வாத்ஸஞ்ஜாயதே ஜ்ஞானம், ரஜஸோ லோப₄ ஏவ ச1 ।\nப்ரமாத₃மோஹௌ தமஸோ, ப₄வதோऽ‌ஜ்ஞானமேவ ச1 ॥14.17॥",

    "ஊர்த்₄வங் க₃ச்1ச₂ன்தி ஸத்த்வஸ்தா₂, மத்₄யே திஷ்ட₂ந்தி ராஜஸா:(ஹா)।\nஜக₄ன்யகு₃ணவ்ருத்திஸ்தா₂, அதோ₄ க₃ச்1ச₂ன்தி தாமஸா: (ஹா) ॥14.18॥",

    "நான்யங் கு₃ணேப்₄ய:(க்₂) கர்தாரம், யதா₃ த்₃ரஷ்டானுபஶ்யதி। \nகு₃ணேப்₄யஶ்ச1 பரம் வேத்தி, மத்₃பா₄வம் ஸோ‌ऽதி₄க₃ச்1ச₂தி ॥14.19॥",

    "கு₃ணானேதானதீத்ய த்ரீன், தே₃ஹீ தே₃ஹஸமுத்₃ப₄வான்। \nஜன்மம்ருத்யுஜராது₃:கை₂(ர்), விமுக்தோSம்ருதமஶ்னுதே ॥14.20॥",

    "அர்ஜுன உவாச \nகைர்லிங்கை₃ஸ்த்ரீன்கு₃ணானேதான், அதீதோ ப₄வதி ப்ரபோ₄। \nகிமாசார:(க்₂) கத₂ஞ் சைதாம்ஸ், த்ரீன்கு₃ணானதிவர்ததே ॥14.21॥",

    "ஶ்ரீப₄க₃வானுவாச \nப்ரகாஶஞ் ச1 ப்ரவ்ருத்திஞ் ச1, மோஹமேவ ச1 பாண்ட₃வ ।\nந த்₃வேஷ்டி ஸம்ப்ரவ்ருத்தானி, ந நிவ்ருத்தானி காங்க்ஷதி ॥14.22॥",

    "உதா₃ஸீனவதா₃ஸீனோ, கு₃ணைர்யோ ந விசா1ல்யதே ।\nகு₃ணா வர்தன்த இத்யேவ, யோsவதிஷ்ட₂தி நேங்க₃தே ॥14.23॥",

    "ஸமது₃ஃக₂ஸுக:(ஸ்) ஸ்வஸ்த₂:(ஸ்), ஸமலோஷ்டாஶ்மகாஞ்சன: (ஹ)। \nதுல்யப்ரியாப்ரியோ தீ₄ர:(ஸ்), துல்யனின்தா₃த்மஸம்ஸ்துதி:(ஹி) ॥14.24॥",

    "மானாபமானயோஸ்துல்ய:(ஸ்), துல்யோ மித்ராரி பக்ஷயோ: (ஹோ)।  \nஸர்வாரம்ப₄பரித்யாகீ₃, கு₃ணாதீத:(ஸ்) ஸ உச்1யதே ॥14.25॥",

    "மாஞ் ச1 யோ‌ऽவ்யபி₄சா1ரேண, ப₄க்தியோகே₃ன ஸேவதே। \nஸ கு₃ணான்ஸமதீத்யைதான், ப்₃ரஹ்ம பூ₄யாய கல்பதே ॥14.26॥",

    "ப்₃ரஹ்மணோ ஹி ப்ரதிஷ்டா₂ஹம், அம்ருதஸ்யாவ்யயஸ்ய ச1। \nஶாஶ்வதஸ்ய ச1 த₄ர்மஸ்ய, ஸுக₂ஸ்யைகான்திகஸ்ய ச1 ॥14.27॥",

    "ஓம் தத்ஸதி₃தி ஶ்ரீமத்₃ப₄கவத்₃கீ₃தாஸூ உபனிஷத்ஸு ப்₃ரஹ்மவித்₃யாயாம் யோக₃ஶாஸ்த்ரேஶ்ரீக்றுஷ்ணார்ஜுனஸம்வாதே₃ கு₃ணத்ரயவிபா₄க₃யோகோ₃ னாம சதுர்த₃ஶோ‌ऽத்₄யாய: (ஹ) ॥14॥"
  ],
  "adhyaya-15" : [
    "ஸ்ரீ ப₄க₃வானுவாச\nஊர்த்₄வமூலமத₄:(ஶ்) ஶாக₂ம், அஶ்வத்த₂ம்(ம்) ப்ராஹுரவ்யயம் ।\nச₂ந்தா₃ம்ஸி யஸ்ய பர்ணானி, யஸ்தம்(வ்) வேத₃ ஸ வேத₃வித் ॥15.1॥",
    
    "அத₄ஶ்சோர்த்₄வம் ப்ரஸ்ருதாஸ்தஸ்ய ஶாகா₂,\nகு₃ணப்ரவ்ருத்₃தா₄ விஷயப்ரவாலா:(ஹா) ।\nஅத₄ஶ்ச மூலாந்யநுஸந்ததானி,\nகர்மாநுப₃ந்தீ₄னி மநுஷ்யலோகே ॥15.2॥",
    
    "ந ரூபமஸ்யேஹ ததோ₂பலப்₄யதே,\nநாந்தோ ந சாதி₃ர்ந ச ஸம்ப்ரதிஷ்டா₂ ।\nஅஶ்வத்த₂மேநம் ஸுவிரூட₄மூலம்,\nஅஸங்க₃ஶஸ்த்ரேண த்₃ருடே₄ன சி₂த்வா ॥15.3॥",
    
    "தத:(ஃப்) பத₃ம்(ந்) தத்பரிமார்கி₃தவ்யம்(ய்), \nயஸ்மின்க₃தா ந நிவர்தந்தி பூ₄ய:(ஹ)।\nதமேவ சாத்₃யம்(ம்) புருஷம்(ம்) ப்ரபத்₃யே,\nயத:(ஃப்) ப்ரவ்ருத்தி:( ஃப் ) ப்ரஸ்ருதா புராணீ ॥15.4॥",
    
    "நிர்மானமோஹா ஜிதஸங்க₃தோ₃ஷா,\nஅத்₄யாத்மநித்யா விநிவ்ருத்தகாமா:(ஹா) ।\nத்₃வந்த்₃வைர்விமுக்தா:(ஸ்) ஸுக₂து₃:க₂ ஸம்ஜ்ஞைர்,\nக₃ச்ச₂ந்த்யமூடா₄:(ஃப்) பத₃மவ்யயந் தத் ॥15.5॥",
    
    "ந தத்₃பா₄ஸயதே ஸூர்யோ, ந ஶஶாங்கோ ந பாவக:(ஹ)।\nயத்₃க₃த்வா ந நிவர்தந்தே, தத்₃தா₄ம பரமம் மம ॥15.6॥",
    
    "மமைவாம்ஶோ ஜீவலோகே, ஜீவபூ₄த:(ஸ்) ஸநாதன:(ஹ) ।\nமன:(ஷ்) ஷஷ்டா₂நீந்த்₃ரியாணி, ப்ரக்ருதிஸ்தா₂னி கர்ஷதி ॥15.7॥",
    
    "ஶரீரம்(ய்) யத₃வாப்நோதி, யச்சாப்யுத்க்ராமதீஶ்வர:(ஹ)।\nக்₃ருஹீத்வைதானி ஸம்யாதி, வாயுர்க₃ந்தா₄ நிவாஶயாத் ॥15.8॥",
    
    "ஶ்ரோத்ரம்(ஞ்) சக்ஷு:(ஸ்) ஸ்பர்ஶனம்(ஞ்) ச, ரஸனம்(ங்) க்₄ராணமேவ ச।\nஅதி₄ஷ்டா₂ய மனஶ்சாயம்(வ்), விஷயாநுபஸேவதே ॥15.9॥",
    
    "உத்க்ராமந்தம்(ம்) ஸ்தி₂தம்(வ்) வாபி, பு₄ஞ்ஜானம்(வ்) வா கு₃ணாந்விதம்।\nவிமூடா₄ நாநுபஶ்யந்தி,  பஶ்யந்தி ஜ்ஞானசக்ஷுஷ:(ஹ) ॥15.10॥",
    
    "யதந்தோ யோகி₃னஶ்சைனம், பஶ்யந்த்யாத்மந்யவஸ்தி₂தம் ।\nயதந்தோsப்யக்ருதாத்மானோ, நைநம் பஶ்யந்த்யசேதஸ:(ஹ) ॥15.11॥",
    
    "யதா₃வதி₃த்யக₃தந் தேஜோ, ஜக₃த்₃பா₄ஸயதேsகி₂லம் ।\nயச்சந்த்₃ரமஸி யச்சாக்₃னௌ, தத்தேஜோ வித்₃தி₄ மாமகம் ॥15.12॥",
    
    "கா₃மாவிஶ்ய ச பூ₄தானி,  தா₄ரயாம்யஹமோஜஸா ।\nபுஷ்ணாமி சௌஷதீ₄:(ஸ்) ஸர்வா:(ஸ்), ஸோமோ பூ₄த்வா ரஸாத்மக:(ஹ ॥15.13॥",
    
    "அஹம்(வ்) வைஶ்வாநரோ பூ₄த்வா, ப்ராணிநாம்(ந்) தே₃ஹமாஶ்ரித:(ஹ)।\nப்ராணாபானஸமாயுக்த:(ஃப்), பசாம்யன்னம்(ஞ்) சதுர்வித₄ம் ॥15.14॥",
    
    "ஸர்வஸ்ய சாஹம் ஹ்ருதி₃ ஸந்நிவிஷ்டோ\nமத்த:(ஸ்) ஸ்ம்ருதிர்ஜ்ஞானமபோஹனஞ்ச ।\nவேதை₃ஶ்ச ஸர்வைரஹமேவ வேத்₃யோ\nவேதா₃ந்தக்ருத்₃ வேத₃விதே₃வ சாஹம் ॥15.15॥",
    
    "த்₃வாவிமௌ புருஷௌ லோகே, க்ஷரஶ்சாக்ஷர ஏவ ச ।\nக்ஷர:(ஸ்) ஸர்வாணி பூ₄தானி, கூடஸ்தோ₂sக்ஷர உச்யதே ॥15.16॥",
    
    "உத்தம:(ஃப்) புருஷஸ்த்வந்ய:(ஃப்), பரமாத்மேத்யுதா₃ஹ்ருத:(ஹ) ।\nயோ லோகத்ரயமாவிஶ்ய, பி₃ப₄ர்த்யவ்யய ஈஶ்வர:(ஹ) ॥15.17॥",
    
    "யஸ்மாத்க்ஷரமதீதோsஹம், அக்ஷராத₃பி சோத்தம:(ஹ) ।\nஅதோsஸ்மி லோகே வேதே₃ ச, ப்ரதி₂த:(ஃப்) புருஷோத்தம:(ஹ) ॥15.18॥",
    
    "யோ மாமேவமஸம்மூடோ₄, ஜாநாதி புருஷோத்தமம் ।\nஸ ஸர்வவித்₃ப₄ஜதி மாம், ஸர்வபா₄வேன பா₄ரத ॥15.19॥",
    
    "இதி கு₃ஹ்யதமம் ஶாஸ்த்ரம், இத₃முக்தம் மயாநக₄ ।\nஏதத்₃பு₃த்₃த்₄வா புத்₃தி₄மான்ஸ்யாத், க்ருதக்ருத்யஶ்ச பா₄ரத ॥15.20॥",
    
    "ॐ தத்ஸதி₃தி ஶ்ரீமத்₃ப₄க₃வத்₃கீ₃தாஸு உபநிஷத்ஸு ப்₃ரஹ்மவித்₃யாயாம்(ய்) யோக₃ஶாஸ்த்ரே ஶ்ரீ க்ருஷ்ணார்ஜுன ஸம்வாதே₃ புருஷோத்தம யோகோ₃ நாம பஞ்சத₃ஶோsத்₄யாய:(ஹ) ॥15॥"
  ],
  "adhyaya-16" : [
    "ஸ்ரீ ப₄க₃வாநுவாச\nஅப₄யம் ஸத்வஸம்ஶுத்₃தி₄ர், ஜ்ஞானயோக₃வ்யவஸ்தி₂தி:(ஹி)।\nதா₃னந் த₃மஶ்ச யஜ்ஞஶ்ச, ஸ்வாத்₄யாயஸ்தப ஆர்ஜவம் ॥16.1॥",

    "அஹிம்ஸா ஸத்யமக்ரோத₄:(ஸ்), த்யாக₃:(ஶ்) ஶாந்திரபைஶுநம்। \nத₃யா பூ₄தேஷ்வலோலுப்த்வம், மார்த₃வம் ஹ்ரீரசாபலம் ॥16.2॥",

    "தேஜ:(ஹ்) க்ஷமா த்₄ருதி:(ஶ்) ஶௌசம், அத்₃ரோஹோ நாதிமானிதா।\nப₄வந்தி ஸம்பதந் தைவீம், அபி₄ஜாதஸ்ய பா₄ரத ॥16.3॥",

    "த₃ம்போ₄ த₃ர்போSபி₄மானஶ்ச, க்ரோத₄:(ஃப்) பாருஷ்யமேவச।\nஅஜ்ஞானஞ் சாபி₄ஜாதஸ்ய, பார்த₂ ஸம்பத₃மாஸுரீம் ॥16.4॥",

    "தை₃வீ ஸம்பத்₃விமோக்ஷாய, நிப₃ந்தா₄யாஸுரீ மதா।\nமா ஶுச:(ஸ்) ஸம்பத₃ந் தை₃வீம், அபி₄ஜாதோSஸி பாண்ட₃வ ॥16.5॥",

    "த்₃வௌ பூ₄த ஸர்கௌ₃ லோகேSஸ்மின், தை₃வ ஆஸுர ஏவ ச।\nதை₃வோ விஸ்தரஶ:(ஃப்) ப்ரோக்த, ஆஸுரம் பார்த₂ மே ஶ்ருணு ॥16.6॥",

    "ப்ரவ்ருத்திஞ் ச நிவ்ருத்திஞ் ச, ஜனா ந விது₃ராஸுரா:(ஹா)।\nந ஶௌசந் நாபி சாசாரோ, ந ஸத்யந் தேஷு வித்₃யதே ॥16.7॥",

    "அஸத்யமப்ரதிஷ்ட₂ந் தே, ஜக₃தா₃ஹுரநீஶ்வரம்। \nஅபரஸ்பரஸம்பூ₄தங், கிமன்யத்காமஹைதுகம ॥16.8॥",

    "ஏதாந் த்₃ருஷ்டிமவஷ்டப்₄ய, நஷ்டாத்மாநோல்பபு₃த்₃த₄ய:(ஹ)।\nப்ரப₄வந்த்யுக்₃ரகர்மாண:(ஹ), க்ஷயாய ஜக₃தோSஹிதா:(ஹா) ॥16.9॥",

    "காமமாஶ்ரித்ய து₃ஷ்பூரந், த₃ம்ப₄மானமதா₃ந்விதா:(ஹா) ।\nமோஹாத்₃க்₃ருஹீத்வாஸத்₃க்₃ராஹான், ப்ரவர்தந்தேSஶுசிவ்ரதா:(ஹா) ॥16.10॥",

    "சிந்தாமபரிமேயாஞ் ச, ப்ரலயாந்தாமுபாஶ்ரிதா:(ஹா)।\nகாமோபபோ₄க₃பரமா, ஏதாவதி₃தி நிஶ்சிதா:(ஹா) ॥16.11॥",

    "ஆஶாபாஶஶதைர்ப₃த்₃தா₄:(க்₂), காமக்ரோத₄பராயணா:(ஹா)।\nஈஹந்தே காமபோ₄கா₃ர்த₂ம், அந்யாயேநார்த₂ஸஞ்சயான் ॥16.12॥",

    "இத₃மத்₃ய மயா லப்₃த₄ம், இமம் ப்ராப்ஸ்யே மனோரத₂ம்।\nஇத₃மஸ்தீத₃மபி மே, ப₄விஷ்யதி புநர்த₄னம் ॥16.13॥",

    "அஸௌ மயா ஹத:(ஶ்) ஶத்ருர், ஹநிஷ்யே சாபராநபி।\nஈஶ்வரோSஹமஹம் போ₄கீ₃, ஸித்₃தோ₄Sஹம் ப₃லவான்ஸுகீ₂ ॥16.14॥",

    "ஆட்₄யோSபி₄ஜனவாநஸ்மி, கோSந்யோSஸ்தி ஸத்₃ருஶோ மயா।\nயக்ஷ்யே தா₃ஸ்யாமி மோதி₃ஷ்ய, இத்யஜ்ஞானவிமோஹிதா:(ஹா) ॥16.15॥",

    "அநேகசித்தவிப்₄ராந்தா, மோஹஜாலஸமாவ்ருதா:(ஹா)।\nப்ரஸக்தா:(க்₂) காமபோ₄கே₃ஷு, பதந்தி நரகேSஶுசௌ ॥16.16॥",

    "ஆத்மஸம்பா₄விதா:(ஸ்) ஸ்தப்₃தா₄, த₄னமானமதா₃ந்விதா:(ஹா)।\nயஜந்தே நாமயஜ்ஞைஸ்தே, த₃ம்பே₄நாவிதி₄பூர்வகம் ॥16.17॥",

    "அஹங்காரம் ப₃லந் த₃ர்பங், காமங் க்ரோத₄ஞ் ச ஸம்ஶ்ரிதா:(ஹா)।\nமாமாத்மபரதே₃ஹேஷு, ப்ரத்₃விஷந்தோSப்₄யஸூயகா:(ஹா) ॥16.18॥",

    "தாநஹந் த்₃விஷத:(க்₂) க்ரூரான், ஸம்ஸாரேஷு நராத₄மான்। \nக்ஷிபாம்யஜஸ்ரமஶுபா₄ன், ஆஸுரீஷ்வேவ யோநிஷு ॥16.19॥",

    "ஆஸுரீம்(ய்)  யோநிமாபன்னா, மூடா₄ ஜந்மனி ஜந்மனி\nமாமப்ராப்யைவ கௌந்தேய, ததோ யாந்த்யத₄மாம்(ங்) க₃திம் ॥16.20॥",

    "த்ரிவித₄ந் நரகஸ்யேத₃ந், த்₃வாரந் நாஶனமாத்மன:(ஹ)।\nகாம:(க்₂) க்ரோத₄ஸ்ததா₂ லோப₄:(ஸ்), தஸ்மாதே₃தத்த்ரயந் த்யஜேத் ॥16.21॥",

    "ஏதைர்விமுக்த:(க்₂) கௌந்தேய, தமோத்₃வாரைஸ்த்ரிபி₄ர்நர:(ஹ)।\nஆசரத்யாத்மன:(ஶ்) ஶ்ரேய:(ஸ்), ததோ யாதி பராங் க₃திம் ॥16.22॥",

    "ய:(ஶ்) ஶாஸ்த்ரவிதி₄முத்ஸ்ருஜ்ய, வர்ததே காமகாரத:(ஹ)।\nந ஸ ஸித்₃தி₄மவாப்நோதி, ந ஸக₂ந் ந பராங் க₃திம் ॥16.23॥",

    "தஸ்மாச்சா₂ஸ்த்ரம் ப்ரமாணந் தே, கார்யாகார்யவ்யவஸ்தி₂தௌ।\nஜ்ஞாத்வா ஶாஸ்த்ரவிதா₄நோக்தங், கர்ம கர்துமிஹார்ஹஸி ॥16.24॥",

    "ஓம் தத்ஸதி₃தி ஸ்ரீமத்₃ப₄க₃வத்₃கீ₃தாஸு உபநிஷத்ஸு ப்₃ரஹ்மவித்₃யாயாம்(ய்)  யோக₃ஶாஸ்த்ரே ஸ்ரீக்ருஷ்ணார்ஜுனஸம்வாதே₃ தை₃வாஸுரஸம்பத்₃விபா₄க₃யோகோ₃ நாம ஷோட₃ஶோSத்₄யாய:(ஹ) ॥16॥"
  ],
  "adhyaya-17" : [
    "அர்ஜுன உவாச\nயே  ஶாஸ்த்ரவிதி₄முத்ஸ்ருஜ்ய, யஜந்தே  ஶ்ரத்₃த₄யாந்விதாஹா। \nதேஷாந் நிஷ்டா₂ து கா க்ருஷ்ண, ஸத்த்வமாஹோ ரஜஸ்தமஹ ।।17.1।।",

    "ஸ்ரீ ப₄க₃வான் உவாச\nத்ரிவிதா₄ ப₄வதி  ஶ்ரத்₃தா₄, தே₃ஹிநாம் ஸா ஸ்வபா₄வஜா। \nஸாத்த்விகீ ராஜஸீ சைவ, தாமஸீ சேதி தாம்  ஶ்ருணு  ।।17.2।।",

    "ஸத்த்வாநுரூபா ஸர்வஸ்ய,  ஶ்ரத்₃தா₄ ப₄வதி பா₄ரத ।\nஶ்ரத்₃தா₄ மயோSயம் புருஷோ, யோ யச்ச்₂ரத்₃த₄ஸ் ஸ ஏவ ஸஹ ।।17.3।।",

    "யஜந்தே  ஸாத்த்விகா தே₃வாந், யக்ஷ ரக்ஷாம்ஸி ராஜஸாஹா। \nப்ரேதாந் பூ₄தக₃ணாம்ஶ்சான்யே, யஜந்தே தாமஸா ஜநாஹா ।।17.4।।",

    "அஶாஸ்த்ர விஹிதங் கோ₄ரந், தப்யந்தே யே தபோ ஜநாஹா ।\nத₃ம்பா₄ஹங்கார ஸம்யுக்தா(க்₂), காமராக₃ ப₃லாந்விதாஹா ।।17.5।।",

    "கர்ஶயந்தஶ்  ஶரீரஸ்த₂ம், பூ₄தக்₃ராமமசேதஸஹ ।\nமாஞ் சைவாந்தஶ் ஶரீரஸ்த₂ந், தாந்வித்₃த்₄யாஸுர நிஶ்சயான் ।।17.6।।",

    "ஆஹாரஸ்த்வபி ஸர்வஸ்ய, த்ரிவிதோ₄  ப₄வதி ப்ரியஹ। \nயஜ்ஞஸ் தபஸ் ததா₂ தா₃னந்,தேஷாம் பே₄த₃மிமம் ஶ்ருணு ।।17.7।।",

    "ஆயுஸ் ஸத்த்வ ப₃லாரோக்₃ய, ஸுக₂ ப்ரீதி விவர்த₄நாஹா।\nரஸ்யாஸ் ஸ்நிக்₃தா₄ஸ் ஸ்தி₂ரா ஹ்ருத்₃யா, ஆஹாராஸ் ஸாத்விகப்ரியாஹா ।।17.8।।",

    "கட்வம்ல லவணாத்யுஷ்ண, தீக்ஷ்ண ரூக்ஷ விதா₃ஹிநஹ ।\nஆஹாரா ராஜஸஸ்யேஷ்டா, து₃:க₂ ஶோகாமய ப்ரதா₃ஹா  ।।17.9।।",

    "யாதயாமங் க₃தரஸம், பூதி பர்யுஷிதஞ்ச யத் ।\nஉச்சி₂ஷ்டமபி சாமேத்₄யம், போ₄ஜனந் தாமஸப்ரியம் । ।17.10।।",

    "அஃபலாகாங்க்ஷிபி₄ர்யஜ்ஞோ, விதி₄ த்₃ருஷ்டோ ய இஜ்யதே। \nயஷ்டவ்யமேவேதி மனஸ், ஸமாதா₄ய ஸ ஸாத்த்வி கஹ।।11।।",

    "அபி₄ஸந்தா₄ய து ப₂லந், த₃ம்பா₄ர்த₂மபி சைவ யத் ।\nஇஜ்யதே ப₄ரதஶ்ரேஷ்ட₂, தம்யஜ்ஞம் வித்₃தி₄ ராஜஸம்। ।17.1₂।।",

    "விதி₄ஹீநமஸ்ருஷ்டான்னம், மந்த்ரஹீனமத₃க்ஷிணம்। \nஶ்ரத்₃தா₄ விரஹிதம் யஜ்ஞந், தாமஸம் பரிசக்ஷதே। ।17.1₃।।",

    "தே₃வத்₃விஜகு₃ருப்ராஜ்ஞ, பூஜனம் ஶௌசமார்ஜவம்। \nப்₃ரஹ்மசர்யமஹிம்ஸா ச, ஶாரீரந்தப உச்யதே। ।17.1₄।।",

    "அநுத்₃வேக₃கரம் வாக்யம், ஸத்யம் ப்ரியஹிதஞ்ச யத் ।\nஸ்வாத்₄யாயாப்₄யஸனஞ் சைவ, வாங்மயந் தப உச்யதே। ।17.15।।",

    "மனப்₂ ப்ரஸாத₃ஸ் ஸௌம்யத்வம், மௌனமாத்மவிநிக்₃ரஹஹ।\nபா₄வஸம்ஶுத்₃தி₄ரித்யேதத், தபோ மானஸமுச்யதே। ।17.16।।",

    "ஶ்ரத்₃த₄யா பரயா தப்தந், தபஸ் தத்த்ரிவித₄ந் நரைஹி।\nஅப₂லாகாங்க்ஷிபி₄ர்யுக்தைஸ், ஸாத்த்விகம் பரிசக்ஷதே। ।17.17।।",

    "ஸத்கார மான பூஜார்த₂ந், தபோ த₃ம்பே₄ன சைவ யத்।\nக்ரியதே ததி₃ஹ ப்ரோக்தம், ராஜஸஞ் சலமத்₄ருவம்। ।17.18।।",

    "மூட₄ க்₃ராஹேணாத்மநோ யத், பீட₃யா க்ரியதே தபஹ।\nபரஸ்யோத்ஸாத₃நார்த₂ம் வா, தத்தாமஸமுதா₃ஹ்ருதம்। ।17.19।।",

    "தா₃தவ்யமிதி யத்₃தா₃னந், தீ₃யதேSநுபகாரிணே।\nதே₃ஶே காலே ச பாத்ரே ச, தத்₃தா₃னம் ஸாத்த்விகம் ஸ்ம்ருதம்। ।17.20।।",

    "யத்து ப்ரத்யுபகாரார்த₂ம், ப₂லமுத்₃தி₃ஶ்ய வா புனஹ।\nதீ₃யதே ச பரிக்லிஷ்டந், தத்₃தா₃னம் ராஜஸம் ஸ்ம்ருதம்। ।17.21।।",

    "அதே₃ஶகாலே யத்₃தா₃னம், அபாத்ரேப்₄யஶ்ச தீ₃யதே।\nஅஸத்க்ருதமவஜ்ஞாதந், தத்தாமஸமுதா₃ஹ்ருதம்। ।17.2₂।।",

    "ஓம் தத்ஸதி₃தி நிர்தே₃ஶோ, ப்₃ரஹ்மணஸ்த்ரிவித₄ஸ் ஸ்ம்ருதஹ।\nப்₃ராஹ்மணாஸ்தேன வேதா₃ஶ்ச, யஜ்ஞாஶ்ச விஹிதாப்₂ புரா। ।17.2₃।।",

    "தஸ்மாதோ₃மித்யுதா₃ஹ்ருத்ய, யஜ்ஞ தா₃னதபக்₂ க்ரியாஹா।\nப்ரவர்தந்தே விதா₄னோக்தாஸ், ஸததம் ப்₃ரஹ்மவாதி₃னாம்। ।17.2₄।।",

    "ததி₃த்யநபி₄ஸந்தா₄ய, ப₂லம் யஜ்ஞதபக்₂ க்ரியாஹா।\nதா₃னக்ரியாஶ்ச விவிதா₄க்₂, க்ரியந்தே மோக்ஷ காங்க்ஷிபி₄ஹி। ।17.25।।",

    "ஸத்₃பா₄வே ஸாது₄பா₄வே ச, ஸதி₃த்யேதத் ப்ரயுஜ்யதே।\nப்ரஶஸ்தே கர்மணி ததா₂, ஸச்ச₂ப்₃த₃ப்₂ பார்த₂ யுஜ்யதே। ।17.26।।",

    "யஜ்ஞே தபஸி தா₃னே ச, ஸ்தி₂திஸ் ஸதி₃தி சோச்யதே।\nகர்ம சைவ தத₃ர்தீ₂யம், ஸதி₃த்யேவாபி₄தீ₄யதே। ।17.27।।",

    "அஶ்ரத்₃த₄யா ஹுதந் த₃த்தந், தபஸ் தப்தங் க்ருதஞ் ச யத்।\nஅஸதி₃த்யுச்யதே பார்த₂, ந ச தத்ப்ரேத்ய நோ இஹ। ।17.28।।",

    "ஓம் தத்ஸதி₃தி ஶ்ரீமத்₃ ப₄க₃வத்₃கீ₃தாஸு உபநிஷத்ஸு ப்₃ரஹ்மவித்₃யாயாம் யோக₃ஶாஸ்த்ரே ஶ்ரீ க்ருஷ்ணார்ஜுன ஸம்வாதே₃ ஶ்ரத்₃தா₄ த்ரய விபா₄க₃ யோகோ₃ நாம ஸப்தத₃ஶோSத்₄யாயஹ ॥17॥"
  ],
  "adhyaya-18" : [
    "அர்ஜுன உவாச\nஸந்ந்யாஸஸ்ய மஹாபா₃ஹோ, தத்த்வமிச்சா₂மி வேதி₃தும்। \nத்யாக₃ஸ்ய ச ஹ்ருஷீகேஶ, ப்ருத₂க் கேஶிநிஷூத₃ ந॥18.1॥",

    "ஶ்ரீ ப₄க₃வாநுவாச\nகாம்யாநா(ங்) கர்மணா(ந்) ந்யாஸம், ஸந்ந்யாஸ(ங்) கவயோ விது₃ஹு। \nஸர்வ கர்ம ஃபல த்யாக₃ம், ப்ராஹுஸ் த்யாக₃ம் விசக்ஷணா ஹா॥18.2॥",

    "த்யாஜ்ய(ந்) தோ₃ஷவதி₃த்யேகே, கர்ம ப்ராஹுர் மநீஷிணஹ।\nயஜ்ஞ தா₃ன தபஹ்கர்ம, ந த்யாஜ்யமிதி சாப ரே॥18.3॥",

    "நிஶ்சயம் ஶ்ருணு மே தத்ர, த்யாகே₃ ப₄ரதஸத்தம।\nத்யாகோ₃ஹி புருஷ வ்யாக்₄ர, த்ரிவித₄ஸ் ஸம்ப்ரகீர்தித ஹ॥18.4॥",

    "யஜ்ஞ தா₃ன தபஹ்கர்ம, ந த்யாஜ்ய(ங்) கார்யமேவதத்।\nயஜ்ஞோ தா₃ந(ந்) தபஶ்சைவ பாவநாநி மநீஷிணா ம்॥18.5॥",

    "ஏதாந்யபி து கர்மாணி,ஸங்க₃(ந்) த்யக்த்வா ஃபலானி ச।\nகர்தவ்யாநீதி மே பார்த₂,நிஶ்சிதம் மதமுத்தம ம்॥18.6॥",

    "நியதஸ்ய து ஸந்ந்யாஸ(க்₂), கர்மணோ நோபபத்₃யதே।\nமோஹாத்தஸ்ய பரித்யாக₃ஸ், தாமஸ(ஃப்) பரிகீர்தித ஹ॥18.7॥",

    "து₃க்₂க₂மித்யேவ யத்கர்ம, காயக்லேஶப₄யாத்த்யஜேத்।\nஸ க்ருத்வா ராஜஸ(ந்) த்யாக₃(ந்), நைவ த்யாக₃ஃபலம் லபே₄ த்॥18.8॥",

    "கார்யமித்யேவ யத் கர்ம, நியத(ங்) க்ரியதேsர்ஜுன।\nஸங்க₃(ந்) த்யக்த்வா ஃபல(ஞ்) சைவ,ஸ த்யாக₃(ஸ்) ஸாத்த்விகோ மத ஹ॥18.9॥",

    "ந த்₃வேஷ்ட்யகுஶல(ங்) கர்ம, குஶலே நாநுஷஜ்ஜதே।\nத்யாகீ₃ ஸத்த்வ ஸமாவிஷ்டோ, மேதா₄வீ சி₂ந்ந ஸம்ஶயஹ ॥18.10॥",

    "ந ஹி தே₃ஹப்₄ருதா ஶக்ய(ந்), த்யக்து(ங்) கர்மாண்யஶேஷதஹ।\nயஸ்து கர்மஃபல த்யாகீ₃, ஸ த்யாகீ₃த்யபி₄தீ₄யதே ॥18.11॥",

    "அநிஷ்டமிஷ்டம் மிஶ்ர(ஞ்) ச, த்ரிவித₄(ங்) கர்மண(ஃப்) ஃப₂லம்।\nப₄வத்ய த்யாகி₃நாம் ப்ரேத்ய, ந து ஸந்ந்யாஸிநா(ங்) க்வசித் ॥18.12॥",

    "பஞ்சைதாநி மஹாபா₃ஹோ, காரணாநி நிபோ₃த₄ மே।\nஸாங்க்₂யே க்ருதாந்தே ப்ரோக்தாநி, ஸித்₃த₄யே ஸர்வகர்மணாம் ॥18.13॥",

    "அதி₄ஷ்டா₂ந(ந்) ததா₂ கர்தா, கரண(ஞ்) ச ப்ருத₂க்₃வித₄ம்।\nவிவிதா₄ஶ்ச ப்ருத₂க்சேஷ்டா, தை₃வ(ஞ்) சைவாத்ர பஞ்சமம் ॥18.14॥",

    "ஶரீர வாங்மனோபி₄ர்யத், கர்ம ப்ராரப₄தே நரஹ।\nந்யாய்யம் வா விபரீதம் வா, பஞ்சைதே தஸ்ய ஹேதவஹ ॥18.15॥",

    "தத்ரைவம் ஸதி கர்தாரம், ஆத்மாந(ங்) ஙகேவல(ந்) து யஹ।\nபஶ்யத்யக்ருத பு₃த்₃தி₄த்வாந், ந ஸ பஶ்யதி து₃ர்மதிஹி ॥18.16॥",

    "யஸ்ய நாஹங்க்ருதோ பா₄வோ, பு₃த்₃தி₄ர்யஸ்ய ந லிப்யதே।\nஹத்வாபி ஸ இமா(ம்ல் nasal tone) லோகாந், ந ஹந்தி ந நிப₃த்₄யதே ॥18.17॥",

    "ஜ்ஞான(ஞ்) ஜ்ஞேயம் பரிஜ்ஞாதா, த்ரிவிதா₄ கர்மசோத₃நா।\nகரண(ங்) கர்ம கர்தேதி, த்ரிவித(க்₂) கர்ம ஸங்க்₃ரஹஹ ॥18.18॥",

    "ஜ்ஞாந(ங்) கர்ம ச கர்தா ச, த்ரிதை₄வ கு₃ணபே₄த₃தஹ।\nப்ரோச்யதே கு₃ண ஸங்க்₂யாநே, யதா₂வச்ச்₂ருணு தாந்யபி ॥18.19॥",

    "ஸர்வ பூ₄தேஷு யேநைகம், பா₄வமவ்யயமீக்ஷதே।\nஅவிப₄க்தம் விப₄க்தேஷு, தஜ்ஞாநம் வித்₃தி₄ ஸாத்த்விகம் ॥18.20॥",

    "ப்ருத₂க்த்வேந து யஜ்ஞான(ந்) நாநா பா₄வாந் ப்ருத₂க்₃விதா₄ன்।\nவேத்தி ஸர்வேஷு பூ₄தேஷு, தஜ்ஞானம் வித்₃தி₄ ராஜஸம் ॥18.21॥",

    "யத்து க்ருத்ஸ்நவதே₃கஸ்மின் கார்யே ஸக்தமஹைதுகம்।\nஅதத்வார்த₂வதல்ப(ஞ்), தத்தாமஸமுதா₃ஹ்ருதம் ॥18.22॥",

    "நியதம் ஸங்க₃ரஹிதம், அராக₃த்₃வேஷத(க்₂) க்ருதம்।\nஅஃபலப்ரேப்ஸுநா கர்ம, யத்த்தத்ஸாத்த்விகமுச்யதே ॥18.23॥",

    "யத்து காமேப்ஸுநா கர்ம,ஸாஹங்காரேண வா புநஹ।\nக்ரியதே பஹுலாயாஸ(ந்) தத்₃ராஜஸமுதா₃ஹ்ருதம் ॥18.24॥",

    "அநுப₃ந்த₄(ங்) க்ஷயம் ஹிம்ஸாம், அநவேக்ஷ்ய ச। \nபௌருஷம்மோஹாதா₃ரப்₄யதே கர்ம, யத்தத்தாமஸமுச்யதே ॥18.25॥",

    "முக்த ஸங்கோ₃sநஹம்வாதீ₃,த்₄ருத்யுஸாஹ ஸமந்விதஹ।\nஸித்₃த்₄யஸித்₃த்₄யோர்நிர்விகாரஹ, கர்தா ஸாத்த்விக உச்யதே ॥18.26॥",

    "ராகீ₃ கர்மஃபலப்ரேப்ஸுர்லுப்₃தோ₄ ஹிம்ஸாத்மகோsஶுசிஹி।\nஹர்ஷஶோகாந்வித(க்₂) கர்தா, ராஜஸ(ஃப்₂) பரிகீர்திதஹ ॥18.27॥",

    "அயுக்த(ஃப்₂) ப்ராக்ருத(ஸ்) ஸ்தப்₃த₄(ஶ்) ஶடோ₂sநைஷ்க்ருதிகோsலஸஹ।\nவிஷாதீ₃ தீ₃ர்க₄ஸூத்ரீ ச, கர்தா தாமஸ உச்யதே ॥18.28॥",

    "பு₃த்₃தே₄ர்பே₄த₃(ந்) த்₄ருதேஶ்சைவ, குணதஸ்த்ரிவித₄ம் ஶ்ருணு।\nப்ரோச்யமாநமஶேஷேண, ப்ருத₂க்த்வேன த₄னஞ்ஜய ॥18.29॥",

    "ப்ரவ்ருத்தி(ஞ்) ச நிவ்ருத்தி(ஞ்) ச, கார்யாகார்யே ப₄யாப₄யே।\nப₃ந்த₄ம் மோக்ஷ(ஞ்) ச யா வேத்தி, பு₃த்₃தி₄ஸ் ஸா பார்த₂ ஸாத்த்விகீ ॥18.30॥",

    "யயா த₄ர்மமத₄ர்ம(ஞ்) ச, கார்ய(ஞ்) சாகார்யமேவ ச।\nஙஅயதா₂வத் ப்ரஜாநாதி, பு₃த்₃தி₄ஸ் ஸா பார்த₂ ராஜஸீ ॥18.31॥",

    "அத₄ர்ம(ந்) த₄ர்மமிதி யா, மந்யதே தமஸாவ்ருதா।\nஸர்வார்தா₂ந் விபரீதாம்ஶ்ச,  பு₃த்₃தி₄(ஸ்) ஸா பார்த₂ தாமஸீ ॥18.32॥",

    "த்₄ருத்யா யயா தா₄ரயதே, மனஹ்ப்ராணேந்த்ரிய க்ரியாஹா।\nயோகே₃நாவ்யபி₄சாரிண்யா, த்₄ருதிஸ் ஸா பார்த₂ ஸாத்த்விகீ ॥18.33॥",

    "யயா து த₄ர்மகாமார்தா₂ந், த்₄ருத்யா தா₄ரயதேsர்ஜுன।\nப்ரஸங்கே₃ந ஃப₂லாகாங்க்ஷீ, த்₄ருதிஸ் ஸா பார்த₂ ராஜஸீ ॥18.34॥",

    "யயா ஸ்வப்நம் ப₄யம் ஶோகம், விஷாத₃ம் மத₃மேவ ச।\nந விமுஞ்சதி து₃ர்மேதா₄ த்₄ருதிஸ் ஸா பார்த₂ தாமஸீ ॥18.35॥",

    "ஸுக₂ந் த்விதா₃நீந் த்ரிவித₄ம், ஶ்ருணு மே ப₄ரதர்ஷப₄।\nஅப்₄யாஸாத்₃ ரமதே யத்ர, து₃க்₂கா₂ந்தஞ் ச நிக₃ச்ச₂தி ॥18.36॥",

    "யத்தத₃க்₃ரே விஷமிவ, பரிணாமேsம்ருதோபமம்।\nதத்ஸுக₂ம் ஸாத்த்விகம் ப்ரோக்தம், ஆத்மபு₃த்₃தி₄ ப்ரஸாத₃ஜம் ॥18.37॥",

    "விஷயேந்த்₃ரிய ஸம்யோகா₃த்,யத்தத₃க்₃ரேsம்ருதோபமம்।\nபரிணாமே விஷமிவ, தத்ஸுக₂ம் ராஜஸம் ஸ்ம்ருதம் ॥18.38॥",

    "யத₃க்₃ரே சாநுப₃ந்தே₄ ச, ஸுக₂ம் மோஹநமாத்மநஹ।\nநித்₃ராலஸ்ய ப்ரமாதோ₃த்த₂ந், தத்தாமஸமுதா₃ஹ்ருதம் ॥18.39॥",

    "ந தத₃ஸ்தி ப்ருதி₂வ்யாம் வா, தி₃வி தே₃வேஷு வா புநஹ ।\nஸத்த்வம் ப்ரக்ருதிஜைர்முக்தம், யதே₃பி₄ஸ் ஸ்யாத்த்ரிபி₄ர்கு₃ணைஹி ॥18.40॥",

    "ப்₃ராஹ்மண க்ஷத்ரிய விஶாம், ஶூத்₃ராணாஞ் ச பரந்தப।\nகர்மாணி ப்ரவிப₄க்தாநி, ஸ்வபா₄வ ப்ரப₄வைர்கு₃ணைஹி ॥18.41॥",

    "ஶமோ த₃மஸ் தபஶ் ஶௌசங், க்ஷாந்திரார்ஜவமேவ ச।\nஜ்ஞானம் விஜ்ஞானம் ஆஸ்திக்யம், ப்₃ரஹ்ம கர்ம ஸ்வபா₄வஜம் ॥18.42॥",

    "ஶௌர்யந் தேஜோ த்₄ருதிர் தா₃க்ஷ்யம், யுத்₃தே₄ சாப்ய பலாயநம்।\nதா₃நம் ஈஶ்வரபா₄வஶ்ச, க்ஷாத்ரங் கர்ம ஸ்வபா₄வஜம் ॥18.43॥",

    "க்ருஷி கௌ₃ரக்ஷ்ய வாணிஜ்யம், வைஶ்ய கர்ம ஸ்வபா₄வஜம்। \nபரிசர்யாத்மகங் கர்ம, ஶூத்₃ரஸ்யாபி ஸ்வபா₄வஜம் ॥18.44॥",

    "ஸ்வே ஸ்வே கர்மண்யபி₄ரதஸ், ஸம்ஸித்₃தி₄ம் லப₄தே நரஹ।\nஸ்வகர்ம நிரதஸ் ஸித்₃தி₄ம், யதா₂ விந்த₃தி தச்ச்₂ருணு ॥18.45॥",

    "யதஃப்₂ ப்ரவ்ருத்திர் பூ₄தாநாம், யேந ஸர்வமித₃ந் ததம்।\nஸ்வகர்மணா தமப்₄யர்ச்ய, ஸித்₃தி₄ம் விந்த₃தி மாநவஹ ॥18.46॥",

    "ஶ்ரேயாந் ஸ்வத₄ர்மோ விகு₃ணஃப்₂, பரத₄ர்மாத் ஸ்வநுஷ்டி₂தாத்। \nஸ்வபா₄வ நியதங் கர்ம, குர்வந்நாப்நோதி கில்பி₃ஷம் ॥18.47॥",

    "ஸஹஜங் கர்ம கௌந்தேய, ஸதோ₃ஷமபி ந த்யஜேத்।\nஸர்வாரம்பா₄ ஹி தோ₃ஷேண, தூ₄மேநாக்₃நிரிவாவ்ருதாஹா ॥18.48॥",

    "அஸக்த பு₃த்₃தி₄ஸ் ஸர்வத்ர, ஜிதாத்மா விக₃த ஸ்ப்ருஹஹ। \nநைஷ்கர்ம்ய ஸித்₃தி₄ம் பரமாம், ஸந்ந்யாஸேநாதி₄க₃ச்ச₂தி ॥18.49॥",

    "ஸித்₃தி₄ம் ப்ராப்தோ, யதா₂ ப்₃ரஹ்ம, ததா₂ப்நோதி நிபோ₃த₄ மே ।\nஸமாஸேநைவ கௌந்தேய, நிஷ்டா₂ ஜ்ஞானஸ்ய யா பரா ॥18.50॥",

    "பு₃த்₃த்₄யா விஶுத்₃த₄யா யுக்தோ, த்₄ருத்யாத்மாநந் நியம்ய ச।\nஶப்₃தா₃தீ₃ந் விஷயாம்ஸ்த்யக்த்வா, ராக₃ த்₃வேஷௌ வ்யுத₃ஸ்ய ச ॥18.51॥",

    "விவிக்த ஸேவீ லக்₄வாஷீ, யத வாக் காய மாநஸஹ।\nத்₄யாந யோக₃ பரோ நித்யம், வைராக்₃யம் ஸமுபாஶ்ரிதஹ ॥18.52॥",

    "அஹங்காரம் ப₃லந் த₃ர்பங், காமங்க்ரோதம் பரிக்₃ரஹம் ।\nவிமுச்ய நிர்மமஶ் ஶாந்தோ, ப்₃ரஹ்ம பூ₄யாய கல்பதே ॥18.53॥",

    "ப்₃ரஹ்ம பூ₄தஃப்₂ ப்ரஸந்நாத்மா, ந ஶோசதி ந காங்க்ஷதி।\nஸமஸ் ஸர்வேஷு பூ₄தேஷு, மத்₃ப₄க்திம் லப₄தே பராம் ॥18.54॥",

    "ப₄க்த்யா மாமபி₄ஜாநாதி, யாவாந் யஶ்சாஸ்மி தத்த்வதஹ।\nததோ மாந் தத்த்வதோ ஜ்ஞாத்வா, விஶதே தத₃நந்தரம் ॥18.55॥",

    "ஸர்வ கர்மாண்யபி ஸதா₃, குர்வாணோ மத்₃வ்யபாஶ்ரயஹ। \nமத்ப்ரஸாதா₃த₃வாப்நோதீ, ஶாஶ்வதம் பத₃மவ்யயம் ॥18.56॥",

    "சேதஸா ஸர்வ கர்மாணி, மயி ஸந்ந்யஸ்ய மத்பரஹ ।\nபு₃த்₃தி₄யோக₃முபாஶ்ரித்ய, மச்சித்தஸ் ஸததம் ப₄வ ॥18.57॥",

    "மச்சித்தஸ் ஸர்வ து₃ர்கா₃ணி, மத்ப்ரஸாதா₃த்தரிஷ்யஸி ।\nஅத₂ சேத்த்வமஹங்காராந், ந ஶ்ரோஷ்யஸி விநங்க்ஷ்யஸி ॥18.58॥",

    "யத₃ஹங்காரமாஶ்ரித்ய, ந யோத்ஸ்ய இதி மந்யஸே।\nமித்₂யைஷ வ்யவஸாயஸ்தே, ப்ரக்ருதிஸ்த்வாந் நியோக்ஷ்யதி ॥18.59॥",

    "ஸ்வபா₄வஜேந கௌந்தேய, நிப₃த்₃த₄ஸ் ஸ்வேந கர்மணா।\nகர்துந் நேச்ச₂ஸி யந்மோஹாத் கரிஷ்யஸ்யவஶோsபி தத் ॥18.60॥",

    "ஈஶ்வரஸ் ஸர்வபூ₄தாநாம், ஹ்ருத்₃தே₃ஶேsர்ஜுன திஷ்ட₂தி।\nப்₄ராமயந் ஸர்வ பூ₄தாநி, யந்த்ராரூடா₄நி மாயயா ॥18.61॥",

    "தமேவ ஶரணங் கச்ச₂, ஸர்வ பா₄வேந பா₄ரத।\nதத்ப்ரஸாதா₃த் பராம் ஶாந்திம், ஸ்தா₂நம் ப்ராப்ஸ்யஸி ஶாஶ்வதம் ॥18.62॥",

    "இதி தே ஜ்ஞாநமாக்₂யாதங், கு₃ஹ்யாத்₃ கு₃ஹ்யதரம் மயா। \nவிம்ருஶ்யைதத₃ஶேஷேண, யதே₂ச்ச₂ஸி ததா₂ குரு ॥18.63॥",

    "ஸர்வ கு₃ஹ்யதமம் பூ₄யஶ், ஶ்ருணு மே பரமம் வசஹ।\nஇஷ்டோsஸி மே த்₃ருட₄மிதி, ததோ வக்ஷ்யாமி தே ஹிதம் ॥18.64॥",

    "மந்மநா ப₄வ மத்₃ப₄க்தோ, மத்₃யாஜீ மாந் நமஸ்குரு। \nமாமேவைஶ்யஸி ஸத்யந் தே, ப்ரதி ஜாநே ப்ரியோsஸி மே ॥18.65॥",

    "ஸர்வ த₄ர்மாந் பரித்யஜ்ய, மாமேகம் ஶரணம் வ்ரஜ।\nஅஹந் த்வா ஸர்வ  பாபேப்₄யோ, மோக்ஷயிஷ்யாமி மா ஶுசஹ ॥18.66॥",

    "இத₃ந் தே நாதபஸ்காய, நாப₄க்தாய கதா₃சந।\nந சாஶுஶ்ரூஶவே வாச்யந், ந ச மாம் யோsப்₄யஸூயதி ॥18.67॥",

    "ய இமம் பரமங் கு₃ஹ்யம், மத்₃ப₄க்தேஷ்வபி₄தா₄ஸ்யதி।\nப₄க்திம் மயி பராங் க்ருத்வா, மாமேவைஷ்யத்யஸம்ஶயஹ ॥18.68॥",

    "ந ச தஸ்மாந் மநுஷ்யேஷு, கஶ்சிந் மே ப்ரிய க்ருத்தமஹ।\nப₄விதா ந ச மே தஸ்மாத்₃, அந்யஃப்₂ ப்ரியதரோ பு₄வி ॥18.69॥",

    "அத்₄யேஷ்யதே ச ய இமந், த₄ர்ம்யம் ஸம்வாத₃மாவயோஹோ।\nஜ்ஞான யஜ்ஞேன தேநாஹம், இஷ்டஸ் ஸ்யாமிதி மே மதிஹி ॥18.70॥",

    "ஶ்ரத்₃தா₄வாநநஸூயஶ்ச, ஶ்ருணுயாத₃பி யோ நரஹ।\nஸோsபி முக்தஶ் ஶுபா₄(ம்ல்)லோகாந், ப்ராப்நுயாத் புண்ய கர்மணாம் ॥18.71॥",

    "கச்சிதே₃தச்ச்₂ருதம் பார்த₂, த்வயைகாக்₃ரேண சேதஸா। \nகச்சித₃ஜ்ஞாந ஸம்மோஹஃப்₂ ப்ரநஷ்டஸ்தே த₄நஞ்ஜய ॥18.72॥",

    "அர்ஜுன உவாச\nநஷ்டோ மோஹஸ் ஸ்ம்ருதிர் லப்₃தா₄, த்வத்ப்ரஸாதா₃ந் மயாச்யுத। \nஸ்தி₂தோsஸ்மி க₃த ஸந்தே₃ஹக்₂ கரிஷ்யே வசனந் தவ ॥18.73॥",

    "ஸஞ்ஜய உவாச\nஇத்யஹம் வாஸுதே₃வஸ்ய, பார்த₂ஸ்ய ச மஹாத்மநஹ ।\nஸம்வாத₃மிமமஶ்ரௌஷம், அத்₃பு₄தம் ரோமஹர்ஷணம் ॥18.74॥",

    "வ்யாஸ ப்ரஸாதா₃ச்ச்₂ருதவாந், ஏதத்₃கு₃ஹ்யமஹம் பரம்।\nயோக₃ம் யோகே₃ஶ்வராத் க்ருஷ்ணாத், ஸாக்ஷாத் கத₂யதஸ் ஸ்வயம் ॥18.75॥",

    "ராஜந் ஸம்ஸ்ம்ருத்ய ஸம்ஸ்ம்ருத்ய, ஸம்வாத₃மிமம் அத்₃பு₄தம்। \nகேஶவார்ஜுநயோஃப்₂ புண்யம், ஹ்ருஷ்யாமி ச முஹுர்முஹுஹு ॥18.76॥",

    "தச்ச ஸம்ஸ்ம்ருத் ஸம்ஸ்ம்ருத்ய, ரூபமத்யத்₃பு₄தம் ஹரேஹே।\nவிஸ்மயோ மே மஹாந் ராஜந், ஹ்ருஷ்யாமி ச புநஃப்புநஹ ॥18.77॥",

    "யத்ர யோகே₃ஶ்வரக்₂ க்ருஷ்ணோ, யத்ர பார்தோ₂ த₄நுர்த₄ரஹ।\nதத்ர ஶ்ரீர் விஜயோபூ₄திர், த்₄ருவா நீதிர் மதிர் மம ॥18.78॥",

    "ௐ தத்ஸதி₃தி ஶ்ரீமத்₃ப₄க₃வத்₃கீ₃தாஸு உபநிஷத்ஸு ப்₃ரஹ்ம வித்₃யாயாம் யோக₃ஶாஸ்த்ரே ஶ்ரீ க்ருஷ்ணார்ஜுந ஸம்வாதே₃, மோக்ஷ ஸந்ந்யாஸ யோகோ₃ நாம அஷ்டாத₃ஶோsத்₄யாயஹ ॥18॥"
  ]
},
{
  "adhyaya-1" : [
    "திருதராஷ்டிரர் கேட்கிறார் : ஸஞ்ஜயா, தர்மபூமியான குருக்ஷேத்திர நிலத்தில் போர் செய்ய விரும்பித் திரண்ட என்புதல்வர்களும் பாண்டுவின்  புதல்வர்களும் என்ன செய்தனர்? 1.1",

    "ஸஞ்ஜயன் கூறுகிறார் : அந்த சமயத்தில், ராஜா துரியோதனன் அணிவகுத்து நின்ற பாண்டவர் படையைப் பார்த்துவிட்டு ஆசாரியர் துரோணரை அணுகி பின்வரும் சொற்களைக் கூறலானான். 1.2",

    "ஆசாரியரே! துருபதன் மகனும் தங்கள் திறமைசாலியான சீடனுமாகிய திருஷ்டத்யும்ந்நால் வகுப்புற்ற இப்பெரிய பாண்டவப் படையைப் பாருங்கள். 1.3",

    "இதோ பாருங்கள், அவர்களின் அணிகளில் யுயுதானன், விராடன், துருபதன் போன்ற பலம் வாய்ந்த போர்வீரர்கள், பீமன் மற்றும் அர்ஜுனனுக்கு இணையான வலிமைமிக்க வில்களை ஏந்தியவர்களாகவும், போர்களத்தில் வலிமையில் சமமானவர்களாகவும் உள்ளனர். 1.4",

    "திருஷ்டகேது, சேகிதானன், காசியின் வீர மன்னன், புருஜித், குந்திபோஜன் மற்றும் ஷைப்யா போன்ற திறமையானவர்கள் உள்ளனர்—அனைவரும் சிறந்த மனிதர்கள். 1.5",

    "அவர்களின் அணிகளில் வலிமைமிக்க யுதாமந்யுவும், வீரம் மிக்க உத்தமெளஜாவும், சுபத்திரை மகன் அபிமன்யுவும், திரெளபதியின் ஐந்து மகன்களான, மகாரதர்கள் உள்ளனர். 1.6",

    "அந்தணர்களிற் சிறந்தவரே! இனி நமது தரப்பில் உள்ள தலைமை தளபதிகளைப் பற்றியும் கேளுங்கள், இவற்றை இப்போது நான் உங்களுக்குச் சொல்கிறேன், அவர்கள் தலைமை தாங்குவதற்கு குறிப்பாகத் தகுதியானவர்கள். 1.7",

    "துரோணாசார்யரான தாங்கள், பிதாமஹர் பீஷ்மர், கர்ணன், கிருபாசார்யர், அசுவத்தாமன், விகர்ணன், சோமதத்தனின் மகன் பூரிச்ரவஸ் போன்றவர்கள் யுத்தங்களில் எப்போதுமே வெற்றியே பெற்றவர்கள். 1.8",

    "மேலும், எனக்காக உயிரைக் கொடுக்கத் தயாராக இருக்கும் இன்னும் பல வீரப்போராளிகள் இருக்கிறார்கள். அவர்கள் அனைவரும் போர்க்கலையில் கைதேர்ந்தவர்கள், பல்வேறு வகையான ஆயுதங்களைக் கொண்டவர்கள். 1.9",

    "நமது படையின் பலம் அளவில்லாதது, ஏனெனில் பிதாமஹர் பீஷ்மரால் நாம் பாதுகாப்பாகப் போரிடப்போகிறோம், அதே சமயம் பீமனால் கவனமாகப் படைக்கப்பட்ட பாண்டவப் படையின் பலம்  அளவிடக்கூடியது.  1.10",

    "அனைத்துப் போர்முனைகளிலும் அவரவர் இடத்தை தந்திரமாய் பாதுகாத்தாலும், பிதாமஹர் பீஷ்மரை நான்கு திசைகளிலும் முழு ஆதரவுடன் அரணாக நின்று காப்பாற்றும் படி கௌரவ சேனையின் அனைத்து தளபதிக்கும் கட்டளை இட்டான் துரியோதனன். 1.11",

    "துரியோதனனின் உள்ளம் மகிழ்ச்சியடையும் வகையில் சிங்கத்தின் கர்ஜனையைப் போன்று உரக்கக் கர்ஜித்து சங்கை முழங்கினார்  குரு வம்சத்தின் பெரிய முதியவரான, மேலும் புகழ்பெற்ற குலபதி பீஷ்மர். 1.12",

    "அதன்பிறகு, சங்குகளும் பேரிகைகளும் தம்பட்டம் , பறை , கொம்புகள் முதலிய வாத்தியங்களும் திடீரென்று ஒன்றாகவே முழங்கின. அவற்றின் ஒருங்கிணைந்த ஒலி மிகவும் பயங்கரமாக இருந்தது. 1.13",

    "அடுத்தாற்போல், பாண்டவப் படையின் நடுவில் இருந்து, வெள்ளைக் குதிரைகள் பூட்டப்பட்ட உயர்ந்த தேரில் அமர்ந்து, மாதவனும் அர்ஜுனனும் தங்கள் தெய்வீகமான சங்குகளை முழங்கினார்கள். 1.14",

    "ஹிருஷிகேஷர் பாஞ்சஜன்யம் என்று அழைக்கப்படும் தனது சங்கையும் அர்ஜுனன் தேவதத்தம் என்ற சங்கை முழங்கினார்கள். பீமன் பெரும் விருப்பமுடன் உண்பவர் மற்றும் கடுமையான பணிகளைச் செய்பவர், பௌண்டிரம் என்று அழைக்கப்படும் தனது வலிமைமிக்க சங்கை முழங்கினார். 1.15",

    "குந்தியின் மகனாகிய அரசர் யுதிஷ்டிரர் அநந்தவிஜயம் என்ற சங்கையும்,நகுலனும் சகதேவனும் முறையே ஸுகோஷம் , மணிபுஷ்பகம் என்ற சங்குகளையும் முழங்கினார்கள். 1.16",

    "வில்லாளிகளில் மிகச்சிறந்த வில்லாளியான காசிராஜனும் மகாரதனாகிய சிகண்டியும், திருஷ்டத்யும்னனும், மன்னன் விராடனும், வெல்லப்படாத ஸாத்யகியும் தங்கள் சங்குகளை முழங்கினார்கள்.  1.17",

    "பூமியின் அதிபதியான துருபதனும், திரௌபதியின் ஐந்து மகன்களும் மற்றும் வலிமைமிக்க ஆயுதம் கொண்ட சுபத்திரை மகன் அபிமன்யுவும் தனித்தனியே  அவர்களின் சங்குகளை ஒலித்தனர். 1.18",

    "அந்தப் பெருமுழக்கம்வானையும் மண்ணையும் பிளவுறச் செய்து திருதராஷ்டிரர் மகன்களின் இதயங்களைப் பிளந்தது. 1.19",

    "அப்போது, ​​பாண்டுவின் மகன் அர்ஜுனன், தன்தேரின் கொடியில் அனுமனின் முத்திரையுடன் இருந்தவன், போர் புரியத் தயாராய் நிற்கின்ற திருதராஷ்டிர குமாரர்கள் அணிவகுத்திருப்பதைக் கண்டு தன்வில்லை எடுத்தான். 1.20",

    "யுத்தம் செய்யும் ஆசையுடன் அணிவகுத்து நிற்கும் கௌரவர்களின் எதிரிப்படை வீரர்களை நான் உன்னிப்பாக கவனித்து, யார் - யாருடன் இந்த போரில் ஈடுபட வேண்டும் என்று பார்க்கும் வரை ரதத்தை நிறுத்தி வைத்திருங்கள். 1.21",

    "விடுக்க முனைகின்றபோது வில்லை நிமிர்த்திக் கொண்டு ஹ்ருஷீகேசனான பகவான் ஸ்ரீகிருஷ்ணனிடம் இந்த வசனத்தைக் கூறினார்- அச்யுதா! என்னுடைய ரதத்தை இரண்டு படைகளுக்கும் நடுவில் நிறுத்துங்கள். 1.22",

    "திருதராஷ்டிரனின் தீய எண்ணம் கொண்ட மகனின் பக்கம் போரிட்டு அவனை மகிழ்விக்க வந்திருப்பவர்களை நான் காண விரும்புகிறேன். 1.23",

    "ஸஞ்ஜயன் கூறுகிறார்: உறக்கத்தை ஜெயங்கொண்ட அர்ஜுனனால் இவ்வாறு உரைக்கப்பட்ட பிறகு, ஹ்ருஷீகேசனான பகவான் ஸ்ரீ கிருஷ்ணர் இரு படைகளுக்கும் இடையே அற்புதமான தேரை கொண்டு நிறுத்தினார். 1.24",

    "பீஷ்மர், துரோணாச்சாரியார் மற்றும் அனைத்து அரசர்களின் முன்னிலையில், ஸ்ரீ கிருஷ்ணர் கூறினார்: ஓ பார்த்தா,  யுத்தத்திற்காக ஒன்றாக கூடியிருக்கும் குரு வம்சத்தினரைப் பார். 1.25",

    "அங்கு, பார்த்தா என்கிற அர்ஜுனன் அவனது தந்தையர்கள், பிதாமஹான்கள், ஆசிரியர்கள், தாய்மாமன்கள், சகோதரர்கள், உறவினர்கள், மகன்கள், மருமகன்களை கண்டான். 1.26",

    "அங்ஙனமே மாமன்மாரும், மாமனார்களும்,  நண்பர்களும், உறவினர்களும் இரண்டு படைகளிலும் நிற்கக் கண்டு கலங்கினான்,குந்தி மகனாகிய அர்ஜுனன். 1.27",

    "ஆழ்ந்த வருத்தத்துடன், பின்வரும் வார்த்தைகளை அர்ஜுனன் கூறினான்: ஓ கிருஷ்ணா, இங்கே போருக்கு அணிவகுத்து நிற்கும் என் சொந்த மக்கள் ஒருவருக்கொருவர் சண்டையிட ஆர்வமாக உள்ளனர். 1.28",

    "இதைக்கண்டு, என்அங்கங்கள் சோர்வடைகின்றன, என் வாய் வறண்டு போகிறது, உடம்பெல்லாம் நடுங்குகிறது; மெய் சிலிர்த்து நிற்கிறது. 1.29",

    "என்வில் காண்டீவம், என்கையிலிருந்து நழுவுகிறது. என்தோல் முழுவதும் எரிகிறது. என்மனம் குழப்பத்தில் சுழன்றடிக்கிறது;  இனி என்னால் , என்னை நிலையாக வைத்திருக்கவே இயலாது. 1.30",

    "ஓ கேசவா! (கேசி அரக்கனைக் கொன்றவரே), நான் பல விபரீதமான சகுனங்களை மட்டுமே காண்கிறேன். இந்தப் போரில் எனது சொந்த மக்களை கொல்வதால் எந்த நன்மை வரும் என்று என்னால் கற்பனை ௯ட செய்ய இயலவில்லை. 1.31",

    "ஓ கிருஷ்ணா, நான் வெற்றியையோ, ராஜ்யத்தையோ, அதில் சேரும் மகிழ்ச்சியையோ விரும்பவில்லை. ஓ கோவிந்தா! இத்தகைய வெற்றிக்குப்பின் ராஜ்ஜியமோ, இன்பங்களோ அல்லது வாழ்க்கையே கூட பயன் உண்டா? 1.32",

    "ஓ கிருஷ்ணா, யார் பொருட்டு நாம் ராஜ்யத்தையும் போகங்களையும், இன்பங்களையும் விரும்புகிறோமோ அவர்கள் உயிரையும் செல்வங்களையும் துறந்தவர்களாய் நம் முன் போருக்கு வந்து நிற்கிறார்களே! 1.33",

    "எனது குருமார்கள், தந்தையர்கள், மகன்கள், பிதாமஹர், தாய்மாமன்கள், பேரன்கள், மாமனார், மருமகன்கள், மைத்துனர்கள் மற்றும் பிற உறவினர்கள் தங்கள் உயிரையும் செல்வத்தையும் பணயம் வைத்து இங்கு கூடியிருக்கிறார்கள். 1.34",

    "ஓ மதுசூதனா! (மது என்ற அரக்கனைக் கொன்றவர்) அவர்கள் என்னைத் தாக்கினாலும் அவர்களைக் கொல்ல நான் விரும்பவில்லை. இந்த மூன்று உலகங்களின் ஆதிக்கத்துக்காக கூட திருதராஷ்டிரரின் மகன்களைக் கொன்றுவிட என் மனம் ஏற்கவில்லை, அப்படி இருக்கையில் இந்தப் பூமி ஒன்றின் மேல் எனக்கு என்ன ஈர்ப்பு இருக்கும்? 1.35",

    "ஓ ஜநார்த்தன! (அனைத்து உயிரினங்களையும் பராமரிப்பவரே), திருதராஷ்டிரனின் மகன்களைக் கொல்வதால் நாம் என்ன இன்பம் அடையப்போகிறோம்? அவர்கள் ஆக்கிரமிப்பாளர்களாக இருந்தாலும், அவர்களைக் கொன்றால் பாவம் நிச்சயமாக நம்மைச் சாரும். 1.36",

    "ஆதலால், நம்முடைய சொந்த உறவினர்களையும், திருதராஷ்டிரனின் குமாரர்களையும், நண்பர்களையும் கொல்வது நமக்குத் தகாது. ஓ மாதவா! (கிருஷ்ணா), நம் பந்தங்களைக் கொன்று நாம் எப்படி மகிழ்ச்சியாக இருக்க முடியும்? 1.37",

    "அவர்களின் எண்ணங்கள் பேராசையின் மிகுதியால் ஆட்கொன்டு இருக்கின்றன. மேலும் அவர்கள் தங்கள் குலத்தையழிப்பதில் அல்லது நண்பர்கள் மீது துரோகத்தை ஏற்படுத்துவதில் உள்ள பாதகத்தையும் பார்க்க தவறிவிட்டார்கள். 1.38",

    "ஆயினும், ஓ ஜனார்தனா! (கிருஷ்ணா), நம் குலநாசத்தால் ஏற்படும் குற்றத்தை தெளிவாக உணர்ந்த நாம் ஏன் இந்தப் பாவத்திலிருந்து விலகக்கூடாது? 1.39",

    "ஒரு வம்சம் அழிக்கப்படும்போது, ​​என்றும் உள்ள ​​அதன் தர்மங்கள் அழிந்துவிடும், மேலும் குடும்பத்தில் உள்ள மற்றவர்கள் நம்பிக்கையற்ற செயல்களில் ஈடுபடுகின்றனர். 1.40",

    "ஓ கிருஷ்ணா, களங்கத்தின் ஆதிக்கத்தால், குல ஸ்திரீகள் குணம் மாறிப் போகிறார்கள்; மற்றும் பெண்களின் மணம் கெடுவதால், ஓ விருஷ்ணி இனத்தின் பரம்பரையை சேர்ந்தவரே (கிருஷ்ணா), பிறகுலத்தின்  சந்ததிகள் பிறந்து வர்ணக்குழப்பம் உண்டாகிறது. 1.41",

    "வர்ணக்குழப்பத்தால் பிறகுலத்தின் குழந்தைகளின் அதிகரிப்பு குடும்பத்திற்கும், குடும்பத்தை அழிப்பவர்களுக்கும் நரக வாழ்க்கையையே விளைவிக்கிறது. ஸ்ரார்த்தம் செய்யாமல், இப்படிப்பட்ட சூழலில் குடும்பங்களின் முன்னோர்களும் வீழ்ச்சி பெறுகிறார்கள். 1.42",

    "குடும்ப பாரம்பரியத்தை அழித்து, பிறகுலத்தின் சந்ததிகளை உருவாக்குபவர்களின் தீய செயல்களால், பல்வேறு சமூக மற்றும் குடும்ப நலச் செயல்பாடுகள் அழிக்கப்படுகின்றன. 1.43",

    "ஓ ஜனார்தனா! (பொதுமக்களை கவனிக்கும் கிருஷ்ணா), குடும்ப மரபுகளை அழிப்பவர்கள் காலவரையின்றி நரகத்தில் வாழ்கிறார்கள் என்று கற்றறிந்தவர்களிடமிருந்து கேள்விப்பட்டேன். 1.44",

    "அந்தோ பாவம்!அரச போகத்தை விரும்பி உறவினர்களையே கொல்வதற்கு முனைந்துவிட்டோம். பயங்கரமான விளைவு நேரும் என்ற போதும் நாம் இந்த மாபெரும்பாவத்தை செய்வதற்கு துணிந்துவிட்டோமே! 1.45",

    "நிராயுதபாணியாகவும், போர்க்களத்தில் எதிர்க்காமல் இருக்கின்ற என்னைக் திருதராஷ்டிரனின் மகன்கள் கையில் ஏந்திய  ஆயுதங்களால் கொன்றாலும் எனக்கு நன்மையே. 1.46",

    "ஸஞ்ஜயன் கூறுகிறார்: போர்க்களத்தில் இவ்வாறு கூறிய அர்ஜுனன் தன் வில்லையும் அம்புகளையும் எறிந்துவிட்டுத்  துயரத்தில் மூழ்கி ஆழ்ந்த துக்கத்தால், தேர்ப்பீடத்தின் மேல்  அமர்ந்துவிட்டான். 1.47",

    "பிரம்ம வித்தையும் யோக சாஸ்திரமும் ஸ்ரீ கிருஷ்ணார்ஜுன சம்பாஷணையுமாகிய ஸ்ரீமத்பகவத்கீதை உபநிஷத்தில் \"அர்ஜுன விஷாத யோகம்\" என்ற முதல் அத்தியாயம் ஸம்பூர்ணம்."
  ],
  "adhyaya-2" : [
    "ஸஞ்ஜயன் கூறுகிறார்— அவ்விதம் இரக்கம் நிறைந்து நீர் நிரம்பிக் கலங்கிய கண்களுடன் சோகத்தோடுகூடிய அந்த அர்ஜுனனிடம் பகவான் மதுசூதனன் இவ்வாறு கூறினார். 2.1",

   "ஸ்ரீபகவான் கூறுகிறார்— “அர்ஜுனா! தகாத சமயத்தில் இந்த மோகம் எந்தக் காரணத்தினால் உன்னை வந்தடைந்தது? ஏனெனில், இது சான்றோர்களால் கடைப்பிடிக்கப்படாதது; சுவர்க்கத்தை அளிக்காதது; மேலும் புகழையும் தரவல்லதன்று. 2.2",

   "அர்ஜுனா! அலியின் தன்மையை அடையாதே உனக்கு இது பொருத்தமானதன்று. பரந்தபா! (எதிரிகளை வாட்டுபவனே!) இழிந்த உள்ளத்தின் தளர்ச்சியை விடுத்துப் போருக்காக எழுந்து நில், 2.3",

   "அர்ஜுனன் கூறுகிறார்- மதுசூதனா! நான் போர்க்களத்தில் எவ்வாறு அம்புகளினால் பாட்டனார் பீஷ்மரையும், ஆசார்யர் துரோணரையும் எதிர்த்துப் போரிடுவேன்? ஏனெனில், பகைவர்களை அழிப்பவனே! அவர்கள் இருவருமே பூஜிக்கத்தக்கவர்கள். 2.4",

   "மேன்மை பொருந்திய ஆசார்யர்களைக் கொல்வதைவிட, இவ்வுலகில் பிச்சையெடுப்பதால் கிடைக்கும் அன்னத்தை உண்பதுகூட மேன்மையைத் தரும். ஏனெனில், ஆசார்யர்களைக் கொன்றாலும் இவ்வுலகில் இரத்தம் கலந்த செல்வம், காம்ய வஸ்துக்கள் - இவற்றின் வடிவிலுள்ள போகங்களைத்தான் அனுபவிக்கப் போகிறேன். 2.5",

   "போரிடுவதா, வேண்டாமா என்ற இவ்விரண்டில் நமக்கு எது சிறந்தது? அல்லது நாம் அவர்களை வெற்றி கொள்வோமா? அல்லது நம்மை அவர்கள் வென்றுவிடுவார்களா? இதையும் அறியோம். எவர்களைக் கொன்று நாம் வாழக் கூட விரும்பவில்லையோ, நம்மவர்களான அந்தத் திருதராஷ்டிர குமாரர்களே நம் முன்னே நம்மை எதிர்த்து நிற்கிறார்கள். 2.6",

   "கோழைத்தனத்தினால் சீரழிந்த ஸ்வபாவம் கொண்டவனும், தர்ம விஷயத்தில் குழம்பிய மனதுடையவனுமான நான் உங்களிடம் கேட்பதென்னவென்றால், எந்தச் சாதனை உறுதியாக மேன்மை பயக்கக்கூடுமோ, அதை எனக்குக் கூறுங்கள். நான் உங்களுடைய சீடன். ஆகவே, உங்களைச் சரணடைந்த எனக்கு அறிவுரை கூறுங்கள். 2.7",

   "ஏனெனில், பூவுலகில் எதிரிகளற்ற தன தானியங்கள் நிறைந்த ராஜ்யத்தையும், தேவர்களுக்கு அதிபதியாக இருப்பதையும் அடைந்தாலும்கூட, எது என்னுடைய புலன்களை வாட வைக்கின்ற சோகத்தைப் போக்கடிக்குமோ, அதை நான் காணவில்லை. 2.8",

   " ஸஞ்ஜயன் கூறுகிறார்— அரசே! உறக்கத்தை வென்ற அர்ஜுனன் அந்தர்யாமியான ஸ்ரீகிருஷ்ண பரமாத்மாவிடம் இவ்வாறு கூறிவிட்டுப் பின் ஸ்ரீகோவிந்தனிடம் 'போர் புரிய மாட்டேன்' என்று தெளிவாகக் கூறி மௌனமாகிவிட்டார். 2.9",

   "பரதகுலத்தோன்றலே! திருதராஷ்டிரரே! அந்தர்யாமியான ஸ்ரீகிருஷ்ணன் இரண்டு படைகளுக்கும் நடுவில் துயரப்பட்டுக் கொண்டிருக்கும் அர்ஜுனனிடம் சிரித்தாற்போல இந்த வார்த்தைகளைக் கூறினார். 2.10",

   "ஸ்ரீபகவான் கூறுகிறார்- நீ துக்கப் படத்தகாத மனிதர்களின் பொருட்டு வருந்துகிறாய். மேலும், பண்டிதர்களைப் போன்று பேசுகிறாய். ஆனால், உயிர் போனவர்களுக்காகவும், உயிர் போகாதவர்களுக்காகவும், பண்டிதர்கள் வருந்துவதில்லை. 2.11",

   "இவ்விதம் நான் எப்பொழுதும் இருந்ததில்லை என்பது இல்லவே இல்லை; நீயும் இருந்ததில்லை; இந்த அரசர்களும் இருந்ததில்லை என்பதும் இல்லை; இனி மேலும், நாம் அனைவரும் இருக்கப் போவதில்லை என்பதும் இல்லை. 2.12",

   "எப்படி ஜீவாத்மாவுக்கு இந்த உடலில் இளமையும் வாலிபப்பருவமும் முதுமையும் வருகின்றனவோ, அப்படியே வேறு உடலும் வந்து சேர்கிறது. அவ்விஷயத்தில் தீர புருஷன் கலங்குவதில்லை 2.13",

   "குந்தியின் மைந்தனே! தட்பவெப்பம் மற்றும் சுகதுக்கங்களைக் கொடுக்கக்கூடிய புலன்களுடன் போக விஷயங்களின் சேர்க்கைகள் உண்டாகி அழிபவை; அநித்தியமானவை. ஆகவே, பாரதா! நீ அவற்றைப் பொறுத்துக் கொள். 2.14",

   " ஏனெனில், புருஷர்களில் சிறந்தவனே! சுக துக்கங்களைச் சமமாக ஏற்கின்ற எந்த தீரனான மனிதனை, புலன்கள் மற்றும் போக விஷயங்களின் சேர்க்கை கலங்க வைப்பதில்லையோ, அவன் மோட்சத்திற்குத் தகுதி பெறுகிறான். 2.15",

   "இல்லாததற்கு இருப்பது என்பது இல்லை. மேலும், உண்மையாக இருப்பதற்கு இல்லாமை என்பதும் இல்லை. இந்த இரண்டின் தத்துவமும் தத்துவஞானிகளால் கண்டறியப்பட்டது. 2.16",

   "அழிவற்றதுதான் என்று அதை அறிந்து கொள். எதனால் காணப்படும் பொருட்கள் அனைத்தும் வியாபிக்கப்பட்டிருக்கிறதோ, அந்த அழிவற்றதற்கு அழிவை உண்டாக்குவதற்கு எவரும் ஆற்றல் பெற்றவரல்லர். 2.17",

   "அழிவற்ற, விளக்க முடியாத, நித்தியமான ஜீவாத்மாவினுடைய இந்த அனைத்து உடல்களும் அழியக்கூடியவையாகக் கூறப்பட்டுள்ளன. ஆகவே, பரதகுலத்தோன்றலே! அர்ஜுனா! நீ போர் புரிவாயாக. 2.18",

   "எவன் இந்த ஆத்மாவைக் கொல்பவனாக நினைக்கிறானோ மற்றும் எவன் இந்த ஆத்மாவைக் கொல்லப்பட்டவனாக நினைக்கிறானோ, அந்த இருவருமே (உண்மையை) அறியாதவர்கள். ஏனெனில், இந்த ஆத்மா உண்மையில் யாரையும் கொல்வதுமில்லை; யாராலும் கொல்லப்படுவதுமில்லை. 2.19",

   "இந்த ஆத்மா ஒருபொழுதும் பிறப்பதும் இல்லை; அன்றியும் இறப்பதும் இல்லை. அவ்வாறே முன் உண்டாகிப் பிறகு ஏற்படக் கூடியது என்பதும் இல்லை. ஏனெனில், இந்த ஆத்மா பிறப்பற்றவன்; நித்தியமானவன்; எக்காலத்திலும் உள்ளவன்; பழமையானவன். உடல் கொல்லப்படும்போதும் இந்த ஆத்மா கொல்லப் படுவதில்லை. 2.20",

   "பிருதையின் (குந்தியின்) மைந்தனே, அர்ஜுனா! எந்த மனிதன் இந்த ஆத்மாவை அழிவற்றவன்; நித்தியமானவன்; பிறப்பற்றவன்; மாறுதலற்றவன் என்று அறிகிறானோ, அவன் எவ்வாறு யாரைக்கொல்லச் செய்கிறான்? எப்படி யாரைக் கொல்கிறான்? 2.21",

   "எப்படி மனிதன் நைந்துபோன பழைய துணிகளை நீத்துவிட்டு, வேறு புதிய துணிகளை எடுத்துக் கொள்கிறானோ, அவ்வாறே ஜீவாத்மா நைந்துபோன பழைய உடல்களை நீத்துவிட்டு வேறு புதிய உடல்களை அடைகிறான். 2.22",

   "இந்த ஆத்மாவை ஆயுதங்கள் வெட்டுவ தில்லை; இவனை நெருப்பு எரிப்பதில்லை; இவனைத் தண்ணீர் நனைப்பதில்லை; மேலும், காற்று உலர வைப்பதில்லை. 2.23",

   "இந்த ஆத்மா வெட்டுப்பட முடியாதவன்; எரிக்கப்பட முடியாதவன்; நனைக்கப்பட முடியாதவன்; மேலும் சந்தேகமின்றி உலர்த்தப்பட முடியாதவன்; மற்றும் இந்த ஆத்மா நித்தியமானவன்; எங்கும் நிறைந்தவன்; இயக்கமற்றவன்; ஸ்திரமாக இருப்பவன்; சாஸ்வதமானவன். 2.24",

   "இந்த ஆத்மா புலன்களுக்குப் புலப்படாதவன்; மனத்தால் சிந்திக்க முடியாதவன்; மேலும், மாறுதல் இல்லாதவன் என்று கூறப்படுகிறது. ஆகையால், அர்ஜுனா! இந்த ஆத்மாவை மேலே கூறியவாறு அறிந்து கொண்டு, நீ வருத்தப்படுவது உசிதமில்லை. 2.25",

   "ஆயினும், இந்த ஆத்மாவை எப்பொழுதும் பிறந்து கொண்டேயிருப்பவன்; அவ்வாறே எப்பொழுதும் இறந்து கொண்டேயிருப்பவன் என்று நீ கருதினாலும்கூட, நீண்ட கைகளை உடையோனே! நீ இவ்வாறு வருத்தப்படுவது நன்றன்று. 2.26",

   "ஏனெனில், (இதன்படி) பிறந்தவனுக்கு இறப்பு நிச்சயமானது; இறந்தவனுக்கும் பிறப்பு நிச்சயம். ஆகவே, தவிர்ப்பதற்கான உபாயமே இல்லாத இந்த விஷயத்தில் நீ துயரப்படுவது தக்கதன்று. 2.27",

   "அர்ஜுனா! எல்லா உயிரினங்களும் பிறப்பிற்கு முன் தென்படாதனவாக இருந்தன. மேலும், இறப்பிற்குப் பின்னும் தென்படாமலே போகக் கூடியன. நடுவில் மட்டுமே தென்படுகின்றன பிறகு, இந்நிலையில் ஏன் வருத்தப்பட வேண்டும். 2.28",

   "யாரோ ஒரு மகாபுருஷன்தான் இந்த ஆத்மாவை ஆச்சரியமாகப் பார்க்கிறான். மேலும், அவ்வாறே வேறு ஒரு மகாபுருஷன்தான் இந்தத் தத்துவத்தை ஆச்சரியமாக வர்ணிக்கிறான். மற்றும் வேறொரு தகுதி பெற்றவன்தான் ஆத்மாவை ஆச்சரியமாகக் கேட்கிறான். மேலும், யாராவது ஒருவன் அப்படிக் கேட்டாலும் ஆத்மாவை அறியவே மாட்டான். 2.29",

   "அர்ஜுனா! இந்த ஆத்மா எல்லோருடைய உடலிலும் எப்பொழுதும் கொல்லப்பட முடியாதவன். ஆகவே, எல்லா உயிரினங்களுக்காகவும் நீ வருந்துவது தகுந்ததன்று. 2.30",

   "அவ்வாறே தன்னுடைய தர்மம் என்று பார்த்தாலும் நீ பயப்படக்கூடாது. ஏனெனில்,க்ஷத்திரியனுக்கு தர்ம சம்பந்தமானப் போரைக்காட்டிலும் வேறு ஒரு மேன்மை தரும் கடமை கிடையாது. 2.31",

   "பார்த்தா! தானாகவே நேர்ந்திருக்கிறதும் திறந்து வைக்கப்பட்டுள்ளதுமான சுவர்க்கவாயில் வடிவான இத்தகைய யுத்தத்தை பாக்கியமுடைய க்ஷத்திரியர்கள்தான் அடைகிறார்கள். 2.32",

   "ஆகவே, இந்தத் தர்மத்தோடு கூடிய யுத்தத்தை நீ செய்யவில்லையானால், அப்போது ஸ்வதர்மத்தையும் புகழையும் இழந்து பாவத்தை அடைவாய். 2.33",

   "மேலும், அனைவரும் உன்னுடைய நீண்ட காலம் இருக்கக்கூடிய இகழ்ச்சியைப் பற்றிக்கூடப் பேசுவார்கள். மேலும், கௌரவம் உடையவனுக்கு இகழ்ச்சி மரணத்தைக் காட்டிலும் அதிகமான துன்பத்தைக் கொடுக்கக்கூடியது. 2.34",

   "மேலும், எவர்களுடைய பார்வையில் நீ முன்னர் மிகவும் பெருமை பெற்றவனாக இருந்து விட்டு, இப்பொழுது தாழ்வை அடைவாயோ, அந்த மகாரதிகள் உன்னை பயத்தினால் யுத்தத்திலிருந்து பின்வாங்கியவனாக நினைப்பார்கள். 2.35",

   "உன்னுடைய எதிரிகள் உன்னுடைய திறமையை நிந்தித்துப் பலவிதமான கூறத்தகாத வார்த்தைகளையும் கூறுவார்கள். அதைவிட அதிகத் துயரம் கொடுக்கக்கூடியது வேறு என்ன இருக்கும்? 2.36",

   "கொல்லப்பட்டாலோ சுவர்க்கத்தை அடைவாய்; வெற்றி பெற்றாலோ புவியை அனுபவிப்பாய். ஆகையினால், அர்ஜுனா! நீ யுத்தத்தின் பொருட்டு உறுதியாக நிச்சயித்துக் கொண்டு எழுந்திரு. 2.37",

   "வெற்றி-தோல்விகளையும், லாப-நஷ்டங்களையும், சுக-துக்கங்களையும் சமமாகக் கருதி, அதற்குப்பின் யுத்தத்திற்குத் தயாராவாயாக. இவ்விதம் யுத்தம் செய்வதால் நீ பாவத்தை அடையமாட்டாய். 2.38",

   "பார்த்தா! இந்த புத்தி உனக்கு ஞானயோக விஷயத்தில் கூறப்பட்டது. இனி, இதனைக் கர்மயோக விஷயத்தில் கேள். அந்த புத்தியோடு கூடியவனாக ஆகி கர்மபந்தத்தை முழுமையாக விலக்கி விடுவாய்; அதாவது, முற்றிலும் அழித்து விடுவாய். 2.39",

   "இந்தக் கர்மயோகத்தில் ஆரம்பம், அதாவது விதை அழிவது என்பது இல்லை; நேரெதிரான விளைவும் இல்லை. இந்தக் கர்மயோகம் என்கிற தர்மத்தைச் சிறிதளவு கைக்கொள்வதும் கூட பெரியதான பிறப்பு, இறப்பு என்ற பயத்திலிருந்து காப்பாற்றுகிறது. 2.40",

   "அர்ஜுனா! இந்தக் கர்மயோகத்தில் தீர்மானமான புத்தி ஒன்றுதான் இருக்கிறது. ஆனால், ஒரு விஷயத்திலும் நிச்சய புத்தி இல்லாத, விவேகமற்ற, பயனில் ஆசையுள்ள மனிதர்களுடைய புத்திகள் நிச்சயமாகப் பல நோக்கங்களுடையனவாகவும் முடிவற்றவையாகவும் இருக்கின்றன. 2.41",

   "அர்ஜுனா! போகங்களில் மூழ்கியவர்களும், ஸ்வர்க்கமே மேலானது என்று நினைப்பவர்களும், கர்மங்களின் பயன்களைப் புகழ்ந்துரைக்கும் வேதவாக்கியங்களிலேயே பிரியம் வைத்து வேறொன்றும் இல்லை என்று வாதம் செய்கின்றவர்களுமான அறிவிலிகள், கர்மங்களின் பயனான பிறவியை அளிக்கின்ற போகங்கள் மற்றும் செல்வங்கள் பெறுவதற்குரிய பல வகையான கர்மங்களைக் கூறுகின்ற மலர்போலக் கவர்ச்சியுள்ள எந்தப் பேச்சை எடுத்துப் பேசுகிறார்களோ, அந்தப் பேச்சினால் மனம் அபகரிக்கப்பட்டு போகத்திலும் செல்வத்திலும் மிகுந்த பற்றுள்ளவர்களுக்கு பரமாத்மாவிடம் நிச்சயமான கருத்துள்ள புத்தி நிலைபெற்றிருப்பதில்லை. 2.42-2.44",

   "அர்ஜுனா! போகங்களில் மூழ்கியவர்களும், ஸ்வர்க்கமே மேலானது என்று நினைப்பவர்களும், கர்மங்களின் பயன்களைப் புகழ்ந்துரைக்கும் வேதவாக்கியங்களிலேயே பிரியம் வைத்து வேறொன்றும் இல்லை என்று வாதம் செய்கின்றவர்களுமான அறிவிலிகள், கர்மங்களின் பயனான பிறவியை அளிக்கின்ற போகங்கள் மற்றும் செல்வங்கள் பெறுவதற்குரிய பல வகையான கர்மங்களைக் கூறுகின்ற மலர்போலக் கவர்ச்சியுள்ள எந்தப் பேச்சை எடுத்துப் பேசுகிறார்களோ, அந்தப் பேச்சினால் மனம் அபகரிக்கப்பட்டு போகத்திலும் செல்வத்திலும் மிகுந்த பற்றுள்ளவர்களுக்கு பரமாத்மாவிடம் நிச்சயமான கருத்துள்ள புத்தி நிலைபெற்றிருப்பதில்லை. 2.42-2.44",

   "அர்ஜுனா! போகங்களில் மூழ்கியவர்களும், ஸ்வர்க்கமே மேலானது என்று நினைப்பவர்களும், கர்மங்களின் பயன்களைப் புகழ்ந்துரைக்கும் வேதவாக்கியங்களிலேயே பிரியம் வைத்து வேறொன்றும் இல்லை என்று வாதம் செய்கின்றவர்களுமான அறிவிலிகள், கர்மங்களின் பயனான பிறவியை அளிக்கின்ற போகங்கள் மற்றும் செல்வங்கள் பெறுவதற்குரிய பல வகையான கர்மங்களைக் கூறுகின்ற மலர்போலக் கவர்ச்சியுள்ள எந்தப் பேச்சை எடுத்துப் பேசுகிறார்களோ, அந்தப் பேச்சினால் மனம் அபகரிக்கப்பட்டு போகத்திலும் செல்வத்திலும் மிகுந்த பற்றுள்ளவர்களுக்கு பரமாத்மாவிடம் நிச்சயமான கருத்துள்ள புத்தி நிலைபெற்றிருப்பதில்லை. 2.42-2.44",

   "அர்ஜுனா! மேலே கூறியபடி வேதங்கள் முக்குணங்களின் செயல்களான போகங்களையும், அவற்றின் சாதனை முறைகளையும் பற்றிக் கூறுபவை. ஆகவே, நீ அந்த போகங்களிலும் அவற்றின் சாதனங்களிலும் பற்று கொள்ளாதவனாக, மகிழ்ச்சி-துயரம் முதலிய இரட்டைகளுக்கு ஆட்படாதவனாக, நித்தியப் பொருளான பரமாத்மாவிடம் நிலைபெற்று யோகக்ஷேமத்தை - பொருள் சேர்ப்பது, காப்பது என்பதை விரும்பாதவனாகத் தனக்கு வசப்பட்ட மனம் உடையவனாக ஆகக்கடவாய். 2.45",

   "எல்லாப்புறங்களிலும் நிறைந்த நீர்நிலையை அடைந்த பிறகு சிறிய நீர்நிலையினால் மனிதனுக்கு எவ்வளவு பயன் இருக்கிறதோ, பிரம்மத்தைத் தத்துவரீதியாக அறிந்த பிராமணனுக்கு அனைத்து வேதங்களினாலும் அவ்வளவு பயன்தான் கிடைக்கும். (பெரிய நீர்நிலையை அடைந்தவனுக்குக் கிணறு, குளம் போன்ற சிறிய நீர்நிலைகள் தேவையற்றுப் போவதுபோல் பிரம்மானந்தத்தைப் பெற்ற பிறகு, ஆனந்தத்தைப் பெறுவதற்காக வேதங்கள் தேவைப்படுவதில்லை.) 2.46",

   "உனக்குக் கர்மங்களை ஆற்றுவதில்தான் பொறுப்பு உண்டு. அவற்றின் பயன்களில் ஒருக்காலும் உரிமையில்லை. ஆகவே, நீ கர்மங்களின் பயன்களைக் கோருவதற்குக் காரணமாக ஆகாதே. மேலும், உனக்குக் கர்ம 2.47",

   "அர்ஜுனா! பற்றினைத் துறந்து மேலும் கைகூடுவது, கைகூடாமலிருப்பது என்பதில் சமநோக்குடையவனாக இருந்து, யோகத்தில் நிலைபெற்றுச் செய்யவேண்டிய கர்மங்களைச் செய். சமபாவனைதான் கூறப்படுகிறது. யோகம் என்று கூறாப்படுகிறது. 2.48",

   "இந்தச் சமபாவனையான புத்தியோகத்தைக் காட்டிலும் பயன் கருதிச் செய்யப்படும் கர்மம் மிகவும் தாழ்ந்த ரகத்தைச் சேர்ந்தது. ஆகவே, அர்ஜுனா! சமபுத்தியில் தஞ்சம் அடைவாய். ஏனெனில், பலனுக்குத் தம்மைக் காரணமாக்கிக் கொள்கிறவர்கள் இரக்கத்துக்குரியவர்கள். 2.49",

   "சமபுத்தியுடையவன், புண்ணியம், பாவம் என்ற இரண்டையும் இவ்வுலகிலேயே விட்டு விடுகிறான். அதாவது, அவற்றிலிருந்து விடுபடுகிறான். இதனால், நீ சமத்துவ யோகத்தில் ஈடுபட்டிரு. இந்தச் சமத்துவரூப யோகம்தான் கடமையை ஆற்றுவதில் திறமையாகும் - கர்ம பந்தத்திலிருந்து விடுபடுவதற்கு உபாயமாகும். 2.50",

   "ஏனெனில், சமபுத்தியுடைய ஞானிகள் கர்மத்தினால் உண்டாகும் பயனைத் தியாகம் செய்து, பிறப்பு என்ற பந்தத்திலிருந்து விடுபட்டு மாறுதல்களற்ற பரமபதத்தை அடைகிறார்கள். 2.51",

   "எப்பொழுது உன்னுடைய புத்தி மோகம் என்ற சேற்றை முற்றிலுமாகக் கடந்துவிடுகிறதோ, அப்பொழுது நீ கேட்டது பற்றியும், கேட்கப்படுகிறவை பற்றியும், இவ்வுலக -பரலோகங்களில் ஏற்படும் அனைத்துப் போகங்களிலிருந்தும்  வைராக்கியத்தை அடைவாய். 2.52",

   "பற்பல வசனங்களைக் கேட்டுச் சஞ்சலமாகியுள்ள உன்னுடைய புத்தி, எப்பொழுதும் பரமாத்மாவிடம் அசையாமல் மேலும் திடமாக நிலைபெறுகிறதோ, அப்பொழுது யோகத்தை அடைவாய். நீ பரமாத்மாவிடம் நிரந்தரமாக ஒன்றிவிடுவாய். 2.53",

   "அர்ஜுனன் கேட்கிறார்— கேசவா! சமாதியில் நிலைபெற்றுப் பரமாத்மாவை அடைந்த ஸ்திர புத்தி உள்ளவனின் இலக்கணம் என்ன? இந்த ஸ்திரபுத்தியுள்ள புருஷன் எப்படிப் பேசுவான்? எப்படி உட்காருவான்? மேலும் எப்படி நடப்பான்? 2.54",

   "ஸ்ரீபகவான் கூறுகிறார்— “அர்ஜுனா! எப்பொழுது இந்த மனிதன் மனத்திலுள்ள எல்லா ஆசைகளையும் அறவே துறக்கிறானோ, மேலும் ஆத்மாவினால் ஆத்மாவிடமே ஆனந்தமாக இருக்கிறானோ, அப்பொழுது அவன் ஸ்திதப்ரக்ஞன் என்று கூறப்படுகிறான். 2.55",

   "துன்பங்கள் நேர்கையில் எவனுடைய மனம் கலங்குவது இல்லையோ, சுகங்களை அடையும் போது அவற்றில் சிறிது கூட ஆசை கொள்ளவதில்லையோ, எவனுக்கு விருப்பு, பயம், கோபம் ஆகிய இவை முற்றும் அற்றுப்போயினவோ, அத்தகைய முனிவன் ஸ்திதப்ரக்ஞன் என்று கூறப்படுகிறான். 2.56",

   "எவன் ஒருவன் அனைத்திலும் பாசம் வைக்காமல் அந்தந்த நல்ல அல்லது கெட்ட பொருளை அடைந்து மகிழ்வதுமில்லையோ, வெறுப்பதுமில்லையோ, அவனுடைய புத்தி ஸ்திரமானது. 2.57",

   "ஆமை, எல்லாப் பக்கங்களிலிருந்தும் தனது உறுப்புக்களை உள்ளிழுத்துக் கொள்வதுபோல, எப்போது இந்த மனிதன் புலன்நுகர்ப் பொருட்களிலிருந்து புலன்களை முற்றிலும் இழுத்துக் கொள்கிறானோ, அப்போது அவனுடைய புத்தி ஸ்திரமானது என்று புரிந்துகொள்ள வேண்டும். 2.58",

   "புலன்களின் மூலம் போகப் பொருட்களை ஏற்றுக் கொள்ளாத மனிதனுடைய புலன்நுகர் பொருட்கள் மட்டுமே விலகுகின்றன. ஆனால், அவற்றில் தங்கியுள்ள பற்று விலகுவதில்லை. இந்த ஸ்திதப்ரக்ஞனுக்கோ பரமாத்மாவைத் தரிசித்தப் பிறகு, அப்பற்றும் விலகிவிடுகிறது. 2.59",

   "அர்ஜுனா! பற்று அழியாததால் கலக்குபவையான புலன்கள் முயற்சியுள்ள புத்திசாலியான மனிதனின் மனத்தைக் கூட பலவந்தமாக இழுத்துச் சென்று விடுகின்றன. 2.60",

   "அந்த அனைத்துப் புலன்களையும் வசப்படுத்தி ஒருமுகப்படுத்திய சித்தமுடையவன் என்னையே பரமகதியாகக் கொண்டு தியானத்தில் அமர வேண்டும். ஏனெனில், எவனுடைய புலன்கள் வசப்பட்டு இருக்கின்றனவோ, அவனுடை புத்தி ஸ்திரமாகிறது. 2.61",

   " புலன்நுகர்ப் பொருட்களைச் சிந்தித்துச் கொண்டிருக்கின்ற மனிதனுக்கு, அவ்விஷயங்களில் பற்று ஏற்படுகிறது; பற்றுதலிலிருந்து ஆசை உண்டாகிறது; ஆசையிலிருந்து (ஆசைக்கு இடையூறு வருவதனால்) கோபம் உண்டாகிறது. 2.62",

   "கோபத்தினால் மிகுந்த அறிவின்மை ஏற்படும்; அறிவின்மையால் நினைவு தடுமாற்றம் உண்டாகிறது; நினைவு தடுமாறுவதனால் புத்தி அதாவது விவேகத்தின் அழிவு உண்டாகிறது; விவேகம் அழிவதனால் மனிதன் தன் நிலையிலிருந்து வீழ்ச்சியடைகிறான். 2.63",

   "ஆனால், தன்வசப்பட்ட மனத்துடைய சாதகன் விருப்பு-வெறுப்புகளின்றித் தன்னால் வசப்படுத்தப்பட்ட புலன்கள் மூலம் போக விஷயங்களில் சஞ்சரித்துக் கொண்டு உள்ளத் தெளிவை அடைகிறான். 2.64",

   "உள்ளத்தெளிவு ஏற்பட்டுவிட்டால், இவனுடைய எல்லா துக்கங்களுக்கும் அழிவு ஏற்பட்டுவிடும். தெளிவடைந்த மனமுள்ள கர்மயோகியின் புத்தி விரைவிலேயே எல்லா விஷயங்களிலிருந்தும் விலகிப் பரமாத்மா ஒன்றிலேயே நன்கு ஸ்திரமாகிவிடுகிறது. 2.65",

   "மனத்தையும் புலன்களையும் வெற்றி கொள்ளாதவனுக்கு நிச்சயமான புத்தி இருக்காது. உறுதியான புத்தியற்றவனுக்கு மனத்தில் பாவனையும் தோன்றாது. அவ்வாறே, பாவனையற்றவனுக்கு அமைதியும் கிடைக்காது. அமைதியற்றவனுக்குச் சுகம் ஏது? 2.66",

   "ஏனெனில், நீரில் போய்க் கொண்டிருக்கும் ஓடத்தைக் காற்று அடித்துக் கொண்டு போவது போல, போகங்களில் சஞ்சரிக்கின்ற புலன்களில் எந்த ஒரு புலனுடன் மனம் ஒட்டியிருக்கிறதோ. அந்த ஒரே புலன் மனம் வசப்படாத இந்த மனிதனின் புத்தியைக் கவர்ந்து செல்கிறது. 2.67",

   "ஆகையால், நீண்ட புஜங்கள் உடையவனே! (அர்ஜுனா!) எந்த மனிதனுடைய புலன்கள் புலன்களுக்குரிய போகப் பொருட்களிலிருந்து எல்லா விதங்களிலும் மீட்கப் பட்டுவிட்டனவோ, அவனுடைய புத்தி ஸ்திரமானது. 2.68",

   "அனைத்து உயிரினங்களுக்கும் எது இரவு போல இருக்கிறதோ, அந்த நித்தியமான, ஞான வடிவான பரமானந்தத்தை அடைவதில் ஸ்திதப்ரக்ஞனான யோகி விழித்திருக்கிறான். மேலும், எதில் அழியும் தன்மையுடைய உலகியல் இன்பங்களை அடைவதில் அனைத்து உயிரினங்களும் விழித் திருக்கின்றனவோ, பரமாத்மாவின் தத்துவத்தை உணர்கின்ற முனிவருக்கு, அது இரவுபோல ஆகின்றது. 2.69",

   "எவ்விதம் எல்லாப் பக்கங்களிலிருந்தும் நீர் நிறைந்தும் அசைவில்லாமல் இருக்கும் கடலில் பல ஆறுகள் அதைக் கலங்கச் செய்யாமலேயே கலந்து விடுகின்றனவோ, அதே போல ஸ்திதப்ரக்ஞனான மனிதனை அனைத்து போகங்களும் எவ்வித மாறுதலும் உண்டுபண்ணாமலேயே வந்து அடைகின்றன. அவனே மேலான அமைதியை அடைகிறான். போகங்களை அடைவதில்லை. விரும்புபவன் அமைதியை அடைவதில்லை. 2.70",

   "எந்த மனிதன் அனைத்து விருப்பங்களையும் துறந்துவிட்டு மமதை இல்லாதவனாக, அகங்கார மற்றவனாக, சிறிதளவு ஆசையும் இல்லாதவனாக சஞ்சரிக்கிறானோ, அவனே அமைதியை அடைகிறான். அதாவது, அவனே சாந்தியைப் பெறுகிறான். 2.71",

   "அர்ஜுனா! இதுதான் பிரம்மத்தை அடைந்தவனின் நிலை. இதை அடைந்தபின் யோகி ஒருபோதும் மோகமடைவதில்லை. மேலும், உயிர் பிரியும் நேரத்திலும் இந்தப் பிரம்மநிலையிலேயே நிலைபெற்றுப் பிரம்மானந்தத்தை அடைகிறான். 2.72",

   "பிரம்ம வித்தையும் யோக சாஸ்திரமும் ஸ்ரீ கிருஷ்ணார்ஜுன சம்பாஷணையுமாகிய ஸ்ரீமத்பகவத்கீதை உபநிஷத்தில் \"ஸாங்கிய யோகம்\" என்ற இரண்டாம் அத்தியாயம் ஸம்பூர்ணம்."
  ],
  "adhyaya-3" : [
    "அர்ஜுனன் கூறுகிறார்: ஓ ஜனார்தனா ( பொதுமக்களைக் காப்பவர்),  செயலைவிட அறிவையே உன்னதமாக நீ கருதினால், இந்தப் பயங்கரமான போரை ஏன் நடத்தச் சொல்கிறாய் கேசவா (கேசி என்ற அரக்கனைக் கொன்றவன்)? 3.1",

    "தங்கள் பல பொருள் அடிப்படையின் பேச்சு என் புத்தியை சற்றுக் குழப்பிவிட்டது. தயவு செய்து எனக்கு உயர்ந்த நன்மையைத் தரக்கூடிய ஒரு பாதையை உறுதியாகக் கூறுங்கள். 3.2",

    "பகவான் கூறுகிறார்:ஓர் பழி - பாவமற்ற அர்ஜுனா, இவ்வுலகத்தில் ஞானத்தை நோக்கி செல்லும் இரண்டு பாதைகள் முன்பு என்னால் கூறப்பட்டது: ஆழ்ந்தச்சிந்தனை சார்ந்தவர்களுக்கு ஞானயோகமென்ற அறிவின்பாதை மற்றும் செயலினை சார்ந்தவர்களுக்கு  கர்மயோகமென்ற செய்கைபாதை. 3.3",

    "வெறும் வேலையை தவிர்த்து இருப்பதன் மூலம் கர்ம வினைகளில் இருந்து விடுதலை பெற முடியாது, அல்லது வெறும்  உடல் துறவினாலேயே அறிவின் முழுமையை அடைய முடியாது. 3.4",

    "யாராலும் ஒரு கண நேரம் கூட செயலின்றி இருக்க இயலாது. உண்மையில், அனைத்து உயிரினங்களும் இந்த ஸ்தூலமான இயற்கையில் (மூன்று குணங்கள்) பிறந்த தங்கள் குணங்களால் செயல்பட வேண்டிய கட்டாயத்தில் உள்ளன. 3.5",

    "செயல்களின் வெளிப்புற புலன்களைக் கட்டுப்படுத்துபவர்கள், மனதில் தொடர்ந்து புலன்களின் மீது  சிந்தனையை வைத்திருந்தால், நிச்சயமாக தங்களைத் தாங்களே ஏமாற்றிக் கொள்கிறார்கள் மற்றும் கபடவாதிகள் என்று அழைக்கப்படுவார்கள். 3.6",

    "ஆனால் அர்ஜுனா, தங்கள் அறிவு புலன்களை மனத்தால் கட்டுப்படுத்தி, செயலைச்செய்யும் புலன்களைப் பற்றற்ற செயலில் ஈடுபடுத்தும் அந்த கர்ம யோகிகளே நிச்சயமாக உயர்ந்தவர்கள் எனக் கருதப்படுவார்கள். 3.7",

    "செயலற்றதை விட செயல் மேலானது என்பதால், நீங்கள் உங்களுக்கு விதிக்கப்பட்ட வேதக்கடமைகளைச் செய்ய வேண்டும். செயல்பாட்டை நிறுத்துவதன் மூலம், உங்கள் உடலைப் பராமரிப்பது கூட சாத்தியமில்லாமல் போய்விடும். 3.8",

    "ஓ கௌந்தேயா! (குந்தியின் மகன்), பரமாத்மாவுக்கு ஒரு யாகமாக வேலை செய்ய வேண்டும்; இல்லையெனில், இந்த ஜட உலகில் வேலையினால் பிணைப்பு மனிதருக்கு உருவாகிறது. எனவே, குந்தியின் மகனே, கடவுளின் திருப்திக்காக, முடிவுகளுடன் பிணைக்கப்படாமல் உங்களுக்கு விதிக்கப்பட்ட கடமைகளைச் செய்யுங்கள். 3.9",

    "படைப்பின் தொடக்கத்தில், பிரம்மா மனிதகுலத்தை கடமைகளுடன் உருவாக்கினார், மேலும், இந்த யாகங்கள் செய்வதில் வெற்றிபெறுங்கள், ஏனென்றால் நீங்கள் அடைய விரும்பும் அனைத்தையும் அவை உங்களுக்கு வழங்கும். 3.10",

    "உங்கள் யாகங்களால், விண்ணுலக தெய்வங்கள் மகிழ்ச்சியடைவார்கள், மேலும் மனிதர்களுக்கும் விண்ணுலக தெய்வங்களுக்கும் இடையிலான பரஸ்பரமான ஒத்துழைப்பால், அனைவருக்கும் பெரும் செழிப்பான ஆட்சி கிடைக்கும். 3.11",

    "யாகங்களால் விண்ணுலக தெய்வங்கள் திருப்தியடைந்து மகிழ்ச்சியுடன் அவர்கள் உங்களுக்கு விரும்பிய போகங்களை எல்லாம் தருவார்கள். அவர்களுக்குப் பிரதிபலனாக காணிக்கை செலுத்தாமல் அவர்கள் கொடுப்பதை பெறுபவன் கள்வனே ஆவான். 3.12",

    "ஆன்மிக எண்ணம் கொண்ட சான்றோர்கள் யாகத்தில் முதலில் அளிக்கப்படும் உணவை உண்று, எல்லாவிதமான பாவங்களிலிருந்தும் விடுவிக்கப்படுகிறார்கள். மற்றவர்கள், தங்கள் நலனுக்காக உணவை சமைக்கிறார்கள், உண்மையில் பாவத்தை மட்டுமே உண்ணுகிறார்கள். 3.13",

    "அன்னத்தால் உயிரினங்கள் அனைத்தும் ஜீவிக்கின்றன. மழையால் உணவு உற்பத்தி உண்டாகிறது. மழை யாகங்களின் செயல்பாட்டிலிருந்து வருகிறது. மேலும் யாகங்கள் விதிக்கப்பட்டக் கர்மங்களை நிறைவேற்றுவதன் மூலம் உற்பத்தி செய்யப்படுகிறது. 3.14",

    "மனிதர்களுக்கான கர்மங்கள் வேதங்களில் விவரிக்கப்பட்டுள்ளன. மேலும் வேதம் அழிவற்ற பரமாத்மாவிடமிருந்து நேரடியாக வெளிப்படுத்தப்பட்டது. எனவே, எங்கும் நிறைந்த பரமாத்மா எப்போதும் யாகங்களில் நிரந்தரமாக நிலைபெற்றிருக்கிறார். 3.15",

    "ஓ பார்தா (ப்ரிதாவின் மகனான அர்ஜுனா) வேதங்களால் நிறுவப்பட்ட யாகத்தின் சுழற்சியில் தங்கள் பொறுப்பை ஏற்காதவர்கள் பாவம் செய்தவர்கள். அவர்கள் தங்கள் புலன்களின் மகிழ்ச்சிக்காக மட்டுமே வாழ்கிறார்கள்; உண்மையில் அவர்களின் வாழ்க்கை வீணானது. 3.16",

    "ஆனால் யார் தன்னுள் ஆத்ம மகிழ்ச்சி அடைகிறார்களோ, யார்  தன்னுள் ஆத்மாவால் பிரகாசித்து முழுத்திருப்தி அடைகிறார்களோ, அவர்களுக்கு எந்தக் கடமையும் இல்லை. 3.17",

    "அத்தகைய சுய ஆத்ம - உணர்ந்த ஆன்மாக்கள் தங்கள் கடமைகளை நிறைவேற்றுவதிலோ அல்லது துறப்பதிலோ எதையும் பெறவோ அல்லது இழப்பதோ இல்லை. மேலும் அவர்கள் தங்கள் ஆத்ம திருப்தியை நிறைவேற்ற மற்ற உயிரினங்களைச் சார்ந்திருக்க வேண்டிய அவசியமும் இல்லை. 3.18",

    "எனவே, எப்போதும் பற்றுதலைக் கைவிட்டு, செயல்களைக் கடமையாகச் செய்யுங்கள். ஏனெனில் பலன்களில் பற்று கொள்ளாமல் உழைத்தால், ஒருவன் உன்னதமான பரம்பொருளை அடைகிறான். 3.19",

    "தங்களுக்கு விதிக்கப்பட்டக் கடமைகளைச் செய்ததன் மூலம், மன்னர் ஜனகர் மற்றும் பிறர் முழுமையை அடைந்தனர். உலக நன்மைக்கு முன்னுதாரணமாக உங்கள் கடமைகளையும் செய்ய வேண்டும். 3.20",

    "பெரிய மனிதர்கள் எந்தச் செயலைச் செய்தாலும், அனைத்து மக்களும் பின்பற்றுகிறார்கள். அவர்கள் எந்த அளவுகோல்களை நிர்ணயித்தாலும், உலகம் முழுவதும் அதனைப் பின்பற்றுகின்றது. 3.21",

    "ஓ பார்தா (ப்ரிதாவின் மகனான அர்ஜுனன்), மூன்று உலகங்களிலும் நான் செய்ய வேண்டிய கடமை எதுவுமில்லை, பெறுவதற்கும் அடைவதற்கும் எனக்கு எதுவும் இல்லை. இருப்பினும், நான் பரிந்துரைக்கப்பட்டக் கடமைகளில் ஈடுபட்டுள்ளேன். 3.22",

    "ஏனென்றால், நான் விதிக்கப்பட்டக் கடமைகளைக் கவனமாகச் செய்யவில்லை என்றால், ஓ பார்தா, எல்லா மனிதர்களும் எல்லா வகையிலும் என் வழியைப் பின்பற்றுவார்கள். 3.23",

    "நான் விதிக்கப்பட்டச் செயல்களைச் செய்வதை நிறுத்தினால், இந்த உலகங்கள் அனைத்தும் அழிந்துவிடும். நிலவும் கலவரத்திற்கு நான் பொறுப்பாவேன், அதன் மூலம் மனித இனத்தின் அமைதியே அழிந்துவிடும். 3.24",

    "அறியாமையால் மக்கள் தங்கள் கடமைகளை முடிவுகளின் மீது பற்றுக்கொண்டு செய்வது போல, ஓ பாரதா! (பாரதத்தின்  மைந்தனே அர்ஜுனா), ஞானிகள் மக்களை நேர்வழியில் அழைத்துச் செல்வதற்காக பற்றற்றச் செயலைச் செய்ய வேண்டும். 3.25",

    "அஞ்ஞானிகள் பலன் தரும் செயல்களில் பற்றுக் கொண்டவர்கள். இதை அறிந்த விவேகமுள்ளவர்கள் அவர்களின் புத்தியில் முரண்பாட்டை விளைவிக்கக் கூடாது. மாறாக, அறிவொளியுடன்  தங்கள் கடமைகளைச் செய்வதன் மூலம், அவர்கள் அறியாதவர்களையும் பரிந்துரைக்கப்பட்டக் கடமைகளைச் செய்ய ஊக்குவிக்க வேண்டும். 3.26",

    "அனைத்துச் செயல்களும் ஜட இயற்கையின் மூன்று முறைகளால் மேற்கொள்ளப்படுகின்றன. ஆனால் அறியாமையில், உடலுடன் தவறான அடையாளத்தால் ஏமாற்றப்பட்ட ஆன்மா, தன்னைச் செய்பவராக நினைக்கிறது. 3.27",

    "ஓ வலிமையான கரங்கள் கொண்ட அர்ஜுனா, ஒளிமயமான மனிதர்கள் ஆன்மாவை குணங்கள் மற்றும் கர்மாக்களில் இருந்து வேறுபடுத்திக் காட்டுகிறார்கள். குணங்கள் (உணர்வுகள், மனம் மற்றும் பிறவற்றின் வடிவத்தில்) மட்டுமே குணங்களுக்கிடையில் (உணர்தல் பொருள்களின் வடிவத்தில்) நகரும் என்பதை அவர்கள் உணர்கிறார்கள், இதனால் அவர்கள் அவற்றில் சிக்கிக் கொள்ள மாட்டார்கள். 3.28",

    "குணங்களின் செயல்பாட்டால் ஏமாற்றப்படுபவர்கள் தங்கள் செயல்களின் விளைவுகளுடன் இணைந்திருக்கிறார்கள். ஆனால், இந்த உண்மைகளைப் புரிந்துகொள்ளும் அறிவாளிகள், மிகக் குறைந்த அறிவுள்ள இத்தகைய அறிவிலிகளை நிலைகுலையச் செய்யக்கூடாது. 3.29",

    "எல்லாக் காரியங்களையும் எனக்குப் பிரசாதமாகச் செய்து, என்னையே பரமாத்மாவாகத் தியானியுங்கள். ஆசை மற்றும் சுயநலத்திலிருந்து விடுபடுங்கள், உங்கள் மன வருத்தத்துடன்  போராடுங்கள்! 3.30",

    "என்னுடைய இந்த போதனைகளை ஆழ்ந்த நம்பிக்கையுடனும், பொறாமையின்றியும் கடைப்பிடிப்பவர்கள் கர்ம பந்தத்திலிருந்து விடுபடுகிறார்கள். 3.31",

    "ஆனால், எனது போதனைகளில் தவறுகளைக் கண்டறிபவர்கள், அறிவு இல்லாதவர்களாகவும், பாகுபாடுகள் அற்றவர்களாகவும் இருப்பதால், அவர்கள் இந்தக் கொள்கைகளைப் புறக்கணித்து, தங்களுக்கு அழிவையே ஏற்படுத்திக் கொள்கிறார்கள். 3.32",

    "புத்திசாலிகள் கூட தங்கள் இயல்புகளுக்கேற்ப செயல்படுகிறார்கள். ஏனென்றால் எல்லா உயிரினங்களும் அவற்றின் இயல்பான போக்குகளால் செயல்படுகின்றன. அடக்குமுறையால் ஒருவருக்கு என்ன லாபம்? 3.33",

    "ஒவ்வொரு மனிதனுக்கும் இயல்பாக இருக்கும் புலன்களின் மீதான விருப்பு - வெறுப்புகளில்  அகப்படக் கூடாது. அவ்விரண்டும் மேன்மை பாதையின் எதிரிகள். 3.34",

    "பிறர் வழிக் கடமையை சிறப்பாகச் செய்ய நேர்ந்தாலும், தனக்கு விதிக்கப்பட்டக் கடமையை செய்வதில் சிரமங்கள் நிறைந்திருந்தாலும், அவரவர் கடமையைச் செய்வது மிகவும் சிறந்தது. உண்மையில், ஆபத்து நிறைந்த மற்றொருவரின் பாதையில் செல்வதை விட, ஒருவரின் கடமையை நிறைவேற்றுவதில் இறப்பது விரும்பத்தக்கது. 3.35",

    "அர்ஜுனன் கேட்டான்: ஓ விருஷ்ணியின் (கிருஷ்ணனின்) வம்சாவளியினரே, ஒருவன் ஏன் பாவச் செயல்களைச் செய்யத் தூண்டப்படுகிறான்? 3.36",

    "பகவான் கூறினார்: மோகத்தின் (ரஜோ குணம்) தொடர்புக் கொண்டுக் காமம் பிறக்கிறது. பின்னர் அதுவே கோபமாக மாறுகிறது. இந்த உலகில் ஆசையைத் தூண்டும் பாவமுள்ளக் காமத்தை, அனைத்தையும் விழுங்கும் எதிரியாக அறிந்து கொள்ளுங்கள். 3.37",

    "நெருப்பை புகையால் மூடுவது போலவும், கண்ணாடியை தூசியால் மூடிவிடுவது போலவும், கருவை கருவறையால் மறைப்பது போலவும், ஒருவரின் அறிவு ஆசையால் மறைக்கப்படுகிறது. 3.38",

    "மிகவும் விவேகமுள்ளவர்களின் அறிவு கூட தீராத ஆசை என்ற இந்த நிரந்தர எதிரியின் வடிவத்தில் மூடப்பட்டிருக்கும். ஓ குந்தியின் மகனே, இந்த ஆசை ஒருபோதும் திருப்தியடையாது மற்றும் நெருப்பைப் போல எரிகிறது. 3.39",

    "புலன்கள், மனம் மற்றும் புத்தி ஆகியவை ஆசையின் இனப்பெருக்கம் என்று கூறப்படுகிறது. அவற்றின்         மூலம், ஒருவரின் அறிவு மழுங்கடிக்கப்பட்டு, உருவான ஆன்மாவை ஏமாற்றுகின்றன. 3.40",

    "ஆகவே, ஓ பாரதர்களில் சிறந்தவனேஆரம்பத்திலேயே புலன்களைக் கட்டுக்குள் கொண்டு வந்து, பாவத்தின் உருவமாகிய, அறிவையும் உணர்வையும் அழிக்கும் ஆசை என்ற இந்த எதிரியைக் கொன்றுவிடு. 3.41",

    "புலன்கள் ஸ்தூல உடலை விட மேலானது. புலன்களை விட மனம் உயர்ந்தது. மனதிற்கு அப்பாற்பட்டது புத்தி, புத்திக்கு அப்பாற்பட்டது ஆன்மா. 3.42",

    "இவ்வாறாக ஆன்மாவை ஜடப் புத்தியை விட உயர்ந்தது என்று அறிந்து, ஓ வலிமையான கரங்கள் கொண்ட அர்ஜுனா, தாழ்ந்த சுயத்தை (உணர்வுகள், மனம் மற்றும் புத்தியை) உயர்ந்த சுயத்தால் (ஆன்மாவின் வலிமை) அடக்கி, காமம் என்ற இந்த வலிமையான எதிரியைக் கொன்றுவிடு. 3.43",

    "பிரம்ம வித்தையும் யோக சாஸ்திரமும் ஸ்ரீ கிருஷ்ணார்ஜுன சம்பாஷணையுமாகிய ஸ்ரீமத்பகவத்கீதை உபநிஷத்தில் \"கர்ம யோகம்\" என்ற மூன்றாவது அத்தியாயம் ஸம்பூர்ணம்."
  ],
  "adhyaya-4" : [
    "பரம பரமாத்மா ஶ்ரீ பகவான் கூறினார்: நான் இந்த நித்திய அழிவற்ற விஞ்ஞான யோகத்தை சூரியக் கடவுளான விவஸ்வானுக்குப் போதித்தேன். அவர் அதை மனுவுக்குப் (மனிதகுலத்தின் மூலமுதல் முன்னோர்)போதித்தார்; மேலும் மனு, அதை இக்ஷ்வாகுவுக்கு (சூரிய வம்சத்தின் முதல் அரசர்) போதித்தார். 4.1",

    "ஓ பரந்தபா! (எதிரிகளை அடிபணியச் செய்பவரான அர்ஜுனா), இவ்வாறு பரம்பரையாகக் கிடைத்த இந்தவிஞ்ஞானயோகத்தை ராஜரிஷிகள் உணர்ந்திருந்தனர். அந்த யோகம் கால மிகுதியால் இவ்வுலகத்தில் இழக்கப்பட்டது. 4.2",

    "அந்தப் பண்டைய யோகத்தையே இன்று நான் உனக்கு கூறுகிறேன். நீ என் பக்தனும் தோழனும் ஆவாய்.  இந்த ஆழ்நிலை ஞானத்தைப் புரிந்துக் கொள்ள உன்னால் முடியும். 4.3",

    "அர்ஜுனன் கேட்கிறார்: நீங்கள் விவஸ்வானுக்கு நீண்ட நாட்களுக்குப் பிறகு பிறந்தவர் என்பதால் தொடக்கத்தில் நீங்கள் அவருக்கு இந்த அறிவியலைப் போதித்தீர்கள் என்பதை நான் எப்படிப் புரிந்துக்கொள்வது? 4.4",

    "ஶ்ரீ பகவான் கூறினார்: அர்ஜுனா, நீயும் நானும் பலப் பிறவிகள் பெற்றிருக்கிறோம். ஓ பரந்தபா, நான் அவற்றையெல்லாம் என்நினைவில் அறிவேன். ஆனால் நீ அவற்றை அறிவதில்லை. 4.5",

    "நான் என்றும் பிறப்பற்றவன் எனினும், என்றும் அழிவற்றவன் எனினும் , உயிர்களுக்குக்கெல்லாம்  ஈசனே எனினும், நான் எனது   தெய்வீக சக்தியில் நிலைபெற்று ஆத்ம மாயையால் ,இந்த உலகில் மீண்டும் மீண்டும் தோன்றுகிறேன். 4.6",

    "ஓ பாரதா! (பாரதத்தின் வம்சமான அர்ஜுனா)எப்பொழுதெல்லாம் தர்மம் அழிந்துபோய் அதர்மம் எழுச்சிப் பெருகுகிறதோ, அப்பொழுதெல்லாம் நான் பூமியில் அவதாரமெடுக்கிறேன். 4.7",

    "நேர்மையான  நல்லோரைக் காக்கவும், தீயன செய்வோரை  அழிக்கவும், தர்மத்தை மீண்டும் நிலைநாட்டவும் நான் இந்த பூமியில் யுகந்தோறும் பிறக்கிறேன். 4.8",

    "அர்ஜுனா, எனது தெய்வத்தன்மைக் கொண்டப் பிறப்பும், செயல்களின் தன்மையையும் புரிந்துகொள்பவர்கள், உடலை விட்டு வெளியேறியப் பிறகு மீண்டும் பிறக்க அவசியமில்லை. ஏனெனில் அவர்கள் எனது நித்திய இருப்பிடத்திற்கு வந்து சேர்வார்கள். 4.9",

    "பற்று, பயம், கோபம் ஆகியவற்றிலிருந்து விடுபட்டவராய், என்னில் முழுமையாக மூழ்கி, என்னிடமே அடைக்கலமாகி, கடந்த காலத்தில் என்னைப் பற்றிய அறிவால் தூய்மையடைந்து, என் தெய்வீக அன்பை அடைந்தவர்கள் பலர். 4.10",

    "எவ்வகையில் மக்கள் என்னிடம் சரணடைகிறார்களோ, அவ்வகையில் நான் பிரதிபலன் கொடுக்கிறேன். ஓ பார்தா( ப்ரிதாவின் மகனே), அனைவரும் அறிந்தோ அறியாமலோ என் வழியைப் பின்பற்றுகிறார்கள். 4.11",

    "இவ்வுலகில், பௌதிகச் செயல்களில் வெற்றிப் பெற விரும்புவோர் விண்ணுலகத் தெய்வங்களை வணங்குகின்றனர். ஏனெனில்  மனித உலகத்தில் பொருள் வெகுமதிகள் விரைவாக அவர்களிடம் இருந்து வெளிப்படும். 4.12",

    "மனிதர்களின் குணத்துக்கும் செயல்பாடுகளுக்கும் தக்கபடி நான் நான்கு வர்ணங்களை அமைத்தேன். நான் இந்த அமைப்பை உருவாக்கியவன் என்றாலும், அந்தச் செயல்களை நான் செய்பவன் இல்லை. செயல்களிலிருந்து விலகியே இருக்கிறேன். மேலும் நான் அழிவற்றவன். 4.13",

    "செயல்பாடுகள் எனக்கு மோகம் ஏற்ப்படுத்துவதில்லை, செயலின் பலனையும் நான் விரும்புவதில்லை. இந்த வழியில் என்னை அறிந்தவன் செயலின் கர்ம வினைகளுக்கு ஒருபோதும் கட்டுப்படுவதில்லை. 4.14",

    "முற்காலத்தில் இந்த காலச்சக்கரத்தின் வலையிலுருந்து முக்தியைபெற வேண்டி இந்த உண்மையை அறிந்த முன்னோர்கள், இந்தச் செய்கையைச் செய்தார்கள். எனவே, அந்த முற்கால முனிவர்களின் அடிச்சுவடுகளைப் பின்பற்றி, நீங்களும் உங்கள் கடமையைச் செய்யுங்கள். 4.15",

    "எது செயல்? எது செயலற்றது?இந்த விஷயத்தைத் தீர்மானிப்பதில் ஞானிகளும் குழப்பமடைகிறார்கள். ஆதலால் உனக்குச் செயலின் ரகசியத்தை உணர்த்துகிறேன்.   இதை அறிவதனால் பூலோக வாழ்வின் பற்றுகளிலிருந்து  விடுபடலாம். 4.16",

    "பரிந்துரைக்கப்பட்டச் செயல், தவறான செயல் மற்றும் செயலற்ற தன்மை ஆகிய மூன்றின் தன்மையையும் நீங்கள் புரிந்துக் கொள்ள வேண்டும். செயல்களின் இந்த ரூபங்கள் பற்றிய உண்மை ஆழமானது மற்றும் புரிந்துகொள்வதும் கடினம். 4.17",

    "செய்கையில் செயலின்மையையும், செயலின்மையில் செய்கையையும் யார் காண்கிறார்களோ, அவரே மனிதர்களில் அறிவுடையவர்கள். அவர்களே யோகிகள் மற்றும் எல்லா  செயல்களிலும் வல்லவர்கள். 4.18",

    "ஞானம் பெற்ற முனிவர்கள், யாருடைய ஒவ்வொரு செயலும் ஜட இன்ப ஆசையிலிருந்து விடுபட்டு, உழைப்பின் வினைகளைத் தெய்வீக அறிவின் நெருப்பில் எரித்திரிக்கிறார்களோ அவர்களைப் பண்டிதன் என்று அழைக்கிறார்கள். 4.19",

    "அத்தகைய மனிதர்கள், தங்கள் செயல்களின் பலன்களின் மீது பற்றுதலைக் கைவிட்டவராய், எப்போதும் திருப்தியுடையோராய் எதிலும் சார்பற்று நிற்பவராய்  இருப்பர். அவர்கள் செயல்கள் செய்துக் கொண்டிருக்கையிலும் செயல் அற்றவர்களாக ஆவார்கள். 4.20",

    "ஆசையற்றவர்கள், மனம், புத்தியை முழுமையாகக் கட்டுப்படுத்தி இவ்வுலகப் பொருட்கள் அனைத்தையும் துறந்தவர்கள், தங்கள் உடலால் செயல்களைச் செய்தாலும் அவர்களுக்கு எந்தப் பாவமும் ஏற்படாது. 4.21",

    "தானாக வந்துசேரும் லாபத்தில் விருப்பு- வெறுப்பின்றி சந்தோஷம் அடைந்து, இருமைகளைக் கடந்து,  பொறாமையற்றவனாய், வெற்றியிலும் தோல்வியிலும் சமநிலைப் பெற்ற அவர்கள் எவ்விதமான செயல்களைச் செய்யும்போதும்  தங்கள் செயல்களுக்குக் கட்டுப்படுவதில்லை. 4.22",

    "உலகப் பற்றுதல் அகன்றவர், உடல் மற்றும் மனப் பற்றிலிருந்து விடுதலைக் கொண்டவர்,  அவர்களின்  மனத்தை தெய்வீக அறிவில் நிலைநிறுத்தியவர்கள். எல்லாச் செயல்களையும் த்யாகமாக (கடவுளுக்கு) செய்வதால், அவர்கள் எல்லாக் கர்ம வினைகளிலிருந்தும் விடுபடுகிறார்கள். 4.23",

    "இறையுணர்வில் தன்னை முழுவதும்    அர்ப்பணித்துக் கடவுளுக்கு  வைக்கப்படும் காணிக்கை பிரம்மம், அதைச் செலுத்தும் அகப்பை பிரம்மம், த்யாகம் செய்யும் செயல் பிரம்மம், யாகத் தீயும் பிரம்மம். இவ்வாறு எல்லாவற்றையும் கடவுளாகக் கருதும்  நபர்கள் கடவுளை எளிதில் அடைகிறார்கள். 4.24",

    "சில யோகிகள் விண்ணுலகக் கடவுள்களுக்குச் செய்யப்படும் வேள்வியை வழிபடுகிறார்கள். மற்றவர்கள் பரம சத்தியத்தின் நெருப்பில் சுயத்தைத் தியாகம் செய்பவர்களைப் பரிபூரணமாக வணங்குகிறார்கள். 4.25",

    "சிலர் செவி முதலிய அனைத்து புலன்களையும் புலன் அடக்குதல் ஆகிய  சம்யமம் என்ற அக்னியில் தியாகம் செய்கிறார்கள். வேறு சிலர் ஒலி அதிர்வுகள் மற்றும் எல்லாப் புலன்களின் பொருட்களையும் புலன்களின் அக்னியில் தியாகம் செய்கிறார்கள். 4.26",

    "சிலர் ஞானத்தால் ஈர்க்கப்பட்டு, கட்டுப்படுத்தப்பட்ட மனத்தின் நெருப்பில் தங்கள் அனைத்து புலன்களின் செயல்பாடுகளையும் தங்கள் உயிர் ஆற்றலையும் தியாகம்  செய்கிறார்கள். 4.27",

    "சிலர் செல்வத்தைத் யாகம் செய்கிறார்கள். வேறு சிலர் கடுமையானத் தவத்தால். மற்றும் சிலர் யோகத்தின் எட்டு வகைப் பாதையான அஷ்டாங்க யோகத்தால். இன்னும் சிலர்  வேதங்களைப் படித்துக் கல்வியால் ஞானத்தை வளர்த்து கடுமையானப் பிரதிக்ஞையை கடைப்பிடிக்கிறார்கள். 4.28",

    "இனி வேறு சிலர் பிராணாயாமத்தில் கடினமாகப் பயிற்சியில் ஈடுபட்டவர்களாய், பிராணன் (உள்ளிருக்கும் காற்றை வெளியே தள்ளுவது), அபானன் (வெளிக் காற்று உள்ளே செல்வது) என்ற வாயுக்களின் தன்மையைக் கட்டுப்படுத்தி, அபானவாயுவில் பிராணவாயுவையும் , பிராணவாயுவில் அபானத்தையும் யாகம் செய்கிறார்கள். 4.29",

    "இன்னும் சிலர் தங்கள் உணவை ஒழுங்குபடுத்தி உயிர்-சக்தியில் மூச்சைத் தியாகமாக செய்கிறார்கள்.  இதை அறிந்த அனைவரும் வேள்வி நெறியுணர்ந்து யாகங்களால் பாவமற்றுப் போகிறார்கள். 4.30",

    "தியாகத்தின் ரகசியத்தை அறிந்து, அதில் ஈடுபடுபவர்கள், அதன் அமிர்தத்தை உட்கொண்டு, முழுமையான பிரம்மத்தை  அடைகிறார்கள். ஓ குரு வம்சத்தில் சிறந்தவரே அர்ஜுனா! இத்தகைய யாகம் செய்யாதவருக்கு இவ்வுலகிலும் பரலோகத்திலும் மகிழ்ச்சி அடைவதில்லை. 4.31",

    "இவ்விதம் இன்னும் பல்வேறு வகையான தியாகங்கள் அனைத்தும் வேதங்களில் விவரிக்கப்பட்டுள்ளன. அவை பல்வேறு வகையான எழுதப்பட்ட அறிவார்ந்த  படைப்புகளிலிருந்து தோன்றியவை என அறிந்து கொள்ளுங்கள்; இந்தப் புரிதல் பொருள் அடிமைத்தனத்தின் முடிச்சுக்களிலிருந்து நம்மை விடுவிக்கும். 4.32",

    "ஓ பரந்தபா! (எதிரிகளை அடிபணியச் செய்பவரே அர்ஜுனா) இயந்திரப் பொருட்கள் ( உலகியல் பொருட்கள்) கொண்டு செய்யப்படும்  யாகத்தை விட அறிவால் செய்யப்படும் ஞானவேள்வி சிறந்தது. ஓ பார்தா, செய்கையின் அனைத்து தியாகங்களும் ஞானத்தில் முடிவடைகின்றன. 4.33",

    "ஆன்மீகக் குருவை அணுகி உண்மையைக் கற்றுக்கொள்ளுங்கள். அவரிடம் குருபக்தியுடன்    பணிவாகச் சேவைச் செய்யுங்கள். அத்தகைய உண்மை அறிந்த ஞானிகள் உங்களுக்கு ஞானத்தை உபதேசிப்பார்கள். 4.34",

    "இந்த வழியைப் பின்பற்றி குருவிடம் ஞானம் பெற்ற பிறகு, அர்ஜுனா! இனி நீ மாயையின் வலையில் விழமாட்டாய். அந்த அறிவின் வெளிச்சத்தில், எல்லா ஜீவராசிகளும் பரமபிதாவின் பகுதிகளே. எல்லோரும் எனக்குள் இருப்பதையும் நீங்கள் காண்பீர்கள். 4.35",

    "பாவிகள் எல்லோரைக் காட்டிலும் அதிகப் பாவியாக இருந்தாலும் கூட , அப்பாவத்தை எல்லாம் ஞானமெனும் தோணியின் உதவியால் பிறவிக்கடலை கடந்துவிடலாம். 4.36",

    "எரிக்கப்பட்ட நெருப்பு விறகுகளைச் சாம்பலாக்குவதுப் போல, ஓ அர்ஜுனா! அறிவின் நெருப்பு பௌதிகச் செயல்களின் அனைத்து எதிர்வினைகளையும் சாம்பலாக்குகிறது. 4.37",

    "இவ்வுலகில் தெய்வீக அறிவைப் போல் தூய்மைப்படுத்துவது வேறு எதுவும் இல்லை. நீண்டகால யோகப் பயிற்சியின் மூலம் மனத்தூய்மை அடைந்த ஒருவர், சரியான நேரத்தில் இதயத்தில் அத்தகைய அறிவைப் பெறுகிறார். 4.38",

    "எவருடைய நம்பிக்கை  பிரம்மத்தில் ஆழமாக இருக்கிறதோ, அவர்கள் மனதையும் புலன்களையும் அடக்கிப் பழகுபவர்கள், மற்றும் தெய்வீக அறிவை அடைகிறார்கள். இத்தகைய ஆழ்நிலை அறிவின் மூலம், அவர்கள் விரைவில் நிரந்தரமான உயர்ந்த அமைதியை அடைகிறார்கள். 4.39",

    "ஆனால் நம்பிக்கையோ, அறிவோ இல்லாதவர்கள், சந்தேகப்படும் இயல்புடையவர்கள், வீழ்ச்சியைச் சந்திக்கிறார்கள். சந்தேகம் கொண்ட ஆன்மாக்களுக்கு இம்மையிலும் மறுமையிலும் மகிழ்ச்சி இல்லை. 4.40",

    "அர்ஜுனா, கர்மத்தைத் துறந்தவர்களையும், அறிவினால் சந்தேகங்கள் நீங்கியவர்களையும், சுய அறிவில் நிலைபெற்றவர்களையும் செயல்கள் பிணைப்பதில்லை. 4.41",

    "ஆதலால், அஞ்ஞானத்தால் உள்ளத்தில் எழுந்த ஐயங்களை ஞான வாளால் துண்டித்துவிட்டு. பாரதத்தின் வாரிசுகளே, கர்ம யோகத்தில் உங்களை நிலைநிறுத்திக் கொள்ளுங்கள். எழுந்துநில், செயலாற்ற 4.42",

    "பிரம்ம வித்தையும் யோக சாஸ்திரமும் ஸ்ரீ கிருஷ்ணார்ஜுன சம்பாஷணையுமாகிய ஸ்ரீமத்பகவத்கீதை உபநிஷத்தில் \"ஞானகர்ம ஸந்யாஸ யோகம்\" என்ற நான்காவது அத்தியாயம் ஸம்பூர்ணம்."
  ],
  "adhyaya-5" : [
    "அர்ஜுனன் கூறினார்: ஓ ஸ்ரீ கிருஷ்ணா, நீங்கள் கர்ம சந்யாச பாதையை (செயல்களைத் துறக்கும் பாதை) புகழ்ந்தீர்கள். மேலும் கர்ம யோகத்தை (பக்தியுடன் செய்யும் வேலை) செய்ய அறிவுறுத்தினீர்கள். இரண்டில் எது அதிக பலன் தரும் என்பதை தயவு செய்து இறுதியாகச் சொல்லுங்கள்? 5.1",

    "பகவான் கூறினார்: கர்ம சந்யாச யோகம் (செயல்களைத் துறத்தல்) மற்றும் கர்ம யோகம் (பக்தியுடன் பணிபுரிதல்) ஆகிய இரண்டும் உயர்ந்த இலக்கை நோக்கிச் செல்கின்றன. ஆனால் கர்ம யோகம் கர்ம சந்யாச யோகத்தை விட மேலானது. 5.2",

    "கர்ம யோகிகளில், எதையும் விரும்பாமலும் அதே சமயம் எதையும் வெறுக்காமலும் இருக்கிறவர்களை, எப்பொழுதும் அனைத்தும் துறந்தவர்களாகவே கருதப்பட வேண்டும். அனைத்து இருமைகளிலிருந்தும் விடுபட்ட அவர்கள், பௌதிக சக்தியின் பிணைப்பிலிருந்து எளிதில் விடுபடுகிறார்கள். 5.3",

    "அறியாமையில் உள்ளவர்கள் மட்டுமே சாங்கியம் (செயல்களைத் துறத்தல், அல்லது கர்ம சந்யாசிகள்) மற்றும் கர்ம யோகம் (பக்தியுடன் பணிபுரிதல்) ஆகியவற்றை வித்தியாசமாகப் பேசுகிறார்கள். இந்த பாதைகளில் ஏதேனும் ஒன்றைப் பற்றிக்கொள்வதன் மூலம், இரண்டின் பலனையும் அடையலாம் என்று கற்றவர்கள் கூறுகிறார்கள். 5.4",

    "கர்ம சந்யாசத்தால் அடையும் உன்னத நிலையை பக்தியில் உழைப்பதாலும் அடையப்படுகிறது. எனவே, கர்ம சந்யாக்களையும் கர்ம யோகத்தையும் ஒரே மாதிரியாகப் பார்ப்பவர்களே உண்மையாகவே எல்லாவற்றையும் சரியான காட்சியோடு பார்க்கிறார்கள். 5.5",

    "ஓ வலிமையான கரங்கள் கொண்ட அர்ஜுனா,பக்தியில் கர்மயோகம் இல்லாதவர்கள் (வேலை செய்யாமல்) பரிபூரண துறவு (கர்ம சந்நியாசம்) பெறுதல் கடினம்.  ஆனால் கர்மயோகத்தில் தன்னைப் பொருந்தியத் திறமையான முனிவர்கள் விரைவில் பிரம்மத்தை அடைகிறார்கள். 5.6",

    "மனக்கட்டுப்பாடுடையவனும் புலன்களை வென்றவனும் மனத்தூய்மை உடையவனும் எல்லா உயிரினங்களுக்கும் ஆத்மாவான பரமாத்மாவைத் தன்-ஆத்மா என்று உணர்ந்தவனுமான கர்மயோகி கர்மங்களைச் செய்து கொண்டிருந்தாலும் அவற்றில் ஒட்டுவதில்லை. 5.7",

    "கர்ம யோகத்தை உறுதியாகப்பற்றி உண்மை அறிந்த யோகிகள், பார்ப்பது, கேட்பது, தீண்டுவது, நுகர்வது, உண்பது, நடப்பது , உறங்குவது, போன்ற செயல்களில் ஈடுபடும்போதும், “நான் எதனையுஞ் செய்வதில்லை ” என்று எப்போதும் உணர்ந்தவர்கள். 5.8",

    "பேசுவது, புலம்புவது , வெளியேற்றுவது, பற்றுவது, இமைகளைத் திறப்பது மூடுவது என்று எதிலும் தெய்வீக அறிவின் ஒளியால், அவர்கள் தங்கள் பொருள்களுக்கு இடையே ஜடப்பொருள் உணர்வுகள் மட்டுமே நகர்வதைக் காண்கிறார்கள். 5.9",

    "எல்லாப் பற்றுகளையும் விட்டுவிட்டுத் தங்கள் செயல்களைக் கடவுளுக்கு அர்ப்பணிப்பவர்கள், நீரில் தாமரை இலை ஒட்டாதது போல் பாவத்தால் ஒட்டாமல் இருக்கிறார்கள். 5.10",

    "யோகிகள், பற்றுதலைக் கைவிட்டு, தங்கள் உடல், புலன்கள், மனம், புத்தி ஆகியவற்றைக் கொண்டு சுய தூய்மைக்காக மட்டுமே செயல்களைச் செய்கிறார்கள் 5.11",

    "அனைத்துச் செயல்களின் பலனையும் கடவுளுக்கு வழங்குவதால், கர்மயோகிகள் நிரந்தரமான அமைதியை அடைகிறார்கள். அதேசமயம், கர்மயோகம் அற்றவர்கள் தங்கள் ஆசைகளால் தூண்டப்பட்டு, சுயநல நோக்கத்துடன் வேலை செய்பவர்கள் தங்கள் தங்கள் செயல்களின் பலனுடன் இணைந்திருப்பதால் சிக்கிக் கொள்கிறார்கள். 5.12",

    "தன்னடக்கமும், ஒதுங்கியும் இருக்கும் ஆத்மாக்கள், எதற்கும் தாங்கள் செய்பவர்கள் அல்லது காரணம் என்ற எண்ணங்கள் இல்லாமல் ஒன்பது வாயில்கள் கொண்ட நகரத்தில் பரமாத்மாவிடம் நிலைத்து மகிழ்ச்சியுடன் வாழ்கின்றனர் 5.13",

    "செயலின் உணர்வோ செயல்களின் தன்மையோ கடவுளிடமிருந்து வருவதில்லை; செயல்களின் பலனையும் படைக்கவில்லை. இவை அனைத்தும் ஜட இயற்கையின் (குணங்கள்) முறைகளால் இயற்றப்படுகின்றன. 5.14",

    "எங்கும் நிறைந்த கடவுள் யாருடைய பாவ, புண்ணிய செயல்களிலும் தன்னை ஈடுபடுத்திக் கொள்வதில்லை. ஜீவராசிகள் மாயைக்கு ஆளாகின்றனர், ஏனென்றால் அவர்களின் உள்ளறிவு அறியாமையால் சூழப்பட்டுள்ளது. 5.15",

    "ஆனால் தெய்வீக அறிவால் அறியாமை அழிக்கப்படுபவர்களுக்கு, சூரியன் உதிக்கும்போது எல்லாவற்றையும் ஒளிரச் செய்வது போல, பரம்பொருளை ஒளியுறக் காட்டுகிறது. 5.16",

    "யாருடைய புத்தி கடவுளில் நிலைத்திருக்கிறதோ, கடவுளில் முழுவதுமாக லயித்து, அவரையே உயர்ந்த குறிக்கோளாகக் கொண்டுள்ள உறுதியான நம்பிக்கையுடைய அத்தகைய நபர்கள், அறிவின் ஒளியால் தங்கள் பாவங்கள் அகற்றப்பட்டு, திரும்ப முடியாத நிலையை விரைவாக அடைகிறார்கள். 5.17",

    "கல்வியும் விநயமும் நன்கு கற்ற பிராமணரையும், பசுவையும், யானையையும், நாயையும், நாயை உண்பவரையும், உண்மையான கற்றவர்கள்  தெய்வீக ஞானக்கண்களால் சமமான பார்வையுடன் பார்க்கிறார்கள் 5.18",

    "பார்வையில் சமத்துவத்தில் மனதை நிலைநிறுத்துபவர்கள் இந்த வாழ்க்கையில் பிறப்பு மற்றும் இறப்பு சுழற்சியை வெல்வார்கள். அவர்கள் கடவுளின் குறைபாடற்ற குணங்களைக் கொண்டுள்ளனர். எனவே முழுமையான பிரம்மத்தில் நிலைப் பெறுகிறார்கள் 5.19",

    "கடவுளில் நிலைபெற்று, தெய்வீக அறிவைப் பற்றிய உறுதியான புரிதலுடன், மாயையால் தடைபடாதவர்கள், இனிமையான ஒன்றைப் பெறுவதில் மகிழ்ச்சியடைவதுமில்லை விரும்பத்தகாததை அனுபவிப்பதில் வருத்தப்படுவதுமில்லை. 5.20",

    "புற புலன் இன்பங்களில் பற்று இல்லாதவர்கள் தெய்வீக ஆனந்தத்தை சுயமாக உணர்கிறார்கள். யோகத்தின் மூலம் கடவுளோடு ஐக்கியமாகி, முடிவில்லாத மகிழ்ச்சியை அனுபவிக்கிறார்கள். 5.21",

    "இந்திரியப் பொருட்களுடன் தொடர்பு கொள்வதால் ஏற்படும் இன்பங்கள், உலக எண்ணம் கொண்டவர்களுக்கு இன்பமாகத் தோன்றினாலும், உண்மையில் துன்பத்திற்கு ஆதாரமே. குந்தியின் மகனே, இத்தகைய இன்பங்களுக்கு ஒரு தொடக்கமும் முடிவும் உண்டு, எனவே ஞானிகள் அவற்றில் மகிழ்ச்சியடைவதில்லை. 5.22",

    "உடலிலிருந்து நீங்குமுன்னர்  இவ்வுலகத்திலேயே விருப்பத்தாலும் சினத்தாலும் விளையும் வேகத்தை  பொறுக்கும் வல்லவர்களான அந்த நபர்கள் யோகிகள். அவர்கள் மட்டுமே மகிழ்ச்சியாக இருக்கிறார்கள். 5.23",

    "எவர்கள் தங்களுக்குள் மகிழ்ச்சியாக இருப்பார்களோ, உள்ளுக்குள் கடவுளின் மகிழ்ச்சியை அனுபவித்து, அக ஒளியால் பிரகாசிக்கிறார்களோ, அத்தகைய யோகிகள் இறைவனுடன் ஐக்கியமாகி ஜட வாழ்விலிருந்து விடுபடுகிறார்கள். 5.24",

    "எவருடைய பாவங்கள் நிவர்த்தி செய்யப்பட்டதோ, சந்தேகங்கள் நிவர்த்தி செய்யப்பட்டதோ, எவருடைய மனங்கள் ஒழுக்கமுடையதோ, எல்லா உயிர்களின் நலனுக்காகவும் அர்ப்பணிப்புடன் உள்ளதோ அந்த புனிதமானவர்கள் கடவுளை அடைந்து ஜட வாழ்விலிருந்து விடுபடுகிறார்கள். 5.25",

    "இடைவிடாத முயற்சியால் கோபம் மற்றும் காமத்தை உடைத்து, தங்கள் மனதை அடக்கி, சுயமாக உணர்ந்த அந்த சந்யாசிகளுக்கு, ஜட வாழ்விலிருந்து விடுதலை என்பது இம்மையும் மறுமையும் ஆகும். 5.26",

    "புருவங்களுக்கு இடையே உள்ள இடைவெளியில் பார்வையை நிலைநிறுத்தி, நாசியில் உள்வரும் மற்றும் வெளியேறும் மூச்சின் ஓட்டத்தை சமன் செய்து, வெளிப்புற இன்பத்தின் அனைத்து எண்ணங்களையும் மூடுதல் 5.27",

    "இவ்வாறு புலன்கள், மனம், புத்தி ஆகியவற்றைக் கட்டுப்படுத்தி, ஆசை மற்றும் பயத்திலிருந்து விடுபடும் ஞானி எப்போதும் சுதந்திரமாக வாழ்கிறார். 5.28",

    "அனைத்து யாகங்களையும், துறவறங்களையும் அனுபவிப்பவனாகவும், அனைத்து உலகங்களுக்கும் மேலான இறைவனாகவும், அனைத்து உயிரினங்களின் தன்னலமற்ற நண்பனாகவும் என்னை உணர்ந்து, என் பக்தன் அமைதியை அடைகிறான். 5.29",

    "பிரம்ம வித்தையும் யோக சாஸ்திரமும் ஸ்ரீ கிருஷ்ணார்ஜுன சம்பாஷணையுமாகிய ஸ்ரீமத்பகவத்கீதை உபநிஷத்தில் \"கர்ம ஸந்யாஸ யோகம்\" என்ற ஐந்தாம் அத்தியாயம் ஸம்பூர்ணம்."
  ],
  "adhyaya-6" : [
    "பகவான் கூறினார்: தங்கள் செயல்களின் முடிவுகளை விரும்பாமல் விதிக்கப்பட்ட கடமைகளைச் செய்பவர்கள் உண்மையான சந்யாசிகள் (துறந்தவர்கள்) மற்றும் யோகிகள் ஆவார்கள். அக்னிஹோத்ர யாகம் போன்ற யாகங்களைச் செய்வதை நிறுத்தியவர்கள் அல்லது உடல் செயல்பாடுகளைக் கைவிட்டவர்கள் யோகிகள் ஆகமாட்டார்கள். 6.1",

    "அர்ஜுனா! சந்யாசம் என்று அறியப்படுவது யோகத்திலிருந்து வேறுபட்டதல்ல, ஏனென்றால் உலக ஆசைகளைத் துறக்காமல் யாரும் யோகிகளாக மாற மாட்டார்கள். 6.2",

    "யோகத்தில் பரிபூரணத்தை விரும்பும் ஆன்மாவுக்கு, பற்றற்ற உழைப்பு வழி என்று கூறப்படுகிறது; ஏற்கனவே யோகத்தில் உயர்ந்த முனிவருக்கு, தியானத்தில் அமைதியே வழி என்று கூறப்படுகிறது 6.3",

    "ஒருவன் புலன்களிடமோ அல்லது செயல்களிடமோ பற்று இல்லாதபோது, ​​அத்தகைய நபர் செயல்களின் பலனுக்காக அனைத்து ஆசைகளையும் துறந்து யோக சாஸ்திரத்தில் உயர்ந்தவர் என்று கூறப்படுகிறார். 6.4",

    "உங்கள் மனதின் சக்தியால் உங்களை உயர்த்திக் கொள்ளுங்கள். உங்களை நீங்களே தாழ்த்திக் கொள்ளாதீர்கள், ஏனென்றால் மனமே சுயத்தின் நண்பனாகவும் எதிரியாகவும் இருக்கும். 6.5",

    "மனதை வென்றவர்களுக்கு அது நண்பன். அப்படிச் செய்யத் தவறியவர்களுக்கு, மனம் எதிரியைப் போல் செயல்படுகிறது. 6.6",

    "மனதை வென்ற யோகிகள் குளிர் மற்றும் வெப்பம், மகிழ்ச்சி மற்றும் துக்கம், மரியாதை மற்றும் அவமதிப்பு ஆகிய இருமைகளுக்கு மேலாக உயர்கிறார்கள். அத்தகைய யோகிகள் அமைதியுடனும், கடவுள் பக்தியில் உறுதியாகவும் இருப்பார்கள். 6.7",

    "அறிவு மற்றும் பாகுபாடுகளால் திருப்தியடைந்து தங்கள் புலன்களை வென்ற யோகி, எல்லா சூழ்நிலைகளிலும் இன்னல்கள் இல்லாமல் இருக்கிறார். அவர்கள்  மண் - கற்கள் மற்றும் தங்கம் அனைத்தையும் ஒரே மாதிரியாகப் பார்க்கிறார்கள். இப்படிப்பட்ட யோகி பகவானை அடைந்தவர் என்று அழைக்கப்படுகிறார். 6.8",

    "தன்னலங்கருதாமல் அனைவருக்கும் நலன் செய்பவன், நண்பனிடமும் பகைவனிடமும் ஒரு சார்புமற்றவன், நடுநிலையிலே நிற்பவன், வெறுக்கத்தக்கவன், உறவினர்களிடத்தும் நல்லோர்களிடத்தும் பாவிகளிடத்தும்கூட சமமான பாவனை கொண்டுள்ளவன்,அத்தகைய யோகிகள் மனிதர்களிடையே மிகவும் சிறந்தவர்களாக ஆகிறார்கள். 6.9",

    "யோக நிலையைத் தேடுபவர்கள் தனிமையில் இருக்க வேண்டும், மனதையும் உடலையும் கட்டுப்படுத்திக் கொண்டு, ஆசைகளையும் உடைமைகளையும் துறந்து, மகிழ்ச்சிக்காகத் தியானத்தில் தொடர்ந்து ஈடுபட வேண்டும். 6.10",

    "யோகப்பயிற்சி செய்ய, சுத்தமான இடத்தில்  துணி,மான்தோல், தர்ப்பை இவற்றை ஒன்றின்மேல் ஒன்றாக விரித்து, இவற்றின்மீது அதிக உயரமில்லாமலும் அதிகத் தாழ்வில்லாமலும் ஆசனம் தரைமீது உறுதியாக அமைக்க வேண்டும். 6.11",

    "அந்த ஆசனத்தின் மீது உறுதியாக அமர்ந்து கொண்டு, யோகிகள் அனைத்து எண்ணங்களையும், செயல்களையும், மனதையும் கட்டுப்படுத்தி,  ஒருமுகத்துடன் தியானத்தில் கவனம் செலுத்துவதன் மூலம் உள்ளத்தைத் தூய்மைப்படுத்த முயற்சிக்க வேண்டும். 6.12",

    "யோகிகள் உடல், கழுத்து மற்றும் தலையை ஒரு நேர்கோட்டில் உறுதியாகப் பிடித்துக் கொண்டு, கண்களை அலைய விடாமல் நாசியின் நுனியின்மீது பார்வையை ஒருமுனைப்படுத்த  வேண்டும். 6.13",

    "எனவே, அமைதியான, அச்சமற்ற, அசையாத மனதுடன், பிரம்மச்சரியத்தின் உறுதியுடன், விழிப்புள்ள யோகி, என்னை மட்டுமே உயர்ந்த குறிக்கோளாகக் கொண்டு என்னைத் தியானிக்க வேண்டும். 6.14",

    "மனத்தைத் தன்வசப்படுத்திய யோகி, இவ்விதம் ஆத்மாவை இடையறாது பரமேசுவரனான என்னுடைய ஸ்வரூபத்தில் இணைத்துக் கொண்டு பரமானந்தத்தின் பெருநிலையாகிய அமைதியை அடைகிறான். 6.15",

    "இத்தகைய கற்பனைகளால் ஆட்கொள்ளப்பட்டு, வழிதவறி, மாயையின் வலையில் சூழப்பட்டு, புலன் இன்பங்களின் திருப்திக்கு அடிமையாகி, அவர்கள் இருண்ட நரகத்தில் இறங்குகிறார்கள். 6.16",

    "ஆனால்  அளவோடு உண்ணுதல் மற்றும் பொழுதுபோக்கில் நிதானமாக இருப்பவர்களும், வேலையில் சமநிலையுடன் இருப்பவர்களும், தூக்கத்தில் சீராக இருப்பவர்களுக்கும், தன் துக்கத்தை போக்க யோகப் பயிற்சி கைக்கொடுக்கிறது. 6.17",

    "முற்றிலும் தன்வசப்பட்ட உள்ளம் எப்பொழுதும் பரமாத்மாவிடமே நன்கு நிலைபெற்றிருக்கிறதோ, அப்பொழுது அனைத்து உலகப் போகங்களிலிருந்தும் பற்று நீங்கிய மனிதன் யோகத்தில் முதிர்ந்தநிலை பெற்றவர்கள் ஆவார்கள். 6.18",

    "காற்றில்லாத இடத்தில் விளக்கின் சுடர் எப்படி அசைவின்றி ஒளிராமல் இருக்கிறதோ, அதுபோல ஒரு யோகியின் ஒழுக்கமான மனம் பரமாத்மாவைத் தியானத்தில் நிலைத்திருக்கும். 6.19",

    "யோகப் பயிற்சியால் மனம், பௌதிகச் செயல்களில் இருந்து கட்டுப்படுத்தப்பட்டால், அத்தகைய யோகி, தூய்மையான மனதின் மூலம் ஆன்மாவைப் பார்க்க முடியும், மேலும் அவர் உள்மகிழ்ச்சியில் மகிழ்ச்சியடைகிறார். 6.20",

    "சமாதி என்று அழைக்கப்படும் யோகத்தின் அந்த மகிழ்ச்சியான நிலையில், ஒருவர் உச்ச எல்லையற்ற தெய்வீக பேரின்பத்தை அனுபவிக்கிறார். இவ்வாறு நிலைநிறுத்தப்பட்டால், ஒருவர் நித்திய சத்தியத்திலிருந்து ஒருபோதும் விலகுவதில்லை. 6.21",

    "அந்த நிலையைப் பெற்ற பிறகு, எந்த ஒரு சாதனையையும் பெரியதாகக் கருதுவதில்லை. இவ்வாறு நிலைநிறுத்தப்பட்டதால், மிகப்பெரிய பேரிடரின் துயரத்தின் மத்தியிலும் ஒருவர்  கலங்குவதில்லை. 6.22",

    "துன்பத்துடன் இணைந்த அந்த நிலையே யோகம் எனப்படும். இந்த யோகத்தை அவநம்பிக்கையிலிருந்து விடுபட உறுதியுடன் பயிற்சி செய்ய வேண்டும். 6.23",

    "உலகத்தின் எண்ணங்களிலிருந்து எழும் அனைத்து ஆசைகளையும் முற்றிலுமாகத் துறந்து, ஒருவன் மனதால் எல்லாப் பக்கங்களிலிருந்தும் புலன்களைக் கட்டுப்படுத்த வேண்டும். 6.24",

    "மெதுவாகவும் சீராகவும், புத்தியில் உறுதியுடன், மனம் கடவுளிடம் மட்டுமே நிலைபெற செய்து, வேறு எதையும் நினைக்கலாகாது. 6.25",

    "அமைதியற்ற மற்றும் நிலையற்ற மனம் அலையும் போதெல்லாம், அதைத் திரும்பக் கொண்டுவந்து, தொடர்ந்து கடவுளிடம் கவனம் செலுத்த வேண்டும். 6.26",

    "எந்த யோகியின் மனம் அமைதியாக இருக்கிறதோ, யாருடைய உணர்ச்சிகள் அடங்கிப் போகின்றனவோ, பாவம் இல்லாதவனாக, எல்லாவற்றையும் கடவுளோடு தொடர்புகொண்டு பார்க்கிற யோகிக்கு பெரும் ஆழ்நிலை மகிழ்ச்சி ஏற்படுகிறது. 6.27",

    "சுயக்கட்டுப்பாடு கொண்ட யோகி, இவ்வாறு தன்னைக் கடவுளுடன் ஐக்கியப்படுத்தி, ஜட மாசுபாட்டிலிருந்து விடுபட்டு, பரமாத்மாவுடன் தொடர்ந்து தொடர்பில் இருந்து, பரிபூரண மகிழ்ச்சியின் உயர்ந்த நிலையை அடைகிறான். 6.28",

    "உண்மையான யோகிகள், தங்கள் உணர்வை கடவுளுடன் இணைத்து, எல்லா உயிர்களையும் கடவுளிலும், கடவுளை எல்லா உயிரினங்களிலும் சமக் கண்ணால் பார்க்கிறார்கள். 6.29",

    "எல்லா இடங்களிலும் என்னைப் பார்ப்பவர்களுக்கும், என்னில் எல்லாவற்றையும் பார்ப்பவர்களுக்கும், நான் ஒருபோதும் தொலைந்து போவதில்லை, அவர்கள் என்னை இழந்ததும் இல்லை. 6.30",

    "என்னுடன் ஐக்கியமாகி, எல்லா உயிர்களிலும் வசிக்கும் பரமாத்மாவாக என்னை வழிபடும் யோகி, எல்லா வகையான செயல்களிலும் ஈடுபட்டிருந்தாலும், என்னிடத்தில் மட்டுமே வசிக்கிறார். 6.31",

    "அனைத்து உயிரினங்களின் உண்மையான சமத்துவத்தைக் கண்டு, பிறருடைய இன்ப துன்பங்களுக்குத் தம்முடையது போலப்  பாவிக்கும் பரிபூரண யோகிகளாக அவர்களை நான் கருதுகிறேன். 6.32",

    "அர்ஜுனன் கூறினார்: ஓ மதுசூதனே ! நீங்கள் விவரித்த யோக முறை, அமைதியற்ற மனதால், நடைமுறைக்கு சாத்தியமற்றதாகவும், அடைய முடியாததாகவும் தோன்றுகிறது. 6.33",

    "மனம் மிகவும் அமைதியற்றது, கொந்தளிப்பானது, வலிமையானது மற்றும் பிடிவாதமானது, ஓ கிருஷ்ணா! காற்றைத் தடுத்து நிறுத்தி கட்டுப்படுத்துவது போல் மிகவும் கடினமானது என்று எனக்குத் தோன்றுகிறது. 6.34",

    "பகவான் கூறினார்: ஓ வலிமைமிக்க கரங்கள் கொண்ட குந்தியின் மகனே! நீ சொல்வது சரிதான்; மனதை அடக்குவது உண்மையில் மிகவும் கடினம். ஆனால் பயிற்சி மற்றும் பற்றின்மை மூலம் அதைக் கட்டுப்படுத்த முடியும். 6.35",

    "கட்டுப்பாடற்ற மனம் உள்ளவருக்கு யோகம் கிடைப்பது கடினம். இருப்பினும், மனதைக் கட்டுப்படுத்தக் கற்றுக்கொண்டவர்களும், முறையான வழிகளில் தீவிரமாக முயற்சிப்பவர்களும் யோகத்தில் முழுமையை அடைய முடியும். இது என்னுடைய கருத்து. 6.36",

    "அர்ஜுனன் கூறினார்: நம்பிக்கையுடன் பாதையைத் தொடங்கும் தோல்வியுற்ற யோகியின்(பகவானின் தரிசனம் கிடைக்காத யோகி) கதி என்ன? ஆனால் ஒரு நிலையற்ற மனத்தால் போதுமான முயற்சி செய்யாமல், இந்த வாழ்க்கையில் யோகத்தின் இலக்கை அடைய முடியாதவரின் நிலை என்ன? 6.37",

    "யோகத்தில் இருந்து விலகிய அத்தகைய நபர், பௌதிக மற்றும் ஆன்மீக வெற்றி இரண்டையும் இழந்து, ஓ வலிமையான கரங்கள் கொண்டவனே இரண்டு கோளங்களிலும் எந்த நிலையும் இல்லாமல் உடைந்த மேகம் போல அழிந்து விடவில்லையா? 6.38",

    "ஓ பகவானே! தயவு செய்து என்னுடைய இந்தச் சந்தேகத்தை முழுவதுமாக நிவர்த்தி செய். உன்னைத்தவிர வேறு யாரால் அவ்வாறு நிவர்த்தி செய்ய முடியும்? 6.39",

    "பகவான் கூறினார்: ஓ பார்த்தா! ஆன்மீகப் பாதையில் ஈடுபடுபவன் இவ்வுலகிலும், மறுமையிலும் அழிவைச் சந்திப்பதில்லை. என் அன்பான நண்பரே, கடவுளை உணர பாடுபடுபவர் ஒருபோதும் தீமையால் வெல்லப்படுவதில்லை. 6.40",

    "தோல்வியுற்ற யோகிகள், இறந்தவுடன், நல்லொழுக்கமுள்ளவர்களின் இருப்பிடங்களுக்குச் செல்கிறார்கள். பல யுகங்கள் அங்கு வசித்த பிறகு, அவர்கள் மீண்டும் பூமியில், பக்தியுள்ள மற்றும் செழிப்பான மக்கள் குடும்பத்தில் மீண்டும் பிறக்கிறார்கள். 6.41",

    "இல்லையெனில், அவர்கள் நீண்ட காலமாக யோகப் பயிற்சிக்குப் பின் சில காரணங்களால் யோகத்தினின்று தவறினால், அவர்கள் தெய்வீக ஞானம் கொண்ட குடும்பத்தில் பிறக்கிறார்கள். இவ்வுலகில் இத்தகைய பிறவி அடைவது மிகவும் கடினம். 6.42",

    "குருக்களின் வம்சாவளியினரே, அத்தகைய பிறப்பை எடுத்தவுடன், அவர்கள் தங்கள் முந்தைய வாழ்க்கையின் ஞானத்தை மீண்டும் எழுப்புகிறார்கள், மேலும் யோகத்தில் முழுமையை நோக்கி இன்னும் கடினமாக முயற்சி செய்கிறார்கள். 6.43",

    "உண்மையில், அவர்கள் தங்கள் கடந்தகால ஒழுக்கத்தின் வலிமையால், தங்கள் விருப்பத்திற்கு மாறாக, கடவுளிடம் ஈர்க்கப்படுகிறார்கள். இத்தகைய தேடல் இருப்பவர்கள் இயற்கையாகவே வேதத்தின் சடங்கு கொள்கைகளை விட உயர்கிறார்கள். 6.44",

    "பல கடந்த பிறவிகளின் திரட்டப்பட்ட புண்ணியங்களுடன், இந்த யோகிகள் மேலும் முன்னேறுவதற்கான நேர்மையான முயற்சிகளில் ஈடுபடும் போது, ​​அவர்கள் ஜட ஆசைகளிலிருந்து தூய்மையடைந்து, இந்த வாழ்க்கையிலேயே முழுமையை அடைகிறார்கள். 6.45",

    "ஒரு யோகி தபஸ்வியை விட (சந்நியாசி), ஞானியை விட உயர்ந்தவர் (கற்றல் உடையவர்), மேலும் கர்மியை விடவும் (சடங்கு செய்பவர்) உயர்ந்தவர். எனவே, அர்ஜுனா! யோகியாக இருக்க முயற்சி செய். 6.46",

    "எல்லா யோகிகளிலும், யாருடைய மனம் எப்பொழுதும் என்னில் லயித்து, மிகுந்த நம்பிக்கையுடன் என்னிடம் பக்தியில் ஈடுபடுகிறதோ, அவர்களையே நான் எல்லோரிலும் உயர்ந்தவர்களாகக் கருதுகிறேன். 6.47",

    "பிரம்ம வித்தையும் யோக சாஸ்திரமும் ஸ்ரீ கிருஷ்ணார்ஜுன சம்பாஷணையுமாகிய ஸ்ரீமத்பகவத்கீதை உபநிஷத்தில் \"ஆத்மஸம்யம யோகம்\" என்ற ஆறாவது அத்தியாயம் ஸம்பூர்ணம்."
  ],
  "adhyaya-7" : [
    "பகவான் கூறினார்: அர்ஜுனா! இப்போது, எப்படி மனதை எப்பொழுதும் என்னிடத்தில் பிரத்தியேகமாக இணைத்து, பக்தி யோகத்தினால் என்னிடம் சரணடைவதன் மூலம், சந்தேகமின்றி, என்னை முழுமையாக அறிந்துக் கொள்ள முடியும் என்பதைக் கேள். 7.1",

    "இந்த அறிவையும் ஞானத்தையும் நான் உனக்கு முழுமையாகக் கூறுகிறேன். இதை அறிந்த பின்னால் இவ்வுலகில் அறியப்பட வேண்டியது வேறு எதுவும் இல்லை. 7.2",

    "ஆயிரக்கணக்கான மனிதரில், யாரோ ஒருவர் பூரண தத்துவத்திற்காக முயலுவார்கள்; மேலும் பரிபூரணத்தை அடையும் முயற்சியுடைய பலரில் ஒருவர், நான் யார் என்ற உண்மை உள்ளபடி அறிகிறார். 7.3",

    "பூமி, நீர், நெருப்பு, காற்று, ஆகாயம் எனும் பஞ்சபூதங்களுடன், மனம், புத்தி மற்றும் அகங்காரம்   (நான் என்ற அகந்தை) - இவையும் எனது பொருள் ஆற்றலின் எட்டுக் கூறுகள். 7.4",

    "என்னுடைய தாழ்ந்த ஆற்றல் அப்படியானது. ஆனால் என்னிடம் வேறுபட்டதாகிய ஒரு உயர்ந்த ஆற்றல் உள்ளது. இதுவே ஜீவசக்தி (ஆத்ம ஆற்றல்), ஓ வலிமையான கரங்கள் கொண்ட அர்ஜுனா, இது இவ்வுலகில் வாழ்வின் அடிப்படையாக விளங்கும் ஆன்மாக்களை உள்ளடக்கியது. 7.5",

    "என்னுடைய இந்த இரண்டு ஆற்றல்கள் தான் எல்லா உயிர்களும் வெளிப்படக் காரணம் என்பதை அறிந்துக் கொள்ளுங்கள். அதனால் முழு உலகப் படைப்பின் ஆதாரம் நானே, அவை மீண்டும் என்னுள் கரைகின்றன. 7.6",

    "அர்ஜுனா, என்னை விட உயர்ந்த பொருள் வேறெதுவுமில்லை. ஒரு நூலில் கட்டப்பட்ட மணிகளைப்போல உலகிலுள்ள அனைத்தும் என்னுள் கோர்க்கப்பட்டுள்ளது. 7.7",

    "ஓ கௌந்தேயா, (குந்தியின் மகனே), நீரின் சுவையும், சூரியன் மற்றும் சந்திரனின் பிரகாசமும் நானே. நான் வேத மந்திரங்களில் ஓம் என்ற புனித எழுத்து; நான் ஈதரில் (வானில்) ஒலி, மற்றும் மனிதர்களில் திறன். 7.8",

    "நான் பூமியின் தூய நறுமணம், நெருப்பில் பிரகாசம். நான் எல்லா உயிர்களிலும் உயிர் சக்தியாகவும், துறவிகளின் தவமாகவும் இருக்கிறேன். 7.9",

    "அர்ஜுனா, நான் எல்லா உயிர்களுக்கும் நித்திய விதை என்பதை அறிந்துகொள். புத்திசாலிகளின் புத்தியும், மகிமையுள்ளவர்களின் மகிமையும் நானே. 7.10",

    "ஓ பாரதர்களில் சிறந்தவரே அர்ஜுனா, வலிமையான மனிதர்களில் ஆசை மற்றும் பேரார்வம் இல்லாத அவர்களின் பலம் நானே. நான் தர்மத்திற்க்கும் வேத கட்டளைகளுக்கும் முரண்படாத காமத்தில் இருக்கிறேன். 7.11",

    "பொருள் இருப்பின் மூன்று(சத்வ - ரஜஸ் -தமோ ) குணங்களைச் சார்ந்த மன நிலைகளெல்லாம் என்னிடம் இருந்தே பிறந்தன. அவை என்னுள் இருக்கின்றன.நான் அவற்றுள் இல்லை. நான் அவற்றிற்கு அப்பாற்பட்டவன். 7.12",

    "இந்த மூன்று குணங்களினாலாகிய எண்ணங்களால் இவ்வுலகியலில் மயங்கிப்போய் இவை அனைத்திற்கும் மேலான அழியாத இயல்பு கொண்ட என்னை உணராதிருக்கின்றனர். 7.13",

    "இயற்கையின் மூன்று முறைகளைக் கொண்ட எனது தெய்வீக ஆற்றல் மாயாவை வெல்வது மிகவும் கடினம். ஆனால் என்னிடம் சரணடைந்தவர்கள் அதை எளிதாகக் கடக்கிறார்கள். 7.14",

    "நால்வகை மக்கள் என்னிடம் சரணடைவதில்லை - அறிவை அறியாதவர்கள், என்னை அறியும் திறன் கொண்டவர்கள் ஆயினும் சோம்பேறித்தனமாகத் தங்கள் கீழ்நிலையைப் பின்பற்றுபவர்கள், மாயை கொண்டவர்கள் மற்றும் அசுர குணம் கொண்டவர்கள். 7.15",

    "பாரதர்களில் சிறந்தவனே, துன்பத்தில் உள்ளவர்கள், அறிவைத் தேடுபவர்கள், உலகப் பொருள்களைத் தேடுபவர்கள், அறிவில் நிலைபெற்றவர்கள் என நான்கு வகையான பக்திமான்கள் என் பக்தியில் ஈடுபடுகிறார்கள். 7.16",

    "இவர்களுள், என்னை அறிவால் வழிபடுபவர்களும், உறுதியோடு என்னிடம் மட்டுமே பக்தி கொண்டவர்களும், உயர்ந்தவர்கள் என்று நான் கருதுகிறேன். நான் அவர்களுக்கு மிகவும் பிரியமானவன்; அவர்கள் எனக்கு மிகவும் பிரியமானவர்கள். 7.17",

    "என் மீது பக்தி கொண்டவர்கள் அனைவரும் உண்மையிலேயே உன்னதமானவர்கள். ஆனால், அறிவில் உள்ளவர்கள், உறுதியான மனம் கொண்டவர்களும், புத்தி என்னில் இணைந்திருப்பவர்களும், என்னை மட்டுமே தங்கள் உயர்ந்த குறிக்கோளாகக் கொண்டவர்களும், நான் என் சுயமாகவே கருதுகிறேன். 7.18",

    "ஆன்மிகப் பயிற்சியின் பல பிறவிகளுக்குப் பிறகு, ஞானம் பெற்ற ஒருவன், நான் தான் எல்லாமே என்று அறிந்து, என்னிடம் சரணடைகிறான். இவ்வளவு பெரிய ஆன்மா மிகவும் அரிது. 7.19",

    "பௌதிக ஆசைகளால் அறிவு பறிக்கப்பட்டவர்கள் விண்ணுலகக் கடவுள்களிடம் சரணடைகிறார்கள். அவர்களின் சொந்த இயல்பைப் பின்பற்றி, அவர்கள் தெய்வங்களை வழிபடுகிறார்கள். இந்த வான ஆளுமைகளைச் சாந்தப்படுத்துவதற்காகச் சடங்குகளைப் பின்பற்றுகிறார்கள். 7.20",

    "ஒரு பக்தன் எந்த வான வடிவத்தை நம்பிக்கையுடன் வழிபட விரும்புகிறானோ, அத்தகைய பக்தனின் நம்பிக்கையை நான் அந்த வடிவத்தில் நிலைநிறுத்துகிறேன். 7.21",

    "நம்பிக்கையுடன், பக்தன் ஒரு குறிப்பிட்ட வானக் கடவுளை வணங்கி, ஆசைப் பொருட்களைப் பெறுகிறான். ஆனால் உண்மையில், நான் மட்டுமே இந்த நன்மைகளை வகுத்துக்கொடுக்கிறேன். 7.22",

    "ஆனால், கொஞ்சம் கொஞ்சமாகப் புரிந்துகொள்ளும் அறிவு கொண்ட இவர்கள் பெற்ற பலன் அழியக்கூடியது. விண்ணுலகக் கடவுள்களை வணங்குபவர்கள் விண்ணுலகிற்குச் செல்கிறார்கள். என் பக்தர்கள் என்னிடம் வருகிறார்கள். 7.23",

    "புத்தி குறைந்தவர்கள், முன்பு என்னை (பரம ஶ்ரீ பகவான்) உருவமற்றவனாக இருந்தேன், இப்போது இந்த ஆளுமையை ஏற்றுக்கொண்டேன் என்று நினைக்கிறார்கள். என்னுடைய தனிப்பட்ட வடிவத்தின் அழியாத உயர்ந்த தன்மையை அவர்கள் புரிந்துக் கொள்ளவதில்லை. 7.24",

    "எனது தெய்வீக யோகமய சக்தியால் மறைக்கப்பட்ட நான் அனைவருக்கும் வெளிப்படுவதில்லை. எனவே, அறிவு இல்லாதவர்கள் நான் பிறப்பில்லாதவன், மாறாதவன் என்பதை அறிவதில்லை. 7.25",

    "அர்ஜுனா! கடந்தகாலம், நிகழ்காலம், எதிர்காலம் ஆகியவற்றை நான் அறிவேன், மேலும் அனைத்து உயிரினங்களையும் நான் அறிவேன். ஆனால்  பிறப்பும் - இறப்புமற்ற என்னை யாரும்  அறிவதில்லை. 7.26",

    "ஓ பாரதத்தின் வழித்தோன்றல்களே, ஆசை, வெறுப்பு என்ற இருமைகள் மாயையிலிருந்து எழுகின்றன. எதிரிகளை வென்றவரே அர்ஜுனா, பௌதிக உலகில் உள்ள அனைத்து ஜீவராசிகளும் இவற்றால் ஏமாற்றப்படுகின்றனர். 7.27",

    "ஆனால் புண்ணிய செயல்களில் ஈடுபட்டு பாவங்கள் அழிக்கப்பட்ட நபர்கள், இருமைகளின் மாயையிலிருந்து விடுபடுகிறார்கள். அத்தகையவர்கள் என்னை உறுதியுடன் வணங்குகிறார்கள். 7.28",

    "முதுமை மற்றும் இறப்பிலிருந்து விடுதலைப் பெற முயல்பவர்கள் என்னிடத்தில் அடைக்கலம் புகுந்தவர்கள், பிரம்மத்தையும், தனிமனித சுயத்தையும், கர்மவினை முழுவதையும் அறிந்து கொள்கிறார்கள். 7.29",

    "என்னை அதிபூதம் (பொருளின் புலம்) மற்றும் அதிதெய்வ (வானக் கடவுள்கள்) மற்றும் அதியஜ்ஞா (அனைத்து யாகங்களின் இறைவன்) ஆகியவற்றின் ஆட்சிக் கொள்கையாக அறிந்தவர்கள், அத்தகைய ஞானம் பெற்ற ஆத்மாக்கள் மரணத்தின் நேரத்திலும் என்னைப் பற்றிய முழு உணர்வில் உள்ளனர். 7.30",

    "பிரம்ம வித்தையும் யோக சாஸ்திரமும் ஸ்ரீ கிருஷ்ணார்ஜுன சம்பாஷணையுமாகிய ஸ்ரீமத்பகவத்கீதை உபநிஷத்தில் \"ஞான விஞ்ஞான யோகம்\" என்ற ஏழாம் அத்தியாயம் ஸம்பூர்ணம்."
    ],
  "adhyaya-8" : [
    "அர்ஜுனன் கேட்கிறார்— “புருஷோத்தமா! அந்தப் பிரம்மம் என்பது என்ன? அத்யாத்மம் என்பது என்ன? கர்மம் என்பது என்ன? அதிபூதம் என்ற பெயரால் எது அழைக்கப்படுகிறது? மேலும், அதிதைவம் என்று எது சொல்லப்படுகிறது? 8.1",

   "மதுசூதனா! இங்கு அதியக்ஞன் என்பவர் யார்? இந்த தேகத்தில் அவர் எப்படி இருக்கிறார்? மேலும், ஒன்றிய மனத்தோடு கூடியவர்களால் மரணத்தறுவாயில் எவ்வாறு நீங்கள் அறியப்படுகிறீர்கள்? 8.2",

   "ஸ்ரீபகவான் சொல்கிறார்— “பிரம்மம் எனப்படுவது மிக உயர்ந்ததும் அழிவற்றதுமானது. அதன் ஸ்வரூபம் அதாவது ஜீவாத்மா அத்யாத்மம் என்ற பெயரால் அழைக்கப்படுகிறது. சராசரங்களின் இருப்பை உண்டுபண்ணுகின்ற படைப்பு (தியாகம்) கர்மம் என்ற பெயரால் அழைக்கப் படுகிறது. 8.3",

   "தோன்றுவது, அழிவது என்ற நியதியோடு கூடிய பொருட்கள் எல்லாம் ‘அதிபூதம்' ஆகும். ஹிரண்யமயனான புருஷன் அதாவது நான்முக பிரம்மதேவன் 'அதிதைவம்' ஆவார். மேலும், உடல் பெற்றவர்களில் சிறந்தவனான அர்ஜுனா! இந்த உடலில் வாசுதேவனான நானே அந்தர்யாமி உருவிலே ‘அதியக்ஞ’மாக இருக்கிறேன். 8.4",

   "மரணகாலத்திலும் எந்த மனிதன் என்னையே நினைத்துக் கொண்டு சரீரத்தை நீத்துக் கிளம்புகிறானோ (அதாவது உயிர்விடுகிறானோ), அவன் என் ஸ்வரூபத்தையே அடைகிறான். இதில் சிறிதுகூட சந்தேகம் இல்லை. 8.5",

   "குந்தியின் மைந்தனே! அர்ஜுனா! இந்த மனிதன் கடைசி காலத்தில் (மரணத்தறுவாயில்) எந்தெந்த ஸ்வரூபத்தைச் சிந்தித்த வண்ணமாய் சரீரத்தைத் துறக்கிறானோ, அந்தந்த ஸ்வரூபத்தையே அடைகிறான். ஏனெனில், அவன் எப்பொழுதும் அதே சிந்தனையில் இருந்திருக்கிறான். 8.6",

   "ஆகையினால் அர்ஜுனா! நீ எல்லாக் காலங்களிலும் இடைவிடாமல் என்னையே தொடர்ந்து நினைத்துக் கொண்டிரு! போரும் புரிவாயாக! இவ்வாறு என்னிடத்தில் அர்ப்பணம் செய்யப் பட்ட மனம்-புத்தியுடன் கூடியவனாக, நீ ஐயமின்றி என்னையே அடைவாய். 8.7",

   "அர்ஜுனா! பகவானைத் தியானிப்பது என்ற பயிற்சியான யோகத்தில் ஈடுபட்டு, வேறு விஷயங்கள்பால் செல்லாத மனத்தினால் பகவானையே சிந்தித்திருக்கும் மனிதன் மிகவும் உயர்ந்த ஒளிபொருந்திய தெய்வீகமான புருஷனையே, அதாவது, சாட்சாத் பரமேசுவரனையே அடை கிறான். இது நியதி. 8.8",

   "எந்த மனிதன் எல்லாமறிந்தவரும் அநாதி யானவரும் (தொன்மையானவரும்) எல்லாவற்றையும் ஆள்பவரும் நுண்ணியதைக் காட்டிலும் மிகவும் நுண்ணியரானவரும் எல்லாவற்றையும் தாங்கிக் காப்பாற்றுபவரும் சிந்தனைக்கெட்டாத வடிவுடையவரும் சூரியனைப் போன்று எப்பொழுதும் சைதன்யப் பிரகாச வடிவானவரும் அவித்யைக்கு மிகவும் அப்பாற்பட்டவரும் சுத்த ஸத் சித் ஆனந்தமயமான வருமான பரமேசுவரனை நினைக்கிறானோ— 8.9",

   "பக்தியோடு கூடிய அந்த மனிதன் யோகத்தின் வலிமையால் புருவங்களின் மத்தியில் பிராணனை நன்றாக நிலைநிறுத்தி, மேலும் அசையாத மனத்தால் நினைத்துக் கொண்டு அந்தத் திவ்விய ரூபத்தோடு கூடிய மேலான புருஷனான பரமாத்மாவையே அடைகிறான். 8.10",

   "வேதமறிந்த வித்துவான்கள் எந்த ஸத் சித் ஆனந்தமயமான பரமபதத்தை அழிவில்லாதது என்று கூறுகிறார்களோ, பற்று நீங்கிய முயற்சியுள்ள சந்நியாசிகளான மகாபுருஷர்கள் எதில் புகுகிறார்களோ, எந்தப் பரமபதத்தை விரும்புகின்ற பிரம்மசாரிகள் பிரம்மசரிய நெறியைக் கடைப்பிடிக்கிறார்களோ, அந்தப் பரமபதத்தைப் பற்றி, உனக்குச் சுருக்கமாகக் கூறப் போகிறேன். 8.11",

   "புலன்களின் எல்லா வாயில்களையும் அடைத்து வைத்து (அதாவது, வெளிவிஷயங்கள் புகாமல் தடுத்து நிறுத்தி), மனத்தையும் இதயத்தில் நிலையாக நிறுத்தி, (பின் அவ்வாறு வசப்படுத்தப் பட்ட மனத்தால்) பிராணனை உச்சந்தலையில் நிலைபெறச் செய்து, பரமாத்மா சம்பந்தமான  யோகதாரணையில் நிலைத்து நின்று, எவன் ‘ஓம்’ என்னும் ஒரே எழுத்தான பிரம்மத்தை உச்சரித்துக் கொண்டு, அந்த 'ஓம்' என்ற ஏகாக்ஷரத்தின் பொருளான நிர்குணப் பிரம்மமான என்னைச் சிந்தனை செய்து கொண்டு, இவ்வுடலை நீக்கிக் செல்கி 8.12-8.13",

   "புலன்களின் எல்லா வாயில்களையும் அடைத்து வைத்து (அதாவது, வெளிவிஷயங்கள் புகாமல் தடுத்து நிறுத்தி), மனத்தையும் இதயத்தில் நிலையாக நிறுத்தி, (பின் அவ்வாறு வசப்படுத்தப் பட்ட மனத்தால்) பிராணனை உச்சந்தலையில் நிலைபெறச் செய்து, பரமாத்மா சம்பந்தமான  யோகதாரணையில் நிலைத்து நின்று, எவன் ‘ஓம்’ என்னும் ஒரே எழுத்தான பிரம்மத்தை உச்சரித்துக் கொண்டு, அந்த 'ஓம்' என்ற ஏகாக்ஷரத்தின் பொருளான நிர்குணப் பிரம்மமான என்னைச் சிந்தனை செய்து கொண்டு, இவ்வுடலை நீக்கிக் செல்கி 8.12-8.13",

   "அர்ஜுனா! எவன் வேறு சிந்தனையற்று எப்பொழுதும் இடைவிடாமல் புருஷோத்தமனான என்னை நினைக்கிறானோ, எப்போதும் இடைவிடாமல் என்னிடம் ஒன்றிவிட்ட அந்த யோகி என்னை எளிதில் அடைய முடியும். 8.14",

   "மிக உயர்ந்த ஸித்தியை அடைந்த மகாத்மாக்கள் என்னை அடைந்துவிட்ட பிறகு, துன்பங்களுக்கு உறைவிடமானதும் நிலையற்றதுமான மறுபிறவியை அடைவதில்லை. 8.15",

   "அர்ஜுனா! பிரம்மலோகம் வரை உள்ள எல்லா உலகங்களும் அழிந்து உண்டாகும் தன்மை உடையன. அதாவது, அந்த லோகங்களை அடைந்தாலும் திரும்பவும் உலகில் பிறக்க நேரிடும். ஆனால், குந்தியின் புதல்வனே! என்னை அடைந்தபின்னர் மறுபிறவி கிடையாது. ஏனென்றால், நானோ காலத்தைக் கடந்தவன். இந்தப் பிரம்மலோகம் முதலியன எல்லாம் காலவரைக்குட்பட்டு அழிவன. 8.16",

   "பிரம்மாவுக்கு எது ஒரு பகலோ அது ஓராயிரம் சதுர்யுகங்களைக் கால வரையறையாகக் கொண்டது என்றும், அவ்வாறே இரவும் ஓராயிரம் சதுர்யுகங்களை முடிவாகக் கொண்டது என்றும், எந்த மனிதர்கள் தத்துவரீதியாக அறிகிறார்களோ, அந்த யோகியர் பகலிரவு என்ற காலத்தின் தத்துவத்தை அறிந்தவர்கள். 8.17",

   "பிரம்மாவின் பகல் தொடங்கும்போது எல்லா விதமான சராசரமான உயிர்த்தொகுதிகளும் அவ்யக்தத்திலிருந்து -பிரம்மாவின் சூட்சும சரீரத்திலிருந்து - வெளிப்படுகின்றன. மேலும், பிரம்மதேவனின் இரவு தொடங்கும்போது, அந்த அவ்யக்தம் என்ற பெயருள்ள பிரம்மாவின் சூட்சும சரீரத்திலேயே மறைகின்றன. 8.18",

   "அர்ஜுனா! அதே இந்த உயிரினங்களின் கூட்டம் மீண்டும் மீண்டும் பிறந்து தன்வசமின்றிப் பிரகிருதியின் வசப்பட்டு, இரவின் தொடக்கக் காலத்தில் மறைகிறது. பகலின் தொடக்கத்தில் மறுபடியும் உண்டாகிறது. 8.19",

   "ஆனால், அந்த அவ்யக்தத்தைக் காட்டிலும் மிகவும் உயர்ந்த, வேறான தனித்தன்மை கொண்ட எந்த சாசுவதமான, வெளிப்படாத பரம்பொருளான பரமாத்மா உள்ளாரோ, அந்த மேலான திவ்விய புருஷன் எல்லா பூதங்களும் (உயிரினங்களும்) அழிந்தபோதிலும் அழிவதில்லை. 8.20",

   "அவ்யக்தம் (தோன்றாநிலை), அக்ஷரம் (அழிவில்லாதது) என்று கூறப்பட்ட அதே ‘அக்ஷரம்’ என்ற பெயருள்ள அவ்யக்தத் தன்மையை மிக உயர்ந்த கதி என்று கூறுவர். அவ்வாறே எந்த சநாதனமான அவ்யக்த நிலையை அடைந்த பிறகு மனிதர்கள் திரும்புவதில்லையோ, அது என்னுடைய பரமபதம். 8.21",

   "அர்ஜுனா! எல்லா உயிரினங்களும் எந்த பரமாத்மாவினுள் இருக்கின்றனவோ, எந்த ஸத் சித் ஆனந்தமயமான பரமாத்மாவினால் இந்த அகில உலகமும் பரிபூரணமாக நிறைந்து இருக்கிறதோ, அந்த சநாதன அவ்யக்தனான பரம புருஷனோ, வேறு எதிலும் நாட்டமில்லாத பக்தியினாலேயே அடையத்தக்கவர். 8.22",

   "அர்ஜுனா! எந்தக் காலத்தில் (வழியில்) உடலை நீத்துவிட்டுச் செல்கின்ற யோகியர் திரும்பி வராத பெருநிலையையும், மேலும் எந்த வழியில் செல்கின்ற யோகிகள் திரும்பி வரும் நிலையையும் (மறுபிறப்பு என்னும் நிலையையும்) அடைகிறார்களோ, அந்தக் காலத்தை (இரண்டு வழிகளையும்) சொல்கிறேன். 8.23",

   "எந்த வழியில் ஒளிமயமான அக்னி அபிமான தேவதையாக இருக்கிறாரோ, பகலின் அபிமான தேவதை இருக்கிறாரோ, சுக்லபக்ஷ அபிமான தேவதை இருக்கிறாரோ, உத்தராயணத்தின் ஆறு மாதங்களின் அபிமான தேவதை இருக்கிறாரோ, அந்த மார்க்கத்தில் இறந்த பிறகு செல்கின்ற பிரம்மத்தை அறிந்த யோகிகளான ஜனங்கள் மேலே கூறப்பட்ட தேவதைகளால் வரிசையாக அழைத்துச் செல்லப்பட்டுப் பிரம்மத்தை அடைகிறார்கள். 8.24",

   "எந்த மார்க்கத்தில் புகைக்குரிய தேவதை இருக்கிறாரோ, ராத்திரிக்குரிய தேவதை இருக்கிறாரோ, அப்படியே கிருஷ்ணபக்ஷத்திற்குரிய தேவதை இருக்கிறாரோ, தக்ஷிணாயனத்தின் ஆறு மாதங்களுக்குரிய தேவதை இருக்கிறாரோ, அந்த மார்க்கத்தில் பற்றுடன் கர்மங்களைச் செய்கின்ற யோகி இறந்தபின் மேற்கூறப்பட்ட தேவதைகளால் வரிசையாக அழைத்துச் செல்லப்பட்டுச் சந்திரனுடைய ஒளியை அடைந்து சுவர்க்கத்தில் தன் நற்செயல்களின் பயன்களை அனுபவித்து விட்டுத் திரும்பி வருகிறார். 8.25",

   "ஏனென்றால், உலகிற்கு இந்த இரண்டு விதமான சுக்ல, கிருஷ்ண அதாவது, தேவயானம், பித்ருயானம் எனப்படும் வழிகள் என்றும் உள்ளனவாகக் கருதப்படுகின்றன. இவற்றுள் ஒன்றின் வழியாகச் செல்கின்றவன் (அர்ச்சிர் மார்க்கத்தின் மூலம் செல்கிற யோகி) எதிலிருந்து வர வேண்டியதில்லையோ, அந்த பரமகதியை அடைகிறான். மற்றொரு வழியாக திரும்பி செல்கின்றவன் (தூமமார்க்கத்தில் செல்கின்ற பற்றுடன் கர்மம் செய்கின்ற யோகி ) மறுபடியும் திரும்பி வருகிறான். அதாவது, பிறப்பையும் இறப்பையும் அடைகிறான்.  8.26",

   "பார்த்தா! இந்த இரண்டு மார்க்கங்களையும். தத்துவரீதியாக அறிந்த எந்த யோகியும் மோகம் அடைவதில்லை. அவன் பற்றற்ற நிலையிலேயே சாதனையைச் செய்வான். ஆசையில் சிக்கிக் கொள்ளமாட்டான். ஆகையால், அர்ஜுனா! எல்லாக் காலங்களிலும் சமபுத்தி என்னும் யோகத்தோடு கூடியவனாக ஆகிவிடு. எப்பொழுதும் என்னை அடைவதற்காகவே சாதனைகளைச் செய்பவனாக இரு. 8.27",

   "யோகியானவன் இந்த ரகசியத்தைத் தத்துவரீதியாக அறிந்து, வேதங்களை அத்தியயனம் செய்வதிலும் யாகங்களைச் செய்வதிலும் தவம் செய்வதிலும் தானங்கள் செய்வதிலும் எந்த புண்ணியத்தின் பயன் ஏற்படுமென்று சொல்லப் பட்டிருக்கிறதோ, அந்த எல்லாவற்றையுமே ஐயமின்றி கடந்து செல்கிறான். மேலும் என்றுமுள்ள பரமபதத்தை அடைகிறான். 8.28",

   "பிரம்ம வித்தையும் யோக சாஸ்திரமும் ஸ்ரீ கிருஷ்ணார்ஜுன சம்பாஷணையுமாகிய ஸ்ரீமத்பகவத்கீதை உபநிஷத்தில் \"அக்ஷரப்ரஹ்ம  யோகம்\" என்ற எட்டாம் அத்தியாயம் ஸம்பூர்ணம்."
  ],
  "adhyaya-9" : [
    "ஶ்ரீ பகவான் கூறுகிறார்: ஓ அர்ஜுனா! என் மீது பொறாமை கொள்ளாத இயல்புடைய பக்தனாகிய உனக்கு நான் இப்போது  இந்த இரகசிய அறிவையும் ஞானத்தையும் வழங்குகிறேன். இதை அறிந்தால்  இந்தச்சம்சார வாழ்வின் துன்பங்களிலிருந்து நீ விடுதலை பெறுவாய். 9.1",

    "இந்த அறிவு அனைத்து அறிவியல் கடலின் ராஜா மற்றும் அனைத்து ரகசியங்களிலும் மிகவும் ஆழமானது. அதைக் கேட்பவர்களைத் தூய்மைப்படுத்துகிறது. இது நேரடியாக உணரக்கூடியது, தர்மத்திற்கு இணங்கியது, நடைமுறைப்படுத்த எளிதானது, மற்றும் நிரந்தரமான விளைவுள்ளது. 9.2",

    "ஒ பரன்தபா! ( எல்லா எதிரிகளையும் வென்ற அர்ஜுனா) , இந்த தர்மத்தின் சிறப்பில் நம்பிக்கை இல்லாதவர்களால் என்னை அடைய முடியாது. அவர்கள் பிறப்பு இறப்பு சுழற்சியில் மீண்டும் மீண்டும் இந்த சம்சாரச் சக்கரத்தில் சுழல்கிறார்கள். 9.3",

    "இந்த முழு பிரபஞ்ச வெளிப்பாடும் எனது உருவமற்ற பரமாத்மாவான வெளிப்படுத்தப்படாத வடிவத்தில் நான் வியாபித்துள்ளேன். எல்லா உயிர்களும் என்னில் வசிக்கின்றன, ஆனால் நான் அவற்றில் வசிக்கவில்லை. 9.4",

    "ஆனால் இங்கு உள்ள உயிரினர்கள் என்னில் நிலைத்திருக்கவில்லை. என் தெய்வீக ஆற்றலின் பெருமையை பாருங்கள்! நான் அனைத்து உயிரினங்களையும் உருவாக்கி பராமரிப்பவன் என்றாலும், நான் அவற்றால் அல்லது ஸ்தூலமான இயற்கையால் பாதிக்கப்படவில்லை. 9.5",

    "எங்கும் வீசும் பலத்த காற்று வானத்தில் எப்பொழுதும் நிலைபெற்றிருக்கிறதோ அதைப்போல, எல்லா உயிர்களும் எப்போதும் என்னிடத்தில் நிலையாக இருக்கின்றன என்பதை அறிந்து கொள்ளுங்கள். 9.6",

    "ஒரு கல்பத்தின் முடிவில், அனைத்து உயிரினங்களும் எனது ஆதிப்பௌதிக ஆற்றலில் இணைகின்றன. அடுத்த படைப்பின் தொடக்கத்தில், ஓ கௌந்தேயா! (குந்தியின் மகனே), நான் அவற்றை மீண்டும் படைக்கிறேன். 9.7",

    "எனது பௌதிக ஆற்றலைத் தலைமை தாங்கி, நான் இந்த எண்ணற்ற வடிவங்களை அவற்றின் இயல்புகளின் சக்திக்கு ஏற்ப மீண்டும் மீண்டும் உருவாக்குகிறேன். 9.8",

    "ஓ தநஞ்ஜேயா! (செல்வத்தை வென்றவன்), இந்த செயல்கள் எதுவும் என்னை பிணைப்பதில்லை. நான் இந்தச் செயல்களில் இருந்து விலகி, நடுநிலையான பார்வையாளனாகவே இருக்கிறேன். 9.9",

    "ஓ கௌந்தேயா!(குந்தியின் மகனே), எனது வழிகாட்டுதலின் கீழ் செயல்படுவதால், இந்த பொருள் ஆற்றல் அனைத்து உயிருள்ள மற்றும் உயிரற்ற வடிவங்களை உருவாக்குகிறது. இக்காரணத்திற்காக இந்த உலகம் மாற்றங்களுக்கு உட்படுகிறது (உருவாக்கம், பராமரிப்பு மற்றும் கலைத்தல்). 9.10",

    "நான் எனது திவ்ய ரூபத்தில் இருக்கும்போது, திசைமாறிய நபர்கள் என்னை அறியாமல் புறக்கணிக்கிறார்கள். எல்லா உயிர்களுக்கும் மேலான இறைவனாகிய எனது ஆதிபத்ய தெய்வீகத்தன்மையை அவர்கள் அறிய இயலாதவர்கள். 9.11",

    "பொருள் ஆசை, தேவையற்ற அறிவு, உறுதி அற்ற மனம் உள்ள நபர்கள்  அசுர மற்றும் அரக்க தன்மையை ஏற்றுக்கொள்கிறார்கள். அந்த திசைமாறி - திக்குமாறியவர்கள் தன் நலன் மீதான நம்பிக்கைகள் வீணாகி, அவர்களின் செயல் பலன் வீணாகி, அவர்களின் அறிவு கலாச்சாரம் குழப்பமடைகிறது. 9.12",

    "ஆனால், என் தெய்வீக ஆற்றலில் அடைக்கலம் கொண்ட உயர்ந்த ஆத்மாக்கள், ஓ பார்தா, (ப்ருதாவின் மகன்), பகவான்  கிருஷ்ணனான என்னை அனைத்து படைப்புகளுக்கும் முன் தோன்றிய மற்றும் குறைகளற்றபிறப்பிடமாகவும் அறிவார்கள். அவர்கள் தங்கள் மனதை என்னிடமே நிலைநிறுத்தி என் பக்தியில் ஈடுபடுகிறார்கள். 9.13",

    "எப்பொழுதும் என்தெய்வீக மகிமைகளைப்பாடி, மிகுந்த உறுதியுடன் ஈடுபாட்டுடன், பணிவுடன் என் முன்பணிந்து, அவர்கள் அன்பான பக்தியுடன் தொடர்ந்து என்னை வணங்குகிறார்கள். 9.14",

    "சிலர், அறிவு வளர்ச்சி மனப்பான்மையை வளர்க்க யாகத்தில் ஈடுபட்டு, பல முறைகளால் என்னை வழிபடுகிறார்கள். சிலர் என்னை வேறுபடுத்தாத ஒருமையாகப் பார்க்கிறார்கள், நான் அவர்களிடமிருந்து வேறுபட்டவன் அல்ல என்று. சிலர் என்னை அவர்களிடமிருந்து தனித்தனியாகப் ( பல தோற்றங்களில்) பார்க்கிறார்கள். இன்னும் சிலர் என் பிரபஞ்ச வடிவத்தின் எல்லையற்ற வெளிப்பாடுகளில் என்னை வணங்குகிறார்கள். 9.15",

    "நானே வேத சம்பிரதாயம், நானே யாகத்தின் யக்ஞம், நானே முன்னோர்களுக்குப் படைக்கும் பிரசாதம். மருத்துவ மூலிகைகளும் நானே,  வேத மந்திரமும் நானே.  தெளிவுபடுத்தப்பட்ட வெண்ணெய், நெருப்பு மற்றும்  எல்லோரும் கொடுக்கும் பிரசாதமும் நானே. 9.16",

    "இந்த பிரபஞ்சத்தின், தந்தையும் நான் தான் ;  தாயும் நான் தான். பராமரிப்பாளரும், பேரருளும் கூட நான் தான். நானே தூய்மையாக்குபவன், எல்லோரின் அறிவின் குறிக்கோள் மேலும் ஓம் என்ற புனித எழுத்து. வேதங்களில் நானே ரிக் வேதம், சாம வேதம், யஜுர் வேதம். 9.17",

    "நானே அனைத்து உயிர்களுக்கும் உயர்ந்த இலக்கு, மேலும் நான் அவற்றின் பராமரிப்பாளர், எஜமானர், சாட்சி, வசிக்கும் இடம், பாதுகாவலான இடம் மற்றும் நண்பர். நான் படைப்பின் தோற்றம் - முடிவு மற்றும் ஓய்வு இடம்; நான் களஞ்சியம் மற்றும் அழியாத நிலையான விதை. 9.18",

    "நான் சூரியனின் பிரகாசமான வெப்பக்கதிர் ஒளிகளை போல் வெப்பத்தை வெளிப்படுத்துகிறேன்;நானேதான் மழையை உள்ளிழுத்து தடுத்து நிறுத்திகிறேன், அதே சமயம் மழையைப்பொழியவும் செய்கிறேன். நானேதான் அமரத்துவம் அதேபோல் மரணம் உருவானவன், ஓ அர்ஜுனா! நிரந்தர ஆத்மாவும் நானேதான் மற்றும் தற்காலிக ஸ்தூலமான பொருளும் நானேதான். 9.19",

    "வேதங்களில் விவரிக்கப்பட்டுள்ள பலன் தரும் செயலில் நாட்டம் கொண்டவர்கள் சம்பிரதாய யாகங்கள் மூலம் என்னை வழிபடுகிறார்கள். யாகங்களில் எஞ்சியிருக்கும் சோம ரசத்தைப் பருகி பாவத்திலிருந்து தூய்மையடைந்து சொர்க்கம் செல்ல முற்படுகிறார்கள். தங்களின் புண்ணிய செயல்களால், சொர்க்கத்தின் அரசனான இந்திரனின் இருப்பிடத்திற்குச் சென்று, தேவலோகத்தில் தேவர்களின் இன்பத்தை அனுபவிக்கின்றனர். 9.20",

    "அவர்கள் பரலோக இன்பங்களை அனுபவித்து, அவர்களின் புண்ணியத் தகுதிகள் தீர்ந்தவுடன் மறுபடியும் அழிவுடைய பூமிக்கு திரும்புகிறார்கள். இவ்வாறு, மூன்றுவேத நெறிமுறைகளைப் பின்பற்றுபவர்கள், இன்பப் பொருட்களை விரும்பி, திரும்பத் திரும்ப இவ்வுலகில் வந்து செல்கின்றனர். 9.21",

    "எப்பொழுதும் என்னையே நினைத்து, என்னிடம் பிரத்யேக பக்தியில் ஈடுபடுபவர்கள் மற்றும் எவருடைய மனம் எப்போதும் என்னில் லயிக்கப்படுகிறதோ, அவர்களுக்கு நான் அவர்களிடம் இல்லாததை வழங்குகிறேன். என்னை அடைவதற்கான ஸாதனங்களை அளிக்கிறேன். ஏற்கனவே அவர்களிடம் இருப்பதைப் பாதுகாக்கிறேன். 9.22",

    "ஓ கௌந்தேயா! (குந்தியின் மகனே), பயனை எதிர்ப்பார்த்து மற்ற தெய்வங்களை உண்மையாக வழிபடும் பக்தர்களும் என்னையே வணங்குகிறார்கள். ஆனால் அவர்கள் தவறான முறையில் வழிபடுகிறார்கள்  9.23",

    "எல்லோரும்  செய்யும் தியாகங்களும் புண்ணிய சேவைகளும் அனுபவிக்கும்  ஒரே இறைவன் நான்தான். ஆனால் எனது தெய்வீக இயல்பை உணரத் தவறியவர்கள் மீண்டும் மீண்டும் இவ்வுலகில் பிறக்க வேண்டும். 9.24",

    "விண்ணுலகக் கடவுள்களை வழிபடுபவர்கள் வான தெய்வங்களிடையே பிறக்கிறார்கள், முன்னோர்களை வணங்குபவர்கள் முன்னோர்களிடம் செல்கிறார்கள், ஆவிகளை வணங்குபவர்கள் அத்தகைய உயிரினங்களில் பிறக்கிறார்கள், என்பக்தர்கள் என்னிடம் மட்டுமே வந்து சேர்கிறார்கள். 9.25",

    "பக்தியுடன் ஒருஇலையோ , பூவோ, பழமோ அல்லது  தண்ணீரைக் கூட அன்புடன் பயன் எதிர்ப்பாராமல் எனக்கு சமர்ப்பித்தால், என்பக்தன் அன்புடன் அளிக்கும் அந்தப் பொருளை தூய்மையான உணர்வில் நான் மகிழ்ச்சியுடன் ஏற்றுக்கொள்கிறேன். 9.26",

    "ஓ கௌத்தேயா ! (குந்தியின் மகனே), நீ எதைச் செய்தாலும், எதை உண்டாலும், புனிதமான ஹோமத்திற்கு த்யாகமாக எதைச்  செலுத்தினாலும், எதனைக் கொடுத்தாலும் துறவியாக இருந்தாலும் , அவை எல்லாமே எனக்கு காணிக்கை அர்ப்பணமென்ற எண்ணத்துடன் செய். 9.27",

    "உன்னுடைய எல்லாப் படைப்புகளையும் எனக்கு அர்ப்பணிப்பதன் மூலம், நன்மை தீமைகளின் அடிமைத்தனத்திலிருந்து நீ விடுபடுவாய். இவ்வாறு துறப்பதன் மூலம் என்னுடன் உங்கள் மனம் இணைந்திருந்தால், நீங்கள் விடுதலை பெற்று என்னை அடைவீர்கள். 9.28",

    "நான் எல்லா உயிர்களிடத்தும் சமமாகவே உள்ளேன்; நான் யாருக்கும் நட்பும்- பகையும் இல்லை, பாரபட்சமும் இல்லை. ஆனால் அன்புடன் என்னை வழிபடும் பக்தர்கள் என்னில் வசிக்கிறார்கள், நான் அவர்களில் வசிக்கிறேன். 9.29",

    "மிகவும் கொடிய இழிவான பாவிகள் என்னைப் பிரத்தியேக பக்தியுடன் வணங்கினாலும், அவர்கள் சரியான தீர்மானத்தைச் செய்ததால் அவர்களை நற்குணமுள்ளோர் என கருதப்படுவார்கள். 9.30",

    "விரைவில் அவர்கள் நல்லொழுக்கம் உள்ளவர்களாக மாறி, நீடித்த நிலையான சமாதானமான அமைதியை அடைகிறார்கள். ஓ கௌந்தேயா! (குந்தியின் மகனே), என்னுடைய எந்த ஒருபக்தனும் ஒருபோதும் வழிதவறியதில்லை என்பதை நீ தைரியமாக அறிவித்துவிடு. 9.31",

    "ஓ பார்த்தா! என்னிடத்தில் அடைக்கலம் புகுபவர்கள் அனைவரும், அவர்களின் பிறப்பு, இனம், பாலினம் அல்லது ஜாதி எதுவாக இருந்தாலும், சமூகம் அவர்களை இகழ்ந்தாலும், உயர்ந்த இலக்கான என்னை அடைவார்கள். அதாவது முக்தி பெறுவார்கள். 9.32",

    "அப்படியென்றால் அரசர்களும் மெச்சத்தக்க தூய்மையான அந்தணர்களும் முனிவர்களும் எனது உயர்ந்த தகுதியான பக்தர்களைப் பற்றி என்ன கூறுவது? எப்படியோ, இந்த நிலையற்ற மற்றும் மகிழ்ச்சியற்ற உலகத்திற்கு வந்து விட்டீர்கள், எப்பொழுதும் என்னிடம் பக்தியில் ஈடுபடுங்கள். 9.33",

    "எப்பொழுதும் என்னையே நினைத்தும், என்னிடம் பக்தி செலுத்தியும், என்னையே ஆராதனை செய்து என்னை நித்தியம் வணங்கு. உங்கள் மனதையும் உடலையும் எனக்கே அர்ப்பணம் செய்தால், உறுதியாக என்னிடம் வந்து சேர்வீர்கள். 9.34",

    "பிரம்ம வித்தையும் யோக சாஸ்திரமும் ஸ்ரீ கிருஷ்ணார்ஜுன சம்பாஷணையுமாகிய ஸ்ரீமத்பகவத்கீதை உபநிஷத்தில் \"ராஜவித்யா ராஜகுஹ்ய யோகம்\" என்ற ஒன்பதாம் அத்தியாயம் ஸம்பூர்ணம்."
  ],
  "adhyaya-10" : [
    "ஸ்ரீபகவான் சொல்கிறார்— “திரண்டுருண்ட தோளுடையவனே! மிகவும் அன்பு கொண்டவனான உனக்கு உன் நலம் கருதி, நான் எதை மீண்டும் கூறுவேனோ, (அந்த) என்னுடைய மிக உயரிய பொருள் பொதிந்தச் சொற்களைக் கேள். 10.1",

   "என்னுடைய உற்பத்தியை, அதாவது லீலையாக வெளிப்படுவதை தேவகணங்கள் அறியார்கள்; மகரிஷிகளும் அறியார்கள். ஏனெனில், நான் எல்லாவிதங்களிலும் தேவர்களுக்கும் மகரிஷிகளுக்கும் ஆதி காரணம் ஆவேன். 10.2",

   "எவன் என்னைப் பிறப்பற்றவன், அநாதியானவன், உலகங்களுக்கெல்லாம் தலைவன் என்று தத்துவரீதியாக அறிகிறானோ, மனிதர்களில் மிகவும் அறிவாளியான அவன் பாவங்கள் அனைத்திலிருந்தும் விடுபடுகிறான். 10.3",

   "தீர்மானம் செய்யும் திறன், உண்மையான அறிவு, மோகமின்மை, பொறுமை, உண்மை, புலனடக்கம், மனவடக்கம், இன்பமும் துன்பமும், தோன்றுவதும் அழிவதும், பயமும் பயமின்மையும், அஹிம்சை, சமபாவனை, மகிழ்ச்சி, தவம், தானம், புகழ், இகழ்வு -  இத்தகைய உயிரினங்களுடைய வெவ்வேறான மனப்பாங்குகள் என்னிடமிருந்துதான் உண்டாகின்றன. 10.4-10.5",

   "தீர்மானம் செய்யும் திறன், உண்மையான அறிவு, மோகமின்மை, பொறுமை, உண்மை, புலனடக்கம், மனவடக்கம், இன்பமும் துன்பமும், தோன்றுவதும் அழிவதும், பயமும் பயமின்மையும், அஹிம்சை, சமபாவனை, மகிழ்ச்சி, தவம், தானம், புகழ், இகழ்வு -  இத்தகைய உயிரினங்களுடைய வெவ்வேறான மனப்பாங்குகள் என்னிடமிருந்துதான் உண்டாகின்றன. 10.4-10.5",

   "உலகில் இந்தப் பிரஜைகள் அனைவரும் எவர்களிடமிருந்து உண்டானார்களோ, அந்த ஏழு மகரிஷிகளும், அவர்களுக்கும் முந்தியவர்களான சனகர் முதலிய நான்கு முனிவர்களும், அவ்வாறே ஸ்வாயம்புவர் முதலிய பதினான்கு மனுக்களும், என் சங்கல்பத்தாலேயே தோன்றியவர்கள். எல்லோருமே என்னிடம் அன்பும் நம்பிக்கையும் கொண்டவர்கள். 10.6",

   "எவன் என்னுடைய இந்த ஈஸ்வரத் தன்மையின் விரிவையும் யோக சக்தியையும் தத்துவரீதியாக அறிகிறானோ, அவன் அசையாத பக்தியோகத்துடன் கூடியவனாக ஆகிறான். இவ்விஷயத்தில் சிறிதளவும் ஐயமில்லை. 10.7",

   "வாசுதேவனான நானே அனைத்துலகும் தோன்றுவதற்குக் காரணம். என்னாலேயே அகில உலகமும் இயங்குகிறது என்று புரிந்துகொண்டு, நம்பிக்கையும் பக்தியும் கொண்ட அறிஞர்களான பக்தர்கள் பரமேஸ்வரனான என்னை இடையறாது உபாசிக்கிறார்கள். 10.8",

   "இடைவிடாது என்னிடமே மனம் செலுத்தியவர்களும் என்னிடமே உயிரை அர்ப்பணம் செய்தவர்களுமான பக்தர்கள், என்னுடைய பக்தியின் விளக்கத்தின் மூலம் தங்களுக்குள்ளே என்னுடைய பிரபாவத்தை விளக்கிக் கொண்டும், என் குணங்களையும் பிரபாவங்களையும் பேசிக் கொண்டும் எப்பொழுதும் மகிழ்கிறார்கள். மேலும், வாசுதேவனான என்னிடமே இன்புறுகிறார்கள். 10.9",

   "தியானம் முதலியவற்றால் எப்பொழுதும் என்னிடமே ஈடுபடுகின்றவர்களும் பேரன்போடு வழிபடுகின்றவர்களுமான அந்த பக்தர்களுக்கு, எந்த புத்தியோகத்தின் மூலம் அவர்கள் என்னை அடைவார்களோ, அந்தத் தத்துவஜ்ஞான வடிவாகிய புத்தியோகத்தை அளிக்கிறேன். 10.10",

   "அவர்களுக்கு அருள்புரிவதற்காக அவர்களுடைய உள்ளத்தில் நிலைத்து நின்று அறியாமையினால் உண்டான இருளை ஒளி மயமான தத்துவஜ்ஞான வடிவான விளக்கினால் நானே அழிக்கிறேன்.”  10.11",

   "அர்ஜுனன் சொல்கிறார்— “நீங்கள் பரப் பிரம்மம், பரமபதம், மிகவும் புனிதமானவர். ஏனெனில், எல்லா ரிஷிகளும் உங்களை என்றும் உள்ளவர், தெய்விகமான புருஷன், முழுமுதற் கடவுள், பிறப்பற்றவர், எங்கும் நிறைந்தவர் என்று கூறுகிறார்கள். அவ்வாறே தேவரிஷியான நாரதரும், ரிஷிகளான அஸிதரும் தேவலரும், மகரிஷியான வியாசரும் கூடக் கூறுகிறார்கள். நீங்களுமே அவ்வாறே என்னிடம் கூறுகிறீர்கள். 10.12-10.13",

   "அர்ஜுனன் சொல்கிறார்— “நீங்கள் பரப் பிரம்மம், பரமபதம், மிகவும் புனிதமானவர். ஏனெனில், எல்லா ரிஷிகளும் உங்களை என்றும் உள்ளவர், தெய்விகமான புருஷன், முழுமுதற் கடவுள், பிறப்பற்றவர், எங்கும் நிறைந்தவர் என்று கூறுகிறார்கள். அவ்வாறே தேவரிஷியான நாரதரும், ரிஷிகளான அஸிதரும் தேவலரும், மகரிஷியான வியாசரும் கூடக் கூறுகிறார்கள். நீங்களுமே அவ்வாறே என்னிடம் கூறுகிறீர்கள். 10.12-10.13",

   "கேசவா! என்னிடம் எதைக் கூறுகிறீர்களோ, இந்த எல்லாவற்றையும் உண்மையே என்று எண்ணுகிறேன். பகவானே! திருவிளையாடலுக்காக ஏற்றுக்கொண்ட உங்களுடைய ஸ்வரூபத்தை அசுரர்கள் அறிந்து கொள்ளவில்லை; தேவர்கள் கூட அறிந்து கொள்ளவில்லை. 10.14",

   "உயிரினங்களைத் தோற்றுவித்தவரே! உயிரினங்களின் தலைவரே! தேவர்களுக்குத் தேவனே! ஜகந்நாயகனே! புருஷோத்தமரே! நீங்கள்தான் உங்களை அறியவல்லவர். 10.15",

   "எந்த விபூதிகளினால் (சிறப்புக்களினால்) இந்த உலங்களை வியாபித்து நிற்கிறீர்களோ, அந்தத் தெய்விகச் சிறப்படைய உங்களுடைய விபூதிகளை- பெருமைகளை- நீங்கள்தான் முழுமையாகக் கூறுவதற்குத் திறமை வாய்ந்தவர். 10.16",

   "யோகேஸ்வரா! எப்பொதும் சிந்தனை செய்து, நான் உங்களை எவ்வாறு அறிவேன்? மேலும், பகவானே! நீங்கள் எந்தெந்த ஸ்வரூபங்களில் என்னால் தியானம் செய்யத்தக்கவராக விளங்குகிறீர்கள்? 10.17",

   "ஜனார்தனா! உங்களது யோக சக்தியையும் விபூதியையும் மீண்டும் விரிவாகக் கூறுங்கள். ஏனெனில், உங்களுடைய அமுதமொழிகளைக் கேட்கின்ற எனக்குத் திருப்தி ஏற்படவில்லை.” (கேட்க வேண்டுமென்ற ஆசை தொடர்ந்து இருந்து கொண்டிருக்கிறது.) 10.18",

   "ஸ்ரீபகவான் சொல்கிறார்— “குருகுலத்தில் சிறந்தவனே! என்னுடைய விபூதிகள் தெய்விகமானவைதாம். இப்பொழுது உனக்கு அவற்றில் முக்கியமானவற்றைக் கூறுகிறேன். ஏனெனில், என்னுடைய விரிவுக்கு முடிவு இல்லை. 10.19",

   "அர்ஜுனா! நான் எல்லா உயிரினங்களின் உள்ளத்திலும் ஆத்மாவாக இருக்கிறேன். மேலும், உயிரினங்களுக்கெல்லாம் ஆதியாகவும் நடுவாகவும் முடிவாகவும் நானே இருக்கிறேன். 10.20",

   "நான், அதிதியின் புதல்வர்களான பன்னிருவர்களுள் விஷ்ணுவாகவும், ஒளிர்பவைகளுள் கிரணங்களோடு கூடிய சூரியனாகவும், நாற்பத்தொன்பது வாயுதேவர்களுள் தேஜஸ் ஆகவும், நட்சத்திரங்களுள் அவற்றின் தலைவனான சந்திரனாகவும் இருக்கிறேன். 10.21",

   "வேதங்களுள் சாமவேதமாக இருக்கிறேன். தேவர்களுள் இந்திரனாக இருக்கிறேன். பொறி- புலன்களுள் மனத்தாக இருக்கிறேன். மேலும், உயிரினங்களும் உணர்வாக -ஞானசக்தியாக -இருக்கிறேன். 10.22",

   "நான், ருத்ரர்கள் பதினொருவருள் பரமசிவனாக இருக்கிறேன். யக்ஷ-ராக்ஷஸர்களுள் செல்வத்திற்கு அதிபதியான குபேரனாகவும், வசுக்கள் எண்மருள் அக்னியாகவும், சிகரமுடைய மலைகளுள் சுமேரு மலையாகவும் இருக்கிறேன். 10.23",

   "புரோகிதர்களுள் முக்கியமானவரான பிருஹஸ்பதி என்று என்னை அறிந்துகொள். அர்ஜுனா! நான் படைத்தலைவர்களுள் ஸ்கந்தனாகவும், நீர்நிலைகளுள் கடலாகவும் இருக்கிறேன். 10.24",

   "நான் மகரிஷிகளுள் பிருகு மகரிஷியாகவும், சொற்களுள் ஓரெழுத்தான ஓங்காரமாகவும் உள்ளேன். வேள்விகளனைத்துள்ளும் ஜபமாகிய யக்ஞமாகவும், அசையாப் பொருட்களுள் இமய மலையாகவும் இருக்கிறேன். 10.25",

   "மரங்களனைத்துள்ளும் அரச மரமாகவும், தேவரிஷிகளுள் நாரதராகவும், கந்தர்வர்களுள் சித்ரரதன் என்ற கந்தர்வனாகவும், சித்தர்களுள் கபில முனிவராகவும் இருக்கிறேன். 10.26",

   "குதிரைகளுள் அமுதத்துடன் தோன்றிய உச்சைஸ்ரவஸ் என்ற குதிரையாகவும், யானைகளுள் ஐராவதம் என்ற யானையாகவும், மனிதர்களுள் அரசனாகவும் என்னை அறிந்துகொள். 10.27",

   "நான் ஆயுதங்களுள் வஜ்ராயுதமாகவும் பசுக்களுள் காமதேனுவாகவும் உள்ளேன். சாஸ்திர முறையில் மகப்பேற்றுக்குக் காரணமான மன்மதனாக இருக்கிறேன். சர்ப்பங்களுள் சர்ப்பராஜ வாசுகியாகவும் இருக்கிறேன்.  10.28",

   "நான் நாகங்களுள் ஆதிசேஷனாகவும், நீர் வாழ்வனவற்றிற்கும் ஜலதேவதைகளுக்கும் தலைவனான வருணனாகவும் இருக்கிறேன். பித்ரு தேவதைகளுள் அர்யமா என்ற பித்ரு தேவதையாகவும், அடக்கி ஆள்பவர்களுள் யமனாகவும் இருக்கிறேன். 10.29",

   "நான் தைத்தியர்களுள் பிரகலாதனாகவும், காலம் கணிப்பவர்களுள் காலமாகவும், விலங்குகளுள் சிங்கமாகவும், பறவைகளுள் கருடனாகவும் இருக்கிறேன். 10.30",

   "நான் தூய்மைப்படுத்துபவைகளுள் காற்றாகவும், ஆயுதம் தாங்கியவர்களுள் ஸ்ரீராமபிரானாகவும் இருக்கிறேன். மீன் இனங்களுள் முதலையாகவும், நதிகளுள் பாகீரதியான கங்கையாகவும் இருக்கிறேன்.  10.31",

   "அர்ஜுனா! படைப்புகளின் ஆரம்பமாகவும் முடிவாகவும் நடுவாகவும் நான்தான் இருக்கிறேன். வித்தைகளுள் ஆத்ம வித்தையாகவும் (பிரம்ம வித்தையாகவும்), தர்க்கம் செய்பவர்களுடைய தத்துவத்தைத் தீர்மானம் செய்ய உதவும் வாதமாகவும் நானே இருக்கிறேன். 10.32",

   "நான் எழுத்துக்களுள் அகாரமாகவும், ஸமாஸம் அதாவது தொகைகளில் அல்லது தொடர்களுள் உம்மைத் தொகையாகவும் (துவந்துவ ஸமாஸமாகவும்) இருக்கிறேன். அழிவற்ற (முதலும் முடிவுமற்ற) காலமும் (காலங்களுக்கெல்லாம் காலமாகவும்), எல்லாப் புறங்களிலும் முகம் கொண்ட விராட் ஸ்வரூபனும், எல்லாவற்றையும் காத்துப் பேணுபவனும் நானே! 10.33",

   "நான் எல்லாவற்றையும் அழிக்கின்ற மரண தேவதையாகவும், இனி உண்டாகப் போகின்றவற்றிற்குக் காரணமாகவும், மாதர்களுள் கீர்த்தி தேவியாகவும், ஸ்ரீதேவியாகவும், வாக் தேவியாகவும், ஸ்ம்ருதி தேவியாகவும், மேதா தேவியாகவும், த்ருதி தேவியாகவும், க்ஷமா தேவியாகவும் இருக்கிறேன். 10.34",

   "அவ்வாறே கானம் செய்யத் தகுந்த வேதப் பகுதிகளுள் பிருஹத்ஸாமம் என்ற சாம வேதப் பகுதியாகவும், சந்தங்களுள் காயத்ரீ என்ற சந்தமாகவும் நான் இருக்கிறேன். மாதங்களுள் மார்கழியாகவும், பருவங்கள் ஆறினுள் வசந்த ருதுவாகவும் நான் இருக்கிறேன். 10.35",

   "நான் வஞ்சகர்களுள் அவர்களது சூதாட்டமாகவும், திறமை படைத்தவர்களுடைய திறமையாகவும் இருக்கிறேன். நான் வெற்றி கொள்பவரது வெற்றியாக இருக்கிறேன். தீர்மானம் செய்யும் சக்தி உள்ளவர்களின் தீர்மானமாகவும், சத்துவ குணம் படைத்தவர்களிடம் சத்துவ குணமாகவும் இருக்கிறேன். 10.36",

   "நான் விருஷ்ணி குலத்தோருள் வாசுதேவனாகவும் (உன்னுடைய நண்பனான நானாகவும்), பாண்டவர்களுள் அர்ஜுனனாகவும் (நீயாகவும்), முனிவர்களுள் வேதவியாசராகவும், கவிஞர்களுள் சுக்ராசார்யரான கவியாகவும் இருக்கிறேன். 10.37",

   "அடக்குபவர்களின் அடக்கும் சக்தியாக இருக்கிறேன். வெற்றி பெற விரும்புபவர்களின் நியாய உணர்வாக இருக்கிறேன். மறைத்து வைக்கப்பட வேண்டியவற்றைக் காப்பாற்றுவதான மௌனமாகவும், ஞானிகளுடைய தத்துவ ஞானமாகவும் நானே இருக்கிறேன்.  10.38",

   "மேலும், அர்ஜுனா! எல்லா உயிரினங்களுக்கும் எது விதையோ (அவை தோன்றுவதற்குக் காரணமோ) அதுவும் நான்தான். ஏனெனில், நான் இன்றி எது இருக்கக்கூடுமோ, அத்தகைய இயங்குவதும் இயங்காததுமான உயிரினம் எதுவுமில்லை. 10.39",

   "எதிரிகளை வாட்டுபவனே! என்னுடைய தெய்விகமான விபூதிகளுக்கு (பெருமைகளுக்கு) முடிவு இல்லை. எனது விபூதிகளினுடைய இந்த விரிவும்கூட உனக்காகவே ஓரளவுதான் அதாவது சுருக்கமாகத்தான் என்னால் கூறப்பட்டது. 10.40",

   "எது எது சிறப்புடையதோ, ஒளியுடையதோ, சக்தி படைத்ததோ, அந்த அந்தப் பொருள் எல்லாம் என்னுடைய ஒளியின் ஒரு பகுதியின் வெளிப்பாடே என்று நீ அறிந்துகொள். 10.41",

   "தவிரவும், அர்ஜுனா! இவ்வாறு அதிகம் தெரிந்து கொள்வதால் உனக்கு என்ன பயன்? நான் இந்த அனைத்துலகையும் எனது யோக சக்தியின் ஓர் அம்சத்தினால் தாங்கிக் கொண்டு இருக்கிறேன்.'' 10.42",

   "பிரம்ம வித்தையும் யோக சாஸ்திரமும் ஸ்ரீ கிருஷ்ணார்ஜுன சம்பாஷணையுமாகிய ஸ்ரீமத்பகவத்கீதை உபநிஷத்தில் \"விபூதி யோகம்\" என்ற பத்தாம் அத்தியாயம் ஸம்பூர்ணம்."
  ],
  "adhyaya-11" : [
    "அர்ஜுனன் கூறினார்:எனக்கு அருள்புரிவதற்காக என் மீது இரக்கம் கொண்டு , ஆத்ம ஞானமென்ற பரம  ரகசியத்தைத்  தாங்கள் எனக்கு உரைத்தது கேட்டு, என்மயக்கம் (மாயை) இப்போது அழிந்தது. 11.1",

    "தாமரைக் கண்களை உடையவரே, எல்லா உயிர்களின் தோற்றமும் மறைவும் பற்றியும், உங்களது நித்திய மகத்துவத்தைப் பற்றியும், பெருமையையும் விரிவாக உன்னிடம் கேட்கப்பட்டேன். 11.2",

    "ஓ எனது உன்னத பரம்பொருளே! புருஷோத்தமா!நீங்கள் என்னவாக இருக்கிறீர்கள் என்பதை எனக்குத் துல்லியமாக அறிவிக்கிறீர்கள். மனிதர்களில் ஆதிமூலவரே, பரமேஸ்வரா, தங்களின் ஒளி - ஐஸ்வர்யம் மிகுந்த, தெய்வீகப் பிரபஞ்ச விஸ்வரூப வடிவத்தை நான் காண விரும்புகிறேன். 11.3",

    "அனைத்து மாய சக்திகளுக்கும் தலைவரே! யோகேஸ்வரா! அதைக் காணும் அளவுக்கு நான் வலிமையானவன் என்று நீங்கள் நினைத்தால்,  நின் அழிவற்ற பிரபஞ்ச வடிவத்தை எனக்கு வெளிப்படுத்துங்கள். 11.4",

    "பரமேஸ்வரரான  பகவான் கூறினார்: ஒ பார்த்தா! பல நூறாகவும், பல்லாயிரமாகவும்,  பல வகை, பல நிறம், அளவு பலவாகும் உள்ள என்திவ்ய ரூபங்களைப் பார்! 11.5",

    "ஓ பாரதத்தின் வாரிசே அர்ஜுனா, அதிதியின் மகன்களான  பன்னிரண்டு ஆதித்யர்களையும், எட்டு வசுக்களையும், பதினொரு ருத்ரர்களையும், இரட்டை அஷ்வினி குமாரர்களையும், அத்துடன் நாற்பத்தொன்பது மருத்துக்களையும் பார்.  இன்னும் பலரையும், இதுவரை வெளிப்படுத்தப்படாத அற்புதங்களையும் பார். 11.6",

    "இப்போது பார் அர்ஜுனா! முழுப் பிரபஞ்சமும், அசையும் மற்றும் அசையாத அனைத்தும், என்பிரபஞ்ச வடிவில் ஒன்றாக கூடியிருக்கிறது.இன்னும்  வேறு நீ எதைக் காண விரும்புகிறாயோ, அதையெல்லாம் இந்தப் பிரபஞ்ச வடிவத்திற்குள் பார் பார்த்தா. 11.7",

    "ஆனால் உன்னுடைய இந்த பௌதிகக் கண்களால் என்னுடைய பிரபஞ்ச வடிவத்தை உன்னால் பார்க்க முடியாது. எனவே உனக்கு ஞானக்கண் கொடுக்கிறேன். என்னுடைய விஸ்வரூப தரிசனத்தைப் பார். 11.8",

    "சஞ்ஜயன் கூறினார்: ஒ மன்னரே, இவ்வாறுரைத்துவிட்டு, யோகத்தின் அதிபதியான ஸ்ரீகிருஷ்ணர், மிக உயர்ந்த தனது தெய்வீகமான விஸ்வரூப வடிவத்தை அர்ஜுனனுக்குக் காட்டினார். 11.9",

    "அந்த பிரபஞ்ச வடிவத்தில், அர்ஜுனன் எல்லையற்ற முகங்களையும் கண்களையும், பல திவ்ய ஆபரணங்களால் அலங்கரிக்கப்பட்ட மற்றும் பல வகையான தெய்வீக ஆயுதங்களைப் பயன்படுத்துவதையும் கண்டான். 11.10",

    "அவர் தனது உடலில் பல  திவ்ய மாலைகளையும் ஆடைகளையும் அணிந்திருந்தார், மற்றும் பல இனிமையான மணம் கொண்ட சொர்க்க வாசனைகளால் அபிஷேகம் செய்யப்பட்டார். எங்கும் முகம் கொண்ட அற்புதமான   தேவரூபம் மற்றும் எல்லையற்ற இறைவனாக அவர் தன்னை வெளிப்படுத்தினார். 11.11",

    "வானத்தில் ஆயிரம் சூரியன்கள் ஒன்று சேர்ந்து பிரகாசித்தால்,  அங்கு தோன்றும் ஒளி கூட அந்தப் பெரிய தெய்வீக திவ்ய வடிவத்தின் ஒளிக்கு நிகராகாது. 11.12",

    "பிரபஞ்சம் முழுவதையும் ஒரே இடத்தில், கடவுள் கடவுளின் திருமேனியில் நிலைநிறுத்துவதைப் பாண்டுபுத்ரனான அர்ஜுனனால் அங்கு பார்க்க முடிந்தது. 11.13",

    "பிறகு அர்ஜுனன், பெரும் ஆச்சரியம் நிறைந்து, மெய்சிலிர்த்ததுடன், இறைவனின் முன் தலை குனிந்து, கூப்பிய கைகளுடன் அவரை நோக்கி பின்வருமாறு கூறினான். 11.14",

    "அர்ஜுனன் கூறினார்: ஓ ஸ்ரீ கிருஷ்ணா! நான் உங்களது திருமேனியில் அனைத்து கடவுள்களையும் பல்வேறு உயிரினங்களின் சேனைகளையும் காண்கிறேன். தாமரை மலரில் பிரம்மா அமர்ந்திருப்பதைக் காண்கிறேன்; நான் சிவனையும், அனைத்து முனிவர்களையும், விண்ணுலக நாகங்களையும் பார்க்கிறேன். 11.15",

    "எண்ணற்ற கரங்கள், வயிறுகள், முகங்கள் மற்றும் கண்கள் கொண்ட உங்களது எல்லையற்ற வடிவத்தை ஒவ்வொரு திசையிலும் காண்கிறேன். ஓ பிரபஞ்சத்தை இயக்கியவனே, பிரபஞ்சமே யாருடைய வடிவமாக இருக்கிறதோ, நான் உன்னில் எந்த ஆரம்பத்தையும், நடுவையும், முடிவையும் காணவில்லை. 11.16",

    "கிரீடத்தால் அலங்கரிக்கப்பட்டு, சங்கு - சக்கர ஆயுதம் ஏந்திய உங்களது வடிவத்தை, எங்கும் பிரகாசிக்கும் ஒளியின் இருப்பிடமாகவும், அளவிட முடியாதவராய் எல்லாத் திசைகளிலும் சூரியனைப் போல பிரகாசிக்கும் உங்களது பிரகாசத்தின் எரியும் நெருப்பில் உங்களைப் பார்ப்பது கடினம். 11.17",

    "நான் உங்களை உன்னதமான அழியாத பரம்பொருளாக, வேதத்தால் அறியப்படும் இறுதி உண்மையாக அங்கீகரிக்கிறேன். எல்லா படைப்புகளுக்கும் நீங்களே துணை; நீங்கள் சநாதன தர்மத்தின் (நித்திய தர்மம்) நித்திய பாதுகாவலர்; மற்றும் நீங்கள் என்றென்றும் உயர்ந்த தெய்வீக ஆளுமையெனக் கொண்டேன். 11.18",

    "நீங்கள் ஆரம்பம், நடுவும் அல்லது முடிவும் இல்லாமல் இருக்கிறீர்கள்; உங்கள் சக்திக்கும் எல்லை இல்லை. உங்கள் கரங்களும் எல்லையற்றவை; சூரியனும் சந்திரனும் உங்கள் கண்களைப் போலவும், எரியுங்கனல் போல்  முகத்தினைக்கொண்டு, உங்களது அந்தப்பிரகாசத்தால் முழுப்படைப்பையும் சூடேற்றுவதை இங்கு நான் காண்கிறேன். 11.19",

    "வானத்துக்கும் பூமிக்கும் எல்லாத் திசைகளுக்கும் இடைப்பட்ட இடம் உங்களால் மட்டுமே வியாபித்திருக்கிறது. உங்களுடைய அதிசயமான மற்றும் பயங்கரமான வடிவத்தைக்கண்டு, மூன்று உலகங்களும் பயத்தில் நடுங்குவதை நான் காண்கிறேன், ஓ எல்லா உயிரினங்களிலும் சிறந்த தெய்வமே. 11.20",

    "அனைத்து வான தெய்வங்களும் உங்களுக்குள் நுழைந்து அடைக்கலம் பெறுகின்றனர் . பிரமிப்பில்! சிலர் பயந்து கூப்பிய கைகளுடன் உங்களைப் புகழ்கிறார்கள். சிறந்த முனிவர்களும், சித்தர்களும், 'மங்களம் உண்டாகட்டும்' என்று திவ்ய துதிகளாலும், அபரிமிதமான பிரார்த்தனைகளாலும் உம்மைப் போற்றுகின்றனர். 11.21",

    "ருத்ரர்கள், ஆதித்யர்கள், வசுக்கள், சாத்யர்கள், விஸ்வதேவர்கள், அஸ்வினி குமாரர்கள், மருத்துக்கள், உஷ்மபர்கள் (முன்னோர்கள்), கந்தர்வர்கள், யக்ஷர்கள், அசுரர்கள், சித்தர்கள் ஆகிய அனைவரும் உங்களை ஆச்சரியத்துடன் பார்த்துக் கொண்டிருக்கிறார்கள். 11.22",

    "ஓ வலிமைமிக்க இறைவனே, பல வாய்களும், விழிகளும்,  பல கைகளும், பல கால்களும், பல வயிறுகளும் மற்றும் பல பயங்கரமான பற்களுமுடைய உக்கிரமான பெருவடிவைக் கண்டு, அனைத்து உலகங்களும் நடுங்குகின்றன,  அங்ஙனமே நானும். 11.23",

    "ஓ பகவான் விஷ்ணுவே! வானைத்  தொடுவதும், பல வண்ணங்களில் பிரகாசிப்பதும்,  அகலத் திறந்த வாய்களும், மகத்தான சுடர்விடும் விழிகளும் கொண்ட உங்களது வடிவத்தைக் கண்டு, என் இதயம் பயத்தால் நடுங்குகிறது. அதனால் தைரியத்தையும் மன அமைதியையும் இழந்துவிட்டேன். 11.24",

    "அழியும் நேரத்தில் பொங்கி எழும் நெருப்பை ஒத்த, பயங்கரமான உங்களது பற்களைத் தாங்கி நிற்கும் உங்களது பல வாய்களைக் கண்டு,  நான் இருக்கும் இடத்தை மறந்து, எங்கு செல்வதென்று திசைகள் தெரியவில்லை. மனம் அமைதியில்லை, தேவர்களின் தலைவனே, இந்தப் பிரபஞ்சத்தின் உறைவிடம் நீங்கள்; எனக்கு அருள் செய்யுங்கள். 11.25",

    "பீஷ்மர், துரோணாச்சாரியார், கர்ணன் உள்ளிட்ட அவர்களது நட்பு அரசர்களுடன், திருதராஷ்டிரனின் அனைத்து மகன்களையும், எங்கள் தரப்பில் உள்ள தளபதிகளையும் நான் உங்கள் உள்ளே பார்க்கிறேன். 11.26",

    "கொடிய பற்களுடைய  பயமுறுத்தும் உங்கள் வாய்களுக்குள் தலைகீழாக விரைந்து வீழ்கின்றனர். உங்கள் பயங்கரமான பற்களுக்கு இடையில் சிலர் தலைகள் அகப்பட்டு உடைந்து கிடப்பதையும் நான் காண்கிறேன். 11.27",

    "எத்தனையோ பல நதிகளின் வெள்ளங்கள் கடலையே நோக்கி வந்து வேகமாக வீழ்வதுபோல், இந்தப்பெரிய நரலோக  போர்வீரர்கள் அனைவரும் உங்களது சுடர்விட்டு  எரியும்  வாயில்களில் வந்து வீழ்கின்றனர். 11.28",

    "அந்துப்பூச்சிகள் அழிந்துபோக நெருப்புக்குள் பெரும் வேகத்தில் விரைவதைப்போல, இந்தப் படைகள் அனைத்தும் உங்களுடைய வாய்களில்  மிகுந்த வேகத்துடன் வந்து நுழைகின்றன. 11.29",

    "கனல்கின்ற உங்களது நெருப்பு வாய்களால் எல்லாப் பக்கங்களிலும் உள்ள எல்லா உலகங்களையும் நீங்கள் தீண்டுகிறீர்கள். ஓ விஷ்ணுவே! உங்கள் பிரகாசத்தின் உக்கிரம், எங்கும் பரவியிருக்கும் தம்கதிர்களால் பிரபஞ்சம் முழுவதையும் நிரப்பிச் சுடுகின்றன. 11.30",

    "மிகவும் உக்கிரரூப வடிவான நீங்கள் யார் என்று எனக்குச் சொல்லுங்கள். தெய்வங்களின் கடவுளே, நான் உமக்கு முன்பாக வணங்குகிறேன்; அருள் புரியுங்கள்.  படைப்புகள் அனைத்திற்கும் முன் இருந்த ஆதியாகிய நீங்கள், உங்கள் இயல்பையும் செயல்பாடுகளையும் நான் புரிந்து கொள்ளாததால்,  நீங்கள் யார் என்பதை அறிய விரும்புகிறேன். 11.31",

    "ஶ்ரீ பகவான் கூறினார்:  நான் அழிக்க முடியாத காலம்! நான் உலகத்தை  அழிப்பதற்காக வரும் அழிவின் ஆதாரம். இந்த நேரத்தில் உங்கள் பங்கேற்பு இல்லாவிட்டாலும், எதிரணியின் படையில் அணிவகுத்து நிற்கும் வீரர்கள் இல்லாமல் போய்விடுவார்கள். 11.32",

    "ஆதலால் அர்ஜுனா எழுந்து நில் ; புகழெய்து;  உங்கள் எதிரிகளை வென்று வளமான ஆட்சியை ஆள். இந்தப் போர்வீரர்கள் ஏற்கனவே என்னால் கொல்லப்பட்டு நிற்கிறார்கள். ஓ திறமையான வில்லாளியே! நீங்கள் என்பணியின் ஒரு கருவியாகவும் காரணமாகவும்  மட்டுமே இருப்பீர்கள். 11.33",

    "துரோணாச்சாரியார், பீஷ்மர், ஜயத்ரதன், கர்ணன் மற்றும் மற்ற யுத்தப் போர்வீரர்கள் ஏற்கனவே என்னால் கொல்லப்பட்டுள்ளனர்.  நீ கொல். அஞ்சாதே; போர் செய் ;  உங்கள் எதிரிகளை போரில் வெற்றி பெறுவீர்கள். 11.34",

    "சஞ்ஜயன் கூறினார்:கேசவன் சொல்லிய இவ்வார்த்தைகளைக் கேட்ட அர்ஜுனன் பயத்தில் நடுங்கினான். இருகரம் குவித்து மீண்டும் அவர் ஸ்ரீ பகவானை வணங்கியபடியே, பயத்தில் மூழ்கிய குரலில் தடுமாற்றத்துடன் பின்வருமாறு பேசினார். 11.35",

    "அர்ஜுனன் கூறினார்: ஓ உணர்வு புலன்களின் குருவே, பிரபஞ்சம் உங்களைப் புகழ்வதில் மகிழ்ச்சி அடைவதும், உங்களால் ஈர்க்கப்படுவதும் மிகவும் பொருத்தமானது. ராட்சதர்  அச்சமுற்று, எல்லாத் திசைகளிலும் உங்களை விட்டுப் பயந்து ஓடி மறைகிறார்கள். பரிபூரணமான துறவிகளின் அனைத்து சேனைகளும் உங்களை வணங்குகின்றன. 11.36",

    "ஓ பரன்தாமனே! மூலமுதலான படைப்பாளரான பிரம்மாவை விட பெரியவரே, அவர்கள் ஏன் உங்களை வணங்கமாட்டார்கள்? ஓ எல்லையற்றவரே! தேவதைகளின் இறைவனே, ஓ பிரபஞ்சத்தின் அடைக்கலமே! நீங்கள் வெளிப்படையான மற்றும் வெளிப்படுத்தப்படாத இரண்டிற்கும் அப்பாற்பட்ட அழியாத உண்மை. 11.37",

    "நீங்கள் முதன்மையான கடவுள் மற்றும் மூலமுதலான தெய்வீக ஆளுமை;நீங்கள் இந்த பிரபஞ்சத்தின் ஒரே இளைப்பாறும் இடம். எல்லாம் அறிந்தவர் மற்றும் அறிவின் பொருள் இரண்டும் நீங்களே ; நீங்களே பரமபதம், அனந்த ரூபம் (எல்லையற்ற வடிவங்களை உடையவரே), நீங்கள் ஒருவரே பிரபஞ்சம் முழுவதும் வியாபித்திருக்கிறீர்கள். 11.38",

    "நீங்கள் வாயு (காற்றின் கடவுள்), யமதர்ம ராஜன் (மரணத்தின் கடவுள்), அக்னி (அக்னியின் கடவுள்), வருணன் (நீரின் கடவுள்) மற்றும் சந்திரன் (சந்திரன் கடவுள்). அனைத்து உயிரினங்களின் முப்பாட்டனாகிய சிருஷ்டிகர்த்தா பிரம்மா நீங்களே. ஆயிரம் முறை வணங்குகிறேன். மீண்டும் மீண்டும் என் வணக்கங்களைத் தங்களுக்குச் சமர்ப்பிக்கிறேன். 11.39",

    "எல்லையற்ற சக்தியின் ஆண்டவரே,  உங்களுக்கு முன்னும் பின்னும்,  மெய்யாக எல்லாப் பக்கங்களிலிருந்தும் எனது வணக்கங்கள்! நீங்கள் எல்லையற்ற வீரம் மற்றும்  அளவற்ற வலிமை மற்றும் அனைத்தையும் வியாபித்து இருக்கிறீர்கள், ஆதலால் நீங்கள் சர்வமயமானவர். 11.40",

    "இப்படிப்பட்ட உங்கள் மகத்துவத்தை அறியாமல், உங்களை என்தோழனென்று கருதித் துடிப்புற்று,\"ஓ கிருஷ்ணா\", \"ஓ யாதவா\", \"ஓ என் தோழா\" என்று ஆணவத்துடன் உங்களை அழைத்தேன். அலட்சியத்தையும் அளவற்ற பாசத்தையும் காட்டி இருந்தேன். 11.41",

    "விளையாடும் போதும், ஓய்வெடுக்கும் போதும், அமர்ந்தும், உண்ணும் போதும், தனிமையில் இருக்கும் போதும் அல்லது பிறர் முன்னிலையிலும் நான் உங்களுக்கு வேடிக்கையாகச் செய்திருக்கும்  அவமதிப்புகளை எல்லாம் பொறுத்து என்னை மன்னிக்க வேண்டுகிறேன்.. 11.42",

    "நீங்கள் முழு பிரபஞ்சத்திற்கும், அசையும் மற்றும் அசையாத அனைத்து உயிரினங்களுக்கும் தந்தை. நீங்கள் வழிபாட்டிற்கு மிகவும் தகுதியானவர் மற்றும் உயர்ந்த ஆன்மீக குரு. மூன்று உலகங்களிலும் உமக்கு நிகரான எவரும் இல்லாதபோது, ​​ஒப்பற்ற சக்தியை உடையவரே, உங்களைவிடப் பெரியவர் யார் இருக்க முடியும்? 11.43",

    "ஆதலால், வணக்கத்திற்குரிய ஆண்டவரே, ஆழ்ந்து வணங்கி, உமது அருளுக்காக உம்மை மன்றாடுகிறேன். தகப்பன் தன் மகனைப் பொறுத்துக் கொள்வது போலவும், நண்பன் தன் நண்பனை மன்னிப்பது போலவும்,  காதலன் காதலியை மன்னிப்பது போலவும், தயவு செய்து என் குற்றங்களுக்காக என்னை மன்னித்தருளுங்கள். 11.44",

    "இதுவரை நான் பார்த்திராத உனது பிரபஞ்ச வடிவத்தைக்கண்டு நான் மிகுந்த மகிழ்ச்சி அடைகிறேன். ஆனாலும், என் மனம் பயத்தால் நடுங்குகிறது.   மீண்டும் உமது மகிழ்ச்சியான முந்தைய தேவரூபத்தை எனக்குக் காட்டுங்கள். கடவுளின் கடவுளே, பிரபஞ்சத்தின் வசிப்பிடமே, தயவுசெய்து என் மீது கருணைக் காட்டுங்கள். 11.45",

    "ஆயிரம் கரங்களை உடையவரே! நீங்கள் அனைத்துப் படைப்புகளின் உருவமாக இருந்தாலும், முன்புபோலவே தங்களுடைய நான்கு கரங்களுடனான, தண்டாயுதத்தையும் சக்கரமும் ஏந்தியபடி கிரீடத்தை அணிந்த உங்களைக் காண விரும்புகிறேன். 11.46",

    "பகவான் கூறினார்: அர்ஜுனா, உன்னில் மகிழ்ச்சியடைந்து, எனது யோகமய சக்தியால், எனது ஒளிரும், வரம்பற்ற, மற்றும் ஆதிகால பிரபஞ்ச வடிவத்தின் தரிசனத்தை உனக்குக் காண்பித்தேன். ஆதியாகிய இவ்வடிவத்தை இதற்கு முன்  உன்னைத்தவிர வேறு யாரும் பார்த்ததில்லை. 11.47",

    "குரு வீரர்களில் சிறந்தவரே! வேதங்களைப் படிப்பதாலும், யாகம் செய்வதாலும்,  சடங்குகளாலும், தானம் செய்வதாலும், கடுமையானத் துறவறம் மேற்கொண்டாலும் கூட,  மனித உலகத்தில் என்னை இவ்வடிவத்தில் உன்னைத்தவிர வேறு யாராலும் பார்க்க முடியாது. 11.48",

    "இப்படிப்பட்ட என்னுடைய  பயங்கரமான கோர வடிவத்தைக் கண்டு கலங்காதே; மயங்காதே;  பயத்திலிருந்து விடுபட்டு, மகிழ்ச்சியான இதயத்துடன், மீண்டும் ஒருமுறை எனது தனிப்பட்ட  முந்தைய  வடிவத்தை நீ இதோ பார் அர்ஜுனா. 11.49",

    "சஞ்ஜயன் கூறினார்: இவ்வாறு பேசிய வாசுதேவரின் இரக்கமுள்ள மகன் தன்னுடைய தனிப்பட்ட (நான்கு கைகள்) வடிவத்தை மீண்டும் காட்டினார். பின்னர், அவர் தன்னுடைய மென்மையான (இரண்டு கைகள்) வடிவத்தை எடுத்துக்கொண்டு பயந்துபோன அர்ஜுனனை மேலும் ஆறுதல்படுத்தினார். 11.50",

    "அர்ஜுனன் கூறினார்: ஓ ஸ்ரீ கிருஷ்ணா! உனது மென்மையான மனித வடிவத்தை (இரண்டு கரங்களுடன்) பார்த்து, நான் என் அமைதியை மீட்டெடுத்தேன். என் மனம் இயல்பு நிலைக்குத்  திரும்பியது. 11.51",

    "ஶ்ரீ பகவான் கூறினார்: அர்ஜுனா! இங்கு பார்த்த என்னுடைய இந்த வடிவம் காண்பது மிகக் கடினம். இவ்வடிவத்தைக் காண எப்போதும் விண்ணுலக தேவர்களும் ஆவலுடன் உள்ளனர். 11.52",

    "நீ என்னைப் பார்த்ததுபோல் வேதம் படிப்பதாலோ, தவம் செய்வதாலோ, தானம் செய்வதாலோ, அக்னி யாகங்களினாலோ, என்னைப் பார்க்க முடியாது. 11.53",

    "எதிரிகளை வாட்டுபவனே அர்ஜுனா! அலாதியான பக்தியினால் மட்டுமே என்னை இவ்விதமாக அறிதலும், உள்ளபடி காணுதலும்  முடியும். அதன் மூலம்,என்தெய்வீகத் தரிசனத்தைப் பெற்றவுடன், ஒருவன் என்னுடன் ஐக்கியமாக முடியும். 11.54",

    "யார் என்பொருட்டுத் தங்கள் கடமைகள் அனைத்தையும் செய்கிறார்களோ, யார் என்னைச் சார்ந்து, என்னிடம் பக்தி கொண்டவர்களோ, பற்றற்றவர்களோ, எல்லா உயிர்களிடத்தும் தீமை இல்லாதவர்களோ, அத்தகைய பக்தர்கள் நிச்சயமாக என்னை அடைகிறார்கள். 11.55",

    "பிரம்ம வித்தையும் யோக சாஸ்திரமும் ஸ்ரீ கிருஷ்ணார்ஜுன சம்பாஷணையுமாகிய ஸ்ரீமத்பகவத்கீதை உபநிஷத்தில் \"விஸ்வரூப தரிசன  யோகம்\" என்ற பதினொன்றாம் அத்தியாயம் ஸம்பூர்ணம்."
  ],
  "adhyaya-12" : [
    "அர்ஜுனன் கேட்கின்றார்: உங்களிடம் அன்பு  கொண்ட பக்தர்கள் வேறொன்றிலும் நாட்டமில்லாமல் உங்களுடைய பஜனையிலும் தியானத்திலும் ஈடுபட்டு ஸகுணரூபனான பரமேஸ்வரனான உங்களை உயர்ந்த நிலையில் வழிபடுகிறவர்கள், மற்றும் அழிவற்ற ஸத் சித் ஆனந்த மயமான உருவமற்ற பிரமத்தை உயர்நிலையில் வழிபடுகிறவர்கள், இவ்விரு வகையான உபாசகர்களிடையே யோகத்தை சிறந்த முறையில் அறிந்தவர்கள் யார்? 12.1",

    "ஶ்ரீ பகவான் கூறுகிறார்: என்னிடத்தில் மனதை ஒருமுகப்படுத்தி, எப்போதும் என்னை வழிப்பட்டு தியானம் செய்யும் பக்தர்கள், சிறந்த சிரத்தையுடன் சகுண ரூபத்தோடு கூடிய பரமேஸ்வரனான  என்னை வழிபடுகிறவர்கள், யோகிகளிலேயே மேலானவர் என்று என்னால் மதிக்கப்படுவர். 12.2",

    "எந்த ஒரு பக்தன் , என்றும் ஒரே விதமாக இருப்பது, அக்ஷரத்தால் விளக்கமற்ற, நித்யமாக,  அசையாமல், இயக்கமற்ற , உருவமற்ற , அழிவற்ற , ஸத் சித் ஆனந்தத்தை உபாசிக்கிறார்களோ அவர்கள் என்னையே அடைகிறார்கள். 12.3",

    "அனைத்து புலன்களையும் நன்கு அடக்கி, எல்லாவற்றிலும் சமபாவனை கொண்டு, எல்லா உயிரினங்களின் நன்மையில் ஈடுபாடு கொண்ட பக்தர்கள் நிச்சயம் என்னை தான் அடைகிறார்கள். 12.4",

    "ஸத் சித் ஆனந்தமயமான உருவமற்ற பிரம்மத்தில் மனதை ஈடுபடுத்தும் மனிதர்களுக்குச் சாதனையின் உழைப்பு அதிகமாகும். ஏனெனில் உடலில் பற்றுள்ளவர்களால் உருவமற்ற பிரம்ம மார்கத்தை மிகுந்த சிரமத்துடன் அடைகின்றனர். 12.5",

    "ஆனால் ஸகுணமான பரமாத்மாவான என்னையே கதியாகக் கொண்ட பக்தர்கள் எல்லாக் கர்மங்களையும் என்னிடம் சமர்ப்பணம் செய்து ,என்னையே அநன்யமான பக்தியோகத்துடன் இடைவிடாது தியானம் செய்துகொண்டு உபாசிக்கிறார்களோ அவர்கள் சிறந்த வழிபடுகிறவர்கள். 12.6",

    "அர்ஜுனா! இவ்விதம் என்னிடம் மனத்தை ஈடுபடுத்தி பிரேம பக்தி செய்யும் பக்தர்களை நான் சீக்கிரமாகவே மரண வடிவான ஸம்ஸாரக் கடலிலிருந்து கரையேற்றுவேன், இது நிச்சயம். 12.7",

    "என்னிடமே மனதை நிலை நிறுத்து.புத்தியை ஈடுபடுத்து. பிறகு எப்போதும் என்னிடமே வாழ்வாய்.இதில் சிறிதும் ஐயமில்லை. 12.8",

    "அர்ஜுனா!ஒருவேளை  மனதை என்னிடம் நிலைநிறுத்த முடியாவிட்டால் அப்போது பகவான் நாம கீர்த்தனம்- இறைவன் திருநாமம் ஓதுதல் போன்ற பயிற்சி யோகத்தினால் என்னை அடைய விரும்பு. 12.9",

    "இவ்விதமான  பயிற்சிகளில் கூடத் திறமையற்றவனாய் இருந்தால், எனக்காகவே உன் கடமைகளை செய்வது ஒன்றையே மேலான இலட்சியமாகக் கொண்டு இருந்தால், என்னை அடைவது என்ற இலட்சியத்தை இலகுவாக அடைவாய் அர்ஜுனா. 12.10",

    "என்னை அடைவது என்ற யோகத்தைச் சார்ந்து நின்று (அப்யாஸம்) பயிற்சி செய்வதற்குக்கூட திறமையற்றவனாய் இருந்தால்.. அப்போது மனம், புத்தி ஆகியவற்றை அடக்கி வெற்றி கண்டு செய்கின்ற எல்லாக் கர்மங்களின் பயன்களையும் துறந்து விடு. 12.11",

    "தன்னைப்பற்றிய உட்பொருள் அறியாமல் பகவானை அடைய செய்யும் சாஸ்திரப் பயிற்சியைவிட அதன் மூலம் கிடைக்கும் ஞானம் சிறந்தது, ஞானத்தை விட என் ஸ்வரூபத்தைத் தியானம் செய்வது சிறந்தது, தியானத்தை விட கர்மத்தின் பயனைத் துறப்பது சாலச் சிறந்தது, ஏனெனில் தியாகத்தின் மூலம் தாமதமின்றி உடனே மேலான அமைதி உண்டாகிறது. 12.12",

    "யார் எல்லா உயிரினங்களிடமும் வெறுப்பு இல்லாதவனோ, தன்னலம் இல்லாமல் எல்லோரிடமும் அன்பு கொண்டவனோ, காரணமின்றி இரக்கம் காட்டுபவனோ, மமகாரமற்றவனோ, அஹங்காரமற்றவனோ, இன்ப துன்பங்கள் நேரும்போது நிலை மாறாது ஸமமாக இருப்பவனோ, பொறுக்கும் இயல்புடையவனோ( குற்றம் செய்தவனுக்கும் அபயம் அளிப்பவனோ ) அவன் சிறந்தவன். 12.13",

    "எப்பொழுதும் எல்லாவிதங்களிலும் திருப்தி உள்ளவனோ , மனம் புலன்களோடு கூடிய உடலைத் தன் வசப்படுத்தியவனோ, என் மீது திடமான நம்பிக்கையுடையவனோ , என்னிடத்தில் மனம் புத்தி இவற்றை அர்ப்பணம் செய்தவனோ , அந்த பக்தன் எனக்கு ப்ரியமானவன். 12.14",

    "எவனிடமிருந்து எந்த ஜீவனும் பாதிப்படைவது இல்லையோ,  எந்த ஜீவன் மூலமும் பாதிப்படைய மாட்டானோ,மேலும் எவன் ஒருவன் மகிழ்ச்சி, பொறாமை, பயம், பாதிப்பு, இவற்றிலிருந்து விடுபட்டவனோ அந்த பக்தன் எனக்குப் பிரியமானவன். 12.15",

    "எவனொருவன் எதையும் எதிர்பார்க்காதவனோ அகம் மற்றும் புறம் தூய்மையாக உள்ளவனோ, பிறவிப்பயனை அறிந்தவனோ, பாரபட்சமற்றவனோ சுக-துக்கங்களிலிருந்து  விடுதலை பெற்றவனோ, செயல்கள் அனைத்திலும் \"நான் செய்கிறேன்\" என்னும் எண்ணத்தை நீக்கியவனோ, அந்த பக்தன் எனக்குப் பிரியமானவன். 12.16",

    "எவன் ஒருபோதும் மகிழ்ச்சி, வெறுப்பு துயரம் மற்றும் எதற்கும் ஆசைப்படுவதில்லையோ, எவன் ஒருவன் நல்லதும் தீயதுமான செயல்களை துறந்தவனோ அந்த பக்தன் எனக்குப் பிரியமானவன். 12.17",

    "எவன் ஒருவன் பகைவன் - நண்பன் , மதிப்பு - அவமதிப்பு இவற்றில் சமபுத்தி உடையவனோ, அவ்விதமே தட்ப - வெப்பம்,சுக - துக்கங்களாகிய இரட்டைகளில் சமபுத்தி உள்ளவனோ மேலும் பற்றற்றவனோ அந்த பக்தன் எனக்குப் பிரியமானவன். 12.18",

    "இகழ்ச்சியும் புகழ்ச்சியும் சமமாக மதிப்பவனோ, எப்பொழுதும் பகவானை சிந்தித்து  இருப்பவனோ, மேலும்   எந்த விதத்தில் உடலை பேணுதல் நடைபெற்றாலும் மகிழ்ச்சி கொள்பவனோ, வசிக்கும் இடத்தில் தனது என்ற பற்றற்றவனோ, நிலைத்த புத்தி உடையவனோ, பக்தியுள்ள அந்த பக்தன் எனக்குப் பிரியமானவன். 12.19",

    "என்னிடம் நம்பிக்கையோடு என்னையே கதியாகக் கொண்டு மேலே கூறப்பட்ட தர்மமே உருவான அமுதத்தைப்  பயனைக் கருதாது அன்புடன் அனுஷ்டிக்கிறார்களோ அந்த பக்தர்கள் எனக்கு மிகவும் பிரியமானவர்கள். 12.20",

    "பிரம்ம வித்தையும் யோக சாஸ்திரமும் ஸ்ரீ கிருஷ்ணார்ஜுன சம்பாஷணையுமாகிய ஸ்ரீமத்பகவத்கீதை உபநிஷத்தில் \"பக்தி யோகம்\" என்ற பன்னிரண்டாம் அத்தியாயம் ஸம்பூர்ணம்."
  ],
  "adhyaya-13" : [
    "ஸ்ரீ பகவான் கூறினார் – அர்ஜுனா! இந்த உடல் க்ஷேத்திரம் என்ற பெயரால் அழைக்கப்படுகின்றது. இதை எவன் அறிகிறானோ அவனை, க்ஷேத்திரக்ஞன் என்று தத்துவமறிந்த ஞானிகள் கூறுகிறார்கள். 13.1",

    "அர்ஜுனா! நீ, எல்லா க்ஷேத்திரங்களிலும் உள்ள க்ஷேத்ரக்ஞன், அதாவது ஜீவாத்மாவும் நானே என்று தெரிந்துகொள். மேலும், க்ஷேத்திரத்தைப் பற்றியும், க்ஷேத்ரக்ஞனைப் பற்றியும் அதாவது விகாரத்தோடு கூடிய பிரகிருதியையும் புருஷனையும் பற்றிய எது தத்துவரீதியாக அறியக்கூடிய ஞானமோ, அதுதான் ஞானம் என்பது என்னுடைய கருத்து. 13.2",

    "அந்த க்ஷேத்திரம் எது? மேலும் எத்தகையது? எந்த விகாரங்களோடு கூடியது? எதிலிருந்து எது உண்டாயிற்று? மேலும் அந்த க்ஷேத்ரக்ஞன் யார்? எத்தகைய பெருமை கொண்டவன்? என்ற இவையனைத்தையும் சுருக்கமாக என்னிடமிருந்து கேள். 13.3",

    "இந்த க்ஷேத்திர க்ஷேத்ரக்ஞனுடைய தத்துவம் ரிஷிகளால் பலவாறு கூறப்பட்டிருக்கிறது. மேலும், பலவிதமான வேதமந்திரங்கள் மூலமாகவும், தனியாகப் பிரித்தும் சொல்லப்பட்டிருக்கிறது. அப்படியே நன்கு தீர்மானிக்கப்பட்ட யுக்திகளோடு கூடிய பிரம்மசூத்திரத்தின் பதங்களாலும் கூறப்பட்டிருக்கிறது. 13.4",

    "நுண்ணிய ஐம்பெரும் பூதங்களும் அகங்காரமும் புத்தியும் மூலப்பிரகிருதியும் பத்துப்புலன்களும் மனமும் புலன்களுடைய நுகர் பொருட்களான சுவை, ஒளி, ஊறு, ஓசை, நாற்றம் என்ற ஐந்தும் – 13.5",

    "அப்படியே விருப்பு, வெறுப்பு, இன்பம், துன்பம், உடற்கட்டு, சைதன்ய சக்தி மேலும் உறுதி ஆகிய மாறுபாடுகளுடைய இந்த க்ஷேத்திரம் சுருக்கமாகச் சொல்லப் பட்டது. 13.6",

    "தற்பெருமையின்மை; தன்னிடம் இல்லாத நற்குணங்களை இருப்பதாக காட்டிக் கொள்ளாமை; எந்த உயிரினத்தையும் எந்த வகையிலும் துன்புறுத்தாமல் இருத்தல்; பொறுத்துக் கொள்ளுதல்; மனம் வாக்கு முதலியவற்றில் நேர்மை; சிரத்தையுடனும் பக்தியுடனும் பெரியோர்களுக்குப் பணிவிடை செய்தல்; உள்ளும் புறமும் தூய்மையாக இருத்தல்; உள்ளத்தின் உறுதி; மனம்-புலன்களோடு கூடிய உடலைக் கட்டுப்படுத்துதல் – 13.7",

    "இவ்வுலக பரலோக போகங்கள் அனைத்திலும் பற்றற்ற தன்மை; மேலும், அகங்காரம் இல்லாமை; பிறப்பு, இறப்பு, மூப்பு, நோய் - இவற்றில் உள்ள துக்கத்தையும் குறைபாடுகளையும் திரும்பத் திரும்பச் சிந்தித்துப் பார்த்தல் – 13.8",

    "மனைவி, மக்கள், வீடு, செல்வம் – இவற்றில் பற்றின்மை; தன்னுடையது என்ற எண்ணமின்மை, (மமகாரமின்மை); அவ்வாறே வேண்டியன வேண்டாதனவற்றை அடையும் போதும் எப்போதுமே உள்ளம் ஒரே மாதிரியாக இருத்தல், அதாவது சம பாவனையுடன் இருத்தல் – 13.9",

    "பரமேஸ்வரனான என்னிடத்தில் வேறு எதையும் எண்ணாத யோகத்தின் மூலம் பிறழாத பக்தி; தனிமையில் தூய்மையான இடத்தில் வாழும் இயல்பு; உலகியலில் ஈடுபாடுகொண்ட மனிதர்களுடைய கூட்டத்தில் விருப்பம் கொள்ளாதிருத்தல் – 13.10",

    "அத்யாயத்ம ஞானத்தில் எப்பொழுதும் நிலைத்து நிற்றல்; தத்துவ ஞானத்தின் பொருளான பரமாத்மாவையே தரிசனம் செய்தல் – இவை எல்லாம் ஞானம் என்றும்,  எது அதனிலும் மாறுபட்டதோ அது அஞஞானம் என்றும் சொல்லப்பட்டது. 13.11",

    "எது அறியப்பட வேண்டியதோ, எதை அறிந்து மனிதன் பரமானந்தத்தை அடைகிறானோ, அதை விளக்கிக் கூறுவேன்.  அந்த ஆதியில்லாத பரப்பிரம்மம் ‘ஸத்’ என்றும் கூறப்படுவதில்லை ; ‘அஸத்’ என்றும் கூறப்படுவதில்லை. 13.12",

    "அது எங்கும் கைகால்களை உடையது. எங்கும் கண், தலை, முகங்களோடு கூடியது. எங்கும் காதுகளை உடையது. ஏனெனில் அது உலகில் அனைத்தையும் வியாபித்து நிற்கிறது. 13.13",

    "எல்லாப் புலன்நுகர்ப் பொருட்களையும் அறிவது. ஆனால், உண்மையில் எல்லாப் புலன்களும் அற்றது.  அப்படியே ஒன்றிலும் பற்றற்றது. ஆனாலும், எல்லாவற்றையும் தாங்குவது, காப்பது.  மேலும், குணமற்றதாக இருந்தாலும் குணங்களை அனுபவிப்பது. 13.14",

    "சராசரமான எல்லா பிராணிகளுடைய உள்ளும் புறமும் அது நிறைந்திருக்கிறது.  அசைவன – அசையாதவற்றின் வடிவிலும் கூட அதுவே உள்ளது. அது நுண்ணியதாக இருப்பதால் அறிய முடியாதது.  மிக அருகிலும் தூரத்திலும் இருப்பதும் அதுவே. 13.15",

    "அறியத்தக்க அந்தப் பரப்பிரம்மம் ஆகாயத்தைப் போல் பிளவு இல்லாமல் எங்கும் ஒரே சீராக நிறைந்திருந்த போதிலும், சராசரமாகிய பிராணிகள் அனைத்திலும் பிரிக்கப்பட்டுத் தனித் தனியாக இருப்பது போன்ற தோற்றத்தை அளிக்கிறது.  ஸ்ரீ மகா விஷ்ணு ஸ்வரூபமாக சகலப் பிராணிகளையும் தாங்கிப் பேணிக்காப்பது.  மேலும், ருத்ரனுடைய உருவமாக அழிப்பது.  அப்படியே பிரம்மாவின் உருவமாக எல்லோரையும் படைப்பது. 13.16",

    "அந்த பரப்பிரம்மம் ஒளிகளுக்கெல்லாம் ஒளியானது; மாயைக்கு அப்பாற்பட்டது; ஞானவடிவானது; அறியப்பட வேண்டியது; தத்துவ ஞானத்தினால் அடையத்தக்கது; எல்லோருடைய இதயத்திலும் சிறப்பாக விளங்குவது என்று கூறப்படுகிறது. 13.17",

    "இவ்விதம் க்ஷேத்ரக்ஞனுடைய ஸ்வரூபமும்; அவ்விதமே ஞானமும்; அறியவேண்டிய பரமாத்ம ஸ்வரூபமும் சுருக்கமாகச் சொல்லப் பட்டன. என்னுடைய பக்தன் இதை உள்ளபடியறிந்து, என்னுடைய ஸ்வரூபத்தை அடைகிறான். 13.18",

    "பிரகிருதியையும், புருஷனையும் - இவ்விரண்டுமே ஆரம்பமற்றதென அறிந்து கொள்.  மேலும் ராகம், துவேஷம் முதலிய விகாரங்களையும், முக்குணங்களடங்கிய பொருட்கள் அனைத்தையும் கூட பிரகிருதியிலிருந்து உண்டானவையே என்று அறிந்துகொள். 13.19",

    "காரியத்தையும் காரணங்களையும் உண்டுபண்ணும் விஷயத்தில் பிரகிருதி காரணம் என்று கூறப்படுகிறது மேலும் சுக – துக்கங்களை அனுபவிப்பதில் ஜீவாத்மா காரணமென்று கூறப்படுகிறான். 13.20",

    "பிரகிருதியிலிருந்து கொண்டுதான் புருஷன் பிரகிருதியிலிருந்து உண்டான முக்குணங்கள் நிறைந்த பொருட்களை அனுபவிக்கிறான். மேலும், அந்த குணங்களுடைய தொடர்பே, அந்த ஜீவாத்மா நல்லதும் தீயதுமான பிறவிகளில் பிறப்பதற்குக் காரணமாகிறது. 13.21",

    "அந்த புருஷனாகிற ஜீவாத்மா இந்த தேகத்தில் இருப்பினும், அது உண்மையில் பரமாத்மாவே.  (அவரே) சாட்சியாக இருப்பதால் ‘உபத்ரஷ்டா’ என்றும்,  உண்மையான ஒப்புதல் அளிப்பதால் ‘அநுமந்தா’ என்றும், எல்லோரையும் தாங்குவதாலும் காப்பதாலும் ‘பர்த்தா’ என்றும், ஜீவருபமாக இருந்து அனுபவிப்பதால் ‘போக்தா’ என்றும்,  பிரம்மா முதலியவர்களுக்கும் தலைவரானதால் ‘மகேஸ்வரன்’ என்றும்,  சுத்த ஸத் சித் ஆனந்தமயமாக இருப்பதால் ‘பரமாத்மா’ என்றும் சொல்லப்படுகிறார். 13.22",

   "இவ்வாறு புருஷனையும் குணங்களோடு கூடிய பிரகிருதியையும் எந்த மனிதன் தத்துவரீதியாக அறிவானோ, அவன் எல்லா விதங்களிலும் செய்ய வேண்டிய கடமைகளைச் செய்து கொண்டிருந்தால் கூட மறுபடியும் பிறப்பதில்லை. 13.23",

   "அந்தப் பரமாத்மாவை சில மனிதர்கள் தூய்மையடைந்த நுண்ணிய புத்தியினால் தியானத்தின் மூலமாக இதயத்தில் பார்க்கிறார்கள். மற்றும் சிலர், ஞான யோகத்தின் மூலமாகவும், வேறு சிலர் கர்ம யோகத்தின் மூலமாகவும் பார்க்கிறார்கள். (அடைகிறார்கள்). 13.24",

   "ஆனால் வேறு சிலர் (அதாவது, மந்த புத்தியுள்ளவர்கள்) இவ்வாறு அறியாமல் தத்துவத்தை அறிந்த பெரியோர்களிடமிருந்து கேட்டு, அதற்கேற்ப உபாசனையும் செய்கிறார்கள். அவ்வாறு கேட்டறிந்து உபதேசத்தையே மேலான கதியாகக் கொண்டவர்களும், மரணரூபமாகிய சம்சாரக் கடலைச் சந்தேகமின்றி கடக்கிறார்கள். 13.25",

   "அர்ஜுனா! எத்தனை எத்தனை அசைவன,  அசையாதன ஆகிய பிராணி வர்க்கம் உண்டாகின்றனவோ, அவையனைத்தும் நீ,  க்ஷேத்திரம் (அபராப்பிரகிருதி),  க்ஷேத்ரக்ஞன் (பராப் பிரகிருதி) – இவர்களுடைய சேர்க்கையால் உண்டானதென அறிந்து கொள். 13.26",

   "எந்த மனிதன் அழியக்கூடிய சராசரங்களான அனைத்திலும் பரமேஸ்வரனான பகவானை அழியாதவராகவும், (மேலும்) எங்கும் சமமாக  இருப்பவராகவும் பார்க்கிறானோ, அவனே உண்மையில் பார்க்கிறவன் ஆவான். 13.27",

   "ஏனெனில், எந்த ஒருவன் அனைத்திலும் சமமாக நிலைபெற்றிருக்கும் பகவானை சமமாகவே பார்த்துக் கொண்டு, தன்னைத் தன்னாலேயே அழித்துக் கொள்வதில்லையோ, அதனால் அவன் பரம கதியை அடைகிறான். 13.28",

   "மேலும், எந்த மனிதன் எல்லாக் கர்மங்களும் எல்லாவிதங்களிலும் பிரகிருதியினாலேயே செய்யப்படுவனவென்றும்,  மேலும், ஆத்மாவைக் கர்த்தா அல்லன் என்றும் பார்க்கிறானோ, அவனே உண்மையைப் பார்க்கிறவன் ஆவான். 13.29",

   "எப்போது இந்த மனிதன் சராசரங்கள் அனைத்தினுடைய தனித்தன்மையும் பரமாத்மா ஒருவரிடமே நிலைபெற்றிருப்பதாகவும்,  அந்த பரமாத்மாவிடமிருந்தே சகல சராசரங்களும் விரிவடைந்தனவாகவும் காண்கிறானோ, அப்பொழுதே அந்த சத் சித் ஆனந்த மயமான பரமாத்மாவை அடைகிறான். 13.30",

   "அர்ஜுனா!  அனாதியானபடியாலும் நிர்குணமானபடியாலும் இந்த அழிவற்ற பரமாத்மா உடலிலேயே இருந்த போதிலும், உண்மையில் ஒன்றும் செய்வதில்லை; எதிலும் ஒட்டுவதுமில்லை. 13.31",

   "எவ்வாறு எங்கும் நிறைந்த  ஆகாயம் நுண்ணியதாக இருப்பதால் எதிலும் ஒட்டுவதில்லையோ, அவ்வாறே இவ்வுடலில் எங்கும் நிலைபெற்றிருக்கும் ஆத்மா நிர்குணமாக இருப்பதால் உடலின் குணங்களில் ஒட்டுவதில்லை. 13.32",
  
   "அர்ஜுனா!  சூரியன் ஒருவனே, இந்தப் பிரம்மாண்டம் முழுவதையும் எப்படி பிரகாசிக்கச் செய்கிறானோ, அப்படியே ஆத்மா ஒன்றே க்ஷேத்திரம் முழுவதையும் பிரகாசிக்கச் செய்கிறான். 13.33",

   "இவ்விதம் க்ஷேத்திரம்,  க்ஷேத்திரக்ஞன்  - இவற்றின் வேறுபாட்டையும்,  பிரகிருதி பிரகிருதியுடைய செயல்கள் – இவற்றிலிருந்து விடுபடுவதையும், எவர்கள் ஞானக்கண்களால் தத்துவரீதியாக அறிகிறார்களோ, அந்த மகாத்மாக்கள் பரப்பிரம்ம பரமாத்மாவை அடைகிறார்கள். “ 13.34",

   "பிரம்ம வித்தையும் யோக சாஸ்திரமும் ஸ்ரீ கிருஷ்ணார்ஜுன சம்பாஷணையுமாகிய ஸ்ரீமத்பகவத்கீதை உபநிஷத்தில் \"க்ஷேத்ர க்ஷேத்ரக்ஞ விபாக யோகம்\" என்ற பதிமூன்றாம் அத்தியாயம் ஸம்பூர்ணம்."
  ],
  "adhyaya-14" : [
    "ஶ்ரீ பகவான் கூறினார்:     ஞானங்கள் அனைத்திலும் மேலான பரம ஞானத்தை நான் மீண்டும் ஒருமுறை உங்களுக்கு விளக்குகிறேன்; அதை அறிவதன் மூலம், அனைத்துப் பெரிய மகான்களும் உயர்ந்தப் பரிபூரணத்தை அடைந்துள்ளனர். 14.1",

    "இந்த ஞானத்தில் அடைக்கலம் புகுந்தவர்கள் அதனால் என்னுடன் ஐக்கியமாவார்கள். அவர்கள் படைப்பின் போது மீண்டும் பிறக்கவும் மாட்டார்கள், அதே சமயம் உலகம் அழியும் நேரத்தில் அழியவும் மாட்டார்கள். 14.2",

    "இந்த ஸ்தூல பிரக்ருதி ஒரு கருவறை. நான் அதைத் தனிப்பட்ட ஆத்மாக்களால் செறிவூட்டுகிறேன். இதனால் அனைத்து உயிரினங்களும் பிறக்கின்றன. 14.3",

    "ஓ கௌத்தேயா (குந்தியின் மகனே), உற்பத்தியாகும் அனைத்து உயிரினங்களுக்கும், ஜட இயற்கையே கருவாக இருக்கிறது, நான் அதற்கு விதை தரும் தந்தை. 14.4",

    "ஓ வலிமையான கரங்கள்  கொண்ட அர்ஜுனா, பொருள் ஆற்றல் (முறைகள்) மூன்று குணங்களைக் கொண்டுள்ளது - சத்வம் (நன்மை), ரஜஸ் (ஆர்வம்), மற்றும் தமஸ் (அறியாமை). இந்த முறைகள் நித்திய ஆன்மாவை அழியக்கூடிய உடலுடன் பிணைக்கின்றன. 14.5",

    "இவற்றில் சத்வ குணம், நன்மையின் முறை, மற்றவற்றை விட தூய்மையானது, ஒளிமயமானது மற்றும் நல்வாழ்வு நிறைந்தது. ஓ ஒரு பாவம் செய்யாதவரே அர்ஜுனா! அது மகிழ்ச்சி மற்றும் அறிவின் உணர்விற்கானப் பற்றுதலை உருவாக்கி ஆன்மாவைப் பிணைக்கிறது. 14.6",

    "அர்ஜுனா, ரஜோ குணம் உணர்ச்சியின் இயல்புடையது. இது உலக ஆசைகள் மற்றும் பாசங்களிலிருந்து எழுகிறது. மேலும் பலனளிக்கும் செயல்களில் பற்றுதல் மூலம் ஆன்மாவைப் பிணைக்கிறது. 14.7",

    "ஓ அர்ஜுனா, அறியாமையால் பிறக்கும் தமோ குணம், உருவான ஆத்மாக்களுக்கு மாயையை உண்டாக்குகிறது. அலட்சியம், சோம்பல், தூக்கம் ஆகியவற்றின் மூலம் எல்லா உயிர்களையும் ஏமாற்றிவிடுகிறது. 14.8",

    "சத்வ குணம் ஒருவரை பொருள் மகிழ்ச்சியுடன் பிணைக்கிறது; ரஜஸ் குணம் ஆன்மாவை செயல்களை நோக்கி நிலைப்படுத்துகிறது; மற்றும் தமஸ் குணம் ஞானத்தை மேகம் போல் மறைத்து ஒருவரை மாயையுடன் பிணைக்கிறது. 14.9",

    "சில நேரங்களில் நன்மை (சத்வம்) பேரார்வம் (ரஜஸ்) மற்றும் அறியாமையை (தமஸ்) மேலோங்குகிறது. ஓ பாரதத்தின் வாரிசான அர்ஜுனா! சில சமயங்களில் பேரார்வம் (ரஜஸ்) நன்மை (சத்வம்) மற்றும் அறியாமை (தமஸ்) மேல் ஆதிக்கம் செலுத்துகிறது, மற்ற நேரங்களில் அறியாமை (தமஸ்) நன்மை (சத்வம்) மற்றும் பேரார்வம் (ரஜஸ்) ஆகியவற்றை வெல்லும். 14.10",

    "இந்த உடலின் அனைத்து வாயில்களிலும் அறிவால் ஞான ஒளி பிரகாசிக்கப்படும்போது, ​​​​அது நல்வழியின் வெளிப்பாடு (சத்துவ குணம்)என்று அறிந்துக் கொள்ளுங்கள். 14.11",

    "அர்ஜுனா, பேராசை, உலகியலில் ஈடுபாடு, உலக ஆதாயத்திற்கான உழைப்பு, அமைதியின்மை மற்றும் ஏக்கம் இவை ரஜோ குணம் மிகுதிப்படும் போது தோன்றுகின்றன. 14.12",

    "ஓ அர்ஜுனா!அறியாமை - ஒளியின்மை,செயலற்ற தன்மை - முயற்சியின்மை, அலட்சியம் - தவறுதல் மற்றும் மாயை- மயக்கம் இவை அறியாமை (தமோ குணம்) மிகுதிப்படுவதிலிருந்துத் தோன்றுகின்றன. 14.13",

    "சத்வ குணம்  ஆதிக்கத்தில் உள்ளவர் இறப்பவர்களாயின்,   கற்றவர்களின் தூய இருப்பிடங்களை (ரஜஸ் மற்றும் தமஸம் இல்லாத உலகை) அடைகிறார்கள். 14.14",

    "பேரார்வம் அதிகமாகி (ரஜோ குணம்) இறப்பவர்கள் வேலையால் உந்தப்பட்ட  (கர்மப் பற்று) மக்களிடையே பிறக்கிறார்கள், அறியாமையால் (தமஸ் குணம்) இறப்பவர்கள் விலங்கு ராஜ்ஜியத்தில் பிறக்கிறார்கள். 14.15",

    "நன்மையின் வழியில் (சத்வ குணம்) செய்யப்படும் செயல்களின் பலன் தூய பலனைத் தரும் என்று கூறப்படுகிறது. பேரார்வத்தின் (ரஜோ குணம் ) வழியில் செய்யப்படும் செயல்கள் வலியை விளைவிக்கின்றன. அதே சமயம் அறியாமை (தமோ குணம்) முறையில் செய்யப்படுவது இருளில் விளைகிறது. 14.16",

    "நல்வழியிலிருந்து (சத்வ) அறிவும், பேரார்வத்திலிருந்து (ரஜோ) பேராசையும், அறியாமையிலிருந்து  (தமோ) அலட்சியமும் மாயையும் உருவாகின்றன. 14.17",

    "நன்மையின் குணத்தில் அமைந்தவர்கள் மேல்நோக்கி உயர்கின்றனர்; பேரார்வம் குணத்தில் உள்ளவர்கள் நடுவில் (மனித உலகம்) இருப்பார்கள்; மேலும் அறியாமை குணத்தில் இருப்பவர்கள் மிகவும் தாழ்ந்த குணங்களும் செயல்களும் உடையோராய்க் கீழ்நோக்கிச் செல்கின்றனர். 14.18",

    "புத்திசாலிகள் எல்லா வேலைகளிலும் மூன்று குணங்களைத் தவிர வேறு எந்த செயலும் இல்லை என்பதைக் கண்டு, இந்த குணங்களுக்கு அப்பாற்பட்டவர் என்று அவர்கள் அறிந்தால், அவர்கள் எனது தெய்வீக தன்மையை அடைகிறார்கள். 14.19",

    "உடலுடன் தொடர்புடைய ஜட இயற்கையின் மூன்று முறைகளைக் கடந்தவன் - பிறப்பு, இறப்பு, முதுமை, துன்பம் ஆகியவற்றிலிருந்து விடுபட்டு, அழியாமையைப் பெறுகிறார். 14.20",

    "அர்ஜுனன் வினவினான்: மூன்று குணங்களையும் கடந்தவர்களின் குணாதிசயங்கள் யாவை,ஓ பகவானே? அவர்கள் எப்படிச் செயல்படுகிறார்கள்? குணங்களின் அடிமைத்தனத்தைத் தாண்டி அவர்கள் எப்படிக் கடந்துச் செல்கிறார்கள்?. 14.21",

    "பரம தெய்வீக பகவான் கூறினார்: ஓ அர்ஜுனா! மூன்று குணங்களுக்கும் அப்பாற்பட்டவர்கள் வெளிச்சத்தையோ (சத்வத்தால் பிறந்தவர்கள்), செயலையோ (ராஜஸால் பிறந்தவர்கள்) அல்லது மாயையையோ (தமஸால் பிறந்தவர்கள்) கூட வெறுக்கமாட்டார்கள். இவை ஏராளமாக இருக்கும்போது, ​​அல்லது அவை இல்லாதபோது அவைகளுக்காக ஏங்கமாட்டார்கள். 14.22",

    "அவை இயற்கையின் முறைகளுக்கு நடுநிலை வகிக்கின்றன மற்றும் அவற்றால் தொந்தரவு செய்யப்படவில்லை. குணங்கள் மட்டுமே சுழல்கின்றன என்பதை அறிந்து, அசையாமல் தன்சுயத்தில்  நிலையாக நிற்பான். 14.23",

    "மகிழ்ச்சியிலும் துன்பத்திலும் ஒரே மாதிரியாக இருப்பவர்கள்; சுயத்தில் நிறுவப்பட்டவர்கள்; ஒரு கட்டையும், ஒரு கல்லையும், தங்கத் துண்டையும் சமமாகப் பார்ப்பவர்கள்; இனிமையான மற்றும் விரும்பத்தகாத நிகழ்வுகளுக்கு மத்தியில் ஒரே மாதிரியாக இருப்பவர்கள்; அறிவாளிகள்; பழி, பாராட்டு இரண்டையும் சமமாக ஏற்றுக்கொள்பவர்கள்; 14.24",

    "கெளரவத்திலும் - அவமதிப்பிலும் ஒரே மாதிரியான மனநிலையில் இருப்பவர்கள்; நண்பன்- பகைவன் இருவரையும் ஒரே மாதிரியாக நடத்துபவர்கள்; மற்றும் அனைத்து உலகப் பொருள்களையும்  துறந்தவர்கள்  அவர்கள் மூன்று குணங்களையும் விட உயர்ந்தவர்கள் என்றுக் கூறப்படுகிறார்கள். 14.25",

    "வேறுபாடில்லாத பக்தி யோகத்தால் என்னைச் சேவிப்பவர்கள், ஜட இயற்கையின் இம்மூன்று குணங்களைக் கடந்து, பிரம்மத்தை அடைவதற்குத் தகுதியானவர்கள். 14.26",

    "அழியாத உருவமற்ற பிரம்மம்  மற்றும் அழியாத நித்திய தர்மம் மற்றும் முடிவில்லாத தெய்வீகப் பேரின்பத்தின் அடிப்படையும் நானே ஆவேன். 14.27",

    "பிரம்ம வித்தையும் யோக சாஸ்திரமும் ஸ்ரீ கிருஷ்ணார்ஜுன சம்பாஷணையுமாகிய ஸ்ரீமத்பகவத்கீதை உபநிஷத்தில் \"குணத்ரயவிபாக  யோகம்\" என்ற பதினான்காம் அத்தியாயம் ஸம்பூர்ணம்."
  ],
  "adhyaya-15" : [
    "ஸ்ரீ பகவான் கூறுகிறார்: இந்தப் பிரபஞ்சம் அரசமரம் போல் ஆனது.ஆதி-புருஷனான பரமேஸ்வரன் வேராகவும் பிரம்மதேவன் முக்கிய நடுமரமாகவும் உருவமான இந்த பிரபஞ்ச அரசமரத்தை அழியாதது என கூறுகின்றேன். இந்த பிரபஞ்ச மரத்திற்கு வேதங்களே இலைகளாகும், ஸம்ஸாரம் (பிரபஞ்சம்)என்ற இந்த அரச மரத்தை எந்தபக்தன் வேருடன் சேர்த்துத் தத்துவரீதியாக அறிகிறாரோ அவரே வேதத்தின் உட்கருத்தை அறிந்தவர் ஆகிறார். 15.1",

    "இந்தப் பிரபஞ்சம் (ஸம்ஸாரம்) என்ற அரச மரத்தின் கிளைகள்  முக்குணங்கள் என்ற நீரினால் வளர்ந்து புலன் நுகர்ந்து புதிய தளிர்களுடன் கூடி, தேவர்- மனிதர்- விலங்கு , முதலிய பிறவி உருவாகி மேலும் கீழுமாக எங்கும் பரவியுள்ளன. அவ்வாறே அகந்தை, மமதை, வாசனை உருவான வேர்களும், மனிதப் பிறவியில் கர்மங்களுக்கு ஏற்றவாறு   பிணைக்கப்பட்டு மேலும் கீழுமாக எல்லா உலகங்களிலும் பரவியுள்ளன. 15.2",

    "இந்த ஸம்ஸார மரத்தின் ஸ்வரூபம் எவ்வாறு சொல்லப்பட்டதோ அவ்வாறு ஆராய்ந்தால் புலப்படுவதில்லை. ஏனெனில் அதற்கு ஆதியும் கிடையாது; அந்தமும் கிடையாது; நிலையான இருப்பும் கிடையாது. எனவே அகங்காரம், மமகாரம், முன்வினை, வாசனை என்ற உறுதியான வேர்களைக் கொண்ட இந்த ஸம்ஸார  உருவான அரச மரத்தை உறுதியான வைராக்கியம் என்ற கோடாரியால் வெட்டி எறிந்துவிடு. 15.3",

    "பக்தர்கள் எப்பொழுதும் பகவானின் பாதத்தை அடைய முயற்சி செய்ய வேண்டும், ஏனெனில்  பரமபதத்திற்கு சென்ற மனிதர்கள் மீண்டும் ஸம்சாரத்திற்கு திரும்பி வருவது இல்லை. அங்கு ஆதிபுருஷனான  நாராயணனையே சரணமடைகிறேன் என்ற உறுதியோடு அந்த பரமேஸ்வரனை சிந்தித்துத் தியானிக்க வேண்டும். 15.4",

    "தற்பெருமையும் மோகமும் அகன்றவர்கள்,  பற்று என்ற குறைபாட்டை வென்றவர்கள்,  பரமாத்ம ஸ்வரூபத்தில் என்றும் நிலை பெற்றவர்கள், ஆசைகளை முழுமையாக அழித்துக் கொண்டவர்கள், இன்பம் - துன்பம் என்ற பெயர் கொண்ட இரட்டைகளில் இருந்து விடுபட்டவர்கள் ஆகிய ஞானிகள், அழிவற்ற பரமபதத்தை அடைகிறார்கள். 15.5",

    "சூரியன், சந்திரன் மற்றும் அக்னிதேவனால் கூட அந்த பரமபதத்தை  ப்ரகாசிக்க இயலாது. அங்கு சென்றடைந்த பக்தர்கள் இந்த ஸம்ஸாரத்திற்கு திரும்புவது இல்லை. அது என்னுடைய மேலான இடம் - பரமபதம் ஆகும். 15.6",

    "உடலில் என்றும் உள்ள ஜீவாத்மா என்னுடைய அம்சமே. அதுவே பிரக்ருதியில் உள்ள மனம் மற்றும் ஐந்து புலன்களையும் ஈர்க்கிறது. 15.7",

    "மணமுள்ள இடத்தில் காற்று பலவிதமான மணங்களை கவர்ந்து செல்வது போல், உடலை ஆள்கின்ற ஜீவாத்மாவும் எந்த உடலை விட்டு கிளம்புகிறதோ,  அதிலிருந்து மனதோடு கூடிய புலன்களையும் எடுத்துக்கொண்டு புதிய உடலில் சேர்ந்து கொள்கிறது. 15.8",

    "இந்த ஜீவாத்மா கண், மூக்கு , செவி, நாக்கு , சருமம் - இவற்றுடன் மனதையும் சார்ந்து கொண்டு அவற்றின் உதவியுடன் தான் புலன் இன்பங்களை அனுபவிக்கின்றது. 15.9",

    "இந்த ஜீவனை (ஜீவாத்மாவை) உடலில் இருக்கும் போதும், உடலை விட்டு பிரியும் போதும், போகங்களை   அனுபவிக்கும் போதும், முக்குணங்களுடன் கூடி இருக்கும் போதும்  மனிதர்கள் எளிமையாக அறிவதில்லை. ஞானக்கண் பெற்றவர்களும் - விவேகமுள்ள ஞானிகளும் தான் -  தத்துவரீதியாக அறிகிறார்கள். 15.10",

    "கடும் முயற்சி செய்யும் யோகிகளும் இந்த ஜீவாத்மாவைத் தத்துவரீதியாக உணர்கிறார்கள்.ஆனால் தங்கள் உள்ளத்தைத் தூய்மைப்படுத்திக் கொள்ளாத அஞ்ஞானிகள் முயன்றாலும் கூட இந்த ஜீவாத்மாவை உணர்வதில்லை. 15.11",

    "சூரியனில் உள்ள எந்த ஒளி உலகனைத்தையும் பிரகாசிக்க செய்கிறதோ , மேலும் எந்த ஒளி சந்திரனிலும், நெருப்பிலும் உள்ளதோ அது என்னுடைய ஒளியே என்று தெரிந்து கொள். 15.12",

    "என் சக்தியினால் பூமியில் உள்ள உயிரினங்கள் அனைத்திற்கும் ஆதரவு தருகிறேன். அமுதமே நிறைந்த நிலவாகி அனைத்து பயிர்களையும் மரங்களையும் போஷிக்கிறேன். 15.13",

    "நானே எல்லா உயிரினங்களுடைய உடலில் உறைபவனாகி  பிராண -  அபான வாயுக்களுடன் கூடி வைஷ்வாநரன் என்ற அக்னியாகி, நான்கு வகையான உணவை ஜீரணிக்கிறேன். 15.14",

    "நானே எல்லா பிராணிகளுடைய இதயத்திலும் அந்தர்யாமியாக  இருக்கின்றேன்.என்னிடமிருந்து தான் நினைவும், அறிவும், ஐயமும் - தெளிவும் ஏற்படுகின்றன.வேதாந்தத்தைச் செய்தவனும், அதன் உட்பொருளை அறிந்தவனும் ,எல்லா வேதங்களாலும் அறியப்படுவதும் நானே ஆகும். 15.15",

    "இவ்வுலகில் அழியாதது - அழியக்கூடியது என்ற இரண்டு வகையான புருஷ தத்துவங்கள் இருக்கின்றன. இதில் எல்லா உயிரினங்களின்  உடல் அனைத்தும் அழியக்கூடியது என்றும்; நம்முள் இருக்கின்ற ஜீவாத்மாவை அழிவற்றது என்றும் கூறப்படுகின்றது. 15.16",

    "எவர் மூன்று உலகங்களுக்கும் சென்று எல்லோரையும் போஷிக்கிறாரோ, அழிவற்ற - பரமேஸ்வரன் - பரமாத்மன் என்றும் அழைக்கப்படுகிறாரோ அந்த புருஷோத்தமன், இந்த க்ஷர  - அக்ஷர புருஷர்களிடமிருந்து வேறுபட்டவர் ஆவார். 15.17",

    "நான் (க்ஷர) அழியக்கூடிய ஜட வர்க்கத்திற்கு அப்பாற்பட்டவனாகவும்,  (அக்ஷரனான) அழியாத ஜீவாத்மாவைக் காட்டிலும் சிறந்தவனாகவும்  இருக்கின்றேன். எனவே உலகிலும் வேதத்திலும் புருஷோத்தமன் என்கிற முழுமுதற் கடவுள் என்றும் அறியப்படுகின்றேன். 15.18",

    "பரத குலத்தோன்றலே அர்ஜுனா! என்னை புருஷோத்தமன் என்று தத்துவரீதியாக எந்த ஞானிகள் அறிகிறார்களோ, அவர்கள் எப்போதும் வாசுதேவனும் பரமேஸ்வரனுமான  என்னையே வழிபடுகிறார்கள். 15.19",

    "அர்ஜுனா! இவ்வாறு மிகவும் ரகசியமாக மறைத்துப் போற்றத்தக்க  இந்த சாஸ்திரம் என்னால் கூறப்பட்டுள்ளது. இதை தத்துவரீதியாக அறிந்து கொண்ட மனிதர்கள் ஞானிகளாகவும் தன்  வாழ்க்கையின் இலக்கை அடைந்தவர்களாகவும் ஆகிறார்கள். 15.20",

    "பிரம்ம வித்தையும் யோக சாஸ்திரமும் ஸ்ரீ கிருஷ்ணார்ஜுன சம்பாஷணையுமாகிய ஸ்ரீமத்பகவத்கீதை உபநிஷத்தில் \"புருஷோத்தம யோகம்\" என்ற பதினைந்தாம் அத்தியாயம் ஸம்பூர்ணம்."
  ],
  "adhyaya-16" : [
    "ஶ்ரீ பகவான் கூறுகிறார்: அர்ஜுனா, இவை தெய்வீக குணம் கொண்டவர்களின் புனித நற்பண்புகள் - அச்சமின்மை, மனத்தூய்மை, தத்வஞானம் பெற த்யான யோகத்தில் உறுதி, ஸாத்வீகமான தானம்(ஈகை), தொண்டு, வேள்விகள் மற்றும் நற்கர்மங்களைக் கடைப்பிடித்தல், புலன்களைக் கட்டுப்படுத்துதல், தியாகம், வேதங்களையும்- சாஸ்திரங்களையும் கற்றல், கற்பித்தல், தவம் போல் பகவானுடைய நாம -குணகீர்த்தனம் செய்தல், தர்மத்தைக் கடைப்பிடிப்பதில் ஏற்படும் துன்பங்களைப் பொறுத்தல், மனம்  புலன் கட்டுப்பாட்டுடன் நேர்மையான வழியில் நடத்தல் 16.1",

    "மேலும், அகிம்சை, உண்மை, கோபம் இல்லாமை, பற்று அற்ற வாழ்வு, அமைதி, தவறுகளைக் கண்டறிதல், எல்லா உயிர்களிடத்தும் இரக்கம், பேராசை இல்லாமை, மென்மை, அடக்கம், மற்றும் நிலையற்ற தன்மை இல்லாமை, இவை தெய்வீக குணம் கொண்டவர்களின் புனித நற்பண்புகள். 16.2",

    "வீரியம், மன்னிப்பு, துணிவு, தூய்மை, யாரிடமும் பகைமை கொள்ளாமல் இருத்தல், வீண் தன்மை இல்லாதிருத்தல், இவையெல்லாம் அர்ஜுனா !தெய்வீகத்தன்மையுடன்( சம்பத்துடன்)   பிறந்தவர்களின் குண- இலக்கணங்களாகும் . 16.3",

    "ஓ அர்ஜுனா: டம்பம் - பகட்டான கபடம், ஆணவம், அகந்தை, கோபம், கடுமை, அஞ்ஞானம், இவையெல்லாம் அசுர குணம் கொண்டவர்களின் குணங்கள். 16.4",

    "தெய்வீக குணங்கள் மோட்சத்திற்கு வழிவகுக்கின்றன, அதே சமயம் அசுர குணங்கள் பந்தத்திற்கு காரணமாகி அடிமைத்தனத்தின் தொடர்ச்சியான விதிக்கு காரணமாகும். ஓ அர்ஜுனா, நீ தெய்வீக குணங்களும் நற்பண்புகளுடனும் பிறந்ததால் வருந்தாதே. 16.5",

    "இவ்வுலகத்தில் இரண்டு வகையான உயிரினங்கள் உள்ளன - தெய்வீக குணம் கொண்டவை மற்றும் அசுர குணம் கொண்டவை. ஓ பார்த்தா! தெய்வீக குணங்களை விரிவாக விவரித்துள்ளேன். இப்போது அசுர குணத்தைப் பற்றி சொல்லுகிறேன் கேள். 16.6",

    "அசுர குணம் கொண்டவர்கள் எந்தச் செயல்கள் சரியானவை, எது முறையற்றவை என்பதைப் புரிந்து கொள்ள மாட்டார்கள். எனவே, அவர்களிடம் தூய்மையோ, நல்ல நடத்தையோ, உண்மையோ காணப்படுவதில்லை. 16.7",

    "அவர்கள் கூறுகிறார்கள், “உலகம் முழுமையான உண்மை இல்லாதது, எந்த அடிப்படையும் (தார்மீக ஒழுங்குக்கு), மற்றும் கடவுள் இல்லாமல் (அதை உருவாக்கிய அல்லது கட்டுப்படுத்தும்). இது இரண்டு பாலினங்களின் கலவையிலிருந்து உருவாக்கப்பட்டது, மேலும் புலன்களின் திருப்தியைத் தவிர வேறு எந்த நோக்கமும் இல்லை. 16.8",

    "இத்தகைய பார்வைகளை உறுதியாகப் பிடித்துக் கொண்டு, இந்த தவறான ஆன்மாக்கள், சிறிய புத்தி மற்றும் கொடூரமான செயல்களால், உலகின் எதிரிகளாக எழுகின்றனர், அதன் அழிவை அச்சுறுத்துகின்றனர். 16.9",

    "பாசாங்குத்தனம், பெருமை மற்றும் ஆணவம் நிறைந்த, தணியாத காமத்தை அடைந்து, அஞ்ஞானத்தினால் தங்கள் தவறான கொள்கைகளில் ஒட்டிக்கொள்கின்றனர். இவ்வாறு மாயை அடைந்து, அவர்கள் நிலையற்றவற்றில் ஈர்க்கப்பட்டு தூய்மையற்ற உறுதியுடன் செயல்படுகின்றனர்.  16.10",

    "மரணத்துடன் மட்டுமே முடிவடையும் முடிவில்லாத கவலைகளால் அவர்கள் தீவிரமாக இருக்கிறார்கள். இருப்பினும், ஆசைகளை திருப்திப்படுத்துவதும், செல்வத்தை குவிப்பதும்தான் வாழ்க்கையின் உயர்ந்த நோக்கம் என்பதை அவர்கள் முழு உறுதியுடன் கடைப்பிடிக்கின்றனர். 16.11",

    "நூற்றுக்கணக்கான ஆசைகளால் அடிமைப்பட்டு, காமம் மற்றும் கோபத்தால் உந்தப்பட்டு, அவர்கள் தங்கள் புலன்களின் திருப்திக்காக அநியாயமான வழிகளில் செல்வத்தைக் குவிக்க முயல்கிறார்கள். 16.12",

    "அசுர தன்மை பிடித்தவர்கள் நினைக்கிறார்கள், “இன்று நான் இவ்வளவு செல்வம் சம்பாதித்துவிட்டேன், இப்போது என்னுடைய இந்த ஆசையை நிறைவேற்றுவேன். இது என்னுடையது, நாளை எனக்கு இன்னும் அதிகமாக இருக்கும். 16.13",

    "அந்த எதிரி என்னாலேயே அழிக்கப்பட்டு விட்டான், மற்றவர்களையும் அழிப்பேன்! நான் கடவுளைப் போல் இருக்கிறேன், நான் அனுபவிப்பவன், நான் பரிபூரணமானவன், நான் சக்தி வாய்ந்தவன், நான் மகிழ்ச்சியாக இருக்கிறேன். 16.14",

    "நான் பணக்காரன், எனக்கு உறவினர்கள் உயர் பதவியில் உள்ளனர். எனக்கு நிகரானவர் வேறு யார்? நான் (வான தெய்வங்களுக்கு) யாகங்களைச் செய்வேன்; நான் தானம் கொடுப்பேன்; நான் இன்பத்தில் திழைப்பேன். இதன் மூலம் அஞ்ஞானத்தினால் மயக்கமுற்று ஏமாந்து விடுகிறார்கள். 16.15",

    "இத்தகைய கற்பனைகளால் ஆட்கொள்ளப்பட்டு, வழிதவறி, மாயையின் வலையில் சூழப்பட்டு, புலன் இன்பங்களின் திருப்திக்கு அடிமையாகி, அவர்கள் இருண்ட நரகத்தில் இறங்குகிறார்கள். 16.16",

    "இப்படிப்பட்ட தன்னம்பிக்கையும், பிடிவாதமும் கொண்டவர்கள், தங்கள் செல்வத்தில் பெருமிதமும் கர்வமும் கொண்டவர்கள், சாஸ்திர விதிகளை பொருட்படுத்தாமல், பெயருக்கு மட்டுமே ஆடம்பரமான யாகங்களைச் செய்கிறார்கள். 16.17",

    "அகங்காரம், வலிமை, ஆணவம், ஆசை, கோபம் போன்றவற்றால் கண்மூடித்தனமான இந்த அசுர தனம் உள்ளவர்கள் தங்கள் உடலிலும் பிறர் உடலிலும் அந்தர்யாமியாக இருக்கும் என்னைத் வெறுக்கின்றனர். 16.18",

    "இந்த கொடூரமான மற்றும் வெறுக்கத்தக்க மனிதர்கள், மனிதகுலத்தின் இழிவான மற்றும் தீய மனிதர்கள், நான் ஜட உலகில் மறுபிறப்பு சுழற்சியில் ஒரே மாதிரியான அசுர இயல்புகளைக் கொண்டவர்களின் கருவில் தொடர்ந்து சுழல விடுகிறேன். 16.19",

    "இந்த அறியா ஆத்மாக்கள் மீண்டும் மீண்டும் அசுர இயல்புள்ளவர்களின் கருப்பையில் பிறக்கின்றன. ஓ அர்ஜுனா, என்னை அடையத் தவறினால், அவர்கள் படிப்படியாக மிகவும் அருவருக்கத்தக்க வகையிலான இருப்பில் தாழ்ந்த கோரமான நரகங்களில் மூழ்கிவிடுகிறார்கள். 16.20",

    "ஆன்மாவின் சுய அழிவின் நரகத்தில் மூன்று வாயில்கள் உள்ளன - காமம், கோபம் மற்றும் பேராசை. எனவே, மூன்றையும் கைவிட வேண்டும். 16.21",

    "ஓ கௌன்தேயா! ( குந்தியின் மகனான அர்ஜுனன்) மூன்று வாசல்களின் இருளில் இருந்து விடுபட்டவர்கள் தங்கள் ஆன்மாவின் நலனுக்காக முயற்சி செய்கிறார்கள், அதன் மூலம் உயர்ந்த இலக்கை அடைகிறார்கள் அதாவது என்னை அடைகிறார்கள். 16.22",

    "வேதாகமத்தின் கட்டளைகளையும் சாஸ்திர முறையையும் துறந்து ஆசையின் தூண்டுதலின் கீழ் செயல்படுபவர்கள், வாழ்க்கையில் முழுமையையோ, மகிழ்ச்சியையோ, உயர்ந்த இலக்கையோ அடைவதில்லை அதாவது என்னை அடைவதில்லை. 16.23",

    "எனவே, எதைச் செய்ய வேண்டும், எதைச் செய்யக்கூடாது என்பதைத் தீர்மானிப்பதில் வேதமும் சாஸ்திரமும் உங்கள் ஆதாரமாக இருக்கட்டும். வேத கட்டளைகளையும் போதனைகளையும் புரிந்து கொண்டு, அதன்பின் இவ்வுலகில் உங்கள் செயல்களைச் செய்யுங்கள். 16.24",

    "பிரம்ம வித்தையும் யோக சாஸ்திரமும் ஸ்ரீ கிருஷ்ணார்ஜுன சம்பாஷணையுமாகிய ஸ்ரீமத்பகவத்கீதை உபநிஷத்தில் \"தெய்வாஸுர ஸம்பத்விபாக யோகம்\" என்ற பதினாறாம் அத்தியாயம் ஸம்பூர்ணம்."
  ],
  "adhyaya-17" : [
    "அர்ஜுனன் கூறுகிறார்: ஓ கிருஷ்ணா, சிலர் சாஸ்திர விதியை மீறி கட்டளைகளை மதிக்காமல்,  வெறும் நம்பிக்கையுடன் மட்டுமே வழிபடுபவர்களுக்கு என்ன நிலை கிடைக்கிறது? அவர்களின் நம்பிக்கை நன்மையா( சத்துவ குணம்), பேரார்வம்மா (ரஜோ குணம்), அல்லது அறியாமையா (தாமஸ குணம்) மேலே குறிப்பிட்ட எவ்வகையை சேர்ந்தது? 17.1",

    "ஶ்ரீ பகவான் கூறுகிறார்: ஒவ்வொரு மனிதனும் உள்ளார்ந்த நம்பிக்கையுடன் பிறக்கிறான், அது மூன்று வகைகளாக இருக்கலாம் - சாத்விகம், ராஜசம் அல்லது தாமச குணம். இப்போது இதைப் பற்றி நான் கூறுகிறேன்அதைக் கேள் அர்ஜுனா! 17.2",

    "ஓ பரதா! (பாரதத்தின் புதல்வனான அர்ஜுனன்) எல்லா மனிதர்களுக்கும் தத்தம் உள்ளியல்புக்கு  ஒத்தபடியாகவே நம்பிக்கை அமைகிறது. மனிதன் சிரத்தை மயமானவன் மனிதரின் நம்பிக்கைத் தன்மை எதுவாக இருக்கிறதோ அவர்கள் அந்த தன்மை உடையவர்களே. 17.3",

    "நல்வழியில் (சத்துவ குணம்) இருப்பவர்கள் விண்ணுலகக் கடவுள்களான தேவர்களை வணங்குகிறார்கள்; மோகம் உள்ளவர்கள் (ரஜோ குணம்) யக்ஷர்களையும் ராக்ஷஸர்களையும் வழிபாடு செய்கிறார்கள் அறியாமை  (தாமஸ குணம்) முறையில் இருப்பவர்கள் பேய்களும் ஆவிகளுமான பிரேத பூதங்களை ஆராதனை செய்கிறார்கள். 17.4",

    "சிலர் வேதங்களால் கட்டளையிடப்படாத கடுமையான துறவறங்களைச் செய்கிறார்கள். இது அவர்களுடைய பாசாங்குத்தனம், பேராசை, வலிமை மற்றும் அகங்காரத்தால் தூண்டப்படுவதால் செய்கிறார்கள். 17.5",

    "ஆசை மற்றும் பற்றுதலால் தூண்டப்பட்டு, அவர்கள் தங்கள் உடலின் புலன்களை மட்டுமல்ல, அவர்களுக்குள் பரமாத்மாவாக வசிக்கும் என்னையும் துன்புறுத்துகிறார்கள். இந்த புத்தியில்லாதவர்களை அசுரத்தனமான தாமஸ குணம் உள்ளவர்கள் என்று தீர்மானமாக அறிந்து கொள். 17.6",

    "ஒவ்வொருவருக்கும் பிரியமான உணவு அவர்கள் தன்மை பொருத்து மூன்று வகைப்படுகிறது. வேள்வியும், தவமும்  ,தானமும் அவர்களின் தன்மை சார்ந்து வகைப்படுகின்றன. அவற்றின் வேற்றுமையைப்  பற்றி கூறுகிறேன் கேள். 17.7",

    "நல்வழியில் இருப்பவர்கள் (சத்வ குணம்) ஆயுட்காலத்தை மேம்படுத்தும் மற்றும் நல்லொழுக்கம், வலிமை, ஆரோக்கியம், மகிழ்ச்சி மற்றும் மனநிறைவு ஆகியவற்றை அதிகரிக்கும் உணவுகளை விரும்புகிறார்கள். இத்தகைய உணவுகள் சத்துக்கள் நிறைந்தாகவும், சதைப்பற்றுள்ளதாகவும், ஊட்டமளிப்பதாகவும், உடலில் நீண்ட நேரம் தங்குபவையாகவும் இயற்கையாகவே சுவையாகவும் இருக்கும். 17.8",

    "மிகவும் கசப்பான, அதிகப்புளிப்பான, உப்பு, மிகவும் சூடான, காரமான, உலர்ந்த மற்றும் மிளகாய் நிறைந்த உணவுகள், ஆர்வமுள்ள (ராஜச குணம்) நபர்களுக்கு மிகவும் பிடித்தமானவை. இத்தகைய உணவுகள் துயரையும், துன்பத்தையும் மற்றும் நோயையும் உருவாக்குகின்றன. 17.9",

    "அறியாமை (தாமஸ குணம்) முறையில் உள்ளவர்களுக்கு அதிகமாக சமைக்கப்பட்டது, பழையது, அழுகியது, துர்நாற்றமுடையது, சுவையற்றது மற்றும் தூய்மையற்ற உணவுகள் விரும்பத்தக்கவையாக இருக்கும். 17.10",

    "பலன்களை எதிர்பாராமல், கடமை என்ற உறுதியுடன் மன உறுதியுடன், வேத விதிகளின்படி செய்யப்படும் யாகம், நற்குணத்தின் (சத்வ குணம்) இயல்புடையது. 17.11",

    "ஓ பரதா! (பாரதர்களில் சிறந்தவரே அர்ஜுனா), பௌதிக நலனுக்காக அல்லது பாசாங்குத்தனமான நோக்கத்துடன் செய்யப்படும் யாகம் பேரார்வத்தின் (ராஜச குணம்) முறையில் உள்ளது என்பதை அறிந்து கொள்ளுங்கள். 17.12",

    "நம்பிக்கை இல்லாத, வேத - சாஸ்திர கட்டளைகளுக்கு மாறாக, எந்த உணவையும் பிறருக்கு வழங்காத, மந்திரங்களை உச்சரிக்காத, தானம் செய்யாத தியாகம் அறியாமை (தாமஸ குணம்) முறையில் கருதப்பட வேண்டும். 17.13",

    "எப்பொழுது பரமாத்மா, பிராமணர்கள், ஆன்மிக குருக்கள், ஞானிகள், பெரியோர்கள் ஆகியோரை வழிபடும்போது தூய்மை, எளிமை,நேர்மை, பிரம்மச்சரியம், அஹிம்சை ஆகியவற்றைக் கடைப்பிடிக்கிறார்களோ அந்த வழிபாடு உடலின் துறவறம் (சத்வ குணம்) என்று அறிவிக்கப்படுகிறது. 17.14",

    "துன்பத்தை ஏற்படுத்தாத வார்த்தைகள், உண்மை சொற்கள், புண்படுத்தாத மற்றும் நன்மை பயக்கிற வார்த்தைகள் , அத்துடன்  வேத சாஸ்திரங்களைத் தொடர்ந்து ஓதுதல் - இவை எளிமையான பேச்சு என அறிவிக்கப்படுகின்றது. 17.15",

    "சிந்தனையின் அமைதி, மென்மை, மௌனம்(எப்பொழுதும் பகவானை நினைவு கொள்வது), சுயக்கட்டுப்பாடு மற்றும் எண்ணத்தில் தூய்மை - இவை அனைத்தும் மனதின் கட்டுப்பாட்டாய் அறிவிக்கப்படுகின்றது. 17.16",

    "பொருள் பலன்களுக்காக ஏங்காமல் தீவிர நம்பிக்கை கொண்ட பக்தர்கள், இந்த மும்மடங்கு துறவறங்களைக் கடைப்பிடிக்கும்போது, ​​அவர்கள் நற்குணத்தின் முறையில் துறவு சாத்விகமானது என்று குறிப்பிடப்படுகின்றது. 17.17",

    "மதிப்பு, மரியாதை, சுயநலம் மற்றும் வணக்கத்தைப் பெறுவதற்காக ஆடம்பரத்துடன் செய்யப்படும் தீவிரமான கார்யங்கள் வெறும் உணர்ச்சியின் முறையில் செய்யப்படுவது. அதன் நன்மைகள் நிலையற்றவை மற்றும் உறுதியற்றவையாக இருக்கும். இது ராஜச குணத்தை சேர்ந்தது. 17.18",

    "குழப்பமான கருத்துக்களைக் கொண்டவர்களால் மேற்கொள்ளப்படும் கடுமையான செயல்களான தன்னைத் துன்புறுத்துவது அல்லது பிறருக்கு தீங்கு விளைவிப்பது ஆகியவை அறியாமையின் (தாமஸ குணம்) பயன்முறையில் விவரிக்கப்படுகிறது. 17.19",

    "ஒரு தகுதியான நபருக்கு கொடுத்தல் கடமையென்று கருதி வழங்கப்படும் தொண்டு, சரியான நேரத்தில் மற்றும் சரியான இடத்தில், தமக்கு உபகாரம் செய்யாதவர் ஆயினும் பிரதிபலனாக எதையும் கருத்தில் கொள்ளாமல், கொடுப்பது சரியானது என்பதால், அது நன்மையின் (சாத்விகம்) முறையில் கூறப்படுகிறது. 17.20",

    "ஆனால் தயக்கத்துடன், திரும்ப கிடைக்கும் என்ற நம்பிக்கையுடன் அல்லது வெகுமதியை எதிர்பார்த்து செய்யப்படும் தொண்டு, ராஜசீக உணர்ச்சியின் முறையில் கூறப்படுகிறது. 17.21",

    "தகுதியற்ற நபர்களுக்கு தவறான இடத்திலும், தவறான நேரத்திலும், மரியாதை காட்டாமல் அல்லது இகழ்ச்சியுடன் கொடுக்கப்படும் அந்தத் தொண்டு, அநாகரீகமான தாமஸ குணம் என கருதப்படுகிறது. 17.22",

    "\"ஓம் தத் ஸத்\" என்ற வார்த்தைகள், படைப்பின் தொடக்கத்திலிருந்தே, உச்ச முழுமையான உண்மையின் அடையாளப் பிரதிநிதிகளாக அறிவிக்கப்பட்டுள்ளன. அவர்களிடமிருந்தே ஆசார்யர்களும், வேதங்களும், த்யாகமும், வேள்விகளும் வகுக்கப்பட்டன . 17.23",

    "எனவே, யாகம் செய்யும்போதும் , ​​தானம் செய்யும்போதும் அல்லது தவம் செய்யும்போதும், ​​வேதங்களை விளக்குவோர் எப்போதும் வேத கட்டளைகளின்படி “ஓம்” என்று உச்சரிப்பதன் மூலம் தொடங்குகிறார்கள். 17.24",

    "பலனளிக்கும் வெகுமதிகளை விரும்பாதவர்கள், ஆனால் ஸ்தூல பொருள் சிக்கல்களிலிருந்து விடுபட முற்படுபவர்கள், வெற்றுதன்மை, தியாகம் மற்றும் கருணைமிக்க  செயல்களுடன் மோக்ஷத்தை விரும்புவோரால் \"தத்\" என்ற வார்த்தை உச்சரிக்கப்படுகின்றது. 17.25",

    "\"ஸத்\" என்ற வார்த்தைக்கு நித்திய இருப்பு மற்றும் நன்மை என்று பொருள். ஓ பார்தா! (ப்ரிதாவின் மகனான அர்ஜுனா) , இது ஒரு மங்களம் தரும் நல்ல செயலை விவரிக்கவும் பயன்படுத்தப்படுகிறது. 17.26",

    "தியாகம், தவம் மற்றும் தானம் ஆகியவற்றின் செயல்பாட்டில் உறுதி நிலை \"ஸத்\" என்ற வார்த்தையால் விவரிக்கப்படுகிறது. எனவே அத்தகைய பிரம்மத்தின் நோக்கங்களுக்காக செய்யும் எந்தவொரு செயலும் \"ஸத்\" என்று பெயரிடப்படுகிறது. 17.27",

    "ஓ ப்ரிதாவின் மகனே அர்ஜுனா நம்பிக்கையின்றி செய்யப்படும் தியாகம், தானம் அல்லது தவம் எதுவோ அதுவே “அஸத்” எனப்படும். அவை இம்மையிலும் பயனற்றவை. மறுமையிலும் பயனற்றவை. 17.28",

    "பிரம்ம வித்தையும் யோக சாஸ்திரமும் ஸ்ரீ கிருஷ்ணார்ஜுன சம்பாஷணையுமாகிய ஸ்ரீமத்பகவத்கீதை உபநிஷத்தில் \"ஶ்ரத்தாத்ரயவிபாக  யோகம்\" என்ற பதினேழாம் அத்தியாயம் ஸம்பூர்ணம்."
  ],
  "adhyaya-18" : [
    "அர்ஜுனன் கேட்கிறார்-  “நீண்ட புஜங்கள் உடையவரே! அந்தர்யாமியே! கேசியை வதைத்த வாசுதேவனே! ஸன்யாசம், தியாகம் – இவை பற்றிய தத்துவங்களைத் தனித்தனியாக அறிந்து கொள்ள விரும்புகிறேன். 18.1",

   "ஸ்ரீ பகவான் கூறுகிறார் -  “பண்டிதர்கள், விரும்பிய பொருளைப் பெறுவதற்காகச் செய்யப்படும் காம்ய கர்மங்களைத் துறத்தலை, ஸன்யாசம் என்று அறிகிறார்கள்,  மற்றும் சில ஆராய்ச்சியாளர்கள் கர்மங்கள் அனைத்தினுடைய பயனையும் துறத்தலையே தியாகம் என்று கூறுகிறார்கள். 18.2",

   "சில அறிஞர்கள்,  கர்மங்கள் அனைத்தும் குற்றமுடையவை. ஆகவே, துறக்கப்பட வேண்டியவை என்று சொல்கிறார்கள்.  இன்னும் சிலர் வேள்வி, தானம், தவம் என்கிற கர்மங்கள் துறக்கத்தகுந்தன அல்ல என்று கூறுகிறார்கள். 18.3",

   "மனிதர்களில் சிறந்தவனே! அர்ஜுனா! ஸன்யாசம், தியாகம் -  இவை இரண்டிலும் முதலில் தியாகத்தைப் பற்றி,  நீ என்னுடைய கொள்கையைக் கேள். ஏனெனில், தியாகம் சாத்வீகம், ராஜசம், தாமசம் என்ற பிரிவுகளோடு மூன்றுவிதமாகக் கூறப்பட்டுள்ளது. 18.4",

   "வேள்வி, தானம், தவம் ஆகிய கர்மங்கள் துறக்கத்தக்கனவல்ல. அவை கட்டாயம் செய்யப்பட வேண்டியவை தான். ஏனெனில் வேள்வி, தானம், தவம் – இம்மூன்றுமே அறிவுடையவர்களைப் பரிசுத்தப்படுத்தக் கூடியன. 18.5",

   "ஆகையால் பார்த்தனே! இந்த வேள்வி, தானம், தவம் என்ற கர்மங்களும், அப்படியே மேலும் செய்ய வேண்டிய கடமைகளும், பற்றையும் பயன்களையும் தியாகம் செய்து கட்டாயமாகச் செய்யப்பட வேண்டியன என்பது என்னுடைய தீர்மானிக்கப்பட்ட உயர்ந்த கொள்கை. 18.6",

   "( விலக்கப்பட்ட கர்மங்களும், பலனை எதிர்பார்த்துச் செய்யப்படும் கர்மங்களும் துறக்கப்பட வேண்டியனவே.) ஆனால், விதிக்கப்பட்ட கர்மத்தைச் செய்யாமல் விடுதல் தகாது.  ஆகையால், அறியாமையினால் அதைத் தியாகம் செய்தல் தாமசத் தியாகம் என்று சொல்லப்படுகிறது. 18.7",

   "எது செய்யவேண்டிய  கர்மமோ, அதைத் துன்பம் என்றே நினைத்து உடல் வருந்துமோ என்ற பயத்தினால் ஒருவன் செய்யாமல் விடுவானேயானால், அவன் ராஜஸத் தியாகத்தைச் செய்து, தியாகத்திற்குரிய பயனை அடையவே மாட்டான். 18.8",

   " அர்ஜுனா! சாஸ்திரங்களில் விதிக்கப்பட்ட எந்தக் கர்மம் கட்டாயம் செய்யப்படத்தான் வேண்டும் என்று கருதிப்  பற்றையும் பயனையும் விடுத்து செய்யப்படுகிறதோ, அதுவே சாத்வீகமான தியாகம் என்று மதிக்கப்படுகிறது. 18.9",

   "எந்த மனிதன் நன்மை பயக்காத கர்மத்தை வெறுப்பதில்லையோ, மேலும் நன்மை பயக்கக்கூடிய கர்மத்தில் பற்றும் கொள்வதில்லையோ அந்த சுத்தமான சத்துவ குணம் நிறைந்தவன் தான் சந்தேகம் நீங்க  பெற்றவனும் அறிஞனும் உண்மையான தியாகியும் ஆவான். 18.10",

   "உடலபிமானமுடையவனால் முற்றிலும் கர்மங்களை தியாகம் செய்ய முடியாது ஆகையால் எவன் கர்ம பலனை விடுகிரானோ அவனே தியாகி என்று சொல்லப்படுகிறான். 18.11",

   "கர்மத்தின் பயனைத் துறக்காத மனிதர்களுக்குக் கர்மத்தினுடைய நல்லதும் தீயதும் மேலும் இரண்டும் கலந்ததுமான மூன்று விதமான பயன் இறந்தபின் கட்டாயமாகக் கிடைக்கிறது. ஆனால் கர்மபயனைத் துறந்த மனிதர்களுக்குக் கர்மங்களுடைய பயன் ஒருபோதும் ஏற்படாது. 18.12",

   "அகன்ற தோள் படைத்தவனே! எல்லாக் கர்மங்களும் நிறைவேறுவதற்கு இந்த ஐந்து காரணங்கள் கர்மங்களின் முடிவிற்கான உபாயத்தை கூறும் ஸாங்க்ய சாஸ்திரத்தில் கூறப்பட்டன. அவற்றை நீ என்னிடமிருந்து நன்கு அறிந்து கொள். 18.13",

   "கர்மங்களின் ஸித்தியைப் பெறும் விஷயத்தில் கர்மம் செய்கிற உடலும்,  செய்கின்றவனும், தனித்தனியாக இந்திரியங்களும் அதன் உபகரணங்களும், பலவிதமான தனித்தனியான செயல்களும், அவ்வாறே ஐந்தாவதாக தெய்வமும் காரணமாகும். 18.14",

   "மனிதன் மனம் - வாக்கு - உடலினால் சாஸ்திரங்களுக்கு இசைந்தவாறோ அல்லது அதற்கு முரணாகவோ எந்தச் செயலைச் செய்கிறானோ, அதற்கு இந்த ஐந்தும் காரணங்கள். 18.15",

   "ஆனால் இங்ஙனம் இருக்க, எந்த மனிதன் புத்தியில் தூய்மை இல்லாமையினால் கருமங்களைச் செய்வதில் தூயதான ஆத்மாவையே செய்பவனாகக் காண்கிறானோ, அந்தத் தூய்மையற்ற புத்தியுடைய அஞ்ஞானி உண்மையைப் புரிந்து கொள்ளவில்லை. 18.16",

   "எந்த மனிதனுடைய உள்ளத்தில்  நான் செய்கிறேன் என்ற எண்ணம் இல்லையோ அவ்வாறே எவனுடைய புத்தி உலகியல் பொருட்களிலும் செயல்களிலும் ஒட்டுவதில்லையோ, அவன் இவ்வுலகங்கள் அனைத்தையும் (மக்கள் அனைவரையும்) கொன்றாலும் உண்மையில் கொல்வதில்லை. மேலும் பாவத்தினால் தள்ளப்படுவதும் இல்லை. 18.17",

   "அறிபவன், அறிவு, அறியப்படுவது என்ற மூன்றும் (ஒன்று சேர்ந்து) கர்மத்தைத் தூண்டுவன. மேலும் செயல் புரிபவன்,  கரணம் (மனம் புத்தி, புலன்கள்),  செயல் என்ற மூன்றும் கர்மத்தின் தொகுப்பு (அதாவது, இம்மூன்றும் ஒன்று சேர்வதாலேயே கர்மம் உண்டாகிறது). 18.18",

   "குணங்கள் எண்ணிக்கையைக் கூறும்  ஸாங்க்ய சாஸ்திரத்தில் ஞானமும் செயலும் செய்பவனும் குணங்களின் வேற்றுமையினால் மும்மூன்றாகவே சொல்லப்படுகின்றன. அவற்றையும் நீ என்னிடமிருந்து உள்ளவாறு கேட்டுக் கொள். 18.19",

   "எந்த ஞானத்தினால் மனிதன் தனித்தனியான உயிரினங்கள் அனைத்திலும் ஒன்றேயானதும் அழிவற்றதுமான பரமாத்மாவின் இருப்பைப் பிரிவற்றதாகப் (எங்கும் சமமாக உள்ளதாக) பார்க்கிறானோ, அந்த ஞானத்தைச் சாத்வீகம் என்று அறிவாயாக. 18.20",

   "ஆனால்,  எந்த ஞானத்தின் மூலமாக மனிதன் உயிரினங்கள் அனைத்திலும் தனித்தனியான, விதவிதமான இருப்புகளைத் தனித்தனியாக அறிகிறானோ, அந்த ஞானத்தை ராஜஸம் என்று அறிந்துகொள். 18.21",

   "ஆனால்,  பிரகிருதி காரியத்தின் விளைவான உடல் ஒன்றையே அனைத்தும் என்று பற்றுக் கொண்டுள்ள எந்த ஞானம் உண்டோ, அது யுக்திக்குப் பொருந்தாதது; உண்மைப் பொருளை உணர்த்தாதது; அற்பமானது. ஆகவே, அந்தக் கருத்து தாமஸம் என்று கூறப்படுகிறது. 18.22",

   "எந்தச் செயல் சாஸ்திரங்களில் விதிக்கப்பட்டுள்ளதோ, ‘நான் செய்கிறேன்’  என்ற அபிமானமின்றிப் பயனில் விருப்பம் கொள்ளாத மனிதனால் விருப்பு-வெறுப்பின்றிச் செய்யப்படுகிறதோ, அச்செயல் சாத்வீகம் என்று கூறப்படுகிறது. 18.23",

   "ஆனால், எந்தச் செயல் கடுமையான உழைப்பினால் நிறைவேறக் கூடியதோ, அவ்வாறே போகப் பயனில் கருத்துடையவனாலோ அல்லது அகங்காரமுடையவனாலோ செய்யப்படுகிறதோ, அந்தச் செயல் ‘ராஜஸம்’ எனப்படுகிறது. 18.24",

   "எந்த செயல்  விளைவையும் இழப்பையும் துன்பத்தையும் திறமையையும் ஆராயாமல் வெறும் அஞ்ஞானத்தினால் தொடங்கப்படுகிறதோ, அந்தச் செயல் ‘தாமஸம்’ என்று கூறப்படுகிறது. 18.25",

   "பற்றற்றவனாகவும், நான் என்ற அகங்காரத்தைக் காட்டும் சொல்லை கூறாதவனாகவும், உறுதியும், ஊக்கமும், உடையவனாகவும், செயல், கை கூடுதல், கைகூடாமை – இவற்றில் மகிழ்ச்சி, துயரம் முதலிய மாறுபாடற்றவனாகவும் இருக்கின்ற அந்தக்  கர்த்தா, சாத்வீகன் எனப்படுகிறான். 18.26",

   "பற்றுடன் கூடியவன், செயலின் பலனை விரும்புபவன், பேராசையுள்ளவன், பிறரைத் துன்புறுத்தும் தன்மை கொண்டவன், தூய்மையற்ற நடத்தையுள்ளவன், மகிழ்ச்சியாலும், துயரத்தாலும் பாதிக்கப்படுபவன் – இத்தகைய கர்த்தா ‘ராஜஸன்’ என்று அழைக்கப்படுகிறான். 18.27",

   "கட்டுப்படுத்தப்படாத மனமும் புலன்களும் உடையவனும், அறிவு முதிர்ச்சி அடையாதவனும்,  கர்வமுடையவனும்,  வஞ்சகனும், பிறருடைய வாழ்க்கையைக் கெடுப்பவனும், கவலை கொண்டவனும், சோம்பேறியும், காலம் கடத்துபவனுமான கர்த்தா ‘தாமஸன் ‘ என்று கூறப்படுகிறான். 18.28",

   "அர்ஜுனா! இப்பொழுது நீ முக்குணங்களுக்கேற்பவே புத்திக்கும் மனஉறுதிக்கும் ஏற்படும் மூன்று விதமான பாகுபாட்டை முழுமையாகவும் தனித்தனியாகவும் நான் கூறக் கேள். 18.29",

   "அர்ஜுனா!  எந்த புத்தி,  பிரவிருத்தி -  நிவிருத்தி மார்கத்தையும்,  செய்யத் தகுந்தது – செய்யத் தகாதது என்பதையும்,  பயம் – பயமின்மையையும், தளை- மோட்சத்தையும் உண்மையாக அறிகிறதோ, அந்த புத்தி  சாத்வீகமானது. 18.30",

   "ஹே அர்ஜுனா!  எந்த புத்தியைக் கொண்டு விதித்த தர்மத்தையும் வெறுக்கும் படியான அதர்மத்தையும் செய்யத் தகுந்ததையும் செய்யத் தகாததையும் உள்ளபடியல்லாமல் அறிகிறானோ அந்த புத்தியானது ராஜோகுணப்ரதானமானது 18.31",

   "அர்ஜுனா! எந்த புத்தி தமோ குணத்தினால் சூழப்பட்டு அதர்மத்தைத் தர்மம் என்றும், எல்லா பொருட்களையும் அவற்றின் உண்மைத் தன்மைக்கு மாறாகவும் நினைக்கிறதோ, அந்த புத்தி தாமசமானது. 18.32",

   "அர்ஜுனா! பகவானைத் தவிர வேறு எந்த விஷயத்திலும் சிந்திக்காத எந்த உறுதியினால் மனிதன் தியான யோகத்தின் மூலம் மனம், பிராணன், புலன்கள் – இவற்றினுடைய செயல்களை நிலைநிறுத்துகிறானோ, அந்த உறுதி சாத்வீகமானது. 18.33",

   "ஆனால் பிருதையின் மைந்தனே! அர்ஜுனா! பயனில் விருப்பமுடைய மனிதன் எந்த உறுதியினால் மிகுந்த பற்றோடு அறம், பொருள், இன்பங்களை அடைவதிலேயே உறுதியாக இருக்கிறானோ, அந்த  உறுதி ராஜஸமானது. 18.34",

   "பார்த்தா! தீய புத்தி கொண்டவன் எந்த உறுதியினால் தூக்கத்தையும் அச்சத்தையும் கவலையையும் துயரத்தையும் செருக்கையும் கூட விடாமல் உறுதியுடன் பற்றுகிறானோ, அந்த உறுதி தாமஸமானது. 18.35",

    "பரதகுலச் செம்மலே!  இப்பொழுது மூன்று விதமான சுகம் பற்றியும் நீ என்னிடம் கேள். எந்தச் சுகத்தில் சாதகனான மனிதன் பஜனை, தியானம், சேவை முதலிய பயிற்சியினால் இன்புறுகிறானோ, துன்பத்தின் முடிவையும் அடைகிறானோ, அந்தச் சுகம் சாதனையின் தொடக்கத்தில் விஷத்தைப் போலத் தோன்றினாலும் விளைவில் அமிர்தத்திற்கொப்பாக  இருக்கும்.  ஆகவே, பகவான் பற்றியத் தெளிவான அறிவினால் ஏற்படக்கூடிய அந்தச் சுகம் சாத்வீகம் என்று கூறப்படுகிறது. 18.36-18.37",

    "பரதகுலச் செம்மலே!  இப்பொழுது மூன்று விதமான சுகம் பற்றியும் நீ என்னிடம் கேள். எந்தச் சுகத்தில் சாதகனான மனிதன் பஜனை, தியானம், சேவை முதலிய பயிற்சியினால் இன்புறுகிறானோ, துன்பத்தின் முடிவையும் அடைகிறானோ, அந்தச் சுகம் சாதனையின் தொடக்கத்தில் விஷத்தைப் போலத் தோன்றினாலும் விளைவில் அமிர்தத்திற்கொப்பாக  இருக்கும்.  ஆகவே, பகவான் பற்றியத் தெளிவான அறிவினால் ஏற்படக்கூடிய அந்தச் சுகம் சாத்வீகம் என்று கூறப்படுகிறது. 18.36-18.37",

   "எந்தச் சுகம் போகப் பொருட்களில் புலன்கள் திளைப்பதால் உண்டாகிறதோ, அந்தச் சுகம் தொடக்கத்தில் (அனுபவிக்கும் காலத்தில்) அமுதத்திற்கொப்பாகத் தோன்றினாலும் விரைவில் நஞ்சுக்கு ஒப்பாகும். ஆகையால் அந்த சுகம் ராஜஸம் என்று கூறப்படுகிறது. 18.38",

   "எந்தச் சுகம் அனுபவிக்கும் காலத்திலும் விளைவிலும் ஆத்மாவை மயக்கமடையச் செய்கிறதோ, தூக்கம், சோம்பல், கவனமின்மை – இவற்றிலிருந்து தோன்றும் அந்தச் சுகம் தாமசம் என்று கூறப்படுகிறது. 18.39",

   "பிரகிருதியில் உண்டான இந்த மூன்று குணங்களிலிருந்து விடுபட்டதாக எந்தப் பிராணியும் நில உலகிலோ,  ஆகாயத்திலோ, வானவர்களிலோ அல்லது இவற்றைத் தவிர வேறு இடத்திலோ (எங்கும்) இல்லை. 18.40",

   "எதிரிகளை வாட்டுபவனே! பிராமணன், க்ஷத்திரியன், வைசியன் மற்றும் நான்காம் வர்ணத்தவருடைய கர்மங்கள் இயல்பிலிருந்து உண்டான குணங்களுக்கு ஏற்பப் பிரிக்கப்பட்டிருக்கின்றன. 18.41",

   "மனவடக்கம்; புலனடக்கம்; ஸ்வதர்மத்தைக் கடைப்பிடிப்பதற்காகத் துன்பங்களைப் பொறுத்துக் கொள்ளுதல்; அகத்தூய்மை- புறத்தூய்மை; பிறர் குற்றம் பொறுத்தல்; மனம், புலன், உடல் – இவற்றில் நேர்மை; வேதம், சாஸ்திரம், கடவுள், பரலோகம் – இவற்றில் நம்பிக்கை; வேதங்களையும் சாஸ்திரங்களையும் கற்றல் – கற்பித்தல்; பரமாத்மத் தத்துவத்தை அனுபவம் மூலம் உணர்தல் – இவையே பிராமணர்களுக்கு இயல்பாகவே உண்டான கர்மங்கள். 18.42",

   "சூரவீரத்தனமும், தேஜஸ்ஸும், உறுதியும், திறமையும், போரில் புறங்காட்டாமையும், கொடையும்,  ஆளும் தன்மையும் க்ஷத்திரியர்களுக்கு இயல்பான கர்மங்கள் ஆகும். 18.43",

   "உழவு செய்தலும், பசுக்களைக் காத்தலும், பொருட்களை வாங்கி விற்பதில் சத்தியத்தைக் கடைப்பிடித்தலும் வைசியர்களுக்கு இயல்பாக உண்டான கர்மங்கள். அப்படியே, மக்கள் அனைவருக்கும் சேவை புரிதல் நான்காம் வர்ணத்தவர்களுக்கு இயல்பாக உண்டான கர்மம். 18.44",

   "தத்தம் இயல்பான கடமைகளில் முழு முனைப்புடன் ஈடுபட்டிருக்கும் மனிதன், பகவானை அடைதலாகிற ஸித்தியை அடைகிறான். தன்னுடைய இயல்பான கடமையில் கருத்துடையவன் தன் செயலை எப்படிச் செய்து பரம நிலையை அடைகிறானோ, அந்த முறையை நீ கேள். 18.45",

   "எந்தப் பரம்பொருளிலிருந்து சகல பிராணிகளும் உண்டாயினவோ, எந்த பரமாத்மாவினால் இந்த உலகம் முழுமையும் நிறைந்துள்ளதோ, அந்த பரமாத்மாவைத் தன்னுடைய இயல்பான கடமைகளினால் வழிபட்டு, மனிதன் பரவசத்தை அடைகிறான். 18.46",

   "நல்ல முறையில் கடைப்பிடிக்கப்பட்ட பிறருடைய தர்மத்தை காட்டிலும், குணக்குறை உள்ளதாயினும், தன்னுடைய தர்மம் உயர்ந்தது. ஏனெனில், இயல்புக்கேற்ப விதிக்கப்பட்ட தன்னுடைய கடமையைச் செய்கின்ற மனிதன் பாவத்தை அடையமாட்டான். 18.47",

   "குந்தியின் மைந்தனே! குறை உள்ளதாயினும் தனக்குரிய இயல்பான கடமையை விட்டு விடலாகாது. ஏனெனில், புகையால் சூழப்பட்ட தீ போல் எல்லா செயல்களும் ஏதோ ஒரு வகையில் குறையினால் சூழப்பட்டுள்ளன. 18.48",

   " எங்கும் பற்றற்ற புத்தியுடைய, ஆசையற்றவனான, உள்ளத்தை அடக்கிய மனிதன் ஸாங்க்ய யோகத்தினால் கர்மத் தளைகளிலிருந்து முற்றும் விடுபட்ட பெருநிலையை அடைகிறான். 18.49",

   " ஞானயோகத்தினுடைய எந்த உயர்ந்த நிலை உண்டோ, அந்த நைஷ்கர்ம்ய ஸித்தியை அடைந்த மனிதன் பிரம்மத்தை எப்படி அடைகிறானோ, அந்த முறையை, குந்தியின் புதல்வனே! சுருக்கமாகவே என்னிடமிருந்து அறிந்துகொள். 18.50",

   "தெளிந்த அறிவுடன் கூடியவனாகவும், எளிய – சாத்வீக – அளவான உணவு அருந்துபவனாகவும், ஒலி முதலிய புலன்நுகர்ப் பொருட்களைத் துறந்து, தூய்மையான இடத்தில் தனித்திருந்து, சாத்வீகமான உறுதியோடு உள்ளத்தையும் புலன்களையும் அடக்கி, மனம் – வாக்கு -உடல் ஆகியவற்றைத் தன்வசத்தில் நிறுத்தி, விருப்பு – வெறுப்புகளை முற்றிலும் அழித்து, திடமான வைராக்கியத்தை அடைந்தவனாகவும்,  அகங்காரத்தையும் வலிமையையும் கர்வத்தையும் காமத்தையும் கோபத்தையும் சொத்து சேர்த்தலையும் நீக்கி, எப்பொழுதும் தியான யோகத்தில் நிலைத்திருந்து, மமகாரமற்று, சாந்தியோடு கூடியவனாகவும் உள்ளவன் ஸத் சித் ஆனந்தமயமான பரப்பிரம்மத்தில் இரண்டறக் கலப்பதற்குத் தகுதி பெறுகிறான். 18.51-18.53",

   "தெளிந்த அறிவுடன் கூடியவனாகவும், எளிய – சாத்வீக – அளவான உணவு அருந்துபவனாகவும், ஒலி முதலிய புலன்நுகர்ப் பொருட்களைத் துறந்து, தூய்மையான இடத்தில் தனித்திருந்து, சாத்வீகமான உறுதியோடு உள்ளத்தையும் புலன்களையும் அடக்கி, மனம் – வாக்கு -உடல் ஆகியவற்றைத் தன்வசத்தில் நிறுத்தி, விருப்பு – வெறுப்புகளை முற்றிலும் அழித்து, திடமான வைராக்கியத்தை அடைந்தவனாகவும்,  அகங்காரத்தையும் வலிமையையும் கர்வத்தையும் காமத்தையும் கோபத்தையும் சொத்து சேர்த்தலையும் நீக்கி, எப்பொழுதும் தியான யோகத்தில் நிலைத்திருந்து, மமகாரமற்று, சாந்தியோடு கூடியவனாகவும் உள்ளவன் ஸத் சித் ஆனந்தமயமான பரப்பிரம்மத்தில் இரண்டறக் கலப்பதற்குத் தகுதி பெறுகிறான். 18.51-18.53",

   "தெளிந்த அறிவுடன் கூடியவனாகவும், எளிய – சாத்வீக – அளவான உணவு அருந்துபவனாகவும், ஒலி முதலிய புலன்நுகர்ப் பொருட்களைத் துறந்து, தூய்மையான இடத்தில் தனித்திருந்து, சாத்வீகமான உறுதியோடு உள்ளத்தையும் புலன்களையும் அடக்கி, மனம் – வாக்கு -உடல் ஆகியவற்றைத் தன்வசத்தில் நிறுத்தி, விருப்பு – வெறுப்புகளை முற்றிலும் அழித்து, திடமான வைராக்கியத்தை அடைந்தவனாகவும்,  அகங்காரத்தையும் வலிமையையும் கர்வத்தையும் காமத்தையும் கோபத்தையும் சொத்து சேர்த்தலையும் நீக்கி, எப்பொழுதும் தியான யோகத்தில் நிலைத்திருந்து, மமகாரமற்று, சாந்தியோடு கூடியவனாகவும் உள்ளவன் ஸத் சித் ஆனந்தமயமான பரப்பிரம்மத்தில் இரண்டறக் கலப்பதற்குத் தகுதி பெறுகிறான். 18.51-18.53",

   "ஸத் சித் ஆனந்தமயமான பிரம்மத்தில் ஒன்றிய, மனத்தெளிவுடைய யோகி எதற்கும் வருந்துவதில்லை; எதையும் எதிர்பார்ப்பதில்லை. அனைத்து பிராணிகளிடமும் சம பாவனையோடு இருந்துகொண்டு, என்னுடைய உயர்ந்த பராபக்தியைப் பெறுகிறான். 18.54",

   "அந்த பரா பக்தியின் மூலம் அவன் பரமாத்மாவான என்னை யார் என்றும், எத்தன்மையானவன் என்றும் உண்மையில் உள்ளது உள்ளபடியே தத்துவரீதியாக அறிகிறான். அந்த பராபக்தியின் மூலம் என்னைத் தத்துவரீதியாக அறிந்து, அக்கணமே என்னிடம் ஐக்கியமாகிறான். 18.55",

   "என்னையே புகலாகக் கொண்ட கர்மயோகி எல்லாக் கர்மங்களையும் எப்பொழுதும் செய்து கொண்டிருப்பினும், எனது அருளால் நிலையான என்றுமுள்ள அழியாத பரமபதத்தை அடைகிறான். 18.56",

   "எல்லாக் கர்மங்களையும் மனப்பூர்வமாக என்னிடம் அர்ப்பணம் செய்து, அப்படியே சம புத்தியாகிற யோகத்தைக் கடைப்பிடித்து, என்னை அடைக்கலமாகக் கொண்டு, மேலும் எப்பொழுதும் என்னிடமே உள்ளம் பதித்தவனாக இருப்பாயாக. 18.57",

   "( மேற்கூறியபடி) என்னிடமே உள்ளம் பதிந்தவனாக, நீ எனது அருளால் எல்லா இன்னல்களையும் எளிதாகக் கடந்து விடுவாய். மேலும், அகங்காரத்தின் காரணமாக என்னுடைய சொற்களைக் கேளாவிட்டால் அழிந்து போவாய். அதாவது பெருநிலை பெறமாட்டாய். 18.58",

   " எந்த அகங்காரத்தின் வசப்பட்டு நீ,  ‘நான் யுத்தம் செய்யமாட்டேன்’ என்று நினைக்கிறாயோ, உன்னுடைய இந்தத் தீர்மானம் பொய்யானது. ஏனெனில், உன்னுடைய இயல்பு உன்னை வலுவில் போரில் ஈடுபடுத்தும். 18.59",

   "குந்தியின் மைந்தனே! அறிவு மயக்கத்தின் காரணமாக, நீ எந்தச் செயலை செய்வதற்கு விரும்பவில்லையோ, அதையும் உன்னுடைய இயல்பான கர்மவினையினால் கட்டப்பட்டு, உன் வசம் இழந்து செய்யப்போகிறாய். 18.60",

   "அர்ஜுனா!  உடல் என்கிற இயந்திரத்தில் ஏற்றி வைக்கப்பட்ட அனைத்துப் பிராணிகளையும் அந்தர்யாமியாக பகவான் தன்னுடைய மாயையினால் அவரவர்கள் கர்மவினைக்கேற்றவாறு சுழலச் செய்து கொண்டு, அனைத்து பிராணிகளுடைய இதயத்திலும் வீற்றிருக்கிறார். 18.61",

   "பரதகுலத் தோன்றலே! நீ எவ்வகையிலும் அந்தப் பரமேஸ்வரனேயே தஞ்சம் அடைவாயாக! அந்த பரமாத்மாவின் அருளினால் நீ உயர்ந்த அமைதியையும், நிலையான பரமபதத்தையும் அடைவாய். 18.62",

   "இவ்வாறு மறைபொருளுக்கெல்லாம் மறைபொருளான ஞானம் என்னால் உனக்கு கூறப்பட்டது. இப்போது நீ ரகசியமான இந்த ஞானத்தை முழுமையாக நன்கு ஆராய்ந்து எப்படி விரும்புகிறாயோ, அப்படியே செய். 18.63",

   "மறைபொருளுக்கெல்லாம் மறைபொருளான என்னுடைய மேலான ரகசியமான உரையை மீண்டும் கேள். எனக்கு நீ மிகவும் உற்ற நண்பனாக இருக்கிறாய். ஆகையால், மேலான நன்மை பயக்கும் என்ற கருத்தோடு, இந்த உரையை நான் உனக்கு கூறுவேன். 18.64",

   " அர்ஜுனா! நீ என்னிடமே மனத்தைச் செலுத்து. என்னிடமே பக்தி கொண்டிரு. என்னையே வழிபடு. என்னையே வணங்கு.  இப்படிச் செய்வதால் நீ என்னையே அடைவாய். இதையே நான் உனக்கு உண்மையான உறுதி கூறுகிறேன். ஏனெனில், நீ எனக்கும மிகவும் அன்புக்குரியவனாக இருக்கிறாய். 18.65",

   "தர்மங்கள் அனைத்தையும் அதாவது செய்ய வேண்டிய கடமைகள் அனைத்தையும் என்னிடம் அர்ப்பணம் செய்துவிட்டு, சர்வ வல்லமை பொருந்திய, எல்லாவற்றுக்கும் ஆதாரமான, பரமேஸ்வரனான என் ஒருவனையே சரணடைவாயாக.  நான் உன்னைப் பாவங்கள் அனைத்திலிருந்தும் விடுவிப்பேன். வருந்தாதே! 18.66",

   "உனக்குக் கூறப்பட்ட இந்த ரகசியமான கீதோபதேசத்தை ஒருபோதும் தவம் இல்லாதவனுக்குச் சொல்லக்கூடாது; பக்தி இல்லாதவனுக்கும் சொல்லக்கூடாது; கேட்க விரும்பாதவனுக்கும் சொல்லக்கூடாது; எவன் என்னிடம் குறை காண்கிறானோ, அவனுக்கு ஒரு போதும் சொல்லக்கூடாது. 18.67",

   " எந்த மனிதன் என்னிடம் உயர்ந்த பிரேம பக்தியைச் செய்து, இந்த உயர்ந்த ரகசியமான கீதா சாஸ்திரத்தை பக்தர்களுக்குச் சொல்வானோ, அவன் என்னையே அடைகின்றான். இதில் சந்தேகமில்லை. 18.68",

   "அந்த பக்தனை காட்டிலும் எனக்கு மிகவும் பிரியமான காரியத்தைச் செய்யக் கூடியவன் மனிதர்களில் வேறு எவனுமில்லை.  அப்படியே, பூமியில் அவனைக் காட்டிலும் எனக்கு மிகவும் பிரியமானவன் வேறொருவன் இருக்கப் போவதில்லை. 18.69",

   " எந்த மனிதன், இந்த தர்மமயமான, நம் இருவருடைய உரையாடலான கீதா சாஸ்திரத்தைக் கற்பானோ, அவனாலும் நான் ஞான வேள்வியினால் வழிபட்டவனாக ஆவேன் என்பது என்னுடைய கருத்து. 18.70",

   "எந்த மனிதன் நம்பிக்கையுடனும் குறைகாணதவனாகவும் இந்த கீதா சாஸ்திரத்தைக் கேட்கவாவது செய்வானோ,  அவனும் பாவங்களிலிருந்து விடுபட்டுப் புண்ணிய காரியங்கள் செய்யக்கூடிய மனிதர்கள் அடையும் சிறந்த புண்ணிய உலகங்களை அடைவான். 18.71",

   " பார்த்தா! கீதா சாஸ்திரம் உன்னால் ஒருமுகப்படுத்தப்பட்ட மனத்தோடு கேட்கப்பட்டதா? (மேலும்) அர்ஜுனா! அஞ்ஞானத்தினால் உண்டான உன் மோகம் அழிந்து விட்டதா? 18.72",

   "அர்ஜுனன் கூறுகிறார் -  அச்யுதா! உங்கள் அருளால் எனது மோகம் அழிந்தது. மேலும் என்னால் ஞான ஒளி பெறப்பட்டது. இப்பொழுது  நான் சந்தேகமற்றவனாக உறுதியாக இருக்கிறேன். ஆகையால், உங்கள் ஆணையை நிறைவேற்றுவேன். 18.73",

   "சஞ்சயன் கூறுகிறார் – “இவ்வாறு நான்,  பகவான் ஸ்ரீ வாசுதேவருக்கும், மகாத்மாவான பார்த்தனுக்கும் இடையே நிகழ்ந்த இந்த அற்புதமான உட்பொருள் கொண்ட புல்லரிக்கக் கூடிய உரையாடலைக் கேட்டேன். 18.74",

   "ஸ்ரீவியாச பகவானுடைய அருளினால் தெய்வீகப் பார்வையைப் பெற்ற நான் உயர்ந்த ரகசியமான யோகத்தை, அர்ஜுனைக் குறித்துச் சொல்லுகின்ற சாட்சாத் யோகேஸ்வரரான பகவான் ஸ்ரீ கிருஷ்ணரிடம் இருந்து நேரிடையாகக் கேட்டேன். 18.75",

   "அரசே! பகவான் ஸ்ரீ கிருஷ்ணனுக்கும் அர்ஜுனனுக்குமிடையே நடந்த இந்த ரகசியமான நன்மை தரக்கூடிய அற்புதமான உரையாடலை நினைத்து நினைத்து, நான் மீண்டும் மீண்டும் மகிழ்கிறேன். 18.76",

   "அரசே! பாவங்களனைத்தையும் போக்கக்கூடிய பகவான் ஸ்ரீ ஹரியின் மிகவும் அற்புதமான, அழகான அந்தத் திருவுருவையும் நினைந்து நினைந்து, என்னுடைய மனதில் பெருவியப்பு உண்டாகிறது.  மேலும் நான், மேலும் மேலும் மகிழ்ச்சியடைகிறேன். 18.77",

   "அரசே! இங்கு நான் என்ன சொல்லப்போகிறேன்? எங்கு யோகேஸ்வரரான பகவான் ஸ்ரீ கிருஷ்ணரும் எவ்விடத்தில் காண்டீபம் ஏந்திய அர்ஜுனனும் இருக்கிறார்களோ, அவ்விடத்தில் மகாலட்சுமியும் வெற்றியும் ஐஸ்வர்யமும் நிலைத்த நீதியும் இருக்கும் என்பது என்னுடைய கொள்கை. 18.78",

   "பிரம்ம வித்தையும் யோக சாஸ்திரமும் ஸ்ரீ கிருஷ்ணார்ஜுன சம்பாஷணையுமாகிய ஸ்ரீமத்பகவத்கீதை உபநிஷத்தில் \"மோக்ஷ ஸந்யாஸ யோகம்\" என்ற பதினெட்டாம் அத்தியாயம் ஸம்பூர்ணம்."
  ]
  }
]

    export default GeetajiTamil