const GeetajiEnglish = [
      {
      "adhyaya-1" : [
      "dhṛtarāṣṭra uvāca\ndharmakṣetre kurukṣetre, ṣamavetā yuyutsavaḥ,\nmāmakāḥ(ph) pāṇḍavāścaiva, kimakurvata sañjaya. 1.1",

      "sañjaya uvāca\ndṛṣṭvā tu pāṇḍavānīkaṃ(v̐), vyūḍhaṃ(n) duryodhanastadā,\nācāryamupasaṅgamya, rājā vacanamabravīt.1.2",

      "paśyaitāṃ(m) pāṇḍuputrāṇām, ācārya mahatīṃ(ñ) camūm,\nvyūḍhāṃ(n) drupadaputreṇa, tava śiṣyeṇa dhīmatā. 1.3",

      "atra śūrā maheṣvāsā, bhīmārjunasamā yudhi,\nyuyudhāno virāṭaśca, drupadaśca mahārathaḥ. 1.4",

      "dhṛṣṭaketuścekitānaḥ(kh), kāśirājaśca vīryavān,\npurujitkuntibhojaśca, śaibyaśca narapuṅgavaḥ. 1.5",

      "yudhāmanyuśca vikrānta, uttamaujāśca vīryavān,\nsaubhadro draupadeyāśca, sarva eva mahārathāḥ. 1.6",

      "asmākaṃ(n) tu viśiṣṭā ye, tānnibodha dvijottama,\nnāyakā mama sainyasya, sañjñārthaṃ(n) tānbravīmi te. 1.7",

      "bhavānbhīṣmaśca karṇaśca, kṛpaśca samitiñjayaḥ,\naśvatthāmā vikarṇaśca, saumadattistathaiva ca. 1.8",

      "anye ca bahavaḥ(ś) śūrā, madarthe tyaktajīvitāḥ,\nnānāśastrapraharaṇāḥ(s), sarve yuddhaviśāradāḥ. 1.9",

      "aparyāptaṃ(n) tadasmākaṃ(m), balaṃ(m) bhīṣmābhirakṣitam,\nparyāptaṃ(n) tvidameteṣāṃ(m), balaṃ(m) bhīmābhirakṣitam. 1.10",

      "ayaneṣu ca sarveṣu, yathābhāgamavasthitāḥ,\nbhīṣmamevābhirakṣantu, bhavantaḥ(s) sarva eva hi. 1.11",

      "tasya sañjanayanharṣaṃ(ṅ), kuruvṛddhaḥ(ph) pitāmahaḥ,\nsiṃhanādaṃ(v̐) vinadyoccaiḥ(ś), śaṅkhaṃ(n) dadhmau pratāpavān.1.12",

      "tataḥ(ś) śaṅkhāśca bheryaśca, paṇavānakagomukhāḥ,\nsahasaivābhyahanyanta, sa śabdastumulo'bhavat. 1.13",

      "tataḥ(ś) śvetairhayairyukte, mahati syandane sthitau,\nmādhavaḥ(ph) pāṇḍavaś caiva, divyau śaṅkhau pradadhmatuḥ. 1.14",

      "pāñcajanyaṃ(m) hṛṣīkeśo, devadattaṃ(n) dhanañjayaḥ,\npauṇḍraṃ(n) dadhmau mahāśaṅkhaṃ(m), bhīmakarmā vṛkodaraḥ. 1.15",

      "anantavijayaṃ(m) rājā, kuntīputro yudhiṣṭhiraḥ,\nnakulaḥ(s) sahadevaśca, sughoṣamaṇipuṣpakau. 1.16",

      "kāśyaśca parameṣvāsaḥ(ś), śikhaṇḍī ca mahārathaḥ,\ndhṛṣṭadyumno virāṭaśca, sātyakiścāparājitaḥ. 1.17",

      "drupado draupadeyāśca, sarvaśaḥ(ph) pṛthivīpate,\nsaubhadraśca mahābāhuḥ(ś), śaṅkhāndadhmuḥ(ph) pṛthakpṛthak. 1.18",

      "sa ghoṣo dhārtarāṣṭrāṇāṃ(m), hṛdayāni vyadārayat,\nnabhaśca pṛthivīṃ(ñ) caiva, tumulo vyanunādayan. 1.19",

      "atha vyavasthitāndṛṣṭvā, dhārtarāṣṭrānkapidhvajaḥ,\npravṛtte śastrasaṃpāte, dhanurudyamya pāṇḍavaḥ. 1.20",

      "hṛṣīkeśaṃ(n) tadā vākyam, idamāha mahīpate,\narjuna uvāca\nsenayorubhayormadhye, rathaṃ(m) sthāpaya me'cyuta. 1.21",

      "yāvadetānnirīkṣe'haṃ(y̐), yoddhukāmānavasthitān,\nkairmayā saha yoddhavyam, asminraṇasamudyame.1.22",

      "yotsyamānānavekṣe'haṃ(y̐), ya ete'tra samāgatāḥ,\ndhārtarāṣṭrasya durbuddheḥ(r), yuddhe priyacikīrṣavaḥ.1.23",

      "sañjaya uvāca\nevamukto hṛṣīkeśo, guḍākeśena bhārata,\nsenayorubhayormadhye, sthāpayitvā rathottamam. 1.24",

      "bhīṣmadroṇapramukhataḥ(s), sarveṣāṃ(ñ) ca mahīkṣitām,\nuvāca pārtha paśyaitān, ṣamavetānkurūniti. 1.25",

      "tatrāpaśyatsthitānpārthaḥ(ph), pitṝnatha pitāmahān,\nācāryānmātulānbhrātṝn, putrānpautrānsakhīṃstathā. 1.26",

      "śvaśurānsuhṛdaścaiva, senayorubhayorapi,\ntānsamīkṣya sa kaunteyaḥ(s), sarvānbandhūnavasthitān. 1.27",

      "kṛpayā parayāviṣṭo, viṣīdannidamabravīt,\narjuna uvāca\ndṛṣṭvemaṃ(m) svajanaṃ(ṅ) kṛṣṇa, yuyutsuṃ(m) samupasthitam. 1.28",

      "sīdanti mama gātrāṇi, mukhaṃ(ñ) ca pariśuṣyati,\nvepathuśca śarīre me, romaharṣaśca jāyate. 1.29",

      "gāṇḍīvaṃ(m) sraṃsate hastāt, tVākcaiva paridahyate,\nna ca śaknomyavasthātuṃ(m), bhRāmatīva ca me manaḥ. 1.30",

      "nimittāni ca paśyāmi, viparītāni keśava,\nna ca śreyo'nupaśyāmi, hatvā svajanamāhave. 1.31",

      "na kāṅkṣe vijayaṃ(ṅ) kṛṣṇa, na ca rājyaṃ(m) sukhāni ca,\nkiṃ(n) no rājyena govinda, kiṃ(m) bhogairjīvitena vā. 1.32",

      "yeṣāmarthe kāňkṣitaṃ(n) no, rājyaṃ bhogāḥ(s) sukhāni ca,\nta ime'vasthitā yuddhe, prāṇāṃstyaktvā dhanāni ca. 1.33",

      "ācāryāḥ(ph) pitaraḥ(ph) putrāḥ(s), tathaiva ca pitāmahāḥ,\nmātulāḥ(ś) śvaśurāḥ(ph) pautrāḥ(ś), śyālāḥ(s) sambandhinastathā. 1.34",

      "etānna hantumicchāmi, ghnato'pi madhusūdana,\napi trailokyarājyasya, hetoḥ(kh) kiṃ(n) nu mahīkṛte.1.35",

      "nihatya dhārtarāṣṭrānnaḥ(kh), kā prītiḥ(s) syājjanārdana,\npāpamevāśrayedasmān, hatvaitānātatāyinaḥ.1.36",

      "tasmānnārhā vayaṃ(m) hantuṃ(n), dhārtarāṣṭrānsvabāndhavān,\nsvajanaṃ(m) hi kathaṃ(m) hatvā, sukhinaḥ(s) syāma mādhava.1.37",

      "yadyapyete na paśyanti, lobhopahatacetasaḥ,\nkulakṣayakṛtaṃ(n) doṣaṃ(m), mitradrohe ca pātakam.1.38",

      "kathaṃ(n) na jñeyamasmābhiḥ(ph), pāpādasmānnivartitum,\nkulakṣayakṛtaṃ(n) doṣaṃ(m), prapaśyadbhirjanārdana.1.39",

      "kulakṣaye praṇaśyanti, kuladharmāḥ(s) sanātanāḥ,\ndharme naṣṭe kulaṃ(ṅ) kṛtsnam, adharmo'bhibhavatyuta.1.40",

      "adharmābhibhavātkṛṣṇa, praduṣyanti kulastriyaḥ,\nstrīṣu duṣṭāsu vārṣṇeya, jāyate varṇasaṅkaraḥ.1.41",

      "saṅkaro narakāyaiva, kulaghnānāṃ(ṅ) kulasya ca,\npatanti pitaro hyeṣāṃ(l̐), luptapiṇḍodakakriyāḥ.1.42",

      "doṣairetaiḥ(kh) kulaghnānāṃ(v̐), varṇasaṅkarakārakaiḥ,\nutsādyante jātidharmāḥ(kh), kuladharmāśca śāśvatāḥ.1.43",

      "utsannakuladharmāṇāṃ(m), manuṣyāṇāṃ(ñ) janārdana,\nnarake'niyataṃ(v̐) vāso, bhavatītyanuśuśruma.1.44",

      "aho bata mahatpāpaṃ(ṅ), kartuṃ(v̐) vyavasitā vayam,\nyadrājyasukhalobhena, hantuṃ(m) svajanamudyatāḥ.1.45",

      "yadi māmapratīkāram, aśastraṃ(m) śastrapāṇayaḥ,\ndhārtarāṣṭrā raṇe hanyuḥ(s), tanme kṣemataraṃ(m) bhavet.1.46",

      "sañjaya uvāca\nevamuktvārjunaḥ(s) saṅkhye, rathopastha upāviśat,\nvisṛjya saśaraṃ(ñ) cāpaṃ(m), śokasaṃvignamānasaḥ.1.47",

      "om tatsaditi śrīmadbhagavadgītāsu upaniṣatsu brahmavidyāyāṃ(y̐)\nyogaśāstre śrīkṛṣṇārjunasaṃvāde arjunavishadayogo nāma prathamo'dhyāyaḥ"
      ],
      "adhyaya-2" : [
      "sañjaya uvāca\ntaṃ(n) tathā kṛpayāviṣṭam, aśrupūrṇākulekṣaṇam,\nviṣīdantamidaṃ( v̐) vākyam, uvāca madhusūdanaḥ.2.1",

      "śrībhagavānuvāca\nkutastvā kaśmalamidaṃ(v̐), viṣame samupasthitam,\nanāryajuṣṭamasvargyam, akīrtikaramarjuna.2.2",

      "klaibyaṃ(m) mā sma gamaḥ(ph) pārtha, naitattvayyupapadyate,\nkṣudraṃ(m) hṛdayadaurbalyaṃ(n), tyaktvottiṣṭha parantapa. 2.3",

      "arjuna uvāca\nkathaṃ(m) bhīṣmamahaṃ(m) saṅkhye, droṇaṃ(ñ) ca madhusūdana,\niṣubhiḥ(ph) pratiyotsyāmi, pūjārhāvarisūdana. 2.4",

      "gurūnahatvā hi mahānubhāvān, śreyo bhoktuṃ(m) bhaikṣyamapīha loke,\nhatvārthakāmāṃstu gurūnihaiva, bhuñjīya bhogānrudhirapradigdhān. 2.5",

      "na caitadvidmaḥ(kh) kataranno garīyo, yadvā jayema yadi vā no jayeyuḥ,\nyāneva hatvā na jijīviṣāmaḥ(s), te'vasthitāḥ(ph) pramukhe dhārtarāṣṭrāḥ. 2.6",

      "kārpaṇyadoṣo pahatasvabhāvaḥ(ph), pṛcchāmi tvāṃ(n) dharmasaṃmūḍhacetāḥ,\nyacchreyaḥ(s) syānniścitaṃ(m) brūhi tanme, śiṣyaste'haṃ(m) śādhi māṃ(n) tvāṃ(m) prapannam. 2.7",

      "na hi prapaśyāmi mamāpanudyād, yacchokamucchoṣaṇamindriyāṇām,\navāpya bhūmāvasapatnamṛddhaṃ(m), rājyaṃ(m) surāṇāmapi cādhipatyam. 2.8",

      "sañjaya uvāca\nevamuktvā hṛṣīkeśaṃ(ṅ), guḍākeśaḥ(ph) parantapa,\nna yotsya iti govindam, uktvā tūṣṇīṃ(m) babhūva ha. 2.9",

      "tamuvāca hṛṣīkeśaḥ(ph), prahasanniva bhārata,\nsenayorubhayormadhye, viṣīdantamidaṃ(v̐) vacaḥ.2.10",

      "śrībhagavānuvāca\naśocyānanvaśocastvaṃ(m), prajñāvādāṃśca bhāṣase,\ngatāsūnagatāsūṃśca, nānuśocanti paṇḍitāḥ. 2.11",

      "na tvevāhaṃ(ñ) jātu nāsaṃ(n), na tvaṃ(n) neme janādhipāḥ,\nna caiva na bhaviṣyāmaḥ(s), sarve vayamataḥ(ph) param. 2.12",

      "dehino'sminyathā dehe, kaumāraṃ(y̐) yauvanaṃ(ñ) jarā,\ntathā dehāntaraprāptiḥ(r), dhīrastatra na muhyati.2.13",

      "mātrāsparśāstu kaunteya, śītoṣṇasukhaduḥkhadāḥ,\nāgamāpāyino'nityāḥ(s), tāṃstitikṣasva bhārata. 2.14",

      "yaṃ(m) hi na vyathayantyete, puruṣaṃ(m) puruṣarṣabha,\nṣamaduḥkhasukhaṃ(n) dhīraṃ(m), so'mṛtatvāya kalpate. 2.15",

      "nāsato vidyate bhāvo, nābhāvo vidyate sataḥ,\nubhayorapi dṛṣṭo'ntaḥ(s), tvanayostattvadarśibhiḥ. 2.16",

      "avināśi tu tadviddhi, yena sarvamidaṃ(n) tatam,\nvināśamavyayasyāsya, na kaścitkartumarhati. 2.17",

      "antavanta ime dehā, nityasyoktāḥ(ś) śarīriṇaḥ,\nanāśino'prameyasya, tasmādyudhyasva bhārata. 2.18",

      "ya enaṃ(v̐) vetti hantāraṃ,(y̐) yaścainaṃ(m) manyate hatam\nubhau tau na vijānīto, nāyaṃ(m) hanti na hanyate.2.19",

      "na jāyate mriyate vā kadācin, nāyaṃ(m) bhūtvā bhavitā vā na bhūyaḥ,\n ajo nityaḥ(ś) śāśvato'yaṃ(m) purāṇo, na hanyate hanyamāne śarīre. 2.20",

      "vedāvināśinaṃ(n) nityaṃ(y̐), ya enamajamavyayam,\n kathaṃ(m) sa puruṣaḥ(ph) pārtha, kaṃ(ṅ) ghātayati hanti kam.2.21",

      "vāsāṃsi jīrṇāni yathā vihāya, navāni gṛhṇāti naro'parāṇi,\ntathā śarīrāṇi vihāya jīrṇā-nyanyāni saṃyāti navāni dehī. 2.22",

      "nainaṃ(ñ) chindanti śastrāṇi, nainaṃ(n) dahati pāVākaḥ,\nna cainaṃ(ṅ) kledayantyāpo, na śoṣayati mārutaḥ. 2.23",

      "acchedyo'ya madāhyo'yam, akledyo'śoṣya eva ca,\nnityaḥ(s) sarvagataḥ(s) sthāṇur, acalo'yaṃ(m) sanātanaḥ. 2.24",

      "avyakto'yama cintyo'yam, avikāryo'yamucyate,\ntasmādevaṃ(v̐) viditvainaṃ(n) nānuśocitumarhasi.2.25",

      "atha cainaṃ(n) nityajātaṃ(n), nityaṃ(v̐ ) vā manyase mṛtam,\ntathāpi tvaṃ(m) mahābāho, naivaṃ śocitumarhasi.2.26",

      "jātasya hi dhruvo mṛtyuḥ(r), dhruvaṃ(ñ) janma mṛtasya ca,\ntasmādaparihārye'rthe, na tvaṃ(m) śocitumarhasi. 2.27",

      "avyaktādīni bhūtāni, vyaktamadhyāni bhārata,\navyaktanidhanānyeva, tatra kā paridevanā. 2.28",

      "āścaryavatpaśyati kaścidenam, āścaryavadvadati tathaiva cānyaḥ,\nāścaryavaccainamanyaḥ(ś) śṛṇoti, śrutvā'pyenaṃ(v̐) veda na caiva kaścit.2.29",

      "dehī nityamavadhyo'yaṃ(n), dehe sarvasya bhārata,\ntasmātsarvāṇi bhūtāni, na tvaṃ(m) śocitumarhasi. 2.30",

      "svadharmamapi cāvekṣya, na vikampitumarhasi,\ndharmyāddhi yuddhācchreyo'nyat, kṣatriyasya na vidyate. 2.31",

      "yadṛcchayā copapannaṃ(m), svargadvāRāmapāvṛtam,\nsukhinaḥ kṣatriyāḥ(ph) pārtha, labhante yuddhamīdṛśam. 2.32",

      "atha cettvamimaṃ(n) dharmyaṃ(m), saṅgrāmaṃ(n) na kariṣyasi,\ntataḥ(s) svadharmaṃ(ṅ) kīrtiṃ(ñ) ca, hitvā pāpamavāpsyasi. 2.33",

      "akīrtiṃ(ñ) cāpi bhūtāni, kathayiṣyanti te'vyayām,\nsaṃbhāvitasya cākīrtiḥ(r), maraṇādatiricyate. 2.34",

      "bhayādraṇāduparataṃ(m), maṃsyante tvāṃ(m) mahārathāḥ,\nyeṣāṃ(ñ) ca tvaṃ bahumato, bhūtvā yāsyasi lāghavam. 2.35",

      "avācyavādāṃśca bahūn, vadiṣyanti tavāhitāḥ,\nnindantastava sāmarthyaṃ(n), tato duḥkhataraṃ(n) nu kim. 2.36",

      "hato vā prāpsyasi svargaṃ(ñ), jitvā vā bhokṣyase mahīm,\ntasmāduttiṣṭha kaunteya, yuddhāya kṛtaniścayaḥ. 2.37",

      "sukhaduḥkhe same kṛtvā, lābhālābhau jayājayau,\ntato yuddhāya yujyasva, naivaṃ(m) pāpamavāpsyasi. 2.38",

      "eṣā te'bhihitā sāṅkhye, buddhiryoge tvimāṃ(m) śṛṇu,\nbuddhyā yukto yayā pārtha, karmabandhaṃ(m) prahāsyasi. 2.39",

      "nehābhikRāmanāśo'sti, pratyavāyo na vidyate,\nsvalpamapyasya dharmasya, trāyate mahato bhayāt. 2.40",

      "vyavasāyātmikā buddhiḥ(r), ekeha kurunandana,\nbahuśākhā hyanantāśca, buddhayo'vyavasāyinām. 2.41",

      "yāmimāṃ(m) puṣpitāṃ(v̐) vācaṃ(m), pravadantyavipaścitaḥ,\nvedavādaratāḥ(ph) pārtha, nānyadastīti vādinaḥ.2.42",

      "kāmātmānaḥ(s) svargaparā, janmakarmaphalapradām,\nkriyāviśeṣabahulāṃ(m), bhogaiśvaryagatiṃ(m) prati. 2.43",

      "bhogaiśvaryaprasaktānāṃ(n), tayāpahṛtacetasām,\nvyavasāyātmikā buddhiḥ(s), samādhau na vidhīyate. 2.44",

      "traiguṇyaviṣayā vedā, nistraiguṇyo bhavārjuna,\nnirdvandvo nityasattvastho, niryogakṣema ātmavān. 2.45",

      "yāvānartha udapāne, sarvataḥ(s) saṃplutodake,\ntāvānsarveṣu vedeṣu, brāhmaṇasya vijānataḥ. 2.46",

      "karmaṇyevādhikāraste, mā phaleṣu kadācana,\nmā karmaphalaheturbhūr, mā te saṅgo'stVākarmaṇi. 2.47",

      "yogasthaḥ(kh) kuru karmāṇi, sañgaṃ(n) tyaktvā dhanañjaya,\nsiddhyasiddhyoḥ(s) samo bhūtvā, samatvaṃ(y̐) yoga ucyate.2.48",

      "dūreṇa hyavaraṃ(ṅ) karma, buddhiyogāddhanañjaya,\nbuddhau śaraṇamanviccha, kṛpaṇāḥ(ph) phalahetavaḥ. 2.49",

      "buddhiyukto jahātīha, ubhe sukṛtaduṣkṛte,\ntasmādyogāya yujyasva, yogaḥ(kh) karmasu kauśalam. 2.50",

      "karmajaṃ(m) buddhiyuktā hi, phalaṃ(n) tyaktvā manīṣiṇaḥ,\njanmabandhavinirmuktāḥ(ph), padaṃ(ṅ) gacchantyanāmayam. 2.51",

      "yadā te mohakalilaṃ(m), buddhirvyatitariṣyati,\ntadā gantāsi nirvedaṃ(m), śrotavyasya śrutasya ca. 2.52",

      "śrutivipratipannā te, yadā sthāsyati niścalā,\nsamādhāvacalā buddhiḥ(s) tadā yogamavāpsyasi. 2.53",

      "arjuna uvāca\nsthitaprajñasya kā bhāṣā, samādhisthasya keśava,\nsthitadhīḥ(kh) kiṃ(m) prabhāṣeta, kimāsīta vrajeta kim. 2.54",

      "śrībhagavānuvāca\nprajahāti yadā kāmān, sarvānpārtha manogatān,\nātmanyevātmanā tuṣṭaḥ(s), sthitaprajñastadocyate. 2.55",

      "duḥkheṣvanudvignamanāḥ(s), sukheṣu vigataspṛhaḥ,\nvītarāgabhayakrodhaḥ(s), sthitadhīrmunirucyate. 2.56",

      "yaḥ(s) sarvatrānabhisnehaḥ(s), tattatprāpya śubhāśubham,\nnābhinandati na dveṣṭi, tasya prajñā pratiṣṭhitā. 2.57",

      "yadā saṃ(nv)harate cāyaṃ(ṅ), kūrmo'ṅgānīva sarvaśaḥ,\nindriyāṇīndriyārthe'bhyaḥ(s), tasya prajñā pratiṣṭhitā. 2.58",

      "viṣayā vinivartante, nirāhārasya dehinaḥ,\nrasavarjaṃ(m) raso'pyasya, paraṃ(n) dṛṣṭvā nivartate. 2.59",

      "yatato hyapi kaunteya, puruṣasya vipaścitaḥ,\nindriyāṇi pramāthīni, haranti prasabhaṃ(m) manaḥ. 2.60",

      "tāni sarvāṇi saṃyamya, yukta āsīta matparaḥ,\nvaśe hi yasyendriyāṇi, tasya prajñā pratiṣṭhitā. 2.61",

      "dhyāyato viṣayānpuṃsaḥ(s), saṅgasteṣūpajāyate,\nsaṅgātsañjāyate kāmaḥ(kh), kāmātkrodho'bhijāyate. 2.62",

      "krodhādbhavati saṃmohaḥ(s), saṃmohātsmṛtivibhRāmaḥ,\nsmṛtibhraṃśād buddhināśo, buddhināśātpraṇaśyati. 2.63",

      "rāgadveṣaviyuktaistu, viṣayānindriyaiścaran,\nātmavaśyairvidheyātmā, prasādamadhigacchati. 2.64",

      "prasāde sarvaduḥkhānāṃ(m), hānirasyopajāyate,\nprasannacetaso hyāśu, buddhiḥ(ph) paryavatiṣṭhate. 2.65",

      "nāsti buddhirayuktasya, na cāyuktasya bhāvanā,\nna cābhāvayataḥ(ś) śāntiḥ(r) aśāntasya kutaḥ(s) sukham. 2.66",

      "indriyāṇāṃ(m) hi caratāṃ(y̐), yanmano'nuvidhīyate,\ntadasya harati prajñāṃ(v̐), vāyurnāvamivāmbhasi.2.67",

      "tasmādyasya mahābāho, nigṛhītāni sarvaśaḥ,\nindriyāṇīndriyārthebhyaḥ(s), tasya prajñā pratiṣṭhitā. 2.68",

      "yā niśā sarvabhūtānāṃ(n), tasyāṃ(ñ) jāgarti saṃ(y)yamī,\nyasyāṃ(ñ) jāgrati bhūtāni, sā niśā paśyato muneḥ. 2.69",

      "āpūryamāṇamacalapratiṣṭhaṃ, samudramāpaḥ(ph) praviśanti yadvat,\ntadvatkāmā yaṃ(m) praviśanti sarve, sa śāntimāpnoti na kāmakāmī. 2.70",

      "vihāya kāmānyaḥ(s) sarvān, pumāṃścarati niḥspṛhaḥ,\nnirmamo nirahaṅkāraḥ(s), sa śāntimadhigacchati. 2.71",

      "eṣā brāhmī sthitiḥ(ph) pārtha, naināṃ(m) prāpya vimuhyati,\nsthitvāsyāmantakāle'pi, brahmanirvāṇamṛcchati. 2.72",

      "Om tatsaditi śrīmadbhagavadgītāsu upaniṣatsu brahmavidyāyāṃ(y̐)\nyogaśāstre śrīkṛṣṇārjunasaṃvāde sāṅkhyayogo nāma dvitīyo'dhyāyaḥ"
      ],
      "adhyaya-3" : [
      "arjuna uvāca :\njyāyasī cetkarmaṇaste, matā buddhirjanārdana,\ntatkiṃ(ṅ) karmaṇi ghore māṃ(n), niyojayasi keśava. 3.1",

      "vyāmiśreṇeva vākyena, buddhiṃ(m) mohayasīva me,\ntadekaṃ(v̐) vada niścitya, yena śreyo'hamāpnuyām.॥3.2॥",

      "Śrībhagavānuvāca :\nloke'smindvividhā niṣṭhā, purā proktā mayānagha,\njñānayogena sāṅkhyānāṃ(ṅ), karmayogena yoginām. 3.3",

      "na karmaṇāmanārambhān, naiṣkarmyaṃ(m) puruṣo'śnute,\nna ca sannyasanādeva, siddhiṃ(m) ṣamadhigacchati. 3.4",

      "na hi kaścitkṣaṇamapi, jātu tiṣṭhatyakarmakṛt,\nkāryate hyavaśaḥ(kh) karma, sarvaḥ(ph) prakṛtijairguṇaiḥ. 3.5",

      "karmendriyāṇi saṃyamya, ya āste manasā smaran,\nindriyārthānvimūḍhātmā, mithyācāraḥ(s) sa ucyate. 3.6",

      "yastvindriyāṇi manasā, niyamyārabhate'rjuna,\nkarmendriyaiḥ(kh) karmayogam, asaktaḥ(s) sa viśiṣyate. 3.7",

      "niyataṃ(ṅ) kuru karma tvaṃ(ṅ), karma jyāyo hyakarmaṇaḥ,\nśarīrayātrāpi ca te, na prasiddhyedakarmaṇaḥ. 3.8",

      "yajñārthātkarmaṇo'nyatra, loko'yaṃ(ṅ) karmabandhanaḥ,\ntadarthaṃ(ṅ) karma kaunteya, muktasaṅgaḥ(s) samācara. 3.9",

      "sahayajñāḥ(ph) prajāḥ(s) sṛṣṭvā, purovāca prajāpatiḥ,\nanena prasaviṣyadhvam, eṣa vo'stviṣṭakāmadhuk. 3.10",

      "devānbhāvayatānena, te devā bhāvayantu vaḥ,\nparasparaṃ bhāvayantaḥ(ś), śreyaḥ(ph) paRāmavāpsyatha. 3.11",

      "iṣṭānbhogānhi vo devā, dāsyante yajñabhāvitāḥ,\ntairdattānapradāyaibhyo, yo bhuṅkte stena eva saḥ. 3.12",

      "yajñaśiṣṭāśinaḥ(s) santo, mucyante sarVākilbiṣaiḥ,\nbhuñjate te tvaghaṃ(m) pāpā, ye pacantyātmakāraṇāt. 3.13",

      "annādbhavanti bhūtāni, parjanyādannasaṃbhavaḥ,\nyajñādbhavati parjanyo, yajñaḥ(kh) karmasamudbhavaḥ. 3.14",

      "karma brahmodbhavaṃ(v̐) viddhi, brahmākṣarasamudbhavam,\ntasmātsarvagataṃ(m) brahma, nityaṃ(y̐) yajñe pratiṣṭhitam. 3.15",

      "evaṃ pravartitaṃ(ñ) cakraṃ(n), nānuvartayatīha yaḥ,\naghāyurindriyārāmo, moghaṃ(m) pārtha sa jīvati. 3.16",

      "yastvātmaratireva syād, ātmatṛptaśca mānavaḥ,\nātmanyeva ca santuṣṭaḥ(s), tasya kāryaṃ(n) na vidyate. 3.17",

      "naiva tasya kṛtenārtho, nākṛteneha kaścana,\nna cāsya sarvabhūteṣu, kaścidarthavyapāśrayaḥ. 3.18",

      "tasmādasaktaḥ(s) satataṃ(ṅ), kāryaṃ(ṅ) karma samācara,\nasakto hyācarankarma, paramāpnoti pūruṣaḥ. 3.19",

      "karmaṇaiva hi saṃsiddhim, āsthitā janakādayaḥ,\nlokasaṅgrahamevāpi, saṃpaśyankartumarhasi. 3.20",

      "yadyadācarati śreṣṭhaḥ(s), tattadevetaro janaḥ,\nsa yatpramāṇaṃ(ṅ) kurute, lokastadanuvartate. 3.21",

      "na me pārthāsti kartavyaṃ(n), triṣu lokeṣu kiñcana,\nnānavāptamavāptavyaṃ(v̐), varta eva ca karmaṇi. 3.22",

      "yadi hyahaṃ(n) na varteyaṃ(ñ), jātu karmaṇyatandritaḥ,\nmama vartmānuvartante, manuṣyāḥ(ph) pārtha sarvaśaḥ. 3.23",

      "utsīdeyurime lokā, na kuryāṃ(ṅ) karma cedaham,\nsaṅkarasya ca kartā syām, upahanyāmimāḥ(ph) prajāḥ. 3.24",

      "saktāḥ(kh) karmaṇyavidvāṃso, yathā kurvanti bhārata,\nkuryādvidvāṃstathāsaktaḥ(ś), cikīrṣurlokasaṅgraham. 3.25",

      "na buddhibhedaṃ(ñ) janayed, ajñānāṃ(ṅ) karmasaṅginām,\njoṣayetsarVākarmāṇi, vidvānyuktaḥ(s) samācaran. 3.26",

      "prakṛteḥ(kh) kriyamāṇāni, guṇaiḥ(kh) karmāṇi sarvaśaḥ,\nahaṅkāravimūḍhātmā, kartāhamiti manyate. 3.27",

      "tattvavittu mahābāho, guṇakarmavibhāgayoḥ,\nguṇā guṇeṣu vartanta, iti matvā na sajjate. 3.28",

      "prakṛterguṇasammūḍhāḥ(s), sajjante guṇakarmasu,\ntānakṛtsnavido mandān, kṛtsnavinna vicālayet. 3.29",

      "mayi sarvāṇi karmāṇi, sannyasyādhyātmacetasā,\nnirāśīrnirmamo bhūtvā, yudhyasva vigatajvaraḥ. 3.30",

      "ye me matamidaṃ(n) nityam, anutiṣṭhanti mānavāḥ,\nśraddhāvanto'nasūyanto, mucyante te'pi karmabhiḥ. 3.31",

      "ye tvetadabhyasūyanto, nānutiṣṭhanti me matam,\nsarvajñānavimūḍhāṃstān, viddhi naṣṭānacetasaḥ. 3.32",

      "sadṛśaṃ(ñ) ceṣṭate svasyāḥ(ph), prakṛterjñānavānapi,\nprakṛtiṃ(y̐) yānti bhūtāni, nigrahaḥ(kh) kiṃ(ṅ) kariṣyati. 3.33",

      "indriyasyendriyasyārthe, rāgadveṣau vyavasthitau,\ntayorna vaśamāgacchet, tau hyasya paripanthinau. 3.34",

      "śreyānsvadharmo viguṇaḥ(ph), paradharmātsvanuṣṭhitāt,\nsvadharme nidhanaṃ(m) śreyaḥ(ph), paradharmo bhayāvahaḥ. 3.35",

      "arjuna uvāca :\natha kena prayukto'yaṃ(m), pāpaṃ(ñ) carati pūruṣaḥ,\nanicchannapi vārṣṇeya, balādiva niyojitaḥ. 3.36",

      "Srībhagavānuvāca :\nkāma eṣa krodha eṣa, rajoguṇasamudbhavaḥ,\nmahāśano mahāpāpmā, viddhyenamiha vairiṇam. 3.37",

      "dhūmenāvriyate vahniḥ(r), yathādarśo malena ca,\nyatholbenāvṛto garbhaḥ(s), tathā tenedamāvṛtam. 3.38",

      "āvṛtaṃ(ñ) jñānametena, jñānino nityavairiṇā,\nkāmarūpeṇa kaunteya, duṣpūreṇānalena ca. 3.39",

      "indriyāṇi mano buddhiḥ(r), asyādhiṣṭhānamucyate,\netairvimohayatyeṣa, jñānamāvṛtya dehinam. 3.40",

      "tasmāttvamindriyāṇyādau, niyamya bharatarṣabha,\npāpmānaṃ(m) prajahi hyenaṃ(ñ), jñānavijñānanāśanam. 3.41",

      "indriyāṇi parāṇyāhuḥ(r), indriyebhyaḥ(ph) paraṃ(m) manaḥ,\nmanasastu parā buddhiḥ(r), yo buddheḥ(ph) paratastu saḥ. 3.42",

      "evaṃ(m) buddheḥ(ph) paraṃ(m) buddhvā, saṃstabhyātmānamātmanā,\njahi śatruṃ(m) mahābāho, kāmarūpaṃ(n) durāsadam. 3.43",

      "Om tatsaditi śrīmadbhagavadgītāsu upaniṣatsu brahmavidyāyāṃ(y̐)\nyogaśāstre śrīkṛṣṇārjunasaṃvāde karmayogo nāma tṛtīyo'dhyāyaḥ"
      ],
      "adhyaya-4" : [
      "śrībhagavānuvāca\nimaṃ(m) vivasvate yogaṃ(m), proktavānahamavyayam,\nvivasvānmanave prāha, manurikṣvākave'bravīt. 4.1",

      "evaṃ(m) paramparāprāptam, imaṃ(m) rājarṣayo viduḥ,\nsa kāleneha mahatā, yogo naṣṭaḥ(ph) parantapa. 4.2",

      "sa evāyaṃ(m) mayā te'dya, yogaḥ(ph) proktaḥ(ph) purātanaḥ,\nbhakto'si me sakhā ceti, rahasyaṃ(m) hyetaduttamam. 4.3",

      "arjuna uvāca\naparaṃ(m) bhavato janma, paraṃ(ñ) janma vivasvataḥ,\nkathametadvijānīyāṃ(n), tvamādau proktavāniti. 4.4",

      "śrībhagavānuvāca\nbahūni me vyatītāni, janmāni tava cārjuna,\ntānyahaṃ(m) veda sarvāṇi, na tvaṃ(m) vettha parantapa. 4.5",

      "ajo'pi sannavyayātmā, bhūtānāmīśvaro'pi san,\nprakṛtiṃ(m) svāmadhiṣṭhāya, saṃbhavāmyātmamāyayā. 4.6",

      "yadā yadā hi dharmasya, glānirbhavati bhārata,\nabhyutthānamadharmasya, tadātmānaṃ(m) sṛjāmyaham. 4.7",

      "paritrāṇāya sādhūnāṃ(m), vināśāya ca duṣkṛtām,\ndharmasaṃsthāpanārthāya, sambhavāmi yuge yuge. 4.8",

      "janma karma ca me divyam, evaṃ(m) yo vetti tattvataḥ,\ntyaktvā dehaṃ(m) punarjanma, naiti māmeti so'rjuna. 4.9",

      "vītarāgabhayakrodhā, manmayā māmupāśritāḥ,\nbahavo jñānatapasā, pūtā madbhāvamāgatāḥ. 4.10",

      "ye yathā māṃ prapadyante, tāṃstathaiva bhajāmyaham,\nmama vartmānuvartante, manuṣyāḥ(ph) pārtha sarvaśaḥ. 4.11",

      "kāṅkṣantaḥ(kh) karmaṇāṃ(m) siddhiṃ(m), yajanta iha devatāḥ,\nkṣipraṃ(m) hi mānuṣe loke, siddhirbhavati karmajā. 4.12",

      "cāturvarṇyaṃ(m) mayā sṛṣṭaṃ(ṅ), guṇakarmavibhāgaśaḥ,\ntasya kartāRāmapi māṃ(m), viddhyakartāRāmavyayam. 4.13",

      "na māṃ(ṅ) karmāṇi limpanti, na me karmaphale spṛhā,\niti māṃ(m) yo'bhijānāti, karmabhirna sa badhyate. 4.14",

      "evaṃ(ñ) jñātvā kṛtaṃ(ṅ) karma, pūrvairapi mumukṣubhiḥ,\nkuru karmaiva tasmāttvaṃ(m), pūrvaiḥ(ph) pūrvataraṃ(ṅ) kṛtam. 4.15",

      "kiṃ(ṅ) karma kimakarmeti, kavayo'pyatra mohitāḥ,\ntatte karma praVākṣyāmi, yajjñātvā mokṣyase'śubhāt. 4.16",

      "karmaṇo hyapi boddhavyaṃ(m), boddhavyaṃ(ñ) ca vikarmaṇaḥ,\nakarmaṇaśca boddhavyaṃ(ṅ), gahanā karmaṇo gatiḥ. 4.17",

      "karmaṇyakarma yaḥ(ph) paśyed, akarmaṇi ca karma yaḥ,\nsa buddhimānmanuṣyeṣu, sa yuktaḥ(kh) kṛtsnakarmakṛt. 4.18",

      "yasya sarve samārambhāḥ(kh), kāmasaṅkalpavarjitāḥ,\njñānāgnidagdhakarmāṇaṃ(n), tamāhuḥ(ph) paṇḍitaṃ(m) budhāḥ. 4.19",

      "tyaktvā karmaphalāsaṅgaṃ(n), nityatṛpto nirāśrayaḥ,\nkarmaṇyabhipravṛtto'pi, naiva kiñcitkaroti saḥ. 4.20",

      "nirāśīryatacittātmā, tyaktasarvaparigrahaḥ,\nśārīraṃ(ṅ) kevalaṃ(ṅ) karma, kurvannāpnoti kilbiṣam. 4.21",

      "yadṛcchālābhasantuṣṭo, dvandvātīto vimatsaraḥ,\nṣamaḥ(s) siddhāvasiddhau ca, kṛtvāpi na nibadhyate. 4.22",

      "gatasaṅgasya muktasya, jñānāvasthitacetasaḥ,\nyajñāyācarataḥ(kh) karma, ṣamagraṃ(m) pravilīyate. 4.23",

      "brahmārpaṇaṃ(m) brahma haviḥ(r), brahmāgnau brahmaṇā hutam,\nbrahmaiva tena gantavyaṃ(m), brahma karma samādhinā. 4.24",

      "daivamevāpare yajñaṃ(m), yoginaḥ(ph) paryupāsate,\nbrahmāgnāvapare yajñaṃ(m), yajñenaivopajuhvati. 4.25",

      "śrotrādīnīndriyāṇyanye, saṃyamāgniṣu juhvati,\nśabdādīnviṣayānanya, indriyāgniṣu juhvati. 4.26",

      "sarvāṇīndriyakarmāṇi, prāṇakarmāṇi cāpare,\nātmasaṃyamayogāgnau, juhvati jñānadīpite. 4.27",

      "dravyayajñāstapoyajñā, yogayajñāstathāpare,\nsvādhyāyajñānayajñāśca, yatayaḥ(s) saṃśitavratāḥ. 4.28",

      "apāne juhvati prāṇaṃ(m), prāṇe'pānaṃ(n) tathāpare,\nprāṇāpānagatī ruddhvā, prāṇāyāmaparāyaṇāḥ. 4.29",

      "apare niyatāhārāḥ(ph), prāṇānprāṇeṣu juhvati,\nsarve'pyete yajñavido, yajñakṣapitakalmaṣāḥ. 4.30",

      "yajñaśiṣṭāmṛtabhujo, yānti brahma sanātanam,\nnāyaṃ(m) loko'styayajñasya, kuto'nyaḥ(kh) kurusattama. 4.31",

      "evaṃ(m) bahuvidhā yajñā, vitatā brahmaṇo mukhe,\nkarmajānviddhi tānsarvān, evaṃ(ñ) jñātvā vimokṣyase. 4.32",

      "śreyāndravyamayādyajñāj, jñānayajñaḥ(ph) parantapa,\nsarvaṃ(ṅ) karmākhilaṃ(m) pārtha, jñāne parisamāpyate. 4.33",

      "tadviddhi praṇipātena, paripraśnena sevayā,\nupadekṣyanti te jñānaṃ(ñ), jñāninastattvadarśinaḥ. 4.34",

      "yajjñātvā na punarmoham, evaṃ(m) yāsyasi pāṇḍava,\nyena bhūtānyaśeṣeṇa, drakṣyasyātmanyatho mayi. 4.35",

      "api cedasi pāpebhyaḥ(s), sarvebhyaḥ(ph) pāpakṛttamaḥ,\nsarvaṃ(ñ) jñānaplavenaiva, vṛjinaṃ(m) santariṣyasi. 4.36",

      "yathaidhāṃsi samiddho'gniḥ(r), bhasmasātkurute'rjuna,\njñānāgniḥ(s) sarVākarmāṇi, bhasmasātkurute tathā. 4.37",

      "na hi jñānena sadṛśaṃ(m), pavitramiha vidyate,\ntatsvayaṃ(m) yogasaṃsiddhaḥ(kh), kālenātmani vindati. 4.38",

      "śraddhāvā˜llabhate jñānaṃ(n), tatparaḥ(s) saṃyatendriyaḥ,\njñānaṃ(m) labdhvā parāṃ(m) śāntim, acireṇādhigacchati. 4.39",

      "ajñaścāśraddadhānaśca, saṃśayātmā vinaśyati,\nnāyaṃ(m) loko'sti na paro, na sukhaṃ(m) saṃśayātmanaḥ. 4.40",

      "yogasannyastakarmāṇaṃ(ñ), jñānasañchinnasaṃśayam,\nātmavantaṃ(n) na karmāṇi, nibadhnanti dhanañjaya. 4.41",

      "tasmādajñānasambhūtaṃ(m), hṛtsthaṃ(ñ) jñānāsinātmanaḥ,\nchittvainaṃ(m) saṃśayaṃ(m) yogam, ātiṣṭhottiṣṭha bhārata. 4.42",

      "Om tatsaditi śrīmadbhagavadgītāsu upaniṣatsu brahmavidyāyāṃ(y̐)\nyogaśāstre śrīkṛṣṇārjunasaṃvāde  jñānakarmasannyāsayogonāma caturtho'dhyāyaḥ"
      ],
      "adhyaya-5" : [   
      "arjuna uvācha\nsannyāsaṃ(ṅ) karmaṇāṃ(ṅ) kṛṣṇa, punaryogaṃ(ñ) ca śaṃsasi,\nyacchreya etayorekaṃ(n), tanme brūhi suniścitam. 5.1",

      "śrībhagavānuvāca\nsannyāsaḥ(kh) karmayogaśca, niḥśreyasakarāvubhau,\ntayostu karmasannyāsāt, karmayogo viśiṣyate. 5.2",

      "jñeyaḥ(s) sa nityasannyāsī, yo na dveṣṭi na kāṅkṣati,\nnirdvandvo hi mahābāho, sukhaṃ(m) bandhātpramucyate. 5.3",

      "sāṅkhyayogau pṛthagbālāḥ(ph), pravadanti na paṇḍitāḥ,\nekamapyāsthitaḥ(s) samyag, ubhayorvindate phalam. 5.4",

      "yatsāṅkhyaiḥ(ph) prāpyate sthānaṃ(n), tadyogairapi gamyate,\nekaṃ(m) sāṅkhyaṃ(ñ) ca yogaṃ(ñ) ca, yaḥ(ph) paśyati sa paśyati. 5.5",

      "sannyāsastu mahābāho, duḥkhamāptumayogataḥ,\nyogayukto munirbrahma, nacireṇādhigacchati. 5.6",

      "yogayukto viśuddhātmā, vijitātmā jitendriyaḥ,\nsarvabhūtātmabhūtātmā, kurvannapi na lipyate. 5.7",

      "naiva kiñcitkaromīti, yukto manyeta tattvavit,\npaśyañśṛṇvanspṛśañjighran, naśnangacchansvapañśvasan. 5.8",

      "pralapanvisṛjangṛhṇan, nunmiṣannimiṣannapi,\nindriyāṇīndriyārtheṣu, vartanta iti dhārayan. 5.9",

      "brahmaṇyādhāya karmāṇi, saṅgaṃ(n) tyaktvā karoti yaḥ,\nlipyate na sa pāpena, padmapatramivāmbhasā. 5.10",

      "kāyena manasā buddhyā, kevalairindriyairapi,\nyoginaḥ(kh) karma kurvanti, saṅgaṃ(n) tyaktvātmaśuddhaye. 5.11",

      "yuktaḥ(kh) karmaphalaṃ(n) tyaktvā, śāntimāpnoti naiṣṭhikīm,\nayuktaḥ(kh) kāmakāreṇa, phale sakto nibadhyate. 5.12",

      "sarVākarmāṇi manasā, sannyasyāste sukhaṃ(m) vaśī,\nnavadvāre pure dehī, naiva kurvanna kārayan. 5.13",

      "na kartṛtvaṃ(n) na karmāṇi, lokasya sṛjati prabhuḥ,\nna karmaphalasaṃyogaṃ(m), svabhāvastu pravartate. 5.14",

      "nādatte kasyacitpāpaṃ(n), na caiva sukṛtaṃ vibhuḥ,\najñānenāvṛtaṃ(ñ) jñānaṃ(ñ), tena muhyanti jantavaḥ. 5.15",

      "jñānena tu tadajñānaṃ(m), yeṣāṃ(n) nāśitamātmanaḥ,\nteṣāmādityavajjñānaṃ(m), prakāśayati tatparam. 5.16",

      "tadbuddhayastadātmānaḥ(s), tanniṣṭhāstatparāyaṇāḥ,\ngacchantyapunarāvṛttiṃ(ñ), jñānanirdhūtakalmaṣāḥ. 5.17",

      "vidyāvinayasaṃpanne, brāhmaṇe gavi hastini,\nśuni caiva(ś) śvapāke ca, paṇḍitāḥ(s) ṣamadarśinaḥ. 5.18",

      "ihaiva tairjitaḥ(s) sargo, yeṣāṃ(m) sāmye sthitaṃ(m) manaḥ,\nnirdoṣaṃ(m) hi ṣamaṃ(m) brahma, tasmād brahmaṇi te sthitāḥ. 5.19",

      "na prahṛṣyetpriyaṃ(m) prāpya, nodvijetprāpya cāpriyam,\nsthirabuddhirasaṃmūḍho, brahmavid brahmaṇi sthitaḥ. 5.20",

      "bāhyasparśeṣvasaktātmā, vindatyātmani yatsukham,\nsa brahmayogayuktātmā, sukhamakṣayamaśnute. 5.21",

      "ye hi saṃsparsajā bhogā, duḥkhayonaya eva te,\nādyantavantaḥ(kh) kaunteya, na teṣu Rāmate budhaḥ. 5.22",

      "śaknotīhaiva yaḥ(s) soḍhuṃ(m), prākṣarīravimokṣaṇāt,\nkāmakrodhodbhvaṃ(m) vegaṃ(m), sa yuktaḥ(s) sa sukhī naraḥ. 5.23",

      "yo'ntaḥ(s) sukho'ntarārāmaḥ(s), tathāntarjyotireva yaḥ,\nsa yogī brahmanirvāṇaṃ(m), brahmabhūto'dhigacchati. 5.24",

      "labhante brahmanirvāṇam, ṛṣayaḥ kṣīṇakalmaṣāḥ,\nchinnadvaidhā yatātmānaḥ(s), sarvabhūtahite ratāḥ. 5.25",

      "kāmakrodhaviyuktānāṃ(m), yatīnāṃ(m) yatacetasām,\nabhito brahmanirvāṇaṃ(m), vartate viditātmanām. 5.26",

      "sparśānkṛtvā bahirbāhyāṃś, cakṣuścaivāntare bhruvoḥ,\nprāṇāpānau ṣamau kṛtvā, nāsābhyantaracāriṇau. 5.27",

      "Yatendriyamanobuddhiḥ(r), munirmokṣaparāyaṇaḥ,\nvigatecchābhayakrodho, yaḥ sadā mukta eva saḥ. 5.28",

      "bhoktāraṃ(m) yajñatapasāṃ(m), sarvalokamaheśvaram,\nsuhṛdaṃ(m) sarvabhūtānāṃ(ñ), jñātvā māṃ(m) śāntimṛcchati. 5.29",

      "Om tatsaditi śrīmadbhagavadgītāsu upaniṣatsu brahmavidyāyāṃ(y̐)\nyogaśāstre śrīkṛṣṇārjunasaṃvāde karmasannyāsayogonāma pañchamo'dhyāyaḥ"
      ],
      "adhyaya-6" : [
      "anāśritaḥ(kh) karmaphalaṃ(ṅ), kāryaṃ(ṅ) karma karoti yaḥ,\nsa sannyāsī ca yogī ca, na niragnirna cākriyaḥ. 6.1",

      "yaṃ(m) sannyāsamiti prāhuḥ(r), yogaṃ(n) taṃ viddhi pāṇḍava,\nna hyasannyastasaṅkalpo, yogī bhavati kaścana. 6.2",

      "ārurukṣormuneryogaṃ(ṅ), karma kāraṇamucyate,\nyogārūḍhasya tasyaiva, śamaḥ(kh) kāraṇamucyate. 6.3",

      "yadā hi nendriyārtheṣu, na karmasvanuṣajjate,\nsarvasaṅkalpasannyāsī, yogārūḍhastadocyate. 6.4",

      "uddharedātmanātmānaṃ(n), nātmānamavasādayet,\nātmaiva hyātmano bandhuḥ(r), ātmaiva ripurātmanaḥ. 6.5",

      "bandhurātmātmanastasya, yenātmaivātmanā jitaḥ,\nanātmanastu śatrutve, vartetātmaiva śatruvat. 6.6",

      "jitātmanaḥ(ph) praśāntasya, paramātmā samāhitaḥ,\nśītoṣṇasukhaduḥkheṣu, tathā mānāpamānayoḥ. 6.7",

      "jñānavijñānatṛptātmā, kūṭastho vijitendriyaḥ,\nyukta ityucyate yogī, ṣamaloṣṭāśmakāñcanaḥ. 6.8",

      "Suhṛnmitrāryudāsīna, madhyasthadveṣyabandhuṣu,\nsādhuṣvapi ca pāpeṣu, ṣamabuddhirviśiṣyate. 6.9",

      "yogī yuñjīta satatam, ātmānaṃ(m) rahasi sthitaḥ,\nekākī yatacittātmā, nirāśīraparigrahaḥ. 6.10",

      "śucau deśe pratiṣṭhāpya, sthiramāsanamātmanaḥ,\nnātyucchritaṃ(n) nātinīcaṃ(ñ), cailājinakuśottaram. 6.11",

      "tatraikāgraṃ(m) manaḥ(kh) kṛtvā, yatacittendriyakriyāḥ,\nupaviśyāsane yuñjyād, yogamātmaviśuddhaye. 6.12",

      "ṣamaṃ(ṅ) kāyaśirogrīvaṃ(n), dhārayannacalaṃ(m) sthiraḥ,\nsaṃprekṣya nāsikāgraṃ(m) svaṃ(n), diśaścānavalokayan. 6.13",

      "praśāntātmā vigatabhīḥ(r), brahmacārivrate sthitaḥ,\nmanaḥ(s) saṃyamya maccitto, yukta āsīta matparaḥ. 6.14",

      "yuñjannevaṃ(m) sadātmānaṃ(m), yogī niyatamānasaḥ,\nśāntiṃ(n) nirvāṇaparamāṃ(m), matsaṃsthāmadhigacchati. 6.15",

      "nātyaśnatastu yogo'sti, na caikāntamanaśnataḥ,\nna cātisvapnaśīlasya, jāgrato naiva cārjuna. 6.16",

      "yuktāhāravihārasya, yuktaceṣṭasya karmasu,\nyuktasvapnāvabodhasya, yogo bhavati duḥkhahā. 6.17",

      "yadā viniyataṃ(ñ) cittam, ātmanyevāvatiṣṭhate,\nniḥspṛhaḥ(s) sarVākāmebhyo, yukta ityucyate tadā. 6.18",

      "yathā dīpo nivātastho, neṅgate sopamā smṛtā,\nyogino yatacittasya, yuñjato yogamātmanaḥ. 6.19",

      "yatropaRāmate cittaṃ(n), niruddhaṃ(m) yogasevayā,\nyatra caivātmanātmānaṃ(m), paśyannātmani tuṣyati. 6.20",

      "sukhamātyantikaṃ(m) yattad, buddhigrāhyamatīndriyam,\nvetti yatra na caivāyaṃ(m), sthitaścalati tattvataḥ. 6.21",

      "yaṃ(m) labdhvā cāparaṃ(m) lābhaṃ(m), manyate nādhikaṃ(n) tataḥ,\nyasminsthito na duḥkhena, guruṇāpi vicālyate. 6.22",

      "taṃ vidyād duḥkhasaṃyoga, viyogaṃ(m) yogasaṃjñitam,\nsa niścayena yoktavyo, yogo'nirviṇṇacetasā. 6.23",

      "saṅkalpaprabhavānkāmāṃs, tyaktvā sarvānaśeṣataḥ,\nmanasaivendriyagrāmaṃ(m), viniyamya ṣamantataḥ. 6.24",

      "śanaiḥ(s) śanairuparamed, buddhyā dhṛtigṛhītayā,\nātmasaṃsthaṃ(m) manaḥ(kh) kṛtvā, na kiñcidapi cintayet. 6.25",

      "yato yato niścarati, manaścañcalamasthiram,\ntatastato niyamyaitad, ātmanyeva vaśaṃ(n) na yet. 6.26",

      "praśāntamanasaṃ(m) hyenaṃ(m), yoginaṃ(m) sukhamuttamam,\nupaiti śāntarajasaṃ(m), brahmabhūtamakalmaṣam. 6.27",

      "yuñjannevaṃ(m) sadātmānaṃ(m), yogī vigatakalmaṣaḥ,\nsukhena brahmasaṃsparśam, atyantaṃ(m) sukhamaśnute. 6.28",

      "sarvabhūtasthamātmānaṃ(m), sarvabhūtāni cātmani,\nīkṣate yogayuktātmā, sarvatra ṣamadarśanaḥ. 6.29",

      "yo māṃ(m) paśyati sarvatra, sarvaṃ(ñ) ca mayi paśyati,\ntasyāhaṃ(n) na praṇaśyāmi, sa ca me na praṇaśyati. 6.30",

      "sarvabhūtasthitaṃ(m) yo māṃ(m), bhajatyekatvamāsthitaḥ,\nsarvathā vartamāno'pi, sa yogī mayi vartate. 6.31",

      "ātmaupamyena sarvatra, ṣamaṃ(m) paśyati yo'rjuna,\nsukhaṃ vā yadi vā duḥkhaṃ(m), sa yogī paramo mataḥ. 6.32",

      "arjuna uvāca\nyo'yaṃ(m) yogastvayā proktaḥ(s), sāmyena madhusūdana,\netasyāhaṃ(n) na paśyāmi, cañcalatvātsthitiṃ(m) sthirām. 6.33",

      "cañcalaṃ(m) hi manaḥ(kh) kṛṣṇa, pramāthi balavaddṛḍham,\ntasyāhaṃ(n) nigrahaṃ(m) manye, vāyoriva suduṣkaram. 6.34",

      "asaṃśayaṃ(m) mahābāho, mano durnigrahaṃ(ñ) calam,\nabhyāsena tu kaunteya, vairāgyeṇa ca gṛhyate. 6.35",

      "asaṃyatātmanā yogo, duṣprāpa iti me matiḥ,\nvaśyātmanā tu yatatā, śakyo'vāptumupāyataḥ. 6.36",

      "arjuna uvāca\nayatiḥ(ś) śraddhayopeto, yogāccalitamānasaḥ,\naprāpya yogasaṃsiddhiṃ(ṅ), kāṃ(ṅ) gatiṃ(ṅ) kṛṣṇa gacchati. 6.37",

      "kaccinnobhayavibhraṣṭaḥ(ś), chinnābhramiva naśyati,\napratiṣṭho mahābāho, vimūḍho brahmaṇaḥ(ph) pathi. 6.38",

      "etanme saṃśayaṃ(ṅ) kṛṣṇa, chettumarhasyaśeṣataḥ,\ntvadanyaḥ(s) saṃśayasyāsya, chettā na hyupapadyate. 6.39",

      "śrībhagavānuvāca\npārtha naiveha nāmutra, vināśastasya vidyate,\nna hi kalyāṇakṛtkaścid, durgatiṃ(n) tāta gacchati. 6.40",

      "prāpya puṇyakṛtāṃ(m) lokān, uṣitvā śāśvatīḥ(s) samāḥ,\nśucīnāṃ(m) śrīmatāṃ(ṅ) gehe, yogabhraṣṭo'bhijāyate. 6.41",

      "athavā yogināmeva, kule bhavati dhīmatām,\netaddhi durlabhataraṃ(m), loke janma yadīdṛśam. 6.42",

      "tatra taṃ(m) buddhisaṃyogaṃ(m), labhate paurvadehikam,\nyatate ca tato bhūyaḥ(s), saṃsiddhau kurunandana. 6.43",

      "pūrvābhyāsena tenaiva, hriyate hyavaśo'pi saḥ,\njijñāsurapi yogasya, śabdabrahmātivartate. 6.44",

      "prayatnādyatamānastu, yogī saṃśuddhakilbiṣaḥ,\nanekajanmasaṃsiddhaḥ(s), tato yāti parāṃ(ṅ) gatiṃ. 6.45",

      "tapasvibhyo'dhiko yogī, jñānibhyo'pi mato'dhikaḥ,\nkarmibhyaścādhiko yogī, tasmādyogī bhavārjuna. 6.46",

      "yogināmapi sarveṣāṃ(m), madgatenāntarātmanā,\nśraddhāvānbhajate yo māṃ(m), sa me yuktatamo mataḥ. 6.47",

      "Om tatsaditi śrīmadbhagavadgītāsu upaniṣatsu brahmavidyāyāṃ(y̐)\nyogaśāstre śrīkṛṣṇārjunasaṃvāde ātmasaṃyamayogonāma ṣaṣṭho'dhyāyaḥ."     
      ],
      "adhyaya-7" : [
      "śrībhagavānuvāca\nmayyāsaktamanāḥ(ph) pārtha, yogaṃ(m) yuñjanmadāśrayaḥ,\nasaṃśayaṃ(m) ṣamagraṃ(m) māṃ(m), yathā jñāsyasi tacchṛṇu. 7.1",

      "jñānaṃ(n) te'haṃ(m) savijñānam, idaṃ(m) Vākṣyāmyaśeṣataḥ,\nyajjñātvā neha bhūyo'nyaj, jñātavyamavaśiṣyate. 7.2",

      "manuṣyāṇāṃ(m) sahasreṣu, kaścidyatati siddhaye,\nyatatāmapi siddhānāṃ(ṅ), kaścinmāṃ(m) vetti tattvataḥ. 7.3",

      "bhūmirāpo'nalo vāyuḥ(kh), khaṃ(m) mano buddhireva ca,\nahaṅkāra itīyaṃ(m) me, bhinnā prakṛtiraṣṭadhā. 7.4",

      "apareyamitastvanyāṃ(m), prakṛtiṃ(m) viddhi me parām,\njīvabhūtāṃ(m) mahābāho, yayedaṃ(n) dhāryate jagat. 7.5",

      "etadyonīni bhūtāni, sarvāṇītyupadhāraya,\nahaṃ(ṅ) kṛtsnasya jagataḥ(ph), prabhavaḥ(ph) pralayastathā. 7.6",

      "mattaḥ(ph) parataraṃ(n) nānyat, kiñcidasti dhanañjaya,\nmayi sarvamidaṃ(m) protaṃ(m), sūtre maṇigaṇā iva. 7.7",

      "raso'hamapsu kaunteya, prabhāsmi śaśisūryayoḥ,\npraṇavaḥ(s) sarvavedeṣu, śabdaḥ(kh) khe pauruṣaṃ(n) nṛṣu. 7.8",

      "puṇyo gandhaḥ(ph) pṛthivyāṃ(ñ) ca, tejaścāsmi vibhāvasau,\njīvanaṃ(m) sarvabhūteṣu, tapaścāsmi tapasviṣu. 7.9",

      "bījaṃ(m) māṃ(m) sarvabhūtānāṃ(m), viddhi pārtha sanātanam,\nbuddhirbuddhimatāmasmi, tejastejasvināmaham. 7.10",

      "balaṃ(m) balavatāṃ(ñ) cāhaṃ(ṅ), kāmarāgavivarjitam,\ndharmāviruddho bhūteṣu, kāmo'smi bharatarṣabha. 7.11",

      "ye caiva sāttvikā bhāvā, rājasāstāmasāśca ye,\nmatta eveti tānviddhi, na tvahaṃ(n) teṣu te mayi. 7.12",

      "tribhirguṇamayairbhāvaiḥ(r), ebhiḥ(s) sarvamidaṃ(ñ) jagat,\nmohitaṃ(n) nābhijānāti, māmebhyaḥ(ph) paRāmavyayam. 7.13",

      "daivī hyeṣā guṇamayī, mama māyā duratyayā,\nmāmeva ye prapadyante, māyāmetāṃ(n) taranti te. 7.14",

      "na māṃ(n) duṣkṛtino mūḍhāḥ(ph), prapadyante narādhamāḥ,\nmāyayāpahṛtajñānā, āsuraṃ(m) bhāvamāśritāḥ. 7.15",

      "caturvidhā bhajante māṃ(ñ), janāḥ(s) sukṛtino'rjuna,\nārto jijñāsurarthārthī, jñānī ca bharatarṣabha. 7.16",

      "teṣāṃ(ñ) jñānī nityayukta, ekabhaktirviśiṣyate,\npriyo hi jñānino'tyartham, ahaṃ(m) sa ca mama priyaḥ. 7.17",

      "udārāḥ(s) sarva evaite, jñānī tvātmaiva me matam,\nāsthitaḥ(s) sa hi yuktātmā, māmevānuttamāṃ(ṅ) gatim. 7.18",

      "bahūnāṃ(ñ) janmanāmante, jñānavānmāṃ(m) prapadyate,\nvāsudevaḥ(s) sarvamiti, sa mahātmā sudurlabhaḥ. 7.19",

      "kāmaistaistairhṛtajñānāḥ(ph), prapadyante'nyadevatāḥ,\ntaṃ(n) taṃ(n) niyamamāsthāya, prakṛtyā niyatāḥ(s) svayā. 7.20",

      "yo yo yāṃ(m) yāṃ(n) tanuṃ(m) bhaktaḥ(ś), śraddhayārcitumicchati,\ntasya tasyācalāṃ(m) śraddhāṃ(n), tāmeva vidadhāmyaham. 7.21",

      "sa tayā śraddhayā yuktaḥ(s), tasyārādhanamīhate,\nlabhate ca tataḥ(kh) kāmān, mayaiva vihitānhi tān. 7.22",

      "antavattu phalaṃ(n) teṣāṃ(n), tadbhavatyalpaMedhāsām,\ndevāndevayajo yānti, madbhaktā yānti māmapi. 7.23",

      "avyaktaṃ(m) vyaktimāpannaṃ(m), manyante māmabuddhayaḥ,\nparaṃ bhāvamajānanto, mamāvyayamanuttamam. 7.24",

      "nāhaṃ(m) prakāśaḥ(s) sarvasya, yogamāyāsamāvṛtaḥ,\nmūḍho'yaṃ(n) nābhijānāti, loko māmajamavyayam. 7.25",

      "vedāhaṃ(m) ṣamatītāni, vartamānāni cārjuna,\nbhaviṣyāṇi ca bhūtāni, māṃ(n) tu veda na kaścana. 7.26",

      "icchādveṣasamutthena, dvandvamohena bhārata,\nsarvabhūtāni saṃmohaṃ(m), sarge yānti parantapa. 7.27",

      "yeṣāṃ(n) tvantagataṃ(m) pāpaṃ(ñ), janānāṃ(m) puṇyakarmaṇām,\nte dvandvamohanirmuktā, bhajante māṃ(n) dṛḍhavratāḥ. 7.28",

      "jarāmaraṇamokṣāya, māmāśritya yatanti ye,\nte brahma tadviduḥ(kh) kṛtsnam, adhyātmaṃ(ṅ) karma cākhilam. 7.29",

      "sādhibhūtādhidaivaṃ(m) māṃ(m), sādhiyajñaṃ(ñ) ca ye viduḥ,\nprayāṇakāle'pi ca māṃ(n), te viduryuktacetasaḥ. 7.30",

      "Om tatsaditi śrīmadbhagavadgītāsu upaniṣatsu brahmavidyāyāṃ(y̐)\nyogaśāstre śrīkṛṣṇārjunasaṃvāde jñānavijñānayogo nāma saptamo'dhyāyaḥ"
      ],
      "adhyaya-8" : [
      "arjuna uvāca\nkiṃ(n) tadbrahma kimadhyātmaṃ(ṅ), kiṃ(ṅ) karma puruṣottama,\nadhibhūtaṃ(ñ) ca kiṃ(m) proktam, adhidaivaṃ(ṅ) kimucyate. 8.1"
      ,
      "adhiyajñaḥ(kh) kathaṃ(ṅ) ko'tra, dehe'sminmadhusūdana,\nprayāṇakāle ca kathaṃ(ñ), jñeyo'si niyatātmabhiḥ. 8.2"
      ,
      "śrībhagavānuvāca\n akṣaraṃ(m) brahma paRāmaṃ(m), svabhāvo'dhyātmamucyate,\nbhūtabhāvodbhaVākaro, visargaḥ(kh) karmasaṃjñitaḥ. 8.3"
      ,
      "adhibhūtaṃ(ṅ) kṣaro bhāvaḥ(ph), puruṣaścādhidaivatam,\nadhiyajño'hamevātra, dehe dehabhṛtāṃ vara. 8.4"
      ,
      "antakāle ca māmeva, smaranmuktvā kalevaram,\nyaḥ(ph) prayāti sa madbhāvaṃ(m), yāti nāstyatra saṃśayaḥ. 8.5"
      ,
      "yaṃ(m) yaṃ(m) vāpi smaranbhāvaṃ(n), tyajatyante kalevaram,\ntaṃ(n) tamevaiti kaunteya, sadā tadbhāvabhāvitaḥ. 8.6"
      ,
      "tasmātsarveṣu kāleṣu, māmanusmara yudhya ca,\nmayyarpitamanobuddhiḥ(r), māmevaiṣyasyasaṃśayaḥ. 8.7"
      ,
      "abhyāsayogayuktena, cetasā nānyagāminā,\npaRāmaṃ(m) puruṣaṃ(n) divyaṃ, yāti pārthānucintayan. 8.8"
      ,
      "kaviṃ(m) purāṇamanuśāsitāraṃ, aṇoraṇīyaṃ(m) ṣamanusmaredyaḥ,\nsarvasya dhātāRāmacintyarūpaṃ(m), ādityavarṇaṃ(n) tamasaḥ(ph) parastāt. 8.9"
      ,
      "prayāṇakāle manasācalena, bhaktyā yukto yogabalena caiva,\nbhruvormadhye prāṇamāveśya samyak, sa taṃ(m) paraṃ(m) puruṣamupaiti divyam. 8.10",

      "yadakṣaraṃ(m) vedavido vadanti, viśanti yadyatayo vītarāgāḥ,\nyadicchanto brahmacaryaṃ(ñ) caranti, tatte padaṃ(m) saṃgraheṇa praVākṣye. 8.11",

      "sarvadvārāṇi saṃyamya, mano hṛdi nirudhya ca,\nmūrdhnyādhāyātmanaḥ(ph) prāṇam, āsthito yogadhāraṇām. 8.12",

      "omityekākṣaraṃ(m) brahma, vyāharanmāmanusmaran,\nyaḥ(ph) prayāti tyajandehaṃ(m), sa yāti paramāṃ(ṅ) gatim. 8.13",

      "ananyacetāḥ(s) satataṃ(m), yo māṃ(m) smarati nityaśaḥ,\ntasyāhaṃ(m) sulabhaḥ(ph) pārtha, nityayuktasya yoginaḥ. 8.14",

      "māmupetya punarjanma, duḥkhālayamaśāśvatam,\nnāpnuvanti mahātmānaḥ(s), saṃsiddhiṃ(m) paramāṃ(ṅ) gatāḥ. 8.15",

      "ābrahmabhuvanāllokāḥ(ph), punarāvartino'rjuna,\nmāmupetya tu kaunteya, punarjanma na vidyate. 8.16",

      "sahasrayugaparyantam, aharyadbrahmaṇo viduḥ,\nrātriṃ(m) yugasahasrāntāṃ(n), te'horātravido janāḥ. 8.17",

      "avyaktādvyaktayaḥ(s) sarvāḥ(ph), prabhavantyaharāgame,\nrātryāgame pralīyante, tatraivāvyaktasañjñake. 8.18",

      "bhūtagrāmaḥ(s) sa evāyaṃ(m), bhūtvā bhūtvā pralīyate,\nrātryāgame'vaśaḥ(ph) pārtha, prabhavatyaharāgame. 8.19",

      "parastasmāttu bhāvo'nyo-'vyakto'vyaktātsanātanaḥ,\nyaḥ(s) sa sarveṣu bhūteṣu, naśyatsu na vinaśyati. 8.20",

      "avyakto'kṣara ityuktaḥ(s), tamāhuḥ(ph) paramāṃ(ṅ) gatim,\nyaṃ(m) prāpya na nivartante, taddhāma paRāmaṃ(m) mama. 8.21",

      "puruṣaḥ(s) sa paraḥ(ph) pārtha, bhaktyā labhyastvananyayā,\nyasyāntaḥsthāni bhūtāni, yena sarvamidaṃ(n) tatam. 8.22",

      "yatra kāle tvanāvṛttim, āvṛttiṃ(ñ) caiva yoginaḥ,\nprayātā yānti taṃ(ṅ) kālaṃ(m), Vākṣyāmi bharatarṣabha. 8.23",

      "agnirjyotirahaḥ(ś) śuklaḥ(ṣ), ṣaṇmāsā uttarāyaṇam,\ntatra prayātā gacchanti, brahma brahmavido janāḥ. 8.24",

      "dhūmo rātristatā kṛṣṇaḥ(ṣ), ṣaṇmāsā dakṣiṇāyanam,\ntatra cāndRāmasaṃ(ñ) jyotir, yogī prāpya nivartate. 8.25",

      "śuklakṛṣṇe gatī hyete, jagataḥ(ś) śāśvate mate,\nekayā yātyanāvṛttim, anyayāvartate punaḥ. 8.26",

      "naite sṛtī pārtha jānan, yogī muhyati kaścana,\ntasmātsarveṣu kāleṣu, yogayukto bhavārjuna. 8.27",

      "vedeṣu yajñeṣu tapaḥsu caiva, dāneṣu yatpuṇyaphalaṃ(m) pradiṣṭam,\natyeti tatsarvamidaṃ(m) viditvā, yogī paraṃ(m) sthānamupaiti cādyam. 8.28",

      "Om tatsaditi śrīmadbhagavadgītāsu upaniṣatsu brahmavidyāyāṃ(y̐)\nyogaśāstre śrīkṛṣṇārjunasaṃvāde akṣarabrahmayogo nāma aṣṭamo'dhyāyaḥ"
      ],
      "adhyaya-9" : [
      "śrībhagavānuvāca\nidaṃ(n) tu te guhyatamaṃ(m), pravakṣyāmyanasūyave,\njñānaṃ(v̐) vijñānasahitaṃ(y̐), yajjñātvā mokṣyase'śubhāt. 9.1",

      "rājavidyā rājaguhyaṃ(m), pavitramidamuttamam,\npratyakṣāvagamaṃ(n) dharmyaṃ(m), susukhaṃ(ṅ) kartumavyayam. 9.2",

      "aśraddadhānāḥ(ph) puruṣā, dharmasyāsya parantapa,\naprāpya māṃ(n) nivartante, mṛtyusaṃsāravartmani 9.3",

      "mayā tatamidaṃ(m) sarvaṃ(ñ), jagadavyaktamūrtinā,\nmatsthāni sarvabhūtāni, na cāhaṃ(n) teṣvavasthitaḥ 9.4",

      "na ca matsthāni bhūtāni, paśya me yogamaiśvaram,\nbhūtabhṛnna ca bhūtastho, mamātmā bhūtabhāvanaḥ. 9.5",

      "yathākāśasthito nityaṃ(v̐), vāyuḥ(s) sarvatrago mahān,\ntathā sarvāṇi bhūtāni, matsthānītyupadhāraya.9.6",

      "sarvabhūtāni kaunteya, prakṛtiṃ(y̐) yānti māmikām,\nkalpakṣaye punastāni, kalpādau visṛjāmyaham.9.7",

      "prakṛtiṃ(m) svāmavaṣṭabhya, visṛjāmi punaḥ(ph) punaḥ,\nbhūtagrāmamimaṃ(ṅ) kṛtsnam, avaśaṃ(m) prakṛtervaśāt. 9.8",

      "na ca māṃ(n) tāni karmāṇi, nibadhnanti dhanañjaya,\nudāsīnavadāsīnam, asaktaṃ(n) teṣu karmasu. 9.9",

      "mayādhyakṣeṇa prakṛtiḥ(s), sūyate sacarācaram,\nhetunānena kaunteya, jagadviparivartate. 9.10",

      "avajānanti māṃ(m) mūḍhā, mānuṣīṃ(n) tanumāśritam,\nparaṃ(m) bhāvamajānanto, mama bhūtamaheśvaram. 9.11",

      "moghāśā moghakarmāṇo, moghajñānā vicetasaḥ,\nrākṣasīmāsurīṃ(ñ) caiva, prakṛtiṃ(m) mohinīṃ(m) śritāḥ. 9.12",

      "mahātmānastu māṃ(m) pārtha, daivīṃ(m) prakṛtimāśritāḥ,\nbhajantyananyamanaso, jñātvā bhūtādimavyayam. 9.13",

      "satataṃ(ṅ) kīrtayanto māṃ(y̐), yatantaśca dṛḍhavratāḥ,\nnamasyantaśca māṃ(m) bhaktyā, nityayuktā upāsate.9.14",

      "jñānayajñena cāpyanye, yajanto māmupāsate,\nekatvena pṛthaktvena, bahudhā viśvatomukham. 9.15",

      "ahaṃ(ṅ) kraturahaṃ(y̐) yajñaḥ(s), svadhāhamahamauṣadham,\nmantro'hamahamevājyam, ahamagnirahaṃ(m) hutam.9.16",

      "pitāhamasya jagato, mātā dhātā pitāmahaḥ,\nvedyaṃ(m) pavitramoṅkāra, ṛksāma yajureva ca. 9.17",

      "gatirbhartā prabhuḥ(s) sākṣī, nivāsaḥ(ś) śaraṇaṃ suhṛt,\nprabhavaḥ(ph) pralayaḥ(s) sthānaṃ(n), nidhānaṃ(m) bījamavyayam. 9.18",

      "tapāmyahamahaṃ(v̐) varṣaṃ(n), nigṛhṇāmyutsṛjāmi ca,\namṛtaṃ(ñ) caiva mṛtyuśca, sadasaccāhamarjuna.9.19",

      "traividyā māṃ(m) somapāḥ(ph) pūtapāpā, yajñairiṣṭvā svargatiṃ(m) prārthayante,\nte puṇyamāsādya surendralokam, aśnanti divyāndivi devabhogān. 9.20",

      "te taṃ(m) bhuktvā svargalokaṃ(v̐) viśālaṃ(ṅ), kṣīṇe puṇye martyalokaṃ(v̐) viśanti,\nevaṃ(n) trayīdharmamanuprapannā, gatāgataṃ(ṅ) kāmakāmā labhante.9.21",

      "ananyāścintayanto māṃ(y̐), ye janāḥ(ph) paryupāsate,\nteṣāṃ(n) nityābhiyuktānāṃ(y̐), yogakṣemaṃ(v̐) vahāmyaham.9.22",

      "ye'pyanyadevatā bhaktā, yajante śraddhayānvitāḥ,\nte'pi māmeva kaunteya, yajantyavidhipūrVākam. 9.23",

      "ahaṃ(m) hi sarvayajñānāṃ(m), bhoktā ca prabhureva ca,\nna tu māmabhijānanti, tattvenātaścyavanti te. 9.24",

      "yānti devavratā devān, pitṝnyānti pitṛvratāḥ,\nbhūtāni yānti bhūtejyā, yānti madyājino'pi mām. 9.25",

      "patraṃ(m) puṣpaṃ(m) phalaṃ(n) toyaṃ(y̐), yo me bhaktyā prayacchati,\ntadahaṃ(m) bhaktyupahṛtam, aśnāmi prayatātmanaḥ.9.26",

      "yatkaroṣi yadaśnāsi, yajjuhoṣi dadāsi yat,\nyattapasyasi kaunteya, tatkuruṣva madarpaṇam. 9.27",

      "śubhāśubhaphalairevaṃ(m), mokṣyase karmabandhanaiḥ,\nsannyāsayogayuktātmā, vimukto māmupaiṣyasi. 9.28",

      "samo'haṃ(m) sarvabhūteṣu, na me dveṣyo'sti na priyaḥ,\nye bhajanti tu māṃ(m) bhaktyā, mayi te teṣu cāpyaham. 9.29",

      "api cetsudurācāro, bhajate māmananyabhāk,\nsādhureva sa mantavyaḥ(s), samyagvyavasito hi saḥ. 9.30",

      "kṣipraṃ(m) bhavati dharmātmā, śaśvacchāntiṃ(n) nigacchati,\nkaunteya pratijānīhi, na me bhaktaḥ(ph) praṇaśyati. 9.31",

      "māṃ(m) hi pārtha vyapāśritya, ye'pi syuḥ(ph) pāpayonayaḥ,\nstriyo vaiśyāstathā śūdrās, te'pi yānti parāṃ(ṅ) gatim. 9.32",

      "kiṃ(m) punarbrāhmaṇāḥ(ph) puṇyā, bhaktā rājarṣayastathā,\nanityamasukhaṃ(m) lokam, imaṃ(m) prāpya bhajasva mām. 9.33",

      "manmanā bhava madbhakto, madyājī māṃ(n) namaskuru,\nmāmevaiṣyasi yuktvaivam, ātmānaṃ(m) matparāyaṇaḥ. 9.34",

      "om tatsaditi śrīmadbhagavadgītāsu upaniṣatsu brahmavidyāyāṃ(y̐)\nyogaśāstre śrīkṛṣṇārjunasaṃvāde rājavidyārājaguhyayogo nāma navamo'dhyāyaḥ."
      ],
      "adhyaya-10" : [
      "śrībhagavānuvāca\nbhūya eva mahābāho, śṛṇu me paRāmaṃ(m) vacaḥ,\nyatte'haṃ(m) prīyamāṇāya, Vākṣyāmi hitakāmyayā. 10.1",

      "na me viduḥ(s) suragaṇāḥ(ph), prabhavaṃ(n) na maharṣayaḥ,\nahamādirhi devānāṃ(m), maharṣīṇāṃ(ñ) ca sarvaśaḥ. 10.2",

      "yo māmajamanādiṃ(ñ) ca, vetti lokamaheśvaram,\nasaṃmūḍhaḥ(s) sa martyeṣu, sarvapāpaiḥ(ph) pramucyate. 10.3",

      "buddhirjñānamasaṃmohaḥ, kṣamā satyaṃ(n) damaḥ(ś) śamaḥ,\nsukhaṃ(n) duḥkhaṃ(m) bhavo'bhāvo, bhayaṃ(ñ) cābhayameva ca. 10.4",

      "ahiṃsā ṣamatā tuṣṭiḥ(s), tapo dānaṃ(m) yaśo'yaśaḥ,\nbhavanti bhāvā bhūtānāṃ(m), matta eva pṛthagvidhāḥ. 10.5",

      "maharṣayaḥ(s) sapta pūrve, catvāro manavastathā,\nmadbhāvā mānasā jātā, yeṣāṃ(m) loka imāḥ(ph) prajāḥ. 10.6",

      "etāṃ(m) vibhūtiṃ(m) yogaṃ(ñ) ca, mama yo vetti tattvataḥ,\nso'vikampena yogena, yujyate nātra saṃśayaḥ. 10.7",

      "ahaṃ(m) sarvasya prabhavo, mattaḥ(s) sarvaṃ(m) pravartate,\niti matvā bhajante māṃ(m), budhā bhāvaṣamanvitāḥ. 10.8",

      "maccittā madgataprāṇā, bodhayantaḥ(ph) parasparam,\nkathayantaśca māṃ(n) nityaṃ(n), tuṣyanti ca Rāmanti ca. 10.9",

      "teṣāṃ(m) satatayuktānāṃ(m), bhajatāṃ(m) prītipūrVākam,\ndadāmi buddhiyogaṃ(n) taṃ(m), yena māmupayānti te. 10.10",

      "teṣāmevānukampārtham, ahamajñānajaṃ(n) tamaḥ,\nnāśayāmyātmabhāvastho, jñānadīpena bhāsvatā. 10.11",

      "arjuna uvāca\nparaṃ(m) brahma paraṃ(n) dhāma, pavitraṃ(m) paRāmaṃ(m) bhavān,\npuruṣaṃ(m) śāśvataṃ(n) divyam, ādidevamajaṃ(m) vibhum. 10.12",

      "āhustvāmṛṣayaḥ(s) sarve, devarṣirnāradastathā,\nasito devalo vyāsaḥ(s), svayaṃ(ñ) caiva bravīṣi me. 10.13",

      "sarvametadṛtaṃ(m) manye, yanmāṃ(m) vadasi keśava,\nna hi te bhagavanvyaktiṃ(m), vidurdevā na dānavāḥ. 10.14",

      "svayamevātmanātmānaṃ(m), vettha tvaṃ(m) puruṣottama,\nbhūtabhāvana bhūteśa, devadeva jagatpate. 10.15",

      "Vāktumarhasyaśeṣeṇa, divyā hyātmavibhūtayaḥ,\nyābhirvibhūtibhirlokān, imāṃstvaṃ(m) vyāpya tiṣṭhasi. 10.16",

      "kathaṃ(m) vidyāmahaṃ(m) yogiṃs, tvāṃ(m) sadā paricintayan,\nkeṣu keṣu ca bhāveṣu, cintyo'si bhagavanmayā. 10.17",

      "vistareṇātmano yogaṃ(m), vibhūtiṃ() ca janārdana,\nbhūyaḥ(kh) kathaya tṛptirhi, śṛṇvato nāsti me'mṛtam. 10.18",

      "śrībhagavānuvāca\nhanta te kathayiṣyāmi, divyā hyātmavibhūtayaḥ,\nprādhānyataḥ(kh) kuruśreṣṭha, nāstyanto vistarasya me. 10.19",

      "ahamātmā guḍākeśa, sarvabhūtāśayasthitaḥ,\nahamādiśca madhyaṃ(ñ) ca, bhūtānāmanta eva ca. 10.20",

      "ādityānāmahaṃ viṣṇuḥ(r), jyotiṣāṃ(m) raviraṃśumān,\nmarīcirmarutāmasmi, nakṣatrāṇāmahaṃ(m) śaśī. 10.21",

      "vedānāṃ(m) sāmavedo'smi, devānāmasmi vāsavaḥ,\nindriyāṇāṃ(m) manaścāsmi, bhūtānāmasmi cetanā. 10.22",

      "rudrāṇāṃ(m) śaṅkaraścāsmi, vitteśo yakṣarakṣasām,\nvasūnāṃ(m) pāVākaścāsmi, meruḥ(ś) śikhariṇāmaham.  10.23",

      "purodhasāṃ(ñ) ca mukhyaṃ(m) māṃ(m), viddhi pārtha bṛhaspatim,\nsenānīnāmahaṃ(m) skandaḥ(s), sarasāmasmi sāgaraḥ. 10.24",

      "maharṣīṇāṃ(m) bhṛgurahaṃ(ṅ), girāmasmyekamakṣaram,\nyajñānāṃ(ñ) japayajño'smi, sthāvarāṇāṃ(m) himālayaḥ. 10.25",

      "aśvatthaḥ(s) sarvavṛkṣāṇāṃ(n) devarṣīṇāṃ(ñ) ca nāradaḥ,\ngandharvāṇāṃ citrarathaḥ(s) siddhānāṃ(ṅ) kapilo muniḥ. 10.26",

      "uccaiḥśravaṣamaśvānāṃ(m), viddhi māmamṛtodbhavam,\nairāvataṃ(ṅ) gajendrāṇāṃ(n), narāṇāṃ(ñ) ca narādhipam. 10.27",

      "āyudhānāmahaṃ(m) vajraṃ(m) dhenūnāmasmi kāmadhuk,\nprajanaścāsmi kandarpaḥ(s), sarpāṇāmasmi vāsukiḥ. 10.28",

      "anantaścāsmi nāgānāṃ(m), varuṇo yādasāmaham,\npitṝṇāmaryamā cāsmi, yamaḥ(s) saṃyamatāmaham.10.29",

      "prahlādaścāsmi daityānāṃ(ṅ), kālaḥ(kh) kalayatāmaham,\nmṛgāṇāṃ(ñ) ca mṛgendro'haṃ(m), vainateyaśca pakṣiṇām. 10.30",

      "pavanaḥ(ph) pavatāmasmi, rāmaḥ(ś) śastrabhṛtāmaham,\njhaṣāṇāṃ(m) makaraścāsmi, srotasāmasmi jāhnavī. 10.31",

      "sargāṇāmādirantaśca, madhyaṃ(ñ) caivāhamarjuna,\nadhyātmavidyā vidyānāṃ(m), vādaḥ(ph) pravadatāmaham. 10.32",

      "akṣarāṇāmakāro'smi, dvandvaḥ(s) sāmāsikasya ca,\nahamevākṣayaḥ(kh) kālo, dhātā'haṃ(m) viśvatomukhaḥ. 10.33",

      "mṛtyuḥ(s) sarvaharaścāham, udbhavaśca bhaviṣyatām,\nkīrtiḥ(ś) śrīrvākca nārīṇāṃ(m), smṛtirmedhā dhṛtiḥ kṣamā. 10.34",

      "bṛhatsāma tathā sāmnāṃ(ṅ), gāyatrī chandasāmaham,\nmāsānāṃ(m) mārgaśīrṣo'ham, ṛtūnāṃ(ṅ) kusumākaraḥ. 10.35",

      "dyutaṃ(ñ) chalayatāmasmi, tejastejasvināmaham,\njayo'smi vyavasāyo'smi, sattvaṃ(m) sattvavatāmaham. 10.36",

      "vṛṣṇīnāṃ(m) vāsudevo'smi, pāṇḍavānāṃ(n) dhanañjayaḥ,\nmunīnāmapyahaṃ(m), vyāsaḥ(kh) kavīnāmuśanā kaviḥ. 10.37",

      "daṇḍo damayatāmasmi, nītirasmi jigīṣatām,\nmaunaṃ(ñ) caivāsmi guhyānāṃ(ñ), jñānaṃ(ñ) jñānavatāmaham. 10.38",

      "yaccāpi sarvabhūtānāṃ(m), bījaṃ(n) tadahamarjuna,\nna tadasti vinā yatsyān, mayā bhūtaṃ(ñ) carācaram. 10.39",

      "nānto'sti mama divyānāṃ(m), vibhūtīnāṃ(m) parantapa,\neṣa tūddeśataḥ(ph) prokto, vibhūtervistaro mayā. 10.40",

      "yadyadvibhūtimatsattvaṃ(m), śrīmadūrjitameva vā,\ntattadevāvagaccha tvaṃ(m), mama tejoṃśasaṃbhavam. 10.41",

      "athavā bahunaitena, kiṃ(ñ) jñātena tavārjuna,\nviṣṭabhyāhamidaṃ(ṅ) kṛtsnam, ekāṃśena sthito jagat. 10.42",

      "Om tatsaditi śrīmadbhagavadgītāsu upaniṣatsu brahmavidyāyāṃ(y̐)\nyogaśāstre śrīkṛṣṇārjunasaṃvāde vibhūtiyogo nāma daśamo'dhyāyaḥ",
      ],
      "adhyaya-11" : [
      "arjuna uvāca\nmadanugrahāya paRāmaṃ(ṅ), guhyamadhyātmasañjñitam,\nyattvayoktaṃ(m) vacastena, moho'yaṃ(m) vigato mama. 11.1",

      "bhavāpyayau hi bhūtānāṃ(m), śrutau vistaraśo mayā,\ntvattaḥ(kh) kamalapatrākṣa, māhātmyamapi cāvyayam. 11.2",

      "evametadyathāttha tvam, ātmānaṃ(m) parameśvara,\ndraṣṭumicchāmi te rūpam, aiśvaraṃ(m) puruṣottama. 11.3",

      "manyase yadi tacchakyaṃ(m), mayā draṣṭumiti prabho,\nyogeśvara tato me tvaṃ(n), darśayātmānamavyayam. 11.4",

      "śrībhagavānuvāca\npaśya me pārtha rūpāṇi, śataśo'tha sahasraśaḥ,\nnānāvidhāni divyāni, nānāvarṇākṛtīni ca. 11.5",

      "paśyādityānvasūnrudrān, aśvinau marutastathā,\nbahūnyadṛṣṭapūrvāṇi, paśyāścaryāṇi bhārata. 11.6",

      "ihaikasthaṃ(ñ) jagatkṛtsnaṃ(m), paśyādya sacarācaram,\nmama dehe guḍākeśa, yaccānyaddraṣṭumicchasi. 11.7",

      "na tu māṃ(m) śakyase draṣṭum, anenaiva svacakṣuṣā,\ndivyaṃ(n) dadāmi te cakṣuḥ(ph), paśya me yogamaiśvaram. 11.8",

      "sañjaya uvāca\nevamuktvā tato rājan, mahāyogeśvaro hariḥ,\ndarśayāmāsa pārthāya, paRāmaṃ(m) rūpamaiśvaram. 11.9",

      "anekaVāktranayanam, anekādbhutadarśanam,\nanekadivyābharaṇaṃ(n), divyānekodyatāyudham. 11.10",

      "divyamalyāmbaradharaṃ(n), divyagandhānulepanam,\nsarvāścaryamayaṃ(n) devam, anantaṃ(m) viśvatomukham. 11.11",

      "divi sūryasahasrasya, bhavedyugapadutthitā,\nyadi bhāḥ(s) sadṛśī sā syād, bhāsastasya mahātmanaḥ. 11.12",

      "tatraikasthaṃ(ñ) jagatkṛtsnaṃ(m), pravibhaktamanekadhā,\napaśyaddevadevasya, śarīre pāṇḍavastadā. 11.13",

      "tataḥ(s) sa vismayāviṣṭo, hṛṣṭaromā dhanañjayaḥ,\npraṇamya śirasā devaṃ(ṅ), kṛtāñjalirabhāṣata. 11.14",

      "arjuna uvāca\npaśyāmi devāṃstava deva dehe, sarvāṃstathā bhūtaviśeṣasaṅghān,\nbrahmāṇamīśaṃ(ṅ) kamalāsanastham, ṛṣīṃśca sarvānuragāṃśca divyān. 11.15",

      "anekabāhūdaraVāktranetraṃ(m), paśyāmi tvāṃ(m) sarvato'nantarūpam,\nnāntaṃ(n) na madhyaṃ(n) na punastavādiṃ(m), paśyāmi viśveśvara viśvarūpa. 11.16",

      "kirīṭinaṃ(ṅ) gadinaṃ(ñ) cakriṇaṃ(ñ) ca, tejorāśiṃ(m) sarvato dīptimantam,\npaśyāmi tvāṃ(n) durnirīkṣyaṃ(m) ṣamantād- dīptānalārkadyutimaprameyam. 11.17",

      "tvamakṣaraṃ(m) paRāmaṃ(m) veditavyaṃ(n), tvamasya viśvasya paraṃ(n) nidhānam,\ntvamavyayaḥ(ś) śāśvatadharmagoptā, sanātanastvaṃ(m) puruṣo mato me. 11.18",

      "Anādimadhyāntamanantavīryam, anantabāhuṃ(m) śaśisūryanetram,\npaśyāmi tvāṃ(n) dīptahutāśaVāktraṃ, svatejasā viśvamidaṃ(n) tapantam. 11.19",

      "dyāvāpṛthivyoridamantaraṃ(m) hi, vyāptaṃ(n) tvayaikena diśaśca sarvāḥ,\ndṛṣṭvādbhutaṃ(m) rūpamugraṃ(n) tavedaṃ(m), lokatrayaṃ(m) pravyathitaṃ(m) mahātman. 11.20",

      "amī hi tvāṃ(m) surasaṅghā viśanti, kecidbhītāḥ(ph) prāñjalayo gṛṇanti,\nsvastītyuktvā maharṣisiddhasaṅghāḥ(s), stuvanti tvāṃ(m) stutibhiḥ(ph) puṣkalābhiḥ. 11.21",

      "rudrādityā vasavo ye ca sādhyā, viśveśvinau marutaścoṣmapāśca,\ngandharvayakṣāsurasiddhasaṅghā, vīkṣante tvāṃ(m) vismitāścaiva sarve. 11.22",

      "rūpaṃ(m) mahatte bahuVāktranetraṃ(m), mahābāho bahubāhūrupādam,\nbahūdaraṃ(m) bahudaṃṣṭrākarālaṃ(n), dṛṣṭvā lokāḥ(ph) pravyathitāstathāham. 11.23",

      "nabhaḥspṛśaṃ(n) dīptamanekavarṇaṃ(m), vyāttānanaṃ(n) dīptaviśālanetram,\ndṛṣṭvā hi tvāṃ(m) pravyathitāntarātmā, dhṛtiṃ(n) na vindāmi śamaṃ(ñ) ca viṣṇo. 11.24",

      "daṃṣṭrākarālāni ca te mukhāni, dṛṣṭvaiva kālānalasannibhāni,\ndiśo na jāne na labhe ca śarma, prasīda deveśa jagannivāsa. 11.25",

      "amī ca tvāṃ(n) dhṛtarāṣṭrasya putrāḥ(s), sarve sahaivāvanipālasaṅghaiḥ,\nbhīṣmo droṇaḥ(s) sūtaputrastathāsau, sahāsmadīyairapi yodhamukhyaiḥ. 11.26",

      "Vāktrāṇi te tvaramāṇā viśanti, daṃṣṭrākarālāni bhayānakāni,\nkecidvilagnā daśanāntareṣu, sandṛśyante cūrṇitairuttamāṅgaiḥ. 11.27",

      "yathā nadīnāṃ(m) bahavo’mbuvegāḥ(s), samudramevābhimukhā dravanti,\ntathā tavāmī naralokavīrā, viśanti Vāktrāṇyabhivijvalanti. 11.28",

      "yathā pradīptaṃ(ñ) jvalanaṃ(m) pataṅgā, viśanti nāśāya samṛddhavegāḥ,\ntathaiva nāśāya viśanti lokāḥ(s), tavāpi Vāktrāṇi samṛddhavegāḥ. 11.29",

      "lelihyase grasamānaḥ(s) ṣamantāl, lokānṣamagrānvadanairjvaladbhiḥ,\ntejobhirāpūrya jagatṣamagraṃ(m), bhāsastavogrāḥ(ph) pratapanti viṣṇo. 11.30",

      "ākhyāhi me ko bhavānugrarūpo, namo'stu te devavara prasīda,\nvijñātumicchāmi bhavantamādyaṃ(n), na hi prajānāmi tava pravṛttim. 11.31",

      "śrībhagavānuvāca\nkālo'smi lokakṣayakṛtpravṛddho, lokān samāhartumiha pravṛttaḥ,\nṛte'pi tvāṃ(n) na bhaviṣyanti sarve, ye'vasthtitāḥ(ph) pratyanīkeṣu yodhāḥ. 11.32",

      "tasmāttvamuttiṣṭha yaśo labhasva, jitvā śatrūn bhuṅkṣva rājyaṃ(m) samṛddham,\nmayaivaite nihatāḥ(ph) pūrvameva, nimittamātraṃ(m) bhava savyasācin. 11.33",

      "droṇaṃ(ñ) ca bhīṣmaṃ(ñ) ca jayadrathaṃ(ñ) ca, karṇaṃ(n) tathānyānapi yodhavīrān,\nmayā hatāṃstvaṃ(ñ) jahi māvyathiṣṭhā yudhyasva jetāsi raṇe sapatnān. 11.34",

      "sañjaya uvāca\netacchrutvā vacanaṃ(ṅ) keśavasya, kṛtāñjalirvepamānaḥ(kh) kirīṭī,\nnamaskṛtvā bhūya evāha kṛṣṇaṃ(m), sagadgadaṃ(m) bhītabhītaḥ(ph) praṇamya. 11.35",

      "arjuna uvāca\nsthāne hṛṣīkeśa tava prakīrtyā, jagatprahṛṣyatyanurajyate ca,\nrakṣāṃsi bhītāni diśo dravanti, sarve namasyanti ca siddhasaṅghāḥ. 11.36",

      "kasmācca te na nameranmahātman, garīyase brahmaṇo'pyādikartre,\nananta deveśa jagannivāsa, tvamakṣaraṃ(m) sadasattatparaṃ(m) yat. 11.37",

      "tvamādidevaḥ(ph) puruṣaḥ(ph) purāṇaḥ(s), tvamasya viśvasya paraṃ(n) nidhānam,\nvettāsi vedyaṃ(ñ) ca paraṃ(ñ) ca dhāma, tvayā tataṃ(m) viśvamanantarūpa. 11.38",

      "vāyuryamo'gnirvaruṇaḥ(ś) śaśāṅkaḥ(ph), prajāpatistvaṃ(m) prapitāmahaśca,\nnamo namaste'stu sahasrakṛtvaḥ(ph), punaśca bhūyo'pi namo namaste. 11.39",

      "namaḥ(ph) purastādatha pṛṣṭhataste, namo'stu te sarvata eva sarva,\nanantavīryāmitavikRāmastvaṃ(m), sarvaṃ(m) samāpnoṣi tato'si sarvaḥ. 11.40",

      "sakheti matvā prasabhaṃ(m) yaduktaṃ(m), he kṛṣṇa he yādava he sakheti,\najānatā mahimānaṃ(n) tavedaṃ(m), mayā pramādātpraṇayena vā'pi. 11.41",

      "yaccāvahāsārthamasatkṛto'si, vihāraśayyāsanabhojaneṣu,\neko'thavāpyacyuta tatṣamakṣaṃ(n), tatkṣāmaye tvāmahamaprameyam. 11.42",

      "pitāsi lokasya carācarasya, tvamasya pūjyaśca gururgarīyān,\nna tvatsamo'styabhyadhikaḥ(kh) kuto'nyo, lokatraye'pyapratimaprabhāva. 11.43",

      "tasmātpraṇamya praṇidhāya kāyaṃ(m), prasādaye tvāmahamīśamīḍyam,\npiteva putrasya sakheva sakhyuḥ(ph), priyaḥ(ph) priyāyārhasi deva soḍhum. 11.44",

      "adṛṣṭapūrvaṃ(m) hṛṣito'smi dṛṣṭvā, bhayena ca pravyathitaṃ(m) mano me,\ntadeva me darśaya deva rūpaṃ(m), prasīda deveśa jagannivāsa. 11.45",

      "kirīṭinaṃ(ṅ) gadinaṃ(ñ) cakrahastaṃ(m), icchāmi tvāṃ(n) draṣṭumahaṃ(n) tathaiva,\ntenaiva rūpeṇa caturbhujena, sahasrabāho bhava viśvamūrte. 11.46",

      "śrībhagavānuvāca\nmayā prasannena tavārjunedaṃ(m), rūpaṃ(m) paraṃ(n) darśitamātmayogāt,\ntejomayaṃ(m) viśvamanantamādyaṃ(m), yanme tvadanyena na dṛṣṭapūrvam. 11.47",

      "na vedayajñādhyayanairna dānaiḥ(r), na ca kriyābhirna tapobhirugraiḥ,\nevaṃrūpaḥ(ś) śakya ahaṃ(n) nṛloke, draṣṭuṃ(n) tvadanyena kurupravīra. 11.48",

      "mā te vyathā mā ca vimūḍhabhāvo, dṛṣṭvā rūpaṃ(ṅ) ghoramīdṛṅmamedam,\nvyapetabhīḥ(ph) prītamanāḥ(ph) punastvaṃ(n), tadeva me rūpamidaṃ(m) prapaśya. 11.49",

      "sañjaya uvāca\nityarjunaṃ(m) vāsudevastathoktvā, sVākaṃ(m) rūpaṃ(n) darśayāmāsa bhūyaḥ,\nāśvāsayāmāsa ca bhītamenaṃ(m), bhūtvā punaḥ(s) saumyavapurmahātmā. 11.50",

      "arjuna uvāca\ndṛṣṭvedaṃ(m) mānuṣaṃ(m) rūpaṃ(n), tava saumyaṃ(ñ) janārdana,\nidānīmasmi saṃvṛttaḥ(s), sacetāḥ(ph) prakṛtiṃ(ṅ) gataḥ. 11.51",

      "śrībhagavānuvāca\nsudurdarśamidaṃ(m) rūpaṃ(n), dṛṣṭavānasi yanmama,\ndevā apyasya rūpasya, nityaṃ(n) darśanakāṅkṣiṇaḥ. 11.52",

      "nāhaṃ(m) vedairna tapasā, na dānena na cejyayā,\nśakya evaṃvidho draṣṭuṃ(n), dṛṣṭavānasi māṃ(m) yathā. 11.53",

      "bhaktyā tvananyayā śakya, ahamevaṃvidho'rjuna,\njñātuṃ(n) draṣṭuṃ(ñ) ca tatvena, praveṣṭuṃ(ñ) ca parantapa. 11.54",

      "matkarmakṛnmatparamo, madbhaktaḥ(s) saṅgavarjitaḥ,\nnirvairaḥ(s) sarvabhūteṣu, yaḥ(s) sa māmeti pāṇḍava. 11.55",

      "Om tatsaditi śrīmadbhagavadgītāsu upaniṣatsu brahmavidyāyāṃ(y̐)\nyogaśāstre śrīkṛṣṇārjunasaṃvāde viśvarūpadarśanayogonāma ekādaśo'dhyāyaḥ"
      ],
      "adhyaya-12" : [
      "arjuna uvāca\nevaṁ(m) satatayuktā ye, bhaktāstvāṁ(m) paryupāsate,\nye cāpyakṣaRāmavyaktaṁ(n), teṣāṁ(ṅ) ke yogavittamāḥ. 12.1",

      "śrībhagavānuvāca\nmayyāveśya mano ye māṁ(n), nityayuktā upāsate,\nśraddhayā parayopetāḥ(s), te me yuktatamā matāḥ. 12.2",

      "ye tVākṣaRāmanirdeśyaṁ(m), avyaktaṁ(m) paryupāsate,\nsarvatragamacintyaṁ(ñ) ca, kūṭasthamacalaṁ(n) dhruvam. 12.3",

      "sanniyamyendriyagrāmaṁ(m), sarvatra ṣamabuddhayaḥ,\nte prāpnuvanti māmeva, sarvabhūtahite ratāḥ. 12.4",

      "kleśo'dhikatarasteṣāṁ(m), avyaktāsaktacetasām,\navyaktā hi gatirduḥkhaṁ(n), dehavadbhiravāpyate. 12.5",

      "ye tu sarvāṇi karmāṇi, mayi sannyasya matparāḥ,\nananyenaiva yogena, māṁ(n) dhyāyanta upāsate. 12.6",

      "teṣāmahaṁ(m) samuddhartā, mṛtyusaṁsārasāgarāt,\nbhavāmi nacirātpārtha, mayyāveśitacetasām. 12.7",

      "mayyeva mana ādhatsva, mayi buddhiṁ(n) niveśaya,\nnivasiṣyasi mayyeva, ata ūrdhvaṁ(n) na saṁśayaḥ. 12.8",

      "atha cittaṁ(m) samādhātuṁ(n), na śaknoṣi mayi sthiram,\nabhyāsayogena tato, māmicchāptuṁ(n) dhanañjaya. 12.9",

      "abhyāse'pyaṣamartho'si, matkarmaparamo bhava,\nmadarthamapi karmāṇi, kurvansiddhimavāpsyasi. 12.10",

      "athaitadapyaśakto'si, kartuṁ(m) madyogamāśritaḥ,\nsarVākarmaphalatyāgaṁ(n), tataḥ(kh) kuru yatātmavān. 12.11",

      "śreyo hi jñānamabhyāsāj, jñānāddhyānaṁ(v̐) viśiṣyate,\ndhyānātkarmaphalatyāgaḥ(s), tyāgācchāntiranantaram. 12.12",

      "adveṣṭā sarvabhūtānāṁ(m), maitraḥ(kh) karuṇa eva ca,\nnirmamo nirahaṅkāraḥ(s), ṣamaduḥkhasukhaḥ kṣamī. 12.13",

      "santuṣṭaḥ(s) satataṁ(y̐) yogī, yatātmā dṛḍhaniścayaḥ,\nmayyarpitamanobuddhiḥ(r), yo madbhaktaḥ(s) sa me priyaḥ. 12.14",

      "yasmānnodvijate loko, lokānnodvijate ca yaḥ,\nharṣāmarṣabhayodvegaiḥ(r), mukto yaḥ(s) sa ca me priyaḥ 12.15",

      "napekṣaḥ(ś) śucirdakṣa, udāsīno gatavyathaḥ,\nsarvārambhaparityāgī, yo madbhaktaḥ(s) sa me priyaḥ. 12.16",

      "yo na hṛṣyati na dveṣṭi, na śocati na kāṅkṣati,\nśubhāśubhaparityāgī, bhaktimānyaḥ(s) sa me priyaḥ. 12.17",

      "ṣamaḥ(ś) śatrau ca mitre ca, tathā mānāpamānayoḥ,\nśītoṣṇasukhaduḥkheṣu, ṣamaḥ(s) saṅgavivarjitaḥ. 12.18",

      "tulyanindāstutirmaunī, santuṣṭo yena kenacit,\naniketaḥ(s) sthiRāmatiḥ(r), bhaktimānme priyo naraḥ. 12.19",

      "ye tu dharmyāmṛtamidaṁ(y̐), yathoktaṁ(m) paryupāsate,\nśraddadhānā matparamā, bhaktāste'tīva me priyāḥa.12.20",

      "om tatsaditi śrīmadbhagavadgītāsu upaniṣatsu brahmavidyāyāṃ(y̐)\nyogaśāstre śrīkṛṣṇārjunasaṃvāde bhaktiyogo nāma dvādaśo'dhyāyaḥ"
      ],
      "adhyaya-13" : [
      "śrībhagavānuvāca\nidaṃ(m) śarīraṃ(ṅ) kaunteya, kṣetramityabhidhīyate,\netadyo vetti taṃ(m) prāhuḥ(kh), kṣetrajña iti tadvidaḥ  13.1",

      "kṣetrajñaṃ(ñ) cāpi māṃ(m) viddhi, sarVākṣetreṣu bhārata,\nkṣetrakṣetrajñayorjñānaṃ(m), yattajjñānaṃ(m) mataṃ(m) mama. 13.2",

      "tatkṣetraṃ(m) yacca yādṛkca, yadvikāri yataśca yat,\nsa ca yo yatprabhāvaśca, tatsamāsena me śṛṇu  13.3",

      "ṛṣibhirbahudhā gītaṃ(ñ), chandobhirvividhaiḥ(ph) pṛthak,\nbrahmasūtrapadaiścaiva, hetumadbhirviniścitaiḥ 13.4",

      "mahābhūtānyahaṅkāro, buddhiravyaktameva ca,\nindriyāṇi daśaikaṃ(ñ) ca, pañca cendriyagocarāḥ. 13.5",

      "icchā dveṣaḥ(s) sukhaṃ(n) duḥkhaṃ(m), saṅghātaścetanā dhṛtiḥ,\netatkṣetraṃ(m) samāsena, savikāramudāhṛtam. 13.6",

      "amānitvamadambhitvam, ahiṃsā kṣāntirārjavam,\nācāryopāsanaṃ(m) śaucaṃ(m), sthairyamātmavinigrahaḥ. 13.7",

      "indriyārtheṣu vairāgyam, anahaṅkāra eva ca,\njanmamṛtyujarāvyādhi, duḥkhadoṣānudarśanam. 13.8",

      "asaktiranabhiṣvaṅgaḥ(ph), putradāragṛhādiṣu,\nnityaṃ(ñ) ca ṣamacittatvam, iṣṭāniṣṭopapattiṣu. 13.9",

      "mayi cānanyayogena, bhaktiravyabhicāriṇī,\nviviktadeśasevitvam, aratirjanasaṃsadi. 13.10",

      "adhyātmajñānanityatvaṃ(n), tattvajñānārthadarśanam,\netajjñānamiti proktam, ajñānaṃ(m) yadato'nyathā. 13.11",

      "jñeyaṃ(m) yattatpraVākṣyāmi, yajjñātvāmṛtamaśnute,\nanādimatparaṃ(m) brahma, na sattannāsaducyate. 13.12",

      "sarvataḥ(ph) pāṇipādaṃ(n) tat, sarvato'kṣiśiromukham,\nsarvataḥ(ś) śrutimalloke, sarvamāvṛtya tiṣṭhati. 13.13",

      "sarvendriyaguṇābhāsaṃ(m), sarvendriyavivarjitam,\nasaktaṃ(m) sarvabhṛccaiva, nirguṇaṃ(ṅ) guṇabhoktṛ ca. 13.14",

      "bahirantaśca bhūtānām, acaraṃ(ñ) carameva ca,\nsūkṣmatvāttadavijñeyaṃ(n), dūrasthaṃ(ñ) cāntike ca tat. 13.15",

      "avibhaktaṃ(ñ) ca bhūteṣu, vibhaktamiva ca sthitam,\nbhūtabhartṛ ca tajjñeyaṃ(ṅ), grasiṣṇu prabhaviṣṇu ca. 13.16",

      "jyotiṣāmapi tajjyotiḥ(s), tamasaḥ(ph) paramucyate,\njñānaṃ(ñ) jñeyaṃ(ñ) jñānagamyaṃ(m), hṛdi sarvasya viṣṭhitam. 13.17",

      "iti kṣetraṃ(n) tathā jñānaṃ(ñ), jñeyaṃ(ñ) coktaṃ(m) samāsataḥ,\nmadbhakta etadvijñāya, madbhāvāyopapadyate. 13.18",

      "prakṛtiṃ(m) puruṣaṃ(ñ) caiva, vidyanādi ubhāvapi,\nvikārāṃśca guṇāṃścaiva, viddhi prakṛtisaṃbhavān. 13.19",

      "kāryakaraṇakartṛtve, hetuḥ(ph) prakṛtirucyate,\npuruṣaḥ(s) sukhaduḥkhānāṃ(m), bhoktṛtve heturucyate.. 13.20",

      "puruṣaḥ(ph) prakṛtistho hi, bhuṅkte prakṛtijānguṇān,\nkāraṇaṃ(ṅ) guṇasaṅgo'sya, sadasadyonijanmasu. 13.21",

      "upadraṣṭānumantā ca, bhartā bhoktā maheśvaraḥ,\nparamātmeti cāpyukto, dehe'sminpuruṣaḥ(ph) paraḥ. 13.22",

      "ya evaṃ(m) vetti puruṣaṃ(m), prakṛtiṃ(ñ) ca guṇaiḥ(s) saha,\nsarvathā vartamāno'pi, na sa bhūyo'bhijāyate. 13.23",

      "dhyānenātmani paśyanti, kecidātmānamātmanā,\nanye sāṅkhyena yogena, karmayogena cāpare. 13.24",

      "anye tvevamajānantaḥ(ś), śrutvānyebhya upāsate,\nte'pi cātitarantyeva, mṛtyuṃ(m) śrutiparāyaṇāḥ. 13.25",

      "yāvatsañjāyate kiñcit, sattvaṃ(m) sthāvarajaṅgamam,\nkṣetrakṣetrajñasaṃyogāt, tadviddhi bharatarṣabha. 13.26",

      "ṣamaṃ(m) sarveṣu bhūteṣu, tiṣṭhantaṃ(m) parameśvaram,\nvinaśyatsvavinaśyantaṃ(m), yaḥ(ph) paśyati sa paśyati. 13.27",

      "ṣamaṃ(m) paśyanhi sarvatra, ṣamavasthitamīśvaram,\nna hinastyātmanātmānaṃ(n), tato yāti parāṃ(ṅ) gatim. 13.28",

      "prakṛtyaiva ca karmāṇi, kriyamāṇāni sarvaśaḥ,\nyaḥ(ph) paśyati tathātmānam, akartāraṃ(m) sa paśyati. 13.29",

      "yadā bhūtapṛthagbhāvam, ekasthamanupaśyati,\ntata eva ca vistāraṃ(m), brahma saṃpadyate tadā. 13.30",

      "anāditvānnirguṇatvāt, paramātmāyamavyayaḥ,\nśarīrastho'pi kaunteya, na karoti na lipyate. 13.31",

      "yathā sarvagataṃ(m) saukṣmyād, ākāśaṃ(n) nopalipyate,\nsarvatrāvasthito dehe, tathātmā nopalipyate. 13.32",

      "yathā prakāśayatyekaḥ(kh), kṛtsnaṃ(m) lokamimaṃ(m) raviḥ,\nkṣetraṃ(ṅ) kṣetrī tathā kṛtsnaṃ(m), prakāśayati bhārata. 13.33",

      "kṣetrakṣetrajñayorevam, antaraṃ(ñ) jñānacakṣuṣā,\nbhūtaprakṛtimokṣaṃ(ñ) ca, ye viduryānti te param. 13.34",

      "om tatsaditi śrīmadbhagavadgītāsu upaniṣatsu brahmavidyāyāṃ(y̐)\nyogaśāstre śrīkṛṣṇārjunasaṃvāde kṣetrakṣetrajnavibhaga yogo nāma trayodaśo'dhyāyaḥ."        
      ],
      "adhyaya-14" : [
      "śrībhagavānuvāca\nparaṃ(m) bhūyaḥ(ph) praVākṣyāmi, jñānānāṃ(ñ) jñānamuttamam,\nyajjñātvā munayaḥ(s) sarve, parāṃ(m) siddhimito gatāḥ. 14.1",

      "idaṃ(ñ) jñānamupāśritya, mama sādharmyamāgatāḥ,\nsarge'pi nopajāyante, pralaye na vyathanti ca.  14.2",

      "mama yonirmahadbrahma, tasmingarbhaṃ(n) dadhāmyaham,\nsambhavaḥ(s) sarvabhūtānāṃ(n), tato bhavati bhārata. 14.3",

      "sarvayoniṣu kaunteya, mūrtayaḥ(s) saṃbhavanti yāḥ,\ntāsāṃ(m) brahma mahadyoniḥ(r), ahaṃ(m) bījapradaḥ(ph) pitā. 14.4",

      "sattvaṃ(m) rajastama iti, guṇāḥ(ph) prakṛtisambhavāḥ,\nnibadhnanti mahābāho, dehe dehinamavyayam. 14.5",

      "tatra sattvaṃ(n) nirmalatvāt, prakāśakamanāmayam,\nsukhasaṅgena badhnāti, jñānasaṅgena cānagha. 14.6",

      "rajo rāgātmakaṃ(m) viddhi, tṛṣṇāsaṅgasamudbhavam,\ntannibadhnāti kaunteya, karmasaṅgena dehinam. 14.7",

      "tamastvajñānajaṃ(m) viddhi, mohanaṃ(m) sarvadehinām,\npramādālasyanidrābhiḥ(s), tannibadhnāti bhārata. 14.8",

      "sattvaṃ(m) sukhe sañjayati, rajaḥ(kh) karmaṇi bhārata,\njñānamāvṛtya tu tamaḥ(ph), pramāde sañjayatyuta. 14.9",

      "rajastamaścābhibhūya, sattvaṃ(m) bhavati bhārata,\nrajaḥ(s) sattvaṃ(n) tamaścaiva, tamaḥ(s) sattvaṃ(m) rajastathā. 14.10",

      "sarvadvāreṣu dehe'smin, prakāśa upajāyate,\njñānaṃ(m) yadā tadā vidyād, vivṛddhaṃ(m) sattvamityuta. 14.11",

      "lobhaḥ(ph) pravṛttirārambhaḥ(kh), karmaṇāmaśamaḥ(s) spṛhā,\nrajasyetānijāyante, vivṛddhe bharatarṣabha. 14.12",

      "aprakāśo'pravṛttiśca, pramādo moha eva ca,\ntamasyetāni jāyante, vivṛddhe kurunandana. 14.13",

      "yadā sattve pravṛddhe tu, pralayaṃ(m) yāti dehabhṛt,\ntadottamavidāṃ(m) lokān, amalānpratipadyate. 14.14",

      "rajasi pralayaṃ(ṅ) gatvā, karmasaṅgiṣu jāyate,\ntathā pralīnastamasi, mūḍhayoniṣu jāyate. 14.15",

      "karmaṇaḥ(s) sukṛtasyāhuḥ(s), sāttvikaṃ(n) nirmalaṃ(m) phalam,\nrajasastu phalaṃ(n) duḥkham, ajñānaṃ(n) tamasaḥ(ph) phalam. 14.16",

      "sattvātsañjāyate jñānaṃ(m), rajaso lobha eva ca,\npramādamohau tamaso, bhavato'jñānameva ca. 14.17",

      "ūrdhvaṃ(ṅ) gacchanti sattvasthā, madhye tiṣṭhanti rājasāḥ,\njaghanyaguṇavṛttisthā, adho gacchanti tāmasāḥ. 14.18",

      "nānyaṃ(ṅ) guṇebhyaḥ(kh) kartāraṃ(m), yadā draṣṭānupaśyati,\nguṇebhyaśca paraṃ(m) vetti, madbhāvaṃ(m) so'dhigacchati. 14.19",

      "guṇānetānatītya trīn, dehī dehasamudbhavān,\njanmamṛtyujarāduḥkhaiḥ(r), vimukto'mṛtamaśnute. 14.20",

      "arjuna uvāca\nkairliṅgaistrīnguṇānetān, atīto bhavati prabho,\nkimācāraḥ(kh) kathaṃ(ñ) caitāṃs, trīnguṇānativartate. 14.21",

      "śrībhagavānuvāca\nprakāśaṃ(ñ) ca pravṛttiṃ(ñ) ca, mohameva ca pāṇḍava,\nna dveṣṭi saṃpravṛttāni, na nivṛttāni kāṅkṣati. 14.22",

      "Udāsīnavadāsīno, guṇairyo na vicālyate,\nguṇā vartanta ityeva, yo'vatiṣṭhati neṅgate. 14.23",

      "ṣamaduḥkhasukhaḥ(s) svasthaḥ(s), ṣamaloṣṭāśmakāñcanaḥ,\ntulyapriyāpriyo dhīraḥ(s), tulyanindātmasaṃstutiḥ. 14.24",

      "mānāpamānayostulyaḥ(s), tulyo mitrāripakṣayoḥ,\nsarvārambhaparityāgī, guṇātītaḥ(s) sa ucyate. 14.25",

      "māṃ(ñ) ca yo'vyabhicāreṇa, bhaktiyogena sevate,\nsa guṇānṣamatītyaitān, brahmabhūyāya kalpate. 14.26",

      "brahmaṇo hi pratiṣṭhāham, amṛtasyāvyayasya ca,\nśāśvatasya ca dharmasya, sukhasyaikāntikasya ca. 14.27",

      "om tatsaditi śrīmadbhagavadgītāsu upaniṣatsu brahmavidyāyāṃ(y̐)\nyogaśāstre śrīkṛṣṇārjunasaṃvāde Guṇatrayavibhāgayogo nāma caturdaśo'dhyāyaḥ."
      ],
      "adhyaya-15" : [
      "śrībhagavānuvāca\nūrdhvamūlamadhaḥ(ś) śākham, aśvatthaṃ(m) prāhuravyayam,\nchandāṃsi yasya parṇāni,  yastaṃ(v̐) veda sa vedavit. 15.1",

      "adhaścordhvaṃ(m) prasṛtāstasya śākhā, guṇapravṛddhā viṣayapravālāḥ,\nadhaśca mūlānyanusantatāni, karmānubandhīni manuṣyaloke. 15.2",

      "na rūpamasyeha tathopalabhyate, nānto na cādirna ca sampratiṣṭhā,\naśvatthamenaṃ(m) suvirūḍhamūlam, asaṅgaśastreṇa dṛḍhena chittvā. 15.3",

      "tataḥ(ph) padaṃ(n) tatparimārgitavyaṃ(y̐), yasmingatā na nivartanti bhūyaḥ,\ntameva cādyaṃ(m) puruṣaṃ(m) prapadye, yataḥ(ph) pravṛttiḥ(ph) prasṛtā purāṇī.15.4",

      "nirmānamohā jitasaṅgadoṣā, adhyātmanityā vinivṛttakāmāḥ,\ndvandvairvimuktāḥ(s) sukhaduḥkhasañjñaiḥ(r), gacchantyamūḍhāḥ(ph) padamavyayaṃ(n) tat. 15.5",

      "na tadbhāsayate sūryo, na śaśāṅko na pāVākaḥ,\nyadgatvā na nivartante, taddhāma paRāmaṃ(m) mama. 15.6",

      "mamaivāṃśo jīvaloke, jīvabhūtaḥ(s) sanātanaḥ,\nmanaḥ(ṣ) ṣaṣṭhānīndriyāṇi, prakṛtisthāni karṣati. 15.7",

      "śarīraṃ(y̐) yadavāpnoti, yaccāpyutkrāmatīśvaraḥ,\ngṛhītvaitāni saṃyāti, vāyurgandhānivāśayāt.15.8",

      "śrotraṃ(ñ) cakṣuḥ(s) sparśanaṃ(ñ) ca, rasanaṃ(ṅ) ghrāṇameva ca,\nadhiṣṭhāya manaścāyaṃ(v̐), viṣayānupasevate.15.9",

      "utkrāmantaṃ(m) sthitaṃ(v̐) vāpi, bhuñjānaṃ(v̐) vā guṇānvitam,\nvimūḍhā nānupaśyanti, paśyanti jñānacakṣuṣaḥ.15.10",

      "yatanto yoginaścainaṃ(m), paśyantyātmanyavasthitam,\nyatanto'pyakṛtātmāno, nainaṃ(m) paśyantyacetasaḥ. 15.11",

      "yadādityagataṃ(n) tejo, jagadbhāsayate'khilam,\nyaccandRāmasi yaccāgnau, tattejo viddhi māmakam. 15.12",

      "gāmāviśya ca bhūtāni, dhārayāmyahamojasā,\npuṣṇāmi cauṣadhīḥ(s) sarvāḥ(s), somo bhūtvā rasātmakaḥ. 15.13",

      "ahaṃ(v̐) vaiśvānaro bhūtvā, prāṇināṃ(n) dehamāśritaḥ,\nprāṇāpānasamāyuktaḥ(ph), pacāmyannaṃ(ñ) caturvidham.15.14",

      "sarvasya cāhaṃ(m) hṛdi sanniviṣṭo, mattaḥ(s) smṛtirjñānamapohanaṃ(ñ) ca,\nvedaiśca sarvairahameva vedyo, vedāntakṛdvedavideva cāham. 15.15",

      "dvāvimau puruṣau loke, kṣaraścākṣara eva ca,\nkṣaraḥ sarvāṇi bhūtāni, kūṭastho'kṣara ucyate. 15.16",

      "uttamaḥ(ph) puruṣastvanyaḥ(ph), paramātmetyudāhṛtaḥ,\nyo lokatrayamāviśya, bibhartyavyaya īśvaraḥ. 15.17",

      "yasmātkṣaRāmatīto'ham, akṣarādapi cottamaḥ,\nato'smi loke vede ca, prathitaḥ(ph) puruṣottamaḥ. 15.18",

      "yo māmevamasammūḍho, jānāti puruṣottamam,\nsa sarvavidbhajati māṃ(m), sarvabhāvena bhārata. 15.19",

      "iti guhyatamaṃ(m) śāstram, idamuktaṃ(m) mayānagha,\netadbuddhvā buddhimānsyāt, kṛtakṛtyaśca bhārata. 15.20",

      "om tatsaditi śrīmadbhagavadgītāsu upaniṣatsu brahmavidyāyāṃ(y̐)\nyogaśāstre śrīkṛṣṇārjunasaṃvāde puruṣottamayogo nāma pañcadaśo'dhyāyaḥ."
      ],
      "adhyaya-16" : [
      "śrībhagavānuvāca\nabhayaṃ(m) sattvasaṃśuddhiḥ(r), jñānayogavyavasthitiḥ,\ndānaṃ(n) damaśca yajñaśca, svādhyāyastapa ārjavam  16.1",

      "ahiṃsā satyamakrodhaḥ(s), tyāgaḥ(ś) śāntirapaiśunam,\ndayā bhūteṣvaloluptvaṃ(m), mārdavaṃ(m) hrīr acāpalam  16.2",

      "tejaḥ kṣamā dhṛtiḥ(ś) śaucam, adroho nātimānitā,\nbhavanti saṃpadaṃ(n) daivīm, abhijātasya bhārata  16.3",

      "dambho darpo'bhimānaśca, krodhaḥ(ph) pāruṣyameva ca,\najñānaṃ(ñ) cābhijātasya, pārtha sampadamāsurīm    16.4",

      "daivī sampadvimokṣāya, nibandhāyāsurī matā,\nmā śucaḥ(s) saṃpadaṃ(n) daivīm, abhijāto'si pāṇḍava  16.5",

      "dvau bhūtasargau loke'smin, daiva āsura eva ca,\ndaivo vistaraśaḥ(ph) prokta, āsuraṃ(m) pārtha me śṛṇu 16.6",

      "pravṛttiṃ(ñ) ca nivṛttiṃ(ñ) ca, janā na vidurāsurāḥ,\nna śaucaṃ(n) nāpi cācāro, na satyaṃ(n) teṣu vidyate 16.7",

      "asatyamapratiṣṭhaṃ(n) te, jagadāhuranīśvaram,\naparasparasambhūtaṃ(ṅ), kimanyatkāmahaitukam  16.8",

      "etāṃ(n) dṛṣṭimavaṣṭabhya, naṣṭātmāno'lpabuddhayaḥ,\nprabhavantyugrakarmāṇaḥ, kṣayāya jagato'hitāḥ  16.9",

      "kāmamāśritya duṣpūraṃ(n), dambhamānamadānvitāḥ,\nmohādgṛhītvāsadgrāhān, pravartante'śucivratāḥ  16.10",

      "cintāmaparimeyāṃ(ñ) ca, pralayāntāmupāśritāḥ,\nkāmopabhogaparamā, etāvaditi niścitāḥ  16.11",

      "āśāpāśaśatairbaddhāḥ(kh), kāmakrodhaparāyaṇāḥ,\nīhante kāmabhogārtham, anyāyenārthasañcayān  16.12",

      "idamadya mayā labdham, imaṃ(m) prāpsye manoratham,\nidamastīdamapi me, bhaviṣyati punardhanam  16.13",

      "asau mayā hataḥ(ś) śatruḥ(r), haniṣye cāparānapi,\nīśvaro'hamahaṃ(m) bhogī, siddho'haṃ(m) balavānsukhī 16.14",

      "āḍhyo'bhijanavānasmi, ko'nyosti sadṛśo mayā,\nyakṣye dāsyāmi modiṣya, ityajñānavimohitāḥ 16.15",

      "anekacittavibhrāntā, mohajālasamāvṛtāḥ,\nprasaktāḥ(kh) kāmabhogeṣu, patanti narake'śucau  16.16",

      "ātmasaṃbhāvitāḥ(s) stabdhā, dhanamānamadānvitāḥ,\nyajante nāmayajñaiste, dambhenāvidhipūrVākam  16.17",

      "ahaṅkāraṃ(m) balaṃ(n) darpaṃ(ṅ), kāmaṃ(ṅ) krodhaṃ(ñ) ca saṃśritāḥ,\nmāmātmaparadeheṣu, pradviṣanto'bhyasūyakāḥ  16.18",

      "tānahaṃ(n) dviṣataḥ(kh) krūrān, saṃsāreṣu narādhamān,\nkṣipāmyajasRāmaśubhān, āsurīṣveva yoniṣu  16.19",

      "āsurīṃ(y̐) yonimāpannā, mūḍhā janmani janmani,\nmāmaprāpyaiva kaunteya, tato yāntyadhamāṃ(ṅ) gatim.16.20",

      "trividhaṃ(n) narakasyedaṃ(n), dvāraṃ(n) nāśanamātmanaḥ,\nkāmaḥ(kh) krodhastathā lobhaḥ(s), tasmādetattrayaṃ(n) tyajet 16.21",

      "etairvimuktaḥ(kh) kaunteya, tamodvāraistribhirnaraḥ,\nācaratyātmanaḥ(ś) śreyaḥ(s), tato yāti parāṃ(ṅ) gatim 16.22",

      "yaḥ(ś) śāstravidhimutsṛjya, vartate kāmakārataḥ,\nna sa siddhimavāpnoti, na sukhaṃ(n) na parāṃ(ṅ) gatim 16.23",

      "tasmācchāstraṃ(m) pramāṇaṃ(n) te, kāryākāryavyavasthitau,\nJñātvā śāstravidhānoktaṃ(ṅ), karma kartumihārhasi 16.24",

      "om tatsaditi śrīmadbhagavadgītāsu upaniṣatsu brahmavidyāyāṃ(y̐)\nyogaśāstre śrīkṛṣṇārjunasaṃvāde daivāsurasampadvibhāgayogo nāma ṣoḍaśo'dhyāyaḥ."        
      ],
      "adhyaya-17" : [
      "arjuna uvāca\nye śāstravidhimutsṛjya, yajante śraddhayānvitāḥ,\nteṣāṃ(n) niṣṭhā tu kā kṛṣṇa, sattvamāho rajastamaḥ. 17.1",

      "śrībhagavānuvāca\ntrividhā bhavati śraddhā, dehināṃ(m) sā svabhāvajā,\nsāttvikī rājasī caiva, tāmasī ceti tāṃ(m) śṛṇu. 17.2",

      "sattvānurūpā sarvasya, śraddhā bhavati bhārata,\nśraddhāmayo'yaṃ(m) puruṣo, yo yacchraddhaḥ(s) sa eva saḥ. 17.3",

      "yajante sāttvikā devān, yakṣarakṣāṃsi rājasāḥ,\npretānbhūtagaṇāṃścānye, yajante tāmasā janāḥ. 17.4",

      "aśāstravihitaṃ(ṅ) ghoraṃ(n), tapyante ye tapo janāḥ,\ndambhāhaṅkārasaṃyuktāḥ(kh), kāmarāgabalānvitāḥ. 17.5",

      "karśayantaḥ(ś) śarīrasthaṃ(m), bhūtagrāmamacetasaḥ,\nmāṃ(ñ) caivāntaḥ(ś) śarīrasthaṃ(n), tānviddhyāsuraniścayān. 17.6",

      "āhārastvapi sarvasya, trividho bhavati priyaḥ,\nyajñastapastathā dānaṃ(n), teṣāṃ(m) bhedamimaṃ(m) śṛṇu. 17.7",

      "āyuḥ(s) sattvabalārogya, sukhaprītivivardhanāḥ,\nrasyāḥ(s) snigdhāḥ(s) sthirā hṛdyā, āhārāḥ(s) sāttvikapriyāḥ. 17.8",

      "kaṭvamlalavaṇātyuṣṇa, tīkṣṇarūkṣavidāhinaḥ,\nāhārā rājasasyeṣṭā, duḥkhaśokāmayapradāḥ. 17.9",

      "yātayāmaṃ(ṅ) gatarasaṃ(m), pūti paryuṣitaṃ(ñ) ca yat,\nucchiṣṭamapi cāmedhyaṃ(m), bhojanaṃ(n) tāmasapriyam. 17.10",

      "aphalākāṅkṣibhiryajño, vidhidṛṣṭo ya ijyate,\nyaṣṭavyameveti manaḥ(s), samādhāya sa sāttvikaḥ. 17.11",

      "abhisandhāya tu phalaṃ(n), dambhārthamapi caiva yat,\nijyate bharataśreṣṭha, taṃ(m) yajñaṃ(m) viddhi rājasam. 17.12",

      "vidhihīnamasṛṣṭānnaṃ(m), mantrahīnamadakṣiṇam,\nśraddhāvirahitaṃ(m) yajñaṃ(n), tāmasaṃ(m) paricakṣate. 17.13",

      "devadvijaguruprājña, pūjanaṃ(m) śaucamārjavam,\nbrahmacaryamahiṃsā ca, śārīraṃ(n) tapa ucyate. 17.14",

      "anudvegakaraṃ(m) vākyaṃ(m), satyaṃ(m) priyahitaṃ(ñ) ca yat,\nsvādhyāyābhyasanaṃ(ñ) caiva, vāṅmayaṃ(n) tapa ucyate. 17.15",

      "manaḥ(ph) prasādaḥ(s) saumyatvaṃ(m), maunamātmavinigrahaḥ,\nbhāvasaṃśuddhirityetat, tapo mānasamucyate. 17.16",

      "śraddhayā parayā taptaṃ(n), tapastattrividhaṃ(n) naraiḥ,\naphalākāṅkṣibhiryuktaiḥ(s), sāttvikaṃ(m) paricakṣate. 17.17",

      "satkāramānapūjārthaṃ(n), tapo dambhena caiva yat,\nkriyate tadiha proktaṃ(m), rājasaṃ(ñ) calamadhruvam. 17.18",

      "mūḍhagrāheṇātmano yat, pīḍayā kriyate tapaḥ,\nparasyotsādanārthaṃ(m) vā, tattāmasamudāhṛtam. 17.19",

      "dātavyamiti yaddānaṃ(n), dīyate'nupakāriṇe,\ndeśe kāle ca pātre ca, taddānaṃ(m) sāttvikaṃ(m) smṛtam. 17.20",

      "yattu pratyupakārārthaṃ(m), phalamuddiśya vā punaḥ,\ndīyate ca parikliṣṭaṃ(n), taddānaṃ(m) rājasaṃ(m) smṛtam. 17.21",

      "adeśakāle yaddānam, apātrebhyaśca dīyate,\nasatkṛtamavajñātaṃ(n), tattāmasamudāhṛtam. 17.22",

      "oṃ tatsaditi nirdeśo, brahmaṇastrividhaḥ(s) smṛtaḥ,\nbrāhmaṇāstena vedāśca, yajñāśca vihitāḥ(ph) purā. 17.23",

      "tasmādomityudāhṛtya, yajñadānatapaḥ(kh) kriyāḥ,\npravartante vidhānoktāḥ(s), satataṃ(m) brahmavādinām. 17.24",

      "tadityanabhisandhāya, phalaṃ(m) yajñatapaḥ(kh) kriyāḥ,\ndānakriyāśca vividhāḥ(kh), kriyante mokṣakāṅkṣibhiḥ. 17.25",

      "sadbhāve sādhubhāve ca, sadityetatprayujyate,\npraśaste karmaṇi tathā, sacchabdaḥ(ph) pārtha yujyate. 17.26",

      "yajñe tapasi dāne ca, sthitiḥ(s) saditi cocyate,\nkarma caiva tadarthīyaṃ(m), sadityevābhidhīyate. 17.27",

      "aśraddhayā hutaṃ(n) dattaṃ(n), tapastaptaṃ(ṅ) kṛtaṃ(ñ) ca yat,\nasadityucyate pārtha, na ca tatpretya no iha. 17.28",

      "om tatsaditi śrīmadbhagavadgītāsu upaniṣatsu brahmavidyāyāṃ(y̐)\nyogaśāstre śrīkṛṣṇārjunasaṃvāde śraddhātrayavibhāgayogonāma saptadaśo'dhyāyaḥ."       
      ],
      "adhyaya-18" : [
      "arjuna uvāca\nsannyāsasya mahābāho, tattvamicchāmi veditum,\ntyāgasya ca hṛṣīkeśa, pṛthakkeśiniṣūdana. 18.1",

      "śrībhhagavānuvāca\nkāmyānāṃ(ṅ) karmaṇāṃ(n) nyāsaṃ(m), sannyāsaṃ(ṅ) kavayo viduḥ,\nsarVākarmaphalatyāgaṃ(m), prāhustyāgaṃ(m) vicakṣaṇāḥ. 18.2",

      "tyājyaṃ(n) doṣavadityeke, karma prāhurmanīṣiṇaḥ,\nyajñadānatapaḥkarma, na tyājyamiti cāpare. 18.3",

      "niścayaṃ(m) śṛṇu me tatra, tyāge bharatasattama,\ntyāgo hi puruṣavyāghra, trividhaḥ(s) samprakīrtitaḥ. 18.4",

      "yajñadānatapaḥkarma, na tyājyaṃ(ṅ) kāryameva tat,\nyajño dānaṃ(n) tapaścaiva, pāvanāni manīṣiṇām. 18.5",

      "etānyapi tu karmāṇi, saṅgaṃ(n) tyaktvā phalāni ca,\nkartavyānīti me pārtha, niścitaṃ(m) matamuttamam. 18.6",

      "niyatasya tu sannyāsaḥ(kh), karmaṇo nopapadyate,\nmohāttasya parityāgaḥ(s), tāmasaḥ(ph) parikīrtitaḥ. 18.7",

      "duḥkhamityeva yatkarma, kāyakleśabhayāttyajet,\nsa kṛtvā rājasaṃ(n) tyāgaṃ(n), naiva tyāgaphalaṃ(m) labhet. 18.8",

      "kāryamityeva yatkarma, niyataṃ(ṅ) kriyate’rjuna,\nsaṅgaṃ(n) tyaktvā phalaṃ(ñ) caiva, sa tyāgaḥ(s) sāttviko mataḥ. 18.9",

      "na dvestyakuśalaṃ(ṅ) karma, kuśale nānuṣajjate,\ntyāgī sattvasamāviṣṭo, medhāvī chinnasaṃśayaḥ. 18.10",

      "na hi dehabhṛtā śakyaṃ(n), tyaktuṃ(ṅ) karmāṇyaśeṣataḥ,\nyastu karmaphalatyāgī, sa tyāgītyabhidhīyate. 18.11",

      "aniṣṭamiṣṭaṃ(m) miśraṃ(ñ) ca, trividhaṃ(ṅ) karmaṇaḥ(ph) phalam,\nbhavatyatyāgināṃ(m) pretya, na tu sannyāsināṃ(ṅ) kvacit. 18.12",

      "pañcaitāni mahābāho, kāraṇāni nibodha me,\nsāṅkhye kṛtānte proktāni, siddhaye sarVākarmaṇām. 18.13",

      "adhiṣṭhānaṃ(n) tathā kartā, karaṇaṃ(ñ) ca pṛthagvidham,\nvividhāśca pṛthakceṣṭā, daivaṃ(ñ) caivātra pañcamam. 18.14",

      "śarīravāṅmanobhiryat, karma prārabhate naraḥ,\nnyāyyaṃ(m) vā viparītaṃ(m) vā, pañcaite tasya hetavaḥ. 18.15",

      "tatraivaṃ(m) sati kartāram, ātmānaṃ(ṅ) kevalaṃ(n) tu yaḥ,\npaśyatyakṛtabuddhitvān, na sa paśyati durmatiḥ. 18.16",

      "yasya nāhaṅkṛto bhāvo, buddhiryasya na lipyate,\nhatvāpi sa imāṃllokān, na hanti na nibadhyate. 18.17",

      "jñānaṃ(ñ) jñeyaṃ(m) parijñātā, trividhā karmacodanā,\nkaraṇaṃ(ṅ) karma karteti, trividhaḥ(kh) karmasaṅgrahaḥ. 18.18",

      "jñānaṃ(ṅ) karma ca kartāca, tridhaiva guṇabhedataḥ,\nprocyate guṇasaṅkhyāne, yathāvacchṛṇu tānyapi. 18.19",

      "sarvabhūteṣu yenaikaṃ(m), bhāvamavyayamīkṣate,\navibhaktaṃ(m) vibhakteṣu, tajjñānaṃ(m) viddhi sāttvikam. 18.20",

      "pṛthaktvena tu yajjñānaṃ(n), nānābhāvānpṛthagvidhān,\nvetti sarveṣu bhūteṣu, tajjñānaṃ(m) viddhi rājasam. 18.21",

      "yattu kṛtsnavadekasmin, kārye saktamahaitukam,\natattvārthavadalpaṃ(ñ) ca, tattāmasamudāhṛtam. 18.22",

      "niyataṃ(m) saṅgarahitam, arāgadveṣataḥ(kh) kṛtam,\naphalaprepsunā karma, yattatsāttvikamucyate. 18.23",

      "yattu kāmepsunā karma, sāhaṅkāreṇa vā punaḥ,\nkriyate bahulāyāsaṃ(n), tadrājasamudāhṛtam. 18.24",

      "anubandhaṃ(ṅ) kṣayaṃ(m) hiṃsām, anavekṣya ca pauruṣam,\nmohādārabhyate karma, yattattāmasamucyate. 18.25",

      "muktasaṅgo’nahaṃvādī, dhṛtyutsāhaṣamanvitaḥ,\nsiddhyasiddhyornirvikāraḥ(kh), kartā sāttvika ucyate. 18.26",

      "rāgī karmaphalaprepsuḥ(r), lubdho hiṃsātmako’śuciḥ,\nharṣaśokānvitaḥ(kh) kartā, rājasaḥ(ph) parikīrtitaḥ. 18.27",

      "ayuktaḥ(kh) prākṛtaḥ(s) stabdhaḥ(ś), śaṭho naiṣkṛtiko’lasaḥ,\nviṣādī dīrghasūtrī ca, kartā tāmasa ucyate. 18.28",

      "buddherbhedaṃ(n) dhṛteścaiva, guṇatastrividhaṃ(m) śṛṇu,\nprocyamānamaśeṣeṇa, pṛthaktvena dhanañjaya. 18.29",

      "pravṛttiṃ(ñ) ca nivṛttiṃ(ñ) ca, kāryākārye bhayābhaye,\nbandhaṃ(m) mokṣaṃ(ñ) ca yā vetti, buddhiḥ(s) sā pārtha sāttvikī. 18.30",

      "yayā dharmamadharmaṃ(ñ) ca, kāryaṃ(ñ) cākāryameva ca,\nayathāvatprajānāti, buddhiḥ(s) sā pārtha rājasī. 18.31",

      "adharmaṃ(n) dharmamiti yā, manyate tamasāvṛtā,\nsarvārthānviparītāṃśca, buddhiḥ(s) sā pārtha tāmasī. 18.32",

      "dhṛtyā yayā dhārayate, manaḥprāṇendriyakriyāḥ,\nyogenāvyabhicāriṇyā, dhṛtiḥ(s) sā pārtha sāttvikī. 18.33",

      "yayā tu dharmakāmārthān, dhṛtyā dhārayate’rjuna,\nprasaṅgena phalākāṅkṣī, dhṛtiḥ(s) sā pārtha rājasī. 18.34",

      "yayā svapnaṃ(m) bhayaṃ(m) śokaṃ(m), viṣādaṃ(m) madameva ca,\nna vimuñcati durmedhā, dhṛtiḥ(s) sā pārtha tāmasī. 18.35",

      "sukhaṃ(n) tvidānīṃ(n) trividhaṃ(m), śṛṇu me bharatarṣabha,\nabhyāsādRāmate yatra, duḥkhāntaṃ(ñ) ca nigacchati. 18.36",

      "yattadagre viṣamiva, pariṇāme’mṛtopamam,\ntatsukhaṃ(m) sāttvikaṃ(m) proktam, ātmabuddhiprasādajam. 18.37",

      "viṣayendriyasaṃyogād, yattadagre’mṛtopamam,\npariṇāme viṣamiva, tatsukhaṃ(m) rājasaṃ(m) smṛtam. 18.38",

      "yadagre cānubandhe ca, sukhaṃ(m) mohanamātmanaḥ,\nnidrālasyapramādotthaṃ(n), tattāmasamudāhṛtam. 18.39",

      "na tadasti pṛthivyāṃ(m) vā, divi deveṣu vā punaḥ,\nsattvaṃ(m) prakṛtijairmuktaṃ(m), yadebhiḥ(s) syāttribhirguṇaiḥ. 18.40",

      "brāhmaṇakṣatriyaviśāṃ(m), śūdrāṇāṃ(ñ) ca parantapa,\nkarmāṇi pravibhaktāni, svabhāvaprabhavairguṇaiḥ. 18.41",

      "śamo damastapaḥ(ś) śaucaṃ(ṅ), kṣāntirārjavameva ca,\njñānaṃ(m) vijñānamāstikyaṃ(m), brahmakarma svabhāvajam. 18.42",

      "śauryaṃ(n) tejo dhṛtirdākṣyaṃ(m), yuddhe cāpyapalāyanam,\ndānamīśvarabhāvaśca, kṣātraṃ(ṅ) karma svabhāvajam. 18.43",

      "kṛṣigaurakṣyavāṇijyaṃ(m), vaiśyakarma svabhāvajam,\nparicaryātmakaṃ(ṅ) karma, śūdrasyāpi svabhāvajam. 18.44",

      "sve sve karmaṇyabhirataḥ(s), saṃsiddhiṃ(m) labhate naraḥ,\nsVākarmanirataḥ(s) siddhiṃ(m), yathā vindati tacchṛṇu. 18.45",

      "yataḥ(ph) pravṛttirbhūtānāṃ(m), yena sarvamidaṃ(n) tatam,\nsVākarmaṇā tamabhyarcya, siddhiṃ(m) vindati mānavaḥ. 18.46",

      "śreyānsvadharmo viguṇaḥ(ph), paradharmātsvanuṣṭhitāt,\nsvabhāvaniyataṃ(ṅ) karma, kurvannāpnoti kilbiṣam. 18.47",

      "sahajaṃ(ṅ) karma kaunteya, sadoṣamapi na tyajet,\nsarvārambhā hi doṣeṇa, dhūmenāgnirivāvṛtāḥ. 18.48",

      "asaktabuddhiḥ(s) sarvatra, jitātmā vigataspṛhaḥ,\nnaiṣkarmyasiddhiṃ(m) paramāṃ(m), sannyāsenādhigacchati. 18.49",

      "siddhiṃ(m) prāpto yathā brahma, tathāpnoti nibodha me,\nsamāsenaiva kaunteya, niṣṭhā jñānasya yā parā. 18.50",

      "buddhyā viśuddhayā yukto, dhṛtyātmānaṃ(n) niyamya ca,\nśabdādīnviṣayāṃstyaktvā, rāgadveṣau vyudasya ca. 18.51",

      "viviktasevī laghvāśī, yatavākkāyamānasaḥ,\ndhyānayogaparo nityaṃ(m), vairāgyaṃ(m) samupāśritaḥ. 18.52",

      "ahaṅkāraṃ(m) balaṃ(n) darpaṃ(ṅ), kāmaṃ(ṅ) krodhaṃ(m) parigraham,\nvimucya nirmamaḥ(ś) śānto, brahmabhūyāya kalpate. 18.53",

      "brahmabhūtaḥ(ph) prasannātmā, na śocati na kāṅkṣati,\nṣamaḥ(s) sarveṣu bhūteṣu, madbhaktiṃ(m) labhate parām. 18.54",

      "bhaktyā māmabhijānāti, yāvānyaścāsmi tattvataḥ,\ntato māṃ(n) tattvato jñātvā, viśate tadanantaram. 18.55",

      "sarVākarmāṇyapi sadā, kurvāṇo madvyapāśrayaḥ,\nmatprasādādavāpnoti, śāśvataṃ(m) padamavyayam. 18.56",

      "cetasā sarVākarmāṇi, mayi sannyasya matparaḥ,\nbuddhiyogamupāśritya, maccittaḥ(s) satataṃ(m) bhava. 18.57",

      "maccittaḥ(s) sarvadurgāṇi, matprasādāttariṣyasi,\natha cettvamahaṅkārān, na śroṣyasi vinaṅkṣyasi. 18.58",

      "yadahaṅkāramāśritya, na yotsya iti manyase,\nmithyaiṣa vyavasāyaste, prakṛtistvāṃ(n) niyokṣyati. 18.59",

      "svabhāvajena kaunteya, nibaddhaḥ(s) svena karmaṇā,\nkartuṃ(n) necchasi yanmohāt, kariṣyasyavaśo'pi tat. 18.60",

      "īśvaraḥ(s) sarvabhūtānāṃ(m), hṛddeśe’rjuna tiṣṭhati,\nbhrāmayansarvabhūtāni, yantrārūḍhāni māyayā. 18.61",

      "tameva śaraṇaṃ(ṅ) gaccha, sarvabhāvena bhārata,\ntatprasādātparāṃ(m) śāntiṃ(m), sthānaṃ(m) prāpsyasi śāśvatam. 18.62",

      "iti te jñānamākhyātaṃ(ṅ), guhyādguhyataraṃ(m) mayā,\nvimṛśyaitadaśeṣeṇa, yathecchasi tathā kuru. 18.63",

      "sarvaguhyatamaṃ(m) bhūyaḥ(ś), śṛṇu me paRāmaṃ(m) vacaḥ,\niṣṭo’si me dṛḍhamiti, tato Vākṣyāmi te hitam.  18.64",

      "manmanā bhava madbhakto, madyājī māṃ(n) namaskuru,\nmāmevaiṣyasi satyaṃ(n) te, pratijāne priyo’si me. 18.65",

      "Sarvadharmānparityajya, māmekaṃ(m) śaraṇaṃ(m) vraja,\nahaṃ(n) tvā sarvapāpebhyo, mokṣayiṣyāmi mā śucaḥ. 18.66",

      "idaṃ(n) te nātapaskāya, nābhaktāya kadācana,\nna cāśuśrūṣave vācyaṃ(n), na ca māṃ(m) yo’bhyasūyati. 18.67",

      "ya imaṃ(m) paRāmaṃ(ṅ) guhyaṃ(m), madbhakteṣvabhidhāsyati,\nbhaktiṃ(m) mayi parāṃ(ṅ) kṛtvā, māmevaiṣyatyasaṃśayaḥ. 18.68",

      "na ca tasmānmanuṣyeṣu, kaścinme priyakṛttamaḥ,\nbhavitā na ca me tasmād anyaḥ(ph) priyataro bhuvi. 18.69",

      "adhyeṣyate ca ya imaṃ(n), dharmyaṃ(m) saṃvādamāvayoḥ,\njñānayajñena tenāham, iṣṭaḥ(s) syāmiti me matiḥ. 18.70",

      "śraddhāvānanasūyaśca, śṛṇuyādapi yo naraḥ,\nso’pi muktaḥ(ś) śubhālṃlokān, prāpnuyātpuṇyakarmaṇām. 18.71",

      "kaccidetacchrutaṃ(m) pārtha, tvayaikāgreṇa cetasā,\nkaccidajñānasaṃmohaḥ(ph), pranaṣṭaste dhanañjaya. 18.72",

      "arjuna uvāca\nnaṣṭo mohaḥ(s) smṛtirlabdhā, tvatprasādānmayācyuta,\nsthito’smi gatasandehaḥ(kh), kariṣye vacanaṃ(n) tava. 18.73",

      "sañjaya uvāca\nityahaṃ(m) vāsudevasya, pārthasya ca mahātmanaḥ,\nsaṃvādamimamaśrauṣam, adbhutaṃ(m) romaharṣaṇam. 18.74",

      "Vyāsaprasādācchrutavān, etadguhyamahaṃ(m) param,\nyogaṃ(m) yogeśvarātkṛṣṇāt, sākṣātkathayataḥ(s) svayam. 18.75",

      "rājansaṃsmṛtyasaṃsmṛtya saṃvādamimamadbhutam,\nkeśavārjunayoḥ(ph) puṇyaṃ(m), hṛṣyāmi ca muhurmuhuḥ. 18.76",

      "tacca saṃsmṛtya-saṃsmṛtya, rūpamatyadbhutaṃ(m) hareḥ,\nvismayo me mahānrājan, hṛṣyāmi ca punaḥ(ph) punaḥ. 18.77",

      "yatra yogeśvaraḥ(kh) kṛṣṇo, yatra pārtho dhanurdharaḥ,\ntatra śrīrvijayo bhūtiḥ(r), dhruvā nītirmatirmama. 18.78",

      "Om tatsaditi śrīmadbhagavadgītāsu upaniṣatsu brahmavidyāyāṃ(y̐)\nyogaśāstre śrīkṛṣṇārjunasaṃvāde mokṣasannyāsayogonāma aṣṭādaśo'dhyāyaḥ"
      ]
      },
      {
      "adhyaya-1" : [
      "Dhṛtarāṣṭra said:\nSañjaya, gathered on the holy land of Kurukṣetra, eager to fight, what did my sons and the sons of Pāņḍu do? 1.1",

      "Sañjaya said:\nAt that time, seeing the army of the Pāņḍavas drawn up for battle and approaching Droņācārya, King Duryodhana spoke the following words :  1.2",

      "Behold, O Revered Master, the mighty army of the sons of Pāņḍu arrayed for battle by your talented pupil, Dhṛṣṭadyumna, son of Drupada. 1.3",

      "There are in this army, heroes wielding mighty bows and equal in military prowess to Bhīma and Arjuna-Sātyaki and Virāṭa and the Mahārathī (warrior chief)  1.4",

      "Drupada; Dhṛṣṭaketu, Cekitāna and the valiant King of Kāśī, and Purujit, Kuntibhoja, and Śaibya, the best of men, 1.5",

      "and mighty Yudhāmanyu, and valiant Uttamaujā, Abhimanyu, the son of Subhadrā, and the five sons of Draupadī- all of them Mahārathīs (warrior chiefs). 1.6",

      "O best of Brāhmaņas, know them also who are the principal warriors on our side- the generals of my army. For your information I mention them. 1.7",

      "Yourself and Bhīṣma and Karņa and Kṛpa, who is ever victorious in battle; and even so Aśvatthāmā, Vikarņa and Bhūriśravā (the son of Somadatta); 1.8",

      "And there are many other heroes, all skilled in warfare equipped with various weapons and missiles, who have staked their lives for me. 1.9",

      "This army of ours, fully protected by Bhīṣma, is unconquerable; while that army of theirs, guarded in everyway by Bhīma, is easy to conquer. 1.10",

      "Therefore, stationed in your respective positions on all fronts, do you all guard Bhīṣma in particular on all sides.  1.11",

      "The grand old man of the Kaurava race, their glorious grand-patriarch Bhīṣma, cheering up Duryodhana, roared terribly like a lion and blew his conch. 1.12",

      "Then conches, kettledrums, tabors, drums and trumpets blared forth all at once and the noise was tumultuous.  1.13",

      "Then, seated in a glorious chariot drawn by white horses, Śrī Kṛṣņa as well as Arjuna blew their celestial conches. 1.14",

      "Śrī Kṛṣņa blew His conch named Pāñcajanya; Arjuna, Devadatta; while Bhīma of ferocious deeds blew his mighty conch Pauņḍra.  1.15",

      "King Yudhiṣṭhira, son of Kuntī, blew his conch Anantavijaya, while Nakula and Sahadeva blew theirs, known as Sughoṣa and Maņipuṣpaka respectively. 1.16",

      "And the excellent archer, the King of Kāśī, and Śikhaņḍī the Mahārathī (the great chariot-warrior), Dhṛṣṭadyumna and Virāṭa, and invincible Sātyaki,  1.17",

      "Drupada as well as the five sons of Draupadī, and the mighty-armed Abhimanyu, son of Subhadrā, all of them, O lord of the earth, severally blew their respective conches from all sides. 1.18",

      "And the terrible sound, echoing through heaven and earth, rent the hearts of Dhṛtarāṣṭra's army. 1.19",

      "Now, O lord of the earth, seeing your sons arrayed against him and when missiles were ready to be hurled,  1.20",

      "Arjuna, who had the figure of Hanumān on the flag of his chariot, took up his bow and then addressed the following words to Śrī Kṛṣņa; \"Kṛṣņa, place my chariot between the two armies.\" 1.21",

      "And keep it there till I have carefully observed these warriors drawn up for battle, and have seen with whom I have to engage in this fight. 1.22",

      "I shall have a look at the well-wishers of evilminded Duryodhana, in this war whoever have assembled on his side and are ready for the fight. 1.23",

      "Sañjaya said: O king, thus addressed by Arjuna, Śrī Kṛṣņa placed the magnificent chariot between the two armies  1.24",

      "in front of Bhīṣma, Droņa and all the kings and said, \"Arjuna, behold these Kauravas assembled here.\" 1.25",

      "Now Arjuna saw stationed there in both the armies his uncles, grand-uncles and teachers, even great grand-uncles, maternal uncles, brothers and cousins, sons and nephews, and grand-nephews, even so friends, fathers-in-law and well-wishers as well. (26 & first half of 27)",

      "Seeing all the relations present there, Arjuna was overcome with deep compassion and spoke thus in sorrow. (Second half of 27 and first half of 28)",

      "Seeing all the relations present there, Arjuna was overcome with deep compassion and spoke thus in sorrow. (Second half of 27 and first half of 28)\nArjuna said: \nKṛṣņa, as I see these kinsmen arrayed for battle,  (Second half of 28)",

      "my limbs give way, and my mouth is getting parched; nay, a shiver runs through my body and hair stands on end. 1.29",

      "The bow, Gāņḍīva, slips from my hand and my skin too burns all over; my mind is whirling, as it were, and I can no longer hold myself steady. 1.30",

      "And, Keśava, I see omens of evil, nor do I see any good in killing my kinsmen in battle.  1.31",

      "Kṛṣņa, I do not covet victory, nor kingdom, nor pleasures. Govinda, of what use will kingdom or luxuries or even life be to us! 1.32",

      "Those very persons for whose sake we covet the kingdom, luxuries and pleasures- 1.33",

      "teachers, uncles, sons and nephews and even so, granduncles and great grand-uncles, maternal uncles, fathers-in-law, grand-nephews, brothers-in-law and other relations-are here arrayed on the battlefield staking their lives and wealth. 1.34",

      "O Slayer of Madhu, I do not want to kill them, though they may slay me, even for the sovereignty over the three worlds; how much the less for the kingdom here on earth!  1.35",

      "Kṛṣņa, how can we hope to be happy slaying the sons of Dhṛtarāṣṭra; by killing even these desperadoes, sin will surely accrue to us. 1.36",

      "Therefore, Kṛṣņa, it does not behove us to kill our relations, the sons of Dhṛtarāṣṭra. For, how can we be happy after killing our own kinsmen? 1.37",

      "Even though these people, with their mind blinded by greed, perceive no evil in destroying their own race and no sin in treason to friends,  1.38",

      "why should not we, O Kṛṣņa, who see clearly the sin accruing from the destruction of one's family, think of desisting from committing this sin.  1.39",

      "Age-long family traditions disappear with the destruction of a family; and virtue having been lost, vice takes hold of the entire race.  1.40",

      "With the preponderance of vice, Kṛṣņa, the women of the family become corrupt; and with the corruption of women, O descendant of Vṛṣņi, there ensues an intermixture of castes. 1.41",

      "Progeny owing to promiscuity damns the destroyers of the race as well as the race itself. Deprived of the offerings of rice and water (Śrāddha, Tarpaņa etc.,) the manes of their race also fall.  1.42",

      "Through these evils bringing about an intermixture of castes, the age-long caste traditions and family customs of the killers of kinsmen get extinct.  1.43",

      "Kṛṣņa, we hear that men who have lost their family traditions, dwell in hell for an indefinite period of time.  1.44",

      "Oh what a pity! Though possessed of intelligence we have set our mind on the commission of a great sin; that due to lust for throne and enjoyment we are intent on killing our own kinsmen. 1.45",

      "It would be better for me if the sons of Dhṛtarāṣṭra, armed with weapons, kill me in battle, while I am unarmed and unresisting. 1.46",

      "Sañjaya said:\nArjuna, whose mind was agitated by grief on the battlefield, having spoken thus, and having cast aside his bow and arrows, sank into the hinder part of his chariot. 1.47",

      "Thus, in the Upaniṣad sung by the Lord, the Science of Brahma, the scripture of Yoga, the dialogue between Śrī Kṛṣņa and Arjuna, ends the first chapter entitled \"The Yoga of Dejection of Arjuna.\""
      ],
      "adhyaya-2" : [
      "Sañjaya said:\nŚrī Kṛṣṇa then addressed the following words to Arjuna, who was as mentioned before overwhelmed with pity, whose eyes were filled with tears and agitated, and who was full of sorrow. 2.1",

      "Śrī Bhagavān said:\nArjuna, how has this infatuation overtaken you at this odd hour? It is shunned by noble souls; neither will it bring heaven, nor fame to you. 2.2",

      "Yield not to unmanliness, Arjuna; it does not befit you. Shaking off this base faint-heartedness stand up , O scorcher of enemies. 2.3",

      "Arjuna said:\nHow  Kṛṣṇa, shall I fight Bhīṣma and Droṇa with arrows on the battle-field ? They are worthy of deepest reverence, O destroyer of foes. 2.4",

      "It is better to live on alms in this world by not slaying these noble elders, because even after killing them we shall after all enjoy only blood-stained pleasures in the form of wealth and sense-enjoyment. 2.5",

      "We do not even know which is preferable for us– to fight or not to fight; nor do we know whether we shall win or whether they will conquer us. Those very sons of Dhṛtarāṣṭra, killing whom we do not even wish to live, stand in the army ranks. 2.6",

      "With my very being smitten by the vice of faint-heartedness and my mind puzzled with regard to duty, I beseech you! tell me that which is decidedly good; I am your disciple. Pray, instruct me, who have taken refuge in you. 2.7",

      "For, even on obtaining undisputed sovereignty and an affluent kingdom on this earth and lordship over the gods, I do not see any means that can drive away the grief which is drying up my senses. 2.8",

      "Sañjaya said:\nO King, having thus spoken to Śrī Kṛṣṇa, Arjuna again said to Him,” I will not fight ” and became silent. 2.9",

      "Then, O Dhṛtarāṣṭra, Śrī Kṛṣṇa, as if smiling, addressed the following words to Arjuna, sorrowing in the midst of two armies. 2.10",

      "Śrī Bhagavān said:\nArjuna, you grieve over those who should not be grieved for and yet speak like the learned; wise men do not sorrow over the dead or the living 2.11",

      "In fact, there was never a time when I was not , or when you or these kings were not. Nor is it a fact that hereafter we shall all cease to be. 2.12",

      "Just as boyhood, youth and old age are attributed to the soul through this body, even so it attains another body, The wise man does not get deluded about this. 2.13",

      "O son of Kuntī, the contacts between the senses and their objects, which give rise to the feeling of heat and cold, pleasure and pain etc., are transitory and fleeting; therefore, Arjuna, endure them. 2.14",

      "Arjuna, the wise man to whom pain and pleasure are alike, and who is not tormented by these contacts, becomes eligible for immortality. 2.15",

      "The unreal has no existence, and the real never ceases to be; the reality of both has thus been perceived by the seers of Truth. 2.16",

      "Know that alone to be imperishable, which pervades this universe; for no one has power to destroy this indestructible substance. 2.17",

      "All these bodies pertaining to the imperishable, indefinable and eternal soul are spoken of as perishable; therefore , Arjuna, fight. 2.18",

      "Both of them are ignorant, he who considers the soul to be capable of killing and he who takes it as killed; for verily the soul neither kills, nor is killed. 2.19",

      "The soul is never born, nor it ever dies; nor does it become after being born. For, it is unborn, eternal, everlasting and primeval; even though the body is slain, the soul is not. 2.20",

      "Arjuna, the man who knows this soul to be imperishable, eternal and free from birth and decay-how and whom will he cause to be killed, how and whom will he kill ? 2.21",

      "As a man shedding worn-out garments, takes other new ones, likewise, the embodied soul, casting off worn-out bodies, enters into others that are new. 2.22",

      "Weapon can not cut it nor can fire burn it; water cannot wet it nor can wind dry it. 2.23",

      "For this soul is incapable of being cut, or burnt by fire; nor can it be desolved by water and is undriable by air as well; This soul is eternal, all-pervading, immovable, constant and everlasting. 2.24",

      "This soul is unmanifest; it is incomprehensible and it is spoken of as immutable. Therefore, knowing it as such , you should not grieve. 2.25",

      "And, Arjuna, if you should suppose this soul to be subject to constant birth and death, even then you should not grieve like this. 2.26",

      "For, in that case death is certain for the born, and rebirth is inevitable for the dead.You should not, therefor, grieve over the inevitable. 2.27",

      "Arjuna, before birth beings are not manifest to our human senses; on death they return to the un manifest again. They are manifest only in the interim between birth and death. What occasion, then, for lamentation? 2.28",

      "Hardly any great soul perceives this soul as marvellous, scarce another great soul likewise speaks thereof as marvellous, and scarce another worthy one hears of it as marvellous, while there are some who know it not even on hearing of it. 2.29",

      "Arjuna, this soul dwelling in the bodies of all, can never be slain; therefore , you should not mourn for any one. 2.30",

      "Besides, considering your own duty too, you should not waver, for there is nothing more welcome for a man of the warrior class than a righteous war. 2.31",

      "Arjuna, fortunate are the Kṣatriyās who get such an unsolicited opportunity for war, which is an open gateway to heaven. 2.32",

      "Now, if you refuse to fight this righteous war, then, shirking your duty and losing your reputation, you will incur sin. 2.33",

      "Nay, people will also pour undying infamy on you; and infamy brought on a man enjoying popular esteem is worse than death. 2.34",

      "And the warrior-chiefs who thought highly of you, will now despise you, thinking that it was fear which drove you away from battle. 2.35",

      "And your enemies, disparaging your might, will speak many unbecoming words; what can be more distressing than this? 2.36",

      "Die, and you will win heaven; conquer, and you enjoy sovereignty of the earth; therefore, stand up, Arjuna, determined to fight. 2.37",

      "Treating alike victory and defeat, gain and loss, pleasure and pain, get ready for the battle; fighting thus you will not incur sin. 2.38",

      "Arjuna, this attitude of mind has been presented to you from the point of view of Jñānayoga; now hear the same as presented from the standpoint of Karmayoga (the Yoga of selfless action). Equipped with this attitude of mind, you will be able to throw off completely the shackles of Karma. 2.39",

      "In this path (of selfless action) there is no loss of effort, nor is there fear of contrary result, even a little practice of this discipline saves one from the terrible fear of birth and death. 2.40",

      "Arjuna, in this Yoga (of selfless action) the intellect is determinate and directed singly towards one ideal; where as the intellect of undecided (ignorant men moved by desires) wanders in all directions, after innumerable aims. 2.41",

      "Arjuna, those who are full of worldly desires and devoted to the letter of the Vedas, who look upon heaven, as the supreme goal and argue that there is nothing beyond heaven, are unwise.  2.42",

      "They utter flowery speech recommending many rituals of various kinds for the attainment of pleasure and power with rebirth as their fruit. 2.43",

      "Those whose minds are carried away by such words, and who are deeply attached to pleasure and worldly power, cannot attain the determinate intellect concentrated on God. 2.44",

      "Arjuna, the Vedas thus deal with the evolutes of three Guṇas (modes of Prakṛti), viz., worldly enjoyments and the means of attaining such enjoyments; be thou indifferent to these enjoyments and their means, rising above pairs of opposites like pleasure and pain etc., established in the Eternal Existence (God), absolutely unconcerned about the fulfillments of wants and the preservation of what has been already attained, you be self-controlled. 2.45",

      "A Brāhmaṇa, who has obtained enlightenment, has as much use for all the Vedas as one who stands at the brink of a sheet of water overflowing on all sides has for a small reservoir of water. 2.46",

      "Your right is to work only and never to the fruit thereof. Do not consider yourself to be the cause of the fruit of  actions, nor let your attachment be to inaction. 2.47",

      "Arjuna, perform your duties established in Yoga, renouncing attachment, and be even-minded in success and failure; evenness of mind is called \"Yoga\". 2.48",

      "Action with a selfish motive is far inferior to this Yoga in the form of equanimity. Do seek refuge in this equipoise of mind, Arjuna; for poor and wretched are those who are the cause in making their actions bear fruits. 2.49",

      "Endowed with equanimity, one sheds in this life both good and evil. Therefor, strive for the practice of this Yoga of equanimity. Skill in action lies in the practice of this Yoga. 2.50",

      "For wise men possessing an equipoised mind, renouncing the fruit of actions and freed from the shackles of birth, attain the blissful supreme state. 2.51",

      "When your mind will have fully crossed the mire of delusion, you will then grow indifferent to the enjoyments of this world and the next that have been heard of as well as to those that are yet to be heard of. 2.52",

      "When your intellect, confused by hearing conflicting statements, will rest steady and undistracted (in meditation) on God, you will then attain Yoga (Everlasting union with God). 2.53",

      "Arjuna said:\nKṛṣṇa, what are the characteristics of a God-realized soul, stable of mind and established in Samādhi (perfect tranquility of mind)? How does the man of stable mind speak, how does he sit, how does he walk? 2.54",

      "Śrī Bhagavān said:\nArjuna, when one thoroughly casts off all cravings of the mind, and is satisfied in the Self through the joy of the Self, then he is called stable of mind. 2.55",

      "The sage, whose mind remains unperturbed amid sorrows, whose thirst for pleasures has altogether disappeared, and who is free from passion, fear and anger, is called stable of mind. 2.56",

      "He who is unattached to everything, and meeting with good and evil, neither rejoices nor recoils, his mind is stable. 2.57",

      "When, like a tortoise, that draws in its limbs from all directions, he withdraws all his senses from the sense-objects, his mind become steady. 2.58",

      "Sense-objects turn away from him, who does not enjoy them with his senses; but the taste for them persists, this relish also disappears in the case of the man of stable mind when he realises the Supreme. 2.59",

      "Turbulent by nature, the senses (not free from attachment) even of a wise man, who is practicing self-control, forcibly carry away his mind, Arjuna. 2.60",

      "Therefore, having controlled all the senses and concentrating his mind, he should sit for meditation, devoting himself heart and soul to Me. For, he whose senses are under his control, is known to have a stable mind. 2.61",

      "The man dwelling on sense-objects develops attachment for them; from attachment springs up desire, and from desire (unfulfilled) ensues anger. 2.62",

      "From anger arises delusion; from delusion, confusion of memory; from confusion of memory, loss of reason; and from loss of reason one goes to complete ruin. 2.63",

      "But the self -controlled Sādhaka, while enjoying the various sense-objects through his senses, which are disciplined and free from likes and dislikes, attains placidity of mind. 2.64",

      "With the attainment of such placidity of mind, all his sorrows come to an end; and the intellect of such a person of tranquil mind soon withdrawing itself from all sides, becomes firmly established in God. 2.65",

      "He who has not controlled his mind and senses, can have no determinate intellect, nor contemplation. Without contemplation, he can have no peace; and how can there be happiness for one lacking peace of mind? 2.66",

      "As the wind carries away a boat upon the waters, even so, of the senses moving among sense-objects, the one to which the mind is attached, takes away his discrimination. 2.67",

      "Therefore, Arjuna, he, whose senses are completely restrained from their objects, is said to have a stable mind. 2.68",

      "That which is night to all beings, in that state of Divine Knowledge and Supreme Bliss the God-realized Yogī keeps awake, and that (the ever-changing, transient worldly happiness) in which all beings keep awake, is night to the seer. 2.69",

      "As the waters of different rivers enter the ocean, which, though full on all sides, remains undisturbed; likewise, he in whom all enjoyments merge themselves without causing disturbance attains peace; not he who hankers after such enjoyments. 2.70",

      "He who has given up all desires, and moves free from attachments, egoism and thirst for enjoyment attains peace. 2.71",

      "Arjuna, such is the state of God-realized soul; having reached this state, he overcomes delusion. And established in this state, even at the last moment, he attains Brahmic Bliss. 2.72",

      "Thus, in the Upaniṣad sung by the Lord, the Science of Brahma, the scripture of Yoga, the dialogue between Śrī Kṛṣṇa and Arjuna, ends the second chapter entitled \"Sāṅkhyayoga\" (The Yoga of Knowledge)."
      ],
      "adhyaya-3" : [
      "Arjuna said :\nKṛṣņa, if You consider Knowledge as superior to Action, why then do You urge me to this dreadful action, Keśava! 3.1",

      "You are, as it were, puzzling my mind by these seemingly conflicting expressions; therefore, tell me the one definite discipline by which I may obtain the highest good. 3.2",

      "Śrī Bhagavān said:\nArjuna, in this world two courses of Sādhanā (spiritual discipline) have been enunciated by Me in the past. In the case of the Sāṅkhyayogī, the Sādhanā proceeds along the path of Knowledge; whereas in the case of the Karmayogī, it proceeds along the path of Action. 3.3",

      "Man does not attain freedom from action (culmination of the discipline of Action) without entering upon action; nor does he reach perfection (culmination of the discipline of Knowledge) merely by ceasing to act. 3.4",

      "Surely, none can ever remain inactive even for a moment; for, everyone is helplessly driven to action by modes of Prakṛti (nature born qualities). 3.5",

      "He who outwardly restraining the organs of sense and action, sits mentally dwelling on the objects of senses, that man of deluded intellect is called a hypocrite. 3.6",

      "On the other hand, he who controlling the organs of sense and action by the power of his will, and remaining unattached, undertakes the Yoga of selfless Action through those organs, Arjuna, he excels. 3.7",

      "Therefore, do you perform your allotted duty; for action is superior to inaction. Desisting from action, you cannot even maintain your body. 3.8",

      "Man is bound by his own action except when it is performed for the sake of sacrifice. Therefore, Arjuna, do you efficiently perform your duty, free from attachment, for the sake of sacrifice alone. 3.9",

      "Having created mankind along with Yajña, at the beginning of creation, the creator, Brahmā, said to them, \"You shall prosper by this; may this yield the enjoyments you seek.\" 3.10",

      "Foster the gods through this sacrifice, and let the gods foster you. Thus, each fostering the other selflessly, you will attain the highest good. 3.11",

      "Fostered by sacrifice, the gods will surely bestow on you unasked all the desired enjoyments. He who enjoys the gifts bestowed by them without offering their share to them, is undoubtedly a thief. 3.12",

      "The virtuous who partake of what is left over after sacrifice, are absolved of all sins. Those sinful ones who cook for the sake of nourishing their bodies alone, partake of sin only. 3.13",

      "All beings are evolved from food; production of food is dependent on rain; rain ensues from sacrifice, and sacrifice is rooted in prescribed action.  3.14",

      "Know that prescribed action has its origin in the Vedas, and the Vedas proceed from the Indestructible (God); hence the all-pervading Infinite is always present in sacrifice. 3.15",

      "Arjuna, he who does not follow the wheel of creation thus set going in this world i.e., does not perform his duties, leads a sinful and sensual life, he lives in vain. 3.16",

      "He, however, who takes delight in the Self alone and is gratified with the Self, and is contented in the Self, has no duty. 3.17",

      "In this world that great soul has nothing to gain by action nor by abstaining from action; nor has he selfish dependence of any kind on any creature. 3.18",

      "Therefore, go on efficiently doing your duty at all times without attachment. Doing work without attachment man attains the Supreme. 3.19",

      "It is through action without attachment alone that Janaka and other wise men reached perfection. Having in view the maintenance of the world order too, you should take to action. 3.20",

      "For, whatever a great man does, that very thing other men also do; whatever standard he sets up, the generality of men follow the same. 3.21",

      "Arjuna, there is no duty in all the three worlds for Me to perform, nor is there anything worth attaining, unattained by Me; yet I continue to work. 3.22",

      "Should I not engage in action scrupulously at any time, great harm will come to the world; for, Arjuna, men follow My way in all matters. 3.23",

      "If I ever cease to act, these worlds would perish; nay, I should prove to be the cause of confusion, and of the destruction of these people. 3.24",

      "Arjuna, as the unwise act with attachment, so should the wise man, with a view to maintain the world order, act without attachment. 3.25",

      "A wise man established in the Self should not unsettle the mind of the ignorant attached to action, but should get them to perform all their duties, duly performing his own duties. 3.26",

      "In fact all actions are being performed by the modes of Prakṛti (Primordial Nature). The fool, whose mind is deluded by egoism, thinks: \"I am the doer.\" 3.27",

      "However, he who has true insight into the respective spheres of Guņas (modes of Prakṛti) and their actions, holding that it is the Guņas (in the form of the senses, mind, etc.,) that move among the Guņas (objects of perception), does not get attached to them, Arjuna. 3.28",

      "Those who are completely deluded by the Guņas (modes) of Prakṛti remain attached to those Guņas and actions; the man of perfect Knowledge should not unsettle the mind of those ignorants of imperfect knowledge. 3.29",

      "Hence, relinquish your actions to Me, focus your thoughts on the Ultimate Reality, devoid of selfishness and any expectation of reward. Approach the battle with a calm and composed mind, free from agitation. 3.30",

      "Even those men who, with an uncavilling and devout mind, always follow this teaching of Mine, are released from the bondage of all actions. 3.31",

      "But they, however, who, finding fault with this teaching of Mine, do not follow it, take those fools to be deluded in the matter of all knowledge as lost. 3.32",

      "All living creatures follow their tendencies; even the wise man acts according to the tendencies of his own nature. Of what use is restraint by force. 3.33",

      "Attraction and repulsion are rooted in all sense-objects. Man should never allow himself to be swayed by them, because they are the two principal enemies standing in the way of his redemption. 3.34",

      "One's own duty, though devoid of merit, is preferable to the duty of another well performed. Even death in the performance of one's own duty brings blessedness; another's duty is fraught with fear. 3.35",

      "Arjuna said:\nNow impelled by what, Kṛṣņa, does this man commit sin even involuntarily, as though driven by force? 3.36",

      "Śrī Bhagavān said:\nIt is desire begotten of the element of Rajas, which appears as wrath; nay, it is insatiable and grossly wicked. Know this to be the enemy in this case. 3.37",

      "As fire is covered by smoke, mirror by dust, and embryo by the amnion, so is knowledge covered by desire. 3.38",

      "And, Arjuna, Knowledge stands covered by this eternal enemy of the wise, known as desire, which is insatiable like fire. 3.39",

      "The senses, the mind and the intellect are declared to be its seat; covering the knowledge through these, it (desire) deludes the embodied soul. 3.40",

      "Therefore, Arjuna, you must first control your senses, and then kill this evil thing which obstructs Jñāna (Knowledge of the Absolute or Nirguņa Brahma) and Vijñāna (Knowledge of Sākāra Brahma or manifest Divinity). 3.41",

      "The senses are said to be greater than the body; but greater than the senses is the mind. Greater than the mind is the intellect; and what is greater than the intellect is He, the Self. 3.42",

      "Thus, Arjuna, knowing the Self which is higher than the intellect and subduing the mind by reason, kill this enemy in the form of desire that is hard to overcome. 3.43",

      "Thus, in the Upaniṣad sung by the Lord, the Science of Brahma, the scripture of Yoga, the dialogue between Śrī Kṛṣņa and Arjuna, ends the third chapter entitled \"Karmayoga, or the Yoga of Action.\""
      ],
      "adhyaya-4" : [
      "Śrī bhagvān said: I revealed this imortal yoga to Vivasvān(sun-god); Vivasvān conveyd it to Manu(his son); and Manu Imparted it to his son Ikṣvāku. 4.1",

      "Thus transmitted in succession from father to son, Arjuna, this Yoga remained known to the Rājaraṣis is (royal sages). Through long lapse of time, this Yoga got lost to the world. 4.2",

      "The same ancient Yoga, which is the supreme secret, has this day been imparted to you by Me, because you are My devotee and friend. 4.3",

      "Arjuna said: You are of recent origin, while the birth of Vivāsvan dates back to remote antiquity. How, then, am I to believe that You imparted this Yoga at the beginning of the creation? 4.4",

      "Śrī Bhagavan said : Arjuna, you and I have passed through many births; I remember them all; you do not remember, O chastiser of foes. 4.5",

      "Though birthless and immortal and the Lord of all beings, I manifest Myself through My own Yogamāyā (divine potency), keeping My nature (Prakṛti) under control. 4.6",

      "Arjuna, whenever righteousness is on the decline, unrighteousness is in the ascendant, then I body Myself forth. 4.7",

      "For the protection of the virtuous, for the extirpation of evil-doers, and for establishing Dharma (righteousness) on a firm footing, I manifest Myself from age to age. 4.8",

      "Arjuna, My birth and activities are divine. He who knows this in reality is not reborn on leaving his body, but comes to Me. 4.9",

      "Completely rid of attachment, fear and anger, wholly absorbed in Me, depending on Me, and purified by the penance of wisdom, many have become one with Me even in the past. 4.10",

      "Arjuna, howsoever men seek Me, even so do I respond to them; for all men follow My path in everyway. 4.11",

      "In this world of human beings, men seeking the fruition of their activities, worship the gods; for success born of actions follows quickly 4.12",

      "The four orders of society (viz., the Brāhmana, the Kṣatriya, the Vaiśya and the śūdra) were created by Me, classifying them according to the Guṇas predominant in each and apportioning corresponding duties to them; though the originator of this creation, know Me, the Immortal Lord, to be a non-doer. 4.13",

      "Since I have no craving for the fruit of actions, actions do not taint Me. Even he who thus knows Me in reality is not bound by actions. 4.14",

      "Having known thus, action was performed even by the ancient seekers for liberation; therefore, do you also perform actions as have been performed by the ancients from antiquity. 4.15",

      "What is action and what is inaction? Even men of intelligence are puzzled over this question. Therefore, I shall expound to you the truth about action, knowing which you will be freed from its evil effects i.e., the shackles of karma. 4.16",

      "The truth about action must be known and the truth of inaction also must be known; even so, the truth about prohibited action (Vikarma) must be known. For, mysterious are the ways of action. 4.17",

      "He who sees inaction in action, and action in inaction, is wise among men; he is a Yogī, who has performed all actions. 4.18",

      "Even the wise call him a sage, whose undertakings are all free from desire and Sankalpa (thoughts of the world) and whose actions are burnt up by the fire of wisdom. 4.19",

      "He, who, having totally given up attachment to actions and their fruit, no longer depends on anything in the world, and is ever content, does nothing at all, though fully engaged in action. 4.20",

      "Having subdued his mind and body, and having given up all objects of enjoyment, free from craving, he who performs sheer bodily action, does not incur sin. 4.21",

      "The Karmayogī, who is contented with whatever is got unsought, is free from jealousy and has transcended all pairs of opposites like joy and grief, and is balanced in success and failure, is not bound by his action. 4.22",

      "All his actions get dissolved entirely, who is free from attachment and has no identification with the body and free from the feeling of mine, whose mind is established in the knowledge of Self and who works merely for the sake of sacrifice. 4.23",

      "In the practice of seeing Brahma everywhere as a form of sacrifice, Brahma is the ladle (with which oblation is poured into the fire, etc.); Brahma, again, is the oblation; Brahma is the fire, Brahma itself is the sacrificer and so Brahma itself constitutes the act of pouring the oblation into the fire. And, finally, Brahma is the goal to be reached by him who is absorbed in Brahma as the act of such sacrifice. 4.24",

      "Other Yogīs duly offer sacrifice only in the form of worship to gods, while others perform sacrifice by offering the self by the Self itself in the fire of Brahma. 4.25",

      "Others offer as sacrifice their senses of hearing etc., into the fires of self-discipline. Other Yogis, again, offer sound and other objects of perception into the fires of the senses. 4.26",

      "Others sacrifice all the functions of their senses and the functions of the vital airs (Prāṇa) into the fire of Yoga in the shape of self-control, kindled by wisdom. 4.27",

      "Some perform sacrifice with material possessions; some offer sacrifice in the shape of austerities; others sacrifice through the practice of Yoga; while some striving souls, observing austere vows, perform sacrifice in the shape of wisdom through the study of sacred texts. 4.28",

      "Other Yogīs offer the act of exhalation into that of inhalation; even so, others the act of inhalation into that of exhalation. There are still others given to the practice of Prāṇāyāma (breath- control), who, having regulated their diet and controlled the processes of exhalation and inhalation both, pour their vital airs into the vital airs themselves. All these have their sins consumed away by sacrifice and understand the meaning of sacrificial worship. (4.29-4.30)",

      "Other Yogīs offer the act of exhalation into that of inhalation; even so, others the act of inhalation into that of exhalation. There are still others given to the practice of Prāṇāyāma (breath- control), who, having regulated their diet and controlled the processes of exhalation and inhalation both, pour their vital airs into the vital airs themselves. All these have their sins consumed away by sacrifice and understand the meaning of sacrificial worship. (4.29-4.30)",

      "Arjuna, Yogīs who enjoy the nectar that has been left over after the performance of a sacrifice attain the eternal Brahma. To the man who does not offer sacrifice, even this world is not happy; how, then, can the other world be happy?  4.31",

      "Many such forms of sacrifice have been set forth in detail in the Vedas; know them all as involving the action of mind, senses and body. Thus, knowing the truth about them you shall be freed from the bondage of action (through their performance). 4.32",

      "Arjuna, sacrifice through Knowledge, is superior to sacrifice performed with material things. For all actions without exception culminate in Knowledge, O son of Kuntī. 4.33",

      "Understand the true nature of that Knowledge by approaching seers of Truth. If you prostrate at their feet, render them service, and question them with an open and guileless heart, those wise seers of Truth will instruct you in that Knowledge. 4.34",

      "Arjuna, when you have achieved enlightenment, ignorance will delude you no more. In the light of that knowledge, you will see the entire creation first within your own Self, and then in Me (the Oversoul). 4.35",

      "Even if you were the most sinful of all sinners, this Knowledge alone would carry you, like a raft, across all your sins. 4.36",

      "For, as the blazing fire reduces the fuel to ashes, Arjuna, even so the fire of Knowledge turns all actions to ashes. 4.37",

      "In this world there is no purifier as great as Knowledge; he who has attained purity of heart through prolonged practice of Karmayoga, automatically sees the light of Truth in the self in course of time. 4.38",

      "He who has mastered his senses, is exclusively devoted to his practice and is full of faith, attains Knowledge; having had the revelation of Truth, he immediately attains supreme peace in the form of God-realization. 4.39",

      "He who lacks discrimination, is devoid of faith, and is at the same time possessed by doubt, is lost to the spiritual path. For the doubting soul there is neither this world nor the world beyond, nor even happiness. 4.40",

      "Arjuna, actions do not bind him who has dedicated all his actions to God according to the spirit of Karmayoga, whose doubts have been dispelled by wisdom and who is self-possessed.  4.41",

      "Therefore, Arjuna slashing to pieces, with the sword of knowledge, this doubt in your heart, born of ignorance, establish yourself in Karmayoga in the shape of even-mindedness, and stand up for the fight. 4.42",

      "Thus, in the Upaniṣad sung by the Lord, the Science of Brahma, the scripture of Yoga, the dialogue between Śrī Kṛṣņa and Arjuna, ends the fourth chapter entitled \"The Yoga of Knowledge as well as the disciplines of Action and Knowledge\""
      ],
      "adhyaya-5" : [
      "Arjuna said:\nKṛṣņa, you extol Sāṅkhyayoga (the Yoga of Knowledge) and then the Yoga of Action. Pray, tell me which of the two is decidedly conducive to my good. 5.1",

      "Śrī Bhagavān said:\nThe Yoga of Knowledge and the Yoga of Action both lead to supreme Bliss. Of the two, however, the Yoga of Action, being easier of practice, is superior to the Yoga of Knowledge. 5.2",

      "The Karmayogī who neither hates nor desires should ever be considered as an ever renunciant. For, Arjuna, he who is free from the pairs of opposites is easily liberated from bondage. 5.3",

      "It is the ignorant, not the wise, who say that Sāṅkhyayoga and Karmayoga lead to divergent results. For, one who is firmly established in either, gets the fruit of both which is the same, viz., God-realization. 5.4",

      "The (supreme) state which is reached by the Sāṅkhyayogī is attained also by the Karmayogī. Therefore, he alone who sees Sāṅkhyayoga and Karmayoga as identical so far as their result goes, sees truly. 5.5",

      "Without Karmayoga, however, Sāṅkhyayoga i.e., renunciation of doership in relation to all activities of the mind, senses and body is difficult to accomplish; whereas the Karmayogī, who keeps his mind fixed on God, reaches Brahma in no time, Arjuna. 5.6",

      "The Karmayogī, who has fully conquered his mind and mastered his senses, whose heart is pure, and who has identified himself with the Self of all beings (viz., God), remains untainted, even though performing action. 5.7",

      "However, the Sāṅkhyayogī, who knows the reality of things, must believe that he does nothing, even though seeing, hearing, touching, smelling, eating or drinking, walking, sleeping, breathing, 5.8",

      "speaking, answering the calls of nature, grasping, and opening or closing the eyes, holding that it is the senses alone that are moving among their objects. 5.9",

      "He who acts offering all actions to God, and shaking off attachment, remains untouched by sin, as the lotus leaf by water. 5.10",

      "The Karmayogīs perform action only with their senses, mind, intellect and body as well, without the feeling of mine in respect of them and shaking off attachment, simply for the sake of self-purification. 5.11",

      "Offering the fruit of actions to God, the Karmayogī attains everlasting peace in the form of God-realization; whereas, he who works with a selfish motive, being attached to the fruit of actions through desire, gets tied down. 5.12",

      "The self-controlled Sāṅkhyayogī, doing nothing himself and getting nothing done by others, rests happily in God-the embodiment of Truth, Knowledge and Bliss, mentally relegating all actions to the mansion of nine gates (the body with nine openings). 5.13",

      "God determines neither the doership nor the doings of men, nor even their contact with the fruit of actions; but it is Nature alone that does all this. 5.14",

      "The omnipresent God does not partake the virtue or sin of anyone. Knowledge is enveloped by ignorance; hence it is that beings are constantly falling a prey to delusion. 5.15",

      "In the case, however, of those whose said ignorance has been destroyed by true knowledge of God, that wisdom shining like the sun reveals the Supreme. 5.16",

      "Those whose mind and intellect are wholly merged in Him, who remain constantly established in identity with Him, and have finally become one with Him, their sins being wiped out by wisdom, reach the supreme goal whence there is no return. 5.17",

      "The wise look with equanimity on all whether it be a Brāhmaņa endowed with learning and humility, a cow, an elephant, a dog and a pariah, too. 5.18",

      "Even here is the mortal plane conquered by those whose mind is established in equanimity; since the Absolute is untouched by evil and is the same to all, hence they are established in Paramātmā. 5.19",

      "He who, with firm intellect and free from doubt, rejoices not on obtaining what is pleasant and does not feel perturbed on meeting with the unpleasant, that knower of Brahma lives eternally in identity with Brahma. 5.20",

      "He whose mind remains unattached to senseobjects, derives through meditation, the Sāttvika joy which dwells in the mind; then that Yogī, having completely identified himself through meditation with Brahma, enjoys eternal Bliss. 5.21",

      "The pleasures which are born of sense-contacts, are verily a source of suffering only (though appearing as enjoyable to worldly-minded people). They have a beginning and an end (they come and go); Arjuna, it is for this reason that a wise man does not indulge in them. 5.22",

      "He alone, who is able to withstand, in this very life before casting off this body, the urges of lust and anger, is a Yogī, and he alone is a happy man. 5.23",

      "He who is happy within himself, enjoys within himself the delight of the soul, and, even so, is illumined by the inner light (light of the soul), such a Yogī (Sāṅkhyayogī) identified with Brahma attains Brahma, who is all peace. 5.24",

      "The seers whose sins have been purged, whose doubts have been dispelled by knowledge, whose disciplined mind is firmly established in God and who are devoted to the welfare of all beings, attain Brahma, who is all peace. 5.25",

      "To those wise men who are free from lust and anger, who have subdued their mind and have realized God, Brahma, the abode of eternal peace, is present all-round. 5.26",

      "Shutting out all thoughts of external enjoyments, with the gaze fixed on the space between the eye-brows, having regulated the Prāņa (outgoing) and the Apāna (incoming) breaths flowing within the nostrils, 5.27",

      "he who has brought his senses, mind and intellect under control-such a contemplative soul intent on liberation and free from desire, fear and anger, is ever liberated. 5.28",

      "Having known Me in reality as the enjoyer of all sacrifices and austerities, the supreme Lord of all the worlds, and the selfless friend of all beings, My devotee attains peace. 5.29",

      "Thus, in the Upaniṣad sung by the Lord, the Science of Brahma, the scripture of Yoga, the dialogue between Śrī Kṛṣņa and Arjuna, ends the fifth chapter entitled \"The Yoga of Action and Knowledge.\""
      ],
      "adhyaya-6" : [
      "Śrī Bhagavān said:\nHe who does his duty without expecting the fruit of action is Sannyāsī (Sāṅkhyayogī)  and Yogī (Karmayogī) both. He is no Sannyāsī (renouncer) who has simply renounced the sacred fire; even so, he is no Yogī who has given up all activity 6.1",

      "Arjuna, you must know that what they call as Sannyāsa is no other than Yoga; for none becomes a Yogī who has not abandoned his 'Saṅkalpas' (thoughts of the world). 6.2",

      "To the contemplative soul who desires to attain Karmayoga, selfless action is said to be the means; for the same man when he is established in Yoga, absence of all 'Saṅkalpas' (thoughts of the world) is said to be the way to blessedness. 6.3",

      "When a man ceases to have any attachment for the objects of senses and for actions, and has renounced all 'Saṅkalpas' (thoughts of the world), he is said to have attained Yoga. 6.4",

      "One should lift oneself by one's own efforts and should not degrade oneself; for one's own self is one's friend, and one's own self is one's enemy 6.5",

      "One's own self is the friend of the soul by whom the lower self (consisting of the mind, senses, and body) has been conquered; even so, the very Self of him, who has not conquered his lower self, behaves antagonistically like an enemy. 6.6",

      "The Supreme Spirit is rooted in the knowledge of the self-controlled man whose mind is perfectly serene in the midst of pairs of opposites ,such as cold and heat, joy and sorrow, and honor and ignominy. 6.7",

      "The Yogī whose mind is sated with Jñāna (Knowledge of Nirguņa Brahma) and Vijñāna (Knowledge of manifest Divinity), who is unmoved under all circumstances, whose senses are completely under control, and to whom mud, stone and gold are all alike, is spoken of as a God-realized soul. 6.8",

      "He who looks upon well-wishers and neutrals as well as mediators, friends and foes, relatives and inimicals, the virtuous and the sinful, with equanimity, stands supreme. 6.9",

      "Living in seclusion all by himself, the Yogī who has controlled his mind and body, and is free from desires and void of possessions, should constantly engage his mind in meditation. 6.10",

      "Having firmly set his seat in a spot which is free from dirt and other impurities with the sacred Kuśa grass, deerskin and cloth spread thereon one upon the other( Kuśa below, deerskin in the middle and cloth uppermost), neither very high nor very low; 6.11",

      "And occupying that seat , concentrating the mind and controlling the functions of the mind and senses, he should practice Yoga for self purification. 6.12",

      "Holding the trunk, head and neck straight and steady, remaining firm and fixing the gaze on the tip of his nose, without looking in other directions. 6.13",

      "Firm in the vow of complete chastity and fearlessness, keeping himself perfectly calm and with the mind held in restraint and fixed on Me, the vigilant Yogī should sit absorbed in Me. 6.14",

      "Thus constantly applying his mind to Me, the Yogī of disciplined mind attains the everlasting peace, consisting of Supreme Bliss, which abides in Me. 6.15",

      "Arjuna, this yoga is neither for him who overeats, nor for him who observes a complete fast; it is neither for him who is given to too much sleep, nor even for him who is ceaselessly awake. 6.16",

      "Yoga which rids one of woe, is accomplished only by him who is regulated in diet and recreation, regulated in performing actions, and is regulated in sleep and wakefulness. 6.17",

      "When the mind which is thoroughly disciplined, gets riveted on God alone , then the person who is free from yearning for all enjoyments is said to be established in Yoga. 6.18",

      "As a flame does not flicker in a windless place, such is stated to be the picture of the disciplined mind of Yogī practicing meditation on God. 6.19",

      "The state in which the Chitta (mind), subdued through the practice of Yoga,  becomes completely tranquil, and in which, realizing God through subtle reasoning purified by meditation on God, the soul rejoices only in God. 6.20",

      "Nay, in which the soul experiences the eternal and super-sensuous joy which can be intuited only through the subtle and purified intellect, and wherein established the said Yogī moves not from Truth on any account. 6.21",

      "And having obtained which he does not reckon any other gain as greater than that, and established in which he is not shaken even by the heaviest of sorrows; 6.22",

      "That state called Yoga, which is free from the contact of sorrow ( in the form of transmigration), should be known. Nay, this Yoga should be resolutely practiced with an unwearied mind. 6.23",

      "Completely renouncing all desires arising from the Saṅkalpas (thoughts of the world), and fully restraining all the senses from all sides by the mind. 6.24",

      "He should through gradual practice, attain tranquillity; and fixing the mind on God through reason controlled by steadfastness, he should not think of any thing else. 6.25",

      "Drawing back the restless and fidgety mind from all those objects after which it runs, he should repeatedly fix it on God. 6.26",

      "For, to the Yogī whose mind is perfectly serene, who is sinless, whose passion is subdued, and who is identified with Brahma, the embodiment of Truth, Knowledge and Bliss, supreme happiness comes as a matter of course 6.27",

      "The sinless Yogī, thus uniting his Self constantly with God, easily enjoys the eternal Bliss of oneness with Brahma. 6.28",

      "The Yogī who is united in identity with the all-pervading, infinite consciousness, whose vision everwhere is even, beholds the Self existing in all beings and all beings as assumed in the Self. 6.29",

      "He who sees Me (the Universal Self) present in all beings, and all being existing within Me, he is never out of My sight, nor am I ever out of his sight. 6.30",

      "The Yogī who is established in union with Me, and worships Me as residing in all beings as their very Self, whatever activity he performs, he performs than in Me. 6.31",

      "Arjuna, he, who looks on all as one, on the analogy of his own Self, and looks upon the joy and sorrow of all equally - such a Yogī is deemed to be the highest of all. 6.32",

      "Arjuna said:\nKṛṣṇa, owing to restlessness of mind, I do not perceive the stability of this Yoga in the form of equanimity, which You have just spoken of. 6.33",

      "For, Kṛṣṇa, the mind is very unsteady, turbulent, tenatious and powerful; therefore, I consider it as difficult to control as the wind. 6.34",

      "Śrī Bhagavān said:\nThe mind is restless no doubt, and difficult to curb, Arjuna; but it can be brought under control by repeated practice (of meditation) and by the exercise of dispassion, O son of Kuntī. 6.35",

      "Yoga is difficult of achievement by one whose mind is not subdued by him; however, who has the mind under control, and is ceaselessly striving, it can be easily attained through practice. Such is My conviction. 6.36",

      "Arjuna said:\nKṛṣṇa, what becomes of the aspirant who, though endowed with faith, has not been able to subdue his passion, and whose mind is, therefore, diverted from Yoga at the time of death, and who thus fails to reach perfection in Yoga (God-Realization)? 6.37",

      "Kṛṣṇa, swerved from the path leading to God-Realization and without any thing to stand upon, is he not lost like the scattered cloud, deprived of both God-Realization and heavenly enjoyment? 6.38",

      "Kṛṣṇa, only You are capable to remove this doubt of mine completely; for none other than You can dispel this doubt. 6.39",

      "Śrī Bhagavān said:\nArjuna, there is no fall for him either here or hereafter. For, O My beloved,  none who strives for self-redemption (i.e., God-Realization) ever meets with evil destiny. 6.40",

      "Such a person who has strayed from Yoga, obtains the higher worlds, (heaven etc.) to which men of meritorious deeds alone are entitled, and having resided there for innumerable years, takes birth  of pious and prosperous parents. 6.41",

      "Or, if he is possessed of dispassion, then not attaining to those reasons he is born in the family of enlightened Yogīs; but such a birth in this world is very difficult to obtain. 6.42",

      "Arjuna, he automatically regains in that birth the latencies of even-mindedness of his previous birth; and through that he strives, harder than ever for perfection in the form of God-Realization. 6.43",

      "The other one who takes birth in a rich family, though under the sway of his senses, feels drawn towards God by force of the habit acquired in his previous birth; nay, even the seeker of Yoga (in the form of even-mindedness) transcends the fruit of actions performed with some interested motive as laid down in the Vedas. 6.44",

      "The Yogī, however, who diligently takes up the practice, attains perfection in this very life with the help of latencies of many births, and being thoroughly purged of sin, forthwith reaches the Supreme state. 6.45",

      "The Yogī is superior to the ascetics; he is regarded superior even to those versed in sacred lore. The Yogī is also superior to those who perform action with some interested motive. Therefore, Arjuna, do become a Yogī. 6.46",

      "Of all the Yogīs, again, he who devoutly worships Me with his mind focussed on Me is considered by Me to be the best Yogī. 6.47",

      "Thus, in the Upaniṣad sung by the Lord, the Science of Brahma, the scripture of Yoga, the dialogue between Śrī Kṛṣņa and Arjuna, ends the sixth chapter entitled ” The Yoga of Self-control."
      ],
      "adhyaya-7" : [
      "Śrī Bhagavān said:\nArjuna, now listen how with the mind attached to Me (through exclusive love) and practising Yoga with absolute dependence on Me, you will know Me, the repository of all power, strength and glory and other attributes, the Universal soul, in entirety and without any shadow of doubt. 7.1",

      "I shall unfold to you in its entirety this wisdom (Knowledge of God in His absolute formless aspect) along with the Knowledge of the qualified aspect of God (both with form and without form), having known which nothing else remains yet to be known in this world. 7.2",
      
      "Hardly one among thousands of men strives to realize Me; of those striving Yogīs, again, some rare one, devoting himself exclusively to Me, knows Me in reality. 7.3",
      
      "Earth, water, fire, air, ether, mind, reason and also ego-these constitute My nature divided into eight parts. This indeed is My lower (material) nature; 7.4",
      
      "the other than this, by which the whole universe is sustained, know it to be My higher (or spiritual) nature in the form of Jīva (the lifeprinciple), O Arjuna. 7.5",
      
      "Arjuna, know that all beings have evolved from this twofold Prakṛti, and that I am the source of the entire creation, and into Me again it dissolves. 7.6",
      
      "There is nothing else besides Me, Arjuna. Like clusters of yarn-beads formed by knots on a thread, all this is threaded on Me. 7.7",
      
      "Arjuna, I am the sapidity in water and the radiance in the moon and the sun; I am the sacred syllable OM in all the Vedas, the sound in ether, and virility in men. 7.8",
      
      "I am the pure odour (the subtle principle of smell) in the earth and the brightness in fire; nay, I am the life in all beings and austerity in the ascetics. 7.9",
      
      "Arjuna, know Me the eternal seed of all beings. I am the intelligence of the intelligent; the glory of the glorious am I. 7.10",
      
      "Arjuna, of the mighty I am the might, free from passion and desire; in beings I am the sexual desire not conflicting with virtue or scriptural injunctions. 7.11",
      
      "Whatever other entities there are, born of Sattva (the quality of goodness), and those that are born of Rajas (the principle of activity) and Tamas (the principle of inertia), know them all as evolved from Me alone. In reality, however, neither do I exist in them, nor do they in Me. 7.12",
      
      "The whole of this creation is deluded by these objects evolved from the three modes of Prakṛti- Sattva, Rajas and Tamas; that is why the world fails to recognize Me, standing apart from these, the Imperishable. 7.13",
      
      "For, this most wonderful Māyā (veil) of Mine, consisting of the three Guņas (modes of Nature), is extremely difficult to breakthrough; those, however, who constantly adore Me alone, are able to cross it. 7.14",
      
      "Those whose wisdom has been carried away by Māyā, and are of demoniac nature, such foolish and vile men of evil deeds do not adore Me. 7.15",
      
      "Four types of devotees of noble deeds worship Me, Arjuna, the seeker after worldly possessions, the afflicted, the seeker for knowledge, and the man of wisdom, O best of Bharatas. 7.16",
      
      "Of these, the best is the man of wisdom, ever established in identity with Me and possessed of exclusive devotion. For, I am extremely dear to the wise man who knows Me in reality, and he is extremely dear to Me. 7.17",
      
      "Indeed, all these are noble, but the man of wisdom is My very self; such is My view. For such a devotee, who has his mind and intellect merged in Me, is firmly established in Me alone as the highest goal. 7.18",
      
      "In the very last of all births the enlightened person worships Me by realizing that all this is God. Such a great soul is very rare indeed. 7.19",
      
      "Those whose wisdom has been carried away by various desires, being prompted by their own nature, worship other deities, adopting norms relating to each. 7.20",
      
      "Whatever celestial form a devotee (craving for some worldly object) chooses to worship with reverence, I stabilize the faith of that particular devotee in that very form. 7.21",
      
      "Endowed with such faith, he worships that particular deity and obtains through that deity without doubt his desired enjoyments as verily ordained by Me. 7.22",
      
      "The fruit gained by these people of small understanding, however, is perishable. The worshippers of gods attain the gods; whereas My devotees, howsoever they worship Me, eventually come to Me and Me alone. 7.23",
      
      "Not knowing My supreme nature, unsurpassable and undecaying, the ignorant persons regard Me, who am the Supreme Spirit beyond the reach of mind and senses, and the embodiment of Truth, Knowledge and Bliss, to have assumed a finite form through birth as an ordinary human being. 7.24",
      
      "Veiled by My Yogamāyā, My divine potency, I am not manifest to all. Hence these ignorant folk fail to recognize Me, the birthless and imperishable Supreme Deity i.e., consider Me as subject to birth and death. 7.25",
      
      "Arjuna, I know all beings, past as well as present, nay, even those that are yet to come; but none, devoid of faith and devotion, knows Me. 7.26",
      
      "O valiant Arjuna, through delusion in the form of pairs of opposites (such as pleasure and pain etc.,) born of desire and aversion, all living creatures in this world are falling a prey to infatuation. 7.27",
      
      "But those men of virtuous deeds, whose sins have come to an end, being freed from delusion in the form of pairs of opposites born of attraction and repulsion, worship Me with a firm resolve in every way. 7.28",
      
      "They who, having taken refuge in Me, strive for deliverance from old age and death, know Brahma (the Absolute), the whole Adhyātma (the totality of Jīvas or embodied souls), and the entire field of Karma (action) as well as My integral being, comprising Adhibhūta (the field of Matter), 7.29",
      
      "Adhidaiva (Brahmā) and Adhiyajña (the unmanifest Divinity dwelling in the heart of all beings as their witness). And they who, possessed of a steadfast mind, know thus even at the hour of death, they too know Me alone. 7.30",
      
      "Thus, in the Upaniṣad sung by the Lord, the Science of Brahma, the scripture of Yoga, the dialogue between Śrī Kṛṣņa and Arjuna, ends the seventh chapter entitled \"The Yoga of Jñāna (Knowledge of Nirguņa Brahma) and Vijñāna (Knowledge of Manifest Divinity).\""
      ],
      "adhyaya-8" : [
      "Arjuna said:\nKṛṣṇā, what is that Brahma (Absolute), what is Adhyātma ( Spirit), and what is Karma (Action)? What is called Adhibhūta (Matter) and what is termed as Adhidaiva (Divine Intelligence)? 8.1",

      "Kṛṣṇa, who is Adhiyajña here and how does he dwell in the body? And how are you to be realized at the time of death by those of steadfast mind? 8.2",

      "Śrī Bhagavan said:\nThe supreme Indestructible is Brahma, one's own Self (the individual soul) is called AdhyAtṃā; and the Primal resolve of God (Visarga), which brings forth the existence of beings, is called Karma (Action). 8.3",

      "All perishable objects are Adhibhūta, the shining Puruşa (Brahma) is Adhidaiva and in this body I Myself, dwelling as the inner witness, am Adhiyajña, O Arjuna ! 8.4",

      "He who departs from the body, thinking of Me alone even at the time of death, attains My state; there is no doubt about it. 8.5",

      "Arjuna, thinking of whatever entity one leaves the body at the time of death, that and that alone one attains, being ever absorbed in its thought. 8.6",

      "Therefore, Arjuna, think of Me at all times and fight. With mind and reason thus set on Me, you will doubtless come to Me. 8.7",

      "Arjuna, he who with his mind disciplined through Yoga in the form of practice of meditation and thinking of nothing else, is constantly engaged in contemplation of God attains the supremely effulgent Divine Purușa (God). 8.8",

      "He who contemplates on the all-knowing, ageless Being, the Ruler of all, subtler than the subtle, the universal sustainer, possessing a form beyond human conception, effulgent like the sun and far beyond the darkness of ignorance. 8.9",

      "Having by the power of Yoga firmly held the life-breath in the space between the two eyebrows even at the time of death, and then contemplating on God with a steadfast mind, full of devotion, he reaches verily that supreme divine Purușa (God). 8.10",

      "I shall tell you briefly about that Supreme goal (viz., God, who is an embodiment of Truth, Knowledge and Bliss), which the knowers of the Veda term as the Indestructible, which striving recluses, free from passion, merge into, and desiring which the celibates practise Brahmacarya. 8.11",

      "Having controlled all the senses, and firmly holding the mind in the heart, and then drawing the life-breath to the head, and thus remaining steadfast in Yogic concentration on God 8.12",

      "he who leaves body and departs uttering the one Indestructible Brahma, OM, and dwelling on Me in My absolute aspect, reaches the supreme goal. 8.13",

      "Arjuna, whosoever always and constantly thinks of Me with undivided mind, to that Yogi ever absorbed in Me I am easily attainable. 8.14",

      "Great souls, who have attained the highest perfection, having come to Me, are no more subject to transitory rebirth, which is the abode of sorrow, and transient by nature. 8.15",

      "Arjuna, all the worlds from Brahmaloka (the heavenly realm of the Creator, Brahma) downwards are liable to birth and rebirth. But, O son of Kunti, on attaining Me there is no rebirth (For, while I am beyond Time, regions like Brahmaloka, being conditioned by time, are transitory). 8.16",

      "Those Yogis, who know from realization Brahma's day as covering a thousand Mahayugas, and so his night as extending to another thousand Mahayugas, know the reality about Time. 8.17",

      "All embodied beings emanate from the Unmanifest (i.e., Brahma's subtle body) at the coming of the cosmic day; at the cosmic nightfall they merge into the same subtle body of Brahma, known as the Unmanifest. 8.18",

      "Arjuna, this multitude of beings, being born again and again, is dissolved under compulsion of its nature at the coming of the cosmic night and rises again at the commencement of cosmic day. 8.19",

      "Far beyond even this unmanifest, there is yet another unmanifest Existence, that Supreme Divine Person, who does not perish even though all beings perish. 8.20",

      "The same unmanifest which has been spoken of as the Indestructible, is also called the supreme goal; that again is My supreme Abode, attaining which they return not to this mortal world. 8.21",

      "Arjuna, that eternal unmanifest supreme Puruṣa in whom all beings reside and by whom all this is pervaded, is attainable only through exclusive Devotion. 8.22",

      "Arjuna, I shall now tell you the time (path) departing when Yogis do not return, and also the time (path) departing when they do return. 8.23",

      "(Of the two paths) the one is that in which, are stationed the all-effulgent fire-god and the deities presiding over daylight, the bright fortnight, and the six months of the northward course of the sun respectively; proceeding along it after death, Yogīs who have known Brahma, being successively by the above gods, finally reach Brahma. 8.24",

      "The other path is that wherein are stationed the gods presiding over smoke, night, the dark fortnight, and the six months of the southward course of the sun; the Yogi (devoted to action with an interested motive) taking to this path after death is led by the above gods, one after another, and attaining the lustre of the moon (and enjoying the fruit of his meritorious deeds in heaven) returns to this mortal world. 8.25",

      "For these two paths of the world, the bright and the dark, are considered to be eternal. Proceeding by one of them, one reaches the supreme state from which there is no return; and proceeding by the other, one returns to the mortal world, i.e., becomes subject to birth and death once more. 8.26",

      "Knowing thus the secret of these two paths, O son of Kunti, no Yogī gets deluded. Therefore, Arjuna, at all times be steadfast in Yoga in the form of equanimity (i.e., strive constantly for My realization). 8.27",

      "The Yogi, realizing this profound truth, doubtless transcends all the rewards enumerated for the study of the Vedas as well as for performance of sacrifices, austerities and charities and attains the supreme and primal state. 8.28",

      "Thus, in the Upanişad sung by the Lord, the Science of Brahma, the scripture of Yoga, the dialogue between Śrī Kṛṣṇa and Arjuna, ends the eighth chapter entitled \"The Yoga of the Indestructible Brahma.\""
      ],
      "adhyaya-9" : [
      "Śrī Bhagavān said:\nTo you, who are devoid of the carping spirit, I shall now unfold the most secret knowledge of Nirguņa Brahma along with the knowledge of manifest Divinity, knowing which you shall be free from the evil of worldly existence. 9.1",

      "This knowledge (of both the Nirguņa and Saguņa aspects of Divinity) is a sovereign secret,supremely holy, most excellent, directly enjoyable, attended with virtue, very easy to practice and imperishable. 9.2",

      "Arjuna,people having no faith in this Dharma, failing to reach Me,continue to revolve in the path of the world of birth and death. 9.3",

      "The whole of this universe is permeated by Me as unmanifest Divinity, like ice by water and all beings dwell on the idea within Me. But, really speaking, I am not present in them. 9.4",

      "Nay, all those beings abide not in Me; but behold the wonderful power of My divine Yoga; though the Sustainer and Creator of beings, Myself in reality dwell not in those beings.  9.5",

      "Just as the extensive air, which is moving everywhere, (being born of ether) ever remains in ether, likewise, know that all beings, who have originated from My Saṅkalpa, abide in Me. 9.6",

      "Arjuna, during the Final Dissolution all beings enter My Prakṛti (the prime cause), and at the beginning of creation, I send them forth again.  9.7",

      "Wielding My nature I procreate again and again, according to their respective Karmas, all this multitude of beings subject to the sway of their own nature. 9.8",

      "Arjuna, those actions, however, do not bind Me, unattached as I am to such actions, and standing apart as it were. 9.9",

      "Arjuna, under My aegis, Nature brings forth the whole creation, consisting of both sentient and insentient beings; it is due to this cause that the wheel of ṣamsara is going round. 9.10",

      "Not Knowing My supreme nature, fools deride Me, the overlord of the entire creation, who have assumed the human form. That is to say, they take Me, who have appeared in human form through My 'Yogamaya' for deliverance of the world, as an ordinary mortal. 9.11",

      "Those bewildered persons with vain hopes, futile actions and fruitless knowledge, have embraced a fiendish, demoniacal and delusive nature. 9.12",

      "On the other hand, Arjuna, great souls who have adopted the divine nature, knowing Me as the prime source of all beings and the imperishable eternal, worship Me constantly with one pointedness of mind. 9.13",

      "Constantly chanting My names and glories and striving for My realization, and bowing again and again to Me, those devotees of firm resolve, ever united with me through meditation, worship Me with single-minded devotion. 9.14",

      "Others, who follow the path of Knowledge, betake themselves to Me through yajña of Knowledge, worshipping Me in My absolute, formless aspect as one with themselves; while still others worship Me in My Universal Form in many ways, taking Me to be diverse in manifold celestial forms. 9.15",

      "I am the Vedic ritual, I am the sacrifice, I am the offering to the departed; I am the herbage and food grains; I am the sacred mantra, I am the clarified butter, I am the sacred fire, and I am verily the act of offering oblations into the fire. 9.16",

      "I am the sustainer and ruler of this universe, its father, mother and grandfather, the one worth knowing, the purifier, the sacred syllable OM, and the three Vedas - Ṛig, Yajuṣ and ṣama. 9.17",

      "I am the supreme goal, sustainer, lord, witness, abode, refuge, well-wisher seeking no return, origin and end, resting-place, store-house to which all beings return at the time of universal destruction, and the imperishable seed. 9.18",

      "I radiate heat as the sun, and hold back as well as send forth showers, Arjuna. I am immortality as well as death; even so, I am being and also non-being. 9.19",

      "Those who perform action with some interested motive as laid down in these three Vedas and drink the sap of the Soma plant, and have thus been purged of sin, worshipping Me through sacrifices, seek access to heaven; attaining Indra's paradise as the result of their virtuous deeds, they enjoy the celestial pleasures of gods in heaven. 9.20",

      "Having enjoyed the extensive heaven-world, they return to this world of mortals on the stock of their merits being exhausted. Thus devoted to the ritual with interested motive, recommended by the three Vedas as the means of attaining heavenly bliss, and seeking worldly enjoyments, they repeatedly come and go (i.e., ascend to heaven by virtue of their merits and return to earth when their fruit has been enjoyed). 9.21",

      "The devotees, however, who loving no one else constantly think of Me, and worship Me in a disinterested spirit, to those ever united in thought with Me, I bring full security and personally attend to their needs. 9.22",

      "Arjuna, even those devotees who, endowed with faith, worship other gods (with some interested motive) worship Me alone, though with a mistaken approach. 9.23",

      "For, I am the enjoyer and also the lord of all sacrifices; but they who do not know Me in reality as the Supreme Deity, they fall i.e., return to life on earth. 9.24",

      "Those who are votaries of gods, go to gods, those who are votaries of manes, reach the manes; those who adore the spirits, reach the spirits and those who worship Me, come to Me alone. That is why My devotees are no longer subject to birth and death. 9.25",

      "Whosoever offers Me with love a leaf, a flower, a fruit or water, I, appear in person before that selfless devotee of sinless mind, and delightfully partake of that article offered by him with love. 9.26",

      "Arjuna, whatever you do, whatever you eat, whatever you offer as oblation to the sacred fire, whatever you bestow as a gift, whatever you do by way of penance, offer all that to Me. 9.27",

      "With your mind thus established in the Yoga of renunciation (offering of all actions to Me), you will be freed from the bondage of action in the form of good and evil results; thus freed from them, you will attain Me. 9.28",

      "I am equally present in all beings; there is none hateful or dear to Me. They, however, who devoutly worship Me abide in Me; and I too stand revealed to them. 9.29",

      "Even if the vilest sinner worships Me with exclusive devotion, he should be regarded a saint; for, he has rightly resolved. (He is positive in his belief that there is nothing like devoted worship of God). 9.30",

      "Speedily he becomes virtuous and attains abiding peace. Know it for certain, Arjuna, that My devotee never perishes. 9.31",

      "Arjuna, women, Vaiśyās (members of the trading and agriculturist classes), śūdrās (those belonging to the labour and artisan classes), as well as those of impious birth (such as the pariah), whoever they may be, taking refuge in Me, they too attain the supreme goal. 9.32",

      "How much more, then, if they be holy Brahmaņas and royal sages devoted to Me! Therefore, having obtained this joyless and transient human life, constantly worship Me. 9.33",

      "Fix your mind on Me, be devoted to Me, worship Me and make obeisance to Me; thus linking yourself with Me and entirely depending on Me, you shall come to Me. 9.34",

      "Thus, in the Upaniṣad sung by the Lord, the Science of Brahma, the scripture of Yoga, the dialogue between Śrī Kṛṣṇā and Arjuna, ends the ninth chapter entitled \"The Yoga of Sovereign Science and the Sovereign Secret.\""            
      ],
      "adhyaya-10" : [
      "Śrī Bhagavān said:\nArjuna, hear once again My supreme word, which I shall speak to you, who are so loving, out of solicitude for your welfare. 10.1",

      "Neither gods nor the great sages know the secret of My birth (i.e., My manifestation in human or other forms out of mere sport); for, I am the prime cause in all respects of gods as well as of the great seers. 10.2",

      "He who knows Me in reality as birthless and without beginning, and as the supreme Lord of the Universe, he, undeluded among men, is purged of all sins. 10.3",

      "Reason, right knowledge, unclouded understanding, forbearance, veracity, control over the senses and mind, joy and sorrow, evolution and dissolution, fear and fearlessness, 10.4",

      "non-violence, equanimity, contentment, austerity, charity, fame and obloquy-these diverse traits of creatures emanate from Me alone. 10.5",

      "The seven great seers, their four elders (Sanaka and others), and the fourteen Manus or progenitors of mankind (such as Svayambhuva and his successors), who were all devoted to Me, were born of My will; from them all these creatures in the world have descended. 10.6",

      "He who knows in reality this supreme divine glory and supernatural power of Mine, gets established in Me through unfaltering devotion; of this there is no doubt. 10.7",

      "I am the source of all creation and everything in the world moves because of Me; knowing thus, the wise, full of devotion, constantly worship Me. 10.8",

      "With their minds fixed on Me, and their lives surrendered to Me, conversing and enlightening one another about My glories, My devotees ever remain contented and take delight in Me. 10.9",

      "On those ever united through meditation with Me and worshipping Me with love, I confer that Yoga of wisdom by which they come to Me. 10.10",

      "In order to bestow My compassion on them, I, dwelling in their hearts, dispel their darkness born of ignorance by the illuminating lamp of knowledge. 10.11",

      "Arjuna said: You are the transcendent Eternal, the supreme Abode and the greatest purifier; all the seers speak of You as the eternal divine Puruṣa, the primal Deity, unborn and all-pervading. 10.12",

      "Likewise speak the celestial sage Nārada, the sages Asita and Devala and the great sage vyāsa; and Yourself too proclaim this to me. 10.13",

      "Kṛṣṇā, I believe as true all that You tell me. Lord, neither demons nor gods are aware of Your manifestations. 10.14",

      "O Creator of beings, O Ruler of creatures, god of gods, the Lord of the universe, O supreme Puruṣa, You alone know what You are by Yourself. 10.15",

      "Therefore, You alone can describe in full Your divine glories, whereby You pervade all these worlds. 10.16",

      "O Master of Yoga, through what process of continuous meditation shall I know You? And in what particular forms, O Lord, are You to be meditated upon by me? 10.17",

      "Kṛṣṇā, tell me once more in detail Your power of Yoga and Your glory; for I know no satiety in hearing Your nectar-like words. 10.18",

      "Śrī Bhagavān said: Arjuna, now I shall tell you My prominent divine glories; for there is no limit to My manifestations. 10.19",

      "Arjuna, I am the universal Self seated in the hearts of all beings; so, I alone am the beginning, the middle and also the end of all beings. 10.20",

      "I am Visņu among the twelve sons of Aditi, and the radiant sun among the luminaries; I am the glow of the Maruts (the forty-nine wind-gods), and the moon, the lord of the stars. 10.21",

      "Among the Vedas, I am the ṣamaveda; among the gods, I am Indra. Among the organs of perception i.e., senses, I am the mind; and I am the consciousness (life-energy) in living beings. 10.22",

      "Among the eleven Rudrās (gods of destruction), I am Siva; and among the yakṣas and rakṣasās, I am the lord of riches (Kubera). Among the eight Vasus, I am the god of fire; and among the mountains, I am the Meru. 10.23",

      "Among the priests, Arjuna, know Me to be their chief, Bṛhaspati. Among warrior-chiefs, I am Skanda (the generalissimo of the gods); and among the reservoirs of water, I am the ocean. 10.24",

      "Among the great seers, I am Bhrgu; among words, I am the sacred syllable OM; among sacrifices, I am the sacrifice of Japa (muttering of sacred formulas); and among the immovables, the Himalaya. 10.25",

      "Among all trees, I am Aśvattha (the holy fig tree); among the celestial sages, Nārada; among the Gandharvas (celestial musicians), Citraratha, and among the Siddhas, I am the sage Kapila. 10.26",

      "Among horses, know me to be the celestial horse Uccaiḥśrava, begotten of the churning of the ocean along with nectar; among mighty elephants, Airāvata (Indra's elephant); and among men, the king. 10.27",

      "Among weapons, I am the thunderbolt; among cows, I am the celestial cow Kāmadhenu (the cow of plenty). I am Kandarpa, the generative passion which leads to procreation (as enjoined by the scriptures); among serpents, I am Vāsuki. 10.28",

      "Among Nāgās (a special class of serpents), I am the serpent-god Ananta; and I am Varuņa, the lord of aquatic creatures. Among the manes, I am Aryama (the head of the Pitṝs); and among rulers, I am Yama (the god of death). 10.29",

      "Among the Daityas, I am the great devotee Prahlāda; and of calculators, I am Time; among quadrupeds, I am the lion; and among birds, I am Garuda. 10.30",

      "Among purifiers, I am the wind; among wielders of arms, I am Śrī Rāma. Among fishes, I am the alligator; and among streams, I am the Ganges. 10.31",

      "Arjuna, I am the beginning, the middle and the end of all creations. Of all knowledge, I am the knowledge of the soul (metaphysics); among disputants, I am the right type of discussion. 10.32",

      "Among the sounds represented by the various letters, I am 'A' (the sound represented by the first letter of the alphabet); of the different kinds of compounds in grammar, I am the copulative compound. I am verily the endless Time (the devourer of Time, God); I am the sustainer of all, having My face on all sides. 10.33",

      "I am the all-destroying Death that annihilates all, and the origin of all that are to be born. Of feminities, I am Kīrti, Śrī, Vāk, Smṛti, Medhā, Dhṛti and Kṣama (the goddesses presiding over glory, prosperity, speech, memory, intelligence, fortitude and forbearance, respectively). 10.34",

      "Likewise, among the Srutis that can be sung, I am the variety known as Bṛhatṣama; while among the Vedic hymns, I am the hymn known as Gāyatrī. Again, among the twelve months of the Hindu calendar, I am the month known as 'Margasirṣa' (corresponding approximately to November-December); and among the six seasons (successively appearing in India in the course of a year), I am the spring season. 10.35",

      "I am gambling among deceitful practices, and the glory of the glorious. I am the victory of the victorious, the resolve of the resolute, the goodness of the good. 10.36",

      "I am Kṛṣṇā among the Vṛṣṇīs, Arjuna among the sons of Paņḍu, vyāsa among the sages, and the sage Sukracarya among the wise. 10.37",

      "I am the subduing power of rulers; I am righteousness in those who seek to conquer. Of things to be kept secret, I am the custodian in the form of reticence; and I am the wisdom of the wise. 10.38",

      "Arjuna, I am even that, which is the seed of all life. For there is no creature, moving or unmoving, which can exist without Me. 10.39",

      "Arjuna, there is no limit to My divine manifestations. This is only a brief description by Me of the extent of My glory. 10.40",

      "Every such being as is glorious, brilliant and powerful, know that to be a part manifestation of My glory. 10.41",

      "Or, what will you gain by knowing all this in detail, Arjuna? Suffice it to say that I hold this entire universe by a fraction of My Yogic Power. 10.42",

      "Thus, in the Upaniṣad sung by the Lord, the Science of Brahma, the scripture of Yoga, the dialogue between Śrī Kṛṣṇā and Arjuna, ends the tenth chapter entitled \"The Yoga of Divine Glories.\""
      ],
      "adhyaya-11" : [
      "Arjuna said :\nThanks to the most profound words of spiritual wisdom that You have spoken out of kindness to me, this delusion of mine has entirely disappeared. 11.1",

      "For, Kṛṣņa, I have heard from You in detail an account of the evolution and dissolution of beings, and also Your immortal glory. 11.2",

      "Lord, You are precisely what You declare Yourself to be. But I long to see Your divine form possessed of wisdom, glory, energy, strength, valour and effulgence, O Puruṣottama, the Supreme Being! 11.3",

      "Kṛṣņa, if You think that it can be seen by me, then, O Lord of Yoga, reveal to me Your imperishable form. 11.4",

      "Śrī Bhagavān said:\nArjuna, behold My manifold, multifarious divine forms of various colours and shapes, in their hundreds and thousands. 11.5",

      "Behold in Me, Arjuna, the twelve sons of Aditi, the eight Vasus, the eleven Rudrās (gods of destruction), the two Aśvinīkumāras (the twin-born physicians of gods) and the forty-nine Maruts (wind-gods), and witness many more wonderful forms never seen before. 11.6",

      "Arjuna, behold as concentrated within this body of Mine the entire creation consisting of both the moving and the unmoving beings, and whatever else you desire to see. 11.7",

      "But surely you cannot see Me with these physical eyes of yours; therefore, I vouchsafe to you the divine eye. With this you behold My divine power of Yoga. 11.8",

      "Sañjaya said:\nMy lord! having spoken thus, Śrī Kṛṣņa, the supreme Master of Yoga, forthwith revealed to Arjuna His supremely glorious divine Form. 11.9",

      "Arjuna saw the supreme Deity possessing many mouths and eyes, presenting many a wonderful sight, decked with many divine ornaments, wielding many uplifted divine weapons, 11.10",

      "wearing divine garlands and vestments, anointed all over with divine fragrances, full of all wonders, infinite and having faces on all sides. 11.11",

      "If there be the effulgence of a thousand suns bursting forth all at once in the heavens, even that would hardly approach the splendour of the mighty Lord. 11.12",

      "Concentrated at one place in the person of that supreme Deity, Arjuna then beheld the whole universe with its manifold divisions. 11.13",

      "Then Arjuna, full of wonder and with the hair standing on end, reverentially bowed his head to the divine Lord, and with folded hands addressed Him thus. 11.14",

      "Arjuna said:\nLord, I behold within Your body all gods and hosts of different beings, Brahmā throned on his lotus-seat, Śiva and all Ṛṣis and celestial serpents. 11.15",

      "O Lord of the universe, I see You endowed with numerous arms, bellies, mouths, and eyes and having innumerable forms extended on all sides. O Lord, manifested in the form of the universe, I see neither Your beginning nor middle, nor even Your end. 11.16",

      "I see you endowed with diadem, club and discus, a mass of splendour glowing all round, having the brilliance of a blazing fire and the sun, hard to gaze at and immeasurable on all sides. 11.17",

      "You are the supreme indestructible worthy of being known; you are the ultimate refuge of this universe. You are, again, the protector of the ageless Dharma; I consider You to be the eternal imperishable Being. 11.18",

      "I see You without beginning, middle or end, possessing unlimited prowess and endowed with numberless arms, having the moon and the sun for Your eyes, and blazing fire for Your mouth, and scorching this universe by Your radiance. 11.19",

      "Yonder space between heaven and earth and all the quarters are entirely filled by You alone. Seeing this transcendent, dreadful Form of Yours, O Soul of the universe, all the three worlds feel greatly perturbed. 11.20",

      "Yonder hosts of gods are entering You; some with palms joined out of fear are recounting Your names and glories. Multitudes of Maharṣis and Siddhas, saying 'Let there be peace', are extolling You by means of excellent hymns. 11.21",

      "The eleven Rudrās, twelve Ādityas and eight Vasus, the Sādhyas and Viśvedevas, the two Aśvinīkumāras and forty-nine Maruts, as well as the manes and multitudes of Gandharvas, Yakṣas, Asuras and Siddhas, all these gaze upon You in amazement.  11.22",

      "Lord, seeing this stupendous and dreadful Form of Yours, possessing numerous mouths and eyes, many arms, thighs and feet, many bellies and many teeth, the worlds are terror-struck; so am I. 11.23",

      "Lord, seeing Your Form reaching the heavens, effulgent multi-coloured, having its mouth wide open and possessing large flaming eyes, I, with my inmost self frightened, have lost self-control and find no peace. 11.24",

      "Seeing Your faces frightful on account of teeth therein and blazing like the fire at the time of universal destruction, I am utterly bewildered and find no happiness; therefore, have mercy on me, O Lord of celestials! O Abode of the universe! 11.25",

      "All those sons of Dhṛtarāṣṭra with hosts of kings are entering You. Bhīṣma, Droņa and yonder Karņa, with the principal warriors on our side as well, 11.26",

      "are rushing headlong into Your fearful mouths looking all the more terrible on account of the teeth; some are seen stuck up in the gaps between Your teeth with their heads crushed. 11.27",

      "As the myriad streams of rivers rush towards the sea alone, so do those warriors of the mortal world enter Your flaming mouths. 11.28",

      "As moths rush with great speed into the blazing fire for extinction out of 'Moha', even so, all these people are with great rapidity entering Your mouths to meet their doom. 11.29",

      "Devouring all the worlds through Your flaming mouths and licking them on all sides, O Lord Viṣņu! Your fiery rays fill the whole universe with their fierce radiance and are scorching it. 11.30",

      "Tell me who You are with a form so terrible? My obeisance to You, O best of gods; be kind to me. I wish to know You, the Primal Being, in particular; for I know not what you intend to do.  11.31",

      "Śrī Bhagavān said:\nI am mighty Kāla (the eternal Time-spirit), the destroyer of the worlds. I am out to exterminate these people. Even without you all those warriors, arrayed in the enemy's camp, shall die. 11.32",

      "Therefore, do you arise and win glory; conquering foes, enjoy the affluent kingdom. These warriors stand already slain by Me; be you only an instrument, Arjuna. 11.33",

      "Do kill Droņa and Bhīṣma and Jayadratha and Karņa and other brave warriors, who already stand killed by Me; fear not. Fight and you will surely conquer the enemies in the war. 11.34",

      "Sañjaya said:\nHearing these words of Bhagavān Keśava, Arjuna tremblingly bowed to Him with joined palms, and bowing again in extreme terror spoke to Śrī Kṛṣņa in faltering accents.  11.35",

      "Arjuna said:\nLord, well it is, the universe exults and is filled with love by chanting Your names, virtues and glory; terrified Rākṣasas are fleeing in all directions, and all the hosts of Siddhas are bowing to You. 11.36",

      "O Great soul, why should they not bow to you, who are the progenitor of Brahmā himself and the greatest of the great? O infinite, O Lord of celestials, O Abode of the universe, You are that which is existent (Sat), that which is non-existent (Asat) and also that which is beyond both, viz., the indestructible Brahma. 11.37",

      "You are the primal Deity, the most ancient Person; You are the ultimate resort of this universe. You are both the knower and the knowable, and the highest abode. It is You who pervade the universe, O one assuming endless forms. 11.38",

      "You are Vāyu (the wind-god), Yama (the god of death), Agni (the god of fire), Varuņa (the god of water), the moon-god, Brahmā (the Lord of creation), nay, the father of Brahmā himself. Hail, hail to You a thousand times; salutations, repeated salutations to You once again. 11.39",

      "O Lord of infinite prowess, my salutations to You from the front and from behind. O soul of all, my obeisance to You from all sides indeed. You, who possess infinite might, pervade all; therefore, You are all. 11.40",

      "The way in which I have importunately called You, either through intimacy or thoughtlessly, \"Ho Kṛṣņa! Ho Yādava! Ho Comrade!\" and so on, unaware of the greatness of Yours, and thinking You only to be a friend, 11.41",

      "and the way in which O Acyuta! the Infallible! You have been slighted by me in jest, while at play, reposing, sitting or at meals, either alone or even in the presence of others-for all that, O Immeasurable Lord, I crave forgiveness from You. 11.42",

      "You are the Father of this moving and unmoving creation, nay, the greatest teacher worthy of adoration. O Lord of incomparable might, in all the three worlds there is none else even equal to You; how, then, can anyone be greater than You? 11.43",

      "Therefore, Lord, prostrating my body at Your feet and bowing low I seek to propitiate You, the ruler of all and worthy of all praise. It behoves You to bear with me even as a father bears with his son, a friend with his friend and a husband with his beloved spouse. 11.44",

      "Having seen Your wondrous form, which was never seen before, I feel transported with joy; at the same time my mind is tormented by fear. Pray! reveal to me that divine form, the form of Viṣņu with four-arms; O Lord of celestials, O Abode of the universe, be gracious. 11.45",

      "I wish to see You adorned in the same way with a diadem on the head, and holding a mace and a discus in two of Your hands. O Lord with a thousand arms, O Universal Being, appear again in the same four-armed Form. 11.46",

      "concentrated at one place in the person of that supreme Deity, Arjuna then beheld the whole universe with its manifold divisions.  11.47",

      "Arjuna, in this mortal world I cannot be seen in this Form by anyone other than you, either through the study of the Vedas or by rituals, or, again, through gifts, actions or austere penances. 11.48",

      "Seeing such a dreadful Form of Mine as this, do not be perturbed or perplexed; with a fearless and tranquil mind, behold once again the same four-armed Form of Mine bearing the conch, discus, mace and lotus. 11.49",

      "Sañjaya said:\nHaving spoken thus to Arjuna, Bhagavān Vāsudeva again revealed to him His own four-armed Form; and then, assuming a genial form, the high-souled Śrī Kṛṣņa consoled the frightened Arjuna. 11.50",

      "Arjuna said:\nKṛṣņa, seeing this gentle human form of Yours I have regained my composure and am my ownself again. 11.51",

      "Śrī Bhagavān said:\nThis form of Mine (with four-arms) which you have just seen, is exceedingly difficult to behold. Even the gods are always eager to see this form.  11.52",

      "Neither by study of the Vedas, nor by penance, nor again by charity, nor even by rituals can I be seen in this form (with four-arms) as you have seen Me. 11.53",

      "Through single-minded devotion, however, I can be seen in this form (with four-arms), nay, known in essence and even entered into, O valiant Arjuna.  11.54",

      "Arjuna, he who performs all his duties for My sake, depends on Me, is devoted to Me, has no attachment, and is free from malice towards all beings, reaches Me. 11.55",

      "Thus, in the Upaniṣad sung by the Lord, the Science of Brahma, the scripture of Yoga, the dialogue between Śrī Kṛṣņa and Arjuna, ends the eleventh chapter entitled \"The Yoga of the Vision of the Universal Form.\""
      ],
      "adhyaya-12" : [
      "Arjuna said:\nThe devotees exclusively and constantly devoted to You in the manner stated just earlier, adore You as possessed of form and attributes, and those who adore as the supreme Reality only the indestructible unmanifest Brahma (who is Truth, Knowledge and Bliss solidified)- of these two types of worshippers who are the best knowers of Yoga? 12.1",
      
      "Śrī Bhagavān said:\nI consider them to be the best Yogīs, who endowed with supreme faith, and ever united through meditation with Me, worship Me with their mind centred on Me. 12.2",

      "Those, however, who fully controlling all their senses and even-minded towards all, and devoted to the welfare of all beings, constantly adore as their very self the unthinkable, 12.3",

      "omnipresent, indestructible, indefinable, eternal, immovable, unmanifest and changeless Brahma, they too come to Me. 12.4",

      "Of course, the strain is greater for those who have their mind attached to the Unmanifest, as attunement with the Unmanifest is attained with difficulty by the body-conscious people. 12.5",

      "On the other hand, those depending exclusively on Me, and surrendering all actions to Me, worship Me (God with attributes), constantly meditating on Me 12.6",

      "with single-minded devotion, them, Arjuna, I speedily deliver from the ocean of birth and death, their mind being fixed on Me. 12.7",

      "Therefore, fix your mind on Me, and establish your intellect in Me alone; thereafter you will abide solely in Me. There is no doubt about it. 12.8",

      "If you cannot steadily fix the mind on Me, Arjuna, then seek to attain Me through the Yoga of practice. 12.9",

      "If you are unequal even to the pursuit of such practice, be intent to work for Me; you shall attain perfection (in the form of My realization) even by performing actions for My sake. 12.10",

      "If, taking recourse to the Yoga of My realization, you are unable even to do this, then, subduing your mind and intellect etc., relinquish the fruit of all actions. 12.11",

      "Knowledge is better than practice without discernment, meditation on God is superior to knowledge, and renunciation of the fruit of actions is even superior to meditation; for, peace immediately follows renunciation. 12.12",

      "He who is free from malice towards all beings, friendly and compassionate, and free from the feelings of 'I' and 'mine', balanced in joy and sorrow, forgiving by nature, 12.13",

      "ever-contented and mentally united with Me, nay, who has subdued his mind, senses and body, has a firm resolve, and has surrendered his mind and reason to Me-that devotee of Mine is dear to Me. 12.14",

      "He who is not a source of annoyance to his fellow-creatures, and who in his turn does not feel vexed with his fellow-creatures, and who is free from delight and envy, perturbation and fear, is dear to Me. 12.15",

      "He who expects nothing, who is both internally and externally pure, is wise and impartial and has risen above all distractions, and who renounces the sense of doership in all undertakings-such a devotee of Mine is dear to Me. 12.16",

      "He who neither rejoices nor hates, nor grieves, nor desires, and who renounces both good and evil actions and is full of devotion, is dear to Me. 12.17",

      "He who deals equally with friend and foe, and is the same in honour and ignominy, who is alike in heat and cold, pleasure and pain and other contrary experiences, and is free from attachment, 12.18",

      "he who takes praise and reproach alike, and is given to contemplation and is contented with any means of subsistence available, entertaining no sense of ownership and attachment in respect of his dwelling-place and is full of devotion to Me, that person is dear to Me. 12.19",

      "Those devotees, however, who partake in a disinterested way of this nectar of pious wisdom set forth above, endowed with faith and solely devoted to Me, they are extremely dear to Me. 12.20",

      "Thus, in the Upaniṣad sung by the Lord, the Science of Brahma, the scripture of Yoga, the dialogue between Śrī Kṛṣņa and Arjuna, ends the twelfth chapter entitled \"The Yoga of Devotion.\""
      ],
      "adhyaya-13" : [
      "Śrī Bhagavān said:\nThis body Arjuna, is termed as the Field (kṣetra) and he knows it, is called the knower of the field (kṣetrajna) by the sages discerning the truth about both. 13.1",

      "Know Myself to be the kṣetrajna (individual soul) in all the kṣetras (fields), Arjuna. And it is the knowledge of the field (kṣetra), and knower (kṣetrajna), (ie. of Matter with its evolutes and the Spirit) which I consider as true knowledge. 13.2",

      "What that Field (kṣetra) is and what is its nature, what are its modifications, and from what causes what effects have arisen, and also who its knower (kṣetrajna) is, and what is His glory - hear all this from Me in brief. 13.3",

      "The truth, about the kṣetra and kṣetrajna, has been expounded by the seers in manifold ways; again it has been stated separately in different Vedic chants and also in the conclusive and reasoned texts of the Brahmasūtras. 13.4",

      "The five elements, the ego, the intellect, the unmanifest (Primordial Matter), the ten organs of perception and action, the mind and five objects of sense ( sound, touch, colour, taste and smell. 13.5",

      "Also desire, aversion, pleasure, pain, the physical body consciousness (life-breath), firmness: these comprise the kṣetra, with its modifications, described briefly 13.6",

      "Absence of pride, freedom from hypocrisy, non-violence, forgiveness, uprightness of speech and mind etc, devout service of the perceptor, internal and external purity, steadfastness of mind and control of body, mind and the senses. 13.7",

      "Dispassion towards the objects of enjoyment of this world and the next, and also absence of egotism; constant perception of evil and pain and evils inherent in birth, death, old age and disease. 13.8",

      "Absence of attachment and the sense of mineness in respect of son, wife, home etc., and constant equipoise of mind both in favourable and unfavourable circumstances. 13.9",

      "Unflinching devotion in Me through exclusive attachment, living in secluded and holy places, and finding no delight in the company of worldly people; 13.10",

      "Constancy, in Self-knowledge and seeing God everywhere as the object of true knowledge-all this is declared to be knowledge, and what is contrary to it, is called ignorance. 13.11",

      "I shall speak to you at length about which ought to be known, and knowing which one attains Supreme Bliss. That supreme Brahma, who is the lord of the (two) beginningless entities - ( Prakṛti and Jiva) is said to be neither Sat (being) nor Asat (non-being) 13.12",

      "It has hands and feet on all sides, eyes, head and mouth in all directions, and ears all around; for it stands pervading all in the universe. 13.13",

      "Through perceiving all sense objects, it is really speaking devoid of all senses. Nay, though unattached, It is the sustainer of all nonetheless; and though attributeless, It is the enjoyer of Guṇas, the three modes of Prakṛti. 13.14",

      "He exists, without and within, all beings and constitutes the moving and the unmoving creation as well. And by reason of its subtlety, it is incomprehensible. It is close at hand and stands afar, too. 13.15",

      "Though integral like space in its undivided aspect, it appears divided as it were, in all animate and all inanimate beings. And the Godhead, which is the only object worth knowing, is the sustainer of beings (as Viṣṇu), the destroyer (as Rudra) and the creator of all (as Brahma). 13.16",

      "That supreme Brahma is said to be the light of all lights, and entirely beyond Maya. That Godhead is knowledge itself, worth knowing and worth attaining through real wisdom, and is particularly abiding in the hearts of all. 13.17",

      "Thus the truth of the kṣetra (body) and knowledge, as well as of the object worth knowing, ie., God, has been briefly described; and knowing this in reality, My devotee attains to My being.  13.18",

      "Prakṛti (matter) and Puruṣa (Spirit), know both these as beginningless. And know all modifications such as likes and dislikes etc., and all objects constituted of the three Guṇas as born of Prakṛti. 13.19",

      "Prakṛti is said to be responsible for bringing forth the evolutes and the instruments; while the individual soul is declared to be responsible for the experience of joys and sorrows.  13.20",

      "Only the Puruṣa in association with Prakṛti experiences objects of the nature of the three Guṇas evolved from Prakṛti and it is attachment with these Guṇas that is responsible for the birth of this soul in good and evil wombs. 13.21",

      "The Spirit dwelling in this body, is really the same as the Supreme. He has been spoken of as the Witness, the true Guide, the Sustainer of all, the Experiencer (as the embodied soul), the Overlord and the Absolute as well. 13.22",

      "He who thus knows the Puruṣa (Spirit) and Prakṛti (Nature) together with the Guṇas-even though performing his duties in everyway, is not born again. 13.23",

      "Some by meditation behold the supreme Spirit in the heart with the help of their refined and sharp intellect; others realize it through the discipline of Knowledge, and still others, through the discipline of Action, i.e., Karmayoga. 13.24",

      "Other dull-witted persons, however, not knowing thus, worship even as they have heard from others i.e., the knowers of truth; and even those who are thus devoted to what they have heard, are able to cross the ocean of mundane existence in the shape of death. 13.25",

      "Arjuna, whatsoever being, the moving or unmoving, is born, know it as emanated through the union of kṣetra (Matter) and the kṣetrajna (Spirit). 13.26",

      "He alone truly sees, who sees the supreme Lord as imperishable and abiding equally in all perishable beings, both animate and inanimate. 13.27",

      "For, by seeing the Supreme Lord equally present in all, he does not kill the Self by himself, and there by attains the supreme state. 13.28",

      "He who sees that all actions are performed in every way by nature (Prakṛti) and the Self as the non-doer, he alone verily sees. 13.29",

      "The moment man perceives the diversified existence of beings as rooted in the one supreme Spirit, and the spreading forth of all beings from the same, that very moment he attains Brahma (who is Truth, Consciousness and Bliss solidified). 13.30",

      "Arjuna, being without beginning and without attributes, this indestructible supreme Spirit, though dwelling in the body, in fact does nothing, nor gets tainted. 13.31",

      "As the all-pervading ether is not contaminated by reason of its subtlety, though permeating the body, the Self is not affected by the attributes of the body due to Its attributeless character. 13.32",

      "Arjuna, as the one sun illumines this entire universe, so the one Atṃā (Spirit) illumines the whole kṣetra (Field). 13.33",

      "Those who thus perceive with the eye of wisdom, the difference between the kṣetra and kṣetrajna, and the phenomenon of liberation from Prakṛti with her evolutes, reach the supreme eternal Spirit. 13.34",

      "Thus, in the Upanisad sung by the Lord, the Science of Brahma, the scripture of Yoga, the dialogue between Śrī Kṛṣṇā and Arjuna, ends the thirteenth chapter entitled \"The Yoga of discrimination between the Field and the Knower of the Field.\""
      ],
      "adhyaya-14" : [
      "Śrī Bhagavān said:\nI shall expound once more the supreme knowledge, the best of all knowledge, acquiring which all sages have attained highest perfection, being liberated from this mundane existence. 14.1",

      "Those who, by practising this knowledge have entered into My being, are not born again at the cosmic dawn, nor feel disturbed even during the cosmic dissolution (Pralaya). 14.2",

      "My primordial Nature, known as the great Brahma, is the womb of all creatures; in that womb I place the seed of all life. The creation of all beings follows from that union of Matter and Spirit, O Arjuna. 14.3",

      "Of all embodied beings that appear in all the species of various kinds, Arjuna, Prakṛti or Nature is the conceiving Mother, while I am the seed giving Father. 14.4",

      "Sattva, Rajas and Tamas-these three Guņas born of Nature tie down the imperishable soul to the body, Arjuna. 14.5",

      "Of these, Sattva being immaculate, is illuminating and flawless, Arjuna; it binds through attachment to happiness and knowledge. 14.6",

      "Arjuna, know the quality of Rajas, which is of the nature of passion, as born of desire and attachment. It binds the soul through attachment to actions and their fruit. 14.7",

      "And know Tamas, the deluder of all those who look upon the body as their own self, as born of ignorance. It binds the soul through error, sleep and sloth, Arjuna. 14.8",

      "Sattva draws one to joy and Rajas to action; while Tamas, clouding wisdom, impels one to error, sleep and sloth Arjuna. 14.9",

      "Overpowering Rajas and Tamas, Arjuna, Sattva prevails; overpowering Sattva and Tamas, Rajas prevails; even so, overpowering Sattva and Rajas, Tamas prevails. 14.10",

      "When light and discernment dawn in this body, as well as in the mind and senses, then one should know that Sattva is predominant. 14.11",

      "With the preponderance of Rajas, Arjuna, greed, activity, undertaking of action with an interested motive, restlessness and a thirst for enjoyment make their appearance. 14.12",

      "With the growth of Tamas, Arjuna, obtuseness of the mind and senses, disinclination to perform one's obligatory duties, frivolity and stupor-all these appear. 14.13",

      "When a man dies during the preponderance of Sattva, he obtains the stainless ethereal worlds (heaven etc.,) attained by men of noble deeds. 14.14",

      "Dying when Rajas predominates, he is born among those attached to action; even so, the man who has expired during the preponderance of Tamas is reborn in the species of the deluded creatures such as insects and beasts etc. 14.15",

      "The reward of a righteous act, they say, is  Sāttvika i.e., faultless in the form of joy, wisdom and dispassion etc., sorrow is declared to be the fruit of a Rājasikā act and ignorance, the fruit of a Tāmasika act. 14.16",

      "Wisdom follows from Sattva, and greed, undoubtedly, from Rajas; likewise, obstinate error, stupor and also ignorance follow from Tamas. 14.17",

      "Those who abide in the quality of Sattva wend their way upwards; while those of a Rājasikā disposition stay in the middle. And those of a Tāmasika temperament, enveloped as they are in the effects of Tamoguņa, sink down. 14.18",

      "When the discerning person sees no one as doer other than the three Guṇas, and realizes Me, the supreme Spirit standing entirely beyond these Guṇas, he enters into My being. 14.19",

      "Having transcended the aforesaid three Guṇas, which have caused the body, and freed from birth, death, old age and all kinds of sorrow, the embodied soul attains supreme bliss. 14.20",

      "Arjuna said:\nWhat are the marks of him who has risen above the three Guṇas, and what is his conduct? And how, Lord, does he rise above the three Guṇas? 14.21",

      "Śrī Bhagavān said:\nArjuna, he who abhors not illumination (which is born of Sattva) and activity (which is born of Rajas) and even stupor (which is born of Tamas), when prevalent, nor longs for them when they have ceased.  14.22",

      "He who, sitting like a witness, is not disturbed by the Guṇas, and who, knowing that the Guṇas alone move among the Guṇas, remains established in identity with God, and never falls off from that state. 14.23",

      "He who is ever established in the Self, takes pain and pleasure alike, regards a clod of earth, a stone and a piece of gold as equal in value, is possessed of wisdom, accepts the pleasant as well as the unpleasant in the same spirit, and views censure and praise alike. 14.24",

      "He who is equipoised in honour or ignominy, is alike towards a friend or an enemy, and has renounced the sense of doership in all undertakings, is said to have risen above the three Guṇas. 14.25",

      "He too who, constantly worships Me through the Yoga of exclusive devotion-transcending these three Guṇas, he becomes eligible for attaining Brahma. 14.26",

      "For, I am the substratum of the imperishable Brahma, of immortality, of the eternal Dharma and of unending immutable bliss. 14.27",

      "Thus, in the Upaniṣad sung by the Lord, the Science of Brahma, the scripture of Yoga, the dialogue between Śrī Kṛṣṇā and Arjuna, ends the fourteenth chapter entitled \"The Yoga of Division of three Guṇas.\""
      ],
      "adhyaya-15" : [
      "Srī Bhagavān said:\nHe who knows the Pīpala tree ( in the form of creation); which is said to be imperishable, with its roots in the Primeval being (God), whose branch is represented by Brahmā (the Creator), and whose leaves are the Vedas, is the knower of the purport of Vedas. 15.1",

      "Fed by the three Guṇas and having sense-objects for their tender leaves, the branches of the aforesaid tree (in the shape of different orders of creation) extend  both upwards and downwards; and it's roots which bind the soul according to its actions in the human body, are spread in all regions, higher as well as lower. 15.2",

      "The nature of this tree of creation, does not on mature thought, turn out what it is represented to be; for it has neither beginning nor end, nor even stability. Therefore cutting down this Pīpala tree, which is most firmly rooted, with the formidable axe of dispassion 15.3",

      "Thereafter a man should diligently seek for that supreme state, viz.,God, having attained which they return no more to this world; and having fully resolved that he stands dedicated to the Primeval Being (God Nārāyaṇa) Himself, for whom the flow of this beginningless creation has progressed, he should dwell and meditate on Him. 15.4",

      "They who are free from pride and delusion, who have conquered the evil of attachment, and are constantly abiding in God, whose cravings have altogether ceased and who are completely immune to all pairs of opposites going by the names of pleasure and pain, and are undeluded, attain that supreme immortal state. 15.5",

      "Neither the sun nor the moon nor fire can illumine that supreme self-effulgent state, attaining which they never return to this world; that is My supreme abode. 15.6",

      "The eternal Jīvātmā in this body is a fragment of My own Self; and it is that alone which draws around itself the  mind and the five senses, which abide inPrakṛti. 15.7",

      "Even as the wind wafts scents from their seat, so, too, the Jīvātmā, which is the controller of the body etc., taking the mind and the senses from the body which it leaves behind, forthwith migrates to the body which it acquires. 15.8",

      "It is while dwelling in the sense of hearing, touch, taste and smell, as well as in the mind, that this Jīvātmā enjoys the objects of senses. 15.9",

      "The ignorant know not the soul departing from, of dwelling in the body, or enjoying the objects of senses, i.e., even when it is connected with the three Guṇas; only those endowed with the eyes of wisdom are able to realize it. 15.10",

      "Striving Yogīs too are able to realise this Self enshrined in their heart. The ignorant, however, whose heart has not been purified, know not this Self in spite of their best endeavours. 15.11",

      "The radiance in the sun that illumines the entire world, and that which shines in the moon and that which shines in the fire too, know that radiance to be Mine. 15.12",

      "And permeating the soil, it is I who support all creatures by My vital energy, and becoming the sapful moon, I nourish all plants. 15.13",

      "Taking the form of fire, as Vaiśvānara, lodged in the body of all creatures and united with the Prāṇa (exhalation) and Apāna (inhalation) breaths, it is I who digest and assimilate the four kinds of food. 15.14",

      "It is I who remain seated in the heart of all creatures as the inner controller of all; and it is I who am the source of memory, knowledge and ratiocinative faculty. Again, I am the only object worth knowing through the Vedas; I alone am the origin of Vedānta and the knower of the Vedas too. 15.15",

      "The perishable and the imperishable too-- these are the two kinds of Puruṣas in this world. Of these, the bodies of all beings are spoken of as the perishable; while the Jīvātmā or the embodied soul is called imperishable. 15.16",

      "Yet, the Supreme Person is other than these, who, having encompassed all the three worlds, upholds and maintains all, and has been spoken of as the imperishable Lord and the Supreme Spirit. 15.17",

      "Since I am wholly beyond the perishable world of matter and Kṣetra, and am superior even to the imperishable soul, Jīvātmā, hence I am known as the Puruṣottama, the Supreme Self, in the world as well as in the Vedas 15.18",

      "Arjuna, the wise man who thus realizes Me as the Supreme Person--knowing all, he constantly worships Me (the all-pervading Lord) with his whole being. 15.19",

      "Arjuna, this most esoteric teaching has thus been imparted by Me; grasping it in essence man becomes wise and his mission in life is accomplished. 15.20",

      "Thus, in the Upaniṣad sung by the Lord, the Science of Brahma, the scripture of Yoga, the dialogue between Śrī Kṛṣṇa and Arjuna, ends the fifteenth chapter entitled \"The Yoga of the Supreme Person.\""
      ],
      "adhyaya-16" : [
      "Śrī Bhagavān said:\nAbsolute fearlessness, perfect purity of mind, constant fixity in the Yoga of meditation for the sake of self-realization, and even so, charity in its  Sāttvika form, control of the senses, worship of God and other deities as well as of one's elders including the performance of Agnihotra (pouring oblations into the sacred fire) and other sacred duties, study and teaching of the Vedas and other sacred books as well as the chanting of God's names and glories, suffering hardships for the discharge of one’s sacred obligations and uprightness of mind as well as of the body and senses. 16.1",

      "Non-violence in thought, word and deed, truthfulness and geniality of speech, absence of anger even on provocation, disclaiming doership in respect of actions, quietude or composure of mind, abstaining from slander, compassion towards all creatures, absence of attachment to the objects of senses even during their contact to the objects of senses, mildness, a sense of shame in transgressing the scriptures or social conventions, and abstaining from frivolous pursuits; 16.2",

      "Sublimity, forgiveness, fortitude, external purity, bearing enmity to none and absence of self-esteem, these are, O Arjuna, the marks of him, who is born with divine endowments. 16.3",

      "Hypocrisy, arrogance, pride and anger, sternness and ignorance too - these are the marks of him, who is born with demoniac properties.  16.4",

      "The divine endowment has been recognized as conducive to liberation, and the demoniac one as leading to bondage. Grieve not, Arjuna, for you are born with the divine propensities. 16.5",

      "There are only two types of men in this world, Arjuna, the one possessing a divine nature and the other possessing a demoniac disposition. Of these, the type possessing divine nature has been dealt with at length; now hear in detail from Me about the type possessing demoniac disposition. 16.6",

      "Men possessing a demoniac disposition know not what right activity is, and what right abstinence from activity is. Hence they possess neither purity (external or internal) nor good conduct nor even truthfulness. 16.7",

      "Men of demoniac disposition say this world is without any foundation, absolutely unreal and Godless, brought forth by mutual union of the male and female and hence conceived in lust; what else than this? 16.8",

      "Clinging to this false view, these slow - witted men of vile disposition and terrible deeds, are enemies of mankind, bent on destruction of the world. 16.9",

      "Cherishing insatiable desires and embracing false doctrines through ignorance, these men of impure conduct move in this world, full of hypocrisy, pride and arrogance. 16.10",

      "Giving themselves up to innumerable cares ending only with death, they remain devoted to the enjoyment of sensuous pleasures and are firm in their belief that this is the highest limit of joy. 16.11",

      "Held in bondage by hundreds of ties of expectation and wholly giving themselves up to lust and anger, they strive to amass by unfair means hoards of money and other objects for the enjoyment of sensuous pleasures. 16.12",

      "They say to themselves, “This much has been secured by me today and now I shall realize this ambition. So much wealth is already with me and yet again this shall be mine”.  16.13",

      "“That enemy has been slain by me and I shall kill those others too. I am the Lord of all, and enjoyer of all power. I am endowed with all occult powers, and am mighty and happy.” 16.14",

      "“I am wealthy and own a large family; who else is equal to me? I will sacrifice to Gods, will give alms, I will make merry.”  16.15",

      "Thus deluded by ignorance, enveloped in the mesh of delusion and addicted to the enjoyment of sensuous pleasures, their mind bewildered by numerous thoughts, these men of devilish disposition fall into the foulest hell. 16.16",

      "Intoxicated by wealth and honour, those self-conceited and haughty men perform sacrifices only in name for ostentation, without following the sacred rituals. 16.17",

      "Given over to egotism, brute force, arrogance, lust and anger etc., and calumniating others, they despise Me (the in-dweller), dwelling in their own bodies, as in those of others. 16.18",

      "Those haters, sinful, cruel and vilest among men, I cast again and again into demoniacal wombs in this world. 16.19",

      "Failing to reach Me, Arjuna, those stupid persons are born life after life in demoniac wombs and then verily sink down to a still lower plane. 16.20",

      "Desire, anger and greed - these triple gates of hell, bring about the downfall of the soul. Therefore, one must shun all these three. 16.21",

      "Freed from these three gates of hell, man works for his own salvation and thereby attains the Supreme goal ie. God. 16.22",

      "Discarding the injunctions of the scriptures, he who acts in an arbitrary way according to his own sweet will, such a person neither attains occult powers, nor the supreme goal, nor even happiness. 16.23",

      "Therefore, the scripture alone is your guide in determining what should be done and what should not be done. Knowing this, you ought to perform only such action as is ordained by the scriptures.  16.24",

      "Thus, in the Upanisad sung by the Lord, the Science of Brahma, the scripture of Yoga, the dialogue between Śrī Kṛṣṇā and Arjuna, ends the sixteenth chapter entitled \"The Yoga of Division between the Divine and the Demoniacal Properties.\""
      ],
      "adhyaya-17" : [
      "Arjuna said:\nThose, endowed with faith, who worship gods and others, disregarding the injunctions of scriptures, where do they stand, Kṛṣṇa,- in Sattva, Rajas or Tamas. 17.1",

      "Śrī Bhagavān said:\nThat untutored innate faith of men is of three kinds– Sāttvika, Rājasika and Tāmasika. Hear of it from Me. 17.2",

      "The faith of all men conforms to their mental disposition, Arjuna. Faith constitutes a man; whatever the nature of his faith, he is verily that. 17.3",

      "Men of Sāttvika disposition worship gods; those of Rājasika temperament worship demigods and demons; while others, who are men of Tāmasika disposition, worship the spirits of dead and ghosts. 17.4",

      "Men who practice severe penance of an arbitrary type, not sanctioned by scriptures, and who are full of hypocrisy and egotism are obsessed with desire, attachment and pride of power; 17.5",

      "And who emaciate the elements constituting their body as well as Me, the Supreme Spirit, dwelling in their heart- know those senseless people to have a demoniac disposition. 17.6",

      "Food also, which is agreeable to different men according to their innate disposition is of three kinds. and likewise sacrifice, penance and charity too are of three kinds each; hear their distinction as follows. 17.7",

      "Foods which promote longevity, intelligence, vigor, health, happiness and cheerfulness, and which are juicy, succulent, substantial and naturally agreeable, are liked by men of Sāttvika nature. 17.8",

      "Foods which are bitter, sour, salty, overhot, pungent, dry and burning, and which cause suffering, grief and sickness, are dear to the Rājasika type of men. 17.9",

      "Food which is ill-cooked or not fully ripe, insipid, putrid, stale and polluted, and which is impure too, is dear to men of a Tāmasika disposition. 17.10",

      "The sacrifice which is offered, as ordained by scriptural injunctions, by men who expect no return and who believe that such sacrifices must be performed, is Sāttvika in character. 17.11",

      "That sacrifice however, which is offered for the sake of mere show or even with an eye to its fruit, know it to be Rājasika, Arjuna. 17.12",

      "A sacrifice, which is not in conformity with scriptural injunctions, in which no food is offered, and no sacrificial fees are paid, which is without sacred chant of hymns and devoid of faith, is said to be Tāmasika. 17.13",

      "Worship of gods, the Brāhamaṇs, one's guru, elders and great soul, purity, straightforwardness, continence and non-violence -these are called penance of the body. 17.14",

      "Words which cause no annoyance to others and are truthful, agreeable and beneficial, as well as the study of Vedas and other  Śāstras and the practice of the chanting of Divine Name— this is known as penance of speech. 17.15",

      "Cheerfulness of mind, placidity, habit of contemplation on God, control of mind and perfect purity of inner feelings-all this is called austerity of the mind. 17.16",

      "This threefold penance performed with supreme faith by Yogīs expecting no return is called Sāttvika. 17.17",

      "The austerity which is performed for the sake of renown, honor or adoration, as well as for any other selfish gain, either in all sincerity or by way of ostentation, and yields an uncertain and momentary fruit, has been spoken of here as Rājasika. 17.18",

      "Penance which is resorted to out of foolish obstinacy and is accompanied with self-mortification or is intended to harm others, such penance has been declared as Tāmasika. 17.19",

      "A gift which is bestowed with a sense of duty on one from whom no return is expected, at appropriate time and place, and to a deserving person, that gift has been declared as Sāttvika. 17.20",

      "A gift which is bestowed in grudging spirit and with the object of getting a service in return or in the hope of obtaining a reward, is called Rājasika. 17.21",

      "A gift which is made without good grace and in disdainful spirit out of time and place and to undeserving persons, is said to be Tāmasika. 17.22",

      "OM, TAT, and SAT– this has been declared as the triple appellation of Brahma, who is Truth, Consciousness, and Bliss. By that were the Brāhmaṇas and the Vedas as well as sacrifices created at the cosmic dawn. 17.23",

      "Therefore, acts of sacrifice, charity and austerity as enjoined by sacred precepts are always commenced by noble persons, used to the recitation of Vedic chants, with the invocation of the divine name 'OM'. 17.24",

      "With the idea that all this belongs to God, who is denoted by the appellation 'TAT', acts of sacrifice and austerity as well as acts of charity of various kinds are performed by seekers of liberation, expecting no return for them. 17.25",

      "The name of God, 'SAT' is used in the sense of reality and goodness. And the word 'SAT' is also used in the sense of a praiseworthy and auspicious action, Arjuna. 17.26",

      "And steadfastness in sacrifice, austerity and charity is likewise spoken of as 'SAT' and action for the sake of God is verily termed as 'SAT'. 17.27",

      "An oblation which is offered, a gift given, an austerity practiced, and whatever good deed is performed, if it is without faith, it is termed as naught i.e., 'asat'; therefore, it is of no avail here or hereafter. 17.28",

      "Thus, in the Upaniṣad sung by the Lord, the Science of Brahma, the scripture of Yoga, the dialogue between Śrī Kṛṣṇa and Arjuna, ends the seventeenth chapter entitled \"The Yoga of the Division of  the Threefold Faith.\""            
      ],
      "adhyaya-18" : [
      "Arjuna said:\nO mighty-armed Śrī Kṛṣņa, O inner controller of all, O Slayer of Keśi, I wish to know severally the truth of Samnyāsa as also of Tyāga. 18.1",

      "Śrī Bhagavān said:\nSome sages understand Samnyāsa as the giving up of all actions motivated by desire; and the wise declare that Tyāga consists in relinquishing the fruit of all actions. 18.2",

      "Some wise men declare that all actions contain a measure of evil, and are, therefore, worth giving up; while others say that acts of sacrifice, charity and penance are not to be shunned. 18.3",

      "Of Samnyāsa and Tyāga, first hear My conclusion on the subject of renunciation (Tyāga), Arjuna; for renunciation, O tiger among men, has been declared to be of three kinds-Sāttvika, Rājasika and Tāmasika. 18.4",

      "Acts of sacrifice, charity and penance are not worth giving up; they must be performed. For sacrifice, charity and penance-all these are purifiers to the wise men. 18.5",

      "Hence these acts of sacrifice, charity and penance, and all other acts of duty too, must be performed without attachment and expectation of reward : this is My well-considered and supreme verdict, Arjuna. 18.6",

      "(Prohibited acts and those that are motivated by desire should, no doubt, be given up). But it is not advisable to abandon a prescribed duty. Such abandonment out of ignorance has been declared as Tāmasika. 18.7",

      "Should anyone give up his duties for fear of physical strain, thinking that all actions are verily painful-practising such Rājasika form of renunciation, he does not reap the fruit of renunciation. 18.8",

      "A prescribed duty which is performed simply because it has to be performed, giving up attachment and fruit, that alone has been recognized as the Sāttvika form of renunciation. 18.9",

      "He who has neither aversion for action which is leading to bondage (अकुशल) nor attachment to that which is conducive to blessedness (कुशल)- imbued with the quality of goodness, he has all his doubts resolved, is intelligent and a man of true renunciation. 18.10",

      "Since all actions cannot be given up in their entirety by anyone possessing a body, he alone who renounces the fruit of actions is called a man of renunciation. 18.11",

      "Agreeable, disagreeable and mixed-threefold, indeed, is the fruit that accrues after death from the actions of the unrenouncing. But there is none whatsoever for those who have renounced. 18.12",

      "In the branch of learning known as Sāṅkhya, which prescribes means for neutralizing all actions, the five factors have been mentioned as contributory to the accomplishment of all actions; know them all from Me, Arjuna. 18.13",

      "The following are the factors operating towards the accomplishment of actions, viz., the body and the doer, the organs of different kinds and the different functions of manifold kinds; and the fifth is Daiva, latencies of past actions. 18.14",

      "These five are the contributory causes of whatever actions, prescribed or prohibited, man performs with the mind, speech and body. 18.15",

      "Notwithstanding this, however, he who, having an impure mind, regards the absolute, taintless Self alone as the doer, that man of perverse understanding does not view aright. 18.16",

      "He whose mind is free from the sense of doership, and whose reason is not affected by worldly objects and activities, does not really kill, even having killed all these people, nor does any sin accrue to him. 18.17",

      "The Knower, knowledge and the object of knowledge-these three motivate action. Even so, the doer, the organs and activity-these are the three constituents of action. 18.18",

      "In the branch of knowledge dealing with the Guņas or modes of Prakṛti, knowledge and action as well as the doer have been declared to be of three kinds according to the Guņa which predominates in each; hear them too duly from Me. 18.19",

      "That by which man perceives one imperishable divine existence as undivided and equally present in all individual beings, know that knowledge to be Sāttvika. 18.20",

      "The knowledge by which man cognizes many existences of various kinds, as apart from one another, in all beings, know that knowledge to be Rājasika. 18.21",

      "Again, that knowledge which clings to one body as if it were the whole, and which is irrational, has no real grasp of truth and is trivial, has been declared as Tāmasika. 18.22",

      "That action which is ordained by the scriptures and is not accompanied by the sense of doership, and has been done without any attachment or aversion by one who seeks no return, is called Sāttvika. 18.23",

      "That action, however, which involves much strain and is performed by one who seeks enjoyments or by a man full of egotism, has beenspoken of as Rājasika. 18.24",

      "That action which is undertaken through sheer ignorance, without regard to consequences or loss to oneself, injury to others and one's own resourcefulness, is declared as Tāmasika. 18.25",

      "Free from attachment, unegoistic, endowed with firmness and zeal and unswayed by success and failure-such a doer is said to be Sāttvika. 18.26",

      "The doer who is full of attachment, seeks the fruit of actions and is greedy, and who is oppressive by nature and of impure conduct, and who feels joy and sorrow, has been called Rājasika. 18.27",

      "Lacking piety and self-control, uncultured, arrogant, deceitful, inclined to rob others of their livelihood, slothful, despondent and procrastinating-such a doer is called Tāmasika. 18.28",

      "Now hear, Arjuna, the threefold divison, based on the predominance of each Guņa, of understanding (Buddhi) and firmness (Dhṛti), which I shall explain in detail, one by one. 18.29",

      "The intellect which correctly determines the paths of activity and renunciation, what ought to be done and what should not be done, what is fear and what is fearlessness, and what is bondage and what is liberation, that intellect is Sāttvika. 18.30",

      "The intellect by which man does not truly perceive what is Dharma and what is Adharma, what ought to be done and what should not be done-that intellect is Rājasika. 18.31",

      "The intellect wrapped in ignorance, which imagines even Adharma to be Dharma, and sees all other things upside-down-that intellect is Tāmasika, Arjuna. 18.32",

      "The unwavering perseverance by which man controls through the Yoga of meditation the functions of the mind, the vital airs and the senses- that firmness, Arjuna, is Sāttvika. 18.33",

      "The perseverance (Dhṛti), however, by which the man seeking reward for his actions clutches with extreme fondness virtues, earthly possessions and worldly enjoyments-that persevrance (Dhṛti) is said to be Rājasika, Arjuna.  18.34",

      "The perseverance (Dhṛti) by which an evilminded person does not give up sleep, fear, anxiety, sorrow and vanity as well, that perseverance is Tāmasika. 18.35",

      "Now hear from Me the threefold joy too. That in which the striver finds enjoyment through practice of adoration, meditation and service to God etc., and whereby he reaches the end of sorrow-such a joy,  18.36",

      "though appearing as poison in the beginning, tastes like nectar in the end; hence that joy, born as it is of the placidity of mind brought about by meditation on God, has been declared as Sāttvika. 18.37",

      "The delight which ensues from the contact of the senses with their objects is eventually poisonlike, though appearing at first as nectar; hence it has been spoken of as Rājasika. 18.38",

      "That which stupefies the Self during its enjoyment as well as in the end-derived from sleep, indolence and obstinate error, such delight has been called Tāmasika. 18.39",

      "There is no being on earth, or in the middle region or even among the gods or anywhere else, who is free from these three Guņas, born of Prakṛti. 18.40",

      "The duties of the Brāhmaņas, the Kṣatriyas and the Vaiśyas, as well as of the Śūdras have been assigned according to their innate modes of Prakṛti (Guņas), Arjuna. 18.41",

      "Subjugation of the mind and senses, enduring hardships for the discharge of one's sacred obligations, external and internal purity, forgiving the faults of others, straightness of mind, senses and behaviour, belief in the Vedas and other scriptures, God and life after death etc., study and teaching of the Vedas and other scriptures and realization of the truth relating to God-all these constitute the natural duties of a Brāhmaņa. 18.42",

      "Heroism, majesty, firmness, diligence and dauntlessness in battle, bestowing gifts, and lordliness-all these constitute the natural duty of a Kṣatriya. 18.43",

      "Agriculture, rearing of cows and honest exchange of merchandise-these constitute the natural duty of a Vaiśya (a member of the trading class); and service of the other classes is the natural duty even of a Śūdra (a member of the labouring class). 18.44",

      "Keenly devoted to his own natural duty, man attains the highest perfection in the form of Godrealization. Hear the mode of performance whereby the man engaged in his inborn duty reaches that highest consummation.  18.45",

      "From whom all beings come into being and by whom the whole universe is pervaded, by worshipping Him through the performance of his own natural duties, man attains the highest perfection. 18.46",

      "Better is one's own duty, though devoid of merit, than the duty of another well-performed; for, performing the duty ordained by his own nature, man does not incur sin. 18.47",

      "Therefore, Arjuna, one should not relinquish one's innate duty, even though it has a measure of evil; for all undertakings are beset by some evil, as is the fire covered by smoke. 18.48",

      "He whose intellect is unattached everywhere, whose thirst for enjoyment has altogether disappeared and who has subdued his mind, reaches through Sāṅkhyayoga (the path of Knowledge) the consummation of actionlessness. 18.49",

      "Arjuna, know from Me only briefly the process through which man having attained actionlessness, which is the highest consummation of Jñānayoga (the path of Knowledge), reaches Brahma. 18.50",

      "Endowed with a pure intellect and partaking of a light, Sāttvika and regulated diet, living in a lonely and undefiled place, having rejected sound and other objects of sense,  18.51",

      "having controlled the mind, speech and body by restraining the mind and senses through firmness of a Sāttvika type, taking a resolute stand on dispassion, after having completely got rid of attraction and aversion and remaining ever devoted to the Yoga of meditation, 18.52",

      "having given up egotism, violence, arrogance, lust, anger and luxuries, devoid of the feeling of meum and tranquil of heart-such a man becomes qualified for oneness with Brahma, who is Truth, Consciousness and Bliss. 18.53",

      "Established in identity with Brahma (who is Truth, Consciousness and Bliss solidified), and cheerful in mind, the Sāṅkhyayogī no longer grieves nor craves for anything. The same to all beings, such a Yogī attains supreme devotion to Me. 18.54",

      "Through that supreme devotion he comes to know Me in reality, what and who I am; and thereby knowing Me truly, he forthwith merges into My being. 18.55",

      "The Karmayogī, however, who depends on Me, attains by My grace the eternal, imperishable state, even though performing all actions. 18.56",

      "Mentally dedicating all your actions to Me, and taking recourse to Yoga in the form of evenmindedness, be solely devoted to Me and constantly fix your mind on Me. 18.57",

      "With your mind thus devoted to Me, you shall, by My grace overcome all difficulties. But, if from self-conceit you do not care to listen to Me, you will be lost. 18.58",

      "If, taking your stand on egotism, you think, \"I will not fight,\" vain is this resolve of yours; nature will drive you to the act. 18.59",

      "That action, too, which you are not willing to undertake through ignorance you will perforce perform, bound by your own duty born of your nature. 18.60",

      "Arjuna, God abides in the heart of all creatures, causing them to revolve according to their Karma by His illusive power (Māyā) as though mounted on a machine. 18.61",

      "Take refuge in Him alone with all your being, Arjuna. By His mere grace you will attain supreme peace and the eternal abode. 18.62",

      "Thus, has this wisdom, the most profound secret of all secret knowledge, been imparted to you by Me; deeply pondering over it, now do as you like. 18.63",

      "Hear, again, My supremely profound words, the most esoteric of all truths; as you are extremely dear to Me, therefore, I shall give you this salutary advice for your own good. 18.64",

      "Give your mind to Me, be devoted to Me, worship Me and bow to Me. Doing so, you will come to Me alone, I truly promise you; for, you are exceptionally dear to Me. 18.65",

      "Resigning all your duties to Me, the all-powerful and all supporting Lord, take refuge in Me alone; I shall absolve you of all sins, worry not. 18.66",

      "This secret gospel of the Gītā should never be imparted to a man who lacks in austerity, nor to him who is wanting in devotion, nor even to him who is not willing to hear; and in no case to him who finds fault with Me.  18.67",

      "He who, offering the highest love to Me, preaches the most profound gospel of the Gītā among My devotees, shall come to Me alone; there is no doubt about it. 18.68",

      "Among men there is none who does Me a more loving service than he; nor shall anyone be dearer to Me on the entire globe than he. 18.69",

      "Whosoever studies this sacred dialogue of ours in the form of the Gītā, by him too shall I be worshipped with Yajña of Knowledge; such is My conviction. 18.70",

      "The man who listens to the holy Gītā with reverence, being free from malice, he too, liberated from sin, shall reach the propitious worlds of the virtuous. 18.71",

      "Have you, O Arjuna, heard this gospel of the Gītā attentively? And has your delusion born of ignorance been destroyed, O Dhanañjaya, conqueror of riches? 18.72",

      "Arjuna said:\nKṛṣņa, by Your grace my delusion has been destroyed and I have gained wisdom. I am free of all doubt. I will do your bidding. 18.73",

      "Sañjaya said:\nThus I heard the mysterious and thrilling conversation between Śrī Kṛṣņa and the high-souled Arjuna, son of Kuntī. 18.74",

      "Having been blessed with the divine vision by the grace of Śrī Vyāsa, I heard in person this supremely esoteric gospel from the Lord of Yoga, Śrī Kṛṣņa Himself, imparting it to Arjuna. 18.75",

      "Remembering, over and over, that sacred and mystic conversation between Bhagavān Śrī Kṛṣņa and Arjuna, O King! I am thrilled again and yet again. 18.76",

      "Remembering also, again and again, that most wonderful form of Śrī Kṛṣņa, great is my wonder and I am thrilled over and over again. 18.77",

      "Wherever there is Bhagavān Śrī Kṛṣņa, the Lord of Yoga, and wherever there is Arjuna, the wielder of the Gāņḍīva bow, goodness, victory, glory and unfailing righteousness will surely be there : such is My conviction. 18.78",

      "Thus, in the Upaniṣad sung by the Lord, the Science of Brahma, the scripture of Yoga, the dialogue between Śrī Kṛṣņa and Arjuna, ends the eighteenth chapter entitled \"The Yoga of Liberation through the Path of Knowledge and Self-Surrender.\""            
      ]
      },
]

  export default GeetajiEnglish