const GeetajiMarathi = [
    {
      "adhyaya-1" : [
        "धृतराष्ट्र उवाच\nधर्मक्षेत्रे कुरुक्षेत्रे, समवेता युयुत्सवः।\nमामकाः(फ्) पाण्डवाश्चैव, किमकुर्वत सञ्जय॥1.1॥",
        
        "सञ्जय उवाच\nदृष्ट्वा तु पाण्डवानीकं(व्ँ), व्यूढं(न्) दुर्योधनस्तदा।\nआचार्यमुपसङ्गम्य, राजा वचनमब्रवीत्॥1.2॥",
        
        "पश्यैतां(म्) पाण्डुपुत्राणाम्, आचार्य महतीं(ञ्) चमूम्।\nव्यूढां(न्) द्रुपदपुत्रेण, तव शिष्येण धीमता॥1.3॥",
        
        "अत्र शूरा महेष्वासा, भीमार्जुनसमा युधि।\nयुयुधानो विराटश्च, द्रुपदश्च महारथः॥1.4॥",
        
        "धृष्टकेतुश्चेकितानः(ख्), काशिराजश्च वीर्यवान्।\nपुरुजित्कुन्तिभोजश्च, शैब्यश्च नरपुङ्गवः॥1.5॥",
        
        "युधामन्युश्च विक्रान्त, उत्तमौजाश्च वीर्यवान्।\nसौभद्रो द्रौपदेयाश्च, सर्व एव महारथाः॥1.6॥",
        
        "अस्माकं(न्) तु विशिष्टा ये, तान्निबोध द्विजोत्तम।\nनायका मम सैन्यस्य, सञ्ज्ञार्थं(न्) तान्ब्रवीमि ते॥1.7॥",
        
        "भवान्भीष्मश्च कर्णश्च, कृपश्च समितिञ्जयः।\nअश्वत्थामा विकर्णश्च, सौमदत्तिस्तथैव च॥1.8॥",
        
        "अन्ये च बहवः(श्) शूरा, मदर्थे त्यक्तजीविताः।\nनानाशस्त्रप्रहरणाः(स्), सर्वे युद्धविशारदाः॥1.9॥",
        
        "अपर्याप्तं(न्) तदस्माकं(म्), बलं(म्) भीष्माभिरक्षितम्।\nपर्याप्तं(न्) त्विदमेतेषां(म्), बलं(म्) भीमाभिरक्षितम्॥1.10॥",
        
        "अयनेषु च सर्वेषु, यथाभागमवस्थिताः।\nभीष्ममेवाभिरक्षन्तु, भवन्तः(स्) सर्व एव हि॥1.11॥",
        
        "तस्य सञ्जनयन्हर्षं(ङ्), कुरुवृद्धः(फ्) पितामहः।\nसिंहनादं(व्ँ) विनद्योच्चैः(श्), शङ्खं(न्) दध्मौ प्रतापवान्॥1.12॥",
        
        "ततः(श्) शङ्खाश्च भेर्यश्च, पणवानकगोमुखाः।\nसहसैवाभ्यहन्यन्त, स शब्दस्तुमुलोऽभवत्॥1.13॥",
        
        "ततः(श्) श्वेतैर्हयैर्युक्ते, महति स्यन्दने स्थितौ।\nमाधवः(फ्) पाण्डवश्चैव, दिव्यौ शङ्खौ प्रदध्मतुः॥1.14॥",
        
        "पाञ्चजन्यं(म्) हृषीकेशो, देवदत्तं(न्) धनञ्जयः।\nपौण्ड्रं(न्) दध्मौ महाशङ्खं(म्), भीमकर्मा वृकोदरः॥1.15॥",
        
        "अनन्तविजयं(म्) राजा, कुन्तीपुत्रो युधिष्ठिरः।\nनकुलः(स्) सहदेवश्च, सुघोषमणिपुष्पकौ॥1.16॥",
        
        "काश्यश्च परमेष्वासः(श्), शिखण्डी च महारथः।\nधृष्टद्युम्नो विराटश्च, सात्यकिश्चापराजितः॥1.17॥",
        
        "द्रुपदो द्रौपदेयाश्च, सर्वशः(फ्) पृथिवीपते।\nसौभद्रश्च महाबाहुः(श्), शङ्खान्दध्मुः(फ्) पृथक् पृथक्॥1.18॥",
        
        "स घोषो धार्तराष्ट्राणां(म्), हृदयानि व्यदारयत्।\nनभश्च पृथिवीं(ञ्) चैव, तुमुलो व्यनुनादयन्॥1.19॥",
        
        "अथ व्यवस्थितान्दृष्ट्वा, धार्तराष्ट्रान्कपिध्वजः।\nप्रवृत्ते शस्त्रसम्पाते, धनुरुद्यम्य पाण्डवः॥1.20॥",
        
        "हृषीकेशं(न्) तदा वाक्यम्, इदमाह महीपते।\nअर्जुन उवाच\nसेनयोरुभयोर्मध्ये, रथं(म्) स्थापय मेऽच्युत॥1.21॥",
        
        "यावदेतान्निरीक्षेऽहं(य्ँ), योद्धुकामानवस्थितान्।\nकैर्मया सह योद्धव्यम्,  अस्मिन्रणसमुद्यमे॥1.22॥",
        
        "योत्स्यमानानवेक्षेऽहं(य्ँ), य एतेऽत्र समागताः।\nधार्तराष्ट्रस्य दुर्बुद्धे:(र्), युद्धे प्रियचिकीर्षवः॥1.23॥",
        
        "सञ्जय उवाच\nएवमुक्तो हृषीकेशो, गुडाकेशेन भारत।\nसेनयोरुभयोर्मध्ये, स्थापयित्वा रथोत्तमम्॥1.24॥",
        
        "भीष्मद्रोणप्रमुखतः(स्), सर्वेषां(ञ्) च महीक्षिताम्।\nउवाच पार्थ पश्यैतान्, समवेतान्कुरूनिति॥1.25॥",
        
        "तत्रापश्यत्स्थितान्पार्थः(फ्), पितॄनथ पितामहान्।\nआचार्यान्मातुलान्भ्रातॄन्, पुत्रान्पौत्रान्सखींस्तथा॥1.26॥",
        
        "श्वशुरान्सुहृदश्चैव, सेनयोरुभयोरपि।\nतान्समीक्ष्य स कौन्तेयः(स्), सर्वान्बन्धूनवस्थितान्॥1.27॥",
        
        "कृपया परयाविष्टो, विषीदन्निदमब्रवीत्।\nअर्जुन उवाच\nदृष्ट्वेमं(म्) स्वजनं(ङ्) कृष्ण, युयुत्सुं(म्) समुपस्थितम्॥1.28॥",
        
        "सीदन्ति मम गात्राणि, मुखं(ञ्) च परिशुष्यति।\nवेपथुश्च शरीरे मे, रोमहर्षश्च जायते॥1.29॥",
        
        "गाण्डीवं(म्) स्रंसते हस्तात्, त्वक्चैव परिदह्यते।\nन च शक्नोम्यवस्थातुं(म्), भ्रमतीव च मे मनः॥1.30॥",
        
        "निमित्तानि च पश्यामि, विपरीतानि केशव।\nन च श्रेयोऽनुपश्यामि, हत्वा स्वजनमाहवे॥1.31॥",
        
        "न काङ्क्षे विजयं(ङ्) कृष्ण, न च राज्यं(म्) सुखानि च।\nकिं(न्) नो राज्येन गोविन्द, किं(म्) भोगैर्जीवितेन वा॥1.32॥",
        
        "येषामर्थे काङ्क्षितं(न्) नो, राज्यं(म्) भोगाः(स्) सुखानि च।\nत इमेऽवस्थिता युद्धे, प्राणांस्त्यक्त्वा धनानि च॥1.33॥",
        
        "आचार्याः(फ्) पितरः(फ्) पुत्रा:(स्), तथैव च पितामहाः।\nमातुलाः(श्) श्वशुराः(फ्) पौत्राः(श्), श्यालाः(स्) सम्बन्धिनस्तथा॥1.34॥",
        
        "एतान्न हन्तुमिच्छामि, घ्नतोऽपि मधुसूदन।\nअपि त्रैलोक्यराज्यस्य, हेतोः(ख्) किं(न्) नु महीकृते॥1.35॥",
        
        "निहत्य धार्तराष्ट्रान्नः(ख्), का प्रीतिः(स्) स्याज्जनार्दन।\nपापमेवाश्रयेदस्मान्, हत्वैतानाततायिनः॥1.36॥",
        
        "तस्मान्नार्हा वयं(म्) हन्तुं(न्), धार्तराष्ट्रान्स्वबान्धवान्।\nस्वजनं(म्) हि कथं(म्) हत्वा, सुखिनः(स्) स्याम माधव॥1.37॥",
        
        "यद्यप्येते न पश्यन्ति, लोभोपहतचेतसः।\nकुलक्षयकृतं(न्) दोषं(म्), मित्रद्रोहे च पातकम्॥1.38॥",
        
        "कथं(न्) न ज्ञेयमस्माभिः(फ्), पापादस्मान्निवर्तितुम्।\nकुलक्षयकृतं(न्) दोषं(म्), प्रपश्यद्भिर्जनार्दन॥1.39॥",
        
        "कुलक्षये प्रणश्यन्ति, कुलधर्माः(स्) सनातनाः।\nधर्मे नष्टे कुलं(ङ्) कृत्स्नम्, अधर्मोऽभिभवत्युत॥1.40॥",
        
        "अधर्माभिभवात्कृष्ण, प्रदुष्यन्ति कुलस्त्रियः।\nस्त्रीषु दुष्टासु वार्ष्णेय, जायते वर्णसङ्करः॥1.41॥",
        
        "सङ्करो नरकायैव, कुलघ्नानां(ङ्) कुलस्य च।\nपतन्ति पितरो ह्येषां(ल्ँ),  लुप्तपिण्डोदकक्रियाः॥1.42॥",
        
        "दोषैरेतैः(ख्) कुलघ्नानां(व्ँ), वर्णसङ्करकारकैः।\nउत्साद्यन्ते जातिधर्माः(ख्), कुलधर्माश्च शाश्वताः॥1.43॥",
        
        "उत्सन्नकुलधर्माणां(म्), मनुष्याणां(ञ्) जनार्दन।\nनरकेऽनियतं(व्ँ) वासो, भवतीत्यनुशुश्रुम॥1.44॥",
        
        "अहो बत महत्पापं(ङ्), कर्तुं(व्ँ) व्यवसिता वयम्।\nयद्राज्यसुखलोभेन, हन्तुं(म्) स्वजनमुद्यताः॥1.45॥",
        
        "यदि मामप्रतीकारम्, अशस्त्रं(म्) शस्त्रपाणयः।\nधार्तराष्ट्रा रणे हन्यु:(स्), तन्मे क्षेमतरं(म्) भवेत्॥1.46॥",
        
        "सञ्जय उवाच\nएवमुक्त्वार्जुनः(स्) सङ्ख्ये , रथोपस्थ उपाविशत्।\nविसृज्य सशरं(ञ्) चापं(म्), शोकसंविग्नमानसः॥1.47॥",
      
        "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु ब्रह्मविद्यायां(य्ँ) योगशास्त्रे\nश्रीकृष्णार्जुनसंवादे अर्जुनविषादयोगो नाम प्रथमोऽध्याय:।।"
      ],
      "adhyaya-2" : [
        "सञ्जय उवाच\nतं(न्) तथा कृपयाविष्टम्, अश्रुपूर्णाकुलेक्षणम्।\nविषीदन्तमिदं(व्ँ) वाक्यम्, उवाच मधुसूदनः॥2.1॥",
        
        "श्रीभगवानुवाच\nकुतस्त्वा कश्मलमिदं(व्ँ), विषमे समुपस्थितम्।\nअनार्यजुष्टमस्वर्ग्यम्,           अकीर्तिकरमर्जुन॥2.2॥",
        
        "क्लैब्यं(म्) मा स्म गमः(फ्) पार्थ, नैतत्त्वय्युपपद्यते ।\nक्षुद्रं(म्) हृदयदौर्बल्यं(न्), त्यक्त्वोत्तिष्ठ परन्तप॥2.3॥",
        
        "अर्जुन उवाच\nकथं(म्) भीष्ममहं(म्) सङ्ख्ये, द्रोणं(ञ्) च मधुसूदन।\nइषुभिः(फ्) प्रति योत्स्यामि, पूजार्हावरिसूदन॥2.4॥",
        
        "गुरूनहत्वा हि महानुभावान्,\nश्रेयो भोक्तुं(म्) भैक्ष्यमपीह लोके।\nहत्वार्थकामांस्तु गुरूनिहैव,\nभुञ्जीय  भोगान् रुधिरप्रदिग्धान्॥2.5॥",
        
        "न चैतद्विद्मः(ख्) कतरन्नो गरीयो,\nयद्वा जयेम यदि वा नो जयेयुः।\nयानेव हत्वा न जिजीविषाम:(स्),\nतेऽवस्थिताः(फ्) प्रमुखे धार्तराष्ट्राः॥2.6॥",
        
        "कार्पण्यदोषोपहतस्वभावः(फ्),\nपृच्छामि त्वां(न्)  धर्मसम्मूढचेताः।\nयच्छ्रेयः(स्) स्यान्निश्चितं(म्) ब्रूहि तन्मे,\nशिष्यस्तेऽहं(म्) शाधि मां(न्) त्वां(म्) प्रपन्नम्॥2.7॥",
        
        "न हि प्रपश्यामि ममापनुद्याद्,\nयच्छोकमुच्छोषणमिन्द्रियाणाम्।\nअवाप्य भूमावसपत्नमृद्धं(म्),\nराज्यं(म्) सुराणामपि चाधिपत्यम्॥2.8॥",
        
        "सञ्जय उवाच\nएवमुक्त्वा हृषीकेशं(ङ्), गुडाकेशः(फ्) परन्तप।\nन योत्स्य इति गोविन्दम्, उक्त्वा तूष्णीं(म्) बभूव ह॥2.9॥",
        
        "तमुवाच हृषीकेशः(फ्), प्रहसन्निव भारत।\nसेनयोरुभयोर्मध्ये, विषीदन्तमिदं(व्ँ) वचः॥2.10॥",
        
        "श्रीभगवानुवाच\nअशोच्यानन्वशोचस्त्वं(म्), प्रज्ञावादांश्च भाषसे।\nगतासूनगतासूंश्च,        नानुशोचन्ति  पण्डिता:॥2.11॥",
        
        "न त्वेवाहं(ञ्) जातु नासं(न्), न त्वं(न्) नेमे जनाधिपाः।\nन चैव न भविष्यामः(स्), सर्वे वयमतः(फ्) परम्॥2.12॥",
        
        "देहिनोऽस्मिन्यथा देहे, कौमारं(य्ँ) यौवनं(ञ्) जरा।\nतथा देहान्तरप्राप्ति:(र्),          धीरस्तत्र न मुह्यति॥2.13॥",
        
        "मात्रास्पर्शास्तु कौन्तेय, शीतोष्णसुखदुःखदाः।\nआगमापायिनोऽनित्या:(स्), तांस्तितिक्षस्व भारत॥2.14॥",
        
        "यं(म्) हि न व्यथयन्त्येते, पुरुषं(म्) पुरुषर्षभ।\nसमदुःखसुखं(न्) धीरं(म्), सोऽमृतत्वाय कल्पते॥2.15॥",
        
        "नासतो विद्यते भावो, नाभावो विद्यते सतः।\nउभयोरपि दृष्टोऽन्त:(स्), त्वनयोस्तत्त्वदर्शिभिः॥2.16॥",
        
        "अविनाशि तु तद्विद्धि, येन सर्वमिदं(न्) ततम्।\nविनाशमव्ययस्यास्य,      न कश्चित्कर्तुमर्हति॥2.17॥",
        
        "अन्तवन्त इमे देहा, नित्यस्योक्ताः(श्) शरीरिणः।\nअनाशिनोऽप्रमेयस्य,    तस्माद्युध्यस्व भारत॥2.18॥",
        
        "य एनं(व्ँ) वेत्ति हन्तारं(य्ँ), यश्चैनं(म्) मन्यते हतम्।\nउभौ तौ न विजानीतो, नायं(म्) हन्ति न हन्यते॥2.19॥",
        
        "न जायते म्रियते वा कदाचिन्,\nनायं (म्) भूत्वा भविता वा न भूयः।\nअजो नित्यः(श्) शाश्वतोऽयं(म्) पुराणो,\n न हन्यते हन्यमाने शरीरे॥2.20॥",
        
        "वेदाविनाशिनं(न्) नित्यं(य्ँ), य एनमजमव्ययम्।\nकथं(म्) स पुरुषः(फ्) पार्थ, कं(ङ्) घातयति हन्ति कम्॥2.21॥",
        
        "वासांसि जीर्णानि यथा विहाय,\nनवानि गृह्णाति नरोऽपराणि।\nतथा शरीराणि विहाय जीर्णा-\nन्यन्यानि संयाति नवानि देही॥2.22॥",
        
        "नैनं(ञ्) छिन्दन्ति शस्त्राणि, नैनं(न्) दहति पावकः।\nन चैनं(ङ्) क्लेदयन्त्यापो, न शोषयति मारुतः॥2.23॥",
        
        "अच्छेद्योऽयमदाह्योऽयम्, अक्लेद्योऽशोष्य एव च।\nनित्यः(स्) सर्वगतः(स्) स्थाणु:(र्), अचलोऽयं(म्) सनातनः॥2.24॥",
        
        "अव्यक्तोऽयमचिन्त्योऽयम्, अविकार्योऽयमुच्यते।\nतस्मादेवं(व्ँ) विदित्वैनं(न्), नानुशोचितुमर्हसि॥2.25॥",
        
        "अथ चैनं(न्) नित्यजातं(न्), नित्यं(व्ँ) वा मन्यसे मृतम्।\nतथापि त्वं(म्) महाबाहो, नैवं(म्) शोचितुमर्हसि॥2.26॥",
        
        "जातस्य हि ध्रुवो मृत्यु:(र्), ध्रुवं(ञ्) जन्म मृतस्य च।\nतस्मादपरिहार्येऽर्थे, न त्वं(म्) शोचितुमर्हसि॥2.27॥",
        
        "अव्यक्तादीनि भूतानि, व्यक्तमध्यानि भारत।\nअव्यक्तनिधनान्येव, तत्र का परिदेवना॥2.28॥",
        
        "आश्चर्यवत्पश्यति कश्चिदेनम्,\nआश्चर्यवद्वदति तथैव चान्यः।\nआश्चर्यवच्चैनमन्यः(श्) शृणोति,\nश्रुत्वाप्येनं(व्ँ) वेद न चैव कश्चित्॥2.29॥",
        
        "देही नित्यमवध्योऽयं(न्), देहे सर्वस्य भारत।\nतस्मात्सर्वाणि भूतानि, न त्वं(म्) शोचितुमर्हसि॥2.230॥",
        
        "स्वधर्ममपि चावेक्ष्य, न विकम्पितुमर्हसि।\nधर्म्याद्धि युद्धाच्छ्रेयोऽन्यत्, क्षत्रियस्य न विद्यते॥2.31॥",
        
        "यदृच्छया चोपपन्नं(म्), स्वर्गद्वारमपावृतम्।\nसुखिनः क्षत्रियाः(फ्) पार्थ, लभन्ते युद्धमीदृशम्॥2.32॥",
        
        "अथ चेत्त्वमिमं(न्) धर्म्यं(म्), सङ्ग्रामं(न्) न करिष्यसि।\nततः(स्) स्वधर्मं(ङ्) कीर्तिं(ञ्) च, हित्वा पापमवाप्स्यसि॥2.33॥",
        
        "अकीर्तिं(ञ्) चापि भूतानि, कथयिष्यन्ति तेऽव्ययाम्।\nसम्भावितस्य चाकीर्ति:(र्), मरणादतिरिच्यते॥2.34॥",
        
        "भयाद्रणादुपरतं(म्), मंस्यन्ते त्वां(म्) महारथाः।\nयेषां(ञ्) च त्वं(म्) बहुमतो, भूत्वा यास्यसि लाघवम्॥2.35॥",
        
        "अवाच्यवादांश्च बहून्, वदिष्यन्ति तवाहिताः।\nनिन्दन्तस्तव सामर्थ्यं(न्), ततो दुःखतरं(न्) नु किम्॥2.36॥",
        
        "हतो वा प्राप्स्यसि स्वर्गं(ञ्), जित्वा वा भोक्ष्यसे महीम्।\nतस्मादुत्तिष्ठ कौन्तेय, युद्धाय कृतनिश्चयः॥2.37॥",
        
        "सुखदुःखे समे कृत्वा, लाभालाभौ जयाजयौ।\nततो युद्धाय युज्यस्व, नैवं(म्) पापमवाप्स्यसि॥2.38॥",
        
        "एषा तेऽभिहिता साङ्ख्ये, बुद्धिर्योगे त्विमां(म्) शृणु।\nबुद्ध्या युक्तो यया पार्थ, कर्मबन्धं(म्) प्रहास्यसि॥2.39॥",
        
        "नेहाभिक्रमनाशोऽस्ति, प्रत्यवायो न विद्यते।\nस्वल्पमप्यस्य धर्मस्य, त्रायते महतो भयात्॥2.40॥",
        
        "व्यवसायात्मिका बुद्धि:(र्), एकेह कुरुनन्दन।\nबहुशाखा ह्यनन्ताश्च, बुद्धयोऽव्यवसायिनाम्॥2.41॥",
        
        "यामिमां(म्) पुष्पितां(व्ँ) वाचं(म्), प्रवदन्त्यविपश्चितः।\nवेदवादरताः(फ्) पार्थ, नान्यदस्तीति वादिनः॥2.42॥",
        
        "कामात्मानः(स्) स्वर्गपरा, जन्मकर्मफलप्रदाम्।\nक्रियाविशेषबहुलां(म्), भोगैश्वर्यगतिं(म्) प्रति॥2.43॥",
        
        "भोगैश्वर्यप्रसक्तानां(न्), तयापहृतचेतसाम्।\nव्यवसायात्मिका बुद्धिः(स्), समाधौ न विधीयते॥2.44॥",
        
        "त्रैगुण्यविषया वेदा, निस्त्रैगुण्यो भवार्जुन।\nनिर्द्वन्द्वो नित्यसत्त्वस्थो, निर्योगक्षेम आत्मवान्॥2.45॥",
        
        "यावानर्थ उदपाने, सर्वतः(स्) सम्प्लुतोदके।\nतावान्सर्वेषु वेदेषु, ब्राह्मणस्य विजानतः॥2.46॥",
        
        "कर्मण्येवाधिकारस्ते, मा फलेषु कदाचन।\nमा कर्मफलहेतुर्भू:(र्), मा ते सङ्गोऽस्त्वकर्मणि॥2.47॥",
        
        "योगस्थः(ख्) कुरु कर्माणि, सङ्गं(न्) त्यक्त्वा धनञ्जय।\nसिद्ध्यसिद्ध्योः(स्) समो भूत्वा, समत्वं(य्ँ) योग उच्यते॥2.48॥",
        
        "दूरेण ह्यवरं(ङ्) कर्म, बुद्धियोगाद्धनञ्जय।\nबुद्धौ शरणमन्विच्छ, कृपणाः(फ्) फलहेतवः॥2.49॥",
        
        "बुद्धियुक्तो जहातीह, उभे सुकृतदुष्कृते।\nतस्माद्योगाय युज्यस्व, योगः(ख्) कर्मसु कौशलम्॥2.50॥",
        
        "कर्मजं(म्) बुद्धियुक्ता हि, फलं(न्) त्यक्त्वा मनीषिणः।\nजन्मबन्धविनिर्मुक्ताः(फ्), पदं(ङ्) गच्छन्त्यनामयम्॥2.51॥",
        
        "यदा ते मोहकलिलं(म्), बुद्धिर्व्यतितरिष्यति।\nतदा गन्तासि निर्वेदं(म्), श्रोतव्यस्य श्रुतस्य च॥2.52॥",
        
        "श्रुतिविप्रतिपन्ना ते, यदा स्थास्यति निश्चला।\nसमाधावचला बुद्धि:(स्), तदा योगमवाप्स्यसि॥2.53॥",
        
        "अर्जुन उवाच\nस्थितप्रज्ञस्य का भाषा, समाधिस्थस्य केशव।\nस्थितधीः(ख्) किं(म्) प्रभाषेत, किमासीत व्रजेत किम्॥2.54॥",
        
        "श्रीभगवानुवाच\nप्रजहाति यदा कामान्, सर्वान्पार्थ मनोगतान्।\nआत्मन्येवात्मना तुष्टः(स्), स्थितप्रज्ञस्तदोच्यते॥2.55॥",
        
        "दुःखेष्वनुद्विग्नमनाः(स्), सुखेषु विगतस्पृहः।\nवीतरागभयक्रोधः(स्), स्थितधीर्मुनिरुच्यते॥2.56॥",
        
        "यः(स्) सर्वत्रानभिस्नेह:(स्), तत्तत्प्राप्य शुभाशुभम्।\nनाभिनन्दति न द्वेष्टि, तस्य प्रज्ञा प्रतिष्ठिता॥2.57॥",
        
        "यदा संहरते चायं(ङ्), कूर्मोऽङ्गानीव सर्वशः।\nइन्द्रियाणीन्द्रियार्थेभ्य:(स्), तस्य प्रज्ञा प्रतिष्ठिता॥2.58॥",
        
        "विषया विनिवर्तन्ते, निराहारस्य देहिनः।\nरसवर्जं(म्) रसोऽप्यस्य, परं(न्) दृष्ट्वा निवर्तते ॥2.59॥",
        
        "यततो ह्यपि कौन्तेय, पुरुषस्य विपश्चितः।\nइन्द्रियाणि प्रमाथीनि, हरन्ति प्रसभं(म्) मनः॥2.60॥",
        
        "तानि सर्वाणि संयम्य, युक्त आसीत मत्परः।\nवशे हि यस्येन्द्रियाणि, तस्य प्रज्ञा प्रतिष्ठिता॥2.61॥",
        
        "ध्यायतो विषयान्पुंस:(स्), सङ्गस्तेषूपजायते।\nसङ्गात्सञ्जायते कामः(ख्), कामात्क्रोधोऽभिजायते॥2.62॥",
        
        "क्रोधाद्भवति सम्मोह:(स्), सम्मोहात्स्मृतिविभ्रमः।\nस्मृतिभ्रंशाद् बुद्धिनाशो, बुद्धिनाशात्प्रणश्यति॥2.63॥",
        
        "रागद्वेषवियुक्तैस्तु, विषयानिन्द्रियैश्चरन्।\nआत्मवश्यैर्विधेयात्मा, प्रसादमधिगच्छति॥2.64॥",
        
        "प्रसादे सर्वदुःखानां(म्), हानिरस्योपजायते।\nप्रसन्नचेतसो ह्याशु, बुद्धिः(फ्) पर्यवतिष्ठते॥2.65॥",
        
        "नास्ति बुद्धिरयुक्तस्य, न चायुक्तस्य भावना।\nन चाभावयतः(श्) शान्ति:(र्), अशान्तस्य कुतः(स्) सुखम्॥2.66॥",
        
        "इन्द्रियाणां(म्) हि चरतां(य्ँ), यन्मनोऽनुविधीयते।\nतदस्य हरति प्रज्ञां(व्ँ), वायुर्नावमिवाम्भसि॥2.67॥",
        
        "तस्माद्यस्य महाबाहो, निगृहीतानि सर्वशः।\nइन्द्रियाणीन्द्रियार्थेभ्य:(स्), तस्य प्रज्ञा प्रतिष्ठिता॥2.68॥",
        
        "या निशा सर्वभूतानां(न्), तस्यां(ञ्) जागर्ति संयमी।\nयस्यां(ञ्) जाग्रति भूतानि, सा निशा पश्यतो मुनेः॥2.69॥",
        
        "आपूर्यमाणमचलप्रतिष्ठं(म्),\nसमुद्रमापः(फ्) प्रविशन्ति यद्वत्।\nतद्वत्कामा यं(म्) प्रविशन्ति सर्वे,\nस शान्तिमाप्नोति न कामकामी॥2.70॥",
        
        "विहाय कामान्यः(स्) सर्वान्, पुमांश्चरति निःस्पृहः।\nनिर्ममो निरहङ्कारः(स्), स शान्तिमधिगच्छति॥2.71॥",
        
        "एषा ब्राह्मी स्थितिः(फ्) पार्थ, नैनां(म्) प्राप्य विमुह्यति ।\nस्थित्वास्यामन्तकालेऽपि,        ब्रह्मनिर्वाणमृच्छति॥2.72॥",
      
        "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु ब्रह्मविद्यायां(य्ँ) योगशास्त्रे\nश्रीकृष्णार्जुनसंवादे साङ्ख्ययोगो नाम द्वितीयोऽध्यायः ॥२॥"
      ],
      "adhyaya-3" : [
        "अर्जुन उवाच\nज्यायसी चेत्कर्मणस्ते, मता बुद्धिर्जनार्दन।\nतत्किं(ङ्) कर्मणि घोरे मां(न्), नियोजयसि केशव॥3.1॥",
        
        "व्यामिश्रेणेव वाक्येन, बुद्धिं(म्) मोहयसीव मे।\nतदेकं(व्ँ) वद निश्चित्य, येन श्रेयोऽहमाप्नुयाम्॥3.2॥",
        
        "श्रीभगवानुवाच\nलोकेऽस्मिन्द्विविधा निष्ठा, पुरा प्रोक्ता मयानघ।\nज्ञानयोगेन साङ्ख्यानां(ङ्), कर्मयोगेन योगिनाम्॥3.3॥",
        
       " न कर्मणामनारम्भान्, नैष्कर्म्यं(म्) पुरुषोऽश्नुते।\nन च सन्न्यसनादेव, सिद्धिं(म्) समधिगच्छति॥3.4॥",
        
        "न हि कश्चित्क्षणमपि, जातु तिष्ठत्यकर्मकृत्।\nकार्यते ह्यवशः(ख्) कर्म, सर्वः(फ्) प्रकृतिजैर्गुणैः॥3.5॥",
        
        "कर्मेन्द्रियाणि संयम्य, य आस्ते मनसा स्मरन्।\nइन्द्रियार्थान्विमूढात्मा, मिथ्याचारः(स्) स उच्यते॥3.6॥",
        
        "यस्त्विन्द्रियाणि मनसा, नियम्यारभतेऽर्जुन।\nकर्मेन्द्रियैः(ख्) कर्मयोगम्, असक्तः(स्) स विशिष्यते॥3.7॥",
        
        "नियतं(ङ्) कुरु कर्म त्वं(ङ्), कर्म ज्यायो ह्यकर्मणः।\nशरीरयात्रापि च ते, न प्रसिद्ध्येदकर्मणः॥3.8॥",
        
        "यज्ञार्थात्कर्मणोऽन्यत्र, लोकोऽयं(ङ्) कर्मबन्धनः।\nतदर्थं(ङ्) कर्म कौन्तेय, मुक्तसङ्गः(स्) समाचर॥3.9॥",
        
        "सहयज्ञाः(फ्) प्रजाः(स्) सृष्ट्वा, पुरोवाच प्रजापतिः।\nअनेन प्रसविष्यध्वम्, एष वोऽस्त्विष्टकामधुक्॥3.10॥",
        
        "देवान्भावयतानेन, ते देवा भावयन्तु वः।\nपरस्परं(म्) भावयन्तः(श्), श्रेयः(फ्) परमवाप्स्यथ॥3.11॥",
        
        "इष्टान्भोगान्हि वो देवा, दास्यन्ते यज्ञभाविताः।\nतैर्दत्तानप्रदायैभ्यो, यो भुङ्क्ते स्तेन एव सः॥3.12॥",
        
        "यज्ञशिष्टाशिनः(स्) सन्तो, मुच्यन्ते सर्वकिल्बिषैः।\nभुञ्जते ते त्वघं(म्) पापा, ये पचन्त्यात्मकारणात्॥3.13॥",
        
        "अन्नाद्भवन्ति भूतानि, पर्जन्यादन्नसम्भवः।\nयज्ञाद्भवति पर्जन्यो, यज्ञः(ख्) कर्मसमुद्भवः॥3.14॥",
        
        "कर्म ब्रह्मोद्भवं(व्ँ) विद्धि, ब्रह्माक्षरसमुद्भवम्।\nतस्मात्सर्वगतं(म्) ब्रह्म, नित्यं(य्ँ) यज्ञे प्रतिष्ठितम्॥3.15॥",
        
        "एवं(म्) प्रवर्तितं(ञ्) चक्रं(न्), नानुवर्तयतीह यः।\nअघायुरिन्द्रियारामो, मोघं(म्) पार्थ स जीवति॥3.16॥",
        
        "यस्त्वात्मरतिरेव स्याद्, आत्मतृप्तश्च मानवः।\nआत्मन्येव च सन्तुष्ट:(स्), तस्य कार्यं(न्) न विद्यते॥3.17॥",
        
        "नैव तस्य कृतेनार्थो, नाकृतेनेह कश्चन।\nन चास्य सर्वभूतेषु, कश्चिदर्थव्यपाश्रयः॥3.18॥",
        
        "तस्मादसक्तः(स्) सततं(ङ्), कार्यं(ङ्) कर्म समाचर।\nअसक्तो ह्याचरन्कर्म, परमाप्नोति पूरुषः॥3.19॥",
        
        "कर्मणैव हि संसिद्धिम्, आस्थिता जनकादयः।\nलोकसङ्ग्रहमेवापि, सम्पश्यन्कर्तुमर्हसि॥3.20॥",
        
        "यद्यदाचरति श्रेष्ठ:(स्), तत्तदेवेतरो जनः।\nस यत्प्रमाणं(ङ्) कुरुते, लोकस्तदनुवर्तते॥3.21॥",
        
        "न मे पार्थास्ति कर्तव्यं(न्), त्रिषु लोकेषु किञ्चन।\nनानवाप्तमवाप्तव्यं(व्ँ), वर्त एव च कर्मणि॥3.22॥",
        
        "यदि ह्यहं(न्) न वर्तेयं(ञ्), जातु कर्मण्यतन्द्रितः।\nमम वर्त्मानुवर्तन्ते, मनुष्याः(फ्) पार्थ सर्वशः॥3.23॥",
        
        "उत्सीदेयुरिमे लोका, न कुर्यां(ङ्) कर्म चेदहम्।\nसङ्करस्य च कर्ता स्याम्, उपहन्यामिमाः(फ्) प्रजाः॥3.24॥",
        
        "सक्ताः(ख्) कर्मण्यविद्वांसो, यथा कुर्वन्ति भारत।\nकुर्याद्विद्वांस्तथासक्त:(श्), चिकीर्षुर्लोकसङ्ग्रहम्॥3.25॥",
        
        "न बुद्धिभेदं(ञ्) जनयेद्, अज्ञानां(ङ्) कर्मसङ्गिनाम्।\nजोषयेत्सर्वकर्माणि, विद्वान्युक्तः(स्) समाचरन्॥3.26॥",
        
        "प्रकृतेः(ख्) क्रियमाणानि, गुणैः(ख्) कर्माणि सर्वशः।\nअहङ्कारविमूढात्मा, कर्ताहमिति मन्यते॥3.27॥",
        
        "तत्त्ववित्तु महाबाहो, गुणकर्मविभागयोः।\nगुणा गुणेषु वर्तन्त, इति मत्वा न सज्जते॥3.28॥",
        
        "प्रकृतेर्गुणसम्मूढाः(स्), सज्जन्ते गुणकर्मसु।\nतानकृत्स्नविदो मन्दान्, कृत्स्नविन्न विचालयेत्॥3.29॥",
        
        "मयि सर्वाणि कर्माणि,  सन्न्यस्याध्यात्मचेतसा।\nनिराशीर्निर्ममो भूत्वा, युध्यस्व विगतज्वरः॥3.30॥",
        
        "ये मे मतमिदं(न्) नित्यम्, अनुतिष्ठन्ति मानवाः।\nश्रद्धावन्तोऽनसूयन्तो, मुच्यन्ते तेऽपि कर्मभिः॥3.31॥",
        
        "ये त्वेतदभ्यसूयन्तो, नानुतिष्ठन्ति मे मतम्।\nसर्वज्ञानविमूढांस्तान्, विद्धि नष्टानचेतसः॥3.32॥",
        
        "सदृशं(ञ्) चेष्टते स्वस्याः(फ्), प्रकृतेर्ज्ञानवानपि।\nप्रकृतिं(य्ँ) यान्ति भूतानि, निग्रहः(ख्) किं(ङ्) करिष्यति॥3.33॥",
        
        "इन्द्रियस्येन्द्रियस्यार्थे, रागद्वेषौ व्यवस्थितौ।\nतयोर्न वशमागच्छेत्, तौ ह्यस्य परिपन्थिनौ॥3.34॥",
        
        "श्रेयान्स्वधर्मो विगुणः(फ्),  परधर्मात्स्वनुष्ठितात्।\nस्वधर्मे निधनं(म्) श्रेयः(फ्), परधर्मो भयावहः॥3.35॥",
        
        "अर्जुन उवाच\nअथ केन प्रयुक्तोऽयं(म्), पापं(ञ्) चरति पूरुषः।\nअनिच्छन्नपि वार्ष्णेय, बलादिव नियोजितः॥3.36॥",
        
        "श्रीभगवानुवाच\nकाम एष क्रोध एष, रजोगुणसमुद्भवः।\nमहाशनो महापाप्मा, विद्ध्येनमिह वैरिणम्॥3.37॥",
        
        "धूमेनाव्रियते वह्नि:(र्), यथादर्शो मलेन च।\nयथोल्बेनावृतो गर्भ:(स्), तथा तेनेदमावृतम्॥3.38॥",
        
        "आवृतं(ञ्) ज्ञानमेतेन, ज्ञानिनो नित्यवैरिणा।\nकामरूपेण कौन्तेय, दुष्पूरेणानलेन च॥3.39॥",
        
        "इन्द्रियाणि मनो बुद्धि:(र्), अस्याधिष्ठानमुच्यते।\nएतैर्विमोहयत्येष, ज्ञानमावृत्य देहिनम्॥3.40॥",
        
        "तस्मात्त्वमिन्द्रियाण्यादौ, नियम्य भरतर्षभ।\nपाप्मानं(म्) प्रजहि ह्येनं(ञ्), ज्ञानविज्ञाननाशनम्॥3.41॥",
        
        "इन्द्रियाणि पराण्याहु:(र्), इन्द्रियेभ्यः(फ्) परं(म्) मनः।\nमनसस्तु परा बुद्धि:(र्), यो बुद्धेः(फ्) परतस्तु सः॥3.42॥",
        
        "एवं(म्) बुद्धेः(फ्) परं(म्) बुद्ध्वा, संस्तभ्यात्मानमात्मना।\nजहि शत्रुं(म्) महाबाहो, कामरूपं(न्) दुरासदम्॥3.43॥",
      
        "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु  ब्रह्मविद्यायां(य्ँ) योगशास्त्रे\nश्रीकृष्णार्जुनसंवादे कर्मयोगो नाम तृतीयोऽध्याय:॥"
      ],
      "adhyaya-4" : [
        "श्रीभगवानुवाच\nइमं(व्ँ) विवस्वते योगं(म्), प्रोक्तवानहमव्ययम्।\nविवस्वान्मनवे प्राह, मनुरिक्ष्वाकवेऽब्रवीत्॥4.1॥",
        
        "एवं(म्) परम्पराप्राप्तम्, इमं(म्) राजर्षयो विदुः।\nस कालेनेह महता, योगो नष्टः(फ्) परन्तप॥4.2 ॥",
        
        "स एवायं(म्) मया तेऽद्य, योगः(फ्) प्रोक्तः(फ्) पुरातनः।\nभक्तोऽसि मे सखा चेति, रहस्यं(म्) ह्येतदुत्तमम्॥4.3॥",  
        
        "अर्जुन उवाच\nअपरं(म्) भवतो जन्म, परं(ञ्) जन्म विवस्वतः।\nकथमेतद्विजानीयां(न्), त्वमादौ प्रोक्तवानिति॥4.4॥",
        
        "श्रीभगवानुवाच\nबहूनि मे व्यतीतानि, जन्मानि तव चार्जुन।\nतान्यहं(व्ँ) वेद सर्वाणि, न त्वं(व्ँ) वेत्थ परन्तप॥4.5॥",
        
        "अजोऽपि सन्नव्ययात्मा, भूतानामीश्वरोऽपि सन्।\nप्रकृतिं(म्) स्वामधिष्ठाय, सम्भवाम्यात्ममायया॥4.6॥",
        
        "यदा यदा हि धर्मस्य, ग्लानिर्भवति भारत।\nअभ्युत्थानमधर्मस्य, तदात्मानं(म्) सृजाम्यहम्॥4.7॥",
        
        "परित्राणाय साधूनां(व्ँ), विनाशाय च दुष्कृताम्।\nधर्मसंस्थापनार्थाय, सम्भवामि युगे युगे॥4.8॥",
        
        "जन्म कर्म च मे दिव्यम्, एवं(य्ँ) यो वेत्ति तत्त्वतः।\nत्यक्त्वा देहं(म्) पुनर्जन्म, नैति मामेति सोऽर्जुन॥4.9॥",
        
        "वीतरागभयक्रोधा, मन्मया मामुपाश्रिताः।\nबहवो ज्ञानतपसा, पूता मद्भावमागताः॥4.10॥",
        
        "ये यथा मां(म्) प्रपद्यन्ते, तांस्तथैव भजाम्यहम्।\nमम वर्त्मानुवर्तन्ते, मनुष्याः(फ्) पार्थ सर्वशः॥4.11॥",
        
        "काङ्क्षन्तः(ख्) कर्मणां(म्) सिद्धिं(य्ँ), यजन्त इह देवताः।\nक्षिप्रं(म्) हि मानुषे लोके, सिद्धिर्भवति कर्मजा॥4.12॥",
        
        "चातुर्वर्ण्यं(म्) मया सृष्टं(ङ्), गुणकर्मविभागशः।\nतस्य कर्तारमपि मां(व्ँ), विद्ध्यकर्तारमव्ययम्॥4.13॥",
        
        "न मां(ङ्) कर्माणि लिम्पन्ति, न मे कर्मफले स्पृहा।\nइति मां(य्ँ) योऽभिजानाति, कर्मभिर्न स बध्यते॥4.14॥",
        
        "एवं(ञ्) ज्ञात्वा कृतं(ङ्) कर्म, पूर्वैरपि मुमुक्षुभिः।\nकुरु कर्मैव तस्मात्त्वं(म्), पूर्वैः(फ्) पूर्वतरं(ङ्) कृतम्॥4.15॥",
        
        "किं(ङ्) कर्म किमकर्मेति, कवयोऽप्यत्र मोहिताः।\nतत्ते कर्म प्रवक्ष्यामि, यज्ज्ञात्वा मोक्ष्यसेऽशुभात्॥4.16॥",
        
        "कर्मणो ह्यपि बोद्धव्यं(म्), बोद्धव्यं(ञ्) च विकर्मणः।\nअकर्मणश्च बोद्धव्यं(ङ्), गहना कर्मणो गतिः॥4.17॥",
        
        "कर्मण्यकर्म यः(फ्) पश्येद्, अकर्मणि च कर्म यः।\nस बुद्धिमान्मनुष्येषु, स युक्तः(ख्) कृत्स्नकर्मकृत्॥4.18॥",
        
        "यस्य सर्वे समारम्भाः(ख्), कामसङ्कल्पवर्जिताः।\nज्ञानाग्निदग्धकर्माणं(न्), तमाहुः(फ्) पण्डितं(म्) बुधाः॥4.19॥",
        
        "त्यक्त्वा कर्मफलासङ्गं(न्), नित्यतृप्तो निराश्रयः।\nकर्मण्यभिप्रवृत्तोऽपि, नैव किञ्चित्करोति सः॥4.20॥",
        
        "निराशीर्यतचित्तात्मा, त्यक्तसर्वपरिग्रहः।\nशारीरं(ङ्) केवलं(ङ्) कर्म, कुर्वन्नाप्नोति किल्बिषम्॥4.21॥",
        
        "यदृच्छालाभसन्तुष्टो, द्वन्द्वातीतो विमत्सरः।\nसमः(स्) सिद्धावसिद्धौ च, कृत्वापि न निबध्यते॥4.22॥",
        
        "गतसङ्गस्य मुक्तस्य, ज्ञानावस्थितचेतसः।\nयज्ञायाचरतः(ख्) कर्म, समग्रं(म्) प्रविलीयते॥23॥",
        
        "ब्रह्मार्पणं(म्) ब्रह्म हवि:(र्), ब्रह्माग्नौ ब्रह्मणा हुतम्।\nब्रह्मैव तेन गन्तव्यं(म्), ब्रह्मकर्मसमाधिना॥4.24॥",
        
        "दैवमेवापरे यज्ञं(य्ँ), योगिनः(फ्) पर्युपासते।\nब्रह्माग्नावपरे यज्ञं(य्ँ), यज्ञेनैवोपजुह्वति॥4.25॥",
        
        "श्रोत्रादीनीन्द्रियाण्यन्ये, संयमाग्निषु जुह्वति।\nशब्दादीन्विषयानन्य, इन्द्रियाग्निषु जुह्वति॥4.26॥",
        
        "सर्वाणीन्द्रियकर्माणि, प्राणकर्माणि चापरे।\nआत्मसंयमयोगाग्नौ, जुह्वति ज्ञानदीपिते॥4.27॥",
        
        "द्रव्ययज्ञास्तपोयज्ञा, योगयज्ञास्तथापरे।\nस्वाध्यायज्ञानयज्ञाश्च, यतयः(स्) संशितव्रताः॥4.28॥",
         
        "अपाने जुह्वति प्राणं(म्), प्राणेऽपानं(न्) तथापरे।\nप्राणापानगती रुद्ध्वा, प्राणायामपरायणाः॥4.29॥",
         
        "अपरे नियताहाराः(फ्), प्राणान्प्राणेषु जुह्वति।\nसर्वेऽप्येते यज्ञविदो, यज्ञक्षपितकल्मषाः॥4.30॥",
         
        "यज्ञशिष्टामृतभुजो, यान्ति ब्रह्म सनातनम्।\nनायं(ल्ँ) लोकोऽस्त्ययज्ञस्य, कुतोऽन्यः(ख्) कुरुसत्तम॥4.31॥",
        
        "एवं(म्) बहुविधा यज्ञा, वितता ब्रह्मणो मुखे।\nकर्मजान्विद्धि तान्सर्वान्, एवं(ञ्) ज्ञात्वा विमोक्ष्यसे॥4.32॥",
        
        "श्रेयान्द्रव्यमयाद्यज्ञाज्, ज्ञानयज्ञः(फ्) परन्तप।\nसर्वं(ङ्) कर्माखिलं(म्) पार्थ, ज्ञाने परिसमाप्यते॥4.33॥",
        
        "तद्विद्धि प्रणिपातेन, परिप्रश्नेन सेवया।\nउपदेक्ष्यन्ति ते ज्ञानं(ञ्), ज्ञानिनस्तत्त्वदर्शिनः॥4.34॥",
        
        "यज्ज्ञात्वा न पुनर्मोहम्, एवं(य्ँ) यास्यसि पाण्डव।\nयेन भूतान्यशेषेण, द्रक्ष्यस्यात्मन्यथो मयि॥4.35॥",
        
        "अपि चेदसि पापेभ्यः(स्), सर्वेभ्यः(फ्) पापकृत्तमः।\nसर्वं(ञ्) ज्ञानप्लवेनैव, वृजिनं(म्) सन्तरिष्यसि॥4.36॥",
        
        "यथैधांसि समिद्धोऽग्नि:(र्),  भस्मसात्कुरुतेऽर्जुन।\nज्ञानाग्निः(स्) सर्वकर्माणि, भस्मसात्कुरुते तथा॥4.37॥",
        
        "न हि ज्ञानेन सदृशं(म्), पवित्रमिह विद्यते।\nतत्स्वयं(य्ँ) योगसंसिद्धः(ख्), कालेनात्मनि विन्दति॥4.38॥",
        
        "श्रद्धावाँल्लभते ज्ञानं(न्), तत्परः(स्) संयतेन्द्रियः।\nज्ञानं(ल्ँ) लब्ध्वा परां(म्) शान्तिम्, अचिरेणाधिगच्छति॥4.39॥",
        
        "अज्ञश्चाश्रद्दधानश्च, संशयात्मा विनश्यति।\nनायं(ल्ँ) लोकोऽस्ति न परो, न सुखं(म्) संशयात्मनः॥4.40॥",
        
        "योगसन्न्यस्तकर्माणं(ञ्), ज्ञानसञ्छिन्नसंशयम्।\nआत्मवन्तं(न्) न कर्माणि, निबध्नन्ति धनञ्जय॥4.41॥",
        
        "तस्मादज्ञानसम्भूतं(म्), हृत्स्थं(ञ्) ज्ञानासिनात्मन:।\nछित्त्वैनं(म्) संशयं(य्ँ) योगम्, आतिष्ठोत्तिष्ठ भारत॥4.42॥",
        
        "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु ब्रह्मविद्यायां(य्ँ) योगशास्त्रे\nश्रीकृष्णार्जुनसंवादे ज्ञानकर्मसन्न्यासयोगो नाम चतुर्थोऽध्याय:।।",
      ],
      "adhyaya-5" : [
      
        "अर्जुन उवाच\nसन्न्यासं(ङ्) कर्मणां(ङ्) कृष्ण, पुनर्योगं(ञ्) च शंससि।\nयच्छ्रेय एतयोरेकं(न्), तन्मे ब्रूहि सुनिश्चितम्॥5.1॥",
        
        "श्रीभगवानुवाच\nसन्न्यासः(ख्) कर्मयोगश्च, निःश्रेयसकरावुभौ।\nतयोस्तु कर्मसन्न्यासात्, कर्मयोगो विशिष्यते॥5.2॥",
        
        "ज्ञेयः(स्) स नित्यसन्न्यासी, यो न द्वेष्टि न काङ्क्षति।\nनिर्द्वन्द्वो हि महाबाहो, सुखं(म्) बन्धात्प्रमुच्यते॥5.3॥",
        
        "साङ्ख्ययोगौ पृथग्बालाः(फ्), प्रवदन्ति न पण्डिताः।\nएकमप्यास्थितः(स्) सम्यग्, उभयोर्विन्दते फलम्॥5.4॥",
        
        "यत्साङ्ख्यैः(फ्) प्राप्यते स्थानं(न्), तद्योगैरपि गम्यते।\nएकं(म्) साङ्ख्यं(ञ्) च योगं(ञ्) च, यः(फ्) पश्यति स पश्यति॥5.5॥",
        
        "सन्न्यासस्तु महाबाहो, दुःखमाप्तुमयोगतः।\nयोगयुक्तो मुनिर्ब्रह्म, नचिरेणाधिगच्छति॥5.6॥",
        
        "योगयुक्तो विशुद्धात्मा, विजितात्मा जितेन्द्रियः।\nसर्वभूतात्मभूतात्मा, कुर्वन्नपि न लिप्यते॥5.7॥",
        
        "नैव किञ्चित्करोमीति, युक्तो मन्येत तत्त्ववित्।\nपश्यञ्शृण्वन्स्पृशञ्जिघ्रन्, नश्नन्गच्छन्स्वपञ्श्वसन्॥5.8॥",
        
        "प्रलपन्विसृजन्गृह्णन्, नुन्मिषन्निमिषन्नपि।\nइन्द्रियाणीन्द्रियार्थेषु, वर्तन्त इति धारयन्॥5.9॥", 
        
        "ब्रह्मण्याधाय कर्माणि, सङ्गं(न्) त्यक्त्वा करोति यः।\nलिप्यते न स पापेन, पद्मपत्रमिवाम्भसा॥5.10॥", 
        
        "कायेन मनसा बुद्ध्या, केवलैरिन्द्रियैरपि।\nयोगिनः(ख्) कर्म कुर्वन्ति, सङ्गं(न्) त्यक्त्वात्मशुद्धये॥5.11॥",
         
        "युक्तः(ख्) कर्मफलं(न्) त्यक्त्वा, शान्तिमाप्नोति नैष्ठिकीम्।\nअयुक्तः(ख्) कामकारेण, फले सक्तो निबध्यते॥5.12॥",
        
        "सर्वकर्माणि मनसा, सन्न्यस्यास्ते सुखं(व्ँ) वशी।\nनवद्वारे पुरे देही, नैव कुर्वन्न कारयन्॥5.13॥",
        
        "न कर्तृत्वं(न्) न कर्माणि, लोकस्य सृजति प्रभुः।\nन कर्मफलसंयोगं(म्), स्वभावस्तु प्रवर्तते॥5.14॥",
        
        "नादत्ते कस्यचित्पापं(न्), न चैव सुकृतं(व्ँ) विभुः।\nअज्ञानेनावृतं(ञ्) ज्ञानं(न्), तेन मुह्यन्ति जन्तवः॥5.15॥",
        
        "ज्ञानेन तु तदज्ञानं(य्ँ), येषां(न्) नाशितमात्मनः।\nतेषामादित्यवज्ज्ञानं(म्), प्रकाशयति तत्परम्॥5.16॥",
        
        "तद्बुद्धयस्तदात्मान:(स्), तन्निष्ठास्तत्परायणाः।\nगच्छन्त्यपुनरावृत्तिं(ञ्), ज्ञाननिर्धूतकल्मषाः॥5.17॥",
        
        "विद्याविनयसम्पन्ने, ब्राह्मणे गवि हस्तिनि।\nशुनि चैव श्वपाके च, पण्डिताः(स्) समदर्शिनः॥5.18॥",
        
        "इहैव तैर्जितः(स्) सर्गो, येषां(म्) साम्ये स्थितं(म्) मनः।\nनिर्दोषं(म्) हि समं(म्) ब्रह्म, तस्माद् ब्रह्मणि ते स्थिताः॥5.19॥",
        
        "न प्रहृष्येत्प्रियं(म्) प्राप्य, नोद्विजेत्प्राप्य चाप्रियम्।\nस्थिरबुद्धिरसम्मूढो, ब्रह्मविद् ब्रह्मणि स्थितः॥5.20॥",
        
        "बाह्यस्पर्शेष्वसक्तात्मा, विन्दत्यात्मनि यत्सुखम्।\nस ब्रह्मयोगयुक्तात्मा, सुखमक्षयमश्नुते॥5.21॥",
        
        "ये हि संस्पर्शजा भोगा, दुःखयोनय एव ते।\nआद्यन्तवन्तः(ख्) कौन्तेय, न तेषु रमते बुधः॥5.22॥",
        
        "शक्नोतीहैव यः(स्) सोढुं(म्), प्राक्शरीरविमोक्षणात्।\nकामक्रोधोद्भवं(व्ँ) वेगं(म्), स युक्तः(स्) स सुखी नरः॥5.23॥",
        
        "योऽन्तःसुखोऽन्तराराम:(स्), तथान्तर्ज्योतिरेव यः।\nस योगी ब्रह्मनिर्वाणं(म्), ब्रह्मभूतोऽधिगच्छति॥5.24॥",
        
        "लभन्ते ब्रह्मनिर्वाणम्, ऋषयः क्षीणकल्मषाः।\nछिन्नद्वैधा यतात्मानः(स्), सर्वभूतहिते रताः॥5.25॥",
        
        "कामक्रोधवियुक्तानां(य्ँ), यतीनां(य्ँ) यतचेतसाम्।\nअभितो ब्रह्मनिर्वाणं(व्ँ), वर्तते विदितात्मनाम्॥5.26॥",
        
        "स्पर्शान्कृत्वा बहिर्बाह्यांश्, चक्षुश्चैवान्तरे भ्रुवोः।\nप्राणापानौ समौ कृत्वा, नासाभ्यन्तरचारिणौ॥5.27॥",
        
        "यतेन्द्रियमनोबुद्धि:(र्), मुनिर्मोक्षपरायणः।\nविगतेच्छाभयक्रोधो, यः(स्) सदा मुक्त एव सः॥5.28॥",
        
        "भोक्तारं(य्ँ) यज्ञतपसां(म्), सर्वलोकमहेश्वरम्।\nसुहृदं(म्) सर्वभूतानां(ञ्), ज्ञात्वा मां(म्) शान्तिमृच्छति॥5.29॥",
        
        "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु ब्रह्मविद्यायां(य्ँ) योगशास्त्रे\nश्रीकृष्णार्जुनसंवादे कर्मसन्न्यासयोगो नाम पञ्चमोऽध्याय:॥",
      ],
      "adhyaya-6" : [
      
        "श्रीभगवानुवाच\nअनाश्रितः(ख्) कर्मफलं(ङ्), कार्यं(ङ्) कर्म करोति यः।\nस सन्न्यासी च योगी च, न निरग्निर्न चाक्रियः॥6.1॥",
        
        "यं(म्) सन्न्यासमिति प्राहु:(र्), योगं(न्) तं(व्ँ) विद्धि पाण्डव।\nन ह्यसन्न्यस्तसङ्कल्पो, योगी भवति कश्चन॥6.2॥",
        
        "आरुरुक्षोर्मुनेर्योगं(ङ्), कर्म कारणमुच्यते।\nयोगारूढस्य तस्यैव, शमः(ख्) कारणमुच्यते॥6.3॥",
        
        "यदा हि नेन्द्रियार्थेषु, न कर्मस्वनुषज्जते।\nसर्वसङ्कल्पसन्न्यासी, योगारूढस्तदोच्यते॥6.4॥",
        
        "उद्धरेदात्मनात्मानं(न्), नात्मानमवसादयेत्।\nआत्मैव ह्यात्मनो बन्धु:(र्), आत्मैव रिपुरात्मनः ॥6.5॥",
        
        "बन्धुरात्मात्मनस्तस्य, येनात्मैवात्मना जितः।\nअनात्मनस्तु शत्रुत्वे, वर्तेतात्मैव शत्रुवत्॥6.6॥",
        
        "जितात्मनः(फ्) प्रशान्तस्य, परमात्मा समाहितः।\nशीतोष्णसुखदुःखेषु, तथा मानापमानयोः॥6.7॥",
         
        "ज्ञानविज्ञानतृप्तात्मा, कूटस्थो विजितेन्द्रियः।\nयुक्त इत्युच्यते योगी, समलोष्टाश्मकाञ्चनः॥6.8॥",
        
        "सुहृन्मित्रार्युदासीन, मध्यस्थद्वेष्यबन्धुषु।\nसाधुष्वपि च पापेषु, समबुद्धिर्विशिष्यते॥6.9॥",
        
        "योगी युञ्जीत सततम्, आत्मानं(म्) रहसि स्थितः।\nएकाकी यतचित्तात्मा, निराशीरपरिग्रहः॥6.10॥",
        
        "शुचौ देशे प्रतिष्ठाप्य, स्थिरमासनमात्मनः।\nनात्युच्छ्रितं(न्) नातिनीचं(ञ्), चैलाजिनकुशोत्तरम्॥6.11॥",
        
        "तत्रैकाग्रं(म्) मनः(ख्) कृत्वा, यतचित्तेन्द्रियक्रियः।\nउपविश्यासने युञ्ज्याद्, योगमात्मविशुद्धये॥6.12॥",
        
        "समं(ङ्) कायशिरोग्रीवं(न्), धारयन्नचलं(म्) स्थिरः।\nसम्प्रेक्ष्य नासिकाग्रं(म्) स्वं(न्), दिशश्चानवलोकयन्॥6.13॥",
        
        "प्रशान्तात्मा विगतभी:(र्), ब्रह्मचारिव्रते स्थितः।\nमनः(स्) संयम्य मच्चित्तो, युक्त आसीत मत्परः॥6.14॥",
        
        "युञ्जन्नेवं(म्) सदात्मानं(य्ँ), योगी नियतमानसः।\nशान्तिं(न्) निर्वाणपरमां(म्), मत्संस्थामधिगच्छति॥6.15॥",
        
        "नात्यश्नतस्तु योगोऽस्ति, न चैकान्तमनश्नतः।\nन चातिस्वप्नशीलस्य, जाग्रतो नैव चार्जुन॥6.16॥",
        
        "युक्ताहारविहारस्य,  युक्तचेष्टस्य कर्मसु।\nयुक्तस्वप्नावबोधस्य, योगो भवति दुःखहा॥6.17॥",
        
        "यदा विनियतं(ञ्) चित्तम्, आत्मन्येवावतिष्ठते।\nनिःस्पृहः(स्) सर्वकामेभ्यो, युक्त इत्युच्यते तदा॥6.18॥",
        
        "यथा दीपो निवातस्थो, नेङ्गते सोपमा स्मृता।\nयोगिनो यतचित्तस्य, युञ्जतो योगमात्मनः॥6.19॥",
        
        "यत्रोपरमते चित्तं(न्), निरुद्धं(य्ँ) योगसेवया।\nयत्र चैवात्मनात्मानं(म्), पश्यन्नात्मनि तुष्यति॥6.20॥",
        
        "सुखमात्यन्तिकं(य्ँ) यत्तद्, बुद्धिग्राह्यमतीन्द्रियम्।\nवेत्ति यत्र न चैवायं(म्), स्थितश्चलति तत्त्वतः॥6.21॥",
        
        "यं(ल्ँ) लब्ध्वा चापरं(ल्ँ) लाभं(म्), मन्यते नाधिकं(न्) ततः।\nयस्मिन्स्थितो न दुःखेन, गुरुणापि विचाल्यते॥6.22॥",
        
        "तं(व्ँ) विद्याद् दुःखसंयोग, वियोगं(य्ँ) योगसञ्ज्ञितम्।\nस निश्चयेन योक्तव्यो, योगोऽनिर्विण्णचेतसा॥6.23॥",
        
        "सङ्कल्पप्रभवान्कामांस्, त्यक्त्वा सर्वानशेषतः।\nमनसैवेन्द्रियग्रामं(व्ँ), विनियम्य समन्ततः॥6.24॥",
        
        "शनैः(श्) शनैरुपरमेद्, बुद्ध्या धृतिगृहीतया।\nआत्मसंस्थं(म्) मनः(ख्) कृत्वा, न किञ्चिदपि चिन्तयेत्॥6.25॥",
        
        "यतो यतो निश्चरति, मनश्चञ्चलमस्थिरम्।\nततस्ततो नियम्यैतद्, आत्मन्येव वशं(न्) नयेत् ॥6.26॥",
        
        "प्रशान्तमनसं(म्) ह्येनं(य्ँ), योगिनं(म्) सुखमुत्तमम्।\nउपैति शान्तरजसं(म्), ब्रह्मभूतमकल्मषम्॥6.27॥",
        
        "युञ्जन्नेवं(म्) सदात्मानं(य्ँ), योगी विगतकल्मषः।\nसुखेन ब्रह्मसंस्पर्शम्, अत्यन्तं(म्) सुखमश्नुते॥6.28॥",
        
        "सर्वभूतस्थमात्मानं(म्), सर्वभूतानि चात्मनि।\nईक्षते योगयुक्तात्मा, सर्वत्र समदर्शनः॥6.29॥",
        
        "यो मां(म्) पश्यति सर्वत्र, सर्वं(ञ्) च मयि पश्यति।\nतस्याहं(न्) न प्रणश्यामि, स च मे न प्रणश्यति॥6.30॥",
        
        "सर्वभूतस्थितं(य्ँ) यो मां(म्), भजत्येकत्वमास्थितः।\nसर्वथा वर्तमानोऽपि, स योगी मयि वर्तते॥6.31॥",
        
        "आत्मौपम्येन सर्वत्र, समं(म्) पश्यति योऽर्जुन।\nसुखं(व्ँ) वा यदि वा दुःखं(म्), स योगी परमो मतः॥6.32॥",
        
        "अर्जुन उवाच\n योऽयं(य्ँ) योगस्त्वया प्रोक्तः(स्), साम्येन मधुसूदन।\nएतस्याहं(न्) न पश्यामि, चञ्चलत्वात्स्थितिं(म्) स्थिराम्॥6.33॥",
        
        "चञ्चलं(म्) हि मनः(ख्) कृष्ण, प्रमाथि बलवद्दृढम् ।\nतस्याहं(न्) निग्रहं(म्) मन्ये, वायोरिव सुदुष्करम् ॥6.34॥",
        
        "श्रीभगवानुवाच\nअसंशयं(म्) महाबाहो, मनो दुर्निग्रहं(ञ्) चलम्।\nअभ्यासेन तु कौन्तेय, वैराग्येण च गृह्यते॥6.35॥",
        
        "असंयतात्मना योगो, दुष्प्राप इति मे मतिः।\nवश्यात्मना तु यतता, शक्योऽवाप्तुमुपायतः॥6.36॥",
        
        "अर्जुन उवाच\nअयतिः(श्) श्रद्धयोपेतो, योगाच्चलितमानसः।\nअप्राप्य योगसंसिद्धिं(ङ्), कां(ङ्) गतिं (ङ्) कृष्ण गच्छति॥6.37॥",
        
        "कच्चिन्नोभयविभ्रष्ट:(श्), छिन्नाभ्रमिव नश्यति।\nअप्रतिष्ठो महाबाहो, विमूढो ब्रह्मणः(फ्) पथि॥6.38॥",
        
        "एतन्मे संशयं(ङ्) कृष्ण, छेत्तुमर्हस्यशेषतः।\nत्वदन्यः(स्) संशयस्यास्य, छेत्ता न ह्युपपद्यते॥6.39॥",
        
        "श्रीभगवानुवाच\nपार्थ नैवेह नामुत्र, विनाशस्तस्य विद्यते।\nन हि कल्याणकृत्कश्चिद्, दुर्गतिं(न्) तात गच्छति॥6.40॥",
        
        "प्राप्य पुण्यकृतां(ल्ँ) लोकान्, उषित्वा शाश्वतीः(स्) समाः।\nशुचीनां(म्) श्रीमतां(ङ्) गेहे, योगभ्रष्टोऽभिजायते॥6.41॥",
        
        "अथवा योगिनामेव, कुले भवति धीमताम्।\nएतद्धि दुर्लभतरं(ल्ँ), लोके जन्म यदीदृशम्॥6.42॥",
         
        "तत्र तं(म्) बुद्धिसंयोगं(ल्ँ), लभते पौर्वदेहिकम्।\nयतते च ततो भूयः(स्), संसिद्धौ कुरुनन्दन॥6.43॥",
        
        "पूर्वाभ्यासेन तेनैव, ह्रियते ह्यवशोऽपि सः।\nजिज्ञासुरपि योगस्य, शब्दब्रह्मातिवर्तते॥6.44॥",
        
        "प्रयत्नाद्यतमानस्तु, योगी संशुद्धकिल्बिषः।\nअनेकजन्मसंसिद्ध:(स्), ततो याति परां(ङ्) गतिम्॥6.45॥",
        
        "तपस्विभ्योऽधिको योगी, ज्ञानिभ्योऽपि मतोऽधिकः।\nकर्मिभ्यश्चाधिको योगी, तस्माद्योगी भवार्जुन॥6.46॥",
        
        "योगिनामपि सर्वेषां(म्), मद्गतेनान्तरात्मना।\nश्रद्धावान्भजते यो मां(म्), स मे युक्ततमो मतः॥6.47॥",
        
        "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु ब्रह्मविद्यायां(य्ँ) योगशास्त्रे\nश्रीकृष्णार्जुनसंवादे आत्मसंयमयोगो नाम षष्ठोऽध्यायः॥"
      ],
      "adhyaya-7" : [
      
        "श्रीभगवानुवाच\nमय्यासक्तमनाः(फ्) पार्थ, योगं(य्ँ) युञ्जन्मदाश्रयः।\nअसंशयं(म्) समग्रं(म्) मां(य्ँ), यथा ज्ञास्यसि तच्छृणु॥7.1॥",
        
        "ज्ञानं(न्) तेऽहं(म्) सविज्ञानम्, इदं(व्ँ) वक्ष्याम्यशेषतः।\nयज्ज्ञात्वा नेह भूयोऽन्यज्, ज्ञातव्यमवशिष्यते॥7.2॥",
        
        "मनुष्याणां(म्) सहस्रेषु, कश्चिद्यतति सिद्धये।\nयततामपि सिद्धानां(ङ्), कश्चिन्मां(व्ँ) वेत्ति तत्त्वतः॥7.3॥",
        
        "भूमिरापोऽनलो वायुः(ख्), खं(म्) मनो बुद्धिरेव च।\nअहङ्कार इतीयं(म्) मे, भिन्ना प्रकृतिरष्टधा॥7.4॥",
        
        "अपरेयमितस्त्वन्यां(म्), प्रकृतिं(व्ँ) विद्धि मे पराम्।\nजीवभूतां(म्) महाबाहो, ययेदं(न्) धार्यते जगत्॥7.5॥",
        
        "एतद्योनीनि भूतानि, सर्वाणीत्युपधारय।\nअहं(ङ्) कृत्स्नस्य जगतः(फ्), प्रभवः(फ्) प्रलयस्तथा॥7.6॥",
        
        "मत्तः(फ्) परतरं(न्) नान्यत्, किञ्चिदस्ति धनञ्जय।\nमयि सर्वमिदं(म्) प्रोतं(म्), सूत्रे मणिगणा इव॥7.7॥",
        
        "रसोऽहमप्सु कौन्तेय, प्रभास्मि शशिसूर्ययोः।\nप्रणवः(स्) सर्ववेदेषु, शब्दः(ख्) खे पौरुषं(न्) नृषु॥7.8॥",
        
        "पुण्यो गन्धः(फ्) पृथिव्यां(ञ्) च, तेजश्चास्मि विभावसौ।\nजीवनं(म्) सर्वभूतेषु, तपश्चास्मि तपस्विषु॥7.9॥",
        
        "बीजं(म्) मां(म्) सर्वभूतानां(व्ँ), विद्धि पार्थ सनातनम्।\nबुद्धिर्बुद्धिमतामस्मि, तेजस्तेजस्विनामहम्॥7.10॥",
        
        "बलं(म्) बलवतां(ञ्) चाहं(ङ्), कामरागविवर्जितम्।\nधर्माविरुद्धो भूतेषु, कामोऽस्मि भरतर्षभ॥7.11॥",
        
        "ये चैव सात्त्विका भावा, राजसास्तामसाश्च ये।\nमत्त एवेति तान्विद्धि, न त्वहं(न्) तेषु ते मयि॥7.12॥",
        
        "त्रिभिर्गुणमयैर्भावै:(र्), एभिः(स्) सर्वमिदं(ञ्) जगत्।\nमोहितं(न्) नाभिजानाति, मामेभ्यः(फ्) परमव्ययम्॥7.13॥",
        
        "दैवी ह्येषा गुणमयी, मम माया दुरत्यया।\nमामेव ये प्रपद्यन्ते, मायामेतां(न्) तरन्ति ते॥7.14॥",
         
        "न मां(न्) दुष्कृतिनो मूढाः(फ्), प्रपद्यन्ते नराधमाः।\nमाययापहृतज्ञाना, आसुरं(म्) भावमाश्रिताः॥7.15॥",
        
        "चतुर्विधा भजन्ते मां(ञ्), जनाः(स्) सुकृतिनोऽर्जुन।\nआर्तो जिज्ञासुरर्थार्थी, ज्ञानी च भरतर्षभ॥7.16॥",
        
        "तेषां(ञ्) ज्ञानी नित्ययुक्त, एकभक्तिर्विशिष्यते।\nप्रियो हि ज्ञानिनोऽत्यर्थम्, अहं (म्) स च मम प्रियः॥7.17॥",
        
        "उदाराः(स्) सर्व एवैते, ज्ञानी त्वात्मैव मे मतम्।\nआस्थितः(स्) स हि युक्तात्मा, मामेवानुत्तमां(ङ्) गतिम्॥7.18॥",
        
        "बहूनां(ञ्) जन्मनामन्ते, ज्ञानवान्मां(म्) प्रपद्यते।\nवासुदेवः(स्) सर्वमिति, स महात्मा सुदुर्लभः॥7.19॥",
        
        "कामैस्तैस्तैर्हृतज्ञानाः(फ्), प्रपद्यन्तेऽन्यदेवताः।\nतं(न्) तं(न्) नियममास्थाय, प्रकृत्या नियताः(स्) स्वया॥7.20॥",
        
        "यो यो यां(य्ँ) यां(न्) तनुं(म्) भक्तः(श्), श्रद्धयार्चितुमिच्छति।\nतस्य तस्याचलां(म्) श्रद्धां(न्), तामेव विदधाम्यहम्॥7.21॥",
        
        "स तया श्रद्धया युक्त:(स्), तस्याराधनमीहते।\nलभते च ततः(ख्) कामान्, मयैव विहितान्हि तान्॥7.22॥",
        
        "अन्तवत्तु फलं(न्) तेषां(न्), तद्भवत्यल्पमेधसाम्।\nदेवान्देवयजो यान्ति, मद्भक्ता यान्ति मामपि॥7.23॥",
        
        "अव्यक्तं(व्ँ) व्यक्तिमापन्नं(म्), मन्यन्ते मामबुद्धयः।\nपरं(म्) भावमजानन्तो, ममाव्ययमनुत्तमम्॥7.24॥",
        
        "नाहं(म्) प्रकाशः(स्) सर्वस्य, योगमायासमावृतः।\nमूढोऽयं(न्) नाभिजानाति, लोको मामजमव्ययम्॥7.25॥",
        
        "वेदाहं(म्) समतीतानि, वर्तमानानि चार्जुन।\nभविष्याणि च भूतानि, मां(न्) तु वेद न कश्चन॥7.26॥",
        
        "इच्छाद्वेषसमुत्थेन, द्वन्द्वमोहेन भारत।\nसर्वभूतानि सम्मोहं(म्), सर्गे यान्ति परन्तप॥7.27॥",
        
        "येषां(न्) त्वन्तगतं(म्) पापं(ञ्), जनानां(म्) पुण्यकर्मणाम्।\nते द्वन्द्वमोहनिर्मुक्ता, भजन्ते मां(न्) दृढव्रताः॥7.28॥",
        
        "जरामरणमोक्षाय, मामाश्रित्य यतन्ति ये।\nते ब्रह्म तद्विदुः(ख्) कृत्स्नम्, अध्यात्मं(ङ्) कर्म चाखिलम्॥7.29॥",
        
        "साधिभूताधिदैवं(म्) मां(म्), साधियज्ञं(ञ्) च ये विदुः।\nप्रयाणकालेऽपि च मां(न्), ते विदुर्युक्तचेतसः॥7.30॥",
        
        "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु ब्रह्मविद्यायां (य्ँ) योगशास्त्रे\nश्रीकृष्णार्जुनसंवादे ज्ञानविज्ञानयोगो नाम सप्तमोऽध्यायः॥"
      ],
      "adhyaya-8" : [
      
        "अर्जुन उवाच\nकिं(न्) तद्ब्रह्म किमध्यात्मं(ङ्), किं(ङ्) कर्म पुरुषोत्तम।\nअधिभूतं(ञ्) च किं(म्) प्रोक्तम्, अधिदैवं(ङ्) किमुच्यते॥8.1॥",
        
        "अधियज्ञ:(ख्) कथं(ङ्) कोऽत्र, देहेऽस्मिन्मधुसूदन।\nप्रयाणकाले च कथं(ञ्), ज्ञेयोऽसि नियतात्मभिः॥8.2॥",
        
        "श्रीभगवानुवाच\n अक्षरं(म्) ब्रह्म परमं(म्), स्वभावोऽध्यात्ममुच्यते।\nभूतभावोद्भवकरो, विसर्गः(ख्) कर्मसञ्ज्ञितः॥8.3॥",
        
        "अधिभूतं(ङ्) क्षरो भावः(फ्), पुरुषश्चाधिदैवतम्।\nअधियज्ञोऽहमेवात्र, देहे देहभृतां(व्ँ) वर॥8.4॥",
        
        "अन्तकाले च मामेव, स्मरन्मुक्त्वा कलेवरम्।\nयः(फ्) प्रयाति स मद्भावं(य्ँ), याति नास्त्यत्र संशयः॥8.5॥",
        
        "यं(य्ँ) यं(व्ँ) वापि स्मरन्भावं(न्), त्यजत्यन्ते कलेवरम्।\nतं(न्) तमेवैति कौन्तेय, सदा तद्भावभावितः॥8.6॥",
        
        "तस्मात्सर्वेषु कालेषु, मामनुस्मर युध्य च।\nमय्यर्पितमनोबुद्धि:(र्), मामेवैष्यस्यसंशयम्॥8.7॥",
        
        "अभ्यासयोगयुक्तेन, चेतसा नान्यगामिना।\nपरमं(म्) पुरुषं(न्) दिव्यं(य्ँ), याति पार्थानुचिन्तयन्॥8.8॥",
        
        "कविं(म्) पुराणमनुशासितारम्,\nअणोरणीयांसमनुस्मरेद्यः ।\nसर्वस्य धातारमचिन्त्यरूपम्,\nआदित्यवर्णं(न्) तमसः(फ्) परस्तात्॥8.9॥",
        
        "प्रयाणकाले मनसाचलेन,\nभक्त्या युक्तो योगबलेन चैव।\nभ्रुवोर्मध्ये प्राणमावेश्य सम्यक्,\nस तं(म्) परं(म्) पुरुषमुपैति दिव्यम्॥8.10॥",
        
        "यदक्षरं(व्ँ) वेदविदो वदन्ति,\nविशन्ति यद्यतयो वीतरागाः।\nयदिच्छन्तो ब्रह्मचर्यं(ञ्) चरन्ति,\nतत्ते पदं(म्) सङ्ग्रहेण प्रवक्ष्ये॥8.11॥",
        
        "सर्वद्वाराणि संयम्य, मनो हृदि निरुध्य च।\nमूर्ध्न्याधायात्मनः(फ्) प्राणम्, आस्थितो योगधारणाम्॥8.12॥",
        
        "ओमित्येकाक्षरं(म्) ब्रह्म, व्याहरन्मामनुस्मरन्।\nय:(फ्) प्रयाति त्यजन्देहं(म्), स याति परमां(ङ्) गतिम्॥8.13॥",
        
        "अनन्यचेताः(स्) सततं(य्ँ), यो मां(म्) स्मरति नित्यशः।\nतस्याहं(म्) सुलभः(फ्) पार्थ, नित्ययुक्तस्य योगिनः॥8.14॥",
        
        "मामुपेत्य पुनर्जन्म, दुःखालयमशाश्वतम्।\nनाप्नुवन्ति महात्मानः(स्), संसिद्धिं(म्) परमां(ङ्) गताः॥8.15॥",
        
        "आब्रह्मभुवनाल्लोकाः(फ्), पुनरावर्तिनोऽर्जुन।\nमामुपेत्य तु कौन्तेय, पुनर्जन्म न विद्यते॥8.16॥",
        
        "सहस्रयुगपर्यन्तम्, अहर्यद्ब्रह्मणो विदुः।\nरात्रिं(य्ँ) युगसहस्रान्तां(न्), तेऽहोरात्रविदो जनाः॥8.17॥",
        
        "अव्यक्ताद्व्यक्तयः(स्) सर्वाः(फ्), प्रभवन्त्यहरागमे।\nरात्र्यागमे प्रलीयन्ते, तत्रैवाव्यक्तसञ्ज्ञके॥8.18॥",
        
        "भूतग्रामः(स्) स एवायं(म्), भूत्वा भूत्वा प्रलीयते।\nरात्र्यागमेऽवशः(फ्) पार्थ, प्रभवत्यहरागमे॥8.19॥",
        
        "परस्तस्मात्तु भावोऽन्यो-ऽव्यक्तोऽव्यक्तात्सनातनः।\nयः(स्) स सर्वेषु भूतेषु, नश्यत्सु न विनश्यति॥8.20॥",
        
        "अव्यक्तोऽक्षर इत्युक्त:(स्), तमाहुः(फ्) परमां(ङ्) गतिम्।\nयं(म्) प्राप्य न निवर्तन्ते, तद्धाम परमं(म्) मम॥8.21॥",
        
        "पुरुषः(स्) स परः(फ्) पार्थ, भक्त्या लभ्यस्त्वनन्यया।\nयस्यान्तःस्थानि भूतानि, येन सर्वमिदं(न्) ततम्॥8.22॥",
        
        "यत्र काले त्वनावृत्तिम्, आवृत्तिं(ञ्) चैव योगिनः।\nप्रयाता यान्ति तं(ङ्) कालं(व्ँ), वक्ष्यामि भरतर्षभ॥8.23॥",
        
        "अग्निर्ज्योतिरहः(श्) शुक्लः(ष्), षण्मासा उत्तरायणम्।\nतत्र प्रयाता गच्छन्ति, ब्रह्म ब्रह्मविदो जनाः॥8.24॥",
        
        "धूमो रात्रिस्तथा कृष्णः(ष्), षण्मासा दक्षिणायनम्।\nतत्र चान्द्रमसं(ञ्) ज्योति:(र्), योगी प्राप्य निवर्तते॥8.25॥",
        
        "शुक्लकृष्णे गती ह्येते, जगतः(श्) शाश्वते मते।\nएकया यात्यनावृत्तिम्, अन्ययावर्तते पुनः॥8.26॥",
        
        "नैते सृती पार्थ जानन्, योगी मुह्यति कश्चन।\nतस्मात्सर्वेषु कालेषु, योगयुक्तो भवार्जुन॥8.27॥",
         
        "वेदेषु यज्ञेषु तपः(स्) सु चैव,\nदानेषु यत्पुण्यफलं(म्) प्रदिष्टम्।\nअत्येति तत्सर्वमिदं(व्ँ) विदित्वा,\n योगी परं(म्) स्थानमुपैति चाद्यम्॥8.28॥",
        
        "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु ब्रह्मविद्यायां(य्ँ) योगशास्त्रे\nश्रीकृष्णार्जुनसंवादे अक्षरब्रह्मयोगो नाम अष्टमोऽध्यायः॥"
      ],
      "adhyaya-9" : [
      
        "श्रीभगवानुवाच\nइदं(न्) तु ते गुह्यतमं(म्), प्रवक्ष्याम्यनसूयवे।\nज्ञानं(व्ँ) विज्ञानसहितं(य्ँ), यज्ज्ञात्वा मोक्ष्यसेऽशुभात्॥9.1॥",
        
        "राजविद्या राजगुह्यं(म्), पवित्रमिदमुत्तमम्।\nप्रत्यक्षावगमं(न्) धर्म्यं(म्), सुसुखं(ङ्) कर्तुमव्ययम्॥9.2॥",
        
        "अश्रद्दधानाः(फ्) पुरुषा, धर्मस्यास्य परन्तप।\nअप्राप्य  मां(न्)  निवर्तन्ते,  मृत्युसंसारवर्त्मनि॥9.3॥",
        
        "मया ततमिदं(म्) सर्वं(ञ्), जगदव्यक्तमूर्तिना।\nमत्स्थानि  सर्वभूतानि,  न  चाहं(न्)  तेष्ववस्थितः॥9.4॥",
        
        "न च मत्स्थानि भूतानि, पश्य मे योगमैश्वरम्।\nभूतभृन्न   च   भूतस्थो,   ममात्मा    भूतभावनः॥9.5॥",
        
        "यथाकाशस्थितो नित्यं(व्ँ), वायुः(स्) सर्वत्रगो महान्।\nतथा   सर्वाणि    भूतानि,   मत्स्थानीत्युपधारय॥9.6॥",
        
        "सर्वभूतानि कौन्तेय, प्रकृतिं(य्ँ) यान्ति मामिकाम्।\nकल्पक्षये  पुनस्तानि,  कल्पादौ  विसृजाम्यहम्॥9.7॥",
        
        "प्रकृतिं(म्) स्वामवष्टभ्य, विसृजामि पुनः(फ्) पुनः।\nभूतग्राममिमं(ङ्) कृत्स्नम्, अवशं(म्) प्रकृतेर्वशात्॥9.8॥",
        
        "न च मां(न्) तानि कर्माणि, निबध्नन्ति धनञ्जय।\nउदासीनवदासीनम्,  असक्तं(न्)  तेषु  कर्मसु॥9.9॥",
        
        "मयाध्यक्षेण प्रकृतिः(स्), सूयते सचराचरम्।\nहेतुनानेन     कौन्तेय,     जगद्विपरिवर्तते॥9.10॥",
        
        "अवजानन्ति मां(म्) मूढा, मानुषीं(न्) तनुमाश्रितम्।\nपरं(म्)   भावमजानन्तो,   मम   भूतमहेश्वरम्॥9.11॥",
        
        "मोघाशा मोघकर्माणो, मोघज्ञाना विचेतसः।\nराक्षसीमासुरीं(ञ्) चैव, प्रकृतिं(म्) मोहिनीं(म्) श्रिताः॥9.12॥",
        
        "महात्मानस्तु मां(म्) पार्थ, दैवीं(म्) प्रकृतिमाश्रिताः।\nभजन्त्यनन्यमनसो,    ज्ञात्वा    भूतादिमव्ययम्॥9.13॥",
        
        "सततं(ङ्) कीर्तयन्तो मां(य्ँ), यतन्तश्च दृढव्रताः।\nनमस्यन्तश्च  मां(म्)  भक्त्या,  नित्ययुक्ता   उपासते॥9.14॥",
        
        "ज्ञानयज्ञेन चाप्यन्ये, यजन्तो मामुपासते।\nएकत्वेन     पृथक्त्वेन,    बहुधा    विश्वतोमुखम्॥9.15॥",
        
        "अहं(ङ्) क्रतुरहं(य्ँ) यज्ञः(स्), स्वधाहमहमौषधम्।\nमन्त्रोऽहमहमेवाज्यम्,    अहमग्निरहं(म्)   हुतम्॥9.16॥",
        
        "पिताहमस्य जगतो, माता धाता पितामहः।\nवेद्यं(म्)    पवित्रमोङ्कार, ऋक्साम   यजुरेव    च॥9.17॥",
        
        "गतिर्भर्ता प्रभुः(स्) साक्षी, निवासः(श्) शरणं(म्) सुहृत्।\nप्रभवः(फ्) प्रलयः(स्) स्थानं(न्), निधानं(म्) बीजमव्ययम्॥9.18॥",
        
        "तपाम्यहमहं(व्ँ) वर्षं(न्), निगृह्णाम्युत्सृजामि च।\nअमृतं(ञ्)    चैव    मृत्युश्च,   सदसच्चाहमर्जुन॥9.19॥",
        
        "त्रैविद्या मां(म्) सोमपाः(फ्) पूतपापा,\nयज्ञैरिष्ट्वा स्वर्गतिं(म्) प्रार्थयन्ते।\nते पुण्यमासाद्य सुरेन्द्रलोकम्\nअश्नन्ति    दिव्यान्दिवि    देवभोगान्॥9.20॥",
        
        "ते तं(म्) भुक्त्वा स्वर्गलोकं(व्ँ) विशालं(ङ्),\nक्षीणे पुण्ये मर्त्यलोकं(व्ँ)  विशन्ति।\nएवं(न्)  त्रयीधर्ममनुप्रपन्ना,\nगतागतं(ङ्)    कामकामा    लभन्ते॥9.21॥",
        
        "अनन्याश्चिन्तयन्तो मां(य्ँ), ये जनाः(फ्) पर्युपासते।\nतेषां(न्) नित्याभियुक्तानां(य्ँ), योगक्षेमं(व्ँ) वहाम्यहम्॥9.22॥",
        
        "येऽप्यन्यदेवता भक्ता, यजन्ते श्रद्धयान्विताः।\nतेऽपि  मामेव  कौन्तेय,  यजन्त्यविधिपूर्वकम्॥9.23॥",
        
        "अहं(म्) हि सर्वयज्ञानां(म्), भोक्ता च प्रभुरेव च।\nन  तु  मामभिजानन्ति,  तत्त्वेनातश्च्यवन्ति ते॥9.24॥",
        
        "यान्ति देवव्रता देवान्, पितॄन्यान्ति पितृव्रताः।\nभूतानि यान्ति भूतेज्या, यान्ति मद्याजिनोऽपि माम्॥9.25॥",
        
        "पत्रं(म्) पुष्पं(म्) फलं(न्) तोयं(य्ँ), यो मे भक्त्या प्रयच्छति।\nतदहं(म्)    भक्त्युपहृतम्,    अश्नामि    प्रयतात्मनः॥9.26॥",
        
        "यत्करोषि यदश्नासि, यज्जुहोषि ददासि यत्।\nयत्तपस्यसि  कौन्तेय,  तत्कुरुष्व  मदर्पणम्॥9.27॥",
        
        "शुभाशुभफलैरेवं(म्), मोक्ष्यसे कर्मबन्धनैः।\nसन्न्यासयोगयुक्तात्मा, विमुक्तो मामुपैष्यसि॥9.28॥",
        
        "समोऽहं(म्) सर्वभूतेषु, न मे द्वेष्योऽस्ति न प्रियः।\nये भजन्ति तु मां(म्) भक्त्या, मयि ते तेषु चाप्यहम्॥9.29॥",
         
        "अपि चेत्सुदुराचारो, भजते मामनन्यभाक्।\nसाधुरेव स मन्तव्यः(स्), सम्यग्व्यवसितो हि सः॥9.30॥",
        
        "क्षिप्रं(म्) भवति धर्मात्मा, शश्वच्छान्तिं(न्) निगच्छति।\nकौन्तेय  प्रतिजानीहि,  न  मे  भक्तः(फ्)  प्रणश्यति॥9.31॥",
        
        "मां(म्) हि पार्थ व्यपाश्रित्य, येऽपि स्युः(फ्) पापयोनयः।\nस्त्रियो वैश्यास्तथा शूद्रा:(स्), तेऽपि यान्ति परां(ङ्) गतिम्॥9.32॥",
        
        "किं(म्) पुनर्ब्राह्मणाः(फ्) पुण्या, भक्ता राजर्षयस्तथा।\nअनित्यमसुखं(ल्ँ) लोकम्, इमं(म्) प्राप्य भजस्व माम्॥9.33॥",
        
        "मन्मना भव मद्भक्तो, मद्याजी मां(न्) नमस्कुरु।\nमामेवैष्यसि युक्त्वैवम्, आत्मानं(म्) मत्परायणः॥9.34॥",
        
        "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु ब्रह्मविद्यायां(य्ँ) योगशास्त्रे\nश्रीकृष्णार्जुनसंवादे राजविद्याराजगुह्ययोगो नाम नवमोऽध्याय:।।",
      ],
      "adhyaya-10" : [
    
        "श्रीभगवानुवाच\nभूय एव महाबाहो,शृणु मे परमं(व्ँ) वचः।\nयत्तेऽहं(म्) प्रीयमाणाय, वक्ष्यामि हितकाम्यया॥10.1॥",
        
        "न मे विदुः(स्) सुरगणाः(फ्), प्रभवं(न्) न महर्षयः।\nअहमादिर्हि देवानां(म्), महर्षीणां(ञ्) च सर्वशः॥10.2॥",
        
        "यो मामजमनादिं(ञ्) च,वेत्ति लोकमहेश्वरम्।\nअसम्मूढः(स्) स मर्त्येषु, सर्वपापैः(फ्) प्रमुच्यते॥10.3॥",
        
        "बुद्धिर्ज्ञानमसम्मोहः, क्षमा सत्यं(न्) दमः(श्) शमः।\nसुखं(न्) दुःखं(म्) भवोऽभावो, भयं(ञ्) चाभयमेव च॥10.4॥",
        
        "अहिंसा समता तुष्टि:(स्), तपो दानं(य्ँ) यशोऽयशः।\nभवन्ति भावा भूतानां(म्),मत्त एव पृथग्विधाः॥10.5॥",
        
        "महर्षयः(स्) सप्त पूर्वे,चत्वारो मनवस्तथा।\nमद्भावा मानसा जाता, येषां(ल्ँ) लोक इमाः(फ्) प्रजाः॥10.6॥",
        
        "एतां(व्ँ) विभूतिं(य्ँ) योगं(ञ्) च, मम यो वेत्ति तत्त्वतः।\nसोऽविकम्पेन योगेन,युज्यते नात्र संशयः॥10.7॥",
        
        "अहं(म्) सर्वस्य प्रभवो, मत्तः(स्) सर्वं(म्) प्रवर्तते।\nइति मत्वा भजन्ते मां(म्), बुधा भावसमन्विताः॥10.8॥",
        
        "मच्चित्ता मद्गतप्राणा,बोधयन्तः(फ्) परस्परम्।\nकथयन्तश्च मां(न्) नित्यं(न्), तुष्यन्ति च रमन्ति च॥10.9॥",
        
        "तेषां(म्) सततयुक्तानां(म्), भजतां(म्) प्रीतिपूर्वकम्।\nददामि बुद्धियोगं(न्) तं(य्ँ), येन मामुपयान्ति ते॥10.10॥",
        
        "तेषामेवानुकम्पार्थम्,अहमज्ञानजं(न्) तमः।\nनाशयाम्यात्मभावस्थो, ज्ञानदीपेन भास्वता॥10.11॥",
        
        "अर्जुन उवाच\nपरं(म्) ब्रह्म परं(न्) धाम, पवित्रं(म्) परमं(म्) भवान्।\nपुरुषं(म्) शाश्वतं(न्) दिव्यम्, आदिदेवमजं(व्ँ) विभुम्॥10.12॥",
        
        "आहुस्त्वामृषयः(स्) सर्वे, देवर्षिर्नारदस्तथा।\nअसितो देवलो व्यासः(स्), स्वयं(ञ्) चैव ब्रवीषि मे॥10.13॥",
        
        "सर्वमेतदृतं(म्) मन्ये, यन्मां(व्ँ) वदसि केशव।\nन हि ते भगवन्व्यक्तिं(व्ँ), विदुर्देवा न दानवाः॥10.14॥",
        
        "स्वयमेवात्मनात्मानं(व्ँ), वेत्थ त्वं(म्) पुरुषोत्तम।\nभूतभावन भूतेश,देवदेव जगत्पते॥10.15॥",
        
        "वक्तुमर्हस्यशेषेण, दिव्या ह्यात्मविभूतयः।\nयाभिर्विभूतिभिर्लोकान्, इमांस्त्वं(व्ँ) व्याप्य तिष्ठसि॥10.16॥",
        
        "कथं(व्ँ) विद्यामहं(य्ँ) योगिंस्, त्वां(म्) सदा परिचिन्तयन्।\nकेषु केषु च भावेषु,चिन्त्योऽसि भगवन्मया॥10.17॥",
        
        "विस्तरेणात्मनो योगं(व्ँ), विभूतिं(ञ्) च जनार्दन।\nभूयः(ख्) कथय तृप्तिर्हि, शृण्वतो नास्ति मेऽमृतम्॥10.18॥",
        
        "श्रीभगवानुवाच\nहन्त ते कथयिष्यामि, दिव्या ह्यात्मविभूतयः।\nप्राधान्यतः(ख्) कुरुश्रेष्ठ, नास्त्यन्तो विस्तरस्य मे॥10.19॥",
        
        "अहमात्मा गुडाकेश, सर्वभूताशयस्थितः।\nअहमादिश्च मध्यं(ञ्) च, भूतानामन्त एव च॥10.20॥",
        
        "आदित्यानामहं(व्ँ) विष्णु:(र्), ज्योतिषां(म्) रविरंशुमान्।\nमरीचिर्मरुतामस्मि, नक्षत्राणामहं(म्) शशी॥10.21॥",
        
        "वेदानां(म्) सामवेदोऽस्मि, देवानामस्मि वासवः।\nइन्द्रियाणां(म्) मनश्चास्मि, भूतानामस्मि चेतना॥10.22॥",
        
        "रुद्राणां(म्) शङ्करश्चास्मि,वित्तेशो यक्षरक्षसाम्।\nवसूनां(म्) पावकश्चास्मि, मेरुः(श्) शिखरिणामहम्॥10.23॥",
        
        "पुरोधसां(ञ्) च मुख्यं(म्) मां(व्ँ), विद्धि पार्थ बृहस्पतिम्।\nसेनानीनामहं(म्) स्कन्दः(स्),सरसामस्मि सागरः॥10.24॥",
        
        "महर्षीणां(म्) भृगुरहं(ङ्),गिरामस्म्येकमक्षरम्।\nयज्ञानां(ञ्) जपयज्ञोऽस्मि, स्थावराणां(म्) हिमालयः॥10.25॥",
        
        "अश्वत्थः(स्) सर्ववृक्षाणां(न्),देवर्षीणां(ञ्) च नारदः।\nगन्धर्वाणां(ञ्) चित्ररथः(स्), सिद्धानां(ङ्) कपिलो मुनिः॥10.26॥",
        
        "उच्चैःश्रवसमश्वानां(व्ँ),विद्धि माममृतोद्भवम्।\nऐरावतं(ङ्) गजेन्द्राणां(न्), नराणां(ञ्) च नराधिपम्॥10.27॥",
        
        "आयुधानामहं(व्ँ) वज्रं(न्), धेनूनामस्मि कामधुक्।\nप्रजनश्चास्मि कन्दर्पः(स्), सर्पाणामस्मि वासुकिः॥10.28॥",
        
        "अनन्तश्चास्मि नागानां(व्ँ), वरुणो यादसामहम्।\nपितॄणामर्यमा चास्मि, यमः(स्) संयमतामहम्॥10.29॥",
        
        "प्रह्लादश्चास्मि दैत्यानां(ङ्), कालः(ख्) कलयतामहम्।\nमृगाणां(ञ्) च मृगेन्द्रोऽहं(व्ँ), वैनतेयश्च पक्षिणाम्॥10.30॥",
        
        "पवनः(फ्) पवतामस्मि, रामः(श्) शस्त्रभृतामहम्।\nझषाणां(म्) मकरश्चास्मि, स्रोतसामस्मि जाह्नवी॥10.31॥",
        
        "सर्गाणामादिरन्तश्च,मध्यं(ञ्) चैवाहमर्जुन।\nअध्यात्मविद्या विद्यानां(व्ँ), वादः(फ्) प्रवदतामहम्॥10.32॥",
        
        "अक्षराणामकारोऽस्मि, द्वन्द्व:(स्) सामासिकस्य च।\nअहमेवाक्षयः(ख्) कालो, धाताहं(व्ँ) विश्वतोमुखः॥10.33॥",
        
        "मृत्यु(स्) सर्वहरश्चाहम्, उद्भवश्च भविष्यताम्।\nकीर्तिः(श्) श्रीर्वाक्च नारीणां(म्), स्मृतिर्मेधा धृतिः क्षमा॥10.34॥",
        
        "बृहत्साम तथा साम्नां(ङ्),गायत्री छन्दसामहम्।\nमासानां(म्) मार्गशीर्षोऽहम्, ऋतूनां(ङ्) कुसुमाकरः॥10.35॥",
        
        "द्युतं(ञ्)    छलयतामस्मि,     तेजस्तेजस्विनामहम्।\nजयोऽस्मि व्यवसायोऽस्मि, सत्त्वं(म्) सत्त्ववतामहम्॥10.36॥",
        
        "वृष्णीनां(व्ँ) वासुदेवोऽस्मि, पाण्डवानां(न्) धनञ्जयः।\nमुनीनामप्यहं(व्ँ) व्यासः(ख्), कवीनामुशना कविः॥10.37॥",
        
        "दण्डो दमयतामस्मि, नीतिरस्मि जिगीषताम्।\nमौनं(ञ्) चैवास्मि गुह्यानां(ञ्), ज्ञानं(ञ्) ज्ञानवतामहम्॥10.38॥",
        
        "यच्चापि सर्वभूतानां(म्), बीजं(न्) तदहमर्जुन।\nन तदस्ति विना यत्स्यान्, मया भूतं(ञ्) चराचरम्॥10.39॥",
        
        "नान्तोऽस्ति मम दिव्यानां(व्ँ), विभूतीनां(म्) परन्तप।\nएष तूद्देशतः(फ्) प्रोक्तो,विभूतेर्विस्तरो मया॥10.40॥",
        
        "यद्यद्विभूतिमत्सत्त्वं(म्), श्रीमदूर्जितमेव वा।\nतत्तदेवावगच्छ त्वं(म्), मम तेजोंऽशसम्भवम्॥10.41॥",
        
        "अथवा बहुनैतेन, किं(ञ्) ज्ञातेन तवार्जुन।\nविष्टभ्याहमिदं(ङ्) कृत्स्नम्, एकांशेन स्थितो जगत्॥10.42॥",
        
        "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु ब्रह्मविद्यायां(य्ँ)\nयोगशास्त्रे श्रीकृष्णार्जुनसंवादे विभूतियोगो नाम दशमोऽध्यायः॥"
      ],
      "adhyaya-11" : [
    
        "अर्जुन उवाच\nमदनुग्रहाय परमं(ङ्), गुह्यमध्यात्मसञ्ज्ञितम्।\nयत्त्वयोक्तं(व्ँ) वचस्तेन, मोहोऽयं(व्ँ) विगतो मम॥11.1॥",
        
        "भवाप्ययौ हि भूतानां(म्), श्रुतौ विस्तरशो मया।\nत्वत्तः(ख्) कमलपत्राक्ष, माहात्म्यमपि चाव्ययम्॥11.2॥",
        
        "एवमेतद्यथात्थ त्वम्, आत्मानं(म्) परमेश्वर।\nद्रष्टुमिच्छामि ते रूपम्, ऐश्वरं(म्) पुरुषोत्तम॥11.3॥",
        
        "मन्यसे यदि तच्छक्यं(म्), मया द्रष्टुमिति प्रभो।\nयोगेश्वर ततो मे त्वं(न्), दर्शयात्मानमव्ययम्॥11.4॥",
        
        "श्रीभगवानुवाच\nपश्य मे पार्थ रूपाणि, शतशोऽथ सहस्रशः।\nनानाविधानि दिव्यानि, नानावर्णाकृतीनि च॥11.5॥",
        
        "पश्यादित्यान्वसून् रुद्रान्, अश्विनौ मरुतस्तथा।\nबहून्यदृष्टपूर्वाणि, पश्याश्चर्याणि भारत॥11.6॥",
        
        "इहैकस्थं(ञ्) जगत्कृत्स्नं(म्), पश्याद्य सचराचरम्।\nमम देहे गुडाकेश, यच्चान्यद्द्रष्टुमिच्छसि॥11.7॥", 
        
        "न तु मां(म्) शक्यसे द्रष्टुम्, अनेनैव स्वचक्षुषा।\nदिव्यं(न्) ददामि ते चक्षुः(फ्), पश्य मे योगमैश्वरम्॥11.8॥",
        
        "सञ्जय उवाच\nएवमुक्त्वा ततो राजन्, महायोगेश्वरो हरिः।\nदर्शयामास पार्थाय, परमं(म्) रूपमैश्वरम्॥11.9॥",
        
        "अनेकवक्त्रनयनम्, अनेकाद्भुतदर्शनम्।\nअनेकदिव्याभरणं(न्), दिव्यानेकोद्यतायुधम्॥11.10॥",
        
        "दिव्यमाल्याम्बरधरं(न्), दिव्यगन्धानुलेपनम्।\nसर्वाश्चर्यमयं(न्) देवम्, अनन्तं(व्ँ) विश्वतोमुखम्॥11.11॥",
        
        "दिविसूर्यसहस्रस्य, भवेद्युगपदुत्थिता।\nयदि भाः(स्) सदृशी सा स्याद्, भासस्तस्य महात्मनः॥11.12॥",
        
        "तत्रैकस्थं(ञ्) जगत्कृत्स्नं(म्), प्रविभक्तमनेकधा।\nअपश्यद्देवदेवस्य,      शरीरे पाण्डवस्तदा॥11.13॥",
        
        "ततः(स्) स विस्मयाविष्टो, हृष्टरोमा धनञ्जयः।\nप्रणम्य शिरसा देवं(ङ्), कृताञ्जलिरभाषत॥11.14॥",
        
        "अर्जुन उवाच\nपश्यामि देवांस्तव देव देहे,\n सर्वांस्तथा भूतविशेषसङ्घान्।\nब्रह्माणमीशं(ङ्) कमलासनस्थम्\n  ऋषींश्च सर्वानुरगांश्च दिव्यान्॥11.15॥",
        
        "अनेकबाहूदरवक्त्रनेत्रं(म्),\nपश्यामि त्वां(म्) सर्वतोऽनन्तरूपम्।\nनान्तं(न्) न मध्यं(न्) न पुनस्तवादिं(म्),\nपश्यामि विश्वेश्वर विश्वरूप॥11.16॥",
        
        "किरीटिनं(ङ्) गदिनं(ञ्) चक्रिणं(ञ्) च,\nतेजोराशिं(म्) सर्वतो दीप्तिमन्तम्।\nपश्यामि त्वां(न्) दुर्निरीक्ष्यं(म्) समन्ताद्-\n दीप्तानलार्कद्युतिमप्रमेयम् ॥11.17॥",
        
        "त्वमक्षरं(म्) परमं(व्ँ) वेदितव्यं(न्),\nत्वमस्य विश्वस्य परं(न्) निधानम्।\nत्वमव्ययः(श्) शाश्वतधर्मगोप्ता,\n सनातनस्त्वं(म्) पुरुषो मतो मे॥11.18॥",
        
        "अनादिमध्यान्तमनन्तवीर्यम्,\nअनन्तबाहुं(म्) शशिसूर्यनेत्रम्।\nपश्यामि त्वां(न्) दीप्तहुताशवक्त्रं(म्),\n स्वतेजसा विश्वमिदं(न्) तपन्तम्॥11.19॥",
        
        "द्यावापृथिव्योरिदमन्तरं(म्) हि,\n व्याप्तं(न्) त्वयैकेन दिशश्च सर्वाः।\nदृष्ट्वाद्भुतं(म्) रूपमुग्रं(न्) तवेदं(ल्ँ),\n लोकत्रयं(म्) प्रव्यथितं(म्) महात्मन्॥11.20॥",
        
        "अमी हि त्वां(म्) सुरसङ्घा विशन्ति,\nकेचिद्भीताः(फ्) प्राञ्जलयो गृणन्ति।\nस्वस्तीत्युक्त्वा महर्षिसिद्धसङ्घा:(स्),\n स्तुवन्ति त्वां(म्) स्तुतिभिः(फ्) पुष्कलाभिः॥11.21॥",
        
        "रुद्रादित्या वसवो ये च साध्या-\nविश्वेऽश्विनौ मरुतश्चोष्मपाश्च।\nगन्धर्वयक्षासुरसिद्धसङ्घा,\nवीक्षन्ते त्वां(व्ँ) विस्मिताश्चैव सर्वे॥11.22॥",
        
        "रूपं(म्) महत्ते बहुवक्त्रनेत्रं(म्),\nमहाबाहो बहुबाहूरुपादम्।\nबहूदरं(म्) बहुदंष्ट्राकरालं(न्),\n दृष्ट्वा लोकाः(फ्) प्रव्यथितास्तथाहम्॥11.23॥",
        
        "नभःस्पृशं(न्) दीप्तमनेकवर्णं(व्ँ),\n व्यात्ताननं(न्) दीप्तविशालनेत्रम्।\nदृष्ट्वा हि त्वां(म्) प्रव्यथितान्तरात्मा,\n धृतिं(न्) न विन्दामि शमं(ञ्) च विष्णो॥11.24॥",
        
        "दंष्ट्राकरालानि च ते मुखानि,\n दृष्ट्वैव कालानलसन्निभानि।\nदिशो न जाने न लभे च शर्म,\n प्रसीद देवेश जगन्निवास॥11.25॥",
        
        "अमी च त्वां(न्) धृतराष्ट्रस्य पुत्राः(स्),\nसर्वे सहैवावनिपालसङ्घैः।\nभीष्मो द्रोणः(स्) सूतपुत्रस्तथासौ,\n सहास्मदीयैरपि योधमुख्यैः॥11.26॥",
        
        "वक्त्राणि ते त्वरमाणा विशन्ति,\n दंष्ट्राकरालानि भयानकानि।\nकेचिद्विलग्ना दशनान्तरेषु,\n सन्दृश्यन्ते चूर्णितैरुत्तमाङ्गै:॥11.27॥",
          
        "यथा नदीनां(म्) बहवोऽम्बुवेगाः(स्),\nसमुद्रमेवाभिमुखा द्रवन्ति।\nतथा तवामी नरलोकवीरा,\nविशन्ति वक्त्राण्यभिविज्वलन्ति॥11.28॥",
        
        "यथा प्रदीप्तं(ञ्) ज्वलनं(म्) पतङ्गा,\n विशन्ति नाशाय समृद्धवेगाः।\nतथैव नाशाय विशन्ति लोका:(स्),\n तवापि वक्त्राणि समृद्धवेगाः॥11.29॥",
        
        "लेलिह्यसे ग्रसमानः(स्) समन्ताल्,\n लोकान्समग्रान्वदनैर्ज्वलद्भिः।\nतेजोभिरापूर्य जगत्समग्रं(म्),\n भासस्तवोग्राः(फ्) प्रतपन्ति विष्णो॥11.30॥",
        
        "आख्याहि मे को भवानुग्ररूपो,\nनमोऽस्तु ते देववर प्रसीद।\nविज्ञातुमिच्छामि भवन्तमाद्यं(न्),\n न हि प्रजानामि तव प्रवृत्तिम्॥11.31॥",
        
        "श्रीभगवानुवाच\nकालोऽस्मि लोकक्षयकृत्प्रवृद्धो,\n लोकान् समाहर्तुमिह प्रवृत्तः।\nऋतेऽपि त्वां(न्) न भविष्यन्ति सर्वे,\n येऽवस्थिताः(फ्) प्रत्यनीकेषु योधाः॥11.32॥",
        
        "तस्मात्त्वमुत्तिष्ठ यशो लभस्व,\n जित्वा शत्रून्भुङ्क्ष्व राज्यं(म्) समृद्धम्।\nमयैवैते निहताः(फ्) पूर्वमेव,\nनिमित्तमात्रं(म्) भव सव्यसाचिन्॥11.33॥",
        
        "द्रोणं(ञ्) च भीष्मं(ञ्) च जयद्रथं(ञ्) च,\n कर्णं(न्) तथान्यानपि योधवीरान्।\nमया हतांस्त्वं(ञ्) जहि मा व्यथिष्ठा,\nयुध्यस्व जेतासि रणे सपत्नान्॥11.34॥",
        
        "सञ्जय उवाच\nएतच्छ्रुत्वा वचनं(ङ्) केशवस्य,\n कृताञ्जलिर्वेपमानः(ख्) किरीटी ।\nनमस्कृत्वा भूय एवाह कृष्णं(म्),\n सगद्गदं(म्) भीतभीतः(फ्) प्रणम्य ॥11.35॥",
        
        "अर्जुन उवाच\nस्थाने हृषीकेश तव प्रकीर्त्या,\nजगत्प्रहृष्यत्यनुरज्यते च।\nरक्षांसि भीतानि दिशो द्रवन्ति,\nसर्वे नमस्यन्ति च सिद्धसङ्घा:॥11.36॥",
        
        "कस्माच्च ते न नमेरन्महात्मन्,\n गरीयसे ब्रह्मणोऽप्यादिकर्त्रे।\nअनन्त देवेश जगन्निवास,\nत्वमक्षरं(म्) सदसत्तत्परं(य्ँ) यत्॥11.37॥",
        
        "त्वमादिदेवः(फ्) पुरुषः(फ्) पुराण:(स्),\nत्वमस्य विश्वस्य परं(न्) निधानम्।\nवेत्तासि वेद्यं(ञ्) च परं(ञ्) च धाम,\n त्वया ततं(व्ँ) विश्वमनन्तरूप॥11.38॥",
        
        "वायुर्यमोऽग्निर्वरुणः(श्) शशाङ्क:(फ्),\nप्रजापतिस्त्वं(म्) प्रपितामहश्च।\nनमो नमस्तेऽस्तु सहस्रकृत्वः(फ्),\nपुनश्च भूयोऽपि नमो नमस्ते॥11.39॥",
        
        "नमः(फ्) पुरस्तादथ पृष्ठतस्ते,\nनमोऽस्तु ते सर्वत एव सर्व।\nअनन्तवीर्यामितविक्रमस्त्वं(म्),\nसर्वं(म्) समाप्नोषि ततोऽसि सर्वः॥11.40॥",
        
        "सखेति मत्वा प्रसभं(य्ँ) यदुक्तं(म्),\nहे कृष्ण हे यादव हे सखेति।\nअजानता महिमानं(न्) तवेदं(म्),\nमया प्रमादात्प्रणयेन वापि॥11.41॥",
        
        "यच्चावहासार्थमसत्कृतोऽसि,\nविहारशय्यासनभोजनेषु।\nएकोऽथवाप्यच्युत तत्समक्षं(न्),\n तत्क्षामये त्वामहमप्रमेयम्॥11.42॥",
        
        "पितासि लोकस्य चराचरस्य,\n त्वमस्य पूज्यश्च गुरुर्गरीयान्।\nन त्वत्समोऽस्त्यभ्यधिकः(ख्) कुतोऽन्यो,\n लोकत्रयेऽप्यप्रतिमप्रभाव॥11.43॥",
        
        "तस्मात्प्रणम्य प्रणिधाय कायं(म्),\n प्रसादये त्वामहमीशमीड्यम्।\nपितेव पुत्रस्य सखेव सख्युः(फ्),\nप्रियः(फ्) प्रियायार्हसि देव सोढुम्॥11.44॥",
        
        "अदृष्टपूर्वं(म्) हृषितोऽस्मि दृष्ट्वा,\n भयेन च प्रव्यथितं(म्) मनो मे।\nतदेव मे दर्शय देवरूपं(म्),\nप्रसीद देवेश जगन्निवास॥11.45॥",
        
        "किरीटिनं(ङ्) गदिनं(ञ्) चक्रहस्तम्,\nइच्छामि त्वां(न्) द्रष्टुमहं(न्) तथैव।\nतेनैव रूपेण चतुर्भुजेन,\n सहस्रबाहो भव विश्वमूर्ते॥11.46॥",
        
        "श्रीभगवानुवाच\nमया प्रसन्नेन तवार्जुनेदं(म्),\n रूपं(म्) परं(न्) दर्शितमात्मयोगात्।\nतेजोमयं(व्ँ) विश्वमनन्तमाद्यं(य्ँ),\nयन्मे त्वदन्येन न दृष्टपूर्वम्॥11.47॥",
        
        "न वेदयज्ञाध्ययनैर्न दानै: (र्),\n न च क्रियाभिर्न तपोभिरुग्रैः।\nएवं(म्)रूपः(श्) शक्य अहं(न्) नृलोके,\nद्रष्टुं(न्) त्वदन्येन कुरुप्रवीर॥11.48॥",
        
        "मा ते व्यथा मा च विमूढभावो,\nदृष्ट्वा रूपं(ङ्) घोरमीदृङ्ममेदम्।\nव्यपेतभीः(फ्) प्रीतमनाः(फ्) पुनस्त्वं(न्),\n तदेव मे रूपमिदं(म्) प्रपश्य॥11.49॥",
        
        "सञ्जय उवाच\nइत्यर्जुनं(म्) वासुदेवस्तथोक्त्वा,\nस्वकं (म्) रूपं(न्) दर्शयामास भूयः।\nआश्वासयामास च भीतमेनं(म्),\n भूत्वा पुनः(स्) सौम्यवपुर्महात्मा॥11.50॥",
        
        "अर्जुन उवाच\nदृष्ट्वेदं(म्) मानुषं(म्) रूपं(न्), तव सौम्यं(ञ्) जनार्दन।\nइदानीमस्मि संवृत्तः(स्), सचेताः(फ्) प्रकृतिं(ङ्) गतः॥11.51॥",
        
        "श्रीभगवानुवाच\nसुदुर्दर्शमिदं(म्) रूपं(न्), दृष्टवानसि यन्मम।\nदेवा अप्यस्य रूपस्य, नित्यं(न्) दर्शनकाङ्क्षिणः॥11.52॥",
        
        "नाहं(व्ँ) वेदैर्न तपसा, न दानेन न चेज्यया।\nशक्य एवंविधो द्रष्टुं(न्), दृष्टवानसि मां यथा॥11.53॥",
        
        "भक्त्या त्वनन्यया शक्य, अहमेवंविधोऽर्जुन।\nज्ञातुं(न्) द्रष्टुं(ञ्) च तत्त्वेन, प्रवेष्टुं(ञ्) च परन्तप ॥11.54॥",
        
        "मत्कर्मकृन्मत्परमो, मद्भक्तः (स्) सङ्गवर्जितः।\nनिर्वैरः (स्) सर्वभूतेषु, यः (स्)  स मामेति पाण्डव ॥11.55॥",
        
        "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु ब्रह्मविद्यायां (य्ँ) योगशास्त्रे\nश्रीकृष्णार्जुनसंवादे 'विश्वरूपदर्शनयोगो' नामैकादशोऽध्यायः ॥11॥"
      ],
      "adhyaya-12" : [
    
        "अर्जुन उवाच\nएवं(म्) सततयुक्ता ये, भक्तास्त्वां(म्) पर्युपासते।\n ये चाप्यक्षरमव्यक्तं (न्), तेषां(ङ्) के योगवित्तमाः॥12.1॥",
        
        "श्रीभगवानुवाच\nमय्यावेश्य मनो ये मां(न्), नित्ययुक्ता उपासते।\n श्रद्धया परयोपेता:(स्), ते मे युक्ततमा मताः॥12.2॥",
        
        "ये त्वक्षरमनिर्देश्यम्, अव्यक्तं(म्) पर्युपासते।\n सर्वत्रगमचिन्त्यं(ञ्) च, कूटस्थमचलं(न्) ध्रुवम्॥12.3॥",
        
        "सन्नियम्येन्द्रियग्रामं(म्), सर्वत्र समबुद्धयः।\n ते प्राप्नुवन्ति मामेव, सर्वभूतहिते रताः॥12.4॥",
        
        "क्लेशोऽधिकतरस्तेषाम्, अव्यक्तासक्तचेतसाम्।\n अव्यक्ता हि गतिर्दुःखं(न्), देहवद्भिरवाप्यते॥12.5॥",
        
        "ये तु सर्वाणि कर्माणि, मयि सन्न्यस्य मत्पराः।\n अनन्येनैव योगेन, मां(न्) ध्यायन्त उपासते॥12.6॥",
        
        "तेषामहं(म्) समुद्धर्ता, मृत्युसंसारसागरात्।\n भवामि नचिरात्पार्थ, मय्यावेशितचेतसाम्॥12.7॥",
        
        "मय्येव मन आधत्स्व, मयि बुद्धिं(न्) निवेशय।\n निवसिष्यसि मय्येव, अत ऊर्ध्वं(न्) न संशयः॥12.8॥",
        
        "अथ चित्तं(म्) समाधातुं(न्), न शक्नोषि मयि स्थिरम्।\n अभ्यासयोगेन ततो, मामिच्छाप्तुं(न्) धनञ्जय॥12.9॥",
        
        "अभ्यासेऽप्यसमर्थोऽसि, मत्कर्मपरमो भव।\n मदर्थमपि कर्माणि, कुर्वन्सिद्धिमवाप्स्यसि॥12.10॥",
        
        "अथैतदप्यशक्तोऽसि, कर्तुं(म्) मद्योगमाश्रितः।\n सर्वकर्मफलत्यागं(न्), ततः(ख्) कुरु यतात्मवान्॥12.11॥",
        
        "श्रेयो हि ज्ञानमभ्यासाज्, ज्ञानाद्ध्यानं(व्ँ) विशिष्यते।\n ध्यानात्कर्मफलत्याग:(स्),त्यागाच्छान्तिरनन्तरम्॥12.12॥",
        
        "अद्वेष्टा सर्वभूतानां(म्), मैत्रः(ख्) करुण एव च।\n निर्ममो निरहङ्कारः(स्), समदुःखसुखः क्षमी॥12.13॥",
        
        "सन्तुष्टः(स्) सततं(य्ँ) योगी, यतात्मा दृढनिश्चयः।\n मय्यर्पितमनोबुद्धि:(र्), यो मद्भक्तः(स्) स मे प्रियः॥12.14॥",
        
        "यस्मान्नोद्विजते लोको, लोकान्नोद्विजते च यः।\n हर्षामर्षभयोद्वेगै:(र्),  मुक्तो यः(स्) स च मे प्रियः॥12.15॥",
        
        "अनपेक्षः(श्) शुचिर्दक्ष, उदासीनो गतव्यथः।\n सर्वारम्भपरित्यागी, यो मद्भक्तः(स्) स मे प्रियः॥12.16॥",
        
        "यो न हृष्यति न द्वेष्टि, न शोचति न काङ्क्षति।\n शुभाशुभपरित्यागी, भक्तिमान्यः(स्) स मे प्रियः॥12.17॥",
        
        "समः(श्) शत्रौ च मित्रे च, तथा मानापमानयोः।\n शीतोष्णसुखदुःखेषु, समः(स्) सङ्गविवर्जितः॥12.18॥",
        
        "तुल्यनिन्दास्तुतिर्मौनी, सन्तुष्टो येन केनचित्।\n अनिकेतः(स्) स्थिरमति:(र्), भक्तिमान्मे प्रियो नरः॥12.19॥",
        
        "ये तु धर्म्यामृतमिदं(य्ँ), यथोक्तं(म्) पर्युपासते।\n श्रद्दधाना मत्परमा, भक्तास्तेऽतीव मे प्रियाः॥12.20॥",
        
        "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु ब्रह्मविद्यायां(य्ँ) योगशास्त्रे\nश्रीकृष्णार्जुनसंवादे भक्तियोगो नाम द्वादशोऽध्यायः॥"
      ],
      "adhyaya-13" : [
    
        "श्रीभगवानुवाच\nइदं(म्) शरीरं(ङ्) कौन्तेय, क्षेत्रमित्यभिधीयते।\nएतद्यो वेत्ति तं(म्) प्राहुः, क्षेत्रज्ञ इति तद्विदः॥13.1॥",
        
        "क्षेत्रज्ञं(ञ्) चापि मां(व्ँ) विद्धि, सर्वक्षेत्रेषु भारत।\nक्षेत्रक्षेत्रज्ञयोर्ज्ञानं(य्ँ), यत्तज्ज्ञानं(म्) मतं(म्) मम॥13.2॥",
        
        "तत्क्षेत्रं(य्ँ) यच्च यादृक्च, यद्विकारि यतश्च यत्।\nस च यो यत्प्रभावश्च, तत्समासेन मे शृणु॥13.3॥",
        
        "ऋषिभिर्बहुधा गीतं(ञ्), छन्दोभिर्विविधैः(फ्) पृथक्।\nब्रह्मसूत्रपदैश्चैव, हेतुमद्भिर्विनिश्चितैः॥13.4॥",
        
        "महाभूतान्यहङ्कारो, बुद्धिरव्यक्तमेव च।\nइन्द्रियाणि दशैकं(ञ्) च, पञ्च चेन्द्रियगोचराः॥13.5॥",
        
        "इच्छा द्वेषः(स्) सुखं(न्) दुःखं(म्), सङ्घातश्चेतना धृतिः।\nएतत्क्षेत्रं(म्) समासेन, सविकारमुदाहृतम्॥13.6॥",
        
        "अमानित्वमदम्भित्वम्, अहिंसा क्षान्तिरार्जवम्।\nआचार्योपासनं(म्) शौचं(म्), स्थैर्यमात्मविनिग्रहः॥13.7॥",
        
        "इन्द्रियार्थेषु वैराग्यम्, अनहङ्कार एव च।\nजन्ममृत्युजराव्याधि, दुःखदोषानुदर्शनम्॥13.8॥",
        
        "असक्तिरनभिष्वङ्ग:(फ्), पुत्रदारगृहादिषु।\nनित्यं(ञ्) च समचित्तत्वम्, इष्टानिष्टोपपत्तिषु॥13.9॥",
        
        "मयि चानन्ययोगेन, भक्तिरव्यभिचारिणी।\nविविक्तदेशसेवित्वम्, अरतिर्जनसंसदि॥13.10॥",
        
        "अध्यात्मज्ञाननित्यत्वं(न्), तत्त्वज्ञानार्थदर्शनम्।\nएतज्ज्ञानमिति प्रोक्तम्, अज्ञानं(य्ँ) यदतोऽन्यथा॥13.11॥",
        
        "ज्ञेयं(य्ँ) यत्तत्प्रवक्ष्यामि, यज्ज्ञात्वामृतमश्नुते।\nअनादिमत्परं(म्) ब्रह्म, न सत्तन्नासदुच्यते॥13.12॥",
        
        "सर्वतः(फ्) पाणिपादं(न्) तत्, सर्वतोऽक्षिशिरोमुखम्।\nसर्वतः(श्) श्रुतिमल्लोके, सर्वमावृत्य तिष्ठति॥13.13॥",
        
        "सर्वेन्द्रियगुणाभासं(म्), सर्वेन्द्रियविवर्जितम्।\nअसक्तं(म्) सर्वभृच्चैव, निर्गुणं(ङ्) गुणभोक्तृ च॥13.14॥",
        
        "बहिरन्तश्च भूतानाम्, अचरं(ञ्) चरमेव च।\nसूक्ष्मत्वात्तदविज्ञेयं(न्), दूरस्थं(ञ्) चान्तिके च तत्॥13.15॥",
        
        "अविभक्तं(ञ्) च भूतेषु, विभक्तमिव च स्थितम्।\nभूतभर्तृ च तज्ज्ञेयं(ङ्),  ग्रसिष्णु प्रभविष्णु च॥13.16॥",
        
        "ज्योतिषामपि तज्ज्योति:(स्), तमसः(फ्) परमुच्यते।\nज्ञानं(ञ्) ज्ञेयं(ञ्) ज्ञानगम्यं(म्), हृदि सर्वस्य विष्ठितम्॥13.17॥",
        
        "इति क्षेत्रं(न्) तथा ज्ञानं(ञ्), ज्ञेयं(ञ्) चोक्तं(म्) समासतः।\nमद्भक्त एतद्विज्ञाय, मद्भावायोपपद्यते॥13.18॥",
        
        "प्रकृतिं(म्) पुरुषं(ञ्) चैव, विद्ध्यनादी उभावपि।\nविकारांश्च गुणांश्चैव, विद्धि प्रकृतिसम्भवान्॥13.19॥",
        
        "कार्यकरणकर्तृत्वे, हेतुः(फ्) प्रकृतिरुच्यते।\nपुरुषः(स्) सुखदुःखानां(म्), भोक्तृत्वे हेतुरुच्यते॥13.20॥",
        
        "पुरुषः(फ्) प्रकृतिस्थो हि, भुङ्क्ते प्रकृतिजान्गुणान्।\nकारणं(ङ्) गुणसङ्गोऽस्य, सदसद्योनिजन्मसु॥13.21॥",
        
        "उपद्रष्टानुमन्ता च, भर्ता भोक्ता महेश्वरः।\nपरमात्मेति चाप्युक्तो, देहेऽस्मिन्पुरुषः(फ्) परः॥13.22॥",
        
        "य एवं(व्ँ) वेत्ति पुरुषं(म्), प्रकृतिं(ञ्) च गुणैः(स्) सह।\nसर्वथा वर्तमानोऽपि, न स भूयोऽभिजायते॥13.23॥",
        
        "ध्यानेनात्मनि पश्यन्ति, केचिदात्मानमात्मना।\nअन्ये साङ्ख्येन योगेन, कर्मयोगेन चापरे॥13.24॥",
        
        "अन्ये त्वेवमजानन्तः(श्), श्रुत्वान्येभ्य उपासते।\nतेऽपि चातितरन्त्येव, मृत्युं(म्) श्रुतिपरायणाः॥13.25॥",
        
        "यावत्सञ्जायते किञ्चित्, सत्त्वं(म्) स्थावरजङ्गमम्।\nक्षेत्रक्षेत्रज्ञसंयोगात्, तद्विद्धि भरतर्षभ॥13.26॥",
        
        "समं(म्) सर्वेषु भूतेषु, तिष्ठन्तं(म्) परमेश्वरम्।\nविनश्यत्स्वविनश्यन्तं(य्ँ), यः(फ्) पश्यति स पश्यति ॥13.27॥",
        
        "समं(म्) पश्यन्हि सर्वत्र, समवस्थितमीश्वरम्।\nन हिनस्त्यात्मनात्मानं(न्), ततो याति परां(ङ्) गतिम्॥13.28॥",
        
        "प्रकृत्यैव च कर्माणि, क्रियमाणानि सर्वशः।\nयः(फ्) पश्यति तथात्मानम्, अकर्तारं(म्) स पश्यति॥13.29॥",
        
        "यदा भूतपृथग्भावम्, एकस्थमनुपश्यति।\nतत एव च विस्तारं(म्), ब्रह्म सम्पद्यते तदा॥13.30॥",
        
        "अनादित्वान्निर्गुणत्वात्, परमात्मायमव्ययः।\nशरीरस्थोऽपि कौन्तेय, न करोति न लिप्यते॥13.31॥",
        
        "यथा सर्वगतं(म्) सौक्ष्म्याद्, आकाशं(न्) नोपलिप्यते।\nसर्वत्रावस्थितो देहे, तथात्मा नोपलिप्यते॥13.32॥",
        
        "यथा प्रकाशयत्येकः(ख्), कृत्स्नं(ल्ँ) लोकमिमं(म्) रविः।\nक्षेत्रं(ङ्) क्षेत्री तथा कृत्स्नं(म्), प्रकाशयति भारत॥13.33॥",
        
        "क्षेत्रक्षेत्रज्ञयोरेवम्, अन्तरं(ञ्) ज्ञानचक्षुषा।\nभूतप्रकृतिमोक्षं(ञ्) च, ये विदुर्यान्ति ते परम्॥13.34॥",
        
        "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु ब्रह्मविद्यायां(य्ँ) योगशास्त्रे\nश्रीकृष्णार्जुनसंवादे क्षेत्रक्षेत्रज्ञविभागयोगो नाम त्रयोदशोऽध्यायः॥"
      ],
      "adhyaya-14" : [
    
        "श्रीभगवानुवाच\nपरं(म्) भूयः(फ्) प्रवक्ष्यामि, ज्ञानानां(ञ्) ज्ञानमुत्तमम्।\nयज्ज्ञात्वा मुनयः(स्) सर्वे, परां(म्) सिद्धिमितो गताः॥14.1॥",
        
        "इदं(ञ्) ज्ञानमुपाश्रित्य, मम साधर्म्यमागताः।\nसर्गेऽपि नोपजायन्ते, प्रलये न व्यथन्ति च॥14.2॥",
        
        "मम योनिर्महद्ब्रह्म, तस्मिन्गर्भं(न्) दधाम्यहम्।\nसम्भवः(स्) सर्वभूतानां(न्), ततो भवति भारत॥14.3॥",
        
        "सर्वयोनिषु कौन्तेय, मूर्तयः(स्)  सम्भवन्ति याः।\nतासां(म्) ब्रह्म महद्योनि:(र्), अहं(म्) बीजप्रदः(फ्) पिता॥14.4॥",
        
        "सत्त्वं(म्) रजस्तम इति, गुणाः(फ्) प्रकृतिसम्भवाः।\nनिबध्नन्ति महाबाहो, देहे देहिनमव्ययम्॥14.5॥",
        
        "तत्र सत्त्वं(न्) निर्मलत्वात्, प्रकाशकमनामयम्।\nसुखसङ्गेन बध्नाति, ज्ञानसङ्गेन चानघ॥14.6॥",
        
        "रजो रागात्मकं(व्ँ) विद्धि, तृष्णासङ्गसमुद्भवम्।\nतन्निबध्नाति कौन्तेय, कर्मसङ्गेन देहिनम्॥14.7॥",
        
        "तमस्त्वज्ञानजं(व्ँ) विद्धि, मोहनं(म्) सर्वदेहिनाम्।\nप्रमादालस्यनिद्राभि:(स्), तन्निबध्नाति भारत॥14.8॥",
        
        "सत्त्वं(म्) सुखे सञ्जयति, रजः(ख्) कर्मणि भारत।\nज्ञानमावृत्य तु तमः(फ्), प्रमादे सञ्जयत्युत॥14.9॥",
        
        "रजस्तमश्चाभिभूय, सत्त्वं(म्) भवति भारत।\nरजः(स्) सत्त्वं(न्) तमश्चैव, तमः(स्) सत्त्वं(म्) रजस्तथा॥14.10॥",
        
        "सर्वद्वारेषु देहेऽस्मिन्, प्रकाश उपजायते।\nज्ञानं(य्ँ) यदा तदा विद्याद्, विवृद्धं(म्) सत्त्वमित्युत॥14.11॥",
        
        "लोभः(फ्) प्रवृत्तिरारम्भः(ख्), कर्मणामशमः(स्) स्पृहा।\nरजस्येतानि जायन्ते, विवृद्धे भरतर्षभ॥14.12॥",
        
        "अप्रकाशोऽप्रवृत्तिश्च, प्रमादो मोह एव च।\nतमस्येतानि जायन्ते, विवृद्धे कुरुनन्दन॥14.13॥",
        
        "यदा सत्त्वे प्रवृद्धे तु, प्रलयं(य्ँ) याति देहभृत्।\nतदोत्तमविदां(ल्ँ) लोकान्, अमलान्प्रतिपद्यते॥14.14॥",
        
        "रजसि प्रलयं(ङ्) गत्वा, कर्मसङ्गिषु जायते।\nतथा प्रलीनस्तमसि, मूढयोनिषु जायते॥14.15॥",
        
        "कर्मणः(स्) सुकृतस्याहुः(स्), सात्त्विकं(न्) निर्मलं(म्) फलम्।\nरजसस्तु फलं(न्) दुःखम्, अज्ञानं(न्) तमसः(फ्) फलम्॥14.16॥",
        
        "सत्त्वात्सञ्जायते ज्ञानं(म्), रजसो लोभ एव च।\nप्रमादमोहौ तमसो, भवतोऽज्ञानमेव च॥14.17॥",
        
        "ऊर्ध्वं(ङ्) गच्छन्ति सत्त्वस्था, मध्ये तिष्ठन्ति राजसाः।\nजघन्यगुणवृत्तिस्था, अधो गच्छन्ति तामसाः॥14.18॥",
        
        "नान्यं(ङ्) गुणेभ्यः(ख्) कर्तारं(य्ँ), यदा द्रष्टानुपश्यति।\nगुणेभ्यश्च परं(व्ँ) वेत्ति, मद्भावं(म्) सोऽधिगच्छति॥14.19॥",
        
        "गुणानेतानतीत्य त्रीन्, देही देहसमुद्भवान्।\nजन्ममृत्युजरादुःखै:(र्), विमुक्तोऽमृतमश्नुते॥14.20॥",
        
        "अर्जुन उवाच\nकैर्लिङ्गैस्त्रीन्गुणानेतान्, अतीतो भवति प्रभो।\nकिमाचारः(ख्) कथं(ञ्) चैतांस्, त्रीन्गुणानतिवर्तते॥14.21॥",
        
        "श्रीभगवानुवाच\nप्रकाशं(ञ्) च प्रवृत्तिं(ञ्) च, मोहमेव च पाण्डव।\nन द्वेष्टि सम्प्रवृत्तानि, न निवृत्तानि काङ्क्षति॥14.22॥",
        
        "उदासीनवदासीनो, गुणैर्यो न विचाल्यते।\nगुणा वर्तन्त इत्येव, योऽवतिष्ठति नेङ्गते॥14.23॥",
        
        "समदुःखसुखः(स्) स्वस्थः(स्), समलोष्टाश्मकाञ्चनः।\nतुल्यप्रियाप्रियो धीर:(स्), तुल्यनिन्दात्मसंस्तुतिः॥14.24॥",
        
        "मानापमानयोस्तुल्य:(स्), तुल्यो मित्रारिपक्षयोः।\nसर्वारम्भपरित्यागी,  गुणातीतः(स्) स उच्यते॥14.25॥",
        
        "मां(ञ्) च योऽव्यभिचारेण, भक्तियोगेन सेवते।\nस गुणान्समतीत्यैतान्, ब्रह्मभूयाय कल्पते॥14.26॥",
        
        "ब्रह्मणो हि प्रतिष्ठाहम्, अमृतस्याव्ययस्य च।\nशाश्वतस्य च धर्मस्य, सुखस्यैकान्तिकस्य च॥14.27॥",
        
        "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु ब्रह्मविद्यायां(य्ँ) योगशास्त्रे\nश्रीकृष्णार्जुनसंवादे गुणत्रयविभागयोगो नाम चतुर्दशोऽध्याय:॥"
      ],
      "adhyaya-15" : [
    
        "श्रीभगवानुवाच\nऊर्ध्वमूलमधः(श्) शाखम्, अश्वत्थं(म्) प्राहुरव्ययम्।\nछन्दांसि यस्य पर्णानि, यस्तं(व्ँ) वेद स वेदवित्॥15.1॥",
        
        "अधश्चोर्ध्वं(म्) प्रसृतास्तस्य शाखा, गुणप्रवृद्धा विषयप्रवालाः।\nअधश्च मूलान्यनुसन्ततानि, कर्मानुबन्धीनि मनुष्यलोके॥15.2॥",
        
        "न रूपमस्येह तथोपलभ्यते, नान्तो न चादिर्न च सम्प्रतिष्ठा ।\nअश्वत्थमेनं(म्) सुविरूढमूलम्, असङ्गशस्त्रेण दृढेन छित्त्वा॥15.3॥",
        
        "ततः(फ्) पदं(न्) तत्परिमार्गितव्यं(य्ँ), यस्मिन्गता न निवर्तन्ति भूयः।\nतमेव चाद्यं(म्) पुरुषं(म्) प्रपद्ये, यतः(फ्) प्रवृत्तिः(फ्) प्रसृता पुराणी॥15.4॥",
        
        "निर्मानमोहा जितसङ्गदोषा, अध्यात्मनित्या विनिवृत्तकामाः।\nद्वन्द्वैर्विमुक्ताः(स्) सुखदुःखसञ्ज्ञै:(र्), गच्छन्त्यमूढाः(फ्) पदमव्ययं(न्) तत्॥15.5॥",
        
        "न तद्भासयते सूर्यो, न शशाङ्को न पावकः।\nयद्गत्वा न निवर्तन्ते, तद्धाम परमं(म्) मम॥15.6॥",
        
        "ममैवांशो जीवलोके, जीवभूतः(स्) सनातनः।\nमनः(ष्) षष्ठानीन्द्रियाणि, प्रकृतिस्थानि कर्षति॥15.7॥",
        
        "शरीरं(य्ँ) यदवाप्नोति,  यच्चाप्युत्क्रामतीश्वरः।\nगृहीत्वैतानि संयाति,  वायुर्गन्धानिवाशयात्॥15.8॥",
        
        "श्रोत्रं(ञ्) चक्षुः(स्) स्पर्शनं(ञ्) च,  रसनं(ङ्) घ्राणमेव च।\nअधिष्ठाय मनश्चायं(व्ँ), विषयानुपसेवते॥15.9॥",
        
        "उत्क्रामन्तं(म्) स्थितं(व्ँ) वापि, भुञ्जानं(व्ँ) वा गुणान्वितम्।\nविमूढा नानुपश्यन्ति, पश्यन्ति ज्ञानचक्षुषः॥15.10॥",
        
        "यतन्तो योगिनश्चैनं(म्), पश्यन्त्यात्मन्यवस्थितम्।\nयतन्तोऽप्यकृतात्मानो,नैनं(म्) पश्यन्त्यचेतसः॥15.11॥",
        
        "यदादित्यगतं(न्) तेजो, जगद्भासयतेऽखिलम्।\nयच्चन्द्रमसि यच्चाग्नौ,तत्तेजो विद्धि मामकम्॥15.12॥",
        
        "गामाविश्य च भूतानि, धारयाम्यहमोजसा।\nपुष्णामि चौषधीः(स्) सर्वाः(स्),  सोमो भूत्वा रसात्मकः॥15.13॥",
        
        "अहं(व्ँ) वैश्वानरो भूत्वा, प्राणिनां(न्) देहमाश्रितः।\nप्राणापानसमायुक्तः(फ्), पचाम्यन्नं(ञ्) चतुर्विधम्॥15.14॥",
        
        "सर्वस्य चाहं(म्) हृदि सन्निविष्टो, मत्तः(स्) स्मृतिर्ज्ञानमपोहनं(ञ्) च।\nवेदैश्च सर्वैरहमेव वेद्यो, वेदान्तकृद्वेदविदेव चाहम्॥15.15॥",
        
        "द्वाविमौ पुरुषौ लोके, क्षरश्चाक्षर एव च।\nक्षरः(स्) सर्वाणि भूतानि,  कूटस्थोऽक्षर उच्यते॥15.16॥",
        
        "उत्तमः(फ्) पुरुषस्त्वन्यः(फ्),  परमात्मेत्युदाहृतः।\nयो लोकत्रयमाविश्य, बिभर्त्यव्यय ईश्वरः॥15.17॥",
        
        "यस्मात्क्षरमतीतोऽहम्, अक्षरादपि चोत्तमः।\nअतोऽस्मि लोके वेदे च,  प्रथितः(फ्) पुरुषोत्तमः॥15.18॥",
        
        "यो मामेवमसम्मूढो, जानाति पुरुषोत्तमम्।\nस सर्वविद्भजति मां(म्),सर्वभावेन भारत॥15.19॥",
        
        "इति गुह्यतमं(म्) शास्त्रम्, इदमुक्तं(म्) मयानघ।\nएतद्बुद्ध्वा बुद्धिमान्स्यात्, कृतकृत्यश्च भारत॥15.20॥",
        
        "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु ब्रह्मविद्यायां(य्ँ) योगशास्त्रे\nश्रीकृष्णार्जुनसंवादे पुरुषोत्तमयोगो नाम पञ्चदशोऽध्याय:॥"
      ],
      "adhyaya-16" : [
    
        "श्रीभगवानुवाच\nअभयं(म्) सत्त्वसंशुद्धिः(र्), ज्ञानयोगव्यवस्थितिः।\nदानं(न्) दमश्च यज्ञश्च, स्वाध्यायस्तप आर्जवम्।।16.1।।",
        
        "अहिंसा सत्यमक्रोध:(स्),  त्यागः(श्) शान्तिरपैशुनम् ।\nदया भूतेष्वलोलुप्त्वं(म्), मार्दवं(म्) ह्रीरचापलम्।।16.2।।",
        
        "तेजः क्षमा धृतिः(श्) शौचम्, अद्रोहो नातिमानिता।\nभवन्ति सम्पदं(न्) दैवीम्, अभिजातस्य भारत।।16.3।।",
        
        "दम्भो दर्पोऽभिमानश्च, क्रोधः(फ्) पारुष्यमेव च।\nअज्ञानं(ञ्) चाभिजातस्य, पार्थ सम्पदमासुरीम्।।16.4।।",
        
        "दैवी सम्पद्विमोक्षाय, निबन्धायासुरी मता।\nमा शुचः(स्) सम्पदं(न्) दैवीम्, अभिजातोऽसि पाण्डव।।16.5।।",
        
        "द्वौ भूतसर्गौ लोकेऽस्मिन्, दैव आसुर एव च।\nदैवो विस्तरशः(फ्) प्रोक्त , आसुरं(म्) पार्थ मे शृणु।।16.6।।",
        
        "प्रवृत्तिं(ञ्) च निवृत्तिं(ञ्) च, जना न विदुरासुराः।\nन शौचं(न्) नापि चाचारो, न सत्यं(न्) तेषु विद्यते।।16.7।।",
        
        "असत्यमप्रतिष्ठं(न्) ते, जगदाहुरनीश्वरम्।\nअपरस्परसम्भूतं(ङ्), किमन्यत्कामहैतुकम्।।16.8।।",
        
        "एतां(न्) दृष्टिमवष्टभ्य, नष्टात्मानोऽल्पबुद्धयः।\nप्रभवन्त्युग्रकर्माणः, क्षयाय जगतोऽहिताः।।16.9।।",
        
        "काममाश्रित्य दुष्पूरं(न्), दम्भमानमदान्विताः।\nमोहाद्गृहीत्वासद्ग्राहान्,   प्रवर्तन्तेऽशुचिव्रताः।।16.10।।",
        
        "चिन्तामपरिमेयां(ञ्) च, प्रलयान्तामुपाश्रिताः।\nकामोपभोगपरमा, एतावदिति निश्चिताः।।16.11।।",
        
        "आशापाशशतैर्बद्धाः(ख्), कामक्रोधपरायणाः।\nईहन्ते कामभोगार्थम्, अन्यायेनार्थसञ्चयान्।।16.12।।",
        
        "इदमद्य मया लब्धम्, इमं(म्) प्राप्स्ये मनोरथम्।\nइदमस्तीदमपि मे, भविष्यति पुनर्धनम्।।16.13।।",
        
        "असौ मया हतः(श्) शत्रु:(र्), हनिष्ये चापरानपि।\nईश्वरोऽहमहं(म्) भोगी, सिद्धोऽहं(म्) बलवान्सुखी।।16.14।।",
        
        "आढ्योऽभिजनवानस्मि, कोऽन्योऽस्ति सदृशो मया।\nयक्ष्ये दास्यामि मोदिष्य, इत्यज्ञानविमोहिताः।।16.15।।",
        
        "अनेकचित्तविभ्रान्ता, मोहजालसमावृताः।\nप्रसक्ताः(ख्) कामभोगेषु, पतन्ति नरकेऽशुचौ।।16.16।।",
        
        "आत्मसम्भाविताः(स्) स्तब्धा, धनमानमदान्विताः।\nयजन्ते नामयज्ञैस्ते, दम्भेनाविधिपूर्वकम्।।16.17।।",
        
        "अहङ्कारं(म्) बलं(न्) दर्पं(ङ्), कामं(ङ्) क्रोधं(ञ्) च संश्रिताः।\nमामात्मपरदेहेषु, प्रद्विषन्तोऽभ्यसूयकाः।।16.18।।",
        
        "तानहं(न्) द्विषतः(ख्) क्रूरान् , संसारेषु  नराधमान्।\nक्षिपाम्यजस्रमशुभान्, आसुरीष्वेव योनिषु।।16.19।।",
        
        "आसुरीं(य्ँ) योनिमापन्ना, मूढा जन्मनि जन्मनि।\nमामप्राप्यैव कौन्तेय, ततो यान्त्यधमां(ङ्) गतिम्।।16.20।।",
        
        "त्रिविधं(न्) नरकस्येदं(न्), द्वारं(न्) नाशनमात्मनः।\nकामः(ख्) क्रोधस्तथा लोभ:(स्), तस्मादेतत्त्रयं(न्) त्यजेत्।।16.21।।",
        
        "एतैर्विमुक्तः(ख्) कौन्तेय, तमोद्वारैस्त्रिभिर्नरः।\nआचरत्यात्मनः(श्) श्रेयस् , ततो याति परां(ङ्) गतिम्।।16.22।।",
        
        "यः(श्) शास्त्रविधिमुत्सृज्य, वर्तते कामकारतः।\nन स सिद्धिमवाप्नोति ,  न सुखं(न्)  न परां(ङ्) गतिम् ।।16.23।।",
        
        "तस्माच्छास्त्रं(म्) प्रमाणं(न्) ते, कार्याकार्यव्यवस्थितौ।\nज्ञात्वा शास्त्रविधानोक्तं(ङ्), कर्म कर्तुमिहार्हसि।।16.24।।",
        
        "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु ब्रह्मविद्यायां(य्ँ) योगशास्त्रे\nश्रीकृष्णार्जुनसंवादे दैवासुरसम्पद्विभागयोगो नाम षोडशोऽध्याय:।।"  
      ],
      "adhyaya-17" : [
    
        "अर्जुन उवाच\nये शास्त्रविधिमुत्सृज्य, यजन्ते श्रद्धयान्विताः।\nतेषां(न्) निष्ठा तु का कृष्ण, सत्त्वमाहो रजस्तमः ॥17.1॥",
        
        "श्रीभगवानुवाच\nत्रिविधा भवति श्रद्धा, देहिनां(म्) सा स्वभावजा।\nसात्त्विकी राजसी चैव, तामसी चेति तां(म्) शृणु॥17.2॥",
        
        "सत्त्वानुरूपा सर्वस्य, श्रद्धा भवति भारत।\nश्रद्धामयोऽयं(म्) पुरुषो, यो यच्छ्रद्धः(स्) स एव सः॥17.3॥",
        
        "यजन्ते सात्त्विका देवान्, यक्षरक्षांसि राजसाः।\nप्रेतान्भूतगणांश्चान्ये, यजन्ते तामसा जनाः॥17.4॥",
        
        "अशास्त्रविहितं(ङ्) घोरं(न्), तप्यन्ते ये तपो जनाः।\nदम्भाहङ्कारसंयुक्ताः(ख्), कामरागबलान्विताः॥17.5॥",
        
        "कर्शयन्तः(श्) शरीरस्थं(म्), भूतग्राममचेतसः।\nमां(ञ्) चैवान्तः(श्) शरीरस्थं(न्), तान्विद्ध्यासुरनिश्चयान्॥17.6॥",
        
        "आहारस्त्वपि सर्वस्य, त्रिविधो भवति प्रियः।\nयज्ञस्तपस्तथा दानं(न्), तेषां(म्) भेदमिमं(म्) शृणु॥17.7॥",
        
        "आयुः(स्) सत्त्वबलारोग्य, सुखप्रीतिविवर्धनाः।\nरस्याः(स्) स्निग्धाः(स्) स्थिरा हृद्या, आहाराः(स्) सात्त्विकप्रियाः॥17.8॥",
        
        "कट्वम्ललवणात्युष्ण, तीक्ष्णरूक्षविदाहिनः।\nआहारा राजसस्येष्टा, दुःखशोकामयप्रदाः॥17.9॥",
        
        "यातयामं(ङ्) गतरसं(म्), पूति पर्युषितं(ञ्) च यत्।\nउच्छिष्टमपि चामेध्यं(म्), भोजनं(न्) तामसप्रियम्॥17.10॥",
        
        "अफलाकाङ्क्षिभिर्यज्ञो, विधिदृष्टो य इज्यते।\nयष्टव्यमेवेति मनः(स्), समाधाय स सात्त्विकः॥17.11॥",
        
        "अभिसन्धाय तु फलं(न्), दम्भार्थमपि चैव यत्।\nइज्यते भरतश्रेष्ठ, तं(य्ँ) यज्ञं(व्ँ) विद्धि राजसम्॥17.12॥",
        
        "विधिहीनमसृष्टान्नं(म्), मन्त्रहीनमदक्षिणम्।\nश्रद्धाविरहितं(य्ँ) यज्ञं(न्), तामसं(म्) परिचक्षते॥17.13॥",
        
        "देवद्विजगुरुप्राज्ञ, पूजनं(म्) शौचमार्जवम्।\nब्रह्मचर्यमहिंसा च, शारीरं(न्) तप उच्यते॥17.14॥",
        
        "अनुद्वेगकरं(व्ँ) वाक्यं(म्), सत्यं(म्) प्रियहितं(ञ्) च यत्।\nस्वाध्यायाभ्यसनं(ञ्) चैव, वाङ्मयं(न्) तप उच्यते॥17.15॥",
        
        "मनः(फ्) प्रसादः(स्) सौम्यत्वं(म्), मौनमात्मविनिग्रहः।\nभावसंशुद्धिरित्येतत्, तपो मानसमुच्यते॥17.16॥",
        
        "श्रद्धया परया तप्तं(न्), तपस्तत्त्रिविधं(न्) नरैः।\nअफलाकाङ्क्षिभिर्युक्तैः(स्), सात्त्विकं(म्) परिचक्षते॥17.17॥",
        
        "सत्कारमानपूजार्थं(न्), तपो दम्भेन चैव यत्।\nक्रियते तदिह प्रोक्तं(म्), राजसं(ञ्) चलमध्रुवम्॥17.18॥",
        
        "मूढग्राहेणात्मनो यत्, पीडया क्रियते तपः।\nपरस्योत्सादनार्थं(व्ँ) वा, तत्तामसमुदाहृतम्॥17.19॥",
        
        "दातव्यमिति यद्दानं(न्), दीयतेऽनुपकारिणे।\nदेशे काले च पात्रे च, तद्दानं(म्) सात्त्विकं(म्) स्मृतम्॥17.20॥",
        
        "यत्तु प्रत्युपकारार्थं(म्), फलमुद्दिश्य वा पुनः।\nदीयते च परिक्लिष्टं(न्), तद्दानं(म्) राजसं(म्) स्मृतम्॥17.21॥",
        
        "अदेशकाले यद्दानम्, अपात्रेभ्यश्च दीयते।\nअसत्कृतमवज्ञातं(न्), तत्तामसमुदाहृतम्॥17.22॥",
        
        "ॐ तत्सदिति निर्देशो, ब्रह्मणस्त्रिविधः(स्) स्मृतः।\nब्राह्मणास्तेन वेदाश्च, यज्ञाश्च विहिताः(फ्) पुरा॥17.23॥",
        
        "तस्मादोमित्युदाहृत्य, यज्ञदानतपः(ख्) क्रियाः।\nप्रवर्तन्ते विधानोक्ताः(स्), सततं(म्) ब्रह्मवादिनाम्॥17.24॥",
        
        "तदित्यनभिसन्धाय, फलं(य्ँ) यज्ञतपः(ख्) क्रियाः।\nदानक्रियाश्च विविधाः(ख्), क्रियन्ते मोक्षकाङ्क्षिभि:॥17.25॥",
        
        "सद्भावे साधुभावे च, सदित्येतत्प्रयुज्यते।\nप्रशस्ते कर्मणि तथा, सच्छब्दः(फ्) पार्थ युज्यते॥17.26॥",
        
        "यज्ञे तपसि दाने च, स्थितिः(स्) सदिति चोच्यते।\nकर्म चैव तदर्थीयं(म्), सदित्येवाभिधीयते॥17.27॥",
        
        "अश्रद्धया हुतं(न्) दत्तं(न्), तपस्तप्तं(ङ्) कृतं(ञ्) च यत्।\nअसदित्युच्यते पार्थ, न च तत्प्रेत्य नो इह17.28॥",
        
        "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु ब्रह्मविद्यायां(य्ँ) योगशास्त्रे\nश्रीकृष्णार्जुनसंवादे श्रद्धात्रयविभागयोगो नाम सप्तदशोऽध्याय:।"
      ],
      "adhyaya-18" : [
            
        "अर्जुन उवाच\n सन्न्यासस्य महाबाहो, तत्त्वमिच्छामि वेदितुम्।\nत्यागस्य च हृषीकेश, पृथक्केशिनिषूदन॥18.1॥",
        
        "श्रीभगवानुवाच\nकाम्यानां(ङ्) कर्मणां(न्) न्यासं(म्), सन्न्यासं(ङ्) कवयो विदुः।\nसर्वकर्मफलत्यागं(म्), प्राहुस्त्यागं(व्ँ) विचक्षणाः॥18.2॥",
        
        "त्याज्यं(न्) दोषवदित्येके, कर्म प्राहुर्मनीषिणः।\nयज्ञदानतपःकर्म, न त्याज्यमिति चापरे॥18.3॥",
        
        "निश्चयं(म्) शृणु मे तत्र, त्यागे भरतसत्तम।\nत्यागो हि पुरुषव्याघ्र, त्रिविधः(स्) सम्प्रकीर्तितः॥18.4॥",
        
        "यज्ञदानतपःकर्म, न त्याज्यं(ङ्) कार्यमेव तत्।\nयज्ञो दानं(न्) तपश्चैव, पावनानि मनीषिणाम्॥18.5॥",
        
        "एतान्यपि तु कर्माणि, सङ्गं(न्) त्यक्त्वा फलानि च।\nकर्तव्यानीति मे पार्थ, निश्चितं(म्) मतमुत्तमम्॥18.6॥",
        
        "नियतस्य तु सन्न्यासः(ख्), कर्मणो नोपपद्यते।\nमोहात्तस्य परित्याग:(स्), तामसः(फ्) परिकीर्तितः॥18.7॥",
        
        "दुःखमित्येव यत्कर्म, कायक्लेशभयात्त्यजेत्।\nस कृत्वा राजसं(न्) त्यागं(न्), नैव त्यागफलं(ल्ँ) लभेत् ॥18.8॥",
        
        "कार्यमित्येव यत्कर्म, नियतं(ङ्) क्रियतेऽर्जुन।\nसङ्गं(न्) त्यक्त्वा फलं(ञ्) चैव, स त्यागः(स्) सात्त्विको मतः॥18.9॥",
        
        "न द्वेष्ट्यकुशलं(ङ्) कर्म, कुशले नानुषज्जते।\nत्यागी सत्त्वसमाविष्टो, मेधावी छिन्नसंशयः॥18.10॥",
        
        "न हि देहभृता शक्यं(न्), त्यक्तुं(ङ्) कर्माण्यशेषतः।\nयस्तु कर्मफलत्यागी, स त्यागीत्यभिधीयते॥18.11॥",
        
        "अनिष्टमिष्टं(म्) मिश्रं(ञ्) च, त्रिविधं(ङ्) कर्मणः(फ्) फलम्।\nभवत्यत्यागिनां(म्) प्रेत्य, न तु सन्न्यासिनां(ङ्) क्वचित्॥18.12॥",
        
        "पञ्चैतानि महाबाहो, कारणानि निबोध मे।\nसाङ्ख्ये कृतान्ते प्रोक्तानि, सिद्धये सर्वकर्मणाम्॥18.13॥",
        
        "अधिष्ठानं(न्) तथा कर्ता, करणं(ञ्) च पृथग्विधम्।\nविविधाश्च पृथक्चेष्टा, दैवं(ञ्) चैवात्र पञ्चमम्॥18.14॥",
        
        "शरीरवाङ्मनोभिर्यत्, कर्म प्रारभते नरः।\nन्याय्यं(व्ँ) वा विपरीतं(व्ँ) वा, पञ्चैते तस्य हेतवः॥18.15॥",
        
        "तत्रैवं(म्) सति कर्तारम्, आत्मानं(ङ्) केवलं(न्) तु यः।\nपश्यत्यकृतबुद्धित्वान्, न स पश्यति दुर्मतिः॥18.16॥",
        
        "यस्य नाहङ्कृतो भावो, बुद्धिर्यस्य न लिप्यते।\nहत्वापि स इमाँल्लोकान्, न हन्ति न निबध्यते॥18.17॥",
        
        "ज्ञानं(ञ्) ज्ञेयं(म्) परिज्ञाता, त्रिविधा कर्मचोदना।\nकरणं(ङ्) कर्म कर्तेति, त्रिविधः(ख्) कर्मसङ्ग्रहः॥18.18॥",
        
        "ज्ञानं(ङ्) कर्म च कर्ता च, त्रिधैव गुणभेदतः।\nप्रोच्यते गुणसङ्ख्याने, यथावच्छृणु तान्यपि॥18.19॥",
        
        "सर्वभूतेषु येनैकं(म्), भावमव्ययमीक्षते।\nअविभक्तं(व्ँ) विभक्तेषु, तज्ज्ञानं(व्ँ) विद्धि सात्त्विकम्॥18.20॥",
        
        "पृथक्त्वेन तु यज्ज्ञानं(न्), नानाभावान्पृथग्विधान्।\nवेत्ति सर्वेषु भूतेषु,  तज्ज्ञानं(व्ँ)विद्धि राजसम्॥18.21॥",
        
        "यत्तु कृत्स्नवदेकस्मिन्, कार्ये सक्तमहैतुकम्।\nअतत्त्वार्थवदल्पं(ञ्) च, तत्तामसमुदाहृतम्॥18.22॥",
        
        "नियतं(म्) सङ्गरहितम्, अरागद्वेषतः(ख्) कृतम्।\nअफलप्रेप्सुना कर्म, यत्तत्सात्त्विकमुच्यते॥18.23॥",
        
        "यत्तु कामेप्सुना कर्म, साहङ्कारेण वा पुनः।\nक्रियते बहुलायासं(न्), तद्राजसमुदाहृतम्॥18.24॥",
        
        "अनुबन्धं(ङ्) क्षयं(म्) हिंसाम्, अनवेक्ष्य च पौरुषम्।\nमोहादारभ्यते कर्म, यत्तत्तामसमुच्यते॥18.25॥",
        
        "मुक्तसङ्गोऽनहंवादी, धृत्युत्साहसमन्वितः।\nसिद्ध्यसिद्ध्योर्निर्विकारः(ख्), कर्ता सात्त्विक उच्यते॥18.26॥",
        
        "रागी कर्मफलप्रेप्सु:(र्), लुब्धो हिंसात्मकोऽशुचिः।\nहर्षशोकान्वितः(ख्) कर्ता, राजसः(फ्) परिकीर्तितः॥18.27॥",
        
        "अयुक्तः(फ्) प्राकृतः(स्) स्तब्धः(श्), शठो नैष्कृतिकोऽलसः।\nविषादी दीर्घसूत्री च, कर्ता तामस उच्यते॥18.28॥",
        
        "बुद्धेर्भेदं(न्) धृतेश्चैव, गुणतस्त्रिविधं(म्) शृणु।\nप्रोच्यमानमशेषेण, पृथक्त्वेन धनञ्जय॥18.29॥",
        
        "प्रवृत्तिं(ञ्) च निवृत्तिं(ञ्) च, कार्याकार्ये भयाभये।\nबन्धं(म्) मोक्षं(ञ्) च या वेत्ति, बुद्धिः(स्) सा पार्थ सात्त्विकी॥18.30॥",
        
        "यया धर्ममधर्मं(ञ्) च, कार्यं(ञ्) चाकार्यमेव च।\nअयथावत्प्रजानाति, बुद्धिः(स्) सा पार्थ राजसी॥18.31॥",
        
        "अधर्मं(न्) धर्ममिति या, मन्यते तमसावृता।\nसर्वार्थान्विपरीतांश्च, बुद्धिः(स्) सा पार्थ तामसी॥18.32॥",
        
        "धृत्या यया धारयते, मनःप्राणेन्द्रियक्रियाः।\nयोगेनाव्यभिचारिण्या, धृतिः(स्) सा पार्थ सात्त्विकी॥18.33॥",
        
        "यया तु धर्मकामार्थान्, धृत्या धारयतेऽर्जुन।\nप्रसङ्गेन फलाकाङ्क्षी, धृतिः(स्) सा पार्थ राजसी॥18.34॥",
        
        "यया स्वप्नं(म्) भयं(म्) शोकं(व्ँ), विषादं(म्) मदमेव च।\nन विमुञ्चति दुर्मेधा, धृतिः(स्) सा पार्थ तामसी॥18.35॥",
        
        "सुखं(न्) त्विदानीं(न्) त्रिविधं(म्), शृणु मे भरतर्षभ।\nअभ्यासाद्रमते यत्र, दुःखान्तं(ञ्) च निगच्छति॥18.36॥",
        
        "यत्तदग्रे विषमिव, परिणामेऽमृतोपमम्।\nतत्सुखं(म्) सात्त्विकं(म्) प्रोक्तम्, आत्मबुद्धिप्रसादजम्॥18.37॥",
        
        "विषयेन्द्रियसंयोगाद्, यत्तदग्रेऽमृतोपमम्।\nपरिणामे विषमिव, तत्सुखं(म्) राजसं(म्) स्मृतम्॥18.38॥",
        
        "यदग्रे चानुबन्धे च, सुखं(म्) मोहनमात्मनः।\nनिद्रालस्यप्रमादोत्थं(न्), तत्तामसमुदाहृतम्॥18.39॥",
        
        "न तदस्ति पृथिव्यां(व्ँ) वा, दिवि देवेषु वा पुनः।\nसत्त्वं(म्) प्रकृतिजैर्मुक्तं(य्ँ), यदेभिः(स्) स्यात्त्रिभिर्गुणैः॥18.40॥",
        
        "ब्राह्मणक्षत्रियविशां(म्), शूद्राणां(ञ्) च परन्तप।\nकर्माणि प्रविभक्तानि, स्वभावप्रभवैर्गुणैः॥18.41॥",
        
        "शमो दमस्तपः(श्) शौचं(ङ्), क्षान्तिरार्जवमेव च।\nज्ञानं(व्ँ) विज्ञानमास्तिक्यं(म्), ब्रह्मकर्म स्वभावजम्॥18.42॥",
        
        "शौर्यं(न्) तेजो धृतिर्दाक्ष्यं(य्ँ), युद्धे चाप्यपलायनम्।\nदानमीश्वरभावश्च, क्षात्रं(ङ्) कर्म स्वभावजम्॥18.43॥",
        
        "कृषिगौरक्ष्यवाणिज्यं(व्ँ), वैश्यकर्म स्वभावजम्।\nपरिचर्यात्मकं(ङ्) कर्म, शूद्रस्यापि स्वभावजम्॥18.44॥",
        
        "स्वे स्वे कर्मण्यभिरतः(स्), संसिद्धिं(ल्ँ) लभते नरः।\nस्वकर्मनिरतः(स्) सिद्धिं(य्ँ), यथा विन्दति तच्छृणु॥18.45॥",
        
        "यतः(फ्) प्रवृत्तिर्भूतानां(य्ँ), येन सर्वमिदं(न्) ततम्।\nस्वकर्मणा तमभ्यर्च्य, सिद्धिं(व्ँ) विन्दति मानवः॥18.46॥",
        
        "श्रेयान्स्वधर्मो विगुणः(फ्), परधर्मात्स्वनुष्ठितात्।\nस्वभावनियतं(ङ्) कर्म, कुर्वन्नाप्नोति किल्बिषम्॥18.47॥",
        
        "सहजं(ङ्) कर्म कौन्तेय, सदोषमपि न त्यजेत्।\nसर्वारम्भा हि दोषेण, धूमेनाग्निरिवावृताः॥18.48॥",
        
        "असक्तबुद्धिः(स्) सर्वत्र, जितात्मा विगतस्पृहः।\nनैष्कर्म्यसिद्धिं(म्) परमां(म्), सन्न्यासेनाधिगच्छति॥18.49॥",
        
        "सिद्धिं(म्) प्राप्तो यथा ब्रह्म, तथाप्नोति निबोध मे।\nसमासेनैव कौन्तेय, निष्ठा ज्ञानस्य या परा॥18.50॥",
        
        "बुद्ध्या विशुद्धया युक्तो, धृत्यात्मानं(न्) नियम्य च।\nशब्दादीन्विषयांस्त्यक्त्वा, रागद्वेषौ व्युदस्य च॥18.51॥",
        
        "विविक्तसेवी लघ्वाशी, यतवाक्कायमानसः।\nध्यानयोगपरो नित्यं(व्ँ), वैराग्यं(म्) समुपाश्रितः॥18.52॥",
        
        "अहङ्कारं(म्) बलं(न्) दर्पं(ङ्), कामं(ङ्) क्रोधं(म्) परिग्रहम्।\nविमुच्य निर्ममः(श्) शान्तो, ब्रह्मभूयाय कल्पते॥18.53॥",
        
        "ब्रह्मभूतः(फ्) प्रसन्नात्मा, न शोचति न काङ्क्षति।\nसमः(स्) सर्वेषु भूतेषु, मद्भक्तिं(ल्ँ) लभते पराम्॥18.54॥",
        
        "भक्त्या मामभिजानाति, यावान्यश्चास्मि तत्त्वतः।\nततो मां(न्) तत्त्वतो ज्ञात्वा, विशते तदनन्तरम्॥18.55॥",
        
        "सर्वकर्माण्यपि सदा, कुर्वाणो मद् व्यपाश्रयः।\nमत्प्रसादादवाप्नोति, शाश्वतं(म्) पदमव्ययम्॥18.56॥",
        
        "चेतसा सर्वकर्माणि, मयि सन्न्यस्य मत्परः।\nबुद्धियोगमुपाश्रित्य, मच्चित्तः(स्) सततं(म्) भव॥18.57॥",
        
        "मच्चित्तः(स्) सर्वदुर्गाणि, मत्प्रसादात्तरिष्यसि।\nअथ चेत्त्वमहङ्कारान्, न श्रोष्यसि विनङ्क्ष्यसि॥18.58॥",
        
        "यदहङ्कारमाश्रित्य, न योत्स्य इति मन्यसे।\nमिथ्यैष व्यवसायस्ते, प्रकृतिस्त्वां(न्) नियोक्ष्यति॥18.59॥",
        
        "स्वभावजेन कौन्तेय, निबद्धः(स्) स्वेन कर्मणा।\nकर्तुं(न्) नेच्छसि यन्मोहात्, करिष्यस्यवशोऽपि तत्॥18.60॥",
        
        "ईश्वरः(स्) सर्वभूतानां(म्), हृद्देशेऽर्जुन तिष्ठति।\nभ्रामयन्सर्वभूतानि, यन्त्रारूढानि मायया॥18.61॥",
        
        "तमेव शरणं(ङ्) गच्छ, सर्वभावेन भारत।\nतत्प्रसादात्परां(म्) शान्तिं(म्), स्थानं(म्) प्राप्स्यसि शाश्वतम्॥18.62॥",
        
        "इति ते ज्ञानमाख्यातं(ङ्), गुह्याद्गुह्यतरं(म्) मया।\nविमृश्यैतदशेषेण, यथेच्छसि तथा कुरु॥18.63॥",
        
        "सर्वगुह्यतमं(म्) भूयः(श्), शृणु मे परमं(व्ँ) वचः।\nइष्टोऽसि मे दृढमिति, ततो वक्ष्यामि ते हितम्॥18.64॥",
        
        "मन्मना भव मद्भक्तो, मद्याजी मां(न्) नमस्कुरु।\nमामेवैष्यसि सत्यं(न्) ते, प्रतिजाने प्रियोऽसि मे॥18.65॥",
        
        "सर्वधर्मान्परित्यज्य, मामेकं(म्) शरणं(व्ँ) व्रज।\nअहं(न्) त्वा सर्वपापेभ्यो, मोक्षयिष्यामि मा शुचः॥18.66॥",
        
        "इदं(न्) ते नातपस्काय, नाभक्ताय कदाचन।\nन चाशुश्रूषवे वाच्यं(न्), न च मां(य्ँ) योऽभ्यसूयति॥18.67॥",
        
        "य इमं(म्) परमं(ङ्) गुह्यं(म्), मद्भक्तेष्वभिधास्यति।\nभक्तिं(म्) मयि परां(ङ्) कृत्वा, मामेवैष्यत्यसंशयः॥18.68॥",
        
        "न च तस्मान्मनुष्येषु, कश्चिन्मे प्रियकृत्तमः।\nभविता न च मे तस्माद्, अन्यः(फ्) प्रियतरो भुवि॥18.69॥",
        
        "अध्येष्यते च य इमं(न्), धर्म्यं(म्) संवादमावयोः।\nज्ञानयज्ञेन तेनाहम्, इष्टः(स्) स्यामिति मे मतिः॥18.70॥",
        
        "श्रद्धावाननसूयश्च, शृणुयादपि यो नरः।\nसोऽपि मुक्तः(श्) शुभाँल्लोकान्, प्राप्नुयात्पुण्यकर्मणाम्॥18.71॥",
        
        "कच्चिदेत?छ्रुतं(म्) पार्थ, त्वयैकाग्रेण चेतसा।\nकच्चिदज्ञानसम्मोहः(फ्), प्रनष्टस्ते धनञ्जय॥18.72॥",
        
        "अर्जुन उवाच\n नष्टो मोहः(स्) स्मृतिर्लब्धा, त्वत्प्रसादान्मयाच्युत।\nस्थितोऽस्मि गतसन्देहः(ख्), करिष्ये वचनं(न्) तव॥18.73॥",
        
        "सञ्जय उवाच\nइत्यहं(व्ँ) वासुदेवस्य, पार्थस्य च महात्मनः।\nसंवादमिममश्रौषम्, अद्भुतं(म्) रोमहर्षणम्॥18.74॥",
        
        "व्यासप्रसादाच्छ्रुतवान्, एतद्गुह्यमहं(म्) परम्।\nयोगं(य्ँ) योगेश्वरात्कृष्णात्, साक्षात्कथयतः(स्) स्वयम्॥18.75॥",
        
        "राजन्संस्मृत्य संस्मृत्य, संवादमिममद्भुतम्।\nकेशवार्जुनयोः(फ्) पुण्यं(म्), हृष्यामि च मुहुर्मुहुः॥18.76॥",
        
        "तच्च संस्मृत्य संस्मृत्य, रूपमत्यद्भुतं(म्) हरेः।\nविस्मयो मे महान् राजन्, हृष्यामि च पुनः(फ्) पुनः॥18.77॥",
        
        "यत्र योगेश्वरः(ख्) कृष्णो, यत्र पार्थो धनुर्धरः।\nतत्र श्रीर्विजयो भूति:(र्), ध्रुवा नीतिर्मतिर्मम॥18.78॥",
        
        "ॐ तत्सदिति श्रीमद्भगवद्गीतासु उपनिषत्सु ब्रह्मविद्यायां(य्ँ) योगशास्त्रे\nश्रीकृष्णार्जुनसंवादे मोक्षसन्न्यासयोगो नाम अष्टादशोऽध्यायः ॥ 18॥"  
      ]
    },
    {
      "adhyaya-1" : [
        "धृतराष्ट्र म्हणाला, हे संजया! धर्मभूमी असलेल्या कुरुक्षेत्रात युद्धाच्या इच्छेने एकत्र जमलेल्या माझ्या आणि पांडूच्या मुलांनी काय केले? 1.1",

        "संजय म्हणाला, तेव्हा राजा दुर्योधनाने व्यूहरचना केलेले पांडवांचे सैन्य पाहिले आणि द्रोणाचार्यांजवळ जाऊन तो म्हणाला. 1.2",

        "अहो आचार्य! तुमच्या बुद्धिमान शिष्याने - द्रुपदपुत्र धृष्टद्युम्नाने व्यूहरचना करून उभी केलेली ही पांडुपुत्रांची प्रचंड सेना पहा. 1.3",

        "या सैन्यात मोठमोठी धनुष्ये घेतलेले भीम, अर्जुन यांसारखे शूरवीर, सात्यकी, विराट, महारथी द्रुपद, धृष्टकेतू, चेकितान, बलवान काशिराज, पुरुजित, कुन्तिभोज, नरश्रेष्ठ शैब्य, पराक्रमी युधामन्यू, शक्तिमान उत्तमौजा, सुभद्रापुत्र अभिमन्यू आणि द्रौपदीचे पाच पुत्र हे-सर्वच महारथी आहेत. (1.4-1.6)",

        "या सैन्यात मोठमोठी धनुष्ये घेतलेले भीम, अर्जुन यांसारखे शूरवीर, सात्यकी, विराट, महारथी द्रुपद, धृष्टकेतू, चेकितान, बलवान काशिराज, पुरुजित, कुन्तिभोज, नरश्रेष्ठ शैब्य, पराक्रमी युधामन्यू, शक्तिमान उत्तमौजा, सुभद्रापुत्र अभिमन्यू आणि द्रौपदीचे पाच पुत्र हे-सर्वच महारथी आहेत. (1.4-1.6)",

        "या सैन्यात मोठमोठी धनुष्ये घेतलेले भीम, अर्जुन यांसारखे शूरवीर, सात्यकी, विराट, महारथी द्रुपद, धृष्टकेतू, चेकितान, बलवान काशिराज, पुरुजित, कुन्तिभोज, नरश्रेष्ठ शैब्य, पराक्रमी युधामन्यू, शक्तिमान उत्तमौजा, सुभद्रापुत्र अभिमन्यू आणि द्रौपदीचे पाच पुत्र हे-सर्वच महारथी आहेत. (1.4-1.6)",

        "अहो ब्राह्मणश्रेष्ठ! आपल्यातील जे महत्त्वाचे आहेत, ते जाणून घ्या. आपल्या माहितीसाठी माझ्या सैन्याचे जे जे सेनापती आहेत, ते मी आपल्याला सांगतो. 1.7",

        "आपण - द्रोणाचार्य, पितामह भीष्म, कर्ण, युद्धात विजयी होणारे कृपाचार्य, अश्वत्थामा, विकर्ण तसेच सोमदत्ताचा मुलगा भूरिश्रवा. 1.8",

        "इतरही माझ्यासाठी जिवावर उदार झालेले पुष्कळ शूरवीर आहेत. ते सर्वजण निरनिराळ्या शस्त्रास्त्रांनी सुसज्ज असून युद्धात पारंगत आहेत. 1.9",

        "भीष्मपितामहांनी रक्षण केलेले आमचे हे सैन्य सर्व दृष्टींनी अजिंक्य आहे; तर भीमाने रक्षण केलेले यांचे सैन्य जिंकायला सोपे आहे. 1.10",

        "म्हणून सर्व व्यूहांच्या प्रवेशद्वारांत आपापल्या जागेवर राहून आपण सर्वांनीच निःसंदेह भीष्म पितामहांचेच सर्व बाजूंनी रक्षण करावे. 1.11",

        "कौरवांतील वृद्ध, महापराक्रमी, पितामह भीष्मांनी त्या दुर्योधनाच्या अन्तःकरणात आनन्द निर्माण करीत मोठ्याने सिंहासारखी गर्जना करून शंख वाजवला. 1.12",

        "त्यानंतर शंख, नगारे, ढोल, मृदंग, शिंगे इ. वाद्ये एकदम वाजू लागली. त्यांचा प्रचंड आवाज झाला. 1.13",

        "त्यानंतर पांढरे घोडे जोडलेल्या उत्तम रथात बसलेल्या श्रीकृष्णांनी आणि अर्जुनानेही दिव्य शंख वाजवले. 1.14",

        "श्रीकृष्णांनी पाञ्चजन्य नावाचा, अर्जुनाने देवदत्त नावाचा आणि भयानक कृत्ये करणाऱ्या भीमाने पौण्ड्र नावाचा मोठा शंख फुंकला. 1.15",

        "कुन्तीपुत्र राजा युधिष्ठिराने अनन्तविजय नावाचा आणि नकुल व सहदेव यांनी सुघोष व मणिपुष्पक नावाचे शंख वाजवले. 1.16",

        "श्रेष्ठ धनुष्य असलेला काशिराज, महारथी शिखण्डी, धृष्टद्युम्न, राजा विराट, अजिंक्य सात्यकी, राजा द्रुपद, द्रौपदीचे पाचही पुत्र, महाबाहू सुभद्रापुत्र अभिमन्यू या सर्वांनी हे राजा! सर्व बाजूंनी वेगवेगळे शंख वाजवले. (1.17-1.18)",

        "श्रेष्ठ धनुष्य असलेला काशिराज, महारथी शिखण्डी, धृष्टद्युम्न, राजा विराट, अजिंक्य सात्यकी, राजा द्रुपद, द्रौपदीचे पाचही पुत्र, महाबाहू सुभद्रापुत्र अभिमन्यू या सर्वांनी हे राजा! सर्व बाजूंनी वेगवेगळे शंख वाजवले. (1.17-1.18)",

        "आणि त्या भयानक आवाजाने आकाश व पृथ्वीला दुमदुमून टाकीत कौरवांची अर्थात् आपल्या पक्षातील लोकांची छाती दडपून टाकली. 1.19",

        "महाराज! त्यानंतर ध्वजावर हनुमान् असणाऱ्या अर्जुनाने युद्धाच्या तयारीने उभ्या असलेल्या कौरवांना पाहून, शस्त्रांचा वर्षाव होण्याची वेळ आली तेव्हा धनुष्य उचलून तो हृषीकेश श्रीकृष्णांना असे म्हणाला- हे अच्युता! माझा रथ दोन्ही सैन्यांच्या मध्यभागी उभा करा. (1.20-1.21)",

        "महाराज! त्यानंतर ध्वजावर हनुमान् असणाऱ्या अर्जुनाने युद्धाच्या तयारीने उभ्या असलेल्या कौरवांना पाहून, शस्त्रांचा वर्षाव होण्याची वेळ आली तेव्हा धनुष्य उचलून तो हृषीकेश श्रीकृष्णांना असे म्हणाला- हे अच्युता! माझा रथ दोन्ही सैन्यांच्या मध्यभागी उभा करा. (1.20-1.21)",

        "मी रणभूमीवर युद्धाच्या इच्छेने सज्ज झालेल्या या शत्रुपक्षाकडील योद्ध्यांना जोवर नीट पाहून घेईन की, मला या युद्धाच्या उद्योगात कोणाकोणाशी लढणे योग्य आहे, तोवर रथ उभा करा. 1.22",

        "दुष्टबुद्धी दुर्योधनाचे युद्धात प्रिय करू इच्छणारे जे जे हे राजे या सैन्यात आले आहेत, त्या योद्ध्यांना मी पाहतो. 1.23",

        "संजय म्हणाला, धृतराष्ट्रमहाराज! अर्जुनाने असे सांगितल्यावर श्रीकृष्णांनी दोन्ही सैन्यांच्या मध्यभागी भीष्म, द्रोणाचार्य व इतर सर्व राजांसमोर उत्तम रथ उभा करून म्हटले, हे पार्था! युद्धासाठी जमलेल्या या कौरवांना पहा. (1.24-1.25)",

        "संजय म्हणाला, धृतराष्ट्रमहाराज! अर्जुनाने असे सांगितल्यावर श्रीकृष्णांनी दोन्ही सैन्यांच्या मध्यभागी भीष्म, द्रोणाचार्य व इतर सर्व राजांसमोर उत्तम रथ उभा करून म्हटले, हे पार्था! युद्धासाठी जमलेल्या या कौरवांना पहा. (1.24-1.25)",

        "त्यानंतर कुंतीपुत्र अर्जुनाने त्या दोन्ही सैन्यांमध्ये असलेल्या काका, आजे-पणजे, गुरू, मामा, भाऊ, मुलगे, नातू, मित्र, सासरे आणि हितचिंतक यांना पाहिले. 1.26",

        "तेथे असलेल्या त्या सर्व बान्धवांना पाहून अत्यंत करुणेने व्याप्त झालेला कुन्तीपुत्र अर्जुन शोकाकुल होऊन असे म्हणाला. 1.27",

        "अर्जुन म्हणाला, हे कृष्णा! युद्धाच्या इच्छेने रणांगणावर उभ्या असलेल्या या स्वजनांना पाहून माझे अवयव गळून जात आहेत; तोंडाला कोरड पडली आहे; शरीराला कंप सुटला आहे आणि अंगावर रोमांच उभे राहात आहेत. (1.28-1.29)",

        "अर्जुन म्हणाला, हे कृष्णा! युद्धाच्या इच्छेने रणांगणावर उभ्या असलेल्या या स्वजनांना पाहून माझे अवयव गळून जात आहेत; तोंडाला कोरड पडली आहे; शरीराला कंप सुटला आहे आणि अंगावर रोमांच उभे राहात आहेत. (1.28-1.29)",

        "हातातून गाण्डीव धनुष्य गळून पडत आहे. अंगाचा दाह होत आहे. तसेच माझे मन भ्रमिष्टासारखे झाले आहे. त्यामुळे मी उभादेखील राहू शकत नाही. 1.30",

        "हे केशवा, मला विपरीत चिन्हे दिसत आहेत. युद्धात आप्तांना मारून कल्याण होईल, असे मला वाटत नाही. 1.31",

        "हे कृष्णा! मला तर विजयाची इच्छा नाही, राज्याची नाही की सुखाचीही नाही. हे गोविन्दा! आम्हांला असे राज्य काय करायचे? अशा भोगांनी आणि जगण्याने तरी काय लाभ होणार आहे? 1.32",

        "आम्हांला ज्यांच्यासाठी राज्य, भोग आणि सुखादी अपेक्षित आहेत, तेच हे सर्वजण संपत्तीची आणि जीविताची आशा सोडून युद्धात उभे ठाकले आहेत. 1.33",

        "गुरुजन, काका, मुलगे, आजे, मामा, सासरे, नातू, मेहुणे, त्याचप्रमाणे इतर आप्त आहेत. 1.34",

        "हे मधुसूदना! हे मला मारण्यास तयार झाले तरी किंवा त्रैलोक्याच्या राज्यासाठीही मी या सर्वांना मारू इच्छित नाही. मग पृथ्वीची काय कथा? 1.35",

        "हे जनार्दना! धृतराष्ट्राच्या मुलांना मारून आम्हांला कोणते सुख मिळणार? या आततायींना मारून आम्हाला पापच लागणार. 1.36",

        "म्हणूनच हे माधवा! आपल्या बान्धवांना-धृतराष्ट्रपुत्रांना आम्ही मारणे योग्य नाही. कारण आपल्याच कुटुंबियांना मारून आम्ही कसे सुखी होणार? 1.37",

        "जरी लोभामुळे बुद्धी भ्रष्ट झालेल्या यांना कुळाचा नाश झाल्यामुळे उत्पन्न होणारा दोष आणि मित्रांशी वैर करण्याचे पातक दिसत नसले तरी हे जनार्दना! कुळाच्या नाशाने उत्पन्न होणारा दोष स्पष्ट दिसत असतानाही आम्ही या पापापासून परावृत्त होण्याचा विचार का बरे करू नये? (1.38-1.39)",

        "जरी लोभामुळे बुद्धी भ्रष्ट झालेल्या यांना कुळाचा नाश झाल्यामुळे उत्पन्न होणारा दोष आणि मित्रांशी वैर करण्याचे पातक दिसत नसले तरी हे जनार्दना! कुळाच्या नाशाने उत्पन्न होणारा दोष स्पष्ट दिसत असतानाही आम्ही या पापापासून परावृत्त होण्याचा विचार का बरे करू नये? (1.38-1.39)",

        "कुळाचा नाश झाला असता परंपरागत कुळधर्म नाहीसे होतात. कुळधर्म नाहीसे झाले असता त्या कुळात मोठ्या प्रमाणात पाप फैलावते. 1.40",

        "हे कृष्णा! पाप अधिक वाढल्याने कुळातील स्त्रिया अतिशय बिघडतात आणि हे वार्ष्णेया! स्त्रिया बिघडल्या असता वर्णव्यवस्थेचा नाश होतो. 1.41",

        "वर्णसंकर हा कुळाचा नाश करणाऱ्यांना आणि कुळाला नरकालाच नेतो. कारण श्राद्ध, जलतर्पण इत्यादींना मुकलेले यांचे पितर अधोगतीला जातात. 1.42",

        "या वर्णसंकर करणाऱ्या दोषांमुळे परंपरागत जातिधर्म आणि कुळधर्म उध्वस्त होतात. 1.43",

        "हे जनार्दना! ज्यांचे कुळधर्म नाहीसे झाले आहेत, अशा माणसांना अनिश्चित काळपर्यंत नरकात पडावे लागते, असे आम्ही ऐकत आलो आहोत. 1.44",

        "अरेरे! किती खेदाची गोष्ट आहे! आम्ही बुद्धिमान असून राज्य आणि सुख यांच्या लोभाने कुटुंबियांना ठार मारायला तयार झालो, हे केवढे मोठे पाप करायला उद्युक्त झालो बरे! 1.45",

        "जरी शस्त्ररहित व प्रतीकार न करणाऱ्या मला हातात शस्त्र घेतलेल्या धृतराष्ट्रपुत्रांनी रणात ठार मारले, तरी ते मला अधिक कल्याणकारक ठरेल. 1.46",

        "संजय म्हणाला, रणांगणावर दुःखाने उद्विग्न मन झालेला अर्जुन एवढे बोलून बाणासह धनुष्य टाकून देऊन रथाच्या मागील भागात जाऊन बसला. 1.47",
        
        "इस प्रकार ॐ तत  सत - इन भगवन्नामों के उच्चारणपूर्वक ब्रह्मविद्या और योगशास्त्रमय श्रीमद्भगवद्गीतोपनिषदरूप श्रीकृष्णार्जुनसंवाद में ‘अर्जुनविषादयोगनामक’ पहला अध्याय पूर्ण हुआ।"
      ],
      "adhyaya-2" : [
        "संजय म्हणाला, अशा रीतीने करुणेने व्याप्त, ज्याचे डोळे आसवांनी भरलेले व व्याकुळ दिसत आहेत, अशा शोक करणाऱ्या अर्जुनाला भगवान मधुसूदन असे म्हणाले. 2.1",

        "श्रीभगवान म्हणाले, हे अर्जुना! या भलत्याच वेळी हा मोह तुला कशामुळे उत्पन्न झाला? कारण हा थोरांनी न आचरिलेला, स्वर्ग मिळवून न देणारा आणि कीर्तिकारकही नाही. 2.2",

        "म्हणून हे पार्था! षंढपणा पत्करू नकोस. हा तुला शोभत नाही. हे परंतपा! अंतःकरणाचा तुच्छ दुबळेपणा सोडून देऊन युद्धाला उभा राहा. 2.3",

        "अर्जुन म्हणाला, हे मधुसूदना! युद्धात मी भीष्मपितामहांच्या आणि द्रोणाचार्यांच्या विरुद्ध बाणांनी कसा लढू? कारण हे अरिसूदना! ते दोघेही पूज्य आहेत. 2.4",

        "म्हणून या महानुभाव गुरुजनांना न मारता मी या जगात भिक्षा मागून खाणेही कल्याणकारक समजतो. कारण गुरुजनांना मारूनही या लोकात रक्ताने माखलेले अर्थ व कामरूप भोगच ना भोगावयाचे! 2.5",

        "युद्ध करणे व न करणे या दोहोंपैकी आम्हांला काय श्रेष्ठ आहे, हे कळत नाही. किंवा आम्ही त्यांना जिंकू की ते आम्हांला जिंकतील, हेही आम्हाला माहीत नाही. आणि ज्यांना मारून आम्हांला जगण्याचीही इच्छा नाही, तेच आमचे बांधव-धृतराष्ट्रपुत्र आमच्या विरुद्ध उभे आहेत. 2.6",

        "करुणाव्याप्त दैन्यामुळे ज्याचा मूळ स्वभाव नाहीसा झाला आहे व धर्माधर्माचा निर्णय करण्याविषयी ज्याची बुद्धी असमर्थ आहे, असा मी तुम्हांला विचारीत आहे की, जे साधन खात्रीने कल्याणकारक आहे, ते मला सांगा. कारण मी तुमचा शिष्य आहे. म्हणून तुम्हांला शरण आलेल्या मला उपदेश करा. 2.7",

        "कारण, पृथ्वीचे शत्रुरहित व धन-धान्यसमृद्ध राज्य मिळाले किंवा देवांचे स्वामित्व जरी मिळाले, तरी माझ्या इंद्रियांना शोषून टाकणारा शोक जो दूर करू शकेल, असा उपाय मला दिसत नाही. 2.8",

        "संजय म्हणाला, हे राजा! निद्रेवर ताबा असलेल्या अर्जुनाने अन्तर्यामी श्रीकृष्णाला एवढे बोलून 'मी युद्ध करणार नाही' असे स्पष्टपणे सांगितले व तो गप्प झाला. 2.9",

        "हे भरतवंशी धृतराष्ट्र महाराज! अन्तर्यामी भगवान श्रीकृष्ण दोन्ही सैन्यांच्या मध्यभागी शोक करणाऱ्या त्या अर्जुनाला हसल्यासारखे करून असे म्हणाले. 2.10",

        "श्रीभगवान् म्हणाले, हे अर्जुना! तू ज्यांचा शोक करू नये, अशा माणसांसाठी शोक करतोस आणि विद्वानांसारखा युक्तिवाद करतोस. परन्तु ज्यांचे प्राण गेले आहेत त्यांच्यासाठी आणि ज्यांचे प्राण गेले नाहीत, त्यांच्यासाठीही विद्वान माणसे शोक करीत नाहीत. 2.11",

        "मी कोणत्याही काळी नव्हतो, तू नव्हतास किंवा हे राजे नव्हते असेही नाही आणि यापुढे आम्ही सर्वजण असणार नाही, असेही नाही. 2.12",

        "ज्याप्रमाणे जीवात्म्याला या शरीरात बालपण, तारुण्य आणि वार्धक्य येते, त्याचप्रमाणे दुसरे शरीर मिळते. याविषयी धीर पुरुषांना मोह उत्पन्न होत नाही. 2.13",

        "हे कुन्तीपुत्रा! इंद्रियांचे विषयांशी संयोग हे थंडी-उष्णता आणि सुख-दुःख देणारे आहेत. ते उत्पन्न होतात व नाहीसे होतात म्हणून अनित्य आहेत. तेव्हा हे भारता! ते तू सहन कर. 2.14",

        "कारण हे श्रेष्ठ पुरुषा! सुख-दुःख समान मानणाऱ्या ज्या धीर पुरुषाला हे इंद्रियांचे विषयांशी संयोग व्याकुळ करीत नाहीत, तो मोक्षाला योग्य ठरतो. 2.15",

        "असत् वस्तूला अस्तित्व नाही आणि सत् वस्तूचा अभाव नसतो. अशा रीतीने या दोहोंचेही सत्य स्वरूप तत्त्वज्ञानी पुरुषांनी अस्तित्व पाहिले आहे. 2.16",

        "ज्याने हे सर्व जग - दिसणाऱ्या सर्व वस्तू - व्यापल्या आहेत, त्याचा नाश नाही, हे तू लक्षात ठेव. त्या अविनाशीचा नाश कोणीही करू शकत नाही. 2.17",

        "या नाशरहित, मोजता न येणाऱ्या, नित्यस्वरूप जीवात्म्याची ही शरीरे नाशिवंत आहेत, असे म्हटले गेले आहे. म्हणून हे भरतवंशी अर्जुना! तू युद्ध कर. 2.18",

        "जो या आत्म्याला मारणारा असे समजतो, तसेच जो 'हा (आत्मा) मेला' असे मानतो, ते दोघेही अज्ञानी आहेत. कारण हा आत्मा वास्तविक पाहता कोणाला मारीत नाही आणि कोणा कडून मारला जात नाही. 2.19",

        "हा आत्मा कधीही जन्मत नाही आणि मरतही नाही. तसेच हा एकदा उत्पन्न झाल्यावर पुन्हा उत्पन्न होणारा नाही. कारण हा जन्म नसलेला, नित्य, सनातन आणि प्राचीन आहे. शरीर मारले गेले तरी हा मारला जात नाही. 2.20",

        "हे पार्था! जो पुरुष, \"हा आत्मा नाशरहित, नित्य, न जन्मणारा व न बदलणारा आहे\", हे जाणतो, तो कोणाला कसा ठार करवील किंवा कोणाला कसा ठार करील? 2.21",

        "ज्याप्रमाणे माणूस जुनी वस्त्रे टाकून देऊन दुसरी नवी वस्त्रे घेतो, त्याचप्रमाणे जीवात्मा जुनी शरीरे टाकून दुसऱ्या नव्या शरीरात जातो. 2.22",

        "या आत्म्याला शस्त्रे कापू शकत नाहीत, विस्तव जाळू शकत नाही, पाणी भिजवू शकत नाही आणि वारा वाळवू शकत नाही. 2.23",

        "कारण हा आत्मा कापता न येणारा, जाळता न येणारा, भिजवता न येणारा आणि निःसंशय वाळवता न येणारा आहे. तसेच हा आत्मा नित्य, सर्वव्यापी, अचल, स्थिर राहणारा आणि सनातन आहे. 2.24",

        "हा आत्मा अव्यक्त आहे, अचिन्त्य आहे आणि विकाररहित आहे, असे म्हटले जाते. म्हणून हे अर्जुना! हा आत्मा वर सांगितल्याप्रमाणे आहे, हे लक्षात घेऊन तू शोक करणे योग्य नाही. 2.25",

        "परन्तु, जर तू आत्मा नेहमी जन्मणारा व नेहमी मरणारा आहे, असे मानत असशील, तरीसुद्धा हे महाबाहो! तू अशा रीतीने शोक करणे योग्य ही. 2.26",

        "कारण असे मानल्यास त्यानुसार जन्मास आलेल्याला मृत्यू निश्चित आहे आणि मेलेल्याला जन्म निश्चित आहे. म्हणून या उपाय नसलेल्या गोष्टींविषयीही तू शोक करणे योग्य नाही. 2.27",

        "हे अर्जुना! सर्व प्राणी जन्मापूर्वी अप्रगट असतात आणि मेल्यानंतरही अप्रगट होणार असतात. फक्त मध्ये प्रगट असतात. मग अशा स्थितीत शोक कसला करायचा? 2.28",

        "एखादा महापुरुषच या आत्म्याला आश्चर्याप्रमाणे पाहतो आणि तसाच दुसरा एखादा महापुरुष या तत्त्वाचे आश्चर्याप्रमाणे वर्णन करतो. तसेच आणखी एखादा अधिकारी पुरुषच याच्याविषयी आश्चर्याप्रमाणे ऐकतो आणि कोणी कोणी तर ऐकूनही याला जाणत नाहीत. 2.29",

        "हे अर्जुना! हा आत्मा सर्वांच्या शरीरात नेहमीच अवध्य असतो. म्हणून सर्व प्राण्यांच्या बाबतीत तू शोक करणे योग्य नाही. 2.30",

        "तसेच स्वतःचा धर्म लक्षात घेऊनही तू भिता कामा नये. कारण क्षत्रियाला, धर्माला अनुसरून असलेल्या युद्धाहून दुसरे कोणतेही कल्याणकारक कर्तव्य नाही. 2.31",

        "हे पार्था! आपोआप समोर आलेले, उघडलेले स्वर्गाचे दारच असे हे युद्ध भाग्यवान क्षत्रियांनाच लाभते. 2.32",

        "परंतु जर तू हे धर्मयुक्त युद्ध केले नाहीस तर स्वधर्म आणि कीर्ती गमावून पापाला जवळ करशील. 2.33",

        "तसेच सर्व लोक तुझी चिरकाल अपकीर्ती सांगत राहतील. आणि सन्माननीय पुरुषाला अपकीर्ती मरणाहून दुःसह वाटते. 2.34",

        "शिवाय ज्यांच्या दृष्टीने तू आधी अतिशय आदरणीय होतास, त्यांच्या दृष्टीने आता तुच्छ ठरशील. ते महारथी तुला 'भिऊन युद्धातून काढता पाय घेतला,' असे मानतील. 2.35",

        "तुझे शत्रू तुझ्या सामर्थ्याची निन्दा करीत तुला पुष्कळसे नको नको ते बोलतील. याहून अधिक दुःखदायक काय असणार आहे? 2.36",

        "युद्धात तू मारला गेलास तर स्वर्गाला जाशील अथवा युद्धात जिंकलास तर पृथ्वीचे राज्य भोगशील. म्हणून हे अर्जुना! तू युद्धाचा निश्चय करून उभा रहा. 2.37",

        "जय-पराजय, फायदा-तोटा आणि सुख-दुःख समान मानून युद्धाला तयार हो. अशा रीतीने युद्ध केलेस तर तुला पाप नाही लागणार. 2.38",

        "हे पार्था! हा विचार तुला ज्ञानयोगाच्या संदर्भात सांगितला. आणि आता कर्मयोगाविषयी ऐक. ज्या बुद्धीने युक्त झाला असता तू कर्माचे बंधन चांगल्या प्रकारे तोडून टाकशील. 2.39",

        "या कर्मयोगात आरंभाचा अर्थात् बीजाचा नाश नाही. आणि उलट फळरूप दोषही नाही. इतकेच नव्हे तर, या कर्मयोगरूप धर्माचे थोडेसेही साधन जन्ममृत्युरूप मोठ्या भयापासून रक्षण करते. 2.40",

        "हे अर्जुना! या कर्मयोगात निश्चयात्मक बुद्धी एकच असते. परन्तु अस्थिर विचार असणाऱ्या अविचारी, कामनायुक्त माणसांच्या बुद्धी खात्रीने पुष्कळ फाटे फुटलेल्या व असंख्य असतात. 2.41",

        "हे अर्जुना, जे भोगांत रमलेले असतात, कर्मफलाची स्तुती करणाऱ्या वेदवाक्यांची ज्यांना आवड आहे, ज्यांच्या मते स्वर्ग हीच श्रेष्ठ मिळवण्याजोगी वस्तू आहे, स्वर्गाहून श्रेष्ठ दुसरी कोणतीही गोष्ट नाही, असे जे सांगतात, ते अविवेकी लोक अशा प्रकारची जी पुष्पित म्हणजे दिखाऊ शोभायुक्त भाषा बोलत असतात, त्यांची ही भाषा जन्मरूप कर्मफल देणारी तसेच भोग व ऐश्वर्य मिळण्यासाठी अनेक प्रकारच्या क्रियांचे वर्णन करणारी असते. या भाषेने ज्यांचे अन्तःकरण आकृष्ट करून घेतले आहे, जे भोग व ऐश्वर्यात अत्यंत आसक्त आहेत, अशा पुरुषांची परमात्म्याविषयी निश्चयी बुद्धी असत नाही. (2.42-2.44)",

        "हे अर्जुना, जे भोगांत रमलेले असतात, कर्मफलाची स्तुती करणाऱ्या वेदवाक्यांची ज्यांना आवड आहे, ज्यांच्या मते स्वर्ग हीच श्रेष्ठ मिळवण्याजोगी वस्तू आहे, स्वर्गाहून श्रेष्ठ दुसरी कोणतीही गोष्ट नाही, असे जे सांगतात, ते अविवेकी लोक अशा प्रकारची जी पुष्पित म्हणजे दिखाऊ शोभायुक्त भाषा बोलत असतात, त्यांची ही भाषा जन्मरूप कर्मफल देणारी तसेच भोग व ऐश्वर्य मिळण्यासाठी अनेक प्रकारच्या क्रियांचे वर्णन करणारी असते. या भाषेने ज्यांचे अन्तःकरण आकृष्ट करून घेतले आहे, जे भोग व ऐश्वर्यात अत्यंत आसक्त आहेत, अशा पुरुषांची परमात्म्याविषयी निश्चयी बुद्धी असत नाही. (2.42-2.44)",

        "हे अर्जुना, जे भोगांत रमलेले असतात, कर्मफलाची स्तुती करणाऱ्या वेदवाक्यांची ज्यांना आवड आहे, ज्यांच्या मते स्वर्ग हीच श्रेष्ठ मिळवण्याजोगी वस्तू आहे, स्वर्गाहून श्रेष्ठ दुसरी कोणतीही गोष्ट नाही, असे जे सांगतात, ते अविवेकी लोक अशा प्रकारची जी पुष्पित म्हणजे दिखाऊ शोभायुक्त भाषा बोलत असतात, त्यांची ही भाषा जन्मरूप कर्मफल देणारी तसेच भोग व ऐश्वर्य मिळण्यासाठी अनेक प्रकारच्या क्रियांचे वर्णन करणारी असते. या भाषेने ज्यांचे अन्तःकरण आकृष्ट करून घेतले आहे, जे भोग व ऐश्वर्यात अत्यंत आसक्त आहेत, अशा पुरुषांची परमात्म्याविषयी निश्चयी बुद्धी असत नाही. (2.42-2.44)",

        "हे अर्जुना! वेद वर सांगितल्याप्रमाणे तिन्ही गुणांची कार्ये असणारे सर्व भोग आणि त्यांची साधने सांगणारे आहेत. म्हणून तू ते भोग आणि त्यांच्या साधनांच्या बाबतीत आसक्ती बाळगू नकोस. तसेच सुख दुःखादी द्वंद्वांनी रहित, नित्यवस्तू असणाऱ्या परमात्म्यात स्थित, योगक्षेमाची इच्छा न बाळगणारा आणि अन्तःकरणाला ताब्यात ठेवणारा हो. 2.45",

        "सर्व बाजूंनी भरलेला मोठा जलाशय मिळाल्यावर लहान जलाशयाची मनुष्याला जेवढी गरज असते, तेवढीच चांगल्या प्रकारे ब्रह्म जाणणाऱ्या ब्राह्मणाला वेदांची गरज उरते. 2.46",

        "तुला कर्म करण्याचाच अधिकार आहे. त्याच्या फळाविषयी कधीही नाही. म्हणून तू कर्मांच्या फळांची इच्छा करणारा होऊ नकोस. तसेच कर्म न करण्याचाही आग्रह धरू नकोस. 2.47",

        "आसक्ती सोडून तसेच सिद्धी आणि असिद्धीमध्ये समान भाव ठेवून योगात स्थिर होऊन कर्तव्य कर्मे कर. समत्वालाच योग म्हटले आहे. 2.48",

        "द्धियोगापेक्षा सकाम कर्म अत्यन्त तुच्छ आहे. म्हणून हे धनंजया! तू समबुद्धीतच रक्षणाचा उपाय शोध म्हणजे बुद्धियोगाचाच आश्रय घे. कारण फळाची इच्छा बाळगणारे अत्यन्त दीन होत. 2.49",

        "पुण्य व पाप या दोहींचाही याच जगात त्याग करतो. अर्थात् त्यांपासून मुक्त असतो. म्हणून तू समत्वरूप योगाला चिकटून राहा. हा समत्वरूप योगच कर्मांतील दक्षता आहे, म्हणजेच कर्मबन्धनातून सुटण्याचा उपाय आहे. 2.50",

        "युक्त असलेले ज्ञानी लोक कर्मापासून उत्पन्न होणाऱ्या फळाचा त्याग करून जन्मरूप बंधनापासून मुक्त होऊन निर्विकार परमपदाला प्राप्त होतात. 2.51",

        "मोहरूपी चिखलाला पूर्णपणे पार करून जाईल, तेव्हा तू ऐकलेल्या व ऐकण्यासारख्या इह-परलोकातील सर्व भोगांपासून विरक्त होशील. 2.52",

        "ने ऐकून विचलित झालेली तुझी बुद्धी जेव्हा परमात्म्यात अचलपणे स्थिर राहील, तेव्हा तू योगाला प्राप्त होशील म्हणजेच तुझा परमात्म्याशी नित्यसंयोग होईल. 2.53",

        "हे केशवा! जो समाधीत परमात्म्याला प्राप्त झालेला आहे, अशा स्थिरबुद्धी पुरुषाचे लक्षण काय? तो स्थिरबुद्धी पुरुष कसा बोलतो, कसा बसतो, आणि कसा चालतो? 2.54",

        "ले, हे अर्जुना! ज्या वेळी हा पुरुष मनातील सर्व कामना पूर्णपणे टाकतो आणि आत्म्यानेच आत्म्याच्या ठिकाणी संतुष्ट राहतो, त्यावेळी त्याला स्थितप्रज्ञ म्हटले जाते. 2.55",

        "ज्याच्या मनाला खेद वाटत नाही, सुखांच्या प्राप्तीविषयी ज्याला मुळीच इच्छा नाही, तसेच ज्याचे प्रीती, भय व क्रोध नाहीसे झाले आहेत, असा मुनी स्थिरबुद्धी म्हटला जातो. 2.56",

        "बाबतीत स्नेहशून्य असून त्या त्या शुभ किंवा अशुभ गोष्टी घडल्या असता प्रसन्नही होत नाही किंवा त्यांचा द्वेषही करीत नाही, त्याची बुद्धी स्थिर झाली. 2.57",

        "नी आपले अवयव जसे आत ओढून धरतो, त्याचप्रमाणे जेव्हा हा पुरुष इन्द्रियांच्या विषयांपासून इंद्रियांना सर्व प्रकारे आवरून घेतो, तेव्हा त्याची बुद्धी स्थिर झाली, असे समजावे. 2.58",

        "सेवन न करणाऱ्या पुरुषाचेही केवळ विषयच दूर होतात; परंतु त्यांच्याविषयीची आवड नाहीशी होत नाही. या स्थितप्रज्ञ पुरुषाची तर आसक्तीही परमात्म्याच्या साक्षात्काराने नाहीशी होते. 2.59",

        "क्ती नाहीशी न झाल्यामुळे ही क्षोभ उत्पन्न करणारी इन्द्रिये प्रयत्न करीत असलेल्या बुद्धिमान पुरुषाच्या मनालाही जबरदस्तीने आपल्याकडे ओढून घेतात. 2.60",

        "त्या सर्व इन्द्रियांना ताब्यात ठेवून, चित्त स्थिर करून, मनाला माझाच आधार देऊन ध्यानात बसावे. कारण इन्द्रिये ज्या पुरुषाच्या ताब्यात असतात, त्याची बुद्धी स्थिर होते. 2.61",

        "करणाऱ्या पुरुषाची त्या विषयांत आसक्ती उत्पन्न होते. आसक्तीमुळे त्या विषयांची कामना उत्पन्न होते. कामना पूर्ण झाल्या नाहीत की राग येतो. 2.62",

        "मूढता अर्थात् अविचार उत्पन्न होतो. मूढतेमुळे स्मरणशक्ती भ्रष्ट होते. स्मरणशक्ती भ्रष्ट झाली की बुद्धीचा म्हणजे ज्ञानशक्तीचा नाश होतो. आणि बुद्धीचा नाश झाल्यामुळे माणसाचा अधःपात होतो. 2.63",

        "ण ताब्यात ठेवलेला साधक आपल्या ताब्यात ठेवलेल्या राग-द्वेष-रहित इन्द्रियांनी विषयांचा उपभोग घेत असूनही अन्तःकरणाची प्रसन्नता प्राप्त करून घेतो. 2.64",

        "न्न असल्यामुळे त्याची सर्व दुःखे नाहीशी होतात. आणि त्या चित्त प्रसन्न असलेल्या कर्मयोग्याची बुद्धी तत्काळ सर्व गोष्टींपासून निवृत्त होऊन एका परमात्म्यामध्येच उत्तम प्रकारे स्थिर होते. 2.65",

        "ये न जिंकणाऱ्या पुरुषाच्या ठिकाणी निश्चयात्मक बुद्धी नसते आणि अशा अयुक्त पुरुषाच्या अन्तःकरणात आस्तिक भावही नसतो. तसेच भावशून्य माणसाला शान्ती मिळत नाही. मग शान्ती नसलेल्या माणसाला सुख कोठून मिळणार? 2.66",

        "पाण्यात चालणाऱ्या नावेला वारा वाहून नेतो, त्याचप्रमाणे विषयांत वावरणाऱ्या इंद्रियांपैकी मन ज्या इन्द्रियाबरोबर राहते, ते एकच इन्द्रिय या अयुक्त पुरुषाची बुद्धी हिरावून घेते. 2.67",

        "हो! ज्याची इन्द्रिये इन्द्रियांच्या विषयांपासून सर्व प्रकारे आवरून धरलेली असतात, त्याची बुद्धी स्थिर असते. 2.68",

        " दृष्टीने जी रात्रीसारखी असते, अशा नित्य ज्ञानस्वरूप परमानन्दाच्या प्राप्तीत स्थितप्रज्ञ योगी जागतो आणि ज्या नाशिवंत सांसारिक सुखाच्या प्राप्तीत सर्व प्राणी जागतात, ती परमात्मतत्त्व जाणणाऱ्या मुनीला रात्रीसारखी असते. 2.69",

        "राळ्या नद्यांचे पाणी, सर्व बाजूंनी भरलेल्या व स्थिर असलेल्या समुद्रात त्याला विचलित न करताही सामावून जाते, त्याचप्रमाणे सर्व भोग ज्या स्थितप्रज्ञ पुरुषामध्ये कोणत्याही प्रकारचा विकार उत्पन्न न करताच सामावून जातात, तोच पुरुष परम शान्तीला प्राप्त होतो. भोगांची इच्छा करणारा नव्हे. 2.70",

        "कामनांचा त्याग करून, ममता, अहंकार आणि इच्छा टाकून राहात असतो, त्यालाच शान्ती मिळते. 2.71",

        "ह्माला प्राप्त झालेल्या पुरुषाची ही स्थिती आहे. ही प्राप्त झाल्याने योगी कधी मोहित होत नाही. आणि अन्तकाळीही या ब्राह्मी स्थितीत स्थिर होऊन ब्रह्मानन्द मिळवतो. 2.72",

        "इस प्रकार ॐ तत्  सत् - इन भगवन्नामों के उच्चारणपूर्वक ब्रह्मविद्या और योगशास्त्रमय श्रीमद्भगवद्गीतोपनिषदरूप श्रीकृष्णार्जुनसंवाद में ‘सांख्ययोग’ नामक दूसरा अध्याय पूर्ण हुआ।"
      ],
      "adhyaya-3" : [
        "अर्जुन म्हणाला - हे जनार्दना! जर तुम्हाला कर्माहून ज्ञान श्रेष्ठ वाटते, तर मग हे केशवा! मला भयंकर कर्म करण्यास कां प्रवृत्त करत आहात? 3.1",

        "तुम्ही मिश्रीतशा भाषणाने माझ्या बुद्धीला जणू मोहीत करत आहात. म्हणून अशी एकच गोष्ट निश्चित करून मला सांगा की, ज्यामुळे माझे कल्याण होईल. 3.2",

        "श्रीभगवान म्हणाले - हे निष्पापा! या जगात दोन प्रकारची निष्ठा माझ्याकडून पूर्वी सांगितली गेली आहे. त्यातील साङ्ख्ययोगांची निष्ठा ज्ञानयोगाने व योग्यांची निष्ठा कर्मयोगाने होते. 3.3",

        "मनुष्य कर्मे केल्याशिवाय निष्कर्मतेला म्हणजेच योगनिष्ठा प्राप्त होत नाही. आणि कर्माचा फक्त त्याग केल्याने तो सिद्धीला म्हणजेच सांख्यनिष्ठेला प्राप्त होत नाही. 3.4",

        "निःसंशयपणे कोणीही मनुष्य कोणत्याही वेळी क्षणभरसुद्धा काम न करता रहात नाही. कारण सर्व मनुष्यसमुदाय प्रकृतीपासून उत्पन्न झालेल्या गुणांमुळे पराधीन असल्यामुळे कर्म करायला भाग पाडला जातो. 3.5",

        "जो मूर्ख मनुष्य सर्व इंद्रिये बळेच वरवर आवरून मनाने त्या इंद्रियांच्या विषयांचे चिंतन करत राहतो, तो मिथ्याचारी म्हणजेच दांभिक म्हटला जातो. 3.6",

        "परंतु हे अर्जुना! जो मनुष्य मनाने इंद्रियांना ताब्यात ठेवून आसक्त न होता सर्व इंद्रियांच्या द्वारा कर्माचे आचरण करतो, तो श्रेष्ठ होय. 3.7",

        "तू शास्त्रविहीत कर्तव्यकर्म कर कारण कर्म न करण्यापेक्षा कर्म करणे श्रेष्ठ आहे. तसेच कर्म न करण्याने तुझे शरीरव्यवहारही चालणार नाहीत. 3.8",

        "यज्ञानिमित्त केल्या जाणा-या कर्मांशिवाय दुस-या कर्मांत गुंतलेला हा मनुष्य समुदाय कर्मांनी बांधला जातो. म्हणून हे अर्जुना! तू आसक्ती सोडून यज्ञासाठी उत्तम प्रकारे कर्तव्य कर्म कर. 3.9",

        "प्रजापती ब्रह्मदेवाने कल्पनांची यज्ञासह प्रजा उत्पन्न करून त्यांना सांगितले की, तुम्ही या यज्ञाच्या द्वारा उत्कर्ष करुन घ्या आणि हा यज्ञ तुमचे इच्छित मनोरथ पूर्ण करणारा होवो. 3.10",

        "तुम्ही या यज्ञाने देवतांची पुष्टी करा आणि त्या देवतांनी तुम्हाला पुष्ट करावे. अशा प्रकारे निःस्वार्थीपणाने एकमेकांची उन्नती करीत तुम्ही परम कल्याणाला प्राप्त व्हाल. 3.11",

        "यज्ञाने पुष्ट झालेल्या देवता तुम्हाला न मागताही इच्छित भोग खात्रीने देत राहतील. अशा रीतीने त्या देवतांनी दिलेले भोग त्यांना अर्पण न करता जो पुरुष स्वतः खातो, तो चोरच होय. 3.12",

        "यज्ञ करून शिल्लक राहिलेले अन्न खाणारे पुरुष सर्व पापांपासून मुक्त होतात. पण जे पापी लोक स्वतःच्या शरीर-पोषणासाठी अन्न शिजवतात, ते तर पापच खातात. 3.13",

        "सर्व प्राणी अन्नापासून उत्पन्न होतात. अन्ननिर्मिती पावसापासून होते. पाऊस यज्ञामुळे पडतो आणि यज्ञ विहित कर्मांमुळे घडतो. कर्मसमुदाय वेदांपासून व वेद अविनाशी परमात्म्यापासून उत्पन्न झालेले आहेत, असं समज. यावरून हेच सिद्ध होते की, सर्वव्यापी परम अक्षर परमात्मा नेहमीच यज्ञात प्रतिष्ठित असतो. (3.14-3.15)",

        "सर्व प्राणी अन्नापासून उत्पन्न होतात. अन्ननिर्मिती पावसापासून होते. पाऊस यज्ञामुळे पडतो आणि यज्ञ विहित कर्मांमुळे घडतो. कर्मसमुदाय वेदांपासून व वेद अविनाशी परमात्म्यापासून उत्पन्न झालेले आहेत, असं समज. यावरून हेच सिद्ध होते की, सर्वव्यापी परम अक्षर परमात्मा नेहमीच यज्ञात प्रतिष्ठित असतो. (3.14-3.15)",

        "हे पार्था! जो पुरुष या जगात अशा प्रकारे परंपरेने चालू असलेल्या सृष्टीचक्राला अनुसरून वागत नाही, म्हणजेच आपल्या कर्तव्याचे पालन करीत नाही, तो इंद्रियांच्या द्वारा भोगात रमणारा पापी आयुष्य असलेला पुरुष व्यर्थच जगतो. 3.16",

        "परंतु जो मनुष्य आत्म्यातच रमणारा, आत्म्यातच तृप्त आणि आत्म्यातच संतुष्ट असतो, त्याला कोणतेच कर्तव्य उरत नाही. 3.17",

        "त्या महापुरुषाला या विश्वात कर्मे करण्याने काही प्रयोजन असत नाही. तसेच कर्मे न करण्यानेही काही प्रयोजन असत नाही. तसेच सर्व प्राणिमात्रातही त्याचा जरादेखील स्वार्थाचा संबंध असत नाही. 3.18",

        "म्हणून तू नेहमी आसक्त न होता कर्तव्यकर्म नीट करत रहा. कारण आसक्ती सोडून कर्म करणारा मनुष्य परमात्म्याला जाऊन मिळतो. 3.19",

        "जनकादी ज्ञानी लोकही आसक्तीरहित कर्मानेच परम सिद्धीला प्राप्त झाले होते. म्हणून तसेच लोकसंग्रहाकडे दृष्टी देऊन देखील तू कर्म करणेच योग्य. 3.20",

        "श्रेष्ठ पुरुष जे जे आचरण करतो, त्या त्याप्रमाणेच इतर लोकही आचरण करतात. तो जे काही प्रमाण म्हणून सांगतो, त्याप्रमाणेच सर्व मनुष्य समुदाय वागू लागतो. 3.21",

        "हे अर्जुना! मला या तिन्ही लोकांत काहीही कर्तव्य नाही आणि मिळविण्याजोगी कोणतीही वस्तू मिळाली नाही, असे नाही. तरीही मी कर्म करीतच असतो. 3.22",

        "कारण हे पार्था! जर का मी सावध राहून कर्मे केली नाहीत, तर मोठे नुकसान होईल. कारण मनुष्य सर्व प्रकारे माझ्याच मार्गाचे अनुसरण करतात. 3.23",

        "म्हणून मी कर्म केले नाही, तर सर्व माणसे नष्ट भ्रष्ट होतील आणि मी संकरतेचे कारण होईन. तसेच या सर्व प्रजेचा घात करणारा होईन. 3.24",

        "हे भारता! कर्मात आसक्त असलेले अज्ञानी लोक ज्या रीतीने कर्में करतात, त्यात रीतीने आसक्ती नसलेल्या विद्वानानेही लोक संग्रह करण्याच्या इच्छेने कर्में करावीत. 3.25",

        "परमात्म्यस्वरूपात स्थिर असलेल्या ज्ञानी पुरुषाने शास्त्रविहीत कर्मांत आसक्ती असलेल्या अज्ञानी लोकांच्या बुद्धीत भ्रम म्हणजेच कर्माविषयी अश्रद्धा निर्माण करु नये. उलट स्वतः शास्त्रविहीत सर्व कर्में उत्तम प्रकारे करीत त्यांच्याकडूनही तशीच करून घ्यावी. 3.26",

        "वास्तविक सर्व कर्में सर्व प्रकारे प्रकृतीच्या गुणांमार्फत केली जातात. तरीही ज्याचे अंतःकरण अहंकाराने मोहित झाले आहे, असा अज्ञानी पुरुष ‘मी कर्ता आहे’ असा मानतो. 3.27",

        "पण हे महाबाहो! गुणविभाग आणि कर्मविभाग यांचे तत्व जाणणारा ज्ञानयोगी सर्व गुणच गुणांत वावरत असतात, हे लक्षात घेऊन त्यामधे आसक्त होत नाही. 3.28",

        "प्रकृतीच्या गुणांनी अत्यंत मोहीत झालेली माणसे गुणात आणि कर्मांत आसक्त होतात. त्या चांगल्या रीतीने न जाणणा-या मंदबुद्धीच्या अज्ञान्यांच्या पूर्ण ज्ञान असणा-या ज्ञान्याने बुद्धिभेद करू नये. 3.29",

        "अंतर्यामी मज परमात्म्यामधे गुंतलेल्या चित्ताने सर्व कर्में मला समर्पण करुन आशा, ममता व संतापरहित होऊन तू युद्ध कर. 3.30",

        "जे कोणी मानव दोषदृष्टी टाकून श्रद्धायुक्त अंतःकरणाने माझ्या या मताचे नेहमी अनुसरण करतात, ते ही सर्व कर्मांपासून मुक्त होतात. 3.31",

        "परंतु, जे मानव माझ्यावर दोषारोप करून माझ्या या मतानुसार वागत नाहीत, त्या मूर्खांना तू सर्व ज्ञानाला मुकलेले आणि नष्ट झालेलेच समज. 3.32",

        "सर्व प्राणी प्रकृतीच्या वळणावर जातात म्हणजेच आपल्या स्वभावाच्या अधीन होऊन कर्में करतात. ज्ञानीसुद्धा आपल्या स्वभावानुसार व्यवहार करतो. मग या विषयांत कोणाचाही हट्टीपणा काय करील. 3.33",

        "प्रत्येक इंद्रियाचे इंद्रियाच्या विषयात राग व  द्वेष लपलेले असतात. माणसाने त्या दोहोंच्या आहारी जाता कामा नये. कारण ते दोन्हीही त्याच्या कल्याणमार्गात विघ्न करणारे मोठे शत्रू आहेत. 3.34",

        "चांगल्या आचरणात आणलेल्या दुस-याच्या धर्माहून गुणरहित असला तरी आपला धर्म अतिशय उत्तम आहे. आपल्या धर्मात तर मरणेही कल्याणकारक आहे. पण दुसऱ्याचा धर्म भय देणारा आहे. 3.35",

        "अर्जुन म्हणाला - हे कृष्णा! तर मग हा मनुष्य स्वतःची इच्छा नसतानाही जबरदस्तीने करावयास लावल्याप्रमाणे कोणाच्या प्रेरणेने पापाचे आचरण करतो. 3.36",

        "श्रीभगवान म्हणाले - रजोगुणापासून उत्पन्न झालेला हा कामच क्रोध आहे. हा खूप खादाड अर्थात् भोगांनी कधीही तृप्त न होणारा व मोठा पापी आहे. हाच या विषयातील वैरी आहे, असे तू जाण. 3.37",

        "ज्या प्रमाणे धुराने अग्नी, धुळीने आरसा आणि वारेने गर्भ झाकला जातो,  त्याचप्रमाणे कामामुळे हे ज्ञान आच्छादित राहाते. 3.38",

        "आणि हे अर्जुना! कधीही तृप्त न होणारा हा कामरूप अग्नी ज्ञानी माणसाचा कायमचा शत्रू आहे. त्याने माणसांचे ज्ञान झाकले आहे. 3.39",

        "इंद्रिये,  मन आणि बुद्धी ही याचे निवासस्थान म्हटली जातात. हा काम या मन, बुद्धी आणि इंद्रियांच्या द्वारा ज्ञानाला आच्छादित करुन जीवात्म्याला मोहित करतो. 3.40",

        "म्हणून हे अर्जुना! तू प्रथम इंद्रियांवर ताबा ठेवून या ज्ञान आणि विज्ञान यांचा नाश करणा-या,  मोठ्या पापी कामाला अवश्य बळेच मारून टाक. 3.41",

        "इंद्रियांना स्थूल शरीराहून पर म्हणजे श्रेष्ठ, बलवान आणि सूक्ष्म म्हटले जाते. या इंद्रियाहून मन पर आहे. मनाहून बुद्धी पर आहे. आणि जो बुद्धीहूनही अत्यंत पर आहे, तो आत्मा होय. 3.42",

        "अशा प्रकारे बुद्धीहून पर अर्थात सूक्ष्म, बलवान् व अत्यंत श्रेष्ठ असा आत्मा आहे,  हे जाणून बुद्धीच्या द्वारा मनाला स्वाधीन करून हे महाबाहो! तू या कामरूप अजिंक्य शत्रूला मारून टाक. 3.43",

        "इस प्रकार ॐ तत्  सत् - इन भगवन्नामों के उच्चारणपूर्वक ब्रह्मविद्या और योगशास्त्रमय श्रीमद्भगवद्गीतोपनिषदरूप श्रीकृष्णार्जुनसंवाद में ‘कर्मयोग’ नामक तीसरा अध्याय पूर्ण हुआ।"
      ],
      "adhyaya-4" : [
        "श्री भगवान म्हणाले, मी हा अविनाशी योग सूर्याला सांगितला होता. सूर्याने आपला पुत्र मनु याला सांगितला आणि मनूने आपला पुत्र राजा इक्ष्वाकू याला सांगितला. 4.1",

        "हे परंतप अर्जुना! अशाप्रकारे परंपरेने आलेला हा योग राजर्षींनी जाणला. परंतु त्यानंतर पुष्कळ काळापासून हा योग या पृथ्वीवर लुप्तप्राय झाला. 4.2",

        "तू माझा प्रिय भक्त आणि प्रिय सखा आहेस. म्हणून तोच हा पुरातन योग आज मी तुला सांगितला आहे. कारण हा अतिशय उत्तम आणि रहस्यमय आहे. अर्थात गुप्त ठेवण्याजोगा आहे. 4.3",

        "अर्जुन म्हणाला, आपला जन्म तर अलीकडचा; आणि सूर्याचा जन्म तर फार पूर्वीचा अर्थात कल्पारंभी झाला होता. मग आपणच कल्पारंभी सूर्याला हा योग सांगितला होता, असे कसे समजू? 4.4",

        "श्री भगवान म्हणाले, हे परंतप अर्जुना! माझे आणि तुझे पुष्कळ जन्म झाले आहेत. हे सर्व तुला माहीत नाही; पण मला माहीत आहेत. 4.5",

        "मी जन्मरहित आणि अविनाशी असूनही तसेच सर्व प्राण्यांचा ईश्वर असूनही आपल्या प्रकृतीला स्वाधीन करून आपल्या योगमायेने प्रगट होत असतो. 4.6",

        "हे भारता! जेव्हा जेव्हा धर्माचा ऱ्हास आणि अधर्माची वाढ होत असते, तेव्हा तेव्हाच मी आपले रूप रचतो म्हणजे आकार घेऊन लोकांसमोर प्रगट होतो. 4.7",

        "सज्जनांच्या उद्धारासाठी, पापकर्म करणाऱ्यांच्या नाश करण्यासाठी आणि धर्माची उत्तम प्रकारे स्थापना करण्यासाठी मी युगायुगात प्रगट होतो. 4.8",

        "हे अर्जुना! माझा जन्म आणि कर्म दिव्य अर्थात् निर्मळ आणि अलौकिक आहे. असे जो मनुष्य तत्त्वतः जाणतो, तो शरीराचा त्याग केल्यावर पुन्हा जन्माला येत नाही, तर तो मलाच येऊन मिळतो. 4.9",

        "पूर्वीसुद्धा ज्यांचे आसक्ती, भय आणि क्रोध पूर्णपणे नाहीसे झाले होते आणि जे माझ्यात अनन्य प्रेमपूर्वक स्थिर राहात होते, असे माझा आश्रय घेतलेले पुष्कळसे भक्त वर सांगितलेल्या ज्ञानरूप तपाने पवित्र होऊन माझ्या स्वरूपाला प्राप्त झालेले आहेत. 4.10",

        "हे अर्जुना! जे भक्त मला असे भजतात, मीही त्यांना तसाच भजतो, कारण सर्वच मानव सर्व प्रकारे माझ्याच मार्गाचे अनुसरण करतात. 4.11",

        "या मनुष्य लोकात कर्माच्या फळाची इच्छा करणारे लोक देवतांची पूजा करतात. कारण त्यांना कर्मापासून उत्पन्न होणारी सिद्धी लवकरच मिळते. 4.12",

        "ब्राह्मण, क्षत्रिय, वैश्य आणि शूद्र या चार वर्णांचा समूह, गुण आणि कर्म यांच्या विभागाने मी निर्माण केला आहे. अशा रीतीने या सृष्टीरचना इत्यादी कर्मांचा मी कर्ता असूनही मला- अविनाशी परमात्म्याला तू वास्तविक अकर्ताच समज. 4.13",

        "कर्मांच्या फळाची मला इच्छा नाही. त्यामुळे मला कर्मांचे बंधन होत नाही अशा प्रकारे जो मला तत्त्वतः जाणतो, त्याला ही कर्मांचे बंधन होत नाही. 4.14",

        "पूर्वीच्या मुमुक्षूंनी सुद्धा असे जाणूनच कर्मे केली आहेत, म्हणून तुही पूर्वजांकडून नेहमी केली जाणारी कर्मेच कर. 4.15",

        "कर्म काय आणि अकर्म काय याचा निर्णय करण्याच्या बाबतीत बुद्धिमान पुरुषही संभ्रमात पडतात. म्हणून ते कर्माचे तत्व मी तुला व्यवस्थित समजावून सांगेन. ते कळले की तू अशुभापासून म्हणजेच कर्मबंधनातून सुटशील. 4.16",

        "कर्माचे स्वरूपही जाणले पाहिजे आणि अकर्माचे स्वरूपही जाणले पाहिजे. तसेच विकर्मा चे स्वरूपही जाणले पाहिजे. कारण कर्माचे तात्विक स्वरूप समजण्यास कठीण आहे. 4.17",

        "जो पुरुष कर्मामध्ये अकर्म पाहील आणि अकर्मा मध्ये कर्म पाहील, तो मनुष्यांमध्ये बुद्धिमान होय आणि तो योगी सर्व कर्मे करणारा आहे. 4.18",

        "ज्याची सर्व शास्त्रसंमत कर्मे कामनारहित व संकल्परहित होत असतात, तसेच ज्याची सर्व कर्मे ज्ञानरूप अग्नीने जळून गेली आहेत, त्या महापुरुषाला ज्ञानी लोक ‘पंडित’ म्हणतात. 4.19",

        "जो पुरुष सर्व कर्मांन मध्ये आणि त्यांच्या फलांमध्ये आसक्ती पूर्णपणे टाकून तसेच सांसारिक आश्रय सोडून देऊन परमात्म्यात नित्य तृप्‍त असतो, तो कर्मांमध्ये उत्तम प्रकारे वावरत असूनही वास्तविक काहीच करत नाही. 4.20",

        "ज्याने अतःकरण व इंद्रियांसह शरीर जिंकले आहे आणि सर्व भोग सामग्रीचा त्याग केला आहे, असा आशा नसलेला मनुष्य केवळ शरीरासंबंधीचे कर्म करीत राहूनही पापी होत नाही. 4.21",

        "जो इच्छेशिवाय आपोआप मिळालेल्या पदार्थांत नेहमी संतुष्ट असतो, ज्याला मत्सर मुळीच वाटत नाही. जो सुखदुःख इत्यादी द्वंद्वांच्या पूर्णपणे पार गेलेला आहे, असा सिद्धीत व असिद्धीत समभाव ठेवणारा कर्मयोगी कर्म करीत असूनही त्यात बांधला जात नाही. 4.22",

        "ज्याची आसक्ती पूर्णपणे नाहीशी झाली आहे, जो देहाभिमान आणि ममत्व यांनी रहित आहे, त्याचे चित्त नेहमी परमात्म्याच्या ज्ञानात स्थिर आहे, अशा केवळ यज्ञासाठी कर्म करणाऱ्या माणसाची संपूर्ण कर्मे पूर्णपणे नाहीशी होतात. 4.23",

        "ज्या यज्ञात अर्पण अर्थात् स्रुवा आदिही ब्रह्म आहे आणि हवन करण्याजोगे द्रव्यसुद्धा ब्रह्म आहे, तसेच ब्रह्मरूप कर्त्याच्या द्वारा ब्रह्मरूप अग्नीमध्ये आहुती देण्याची क्रियाही ब्रह्म आहे, त्या ब्रह्म कर्मात स्थित असणाऱ्या योग्याला, मिळण्या जोगे फळ सुद्धा ब्रह्मच आहे. 4.24",

        "दुसरे काही योगी देवपूजारूप यज्ञाचे उत्तम प्रकारे अनुष्ठान करतात, इतर योगी परब्रह्म परमात्मरुपी अग्नीत अभेददर्शनरूप यज्ञाच्या द्वाराच आत्मरुपी यज्ञाचे हवन करतात. 4.25",

        "दुसरे काही योगी 'कान' इत्यादी इंद्रियांचे संयम रूप अग्नीत हवन करतात तर इतर योगी शब्द इत्यादी सर्व विषयांचे इंद्रियरूप अग्नीत हवन करतात. 4.26",

        "अन्य योगी इंद्रियांच्या सर्व क्रिया आणि प्राण्यांच्या सर्व क्रिया यांचे ज्ञानाने प्रकाशित जो आत्मसंयमयोगरूप अग्नी त्यात हवन करतात. 4.27",

        "काही पुरुष द्रव्य विषयक यज्ञ करणारे असतात. काहीजण तपश्चर्यारूप यज्ञ करणारे असतात. तसेच दुसरे काही जण योगरुप यज्ञ करणारे असतात. कितीतरी जण अहिंसा इत्यादी कडक व्रते पाळणारे यत्नशील पुरुष स्वाध्यायरूप ज्ञानयज्ञ करणारे असतात. 4.28",

        "अन्य काही योगीजन अपानवायू मध्ये प्राणवायूचे हवन करतात. तसेच दुसरे योगी प्राणवायू मध्ये अपानवायूचे हवन करतात. त्याच प्रमाणे इतर कितीतरी नियमित आहार घेणारे प्राणायामाविषयी तत्पर असणारे पुरुष प्राण व अपानाची गती थांबवून प्राणांचे प्राणांतच हवन करीत असतात. हे सर्व साधक यज्ञांच्या द्वारा पापांचा नाश करणारे व यज्ञ जाणणारे आहेत. (4.29-4.30)",

        "अन्य काही योगीजन अपानवायू मध्ये प्राणवायूचे हवन करतात. तसेच दुसरे योगी प्राणवायू मध्ये अपानवायूचे हवन करतात. त्याच प्रमाणे इतर कितीतरी नियमित आहार घेणारे प्राणायामाविषयी तत्पर असणारे पुरुष प्राण व अपानाची गती थांबवून प्राणांचे प्राणांतच हवन करीत असतात. हे सर्व साधक यज्ञांच्या द्वारा पापांचा नाश करणारे व यज्ञ जाणणारे आहेत. (4.29-4.30)",

        "हे कुरुश्रेष्ठ अर्जुना! यज्ञातून शिल्लक राहिलेल्या, अमृताचा अनुभव घेणारे योगी सनातन परब्रह्म परमात्म्याला प्राप्त होतात. यज्ञ न करणाऱ्या पुरुषाला हा मनुष्यलोक सुद्धा सुखदायक होत नाही, तर परलोक कसा सुखदायक होईल? 4.31",

        "अशाप्रकारे इतरही पुष्कळ प्रकारचे यज्ञ वेदवाणीत विस्ताराने सांगितले गेलेले आहेत. ते सर्व तू मन, इंद्रियें आणि शरीर यांच्या क्रियांनी उत्पन्न होणारे आहेत, असे समज. अशाप्रकारे तत्वतः जाणून त्यांचे अनुष्ठान केल्याने तू कर्मबंधनातून पूर्णपणे मुक्त होशील. 4.32",

        "हे परंतप अर्जुना! द्रव्यमय यज्ञापेक्षा ज्ञानयज्ञ अत्यंत श्रेष्ठ आहे. तसेच यच्चयावत सर्व कर्मे ज्ञानात समाप्त होतात. 4.33",

        "ते ज्ञान तू तत्वसाक्षात्कारी ज्ञानी लोकांच्याकडे जाऊन समजून घे. त्यांना साष्टांग नमस्कार केल्याने, त्यांची सेवा केल्याने आणि निष्कपटपणे सरळ मनाने त्यांना प्रश्न विचारल्याने, परमात्मतत्व उत्तम रीतीने जाणणारे ते ज्ञानी महात्मे तुला त्या तत्वज्ञानाचा उपदेश करतील. 4.34",

        "हे जाणल्याने पुन्हा तू अशा प्रकारच्या मोहात पडणार नाहीस, तसेच हे अर्जुना! ज्ञानामुळे तू सर्व प्राणिमात्र पूर्णपणे प्रथम आपल्यात आणि नंतर मज सच्चिदानंदघन परमातम्यात पाहशील. 4.35",

        "जरी तू इतर पाप्यांहूनही अधिक पाप करणारा असलास, तरीही तू ज्ञानरूप नौकेने खात्रीने संपूर्ण पापसमुद्रातून चांगल्या प्रकारे तरुन जाशील. 4.36",

        "कारण हे अर्जुना! ज्याप्रमाणे पेटलेला अग्नी इंधनाची राख करतो, तसाच ज्ञानरूप अग्नी सर्व कर्मांची राखरांगोळी करतो. 4.37",

        "या जगात ज्ञाना सारखे पवित्र करणारे खात्रीने दुसरे काहीही नाही. ते ज्ञान कितीतरी काळाने कर्मयोगाने अंतःकरण शुद्ध झालेला माणूस आपल्या आत्म्यात आपोआपच प्राप्त करून घेतो. 4.38",

        "जितेंद्रिय, साधनतत्पर आणि श्रद्धाळू माणूस ज्ञान मिळवतो आणि ज्ञान झाल्यावर तो तात्काळ भगवत्प्राप्तीरूप परम शांतीला प्राप्त होतो. 4.39",

        "अविवेकी आणि श्रद्धा नसलेला संशयी मनुष्य परमार्थापासून खात्रीने भ्रष्ट होतो. संशयी माणसाला ना हा लोक, ना परलोक आणि ना सुख. 4.40",

        "हे धनंजया! जाने कर्मयोगाच्या विधीने सर्व कर्मे परमात्म्याला अर्पण केली आहेत आणि ज्याने विवेकाने सर्व संशयांचा नाश केला आहे, अशा अंतःकरण स्वाधीन असलेल्या पुरुषाला कर्मे बंधनकारक होत नाहीत. 4.41",

        "म्हणून हे भरतवंशी अर्जुना! हृदयात असलेल्या या अज्ञानाने उत्पन्न झालेल्या संशयाचा विवेकज्ञानरुप तलवारीने नाश करून समत्वरूप कर्मयोगात स्थिर रहा आणि युद्धाला उभा रहा. 4.42",

        "इस प्रकार ॐ तत्  सत् - इन भगवन्नामों के उच्चारणपूर्वक ब्रह्मविद्या और योगशास्त्रमय श्रीमद्भगवद्गीतोपनिषदरूप श्रीकृष्णार्जुनसंवाद में ज्ञानकर्मसन्यासयोग’ नामक चौथा अध्याय पूर्ण हुआ।"
      ],
      "adhyaya-5" : [
        "अर्जुन म्हणाला - हे कृष्णा! तुम्ही कर्मे टाकण्याची आणि फिरून कर्मयोगाची प्रशंसा करता! तेव्हा या दोहोंपैकी माझ्यासाठी अगदी निश्चित कल्याणकारक जे एक साधन असेल,  ते सांगा. 5.1",

        "श्रीभगवान म्हणाले - कर्मसंन्यास आणि कर्मयोग हे दोन्हीही परम कल्याण करणारेच आहेत. परंतु त्या दोहोतही संन्यासाहून कर्मयोग साधण्यास सोपा असल्याने श्रेष्ठ आहे. 5.2",

        "हे अर्जुना! जो पुरुष कोणाचा द्वेष करीत नाही आणि कशाची अपेक्षा करीत नाही, तो कर्मयोगी नेहमीच संन्यासी समजावा. कारण राग - द्वेष इत्यादी द्वंद्वानी रहित असलेला पुरुष सुखाने संसारबन्धनातून मुक्त होतो. 5.3",

        "वर सांगितलेले संन्यास आणि कर्मयोग वेगवेगळी फळे देणारे आहेत, असे मूर्ख लोक म्हणतात; पण्डित नव्हेत . कारण दोहोंपैकी एकाचे ठिकाणी सुद्धा प्रकारे स्थित असलेला पुरुष दोहोंचे फलस्वरूप असलेल्या परमात्म्याला प्राप्त होतो. 5.4",

        "ज्ञानयोग्यांना जे परम धाम प्राप्त होते;  तेच कर्मयोग्यांनाही प्राप्त होते. म्हणून जो मनुष्य ज्ञानयोग आणि कर्मयोग हे फळाच्या दृष्टीने एकच आहेत, असे पाहतो, तोच खऱ्या अर्थाने पाहतो. 5.5",

        "परन्तु हे अर्जुना! कर्मयोगाशिवाय मन, इंद्रिये व शरीर यांच्याकडून होणाऱ्या सर्व कर्मांच्या बाबतीत कर्तेपणाचा त्याग होणे कठीण आहे. आणि भगवत्स्वरूपाचे चिंतन करणारा कर्मयोगी परब्रह्म परमात्म्याला फार लवकर प्राप्त होतो. 5.6",

        "ज्याचे मन स्वतःच्या ताब्यात आहे, जो इन्द्रियनिग्रही आणि शुद्ध अन्तः करणाचा आहे, तसेच सर्व प्राण्यांचा आत्मरूप परमात्माच ज्याचा आत्मा आहे असा कर्मयोगी कर्मे करूनही अलिप्त राहातो. 5.7",

        "सांख्ययोगी तत्त्ववेत्त्याने पाहात असता, ऐकत असता, वास घेत असता, भोजन करीत असता, चालत असता, झोपत असता, श्वासोच्छ्वास करीत असता, बोलत असता, टाकीत असता, घेत असता तसेच डोळ्यांनी उघडझाप करीत असताही सर्व इंद्रिये आपापल्या विषयात वावरत आहेत, असे समजून निःसंशय असे मानावे की, मी काहीच करत नाही. (5.8-5.9)",

        "सांख्ययोगी तत्त्ववेत्त्याने पाहात असता, ऐकत असता, वास घेत असता, भोजन करीत असता, चालत असता, झोपत असता, श्वासोच्छ्वास करीत असता, बोलत असता, टाकीत असता, घेत असता तसेच डोळ्यांनी उघडझाप करीत असताही सर्व इंद्रिये आपापल्या विषयात वावरत आहेत, असे समजून निःसंशय असे मानावे की, मी काहीच करत नाही. (5.8-5.9)",

        "जो पुरुष सर्व कर्मे परमात्म्याला अर्पण करून आणि आसक्ती सोडून कर्म करतो, तो पुरुष पाण्यातील कमल पत्राप्रमाणे पापाने लिप्त होत नाही. 5.10",

        "कमयोगी ममत्वबुद्धी सोडून केवळ इन्द्रिये, मन,  बुद्धि आणि शरीर  यांच्याद्वारा आसक्ती सोडून अन्त:करणाच्या शुद्धीसाठी कर्म करतात. 5.11",

        "कर्मयोगी कर्मांच्या फळांचा त्याग करून भगवत्प्राप्तिरूप शान्तीला प्राप्त होतो, आणि कामना असलेला पुरुष कामनांच्या प्रेरणेमुळे फळांत आसक्त होऊन बद्ध होतो. 5.12",

        "अन्तःकरण ज्याच्या ताब्यात आहे, असा सांख्ययोगाचे आचरण करणारा पुरुष कोणतेही कर्म करणारा किंवा करविणारा न होताच नऊ दरवाजांच्या शरीररूपी घरात सर्व कर्मांचा मनाने त्याग करून आनन्दाने सच्चिदानन्दघन परमात्म्याच्या स्वरूपात स्थित राहतो. 5.13",

        "परमेश्वर मनुष्यांचे कर्तेपण,  कर्में किंवा कर्मफलांशी संयोग उत्पन्न करीत नाही;  तर प्रकृतीच खेळ करीत असते. 5.14",

        "सर्वव्यापी परमेश्वही कोणाचेही पापकर्म किंवा पुण्यकर्म स्वतःकडे घेत नाही. परन्तु अज्ञानाने ज्ञान झाकलेले आहे. त्यामुळे सर्व अज्ञानी लोक मोहित होतात. 5.15",

        "परन्तु ज्यांचे ते अज्ञान परमात्मज्ञानाने नाहीसे झाले आहे, त्यांचे ते ज्ञान सूर्याप्रमाणे त्या सच्चिदानन्दघन परमात्म्याला प्रकाशित करते. 5.16",

        "ज्यांचे मन व बुद्धि तद्रूप झालेली आहे आणि सच्चिदानन्दघन परमात्म्यांतच ज्यांचे नित्य ऐक्य झाले आहे, असे ईश्वरपरायण पुरुष ज्ञानाने पापरहित होऊन परम गतीला प्राप्त होतात. 5.17",

        "ते ज्ञानी पुरुष विद्या व विनय यांनी युक्त असलेला ब्राह्मण, गाय, हत्ती, कुत्रा आणि चांडाळ या सर्वांना समदृष्टीनेच पाहातात. 5.18",

        "ज्यांचे मन समभावात स्थिर झाले, त्यांनी या जन्मीच संपूर्ण संसार जिंकला. कारण सच्चिदानंदघन परमात्मा निर्दोष आणि सम आहे. म्हणून ते सच्चिदानन्दघन परमात्म्यातच स्थिर असतात. 5.19",

        "जो पुरुष प्रिय वस्तु मिळाली असता आनंदित होत नाही आणि अप्रिय वस्तू प्राप्त झाली असना उद्विग्न होत नाही,  तो स्थिर बुद्धि असलेला संशयरहित ब्रह्मवेत्ता पुरुष सच्चिदानन्दघन परब्रह्म परमात्म्यात ऐक्यभावाने नित्य स्थित असतो. 5.20",

        "ज्याच्या अन्तः करणाला बाहेरील विषयांची आसक्ती नसते, असा साधक आत्म्यात असलेल्या, ध्यानामुळे मिळणाऱ्या सात्विक आनंदाला प्राप्त होतो. त्यानंतर तो सच्चिदानन्दघन परब्रह्म परमात्म्याच्या ध्यानरूप योगात ऐक्यभावाने स्थिति असलेला पुरुष अक्षय आनन्दाचा अनुभव घेतो. 5.21",

        "जे हे इंद्रिय आणि विषय यांच्या संयोगाने उत्पन्न होणारे सर्व भोग आहेत, ते जरी विषयी पुरुषांना सुखरूप वाटत असले, तरी तेही दुःखालाच कारण होणारे आणि अनित्य आहेत. म्हणून हे अर्जुना! बुद्धिमान विवेकी पुरुष त्यांत रमत नाहीत. 5.22",

        "जो साधक या मनुष्यशरीरात शरीर पडण्याआधिच काम - क्रोधामुळे उत्पन्न होणारा आवेग सहन करण्यास समर्थ होतो, तोच योगी होय व तोच सुखी होय. 5.23",

        "जो पुरुष अन्तरात्म्यातच सुखी, आत्म्यातच रमणारा आणि आत्म्यातच ज्ञान मिळालेला असतो, तो सच्चिदानन्दघन परब्रह्म परमात्म्यासह ऐक्यभावाला प्राप्त झालेला सांख्ययोगी शान्त ब्रह्माला प्राप्त होतो. 5.24",

        "ज्यांचे सर्व पाप नष्ट झाले आहे, ज्यांचे सर्व संशय ज्ञानामुळे फिटले आहेत, जे प्राणिमात्राच्या कल्याणात तत्पर आहेत आणि ज्यांचे जिंकलेले मन निश्चलपणे परमात्म्यात स्थिर असते,  ते ब्रहमवेत्ते शान्त ब्रह्माला प्राप्त होतात. 5.25",

        "काम - क्रोध मावळलेले, मन जिंकलेले, परब्रह्म परमात्म्याचा साक्षात्कार करून घेतलेले जे ज्ञानी पुरुष असतात, त्यांना सर्व बाजूंनी शान्त परब्रह्म परमात्माच परिपूर्ण असतो. 5.26",

        "बाहेरच्या विषयभोगांचे चिन्तन न करता ते बाहेरच ठेवून, दृष्टी भुवयांच्या मध्यभागी स्थिर करून तसेच नाकातून वाहणारे प्राण व अपान सम करून ज्याचे इंद्रिये, मन व बुद्धि जिंकली आहेत, असा मोक्षतत्पर मुनी इच्छा, भय आणि क्रोध यांनी रहित झाला की, तो सदोदित मुक्तच असतो. (5.27-5.28)",

        "बाहेरच्या विषयभोगांचे चिन्तन न करता ते बाहेरच ठेवून, दृष्टी भुवयांच्या मध्यभागी स्थिर करून तसेच नाकातून वाहणारे प्राण व अपान सम करून ज्याचे इंद्रिये, मन व बुद्धि जिंकली आहेत, असा मोक्षतत्पर मुनी इच्छा, भय आणि क्रोध यांनी रहित झाला की, तो सदोदित मुक्तच असतो. (5.27-5.28)",

        "माझा भक्त मला सर्व यज्ञ आणि तपांचा भोक्ता, सर्व लोकांच्या ईश्वराचाही ईश्वर, प्राणिमात्रांचा सुह्रद अर्थात स्वार्थरहित दयाळू आणि प्रेमी, असे तत्वतः समजून शान्तीला प्राप्त होतो. 5.29",

        "इस प्रकार ॐ तत्  सत् - इन भगवन्नामों के उच्चारणपूर्वक ब्रह्मविद्या और योगशास्त्रमय श्रीमद्भगवद्गीतोपनिषदरूप श्रीकृष्णार्जुनसंवाद में ‘कर्मसन्यासयोग’ नामक पाँचवाँ अध्याय पूर्ण हुआ।"
      ],
      "adhyaya-6" : [
        "श्री भगवान म्हणाले, जो पुरुष कर्म फळाचा आश्रय न घेता कर्तव्य कर्म करत,  तो संन्यासी व योगी होय. आणि केवळ अग्नीचा त्याग करणारा संन्यासी नव्हे,  तसेच केवळ क्रियांचा त्याग करणारा योगी नव्हे. 6.1",

        "हे अर्जुना! ज्याला संन्यास असे म्हणतात तोच योग आहे,  असे तू समज. कारण संकल्पाचा त्याग न करणारा कोणीही पुरुष योगी होत नाही. 6.2",

        "योगावर आरूढ होण्याची इच्छा करणाऱ्या मननशील पुरुषाला योगाची प्राप्ती होण्यासाठी 'निष्काम कर्म करणे’ हाच हेतू सांगितला आहे आणि योगारूढ झाल्यावर योगारूढ पुरुषाचा जो सर्व संकल्पांचा अभाव असतो,  तोच कल्याणाला कारण सांगितला आहे. 6.3",

        "ज्यावेळी इंद्रियांच्या भोगात किंवा कर्मात ही पुरुष आसक्त होत नाही,  त्यावेळी सर्व संकल्पांचा त्याग करणाऱ्या पुरुषाला योगारूढ म्हटले जाते. 6.4",

        "स्वतः स्वतःचा संसार समुद्रातून उद्धार करून घ्यावा आणि स्वतःला अधोगतीला जाऊ देऊ नये. कारण हा मनुष्य स्वतः स्वतःचा मित्र आहे आणि स्वतः स्वतःचा शत्रू आहे. 6.5",

        "ज्या जीवातम्याने मन व इंद्रिये यांसह शरीर जिंकले,  त्या जीवात्म्याचा तर तो स्वतः मित्र आहे आणि ज्याने मन व इंद्रियांसह शरीर जिंकले नाही त्याचे तो स्वतःच शत्रूप्रमाणे शत्रुत्व करतो. 6.6",

        "थंड व उष्ण, सुखदुःख इत्यादी तसेच मान-अपमान यामध्ये ज्याच्या अंत:करणाची वृत्ती पूर्णपणे शांत असते अशा स्वाधीन आत्मा असलेल्या पुरुषाच्या ज्ञानात सच्चिदानंदघन परमात्मा उत्तम प्रकारे अधिष्ठित असतो; म्हणजे त्याच्या ज्ञानात परमात्म्या शिवाय दुसरे काहीच नसते. 6.7",

        "ज्याचे अंतकरण ज्ञानविज्ञानाने तृप्त आहे ,  ज्याची स्थिती निर्विकार आहे,  ज्याने इंद्रिये पूर्णपणे जिंकली आहेत आणि ज्याला माती, दगड,  सोने समान आहे,  तो योगी युक्त म्हणजे भगवंताला प्राप्त झालेला आहे असे म्हटले जाते. 6.8",

        "सुहृद, मित्र,  शत्रू, उदासीन,  मध्यस्थ,  द्वेष करण्याजोगा बांधव,  सज्जन आणि पापी या सर्वांविषयी समान भाव ठेवणारा अत्यंत श्रेष्ठ आहे. 6.9",

        "मन व इंद्रिये यासह शरिर ताब्यात ठेवणाऱ्या,  निरिच्छ आणि संग्रह न करणाऱ्या योग्याने एकट्याने एकांतात बसून आत्म्याला नेहमी परमात्म्यात लावावे. 6.10",

        "शुद्ध जमिनीवर क्रमाने दर्भ,  मृगाजिन आणि वस्त्र अंथरून तयार केलेले, जे फार उंच नाही व फार सखलनाही असे आपले आसन स्थिर मांडून - 6.11",

        "त्या आसनावर बसून चित्त व इंद्रियांच्या क्रिया ताब्यात ठेवून मन एकाग्र करून अंतःकरणाच्या शुद्धीसाठी योगाभ्यास करावा. 6.12",

        "शरीर,  डोके आणि मान सरळ रेषेत अचल ठेवून स्थिर व्हावे. आपल्या नाकाच्या शेंड्यावर दृष्टी ठेवून अन्य दिशांकडे न पाहता - 6.13",

        "ब्रह्मचर्यव्रतात राहणाऱ्या,  निर्भय तसेच अत्यंत शांत अंतकरण असणाऱ्या सावध योग्याने मन आवरुन,  चित्त माझ्या ठिकाणी लावून माझ्या आश्रयाने राहावे. 6.14",

        "मन ताब्यात ठेवलेला योगी अशा प्रकारे आत्म्याला नेहमी मज परमेश्वराच्या स्वरूपाच्या ठिकाणी लावून माझ्यात असणारी परमानंदाची पराकाष्टा अशी शांती मिळवितो. 6.15",

        "हे अर्जुना! हा योग फार खाणाऱ्याला, अजिबात न खाणाऱ्याला, फार झोपाळू ला तसेच नेहमी जागरण करणाऱ्याला साध्य होत नाही. 6.16",

        "दुःखाचा नाश करणारा योग यथा योग्य आहार विहार करणाऱ्याला कर्मांमध्ये यथायोग्य व्यवहार करणाऱ्याला आणि झोपणे व जागणे त्याची यथायोग्य आहेत,  त्यालाच साध्य होतो. 6.17",

        "पूर्णपणे ताब्यात आणलेले चित्त जेव्हा परमात्म्यात पूर्णपणे स्थिर होते तेव्हा सर्व भोगांची इच्छा नाहीशी झालेला पुरुष योग युक्त म्हटला जातो. 6.18",

        "ज्याप्रमाणे वारा नसलेल्या जागी दिव्याची ज्योत हालत नाही,  तीच उपमा परमात्म्याच्या ध्यानात मग्न झालेल्या योग्याच्या जिंकलेल्या चित्ताला दिली गेली आहे. 6.19",

        "योगाच्या अभ्यासाने निर्माण केलेले चित्त ज्या स्थितीत शांत होते आणि ज्या स्थितीत परमात्म्याच्या ध्यानाने शुद्ध झालेल्या सूक्ष्म बुद्धीने परमात्म्याचा साक्षात्कार होऊन सच्चिदानंदघन परमात्म्यात संतुष्ट राहते. 6.20",

        "इंद्रियातीत केवळ शुद्ध झालेल्या सूक्ष्म बुद्धीने ग्रहण करता येणारा जो अनंत आनंद आहे, तो ज्या अवस्थेत अनुभवाला येतो आणि ज्या अवस्थेत असलेला हा योगी परमात्म्याच्या स्वरूपापासून मुळीच विचलित होत नाही. 6.21",

        "परमात्मप्राप्ती रूप जो लाभ झाल्यामुळे त्याहून अधिक दुसरा कोणताही लाभ तो मानीत नाही आणि परमात्मप्राप्ती रुप ज्या अवस्थेत असलेला हा योगी फार मोठ्या दुःखानेही विचलित होत नाही. 6.22",

        "जो दुःखरूप संसाराच्या संयोगाने रहित आहे,  तसेच ज्याचे नाव योग आहे तो जाणला पाहिजे तो योग न कंटाळता अर्थात धैर्य व उत्साह युक्त चित्ताने निश्चयाने केला पाहिजे. 6.23",

        "संकल्पाने उत्पन्न होणार्‍या सर्व कामना पूर्णपणे टाकून आणि मनाने इंद्रिय समुदायाला सर्व बाजूंनी पूर्णतया आवरून 6.24",

        "क्रमाक्रमाने अभ्यास करीत उपरत व्हावे; तसेच धैर्य युक्त बुद्धीने मनाला परमात्म्यात स्थिर करून दुसऱ्या कशाचाही विचार करू नये. 6.25",

        "हे स्थिर न राहणारे चंचल मन ज्या ज्या शब्दादी विषयांच्या निमित्ताने संसारात भरकटत असते त्या-त्या विषयांपासून त्याला आवरून वारंवार परमात्म्यात स्थिर करावे. 6.26",

        "कारण ज्याचे मन पूर्ण शांत आहे, जो पापरहित आहे आणि ज्याचा रजोगुण शांत झालेला आहे अशा या सच्चिदानंदघन ब्रह्माशी ऐक्य पावलेल्या योग्याला उत्तम आनंद मिळतो. 6.27",

        "तो निष्पाप योगी अशा प्रकारे सतत आत्म्याला परमात्म्याशी जोडून सहजपणे परब्रम्ह परमात्म्याच्या प्राप्तीच्या अपार आनंदाचा अनुभव घेतो. 6.28",

        "ज्याचा आत्मा सर्वव्यापी अनंत चैतन्यात ऐक्यस्थितीरूप योगाने युक्त असून जो सर्वांना समभावाने पाहणारा आहे, असा योगी आत्मा सर्व प्राणीमात्रातस्थित व सर्व प्राणीमात्र आत्म्यात कल्पिलेले पाहतो. 6.29",

        "जो पुरुष सर्व प्राण्यांत सर्वांचा आत्मा असलेल्या मला वासुदेवालाच व्यापक असलेला पाहतो आणि सर्व प्राण्यांना मज वासुदेवात पाहतो, त्याला मी अदृश्य असत नाही आणि मला तो अदृश्य असत नाही. 6.30",

        "जो पुरुष ऐक्यभावाला प्राप्त होऊन सर्व प्राणीमात्रात आत्म रूपाने असलेल्या मला सच्चिदानंदघन वासुदेवाला भजतो, तो योगी सर्व प्रकारचे व्यवहार करत असला तरी त्याचे सर्व व्यवहार माझ्यातच होत असतात. 6.31",

        "हे अर्जुना! जो योगी आपल्या प्रमाणे सर्व प्राणीमात्रांना समभावाने पाहतो तसेच सर्वांमध्ये सुख किंवा दुःख समदृष्टीने पाहतो तो योगी अत्यंत श्रेष्ठ मानला गेला आहे. 6.32",

        "अर्जुन म्हणाला, हे मधुसूदना! जो हा समभावाचा योग तुम्ही सांगितलात, तो मन चंचल असल्यामुळे नित्य स्थिर राहील, असे मला वाटत नाही. 6.33",

        "कारण हे श्रीकृष्णा! हे मन मोठे चंचल, मोठे दृढ आणि बलवान आहे, त्यामुळे त्याला वश करणे, मी वाऱ्याला अडवण्याप्रमाणेच अत्यंत कठीण समजतो. 6.34",

        "श्रीभगवान म्हणाले, हे महाबाहो! मन चंचल आणि आवरण्यास कठीण आहे, यात शंका नाही, परंतु हे कुंतीपुत्र अर्जुना! ते अभ्यासाने आणि वैराग्याने ताब्यात येते. 6.35",

        "ज्याने मनावर ताबा मिळवला नाही, अशा पुरुषाला योग साधणे कठीण आहे आणि ज्याने मन ताब्यात ठेवले आहे, अशा प्रयत्नशील माणसाला साधनेने तो प्राप्त होणे शक्य आहे, असे माझे मत आहे. 6.36",

        "अर्जुन म्हणाला,  हे कृष्णा! जो योगावर श्रद्धा ठेवणारा आहे; परंतु संयमी नसल्यामुळे ज्याचे मन अंतकाळी योगापासून विचलित झाले,  असा साधक योगसिद्धीला म्हणजे भगवतसाक्षात्काराला प्राप्त न होता कोणत्या गतीला जातो? 6.37",

        "हे महाबाहो! भगवतप्राप्तीच्या मार्गात मोहित झालेला व आश्रय रहित असलेला पुरुष छिन्न-विछिन्न ढगाप्रमाणे दोन्हीकडून भ्रष्ट होऊन नाश तर नाही ना पावत? 6.38",

        "हे श्रीकृष्णा! हा माझा संशय तुम्हीच पूर्णपणे नाहीसा करू शकाल कारण तुमच्याशिवाय दुसरा हा संशय दूर करणारा मिळण्याचा संभव नाही. 6.39",

        "भगवान म्हणाले,  हे पार्था! त्या पुरुषाचा इहलोकातही नाश नाही व परलोकातही नाही. कारण बाबा रे! आत्मोद्धारासाठी अर्थात भगवतप्राप्ती साठी कर्म करणारा कोणताही मनुष्य अधोगतीला जात नाही. 6.40",

        "योगभ्रष्ट पुरुष पुण्यवानांना मिळणाऱ्या लोकांना अर्थात स्वर्गादी उत्तम लोकांना जाऊन तेथे पुष्कळ वर्षे राहून नंतर शुद्ध आचरण असणाऱ्या श्रीमंतांच्या घरात जन्म घेतो. 6.41",

        "किंवा वैराग्यशील पुरुष त्या लोकात न जाता ज्ञानी योग्यांच्याच कुळात जन्म घेतो, परंतु या प्रकारचा जो हा जन्म आहे तो या जगात नि:संशय अत्यंत दुर्मिळ आहे. 6.42",

        "तेथे त्या पहिल्या शरीरात संग्रह केलेल्या बुद्धी संयोगाला म्हणजे समत्वबुद्धी रूप योग्याच्या संस्कारांना अनायासे प्राप्त होतो आणि हे कुरुनंदना! त्यांच्या प्रभावाने तो पुन्हा परमात्मप्राप्तीरूप सिद्धीसाठी पूर्वीपेक्षा अधिक प्रयत्न करतो. 6.43",

        "तो श्रीमंतांच्या घरात जन्म घेणारा योगभ्रष्ट पराधीन असला तरी त्या पहिल्या जन्मीच्या अभ्यासामुळे नि:शंकपणे भगवंताकडे आकर्षिला जातो,  तसेच समबुद्धीरूप योगाचा जिज्ञासू देखील वेदाने सांगितलेल्या सकाम कर्मांच्या फळांना ओलांडून जातो. 6.44",

        "परंतु प्रयत्नपूर्वक अभ्यास करणारा योगी तर मागील अनेक जन्मांच्या संस्कारांच्या जोरावर या जन्मात पूर्ण सिद्धी मिळवून सर्व पापांपासून मुक्त होऊन तात्काळ परम गतीला प्राप्त होतो. 6.45",

        "तपस्वयाहूनही योगी श्रेष्ठ आहे शास्त्रज्ञानांहूनही तो श्रेष्ठ मानला गेला आहे आणि सकाम कर्मे करणार्‍याहूनही श्रेष्ठ आहे म्हणून हे अर्जुना! तू योगी हो. 6.46",

        "सर्व योग्यांतही जो श्रद्धावान योगी माझ्या ठिकाणी अंतरात्म्याला स्थापन करून मला अखंड भजतो तो योगी मला सर्वात श्रेष्ठ वाटतो. 6.47",

        "इस प्रकार ॐ तत्  सत् - इन भगवन्नामों के उच्चारणपूर्वक ब्रह्मविद्या और योगशास्त्रमय श्रीमद्भगवद्गीतोपनिषदरूप श्रीकृष्णार्जुनसंवाद में  आत्मसंयमयोग’ नामक छठा अध्याय पूर्ण हुआ।"
      ],
      "adhyaya-7" : [
        "श्रीभगवान म्हणाले, हे पार्था! अनन्य प्रेमाने मन माझ्या ठिकाणी आसक्त करून तसेच अनन्य भावाने माझा आश्रय घेऊन, योगयुक्त होऊन तु ज्यायोगे संपूर्ण विभूती, शक्ती, ऐश्वर्यादी गुणांनी युक्त, सर्वांचा आत्मा असणाऱ्या मला निःसंकोचपणे जाणशील, ते ऐक. 7.1",

        "मी तुला विज्ञानासह तत्वज्ञान संपूर्ण सांगेन, जे जाणले असता या जगात पुन्हा दुसरे काहीही जाणावयाचे शिल्लक राहत नाही. 7.2",

        "हजारो मनुष्यांत कोणी एखादा माझ्या प्राप्तीसाठी प्रयत्न करतो आणि त्या प्रयत्न करणाऱ्या योग्यांमध्येही एखादाच मत्परायण होऊन मला खऱ्या स्वरूपाने जाणतो. 7.3",

        "पृथ्वी, पाणी, अग्नी, वायू, आकाश, मन, बुध्दी आणि अहंकार अशी ही आठ प्रकारात विभागलेली माझी प्रकृती आहे. ही आठ प्रकारचे भेद असणारी माझी अपरा म्हणजे अचेतन प्रकृती आहे. आणि हे महाबाहो! हिच्याहून दुसरी, जिच्यायोगे सर्व जग धारण केले जाते, ती माझी जीवरूप परा म्हणजे सचेतन प्रकृती समज. (7.4-7.5)",

        "पृथ्वी, पाणी, अग्नी, वायू, आकाश, मन, बुध्दी आणि अहंकार अशी ही आठ प्रकारात विभागलेली माझी प्रकृती आहे. ही आठ प्रकारचे भेद असणारी माझी अपरा म्हणजे अचेतन प्रकृती आहे. आणि हे महाबाहो! हिच्याहून दुसरी, जिच्यायोगे सर्व जग धारण केले जाते, ती माझी जीवरूप परा म्हणजे सचेतन प्रकृती समज. (7.4-7.5)",

        "हे अर्जुना! तू असे समज की, सर्व भूतमात्र या दोन प्रकृतींपासूनच उत्पन्न झालेले आहे. आणि मी सर्व जगाची उत्पत्ती व प्रलय आहे अर्थात सर्व जगाचे मूळ कारण आहे. 7.6",

        "हे धनंजया! माझ्याहून निराळे दुसरे कोणतेही परम कारण नाही. हे संपूर्ण जग दोऱ्यात दोऱ्याचे मणी ओवावे, तसे माझ्यात ओवलेले आहे. 7.7",

        "हे अर्जुना! मी पाण्यातील रस आहे, चंद्र-सूर्यातील प्रकाश आहे, सर्व वेदांतील ओंकार आहे, आकाशातील शब्द आणि पुरुषांतील पुरुषत्व आहे. 7.8",

        "मी पृथ्वीतील पवित्र गंध आणि अग्नीतील तेज आहे. तसेच सर्व भूतांचे जीवन आहे आणि तपसव्यांतील तप आहे. 7.9",

        "हे अर्जुना! तू संपूर्ण भूतांचे सनातन कारण मलाच समज. मी बुध्दिमानांची बुध्दी आणि तेजस्व्यांचे तेज आहे. 7.10",

        "हे भरतश्रेष्ठा! मी बलवानांचे असक्तीरहित व कामनारहित सामर्थ्य आहे आणि सर्व प्राण्यांतील धर्माला अनुकूल अर्थात शास्त्राला अनुकूल असा काम आहे. 7.11",

        "आणखीही जे सत्वगुणापासून, रजोगुणापासून आणि तमोगुणापासून होणारे भाव व पदार्थ आहेत, ते सर्व माझ्यापासूनच उत्पन्न होणारे आहेत असे तू समज. परंतु वास्तविक पाहता त्यांच्यात मी आणि माझ्यात ते नाहीत. 7.12",

        "गुणांचे कार्य असणाऱ्या सात्विक, राजस आणि तामस या तिन्ही प्रकारच्या पदार्थांनी हे सारे जग- प्राणीसमुदाय मोहित झाले आहे. त्यामुळे या तिन्ही गुणांच्या पलीकडे असणाऱ्या अविनाशी अशा मला ते ओळखत नाही. 7.13",

        "कारण ही अलौकिक अर्थात् अति अद्भुत त्रिगुणात्मक माझी माया पार होण्यास कठीण आहे. परंतु जे केवळ मलाच निरंतर भजतात, ते या मायेला ओलांडून जातात म्हणजे संसार तरून जातात. 7.14",

        "मायेने ज्यांचे ज्ञान हिरावून घेतले आहे, असे आसुरी स्वभावाचे, मनुष्यांत नीच असणारे, दुष्ट कर्मे करणारे मूर्ख लोक मला भजत नाहीत. 7.15",

        "हे भरतवंशीयांमध्ये श्रेष्ठ अर्जुना! उत्तम कर्मे करणारे अर्थार्थी, आर्त, जिज्ञासू आणि ज्ञानी असे चार प्रकारचे भक्त मला भजतात. 7.16",

        "त्यांपैकी नेहमी माझ्या ठिकाणी ऐक्य भावाने स्थित असलेला अनन्य प्रेम-भक्ती असलेला ज्ञानी भक्त अति उत्तम होय. कारण मला तत्वतः जाणणाऱ्या ज्ञानी माणसाला मी अत्यंत प्रिय आहे आणि तो ज्ञानी मला अत्यंत प्रिय आहे. 7.17",

        "हे सर्वच उदार आहेत. परंतु ज्ञानी तर साक्षात माझे स्वरूपच आहे, असे माझे मत आहे. कारण तो माझ्या ठिकाणी मन-बुध्दी असणारा ज्ञानी भक्त अति उत्तम गतिस्वरूप अशा माझ्यामध्येच चांगल्या प्रकारे स्थित असतो. 7.18",

        "पुष्कळ जन्मांच्या शेवटच्या जन्मात तत्वज्ञान झालेला पुरुष ‘सर्व काही वासुदेव' च आहे असे समजून मला भजतो, तो महात्मा अत्यंत दुर्मिळ आहे. 7.19",

        "त्या त्या भोगांच्या इच्छेने ज्यांचे ज्ञान हिरावून घेतले आहे असे लोक आपापल्या स्वभावाने प्रेरित होऊन निरनिराळे नियम पाळून इतर देवतांची पूजा करतात. 7.20",

        "जो जो सकाम भक्त ज्या ज्या देवतास्वरूपाचे श्रध्देने पूजन करू इच्छितो, त्या त्या भक्ताची त्याच देवतेवरील श्रध्दा मी दृढ करतो. 7.21",

        "तो तो त्या श्रद्धेने युक्त होऊन त्या देवतेचे पूजन करतो आणि त्या देवतेकडून मीच ठरविलेले इच्छित भोग निश्चितपणे मिळवितो. 7.22",

        "पण त्या मंदबुध्दी लोकांचे ते फळ नाशिवंत असते. तसेच देवतांची पूजा करणारे देवतांना प्राप्त होतात आणि माझे भक्त, मला कसेही भजोत, अंती मलाच येऊन मिळतात. 7.23",

        "मूढ लोक माझ्या सर्वश्रेष्ठ, अविनाशी अशा परम भावाला न जाणता मन-इंद्रियांच्या पलीकडे असणाऱ्या, सच्चिदानन्दघन परमात्मस्वरूप मला मनुष्याप्रमाणे जन्म घेऊन प्रगट झालेला मानतात. 7.24",

        "आपल्या योगमायेने लपलेला मी सर्वांना प्रत्यक्ष दिसत नाही. म्हणून हे अज्ञानी लोक जन्म नसलेल्या, अविनाशी, मला परमेश्वराला जाणत नाहीत. अर्थात् मी जन्मणारा-मरणारा आहे, असे समजतात. 7.25",

        "हे अर्जुना! पूर्वी होऊन गेलेल्या, वर्तमान काळातील आणि पुढे होणाऱ्या सर्व प्राण्यांना मी जाणतो. पण श्रध्दा-भक्ती नसलेला कोणीही मला जाणत नाही. 7.26",

        "हे भरतवंशी अर्जुना! सृष्टीत इच्छा आणि द्वेष यांमुळे उत्पन्न झालेल्या सुख-दुःखरूप द्वंद्वाच्या मोहाने सर्व प्राणी अत्यंत अज्ञानाला प्राप्त होतात. 7.27",

        "परंतु निष्कामभावाने श्रेष्ठ कर्मांचे आचरण करणाऱ्या ज्या पुरुषांचे पाप नष्ट झाले आहे, ते राग-द्वेषाने उत्पन्न होणाऱ्या द्वंद्वरूप मोहापासून मुक्त असलेले दृढनिश्चयी भक्त मला सर्व प्रकारे भजतात. 7.28",

        "जे मला शरण येऊन वार्धक्य व मरण यांपासून सुटण्याचा प्रयत्न करतात ते पुरुष, ते ब्रह्म, संपूर्ण अध्यात्म आणि संपूर्ण कर्म जाणतात. 7.29",

        "जे पुरुष अधिभूत, अधिदैव व अधियज्ञ यांसह (सर्वांच्या आत्मरूप अशा) मला अंतकाळीही जाणतात, ते युक्त चित्ताचे पुरुष मला जाणतात, म्हणजे मला येऊन मिळतात. 7.30",

        "इस प्रकार ॐ तत्  सत् - इन भगवन्नामों के उच्चारणपूर्वक ब्रह्मविद्या और योगशास्त्रमय श्रीमद्भगवद्गीतोपनिषदरूप श्रीकृष्णार्जुनसंवाद में ‘ज्ञानविज्ञानयोग’ नामक सातवाँ अध्याय पूर्ण हुआ।"   
      ],
      "adhyaya-8" : [
        "अर्जुन म्हणाला, हे पुरुषोत्तमा! ते ब्रह्म काय आहे? अध्यात्म काय आहे? कर्म काय आहे? अधिभूत शब्दाने काय सांगितले आहे? आणि अधिदेव कशाला म्हणतात ? 8.1",

        "हे मधुसूदना! येथे अधियज्ञ कोण आहे? आणि तो या शरीरात कसा आहे? तसेच अंतकाळी युक्त चित्ताचे पुरुष तुम्हाला कसे जाणतात? 8.2",

        "श्री भगवान म्हणाले, परम अक्षर 'ब्रह्म' आहे. आपले स्वरूप अर्थात जीवात्मा 'अध्यात्म' नावाने सांगितला जातो. तसेच भूतांचे भाव उत्पन्न करणारा जो  त्याग आहे, तो कर्म या नावाने संबोधला जातो. 8.3",

        "उत्पत्ति- विनाश असलेले सर्व पदार्थ 'अधिभूत' आहेत. हिरण्यमय पुरुष  'अधिदैव' आहे आणि देहधारी यांमध्ये श्रेष्ठ अर्जुना! या शरीरात मी वासुदेवच अंतर्यामी रूपाने 'अधियज्ञ' आहे. 8.4",

        "जो पुरुष अंतकाळी ही माझे स्मरण करीत शरीराचा त्याग करून जातो, तो साक्षात माझ्या स्वरूपाला प्राप्त होतो, यात मुळीच शंका नाही. 8.5",

        "हे कुंतीपुत्र अर्जुना! हा मनुष्य अंतकाळी ज्या ज्या भावाचे स्मरण करीत शरीराचा त्याग करतो, त्याला त्याला तो जाऊन मिळतो. कारण तो नेहमी त्याच भावाचे चिंतन करीत असतो. 8.6",

        "म्हणून हे अर्जुना! तू सर्वकाळी निरंतर माझे स्मरण कर आणि युद्धही कर. अशाप्रकारे माझ्या ठिकाणी मन बुद्धी अर्पण केल्यामुळे तू निसं:शय मलाच मिळशील. 8.7",

        "हे पार्था! असा नियम आहे की, परमेश्वराच्या ध्यानाच्या अभ्यास रुपी योगाने युक्त, दुसरीकडे न जाणाऱ्या चित्ताने निरंतर चिंतन करणारा मनुष्य, परम प्रकाशस्वरूप दिव्य पुरुषाला म्हणजे परमेश्वरालाच जाऊन मिळतो. 8.8",

        "जो पुरुष सर्वज्ञ, अनादी, सर्वांचा नियामक, सूक्ष्म यापेक्षाही अतिसूक्ष्म, सर्वांचे धारण पोषण करणारा, अतर्क्य स्वरूप  सूर्याप्रमाणे नेहमी चेतन प्रकाश रूप आणि अविद्येच्या अत्यंत पलिकडील अशा शुद्ध सच्चिदानंदघन परमेश्वराचे स्मरण करतो, 8.9",

        "तो भक्तियुक्त पुरुष अंतकाळी सुद्धा योगबलाने भुवयांच्या मध्यभागी प्राण चांगल्या रीतीने स्थापन करून मग निश्चल मनाने स्मरण करीत त्या दिव्य रूप परमपुरुष परमात्म्यालाच प्राप्त होतो. 8.10",

        "वेदवेत्ते विद्वान ज्या सच्चिदानंदघनस्वरूप परमपदाला अविनाशी म्हणतात, आसक्ती नसलेले यत्नशील संन्यासी  महात्मे ज्यांच्यामध्ये प्रवेश करतात आणि ज्या परमपदाची इच्छा करणारे ब्रह्मचारी ब्रह्मचर्याचे आचरण करतात, ते परमपद मी तुला थोडक्यात सांगतो. 8.11",

        "सर्व इंद्रियांची दारे अडवून, मनाला हृदयाच्या  ठिकाणी स्थिर करून, नंतर जिंकलेल्या मनाने प्राण मस्तकात स्थापन करून, परमात्म्यासंबंधी योग्य धारणेत स्थिर होऊन जो पुरुष 'ॐ' या एक अक्षर रूप ब्रह्माचा उच्चार करीत आणि त्याचे अर्थ स्वरूप निर्गुण ब्रह्म जो मी, त्याचे चिंतन करीत देह टाकून जातो, तो परम गतीला प्राप्त होतो. (8.12-8.13)",

        "सर्व इंद्रियांची दारे अडवून, मनाला हृदयाच्या  ठिकाणी स्थिर करून, नंतर जिंकलेल्या मनाने प्राण मस्तकात स्थापन करून, परमात्म्यासंबंधी योग्य धारणेत स्थिर होऊन जो पुरुष 'ॐ' या एक अक्षर रूप ब्रह्माचा उच्चार करीत आणि त्याचे अर्थ स्वरूप निर्गुण ब्रह्म जो मी, त्याचे चिंतन करीत देह टाकून जातो, तो परम गतीला प्राप्त होतो. (8.12-8.13)",

        "हे अर्जुना! जो पुरुष माझ्या ठिकाणी अनन्यचित्त  होऊन नेहमी मज पुरुषोत्तमाचे स्मरण करतो, त्या नित्य माझ्याशी युक्त असलेल्या योग्या ला मी सहज प्राप्त होणारा आहे. 8.14",

        "परम सिद्धी मिळविलेले महात्मे एकदा मला प्राप्त झाल्यावर दुःखाचे आगर असलेल्या क्षणभंगुर पुनर्जन्माला जात नाही. 8.15",

        "हे अर्जुना! ब्रह्मलोकांपर्यंतचे सर्व लोक पुनरावर्ती आहेत, परंतु हे कुंती पुत्रा! मला प्राप्त झाल्यावर पुनर्जन्म होत नाही. कारण मी कालातीत आहे आणि हे सर्व ब्रह्मादिकांचे लोक कालाने मर्यादित असल्याने अनित्य आहे. 8.16",

        "ब्रह्मदेवाचा एक दिवस एक हजार चतुर्यूगाचा असून रात्रही एक हजार चतुर्यूगाची असते. जे योगी हे तत्वतः जाणतात, ते काळाचे स्वरूप जाणणारे होत. 8.17",

        "सर्व चराचर भूत समुदाय ब्रह्मदेवाच्या दिवसाच्या आरंभी अव्यक्ता पासून म्हणजे ब्रह्मदेवाच्या सुक्ष्म शरीरापासून उत्पन्न होतात आणि ब्रह्मदेवाच्या रात्रीच्या आरंभी त्या अव्यक्त नावाच्या ब्रह्मदेवाच्या सूक्ष्म शरीरात विलीन होतात. 8.18",

        "हे पार्था! तोच हा भूत समुदाय पुन्हा पुन्हा उत्पन्न होऊन प्रकृतीच्या आधीन असल्यामुळे रात्रीच्या आरंभी विलीन होतो व दिवसाच्या आरंभी पुन्हा उत्पन्न होतो. 8.19",

        "त्या अव्यक्ताहून फार पलीकडचा तिसरा अर्थात विलक्षण जो सनातन अव्यक्त भाव आहे, तो परम दिव्य पुरुष सर्व भुते नाहीशी झाली तरी नाहीसा होत नाही. 8.20",

        "त्याला अव्यक्त, अक्षर असे म्हणतात, यालाच श्रेष्ठ गती म्हणतात. ज्या सनातन अव्यक्त भावाला प्राप्त झाल्यावर मनुष्य परत येत नाही, ते माझे सर्वश्रेष्ठ स्थान होय. 8.21",

        "हे पार्था! ज्या परमात्म्याच्या ठिकाणी सर्व भुते आहेत आणि ज्या सच्चिदानंदघन परमात्म्याने हे सर्व जग व्यापले आहे, तो सनातन अव्यक्त परमपुरुष अनन्य भक्तीने प्राप्त होणार आहे. 8.22",

        "हे अर्जुना! ज्या काळी शरीराचा त्याग करून गेलेले योगी परत जन्माला न येणार्‍या गतीला प्राप्त होतात आणि ज्याकाळी गेलेले परत जन्माला येणार्‍या गतीला प्राप्त होतात, तो काळ अर्थात दोन मार्ग मी सांगेन. 8.23",

        "ज्या मार्गात ज्योतिर्मय अग्नीची अभिमानी देवता आहे, दिवसाची अभिमानी देवता आहे, शुक्लपक्षाची अभिमानी देवता आहे आणि उत्तरायणाचा सहा महिन्यांची अभिमानी देवता आहे, त्या मार्गात गेल्यावर गेलेले ब्रह्मज्ञानी योगी वरील देवतांकडून क्रमाने नेले जाऊन ब्रह्माला प्राप्त होतात. 8.24",

        "महामार्गात धुराची अभिमानी देवता आहे, रात्रीची अभिमानी देवता आहे, कृष्ण पक्षाची अभिमानी देवता आहे आणि दक्षिणायनाच्या सहा महिन्यांची अभिमानी देवता आहे, त्या मार्गात मेल्यावर गेलेला सकाम कर्म करणारा योगी वरील देवतांकडून नेला जातो. पुढे तो चंद्र तेजाला प्राप्त होऊन स्वर्गात आपल्या शुभ कर्माची फळे भोगून परत येतो. 8.25",

        "कारण जगाचे हे दोन प्रकारचे शुक्ल आणि कृष्ण अर्थात देवयान आणि पितृ यान मार्ग सनातन मानले गेले आहेत. यातील ज्या मार्गाने गेले असता परत यावे लागत नाही, अशा मार्गाने गेलेला त्या परम गतीला प्राप्त होतो आणि दुसऱ्या मार्गाने गेलेला पुन्हा परत येतो म्हणजे जन्म-मृत्यू ला प्राप्त होतो. 8.26",

        "हे पार्था! अशा रीतीने या दोन मार्गांना तत्वतः जाणल्यावर कोणीही योगी मोह पावत नाही. म्हणून हे अर्जुना! तू सर्वकाळी समबुद्धिरूप योगाने युक्त हो अर्थात नेहमी माझ्या प्राप्तीसाठी साधन करणारा हो. 8.27",

        "योगी पुरुष या रहस्याला तत्वतः जाणून, वेदांचे पठण, यज्ञ, तप, दान इत्यादी करण्याचे जे पुण्यफल सांगितले आहे, त्या सर्वाला निःसंशय  ओलांडून जातो आणि परमपदाला पोहोचतो. 8.28",

        "इस प्रकार ॐ तत्  सत् - इन भगवन्नामों के उच्चारणपूर्वक ब्रह्मविद्या और योगशास्त्रमय श्रीमद्भगवद्गीतोपनिषदरूप श्रीकृष्णार्जुनसंवाद में ‘अक्षरब्रह्मयोग’ नामक आठवाँ अध्याय पूर्ण हुआ।"
      ],
      "adhyaya-9" : [
        "श्रीभगवान म्हणाले, दोषदृष्टीरहित अशा तुला भक्ताला हे अतिशय गोपनीय विज्ञानासहित ज्ञान पुन्हा नीटपणे सांगतो. ते जाणल्याने तू दुःखरूप संसारापासून मुक्त होशील. 9.1",

        "हे विज्ञानासहित ज्ञान सर्व विद्यांचा राजा, सर्व गुप्त गोष्टींचा राजा, अतिशय पवित्र, अतिशय उत्तम, प्रत्यक्ष फळ देणारे, धर्मयुक्त, साधन करण्यास फार सोपे आणि अविनाशी आहे. 9.2",

        "हे परंतपा! या वर सांगितलेल्या धर्मावर श्रद्धा नसलेले पुरुष मला प्राप्त न होता मृत्युरूप संसारचक्रात फिरत राहतात. 9.3",

        "जसे पाण्याने बर्फ परिपूर्ण भरलेले असते, तसे मी निराकार परमात्म्याने हे सर्व जग पूर्ण व्यापलेले आहे. तसेच सर्व भूते माझ्यामध्ये संकल्पाच्या आधारावर राहिलेली आहेत. पण वास्तविक मी त्यांच्यामध्ये राहिलेलो नाही. 9.4",

        "ती सर्व भूते माझ्या ठिकाणी राहिलेली नाहीत. परंतु माझी ईश्वरी योगशक्ती पहा की, भूतांना उत्पन्न करणारा व त्यांचे धारण-पोषण करणारा असूनही माझा आत्मा वास्तविकपणे भूतांच्या ठिकाणी राहिलेला नाही. 9.5",

        "जसा आकाशापासून उत्पन्न होऊन सर्वत्र फिरणारा महान् वायू नेहमी आकाशातच राहतो, त्याचप्रमाणे माझ्या संकल्पाने उत्पन्न झाल्यामुळे सर्व भूते माझ्यात राहतात, असे समज. 9.6",

        "हे अर्जुना! कल्पाच्या शेवटी सर्व भूते माझ्या प्रकृतीत विलीन होतात आणि कल्पाच्या आरंभी त्यांना मी पुन्हा उत्पन्न करतो. 9.7",

        "आपल्या मायेचा अंगीकार करून प्रकृतीच्या ताब्यात असल्यामुळे पराधीन झालेल्या या सर्व भूतसमुदायाला मी वारंवार त्यांच्या कर्मानुसार उत्पन्न करतो. 9.8",

        "हे अर्जुना! त्या कर्मात आसक्ती नसलेल्या व उदासीनाप्रमाणे असलेल्या मज परमात्म्याला ती कर्मे बंधनकारक होत नाहीत. 9.9",

        "हे अर्जुना! माझ्या अधिष्ठानामुळे प्रकृती चराचरासह सर्व जग निर्माण करते. याच कारणाने हे संसारचक्र फिरत आहे. 9.10",

        "माझ्या परम भावाला न जाणणारे मूर्ख लोक मनुष्यशरीर धारण करणार्‍या मला - सर्व भूतांच्या ईश्वराला तुच्छ समजतात. अर्थात आपल्या योगमायेने जगाच्या उद्धारासाठी मनुष्यरूपात वावरणाऱ्या मला परमेश्वराला सामान्य मनुष्य समजतात. 9.11",

        "ज्यांची आशा व्यर्थ, कर्मे निरर्थक आणि ज्ञान फुकट असे विक्षिप्त चित्त असलेले अज्ञानी लोक राक्षसी, आसुरी आणि मोहिनी प्रकृतीचाच आश्रय करून राहतात. 9.12",

        "परंतु हे कुंतीपुत्रा! दैवी प्रकृतीचा आश्रय घेतलेले महात्मे मला सर्व भूतांचे सनातन कारण आणि अविनाशी अक्षर स्वरूप जाणून अनन्य चित्ताने युक्त होऊन निरंतर भजतात. 9.13",

        "ते दृढनिश्चयी भक्त निरंतर माझ्या नामाचे व गुणांचे कीर्तन करीत माझ्या प्राप्तीसाठी प्रयत्न करीत असतात. तसेच वारंवार मला प्रणाम नेहमी माझ्या ध्यानात मग्न होऊन अनन्य प्रेमाने माझी उपासना करतात. 9.14",

        "दुसरे काही ज्ञानयोगी मज निर्गुण निराकार ब्रह्माची ज्ञानयज्ञाने अभेदभावाने पूजा करीतही माझी उपासना करतात आणि दुसरे काही अनेक रूपांनी असलेल्या मज विराट स्वरूप परमेश्वराची नाना प्रकारांनी उपासना करतात. 9.15",

        "श्रौतयज्ञ मी आहे. स्मार्तयज्ञ मी आहे. पितृयज्ञ मी आहे. वनस्पती, अन्न व औषधी मी आहे. मंत्र मी आहे. तूप मी आहे आणि हवनाची क्रियाही मीच आहे. 9.16",

        "या जगाला धारण करणारा व कर्मफल देणारा, आई-वडील, आजोबा, जाणण्याजोगा पवित्र ओंकार, तसेच ऋग्वेद, सामवेद आणि यजुर्वेदही मीच आहे. 9.17",

        "प्राप्त होण्याजोगे परमधाम, भरण-पोषण करणारा, सर्वांचा स्वामी, शुभाशुभ पाहणारा, सर्वांचे निवासस्थान, शरण जाण्याजोगा, प्रत्युपकाराची इच्छा न करता हित करणारा, सर्वांच्या उत्पत्ति-प्रलयाचे कारण, स्थितीला आधार, निधान आणि अविनाशी कारणही मीच आहे. 9.18",

        "मीच सूर्याच्या रूपाने उष्णता देतो, पाणी आकर्षून घेतो व त्याचा वर्षाव करतो. हे अर्जुना! मीच अमृत आणि मृत्यू आहे आणि सत् व असत् ही मीच आहे. 9.19",

        "तिन्ही वेदांत सांगितलेली सकाम कर्मे करणारे, सोमरस पिणारे, पापमुक्त लोक माझी यज्ञांनी पूजा करून स्वर्गप्राप्तीची इच्छा करतात. ते पुरुष आपल्या पुण्याईचे फळ असणार्‍या स्वर्गलोकाला जाऊन स्वर्गात देवांचे भोग भोगतात. 9.20",

        "ते त्या विशाल स्वर्गलोकाचा उपभोग घेऊन पुण्याई संपल्यावर मृत्यूलोकात येतात. अशा रीतीने स्वर्गप्राप्तीचे साधन असणार्‍या, तिन्ही वेदांत सांगितलेल्या, सकाम कर्मांचे अनुष्ठान करून भोगांची इच्छा करणारे पुरुष वारंवार ये-जा करीत असतात. अर्थात पुण्याच्या जोरावर स्वर्गात जातात आणि पुण्य संपल्यावर मृत्यूलोकात येतात. 9.21",

        "जे अनन्य प्रेमी भक्त मज परमेश्वराला निरंतर चिंतन करीत निष्काम भावनेने भजतात, त्या नित्य माझे चिंतन करणार्‍या माणसांचा योगक्षेम मी स्वतः त्यांना प्राप्त करून देतो. 9.22",

        "हे अर्जुना! जे सकाम भक्त श्रद्धेने दुसर्‍या देवांची पूजा करतात, तेही माझीच पूजा करतात. परंतु त्यांचे ते पूजन अज्ञानपूर्वक असते. 9.23",

        "कारण सर्व यज्ञांचा भोक्ता आणि स्वामीही मीच आहे. पण ते मला तत्त्वतः जाणत नाहीत; म्हणून पुनर्जन्म घेतात. 9.24",

        "देवांची पूजा करणारे देवांना मिळतात. पितरांची पूजा करणारे पितरांना जाऊन मिळतात. भूतांची पूजा करणारे भूतांना प्राप्त होतात आणि माझी पूजा करणारे भक्त मला येऊन मिळतात. त्यामुळे माझ्या भक्तांना पुनर्जन्म नाही. 9.25",

        "जो कोणी भक्त मला प्रेमाने पान, फूल, फळ, पाणी इत्यादी अर्पण करतो, त्या शुद्ध बुद्धीच्या निष्काम प्रेमी भक्ताने अर्पण केलेले ते पान, फूल इत्यादी मी सगुण रूपाने प्रकट होऊन मोठ्या प्रीतीने खातो. 9.26",

        "हे अर्जुना! तू जे कर्म करतोस, जे खातोस, जे हवन करतोस, जे दान करतोस आणि जे तप करतोस, ते सर्व मला अर्पण कर. 9.27",

        "अशा रीतीने ज्यामधे सर्व कर्मे मला भगवंताला अर्पण होतात, अशा संन्यासयोगाने मुक्त चित्त असलेला तू शुभाशुभफलरूप कर्म बंधनातून मुक्त होशील आणि मला येऊन मिळशील. 9.28",

        "मी सर्व प्राणिमात्रात समभावाने व्यापून राहिलो आहे. मला ना कोणी अप्रिय ना प्रिय. परंतु जे भक्त मला प्रेमाने भजतात, ते माझ्यात राहतात आणि मीही त्यांच्यात प्रत्यक्ष प्रकट असतो. 9.29",

        "जर एखादा अत्यंत दुर्वर्तनीसुद्धा अनन्यभावाने माझा भक्त होऊन मला भजेल, तर तो सज्जनच समजावा. कारण तो यथार्थ निश्चयी असतो. अर्थात त्याने ईश्वरभजनासारखे दुसरे काहीही नाही, असा पूर्ण निश्चय केलेला असतो. 9.30",

        "तो तत्काळ धर्मात्मा होतो आणि नेहमी टिकणाऱ्या परम शांतीला प्राप्त होतो. हे अर्जुना! तू हे पक्के सत्य लक्षात ठेव की माझा भक्त नाश पावत नाही. 9.31",

        "हे अर्जुना! स्त्रिया, वैश्य, शूद्र तसेच पापयोनी अर्थात चाण्डालादी कोणीही असो, तेसुद्धा मला शरण आले असता परम गतीलाच प्राप्त होतात. 9.32",

        "मग पुण्यशील ब्राह्मण तसेच राजर्षी भक्तलोक मला शरण येऊन परम गतीला प्राप्त होतात, हे काय सांगावयास पाहिजे?  म्हणून तू सुखरहित व नाशवंत या मनुष्यशरीराला प्राप्त होऊन नेहमी माझेच भजन कर. 9.33",

        "माझ्यात मन ठेव. माझा भक्त हो. माझी पूजा कर. मला नमस्कार कर. अशारीतीने आत्म्याला माझ्याशी जोडून मत्परायण होऊन तू मलाच प्राप्त होशील. 9.34",

        "इस प्रकार ॐ तत्  सत् - इन भगवन्नामों के उच्चारणपूर्वक ब्रह्मविद्या और योगशास्त्रमय श्रीमद्भगवद्गीतोपनिषदरूप श्रीकृष्णार्जुनसंवाद में ‘राजविद्याराजगुह्ययोग’ नामक नवाँ अध्याय पूर्ण हुआ।"
      ],
      "adhyaya-10" : [
        "श्रीभगवान म्हणाले, हे महाबाहो! आणखीही माझे परम रहस्यमय आणि प्रभावयुक्त म्हणणे ऐक. जे मी अतिशय प्रेमी अशा तुला तुझ्या हितासाठी सांगणार आहे. 10.1",

        "माझी उत्पत्ती अर्थात् लीलेने प्रकट होणे ना देव जाणत ना महर्षी. कारण मी सर्व प्रकारे देवांचे व महर्षीचे आदिकारण आहे. 10.2",

        "जो मला वास्तविक जन्मरहित, अनादी आणि लोकांचा महान ईश्वर असे तत्त्वत: जाणतो, तो मनुष्यात ज्ञानी असणारा सर्व पापांपासून मुक्त होतो. 10.3",

        "निर्णयशक्ती, यथार्थ ज्ञान, असंमूढता, क्षमा, सत्य, इंद्रियनिग्रह, मनोनिग्रह, सुख-दुःख, उत्पत्ति प्रलय, भय अभय, अहिंसा, समता, संतोष, तप, दान, कीर्ति-अपकीर्ति असे हे प्राण्यांचे अनेक प्रकारचे भाव माझ्यापासूनच होतात. (10.4-10.5)",

        "निर्णयशक्ती, यथार्थ ज्ञान, असंमूढता, क्षमा, सत्य, इंद्रियनिग्रह, मनोनिग्रह, सुख-दुःख, उत्पत्ति प्रलय, भय अभय, अहिंसा, समता, संतोष, तप, दान, कीर्ति-अपकीर्ति असे हे प्राण्यांचे अनेक प्रकारचे भाव माझ्यापासूनच होतात. (10.4-10.5)",

        "सात महर्षी, त्यांच्याही पूर्वी असणारे चार सनकादिक तसेच स्वायंभुव इत्यादी चौदा मनू हे माझ्या ठिकाणी भाव असलेले सर्वच माझ्या संकल्पाने उत्पन्न झाले आहेत. या जगातील सर्व प्रजा त्यांचीच आहे. 10.6",

        "जो पुरुष माझ्या ह्या परमैश्वर्यरूप विभूतीला आणि योगशक्तीला तत्त्वत: जाणतो, तो स्थिर भक्तियोगाने युक्त होतो, यात मुळीच शंका नाही. 10.7",

        "मी वासुदेव सर्व जगाच्या उत्पत्तीचे कारण आहे आणि माझ्यामुळेच सर्व जग क्रियाशील होत आहे, असे जाणून श्रद्धा व भक्तीने युक्त असलेले बुद्धिमान भक्त मज परमेश्वरालाच नेहमी भजतात. 10.8",

        "निरंतर माझ्यात मन लावणारे आणि माझ्यातच प्राणांना अर्पण करणारे माझे भक्तजन माझ्या भक्तीच्या चर्चेने परस्परांत माझ्या प्रभावाचा बोध करीत तसेच गुण व प्रभावासह माझे किर्तन करीत निरंतर संतुष्ट होतात आणि मज वासुदेवातच नेहमी रममाण होत असतात. 10.9",

        "त्या नेहमी माझे ध्यान वगैरेमधे मग्न झालेल्या आणि प्रेमाने भजणा-या भक्तांना मी तो तत्वज्ञानरूप योग देतो. ज्यामुळे ते मलाच प्राप्त होतात. 10.10",

        "हे अर्जुना! त्यांच्यावर कृपा करण्यासाठी त्यांच्या अंतःकरणात असलेला मी स्वतःच त्यांच्या अज्ञानाने उत्पन्न झालेला अंधकार प्रकाशमय तत्वज्ञानरूप दिव्याने नाहीसा करतो. 10.11",

        "अर्जुन म्हणाला, आपण परम ब्रह्म परम धाम आणि परम पवित्र आहात. कारण आपल्याला सर्व ऋषिगण सनातन, दिव्य पुरुष, तसेच देवांचाही आदिदेव, अजन्मा आणि सर्वव्यापी म्हणतात. देवर्षी नारद, अमित, देवल व महर्षी व्यासही तसेच सांगतात आणि आपणही मला तसेच सांगता. (10.12-10.13)",

        "अर्जुन म्हणाला, आपण परम ब्रह्म परम धाम आणि परम पवित्र आहात. कारण आपल्याला सर्व ऋषिगण सनातन, दिव्य पुरुष, तसेच देवांचाही आदिदेव, अजन्मा आणि सर्वव्यापी म्हणतात. देवर्षी नारद, अमित, देवल व महर्षी व्यासही तसेच सांगतात आणि आपणही मला तसेच सांगता. (10.12-10.13)",

        "हे केशवा! जे काही मला आपण सांगत आहात, ते सर्व मी सत्य मानतो. हे भगवन्! आपल्या लीलामय ना दानव जाणत ना देव. 10.14",

        "हे भूतांना उत्पन्न करणारे! हे भूतांचे ईश्वर! हे देवांचे देव! हे जगाचे स्वामी पुरुषोत्तमा! तुम्ही स्वतःच आपण आपल्याला जाणत आहात. 10.15",

        "म्हणून ज्या विभूतींच्या योगाने आपण या सर्व लोकांना व्यापून राहिला आहात, त्या आपल्या दिव्य वीभूती पूर्णपणे सांगायला आपणच समर्थ आहात. 10.16",

        "हे योगेश्वर! मी कशाप्रकारे निरंतर चिंतन करीत आपल्याला जाणावे आणि हे भगवन्! आपण कोणकोणत्या भावांत माझ्याकडून चिंतन करण्यास योग्य आहात? 10.17",

        "हे जनार्दना! आपली योगशक्ती आणि विभूती पुन्हाही विस्ताराने सांगा. कारण आपली अमृतमय वचने ऐकत असता माझी तृप्ती होत नाही. अर्थात् ऐकण्याची उत्कंठा अधिकच वाढत राहाते. 10.18",

        "श्रीभगवान म्हणाले, हे कुरूश्रेष्ठा! आता मी ज्या माझ्या दिव्य विभूती आहेत, त्या मुख्य मुख्य अशा तुला सांगेन. कारण माझ्या विस्ताराला शेवट नाही. 10.19",

        "हे अर्जुना! मी सर्व भूतांच्या हृदयात असलेला सर्वांचा आत्मा आहे. तसेच सर्व भूतांचा आदी, मध्य आणि अंतही मीच आहे. 10.20",

        "अदितीच्या बारा पुत्रांपैकी विष्णू मी आणि ज्योतींमध्ये किरणांनी युक्त सूर्य मी आहे. एकोणपन्नास वायुदेवतांचे तेज आणि नक्षत्रांचा अधिपती चंद्र मीच आहे. 10.21",

        "वेदांत सामवेद मी आहे. देवांत इंद्र आहे. इंद्रीयामधे मन आहे आणि भूतप्राण्यांमधील चेतना म्हणजे जीवनशक्ती मी आहे. 10.22",

        "अकरा रुद्रांमधे शंकर मी आहे आणि यक्ष व राक्षसांमधे धनाचा स्वामी कुबेर मी आहे. मी आठ वसूंमधला अग्नी आहे आणि शिखरे असणा-या पर्वतांमधे सुमेरु पर्वत मी आहे. 10.23",

        "पुरोहितांमध्ये मुख्य बृहस्पती मला समज. हे पार्था! मी सेनापतींमधला स्कंद आणि जलाशयांमधे समुद्र आहे. 10.24",

        "मी महर्षींमध्ये भृगू आणि शब्दांमध्ये एक अक्षर अर्थात् ओंकार आहे. सर्व प्रकारच्या यज्ञांमधे जपयज्ञ आणि स्थिर राहणाऱ्यांमध्ये हिमालय पर्वत मी आहे. 10.25",

        "सर्व वृक्षांत पिंपळ आणि देवर्षींमध्ये नारद मुनी, गंधर्वांमध्ये चित्ररथ आणि सिद्धांमध्ये कपिल मुनी मी आहे. 10.26",

        "घोड्यांमध्ये मृताबरोबर उत्पन्न झालेला उच्चैःश्रवा नावाचा घोडा, श्रेष्ठ हत्तींमध्ये ऐरावत नावाचा हत्ती आणि मनुष्यांमध्ये राजा मलाच समज. 10.27",

        "मी शस्त्रांमध्ये वज्र आणि गायींमध्ये कामधेनू आहे. शास्त्रोक्त रीतीने प्रजोत्पत्तीचे कारण कामदेव मी आहे आणि सर्पांमध्ये सर्पराज वासुकी मी आहे. 10.28",

        "मी नागांमध्ये शेषनाग आणि जलचरांचा अधिपती वरुण देव आहे पितरांमधे अर्यमा नावाचा पितर आणि शासन करणा-या मध्ये यमराज मी आहे. 10.29",

        "मी दैत्यांमध्ये प्रह्लाद आणि गणना करणा-यांमध्ये समय आहे. तसेच पशूंमध्ये मृगराज सिंह आणि पक्षांमध्ये गरुड आहे. 10.30",

        "मी पवित्र करणाऱ्यात वायू आणि शस्त्रधा-यांत श्रीराम आहे. तसेच माशांवर मगर आहे आणि नद्यांवर श्रीभागीरथी गंगा आहे. 10.31",

        "हे अर्जुना! सृष्टीचा आदी आणि अंत तसेच मध्यही मी आहे. मी विद्यांतील ब्रह्मविद्या आणि परस्पर वाद करणा-यांमध्ये तत्वनिर्णयासाठी केला जाणारा वाद मी आहे. 10.32",

        "मी अक्षरांतील अकार आणि समासांपैकी द्वन्द्व आहे.अक्षय काल म्हणजेच कालाचाही महाकाल तसेच सर्व बाजूंनी तोंडे असलेला विराटस्वरूप सर्वाचे धारण-पोषण करणाराही मीच आहे. 10.33",

        "सर्वाचा नाश करणारा मृत्यू आणि उत्पन्न होणा-यांच्या उत्पत्तीचे कारण मी आहे. तसेच स्त्रियांमध्ये कीर्ती, लक्ष्मी, वाणी, स्मृती, मेधा, धृती आणि क्षमा मी आहे. 10.34",

        "तसेच गायन करण्याजोग्या वेदांमध्ये मी बृहत्साम आणि छंदांमध्ये गायत्री छंद मी आहे. त्याचप्रमाणे महिन्यातील मार्गशीर्ष आणि ऋतुंतील वसंत मी आहे. 10.35",

        "कपट करणा-यांतील जुगार आणि प्रभावशाली पुरुषांचा प्रभाव आहे. मी जिंकणा-यांचा विजय आहे. निश्चयी लोकांचा निश्चय आणि सात्विक पुरुषांचा सात्विक भाव आहे. 10.36",

        "वृष्णिवंशियांमध्ये वासुदेव अर्थात मी स्वतः तुझा मित्र, पांडवामध्ये धनंजय म्हणजे तू, मुनींमध्ये वेदव्यास आणि कवींमध्ये शुक्राचार्य कवीही मीच आहे. 10.37",

        "दमन करणा-या मध्ये दंड म्हणजे दमन करण्याची शक्ती मी आहे. विजयाची इच्छा करणा-याची नीती मी आहे. गुप्त ठेवण्यासारख्या भावांचा रक्षक मौन आणि ज्ञानवानांचे तत्वज्ञान मीच आहे. 10.38",

        "आणि हे अर्जुना! जे सर्व भूतांच्या उत्पत्तीचे कारण तेही मीच आहे. कारण असे चराचरातील एकही भूत नाही की, जे माझ्याशिवाय असेल. 10.39",

        "हे परंतपा! माझ्या दिव्य विभूतींचा अंत नाही. हा विस्तार तर तुझ्यासाठी थोडक्यात सांगितला. 10.40",

        "जी जी ऐश्वर्ययुक्त, कांतियुक्त आणि शक्तियुक्त वस्तु आहे, ती ती तू माझ्या तेजाच्या अंशाचीच अभिव्यक्ती समज. 10.41",

        "किंवा हे अर्जुना! हे फार फार जाणण्याचे तुला काय प्रयोजन आहे. मी या संपूर्ण जगाला आपल्या योगशक्तीच्या केवळ एका अंशाने धारण करुन राहीलो आहे. 10.42",

        "इस प्रकार ॐ तत्  सत् - इन भगवन्नामों के उच्चारणपूर्वक ब्रह्मविद्या और योगशास्त्रमय श्रीमद्भगवद्गीतोपनिषदरूप श्रीकृष्णार्जुनसंवाद में ‘विभूतियोग’ नामक दसवाँ अध्याय पूर्ण हुआ।"
      ],
      "adhyaya-11" : [
        "अर्जुन म्हणाला, माझ्यावर कृपा करण्यासाठी आपण जो अत्यंत गुप्त अध्यात्मविषयक उपदेश मला केला, त्याने माझे हे अज्ञान नाहीसे झाले. 11.1",

        "कारण हे कमलदलनयना! मी आपल्याकडून प्राण्यांची उत्पत्ती आणि प्रलय विस्तारपूर्वक ऐकले आहेत. तसेच आपला अविनाशी प्रभावही ऐकला आहे. 11.2",

        "हे परमेश्वरा! आपण आपल्याविषयी जसे सांगत आहात, ते बरोबर तसेच आहे. हे पुरुषोत्तमा! आपले ज्ञान, ऐश्वर्य, शक्ती, बल, वीर्य आणि तेजयुक्त ईश्वरी स्वरूप मला प्रत्यक्ष पाहण्याची इच्छा आहे. 11.3",

        "हे प्रभो! जर मला आपले ते रूप पाहता येईल, असे आपल्याला वाटत असेल, तर हे योगेश्वरा! त्या अविनाशी स्वरूपाचे मला दर्शन घडवा. 11.4",

        "श्रीभगवान म्हणाले, हे पार्था आता तू माझी शेकडो-हजारो नाना प्रकारची, नाना रंगांची आणि नाना आकारांची अलौकिक रूपे पहा. 11.5",

        "हे भरतवंशी अर्जुना! माझ्यामध्ये अदितीच्या बारा पुत्रांना, आठ वसूंना, अकरा रुद्रांना, दोन्ही अश्विनीकुमारांना आणि एकोणपन्नास मरुद्गणांना पहा. तसेच आणखीही पुष्कळशी यापूर्वी न पाहिलेली आश्चर्यकारक रूपे पहा. 11.6",

        "हे अर्जुना! आता या माझ्या शरीरात एकत्रित असलेले चराचरासह संपूर्ण जग पहा. तसेच इतरही जे काही तुला पाहण्याची इच्छा असेल, ते पाहा. 11.7",

        "परन्तु मला तू या तुझ्या चर्मचक्षूंनी खात्रीने पाहू शकणार नाहीस, म्हणून मी तुला दिव्य दृष्टी देतो. तिच्या साह्याने तू माझी ईश्वरी योगशक्ती पहा. 11.8",

        "संजय म्हणाला, हे महाराज! महायोगेश्वर आणि सर्व पापांचा नाश करणाऱ्या भगवंतांनी असे सांगून मग अर्जुनाला परम ऐश्वर्ययुक्त दिव्य स्वरूप दाखवले. 11.9",

        "अनेक तोंडे व डोळे असलेल्या, अनेक आश्चर्यकारक दर्शने असलेल्या, पुष्कळशा दिव्य अलंकारांनी विभूषित आणि पुष्कळशी दिव्य शस्त्रे हातात घेतलेल्या, 11.10",

        "दिव्य माळा आणि वस्त्रे धारण केलेल्या, तसेच दिव्य गन्धाने विभूषित, सर्व प्रकारच्या आश्चर्यांनी युक्त, अनन्तस्वरूप, सर्व बाजूंनी तोंडे असलेल्या विराटस्वरूप परमदेव परमेश्वराला अर्जुनाने पाहिले. 11.11",

        "आकाशात हजार सूर्य एकदम उगवले असता जो प्रकाश पडेल, तोही त्या विश्वरूप परमात्म्याच्या प्रकाशाइतका कदाचितच होईल म्हणजेच होणार नाही. 11.12",

        "पांडुपुत्र अर्जुनाने त्यावेळी अनेक प्रकारांत विभागलेले संपूर्ण जग देवाधिदेव भगवान श्रीकृष्णांच्या त्या शरीरात एकत्रित असलेले पाहिले. 11.13",

        "त्यानंतर तो आश्चर्यचकित झालेला व अंगावर रोमांच उभे राहिलेला अर्जुन, प्रकाशमय विश्वरूप परमात्म्याला श्रद्धाभक्तीसह मस्तकाने प्रणाम करून हात जोडून म्हणाला. 11.14",

        "अर्जुन म्हणाला, हे देवा! मी आपल्या दिव्य देहात संपूर्ण देवांना तसेच अनेक भूतांच्या समुदायांना, कमळाच्या आसनावर विराजमान झालेल्या ब्रह्मदेवाला, शंकराला, सर्व ऋषींना तसेच दिव्य सर्पांना पाहात आहे. 11.15",

        "हे संपूर्ण विश्वाचे स्वामी! मी आपल्याला अनेक बाहू, पोटे, तोंडे आणि डोळे असलेले तसेच सर्व बाजूंनी अनन्त रूपे असलेले पाहात आहे. हे विश्वरूपा! मला आपला ना अंत दिसत, ना मध्य दिसत, ना आरम्भ. 11.16",

        "मी आपल्याला मुकुट घातलेले, गदा व चक्र धारण केलेले, सर्व बाजूंनी प्रकाशमान तेजाचा समूह असे, प्रज्वलित अग्नी व सूर्य यांच्याप्रमाणे तेजाने युक्त, पाहण्यास अतिशय कठीण आणि सर्व दृष्टींनी अमर्याद असे पाहात आहे. 11.17",

        "आपणच जाणण्याजोगे परब्रह्म परमात्मा आहात. आपणच या जगाचे परम आधार आहात. आपणच अनादी धर्माचे रक्षक आहात आणि आपणच अविनाशी सनातन पुरुष आहात, असे मला वाटते. 11.18",

        "अनन्त बाहू असलेले, चंद्र व सूर्य हे ज्यांचे नेत्र आहेत, पेटलेल्या अग्नीसारखे ज्यांचे मुख आहे आणि आपल्या तेजाने या जगाला तापविणारे, असे आहात, असे मला दिसते. 11.19",

        "हे महात्मन्! हे स्वर्ग आणि पृथ्वी यांच्यामधील आकाश आणि सर्व दिशा फक्त आपण एकट्यानेच व्यापून टाकल्या आहेत. आपले हे अलौकिक आणि भयंकर रूप पाहून तिन्ही लोक अत्यंत भयभीत झाले आहेत. 11.20",

        "तेच देवांचे समुदाय आपल्यात शिरत आहेत आणि काही भयभीत होऊन हात जोडून आपल्या नावांचे व गुणांचे वर्णन करीत आहेत. तसेच महर्षी व सिद्धांचे समुदाय 'सर्वांचे कल्याण होवो' अशी मंगलाशा करून उत्तमोत्तम स्तोत्रे म्हणून आपली स्तुती करीत आहेत. 11.21",

        "अकरा रुद्र, बारा आदित्य तसेच आठ वसू, साध्यगण, विश्वेदेव, दोन अश्विनीकुमार, मरुद्गण आणि पितरांचे समुदाय, तसेच गंधर्व, यक्ष, राक्षस आणि सिद्धांचे समुदाय आहेत. ते सर्वच चकित होऊन आपल्याकडे पाहात आहेत. 11.22",

        "हे महाबाहो! आपले अनेक तोंडे, अनेक डोळे, अनेक हात, मांड्या व पाय असलेले, अनेक पोटांचे आणि अनेक दाढांमुळे अतिशय भयंकर असे महान रूप पाहून सर्व लोक व्याकुळ ह होत आहे. आहेत. तसेच मीही व्याकुळ होत आहे. 11.23",

        "कारण हे विष्णो! आकाशाला जाऊन भिडलेल्या, तेजस्वी, अनेक रंगांनी युक्त, पसरलेली तोंडे व तेजस्वी विशाल डोळे यांनी युक्त अशा आपल्याला पाहून भयभीत अंत:करण झालेल्या माझे धैर्य व शान्ती नाहीशी झाली आहे. 11.24",

        "दाढांमुळे भयानक व प्रलयकाळच्या अग्नीसारखी प्रज्वलित आपली तोंडे पाहून मला दिशा कळेनाशा झाल्या असून माझे सुखही हरपले आहे. म्हणून हे देवाधिदेवा! हे जगन्निवासा! आपण प्रसन्न व्हा. 11.25",

        "ते सर्व धृतराष्ट्राचे पुत्र राजसमुदायासह आपल्यात प्रवेश करीत आहेत आणि पितामह भीष्म, द्रोणाचार्य, तसेच तो कर्ण आणि आमच्या बाजूच्याही प्रमुख योद्ध्यांसह सगळेच आपल्या दाढांमुळे भयंकर दिसणाऱ्या तोंडात मोठ्या वेगाने धावत धावत घुसत आहेत आणि कित्येक डोकी चिरडलेले आपल्या दातांच्या फटीत अडकलेले दिसत आहेत. (11.26-11.27)",

        "ते सर्व धृतराष्ट्राचे पुत्र राजसमुदायासह आपल्यात प्रवेश करीत आहेत आणि पितामह भीष्म, द्रोणाचार्य, तसेच तो कर्ण आणि आमच्या बाजूच्याही प्रमुख योद्ध्यांसह सगळेच आपल्या दाढांमुळे भयंकर दिसणाऱ्या तोंडात मोठ्या वेगाने धावत धावत घुसत आहेत आणि कित्येक डोकी चिरडलेले आपल्या दातांच्या फटीत अडकलेले दिसत आहेत. (11.26-11.27)",

        "ज्याप्रमाणे नद्यांचे पुष्कळसे जलप्रवाह स्वाभाविकच समुद्राच्याच दिशेने धाव घेतात अर्थात समुद्रात प्रवेश करतात त्याचप्रमाणे ते मनुष्यलोकातील वीर आपल्या धडाडून पेटलेल्या तोंडात शिरत आहेत. 11.28",

        "जसे पतंग मोहाने मरून जाण्यासाठी पेटलेल्या अग्नीत अतिशय वेगाने धावत शिरतात, तसेच हे सर्व लोकही स्वतःच्या नाशासाठी आपल्या तोंडात अतिशय वेगाने धावत प्रवेश करीत आहेत. 11.29",

        "आपण त्या सर्व लोकांना प्रज्वलित तोंडांनी गिळत गिळत सर्व बाजूंनी वारंवार चाटत आहात. हे विष्णो! आपला प्रखर प्रकाश सर्व जगाला तेजाने पूर्ण भरून तापवीत आहे. 11.30",

        "मला सांगा की भयंकर रूप धारण करणारे आपण कोण आहात? हे देवश्रेष्ठा! आपणाला नमस्कार असो. आपण प्रसन्न व्हा. आदिपुरुष अशा आपल्याला मी विशेष रीतीने जाणू इच्छितो. कारण आपली ही करणी मला कळत नाही. 11.31",

        "श्रीभगवान म्हणाले, मी लोकांचा नाश करणारा वाढलेला महाकाल आहे. यावेळी या लोकांच्या नाशासाठी मी प्रवृत्त झालो आहे. म्हणून शत्रूपक्षीय सैन्यात जे योद्धे आहेत, ते सर्व तुझ्याशिवायही राहणार नाहीत. म्हणजेच तू युद्ध केले नाहीस, तरी या सर्वांचा नाश होणार. 11.32",

        "म्हणूनच तू ऊठ. यश मिळव. शत्रूंना जिंकून धनधान्यसंपन्न राज्याचा उपभोग घे. हे सर्व शूरवीर आधीच माझ्याकडून मारले गेले आहेत. हे सव्यसाची! तू फक्त निमित्तमात्रच हो. 11.33",

        "द्रोणाचार्य आणि पितामह भीष्म तसेच जयद्रथ आणि कर्ण त्याचप्रमाणे माझ्याकडून मारल्या गेलेल्या इतरही पुष्कळ शूर योद्ध्यांना तू मार. भिऊ नकोस. युद्धात खात्रीने तू शत्रूना जिंकशील. म्हणून युद्ध कर. 11.34",

        "संजय म्हणाला, भगवान केशवांचे हे बोलणे ऐकून किरीटी अर्जुनाने हात जोडून कापत कापत नमस्कार केला आणि फिरूनही अत्यन्त भयभीत होऊन प्रणाम करून भगवान श्रीकृष्णांना सद्गदित होऊन तो म्हणाला. 11.35",

        "अर्जुन म्हणाला, हे अन्तर्यामी! आपले नाव, गुण आणि प्रभाव यांच्या वर्णनाने जग अतिशय आनंदित होते व तुमच्यावर अतिशय प्रेम करू लागते. तसेच भ्यालेले राक्षस दशदिशांत पळून जात आहेत आणि सर्व सिद्धगणांचे समुदाय आपल्याला नमस्कार करीत आहेत, हे योग्यच होय. 11.36",

        "हे महात्मन! ब्रह्मदेवाचेही आदिकारण आणि सर्वांत श्रेष्ठ अशा आपल्याला हे नमस्कार का बरे करणार नाहीत? कारण हे अनन्ता! हे देवाधिदेवा! हे जगन्निवासा! जे सत्, असत् व त्यापलीकडील अक्षर अर्थात सच्चिदानन्दघन ब्रह्म आहे, ते आपणच आहात. 11.37",

        "आपण आदीदेव आणि सकाम पुरुष आहात. आपण या जगाचे परम आश्रयस्थान आहात. जग जाणणारेही आपणच व जाणण्याजोगेही आपणच. परमधामही आपणच आहात. हे अनंतरुप! आपण हे सर्व विश्व व्यापले आहे. 11.38",

        "आपण वायू, यमराज, अग्नी, वरुण, चंद्र, प्रजेचे स्वामी ब्रह्मदेव आणि ब्रह्मदेवाचेही जनक आहात. आपल्याला हजारो वेळा नमस्कार. नमस्कार असो! आपणांला आणखीही वारंवार नमस्कार! नमस्कार असोत! 11.39",

        "हे अनन्त सामर्थ्यशाली! आपल्याला पुढून व मागूनही नमस्कार! हे सर्वात्मका! आपल्याला सर्व बाजूंनीच नमस्कार असो. कारण अनंत पराक्रमशाली अशा आपण सर्व जग व्यापले आहे. म्हणून आपण सर्वरूप आहात. 11.40",

        "आपला हा प्रभाव न जाणल्यामुळे, आपण माझे मित्र आहात असे मानून प्रेमाने किंवा चुकीने मी 'हे कृष्णा! हे यादवा! हे सख्या!' असे जे काही विचार न करता मुद्दाम म्हटले असेल, 11.41",

        "आणि हे अच्युता! माझ्याकडून विनोदासाठी फिरताना, झोपताना, बसल्यावेळी आणि भोजन इत्यादी करताना आपला एकान्तात किंवा त्या मित्रांच्या समक्ष जो अपमान झाला असेल, त्या सर्व अपराधांची अचिन्त्य प्रभावशाली अशा आपणांकडे मी क्षमा मागत आहे. 11.42",

        "आपण या चराचर जगताचे जनक आहात. तसेच सर्वश्रेष्ठ गुरू व अत्यंत पूजनीय आहात. हे अतुलनीयप्रभावा! त्रैलोक्यात आपल्या बरोबरीचाही दुसरा कोणी नाही. मग आपल्याहून श्रेष्ठ कसा असू शकेल? 11.43",

        "म्हणूनच हे प्रभो! मी आपल्या चरणांवर शरीराने लोटांगण घालून नमस्कार करून स्तुत्य अशा आपण ईश्वराने प्रसन्न व्हावे, म्हणून प्रार्थना करीत आहे. हे देवा! वडील जसे पुत्राचे, मित्र जसे मित्राचे आणि पती जसे आपल्या प्रियतम पत्नीचे अपराध सहन करतात, तसेच आपणही माझे अपराध सहन करण्यास योग्य आहात. 11.44",

        "पूर्वी न पाहिलेले आपले हे आश्चर्यकारक रूप पाहून मी आनंदित झालो आहे आणि माझे मन भीतीने अतिशय व्याकूळही होत आहे. म्हणून आपण मला ते चतुर्भुज विष्णूरूपच दाखवा. हे देवेशा! हे जगन्निवासा! प्रसन्न व्हा. 11.45",

        "मी पहिल्यासारखेच आपणाला मुकुट धारण केलेले तसेच गदा आणि चक्र हातात घेतलेले पाहू इच्छितो. म्हणून हे विश्वस्वरूपा! हे सहस्रबाहो! आपण त्याच चतुर्भुज रूपाने प्रकट व्हा. 11.46",

        "श्रीभगवान म्हणाले, हे अर्जुना! मी तुझ्यावर अनुग्रह करण्यासाठी आपल्या योगशक्तीच्या प्रभावाने हे माझे परम तेजोमय, सर्वांचे आदी, सीमा नसलेले विराट रूप तुला दाखवले. ते तुझ्याशिवाय दुसऱ्या कोणीही यापूर्वी पाहिले नव्हते. 11.47",

        "हे अर्जुना! मानवलोकात अशा प्रकारचा विश्वरूपधारी मी वेदांच्या आणि यज्ञांच्या अध्ययनाने, दानाने, वैदिक कर्मांनी आणि उग्र तपश्चर्यांनीही तुझ्याखेरीज दुसऱ्याकडून पाहिला जाणे शक्य नाही. 11.48",

        "माझे या प्रकारचे हे भयंकर रूप पाहून तू भयभीत होऊ नकोस किंवा गोंधळून जाऊ नकोस. तू भीती सोडून प्रीतियुक्त अंत:करणाने तेच माझे हे शंख-चक्र-गदा-पद्म धारण केलेले चतुर्भुज रूप पुन्हा पहा. 11.49",

        "संजय म्हणाला, भगवान वासुदेवांनी अर्जुनाला असे सांगून पुन्हा तसलेच आपले चतुर्भुज रूप दाखवले आणि पुन्हा महात्म्या श्रीकृष्णांनी सौम्य रूप धारण करून भयभीत अर्जुनाला धीर दिला. 11.50",

        "अर्जुन म्हणाला, हे जनार्दना! आपले हे अतिशय शांत मनुष्यरूप पाहून आता माझे मन स्थिर झाले असून मी माझ्या मूळ स्थितीला प्राप्त झालो आहे. 11.51",

        "श्रीभगवान म्हणाले, माझे जे चतुर्भुज रूप तू पाहिलेस, ते पाहावयास मिळणे अतिशय दुर्लभ आहे. देवसुद्धा नेहमी या रूपाच्या दर्शनाची इच्छा करीत असतात. 11.52",

        "तू जसे मला पाहिलेस, तशा माझ्या चतुर्भुज रूपाचे दर्शन वेदांनी, तपाने, दानाने आणि यज्ञानेही मिळणे शक्य नाही. 11.53",

        "परंतु हे परंतप अर्जुना! अनन्य भक्तीने या प्रकारच्या चतुर्भुजधारी मला प्रत्यक्ष पाहणे, तत्त्वत: जाणणे तसेच (माझ्यात) प्रवेश करणे अर्थात् (माझ्याशी ) एकरूप होणेही शक्य आहे. 11.54",

        "हे अर्जुना! जो पुरुष केवळ माझ्याचसाठी सर्व कर्तव्यकर्मे करणारा, मलाच परम आश्रय मानणारा, माझा भक्त आसक्तिरहित असतो आणि सर्व प्राणिमात्राविषयी वैरभाव बाळगत नाही, तो अनन्य भक्त मलाच प्राप्त होतो. 11.55",

        "इस प्रकार ॐ तत्  सत् - इन भगवन्नामों के उच्चारणपूर्वक ब्रह्मविद्या और योगशास्त्रमय श्रीमद्भगवद्गीतोपनिषदरूप श्रीकृष्णार्जुनसंवाद में ‘विश्वरूपदर्शनयोग’ नामक ग्यारहवाँ अध्याय पूर्ण हुआ।"    
      ],
      "adhyaya-12" : [
        "अर्जुन म्हणाला, जे अनन्यप्रेमी भक्तजन पूर्वी सांगितल्याप्रमाणे निरंतर आपल्या भजन - ध्यानात मग्न राहून आपणा सगुणरूप परमेश्वराची आणि दुसरे जे केवळ अविनाशी सच्चिदानंदघन निराकार ब्रह्माचीच अतिश्रेष्ठ भावाने उपासना करतात, त्या दोन्ही प्रकारच्या भक्तांमध्ये अतिशय उत्तम योगवेत्ते कोण होत? 12.1",

        "श्रीभगवान म्हणाले, माझ्या ठिकाणी मन एकाग्र करून निरंतर माझ्या भजन-ध्यानांत रत झालेले जे भक्तजन अतिशय श्रेष्ठ श्रध्देने युक्त होऊन मज सगुणरूप परमेश्वराला भजतात, ते मला योग्यांमधील अति-उत्तम योगी वाटतात. 12.2",

        "परन्तु जे पुरुष इन्द्रियसमूहाला चांगल्या प्रकारे ताब्यात ठेवून मन-बुद्धिच्या पलीकडे असणाऱ्या, सर्वव्यापी, अवर्णनीय, स्वरूप आणि नेहमी एकरूप असणाऱ्या नित्य, अचल, निराकार, अविनाशी सच्चिदानंदघन, ब्रह्माची निरंतर ऐक्यभावनेने ध्यान करीत उपासना करतात, ते सर्व प्राणिमांत्राच्या कल्याणात तत्पर आणि सर्वांच्या ठिकाणी समान भाव ठेवणारे योगी मलाच येऊन मिळतात. (12.3-12.4)",

        "परन्तु जे पुरुष इन्द्रियसमूहाला चांगल्या प्रकारे ताब्यात ठेवून मन-बुद्धिच्या पलीकडे असणाऱ्या, सर्वव्यापी, अवर्णनीय, स्वरूप आणि नेहमी एकरूप असणाऱ्या नित्य, अचल, निराकार, अविनाशी सच्चिदानंदघन, ब्रह्माची निरंतर ऐक्यभावनेने ध्यान करीत उपासना करतात, ते सर्व प्राणिमांत्राच्या कल्याणात तत्पर आणि सर्वांच्या ठिकाणी समान भाव ठेवणारे योगी मलाच येऊन मिळतात. (12.3-12.4)",

        "सच्चिदानंदघन निराकार ब्रह्मांत चित्त गुंतलेल्या त्या पुरुषांच्या साधनांत कष्ट जास्त आहेत. कारण देहाचा अभिमान असणाऱ्यांकडून अव्यक्त ब्रह्माची प्राप्ती कष्टानेच होत असते. 12.5",

        "परंतु जे मत्परायण सर्व कर्मे माझ्या ठिकाणी अर्पण करून मज सगुणरूप परमेश्वराची अनन्य भक्तियोगाने निरन्तर चिन्तन करीत उपासना करतात, 12.6",

        "हे अर्जुना! त्या माझ्यात चित्त गुंतवलेल्या प्रेमी भक्तांचा मी तत्काळ मृत्युरूप संसारसागरातून उद्धार करणारा होतो. 12.7",

        "माझ्यातच मन ठेव. माझ्या ठिकाणीच बुद्धि स्थापन कर. म्हणजे मग तू माझ्यातच राहशील, यात मुळीच संशय नाही. 12.8",

        "जर तू माझ्यात मन निश्चल ठेवायला समर्थ नसशील, तर हे अर्जुना! अभ्यासरूप योगाने मला प्राप्त होण्याची इच्छा कर. 12.9",

        "जर तू वर सांगितलेल्या अभ्यासालाही असमर्थ असशील, तर केवळ माझ्याकरता कर्म करायला परायण हो. अशा रीतीने माझ्यासाठी कर्मे केल्यानेही 'माझी प्राप्ती होणे' ही सिद्धी तू मिळवशील. 12.10",

        "जर माझ्या प्राप्तिरूप योगाचा आश्रय करून वर सांगितलेले साधन करायलाही तू असमर्थ असशील, तर मन, बुद्धि इत्यादींवर विजय मिळवणारा होउन सर्व कर्मांच्या फळांचा त्याग कर. 12.11",

        "मर्म न जाणता केलेल्या अभ्यासापेक्षा ज्ञान श्रेष्ठ होय. ज्ञानापेक्षा मज परमेश्वराच्या स्वरूपाचे ध्यान श्रेष्ठ आहे आणि ध्यानापेक्षाही सर्व कर्मांच्या फळांचा त्याग श्रेष्ठ आहे. कारण त्यागाने ताबडतोबच परम शान्ती मिळते. 12.12",

        "जो कोणत्याही प्राण्याचा द्वेष न करणारा, स्वार्थरहित, सर्वांवर प्रेम करणारा व अकारण दया करणारा, माझेपणा व मीपणा नसलेला, सुखात व दुःखात समभाव असलेला आणि क्षमावान म्हणजे अपराध करणार्‍यालाही अभय देणारा असतो; तसेच जो योगी नेहमी संतुष्ट असतो, ज्याने शरीर, मन व इंद्रिये ताब्यात ठेवलेली असतात, ज्याची माझ्यावर दृढ श्रद्धा असते, तो मन व बुद्धि मलाच अर्पण केलेला माझा भक्त मला प्रिय आहे. (12.13-12.14)",

        "जो कोणत्याही प्राण्याचा द्वेष न करणारा, स्वार्थरहित, सर्वांवर प्रेम करणारा व अकारण दया करणारा, माझेपणा व मीपणा नसलेला, सुखात व दुःखात समभाव असलेला आणि क्षमावान म्हणजे अपराध करणार्‍यालाही अभय देणारा असतो; तसेच जो योगी नेहमी संतुष्ट असतो, ज्याने शरीर, मन व इंद्रिये ताब्यात ठेवलेली असतात, ज्याची माझ्यावर दृढ श्रद्धा असते, तो मन व बुद्धि मलाच अर्पण केलेला माझा भक्त मला प्रिय आहे. (12.13-12.14)",

        "ज्याच्यापासून कोणत्याही प्राण्याला त्रास होत नाही तसेच ज्याला कोणत्याही प्राण्याचा उबग येत नाही, जो हर्ष, मत्सर, भीती आणि उद्वेग इत्यादींपासून अलिप्त असतो, तो भक्त मला प्रिय आहे. 12.15",

        "ज्याला कशाची अपेक्षा नाही, जो अंतर्बाह्य शुद्ध, चतुर, तटस्थ आणि दुःखमुक्त आहे, असा कर्तृत्वाचा अभिमान न बाळगणारा माझा भक्त मला प्रिय आहे. 12.16",

        "जो कधी हर्षयुक्त होत नाही, द्वेष करीत नाही, शोक करीत नाही, इच्छा करीत नाही, तसेच जो शुभ आणि अशुभ सर्व कर्मांचा त्याग करणारा आहे, तो भक्तियुक्त पुरुष मला प्रिय आहे. 12.17",

        "जो शत्रु-मित्र आणि मान-अपमान यांविषयी समभाव बाळगतो, तसेच थंडी-ऊन, सुख-दुःख इत्यादी द्वंद्वात ज्याची वृत्ती सारखीच राहते, ज्याला आसक्ती नसते, 12.18",

        "ज्याला निन्दा-स्तुती सारखीच वाटते, जो ईशस्वरूपाचे मनन करणारा असतो, जो काही मिळेल त्यानेच शरीरनिर्वाह होण्याने नेहमी समाधानी असतो, निवासस्थानाविषयी ज्याला ममता किवा आसक्ती नसते, तो स्थिर बुद्धि असणारा भक्तिमान पुरुष मला प्रिय असतो. 12.19",

        "परंतु जे श्रद्धाळू पुरुष मत्परायण होउन या वर सांगितलेल्या धर्ममय अमृताचे निष्काम प्रेमभावनेने सेवन करतात, ते भक्त मला अतिशय प्रिय आहेत. 12.20",

        "इस प्रकार ॐ तत्  सत् - इन भगवन्नामों के उच्चारणपूर्वक ब्रह्मविद्या और योगशास्त्रमय श्रीमद्भगवद्गीतोपनिषदरूप श्रीकृष्णार्जुनसंवाद में ‘भक्तियोग’ नामक बारहवाँ अध्याय पूर्ण हुआ।"
      ],
      "adhyaya-13" : [
        "श्री भगवान म्हणाले, हे अर्जुना! हे शरीर क्षेत्र या नावाने संबोधले जाते आणि याला जो जाणतो त्याला, त्याचे तत्त्व जाणणारे ज्ञानी लोक 'क्षेत्रज्ञ' असे म्हणतात. 13.1",

        "हे अर्जुना! तू सर्व क्षेत्रांमध्ये क्षेत्रज्ञ अर्थात जीवात्माही मलाच समज. आणि क्षेत्र व क्षेत्रज्ञ अर्थात विकारांसहित प्रकृती व पुरुष यांना जे तत्त्वत: जाणणे ते ज्ञान आहे, असे माझे मत आहे. 13.2",

        "ते क्षेत्र जे आणि जसे आहे, तसेच ज्या विकारांनी युक्त आहे आणि ज्या कारणांपासून जे झाले आहे, तसेच तो क्षेत्रज्ञही जो आणि ज्या प्रभावाने युक्त आहे, ते सर्व थोडक्यात माझ्याकडून ऐक. 13.3",

        "हे क्षेत्र आणि क्षेत्रज्ञाचे तत्त्व ऋषींनी पुष्कळ प्रकारांनी सांगितले आहे. आणि निरनिराळ्या वेदमंत्रांतूनही विभागपूर्वक सांगितले गेले आहे. तसेच पूर्णपणे निश्चय केलेल्या युक्तियुक्त ब्रह्मसूत्राच्या पदांनीही सांगितले आहे. 13.4",

        "पाच महाभूते, अहंकार, बुद्धी आणि मूळ प्रकृती तसेच दहा इंद्रिये एक मन आणि पाच इंद्रियांचे विषय अर्थात शब्द, स्पर्श, रूप, रस आणि गंध. 13.5",

        "तसेच इच्छा, द्वेष, सुख, दुःख, स्थूल देहाचा पिंड, चेतना आणि धृत अशा प्रकारे विकारांसहित हे क्षेत्र थोडक्यात सांगितले गेले आहे. 13.6",

        "मोठेपणाचा अभिमान नसणे, ढोंग न करणे, कोणत्याही प्राण्याला कोणत्याही प्रकारे त्रास न देणे, क्षमा करणे, मन वाणी इत्यादींबाबतीत सरळपणा, श्रद्धा-भक्तीसह गुरूंची सेवा, अंतर्बाह्य शुद्धी, अंतःकरणाची स्थिरता आणि मन व इंद्रियांसह शरीराचा निग्रह. 13.7",

        "इह-परलोकांतील सर्व विषयांच्या उपभोगाविषयी आसक्ती नसणे आणि अहंकारही नसणे, जन्म, मृत्यू, वृद्धत्व आणि रोग इत्यादींमध्ये दुःख व दोषांचा वारंवार विचार करणे. 13.8",

        "पुत्र, स्त्री, घर आणि धन इत्यादींची आसक्ती नसणे व ममता नसणे तसेच आवडती आणि नावडती गोष्ट घडली असता नेहमीच चित्त समतोल ठेवणे. 13.9",

        "मज परमेश्वरामध्ये अनन्य योगाने अव्यभिचारिणी भक्ती, तसेच एकान्तात शुद्ध ठिकाणी राहण्याचा स्वभाव आणि विषयासक्त मनुष्यांच्या सहवासाची आवड नसणे. 13.10",

        "अध्यात्मज्ञानात नित्य स्थिती आणि तत्त्वज्ञानाचा अर्थ जो परमात्मा त्यालाच पाहणे हे सर्व ज्ञान होय आणि याउलट जे असेल, ते अज्ञान होय, असे म्हटले आहे. 13.11",

        "जे जाणण्याजोगे आहे आणि जे जाणल्यामुळे मनुष्याला परम आनंद मिळतो, ते चांगल्या प्रकारे सांगतो. ते अनादी परम ब्रह्म 'सत्' ही म्हणता येत नाही आणि 'असत्' ही म्हणता येत नाही. 13.12",

        " ते सर्व बाजूंनी हात-पाय असलेले सर्व बाजूंनी डोळे, डोकी व तोंडे असलेले, तसेच सर्व बाजूंनी कान असलेले आहे. कारण ते विश्वात सर्वाला व्यापून राहिले आहे. 13.13",

        "ते सर्व इन्द्रियांच्या विषयांना जाणणारे आहे. परंतु वास्तविक सर्व इंद्रियांनी रहित आहे. ते आसक्तिरहित असूनही सर्वांचे धारण-पोषण करणारे आणि निर्गुण असूनही गुणांचा भोग घेणारे आहे. 13.14",

        "ते चराचर सर्व प्राणिमात्रांच्या बाहेर व आत परिपूर्ण भरले आहे. तसेच चर आणि अचरही तेच आहे आणि ते सूक्ष्म असल्यामुळे कळण्याजोगे नाही. तसेच अतिशय जवळ  आणि दूरही असलेले तेच आहे. 13.15",

        "तो परमात्मा विभागरहित एकरूप असा आकाशासारखा परिपूर्ण असूनही चराचर संपूर्ण भूतांमध्ये वेगवेगळा असल्यासारखा भासत आहे. तसाच तो जाणण्याजोगा परमात्मा विष्णुरूपाने भूतांचे धारण-पोषण करणारा, रुद्ररूपाने संहार करणारा आणि ब्रह्मदेवरूपाने सर्वांना उत्पन्न करणारा आहे. 13.16",

        "ते परब्रह्म ज्योतींची ज्योत आणि मायेच्या अत्यंत पलीकडचे म्हटले जाते. तो परमात्मा ज्ञानस्वरूप, जाणण्यास योग्य आणि तत्त्वज्ञानाने प्राप्त होण्याजोगा आहे. तसेच सर्वांच्या हृदयांत विशेषरूपाने राहिलेला आहे. 13.17",

        "अशा प्रकारे क्षेत्र तसेच ज्ञान आणि जाणण्याजोगे परमात्म्याचे स्वरूप थोडक्यात सांगितले. माझा भक्त हे तत्त्वत: जाणून माझ्या स्वरूपाला प्राप्त होतो. 13.18",

        "प्रकृती आणि पुरुष हे दोन्हीही अनादि आहेत, असे तू समज आणि जसे महाकाश विभागरहित असूनही घड्यांत वेगवेगळे असल्यासारखे भासते, तसाच परमात्मा सर्व भूतात एकरूप असूनही वेगवेगळा असल्यासारखा भासतो. 13.19",

        "कार्य व करण यांच्या उत्पत्तीचे कारण प्रकृती म्हटली जाते आणि जीवात्मा सुखदुःखांच्या भोगण्याला कारण म्हटला जातो. 13.20",

        "प्रकृतीत राहिलेलाच पुरुष प्रकृतीपासून उत्पन्न झालेल्या त्रिगुणात्मक पदार्थांना भोगतो आणि या गुणांची संगतीच या जीवात्म्याला बऱ्या-वाईट योनीत जन्म मिळण्याला कारण आहे 13.21",

        "या देहात असलेला आत्मा वास्तविक परमात्माच आहे. तोच साक्षी असल्यामुळे उपद्रष्टा आणि खरी संमती देणारा असल्याने अनुमन्ता, सर्वांचे धारण-पोषण करणारा म्हणून भर्ता, जीवरूपाने भोक्ता, ब्रह्मदेव इत्यादींचाही स्वामी असल्याने महेश्वर आणि शुद्ध सच्चिदानन्दघन असल्यामुळे परमात्मा म्हटला जातो. 13.22",

        "अशा रीतीने पुरुषाला आणि गुणांसहित प्रकृतीला जो मनुष्य तत्त्वतः जाणतो, तो सर्व प्रकारे कर्तव्य कर्मे करीत असला, तरी पुन्हा जन्माला येत नाही. 13.23",

        "त्या परमात्म्याला काहीजण शुद्ध झालेल्या सूक्ष्म बुद्धीने ध्यानाच्या योगाने हृदयात पाहतात. दुसरे काहीजण ज्ञानयोगाच्या द्वारा आणि इतर कितीतरी लोक कर्मयोगाच्या द्वारा पाहतात म्हणजेच प्राप्त करतात. 13.24",

        "परंतु यांखेरीज इतर अर्थात मंदबुद्धीचे पुरुष आहेत, ते अशाप्रकारे न जाणणारे असतात. ते दुसऱ्यांकडून म्हणजेच तत्त्वज्ञानी पुरुषांकडून ऐकूनच तदनुसार उपासना करतात आणि ते ऐकलेले प्रमाण मानणारे पुरुषसुद्धा मृत्युरूप संसारसागर खात्रीने तरून जातात. 13.25",

        "हे अर्जुना! जेवढे म्हणून स्थावर जंगम प्राणी उत्पन्न होतात, ते सर्व क्षेत्र आणि क्षेत्रज्ञाच्या संयोगानेच उत्पन्न होतात, असे समज. 13.26",

        "जो पुरुष नाशिवंत सर्व चराचर भूतांत परमेश्वर हा अविनाशी व सर्वत्र समभावाने स्थित असलेला पाहतो, तोच खरे पाहतो. 13.27",

        "कारण जो पुरुष सर्वांमध्ये समरूपाने असलेल्या परमेश्वराला समान पाहून आपणच आपला नाश करून घेत नाही, त्यामुळे तो परम गतीला जातो. 13.28",

        "आणि जो पुरुष सर्व कर्मे सर्व प्रकारे प्रकृतीकडून केली जाणारी आहेत, असे पाहतो आणि आत्मा अकर्ता आहे, असे पाहतो, तोच खरा पाहतो. 13.29",

        "ज्या क्षणी हा पुरुष भूतांचे निरनिराळे भाव एका परमात्म्यातच असलेले आणि त्या परमात्म्यापासूनच सर्व भूतांचा विस्तार आहे, असे पाहातो, त्याच क्षणी तो सच्चिदानन्दघन ब्रह्माला प्राप्त होतो. 13.30",

        "हे अर्जुना! हा अविनाशी परमात्मा अनादी आणि निर्गुण असल्यामुळे शरीरात राहात असूनही वास्तविक तो काही करीत नाही आणि लिप्त होत नाही. 13.31",

        "ज्या प्रमाणे सर्वत्र व्यापलेले आकाश सुक्ष्म असल्याकारणाने लिप्त होत नाही त्याचप्रमाणे देहात सर्वत्र व्यापून असलेला आत्मा निर्गुण असल्यामुळे देहाच्या गुणांनी लिप्त होत नाही. 13.32",

        "हे अर्जुना! ज्याप्रमाणे एकच सूर्य या संपूर्ण ब्रह्मांडाला प्रकाशित करतो, त्याचप्रमाणे एकच आत्मा संपूर्ण क्षेत्राला प्रकाशित करतो. 13.33",

        "अशाप्रकारे क्षेत्र व क्षेत्रज्ञ यांतील भेद तसेच कार्यासह प्रकृतीपासून मुक्त होण्याचा मार्ग ज्ञानदृष्टीने जे पुरुष तत्त्वत: जाणतात, ते महात्मे परम ब्रह्म परमात्म्याला प्राप्त होतात. 13.34",

        "इस प्रकार ॐ तत्  सत् - इन भगवन्नामों के उच्चारणपूर्वक ब्रह्मविद्या और योगशास्त्रमय श्रीमद्भगवद्गीतोपनिषदरूप श्रीकृष्णार्जुनसंवाद में ‘क्षेत्रक्षेत्रज्ञविभागयोग’ नामक तेरहवाँ अध्याय पूर्ण हुआ।"    
      ],
      "adhyaya-14" : [
        "श्रीभगवान म्हणाले, ज्ञानांतीलही अति उत्तम ते परम ज्ञान मी तुला पुन्हा सांगतो की, जे जाणल्याने सर्व मुनीजन या संसारातून मुक्त होऊन परम सिद्धी पावले आहेत. 14.1",

        "हे ज्ञान धारण करून माझ्या स्वरुपाला प्राप्त झालेले पुरुष सृष्टीच्या आरंभी पुन्हा जन्माला येत नाहीत आणि प्रलयकाळीही व्याकुळ होत नाहीत. 14.2",

        "हे अर्जुना! माझी महद्-ब्रह्मरूप मूळ प्रकृती संपूर्ण भूतांची योनी म्हणजे गर्भधारणा करण्याचे स्थान आहे आणि मी त्या योनीच्या ठिकाणी चेतन-समुदायरूप गर्भाची स्थापना करतो. त्या जड-चेतनाच्या संयोगाने सर्व भूतांची उत्पत्ती होते. 14.3",

        "हे अर्जुना! नाना प्रकारच्या सर्व जातींत जितके शरीरधारी प्राणी उत्पन्न होतात, त्या सर्वांचा गर्भ धारण करणारी माता प्रकृती आहे आणि बीज स्थापन करणारा मी पिता आहे. 14.4",

        "हे अर्जुना! सत्त्वगुण, रजोगुण आणि तमोगुण हे प्रकृतीपासून उत्पन्न झालेले तिन्ही गुण अविनाशी जीवात्म्याला शरीरात बांधून ठेवतात. 14.5",

        "हे निष्पापा! त्या तीन गुणांमधील सत्त्वगुण, प्रकाश उत्पन्न करणारा आणि विकाररहित आहे. तो सुखासंबंधी निर्मळ असल्यामुळे व ज्ञानासंबंधीच्या अभिमानाने बांधतो. 14.6",

        "हे अर्जुना! रागरूप रजोगुण इच्छा व आसक्ती यांपासून उत्पन्न झालेला आहे, असे समज. तो या जीवात्म्याला कर्मांच्या आणि त्यांच्या फळांच्या संबंधाने बांधतो. 14.7",

        "हे अर्जुना! सर्व देहाभिमानी पुरुषांना मोह पाडणारा तमोगुण अज्ञानापासून उत्पन्न झालेला आहे, असे समज. तो या जीवात्म्याला प्रमाद, आळस आणि झोप यांनी बांधतो. 14.8",

        "हे अर्जुना! सत्त्वगुण सुखाला, रजोगुण कर्मांना तसेच तमोगुण ज्ञानाला झाकून प्रमाद करण्याला ही प्रवृत्त करतो. 14.9",

        "आणि हे अर्जुना! रजोगुण आणि तमोगुण यांना दडपून सत्त्वगुण वाढतो. सत्त्वगुण व तमोगुणाला दडपून रजोगुण वाढतो. तसेच सत्त्वगुण व रजोगुणाला दडपून तमोगुण वाढतो. 14.10",

        "ज्यावेळी या देहात तसेच अंतःकरणात व इंद्रियांत चैतन्य आणि विवेकशक्ती उत्पन्न होते, त्यावेळी असे समजावे की, सत्त्वगुण वाढला आहे. 14.11",

        "हे अर्जुना! रजोगुण वाढल्यावर लोभ, प्रवृत्ती, स्वार्थाने प्रेरित होऊन फळाच्या इच्छेने कर्मांचा आरंभ, अशांती आणि विषयभोगांची लालसा ही उत्पन्न होतात. 14.12",

        "हे अर्जुना! तमोगुण वाढल्यावर अंतःकरण व इंद्रिये यांत अंधार, कर्तव्यकर्मांत प्रवृत्ती नसणे, व्यर्थ हालचाली आणि झोप इत्यादी अंतःकरणाला मोहित करणार्‍या वृत्ती, ही सर्व उत्पन्न होतात. 14.13",

        "जेव्हा हा मनुष्य सत्त्वगुण वाढलेला असताना मरण पावतो, तेव्हा तो उत्तम कर्मे करणाऱ्यांच्या निर्मळ दिव्य स्वर्गादी लोकांत जातो. 14.14",

        "रजोगुण वाढलेला असता मरण पावल्यास कर्मांची आसक्ती असणार्‍या मनुष्यांत जन्मतो. तसेच तमोगुण वाढला असता मेलेला माणूस किडा, पशू इत्यादी मूढ (विवेकशून्य) जातींत जन्मतो. 14.15",

        "श्रेष्ठ (सात्विक) कर्माचे सात्विक अर्थात सुख, ज्ञान आणि वैराग्य इत्यादी निर्मळ फळ सांगितले आहे. राजस कर्माचे फळ दुःख तसेच तामस कर्माचे फळ अज्ञान सांगितले आहे. 14.16",

        "सत्त्वगुणापासून ज्ञान उत्पन्न होते. रजोगुणापासून निःसंशय लोभ आणि तमोगुणापासून प्रमाद आणि मोह उत्पन्न होतात आणि अज्ञानही होते. 14.17",

        "सत्त्वगुणात असलेले पुरुष स्वर्गादी उच्च लोकांना जातात. रजोगुणात असलेले पुरुष मध्यलोकात म्हणजे मनुष्यलोकातच राहतात आणि तमोगुणाचे कार्य असलेल्या निद्रा, प्रमाद आणि आळस इत्यादीत रत असलेले तामसी पुरुष अधोगतीला अर्थात् कीटक, पशू इत्यादी नीच जातींत तसेच नरकात जातात. 14.18",

        "ज्यावेळी द्रष्टा तिन्ही गुणांशिवाय दुसरा कोणीही कर्ता नाही, असे पाहतो आणि तिन्ही गुणांच्या अत्यंत पलीकडे असणार्‍या सच्चिदानंदघनस्वरूप मला परमात्म्याला तत्त्वतः जाणतो, त्यावेळी तो माझ्या स्वरूपाला प्राप्त होतो. 14.19",

        "हा पुरुष शरीराच्या उत्पत्तीला कारण असलेल्या या तिन्ही गुणांना उल्लंघून जन्म, मृत्यू, वार्धक्य आणि सर्व प्रकारच्या दुःखांपासून मुक्त होऊन परमानंदाला प्राप्त होतो. 14.20",

        "अर्जुन म्हणाला, या तिन्ही गुणांच्या पलीकडे गेलेला पुरुष कोणकोणत्या लक्षणांनी युक्त असतो? आणि त्याचे आचरण कशाप्रकारचे असते? तसेच हे प्रभो! मनुष्य कोणत्या उपायाने या तीन गुणांच्या पलीकडे जातो? 14.21",

        "श्रीभगवान म्हणाले, हे अर्जुना! जो पुरुष सत्त्वगुणाचे कार्यरूप प्रकाश, रजोगुणाचे कार्यरूप प्रवृत्ती आणि तमोगुणाचे कार्यरूप मोह ही प्राप्त झाली असता त्यांचा विषाद मानत नाही आणि प्राप्त झाली नाही तरी त्यांची इच्छा करीत नाही, 14.22",

        "जो साक्षीरूप राहून गुणांकडून विचलित केला जाऊ शकत आणि गुणच गुणांत वावरत आहेत, असे समजून जो सच्चिदानंदघन परमात्म्यांत एकरूप होऊन राहतो व त्या स्थितीपासून कधी ढळत नाही. 14.23",

        "जो पुरुष निरंतर आत्मभावात राहून सुख-दुःख समान मानतो, माती, दगड आणि सोने यांना सारखेच मानतो, ज्याला आवडती व नावडती गोष्ट सारखीच वाटते, जो ज्ञानी आहे आणि स्वतःची निंदा व स्तुती ज्याला समान वाटतात. 14.24",

        "जो मान व अपमान सारखेच मानतो, ज्याची मित्र व शत्रू या दोघांविषयी समान वृत्ती असते, तसेच सर्व कार्यांत ज्याला 'मी करणारा' हा अभिमान नसतो, त्याला गुणातीत म्हणतात. 14.25",

        "आणि जो पुरुष अव्यभिचारी भक्तियोगाने मला निरंतर भजतो, तोसुद्धा या तिन्ही गुणांना पूर्णपणे उल्लंघून सच्चिदानंदघन ब्रह्माला प्राप्त होण्यास योग्य ठरतो. 14.26",

        "कारण त्या अविनाशी परब्रह्माचा, अमृताचा, नित्य धर्माचा आणि अखंड एकरस आनंदाचा आश्रय मी आहे. 14.27",

        "इस प्रकार ॐ तत्  सत् - इन भगवन्नामों के उच्चारणपूर्वक ब्रह्मविद्या और योगशास्त्रमय श्रीमद्भगवद्गीतोपनिषदरूप श्रीकृष्णार्जुनसंवाद में ‘गुणत्रयविभागयोग’ नामक चौदहवाँ अध्याय पूर्ण हुआ।"
      ],
      "adhyaya-15" : [
        "श्रीभगवान् म्हणाले,  आदिपुरुष परमेश्वराची मूळ असलेल्या ब्रह्मदेवरूप मुख्य फांदी असलेल्या ज्या संसाररूप अश्वत्थवृक्षाला अविनाशी म्हणतात,  तसेच वेद ही ज्याची पाने म्हटली आहेत,  त्या संसाररूप वृक्षाला जो पुरुष मुळासहित तत्वतः जाणतो,  तो वेदांचे तात्पर्य जाणणारा आहे. 15.1",

        "त्या संसार वृक्षाच्या तिन्ही गुणरूप पाण्याने वाढलेल्या, तसेच विषयभोगरूप अंकुरांच्या, देव, मनुष्य आणि पशु-पक्ष्याची योनिरूप फांद्या खाली व वर सर्वत्र पसरल्या आहेत. तसेच मनुष्ययोनीत कर्मांनुसार बांधणारी अहंता-ममता आणि वासनारूप मुळेही खाली आणि वर सर्व लोकांत व्यापून राहिली आहेत. 15.2",

        "या संसारवृक्षाचे स्वरूप जसे सांगितले आहे, तसे येथे विचारकाली आढळत नाही. कारण याचा 'आदी' नाही, 'अन्त' नाही. तसेच त्याची उत्तम प्रकारे स्थिरताही नाही. म्हणून या अहंता, ममता आणि वासनारूपी अतिशय घट्ट मुळे असलेल्या संसाररूपी अश्वत्थवृक्षाला बळकट वैराग्यरूप शस्त्राने कापून. 15.3",

        "त्यानंतर त्या परमपदरूप परमेश्वराला चांगल्या प्रकारे शोधले पाहिजे. जेथे गेलेले पुरुष संसारात परत येत नाहीत आणि ज्या परमेश्वरापासून या प्राचीन संसारवृक्षाची प्रवृत्तिपरंपरा विस्तार पावली आहे,  त्या आदिपुरुष नारायणाला मी शरण आहे, अशा दृढ निश्चयाने त्या परमेश्वराचे मनन आणि निदिध्यासन केले पाहिजे. 15.4",

        "ज्यांचे मान व मोह नष्ट झाले, ज्यांनी आसक्तिरूप दोष जिंकला, ज्यांची परमात्म्याच्या स्वरूपात नित्य स्थिती असते आणि ज्यांच्या कामना पूर्णपणे नाहीशा झाल्या, ते सुख-दुःख नावाच्या द्वंद्वापासून मुक्त झालेले ज्ञानीजन त्या अविनाशी परमपदाला पोचतात. 15.5",

        "ज्या परम पदाला पोचल्यावर माणसे फिरून या संसारात येत नाहीत,  त्या स्वयंप्रकाशी परमपदाला ना सूर्य प्रकाशित करू शकतो,  ना चन्द्र,  ना अग्नि,  तेच माझे परम धाम आहे. 15.6",

        "या देहात हा सनातन जीवात्मा माझाच अंश आहे आणि तोच प्रकृतीत स्थित मनाला आणि पाचही इंद्रियांना आकर्षित करतो. 15.7",

        "वारा वासाच्या वस्तूतून वास घेऊन स्वतः बरोबर नेतो तसाच देहादिकांचा स्वामी जीवात्माही ज्या शरीराचा त्याग करतो,  त्या शरीरातून मनसहित इंद्रिये बरोबर घेऊन नवीन मिळणाऱ्या शरीरात जातो. 15.8",

        "हा जीवात्मा काम, डोळे, त्वचा, जीभ, नाक आणि मन यांच्या आश्रयानेच विषयांचा उपभोग घेतो. 15.9",

        "शरीर सोडून जात असता किंवा शरीरात राहात असता किंवा विषयांचा भोग घेत असता किंवा तीन गुणांनी युक्त असताही (त्या आत्मस्वरूपाला) अज्ञानी लोक ओळखत नाहीत. केवळ ज्ञानरूप दृष्टी असलेले विवेकी ज्ञानीच तत्वतः ओळखतात. 15.10",

        "योगीजनही आपल्या हृदयात असलेल्या या आत्म्याला प्रयत्नानेच तत्वतः जाणतात; परंतु ज्यांनी आपले अंतःकरण शुद्ध केले नाही असे अज्ञानी लोक प्रयत्न करूनही या आत्मस्वरूपाला जाणत नाहीत. 15.11",

        "सूर्यामध्ये राहून जे तेज सर्व जगाला प्रकाशित करते, जे तेज चंद्रात आहे आणि जे अग्नीत आहे, ते माझेच तेज आहे, असे तू जाण. 15.12",

        "आणि मीच पृथ्वीत शिरून आपल्या शक्तीने सर्व भूतांना धारण करतो आणि रसरूप अर्थात अमृतमय चंद्र होऊन सर्व वनस्पतींचे पोषण करतो. 15.13",

        "मीच सर्व प्राण्यांच्या शरीरात राहणारा, प्राण व अपानाने संयुक्त वैश्वानर अग्निरूप होउन चार प्रकारचे अन्न पचवितो. 15.14",

        "मीच सर्व प्राण्यांच्या हृदयात अन्तर्यामी होऊन राहिलो आहे. माझ्यापासूनच स्मृती, ज्ञान आणि अपोहन ही होतात. सर्व वेदांकडून मीच जाणण्यास योग्य आहे. तसेच  वेदान्ताचा कर्ता आणि वेदांना जाणणाराही मीच आहे. 15.15",

        "या विश्वात नाशवान आणि अविनाशीही असे दोन प्रकारचे पुरुष आहेत. त्यामध्ये सर्व भूतमात्रांची शरीरे हा नाशवान आणि जीवात्मा अविनाशी म्हटला जातो. 15.16",

        "परंतु या दोन्हींपेक्षा उत्तम पुरुष तर निराळाच आहे. जो तिन्ही लोकांत प्रवेश करून सर्वांचे धारण-पोषण करतो. याप्रमाणे तो अविनाशी परमेश्वर आणि परमात्मा असा म्हटला जातो. 15.17",

        "कारण मी नाशवान जडवर्ग-क्षेत्रापासून तर पूर्णपणे पलीकडचा आहे आणि अविनाशी जीवात्म्यापेक्षाही उत्तम आहे. म्हणून लोकांत आणि वेदांतही पुरुषोत्तम नावाने प्रसिद्ध आहे. 15.18",

        "हे भारता! जो ज्ञानी पुरुष मला अशाप्रकारे तत्त्वतः पुरुषोत्तम म्हणून जाणतो, तो सर्वज्ञ पुरुष सर्व रीतीने नेहमी मला वासुदेव परमेश्वरालाच भजतो. 15.19",

        "हे निष्पाप अर्जुना! असे हे अतिरहस्यमय गुप्त शास्त्र मी तुला सांगितले आहे, याचे तत्वतः ज्ञान करून घेतल्याने मनुष्य ज्ञानवान आणि कृतार्थ होतो. 15.20",

        "इस प्रकार ॐ तत्  सत् - इन भगवन्नामों के उच्चारणपूर्वक ब्रह्मविद्या और योगशास्त्रमय श्रीमद्भगवद्गीतोपनिषदरूप श्रीकृष्णार्जुनसंवाद में ‘पुरूषोत्तमयोग’ नामक पन्द्रहवाँ अध्याय पूर्ण हुआ।"    
      ],
      "adhyaya-16" : [
        "श्री भगवान म्हणाले, भयाचा पूर्ण अभाव, अंत:करणाची पूर्ण निर्मळता, तत्वज्ञान करता ध्यानयोगात निरंतर दृढ स्थिति आणि सात्विक दान, इंद्रियांचे दमन, भगवान, देवता आणि गुरुजनांची पूजा, तसेच अग्निहोत्र इत्यादी उत्तम कर्माचे आचरण, त्याप्रमाणे वेदशास्त्रांचे पठन-पाठन, भगवंताच्या नामाचे व गुणांचे किर्तन, स्वधर्माचे पालन करण्यासाठी कष्ट सोसणे आणि शरीर व इंद्रियांसह अंत:करणाची सरलता. 16.1",

        "काया-वाचा-मनाने कोणालाही कोणत्याही प्रकारे दुःख न देणे,यथार्थ व प्रिय भाषण, आपल्यावर अपकार करण्यारावरही न रागावणे, कर्माच्या ठिकाणी कर्तेपणाचा व अभिमानाचा त्याग, अंतःकरणात चंचलता नसणे, कोणतीही निंदा वगैरे न करणे, सर्व प्राणिमात्रांच्या ठिकाणी निर्हेतुक दया, इंद्रियांचा विषयांशी संयोग झाला तरी त्यांच्याविषयी आसक्ति न वाटणे, कोमलता, लोकविरुद्ध व शास्त्रविरुद्ध आचरण करण्याची लज्जा, निरर्थक हालचाली न करणे. 16.2",

        "तेज, क्षमा, धैर्य, बाह्यशुद्धी, कुणाविषयीही शत्रुत्व न वाटणे आणि स्वतःविषयी मोठेपणाचा अभिमान नसणे. ही सर्व अर्जुना! दैवी संपत्ती घेऊन जन्मलेल्या माणसाची लक्षणे आहेत. 16.3",

        "हे पार्था! ढोंग, घमेंड, अभिमान, राग, कठोरपणा आणि अज्ञान ही सर्व असुरी संपत्ती घेऊन जन्मलेल्या पुरुषाची लक्षणे आहेत. 16.4",

        "दैवी मुक्तिदायक आणि असुरी संपदा नकारक मानली आहे. म्हणून हे अर्जुना! तू शोक करू नकोस. कारण तू दैवी संपदा घेऊन जन्मला आहेस. 16.5",

        "हे अर्जुना! ह्या जगात मनुष्यसमुदाय दोन प्रकारांचे आहेत. एक दैवी प्रकृतीचे आणि दुसरे आसुरी प्रकृतीचे. त्यापैकी दैवी प्रकृतीचे विस्तारपूर्वक सांगितले. आता तू आसुरी प्रकृतीच्या मनुष्यसमुदायाबद्दलही माझ्याकडून सविस्तर ऐक. 16.6",

        "असुरी स्वभावाचे लोक प्रवृत्ति आणि निवृत्ति दोन्हीही जाणत नाहीत. त्यामुळे त्यांच्या ठिकाणी अंतर्बाह्य शुद्धी असत नाही. उत्तम आचरण असत नाही आणि सत्य भाषणही असत नाही. 16.7",

        "ते आसुरी स्वभावाचे मनुष्य असे सांगतात की हे जग आश्रयरहित, सर्वथा खोटे आणि ईश्वराशिवाय आपोआप केवळ स्त्रीपुरुषांच्या संयोगातून उत्पन्न झाले आहे. म्हणूनच केवळ 'काम' हेच त्याचे करण आहे. त्याशिवाय दुसरे काय आहे? 16.8",

        "ह्या खोट्या ज्ञानाचा अवलंब करून ज्यांचा स्वभाव भ्रष्ट झाला आहे आणि ज्यांची बुद्धी मंद आहे असे सर्वांवर अपकार करणारे क्रूरकर्मी मनुष्य केवळ जगाच्या नाशाला समर्थ होतात. 16.9",

        "ते दंभ, मान आणि मदाने युक्त असलेले मनुष्य कोणत्याही प्रकारे पूर्ण न होणाऱ्या कामनांचा आश्रय घेऊन अज्ञानाने खोटे सिंद्धांत स्वीकारून भ्रष्ट आचरण करीत जगात वावरत असतात. 16.10",

        "तसेच ते आमरणांत असंख्य चिंताचे ओझे घेतलेले, विषयभोग भोगण्यात तत्पर असलेले 'हाच काय तो आनंद आहे' असे मानणारे असतात. 16.11",

        "शेकडो आशांच्या पाशांनी बांधले गेलेले ते मनुष्य काम-क्रोधात बुडून जाऊन विषयभोंगांसाठी अन्यायाने द्रव्यादी पदार्थांचा संग्रह करण्याचा प्रयत्न करीत असतात. 16.12",

        "ते विचार करीत असतात की मी आज हे मिळविले आणि आता मी हा मनोरथ पूर्ण करिन.     माझ्याजवळ हे इतके द्रव्य आहे आणि पुन्हासुद्धा हे मला मिळेल. 16.13",

        "या शत्रूला मी मारले आणि त्या दुसऱ्या शत्रूंनाही मी मारीन. मी ईश्वर आहे, ऐश्वर्य भोगणारा आहे. मी सर्व सिद्धींनी युक्त आहे आणीबाळवं तसाच सुखी आहे. 16.14",

        "मी मोठा धनिक आणि मोठ्या कुळात जन्मलेला आहे. माझ्यासारखा दुसरा कोण आहे ? मी यज्ञ करीन, दान देईन, मजेत राहीन. अशाप्रकारे अज्ञानाने मोहित झालेले, अनेक प्रकारांनी भ्रांत चित्त झालेले, मोहाच्या जाळात अडकलेले आणि विषयभोगांत अत्यंत आसक्त असे आसुरी लोक महा अपवित्र नरकात पडतात. (16.15-16.16)",

        "मी मोठा धनिक आणि मोठ्या कुळात जन्मलेला आहे. माझ्यासारखा दुसरा कोण आहे ? मी यज्ञ करीन, दान देईन, मजेत राहीन. अशाप्रकारे अज्ञानाने मोहित झालेले, अनेक प्रकारांनी भ्रांत चित्त झालेले, मोहाच्या जाळात अडकलेले आणि विषयभोगांत अत्यंत आसक्त असे आसुरी लोक महा अपवित्र नरकात पडतात. (16.15-16.16)",

        "ते स्वतःलाच श्रेष्ठ मानणारे घमेंडखोर लोक धन आणि मान यांच्या मदाने उन्मत्त होऊन केवळ नावाच्या यज्ञांनी पाखंडीपणाने शास्त्रविधिहीन यज्ञ करतात. 16.17",

        "ते अहंकार,बळ, घमेंड, कामना आणि क्रोधादिकांच्या आहारी गेलेले आणि दुसऱ्यांची निंदा करणारे पुरुष आपल्या व इतरांच्या शरीरात असलेल्या मज अंतार्यामीचा द्वेष करणारे असतात. 16.18",

        "त्या द्वेष करणार्‍या, पापी, क्रूर कर्मे करणार्‍या नराधमांना मी संसारात वारंवार आसुरी योनींतच टाकतो. 16.19",

        "हे अर्जुना! ते मूढ मला प्राप्त ना होता जन्मोजन्मी आसुरी योनीतच जन्मतात. उलट त्याहूनही अति नीच गतीला प्राप्त होतात. अर्थात घोर नरकांत पडतात. 16.20",

        "काम, क्रोध आणि लोभ हिबतीं प्रकारची नरकाची दारे आत्म्याचा नाश करणारी अर्थात त्याला अधोगतीला नेणारी आहेत. म्हणूनच त्या तीहींचा त्याग करावा. 16.21",

        "हे अर्जुना! या तिन्ही नारकांच्या दारांपासून मुक्त झालेला पुरुष आपल्या कल्याणाचे आचरण करतो. त्याने तो परम गती मिळवतो. अर्थात मला येऊन मिळतो. 16.22",

        "जो मनुष्य शास्त्राचे नियम सोडून स्वतःच्या मनाला वाटेल तसे वागतो त्याला सिद्धी मिळत नाही, परम गती मिळत नाही आणि सुखही मिळत नाही. 16.23",

        "म्हणून तुला कर्तव्य आणि अकर्तव्य यांची व्यवस्था लावण्यात शास्त्र प्रमाण आहे, असे जाणून तू शास्त्रविधीने नेमलेले कर्मच करणे योग्य आहे. 16.24",

        "इस प्रकार ॐ तत्  सत् - इन भगवन्नामों के उच्चारणपूर्वक ब्रह्मविद्या और योगशास्त्रमय श्रीमद्भगवद्गीतोपनिषदरूप श्रीकृष्णार्जुनसंवाद में ‘देवासुरसम्पदविभाग योग’ नामक सोलहवाँ अध्याय पूर्ण हुआ।"     
      ],
      "adhyaya-17" : [
        "अर्जुन म्हणाला, हे कृष्णा! जी माणसे शास्त्रविधीला सोडून श्रद्धेने युक्त होऊन देव देविकांचे पूजन करतात, त्यांची मग स्थिती कोणती? सात्विक, राजस की तामस? 17.1",

        "श्री भगवान म्हणाले, मनुष्यांची ती शास्त्रीय संस्कार नसलेली, केवळ स्वभावात: उत्पन्न झालेली श्रद्धा सात्विक, राजस व तामस अशा तीन प्रकारचीच असते. ती तू माझ्याकडून ऐक. 17.2",

        "हे भारता! सर्व माणसांची श्रद्धा त्यांच्या अंतःकरणानूरुप असते. हा पुरुष श्रद्धामय आहे. म्हणून जो पुरुष ज्या श्रद्धेने युक्त आहे तो स्वताःही तोच आहे. (अर्थात त्या श्रद्धेनुसार त्याचे स्वरूप असते.) 17.3",

        "सात्विक माणसे देवांची पूजा करतात. राजस माणसे यक्ष-राक्षशांची तसेच जी इतर तामस माणसे असतात, ती प्रेत व भूतगणांची पूजा करतात. 17.4",

        "जी माणसे शास्त्रविधी सोडून केवळ मनाच्या कल्पनेप्रमाणे घोर तप करतात तसेच दंभ, अहंकार, कामना, आसक्ती आणि बळाचा अभिमान यांनी युक्त असतात. 17.5",

        "जे शरीराच्या रुपात असलेल्या भूतसमुदायला आणि अंतःकरणात राहणाऱ्या मलाही कृश करणारे असतात, ते अज्ञानी लोक आसुरी स्वभावाचे आहेत, असे तू जाण. 17.6",

        "भोजनही सर्वांना आपापल्या प्रकृतीप्रमाणे तीन प्रकारचे प्रिय असते. आणि तसेच यज्ञ, तप आणि दानही तीन प्रकारची आहेत. त्यांचे हे निरनिराळे भेद तू माझ्याकडून ऐक. 17.7",

        "आयुष्य, बुद्धी, बळ, आरोग्य,सुख आणि प्रीती वाढविणारे, रसयुक्त, स्निग्ध, स्थिर राहणारे, स्वभावत: मनाला प्रिय वाटणारे असे भोजनाचे पदार्थ सात्विक पुरुषांना प्रिय असतात. 17.8",

        "कडू, आंबट, खारट, फार गरम, तिखट ,कोरडे, जळजळणारे आणि दुःख, काळजी व रोग उत्पन्न करणारे भोजनाचे पदार्थ राजस माणसांना आवडतात. 17.9",

        "जे भोजन कच्चे, रस नसलेले, दुर्गंध येणारे, शिळे आणि उष्टे असते, तसेच जे अपवित्रही असते. ते भोजन तामसी लोकांना आवडते. 17.10",

        "जो शास्त्रविधीने नेमून दिलेला, यज्ञ करणे कर्तव्य आहे असे मनाचे समाधान करून फळाची इच्छा न करणाऱ्या पुरुषाकडून केला जातो, तो सात्विक यज्ञ होय. 17.11",

        "परंतु हे अर्जुना! केवळ दिखाव्यासाठी किंवा फळ नजरेसमोर ठेवून जो यज्ञ केला जातो, तो यज्ञ तू राजस समज. 17.12",

        "शास्त्राला सोडून, अन्नदान न करता, दक्षिणा न देता व श्रद्धा न ठेवता केल्या जाणाऱ्या यज्ञाला तामस यज्ञ म्हणतात. 17.13",

        "देव, ब्राह्मण, गुरू व ज्ञानी यांची पूजा करणे तसेच पावित्र्य, सरळपणा, ब्रह्मचर्य आणि अहिंसा हे शारीरिक तप म्हटलें जाते. 17.14",

        "जे दुसऱ्याला न बोचणारे, प्रिय, हितकारक आणि यथार्थ भाषण असते ते, वेदशास्त्रांचे पठन आणि परमेश्वराच्या नामजपाचा अभ्यास- हेच वाणीचे तप म्हटले जाते. 17.15",

        "मनाची प्रसन्नता,शांत भाव, भगवत्चिंतन करायचा स्वभाव, मनाचा निग्रह आणि अंतःकरणातील भावांची पूर्ण पवित्रता हे मनाचे तप म्हटले जाते. 17.16",

        "फळाची इच्छा न करणाऱ्या योगी पुरुषांकडून अत्यंत श्रद्धेने केलेल्या वर सांगितलेल्या तिन्ही प्रकारच्या तपाला सात्विक तप म्हणतात. 17.17",

        "जे तप सत्कार, मान व पूजा होण्यासाठी तसेंच दूसऱ्या काही स्वार्थासाठीही स्वभावाप्रमाणे किंवा पाखंडीपणाने केले जाते, ते अनिश्चित तसेच क्षणिक फळ देणारे तप येथे राजस म्हटले आहे. 17.18",

        "जे तप मुर्खतापूर्वक हट्टाने, मन, वाणी आणि शरीराला कष्ट देऊन किंवा दुसऱ्याचे अनिष्ट करण्यासाठी केले जाते, ते तप तामस म्हटले गेले आहे. 17.19",

        "दान देणेच कर्तव्य आहे’ ह्या भावनेने जे दान देश, काल आणि पात्र मिळाली असता उपकार न करणाऱ्याला दिले जाते, ते दान सात्विक म्हटले गेले आहे. 17.20",

        "परंतु जे दान क्लेशपूर्वक प्रत्युपकाराच्या हेतूने किंवा फळ नजरेसमोर ठेवून दिले जाते, ते राजस दान म्हटले आहे. 17.21",

        "जे दान सत्काराशिवाय किंवा तिरस्कारपूर्वक अयोग्य ठिकाणी, अयोग्य काळी आणि कुपात्री दिले जाते, ते दान तामस म्हटले गेले आहे. 17.22",

        "ॐ', तत्, सत् अशी तीन प्रकारची सच्चीदानंदघन ब्रह्माची नांवे सांगितली आहेत. त्यापासून सृष्टीच्या आरंभी ब्राह्मण, वेद आणि यज्ञादी रचले गेले आहेत. 17.23",

        "म्हणून वेदमंत्रांचा उच्चार करणाऱ्या श्रेष्ठ पुरुषांना शास्त्राने सांगितलेल्या यज्ञ, दान व तपरूप क्रियांचा नेहमी 'ॐ' परमात्माच्या नावाचा उच्चार करूनच प्रारंभ होत असतो. 17.24",

        "तत्' या नावाने संबोधिल्या जाणाऱ्या परमात्म्याचेच हे सर्व आहे, या भावनेने फळाची इच्छा न करता नाना प्रकारच्या यज्ञ, तप व दानरुप क्रिया कल्याणाची इच्छा करणाऱ्या पुरुषांकडून केल्या जातात. 17.25",

        "सत्' या परमात्माच्या नावाचा सत्य भावात आणि श्रेष्ठ भावात प्रयोग केला जातो. तसेच हे पार्था! 'उत्तम कर्मातही' 'सत्' शब्द योजला जातो. 17.26",

        "तसेच यज्ञ, तप व दान यांमध्ये जी स्थिती (आस्तिक बुद्धी) असते, तिलाही सत् असे म्हणतात आणि त्या परमात्म्यासाठी केलेले कर्म निश्चयानें 'सत्' असे म्हटले जाते.  17.27",

        "हे अर्जुना! श्रद्धेशिवाय केलेले हवन, दिलेले दान, केलेले तप आणि जे काही केलेले शुभ कार्य असेल, ते सर्व 'असत्' म्हटले जाते. त्यामुळे ते ना इहलोकात फलदायी होत ना परलोकात. 17.28",

        "इस प्रकार ॐ तत्  सत् - इन भगवन्नामों के उच्चारणपूर्वक ब्रह्मविद्या और योगशास्त्रमय श्रीमद्भगवद्गीतोपनिषदरूप श्रीकृष्णार्जुनसंवाद में ‘श्रद्धात्रयविभागयोग’ नामक सत्रहवाँ अध्याय पूर्ण हुआ।"
      ],
      "adhyaya-18" : [
        "अर्जुन म्हणाला, हे महाबाहो! हे अंतर्यामी! हे वासुदेवा! मी संन्यास आणि त्याग यांचे तत्त्व वेगवेगळे जाणू इच्छितो. 18.1",

        "श्रीभगवान म्हणाले, कित्येक पण्डित काम्य कर्मांच्या त्यागाला 'संन्यास' मानतात; तर दुसरे काही विचारकुशल लोक सर्व कर्मांच्या फळाच्या त्यागाला 'त्याग' म्हणतात. 18.2",

        "कित्येक विद्वान असे म्हणतात की, सर्व कर्मे दोषयुक्त आहेत म्हणून (ती) टाकणे योग्य होय आणि दुसरे विद्वान म्हणतात की, यज्ञ, दान आणि तपरूप कर्मे टाकणे योग्य नाही. 18.3",

        "हे पुरुषश्रेष्ठ अर्जुना! संन्यास आणि त्याग या दोहोंपैकी प्रथम त्यागाच्या बाबतीत माझा निर्णय ऐक. कारण त्याग सात्त्विक, राजस आणि तामस भेदांमुळे तीन प्रकारचा सांगितला आहे. 18.4",

        "यज्ञ दान आणि तपरूप कर्म टाकणे योग्य नाही. उलट ते अवश्य केले पाहिजे. कारण यज्ञ, दान व तप ही तीनही कर्मे बुद्धिमान माणसांना पवित्र करणारी आहेत. 18.5",

        "म्हणून हे पार्था! ही यज्ञ, दान व तपरूप कर्मे तसेच इतरही सर्व कर्तव्य कर्मे आसक्ती आणि फलांचा त्याग करून अवश्य केली पाहिजेत. हे माझे निश्चित असे उत्तम मत आहे. 18.6",

        "(निषिद्ध आणि काम्य कर्मांचा तर स्वरूपत: त्याग करणे योग्यच आहे.) परन्तु नियत कर्माचा स्वरूपतः त्याग योग्य नाही. म्हणून मोहाने त्याचा त्याग करणे याला 'तामस त्याग' म्हटले आहे. 18.7",

        "काही कर्म आहे, ते दुःखरूपच आहे, असे समजून जर कोणी शारीरिक त्रासाच्या भीतीने कर्तव्य कर्मे सोडून देईल, तर त्याला असा राजस त्याग करून त्यागाचे फळ कोणत्याही प्रकारे मिळत नाही. 18.8",

        "अर्जुना जे शास्त्रविहित कर्म 'करणे कर्तव्य आहे' या भावनेने आसक्ती आणि फळाचा त्याग करून केले जाते, तोच 'सात्त्विक' त्याग मानला गेला आहे. 18.9",

        "जो मनुष्य कुशल नसलेल्या कर्माचा द्वेष करीत नाही आणि कुशल कर्मात आसक्त होत नाही, तो शुद्ध सत्त्वगुणी पुरुष संशयरहित ज्ञानी व खरा त्यागी होय. 18.10",

        "कारण शरीरधारी कोणत्याही माणसाकडून पूर्णपणे सर्व कर्मांचा त्याग केला जाणे शक्य नाही. म्हणून जो कर्मफळाचा त्यागी आहे, तोच त्यागी आहे, असे म्हटले जाते. 18.11",

        "कर्मफळाचा त्याग न करणाऱ्या मनुष्यांना कर्माचे बरे, वाईट व मिश्र असे तीन प्रकारचे फळ मेल्यानंतर जरूर मिळते; परन्तु कर्मफळाचा त्याग करणाऱ्या मनुष्यांना कर्माचे फळ कधीही मिळत नाही. 18.12",

        "हे महाबाहो सर्व कर्मांच्या सिद्धींची ही पाच कारणे, कर्मांचा शेवट करण्याचा उपाय सांगणाऱ्या सांख्यशास्त्रात सांगितली गेली आहेत. ती तू माझ्याकडून नीट समजून घे. 18.13",

        "कर्म पूर्ण होण्यासाठी अधिष्ठान, कर्ता, निरनिराळ्या प्रकारची करणे, अनेक प्रकारच्या वेगवेगळ्या क्रिया आणि तसेच पाचवे कारण दैव आहे. 18.14",

        "मनुष्य मन, वाणी आणि शरीराने शास्त्राला अनुसरून किंवा त्याविरुद्ध जे कोणतेही कर्म करतो, त्याची ही पाचही कारणे असतात. 18.15",

        "परंतु असे असूनही जो मनुष्य अशुद्ध बुद्धीमुळे कर्मे पूर्ण होण्यामध्ये केवळ आणि शुद्धस्वरूप आत्म्याला कर्ता समजतो, तो मलिन बुद्धीचा अज्ञानी खरे काय ते जाणत नाही. 18.16",

        "ज्या माणसाच्या अंत:करणात 'मी कर्ता आहे' असा भाव नसतो, तसेच ज्याची बुद्धी सांसारिक पदार्थांत आणि कर्मांत लिप्त होत नाही, तो माणूस या सर्व लोकांना मारूनही वास्तविक तो मारत नाही आणि त्याला पापही लागत नाही. 18.17",

        "ज्ञाता, ज्ञान आणि ज्ञेय  या तीन प्रकारच्या कर्माच्या प्रेरणा आहेत. आणि कर्ता करण तसेच क्रिया हे तीन प्रकारचे कर्मसंग्रह आहेत. 18.18",

        "गुणांची संख्या करणाऱ्या शास्त्रात ज्ञान, कर्म आणि कर्ता हे गुणांच्या भेदाने तीन-तीन प्रकारचेच सांगितले आहेत. तेही तू माझ्याकडून नीट ऐक. 18.19",

        "ज्या ज्ञानामुळे माणूस \"निरनिराळ्या सर्व भूतांमध्ये एक अविनाशी परमात्मभाव विभागरहित समभावाने भरून राहिला आहे\", असे पाहतो, ते ज्ञान तू सात्त्विक आहे, असे जाण. 18.20",

        "परंतु ज्या ज्ञानाने मनुष्य सर्व भूतांमध्ये भिन्न भिन्न प्रकारांचे अनेक भाव वेगवेगळे जाणतो, ते ज्ञान तू राजस समज. 18.21",

        "परंतु जे ज्ञान एका कार्यरूपी शरीरातच पूर्णासारखे आसक्त असते, तसेच जे युक्तिशून्य, तात्त्विक अर्थाने रहित आणि तुच्छ असते, ते तामस म्हटले गेले आहे. 18.22",

        "जे कर्म शास्त्रविधीने नेमून दिलेले असून कर्तेपणाचा अभिमान न बाळगता फळाची इच्छा न करणाऱ्या माणसाने राग व द्वेष सोडून केलेले असते, ते सात्त्विक म्हटले जाते. 18.23",

        "परंतु जे कर्म अतिशय परिश्रमपूर्वक तसेच भोगांची इच्छा करणाऱ्या किंवा अहंकार बाळगणाऱ्या माणसाकडून केले जाते, ते राजस म्हटले गेले आहे. 18.24",

        "जे कर्म परिणाम, हानी, हिंसा आणि सामर्थ्य यांचा विचार न करता केवळ अज्ञानाने केले जाते, ते तामस होते. 18.25",

        "जो कर्ता आसक्ती न बाळगणारा, 'मी माझे' न म्हणणारा, धैर्य व उत्साहाने युक्त, 'कार्य सिद्ध होवो न होवो', त्याविषयी हर्षशोकादी विकारांनी रहित असलेला तो सात्त्विक म्हटला जातो. 18.26",

        "जो कर्ता आसक्ती असलेला, कर्मांच्या फळांची इच्छा बाळगणारा, लोभी, इतरांना पीडा करण्याचा स्वभाव असलेला, अशुद्ध आचरणाचा आणि हर्ष- शोकांनी युक्त असतो, तो राजस म्हटला जातो. 18.27",

        "जो कर्ता अयुक्त, अशिक्षित, घमेंडखोर, धूर्त, दुसऱ्यांची जीवन-वृत्ती नाहीशी करणारा, शोक करणारा, आळशी आणि दीर्घसूत्री असतो, तो तामस म्हटला जातो. 18.28",

        "हे धनंजया! आता बुद्धीचे व धृतीचेही गुणांनुसार तीन प्रकारचे भेद माझ्याकडून पूर्णपणे विभागपूर्वक सांगितले जात आहेत, ते तू ऐक. 18.29",

        "हे पार्था! जी बुद्धी प्रवृत्तिमार्ग व निवृत्तिमार्ग, कर्तव्य आणि अकर्तव्य, भय आणि अभय, तसेच बंधन आणि मोक्ष यथार्थपणे जाणते, ती सात्त्विक बुद्धी होय. 18.30",

        "हे पार्था! मनुष्य ज्या बुद्धीमुळे धर्म आणि अधर्म तसेच कर्तव्य आणि अकर्तव्य यथायोग्य रीतीने जाणत नाही, ती बुद्धी राजसी होय. 18.31",

        "हे अर्जुना! तमोगुणाने व्यापलेली जी बुद्धी अधर्मालाही 'हा धर्म आहे' असे मानते, तसेच याच रीतीने इतर सर्व पदार्थांनाही विपरीत मानते, ती बुद्धी तामसी होय. 18.32",

        "हे पार्था ज्या अव्यभिचारिणी धारणाशक्तीने मनुष्य ध्यानयोगाने मन, प्राण व इंद्रिये यांच्या क्रिया धारण करीत असतो, ती धारणा सात्त्विक होय. 18.33",

        "परंतु हे कुंतीपुत्र अर्जुना! फळाची इच्छा असलेला मनुष्य अति आसक्तीमुळे ज्या धारणाशक्तीने धर्म, अर्थ व काम यांना धारण करतो, ती धारणा राजसी होय. 18.34",

        "हे पार्था! दुष्ट बुद्धीचा मनुष्य ज्या धारणाशक्तीमुळे झोप, भीती, काळजी, दुःख आणि उन्मत्तपणाही सोडत नाही अर्थात् धारण करून राहतो, ती धारणा तामसी होय. 18.35",

        "हे भरतश्रेष्ठा! आता तीन प्रकारचे सुखही तू माझ्याकडून ऐक. ज्या सुखात साधक भजन, ध्यान आणि सेवा इत्यादींच्या अभ्यासाने रमतो आणि ज्यामुळे त्याचे दु:ख नाहीसे होते, 18.36",

        "जे आरंभी जरी विषासारखे वाटले, तरी परिणामी अमृतासारखे असते, ते परमात्मविषयक बुद्धीच्या प्रसादाने उत्पन्न होणारे सुख सात्त्विक म्हटले गेले आहे. 18.37",

        "जे सुख विषय आणि इंद्रियांच्या संयोगाने उत्पन्न होते, ते प्रथम भोगताना अमृतासारखे वाटत असले तरी परिणामी विषासारखे असते. म्हणून ते सुख राजस म्हटले गेले आहे. 18.38",

        "जे सुख भोगकाली आणि परिणामीही आत्म्याला मोह पाडणारे असते, ते झोप, आळस आणि प्रमादापासून उत्पन्न झालेले सुख तामस म्हटले आहे. 18.39",

        "पृथ्वीवर, आकाशात किंवा देवांत तसेच यांच्याशिवाय इतरत्र कोठेही असा कोणताच प्राणी किंवा पदार्थ नाही की जो प्रकृतीपासून उत्पन्न झालेल्या या तीन गुणांनी रहित असेल. 18.40",

        "हे परंतपा! ब्राह्मण, क्षत्रिय, वैश्य आणि शूद्र यांची कर्मे स्वभावतः उत्पन्न झालेल्या गुणांमुळे निरनिराळी केली गेली आहेत. 18.41",

        "अंत:करणाचा निग्रह, इंद्रियांवर ताबा ठेवणे, धर्मासाठी कष्ट सहन करणे, अंतर्बाह्य शुद्ध राहणे, दुसऱ्यांच्या अपराधांना क्षमा करणे, मन, इंद्रिये व शरीर सरळ राखणे, वेद, शास्त्र, ईश्वर व परलोक इत्यादींवर विश्वास ठेवणे, वेद-शास्त्रांचे अध्ययन अध्यापन करणे आणि परमात्मतत्त्वाचा अनुभव घेणे ही सर्वच्या सर्व ब्राह्मणाची स्वाभाविक कर्मे आहेत. 18.42",

        "शौर्य, तेज, धैर्य, चातुर्य, युद्धातून पलायन न करणे, दान देणे आणि स्वामिभाव' ही सर्वच्या सर्वच क्षत्रियाची स्वाभाविक कर्मे आहेत. 18.43",

        "शेती, गोपालन आणि क्रयविक्रयरूप सत्य व्यवहार ही वैश्याची स्वाभाविक कर्मे होत. तसेच सर्व वर्णांची सेवा करणे हे शूद्राचेही स्वाभाविक कर्म आहे. 18.44",

        "आपापल्या स्वाभाविक कर्मांत तत्पर असलेल्या मनुष्यास भगवत्प्राप्तिरूप परम सिद्धीचा लाभ होतो. आपल्या स्वाभाविक कर्मांत रत असलेला मनुष्य ज्या रीतीने कर्म करून परम सिद्धीला प्राप्त होतो, ती रीत तू ऐक. 18.45",

        "ज्या परमेश्वरापासून सर्व प्राण्यांची उत्पत्ती झाली आहे आणि ज्याने हे सर्व जग व्यापले आहे, त्या परमेश्वराची आपल्या स्वाभाविक कर्मांनी पूजा करून मनुष्य परम सिद्धी मिळवितो. 18.46",

        "उत्तम प्रकारे आचरिलेल्या दुसऱ्याच्या धर्मापेक्षा वैगुण्य असलेलाही आपला धर्म श्रेष्ठ आहे. कारण स्वभावाने नेमून दिलेले स्वधर्मरूप कर्म करणाऱ्या माणसाला पाप लागत नाही. 18.47",

        "म्हणूनच हे कुंतीपुत्रा! सदोष असले तरीही स्वाभाविक कर्म सोडून देऊ नये. कारण धुराने जसा अग्नी, तशी सर्व कामे कोणत्या ना कोणत्या दोषाने युक्त असतात. 18.48",

        "सर्वत्र आसक्तिरहित बुद्धी असलेला, निःस्पृह आणि अंतःकरण जिंकलेला मनुष्य सांख्ययोगाने त्या श्रेष्ठ 'नैष्कर्म्यसिद्धीला' प्राप्त होतो. 18.49",

        "हे कुंतीपुत्रा ! जी ज्ञानयोगाची अंतिम स्थिती आहे, त्या नैष्कर्म्यसिद्धीला ज्या रीतीने प्राप्त होऊन मनुष्य ब्रह्माला प्राप्त होतो, ती रीत थोडक्यात तू माझ्याकडून समजून घे. 18.50",

        "विशुद्ध बुद्धीने युक्त; हलके, सात्त्विक आणि नियमित भोजन घेणारा; शब्दादी विषयांचा त्याग करून एकान्तात शुद्ध ठिकाणी राहणारा; सात्त्विक धारणाशक्तीने अंत:करण व इंद्रियांवर संयम ठेवून मन, वाणी आणि शरीर ताब्यात ठेवणारा; राग-द्वेष पूर्णपणे नाहीसे करून चांगल्या प्रकारे दृढ वैराग्याचा आश्रय घेणारा; अहंकार, बळ, घमेंड, कामना, राग, संग्रहवृत्ती यांचा त्याग करून नेहमी ध्यानयोगात तत्पर असणारा; ममतारहित व शान्तियुक्त असा पुरुष सच्चिदानन्दघन ब्रह्मामध्ये एकरूप होऊन राहण्यास पात्र होतो. (18.51-18.53)",

        "विशुद्ध बुद्धीने युक्त; हलके, सात्त्विक आणि नियमित भोजन घेणारा; शब्दादी विषयांचा त्याग करून एकान्तात शुद्ध ठिकाणी राहणारा; सात्त्विक धारणाशक्तीने अंत:करण व इंद्रियांवर संयम ठेवून मन, वाणी आणि शरीर ताब्यात ठेवणारा; राग-द्वेष पूर्णपणे नाहीसे करून चांगल्या प्रकारे दृढ वैराग्याचा आश्रय घेणारा; अहंकार, बळ, घमेंड, कामना, राग, संग्रहवृत्ती यांचा त्याग करून नेहमी ध्यानयोगात तत्पर असणारा; ममतारहित व शान्तियुक्त असा पुरुष सच्चिदानन्दघन ब्रह्मामध्ये एकरूप होऊन राहण्यास पात्र होतो. (18.51-18.53)",

        "विशुद्ध बुद्धीने युक्त; हलके, सात्त्विक आणि नियमित भोजन घेणारा; शब्दादी विषयांचा त्याग करून एकान्तात शुद्ध ठिकाणी राहणारा; सात्त्विक धारणाशक्तीने अंत:करण व इंद्रियांवर संयम ठेवून मन, वाणी आणि शरीर ताब्यात ठेवणारा; राग-द्वेष पूर्णपणे नाहीसे करून चांगल्या प्रकारे दृढ वैराग्याचा आश्रय घेणारा; अहंकार, बळ, घमेंड, कामना, राग, संग्रहवृत्ती यांचा त्याग करून नेहमी ध्यानयोगात तत्पर असणारा; ममतारहित व शान्तियुक्त असा पुरुष सच्चिदानन्दघन ब्रह्मामध्ये एकरूप होऊन राहण्यास पात्र होतो. (18.51-18.53)",

        "मग तो सच्चिदानन्दघन ब्रह्मांत तद्रूप झालेला प्रसन्न चित्ताचा योगी कशाबद्दलही शोक करीत नाही आणि कशाचीही इच्छा करीत नाही. असा सर्व प्राण्यांच्या ठिकाणी समभाव बाळगणारा योगी माझ्या पराभक्तीला प्राप्त होतो. 18.54",

        "त्या पराभक्तीच्या योगाने तो मज परमात्म्याला 'मी जो आणि जसा आहे', अगदी बरोबर तसाच तत्त्वत: जाणतो. तसेच त्या भक्तीने मला तत्त्वत: जाणून त्याचवेळी माझ्यात प्रविष्ट होतो. 18.55",

        "माझ्या आश्रयाने राहणारा कर्मयोगी सर्व कामे नेहमी करीत असला तरी माझ्या कृपेने सनातन अविनाशी परम पदाला प्राप्त होतो. 18.56",

        "सर्व कर्मे मनाने माझ्या ठिकाणी अर्पण करून तसेच समबुद्धिरूप योगाचा अवलंब करून मत्परायण आणि निरंतर माझ्या ठिकाणी चित्त असलेला हो. 18.57",

        "वर सांगितल्याप्रमाणे माझ्या ठिकाणी चित्त ठेवल्याने तू माझ्या कृपेने सर्व संकटांतून सहजच पार होशील आणि जर अहंकारामुळे माझे सांगणे न ऐकशील तर नष्ट होशील अर्थात परमार्थाला मुकशील. 18.58",

        "तू अहंकार धरून 'मी युद्ध करणार नाही' असे मानतोस, तो तुझा निश्चय व्यर्थ आहे. कारण तुझा स्वभाव तुला जबरदस्तीने युद्ध करावयास लावील. 18.59",

        "हे कुंतीपुत्रा! जे कर्म तू मोहामुळे करू इच्छित नाहीस, तेही आपल्या पूर्वकृत स्वाभाविक कर्माने बद्ध असल्यामुळे पराधीन होऊन करशील. 18.60",

        "हे अर्जुना! अंतर्यामी परमेश्वर आपल्या मायेने शरीररूप यंत्रावर आरूढ झालेल्या सर्व प्राण्यांना त्यांच्या कर्मानुसार फिरवीत सर्व प्राण्यांच्या हृदयांत राहिला आहे. 18.61",

        "हे भारता! तू सर्व प्रकारे त्या परमेश्वरालाच शरण जा. त्या परमात्म्याच्या कृपेनेच तुला परम शान्ती आणि सनातन परम धाम मिळेल. 18.62",

        "अशाप्रकारे हे गोपनीयाहूनही अति गोपनीय ज्ञान मी तुला सांगितले. आता तू या रहस्यमय ज्ञानाचा संपूर्णपणे चांगला विचार करून मग जसे तुला आवडेल तसेच कर. 18.63",

        "सर्व गोपनीयांहून अति गोपनीय माझे परम रहस्ययुक्त वचन तू पुन्हा ऐक. तू माझा अत्यंत आवडता आहेस, म्हणून हे परम हितकारक वचन मी तुला सांगणार आहे. 18.64",

        "हे अर्जुना! तू माझ्या ठिकाणी मन ठेव. माझा भक्त हो. माझे पूजन कर आणि मला नमस्कार कर. असे केले असता तू मलाच येऊन मिळशील. हे मी तुला सत्य प्रतिज्ञापूर्वक सांगतो. कारण तू माझा अत्यंत आवडता आहेस. 18.65",

        "सर्व धर्म म्हणजे सर्व कर्तव्यकर्मे मला अर्पण करून तू केवळ सर्वशक्तिमान, सर्वाधार अशा मला परमेश्वरालाच शरण ये. मी तुला सर्व पापांपासून सोडवीन. तू शोक करू नकोस. 18.66",

        "हा गीतारूप रहस्यमय उपदेश कधीही तप न करणाऱ्या माणसाला सांगू नये. तसेच भक्तिहीन माणसाला आणि ऐकण्याची इच्छा नसणाऱ्यालाही सांगू नये. त्याचप्रमाणे माझ्यामध्ये दोष पाहणाऱ्याला तर कधीच सांगू नये. 18.67",

        "जो पुरुष माझ्या ठिकाणी परम प्रेम ठेवून हे परम रहस्ययुक्त गीताशास्त्र माझ्या भक्तांना सांगेल, तो मलाच प्राप्त होईल, यात मुळीच शंका नाही. 18.68",

        "माझे अत्यंत प्रिय कार्य करणारा त्याच्याहून अधिक मनुष्यांत कोणीही नाही. तसेच पृथ्वीवर त्याच्याहून अधिक मला प्रिय दुसरा कोणी भविष्यकाली होणारही नाही. 18.69",

        "जो पुरुष आम्हा दोघांच्या धर्ममय संवादरूप या गीताशास्त्राचे अध्ययन करील, त्याच्याकडूनही मी ज्ञानयज्ञाने पूजित होईन, असे माझे मत आहे. 18.70",

        "जो मनुष्य श्रद्धायुक्त होऊन दोषदृष्टी न ठेवता या गीताशास्त्राचे श्रवण करील, तोही पापांपासून मुक्त होऊन पुण्यकर्मे करणाऱ्यांच्या श्रेष्ठ लोकांना प्राप्त होईल. 18.71",

        "हे पार्था! हे गीताशास्त्र तू एकाग्र चित्ताने ऐकलेस का? आणि हे धनंजया! तुझा अज्ञानातून उत्पन्न झालेला मोह नाहीसा झाला का? 18.72",

        "अर्जुन म्हणाला, हे अच्युता! आपल्या कृपेने माझा मोह नाहीसा झाला आणि मला स्मृती प्राप्त झाली. आता मी संशयरहित होऊन राहिलो आहे. म्हणून मी आपल्या आज्ञेचे पालन करीन. 18.73",

        "संजय म्हणाला, अशाप्रकारे मी श्रीवासुदेव आणि महात्मा अर्जुन यांचा हा अद्भुत रहस्यमय रोमांचकारक संवाद ऐकला. 18.74",

        "श्रीव्यासांच्या कृपेने दिव्य दृष्टी मिळवून मी हा परम गोपनीय योग अर्जुनाला सांगत असताना स्वतः योगेश्वर भगवान श्रीकृष्णांकडून प्रत्यक्ष ऐकला आहे. 18.75",

        "महाराज! भगवान श्रीकृष्ण आणि अर्जुनाचा हा रहस्यमय, कल्याणकारक आणि अद्भुत संवाद पुन्हा पुन्हा आठवून मी वारंवार आनंदित होत आहे. 18.76",

        "हे राजन! श्रीहरीचे ते अत्यंत अलौकिक रूपही वरचेवर आठवून माझ्या मनाला खूप आश्चर्य वाटत आहे आणि मी वारंवार आनंदित होत आहे आणि हर्षपुलकित होत आहे. 18.77",

        "जेथे योगेश्वर भगवान श्रीकृष्ण आहेत आणि जेथे गांडीव धनुष्य धारण करणारा अर्जुन आहे, तेथेच श्री विजय, विभूती आणि अचल नीती आहे, असे माझे मत आहे. 18.78",

        "इस प्रकार ॐ तत्  सत् - इन भगवन्नामों के उच्चारणपूर्वक ब्रह्मविद्या और योगशास्त्रमय श्रीमद्भगवद्गीतोपनिषदरूप श्रीकृष्णार्जुनसंवाद में ‘मोक्षसन्यासयोग’ नामक अठारहवाँ अध्याय पूर्ण हुआ।"     
      ]
    }
  ]

    export default GeetajiMarathi