function Footer () {
    return (
    <>
        <footer>
            <div className="countVisit"><strong></strong> 
                <a href="https://www.hitwebcounter.com" target="_blank" rel="noreferrer">
                <img src="https://hitwebcounter.com/counter/counter.php?page=10453804&style=0006&nbdigits=6&type=page&initCount=0" title="Counter Widget" alt="Visit counter For Websites"   border="0" /></a>
            </div>
            <h3 className="slogan" >
            ।। गीता पढ़ें, पढ़ायें, जीवन मे लायें ।।
            </h3>
        </footer>
    </>
    )
}

export default Footer