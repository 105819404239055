import { useState, useRef } from "react";
import Geetajis from "./GeetajiAllLang/GeetajiIndex";
import Button from "./Button";
import Texts from "./TextScript/TextIndex"
import Timer from "./Timer";
import { useContext } from "react"
import { MyContext } from "./Context"
import { Link } from "react-router-dom"
import Audio from "./Audio";
// import Audios from "../audios/AudioIndex";
import AudioMark from "../audios/AudioMark";

function SelectShlok ({selected, process}) {
    
    const lang = useContext(MyContext)
    const textType = Texts[lang].Practice
    // console.log(textType)

    const [randNums, setRandNums] = useState(undefined);
    const [showShlokas, setShowShlokas] = useState('');
    const [showMeaning, setShowMeaning] = useState('');

    const geetaji = Geetajis[lang][0]
    const meaning = Geetajis[lang][1]
    // console.log(geetaji)
    // console.log(meaning)
//* TO GET RANDOM SHOK OF RANDOM ADHYAYA *//
    function showShlokNum(){
    // console.log(selected)
    let numOfSelected = selected.length
        if (numOfSelected > 0){
            let adhyayaNum = Math.floor(Math.random()*numOfSelected)
            let selAdhyaya = `adhyaya-${selected[adhyayaNum]}`
            let adhyaya = geetaji[selAdhyaya]
            // console.log(adhyaya)
            let noOfShlok = (adhyaya) ? adhyaya.length-1 : 0

            let shlokNum = Math.ceil(Math.random()*noOfShlok)
                setRandNums([shlokNum,selAdhyaya])
                setShowShlokas('')
                setShowMeaning('')
        } else {
            alert (textType.alert)
        }
    }

//* TO DISPLAY RANDOM SHLOK NO OR CHARAN *//
    let shlokRandom = (randNums) ? `${textType.adhyaya}-${randNums[1].slice(8)}, ${textType.shlokNo}-${randNums[0]}` : ''
    // console.log(shlokRandom)

    let strRand = (randNums) ? `${geetaji[randNums[1]][randNums[0]-1]}`: ''
    let commaIndex = (strRand) ? `${strRand.indexOf(',')}`: ''
    let charanRandom = (strRand) ? `${strRand.slice(0,commaIndex)}`: ''
    // console.log(charanRandom)

    const output = (process === 'Advance') ?
    `${shlokRandom}` :
    (process === 'Basic') ?
    `${charanRandom}` : ''

 //* FOR SHLOK AUDIO *//
    const audioToPlay = (randNums && AudioMark[randNums[1]]) ? 
    `https://content.learngeeta.com/book/assets/audios/Ch${Number(randNums[1].slice(8))}.mp3`
    // `https://github.com/rajesharora2801/audiosGeetaji/blob/main/${Number(randNums[1].slice(8))}.mp3?raw=true`
    // Audios.length >= Number(randNums[1].slice(8)) ?
    // Audios[Number(randNums[1].slice(8))-1] :''
    :''
    // console.log(Audios)
    // console.log(audioToPlay)

    const timeToStart = (randNums && audioToPlay !== '')? AudioMark[randNums[1]][randNums[0]][0] : ''
    const timeToStop = (randNums && audioToPlay !== '') ? 
        (process === 'Advance') ? AudioMark[randNums[1]][randNums[0]][1] :
        AudioMark[randNums[1]][randNums[0]+1][1] : ''

//* TO DISPLAY COMPLETE SHLOK AS ANSWER *//
    function showShlok(){
        let shlokas = (randNums) ? 
        
            (process === 'Advance') ?
            `${geetaji[randNums[1]][randNums[0]-1]}`:
            ((process === 'Basic') ?
            `${geetaji[randNums[1]][randNums[0]-1]}\n\n${geetaji[randNums[1]][randNums[0]]}`:'')
        
        : ''
        setShowShlokas(shlokas)
    }
    // console.log(showShlokas)

//* TO DISPLAY SHLOK MEANING *//
    function showMean(){
        let geetaMeaning = (randNums[1] in meaning) ? 

            (process === 'Advance') ?
            `${meaning[randNums[1]][randNums[0]-1]}`:
            ((process === 'Basic') ?

            (`${meaning[randNums[1]][randNums[0]-1]}` === `${meaning[randNums[1]][randNums[0]]}`? 
            `${meaning[randNums[1]][randNums[0]-1]}` : 
            `${meaning[randNums[1]][randNums[0]-1]}\n\n${meaning[randNums[1]][randNums[0]]}`)

            :'')
        : textType.meaningMsg
        
        setShowMeaning(geetaMeaning)
    }
    // console.log(showMeaning)

//* FOR REGISTERING ATTEMPTS *//
    const [attempts, setAttempts] = useState([]);
//* FOR TIMER COUNTER *//
    const [startTime, setStartTime] = useState(null);
    const [now, setNow] = useState(0);
    const timeRef = useRef(null);
    // const [display, setDisplay] = useState(false);
    
        function timerStart() {
            setStartTime(Date.now());
            setNow(Date.now());
            clearInterval(timeRef.current);
            timeRef.current = setInterval(() => {
            setNow(Date.now());
            }, 1000);
        }
        function timerStop() {
            clearInterval(timeRef.current);
            setAttempts([...attempts,{startTime, now, randNums}])
        }
        // console.log(attempts)

        // function showScore (){
        //     setDisplay(!display)
        // }

    return (
        <div>
            <h4 className="textViolet">{textType.clkBtn}</h4>
            <Button text = {(process === 'Advance') ? textType.shlokNo : textType.shlokCharan}
            onClick={() => {showShlokNum() ; timerStart()}} />
            <p className="output textViolet"><strong>{output}</strong></p>
            {(output !== '') &&
                <section className="timer counter"><strong>Time -</strong>{<Timer startTime={startTime} now={now}/>}</section>
            }
            {(output !== '') && <Button text = {textType.showAnswer} onClick={() => {showShlok() ; timerStop()}} />}

            {showShlokas !== '' && <Link className="link scoreBtn" to = '/showScore' state={attempts}>Report-Card</Link>}

            <p className="shlok textViolet">{showShlokas}</p>

            {(showShlokas !== '' && audioToPlay !== '') && <Audio audioAdh={audioToPlay}  timeStart={timeToStart} timeStop={timeToStop}/>}
            
            {showShlokas !== '' && <Button text = {textType.showMeaning} onClick={showMean} />}
            <p className="shlok textViolet">{showMeaning}</p>

            {/* {showShlokas !== '' && <Button text='Show-Score' onClick={showScore} />}
            {display && <Score attempts={attempts} />} */}
        </div>
    )
}

export default SelectShlok