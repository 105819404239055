const GeetajiGujarati = [
{
  "adhyaya-1" : [
    "ધૃતરાષ્ટ્ર ઉવાચ\nધર્મક્ષેત્રે કુરુક્ષેત્રે, સમવેતા યુયુત્સવઃ।\nમામકાઃ(ફ્) પાણ્ડવાશ્ચૈવ, કિમકુર્વત સઞ્જય ॥૧.૧॥",
    
    "સઞ્જય ઉવાચ\nદૃષ્ટ્વા તુ પાણ્ડવાનીકં(વ્ઁ), વ્યૂઢં(ન્) દુર્યોધનસ્તદા।\nઆચાર્યમુપસઙ્ગમ્ય, રાજા વચનમબ્રવીત્ ॥૧.૨॥",
    
    "પશ્યૈતાં(મ્) પાણ્ડુપુત્રાણામ્, આચાર્ય મહતીં(ઞ્) ચમૂમ્।\nવ્યૂઢાં(ન્) દ્રુપદપુત્રેણ, તવ શિષ્યેણ ધીમતા ॥૧.૩॥",
    
    "અત્ર શૂરા મહેષ્વાસા, ભીમાર્જુનસમા યુધિ।\nયુયુધાનો વિરાટશ્ચ, દ્રુપદશ્ચ મહારથઃ ॥૧.૪॥",
    
    "ધૃષ્ટકેતુશ્ચેકિતાનઃ(ખ્), કાશિરાજશ્ચ વીર્યવાન્।\nપુરુજિત્કુન્તિભોજશ્ચ, શૈબ્યશ્ચ નરપુઙ્ગવઃ ॥૧.૫॥",
    
    "યુધામન્યુશ્ચ વિક્રાન્ત, ઉત્તમૌજાશ્ચ વીર્યવાન્।\nસૌભદ્રો દ્રૌપદેયાશ્ચ, સર્વ એવ મહારથાઃ ॥૧.૬॥",
    
    "અસ્માકં(ન્) તુ વિશિષ્ટા યે, તાન્નિબોધ દ્વિજોત્તમ।\nનાયકા મમ સૈન્યસ્ય, સઞ્જ્ઞાર્થં(ન્) તાન્બ્રવીમિ તે ॥૧.૭॥",
    
    "ભવાન્ભીષ્મશ્ચ કર્ણશ્ચ, કૃપશ્ચ સમિતિઞ્જયઃ।\nઅશ્વત્થામા વિકર્ણશ્ચ, સૌમદત્તિસ્તથૈવ ચ ॥૧.૮॥",
    
    "અન્યે ચ બહવઃ(શ્) શૂરા, મદર્થે ત્યક્તજીવિતાઃ।\nનાનાશસ્ત્રપ્રહરણાઃ(સ્), સર્વે યુદ્ધવિશારદાઃ ॥૧.૯॥",
    
    "અપર્યાપ્તં(ન્) તદસ્માકં(મ્), બલં(મ્) ભીષ્માભિરક્ષિતમ્।\nપર્યાપ્તં(ન્) ત્વિદમેતેષાં(મ્), બલં(મ્) ભીમાભિરક્ષિતમ્ ॥૧.૧૦॥",

    "અયનેષુ ચ સર્વેષુ, યથાભાગમવસ્થિતાઃ।\nભીષ્મમેવાભિરક્ષન્તુ, ભવન્તઃ(સ્) સર્વ એવ હિ ॥૧.૧૧॥",

    "તસ્ય સઞ્જનયન્હર્ષં(ઙ્), કુરુવૃદ્ધઃ(ફ્) પિતામહઃ।\nસિંહનાદં(વ્ઁ) વિનદ્યોચ્ચૈઃ(શ્), શઙ્ખં(ન્) દધ્મૌ પ્રતાપવાન્ ॥૧.૧૨॥",

    "તતઃ(શ્) શઙ્ખાશ્ચ ભેર્યશ્ચ, પણવાનકગોમુખાઃ।\nસહસૈવાભ્યહન્યન્ત, સ શબ્દસ્તુમુલોઽભવત્ ॥૧.૧૩॥",

    "તતઃ(શ્) શ્વેતૈર્હયૈર્યુક્તે, મહતિ સ્યન્દને સ્થિતૌ।\nમાધવઃ(ફ્) પાણ્ડવશ્ચૈવ, દિવ્યૌ શઙ્ખૌ પ્રદધ્મતુઃ ॥૧.૧૪॥",

    "પાઞ્ચજન્યં(મ્) હૃષીકેશો, દેવદત્તં(ન્) ધનઞ્જયઃ।\nપૌણ્ડ્રં(ન્) દધ્મૌ મહાશઙ્ખં(મ્), ભીમકર્મા વૃકોદરઃ ॥૧.૧૫॥",

    "અનન્તવિજયં(મ્) રાજા, કુન્તીપુત્રો યુધિષ્ઠિરઃ।\nનકુલઃ(સ્) સહદેવશ્ચ, સુઘોષમણિપુષ્પકૌ ॥૧.૧૬॥",

    "કાશ્યશ્ચ પરમેષ્વાસઃ(શ્), શિખણ્ડી ચ મહારથઃ।\nધૃષ્ટદ્યુમ્નો વિરાટશ્ચ, સાત્યકિશ્ચાપરાજિતઃ ॥૧.૧૭॥",

    "દ્રુપદો દ્રૌપદેયાશ્ચ, સર્વશઃ(ફ્) પૃથિવીપતે।\nસૌભદ્રશ્ચ મહાબાહુઃ(શ્), શઙ્ખાન્દધ્મુઃ(ફ્) પૃથક્ પૃથક્ ॥૧.૧૮॥",

    "સ ઘોષો ધાર્તરાષ્ટ્રાણાં(મ્), હૃદયાનિ વ્યદારયત્।\nનભશ્ચ પૃથિવીં(ઞ્) ચૈવ, તુમુલો વ્યનુનાદયન્ ॥૧.૧૯॥",

    "અથ વ્યવસ્થિતાન્દૃષ્ટ્વા, ધાર્તરાષ્ટ્રાન્કપિધ્વજઃ।\nપ્રવૃત્તે શસ્ત્રસમ્પાતે, ધનુરુદ્યમ્ય પાણ્ડવઃ ॥૧.૨૦॥",

    "હૃષીકેશં(ન્) તદા વાક્યમ્, ઇદમાહ મહીપતે।\nઅર્જુન ઉવાચ\nસેનયોરુભયોર્મધ્યે, રથં(મ્) સ્થાપય મેઽચ્યુત ॥૧.૨૧॥",

    "યાવદેતાન્નિરીક્ષેઽહં(ય્ઁ), યોદ્ધુકામાનવસ્થિતાન્।\nકૈર્મયા સહ યોદ્ધવ્યમ્, અસ્મિન્ રણસમુદ્યમે ॥૧.૨૨॥",

    "યોત્સ્યમાનાનવેક્ષેઽહં(ય્ઁ), ય એતેઽત્ર સમાગતાઃ।\nધાર્તરાષ્ટ્રસ્ય દુર્બુદ્ધેઃ(ર્), યુદ્ધે પ્રિયચિકીર્ષવઃ ॥૧.૨૩॥",

    "સઞ્જય ઉવાચ\nએવમુક્તો હૃષીકેશો, ગુડાકેશેન ભારત।\nસેનયોરુભયોર્મધ્યે, સ્થાપયિત્વા રથોત્તમમ્ ॥૧.૨૪॥",

    "ભીષ્મદ્રોણપ્રમુખતઃ(સ્), સર્વેષાં(ઞ્) ચ મહીક્ષિતામ્।\nઉવાચ પાર્થ પશ્યૈતાન્, સમવેતાન્કુરૂનિતિ ॥૧.૨૫॥",

    "તત્રાપશ્યત્સ્થિતાન્પાર્થઃ(ફ્), પિતૄનથ પિતામહાન્।\nઆચાર્યાન્માતુલાન્ભ્રાતૄન્, પુત્રાન્પૌત્રાન્સખીંસ્તથા ॥૧.૨૬॥",

    "શ્વશુરાન્સુહૃદશ્ચૈવ, સેનયોરુભયોરપિ।\nતાન્સમીક્ષ્ય સ કૌન્તેયઃ(સ્), સર્વાન્બન્ધૂનવસ્થિતાન્ ॥૧.૨૭॥",

    "કૃપયા પરયાવિષ્ટો, વિષીદન્નિદમબ્રવીત્।\nઅર્જુન ઉવાચ\nદૃષ્ટ્વેમં(મ્) સ્વજનં(ઙ્) કૃષ્ણ, યુયુત્સું(મ્) સમુપસ્થિતમ્ ॥૧.૨૮॥",

    "સીદન્તિ મમ ગાત્રાણિ, મુખં(ઞ્) ચ પરિશુષ્યતિ।\nવેપથુશ્ચ શરીરે મે, રોમહર્ષશ્ચ જાયતે ॥૧.૨૯॥",

    "ગાણ્ડીવં(મ્) સ્રંસતે હસ્તાત્, ત્વક્ચૈવ પરિદહ્યતે।\nન ચ શક્નોમ્યવસ્થાતું(મ્), ભ્રમતીવ ચ મે મનઃ ॥૧.૩૦॥",

    "નિમિત્તાનિ ચ પશ્યામિ, વિપરીતાનિ કેશવ।\nન ચ શ્રેયોઽનુપશ્યામિ, હત્વા સ્વજનમાહવે ॥૧.૩૧॥",

    "ન કાઙ્ક્ષે વિજયં(ઙ્) કૃષ્ણ, ન ચ રાજ્યં(મ્) સુખાનિ ચ।\nકિં(ન્) નો રાજ્યેન ગોવિન્દ, કિં(મ્) ભોગૈર્જીવિતેન વા ॥૧.૩૨॥",

    "યેષામર્થે કાઙ્ક્ષિતં(ન્) નો, રાજ્યં(મ્) ભોગાઃ(સ્) સુખાનિ ચ।\nત ઇમેઽવસ્થિતા યુદ્ધે, પ્રાણાંસ્ત્યક્ત્વા ધનાનિ ચ ॥૧.૩૩॥",

    "આચાર્યાઃ(ફ્) પિતરઃ(ફ્) પુત્રાઃ(સ્),‌ તથૈવ ચ પિતામહાઃ।\nમાતુલાઃ(શ્) શ્વશુરાઃ(ફ્) પૌત્રાઃ(શ્), શ્યાલાઃ(સ્) સમ્બન્ધિનસ્તથા ॥૧.૩૪॥",

    "એતાન્ન હન્તુમિચ્છામિ, ઘ્નતોઽપિ મધુસૂદન।\nઅપિ ત્રૈલોક્યરાજ્યસ્ય, હેતોઃ(ખ્) કિં(ન્) નુ મહીકૃતે ॥૧.૩૫॥",

    "નિહત્ય ધાર્તરાષ્ટ્રાન્નઃ(ખ્), કા પ્રીતિઃ(સ્) સ્યાજ્જનાર્દન।\nપાપમેવાશ્રયેદસ્માન્, હત્વૈતાનાતતાયિનઃ ॥૧.૩૬॥",

    "તસ્માન્નાર્હા વયં(મ્) હન્તું(ન્), ધાર્તરાષ્ટ્રાન્સ્વબાન્ધવાન્।\nસ્વજનં(મ્) હિ કથં(મ્) હત્વા, સુખિનઃ(સ્) સ્યામ માધવ ॥૧.૩૭॥",

    "યદ્યપ્યેતે ન પશ્યન્તિ, લોભોપહતચેતસઃ।\nકુલક્ષયકૃતં(ન્) દોષં(મ્), મિત્રદ્રોહે ચ પાતકમ્ ॥૧.૩૮॥",

    "કથં(ન્) ન જ્ઞેયમસ્માભિઃ(ફ્), પાપાદસ્માન્નિવર્તિતુમ્।\nકુલક્ષયકૃતં(ન્) દોષં(મ્) પ્રપશ્યદ્ભિર્જનાર્દન ॥૧.૩૯॥",

    "કુલક્ષયે પ્રણશ્યન્તિ, કુલધર્માઃ(સ્) સનાતનાઃ।\nધર્મે નષ્ટે કુલં(ઙ્) કૃત્સ્નમ્, અધર્મોઽભિભવત્યુત ॥૧.૪૦॥",

    "અધર્માભિભવાત્કૃષ્ણ, પ્રદુષ્યન્તિ કુલસ્ત્રિયઃ।\nસ્ત્રીષુ દુષ્ટાસુ વાર્ષ્ણેય, જાયતે વર્ણસઙ્કરઃ ॥૧.૪૧॥",

    "સઙ્કરો નરકાયૈવ, કુલઘ્નાનાં(ઙ્) કુલસ્ય ચ।\nપતન્તિ પિતરો હ્યેષાં(લ્ઁ), લુપ્તપિણ્ડોદકક્રિયાઃ ॥૧.૪૨॥",

    "દોષૈરેતૈઃ(ખ્) કુલઘ્નાનાં(વ્ઁ) વર્ણસઙ્કરકારકૈઃ।\nઉત્સાદ્યન્તે જાતિધર્માઃ(ખ્), કુલધર્માશ્ચ શાશ્વતાઃ ॥૧.૪૩॥",

    "ઉત્સન્નકુલધર્માણાં(મ્), મનુષ્યાણાં(ઞ્) જનાર્દન।\nનરકેઽનિયતં(વ્ઁ) વાસો, ભવતીત્યનુશુશ્રુમ ॥૧.૪૪॥",

    "અહો બત મહત્પાપં(ઙ્), કર્તું(વ્ઁ) વ્યવસિતા વયમ્।\nયદ્રાજ્યસુખલોભેન, હન્તું(મ્) સ્વજનમુદ્યતાઃ ॥૧.૪૫॥",

    "યદિ મામપ્રતીકારમ્, અશસ્ત્રં(મ્) શસ્ત્રપાણયઃ।\nધાર્તરાષ્ટ્રા રણે હન્યુઃ(સ્), તન્મે ક્ષેમતરં(મ્) ભવેત્ ॥૧.૪૬॥",

    "સઞ્જય ઉવાચ\nએવમુક્ત્વાર્જુનઃ(સ્) સઙ્ખ્યે, રથોપસ્થ ઉપાવિશત્।\nવિસૃજ્ય સશરં(ઞ્) ચાપં(મ્), શોકસંવિગ્નમાનસઃ ॥૧.૪૭॥",

    "ૐ તત્સદિતિ શ્રીમદ્ભગવદ્ગીતાસુ ઉપનિષત્સુ બ્રહ્મવિદ્યાયાં(ય્ઁ) યોગશાસ્ત્રે\nશ્રીકૃષ્ણાર્જુનસંવાદે અર્જુનવિષાદયોગો નામ પ્રથમોઽધ્યાયઃ ॥૧॥"
  ],
  "adhyaya-2" : [
    "સઞ્જય ઉવાચ\nતં(ન્) તથા કૃપયાવિષ્ટમ્, અશ્રુપૂર્ણાકુલેક્ષણમ્।\nવિષીદન્તમિદં(વ્ઁ) વાક્યમ્, ઉવાચ મધુસૂદનઃ ॥૨.૧॥",

    "શ્રીભગવાનુવાચ\nકુતસ્ત્વા કશ્મલમિદં(વ્ઁ) વિષમે સમુપસ્થિતમ્।\nઅનાર્યજુષ્ટમસ્વર્ગ્યમ્, અકીર્તિકરમર્જુન ॥૨.૨॥",

    "ક્લૈબ્યં(મ્) મા સ્મ ગમઃ(ફ્) પાર્થ, નૈતત્ત્વય્યુપપદ્યતે।\nક્ષુદ્રં(મ્) હૃદયદૌર્બલ્યં(ન્), ત્યક્ત્વોત્તિષ્ઠ પરન્તપ ॥૨.૩॥",

    "અર્જુન ઉવાચ\nકથં(મ્) ભીષ્મમહં(મ્) સઙ્ખ્યે, દ્રોણં(ઞ્) ચ મધુસૂદન।\nઇષુભિઃ(ફ્) પ્રતિ યોત્સ્યામિ, પૂજાર્હાવરિસૂદન ॥૨.૪॥",

    "ગુરૂનહત્વા હિ મહાનુભાવાન્,\nશ્રેયો ભોક્તું(મ્) ભૈક્ષ્યમપીહ લોકે।\nહત્વાર્થકામાંસ્તુ ગુરૂનિહૈવ,\nભુઞ્જીય ભોગાન્ રુધિરપ્રદિગ્ધાન્ ॥૨.૫॥",

    "ન ચૈતદ્વિદ્મઃ(ખ્) કતરન્નો ગરીયો,\nયદ્વા જયેમ યદિ વા નો જયેયુઃ।\nયાનેવ હત્વા ન જિજીવિષામઃ(સ્),\nતેઽવસ્થિતાઃ(ફ્) પ્રમુખે ધાર્તરાષ્ટ્રાઃ ॥૨.૬॥",

    "કાર્પણ્યદોષોપહતસ્વભાવઃ(ફ્),\nપૃચ્છામિ ત્વાં(ન્) ધર્મસમ્મૂઢચેતાઃ।\nયચ્છ્રેયઃ(સ્) સ્યાન્નિશ્ચિતં(મ્) બ્રૂહિતન્મે,\nશિષ્યસ્તેઽહં(મ્) શાધિ માં(ન્) ત્વાં(મ્) પ્રપન્નમ્ ॥૨.૭॥",

    "ન હિ પ્રપશ્યામિ મમાપનુદ્યાદ્,\nયચ્છોકમુચ્છોષણમિન્દ્રિયાણામ્।\nઅવાપ્ય ભૂમાવસપત્નમૃદ્ધં(મ્),\nરાજ્યં(મ્) સુરાણામપિ ચાધિપત્યમ્ ॥૨.૮॥",

    "સઞ્જય ઉવાચ\nએવમુક્ત્વા હૃષીકેશં(ઙ્), ગુડાકેશઃ(ફ્) પરન્તપ।\nન યોત્સ્ય ઇતિ ગોવિન્દમ્, ઉક્ત્વા તૂષ્ણીં(મ્) બભૂવ હ ॥૨.૯॥",

    "તમુવાચ હૃષીકેશઃ(ફ્), પ્રહસન્નિવ ભારત।\nસેનયોરુભયોર્મધ્યે, વિષીદન્તમિદં(વ્ઁ) વચઃ ॥૨.૧૦॥",

    "શ્રીભગવાનુવાચ\nઅશોચ્યાનન્વશોચસ્ત્વં(મ્), પ્રજ્ઞાવાદાંશ્ચ ભાષસે।\nગતાસૂનગતાસૂંશ્ચ, નાનુશોચન્તિ પણ્ડિતાઃ ॥૨.૧૧॥",

    "ન ત્વેવાહં(ઞ્) જાતુ નાસં(ન્), ન ત્વં(ન્) નેમે જનાધિપાઃ।\nન ચૈવ ન ભવિષ્યામઃ(સ્), સર્વે વયમતઃ(ફ્) પરમ્ ॥૨.૧૨॥",

    "દેહિનોઽસ્મિન્યથા દેહે, કૌમારં(ય્ઁ) યૌવનં(ઞ્) જરા।\nતથા દેહાન્તરપ્રાપ્તિઃ(ર્), ધીરસ્તત્ર ન મુહ્યતિ ॥૨.૧૩॥",

    "માત્રાસ્પર્શાસ્તુ કૌન્તેય, શીતોષ્ણસુખદુઃખદાઃ।\nઆગમાપાયિનોઽનિત્યાઃ(સ્), તાંસ્તિતિક્ષસ્વ ભારત ॥૨.૧૪॥",

    "યં(મ્) હિ ન વ્યથયન્ત્યેતે, પુરુષં(મ્) પુરુષર્ષભ।\nસમદુઃખસુખં(ન્) ધીરં(મ્), સોઽમૃતત્વાય કલ્પતે ॥૨.૧૫॥",

    "નાસતો વિદ્યતે ભાવો, નાભાવો વિદ્યતે સતઃ।\nઉભયોરપિ દૃષ્ટોઽન્તઃ(સ્), ત્વનયોસ્તત્ત્વદર્શિભિઃ ॥૨.૧૬॥",

    "અવિનાશિ તુ તદ્વિદ્ધિ, યેન સર્વમિદં(ન્) તતમ્।\nવિનાશમવ્યયસ્યાસ્ય, ન કશ્ચિત્કર્તુમર્હતિ ॥૨.૧૭॥",

    "અન્તવન્ત ઇમે દેહા, નિત્યસ્યોક્તાઃ(શ્) શરીરિણઃ।\nઅનાશિનોઽપ્રમેયસ્ય, તસ્માદ્યુધ્યસ્વ ભારત ॥૨.૧૮॥",

    "ય એનં(વ્ઁ) વેત્તિ હન્તારં(ય્ઁ), યશ્ચૈનં(મ્) મન્યતે હતમ્।\nઉભૌ તૌ ન વિજાનીતો, નાયં(મ્) હન્તિ ન હન્યતે ॥૨.૧૯॥",

    "ન જાયતે મ્રિયતે વા કદાચિન્, \nનાયં(મ્) ભૂત્વા ભવિતા વા ન ભૂયઃ।\nઅજો નિત્યઃ(શ્) શાશ્વતોઽયં(મ્) પુરાણો,\nન હન્યતે હન્યમાને શરીરે ॥૨.૨૦॥",

    "વેદાવિનાશિનં(ન્) નિત્યં(ય્ઁ), ય એનમજમવ્યયમ્।\nકથં(મ્) સ પુરુષઃ(ફ્) પાર્થ, કં(ઙ્) ઘાતયતિ હન્તિ કમ્ ॥૨.૨૧॥",

    "વાસાંસિ જીર્ણાનિ યથા વિહાય,\nનવાનિ ગૃહ્ણાતિ નરોઽપરાણિ।\nતથા શરીરાણિ વિહાય જીર્ણા-,\nન્યન્યાનિ સંયાતિ નવાનિ દેહી ॥૨.૨૨॥",

    "નૈનં(ઞ્) છિન્દન્તિ શસ્ત્રાણિ, નૈનં(ન્) દહતિ પાવકઃ।\nન ચૈનં(ઙ્) ક્લેદયન્ત્યાપો, ન શોષયતિ મારુતઃ ॥૨.૨૩॥",

    "અચ્છેદ્યોઽયમદાહ્યોઽયમ્, અક્લેદ્યોઽશોષ્ય એવ ચ।\nનિત્યઃ(સ્) સર્વગતઃ(સ્) સ્થાણુઃ(ર્), અચલોઽયં(મ્) સનાતનઃ ॥૨.૨૪॥",

    "અવ્યક્તોઽયમચિન્ત્યોઽયમ્, અવિકાર્યોઽયમુચ્યતે।\nતસ્માદેવં(વ્ઁ) વિદિત્વૈનં(ન્), નાનુશોચિતુમર્હસિ ॥૨.૨૫॥",

    "અથ ચૈનં(ન્) નિત્યજાતં(ન્), નિત્યં(વ્ઁ) વા મન્યસે મૃતમ્।\nતથાપિ ત્વં(મ્) મહાબાહો, નૈવં(મ્) શોચિતુમર્હસિ ॥૨.૨૬॥",

    "જાતસ્ય હિ ધ્રુવો મૃત્યુઃ(ર્), ધ્રુવં(ઞ્) જન્મ મૃતસ્ય ચ।\nતસ્માદપરિહાર્યેઽર્થે, ન ત્વં(મ્) શોચિતુમર્હસિ ॥૨.૨૭॥",

    "અવ્યક્તાદીનિ ભૂતાનિ, વ્યક્તમધ્યાનિ ભારત।\nઅવ્યક્તનિધનાન્યેવ, તત્ર કા પરિદેવના ॥૨.૨૮॥",

    "આશ્ચર્યવત્પશ્યતિ કશ્ચિદેનમ્,\nઆશ્ચર્યવદ્વદતિ તથૈવ ચાન્યઃ।\nઆશ્ચર્યવચ્ચૈનમન્યઃ(શ્) શ્રૃણોતિ,\nશ્રુત્વાપ્યેનં(વ્ઁ) વેદ ન ચૈવ કશ્ચિત્ ॥૨.૨૯॥",

    "દેહી નિત્યમવધ્યોઽયં(ન્), દેહે સર્વસ્ય ભારત।\nતસ્માત્સર્વાણિ ભૂતાનિ, ન ત્વં(મ્) શોચિતુમર્હસિ ॥૨.૩૦॥",

    "સ્વધર્મમપિ ચાવેક્ષ્ય, ન વિકમ્પિતુમર્હસિ।\nધર્મ્યાદ્ધિ યુદ્ધાચ્છ્રેયોઽન્યત્, ક્ષત્રિયસ્ય ન વિદ્યતે ॥૨.૩૧॥",

    "યદૃચ્છયા ચોપપન્નં(મ્), સ્વર્ગદ્વારમપાવૃતમ્।\nસુખિનઃ ક્ષત્રિયાઃ(ફ્) પાર્થ, લભન્તે યુદ્ધમીદૃશમ્ ॥૨.૩૨॥",

    "અથ ચેત્ત્વમિમં(ન્) ધર્મ્યં(મ્), સંગ્રામં(ન્) ન કરિષ્યસિ।\nતતઃ(સ્) સ્વધર્મં(ઙ્) કીર્તિં(ઞ્) ચ, હિત્વા પાપમવાપ્સ્યસિ ॥૨.૩૩॥",

    "અકીર્તિં(ઞ્) ચાપિ ભૂતાનિ, કથયિષ્યન્તિ તેઽવ્યયામ્।\nસમ્ભાવિતસ્ય ચાકીર્તિઃ(ર્), મરણાદતિરિચ્યતે ॥૨.૩૪॥",

    "ભયાદ્રણાદુપરતં(મ્), મંસ્યન્તે ત્વાં(મ્) મહારથાઃ।\nયેષાં(ઞ્) ચ ત્વં(મ્) બહુમતો, ભૂત્વા યાસ્યસિ લાઘવમ્ ॥૨.૩૫॥",

    "અવાચ્યવાદાંશ્ચ બહૂન્, વદિષ્યન્તિ તવાહિતાઃ।\nનિન્દન્તસ્તવ સામર્થ્યં(ન્), તતો દુઃખતરં(ન્) નુ કિમ્ ॥૨.૩૬॥",

    "હતો વા પ્રાપ્સ્યસિ સ્વર્ગં(ઞ્), જિત્વા વા ભોક્ષ્યસે મહીમ્।\nતસ્માદુત્તિષ્ઠ કૌન્તેય, યુદ્ધાય કૃતનિશ્ચયઃ ॥૨.૩૭॥",

    "સુખદુઃખે સમે કૃત્વા, લાભાલાભૌ જયાજયૌ।\nતતો યુદ્ધાય યુજ્યસ્વ, નૈવં(મ્) પાપમવાપ્સ્યસિ ॥૨.૩૮॥",

    "એષા તેઽભિહિતા સાઙ્ખ્યે, બુદ્ધિર્યોગે ત્વિમાં(મ્) ણુ।\nબુદ્ધ્યા યુક્તો યયા પાર્થ, કર્મબન્ધં(મ્) પ્રહાસ્યસિ ॥૨.૩૯॥",

    "નેહાભિક્રમનાશોઽસ્તિ, પ્રત્યવાયો ન વિદ્યતે।\nસ્વલ્પમપ્યસ્ય ધર્મસ્ય, ત્રાયતે મહતો ભયાત્ ॥૨.૪૦॥",

    "વ્યવસાયાત્મિકા બુદ્ધિઃ(ર્), એકેહ કુરુનન્દન।\nબહુશાખા હ્યનન્તાશ્ચ, બુદ્ધયોઽવ્યવસાયિનામ્ ॥૨.૪૧॥",

    "યામિમાં(મ્) પુષ્પિતાં(વ્ઁ) વાચં(મ્), પ્રવદન્ત્યવિપશ્ચિતઃ।\nવેદવાદરતાઃ(ફ્) પાર્થ, નાન્યદસ્તીતિ વાદિનઃ ॥૨.૪૨॥",

    "કામાત્માનઃ(સ્) સ્વર્ગપરા, જન્મકર્મફલપ્રદામ્।\nક્રિયાવિશેષબહુલાં(મ્), ભોગૈશ્વર્યગતિં(મ્) પ્રતિ ॥૨.૪૩॥",

    "ભોગૈશ્વર્યપ્રસક્તાનાં(ન્), તયાપહૃતચેતસામ્।\nવ્યવસાયાત્મિકા બુદ્ધિઃ(સ્), સમાધૌ ન વિધીયતે ॥૨.૪૪॥",

    "ત્રૈગુણ્યવિષયા વેદા, નિસ્ત્રૈગુણ્યો ભવાર્જુન।\nનિર્દ્વન્દ્વો નિત્યસત્ત્વસ્થો, નિર્યોગક્ષેમ આત્મવાન્ ॥૨.૪૫॥",

    "યાવાનર્થ ઉદપાને, સર્વતઃ(સ્) સમ્પ્લુતોદકે।\nતાવાન્સર્વેષુ વેદેષુ, બ્રાહ્મણસ્ય વિજાનતઃ ॥૨.૪૬॥",

    "કર્મણ્યેવાધિકારસ્તે, મા ફલેષુ કદાચન।\nમા કર્મફલહેતુર્ભૂઃ(ર્), મા તે સઙ્ગોઽસ્ત્વકર્મણિ ॥૨.૪૭॥",

    "યોગસ્થઃ(ખ્) કુરુ કર્માણિ, સઙ્ગં(ન્) ત્યક્ત્વા ધનઞ્જય।\nસિદ્ધ્યસિદ્ધ્યોઃ(સ્) સમો ભૂત્વા, સમત્વં(ય્ઁ) યોગ ઉચ્યતે ॥૨.૪૮॥",

    "દૂરેણ હ્યવરં(ઙ્) કર્મ, બુદ્ધિયોગાદ્ધનઞ્જય।\nબુદ્ધૌ શરણમન્વિચ્છ, કૃપણાઃ(ફ્) ફલહેતવઃ ॥૨.૪૯॥",

    "બુદ્ધિયુક્તો જહાતીહ, ઉભે સુકૃતદુષ્કૃતે।\nતસ્માદ્યોગાય યુજ્યસ્વ, યોગઃ(ખ્) કર્મસુ કૌશલમ્ ॥૨.૫૦॥",

    "કર્મજં(મ્) બુદ્ધિયુક્તા હિ, ફલં(ન્) ત્યક્ત્વા મનીષિણઃ।\nજન્મબન્ધવિનિર્મુક્તાઃ(ફ્), પદં(ઙ્) ગચ્છન્ત્યનામયમ્ ॥૨.૫૧॥",

    "યદા તે મોહકલિલં(મ્), બુદ્ધિર્વ્યતિતરિષ્યતિ।\nતદા ગન્તાસિ નિર્વેદં(મ્), શ્રોતવ્યસ્ય શ્રુતસ્ય ચ ॥૨.૫૨॥",

    "શ્રુતિવિપ્રતિપન્ના તે, યદા સ્થાસ્યતિ નિશ્ચલા।\nસમાધાવચલા બુદ્ધિઃ(સ્), તદા યોગમવાપ્સ્યસિ ॥૨.૫૩॥",

    "અર્જુન ઉવાચ\nસ્થિતપ્રજ્ઞસ્ય કા ભાષા, સમાધિસ્થસ્ય કેશવ।\nસ્થિતધીઃ(ખ્) કિં(મ્) પ્રભાષેત, કિમાસીત વ્રજેત કિમ્ ॥૨.૫૪॥",

    "શ્રીભગવાનુવાચ\nપ્રજહાતિ યદા કામાન્, સર્વાન્પાર્થ મનોગતાન્।\nઆત્મન્યેવાત્મના તુષ્ટઃ(સ્), સ્થિતપ્રજ્ઞસ્તદોચ્યતે ॥૨.૫૫॥",

    "દુઃખેષ્વનુદ્વિગ્નમનાઃ(સ્), સુખેષુ વિગતસ્પૃહઃ।\nવીતરાગભયક્રોધઃ(સ્), સ્થિતધીર્મુનિરુચ્યતે ॥૨.૫૬॥",

    "યઃ(સ્) સર્વત્રાનભિસ્નેહઃ(સ્), તત્તત્પ્રાપ્ય શુભાશુભમ્।\nનાભિનન્દતિ ન દ્વેષ્ટિ, તસ્ય પ્રજ્ઞા પ્રતિષ્ઠિતા ॥૨.૫૭॥",

    "યદા સંહરતે ચાયં(ઙ્), કૂર્મોઽઙ્ગાનીવ સર્વશઃ।\nઇન્દ્રિયાણીન્દ્રિયાર્થેભ્યઃ(સ્), તસ્ય પ્રજ્ઞા પ્રતિષ્ઠિતા ॥૨.૫૮॥",

    "વિષયા વિનિવર્તન્તે, નિરાહારસ્ય દેહિનઃ।\nરસવર્જં(મ્) રસોઽપ્યસ્ય, પરં(ન્) દૃષ્ટ્વા નિવર્તતે ॥૨.૫૯॥",

    "યતતો હ્યપિ કૌન્તેય, પુરુષસ્ય વિપશ્ચિતઃ।\nઇન્દ્રિયાણિ પ્રમાથીનિ, હરન્તિ પ્રસભં(મ્) મનઃ ॥૨.૬૦॥",

    "તાનિ સર્વાણિ સંયમ્ય, યુક્ત આસીત મત્પરઃ।\nવશે હિ યસ્યેન્દ્રિયાણિ, તસ્ય પ્રજ્ઞા પ્રતિષ્ઠિતા ॥૨.૬૧॥",

    "ધ્યાયતો વિષયાન્પુંસઃ(સ્), સઙ્ગસ્તેષૂપજાયતે।\nસઙ્ગાત્સઞ્જાયતે કામઃ(ખ્), કામાત્ક્રોધોઽભિજાયતે ॥૨.૬૨॥",

    "ક્રોધાદ્ભવતિ સમ્મોહઃ(સ્), સમ્મોહાત્સ્મૃતિવિભ્રમઃ।\nસ્મૃતિભ્રંશાદ્ બુદ્ધિનાશો, બુદ્ધિનાશાત્પ્રણશ્યતિ ॥૨.૬૩॥",

    "રાગદ્વેષવિયુક્તૈસ્તુ, વિષયાનિન્દ્રિયૈશ્ચરન્।\nઆત્મવશ્યૈર્વિધેયાત્મા, પ્રસાદમધિગચ્છતિ ॥૨.૬૪॥",

    "પ્રસાદે સર્વદુઃખાનાં(મ્), હાનિરસ્યોપજાયતે।\nપ્રસન્નચેતસો હ્યાશુ, બુદ્ધિઃ(ફ્) પર્યવતિષ્ઠતે ॥૨.૬૫॥",

    "નાસ્તિ બુદ્ધિરયુક્તસ્ય, ન ચાયુક્તસ્ય ભાવના।\nન ચાભાવયતઃ(શ્) શાન્તિઃ(ર્),અશાન્તસ્ય કુતઃ(સ્) સુખમ્ ॥૨.૬૬॥",

    "ઇન્દ્રિયાણાં(મ્) હિ ચરતાં(ય્ઁ), યન્મનોઽનુવિધીયતે।\nતદસ્ય હરતિ પ્રજ્ઞાં(વ્ઁ) વાયુર્નાવમિવામ્ભસિ ॥૨.૬૭॥",

    "તસ્માદ્યસ્ય મહાબાહો, નિગૃહીતાનિ સર્વશઃ।\nઇન્દ્રિયાણીન્દ્રિયાર્થેભ્યઃ(સ્), તસ્ય પ્રજ્ઞા પ્રતિષ્ઠિતા ॥૨.૬૮॥",

    "યા નિશા સર્વભૂતાનાં(ન્), તસ્યાં(ઞ્) જાગર્તિ સંયમી।\nયસ્યાં(ઞ્) જાગ્રતિ ભૂતાનિ, સા નિશા પશ્યતો મુનેઃ ॥૨.૬૯॥",

    "આપૂર્યમાણમચલપ્રતિષ્ઠં(મ્),\nસમુદ્રમાપઃ(ફ્) પ્રવિશન્તિ યદ્વત્।\nતદ્વત્કામા યં(મ્) પ્રવિશન્તિ સર્વે,\nસ શાન્તિમાપ્નોતિ ન કામકામી ॥૨.૭૦॥",

    "વિહાય કામાન્યઃ(સ્) સર્વાન્, પુમાંશ્ચરતિ નિઃસ્પૃહઃ।\nનિર્મમો નિરહંકારઃ(સ્), સ શાન્તિમધિગચ્છતિ ॥૨.૭૧॥",

    "એષા બ્રાહ્મી સ્થિતિઃ(ફ્) પાર્થ, નૈનાં(મ્) પ્રાપ્ય વિમુહ્યતિ।\nસ્થિત્વાસ્યામન્તકાલેઽપિ, બ્રહ્મનિર્વાણમૃચ્છતિ ॥૨.૭૨॥",

    "ૐ તત્સદિતિ શ્રીમદ્ભગવદ્ગીતાસુ ઉપનિષત્સુ બ્રહ્મવિદ્યાયાં(ય્ઁ) યોગશાસ્ત્રે\nશ્રીકૃષ્ણાર્જુનસંવાદેસાઙ્ખ્યયોગો નામ દ્વિતીયોઽધ્યાયઃ ॥૨॥"
  ],
  "adhyaya-3" : [
    "અર્જુન ઉવાચ\nજ્યાયસી ચેત્કર્મણસ્તે, મતા બુદ્ધિર્જનાર્દન।\nતત્કિં(ઙ્) કર્મણિ ઘોરે માં(ન્), નિયોજયસિ કેશવ ॥૩.૧॥",

    "વ્યામિશ્રેણેવ વાક્યેન, બુદ્ધિં(મ્) મોહયસીવ મે।\nતદેકં(વ્ઁ) વદ નિશ્ચિત્ય, યેન શ્રેયોઽહમાપ્નુયામ્ ॥3.2॥",

    "શ્રીભગવાનુવાચ\nલોકેઽસ્મિન્દ્વિવિધા નિષ્ઠા, પુરા પ્રોક્તા મયાનઘ।\nજ્ઞાનયોગેન સાઙ્ખ્યાનાં(ઙ્), કર્મયોગેન યોગિનામ્ ॥૩.૩॥",

    "ન કર્મણામનારમ્ભાન્, નૈષ્કર્મ્યં(મ્) પુરુષોઽશ્નુતે।\nન ચ સન્ન્યસનાદેવ, સિદ્ધિં(મ્) સમધિગચ્છતિ ॥૩.૪॥",

    "ન હિ કશ્ચિત્ક્ષણમપિ, જાતુ તિષ્ઠત્યકર્મકૃત્।\nકાર્યતે હ્યવશઃ(ખ્) કર્મ, સર્વઃ(ફ્) પ્રકૃતિજૈર્ગુણૈઃ ॥૩.૫॥",

    "કર્મેન્દ્રિયાણિ સંયમ્ય, ય આસ્તે મનસા સ્મરન્।\nઇન્દ્રિયાર્થાન્વિમૂઢાત્મા, મિથ્યાચારઃ(સ્) સ ઉચ્યતે ॥૩.૬॥",

    "યસ્ત્વિન્દ્રિયાણિ મનસા, નિયમ્યારભતેઽર્જુન।\nકર્મેન્દ્રિયૈઃ(ખ્) કર્મયોગમ્, અસક્તઃ(સ્) સ વિશિષ્યતે ॥૩.૭॥",

    "નિયતં(ઙ્) કુરુ કર્મ ત્વં(ઙ્), કર્મ જ્યાયો હ્યકર્મણઃ।\nશરીરયાત્રાપિ ચ તે, ન પ્રસિદ્ધ્યેદકર્મણઃ ॥૩.૮॥",

    "યજ્ઞાર્થાત્કર્મણોઽન્યત્ર, લોકોઽયં(ઙ્) કર્મબન્ધનઃ।\nતદર્થં(ઙ્) કર્મ કૌન્તેય, મુક્તસઙ્ગઃ(સ્) સમાચર ॥૩.૯॥",

    "સહયજ્ઞાઃ(ફ્) પ્રજાઃ(સ્) સૃષ્ટ્વા, પુરોવાચ પ્રજાપતિઃ।\nઅનેન પ્રસવિષ્યધ્વમ્, એષ વોઽસ્ત્વિષ્ટકામધુક્ ॥૩.૧૦॥",

    "દેવાન્ભાવયતાનેન, તે દેવા ભાવયન્તુ વઃ।\nપરસ્પરં(મ્) ભાવયન્તઃ(શ્), શ્રેયઃ(ફ્) પરમવાપ્સ્યથ ॥૩.૧૧॥",

    "ઇષ્ટાન્ભોગાન્હિ વો દેવા, દાસ્યન્તે યજ્ઞભાવિતાઃ।\nતૈર્દત્તાનપ્રદાયૈભ્યો, યો ભુઙ્ક્તે સ્તેન એવ સઃ ॥૩.૧૨॥",

    "યજ્ઞશિષ્ટાશિનઃ(સ્) સન્તો, મુચ્યન્તે સર્વકિલ્બિષૈઃ।\nભુઞ્જતે તે ત્વઘં(મ્) પાપા, યે પચન્ત્યાત્મકારણાત્ ॥૩.૧૩॥",

    "અન્નાદ્ભવન્તિ ભૂતાનિ, પર્જન્યાદન્નસમ્ભવઃ।\nયજ્ઞાદ્ભવતિ પર્જન્યો, યજ્ઞઃ(ખ્) કર્મસમુદ્ભવઃ ॥૩.૧૪॥",

    "કર્મ બ્રહ્મોદ્ભવં(વ્ઁ) વિદ્ધિ, બ્રહ્માક્ષરસમુદ્ભવમ્।\nતસ્માત્સર્વગતં(મ્) બ્રહ્મ, નિત્યં(ય્ઁ) યજ્ઞે પ્રતિષ્ઠિતમ્ ॥3.15॥",

    "એવં(મ્) પ્રવર્તિતં(ઞ્) ચક્રં(ન્), નાનુવર્તયતીહ યઃ।\nઅઘાયુરિન્દ્રિયારામો, મોઘં(મ્) પાર્થ સ જીવતિ ॥૩.૧૬॥",

    "યસ્ત્વાત્મરતિરેવ સ્યાદ્, આત્મતૃપ્તશ્ચ માનવઃ।\nઆત્મન્યેવ ચ સન્તુષ્ટઃ(સ્), તસ્ય કાર્યં(ન્) ન વિદ્યતે ॥૩.૧૭॥",

    "નૈવ તસ્ય કૃતેનાર્થો, નાકૃતેનેહ કશ્ચન।\nન ચાસ્ય સર્વભૂતેષુ, કશ્ચિદર્થવ્યપાશ્રયઃ ॥૩.૧૮॥",

    "તસ્માદસક્તઃ(સ્) સતતં(ઙ્), કાર્યં(ઙ્) કર્મ સમાચર।\nઅસક્તો હ્યાચરન્કર્મ, પરમાપ્નોતિ પૂરુષઃ ॥૩.૧૯॥",

    "કર્મણૈવ હિ સંસિદ્ધિમ્, આસ્થિતા જનકાદયઃ।\nલોકસઙ્ગ્રહમેવાપિ, સમ્પશ્યન્કર્તુમર્હસિ ॥૩.૨૦॥",

    "યદ્યદાચરતિ શ્રેષ્ઠઃ(સ્), તત્તદેવેતરો જનઃ।\nસ યત્પ્રમાણં(ઙ્) કુરુતે, લોકસ્તદનુવર્તતે ॥૩.૨૧॥",

    "ન મે પાર્થાસ્તિ કર્તવ્યં(ન્), ત્રિષુ લોકેષુ કિઞ્ચન।\nનાનવાપ્તમવાપ્તવ્યં(વ્ઁ) વર્ત એવ ચ કર્મણિ ॥૩.૨૨॥",

    "યદિ હ્યહં(ન્) ન વર્તેયં(ઞ્), જાતુ કર્મણ્યતન્દ્રિતઃ।\nમમ વર્ત્માનુવર્તન્તે, મનુષ્યાઃ(ફ્) પાર્થ સર્વશઃ ॥૩.૨૩॥",

    "ઉત્સીદેયુરિમે લોકા, ન કુર્યાં(ઙ્) કર્મ ચેદહમ્।\nસઙ્કરસ્ય ચ કર્તા સ્યામ્, ઉપહન્યામિમાઃ(ફ્) પ્રજાઃ ॥૩.૨૪॥",

    "સક્તાઃ(ખ્) કર્મણ્યવિદ્વાંસો, યથા કુર્વન્તિ ભારત।\nકુર્યાદ્વિદ્વાંસ્તથાસક્તઃ(શ્), ચિકીર્ષુર્લોકસઙ્ગ્રહમ્ ॥૩.૨૫॥",

    "ન બુદ્ધિભેદં(ઞ્) જનયેદ્, અજ્ઞાનાં(ઙ્) કર્મસઙ્ગિનામ્।\nજોષયેત્સર્વકર્માણિ, વિદ્વાન્યુક્તઃ(સ્) સમાચરન્ ॥૩.૨૬॥",

    "પ્રકૃતેઃ(ખ્) ક્રિયમાણાનિ, ગુણૈઃ(ખ્) કર્માણિ સર્વશઃ।\nઅહઙ્કારવિમૂઢાત્મા, કર્તાહમિતિ મન્યતે ॥૩.૨૭॥",

    "તત્ત્વવિત્તુ મહાબાહો, ગુણકર્મવિભાગયોઃ।\nગુણા ગુણેષુ વર્તન્ત, ઇતિ મત્વા ન સજ્જતે ॥૩.૨૮॥",

    "પ્રકૃતેર્ગુણસમ્મૂઢાઃ(સ્), સજ્જન્તે ગુણકર્મસુ।\nતાનકૃત્સ્નવિદો મન્દાન્, કૃત્સ્નવિન્ન વિચાલયેત્ ॥૩.૨૯॥",

    "મયિ સર્વાણિ કર્માણિ, સન્ન્યસ્યાધ્યાત્મચેતસા।\nનિરાશીર્નિર્મમો ભૂત્વા, યુધ્યસ્વ વિગતજ્વરઃ ॥૩.૩૦॥",

    "યે મે મતમિદં(ન્) નિત્યમ્, અનુતિષ્ઠન્તિ માનવાઃ।\nશ્રદ્ધાવન્તોઽનસૂયન્તો, મુચ્યન્તે તેઽપિ કર્મભિઃ ॥૩.૩૧॥",

    "યે ત્વેતદભ્યસૂયન્તો, નાનુતિષ્ઠન્તિ મે મતમ્।\nસર્વજ્ઞાનવિમૂઢાંસ્તાન્, વિદ્ધિ નષ્ટાનચેતસઃ ॥૩.૩૨॥",

    "સદૃશં(ઞ્) ચેષ્ટતે સ્વસ્યાઃ(ફ્), પ્રકૃતેર્જ્ઞાનવાનપિ।\nપ્રકૃતિં(ય્ઁ) યાન્તિ ભૂતાનિ, નિગ્રહઃ(ખ્) કિં(ઙ્) કરિષ્યતિ ॥3.33॥",

    "ઇન્દ્રિયસ્યેન્દ્રિયસ્યાર્થે, રાગદ્વેષૌ વ્યવસ્થિતૌ।\nતયોર્ન વશમાગચ્છેત્, તૌ હ્યસ્ય પરિપન્થિનૌ ॥૩.૩૪॥",

    "શ્રેયાન્સ્વધર્મો વિગુણઃ(ફ્), પરધર્માત્સ્વનુષ્ઠિતાત્।\nસ્વધર્મે નિધનં(મ્) શ્રેયઃ(ફ્), પરધર્મો ભયાવહઃ ॥૩.૩૫॥",

    "અર્જુન ઉવાચ\nઅથ કેન પ્રયુક્તોઽયં(મ્), પાપં(ઞ્) ચરતિ પૂરુષઃ।\nઅનિચ્છન્નપિ વાર્ષ્ણેય, બલાદિવ નિયોજિતઃ ॥૩.૩૬॥",

    "શ્રીભગવાનુવાચ\nકામ એષ ક્રોધ એષ, રજોગુણસમુદ્ભવઃ।\nમહાશનો મહાપાપ્મા, વિદ્ધ્યેનમિહ વૈરિણમ્ ॥૩.૩૭॥",

    "ધૂમેનાવ્રિયતે વહ્નિઃ(ર્), યથાદર્શો મલેન ચ।\nયથોલ્બેનાવૃતો ગર્ભઃ(સ્), તથા તેનેદમાવૃતમ્ ॥૩.૩૮॥",

    "આવૃતં(ઞ્) જ્ઞાનમેતેન, જ્ઞાનિનો નિત્યવૈરિણા।\nકામરૂપેણ કૌન્તેય, દુષ્પૂરેણાનલેન ચ ॥૩.૩૯॥",

    "ઇન્દ્રિયાણિ મનો બુદ્ધિઃ(ર્), અસ્યાધિષ્ઠાનમુચ્યતે।\nએતૈર્વિમોહયત્યેષ, જ્ઞાનમાવૃત્ય દેહિનમ્ ॥૩.૪૦॥",

    "તસ્માત્ત્વમિન્દ્રિયાણ્યાદૌ, નિયમ્ય ભરતર્ષભ।\nપાપ્માનં(મ્) પ્રજહિ હ્યેનં(ઞ્), જ્ઞાનવિજ્ઞાનનાશનમ્ ॥૩.૪૧॥",

    "ઇન્દ્રિયાણિ પરાણ્યાહુઃ(ર્), ઇન્દ્રિયેભ્યઃ(ફ્) પરં(મ્) મનઃ।\nમનસસ્તુ પરા બુદ્ધિઃ(ર્), યો બુદ્ધેઃ(ફ્) પરતસ્તુ સઃ ॥૩.૪૨॥",

    "એવં(મ્) બુદ્ધેઃ(ફ્) પરં(મ્) બુદ્ધ્વા, સંસ્તભ્યાત્માનમાત્મના।\nજહિ શત્રું(મ્) મહાબાહો, કામરૂપં(ન્) દુરાસદમ્ ॥૩.૪૩॥",

    "ૐ તત્સદિતિ શ્રીમદ્ભગવદ્ગીતાસુ ઉપનિષત્સુ બ્રહ્મવિદ્યાયાં(ય્ઁ)\nયોગશાસ્ત્રે શ્રીકૃષ્ણાર્જુનસંવાદે કર્મયોગો નામ તૃતીયોઽધ્યાયઃ ॥૩॥"
  ],
  "adhyaya-4" : [
    "શ્રીભગવાનુવાચ\nઇમં(વ્ઁ) વિવસ્વતે યોગં(મ્), પ્રોક્તવાનહમવ્યયમ્।\nવિવસ્વાન્મનવે પ્રાહ, મનુરિક્ષ્વાકવેઽબ્રવીત્ ॥૪.૧॥",
    
    "એવં(મ્) પરમ્પરાપ્રાપ્તમ્, ઇમં (મ્) રાજર્ષયો વિદુઃ।\nસ કાલેનેહ મહતા, યોગો નષ્ટઃ(ફ્) પરન્તપ ॥૪.૨॥",
    
    "સ એવાયં(મ્) મયા તેઽદ્ય, યોગઃ(ફ્) પ્રોક્તઃ(ફ્) પુરાતનઃ।\nભક્તોઽસિ મે સખા ચેતિ, રહસ્યં(મ્) હ્યેતદુત્તમમ્ ॥૪.૩॥",
    
    "અર્જુન ઉવાચ\nઅપરં(મ્) ભવતો જન્મ, પરં(ઞ્) જન્મ વિવસ્વતઃ।\nકથમેતદ્વિજાનીયાં(ન્), ત્વમાદૌ પ્રોક્તવાનિતિ ॥૪.૪॥",
    
    "શ્રીભગવાનુવાચ\nબહૂનિ મે વ્યતીતાનિ, જન્માનિ તવ ચાર્જુન।\nતાન્યહં(વ્ઁ) વેદ સર્વાણિ, ન ત્વં(વ્ઁ) વેત્થ પરન્તપ ॥૪.૫॥",
    
    "અજોઽપિ સન્નવ્યયાત્મા, ભૂતાનામીશ્વરોઽપિ સન્।\nપ્રકૃતિં(મ્) સ્વામધિષ્ઠાય, સમ્ભવામ્યાત્મમાયયા ॥૪.૬॥",
    
    "યદા યદા હિ ધર્મસ્ય, ગ્લાનિર્ભવતિ ભારત।\nઅભ્યુત્થાનમધર્મસ્ય, તદાત્માનં(મ્) સૃજામ્યહમ્ ॥૪.૭॥",
    
    "પરિત્રાણાય સાધૂનાં(વ્ઁ) વિનાશાય ચ દુષ્કૃતામ્।\nધર્મસંસ્થાપનાર્થાય, સમ્ભવામિ યુગે યુગે ॥૪.૮॥",
    
    "જન્મ કર્મ ચ મે દિવ્યમ્, એવં(ય્ઁ) યો વેત્તિ તત્ત્વતઃ।\nત્યક્ત્વા દેહં(મ્) પુનર્જન્મ, નૈતિ મામેતિ સોઽર્જુન ॥૪.૯॥",
    
    "વીતરાગભયક્રોધા, મન્મયા મામુપાશ્રિતાઃ।\nબહવો જ્ઞાનતપસા, પૂતા મદ્ભાવમાગતાઃ ॥૪.૧૦॥",

    "યે યથા માં(મ્) પ્રપદ્યન્તે, તાંસ્તથૈવ ભજામ્યહમ્।\nમમ વર્ત્માનુવર્તન્તે, મનુષ્યાઃ(ફ્) પાર્થ સર્વશઃ ॥૪.૧૧॥",

    "કાઙ્ક્ષન્તઃ(ખ્) કર્મણાં(મ્) સિદ્ધિં(ય્ઁ), યજન્ત ઇહ દેવતાઃ।\nક્ષિપ્રં(મ્) હિ માનુષે લોકે, સિદ્ધિર્ભવતિ કર્મજા ॥૪.૧૨॥",

    "ચાતુર્વર્ણ્યં(મ્) મયા સૃષ્ટં(ઙ્), ગુણકર્મવિભાગશઃ।\nતસ્ય કર્તારમપિ માં(વ્ઁ) વિદ્ધ્યકર્તારમવ્યયમ્ ॥૪.૧૩॥",

    "ન માં(ઙ્) કર્માણિ લિમ્પન્તિ, ન મે કર્મફલે સ્પૃહા।\nઇતિ માં(ય્ઁ) યોઽભિજાનાતિ, કર્મભિર્ન સ બધ્યતે ॥૪.૧૪॥",

    "એવં(ઞ્) જ્ઞાત્વા કૃતં(ઙ્) કર્મ, પૂર્વૈરપિ મુમુક્ષુભિઃ।\nકુરુ કર્મૈવ તસ્માત્ત્વં(મ્), પૂર્વૈઃ(ફ્) પૂર્વતરં(ઙ્) કૃતમ્ ॥૪.૧૫॥",

    "કિં(ઙ્) કર્મ કિમકર્મેતિ, કવયોઽપ્યત્ર મોહિતાઃ।\nતત્તે કર્મ પ્રવક્ષ્યામિ, યજ્જ્ઞાત્વા મોક્ષ્યસેઽશુભાત્ ॥૪.૧૬॥",

    "કર્મણો હ્યપિ બોદ્ધવ્યં(મ્), બોદ્ધવ્યં(ઞ્) ચ વિકર્મણઃ।\nઅકર્મણશ્ચ બોદ્ધવ્યં(ઙ્), ગહના કર્મણો ગતિઃ ॥૪.૧૭॥",

    "કર્મણ્યકર્મ યઃ(ફ્) પશ્યેદ્, અકર્મણિ ચ કર્મ યઃ।\nસ બુદ્ધિમાન્મનુષ્યેષુ, સ યુક્તઃ(ખ્) કૃત્સ્નકર્મકૃત્ ॥૪.૧૮॥",

    "યસ્ય સર્વે સમારમ્ભાઃ(ખ્), કામસઙ્કલ્પવર્જિતાઃ।\nજ્ઞાનાગ્નિદગ્ધકર્માણં(ન્), તમાહુઃ(ફ્) પણ્ડિતં(મ્) બુધાઃ ॥૪.૧૯॥",

    "ત્યક્ત્વા કર્મફલાસઙ્ગં(ન્), નિત્યતૃપ્તો નિરાશ્રયઃ।\nકર્મણ્યભિપ્રવૃત્તોઽપિ, નૈવ કિઞ્ચિત્કરોતિ સઃ ॥૪.૨૦॥",

    "નિરાશીર્યતચિત્તાત્મા, ત્યક્તસર્વપરિગ્રહઃ।\nશારીરં(ઙ્) કેવલં(ઙ્) કર્મ, કુર્વન્નાપ્નોતિ કિલ્બિષમ્ ॥૪.૨૧॥",

    "યદૃચ્છાલાભસન્તુષ્ટો, દ્વન્દ્વાતીતો વિમત્સરઃ।\nસમઃ(સ્) સિદ્ધાવસિદ્ધૌ ચ, કૃત્વાપિ ન નિબધ્યતે ॥૪.૨૨॥",

    "ગતસઙ્ગસ્ય મુક્તસ્ય, જ્ઞાનાવસ્થિતચેતસઃ।\nયજ્ઞાયાચરતઃ(ખ્) કર્મ, સમગ્રં(મ્) પ્રવિલીયતે ॥૪.૨૩॥",

    "બ્રહ્માર્પણં(મ્) બ્રહ્મ હવિઃ(ર્), બ્રહ્માગ્નૌ બ્રહ્મણા હુતમ્।\nબ્રહ્મૈવ તેન ગન્તવ્યં(મ્), બ્રહ્મકર્મસમાધિના ॥૪.૨૪॥",

    "દૈવમેવાપરે યજ્ઞં(ય્ઁ), યોગિનઃ(ફ્) પર્યુપાસતે।\nબ્રહ્માગ્નાવપરે યજ્ઞં(ય્ઁ), યજ્ઞેનૈવોપજુહ્વતિ ॥૪.૨૫॥",

    "શ્રોત્રાદીનીન્દ્રિયાણ્યન્યે, સંયમાગ્નિષુ જુહ્વતિ।\nશબ્દાદીન્વિષયાનન્ય, ઇન્દ્રિયાગ્નિષુ જુહ્વતિ ॥૪.૨૬॥",

    "સર્વાણીન્દ્રિયકર્માણિ, પ્રાણકર્માણિ ચાપરે।\nઆત્મસંયમયોગાગ્નૌ, જુહ્વતિ જ્ઞાનદીપિતે ॥૪.૨૭॥",

    "દ્રવ્યયજ્ઞાસ્તપોયજ્ઞા, યોગયજ્ઞાસ્તથાપરે।\nસ્વાધ્યાયજ્ઞાનયજ્ઞાશ્ચ, યતયઃ(સ્) સંશિતવ્રતાઃ ॥૪.૨૮॥",

    "અપાને જુહ્વતિ પ્રાણં(મ્), પ્રાણેઽપાનં(ન્) તથાપરે।\nપ્રાણાપાનગતી રુદ્ધ્વા, પ્રાણાયામપરાયણાઃ ॥૪.૨૯॥",

    "અપરે નિયતાહારાઃ(ફ્), પ્રાણાન્પ્રાણેષુ જુહ્વતિ।\nસર્વેઽપ્યેતે યજ્ઞવિદો, યજ્ઞક્ષપિતકલ્મષાઃ ॥૪.૩૦॥",

    "યજ્ઞશિષ્ટામૃતભુજો,‌ યાન્તિ બ્રહ્મ સનાતનમ્।\nનાયં(લ્ઁ) લોકોઽસ્ત્યયજ્ઞસ્ય, કુતોઽન્યઃ(ખ્) કુરુસત્તમ ॥૪.૩૧॥",

    "એવં(મ્) બહુવિધા યજ્ઞા, વિતતા બ્રહ્મણો મુખે।\nકર્મજાન્વિદ્ધિ તાન્સર્વાન્, એવં(ઞ્) જ્ઞાત્વા વિમોક્ષ્યસે ॥૪.૩૨॥",

    "શ્રેયાન્દ્રવ્યમયાદ્યજ્ઞાજ્, જ્ઞાનયજ્ઞઃ(ફ્) પરન્તપ।\nસર્વં(ઙ્) કર્માખિલં(મ્) પાર્થ, જ્ઞાને પરિસમાપ્યતે ॥૪.૩૩॥",

    "તદ્વિદ્ધિ પ્રણિપાતેન, પરિપ્રશ્નેન સેવયા।\nઉપદેક્ષ્યન્તિ તે જ્ઞાનં(ઞ્), જ્ઞાનિનસ્તત્ત્વદર્શિનઃ ॥૪.૩૪॥",

    "યજ્જ્ઞાત્વા ન પુનર્મોહમ્, એવં(ય્ઁ) યાસ્યસિ પાણ્ડવ।\nયેન ભૂતાન્યશેષેણ, દ્રક્ષ્યસ્યાત્મન્યથો મયિ ॥૪.૩૫॥",

    "અપિ ચેદસિ પાપેભ્યઃ(સ્), સર્વેભ્યઃ(ફ્) પાપકૃત્તમઃ।\nસર્વં(ઞ્) જ્ઞાનપ્લવેનૈવ, વૃજિનં(મ્) સન્તરિષ્યસિ ॥૪.૩૬॥",

    "યથૈધાંસિ સમિદ્ધોઽગ્નિઃ(ર્), ભસ્મસાત્કુરુતેઽર્જુન।\nજ્ઞાનાગ્નિઃ(સ્) સર્વકર્માણિ, ભસ્મસાત્કુરુતે તથા ॥૪.૩૭॥",

    "ન હિ જ્ઞાનેન સદૃશં(મ્), પવિત્રમિહ વિદ્યતે।\nતત્સ્વયં(ય્ઁ) યોગસંસિદ્ધઃ(ખ્), કાલેનાત્મનિ વિન્દતિ ॥૪.૩૮॥",

    "શ્રદ્ધાવાઁલ્લભતે જ્ઞાનં(ન્), તત્પરઃ(સ્) સંયતેન્દ્રિયઃ।\nજ્ઞાનં(લ્ઁ) લબ્ધ્વા પરાં(મ્) શાન્તિમ્, અચિરેણાધિગચ્છતિ ॥૪.૩૯॥",

    "અજ્ઞશ્ચાશ્રદ્દધાનશ્ચ, સંશયાત્મા વિનશ્યતિ।\nનાયં(લ્ઁ) લોકોઽસ્તિ ન પરો, ન સુખં(મ્) સંશયાત્મનઃ ॥૪.૪૦॥",

    "યોગસન્ન્યસ્તકર્માણં(ઞ્), જ્ઞાનસઞ્છિન્નસંશયમ્।\nઆત્મવન્તં(ન્) ન કર્માણિ, નિબધ્નન્તિ ધનઞ્જય ॥૪.૪૧॥",

    "તસ્માદજ્ઞાનસમ્ભૂતં(મ્), હૃત્સ્થં(ઞ્) જ્ઞાનાસિનાત્મનઃ।\nછિત્ત્વૈનં(મ્) સંશયં(ય્ઁ) યોગમ્, આતિષ્ઠોત્તિષ્ઠ ભારત ॥૪.૪૨॥",

    "ૐ તત્સદિતિ શ્રીમદ્ભગવદ્ગીતાસુ ઉપનિષત્સુ બ્રહ્મવિદ્યાયાં(ય્ઁ)\nયોગશાસ્ત્રે શ્રીકૃષ્ણાર્જુનસંવાદે જ્ઞાનકર્મસન્ન્યાસયોગો નામ ચતુર્થોઽધ્યાયઃ ॥૪॥"
  ],
  "adhyaya-5" : [
    "અર્જુન ઉવાચ\nસન્ન્યાસં(ઙ્) કર્મણાં(ઙ્) કૃષ્ણ, પુનર્યોગં(ઞ્) ચ શંસસિ।\nયચ્છ્રેય એતયોરેકં(ન્), તન્મે બ્રૂહિ સુનિશ્ચિતમ્ ॥૫.૧॥",

    "શ્રીભગવાનુવાચ\nસન્ન્યાસઃ(ખ્) કર્મયોગશ્ચ, નિઃશ્રેયસકરાવુભૌ।\nતયોસ્તુ કર્મસન્ન્યાસાત્, કર્મયોગો વિશિષ્યતે ॥૫.૨॥",

    "જ્ઞેયઃ(સ્) સ નિત્યસન્ન્યાસી, યો ન દ્વેષ્ટિ ન કાઙ્ક્ષતિ।\nનિર્દ્વન્દ્વો હિ મહાબાહો, સુખં(મ્) બન્ધાત્પ્રમુચ્યતે ॥૫.૩॥",

    "સાઙ્ખ્યયોગૌ પૃથગ્બાલાઃ(ફ્), પ્રવદન્તિ ન પણ્ડિતાઃ।\nએકમપ્યાસ્થિતઃ(સ્) સમ્યગ્, ઉભયોર્વિન્દતે ફલમ્ ॥૫.૪॥",

    "યત્સાઙ્ખ્યૈઃ(ફ્) પ્રાપ્યતે સ્થાનં(ન્), તદ્યોગૈરપિ ગમ્યતે।\nએકં(મ્) સાઙ્ખ્યં(ઞ્) ચ યોગં(ઞ્) ચ, યઃ(ફ્) પશ્યતિ સ પશ્યતિ ॥૫.૫॥",

    "સન્ન્યાસસ્તુ મહાબાહો, દુઃખમાપ્તુમયોગતઃ।\nયોગયુક્તો મુનિર્બ્રહ્મ, નચિરેણાધિગચ્છતિ ॥૫.૬॥",

    "યોગયુક્તો વિશુદ્ધાત્મા, વિજિતાત્મા જિતેન્દ્રિયઃ।\nસર્વભૂતાત્મભૂતાત્મા, કુર્વન્નપિ ન લિપ્યતે ॥૫.૭॥",

    "નૈવ કિઞ્ચિત્કરોમીતિ, યુક્તો મન્યેત તત્ત્વવિત્।\nપશ્યઞ્શૃણ્વન્સ્પૃશઞ્જિઘ્રન્, નશ્નન્ગચ્છન્સ્વપઞ્શ્વસન્ ॥૫.૮॥",

    "પ્રલપન્વિસૃજન્ગૃહ્ણન્, નુન્મિષન્નિમિષન્નપિ।\nઇન્દ્રિયાણીન્દ્રિયાર્થેષુ, વર્તન્ત ઇતિ ધારયન્ ॥૫.૯॥",

    "બ્રહ્મણ્યાધાય કર્માણિ, સઙ્ગં(ન્) ત્યક્ત્વા કરોતિ યઃ।\nલિપ્યતે ન સ પાપેન, પદ્મપત્રમિવામ્ભસા ॥૫.૧૦॥",

    "કાયેન મનસા બુદ્ધ્યા, કેવલૈરિન્દ્રિયૈરપિ।\nયોગિનઃ(ખ્) કર્મ કુર્વન્તિ, સઙ્ગં(ન્) ત્યક્ત્વાત્મશુદ્ધયે ॥૫.૧૧॥",

    "યુક્તઃ(ખ્) કર્મફલં(ન્) ત્યક્ત્વા, શાન્તિમાપ્નોતિ નૈષ્ઠિકીમ્।\nઅયુક્તઃ(ખ્) કામકારેણ, ફલે સક્તો નિબધ્યતે ॥૫.૧૨॥",

    "સર્વકર્માણિ મનસા, સન્ન્યસ્યાસ્તે સુખં(વ્ઁ) વશી।\nનવદ્વારે પુરે દેહી, નૈવ કુર્વન્ન કારયન્ ॥૫.૧૩॥",

    "ન કર્તૃત્વં(ન્) ન કર્માણિ, લોકસ્ય સૃજતિ પ્રભુઃ।\nન કર્મફલસંયોગં(મ્), સ્વભાવસ્તુ પ્રવર્તતે ॥૫.૧૪॥",

    "નાદત્તે કસ્યચિત્પાપં(ન્), ન ચૈવ સુકૃતં(વ્ઁ) વિભુઃ।\nઅજ્ઞાનેનાવૃતં(ઞ્) જ્ઞાનં(ન્), તેન મુહ્યન્તિ જન્તવઃ ॥૫.૧૫॥",

    "જ્ઞાનેન તુ તદજ્ઞાનં(ય્ઁ), યેષાં(ન્) નાશિતમાત્મનઃ।\nતેષામાદિત્યવજ્જ્ઞાનં(મ્), પ્રકાશયતિ તત્પરમ્ ॥૫.૧૬॥",

    "તદ્બુદ્ધયસ્તદાત્માનઃ(સ્), તન્નિષ્ઠાસ્તત્પરાયણાઃ।\nગચ્છન્ત્યપુનરાવૃત્તિં(ઞ્), જ્ઞાનનિર્ધૂતકલ્મષાઃ ॥૫.૧૭॥",

    "વિદ્યાવિનયસમ્પન્ને, બ્રાહ્મણે ગવિ હસ્તિનિ।\nશુનિ ચૈવ શ્વપાકે ચ, પણ્ડિતા:(સ્) સમદર્શિનઃ ॥૫.૧૮॥",

    "ઇહૈવ તૈર્જિતઃ(સ્) સર્ગો, યેષાં(મ્) સામ્યે સ્થિતં(મ્) મનઃ।\nનિર્દોષં(મ્) હિ સમં(મ્) બ્રહ્મ, તસ્માદ્ બ્રહ્મણિ તે સ્થિતાઃ ॥૫.૧૯॥",

    "ન પ્રહૃષ્યેત્પ્રિયં(મ્) પ્રાપ્ય, નોદ્વિજેત્પ્રાપ્ય ચાપ્રિયમ્।\nસ્થિરબુદ્ધિરસમ્મૂઢો, બ્રહ્મવિદ્ બ્રહ્મણિ સ્થિતઃ ॥૫.૨૦॥",

    "બાહ્યસ્પર્શેષ્વસક્તાત્મા, વિન્દત્યાત્મનિ યત્સુખમ્।\nસ બ્રહ્મયોગયુક્તાત્મા, સુખમક્ષયમશ્નુતે ॥૫.૨૧॥",

    "યે હિ સંસ્પર્શજા ભોગા, દુઃખયોનય એવ તે।\nઆદ્યન્તવન્તઃ(ખ્) કૌન્તેય, ન તેષુ રમતે બુધઃ ॥૫.૨૨॥",

    "શક્નોતીહૈવ યઃ(સ્) સોઢું(મ્), પ્રાક્શરીરવિમોક્ષણાત્।\nકામક્રોધોદ્ભવં(વ્ઁ) વેગં(મ્), સ યુક્તઃ(સ્) સ સુખી નરઃ ॥૫.૨૩॥",

    "યોઽન્તઃસુખોઽન્તરારામઃ(સ્), તથાન્તર્જ્યોતિરેવ યઃ।\nસ યોગી બ્રહ્મનિર્વાણં(મ્), બ્રહ્મભૂતોઽધિગચ્છતિ ॥૫.૨૪॥",

    "લભન્તે બ્રહ્મનિર્વાણમ્, ઋષયઃ ક્ષીણકલ્મષાઃ।\nછિન્નદ્વૈધા યતાત્માનઃ(સ્), સર્વભૂતહિતે રતાઃ ॥૫.૨૫॥",

    "કામક્રોધવિયુક્તાનાં(ય્ઁ), યતીનાં(ય્ઁ) યતચેતસામ્।\nઅભિતો બ્રહ્મનિર્વાણં(વ્ઁ) વર્તતે વિદિતાત્મનામ્ ॥૫.૨૬॥",

    "સ્પર્શાન્કૃત્વા બહિર્બાહ્યાંશ્, ચક્ષુશ્ચૈવાન્તરે ભ્રુવોઃ।\nપ્રાણાપાનૌ સમૌ કૃત્વા, નાસાભ્યન્તરચારિણૌ ॥૫.૨૭॥",

    "યતેન્દ્રિયમનોબુદ્ધિઃ(ર્), મુનિર્મોક્ષપરાયણઃ।\nવિગતેચ્છાભયક્રોધો, યઃ(સ્) સદા મુક્ત એવ સઃ ॥૫.૨૮॥",

    "ભોક્તારં(ય્ઁ) યજ્ઞતપસાં(મ્), સર્વલોકમહેશ્વરમ્।\nસુહૃદં(મ્) સર્વભૂતાનાં(ઞ્), જ્ઞાત્વા માં(મ્) શાન્તિમૃચ્છતિ ॥૫.૨૯॥",

    "ૐ તત્સદિતિ શ્રીમદ્ભગવદ્ગીતાસુ ઉપનિષત્સુ બ્રહ્મવિદ્યાયાં(ય્ઁ) યોગશાસ્ત્રે શ્રીકૃષ્ણાર્જુનસંવાદે કર્મસન્ન્યાસયોગો નામ પઞ્ચમોઽધ્યાયઃ ॥૫॥"
  ],
  "adhyaya-6" : [
    "શ્રીભગવાનુવાચ\nઅનાશ્રિતઃ(ખ્) કર્મફલં(ઙ્), કાર્યં(ઙ્) કર્મ કરોતિ યઃ।\nસ સન્ન્યાસી ચ યોગી ચ, ન નિરગ્નિર્ન ચાક્રિયઃ ॥૬.૧॥",

    "યં(મ્) સન્ન્યાસમિતિ પ્રાહુઃ(ર્), યોગં(ન્) તં(વ્ઁ) વિદ્ધિ પાણ્ડવ।\nન હ્યસન્ન્યસ્તસઙ્કલ્પો, યોગી ભવતિ કશ્ચન ॥૬.૨॥",

    "આરુરુક્ષોર્મુનેર્યોગં(ઙ્), કર્મ કારણમુચ્યતે।\nયોગારૂઢસ્ય તસ્યૈવ, શમઃ(ખ્) કારણમુચ્યતે ॥૬.૩॥",

    "યદા હિ નેન્દ્રિયાર્થેષુ, ન કર્મસ્વનુષજ્જતે।\nસર્વસઙ્કલ્પસન્ન્યાસી, યોગારૂઢસ્તદોચ્યતે ॥૬.૪॥",

    "ઉદ્ધરેદાત્મનાત્માનં(ન્), નાત્માનમવસાદયેત્।\nઆત્મૈવ હ્યાત્મનો બન્ધુઃ(ર્), આત્મૈવ રિપુરાત્મનઃ ॥૬.૫॥",

    "બન્ધુરાત્માત્મનસ્તસ્ય, યેનાત્મૈવાત્મના જિતઃ।\nઅનાત્મનસ્તુ શત્રુત્વે, વર્તેતાત્મૈવ શત્રુવત્ ॥૬.૬॥",

    "જિતાત્મનઃ(ફ્) પ્રશાન્તસ્ય, પરમાત્મા સમાહિતઃ।\nશીતોષ્ણસુખદુઃખેષુ, તથા માનાપમાનયોઃ ॥૬.૭॥",

    "જ્ઞાનવિજ્ઞાનતૃપ્તાત્મા, કૂટસ્થો વિજિતેન્દ્રિયઃ।\nયુક્ત ઇત્યુચ્યતે યોગી, સમલોષ્ટાશ્મકાઞ્ચનઃ ॥૬.૮॥",

    "સુહૃન્મિત્રાર્યુદાસીન, મધ્યસ્થદ્વેષ્યબન્ધુષુ।\nસાધુષ્વપિ ચ પાપેષુ, સમબુદ્ધિર્વિશિષ્યતે ॥૬.૯॥",

    "યોગી યુઞ્જીત સતતમ્, આત્માનં(મ્) રહસિ સ્થિતઃ।\nએકાકી યતચિત્તાત્મા, નિરાશીરપરિગ્રહઃ ॥૬.૧૦॥",

    "શુચૌ દેશે પ્રતિષ્ઠાપ્ય, સ્થિરમાસનમાત્મનઃ।\nનાત્યુચ્છ્રિતં(ન્) નાતિનીચં(ઞ્), ચૈલાજિનકુશોત્તરમ્ ॥૬.૧૧॥",

    "તત્રૈકાગ્રં(મ્) મનઃ(ખ્) કૃત્વા, યતચિત્તેન્દ્રિયક્રિયઃ।\nઉપવિશ્યાસને યુઞ્જ્યાદ્, યોગમાત્મવિશુદ્ધયે ॥૬.૧૨॥",

    "સમં(ઙ્) કાયશિરોગ્રીવં(ન્), ધારયન્નચલં(મ્) સ્થિરઃ।\nસમ્પ્રેક્ષ્ય નાસિકાગ્રં(મ્) સ્વં(ન્), દિશશ્ચાનવલોકયન્ ॥૬.૧૩॥",

    "પ્રશાન્તાત્મા વિગતભીઃ(ર્), બ્રહ્મચારિવ્રતે સ્થિતઃ।\nમનઃ(સ્) સંયમ્ય મચ્ચિત્તો, યુક્ત આસીત મત્પરઃ ॥૬.૧૪॥",

    "યુઞ્જન્નેવં(મ્) સદાત્માનં(ય્ઁ), યોગી નિયતમાનસઃ।\nશાન્તિં(ન્) નિર્વાણપરમાં(મ્), મત્સંસ્થામધિગચ્છતિ ॥૬.૧૫॥",

    "નાત્યશ્નતસ્તુ યોગોઽસ્તિ, ન ચૈકાન્તમનશ્નતઃ।\nન ચાતિસ્વપ્નશીલસ્ય, જાગ્રતો નૈવ ચાર્જુન ॥૬.૧૬॥",

    "યુક્તાહારવિહારસ્ય, યુક્તચેષ્ટસ્ય કર્મસુ।\nયુક્તસ્વપ્નાવબોધસ્ય, યોગો ભવતિ દુઃખહા ॥૬.૧૭॥",

    "યદા વિનિયતં(ઞ્) ચિત્તમ્, આત્મન્યેવાવતિષ્ઠતે।\nનિઃસ્પૃહઃ(સ્) સર્વકામેભ્યો, યુક્ત ઇત્યુચ્યતે તદા ॥૬.૧૮॥",

    "યથા દીપો નિવાતસ્થો, નેઙ્ગતે સોપમા સ્મૃતા।\nયોગિનો યતચિત્તસ્ય, યુઞ્જતો યોગમાત્મનઃ ॥૬.૧૯॥",

    "યત્રોપરમતે ચિત્તં(ન્), નિરુદ્ધં(ય્ઁ) યોગસેવયા।\nયત્ર ચૈવાત્મનાત્માનં(મ્), પશ્યન્નાત્મનિ તુષ્યતિ ॥૬.૨૦॥",

    "સુખમાત્યન્તિકં(ય્ઁ) યત્તદ્, બુદ્ધિગ્રાહ્યમતીન્દ્રિયમ્।\nવેત્તિ યત્ર ન ચૈવાયં(મ્), સ્થિતશ્ચલતિ તત્ત્વતઃ ॥૬.૨૧॥",

    "યં(લ્ઁ) લબ્ધ્વા ચાપરં(લ્ઁ) લાભં(મ્), મન્યતે નાધિકં(ન્) તતઃ।\nયસ્મિન્સ્થિતો ન દુઃખેન, ગુરુણાપિ વિચાલ્યતે ॥૬.૨૨॥",

    "તં(વ્ઁ) વિદ્યાદ્ દુઃખસંયોગ, વિયોગં(ય્ઁ) યોગસઞ્જ્ઞિતમ્।\nસ નિશ્ચયેન યોક્તવ્યો, યોગોઽનિર્વિણ્ણચેતસા ॥૬.૨૩॥",

    "સઙ્કલ્પપ્રભવાન્કામાંસ્, ત્યક્ત્વા સર્વાનશેષતઃ।\nમનસૈવેન્દ્રિયગ્રામં(વ્ઁ) વિનિયમ્ય સમન્તતઃ ॥૬.૨૪॥",

    "શનૈઃ(શ્) શનૈરુપરમેદ્, બુદ્ધ્યા ધૃતિગૃહીતયા।\nઆત્મસંસ્થં(મ્) મનઃ(ખ્) કૃત્વા, ન કિઞ્ચિદપિ ચિન્તયેત્ ॥૬.૨૫॥",

    "યતો યતો નિશ્ચરતિ, મનશ્ચઞ્ચલમસ્થિરમ્।\nતતસ્તતો નિયમ્યૈતદ્, આત્મન્યેવ વશં(ન્) નયેત્ ॥૬.૨૬॥",

    "પ્રશાન્તમનસં(મ્) હ્યેનં(ય્ઁ), યોગિનં સુખમુત્તમમ્।\nઉપૈતિ શાન્તરજસં(મ્), બ્રહ્મભૂતમકલ્મષમ્ ॥૬.૨૭॥",

    "યુઞ્જન્નેવં(મ્) સદાત્માનં(ય્ઁ), યોગી વિગતકલ્મષઃ।\nસુખેન બ્રહ્મસંસ્પર્શમ્, અત્યન્તં(મ્) સુખમશ્નુતે ॥૬.૨૮॥",

    "સર્વભૂતસ્થમાત્માનં(મ્), સર્વભૂતાનિ ચાત્મનિ।\nઈક્ષતે યોગયુક્તાત્મા, સર્વત્ર સમદર્શનઃ ॥૬.૨૯॥",

    "યો માં(મ્) પશ્યતિ સર્વત્ર, સર્વં(ઞ્) ચ મયિ પશ્યતિ।\nતસ્યાહં(ન્) ન પ્રણશ્યામિ, સ ચ મે ન પ્રણશ્યતિ ॥૬.૩૦॥",

    "સર્વભૂતસ્થિતં(ય્ઁ) યો માં(મ્), ભજત્યેકત્વમાસ્થિતઃ।\nસર્વથા વર્તમાનોઽપિ, સ યોગી મયિ વર્તતે ॥૬.૩૧॥",

    "આત્મૌપમ્પેન સર્વત્ર સમં પશ્યતિ યોડર્જુન |\nસુખં વા યાદિ વા દુ:ખં(મ્) સ યોગી પરમો મતઃ॥ ૬.૩૨ll",

    "અર્જુન ઉવાચ\nયોઽયં(ય્ઁ) યોગસ્ત્વયા પ્રોક્તઃ(સ્), સામ્યેન મધુસૂદન।\nએતસ્યાહં(ન્) ન પશ્યામિ, ચઞ્ચલત્વાત્સ્થિતિં(મ્) સ્થિરામ્ ॥૬.૩૩॥",

    "ચઞ્ચલં(મ્) હિ મનઃ(ખ્) કૃષ્ણ, પ્રમાથિ બલવદ્ દૃઢમ્।\nતસ્યાહં(ન્) નિગ્રહં(મ્) મન્યે, વાયોરિવ સુદુષ્કરમ્ ॥૬.૩૪॥",

    "શ્રીભગવાનુવાચ\nઅસંશયં(મ્) મહાબાહો, મનો દુર્નિગ્રહં(ઞ્) ચલમ્।\nઅભ્યાસેન તુ કૌન્તેય, વૈરાગ્યેણ ચ ગૃહ્યતે ॥૬.૩૫॥",

    "અસંયતાત્મના યોગો, દુષ્પ્રાપ ઇતિ મે મતિઃ।\nવશ્યાત્મના તુ યતતા, શક્યોઽવાપ્તુમુપાયતઃ ॥૬.૩૬॥",

    "અર્જુન ઉવાચ\nઅયતિઃ(શ્) શ્રદ્ધયોપેતો, યોગાચ્ચલિતમાનસઃ।\nઅપ્રાપ્ય યોગસંસિદ્ધિં(ઙ્), કાં(ઙ્) ગતિં(ઙ્) કૃષ્ણ ગચ્છતિ ॥૬.૩૭॥",

    "કચ્ચિન્નોભયવિભ્રષ્ટઃ(શ્), છિન્નાભ્રમિવ નશ્યતિ।\nઅપ્રતિષ્ઠો મહાબાહો, વિમૂઢો બ્રહ્મણઃ(ફ્) પથિ ॥૬.૩૮॥",

    "એતન્મે સંશયં(ઙ્) કૃષ્ણ, છેત્તુમર્હસ્યશેષતઃ।\nત્વદન્યઃ(સ્) સંશયસ્યાસ્ય, છેત્તા ન હ્યુપપદ્યતે ॥૬.૩૯॥",

    "શ્રીભગવાનુવાચ\nપાર્થ નૈવેહ નામુત્ર, વિનાશસ્તસ્ય વિદ્યતે।\nન હિ કલ્યાણકૃત્કશ્ચિદ્, દુર્ગતિં(ન્) તાત ગચ્છતિ ॥૬.૪૦॥",

    "પ્રાપ્ય પુણ્યકૃતાં(લ્ઁ) લોકાન્, ઉષિત્વા શાશ્વતીઃ(સ્) સમાઃ।\nશુચીનાં(મ્) શ્રીમતાં(ઙ્) ગેહે, યોગભ્રષ્ટોઽભિજાયતે ॥૬.૪૧॥",

    "અથવા યોગિનામેવ, કુલે ભવતિ ધીમતામ્।\nએતદ્ધિ દુર્લભતરં(લ્ઁ), લોકે જન્મ યદીદૃશમ્ ॥૬.૪૨॥",

    "તત્ર તં(મ્) બુદ્ધિસંયોગં(લ્ઁ), લભતે પૌર્વદેહિકમ્।\nયતતે ચ તતો ભૂયઃ(સ્), સંસિદ્ધૌ કુરુનન્દન ॥૬.૪૩॥",

    "પૂર્વાભ્યાસેન તેનૈવ, હ્રિયતે હ્યવશોઽપિ સઃ।\nજિજ્ઞાસુરપિ યોગસ્ય, શબ્દબ્રહ્માતિવર્તતે ॥૬.૪૪॥",

    "પ્રયત્નાદ્યતમાનસ્તુ, યોગી સંશુદ્ધકિલ્બિષઃ।\nઅનેકજન્મસંસિદ્ધઃ(સ્), તતો યાતિ પરાં(ઙ્) ગતિમ્ ॥૬.૪૫॥",

    "તપસ્વિભ્યોઽધિકો યોગી, જ્ઞાનિભ્યોઽપિ મતોઽધિકઃ।\nકર્મિભ્યશ્ચાધિકો યોગી, તસ્માદ્યોગી ભવાર્જુન ॥૬.૪૬॥",

    "યોગિનામપિ સર્વેષાં(મ્), મદ્ગતેનાન્તરાત્મના।\nશ્રદ્ધાવાન્ભજતે યો માં(મ્), સ મે યુક્તતમો મતઃ ॥૬.૪૭॥",

    "ૐ તત્સદિતિ શ્રીમદ્ભગવદ્ગીતાસુ ઉપનિષત્સુ બ્રહ્મવિદ્યાયાં(ય્ઁ) \nયોગશાસ્ત્રે શ્રીકૃષ્ણાર્જુનસંવાદે આત્મસંયમયોગો નામ ષષ્ઠોઽધ્યાયઃ ॥૬॥"
  ],
  "adhyaya-7" : [
    "શ્રીભગવાનુવાચ\nમય્યાસક્તમનાઃ(ફ્) પાર્થ, યોગં(ય્ઁ) યુઞ્જન્મદાશ્રયઃ।\nઅસંશયં(મ્) સમગ્રં(મ્) માં(ય્ઁ), યથા જ્ઞાસ્યસિ તચ્છૃણુ ॥૭.૧॥",

    "જ્ઞાનં(ન્) તેઽહં(મ્) સવિજ્ઞાનમ્, ઇદં(વ્ઁ) વક્ષ્યામ્યશેષતઃ।\nયજ્જ્ઞાત્વા નેહ ભૂયોઽન્યજ્, જ્ઞાતવ્યમવશિષ્યતે ॥૭.૨॥",

    "મનુષ્યાણાં(મ્) સહસ્રેષુ, કશ્ચિદ્યતતિ સિદ્ધયે।\nયતતામપિ સિદ્ધાનાં(ઙ્), કશ્ચિન્માં(વ્ઁ) વેત્તિ તત્ત્વતઃ ॥૭.૩॥",

    "ભૂમિરાપોઽનલો વાયુઃ(ખ્), ખં(મ્) મનો બુદ્ધિરેવ ચ।\nઅહઙ્કાર ઇતીયં(મ્) મે, ભિન્ના પ્રકૃતિરષ્ટધા ॥૭.૪॥",

    "અપરેયમિતસ્ત્વન્યાં(મ્), પ્રકૃતિં(વ્ઁ) વિદ્ધિ મે પરામ્।\nજીવભૂતાં(મ્) મહાબાહો, યયેદં(ન્) ધાર્યતે જગત્ ॥૭.૫॥",

    "એતદ્યોનીનિ ભૂતાનિ, સર્વાણીત્યુપધારય।\nઅહં(ઙ્) કૃત્સ્નસ્ય જગતઃ(ફ્), પ્રભવઃ(ફ્) પ્રલયસ્તથા ॥૭.૬॥",

    "મત્તઃ(ફ્) પરતરં(ન્) નાન્યત્, કિઞ્ચિદસ્તિ ધનઞ્જય।\nમયિ સર્વમિદં(મ્) પ્રોતં(મ્), સૂત્રે મણિગણા ઇવ ॥૭.૭॥",

    "રસોઽહમપ્સુ કૌન્તેય, પ્રભાસ્મિ શશિસૂર્યયોઃ।\nપ્રણવઃ(સ્) સર્વવેદેષુ, શબ્દઃ(ખ્) ખે પૌરુષં(ન્) નૃષુ ॥૭.૮॥",

    "પુણ્યો ગન્ધઃ(ફ્) પૃથિવ્યાં(ઞ્) ચ, તેજશ્ચાસ્મિ વિભાવસૌ।\nજીવનં(મ્) સર્વભૂતેષુ, તપશ્ચાસ્મિ તપસ્વિષુ ॥૭.૯॥",

    "બીજં(મ્) માં(મ્) સર્વભૂતાનાં(વ્ઁ) વિદ્ધિ પાર્થ સનાતનમ્।\nબુદ્ધિર્બુદ્ધિમતામસ્મિ, તેજસ્તેજસ્વિનામહમ્ ॥૭.૧૦॥",

    "બલં(મ્) બલવતાં(ઞ્) ચાહં(ઙ્), કામરાગવિવર્જિતમ્।\nધર્માવિરુદ્ધો ભૂતેષુ, કામોઽસ્મિ ભરતર્ષભ ॥૭.૧૧॥",

    "યે ચૈવ સાત્ત્વિકા ભાવા, રાજસાસ્તામસાશ્ચ યે।\nમત્ત એવેતિ તાન્વિદ્ધિ, ન ત્વહં(ન્) તેષુ તે મયિ ॥૭.૧૨॥",

    "ત્રિભિર્ગુણમયૈર્ભાવૈઃ(ર્), એભિઃ(સ્) સર્વમિદં(ઞ્) જગત્।\nમોહિતં(ન્) નાભિજાનાતિ, મામેભ્યઃ(ફ્) પરમવ્યયમ્ ॥૭.૧૩॥",

    "દૈવી હ્યેષા ગુણમયી, મમ માયા દુરત્યયા।\nમામેવ યે પ્રપદ્યન્તે, માયામેતાં(ન્) તરન્તિ તે ॥૭.૧૪॥",

    "ન માં(ન્) દુષ્કૃતિનો મૂઢાઃ(ફ્), પ્રપદ્યન્તે નરાધમાઃ।\nમાયયાપહૃતજ્ઞાના, આસુરં(મ્) ભાવમાશ્રિતાઃ ॥૭.૧૫॥",

    "ચતુર્વિધા ભજન્તે માં(ઞ્), જનાઃ(સ્) સુકૃતિનોઽર્જુન।\nઆર્તો જિજ્ઞાસુરર્થાર્થી, જ્ઞાની ચ ભરતર્ષભ ॥૭.૧૬॥",

    "તેષાં(ઞ્) જ્ઞાની નિત્યયુક્ત, એકભક્તિર્વિશિષ્યતે।\nપ્રિયો હિ જ્ઞાનિનોઽત્યર્થમ્, અહં (મ્) સ ચ મમ પ્રિયઃ ॥૭.૧૭॥",

    "ઉદારાઃ(સ્) સર્વ એવૈતે, જ્ઞાની ત્વાત્મૈવ મે મતમ્।\nઆસ્થિતઃ(સ્) સ હિ યુક્તાત્મા, મામેવાનુત્તમાં(ઙ્) ગતિમ્ ॥૭.૧૮॥",

    "બહૂનાં(ઞ્) જન્મનામન્તે, જ્ઞાનવાન્માં(મ્) પ્રપદ્યતે।\nવાસુદેવઃ(સ્) સર્વમિતિ, સ મહાત્મા સુદુર્લભઃ ॥૭.૧૯॥",

    "કામૈસ્તૈસ્તૈર્હૃતજ્ઞાનાઃ(ફ્), પ્રપદ્યન્તેઽન્યદેવતાઃ।\nતં(ન્) તં(ન્) નિયમમાસ્થાય, પ્રકૃત્યા નિયતાઃ(સ્) સ્વયા ॥૭.૨૦॥",

    "યો યો યાં(ય્ઁ) યાં(ન્) તનું(મ્) ભક્તઃ(શ્), શ્રદ્ધયાર્ચિતુમિચ્છતિ।\nતસ્ય તસ્યાચલાં(મ્) શ્રદ્ધાં(ન્), તામેવ વિદધામ્યહમ્ ॥૭.૨૧॥",

    "સ તયા શ્રદ્ધયા યુક્તઃ(સ્), તસ્યારાધનમીહતે।\nલભતે ચ તતઃ(ખ્) કામાન્, મયૈવ વિહિતાન્હિ તાન્  ॥૭.૨૨॥",

    "અન્તવત્તુ ફલં(ન્) તેષાં(ન્), તદ્ભવત્યલ્પમેધસામ્।\nદેવાન્દેવયજો યાન્તિ, મદ્ભક્તા યાન્તિ મામપિ ॥૭.૨૩॥",

    "અવ્યક્તં(વ્ઁ) વ્યક્તિમાપન્નં(મ્), મન્યન્તે મામબુદ્ધયઃ।\nપરં(મ્) ભાવમજાનન્તો, મમાવ્યયમનુત્તમમ્ ॥૭.૨૪॥",

    "નાહં(મ્) પ્રકાશઃ(સ્) સર્વસ્ય, યોગમાયાસમાવૃતઃ।\nમૂઢોઽયં(ન્) નાભિજાનાતિ, લોકો મામજમવ્યયમ્ ॥૭.૨૫॥",

    "વેદાહં(મ્) સમતીતાનિ, વર્તમાનાનિ ચાર્જુન।\nભવિષ્યાણિ ચ ભૂતાનિ, માં(ન્) તુ વેદ ન કશ્ચન ॥૭.૨૬॥",

    "ઇચ્છાદ્વેષસમુત્થેન, દ્વન્દ્વમોહેન ભારત।\nસર્વભૂતાનિ સમ્મોહં(મ્), સર્ગે યાન્તિ પરન્તપ ॥૭.૨૭॥",

    "યેષાં(ન્) ત્વન્તગતં(મ્) પાપં(ઞ્), જનાનાં(મ્) પુણ્યકર્મણામ્।\nતે દ્વન્દ્વમોહનિર્મુક્તા, ભજન્તે માં(ન્) દૃઢવ્રતાઃ ॥૭.૨૮॥",

    "જરામરણમોક્ષાય, મામાશ્રિત્ય યતન્તિ યે।\nતે બ્રહ્મ તદ્વિદુઃ(ખ્) કૃત્સ્નમ્, અધ્યાત્મં(ઙ્) કર્મ ચાખિલમ્ ॥૭.૨૯॥",

    "સાધિભૂતાધિદૈવં(મ્) માં(મ્), સાધિયજ્ઞં(ઞ્) ચ યે વિદુઃ।\n'પ્રયાણકાલેઽપિ ચ માં(ન્), તે વિદુર્યુક્તચેતસઃ ॥૭.૩૦॥",

    "ૐ તત્સદિતિ શ્રીમદ્ભગવદ્ગીતાસુ ઉપનિષત્સુ બ્રહ્મવિદ્યાયાં યોગશાસ્ત્રે\nશ્રીકૃષ્ણાર્જુનસંવાદે જ્ઞાનવિજ્ઞાનયોગો નામ સપ્તમોઽધ્યાયઃ ॥૭॥"
  ],
  "adhyaya-8" : [
    "અર્જુન ઉવાચ\nકિં(ન્) તદ્બ્રહ્મ કિમધ્યાત્મં(ઙ્), કિં(ઙ્) કર્મ પુરુષોત્તમ।\nઅધિભૂતં(ઞ્) ચ કિં(મ્) પ્રોક્તમ્, અધિદૈવં(ઙ્) કિમુચ્યતે ॥૮.૧॥",

    "અધિયજ્ઞઃ(ખ્) કથં(ઙ્) કોઽત્ર, દેહેઽસ્મિન્મધુસૂદન।\nપ્રયાણકાલે ચ કથં(ઞ્), જ્ઞેયોઽસિ નિયતાત્મભિઃ ॥૮.૨॥",

    "શ્રીભગવાનુવાચ\nઅક્ષરં(મ્) બ્રહ્મ પરમં(મ્), સ્વભાવોઽધ્યાત્મમુચ્યતે।\nભૂતભાવોદ્ભવકરો, વિસર્ગઃ(ખ્) કર્મસઞ્જ્ઞિતઃ ॥૮.૩॥",

    "અધિભૂતં(ઙ્) ક્ષરો ભાવઃ(ફ્), પુરુષશ્ચાધિદૈવતમ્।\nઅધિયજ્ઞોઽહમેવાત્ર, દેહે દેહભૃતાં(વ્ઁ) વર ॥૮.૪॥",

    "અન્તકાલે ચ મામેવ, સ્મરન્મુક્ત્વા કલેવરમ્।\nયઃ(ફ્) પ્રયાતિ સ મદ્ભાવં(ય્ઁ), યાતિ નાસ્ત્યત્ર સંશયઃ ॥૮.૫॥",

    "યં(ય્ઁ) યં(વ્ઁ) વાપિ સ્મરન્ભાવં(ન્), ત્યજત્યન્તે કલેવરમ્।\nતં(ન્) તમેવૈતિ કૌન્તેય, સદા તદ્ભાવભાવિતઃ ॥૮.૬॥",

    "તસ્માત્સર્વેષુ કાલેષુ, મામનુસ્મર યુધ્ય ચ।\nમય્યર્પિતમનોબુદ્ધિઃ(ર્), મામેવૈષ્યસ્યસંશયમ્ ॥૮.૭॥",

    "અભ્યાસયોગયુક્તેન, ચેતસા નાન્યગામિના।\nપરમં(મ્) પુરુષં(ન્) દિવ્યં(ય્ઁ), યાતિ પાર્થાનુચિન્તયન્ ॥૮.૮॥",

    "કવિં(મ્) પુરાણમનુશાસિતારમ્,\nઅણોરણીયાંસમનુસ્મરેદ્યઃ।\nસર્વસ્ય ધાતારમચિન્ત્યરૂપમ્,\nઆદિત્યવર્ણં(ન્) તમસઃ(ફ્)પરસ્તાત્ ॥૮.૯॥",

    "પ્રયાણકાલે મનસાચલેન,\nભક્ત્યા યુક્તો યોગબલેન ચૈવ।\nભ્રુવોર્મધ્યે પ્રાણમાવેશ્ય સમ્યક્,\nસ તં(મ્) પરં(મ્) પુરુષમુપૈતિ દિવ્યમ્ ॥૮.૧૦॥",
    
    "યદક્ષરં(વ્ઁ) વેદવિદો વદન્તિ,\nવિશન્તિ યદ્યતયો વીતરાગાઃ।\nયદિચ્છન્તો બ્રહ્મચર્યં(ઞ્) ચરન્તિ,\nતત્તે પદં(મ્) સઙ્ગ્રહેણ પ્રવક્ષ્યે ॥૮.૧૧॥",
    
    "સર્વદ્વારાણિ સંયમ્ય, મનો હૃદિ નિરુધ્ય ચ।\nમૂર્ધ્ન્યાધાયાત્મનઃ(ફ્) પ્રાણમ્, આસ્થિતો યોગધારણામ્ ॥૮.૧૨॥",
    
    "ઓમિત્યેકાક્ષરં(મ્) બ્રહ્મ, વ્યાહરન્મામનુસ્મરન્।\nયઃ(ફ્) પ્રયાતિ ત્યજન્દેહં(મ્), સ યાતિ પરમાં(ઙ્) ગતિમ્ ॥૮.૧૩॥",
    
    "અનન્યચેતાઃ(સ્) સતતં(ય્ઁ), યો માં(મ્) સ્મરતિ નિત્યશઃ।\nતસ્યાહં(મ્) સુલભઃ(ફ્) પાર્થ, નિત્યયુક્તસ્ય યોગિનઃ ॥૮.૧૪॥",
    
    "મામુપેત્ય પુનર્જન્મ, દુઃખાલયમશાશ્વતમ્।\nનાપ્નુવન્તિ મહાત્માનઃ(સ્), સંસિદ્ધિં(મ્) પરમાં(ઙ્) ગતાઃ ॥૮.૧૫॥",
    
    "આબ્રહ્મભુવનાલ્લોકાઃ(ફ્), પુનરાવર્તિનોઽર્જુન।\nમામુપેત્ય તુ કૌન્તેય, પુનર્જન્મ ન વિદ્યતે ॥૮.૧૬॥",
    
    "સહસ્રયુગપર્યન્તમ્, અહર્યદ્બ્રહ્મણો વિદુઃ।\nરાત્રિં(ય્ઁ) યુગસહસ્રાન્તાં(ન્), તેઽહોરાત્રવિદો જનાઃ ॥૮.૧૭॥",
    
    "અવ્યક્તાદ્વ્યક્તયઃ(સ્) સર્વાઃ(ફ્), પ્રભવન્ત્યહરાગમે।\nરાત્ર્યાગમે પ્રલીયન્તે, તત્રૈવાવ્યક્તસઞ્જ્ઞકે ॥૮.૧૮॥",
    
    "ભૂતગ્રામઃ(સ્) સ એવાયં(મ્), ભૂત્વા ભૂત્વા પ્રલીયતે।\nરાત્ર્યાગમેઽવશઃ(ફ્) પાર્થ, પ્રભવત્યહરાગમે ॥૮.૧૯॥",
    
    "પરસ્તસ્માત્તુ ભાવોઽન્યો-ઽવ્યક્તોઽવ્યક્તાત્સનાતનઃ।\nયઃ(સ્) સ સર્વેષુ ભૂતેષુ, નશ્યત્સુ ન વિનશ્યતિ ॥૮.૨૦॥",
    
    "અવ્યક્તોઽક્ષર ઇત્યુક્તઃ(સ્), તમાહુઃ(ફ્) પરમાં(ઙ્) ગતિમ્।\nયં(મ્) પ્રાપ્ય ન નિવર્તન્તે, તદ્ધામ પરમં(મ્) મમ ॥૮.૨૧॥",
    
    "પુરુષઃ(સ્) સ પરઃ(ફ્) પાર્થ, ભક્ત્યા લભ્યસ્ત્વનન્યયા।\nયસ્યાન્તઃસ્થાનિ ભૂતાનિ, યેન સર્વમિદં(ન્) તતમ્ ॥૮.૨૨॥",
    
    "યત્ર કાલે ત્વનાવૃત્તિમ્, આવૃત્તિં(ઞ્) ચૈવ યોગિનઃ।\nપ્રયાતા યાન્તિ તં(ઙ્) કાલં(વ્ઁ) વક્ષ્યામિ ભરતર્ષભ ॥૮.૨૩॥",
    
    "અગ્નિર્જ્યોતિરહઃ(શ્) શુક્લઃ(ષ્), ષણ્માસા ઉત્તરાયણમ્।\nતત્ર પ્રયાતા ગચ્છન્તિ, બ્રહ્મ બ્રહ્મવિદો જનાઃ ॥૮.૨૪॥",
    
    "ધૂમો રાત્રિસ્તથા કૃષ્ણઃ(ષ્), ષણ્માસા દક્ષિણાયનમ્।\nતત્ર ચાન્દ્રમસં(ઞ્) જ્યોતિઃ(ર્), યોગી પ્રાપ્ય નિવર્તતે ॥૮.૨૫॥",
    
    "શુક્લકૃષ્ણે ગતી હ્યેતે, જગતઃ(શ્) શાશ્વતે મતે।\nએકયા યાત્યનાવૃત્તિમ્, અન્યયાવર્તતે પુનઃ ॥૮.૨૬॥",
    
    "નૈતે સૃતી પાર્થ જાનન્, યોગી મુહ્યતિ કશ્ચન।\nતસ્માત્સર્વેષુ કાલેષુ, યોગયુક્તો ભવાર્જુન ॥૮.૨૭॥",
    
    "વેદેષુ યજ્ઞેષુ તપઃ(સ્) સુ ચૈવ,\nદાનેષુ યત્પુણ્યફલં(મ્) પ્રદિષ્ટમ્।\nઅત્યેતિ તત્સર્વમિદં(વ્ઁ) વિદિત્વા,\nયોગી પરં(મ્) સ્થાનમુપૈતિ ચાદ્યમ્ ॥૮.૨૮॥",
    
    "ૐ તત્સદિતિ શ્રીમદ્ભગવદ્ગીતાસુ ઉપનિષત્સુ બ્રહ્મવિદ્યાયાં(ય્ઁ)\nયોગશાસ્ત્રે શ્રીકૃષ્ણાર્જુનસંવાદે અક્ષરબ્રહ્મયોગો નામાષ્ટમોઽધ્યાયઃ ॥૮॥"
  ],
  "adhyaya-9" : [
    "શ્રીભગવાનુવાચ\nઇદં(ન્) તુ તે ગુહ્યતમં(મ્), પ્રવક્ષ્યામ્યનસૂયવે।\nજ્ઞાનં(વ્ઁ) વિજ્ઞાનસહિતં(ય્ઁ), યજ્જ્ઞાત્વા મોક્ષ્યસેશુભાત્ ॥૯.૧॥",

    "રાજવિદ્યા રાજગુહ્યં(મ્), પવિત્રમિદમુત્તમમ્।\nપ્રત્યક્ષાવગમં(ન્) ધર્મ્યં(મ્), સુસુખં(ઙ્) કર્તુમવ્યયમ્ ॥૯.૨॥",

    "અશ્રદ્દધાનાઃ(ફ્) પુરુષા, ધર્મસ્યાસ્ય પરન્તપ।\nઅપ્રાપ્ય માં(ન્) નિવર્તન્તે, મૃત્યુસંસારવર્ત્મનિ ॥૯.૩॥",

    "મયા તતમિદં(મ્) સર્વં(ઞ્), જગદવ્યક્તમૂર્તિના।\nમત્સ્થાનિ સર્વભૂતાનિ, ન ચાહં(ન્) તેષ્વવસ્થિતઃ ॥૯.૪॥",

    "ન ચ મત્સ્થાનિ ભૂતાનિ, પશ્ય મે યોગમૈશ્વરમ્।\nભૂતભૃન્ન ચ ભૂતસ્થો, મમાત્મા ભૂતભાવનઃ ॥૯.૫॥",

    "યથાકાશસ્થિતો નિત્યં(વ્ઁ) વાયુઃ(સ્) સર્વત્રગો મહાન્।\nતથા સર્વાણિ ભૂતાનિ, મત્સ્થાનીત્યુપધારય ॥૯.૬॥",

    "સર્વભૂતાનિ કૌન્તેય, પ્રકૃતિં(ય્ઁ) યાન્તિ મામિકામ્।\nકલ્પક્ષયે પુનસ્તાનિ, કલ્પાદૌ વિસૃજામ્યહમ્ ॥૯.૭॥",

    "પ્રકૃતિં(મ્) સ્વામવષ્ટભ્ય, વિસૃજામિ પુનઃ(ફ્) પુનઃ।\nભૂતગ્રામમિમં(ઙ્) કૃત્સ્નમ્, અવશં(મ્) પ્રકૃતેર્વશાત્ ॥૯.૮॥",

    "ન ચ માં(ન્) તાનિ કર્માણિ, નિબધ્નન્તિ ધનઞ્જય।\nઉદાસીનવદાસીનમ્, અસક્તં(ન્) તેષુ કર્મસુ ॥૯.૯॥",

    "મયાધ્યક્ષેણ પ્રકૃતિઃ(સ્), સૂયતે સચરાચરમ્।\nહેતુનાનેન કૌન્તેય, જગદ્વિપરિવર્તતે ॥૯.૧૦॥",

    "અવજાનન્તિ માં(મ્) મૂઢા, માનુષીં(ન્) તનુમાશ્રિતમ્।\nપરં(મ્) ભાવમજાનન્તો, મમ ભૂતમહેશ્વરમ્ ॥૯.૧૧॥",

    "મોઘાશા મોઘકર્માણો, મોઘજ્ઞાના વિચેતસઃ।\nરાક્ષસીમાસુરીં(ઞ્) ચૈવ, પ્રકૃતિં(મ્) મોહિનીં(મ્) શ્રિતાઃ ॥૯.૧૨॥",

    "મહાત્માનસ્તુ માં(મ્) પાર્થ, દૈવીં(મ્) પ્રકૃતિમાશ્રિતાઃ।\nભજન્ત્યનન્યમનસો, જ્ઞાત્વા ભૂતાદિમવ્યયમ્ ॥૯.૧૩॥",

    "સતતં(ઙ્) કીર્તયન્તો માં(ય્ઁ), યતન્તશ્ચ દૃઢવ્રતાઃ।\nનમસ્યન્તશ્ચ માં(મ્) ભક્ત્યા, નિત્યયુક્તા ઉપાસતે ॥૯.૧૪॥",

    "જ્ઞાનયજ્ઞેન ચાપ્યન્યે, યજન્તો મામુપાસતે।\nએકત્વેન પૃથક્ત્વેન, બહુધા વિશ્વતોમુખમ્ ॥૯.૧૫॥",

    "અહં(ઙ્) ક્રતુરહં(ય્ઁ) યજ્ઞઃ(સ્), સ્વધાહમહમૌષધમ્।\nમન્ત્રોઽહમહમેવાજ્યમ્, અહમગ્નિરહં(મ્) હુતમ્ ॥૯.૧૬॥",

    "પિતાહમસ્ય જગતો, માતા ધાતા પિતામહઃ।\nવેદ્યં(મ્) પવિત્રમોઙ્કાર, ઋક્સામ યજુરેવ ચ ॥૯.૧૭॥",

    "ગતિર્ભર્તા પ્રભુઃ(સ્) સાક્ષી, નિવાસઃ(શ્) શરણં(મ્) સુહૃત્।\nપ્રભવઃ(ફ્) પ્રલયઃ(સ્) સ્થાનં(ન્), નિધાનં(મ્) બીજમવ્યયમ્ ॥૯.૧૮॥",

    "તપામ્યહમહં(વ્ઁ) વર્ષં(ન્), નિગૃહ્ણામ્યુત્સૃજામિ ચ।\nઅમૃતં(ઞ્) ચૈવ મૃત્યુશ્ચ, સદસચ્ચાહમર્જુન ॥૯.૧૯॥",

    "ત્રૈવિદ્યા માં(મ્) સોમપાઃ(ફ્) પૂતપાપા,\nયજ્ઞૈરિષ્ટ્વા સ્વર્ગતિં(મ્) પ્રાર્થયન્તે।\nતે પુણ્યમાસાદ્ય સુરેન્દ્રલોકમ્,\nઅશ્નન્તિ દિવ્યાન્દિવિ દેવભોગાન્ ॥૯.૨૦॥",

    "તે તં(મ્) ભુક્ત્વા સ્વર્ગલોકં(વ્ઁ) વિશાલં(ઙ્),\nક્ષીણે પુણ્યે મર્ત્યલોકં(વ્ઁ) વિશન્તિ।\nએવં(ન્) ત્રયીધર્મમનુપ્રપન્ના,\nગતાગતં(ઙ્) કામકામા લભન્તે ॥૯.૨૧॥",

    "અનન્યાશ્ચિન્તયન્તો માં(ય્ઁ), યે જનાઃ(ફ્) પર્યુપાસતે।\nતેષાં(ન્) નિત્યાભિયુક્તાનાં(ય્ઁ), યોગક્ષેમં(વ્ઁ) વહામ્યહમ્ ॥૯.૨૨॥",

    "યેઽપ્યન્યદેવતા ભક્તા, યજન્તે શ્રદ્ધયાન્વિતાઃ।\nતેઽપિ મામેવ કૌન્તેય, યજન્ત્યવિધિપૂર્વકમ્ ॥૯.૨૩॥",

    "અહં(મ્) હિ સર્વયજ્ઞાનાં(મ્), ભોક્તા ચ પ્રભુરેવ ચ।\nન તુ મામભિજાનન્તિ, તત્ત્વેનાતશ્ચ્યવન્તિ તે ॥૯.૨૪॥",

    "યાન્તિ દેવવ્રતા દેવાન્, પિતૄન્યાન્તિ પિતૃવ્રતાઃ ।\nભૂતાનિ યાન્તિ ભૂતેજ્યા, યાન્તિ મદ્યાજિનોઽપિ  મામ્॥૨૫॥",

    "પત્રં(મ્) પુષ્પં(મ્) ફલં(ન્) તોયં(ય્ઁ), યો મે ભક્ત્યા પ્રયચ્છતિ ।\nતદહં(મ્) ભક્ત્યુપહૃતમ્, અશ્નામિ પ્રયતાત્ મનઃ॥૨૬॥",

    "યત્કરોષિ યદશ્નાસિ, યજ્જુહોષિ દદાસિ યત્।\nયત્તપસ્યસિ કૌન્તેય, તત્કુરુષ્વ મદર્પણમ્ ॥૯.૨૭॥",

    "શુભાશુભફલૈરેવં(મ્), મોક્ષ્યસે કર્મબન્ધનૈઃ।\nસન્ન્યાસયોગયુક્તાત્મા, વિમુક્તો મામુપૈષ્યસિ ॥૯.૨૮॥",

    "સમોઽહં(મ્) સર્વભૂતેષુ, ન મે દ્વેષ્યોઽસ્તિ ન પ્રિયઃ।\nયે ભજન્તિ તુ માં(મ્) ભક્ત્યા, મયિ તે તેષુ ચાપ્યહમ્ ॥૯.૨૯॥",

    "અપિ ચેત્સુદુરાચારો, ભજતે મામનન્યભાક્।\nસાધુરેવ સ મન્તવ્યઃ(સ્), સમ્યગ્વ્યવસિતો હિ સઃ ॥૯.૩૦॥",

    "ક્ષિપ્રં(મ્) ભવતિ ધર્માત્મા, શશ્વચ્છાન્તિં(ન્) નિગચ્છતિ।\nકૌન્તેય પ્રતિજાનીહિ, ન મે ભક્તઃ(ફ્) પ્રણશ્યતિ ॥૯.૩૧॥",

    "માં(મ્) હિ પાર્થ વ્યપાશ્રિત્ય, યેઽપિ સ્યુઃ(ફ્) પાપયોનયઃ।\nસ્ત્રિયો વૈશ્યાસ્તથા શૂદ્રાઃ(સ્), તેઽપિ યાન્તિ પરાં(ઙ્) ગતિમ્ ॥૯.૩૨॥",

    "કિં(મ્) પુનર્બ્રાહ્મણાઃ(ફ્) પુણ્યા, ભક્તા રાજર્ષયસ્તથા।\nઅનિત્યમસુખં(લ્ઁ) લોકમ્, ઇમં(મ્) પ્રાપ્ય ભજસ્વ મામ્ ॥૯.૩૩॥",

    "મન્મના ભવ મદ્ભક્તો, મદ્યાજી માં(ન્) નમસ્કુરુ।\nમામેવૈષ્યસિ યુક્ત્વૈવમ્, આત્માનં(મ્) મત્પરાયણઃ ॥૯.૩૪॥",

    "ૐ તત્સદિતિ શ્રીમદ્ભગવદ્ગીતાસુ ઉપનિષત્સુ બ્રહ્મવિદ્યાયાં(ય્ઁ) \nયોગશાસ્ત્રે શ્રીકૃષ્ણાર્જુનસંવાદે રાજવિદ્યારાજગુહ્યયોગો નામ નવમોઽધ્યાયઃ ॥૯॥"
  ],
  "adhyaya-10" : [
    "શ્રીભગવાનુવાચ\nભૂય એવ મહાબાહો, શૃણુ મે પરમં (વ્ઁ) વચઃ।\nયત્તેઽહં(મ્) પ્રીયમાણાય, વક્ષ્યામિ હિતકામ્યયા ॥૧૦.૧॥",

    "ન મે વિદુઃ(સ્) સુરગણાઃ(ફ્), પ્રભવં(ન્) ન મહર્ષયઃ।\nઅહમાદિર્હિ દેવાનાં(મ્), મહર્ષીણાં(ઞ્) ચ સર્વશઃ ॥૧૦.૨॥",

    "યો મામજમનાદિં(ઞ્) ચ, વેત્તિ લોકમહેશ્વરમ્।\nઅસમ્મૂઢઃ(સ્) સ મર્ત્યેષુ, સર્વપાપૈઃ(ફ્) પ્રમુચ્યતે ॥૧૦.૩॥",

    "બુદ્ધિર્જ્ઞાનમસમ્મોહઃ, ક્ષમા સત્યં(ન્) દમઃ(શ્) શમઃ।\nસુખં(ન્) દુઃખં(મ્) ભવોઽભાવો, ભયં(ઞ્) ચાભયમેવ ચ ॥૧૦.૪॥",

    "અહિંસા સમતા તુષ્ટિઃ(સ્), તપો દાનં(ય્ઁ) યશોઽયશઃ।\nભવન્તિ ભાવા ભૂતાનાં(મ્), મત્ત એવ પૃથગ્વિધાઃ ॥૧૦.૫॥",

    "મહર્ષયઃ(સ્) સપ્ત પૂર્વે, ચત્વારો મનવસ્તથા।\nમદ્ભાવા માનસા જાતા, યેષાં(લ્ઁ) લોક ઇમાઃ(ફ્) પ્રજાઃ ॥૧૦.૬॥",

    "એતાં(વ્ઁ) વિભૂતિં(ય્ઁ) યોગં(ઞ્) ચ, મમ યો વેત્તિ તત્ત્વતઃ।\nસોઽવિકમ્પેન યોગેન, યુજ્યતે નાત્ર સંશયઃ ॥૧૦.૭॥",

    "અહં(મ્) સર્વસ્ય પ્રભવો, મત્તઃ(સ્) સર્વં(મ્) પ્રવર્તતે।\nઇતિ મત્વા ભજન્તે માં(મ્), બુધા ભાવસમન્વિતાઃ ॥૧૦.૮॥",

    "મચ્ચિત્તા મદ્ગતપ્રાણા, બોધયન્તઃ(ફ્) પરસ્પરમ્।\nકથયન્તશ્ચ માં(ન્) નિત્યં(ન્), તુષ્યન્તિ ચ રમન્તિ ચ ॥૧૦.૯॥",

    "તેષાં(મ્) સતતયુક્તાનાં(મ્), ભજતાં(મ્) પ્રીતિપૂર્વકમ્।\nદદામિ બુદ્ધિયોગં(ન્) તં(ય્ઁ), યેન મામુપયાન્તિ તે ॥૧૦.૧૦॥",

    "તેષામેવાનુકમ્પાર્થમ્, અહમજ્ઞાનજં(ન્) તમઃ।\nનાશયામ્યાત્મભાવસ્થો, જ્ઞાનદીપેન ભાસ્વતા ॥૧૦.૧૧॥",

    "અર્જુન ઉવાચ\nપરં(મ્) બ્રહ્મ પરં(ન્) ધામ, પવિત્રં(મ્) પરમં(મ્) ભવાન્।\nપુરુષં(મ્) શાશ્વતં(ન્) દિવ્યમ્, આદિદેવમજં(વ્ઁ) વિભુમ્ ॥૧૦.૧૨॥",

    "આહુસ્ત્વામૃષયઃ(સ્) સર્વે, દેવર્ષિર્નારદસ્તથા।\nઅસિતો દેવલો વ્યાસઃ(સ્), સ્વયં(ઞ્) ચૈવ બ્રવીષિ મે ॥૧૦.૧૩॥",

    "સર્વમેતદૃતં(મ્) મન્યે, યન્માં(વ્ઁ) વદસિ કેશવ।\nન હિ તે ભગવન્વ્યક્તિં(વ્ઁ) વિદુર્દેવા ન દાનવાઃ ॥૧૦.૧૪॥",

    "સ્વયમેવાત્મનાત્માનં(વ્ઁ) વેત્થ ત્વં(મ્) પુરુષોત્તમ।\nભૂતભાવન ભૂતેશ, દેવદેવ જગત્પતે ॥૧૦.૧૫॥",

    "વક્તુમર્હસ્યશેષેણ, દિવ્યા હ્યાત્મવિભૂતયઃ।\nયાભિર્વિભૂતિભિર્લોકાન્, ઇમાંસ્ત્વં(વ્ઁ) વ્યાપ્ય તિષ્ઠસિ ॥૧૦.૧૬॥",

    "કથં(વ્ઁ) વિદ્યામહં(ય્ઁ) યોગિંસ્, ત્વાં(મ્) સદા પરિચિન્તયન્।\nકેષુ કેષુ ચ ભાવેષુ, ચિન્ત્યોઽસિ ભગવન્મયા ॥૧૦.૧૭॥",

    "વિસ્તરેણાત્મનો યોગં(વ્ઁ) વિભૂતિં(ઞ્) ચ જનાર્દન।\nભૂયઃ(ખ્) કથય તૃપ્તિર્હિ, શૃણ્વતો નાસ્તિ મેઽમૃતમ્ ॥૧૦.૧૮॥",

    "શ્રીભગવાનુવાચ\nહન્ત તે કથયિષ્યામિ, દિવ્યા હ્યાત્મવિભૂતયઃ।\nપ્રાધાન્યતઃ(ખ્) કુરુશ્રેષ્ઠ, નાસ્ત્યન્તો વિસ્તરસ્ય મે ॥૧૦.૧૯॥",

    "અહમાત્મા ગુડાકેશ, સર્વભૂતાશયસ્થિતઃ।\nઅહમાદિશ્ચ મધ્યં(ઞ્) ચ, ભૂતાનામન્ત એવ ચ ॥૧૦.૨૦॥",

    "આદિત્યાનામહં(વ્ઁ) વિષ્ણુ:(ર્), જ્યોતિષાં(મ્) રવિરંશુમાન્।\nમરીચિર્મરુતામસ્મિ, નક્ષત્રાણામહં(મ્) શશી ॥૧૦.૨૧॥",

    "વેદાનાં(મ્) સામવેદોઽસ્મિ, દેવાનામસ્મિ વાસવઃ।\nઇન્દ્રિયાણાં(મ્) મનશ્ચાસ્મિ, ભૂતાનામસ્મિ ચેતના ॥૧૦.૨૨॥",

    "રુદ્રાણાં(મ્) શઙ્કરશ્ચાસ્મિ, વિત્તેશો યક્ષરક્ષસામ્।\nવસૂનાં(મ્) પાવકશ્ચાસ્મિ, મેરુઃ(શ્) શિખરિણામહમ્ ॥૧૦.૨૩॥",

    "પુરોધસાં(ઞ્) ચ મુખ્યં(મ્) માં(વ્ઁ) વિદ્ધિ પાર્થ બૃહસ્પતિમ્।\nસેનાનીનામહં(મ્) સ્કન્દઃ(સ્), સરસામસ્મિ સાગરઃ ॥૧૦.૨૪॥",

    "મહર્ષીણાં(મ્) ભૃગુરહં(ઙ્), ગિરામસ્મ્યેકમક્ષરમ્।\nયજ્ઞાનાં(ઞ્) જપયજ્ઞોઽસ્મિ, સ્થાવરાણાં(મ્) હિમાલયઃ ॥૧૦.૨૫॥",

    "અશ્વત્થઃ(સ્) સર્વવૃક્ષાણાં(ન્), દેવર્ષીણાં(ઞ્) ચ નારદઃ।\nગન્ધર્વાણાં(ઞ્) ચિત્રરથઃ(સ્), સિદ્ધાનાં(ઙ્) કપિલો મુનિઃ ॥૧૦.૨૬॥",

    "ઉચ્ચૈઃશ્રવસમશ્વાનાં(વ્ઁ) વિદ્ધિ મામમૃતોદ્ભવમ્।\nઐરાવતં(ઙ્) ગજેન્દ્રાણાં(ન્), નરાણાં(ઞ્) ચ નરાધિપમ્ ॥૧૦.૨૭॥",

    "આયુધાનામહં(વ્ઁ) વજ્રં(ન્), ધેનૂનામસ્મિ કામધુક્।\nપ્રજનશ્ચાસ્મિ કન્દર્પઃ(સ્), સર્પાણામસ્મિ વાસુકિઃ ॥૧૦.૨૮॥",

    "અનન્તશ્ચાસ્મિ નાગાનાં(વ્ઁ) વરુણો યાદસામહમ્।\nપિતૄણામર્યમા ચાસ્મિ, યમઃ(સ્) સંયમતામહમ્ ॥૧૦.૨૯॥",

    "પ્રહ્લાદશ્ચાસ્મિ દૈત્યાનાં(ઙ્), કાલઃ(ખ્) કલયતામહમ્।\nમૃગાણાં(ઞ્) ચ મૃગેન્દ્રોઽહં(વ્ઁ) વૈનતેયશ્ચ પક્ષિણામ્ ॥૧૦.૩૦॥",

    "પવનઃ(ફ્) પવતામસ્મિ, રામઃ(શ્) શસ્ત્રભૃતામહમ્।\nઝષાણાં(મ્) મકરશ્ચાસ્મિ, સ્રોતસામસ્મિ જાહ્નવી ॥૧૦.૩૧॥",

    "સર્ગાણામાદિરન્તશ્ચ, મધ્યં(ઞ્) ચૈવાહમર્જુન।\nઅધ્યાત્મવિદ્યા વિદ્યાનાં(વ્ઁ) વાદઃ(ફ્) પ્રવદતામહમ્ ॥૧૦.૩૨॥",

    "અક્ષરાણામકારોઽસ્મિ, દ્વન્દ્વઃ(સ્) સામાસિકસ્ય ચ।\nઅહમેવાક્ષયઃ(ખ્) કાલો, ધાતાહં(વ્ઁ) વિશ્વતોમુખઃ ॥૧૦.૩૩॥",

    "મૃત્યુઃ(સ્) સર્વહરશ્ચાહમ્, ઉદ્ભવશ્ચ ભવિષ્યતામ્।\nકીર્તિઃ(શ્) શ્રીર્વાક્ચ નારીણાં(મ્), સ્મૃતિર્મેધા ધૃતિઃ ક્ષમા ॥૧૦.૩૪॥",

    "બૃહત્સામ તથા સામ્નાં(ઙ્), ગાયત્રી છન્દસામહમ્।\nમાસાનાં(મ્) માર્ગશીર્ષોઽહમ્, ઋતૂનાં(ઙ્) કુસુમાકરઃ ॥૧૦.૩૫॥",

    "દ્યૂતં(ઞ્) છલયતામસ્મિ, તેજસ્તેજસ્વિનામહમ્।\nજયોઽસ્મિ વ્યવસાયોઽસ્મિ, સત્ત્વં(મ્) સત્ત્વવતામહમ્ ॥૧૦.૩૬॥",

    "વૃષ્ણીનાં(વ્ઁ) વાસુદેવોઽસ્મિ, પાણ્ડવાનાં(ન્) ધનઞ્જયઃ।\nમુનીનામપ્યહં(વ્ઁ) વ્યાસઃ(ખ્), કવીનામુશના કવિઃ ॥૧૦.૩૭॥",

    "દણ્ડો દમયતામસ્મિ, નીતિરસ્મિ જિગીષતામ્।\nમૌનં(ઞ્) ચૈવાસ્મિ ગુહ્યાનાં(ઞ્), જ્ઞાનં(ઞ્) જ્ઞાનવતામહમ્ ॥૧૦.૩૮॥",

    "યચ્ચાપિ સર્વભૂતાનાં(મ્), બીજં(ન્) તદહમર્જુન।\nન તદસ્તિ વિના યત્સ્યાન્, મયા ભૂતં(ઞ્) ચરાચરમ્ ॥૧૦.૩૯॥",

    "નાન્તોઽસ્તિ મમ દિવ્યાનાં(વ્ઁ) વિભૂતીનાં(મ્) પરન્તપ।\nએષ તૂદ્દેશતઃ(ફ્) પ્રોક્તો, વિભૂતેર્વિસ્તરો મયા ॥૧૦.૪૦॥",

    "યદ્યદ્વિભૂતિમત્સત્ત્વં(મ્), શ્રીમદૂર્જિતમેવ વા।\nતત્તદેવાવગચ્છ ત્વં(મ્), મમ તેજોંઽશસમ્ભવમ્ ॥૧૦.૪૧॥",

    "અથવા બહુનૈતેન, કિં(ઞ્) જ્ઞાતેન તવાર્જુન।\nવિષ્ટભ્યાહમિદં(ઙ્) કૃત્સ્નમ્, એકાંશેન સ્થિતો જગત્ ॥૧૦.૪૨॥",

    "ૐ તત્સદિતિ શ્રીમદ્ભગવદ્ગીતાસૂપનિષત્સુ બ્રહ્મવિદ્યાયાં(ય્ઁ) \nયોગશાસ્ત્રે શ્રીકૃષ્ણાર્જુનસંવાદે વિભૂતિયોગો નામ દશમોઽધ્યાયઃ ॥૧૦॥"
  ],
  "adhyaya-11" : [
    "અર્જુન ઉવાચ\nમદનુગ્રહાય પરમં(ઙ્), ગુહ્યમધ્યાત્મસઞ્જ્ઞિતમ્।\nયત્ત્વયોક્તં(વ્ઁ) વચસ્તેન, મોહોઽયં(વ્ઁ) વિગતો મમ ॥૧૧.૧॥",

    "ભવાપ્યયૌ હિ ભૂતાનાં(મ્), શ્રુતૌ વિસ્તરશો મયા।\nત્વત્તઃ(ખ્) કમલપત્રાક્ષ, માહાત્મ્યમપિ ચાવ્યયમ્ ॥૧૧.૨॥",

    "એવમેતદ્યથાત્થ ત્વમ્, આત્માનં(મ્) પરમેશ્વર।\nદ્રષ્ટુમિચ્છામિ તે રૂપમ્, ઐશ્વરં(મ્) પુરુષોત્તમ ॥૧૧.૩॥",

    "મન્યસે યદિ તચ્છક્યં(મ્), મયા દ્રષ્ટુમિતિ પ્રભો।\nયોગેશ્વર તતો મે ત્વં(ન્), દર્શયાત્માનમવ્યયમ્ ॥૧૧.૪॥",

    "શ્રીભગવાનુવાચ\nપશ્ય મે પાર્થ રૂપાણિ શતશોઽથ સહસ્રશઃ।\nનાનાવિધાનિ દિવ્યાનિ નાનાવર્ણાકૃતીનિ ચ ॥૧૧.૫॥",

    "પશ્યાદિત્યાન્વસૂન્રુદ્રાન્, અશ્વિનૌ મરુતસ્તથા।\nબહૂન્યદૃષ્ટપૂર્વાણિ, પશ્યાશ્ચર્યાણિ ભારત ॥૧૧.૬॥",

    "ઇહૈકસ્થં(ઞ્) જગત્કૃત્સ્નં(મ્), પશ્યાદ્ય સચરાચરમ્।\nમમ દેહે ગુડાકેશ, યચ્ચાન્યદ્દ્રષ્ટુમિચ્છસિ ॥૧૧.૭॥",

    "ન તુ માં(મ્) શક્યસે દ્રષ્ટુમ્, અનેનૈવ સ્વચક્ષુષા।\nદિવ્યં(ન્) દદામિ તે ચક્ષુઃ(ફ્), પશ્ય મે યોગમૈશ્વરમ્ ॥૧૧.૮॥",

    "સઞ્જય ઉવાચ\nએવમુક્ત્વા તતો રાજન્, મહાયોગેશ્વરો હરિઃ।\nદર્શયામાસ પાર્થાય, પરમં(મ્) રૂપમૈશ્વરમ્ ॥૧૧.૯॥",

    "અનેકવક્ત્રનયનમ્, અનેકાદ્ભુતદર્શનમ્।\nઅનેકદિવ્યાભરણં(ન્), દિવ્યાનેકોદ્યતાયુધમ્ ॥૧૧.૧૦॥",

    "દિવ્યમાલ્યામ્બરધરં(ન્), દિવ્યગન્ધાનુલેપનમ્।\nસર્વાશ્ચર્યમયં(ન્) દેવમ્, અનન્તં(વ્ઁ) વિશ્વતોમુખમ્ ॥૧૧.૧૧॥",

    "દિવિસૂર્યસહસ્રસ્ય, ભવેદ્યુગપદુત્થિતા।\nયદિ ભાઃ(સ્) સદૃશી સા સ્યાદ્, ભાસસ્તસ્ય મહાત્મનઃ ॥૧૧.૧૨॥",

    "તત્રૈકસ્થં(ઞ્) જગત્કૃત્સ્નં(મ્), પ્રવિભક્તમનેકધા।\nઅપશ્યદ્દેવદેવસ્ય, શરીરે પાણ્ડવસ્તદા ॥૧૧.૧૩॥",

    "તતઃ(સ્) સ વિસ્મયાવિષ્ટો હૃષ્ટરોમા ધનઞ્જયઃ।\nપ્રણમ્ય શિરસા દેવં(ઙ્), કૃતાઞ્જલિરભાષત ॥૧૧.૧૪॥",

    "અર્જુન ઉવાચ\nપશ્યામિ દેવાંસ્તવ દેવ દેહે,\nસર્વાંસ્તથા ભૂતવિશેષસઙ્ઘાન્।\nબ્રહ્માણમીશં(ઙ્) કમલાસનસ્થમ્,\nઋષીંશ્ચ સર્વાનુરગાંશ્ચ દિવ્યાન્ ॥૧૧.૧૫॥",

    "અનેકબાહૂદરવક્ત્રનેત્રં(મ્),\nપશ્યામિ ત્વાં(મ્) સર્વતોઽનન્તરૂપમ્।\nનાન્તં(ન્) ન મધ્યં(ન્) ન પુનસ્તવાદિં(મ્),\nપશ્યામિ વિશ્વેશ્વર વિશ્વરૂપ ॥૧૧.૧૬॥",

    "કિરીટિનં(ઙ્) ગદિનં(ઞ્) ચક્રિણં(ઞ્) ચ\nતેજોરાશિં(મ્) સર્વતો દીપ્તિમન્તમ્।\nપશ્યામિ ત્વાં(ન્) દુર્નિરીક્ષ્યં(મ્) સમન્તાદ્- \nદીપ્તાનલાર્કદ્યુતિમપ્રમેયમ્  ॥૧૧.૧૭॥",

    "ત્વમક્ષરં(મ્) પરમં(વ્ઁ) વેદિતવ્યં(ન્),\nત્વમસ્ય વિશ્વસ્ય પરં(ન્) નિધાનમ્।\nત્વમવ્યયઃ(શ્) શાશ્વતધર્મગોપ્તા\nસનાતનસ્ત્વં(મ્) પુરુષો મતો મે ॥૧૧.૧૮॥",

    "અનાદિમધ્યાન્તમનન્તવીર્યમ્,\nઅનન્તબાહું(મ્) શશિસૂર્યનેત્રમ્।\nપશ્યામિ ત્વાં(ન્) દીપ્તહુતાશવક્ત્રં(મ્)\nસ્વતેજસા વિશ્વમિદં(ન્) તપન્તમ્ ॥૧૧.૧૯॥",

    "દ્યાવાપૃથિવ્યોરિદમન્તરં(મ્) હિ,\nવ્યાપ્તં(ન્) ત્વયૈકેન દિશશ્ચ સર્વાઃ।\nદૃષ્ટ્વાદ્ભુતં(મ્) રૂપમુગ્રં(ન્) તવેદં(મ્),\nલોકત્રયં(મ્) પ્રવ્યથિતં(મ્) મહાત્મન્ ॥૧૧.૨૦॥",

    "અમી હિ ત્વાં(મ્) સુરસઙ્ઘા વિશન્તિ,\nકેચિદ્ભીતાઃ(ફ્) પ્રાઞ્જલયો ગૃણન્તિ।\nસ્વસ્તીત્યુક્ત્વા મહર્ષિસિદ્ધસઙ્ઘાઃ(સ્)\nસ્તુવન્તિ ત્વાં(મ્) સ્તુતિભિઃ(ફ્) પુષ્કલાભિઃ ॥૧૧.૨૧॥",

    "રુદ્રાદિત્યા વસવો યે ચ સાધ્યા\nવિશ્વેઽશ્વિનૌ મરુતશ્ચોષ્મપાશ્ચ।\nગન્ધર્વયક્ષાસુરસિદ્ધસઙ્ઘા\nવીક્ષન્તે ત્વાં(વ્ઁ) વિસ્મિતાશ્ચૈવ સર્વે ॥૧૧.૨૨॥",

    "રૂપં(મ્) મહત્તે બહુવક્ત્રનેત્રં(મ્),\nમહાબાહો બહુબાહૂરુપાદમ્।\nબહૂદરં(મ્) બહુદંષ્ટ્રાકરાલં(ન્),\nદૃષ્ટ્વા લોકાઃ(ફ્) પ્રવ્યથિતાસ્તથાહમ્ ॥૧૧.૨૩॥",

    "નભઃસ્પૃશં(ન્) દીપ્તમનેકવર્ણં(મ્),\nવ્યાત્તાનનં(ન્) દીપ્તવિશાલનેત્રમ્।\nદૃષ્ટ્વા હિ ત્વાં(મ્) પ્રવ્યથિતાન્તરાત્મા,\nધૃતિં ન વિન્દામિ શમં ચ વિષ્ણો ॥૧૧.૨૪॥",

    "દંષ્ટ્રાકરાલાનિ ચ તે મુખાનિ,\nદૃષ્ટ્વૈવ કાલાનલસન્નિભાનિ।\nદિશો ન જાને ન લભે ચ શર્મ\nપ્રસીદ દેવેશ જગન્નિવાસ ॥૧૧.૨૫॥",

    "અમી ચ ત્વાં(ન્) ધૃતરાષ્ટ્રસ્ય પુત્રાઃ(સ્)\nસર્વે સહૈવાવનિપાલસઙ્ઘૈઃ।\nભીષ્મો દ્રોણઃ(સ્) સૂતપુત્રસ્તથાસૌ,\nસહાસ્મદીયૈરપિ યોધમુખ્યૈઃ ॥૧૧.૨૬॥",

    "વક્ત્રાણિ તે ત્વરમાણા વિશન્તિ,\nદંષ્ટ્રાકરાલાનિ ભયાનકાનિ।\nકેચિદ્વિલગ્ના દશનાન્તરેષુ,\nસન્દૃશ્યન્તે ચૂર્ણિતૈરુત્તમાઙ્ગૈઃ ॥૧૧.૨૭॥",

    "યથા નદીનાં(મ્) બહવોઽમ્બુવેગાઃ(સ્),\nસમુદ્રમેવાભિમુખા દ્રવન્તિ।\nતથા તવામી નરલોકવીરા,\nવિશન્તિ વક્ત્રાણ્યભિવિજ્વલન્તિ ॥૧૧.૨૮॥",

    "યથા પ્રદીપ્તં((ઞ્) જ્વલનં(મ્) પતઙ્ગા,\nવિશન્તિ નાશાય સમૃદ્ધવેગાઃ।\nતથૈવ નાશાય વિશન્તિ લોકા:(સ્),\nતવાપિ વક્ત્રાણિ સમૃદ્ધવેગાઃ ॥૧૧.૨૯॥",

    "લેલિહ્યસે ગ્રસમાનઃ(સ્) સમન્તાલ્,\nલોકાન્સમગ્રાન્વદનૈર્જ્વલદ્ભિઃ।\nતેજોભિરાપૂર્ય જગત્સમગ્રં(મ્)\nભાસસ્તવોગ્રાઃ(ફ્) પ્રતપન્તિ વિષ્ણો ॥૧૧.૩૦॥",

    "આખ્યાહિ મે કો ભવાનુગ્રરૂપો,\nનમોઽસ્તુ તે દેવવર પ્રસીદ।\nવિજ્ઞાતુમિચ્છામિ ભવન્તમાદ્યં(ન્),\nન હિ પ્રજાનામિ તવ પ્રવૃત્તિમ્ ॥૧૧.૩૧॥",

    "શ્રીભગવાનુવાચ\nકાલોઽસ્મિ લોકક્ષયકૃત્પ્રવૃદ્ધો,\nલોકાન્સમાહર્તુમિહ પ્રવૃત્તઃ।\nઋતેઽપિ ત્વાં(ન્) ન ભવિષ્યન્તિ સર્વે,\nયેઽવસ્થિતાઃ(ફ્) પ્રત્યનીકેષુ યોધાઃ ॥૧૧.૩૨॥",

    "તસ્માત્ત્વમુત્તિષ્ઠ યશો લભસ્વ,\nજિત્વા શત્રૂન્ ભુઙ્ક્ષ્વ રાજ્યં(મ્) સમૃદ્ધમ્।\nમયૈવૈતે નિહતાઃ(ફ્) પૂર્વમેવ,\nનિમિત્તમાત્રં(મ્) ભવ સવ્યસાચિન્ ॥૧૧.૩૩॥",

    "દ્રોણં(ઞ્) ચ ભીષ્મં(ઞ્) ચ જયદ્રથં(ઞ્) ચ,\nકર્ણં(ન્) તથાન્યાનપિ યોધવીરાન્।\nમયા હતાંસ્ત્વં(ઞ્) જહિ મા વ્યથિષ્ઠા,\nયુધ્યસ્વ જેતાસિ રણે સપત્નાન્ ॥૧૧.૩૪॥",

    "સઞ્જય ઉવાચ\nએતચ્છ્રુત્વા વચનં(ઙ્) કેશવસ્ય,\nકૃતાઞ્જલિર્વેપમાનઃ(ખ્) કિરીટી।\nનમસ્કૃત્વા ભૂય એવાહ કૃષ્ણં(મ્),\nસગદ્ગદં(મ્) ભીતભીતઃ(ફ્) પ્રણમ્ય ॥૧૧.૩૫॥",

    "અર્જુન ઉવાચ\nસ્થાને હૃષીકેશ તવ પ્રકીર્ત્યા\nજગત્પ્રહૃષ્યત્યનુરજ્યતે ચ।\nરક્ષાંસિ ભીતાનિ દિશો દ્રવન્તિ\nસર્વે નમસ્યન્તિ ચ સિદ્ધસઙ્ઘાઃ ॥૧૧.૩૬॥",

    "કસ્માચ્ચ તે ન નમેરન્મહાત્મન્\nગરીયસે બ્રહ્મણોઽપ્યાદિકર્ત્રે।\nઅનન્ત દેવેશ જગન્નિવાસ\nત્વમક્ષરં(મ્) સદસત્તત્પરં(ય્ઁ) યત્ ॥૧૧.૩૭॥",

    "ત્વમાદિદેવઃ(ફ્) પુરુષઃ(ફ્) પુરાણ:(સ્)\nત્વમસ્ય વિશ્વસ્ય પરં(ન્) નિધાનમ્।\nવેત્તાસિ વેદ્યં(ઞ્) ચ પરં(ઞ્) ચ ધામ\nત્વયા તતં(વ્ઁ) વિશ્વમનન્તરૂપ ॥૧૧.૩૮॥",

    "વાયુર્યમોઽગ્નિર્વરુણઃ(શ્) શશાઙ્કઃ(ફ્)\nપ્રજાપતિસ્ત્વં(મ્) પ્રપિતામહશ્ચ।\nનમો નમસ્તેઽસ્તુ સહસ્રકૃત્વઃ(ફ્)\nપુનશ્ચ ભૂયોઽપિ નમો નમસ્તે ॥૧૧.૩૯॥",

    "નમઃ(ફ્) પુરસ્તાદથ પૃષ્ઠતસ્તે,\nનમોઽસ્તુ તે સર્વત એવ સર્વ।\nઅનન્તવીર્યામિતવિક્રમસ્ત્વં(મ્),\nસર્વં(મ્) સમાપ્નોષિ તતોઽસિ સર્વઃ ॥૧૧.૪૦॥",

    "સખેતિ મત્વા પ્રસભં(ય્ઁ) યદુક્તં(મ્),\nહે કૃષ્ણ હે યાદવ હે સખેતિ।\nઅજાનતા મહિમાનં(ન્) તવેદં(મ્),\nમયા પ્રમાદાત્પ્રણયેન વાપિ ॥૧૧.૪૧॥",

    "યચ્ચાવહાસાર્થમસત્કૃતોઽસિ,\nવિહારશય્યાસનભોજનેષુ।\nએકોઽથવાપ્યચ્યુત તત્સમક્ષં(ન્),\nતત્ક્ષામયે ત્વામહમપ્રમેયમ્ ॥૧૧.૪૨॥",

    "પિતાસિ લોકસ્ય ચરાચરસ્ય\nત્વમસ્ય પૂજ્યશ્ચ ગુરુર્ગરીયાન્।\nન ત્વત્સમોઽસ્ત્યભ્યધિકઃ(ખ્) કુતોઽન્યો\nલોકત્રયેઽપ્યપ્રતિમપ્રભાવ ॥૧૧.૪૩॥",

    "તસ્માત્પ્રણમ્ય પ્રણિધાય કાયં(મ્),\nપ્રસાદયે ત્વામહમીશમીડ્યમ્।\nપિતેવ પુત્રસ્ય સખેવ સખ્યુઃ(ફ્),\nપ્રિયઃ(ફ્) પ્રિયાયાર્હસિ દેવ સોઢુમ્ ॥૧૧.૪૪॥",

    "અદૃષ્ટપૂર્વં(મ્) હૃષિતોઽસ્મિ દૃષ્ટ્વા,\nભયેન ચ પ્રવ્યથિતં(મ્) મનો મે।\nતદેવ મે દર્શય દેવ રૂપં(મ્),\nપ્રસીદ દેવેશ જગન્નિવાસ ॥૧૧.૪૫॥",

    "કિરીટિનં(ઙ્) ગદિનં(ઞ્) ચક્રહસ્તમ્,\nઇચ્છામિ ત્વાં(ન્) દ્રષ્ટુમહં(ન્) તથૈવ।\nતેનૈવ રૂપેણ ચતુર્ભુજેન,\nસહસ્રબાહો ભવ વિશ્વમૂર્તે ॥૧૧.૪૬॥",

    "શ્રીભગવાનુવાચ\nમયા પ્રસન્નેન તવાર્જુનેદં(મ્),\nરૂપં(મ્) પરં(ન્) દર્શિતમાત્મયોગાત્।\nતેજોમયં(વ્ઁ) વિશ્વમનન્તમાદ્યં(મ્),\nયન્મે ત્વદન્યેન ન દૃષ્ટપૂર્વમ્ ॥૧૧.૪૭॥",

    "ન વેદયજ્ઞાધ્યયનૈર્ન દાનૈ:(ર્),\nન ચ ક્રિયાભિર્ન તપોભિરુગ્રૈઃ।\nએવંરૂપઃ(શ્) શક્ય અહં(ન્) નૃલોકે,\nદ્રષ્ટું(ન્) ત્વદન્યેન કુરુપ્રવીર ॥૧૧.૪૮॥",

    "મા તે વ્યથા મા ચ વિમૂઢભાવો\nદૃષ્ટ્વા રૂપં(ઙ્) ઘોરમીદૃઙ્મમેદમ્।\nવ્યપેતભીઃ(ફ્) પ્રીતમનાઃ(ફ્) પુનસ્ત્વં(ન્)\nતદેવ મે રૂપમિદં(મ્) પ્રપશ્ય ॥૧૧.૪૯॥",

    "સઞ્જય ઉવાચ\nઇત્યર્જુનં(વ્ઁ) વાસુદેવસ્તથોક્ત્વા,\nસ્વકં(મ્) રૂપં(ન્) દર્શયામાસ ભૂયઃ।\nઆશ્વાસયામાસ ચ ભીતમેનં(મ્),\nભૂત્વા પુનઃ(સ્) સૌમ્યવપુર્મહાત્મા ॥૧૧.૫૦॥",

    "અર્જુન ઉવાચ\nદૃષ્ટ્વેદં(મ્) માનુષં(મ્) રૂપં(ન્), તવ સૌમ્યં(ઞ્) જનાર્દન।\nઇદાનીમસ્મિ સંવૃત્તઃ(સ્) સચેતાઃ(ફ્) પ્રકૃતિં(ઙ્) ગત: ॥૧૧.૫૧॥",

    "શ્રીભગવાનુવાચ\nસુદુર્દર્શમિદં(મ્) રૂપં(ન્), દૃષ્ટવાનસિ યન્મમ।\nદેવા અપ્યસ્ય રૂપસ્ય, નિત્યં(ન્) દર્શનકાઙ્ક્ષિણઃ ॥૧૧.૫૨॥",

    "નાહં(વ્ઁ) વેદૈર્ન તપસા, ન દાનેન ન ચેજ્યયા।\nશક્ય એવંવિધો દ્રષ્ટું(ન્), દૃષ્ટવાનસિ માં(ય્ઁ) યથા ॥૧૧.૫૩॥",

    "ભક્ત્યા ત્વનન્યયા શક્ય, અહમેવંવિધોઽર્જુન।\nજ્ઞાતું(ન્) દ્રષ્ટું(ઞ્) ચ તત્ત્વેન, પ્રવેષ્ટું(ઞ્) ચ પરન્તપ ॥૧૧.૫૪॥",

    "મત્કર્મકૃન્મત્પરમો, મદ્ભક્તઃ(સ્) સઙ્ગવર્જિતઃ।\nનિર્વૈરઃ(સ્) સર્વભૂતેષુ, યઃ(સ્) સ મામેતિ પાણ્ડવ ॥૧૧.૫૫॥",

    "ૐ તત્સદિતિ શ્રીમદ્ભગવદ્ગીતાસૂપનિષત્સુ બ્રહ્મવિદ્યાયાં\nયોગશાસ્ત્રે શ્રીકૃષ્ણાર્જુનસંવાદે વિશ્વરૂપદર્શનયોગો નામૈકાદશોઽધ્યાયઃ ॥૧૧॥"
  ],
  "adhyaya-12" : [
    "અર્જુન ઉવાચ\nએવં(મ્) સતતયુક્તા યે, ભક્તાસ્ત્વાં(મ્) પર્યુપાસતે।\nયેચાપ્યક્ષરમવ્યક્તં(ન્), તેષાં(ઙ્) કે યોગવિત્તમાઃ ॥૧૨.૧॥",
    
    "શ્રીભગવાનુવાચ\nમય્યાવેશ્ય મનો યે માં(ન્), નિત્યયુક્તા ઉપાસતે।\nશ્રદ્ધયા પરયોપેતાઃ(સ્), તે મે યુક્તતમા મતાઃ ॥૧૨.૨॥",
    
    "યે ત્વક્ષરમનિર્દેશ્યમ્, અવ્યક્તં(મ્) પર્યુપાસતે।\nસર્વત્રગમચિન્ત્યં(ઞ્) ચ, કૂટસ્થમચલં(ન્) ધ્રુવમ્ ॥૧૨.૩॥",
    
    "સન્નિયમ્યેન્દ્રિયગ્રામં(મ્), સર્વત્ર સમબુદ્ધયઃ।\nતે પ્રાપ્નુવન્તિ મામેવ, સર્વભૂતહિતે રતાઃ ॥૧૨.૪॥",
    
    "ક્લેશોऽધિકતરસ્તેષામ્, અવ્યક્તાસક્તચેતસામ્।\nઅવ્યક્તા હિ ગતિર્દુઃખં(ન્), દેહવદ્ભિરવાપ્યતે ॥૧૨.૫॥",
    
    "યે તુ સર્વાણિ કર્માણિ, મયિ સન્ન્યસ્ય મત્પરાઃ।\nઅનન્યેનૈવ યોગેન, માં(ન્) ધ્યાયન્ત ઉપાસતે ॥૧૨.૬॥",
    
    "તેષામહં(મ્) સમુદ્ધર્તા, મૃત્યુસંસારસાગરાત્।\nભવામિ નચિરાત્પાર્થ, મય્યાવેશિતચેતસામ્ ॥૧૨.૭॥",
    
    "મય્યેવ મન આધત્સ્વ, મયિ બુદ્ધિં(ન્) નિવેશય।\nનિવસિષ્યસિ મય્યેવ, અત ઊર્ધ્વં(ન્) ન સંશયઃ ॥૧૨.૮॥",
    
    "અથ ચિત્તં(મ્) સમાધાતું(ન્), ન શક્નોષિ મયિ સ્થિરમ્।\nઅભ્યાસયોગેન તતો, મામિચ્છાપ્તું(ન્) ધનઞ્જય ॥૧૨.૯॥",
    
    "અભ્યાસેऽપ્યસમર્થોऽસિ, મત્કર્મપરમો ભવ।\nમદર્થમપિ કર્માણિ, કુર્વન્સિદ્ધિમવાપ્સ્યસિ ॥૧૨.૧૦॥",

    "અથૈતદપ્યશક્તોऽસિ, કર્તું(મ્) મદ્યોગમાશ્રિતઃ।\nસર્વકર્મફલત્યાગં(ન્), તતઃ(ખ્) કુરુ યતાત્મવાન્ ॥૧૨.૧૧॥",

    "શ્રેયો હિ જ્ઞાનમભ્યાસાજ્, જ્ઞાનાદ્ધ્યાનં(વ્ઁ) વિશિષ્યતે।\nધ્યાનાત્કર્મફલત્યાગઃ(સ્), ત્યાગાચ્છાન્તિરનન્તરમ્ ॥૧૨.૧૨॥",

    "અદ્વેષ્ટા સર્વભૂતાનાં(મ્), મૈત્રઃ(ખ્) કરુણ એવ ચ।\nનિર્મમો નિરહઙ્કારઃ(સ્), સમદુઃખસુખઃ ક્ષમી ॥૧૨.૧૩॥",

    "સન્તુષ્ટઃ(સ્) સતતં(ય્ઁ) યોગી, યતાત્મા દૃઢનિશ્ચયઃ।\nમય્યર્પિતમનોબુદ્ધિઃ(ર્), યો મદ્ભક્તઃ(સ્) સ મે પ્રિયઃ ॥૧૨.૧૪॥",

    "યસ્માન્નોદ્વિજતે લોકો, લોકાન્નોદ્વિજતે ચ યઃ।\nહર્ષામર્ષભયોદ્વેગૈઃ(ર્), મુક્તો યઃ(સ્) સ ચ મે પ્રિયઃ ॥૧૨.૧૫॥",

    "અનપેક્ષઃ(શ્) શુચિર્દક્ષ, ઉદાસીનો ગતવ્યથઃ।\nસર્વારમ્ભપરિત્યાગી, યો મદ્ભક્તઃ(સ્) સ મે પ્રિયઃ ॥૧૨.૧૬॥",

    "યો ન હૃષ્યતિ ન દ્વેષ્ટિ, ન શોચતિ ન કાઙ્ક્ષતિ।\nશુભાશુભપરિત્યાગી, ભક્તિમાન્યઃ(સ્) સ મે પ્રિયઃ ॥૧૨.૧૭॥",

    "સમઃ(શ્) શત્રૌ ચ મિત્રે ચ, તથા માનાપમાનયોઃ।\nશીતોષ્ણસુખદુઃખેષુ, સમઃ(સ્) સઙ્ગવિવર્જિતઃ ॥૧૨.૧૮॥",

    "તુલ્યનિન્દાસ્તુતિર્મૌની, સન્તુષ્ટો યેન કેનચિત્।\nઅનિકેતઃ(સ્) સ્થિરમતિઃ(ર્), ભક્તિમાન્મે પ્રિયો નરઃ ॥૧૨.૧૯॥",

    "યે તુ ધર્મ્યામૃતમિદં(ય્ઁ), યથોક્તં(મ્) પર્યુપાસતે।\nશ્રદ્દધાના મત્પરમા, ભક્તાસ્તેऽતીવ મે પ્રિયાઃ ॥૧૨.૨૦॥",

    "ૐ તત્સદિતિ શ્રીમદ્ભગવદ્ગીતાસુ ઉપનિષત્સુ બ્રહ્મવિદ્યાયાં(ય્ઁ)\nયોગશાસ્ત્રે શ્રીકૃષ્ણાર્જુનસંવાદે ભક્તિયોગો નામ દ્વાદશોઽધ્યાયઃ ॥૧૨॥"
  ],
  "adhyaya-13" : [
    "શ્રીભગવાનુવાચ\nઇદં(મ્) શરીરં(ઙ્) કૌન્તેય, ક્ષેત્રમિત્યભિધીયતે।\nએતદ્યો વેત્તિ તં(મ્) પ્રાહુઃ, ક્ષેત્રજ્ઞ ઇતિ તદ્વિ દઃ॥૧૩.૧॥",

    "ક્ષેત્રજ્ઞં(ઞ્) ચાપિ માં(વ્ઁ) વિદ્ધિ, સર્વક્ષેત્રેષુ ભારત।\nક્ષેત્રક્ષેત્રજ્ઞયોર્જ્ઞાનં(ય્ઁ), યત્તજ્જ્ઞાનં(મ્) મતં(મ્) મ મ॥૧૩.૨॥",

    "તત્ક્ષેત્રં(ય્ઁ) યચ્ચ યાદૃક્ચ, યદ્વિકારિ યતશ્ચ યત્।\nસ ચ યો યત્પ્રભાવશ્ચ, તત્સમાસેન મે શૃ ણુ॥૧૩.૩॥",

    "ઋષિભિર્બહુધા ગીતં(ઞ્), છન્દોભિર્વિવિધૈઃ(ફ્) પૃથક્।\nબ્રહ્મસૂત્રપદૈશ્ચૈવ, હેતુમદ્ભિર્વિનિશ્ચિ તૈઃ॥૧૩.૪॥",

    "મહાભૂતાન્યહઙ્કારો, બુદ્ધિરવ્યક્તમેવ ચ।\nઇન્દ્રિયાણિ દશૈકં(ઞ્) ચ, પઞ્ચ ચેન્દ્રિયગોચ રાઃ॥૧૩.૫॥",

    "ઇચ્છા દ્વેષઃ(સ્) સુખં(ન્) દુઃખં(મ્), સઙ્ઘાતશ્ચેતના ધૃતિઃ।\nએતત્ક્ષેત્રં(મ્) સમાસેન, સવિકારમુદાહૃત મ્॥૧૩.૬॥",

    "અમાનિત્વમદમ્ભિત્વમ્, અહિંસા ક્ષાન્તિરાર્જવમ્।\nઆચાર્યોપાસનં(મ્) શૌચં(મ્), સ્થૈર્યમાત્મવિનિગ્ર હઃ॥૧૩.૭॥",

    "ઇન્દ્રિયાર્થેષુ વૈરાગ્યમ્, અનહઙ્કાર એવ ચ।\nજન્મમૃત્યુજરાવ્યાધિ, દુઃખદોષાનુદર્શન મ્॥૧૩.૮॥",

    "અસક્તિરનભિષ્વઙ્ગઃ(ફ્), પુત્રદારગૃહાદિષુ।\nનિત્યં(ઞ્) ચ સમચિત્તત્વમ્, ઇષ્ટાનિષ્ટોપપત્તિ ષુ॥૧૩.૯॥",

    "મયિ ચાનન્યયોગેન, ભક્તિરવ્યભિચારિણી।\nવિવિક્તદેશસેવિત્વમ્, અરતિર્જનસંસદિ ॥૧૩.૧૦॥",

    "અધ્યાત્મજ્ઞાનનિત્યત્વં(ન્), તત્ત્વજ્ઞાનાર્થદર્શનમ્।\nએતજ્જ્ઞાનમિતિ પ્રોક્તમ્, અજ્ઞાનં(ય્ઁ) યદતોઽન્યથા ॥૧૩.૧૧॥",

    "જ્ઞેયં(ય્ઁ) યત્તત્પ્રવક્ષ્યામિ, યજ્જ્ઞાત્વામૃતમશ્નુતે।\nઅનાદિમત્પરં(મ્) બ્રહ્મ, ન સત્તન્નાસદુચ્યતે ॥૧૩.૧૨॥",

    "સર્વતઃ(ફ્) પાણિપાદં(ન્) તત્, સર્વતોઽક્ષિશિરોમુખમ્।\nસર્વતઃ(શ્) શ્રુતિમલ્લોકે, સર્વમાવૃત્ય તિષ્ઠતિ ॥૧૩.૧૩॥",

    "સર્વેન્દ્રિયગુણાભાસં(મ્), સર્વેન્દ્રિયવિવર્જિતમ્।\nઅસક્તં(મ્) સર્વભૃચ્ચૈવ, નિર્ગુણં(ઙ્) ગુણભોક્તૃ ચ ॥૧૩.૧૪॥",

    "બહિરન્તશ્ચ ભૂતાનામ્, અચરં(ઞ્) ચરમેવ ચ।\nસૂક્ષ્મત્વાત્તદવિજ્ઞેયં(ન્), દૂરસ્થં(ઞ્) ચાન્તિકે ચ તત્ ॥૧૩.૧૫॥",

    "અવિભક્તં(ઞ્) ચ ભૂતેષુ, વિભક્તમિવ ચ સ્થિતમ્।\nભૂતભર્તૃ ચ તજ્જ્ઞેયં(ઙ્), ગ્રસિષ્ણુ પ્રભવિષ્ણુ ચ ॥૧૩.૧૬॥",

    "જ્યોતિષામપિ તજ્જ્યોતિ:(સ્), તમસઃ(ફ્) પરમુચ્યતે।\nજ્ઞાનં(ઞ્) જ્ઞેયં(ઞ્) જ્ઞાનગમ્યં(મ્), હૃદિ સર્વસ્ય વિષ્ઠિતમ્ ॥૧૩.૧૭॥",

    "ઇતિ ક્ષેત્રં(ન્) તથા જ્ઞાનં(ઞ્), જ્ઞેયં(ઞ્) ચોક્તં(મ્) સમાસતઃ।\nમદ્ભક્ત એતદ્વિજ્ઞાય, મદ્ભાવાયોપપદ્યતે ॥૧૩.૧૮॥",

    "પ્રકૃતિં(મ્) પુરુષં(ઞ્) ચૈવ, વિદ્ધ્યનાદી ઉભાવપિ।\nવિકારાંશ્ચ ગુણાંશ્ચૈવ, વિદ્ધિ પ્રકૃતિસમ્ભવાન્ ॥૧૩.૧૯॥",

    "કાર્યકરણકર્તૃત્વે, હેતુઃ(ફ્) પ્રકૃતિરુચ્યતે।\nપુરુષઃ(સ્) સુખદુઃખાનાં(મ્), ભોક્તૃત્વે હેતુરુચ્યતે ॥૧૩.૨૦॥",

    "પુરુષઃ(ફ્) પ્રકૃતિસ્થો હિ, ભુઙ્ક્તે પ્રકૃતિજાન્ગુણાન્।\nકારણં(ઙ્) ગુણસઙ્ગોઽસ્ય, સદસદ્યોનિજન્મસુ ॥૧૩.૨૧॥",

    "ઉપદ્રષ્ટાનુમન્તા ચ, ભર્તા ભોક્તા મહેશ્વરઃ।\nપરમાત્મેતિ ચાપ્યુક્તો, દેહેઽસ્મિન્પુરુષઃ(ફ્) પરઃ ॥૧૩.૨૨॥",

    "ય એવં(વ્ઁ) વેત્તિ પુરુષં(મ્), પ્રકૃતિં(ઞ્) ચ ગુણૈઃ(સ્) સહ।\nસર્વથા વર્તમાનોઽપિ, ન સ ભૂયોઽભિજાયતે ॥૧૩.૨૩॥",

    "ધ્યાનેનાત્મનિ પશ્યન્તિ, કેચિદાત્માનમાત્મના।\nઅન્યે સાઙ્ખ્યેન યોગેન, કર્મયોગેન ચાપરે ॥૧૩.૨૪॥",

    "અન્યે ત્વેવમજાનન્તઃ(શ્), શ્રુત્વાન્યેભ્ય ઉપાસતે।\nતેઽપિ ચાતિતરન્ત્યેવ, મૃત્યું(મ્) શ્રુતિપરાયણાઃ ॥૧૩.૨૫॥",

    "યાવત્સઞ્જાયતે કિઞ્ચિત્, સત્ત્વં(મ્) સ્થાવરજઙ્ગમમ્।\nક્ષેત્રક્ષેત્રજ્ઞસંયોગાત્, તદ્વિદ્ધિ ભરતર્ષભ ॥૧૩.૨૬॥",

    "સમં(મ્) સર્વેષુ ભૂતેષુ, તિષ્ઠન્તં(મ્) પરમેશ્વરમ્।\nવિનશ્યત્સ્વવિનશ્યન્તં(ય્ઁ), યઃ(ફ્) પશ્યતિ સ પશ્યતિ ॥૧૩.૨૭॥",

    "સમં(મ્) પશ્યન્હિ સર્વત્ર, સમવસ્થિતમીશ્વરમ્।\nન હિનસ્ત્યાત્મનાત્માનં(ન્), તતો યાતિ પરાં(ઙ્) ગતિમ્ ॥૧૩.૨૮॥",

    "પ્રકૃત્યૈવ ચ કર્માણિ, ક્રિયમાણાનિ સર્વશઃ।\nયઃ(ફ્) પશ્યતિ તથાત્માનમ્, અકર્તારં(મ્) સ પશ્યતિ ॥૧૩.૨૯॥",

    "યદા ભૂતપૃથગ્ભાવમ્, એકસ્થમનુપશ્યતિ।\nતત એવ ચ વિસ્તારં(મ્), બ્રહ્મ સમ્પદ્યતે તદા ॥૧૩.૩૦॥",

    "અનાદિત્વાન્નિર્ગુણત્વાત્, પરમાત્માયમવ્યયઃ।\nશરીરસ્થોઽપિ કૌન્તેય, ન કરોતિ ન લિપ્યતે ॥૧૩.૩૧॥",

    "યથા સર્વગતં(મ્) સૌક્ષ્મ્યાદ્, આકાશં(ન્) નોપલિપ્યતે।\nસર્વત્રાવસ્થિતો દેહે, તથાત્મા નોપલિપ્યતે ॥૧૩.૩૨॥",

    "યથા પ્રકાશયત્યેકઃ(ખ્), કૃત્સ્નં(લ્ઁ) લોકમિમં(મ્) રવિઃ।\nક્ષેત્રં(ઙ્) ક્ષેત્રી તથા કૃત્સ્નં(મ્), પ્રકાશયતિ ભારત ॥૧૩.૩૩॥",

    "ક્ષેત્રક્ષેત્રજ્ઞયોરેવમ્, અન્તરં(ઞ્) જ્ઞાનચક્ષુષા।\nભૂતપ્રકૃતિમોક્ષં(ઞ્) ચ, યે વિદુર્યાન્તિ તે પરમ્ ॥૧૩.૩૪॥",

    "ૐ તત્સદિતિ શ્રીમદ્ભગવદ્ગીતાસુ ઉપનિષત્સુ બ્રહ્મવિદ્યાયાં(ય્ઁ) \nયોગશાસ્ત્રે શ્રીકૃષ્ણાર્જુનસંવાદે ક્ષેત્રક્ષેત્રજ્ઞવિભાગયોગો નામ ત્રયોદશોઽધ્યાયઃ ॥૧૩॥"
  ],
  "adhyaya-14" : [
    "શ્રીભગવાનુવાચ\nપરં(મ્) ભૂયઃ(ફ્) પ્રવક્ષ્યામિ, જ્ઞાનાનાં(ઞ્) જ્ઞાનમુત્તમમ્।\nયજ્જ્ઞાત્વા મુનયઃ(સ્) સર્વે, પરાં(મ્) સિદ્ધિમિતો ગતાઃ ॥૧૪.૧॥",

    "ઇદં(ઞ્) જ્ઞાનમુપાશ્રિત્ય, મમ સાધર્મ્યમાગતાઃ।\nસર્ગેઽપિ નોપજાયન્તે, પ્રલયે ન વ્યથન્તિ ચ ॥૧૪.૨॥",

    "મમ યોનિર્મહદ્બ્રહ્મ, તસ્મિન્ગર્ભં(ન્) દધામ્યહમ્।\nસમ્ભવઃ(સ્) સર્વભૂતાનાં(ન્), તતો ભવતિ ભારત ॥૧૪.૩॥",

    "સર્વયોનિષુ કૌન્તેય, મૂર્તયઃ(સ્) સમ્ભવન્તિ યાઃ।\nતાસાં(મ્) બ્રહ્મ મહદ્યોનિઃ(ર્), અહં(મ્) બીજપ્રદઃ(ફ્) પિતા ॥૧૪.૪॥",

    "સત્ત્વં(મ્) રજસ્તમ ઇતિ, ગુણાઃ(ફ્) પ્રકૃતિસમ્ભવાઃ।\nનિબધ્નન્તિ મહાબાહો, દેહે દેહિનમવ્યયમ્ ॥૧૪.૫॥",

    "તત્ર સત્ત્વં(ન્) નિર્મલત્વાત્, પ્રકાશકમનામયમ્।\nસુખસઙ્ગેન બધ્નાતિ, જ્ઞાનસઙ્ગેન ચાનઘ ॥૧૪.૬॥",

    "રજો રાગાત્મકં(વ્ઁ) વિદ્ધિ, તૃષ્ણાસઙ્ગસમુદ્ભવમ્।\nતન્નિબધ્નાતિ કૌન્તેય, કર્મસઙ્ગેન દેહિનમ્ ॥૧૪.૭॥",

    "તમસ્ત્વજ્ઞાનજં(વ્ઁ) વિદ્ધિ, મોહનં(મ્) સર્વદેહિનામ્।\nપ્રમાદાલસ્યનિદ્રાભિઃ(સ્), તન્નિબધ્નાતિ ભારત ॥૧૪.૮॥",

    "સત્ત્વં(મ્) સુખે સઞ્જયતિ, રજઃ(ખ્) કર્મણિ ભારત।\nજ્ઞાનમાવૃત્ય તુ તમઃ(ફ્), પ્રમાદે સઞ્જયત્યુત ॥૧૪.૯॥",

    "રજસ્તમશ્ચાભિભૂય, સત્ત્વં(મ્) ભવતિ ભારત।\nરજઃ(સ્) સત્ત્વં(ન્) તમશ્ચૈવ, તમઃ(સ્) સત્ત્વં(મ્) રજસ્તથા ॥૧૪.૧૦॥",

    "સર્વદ્વારેષુ દેહેઽસ્મિન્, પ્રકાશ ઉપજાયતે।\nજ્ઞાનં(ય્ઁ) યદા તદા વિદ્યાદ્, વિવૃદ્ધં(મ્) સત્ત્વમિત્યુત ॥૧૪.૧૧॥",

    "લોભઃ(ફ્) પ્રવૃત્તિરારમ્ભઃ(ખ્), કર્મણામશમઃ(સ્) સ્પૃહા।\nરજસ્યેતાનિ જાયન્તે, વિવૃદ્ધે ભરતર્ષભ ॥૧૪.૧૨॥",

    "અપ્રકાશોઽપ્રવૃત્તિશ્ચ, પ્રમાદો મોહ એવ ચ।\nતમસ્યેતાનિ જાયન્તે, વિવૃદ્ધે કુરુનન્દન ॥૧૪.૧૩॥",

    "યદા સત્ત્વે પ્રવૃદ્ધે તુ, પ્રલયં(ય્ઁ) યાતિ દેહભૃત્।\nતદોત્તમવિદાં(લ્ઁ) લોકાન્, અમલાન્પ્રતિપદ્યતે ॥૧૪.૧૪॥",

    "રજસિ પ્રલયં(ઙ્) ગત્વા, કર્મસઙ્ગિષુ જાયતે।\nતથા પ્રલીનસ્તમસિ, મૂઢયોનિષુ જાયતે ॥૧૪.૧૫॥",

    "કર્મણઃ(સ્) સુકૃતસ્યાહુઃ(સ્), સાત્ત્વિકં(ન્) નિર્મલં(મ્) ફલમ્।\nરજસસ્તુ ફલં(ન્) દુઃખમ્, અજ્ઞાનં(ન્) તમસઃ(ફ્) ફલમ્ ॥૧૪.૧૬॥",

    "સત્ત્વાત્સઞ્જાયતે જ્ઞાનં(મ્), રજસો લોભ એવ ચ।\nપ્રમાદમોહૌ તમસો, ભવતોઽજ્ઞાનમેવ ચ ॥૧૪.૧૭॥",

    "ઊર્ધ્વં(ઙ્) ગચ્છન્તિ સત્ત્વસ્થા, મધ્યે તિષ્ઠન્તિ રાજસાઃ।\nજઘન્યગુણવૃત્તિસ્થા, અધો ગચ્છન્તિ તામસાઃ ॥૧૪.૧૮॥",

    "નાન્યં(ઙ્) ગુણેભ્યઃ(ખ્) કર્તારં(ય્ઁ), યદા દ્રષ્ટાનુપશ્યતિ।\nગુણેભ્યશ્ચ પરં(વ્ઁ) વેત્તિ, મદ્ભાવં(મ્) સોઽધિગચ્છતિ ॥૧૪.૧૯॥",

    "ગુણાનેતાનતીત્ય ત્રીન્, દેહી દેહસમુદ્ભવાન્।\nજન્મમૃત્યુજરાદુઃખૈઃ(ર્), વિમુક્તોઽમૃતમશ્નુતે ॥૧૪.૨૦॥",

    "અર્જુન ઉવાચ\nકૈર્લિંગૈસ્ત્રીન્ગુણાનેતાન્, અતીતો ભવતિ પ્રભો।\nકિમાચારઃ(ખ્) કથં(ઞ્) ચૈતાંસ્, ત્રીન્ગુણાનતિવર્તતે ॥૧૪.૨૧॥",

    "શ્રીભગવાનુવાચ\nપ્રકાશં(ઞ્) ચ પ્રવૃત્તિં(ઞ્) ચ, મોહમેવ ચ પાણ્ડવ।\nન દ્વેષ્ટિ સમ્પ્રવૃત્તાનિ, ન નિવૃત્તાનિ કાઙ્ક્ષતિ ॥૧૪.૨૨॥",

    "ઉદાસીનવદાસીનો, ગુણૈર્યો ન વિચાલ્યતે।\nગુણા વર્તન્ત ઇત્યેવ, યોઽવતિષ્ઠતિ નેઙ્ગતે ॥૧૪.૨૩॥",

    "સમદુઃખસુખઃ(સ્) સ્વસ્થઃ(સ્), સમલોષ્ટાશ્મકાઞ્ચનઃ।\nતુલ્યપ્રિયાપ્રિયો ધીરઃ(સ્), તુલ્યનિન્દાત્મસંસ્તુતિઃ ॥૧૪.૨૪॥",

    "માનાપમાનયોસ્તુલ્યઃ(સ્), તુલ્યો મિત્રારિપક્ષયોઃ।\nસર્વારમ્ભપરિત્યાગી, ગુણાતીતઃ(સ્) સ ઉચ્યતે ॥૧૪.૨૫॥",

    "માં(ઞ્) ચ યોઽવ્યભિચારેણ, ભક્તિયોગેન સેવતે।\nસ ગુણાન્સમતીત્યૈતાન્, બ્રહ્મભૂયાય કલ્પતે ॥૧૪.૨૬॥",

    "બ્રહ્મણો હિ પ્રતિષ્ઠાહમ્, અમૃતસ્યાવ્યયસ્ય ચ।\nશાશ્વતસ્ય ચ ધર્મસ્ય, સુખસ્યૈકાન્તિકસ્ય ચ ॥૧૪.૨૭॥",

    "ૐ તત્સદિતિ શ્રીમદ્ભગવદ્ગીતાસુ ઉપનિષત્સુ બ્રહ્મવિદ્યાયાં(ય્ઁ) \nયોગશાસ્ત્રે શ્રીકૃષ્ણાર્જુનસંવાદે ગુણત્રયવિભાગયોગો નામ ચતુર્દશોઽધ્યાયઃ ॥૧૪॥"
  ],
  "adhyaya-15" : [
    "શ્રીભગવાનુવાચ\nઊર્ધ્વમૂલમધઃ(શ્) શાખમ્, અશ્વત્થં(મ્) પ્રાહુરવ્યયમ્।\nછન્દાંસિ યસ્ય પર્ણાનિ, યસ્તં(વ્ઁ) વેદ સ વેદવિત્ ॥૧૫.૧॥",

    "અધશ્ચોર્ધ્વં(મ્) પ્રસૃતાસ્તસ્ય શાખા,\nગુણપ્રવૃદ્ધા વિષયપ્રવાલાઃ।\nઅધશ્ચ મૂલાન્યનુસન્તતાનિ,\nકર્માનુબન્ધીનિ મનુષ્યલોકે ॥૧૫.૨॥",

    "ન રૂપમસ્યેહ તથોપલભ્યતે,\nનાન્તો ન ચાદિર્ન ચ સમ્પ્રતિષ્ઠા।\nઅશ્વત્થમેનં(મ્) સુવિરૂઢમૂલમ્,\nઅસઙ્ગશસ્ત્રેણ દૃઢેન છિત્ત્વા ॥૧૫.૩॥",

    "તતઃ(ફ્) પદં(ન્) તત્પરિમાર્ગિતવ્યં(ય્ઁ),\nયસ્મિન્ગતા ન નિવર્તન્તિ ભૂયઃ।\nતમેવ ચાદ્યં(મ્) પુરુષં(મ્) પ્રપદ્યે,\nયતઃ(ફ્) પ્રવૃત્તિઃ(ફ્) પ્રસૃતા પુરાણી ॥૧૫.૪॥",

    "નિર્માનમોહા જિતસઙ્ગદોષા,\nઅધ્યાત્મનિત્યા વિનિવૃત્તકામાઃ।\nદ્વન્દ્વૈર્વિમુક્તાઃ(સ્) સુખદુઃખસઞ્જ્ઞૈઃ(ર્),\nગચ્છન્ત્યમૂઢાઃ(ફ્) પદમવ્યયં(ન્) તત્ ॥૧૫.૫॥",

    "ન તદ્ભાસયતે સૂર્યો, ન શશાઙ્કો ન પાવકઃ।\nયદ્ગત્વા ન નિવર્તન્તે, તદ્ધામ પરમં(મ્) મમ ॥૧૫.૬॥",

    "મમૈવાંશો જીવલોકે, જીવભૂતઃ(સ્) સનાતનઃ।\nમનઃ(ષ્) ષષ્ઠાનીન્દ્રિયાણિ, પ્રકૃતિસ્થાનિ કર્ષતિ ॥૧૫.૭॥",

    "શરીરં(ય્ઁ) યદવાપ્નોતિ, યચ્ચાપ્યુત્ક્રામતીશ્વરઃ।\nગૃહીત્વૈતાનિ સંયાતિ, વાયુર્ગન્ધાનિવાશયાત્ ॥૧૫.૮॥",

    "શ્રોત્રં(ઞ્) ચક્ષુઃ(સ્) સ્પર્શનં(ઞ્) ચ, રસનં(ઙ્) ઘ્રાણમેવ ચ।\nઅધિષ્ઠાય મનશ્ચાયં(વ્ઁ) વિષયાનુપસેવતે ॥૧૫.૯॥",

    "ઉત્ક્રામન્તં(મ્) સ્થિતં(વ્ઁ) વાપિ, ભુઞ્જાનં(વ્ઁ) વા ગુણાન્વિતમ્।\nવિમૂઢા નાનુપશ્યન્તિ, પશ્યન્તિ જ્ઞાનચક્ષુષઃ ॥૧૫.૧૦॥",

    "યતન્તો યોગિનશ્ચૈનં(મ્), પશ્યન્ત્યાત્મન્યવસ્થિતમ્।\nયતન્તોઽપ્યકૃતાત્માનો, નૈનં(મ્) પશ્યન્ત્યચેતસઃ ॥૧૫.૧૧॥",

    "યદાદિત્યગતં(ન્) તેજો, જગદ્ભાસયતેઽખિલમ્।\nયચ્ચન્દ્રમસિ યચ્ચાગ્નૌ, તત્તેજો વિદ્ધિ મામકમ્ ॥૧૫.૧૨॥",

    "ગામાવિશ્ય ચ ભૂતાનિ, ધારયામ્યહમોજસા।\nપુષ્ણામિ ચૌષધીઃ(સ્) સર્વાઃ(સ્), સોમો ભૂત્વા રસાત્મકઃ ॥૧૫.૧૩॥",

    "અહં(વ્ઁ) વૈશ્વાનરો ભૂત્વા, પ્રાણિનાં(ન્) દેહમાશ્રિતઃ।\nપ્રાણાપાનસમાયુક્તઃ(ફ્) પચામ્યન્નં(ઞ્) ચતુર્વિધમ્ ॥૧૫.૧૪॥",

    "સર્વસ્ય ચાહં(મ્) હૃદિ સન્નિવિષ્ટો,\nમત્તઃ(સ્) સ્મૃતિર્જ્ઞાનમપોહનં(ઞ્) ચ।\nવેદૈશ્ચ સર્વૈરહમેવ વેદ્યો,\nવેદાન્તકૃદ્વેદવિદેવ ચાહમ્ ॥૧૫.૧૫॥",

    "દ્વાવિમૌ પુરુષૌ લોકે, ક્ષરશ્ચાક્ષર એવ ચ।\nક્ષરઃ(સ્) સર્વાણિ ભૂતાનિ, કૂટસ્થોઽક્ષર ઉચ્યતે ॥૧૫.૧૬॥",

    "ઉત્તમઃ(ફ્) પુરુષસ્ત્વન્યઃ(ફ્), પરમાત્મેત્યુદાહૃતઃ।\nયો લોકત્રયમાવિશ્ય, બિભર્ત્યવ્યય ઈશ્વરઃ ॥૧૫.૧૭॥",

    "યસ્માત્ક્ષરમતીતોઽહમ્, અક્ષરાદપિ ચોત્તમઃ।\nઅતોઽસ્મિ લોકે વેદે ચ, પ્રથિતઃ(ફ્) પુરુષોત્તમઃ ॥૧૫.૧૮॥",

    "યો મામેવમસમ્મૂઢો, જાનાતિ પુરુષોત્તમમ્।\nસ સર્વવિદ્ભજતિ માં(મ્), સર્વભાવેન ભારત ॥૧૫.૧૯॥",

    "ઇતિ ગુહ્યતમં(મ્) શાસ્ત્રમ્, ઇદમુક્તં(મ્) મયાઽનઘ।\nએતદ્બુદ્ધ્વા બુદ્ધિમાન્સ્યાત્, કૃતકૃત્યશ્ચ ભારત ॥૧૫.૨૦॥",

    "ૐ તત્સદિતિ શ્રીમદ્ભગવદ્ગીતાસુ ઉપનિષત્સુ બ્રહ્મવિદ્યાયાં(ય્ઁ) \nયોગશાસ્ત્રે શ્રીકૃષ્ણાર્જુનસંવાદે પુરુષોત્તમયોગો નામ પઞ્ચદશોઽધ્યાયઃ ॥૧૫॥"
  ],
  "adhyaya-16" : [
    "શ્રીભગવાનુવાચ\nઅભયં(મ્) સત્ત્વસંશુદ્ધિઃ(ર્), જ્ઞાનયોગવ્યવસ્થિતિઃ।\nદાનં(ન્) દમશ્ચ યજ્ઞશ્ચ, સ્વાધ્યાયસ્તપ આર્જવમ્ ॥૧૬.૧॥",

    "અહિંસા સત્યમક્રોધઃ(સ્), ત્યાગઃ(શ્) શાન્તિરપૈશુનમ્।\nદયા ભૂતેષ્વલોલુપ્ત્વં(મ્), માર્દવં(મ્) હ્રીરચાપલમ્ ॥૧૬.૨॥",

    "તેજઃ ક્ષમા ધૃતિઃ(શ્) શૌચમ્, અદ્રોહો નાતિમાનિતા।\nભવન્તિ સમ્પદં(ન્) દૈવીમ્, અભિજાતસ્ય ભારત ॥૧૬.૩॥",

    "દમ્ભો દર્પોઽભિમાનશ્ચ, ક્રોધઃ(ફ્) પારુષ્યમેવ ચ।\nઅજ્ઞાનં(ઞ્) ચાભિજાતસ્ય, પાર્થ સમ્પદમાસુરીમ્ ॥૧૬.૪॥",

    "દૈવી સમ્પદ્વિમોક્ષાય, નિબન્ધાયાસુરી મતા।\nમા શુચઃ(સ્) સમ્પદં(ન્) દૈવીમ્, અભિજાતોऽસિ પાણ્ડવ ॥૧૬.૫॥",

    "દ્વૌ ભૂતસર્ગૌ લોકેऽસ્મિન્, દૈવ આસુર એવ ચ।\nદૈવો વિસ્તરશઃ(ફ્) પ્રોક્ત, આસુરં(મ્) પાર્થ મે શૃણુ ॥૧૬.૬॥",

    "પ્રવૃત્તિં(ઞ્) ચ નિવૃત્તિં(ઞ્) ચ, જના ન વિદુરાસુરાઃ।\nન શૌચં(ન્) નાપિ ચાચારો, ન સત્યં(ન્) તેષુ વિદ્યતે ॥૧૬.૭॥",

    "અસત્યમપ્રતિષ્ઠં(ન્) તે, જગદાહુરનીશ્વરમ્।\nઅપરસ્પરસમ્ભૂતં(ઙ્), કિમન્યત્કામહૈતુકમ્ ॥૧૬.૮॥",

    "એતાં(ન્) દૃષ્ટિમવષ્ટભ્ય, નષ્ટાત્માનોऽલ્પબુદ્ધયઃ।\nપ્રભવન્ત્યુગ્રકર્માણઃ, ક્ષયાય જગતોऽહિતાઃ ॥૧૬.૯॥",

    "કામમાશ્રિત્ય દુષ્પૂરં(ન્), દમ્ભમાનમદાન્વિતાઃ।\nમોહાદ્ ગૃહીત્વાસદ્ગ્રાહાન્, પ્રવર્તન્તેऽશુચિવ્રતાઃ ॥૧૬.૧૦॥",

    "ચિન્તામપરિમેયાં(ઞ્) ચ, પ્રલયાન્તામુપાશ્રિતાઃ।\nકામોપભોગપરમા, એતાવદિતિ નિશ્ચિતાઃ ॥૧૬.૧૧॥",

    "આશાપાશશતૈર્બદ્ધાઃ(ખ્), કામક્રોધપરાયણાઃ।\nઈહન્તે કામભોગાર્થમ્, અન્યાયેનાર્થસઞ્ચયાન્ ॥૧૬.૧૨॥",

    "ઇદમદ્ય મયા લબ્ધમ્, ઇમં(મ્) પ્રાપ્સ્યે મનોરથમ્।\nઇદમસ્તીદમપિ મે, ભવિષ્યતિ પુનર્ધનમ્ ॥૧૬.૧૩॥",

    "અસૌ મયા હતઃ(શ્) શત્રુ:(ર્), હનિષ્યે ચાપરાનપિ।\nઈશ્વરોऽહમહં(મ્) ભોગી, સિદ્ધોऽહં(મ્) બલવાન્સુખી ॥૧૬.૧૪॥",

    "આઢ્યોऽભિજનવાનસ્મિ, કોऽન્યોऽસ્તિ સદૃશો મયા।\nયક્ષ્યે દાસ્યામિ મોદિષ્ય, ઇત્યજ્ઞાનવિમોહિતાઃ ॥૧૬.૧૫॥",

    "અનેકચિત્તવિભ્રાન્તા, મોહજાલસમાવૃતાઃ।\nપ્રસક્તાઃ(ખ્) કામભોગેષુ, પતન્તિ નરકેऽશુચૌ ॥૧૬.૧૬॥",

    "આત્મસમ્ભાવિતાઃ(સ્) સ્તબ્ધા, ધનમાનમદાન્વિતાઃ।\nયજન્તે નામયજ્ઞૈસ્તે, દમ્ભેનાવિધિપૂર્વકમ્ ॥૧૬.૧૭॥",

    "અહઙ્કારં(મ્) બલં(ન્) દર્પં(ઙ્), કામં(ઙ્) ક્રોધં(ઞ્) ચ સંશ્રિતાઃ।\nમામાત્મપરદેહેષુ, પ્રદ્વિષન્તોऽભ્યસૂયકા: ॥૧૬.૧૮॥",

    "તાનહં(ન્) દ્વિષતઃ(ખ્) ક્રૂરાન્, સંસારેષુ નરાધમાન્।\nક્ષિપામ્યજસ્રમશુભાન્, આસુરીષ્વેવ યોનિષુ ॥૧૬.૧૯॥",

    "આસુરીં(ય્ઁ) યોનિમાપન્ના, મૂઢા જન્મનિ જન્મનિ।\nમામપ્રાપ્યૈવ કૌન્તેય, તતો યાન્ત્યધમાં(ઙ્) ગતિમ્ ॥૧૬.૨૦॥",

    "ત્રિવિધં(ન્) નરકસ્યેદં(ન્), દ્વારં(ન્) નાશનમાત્મનઃ।\nકામઃ(ખ્) ક્રોધસ્તથા લોભ:(સ્), તસ્માદેતત્ત્રયં(ન્) ત્યજેત્ ॥૧૬.૨૧॥",

    "એતૈર્વિમુક્તઃ(ખ્) કૌન્તેય, તમોદ્વારૈસ્ત્રિભિર્નરઃ।\nઆચરત્યાત્મનઃ(શ્) શ્રેયઃ(સ્), તતો યાતિ પરાં(ઙ્) ગતિમ્ ॥૧૬.૨૨॥",

    "યઃ(શ્) શાસ્ત્રવિધિમુત્સૃજ્ય, વર્તતે કામકારતઃ।\nન સ સિદ્ધિમવાપ્નોતિ, ન સુખં(ન્) ન પરાં(ઙ્) ગતિમ્ ॥૧૬.૨૩॥",

    "તસ્માચ્છાસ્ત્રં(મ્) પ્રમાણં(ન્) તે, કાર્યાકાર્યવ્યવસ્થિતૌ।\nજ્ઞાત્વા શાસ્ત્રવિધાનોક્તં(ઙ્), કર્મ કર્તુમિહાર્હસિ ॥૧૬.૨૪॥",

    "ૐ તત્સદિતિ શ્રીમદ્ભગવદ્ગીતાસુ ઉપનિષત્સુ બ્રહ્મવિદ્યાયાં(ય્ઁ) \nયોગશાસ્ત્રે શ્રીકૃષ્ણાર્જુનસંવાદે દૈવાસુરસમ્પદ્વિભાગયોગો નામ ષોડશોઽધ્યાયઃ ॥૧૬॥"
  ],
  "adhyaya-17" : [
    "અર્જુન ઉવાચ\nયે શાસ્ત્રવિધિમુત્સૃજ્ય, યજન્તે શ્રદ્ધયાન્વિતાઃ।\nતેષાં(ન્) નિષ્ઠા તુ કા કૃષ્ણ, સત્ત્વમાહો રજસ્તમઃ ॥૧૭.૧॥",

    "શ્રીભગવાનુવાચ\nત્રિવિધા ભવતિ શ્રદ્ધા, દેહિનાં(મ્) સા સ્વભાવજા।\nસાત્ત્વિકી રાજસી ચૈવ, તામસી ચેતિ તાં(મ્) શૃણુ ॥૧૭.૨॥",

    "સત્ત્વાનુરૂપા સર્વસ્ય, શ્રદ્ધા ભવતિ ભારત।\nશ્રદ્ધામયો‌ऽયં(મ્) પુરુષો, યો યચ્છ્રદ્ધઃ(સ્) સ એવ સઃ ॥૧૭.૩॥",

    "યજન્તે સાત્ત્વિકા દેવાન્, યક્ષરક્ષાંસિ રાજસાઃ।\nપ્રેતાન્ભૂતગણાંશ્ચાન્યે, યજન્તે તામસા જનાઃ ॥૧૭.૪॥",

    "અશાસ્ત્રવિહિતં(ઙ્) ઘોરં(ન્), તપ્યન્તે યે તપો જનાઃ।\nદમ્ભાહઙ્કારસંયુક્તાઃ(ખ્), કામરાગબલાન્વિતાઃ ॥૧૭.૫॥",

    "કર્શયન્તઃ(શ્) શરીરસ્થં(મ્), ભૂતગ્રામમચેતસઃ।\nમાં(ઞ્) ચૈવાન્તઃ(શ્) શરીરસ્થં(ન્), તાન્વિદ્ધ્યાસુરનિશ્ચયાન્ ॥૧૭.૬॥",

    "આહારસ્ત્વપિ સર્વસ્ય, ત્રિવિધો ભવતિ પ્રિયઃ।\nયજ્ઞસ્તપસ્તથા દાનં(ન્), તેષાં(મ્) ભેદમિમં(મ્) શૃણુ ॥૧૭.૭॥",

    "આયુઃ(સ્) સત્ત્વબલારોગ્ય, સુખપ્રીતિવિવર્ધનાઃ।\nરસ્યાઃ(સ્) સ્નિગ્ધાઃ(સ્) સ્થિરા હૃદ્યા, આહારાઃ(સ્) સાત્ત્વિકપ્રિયાઃ ॥૧૭.૮॥",

    "કટ્વમ્લલવણાત્યુષ્ણ, તીક્ષ્ણરૂક્ષવિદાહિનઃ।\nઆહારા રાજસસ્યેષ્ટા, દુઃખશોકામયપ્રદાઃ ॥૧૭.૯॥",

    "યાતયામં(ઙ્) ગતરસં(મ્), પૂતિ પર્યુષિતં(ઞ્) ચ યત્।\nઉચ્છિષ્ટમપિ ચામેધ્યં(મ્), ભોજનં(ન્) તામસપ્રિયમ્ ॥૧૭.૧૦॥",

    "અફલાકાઙ્ક્ષિભિર્યજ્ઞો, વિધિદૃષ્ટો ય ઇજ્યતે।\nયષ્ટવ્યમેવેતિ મનઃ(સ્), સમાધાય સ સાત્ત્વિકઃ ॥૧૭.૧૧॥",

    "અભિસન્ધાય તુ ફલં(ન્), દમ્ભાર્થમપિ ચૈવ યત્।\nઇજ્યતે ભરતશ્રેષ્ઠ, તં(ય્ઁ) યજ્ઞં(વ્ઁ) વિદ્ધિ રાજસમ્ ॥૧૭.૧૨॥",

    "વિધિહીનમસૃષ્ટાન્નં(મ્), મન્ત્રહીનમદક્ષિણમ્।\nશ્રદ્ધાવિરહિતં(ય્ઁ) યજ્ઞં(ન્), તામસં(મ્) પરિચક્ષતે ॥૧૭.૧૩॥",

    "દેવદ્વિજગુરુપ્રાજ્ઞ, પૂજનં(મ્) શૌચમાર્જવમ્।\nબ્રહ્મચર્યમહિંસા ચ, શારીરં(ન્) તપ ઉચ્યતે ॥૧૭.૧૪॥",

    "અનુદ્વેગકરં(વ્ઁ) વાક્યં(મ્), સત્યં(મ્) પ્રિયહિતં(ઞ્) ચ યત્।\nસ્વાધ્યાયાભ્યસનં(ઞ્) ચૈવ, વાઙ્મયં(ન્) તપ ઉચ્યતે ॥૧૭.૧૫॥",

    "મનઃ(ફ્) પ્રસાદઃ(સ્) સૌમ્યત્વં(મ્), મૌનમાત્મવિનિગ્રહઃ।\nભાવસંશુદ્ધિરિત્યેતત્, તપો માનસમુચ્યતે ॥૧૭.૧૬॥",

    "શ્રદ્ધયા પરયા તપ્તં(ન્), તપસ્તત્ત્રિવિધં(ન્) નરૈઃ।\nઅફલાકાઙ્ક્ષિભિર્યુક્તૈઃ(સ્), સાત્ત્વિકં(મ્) પરિચક્ષતે ॥૧૭.૧૭॥",

    "સત્કારમાનપૂજાર્થં(ન્), તપો દમ્ભેન ચૈવ યત્।\nક્રિયતે તદિહ પ્રોક્તં(મ્), રાજસં(ઞ્) ચલમધ્રુવમ્ ॥૧૭.૧૮॥",

    "મૂઢગ્રાહેણાત્મનો યત્, પીડયા ક્રિયતે તપઃ।\nપરસ્યોત્સાદનાર્થં(વ્ઁ) વા, તત્તામસમુદાહૃતમ્ ॥૧૭.૧૯॥",

    "દાતવ્યમિતિ યદ્દાનં(ન્), દીયતે‌ऽનુપકારિણે।\nદેશે કાલે ચ પાત્રે ચ, તદ્દાનં(મ્) સાત્ત્વિકં(મ્) સ્મૃતમ્ ॥૧૭.૨૦॥",

    "યત્તુ પ્રત્યુપકારાર્થં(મ્), ફલમુદ્દિશ્ય વા પુનઃ।\nદીયતે ચ પરિક્લિષ્ટં(ન્), તદ્દાનં(મ્) રાજસં(મ્) સ્મૃતમ્ ॥૧૭.૨૧॥",

    "અદેશકાલે યદ્દાનમ્, અપાત્રેભ્યશ્ચ દીયતે।\nઅસત્કૃતમવજ્ઞાતં(ન્), તત્તામસમુદાહૃતમ્ ॥૧૭.૨૨॥",

    "ૐ તત્સદિતિ નિર્દેશો, બ્રહ્મણસ્ત્રિવિધઃ(સ્) સ્મૃતઃ।\nબ્રાહ્મણાસ્તેન વેદાશ્ચ, યજ્ઞાશ્ચ વિહિતાઃ(ફ્) પુરા ॥૧૭.૨૩॥",

    "તસ્માદોમિત્યુદાહૃત્ય, યજ્ઞદાનતપઃ(ખ્) ક્રિયાઃ।\nપ્રવર્તન્તે વિધાનોક્તાઃ(સ્), સતતં(મ્) બ્રહ્મવાદિનામ્ ॥૧૭.૨૪॥",

    "તદિત્યનભિસન્ધાય, ફલં(ય્ઁ) યજ્ઞતપઃ(ખ્) ક્રિયાઃ।\nદાનક્રિયાશ્ચ વિવિધાઃ(ખ્), ક્રિયન્તે મોક્ષકાઙ્ક્ષિભિઃ ॥૧૭.૨૫॥",

    "સદ્ભાવે સાધુભાવે ચ, સદિત્યેતત્પ્રયુજ્યતે।\nપ્રશસ્તે કર્મણિ તથા, સચ્છબ્દઃ(ફ્) પાર્થ યુજ્યતે ॥૧૭.૨૬॥",

    "યજ્ઞે તપસિ દાને ચ, સ્થિતિઃ(સ્) સદિતિ ચોચ્યતે।\nકર્મ ચૈવ તદર્થીયં(મ્), સદિત્યેવાભિધીયતે ॥૧૭.૨૭॥",

    "અશ્રદ્ધયા હુતં(ન્) દત્તં(ન્), તપસ્તપ્તં(ઙ્) કૃતં(ઞ્) ચ યત્।\nઅસદિત્યુચ્યતે પાર્થ, ન ચ તત્પ્રેત્ય નો ઇહ ॥૧૭.૨૮॥",

    "ૐ તત્સદિતિ શ્રીમદ્ભગવદ્ગીતાસુ ઉપનિષત્સુ બ્રહ્મવિદ્યાયાં(ય્ઁ) \nયોગશાસ્ત્રે શ્રીકૃષ્ણાર્જુનસંવાદે શ્રદ્ધાત્રયવિભાગયોગો નામ સપ્તદશો‌ऽધ્યાયઃ ॥૧૭॥"
  ],
  "adhyaya-18" : [
    "અર્જુન ઉવાચ\nસન્ન્યાસસ્ય મહાબાહો, તત્ત્વમિચ્છામિ વેદિતુમ્।\nત્યાગસ્ય ચ હૃષીકેશ, પૃથક્કેશિનિષૂદન ॥૧૮.૧॥",

    "શ્રીભગવાનુવાચ\nકામ્યાનાં(ઙ્) કર્મણાં(ન્) ન્યાસં(મ્), સન્ન્યાસં(ઙ્) કવયો વિદુઃ।\nસર્વકર્મફલત્યાગં(મ્), પ્રાહુસ્ત્યાગં(વ્ઁ) વિચક્ષણાઃ ॥૧૮.૨॥",

    "ત્યાજ્યં(ન્) દોષવદિત્યેકે, કર્મ પ્રાહુર્મનીષિણઃ।\nયજ્ઞદાનતપઃકર્મ, ન ત્યાજ્યમિતિ ચાપરે ॥૧૮.૩॥",

    "નિશ્ચયં(મ્) શૃણુ મે તત્ર, ત્યાગે ભરતસત્તમ।\nત્યાગો હિ પુરુષવ્યાઘ્ર, ત્રિવિધઃ(સ્) સમ્પ્રકીર્તિતઃ ॥૧૮.૪॥",

    "યજ્ઞદાનતપઃકર્મ, ન ત્યાજ્યં(ઙ્) કાર્યમેવ તત્।\nયજ્ઞો દાનં(ન્) તપશ્ચૈવ, પાવનાનિ મનીષિણામ્ ॥૧૮.૫॥",

    "એતાન્યપિ તુ કર્માણિ, સઙ્ગં(ન્) ત્યક્ત્વા ફલાનિ ચ।\nકર્તવ્યાનીતિ મે પાર્થ, નિશ્ચિતં(મ્) મતમુત્તમમ્ ॥૧૮.૬॥",

    "નિયતસ્ય તુ સન્ન્યાસઃ(ખ્), કર્મણો નોપપદ્યતે।\nમોહાત્તસ્ય પરિત્યાગઃ(સ્), તામસઃ(ફ્) પરિકીર્તિતઃ ॥૧૮.૭॥",

    "દુઃખમિત્યેવ યત્કર્મ, કાયક્લેશભયાત્ત્યજેત્।\nસ કૃત્વા રાજસં(ન્) ત્યાગં(ન્), નૈવ ત્યાગફલં(લ્ઁ) લભેત્ ॥૧૮.૮॥",

    "કાર્યમિત્યેવ યત્કર્મ, નિયતં(ઙ્) ક્રિયતેઽર્જુન।\nસઙ્ગં(ન્) ત્યક્ત્વા ફલં(ઞ્) ચૈવ, સ ત્યાગઃ(સ્) સાત્ત્વિકો મતઃ ॥૧૮.૯॥",

    "ન દ્વેષ્ટ્યકુશલં(ઙ્) કર્મ, કુશલે નાનુષજ્જતે।\nત્યાગી સત્ત્વસમાવિષ્ટો, મેધાવી છિન્નસંશયઃ ॥૧૮.૧૦॥",

    "ન હિ દેહભૃતા શક્યં(ન્), ત્યક્તું(ઙ્) કર્માણ્યશેષતઃ।\nયસ્તુ કર્મફલત્યાગી, સ ત્યાગીત્યભિધીયતે ॥૧૮.૧૧॥",

    "અનિષ્ટમિષ્ટં(મ્) મિશ્રં(ઞ્) ચ, ત્રિવિધં(ઙ્) કર્મણઃ(ફ્) ફલમ્।\nભવત્યત્યાગિનાં(મ્) પ્રેત્ય, ન તુ સન્ન્યાસિનાં(ઙ્) ક્વચિત્ ॥૧૮.૧૨॥",

    "પઞ્ચૈતાનિ મહાબાહો, કારણાનિ નિબોધ મે।\nસાઙ્ખ્યે કૃતાન્તે પ્રોક્તાનિ, સિદ્ધયે સર્વકર્મણામ્ ॥૧૮.૧૩॥",

    "અધિષ્ઠાનં(ન્) તથા કર્તા, કરણં(ઞ્) ચ પૃથગ્વિધમ્।\nવિવિધાશ્ચ પૃથક્ચેષ્ટા, દૈવં(ઞ્) ચૈવાત્ર પઞ્ચમમ્ ॥૧૮.૧૪॥",

    "શરીરવાઙ્મનોભિર્યત્, કર્મ પ્રારભતે નરઃ।\nન્યાય્યં(વ્ઁ) વા વિપરીતં(વ્ઁ) વા, પઞ્ચૈતે તસ્ય હેતવઃ ॥૧૮.૧૫॥",

    "તત્રૈવં(મ્) સતિ કર્તારમ્, આત્માનં(ઙ્) કેવલં(ન્) તુ યઃ।\nપશ્યત્યકૃતબુદ્ધિત્વાન્, ન સ પશ્યતિ દુર્મતિઃ ॥૧૮.૧૬॥",

    "યસ્ય નાહઙ્કૃતો ભાવો, બુદ્ધિર્યસ્ય ન લિપ્યતે।\nહત્વાઽપિ સ ઇમાઁલ્લોકાન્, ન હન્તિ ન નિબધ્યતે ॥૧૮.૧૭॥",

    "જ્ઞાનં(ઞ્) જ્ઞેયં(મ્) પરિજ્ઞાતા, ત્રિવિધા કર્મચોદના।\nકરણં(ઙ્) કર્મ કર્તેતિ, ત્રિવિધઃ(ખ્) કર્મસઙ્ગ્રહઃ ॥૧૮.૧૮॥",

    "જ્ઞાનં(ઙ્) કર્મ ચ કર્તા ચ, ત્રિધૈવ ગુણભેદતઃ।\nપ્રોચ્યતે ગુણસઙ્ખ્યાને, યથાવચ્છૃણુ તાન્યપિ ॥૧૮.૧૯॥",

    "સર્વભૂતેષુ યેનૈકં(મ્), ભાવમવ્યયમીક્ષતે।\nઅવિભક્તં(વ્ઁ) વિભક્તેષુ, તજ્જ્ઞાનં(વ્ઁ) વિદ્ધિ સાત્ત્વિકમ્ ॥૧૮.૨૦॥",

    "પૃથક્ત્વેન તુ યજ્જ્ઞાનં(ન્), નાનાભાવાન્પૃથગ્વિધાન્।\nવેત્તિ સર્વેષુ ભૂતેષુ, તજ્જ્ઞાનં(વ્ઁ) વિદ્ધિ રાજસમ્ ॥૧૮.૨૧॥",

    "યત્તુ કૃત્સ્નવદેકસ્મિન્, કાર્યે સક્તમહૈતુકમ્।\nઅતત્ત્વાર્થવદલ્પં(ઞ્) ચ, તત્તામસમુદાહૃતમ્ ॥૧૮.૨૨॥",

    "નિયતં(મ્) સઙ્ગરહિતમ્, અરાગદ્વેષતઃ(ખ્) કૃતમ્।\nઅફલપ્રેપ્સુના કર્મ, યત્તત્સાત્ત્વિકમુચ્યતે ॥૧૮.૨૩॥",

    "યત્તુ કામેપ્સુના કર્મ, સાહઙ્કારેણ વા પુનઃ।\nક્રિયતે બહુલાયાસં(ન્), તદ્રાજસમુદાહૃતમ્ ॥૧૮.૨૪॥",

    "અનુબન્ધં(ઙ્) ક્ષયં(મ્) હિંસામ્, અનવેક્ષ્ય ચ પૌરુષમ્।\nમોહાદારભ્યતે કર્મ, યત્તત્તામસમુચ્યતે ॥૧૮.૨૫॥",

    "મુક્તસઙ્ગોઽનહંવાદી, ધૃત્યુત્સાહસમન્વિતઃ।\nસિદ્ધ્યસિદ્ધ્યોર્નિર્વિકારઃ(ખ્), કર્તા સાત્ત્વિક ઉચ્યતે ॥૧૮.૨૬॥",

    "રાગી કર્મફલપ્રેપ્સુઃ(ર્), લુબ્ધો હિંસાત્મકોઽશુચિઃ।\nહર્ષશોકાન્વિતઃ(ખ્) કર્તા, રાજસઃ(ફ્) પરિકીર્તિતઃ ॥૧૮.૨૭॥",

    "અયુક્તઃ(ફ્) પ્રાકૃતઃ(સ્) સ્તબ્ધઃ(શ્), શઠો નૈષ્કૃતિકોઽલસઃ।\nવિષાદી દીર્ઘસૂત્રી ચ, કર્તા તામસ ઉચ્યતે ॥૧૮.૨૮॥",

    "બુદ્ધેર્ભેદં(ન્) ધૃતેશ્ચૈવ, ગુણતસ્ત્રિવિધં(મ્) શૃણુ।\nપ્રોચ્યમાનમશેષેણ, પૃથક્ત્વેન ધનઞ્જય ॥૧૮.૨૯॥",

    "પ્રવૃત્તિં(ઞ્) ચ નિવૃત્તિં(ઞ્) ચ, કાર્યાકાર્યે ભયાભયે।\nબન્ધં(મ્) મોક્ષં(ઞ્) ચ યા વેત્તિ, બુદ્ધિઃ(સ્) સા પાર્થ સાત્ત્વિકી ॥૧૮.૩૦॥",

    "યયા ધર્મમધર્મં(ઞ્) ચ, કાર્યં(ઞ્) ચાકાર્યમેવ ચ।\nઅયથાવત્પ્રજાનાતિ, બુદ્ધિઃ(સ્) સા પાર્થ રાજસી ॥૧૮.૩૧॥",

    "અધર્મં(ન્) ધર્મમિતિ યા, મન્યતે તમસાવૃતા।\nસર્વાર્થાન્વિપરીતાંશ્ચ, બુદ્ધિઃ(સ્) સા પાર્થ તામસી ॥૧૮.૩૨॥",

    "ધૃત્યા યયા ધારયતે, મનઃપ્રાણેન્દ્રિયક્રિયાઃ।\nયોગેનાવ્યભિચારિણ્યા, ધૃતિઃ(સ્) સા પાર્થ સાત્ત્વિકી ॥૧૮.૩૩॥",

    "યયા તુ ધર્મકામાર્થાન્, ધૃત્યા ધારયતેઽર્જુન।\nપ્રસઙ્ગેન ફલાકાઙ્ક્ષી, ધૃતિઃ(સ્) સા પાર્થ રાજસી ॥૧૮.૩૪॥",

    "યયા સ્વપ્નં(મ્) ભયં(મ્) શોકં(વ્ઁ) વિષાદં(મ્) મદમેવ ચ।\nન વિમુઞ્ચતિ દુર્મેધા, ધૃતિઃ(સ્) સા પાર્થ તામસી ॥૧૮.૩૫॥",

    "સુખં(ન્) ત્વિદાનીં(ન્) ત્રિવિધં(મ્), શૃણુ મે ભરતર્ષભ।\nઅભ્યાસાદ્રમતે યત્ર, દુઃખાન્તં(ઞ્) ચ નિગચ્છતિ ॥૧૮.૩૬॥",

    "યત્તદગ્રે વિષમિવ, પરિણામેઽમૃતોપમમ્।\nતત્સુખં(મ્) સાત્ત્વિકં(મ્) પ્રોક્તમ્, આત્મબુદ્ધિપ્રસાદજમ્ ॥૧૮.૩૭॥",

    "વિષયેન્દ્રિયસંયોગાદ્, યત્તદગ્રેઽમૃતોપમમ્।\nપરિણામે વિષમિવ, તત્સુખં(મ્) રાજસં(મ્) સ્મૃતમ્ ॥૧૮.૩૮॥",

    "યદગ્રે ચાનુબન્ધે ચ, સુખં(મ્) મોહનમાત્મનઃ।\nનિદ્રાલસ્યપ્રમાદોત્થં(ન્), તત્તામસમુદાહૃતમ્ ॥૧૮.૩૯॥",

    "ન તદસ્તિ પૃથિવ્યાં(વ્ઁ) વા, દિવિ દેવેષુ વા પુનઃ।\nસત્ત્વં(મ્) પ્રકૃતિજૈર્મુક્તં(ય્ઁ), યદેભિઃ(સ્) સ્યાત્ત્રિભિર્ગુણૈઃ ॥૧૮.૪૦॥",

    "બ્રાહ્મણક્ષત્રિયવિશાં(મ્), શૂદ્રાણાં(ઞ્) ચ પરન્તપ।\nકર્માણિ પ્રવિભક્તાનિ, સ્વભાવપ્રભવૈર્ગુણૈઃ ॥૧૮.૪૧॥",

    "શમો દમસ્તપઃ(શ્) શૌચં(ઙ્), ક્ષાન્તિરાર્જવમેવ ચ।\nજ્ઞાનં(વ્ઁ) વિજ્ઞાનમાસ્તિક્યં(મ્), બ્રહ્મકર્મ સ્વભાવજમ્ ॥૧૮.૪૨॥",

    "શૌર્યં(ન્) તેજો ધૃતિર્દાક્ષ્યં(ય્ઁ), યુદ્ધે ચાપ્યપલાયનમ્।\nદાનમીશ્વરભાવશ્ચ, ક્ષાત્રં(ઙ્) કર્મ સ્વભાવજમ્ ॥૧૮.૪૩॥",

    "કૃષિગૌરક્ષ્યવાણિજ્યં(વ્ઁ) વૈશ્યકર્મ સ્વભાવજમ્।\nપરિચર્યાત્મકં(ઙ્) કર્મ, શૂદ્રસ્યાપિ સ્વભાવજમ્ ॥૧૮.૪૪॥",

    "સ્વે સ્વે કર્મણ્યભિરતઃ(સ્), સંસિદ્ધિં(લ્ઁ) લભતે નરઃ।\nસ્વકર્મનિરતઃ(સ્) સિદ્ધિં(ય્ઁ), યથા વિન્દતિ તચ્છૃણુ ॥૧૮.૪૫॥",

    "યતઃ(ફ્) પ્રવૃત્તિર્ભૂતાનાં(ય્ઁ), યેન સર્વમિદં(ન્) તતમ્।\nસ્વકર્મણા તમભ્યર્ચ્ય, સિદ્ધિં(વ્ઁ) વિન્દતિ માનવઃ ॥૧૮.૪૬॥",

    "શ્રેયાન્સ્વધર્મો વિગુણઃ(ફ્), પરધર્માત્સ્વનુષ્ઠિતાત્।\nસ્વભાવનિયતં(ઙ્) કર્મ, કુર્વન્નાપ્નોતિ કિલ્બિષમ્ ॥૧૮.૪૭॥",

    "સહજં(ઙ્) કર્મ કૌન્તેય, સદોષમપિ ન ત્યજેત્।\nસર્વારંભા હિ દોષેણ, ધૂમેનાગ્નિરિવાવૃતાઃ ॥૧૮.૪૮॥",

    "અસક્તબુદ્ધિઃ(સ્) સર્વત્ર, જિતાત્મા વિગતસ્પૃહઃ।\nનૈષ્કર્મ્યસિદ્ધિં(મ્) પરમાં(મ્), સન્ન્યાસેનાધિગચ્છતિ ॥૧૮.૪૯॥",

    "સિદ્ધિં(મ્) પ્રાપ્તો યથા બ્રહ્મ, તથાપ્નોતિ નિબોધ મે।\nસમાસેનૈવ કૌન્તેય, નિષ્ઠા જ્ઞાનસ્ય યા પરા ॥૧૮.૫૦॥",

    "બુદ્ધ્યા વિશુદ્ધયા યુક્તો, ધૃત્યાત્માનં(ન્) નિયમ્ય ચ।\nશબ્દાદીન્વિષયાંસ્ત્યક્ત્વા, રાગદ્વેષૌ વ્યુદસ્ય ચ ॥૧૮.૫૧॥",

    "વિવિક્તસેવી લઘ્વાશી, યતવાક્કાયમાનસઃ।\nધ્યાનયોગપરો નિત્યં(વ્ઁ) વૈરાગ્યં(મ્) સમુપાશ્રિતઃ ॥૧૮.૫૨॥",

    "અહઙ્કારં(મ્) બલં(ન્) દર્પં(ઙ્), કામં(ઙ્) ક્રોધં(મ્) પરિગ્રહમ્।\nવિમુચ્ય નિર્મમઃ(શ્) શાંતો, બ્રહ્મભૂયાય કલ્પતે ॥૧૮.૫૩॥",

    "બ્રહ્મભૂતઃ(ફ્) પ્રસન્નાત્મા, ન શોચતિ ન કાઙ્ક્ષતિ।\nસમઃ(સ્) સર્વેષુ ભૂતેષુ, મદ્ભક્તિં(લ્ઁ) લભતે પરામ્ ॥૧૮.૫૪॥",

    "ભક્ત્યા મામભિજાનાતિ, યાવાન્યશ્ચાસ્મિ તત્ત્વતઃ।\nતતો માં(ન્) તત્ત્વતો જ્ઞાત્વા, વિશતે તદનન્તરમ્ ॥૧૮.૫૫॥",

    "સર્વકર્માણ્યપિ સદા, કુર્વાણો મદ્વ્યપાશ્રયઃ।\nમત્પ્રસાદાદવાપ્નોતિ, શાશ્વતં(મ્) પદમવ્યયમ્ ॥૧૮.૫૬॥",

    "ચેતસા સર્વકર્માણિ, મયિ સન્ન્યસ્ય મત્પરઃ।\nબુદ્ધિયોગમુપાશ્રિત્ય, મચ્ચિત્તઃ(સ્) સતતં(મ્) ભવ ॥૧૮.૫૭॥",

    "મચ્ચિત્તઃ(સ્) સર્વદુર્ગાણિ, મત્પ્રસાદાત્તરિષ્યસિ।\nઅથ ચેત્ત્વમહઙ્કારાન્, ન શ્રોષ્યસિ વિનઙ્ક્ષ્યસિ ॥૧૮.૫૮॥",

    "યદહઙ્કારમાશ્રિત્ય, ન યોત્સ્ય ઇતિ મન્યસે।\nમિથ્યૈષ વ્યવસાયસ્તે, પ્રકૃતિસ્ત્વાં(ન્) નિયોક્ષ્યતિ ॥૧૮.૫૯॥",

    "સ્વભાવજેન કૌન્તેય, નિબદ્ધઃ(સ્) સ્વેન કર્મણા।\nકર્તું(ન્) નેચ્છસિ યન્મોહાત્, કરિષ્યસ્યવશોઽપિ તત્ ॥૧૮.૬૦॥",

    "ઈશ્વરઃ(સ્) સર્વભૂતાનાં(મ્), હૃદ્દેશેઽર્જુન તિષ્ઠતિ।\nભ્રામયન્સર્વભૂતાનિ, યન્ત્રારૂઢાનિ માયયા ॥૧૮.૬૧॥",

    "તમેવ શરણં(ઙ્) ગચ્છ, સર્વભાવેન ભારત।\nતત્પ્રસાદાત્પરાં(મ્) શાન્તિં(મ્), સ્થાનં(મ્) પ્રાપ્સ્યસિ શાશ્વતમ્ ॥૧૮.૬૨॥",

    "ઇતિ તે જ્ઞાનમાખ્યાતં(ઙ્), ગુહ્યાદ્ગુહ્યતરં(મ્) મયા।\nવિમૃશ્યૈતદશેષેણ, યથેચ્છસિ તથા કુરુ ॥૧૮.૬૩॥",

    "સર્વગુહ્યતમં(મ્) ભૂયઃ(શ્), શૃણુ મે પરમં(વ્ઁ) વચઃ।\nઇષ્ટોઽસિ મે દૃઢમિતિ, તતો વક્ષ્યામિ તે હિતમ્ ॥૧૮.૬૪॥",

    "મન્મના ભવ મદ્ભક્તો, મદ્યાજી માં(ન્) નમસ્કુરુ।\nમામેવૈષ્યસિ સત્યં(ન્) તે, પ્રતિજાને પ્રિયોઽસિ મે ॥૧૮.૬૫॥",

    "સર્વધર્માન્પરિત્યજ્ય, મામેકં(મ્) શરણં(વ્ઁ) વ્રજ।\nઅહં(ન્) ત્વા સર્વપાપેભ્યો, મોક્ષયિષ્યામિ મા શુચઃ ॥૧૮.૬૬॥",

    "ઇદં(ન્) તે નાતપસ્કાય, નાભક્તાય કદાચન।\nન ચાશુશ્રૂષવે વાચ્યં(ન્), ન ચ માં(ય્ઁ) યોઽભ્યસૂયતિ ॥૧૮.૬૭॥",

    "ય ઇમં(મ્) પરમં(ઙ્) ગુહ્યં(મ્), મદ્ભક્તેષ્વભિધાસ્યતિ।\nભક્તિં(મ્) મયિ પરાં(ઙ્) કૃત્વા, મામેવૈષ્યત્યસંશયઃ ॥૧૮.૬૮॥",

    "ન ચ તસ્માન્મનુષ્યેષુ, કશ્ચિન્મે પ્રિયકૃત્તમઃ।\nભવિતા ન ચ મે તસ્માદ્, અન્યઃ(ફ્) પ્રિયતરો ભુવિ ॥૧૮.૬૯॥",

    "અધ્યેષ્યતે ચ ય ઇમં(ન્), ધર્મ્યં(મ્) સંવાદમાવયોઃ।\nજ્ઞાનયજ્ઞેન તેનાહમ્, ઇષ્ટઃ(સ્) સ્યામિતિ મે મતિઃ ॥૧૮.૭૦॥",

    "શ્રદ્ધાવાનનસૂયશ્ચ, શૃણુયાદપિ યો નરઃ।\nસોઽપિ મુક્તઃ(શ્) શુભાઁલ્લોકાન્, પ્રાપ્નુયાત્પુણ્યકર્મણામ્ ॥૧૮.૭૧॥",

    "કચ્ચિદેતચ્છ્રુતં(મ્) પાર્થ, ત્વયૈકાગ્રેણ ચેતસા।\nકચ્ચિદજ્ઞાનસમ્મોહઃ(ફ્), પ્રનષ્ટસ્તે ધનઞ્જય ॥૧૮.૭૨॥",

    "અર્જુન ઉવાચ\nનષ્ટો મોહઃ(સ્) સ્મૃતિર્લબ્ધા, ત્વત્પ્રસાદાન્મયાચ્યુત।\nસ્થિતોઽસ્મિ ગતસન્દેહઃ(ખ્), કરિષ્યે વચનં(ન્) તવ ॥૧૮.૭૩॥",

    "સઞ્જય ઉવાચ\nઇત્યહં(વ્ઁ) વાસુદેવસ્ય, પાર્થસ્ય ચ મહાત્મનઃ।\nસંવાદમિમમશ્રૌષમ્, અદ્ભુતં(મ્) રોમહર્ષણમ્ ॥૧૮.૭૪॥",

    "વ્યાસપ્રસાદાચ્છ્રુતવાન્, એતદ્ગુહ્યમહં(મ્) પરમ્।\nયોગં(ય્ઁ) યોગેશ્વરાત્કૃષ્ણાત્, સાક્ષાત્કથયતઃ(સ્) સ્વયમ્ ॥૧૮.૭૫॥",

    "રાજન્સંસ્મૃત્ય સંસ્મૃત્ય, સંવાદમિમમદ્ભુતમ્।\nકેશવાર્જુનયોઃ(ફ્) પુણ્યં(મ્), હૃષ્યામિ ચ મુહુર્મુહુઃ ॥૧૮.૭૬॥",

    "તચ્ચ સંસ્મૃત્ય સંસ્મૃત્ય, રૂપમત્યદ્ભુતં(મ્) હરેઃ।\nવિસ્મયો મે મહાન્ રાજન્, હૃષ્યામિ ચ પુનઃ(ફ્) પુનઃ ॥૧૮.૭૭॥",

    "યત્ર યોગેશ્વરઃ(ખ્) કૃષ્ણો, યત્ર પાર્થો ધનુર્ધરઃ।\nતત્ર શ્રીર્વિજયો ભૂતિઃ(ર્), ધ્રુવા નીતિર્મતિર્મમ ॥૧૮.૭૮॥",

    "ૐ તત્સદિતિ શ્રીમદ્ભગવદ્ગીતાસુ ઉપનિષત્સુ બ્રહ્મવિદ્યાયાં(ય્ઁ) \nયોગશાસ્ત્રે શ્રીકૃષ્ણાર્જુનસંવાદે મોક્ષસન્ન્યાસયોગો નામ અષ્ટાદશોઽધ્યાયઃ ॥૧૮॥" 
  ]
},
{
  "adhyaya-1" : [
    "ધૃતરાષ્ટ્ર બોલ્યા : હે સંજય! ધર્મભૂમિ કુરુક્ષેત્રમાં એકઠા થયેલા, યુદ્ધના ઇચ્છુક, મારા તથા પાંડુના પુત્રોએ શું કર્યું? 1.1",

    "સંજય બોલ્યા : તે વખતે રાજા દુર્યોધને વ્યૂહમાં ગોઠવાયેલી પાંડવોની સેનાને જોયા પછી દ્રોણાચાર્ય પાસે જઈને આ વચન કહ્યું. 1.2",

    "હે ગુરુદેવ! આપના બુદ્ધિમાન શિષ્ય દ્રુપદપુત્ર ધૃષ્ટદ્યુમ્ન વડે વ્યૂહાકારે ઊભી કરાયેલી પાંડુપુત્રોની આ અત્યંત વિશાળ સેનાને જુઓ. 1.3",

    "આ સેનામાં મોટાં ધનુષ્યો ધારણ કરનારા તથા યુદ્ધમાં ભીમ અને અર્જુન જેવા શૂરવી૨ - સાત્યકિ, વિરાટ, મહારથી રાજા દ્રુપદ, ધૃષ્ટકેતુ, ચેકિતાન, બળવાન કાશિરાજ, પુરુજિત, કુત્તિભોજ અને પુરુષશ્રેષ્ઠ શૈબ્ય, પરાક્રમી યુધામન્યુ તથા બળવાન ઉત્તમૌજા, સુભદ્રાપુત્ર અભિમન્યુ તેમજ દ્રૌપદીના પાંચેય પુત્રો - બધા જ મહારથીઓ છે. 1.4-1.6",

    "આ સેનામાં મોટાં ધનુષ્યો ધારણ કરનારા તથા યુદ્ધમાં ભીમ અને અર્જુન જેવા શૂરવી૨ - સાત્યકિ, વિરાટ, મહારથી રાજા દ્રુપદ, ધૃષ્ટકેતુ, ચેકિતાન, બળવાન કાશિરાજ, પુરુજિત, કુત્તિભોજ અને પુરુષશ્રેષ્ઠ શૈબ્ય, પરાક્રમી યુધામન્યુ તથા બળવાન ઉત્તમૌજા, સુભદ્રાપુત્ર અભિમન્યુ તેમજ દ્રૌપદીના પાંચેય પુત્રો - બધા જ મહારથીઓ છે. 1.4-1.6",

    "આ સેનામાં મોટાં ધનુષ્યો ધારણ કરનારા તથા યુદ્ધમાં ભીમ અને અર્જુન જેવા શૂરવી૨ - સાત્યકિ, વિરાટ, મહારથી રાજા દ્રુપદ, ધૃષ્ટકેતુ, ચેકિતાન, બળવાન કાશિરાજ, પુરુજિત, કુત્તિભોજ અને પુરુષશ્રેષ્ઠ શૈબ્ય, પરાક્રમી યુધામન્યુ તથા બળવાન ઉત્તમૌજા, સુભદ્રાપુત્ર અભિમન્યુ તેમજ દ્રૌપદીના પાંચેય પુત્રો - બધા જ મહારથીઓ છે. 1.4-1.6",

    "હૈ દ્વિજશ્રેષ્ઠ! હવે આપણા સૈન્યમાં પણ જે મુખ્ય શૂરવીરો છે તેમને પણ આપ જાણી લો; આપની જાણ ખાતર મારી સેનાના જે જે સેનાપતિઓ છે, તેમને કહું છું. 1.7",

    "આપ સ્વયં, પિતામહ ભીષ્મ તથા કર્ણ, સંગ્રામવિજયી કૃપાચાર્ય તેમજ અશ્વત્થામા, વિકર્ણ અને સોમદત્તનો ભૂરિશ્રવા – 1.8",

    "આ સિવાય બીજા પણ મારા માટે જીવ ત્યજનારા ઘણા શૂરવીરો અનેક પ્રકારનાં શસ્ત્રા સજ્જ છે તેમજ સર્વે યુદ્ધમાં નિપુણ છે. 1.9",

    "ભીષ્મ પિતામહ વડે રક્ષાયેલી આપણી તે સેના અપરિમિત હોવાને લીધે બધી જ રીતે અજેય છે, જ્યારે ભીમ વડે રક્ષાયેલી આ લોકોની આ સેનાને તો પરિમિત હોવાને કારણે જીતવી સાવ સહેલી છે. 1.10",

    "આથી બધાં વ્યૂહદ્વારો પર પોતપોતાની જગ્યાએ રહેલા તમે બધાય સજાગ રહીને ભીષ્મપિતામહનું જ બધી બાજુથી રક્ષણ કરો. 1.11",

    "કૌરવોમાં વૃદ્ધ મહાન પ્રતાપી પિતામહ ભીષ્મે તે દુર્યોધનના હૃદયમાં હરખ જન્માવતાં જોરથી સિંહની જેમ ગરજીને શંખ વગાડ્યો. 1.12",

    "પછી શંખ, નગારાં, ઢોલ, મૃદંગ તેમજ રણશિંગાં વગેરે વાદ્યો એક સાથે જ વાગી ઊઠ્યાં; તેમનો એ અવાજ ઘણો ભયંકર થયો. 1.13",

    "ત્યાર પછી શ્વેત અશ્વો જોડેલા ઉત્તમ રથમાં બેઠેલા શ્રીકૃષ્ણ મહારાજે અને અર્જુને પણ અલૌકિક શંખ વગાડ્યા. 1.14",

    "શ્રીકૃષ્ણ મહારાજે પાંચજન્ય નામનો, અર્જુને દેવદત્ત નામનો અને ભયાનક કર્મ કરનાર ભીમસેને પૌણ્ડ્ર નામનો મહાશંખ વગાડ્યો. 1.15",

    "કુન્તીપુત્ર રાજા યુધિષ્ઠિરે અનન્તવિજય નામનો અને નકુળ તથા સહદેવે સુઘોષ અને મણિપુષ્પક નામના શંખ વગાડ્યા. 1.16",

    "શ્રેષ્ઠ ધનુર્ધારી કાશિરાજ, મહારથી શિખણ્ડી, ધૃષ્ટદ્યુમ્ન, રાજા વિરાટ, અજેય સાત્યકિ, રાજા દ્રુપદ, દ્રૌપદીના પાંચેય પુત્રો અને મહાબાહુ સુભદ્રાપુત્ર અભિમન્યુ-આ સૌએ, હે રાજન! બધી બાજુએથી જુદા જુદા શંખો વગાડ્યા. 1.17-1.18",

    "શ્રેષ્ઠ ધનુર્ધારી કાશિરાજ, મહારથી શિખણ્ડી, ધૃષ્ટદ્યુમ્ન, રાજા વિરાટ, અજેય સાત્યકિ, રાજા દ્રુપદ, દ્રૌપદીના પાંચેય પુત્રો અને મહાબાહુ સુભદ્રાપુત્ર અભિમન્યુ-આ સૌએ, હે રાજન! બધી બાજુએથી જુદા જુદા શંખો વગાડ્યા. 1.17-1.18",

    "તે ભયાનક નાદે આકાશ અને પૃથ્વીને પણ ગજાવતા ધાર્તરાષ્ટ્રોના એટલે કે આપના પક્ષધારીઓના હૃદય ચીરી નાખ્યાં. 1.19",

    "હે રાજન! તે પછી કપિધ્વજ અર્જુને યુદ્ધ માટે સજ્જ થયેલા ધૃતરાષ્ટ્ર-સંબંધીઓને જોઈને, શસ્ત્રપ્રહારની તૈયારી વેળાએ ધનુષ ઉપાડીને હૃષીકેશ શ્રીકૃષ્ણ મહારાજને આ વચન કહ્યું, ‘‘હે અચ્યુત! મારા રથને બન્ને સેનાઓની વચ્ચે ત્યાં સુધી ઊભો રાખો, 1.20-1.21",

    "હે રાજન! તે પછી કપિધ્વજ અર્જુને યુદ્ધ માટે સજ્જ થયેલા ધૃતરાષ્ટ્ર-સંબંધીઓને જોઈને, શસ્ત્રપ્રહારની તૈયારી વેળાએ ધનુષ ઉપાડીને હૃષીકેશ શ્રીકૃષ્ણ મહારાજને આ વચન કહ્યું, ‘‘હે અચ્યુત! મારા રથને બન્ને સેનાઓની વચ્ચે ત્યાં સુધી ઊભો રાખો, 1.20-1.21",

    "જ્યાં સુધીમાં ભૂમિમાં ભેગા થયેલા યુદ્ધની અભિલાષા રાખનારા આ વિપક્ષી યોદ્ધાઓને સારી પેઠે હું જોઈ ન લઉં; કે આ યુદ્ધમાં મારે કોની કોની સાથે લડવાનું છે એ હું જોઈ લઉં!; ત્યાં સુધી તેને ઊભો રાખો. 1.22",

    "દુર્બુદ્ધિ દુર્યોધનનું યુદ્ધમાં હિત ઇચ્છનારા જે જે રાજાઓ આ સૈન્યમાં આવ્યા છે તે સૌ યુદ્ધ કરનારાઓને હું સારી રીતે જોઉં તો ખરો! 1.23",

    "સંજય બોલ્યા : હે ધૃતરાષ્ટ્ર! ગુડાકેશ અર્જુન દ્વારા આ પ્રકારે કહેવાયેલા હૃષીકેશ શ્રીકૃષ્ણચંદ્રે બન્ને સેનાઓની મધ્યમાં ભીષ્મ અને દ્રોણાચાર્યની તેમજ સમસ્ત રાજાઓની સામે ઉત્તમ રથને ઊભો રાખીને 1.24",

    "આ પ્રમાણે કહ્યું, “હે પાર્થ! યુદ્ધ માટે એકઠા થયેલા આ કોરવોને જો. 1.25",

    "ત્યાં પૃથાપુત્ર અર્જુને એ બન્ને સેનાઓમાં રહેલા વડીલો, કાકાઓ, પિતામહો, ગુરુઓ, મામાઓ, ભાઈઓ, પુત્રો, પૌત્રો, મિત્રો, સસરાઓ તેમજ સુહૃદોને જ જોયા. 1.26",

    "તે ઉપસ્થિત સઘળા બાંધવોને જોઈને એ કુન્તીપુત્ર અર્જુન અત્યંત કરુણાથી ઘેરાઈને વિષાદ કરતાં આ વચનો બોલ્યા. 1.27",

    "અર્જુન બોલ્યા : હે કૃષ્ણ! યુદ્ધક્ષેત્રમાં ઊભેલા યુદ્ધ કરવા ઇચ્છતાં આ સ્વજન-સમુદાયને જોઈને મારાં અંગો શિથિલ થઈ રહ્યાં છે, મોં સુકાઈ રહ્યું છે તથા મારા શરીરમાં કંપ અને રોમાંચ થઈ રહ્યો છે. 1.28-1.29",

    "અર્જુન બોલ્યા : હે કૃષ્ણ! યુદ્ધક્ષેત્રમાં ઊભેલા યુદ્ધ કરવા ઇચ્છતાં આ સ્વજન-સમુદાયને જોઈને મારાં અંગો શિથિલ થઈ રહ્યાં છે, મોં સુકાઈ રહ્યું છે તથા મારા શરીરમાં કંપ અને રોમાંચ થઈ રહ્યો છે. 1.28-1.29",

    "હાથમાંથી ગાંડીવ ધનુષ સરી રહ્યું છે, ચામડી પણ ઘણી બળી રહી છે તથા મારું મન જાણે ભમે છે, માટે હું ઊભો રહેવાને પણ સમર્થ નથી. 1.30",

    "હે કેશવ! હું તો લક્ષણો પણ અવળાં જ જોઉં છું અને યુદ્ધમાં સ્વજનોને મારીને કલ્યાણ પણ નથી જોતો. 1.31",

    "હે કૃષ્ણ! હું ન તો વિજય ઇચ્છું છું કે ન તો રાજ્ય, કે ન સુખ પણ; હે ગોવિંદ! અમારે આવા રાજ્યનું શું પ્રયોજન? અથવા આવા ભોગો અને જીવનથી પણ અમને શો લાભ? 1.32",

    "અમે જેમના માટે રાજ્ય, ભોગ અને સુખો ઇચ્છીએ છીએ, તે જ આ બધા લોકો તો ધન અને જીવવાની આશા જતી કરીને યુદ્ધમાં ઊભા છે! 1.33",

    "અહીં ગુરુજનો, વડીલો, પુત્રો તેમજ દાદા, મામા, સસરા, પૌત્રો, સાળા તથા બીજા બધા સંબંધીઓ જ છે. 1.34",

    "હે મધુસૂદન! હું હણાઉં છતાં પણ અથવા ત્રણે લોકનું રાજ્ય મેળવવા માટે પણ હું આ બધાને હણવા નથી ઇચ્છતો, તો પછી પૃથ્વી માટે તો કહેવું જ શું? 1.35",

    "હે જનાર્દન ! ધૃતરાષ્ટ્રના પુત્રોને હણીને અમને કયો આનંદ મળશે? આ આતતાયીઓને હણીને તો અમને પાપ જ લાગશે. 1.36",

    "માટે હે માધવ! પોતાના જ બાંધવ ધૃતરાષ્ટ્ર પુત્રોને હણવા માટે અમે યોગ્ય નથી પોતાના જ સ્વજનોને હણીને અમે કેમ સુખી થઈશું ? 1.37",

    "જોકે લોભને લીધે ભ્રષ્ટ ચિત્ત થયેલા આ લોકો કુળના નાશથી ઉત્પન્ન થતા દોષ ને તથા મિત્રનો વિરોધ કરવાથી ઉત્પન્ન થતા પાપ ને જોતા નથી; છતાં પણ હે જનાર્દન ! કુળ ના કારણે લાગતા દોષ ને જાણનારા અમોએ આ પાપથી બચવા માટે કેમ ન વિચારવું ? 1.38-1.39",

    "જોકે લોભને લીધે ભ્રષ્ટ ચિત્ત થયેલા આ લોકો કુળના નાશથી ઉત્પન્ન થતા દોષ ને તથા મિત્રનો વિરોધ કરવાથી ઉત્પન્ન થતા પાપ ને જોતા નથી; છતાં પણ હે જનાર્દન ! કુળ ના કારણે લાગતા દોષ ને જાણનારા અમોએ આ પાપથી બચવા માટે કેમ ન વિચારવું ? 1.38-1.39",

    "કુળનો નાશ થતાં સનાતન કુળધર્મો નષ્ટ થઇ જાય છે અને ધર્મ નાશ પામતા સમસ્ત કુળમાં પાપ પણ ખૂબ જ ફેલાઈ જાય છે. 1.40",

    "હે કૃષ્ણ! પાપ ઘણુ વધી જતાં કુળની સ્ત્રીઓ અત્યંત દૂષિત થઈ જાય છે અને હે વાર્ષ્ણૅય ! જ્યારે સ્ત્રીઓ દૂષિત થાય છે ત્યારે વર્ણસંકર પ્રજા જન્મે છે. 1.41",

    "વર્ણસંકર સંતતિ કુળના હણનારા ઓને તથા કુળને નરકમાં લઈ જવા માટે જ હોય છે; અને તર્પણ ક્રિયાથી વંચિત એમના પિતૃઓ પણ અધોગતિને પામે છે. 1.42",

    "આ વર્ણસંકર કારક દોષોથી કુળઘાતીઓના સનાતન કુળધર્મ અને જાતિધર્મો સમુળા નષ્ટ થઈ જાય છે. 1.43",

    "એટલું જ નહીં, હે જનાર્દન! જેમનાં ગુણધર્મો સમુળા નષ્ટ થઈ ગયા હોય એવા મનુષ્યોનો અનિશ્ચિતકાળ સુધી નરકમાં વાસ થાય છે એમ અમે સાંભળતા આવ્યા છીએ. 1.44",

    "અરેરે ! ઘણા દુઃખની વાત છે કે અમે બુદ્ધિમાન હોવા છતાં પણ એવું મોટું પાપ કરવા તત્પર થયા છીએ, જે રાજ્ય અને સુખના લોભે સ્વજનોને હણવા તૈયાર થયા છીએ. 1.45",

    "એ કરતાં શસ્ત્ર રહિત અને સામનો ન કરનાર એવા મને જો હાથમાં શસ્ત્ર લીધેલા ધૃતરાષ્ટ્રના પુત્રો રણમાં હણી નાખે તો તે મરવું પણ મારા માટે વધુ કલ્યાણકારક થશે. 1.46",

    "સંજય બોલ્યા: રણભૂમિમાં શોકથી ઉદ્વિગ્ન મનનો અર્જુન આમ કહીને બાણ સહિત ધનુષ ત્યજીને રથના પાછળના ભાગમાં બેસી ગયો. 1.47",

    "આ રીતે ૐ, તત્‌, સત્‌-એ ભગવાનના નામોના ઉચ્ચારણ સાથે બ્રહ્મવિધા અને યોગશાસ્ત્રમય શ્રીમદ્ભગવદ્‌ગીતોપનિષદરૂપી શ્રીકૃષ્ણાર્જુનસંવાદમાં \"અર્જુનવિષાદયોગ\" નામનો પહેલો અધ્યાય પૂરો થયો. ॥૧॥"
  ],
  "adhyaya-2" : [
    "સઞ્જય બોલ્યા : આ પ્રમાણે કરુણાથી ઘેરાયલા તેમજ આંસુભરેલા અને વ્યાકુળ નેત્રોના તથા શોક કરતા તે અર્જુનને ભગવાન મધુસૂદનને આ વાક્ય કહ્યું : 2.1",

    "શ્રીભગવાન બોલ્યા : હે અર્જુન! તારા જેવા શૂરવીરને અસમયે મોહ ક્યાંથી થયો? કારણ કે ન તો આ શ્રેષ્ઠ પુરુષોએ આચરેલો છે, ન તો સ્વર્ગ આપનારો છે કે ન તો યશ આપનારો છે. 2.2",

    "આથી હે પાર્થ! નપુંસકતાને વશ ન થા, તને એ શોભતી નથી; હે શત્રુઓને તપાવનાર! હૃદયની આ તુચ્છ દુર્બળતાને ત્યજીને યુદ્ધ માટે ઊભો થઇ જા. 2.3",

    "અર્જુન બોલ્યા : હે મધુસૂદન! રણભૂમિમાં હું બાણોથી કઈ રીતે ભીષ્મપિતામહ અને દ્રોણાચાર્ય વિરુદ્ધ લડીશ? કેમ કે હે અરિસૂદન! તેઓ બન્નેય પૂજનીય છે. 2.4",

    "માટે આ મહાનુભાવ ગુરુજનોને નહિ હણીને હું આ લોકમાં ભિક્ષાવૃત્તિ વડે જીવનનિર્વાહ કરવાનું પણ કલ્યાણકારી સમજુ છું; કેમકે ગુરુજનોને હણીને પણ આ લોકમાં લોહીથી ખરડાયેલા અર્થ અને કામરૂપ ભોગોને જ ભોગવીશ ને! 2.5",

    "વળી અમે એ પણ જાણતા નથી કે અમારા માટે યુદ્ધ કરવું કે ન કરવું એ બન્નેમાંથી શું શ્રેષ્ઠ છે; અથવા એ પણ નથી જાણતા કે તેમને જીતીશું કે તેઓ અમને જીતશે. જેમને હણીને અમે જીવવા પણ માગતા નથી, તે જ અમારા આત્મીય ધૃતરાષ્ટ્રના પુત્રો અમારી સામે યુદ્ધ માટે ઊભા છે! 2.6",

    "આથી કાયરતારૂપી દોષને લીધે જેનો સ્વભાવ નાશ પામ્યો છે એવો તથા ધર્મની બાબતમાં મોહિતચિત્ત થયેલો હું આપને પૂછું છું કે જે નિશ્ચિતરૂપે કલ્યાણકારી સાધન હોય, તે મને કહો; કેમ કે હું આપનો શિષ્ય છું, માટે આપને શરણે આવેલા મને ઉપદેશ આપો. 2.7",

    "કેમ કે પૃથ્વીનું નિષ્કંટક અને ધન-ધાન્યથી સંપન્ન રાજ્ય તેમજ દેવતાઓનું આધિપત્ય પામીને પણ હું એવો ઉપાય નથી જોતો, જે મારી ઇન્દ્રિયોને સુકાવનારા શોકને નિવારી શકે. 2.8",

    "સંજય બોલ્યા : હે રાજા, નિદ્રાને જીતનારા અર્જુન, અંતર્યામી શ્રીકૃષ્ણ મહારાજ પ્રત્યે આમ કહીને પછી શ્રીગોવિંદ ભગવાનને 'યુદ્ધ નહીં કરું' એમ સ્પષ્ટ કહીને ચુપ થઈ ગયા. 2.9",

    "હે ભરતવંશી ધૃતરાષ્ટ્ર! અંતર્યામી શ્રીકૃષ્ણ મહારાજ બન્ને સેનાઓની મધ્યે શોક કરતા અર્જુન પ્રત્યે મલકતા હોય એમ આ વચન બોલ્યા. 2.10",

    "શ્રીભગવાન બોલ્યા : હે અર્જુન! જેમના માટે શોક કરવો યોગ્ય નથી તેમના માટે તું શોક કરે છે અને જ્ઞાનીજનો જેવા વચનો બોલે છે, પરંતુ જેમના પ્રાણ જતા રહ્યા છે તેમના માટે કે જેમના પ્રાણ નથી ગયા તેમના માટે જ્ઞાનીજનો શોક નથી કરતા. 2.11",

    "હું કોઈ કાળમાં ન હતો એવું નથી, તું ન હતો કે આ રાજાઓ ન હતા એવું પણ નથી અને એવું પણ નથી કે હવે પછી આપણે બધા નહીં હોઈએ. 2.12",

    "જેમ જીવાત્માને આ શરીરમાં બાળપણ, યુવાની અને વૃદ્ધાવસ્થા પ્રાપ્ત થાય છે, તે જ પ્રમાણે બીજા શરીરની પ્રાપ્તિ થાય છે; એ બાબતમાં ધીર પુરુષ મોહિત થતો નથી. 2.13",

    "હે કુંતીપુત્ર! ઠંડી-ગરમી અને સુખ-દુઃખ દેનારા ઇન્દ્રિયો સાથેના વિષયોના સંયોગો તો ઉત્પત્તિ-વિનાશશીલ છે, અનિત્ય છે; માટે હે ભારત! તેમને તું સહન કર. 2.14",

    "કેમકે હે પુરુષશ્રેષ્ઠ! દુ:ખ-સુખને સમાન સમજનાર જે ધીર પુરુષને આ ઇન્દ્રિયો સાથેના વિષયોના સંયોગો વ્યાકુળ નથી કરતા, તે મોક્ષને પાત્ર થાય છે. 2.15",

    "અસત્ પદાર્થની સત્તા નથી અને સત્ નો અભાવ નથી; આ બન્નેનું તત્ત્વ તત્ત્વજ્ઞાની પુરુષોએ જોયું છે. 2.16",

    "નાશરહિત તો તું તેને જાણ, જેનાથી આ સકળ જગત-દૃશ્યવર્ગ વ્યાપ્ત છે. આ અવિનાશીનો વિનાશ કરવા કોઈ પણ સમર્થ નથી. 2.17",

    "આ નાશરહિત, અપ્રમેય, નિત્યસ્વરૂપ જીવાત્માનાં આ સઘળાં શરીરો નાશવંત કહેવાયાં છે, માટે હે ભરતવંશી અર્જુન! તું યુદ્ધ કર. 2.18",

    "જે આ આત્માને હણનાર સમજે છે તથા જે એને હણાયેલ માને છે, તે બન્નેય જાણતા નથી; કેમકે આ આત્મા વાસ્તવમાં નથી કોઈને હણતો કે નથી કોઈના દ્વારા હણાતો. 2.19",

    "આ આત્મા કોઈ પણ કાળમાં જન્મ લેતો હોય એમ નથી કે મરણ પામતો હોય એમ નથી, તેમજ ઉત્પન્ન થઈને ફરીથી સત્તાવાન થતો હોય એમ પણ નથી; કારણ કે તે અજન્મા, નિત્ય, શાશ્વત અને પુરાતન છે, શરીરના હણાવા છતાં પણ તે હણાતો નથી. 2.20",

    "હે પાર્થ! જે પુરુષ આ આત્માને નાશરહિત, નિત્ય, અજન્મા તેમજ અવ્યય જાણે છે, તે પુરુષ કઈ રીતે કોઇને હણાવે છે અને કઈ રીતે કોઈને હણે છે? 2.21",

    "જેમ માણસ જૂનાં વસ્ત્રો ત્યજીને બીજાં નવાં વસ્ત્રો ગ્રહણ કરે છે, તેમજ જીવાત્મા જૂનાં શરીરો ત્યજીને બીજાં નવાં શરીરો પામે છે. 2.22",

    "આ આત્માને શસ્ત્રો છેદી શકતાં નથી, આને અગ્નિ બાળી શકતો નથી, આને પાણી ઓગાળી શકતું નથી અને પવન આને સુકવી શકતો નથી. 2.23",

    "કેમકે આ આત્મા અચ્છેદ્ય, અદાહ્ય, અક્લેદ્ય, અશોષ્ય તથા નિત્ય, સર્વવ્યાપી, અચળ, સ્થિર તેમજ સનાતન છે. 2.24",

    "આ આત્મા અવ્યક્ત, અચિંત્ય અને વિકારરહિત કહેવાય છે; તેથી હે અર્જુન! આ આત્માને આ પ્રમાણે જાણીને તું શોક કરવાને યોગ્ય નથી એટલે કે તારે શોક કરવો ઉચિત નથી. 2.25",

    "અને જો તું આ આત્માને સદા જન્મ લેનાર તથા સદા મૃત્યુ પામનાર માનતો હોય, તો પણ હે મહાબાહો! આવી રીતે શોક કરવો તારા માટે યોગ્ય નથી. 2.26",

    "કેમકે આ માન્યતા મુજબ જન્મેલાનું મૃત્યુ નિશ્ચિત છે અને મરેલાનો જન્મ નિશ્ચિત છે; તેથી પણ આ ઉપાય વિનાના વિષયમાં શોક કરવો તારા માટે યોગ્ય નથી. 2.27",

    "હે અર્જુન! સઘળાં પ્રાણીઓ જન્મ પૂર્વે અપ્રગટ હતાં અને મર્યા પછી પણ અપ્રગટ થઈ જનાર છે, કેવળ વચગાળામાં જ પ્રગટ છે, તો પછી આવી સ્થિતિમાં શો શોક કરવાનો? 2.28",

    "કોઈ એક વિરલ મહાપુરુષ જ આ આત્માને આશ્ચર્યની જેમ જુએ છે, તેમજ બીજો કોઈ મહાપુરુષ જ આત્મતત્ત્વને આશ્ચર્યની જેમ વર્ણવે છે તથા બીજો કોઈ અધિકારી પુરુષ જ આને આશ્ચર્યની પેઠે સાંભળે છે અને કોઈ તો સાંભળીને પણ આને જાણતો નથી. 2.29",

    "હે અર્જુન! આ આત્મા સૌનાં શરીરોમાં હંમેશાં અવધ્ય છે; એ કારણે તારે સમસ્ત પ્રાણીઓ માટે શોક કરવો ઉચિત નથી. 2.30",

    "વળી, સ્વધર્મને જોતાં પણ તારે ભય પામવો ન જોઈએ; કેમકે ક્ષત્રિય માટે ધર્મયુક્ત યુદ્ધથી વધીને બીજું કોઈ કલ્યાણકારી કર્તવ્ય નથી. 2.31",

    "હે પાર્થ! આપમેળે પ્રાપ્ત થયેલા તેમજ ઊઘડેલા સ્વર્ગના દ્વારરૂપી આવા યુદ્ધને ભાગ્યશાળી ક્ષત્રિયો જ મેળવે છે. 2.32",

    "આમ છતાં પણ જો તું આ ધર્મયુક્ત યુદ્ધ નહીં કરે, તો સ્વધર્મ અને કીર્તિને ગુમાવીને પાપને પામીશ. 2.33",

    "એટલું જ નહીં, બધા લોકો ઘણા લાંબા કાળ સુધી રહેનારી તારી અપકીર્તિ પણ કહેશે અને ગૌરવશાળી પુરુષ માટે અપકીર્તિ મરણથીયે અધિક છે. 2.34",

    "તારા અતુલનીય પ્રભાવને લીધે જેઓ તને ઘણા સન્માનની દૃષ્ટિએ જોતા આવ્યા છે, તેમની દૃષ્ટિમાં તું તુચ્છતાને પામીશ, તે મહારથીઓ તને ભયને લીધે યુદ્ધથી વિમુખ થયેલો માનશે. 2.35",

    "તારા વેરીઓ તારા સામર્થ્યની નિંદા કરતાં તને ઘણાં બધાં ન કહેવા જેવાં વચનો પણ કહેશે; એનાથી વધારે દુ:ખ બીજું શું હશે? 2.36",

    "કાં તો તું યુદ્ધમાં હણાઈને સ્વર્ગ પામીશ અથવા તો સંગ્રામમાં જીતીને ભૂમંડળનું રાજ્ય ભોગવીશ, માટે હે અર્જુન! તું યુદ્ધ માટે નિશ્ચય કરીને ઊભો થઈ જા. 2.37",

    "જય-પરાજય, લાભ-હાનિ અને સુખ-દુઃખને સમાન સમજ્યા પછી યુદ્ધ માટે કટિબદ્ધ થઈ જા, આ પ્રમાણે યુદ્ધ કરવાથી તું પાપને પામીશ નહીં. 2.38",

    "હે પાર્થ! આ બુદ્ધિ તારે માટે જ્ઞાનયોગના વિષયમાં કહેવામાં આવી અને હવે તું એને કર્મયોગના વિષયમાં સાંભળ, જે બુદ્ધિથી યુક્ત થયેલો તું કર્મોના બંધનને સારી પેઠે ત્યજી દઈશ એટલે કે કર્મબંધનમાંથી છૂટી જઈશ. 2.39",

    "આ કર્મયોગમાં આરંભનો અર્થાત્ બીજનો નાશ નથી અને પ્રત્યવાય દોષ અર્થાત્ અવળા ફળરૂપી દોષ પણ નથી, બલકે આ કર્મયોગરૂપી ધર્મનું થોડું પણ આચરણ જન્મ-મૃત્યુરૂપી મહાન ભયમાંથી ઉગારે છે. 2.40",

    "હે અર્જુન! આ કર્મયોગમાં નિશ્ચયાત્મિકા બુદ્ધિ એક જ હોય છે, જ્યારે અસ્થિર વિચારના વિવેકહીન સકામ માણસોની બુદ્ધિઓ ખરેખર ઘણા પ્રકારની અને અપાર હોય છે. 2.41",

    "હે અર્જુન! જેઓ ભોગોમાં તન્મય થયેલા છે, જેઓ કર્મફળનાં પ્રશંસક વેદવાક્યોમાં જ પ્રીતિ સેવે છે, જેમની બુદ્ધિમાં એકમાત્ર સ્વર્ગ જ પરમ પ્રાપ્ય વસ્તુ છે અને જેઓ સ્વર્ગથી ચઢિયાતું બીજું કશું જ નથી - એમ બોલનારા છે, એવા અવિવેકી માણસો આ પ્રકારની પુષ્પિત એટલે કે માત્ર સાંભળવામાં જ મધુર અને મનોહર વાણી બોલ્યા કરે છે કે જે જન્મરૂપી કર્મફળ દેનારી તેમજ ભોગ તથા ઐશ્વર્યની પ્રાપ્તિ અર્થે વિવિધ જાતની ઘણી-બધી ક્રિયાઓનું વર્ણન કરનારી છે, તે વાણી દ્વારા જેમનું ચિત્ત હરાયેલું છે, જેઓ ભોગ અને ઐશ્વર્યમાં અત્યંત આસક્ત છે, એવા માણસોની પરમાત્મામાં નિશ્ચયાત્મિકા બુદ્ધિ નથી હોતી. 2.42-2.44",

    "હે અર્જુન! જેઓ ભોગોમાં તન્મય થયેલા છે, જેઓ કર્મફળનાં પ્રશંસક વેદવાક્યોમાં જ પ્રીતિ સેવે છે, જેમની બુદ્ધિમાં એકમાત્ર સ્વર્ગ જ પરમ પ્રાપ્ય વસ્તુ છે અને જેઓ સ્વર્ગથી ચઢિયાતું બીજું કશું જ નથી - એમ બોલનારા છે, એવા અવિવેકી માણસો આ પ્રકારની પુષ્પિત એટલે કે માત્ર સાંભળવામાં જ મધુર અને મનોહર વાણી બોલ્યા કરે છે કે જે જન્મરૂપી કર્મફળ દેનારી તેમજ ભોગ તથા ઐશ્વર્યની પ્રાપ્તિ અર્થે વિવિધ જાતની ઘણી-બધી ક્રિયાઓનું વર્ણન કરનારી છે, તે વાણી દ્વારા જેમનું ચિત્ત હરાયેલું છે, જેઓ ભોગ અને ઐશ્વર્યમાં અત્યંત આસક્ત છે, એવા માણસોની પરમાત્મામાં નિશ્ચયાત્મિકા બુદ્ધિ નથી હોતી. 2.42-2.44",

    "હે અર્જુન! જેઓ ભોગોમાં તન્મય થયેલા છે, જેઓ કર્મફળનાં પ્રશંસક વેદવાક્યોમાં જ પ્રીતિ સેવે છે, જેમની બુદ્ધિમાં એકમાત્ર સ્વર્ગ જ પરમ પ્રાપ્ય વસ્તુ છે અને જેઓ સ્વર્ગથી ચઢિયાતું બીજું કશું જ નથી - એમ બોલનારા છે, એવા અવિવેકી માણસો આ પ્રકારની પુષ્પિત એટલે કે માત્ર સાંભળવામાં જ મધુર અને મનોહર વાણી બોલ્યા કરે છે કે જે જન્મરૂપી કર્મફળ દેનારી તેમજ ભોગ તથા ઐશ્વર્યની પ્રાપ્તિ અર્થે વિવિધ જાતની ઘણી-બધી ક્રિયાઓનું વર્ણન કરનારી છે, તે વાણી દ્વારા જેમનું ચિત્ત હરાયેલું છે, જેઓ ભોગ અને ઐશ્વર્યમાં અત્યંત આસક્ત છે, એવા માણસોની પરમાત્મામાં નિશ્ચયાત્મિકા બુદ્ધિ નથી હોતી. 2.42-2.44",

    "હે અર્જુન! વેદો ઉપર જણાવ્યા અનુસાર ત્રણેય ગુણોના કાર્યરૂપ સઘળા ભોગો તથા એમનાં સાધનોનું પ્રતિપાદન કરનારા છે; માટે તું એ ભોગો અને એમનાં સાધનોમાં આસક્તિરહિત અને હરખ-શોક વગેરે દ્વન્દ્વોથી રહિત બનીને નિરંતર પરમાત્મામાં સ્થિત થઈ જા, તેમજ યોગક્ષેમને (અપ્રાપ્તની પ્રાપ્તિનું નામ 'યોગ' અને પ્રાપ્તની રક્ષાનું નામ 'ક્ષેમ' છે.) ન ઈચ્છનાર (એટલે કે શરીરનિર્વાહની ચિંતા પણ ન કરનાર) તથા સ્વાધીન અન્તઃકરણનો અર્થાત્ જીતેન્દ્રિય થા. 2.45",

    "બધી બાજુથી ભરપૂર જળાશય મળી જતાં નાનકડા જળાશયમાં મનુષ્યનું જેટલું પ્રયોજન રહે છે, બ્રહ્મને તત્ત્વથી જાણનાર બ્રાહ્મણનું સઘળા વેદોમાં એટલું જ પ્રયોજન રહે છે. (અર્થાત્ જેમ મોટું જળાશય પ્રાપ્ત થઈ જતાં જળ માટે નાનાં જળાશયોની જરૂર રહેતી નથી, તેમજ બ્રહ્માનંદની પ્રાપ્તિ થતાં આનંદ માટે વેદોક્ત કર્મોના ફળરૂપી ભોગોની જરૂર રહેતી નથી.) 2.46",

    "તારો કર્મ કરવામાં જ અધિકાર છે, એના ફળોમાં કદીયે નહીં, માટે તું ફળનો હેતુ થા મા, અર્થાત્ ફલાપેક્ષાથી રહિત થઈને કર્તવ્યબુદ્ધિથી કર્મ કર તથા તારી કર્મ ન કરવામાં પણ આસક્તિ ન થાઓ. 2.47",

    "હે ધનંજય! તું આસક્તિ ત્યજીને તથા સિદ્ધિ અને અસિદ્ધિમાં સમબુદ્ધિ રાખીને યોગમાં સ્થિત થઈ કર્તવ્ય કર્મો કર, 'સમત્વ' એ જ યોગ કહેવાય છે. 2.48",

    "આ સમત્વરૂપ બુદ્ધિયોગ કરતાં સકામ કર્મ ઘણું જ નીચલી કક્ષાનું છે, માટે હે ધનંજય! તું સમબુદ્ધિમાં જ રક્ષણનો ઉપાય શોધ અર્થાત્ બુદ્ધિયોગનો જ આશરો લે; કેમકે ફળનો હેતુ બનનારા અર્થાત્ ફલાપેક્ષી જનો દયાને પાત્ર છે. 2.49",

    "સમબુદ્ધિયુક્ત માણસ પુણ્ય અને પાપ બેયને આ લોકમાં ત્યાગી દે છે - તેમનાથી મુક્ત થઈ જાય છે, માટે તું સમત્વરૂપ યોગમાં જોડાઈ જા, આ સમત્વરૂપ યોગ એ જ કર્મોમાં કુશળતા છે અર્થાત્ કર્મબંધનમાંથી છૂટવાનો ઉપાય છે. 2.50",

    "કેમકે સમબુદ્ધિથી યુક્ત જ્ઞાનીજનો કર્મોથી ઉત્પન્ન થનાર ફળનો ત્યાગ કરીને જન્મરૂપી બંધનથી મુક્ત થઈ નિર્વિકાર પરમ પદને પામે છે. 2.51",

    "જ્યારે તારી બુદ્ધિ મોહરૂપી કળણને સારી રીતે ઓળંગી જશે, તે વખતે તું સાંભળેલા અને સાંભળવા બાકી રહેલા આ તેમ જ પરલોક સંબંધી બધાએ ભોગો પ્રત્યે વૈરાગ્ય પામીશ. જાતજાતના વચનો સાંભળવા ને લીધે વિચલીત થયેલી તારી બુદ્ધિ જ્યારે પરમાત્મામાં અચળ અને સ્થિર ભાવે સ્થાયી થઈ જશે, ત્યારે તું યોગ ને પામીશ અર્થાત્ તારો પરમાત્મા સાથે નિત્ય સંયોગ થઈ જશે. 2.52-2.53",

    "જ્યારે તારી બુદ્ધિ મોહરૂપી કળણને સારી રીતે ઓળંગી જશે, તે વખતે તું સાંભળેલા અને સાંભળવા બાકી રહેલા આ તેમ જ પરલોક સંબંધી બધાએ ભોગો પ્રત્યે વૈરાગ્ય પામીશ. જાતજાતના વચનો સાંભળવા ને લીધે વિચલીત થયેલી તારી બુદ્ધિ જ્યારે પરમાત્મામાં અચળ અને સ્થિર ભાવે સ્થાયી થઈ જશે, ત્યારે તું યોગ ને પામીશ અર્થાત્ તારો પરમાત્મા સાથે નિત્ય સંયોગ થઈ જશે. 2.52-2.53",

    "અર્જુન બોલ્યા: હે કેશવ! સમાધિમાં સ્થિત પરમાત્માને પામેલા સ્થિર બુદ્ધિ પુરુષનું શું લક્ષણ છે? તે સ્થિર બુદ્ધિ પુરુષ કેવી રીતે બોલે છે, કેવી રીતે બેસે છે તથા કેવી રીતે ચાલે છે અર્થાત તેનાં આચરણ કેવાં હોય છે? 2.54",

    "શ્રી ભગવાન બોલ્યા: હે પાર્થ! જે વખતે આ પુરુષ મનમાં રહેલી સઘળી કામનાઓને સમ્યક રીતે ત્યજી દે છે અને આત્માથી આત્મામાં જ સંતુષ્ટ રહે છે ત્યારે તે સ્થિતપ્રજ્ઞ કહેવાય છે. 2.55",

    "દુઃખોની પ્રાપ્તિ થતા જેના મનમાં ઉદ્વેગ નથી થતો સુખો મળતા જે સંપૂર્ણપણે નિસ્પૃહ હોય છે તથા જેના રાગ, ભય અને ક્રોધ નાશ પામ્યા છે એવો મુની સ્થિરબુદ્ધિ કહેવાય છે. 2.56",

    "જે પુરુષ સર્વત્ર સ્નેહરહિત થયેલો છે તે શુભ કે અશુભ વસ્તુ પામીને નથી પ્રસન્ન થતો કે નથી દ્વેષ કરતો તેની બુદ્ધિ સ્થિર છે. 2.57",

    "કાચબો બધી બાજુથી પોતાના અંગોને જેમ સંકોરી લે છે તેમજ જ્યારે આ પુરુષ ઈન્દ્રિયોના વિષયથી ઇન્દ્રિયોને સર્વ પ્રકારે ખેંચી લે છે ત્યારે તેની બુદ્ધિ સ્થિર છે એમ સમજવું. 2.58",

    "ઇન્દ્રિયો દ્વારા વિષયોને ગ્રહણ ન કરનાર પુરુષનાય માત્ર વિષયો તો નિવૃત્ત થઈ જાય છે પરંતુ એમના પ્રત્યેની આસક્તિ નિવૃત્ત થતી નથી થતી જ્યારે આ સ્થિતપ્રજ્ઞ પુરુષની તો આસક્તિ પણ પરમાત્માનો સાક્ષાત્કાર કરીને નિવૃત્ત થઈ જાય છે અર્થાત્ પરમાત્માનો સાક્ષાત્કાર થવાને લીધે એની સંસાર પ્રત્યે લેશ માત્ર આ શક્તિ રહેતી નથી. 2.59",

    "હે કુંતીપુત્ર, આસક્તિનો નાશ થવાને લીધે મંથન કરી નાખવાના સ્વભાવની આ ઇન્દ્રિયો યત્ન કરતા બુદ્ધિમાન મનુષ્યના મનને પણ પરાણે હરી લે છે. 2.60",

    "માટે સાધક માટે જરૂરી છે કે પોતે આ સમસ્ત ઇન્દ્રિયોને વશમાં કરી સમાહિત ચિત્ત થયેલો મારા પરાયણ થઈને ધ્યાનમાં બેસે કેમકે જે પુરુષની ઇન્દ્રિયો વશ માં હોય છે તેની બુદ્ધિ સ્થિર થાય છે. 2.61",

    "વિષયોનું ચિંતન કરનારા પુરુષને તે વિષયોમાં આસક્તિ જન્મે છે આસક્તિથી તે વિષયોની કામના ઉત્પન્ન થાય છે કામનામાં વિઘ્ન આવવાથી ક્રોધ જન્મે છે. 2.62",

    "ક્રોધથી ઘણી મૂઢતા આવે છે, મૂઢતાથી સ્મૃતિમાં ભ્રમ ઊભો થાય છે, સ્મૃતિમાં ભ્રમ ઊભો થવાથી બુદ્ધિ અર્થાત્ જ્ઞાનશક્તિનો નાશ થઈ જાય છે અને બુદ્ધિનો નાશ થવાથી એ પુરુષનું પોતાની સ્થિતિથી પતન થાય છે. 2.63",

    "પરંતુ સ્વાધીન અંતઃકરણનો સાધક પોતાના વશમાં કરેલી રાગદ્વેષ વિનાની ઇન્દ્રિયો દ્વારા વિષયોમાં વિચરણ કરતો અન્તઃકરણની આધ્યાત્મિક પ્રસન્નતાને પામે છે. 2.64",

    "અન્તઃકરણ પ્રસન્ન થતાં આનાં સર્વ દુઃખોનો અભાવ થઈ જાય છે અને એ પ્રસન્ન-ચિત્ત કર્મયોગીની બુદ્ધિ તત્કાળ બધી બાજુએથી દૂર થઈને એક પરમાત્મામાં જ સારી સ્થિર થઈ જાય છે. 2.65",

    "જે પુરુષે મન અને ઈન્દ્રિયો જીત્યાં નથી તેનામાં નિશ્ચયાત્મિકા બુદ્ધિ નથી હોતી તેમજ એ અયુક્ત માણસના અન્તઃકરણમાં ભાવના પણ નથી હોતી તથા ભાવનાહીન માણસને શાંતિ નથી મળતી અને શાંતિ વિનાના માણસને સુખ ક્યાંથી મળે? 2.66",

    "કેમકે જેમ વાયુ જળમાં વહેતી નૌકાને ખેંચી જાય છે, તે જ પ્રમાણે વિષયોમાં વિચરતી ઈન્દ્રિયોમાંથી જે ઈન્દ્રિયની સાથે મન રહે છે, તે એકલી ઈન્દ્રિય આ અયુક્ત પુરુષની બુદ્ધિને હરી લે છે. 2.67",

    "તેથી હે મહાબાહો ! જે પુરુષની ઈન્દ્રિયો ઈન્દ્રિયોના વિષયોથી સર્વ પ્રકારે નિગૃહીત કરાયેલી છે, એની જ બુદ્ધિ સ્થિર છે. 2.68",

    "સમસ્ત પ્રાણીઓ માટે જે રાત્રિ સમાન છે, તે નિત્ય જ્ઞાનસ્વરૂપ પરમાનન્દની પ્રાપ્તિમાં સ્થિતપ્રજ્ઞ યોગી જાગે છે અને જે નાશવંત સાંસારિક સુખની પ્રાપ્તિમાં સઘળાં પ્રાણીઓ જાગે છે, પરમાત્માના તત્ત્વને જાણનાર મુનિ માટે તે રાત્રિ સમાન છે. 2.69",

    "જે પ્રમાણે બધી બાજુથી ભરપૂર, અચળ પ્રતિષ્ઠાવાળા સમુદ્રમાં અનેક નદીઓનાં પાણી તેને વિચલિત કર્યા વિના જ સમાઈ જાય છે, તે જ પ્રમાણે સર્વ ભોગો જે સ્થિતપ્રજ્ઞ પુરુષમાં કોઈ પણ પ્રકારનો વિકાર ઉત્પન્ન કર્યા વિના જ સમાઈ જાય છે, તે જ પુરુષ પરમ શાંતિને પામે છે, ભોગોને ઈચ્છનારો નહીં. 2.70",

    "જે પુરુષ સઘળી કામનાઓ છોડીને મમતા વિનાનો, અહંકાર વિનાનો અને સ્પૃહા વિનાનો થઈને વિચરે છે તે જ શાંતિને પામે છે અર્થાત્ તે શાંતિને પામેલો છે. 2.71",

    "હે પાર્થ ! આ બ્રહ્મને પામી ચુકેલા પુરુષની સ્થિતિ છે, આને પામીને યોગી કદી મોહિત નથી થતો અને અંતકાળે પણ આ બ્રાહ્મી સ્થિતિમાં સ્થિત થઈને બ્રહ્માનંદને પામી જાય છે. 2.72",

    "આ રીતે ૐ, તત્‌, સત્‌-એ ભગવાનના નામોના ઉચ્ચારણ સાથે બ્રહ્મવિધા અને યોગશાસ્ત્રમય શ્રીમદ્ભગવદ્‌ગીતોપનિષદરૂપી શ્રીકૃષ્ણાર્જુનસંવાદમાં \"સાંખ્યયોગ\" નામનો બીજો અધ્યાય પૂરો થયો. ॥૨॥"
  ],
  "adhyaya-3" : [
    "અર્જુન બોલ્યા : હે જનાર્દન! જો તમે કર્મ કરતાં જ્ઞાનને ચઢિયાતું માનો છો, તો પછી હે કેશવ! મને આ યુદ્ધરૂપી ભયાનક કર્મમાં કેમ જોડો છો? 3.1",

    "તમે ભળતાં જેવાં વચનોથી મારી બુદ્ધિને જાણે ભરમાવી રહ્યા છો, તો એવી એક વાતને નિશ્ચિત કરીને કહો, કે જેનાથી હું કલ્યાણને પામું. 3.2",

    "શ્રી ભગવાન બોલ્યા : હે નિષ્પાપ! આ લોકમાં બે પ્રકારની નિષ્ઠા મારા વડે પહેલાં કહેવાઈ ચુકી છે; એમનામાંથી સાંખ્યયોગીઓની નિષ્ઠા જ્ઞાનયોગ દ્વારા અને યોગીઓની નિષ્ઠા કર્મયોગ દ્વારા થાય છે. 3.3",

    "માણસ ન તો કર્મોનો આરંભ કર્યા વિના અર્થાત્ આચરણ કર્યા વિના નિષ્કર્મતાને એટલે કે યોગનિષ્ઠાને પામી શકે કે છે કે ન તો કર્મોના કેવળ ત્યાગમાત્રથી સિદ્ધિ એટલે કે સાંખ્યનિષ્ઠાને પામી શકે છે. 3.4",

    "ખરેખર કોઈ પણ માણસ કદીયે ક્ષણમાત્ર પણ કર્મ કર્યા વિના નથી રહેતો; કેમકે આખાય મનુષ્યસમુદાયને પ્રકૃતિજનિત ગુણો વડે પરવશ થઈને કર્મ તો કરવું જ પડે છે. 3.5",

    "જે મૂઢબુદ્ધિનો મનુષ્ય સઘળી ઈન્દ્રિયોને હઠપૂર્વક ઉપર-ઉપરથી રોકીને મનથી તે ઈન્દ્રિયોના વિષયોનું ચિંતન કરતો રહે છે, તે મિથ્યાચારી અર્થાત્ દંભી કહેવાય છે. 3.6",

    "પરંતુ હે અર્જુન! જે પુરુષ મન વડે ઇન્દ્રિયોને વશમાં કરી અનાસક્ત થઈને સઘળી ઈન્દ્રિયો દ્વારા કર્મયોગનું આચરણ કરે છે, તે યોગી શ્રેષ્ઠ છે. 3.7",

    "આથી તું શાસ્ત્રવિહીત સ્વધર્મરૂપી કર્તવ્યકર્મ કર; કેમકે કર્મ ન કરવાં કરતાં કર્મ કરવું શ્રેષ્ઠ છે, તેમજ કર્મ ન કરવાથી તારો શરીર-નિર્વાહ પણ સિદ્ધ નહીં થાય. 3.8",

    "યજ્ઞનિમિતે કરવામાં આવતાં કર્મો સિવાયના બીજાં કર્મોમાં જોડાઈ ને જ આ મનુષ્ય-સમુદાય કર્મોથી બંધાય છે, માટે હે અર્જુન! તું આસક્તિ વિનાનો થઈને તે યજ્ઞનિમિત્તે જ સારી રીતે કર્તવ્યકર્મ કર. 3.9",

    "પ્રજાપતિ બ્રહ્માએ કલ્પના આદિમાં અર્થાત્ સૃષ્ટિના આરંભે યજ્ઞસહિત પ્રજાઓને સર્જીને તે પ્રજાઓને કહ્યું કે તમે આ યજ્ઞ દ્વારા વૃદ્ધિ પામો અને આ યજ્ઞ તમને બધાને ઇચ્છિત ભોગ આપનાર થાઓ. 3.10",

    "તમે બધા આ યજ્ઞ વડે દેવતાઓને ઉન્નત કરો અને તે દેવતાઓ તમને બધાને ઉન્નત કરે; આ પ્રમાણે નિ:સ્વાર્થભાવે એક-બીજાને ઉન્નત કરતા રહીને તમે બધા પરમ કલ્યાણ પામશો. 3.11",

    "યજ્ઞ વડે વૃદ્ધિ પામેલા દેવતાઓ તમને બધાને વણમાગ્યે જ ઈચ્છિત ભોગો અવશ્ય આપતા રહેશે; આ રીતે તે દેવતાઓ દ્વારા અપાયેલા ભોગોને જે પુરુષ તે દેવતાઓને નહીં આપી પોતે ભોગવે છે, તે ચોર જ છે. 3.12",

    "યજ્ઞમાંથી વધેલા અન્નને આરોગનારા શ્રેષ્ઠ પુરુષો સર્વ પાપોથી મુક્ત થઈ જાય છે અને જે પાપીઓ પોતાના શરીરના પોષણ માટે જ અન્ન રાંધે છે, તેઓ તો પાપને જ ખાય છે. 3.13",

    "સઘળાં પ્રાણીઓ અન્નથી ઉત્પન્ન થાય છે, અન્નની ઉત્પતિ વરસાદથી થાય છે, વરસાદ યજ્ઞથી થાય છે અને યજ્ઞ વિહિત કર્મોથી ઉત્પન્ન થનારો છે, કર્મસમુદાયને તું વેદથી ઉદ્ભવેલો અને વેદને અવિનાશી પરમાત્માથી ઉદ્ભવેલો જાણ; આથી સિદ્ધ થાય છે કે સર્વવ્યાપી પરમ અક્ષર પરમાત્મા સદાય યજ્ઞમાં પ્રતિષ્ઠિત છે. 3.14-3.15",

    "સઘળાં પ્રાણીઓ અન્નથી ઉત્પન્ન થાય છે, અન્નની ઉત્પતિ વરસાદથી થાય છે, વરસાદ યજ્ઞથી થાય છે અને યજ્ઞ વિહિત કર્મોથી ઉત્પન્ન થનારો છે, કર્મસમુદાયને તું વેદથી ઉદ્ભવેલો અને વેદને અવિનાશી પરમાત્માથી ઉદ્ભવેલો જાણ; આથી સિદ્ધ થાય છે કે સર્વવ્યાપી પરમ અક્ષર પરમાત્મા સદાય યજ્ઞમાં પ્રતિષ્ઠિત છે. 3.14-3.15",

    "હે પાર્થ! જે મનુષ્ય આ લોકમાં આ પ્રમાણે પરંપરાથી પ્રચલિત સૃષ્ટીચક્રને નથી અનુસરતો, અર્થાત્ પોતાના કર્તવ્યનું પાલન નથી કરતો, તે ઈન્દ્રિયો વડે ભોગોમાં રમનારો પાપાયુ મનુષ્ય ફોગટ જ જીવે છે. 3.16",

    "પણ જે માણસ આત્માંમાં જ રમનારો અને આત્મામાં જ તૃપ્ત તેમજ આત્મામાં સંતુષ્ટ હોય, તેના માટે કશું કરવાનું બાકી નથી રહેતું. 3.17",

    "એ મહાપુરુષનું આ સંસારમાં ન તો કર્મ કરવાથી કશું પ્રયોજન રહે છે કે ન તો કર્મ ન કરવાથીયે કશું પ્રયોજન રહે છે; તેમજ સારા જીવો સાથે આ મહાપુરુષનો લેશમાત્ર પણ સ્વાર્થનો સંબંધ નથી રહેતો. 3.18",

    "આથી તું નિરંતર આસક્તિ વિનાનો થઈ હંમેશા કર્તવ્યકર્મોને સમ્યક્ રીતે કરતો રહે, કેમકે આસક્તિ વિનાનો થઈને કર્મ કરતો માણસ પરમાત્માને પામે છે. 3.19",

    "જનક વગેરે જ્ઞાનીજનો પણ આસક્તિ વિનાના કર્માચરણ દ્વારા જ પરમ સિદ્ધિને પામ્યા હતા, તેથી તથા લોકસંગ્રહને જોતાં પણ તું કર્મ કરવાને જ યોગ્ય છે, અર્થાત્ તારા માટે કર્મ કરવું એ જ ઉચિત છે. 3.20",

    "શ્રેષ્ઠ પુરુષ જે જે આચરે છે, અન્ય માણસો પણ તેને જ આચરે છે; તે જે કંઈ પ્રમાણ કરી આપે છે, સકળ માનવ-સમુહ તે પ્રમાણે વર્તવા લાગે છે. 3.21",

    "હે પાર્થ! મારે આ ત્રણેય લોકોમાં ન તો કશું કર્તવ્ય છે કે ન કશીયે પ્રાપ્ત કરવા જેવી વસ્તુ અપ્રાપ્ત છે, છતાંય હું કર્મમાં જ વર્તુ છું. 3.22",

    "કેમકે હે પાર્થ! જો કદાચ હું સાવધાન થઈને કર્મોમાં ન વર્તુ તો ભારે હાનિ થઈ જાય, કારણ કે માણસો બધી રીતે મારા જ માર્ગનું અનુકરણ કરે છે. 3.23",

    "આથી જો હું કર્મ ન કરું તો આ માણસો નષ્ટ-ભ્રષ્ટ થઈ જાય અને હું સંકરપણાનો કરનાર બનું તથા આ આખી પ્રજાને નષ્ટ કરનારો બનું. 3.24",

    "માટે હે ભારત! કર્મમાં આસક્ત થયેલા અજ્ઞાનીજનો જે પ્રમાણે કર્મ કરે છે, આસક્તિ વિનાનો વિદ્વાન પણ લોકસંગ્રહની ઈચ્છા રાખતો તે જ પ્રમાણે કર્મ કરે. 3.25",

    "પરમાત્માના સ્વરૂપમાં અચળભાવે સ્થિત થયેલો જ્ઞાની પુરૂષ શાસ્ત્રવિહીત કર્મોમાં આસક્તિ રાખનારા અજ્ઞાનીઓની બુદ્ધિમાં ભ્રમ અર્થાત્ એ કર્મો પ્રત્યે અશ્રદ્ધા ન ઉપજાવે; પરંતુ પોતે શાસ્ત્રવિહિત કર્મો સારી પેઠે કરતો રહી એમની પાસે પણ તે જ પ્રમાણે કર્મ કરાવે. 3.26",

    "વાસ્તવમાં સઘળાં કર્મો સર્વ પ્રકારે પ્રકૃતિના ગુણો વડે કરવામાં આવે છે, છતાં પણ અહંકાર દ્વારા મોહિત થયેલા અંત:કરણનો અજ્ઞાની ‘હું કર્તા છું' એમ માને છે. 3.27",

    "પણ હે મહાબાહો! ગુણવિભાગ અને કર્મવિભાગના તત્ત્વને જાણનાર જ્ઞાનયોગી તો સમસ્ત ગુણો જ ગુણોમાં વર્તી રહ્યા છે એમ સમજીને એમનામાં આસક્ત નથી થતો. 3.28",

    "પ્રકૃતિના ગુણોથી અત્યંત મોહિત થયેલા મનુષ્યો ગુણોમાં અને કર્મોમાં આસક્ત રહે છે; એવા સંપૂર્ણપણે ન સમજનાર મંદબુદ્ધિના અજ્ઞાનીઓને સંપૂર્ણપણે જાણનાર જ્ઞાની વિચલિત ન કરે. 3.29",

    "મુજ અન્તર્યામી પરમાત્મામાં પરોવાયેલા ચિત્ત દ્વારા સઘળાં કર્મોને મારામાં સમર્પીને ઈચ્છા વિનાનો, મમત્વ વિનાનો, અને સંતાપ વિનાનો થઈને તું યુદ્ધ કર. 3.30",

    "જે માણસો દોષર્દષ્ટિ વિનાના અને શ્રદ્ધાળુ બનીને મારા આ મતને સદા અનુસરે છે, તેઓ પણ સર્વ કર્મોથી છૂટી જાય છે. 3.31",

    "પણ જે માણસો મારામાં દોષારોપણ કરીને મારા આ મત અનુસાર નથી ચાલતા, એ મૂર્ખાઓને તો તું સર્વ જ્ઞાનોમાં મોહિત અને નષ્ટ થયેલા જ સમજ. 3.32",

    "સર્વ પ્રાણીઓ પ્રકૃતિને પામે છે એટલે કે પોતાનાં સ્વભાવને વશ થઈને કર્મો કરે છે; જ્ઞાની પણ પોતાની પ્રકૃતિ અનુસાર ચેષ્ટા કરે છે; એમાં કોઈનો દુરાગ્રહ શો કરવાનો? 3.33",

    "ઈન્દ્રિય-ઈન્દ્રિયના અર્થમાં એટલે કે પ્રત્યેક ઈંન્દ્રિયનાં વિષયમાં રાગ અને દ્વેષ છુપાઈને રહેલા છે; માણસે એ બન્નેને વશ ન થવું; કેમ કે તે બન્નેય એના કલ્યાણમાર્ગમાં વિધ્નો ઊભા કરનારા મહાશત્રુઓ છે. 3.34",

    "સારી રીતે આચરવામાં આવેલા અન્યના ધર્મ કરતાં ગુણ વિનાનો હોવા છતાં પણ પોતાનો ધર્મ ઘણો ચઢિયાતો છે; પોતાના ધર્મમાં તો મરવું પણ કલ્યાણકારી છે, જ્યારે અન્યનો ધર્મ તો ભયપ્રદ છે. 3.35",

    "અર્જુન બોલ્યા : હે કૃષ્ણ! તો પછી આ માણસ પોતે ન ઈચ્છતો હોવા છતાં પણ પરાણે જોડાયો હોય એમ કોનાથી પ્રેરાઈને પાપનું આચરણ કરે છે? 3.36",

    "શ્રી ભગવાન બોલ્યા: રજોગુણમાંથી ઉદ્ભવેલો આ કામ જ ક્રોધ છે, આ ઘણું ખાનારો એટલે કે ભોગોથી કદી ન ધરાતા અને મહાપાપી છે, આને જ તું આ બાબતમાં વેરી જાણ. 3.37",

    "જેમ ધુમાડાથી અગ્નિ અને મેલથી દર્પણ ઢંકાઈ જાય છે તથા જેમ ઓરથી ગર્ભ ઢંકાયેલો રહે છે, તેમજ એ કામ વડે આ જ્ઞાન ઢંકાયેલું છે. 3.38",

    "હે કૌન્તેય! આ અગ્નિની પેઠે કદી પૂર્ણ ન થનારા કામરૂપી જ્ઞાનીઓના નિત્ય વેરી વડે માણસનું જ્ઞાન ઢંકાયેલું છે. 3.39",

    "ઈન્દ્રિયો, મન અને બુદ્ધિ - એ સર્વ આનું રહેઠાણ કહેવાય છે; આ કામ આ મન, બુદ્ધિ અને ઈન્દ્રિયો દ્વારા જ જ્ઞાનને ઢાંકી દઇને જીવાત્માને મોહિત બનાવે છે. 3.40",

    "માટે હે ભરતવંશીઓમાં શ્રેષ્ઠ! તું પહેલાં ઈન્દ્રિયોને વશમાં કરીને, જ્ઞાન અને વિજ્ઞાનનો નાશ કરનાર આ મહાપાપી કામને અવશ્ય બળપૂર્વક હણી નાંખ. 3.41",

    "ઈન્દ્રિયોને સ્થૂળ શરીર કરતાં પર અર્થાત્ શ્રેષ્ઠ, બળવાન તેમજ સૂક્ષ્મ કહે છે; આ ઈન્દ્રિયો કરતાં મન પર છે, મન કરતાંય બુદ્ધિ પર છે અને જે બુદ્ધિ કરતાં પણ સાવ પર છે, તે આત્મા છે. 3.42",

    "આ પ્રમાણે બુદ્ધિ કરતાં પર એટલે કે સૂક્ષ્મ, બળવાન અને ઘણા શ્રેષ્ઠ આત્માને ઓળખીને અને બુદ્ધિ વડે મનને વશ કરીને હે મહાબાહો ! તું આ કામરૂપી દુર્જય શત્રુને હણ. 3.43",

    "આ રીતે ૐ, તત્‌, સત્‌-એ ભગવાનના નામોના ઉચ્ચારણ સાથે બ્રહ્મવિધા અને યોગશાસ્ત્રમય શ્રીમદ્ભગવદ્‌ગીતોપનિષદરૂપી શ્રીકૃષ્ણાર્જુનસંવાદમાં \"કર્મયોગ\" નામનો ત્રીજો અધ્યાય પૂરો થયો. ॥૩॥"
  ],
  "adhyaya-4" : [
    "શ્રી ભગવાન બોલ્યા : આ અવિનાશી યોગ મેં સૂર્યને કહ્યો હતો, સૂર્યએ પોતાના પુત્ર વૈવસ્વત મનુને કહ્યો અને મનુએ પોતાના પુત્ર રાજા ઇક્ષ્વાકુને કહ્યો. 4.1",

    "હે પરંતપ! આમ પરંપરાથી પ્રાપ્ત આ યોગને રાજર્ષિઓએ જાણ્યો; પણ ત્યાર બાદ તે યોગ ઘણા સમયથી આ પૃથ્વીલોકમાં લગભગ લુપ્તપ્રાય થઈ ગયો. 4.2",

    "તું મારો ભક્ત અને પ્રિય સખા છે, માટે એ જ આ પુરાતન યોગ આજે મેં તને કહ્યો છે; કેમકે આ ઘણું ઉત્તમ રહસ્ય છે એટલે કે ગુપ્ત રાખવાયોગ્ય વિષય છે. 4.3",

    "અર્જુન બોલ્યા : આપનો જન્મ તો અર્વાચીન - હાલનો છે, જ્યારે સૂર્યનો જન્મ તો ઘણો પહેલાંનો છે એટલે કે કલ્પના આરંભે થયેલો છે; તો હું એ વાતને કેમ સમજું કે તમે જ કલ્પના આરંભે સૂર્યને આ યોગ કહ્યો હતો? 4.4",

    "શ્રીભગવાન બોલ્યા : હે પરંતપ અર્જુન! મારા અને તારા ધણા બધા જન્મો થઈ ચુક્યા છે; એ બધાને તું નથી જાણતો, પણ હું જાણું છું. 4.5",

    "હું અજન્મા અને અવિનાશીસ્વરૂપ હોવા છતાં પણ, તેમજ બધાંય પ્રાણીઓનો ઈશ્વર હોવા છતાંય પોતાની પ્રકૃતિને આધીન કરીને પોતાની યોગમાયાથી પ્રગટ થાઉં છું. 4.6",

    "હે ભારત! જ્યારે જ્યારે ધર્મની હાનિ અને અધર્મની વૃદ્ધિ થાય છે, ત્યારે ત્યારે હું પોતાના રૂપને સર્જું છું એટલે કે સાકારરૂપે લોકો સમક્ષ પ્રગટ થાઉં છું. 4.7",

    "સાધુપુરુષોનો ઉદ્ધાર કરવા માટે, પાપકર્મ કરનારાઓનો વિનાશ કરવા માટે અને ધર્મની સમ્યક્ રીતે સ્થાપના કરવા માટે હું યુગે યુગે પ્રગટ થાઉં છું. 4.8",

    "હે અર્જુન! મારા જન્મ અને કર્મ દિવ્ય અર્થાત્ નિર્મળ અને અલૌકિક છે - એમ જે માણસ તત્ત્વથી જાણી લે છે, તે દેહ છોડીને પુનર્જન્મ નથી પામતો, પણ મને જ પામે છે. 4.9",

    "જેમના રાગ, ભય તથા ક્રોધ સંપૂર્ણપણે નાશ પામી ચુક્યા હતા તેમજ જેઓ મારામાં અનન્ય પ્રેમભાવે સ્થિત રહેતા હતા, એવા મારે આશ્રયે રહેનારા ઘણાય ભક્તો આ પહેલા પણ ઉપર કહેલા જ્ઞાનરૂપી તપ વડે પવિત્ર થઈને મારા સ્વરૂપને પામી ચુક્યા છે. 4.10",

    "હે પાર્થ! જે ભક્તો મને જેવા ભાવથી ભજે છે, હું પણ તેમને એવા જ ભાવથી ભજું છું; કેમકે સૌ મનુષ્યો સર્વ રીતે મારા જ માર્ગને અનુસરે છે. 4.11",

    "આ મનુષ્યલોકમાં કર્મોના ફળને ઇચ્છનારા માણસો દેવતાઓનું પૂજન કરતા રહે છે; કેમકે એમને કર્મોથી ઉત્પન્ન થનારી સિદ્ધિ સત્વરે મળી જાય છે. 4.12",

    "બ્રાહ્મણ, ક્ષત્રિય, વૈશ્ય અને શૂદ્ર - આ ચાર વર્ણોનો સમૂહ ગુણ અને કર્મોના વિભાગ પ્રમાણે મારા દ્વારા સર્જાયેલો છે; આ પ્રમાણે એ સૃષ્ટિરચના વગેરે કર્મનો કર્તા હોવા છતાં પણ મુજ અવિનાશી પરમેશ્વરને તું વાસ્તવમાં અકર્તા જ જાણ. 4.13",

    "કર્મોના ફળમાં મારી સ્પૃહા નથી, માટે મને કર્મો લિપ્ત નથી કરતાં - આ પ્રમાણે જે મને તત્ત્વથી જાણી લે છે, તે પણ કર્મોથી નથી બંધાતો. 4.14",

    "પૂર્વકાળના મુમુક્ષુઓએ પણ આ પ્રમાણે જાણીને જ કર્મો કર્યાં છે, માટે તું પણ પૂર્વજો વડે સદાકાળથી આચરવામાં આવેલાં કર્મોને જ કર. 4.15",

    "કર્મ શું છે? અને અકર્મ શું છે? - એનો નિર્ણય કરવામાં બુદ્ધિશાળી પુરુષો પણ મોહિત થઈ જાય છે; માટે તે કર્મતત્ત્વ હું તને સમ્યક્ રીતે સમજાવીને કહું છું, જેને જાણીને તું અશુભથી એટલે કે કર્મબંધનમાંથી મુક્ત થઈ જઈશ. 4.16",

    "કર્મનું સ્વરૂપ પણ જાણવું જોઈએ અને અકર્મનું સ્વરૂપ પણ જાણવું જોઈએ, તેમજ વિકર્મનું સ્વરૂપ પણ જાણવું જોઈએ; કેમકે કર્મની ગતિ અતિગહન છે. 4.17",

    "જે માણસ કર્મમાં અકર્મ જુએ છે અને જે અકર્મમાં કર્મ જુએ છે, એ માણસ સઘળા માણસોમાં બુદ્ધિશાળી છે તેમજ એ યોગી સમસ્ત કર્મો કરનારો છે. 4.18",

    "જેના સર્વ શાસ્ત્રસંમત કર્મો કામના કે સંકલ્પ વિના થાય છે તથા જેના બધાંય કર્મો જ્ઞાનરૂપી અગ્નિ વડે બળી ગયાં છે, એ મહાપુરુષને જ્ઞાનીજનો પણ પંડિત કહે છે. 4.19",

    "જે માણસ સર્વ કર્મો પ્રત્યેની તેમજ એમનાં ફળ પ્રત્યેની આસક્તિને બધી રીતે ત્યાગીને સંસારના આશ્રયથી રહિત થઈ ગયો છે તથા પરમાત્મામાં સદાય તૃપ્ત છે, તે કર્મોમાં યોગ્ય રીતે વર્તતો હોવા છતાં પણ વાસ્તવમાં કંઈ જ નથી કરતો. 4.20",

    "જેને કશાયની ખેવના નથી, અંતઃકરણ અને ઇન્દ્રિયો સહિત જેણે શરીર જીત્યું છે તેમજ સઘળી ભોગોની સામગ્રીનો જેણે પરિત્યાગ કરી દીધો છે એવો સાંખ્યયોગી કેવળ શરીરસંબંધી કર્મ કરતો હોવા છતાં પણ પાપને નથી પામતો. 4.21",

    "ઇચ્છા વિના આપમેળે મળેલા પદાર્થમાં જે સદાય સંતુષ્ટ રહે છે, અદેખાઈનો જેનામાં સર્વ રીતે અભાવ થઈ ગયો છે, હરખ-શોક વગેરે દ્વંદ્વોથી જે સંપૂર્ણપણે અતીત થઈ ગયો છે - એવો સિદ્ધિ તથા અસિદ્ધિમાં સમ રહેનાર કર્મયોગી કર્મ કરતો હોવા છતાં પણ કર્મોથી નથી બંધાતો. 4.22",

    "જેની આસક્તિ સર્વથા નાશ પામી ચુકી છે, જે દેહાભિમાન તથા મમત્વ વિનાનો થઈ ચુક્યો છે, જેનું ચિત્ત નિરંતર પરમાત્માના જ્ઞાનમાં સ્થિત રહે છે - એવા કેવળ યજ્ઞસંપાદનને અર્થે કર્મ કરનાર મનુષ્યનાં સમસ્ત કર્મો પૂર્ણ રીતે વિલીન થઈ જાય છે. 4.23",

    "બ્રહ્મયજ્ઞ કે જેમાં અર્પણ એટલે કે સ્રુવા આદિ ઉપકરણ પણ બ્રહ્મ છે, હવન કરવા માટેનું દ્રવ્ય પણ બ્રહ્મ છે, બ્રહ્મરૂપી હોતા દ્વારા બ્રહ્મરૂપી અગ્નિમાં હોમવારૂપી ક્રિયા પણ બ્રહ્મ છે અને સર્વત્ર બ્રહ્મબુદ્ધિ કરવારૂપી બ્રહ્મકર્મમાં સ્થિત રહેનાર યોગીને મળનારું ફળ પણ બ્રહ્મ જ છે. 4.24",

    "બીજા કેટલાક યોગીજનો દેવતાઓની પૂજારૂપી યજ્ઞનું સમ્યક્ પ્રકારે અનુષ્ઠાન કરે છે, જ્યારે અન્ય યોગીજનો પરબ્રહ્મ પરમાત્મારૂપી અગ્નિમાં અભેદદર્શનરૂપી યજ્ઞ દ્વારા જ આત્મારૂપી યજ્ઞનો હોમ કરે છે. 4.25",

    "અન્ય કેટલાક યોગીઓ શ્રોત્ર વગેરે સઘળી ઇન્દ્રિયોને સંયમરૂપી અગ્નિઓમાં હોમે છે, તો વળી બીજા કેટલાક યોગીજનો શબ્દ વગેરે સર્વ વિષયોને ઈન્દ્રિયોરુપી અગ્નિઓમાં હોમતા રહે છે. 4.26",

    "અન્ય યોગીજનો ઇન્દ્રિયોની સર્વ ક્રિયાઓને તથા પ્રાણોની સમસ્ત ક્રિયાઓને જ્ઞાન વડે પ્રજ્વલિત આત્મસંયમયોગરૂપી અગ્નિમાં હોમી દે છે. 4.27",

    "અન્ય કેટલાક માણસો દ્રવ્યસંબંધી યજ્ઞ કરનારા છે, કેટલાક તપરૂપી યજ્ઞ કરનારા છે, બીજા કેટલાક પુરુષો અષ્ટાંગ યોગરૂપી યજ્ઞ કરનારા છે અને વળી કેટલાક અહિંસા આદિ લોકોત્તર વ્રતો પાળનારા પ્રયત્નશીલ માણસો સ્વાધ્યાયરૂપી જ્ઞાનયજ્ઞ કરનારા છે. 4.28",

    "બીજા કેટલાય યોગીજનો અપાનવાયુમાં પ્રાણવાયુને હોમે છે, તેમજ અન્ય યોગીજનો પ્રાણવાયુમાં અપાનવાયુને હોમે છે, તો અન્ય કેટલાય નિયમ પ્રમાણે આહાર કરનારા પ્રાણાયામપરાયણ માણસો પ્રાણ અને અપાનની ગતિને રોકીને પ્રાણોને પ્રાણોમાં જ હોમે છે; આ સઘળાય સાધકો યજ્ઞો દ્વારા પાપોનો નાશ કરનારા તેમજ યજ્ઞોને જાણનારા છે. 4.29-4.30",

    "બીજા કેટલાય યોગીજનો અપાનવાયુમાં પ્રાણવાયુને હોમે છે, તેમજ અન્ય યોગીજનો પ્રાણવાયુમાં અપાનવાયુને હોમે છે, તો અન્ય કેટલાય નિયમ પ્રમાણે આહાર કરનારા પ્રાણાયામપરાયણ માણસો પ્રાણ અને અપાનની ગતિને રોકીને પ્રાણોને પ્રાણોમાં જ હોમે છે; આ સઘળાય સાધકો યજ્ઞો દ્વારા પાપોનો નાશ કરનારા તેમજ યજ્ઞોને જાણનારા છે. 4.29-4.30",

    "હે કુરુશ્રેષ્ઠ! યજ્ઞમાંથી બચેલા અમૃતનો અનુભવ કરનારા યોગીજનો સનાતન પરબ્રહ્મ પરમાત્માને પામે છે, જયારે યજ્ઞ ન કરનાર માણસ માટે તો આ મનુષ્ય-લોક પણ સુખદાયક નથી, તો પરલોક ક્યાંથી સુખદાયક હોય? 4.31",

    "આવા બીજા પણ અનેક જાતના યજ્ઞો વેદની વાણીમાં વિસ્તારથી કહેવાયેલા છે, એ સર્વેને તું મન, ઇન્દ્રિયો અને શરીરની ક્રિયા દ્વારા સંપન્ન થનારા જાણ; આ પ્રમાણે તત્ત્વથી જાણીને એમના અનુષ્ઠાન દ્વારા તું કર્મબંધનથી પૂર્ણ રીતે છૂટી જઈશ. 4.32",

    "હે પરંતપ! દ્રવ્યમય યજ્ઞની અપેક્ષાએ જ્ઞાનયજ્ઞ ઘણો ચઢિયાતો છે તથા હે પાર્થ! સઘળાં કર્મો જ્ઞાનમાં સમાપ્તિને પામે છે. 4.33",

    "એ જ્ઞાનને તું તત્ત્વદર્શી જ્ઞાનીજનો પાસે જઈને જાણી લે; એમને યોગ્ય રીતે દંડવત્ પ્રણામ કરવાથી, એમની સેવા કરવાથી તેમજ કપટ છોડીને સરળ ભાવે પ્રશ્ન પૂછવાથી પરમાત્મતત્ત્વને બરાબર ઓળખનારા એ જ્ઞાની મહાત્માજનો તને એ તત્ત્વજ્ઞાનનો ઉપદેશ આપશે. 4.34",

    "જેને જાણ્યા પછી ફરીથી તું આ રીતે મોહને નહીં પામે, તેમજ હે પાંડુપુત્ર! જે જ્ઞાનથી તું સમગ્ર ભૂતોને નિ:શેષભાવે પહેલાં પોતાનામાં અને પછી મુજ સચ્ચિદાનંદઘન પરમાત્મામાં જોઈશ. 4.35",

    "જો તું બીજા સમસ્ત પાપીઓ કરતાં પણ વધારે પાપ કરનાર હોય, તો પણ તું જ્ઞાનરૂપી નાવડા દ્વારા નિ:સંદેહ આખા પાપ સાગરને સારી રીતે પાર કરી જઈશ. 4.36",

    "કેમકે હે અર્જુન! જે રીતે ભડભડતો અગ્નિ ઘણાંબધાં ઇંધણોને ભસ્મીભૂત કરી નાખે છે, તે જ રીતે જ્ઞાનરૂપી અગ્નિ સમગ્ર કર્મોને ભસ્મીભૂત કરી નાખે છે. 4.37",

    "માટે આ સંસારમાં જ્ઞાનના જેવું પવિત્ર કરનારું નિઃસંદેહ બીજું કશું જ નથી; એ જ્ઞાનને ઘણા સમય સુધી કર્મયોગના આચરણ દ્વારા શુદ્ધાન્તઃકરણ થયેલો માણસ આપમેળે જ આત્મામાં પામે છે. 4.38",

    "જિતેન્દ્રિય, સાધનપરાયણ અને શ્રદ્ધાવાન માણસ જ્ઞાનને પામી જાય છે અને જ્ઞાનને પામીને એ વિના વિલંબે તત્કાળ જ ભગવત્પ્રાપ્તિરૂપી પરમ શાન્તિને પ્રાપ્ત થઈ જાય છે. 4.39",

    "પણ વિવેકહીન, અશ્રદ્ધાળુ તેમજ સંશયગ્રસ્ત માણસ પરમાર્થથી નિશ્ચિતપણે ભ્રષ્ટ થઈ જાય છે; આવા સંશયગ્રસ્ત મનુષ્ય માટે ન આ લોક છે, ન તો પરલોક કે ન સુખ. 4.40",

    "હે ધનંજય! જેણે કર્મયોગના આચરણ દ્વારા સમગ્ર કર્મો પરમાત્મામાં અર્પી દીધાં છે તથા જેણે વિવેક વડે સકળ સંશયોનો નાશ કરી દીધો છે એવા વશ કરેલ અન્તઃકરણના પુરુષને કર્મો નથી બાંધતાં. 4.41",

    "માટે હે ભરતવંશી! તું હૃદયમાં રહેલા આ અજ્ઞાનજનિત પોતાના સંશયને વિવેકજ્ઞાનરૂપી તલવાર વડે છેદીને સમત્વરૂપ કર્મયોગમાં સ્થિત થઈ જા અને યુદ્ધ માટે ઊભો થઈ જા. 4.42",

    "આ રીતે ૐ, તત્‌, સત્‌-એ ભગવાનના નામોના ઉચ્ચારણ સાથે બ્રહ્મવિધા અને યોગશાસ્ત્રમય શ્રીમદ્ભગવદ્‌ગીતોપનિષદરૂપી શ્રીકૃષ્ણાર્જુનસંવાદમાં \"જ્ઞાનકર્મસંન્યાસયોગ\" નામનો ચોથો અધ્યાય પૂરો થયો. ॥૪॥"
  ],
  "adhyaya-5" : [
    "અર્જુન બોલ્યા : હે કૃષ્ણ! તમે એક બાજુ કર્મોના સંન્યાસની અને વળી બીજી બાજુ કર્મયોગની પ્રશંસા કરો છો, તો આ બન્નેમાંથી જે એક મારા માટે ચોક્કસપણે કલ્યાણકારી સાધન હોય, તે કહો. 5.1",

    "શ્રીભગવાન બોલ્યા : કર્મસંન્યાસ અને કર્મયોગ આ બેય પરમ કલ્યાણ કરનારા છે; પરંતુ એ બન્નેમાં કર્મસંન્યાસ કરતાં કર્મયોગ સાધનામાં સુગમ હોવાથી શ્રેષ્ઠ છે. 5.2",

    "હે મહાબાહો! જે માણસ ન તો કોઈનોય દ્વેષ કરે છે કે ન તો કશાયની આકાંક્ષા કરે છે, તે કર્મયોગી સદા સંન્યાસી જ સમજવા યોગ્ય છે; કેમકે રાગ-દ્વેષ વગેરે દ્વન્દ્વો વિનાનો માણસ સુખપૂર્વક સંસારના બંધનમાંથી મુક્ત થઈ જાય છે. 5.3",

    "ઉપર કહેલા સંન્યાસ અને કર્મયોગને બાળકબુદ્ધિના માણસો અલગ-અલગ ફળ આપનાર કહે છે, ડાહ્યા માણસો નહીં; કેમકે બન્નેમાંથી એકમાં પણ સમ્યક્ રીતે સ્થિત માણસ બેયના ફળસ્વરૂપ પરમાત્માને પામે છે. 5.4",

    "જ્ઞાનયોગીઓ વડે જે પરમધામ પ્રાપ્ત કરાય છે, કર્મયોગીઓ વડે પણ એ જ પ્રાપ્ત કરવામાં આવે છે; આથી જે માણસ જ્ઞાનયોગ અને કર્મયોગને ફળરૂપે એક સમાન જુએ છે, તે જ યથાર્થ જુએ છે. 5.5",

    "તેમ છતાં પણ હે મહાબાહો! કર્મયોગ વિના સંન્યાસ (મન, ઈન્દ્રિય અને શરીર દ્વારા થતાં સમસ્ત કર્મોમાં કર્તાપણાના ભાવનો ત્યાગ) પ્રાપ્ત થવો મુશ્કેલ છે; જ્યારે ભગવત્સ્વરૂપનું મનન કરનાર કર્મયોગી પરબ્રહ્મ પરમાત્માને વિના વિલંબે પામી જાય છે. 5.6",

    "જેનું મન પોતાને વશ છે, જે જિતેન્દ્રિય છે, જેનું અંતઃકરણ વિશુદ્ધ છે તથા સઘળા પ્રાણીઓના આત્માસ્વરૂપ પરમાત્મા જ જેનો આત્મા છે, એવો કર્મયોગી કર્મ કરતો હોવા છતાં પણ તેનાથી લિપ્ત થતો નથી. 5.7",

    "તત્ત્વને જાણનારો સાંખ્યયોગી તો જોતો, સાંભળતો, સ્પર્શ કરતો, સૂંઘતો, જમતો, ચાલતો, ઊંઘતો, શ્વાસ લેતો, બોલતો, ત્યાગતો, ગ્રહણ કરતો, તેમજ આંખો ઉઘાડતો મીચતો હોવા છતાં પણ, ‘સર્વ ઇન્દ્રિયો પોતપોતાના અર્થમાં એટલે કે વિષયોમાં વર્તી રહી છે' એમ સમજીને નિઃશંકપણે એમ માને કે હું કશું જ નથી કરી રહ્યો. 5.8-5.9",

    "તત્ત્વને જાણનારો સાંખ્યયોગી તો જોતો, સાંભળતો, સ્પર્શ કરતો, સૂંઘતો, જમતો, ચાલતો, ઊંઘતો, શ્વાસ લેતો, બોલતો, ત્યાગતો, ગ્રહણ કરતો, તેમજ આંખો ઉઘાડતો મીચતો હોવા છતાં પણ, ‘સર્વ ઇન્દ્રિયો પોતપોતાના અર્થમાં એટલે કે વિષયોમાં વર્તી રહી છે' એમ સમજીને નિઃશંકપણે એમ માને કે હું કશું જ નથી કરી રહ્યો. 5.8-5.9",

    "જે માણસ બધાં કર્મોને પરમાત્મામાં અર્પીને તેમજ આસક્તિને છોડીને કર્મ કરે છે, એ માણસ જળથી કમળનાં પાંદડાંની પેઠે પાપથી લિપ્ત થતો નથી. 5.10",

    "કર્મયોગીઓ મમત્વભાવ રાખ્યા વિનાનાં કેવળ ઇન્દ્રિય, મન, બુદ્ધિ તેમજ શરીર દ્વારા થનારી સઘળી ક્રિયાઓમાં આસક્તિ ત્યજીને અન્તઃકરણની શુદ્ધિ માટે કર્મ કરે છે. 5.11",

    "કર્મયોગી કર્મોના ફળને ત્યજીને ભગવત્પ્રાપ્તિસ્વરૂપ શાંતિ પ્રાપ્ત કરે છે, જ્યારે સકામ માણસ કામનાની પ્રેરણાથી ફળમાં આસક્ત થઈને બંધાય છે. 5.12",

    "અન્તઃકરણ જેના વશમાં છે, એવો સાંખ્યયોગનું આચરણ કરનાર મનુષ્ય કશું ન કરતો કે ન કરાવતો રહીને જ નવ દ્વારોના શરીરરૂપી ઘરમાં સઘળાંય કર્મોને મનથી ત્યજીને આનંદપૂર્વક સચ્ચિદાનંદઘન પરમાત્માના સ્વરૂપમાં સ્થિત રહે છે. 5.13",

    "પરમેશ્વર માણસોના ન તો કર્તાપણાને, ન કર્મોને કે ન તો કર્મફળના સંયોગને સર્જે છે; પણ સ્વભાવ અર્થાત્ ત્રિગુણમયી પ્રકૃતિ જ પ્રવર્તે છે. 5.14",

    "સર્વવ્યાપી પરમેશ્વર ન તો કોઈના પાપકર્મ ગ્રહણ કરે છે કે ન કોઈના શુભકર્મને; પરંતુ અજ્ઞાન વડે જ્ઞાન ઢંકાયેલું છે, એનાથી જ બધાં અજ્ઞાની માણસો મોહ પામે છે. 5.15",

    "પણ જેમનું એ અજ્ઞાન પરમાત્માના તત્ત્વજ્ઞાન વડે નષ્ટ કરી નાખવામાં આવ્યું છે, એમનું એ જ્ઞાન સૂર્યની જેમ એ સચ્ચિદાનંદઘન પરમાત્માને પ્રકાશિત કરી દે છે, એટલે કે પરમાત્માના સ્વરૂપનો સાક્ષાત્કાર કરાવે છે. 5.16",

    "પરમાત્મામાં જેમનું મન તદ્રુપ થઈ રહ્યું છે, પરમાત્મામાં જેમની બુદ્ધિ તદ્રુપ થઈ રહી છે અને સચ્ચિદાનંદઘન પરમાત્મામાં જ જેમની નિરંતર એકાત્મભાવે સ્થિતિ છે, એવા પરમાત્મપરાયણ મનુષ્યો જ્ઞાન દ્વારા પાપરહિત થઈને અપુનરાવૃત્તિને એટલે કે પરમગતિને પામે છે. 5.17",

    "એવા જ્ઞાનીજનો વિધા અને વિનયશીલ બ્રાહ્મણમાં તથા ગાય, હાથી, કૂતરા અને ચંડાળમાં પણ સમાન દ્રષ્ટિવાળા જ હોય છે. 5.18",

    "જેમનું મન સમભાવમાં સ્થિત છે, તેમણે આ જીવંત અવસ્થામાં જ સકળ સંસાર જીતી લીધો છે; કેમકે સચ્ચિદાનંદઘન પરમાત્મા નિર્દોષ અને સમ છે, માટે તેઓ સચ્ચિદાનંદઘન પરમાત્મામાં જ સ્થિત છે. 5.19",

    "જે માણસ પ્રિયને પામીને હરખાય નહીં તેમજ અપ્રિયને પામીને અકળાય નહીં, એ સ્થિરબુદ્ધિનો અને સંશય વિનાનો બ્રહ્મવેત્તા મનુષ્ય સચ્ચિદાનંદઘન પરબ્રહ્મ પરમાત્મામાં એકાત્મભાવે કાયમ સ્થિત છે. 5.20",

    "બહારના વિષયોમાં આસક્તિ વિનાના અંતઃકરણવાળો સાધક આત્મામાં સ્થિત જે ધ્યાનજનિત સાત્ત્વિક આનંદ છે, એને પામે છે; પછી એ સચ્ચિદાનંદઘન પરબ્રહ્મ પરમાત્માના ધ્યાનસ્વરૂપ યોગમાં અભિન્નભાવે સ્થિત માણસ અક્ષય આનંદને અનુભવે છે. 5.21",

    "જે આ ઇન્દ્રિય અને વિષયોના સંયોગથી ઉત્પન્ન થતા સર્વ ભોગો છે, તે બધા - જો કે વિષયી માણસોને સુખસ્વરૂપ ભાસે છે, તો પણ - નિઃશંકપણે દુઃખના જ હેતુ છે; વળી આદિ-અંતવાળા એટલે કે અનિત્ય છે, માટે હે કૌન્તેય! ડાહ્યો અને વિવેકી માણસ એમનામાં આસક્ત રહેતો નથી. 5.22",

    "જે સાધક આ મનુષ્યશરીરમાં, દેહનો નાશ થયા પહેલાં જ કામ-ક્રોધમાંથી ઉદ્ભવતા વેગને સહેવા સમર્થ બની જાય છે, એ જ માણસ યોગી છે અને એ જ સુખી છે 5.23",

    "જે માણસ અંતરાત્મામાં જ આનંદ અનુભવનારો છે, આત્મામાં જ રમનારો છે અને જે આત્મામાં જ જ્ઞાનસ્વરૂપ પરમાત્માનો અનુભવ કરનારો છે, એ સચ્ચિદાનંદઘન પરબ્રહ્મ પરમાત્માની સાથે ઐક્ય પામેલો સાંખ્યયોગી શાન્ત બ્રહ્મને પામે છે. 5.24",

    "જેમનાં સઘળાં પાપ નષ્ટ થઈ ચૂક્યાં છે, જેના સઘળા સંશયો જ્ઞાન દ્વારા નિવૃત થઈ ચુક્યા છે, જેઓ સર્વ પ્રાણીઓના હિતમાં રત છે તેમજ જેમનું જિતાયેલું મન નિશ્ચળભાવે પરમાત્મામાં સ્થિત છે. એવા બ્રહ્મવેત્તા માણસો શાન્ત બ્રહ્મને પામે છે. 5.25",

    "કામ-ક્રોધ વિનાના, ચિત્તને જીતેલા, પરબ્રહ્મ પરમાત્માનો સાક્ષાત્કાર કરી ચુકેલા જ્ઞાનીજનો માટે ચારેકોર શાન્ત પરબ્રહ્મ પરમાત્મા જ પરિપૂર્ણ છે. 5.26",

    "બહારના વિષયભોગોનું ચિંતન કર્યા વિના તેમને બહાર જ ત્યજીને અને આંખોની દૃષ્ટિને ભમ્મરોની મધ્યમાં સ્થાપીને તથા નાસિકામાં વિચરતા પ્રાણ અને અપાન વાયુને સમ કરીને જેણે ઇન્દ્રિયો, મન અને બુદ્ધિને જીત્યાં છે, એવો જે મોક્ષપરાયણ મુનિ ઇચ્છા, ભય અને ક્રોધ વિનાનો થઈ ગયો છે, તે સદા મુક્ત જ છે. 5.27-5.28",

    "બહારના વિષયભોગોનું ચિંતન કર્યા વિના તેમને બહાર જ ત્યજીને અને આંખોની દૃષ્ટિને ભમ્મરોની મધ્યમાં સ્થાપીને તથા નાસિકામાં વિચરતા પ્રાણ અને અપાન વાયુને સમ કરીને જેણે ઇન્દ્રિયો, મન અને બુદ્ધિને જીત્યાં છે, એવો જે મોક્ષપરાયણ મુનિ ઇચ્છા, ભય અને ક્રોધ વિનાનો થઈ ગયો છે, તે સદા મુક્ત જ છે. 5.27-5.28",

    "મારો ભક્ત મને સર્વ યજ્ઞો અને તપોનો ભોગવનાર, સકળ લોકોના ઈશ્વરોનોય ઈશ્વર તેમજ સઘળાં પ્રાણીઓનો સુહૃદ્ એટલે કે સ્વાર્થ વિના દયાળુ અને પ્રેમ કરનાર એવો તત્ત્વથી જાણીને શાન્તિને પામે છે. 5.29",

    "આ રીતે ૐ, તત્‌, સત્‌-એ ભગવાનના નામોના ઉચ્ચારણ સાથે બ્રહ્મવિધા અને યોગશાસ્ત્રમય શ્રીમદ્ભગવદ્‌ગીતોપનિષદરૂપી શ્રીકૃષ્ણાર્જુનસંવાદમાં \"કર્મસંન્યાસયોગ\" નામનો પાંચમો અધ્યાય પૂરો થયો. ॥૫॥"
  ],
  "adhyaya-6" : [
    "શ્રીભગવાન બોલ્યા : જે માણસ કર્મફળનો આશ્રય લીધા વિના કરવા યોગ્ય કર્મ (કર્તવ્ય-કર્મ) આચરે છે, તે સંન્યાસી તેમજ યોગી છે; જ્યારે કેવળ અગ્નિનો ત્યાગ કરનારો સંન્યાસી નથી અને માત્ર ક્રિયાઓનો ત્યાગ કરનારો યોગી નથી. 6.1",

    "હે પાંડવ! જેને ‘સંન્યાસ' કહે છે, એને જ તું યોગ જાણ; કેમકે સંકલ્પોનો ત્યાગ ન કરનાર કોઈ પણ માણસ યોગી નથી હોતો. 6.2",

    "યોગમાં આરૂઢ થવા ઇચ્છુક મનનશીલ માણસ માટે નિષ્કામભાવે કર્મ કરવું એ જ યોગની પ્રાપ્તિમાં હેતુ કહેવાય છે અને યોગારૂઢ થયા બાદ એ યોગારૂઢ માણસનો જે સમસ્ત સંકલ્પોનો અભાવ છે , એ જ કલ્યાણમાં હેતુ કહેવાય છે. 6.3",

    "જે વખતે તો ઇન્દ્રિયોના ભોગોમાં આસક્ત થતો કે નથી કર્મોમાંય આસક્ત થતો, તે વખતે સઘળા સંકલ્પોનો ત્યાગી માણસ યોગારૂઢ કહેવાય છે. 6.4",

    "પોતાના વડે પોતાનો સંસાર-સાગરથી ઉદ્ઘાર કરે અને પોતાને અધોગતિમાં ન નાખે; કારણ કે માણસ જ પોતે જ તો પોતાનો મિત્ર છે અને પોતે પોતાનો શત્રુ છે. 6.5",

    "જે જીવાત્મા દ્વારા મન અને ઈન્દ્રિયો સહિત શરીર જીતવામાં આવ્યું છે, એ જીવાત્માનો એ પોતે જ મિત્ર છે; અને જેના વડે મન તથા ઇન્દ્રિયો સહિત શરીર નથી જીતવામાં આવ્યું, એના પ્રત્યે એ પોતે જ શત્રુની જેમ શત્રુતામાં વર્તે છે. 6.6",

    "ઠંડી-ગરમીમાં, સુખ-દુઃખાદિમાં તથા માન તેમજ અપમાનમાં જેના અન્તઃકરણની વૃત્તિઓ સમ્યક્ રીતે શાંત છે. એવા સ્વાધીન આત્માવાળા મનુષ્યના જ્ઞાનમાં સચ્ચિદાનંદઘન પરમાત્મા સમ્યક્ પ્રકારે સ્થિત છે એટલે કે એના જ્ઞાનમાં પરમાત્મા સિવાય બીજું કશું છે જ નહીં. 6.7",

    "જેનું અંતઃકરણ જ્ઞાન અને વિજ્ઞાનથી તૃપ્ત છે, જેની સ્થિતિ વિકારરહિત છે, જેની ઇન્દ્રિયો સારી પેઠે જિતાયેલી છે તેમજ જેના માટે માટી, પથ્થર અને સુવર્ણ સમાન છે, એ યોગી યુક્ત અર્થાત્ ભગવત્પ્રાપ્ત કહેવાય છે. 6.8",

    "સુહૃદ્, મિત્ર, શત્રુ ઉદાસીન, મધ્યસ્થ, દ્વેષ્ય તેમજ બંધુગુણોમાં, ધર્માત્માઓમાં અને પાપીઓમાં પણ સમાન ભાવ રાખનાર ઘણો શ્રેષ્ઠ છે. 6.9",

    "મન ઇન્દ્રિયોસહિત શરીરને વશમાં રાખનાર, કશાયની ખેવના ન રાખનાર અને સંગ્રહરહિત યોગી એકલો જ એકાંત સ્થળે સ્થિત થઈને આત્માને નિરંતર પરમાત્મામાં જોડે. 6.10",

    "પવિત્ર ભૂમિ પર કે જેના ઉપર ક્રમ પ્રમાણે દર્ભ, મૃગચર્મ અને વસ્ત્ર પાથરેલા છે, તેમજ ન ઘણા ઊંચા અને ન ઘણા નીચા એવા પોતાના આસનને સ્થિરરૂપે સ્થાપીને- 6.11",

    "એ આસન પર બેસીને ચિત્ત અને ઈન્દ્રિયોની ક્રિયાઓને વશમાં રાખી, મનને એકાગ્ર કરીને, અન્તઃકરણની શુદ્ધિ માટે યોગનો અભ્યાસ કરે. 6.12",

    "કાયા, મસ્તક અને ડોકને સીધાં તેમજ ટટ્ટાર ધારણ કરીને અને સ્થિર થઈ પોતાની નાસિકાના અગ્રભાગે દૃષ્ટિ ટેકવીને અન્ય દિશાઓને ન જોતો - 6.13",

    "બ્રહ્મચારીના વ્રતમાં સ્થિત, નિર્ભય તથા સમ્યક્ રીતે શાન્ત અન્તઃકરણનો સાવધાન યોગી મનને સંયત કરી મારામાં ચિત્ત જોડી અને મારા પરાયણ થઈને ધ્યાનમાં બેસે. 6.14",

    "વશ કરેલા મનનો યોગી આ રીતે આત્માને નિરંતર મુજ પરમેશ્વરના સ્વરૂપમાં જોડતો મારામાં રહેલી પરમ આનંદની પરાકાષ્ઠાસ્વરૂપ શાન્તિને પામે છે. 6.15",

    "હે અર્જુન! આ યોગ ખૂબ ખાનારનો સિદ્ધ થતો નથી તેમજ બિલકુલ ન ખાનારનો પણ સિદ્ધ થતો નથી તથા ઘણું ઊંઘનારનો સિદ્ધ થતો નથી તેમજ હંમેશાં જાગનારનોય સિદ્ધ સિદ્ધ થતો નથી. 6.16",

    "દુઃખોનો નાશ કરનાર યોગ તો યથાયોગ્ય આહાર-વિહાર કરનારનો, કર્મોમાં યથાયોગ્ય ચેષ્ટા કરનારનો તથા યથાયોગ્ય ઊંઘનાર તેમજ જાગનારનો જ સિદ્ધ થાય છે. 6.17",

    "સંપૂર્ણ વશમાં કરેલું ચિત્ત જે વખતે પરમાત્મામાં જ સમ્યક્ રીતે સ્થિત થઈ જાય છે, એ વખતે સમસ્ત ભોગોથી નિઃસ્પૃહ થયેલો માણસ યોગયુક્ત કહેવાય છે. 6.18",

    "જે રીતે વાયુ વિનાના સ્થાનમાં રહેલી દીવાની જ્યોત ડોલતી નથી, એવી જ ઉપમા પરમાત્માના ધ્યાનમાં લાગેલા યોગીના જિતાયેલા ચિત્તની કહેવાઈ છે. 6.19",

    "યોગના અભ્યાસથી નિરુદ્ધ થયેલું ચિત્ત જે અવસ્થામાં ઉપરતિ પામે છે અને જે અવસ્થામાં પરમાત્માના ધ્યાનને લીધે શુદ્ધ થયેલી સૂક્ષ્મ બુદ્ધિ દ્વારા પરમાત્માનો સાક્ષાત્કાર કરતો સચ્ચિદાનંદઘન પરમાત્મામાં જ સંતુષ્ટ રહે છે - 6.20",

    "ઇન્દ્રિયોથી અતીત, માત્ર શુદ્ધ થયેલી સૂક્ષ્મ બુદ્ધિ દ્વારા ગ્રહણ થઈ શકે એવો જે અનંત આનંદ છે; એને જે અવસ્થામાં અનુભવે છે અને જે અવસ્થામાં સ્થિત આ યોગી પરમાત્માના સ્વરૂપથી કદીય વિચલિત થતો જ નથી 6.21",

    "પરમાત્માની પ્રાપ્તિરૂપી લાભને પામીને એનાથી વધુ બીજા કશા લાભને નથી માનતો અને પરમાત્મપ્રાપ્તિસ્વરૂપ જે અવસ્થામાં રહેલો યોગી ઘણા ભારે દુઃખથી પણ વિચલિત નથી થતો- 6.22",

    "જન્મ-મરણના દુ:ખરૂપ સંસાર સાથેના સંયોગથી જે રહિત છે, તથા જેનું નામ યોગ છે, એને જાણવો જોઈએ ; એ યોગ કંટાળ્યા વગર અર્થાત્ ધૈર્યશીલ અને ઉત્સાહી ચિત્તથી નિશ્ચયપૂર્વક કરવો જોઈએ. 6.23",

    "સંકલ્પથી ઉત્પન્ન થતી સઘળી કામનાઓને સમૂળી ત્યજીને, તેમજ મન વડે ઇન્દ્રિયોના સમુદાયને બધી બાજુથી સમ્યક રીતે રોકીને - 6.24",

    "ક્રમે-ક્રમે અભ્યાસ કરતો ઉપરતિને પામે તથા ધૈર્યશીલ બુદ્ધિ દ્વારા મનને પરમાત્મામાં સ્થિત કરીને પરમાત્મા સિવાય બીજા કશાયનું ચિંતન ન કરે. 6.25",

    "આ સ્થિર ન રહેનારું અને ચંચળ મન જે-જે શબ્દાદિ વિષયના નિમિત્તે સંસારમાં વિચરે છે, તે - તે વિષયમાંથી રોકીને એને વારંવાર પરમાત્મામાં જ નિરુદ્ધ કરવું. 6.26",

    "કેમકે જેનું મન સમ્યક્ રીતે શાન્ત છે, જે નિષ્પાપ છે અને જેનો રજોગુણ શાંત થઈ ચુક્યો છે, એવા સચ્ચિદાનંદઘન બ્રહ્મની સાથે એકાત્મતાને પામેલા આ યોગીને ઉત્તમ આનંદ પ્રાપ્ત થાય છે. 6.27",

    "આ પાપ વિનાનો યોગી આ પ્રમાણે નિરંતર આત્માને પરમાત્મામાં જોડતો સુખેથી પરબ્રહ્મ પરમાત્માની પ્રાપ્તિસ્વરૂપ અનંત આનંદનો અનુભવ કરે છે. 6.28",

    "સર્વવ્યાપી અનંત ચેતનમાં એકાત્મભાવે રહેવારૂપી યોગથી યુક્ત આત્મવાન તેમજ સૌમાં સમભાવે જોનાર યોગી આત્માને સર્વ ભૂતોમાં રહેલો અને સર્વ ભૂતોને આત્મામાં કલ્પિત જુએ છે. 6.29",

    "જે માણસ સઘળાં ભૂતોમાં સહુના આત્મારૂપ મુજ વાસુદેવને જ વ્યાપેલ જુએ છે અને સઘળાં ભૂતોને મુજ વાસુદેવની અન્તર્ગત જુએ છે, એને માટે હું અદૃશ્ય નથી હોતો અને એ મારે માટે અદૃશ્ય નથી હોતો. 6.30",

    "જે માણસ એકાત્મભાવમાં સ્થિત થઈને સર્વ ભૂતોમાં આત્મારૂપે રહેલા મુજ સચ્ચિદાનંદઘન વાસુદેવને ભજે છે, એ યોગી સર્વ રીતે વર્તવા છતાં મારામાં જ વર્તે છે. 6.31",

    "હે અર્જુન! જે યોગી પોતાની જેમ સઘળાં ભૂતોમાં સમ જુએ છે, તેમજ સુખ અથવા દુ:ખનેય બધામાં સમ જુએ છે, એ યોગી પરમ શ્રેષ્ઠ મનાયો છે. 6.32",

    "અર્જુન બોલ્યા : હે મધુસૂદન! જે આ યોગ આપે મને સમભાવે કહ્યો, મન ચંચળ હોવાને લીધે હું આની નિત્ય સ્થિતિને નથી જોતો. 6.33",

    "કેમકે હે શ્રીકૃષ્ણ! મન ઘણું ચંચળ, પ્રમથન સ્વભાવનું અત્યંત દ્રઢ અને બળવાન છે; આથી એને વશમાં કરવાનું હું વાયુને રોકવાની પેઠે ઘણું દુષ્કર માનું છું. 6.34",

    "શ્રીભગવાન બોલ્યા : હે મહાબહો! નિ:સંદેહ મન ચંચળ તેમજ મુશ્કેલીથી વશ થનારું છે, છતાં પણ હે કુંતી પુત્ર! એ અભ્યાસ અને વૈરાગ્ય વડે વશમાં થાય છે. 6.35",

    "જેનું મન વશમાં કરાયેલું નથી એવા માણસ દ્વારા યોગ દુષ્પ્રાપ્ય એટલેકે પ્રાપ્ત થવો મુશ્કેલ છે અને જેણે મનને વશ કર્યું છે એવા પ્રયત્નશીલ માણસ દ્વારા સાધન વડે એ પ્રાપ્ત થવો સહજ છે - આ મારો મત છે. 6.36",

    "અર્જુન બોલ્યા : હે શ્રી કૃષ્ણ! જે યોગમાં શ્રદ્ધા તો ધરાવે છે, પરંતુ સંયમી નથી; એ કારણે અંતકાળે જેનું મન યોગથી વિચલિત થઈ ગયું છે, એવો સાધક યોગી યોગની સિદ્ધિને એટલે કે ભગવત્સાક્ષાત્કારને ન પામતાં કઈ ગતિને પામે છે? 6.37",

    "હે મહાબહો! શુ એ ભગવત્પાપ્તિના માર્ગમાં મોહ પામેલો અને આશ્રય વિનાનો માણસ છિન્નભિન્ન થયેલા વાદળની પેઠે બેય બાજુથી ભ્રષ્ટ થઈને નાશ તો નથી પામતો ને? 6.38",

    "હે શ્રીકૃષ્ણ! મારા આ સંશયને સમૂળો છેદવા માટે આપ જ લાયક છો; કેમકે આપના વિના બીજો આ સંશયનું છેદન કરનાર મળવો સંભવિત નથી. 6.39",

    "શ્રીભગવાન બોલ્યા : હે પાર્થ! એ માણસનો ન તો આ લોકમાં વિનાશ થાય છે કે ન પરલોકમાં; કેમકે હે વહાલા! આત્મોદ્વાર માટે અર્થાત્ ભગવત્પ્રાપ્તિનો અર્થ કર્મ કરનાર કોઈ પણ મનુષ્ય દુર્ગતિને પામતો નથી. 6.40",

    "યોગભ્રષ્ટ માણસ પુણ્યશાળીઓ ના લોકોને એટલે કે સ્વર્ગ વગેરે ઉત્તમ લોકોને પામીને ત્યાં ઘણા વર્ષો સુધી નિવાસ કર્યા પછી શુદ્ધ આચરણશીલ શીલ ધનવાનોના ઘરે જન્મ લે છે. 6.41",

    "અથવા આ સિવાયના જે વૈરાગ્યવાન યોગભ્રષ્ટ હોય છે, એવા માણસો એ લોકોમાં ન જતા જ્ઞાનવાન યોગીઓના જ કુળમાં જન્મે છે, પરંતુ આ પ્રકારનો જે આ જન્મ છે, એ સંસારમાં ખરેખર ઘણો દુર્લભ છે. 6.42",

    "ત્યાં તે પૂર્વજન્મના શરીરમાં સંઘરેલા બુદ્ધિસંયોગને એટલે કે સમૃદ્ધિસ્વરૂપ યોગના સંસ્કારોને અનાયાસે જ પામે છે અને હે કુરુનંદન ! એ પ્રભાવને લીધે એ ફરીથી પરમાત્માની પ્રાપ્તિરૂપી સિદ્ધ માટે પહેલાં કરતાં પણ વધુ પ્રયત્ન કરે છે. 6.43",

    "તથા જે શ્રીમંતોના ઘરે જન્મ લેનાર યોગભ્રષ્ટ છે તે પરાધીન હોવા છતાં એ પૂર્વજન્મના અભ્યાસના બળે જ નિશંકપણે ભગવાન પ્રત્યે આકર્ષાય છે અને સમબુદ્ધિસ્વરૂપ યોગનો જિજ્ઞાસુ પણ વેદમાં કહેલા સકામ કર્મોના ફળને ઓળંગી જાય છે. 6.44",

    "પરંતુ પ્રયત્નપૂર્વક અભ્યાસ કરનાર યોગી તો પાછલા અનેક જન્મોના સંસ્કારબળે આજ જન્મે સંસિદ્ધ થઇ સમગ્ર પાપોથી રહિત થયા બાદ તત્કાળ જ પરમ ગતિને પામે છે. 6.45",

    "યોગી તપસ્વીઓ કરતા શ્રેષ્ઠ છે, શાસ્ત્રજ્ઞોથીય શ્રેષ્ઠ મનાયો છે અને સકામ કર્મ કરનારાઓથીય યોગી શ્રેષ્ઠ છે; માટે હે અર્જુન તું યોગી થા. 6.46",

    "સઘળા યોગીઓમાં પણ જે શ્રદ્ધાવાન યોગી મારામાં જોડાયેલા અંતરાત્માથી મને નિરંતર ભજે છે એ યોગી મને સૌથી શ્રેષ્ઠ માનનીય છે. 6.47",

    "આ રીતે ૐ, તત્‌, સત્‌-એ ભગવાનના નામોના ઉચ્ચારણ સાથે બ્રહ્મવિધા અને યોગશાસ્ત્રમય શ્રીમદ્ભગવદ્‌ગીતોપનિષદરૂપી શ્રીકૃષ્ણાર્જુનસંવાદમાં \"આત્મસંયમયોગ\" નામનો છઠ્ઠો અધ્યાય પૂરો થયો. ॥૬॥"
  ],
  "adhyaya-7" : [
    "શ્રીભગવાન બોલ્યા: હે પાર્થ! અનન્ય પ્રેમથી મારામાં ચિત્તને પરોવનાર તથા અનન્ય ભાવથી મારે પરાયણ થઈને યોગમાં જોડાયેલ તું જે રીતે સંપૂર્ણ વિભૂતિ, બળ, ઐશ્વર્ય આદિ ગુણો સહિત સૌના આત્મારૂપ મને વિના સંશયે જાણીશ, એને સાંભળ. 7.1",

    "હું તારે માટે આ વિજ્ઞાનસહિત તત્ત્વજ્ઞાનને સંપૂર્ણપણે કહીશ, જેને જાણીને સંસારમાં ફરી બીજું કશું પણ જાણવા લાયક શેષ નથી રહેતુ. 7.2",

    "હજારો માણસોમાંથી કોઈ એક મને પામવા માટે પ્રયત્ન કરે છે અને એ પ્રયત્ન કરનાર યોગીઓમાંથી પણ કોઈ એક મારે પરાયણ થઈને મને તત્ત્વથી એટલે કે યથાર્થ રૂપે જાણી શકે છે. 7.3",

    "પૃથ્વી, જળ, અગ્નિ, વાયુ, આકાશ, મન, બુદ્ધિ અને અહંકાર- આ પ્રમાણે આ આઠ પ્રકારે વિભાગ પામેલી મારી પ્રકૃતિ છે. આ આઠ પ્રકારના ભેદની તો અપરા એટલે કે મારી જડ પ્રકૃતિ છે અને હે મહાબાહો! આના સિવાયની શક્તિ છે- જેનાથી આ આખું જગત ધારણ કરાય છે - એને મારી જીવરૂપા પરા એટલે કે ચેતન પ્રકૃતિ ઓળખ. 7.4-7.5",

    "પૃથ્વી, જળ, અગ્નિ, વાયુ, આકાશ, મન, બુદ્ધિ અને અહંકાર- આ પ્રમાણે આ આઠ પ્રકારે વિભાગ પામેલી મારી પ્રકૃતિ છે. આ આઠ પ્રકારના ભેદની તો અપરા એટલે કે મારી જડ પ્રકૃતિ છે અને હે મહાબાહો! આના સિવાયની શક્તિ છે- જેનાથી આ આખું જગત ધારણ કરાય છે - એને મારી જીવરૂપા પરા એટલે કે ચેતન પ્રકૃતિ ઓળખ. 7.4-7.5",

    "હે અર્જુન! તું એમ સમજ કે સઘળા જીવો આ બંને પ્રકૃતિઓથી જ ઉદ્ભવનારા છે અને હું આખાય જગતનો પ્રભવ તેમજ પ્રલય છું એટલે કે સકળ જગતનું મૂળ કારણ છું. 7.6",

    "હે ધનંજય! મારા સિવાય બીજું કોઈપણ પરમ કારણ નથી; આ આખું જગત દોરામાં પરોવાયેલા મણિઓની પેઠે મારામાં ગુંથાયેલું છે. 7.7",

    "હે કૌન્તેય! હું જળમાં રસ છું, ચંદ્ર ને સૂર્યમાં પ્રકાશ છું, બધા વેદોમાં ઓંકાર છું, આકાશમાં શબ્દ અને પુરુષોમાં પુરુષત્વ છું. 7.8",

    "હું પૃથ્વીમાં પવિત્ર ગંધ અને અગ્નિમાં તેજ છું તેમજ સમસ્ત ભૂતોમાં એમનું જીવન છું અને તપસ્વીઓમાં તપ છું. 7.9",

    "હે પાર્થ! તું સર્વ જીવોનું સનાતન બીજ મને જ જાણ; હું બુદ્ધિમાનોની બુદ્ધિ તથા તેજસ્વીઓનું તેજ છું. 7.10",

    "હે ભરતવંશીઓમાં શ્રેષ્ઠ! હું બળવાનોનું આસક્તિ તથા કામનાઓ વિનાનું બળ એટલે કે સામર્થ્ય છું અને સહુ જીવોમાં ધર્મને અનુકૂળ એટલે કે શાસ્ત્રને અનુકૂળ કામ છું. 7.11",

    "અને વળી, જે સત્ત્વગુણમાંથી ઉદ્ભવતા ભાવો છે તેમજ જે રજોગુણથી અને તમોગુણથી થતા ભાવો છે એ બધાને તું મારાથી જ થનારા છે એમ સમજ; એમ હોવા છતાં પણ વાસ્તવમાં તેમનામાં હું કે તેઓ મારામાં નથી. 7.12",

    "ગુણોના કાર્યરૂપ સાત્વિક, રાજસ અને તામસ એવા આ ત્રણેય પ્રકારના ભાવોથી આ આખો પ્રાણીસમુદાય મોહ પામી રહ્યો છે, માટે જ આ ત્રણેય ગુણોથી પર એવા મુજ અવિનાશીને નથી ઓળખતો. 7.13",

    "કેમકે આ અલૌકિક અર્થાત્ ઘણી અદભૂત ત્રિગુણમયી મારી માયા પાર કરવી ઘણી કઠિન છે, પરંતુ જે માણસ માત્ર મને જ નિરંતર ભજે છે, તેઓ આ માયાને પાર કરી જાય છે એટલે કે સંસાર-સાગરને તરી જાય છે. 7.14",

    "માયા વડે જેમનું જ્ઞાન હરાયેલું છે, એવા આસુરી સ્વભાવને ધારણ કરનાર, મનુષ્યોમાં અધમ, દૂષિત કર્મ કરનાર મૂઢજનો મને નથી ભજતા. 7.15",

    "હે ભરતશ્રેષ્ઠ અર્જુન! ઉત્તમ કાર્ય કરનાર અર્થાર્થી, આર્ત, જિજ્ઞાસુ અને જ્ઞાની એવા ચાર પ્રકારના ભક્તજનો મને ભજે છે. 7.16",

    "એમનામાંથી હંમેશા મારામાં એકાત્મભાવે સ્થિત, અનન્ય પ્રેમભક્તિ રાખનાર જ્ઞાની ભક્ત શ્રેષ્ઠ છે; કેમ કે મને તત્ત્વથી ઓળખનાર જ્ઞાનીને હું અત્યંત પ્રિય છું અને એ જ્ઞાની મને અત્યંત પ્રિય છે. 7.17",

    "આ બધાય ભક્તો ઉદાર એટલે કે શ્રેષ્ઠ છે, છતાં એ સર્વેમાં જ્ઞાની તો સાક્ષાત્ મારુ સ્વરૂપ જ છે એવો મારો મત છે, કેમ કે મારામાં જ મન તથા બુદ્ધિ રાખનાર જ્ઞાની ભક્ત ઉત્તમ ગતિસ્વરૂપ એવા મારામાં જ સમ્યક્ રીતે સ્થિત છે. 7.18",

    "ઘણા જન્મ પછીના અંતિમ જન્મમાં તત્ત્વજ્ઞાન પામેલો માણસ 'સર્વ કાંઈ વાસુદેવ જ છે' એવા ભાવે મને ભજે છે; એ મહાત્મા ઘણો દુર્લભ છે. 7.19",

    "અમુક-અમુક ભાગોની કામના વડે જેમનું જ્ઞાન હારી ચૂકયું છે, એ માણસો પોતાના સ્વભાવથી પ્રેરાઈને અમુક-અમુક નિયમને ધારણ કરી અન્ય દેવતાઓને ભજે છે એટલે કે ઉપાસે છે. 7.20",

    "જે-જે સકામ ભક્ત જે-જે દેવતાના સ્વરૂપને શ્રદ્ધાથી પુજવા ઇચ્છે છે તે-તે ભક્તની શ્રદ્ધાને હું એજ દેવતા પ્રત્યે દ્રઢ કરું છું. 7.21",

    "એ માણસ એ શ્રદ્ધાથી યુક્ત થઈ એ દેવતાનું પૂજન કરે છે અને એ દેવતા પાસેથી મારા વડે જ વિધાન કરેલા એ ઇચ્છેલા ભાગોને નિ:સંદેહ પામે છે. 7.22",

    "પરંતુ એ અલ્પબુદ્ધિજનોનું એ ફળ નાશવંત હોય છે તેમજ એ દેવતાઓને પૂજનાર જનો દેવતાઓને પામે છે; જ્યારે મારા ભક્તો ભલે ગમે તેમ ભજે છેવટે તેઓ મને જ પામે છે. 7.23",

    "અજ્ઞાનીઓ મારા સર્વ શ્રેષ્ઠ અવિનાશી પરમ ભાવને નહીં ઓળખીને અવ્યક્ત અર્થાત્ મન-ઇન્દ્રિયોથી પર એવા મુજ સચ્ચિદાનંદઘન પરમાત્માને માણસની પેઠે જન્મ લઈને વ્યક્તિભાવને પામનાર માને છે. 7.24",

    "પોતાની યોગમાયાના વડે ઢંકાયેલો હું સૌને પ્રત્યક્ષ નથી થતો, માટે આ અજ્ઞાની જનસમુદાય મુજ અજન્મા અવિનાશી પરમેશ્વરને નથી ઓળખી શકતો એટલે કે મને જન્મ-મરણશીલ સમજે છે. 7.25",

    "હે અર્જુન! પૂર્વે થઇ ગયેલા, વર્તમાનમાં હયાત તથા ભવિષ્યમાં થનાર સર્વ ભૂતોને હું જાણું છું, પરંતુ મને કોઈપણ શ્રદ્ધા-ભક્તિ વિનાનો માણસ નથી જાણી શકતો. 7.26",

    "હે ભરતવંશી! હે અર્જુન! સંસારમાં ઇચ્છા અને દ્વેષથી ઉદ્ભવેલા સુખ- દુ:ખ આદિ દ્વન્દ્વસ્વરૂપ મોહને લીધે સઘળાં પ્રાણીઓ અત્યંત અજ્ઞાનતાને પામી રહ્યા છે. 7.27",

    "પરંતુ નિષ્કામભાવે ઉત્તમ કર્મોનું આચરણ કરનારા જે માણસોનું પાપ નષ્ટ થઈ ચુક્યું છે, એ રાગદ્વેષજનિત દ્વન્દ્વસ્વરૂપ મોહથી મુક્ત થયેલા દઢનિશ્ચયી ભક્તો મને સર્વ રીતે ભજે છે. 7.28",

    "જેઓ મારે શરણે થઈને જરા અને મરણમાંથી છૂટવા માટે પ્રયત્ન કરે છે એ માણસો એ બ્રહ્મને, સમગ્ર અધ્યાત્મને તેમજ સંપૂર્ણ કર્મને ઓળખી લે છે. 7.29",

    "જે માણસો અધિભૂત અને અધિદૈવ સહિત તેમજ અધિયજ્ઞ સહિત સૌના આત્માસ્વરૂપે મને અંતકાળેય ઓળખી લે છે, એ મારામાં ચિત્ત પરોવેલા માણસો મને જ ઓળખી લે છે એટલે કે પામી જાય છે. 7.30",

    "આ રીતે ૐ, તત્‌, સત્‌-એ ભગવાનના નામોના ઉચ્ચારણ સાથે બ્રહ્મવિધા અને યોગશાસ્ત્રમય શ્રીમદ્ભગવદ્‌ગીતોપનિષદરૂપી શ્રીકૃષ્ણાર્જુનસંવાદમાં \"જ્ઞાનવિજ્ઞાનયોગ\" નામનો સાતમો અધ્યાય પૂરો થયો. ॥૭॥"
  ],
  "adhyaya-8" : [
    "અર્જુન બોલ્યા : હે પુરુષોત્તમ! એ બ્રહ્મ શું છે? અધ્યાત્મ શું છે? કર્મ શું છે? અધિભૂત નામથી શું કહેવાયું છે અને અધિદૈવ કોને કહે છે? 8.1",

    "હે મધુસૂદન! આ પ્રસંગે અધિયજ્ઞ કોણ છે? અને તે આ શરીરમાં કેવી રીતે છે? તથા તમારામાં ચિત્ત પરોવેલા માણસો વડે અન્તકાળે તમે કયા પ્રકારે ઓળખી શકાઓ છો? 8.2",

    "શ્રીભગવાન બોલ્યા : પરમ અક્ષર 'બ્રહ્મ' છે, પોતાનું સ્વરૂપ અર્થાત્ જીવાત્મા 'અધ્યાત્મ' નામે કહેવાય છે, તથા સકળ ચરાચર પ્રાણીઓનો ઉદ્ભવ તેમજ અભ્યુદય કરાવનાર જે સૃષ્ટિરચનારૂપી વિસર્જન અર્થાત્ ત્યાગ છે, એ 'કર્મ' નામે ઓળખાયો છે. 8.3",

    "ઉત્પત્તિ-વિનાશશીલ પ્રત્યેક પદાર્થ 'અધિભૂત' છે, હિરણ્યમય પુરુષ 'આધિદૈવ' છે અને હે દેહધારીઓમાં શ્રેષ્ઠ અર્જુન! આ શરીરમાં હું વાસુદેવ જ અન્તર્યામીસ્વરૂપે 'અધિયજ્ઞ' છું. 8.4",

    "જે માણસ અંતકાળેય મને જ સ્મરતો-સ્મરતો શરીર છોડીને જાય છે, એ મારા સાક્ષાત્ સ્વરૂપે પામે છે, એમાં સહેજ પણ સંશય નથી. 8.5",

    "હે કુન્તીપુત્ર! આ માણસ અંતકાળે જે-જે પણ ભાવને સ્મરણ કરતો શરીરને છોડે છે, તેને તેને જ પામે છે, કેમકે એ સદા તે જ ભાવથી ભાવિત રહ્યો છે. 8.6",

    "માટે હે અર્જુન! તું સર્વ કાળે નિરંતર મારું સ્મરણ કર અને યુદ્ધ પણ કર, આ પ્રમાણે મારામાં અર્પેલાં મન-બુદ્ધિથી યુક્ત થઈ તું ચોક્કસ મને જ પામીશ. 8.7",

    "હે પાર્થ! એ નિયમ છે કે પરમેશ્વરના ધ્યાનના અભ્યાસસ્વરૂપ યોગથી યુક્ત, બીજી તરફ ન જનારા ચિત્તથી નિરંતર ચિંતન કરતો માણસ પરમ પ્રકશસ્વરૂપ દિવ્ય પુરુષને એટલે કે પરમેશ્વરને જ પામે છે. 8.8",

    "જે માણસ સર્વજ્ઞ, અનાદિ, સૌના નિયન્તા, સૂક્ષ્મથીય અતિ સૂક્ષ્મ, સૌનું ધારણ-પોષણ કરનાર, અચિન્ત્ય-સ્વરૂપ, સૂર્ય સમાન નિત્ય ચેતન પ્રકાશસ્વરૂપ અને અવિદ્યાથી સાવ પર એવા શુદ્ધ સચ્ચિદાનંદઘન પરમેશ્વરને સ્મરે છે - 8.9",

    "એ ભક્તિયુક્ત માણસ અંતકાળેય યોગબળથી બે ભ્રમરોની મધ્યમાં પ્રાણને સારી રીતે સ્થાપ્યા પછી નિશ્ચળ મનથી સ્મરતો-સ્મરતો એ દિવ્યરૂપ પરમ પુરુષ પરમાત્માને જ પામે છે. 8.10",

    "વેદને જાણનાર વિદ્વાનો જે સચ્ચિદાનંદઘનસ્વરૂપ પરમપદને અવિનાશી કહે છે, આસક્તિ વિનાના યત્નશીલ સંન્યાસી મહાત્માઓ જેનામાં પ્રવેશ કરે છે તથા જે પરમપદના ઈચ્છુક બ્રહ્મચારીઓ બ્રહ્મચર્યનું આચરણ કરે છે, એ પરમપદને હું તારા માટે ટૂંકમાં કહું છું. 8.11",

    "બધી ઈન્દ્રિયોનાં દ્વારોને રોકીને તથા મનને હૃદયપ્રદેશમાં સ્થિર કરીને, પછી એ જિતાયેલ મન વડે પ્રાણને મસ્તકમાં સ્થાપીને, પરમાત્મસંબંધી યોગધારણામાં સ્થિત થઈને જે માણસ 'ૐ' એ એક-અક્ષરસ્વરૂપ બ્રહ્મને ઉચ્ચારતો અને એના અર્થસવરૂપ મુજ નિર્ગુણ બ્રહ્મનું ચિંતન કરતો શરીરને છોડીને જાય છે, એ માણસ પરમ ગતિને પામે છે. 8.12-8.13",

    "બધી ઈન્દ્રિયોનાં દ્વારોને રોકીને તથા મનને હૃદયપ્રદેશમાં સ્થિર કરીને, પછી એ જિતાયેલ મન વડે પ્રાણને મસ્તકમાં સ્થાપીને, પરમાત્મસંબંધી યોગધારણામાં સ્થિત થઈને જે માણસ 'ૐ' એ એક-અક્ષરસ્વરૂપ બ્રહ્મને ઉચ્ચારતો અને એના અર્થસવરૂપ મુજ નિર્ગુણ બ્રહ્મનું ચિંતન કરતો શરીરને છોડીને જાય છે, એ માણસ પરમ ગતિને પામે છે. 8.12-8.13",

    "હે પૃથાપુત્ર! જે માણસ મારામાં અનન્ય-ચિત્ત થઈને સદાય નિરંતર મુજ પુરુષોત્તમને સ્મરે છે, એ નિત્ય-નિરંતર મારામાં જોડાયેલા યોગીને માટે હું સુલભ છું એટલે કે એને સહજ રીતે મળી જાઉં છું. 8.14",

    "પરમ સિદ્ધિને પામેલા મહાત્માઓ મને પામીને દુઃખોના રહેઠાણ તેમજ ક્ષણભંગુર એવા પુનર્જન્મને નથી પામતા. 8.15",

    "હે અર્જુન! બ્રહ્મલોક સુધીના સમસ્ત લોકો પુનરાવર્તી છે, પરંતુ હે કુન્તીપુત્ર! મને પામીને પુનર્જન્મ નથી થતો, કેમકે હું કાલાતીત છું અને આ બ્રહ્મા આદિના લોકો કાળ દ્વારા સીમિત હોવાથી અનિત્ય છે. 8.16",

    "બ્રહ્માને જે એક દિવસ છે એને સત્ય, ત્રેતા, દ્વાપર અને કળિયુગ - એ ચાર યુગના એક હજાર વાર થતા આવર્તનમાં લાગતા સમય જેટલો, તેમજ એમની રાત્રિને પણ એટલી જ એટલે કે એકહજાર ચતુર્યુગના સમય જેટલી (અર્થાત્ બ્રહ્મલોકનો પણ અંત આવતો હોવાને લીધે એ પણ વિનાશશીલ તથા અનિત્ય છે એમ) જેઓ તત્ત્વથી જાણે છે, એ યોગીઓ કાળના તત્ત્વને જાણનારા છે. 8.17",

    "બધાય જીવો બ્રહ્માનો દિવસ શરૂ થતા અવ્યક્તથી અર્થાત્ બ્રહ્માના સૂક્ષ્મ શરીરથી ઉત્પન્ન થાય છે અને બ્રહ્માની રાત્રિ શરૂ થતાં એ અવ્યક્ત નામના બ્રહ્માના સૂક્ષ્મ શરીરમાં જ લીન થઈ જાય છે. 8.18",

    "હે પાર્થ! એ જ આ ભૂતસમુદાય વારંવાર ઉત્પન્ન થઈને પ્રકૃતિને વશ થયેલો બ્રહ્માની રાત્રિ થતાં લય પામે છે અને દિવસ શરૂ થતાં ફરી ઉત્પન્ન થાય છે. 8.19",

    "પરંતુ એ અવ્યક્ત કરતાંય સાવ પર એવો અન્ય એટલે કે વિલક્ષણ જે સનાતન અવ્યક્ત ભાવ છે, એ પરમ દિવ્ય પુરુષ સઘળા જીવોના નષ્ટ થવા છતાંય નાશ નથી પામતો. 8.20",

    "જે અવ્યક્ત 'અક્ષર' એવા નામે કહેવાયો છે, એ જ અક્ષર નામના અવ્યક્તભાવને પરમ ગતિ કહે છે તથા જે સનાતન અવ્યક્તભાવને પામીને માણસો પાછા આવતા નથી, એ મારું પરમ ધામ છે. 8.21",

    "હે પૃથાપુત્ર! જે પરમાત્માની અંતર્ગત સર્વ ભૂતો છે તથા જે સચ્ચિદાનંદઘન પરમાત્માથી આ આખું જગત પરિપૂર્ણ છે, એ સનાતન અવ્યક્ત પરમ પુરુષ તો અનન્ય ભક્તિથી જ પામી શકાય છે. 8.22",

    "હે ભરતશ્રેષ્ઠ! જે કાળે શરીર છોડીને જનારા યોગિજનો પરત ન આવનારી ગતિને જ પ્રાપ્ત થાય છે, એ કાળને એટલે કે બેય માર્ગોને કહીશ. 8.23",

    "આ બે માર્ગમાંથી જે માર્ગે જ્યોતિર્મય અગ્નિ-અભિમાની દેવતા છે, દિવસોનો અભિમાની દેવતા છે, શુક્લપક્ષનો અભિમાની દેવતા છે અને ઉત્તરાયણના છ મહિનાનો અભિમાની દેવતા છે, એ માર્ગે મૃત્યુ પામીને જનારા બ્રહ્મવેત્તા યોગીજનો આ દેવતાઓ દ્વારા ક્રમે ક્રમે લઈ જવાઈને બ્રહ્મને પામે છે. 8.24",

    "અને જે માર્ગે ધૂમાભિમાની દેવતા છે, રાત્રિનો અભિમાની દેવતા છે, કૃષ્ણપક્ષનો અભિમાની દેવતા છે અને દક્ષિણાયનના છ મહિનાનો અભિમાની દેવતા છે, એ માર્ગે મૃત્યુ પામીને જનાર સકામ કર્મ કરનાર યોગી આ દેવતાઓ દ્વારા ક્રમે ક્રમે લઈ જવાઈને ચન્દ્રમાની જ્યોતિને પામીને સ્વર્ગમાં પોતાનાં શુભ કર્મોનું ફળ ભોગવીને પાછો આવે છે. 8.25",

    "કેમકે જગતના આ બે પ્રકારના શુક્લ અને કૃષ્ણ અર્થાત્ દેવયાન અને પિતૃયાન માર્ગ સનાતન મનાયા છે, આ બેમાંથી એક માર્ગ દ્વારા જનાર જ્યાંથી પાછું નથી ફરવું પડતું, એ પરમ ગતિને પામે છે અને બીજા માર્ગ દ્વારા જનાર ફરી પાછો આવે છે એટલે કે જન્મ-મરણને પામે છે. 8.26",

    "હૈ પાર્થ આ પ્રમાણે આ બેય માર્ગોને તત્ત્વથી જાણીને કોઈ પણ યોગી મોહિત નથી થતો; માટે હે અર્જુન! તું સર્વ કાળે સમબુદ્ધિસ્વરૂપ યોગથી યુક્ત થા અર્થાત્ નિરંતર મને પ્રાપ્ત કરવા માટે સાધન કરનાર થા. 8.27",

    "યોગી મનુષ્ય આ રહસ્યને તત્ત્વથી જાણીને વેદોના વાંચનમાં, યજ્ઞ, તપ તેમજ દાન આદિ કરવામાં જે પુણ્યફળ કહ્યું છે, એ સર્વને નિઃશંકપણે ઓળંગી જાય છે અને શાશ્વત પરમ પદને પામે છે. 8.28",

    "આ રીતે ૐ, તત્‌, સત્‌-એ ભગવાનના નામોના ઉચ્ચારણ સાથે બ્રહ્મવિધા અને યોગશાસ્ત્રમય શ્રીમદ્ભગવદ્‌ગીતોપનિષદરૂપી શ્રીકૃષ્ણાર્જુનસંવાદમાં \"અક્ષરબ્રહ્મયોગ\" નામનો આઠમો અધ્યાય પૂરો થયો. ॥૮॥"
  ],
  "adhyaya-9" : [
    "તારા જેવા શુદ્ધ હ્રદયના ભક્ત માટે આ પરમ ગોપનીય વિજ્ઞાનસહિત જ્ઞાનને ફરીથી કહું છું કે જેને જાણીને તું દુ:ખરૂપ સંસારથી છૂટી જઈશ. 9.1",

    "આ વિજ્ઞાન સહિત જ્ઞાન સઘળી વિદ્યાઓનો રાજા, સઘળા ગોપનીયોનો રાજા, અતિ પવિત્ર, ઘણું ઉત્તમ, પ્રત્યક્ષ ફળ અનુભવી શકાય એવું, ધર્મયુક્ત, સાધન કરવામાં સાવ સહેલું અને અવિનાશી છે. 9.2",

    "હે પરંતપ! ઉપર જણાવેલા આ ધર્મમાં શ્રઘ્ધા વિનાના માણસો મને ન પામતાં મૃત્યુરૂપી સંસારચક્રમાં ભટકતા રહે છે. 9.3",

    "મુજ નિરાકાર પરમાત્માથી આ આખું જગત જળથી બરફની પેઠે પરિપૂર્ણ છે અને સઘળા ભૂતો મારામાં સંકલ્પને આધારે સ્થિત છે, પણ વાસ્તવમાં હું એમનામાં સ્થિત નથી 9.4",

    "અને વળી એ સમસ્ત ભૂતો મારામાં સ્થિત નથી; મારી ઈશ્વરીય યોગશક્તિને જો, ભૂતો નું ધારણ- પોષણ કરનાર અને ભૂતો ને ઉત્પન્ન કરનાર છતાંયે મારો આત્મા વાસ્તવમાં ભૂતોમાં સ્થિત નથી. 9.5",

    "જેમ આકાશથી ઉદ્ભવેલો સર્વ બાજુએ વિચરનાર અતિ વિસ્તૃત વાયુ સદા આકાશમાં જ સ્થિત છે, એ જ રીતે મારા સંકલ્પ દ્વારા ઉત્પન્ન હોવાને લીધે સઘળા ભૂતો મારામાં સ્થિત છે, એમ સમજ. 9.6",

    "હે કૌન્તેય! કલ્પોના અંતે બધા ભૂતો મારી મૂળ પ્રકૃતિ ને પામે છે અને કલ્પોનાં આરંભે એમને હું ફરી સર્જુ છું. 9.7",

    "પોતાની પ્રકૃતિને સ્વીકારીને સ્વભાવના બળને લીધે વશ થયેલા આ આખાય ભૂત સમુદાયને વારંવાર એમના કર્મો અનુસાર સર્જું છું. 9.8",

    "હે ધનંજય! એ કર્મોમાં આસક્તિ વિનાના અને ઉદસીનની જેમ સ્થિત મુજ પરમાત્મા ને એ કર્મો નથી બાંધતાં. 9.9",

    "હે કુન્તીપુત્ર! મુજ અધિષ્ઠાતાના પ્રભાવથી જ પ્રકૃતિ ચરાચરસહિત આખા જગતને સર્જે છે અને આ હેતુ ના લીધે જ આ સંસાર- ચક્ર ફરી રહ્યું છે. 9.10",

    "મારા પરમ ભાવને નહિ જાણનારા મૂઢ માણસો, મનુષ્યનું શરીર ધારણ કરનાર મુજ સઘળા ભૂતોના મહાન ઈશ્વરને અવગણે છે એટલે કે પોતાની યોગમાયાથી સંસારનાં ઉદ્ધારને અર્થે મનુષ્ય સ્વરૂપે વિચરતા મુજ પરમેશ્વરને સામાન્ય માણસ ગણે છે. 9.11",

    "એ વ્યર્થ આશા રાખનારા, વ્યર્થ કર્મ કરનારા તેમજ જ્ઞાન નો દુરુપયોગ કરનારા વિક્ષિપ્ત ચિત્તના અજ્ઞાનીજનો રાક્ષસી, આસુરી અને મોહિની પ્રકૃતીને જ ધારણ કરી રાખે છે. 9.12",

    "પણ હે પાર્થ! દૈવી પ્રકૃતિને આશ્રિત મહાત્માઓ મને સર્વ ભૂતોનું સનાતન કારણ અને નાશ રહિત અક્ષર સ્વરૂપ સમજીને અનન્ય મનથી યુક્ત થઈને નિરંતર ભજે છે. 9.13",

    "એ દૃઢનિશ્ચયી ભક્તો નિરંતર મારા નામ અને ગુણોનું કીર્તન કરતા, મારી પ્રાપ્તિ અર્થે પ્રયત્ન કરતાં અને મને વારંવાર પ્રણામ કરતા સદા મારા ધ્યાનમાં જોડાઈને અનન્ય પ્રેમથી મને ઉપાસે છે. 9.14",

    "બીજા જે જ્ઞાનયોગીઓ છે, એ મુજ નિર્ગુણ- નિરાકાર બ્રહ્મનું જ્ઞાનયજ્ઞ દ્વારા અભિન્ન ભાવે પૂજન કરતાં કરતાં પણ મારી ઉપાસના કરે છે અને બીજા ભક્તો આખા બ્રહ્માંડમાં જેટલા પણ ચરાચર પ્રાણીઓ છે, એમને મારું જ સ્વરૂપ માનીને મુજ વિરાટ સ્વરૂપ પરમેશ્વર ની પૃથક્ - ભાવે ઉપાસના કરે છે 9.15",

    "ક્રતું એટલેકે શ્રોતકર્મ હું છું, યજ્ઞ એટલેકે પંચ મહાયજ્ઞ વગેરે સ્માર્ત કર્મ હું છું, સ્વધા એટલેકે પિતૃઓને તર્પણ રૂપે અપાતું અન્ન હું છું, ઔષધ હું છું, ધૃત હું છું, અગ્નિ હું છું અને હવન રૂપી ક્રિયા પણ હું જ છું. 9.16",

    "આ સકળ જગતનો ધાતા એટલેકે ધારણ કરનાર, કર્મોનાં ફળને આપનાર, પિતા, માતા, દાદા, જે જાણવા યોગ્ય છે એ તત્ત્વ, પવિત્ર ૐકાર, તેમજ ઋગ્વેદ, સામવેદ અને યજુર્વેદ પણ હું જ છું. 9.17",

    "પરમપદસ્વરૂપ પરમ ગતિ, ભરણ પોષણ કરનાર, સૌનો સ્વામી, શુભ- અશુભ ને જોનાર, સૌનું રહેઠાણ, શરણ લેવા યોગ્ય, પ્રત્યુપકાર ઈચ્છયા વિના હિત કરનાર, સૌની ઉત્પત્તિ-પ્રલય નો હેતુ, સ્થિતિ નો આધાર, નિધાન તેમજ અવિનાશી કારણ પણ હું જ છું. 9.18",

    "હું જ સૂર્ય રૂપે તપું છું, વર્ષાને સમુદ્ર વગેરે સ્થાનોમાંથી ખેંચું છું અને એને વરસાવું છું; હે અર્જુન! હું જ અમૃત અને મૃત્યુ છું તથા સત્- અસત્ પણ હું જ છું. 9.19",

    "ત્રણેય વેદોમાં વિધાન કરાયેલાં સકામ કર્મોને કરનારા, સોમરસ પીનારા, પાપ વિનાના માણસો મને યજ્ઞો દ્વારા પૂજીને સ્વર્ગની પ્રાપ્તિ ઈચ્છે છે, એ માણસો પોતાનાં પુણ્યોના ફળ સ્વરૂપે સ્વર્ગલોકને પામીને સ્વર્ગમાં દિવ્ય એવા દેવતાઓના ભોગોને ભોગવે છે. 9.20",

    "તેઓએ વિશાળ સ્વર્ગલોકને ભોગવીને પુણ્ય ક્ષીણ થતાં મૃત્યુ લોકમાં પાછા આવે છે; આ રીતે ત્રણેય વેદોમાં કહેલા સ્વર્ગની પ્રાપ્તિનાં સાધન ભૂત સકામ કર્મોનો આશ્રય લેનારા, તેમજ ભોગોને ઈચ્છતા માણસો વારંવાર ગમન-આગમનને પામે છે એટલેકે પુણ્યના પ્રભાવે સ્વર્ગમાં જાય છે અને પુણ્ય ક્ષીણ થતાં મૃત્યુ લોકમાં આવે છે. 9.21",

    "જે અનન્ય પ્રેમી ભક્તજનો મુજ પરમેશ્વરને નિરંતર ચિંતન કરતાં નિષ્કામ ભાવે ભજે છે, એ નિત્ય- નિરંતર મારું ચિંતન કરનારા ભક્તોના યોગક્ષેમનું હું પોતે વહન કરું છું. 9.22",

    "હે કુન્તીપુત્ર! જોકે શ્રદ્ધાથી યુક્ત જે સકામ ભક્તો અન્ય દેવતાઓને પૂજે છે, તેઓ પણ મને જ પૂજે છે; પરંતુ એમનું તે પૂજન અવિધિપૂર્વકનું એટલેકે અજ્ઞાનપૂર્વકનું છે. 9.23",

    "સમસ્ત યજ્ઞો નો ભોક્તા અને સ્વામી પણ હું જ છું; આમ હોવા છતાં પણ એ સકામ ભક્તો મુજ પરમેશ્વર ને તત્ત્વથી નથી જાણતા, માટે જ પતન પામે છે એટલે કે પુનર્જન્મને પામે છે. 9.24",

    "દેવતાઓને પૂજનારા દેવતાઓને પામે છે, પિતૃઓને પૂજનારા પિતૃઓને પામે છે, ભૂતોને પૂજનારા ભૂતોને પામે છે અને મારું પૂજન કરનારા ભક્તો મને જ પામે છે; માટે જ મારા ભક્તોનો પુનર્જન્મ નથી થતો. 9.25",

    "જે કોઈ ભક્ત મને પ્રેમથી પત્ર, પુષ્પ, ફળ, જળ વગેરે અર્પે છે, એ શુદ્ધ બુદ્ધિના નિષ્કામ પ્રેમી ભક્તનું પ્રેમપૂર્વક અર્પેલું એ પત્ર-પુષ્પ આદિ હું સગુણ રૂપે પ્રગટ થઈને પ્રેમ થી આરોગુ છું. 9.26",

    "હે કોન્તેય! તું જે કંઈ કર્મ કરે છે, જે ખાય છે, જે હોમે છે, જે દાન કરે છે તથા જે તપ કરે છે, એ સઘળું મને અર્પણ કર. 9.27",

    "આ પ્રમાણે, જેમાં સમસ્ત કર્મો મુજ ભગવાનને અર્પણ થાય છે, એવા સન્યાસ યોગ થી યુકત થયેલા ચિત્ત નો તું શુભાશુભ ફળ રૂપી કર્મબંધન થી છુટી જઈશ અને એમનાથી છૂટેલો મને જ પ્રાપ્ત થઈશ. 9.28",

    "હું સઘળા ભૂતો માં સમભાવે વ્યાપક છું, ન તો કોઈ મને અપ્રિય છે કે ન પ્રિય છે; છતાં પણ જે ભક્તો મને પ્રેમથી ભજે છે, તેઓ મારામાં છે અને હું પણ એમનાંમાં પ્રત્યક્ષરૂપે પ્રગટ છું. 9.29",

    "જો કોઈ ઘણો દુરાચારી પણ અનન્ય ભાવે મારો ભક્ત બનીને મને ભજે છે, તો એ સાધુ જ માનવા યોગ્ય છે; કેમકે એ ખરો નિશ્ચય કરનારો છે એટલેકે એણે દૃઢતાથી નિશ્ચય કરી લીધો છે કે પરમેશ્વરના ભજન જેવું બીજું કશુંય નથી. 9.30",

    "એ સત્વરે ધર્માત્મા થઈ જાય છે અને સદા રહેનારી પરમ શાંતિને પામે છે; હે કોંતેય! તું નિશ્ચયપૂર્વક સત્ય જાણ કે મારો ભક્ત નાશ નથી પામતો. 9.31",

    "હે પાર્થ! સ્ત્રીઓ, વૈશ્યો, શુદ્રો તથા પાપયોનિ- ચાંડાલ આદિ જે કોઈ પણ હોય, તેઓ પણ મારે શરણે જઈને પરમ ગતિને જ પામે છે. 9.32",

    "જો આ લોકો પરમ ગતિને પામી જતા હોય, તો પુણ્યશાળી બ્રાહ્મણો તથા રાજર્ષિ ભકતો મારે શરણે થઇને પરમ ગતિને પામે એમાં તો કહેવું જ શું! માટે તું સુખ વિનાનું અને ક્ષણભંગુર આ મનુષ્ય-શરીર પામીને નિરંતર મને જ ભજ. 9.33",

    "મારામાં મન ને પરોવ, મારો ભક્ત બન, મારું પૂજન કરનારો થા, મને પ્રણામ કર; આ રીતે આત્માને મારામાં પરોવીને મારે પારાયણ થયેલો તું મને જ પામીશ. 9.34",

    "આ રીતે ૐ, તત્‌, સત્‌-એ ભગવાનના નામોના ઉચ્ચારણ સાથે બ્રહ્મવિધા અને યોગશાસ્ત્રમય શ્રીમદ્ભગવદ્‌ગીતોપનિષદરૂપી શ્રીકૃષ્ણાર્જુનસંવાદમાં \"રાજવિદ્યારાજગુહ્યયોગ\" નામનો નવમો અધ્યાય પૂરો થયો. ॥૯॥"
  ],
  "adhyaya-10" : [
    "શ્રી ભગવાન બોલ્યા : હે મહાબાહો! હજી ફરીથી પણ મારા પરમ રહસ્યને તેમજ પ્રભાવને જણાવનાર વચન ને સાંભળ, જેને હું મારામાં અતિશય પ્રેમ રાખનાર તને, તારા હિતની ઈચ્છા થી કહું છું. 10.1",

    "મારી ઉત્પત્તિને એટલે કે અવતાર ધારણ કરવાની અલૌકિક લીલાને નથી દેવતાઓ જાણતા કે નથી તો મહર્ષિઓ જાણતા ; કેમકે હું સર્વ રીતે દેવતાઓનું અને મહર્ષિઓ નું પણ આદિ કારણ છું. 10.2",

    "જે મને અજન્મા એટલે કે વાસ્તવમાં જન્મ વિનાનો,અનાદિ અને સર્વ લોકોનો મહાન ઈશ્વર તત્ત્વથી જાણે છે , બધા માણસોમાં એ જ્ઞાની માણસ સર્વ પાપોથી છૂટી જાય છે. 10.3",

    "નિશ્ચય કરવાની શક્તિ, યથાર્થ જ્ઞાન , અસંમૂઢતા , ક્ષમા ,સત્ય, ઇન્દ્રિયો વશમાં રાખવી, મનનો નિગ્રહ તેમજ સુખ- દુઃખ, ઉત્પત્તિ- પ્રલય અને ભય-અભય તથા અહિંસા, સમતા, સંતોષ, તપ, દાન, કીર્તિ અને અપકીર્તિ - પ્રાણીઓના આવા વિવિધ પ્રકારના ભાવો મારાથી જ થાય છે. 10.4-10.5",

    "નિશ્ચય કરવાની શક્તિ, યથાર્થ જ્ઞાન , અસંમૂઢતા , ક્ષમા ,સત્ય, ઇન્દ્રિયો વશમાં રાખવી, મનનો નિગ્રહ તેમજ સુખ- દુઃખ, ઉત્પત્તિ- પ્રલય અને ભય-અભય તથા અહિંસા, સમતા, સંતોષ, તપ, દાન, કીર્તિ અને અપકીર્તિ - પ્રાણીઓના આવા વિવિધ પ્રકારના ભાવો મારાથી જ થાય છે. 10.4-10.5",

    "સાત મહર્ષિજનો, ચાર એમની પણ પૂર્વે થયેલા સનક આદિ તથા સ્વાયંભૂવ આદિ ચૌદ મનુઓ- મારામાં ભાવ રાખનારા આ બધાય મારા સંકલ્પથી જન્મેલા છે, જેમની સંસારમાં આ સઘળી પ્રજા છે. 10.6",

    "જે માણસ મારી આ પરંમ ઐશ્વર્યસ્વરૂપ વિભૂતિને અને યોગશક્તિને તત્ત્વથી જાણે છે એ નિશ્ચળ ભક્તિયોગથી યુક્ત થઈ જાય છે - એમાં લેશમાત્ર પણ સંશય નથી. 10.7",

    "હું વાસુદેવ જ આખા જગતની ઉત્પત્તિનું કારણ છું અને મારાથી જ સમગ્ર જગત ચેષ્ટા કરે છે ,આ પ્રમાણે સમજીને શ્રદ્ધા અને ભક્તિ રાખનારા બુદ્ધિમાન ભક્તો મુજ પરમેશ્વરને જ નિરંતર ભજે છે. 10.8",

    "નિરંતર મારામાં મન પરોવી રાખનારા અને મારામાં જ પ્રાણોને અર્પણ કરી રાખનારા ભક્તજનો, મારી ભક્તિની ચર્ચા દ્વારા પરસ્પર મારા પ્રભાવને ઓળખાવતા તથા ગુણ અને પ્રભાવસહિત મારું કથન કરતા જ નિત્ય-નિરંતર સંતોષી રહે છે અને મુજ વાસુદેવમાં જ નિરંતર રમ્યા કરે છે. 10.9",

    "નિરંતર મારા ધ્યાન વગેરેમાં પરોવાયેલા અને પ્રેમથી ભજનારા એ ભક્તોને હું એવો તત્ત્વજ્ઞાનરૂપી યોગ આપું છું, જેનાથી એ ભક્તો મને જ પામે છે. 10.10",

    "હે અર્જુન! એમના ઉપર અનુગ્રહ કરવા માટે, એમના અંતઃકરણમાં રહેલો હું પોતે જ, એમના અજ્ઞાનજનિત અંધકારને ઝળહળતા તત્ત્વજ્ઞાન રૂપી દીપક દ્વારા નષ્ટ કરી દઉં છું. 10.11",

    "અર્જુન બોલ્યા : આપ પરમ બ્રહ્મ, પરમ ધામ અને પરમ પવિત્ર છો; કેમકે આપને સમસ્ત ઋષિજનો સનાતન, દિવ્ય પુરુષ, દેવોના પણ આદિદેવ, અજન્મા અને સર્વવ્યાપી કહે છે, એવું જ દેવર્ષિ નારદ ,અસિત અને દેવલ ઋષિ તથા મહર્ષિ વ્યાસ પણ કહે છે ; અને વળી આપ પોતે પણ મને એમ જ કહો છો. 10.12-10.13",

    "અર્જુન બોલ્યા : આપ પરમ બ્રહ્મ, પરમ ધામ અને પરમ પવિત્ર છો; કેમકે આપને સમસ્ત ઋષિજનો સનાતન, દિવ્ય પુરુષ, દેવોના પણ આદિદેવ, અજન્મા અને સર્વવ્યાપી કહે છે, એવું જ દેવર્ષિ નારદ ,અસિત અને દેવલ ઋષિ તથા મહર્ષિ વ્યાસ પણ કહે છે ; અને વળી આપ પોતે પણ મને એમ જ કહો છો. 10.12-10.13",

    "હે કેશવ ! જે કંઈ પણ મને આપ કહી રહ્યા છો , એ સઘળું હું સત્ય માનું છું ; હે ભગવન્ આપના લીલામય સ્વરૂપને નથી તો દાનવો જાણતા કે નથી દેવતા પણ જાણતા. 10.14",

    "હે સઘળા ભૂતોના સર્જનહાર ! હે સઘળાભૂતોના ઈશ્વર ! હે દેવોના દેવ ! હે જગતના સ્વામી ! હે પુરૂષોત્તમ ! આપ પોતે જ પોતાના વડે પોતાને જાણો છો. 10.15",

    "માટે આપ જ પોતાની દિવ્ય વિભૂતિઓ ને સંપૂર્ણપણે કહેવા સમર્થ છો જેવી વિભૂતિઓ દ્વારા આપ આ સકળ લોકોને વ્યાપીને રહેલા છો. 10.16",

    "હે યોગેશ્વર! હું કયા પ્રકારે નિરંતર ચિંતન કરતો આપને જાણી શકું? અને હે ભગવાન! આપ કયા કયા ભાવોમાં મારા વડે ચિંતન કરવા યોગ્ય છો? 10.17",

    "હે જનાર્દન! પોતાની યોગશક્તિને અને વિભૂતિને હજી પણ વિસ્તારથી કહો, કેમ કે આપના અમૃત જેવા વચનોને સાંભળતા મને તૃપ્તિ થતી નથી, મારી સાંભળવાની ઉત્કંઠા હજીયે વધતી જાય છે. 10.18",

    "શ્રીભગવાન બોલ્યા : હે કુરુશ્રેષ્ઠ! હવે હું જે મારી દિવ્ય વિભૂતિઓ છે એમને તારા માટે મુખ્ય-મુખ્યરૂપે કહું છું કેમ કે મારા વિસ્તાર નો અંત નથી. 10.19",

    "હે નિદ્રાને જીતનારા અર્જુન! હું સઘળા ભૂતોના હૃદયમાં રહેલો સૌનો આત્મા છું તથા સર્વ ભૂતોનો આદિ, મધ્ય અને અંત પણ હું જ છું. 10.20",

    "હું અદિતીના બાર પુત્રોમાં વિષ્ણુ અને જ્યોતિઓમાં અંશુમાલી સૂર્ય છું તથા ઓગણપચાસ વાયુ દેવતાઓનું તેજ તેમજ નક્ષત્રોનો અધિપતિ ચંદ્રમા છું. 10.21",

    "હું વેદોમાં સામવેદ છું, દેવોમાં ઇન્દ્ર છું, ઈન્દ્રિયોમાં મન છું અને પ્રાણીઓની ચેતના એટલે કે જીવનશક્તિ છું. 10.22",

    "હું અગિયાર રુદ્રોમાં શંકર છું, યક્ષ-રાક્ષસોમાં ધનનો સ્વામી કુબેર છું; હું આઠ વસુઓમાં અગ્નિ છું અને શિખરોવાળા પર્વતોમાં મેરુપર્વત છું. 10.23",

    "પુરોહિતોમાં મુખ્ય બૃહસ્પતિ તું મને જાણ. હે પાર્થ! હું સેનાપતિઓમાં સ્કંદ અને જળાશયોમાં સમુદ્ર છું. 10.24",

    "હું મહર્ષિઓમાં ભૃગુ અને અર્થબોધક શબ્દોમાં એક અક્ષર એટલે કે ૐકાર છું, સર્વ પ્રકારના યજ્ઞોમાં જપયજ્ઞ અને સ્થાવરોમાં હિમાલય છું. 10.25",

    "વૃક્ષોમાં પીપળાનું વૃક્ષ, દેવર્ષિઓમાં નારદમુનિ, ગંધર્વોમાં ચિત્રરથ અને સીદ્ધોમાં કપિલમુનિ છું. 10.26",

    "અશ્વોમાં અમૃતની સાથે ઉદ્ભવેલો ઉચ્ચૈશ્રવા નામનો અશ્વ, ગજેન્દ્રોમાં ઐરાવત નામનો હાથી અને મનુષ્યોમાં રાજા તું મને જાણ. 10.27",

    "હું શસ્ત્રોમાં વજ્ર અને ગાયોમાં કામધેનુ છું. શાસ્ત્રોક્ત પ્રકારે સંતાનની ઉત્પત્તિનો જે હેતુ છે એ કામદેવ છું અને સર્પોમાં સર્પરાજ વાસુકી છું. 10.28",

    "હું નાગોમાં શેષનાગ અને જળનો અધિપતિ વરુણદેવતા છું, તેમજ પિતૃઓમાં અર્યમા નામનો પિતૃ તથા શાસન કરનારાઓમાં યમરાજ છું. 10.29",

    "હું દૈત્યોમાં પ્રહલાદ અને ગણના કરનારાઓનો સમય છું તેમજ પશુઓમાં મૃગરાજ સિંહ અને પક્ષીઓમાં હું ગરુંડ છું. 10.30",

    "હું પાવન કરનારાઓમાં વાયુ અને શસ્ત્રધારીઓ માં શ્રીરામ છું તથા માછલીઓમાં મગર છું અને નદીઓમાં શ્રીભાગીરથી ગંગાજી છું. 10.31",

    "હે અર્જુન! સૃષ્ટિનો આદિ, અંત તથા મધ્ય પણ હું જ છું, હું વિદ્યાઓમાં અધ્યાત્મવિદ્યા એટલે કે બ્રહ્મવિદ્યા અને પરસ્પરવાદ કરનારાઓનો તત્ત્વનિર્ણયને માટે કરવામાં આવતો વાદ છું. 10.32",

    "અક્ષરોમાં અકાર છું અને સમાસમાં દ્વન્દ્વ નામનો સમાસ છું, અક્ષયકાળ એટલે કે કાળનોય મહાકાળ તથા સર્વ તરફ મુખ ધરાવનાર વિરાટ સ્વરૂપે ધાતા અર્થાત સૌનું ધારણ-પોષણ કરનાર પણ હું જ છું. 10.33",

    "હું સૌનો સંહાર કરનાર મૃત્યુ અને ઉત્પન્ન થનારાઓની ઉત્પત્તિનો હેતુ છું; તથા સ્ત્રીઓમાં કીર્તિ, શ્રી, વાક્, સ્મૃતિ, મેધા, ધૃતિ અને ક્ષમા છું. 10.34",

    "તથા ગાઈ શકાય એવી શ્રુતિઓમાં હું બૃહત્ નામનો સામ તેમજ છંદોમાં ગાયત્રી છંદ છું; તથા મહિનાઓમાં માગશર અને ઋતુઓમાં વસંત હું છું. 10.35",

    "છળનારાઓમાં જુગાર તથા પ્રભાવશાળીઓનો પ્રભાવ છું; હું જીતનારાઓનો વિજય છું, નિશ્ચય કરનારાઓનો નિશ્ચય અને સાત્ત્વિક માણસોનો સાત્ત્વિક ભાવ છું. 10.36",

    "વૃષ્ણિવંશીઓમાં વાસુદેવ એટલે કે હું પોતે તારો સખા, પાંડવોમાં ધનંજય એટલે કે તું, મુનિઓમાં વેદવ્યાસ અને કવિઓમાં શુક્રાચાર્ય કવિ પણ હું જ છું. 10.37",

    "હું દમન કરનારાઓનો દંડ અર્થાત્ દમન કરનારીશક્તિ છું, વિજય ઇચ્છનારાઓની નીતિ છું, ગુપ્ત રાખવા યોગ્ય ભાવોનું રક્ષણ કરનાર મૌન છું અને જ્ઞાની જનોને તત્ત્વજ્ઞાન હું જ છું. 10.38",

    "અને હૈ અર્જુન! જે સમસ્ત ભૂતોની ઉત્પત્તિનું કારણ છે, એ પણ હું જ છું; કેમકે એવું ચર કે અચર કોઈ પણ પ્રાણી નથી, જે મારા વિનાનું હોય. 10.39",

    "હે પરંતપ! મારી દિવ્ય વિભૂતિઓનો અંત નથી ; મેં પોતાની વિભૂતિઓનો આ વિસ્તાર તો તારા માટે એકદેશરૂપે એટલે કે અત્યંત ટૂંકમાં કહ્યો છે. 10.40",

    "જે-જે પણ વિભૂતિયુક્ત એટલે કે ઐશ્વર્યસંપન્ન, શોભાયુક્ત અને શક્તિયુક્ત પદાર્થ છે, એને-એને તું મારા તેજના અંશની જ અભિવ્યક્તિ સમજ. 10.41",

    "અથવા હે અર્જુન! આ વધારે જાણીને તારે શું કરવું છે? સારરૂપે સમજી લે કે હું આ આખાય બ્રહ્માંડને પોતાની યોગ-શક્તિના એક અંશમાત્રથી ધારણ કરીને સ્થિત છું. 10.42",

    "આ રીતે ૐ, તત્‌, સત્‌-એ ભગવાનના નામોના ઉચ્ચારણ સાથે બ્રહ્મવિધા અને યોગશાસ્ત્રમય શ્રીમદ્ભગવદ્‌ગીતોપનિષદરૂપી શ્રીકૃષ્ણાર્જુનસંવાદમાં \"વિભૂતિયોગ\" નામનો દસમો અધ્યાય પૂરો થયો. ॥૧૦॥"
  ],
  "adhyaya-11" : [
    "અર્જુન બોલ્યા, મારા પર અનુકંપા કરીને આપે જે પરમ ગોપનીય અધ્યાત્મવિષયક ઉપદેશ કહ્યો, એનાથી મારું આ અજ્ઞાન નાશ પામી ચુક્યું છે. 11.1",

    "કેમકે હે કમલનયન ! મેં આપની પાસેથી ભૂતોની ઉત્પત્તિ અને પ્રલય વિસ્તારપૂર્વક સાંભળ્યાં તથા આપનો અવિનાશી મહિમા પણ સાંભળ્યો. 11.2",

    "હે પરમેશ્વર ! આપ પોતાને જેવા કહો છો, એ ખરેખર એમ જ છે, છતાં પણ હે પુરુષોત્તમ ! આપના જ્ઞાન, ઐશ્વર્ય, શક્તિ, બળ, વીર્ય અને તેજથી યુક્ત ઈશ્વરીય રૂપને હું પ્રત્યક્ષ જોવા માંગું છું. 11.3",

    "હે પ્રભો ! જો મારા વડે આપનું એ રૂપ જોવાનું શક્ય છે - એમ આપ માનતા હો, તો હે યોગેશ્વર ! આપ પોતાના એ અવિનાશી સ્વરૂપનું મને દર્શન કરાવો. 11.4",

    "શ્રીભગવાન બોલ્યા : હે પાર્થ ! હવે તું મારાં સેંકડો-હજારો વિવિધ પ્રકારનાં, વિવિધરંગી તથા વિવિધ આકૃતિનાં અલૌકિક રૂપોને જો. 11.5",

    "હે ભરતવંશી ! મારામાં અદિતિના બાર પુત્રોને, આઠ વસુઓને, અગિયાર રુદ્રોને, બેય અશ્વિનીકુમારોને અને ઓગણપચાસ મરુદ્ગણોને જો તથા બીજાં પણ ઘણાંબધાં આ પહેલાં ન જોયેલાં આશ્ચર્યમય રૂપોને જો. 11.6",

    "હે નિદ્રાને જીતનાર ! (નિદ્રાને જીતનાર હોવાથી અર્જુનનું નામ ગુડાકેશ પડ્યું હતું) હમણાં જ આ મારા શરીરમાં એક સ્થળે રહેલા, ચર અને અચર સહિત આખા બ્રહ્માંડને જો તથા બીજું પણ જે કંઈ જોવા ઈચ્છતો હોય એ જો. 11.7",

    "પરંતુ મને તું આ પોતાનાં સ્થૂળ ચક્ષુઓથી જોઈ શકવા ચોક્કસપણે સમર્થ નથી; માટે હું તને અલૌકિક ચક્ષુ આપું છું, એનાથી તું મારી ઈશ્વરીય યોગશક્તિને જો. 11.8",

    "સંજય બોલ્યા, હે રાજન્ ! મહાયોગેશ્વર તેમજ સર્વ પાપોને હરનાર ભગવાન હરિએ આમ કહ્યા પછી અર્જુનને પરમ ઐશ્વર્યયુક્ત દિવ્યસ્વરૂપ દેખાડ્યું. 11.9",

    "અનેક મુખ અને નેત્રો ધરાવનાર, અનેક અદ્ભુત દશ્યોથી યુક્ત, ઘણાં બધાં દિવ્ય આભૂષણોથી યુક્ત અને કેટલાંયે ઉગામેલાં દિવ્ય આયુધો હાથોમાં ધારણ કરેલા, દિવ્ય માળા તેમજ વસ્ત્રોને ધારણ કરેલા તથા જેમના શ્રીવિગ્રહમાંથી દિવ્ય ચંદનનો પરિમલ પ્રસરી રહ્યો છે એવા, બધી જ જાતનાં આશ્ચર્યોથી યુક્ત, સીમા વિનાના અને સર્વ તરફ જેમનાં મુખો છે, એવા વિરાટસ્વરૂપ પરમદેવ પરમેશ્વરને અર્જુને જોયા. 11.10-11.11",

    "અનેક મુખ અને નેત્રો ધરાવનાર, અનેક અદ્ભુત દશ્યોથી યુક્ત, ઘણાં બધાં દિવ્ય આભૂષણોથી યુક્ત અને કેટલાંયે ઉગામેલાં દિવ્ય આયુધો હાથોમાં ધારણ કરેલા, દિવ્ય માળા તેમજ વસ્ત્રોને ધારણ કરેલા તથા જેમના શ્રીવિગ્રહમાંથી દિવ્ય ચંદનનો પરિમલ પ્રસરી રહ્યો છે એવા, બધી જ જાતનાં આશ્ચર્યોથી યુક્ત, સીમા વિનાના અને સર્વ તરફ જેમનાં મુખો છે, એવા વિરાટસ્વરૂપ પરમદેવ પરમેશ્વરને અર્જુને જોયા. 11.10-11.11",

    "હે રાજન્ ! આકાશમાં હજારો સૂર્યોના એક સાથે ઉદય થવાને લીધે જે પ્રકાશ ઊપજે, તે પણ એ વિશ્વરૂપ પરમાત્માના પ્રકાશના જેટલો ભાગ્યે જ હશે. 11.12",

    "પાંડુપુત્ર અર્જુને એ વખતે અનેક પ્રકારે વિભાજિત આખા બ્રહ્માંડને દેવોનાય દેવ ભગવાન શ્રીકૃષ્ણના એ શરીરમાં એક સ્થળે રહેલું જોયું. 11.13",

    "ત્યાર પછી આશ્ચર્યમાં ડૂબેલા અને હર્ષપુલકિત થયેલા તે ધનંજય, પ્રકાશમાન વિશ્વરૂપ પરમાત્માને શ્રદ્ધા અને ભક્તિ સાથે મસ્તક નમાવીને, હાથ જોડી સ્તુતિ કરવા લાગ્યા. 11.14",

    "અર્જુન બોલ્યા: હે દેવ ! હું આપના શ્રીવિગ્રહમાં સઘળા દેવોને તથા અનેક ચરાચર પ્રાણીઓના સમૂહોને, કમળના આસન પર બિરાજેલા બ્રહ્માને, મહાદેવને, સમસ્ત ઋષિઓને અને દિવ્ય સર્પાને જોઉં છું. 11.15",

    "હે સક‌ળ વિશ્વના સ્વામી‌! આપને અસંખ્ય ભુજાઓ, ઉદર, મુખ અને નેત્ર ધરાવનાર, તેમજ સર્વ તરફ અનંત રૂપો ધરાવતા જોઉં છું; આખું વિશ્વ જ જેનું સ્વરૂપ છે એવા હે વિશ્વરૂપ! હું ન તો આપના અંતને જોઉં છું ન તો મધ્ય કે ન તો આદિને. 11.16",

    "આપને હું મુકુટધારી, ગદાધારી અને ચક્રધારી, બધી બાજુએ પ્રકાશમાન તેજના પુંજ સ્વરૂપ, ધગધગતા અગ્નિ અને સૂર્ય જેવા તેજસ્વી, મુશ્કેલીથી જોઈ શકાય એવા બધી બાજુએ અમાપસ્વરૂપે જોઉં છું. 11.17",

    "આપ જ જાણવા યોગ્ય પરમ અક્ષર એટલે કે પરબ્રહ્મ પરમાત્મા છો, આપ જ આ જગતના પરમ આશ્રય છો, આપ જ શાશ્વત ધર્મના રક્ષક છો અને આપ જ અવિનાશી સનાતન પુરુષ છો એવો મારો મત છે. 11.18",

    "આપને આદિ, મધ્ય અને અંત વિનાના, અનંત સામર્થ્ય ધરાવનાર, અપાર ભુજા ધરાવતા, ચંદ્ર - સૂર્ય રૂપી નેત્રો ધરાવતા, પ્રદીપ્ત અગ્નિ રૂપી મુખ ધરાવતા અને પોતાના તેજથી આ બ્રહ્માંડને સંતાપતા જોઉં છું. 11.19",

    "હે મહાત્મા! સ્વર્ગ અને પૃથ્વી ની વચ્ચેનું આ સંપૂર્ણ આકાશ તથા સગળી દિશાઓ, એક માત્ર આપનાથી જ વ્યાપેલા છે: તેમજ આપના આ અદભુત અને ભયાવહ રૂપને જોઈને ત્રણેય લોક ભયના માર્યા અતિ વ્યથિત થઈ રહ્યા છે. 11.20",

    "પેલા દેવતાઓના સમૂહો આપના માં પ્રવેશી રહ્યા છે તથા કેટલાક ભયના માર્યા હાથ જોડીને આપના નામ અને ગુણોનું ગાન કરી રહ્યા છે અને મહર્ષિ તેમજ સીદ્ધો ના સમુદાયો \"કલ્યાણ થાઓ\" એમ કહીને કેટલાંયે એ ઉત્તમ સ્તોત્રો દ્વારા આપની સ્તુતિ કરી રહ્યા છે. 11.21",

    "જે અગિયાર રુદ્રો અને બાર આદિત્યો તથા આઠ વસુઓ, બાર સાધ્યો, દસ વિશ્વેદેવો, બેય અશ્વિનીકુમારો, મરુદ્ગણ અને પિતૃઓનો સમુદાય તથા ગન્ધર્વ, યક્ષ, રાક્ષસ અને સિદ્ધોના સમુદાયો છે - એ બધાય છક થઈને આપને જોઈ રહ્યા છે. 11.22",

    "હે મહાબાહો! આપના ઘણા ઘણા મુખ અને નયનોથી યુક્ત ઘણા હાથ, જંઘા અને ચરણ ધરાવતા, અનેક ઉદરો ધરાવતા અને ઘણી બધી દાઢોને કારણે અત્યંત વિકરાળ, મહાન રૂપને જોઈને બધા લોકો વ્યાકુળ થઈ રહ્યા છે, તેમ જ હું પણ આકુળ-વ્યાકુળ થઈ રહ્યો છું. 11.23",

    "કેમકે હે વિષ્ણો! ગગનચુંબી, દૈદીપ્યમાન અનેક વર્ણોથી યુક્ત તથા ફાડેલા મુખ અને પ્રકાશમાન વિશાળ નેત્રો ધરાવતા આપને જોઈને, ડરેલા અંતઃકરણનો હું ધીરજ અને શાંતિ નથી પામતો. 11.24",

    "તીણી દાઢોને લીધે વિકરાળ અને પ્રલયકાળના અગ્નિ જેવાં પ્રજ્વળેલાં‌ આપનાં મુખોને જોઈને હું દિશાઓને નથી ઓળખી શકતો અને સુખ પણ નથી પામી શકતો; માટે હે દેવોના સ્વામી! જગતના આધાર! આપ પ્રસન્ન થાઓ. 11.25",

    "હું જોઉ છું કે પેલા બધા જ ધૃતરાષ્ટ્રના પુત્રો, રાજાઓના સમૂહ સમેત આપનામાં પ્રવેશી રહ્યા છે અને પિતામહ ભીષ્મ, દ્રોણાચાર્ય તથા પેલો કર્ણ અને આપણા પક્ષના પણ મુખ્ય યોધ્ધાઓ સમેત એકેએક, તીણી દાઢોને લીધે વિકરાળ આપના ભયાવહ મુખોમાં ઘણા વેગ થી દોડીને પ્રવેશી રહ્યા છે. અને કેટલાક તો ભુક્કો થયેલાં માથાં સહિત, આપના દાંતો ની વચ્ચે ભીંસાયેલા દેખાઈ રહ્યાં છે. 11.26-11.27",

    "હું જોઉ છું કે પેલા બધા જ ધૃતરાષ્ટ્રના પુત્રો, રાજાઓના સમૂહ સમેત આપનામાં પ્રવેશી રહ્યા છે અને પિતામહ ભીષ્મ, દ્રોણાચાર્ય તથા પેલો કર્ણ અને આપણા પક્ષના પણ મુખ્ય યોધ્ધાઓ સમેત એકેએક, તીણી દાઢોને લીધે વિકરાળ આપના ભયાવહ મુખોમાં ઘણા વેગ થી દોડીને પ્રવેશી રહ્યા છે. અને કેટલાક તો ભુક્કો થયેલાં માથાં સહિત, આપના દાંતો ની વચ્ચે ભીંસાયેલા દેખાઈ રહ્યાં છે. 11.26-11.27",

    "જેમ નદીઓના અનેક જળ પ્રવાહો સહજ રીતે સાગર તરફ જ દોડે છે, એટલે કે સાગરમાં સમાઈ જાય છે, એ જ રીતે નરલોકના શ્રેષ્ઠ શૂરવીરો પણ આપના પ્રજ્વળતાં મુખોમાં પ્રવેશી રહ્યા છે એટલે કે આપનામાં લીન થઇ રહ્યા છે. 11.28",

    "જેમ પતંગિયા મોહ ને વશ થઇ, નષ્ટ થવા માટે પ્રજ્વલિત અગ્નિ માં, ઘણા વેગથી ઊડતાં ઊડતાં જાતે જ પ્રવેશતાં હોય છે, એમ જ આ સર્વે પણ પોતાના નાશને માટે આપના મુખોમાં અતિ વેગથી, જાતે જ પ્રવેશી રહ્યા છે. 11.29",

    "આપ, એ સમસ્ત લોકોને પ્રજ્વળતા મુખો દ્વારા કોળિયો કરતા, ચારે કોરથી વારંવાર ચાટી રહ્યા છો; હે વિષ્ણો! આપનો ઉગ્ર પ્રકાશ બ્રહ્માંડને તેજથી ભરી દઇને સંતાપી રહ્યો છે. 11.30",

    "મને જણાવો કે ઉગ્ર રૂપધારી આપ કોણ છો? હે દેવાધિદેવ! આપને નમસ્કાર કરું છું, આપ પ્રસન્ન થાઓ; આદિપુરુષ આપને હું વિશેષ રૂપે ઓળખવા માંગું છું, કેમકે હું આપની પ્રવૃત્તિને નથી સમજી શકતો. 11.31",

    "શ્રી ભગવાન બોલ્યા: હું લોકોનો સંહાર કરનાર વધી ગયેલો મહાકાળ છું, અત્યારે આ લોકોનો સંહાર કરવા પ્રવૃત્ત થયો છું; માટે જે પ્રતિપક્ષીઓની સેનામાં રહેલા યોદ્ધાઓ છે, એ બધા તારા વિના પણ નથી રહેવાના, એટલે કે તું યુદ્ધ નહીં કરે તો પણ આ સર્વનો નાશ તો થવાનો જ છે. 11.32",

    "માટે તું ઉભો થા! યશ મેળવ અને શત્રુઓને જીતીને ધન-ધાન્યથી સમૃદ્ધ રાજ્યને ભોગવ; આ સર્વે શૂરવીરો પહેલેથી જ મારા વડે હણાયેલા છે; હે સવ્યસાચી! તું તો કેવળ નિમિત્તમાત્ર થા. 11.33",

    "દ્રોણાચાર્ય, ભીષ્મ પિતામહ, જયદ્રથ, કર્ણ તથા બીજા પણ ઘણા બધા મારા વડે હણાયેલા શૂરવીર યોદ્ધાઓને તું હણ, ભય રાખીશ મા; ચોક્કસ તું યુદ્ધમાં વેરીઓને જીતીશ, માટે યુદ્ધ કર. 11.34",

    "સંજય બોલ્યા : શ્રી હરિ કેશવના આ વચનને સાંભળીને કિરીટી અર્જુન હાથ જોડી થરથર કાંપતા નમસ્કાર કરીને, ફરીથી ઘણા બીતાં બીતાં પ્રણામ કરીને ભગવાન શ્રી કૃષ્ણની ગદ્ગદ વાણીથી સ્તુતિ કરવા લાગ્યા. 11.35",

    "અર્જુન (ફરીથી સ્તુતિ કરતાં) બોલ્યા : હે અન્તર્યામી! એ યોગ્ય જ છે કે આપનાં નામ, ગુણ અને પ્રભાવના કીર્તનથી વિશ્વ ઘણું હરખાઈ રહ્યું છે અને પ્રેમવિહ્વળ પણ થઈ રહ્યું છે, તેમજ ભી પામેલા રાક્ષસો આમ-તેમ નાસી રહ્યા છે તથા સર્વે સિદ્ધગણોના સમૂહો નમસ્કાર કરી રહ્યા છે. 11.36",

    "હે મહાત્મન્! બ્રહ્માના પણ આદિકર્તા અને સૌથી મોટા આપને તેઓ કેમ ન નમે! કેમકે હે અનન્ત! હે દેવતાઓના ઈશ! હે વિશ્વના પરમ આધાર! હે સત્, અસત્ અને એ બેયથી પર અક્ષર એટલે કે સચ્ચિદાનદઘન બ્રહ્મ છે, એ આપ છો. 11.37",

    "આપ આદિદેવ અને સનાતન પુરુષ છો, આપ આ જગતના પરમ આશ્રય, જાણનાર, જાણવા યોગ્ય અને પરમ ધામ છો, હે અનન્તરૂપ! આપનાથી આ આખું વિશ્વ વ્યાપેલું એટલે કે પરિપૂર્ણ છે. 11.38",

    "આપ વાયુ, યમ, અગ્નિ, વરુણ, ચન્દ્રમા અને પ્રજાના સ્વામી બ્રહ્મા છો તથા બ્રહ્માના પણ પિતા છો, આપને વારંવાર નમસ્કાર! હજારો વાર નમસ્કાર! તથા ફરી પાછા એથી પણ વધારે વાર નમસ્કાર!!! 11.39",

    "હે અનન્ત સામર્થ્ય ધરાવનાર! આપને આગળથી તેમજ પાછળથી પણ નમસ્કાર! હે સર્વના આત્મા! આપને બધી જ બાજુઓથી નમસ્કાર કરું છું, કેમકે અનંત પરાક્રમી આપ આખા બ્રહ્માંડને વ્યાપીને રહેલા છો, માટે આપ જ સર્વરૂપ છો. 11.40",

    "આપના આ પ્રભાવને નહીં જાણતાં, તમે મારા સખા છો એમ માનીને, પ્રેમથી કે પ્રમાદથી પણ મેં 'હે કૃષ્ણ!' 'હે યાદવ!' 'હે સખા!' એ પ્રમાણે જે કંઈ વગર વિચાર્યે અવજ્ઞાપૂર્વક કહ્યું છે અને હે અચ્યુત! આપ જે મારા વડે વિનોદ ખાતર વિહાર, શય્યા, આસન અને ભોજન આદિ કરતી વખતે એકાંતમાં, કે પછી એ સખાઓની સામે પણ, અપમાનિત કરવામાં આવ્યા છો - એ સર્વ અપરાધો અપ્રમેયસ્વરૂપ એટલે કે અચિંત્ય પ્રભાવના આપને હું ક્ષમા કરવાની પ્રાર્થના કરું છું. 11.41-11.42",

    "આપના આ પ્રભાવને નહીં જાણતાં, તમે મારા સખા છો એમ માનીને, પ્રેમથી કે પ્રમાદથી પણ મેં 'હે કૃષ્ણ!' 'હે યાદવ!' 'હે સખા!' એ પ્રમાણે જે કંઈ વગર વિચાર્યે અવજ્ઞાપૂર્વક કહ્યું છે અને હે અચ્યુત! આપ જે મારા વડે વિનોદ ખાતર વિહાર, શય્યા, આસન અને ભોજન આદિ કરતી વખતે એકાંતમાં, કે પછી એ સખાઓની સામે પણ, અપમાનિત કરવામાં આવ્યા છો - એ સર્વ અપરાધો અપ્રમેયસ્વરૂપ એટલે કે અચિંત્ય પ્રભાવના આપને હું ક્ષમા કરવાની પ્રાર્થના કરું છું. 11.41-11.42",

    "આપ આ ચરાચર જગતના પિતા, સૌથી મોટા ગુરુ અને ઘણા પૂજનીય છો; હે અતુલ પ્રભાવ ધરાવનાર! ત્રણેય લોકમાં આપના સમાન પણ બીજો કોઈ નથી, પછી ચઢિયાતો તો ક્યાંથી હોઈ શકે!! 11.43",

    "માટે હે પ્રભો! હું શરીરને સારી પેઠે ચરણોમાં નિવેદિત કરીને, પ્રણામ કરીને, સ્તુતિને યોગ્ય આપ ઈશ્વરને પ્રસન્ન થવા માટે પ્રાર્થું છું; હે દેવ! પિતા જેમ પુત્રના, સખા જેમ સખાના અને પતિ જેમ પ્રિયતમા પત્નીના અપરાધોને સહી લે છે, એમ જ આપ પણ મારા અપરાધ સહી લેવા યોગ્ય છો. 11.44",

    "હું પહેલાં ના જોયેલા આપના આ આશ્ચર્યમય રૂપને જોઈને હરખાઈ પણ રહ્યો છું અને મારું માં ભયને લીધે ઘણું વ્યાકુળ પણ થઈ રહ્યું છે; માટે આપ પોતાનું પેલું ચતુર્ભુજ વિષ્ણુરૂપ જ મને દર્શાવો; હે દેવેશ! હે જગન્નિવાસ! પ્રસન્ન થાઓ. 11.45",

    "હું એવા જ આપને મુગટધારી તથા ગદા અને ચક્ર હાથમાં ધારણ કરેલા જોવા ઈચ્છું છું, માટે હે વિશ્વસ્વરૂપ! હે હજાર હાથવાળા! આપ એ જ ચતુર્ભુજરૂપે પ્રગટ થાઓ. 11.46",

    "શ્રીભગવાન બોલ્યા : હે અર્જુન! તારા પર અનુકંપા કરીને મેં પોતાની યોગશક્તિના પ્રભાવે, આ મારું ઉત્કૃષ્ટ, તેજોમય, સર્વનું આદિ અને અસીમ વિરાટ રૂપ તને દર્શાવ્યું છે, જેણે તારા સિવાય બીજા કોઈએ પહેલાં નહોતું જોયું. 11.47",

    "હે કુરુવંશીઓમાં શ્રેષ્ઠ વીર! મનુષ્યલોકમાં આવો વિશ્વરૂપધારી હું, તારા સિવાય બીજા કોઈનાથી ન તો વેદ અને યજ્ઞોના અધ્યયનથી જોઈ શકાઉં છું, ન દાનથી જોઈ શકાઉં છું, ન શાસ્ત્રવિહિત કર્મોથી જોઈ શકાઉં છું કે ન આકરાં તપોથીય જોઈ શકાઉં છું. 11.48",

    "મારા આવા આ વિકરાળ રૂપને જોઈને તને વ્યાકુળતા ન થાઓ અને મૂઢભાવ પણ ન થાઓ; તું નિર્ભય અને પ્રસન્નમન થઈને એ જ મારા આ શંખ ચક્ર ગદા પદ્મધારી ચતુર્ભુજ રૂપને ફરીથી જો. 11.49",

    "સંજય બોલ્યા : હે રાજન્! વાસુદેવ શ્રીહરિએ અર્જુનને આ પ્રમાણે કહીને પાછું એવું જ પોતાનું ચતુર્ભુજરૂપ દર્શાવ્યું અને પછી સૌમ્યમૂર્તિ થઈને આ ભય પામેલા અર્જુનને મહાત્મા શ્રીકૃષ્ણે ધીરજ આપી. 11.50",

    "અર્જુન બોલ્યા : હે જનાર્દન! આપના આ પરમશાંત મનુષ્યરૂપને જોઈને હવે હું સ્થિરચિત્ત થઈ ગયો છું અને પોતાની સ્વાભાવિક સ્થિતિને પામી ચુક્યો છું. 11.51",

    "શ્રીભગવાન બોલ્યા : મારું જે ચતુર્ભુજરૂપ તેં જોયું છે, એ સુદુર્દર્શ છે, એટલે કે એનાં દર્શન થવા ઘણાં દુર્લભ છે; દેવતાઓ પણ સદા આ રૂપનાં દર્શનની ખેવના રાખ્યા કરે છે. 11.52",

    "જેવો તેં મને જોયો છે એવો ચતુર્ભુજરૂપધારી હું ન તો વેદોથી જોઈ શકાઉં છું, ન દાનથી જોઈ શકાઉં છું કે ન યજ્ઞથીયે જોઈ શકાઉં છું. 11.53",

    "પરંતુ હે પરન્તપ અર્જુન! અનન્ય ભક્તિ દ્વારા આવો ચતુર્ભુજરૂપધારી હું પ્રત્યક્ષ દેખાવા માટે, તત્ત્વથી જાણવા માટે તથા પ્રવેશ કરવા એટલે કે એકાત્મભાવે પ્રાપ્ત થવા માટે પણ શક્ય છું. 11.54",

    "હે પાણ્ડુપુત્ર! જે કેવળ મારે જ ખાતર સઘળાં કર્તવ્યકર્મોને કરનારો છે, મારે પારાયણ છે, મારો ભક્ત છે, આસક્તિ વિનાનો છે અને સર્વ પ્રાણીઓ પ્રત્યે વેરભાવથી રહિત છે, એ અનન્યભક્તિયુક્ત માણસ મને જ પામે છે. 11.55",

    "આ રીતે ૐ, તત્‌, સત્‌-એ ભગવાનના નામોના ઉચ્ચારણ સાથે બ્રહ્મવિધા અને યોગશાસ્ત્રમય શ્રીમદ્ભગવદ્‌ગીતોપનિષદરૂપી શ્રીકૃષ્ણાર્જુનસંવાદમાં \"વિશ્વરૂપદર્શનયોગ\" નામનો અગિયારમો અધ્યાય પૂરો થયો. ॥૧૧॥"
  ],
  "adhyaya-12" : [
    "જે અનન્ય પ્રેમી ભક્તજનો, હમણાં આપે જણાવ્યું એ પ્રકારે નિરંતર આપનાં ભજન-ધ્યાનમાં રચ્યા- પચ્યા રહીને આપ સગુણ સ્વરૂપ પરમેશ્વરને ભજે છે અને બીજા ભક્તો, જે કેવળ અવિનાશી સચ્ચિદાનંદઘન નિરાકાર બ્રહ્મને જ ઘણા ઉત્તમ ભાવે ભજે છે- એ બન્ને પ્રકારના ઉપાસકોમાં વધુ ચઢિયાતા યોગવેત્તા કોણ છે ? 12.1",

    "મારામાં મનને પરોવીને નિરંતર મારા ભજન- ધ્યાનમાં રચ્યા- પચ્યા રહેનાર જે ભકતજનો, અત્યંત અડગ શ્રદ્ધાભાવથી યુક્ત થઈને, મુજ સગુણસ્વરૂપને ભજે છે, તેઓ મને યોગીઓમાં વધુ ચઢિયાતા યોગીરૂપે માન્ય છે. 12.2",

    "પરંતુ જે ભક્તો, ઈન્દ્રિયોના સમુદાયને સારી પેઠે વશમાં કરીને મન- બુદ્ધિથી પર, સર્વવ્યાપક, કોઈપણ રીતે નિર્દેશ ન થઈ શકે એવા, સદા એકરસ રહેનાર, નિત્ય, અચળ, નિરાકાર, અવિનાશી સચ્ચિદાનંદઘન બ્રહ્મને નિરંતર એકાત્મભાવે ધ્યાન કરતાં ભજે છે, એ સઘળાં ભૂતોના હિતમાં રત રહેનાર અને સૌમાં સમભાવ રાખનાર યોગીઓ મને જ પામે છે. 12.3-12.4",

    "પરંતુ જે ભક્તો, ઈન્દ્રિયોના સમુદાયને સારી પેઠે વશમાં કરીને મન- બુદ્ધિથી પર, સર્વવ્યાપક, કોઈપણ રીતે નિર્દેશ ન થઈ શકે એવા, સદા એકરસ રહેનાર, નિત્ય, અચળ, નિરાકાર, અવિનાશી સચ્ચિદાનંદઘન બ્રહ્મને નિરંતર એકાત્મભાવે ધ્યાન કરતાં ભજે છે, એ સઘળાં ભૂતોના હિતમાં રત રહેનાર અને સૌમાં સમભાવ રાખનાર યોગીઓ મને જ પામે છે. 12.3-12.4",

    "પણ સચ્ચિદાનંદઘન નિરાકાર બ્રહ્મમાં મન પરોવનાર એ માણસોના સાધનમાં પરિશ્રમ વધુ છે; કારણકે દેહાભિમાનીઓ વડે અવ્યક્તવિષયક ગતિ ઘણું દુ:ખ વેઠીને પ્રાપ્ત કરવામાં આવે છે અર્થાત દેહાભિમાન રહેવાને લીધે નિર્ગુણ બ્રહ્મની પ્રાપ્તિ દુષ્કર છે. 12.5",

    "પરંતુ મારે પરાયણ રહેનાર જે ભક્તજનો, સર્વ કર્મોને મારામાં અર્પણ કરીને, મુજ સગુણસ્વરૂપ પરમેશ્વરને જ અનન્ય ભક્તિયોગથી નિરંતર ચિંતન કરતાં ભજે છે, 12.6",

    "મારામાં ચિત્ત પરોવનાર એ પ્રેમી ભક્તોનો હે પાર્થ! હું સત્વરે મૃત્યુરૂપી સંસાર-સાગરમાંથી ઉદ્ધાર કરી દઉં છું. 12.7",

    "મારામાં મનને પરોવ અને મારામાં જ બુદ્ધિને જોડ; એ પછી તું મારામાં જ નિવાસ કરીશ, એમાં લેશમાત્ર સંશય નથી. 12.8",

    "જો તું મનને મારામાં અચળ ભાવે સ્થાપવા સમર્થ ન હોય, તો હે ધનંજય! અભ્યાસરૂપ યોગ દ્વારા મને પામવાની ઈચ્છા કર. 12.9",

    "જો તું ઉપર જણાવેલ અભ્યાસમાં પણ અસમર્થ છે, તો કેવળ મારે અર્થે કર્મ કરવાને જ પારાયણ થા; મારે અર્થે કર્મોને કરતો રહીને પણ તું મારી પ્રાપ્તિરૂપી સિદ્ધિને જ પામીશ. 12.10",

    "જો મારી પ્રાપ્તિરૂપી યોગનો આશરો લઈને ઊપર જણાવેલ સાધન કરવામાં પણ તું અસમર્થ છે, તો મન-બુદ્ધિ આદિ ઉપર જય મેળવનારો થઈને સર્વ કર્મોનાં ફળનો ત્યાગ કર. 12.11",

    "મર્મને જાણ્યા વિના કરેલા અભ્યાસ કરતાં જ્ઞાન ચઢિયાતું છે, જ્ઞાન કરતાં મુજ પરમેશ્વરના સ્વરૂપનું ધ્યાન ચઢિયાતું છે અને ધ્યાન કરતાં પણ સર્વ કર્મોનાં ફળનો ત્યાગ ચઢિયાતો છે; કેમકે ત્યાગથી તરત જ પરમ શાંતિ મળે છે. 12.12",

    "જે સર્વ ભૂતોમાં દ્વેષભાવ વિનાનો, વિના સ્વાર્થે સૌનો પ્રેમી અને કોઈપણ હેતુ વિના દયાભાવ રાખનારો છે તથા મમત્વ વિનાનો, અહંકાર વિનાનો, સુખ-દુ:ખોની પ્રાપ્તિમાં સમ અને ક્ષમાશીલ છે, એટલે કે અપરાધ કરનારને પણ અભય આપનારો છે તથા જે યોગી નિરંતર સંતુષ્ટ છે, મન-ઈંદ્રિયોસહિત શરીરને વશમાં કરી રાખેલ છે અને મારામાં દ્રઢ નિશ્ચયવાળો છે- એ મારામાં અર્પેલ મન-બુદ્ધિવાળો ભક્ત મને પ્રિય છે. 12.13-12.14",

    "જે સર્વ ભૂતોમાં દ્વેષભાવ વિનાનો, વિના સ્વાર્થે સૌનો પ્રેમી અને કોઈપણ હેતુ વિના દયાભાવ રાખનારો છે તથા મમત્વ વિનાનો, અહંકાર વિનાનો, સુખ-દુ:ખોની પ્રાપ્તિમાં સમ અને ક્ષમાશીલ છે, એટલે કે અપરાધ કરનારને પણ અભય આપનારો છે તથા જે યોગી નિરંતર સંતુષ્ટ છે, મન-ઈંદ્રિયોસહિત શરીરને વશમાં કરી રાખેલ છે અને મારામાં દ્રઢ નિશ્ચયવાળો છે- એ મારામાં અર્પેલ મન-બુદ્ધિવાળો ભક્ત મને પ્રિય છે. 12.13-12.14",

    "જેનાથી કોઈ પણ જીવ ઉદ્ધેગ નથી પામતો અને જે પોતે પણ કોઈ જીવથી ઉદ્ધેગ નથી પામતો તથા જે હર્ષ, અમર્ષ, ભય અને ઉદ્ધેગ આદિથી રહિત છે- એ ભક્ત મને પ્રિય છે. 12.15",

    "જે ખેવના વિનાનો, બાહ્ય તેમ જ આંતરિક રીતે શુદ્ધ, નિપુણ, પક્ષપાત વિનાનો અને દુઃખોમાંથી છૂટેલો છે- એ સર્વ આરંભોનો ત્યાગી મારો ભક્ત મને પ્રિય છે. 12.16",

    "જે ન તો કદી હર્ષ પામે છે, ન દ્વેષ કરે છે, ન શોક કરે છે, ન કશાયની કામના કરે છે તથા જે શુભ અને અશુભ સઘળાં કર્મોનો ત્યાગી છે - એ ભક્તિયુક્ત માણસ મને પ્રિય છે. 12.17",

    "જે શત્રુ કે મિત્રમાં અને માન કે અપમાનમાં સમ છે તથા ઠંડી, ગરમી અને સુખ-દુઃખ આદિ દ્વંદ્વોમાં સમ છે અને આસક્તિ વિનાનો છે, 12.18",

    "જે નિંદા-સ્તુતિને સમાન સમજનાર, મનનશીલ અને ગમે તે પ્રકારે પણ શરીરનો નિર્વાહ થતાં સદા સંતુષ્ટ છે થતા રહેવાના સ્થાનમાં મમતા અને આસક્તિ વિનાનો છે- એ સ્થિરબુધ્ધિનો ભક્તિમાન માણસ મને પ્રિય છે. 12.19",

    "પરંતુ જે શ્રદ્ધાળુ માણસો મારે પરાયણ થઈને ઉપર કહેલા ધર્મમય અમૃતને નિષ્કામ પ્રેમભાવે સેવે છે, એ ભક્તો મને સૌથી પ્રિય છે. 12.20",

    "આ રીતે ૐ, તત્‌, સત્‌-એ ભગવાનના નામોના ઉચ્ચારણ સાથે બ્રહ્મવિધા અને યોગશાસ્ત્રમય શ્રીમદ્ભગવદ્‌ગીતોપનિષદરૂપી શ્રીકૃષ્ણાર્જુનસંવાદમાં \"ભક્તિયોગ\" નામનો બારમો અધ્યાય પૂરો થયો. ॥૧૨॥"
  ],
  "adhyaya-13" : [
    "શ્રી ભગવાન બોલ્યા : હે કૌન્તેય ! આ શરીર 'ક્ષેત્ર' કહેવાય છે અને એને જે જાણે છે તે 'ક્ષેત્રજ્ઞ' જીવાત્મા નામથી ઓળખાય છે, આવું આ બન્નેના તત્ત્વને જાણનારા જ્ઞાનીજનોએ કહ્યું છે. 13.1",

    "હે ભારત! બધાં ક્ષેત્રોમાં ક્ષેત્રજ્ઞ એટલે કે જીવાત્મા પણ તું મને જ જાણ; અને ક્ષેત્ર અર્થાત્ વિકારસહિતની પ્રકૃતિ તથા ક્ષેત્રજ્ઞ અર્થાત્ પુરુષના વિષયનું જે તત્ત્વસહિત જ્ઞાન છે, એ જ ખરું જ્ઞાન છે - એવો મારો મત છે. 13.2",

    "એ ક્ષેત્ર જે સ્વરૂપનું, જે સ્વભાવ ધરાવનારું તથા જે જે વિકારોથી યુક્ત છે અને જે કારણથી એ ઉદ્ભવ્યું છે તથા એ ક્ષેત્રજ્ઞ પણ જે સ્વરૂપનો અને જે પ્રભાવ ધરાવનારો છે - એ સઘળું તું ટૂંકમાં મારી પાસેથી સાંભળ. 13.3",

    "ક્ષેત્ર અને ક્ષેત્રજ્ઞનું આ તત્ત્વ ઋષિઓ દ્વારા અનેક પ્રકારે કહેવાયું છે, વિવિધ વેદમંત્રો દ્વારા પણ વિભાગપૂર્વક કહેવામાં આવ્યું છે તેમજ સારી રીતે નિશ્ચિત કરેલાં યુક્તિપૂર્વકનાં બ્રહ્મસૂત્રનાં પદો દ્વારા પણ કહેવાયું છે. 13.4",

    "પાંચ મહાભૂત, અહંકાર, બુદ્ધિ અને એ જ રીતે મૂળ પ્રકૃતિ પણ; તેમજ દસ ઇન્દ્રિયો, એક મન અને ઇન્દ્રિયોના પાંચ વિષયો - શબ્દ, સ્પર્શ, રૂપ, રસ અને ગન્ધ - 13.5",

    "ઇચ્છા, દ્વેષ, સુખ, દુઃખ, સ્થૂળ શરીર, ચેતના અને ધૃતિ - આટલા વિકારો સહિતનું આ ક્ષેત્ર ટૂંકમાં કહેવાયું છે. 13.6",

    "પોતાને શ્રેષ્ઠ માનવારૂપી અભિમાનનો અભાવ હોવો, દંભાચરણનો અભાવ હોવો, કોઈ પણ પ્રાણીને કોઈ પણ પ્રકારે કષ્ટ ન આપવું, ક્ષમાભાવ, મન-વાણી આદિમાં ઋજુભાવ હોવો, શ્રદ્ધા-ભક્તિ સાથે ગુરુજનોની સેવા, બાહ્ય તેમજ આંતરિક શુદ્ધિ હોવી, અન્તઃકરણની સ્થિરતા હોવી, મન-ઇન્દ્રિયો સહિત શરીરનો નિગ્રહ - 13.7",

    "આ લોક અને પરલોકના સર્વ ભોગોમાં આસક્તિ ન હોવી અને અહંકારનો પણ અભાવ હોવો, જન્મ, મૃત્યુ, જરા અને રોગ આદિમાં દુઃખો અને દોષોને વારંવાર જોવાં - 13.8",

    "પુત્ર, સ્ત્રી, ઘર અને ધન આદિમાં આસક્તિનો તેમજ મમતાનો અભાવ તથા પ્રિય અને અપ્રિયની પ્રાપ્તિ થતાં સદાય ચિત્તનું સમ રહેવું - 13.9",

    "મુજ પરમેશ્વરમાં અનન્ય યોગનો આશરો લઈને અવ્યભિચારિણી ભક્તિ હોવી, તથા એકાન્ત અને શુદ્ધ સ્થાનમાં રહેવાનો સ્વભાવ હોવો અને વિષયાસક્ત માણસોના સમુદાયમાં પ્રીતિ ન હોવી - 13.10",

    "અધ્યાત્મજ્ઞાનમાં નિત્ય સ્થિત રહેવું અને તત્ત્વજ્ઞાન વડે જેનું પ્રતિપાદન કરવામાં આવ્યું છે, એ પરમાત્માને જ જોતાં રહેવું - આ બધું જ્ઞાન છે; અને જે આ બધાથી અવળું છે, એ અજ્ઞાન કહેવાયું છે. 13.11",

    "જે જાણવાયોગ્ય તત્ત્વ છે તથા જેને જાણીને માણસ પરમાનંદને પ્રાપ્ત થાય છે, એને સમ્યક્ રીતે કહું છું, પ્રકૃતિ અને જીવાત્મા - એ બે અનાદિઓનો સ્વામી પરમ બ્રહ્મ નથી સત્ કહેવાતો કે નથી અસત્. 13.12",

    "એ સર્વ તરફ હાથ-પગ ધરાવનાર, સર્વ તરફ આંખ, મસ્તક અને મુખ ધરાવનાર અને સર્વ તરફ કાન ધરાવનાર છે કેમકે સંસારમાં એ સૌને વ્યાપીને સ્થિત છે. 13.13",

    "એ સઘળી ઇન્દ્રિયોના વિષયોને જાણનારો છે, છતાં વાસ્તવમાં સઘળી ઇન્દ્રિયો વિનાનો છે તથા આસક્તિ વિનાનો હોવા છતાં પણ સર્વનું ધારણ-પોષણ કરનારો અને નિર્ગુણ હોવા છતાં પણ ગુણોને ભોગવનારો છે. 13.14",

    "એ ચરાચર સર્વભૂતોની અંદર-બહાર પૂર્ણરૂપે વ્યાપેલો છે, તેમજ ચર-અચરરૂપે પણ એ જ છે અને એ સૂક્ષ્મ હોવાને લીધે જાણી શકાતો નથી તથા એકદમ પાસે તેમજ ઘણો દૂર પણ રહેલો એ જ છે. 13.15",

    "એ પરમાત્મા, અવિભાજિત એકરૂપે રહેલા આકાશની જેમ, પરિપૂર્ણ હોવા છતાં પણ ચરાચર બધાં ભૂતોમાં જાણે વિભાજિત હોય એમ સ્થિત જણાય છે તથા એ જાણવાયોગ્ય પરમાત્મા વિષ્ણુરૂપે ભૂતોનું ધારણ-પોષણ કરનારો, રુદ્રરૂપે સંહાર કરનારો, તેમજ બ્રહ્મારૂપે સર્વને ઉત્પન્ન કરનારો છે. 13.16",

    "એ પરબ્રહ્મ આ જ્યોતિઓનો પણ જ્યોતિ તેમજ માયાથી સાવ પર કહેવાય છે; એ પરમાત્મા બોધસ્વરૂપ, જાણવાયોગ્ય તેમજ તત્ત્વજ્ઞાનથી પામવાયોગ્ય છે અને સૌના હૃદયમાં વિશેષરૂપે રહેલો છે. 13.17",

    "આ પ્રમાણે ક્ષેત્ર, જ્ઞાન તેમજ જાણવાયોગ્ય પરમાત્માનું સ્વરૂપ ટૂંકમાં કહ્યું; મારો ભક્ત એને તત્ત્વથી જાણીને મારા સ્વરૂપને પામે છે. 13.18",

    "મૂળ પ્રકૃતિ અને પુરુષ - એ બેયને તું અનાદિ જાણ અને રાગ-દ્વેષ આદિ વિકારોને તથા ત્રિગુણાત્મક બધાય પદાર્થોને પણ પ્રકૃતિથી ઉદ્ભવેલા જાણ. 13.19",

    "કાર્યો અને કરણોને ઉત્પન્ન કરવામાં પ્રકૃતિ કારણ કહેવાય છે અને સુખ-દુઃખોનો અનુભવ થવામાં જીવાત્મા કારણ કહેવાય છે. 13.20",

    "પ્રકૃતિમાં રહીને જ પુરુષ પ્રકૃતિમાંથી જન્મેલા ત્રિગુણાત્મક પદાર્થોને અનુભવે છે અને આ ગુણોનો સંગ જ આ જીવાત્માના સારી-નરસી યોનિઓમાં જન્મ થવામાં કારણ બને છે. 13.21",

    "આ દેહમાં રહેલો હોવા છતાં, આ જીવાત્મા વાસ્તવમાં પરમાત્મા જ છે, એ જ સાક્ષી હોવાને લીધે ઉપદ્રષ્ટા અને ખરી સંમતિ આપનારો હોવાને લીધે અનુમંતા, સૌનું ધારણ-પોષણ કરનારો હોવાને લીધે ભર્તા, જીવરૂપે ભોક્તા, બ્રહ્મા આદિનો પણ સ્વામી હોવાનો લીધે મહેશ્વર અને શુદ્ધ સચ્ચિદાનંદઘન હોવાથી પરમાત્મા - એમ કહેવાયો છે. 13.22",

    "આ રીતે પુરુષને અને ગુણોની સાથે પ્રકૃતિને, જે માણસ તત્ત્વથી જાણે છે, એ સર્વ રીતે કર્તવ્ય કર્મ કરતો હોવા છતાં પણ ફરી નથી જન્મ પામતો. 13.23",

    "એ પરમાત્માને, કેટલાક માણસો શુદ્ધ થયેલી સૂક્ષ્મ બુદ્ધિ વડે ધ્યાન દ્વારા હૃદયમાં જુએ છે, તો બીજા કેટલાક જ્ઞાનયોગ દ્વારા અને વળી બીજા કેટલાક કર્મયોગ દ્વારા જુએ છે એટલે કે પામે છે. 13.24",

    "પરંતુ આ બધા કરતાં અન્ય પ્રકારના અર્થાત્ મંદબુદ્ધિના માણસો, આ પ્રમાણે ન જાણતા હોવાને લીધે બીજા માણસો પાસેથી એટલે કે તત્ત્વજ્ઞાનીઓ પાસેથી સાંભળીને એ મુજબ ઉપાસના કરે છે અને એ શ્રવણ-પરાયણ પુરુષો પણ મૃત્યુરૂપી સંસારસાગરને નિઃસંદેહ તરી જાય છે. 13.25",

    "હે ભરતશ્રેષ્ઠ! યાવન્માત્ર જેટલાં પણ સ્થાવર-જંગમ પ્રાણીઓ જન્મે છે, એ બધાંયને તું ક્ષેત્ર અને ક્ષેત્રજ્ઞના સંયોગથી જ ઉદ્ભવેલાં જાણ. 13.26",

    "જે માણસ નષ્ટ થઈ રહેલાં સઘળાં ચરાચર ભૂતોમાં પરમેશ્વરને નષ્ટ ન થતા, તેમજ સમભાવે રહેલા જુએ છે, એ જ ખરું જુએ છે. 13.27",

    "કેમકે સર્વમાં સમભાવે રહેલા પરમેશ્વરને સમસ્વરૂપે જોતો માણસ પોતાના વડે પોતાને નષ્ટ નથી કરતો, માટે એ પરમ ગતિને પામે છે. 13.28",

    "અને જે માણસ બધાંય કર્મોને સર્વ રીતે પ્રકૃતિ વડે જ થનારાં જુએ છે તથા આત્માને અકર્તા જુએ છે, એ જ ખરું જુએ છે. 13.29",

    "જે ક્ષણે, આ માણસ પ્રાણીઓનાં જાતજાતનાં સ્વરૂપોને એક પરમાત્મામાં જ રહેલાં તથા એ પરમાત્માથી જ સઘળાં પ્રાણીઓનો વિસ્તાર જુએ છે, એ જ ક્ષણે એ સચ્ચિદાનંદઘન બ્રહ્મને પામી જાય છે. 13.30",

    "હે કૌન્તેય! અનાદિ હોવાને લીધે તથા નિર્ગુણ હોવાને લીધે આ અવિનાશી પરમાત્મા, શરીરમાં રહેલો હોવા છતાં પણ, ખરી રીતે ન તો કશું કરે છે કે ન તો લેપાય છે. 13.31",

    "જેમ સર્વ સ્થળે વ્યાપેલું આકાશ સૂક્ષ્મ હોવાને લીધે સ્થાનોના ગુણ-દોષોથી લેપાતું નથી, એ જ રીતે દેહમાં સર્વત્ર રહેલો આત્મા નિર્ગુણ હોવાને લીધે દેહના ગુણોથી લેપાતો નથી. 13.32",

    "હે ભારત! જેવી રીતે એક જ સૂર્ય આ આખાય બ્રહ્માંડને ઉજાળે છે, એ જ રીતે એક જ આત્મા આખાય ક્ષેત્રને ઉજાળે છે. 13.33",

    "આ પ્રમાણે ક્ષેત્ર અને ક્ષેત્રજ્ઞના ભેદને તથા કાર્ય-સહિત પ્રકૃતિથી મુક્ત થવાને, જે માણસો જ્ઞાનચક્ષુ દ્વારા તત્ત્વથી જાણી લે છે, એ મહાત્માજનો પરમ બ્રહ્મ પરમાત્માને પ્રાપ્ત થઈ જાય છે. 13.34",

    "આ રીતે ૐ, તત્‌, સત્‌-એ ભગવાનના નામોના ઉચ્ચારણ સાથે બ્રહ્મવિધા અને યોગશાસ્ત્રમય શ્રીમદ્ભગવદ્‌ગીતોપનિષદરૂપી શ્રીકૃષ્ણાર્જુનસંવાદમાં \"ક્ષેત્રક્ષેત્રજ્ઞવિભાગયોગ\" નામનો તેરમો અધ્યાય પૂરો થયો. ॥૧૩॥"
  ],
  "adhyaya-14" : [
    "સર્વ જ્ઞાનો કરતાં ચઢિયાતા ઘણા ઉત્કૃષ્ટ એવા જ્ઞાનને હું ફરી કહું છું, જેને જાણીને સઘળા મુનિજનો આ સંસારથી છૂટીને પરમ સિદ્ધિને પામી ચૂક્યા છે. 14.1",

    "આ જ્ઞાનને આશ્રયે મારા સ્વરૂપને પામી ચૂકેલા માણસો, સૃષ્ટિના આરંભે ફરીથી નથી જન્મતા કે પ્રલયકાળેય નથી વ્યાકુળ થતા. 14.2",

    "હે ભારત! મારી મહદ્-બ્રહ્મ સ્વરૂપ મૂળ પ્રકૃતિ સકળ ભૂતોની યોનિ છે, અર્થાત્ ગર્ભધારણનો આધાર છે અને હું યોનિમાં ચેતન સમુદાયરૂપી ગર્ભને સ્થાપું છું; એ જડ-ચેતનાના સંયોગમાંથી સકળ ભૂતોનો ઉદ્ભવ થાય છે. 14.3",

    "હે કુંતીપુત્ર! વિવિધ જાતિની સઘળી યોનિઓમાં જે મૂર્તિઓ - શરીરધારી પ્રાણીઓ ઉદભવે છે પ્રકૃતિ એ સર્વની ગર્ભ ધારણ કરનારી માતા છે અને હું બીજને સ્થાપનાર પિતા છું. 14.4",

    "હે મહાબહો! સત્ત્વગુણ, રજોગુણ તેમજ તમોગુણ પ્રકૃતિમાંથી ઉદ્ભવેલા આ ત્રણે ગુણો અવિનાશી જીવાત્માને શરીરમાં બાંધે છે. 14.5",

    "હે નિષ્પાપ! એ ત્રણે ગુણો માં સત્ત્વગુણ નિર્મળ હોવાને કારણે પ્રકાશક અર્થાત તેજ પ્રગટાવનાર અને વિકાર વિનાનો છે; છતાં એ સુખના સંગ વડે એટલે કે સુખોના અભિમાન વડે અને જ્ઞાનના સંગ વડે એટલે કે જ્ઞાનના અભિમાન વડે જીવાત્માને બાંધે છે. 14.6",

    "હે કૌન્તેય! રાગસ્વરૂપના રજોગુણને અને તું લાલસા અને આસક્તિમાંથી ઉદ્ભવેલો જાણ; એ આ જીવાત્માને કર્મના તથા એમના ફળના વડે બાંધે છે. 14.7",

    "હે ભરતવંશી! સૌ દેહાભિમાનીઓને મોહિત કરનાર તમોગુણને તો તું અજ્ઞાનમાંથી ઉપજેલો જાણ; એ આ જીવાત્માને પ્રમાદ, આળસ અને નિદ્રા વડે બાંધે છે. 14.8",

    "હે ભારત! સત્ત્વગુણ સુખમાં જોડે છે, રજોગુણ કર્મમાં અને વળી તમોગુણ તો જ્ઞાનને ઢાંકી દઈને પ્રમાદમાં પણ જોડે છે. 14.9",

    "હે ભારત! રજોગુણ અને તમોગુણ અને દબાવીને સત્ત્વગુણ, સત્ત્વગુણ અને તમોગુણને દબાવીને રજોગુણ, તેમજ સત્ત્વગુણ રજોગુણ અને તમોગુણ વૃદ્ધિ પામે છે. 14.10",

    "જે વખતે આ દેહમાં તથા અંતઃકરણ અને ઇન્દ્રિયોમાં ચેતનતા અને વિવેકશક્તિ ઉપજે છે, એ વખતે એમ સમજવું કે સત્ત્વગુણ વધ્યો છે. 14.11",

    "હે ભરતવંશીઓમાં શ્રેષ્ઠ! રજોગુણ વધતાં લોભ, પ્રવૃત્તિ, કર્મોને સ્વાર્થબુધ્ધિથી તેમજ સકામભાવે કરવાં, અશાંતિ અને વિષયની લાલસા- આ સઘળું જન્મે છે. 14.12",

    "હે કુરુનંદન! તમોગુણ વધતાં અંતઃકરણ અને ઇન્દ્રિયોમાં નિસ્તેજપણું, ચેતનતાનો અભાવ, કર્તવ્યકર્મમાં પ્રવૃત્ત ન થવું, પ્રમાદ અને નિદ્રા જેવી અંતઃકરણની મોહમાં નાંખનારી વૃત્તિઓ - આ સઘળાંય જન્મે છે. 14.13",

    "જ્યારે સત્ત્વગુણ વધ્યો હોય ત્યારે આ માણસ મૃત્યુ પામે છે, તો ઉત્તમ કર્મ કરનારાઓના નિર્મળ દિવ્ય સ્વર્ગ આદિ લોકોને પામે છે. 14.14",

    "અને રજોગુણ વધતાં મૃત્યુ પામીને કર્મસંગીઓમાં એટલે કે કર્મમાં આસક્તિ રાખનાર માણસોમાં જન્મે છે તથા તમોગુણ વધ્યો હોય ત્યારે મરણ પામેલા માણસ જંતુ, પશુ જેવી મૂઢયોનિમાં જન્મે છે. 14.15",

    "શ્રેષ્ઠ કર્મોનું સાત્વિક એટલે કે સુખ, જ્ઞાન, વૈરાગ્ય આદિ નિર્મળ ફળ તત્ત્વજ્ઞાનઓએ કહ્યું છે; જ્યારે રાજસ કર્મનું ફળ દુઃખ તથા તામસ કર્મનું ફળ અજ્ઞાન કહ્યું છે. 14.16",

    "સત્ત્વગુણ માંથી જ્ઞાન જન્મે છે અને રજોગુણમાંથી નિ:શંકપણે લોભ તથા તમોગુણ માંથી પ્રમાદ અને મોહ જન્મે છે અને વળી અજ્ઞાન પણ જન્મે છે. 14.17",

    "સત્ત્વગુણમાં સ્થિત માણસો સ્વર્ગ આદિ ઉચ્ચ લોકોને પામે છે, રજોગુણમાં સ્થિત રાજસી માણસો મધ્યમાં એટલે કે મનુષ્ય લોકમાં જ રહે છે અને તમોગુણના કાર્ય એવા નિંદ્રા, પ્રમાદ, આળસ આ આદિમાં સ્થિત તામસી માણસો અધોગતિ ને એટલે કે જંતુ, પશુ, આદિ નીચ યોનીઓને તેમજ નરકોને પામે છે. 14.18",

    "જે કાળે સાક્ષીરૂપ રહેલો દ્રષ્ટા ત્રણ ગુણો સિવાય બીજા કોઈને કર્તારૂપે નથી જોતો અને ત્રણે ગુણોથી સાવ પર એવા સચ્ચિદાનંદઘન - સ્વરૂપ મુજ પરમાત્માને તત્ત્વથી જાણે છે, એ વખતે એ મારા સ્વરૂપને પામે છે. 14.19",

    "શરીરની ઉત્પત્તિના કારણ એવા આ ત્રણે ગુણોને પાર કરીને જન્મ, મૃત્યુ, વૃદ્ધાવસ્થાને સર્વ પ્રકારનાં દુઃખોથી છૂટેલો આ પુરુષ પરમાનંદને પામે છે. 14.20",

    "અર્જુન બોલ્યા: આ ત્રણેય ગુણોથી અતીત પુરુષ કયાં કયાં લક્ષણોથી યુક્ત હોય છે અને કેવું આચરણ કરનાર હોય છે તથા હે પ્રભુ! માણસ કયા ઉપાયથી આ ત્રણેય ગુણોથી અતીત થઈ શકે છે? 14.21",

    "શ્રીભગવાન બોલ્યા: હે પાંડવ! સત્ત્વગુણના કાર્ય એવા પ્રકાશનો, રજોગુણના કાર્યસ્વરૂપ પ્રવૃત્તિનો તથા તમોગુણના કાર્ય એવા મોહનો પણ જે પુરુષ નથી તો એમના પ્રવૃત્ત થતાં દ્વેષ કરતો કે નથી તો નિવૃત થતાં એમની આકાંક્ષા સેવતો. 14.22",

    "જે સાક્ષીની પેઠે સ્થિત થયેલો ગુણો વડે વિચલિત કરી શકાતો નથી તથા 'ગુણો જ જ ગુણોમાં વર્તે છે' એમ સમજીને સચ્ચિદાનંદઘન પરમાત્મામાં એકાત્મક ભાવે સ્થિત રહે છે તેમજ તે સ્થિતિથી કદી વિચલિત નથી થતો. 14.23",

    "જે નિરંતર આત્મભાવમાં સ્થિત છે, સુખ દુઃખને સમાન સમજે છે, માટી,પથરા તેમજ સોનામાં સમાન ભાવ રાખે છે, જ્ઞાની છે, પ્રિય અને અપ્રિયને એક જેવાં માને છે તથા પોતાની નિંદા કે સ્તુતિમાં પણ સમાન ભાવ રાખે છે. 14.24",

    "જે માન અને અપમાનમાં સમ છે, મિત્ર અને વેરી પક્ષમાં પણ સમ છે તેમજ સર્વ કર્મોમાં કર્તાપણાનું અભિમાન વિનાનો છે, એ પુરુષ ગુણાતીત કહેવાય છે. 14.25",

    "જે પુરુષ અવ્યભિચારી ભક્તિયોગથી મને નિરંતર ભજે છે, એ પણ આ ત્રણેય ગુણોને સમ્યક્ રીતે પાર કરીને સચ્ચિદાનંદઘન બ્રહ્મને પામવા યોગ્ય બની જાય છે. 14.26",

    "કેમકે, એ અવિનાશી પરબ્રહ્મનું, અમૃતનું, શાશ્વત ધર્મનું તેમજ અખંડ એક રસ આનંદનું રહેઠાણ હું છું. 14.27",

    "આ રીતે ૐ, તત્‌, સત્‌-એ ભગવાનના નામોના ઉચ્ચારણ સાથે બ્રહ્મવિધા અને યોગશાસ્ત્રમય શ્રીમદ્ભગવદ્‌ગીતોપનિષદરૂપી શ્રીકૃષ્ણાર્જુનસંવાદમાં \"ગુણત્રયવિભાગયોગ\" નામનો ચૌદમો અધ્યાય પૂરો થયો. ॥૧૪॥"
  ],
  "adhyaya-15" : [
    "જેનું આદિપુરુષ પરમેશ્વરરૂપી ઊર્ધ્વ - મૂળ છે, જેની નીચે રહેલી બ્રહ્મારૂપી મુખ્ય શાખા છે તથા વેદો જેના પાંદડાઓ છે, એવા આ સંસારરૂપી અશ્વત્થ વૃક્ષને તત્ત્વજ્ઞાનીઓ અવિનાશી કહે છે; જે માણસ એ સંસારીરૂપી વૃક્ષને મૂળસહિત તત્ત્વથી જાણે છે, એ વેદના તાત્પર્યને જાણનાર છે. 15.1",

    "એ સંસારવૃક્ષની ત્રણેય ગુણોરૂપી જળ વડે વૃદ્ધિ પામેલી અને વિષયોરૂપી કૂંપળો ધરાવતી દેવ, મનુષ્ય, તિર્યક્ આદિ યોનીઓ રૂપી શાખાઓ નીચે અને ઉપર આમ તેમ ફેલાયેલી છે તેમજ એ શાખાઓનાં મનુષ્યલોકમાં કર્માનુસાર બાંધનારાં અહંતા, મમતા તથા વાસનારૂપી મૂળિયાં નીચે અને ઉપર બધા લોકોમાં ફેલાયેલાં છે. 15.2",

    "આ સંસારવૃક્ષનું સ્વરૂપ જેવું કહ્યું છે, એવું અહીં વિચારકાળમાં મળી આવતું નથી, કેમકે આનો નથી તો આદિ કે નથી અન્ત, તેમજ નથી આની નિશ્ચિત પ્રકારની સ્થિતિ; માટે આ અહંતા, મમતા અને વાસનારૂપી અત્યંત દ્રઢ થયેલાં મૂળ ધરાવતા સંસારરૂપી અશ્વત્થવૃક્ષને દ્રઢ વૈરાગ્યરૂપી શસ્ત્ર વડે કાપી નાખીને, 15.3",

    "એ પછી પરમપદસ્વરૂપ પરમેશ્વરને સારી પેઠે શોધવા જોઈએ કે જેને પામી ચુકેલા માણસો ફરી પાછા વળીને સંસારમાં નથી આવતા અને જે પરમેશ્વરથી આ પુરાતન સંસાર - વૃક્ષની પ્રવૃત્તિ અર્થાત્ પરંપરા વિસ્તાર પામી છે; એ જ આદિપુરુષ નારાયણને હું શરણે છું - આ પ્રમાણે દ્રઢ નિશ્ચય કરીને પરમેશ્વરનું મનન અને નિદિધ્યાસન કરવું. 15.4",

    "જેમના માન અને મોહ નષ્ટ થઈ ચૂક્યાં છે, જેમણે આસક્તિરૂપી દોષોને જીતી લીધા છે, જેમની પરમાત્માના સ્વરૂપમાં નિત્ય સ્થિતિ છે અને જેમની કામનાઓ પૂર્ણરૂપે નાશ પામી ચૂકી છે - એવા સુખ-દુ:ખરૂપી દ્વન્દ્વોથી છૂટેલા જ્ઞાનીજનો એ અવિનાશી પરમપદને પામે છે. 15.5",

    "જે પરમપદને પામીને મનુષ્યો પાછા ફરીને સંસારમાં આવતા નથી, એ સ્વયંપ્રકાશિત પરમપદને ન તો સૂર્ય ઉજાળી શકે છે, ન ચંદ્રમા કે ન અગ્નિ; એ જ મારું પરમધામ છે. 15.6",

    "એ દેહમાં રહેલો સનાતન જીવાત્મા મારો જ અંશ છે અને એ જ આ પ્રકૃતિમાં રહેલી મનસહિતની પાંચેય ઈન્દ્રિયોને ખેંચે છે. 15.7",

    "વાયુ ગંધના સ્થાનેથી ગંધને જે રીતે ગ્રહણ કરીને બીજા સ્થાને લઈ જાય છે, એ જ રીતે દેહ આદિનો સ્વામી જીવાત્મા પણ જે શરીરને છોડે છે, એ શરીરમાંથી પ્રાણો દ્વારા મન સમેત ઇન્દ્રિયોને લઈને, પછી જે શરીરને પામે છે, એમાં જાય છે. 15.8",

    "આ જીવાત્મા શ્રોત્ર, ચક્ષુ, ત્વચા, રસ, ધ્રાણ અને મનને આશ્રયે એટલે કે એની સહાય લઈને જ વિષયોને સેવે છે. 15.9",

    "શરીર છોડીને જતો, શરીરમાં સ્થિત રહેલો, વિષયોને ભોગવતો કે ત્રણેય ગુણોથી જોડાયેલો હોવા છતાંય, જીવાત્માના યથાર્થ સ્વરૂપને અજ્ઞાનીજનો નથી જાણતા, કેવળ જ્ઞાનચક્ષુ ધરાવનાર વિવેકશીલ જ્ઞાનીજનો તેને તત્ત્વથી જાણે છે. 15.10",

    "યત્ન કરનારા યોગી જનો પણ પોતાના હૃદયમાં સ્થિત આ આત્માને તત્ત્વથી ઓળખી શકે છે; પરંતુ જેમણે અંતઃકરણ શુદ્ધ નથી કર્યું, એવા અજ્ઞાનીજનો તો યત્ન કરતા રહેવા છતાં પણ આ આત્માને નથી જાણી શકતા. 15.11",

    "સૂર્યમાં રહેલું જે તેજ આખા જગતને ઉજાળે છે, જે તે ચંદ્રમામાં છે અને જે અગ્નિમાં છે - એને તું મારું જ તેજ જાણ. 15.12",

    "તથા હું જ પૃથ્વીમાં પ્રવેશીને પોતાની શક્તિથી સઘળાં ભૂતોને ધારણ કરું છું અને રસસ્વરૂપ એટલે કે અમૃતમય ચંદ્રમા બનીને સઘળી વનસ્પતિઓને પોષણ આપું છું. 15.13",

    "સર્વ પ્રાણીઓનાં શરીરમાં સ્થિત રહેલો હું જ પ્રાણ અને અપાનથી સંયોજાયેલ વૈશ્વાનર અગ્નિ સ્વરૂપ થઈને ચાર પ્રકારનાં અન્નને પચાવું છું. 15.14",

    "હું જ સૌ પ્રાણીઓના હૃદયમાં અંતર્યામી રૂપે રહેલો છું તથા મારાથી જ સ્મૃતિ જ્ઞાન તેમજ અપોહન થાય છે, તથા બધા વેદો વડે હું જ જાણવા યોગ્ય છું તેમજ વેદાંતનો કર્તા અને વેદોને જાણનાર પણ હું જ છું. 15.15",

    "આ સંસારમાં નાશવંત અને અવિનાશી - એ બે પ્રકારના પુરુષો છે; એમાંથી સઘળા ભૂતપ્રાણીઓનાં શરીરો નાશવંત જીવાત્મા અવિનાશી કહેવાય છે. 15.16",

    "આ બેયથી ઉત્તમપુરુષ તો જુદો જ છે, જે ત્રણેય લોકોમાં પ્રવેશીને સૌનું ધારણ - પોષણ કરે છે અને જેને અવિનાશી, પરમેશ્વર તેમજ પરમાત્મા એવાં નામોથી ઓળખાવ્યો છે. 15.17",

    "હું નાશવંત જડસમુદાય ક્ષેત્રથી સર્વ રીતે અતીત છું અને અવિનાશી જીવાત્માથી પણ ઉત્તમ છું, માટે લોકમાં તેમજ વેદોમાં પુરુષોત્તમ નામે પ્રસિદ્ધ છું. 15.18",

    "હે ભરતવંશી! જે જ્ઞાની માણસ મને આ રીતે તત્ત્વથી પુરુષોત્તમસ્વરૂપે જાણેછે, એ સર્વજ્ઞ માણસ સર્વ રીતે નિરંતર મુજ વાસુદેવ પરમેશ્વરને જ ભજે છે. 15.19",

    "હે નિષ્પાપ અર્જુન! આમ આ ઘણું રહસ્યમય ગોપનીય શાસ્ત્ર મેં કહ્યું, આને તત્ત્વથી જ જાણીને માણસ જ્ઞાની અને કૃતકૃત્ય થઈ જાય છે. 15.20",

    "આ રીતે ૐ, તત્‌, સત્‌-એ ભગવાનના નામોના ઉચ્ચારણ સાથે બ્રહ્મવિધા અને યોગશાસ્ત્રમય શ્રીમદ્ભગવદ્‌ગીતોપનિષદરૂપી શ્રીકૃષ્ણાર્જુનસંવાદમાં \"પુરુષોત્તમયોગ\" નામનો પંદરમો અધ્યાય પૂરો થયો. ॥૧૫॥"
  ],
  "adhyaya-16" : [
    "શ્રીભગવાન બોલ્યા : ભયનો સંપૂર્ણપણે અભાવ હોવો, અન્તઃકરણ પૂર્ણરૂપે નિર્મળ હોવું, તત્ત્વજ્ઞાનને અર્થે ધ્યાનયોગમાં નિરંતર દૃઢ સ્થિતિ હોવી, સાત્ત્વિક દાન કરવું, ઇન્દ્રિયોને પોતાના વશમાં રાખવી, ભગવાન, દેવતા અને વડીલોની પૂજા કરવી તેમજ અગ્નિહોત્ર આદિ ઉત્તમ કર્મોનું આચરણ કરવું, વેદ-શાસ્ત્રોનું પઠન પાઠન અને ભગવાનનાં નામ તેમજ ગુણોનું કીર્તન કરવું, સ્વધર્મ-પાલન અર્થે કષ્ટ સહેવું તથા શરીર અને ઇન્દ્રિયોસહિત અન્તઃકરણમાં ઋજુભાવ હોવો - 16.1",

    "મન, વાણી અને શરીરથી કોઈ પણ પ્રકારનું કોઈનેય કષ્ટ ન આપવું, યથાર્થ અને પ્રિય બોલવું, પોતાના પર અપકાર કરનાર ઉપર પણ ક્રોધ ન હોવો, કર્મોમાં કર્તાપણાના અભિમાનનો ત્યાગ, અન્તઃકરણની ઉપરતિ એટલે કે ચિત્તની ચંચળતા ન હોવી, કોઈનાંય નિંદા આદિ ન કરવાં, સર્વ ભૂત-પ્રાણીઓ પર કશાય હેતુ વિના દયાભાવ રાખવો, ઇન્દ્રિયોનો વિષયો સાથે સંયોગ થવા છતાં પણ વિષયો પ્રત્યે આસક્તિ ન હોવી, મૃદુ સ્વભાવ હોવો, લોક અને શાસ્ત્રથી વિરુદ્ધ આચરવામાં લજ્જા થવી તથા વ્યર્થ ચેષ્ટાઓ ન થવી - 16.2",

    "તેજ, ક્ષમા, ધૈર્ય, બાહ્યશુદ્ધિ, કોઈના પ્રત્યે શત્રુભાવ ન હોવો, પોતાનામાં પૂજ્યતાનું અભિમાન ન હોવું - આ સઘળાં હે ભારત! દૈવી સંપદાને લઈને જન્મેલા માણસનાં લક્ષણો છે. 16.3",

    "હે પાર્થ! દંભ, ઘમંડ, અભિમાન, ક્રોધ, કઠોરતા અને વળી અજ્ઞાન - આ સઘળાં આસુરી સંપદાને લઈને જન્મેલા માણસનાં લક્ષણો છે. 16.4",

    "દૈવી સંપદા મુક્તિ માટે અને આસુરી સંપદા બાંધવા માટે મનાઈ છે; તું દૈવી સંપદાને લઈને જન્મ્યો છે, માટે હે પાર્થ! તું શોક કર મા. 16.5",

    "હે પાર્થ! આ લોકમાં ભૂતોની સૃષ્ટિ એટલે કે મનુષ્યસમુદાય બે જ પ્રકારનો છે : એક દૈવી પ્રકૃતિનો અને બીજો આસુરી પ્રકૃતિનો; એમાંથી દૈવી પ્રકૃતિના સમુદાય વિષે તો વિસ્તારથી કહ્યું, હવે તું આસુરી પ્રકૃતિના મનુષ્યસમુદાય વિષે પણ વિસ્તારપૂર્વક મારી પાસેથી સાંભળ. 16.6",

    "આસુરી સ્વભાવના માણસો પ્રવૃત્તિ અને નિવૃત્તિ - આ બેયને નથી જાણતા; માટે એમનામાં નથી તો બાહ્ય-આંતરિક શુદ્ધિ હોતી, નથી શ્રેષ્ઠ આચરણ હોતું કે નથી સત્યભાષણ પણ હોતું. 16.7",

    "આસુરી પ્રકૃતિના એ માણસો કહ્યા કરે છે કે જગત્ આશ્રય વિનાનું, સાવ અસત્ય અને ઈશ્વર વિના આપમેળે માત્ર સ્ત્રી-પુરુષના સંયોગથી ઉદ્ભવ્યું છે, માટે કેવળ કામ જ એનું કારણ છે; એ કામ સિવાય બીજું શું છે ? 16.8",

    "આ મિથ્યા દૃષ્ટિને આધારે નાશ પામેલા સ્વભાવના, મંદબુદ્ધિના, સૌનો અપકાર કરનારા, ક્રૂરકર્મી માણસો માત્ર જગતનો નાશ કરવા માટે જ સમર્થ હોય છે. 16.9",

    "દંભ, માન અને મદથી ભરેલા એ માણસો કોઈ પણ રીતે પૂરી ન થનારી કામનાઓનો આશરો લઈને, અજ્ઞાનને લીધે મિથ્યા સિદ્ધાંતોને પકડીને તેમજ ભ્રષ્ટ આચરણો ધારણ કરીને સંસારમાં વિચરે છે. 16.10",

    "તથા તેઓ મૃત્યુપર્યન્ત રહેનારી અસંખ્ય ચિંતાઓનો આશરો લેનારા, વિષયોને ભોગવવામાં તત્પર રહેનારા અને 'આટલું જ સુખ છે' એમ માનનારા હોય છે. 16.11",

    "આશાના સેંકડો ફંદાઓથી બંધાયેલા એ માણસો કામ-ક્રોધને પરાયણ થઈને વિષયભોગોને અર્થે અન્યાયથી ધન આદિ પદાર્થોને ભેગા કરવાના પ્રયત્નો કર્યા કરે છે. 16.12",

    "તેઓ વિચાર્યા કરે છે કે મેં આજે આ મેળવી લીધું અને હવે આ મનોરથને પાર પાડીશ, મારી પાસે આટલું ધન છે અને હજી પણ આ થઈ જશે. 16.13",

    "પેલો શત્રુ તો મારા વડે હણાયો અને પેલા બીજા શત્રુઓને પણ હું હણી નાખીશ, હું ઈશ્વર છું, ઐશ્વર્યને ભોગવનારો છું, હું સઘળી સિદ્ધિઓથી યુક્ત છું તથા બળવાન અને સુખી છું. 16.14",

    "હું ઘણો ધનવાન છું અને મારું આટલું મોટું કુટુંબ છે, મારા જેવો બીજો કોણ છે ? હું યજ્ઞ કરીશ, દાન દઈશ અને મોજ-મજા કરીશ – આમ અજ્ઞાનને કારણે મોહિત રહેનારા, અનેક રીતે ભ્રમિતચિત્ત તથા મોહરૂપી જાળથી વીંટળાયેલા, તેમજ વિષયભોગોમાં રચ્યાપચ્યા રહેનારા આસુરી જનો મહાન અપવિત્ર નરકમાં પડે છે. 16.15-16.16",

    "હું ઘણો ધનવાન છું અને મારું આટલું મોટું કુટુંબ છે, મારા જેવો બીજો કોણ છે ? હું યજ્ઞ કરીશ, દાન દઈશ અને મોજ-મજા કરીશ - આમ અજ્ઞાનને કારણે મોહિત રહેનારા, અનેક રીતે ભ્રમિતચિત્ત તથા મોહરૂપી જાળથી વીંટળાયેલા, તેમજ વિષયભોગોમાં રચ્યાપચ્યા રહેનારા આસુરી જનો મહાન અપવિત્ર નરકમાં પડે છે. 16.15-16.16",

    "એવા પોતે પોતાને જ શ્રેષ્ઠ માનનારા ઘમંડી માણસો ધન અને માનના મદથી ચૂર થઈને કેવળ નામમાત્રના યજ્ઞો વડે ઢોંગ કરવા ખાતર શાસ્ત્રવિધિ કર્યા વિના યજન કરે છે. 16.17",

    "અહંકાર, બળ, ઘમંડ, કામના અને ક્રોધ આદિને પરાયણ રહેનારા, એ સિવાય બીજા અનાચારોમાં રચ્યાપચ્યા તથા બીજાની નિંદા કરનારા એ માણસો પોતાના તેમજ બીજાના શરીરમાં રહેલા મુજ અન્તર્યામીનો જ દ્વેષ કરે છે. 16.18",

    "એ દ્વેષ કરનારા, પાપાચારીઓ અને ક્રૂરકર્મી નરાધમોને હું સંસારમાં વારંવાર આસુરી યોનિઓમાં જ નાખું છું. 16.19",

    "હે કૌન્તેય! એ મૂર્ખાઓ મને પામ્યા વિના જ જનમોજનમ આસુરી યોનિને પામે છે, પછી એનાથીય ઘણી નીચ ગતિને પામે છે એટલે કે ઘોર નરકોમાં પડે છે. 16.20",

    "કામ, ક્રોધ તથા લોભ - આ ત્રણ જાતનાં નરકનાં દ્વાર આત્માનો નાશ કરનારાં એટલે કે એને અધોગતિએ લઈ જનારાં છે, માટે આ ત્રણેયને ત્યજી દેવાં જોઈએ. 16.21",

    "હે કુન્તીપુત્ર! આ ત્રણેય નરકનાં દ્વારોથી છૂટેલો માણસ પોતાનું કલ્યાણ આચરે છે, માટે આ પરમ ગતિને એટલે કે મને પામી જાય છે. 16.22",

    "જે માણસ શાસ્ત્રવિધિને છોડીને પોતાની ઈચ્છા પ્રમાણે મનમાન્યું આચરણ કરે છે, એ ન તો સિદ્ધિને પામે છે, ન પરમ ગતિને કે ન સુખને. 16.23",

    "આથી તારા માટે આ કર્તવ્ય અને અકર્તવ્યની વ્યવસ્થામાં શાસ્ત્ર જ પ્રમાણ છે, એમ જાણીને તારા માટે શાસ્ત્રવિધિ પ્રમાણે નિયત થયેલું કર્મ જ કરવા યોગ્ય છે. 16.24",

    "આ રીતે ૐ, તત્‌, સત્‌-એ ભગવાનના નામોના ઉચ્ચારણ સાથે બ્રહ્મવિધા અને યોગશાસ્ત્રમય શ્રીમદ્ભગવદ્‌ગીતોપનિષદરૂપી શ્રીકૃષ્ણાર્જુનસંવાદમાં \"દૈવાસુરસમ્પદ્વિભાગયોગ\" નામનો સોળમો અધ્યાય પૂરો થયો. ॥૧૬॥"
  ],
  "adhyaya-17" : [
    "અર્જુન બોલ્યા: હે કૃષ્ણ ! જે માણસો શાસ્ત્રવિધિને છોડીને શ્રદ્ધાભાવે જોડાયેલા, દેવ આદિને પૂજે છે, એમની સ્થિતિ તો પછી કઈ ગણાય? સાત્વિકી, રાજસી કે તામસી? 17.1",

    "શ્રીભગવાન બોલ્યા : માણસોની એ શાસ્ત્રીય સંસ્કારો વિનાની કેવળ સ્વભાવમાંથી જન્મેલી શ્રદ્ધા સાત્ત્વિકી, રાજસી અને તામસી - એમ ત્રણ પ્રકારની જ હોય છે, એને તું મારી પાસેથી સાંભળ. 17.2",

    "હે ભરતવંશી ! સૌ માણસોની શ્રદ્ધા એમના અન્તઃકરણને અનુરૂપ હોય છે, આ જીવાત્મા શ્રદ્ધામય છે, માટે જે માણસ જેવી શ્રદ્ધા રાખનારો છે એ પોતે પણ એવા જ સ્વભાવનો છે. 17.3",

    "સાત્ત્વિક માણસો દેવોને પૂજે છે, રાજસી માણસો યક્ષ અને રાક્ષસોને તથા બીજા જે તામસી માણસો છે, તેઓ પ્રેતો અને ભૂતોને પૂજે છે. 17.4",

    "બીજા જે માણસો શાસ્ત્રવિધિ વિનાનું, માત્ર મનમાન્યું ઘોર તપ આચરે છે તથા દંભ અને અહંકારથી ભરેલા તેમજ કામના, આસક્તિ અને બળના અભિમાનથી પણ ભરેલા છે. 17.5",

    "તથા જેઓ શરીરરૂપે રહેલા ભૂતસમુદાયને અને અન્તઃકરણમાં રહેલા મુજ પરમાત્માને પણ કૃશ કરનારા છે, એ અજ્ઞાનીઓને તું આસુરી સ્વભાવના છે. 17.6",

    "ભોજન પણ સૌને પોત-પોતાના સ્વભાવ અનુસાર ત્રણ પ્રકારનું ગમતું હોય છે અને એ જ રીતે યજ્ઞ, તપ અને દાન પણ ત્રણ-ત્રણ પ્રકારનાં હોય છે, એમના આ જુદા-જુદા ભેદને તું મારી પાસેથી સાંભળ. 17.7",

    "આયુષ્ય, બુદ્ધિ, બળ, આરોગ્ય, સુખ અને પ્રીતિ વધારનારા, રસયુક્ત, સ્નિગ્ધ, સ્થિર રહેનારા અને સ્વાભાવિક રીતે જ મનને ગમે એવા આહાર એટલે કે ભોજન કરવાના પદાર્થો સાત્ત્વિક માણસને ગમતા હોય છે. 17.8",

    "કડવા, ખાટા, ખારા, ઘણા ગરમ, તીખા, ભૂંજેલા, દાહ જન્માવનારા અને દુ:ખ, ચિંતા તથા રોગોને જન્માવનારા આહારો, રાજસી માણસને ગમતા હોય છે. 17.9",

    "જે ભોજન અડધું પાકેલું અને અડધું કાચું, સુકાઈ ગયેલા રસનું, સ્વભાવે જ દુર્ગધી, વાસી અને એઠું છે તથા જે અપવિત્ર પણ છે, એ ભોજન તામસી. માણસને ગમતું હોય છે. 17.10",

    "જે શાસ્ત્રવિધિ દ્વારા નિયત થયેલો યજ્ઞ, ‘કરવો એ જ કર્તવ્ય છે' એમ મનમાં નિશ્ચય કરીને ફળની ઈચ્છા વિનાના માણસો વડે કરવામાં આવે છે, એ સાત્ત્વિક છે. 17.11",

    "પણ હે ભરતશ્રેષ્ઠ! કેવળ દંભ આચરવા ખાતર અથવા ફળ પ્રત્યે લક્ષ રાખીને જે યજ્ઞ કરાય છે, એ યજ્ઞને તું રાજસ જાણ. 17.12",

    "શાસ્ત્રવિધિ વિના, અન્નદાન વિના, મંત્રરહિત, વિના દક્ષિણાએ અને શ્રદ્ધાભાવ વિના કરવામાં આવેલા યજ્ઞને તામસ યજ્ઞ કહેવાય છે. 17.13",

    "દેવો, બ્રાહ્મણ, ગુરુજનો અને જ્ઞાનીજનોનું પૂજન, પવિત્રતા, સરળપણું, બ્રહ્મચર્ય અને અહિંસા - આ શરીરનું તપ કહેવાય છે. 17.14",

    "જે ઉદ્વેગ ન જન્માવનારું, પ્રિય અને હિતકારક તેમજ યથાર્થ વચન છે તથા જે વેદશાસ્ત્રોના વાંચનનો અને પરમેશ્વરના નામ-જપનો અભ્યાસ છે - એ જ વાણીનું તપ કહેવાય છે. 17.15",

    "મનની પ્રસન્નતા, શાન્તભાવ, ભગવચ્ચિંતન કરવાનો સ્વભાવ, મનનો નિગ્રહ અને અન્તઃકરણના ભાવોની સારી પેઠે પવિત્રતા - આ પ્રમાણેનું આ મનનું તપ કહેવાય છે. 17.16",

    "ફળની ઈચ્છા વિનાના યોગી જનો દ્વારા પરમ શ્રદ્ધાથી. આચરવામાં આવેલા એ ત્રણ પ્રકારના (કાયિક, વાચિક અને માનસિક) તપને સાત્વિક કહે છે. 17.17",

    "જે તપ સત્કાર, માન અને પૂજા ખાતર તથા બીજા કોઈ સ્વાર્થ ખાતર પાખંડથી આચરવામાં આવે છે, એ અનિશ્ચિત તેમજ ક્ષણિક ફળ આપનારું તપ અહીં રાજસ કહેવાયું છે. 17.18",

    "જે તપ મૂર્ખતાપૂર્વક હઠથી, મન, વાણી અને શરીરની પીડાસહિત અથવા બીજાનું અનિષ્ટ કરવા માટે આચરવામાં આવે છે, એ તપ તામસ કહેવાયું છે. 17.19",

    "દાન આપવું એ જ કર્તવ્ય છે' એવા ભાવથી જે દાન યોગ્ય દેશ, યોગ્ય કાળ અને યોગ્ય પાત્ર પ્રાપ્ત થતાં, પોતાના પર જેણે ઉપકાર નથી કર્યો, એવા માણસને પ્રત્યુપકારની અપેક્ષા ન રાખતાં, નિઃસ્વાર્થભાવે જે આપવામાં આવે છે, એ દાન સાત્ત્વિક કહેવાયું છે. 17.20",

    "પરંતુ જે દાન કલેશપૂર્વક તથા પ્રત્યુપકારની અપેક્ષાથી કે ફળને દૃષ્ટિમાં રાખ્યા પછી આપવામાં આવે છે, એ દાન રાજસ કહેવાયું છે. 17.21",

    "જે દાન સત્કાર વિના કે તિરસ્કારપૂર્વક અયોગ્ય દેશકાળમાં અને કુપાત્રને આપવામાં આવે છે, એ દાન તામસ કહેવાયું છે. 17.22",

    "ૐ, તત, સત્ - એમ આ ત્રણ પ્રકારનું સચ્ચિદાનંદઘન બ્રહ્મનું નામ કહેવાયું છે, એ બ્રહ્મથી જ સૃષ્ટિના આરંભે બ્રાહ્મણો, વેદો તથા યજ્ઞ આદિ રચાયા. 17.23",

    "માટે વેદમંત્રોનું ઉચ્ચારણ કરનારા શ્રેષ્ઠ માણસોની, શાસ્ત્રવિધિથી નિયત થયેલી યજ્ઞ, દાન અને તપરૂપી ક્રિયાઓ સદા ‘ૐ’ એ પરમાત્માના નામનું ઉચ્ચારણ કરીને જ શરૂ થાય છે. 17.24",

    "તત્ એટલે ‘તત્’ નામથી ઓળખાતા પરમાત્માનું જ આ સઘળું છે – એવા ભાવ સાથે ફળને લક્ષમાં ન રાખતાં અનેક પ્રકારની યજ્ઞ - તપરૂપી ક્રિયાઓ, તેમજ દાનરૂપી ક્રિયાઓ કલ્યાણની ઈચ્છા રાખનારા માણસો દ્વારા કરવામાં આવે છે. 17.25",

    "સત્ - એ પ્રમાણે આ પરમાત્માનું નામ સત્યભાવમાં અને શ્રેષ્ઠભાવમાં પ્રયુક્ત થાય છે તથા હે પાર્થ ! ઉત્તમ કર્મોમાં પણ સત્ શબ્દનો પ્રયોગ કરવામાં આવે છે. 17.26",

    "તથા યજ્ઞ, તપ અને દાનમાં જે શ્રદ્ધા અને આસ્તિક બુદ્ધિ છે, એ પણ સત્ કહેવાય છે અને એ પરમાત્માને કાજે કરેલું કર્મ તો નિશ્ચિતપણે સત્ કહેવાય છે. 17.27",

    "હે પાર્થ! વગર શ્રદ્ધાએ કરાયેલો હવન, દીધેલું દાન, આચરેલું તપ અને જે કંઈ પણ કરવામાં આવેલું શુભ કર્મ છે - એ સઘળું અસત્ કહેવાય છે, માટે એ ન તો આ લોકમાં લાભદાયક છે કે ન તો મર્યા પછી. 17.28",

    "આ રીતે ૐ, તત્‌, સત્‌-એ ભગવાનના નામોના ઉચ્ચારણ સાથે બ્રહ્મવિધા અને યોગશાસ્ત્રમય શ્રીમદ્ભગવદ્‌ગીતોપનિષદરૂપી શ્રીકૃષ્ણાર્જુનસંવાદમાં \"શ્રદ્ધાત્રયવિભાગયોગ\" નામનો સત્તરમો અધ્યાય પૂરો થયો. ॥૧૭॥"
  ],
  "adhyaya-18" : [
    "અર્જુન બોલ્યા : હે મહાબાહો! હે અન્તર્યામી! હે વાસુદેવ! હું સંન્યાસના તેમજ ત્યાગના તત્ત્વને જુદું-જુદું જાણવા માંગું છું. 18.1",

    "શ્રીભગવાન બોલ્યા : કેટલાક પંડિતજનો કામ્યકર્મોના ત્યાગને સંન્યાસ સમજે છે, જ્યારે બીજા વિચારકુશળ માણસો સધળાંય કર્મોના ફળના ત્યાગને ત્યાગ કહે છે. 18.2",

    "કેટલાક વિદ્વાનો એમ કહે છે કે કર્મમાત્ર દોષયુક્ત છે, માટે ત્યાગ કરવા યોગ્ય છે, જ્યારે અન્ય વિદ્વાનો એમ કહે છે કે યજ્ઞ, દાન અને તપરૂપી કર્મ ત્યાગ કરવા યોગ્ય નથી. 18.3",

    "હે પુરુષશ્રેષ્ઠ! હે ભરતવંશીઓમાં ઉત્તમ! સંન્યાસ અને ત્યાગ - એ બેમાંથી પહેલાં ત્યાગ વિષે તું મારો નિશ્ચય સાંભળ, કેમકે ત્યાગ સાત્ત્વિક, રાજસ અને તામસ એવા ભેદથી ત્રણ પ્રકારનો કહેવાયો છે. 18.4",

    "યજ્ઞ, દાન અને તપરૂપી કર્મ ત્યાગ કરવા યોગ્ય નથી, પરંતુ એ તો અવશ્ય કરવાં જોઈએ; કેમકે યજ્ઞ, દાન અને તપ- એ ત્રણેય કર્મો બુદ્ધિશાળી માણસોને પવિત્ર કરનારાં છે. 18.5",

    "માટે હે પાર્થ! આ યજ્ઞ, દાન અને તપરૂપી કર્મોને, તેમજ બીજાં પણ સઘળાંય કર્તવ્ય કર્મોને આસક્તિ અને ફળનો ત્યાગ કરીને ચોક્કસ કરવાં જોઈએ; આ મારો નિશ્ચિત કરેલો ઉત્તમ મત છે. 18.6",

    "(નિષિદ્ધ તેમજ કામ્યકર્મોનો તો સ્વરૂપથી ત્યાગ કરવો ઉચિત જ છે,) પરંતુ નિયત કર્મનો સ્વરૂપથી ત્યાગ ઉચિત નથી; માટે મોહને લીધે એ નિયત કર્મનો ત્યાગ કરી દેવો તામસ ત્યાગ કહેવાયો છે. 18.7",

    "જે કંઈ કર્મ છે, એ સઘળું દુઃખરૂપ જ છે - એમ માનીને જો કોઈ માણસ શારીરિક ક્લેશના ભયથી કર્તવ્યકર્મોનો ત્યાગ કરી દે, તો એ આવો રાજસ ત્યાગ કરીને ત્યાગના ફળને કોઈ રીતે પણ નથી પામતો. 18.8",

    "હે અર્જુન! જે 'શાસ્ત્રવિહીત કર્મ કરવું, કર્તવ્ય છે' એવા ભાવથી આસક્તિ અને ફળનો ત્યાગ કરીને કરવામાં આવે છે, એ જ સાત્ત્વિક ત્યાગ મનાયો છે. 18.9",

    "જે માણસ અકુશળ કર્મનો દ્વેષ નથી કરતો કે કુશળ કર્મમાં આસક્ત નથી થતો, એ યુક્ત માણસ સંશય વિનાનો, બુદ્ધિશાળી અને ખરો ત્યાગી છે. 18.10",

    "શરીરધારી કોઈ પણ માણસ દ્વારા સંપૂર્ણપણે બધાં જ કર્મોને ત્યજવાં શક્ય નથી; માટે જે કર્મફળનો ત્યાગ કરનાર છે, એ જ ત્યાગી કહેવાય છે. 18.11",

    "કર્મફળનો ત્યાગ ન કરનારા માણસોનાં કર્મોનું સારું, નરસું અને મિશ્ર - એમ ત્રણ પ્રકારનું ફળ મર્યા પછી અવશ્ય હોય છે; પરંતુ કર્મફળનો ત્યાગ કરી દેનારા માણસોનાં કર્મોનું ફળ કોઈ કાળે નથી હોતું. 18.12",

    "હે મહાબાહો ! સર્વ કર્મોના સિદ્ધ થવામાં આ પાંચ કારણો, કર્મોનોઅંત કરવાનો ઉપાય દર્શાવનાર સાંખ્યશાસ્ત્રમાં કહેવાયાં છે, એમને તું મારી પાસેથી સારી રીતે જાણી લે. 18.13",

    "આ વિષે એટલે કે કર્મોની સિદ્ધિ થવામાં અધિષ્ઠાન, કર્તા, જુદા જુદા પ્રકારનાં કરણો, અનેક જાતની જુદી - જુદી ચેષ્ટાઓ અને એ જ રીતે પાંચમુ કારણ દૈવ છે. 18.14",

    "માણસ મન, વાણી અને શરીરથી શાસ્ત્રને અનુકૂળ કે વિપરીત જે કંઈ પણ કર્મ આચરે છે – એનાં આ પાંચેય કારણો છે. 18.15",

    "પરંતુ આમ હોવા છતાં પણ જે માણસ અશુદ્ધ બુદ્ધિનો હોવાને કારણે કર્મોના થવામાં ‘કેવળ’ અર્થાત્ શુદ્ધસ્વરૂપ આત્માને કર્તા સમજે છે, એ મલિન બુદ્ધિનો અજ્ઞાની ખરું નથી સમજતો. 18.16",

    "જે માણસના અન્તઃકરણમાં ‘હું કર્તા છું’ એવો ભાવ નથી, તેમજ જેની બુદ્ધિ સાંસારિક પદાર્થોમાં અને કર્મોમાં લેપાતી નથી, એ માણસ આ બધા લોકોને હણીને પણ વાસ્તવમાં નથી હણતો કે નથી પાપથી બંધાતો. 18.17",

    "જ્ઞાતા, જ્ઞાન અને જ્ઞેય - આ ત્રણ પ્રકારનાં કર્મના પ્રેરકો છે; કર્તા, કરણ, તેમજ ક્રિયા આ ત્રણ પ્રકારનો ક્રમસંગ્રહ એટલે કે કર્મનાં સાધનો છે. 18.18",

    "તે ગુણોને આધારે સઘળા પદાર્થોની ગણના કરનારા સાંખ્યશાસ્ત્રમાં ગુણોના ભેદને લીધે જ્ઞાન, કર્મ તથા કર્તા ત્રણ - ત્રણ પ્રકારનાં જ કહેવાયાં છે; એમને પણ તું મારી પાસેથી સારી પેઠે સાંભળ. 18.19",

    "જે જ્ઞાનથી માણસ ભિન્ન-ભિન્ન જણાતાં બધાં જ ભૂતોમાં એક અવિનાશી પરમાત્મભાવને, અવિભાજિતરૂપે સમભાવે રહેલો જુએ છે, એ જ્ઞાનને તું સાત્ત્વિક જાણ. 18.20",

    "પરંતુ જે જ્ઞાન એટલે કે જે જ્ઞાન દ્વારા મનુષ્ય સકળ ભૂતોમાં જુદી-જુદી જાતના અનેક ભાવોને જુદા-જુદા સ્વરૂપે જાણે છે, એ જ્ઞાનને તું રાજસ જાણ. 18.21",

    "અને વળી જે જ્ઞાન એકલા કાર્યરૂપ શરીરમાં જ સંપૂર્ણની જેમ આસક્ત રહેનારું છે તથા જે યુક્તિ વિનાનું, તાત્ત્વિક અર્થથી રહિત તેમજ તુચ્છ છે, એ તામસ કહેવાયું છે. 18.22",

    "જે કર્મ શાસ્ત્રવિધિથી નિયત થયેલું હોય, કર્તાપણાના અભિમાનથી રહિત થઈને કરવામાં આવ્યું તેમજ ફળની લાલસા વિનાના માણસ વડે રાગ-દ્વેષ વિના કરવામાં આવ્યું હોય - એ કર્મ સાત્ત્વિક કહેવાય છે. 18.23",

    "પરંતુ જે કર્મ ઘણો પરિશ્રમ વેઠીને કરાય છે અને વળી ભોગોને ઇચ્છતા માણસ વડે કે અહંકારી માણસ વડે કરવામાં આવે છે ,એ કર્મ રાજસ કહેવાયું છે. 18.24",

    "જે કર્મ પરિણામ, હાનિ, હિંસા અને સામર્થ્યને ધ્યાનમાં લીધા વિના, કેવળ અજ્ઞાનને લીધે આચરવામાં આવે છે,એ કર્મ તામસ કહેવાય છે. 18.25",

    "જે કર્તા સંગ વિનાનો, અહંકારભર્યાં વચનો ન બોલનાર, ધૈર્ય અને ઉત્સાહ ધરાવનાર તથા કાર્યની સિદ્ધિ કે અસિદ્ધિમાં હરખ-શોક આદિ વિકારોથી રહિત છે, એ સાત્ત્વિક કહેવાય છે. 18.26",

    "જે કર્તા આસક્તિ ધરાવનાર, કર્મોના ફળની લાલસા રાખનાર, લોભી, બીજાને કષ્ટ આપવાનો સ્વભાવ ધરાવનાર, અશુદ્ધ આચરણ રાખનાર તેમજ હરખ-શોકથી લિપ્ત છે, એ રાજસ કહેવાયો છે. 18.27",

    "જે કર્તા પોતાને વશમાં ન રાખનાર, અશિક્ષિત, ઘમંડી, શઠ, બીજાની આજીવિકાનો નાશ કરનાર, શોક કરનાર,આળસુ અને દીર્ઘસૂત્રી છે, એ તામસ કહેવાય છે. 18.28",

    "હે ધનંજય! હવે તું બુદ્ધિ તેમજ ધૃતિનો પણ ગુણો અનુસાર ત્રણ પ્રકારનો ભેદ મારા વડે સંપૂર્ણપણે વિભાગપૃર્વક કહેવાતો સાંભળ. 18.29",

    "હે પાર્થ! જે બુદ્ધિ પ્રવૃત્તિમાર્ગ અને નિવૃત્તિ માર્ગને, કર્તવ્ય તેમજ અકર્તવ્યને, ભય અને અભયને તથા બંધન અને મોક્ષને યથાર્થપણે જાણે છે, એ બુદ્ધિ સાત્ત્વિકી છે. 18.30",

    "હે પાર્થ! માણસ જે બુદ્ધિ દ્વારા ધર્મ અધર્મને તથા કર્તવ્ય અને અકર્તવ્યને યથાર્થપણે નથી જાણી શકતો, એ બુદ્ધિ રાજસી છે. 18.31",

    "હે પાર્થ! તમોગુણથી ઘેરાયેલી જે બુદ્ધી અધર્મને પણ ‘આ ધર્મ છે’ એમ ધર્મ માની બેસે છે તથા એ જ પ્રમાણે બીજા સઘળા પદાર્થોને પણ અવળા માને છે, એ બુદ્ધિ તામસી છે. 18.32",

    "હે પૃથાપુત્ર! જે અવ્યભિચારિણી ધારણશક્તિથી માણસ ધ્યાનયોગ દ્વારા મન, પ્રાણ અને ઇન્દ્રિયોની ક્રિયાઓને ધારણ કરે છે, એ ધૃતિ સાત્ત્વિકી છે. 18.33",

    "પરંતુ હે પૃથાપુત્ર અર્જુન! ફળની લાલસા રાખનાર માણસ જે ધારણશક્તિ દ્વારા ઘણી આસક્તિ-પૂર્વક ધર્મ અર્થ અને કામને ધારણ કરે છે, એ ધારણશક્તિ રાજસી છે. 18.34",

    "હે પૃથાપુત્ર! દુષ્ટ બુદ્ધિનો માણસ જે ધારણશક્તિ દ્વારા નિદ્રા, ભય, ચિંતા અને દુઃખને તથા ઉન્મત્તપણાને પણ નથી છોડતો, પણ પકડી રાખે છે, એ ધારણશક્તિ તામસી. 18.35",

    "હે ભરતશ્રેષ્ઠ! હવે ત્રણ પ્રકારનાં સુખને પણ તું મારી પાસેથી સાંભળ; જે સુખમાં સાધક માણસ ભજન, ધ્યાન અને સેવા આદિના અભ્યાસ વડે રમણ કરે છે અને જેથી દુઃખોના અન્તને પામી જાય છે - જે આ જાતનું સુખ છે, એ શરૂઆતમાં જોકે વિષ જેવું જણાય છે, પણ પરિણામે અમૃત જેવું છે. માટે એ પરમાત્મવિષયક બુદ્ધિના પ્રસાદમાંથી ઊપજનારુ સુખ સાત્ત્વિક કહેવાયું છે. 18.36-18.37",

    "હે ભરતશ્રેષ્ઠ! હવે ત્રણ પ્રકારનાં સુખને પણ તું મારી પાસેથી સાંભળ; જે સુખમાં સાધક માણસ ભજન, ધ્યાન અને સેવા આદિના અભ્યાસ વડે રમણ કરે છે અને જેથી દુઃખોના અન્તને પામી જાય છે - જે આ જાતનું સુખ છે, એ શરૂઆતમાં જોકે વિષ જેવું જણાય છે, પણ પરિણામે અમૃત જેવું છે. માટે એ પરમાત્મવિષયક બુદ્ધિના પ્રસાદમાંથી ઊપજનારુ સુખ સાત્ત્વિક કહેવાયું છે. 18.36-18.37",

    "જે સુખ વિષય અને ઇન્દ્રિયોના સંયોગથી થાય છે. એ શરૂઆતમાં - ભોગકાળમાં અમૃત જેવું જણાતું હોવા છતાં પરિણામે વિષ જેવું છે; માટે એ સુખ રાજસ કહેવાયું છે. 18.38",

    "જે સુખ ભોગકાળે તેમજ પરિણામે પણ આત્માને મોહિત કરનારું છે, એ નિદ્રા, આળસ અને પ્રમાદમાંથી જન્મેલું સુખ તામસ કહેવાયું છે. 18.39",

    "પૃથ્વી પર, આકાશમાં કે દેવતાઓમાં, અથવા એ સિવાય ક્યાંય પણ એવો કોઈ પણ જીવ નથી, જે આ પ્રકૃતિમાંથી જન્મેલા ત્રણેય ગુણોથી મુક્ત હોય. બ્રાહ્મણક્ષત્રિયવિશાં(મ્), શૂદ્રાણાં(ઞ્) ચ પરન્તપ। 18.40",

    "હે પરંતપ! બ્રાહ્મણો, ક્ષત્રિયો, વૈશ્યો તથા શૂદ્રોનાં કર્મો સ્વભાવમાંથી ઊપજેલા ગુણો દ્વારા વિભક્ત કરવામાં આવ્યા છે. 18.41",

    "અન્તઃકરણનો નિગ્રહ કરવો, ઇન્દ્રિયોનું દમન કરવું, ધર્મના પાલન કાજે કષ્ટ સહેવું, બાહ્ય-ભીતરથી શુદ્ધ રહેવું, બીજાના અપરાધોને ક્ષમા આપવી, મન, ઇન્દ્રિય તેમજ શરીરને ઋજુ સ્વભાવનાં રાખવાં, વેદો, શાસ્ત્રો, ઈશ્વર અને પરલોક આદિમાં શ્રદ્ધા રાખવી, વેદ-શાસ્ત્રોનું અધ્યયન તથા અધ્યાપન કરવું અને પરમાત્માના તત્ત્વને અનુભવવું - આ બધાંય બ્રાહ્મણનાં સ્વાભાવિક કર્યો છે. 18.42",

    "શૂરવીરપણું, તેજ, ધૈર્ય, નિપુણતા તથા યુદ્ધમાં પણ ન ભાગવું, દાન આપવું અને સ્વામિભાવ* - આ બધાં ક્ષત્રિયનાં સ્વાભાવિક કર્યો છે. 18.43",

    "ખેતી, ગોપાલન અને ખરીદવા-વેચવારૂપી સત્ય વ્યવહાર- આ વૈશ્યનાં સ્વાભાવિક કર્યો છે તથા સર્વ વર્ણોની સેવા કરવી, શૂદ્રનું પણ સ્વાભાવિક કર્મ છે. 18.44",

    "પોત પોતાના સ્વાભાવિક કર્મમાં તત્પરતાપૂર્વક જોડાયેલો માણસ ભગવદ્પ્રાપ્તિરૂપી પરમ સિદ્ધિને પામી જાય છે; પોતાના સ્વાભાવિક કર્મમાં માણસ જે રીતે કર્મ કરીને પરમ સિધ્ધિને પામે છે, એ ઉપાયને તું સાંભળ. 18.45",

    "જે પરમેશ્વરથી સઘળા પ્રાણીઓની ઉત્પત્તિ થઈ છે અને જેનાથી આ આખું જગત વ્યાપેલું છે, એ પરમેશ્વરની પોતાના સ્વાભાવિક કર્મો વડે પૂજા કરીને માણસ પરમ સિદ્ધિને પામી જાય છે . 18.46",

    "સારી રીતે આચરવામાં આવેલા બીજાના ધર્મ કરતાં ગુણ વિનાનો છતાં પણ પોતાનો ધર્મ ચડિયાતો છે; કેમકે સ્વભાવથી નિયત થયેલું સ્વધર્મરૂપી કર્મ કરતો માણસ પાપને નથી પામતો. 18.47",

    "હે કૌન્તેય! દોષયુક્ત હોવા છતાં પણ સહજ કર્મને ન છોડવું જોઈએ; કેમકે ધુમાડાથી અગ્નિની પેઠે સઘળા કર્મ કોઈને કોઈ દોષથી યુક્ત છે. 18.48",

    "સર્વત્ર આસક્તિરહિત બુદ્ધિ ધરાવનાર, સ્પૃહા વિનાનો તેમજ જેણે અંતઃકરણને જીત્યું છે એવો માણસ સાંખ્યયોગ દ્વારા એ પરમ નિષ્કર્મ સિદ્ધિને પામે છે. 18.49",

    "જે જ્ઞાનયોગની પરા નિષ્ઠા છે, એ નૈષ્કર્મ્યસિદ્ધિને જે પ્રકારે પામીને માણસ બ્રહ્મને પામે છે, એ પ્રકારને હે કુંતીપુત્ર! તું સંક્ષેપમાં જ મારી પાસેથી સાંભળ. 18.50",

    "વિશુદ્ધ થયેલી બુદ્ધિથી યુક્ત, પચવામાં હલકો, સાત્ત્વિક અને નિયમિતપણે ખોરાક ખાનાર, શબ્દાદિ વિષયોને ત્યજીને એકાંત તેમજ શુદ્ધ દેશનું સેવન કરનાર, સાત્ત્વિક ધારણશક્તિ દ્વારા અંતઃકરણ અને ઇન્દ્રિયોને સંયમિત કરી ને મન, વાણી તથા શરીરને વશમાં રાખનાર, રાગ -દ્વેષને સંપૂર્ણપણે નષ્ટ કરીને યોગ્ય રીતે દ્રઢ વૈરાગ્યનો આશરો લેનાર તથા અહંકાર, બળ, ઘમંડ, કામ, ક્રોધ અને પરિગ્રહને છોડીને નિરંતર ધ્યાનયોગ ને પરાયણ રહેનાર, મમત્વ વિનાનો અને શાંત માણસ સચ્ચિદાનંદઘન બ્રહ્મમાં અભિન્નભાવે સ્થિત રહેવાને પાત્ર બને છે. 18.51-18.53",

    "વિશુદ્ધ થયેલી બુદ્ધિથી યુક્ત, પચવામાં હલકો, સાત્ત્વિક અને નિયમિતપણે ખોરાક ખાનાર, શબ્દાદિ વિષયોને ત્યજીને એકાંત તેમજ શુદ્ધ દેશનું સેવન કરનાર, સાત્ત્વિક ધારણશક્તિ દ્વારા અંતઃકરણ અને ઇન્દ્રિયોને સંયમિત કરી ને મન, વાણી તથા શરીરને વશમાં રાખનાર, રાગ -દ્વેષને સંપૂર્ણપણે નષ્ટ કરીને યોગ્ય રીતે દ્રઢ વૈરાગ્યનો આશરો લેનાર તથા અહંકાર, બળ, ઘમંડ, કામ, ક્રોધ અને પરિગ્રહને છોડીને નિરંતર ધ્યાનયોગ ને પરાયણ રહેનાર, મમત્વ વિનાનો અને શાંત માણસ સચ્ચિદાનંદઘન બ્રહ્મમાં અભિન્નભાવે સ્થિત રહેવાને પાત્ર બને છે. 18.51-18.53",

    "વિશુદ્ધ થયેલી બુદ્ધિથી યુક્ત, પચવામાં હલકો, સાત્ત્વિક અને નિયમિતપણે ખોરાક ખાનાર, શબ્દાદિ વિષયોને ત્યજીને એકાંત તેમજ શુદ્ધ દેશનું સેવન કરનાર, સાત્ત્વિક ધારણશક્તિ દ્વારા અંતઃકરણ અને ઇન્દ્રિયોને સંયમિત કરી ને મન, વાણી તથા શરીરને વશમાં રાખનાર, રાગ -દ્વેષને સંપૂર્ણપણે નષ્ટ કરીને યોગ્ય રીતે દ્રઢ વૈરાગ્યનો આશરો લેનાર તથા અહંકાર, બળ, ઘમંડ, કામ, ક્રોધ અને પરિગ્રહને છોડીને નિરંતર ધ્યાનયોગ ને પરાયણ રહેનાર, મમત્વ વિનાનો અને શાંત માણસ સચ્ચિદાનંદઘન બ્રહ્મમાં અભિન્નભાવે સ્થિત રહેવાને પાત્ર બને છે. 18.51-18.53",

    "પછી એ સચ્ચિદાનંદઘન બ્રહ્મમાં એકાત્મભાવે સ્થિત, પ્રસન્નમન યોગી ન તો કોઈના માટે શોક કરે છે કે ન કશાયની આકાંક્ષા કરે છે; એવો બધાય પ્રાણીઓમાં સમભાવ રાખનાર યોગી મારી પરાભક્તિ ને પામી જાય છે. 18.54",

    "એ પરાભક્તિ દ્વારા યોગી મુજ પરમાત્માને, હું જે છું અને જેવા પ્રભાવનો છું, બરાબર એવો ને એવો તત્ત્વથી જાણી લે છે તથા એ ભક્તિના પ્રભાવે મને તત્ત્વથી જાણીને તત્કાળ મારામાં પ્રવેશી જાય છે. 18.55",

    "મારે પરાયણ થયેલો કર્મયોગી સઘળા કર્મોને સદા કરતો હોવા છતાંય મારી કૃપાથી શાશ્વત અવિનાશી પરમ પદને પામી જાય છે. 18.56",

    "માટે હે અર્જુન! સઘળા કર્મોને મનથી મારામાં અર્પીને તેમજ સમ બુદ્ધિરૂપી યોગને આશ્રયે રહીને, તું મારે પરાયણ અને નિરંતર મારામાં ચિત્ત પરોવનાર થા. 18.57",

    "એ પ્રમાણે મારામાં ચિત્ત પરોવનાર થયેલો તું મારી કૃપાથી બધાંય સંકટોને વિના પ્રયત્ને પાર કરી જઈશ; અને જો અહંકારને લીધે મારાં વચનોને નહીં સાંભળે, તો નાશ પામીશ, એટલે કે પરમાર્થથી ભ્રષ્ટ થઈ જઈશ. 18.58",

    "જે તું અહંકારનો આશરો લઈને એમ માનીને રહ્યો છે કે 'હું યુદ્ધ કરીશ નહીં ', તારો આ નિર્ણય મિથ્યા છે; કેમ કે તારો સ્વભાવ તને બળજબરીથી યુદ્ધમાં જોડશે. 18.59",

    "હે કુંતીપુત્ર! જે કર્મને તું મોહને લીધે કરવા નથી ઈચ્છતો, એને પણ પોતાના સ્વાભાવિક કર્મથી બંધાયેલો પરવશ થઈને કરીશ. 18.60",

    "હે અર્જુન! શરીરરૂપી યંત્ર પર આરૂઢ થયેલા સઘળાંય પ્રાણીઓને પોતાની માયાથી તેમના કર્મો પ્રમાણે ભમાવતો અંતર્યામી પરમેશ્વર સર્વ પ્રાણીઓના હૃદયમાં રહેલો છે. 18.61",

    "હે ભારત! તું સર્વ રીતે એ પરમેશ્વરના જ શરણે જા; એ પરમાત્માની કૃપાથી જ તું પરમ શાંતિને તથા શાશ્વત પરમ ધામને પામીશ. 18.62",

    "આમ આ ગોપનીયથીય અતિ ગોપનીય જ્ઞાન મેં તને કહી દીધું; હવે તું આ રહસ્યયુક્ત જ્ઞાનને સંપૂર્ણપણે યોગ્ય રીતે વિચારીને જેમ ઇચ્છે એમ જ કર. 18.63",

    "સઘળાં ગોપનીય વચનોથીય ગોપનીય મારા પરમ રહસ્યયુક્ત વચન ને તું હજી પણ સાંભળ; તું મને ઘણો પ્રિય છે, માટે આ પરમ હિતકારી વચન હું તારા માટે ફરીથી કહું છું. 18.64",

    "હે અર્જુન! તું મારામાં મન પરોવનાર થા, મારો ભક્ત બની જા, મારું પૂજન કરનાર થા અને મને પ્રણામ કર; આમ કરવાથી તું મને જ પામીશ, આ હું તારી સામે સત્ય પ્રતિજ્ઞા કરું છું , કેમકે તું મને ઘણો પ્રિય છે. 18.65",

    "બધા ધર્મોને એટલેકે બધા કર્તવ્ય કર્મો ને મારામાં ત્યજીને, તું કેવળ એક માત્ર મુજ સર્વ શક્તિમાન સર્વાધાર પરમેશ્વરના જ શરણે આવી જા; હું તને બધા પાપોથી મુક્ત કરી દઈશ, તું શોક કર મા. 18.66",

    "તારે આ ગીતારૂપી રહસ્યમય ઉપદેશ કોઈપણ કાળે ન તો તપરહિત માણસને કહેવો, ન તો ભક્તિરહિતને અને ન તો સાંભળવા ન ઈચ્છતા માણસ ને કહેવો તથા જે મારામાં દોષ દ્રષ્ટિ રાખે છે એને તો કદીયે ન કહેવો. 18.67",

    "જે માણસ મારામાં પરમ પ્રેમ રાખીને આ પરમ રહસ્યયુક્ત ગીતાના ઉપદેશને મારા ભક્તોમાં કહી સંભળાવશે, એ મને જ પામશે એમાં જરા પણ સંદેહ નથી. 18.68",

    "માણસોમાં એનાથી વધીને મારુ પ્રિય કાર્ય કરનારો કોઈ પણ નથી તથા આખી પૃથ્વી પર એનાથી વધીને મારો પ્રિય બીજો કોઈ ભવિષ્યમાં થશે પણ નહીં. 18.69",

    "જે માણસ આ ધર્મમય આપણા બેના સંવાદરૂપી ગીતા શાસ્ત્રોનું અધ્યયન કરશે ,એના દ્વારા પણ હું જ્ઞાન યજ્ઞ થી પૂજિત થઈશ એવો મારો મત છે. 18.70",

    "જે માણસ શ્રદ્ધાળુઓ અને દોષ દ્રષ્ટિ વિનાનો થઈને આ ગીતા શાસ્ત્રનું શ્રવણ કરશે, એ પણ પાપોથી છૂટી ને ઉત્તમ કર્મ કરનારાઓના શ્રેષ્ઠ લોકોને પામશે. 18.71",

    "હે પાર્થ ! શું આ ગીતાશાસ્ત્ર ને તે એકાગ્ર ચિત્તે સાંભળ્યું? અને હે ધનંજય! શું તારો અજ્ઞાનમાંથી ઉપજેલો મોહ નાશ પામ્યો ? 18.72",

    "અર્જુન બોલ્યા : હે અચ્યુત ! આપની કૃપાથી મારો મોહ નષ્ટ થઈ ગયો અને સ્મૃતિ મેળવી લીધી છે, હવે હું સંશયરહિત થઈને સ્થિત છું; માટે આપની આજ્ઞાનું પાલન કરીશ. 18.73",

    "સંજય બોલ્યા : આમ મેં શ્રી વાસુદેવ અને મહાત્મા અર્જુનના આ અદ્ભુત રહસ્યયુક્ત રોમાંચકારક સંવાદને સાંભળ્યો. 18.74",

    "શ્રી વ્યાસજીની કૃપાથી દિવ્ય દ્રષ્ટિ પામીને મેં આ પરમ ગોપનીય યોગને, અર્જુન પ્રત્યે કહેતાં સ્વયં યોગેશ્વર ભગવાન શ્રીકૃષ્ણ પાસેથી પ્રત્યક્ષ સાંભળ્યો છે. 18.75",

    "હે રાજન્ ! ભગવાન શ્રીકૃષ્ણ અને અર્જુનના આ રહસ્ય યુક્ત, કલ્યાણકારક અને અદભુત સંવાદને સંભાળી- સંભાળીને હું વારંવાર હરખાવ છું. 18.76",

    "હે રાજન્ ! શ્રી હરિના એ અત્યંત વિલક્ષણ રૂપને પણ સંભાળી સંભાળીને મારા ચિત્તમાં મહાન આશ્ચર્ય થાય છે અને હું વારંવાર હરખાવ છું. 18.77",

    "હે રાજન્ ! જ્યાં યોગેશ્વર ભગવાન શ્રી કૃષ્ણ છે અને જ્યાં ગાંંડીવધારી અર્જુન છે ત્યાં જ શ્રી, વિજય, વિભૂતિ તેમજ અચળ નીતિ છે - એવો મારો મત છે. 18.78",

    "આ રીતે ૐ, તત્‌, સત્‌-એ ભગવાનના નામોના ઉચ્ચારણ સાથે બ્રહ્મવિધા અને યોગશાસ્ત્રમય શ્રીમદ્ભગવદ્‌ગીતોપનિષદરૂપી શ્રીકૃષ્ણાર્જુનસંવાદમાં \"મોક્ષસંન્યાસયોગ\" નામનો અઢારમો અધ્યાય પૂરો થયો. ॥૧૮॥"
  ]
  }
]

    export default GeetajiGujarati