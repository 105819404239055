import qrCode from '../imgs/QR.jpeg';

const QRandMsg = () => {
  return (
    <div className="QR">
        <p>Learn with us,<br/>pure and correct pronunciation of<br/>great scripture<br/>Śrīmad-Bhagvadgītā !</p>
        <img src={qrCode} alt="QR Code"/>
        <p>हर घर गीता, हर कर गीता</p> 
    </div>
  )
}

export default QRandMsg
