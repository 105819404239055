const TextNepali = {
    Home:
    {
    WelMsg: "लर्नगीता कण्ठस्थिकरण अभ्यास पृष्ठ",
    chooseLang: "आफ्नो भाषा छान्नुहोस्:",
    greetMsg: "गीता परिवारमा हजुरलाई स्वागत छ",
    linkBasic1: "सामान्य अभ्यास",
    linkBasic2:"चरणानुसार",
    startPractice: "अभ्यास प्रारम्भ गर्नुहोस्",
    linkAdv1: "अग्र स्तर अभ्यास",
    linkAdv2: "श्लोकांक",
    linkSerialWise: "क्रमानुसार सीखें",
    alert: "यो भाषा वर्तमानमा उपलब्ध छैन"
    },
Practice:
    {
    WelMsg: "श्रीमद्भगवद्गीता कंठस्थीकरण सहायता पृष्ठ",
    selAdh: "एक वा बढी अध्याय छान्नुहोस्:",
    or: "अथवा",
    selectAll: "सभी अध्याय चुने",
    selectdAdh: "छानिएको अध्याय",
    clkBtn: "क्रमरहित श्लोक संख्या प्राप्त गर्नको लागि बटनमा क्लिक गर्नुहाेस्",
    alert: "कृपया कम से कम एक अध्याय छान्नुहोस्",
    meaningMsg: "यो श्लोकको  अर्थ अहिले उपलब्ध छैन, कृपया आगामी अपडेट कोलागि प्रतीक्षा गर्नुहोला",
    shlokNo: "श्लोक क्रमांक",
    shlokCharan: "श्लोक चरण",
    showAnswer:"उत्तर हेर्नुहोस्",
    showMeaning: "अर्थ हेर्नुहोस्",
    adhyaya: "अध्याय"
    },
Nums:
    {
    num1: "१", 
    num2: "२",
    num3: "३",
    num4: "४",
    num5: "५",
    num6: "६",
    num7: "७",
    num8: "८",
    num9: "९",
    num10: "१०",
    num11: "११",
    num12: "१२",
    num13: "१३",
    num14: "१४",
    num15: "१५",
    num16: "१६",
    num17: "१७",
    num18: "१८"
    },
    Score:
    {
    reportCard: "रिपोर्ट कार्ड",
    srNo: "क्र.सं.",
    adhyaya: "अध्याय",
    shlokNo: "श्लोक क्रमांक",
    time: "समय",
    },
Serialwise:
    {
    WelMsg: "श्रीमद्भगवदगीता क्रमानुसार सीखने का पृष्ठ",
    },
}
export default TextNepali